import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const StyledFullScreenModal = styled.section`
  background: ${(props) => props.theme.fullscreenModal.backgroundColor};
  height: 100vh;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: ${elevations.P100};
`;

const HeaderWrapper = styled.nav`
  display: block;
  vertical-align: middle;
  * {
    vertical-align: middle;
  }
  span {
    text-transform: uppercase;
  }
`;

const HeaderActions = styled.div`
  padding: 10px 12px 0 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CLOSE,
  color: props.color ? props.color : props.theme.entityDetails.headerIconColor,
}))``;

export const FullScreenModal = ({ children, showModal, closeModal }) => {
  if (!showModal) {
    return null;
  }

  return createPortal(
    <StyledFullScreenModal showModal={showModal}>
      <HeaderWrapper>
        <HeaderActions>
          {closeModal && (
            <button type="button" onClick={closeModal}>
              <CloseIcon color={globalColors.slate4} />
            </button>
          )}
        </HeaderActions>
      </HeaderWrapper>
      {children}
    </StyledFullScreenModal>,
    document.querySelector('#portal-root'),
  );
};

FullScreenModal.propTypes = {
  children: PropTypes.node,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

FullScreenModal.defaultProps = {
  showModal: false,
  closeModal: () => null,
};
