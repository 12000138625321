import React, { useCallback, useContext, useRef, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';
import CaseTemplateDetailsPanel from '@ge/feat-analyze/components/configure/cases/case-template-panel/case-template-panel';
import { MonitorProvider } from '@ge/feat-monitor/context/monitor-provider';
import { EntityType } from '@ge/models/constants';
import { inertClass } from '@ge/shared/components/entity-details/dismiss-inert';
import { TaskProvider } from '@ge/shared/components/tasks/task-provider';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { elevations } from '@ge/tokens';

import { SidePanel } from '../side-panel/side-panel';

import AssetDetails from './asset-details/asset-details';
import AnalysisTemplateDetails from './case-details/analysis-template-details';
import CaseDetails from './case-details/case-details';
import PanelNav from './components/panel-nav';
import DamageDetails from './damage-details/damage-details';
import EventDetails from './event-details/event-details';
import PersonDetails from './person-details/person-details';
import RoleDetails from './role-details/role-details';
import SiteDetails from './site-details/site-details';
import BulkServiceRequestPanel from './task-details/bulk-service-request-panel';
import TaskDetails from './task-details/task-details';
import TicketDetails from './ticket-details/ticket-details';

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const DetailsPanel = styled.aside`
  border-left: solid 1px ${(props) => props.theme.entityDetails.borderColor};
  background: ${(props) => props.theme.entityDetails.backgroundColor};
  bottom: 0;
  box-shadow: ${(props) => props.theme.layout.sidebarBoxShadow};
  color: ${(props) => props.theme.layout.textColor};
  overflow: hidden;
  padding: 30px 0 0 4px;
  position: fixed;
  right: 0;
  top: 0;
  width: 780px;
  z-index: ${elevations.P20};

  &:after {
    background-color: ${(props) => props.theme.entityDetails.detailsPanelAfter};
    content: ' ';
    height: 100%;
    width: 4px;
    position: absolute;
    left: 0;
    top: 0;
  }

  table {
    background: ${(props) => props.theme.entityDetails.overviewBlockBackground};
    border-top: solid 1px ${(props) => props.theme.entityDetails.overviewBorderColor};
    margin-top: 10px;
  }
`;

const EntityDetailsPanelComponent = ({ className }) => {
  const { t } = useTranslation(['tasks', 'entity-details', 'general'], {
    useSuspense: false,
  });

  const {
    entityId,
    entityType,
    entityTab,
    hideDetails,
    dirtyFields,
    setEntityFieldsDirtyCheck,
    setCrewForBundledTask,
  } = useContext(EntityDetailsContext);
  const parentRef = useRef();
  const containerRef = useRef(null);
  const history = useHistory();

  const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);
  const [tabChanged, setTabChanged] = useState(false);

  const close = useCallback(
    (e) => {
      if (dirtyFields) {
        setShowConfirmPrompt(true);
      } else {
        const isInert = e && !!e.target.closest(`.${inertClass}`);
        if (!e || !isInert) {
          hideDetails();
        }
      }
      setCrewForBundledTask({
        crewId: null,
        isSecondaryBundle: false,
      });
    },
    [dirtyFields, hideDetails, setCrewForBundledTask],
  );

  const handleCancel = useCallback(() => {
    setShowConfirmPrompt(false);
    setTabChanged(false);
    setEntityFieldsDirtyCheck((prevState) => {
      if (prevState[EntityType.CASE]) {
        return { ...prevState, [EntityType.CASE]: false };
      } else if (prevState[EntityType.SITE]) {
        return { ...prevState, [EntityType.SITE]: false };
      } else if (prevState[EntityType.TURBINE]) {
        return { ...prevState, [EntityType.TURBINE]: false };
      } else if (prevState[EntityType.TASK]) {
        return { ...prevState, [EntityType.TASK]: false };
      } else {
        return prevState;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContinue = useCallback(() => {
    if (tabChanged) {
      parentRef.current.setTab();
    } else {
      hideDetails();
    }
    setShowConfirmPrompt(false);
    setTabChanged(false);
  }, [hideDetails, tabChanged]);

  const showConfirmationPrompt = () => {
    setTabChanged(true);
    setShowConfirmPrompt(true);
  };

  /**
   * Open a new window with the entity-specific view embedded.
   *
   * @param {String} type Type of entity to spawn a window for
   * @param {String} id Entity ID to show
   */
  const spawnWindow = useCallback(
    (type, id) => {
      window.open(
        `/${type}/${id}`,
        '_blank',
        'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780px,height=800px',
      );

      close();
    },
    [close],
  );

  const getCancelFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={handleCancel}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleContinue}>
            {t('general:continue', 'Continue')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [handleCancel, handleContinue, t]);
  /**
   * Factory to retrieve the correct details component for the
   * selected entity.
   */

  const getEntityDetails = useCallback(() => {
    if (!entityId || !entityType) {
      return null;
    }

    switch (entityType) {
      case EntityType.SITE:
      case EntityType.STORAGE_SITE:
      case EntityType.SOLAR_SITE:
      case EntityType.HYBRID_SITE:
        return (
          <MonitorProvider>
            <SiteDetails
              siteId={entityId}
              entityTab={entityTab}
              entityType={entityType}
              isDatePersist={false}
              popsout={false}
            />
            ;
          </MonitorProvider>
        );
      case EntityType.TASK:
        return (
          <TaskDetails
            taskId={entityId}
            entityTab={entityTab}
            showConfirmationPrompt={showConfirmationPrompt}
            ref={parentRef}
          />
        );
      case EntityType.BULK_TASK:
        return (
          <TaskProvider>
            <BulkServiceRequestPanel taskId={entityId} />
          </TaskProvider>
        );
      case EntityType.TURBINE:
      case EntityType.INVERTER:
        return (
          <MonitorProvider>
            <AssetDetails
              assetId={entityId}
              entityTab={entityTab}
              entityType={entityType}
              isDatePersist={false}
              popsout={false}
            />
          </MonitorProvider>
        );
      case EntityType.TICKET:
        return <TicketDetails ticket={entityId} entityType={entityType} />;
      case EntityType.EVENT:
        return <EventDetails eventId={entityId} entityType={entityType} />;
      case EntityType.ANOMALY:
      case EntityType.CASE:
        return <CaseDetails caseId={entityId} />;
      case EntityType.CASE_TEMPLATE:
        return (
          <CaseTemplateDetailsPanel
            entityType={entityType}
            caseTemplateId={entityId}
            closePanel={close}
          />
        );
      case EntityType.ANALYSIS_TEMPLATE:
        return <AnalysisTemplateDetails entityId={entityId} closePanel={close} />;
      case EntityType.PERSON:
        return <PersonDetails personId={entityId} entityTab={entityTab} />;
      case EntityType.ROLE:
        return <RoleDetails roleId={entityId} entityTab={entityTab} />;
      // TODO: Remove NEW_TICKET once we're creating draft tickets. Future will use TICKET only.
      case EntityType.NEW_TICKET:
        return <TicketDetails ticket={{ event: entityId }} entityType={entityType} />;
      case EntityType.INSPECTION_DAMAGE:
        return <DamageDetails damageId={entityId} entityType={entityType} isPopOut={false} />;
      default:
        return null;
    }
  }, [entityId, entityType, entityTab, close]);

  const handleHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // #RENINSPECT MIGRATION NOTES#
  // reconcile reninspect panel with dwf panel in here
  // hopefully can just use side panel and not need this if/else logic
  const renderPanel = () => {
    if (entityType === EntityType.INSPECTION_DAMAGE) {
      return (
        <DetailsPanel ref={containerRef} className={className}>
          <PanelNav
            closePanel={close}
            entityId={entityId}
            spawnWindow={() => spawnWindow(entityType, entityId)}
            onHistoryBack={handleHistoryBack}
          />
          {getEntityDetails()}
        </DetailsPanel>
      );
    }

    return (
      <SidePanel
        className={className}
        close={close}
        spawnWindow={() => spawnWindow(entityType, entityId)}
        entityType={entityType}
        entityTab={entityTab}
      >
        {getEntityDetails()}
        {showConfirmPrompt && (
          <Dialog
            isOpen={true}
            onClose={handleCancel}
            onConfirm={handleContinue}
            header={t('general:confirmation', 'Confirmation')}
            footer={getCancelFooter}
            contentWidth
            padContent={true}
          >
            <p>
              {t(
                'form.continue_without_saving',
                'Are you sure you want to continue without saving changes?',
              )}
            </p>
          </Dialog>
        )}
      </SidePanel>
    );
  };

  return createPortal(renderPanel(entityType), document.querySelector('#portal-root'));
};

export const EntityDetailsPanel = withRouter(EntityDetailsPanelComponent);
