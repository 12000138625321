import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { MenuNavigateChevron, MenuLoader, MenuTable } from '@ge/components/menu';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { EntityType } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledTable = styled.table`
  thead {
    background-color: ${({ theme }) => theme.modal.background};
  }
  tbody {
    .action-cell {
      text-align: right;
    }
    tr {
      width: 100%;
    }
  }
`;

const ScrollingTable = styled(StyledTable)`
  .fixed-head {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .action-cell {
    padding-right: 5% !important;
    width: ${({ entityType }) => (entityType === EntityType.REGION ? '15%' : '25%')};
  }
  .asset-cell {
    width: ${({ entityType }) => (entityType === EntityType.REGION ? '35%' : '40%')};
  }
  .field-cell {
    text-align: center;
    width: calc(
      ${({ entityType }) => (entityType === EntityType.REGION ? '30%' : '35%')} /
        ${({ fieldCount = 1 }) => fieldCount}
    );
  }
  .site-cell {
    width: ${({ entityType }) => (entityType === EntityType.REGION ? '25%' : '35%')};
  }
`;

const ScrollingContainerWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: ${({ minHeight }) => minHeight}px;
  width: 100%;
  .simplebar-placeholder {
    max-height: 0;
  }
`;

const SelectorTable = withTheme(
  ({ children, entityType, fieldCount, headChildren, minHeight, scrollable }) => {
    if (scrollable) {
      return (
        <ScrollingContainerWrapper minHeight={minHeight}>
          <ScrollingContainer>
            <ScrollingTable entityType={entityType} fieldCount={fieldCount}>
              <thead className="fixed-head">{headChildren}</thead>
              <tbody>{children}</tbody>
            </ScrollingTable>
          </ScrollingContainer>
        </ScrollingContainerWrapper>
      );
    }

    return (
      <StyledTable>
        <thead>{headChildren}</thead>
        <tbody>{children}</tbody>
      </StyledTable>
    );
  },
);

SelectorTable.propTypes = {
  children: PropTypes.node,
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  fieldCount: PropTypes.number,
  headChildren: PropTypes.node,
  minHeight: PropTypes.number,
  scrollable: PropTypes.bool,
};

SelectorTable.defaultProps = {
  children: null,
  entityType: EntityType.SITE,
  fieldCount: 1,
  headChildren: null,
  minHeight: undefined,
  scrollable: false,
};

// could make this even more generic but using for assets currently
export const AssetSelectorTable = ({
  data,
  entityType,
  headers,
  minHeight,
  onSelectAsset,
  scrollable,
  isLoading,
  hideArrow,
}) => {
  // handlers
  const handleSelectAsset = (event, id) => {
    killEventPropagation(event);

    onSelectAsset(id);
  };

  const { assetHeader, siteHeader, ..._fieldHeaders } = headers || {};
  const fieldHeaders = Object.values(_fieldHeaders);
  const hasData = !!(data && data.length);
  const isRegionOrSites = [EntityType.REGION, EntityType.FLEET].includes(entityType);

  return (
    <>
      <ConditionalRender shouldRender={isLoading || !hasData}>
        <MenuLoader />
      </ConditionalRender>
      <ConditionalRender shouldRender={!isLoading && hasData}>
        <MenuTable>
          <SelectorTable
            entityType={entityType}
            fieldCount={fieldHeaders.length}
            headChildren={
              <tr>
                <th className="asset-cell">{assetHeader}</th>
                {isRegionOrSites && <th className="site-cell">{siteHeader}</th>}
                {fieldHeaders.map((value) => (
                  <th className="field-cell" key={value}>
                    {value}
                  </th>
                ))}
                <th aria-label="actions" className="action-cell" />
              </tr>
            }
            minHeight={minHeight}
            scrollable={scrollable}
          >
            {data?.map(({ assetId: id, assetName, siteName, pct, kpiValue }) => (
              <tr key={id} onClick={(event) => handleSelectAsset(event, id)}>
                <td className="asset-cell">{assetName}</td>
                {isRegionOrSites && <td className="site-cell">{siteName}</td>}
                <td className="field-cell">{pct}</td>
                <td className="field-cell">{kpiValue}</td>
                {!hideArrow && (
                  <td className="action-cell">
                    <MenuNavigateChevron />
                  </td>
                )}
              </tr>
            ))}
          </SelectorTable>
        </MenuTable>
      </ConditionalRender>
    </>
  );
};

AssetSelectorTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  headers: PropTypes.instanceOf(Object),
  minHeight: PropTypes.number,
  onSelectAsset: PropTypes.func,
  percentSymbol: PropTypes.string,
  scrollable: PropTypes.bool,
  site: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  hideArrow: PropTypes.bool,
};

AssetSelectorTable.defaultProps = {
  data: [],
  entityType: EntityType.SITE,
  headers: [],
  minHeight: undefined,
  onSelectAsset: () => {},
  percentSymbol: null,
  scrollable: false,
  site: undefined,
  isLoading: false,
  hideArrow: false,
};
