import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Placeholders } from '@ge/models/constants';

const WeatherWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const WeatherData = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  svg {
    margin-right: 5px;
  }
`;
const StyledIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.entityDetails.headerIconColor,
}))``;

const DamageDetailsWeather = ({ weather }) => {
  const { windSpeed, temperature, humidity } = weather ?? {};

  return (
    <WeatherWrapper>
      <WeatherData>
        <StyledIcon icon={Icons.PRECIPITATION} />
        <h3 className="text-4">{humidity ? humidity : Placeholders.DOUBLE_DASH} &#37;</h3>
      </WeatherData>
      <WeatherData>
        <StyledIcon icon={Icons.TEMPERATURE} />
        <h3 className="text-4">{temperature ? temperature : Placeholders.DOUBLE_DASH} &#176;C</h3>
      </WeatherData>
      <WeatherData>
        <StyledIcon icon={Icons.WIND_BARB_20} />
        <h3 className="text-4">{windSpeed ? windSpeed : Placeholders.DOUBLE_DASH} m/s</h3>
      </WeatherData>
    </WeatherWrapper>
  );
};

DamageDetailsWeather.propTypes = {
  weather: PropTypes.instanceOf(Object),
};

DamageDetailsWeather.defaultProps = {};

export default DamageDetailsWeather;
