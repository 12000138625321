import { useStoreState } from 'easy-peasy';
import clone from 'ramda/src/clone';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tenant, ErpSource } from '@ge/models/constants';

import { TasksColumnDefs, TasksColumns } from '../models/tasks-table-cols';
export const useColumnDefs = () => {
  const { t } = useTranslation(['manage.erp-statuses']);

  const { approvalFlag, srNewFieldsFlag } = useStoreState((state) => state.tenant.featureFlags);

  const tenantId = sessionStorage.getItem('tenantId');

  const erpSource = tenantId.startsWith(Tenant.DTE) ? ErpSource.DTE_MAXIMO : ErpSource.RACES;

  const updateDefs = (cdef, key1, key2, translate) => {
    let def = cdef[key1].cols[key2];
    cdef[key1].cols[key2].labels = def.labels.map((acc) => {
      acc.a11yKey = 'table.' + translate.toLowerCase();
      acc.a11yDefault = t(translate, translate);
      return acc;
    });
    return cdef;
  };

  let newDef = clone(TasksColumnDefs);
  if (erpSource === ErpSource.DTE_MAXIMO) {
    newDef = updateDefs(newDef, TasksColumns.GROUP_SR_NUMBER, TasksColumns.SR_NUMBER, 'WO_NUMBER');
    //currently wo status is not required. Can be added in future below line.
    //newDef = updateDefs(newDef, TasksColumns.GROUP_SR_STATUS, TasksColumns.SR_STATUS, 'WO_STATUS');
    delete newDef[TasksColumns.GROUP_SR_STATUS];
    //for dte tenant committed date not needed
    delete newDef[TasksColumns.GROUP_COMMITTED_DATE];
  }

  // flag is only required for dfsa tenant
  if (!approvalFlag) delete newDef[TasksColumns.GROUP_FLAG];
  if (!srNewFieldsFlag) delete newDef[TasksColumns.GROUP_RDSPP];

  return useMemo(() => newDef, [newDef]);
};
