import { css } from 'styled-components';

const breakpoints = {
  tabletDown: '1145px',
};

export const Breakpoints = Object.keys(breakpoints).reduce((accum, label) => {
  accum[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;

  return accum;
}, {});
