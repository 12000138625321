import { PropTypes } from 'prop-types';
import React, { useContext, useCallback, forwardRef } from 'react';
import styled from 'styled-components';

import { ParetoChartContext } from '@ge/components/charts/pareto-chart/context';
import { Menu, MenuAllButton, MenuTitle } from '@ge/components/menu';
import { placements } from '@ge/components/menu/models/placements';
import { EntityType } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { useWindowDimensions } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';

import { AssetSelectorTable } from '../asset/asset-selector-table';

const PLACEMENT_THRESHOLD = 750;
const BAR_INDEX_THRESHOLD = 6;

const TooltipContainer = styled.div`
  max-width: ${({ width }) => width}px;
`;

export const DashboardIecLossCategoriesTooltip = forwardRef(
  (
    {
      anchorEl,
      data: _data,
      entityType,
      headers,
      maxAssets,
      onSeeAll,
      onSelectAsset,
      open,
      percentSymbol,
      seeAllButton,
      site,
      title,
      width,
      count,
      setSeeAll,
      hideArrow,
    },
    ref,
  ) => {
    const { height: windowHeight } = useWindowDimensions();
    const { barIndex } = useContext(ParetoChartContext);

    // handlers
    const handleSeeAll = (event) => {
      killEventPropagation(event);
      setSeeAll(true);
      onSeeAll();
    };

    const handleSelectAsset = (id) => onSelectAsset(id);

    const getPlacement = useCallback(() => {
      if (barIndex >= BAR_INDEX_THRESHOLD && windowHeight <= PLACEMENT_THRESHOLD) {
        return placements.AUTO_END;
      }
      return placements.AUTO_START;
    }, [barIndex, windowHeight]);

    const hasData = !!(_data && _data.length);
    const hasSeeAllButton = hasData && (_data.length > maxAssets || count > maxAssets);
    const data = (_data || []).slice(0, maxAssets || undefined).map(({ pct, ...fields }) => ({
      ...fields,
      pct: isNaN(pct) ? '-' : `${pct} ${percentSymbol}`,
    }));

    return (
      <TooltipContainer width={width} data-testid={AnalyzeLocators.ANALYZE_IEC_HOVER}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          container={ref}
          offset={10}
          width={width}
          placement={getPlacement()}
        >
          <MenuTitle title={title} count={count} />
          <AssetSelectorTable
            data={data}
            entityType={entityType}
            headers={headers}
            onSelectAsset={handleSelectAsset}
            site={site}
            hideArrow={hideArrow}
          />
          {hasSeeAllButton && <MenuAllButton onClick={handleSeeAll}>{seeAllButton}</MenuAllButton>}
        </Menu>
      </TooltipContainer>
    );
  },
);

DashboardIecLossCategoriesTooltip.displayName = 'DashboardIecLossCategoriesTooltip';

DashboardIecLossCategoriesTooltip.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  headers: PropTypes.instanceOf(Object),
  maxAssets: PropTypes.number,
  onSeeAll: PropTypes.func,
  onSelectAsset: PropTypes.func,
  open: PropTypes.bool,
  percentSymbol: PropTypes.string,
  seeAllButton: PropTypes.string,
  site: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  width: PropTypes.number,
  count: PropTypes.number,
  setSeeAll: PropTypes.func,
  hideArrow: PropTypes.bool,
};

DashboardIecLossCategoriesTooltip.defaultProps = {
  anchorEl: null,
  data: [],
  entityType: EntityType.SITE,
  headers: [],
  maxAssets: 5,
  onSeeAll: () => {},
  onSelectAsset: () => {},
  open: false,
  percentSymbol: null,
  seeAllButton: null,
  site: undefined,
  title: null,
  width: 150,
  count: 0,
};
