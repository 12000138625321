import {
  TableFilterTypes,
  MonitorCaseStatus,
  Capability,
  PermissionScope,
  AuthStrategy,
} from '@ge/models/constants';
export const EventsColumns = {
  // Asset Status
  GROUP_ASSET_STATE: 'group-asset-state',
  ASSET_STATUS: 'assetStateValue',

  // System Reset
  GROUP_SYSTEM_RESET: 'group-system-reset',
  SYSTEM_RESET: 'resetStatus',

  // Assets
  GROUP_ASSETS: 'group-assets',
  GROUP_SELECT: 'group-select',
  GROUP_TYPE: 'group-type',
  SELECT: 'event-selected',
  GROUP: 'group',
  SITE: 'site',
  TYPE: 'assetType',
  ASSET: 'asset',

  // Events
  GROUP_ISSUE_EVENTS: 'group-issue-events',
  CODE: 'code',
  SEVERITY: 'severity',
  DESCRIPTION: 'description',
  EVENT_STATUS: 'caseState',
  EVENT_TYPE: 'caseTypeLabel',

  // Duration
  GROUP_DURATION: 'group-duration',
  DURATION: 'duration',
  SITE_TIME: 'site-time',

  // TASKS
  GROUP_TASKS: 'group-tasks',
  TASKS: 'tasks',
  NEW_TASK: 'new-task',
  ESCALATIONS: 'escalations',
  ESCALATE: 'escalate',

  // Wind
  GROUP_WIND: 'group-wind',
  WIND: 'wind',

  // Local Time
  GROUP_LOCAL_TIME: 'group-local-time',
  LOCAL_TIME: 'local_time',
  CLOSE_TIME: 'close_time',

  // Tickets
  GROUP_TICKETS: 'group-tickets',

  // Actions
  GROUP_ACTIONS: 'group-actions',
  ACTIONS_MENU: 'actions-menu',
  CONTROLS_MENU: 'controls-menu',

  // Asset Make
  GROUP_ASSET_MAKE: 'group-asset-make',
  ASSET_MAKE: 'asset-make',

  // Asset Model
  GROUP_ASSET_MODEL: 'group-asset-model',
  ASSET_MODEL: 'asset-model',

  // Customer
  GROUP_CUSTOMER: 'group-customer',
  CUSTOMER: 'site-customer',

  // Country
  GROUP_COUNTRY: 'group-country',
  COUNTRY: 'site-country',

  // Priority
  GROUP_PRIORITY: 'group-priority',
  PRIORITY: 'priority',

  // Price
  GROUP_PRICE: 'group-price',
  PRICE: 'price',

  // Lost Revenue
  GROUP_LOST_REVENUE: 'group-lost-revenue',
  LOST_REVENUE: 'lost-revenue',

  // Expected Production
  GROUP_EXPECTED_PRODUCTION: 'group-expected-production',
  EXPECTED_PRODUCTION: 'expected-production',

  // Serial Number
  GROUP_SERIAL_NUMBER: 'group-serial-number',
  SERIAL_NUMBER: 'serial-number',

  //System Number
  GROUP_SYSTEM_NUMBER: 'group-system-number',
  SYSTEM_NUMBER: 'system-number',

  GROUP_CASE_DETAIL: 'group-case-detail',
  CASE_DETAIL: 'case-detail',

  // Notes
  GROUP_NOTES: 'group-notes',
  NOTES: 'notes',

  // Alerts
  GROUP_ALERTS: 'group-alerts',
  ALERTS: 'alerts',

  // Special Instructions
  GROUP_SI: 'group-special-instructions',
  SI: 'specialInstructions',

  // case id
  GROUP_CASE_ID: 'group-case-id',
  CASE_ID: 'id',

  // BOP Site
  GROUP_BOP_SITE: 'group-bop-site',
  BOP_SITE: 'substations',
};

// Define all available columns in the events table.
export const EventsColumnDefs = {
  [EventsColumns.GROUP_SELECT]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [EventsColumns.SELECT]: {
        locked: true,

        labels: [
          {
            a11yKey: 'table.eventSelected',
            a11yDefault: '',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [EventsColumns.GROUP_TYPE]: {
    cols: {
      // TODO : Need to bring this back in future
      // [EventsColumns.GROUP]: {
      //   align: 'left',
      //   labels: [
      //     {
      //       a11yKey: 'table.group',
      //       a11yDefault: 'Group',
      //       sortValue: 'group',
      //     },
      //   ],
      //   cell: {
      //     width: '65px',
      //     style: { fontSize: '11px' },
      //   },
      // },
      [EventsColumns.TYPE]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.type',
            a11yDefault: 'Type',
            sortValue: 'asset.type',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'center',
        },
      },
    },
  },
  [EventsColumns.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.assets',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [EventsColumns.SITE]: {
        locked: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.site',
            a11yDefault: 'Site',
            sortValue: 'site.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          maxWidth: '98px',
          entity: true,
        },
      },

      [EventsColumns.ASSET]: {
        locked: true,
        align: 'left',
        width: '75px',
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: 'asset.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          entity: true,
        },
      },
    },
  },
  [EventsColumns.GROUP_ASSET_STATE]: {
    cols: {
      /* #441 - removing for MVP0 (All the commented columns) */
      // [EventsColumns.SI]: {
      //   width: '20px',
      //   labels: [
      //     {
      //       a11yKey: 'table.si',
      //       a11yDefault: 'si',
      //       sortValue: 'site.tickets.si',
      //     },
      //   ],
      // },
      [EventsColumns.ASSET_STATUS]: {
        minWidth: '41px',
        labels: [
          {
            a11yKey: 'table.state',
            a11yDefault: 'State',
            sortValue: 'assetState.value',
          },
        ],
        // TODO: Fetch filter definitions from endpoint?
        filterType: TableFilterTypes.CHECKBOXES,
      },
      // [EventsColumns.SYSTEM_RESET]: {
      //   width: '10px',
      //   labels: [
      //     {
      //       a11yKey: 'table.resetMode',
      //       a11yDefault: 'System Reset',
      //       sortValue: 'asset.resetMode',
      //     },
      //   ],
      // },
    },
  },
  [EventsColumns.GROUP_SYSTEM_RESET]: {
    cols: {
      [EventsColumns.SYSTEM_RESET]: {
        minWidth: '41px',
        labels: [
          {
            a11yKey: 'table.system_reset',
            a11yDefault: 'System Reset',
            sortValue: 'resetStatus',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventsColumns.GROUP_ISSUE_EVENTS]: {
    labels: [
      {
        a11yKey: 'table.events',
        a11yDefault: 'EVENTS',
      },
    ],
    cols: {
      [EventsColumns.CODE]: {
        locked: true,
        width: '37px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.code',
            a11yDefault: 'Code',
            sortValue: 'code',
          },
        ],
        cell: {
          align: 'center',
        },
      },
      [EventsColumns.DESCRIPTION]: {
        locked: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.description',
            a11yDefault: 'Description',
            sortValue: 'description',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          maxWidth: '280px',
        },
      },
      // Hiding SERVERITY Column for now
      // [EventsColumns.SEVERITY]: {
      //   width: '10px',
      //   align: 'left',
      //   labels: [
      //     {
      //       a11yKey: 'table.severity',
      //       a11yDefault: 'Sev.',
      //       sortValue: 'severity',
      //     },
      //   ],
      //   cell: {
      //     align: 'center',
      //   },
      // },
      [EventsColumns.EVENT_STATUS]: {
        labels: [
          {
            a11yKey: 'table.status',
            a11yDefault: 'Status',
            sortValue: 'caseState',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        filters: [MonitorCaseStatus.NEW, MonitorCaseStatus.RETURNED, MonitorCaseStatus.CLOSED],
        appliesTo: 'events',
        cell: {
          align: 'center',
        },
      },
      [EventsColumns.EVENT_TYPE]: {
        align: 'center',
        labels: [
          {
            a11yKey: 'table.case_type',
            a11yDefault: 'Case Type',
            sortValue: 'caseTypeLabel',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },

  [EventsColumns.GROUP_NOTES]: {
    labels: [
      {
        a11yKey: 'table.notes',
        a11yDefault: 'Notes',
      },
    ],
    cols: {
      [EventsColumns.NOTES]: {
        capabilities: [
          { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
        ],
        align: 'left',
        labels: [
          {
            a11yKey: 'table.notes',
            a11yDefault: 'Notes',
            sortValue: 'notes',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
  [EventsColumns.GROUP_ALERTS]: {
    labels: [
      {
        a11yKey: 'table.alerts',
        a11yDefault: 'Alerts',
      },
    ],
    cols: {
      [EventsColumns.ALERTS]: {
        capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
        align: 'left',
        labels: [
          {
            a11yKey: 'table.alerts',
            a11yDefault: 'Alerts',
            sortValue: 'alerts.length',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
  [EventsColumns.GROUP_SI]: {
    labels: [
      {
        a11yKey: 'table.specialInstructions',
        a11yDefault: 'SI',
      },
    ],
    cols: {
      [EventsColumns.SI]: {
        capabilities: [
          { capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] },
        ],
        labels: [
          {
            a11yKey: 'table.specialInstructions',
            a11yDefault: 'SI',
            sortValue: 'specialInstructions.length',
          },
        ],
      },
    },
  },

  [EventsColumns.GROUP_TASKS]: {
    // Commenting for MVP0
    labels: [
      {
        a11yKey: 'table.tasks',
        a11yDefault: 'TASKS',
      },
    ],
    cols: {
      [EventsColumns.ESCALATIONS]: {
        minWidth: '42px',
        labels: [
          {
            a11yKey: 'table.escalations',
            a11yDefault: 'Escalations',
            sortValue: 'escalated.length',
          },
        ],
        cell: {
          align: 'center',
        },
      },
      [EventsColumns.ESCALATE]: {
        capabilities: [
          { capability: Capability.CASES, scopes: [PermissionScope.EDIT] },
          { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.CREATE] },
          { capability: Capability.EMAIL_NOTIFICATION, scopes: [PermissionScope.CREATE] },
        ],
        authStrategy: AuthStrategy.ALL,
        labels: [
          {
            a11yKey: 'table.escalate',
            a11yDefault: 'Escalate',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
  [EventsColumns.GROUP_ACTIONS]: {
    labels: [
      {
        a11yKey: 'table.actions',
        a11yDefault: 'Actions',
      },
    ],
    cols: {
      [EventsColumns.ACTIONS_MENU]: {
        capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.EDIT] }],
        width: '20px',
        labels: [
          {
            a11yKey: 'table.actions',
            a11yDefault: 'Actions',
          },
        ],
        cell: {
          zeroPadding: true,
        },
      },
    },
  },
  [EventsColumns.GROUP_DURATION]: {
    // Commenting for MVP0
    labels: [
      {
        a11yKey: 'table.duration',
        a11yDefault: 'DURATION',
      },
    ],
    cols: {
      [EventsColumns.DURATION]: {
        width: '50px',
        labels: [
          {
            a11yKey: 'table.duration',
            a11yDefault: 'Duration',
            sortValue: 'duration',
          },
        ],
        cell: {
          align: 'right',
        },
      },
      [EventsColumns.SITE_TIME]: {
        labels: [
          {
            a11yKey: 'table.site_time',
            a11yDefault: 'Site Time',
            sortValue: 'start',
          },
        ],
        cell: {
          maxWidth: '200px',
        },
      },
      [EventsColumns.CLOSE_TIME]: {
        width: '55px',
        labels: [
          {
            a11yKey: 'table.close_time',
            a11yDefault: 'Close Time',
            sortValue: 'end',
          },
        ],
        cell: {
          maxWidth: '200px',
        },
      },
    },
  },
  [EventsColumns.GROUP_ASSET_MAKE]: {
    cols: {
      [EventsColumns.ASSET_MAKE]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.asset_make',
            a11yDefault: 'Asset Make',
            sortValue: 'asset.make',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventsColumns.GROUP_ASSET_MODEL]: {
    cols: {
      [EventsColumns.ASSET_MODEL]: {
        minWidth: '45px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: 'table.asset_model',
            a11yDefault: 'Asset Model',
            sortValue: 'asset.model',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventsColumns.GROUP_CUSTOMER]: {
    cols: {
      [EventsColumns.CUSTOMER]: {
        minWidth: '95px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.customer',
            a11yDefault: 'Customer',
            sortValue: 'site.customer.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventsColumns.GROUP_COUNTRY]: {
    cols: {
      [EventsColumns.COUNTRY]: {
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.country',
            a11yDefault: 'Country',
            sortValue: 'site.country',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventsColumns.GROUP_WIND]: {
    cols: {
      [EventsColumns.WIND]: {
        width: '55px',
        labels: [
          {
            a11yKey: 'table.wind',
            a11yDefault: 'Wind m/s',
            sortValue: 'readings.general.windSpeed',
          },
        ],
      },
    },
  },
  [EventsColumns.GROUP_LOCAL_TIME]: {
    cols: {
      [EventsColumns.LOCAL_TIME]: {
        width: '55px',
        labels: [
          {
            a11yKey: 'table.local_time',
            a11yDefault: 'Local Time',
            sortValue: 'start',
          },
        ],
        cell: {
          maxWidth: '200px',
        },
      },
    },
  },
  // [EventsColumns.GROUP_PRIORITY]: {
  //   cols: {
  //     [EventsColumns.PRIORITY]: {
  //       minWidth: '52px',
  //       align: 'left',
  //       labels: [
  //         {
  //           a11yKey: 'table.priority',
  //           a11yDefault: 'Priority',
  //           sortValue: 'priority',
  //         },
  //       ],
  //     },
  //   },
  // },
  // [EventsColumns.GROUP_PRICE]: {
  //   cols: {
  //     [EventsColumns.PRICE]: {
  //       minWidth: '35px',
  //       align: 'right',
  //       labels: [
  //         {
  //           a11yKey: 'table.price',
  //           a11yDefault: 'Price',
  //           sortValue: 'price',
  //         },
  //       ],
  //     },
  //   },
  // },
  // [EventsColumns.GROUP_LOST_REVENUE]: {
  //   cols: {
  //     [EventsColumns.LOST_REVENUE]: {
  //       whiteSpace: 'wrap',
  //       align: 'right',
  //       labels: [
  //         {
  //           a11yKey: 'table.lost_revenue',
  //           a11yDefault: 'Lost Revenue',
  //           sortValue: 'lost-revenue',
  //         },
  //       ],
  //     },
  //   },
  // },
  // [EventsColumns.GROUP_EXPECTED_PRODUCTION]: {
  //   cols: {
  //     [EventsColumns.EXPECTED_PRODUCTION]: {
  //       whiteSpace: 'wrap',
  //       align: 'right',
  //       labels: [
  //         {
  //           a11yKey: 'table.expected_production',
  //           a11yDefault: 'Expected Production',
  //           sortValue: 'expected-production',
  //         },
  //       ],
  //     },
  //   },
  // },
  [EventsColumns.GROUP_SERIAL_NUMBER]: {
    cols: {
      [EventsColumns.SERIAL_NUMBER]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.serial_number',
            a11yDefault: 'Serial #',
            sortValue: 'asset.serialNumber',
          },
        ],
      },
    },
  },
  [EventsColumns.GROUP_SYSTEM_NUMBER]: {
    cols: {
      [EventsColumns.SYSTEM_NUMBER]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.system_number',
            a11yDefault: 'System #',
            sortValue: 'asset.systemNumber',
          },
        ],
      },
    },
  },
  // Hiding CASE DETAIL Column for now
  // [EventsColumns.GROUP_CASE_DETAIL]: {
  //   fixedRight: true,
  //   cols: {
  //     [EventsColumns.CASE_DETAIL]: {
  //       fixedRight: true,
  //       labels: [
  //         {
  //           a11yKey: 'table.case_detail',
  //           a11yDefault: 'Case Detail',
  //         },
  //       ],
  //     },
  //   },
  // },
  [EventsColumns.GROUP_CASE_ID]: {
    cols: {
      [EventsColumns.CASE_ID]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.id',
            a11yDefault: 'Case Id',
            sortValue: 'id',
          },
        ],
      },
    },
  },

  [EventsColumns.GROUP_BOP_SITE]: {
    cols: {
      [EventsColumns.BOP_SITE]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.bop_site',
            a11yDefault: 'BOP Site',
            sortValue: 'site.substations.instanceCount',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'center',
        },
      },
    },
  },
};

// Define the default visible columns and their order.
export const defaultEventCols = [
  {
    id: EventsColumns.GROUP_SELECT,
    cols: [
      {
        id: EventsColumns.SELECT,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_TYPE,
    cols: [
      // {
      //   id: EventsColumns.GROUP,
      //   visible: false,
      // },
      {
        id: EventsColumns.TYPE,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_ASSETS,
    cols: [
      {
        id: EventsColumns.SITE,
        visible: true,
      },
      {
        id: EventsColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_ASSET_STATE,
    cols: [
      // {
      //   id: EventsColumns.SI,
      //   visible: true,
      // },
      {
        id: EventsColumns.ASSET_STATUS,
        visible: true,
      },
      // {
      //   id: EventsColumns.SYSTEM_RESET,
      //   visible: true,
      // },
    ],
  },
  {
    id: EventsColumns.GROUP_SYSTEM_RESET,
    cols: [
      {
        id: EventsColumns.SYSTEM_RESET,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_ISSUE_EVENTS,
    cols: [
      {
        id: EventsColumns.CODE,
        visible: true,
      },
      {
        id: EventsColumns.DESCRIPTION,
        visible: true,
      },
      // {
      //   id: EventsColumns.SEVERITY,
      //   visible: true,
      // },
      {
        id: EventsColumns.EVENT_TYPE,
        visible: true,
      },
      {
        id: EventsColumns.EVENT_STATUS,
        visible: true,
        filter: 'cases',
      },
      {
        id: EventsColumns.EVENT_STATUS,
        visible: true,
        filter: 'history',
      },
    ],
  },
  {
    id: EventsColumns.GROUP_NOTES,
    cols: [
      {
        id: EventsColumns.NOTES,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_ALERTS,
    cols: [
      {
        id: EventsColumns.ALERTS,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_SI,
    cols: [
      {
        id: EventsColumns.SI,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_TASKS,
    cols: [
      {
        id: EventsColumns.ESCALATIONS,
        visible: true,
      },
      {
        id: EventsColumns.ESCALATE,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_ACTIONS,
    cols: [
      {
        id: EventsColumns.ACTIONS_MENU,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_DURATION,
    cols: [
      {
        id: EventsColumns.DURATION,
        visible: true,
      },
      {
        id: EventsColumns.SITE_TIME,
        visible: true,
      },
      {
        id: EventsColumns.CLOSE_TIME,
        visible: true,
        filter: 'history',
      },
    ],
  },

  {
    id: EventsColumns.GROUP_ASSET_MAKE,
    cols: [
      {
        id: EventsColumns.ASSET_MAKE,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_ASSET_MODEL,
    cols: [
      {
        id: EventsColumns.ASSET_MODEL,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_CUSTOMER,
    cols: [
      {
        id: EventsColumns.CUSTOMER,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_COUNTRY,
    cols: [
      {
        id: EventsColumns.COUNTRY,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_WIND,
    cols: [
      {
        id: EventsColumns.WIND,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_LOCAL_TIME,
    cols: [
      {
        id: EventsColumns.LOCAL_TIME,
        visible: true,
      },
    ],
  },
  // {
  //   id: EventsColumns.GROUP_PRIORITY,
  //   cols: [
  //     {
  //       id: EventsColumns.PRIORITY,
  //       visible: true,
  //     },
  //   ],
  // },
  // {
  //   id: EventsColumns.GROUP_PRICE,
  //   cols: [
  //     {
  //       id: EventsColumns.PRICE,
  //       visible: true,
  //     },
  //   ],
  // },
  // {
  //   id: EventsColumns.GROUP_LOST_REVENUE,
  //   cols: [
  //     {
  //       id: EventsColumns.LOST_REVENUE,
  //       visible: true,
  //     },
  //   ],
  // },
  // {
  //   id: EventsColumns.GROUP_EXPECTED_PRODUCTION,
  //   cols: [
  //     {
  //       id: EventsColumns.EXPECTED_PRODUCTION,
  //       visible: true,
  //     },
  //   ],
  // },
  {
    id: EventsColumns.GROUP_SERIAL_NUMBER,
    cols: [
      {
        id: EventsColumns.SERIAL_NUMBER,
        visible: true,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_SYSTEM_NUMBER,
    cols: [
      {
        id: EventsColumns.SYSTEM_NUMBER,
        visible: true,
      },
    ],
  },
  // {
  //   id: EventsColumns.GROUP_CASE_DETAIL,
  //   cols: [
  //     {
  //       id: EventsColumns.CASE_DETAIL,
  //       visible: true,
  //     },
  //   ],
  // },
  {
    id: EventsColumns.GROUP_CASE_ID,
    cols: [
      {
        id: EventsColumns.CASE_ID,
        visible: false,
      },
    ],
  },
  {
    id: EventsColumns.GROUP_BOP_SITE,
    cols: [
      {
        id: EventsColumns.BOP_SITE,
        visible: false,
      },
    ],
  },
];
