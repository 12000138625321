import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { getOidcInfo } from '@ge/shared/services/auth';

export const useFetchOidcInfo = () => {
  const {
    error,
    isError,
    status,
    refetch,
    isLoading: isLoadingOidcInfo,
    isFetching: isFetchingOidcInfo,
    data: fetchedOidcInfo,
  } = useQuery([QueryKey.FETCH_OIDC_INFO], () => getOidcInfo(), {
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isLoadingOidcInfo,
    isFetching: isFetchingOidcInfo,
    isError,
    status,
    error,
    refetch,
    data: fetchedOidcInfo,
  };
};
