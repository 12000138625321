import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import styled, { css } from 'styled-components';

import { elevations } from '@ge/tokens/elevations';
import { boolToInt } from '@ge/util/bool-utils';

const CommonCSS = css`
  .simplebar-offset {
    right: 0 !important;
    bottom: 0 !important;
  }
`;

const AbsoluteCSS = css`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const RelativeCSS = css`
  position: relative;
  width: 100%;
`;

const StyledSimpleBar = styled(SimpleBar)`
  ${CommonCSS}
  ${(props) => (props.relative ? RelativeCSS : AbsoluteCSS)};

  .simplebar-content {
    ${(props) =>
      props.left ? `margin-left: ${props.offseth}px` : `margin-right: ${props.offseth}px`}
    margin-bottom: ${(props) => `${props.offsetv}px`};
  }

  .simplebar-track {
    z-index: ${elevations.P0};
    position: absolute;
    ${(props) => (props.left ? 'left: 0' : 'right: 0')}
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
    background: ${(props) => props.theme.scrollbar.trackBackground};
    width: 10px;

    &.simplebar-vertical {
      top: 0;
      width: 11px;
      .simplebar-scrollbar {
        position: absolute;
        left: 0;
        right: 0;
        width: 8px;
        min-height: 10px;
        min-width: 10px;
        :before {
          position: absolute;
          content: '';
          border-radius: 2.5px;
          left: 0px;
          right: 0px;
          top: 1px;
          bottom: 1px;
        }
      }
    }

    &.simplebar-horizontal {
      left: 0;
      height: 12px;
      width: 100%;
      .simplebar-scrollbar {
        right: auto;
        left: 0;
        top: 0;
        height: 12px;
        min-height: 12px;
        min-width: 10px;
        width: auto;
        position: absolute;
        &:before {
          position: absolute;
          content: '';
          border-radius: 2.5px;
          height: 100%;
          left: 1px;
          right: 1px;
        }
      }
    }
  }

  &.simplebar-dragging {
    .simplebar-track {
      pointer-events: all;
      background: ${(props) => props.theme.scrollbar.trackActiveBackground};
    }
    .simplebar-horizontal .simplebar-scrollbar:before {
      height: 7px;
      border-radius: 2.5px;
    }
    .simplebar-vertical .simplebar-scrollbar:before {
      width: 7px;
      border-radius: 2.5px;
    }
    .simplebar-scrollbar:before {
      background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
      border: solid 1px ${(props) => props.theme.scrollbar.thumbActiveBorder};
    }
  }

  &:not(.simplebar-dragging) {
    .simplebar-horizontal .simplebar-scrollbar:before {
      height: 7px;
      border-radius: 2.5px;
    }
    .simplebar-vertical .simplebar-scrollbar:before {
      width: 7px;
      border-radius: 2.5px;
    }
    .simplebar-scrollbar:before {
      opacity: 0;
      transition: opacity 0.2s linear;
      background: ${(props) => props.theme.scrollbar.thumbBackground};
      border: solid 2px ${(props) => props.theme.scrollbar.trackBackground};
    }
  }

  .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }
`;

const FlexContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

export const ScrollingContainer = forwardRef((props, ref) => {
  const table = (
    <FlexContainer>
      <StyledSimpleBar
        scrollableNodeProps={{ ref }}
        forceVisible={props.forceVisible}
        autoHide={props.autoHide}
        style={props.style}
        classNames={{
          scrollbar: 'simplebar-scrollbar',
          track: 'simplebar-track',
        }}
        className={props.className}
        offseth={props.offsetX.toString()}
        offsetv={props.offsetY.toString()}
        theme={props.theme}
        left={props.left ? 1 : 0}
        relative={boolToInt(props.relative)}
      >
        {props.children}
      </StyledSimpleBar>
    </FlexContainer>
  );
  return table;
});

ScrollingContainer.displayName = 'ScrollingContainer';

ScrollingContainer.propTypes = {
  theme: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  className: PropTypes.string,
  forceVisible: PropTypes.string,
  autoHide: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  left: PropTypes.bool,
  relative: PropTypes.bool,
  TablekeyProperty: PropTypes.string,
};

ScrollingContainer.defaultProps = {
  theme: null,
  className: '',
  children: null,
  forceVisible: '',
  autoHide: true,
  style: {},
  offsetX: 0,
  offsetY: 0,
  left: false,
  relative: false,
};
