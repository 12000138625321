import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';
import { EntityType, Placeholders } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { killEventPropagation } from '@ge/shared/util/general';

import { AssetSelectorTable } from '../asset/asset-selector-table';

const StyledDialog = styled(Dialog)`
  width: ${({ entityType }) => (entityType === EntityType.REGION ? '500px' : '465px')};
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  h4 {
    margin-right: 6px;
  }
`;

const Content = styled.div`
  margin: -1px 0;
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  button {
    padding: 2px 20px;
  }
`;

export const DashboardIecLossCategoriesAssetSelector = withTheme(
  ({
    closeButton,
    data: _data,
    entityType,
    headers,
    onClose,
    onSelectAsset,
    open,
    percentSymbol,
    theme,
    title,
    isLoading,
    count: _count,
    hideArrow,
  }) => {
    const handleClose = (event) => {
      killEventPropagation(event);

      onClose();
    };

    const count = _count || (_data && _data.length) || 0;
    const data = (_data || []).map(({ pct, ...fields }) => ({
      ...fields,
      pct: pct ? `${pct} ${percentSymbol}` : Placeholders.DASH,
    }));

    return (
      <StyledDialog
        footer={
          <Footer>
            <Button primary onClick={handleClose}>
              {closeButton}
            </Button>
          </Footer>
        }
        header={
          <Header>
            <h4>{title}</h4>
            {Boolean(count) && (
              <Badge label={`${count}`} color={theme.analyze.dashboard.badgeColor} small />
            )}
          </Header>
        }
        isOpen={open}
        onClose={handleClose}
        padContent={false}
        entityType={entityType}
      >
        <Content data-testid={AnalyzeLocators.ANALYZE_IEC_HOVER_TABLE}>
          <AssetSelectorTable
            data={data}
            entityType={entityType}
            headers={headers}
            minHeight={270}
            onSelectAsset={onSelectAsset}
            scrollable
            isLoading={isLoading}
            hideArrow={hideArrow}
          />
        </Content>
      </StyledDialog>
    );
  },
);

DashboardIecLossCategoriesAssetSelector.propTypes = {
  closeButton: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  headers: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onSelectAsset: PropTypes.func,
  open: PropTypes.bool,
  percentSymbol: PropTypes.string,
  site: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  hideArrow: PropTypes.bool,
};

DashboardIecLossCategoriesAssetSelector.defaultProps = {
  closeButton: 'Close',
  data: [],
  entityType: EntityType.SITE,
  headers: [],
  onClose: () => {},
  onSelectAsset: () => {},
  open: true,
  percentSymbol: '%',
  site: undefined,
  count: 0,
};
