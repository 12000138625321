import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Loader } from '@ge/components/loader';
import { useManualAdjustmentContext } from '@ge/feat-analyze/context/manual-adjustment-context';
import { globalColors } from '@ge/tokens/colors';

import { SitePanelAssets } from './site-panel-assets';
import { SitePanelHeader } from './site-panel-header';

const CACollapsiblePanel = styled(CollapsiblePanel)`
  margin-bottom: 2px;
  .title {
    padding: 0 15px 0 7px;
    border-bottom: none;
    background-color: ${(props) => props.theme.analyze.manualAdjustment.panelsColor};
    cursor: pointer;
    svg {
      fill: ${globalColors.slate3};
    }
  }
`;

export const SitePanel = ({ isOverviewLoading, siteData }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const [panelHeightTriggered, triggerPanelHeight] = useState(false);

  const { selectedSite, handleSiteExpand } = useManualAdjustmentContext();

  const panelExpanded = useMemo(() => {
    return selectedSite && selectedSite.id === siteData?.site.id;
  }, [selectedSite, siteData]);

  if (!ready || isOverviewLoading) {
    return <Loader />;
  }

  return (
    <CACollapsiblePanel
      headerContent={
        <SitePanelHeader
          site={siteData?.site}
          assetCount={siteData?.assetCount}
          title={t('lowest_performer', 'Lowest Performer')}
          lowestPerformer={siteData?.lowestPerformer}
        />
      }
      triggerHeight={panelHeightTriggered}
      onToggleExpand={() => handleSiteExpand(siteData?.site)}
      expanded={panelExpanded}
    >
      {panelExpanded ? (
        <SitePanelAssets
          isOverviewLoading={isOverviewLoading}
          siteData={siteData}
          triggerHeight={triggerPanelHeight}
        />
      ) : (
        <div></div>
      )}
    </CACollapsiblePanel>
  );
};

SitePanel.propTypes = {
  isOverviewLoading: PropTypes.bool,
  siteData: PropTypes.object.isRequired,
};
