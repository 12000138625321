import { thunk, action } from 'easy-peasy';

import {
  AttributeGroups,
  DefaultRoleType,
  PersonAssetWorkerTab,
  TabFlowModelVariables,
} from '@ge/models/constants';
//documentation url https://easy-peasy.vercel.app/
//How to use any store values and methods
//1. import { useStoreState,useStoreActions } from 'easy-peasy';
//2  const tabflow = useStoreState((state) => state.tabflow);       //to get current object states
//   console.log(tabflow) // will show example {currentTab: 'example Tab'}

//3. const {changeTab} = useStoreActions((actions) => actions.tabflow);// get partucular action method with current name changeTab, you can use it with changeTab('new Value').
//  const check = useStoreActions((actions) => actions.tabflow);        //check all actions , will show object with different actions
//  console.log(check,'current methods')    //check all actions , will show object with different actions

//import { fetchAssetTickets } from '../services/asset';//examples of http requests
//import { fetchEventTickets } from '../services/event';//examples of http requests
//import { fetchTicketById } from '../services/ticket';//examples of http requests
//import { groupByEventId } from '../util/general';//examples of http requests
// Define initial state
let currentState = {
  providedTab: null,
  personAssets: {
    role: TabFlowModelVariables.ROLESTYPE.USER,
    activeTab: 0,
    editStatus: false,

    list: [], //group array edit mode
    tempList: [], //temporary group whole array reserv edit mode
    tempList_Limited: [], //temporary storing group whole infinite array
    onlyIdtempList: [], //temporary group whole id array

    viewList: [], // group array view mode
    tempViewList: [], //temp group array view mode
    tempViewList_Limited: [], //temporary storing group whole infinite array

    siteList: [], //site array edit mode
    tempSiteList: [], //temporary whole site array
    tempSiteList_Limited: [], //temporary whole site infinite array
    onlyIdtempSiteList: [], //temporary whole id site array

    siteViewList: [], //site array view mode
    tempsiteViewList: [], //temp site whole array view mode
    tempsiteViewList_Limited: [], //temp site whole infinite array view mode
    filterByServiceGroup: '',
    filterBySite: '',
    selectedServiceGroupIds: [],
    selectedSiteIds: [],
    existingServiceGroupIds: [],
    existingSiteIds: [],
    firstLoad: false,
    limitedSiteList: false,
    limitedServiceGroups: false,
    dropdownItems: [],
    ids: [],
    disabledTab: false,
    providedAttributes: {},
    tabIterator: [],
    person: {},
    loaderStatus: true,
    viewByServiceGroupsOrSites: TabFlowModelVariables.VIEWBY.GROUPS,
    checkedClassName: 'PersonAssets_squaredThree',
    uncheckedClassName: 'PersonAssets_squaredThreeUnchecked',
    deletedRoles: [],
  },
};
const tabFlowActions = {
  //actions
  setProvidedTab: action((state, payload) => {
    state.providedTab = payload;
  }),
  setDropdownItems: action((state, payload) => {
    state.personAssets.dropdownItems = payload;
  }),
  setDisableTab: action((state, payload) => {
    state.personAssets.disabledTab = payload;
  }),
  setLoader: action((state, payload) => {
    state.personAssets.loaderStatus = payload;
  }),
  setAutoLoader: thunk((actions) => {
    actions.setLoader(true);
  }),

  setDefaultTab: action((state) => {
    state.personAssets.activeTab = 0;
  }),

  refreshIds: action((state) => {
    state.personAssets.ids = [];
    state.providedTab = null;
    state.personAssets.activeTab = 0;
    state.personAssets.firstLoad = false;
    state.personAssets.editStatus = false;
    state.personAssets.loaderStatus = true;
    state.personAssets.tabIterator = [];
    state.personAssets.viewByServiceGroupsOrSites = TabFlowModelVariables.VIEWBY.GROUPS;
  }),

  setPersonInStore: thunk(async (actions, payload) => {
    actions.setDefaultTab();
    actions.refreshIds();
    actions.setPerson(payload.fetchedPerson);
    actions.checkRoles(payload.mappedLimitedRoles);
  }),
  setPerson: action((state, payload) => {
    state.personAssets.person = payload;
    state.personAssets.loaderStatus = true;
  }),
  setProvidedAttributesInStore: thunk((actions, payload, { getState }) => {
    const action = payload.action;
    const actionValue = payload.value;
    const state = getState();
    let currentIds = [...state.personAssets.ids];

    if (action === 'add') {
      //adding person

      if (actionValue != 'New_User_Role') {
        const newGroupCombination = [
          {
            groupName: AttributeGroups.SITES,
            ids: [],
          },
          {
            groupName: AttributeGroups.SERVICE_GROUP,
            ids: [],
          },
        ];
        currentIds.push({
          role: { roleName: actionValue, groupCombination: newGroupCombination },
          roleNameStatus: 0,
        });
      }

      actions.changeState({
        name: TabFlowModelVariables.ACTIVETAB,
        value: currentIds.length - 1,
      });
      let deletedIds = state.personAssets.deletedRoles.filter((item) => item !== actionValue);
      actions.setRemovedRoles(deletedIds);
    } else if (action === 'delete') {
      //deleting person from store
      currentIds = currentIds.filter((eachRole) => eachRole.role.roleName !== actionValue);
      actions.changeState({ name: TabFlowModelVariables.ACTIVETAB, value: 0 });
      let deletedIds = [...state.personAssets.deletedRoles, actionValue];
      actions.setRemovedRoles(deletedIds);
    }
    actions.changeState({ name: TabFlowModelVariables.IDS, value: currentIds });
  }),
  filterBySearchValue: thunk(async (actions, payload) => {
    actions.filterByListValue(payload);
  }),
  filterByListValue: action((state, payload) => {
    let { name, searchValue } = payload;
    if (name === TabFlowModelVariables.VIEWBY.GROUPS) {
      state.personAssets.filterByServiceGroup = searchValue;
    } else if (name === TabFlowModelVariables.VIEWBY.SITES) {
      state.personAssets.filterBySite = searchValue;
    } else {
      state.personAssets.filterBySite = '';
      state.personAssets.filterByServiceGroup = '';
    }
  }),
  setRemovedRoles: action((state, payload) => {
    state.personAssets.deletedRoles = payload;
  }),
  changeState: action((state, payload) => {
    switch (payload.name) {
      case TabFlowModelVariables.ROLE:
        state.personAssets.role = payload.value;
        break;
      case TabFlowModelVariables.ACTIVETAB:
        state.personAssets.activeTab = payload.value;
        break;
      case TabFlowModelVariables.EDITSTATUS:
        state.personAssets.editStatus = payload.value;
        break;
      case TabFlowModelVariables.LIST:
        state.personAssets.tempList = payload.value;
        state.personAssets.tempViewList = payload.value;
        break;
      case TabFlowModelVariables.SITELIST:
        state.personAssets.tempSiteList = payload.value;
        state.personAssets.tempsiteViewList = payload.value;
        break;
      case TabFlowModelVariables.TABITERATOR:
        state.personAssets.tabIterator = payload.value;
        break;
      case TabFlowModelVariables.VIEWBYSERVICEGROUPORSITES:
        state.personAssets.filterByServiceGroup = '';
        state.personAssets.filterBySite = '';
        state.personAssets.viewByServiceGroupsOrSites = payload.value;
        break;
      case TabFlowModelVariables.IDS:
        state.personAssets.ids = payload.value;
        break;

      default:
        // console.log('please check correct case name associated with object tabFlowModel.js');
        break;
    }
  }),
  editMultiplePermission: thunk(async (actions, payload, { getState }) => {
    let state = getState();
    let { permission, currentState, newId } = payload;
    let ids = [...state.personAssets.ids];
    ids = ids.map((eachItem, index) => {
      if (permission.idsIndex === index) {
        eachItem.role.groupCombination.map((eachGroup) => {
          if (eachGroup.groupName === TabFlowModelVariables.VIEWBY.SITES) {
            let isIdExists = newId.filter((eachSiteId) => eachGroup.ids.includes(eachSiteId));
            if (isIdExists.length && !currentState) {
              if (eachGroup.delete) {
                eachGroup.delete = [...eachGroup.delete, ...isIdExists];
              } else {
                eachGroup.delete = [...isIdExists];
              }
            }
          }
          return eachGroup;
        });
        return eachItem;
      }
      return eachItem;
    });
    actions.changeState({ name: TabFlowModelVariables.IDS, value: ids });
  }),
  editPermission: thunk(async (actions, payload, { getState }) => {
    let state = getState();
    let { permission, currentState, newId } = payload;
    let ids = [...state.personAssets.ids];
    let typeOfRecord =
      permission.viewBy == TabFlowModelVariables.VIEWBY.GROUPS
        ? AttributeGroups.SERVICE_GROUP
        : TabFlowModelVariables.VIEWBY.SITES; //determine do we need to edit service_group record or sites
    ids = ids.map((eachItem, index) => {
      if (permission.idsIndex === index) {
        eachItem.role.groupCombination.map((eachGroup) => {
          if (eachGroup.groupName === typeOfRecord) {
            let isIdExists = eachGroup.ids.find((eachId) => eachId === newId);
            if (!isIdExists && currentState) {
              if (eachGroup.add) {
                eachGroup.add.push(newId);
              } else {
                eachGroup.add = [newId];
              }
            }
            if (isIdExists && currentState && eachGroup.delete?.length) {
              eachGroup.delete = eachGroup.delete.filter((groupId) => groupId !== newId);
            }
            if (isIdExists && !currentState) {
              if (eachGroup.delete) {
                eachGroup.delete.push(newId);
              } else {
                eachGroup.delete = [newId];
              }
            }
            if (!isIdExists && !currentState && eachGroup.add) {
              eachGroup.add = eachGroup.add.filter((groupId) => groupId !== newId);
            }
          }
          return eachGroup;
        });
        return eachItem;
      }
      return eachItem;
    });
    actions.changeState({ name: TabFlowModelVariables.IDS, value: ids });
  }),

  setCheckBox: thunk(async (actions, payload, { getState }) => {
    const { permission, id, currentState, siteIds } = payload;
    const state = getState();
    if (permission.viewBy == TabFlowModelVariables.VIEWBY.GROUPS) {
      if (!currentState) {
        state.personAssets.selectedServiceGroupIds = state.personAssets.selectedServiceGroupIds.filter(
          (groupId) => groupId !== id,
        );
        if (siteIds && siteIds.length) {
          state.personAssets.selectedSiteIds = state.personAssets.selectedSiteIds.filter(
            (siteId) => {
              return !siteIds.includes(siteId);
            },
          );
          actions.editMultiplePermission({
            permission: {
              idsIndex: permission.idsIndex,
              viewBy: TabFlowModelVariables.VIEWBY.SITES,
            },
            currentState,
            newId: siteIds,
          });
        }
      } else if (currentState) {
        state.personAssets.selectedServiceGroupIds = [
          ...state.personAssets.selectedServiceGroupIds,
          id,
        ];
      }
      actions.editPermission({
        permission: permission,
        currentState,
        newId: id,
      });
    } else if (permission.viewBy == TabFlowModelVariables.VIEWBY.SITES) {
      let selectedSiteIds = state.personAssets.selectedSiteIds;
      if (!currentState) {
        state.personAssets.selectedSiteIds = selectedSiteIds.filter((siteId) => siteId !== id);
      } else if (currentState) {
        state.personAssets.selectedSiteIds = [...state.personAssets.selectedSiteIds, id];
      }
      actions.editPermission({
        permission: permission,
        currentState,
        newId: id,
      });
    }
  }),

  onChangeTabHandler: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    let tabIteratorItems = [...state.personAssets.tabIterator];
    state.personAssets.selectedSiteIds = [];
    state.personAssets.selectedServiceGroupIds = [];
    for (let i = 0; i < tabIteratorItems.length; i++) {
      let name = convertString(tabIteratorItems[i].toLowerCase());
      let payloadName = payload.toLowerCase();
      if (name == payloadName) {
        actions.changeState({ name: TabFlowModelVariables.ACTIVETAB, value: i });
        break;
      }
    }
  }),

  addPersonData: thunk((actions, payload, { getState }) => {
    actions.setAutoLoader();
    const state = getState();
    const ids = state.personAssets.ids;
    let body = [];
    let deletedRoles = state.personAssets.person.attributes?.entity_group?.filter((attribute) =>
      state.personAssets.deletedRoles.includes(attribute.roleName),
    );
    if (deletedRoles && deletedRoles.length) {
      deletedRoles.forEach((eachRole) => {
        let item = {
          path: '/attributes/entity_group',
          value: eachRole.roleName,
          op: 'REMOVE',
        };
        body.push(item); //push role
      });
    }
    ids.forEach((eachRole) => {
      //check if it's new role
      if (eachRole.roleNameStatus === 0) {
        let item = {
          path: '/attributes/entity_group',
          value: eachRole.role.roleName,
          op: 'ADD',
        };
        body.push(item); //push role
      }

      eachRole.role.groupCombination.forEach((eachGroup) => {
        //check group name
        if (eachGroup.groupName == TabFlowModelVariables.VIEWBY.SITES) {
          //check if ADD action avalaible
          if (eachGroup?.add?.length) {
            let siteItem = {
              path:
                '/attributes/entity_group/' +
                eachRole.role.roleName +
                '/groupCombination/sites/ids',
              value: String(eachGroup.add),
              op: 'ADD',
            };
            body.push(siteItem);
          }
          if (eachGroup?.delete?.length > 0) {
            let siteItem = {
              path:
                '/attributes/entity_group/' +
                eachRole.role.roleName +
                '/groupCombination/sites/ids',
              value: String(eachGroup.delete),
              op: 'REMOVE',
            };
            body.push(siteItem);
          }
        }

        if (eachGroup.groupName == AttributeGroups.SERVICE_GROUP) {
          //check if user not worker => User
          if (eachRole.role.roleName != PersonAssetWorkerTab.ASSETS_FOR_WORKER) {
            if (eachGroup?.add?.length) {
              let siteItem = {
                path:
                  '/attributes/entity_group/' +
                  eachRole.role.roleName +
                  '/groupCombination/service_group/ids',
                value: String(eachGroup.add),
                op: 'ADD',
              };
              body.push(siteItem);
            }

            if (eachGroup?.delete?.length) {
              let siteItem = {
                path:
                  '/attributes/entity_group/' +
                  eachRole.role.roleName +
                  '/groupCombination/service_group/ids',
                value: String(eachGroup.delete),
                op: 'REMOVE',
              };
              body.push(siteItem);
            }
          } else {
            //adding groups to worker =>  ADD in Worker Service Group
            if (eachGroup?.add?.length) {
              let siteItem = {
                path: '/attributes/worker_service_group/groupCombination/service_group/ids',
                value: String(eachGroup.add),
                op: 'ADD',
              };
              body.push(siteItem);
            }
            //REMOVE in Worker Service Group
            if (eachGroup?.delete?.length) {
              let siteItem = {
                path: '/attributes/worker_service_group/groupCombination/service_group/ids',
                value: String(eachGroup.delete),
                op: 'REMOVE',
              };
              body.push(siteItem);
            }
          }
        }
      });
    });
    if (body.length > 0) return body;
    return false;
  }),

  cancelActions: thunk(async (actions, payload, { getState }) => {
    const state = getState();

    let checkEditStatus = false;
    let ids = state.personAssets.ids.map((eachRole) => {
      eachRole.role.groupCombination.map((eachGroup) => {
        if (eachGroup.add) {
          checkEditStatus = true;
          delete eachGroup.add;
        }
        if (eachGroup.delete) {
          checkEditStatus = true;
          delete eachGroup.delete;
        }
        return eachGroup;
      });
      return eachRole;
    });

    if (checkEditStatus) {
      actions.changeState({ name: TabFlowModelVariables.IDS, value: ids });
      actions.loadChanges('save');
    }
  }),
  // eslint-disable-next-line prettier/prettier
  checkRoles: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    const type = state.personAssets.person.type;
    let ids = state.personAssets.ids;
    const personDetail = state.personAssets.person;

    if (
      type.includes(TabFlowModelVariables.ROLESTYPE.USER) &&
      !type.includes(TabFlowModelVariables.ROLESTYPE.WORKER)
    ) {
      actions.changeState({
        name: TabFlowModelVariables.ROLE,
        value: TabFlowModelVariables.ROLESTYPE.USER,
      });
      //incoming updating data

      const checkIds = getUserPermissions(personDetail, ids, payload);
      if (checkIds) {
        ids = checkIds;

        actions.changeState({ name: TabFlowModelVariables.IDS, value: ids });
      }
    } else if (
      type.includes(TabFlowModelVariables.ROLESTYPE.USER) &&
      type.includes(TabFlowModelVariables.ROLESTYPE.WORKER)
    ) {
      //get worker roles
      actions.changeState({
        name: TabFlowModelVariables.ROLE,
        value: TabFlowModelVariables.ROLESTYPE.USER_AND_WORKER,
      });
      //service_group

      const getWorkerIds = getWorkerPermissions(personDetail, ids);

      if (getWorkerIds) {
        ids = getWorkerIds;
      }

      const checkUserIds = getUserPermissions(personDetail, ids, payload);
      if (checkUserIds) {
        ids = checkUserIds;
      }

      actions.changeState({ name: TabFlowModelVariables.IDS, value: ids });

      //get worker roles
    } else if (type.includes(TabFlowModelVariables.ROLESTYPE.WORKER)) {
      actions.changeState({
        name: TabFlowModelVariables.ROLE,
        value: TabFlowModelVariables.ROLESTYPE.WORKER,
      });
      //service_group
      const getWorkerIds = getCleanWorkerPermissions(personDetail, ids);

      if (getWorkerIds) {
        ids = getWorkerIds;
      }

      actions.changeState({ name: TabFlowModelVariables.IDS, value: ids });
    }
  }),

  loadChanges: thunk((actions, payload, { getState }) => {
    let state = getState();
    state.personAssets.loaderStatus = false;
    actions.setLoader(false);
    state.personAssets.filterByServiceGroup = '';
    state.personAssets.filterBySite = '';
    const ids = state.personAssets.ids;
    const activeTab = state.personAssets.activeTab;
    if (ids.length) {
      let selectedRole = ids.filter((eachRole, index) => {
        return activeTab === index;
      });
      let groupCombination = selectedRole[0].role.groupCombination;
      let filteredSites = groupCombination.filter((eachGroup) => {
        return eachGroup.groupName === TabFlowModelVariables.VIEWBY.SITES;
      });
      let siteIds = filteredSites[0]?.ids ?? [];
      let addedSites = filteredSites[0]?.add ?? [];
      let deletedSites = filteredSites[0]?.delete ?? [];
      siteIds = siteIds.filter((item) => !deletedSites.includes(item));
      state.personAssets.existingSiteIds = filteredSites[0]?.ids ?? [];
      let filteredGroups = groupCombination.filter((eachGroup) => {
        return eachGroup.groupName === AttributeGroups.SERVICE_GROUP;
      });
      let groupIds = filteredGroups[0]?.ids ?? [];
      state.personAssets.existingServiceGroupIds = filteredGroups[0]?.ids ?? [];
      let addedGroups = filteredGroups[0]?.add ?? [];
      let deletedGroups = filteredGroups[0]?.delete ?? [];
      groupIds = groupIds.filter((item) => !deletedGroups.includes(item));
      state.personAssets.selectedSiteIds = [...siteIds, ...addedSites];
      state.personAssets.selectedServiceGroupIds = [...groupIds, ...addedGroups];
      if (selectedRole[0].role.roleName === PersonAssetWorkerTab.ASSETS_FOR_WORKER) {
        actions.changeState({
          name: TabFlowModelVariables.VIEWBYSERVICEGROUPORSITES,
          value: TabFlowModelVariables.VIEWBY.GROUPS,
        });
      }
      let rolesNames = ids.map((eachRole) => eachRole.role.roleName);
      actions.changeState({ name: TabFlowModelVariables.TABITERATOR, value: rolesNames });
    }
  }),
};
const getUserPermissions = (personDetail, ids, limitedUserRoles) => {
  const entity_groups = personDetail.attributes?.entity_group;
  let currentIds = [];
  if (entity_groups) {
    const entity_groups = personDetail.attributes.entity_group;
    currentIds = entity_groups
      .filter((attribute) => {
        return (
          attribute.roleName !== DefaultRoleType.MONITOR_ADMIN &&
          attribute.roleName !== DefaultRoleType.ANALYTICS_VIEW &&
          attribute.roleName !== DefaultRoleType.MONITOR_VIEW &&
          limitedUserRoles?.some((item) => item.a11yKey === attribute.roleName)
        );
      })
      .map((roleDetails) => {
        let newGroupCombination = [
          {
            groupName: AttributeGroups.SITES,
            ids: [],
          },
          {
            groupName: AttributeGroups.SERVICE_GROUP,
            ids: [],
          },
        ];
        if (roleDetails.groupCombination.length) {
          newGroupCombination = newGroupCombination.map((eachGroup) => {
            let isGroupExists = roleDetails.groupCombination.filter(
              (group) => group.groupName === eachGroup.groupName,
            );
            if (isGroupExists.length) {
              eachGroup.groupName = isGroupExists[0].groupName;
              eachGroup.ids = isGroupExists[0].ids ? isGroupExists[0].ids : [];
            }
            return eachGroup;
          });
        }
        return {
          role: { roleName: roleDetails.roleName, groupCombination: newGroupCombination },
          roleNameStatus: 1,
        };
      });
    return [...ids, ...currentIds];
  } else {
    return false;
  }
};

const getWorkerPermissions = (personDetail, ids) => {
  const checkWorkerServiceGroup = personDetail.attributes?.worker_service_group;
  const newGroupCombination = [
    {
      groupName: AttributeGroups.SERVICE_GROUP,
      ids: [],
    },
  ];
  if (checkWorkerServiceGroup) {
    const workerServiceGroup = checkWorkerServiceGroup[0].groupCombination[0].groupName;
    if (workerServiceGroup === AttributeGroups.SERVICE_GROUP) {
      let groupCombination = checkWorkerServiceGroup[0].groupCombination.length
        ? checkWorkerServiceGroup[0].groupCombination
        : newGroupCombination;
      ids.push({
        role: {
          roleName: PersonAssetWorkerTab.ASSETS_FOR_WORKER,
          groupCombination: groupCombination,
        },
        roleNameStatus: 1,
      });
      return ids;
    } else {
      return false;
    }
  } else {
    ids.push({
      role: {
        roleName: PersonAssetWorkerTab.ASSETS_FOR_WORKER,
        groupCombination: newGroupCombination,
      },
      roleNameStatus: 1,
    });
    return ids;
  }
};
const getCleanWorkerPermissions = (personDetail, ids) => {
  const checkWorkerServiceGroup = personDetail.attributes?.worker_service_group;
  const newGroupCombination = [
    {
      groupName: AttributeGroups.SERVICE_GROUP,
      ids: [],
    },
  ];
  if (checkWorkerServiceGroup) {
    const workerServiceGroup = checkWorkerServiceGroup[0].groupCombination[0].groupName;
    if (workerServiceGroup === AttributeGroups.SERVICE_GROUP) {
      let groupCombination = checkWorkerServiceGroup[0].groupCombination.length
        ? checkWorkerServiceGroup[0].groupCombination
        : newGroupCombination;
      ids.push({
        role: {
          roleName: PersonAssetWorkerTab.ASSETS_FOR_WORKER,
          groupCombination: groupCombination,
        },
        roleNameStatus: 1,
      });
    }

    return ids;
  } else {
    ids.push({
      role: {
        roleName: PersonAssetWorkerTab.ASSETS_FOR_WORKER,
        groupCombination: newGroupCombination,
      },
      roleNameStatus: 1,
    });
    return ids;
  }
};

const convertString = (item) => {
  let newString = '';
  for (let i = 0; i < item.length; i++) {
    if (item[i] === ' ') {
      newString += '-';
    } else if (item[i] === '_') {
      newString += '-';
    } else {
      newString += item[i];
    }
  }
  return newString;
};

const tabFlowModel = {
  ...currentState,
  ...tabFlowActions,
};

export default tabFlowModel;
