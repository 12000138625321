import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { TooltipCell } from '@ge/components/table/table';
import { AssetConnectionStatus } from '@ge/models/constants';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

const MOCK_AVAILABILITY_DATA = [
  { id: 1, status: 'good', duration: 288 },
  { id: 2, status: 'error', duration: 346 },
  { id: 3, status: 'good', duration: 432 },
  { id: 4, status: 'warn', duration: 230 },
  { id: 5, status: 'good', duration: 1440 },
  { id: 6, status: 'error', duration: 144 },
];

const Status = styled.section``;
const StatusBarTitle = styled.div`
  border-right: 2px solid ${({ theme }) => theme.entityDetails.statusBar.titleBorderColor};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 4px;
  h3 {
    margin-right: auto;
    text-align: left;
  }
  p {
    margin: 0;
    margin-right: 4px;
    text-align: right;
    text-transform: uppercase;
  }
  > * {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 2px;
  }
`;
const StatusBar = styled.div`
  align-items: stretch;
  border-radius: 2px;
  display: flex;
  flex-flow: row nowrap;
  height: 8px;
  justify-content: stretch;
  overflow: hidden;
  width: 100%;
  > * {
    margin: 0;
    padding: 0;
    + * {
      margin-left: 2px;
    }
  }
`;

const last48InMinutes = 48 * 60;

const getDurationPercentage = (duration) =>
  Math.round(((duration || last48InMinutes) / last48InMinutes) * 100);

const StatusBarSegment = styled.div`
  background-color: ${({ theme, status }) =>
    ({
      good: theme.entityDetails.statusBar.goodColor,
      warn: theme.entityDetails.statusBar.warnColor,
      error: theme.entityDetails.statusBar.errorColor,
    }[status])};
  flex: 1 1 ${({ duration }) => getDurationPercentage(duration)}%;
`;

StatusBarSegment.propTypes = {
  status: PropTypes.oneOf(['good', 'warn', 'error']).isRequired,
  duration: PropTypes.number.isRequired,
};

StatusBarSegment.defaultProps = {
  status: 'good',
  duration: last48InMinutes,
};

const Main = styled.section`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: stretch;
`;

const Readings = styled.section`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`;

// TODO: confirm if dl is the best element to use here in terms of semantics, etc.
const ReadingsCol = styled.dl`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  height: 100%;

  line-height: 20px;
`;

const ReadingsColLeft = styled(ReadingsCol)`
  flex-basis: 50%;
  div:first-child {
    dt {
      margin-left: 20px;
    }
  }
  div {
    dt {
      flex: 1 1 48%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    dd {
      flex: 1 1 45%;
    }
  }
`;

const ReadingsColRight = styled(ReadingsCol)`
  flex-basis: 50%;
  div:first-child {
    dt {
      margin-left: 15px;
    }
  }
  div {
    dt {
      flex: 1 1 45%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    dd {
      flex: 1 1 20%;
    }
  }
`;

const ReadingsRow = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  + div {
    margin-left: 20px;
  }
  dt {
    color: ${({ theme }) => theme.entityDetails.readings.readingHeaderColor};
    font-size: 12px;
    text-align: right;
  }
  dd {
    color: ${({ theme }) => theme.entityDetails.readings.readingValueColor};
    font-size: 12px;
    font-weight: ${typography.weight.light};
    margin-left: 16px;
    text-align: left;
    opacity: ${(props) => (props.isDisconnected ? 0.3 : 1)};
  }
`;

// do we need to round a particular way here?
const formatReadingValue = (val, decimals) => {
  const formatted = Number(val).toFixed(decimals ? decimals : 1);

  return Number.isNaN(formatted) ? undefined : formatted;
};

// TODO: would be nice to get units for readings from backend also review units used in wireframe
export const SensorReadingsList = ({ asset }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  if (!ready || !asset.metrics?.readings) {
    return null;
  }

  const { readings, state, connection } = asset.metrics || {};
  const isDisconnected = connection?.value === AssetConnectionStatus.NOCOMM;
  const { signals } = readings;
  const leftSignals = signals?.slice(0, Math.floor(signals.length / 2));
  const rightSignals = signals?.slice(Math.floor(signals.length / 2));

  const availability = MOCK_AVAILABILITY_DATA;
  // TODO: wire up command and action handlers

  return readings ? (
    <Main>
      {/* Hiding for MVP0 - Story #494 - Remove ConditionalRender component */}
      <ConditionalRender shouldRender={false}>
        <Status>
          <StatusBarTitle>
            <h3>{t('readings_entity.status-last-48-hours', 'STATUS LAST 48 HOURS')}</h3>
            <p>{state}</p>
          </StatusBarTitle>
          <StatusBar>
            {availability.map(({ id, status, duration }) => (
              <StatusBarSegment key={id} status={status} duration={duration} />
            ))}
          </StatusBar>
        </Status>
      </ConditionalRender>
      <Readings>
        <ReadingsColLeft>
          {leftSignals?.map((s) => {
            return (
              <ReadingsRow key={s.id} isDisconnected={isDisconnected}>
                <dt>
                  <TooltipCell
                    tooltip={t(`readings_entity.${s.labelId}`, s.desc)}
                    zIndex={elevations.P20}
                  >
                    {t(`readings_entity.${s.labelId}`, s.desc)}
                  </TooltipCell>
                </dt>

                <dd>
                  {`${formatReadingValue(s.value, s.decimals)}` || '—'} {s.uom}
                </dd>
              </ReadingsRow>
            );
          })}
        </ReadingsColLeft>
        <ReadingsColRight>
          {rightSignals?.map((s) => {
            return (
              <ReadingsRow key={s.id} isDisconnected={isDisconnected}>
                <dt>
                  <TooltipCell
                    tooltip={t(`readings_entity.${s.labelId}`, s.desc)}
                    zIndex={elevations.P20}
                  >
                    {t(`readings_entity.${s.labelId}`, s.desc)}
                  </TooltipCell>
                </dt>

                <dd>
                  {`${formatReadingValue(s.value, s.decimals)}` || '—'} {s.uom}
                </dd>
              </ReadingsRow>
            );
          })}
        </ReadingsColRight>
      </Readings>
    </Main>
  ) : (
    {
      /* add view for when readings aren't available or still loading? */
    }
  );
};

SensorReadingsList.propTypes = {
  asset: PropTypes.shape({
    metrics: PropTypes.object,
  }).isRequired,
};

export default SensorReadingsList;
