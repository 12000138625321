import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks/config';

import { fetchSensorData } from '../services/fp';

export const useCaseSensorData = (alert) => {
  const { start, FPObjectId: objectId, FPDetectionId: detectionId } = alert ?? {};

  return useQuery(
    [QueryKey.CASE_SENSOR_DATA, { objectId, detectionId }],
    () => fetchSensorData({ objectId, detectionId }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(objectId && detectionId && start),
      retry: false,
    },
  );
};
