import { PropTypes } from 'prop-types';
import React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Capability, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

export const AuthRender = ({
  // scope
  admin,
  create,
  delete: _delete,
  edit,
  view,

  // content
  children,
  fallback,

  // context
  // providing option to pass in single capability or multiple
  // single is more streamlined but if multiple needed can pass that in instead
  capabilities: _capabilities,
  capability,
  description,
  enforceView,
  siteIds,
  siteLevel,
  authStrategy,
}) => {
  // state
  const [authorized, setAuthorized] = useState(false);

  // data hooks
  const { audit } = useAuth();

  const component = children?.type?.displayName ?? children?.type?.name;

  // memoize so we don't re-authorize on every render
  // is this more performant than just checking auth on every render?
  const capabilities = useMemo(() => {
    if (_capabilities?.length) {
      return _capabilities;
    }

    const scopes = [
      admin ? PermissionScope.ADMIN : undefined,
      create ? PermissionScope.CREATE : undefined,
      _delete ? PermissionScope.DELETE : undefined,
      edit ? PermissionScope.EDIT : undefined,
      view ? PermissionScope.VIEW : undefined,
    ].filter(Boolean);

    return [{ capability, scopes }];
  }, [admin, _capabilities, capability, create, _delete, edit, view]);

  // check authorization
  useEffect(() => {
    if (siteLevel && !siteIds?.length) {
      setAuthorized(false);

      return;
    }

    const _authorized = audit({
      capabilities,
      description,
      enforceView,
      siteIds,
      source: component,
      type: 'Component render blocked',
      authStrategy,
    });

    setAuthorized(_authorized);
  }, [audit, capabilities, component, description, enforceView, siteIds, siteLevel, authStrategy]);

  // not permitted to render
  if (!authorized) {
    return fallback;
  }

  return children;
};

AuthRender.propTypes = {
  admin: PropTypes.bool,
  capabilities: PropTypes.arrayOf(
    PropTypes.shape({
      capability: PropTypes.object,
      scopes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PermissionScope))),
    }),
  ),
  capability: PropTypes.oneOf(Object.values(Capability)),
  children: PropTypes.node,
  create: PropTypes.bool,
  delete: PropTypes.bool,
  description: PropTypes.string,
  edit: PropTypes.bool,
  // get permissions based on sites in view
  enforceView: PropTypes.bool,
  fallback: PropTypes.node,
  siteIds: PropTypes.arrayOf(PropTypes.string),
  // we use this to enforce authorizing with a site id and not bypassing site-level if site id is undefined
  // which might be due to a timing issue as data is loading
  siteLevel: PropTypes.bool,
  view: PropTypes.bool,
  authStrategy: PropTypes.string,
};

AuthRender.defaultProps = {
  children: <></>,
  analyze: false,
  capabilities: undefined,
  capability: undefined,
  create: false,
  delete: false,
  description: undefined,
  edit: false,
  enforceView: false,
  fallback: <></>,
  siteIds: undefined,
  siteLevel: true,
  view: false,
};
