import { useStoreState } from 'easy-peasy';
import { useCallback, useMemo } from 'react';

import { EntityAll, EntityType, PersonAssetWorkerTab } from '@ge/models/constants';

export const useEntitiesFiltered = (entityType, filterText, selectedRole) => {
  const serviceGroupsSites = useStoreState((state) => state.sites.getViewServiceGroupsSites);
  const allSites = useStoreState((state) => state.sites.sitesForView);
  const { hasLimitedUserManagement, limitedUserRoles, workerLimitedEntities, workerLimitedSites } =
    useStoreState((state) => state.userRole);
  const sortedSites = allSites
    .map((site) => {
      return {
        id: site.id,
        name: site.name,
        serviceGroups: site.serviceGroups,
        assetCount:
          site.turbineTypes && site.turbineTypes.length ? site.turbineTypes[0].modelCount : null,
      };
    })
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const filterEntitiesByLimitedAccess = useCallback(
    (entities, entityType) => {
      if (hasLimitedUserManagement) {
        let selectedRoleDetails = limitedUserRoles?.find(
          (role) =>
            role.a11yKey?.replace(/[_-]/g, ' ').toLowerCase() ===
            selectedRole?.replace(/[_-]/g, ' ').toLowerCase(),
        );
        if (
          selectedRole?.toLowerCase() === PersonAssetWorkerTab.ASSETS_FOR_WORKER.toLowerCase() &&
          !selectedRoleDetails &&
          workerLimitedSites?.indexOf(EntityAll.ALL) === -1
        ) {
          return entities.filter((entity) => {
            return workerLimitedEntities.includes(entity.id);
          });
        }
        if (
          EntityType.SERVICE_GROUP === entityType &&
          selectedRoleDetails &&
          selectedRoleDetails.limitedSites?.indexOf(EntityAll.ALL) === -1
        ) {
          return entities.filter((entity) => {
            return selectedRoleDetails.limitedServiceGroups.includes(entity.id);
          });
        }
        if (
          EntityType.SITE === entityType &&
          selectedRoleDetails &&
          selectedRoleDetails.limitedSites?.indexOf(EntityAll.ALL) === -1
        ) {
          return entities.filter((entity) => selectedRoleDetails.limitedSites.includes(entity.id));
        }
      }
      return entities;
    },
    [
      hasLimitedUserManagement,
      limitedUserRoles,
      selectedRole,
      workerLimitedEntities,
      workerLimitedSites,
    ],
  );
  const entitiesFiltered = useMemo(() => {
    // can't test this yet as there are no service groups coming back from the service
    if (EntityType.SERVICE_GROUP === entityType) {
      let serviceGroupList = filterEntitiesByLimitedAccess(serviceGroupsSites, entityType);
      return filterText.length < 2
        ? serviceGroupList
        : serviceGroupList
            .map((serviceGroup) => {
              return {
                ...serviceGroup,
                sites: serviceGroup.sites.filter((site) =>
                  site.name?.toLowerCase().includes(filterText),
                ),
              };
            })
            .filter((serviceGroup) => serviceGroup.sites.length !== 0);
    } else {
      let siteGroupList = filterEntitiesByLimitedAccess(sortedSites, entityType);
      return filterText.length < 2
        ? siteGroupList
        : siteGroupList.flat().filter((site) => site.name?.toLowerCase().includes(filterText));
    }
  }, [sortedSites, entityType, filterText, serviceGroupsSites, filterEntitiesByLimitedAccess]);

  return { entitiesFiltered };
};
