import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';

import { QueryKey, DispStatus, CalendarRanges } from '@ge/models/constants';

import {
  dispatchToMTM,
  getDispatchDetails,
  patchDispatch,
} from '../../../features/manage/services/dispatch';

const NO_CONTENT = { status: DispStatus.NO_CONTENT };
const REAL_TIME_DATA_REFRESH = 5000;
const DISP_TIME_DATA_REFRESH = 5000 * 60;
//Post API Hook

const handleOnSuccess = (onSuccess, data, status) => {
  let dispData = Array.isArray(data) && data.length === 0 ? NO_CONTENT : data;
  onSuccess(dispData, status);
};

export const useDispatch = ({ onSuccess, onError }) => {
  const { mutate, isLoading, data } = useMutation((postPayload) => dispatchToMTM(postPayload), {
    onSuccess: (disData) => handleOnSuccess(onSuccess, disData, true),
    onError: (error) => {
      error.response && onError(error.response.data);
      console.log(`Dispatched was failed due to: ${error}`);
    },
  });

  return useMemo(
    () => ({
      mutate,
      isLoading,
      data: data || {},
    }),
    [mutate, isLoading, data],
  );
};

//Get API Hook
export const GetDispatch = ({
  onSuccess,
  dispatchDate,
  serviceGroup,
  timeZone,
  refetchDispatch,
  onError,
  range,
}) => {
  const params = {
    serviceGroup,
    dispatchDate,
    timeZone,
  };
  const { isFetching, data } = useQuery(
    [QueryKey.DISPATCH, params],
    () => getDispatchDetails(params),
    {
      onSuccess: (disData) => handleOnSuccess(onSuccess, disData, false),
      onError: (error) => {
        error.response && onError(error.response.data);
        console.log(`Getting dispatched data was failed due to: ${error}`);
      },
      refetchInterval: refetchDispatch ? REAL_TIME_DATA_REFRESH : DISP_TIME_DATA_REFRESH,
      refetchOnWindowFocus: false,
      enabled: range.type === CalendarRanges.DAY && timeZone !== undefined,
    },
  );
  return useMemo(
    () => ({
      isFetching,
      data: data || {},
    }),
    [data, isFetching],
  );
};

//Patch API Hook
export const usePatchDispatch = ({ onSuccess, onError }) => {
  const {
    mutate: patchDispatchId,
    isLoading,
    data,
  } = useMutation((id) => patchDispatch(id, { planChange: true }), {
    onSuccess: (disData) => handleOnSuccess(onSuccess, disData || {}, false),
    onError: (error) => {
      error.response && onError(error.response.data);
      console.log(`Dispatched on patch was failed due to: ${error}`);
    },
  });
  return useMemo(() => ({ patchDispatchId, isLoading, data }), [patchDispatchId, isLoading, data]);
};
