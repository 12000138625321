import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditNoteAttachment = () => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.note_or_attachment', 'Note Or Attachment')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup></FieldGroup>
      </ColumnDetail>
    </div>
  );
};
