import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Radio } from '@ge/components/radio/radio';
import { Table, Tbody, Td, Th, Thead } from '@ge/components/table';
import {
  AlertDLTemplate,
  CaseDLType,
  DateTimeFormats,
  DistributionLists,
} from '@ge/models/constants';
import { useDeleteDistributionList } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util';
import { globalColors } from '@ge/tokens/colors';

import { CreateHeader } from './headers';

//@static content
const SHOW_MORE_TEXT_LENGTH = 5;

//@custom styles
const Container = styled.div`
  padding: 2em 2em 3em 3.3em;
  background: ${(props) => props.theme.distributionListSidePanel.formBackground};

  .wrapper-cls {
    margin: 1em 0;
  }
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.distributionListSidePanel.defaultTextLabel};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};

  div {
    flex-grow: 1;

    &:first-of-type {
      flex-grow: 0;
      width: 192px;
      margin-right: 20px;
    }

    label {
      font: 500 11px/18px Museo Sans;
    }

    .label-cls {
      color: ${globalColors.grey4};
      font: 500 11px/18px Museo Sans;
    }

    .item-text-cls {
      margin: 0 0 0.9em 0;
    }
  }

  .template-block-cls {
    padding: 0 0 0 4.5em;
    border-left: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};
  }

  p {
    margin: 0.3em 0;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};

  div {
    flex-grow: 1;

    &:first-of-type {
      flex-grow: 0;
      width: 192px;
      margin-right: 20px;
    }
    .label-cls {
      color: ${globalColors.grey4};
      font: 500 11px/18px Museo Sans;
    }
  }

  p {
    margin: 0.3em 0;
  }
`;

const OverviewWrap = styled.div`
  margin: 1em 0;

  .edit-icon-cls {
    float: right;
    cursor: pointer;
  }
`;
const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const ScrollableDiv = styled.div`
  max-height: calc(100vh - 130px);
  text-transform: capitalize;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;

const ShowMoreText = styled.div`
  color: #6193a3;
  cursor: pointer;
`;

const ShowMoreIcon = styled(Icon).attrs(() => ({}))`
  transform: ${(props) =>
    props.more ? 'translate(-2px, -2px) rotate(180deg)' : 'translate(-2px, -2px)'};
`;

const RecipientsWrapper = styled.div`
  div {
    margin: 0.2em;
    display: inline-block;

    .header-element-cls {
      width: 100%;
      cursor: pointer;

      .highlight-cls {
        color: ${globalColors.white};
      }
    }
  }

  .table-block-cls {
    width: 100%;
    margin: 1em 0;

    table {
      width: 100%;
      border: none !important;
      border-collapse: collapse;
      color: ${globalColors.slate5};

      th {
        text-align: left;
      }

      tr:nth-child(odd) {
        background: ${globalColors.slate12};
      }

      tr:nth-child(even) {
        background: ${globalColors.slate16};
      }
    }
  }
`;

const StyledCode = styled.span`
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  &.email-address {
    text-transform: lowercase;
  }
  &.radio-cls {
    opacity: 0.4;
  }
`;

const THWrap = styled(Th)`
  background: ${globalColors.grey9} !important;

  > span {
    padding: 0 0 0 0.5em;
  }
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons[props.sorted ? 'ARROW_DOWN' : 'ARROW_UP'],
  color: globalColors.white,
}))`
  padding: 0 0 0.3em 0.6em;
`;

const CondensedRows = styled.tr`
  &:hover {
    cursor: default;
    border-bottom: 1px solid ${globalColors.pine1};
  }
`;

//@functional component
export const ViewDistrubtionList = ({
  close,
  details,
  toggleConfirmPopup,
  ispopup,
  openDLSidePanel,
}) => {
  const { t } = useTranslation(['admin.configure']);

  const [mails, setMails] = useState([]);

  const [loading, setLoading] = useState({ load: false, error: '' });
  const [sorters, setSorters] = useState({ name: 'email', property: 'ASC' });
  const [values, setValues] = useState({ sites: [], assets: [], mails: [], templates: [] });
  const [showMore, setShowMore] = useState({ sites: false, assets: false, templates: false });

  const { deleteDL } = useDeleteDistributionList();

  const { sites, getSiteById } = useStoreState((state) => state.sites);
  const { assets, getAssetById } = useStoreState((state) => state.assets);

  const handleEdit = useCallback(
    (e, id, type) => {
      openDLSidePanel(id, type);
      killEventPropagation(e);
    },
    [openDLSidePanel],
  );

  useEffect(() => {
    if (details && details.entity) {
      const mails = [];
      const distProps = details?.distProperties;
      const formatData = (arg) => {
        if (!arg || !arg.length) return [];
        return arg.map((val) => (val && val.name ? val.name : ''));
      };

      let tempSites = [];
      let tempAssets = [];

      details.entity.forEach((rec) => {
        if (rec?.siteId) {
          tempSites.push(rec.siteId);

          if (rec?.assetId) {
            tempAssets.push(...rec.assetId);
          }
        }
      });
      tempSites = [...new Set(tempSites)];
      tempAssets = [...new Set(tempAssets)];

      if (distProps) {
        Object.keys(distProps).forEach((key) => {
          if (key !== 'name' && key !== 'replyToList' && distProps[key]?.length > 0) {
            distProps[key].forEach((rec) => mails.push({ type: key.replace('List', ''), ...rec }));
          }
        });
      }

      setValues({
        sites: formatData(tempSites.map((item) => getSiteById(item))),
        assets: formatData(tempAssets.map((item) => getAssetById(item)).filter((rec) => rec)),
        templates: details?.distributionType?.map((item) => item?.sourceId),
        mails,
      });

      setMails(mails);
    }
  }, [details, sites, assets]);

  useEffect(() => {
    let sortedValue = sortBy(values.mails, [sorters.name]);

    if (sorters.property === 'desc') {
      sortedValue = sortedValue.reverse();
    }

    setMails(sortedValue);
  }, [sorters, values.mails]);

  const showMoreText = (type) => {
    let text =
      values[type]?.length > SHOW_MORE_TEXT_LENGTH
        ? !showMore[type]
          ? 'show more'
          : 'show less'
        : '';

    if (text) {
      return (
        <ShowMoreText onClick={() => setShowMore({ ...showMore, [type]: !showMore[type] })}>
          <ShowMoreIcon more={showMore[type]} size={12} icon={Icons.CHEVRON} color={'#6193a3'} />
          {text}
        </ShowMoreText>
      );
    } else {
      return text;
    }
  };

  const headerElement = (fieldName, labelName) => {
    const isDesc = sorters.property === 'desc';

    return (
      <div
        className="header-element-cls"
        onClick={() => setSorters({ name: fieldName, property: isDesc ? 'asc' : 'desc' })}
      >
        <span className={sorters.name === fieldName ? 'highlight-cls' : ''}>{labelName}</span>
        {sorters.name === fieldName ? <ArrowIcon sorted={isDesc ? true : false} /> : ''}
      </div>
    );
  };

  const handleContinue = () => {
    toggleConfirmPopup();
    setLoading({ load: true, error: '' });

    deleteDL(details.distId)
      .then(() => {
        setLoading({ load: false, error: '' });
        close();
      })
      .catch((err) => {
        setLoading({
          load: false,
          error: err?.response?.data?.message || err?.response?.statusText || err?.message,
        });
      });
  };

  const getCancelFooter = () => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={toggleConfirmPopup}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleContinue}>
            {t('general:continue', 'Continue')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  };

  return (
    <>
      <Dialog
        isOpen={ispopup}
        onClose={toggleConfirmPopup}
        onConfirm={handleContinue}
        header={t('general:confirmation', 'Confirmation')}
        footer={getCancelFooter()}
        contentWidth
        padContent={true}
      >
        <p>{t('delete_confirmation', 'Are you sure you want to delete this distribution list?')}</p>
      </Dialog>
      {loading.load ? (
        <Loader />
      ) : (
        <>
          <CreateHeader
            data={details || true}
            type={'View'}
            handleDelete={toggleConfirmPopup}
            error={loading.error}
          />
          <ScrollableDiv>
            <Container>
              <div>
                <OverviewWrap>
                  <div
                    className="edit-icon-cls"
                    onClick={(e) => handleEdit(e, details?.distId, DistributionLists.EDIT)}
                  >
                    <Icon size={12} icon={Icons.PENCIL} color={'White'} />
                  </div>
                  <StyledLabel>{t('list_overview', 'LIST OVERVIEW')}</StyledLabel>
                </OverviewWrap>

                <InputWrapper>
                  <div>
                    <p className="label-cls">List Name</p>
                    <p>{details?.name}</p>
                  </div>
                  <div>
                    <p className="label-cls">Description</p>
                    <p>{details?.desc}</p>
                  </div>
                </InputWrapper>

                <div className="wrapper-cls">
                  <StyledLabel>{t('applies_to', 'APPLIES TO')}</StyledLabel>
                </div>

                <InputWrapper>
                  <div>
                    <p className="label-cls">Sites</p>
                    <div>
                      {values?.sites?.map((rec, index) => {
                        if (showMore.sites || (!showMore.sites && index < SHOW_MORE_TEXT_LENGTH)) {
                          return (
                            <p className="item-text-cls" key={index}>
                              {rec}
                            </p>
                          );
                        }
                      })}
                      {showMoreText('sites')}
                    </div>
                  </div>
                  <div>
                    <p className="label-cls">Assets</p>
                    {values?.assets?.length
                      ? values?.assets?.map((rec, index) => {
                          if (
                            showMore.assets ||
                            (!showMore.assets && index < SHOW_MORE_TEXT_LENGTH)
                          ) {
                            return (
                              <p className="item-text-cls" key={index}>
                                {rec}
                              </p>
                            );
                          }
                        })
                      : details?.entity?.length === 1 && details?.entity[0]?.assetId?.length === 0
                      ? '-'
                      : 'All'}
                    {showMoreText('assets')}
                  </div>
                  <div className="template-block-cls">
                    <p className="label-cls">Templates</p>
                    {values?.templates?.map((rec, index) => {
                      if (
                        showMore.templates ||
                        (!showMore.templates && index < SHOW_MORE_TEXT_LENGTH)
                      ) {
                        if (rec === CaseDLType.NOTFAULTED_ACTIONABLE) {
                          return (
                            <p className="item-text-cls" key={index}>
                              {t('DL_form_fields.general_actionable', 'General Actionable')}
                            </p>
                          );
                        } else if (rec === CaseDLType.NOTFAULTED_INFORMATIONAL) {
                          return (
                            <p className="item-text-cls" key={index}>
                              {t('DL_form_fields.general_informational', 'General Informational')}
                            </p>
                          );
                        } else if (rec === AlertDLTemplate.TEMPORARY_CONFIG_ACTIONABLE) {
                          return (
                            <p className="item-text-cls" key={index}>
                              {t(
                                'DL_form_fields.temporary_config_actionable',
                                'Temporary Config Change Actionable',
                              )}
                            </p>
                          );
                        } else if (rec === AlertDLTemplate.TEMPORARY_CONFIG_INFORMATIONAL) {
                          return (
                            <p className="item-text-cls" key={index}>
                              {t(
                                'DL_form_fields.temporary_config_informational',
                                'Temporary Config Change Informational',
                              )}
                            </p>
                          );
                        } else {
                          return (
                            <p className="item-text-cls" key={index}>
                              {rec
                                ?.replace(/_+/g, ' ')
                                ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
                            </p>
                          );
                        }
                      }
                    })}
                    {showMoreText('templates')}
                  </div>
                </InputWrapper>

                <RecipientsWrapper>
                  <div>
                    <StyledLabel>{t('recipients', 'RECIPIENTS')}</StyledLabel>
                  </div>
                  <div>
                    <Badge color={globalColors.stone1} label={values.mails.length} small />
                  </div>
                </RecipientsWrapper>

                <RecipientsWrapper>
                  <div className="table-block-cls">
                    <Table height={'600px'} compressed>
                      <Thead transparent>
                        <tr>
                          <THWrap minWidth="270px">{headerElement('email', 'Email')}</THWrap>
                          <THWrap minWidth="120px">{headerElement('firstName', 'First')}</THWrap>
                          <THWrap minWidth="120px">{headerElement('lastName', 'Last')}</THWrap>
                          <THWrap labels={{ To: 'description' }} />
                          <THWrap labels={{ CC: 'description' }} />
                          <THWrap labels={{ BCC: 'description' }} />
                        </tr>
                      </Thead>
                      <Tbody>
                        {mails.map((event, index) => {
                          return (
                            <CondensedRows key={index}>
                              <Td align="left">
                                <StyledCode className="email-address">{event.email}</StyledCode>
                              </Td>
                              <Td align="left">
                                <StyledCode>{event.firstName}</StyledCode>
                              </Td>
                              <Td align="left" maxWidth="200px">
                                <StyledCode>{event.lastName}</StyledCode>
                              </Td>
                              <Td align="left">
                                <StyledCode className="radio-cls">
                                  <Radio readOnly label="" checked={event.type === 'to'} />
                                </StyledCode>
                              </Td>
                              <Td align="left">
                                <StyledCode className="radio-cls">
                                  <Radio readOnly label="" checked={event.type === 'cc'} />
                                </StyledCode>
                              </Td>
                              <Td align="left">
                                <StyledCode className="radio-cls">
                                  <Radio readOnly label="" checked={event.type === 'bcc'} />
                                </StyledCode>
                              </Td>
                            </CondensedRows>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>
                </RecipientsWrapper>

                <FooterWrapper>
                  <div>
                    <p className="label-cls">Last Updated</p>
                    <div>
                      {details?.auditProperties?.updatedTime
                        ? dayjs(details?.auditProperties?.updatedTime).format(
                            DateTimeFormats.DEFAULT_DATE_TIME_FORMAT_TIMEZONE,
                          )
                        : ''}
                    </div>
                  </div>
                  <div>
                    <p className="label-cls">Last Updated By</p>
                    <div>{details?.auditProperties?.updatedBy}</div>
                  </div>
                </FooterWrapper>
              </div>
            </Container>
          </ScrollableDiv>
        </>
      )}
    </>
  );
};

ViewDistrubtionList.propTypes = {
  close: PropTypes.any,
  details: PropTypes.object,
  toggleConfirmPopup: PropTypes.func,
  ispopup: PropTypes.bool,
  openDLSidePanel: PropTypes.func,
};
