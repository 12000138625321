import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import uniq from 'ramda/src/uniq';
import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Button, ButtonGroup } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader, Loader } from '@ge/components/loader';
import { useNotification } from '@ge/components/notification';
import {
  useCreateErpRequest,
  useCreateBulkErpRequest,
  useErpRequest,
  useFetchErpRequestStatus,
} from '@ge/feat-manage/data-hooks/use-erp-request';
import { useErpTemplate } from '@ge/feat-manage/data-hooks/use-erp-template';
import { useErpTranslations } from '@ge/feat-manage/data-hooks/use-erp-translations';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import {
  ErpBindingPropertyName,
  ErpDynamicFieldType,
  TaskTemplateModes,
  ErpCustomFieldType,
  ErpStatus,
  WarrantyStatusIconMap,
  DateTimeFormats,
  EntityType,
  ErpType,
} from '@ge/models/constants';
import { BulkEditTaskFlagDialog } from '@ge/shared/components/actions-edit-task-flag-dialog';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { ActionsDetail } from '@ge/shared/components/tasks/task-template-shared';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useSiteDetails } from '@ge/shared/data-hooks';
import { getPreferredUserName } from '@ge/shared/services/auth';
import { addUTCOffset, getDateObject } from '@ge/shared/util/time-date';

import { AssignedTechsSection } from './sections/assigned-techs-section/assigned-techs-section';
import { DynamicSection } from './sections/dynamic-section/dynamic-section';
import { LaborChargesSection } from './sections/labor-charges-section/labor-charges-section';
import { PartDetailsSection } from './sections/part-details-section/part-details-section';

dayjs.extend(isSameOrAfter);

const supportedFieldTypes = Object.values(ErpDynamicFieldType);

const ErpFormTemplateContainer = styled.div`
  position: relative;
`;

const ErpFormContainer = styled.div`
  margin: 0 20px;
`;

const FormHeader = styled.div`
  margin-bottom: 15px;
`;

const FieldContainer = styled.div`
  ${({ srEnableSubmit, isTaskSelected }) =>
    !isTaskSelected &&
    srEnableSubmit &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    `}
`;

const FormHeadingContainer = styled.div`
  margin-bottom: 20px;
`;

const FormErrorsContainer = styled.div`
  color: ${(props) => props.theme.dangerColor};
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  font-weight: 700;
`;

const ErrorIcon = styled(Icon).attrs((props) => ({
  size: 16,
  icon: Icons.DATA_NETCOMM,
  color: props.theme.dangerColor,
}))`
  margin-bottom: 5px;
`;

const FormHeading = styled.h2`
  font-size: 18px;
  text-transform: uppercase;
`;

const ErpIdIconStatus = styled.div`
  display: flex;
  align-items: center;
`;

const FormHeaderColumn = styled.div`
  flex-direction: column;
  flex: 1;
`;

const ErpID = styled.h3`
  font-size: 18px;
`;

const WarrantyStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WarrantyStatusIcon = styled.img`
  margin: 0 12px 0 20px;
  width: 20px;
`;

const WarrantyStatus = styled.h3`
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.isSecRes ? '50%' : '0')};
  bottom: 0;
  left: 0;
  right: 0;

  &:after {
    content: '';
    background: rgba(255, 255, 255, 0.2);
    height: ${(props) => (props.height ? `${props.height}px` : '100%')};
    left: 0;
    position: absolute;
    ${(props) => (props.isSecRes ? 'bottom: 0;' : 'top: 0;')};
    width: 100%;
    z-index: 1;
  }
`;

const BulkButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
  padding: 10px;
`;

export const TaskIcon = styled(Icon).attrs((props) => ({
  size: props.size ?? 11,
  color: props.theme.entityDetails.headerIconColor,
  viewbox: props.viewbox,
}))`
  margin-right: 5px;
`;

const BulkSRStatus = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.tasks.details.columnBorder};
  margin-bottom: 18px;
  display: ${(props) => (props.completedSR.length > 0 ? 'none' : 'block')};
`;

const BulkSRSCount = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.tasks.details.columnBorder};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding-bottom: 18px;
  margin-bottom: 18px;
`;

const ErrorMessage = styled.div`
  margin: 0;
  .error_message_code > a {
    color: ${({ theme }) => theme.assetOverview.selectAssetInfo.textColor};
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    font-weight: 700;
  }
  text-transform: none;
`;

const ButtonsContainer = styled.div``;

export const ErpFormTemplate = ({ task, isTaskSelected }) => {
  const updateTasks = useStoreActions((actions) => actions.tasks.updateTasks);
  const { bulkTask, hideDetails } = useContext(EntityDetailsContext);
  const { t } = useTranslation(['manage.erp-statuses'], {
    // to stop flickering effect on click
    useSuspense: false,
  });
  const {
    taskState: { setTimezone, timezone },
  } = useContext(TaskContext);
  const [templateMode, setTemplateMode] = useState(TaskTemplateModes.CREATE);
  const [assignedTechnicians, setAssignedTechnicians] = useState([]);
  const [isEditingAssignedTechs, setIsEditingAssignedTechs] = useState(
    templateMode !== TaskTemplateModes.VIEW,
  );
  const [submittedErpData, setSubmittedErpData] = useState(null);
  const [erpData, setErpData] = useState(null);
  const [resubmit, isResubmit] = useState(false);
  const [srPresent, setSrPresent] = useState([]);
  const [divHeight, setDivHeight] = useState(0);
  const [divLabourHeight, setDivLabourHeight] = useState(0);
  const [divPartHeight, setDivPartHeight] = useState(0);
  const [completedSR, setCompletedSR] = useState([]);
  const isBulkTask = !isTaskSelected && bulkTask?.length >= 1;
  const filteredSRTask = bulkTask?.filter(
    (task) => task?.srNumber === '-' && task?.srNumber !== ' ',
  );
  let userInfo = getPreferredUserName();
  const [inprogressSR, setInprogressSR] = useState([]);

  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  const taskAssetId = task?.asset?.id;
  const siteId = task?.asset?.site?.id ?? task?.site?.id;
  const formSubmitted = !!erpData?.taskId;
  const formHasErrors = erpData?.errorCodes?.length > 0;
  const orderCreationErrorDetails = erpData?.metadata?.orderCreationErrorDetails?.trim();

  const { srNewFieldsFlag } = useStoreState((state) => state.tenant.featureFlags);

  const srCreationInProgress =
    erpData?.status === ErpStatus.SR_CREATION_INP || erpData?.status === ErpStatus.WO_CREATION_INP;
  const srCreationFailed =
    erpData?.status === ErpStatus.SR_CREATION_FAILED ||
    erpData?.status === ErpStatus.WO_CREATION_FAILED;
  const srCreated = !!erpData?.id;
  const srCompleted =
    erpData?.status === ErpStatus.SR_COMPLETED || erpData?.status === ErpStatus.WO_CREATED;

  const { aliases, model: assetModel } = getAssetById(taskAssetId) ?? {};

  const secondResLoader = useCallback(() => {
    return erpData?.lastUpdatedBy && !srCreationFailed && erpData?.id
      ? erpData?.lastUpdatedBy === ErpStatus.SR_HEADER
      : false;
  }, [erpData, srCreationFailed]);

  const erpRefetchInterval = useMemo(() => {
    return srCreationInProgress || secondResLoader() || resubmit
      ? 10 * 1000 // 10 seconds
      : false;
  }, [secondResLoader, srCreationInProgress, resubmit]);

  const { notify, dismiss } = useNotification();

  const { isLoading, data: template } = useErpTemplate({
    crewId: task?.crewIds?.[0],
    taskTitle: task?.title,
  });

  const { isLoading: isTranslationsLoading, data: translations } = useErpTranslations({});
  const {
    isLoading: isErpLoading,
    error: erpDataError,
    data: requestedErpData,
  } = useErpRequest({
    taskId: task?.id,
    erpRefetchInterval,
  });

  useEffect(() => {
    const srSuccess = bulkTask?.filter((task) => task?.srNumber !== '-' && task?.srNumber !== ' ');
    setSrPresent(srSuccess);
  }, [bulkTask, setSrPresent, isTaskSelected]);

  useEffect(() => {
    if (srCreated) {
      updateTasks({ [task?.id]: { ...task, srNumber: requestedErpData?.id ?? '' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srCreated, requestedErpData, updateTasks, isErpLoading]);

  const { mutate: createErpRequest, isLoading: isSubmitting } = useCreateErpRequest({
    onSuccess: (data) => {
      setErpData(null);
      setTemplateMode(TaskTemplateModes.VIEW);
      setIsEditingAssignedTechs(false);
      setSubmittedErpData(data);
    },
    onError: (e) => console.error('Error creating ERP request', e),
  });

  const { mutate: createBulkErpRequest, isLoading: isBulkSRSubmitting } = useCreateBulkErpRequest({
    onSettled: (data) => {
      let message;
      if (data) {
        message = (
          <NotificationMessage
            message={t('status.bulk_SR_success', 'Bulk Service Request Submitted Successfully')}
          />
        );
      } else {
        message = (
          <NotificationMessage
            message={t('status.bulk_SR_failed', 'Bulk Service Request Failed')}
          />
        );
      }
      dismiss();
      notify({ message });
      hideDetails();
    },
  });

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  //reset data on component unmount
  useEffect(() => {
    return () => {
      setTemplateMode(TaskTemplateModes.CREATE);
      setErpData(null);
    };
  }, []);

  useEffect(() => {
    setErpData(submittedErpData ?? {});
  }, [submittedErpData, setErpData]);

  useEffect(() => {
    if (requestedErpData === null) setTemplateMode(TaskTemplateModes.CREATE);
    if (isTaskSelected) {
      setErpData(requestedErpData ?? {});
    } else {
      setTemplateMode(TaskTemplateModes.CREATE);
    }
  }, [requestedErpData, setErpData, isTaskSelected]);

  useEffect(() => {
    if (erpDataError) {
      console.error('Error fetching ERP data:', erpDataError);
      setErpData({});
    }
  }, [erpDataError, setErpData]);

  const {
    data: { site },
    isLoading: isSiteDetailsLoading,
  } = useSiteDetails({ siteId });
  const siteTimezone = site?.timezone;

  useEffect(() => {
    setTimezone(siteTimezone);
  }, [setTimezone, siteTimezone]);

  useEffect(() => {
    if (!isErpLoading && erpData?.metadata) {
      if (srCreationFailed && !erpData?.id) setTemplateMode(TaskTemplateModes.CREATE);
      if (!srCreationFailed && erpData?.id && (!formHasErrors || srCreated)) {
        setTemplateMode(TaskTemplateModes.VIEW);
      }
    }
  }, [
    isErpLoading,
    erpData,
    formHasErrors,
    srCreated,
    setTemplateMode,
    srCreationFailed,
    requestedErpData,
  ]);

  useEffect(() => {
    setIsEditingAssignedTechs(
      templateMode !== TaskTemplateModes.VIEW && template?.type == ErpType.SERVICE_REQUEST,
    );
  }, [templateMode, setIsEditingAssignedTechs, template?.type]);

  useEffect(() => {
    if (erpData?.lastUpdatedBy === ErpStatus.SR_BODY_UPDATE) isResubmit(false);
  }, [erpData]);

  const onAssignedTechniciansChanged = useCallback(
    (technicians) => {
      setAssignedTechnicians(technicians);
    },
    [setAssignedTechnicians],
  );

  const srEnableSubmit = useMemo(() => {
    const activeTaskCount = bulkTask?.length - srPresent?.length;
    let srCheck;
    if ((completedSR?.length > 0 && activeTaskCount > 0) || inprogressSR?.length === 0) {
      srCheck = false;
    } else if (srPresent?.length > 0 || inprogressSR?.length > 0) {
      srCheck = true;
    }
    return srCheck;
  }, [bulkTask, completedSR, srPresent, inprogressSR]);

  const submitSRTasks = useMemo(() => {
    if (completedSR?.length > 0) {
      return filteredSRTask;
    } else {
      return bulkTask;
    }
  }, [completedSR, filteredSRTask, bulkTask]);

  const getTaskIds = () => {
    if (isBulkTask) {
      let taskID = [];
      uniq(
        submitSRTasks?.map((task) => {
          taskID.push(task?.id);
        }),
      );
      return taskID;
    }
  };

  const { data: requestedErpStatusData } = useFetchErpRequestStatus({ taskIds: getTaskIds() });

  useEffect(() => {
    const inProgressSrStatus = requestedErpStatusData?.filter((item) => {
      if (item?.status === 'SR_CREATION_INP' || (item?.id && item?.id !== ' ')) {
        return item;
      }
    });
    setInprogressSR(inProgressSrStatus);
  }, [requestedErpStatusData]);

  const getSiteTimeZone = useCallback((siteTimezone) => {
    if (siteTimezone) {
      const [year, month, day, hours, minutes, seconds, millseconds] = dayjs
        .tz(new Date(), siteTimezone)
        .toArray();

      const timeZone = [
        String(year).padStart(4, '0'),
        String(month).padStart(2, '0'),
        String(day).padStart(2, '0'),
        String(hours).padStart(2, '0'),
        String(minutes).padStart(2, '0'),
        String(seconds).padStart(2, '0'),
        String(millseconds).padStart(3, '0'),
      ];
      return timeZone;
    } else {
      return null;
    }
  }, []);

  const isTaskPresentInBulkTask = useCallback(
    () => bulkTask?.some((data) => data?.id === task?.id),
    [bulkTask, task?.id],
  );

  const onSubmit = useCallback(
    (erpFormValues) => {
      const tagName = erpFormValues?.assetSerialNumber;
      if (isEditingAssignedTechs && templateMode === TaskTemplateModes.VIEW) {
        erpFormValues = erpData.metadata;
        isResubmit(true);

        // `ecoTurbineType` is sent back in the ERP data, but will be rejected by the validator if re-submitted
        delete erpFormValues.ecoTurbineType;

        if (erpFormValues?.tag) {
          // `assetSerialNumber` is sent back in the ERP data as `tag`, must change name back to re-submit
          erpFormValues.assetSerialNumber = erpFormValues?.tag;
          delete erpFormValues.tag;
        }
      }

      // Handle tech assignments / re-assignments
      erpFormValues[ErpCustomFieldType.TECHNICIANS] = assignedTechnicians ?? [];
      // Remove empty or null isolation value from the payload as its gives bad request error.

      // srNewFieldsFlag check added for Offshore tenant

      if ((erpFormValues.isolation == '' || erpFormValues.isolation == null) && srNewFieldsFlag) {
        erpFormValues.isolation = [];
      }
      // Remove empty parts rows from the payload as the backend can't cope with empty fields being submitted for some reason.
      if (erpFormValues.parts?.length > 0) {
        const updatedParts = [];
        erpFormValues.parts.forEach((part) => {
          if (part.number !== '') {
            updatedParts.push(part);
          }
        });
        erpFormValues.parts = updatedParts;
        // Reformat date value before submitting
        if (erpFormValues.needByDate && templateMode === TaskTemplateModes.CREATE) {
          const dateValue = dayjs(erpFormValues.needByDate);
          erpFormValues.needByDate = dateValue.isValid()
            ? dateValue.format(DateTimeFormats.ERP_METADATA_DATE_TIME)
            : null;
          const currDate = dayjs(getDateObject(addUTCOffset(dayjs(), timezone))).format(
            DateTimeFormats.ERP_METADATA_DATE_TIME,
          );
          if (!dayjs(erpFormValues.needByDate).isSameOrAfter(currDate)) {
            methods.setError('needByDate', {
              type: 'date',
              message: t('form.need_by_date', 'Need by date should be current date or future date'),
            });
            return;
          }
        }
      }

      let assetId = '';
      if (task?.taskLevel === EntityType.ASSET) {
        assetId = aliases?.find((alias) => alias?.key === 'racesBopId')?.value ?? taskAssetId;
      } else if (task?.taskLevel === EntityType.SITE) {
        assetId = site?.aliases?.find((alias) => alias?.key === 'racesBopId')?.value ?? taskAssetId;
      }

      if (isBulkTask) {
        const getSiteAssetName = () => {
          const tagEntries = uniq(
            submitSRTasks?.map((task) => {
              const site = getSiteById(task?.site?.id ?? task?.asset?.site?.id);
              return {
                siteGroupSourceKey: task?.site?.id ?? task?.asset?.site?.id,
                assetSerialNumber: task?.asset?.id ? task?.asset?.id : '',
                tag: task?.asset?.name
                  ? `${task?.site?.name} | ${task?.asset?.name}`
                  : task?.site?.name,
                taskId: task?.id,
                scheduledDate: getSiteTimeZone(site?.timezone),
              };
            }),
          );
          return tagEntries;
        };

        const uniqueTags = uniq(
          submitSRTasks?.map((task) => {
            return {
              assetSerialNumber: task?.asset?.id ? task?.asset?.id : '',
              tag: task?.asset?.name
                ? `${task?.site?.name} | ${task?.asset?.name}`
                : task?.site?.name,
            };
          }),
        );

        erpFormValues.assetSerialNumber = uniqueTags
          .map(({ assetSerialNumber }) => assetSerialNumber)
          .toString();
        erpFormValues.tag = uniqueTags.map(({ tag }) => tag).toString();
        erpFormValues.ownerName = userInfo ? userInfo : '';

        createBulkErpRequest({
          getSiteAssetName,
          erpFormValues,
          type: template?.type,
        });
      } else {
        erpFormValues.assetSerialNumber = taskAssetId ? taskAssetId : '';
        erpFormValues.tag = tagName ? tagName : '';
        createErpRequest({
          assetId,
          assetModel,
          erpFormValues,
          siteId,
          siteTimezone,
          id: erpData?.id,
          taskId: task?.id,
          type: template?.type,
          reportedDate: task?.createDate,
        });
      }
    },
    [
      isEditingAssignedTechs,
      templateMode,
      erpData,
      assignedTechnicians,
      taskAssetId,
      assetModel,
      createErpRequest,
      siteId,
      siteTimezone,
      task,
      template?.type,
      aliases,
      methods,
      t,
      timezone,
      createBulkErpRequest,
      userInfo,
      getSiteById,
      getSiteTimeZone,
      isBulkTask,
      srNewFieldsFlag,
      site,
      submitSRTasks,
    ],
  );

  const cancel = useCallback(() => {
    const setData = {};
    setIsEditingAssignedTechs(false);
    methods.reset(setData);
  }, [setIsEditingAssignedTechs, methods]);

  const getErpFormButtons = useCallback(
    (position) => {
      // don't show submit/cancel buttons if the form is created AND no error codes AND technicians aren't being edited
      if (srCreated && !formHasErrors && !isEditingAssignedTechs) {
        return null;
      }

      return (
        <ActionsDetail className={`column-detail actions ${position ?? ''}`}>
          <div className="actions-left"></div>
          <ButtonsContainer className="actions-right">
            {/* show submit button as "Re-Submit" if techs are edited after form has been submitted */}
            {isEditingAssignedTechs && formSubmitted ? (
              <>
                <BulkButtonGroup>
                  <Button type="button" onClick={cancel}>
                    Cancel
                  </Button>
                  <Button
                    primary
                    type="button"
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={
                      isSubmitting || isBulkSRSubmitting || srCreationInProgress || srEnableSubmit
                    }
                  >
                    Re-Submit
                  </Button>
                </BulkButtonGroup>
              </>
            ) : (
              <>
                {bulkTask?.length >= 1 && isTaskPresentInBulkTask() ? (
                  <BulkButtonGroup>
                    <Button type="button" onClick={hideDetails}>
                      Cancel
                    </Button>
                    <Button
                      primary
                      type="button"
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={
                        isSubmitting || isBulkSRSubmitting || srCreationInProgress || srEnableSubmit
                      }
                    >
                      Submit
                    </Button>
                  </BulkButtonGroup>
                ) : (
                  (!formSubmitted || srCreationFailed) && (
                    <Button
                      primary
                      type="button"
                      onClick={methods.handleSubmit(onSubmit)}
                      disabled={isSubmitting || isBulkSRSubmitting || srCreationInProgress}
                    >
                      Submit
                    </Button>
                  )
                )}
              </>
            )}
          </ButtonsContainer>
        </ActionsDetail>
      );
    },
    [
      srCreated,
      formHasErrors,
      isEditingAssignedTechs,
      formSubmitted,
      cancel,
      methods,
      onSubmit,
      isSubmitting,
      isBulkSRSubmitting,
      srCreationInProgress,
      srCreationFailed,
      bulkTask,
      hideDetails,
      srEnableSubmit,
      isTaskPresentInBulkTask,
    ],
  );

  const partsShippingSection = useMemo(() => {
    return template?.sections.find((section) => {
      return Object.values(section.metadata).some(
        (metadataField) => metadataField.type === ErpDynamicFieldType.ADDRESS,
      );
    });
  }, [template]);

  const getNonDynamicSection = (section, index, erpType = ErpType.SERVICE_REQUEST) => {
    return Object.entries(section.metadata).map(([key]) => {
      // This is where we will add new non-dynamic sections.
      switch (key) {
        case ErpCustomFieldType.TECHNICIANS:
          return (
            <AssignedTechsSection
              key={index}
              section={section}
              task={task}
              erpData={erpData?.metadata}
              isSrCreated={srCreated}
              isSrCompleted={srCompleted}
              isEditing={isEditingAssignedTechs}
              setIsEditing={setIsEditingAssignedTechs}
              onAssignedTechniciansChanged={onAssignedTechniciansChanged}
              setDivHeight={setDivHeight}
              bulkTask={bulkTask}
              isTaskSelected={isTaskSelected}
            />
          );
        case ErpBindingPropertyName.LABOR_DETAILS:
          return (
            <LaborChargesSection
              key={index}
              section={section}
              task={task}
              templateMode={templateMode}
              erpData={erpData?.metadata}
              setDivLabourHeight={setDivLabourHeight}
              isTaskSelected={isTaskSelected}
            />
          );
        case ErpBindingPropertyName.PART_DETAILS:
          return (
            <PartDetailsSection
              key={index}
              section={section}
              shippingSection={partsShippingSection}
              task={task}
              templateMode={templateMode}
              timezone={siteTimezone}
              erpType={erpType}
              erpData={erpData?.metadata}
              setDivPartHeight={setDivPartHeight}
              erpDataStatus={erpData?.status}
              erpDataLastUpdatedBy={erpData?.lastUpdatedBy}
            />
          );
        default:
          return null;
      }
    });
  };

  const getSection = (section, i, erpType = ErpType.SERVICE_REQUEST) => {
    if (section === partsShippingSection) {
      // Rendering of shipping section is handled by the `PartDetailsSection` component
      return null;
    }

    const isDynamicSection = Object.entries(section.metadata).every(([, metadataField]) =>
      supportedFieldTypes.includes(metadataField.type),
    );

    return isDynamicSection ? (
      <DynamicSection
        key={i}
        section={section}
        task={task}
        templateMode={templateMode}
        timezone={siteTimezone}
        erpType={erpType}
        erpData={erpData.metadata}
        hideHeading={srCreated && isEditingAssignedTechs}
        isTaskSelected={isTaskSelected}
      />
    ) : (
      getNonDynamicSection(section, i, erpType)
    );
  };

  const getWarrantyStatusIcon = (warrantyStatus) => {
    const iconName = WarrantyStatusIconMap[warrantyStatus];
    if (!iconName) {
      return null;
    }

    const iconSource = require(`../../icons/warranty-status-icons/${iconName}.svg`);

    return (
      <WarrantyStatusContainer>
        <WarrantyStatusIcon src={iconSource} alt={iconName} />
        <WarrantyStatus>{t(`status.${erpData.status}`, '')}</WarrantyStatus>
      </WarrantyStatusContainer>
    );
  };

  const getFormHeading = (formType) => {
    if (srCreated && isEditingAssignedTechs) {
      return (
        <FormHeading>
          {formType === ErpType.SERVICE_REQUEST
            ? t('edit_service_request', 'Edit Service Request')
            : t('edit_work_order', 'Edit Work Order')}
        </FormHeading>
      );
    }

    return (
      <FormHeading>
        {formType === ErpType.SERVICE_REQUEST
          ? t('service_request', 'Service Request')
          : t('work_order', 'Work Order')}
      </FormHeading>
    );
  };

  if (
    isLoading ||
    isSiteDetailsLoading ||
    isTranslationsLoading ||
    isErpLoading ||
    !erpData ||
    (templateMode === TaskTemplateModes.CREATE && erpData?.id)
  )
    return <MiniLoader />;

  if (!template?.sections) return null;

  const reSubmitLoader = () => {
    return resubmit && !srCreationFailed; // && erpData?.lastUpdatedBy != ErpStatus.SR_BODY_UPDATE;
  };

  // Loading spinner shows in this section if there are pending orders for any parts
  const checkFifthRes = () => {
    if (!erpData?.metadata || srCreationFailed) return false;
    const partsData = erpData?.metadata[ErpCustomFieldType.PARTS];
    const placedOrders = partsData?.filter((part) => part.placeOrder || part.replenish).length;
    const ordersReceived = partsData?.filter((part) => part.orderNumber).length;
    /* 1. return failed condition..one of the part is failed..dont wait for 5th response
       2. if parts has no error and placed orders with no order numbers generated...wait for 5th response.
       3. reference - previous condition used for one valid with placed order and one invalid with no place order
       const failed = partsData?.filter((part) => part.placeOrder || part.replenish)
      .some((part) => !part.orderNumber && part.error);
    */
    const failed = partsData?.some((part) => part.error);
    return placedOrders > 0 && failed == false && ordersReceived == 0 ? true : false;
  };

  const getLoaderHeight = () => {
    if (isSubmitting || isBulkSRSubmitting || srCreationInProgress) return null;
    // get Assign tech , labour, parts div height and add loader accordingly untill the second response
    // comes and while resubmitting the tech assign
    if (secondResLoader() || reSubmitLoader())
      return divHeight + divLabourHeight + divPartHeight + 70;
    //labour, parts div height and add loader accordingly untill the fifth response
    if (checkFifthRes()) return divPartHeight + 70;
  };

  return (
    <ErpFormTemplateContainer>
      {(isSubmitting ||
        isBulkSRSubmitting ||
        srCreationInProgress ||
        secondResLoader() ||
        reSubmitLoader() ||
        (checkFifthRes() && !isEditingAssignedTechs)) && (
        <LoaderContainer
          isSecRes={
            !srCreationInProgress && (secondResLoader() || reSubmitLoader() || checkFifthRes())
          }
          isFithRes={!srCreationInProgress && checkFifthRes()}
          height={getLoaderHeight()}
        >
          <Loader />
        </LoaderContainer>
      )}
      <FormProvider {...methods}>
        <ErpFormContainer>
          {getErpFormButtons()}
          {isBulkTask && (
            <>
              {srEnableSubmit && (
                <BulkSRStatus completedSR={completedSR}>
                  <BulkEditTaskFlagDialog
                    setCompletedSR={setCompletedSR}
                    isTaskSelected={isTaskSelected}
                    bulkTask={bulkTask}
                    hideDetails={hideDetails}
                    inprogressSR={inprogressSR}
                    srPresent={srPresent}
                  />
                </BulkSRStatus>
              )}
              <BulkSRSCount>
                <TaskIcon icon={Icons.TASK} />
                &nbsp; {bulkTask?.length - srPresent?.length} Tasks
              </BulkSRSCount>
            </>
          )}
          <FieldContainer srEnableSubmit={srEnableSubmit} isTaskSelected={isTaskSelected}>
            <FormHeader>
              <FormHeadingContainer>
                {getFormHeading(template.type)}
                {formHasErrors && (
                  <FormErrorsContainer>
                    <ErrorIcon />
                    {erpData.errorCodes.map((errorCode) => (
                      <ErrorMessage key={errorCode}>
                        <div
                          className="error_message_code"
                          dangerouslySetInnerHTML={{
                            __html:
                              translations[errorCode] || t('default_error_message', { errorCode }),
                          }}
                        ></div>
                      </ErrorMessage>
                    ))}
                  </FormErrorsContainer>
                )}
                {orderCreationErrorDetails?.length > 0 && (
                  <FormErrorsContainer>
                    {!formHasErrors && <ErrorIcon />}
                    {erpData?.metadata?.orderCreationErrorDetails.split('~').map((errorMessage) => (
                      <ErrorMessage key={errorMessage}>
                        <div className="error_message_code">{errorMessage}</div>
                      </ErrorMessage>
                    ))}
                  </FormErrorsContainer>
                )}
              </FormHeadingContainer>
              <ErpIdIconStatus>
                <FormHeaderColumn>
                  <ErpID>{erpData.id}</ErpID>
                </FormHeaderColumn>
                <FormHeaderColumn>{getWarrantyStatusIcon(erpData.status)}</FormHeaderColumn>
              </ErpIdIconStatus>
            </FormHeader>
            {template.sections.map((section, i) => getSection(section, i, template.type))}
            {getErpFormButtons('below')}
          </FieldContainer>
        </ErpFormContainer>
      </FormProvider>
    </ErpFormTemplateContainer>
  );
};

ErpFormTemplate.propTypes = {
  task: PropTypes.object,
  bulkTask: PropTypes.array,
  hideDetails: PropTypes.func,
  isTaskSelected: PropTypes.bool,
};
