import PropTypes from 'prop-types';
import React, { useRef, useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DraggableTable } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import PageHeader from '@ge/feat-monitor/components/page-header';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { Capability, AdminDefs, DistributionLists } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAllDistributionLists } from '@ge/shared/data-hooks/use-distribution-list';
import { useStorageInverters } from '@ge/shared/data-hooks/use-storage-inverters';
import { AppScopes } from '@ge/shared/models/scopes';
import { killEventPropagation } from '@ge/shared/util';
import { globalColors } from '@ge/tokens';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';

import {
  DistributionColumnDefs,
  DistributionColumns,
} from '../../models/distribution-list-table-cols';
import { PopupMenu } from '../menu-pop-up';

const DraggableTableWrapper = styled(DraggableTable)`
  th {
    border-right: solid 1.5px !important ;
    border-right-color: ${(props) => props.theme.distributionListTable.borderColor} !important;

    .group-last {
      border-right: solid 1.5px !important;
      border-right-color: ${(props) => props.theme.distributionListTable.borderColor} !important;
    }
  }

  td {
    &.group-last {
      top: 0px !important;
      z-index: 8 !important;
      border-right: solid 1.5px !important;
      border-right-color: ${(props) => props.theme.distributionListTable.borderColor} !important;
    }

    span {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
`;
//@custom styles
const EllipsisDivWrapper = styled.div`
  max-width: 75%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;
const AddDLWrapper = styled.div`
  cursor: pointer;
  margin: 0.5em 1em 0 0;
`;
const CellWrapBadge = styled(Badge)`
  float: right;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: props.size,
  icon: props.icon,
  color: props.theme.postProcess.iconButton,
}))``;

//@functional component
const DistributionListTable = ({
  columns,
  setFilterDefs,
  filterDefs,
  sortAction,
  sortMetric,
  viewDetail,
  sortDirection,
  setVisibleColumns,
  updateColumnVisibility,
}) => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { paramValues, openDLSidePanel, selectedRow, setSelectedRow } =
    useContext(EntityDetailsContext);

  const addDL = useCallback(
    (e, type) => {
      killEventPropagation(e);
      openDLSidePanel(e, type);
    },
    [openDLSidePanel],
  );

  const onRowSelect = useCallback(
    (e, row) => {
      killEventPropagation(e);
      setSelectedRow([row.distEntityId]);
      openDLSidePanel(row?.distId, DistributionLists.VIEW);
    },
    [openDLSidePanel, setSelectedRow],
  );

  const containerRef = useRef(null);

  const [filteredDL, setFilteredDL] = useState([]);
  const [currentSearch, setCurrentSearch] = useState({});

  const { t, ready } = useTranslation(['monitor.sites']);
  const { distributionLists, filterValues, isLoading } = useAllDistributionLists({
    search: currentSearch,
    filters: filterDefs,
    sortDirection,
    sortMetric,
  });

  const { isLoading: inverterAssetsLoading } = useStorageInverters({
    enabled: true,
  });

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  useEffect(() => {
    const filtered = distributionLists;
    setFilteredDL(filtered);
    if (paramValues && paramValues[0] !== DistributionLists.NEW && paramValues[1]) {
      const record = distributionLists?.find((value) => value.distId == paramValues[1]);
      record && viewDetail(record);
    }
  }, [distributionLists, params, viewDetail, paramValues]);

  const handleFilterChange = useCallback(
    (_, key, value) => {
      setCurrentSearch({ key, value });
    },
    [setCurrentSearch],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => setFilterDefs(columnKey, value),
    [setFilterDefs],
  );

  const customCellFn = useCallback((columnKey, cellValue) => {
    const menuPopupCellFn = (values, emptyText, record) => {
      let cellElement = `${emptyText || ''}`;
      if (record?.length > 0) {
        let badgeText = '';
        let text = record.join(', ');
        const ellipsisTextLength = values?.title === 'TEMPLATES' ? 42 : 24;

        if (record.length > 1 && text && text.length > ellipsisTextLength + 1) {
          const remainingCount =
            record.length - text.slice(0, ellipsisTextLength).split(', ').length;

          if (remainingCount > 0) {
            badgeText = `+${record.length - text.slice(0, ellipsisTextLength).split(', ').length}`;
          }
        }

        cellElement =
          !badgeText || record.length === 1 ? (
            <TooltipCell tooltip={text}>{text}</TooltipCell>
          ) : (
            <>
              {record.length < 13 ? (
                <div>
                  <EllipsisDivWrapper>{text}</EllipsisDivWrapper>
                  <PopupMenu {...values} data={record}>
                    {record.length > 1 ? (
                      <CellWrapBadge color={globalColors.stone1} label={badgeText} small />
                    ) : (
                      ''
                    )}
                  </PopupMenu>
                </div>
              ) : (
                <TooltipCell tooltip={'All'}>{'All'}</TooltipCell>
              )}
            </>
          );
      }

      return cellElement;
    };

    switch (columnKey) {
      case DistributionColumns.NAME:
        return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
      case DistributionColumns.DESCRIPTION:
        return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
      case DistributionColumns.SITE:
        return menuPopupCellFn({ title: 'SITES', headers: ['Name'] }, null, cellValue);
      case DistributionColumns.ASSET:
        return menuPopupCellFn(
          { title: 'ASSETS', headers: ['Name'] },
          cellValue?.emptyText,
          cellValue?.asset,
        );
      case DistributionColumns.TEMPLATE:
        return menuPopupCellFn({ title: 'TEMPLATES' }, null, cellValue);
      case DistributionColumns.DETAIL:
        return (
          <div>
            <StyledIcon icon={Icons.CHEVRON_RIGHT} size={12} />
          </div>
        );
      default:
        return null;
    }
  }, []);

  const filters = useMemo(
    () =>
      Object.keys(filterDefs).reduce(
        (_filter, key) => ({
          ..._filter,
          [key]: {
            ...filterDefs[key],
            value: filterDefs?.[key]?.value,
          },
        }),
        {},
      ),
    [filterDefs],
  );

  const cellValueMapFn = useCallback((row) => {
    const { name, site, asset, desc, entity, template } = row;

    return {
      [DistributionColumns.NAME]: name,
      [DistributionColumns.SITE]: site.value,
      [DistributionColumns.ASSET]: {
        asset: asset.value,
        emptyText: entity?.length === 1 && entity[0]?.assetId?.length === 0 ? '-' : 'All',
      },
      [DistributionColumns.DESCRIPTION]: desc,
      [DistributionColumns.TEMPLATE]: template,
      [DistributionColumns.DETAIL]: row,
    };
  }, []);

  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: DistributionColumnDefs,
    sortAction,
    sortedDirection,
    cellValueMapFn,
    customCellFn,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters,
    filterValues,
  });

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ADMIN_CONFIGURE_DISTRIBUTION_LIST);

  const { onVisibilityChange: handleColumnChanges } = useManageColumnVisibility({
    subKey: AdminDefs.DISTRIBUTION_LIST_SORT_STATE_ID,
    featPrefs,
    savePrefs,
    setVisibleColumns,
    updateColumnVisibility,
  });

  if (!ready || isLoading || inverterAssetsLoading) {
    return <Loader />;
  }

  return (
    <PageContainer i18nReady={ready}>
      <PageHeader title={t('distribution_list', 'Distribution Lists')} />
      <SubNavigation>
        <AuthRender
          capability={Capability.NOTIFICATION_LIST}
          admin
          description="create new DL"
          siteLevel={false}
        >
          <AddDLWrapper onClick={(e) => addDL(e, DistributionLists.NEW)}>
            {t('new_list', '+ ADD DISTRIBUTION LIST')}
          </AddDLWrapper>
        </AuthRender>

        <ColumnSelectMenu
          translateFn={t}
          containerRef={containerRef}
          columnDef={DistributionColumnDefs}
          columnState={columns}
          onMenuClose={handleColumnChanges}
        />
      </SubNavigation>
      <DraggableTableWrapper
        scrollable={true}
        columns={columns}
        values={filteredDL}
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        sortAction={sortAction}
        cellFactory={cellFactory}
        onValueSelect={onRowSelect}
        rowKeyProperty={['distEntityId']}
        rowsSelected={selectedRow}
      />
    </PageContainer>
  );
};

DistributionListTable.propTypes = {
  onDrop: PropTypes.func,
  viewDetail: PropTypes.func,
  sortAction: PropTypes.func,
  scrollable: PropTypes.bool,
  onRowSelect: PropTypes.func,
  className: PropTypes.string,
  sortMetric: PropTypes.string,
  filterDefs: PropTypes.object,
  setFilterDefs: PropTypes.func,
  filterValues: PropTypes.object,
  openDLSidePanel: PropTypes.func,
  sortDirection: PropTypes.string,
  selectedSiteId: PropTypes.string,
  selectedAssetId: PropTypes.string,
  setVisibleColumns: PropTypes.func,
  updateColumnVisibility: PropTypes.func,
  theme: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  selectedRow: PropTypes.any,
};

DistributionListTable.defaultProps = {
  filterDefs: {},
  selectedSiteId: '',
  selectedAssetId: '',
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
};

export default DistributionListTable;
