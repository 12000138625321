import { MonitorCaseStateMapping } from '@ge/models/constants';
import { ActionKeys } from '@ge/shared/models/actions-menu-items';

import * as request from './api';

const CASE_QUEUE_STATUSES = `${MonitorCaseStateMapping.NEW},${MonitorCaseStateMapping.IN_PROGRESS},${MonitorCaseStateMapping.RETURNED},${MonitorCaseStateMapping.SNOOZED}`;
const CASE_HISTORY_STATUSES = `${MonitorCaseStateMapping.CLOSED}`;
const CASE_STATE_MAPPING = {
  [ActionKeys.EVENT_IN_PROGRESS]: `${MonitorCaseStateMapping.IN_PROGRESS}`,
  [ActionKeys.EVENT_SNOOZE]: `${MonitorCaseStateMapping.SNOOZED}`,
  [ActionKeys.EVENT_CLOSE]: `${MonitorCaseStateMapping.CLOSED}`,
};

const MWP_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

export const fetchCaseQueue = ({
  pageNumber = 0,
  pageSize = 1000,
  tableType,
  selectedRibbonFilter,
}) => {
  if (tableType === 'cases') {
    return request.get('/rtmc/case-queue', {
      params: {
        pageNumber,
        pageSize,
        status: CASE_QUEUE_STATUSES,
        filterType: selectedRibbonFilter,
      },
    });
  } else if (tableType === 'history') {
    return request.get('/rtmc/case-queue', {
      params: { pageNumber, pageSize, status: CASE_HISTORY_STATUSES },
    });
  }
};

export const changeCasesStatus = (values) => {
  const payload = [{ ...values, state: CASE_STATE_MAPPING[values.state] }];
  return request.patch('/rtmc/change-cases-status', payload);
};

export const changeBulkCasesStatus = ({ state, caseIds }) => {
  const payload = caseIds.map((id) => ({ id, state: CASE_STATE_MAPPING[state] }));
  return request.patch('/rtmc/change-cases-status', payload);
};

export const editCaseQueue = (values) => {
  const payload = [{ ...values }];
  return request.patch('/rtmc/edit-case-queue', payload);
};

export const getAssetCase = (assetId, page = 0, pageSize = 1000) => {
  return request.get(`/rtmc/asset/${assetId}/cases`, {
    params: { status: CASE_QUEUE_STATUSES, page, pageSize },
  });
};

export const getAssetCaseHistory = (assetId, days = 7) => {
  return request.get(`/rtmc/asset/${assetId}/cases`, {
    params: { status: CASE_HISTORY_STATUSES, days },
  });
};

export const getAssetTaskHistory = (assetIds, serviceGroupIds) => {
  return request.get(`/mwp/tasks/asset-ids`, {
    params: { assetIds: assetIds.join(','), serviceGroupIds },
    baseURL: MWP_BASE_URL,
  });
};

export const getActiveCaseCount = () => {
  return request.get(`/rtmc/active-case-count`);
};
