import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
// import { usePerson } from '@ge/feat-admin/data-hooks/use-person';
import { EntityType } from '@ge/models/constants';

import EntityDetailHeader, { TypeIcon } from '../entity-details-header';

import RemoveContainer from './remove-container/remove-container';
const PersonInfo = styled.div`
  display: block;
  margin-left: 10px;
`;

const PersonName = styled.div`
  display: block;
  font-size: 22px;
  font-weight: bold;
`;

const PersonType = styled.div`
  display: block;
  font-size: 11px;
  margin-top: 5px;
`;

export const PersonDetailsHeader = withRouter(({ person }) => {
  if (!person) {
    return null;
  }

  const getPersonType = () => person?.type && person?.type.join(' ');

  // DISABLING BUTTONS UNTIL FUNCTIONALITY IS DECIDED /////////////
  // const { deletePerson, disablePerson } = usePerson();
  // const handleDisableClick = () => {
  //   disablePerson(person);
  // };
  // const handleDeleteClick = () => {
  //   deletePerson({ username: person.username });
  // };
  return (
    <EntityDetailHeader type={EntityType.PERSON}>
      <div className="left">
        <div className="related"></div>
        <div className="details">
          <div className="left">
            <TypeIcon icon={Icons.WORKER} />
          </div>
          <div className="right">
            <div className="title">
              <PersonInfo>
                <PersonName>{`${person.firstName} ${person.lastName}`}</PersonName>
                <PersonType>{getPersonType()}</PersonType>
              </PersonInfo>
            </div>
          </div>
        </div>
      </div>
      <RemoveContainer person={person} />
    </EntityDetailHeader>
  );
});

PersonDetailsHeader.propTypes = {
  person: PropTypes.instanceOf(Object),
};

PersonDetailsHeader.defaultProps = {
  person: null,
};

export default PersonDetailsHeader;
