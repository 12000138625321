import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { getTurbineStateType, getTurbineStateColor } from '@ge/shared/util/turbine-state';

import { PlanningContext } from '../../../context/planning-provider';
import { CrewBadge } from '../../crew-badge';
import { TaskGroupScheduleData } from '../task-group-schedule-data';

import { TaskItem } from './task-item';

const TaskContainer = styled.div`
  box-sizing: border-box;
  border: 1px dashed ${(props) => props.theme.manage.tabs.taskGroup.dashedBorderColor};
  border-radius: 3px;
  box-shadow: ${(props) => props.theme.manage.tabs.taskGroup.boxShadow};
  margin: 5px 12px 10px 5px;
  color: ${(props) => props.theme.manage.tabs.taskGroup.textColor};
  > .header {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.manage.taskItem.borderColor};
    position: relative;
    .site {
      font-size: 9px;
    }
    .asset {
      font-family: 'GE Inspira';
      font-size: 17px;
      line-height: 20px;
    }
    .time {
      margin-left: auto;
    }
  }
  > .date-container {
    padding: 8px;
  }
  .tasks > div {
    background: ${(props) => props.theme.manage.dayCard.detail.taskBackground};
  }
  > .crew-container {
    display: flex;
    padding: 11px 8px;
    justify-content: space-between;
    border-top: solid 1px ${(props) => props.theme.manage.taskItem.borderColor};
    border-bottom: solid 1px ${(props) => props.theme.manage.taskItem.borderColor};
    align-items: center;
    .crew-name {
      font-size: 11px;
      font-weight: 500;
    }
    .crew-techs {
      display: flex;
    }
  }
  > .footer {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    button:first-of-type {
      color: currentColor;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
  &.hover {
    border: solid 1px ${(props) => props.theme.manage.dayCard.hoverBorder};
    box-shadow: 0 0 0 4px ${(props) => props.theme.manage.dayCard.hoverBoxShadow};
    background: ${(props) => props.theme.manage.dayCard.gradients.recommendedHover};
    cursor: pointer;
  }
`;

const TechsIcon = styled(Icon).attrs((props) => ({
  icon: Icons.TEAM,
  size: 12,
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-right: 6px;
  margin-top: -3px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-right: 4px;
`;

const StatusBadge = styled(Badge)`
  position: absolute;
  top: 4px;
  left: 5px;
  transform-origin: 0 0;
`;

export const TaskGroupItem = ({ taskGroup }) => {
  const { t } = useTranslation(['manage.planning']);
  const { planningState } = useContext(PlanningContext);

  return (
    <TaskContainer
      className={planningState.hoverItemId === taskGroup.id ? 'hover' : ''}
      onMouseEnter={() => planningState.setHoverItemId(taskGroup.id)}
      onMouseLeave={planningState.setHoverItemId}
    >
      <div className="header">
        {taskGroup.tasks[0].asset && (
          <StatusBadge
            small
            color={getTurbineStateColor(taskGroup.tasks[0].asset.state)}
            label={getTurbineStateType(taskGroup.tasks[0].asset.state)}
          />
        )}
        <StyledIcon icon={Icons.TURBINE} size={24} />
        <div>
          {taskGroup.tasks[0].site && <div className="site">{taskGroup.tasks[0].site.name}</div>}
          {taskGroup.tasks[0].asset && <div className="asset">{taskGroup.tasks[0].asset.name}</div>}
        </div>
        <div className="time">
          <TaskGroupScheduleData estimate={taskGroup.estimate} />
        </div>
      </div>
      <div className="date-container">
        <TaskGroupScheduleData
          scheduledStart={taskGroup.scheduledStart}
          scheduled={taskGroup.scheduled}
        />
      </div>
      <div className="tasks">
        {taskGroup.tasks.map((task, index) => (
          <TaskItem primary={index === 0} key={task.id} task={task} assigned />
        ))}
      </div>
      <div className="crew-container">
        <div className="crew-name">
          <TechsIcon />
          {`Crew ${taskGroup.crew.name}`}
        </div>
        <div className="crew-techs">
          {taskGroup.technicians.map((technician) => (
            <CrewBadge key={technician.id} technician={technician} overlap />
          ))}
        </div>
      </div>
      <div className="footer">
        <button type="button">{t('dismiss_backlog', 'Dismiss to Backlog')}</button>
        <Button primary>{t('schedule', 'Schedule')}</Button>
      </div>
    </TaskContainer>
  );
};

TaskGroupItem.propTypes = {
  taskGroup: PropTypes.instanceOf(Object).isRequired,
};
