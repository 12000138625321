import { Entity } from '../entity';

/**
 * A Task represents work to be done.
 */
export class Task extends Entity {
  /**
   * Type of task
   * @type {import('./constants').TaskType}
   */
  type;

  /**
   * Title of the task
   * @type {string}
   */
  title;

  /**
   * Source of the task
   * @type {import('./constants').TaskSource}
   */
  source;

  /**
   * Date task created (ISO string)
   * @type {string}
   */
  createdDate;

  /**
   * Due status of task
   * @type {import('./constants').TaskDue}
   */
  due;

  /**
   * @type {import('./constants').TaskStatus}
   */
  status;

  /**
   * @type {import('./constants').TaskPriority}
   */
  priority;

  /**
   * @param {Partial<Task>} [initObj] Task object to use to initialize class
   */
  constructor(initObj) {
    super(initObj);

    // Apply all known object values
    Object.assign(this, initObj);
  }
}
