import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';

const StyledSelect = styled.select`
  border: solid 1px ${(props) => props.theme.profile.selectBorderColor};
  padding: 10px;
  width: 120px;
`;

const StyledLabel = styled.label`
  margin-bottom: 4px;
  display: block;
`;

const ProfilePage = styled.div`
  padding: 20px;
`;

export const Profile = () => {
  const { t } = useTranslation();
  const { setLanguage } = useStoreActions((actions) => actions.prefs);
  const { language } = useStoreState((state) => state.prefs);

  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  return (
    <PageContainer>
      <h1 className="pageTitle">{t('profile', 'Profile')}</h1>
      <ProfilePage>
        <StyledLabel className="body-1" htmlFor="lang">
          {t('language', 'Language')}:
        </StyledLabel>
        <StyledSelect
          id="lang"
          onChange={(e) => handleLanguageChange(e.target.value)}
          value={language}
        >
          <option value="en">English</option>
          <option value="de">German</option>
          <option value="pt-BR">Portugese-BR</option>
        </StyledSelect>
      </ProfilePage>
    </PageContainer>
  );
};
