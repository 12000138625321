import { request } from './api';
// import * as request from '@ge/shared/services/api';

export const getDamagesResult = (filters, options) =>
  request.post('/rei/damages/get-damages', filters, options);

export const getDamageReport = (data, options) =>
  request.post(`/rei/damages/damage-report`, data, options);
export const fetchDamageDetailsById = (damageId, options) =>
  request.get(`/rei/damages/damage-details/${damageId}`, options);

export const createPacCase = (data, options) =>
  request.post(`/rei/damages/create-pac-case`, data, options);
