import * as request from '@ge/shared/services/api';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

export const fetchPersonById = (username) => {
  return request.get(`/cmn/admin/users/${username}`, { baseURL: USERS_BASE_URL });
};

export const fetchUserRoleSubsets = () => {
  return request.get('/cmn/role-subsets', {
    baseURL: USERS_BASE_URL,
  });
};
