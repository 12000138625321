import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Input } from '@ge/components/input';
import { Textarea } from '@ge/components/textarea';
import { NotesWidgetStateKeys } from '@ge/feat-reporting/components/widgets/notes-widget';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';

const StyledNotesForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-top: 8px;
`;

const StyledTextarea = styled(Textarea)`
  box-sizing: border-box;
  height: 100px;
  width: 100%;
`;

const CharLimit = styled.span`
  font-size: 9px;
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  font-family: 'Museo Sans';
  font-size: 9px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 10px;
  text-transform: none;
  margin-left: 8px;
`;

const NAMESPACE = 'reporting.sidebar';
const TEXT_AREA_NOTE_CHAR_LIMIT = 8192;
const TITLE_NOTE_CHAR_LIMIT = 150;

export const NotesModule = ({ id, configuration }) => {
  const notesTitle = useRef(null);
  const notesBody = useRef(null);

  const [hasChanged, setHasChanged] = useState(false);
  const { getWidgetState, setWidgetState, getWidgetLocalState, setWidgetLocalState } =
    useContext(ReportsContext);
  const notesState = getWidgetState(id, NotesWidgetStateKeys.VALUES);

  const { t } = useTranslation([NAMESPACE], {
    useSuspense: false,
  });

  // Initialize default notes value in widget state
  useEffect(() => {
    if (!notesState) {
      setWidgetState({
        widgetId: id,
        key: NotesWidgetStateKeys.VALUES,
        value: {
          body: configuration?.body ?? '',
          title: configuration?.title ?? '',
        },
      });
    }
  }, [id, notesState, configuration, setWidgetState]);

  // Keep `input` values in sync with widget state changes
  useEffect(() => {
    if (notesState) {
      if (notesBody.current) {
        notesBody.current.value = notesState.body;
      }
      if (notesTitle.current) {
        notesTitle.current.value = notesState.title;
      }
      setHasChanged(false);
    }
  }, [notesState, setHasChanged]);

  const handleChange = useCallback(() => {
    if (notesState) {
      if (
        notesBody.current?.value === notesState.body &&
        notesTitle.current?.value === notesState.title
      ) {
        setHasChanged(false);
      } else {
        setHasChanged(true);
      }
    }
  }, [notesState, setHasChanged]);

  const handleSetReportNotes = useCallback(
    (id) => {
      setWidgetState({
        widgetId: id,
        key: NotesWidgetStateKeys.VALUES,
        value: {
          body: notesBody.current.value,
          title: notesTitle.current.value,
        },
      });
      const isOverflowed = getWidgetLocalState(id, NotesWidgetStateKeys.OVERFLOW);
      if (isOverflowed) {
        setWidgetLocalState({
          widgetId: id,
          key: NotesWidgetStateKeys.OVERFLOW,
          value: false,
        });
      }
    },
    [setWidgetState, getWidgetLocalState, setWidgetLocalState],
  );

  return (
    <>
      <StyledNotesForm>
        <StyledInput
          ref={notesTitle}
          label={
            <>
              {t('notes_title', 'Notes Title')}
              <CharLimit>{`${TITLE_NOTE_CHAR_LIMIT} ${t('char_limit', 'char limit')}`}</CharLimit>
            </>
          }
          maxLength={TITLE_NOTE_CHAR_LIMIT}
          onChange={() => handleChange()}
        />
        <StyledTextarea
          ref={notesBody}
          label={t('body', 'Body')}
          maxLength={TEXT_AREA_NOTE_CHAR_LIMIT}
          onChange={() => handleChange()}
        />
        <StyledButton
          disabled={!hasChanged}
          primary
          type="button"
          onClick={() => handleSetReportNotes(id)}
        >
          {t('general:save', 'Save')}
        </StyledButton>
      </StyledNotesForm>
    </>
  );
};

NotesModule.propTypes = {
  id: PropTypes.string.isRequired,
  configuration: PropTypes.object,
};
