import { PropTypes } from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CommandsController } from '@ge/components/controllers/commands-controller';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Title, Text } from '@ge/components/typography';
import {
  AssetCommandStatus,
  AssetConnectionStatus,
  EntityTab,
  Feature,
} from '@ge/models/constants';
import { EntityDetailsContext, useAppNotificationContext } from '@ge/shared/context';
import { useAssetCommands } from '@ge/shared/data-hooks';
import { typography } from '@ge/tokens/typography';

const Container = styled.div`
  padding: 14px 0 0 10px;
  width: 150px;
  height: 250px;
  display: flex;
  flex-direction: column;
  .asset-controls {
    background: ${(props) => props.theme.entityDetails.sitePanelBlockBackground};
  }
`;

const CommandsContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  margin-bottom: 6px;
`;

const CommandsTitle = styled(Title).attrs(() => ({
  level: 4,
}))`
  margin-bottom: 10px;
`;

const CommandsDisabledText = styled(Text).attrs(() => ({
  level: 2,
  type: typography.textTypes.body,
}))`
  color: ${(props) => props.theme.dangerColor};
  margin-top: 0;
  display: block;
`;

export const AssetController = ({ asset }) => {
  const { t } = useTranslation(['entity-details']);
  const assets = useMemo(() => [asset], [asset]);
  const [menuActive, setMenuActive] = useState(true);
  const { connection } = asset?.metrics ?? {};
  const { showAssetDetails, showSiteDetails } = useContext(EntityDetailsContext);
  const notifications = useAppNotificationContext();

  const { data, isLoading } = useAssetCommands({
    assets,
    isActive: menuActive,
  });

  const { notify } = notifications[Feature.MONITOR]?.commands ?? {};

  const handleExecute = (context) => {
    if (!(context && notify)) {
      return;
    }

    notify({
      ...context,
      onAssetDetails: (id) => showAssetDetails(id, EntityTab.REALTIME_INFO),
      onSiteDetails: (id) => showSiteDetails(id, EntityTab.REALTIME_INFO),
    });
  };

  const handleOnClose = () => {
    setMenuActive(false);
  };

  return (
    <Container>
      <CommandsTitle level={4}>{t('asset_panel.commands', 'Commands')}</CommandsTitle>
      <CommandsContainer>
        <ScrollingContainer>
          <CommandsController
            assets={[asset]}
            commandsData={data}
            disabled={
              !asset?.metrics ||
              data?.status[0]?.status === AssetCommandStatus.BLOCKED ||
              isLoading ||
              connection?.value === AssetConnectionStatus.NOCOMM ||
              data?.inProgressLimitReached
            }
            onExecute={handleExecute}
            onClose={handleOnClose}
          />
        </ScrollingContainer>
      </CommandsContainer>
      {(data?.status?.[0]?.reason && (
        <CommandsDisabledText>
          {t('general:disabled', 'Disabled')}: {data?.status?.[0]?.reason}
        </CommandsDisabledText>
      )) ||
        (data?.inProgressLimitReached && (
          <CommandsDisabledText>
            {t('general:disabled', 'Disabled')}: {t('command_menu.max_inprogress_limit')}
          </CommandsDisabledText>
        ))}
    </Container>
  );
};

AssetController.propTypes = {
  asset: PropTypes.instanceOf(Object).isRequired,
};
