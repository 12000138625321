import { useMutation, useQueryClient } from 'react-query';

import { updateCases } from '@ge/feat-monitor/services';

import { CasesQueryKeys } from './cases/query-keys';

export const updateCasesCache = (queryClient, cases) => {
  if (!queryClient) return;
  const queriesData = queryClient.getQueriesData(CasesQueryKeys.lists());
  cases.forEach((_case) => {
    queriesData?.forEach((queryData) => {
      let cacheKey = queryData[0][2];
      if (cacheKey.siteIds.includes(_case.site.id) && !cacheKey.lastUpdatedStart) {
        queryClient.refetchQueries(CasesQueryKeys.list(cacheKey));
      }
    });
  });
};

const updateInCaseListCache = (queryClient, response) => {
  queryClient.setQueriesData(CasesQueryKeys.lists(), (prev) => {
    const updatedCases = prev?.data.map((_case) => {
      if (_case.id === response.id) {
        return response;
      }
      return _case;
    });
    return { ...prev, data: updatedCases };
  });
};

export const updateCasesCacheList = (queryClient, cases) => {
  if (!queryClient) return;
  updateInCaseListCache(queryClient, cases);
};

export const useUpdateCases = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCases, {
    onSuccess: (data) => {
      if (data?.cases?.length) {
        updateCasesCache(queryClient, data.cases);
      }
    },
  });
};
