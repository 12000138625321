import * as request from '@ge/shared/services/api';

export const fetchAlerts = async () => request.get('/alerts');

// TODO: This is a place where it will probably make sense to
// set up some pub/sub websocket updates so the alerts remain
// up-to-date as data changes underneath in real-time.
export const subscribe = () => null;

/**
 * Dynamically load the alerts mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/alerts/alerts.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
