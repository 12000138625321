import styled, { css } from 'styled-components';

import { Icon } from '@ge/components/icon';
import { EntityType } from '@ge/models/constants';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { typography } from '@ge/tokens';

export const HeaderIcon = styled(Icon).attrs((props) => ({
  size: props.size ?? 11,
  color: props.theme.entityDetails.headerIconColor,
  viewbox: props.viewbox,
}))`
  margin-right: 5px;
`;

export const ButtonIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.entityDetails.headerIconColor,
}))``;

export const TypeIcon = styled(Icon).attrs((props) => ({
  size: 36,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin: ${(props) =>
    props.type === EntityType.CASE && headerTypeCase ? '15px 10px 15px 0' : '0'};
`;

export const MetricIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 4px;
`;

export const StyledDetailsDismissInert = styled(DetailsDismissInert)`
  height: auto;
  width: auto;
`;

export const StyledDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 5px 10px;
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;

export const StyledDetailMetrics = styled.section`
  justify-content: flex-end;
  .make-model {
    margin: 1px 0 5px 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

const EntityDetailHeader = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin: 12px 10px 0 8px;

  section {
    display: block !important;
    padding: 10px 12px 8px;
    &:first-child {
      flex: 1;
    }
  }
  > .left {
    flex: 1;
    font-size: 12px;
  }
  .asset-bottom {
    button {
      display: flex;
      align-items: center;
    }
  }
  .asset-name {
    font-size: 14px;
    font-weight: ${typography.weight.medium};
    padding: 0 6px;
    display: inline-block;
    + svg {
      margin-right: 0;
    }
  }
  .related {
    padding: 3px 3px;
    border-radius: 3px 3px 0px 0px;
    background: ${(props) => props.theme.admin.panel.header.gradient};
    box-shadow: ${(props) => props.theme.analyze.troubleshooting.header.shadow};
    background-image: ${(props) => props.theme.analyze.troubleshooting.header.background};
    > div {
      display: flex;
      align-items: center;
      .right {
        flex: 1;
        text-align: right;
      }
    }
    a {
      color: currentcolor;
      text-decoration: none;
    }
    button {
      color: currentcolor;
      padding: 0px;
    }
  }
  .phone {
    position: relative;
    top: -2px;
    svg {
      margin-right: 0;
    }
  }
  .details {
    display: flex;
    align-items: center;
    .owner {
      display: flex;
      align-items: center;
      svg {
        margin-left: 8px;
        margin-right: 3px;
      }
    }
    .status {
      color: ${(props) => props.theme.analyze.troubleshooting.header.statusColor};
      position: absolute;
      right: 11px;
      top: 11px;
      svg {
        margin: 0 0 2px 6px;
      }
    }
  }
  .source-details {
    display: flex;
    line-height: 28px;
    align-items: center;
    .source {
      margin-left: auto;
      color: ${(props) => props.theme.analyze.troubleshooting.header.sourceDetails.textColor};
    }
  }
  .actions {
    padding: 10px 10px 5px 10px;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    button,
    a {
      align-items: center;
      color: currentcolor;
      display: flex;
      font-weight: ${typography.weight.bold};
      font-size: 12px;
      width: 100%;
      display: block;
      text-align: left;
      margin-bottom: 6px;
      padding: 0 6px 0 0;
      text-transform: uppercase;
      text-decoration: none;
      svg {
        margin-bottom: 3px;
      }
    }
    .last-btn {
      border-top: solid 1px
        ${(props) => props.theme.analyze.troubleshooting.header.actionsBorderColor};
      padding-top: 8px;
      margin-top: 10px;
      max-width: 120px;
      button,
      a {
        margin-bottom: 0;
      }
    }
  }
  .list-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
      margin-left: 10px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  ${(props) => props.type === EntityType.CASE && headerTypeCase};
  ${(props) => props.type === EntityType.TASK && headerTypeTask};
  ${(props) => props.type === EntityType.EVENT && headerTypeEvent};
  ${(props) => props.type === EntityType.SITE && headerTypeSite};
  ${(props) => props.type === EntityType.ASSET && headerTypeAsset};
  ${(props) => props.type === EntityType.PERSON && headerTypePerson};
  ${(props) => props.type === EntityType.ROLE && headerTypeRole};
  ${(props) => props.type === EntityType.ANALYSIS_TEMPLATE && headerTypeSite};
`;

const headerTypeCase = css`
  .details {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.detailsBackground};
    border-bottom-left-radius: 6px;
    padding: 10px;
    position: relative;
    > svg {
      margin: 0px 18px 0 5px;
    }
  }
  .code {
    font-size: 11px;
    text-transform: uppercase;
    span:last-of-type {
      margin-left: 5px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
    span {
      color: currentcolor;
      padding: 0px;
      margin: 4px 0 8px;
      font-size: 20px;
      line-height: 22px;
    }
  }
  .actions {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.actionsBackground};
    min-width: 100px;
  }
`;

const headerTypeTask = css`
  .details {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.detailsBackground};
    .left {
      background-color: ${(props) => props.theme.analyze.troubleshooting.header.actionsBackground};
      padding: 12px;
      display: flex;
      align-items: center;
      align-self: stretch;
    }
    .right {
      flex: 1;
      padding: 6px 16px;
    }
  }
  span.code {
    margin-left: 12px;
  }
  .task-type {
    color: ${(props) => props.theme.analyze.troubleshooting.header.statusColor};
    text-transform: uppercase;
  }
  .title {
    text-decoration: none;
    color: currentcolor;
    padding: 0px;
    margin: 4px 0 6px;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: flex-start;
    svg {
      margin: 6px 8px 0 0;
    }
    .task-details {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      line-height: 15px;
      margin-left: auto;
      min-width: 20%;
      svg {
        margin: 0 0 1px 4px;
        &.task-flag-icon {
          margin-right: 8px;
        }
      }
    }
    .scheduled {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: ${typography.weight.bold};
    }
    .unscheduled {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: ${typography.weight.bold};
    }
    .completed {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: ${typography.weight.bold};
    }
    .recurring {
      font-size: 11px;
    }
  }
  .source-details {
    border-bottom: 1px solid
      ${(props) => props.theme.analyze.troubleshooting.header.sourceDetails.borderColor};
    margin-bottom: 8px;
  }
  .actions {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.actionsBackground};
    min-width: 100px;
  }
`;

const headerTypeEvent = css`
  > .left {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.detailsBackground};
  }
  .details {
    .left {
      padding: 12px 15px;
      display: flex;
    }
    .right {
      flex: 1;
      padding: 6px;
    }
  }
  .asset-bottom {
    margin-top: 5px;
  }
  span.code {
    margin-left: 12px;
  }
  .task-type {
    color: ${(props) => props.theme.analyze.troubleshooting.header.statusColor};
    text-transform: uppercase;
  }
  .title {
    text-decoration: none;
    color: currentcolor;
    padding: 0px;
    margin: 0 0 6px;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  .new {
    margin-left: auto;
    text-transform: uppercase;
    font-weight: ${typography.weight.bold};
    svg {
      margin-left: 5px;
    }
  }
  .right {
    .list-right:last-of-type {
      margin-top: 7px;
    }
  }
  .actions {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.actionsBackground};
  }
`;

const headerTypeSite = css`
  background-image: ${(props) => props.theme.entityDetails.headerBackground};
  position: relative;
  > section {
    display: block;
    flex: 1 0;
    justify-content: flex-end;
    padding: 10px;
  }
  .details-weather {
    justify-content: flex-end;
  }
  .metrics {
    .model-count {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 5px;
    }
    .list-right {
      margin-top: 7px;
    }
  }
  .actions {
    border-left: solid 1px ${(props) => props.theme.entityDetails.headerBorderColor};
    min-width: 120px;
  }
`;

const headerTypeAsset = css`
  background-image: ${(props) => props.theme.entityDetails.headerBackground};
  position: relative;
  > section {
    display: flex;
    flex: 1 0;
  }
  .asset-model {
    box-sizing: border-box;
    padding-top: 20px;
  }
  .asset-details {
    margin-top: 5px;
    h5:first-child {
      margin-right: 20px;
    }
  }
  .actions {
    border-left: solid 1px ${(props) => props.theme.entityDetails.headerBorderColor};
    min-width: 120px;
  }
`;

const headerTypePerson = css`
  .details {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.detailsBackground};
    border-bottom-left-radius: 6px;
    padding: 10px;
    position: relative;
    > svg {
      margin: 0px 18px 0 5px;
    }
  }
  .code {
    font-size: 11px;
    text-transform: uppercase;
    span:last-of-type {
      margin-left: 5px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
    span {
      color: currentcolor;
      padding: 0px;
      margin: 4px 0 8px;
      font-size: 20px;
      line-height: 22px;
    }
  }
  .actions {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.actionsBackground};
    min-width: 100px;
  }
`;

const headerTypeRole = css`
  .details {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.detailsBackground};
    border-bottom-left-radius: 6px;
    padding: 10px;
    position: relative;
    > svg {
      margin: 0px 18px 0 5px;
    }
  }
  .code {
    font-size: 11px;
    text-transform: uppercase;
    span:last-of-type {
      margin-left: 5px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
    span {
      color: currentcolor;
      padding: 0px;
      margin: 4px 0 8px;
      font-size: 20px;
      line-height: 22px;
    }
  }
  .actions {
    background-color: ${(props) => props.theme.analyze.troubleshooting.header.actionsBackground};
    min-width: 100px;
  }
`;

export default EntityDetailHeader;
