import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/de';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import { FullLoader } from '@ge/components/loader';
import { TenantProvider } from '@ge/feat-admin/context/tenant-provider';
import { loadables as adminLoadables } from '@ge/feat-admin/pages';
import { loadables as analyzeLoadables } from '@ge/feat-analyze/pages';
import { loadables as inspectionsLoadables } from '@ge/feat-inspections/pages';
import { loadables as manageLoadables } from '@ge/feat-manage/pages';
import { loadables as monitorLoadables } from '@ge/feat-monitor/pages';
import { loadables as reportingLoadables } from '@ge/feat-reporting/pages';
import { AuthStrategy, Capability, PermissionScope } from '@ge/models/constants';
import { CommandNotificationController } from '@ge/shared/components/notifications';
import { AppNotificationProvider } from '@ge/shared/context';

import GlobalStyles from '../assets/styles/global';

import { FourOhFour } from './components/404/404';
import { AuthCallback } from './components/auth/auth-callback';
import { SilentRenew } from './components/auth/auth-silent-renew';
import { Logout } from './components/auth/logout';
import { MultiTenantSignIn } from './components/auth/multiTenantSignin';
import { popoutLoadables } from './components/entity-details/popouts';
import { PrivateRoute } from './components/private-route/private-route';
import { Profile } from './components/profile/profile';
import { useNavigation } from './hooks/use-navigation';
import usePermissionListener from './hooks/use-permission-listener';
import { APP_BASE_ROUTE } from './models/routes';

// #RENINSPECT MIGRATION NOTES#
// need to verify that capabilities defined on inspections routes are correct (awaiting feedback)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) =>
        !(failureCount > 3 || [401, 404].includes(error?.response?.status)),
    },
  },
});

const AppRoutes = () => {
  const { getDefaultLevel2Nav, getDefaultLevel3Nav } = useNavigation();
  const getProfileLang = useStoreState((state) => state.prefs.language);
  if (getProfileLang === 'pt-BR') {
    dayjs.locale('pt-br');
  } else if (getProfileLang === 'de') {
    dayjs.locale('de');
  } else {
    dayjs.locale('en');
  }

  return (
    <Router>
      <AppNotificationProvider>
        <TenantProvider>
          <CommandNotificationController />
          <QueryParamProvider ReactRouterRoute={Route}>
            <Suspense fallback={<FullLoader />}>
              <Switch>
                {/* Auth routes */}
                <Route exact={true} path="/signin" component={MultiTenantSignIn} />
                <Route exact={true} path="/signin-oidc" component={AuthCallback} />
                <Route path="/login/oauth2/**" component={AuthCallback} />
                <Route exact={true} path="/silentrenew" component={SilentRenew} />
                <Route exact={true} path="/logout" component={Logout} />
                {/* Base route */}
                <PrivateRoute exact path={APP_BASE_ROUTE} />
                <PrivateRoute path="/profile" exact component={Profile} />
                {/* Monitor feature routes */}
                <PrivateRoute
                  isPopout
                  path="/escalate-case"
                  loadUnhydrated={true}
                  component={popoutLoadables.escalateCase}
                  capabilities={[
                    { capability: Capability.CASES, scopes: [PermissionScope.EDIT] },
                    {
                      capability: Capability.NOTES_AND_ATTACHMENTS,
                      scopes: [PermissionScope.CREATE],
                    },
                    { capability: Capability.EMAIL_NOTIFICATION, scopes: [PermissionScope.CREATE] },
                  ]}
                  authStrategy={AuthStrategy.ALL}
                />
                <PrivateRoute
                  path="/monitor"
                  exact
                  redirectTo={getDefaultLevel3Nav('/monitor/sites')}
                />
                <PrivateRoute
                  path="/monitor/sites"
                  exact
                  redirectTo={getDefaultLevel3Nav('/monitor/sites')}
                />
                <PrivateRoute
                  path="/monitor/cases"
                  exact
                  component={monitorLoadables.issues}
                  redirectTo={getDefaultLevel3Nav('/monitor/cases')}
                />
                <PrivateRoute
                  path="/monitor/issues"
                  exact
                  component={monitorLoadables.issues}
                  redirectTo={getDefaultLevel3Nav('/monitor/cases')}
                />
                <PrivateRoute
                  path="/monitor/issues/:filter"
                  exact
                  component={monitorLoadables.issues}
                  redirectTo={getDefaultLevel3Nav('/monitor/cases')}
                />
                <PrivateRoute
                  path="/monitor/cases/:filter"
                  exact
                  component={monitorLoadables.issues}
                  capabilities={[
                    {
                      capability: Capability.CASES_RECOMMENDATIONS,
                      scopes: [PermissionScope.VIEW],
                    },
                    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/monitor/assets"
                  exact
                  component={monitorLoadables.assets}
                  capabilities={[
                    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/monitor/sites/:filter"
                  exact
                  component={monitorLoadables.sites}
                  capabilities={[
                    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/monitor/current-status"
                  exact
                  component={monitorLoadables.currentStatus}
                />
                <PrivateRoute path="/monitor/reports" exact component={monitorLoadables.reports} />
                {/* Execute feature routes */}
                {/* Redirect from /manage to execute for external links DE182907 */}
                <PrivateRoute path="/manage" redirectTo={getDefaultLevel2Nav('/execute')} />
                <PrivateRoute path="/execute" exact redirectTo={getDefaultLevel2Nav('/execute')} />
                <PrivateRoute
                  path="/execute/overview"
                  exact
                  component={manageLoadables.overview}
                  sidebar={false}
                  capabilities={[
                    { capability: Capability.WORK_PLAN, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/execute/cases-tasks"
                  exact
                  component={manageLoadables.casesTasks}
                  sidebar={false}
                  capabilities={[
                    { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                {/* Analyze feature routes */}
                <PrivateRoute path="/analyze" exact redirectTo={getDefaultLevel2Nav('/analyze')} />
                <PrivateRoute
                  path="/analyze/financial"
                  exact
                  component={analyzeLoadables.financial}
                />
                <PrivateRoute
                  path="/analyze/contracts"
                  exact
                  component={analyzeLoadables.contracts}
                />
                <PrivateRoute
                  path="/analyze/manual-adjustment"
                  exact
                  component={analyzeLoadables.manualAdjustment}
                  capabilities={[
                    {
                      capability: Capability.CONTRACTUAL_AVAILABILITY_MAL,
                      scopes: [PermissionScope.VIEW],
                    },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/fleet-overview/region"
                  exact
                  component={analyzeLoadables.fleetOverview}
                  capabilities={[
                    { capability: Capability.ADVANCED_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/fleet-overview/platform"
                  exact
                  component={analyzeLoadables.fleetOverview}
                  capabilities={[
                    { capability: Capability.ADVANCED_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/region/:region"
                  exact
                  component={analyzeLoadables.region}
                  capabilities={[
                    { capability: Capability.FRO_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/sites"
                  exact
                  component={analyzeLoadables.sites}
                  capabilities={[
                    { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                {/* Should be removed once sites page is finalized */}
                <PrivateRoute
                  path="/analyze/sites-all"
                  exact
                  component={analyzeLoadables.sitesAll}
                  capabilities={[
                    { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/site/:site"
                  exact
                  component={analyzeLoadables.site}
                  capabilities={[
                    { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/assets"
                  exact
                  component={analyzeLoadables.assets}
                  capabilities={[
                    { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/analyze/troubleshooting/:anomalyId"
                  exact
                  component={analyzeLoadables.troubleshooting}
                />
                <PrivateRoute
                  path="/analyze/asset-overview"
                  exact
                  component={analyzeLoadables.assetOverview}
                  sidebar={false}
                  capabilities={[
                    { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                    { capability: Capability.DATA_EXPLORER, scopes: [PermissionScope.EDIT] },
                  ]}
                  authStrategy={AuthStrategy.ANY}
                />
                {/* Should be removed once overview page is finalized */}
                <PrivateRoute
                  path="/analyze/asset-overview-all"
                  exact
                  component={analyzeLoadables.assetOverviewWind}
                  sidebar={false}
                  capabilities={[
                    { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                    { capability: Capability.DATA_EXPLORER, scopes: [PermissionScope.EDIT] },
                  ]}
                  authStrategy={AuthStrategy.ANY}
                />
                <PrivateRoute
                  path="/analyze/data-download"
                  exact
                  component={analyzeLoadables.dataDownload}
                  sidebar={false}
                />
                {/* Admin routes */}
                <PrivateRoute path="/admin" exact redirectTo={getDefaultLevel2Nav('/admin')} />
                <PrivateRoute
                  path="/admin/people"
                  exact
                  component={adminLoadables.people}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] },
                  ]}
                />
                <PrivateRoute
                  path="/admin/roles"
                  exact
                  component={adminLoadables.roles}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] },
                  ]}
                />
                <PrivateRoute
                  path="/admin/configure"
                  exact
                  redirectTo={getDefaultLevel3Nav('/admin/configure')}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/admin/configure/:filter"
                  exact
                  component={adminLoadables.configure}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                {/* Inspection routes */}
                <PrivateRoute path="/inspect" exact redirectTo={getDefaultLevel2Nav('/inspect')} />
                <PrivateRoute
                  path="/inspect/inspection-reports"
                  exact
                  component={inspectionsLoadables.reports}
                  capabilities={[
                    {
                      capability: Capability.REI_INSPECTION_REPORTS,
                      scopes: [PermissionScope.VIEW],
                    },
                  ]}
                />
                <PrivateRoute
                  exact={true}
                  path="/inspect/image-viewer/:img/:anno"
                  component={inspectionsLoadables.imageViewer}
                  capabilities={[
                    { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/results"
                  exact
                  component={inspectionsLoadables.results}
                  capabilities={[
                    { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/lists"
                  exact
                  component={inspectionsLoadables.list}
                  capabilities={[
                    { capability: Capability.REI_LISTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/upload/:id"
                  exact
                  component={inspectionsLoadables.upload}
                  capabilities={[
                    { capability: Capability.REI_POST_PROCESS, scopes: [PermissionScope.CREATE] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/preview/:id"
                  exact
                  component={inspectionsLoadables.preview}
                  capabilities={[
                    { capability: Capability.REI_POST_PROCESS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/execution/:id"
                  exact
                  component={inspectionsLoadables.execution}
                  capabilities={[
                    { capability: Capability.REI_POST_PROCESS, scopes: [PermissionScope.CREATE] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/post-process/:id"
                  exact
                  component={inspectionsLoadables.postProcess}
                  capabilities={[
                    { capability: Capability.REI_POST_PROCESS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/report-generation/:id"
                  exact
                  component={inspectionsLoadables.reportGeneration}
                  capabilities={[
                    { capability: Capability.REI_GENERATE_REPORT, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/performance"
                  exact
                  component={inspectionsLoadables.performanceDashboard}
                  capabilities={[
                    { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspect/analytics"
                  exact
                  component={inspectionsLoadables.analyticDashboard}
                  capabilities={[
                    { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                {/* Popouts for entity-specific detail views */}
                <PrivateRoute isPopout path="/site/:id" component={popoutLoadables.site} />
                <PrivateRoute isPopout path="/event/:id" component={popoutLoadables.event} />
                <PrivateRoute isPopout path="/storage/:id" component={popoutLoadables.site} />
                <PrivateRoute isPopout path="/case/:id" component={popoutLoadables.case} />
                <PrivateRoute isPopout path="/fhp/:id" component={popoutLoadables.fhp} />
                <PrivateRoute isPopout path="/turbine/:id" component={popoutLoadables.turbine} />
                <PrivateRoute isPopout path="/ticket/:id" component={popoutLoadables.ticket} />
                <PrivateRoute isPopout path="/inverter/:id" component={popoutLoadables.turbine} />
                <PrivateRoute
                  isPopout
                  path="/newticket/:eventId"
                  component={popoutLoadables.newTicket}
                />
                <PrivateRoute isPopout path="/task/:id" component={popoutLoadables.task} />
                <PrivateRoute
                  isPopout
                  path="/caseprocedure/:assetId/:eventCode"
                  component={popoutLoadables.caseProcedure}
                />
                <PrivateRoute
                  isPopout
                  path="/inspection-results/:id"
                  component={popoutLoadables.inspectionDamage}
                  capabilities={[
                    { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/inspectiondamage/:id"
                  component={popoutLoadables.inspectionDamage}
                  capabilities={[
                    { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/reporting/reports"
                  exact
                  component={reportingLoadables.reports}
                  capabilities={[
                    { capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] },
                  ]}
                  redirectTo={'/reporting/reports/my-reports'}
                />
                <PrivateRoute
                  path="/reporting/reports/:filter"
                  exact
                  component={reportingLoadables.reports}
                  capabilities={[
                    { capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/reporting/reports/:filter/:id"
                  exact
                  component={reportingLoadables.reports}
                  capabilities={[
                    { capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] },
                  ]}
                />
                <PrivateRoute
                  path="/admin/application-configuration/monitor/distribution-lists"
                  exact
                  component={adminLoadables.distributionlists}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] },
                    { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.ADMIN] },
                  ]}
                  authStrategy={AuthStrategy.ALL}
                />
                <PrivateRoute
                  path="/admin/application configuration/analyze/:filter"
                  exact
                  component={analyzeLoadables.configure}
                  capabilities={[
                    { capability: Capability.ANALYTICS, scopes: [PermissionScope.ADMIN] },
                  ]}
                />
                <PrivateRoute
                  path="/admin/application-configuration/monitor/handling-procedures"
                  exact
                  component={adminLoadables.handlingprocedures}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] },
                    { capability: Capability.FAULT_HANDLING, scopes: [PermissionScope.ADMIN] },
                  ]}
                  authStrategy={AuthStrategy.ALL}
                />
                <PrivateRoute
                  path="/admin/application-configuration/monitor/event-management"
                  exact
                  component={adminLoadables.eventmanagement}
                  capabilities={[
                    { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] },
                    { capability: Capability.ALARM_MANAGEMENT, scopes: [PermissionScope.ADMIN] },
                  ]}
                  authStrategy={AuthStrategy.ALL}
                />
                {/* Catch-all route for 404 */}
                <PrivateRoute component={FourOhFour} />
              </Switch>
            </Suspense>
          </QueryParamProvider>
        </TenantProvider>
      </AppNotificationProvider>
    </Router>
  );
};

const ApplicationRoutes = React.memo(AppRoutes);
function App() {
  const { getThemeJs } = useStoreState((state) => state.prefs);

  usePermissionListener();

  return (
    <ThemeProvider theme={getThemeJs()}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <ApplicationRoutes />
        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

App.defaultProps = {
  location: { hash: '' },
};

export default App;
