import { PropTypes } from 'prop-types';
import React, { useRef, useCallback, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { withHotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'react-image-annotate/SettingsProvider';
import { useDispatchHotkeyHandlers } from 'react-image-annotate/ShortcutsManager';
import styled, { withTheme } from 'styled-components';
import useKey from 'use-key-hook';

import { Button } from '@ge/components/button';

import { AnnotatorConstants } from '../../constants';
import getActiveImage from '../annotator/reducers/get-active-image';
import IconTools from '../icon-tools/icon-tools';
import ImageCanvas from '../image-canvas/image-canvas';

import full_screen from './icons/full_screen.svg';

const MainLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  div[tabindex='-1']:focus {
    outline: 0;
  }
`;

const ImageCanvasContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
`;

const NoImageContainer = styled.div`
  color: ${(props) => props.theme.postProcess.noImageContainerColor}
  font-size: 32px;
`;

const Workspace = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  transition: flex 1000ms ease;
`;

const IconToolsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  left: 10px;
  margin: 40px 0 10px 0;
  position: absolute;
  top: -30px;
`;

const FullscreenButton = styled(Button)`
  background-color: ${(props) => props.theme.postProcess.iconToolsBackground};
  bottom: 10px;
  height: 36px;
  left: 10px;
  line-height: 10px;
  padding: 0px;
  position: absolute;
  width: 36px;
  z-index: 9;
`;

const StyledFullScreen = styled(FullScreen)`
  height: 100%;

  &.collapse {
    display: none;
    height: 0%;
  }
`;

const HotkeyDiv = withHotKeys(({ hotKeys, children, divRef, ...props }) => (
  <div {...{ ...hotKeys, ...props }} ref={divRef}>
    {children}
  </div>
));

const HotKeyContainer = styled(HotkeyDiv)`
  color: ${(props) => props.theme.postProcess.hotkeyColor};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
    
  &.fullscreen: {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  },
`;

const MainLayout = ({
  state,
  dispatch,
  RegionEditLabel,
  onRegionClassAdded,
  onRegionSelect = () => {},
  selectedAnnotationId,
  updateSelectedAnnotationId,
  isLoading,
  SliderValueContext,
}) => {
  const { t } = useTranslation(['inspections.post-process']);

  const settings = useSettings();
  const memoizedActionFns = useRef({});

  const [fullScreen, setFullScreen] = useState(false);
  const fullScreenHandle = useFullScreenHandle();

  const action = (type, ...params) => {
    const fnKey = `${type}(${params.join(',')})`;
    if (memoizedActionFns.current[fnKey]) {
      return memoizedActionFns.current[fnKey];
    }

    const fn = (...args) =>
      params.length > 0
        ? dispatch({
            type,
            ...params.reduce((acc, p, i) => ((acc[p] = args[i]), acc), {}),
          })
        : dispatch({ type, ...args[0] });
    memoizedActionFns.current[fnKey] = fn;

    return fn;
  };

  useKey(() => dispatch({ type: AnnotatorConstants.CANCEL }), {
    detectKeys: [27],
  });

  const innerContainerRef = useRef();
  const hotkeyHandlers = useDispatchHotkeyHandlers({ dispatch });
  const refocusOnMouseEvent = useCallback((e) => {
    if (!innerContainerRef.current) return;
    if (innerContainerRef.current.contains(document.activeElement)) return;
    if (innerContainerRef.current.contains(e.target)) {
      innerContainerRef.current.focus();
      e.target.focus();
    }
  }, []);

  const handleFullScreenToggle = useCallback(() => {
    fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter();
    setFullScreen((prev) => !prev);
  }, [fullScreen, fullScreenHandle, setFullScreen]);

  const { activeImage } = getActiveImage(state);

  return (
    <MainLayoutContainer>
      <HotKeyContainer
        tabIndex={-1}
        divRef={innerContainerRef}
        onMouseDown={refocusOnMouseEvent}
        onMouseOver={refocusOnMouseEvent}
        allowChanges
        handlers={hotkeyHandlers}
        className={fullScreen && AnnotatorConstants.FULLSCREEN}
      >
        <StyledFullScreen
          handle={fullScreenHandle}
          onChange={(open) => {
            if (!open) {
              fullScreenHandle.exit();
              setFullScreen(false);
              action(AnnotatorConstants.HEADER_BUTTON_CLICKED, AnnotatorConstants.BUTTON_NAME);
            }
          }}
        >
          <Workspace>
            <ImageCanvasContainer>
              <IconToolsContainer>
                <IconTools
                  updateSelectedAnnotationId={updateSelectedAnnotationId}
                  enabledTools={state.enabledTools}
                  showTags={state.showTags}
                  showMask={state.showMask}
                  selectedTool={state.selectedTool}
                  onClickTool={action(
                    AnnotatorConstants.SELECT_TOOL,
                    AnnotatorConstants.SELECTED_TOOL,
                  )}
                />
              </IconToolsContainer>
              <FullscreenButton onClick={handleFullScreenToggle}>
                <img src={full_screen} alt="toggle full screen" />
              </FullscreenButton>
              {state.annotationType === AnnotatorConstants.IMAGE && !activeImage ? (
                <NoImageContainer>
                  {t('annotation_canvas.no_image', 'No Image Selected')}
                </NoImageContainer>
              ) : (
                <ImageCanvas
                  {...settings}
                  key={activeImage.src}
                  showMask={state.showMask}
                  fullImageSegmentationMode={state.fullImageSegmentationMode}
                  autoSegmentationOptions={state.autoSegmentationOptions}
                  showTags={state.showTags}
                  allowedArea={state.allowedArea}
                  regionClsList={state.regionClsList}
                  regionTagList={state.regionTagList}
                  regions={activeImage.regions || []}
                  realSize={activeImage.realSize || undefined}
                  videoPlaying={state.videoPlaying}
                  imageSrc={activeImage.src}
                  videoSrc={null}
                  pointDistancePrecision={state.pointDistancePrecision}
                  createWithPrimary={state.selectedTool.includes(AnnotatorConstants.CREATE)}
                  dragWithPrimary={state.selectedTool === AnnotatorConstants.PAN}
                  zoomWithPrimary={state.selectedTool === AnnotatorConstants.ZOOM}
                  showPointDistances={state.showPointDistances}
                  videoTime={
                    state.annotationType === AnnotatorConstants.IMAGE
                      ? state.selectedImageFrameTime
                      : state.currentVideoTime
                  }
                  onMouseMove={action(AnnotatorConstants.MOUSE_MOVE)}
                  onMouseDown={action(AnnotatorConstants.MOUSE_DOWN)}
                  onMouseUp={action(AnnotatorConstants.MOUSE_UP)}
                  onChangeRegion={action(
                    AnnotatorConstants.CHANGE_REGION,
                    AnnotatorConstants.REGION,
                  )}
                  onBeginRegionEdit={action(
                    AnnotatorConstants.OPEN_REGION_EDITOR,
                    AnnotatorConstants.REGION,
                  )}
                  onCloseRegionEdit={action(
                    AnnotatorConstants.CLOSE_REGION_EDITOR,
                    AnnotatorConstants.REGION,
                  )}
                  onDeleteRegion={action(
                    AnnotatorConstants.DELETE_REGION,
                    AnnotatorConstants.REGION,
                  )}
                  onBeginBoxTransform={action(
                    AnnotatorConstants.BEGIN_BOX_TRANSFORM,
                    AnnotatorConstants.BOX,
                    AnnotatorConstants.DIRECTIONS,
                  )}
                  onBeginMovePolygonPoint={action(
                    AnnotatorConstants.BEGIN_MOVE_POLYGON_POINT,
                    AnnotatorConstants.POLYGON,
                    AnnotatorConstants.POINT_INDEX,
                  )}
                  onAddPolygonPoint={action(
                    AnnotatorConstants.ADD_POLYGON_POINT,
                    AnnotatorConstants.POLYGON,
                    AnnotatorConstants.POINT,
                    AnnotatorConstants.POINT_INDEX,
                  )}
                  onSelectRegion={action(
                    AnnotatorConstants.SELECT_REGION,
                    AnnotatorConstants.REGION,
                  )}
                  onBeginMovePoint={action(
                    AnnotatorConstants.BEGIN_MOVE_POINT,
                    AnnotatorConstants.POINT,
                  )}
                  onImageLoaded={action(AnnotatorConstants.IMAGE_LOADED, AnnotatorConstants.IMAGE)}
                  RegionEditLabel={RegionEditLabel}
                  onImageOrVideoLoaded={action(
                    AnnotatorConstants.IMAGE_OR_VIDEO_LOADED,
                    AnnotatorConstants.METADATA,
                  )}
                  onChangeVideoTime={action(
                    AnnotatorConstants.CHANGE_VIDEO_TIME,
                    AnnotatorConstants.NEW_TIME,
                  )}
                  onChangeVideoPlaying={action(
                    AnnotatorConstants.CHANGE_VIDEO_PLAYING,
                    AnnotatorConstants.IS_PLAYING,
                  )}
                  onRegionClassAdded={onRegionClassAdded}
                  currentImage={activeImage}
                  lastAction={state.lastAction}
                  selectedTool={state.selectedTool}
                  onRegionSelect={onRegionSelect}
                  selectedAnnotationId={selectedAnnotationId}
                  isLoading={isLoading}
                  SliderValueContext={SliderValueContext}
                />
              )}
            </ImageCanvasContainer>
          </Workspace>
        </StyledFullScreen>
      </HotKeyContainer>
    </MainLayoutContainer>
  );
};

MainLayout.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  RegionEditLabel: PropTypes.elementType,
  onRegionClassAdded: PropTypes.func,
  selectedTools: PropTypes.any,
  onModalOpen: PropTypes.func,
  onChangeImage: PropTypes.func,
  onRegionSelect: PropTypes.func,
  selectedAnnotationId: PropTypes.number,
  updateSelectedAnnotationId: PropTypes.func,
  isLoading: PropTypes.bool,
  SliderValueContext: PropTypes.any,
};

MainLayout.defaultProps = {
  state: {},
  dispatch: () => {},
  RegionEditLabel: null,
  onRegionClassAdded: () => {},
  selectedTools: {},
  onModalOpen: () => {},
  onChangeImage: () => {},
};

export default withTheme(MainLayout);
``;
