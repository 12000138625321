import styled from 'styled-components';

import { Icon } from '@ge/components/icon';
export const UserServiceGroupRoleStyled = styled.div.attrs({
  className: 'UserServiceGroupRole',
})`
  .header,
  .row {
    display: flex;
  }
  .row {
    padding: 6px 0;
  }
  .elem {
    flex: 1 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .header {
    color: ${({ theme }) => theme.table.columnTextColor};
    padding-bottom: 7px;
    font-weight: 400;
    .elem {
      text-align: left;
      padding-left: 7px;
    }
  }
  .content {
    .row {
      padding-left: 7px;
      font-weight: 100;
    }
    .elem:last-child {
      padding-left: 3px;
      padding-right: 5px;
    }
    .elem:nth-child(2) {
      padding-left: 18px;
    }
    .noRecordText {
      text-align: center;
      margin-top: 25px;
    }
    .noRecordLoader img {
      display: block;
      height: 108px;
      left: calc(50% - 100px);
      position: absolute;
      top: calc(85% - 100px);
      width: 200px;
    }
  }
  .simplebar-wrapper {
    height: 100px;
  }
  .odd {
    background-color: ${({ theme }) => theme.layout.sidebarBackgroundColor};
  }
`;

export const DialogTitle = styled.p.attrs({
  className: 'DialogTitle',
})`
  color: ${({ theme }) => theme.table.dataTextColor};
  border-bottom: 1px solid ${(props) => props.theme.dialog.footerSeparatorColor};
  padding: 0px 0px 17px 0;
  margin-bottom: 10px;
  font-size: larger;
  font-weight: 100;
`;

export const ServiceGroupIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.admin.actionHeader.iconColor,
}))`
  margin-right: 7px;
`;
