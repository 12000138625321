import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens';

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
`;

export const ErrorNotification = ({ className }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className={className}>
      <ErrorText>
        {t(
          'form.api_error_message',
          '! Update failed. Please try again or contact support team if problem persists.',
        )}
      </ErrorText>
    </div>
  );
};

ErrorNotification.propTypes = {
  className: PropTypes.string,
};

ErrorNotification.defaultProps = {
  className: null,
};
