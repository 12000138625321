import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { DataExplorerAxisType } from '@ge/feat-analyze/models/data-explorer-defs';
import {
  getSignalSourceBasedOnType,
  getSignalTitle,
} from '@ge/feat-analyze/util/data-explorer-chart';
import { TimeSignal, SortDirection } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import DataExplorerSignalDialogFooter from './shared/data-explorer-signal-dialog-footer';
import DataExplorerSignalList from './shared/data-explorer-signal-list';

const LoaderContainer = styled.div`
  position: relative;
  min-width: 800px;
  min-height: 220px;
`;

const SignalDialogContainer = styled.div`
  display: flex;
  min-width: 800px;
  max-width: 1200px;
  width: 100%;
  > div {
    flex: 1;
  }
`;

const Y_AXIS_MIN_COUNT = 1;
const Y_AXIS_MAX_COUNT = 4;
const AxisType = DataExplorerAxisType;

export const DataExplorerSignalDialog = ({
  isLoading,
  onClose,
  onConfirm,
  value,
  signalData,
  enableMultiAxis,
}) => {
  const { t } = useTranslation(['analyze.data-explorer', 'general'], { useSuspense: false });

  const [yAxisCount, setYAxisCount] = useState(1);

  const [selectedSignals, setSelectedSignals] = useState({
    [AxisType.X]: null,
    [AxisType.Y]: [],
  });

  const signals = useMemo(() => {
    const filteredSignals = signalData
      .filter((signal) => signal.id !== TimeSignal.id)
      .reduce(
        (result, signal) =>
          signal?.aggregations?.reduce(
            (acc, timeAggr) => [...acc, { ...signal, timeAggr }],
            result,
          ) ?? [...result, { ...signal, timeAggr: null }],
        [],
      );

    return {
      x: [TimeSignal].concat(value?.x ? filteredSignals : filteredSignals),
      y: value?.y ? filteredSignals : filteredSignals,
    };
  }, [signalData, value]);

  useEffect(() => {
    if (value?.x) {
      setSelectedSignals((prev) => ({
        ...prev,
        [AxisType.X]: value.x,
      }));
    }
    if (value?.y) {
      const yAxis = value.y.reduce((result, signal) => {
        const index = signal.yAxis ?? 0;
        if (!result[index]) {
          result[index] = [];
        }
        result[index].push(signal);
        return result;
      }, []);
      setSelectedSignals((prev) => ({
        ...prev,
        [AxisType.Y]: yAxis,
      }));
      if (yAxis.length) setYAxisCount(yAxis.length);
    }
  }, [value]);

  const handleSelectSignal = useCallback(
    ({ checked, axisIndex, axisType, signal, timeAggr }) => {
      const _signal = { ...signal, timeAggr };
      if (axisType === AxisType.X) {
        selectedSignals[axisType] = checked ? _signal : null;
      } else {
        if (!selectedSignals[axisType]) {
          selectedSignals[axisType] = [];
        }
        if (!selectedSignals[axisType][axisIndex]) {
          selectedSignals[axisType][axisIndex] = [];
        }
        const selected = selectedSignals[axisType][axisIndex];
        if (checked) {
          // This is to handle flag and select only one signal if flag is off. this will be removed after the completion of US562817
          if (enableMultiAxis) {
            selectedSignals[axisType][axisIndex] = [...selected, _signal];
          } else {
            selectedSignals[axisType][0] = [_signal];
          }
        } else {
          selectedSignals[axisType][axisIndex] = selected.filter(
            (v) =>
              !(v.id === _signal.id && v.timeAggr.toUpperCase() === _signal.timeAggr.toUpperCase()),
          );
        }
      }
      setSelectedSignals({ ...selectedSignals });
    },
    [selectedSignals, enableMultiAxis],
  );

  const handleConfirm = useCallback(() => {
    const x = selectedSignals[AxisType.X];
    const y = selectedSignals[AxisType.Y]
      .filter((values) => values?.length > 0)
      .reduce(
        (acc, values, i) =>
          acc.concat(
            values.map((v) => ({
              ...v,
              yAxis: i,
              title: getSignalTitle(v),
              signalSource: getSignalSourceBasedOnType(v),
            })),
          ),
        [],
      )
      .sort(sorter('yAxis', SortDirection.ASC));

    if (x?.id) {
      x.title = getSignalTitle(x);
    }
    if (x?.type) {
      x.signalSource = getSignalSourceBasedOnType(x);
    }
    onConfirm({ x, y });
  }, [selectedSignals, onConfirm]);

  const handleClose = useCallback(() => {
    onClose(value);
  }, [value, onClose]);

  const handleAddAxis = useCallback(() => {
    setSelectedSignals((prev) => {
      prev[AxisType.Y].push([]);
      return prev;
    });
    setYAxisCount((prev) => prev + 1);
  }, []);

  const handleRemoveAxis = useCallback((axisIndex) => {
    setSelectedSignals((prev) => {
      prev[AxisType.Y].splice(axisIndex, 1);
      return prev;
    });
    setYAxisCount((prev) => prev - 1);
  }, []);

  const getDisabledY = useCallback(
    (index) => {
      const disabled = [selectedSignals[AxisType.X]]
        .concat(
          selectedSignals[AxisType.Y]
            .filter((_, i) => i !== index)
            .flatMap((v) => {
              return v;
            }),
        )
        .filter(Boolean);
      return disabled ?? [];
    },
    [selectedSignals],
  );

  const yAxisMaxCount = enableMultiAxis ? Y_AXIS_MAX_COUNT : Y_AXIS_MIN_COUNT;

  return (
    <Dialog
      isOpen={true}
      onClose={handleClose}
      onConfirm={handleConfirm}
      header={t('select_axis_signals', 'Select signals for chart axis')} // add new title to string bundles
      footer={
        <DataExplorerSignalDialogFooter
          yAxisMaxCount={yAxisMaxCount}
          yAxisCount={yAxisCount}
          selected={selectedSignals}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      }
      contentWidth
      padContent={false}
    >
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <SignalDialogContainer>
          <DataExplorerSignalList
            axisType={AxisType.X}
            checkboxes
            selected={[selectedSignals[AxisType.X]].filter(Boolean)}
            disabled={selectedSignals[AxisType.Y].flatMap((v) => v)}
            onSelectSignal={handleSelectSignal}
            signalData={signals.x}
            value={value?.x ? [value.x] : []}
          />
          {[...new Array(yAxisCount)].map((_, i) => (
            <DataExplorerSignalList
              key={i}
              checkboxes
              selected={selectedSignals[AxisType.Y]?.[i]?.filter(Boolean) ?? []}
              disabled={getDisabledY(i)}
              onSelectSignal={handleSelectSignal}
              signalData={signals.y}
              axisType={AxisType.Y}
              axisIndex={i}
              totalAxisCount={yAxisCount}
              maxAxisCount={yAxisMaxCount}
              onAddAxis={handleAddAxis}
              onRemoveAxis={handleRemoveAxis}
              value={value?.y?.filter((v) => v.yAxis === i || (!v.yAxis && i === 0)) ?? []}
            />
          ))}
        </SignalDialogContainer>
      )}
    </Dialog>
  );
};

DataExplorerSignalDialog.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  value: PropTypes.instanceOf(Object),
  signalData: PropTypes.instanceOf(Array),
  enableMultiAxis: PropTypes.bool,
};

DataExplorerSignalDialog.defaultProps = {
  isLoading: false,
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  value: { x: null, y: null },
  signalData: null,
};
