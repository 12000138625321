import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens/colors';

const Banner = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 110px 0;
  align-item: center;
  justify-content: center;
  text-align: center;
  span {
    padding-top: 10px;
  }
`;
const TextData = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 4px;
  color: ${(props) => props.theme.entityDetails.tasks.details.headerText};
`;
const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 1px;
  color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
`;
const TextSet = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 1px;
  text-align: center;
  color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
`;
const GridIcon = styled(Icon).attrs(({ theme }) => ({
  size: 70,
  icon: Icons.GRID,
  color: theme.manage.timeline.noDataIconColor,
}))`
  margin-right: 3px;
  padding-right: 2px;
`;
const DataIcon = styled(Icon).attrs(() => ({
  size: 10,
  icon: Icons.DATA_NETCOMM,
  color: globalColors.red2,
}))`
  margin: 10px 0px;
`;

export const WarningMessage = () => {
  const { t } = useTranslation(['manage.cases-tasks']);

  return (
    <Banner>
      <span>
        <GridIcon />
        <div>
          <DataIcon />
        </div>
        <TextData>{t('err_large_data_limit', 'Large data set limit')}</TextData>
        <Text>{t('err_for_only_records', 'Only 100k records can be loaded.')}</Text>
        <Text>{t('err_narrow_service_group', 'Please narrow site/service group')}</Text>
        <TextSet>{t('selection', 'selection')}</TextSet>
      </span>
    </Banner>
  );
};
