import React, { useCallback, useMemo } from 'react';

import { TemplateRow, TemplateRows } from '@ge/feat-reporting/components/template';
import { HeaderWidget } from '@ge/feat-reporting/components/widgets/header-widget';
import { Widget } from '@ge/feat-reporting/components/widgets/widget';

import { NotesWidgetStateKeys } from '../components/widgets/notes-widget';
import { WidgetNames } from '../models/widgets';

export const useReportTemplateFactory = ({ data, isPlaceholder }) => {
  /**
   * Generic function to build each row regardless of widget type.
   */
  const buildTemplateRow = useCallback((props, children) => {
    return React.createElement(TemplateRow, props, children);
  }, []);

  /**
   * Function to dynamically build report header component.
   */
  const buildHeader = useCallback(
    (data) => {
      return React.createElement(HeaderWidget, { isPlaceholder, ...data });
    },
    [isPlaceholder],
  );

  /**
   * Function to dynamically build each standalone widget.
   */
  const buildWidget = useCallback(
    (widgetData) => {
      const props = {
        key: widgetData?.name,
        isPlaceholder,
        ...widgetData,
      };
      return React.createElement(Widget, props);
    },
    [isPlaceholder],
  );

  /**
   * Function to dynamically build each widget belonging to reach row.
   */
  const buildWidgets = useCallback(
    (rowData) => {
      return rowData?.map((widgetData) => buildWidget(widgetData));
    },
    [buildWidget],
  );

  /**
   * Function to dynamically build rows of widgets driven by widgetsLayout.
   * This property drives the layout and provides us widgets id to build each
   * widget from.
   */
  const buildWidgetRows = useCallback(() => {
    const { templateInfo } = data ?? {};

    return (
      templateInfo &&
      Object.entries(templateInfo)
        ?.reduce((row, rowData) => {
          // Names of widgets to filter out of the next standard row created.
          // NB: Attachments widget is always filtered from the report area.
          const filterWidgets = [WidgetNames.ATTACHMENTS];

          const notesWidget = rowData[1].find((column) => column.name === WidgetNames.NOTES);
          if (notesWidget) {
            const widgetState = data.widgetLocalState?.[notesWidget.id];
            if (widgetState?.[NotesWidgetStateKeys.OVERFLOW]) {
              // Filter the Notes widget from the standard row
              filterWidgets.push(WidgetNames.NOTES);
            }
          }

          // Filter widgets from row as needed
          row.push([
            rowData[0],
            rowData[1].filter((column) => !filterWidgets.includes(column.name)),
          ]);

          if (notesWidget && filterWidgets.includes(WidgetNames.NOTES)) {
            // Insert a non-standard row to contain the full-width Notes widget
            row.push([
              `${rowData[0]}-a`, // Modifying row name to prevent key duplication
              [{ ...notesWidget, name: WidgetNames.NOTES_WIDE }],
            ]);
          }

          return row;
        }, [])
        ?.map(([key, rowData]) => {
          const children = buildWidgets(rowData);
          const props = { key };
          return buildTemplateRow(props, children);
        })
    );
  }, [data, buildWidgets, buildTemplateRow]);

  /**
   * Function to dynamically build report header row.
   */
  const buildHeaderRow = useCallback(() => {
    if (!data) return null;

    const children = buildHeader(data);
    const props = { key: data?.name };

    return buildTemplateRow(props, children);
  }, [data, buildTemplateRow, buildHeader]);

  /**
   * Function to dynamically build report template
   * based on service data.
   */
  const template = useMemo(() => {
    return React.createElement(TemplateRows, {}, [buildHeaderRow(), buildWidgetRows()]);
  }, [buildHeaderRow, buildWidgetRows]);

  /**
   * Function to retrive the built template.
   */
  const getTemplate = useCallback(() => {
    if (!data) {
      return null;
    }

    return template;
  }, [data, template]);

  return { template: getTemplate() };
};
