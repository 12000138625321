import { useState, useCallback } from 'react';

import { useChangeCasesStatus } from '@ge/shared/data-hooks';
import { ActionKeys } from '@ge/shared/models/actions-menu-items';

export const useSnoozeDialog = () => {
  const [snoozeDialog, setSnoozeDialog] = useState(false);
  const [isCaseStateChanged, setIsCaseStateChanged] = useState(false);

  const { changeCaseStatus, data: updatedData, isError, error } = useChangeCasesStatus();

  const handleSubmitSnoozeValue = useCallback(
    (data) => {
      changeCaseStatus({ state: ActionKeys.EVENT_SNOOZE, ...data });
      setIsCaseStateChanged(true);
      setSnoozeDialog(false);
    },
    [changeCaseStatus, setIsCaseStateChanged, setSnoozeDialog],
  );

  return {
    changeCaseStatus,
    handleSubmitSnoozeValue,
    snoozeDialog,
    setSnoozeDialog,
    isCaseStateChanged,
    setIsCaseStateChanged,
    updatedData,
    isError,
    error,
  };
};
