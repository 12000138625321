import { Icons } from '@ge/components/icon';
import { EntityType } from '@ge/models/constants';
import { AssetType } from '@ge/models/entities/asset/constants';

export const getAssetIcon = (typeStr) => {
  switch (typeStr) {
    case AssetType.WIND_TURBINE:
      return Icons.TURBINE;
    case AssetType.SUBSTATION:
      return Icons.SUBSTATION;
    case AssetType.SITE_CONTROLLER:
      return Icons.SITE_CONTROLLER;
    case AssetType.STORAGE_INVERTER:
      return Icons.STORAGE_INVERTER;
    case AssetType.SOLAR_INVERTER:
      return Icons.SOLAR;
    case AssetType.HYBRID_INVERTER:
      return Icons.HYBRID;
    default:
      return null;
  }
};

const getSiteIcon = (siteType) => {
  switch (siteType) {
    case EntityType.SITE:
    case EntityType.WIND_SITE:
      return Icons.SITE;
    case EntityType.STORAGE_SITE:
      return Icons.STORAGE;
    case EntityType.SOLAR_SITE:
      return Icons.SOLAR;
    case EntityType.HYBRID_SITE:
      return Icons.HYBRID;
    default:
      return Icons.SITE;
  }
};

export const getCaseLevelIcon = (caseLevel, assetType, siteType) => {
  switch (caseLevel) {
    case EntityType.SITE:
      return getSiteIcon(siteType);
    case EntityType.FLEET:
      return Icons.FLEET;
    default:
      return getAssetIcon(assetType);
  }
};

export const getBOPSiteIcon = (event) => {
  if (event?.caseLevel === EntityType.FLEET) {
    return;
  }
  if (event && event?.site?.substations && event?.site?.substations?.instanceCount > 0) {
    return Icons.SUBSTATION;
  }
};
