import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  Capability,
  ErpStatusFlag,
  ErpStatusIconMap,
  PermissionScope,
  QueryKey,
} from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';

import { fetchErpRequestStatus } from '../services';

const DEFAULT_CONFIG = {
  staleTime: Config.REFRESH.realTime,
  refetchOnMount: true,
};

// try to resolve status and fall back to 0
const getStatusFlag = (...status) =>
  status.reduce((statusFlag, _status) => statusFlag + (ErpStatusFlag[_status] ?? 0), 0);

export const useErpRequestStatus = (
  { isActive = true, taskIds },
  config = DEFAULT_CONFIG,
  queryKey,
) => {
  const { isAuthorized } = useAuth();
  const isAuthorizedForErp = isAuthorized({
    capabilities: [{ capability: Capability.WORK_ORDERS, scopes: [PermissionScope.VIEW] }],
  });

  const {
    data: status,
    error,
    isLoading,
  } = useQuery(
    [QueryKey.ERP_REQUEST_STATUS, taskIds, queryKey],
    () => fetchErpRequestStatus({ taskIds }),
    {
      ...config,
      // TODO: move away from isActive and just pass in config object if need to override behavior
      enabled:
        isAuthorizedForErp &&
        Boolean(taskIds?.length) &&
        (isActive ?? true) &&
        (config?.enabled ?? true),
    },
  );

  const data = useMemo(
    () =>
      status?.map((_status) => {
        const {
          status,
          subStatus: { consolidated, laborDetails, technicians, partDetails },
        } = _status;

        let statusId = getStatusFlag(status);

        if (consolidated) {
          statusId += getStatusFlag(consolidated);
        } else {
          statusId += getStatusFlag(laborDetails, technicians, partDetails);
        }

        const iconId = ErpStatusIconMap[statusId];

        return {
          ..._status,
          iconId,
        };
      }),
    [status],
  );

  return { data, error, isLoading };
};
