import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import {
  commonServiceGroups,
  getFinalFormatedData,
  rolesByServiceGroups,
  combinedDataObjects,
  getNamedData,
} from '../../../../util';
/**
 * function - useGetServiceGroups
 */
export const useGetServiceGroups = (person, header) => {
  const storeSelectedServiceGroup = useStoreState((state) => state.sites.getViewServiceGroupsSites);
  const { limitedUserRoles } = useStoreState((state) => state.userRole);
  const serviceGroupData = useMemo(() => {
    return person && rolesByServiceGroups(person, limitedUserRoles);
  }, [person, limitedUserRoles]);
  const commonData = useMemo(() => {
    return serviceGroupData && commonServiceGroups(serviceGroupData, storeSelectedServiceGroup);
  }, [serviceGroupData, storeSelectedServiceGroup]);

  const finalFormatedData = useMemo(() => {
    return commonData && getFinalFormatedData(combinedDataObjects(commonData), person, header);
  }, [commonData, person, header]);
  const namedData = useMemo(() => {
    return finalFormatedData && getNamedData(finalFormatedData, storeSelectedServiceGroup);
  }, [finalFormatedData, storeSelectedServiceGroup]);
  // patch body
  const patchBody = useMemo(() => {
    return commonData?.map((currentObj) => ({
      path: currentObj.path,
      value: currentObj.serviceGroup,
      op: 'REMOVE',
    }));
  }, [commonData]);

  return { finalData: namedData, patchBody: patchBody };
};
