import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { cloneElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

import { getDefaultRoute } from '../../services/nav';

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  .title {
    font-size: 18px;
    font-weight: 500;
    font-family: 'GE Inspira';
    color: ${(props) => props.theme.navigation.linkColor};
    display: flex;
    align-items: center;
    height: 57px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.navigation.l1BorderColor};
    svg {
      fill: ${(props) => props.theme.navigation.chevronColor};
    }
    &:hover {
      color: ${(props) => props.theme.navigation.linkActiveColor};
      svg {
        fill: ${(props) => props.theme.navigation.linkActiveColor};
      }
    }
    &.active {
      background-color: ${(props) => props.theme.navigation.linkActiveBackgroundColor};
    }
  }
`;

const L2List = styled.ul`
  padding: 0 0 30px 0;
  margin: 0;
  list-style: none;
`;

const StyledNavLink = styled(NavLink)`
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.navigation.linkColor};
  svg {
    fill: ${(props) => props.theme.navigation.linkColor};
    margin-right: 8px;
  }
  text-decoration: none;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
  &.active {
    font-weight: 300;
    text-decoration: none;
    background-color: ${(props) => props.theme.navigation.linkActiveBorderColor};
    color: ${(props) => props.theme.navigation.linkActiveColor};
    svg {
      fill: ${(props) => props.theme.navigation.linkActiveColor};
    }
  }
`;

const L2ListItem = styled.li`
  list-style: none;
  position: relative;
  &:hover,
  &:focus,
  &:focus-within {
    ul {
      display: block;
    }
  }
`;

const L2DropdownList = styled.ul`
  position: relative;
  background-color: ${(props) => props.theme.navigation.backgroundColor};
  padding: 0 0 0 5%;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
`;

const StyledIcon = styled.div`
  position: relative;
  padding: 0 0 0 87%;
  svg {
    margin-top: -44px;
  }
`;

const L1NavItem = ({ activeL1, navMapKey, navMapValue, pathname, handleOnHover }) => {
  const { t } = useTranslation(['nav']);
  const l2LinkRefArray = useRef([]);
  const [panelOpened, setPanelOpened] = useState(false);

  const { resetDateRange } = useStoreActions(({ analyze }) => analyze);

  const getL2NavItems = (value) => {
    const navItems = value.map((navItem, i) => (
      <L2ListItem key={getDefaultRoute(navItem)} ref={(el) => (l2LinkRefArray.current[i] = el)}>
        <StyledNavLink
          className={pathname.includes(navItem.rootRoute) ? 'active' : ''}
          to={Array.isArray(navItem.route) ? navItem.route[0] : navItem.route}
          onMouseEnter={() =>
            navItem.childSubMenu && navItem.childSubMenu.length > 0
              ? handleOnHover(true, navMapKey.i18nKey)
              : handleOnHover(false)
          }
          onClick={() => {
            resetDateRange();
          }}
        >
          {navItem.icon && <Icon size={16} icon={navItem.icon} color={globalColors.white} />}
          {t(navItem.i18nKey, navItem.label)}
        </StyledNavLink>
        {navItem.childSubMenu && navItem.childSubMenu.length > 0 ? (
          <StyledIcon>
            <Icon size={16} icon={Icons.CHEVRON_RIGHT} color={globalColors.white} />
          </StyledIcon>
        ) : (
          ''
        )}
        {navItem.children && navItem.children.length > 0 && (
          <L2DropdownList>
            {navItem.children.map((childNavItem) => (
              <li key={getDefaultRoute(childNavItem)}>
                {childNavItem.dropdownComponent ? (
                  cloneElement(childNavItem.dropdownComponent, {
                    nextLinkRef: l2LinkRefArray?.current[i + 1],
                  })
                ) : (
                  <StyledNavLink
                    className={pathname.includes(childNavItem.rootRoute) ? 'active' : ''}
                    to={
                      Array.isArray(childNavItem.route) ? childNavItem.route[0] : childNavItem.route
                    }
                    onClick={() => {
                      resetDateRange();
                    }}
                  >
                    {childNavItem.icon && (
                      <Icon size={16} icon={childNavItem.icon} color={globalColors.white} />
                    )}
                    {t(childNavItem.i18nKey, childNavItem.label)}
                  </StyledNavLink>
                )}
              </li>
            ))}
          </L2DropdownList>
        )}
      </L2ListItem>
    ));

    return (
      <StyledCollapsiblePanel
        headerContent={<>{t(navMapKey.i18nKey, navMapKey.label)}</>}
        isActiveTitle={activeL1}
        expanded={true}
        secondary={true}
        allowOverflow={panelOpened}
        onToggleExpand={() => setPanelOpened()}
        triggerHeight={navItems}
      >
        <L2List id={navMapKey.i18nKey}>{navItems}</L2List>
      </StyledCollapsiblePanel>
    );
  };

  return <li>{getL2NavItems(navMapValue)}</li>;
};

L1NavItem.propTypes = {
  activeL1: PropTypes.bool,
  navMapKey: PropTypes.object,
  navMapValue: PropTypes.array,
  pathname: PropTypes.string,
  handleOnHover: PropTypes.func,
};

export default withRouter(withTheme(L1NavItem));
