import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

// import { DetailsCurrentSeparator } from '../entity-details-shared';

const StyledHeaderCurrentRegion = styled.button`
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  align-items: center;
  padding: 0;
  h5 {
    white-space: nowrap;
  }
`;

const FleetIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.FLEET,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const HeaderCurrentRegion = ({ className, regionName, onClick }) => (
  <StyledHeaderCurrentRegion className={className} onClick={onClick}>
    <FleetIcon />
    <h5>{regionName}</h5>
    {/* <DetailsCurrentSeparator>/</DetailsCurrentSeparator> */}
  </StyledHeaderCurrentRegion>
);

HeaderCurrentRegion.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  regionName: PropTypes.string,
};

HeaderCurrentRegion.defaultProps = {
  className: null,
  onClick: () => null,
  regionName: '',
};

export default HeaderCurrentRegion;
