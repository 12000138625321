// TODO: look at moving this out of here, just want to keep the
// stuff using notistack in one place so we just pull it into a single package

import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

import { NotificationType } from '@ge/models/constants';

import { Notification } from './notification';

export const useNotification = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const notify = useCallback(
    ({ actions, id: existingId, message, persist, status, type, timestamp, ref }) => {
      const autoHideDuration =
        persist || type === NotificationType.ERROR
          ? null
          : type === NotificationType.FAILURE
          ? 15000
          : 10000;

      const content = (key) => (
        <Notification
          actions={actions}
          id={key}
          message={message}
          onDismiss={() => closeSnackbar(key)}
          status={status}
          type={type}
          timestamp={timestamp}
          ref={ref}
        />
      );

      // pass in message to track dupes even though it isn't used when content is provided
      const id = enqueueSnackbar(existingId ?? message, {
        autoHideDuration,
        content,
        key: existingId,
      });

      // pass back id used to close notification programmatically or update content
      return id;
    },
    [closeSnackbar, enqueueSnackbar],
  );

  return {
    dismiss: closeSnackbar,
    notify,
  };
};
