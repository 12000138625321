import { PropTypes } from 'prop-types';

export const ConditionalRender = ({ shouldRender, children }) => shouldRender && children;

ConditionalRender.propTypes = {
  shouldRender: PropTypes.bool,
};

ConditionalRender.defaultProps = {
  shouldRender: true,
};
