import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ExportGraphButton } from '@ge/feat-analyze/components/modals';
import { DateRange } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';

import { DashboardDateFilter } from './dashboard-date-filter';

const StyledHeader = styled.div`
  height: 25px;
  width: calc(100% - 44px);
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const StyledGraphButton = styled(ExportGraphButton)`
  margin-left: auto;
`;

export const FilterBar = ({
  defaultDateRange,
  timezone,
  rangeDef,
  persist,
  onChange,
  enabledExport,
  onExportClick,
  className,
  externalDateRange,
}) => {
  return (
    <StyledHeader className={className} data-testid={AnalyzeLocators.ANALYZE_DATE_FILTER_CONTAINER}>
      <DashboardDateFilter
        defaultDateRange={defaultDateRange}
        timezone={timezone}
        rangeDef={rangeDef}
        persist={persist}
        onChange={onChange}
        externalDateRange={externalDateRange}
      />
      {enabledExport && <StyledGraphButton onClick={onExportClick} />}
    </StyledHeader>
  );
};

FilterBar.propTypes = {
  defaultDateRange: PropTypes.oneOf(Object.values(DateRange)),
  timezone: PropTypes.string,
  enabledExport: PropTypes.bool,
  onExportClick: PropTypes.func,
  rangeDef: PropTypes.string,
  persist: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  externalDateRange: PropTypes.object,
};

FilterBar.defaultProps = {
  timezone: 'UTC',
  enabledExport: false,
  onExportClick: null,
};
