import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';
import { useColumnState } from '@ge/components/table/use-column-state';
import { SortDirection, AdminDefs } from '@ge/models/constants';
import { useFilterDefs } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util';

import { defaultEventManagementColumns, EventManagementColumnsDefs } from '../../models';
import EventManagementTable from '../tables/event-management-table';

import { EMSidePanel } from './em-sidepanel';

/**
 * Available sort metrics for Event Management Lists.
 */
export const EM_Metric = {
  Name: 'name',
};

const EventManagementList = () => {
  const { ready } = useTranslation(['admin.configure']);
  const history = useHistory();
  const location = useLocation();
  const [listData, setListData] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);
  // const [isFromChanged, setFormChanged] = useState(false);

  // const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.MONITOR_SITES);
  const {
    visibleCols: visibleEventManagementCols,
    sortDirection,
    sortMetric,
    updateColumnVisibility,
    setVisibleColumns,
    updateSortMetric,
  } = useColumnState({
    columnDefs: EventManagementColumnsDefs,
    defaultCols: defaultEventManagementColumns,
    defaultSortMetric: EM_Metric.Name,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: AdminDefs.EVENT_MANAGEMENT_SORT_STATE_ID,
  });

  const {
    filterDefs,
    onChange: setEventManagementColumnDefs,
    onReset: resetFilter,
  } = useFilterDefs({
    columnDefs: EventManagementColumnsDefs,
    stateId: AdminDefs.EVENT_MANAGEMENT_SORT_STATE_ID,
  });

  const openEMSidePanel = useCallback(
    (e, type) => {
      killEventPropagation(e);
      history.push({ pathname: location.pathname, search: `?mode=${type || 'new'}` });
    },
    [history, location.pathname],
  );

  /**
   * TODO: should open DL details on row select
   * (e,row)
   */
  const handleRowSelect = useCallback(
    (e, row, arg) => {
      e.stopPropagation();
      setListData(row);
      setSelectedRow([row.sourceEventId]);
      openEMSidePanel(e, `view__${arg?.value}__${row?.eventId}`);
    },
    [openEMSidePanel],
  );

  const hideEMSidePanel = (e) => {
    // debugger;
    // console.log(type, 'type-1', isFromChanged);
    history.push({ pathname: location.pathname });
    setSelectedRow([]);
    killEventPropagation(e);
  };
  return (
    <PageContainer i18nReady={ready}>
      <EMSidePanel data={listData} close={hideEMSidePanel} />
      <EventManagementTable
        viewDetail={setListData}
        columns={visibleEventManagementCols}
        onRowSelect={handleRowSelect}
        filterDefs={filterDefs}
        sortAction={updateSortMetric}
        sortMetric={sortMetric}
        updateColumnVisibility={updateColumnVisibility}
        setVisibleColumns={setVisibleColumns}
        sortDirection={sortDirection}
        setFilterDefs={setEventManagementColumnDefs}
        openEMSidePanel={openEMSidePanel}
        resetFilter={resetFilter}
        selectedRow={selectedRow}
      />
    </PageContainer>
  );
};

export default EventManagementList;
