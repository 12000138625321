import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { fetchSiteMetrics } from '@ge/shared/services/site';

// TODO: move this into shared data hooks if it can be used elsewhere
// moving away from isActive pattern to just passing in the whole config
export const useTaskAssetState = ({ config = Config.EXECUTE_ONCE, queryKey, tasks }) => {
  const logger = useLogger();

  // TODO: handle errors
  const {
    data: _data,
    error,
    isLoading,
  } = useQuery(
    [QueryKey.TASK_ASSET_STATE, tasks, queryKey],
    async () => {
      const paramsLookup = tasks?.reduce((_paramsLookup, { asset, site }) => {
        const { id: assetId } = asset ?? {};
        const { id: siteId } = site;

        if (!assetId) {
          return _paramsLookup;
        }

        const siteAssets = (_paramsLookup[siteId] = _paramsLookup[siteId] ?? {});
        siteAssets[assetId] = true;

        return _paramsLookup;
      }, []);

      const fetches = Object.entries(paramsLookup).reduce((_fetches, [siteId, assetIdLookup]) => {
        const assetIds = Object.keys(assetIdLookup);

        _fetches.push(fetchSiteMetrics(siteId, assetIds));

        return _fetches;
      }, []);

      const responses = await Promise.allSettled(fetches);

      const data = [];

      for (const { reason, value } of responses) {
        if (!value) {
          logger.error(reason);

          continue;
        }

        data.push(value);
      }

      return data;
    },
    {
      ...config,
      enabled: (config?.enabled ?? true) && Boolean(tasks?.length),
    },
  );

  const data = useMemo(() => {
    if (!_data?.length) {
      return null;
    }

    return _data.reduce((mapped, { assets }) => {
      Object.entries(assets).forEach(([key, { state: { value } = {} }]) => (mapped[key] = value));

      return mapped;
    }, {});
  }, [_data]);

  return { data, error, isLoading };
};
