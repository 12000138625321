import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { fetchAssetPowerCurve } from '@ge/feat-analyze/services';
import { parseTimeZone } from '@ge/feat-analyze/util';
import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks/config';

/**
 * Use assets power curve.
 *
 * @param assetIds the asset ids
 * @param startDate the start date
 * @param endDate the end date
 */
const useAssetPowerCurve = ({ assetIds = [], timezone, startDate, endDate }) => {
  const { start, end } = useMemo(() => {
    if (startDate && endDate && timezone) {
      return {
        start: parseTimeZone(`${startDate}T00:00:00.000Z`, timezone),
        end: parseTimeZone(`${endDate}T23:59:59.000Z`, timezone),
      };
    }
    return {};
  }, [timezone, startDate, endDate]);

  const powerCurveQueries = useQueries(
    assetIds.map((id) => ({
      queryKey: [
        QueryKey.ASSET_POWER_CURVE,
        id,
        {
          startDate,
          endDate,
        },
      ],
      queryFn: () => fetchAssetPowerCurve(id, { start, end }),
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(startDate && endDate && id),
    })),
  );

  const isLoading = powerCurveQueries.some((result) => result.isLoading);
  return useMemo(() => {
    return {
      isLoading: isLoading,
      errorIds: powerCurveQueries
        .map((r, i) => ({ isError: r.isError, index: i }))
        .filter((o) => o.isError)
        .map((o) => assetIds[o.index]),
      error: powerCurveQueries[0]?.error,
      data: !isLoading
        ? powerCurveQueries
            .filter((r) => r.isSuccess)
            .map(({ data: resp }) => {
              const [resultData = {}] = resp?.data?.data ?? [];
              const { data, contract, regression, asset } = resultData;
              return {
                data: data ?? [],
                regression: regression ?? [],
                contract: contract ?? {},
                entity: {
                  id: asset?.id,
                  name: asset?.name,
                },
              };
            })
        : [],
    };
  }, [assetIds, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useAssetPowerCurve;
