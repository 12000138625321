import { AlertType } from '@ge/models/constants';

export const NOTAMTemplate = {
  _id: '19d07af6-0bab-11ec-9a03-0242ac130003',
  alertType: AlertType.NOTAM,
  entrypoint: 'string',
  sections: [
    {
      collapsed: false,
      hidden: false,
      metadata: {
        event: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          position: {
            col: 1,
            row: 1,
            span: 12,
          },
          type: 'text',
        },
        gps: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          position: {
            col: 1,
            row: 2,
            span: 12,
          },
          type: 'text',
        },
        nearestAirport: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          position: {
            col: 1,
            row: 3,
            span: 12,
          },
          type: 'text',
        },
        enr: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          position: {
            col: 1,
            row: 4,
            span: 12,
          },
          type: 'text',
        },
        validTo: {
          create: {
            hidden: false,
            readOnly: false,
            required: true,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: true,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          position: {
            col: 1,
            row: 5,
            span: 12,
          },
          type: 'dateTime',
        },
      },
      type: 'body',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        description: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'description',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        sendNotification: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'send_notification',
    },
  ],
  operations: ['create', 'edit'],
  tenantId: 'dfsa',
};

export const NOTAMMailTemplate = {
  bcc: 'NOTAMBCC@mock.com',
  cc: 'NOTAMCC@mock.com',
  body: '',
  header: 'This is to inform of a NOTAM for {{siteName}} – {{assetName}}',
  maxAttachments: 5,
  maxAttachmentSizeMb: 10,
  subject: 'NOTAM  Notifications for {{siteName}} – {{assetName}}',
  to: '',
};
