import { useTranslation } from 'react-i18next';

export const useSeverityName = (id) => {
  const { t } = useTranslation(['analyze.sites'], { useSuspense: false });

  switch (id) {
    case 3:
      return t('reliability.info');
    case 2:
      return t('reliability.warning');
    case 1:
      return t('reliability.critical');
    default:
      return t('reliability.info');
  }
};
