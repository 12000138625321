import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PresentationTypes, EntityTab, KpiCategoryDefs } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
// TODO (astone): This is an unholy reference to the DWF from outside the app! Remove!
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';

import useSiteIecData from '../../../data-hooks/use-site-iec-data';
import { DashboardIECLossCategories } from '../../dashboard/dashboard-iec-loss-categories';
import { RegionSparkChart } from '../../region/region-spark-chart';
import { AssetsGrid } from '../assets-grid';

const DashboardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .header {
    border-bottom: solid 2px ${(props) => props.theme.analyze.header.borderBottomColor};
    margin-bottom: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  > * {
    flex: 1 1;
  }
`;

/**
 * Set list of view tyoes for Presentation Toggle
 */
const PresentationViews = [PresentationTypes.LIST, PresentationTypes.GRID];

// TODO: wire these up
const regionId = 'reg_nam';
const siteId = '5000615';

export const AnalyzeAssetsWind = () => {
  const { ready, t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });
  const [isActive, setActiveState] = useState(PresentationTypes.LIST);
  const { push } = useHistory();
  const { search } = useLocation();

  /* Todo: need to add fetch/getters for all sites filtered by view selector
   and format to match the current region page. */
  const { sites: viewSites } = useStoreState((state) => state.view.currentView);

  const { fetchAssetCards } = useStoreActions((actions) => actions.analyze);
  const getAssetCards = useStoreState((state) => state.analyze.getAssetCards);

  const { data: iecCategoryData } = useSiteIecData({
    category: KpiCategoryDefs.PRODUCTION_ACTUAL,
    siteId,
  });
  const assetsCardData = getAssetCards(viewSites);

  const { showAssetDetails } = useContext(EntityDetailsContext);

  // // Fetch page data
  useEffect(() => {
    fetchAssetCards();
  }, [fetchAssetCards]);

  // TODO: get route from somewhere so don't need to hardcode here
  const routeToAssetOverview = ({ id }) => push(`/analyze/asset-overview?assets=${id}`);

  // Process URL params for setting initial view type.
  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');
    if (view) {
      setActiveState(view);
    } else {
      setActiveState(PresentationTypes.GRID);
    }
  }, [isActive, search]);

  const handleSelectAssetTitle = useCallback(
    (e, id) => {
      killEventPropagation(e);
      if (id) {
        showAssetDetails(id, EntityTab.PRODUCTION);
      }
    },
    [showAssetDetails],
  );

  if (!ready) {
    return null;
  }

  return (
    <DashboardContainer>
      <ChartWrapper>
        <RegionSparkChart regionId={regionId} />
        <DashboardIECLossCategories
          title={t('tilc.title', 'Top IEC Loss Categories')}
          data={iecCategoryData}
          namespace="analyze.dashboard"
        />
      </ChartWrapper>
      <div className="header">
        <h3>{t('assets', 'Assets')}</h3>
        <PresentationToggle viewList={PresentationViews} defaultView={PresentationTypes.GRID} />
      </div>
      {isActive === PresentationTypes.GRID && (
        <AssetsGrid
          assetData={assetsCardData}
          onSelectAsset={routeToAssetOverview}
          onSelectAssetTitle={handleSelectAssetTitle}
        />
      )}
    </DashboardContainer>
  );
};

AnalyzeAssetsWind.propTypes = {};

AnalyzeAssetsWind.defaultProps = {};
