import * as request from '@ge/shared/services/api';

export const saveView = async (view) => {
  const { views } = await request.post('/views', view);

  // Fake the response showing the newly added view.
  views.push(view);

  return views;
};

export const fetchSavedViews = async () => request.get('/views');

/**
 * Dynamically load the view mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/view/view.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
