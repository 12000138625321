import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { Icon } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

const StyledNavLink = styled(NavLink)`
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  margin: 8px 0;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.navigation.linkColor};
  svg {
    fill: ${(props) => props.theme.navigation.linkColor};
    margin-right: 8px;
  }
  text-decoration: none;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
  &.active {
    font-weight: 300;
    text-decoration: none;
    background-color: ${(props) => props.theme.navigation.linkActiveBorderColor};
    color: ${(props) => props.theme.navigation.linkActiveColor};
    svg {
      fill: ${(props) => props.theme.navigation.linkActiveColor};
    }
  }
`;

const L1StickyNavItem = ({ navMapKey, navMapValue, pathname }) => {
  const { t } = useTranslation(['nav']);

  const { resetDateRange } = useStoreActions(({ analyze }) => analyze);

  const navItem = navMapValue[0]; // this assumes that there's only one L2 value for this sticky link

  return (
    <StyledNavLink
      className={pathname.includes(navItem.rootRoute) ? 'active' : ''}
      to={Array.isArray(navItem.route) ? navItem.route[0] : navItem.route}
      onClick={() => {
        resetDateRange();
      }}
    >
      {navItem.icon && <Icon size={16} icon={navItem.icon} color={globalColors.white} />}
      {t(navMapKey.i18nKey, navMapKey.label)} {/* This is the L1's label, not the L2 */}
    </StyledNavLink>
  );
};

L1StickyNavItem.propTypes = {
  navMapKey: PropTypes.object,
  navMapValue: PropTypes.array,
  pathname: PropTypes.string,
};

export default withRouter(withTheme(L1StickyNavItem));
