import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import { Checkbox } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { Severity } from '@ge/components/severity';
import { Table, TableArrow, Tbody, Td, Th, Thead, Tr } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { PanelColumnWidths } from '@ge/models/constants';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';

// const StyledCheckbox = styled.div`
//   label {
//     display: inline-block;
//     margin-top: 2px;
//   }
//   span {
//     margin-left: 0;
//   }
// `;

const StyledSpan = styled.span`
  white-space: pre;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const AssetTasksTable = ({
  tasks,
  isLoading,
  sortAction,
  sortMetric,
  sortDirection,
  onTaskSelect,
  onRetry,
  scrollable,
}) => {
  const filteredTasks = tasks?.filter((t) => t.status !== 'completed');
  const { t, ready } = useTranslation(['manage.cases-tasks'], {
    useSuspense: false,
  });

  const { getSiteById } = useStoreState((store) => store.sites);
  const site = getSiteById(tasks?.[0]?.asset.siteId);
  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const dynamicProps = {
    scrollable,
    sortAction,
    values: tasks,
    onValueSelect: onTaskSelect,
    rowKeyProperty: 'id',
    isLoading,
  };

  if (!ready) {
    return <Loader />;
  }
  // return React.createElement(draggable ? DraggableTable : DynamicTable, dynamicProps);

  const noData = !(isLoading || Boolean(tasks?.length));

  return (
    <>
      <Table
        height={'400'}
        compressed
        isLoading={isLoading}
        noData={noData}
        onRetry={onRetry}
        scrollable={scrollable}
        {...dynamicProps}
      >
        <Thead noTitles transparent>
          <tr>
            {/* <Th border align="center" width={PanelColumnWidths.XSMALL} /> */}
            <Th
              labels={{
                [t('asset_case_table.priority', 'Priority')]: 'priority',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
            />
            <Th
              labels={{
                [t('asset_case_table.title', 'Task Title')]: 'title',
              }}
              align="left"
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XLARGE}
            />
            <Th
              labels={{
                [t('asset_case_table.source', 'Source')]: 'source',
              }}
              align="left"
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.SMALL}
            />
            <Th
              labels={{
                [t('asset_case_table.work_scope', 'Work Scope')]: 'workScope',
              }}
              align="left"
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.SMALL}
            />
            <Th
              labels={{
                [t('asset_case_table.createDate', 'Created Date')]: 'createDate',
              }}
              align="left"
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.MEDIUM}
            />
            <Th
              labels={{
                [t('asset_case_table.status', 'Status')]: 'status',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.MEDIUM}
            />
            <Th border width={PanelColumnWidths.XSMALL} />
          </tr>
        </Thead>
        <Tbody transparent>
          {filteredTasks?.map((taskData) => {
            const { id, priority, title, source, workScope, createDate, status } = taskData;
            const tasksDate = getDateTimeBasedOnZone(createDate, site?.timezone);
            return (
              <Tr key={id}>
                <Td width={PanelColumnWidths.XSMALL} border>
                  <Severity level={priority?.toLowerCase()} />
                </Td>
                <Td align="left" width={PanelColumnWidths.XXLARGE} border>
                  <TooltipCell tooltip={title} zIndex={elevations.P20}>
                    <StyledSpan>{title}</StyledSpan>
                  </TooltipCell>
                </Td>
                <Td align="left" width={PanelColumnWidths.SMALL} border>
                  <TooltipCell tooltip={source} zIndex={elevations.P20}>
                    <StyledSpan>{source}</StyledSpan>
                  </TooltipCell>
                </Td>
                <Td align="left" width={PanelColumnWidths.SMALL} border>
                  <TooltipCell tooltip={workScope} zIndex={elevations.P20}>
                    <StyledSpan>{workScope}</StyledSpan>
                  </TooltipCell>
                </Td>
                <Td align="left" width={PanelColumnWidths.MEDIUM} border>
                  <StyledTimeDate>
                    <StyledSpan>{tasksDate.date}</StyledSpan>
                  </StyledTimeDate>
                </Td>
                <Td width={PanelColumnWidths.MEDIUM} border>
                  <StyledTimeDate>
                    <StyledSpan>{status.charAt(0).toUpperCase() + status.slice(1)}</StyledSpan>
                  </StyledTimeDate>
                </Td>
                <Td border width={PanelColumnWidths.XSMALL} noPadding>
                  <div onClick={(e) => onTaskSelect(e, id)}>
                    <TableArrow />
                  </div>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

AssetTasksTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isLoading: PropTypes.bool,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onTaskSelect: PropTypes.func,
  onRetry: PropTypes.func,
  scrollable: PropTypes.bool,
  assetId: PropTypes.string,
};

AssetTasksTable.defaultProps = {
  isLoading: false,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onTaskSelect: () => null,
  onRetry: () => null,
  scrollable: false,
};

export default AssetTasksTable;
