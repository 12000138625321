import { PropTypes } from 'prop-types';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Loader } from '@ge/components/loader';
import { Menu } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';

import { EntityDetailsContext } from '../context/entity-details-context';

const StyledTitle = styled.div`
  background: ${({ theme }) => theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${({ theme }) => theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
`;

const StyledTitleText = styled.div`
  padding-right: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  min-width: 367px;
  max-height: 240px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
  ::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }
`;

const TaskRow = styled.div`
  border-top: 1px solid ${globalColors.grey9};
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  &:first-of-type {
    border: none;
  }
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const TaskTitle = styled.div`
  flex-grow: 1;
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DetailColumn = styled.div`
  flex: 1;
  max-width: 275px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
`;

const AnalysisPlotsMenu = ({ containerRef, children, title, menuPlacement, tasks, isLoading }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const { showTaskDetails } = useContext(EntityDetailsContext);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    if (tasks?.length > 1) setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  const handleOpenTaskFn = (id) => () => {
    showTaskDetails(id);
    handleMenuClose();
  };

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={menuPlacement}
        onClick={handleMenuClick}
      >
        <TaskMenu
          tasks={tasks}
          isLoading={isLoading}
          title={title}
          handleOpenTaskFn={handleOpenTaskFn}
        />
      </Menu>
    </div>
  );
};
export default AnalysisPlotsMenu;

const TaskMenu = ({ handleOpenTaskFn, isLoading, tasks, title }) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }
  return (
    <>
      <StyledTitle>
        <StyledTitleText>{title}</StyledTitleText>
        <div>
          <Badge color={globalColors.slate2} label={`${tasks?.length}`} />
        </div>
      </StyledTitle>
      <Content>
        {tasks?.map((task) => (
          <LinkedTaskItem key={task.id} task={task} handleOpenTaskFn={handleOpenTaskFn} />
        ))}
      </Content>
    </>
  );
};

const LinkedTaskItem = ({ task }) => {
  const { ready } = useTranslation(['analysis.analysis_template_table']);

  if (!ready || !task) return null;

  return (
    <TaskRow>
      <DetailColumn>
        <TaskTitle>{task?.title}</TaskTitle>
      </DetailColumn>
    </TaskRow>
  );
};

TaskMenu.propTypes = {
  tasks: PropTypes.array,
  handleOpenTaskFn: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};

LinkedTaskItem.propTypes = {
  task: PropTypes.object,
  isLoading: PropTypes.bool,
  handleOpenTaskFn: PropTypes.func,
};

AnalysisPlotsMenu.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  containerRef: PropTypes.instanceOf(Object),
  menuPlacement: PropTypes.string,
  tasks: PropTypes.array,
  isLoading: PropTypes.bool,
};

AnalysisPlotsMenu.defaultProps = {
  containerRef: null,
};
