import { ErrorMessage } from '@hookform/error-message';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DataLoader } from '@ge/components/data-loader';
import { useAllCrews } from '@ge/feat-manage/data-hooks/use-all-crews';
import { ErpCustomFieldName, DataLoaderType } from '@ge/models/constants';

import {
  SectionColumn,
  SectionContainer,
  SectionTopHeading,
  SectionHeading,
  SectionTroubleshooting,
  SectionRow,
  StyledNumberMetaField,
  StyledErrorMessage,
} from './styles';

export const CreateLaborCharges = ({ section, erpData, task, isTaskSelected }) => {
  // Number of assigned techs
  const { data: allCrews, isFetching } = useAllCrews();

  const crewId = task?.crewIds && task?.crewIds[0];
  const filteredCrewData = allCrews
    ?.filter((crew) => crew._id === crewId)
    .map((filteredCrew) => filteredCrew.members);
  const members = [].concat.apply([], filteredCrewData);
  const memberIds = members.map((mm) => mm.member_id);
  // Esitmated duration
  const estimatedHours = task?.estDurationHours;

  // Default value for repair labor hours
  const defaultHours = isTaskSelected
    ? String(memberIds.length * parseInt(estimatedHours))
    : String(0);
  const { errors, trigger } = useFormContext();
  // if these are 0 it will be a string so won't be falsy
  const watchRepairHours = useWatch({ name: ErpCustomFieldName.REPAIR_HOURS });
  const watchTroubleshootingHours = useWatch({ name: ErpCustomFieldName.TROUBLESHOOTING_HOURS });
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const laborHoursFieldRules = {
    max: { value: 999.99, message: t('form.invalid_quantity') },
    min: { value: 0, message: t('form.invalid_quantity') },
    pattern: { value: /^\d+\.?\d{0,2}$/, message: t('form.invalid_quantity') },
  };

  // handles validation dependencies between fields
  // manually triggering appears to suppress validation on the field that changes, so validating both when either changes
  useEffect(() => {
    trigger([ErpCustomFieldName.REPAIR_HOURS, ErpCustomFieldName.TROUBLESHOOTING_HOURS]);
  }, [trigger, watchRepairHours, watchTroubleshootingHours]);

  if (!section || section.hidden) {
    return null;
  }

  const getErpDataValue = (fieldName) => {
    if (!erpData) return null;

    return erpData[fieldName]?.toString();
  };

  return (
    <DataLoader type={DataLoaderType.TABLE} isLoading={isFetching}>
      <SectionContainer>
        <SectionTopHeading>{section.title}</SectionTopHeading>
        <SectionHeading>{t('form.labor')}</SectionHeading>
        <SectionRow>
          <SectionColumn>
            <label>{t('form.labor_type')}</label>
          </SectionColumn>
          <SectionColumn>
            <label>{t('form.labor_hrs')}</label>
          </SectionColumn>
        </SectionRow>
        <SectionRow>
          <SectionColumn>
            <label>{t('form.repair')}</label>
          </SectionColumn>
          <SectionColumn>
            <>
              <StyledNumberMetaField
                defaultValue={getErpDataValue(ErpCustomFieldName.REPAIR_HOURS) ?? defaultHours}
                label={' '}
                errors={errors}
                max={laborHoursFieldRules.max}
                metadata={{}}
                min={laborHoursFieldRules.min}
                name={ErpCustomFieldName.REPAIR_HOURS}
                placeholder=""
                rules={{
                  ...laborHoursFieldRules,
                  required: false,
                  validate: {
                    conditionalRequired: (value) => {
                      const isEmptyWhileRequired =
                        value === '0' ||
                        watchTroubleshootingHours === '0' ||
                        Boolean(Number(value)) ||
                        Boolean(Number(watchTroubleshootingHours));
                      return isEmptyWhileRequired || t('form.please_select_an_option');
                    },
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name={ErpCustomFieldName.REPAIR_HOURS}
                render={({ message }) => (
                  <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                )}
              />
            </>
          </SectionColumn>
        </SectionRow>
        <SectionRow>
          <SectionColumn>
            <SectionTroubleshooting>
              <label>{t('form.troubleshooting')}</label>
            </SectionTroubleshooting>
          </SectionColumn>
          <SectionColumn>
            <StyledNumberMetaField
              defaultValue={getErpDataValue(ErpCustomFieldName.TROUBLESHOOTING_HOURS) ?? ''}
              label={' '}
              max={laborHoursFieldRules.max}
              metadata={{}}
              min={laborHoursFieldRules.min}
              name={ErpCustomFieldName.TROUBLESHOOTING_HOURS}
              placeholder=""
              rules={{
                ...laborHoursFieldRules,
                required: false,
                validate: {
                  conditionalRequired: (value) => {
                    const isEmptyWhileRequired =
                      value === '0' ||
                      watchRepairHours === '0' ||
                      Boolean(Number(value)) ||
                      Boolean(Number(watchRepairHours));
                    return isEmptyWhileRequired || t('form.please_select_an_option');
                  },
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name={ErpCustomFieldName.TROUBLESHOOTING_HOURS}
              render={({ message }) => (
                <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
              )}
            />
          </SectionColumn>
        </SectionRow>
      </SectionContainer>
    </DataLoader>
  );
};

CreateLaborCharges.propTypes = {
  section: PropTypes.object,
  erpData: PropTypes.object,
  task: PropTypes.object,
  isTaskSelected: PropTypes.bool,
};

CreateLaborCharges.defaultProps = {
  section: {},
  task: {},
};
