import Tooltip from '@mui/material/Tooltip';
import { useStoreState, useStoreActions } from 'easy-peasy';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { Capability, DataLoaderType, Delimiters } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { globalColors } from '@ge/tokens/colors';

import { fetchSiteMetrics } from '../../../../packages/shared/services/site';

const SubHeader = styled.div`
  width: 300px;
  height: 33px;
  .needs-attention-header {
    height: 15px;
    width: 93px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
  }
  font-size: 12px;
  padding: 0px 18px 5px;
  color: ${(props) =>
    props.theme.name === 'Dark' ? props.theme.colors.grey4 : props.theme.colors.grey1};
  .attention-assets-count-section {
    padding-top: 2px;
  }
  .status-attention-badge {
    display: inline-block;
    position: absolute;
    left: 27px;
    /* top: 145px; */
    width: 4px;
    height: 4px;
    background: red;
    border-radius: 30px 30px 30px 30px;
    font-size: 20px;
    z-index: 2;
  }

  .attention-turbine-icon {
    display: inline-block;
    position: relative;
  }

  .assets-count {
    display: inline-block;
    margin-left: 6px;
  }

  .global-count {
    font-family: 'Museo Sans';
    font-size: 11px;
    font-weight: 300;
    margin-right: 10px;
    color: ${(props) =>
      props.theme.name === 'Dark' ? props.theme.colors.grey11 : props.theme.colors.grey1};
  }

  .asset-badge-row {
    display: inline-flex;
    text-align: center;
  }

  .asset {
    margin: 0 1px;
    width: 18px;
    height: 14px;
    border-radius: 5px;
    color: ${(props) =>
      props.theme.name === 'Dark' ? props.theme.colors.grey11 : props.theme.colors.white};
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;

    :hover {
      cursor: pointer;
    }
  }

  .tripped-asset-badge {
    background-color: ${globalColors.red2};
  }

  .impacted-asset-badge {
    background-color: ${globalColors.violet3};
  }

  .stopped-asset-badge {
    background-color: ${globalColors.straw3};
  }

  .repair-asset-badge {
    background-color: ${globalColors.magenta2};
  }

  .maintenance-asset-badge {
    background-color: ${globalColors.magenta1};
  }

  .netcom-asset-badge {
    background-color: ${globalColors.stone2};
  }
`;

// MUI Tooltip Custom Styles

const AttentionStatusTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${globalColors.slate2};
    color: ${globalColors.white};
    margin-bottom: 0;
    text-align: center;
    top: 9px;
  }

  & .MuiTooltip-arrow {
    color: ${globalColors.slate2};
  }

  & .status-tooltip-container {
    text-align: center;
  }

  & .tooltip-title {
    font-family: 'Museo Sans';
    font-size: 10px;
    font-weight: 100;
    margin-left: 2px;
    letter-spacing: 0.01em;
  }

  & .tooltip-pill {
    font-size: 12px;
    display: inline-block;
    min-width: 15px;
    min-width: 19px;
    border-radius: 5px;
    margin-right: 5px;
    text-align: center;
  }

  & .badge-letter {
    font-family: 'Museo Sans';
    font-size: 11px;
  }
`;

const AttentionOverviewTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${(props) =>
      props.theme.name === 'Dark' ? `${globalColors.slate2}` : `${globalColors.white}`};
    color: ${globalColors.white};
    margin-left: 8px;
    padding: 0;
    width: 220px;
    min-width: fit-content;
    z-index: 100;
    max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & .MuiTooltip-tooltip::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }

  & .MuiTooltip-tooltip::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }

  & .MuiTooltip-tooltip::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }

  & .overview-tooltip-header-container {
    font-family: 'Museo Sans';
    border-radius: 5px;
  }

  & .overview-tooltip-header {
    background-color: ${globalColors.slate25};
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding: 3px 0;
    position: relative;
    z-index: 2;
  }

  & .overview-tooltip-header-left {
    display: inline-flex;
    margin-left: 1em;
  }

  & .overview-tooltip-header-right {
    margin: 0 2em 0 0;
    padding: 0;
  }

  & .overview-tooltip-site-title {
    font-size: 10px;
    margin-left: 5px;
    position: relative;
    top: 2px;
    color: ${(props) =>
      props.theme.name === 'Dark' ? props.theme.colors.grey11 : props.theme.colors.white};
  }

  & .header-backdrop {
    outline: 4px solid #445a6c;
    position: relative;
    z-index: 1;
    top: -4px;
  }

  & .border-cover {
    outline: 2px solid ${globalColors.slate25};
    position: relative;
    top: -1px;
    z-index: 3;
  }

  & .overview-tooltip-site-count {
    font-size: 10px;
    position: relative;
    top: 1px;
  }

  & .overview-attention-badge {
    display: inline-block;
    position: absolute;
    bottom: 11.5px;
    width: 4px;
    height: 4px;
    background: red;
    border-radius: 30px 30px 30px 30px;
    font-size: 5px;
    z-index: 2;
  }

  & .overview-tooltip-asset-container {
    padding: 4px 0;
    width: 190px;
    position: relative;
    left: 18px;
  }

  & .overview-tooltip-asset-name {
    margin-left: 2px;
    font-size: 9px;
    color: ${(props) =>
      props.theme.name === 'Dark' ? props.theme.colors.grey11 : props.theme.colors.grey1};
  }

  & .overview-tooltip-asset-status {
    display: inline;
    float: right;
    margin-right: 1em;
  }

  & .overview-tooltip-asset-status-pill {
    min-width: 18px;
    border-radius: 5px;
    margin-left: 4px;
    text-align: center;
  }

  .nav-link {
    color: ${(props) =>
      props.theme.name === 'Dark' ? props.theme.colors.grey11 : props.theme.colors.white};
    text-decoration: none;
  }

  .data-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
`;

// Icon Styles

const StatusTooltipTurbineIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.themeSelector.siteIconColor || '',
}))``;

const OverviewTooltipTurbineIcon = styled(Icon).attrs((props) => ({
  size: 9,
  color: props.theme.colors.grey6,
}))``;

const OverviewTooltipTurbineIconForSite = styled(Icon).attrs(() => ({
  size: 9,
  color: `${globalColors.grey4}`,
}))`
  padding-left: 20px;
`;

const OverviewTooltipArrowIcon = styled(Icon).attrs((props) => ({
  size: 8,
  color: props.theme.colors.grey6,
}))`
  transform: rotate(-90deg);
  position: relative;
  left: 2px;
  top: 4px;
`;

const OverviewTooltipArrowIconForAsset = styled(Icon).attrs((props) => ({
  size: 8,
  color: props.theme.themeSelector.siteIconColor || '',
}))`
  transform: rotate(-90deg);
  position: relative;
  left: 2px;
  top: -0.5px;
`;

const OverviewTrayExpandedIcon = styled(Icon).attrs(() => ({
  size: 6,
  color: `${globalColors.grey5}`,
}))`
  position: relative;
  right: 5px;
  top: 5px;
  margin-left: 2px;
`;

const OverviewTrayExpandedIconRight = styled(OverviewTrayExpandedIcon).attrs(() => ({
  size: 6,
  color: `${globalColors.grey5}`,
}))`
  transform: rotate(30deg);
`;

const SiteIcon = styled(Icon).attrs(() => ({
  size: 12,
  color: `${globalColors.grey4}`,
}))``;

export const NeedsAttention = () => {
  const { ready, t } = useTranslation(['manage.planning'], {
    useSuspense: false,
  });

  // State

  const needsAttention = useStoreState((state) => state.needsAttention.needsAttention);
  const { assetsData } = useStoreState((state) => state.needsAttention);
  const { globalCount } = useStoreState((state) => state.needsAttention);
  const { assetsCount } = useStoreState((state) => state.needsAttention);

  const { currentView } = useStoreState((state) => state.view);
  const getAssetsBySiteIds = useStoreState((state) => state.assets.getAssetsBySiteIds);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  // Actions

  const {
    setAssetsData,
    setAttentionError,
    setGlobalCount,
    setAssetsTrippedCount,
    setAssetsImpactedCount,
    setAssetsStoppedCount,
    setAssetsRepairCount,
    setAssetsMaintenanceCount,
    setAssetsNetcomCount,
  } = useStoreActions((actions) => actions.needsAttention);

  // Returns a string of the currently viewed site(s) ID

  const stringOfSiteIds = currentView.sites.map((site) => site?.id).join(',');

  /* assets -> Gets all assets for currently viewed site(s)
     assetIds -> Maps over assets to get an array of assetIds
  */

  const assets = getAssetsBySiteIds(stringOfSiteIds);
  const assetIds = assets.map((asset) => asset.id);

  // Returns array of assets used to get the count for each status

  const arrayOfAssets = useCallback(() => {
    let assets = [];
    if (Object.keys(assetsData).length) {
      for (const [, value] of Object.entries(assetsData)) {
        assets.push(value);
      }
    }
    return assets;
  }, [assetsData]);

  // Returns an array of currently viewed site(s) object

  const arrayOfSites = useCallback(() => {
    let currentViewCopy = [...currentView.sites];
    let sites = [];

    currentViewCopy.forEach((site) => {
      sites.push(getSiteById(site?.id));
    });

    return sites;
  }, [currentView.sites]);

  // Array of asset IDs that need attention

  const arrOfAssetIds = assetsData?.length && assetsData.map((x) => x.assetId);

  // Filtered & sorted assets for mapping

  const assetsNeedAttention =
    assets?.length &&
    arrOfAssetIds?.length &&
    assets
      .filter((asset) => arrOfAssetIds.includes(asset.id))
      .sort((a, b) => arrOfAssetIds.indexOf(a.id) - arrOfAssetIds.indexOf(b.id));

  // Fetches all Service Groups

  const { sortedServiceGroups } = useStoreState((state) => state.sites);

  // Returns an array of the Service Group ID's with no duplicates

  const getServiceGroupIds = useCallback(() => {
    const serviceGroupIds = [];

    sortedServiceGroups.forEach((serviceGroup) => {
      serviceGroup.sites.some((elem) => {
        const match = currentView.sites.find((x) => elem.id === x.id);

        if (typeof match !== 'undefined') {
          serviceGroupIds.push(serviceGroup.id);
        }
      });
    });
    return [...new Set(serviceGroupIds)];
  }, [currentView.sites, sortedServiceGroups]);

  // Returns an array of all the site names within a Service Group with duplicates

  const arrayOfSiteNames = () => {
    let siteNames = [];
    if (assetsNeedAttention?.length) {
      assetsNeedAttention.forEach((asset) => {
        let siteName = getSiteById(asset.site.id).name;
        siteNames.push(siteName);
      });
    }
    return siteNames;
  };

  // Fetches site metrics every 30 seconds

  const { isLoading: siteMetricsLoading } = useQuery(
    ['siteMetricsData', stringOfSiteIds, assetIds],
    async () => fetchSiteMetrics(stringOfSiteIds, assetIds),
    {
      onSuccess: (data) => setAssetsData(data),
      onError: (error) => setAttentionError(error),
      refetchInterval: 30000,
      enabled: getServiceGroupIds().length === 1,
    },
  );

  // Initializes the count for each asset status

  useEffect(() => {
    setAssetsTrippedCount(arrayOfAssets());
    setAssetsImpactedCount(arrayOfAssets());
    setAssetsStoppedCount(arrayOfAssets());
    setAssetsRepairCount(arrayOfAssets());
    setAssetsMaintenanceCount(arrayOfAssets());
    setAssetsNetcomCount(arrayOfAssets());
    setGlobalCount();
  }, [
    setGlobalCount,
    setAssetsTrippedCount,
    setAssetsImpactedCount,
    setAssetsStoppedCount,
    setAssetsRepairCount,
    setAssetsMaintenanceCount,
    setAssetsNetcomCount,
    arrayOfAssets,
  ]);

  if (!ready) return null;

  return (
    <AuthRender
      capability={Capability.REAL_TIME_PAGES}
      view
      description="Assets that need attention"
      siteLevel={false}
    >
      {/* If user has selected only 1 Service Group -> render globalCount */}

      {getServiceGroupIds().length === 1 && (
        <SubHeader>
          <h3 className="needs-attention-header">{t('needs attention', 'needs attention')}</h3>
          <div className="attention-assets-count-section">
            {/* Red dot for global count */}
            <div className="status-attention-badge"></div>
            <div className="attention-turbine-icon">
              <StatusTooltipTurbineIcon icon={Icons.TURBINE} />{' '}
            </div>
            <div className="assets-count">
              <span className="global-count">{globalCount}</span>
              {/* If needsAttention = true -> render badges with status count */}

              {needsAttention && (
                <div className="asset-badge-row">
                  {Object.entries(assetsCount).map((x) => {
                    let status = x[0];
                    let statusCount = x[1];

                    {
                      /* Check if status has a value > 0 & render badges*/
                    }

                    if (statusCount > 0) {
                      return (
                        <AttentionStatusTooltip
                          key={uuidv4()}
                          title={
                            <div className="status-tooltip-container">
                              {/* Conditional Render -> Badge BG Color for Tooltip */}
                              <div
                                style={{
                                  backgroundColor:
                                    status === 'assetsTrippedCount'
                                      ? `${globalColors.red2}`
                                      : status === 'assetsImpactedCount'
                                      ? `${globalColors.violet3}`
                                      : status === 'assetsStoppedCount'
                                      ? `${globalColors.straw3}`
                                      : status === 'assetsRepairCount'
                                      ? `${globalColors.magenta2}`
                                      : status === 'assetsMaintenanceCount'
                                      ? `${globalColors.magenta1}`
                                      : status === 'assetsNetcomCount'
                                      ? `${globalColors.stone2}`
                                      : '',
                                }}
                                className="tooltip-pill"
                              >
                                {/* Conditional Render -> Badge Letter for Tooltip */}

                                <span className="badge-letter">
                                  {status === 'assetsTrippedCount'
                                    ? `T`
                                    : status === 'assetsImpactedCount'
                                    ? `I`
                                    : status === 'assetsStoppedCount'
                                    ? `S`
                                    : status === 'assetsRepairCount'
                                    ? `R`
                                    : status === 'assetsMaintenanceCount'
                                    ? `M`
                                    : status === 'assetsNetcomCount'
                                    ? `N`
                                    : ''}
                                </span>
                              </div>
                              <span className="tooltip-title">
                                {/* Conditional Render -> Status & Count for Tooltip */}

                                {status === 'assetsTrippedCount'
                                  ? `${statusCount} Tripped`
                                  : status === 'assetsImpactedCount'
                                  ? `${statusCount} Impacted`
                                  : status === 'assetsStoppedCount'
                                  ? `${statusCount} Stopped`
                                  : status === 'assetsRepairCount'
                                  ? `${statusCount} Repair`
                                  : status === 'assetsMaintenanceCount'
                                  ? `${statusCount} Maintenance`
                                  : status === 'assetsNetcomCount'
                                  ? `${statusCount} Netcom`
                                  : ''}
                              </span>
                            </div>
                          }
                          placement="top-start"
                          arrow
                        >
                          {/* Conditional Render -> ClassName for Badge BG Color */}

                          <span
                            className={
                              status === 'assetsTrippedCount'
                                ? `asset tripped-asset-badge`
                                : status === 'assetsImpactedCount'
                                ? `asset impacted-asset-badge`
                                : status === 'assetsStoppedCount'
                                ? `asset stopped-asset-badge`
                                : status === 'assetsRepairCount'
                                ? `asset repair-asset-badge`
                                : status === 'assetsMaintenanceCount'
                                ? `asset maintenance-asset-badge`
                                : status === 'assetsNetcomCount'
                                ? `asset netcom-asset-badge`
                                : 'asset'
                            }
                          >
                            {/* If siteMetricsLoading = true -> render loader -> else render service group info */}

                            <AttentionOverviewTooltip
                              leaveDelay={300}
                              describeChild
                              title={
                                siteMetricsLoading ? (
                                  <div className="data-loader">
                                    <DataLoader
                                      type={DataLoaderType.GRID}
                                      isLoading={siteMetricsLoading}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {/* Check arrayOfSiteNames and get count of each site name */}

                                    {arrayOfSites().map((site) => {
                                      {
                                        /* Asset count for each site */
                                      }
                                      const globalSiteCount = arrayOfSiteNames().filter(
                                        (name) => name === site?.name,
                                      ).length;

                                      return (
                                        <div className="overview-tooltip-container" key={uuidv4()}>
                                          <div className="overview-tooltip-header-container">
                                            <NavLink
                                              className="nav-link"
                                              to={`/execute/overview?entity=entityId${Delimiters.KEY_VALUE}${site?.id}--entityType${Delimiters.KEY_VALUE}site`}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: `${globalColors.slate25}`,
                                                }}
                                              >
                                                <div className="overview-tooltip-header">
                                                  <div className="overview-tooltip-header-left">
                                                    {/* Conditional Render -> Icon position based on globalSiteCount */}
                                                    {globalSiteCount > 0 ? (
                                                      <OverviewTrayExpandedIcon
                                                        icon={Icons.TRAY_EXPANDABLE}
                                                      />
                                                    ) : (
                                                      <OverviewTrayExpandedIconRight
                                                        icon={Icons.TRAY_EXPANDABLE}
                                                      />
                                                    )}
                                                    <SiteIcon icon={Icons.SITE} />{' '}
                                                    {/* Conditional Render -> Truncate Site name if length > 18 */}
                                                    <span className="overview-tooltip-site-title">
                                                      {site?.name.length > 18
                                                        ? site?.name.substring(0, 18) + '...'
                                                        : site?.name}
                                                    </span>
                                                    <OverviewTooltipArrowIcon
                                                      icon={Icons.CHEVRON}
                                                    />{' '}
                                                  </div>
                                                  <div className="overview-tooltip-header-right">
                                                    {/* Red dot for global site count */}
                                                    <div
                                                      className="overview-attention-badge"
                                                      style={{
                                                        right:
                                                          globalSiteCount >= 10
                                                            ? '34.5px'
                                                            : '29.5px',
                                                      }}
                                                    ></div>
                                                    <OverviewTooltipTurbineIcon
                                                      icon={Icons.TURBINE}
                                                    />{' '}
                                                    <span className="overview-tooltip-site-count">
                                                      {' '}
                                                      {globalSiteCount}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </NavLink>

                                            {/* Conditional Render -> globalSiteCount > 0 -> Render header-backdrop & border-cover */}
                                            {globalSiteCount > 0 && (
                                              <>
                                                {' '}
                                                <div
                                                  style={{
                                                    display: globalSiteCount === 0 && 'none',
                                                  }}
                                                  className="header-backdrop"
                                                ></div>
                                                <div className="border-cover"></div>
                                              </>
                                            )}

                                            {/* Filter out the assets that need attention */}
                                            {assetsNeedAttention?.length &&
                                              assetsNeedAttention
                                                .filter((asset) => arrOfAssetIds.includes(asset.id))
                                                .map((asset, index) => {
                                                  const assetSiteName = getSiteById(
                                                    asset.site?.id,
                                                  ).name;

                                                  if (
                                                    assetSiteName === site?.name &&
                                                    globalSiteCount > 0
                                                  ) {
                                                    {
                                                      /* Conditional Render -> If index = 0 -> Add borderTop */
                                                    }
                                                    return (
                                                      <div
                                                        key={uuidv4()}
                                                        className="overview-tooltip-asset-container"
                                                        style={{
                                                          borderTop:
                                                            index >= 0 &&
                                                            `1px solid ${globalColors.grey9}`,
                                                        }}
                                                      >
                                                        <NavLink
                                                          to={`/execute/overview?entity=entityId${Delimiters.KEY_VALUE}${asset.id}--entityType${Delimiters.KEY_VALUE}turbine`}
                                                          className="nav-link"
                                                        >
                                                          <div className="overview-tooltip-asset">
                                                            <OverviewTooltipTurbineIconForSite
                                                              icon={Icons.TURBINE}
                                                            />{' '}
                                                            <span
                                                              className="overview-tooltip-asset-name"
                                                              id={`asset-${index}`}
                                                            >
                                                              {asset.name}
                                                            </span>
                                                            <OverviewTooltipArrowIconForAsset
                                                              icon={Icons.CHEVRON}
                                                            />{' '}
                                                            <div className="overview-tooltip-asset-status">
                                                              {/* Map over assetsData & render the status */}
                                                              {assetsData.map((x) => {
                                                                const status = x.state.value;
                                                                if (x.assetId === asset.id) {
                                                                  {
                                                                    /* Conditional Render -> ClassName for Badge BG Color */
                                                                  }
                                                                  return (
                                                                    <span
                                                                      key={uuidv4()}
                                                                      style={{
                                                                        display: 'inline-block',
                                                                        backgroundColor:
                                                                          status === 'tripped'
                                                                            ? `${globalColors.red2}`
                                                                            : status === 'impacted'
                                                                            ? `${globalColors.violet3}`
                                                                            : status === 'stopped'
                                                                            ? `${globalColors.straw3}`
                                                                            : status === 'repair'
                                                                            ? `${globalColors.magenta2}`
                                                                            : status ===
                                                                              'maintenance'
                                                                            ? `${globalColors.magenta1}`
                                                                            : status === 'no_comm'
                                                                            ? `${globalColors.stone2}`
                                                                            : '',
                                                                        padding:
                                                                          status ===
                                                                            'maintenance' &&
                                                                          '0 0.5px',
                                                                      }}
                                                                      className="asset overview-tooltip-asset-status-pill badge-letter"
                                                                    >
                                                                      {/* Conditional Render -> Badge Letter for Tooltip */}

                                                                      {status === 'tripped'
                                                                        ? `T`
                                                                        : status === 'impacted'
                                                                        ? `I`
                                                                        : status === 'stopped'
                                                                        ? `S`
                                                                        : status === 'repair'
                                                                        ? `R`
                                                                        : status === 'maintenance'
                                                                        ? `M`
                                                                        : status === 'no_comm'
                                                                        ? `N`
                                                                        : ''}
                                                                    </span>
                                                                  );
                                                                }
                                                              })}
                                                            </div>
                                                          </div>
                                                        </NavLink>
                                                      </div>
                                                    );
                                                  }
                                                })}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )
                              }
                            >
                              {/* Conditional Render -> Status count */}

                              <span>
                                {status === 'assetsTrippedCount'
                                  ? `${assetsCount.assetsTrippedCount}`
                                  : status === 'assetsImpactedCount'
                                  ? `${assetsCount.assetsImpactedCount}`
                                  : status === 'assetsStoppedCount'
                                  ? `${assetsCount.assetsStoppedCount}`
                                  : status === 'assetsRepairCount'
                                  ? `${assetsCount.assetsRepairCount}`
                                  : status === 'assetsMaintenanceCount'
                                  ? `${assetsCount.assetsMaintenanceCount}`
                                  : status === 'assetsNetcomCount'
                                  ? `${assetsCount.assetsNetcomCount}`
                                  : 'status'}
                              </span>
                            </AttentionOverviewTooltip>
                          </span>
                        </AttentionStatusTooltip>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        </SubHeader>
      )}
    </AuthRender>
  );
};
