import { PropTypes } from 'prop-types';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CheckedState, Checkbox } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Select } from '@ge/components/select';
import { Textarea } from '@ge/components/textarea/textarea';
import useStateRef from '@ge/hooks/state-ref';
import { EntityType, FormMode, FileType } from '@ge/models/constants';
import { AttachmentField } from '@ge/shared/components/meta-fields';
import { globalColors } from '@ge/tokens/colors';

import { useCaseLinkedTasks } from '../use-linked-task-menu';

const LoaderContainer = styled.div`
  position: relative;
  min-height: 200px;
`;

const FormContainer = styled(ScrollingContainer)`
  max-height: calc(100vh - 380px);
  padding: 0 1px;
  * {
    box-sizing: border-box;
  }
`;

const FormRow = styled.div`
  margin-bottom: 15px;
`;

const StyledTextarea = styled(Textarea)`
  height: 100px;
  width: 100%;
  outline: ${globalColors.red1};
`;

const StyledLabel = styled.div`
  color: ${({ theme }) => theme.input.labelTextColor};
  font-size: 11px;
  padding-bottom: 5px;
`;

const InputRow = styled.div`
  display: flex;
  column-gap: 40px;
  margin-bottom: 10px;
`;

const RadioLabel = styled.div`
  font-size: 12px;
`;

const StyledRadio = styled(Checkbox).attrs({ type: 'radio' })`
  align-items: center;
  * {
    border-radius: 9px;
  }
`;

const TaskRow = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  padding-bottom: 15px;
  margin-bottom: 20px;
  > div {
    width: 50%;
  }
`;

const NoteForm = forwardRef(
  (
    {
      entity,
      entityType,
      namespace,
      isLoading,
      data,
      files,
      errors,
      mode,
      handleUpload,
      enableFileUpload,
      maxFiles,
      maxFileSizeMb,
      noteControl,
    },
    parentRef,
  ) => {
    const { t } = useTranslation([namespace]);
    const [isAddToCase, setIsAddToCase, addToCaseRef] = useStateRef(CheckedState.CHECKED);
    const [isAddToTask, setIsAddToTask, addToTaskRef] = useStateRef(CheckedState.UNCHECKED);

    const params =
      entityType === EntityType.TASK
        ? { caseId: entity.caseId, taskId: entity.id, enabled: false }
        : { caseId: entity.id };
    const { tasks, isTasksLoading } = useCaseLinkedTasks(params);
    const taskOptions = useMemo(
      () =>
        isTasksLoading
          ? []
          : tasks?.map((task) => ({
              label: task.title,
              value: task.id,
            })),
      [isTasksLoading, tasks],
    );

    const toggleAddToCase = useCallback(() => {
      setIsAddToCase(
        addToCaseRef.current === CheckedState.CHECKED
          ? CheckedState.UNCHECKED
          : CheckedState.CHECKED,
      );
      setIsAddToTask(CheckedState.UNCHECKED);
    }, [setIsAddToCase, addToCaseRef, setIsAddToTask]);

    const toggleAddToTask = useCallback(() => {
      setIsAddToTask(
        addToTaskRef.current === CheckedState.CHECKED
          ? CheckedState.UNCHECKED
          : CheckedState.CHECKED,
      );
      setIsAddToCase(CheckedState.UNCHECKED);
    }, [setIsAddToTask, addToTaskRef, setIsAddToCase]);

    const validateNoteType = useCallback(
      () => isAddToCase === CheckedState.CHECKED || isAddToTask === CheckedState.CHECKED,
      [isAddToCase, isAddToTask],
    );

    const validateNote = useCallback(
      (value) => {
        const val = value.trim();
        return Boolean(val || files?.length);
      },
      [files],
    );

    const allowedFileTypes = [
      FileType.DOC,
      FileType.DOCX,
      FileType.GIF,
      FileType.JPG,
      FileType.JPEG,
      FileType.PNG,
      FileType.XLS,
      FileType.XLSM,
      FileType.XLSX,
      FileType.PPT,
      FileType.PPTX,
      FileType.PDF,
      FileType.ZIP,
    ];

    if (isLoading || isTasksLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }
    return (
      <FormContainer autoHide={false} relative={true}>
        {entityType === EntityType.CASE && mode === FormMode.CREATE && (
          <>
            <InputRow>
              <StyledRadio
                id="isAddToCase"
                label={<RadioLabel>{t('notes.add_to_case', 'Add to Case')}</RadioLabel>}
                name="isAddToCase"
                checkState={isAddToCase}
                onChange={toggleAddToCase}
                ref={parentRef({ validate: validateNoteType })}
                error={errors?.isAddToCase}
              />
              {tasks?.length ? (
                <StyledRadio
                  id="isAddToTask"
                  label={<RadioLabel>{t('notes.add_to_task', 'Add to Task')}</RadioLabel>}
                  name="isAddToTask"
                  checkState={isAddToTask}
                  onChange={toggleAddToTask}
                  disabled={!tasks?.length}
                  ref={parentRef({ validate: validateNoteType })}
                  error={errors?.isAddToTask}
                />
              ) : null}
            </InputRow>

            {tasks?.length ? (
              <>
                <StyledLabel>{t('notes.task', 'Task')}</StyledLabel>
                <TaskRow>
                  <div>
                    <Controller
                      control={noteControl}
                      name="task"
                      defaultValue={taskOptions?.length > 0 ? taskOptions[0] : null}
                      render={({ onChange, value }) => (
                        <Select
                          primary
                          options={taskOptions}
                          onChange={onChange}
                          value={value}
                          disabled={isAddToTask === CheckedState.UNCHECKED}
                        />
                      )}
                    />
                  </div>
                </TaskRow>
              </>
            ) : null}
          </>
        )}
        <FormRow>
          <StyledTextarea
            id="note-description"
            label={t('notes.note', 'Note')}
            placeholder={t('notes.add_note_placeholder', 'Add a note')}
            name="note"
            defaultValue={mode === FormMode.EDIT ? data.note : ''}
            ref={parentRef({ validate: validateNote })}
            error={errors?.note}
          />
        </FormRow>
        {enableFileUpload && (
          <AttachmentField
            id="note-attachments"
            mode={mode}
            name="attachments"
            allowedFileTypes={allowedFileTypes}
            maxFiles={data?.attachments?.length ? maxFiles - data?.attachments?.length : maxFiles}
            maxFileSizeMb={maxFileSizeMb}
            files={files}
            handleUpload={handleUpload}
          />
        )}
      </FormContainer>
    );
  },
);

NoteForm.propTypes = {
  namespace: PropTypes.string,
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  data: PropTypes.instanceOf(Object),
  files: PropTypes.array,
  error: PropTypes.string,
  handleUpload: PropTypes.func,
  enableFileUpload: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxFileSizeMb: PropTypes.number,
  entity: PropTypes.object,
  noteControl: PropTypes.object,
  entityType: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

NoteForm.defaultProps = {
  namespace: 'entity-details',
  isLoading: false,
  mode: null,
  data: null,
  files: [],
  error: null,
  enableFileUpload: null,
  allowedFileTypes: null,
  maxFiles: null,
  maxFileSizeMb: null,
  handleUpload: () => {},
  entity: {},
  noteControl: {},
};

NoteForm.displayName = 'NoteForm';

export default NoteForm;
