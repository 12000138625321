import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { updatePerson } from '../services';

export const useEditPerson = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const {
    mutate: edit,
    data,
    isLoading,
    isError,
    error,
    status,
  } = useMutation((input_data) => updatePerson(input_data), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.WORKER]);
      queryClient.invalidateQueries([QueryKey.INFINITE_PEOPLE]);
      queryClient.invalidateQueries([QueryKey.FETCH_PERSON]);
      onSuccess();
    },
  });
  return useMemo(
    () => ({ edit, isLoading, isError, error, data, status }),
    [edit, error, isError, isLoading, data, status],
  );
};
