import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import * as request from '@ge/shared/services/api';
import { sorter } from '@ge/util/metric-sorter';

import { useTenants } from './use-tenants';

const ROLES_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchRolesTable = () => {
  return request.get('/cmn/roles', {
    baseURL: ROLES_BASE_URL,
  });
};

export const useRolesTable = ({ filters, search, tenantId, sortMetric, sortDirection }) => {
  const { data: allTenants, applyTenantName } = useTenants();
  const {
    error,
    isError,
    isLoading: isRolesLoading,
    data: roles,
  } = useQuery([QueryKey.ROLES_TABLE], fetchRolesTable, {
    refetchOnWindowFocus: false,
  });

  const mappedRoles = useMemo(() => {
    if (roles && allTenants) {
      const formattedRoles = roles.map((role, idx) => {
        return {
          id: idx,
          role: role.name.replace(/[_-]/g, ' '),
          description: role.description,
          tenant: {
            id: role.tenantId,
            name: applyTenantName(role.tenantId),
          },
          capabilities: role.capabilities,
        };
      });
      return formattedRoles
        ?.sort(sorter(sortMetric, sortDirection))
        .filter((role) => (tenantId ? role.tenant.id === tenantId : true));
    }
  }, [sortMetric, sortDirection, roles, tenantId, allTenants, applyTenantName]);

  const data = useTableFilter({ data: mappedRoles, filters, search });

  return {
    isLoading: isRolesLoading,
    isError,
    error,
    data,
  };
};
