import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Text } from '@ge/components/typography';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';
import { globalColors } from '@ge/tokens';
import { typography } from '@ge/tokens/typography';

import { NoDataAvailable } from '../../no-data-available/no-data-available';
import { AvailabilityWidget } from '../availability-widget/availability-widget';
import { AverageWindSpeedWidget } from '../average-wind-speed-widget/average-wind-speed-widget';

const LineContainer = styled.div`
  height: 100%;
`;

const LoaderContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.widget.headerTextColor};
`;

const NoData = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataIcon = styled(Icon).attrs(() => ({
  icon: Icons.DATA_NETCOMM,
  size: 14,
  color: globalColors.grey5,
}))`
  margin: 0 5px 2px 0;
`;

const NoDataTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
}))`
  color: ${({ theme }) => theme.createReport.widget.headerLabelColor};
`;

const lineWidgets = {
  [WidgetNames.AVAILABILITY]: {
    widget: AvailabilityWidget,
  },
  [WidgetNames.AVERAGE_WIND_SPEED]: {
    widget: AverageWindSpeedWidget,
  },
};

// Return the correct implementation for the provided widget name.
const getLineWidgetByName = (widgetName, id, isInteractive, data, units) => {
  if (widgetName in lineWidgets) {
    // If the widget exists and we have an empty data set, show no data available.
    if (!data || data.length === 0) {
      return <NoDataAvailable />;
    }

    const lineWidgetEl = React.createElement(lineWidgets[widgetName].widget, {
      name: widgetName,
      id,
      isInteractive,
      data,
      units,
    });
    return lineWidgetEl;
  }

  // TODO: Remove this when all widgets have been implemented?
  return (
    <NoData>
      <NoDataIcon />
      <NoDataTitle>Not yet implemented</NoDataTitle>
    </NoData>
  );
};

export const LineWidget = ({ data, id, isInteractive, isLoading, isPlaceholder, name, units }) => {
  const { t } = useTranslation(['reporting.widgets', 'general']);

  const lineWidget = useMemo(() => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }

    if (isPlaceholder) {
      return null;
    }

    return getLineWidgetByName(name, id, isInteractive, data, units, t);
  }, [name, id, data, units, isInteractive, isLoading, isPlaceholder, t]);

  return (
    <>
      <StyledHeader>
        <h4>{name}</h4>
      </StyledHeader>
      <LineContainer>{lineWidget}</LineContainer>
    </>
  );
};

LineWidget.propTypes = {
  data: PropTypes.instanceOf(Object),
  id: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  name: PropTypes.string.isRequired,
  units: PropTypes.string,
};

LineWidget.defaultProps = {
  data: {},
  isLoading: false,
  isPlaceholder: false,
};
