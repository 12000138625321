import dayjs from 'dayjs';

import { NotesCategory, NoteSelectOptions, MonitorCaseStatus } from '@ge/models/constants';
import { mapObject } from '@ge/util/object-utils';

import * as request from './api';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;
const RTMC_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;

const URLS = {
  NOTES_AND_SI: '/cmn/notes-si',
  NOTES: '/cmn/notes',
  SPECIAL_INSTRUCTIONS: '/cmn/special-instructions',
  ENTITIES_NOTES: '/cmn/entities-notes',
  EVENT_NOTES: '/rtmc/event-notes',
  ESCALATE_CASE: '/rtmc/escalate-case',
  BULK_ESCALATE_CASE: '/rtmc/bulk-escalate-case',
  ACTIVE_SI: '/cmn/active-si',
  ALL_SITE_ACTIVE_SI: '/cmn/all-site-active-si',
  ASSETS_ACTIVE_SI: '/cmn/assets-active-si',
  BULK_EVENT_NOTES: '/rtmc/bulk-event-notes',
};

const getFormDataAndHeader = (data) => {
  const headers = {};
  const { payload, attachments } = data ?? {};
  if (attachments?.length > 0) {
    const formData = new FormData();
    formData.append('json', JSON.stringify(payload));
    for (const attachment of attachments) {
      formData.append('file', attachment.file, attachment.file.name);
    }

    headers['Content-Type'] = 'multipart/form-data';

    return { headers, body: formData };
  }
  return { headers, body: payload };
};

export const fetchNotesData = (
  assetType,
  notesType,
  id,
  pageParam = 0,
  pageSize = 10,
  creationDate,
  siteId,
) => {
  let url = '';
  let params = { assetType, id, pageIndex: pageParam, pageSize, creationDate, siteId };

  switch (notesType) {
    // case NoteSelectOptions.ALL:
    //   url = URLS.NOTES_AND_SI;
    //   break;
    case NoteSelectOptions.NOTES:
      url = URLS.NOTES;
      break;
    case NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION:
      url = URLS.SPECIAL_INSTRUCTIONS;
      params.isActive = true;
      break;
    case NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION:
      url = URLS.SPECIAL_INSTRUCTIONS;
      params.isActive = false;
      break;
  }

  return request.get(url, {
    baseURL: CMN_BASE_URL,
    params,
  });
};

export const addNoteAndSI = (values) => {
  const data = {
    payload: {
      domainId: values.entity?.id,
      title: values.title,
      note: values.note,
      category: values.category,
      scope: values.scope,
    },
    queryParams: {
      assetType: values.entityType,
    },
    attachments: values.attachments,
  };
  if (values.category === NotesCategory.SPECIAL_INSTRUCTION) {
    data.payload.title = 'Special Instructions';
    data.payload.status = values.status;
    if (values.validDateBy) {
      data.payload.validDateBy = dayjs(values.validDateBy).toISOString();
    }
  }
  const url =
    data?.payload?.category === NotesCategory.NOTE ? URLS.NOTES : URLS.SPECIAL_INSTRUCTIONS;

  const { headers, body } = getFormDataAndHeader(data);

  return request.post(url, body, {
    baseURL: CMN_BASE_URL,
    headers,
    params: data.queryParams,
  });
};

export const editNoteAndSI = ({ inputData: values, originalData }) => {
  const data = {
    payload: {
      inputData: {
        title: values.title,
        note: values.note,
        category: values.category,
        scope: values.scope,
      },
      originalData: {
        title: originalData.title,
        note: originalData.note,
        category: originalData.category,
        scope: originalData.scope,
      },
    },
    pathParams: {
      id: values.noteId,
    },
    queryParams: {
      assetType: values.entityType,
    },
    attachments: values.attachments,
  };
  if (values.category === NotesCategory.SPECIAL_INSTRUCTION) {
    data.payload.inputData.title = 'Special Instructions';
    data.payload.inputData.status = values.status;
    data.payload.originalData.status = originalData.status;
    if (values.validDateBy) {
      data.payload.inputData.validDateBy = dayjs(values.validDateBy).toISOString();
    }

    if (originalData.validDateBy) {
      data.payload.originalData.validDateBy = dayjs(originalData.validDateBy).toISOString();
    }
  }
  const url = values.category === NotesCategory.NOTE ? URLS.NOTES : URLS.SPECIAL_INSTRUCTIONS;

  const { headers, body } = getFormDataAndHeader(data);

  return request.patch(`${url}/${data.pathParams.id}`, body, {
    baseURL: CMN_BASE_URL,
    headers,
    params: data.queryParams,
  });
};

export const deleteNoteAndSI = (values) => {
  const data = {
    pathParams: {
      id: values.id,
    },
    queryParams: {
      assetType: values.entityType,
    },
  };
  const url = URLS.NOTES;

  return request.deleteItem(`${url}/${data.pathParams.id}`, {
    baseURL: CMN_BASE_URL,
    params: data.queryParams,
  });
};

export const fetchEntitiesNotes = (ids, entityType) => {
  const data = { ids: ids.join(','), entityType };

  return request.post(URLS.ENTITIES_NOTES, data, {
    baseURL: CMN_BASE_URL,
  });
};

export const addEventNote = (values) => {
  const data = {
    domainId: values?.entity?.id,
    note: values.note,
  };

  return request.post(URLS.EVENT_NOTES, data, {
    baseURL: RTMC_BASE_URL,
  });
};

export const addBulkEventNotes = (values) => {
  const data = {};
  data.domainIds = values?.caseDetails?.map((item) => item.caseId);
  data.note = values?.note;

  return request.post(URLS.BULK_EVENT_NOTES, data, {
    baseURL: RTMC_BASE_URL,
  });
};

export const getEventNotes = (id, pageParam = 0, pageSize = 10) => {
  const params = { id, pageIndex: pageParam, pageSize };
  return request.get(URLS.EVENT_NOTES, {
    baseURL: RTMC_BASE_URL,
    params,
  });
};

const getFormDataAndHeaderForEscalation = (data) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const { actionable, informational } = data ?? {};
  const formData = new FormData();
  formData.append('payload', JSON.stringify(data));
  if ((actionable?.attachments?.length > 0) | (informational?.attachments?.length > 0)) {
    for (const attachment of actionable?.attachments) {
      formData.append('attachment', attachment.file, attachment.id);
    }
    for (const attachment of informational?.attachments) {
      formData.append('attachment', attachment.file, attachment.id);
    }
  }
  return { headers, body: formData };
};

const ESCALATE_CASE_MAPPINGS = {
  caseId: { to: ['caseId'], from: ['entity', 'id'] },
  assetId: { to: ['assetId'], from: ['entity', 'asset', 'id'] },
  assetName: { to: ['assetName'], from: ['entity', 'asset', 'name'] },
  assetState: { to: ['assetState'], from: ['entity', 'asset', 'metrics', 'state', 'value'] },
  assetModel: { to: ['assetModel'], from: ['entity', 'asset', 'model'] },
  siteId: { to: ['siteId'], from: ['entity', 'site', 'id'] },
  timeZone: { to: ['timeZone'], from: ['entity', 'site', 'timezone'] },
  caseType: { to: ['caseType'], from: ['entity', 'type'] },
  siteName: { to: ['siteName'], from: ['entity', 'site', 'name'] },
  eventId: { to: ['eventId'], from: ['entity', 'code'] },
  eventDescription: { to: ['eventDescription'], from: ['entity', 'description'] },
  note: { to: ['note'], from: ['note'] },
  actionable_subject: { to: ['actionable', 'subject'], from: ['actionable_subject'] },
  actionable_to: { to: ['actionable', 'to'], from: ['actionable_to'] },
  actionable_cc: { to: ['actionable', 'cc'], from: ['actionable_cc'] },
  actionable_bcc: { to: ['actionable', 'bcc'], from: ['actionable_bcc'] },
  actionable_reply_to: { to: ['actionable', 'replyTo'], from: ['actionable_reply_to'] },
  actionable_attachment: { to: ['actionable', 'attachments'], from: ['actionable_attachment'] },
  informational_subject: { to: ['informational', 'subject'], from: ['informational_subject'] },
  informational_to: { to: ['informational', 'to'], from: ['informational_to'] },
  informational_cc: { to: ['informational', 'cc'], from: ['informational_cc'] },
  informational_bcc: { to: ['informational', 'bcc'], from: ['informational_bcc'] },
  informational_reply_to: { to: ['informational', 'replyTo'], from: ['informational_reply_to'] },
  informational_attachment: {
    to: ['informational', 'attachments'],
    from: ['informational_attachment'],
  },
  systemNumber: { to: ['systemNumber'], from: ['entity', 'asset', 'systemNumber'] },
};

export const escalateMonitorCase = (values) => {
  const data = mapObject(values, Object.values(ESCALATE_CASE_MAPPINGS));
  if (values?.entity?.code || values?.entity?.description) {
    data.event = `${values?.entity?.code} ${values?.entity?.description}`;
  }
  data.startTime = values?.entity?.start;
  if (values?.entity?.caseState === MonitorCaseStatus.CLOSED) {
    data.closedTime = values?.entity?.end;
  }

  if (!values?.entity?.asset?.id || !values?.entity?.asset?.name) {
    data.assetId = values?.entity?.asset?.id || values?.entity?.assetId;
    data.assetName = values?.entity?.asset?.name || values?.entity?.assetName;
  }

  if (values?.entity?.isSiteLevelCase) {
    data.assetName = 'All';
  }

  const { headers, body } = getFormDataAndHeaderForEscalation(data);

  return request.post(URLS.ESCALATE_CASE, body, {
    baseURL: RTMC_BASE_URL,
    headers,
  });
};

const BULK_ESCALATE_CASE_MAPPINGS = {
  caseDetails: { to: ['caseDetails'], from: ['caseDetails'] },
  note: { to: ['note'], from: ['note'] },
  actionable_subject: { to: ['actionable', 'subject'], from: ['actionable_subject'] },
  actionable_to: { to: ['actionable', 'to'], from: ['actionable_to'] },
  actionable_cc: { to: ['actionable', 'cc'], from: ['actionable_cc'] },
  actionable_bcc: { to: ['actionable', 'bcc'], from: ['actionable_bcc'] },
  actionable_reply_to: { to: ['actionable', 'replyTo'], from: ['actionable_reply_to'] },
  actionable_attachment: { to: ['actionable', 'attachments'], from: ['actionable_attachment'] },
  informational_subject: { to: ['informational', 'subject'], from: ['informational_subject'] },
  informational_to: { to: ['informational', 'to'], from: ['informational_to'] },
  informational_cc: { to: ['informational', 'cc'], from: ['informational_cc'] },
  informational_bcc: { to: ['informational', 'bcc'], from: ['informational_bcc'] },
  informational_reply_to: { to: ['informational', 'replyTo'], from: ['informational_reply_to'] },
  informational_attachment: {
    to: ['informational', 'attachments'],
    from: ['informational_attachment'],
  },
};

export const escalateBulkCases = (values) => {
  const data = mapObject(values, Object.values(BULK_ESCALATE_CASE_MAPPINGS));
  data.siteName = values?.caseDetails?.[0]?.siteName;
  const { headers, body } = getFormDataAndHeaderForEscalation(data);

  return request.post(URLS.BULK_ESCALATE_CASE, body, {
    baseURL: RTMC_BASE_URL,
    headers,
  });
};

export const editEventNote = ({ inputData, originalData }) => {
  const data = {
    inputData: {
      note: inputData.note,
      category: originalData.category,
      scope: originalData.scope,
      status: originalData.status,
    },
    originalData,
  };

  return request.patch(`${URLS.EVENT_NOTES}/${inputData?.noteId}`, data, {
    baseURL: RTMC_BASE_URL,
  });
};

export const deleteEventNote = (values) => {
  return request.deleteItem(`${URLS.EVENT_NOTES}/${values.id}`, {
    baseURL: RTMC_BASE_URL,
  });
};

export const getActiveSIsForSiteAndAssets = (body) => {
  return request.post(URLS.ACTIVE_SI, body, {
    baseURL: CMN_BASE_URL,
  });
};

export const getActiveSIsForAllSites = () => {
  return request.get(URLS.ALL_SITE_ACTIVE_SI, {
    baseURL: CMN_BASE_URL,
  });
};

export const getActiveSIsForAssets = (assetIds) => {
  return request.post(
    URLS.ASSETS_ACTIVE_SI,
    {
      assetIds,
    },
    {
      baseURL: CMN_BASE_URL,
    },
  );
};
