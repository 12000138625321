import { CaseSourceMapping, PanelColumnWidths } from '@ge/models/constants';

export const NAMESPACE = 'analyze.configure';

export const Fields = {
  CASE_TEMPLATE_ID: 'caseTemplateId',
  CASE_TITLE: 'title',
  CASE_DESCRIPTION: 'description',
  CASE_PRIORITY: 'priority',
  CASE_SOURCE: 'source',
  IS_BETA: 'isBeta',
  COMPONENT_L1: 'componentL1',
  COMPONENT_L2: 'componentL2',
  COMPONENT_L3: 'componentL3',
  COMPONENT_TYPE_IDS: 'componentTypeIds',
  ROOT_CAUSES: 'rootCauses',
  ROOT_CAUSES_NEW: 'rootCausesNew',
  ANALYSIS_TEMPLATE_ID: 'analysisTemplateId',
  ANALYTICS_SOURCE: 'analyticSource',
  TASK_AUTO_CREATION: 'taskAutoCreation',
  TASK_SOURCE: 'taskSource',
};

export const TaskFields = {
  TITLE: 'taskTitle',
  DESCRIPTION: 'taskDescription',
  WORK_SCOPE: 'taskWorkScope',
  PRIORITY: 'taskPriority',
  SOURCE: 'taskSource',
  DUE_DATE_OFFSET: 'taskCloseDateOffset',
};

export const SourceMapping = {
  [CaseSourceMapping.CMS]: 'CMS',
  [CaseSourceMapping.MANUAL]: 'Manual',
  [CaseSourceMapping.SCADA]: 'SCADA',
  [CaseSourceMapping.FINGERPRINTS]: 'FINGERPRINTS',
};

export const RootCauseOption = {
  ADD_NEW: 'Add New',
  OTHER: 'Other',
};

export const RootCauseOptions = [
  {
    label: RootCauseOption.OTHER,
    value: RootCauseOption.OTHER,
  },
  {
    label: RootCauseOption.ADD_NEW,
    value: RootCauseOption.ADD_NEW,
  },
];

export const AnalysisTemplateOption = {
  DEFAULT: 'default',
};

export const TaskColumns = {
  GROUP_ID: 'group-id',
  ID: 'id',

  GROUP_TITLE: 'group-title',
  TITLE: 'title',

  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'description',

  GROUP_WORK_SCOPE: 'group-taskWorkScope',
  WORK_SCOPE: 'taskWorkScope',
  WORK_SCOPE_VIEW: 'workScope',

  GROUP_PRIORITY: 'group-priority',
  PRIORITY: 'priority',

  GROUP_DUE_DATE: 'group-dueDate',
  DUE_DATE: 'dueDate',

  GROUP_DUE_DATE_OFFSET: 'group-dueDateOffset',
  DUE_DATE_OFFSET: 'dueDateOffset',

  GROUP_EDIT: 'group-edit',
  EDIT: 'edit',

  GROUP_DELETE: 'group-delete',
  DELETE: 'delete',

  GROUP_TASK_DETAIL: 'group-task-detail',
  TASK_DETAIL: 'taskDetail',
};

export const DefaultTaskColumns = [
  {
    id: TaskColumns.GROUP_PRIORITY,
    cols: [
      {
        id: TaskColumns.PRIORITY,
        visible: true,
      },
    ],
  },
  {
    id: TaskColumns.GROUP_TITLE,
    cols: [
      {
        id: TaskColumns.TITLE,
        visible: true,
      },
    ],
  },
  {
    id: TaskColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: TaskColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: TaskColumns.GROUP_DUE_DATE_OFFSET,
    cols: [
      {
        id: TaskColumns.DUE_DATE_OFFSET,
        visible: true,
      },
    ],
  },
  {
    id: TaskColumns.GROUP_EDIT,
    cols: [
      {
        id: TaskColumns.EDIT,
        visible: true,
      },
    ],
  },
  {
    id: TaskColumns.GROUP_DELETE,
    cols: [
      {
        id: TaskColumns.DELETE,
        visible: true,
      },
    ],
  },
  {
    id: TaskColumns.GROUP_TASK_DETAIL,
    cols: [
      {
        id: TaskColumns.TASK_DETAIL,
        visible: true,
      },
    ],
  },
];

export const TaskColumnDefs = {
  align: 'left',
  [TaskColumns.GROUP_PRIORITY]: {
    cols: {
      [TaskColumns.PRIORITY]: {
        align: 'left',
        labels: [
          [
            {
              a11yKey: 'asset_case_table.priority',
              a11yDefault: 'Priority',
              sortValue: TaskColumns.PRIORITY,
            },
          ],
        ],
        maxWidth: PanelColumnWidths.LARGE,
      },
    },
  },
  [TaskColumns.GROUP_TITLE]: {
    align: 'left',
    cols: {
      [TaskColumns.TITLE]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'asset_case_table.title',
            a11yDefault: 'Task Title',
            sortValue: TaskColumns.TITLE,
          },
        ],
        maxWidth: PanelColumnWidths.XLARGE,
      },
    },
  },
  [TaskColumns.GROUP_DESCRIPTION]: {
    cols: {
      [TaskColumns.DESCRIPTION]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'asset_case_table.description',
            a11yDefault: 'Task Description',
            sortValue: TaskColumns.DESCRIPTION,
          },
        ],
      },
    },
  },
  [TaskColumns.GROUP_DUE_DATE_OFFSET]: {
    align: 'left',
    cols: {
      [TaskColumns.DUE_DATE_OFFSET]: {
        align: 'right',
        labels: [
          {
            a11yKey: 'asset_case_table.dueDateOffset',
            a11yDefault: 'Due Date Offset',
            sortValue: TaskColumns.DUE_DATE_OFFSET,
          },
        ],
        maxWidth: PanelColumnWidths.LARGE,
      },
    },
  },
  [TaskColumns.GROUP_EDIT]: {
    align: 'right',
    cols: {
      [TaskColumns.EDIT]: {
        align: 'right',
        labels: [
          {
            a11yKey: 'asset_case_table.edit',
            a11yDefault: 'Edit',
            sortValue: TaskColumns.EDIT,
          },
        ],
        maxWidth: PanelColumnWidths.MEDIUM,
      },
    },
  },
  [TaskColumns.GROUP_DELETE]: {
    align: 'right',
    cols: {
      [TaskColumns.DELETE]: {
        align: 'right',
        labels: [
          {
            a11yKey: 'asset_case_table.delete',
            a11yDefault: 'Delete',
            sortValue: TaskColumns.DELETE,
          },
        ],
        maxWidth: PanelColumnWidths.MEDIUM,
      },
    },
  },
  [TaskColumns.GROUP_TASK_DETAIL]: {
    align: 'right',
    cols: {
      [TaskColumns.TASK_DETAIL]: {
        align: 'right',
        labels: [
          {
            a11yKey: 'asset_case_table.taskDetail',
            a11yDefault: 'Details',
            sortValue: TaskColumns.TASK_DETAIL,
          },
        ],
        maxWidth: PanelColumnWidths.MEDIUM,
      },
    },
  },
};
