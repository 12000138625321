import { useState, useCallback } from 'react';

import { useChangeCasesStatus, useChangeBulkCasesStatus } from '@ge/shared/data-hooks';

export const useCloseCaseActionDialog = () => {
  const [closeCaseDialog, setCloseCaseDialog] = useState(false);
  const [isCloseCaseStateChanged, setIsCloseCaseStateChanged] = useState(false);
  const [isBulkCloseCaseStateChanged, setIsBulkCloseCaseStateChanged] = useState(false);

  const {
    changeCaseStatus: changeCloseCaseStatus,
    data: updatedCloseData,
    isError: isCloseError,
    error: closeError,
  } = useChangeCasesStatus();

  const {
    changeBulkCaseStatus: changeBulkCloseCaseStatus,
    data: updatedBulkCloseData,
    isError: isBulkCloseError,
    error: bulkCloseError,
  } = useChangeBulkCasesStatus();

  const handleCloseCaseStatus = useCallback(
    (data) => {
      if (data && data?.caseId.length > 1) {
        changeBulkCloseCaseStatus({ state: data.state, caseIds: data.caseId });
        setIsBulkCloseCaseStateChanged(true);
      } else {
        changeCloseCaseStatus({ state: data.state, id: data.caseId });
        setIsCloseCaseStateChanged(true);
      }
      setCloseCaseDialog(false);
    },
    [changeBulkCloseCaseStatus, changeCloseCaseStatus],
  );

  return {
    changeCloseCaseStatus,
    changeBulkCloseCaseStatus,
    handleCloseCaseStatus,
    closeCaseDialog,
    setCloseCaseDialog,
    isCloseCaseStateChanged,
    isBulkCloseCaseStateChanged,
    setIsCloseCaseStateChanged,
    setIsBulkCloseCaseStateChanged,
    updatedCloseData,
    updatedBulkCloseData,
    isCloseError,
    isBulkCloseError,
    closeError,
    bulkCloseError,
  };
};
