import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Dialog } from '@ge/components/modal';
import { TaskColumns } from '@ge/feat-analyze/models/configure-case-template';
import { EntityType, PageSource, TaskTemplateModes, TaskDueDateOffset } from '@ge/models/constants';
import { TaskTemplate } from '@ge/shared/components/tasks/templates/task-template';
import { useTaskSource, useTaskWorkScope } from '@ge/shared/data-hooks';
import { typography } from '@ge/tokens';

const NewTaskForm = styled.form`
  position: relative;
  width: 567px;
  min-height: 600px;
  padding: 20px 25px;
`;

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  span {
    font-weight: ${typography.weight.bold};
    margin-top: 1px;
    text-transform: uppercase;
  }
`;

const CaseTemplateTaskDialog = ({
  isOpen,
  task,
  entity,
  entityType,
  onClose,
  onConfirm,
  templateMode,
}) => {
  const { t, ready } = useTranslation(['tasks', 'general'], { useSuspense: false });

  const [closeTask, setCloseTask] = useState(CheckedState.UNCHECKED);

  const { isLoading: isTaskSourcesLoading, data: taskSources } = useTaskSource({
    entityType: EntityType.ASSET,
    page: PageSource.MONITOR,
  });

  const { isLoading: isTaskWorkScopeLoading, data: taskWorkScopes } = useTaskWorkScope({
    entityType: EntityType.ASSET,
  });

  const isLoading = isTaskSourcesLoading || isTaskWorkScopeLoading;

  const methods = useForm({ mode: 'onChange' });
  const { register, setValue } = methods;

  register(TaskColumns.DUE_DATE_OFFSET);

  useEffect(() => {
    const value = task?.priority ? TaskDueDateOffset[String(task.priority).toUpperCase()] : null;
    setValue(TaskColumns.DUE_DATE_OFFSET, value);
  }, [task, setValue]);

  const toggleCloseTask = useCallback(() => {
    setCloseTask(
      closeTask === CheckedState.CHECKED ? CheckedState.UNCHECKED : CheckedState.CHECKED,
    );
  }, [closeTask, setCloseTask]);

  const handleSave = useCallback(
    (values) => {
      onConfirm({ ...values, id: task.id ? task.id : new Date().getTime() });
    },
    [task, onConfirm],
  );

  if (!ready) return null;

  return (
    <FormProvider {...methods}>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        header={
          templateMode === TaskTemplateModes.CREATE
            ? t('form.create_field_task', 'Create Field Task')
            : t('form.field_task', 'Field Task')
        }
        footer={
          templateMode === TaskTemplateModes.CREATE && (
            <NewTaskFooterWrapper>
              <StyledCheckbox
                name={'closeTask'}
                label={t('form.close_task', 'Close Task')}
                checkState={closeTask}
                onChange={toggleCloseTask}
              />
              <FooterButtons>
                <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
                {closeTask === CheckedState.CHECKED ? (
                  <Button primary onClick={methods.handleSubmit(handleSave)}>
                    {t('form.next_close_task', 'Next: Close Task')}
                  </Button>
                ) : (
                  <Button primary onClick={methods.handleSubmit(handleSave)}>
                    {t('general:save', 'Save')}
                  </Button>
                )}
              </FooterButtons>
            </NewTaskFooterWrapper>
          )
        }
        contentWidth
        padContent={false}
      >
        <NewTaskForm>
          <TaskTemplate
            control={methods.control}
            templateMode={templateMode}
            task={task}
            taskSources={taskSources}
            taskWorkScopes={taskWorkScopes}
            entity={entity}
            entType={entityType}
            isLoading={isLoading}
          />
        </NewTaskForm>
      </Dialog>
    </FormProvider>
  );
};

CaseTemplateTaskDialog.propTypes = {
  isOpen: PropTypes.bool,
  task: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  templateMode: PropTypes.string,
};

CaseTemplateTaskDialog.defaultValues = {
  isOpen: false,
  task: {},
  entity: {},
  entityType: '',
  templateMode: TaskTemplateModes.CREATE,
};

export default CaseTemplateTaskDialog;
