import { ChartType } from '@ge/components/charts/models/chart-type';
import { SignalCategoryId } from '@ge/models/constants';

export const NAMESPACE = 'analyze.configure';

export const Fields = {
  ANALYSIS_TEMPLATE_ID: 'analysisTemplateId',
  ASSET_TYPE_ID: 'assetTypeId',
  SIGNAL_MAPS: 'signalMaps',
  SIGNAL_SOURCE: 'signalSource',
  IS_DEFAULT_TEMPLATE: 'isDefaultTemplate',
  PLOTS: 'plots',
};

export const PlotFields = {
  PLOT_ID: 'plotId',
  PLOT_TYPE: 'plotType',
  SIGNALS_Y: 'signals_y',
  SIGNAL_X: 'signal_x',
};

export const SignalFields = {
  ID: 'signalId',
  AGGREGATION: 'signalAggregation',
  SOURCE: 'signalSource',
  AXIS_Y: 'yAxis',
};

export const ChartTypeOption = {
  LINE: ChartType.LINE,
  SCATTER: ChartType.SCATTER,
};

export const AssetType = {
  TURBINE: 'Wind_Turbine',
  // INVERTER: 'Inverter',
};

export const AssetTypeDefs = {
  [AssetType.TURBINE]: {
    a11yKey: 'asset_type_turbine',
    a11yDefault: 'Turbine',
  },
  [AssetType.INVERTER]: {
    a11yKey: 'asset_type_inverter',
    a11yDefault: 'Inverter',
  },
};

export const SignalSourceMapDefs = {
  [SignalCategoryId.SCADA]: {
    a11yKey: 'signal_source_map_scada',
    a11yDefault: 'Scada',
  },
  [SignalCategoryId.CMS]: {
    a11yKey: 'signal_source_map_cms',
    a11yDefault: 'CMS',
  },
};

export const ChartTypeOptionDefs = {
  [ChartTypeOption.LINE]: {
    a11yKey: 'chart_type_line',
    a11yDefault: 'Line',
  },
  [ChartTypeOption.SCATTER]: {
    a11yKey: 'chart_type_scatter',
    a11yDefault: 'Scatter',
  },
};
