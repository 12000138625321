import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeFormats, TaskRecurrence, TaskRecurrenceEnds } from '@ge/models/constants';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '../../../task-template-shared';

export const ViewRecurrence = ({ task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { recurrence } = task;
  const displayRecurrence = () => {
    const recurrenceDisplay = !recurrence?.recurrence
      ? t('dynamic.recurrence.none')
      : t(`dynamic.recurrence.${recurrence?.recurrence}`, recurrence?.recurrence);
    if (recurrence?.recurrence === TaskRecurrence.NONE || !recurrence?.recurrence) {
      return recurrenceDisplay;
    } else {
      return recurrence?.recurrenceType === TaskRecurrenceEnds.END_BY_DATE
        ? `${recurrenceDisplay}: ${t('form.ends', 'Ends')} ${t('form.on', 'On')} ${dayjs(
            recurrence?.recurrenceEndByDate,
          ).format(DateTimeFormats.DEFAULT_DATE)}`
        : `${recurrenceDisplay}: ${t('form.ends', 'Ends')} ${t('form.after', 'After')} ${
            recurrence?.recurrenceEndByCount
          } ${t('form.occurences', 'Occurrences')}`;
    }
  };

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.recurrence', 'Recurrence')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.recurrence', 'Recurrence')}</DisplayLabel>
            <DisplayField>{displayRecurrence()}</DisplayField>
          </div>
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

ViewRecurrence.propTypes = {
  task: PropTypes.object.isRequired,
};
