import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';
// import { MapView } from '@ge/components/map-view';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import useInterval from '@ge/hooks/polling';
import { MonitorDefs, PresentationTypes } from '@ge/models/constants';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import AssetsTable from '@ge/shared/components/tables/assets-table';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { AppScopes } from '@ge/shared/models/scopes';
import { AssetsColumnDefs } from '@ge/shared/models/table-col-defs';
import { killEventPropagation } from '@ge/shared/util/general';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import SocketListener from '@ge/web-client/src/app/components/websocket/socket-listener';

import { defaultAssetsCols } from '../models/asset-table-col-defaults';

const SORT_STATE_ID = 'monitor-assets';
const ASSETS = MonitorDefs.ASSETS;

/**
 * Available sort metrics for Assets. This will grow and likely move.
 */
const AssetMetric = {
  NAME: 'name',
  STATE: 'metrics.state',
};

/**
 * Set list of view tyoes for Presentation Toggle
 */
const PresentationViews = [PresentationTypes.LIST];

const MonitorAssets = (props) => {
  const { t, ready } = useTranslation(['monitor.assets'], {
    useSuspense: false,
  });

  const { showAssetDetails } = useContext(EntityDetailsContext);

  const {
    location: { search },
  } = props;

  const { fetchAssetsWithMetrics } = useStoreActions((store) => store.assets);
  const getSortedAssets = useStoreState((store) => store.assets.getSortedAssets);
  const { currentView } = useStoreState((state) => state.view);

  const [isActive, setActiveState] = useState(PresentationTypes.LIST);

  const pollingInterval = useInterval(30000);

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.MONITOR_ASSETS);

  // Table state management
  const {
    updateColumnVisibility,
    setVisibleColumns,
    visibleCols,
    sortDirection,
    sortMetric,
    updateSortMetric,
  } = useColumnState({
    columnDefs: AssetsColumnDefs,
    defaultCols: defaultAssetsCols,
    defaultSortMetric: AssetMetric.STATE,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: SORT_STATE_ID,
  });

  const { onVisibilityChange: handleColumnChanges } = useManageColumnVisibility({
    subKey: ASSETS,
    featPrefs,
    savePrefs,
    setVisibleColumns,
    updateColumnVisibility,
  });

  // Trigger fetching the relevant data
  useEffect(() => {
    fetchAssetsWithMetrics({ sortDirection, sortMetric });
  }, [currentView, fetchAssetsWithMetrics, pollingInterval, sortDirection, sortMetric]);

  const assets = getSortedAssets(sortMetric, sortDirection, null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');

    if (view) {
      setActiveState(view);
    } else {
      setActiveState(PresentationTypes.LIST);
    }
  }, [isActive, search]);

  const handleAssetSelect = useCallback(
    (e, asset) => {
      killEventPropagation(e);
      showAssetDetails(asset.id);
    },
    [showAssetDetails],
  );

  if (!assets) {
    return null;
  }

  return (
    <PageContainer i18nReady={ready}>
      <SocketListener />
      <h1 className="pageTitle">{ready && t('assets', 'Assets')}</h1>
      <SubNavigation>
        <ColumnSelectMenu
          translateFn={t}
          columnDef={AssetsColumnDefs}
          columnState={visibleCols}
          onMenuClose={handleColumnChanges}
        />
        <PresentationToggle viewList={PresentationViews} />
      </SubNavigation>

      {isActive === PresentationTypes.LIST && (
        <DetailsDismissInert>
          <AssetsTable
            assets={assets}
            columns={visibleCols}
            sortAction={updateSortMetric}
            sortMetric={sortMetric}
            sortDirection={sortDirection}
            onAssetSelect={handleAssetSelect}
            onDrop={(columnValue) => savePrefs(columnValue, ASSETS)}
            draggable
          />
        </DetailsDismissInert>
      )}
      {/* {isActive === PresentationTypes.MAP && <MapView />} */}
    </PageContainer>
  );
};

MonitorAssets.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

MonitorAssets.defaultProps = {
  location: null,
};

export default withRouter(MonitorAssets);
