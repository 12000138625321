import { selectBy } from '@ge/util';

import { chartOptionsUpdateFactory } from './chart-options-factory';

const clearSeries = (chart) => {
  while (chart.series.length) {
    chart.series[0].remove();
  }
};

const clearSelectedPoints = (chart) => {
  const selectedPoints = chart.getSelectedPoints();
  // clear current selection if accumulate false
  selectedPoints.forEach((point) => point.select(false));
};

// pass chart into callback if chart exists
const withChart = (ref, callback) => {
  const { chart } = (ref && ref.current) || {};

  return chart && callback(chart);
};

// this is the api we expose to the app for interacting with charts
export const chartApi = (ref, { themeRef }) => {
  if (!ref) {
    // could pass back a noop api here
    return {};
  }

  return {
    clearSeries: () => withChart(ref, (chart) => clearSeries(chart)),
    clearSelectedPoints: () => withChart(ref, (chart) => clearSelectedPoints(chart)),
    // add support for selecting across multiple series?
    selectBy: ({ predicate, seriesIndex = null }) =>
      withChart(ref, (chart) => selectBy({ chart, predicate, seriesIndex })),
    selectByX: ({ seriesIndex = 0, x = [] }) => {
      const predicate = x.length ? ({ x: pointX }) => x.includes(pointX) : undefined;
      return withChart(ref, (chart) => selectBy({ chart, predicate, seriesIndex }));
    },
    selectPointsByTimeStamp: ({ points = [], assetId, isBoosting }) => {
      if (!assetId) {
        return [];
      }

      const tsPointMap = new Map();
      points.forEach((point) => tsPointMap.set(point.ts, point));

      const predicate = points.length
        ? ({ ts, clusteredData }) => {
            if (clusteredData) {
              return clusteredData.some((pointData) => tsPointMap.has(pointData.options.ts));
            }
            return tsPointMap.has(ts);
          }
        : undefined;
      return withChart(ref, (chart) =>
        selectBy({ chart, assetId, skipPointSelection: isBoosting, predicate }),
      );
    },
    setData: ({ data = [], seriesIndex = 0 }) =>
      withChart(ref, (chart) => {
        const { series: _series = [] } = chart;
        const series = _series[seriesIndex];

        if (series) {
          // clear existing data
          // if we don't do this, it causes the points to sit on top of each other (but not like when stacking correctly)
          // TODO: look into how we can update data without clearing first because animations are nicer and
          // potential performance gains
          series.setData([]);

          series.setData(data);
        }
      }),
    // updates just the data, useful when most chart options don't change during an update
    updateSeries: ({ colors, series = [], type }) => {
      const { current: theme } = themeRef;

      // grab all the options related to series and type
      const options = chartOptionsUpdateFactory({ colors, series, theme, type });

      withChart(ref, (chart) => chart.update(options, true, true));
    },
    setZoom: ({ min, max, options = {} }) =>
      withChart(ref, (chart) => {
        if (options.id === chart.xAxis[0].options.id) {
          min && max && chart.xAxis[0].setExtremes(min, max);
        } else {
          chart.zoomOut();
        }
      }),
    resetZoom: () => withChart(ref, (chart) => chart.zoomOut()),
  };
};
