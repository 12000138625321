import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { getSiteContact, setSiteContact } from '../services/site-contact';

export const useContactInfo = (siteId) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.CONTACT_INFO, siteId],
    () => getSiteContact(siteId),
    Config.EXECUTE_ONCE,
  );

  const response = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data.map((element) => ({
      siteId: element?.siteIds,
      description: element?.contact?.['desc'],
    }));
  }, [data]);

  return {
    isLoading,
    data: response,
    error,
  };
};

export const useUpdateContactInfo = (siteId) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateContactInfo, data, isLoading, isError, error } = useMutation(
    (input_data) => setSiteContact({ ...input_data, siteId }),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKey.CONTACT_INFO, siteId]),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return useMemo(() => ({ updateContactInfo, data, isLoading, isError, error }), [
    updateContactInfo,
    data,
    error,
    isError,
    isLoading,
  ]);
};
