import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ribbonStatePropType } from '@ge/feat-monitor/models';

import FilterBar from './ribbon-filter';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PageHeader = ({ title, ribbonState }) => {
  return (
    <Container>
      <h1 className="pageTitle">{title}</h1>
      <FilterBar {...ribbonState} />
    </Container>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  ribbonState: PropTypes.shape(ribbonStatePropType),
};

PageHeader.defaultProps = {
  title: null,
  ribbonState: {},
};

export default PageHeader;
