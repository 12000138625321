import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { WidgetTypes } from '@ge/feat-reporting/models/widgets';

import { BarModule } from '../bar-module';
import { DonutModule } from '../donut-module';
import { LineModule } from '../line-module';
import { ListModule } from '../list-module';
import { NotesModule } from '../notes-module';
import { TableModule } from '../table-module/table-module';

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  background: ${({ theme }) => theme.createReport.sidebar.moduleBackgroundColor};
  border-bottom: none;
  margin-bottom: 5px;

  .title {
    border-bottom: none;
    padding: 8px 14px;
    align-items: center;
    display: flex;

    &.expanded {
      border-bottom: 1px solid ${({ theme }) => theme.createReport.sidebar.separationColor};
    }

    & > svg {
      fill: ${({ theme }) => theme.createReport.sidebar.moduleChevronColor};
    }
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const ModuleContainer = styled.div`
  padding: 10px 15px;
`;

const DynamicIcon = styled(Icon).attrs(({ theme, icon, hidden }) => ({
  size: 12,
  icon,
  color: hidden
    ? theme.createReport.sidebar.moduleIconColorDisabled
    : theme.createReport.sidebar.moduleIconColorActive,
}))`
  display: inline-block;
  position: relative;
  margin-right: 8px;
  margin-left: 6px;
`;

const VisibilityIcon = styled(Icon).attrs(({ theme, hidden }) => ({
  size: 18,
  icon: hidden ? Icons.VISIBILITY_OFF : Icons.VISIBILITY_ON,
  color: hidden
    ? theme.createReport.sidebar.moduleIconColorNotVisible
    : theme.createReport.sidebar.moduleIconColorVisible,
}))``;

// NOTE: Using 'visible' here because 'hidden' appears to actually hide the element contents.
const ModuleNameHeader = styled.h4`
  color: ${({ theme, visible }) =>
    visible
      ? theme.createReport.sidebar.moduleHeaderColor
      : theme.createReport.sidebar.moduleHeaderColorNotVisible};
`;

const ModuleHeader = ({ children, handleVisibilityChange, hidden, icon }) => {
  return (
    <StyledTitle>
      <TitleWrapper>
        <DynamicIcon icon={icon} hidden={hidden} />
        <ModuleNameHeader visible={!hidden}>{children}</ModuleNameHeader>
      </TitleWrapper>
      <button type="button" onClick={handleVisibilityChange}>
        <VisibilityIcon hidden={hidden} />
      </button>
    </StyledTitle>
  );
};

ModuleHeader.propTypes = {
  icon: PropTypes.string,
  handleVisibilityChange: PropTypes.func,
  hidden: PropTypes.bool,
  children: PropTypes.node,
};

export const Module = ({
  id,
  name,
  configuration,
  visualizationType,
  onToggleExpand,
  expanded,
  onToggleHidden,
  hidden,
}) => {
  const handleToggle = useCallback(() => {
    onToggleExpand({ id });
  }, [onToggleExpand, id]);

  const icon = useMemo(() => {
    switch (visualizationType) {
      case WidgetTypes.DONUT:
        return Icons.PARETO;
      case WidgetTypes.TEXT:
        return Icons.NOTE;
      case WidgetTypes.TABLE:
        return Icons.TASK;
      default:
        return Icons.LIST;
    }
  }, [visualizationType]);

  const content = useMemo(() => {
    switch (visualizationType) {
      case WidgetTypes.LIST:
        return <ListModule name={name} />;
      case WidgetTypes.LINE:
        return <LineModule id={id} name={name} configuration={configuration} />;
      case WidgetTypes.DONUT:
        return <DonutModule id={id} name={name} configuration={configuration} />;
      case WidgetTypes.TEXT:
        return <NotesModule id={id} configuration={configuration} />;
      case WidgetTypes.TABLE:
        return <TableModule id={id} name={name} configuration={configuration} />;
      case WidgetTypes.BAR:
        return <BarModule id={id} name={name} configuration={configuration} />;
      default:
        return (
          // Placeholder for time being
          <div
            style={{
              height: 50,
              display: 'flex',
              alignzItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>Coming Soon</p>
          </div>
        );
    }
  }, [visualizationType, id, name, configuration]);

  const handleVisibilityChange = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onToggleHidden(e);
    },
    [onToggleHidden],
  );

  return (
    <>
      <StyledCollapsiblePanel
        headerContent={
          <ModuleHeader icon={icon} hidden={hidden} handleVisibilityChange={handleVisibilityChange}>
            {name}
          </ModuleHeader>
        }
        onToggleExpand={handleToggle}
        expanded={expanded}
      >
        <ModuleContainer>{content}</ModuleContainer>
      </StyledCollapsiblePanel>
    </>
  );
};

Module.propTypes = {
  visualizationType: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  configuration: PropTypes.object,
  onToggleExpand: PropTypes.func,
  expanded: PropTypes.bool,
  onToggleHidden: PropTypes.func,
  hidden: PropTypes.bool,
};

Module.defaultProps = {
  onToggleExpand: () => {},
  onToggleHidden: () => {},
};
