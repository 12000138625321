import { ChartType } from './chart-type';

export const roseChartOptions = {
  chart: {
    polar: true,
    type: ChartType.COLUMN,
  },
  legend: {
    align: 'right',
    enabled: true,
    itemMarginBottom: 5,
    layout: 'vertical',
    symbolHeight: 12,
    symbolRadius: 3,
    symbolWidth: 12,
    verticalAlign: 'top',
    y: 20,
  },
  pane: {
    center: ['33%'],
  },
  plotOptions: {
    series: {
      pointStart: 0,
      groupPadding: 0,
      pointPlacement: 'on',
      stacking: 'normal',
    },
  },
  xAxis: {
    tickLength: null,
    tickmarkPlacement: 'on',
    maxPadding: null,
    minPadding: null,
    scrollbar: {
      enabled: false,
    },
  },
  yAxis: {
    endOnTick: false,
    reversedStacks: false,
    visible: false,
  },
};
