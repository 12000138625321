import dayjs from 'dayjs';

import { TaskStatus, TasksTracker } from '@ge/models/constants';

const SECONDS_IN_A_DAY = 86400;

export const getFilteredTasksTrackerData = (tasks, taskTrackerData) => {
  let filteredData = [];
  switch (taskTrackerData?.id) {
    case TasksTracker.ALL_OPEN:
      filteredData = tasks?.filter((task) => task?.status !== TaskStatus.COMPLETED);
      break;
    case TasksTracker.NEW:
      filteredData = tasks?.filter(
        (task) =>
          dayjs().tz(task?.site?.timezone).diff(task?.createDate, 'second') < SECONDS_IN_A_DAY &&
          task,
      );
      break;
    case TasksTracker.OVERDUE:
      filteredData = tasks?.filter(
        (task) =>
          task?.status !== TaskStatus.COMPLETED &&
          dayjs().tz(task?.site?.timezone).diff(task?.dueDate, 'second') > 0 &&
          task,
      );
      break;
    case TasksTracker.UPCOMING_DUE:
      filteredData = tasks?.filter((task) => {
        const dueDateDifference = dayjs().tz(task?.site?.timezone).diff(task?.dueDate, 'second');
        return (
          task?.status !== TaskStatus.COMPLETED &&
          dueDateDifference < 0 &&
          dueDateDifference > -SECONDS_IN_A_DAY * 7 &&
          task
        );
      });
      break;
    case TasksTracker.RECENTLY_CLOSED:
      filteredData = tasks?.filter(
        (task) =>
          task?.status === TaskStatus.COMPLETED &&
          dayjs().tz(task?.site?.timezone).diff(task?.completedDateTime, 'second') <
            SECONDS_IN_A_DAY &&
          task,
      );
      break;
    case TasksTracker.ALL_COMPLETED:
      filteredData = tasks?.filter((task) => task?.status === TaskStatus.COMPLETED);
      break;
    default:
      return tasks;
  }
  return filteredData;
};

export const getSelectedTaskTrackerData = (tasksTrackerData) =>
  tasksTrackerData?.find((taskTracker) => taskTracker?.selected && taskTracker?.visible);
