import { useStoreState } from 'easy-peasy';
import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ChartBorder } from '@ge/components/charts';
import { ExportGraphButton, ExportGraphModal } from '@ge/feat-analyze/components/modals';
import { AssetContext } from '@ge/feat-analyze/context/assetContext';
import useAssetIecData from '@ge/feat-analyze/data-hooks/use-asset-iec-data';
import { useExportGraph } from '@ge/feat-analyze/data-hooks/use-export-graph';
import { DashboardConditionType } from '@ge/feat-analyze/models/constants';
import {
  Capability,
  EntityType,
  KpiCategoryDefs,
  AssetKpiHeaderCategories,
} from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { AuthRender } from '@ge/shared/components/auth-render';

import { AssetEventsProvider } from '../../../context';
import { useAssetDetail } from '../../../data-hooks/use-asset-detail';
import useAssetKpiData from '../../../data-hooks/use-asset-kpi-data';
import useConditionData from '../../../data-hooks/use-condition-data';
import { AssetKpiChartDefs, ChartNames } from '../../../models';
import { getKpiCategories, getKpiChartDef, isTbaOneHundred } from '../../../util';
import MajorLossChart from '../../asset/major-loss-chart';
import { MemoizedDashboardConditionsChart } from '../../dashboard/dashboard-conditions-chart';
import { DashboardKpiChart } from '../../dashboard/dashboard-kpi-chart';
import { DashboardPowerCurveChart } from '../../dashboard/dashboard-power-curve-chart';

import { IssuesSidebar } from './issues-sidebar';

const NAMESPACE = 'analyze.dashboard';

const AssetDetailsContainer = styled.div`
  display: flex;
  margin-right: 20px;
`;

const StyledHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const ChartsContainer = styled.div`
  flex: 1;
`;

const HeaderContainer = styled(ChartBorder)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  min-height: 368px;
`;

const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-width: 600px;
  padding: 10px 0 0;
  min-height: 400px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 20px;

  > div {
    min-height: 385px;
  }
`;

const ContentRight = styled.div`
  flex: 1 1 50%;
  margin-left: 10px;
  min-width: 0;
`;

const ContentLeft = styled.div`
  flex: 1 1 50%;
  min-width: 0;
`;

const StyledGraphButton = styled(ExportGraphButton)`
  margin-left: auto;
`;

const AssetDetails = () => {
  // Hooks + Data
  const { t, ready } = useTranslation(['analyze.dashboard', 'analyze.asset'], {
    useSuspense: false,
  });
  const { assetState, queryParam } = useContext(AssetContext);
  const { setIecData } = assetState;

  const entity = useAssetDetail({ assetId: assetState.selectedAssetId });
  const { site } = entity;

  const getDateRange = useStoreState(({ analyze }) => analyze.getDateRange);
  const dateRange = getDateRange(site?.timezone);

  const [kpiChartState, setKpiChartState] = useState({
    categories: [KpiCategoryDefs.EVENT_DURATION],
    def: { ...AssetKpiChartDefs.performance.actual_production },
  });

  const {
    def: { graph4 },
  } = kpiChartState;

  const iecCategoryOptions = [
    {
      value: KpiCategoryDefs.PRODUCTION_ACTUAL,
      label: t('actual_production', 'Actual Production'),
    },
    {
      value: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
      label: t('unproduced_energy_contract', 'Unproduced Energy'),
    },
    // TODO: Asset IEC KPI Implementation TBD
    // {
    //   value: KpiCategoryDefs.UNPRODUCED_ENERGY_LEARNED,
    //   label: t('unproduced_energy_learned', 'Unproduced Energy (Learned)'),
    // },
    {
      value: KpiCategoryDefs.EVENT_DURATION,
      label: t('event_duration', 'Event Duration'),
    },
    {
      value: KpiCategoryDefs.EVENT_OCCURRENCE,
      label: t('event_occurrence', 'Event Occurrence'),
    },
    {
      value: KpiCategoryDefs.EVENT_RATES,
      label: t('event_rates', 'Event Rates'),
    },
    {
      value: KpiCategoryDefs.EVENT_UNAVAILABLE_TIME,
      label: t('event_unavailable_time', 'Unavailable Time'),
    },
  ];

  const [iecCategory, setIecCategory] = useState(
    iecCategoryOptions.find(({ value }) => value === KpiCategoryDefs.EVENT_UNAVAILABLE_TIME),
  );
  const [windSpeedAvg, setWindSpeedAvg] = useState();

  const {
    data: assetKpiData,
    isLoading: isKpiDataLoading,
    error: assetKpiError,
  } = useAssetKpiData({
    categories: [
      ...AssetKpiHeaderCategories,
      KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
      KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT,
    ],
    assetId: assetState.selectedAssetId,
    startDate: dateRange.startDate.entityTimezone,
    endDate: dateRange.endDate.entityTimezone,
  });

  // fetch asset condition aggregations
  const {
    data: assetConditionData,
    isLoading: isLoadingAssetConditionData,
    error: assetConditionError,
    setSelectedConditionType,
  } = useConditionData({
    entityType: EntityType.ASSET,
    entityId: assetState.selectedAssetId,
    startDate: dateRange.startDate.utc,
    endDate: dateRange.endDate.utc,
    defaultConditionType: [DashboardConditionType.WIND_SPEED],
  });

  const {
    data: iecCategoryData,
    isLoading: isLoadingIecCategoryData,
    error: siteIecCategoryError,
  } = useAssetIecData({
    assetId: assetState.selectedAssetId,
    category: iecCategory.value,
    startDate: dateRange.startDate.entityTimezone,
    endDate: dateRange.endDate.entityTimezone,
  });

  const {
    enabled: enabledExport,
    openModal,
    modalProps,
    setConditionCategory,
    setChartRefs,
  } = useExportGraph({
    isLoading: isKpiDataLoading || isLoadingIecCategoryData || isLoadingAssetConditionData,
    kpiChartState,
    currentIecCategory: iecCategory?.value,
    entityType: EntityType.ASSET,
    entity,
    dateRange,
  });

  const handleCategoryChange = useCallback(
    (filterBar) => {
      const def = getKpiChartDef(AssetKpiChartDefs, filterBar);
      // get list of distinct categories to fetch
      const categories = getKpiCategories(def);
      setKpiChartState({ categories, def, ...filterBar });
    },
    [setKpiChartState],
  );

  const handleConditionTypeChange = useCallback(
    (conditionType) => setSelectedConditionType([conditionType]),
    [setSelectedConditionType],
  );

  // Handlers + Effects

  useEffect(() => {
    setIecData(iecCategoryData);
  }, [setIecData, iecCategoryData]);

  useEffect(() => {
    if (assetConditionData && assetConditionData.windSpeedAverage) {
      setWindSpeedAvg(assetConditionData.windSpeedAverage);
    }
  }, [assetConditionData, setWindSpeedAvg]);

  const noDataLabel = useMemo(
    () => (ready ? t('select_asset', 'Select an asset') : null),
    [ready, t],
  );

  // Render
  if (!ready) {
    return null;
  }

  const selectAssetLabel = !queryParam.assets?.length ? noDataLabel : null;

  return (
    <AssetDetailsContainer>
      <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteLevel={false} view>
        <IssuesSidebar />
      </AuthRender>
      <AuthRender capability={Capability.CORE_KPIS} siteLevel={false} view>
        <ChartsContainer>
          <StyledHeader data-testid={AnalyzeLocators.ANALYZE_ASSET_DATE_FILTER_WRAP}>
            {enabledExport && <StyledGraphButton onClick={openModal} />}
          </StyledHeader>
          <HeaderContainer>
            <ContentLeft data-testid={AnalyzeLocators.ANALYZE_ASSET_DONUT_CHART_WRAP}>
              <MajorLossChart
                data={assetState.data}
                error={siteIecCategoryError}
                isLoading={isLoadingIecCategoryData}
                selectOptions={iecCategoryOptions}
                changeCategory={setIecCategory}
                currentCategory={iecCategory}
                customError={selectAssetLabel}
                showZero={
                  isTbaOneHundred(assetKpiData) &&
                  iecCategory.value === KpiCategoryDefs.EVENT_UNAVAILABLE_TIME
                }
                makeVisible={true}
                ref={(ref) => setChartRefs(ref, ChartNames.PARETO)}
              />
            </ContentLeft>
            <ContentRight data-testid={AnalyzeLocators.ANALYZE_ASSET_POWER_CURVE_WRAP}>
              <DashboardPowerCurveChart
                noDataLabel={noDataLabel}
                assetIds={[assetState.selectedAssetId]}
                site={site}
                dateRange={dateRange}
                ref={(ref) => setChartRefs(ref, ChartNames.POWER_CURVE)}
              />
            </ContentRight>
          </HeaderContainer>
          <Content>
            <ContentLeft>
              <ContentWrapper data-testid={AnalyzeLocators.ANALYZE_ASSET_KPI_CHART_WRAP}>
                {graph4 && (
                  <DashboardKpiChart
                    chartDef={graph4}
                    data={assetKpiData}
                    error={assetKpiError}
                    noDataError={selectAssetLabel}
                    entityType={EntityType.TURBINE}
                    namespace={NAMESPACE}
                    isLoading={isKpiDataLoading}
                    selectable={false}
                    handleFilterBarChange={handleCategoryChange}
                    staticChartTitle={t('asset_trends', 'Asset Trends')}
                    ref={(ref) => setChartRefs(ref, ChartNames.TRENDS)}
                  />
                )}
              </ContentWrapper>
            </ContentLeft>
            <ContentRight>
              <ContentWrapper data-testid={AnalyzeLocators.ANALYZE_ASSET_CONDITION_CHART_WRAP}>
                <MemoizedDashboardConditionsChart
                  data={assetConditionData}
                  error={assetConditionError}
                  noDataLabel={selectAssetLabel}
                  isLoading={isLoadingAssetConditionData}
                  defaultType={DashboardConditionType.WIND_SPEED}
                  namespace={NAMESPACE}
                  height={304}
                  getCurrentCondition={setConditionCategory}
                  isSiteCondition={false}
                  ref={(ref) => setChartRefs(ref, ChartNames.CONDITION)}
                  onConditionTypeChange={handleConditionTypeChange}
                  windSpeedAvg={windSpeedAvg}
                />
              </ContentWrapper>
            </ContentRight>
          </Content>
        </ChartsContainer>
      </AuthRender>
      <ExportGraphModal {...modalProps} enablePng />
    </AssetDetailsContainer>
  );
};

// wrap in providers for export
export const AssetOverviewDetail = () => (
  <AssetEventsProvider>
    <AssetDetails />
  </AssetEventsProvider>
);
