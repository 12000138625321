import { PropTypes } from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tab, Tabs } from '@ge/components/tabs';
import { Capability, EntityTab, EntityType, PermissionScope } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth, useSiteDetails } from '@ge/shared/data-hooks';

import NotesAttachments from '../components/notes-attachments';
import { DetailContainer, SectionContainer } from '../entity-details-shared';

// Hiding for MVP0 - Story #451
// import SiteHistory from './panels/site-history';
import SitePerformance from './panels/site-performance';
import SiteRealtimeInfo from './panels/site-realtime-info';
import SiteDetailsHeader from './site-details-header';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const SiteDetails = ({ siteId, entityTab, entityType, isDatePersist, popsout }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { manageSiteTabs } = useContext(EntityDetailsContext);

  // data hooks
  const { data } = useSiteDetails({ siteId });

  const { site } = data;

  const { isAuthorized } = useAuth();

  const isAuthorizedToViewRealTimeInfo = isAuthorized({
    capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
  });

  const isAuthorizedToViewPerformance = isAuthorized({
    capabilities: [{ capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] }],
  });

  const isAuthorizedToViewNotes = isAuthorized({
    capabilities: [
      { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
    ],
  });
  let defaultTab = entityTab;
  if (!defaultTab || defaultTab === null) {
    if (isAuthorizedToViewRealTimeInfo) {
      defaultTab = EntityTab.REALTIME_INFO;
    }
    if (isAuthorizedToViewPerformance) {
      defaultTab = EntityTab.PERFORMANCE;
    }
    if (isAuthorizedToViewNotes) {
      defaultTab = EntityTab.NOTES_AND_ATTACHMENTS;
    }
  }
  const handleEntityTab = useCallback(
    (e) => {
      manageSiteTabs(site?.id, e, entityType);
    },
    [site, manageSiteTabs, entityType],
  );

  if (!(ready && site)) {
    return <Loader />;
  }

  return (
    <DetailContainer>
      <SiteDetailsHeader site={site} entityType={entityType} />
      <ScrollingContainer autoHide={false}>
        {site && (
          <SectionContainer>
            <StyledTabs small defaultTab={defaultTab} onChangeHandler={handleEntityTab}>
              <Tab
                capabilities={[
                  { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
                ]}
                label={t('tabs.realtime_info', 'Realtime Info')}
              >
                {isAuthorizedToViewRealTimeInfo ? (
                  <SiteRealtimeInfo siteId={site.id} type={entityType} />
                ) : (
                  ''
                )}
              </Tab>

              <Tab
                capabilities={[
                  { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
                ]}
                label={t('tabs.performance', 'Performance')}
              >
                {isAuthorizedToViewPerformance ? (
                  <SitePerformance
                    siteId={site.id}
                    isDatePersist={isDatePersist}
                    popsout={popsout}
                  />
                ) : (
                  ''
                )}
              </Tab>
              {/* Hiding for MVP0 - Story #451 */}
              {/* <Tab
                capabilities={[{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }]}
                label={t('tabs.history', 'History')}
              >
                <SiteHistory site={site} />
              </Tab> */}
              <Tab
                capabilities={[
                  {
                    capability: Capability.NOTES_AND_ATTACHMENTS,
                    scopes: [PermissionScope.VIEW],
                  },
                ]}
                label={t('tabs.notes', 'Notes')}
              >
                {isAuthorizedToViewNotes ? (
                  <NotesAttachments
                    entity={site}
                    entityType={EntityType.SITE}
                    siteType={entityType}
                  />
                ) : (
                  ''
                )}
              </Tab>
            </StyledTabs>
          </SectionContainer>
        )}
      </ScrollingContainer>
    </DetailContainer>
  );
};

SiteDetails.propTypes = {
  siteId: PropTypes.string.isRequired,
  entityTab: PropTypes.string,
  entityType: PropTypes.string,
  isDatePersist: PropTypes.bool,
  popsout: PropTypes.bool,
};

SiteDetails.defaultProps = {
  entityTab: null,
  isDatePersist: true,
  popsout: false,
};

export default SiteDetails;
