import log from 'loglevel';
import { useEffect, useMemo } from 'react';

import { isDevMode } from '@ge/shared/util';

export const LogLevel = log.levels;

const getLoggerApi = (logger = {}) => ({
  private: logger,
  // controlling which props we expose from logger
  // exposing the methods directly so we don't mangle stacktrace
  public: {
    debug: logger.debug,
    error: logger.error,
    info: logger.info,
    // alias for debug, adding to make it easier to replace existing console.log statements
    log: logger.log,
    trace: logger.trace,
    warn: logger.warn,
  },
});

// can override default logging behavior by using log.methodFactory

// can add support for different loggers with their own log level by calling log.getLogger
// but was having issues with the logger object ref changing and causing unwanted updates in app

// TODO: if we need to pipe logs to the backend, this is the place to put that functionality
// right now using a light-weight logging lib to pipe to console, but can swap this out with whatever logger is needed
// or make it environment-specific
export const useLogger = ({ disabled = false, logLevel = LogLevel.DEBUG } = {}) => {
  const logger = useMemo(() => getLoggerApi(log), []);

  useEffect(() => {
    // for now we suppress logging outside of development
    if (!isDevMode() || disabled) {
      logger.private.disableAll();

      return;
    }

    logger.private.setLevel(logLevel);
  }, [disabled, logger, logLevel]);

  return logger.public;
};
