import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Language, DEFAULT_LANGUAGE, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchErpTranslations } from '../services';

export const useErpTranslations = () => {
  const { i18n: { language = DEFAULT_LANGUAGE } = {} } = useTranslation();
  let { data, error, isLoading, isFetched } = useQuery(
    [QueryKey.ERP_TRANSLATIONS, language],
    () => fetchErpTranslations({ language }),
    Config.EXECUTE_ONCE,
  );

  // in case selected language is not present, fallback will be english
  let {
    data: fallbackData,
    error: fallbackError,
    isLoading: fallbackLoadingState,
  } = useQuery(
    [QueryKey.ERP_TRANSLATIONS, Language.EN],
    () => fetchErpTranslations({ language: Language.EN }),
    { ...Config.EXECUTE_ONCE, enabled: isFetched && !(Object.keys(data).length > 0) },
  );

  if (isFetched && !(Object.keys(data).length > 0)) {
    data = fallbackData;
    error = fallbackError;
    isLoading = fallbackLoadingState;
  }

  return { data, error, isLoading };
};
