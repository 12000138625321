import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { CasePriority, TaskPriority } from '@ge/models/constants';

export const getSeverityColor = ({ level, theme }) => {
  switch (level?.toLowerCase()) {
    case TaskPriority.IMMEDIATE:
    case CasePriority.IMMEDIATE:
      return theme.severity.immediateFillColor;
    case TaskPriority.HIGH:
    case CasePriority.HIGH:
      return theme.severity.highFillColor;
    case TaskPriority.MEDIUM:
    case CasePriority.MEDIUM:
      return theme.severity.mediumFillColor;
    case TaskPriority.LOW:
    case CasePriority.LOW:
      return theme.severity.lowFillColor;
    default:
      return theme.severity.defaultFillColor;
  }
};

const StyledSeverity = styled(Icon).attrs((props) => ({
  size: props.size,
  icon: Icons.SEVERITY,
  color: getSeverityColor(props),
  viewbox: props.viewBox,
}))``;

export const Severity = ({ level, className, size, viewBox }) => (
  <StyledSeverity level={level} className={className} size={size} viewBox={viewBox} />
);

Severity.propTypes = {
  level: function(props, propName, componentName) {
    if (
      !props[propName] ||
      ['low', 'medium', 'high', 'immediate'].every(
        (l) => l.toLowerCase() !== props[propName].toLowerCase(),
      )
    ) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. Validation failed.',
      );
    }
  },
  className: PropTypes.string,
  size: PropTypes.number,
  viewBox: PropTypes.string,
};

Severity.defaultProps = {
  className: '',
  size: 8,
  viewBox: undefined,
};
