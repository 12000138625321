import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { Text } from '@ge/components/typography';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { ManageDefs } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

import { PlanningContext, FilterOptions, FilterValues } from '../../context/planning-provider';

import { CalendarFilterMenu } from './calendar-filter-menu';

const FEAT_PREFS_SUB_KEY = ManageDefs.OVERVIEW;
const Container = styled.div`
  width: calc(${({ sidebarWidth }) => sidebarWidth}px - 20px);
  padding: 10px;
  position: sticky;
  left: 0;
  z-index: ${elevations.P4};
  background: ${({ theme }) => theme.manage.calendar.backgroundColor};
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    height: 100%;
    width: 2px;
    background: ${({ theme }) => theme.manage.calendar.sideBarBorderColor};
    box-shadow: 1px 0 6px rgba(0, 0, 0, 0.6);
    display: block;
    content: '';
  }
`;

const FlexContent = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)`
  margin-right: 6px;
`;

const TitleText = styled(Text)`
  margin-bottom: 3px;
  display: block;
`;

export const CalendarGroupFilter = ({ filters }) => {
  const { t, ready } = useTranslation(['manage.planning', 'general'], { useSuspense: false });
  const { featPrefs: _featPrefs } = useFeaturePrefs(AppScopes.MANAGE_OVERVIEW);
  const featPrefs = _featPrefs?.[FEAT_PREFS_SUB_KEY];
  const groupByValue = (featPrefs && featPrefs[1]?.cols) || FilterValues.TASK;
  const groupBySecondValue = (featPrefs && featPrefs[2]?.cols) || null;
  const {
    planningState: { sidebarWidth, groupFilter, handleSetGroupFilter },
  } = useContext(PlanningContext);
  if (ready) {
    setTimeout(() => {
      Object.entries(FilterOptions).forEach(([key, value]) => {
        FilterOptions[key] = value.map((filter) => {
          return {
            value: filter.value,
            label: filter.label ? `${t(filter.label)}` : filter.labelValue,
          };
        });
      });
      groupFilter.L1 = FilterOptions.L1.filter((column) => {
        if (column.value === groupByValue) {
          return column;
        }
      })[0];
      groupFilter.L2 = FilterOptions[groupByValue].filter((column) => {
        if (column.value === groupBySecondValue) {
          return column;
        }
      })[0];
    }, 250);
  }

  return (
    <Container sidebarWidth={sidebarWidth}>
      <TitleText type={typography.textTypes.grid} level={3}>
        {t('group_by', 'Group By:')}
      </TitleText>
      <FlexContent>
        <StyledSelect
          maxWidth={116}
          options={FilterOptions.L1}
          value={groupFilter.L1}
          onChange={(val) =>
            handleSetGroupFilter(val, FilterOptions[val.value][0], groupFilter.filters)
          }
        />
        <StyledSelect
          options={FilterOptions[groupFilter.L1.value]}
          value={groupFilter.L2}
          onChange={(val) => handleSetGroupFilter(groupFilter.L1, val, groupFilter.filters)}
        />
        <CalendarFilterMenu filters={filters} />
      </FlexContent>
    </Container>
  );
};

CalendarGroupFilter.propTypes = {
  filters: PropTypes.instanceOf(Object),
};

CalendarGroupFilter.defaultProps = {
  filters: null,
};
