import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CaseStatus, QueryKey, PromiseStatus, Feature } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { getAssetCase } from '@ge/shared/services/cases';
import { sorter } from '@ge/util/metric-sorter';

import { fetchCasesForAssetIdsAndStatus } from '../../monitor/services';

const monitorCasesTransformer = (data, asset, site) => {
  return data.map((elem) => ({
    domain: Feature.MONITOR,
    id: elem.id,
    siteId: elem?.site_id,
    assetId: elem?.asset_id,
    priority: '',
    code: elem?.code,
    title: elem?.code ? `${elem?.code}  ${elem?.description}` : elem?.description,
    opened: dayjs(elem?.start).valueOf(),
    caseType: elem?.type,
    status: elem?.caseState,
    caseStatus: elem?.caseState,
    escalated: elem?.escalated,
    description: elem?.description,
    totalTaskCount: elem?.totalTaskCount || 0,
    task: elem?.task,
    disposition: elem.disposition,
    rootCause: elem.rootCause,
    asset,
    site,
  }));
};
const analyzeCasesTransformer = ({ data }) => {
  return data.map((elem) => ({
    domain: Feature.ANALYZE,
    id: elem.id,
    asset: elem?.asset,
    site: elem?.site,
    siteId: elem?.site?.id,
    assetId: elem?.asset?.id,
    priority: elem?.priority,
    title: elem?.description,
    opened: elem?.start,
    caseType: elem?.caseType, // changed this from elem?.source
    status: elem?.status,
    caseStatus: elem?.caseStatus,
    totalTaskCount: elem?.totalTaskCount || 0,
    disposition: elem.disposition,
    rootCause: elem.rootCause,
    task: elem?.task,
    escalated: [],
  }));
};

/**
 * Use asset case data.
 *
 * @param assetId an asset id
 * @param caseStatus case status
 */
const useAssetCaseData = ({
  assetId,
  sortMetric,
  sortDirection,
  sortType,
  isAuthorizedToMonitorCases = false,
  isAuthorizedToAnalyzeCases = false,
  includeChildCases = false,
}) => {
  const getSiteById = useStoreState((state) => state.sites.getSiteById);
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const {
    data: _queryData,
    isLoading,
    ...queryRest
  } = useQuery(
    [QueryKey.ASSETS_CASES, assetId],
    async () => {
      const cases = [];
      const promises = [];

      isAuthorizedToMonitorCases && promises.push(getAssetCase(assetId));
      isAuthorizedToAnalyzeCases &&
        promises.push(
          fetchCasesForAssetIdsAndStatus([assetId], CaseStatus.OPEN, includeChildCases, false),
        );
      if (promises.length) {
        const responses = await Promise.allSettled(promises);
        if (responses.length === 2) {
          if (responses[0].status === PromiseStatus.FULFILLED) {
            let asset = getAssetById(responses[0].value?.[0]?.asset_id) || {};
            let site = getSiteById(responses[0].value?.[0]?.site_id) || {};
            cases.push(...monitorCasesTransformer(responses[0].value, asset, site));
          }
          if (responses[1].status === PromiseStatus.FULFILLED) {
            cases.push(...analyzeCasesTransformer(responses[1].value));
          }
        } else if (isAuthorizedToMonitorCases) {
          if (responses[0].status === PromiseStatus.FULFILLED) {
            let asset = getAssetById(responses[0].value?.[0]?.asset_id) || {};
            let site = getSiteById(responses[0].value?.[0]?.site_id) || {};
            cases.push(...monitorCasesTransformer(responses[0].value, asset, site));
          }
        } else {
          if (responses[0].status === PromiseStatus.FULFILLED) {
            cases.push(...analyzeCasesTransformer(responses[0].value));
          }
        }
      }

      return cases;
    },
    {
      enabled: Boolean(assetId),
      ...Config.EXECUTE_ONCE,
      refetchInterval: Config.REFRESH.realTime,
    },
  );

  const sortedData = useMemo(
    () =>
      isLoading || !_queryData ? [] : _queryData?.sort(sorter(sortMetric, sortDirection, sortType)),
    [_queryData, isLoading, sortDirection, sortMetric, sortType],
  );

  return {
    data: sortedData,
    isLoading,
    ...queryRest,
  };
};

export default useAssetCaseData;
