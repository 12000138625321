import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { EntityCreateContext } from '@ge/shared/context/entity-create-context';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useStaticData } from '@ge/shared/data-hooks';
import { elevations } from '@ge/tokens/elevations';

import useAttributesForFullStory from '../../hooks/use-attributes-for-fullstory';
import { useEntityCreateState } from '../../hooks/use-entity-create-state';
import { useEntityDetailsState } from '../../hooks/use-entity-details-state';
import { EntityCreatePanel } from '../entity-create/entity-create-panel';
import { EntityDetailsPanel } from '../entity-details/entity-details-panel';
import ErrorPanel from '../error-panel/error-panel';
import MainNav from '../navigation/main-nav';
import Sidebar from '../sidebar/sidebar';

const duration = 300;

// Provide the flexbox container
const StyledWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const StyledHeaderContainer = styled.header`
  z-index: ${elevations.P15};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
`;

// Provide the flexbox container
const StyledMainContainer = styled.div`
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  align-self: stretch;
  overflow: hidden;
`;

// Style the main content container
const StyledMainContent = styled.main`
  flex: 1;
  order: 1;
`;

const SharedPanelStyles = css`
  &.details-panel-transition-enter {
    transform: translateX(802px);
  }
  &.details-panel-transition-enter-active {
    transition: transform ${duration}ms;
    transform: translateX(0);
  }
  &.details-panel-transition-exit {
    transform: translateX(0);
  }
  &.details-panel-transition-exit-active {
    transition: transform ${duration}ms;
    transform: translateX(802px);
  }
`;

// Apply portal animation styles to entity details panel
const StyledEntityDetailsPanel = styled(EntityDetailsPanel)`
  ${SharedPanelStyles};
`;
const StyledEntityCreatePanel = styled(EntityCreatePanel)`
  ${SharedPanelStyles};
`;

const AnimatedDetailsPanel = () => {
  const { panelShown, resetDetails } = useContext(EntityDetailsContext);

  return (
    <CSSTransition
      in={panelShown}
      classNames="details-panel-transition"
      timeout={duration}
      onExited={resetDetails}
      unmountOnExit
    >
      <StyledEntityDetailsPanel />
    </CSSTransition>
  );
};

const AnimatedCreatePanel = () => {
  const { panelShown, resetDetails } = useContext(EntityCreateContext);

  return (
    <CSSTransition
      in={panelShown}
      classNames="details-panel-transition"
      timeout={duration}
      onExited={resetDetails}
      unmountOnExit
    >
      <StyledEntityCreatePanel />
    </CSSTransition>
  );
};

const Layout = ({ className, children, sidebar, isPopout }) => {
  const { i18n } = useTranslation([], { useSuspense: false });

  const { language } = useStoreState((state) => state.prefs);
  // Commented for the defect: DE197808
  //const { getUserRoleBasedEntityAttributes } = useStoreActions((actions) => actions.userRole);

  const entityDetailsState = useEntityDetailsState(isPopout);
  const entityCreateState = useEntityCreateState(isPopout);
  useAttributesForFullStory();
  // Load all static data on app-layout
  useStaticData();

  // Fetch user role subsets once user is logged in.
  // Commented for the defect: DE197808
  // useEffect(() => {
  //   getUserRoleBasedEntityAttributes();
  // }, [getUserRoleBasedEntityAttributes]);

  // Set the language on initial load.
  useEffect(() => {
    if (!language) {
      return;
    }

    i18n.changeLanguage(language);
  }, [i18n, language]);

  // If we are in a popout, return just the content, otherwise
  // wrap it in the normal app layout. This allows us to inject
  // the entity state management to both with a single hook.
  return (
    <EntityDetailsContext.Provider value={entityDetailsState}>
      <EntityCreateContext.Provider value={entityCreateState}>
        {isPopout ? (
          children
        ) : (
          <StyledWrapper className={className}>
            <ErrorPanel />
            <AnimatedDetailsPanel />
            <AnimatedCreatePanel />
            <StyledHeaderContainer>
              <MainNav />
            </StyledHeaderContainer>
            <StyledMainContainer>
              <StyledMainContent>{children}</StyledMainContent>
              {/* Hiding for MVP1 - Story #1081 */}
              {sidebar && false && <Sidebar />}
            </StyledMainContainer>
          </StyledWrapper>
        )}
      </EntityCreateContext.Provider>
    </EntityDetailsContext.Provider>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  sidebar: PropTypes.bool,
  isPopout: PropTypes.bool,
};

Layout.defaultProps = {
  className: null,
  children: null,
  sidebar: true,
  isPopout: false,
};

const StyledAppLayout = styled(Layout)`
  background: ${(props) => props.theme.layout.mainBackgroundColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const AppLayout = withRouter(StyledAppLayout);
