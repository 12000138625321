import { css } from 'styled-components';

import { globalColors } from '@ge/tokens/colors';

export const typography = {
  family: {
    default: 'Museo Sans',
    secondary: 'GE Inspira',
  },
  weight: {
    light: 100,
    regular: 300,
    medium: 500,
    bold: 700,
    bolder: 900,
  },
  size: {
    sm: '11px',
    md: '13px',
  },
  textTypes: {
    kpi: 'kpi',
    grid: 'grid',
    body: 'body',
  },
  elementTypes: {
    span: 'span',
    div: 'div',
    label: 'label',
  },
  titleLevels: [1, 2, 3, 4, 5],
};

export const typographyClasses = css`
  h1 {
    font-family: ${typography.family.secondary};
    font-weight: ${typography.weight.regular};
    font-size: 30px;
    line-height: 35px;
    margin: 0;
    &.pageTitle {
      padding: 20px 18px;
      &.filter {
        padding: 16px 18px 16px 70px;
      }
    }
  }
  h2 {
    font-family: ${typography.family.secondary};
    font-weight: ${typography.weight.regular};
    font-size: 22px;
    line-height: 26px;
    margin: 0;
  }
  h3 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }
  h4 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.bold};
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 15px;
    margin: 0;
  }
  h5 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    font-size: 11px;
    line-height: 13px;
    margin: 0;
  }
  .kpi-1 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 29px;
  }
  .kpi-2 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 18px;
    letter-spacing: -0.38px;
    line-height: 22px;
  }
  .kpi-3 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    font-size: 16px;
    letter-spacing: -0.33px;
    line-height: 19px;
  }
  .kpi-4 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    font-size: 10px;
    letter-spacing: 0.45px;
    line-height: 12px;
  }
  .kpi-5 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1px;
  }
  .kpi-target {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    font-size: 12px;
    line-height: 14px;
  }
  .grid-header {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    font-size: 11px;
    line-height: 13px;
  }
  .grid-1 {
    font-family: ${typography.family.default};
    font-size: 14px;
    line-height: 30px;
  }
  .grid-2 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 12px;
    line-height: 20px;
  }
  .grid-3 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    font-size: 11px;
    line-height: 16px;
  }
  .body-1 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 14px;
    line-height: 18px;
  }
  .body-2 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 12px;
    line-height: 15px;
  }
  .body-4 {
    font-family: ${typography.family.default};
    font-size: 14px;
    line-height: 18px;
  }
  .text-4 {
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.regular};
    font-size: 11px;
    line-height: 15px;
  }
  .danger-txt {
    font-weight: 700;
    color: ${globalColors.red2};
  }
`;
