import React from 'react';

import { FullLoader } from '@ge/components/loader';
import { getClientId, signinRedirectCallback } from '@ge/shared/services/auth';

// Capture the redirected URL
// var url = window.location.href;

// Check if there was an error parameter
// var error = url.match('error=([^&]*)')

export const AuthCallback = () => {
  signinRedirectCallback(window.location.href + `&client_id=${getClientId()}`);
  return <FullLoader />;
};
