import * as request from './api';
const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

export const fetchTaskById = (taskId) => request.get(`/mwp/tasks/${taskId}`, { baseURL: BASE_URL });
export const fetchTasksByTaskIds = (taskIds, isParentTaskId) =>
  taskIds?.length &&
  request.post(
    '/mwp/tasks/task-ids',
    { taskIds: taskIds.join() },
    {
      baseURL: BASE_URL,
      ...(isParentTaskId && { params: { isRecurrenceParentTaskId: true } }),
    },
  );

export const editTask = (id, task) =>
  request.patch(`/mwp/tasks/${id}`, task, { baseURL: BASE_URL });

export const bulkTask = (task) => request.put('/mwp/bulk-task', task, { baseURL: BASE_URL });

export const deleteTask = (id) => request.deleteItem(`/mwp/tasks/${id}`, { baseURL: BASE_URL });

export const deleteBulkTask = (task) =>
  request.deleteItemWithBody(`/mwp/bulk-task-delete`, task, { baseURL: BASE_URL });
/**
 * Dynamically load the site mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/task/tasks.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });

/**
 * IMPORTANT TODO: These calls have no endpoints underneath them,
 * so we cannot remove the mock calls from the UI.
 */
export const forceMocks = () =>
  import('./__mocks__/task/tasks.mocks')
    .then((mocks) => mocks.forceMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });
