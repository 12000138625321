import { Placeholders } from '@ge/models/constants';

/**
 * Helper function that will create default values for cells in a DRY and consistent way.
 * If a value is either empty or missing we should render a single dash.
 * This function allows usage for both direct values and passing optional children.
 * i.e If you need to render a dash in place of a component or pass a speical value.
 */
export const withDefault = (value, child = false) => {
  if (child) return value ? child : Placeholders.DASH;

  return value ? value : Placeholders.DASH;
};
