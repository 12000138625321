import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { DynamicTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AdminDefs, UserAuditHistory } from '@ge/models/constants';
import { useFilterDefs } from '@ge/shared/hooks';
import { withDefault } from '@ge/shared/util/table-utils';

import {
  globalPersonHistoryCols,
  PersonHistoryColumnDefs,
  PersonHistoryColumns,
} from '../../../../models/person-history-table-cols';

const ThGroup = styled.span`
  height: 1px;
`;

const PersonHistoryTable = ({
  history,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  scrollable,
  className,
  onFilter,
  onFilterChange,
  filterValues,
  onRowSelect,
}) => {
  const { t, ready } = useTranslation(['admin.people']);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const { filterDefs, onChange: onFilterApply } = useFilterDefs({
    columnDefs: PersonHistoryColumnDefs,
    stateId: AdminDefs.HISTORY,
  });

  useEffect(() => {
    onFilter(filterDefs);
  }, [filterDefs, onFilter]);

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilterApply(columnKey, value);
    },
    [onFilterApply],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const cellValueMapFn = useCallback((historyDetail) => {
    if (!historyDetail) {
      return {};
    }

    const { action, type, serviceGroup, changedBy, actionTime } = historyDetail;

    return {
      [PersonHistoryColumns.ACTION]: action,
      [PersonHistoryColumns.TYPE]: type,
      [PersonHistoryColumns.SERVICE_GROUP]: serviceGroup,
      [PersonHistoryColumns.CHANGED_BY]: changedBy,
      [PersonHistoryColumns.ACTION_TIME]: actionTime,
    };
  }, []);

  // TODO: figure out why this isnt rendering in the column header
  const customHeaderFn = useCallback((columnKey) => {
    switch (columnKey) {
      case PersonHistoryColumns.GROUP_ACTION:
      case PersonHistoryColumns.GROUP_TYPE:
      case PersonHistoryColumns.GROUP_SERVICE_GROUP:
      case PersonHistoryColumns.GROUP_CHANGED_BY:
      case PersonHistoryColumns.GROUP_ACTION_TIME:
        return <ThGroup />;
      default:
        return null;
    }
  }, []);

  const formattedActionTime = (cellValue) => {
    let actionTime = cellValue?.format(UserAuditHistory.HISTORY_DATE_FORMAT);
    return actionTime && actionTime !== UserAuditHistory.INVALID_DATE ? actionTime : '';
  };
  const customCellFn = (columnKey, cellValue) => {
    switch (columnKey) {
      case PersonHistoryColumns.ACTION:
      case PersonHistoryColumns.TYPE:
      case PersonHistoryColumns.SERVICE_GROUP:
      case PersonHistoryColumns.CHANGED_BY:
        return withDefault(cellValue);
      case PersonHistoryColumns.ACTION_TIME:
        return withDefault(formattedActionTime(cellValue));
      default:
        return null;
    }
  };

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: PersonHistoryColumnDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    filterValues,
    filters: filterDefs,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
  });

  if (!columns || columns.length === 0) {
    return null;
  }
  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: history,
    rowKeyProperty: 'historyId',
    onValueSelect: onRowSelect,
  };

  const tableEl = React.createElement(DynamicTable, dynamicProps);

  return <PageContainer i18nReady={ready}>{tableEl}</PageContainer>;
};

PersonHistoryTable.propTypes = {
  history: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  handleScroll: PropTypes.func,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
  onRowSelect: PropTypes.func,
};

PersonHistoryTable.defaultProps = {
  columns: globalPersonHistoryCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  scrollable: true,
  onFilter: () => null,
  onFilterChange: () => null,
  onRowSelect: () => null,
  filterValues: {},
};

export default PersonHistoryTable;
