import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { AssetConnectionStatus } from '@ge/models/constants';
import { AssetType, QueryKey } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import { fetchRealTimeDataForAsset } from '../services/asset';
// TODO: pull this from config, ideally something that can be updated without a redeploy
const REAL_TIME_DATA_REFRESH = 30000;

const mockAlarms = [
  {
    code: '2',
    severity: 'high',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    eventClass: 'Information',
    start: dayjs().subtract(151, 'minutes').toISOString(),
  },
  {
    code: '10000020',
    severity: 'medium',
    description: 'No Errors',
    eventClass: 'Information',
    start: dayjs().subtract(93, 'minutes').toISOString(),
  },
  {
    code: 'weca-21-f324-ee334-44433',
    severity: 'low',
    description: 'Lorem ipsum dolor sit amet',
    eventClass: 'Information',
    start: dayjs().subtract(1, 'hours').toISOString(),
  },
];

export const useAssetRealTimeData = ({ assetId, sortDirection, sortMetric }) => {
  const { isLoading: isAssetsLoading } = useStoreState((state) => state.assets);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);
  const getAssetById = useStoreState((state) => state.assets.getAssetById);

  const asset = getAssetById(assetId);
  const {
    data: rtData,
    error,
    isLoading,
    dataUpdatedAt,
    isFetching,
  } = useQuery(
    [QueryKey.ASSET_REALTIME_DATA, assetId],
    async () => fetchRealTimeDataForAsset(asset),
    {
      // TODO enable for all asset types
      enabled: Boolean(assetId) && Boolean(asset),
      refetchInterval: REAL_TIME_DATA_REFRESH,
      // https://github.com/tannerlinsley/react-query/discussions/1118#discussioncomment-90017
      refetchOnWindowFocus: true,
    },
  );

  return useMemo(() => {
    const site = getSiteById(asset?.site?.id);
    let metrics;
    let alarms;
    if (
      asset?.type === AssetType.WIND_TURBINE ||
      asset?.type === AssetType.SITE_CONTROLLER ||
      asset?.type === AssetType.STORAGE_INVERTER
    ) {
      metrics = rtData?.metrics;
      alarms = rtData?.events;
    } else if (asset?.type === AssetType.SUBSTATION) {
      metrics = rtData?.metrics;
      alarms = rtData?.events;
    } else {
      // TODO remove mocks for other asset types
      metrics = { conditions: { wind: null, temp: null }, readings: { signals: [] } };
      alarms = mockAlarms;
    }

    alarms = Array.isArray(alarms)
      ? alarms.map((alarm) => ({
          ...alarm,
          duration: alarm?.start ? dayjs().diff(dayjs(alarm?.start), 'ms') : 0,
        }))
      : [];

    const isDisconnected = asset?.metrics?.connection?.value === AssetConnectionStatus.NOCOMM;

    return {
      data: {
        ...asset,
        site,
        metrics: metrics,
        alarms: alarms?.length ? alarms.sort(sorter(sortMetric, sortDirection)) : undefined,
      },
      error,
      isLoading: isLoading || isAssetsLoading,
      dataUpdatedAt,
      isFetching,
      isDisconnected,
    };
  }, [
    asset,
    error,
    getSiteById,
    isAssetsLoading,
    isLoading,
    rtData,
    sortMetric,
    sortDirection,
    dataUpdatedAt,
    isFetching,
  ]);
};
