import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { elevations } from '@ge/tokens/elevations';

const StyledWrapper = styled.div`
  ${(props) => {
    if (props.danger) return `background: ${props.theme.miniBarChart.dangerBackgroundColor}`;
    if (props.warning) return `background: ${props.theme.miniBarChart.warningBackgroundColor}`;
    return `background: ${
      props.altColor
        ? props.theme.miniBarChart.altBackgroundColor
        : props.theme.miniBarChart.backgroundColor
    }`;
  }};
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: ${(props) => props.width};
  height: 11px;
  display: inline-block;
  position: relative;
`;

const StyledOverFlowWrapper = styled.div`
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 14px;
`;

const StyledLowerIndicator = styled.div`
  ${(props) => {
    if (props.altIndicatorStyle)
      return css`
        border-bottom: 12px solid ${props.theme.miniBarChart.altIndicatorFillColor};
      `;
    return css`
      border-bottom: 12px solid ${(props) => props.theme.miniBarChart.indicatorColor};
    `;
  }};
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  display: ${(props) => (props.hideThresholds || !props.threshold ? 'none' : 'block')};
  position: absolute;
  left: ${(props) => props.threshold}%;
  height: 0;
  top: 6px;
  width: 1px;
  z-index: ${elevations.P2};
`;

const StyledUpperIndicator = styled.div`
  display: ${(props) => (props.hideThresholds || !props.threshold ? 'none' : 'block')};
  position: absolute;
  top: -12px;
  left: calc(${(props) => props.threshold}% - 5px);
  height: 0;
  width: 1px;
  border-top: 20px solid ${(props) => props.theme.miniBarChart.upperIndicatorColor};
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  z-index: ${elevations.P2};
`;

const StyledFill = styled.div`
  ${(props) => {
    if (props.danger)
      return css`
        background: ${props.theme.miniBarChart.dangerFillColor};
      `;
    if (props.warning)
      return css`
        background: ${props.theme.miniBarChart.warningFillColor};
      `;
    return css`
      background: ${props.altColor
        ? props.theme.miniBarChart.altFillColor
        : props.theme.miniBarChart.fillColor};
    `;
  }};
  border-radius: 0 3px 3px 0px;
  position: absolute;
  top: 0;
  right: 0px;
  height: 11px;
  width: ${(props) => props.percent}%;
  display: block;
`;

export const MiniBarChart = ({
  altColor,
  warning,
  danger,
  percent,
  lowerThreshold,
  upperThreshold,
  hideThresholds,
  altIndicatorStyle,
  width = '68px',
}) => {
  percent = 100 - percent;

  return (
    <StyledOverFlowWrapper>
      <StyledWrapper width={width} warning={warning} danger={danger} altColor={altColor}>
        <StyledLowerIndicator
          threshold={lowerThreshold}
          warning={warning}
          danger={danger}
          hideThresholds={hideThresholds}
          altIndicatorStyle={altIndicatorStyle}
        />
        <StyledUpperIndicator threshold={upperThreshold} hideThresholds={hideThresholds} />
        <StyledFill percent={percent} warning={warning} danger={danger} altColor={altColor} />
      </StyledWrapper>
    </StyledOverFlowWrapper>
  );
};

MiniBarChart.propTypes = {
  altColor: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  percent: PropTypes.number,
  lowerThreshold: PropTypes.number,
  upperThreshold: PropTypes.number,
  hideThresholds: PropTypes.bool,
  altIndicatorStyle: PropTypes.bool,
  width: PropTypes.string,
};

MiniBarChart.defaultProps = {
  altColor: false,
  warning: false,
  danger: false,
  percent: 0,
  lowerThreshold: 0,
  upperThreshold: 0,
  hideThresholds: false,
  altIndicatorStyle: false,
  width: '68px',
};
