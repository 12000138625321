import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Radio } from '@ge/components/radio';
import { EntityType } from '@ge/models/constants';

import { AssetComponents } from '../../../task-fields/asset-components';
import { ColumnDetail, ColumnTitle, DisplayLabel, FieldGroup } from '../../../task-template-shared';

const TaskApplicableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0 !important;
  margin: 10px 10px 0 0;
`;

const TaskApplicableRadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0;
    opacity: 0.5;
    &:last-of-type {
      margin-left: 16px;
    }
  }
`;

const EntitySelect = styled.div`
  background: ${(props) => props.theme.select.secondaryBackground};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
  min-height: 25px;
  opacity: 0.5;
  padding: 2px 8px;
  width: 250px;
`;

const StyledAssetComponents = styled(AssetComponents)`
  display: flex;
  &.asset-select {
    .select__menu {
      .select__menu-list {
        max-height: 200px;
        &::-webkit-scrollbar {
          width: 4px;
          height: 0px;
        }
        &::-webkit-scrollbar-track {
          background: ${(props) => props.theme.scrollbar.trackBackground};
        }
        &::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.scrollbar.thumbBackground};
          border-radius: 2.5px;
        }
      }
    }
  }
  > div {
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.select.chevronColor,
}))`
  margin-top: 3px;
`;

export const EditAffectedAssets = ({ entityType, task }) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.affected_assets', 'Affected Asset(s)')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.task_applies_to', 'Task applies to')}</DisplayLabel>
            <TaskApplicableContainer>
              <TaskApplicableRadioContainer>
                <Radio
                  name="entityType"
                  label={t('form.assets', 'Asset(s)')}
                  value={EntityType.ASSET}
                  checked={entityType === EntityType.ASSET}
                  disabled
                />
                <Radio
                  name="entityType"
                  label={t('form.sites', 'Site(s)')}
                  value={EntityType.SITE}
                  checked={entityType === EntityType.SITE}
                  disabled
                />
              </TaskApplicableRadioContainer>
            </TaskApplicableContainer>
          </div>
          <div className="field-wrapper">
            <DisplayLabel>
              {task?.asset ? t('form.assets', 'Asset(s)') : t('form.sites', 'Site(s)')}
            </DisplayLabel>
            <EntitySelect>
              {entityType === EntityType.ASSET ? task?.asset.name : task?.site.name}
              <ChevronIcon />
            </EntitySelect>
          </div>
        </FieldGroup>
        {entityType === EntityType.ASSET && (
          <FieldGroup>
            <StyledAssetComponents
              assetIds={[task?.asset.id]}
              className="asset-select"
              width={160}
              task={task}
            />
          </FieldGroup>
        )}
      </ColumnDetail>
    </div>
  );
};

EditAffectedAssets.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string.isRequired,
};
