/**
 * Create a task distribution array. This array represents the number of tasks that
 * can be assigned to each day in the array representing an even distribution.
 *
 * @param {array} datesArr An array of dates to distribute tasks across
 * @param {array} tasksArr An array of tasks to be distributed
 * @returns array
 */
export const getDatesDistribution = (datesArr, tasksArr) => {
  const totalDays = datesArr.length;
  const totalTasks = tasksArr.length;

  // the current index of the distrArr we're at
  let currentIndex = 0;
  // the current gap in between date indexes in the distrArr
  // initialized at 1 day apart
  let distribGap = 1;

  // last iteration flag
  let lastIteration = false;
  // the gap in between date indexes in the last iteration of the distrArr
  let lastIterationGap = 0;

  // initialize array with placeholder values of 0
  // this will be the amount of tasks that a date needs
  const distrArr = Array(totalDays).fill(0);

  for (const i in tasksArr) {
    // the current date the loop is on in the iteration
    const distDate = datesArr[currentIndex];

    // how many dates are left in the iteration over the datesArr
    const datesLeft = totalDays - datesArr.indexOf(distDate);
    // how many tasks are left in the loop
    const tasksLeft = totalTasks - i;

    // this marks the last iteration
    // this block will only run ONCE (at the beginning of the last iteration)
    if (currentIndex === 0 && datesLeft >= tasksLeft && !lastIteration) {
      lastIteration = true;

      // calculating the distribution for the last iteration (# of total days / # of tasks left for the last iteration)
      lastIterationGap = totalDays / tasksLeft;
    }

    if (lastIteration) {
      // calculating the distribGap of this loop through the last iteration
      distribGap = lastIterationGap * currentIndex;

      // add 1 task to the appropriate date
      distrArr[Math.round(distribGap)] = distrArr[Math.round(distribGap)] + 1;

      // advance the last iteration currentIndex 1
      currentIndex = currentIndex + 1;
    } else {
      // add 1 task to the appropriate date
      distrArr[currentIndex] = distrArr[currentIndex] + 1;

      // check if we need are going to hit the end of the array and reset currentIndex if needed.
      if (currentIndex + distribGap >= totalDays) {
        currentIndex = 0;
        continue;
      }

      // add distribution gap to the currentIndex for next loop
      currentIndex = currentIndex + distribGap;
    }
  }

  return distrArr;
};
