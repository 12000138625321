import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { ManualAdjustmentContext } from './manual-adjustment-context';

export const ManualAdjustmentProvider = ({ children }) => {
  const [calendarDate, setCalendarDate] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    setCalendarDate(dayjs().startOf('date').subtract(1, 'day').valueOf());
  }, []);

  const handleSiteExpand = useCallback((site) => {
    setSelectedEvent(null);
    setSelectedSite(site);
    setSelectedAssets([]);
  }, []);

  const handlePrevNextCalendarDate = useCallback(
    (prevDate) => {
      setSelectedEvent(null);
      if (prevDate) {
        setCalendarDate(dayjs(calendarDate).startOf('date').subtract(1, 'day').valueOf());
      } else {
        setCalendarDate(dayjs(calendarDate).startOf('date').add(1, 'day').valueOf());
      }
    },
    [setCalendarDate, calendarDate],
  );

  const handleCalendarDate = useCallback(
    (date) => {
      setSelectedEvent(null);
      setCalendarDate(date);
    },
    [setCalendarDate],
  );

  const provider = {
    calendarDate,
    selectedEvent,
    autoWidth: !(selectedEvent && !selectedEvent.bulk),
    selectedSite,
    selectedAssets,
    setCalendarDate,
    setSelectedEvent,
    setSelectedSite,
    setSelectedAssets,
    handleSiteExpand,
    handlePrevNextCalendarDate,
    handleCalendarDate,
  };

  return (
    <ManualAdjustmentContext.Provider value={provider}>{children}</ManualAdjustmentContext.Provider>
  );
};

ManualAdjustmentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
