import dayjs from 'dayjs';
import { useMemo } from 'react';

import { EntityType } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useEntityNotes } from '@ge/shared/data-hooks/use-task-notes';

const TASK_NOTES_QUERY_KEY = 'reportingTaskNotes';

export const useTaskNotes = ({ config = Config.EXECUTE_ONCE, queryKey, tasks, entityType }) => {
  const {
    data: _data,
    error,
    isLoading,
  } = useEntityNotes({
    config,
    entityIds: tasks?.map(({ id }) => id),
    entityType: entityType ?? EntityType.TASK,
    queryKey: [TASK_NOTES_QUERY_KEY, tasks, queryKey],
  });

  const data = useMemo(() => {
    if (!_data?.length) {
      return null;
    }

    const notesLookup = _data.reduce(
      (_notesLookup, { deleted, domainId: taskId, id, note, scope, updateDate: timestamp }) => {
        const taskNotes = (_notesLookup[taskId] = _notesLookup[taskId] ?? []);

        // only filter out deleted notes, we want to include all statuses (active/inactive/archived) per jonathan adams
        if (!deleted) {
          taskNotes.push({ id, note, scope, timestamp });
        }

        return _notesLookup;
      },
      {},
    );

    // TODO: can make sort configurable, but defaults to latest notes first
    Object.values(notesLookup ?? {}).forEach((notes) =>
      notes.sort(({ timestamp: a }, { timestamp: b }) => (dayjs(a).isAfter(dayjs(b)) ? -1 : 1)),
    );

    return notesLookup;
  }, [_data]);

  return { data, error, isLoading };
};
