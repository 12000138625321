import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
import { CustomSelect } from '@ge/components/select';
import { SortDirection } from '@ge/models/constants';

const DropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, ${(props) => props.itemWidth}px);
  margin-top: 20px;
  column-gap: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 20px;

  div {
    flex-grow: 1;

    .label-cls {
      color: #999999;
      font-size: 11px;
      font-family: 'Museo Sans';
      font-weight: 500;
      line-height: 13px;
      margin-bottom: 5px;
    }
  }

  p {
    margin: 0.3em 0;
  }
`;

export const DLdropdowns = ({ data }) => {
  const maxWidth = 300;

  const { t } = useTranslation(['admin.configure']);
  const [assets, setAssets] = useState([]);
  const { control } = useFormContext();
  const { getSortedSites, getSiteById } = useStoreState((state) => state.sites);

  useEffect(() => {
    if (data) {
      let sites = [];
      let assets = [];
      data.forEach((rec) => {
        if (rec?.siteId) {
          sites.push(rec.siteId);

          if (rec?.assetId) {
            assets.push(...rec.assetId);
          }
        }
      });
      sites = [...new Set(sites)];
      assets = [...new Set(assets)];
      control.setValue('sites', formatData(sites.map((item) => getSiteById(item))));

      control.setValue(
        'assets',
        sites.length !== 1
          ? 'All'
          : data.length === 1 && data[0]?.assetId?.length
          ? formatData(assets.map((item) => getAssetById(item)))
          : data.length.length === 1 && data[0]?.assetId?.length.length === 0
          ? []
          : formatData(getAllAssetsBySiteIds(sites)),
      );
      setAssets(getAllAssetsBySiteIds(sites));
    }
  }, [control]);

  const siteValueLabelMap = useMemo(
    () =>
      getSortedSites('name', SortDirection.ASC)?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [getSortedSites],
  );

  const assetValueLabelMap = useMemo(
    () => assets.map(({ id, name }) => ({ value: id, label: name })),
    [assets],
  );

  const { getAllAssetsBySiteIds, getAssetById } = useStoreState((state) => state.assets);

  const handleSiteChange = (onChange, value) => {
    const selectedSites = value.map(({ value }) => value);

    setAssets(selectedSites.length === 1 ? getAllAssetsBySiteIds(selectedSites) : []);

    control.setValue('assets', selectedSites.length > 1 ? 'All' : []);
    onChange(value);
  };

  const formatData = (arg) => {
    if (!arg || !arg.length) return [];
    return arg.map((val) => ({
      label: val && val.name ? val.name : '',
      value: val && val.id ? val.id : '',
    }));
  };

  return (
    <DropdownWrapper itemWidth={maxWidth}>
      <Controller
        control={control}
        name={'sites'}
        rules={{
          required: true,
          validate: (val) => Boolean(val.length),
        }}
        render={({ onChange, onBlur, value }, { invalid }) => {
          return (
            <CustomSelect
              placeholder={t('placeholders.select_sites', 'Select sites')}
              disabled={false}
              errors={invalid ? 'select minimum one site' : ''}
              id={'DL_Sites'}
              label={t('DL_form_fields.sites', 'Sites')}
              onSelect={(val) => handleSiteChange(onChange, val)}
              onBlur={onBlur}
              options={siteValueLabelMap}
              required={true}
              value={value}
              isMulti={true}
              searchable={true}
              maxMenuHeight={220}
              maxWidth={maxWidth}
              labelIcon={Icons.SITE}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={'assets'}
        rules={{ required: false, validate: (val) => Boolean(val.length) }}
        render={({ onChange, onBlur, value }, { invalid }) => {
          if (control.getValues('sites')?.length > 1)
            return (
              <InputWrapper>
                <div>
                  <p className="label-cls">Assets</p>
                  <p>All</p>
                </div>
              </InputWrapper>
            );
          return (
            <CustomSelect
              placeholder={t('placeholders.select_assets', 'Select assets')}
              disabled={!assets.length}
              errors={invalid ? 'select minimum one asset' : ''}
              id={'DL_assets'}
              label={t('DL_form_fields.assets', 'Assets')}
              onSelect={(val) => onChange(val)}
              onBlur={onBlur}
              options={assetValueLabelMap}
              required={false}
              value={value}
              isMulti={true}
              searchable={true}
              maxMenuHeight={220}
              maxWidth={maxWidth}
              labelIcon={Icons.TURBINE}
            />
          );
        }}
      />
    </DropdownWrapper>
  );
};

DLdropdowns.propTypes = {
  data: PropTypes.any,
};
