import { useStoreActions, useStoreRehydrated } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { FullLoader } from '@ge/components/loader';
import { AuthStrategy, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { isAuthenticated, isSignedInWithIdp, signinRedirect } from '@ge/shared/services/auth';

import { useNavigation } from '../../hooks/use-navigation';
import { useFetchOidcInfo } from '../../hooks/use-oidc-info';
import { APP_BASE_ROUTE } from '../../models/routes';
import { FourOhThree } from '../403/403';
import { AppLayout } from '../app-layout/app-layout';
import { StaticDataLoader } from '../static-data-loader';

// determines what data is needed by route
const RouteDataLoader = ({ block, children, isPopout }) => {
  if (isPopout) {
    return children;
  }

  return <StaticDataLoader block={block}>{children}</StaticDataLoader>;
};

RouteDataLoader.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isPopout: PropTypes.bool,
};

RouteDataLoader.defaultProps = {
  block: true,
  isPopout: false,
};

/**
 * Just a wrapper around Route to add in authentication
 */
export const PrivateRoute = ({
  capabilities,
  authStrategy,
  component: Component,
  loadUnhydrated,
  path,
  isPopout,
  sidebar,
  redirectTo,
}) => {
  // actions
  // const { setInPopout } = useStoreActions((actions) => actions.session);
  // looks like we were tracking something similar on a session model at some point,
  // can switch app state to session state if that makes more sense
  const { setIsPopout } = useStoreActions((actions) => actions.app);
  const { data: multiTenantData } = useFetchOidcInfo();

  // data hooks
  const { audit, isLoading } = useAuth();
  const { getDefaultLevel1Nav } = useNavigation();
  const isRehydrated = useStoreRehydrated();

  useEffect(() => {
    setIsPopout(isPopout);
  }, [isPopout, setIsPopout]);

  /**
   * The render function for PrivateRoute.
   *
   * @param props
   */
  const RouteRender = (props) => {
    const { location } = props;

    if (!isRehydrated && !loadUnhydrated) {
      return <FullLoader />;
    }
    if (!isAuthenticated()) {
      if (!multiTenantData) {
        return <FullLoader />;
      }
      const { multiTenant } = multiTenantData;
      if (multiTenant?.length && !isSignedInWithIdp()) {
        return <Redirect to="/signin" />;
      }
      // Send to /redirect which will bring them back if authenticated or kick
      // them to /login if not.
      //return <Redirect to={{ pathname: '/redirect', fromUrl: location }} />;
      // TODO: capture from URL for redirect on successful login
      signinRedirect();

      return <FullLoader />;
    }

    if (isLoading) {
      return <FullLoader />;
    }

    // resolve level 1 redirect from base route
    if (path === APP_BASE_ROUTE) {
      return <Redirect to={getDefaultLevel1Nav()} />;
    }

    if (redirectTo) {
      return <Redirect to={{ pathname: redirectTo, fromUrl: location }} />;
    }

    const isAuthorizedRoute =
      // TODO: if we want to enforce capabilities for all private routes can remove this no capabilities check here
      !capabilities?.length ||
      audit({
        capabilities,
        source: `Route - ${path}`,
        type: 'Private route blocked',
        authStrategy,
      });

    const WhatToRender = isAuthorizedRoute ? (
      <Component /> // Currently using the catch-all 404 route in app.jsx
    ) : (
      <FourOhThree />
    ); // This currently uses the 401 design

    // Include app layout if not in popout
    return (
      <RouteDataLoader block isPopout={isPopout}>
        <AppLayout sidebar={sidebar} isPopout={isPopout}>
          {WhatToRender}
        </AppLayout>
      </RouteDataLoader>
    );
  };

  RouteRender.propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
    }),
  };

  RouteRender.defaultProps = {
    location: {
      hash: '',
      pathname: '',
    },
  };

  return <Route path={path} render={RouteRender} />;
};

PrivateRoute.propTypes = {
  capabilities: PropTypes.arrayOf(
    PropTypes.shape({
      capability: PropTypes.object,
      scopes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PermissionScope))),
    }),
  ),
  authStrategy: PropTypes.oneOf(Object.values(AuthStrategy)),
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  path: PropTypes.string,
  loadUnhydrated: PropTypes.bool,
  isPopout: PropTypes.bool,
  sidebar: PropTypes.bool,
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  isPopout: false,
  loadUnhydrated: false,
  sidebar: true,
};
