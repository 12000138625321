import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { elevations } from '@ge/tokens/elevations';

// Style the main content container
const PanelWrapper = styled.div`
  background: ${(props) => props.theme.layout.sidebarBackgroundColor};
  color: ${(props) => props.theme.layout.errorColor};
  min-height: 1.875em;
  left: 20%;
  font-size: 16px;
  line-height: 22px;
  min-height: 1.875em;
  overflow: hidden;
  padding: 10px;
  position: fixed;
  right: 20%;
  top: 0;
  text-align: center;
  transform: translateY(-100%);
  transition: 0.4s ease-in;
  z-index: ${elevations.P101};

  &.active {
    box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.3);
    transform: translateY(0%);
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
`;

const ErrorPanel = ({ theme }) => {
  const { layout } = theme;
  const [messages, setMessages] = useState([]);

  const { getErrors } = useStoreState((state) => state.errors);
  const { resetErrors } = useStoreActions((actions) => actions.errors);

  useEffect(() => {
    setMessages(getErrors().slice(Math.max(getErrors().length - 5, 0)));
  }, [getErrors]);

  const closePanel = () => {
    resetErrors();
  };

  return createPortal(
    <PanelWrapper className={messages.length && 'active'}>
      <CloseIcon onClick={closePanel}>
        <Icon size={12} icon={Icons.CLOSE} color={layout.textColor} />
      </CloseIcon>
      {messages.map((error) => (
        <div key={error.created + error.message}>{error.message}</div>
      ))}
    </PanelWrapper>,
    document.querySelector('#portal-root'),
  );
};

ErrorPanel.propTypes = {
  theme: PropTypes.shape({
    layout: PropTypes.instanceOf(Object),
  }).isRequired,
};

export default withTheme(ErrorPanel);
