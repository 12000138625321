import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const TENANTS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchAllTenants = () => {
  return request.get('/cmn/tenants', {
    baseURL: TENANTS_BASE_URL,
  });
};

export const useTenants = () => {
  const {
    error: tenantError,
    isLoading: isTenantsLoading,
    data: allTenants,
  } = useQuery([QueryKey.ALL_TENANTS], fetchAllTenants, {
    refetchOnWindowFocus: false,
  });

  const applyTenantName = useCallback(
    (str) => {
      let name = allTenants.filter((tenant) => tenant.tenantId === str);
      return name[0].companyName;
    },
    [allTenants],
  );

  return {
    isLoading: isTenantsLoading,
    error: tenantError,
    data: allTenants,
    applyTenantName,
  };
};
