import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { Tbody, Td, Tr } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { DateTimeFormats, TimeAggr } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util';

import { ReportWidgetTable } from '../../tables/report-widget-table';
import { TableWidgetStateKeys } from '../table-widget/table-widget';

import {
  contractualAvailabilityColumnDefs,
  ContractualAvailabilityColumns,
} from './contractual-availability-cols';

dayjs.extend(isoWeek);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ summaryColumn }) =>
    summaryColumn &&
    `
    ${Tbody} ${Tr} ${Td}:last-of-type {
      font-weight: 500;
    }
  `}

  ${({ theme, summaryRow }) =>
    summaryRow &&
    `
    ${Tbody} ${Tr} ${Td}:has(> ${SummaryRowValue}) {
      border-top: 1px solid ${theme.createReport.widget.contractualAvailability.rowBorderColor};
      font-weight: 700;
    }
  `}
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.contractualAvailability.includeTextColor};
`;

const SummaryRowValue = styled.span``;

const getDisplayValue = (value, language) =>
  new Intl.NumberFormat(language, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);

const DynamicColumnIndexes = {
  [ContractualAvailabilityColumns.ONE]: 0,
  [ContractualAvailabilityColumns.TWO]: 1,
  [ContractualAvailabilityColumns.THREE]: 2,
  [ContractualAvailabilityColumns.FOUR]: 3,
  [ContractualAvailabilityColumns.FIVE]: 4,
  [ContractualAvailabilityColumns.SIX]: 5,
  [ContractualAvailabilityColumns.SEVEN]: 6,
  [ContractualAvailabilityColumns.EIGHT]: 7,
  [ContractualAvailabilityColumns.NINE]: 8,
  [ContractualAvailabilityColumns.TEN]: 9,
  [ContractualAvailabilityColumns.ELEVEN]: 10,
};

export const ContractualAvailabilityWidget = ({
  id,
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
  timeAggr,
}) => {
  const { t, ready } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(() => data.map(({ id }) => id));

  const { getWidgetState, setWidgetState } = useContext(ReportsContext);
  const columnState = getWidgetState(id, TableWidgetStateKeys.COLUMN_CONFIG_STATE);

  const showSummaryColumn = useMemo(
    () =>
      columnState
        ?.find((colGrp) => colGrp.id === ContractualAvailabilityColumns.GROUP_ENTITY_AGGR_KPI)
        ?.cols.find((col) => col.id === ContractualAvailabilityColumns.ENTITY_WEIGHTED_KPI)
        ?.visible,
    [columnState],
  );

  const showSummaryRow = useMemo(
    () =>
      columnState
        ?.find((colGrp) => colGrp.id === ContractualAvailabilityColumns.GROUP_TIME_AGGR_KPI)
        ?.cols.find((col) => col.id === ContractualAvailabilityColumns.TIME_WEIGHTED_KPI)?.visible,
    [columnState],
  );

  const dynamicColumns = useMemo(() => {
    const requestedDates = data.reduce(
      (acc, { kpiValues }) => new Set([...acc, ...Object.keys(kpiValues)]),
      new Set(),
    );
    return [...requestedDates];
  }, [data]);

  // Update column configuration in widget state with dynamic column visibility
  useEffect(() => {
    if (!columnState) {
      // Don't create initial state here - wait for it to be initialized first.
      return;
    }

    const dynamicColumnsState = columnState.find(
      (colGrp) => colGrp.id === ContractualAvailabilityColumns.GROUP_DYNAMIC_COLUMNS,
    );
    let updated = false;
    dynamicColumnsState?.cols.forEach((dynCol) => {
      const visibility = DynamicColumnIndexes[dynCol.id] < dynamicColumns.length;
      if (dynCol.visible !== visibility) {
        dynCol.visible = visibility;
        updated = true;
      }
    });

    if (updated) {
      setWidgetState({
        widgetId: id,
        key: TableWidgetStateKeys.COLUMN_CONFIG_STATE,
        value: [...columnState],
      });
    }
  }, [columnState, dynamicColumns, id, setWidgetState]);

  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom table headers for provided columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case ContractualAvailabilityColumns.SELECTED:
          return <IncludeHeader>{t('contractual_availability.columns.include')}</IncludeHeader>;
        case ContractualAvailabilityColumns.ONE:
        case ContractualAvailabilityColumns.TWO:
        case ContractualAvailabilityColumns.THREE:
        case ContractualAvailabilityColumns.FOUR:
        case ContractualAvailabilityColumns.FIVE:
        case ContractualAvailabilityColumns.SIX:
        case ContractualAvailabilityColumns.SEVEN:
        case ContractualAvailabilityColumns.EIGHT:
        case ContractualAvailabilityColumns.NINE:
        case ContractualAvailabilityColumns.TEN:
        case ContractualAvailabilityColumns.ELEVEN: {
          const columnDate = dynamicColumns[DynamicColumnIndexes[columnKey]];
          if (columnDate) {
            switch (timeAggr) {
              case TimeAggr.MONTHLY:
                return dayjs(columnDate).format('MMM').toLocaleUpperCase();
              case TimeAggr.WEEKLY:
                return `WK ${dayjs(columnDate).isoWeek()}`;
              default:
            }
            return dayjs(columnDate).format(DateTimeFormats.DEFAULT_DATE);
          }
          break;
        }
        case ContractualAvailabilityColumns.ENTITY_WEIGHTED_KPI:
          // We don't want a label shown in the header here, but have to return something or a fallback value will be used.
          return <></>;
        default:
      }
    },
    [dynamicColumns, t, timeAggr],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue, rowData) => {
      const isSummaryRow = !!rowData?.totalValue;
      switch (columnKey) {
        case ContractualAvailabilityColumns.SELECTED:
          return (
            cellValue && (
              <StyledCheckbox
                onClick={(e) => {
                  killEventPropagation(e);
                  e.preventDefault();
                  toggleCheckedRow(e, cellValue);
                }}
              >
                <Checkbox
                  checkState={
                    rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                  }
                  label={''}
                />
              </StyledCheckbox>
            )
          );
        default:
          return isSummaryRow ? <SummaryRowValue>{cellValue}</SummaryRowValue> : cellValue;
      }
    },
    [rowsSelected, toggleCheckedRow],
  );

  const dynamicColumnsCellValueMapFn = useCallback(
    ({ kpiValues }) => {
      // This function eliminates duplicated cell-mapping logic for
      // dynamic columns that would otherwise look like a bunch of this:
      //
      // [ContractualAvailabilityColumns.ONE]:
      //    assetAvailabilityData.kpiValues[
      //      dynamicColumns[DynamicColumnIndexes[ContractualAvailabilityColumns.ONE]]
      //    ],
      return Object.fromEntries(
        Object.entries(DynamicColumnIndexes).map(([colKey, colIdx]) => [
          colKey,
          getDisplayValue(kpiValues[dynamicColumns[colIdx]]),
        ]),
      );
    },
    [dynamicColumns],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback(
    (assetAvailabilityData) => {
      const isSummaryRow = !!assetAvailabilityData.totalValue;
      if (isSummaryRow && !showSummaryRow) {
        return null;
      }
      return {
        [ContractualAvailabilityColumns.SELECTED]: isSummaryRow ? null : assetAvailabilityData.id,
        [ContractualAvailabilityColumns.ASSET]: isSummaryRow
          ? null
          : assetAvailabilityData.padNumber,
        [ContractualAvailabilityColumns.ENTITY_WEIGHTED_KPI]: isSummaryRow
          ? getDisplayValue(assetAvailabilityData.totalValue)
          : getDisplayValue(assetAvailabilityData.seriesWeightedValue),
        ...dynamicColumnsCellValueMapFn(assetAvailabilityData),
      };
    },
    [dynamicColumnsCellValueMapFn, showSummaryRow],
  );

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: contractualAvailabilityColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  if (!ready) {
    return <Loader />;
  }

  return (
    <Container summaryColumn={showSummaryColumn} summaryRow={showSummaryRow}>
      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty={'id'}
        isEditing={isEditing}
        variableHeight={true}
        vAlign={'middle'}
      />
    </Container>
  );
};

ContractualAvailabilityWidget.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.string),
  timeAggr: PropTypes.oneOf(Object.values(TimeAggr)).isRequired,
};
