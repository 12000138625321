import { useStoreState } from 'easy-peasy';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityType } from '@ge/models/constants';

import DashboardHeader from '../../dashboard/dashboard-header';

const siteId = '5000615';

export const AnalyzeAssetsWindHeader = () => {
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });
  const getOverviewBySite = useStoreState((state) => state.analyze.getOverviewBySite);
  const siteOverview = getOverviewBySite(siteId);

  return (
    <DashboardHeader
      title={t('assets', 'Assets')}
      type={EntityType.TURBINE}
      showParent={false}
      data={siteOverview && siteOverview.kpi}
    />
  );
};

AnalyzeAssetsWindHeader.propTypes = {};

AnalyzeAssetsWindHeader.defaultProps = {};
