import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useNotification } from '@ge/components/notification';
import { getMappedEventStatus } from '@ge/feat-monitor/models/event-type-status';
import {
  NotificationActionType,
  CasePatchCallStatus,
  NotificationType,
} from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens';

const NotificationMessage = styled.div`
  font: ${typography.weight.regular} 10px/15px ${typography.family.default};
`;

export const useCreateNotification = (currentEvent, updatedData, isError, error) => {
  const { t } = useTranslation(['monitor.assets'], {
    useSuspense: false,
  });
  const { showSiteDetails, showAssetDetails } = useContext(EntityDetailsContext);
  const { notify } = useNotification();

  const handleSiteClick = useCallback(
    (e, site) => {
      killEventPropagation(e);
      showSiteDetails(site.id);
    },
    [showSiteDetails],
  );

  const handleAssetClick = useCallback(
    (e, asset) => {
      killEventPropagation(e);
      showAssetDetails(asset.id);
    },
    [showAssetDetails],
  );

  const createNotification = useCallback(() => {
    const input_data = isError ? error?.response?.data : updatedData;
    if (!Array.isArray(input_data) || !input_data?.length) return;
    input_data?.map((data) => {
      const option = { actions: [] };
      const modifiedEvent = currentEvent.id === data.id && currentEvent;
      if (modifiedEvent?.site) {
        option.actions.push({
          label: modifiedEvent?.site?.name,
          type: NotificationActionType.SITE_DETAILS,
          onClick: (e) => handleSiteClick(e, modifiedEvent.site),
        });
      }
      if (modifiedEvent?.asset && modifiedEvent?.asset?.name) {
        option.actions.push({
          label: modifiedEvent?.asset?.name,
          type: NotificationActionType.TURBINE_DETAILS,
          onClick: (e) => handleAssetClick(e, modifiedEvent.asset),
        });
      }
      option.actions.push({
        label: modifiedEvent?.description,
        type: NotificationActionType.EVENT_DETAILS,
      });
      const mappedEventStatus = getMappedEventStatus(input_data?.[0]?.state);
      option.message = (
        <NotificationMessage>
          {!isError && data?.status === CasePatchCallStatus.SUCCESS
            ? `${t('status.success_in_update', 'Changed the state to')} "${t(
                mappedEventStatus?.translationKey,
              )?.toUpperCase()}"`
            : `${t('status.failed_to_update', 'Failed to change the state to')} "${t(
                mappedEventStatus?.translationKey,
              )?.toUpperCase()}"`}
        </NotificationMessage>
      );

      option.type =
        data?.status === CasePatchCallStatus.FAILURE
          ? NotificationType.ERROR
          : NotificationType.SUCCESS;

      notify(option);
    });
  }, [currentEvent, updatedData, error, isError, notify, t, handleAssetClick, handleSiteClick]);

  const addNoteNotification = useCallback(() => {
    const option = { actions: [] };
    if (currentEvent?.site) {
      option.actions.push({
        label: currentEvent?.site?.name,
        type: NotificationActionType.SITE_DETAILS,
        onClick: (e) => handleSiteClick(e, currentEvent.site),
      });
    }
    if (currentEvent?.asset) {
      option.actions.push({
        label: currentEvent?.asset?.name,
        type: NotificationActionType.TURBINE_DETAILS,
        onClick: (e) => handleAssetClick(e, currentEvent.asset),
      });
    }
    option.actions.push({
      label: currentEvent?.description,
      type: NotificationActionType.EVENT_DETAILS,
    });
    option.message = (
      <NotificationMessage>
        {t('status.failed_to_create_note', 'Failed to create note')}
      </NotificationMessage>
    );
    option.type = NotificationType.ERROR;

    notify(option);
  }, [t, currentEvent, notify, handleAssetClick, handleSiteClick]);

  return {
    createNotification,
    addNoteNotification,
  };
};
