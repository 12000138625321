import { createStore, thunk } from 'easy-peasy';

import analyzeModel from '@ge/feat-analyze/state';
// inspections state
import damagesModel from '@ge/feat-inspections/state/damage-model';
import defectModel from '@ge/feat-inspections/state/defect-model';
import inspectionsFeatureModel from '@ge/feat-inspections/state/feature-model';
import fileModel from '@ge/feat-inspections/state/file-model';
import inspectionsModel from '@ge/feat-inspections/state/inspection-model';
import overviewModel from '@ge/feat-inspections/state/overview-model';
import profileModel from '@ge/feat-inspections/state/profile-model';
import reportModel from '@ge/feat-inspections/state/report-model';
import sessionModel from '@ge/feat-inspections/state/session-model';
import siteManagerModel from '@ge/feat-manage/state';
import workersModel from '@ge/feat-manage/state/worker-model';
import monitorModel from '@ge/feat-monitor/state';
import appModel from '@ge/shared/state/app-model';
import assetModel from '@ge/shared/state/asset-model';
import auditHistoryModel from '@ge/shared/state/audit-history-model';
import authModel from '@ge/shared/state/auth-model';
import caseModel from '@ge/shared/state/case-model';
import customerModel from '@ge/shared/state/customer-model';
import issuesModel from '@ge/shared/state/issues-model';
import metricModel from '@ge/shared/state/metric-model';
import needsAttentionModel from '@ge/shared/state/needs-attention-model';
import regionModel from '@ge/shared/state/region-model';
import rocStationsModel from '@ge/shared/state/roc-stations-model';
import siteModel from '@ge/shared/state/site-model';
import summaryModel from '@ge/shared/state/summary-model';
import tabFlowModel from '@ge/shared/state/tabFlowModel';
import tasksModel from '@ge/shared/state/task-model';
import technicianModel from '@ge/shared/state/technician-model';
import tenantModel from '@ge/shared/state/tenant-model';
import ticketModel from '@ge/shared/state/ticket-model';
import userRoleModel from '@ge/shared/state/user-role-model';

import alertsModel from './app/state/alerts-model';
import errorsModel from './app/state/errors-model';
import prefsModel from './app/state/prefs-model';
import viewModel from './app/state/view-model';
import watchlistModel from './app/state/watchlist-model';

const storeModel = {
  // Global state
  app: appModel,
  assets: assetModel,
  auth: authModel,
  cases: caseModel,
  customers: customerModel,
  issues: issuesModel,
  metrics: metricModel,
  regions: regionModel,
  rocStations: rocStationsModel,
  sites: siteModel,
  summary: summaryModel,
  tasks: tasksModel,
  technicians: technicianModel,
  tickets: ticketModel,
  needsAttention: needsAttentionModel,
  tabflow: tabFlowModel,
  audit: auditHistoryModel,
  userRole: userRoleModel,
  // App-specific state
  alerts: alertsModel,
  errors: errorsModel,
  prefs: prefsModel,
  view: viewModel,
  watchlist: watchlistModel,

  // Feature-specific state
  monitor: monitorModel,
  analyze: analyzeModel,
  workers: workersModel,
  siteManager: siteManagerModel,

  // Inspections-specific state
  // TODO: reconcile this to main store and remove redundant fetches
  inspectionsFeature: inspectionsFeatureModel,
  overview: overviewModel,
  defect: defectModel,
  files: fileModel,
  damages: damagesModel,
  inspections: inspectionsModel,
  profile: profileModel,
  report: reportModel,
  session: sessionModel,
  tenant: tenantModel,
};

export default createStore({
  ...storeModel,
  reset: thunk((actions) => {
    actions.app.resetApp();
    actions.assets.resetAssets();
    actions.auth.resetAuth();
    actions.customers.resetCustomers();
    actions.issues.resetIssues();
    actions.metrics.resetMetrics();
    actions.regions.resetRegions();
    actions.sites.resetSites();
    actions.summary.resetSummary();
    actions.tickets.resetTickets();
    actions.tasks.resetTasks();
    actions.alerts.resetAlerts();
    actions.cases.resetCases();
    actions.monitor.resetMonitor();
    actions.analyze.resetAnalyze();
    actions.siteManager.resetSiteManager();
    actions.workers.resetWorker();
    // inspections
    actions.inspectionsFeature.resetApp();
    actions.overview.resetOverview();
    actions.defect.resetDefect();
    actions.files.resetFile();
    actions.inspections.resetInspections();
    actions.profile.resetProfile();
    actions.report.resetReport();
    actions.session.resetSession();
    actions.tenant.resetTenant();
  }),
});
