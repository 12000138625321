import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '@ge/components/notification';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { EntityType, TaskSourceField, CaseStatus } from '@ge/models/constants';
import { CaseLinkDialog } from '@ge/shared/components/cases/case-link-dialog';
import { CaseLinkProvider } from '@ge/shared/components/cases/case-link-provider';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import useCaseDetailDataByQuery from '@ge/shared/data-hooks/cases/use-case-detail-data-by-query';
import { useUpdateCase } from '@ge/shared/data-hooks/use-update-case';

function AssetCasesHandle({
  showNewTaskDialog,
  setShowNewTaskDialog,
  localCaseId,
  showLinkModal,
  setShowLinkModal,
}) {
  let { caseWithDetails } = useCaseDetailDataByQuery({
    caseId: localCaseId,
  });

  const { mutate: updateCase } = useUpdateCase();
  const { showCaseDetails } = useContext(EntityDetailsContext);
  const { t } = useTranslation(['general'], { useSuspense: false });

  const { updateCases, fetchCaseWithDetails } = useStoreActions((actions) => actions.cases);
  const { notify } = useNotification();

  const message = (
    <NotificationMessage message={t('creating_task', 'Creating Task').toUpperCase()} />
  );

  const handleSaveLink = (res) => {
    if (res?.id) {
      if (res.cases?.length > 0) {
        updateCases(res.cases);
        notify({ message });
      }
      setShowLinkModal(false);
      showCaseDetails(res.id);
    }
  };

  const handleSaveNewTask = (_, __, variables) => {
    setShowNewTaskDialog(false);
    if (!variables) return;
    if (caseWithDetails.status.toUpperCase() === CaseStatus.NEW) {
      updateCase({
        id: caseWithDetails.id,
        asset: {
          id: caseWithDetails.asset.id,
        },
        status: CaseStatus.IN_PROGRESS,
      });
    } else {
      fetchCaseWithDetails(caseWithDetails.id);
    }
  };

  if (caseWithDetails && showNewTaskDialog) {
    return (
      <NewTaskDialog
        entity={caseWithDetails}
        entityId={caseWithDetails.asset?.id}
        associationId={caseWithDetails.id}
        entityType={EntityType.CASE}
        onClose={() => {
          setShowNewTaskDialog(false);
        }}
        onConfirm={handleSaveNewTask}
        taskSource={TaskSourceField.MANUALSITE}
        title={caseWithDetails.description}
      />
    );
  }

  if (caseWithDetails && showLinkModal) {
    return (
      <CaseLinkProvider
        isOpen={showLinkModal}
        entity={caseWithDetails}
        onClose={() => {
          setShowLinkModal(false);
        }}
        onSave={handleSaveLink}
      >
        <CaseLinkDialog />
      </CaseLinkProvider>
    );
  } else return null;
}

AssetCasesHandle.propTypes = {
  setShowNewTaskDialog: PropTypes.any,
  showNewTaskDialog: PropTypes.any,
  localCaseId: PropTypes.string,
};

export default AssetCasesHandle;
