import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledKpi = styled.div`
  display: inline-block;
  .output-title {
    font-size: 10px;
    font-weight: 700;
    color: ${(props) => props.theme.assetCard.secondaryTextColor};
  }
  .value {
    font-size: 16px;
    display: inline-block;
  }
  .label {
    font-weight: 500;
    font-size: 10px;
    vertical-align: text-top;
    margin: 2px 0 0 2px;
    display: inline-block;
  }
  .footer {
    margin-top: 4px;
    color: ${(props) => props.theme.assetCard.secondaryTextColor};
    font-size: 12px;
  }
`;

export const Kpi = ({ className, title, value, label, footer }) => (
  <StyledKpi className={className}>
    <div className="output-title">{title}</div>
    <div className="value">{value}</div>
    <div className="label">{label}</div>
    <div className="footer">{footer}</div>
  </StyledKpi>
);

Kpi.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Kpi.defaultProps = {
  className: null,
};
