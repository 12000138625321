import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import {
  useQueryParams,
  BooleanParam,
  StringParam,
  encodeDelimitedArray,
  decodeDelimitedArray,
  withDefault,
} from 'use-query-params';

import useStateRef from '@ge/hooks/state-ref';
import { PresentationTypes, AssetOverviewTabs } from '@ge/models/constants';
import { useFeatureAssets } from '@ge/shared/data-hooks';

import { PowerCurveSparkData } from '../components/asset/power-curve-data';
import { DataExplorerTemplates, DataExplorerOptions } from '../models/data-explorer-defs';

import { AssetContext } from './assetContext';

const AssetArrayParam = {
  encode: (array) => encodeDelimitedArray(array, '__'),
  decode: (arrayStr) => decodeDelimitedArray(arrayStr, '__'),
};

export const AssetProvider = ({ children }) => {
  const datePickerRef = useRef(null);

  const getDataExplorerOverview = useStoreState(
    (actions) => actions.analyze.getDataExplorerOverview,
  );

  const dataExplorerState = useStoreState((state) => state.analyze.dataExplorer);
  const { updateDataExplorer } = useStoreActions((actions) => actions.analyze);

  const { isLoading, assets } = useFeatureAssets();

  const [queryParam, setQueryParam] = useQueryParams({
    assets: AssetArrayParam,
    tab: withDefault(StringParam, AssetOverviewTabs.DETAILS),
    layout: withDefault(StringParam, PresentationTypes.DETAIL),
    template: withDefault(StringParam, DataExplorerOptions.AD_HOC),
    caseId: StringParam,
    enableMultiAxis: withDefault(BooleanParam, true),
  });

  const [lastAssetParam, setLastAssetParam] = useStateRef(undefined);

  useEffect(() => {
    const explorerOverviewData = getDataExplorerOverview(queryParam.assets) || [];

    setAsset((prevState) => ({
      ...prevState,
      assets: explorerOverviewData.assets,
      signalData: explorerOverviewData?.signals ?? [],
      comparisonAssets:
        queryParam.assets?.length === 1 ? queryParam.assets || [] : prevState.comparisonAssets,
      selectedAssetId:
        prevState.selectedAssetId && queryParam?.assets?.includes(prevState.selectedAssetId)
          ? prevState.selectedAssetId
          : queryParam?.assets?.[0],
    }));
  }, [queryParam.assets, getDataExplorerOverview]);

  // TODO: update data to pull from state.
  const data = {};

  // TODO: update from mocks
  const sparkData = PowerCurveSparkData;

  // TODO: need to address how to calculate the total
  const totalLoss = 32;

  const onSelect = (id) => {
    setAsset((prevState) => ({
      ...prevState,
      powerCurveId: id,
    }));
  };

  const setAssetsParam = useCallback(
    (assets) => {
      setQueryParam({ assets: assets });
      setLastAssetParam(assets);
    },
    [setLastAssetParam, setQueryParam],
  );

  const setTabsParam = (tab) => {
    setQueryParam({ tab });
  };

  const setLayoutParam = (layout) => {
    setQueryParam({ layout });
  };

  const setTemplateParam = (template) => {
    setQueryParam({ template });
  };

  const setDataExplorerCase = (id) => {
    setQueryParam({
      tab: AssetOverviewTabs.DATA_EXPLORER,
      caseId: id,
    });
  };

  const setChartsArray = (charts) => {
    setAsset((prevState) => ({
      ...prevState,
      chartsArray: charts,
    }));
  };

  const addChartToArray = (chart) => {
    setAsset((prevState) => ({
      ...prevState,
      chartsArray: [...prevState.chartsArray, chart],
    }));
  };

  const setSelectedChart = (chart) => {
    setAsset((prevState) => ({
      ...prevState,
      selectedChart: chart,
    }));
  };

  const setSelectedAsset = (id) => {
    setAsset((prevState) => ({
      ...prevState,
      selectedAssetId: id,
    }));
  };

  const setComparisonAssets = (assets) => {
    setAsset((prevState) => ({
      ...prevState,
      comparisonAssets: assets,
    }));
  };

  const setChartFilter = (filter) => {
    setAsset((prevState) => ({
      ...prevState,
      chartFilter: filter,
    }));
  };

  const setDateRange = (start, end) => {
    setAsset((prevState) => ({
      ...prevState,
      startDate: dayjs(start).startOf('date').toDate(),
      endDate: end ? dayjs(end).endOf('date').toDate() : dayjs(start).endOf('date').toDate(),
    }));
  };

  const setKpiDateRange = (kpiDateRange) => {
    setAsset((prevState) => ({
      ...prevState,
      kpiDateRange,
    }));
  };

  const setIecData = (data) => {
    setAsset((prevState) => ({
      ...prevState,
      data,
    }));
  };

  // Remove query assets that aren't in the current view
  useEffect(() => {
    if (isLoading) return;
    if (queryParam.assets) {
      const viewIds = assets.map((a) => a.id);
      if (!queryParam.assets.every((id) => viewIds.includes(id))) {
        setAssetsParam(queryParam.assets.filter((id) => viewIds.includes(id)));
      }
    } else {
      setAssetsParam(lastAssetParam);
    }
  }, [assets, isLoading, lastAssetParam, queryParam.assets, setAssetsParam]);

  const selectedChartInitialValue =
    dataExplorerState.template === queryParam.template && dataExplorerState.selectedChart
      ? dataExplorerState.selectedChart
      : DataExplorerTemplates[queryParam.template].selectedChart;

  const chartsArrayInitialValue =
    dataExplorerState.template === queryParam.template && dataExplorerState.chartsArray
      ? dataExplorerState.chartsArray
      : DataExplorerTemplates[queryParam.template].charts;

  const defaultState = {
    assets: null,
    comparisonAssets: queryParam.assets || [],
    selectedIndex: null,
    powerCurveId: null,
    selectedCaseId: null,
    selectedAssetId: (queryParam?.assets && queryParam.assets[0]) || null,
    startDate: dayjs().subtract(4, 'd').startOf('date').toDate(),
    endDate: dayjs().endOf('date').toDate(),
    data,
    setIecData,
    sparkData,
    signalData: [],
    chartsArray: chartsArrayInitialValue,
    selectedChart: selectedChartInitialValue,
    chartFilter: null,
    onSelect,
    totalLoss,
    datePickerRef,
    kpiDateRange: {
      startDate: {},
      endDate: {},
    },
    setAssetsParam,
    setTabsParam,
    setLayoutParam,
    setDataExplorerCase,
    setTemplateParam,
    setSelectedChart,
    setChartsArray,
    addChartToArray,
    setSelectedAsset,
    setComparisonAssets,
    setChartFilter,
    setDateRange,
    setKpiDateRange,
  };

  const [assetState, setAsset] = useState(defaultState);

  const providerValue = useMemo(
    () => ({ assetState, setAsset, queryParam }),
    [assetState, setAsset, queryParam],
  );

  useEffect(() => {
    updateDataExplorer({
      template: queryParam.template,
      chartsArray: assetState.chartsArray,
      selectedChart: assetState.selectedChart,
    });
  }, [updateDataExplorer, queryParam.template, assetState.chartsArray, assetState.selectedChart]);

  return <AssetContext.Provider value={providerValue}>{children}</AssetContext.Provider>;
};

AssetProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

AssetProvider.defaultProps = {
  children: null,
};
