import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';

// All available columns and groups for assets of concern
export const AssetsOfConcernColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  // Asset Details
  GROUP_ASSET_DETAILS: 'Asset Details',
  ASSET_NAME: 'Asset Name',
  ASSET_SITE: 'Site',
  ASSET_STATE: 'State',

  // Cases and Events
  GROUP_CASES_EVENTS: 'Cases and Events',
  CASES_EVENTS_FAULT: 'Fault',
  CASES_EVENTS_EVENT: 'Event',
  CASES_EVENTS_DURATION: 'Duration',
};

// Assets of Concern table configuration
export const assetsOfConcernColumnDefs = {
  [AssetsOfConcernColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [AssetsOfConcernColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [AssetsOfConcernColumns.GROUP_ASSET_DETAILS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'assets_of_concern.columns.asset_details',
        a11yDefault: 'Asset Details',
      },
    ],
    cols: {
      [AssetsOfConcernColumns.ASSET_NAME]: {
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.asset_name',
            a11yDefault: 'Asset',
          },
        ],
      },
      [AssetsOfConcernColumns.ASSET_SITE]: {
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.asset_site',
            a11yDefault: 'Site',
          },
        ],
      },
      [AssetsOfConcernColumns.ASSET_STATE]: {
        align: 'center',
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.asset_state',
            a11yDefault: 'State',
          },
        ],
      },
    },
  },
  [AssetsOfConcernColumns.GROUP_CASES_EVENTS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'assets_of_concern.columns.cases_events',
        a11yDefault: 'Cases and Events',
      },
    ],
    cols: {
      [AssetsOfConcernColumns.CASES_EVENTS_FAULT]: {
        cell: {
          width: '100px',
        },
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.fault_code',
            a11yDefault: 'Fault Code',
          },
        ],
      },
      [AssetsOfConcernColumns.CASES_EVENTS_EVENT]: {
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.event',
            a11yDefault: 'Event',
          },
        ],
      },
      [AssetsOfConcernColumns.CASES_EVENTS_DURATION]: {
        cell: {
          width: '100px',
        },
        labels: [
          {
            a11yKey: 'assets_of_concern.columns.duration',
            a11yDefault: 'Duration',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const assetsOfConcernOmittedCols = [
  AssetsOfConcernColumns.GROUP_SELECTED,
  AssetsOfConcernColumns.SELECTED,
  AssetsOfConcernColumns.ASSET_NAME,
];

// Default order and visibility configuration for assets of concern columns
// NOTE: For state selection, EVERY column must have a position and visibility default!
export const assetsOfConcernDefaultCols = [
  {
    id: AssetsOfConcernColumns.GROUP_SELECTED,
    cols: [
      {
        id: AssetsOfConcernColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: AssetsOfConcernColumns.GROUP_ASSET_DETAILS,
    cols: [
      {
        id: AssetsOfConcernColumns.ASSET_NAME,
        visible: true,
      },
      {
        id: AssetsOfConcernColumns.ASSET_STATE,
        visible: true,
      },
      {
        id: AssetsOfConcernColumns.ASSET_SITE,
        visible: false,
      },
    ],
  },
  {
    id: AssetsOfConcernColumns.GROUP_CASES_EVENTS,
    cols: [
      {
        id: AssetsOfConcernColumns.CASES_EVENTS_FAULT,
        visible: true,
      },
      {
        id: AssetsOfConcernColumns.CASES_EVENTS_EVENT,
        visible: true,
      },
      {
        id: AssetsOfConcernColumns.CASES_EVENTS_DURATION,
        visible: false,
      },
    ],
  },
];
