import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const CMNU_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const postNewPerson = async (newperson) =>
  request.post('/cmn/allpeople', newperson, { baseURL: CMNU_BASE_URL });

export const useCreateNewPerson = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const {
    mutate: create,
    isLoading: personCreating,
    status,
    isError,
    error,
  } = useMutation((person) => postNewPerson(person), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.WORKER]);
      queryClient.invalidateQueries([QueryKey.INFINITE_PEOPLE]);
      onSuccess();
    },
    onError: (error) => {
      console.log(JSON.stringify(error.response.data));
    },
  });

  return { create, personCreating, status, isError, error };
};
