import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { getServiceGroupIds } from '../util/worker-util';

export const useWorkers = (startDate, endDate, _serviceGroupIds, opts = {}, queryKey) => {
  const types = 'Worker';
  const pageIdx = 0;
  const pageSize = 5000;
  const sortKey = 'id';
  const orderDirection = 'ASC';

  //Gives list of service groups
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  //Gives list of sites currently selected
  const { currentView } = useStoreState((state) => state.view);

  const serviceGroupIds = (
    _serviceGroupIds ?? getServiceGroupIds(sortedServiceGroups, currentView)
  ).toString();

  const { fetchAllWorkers } = useStoreActions((store) => store.workers);
  const workerData = useStoreState((state) => state.workers);

  const { isFetching, isError, error } = useQuery(
    [QueryKey.ERP_WORKER, { endDate, serviceGroupIds, startDate }, queryKey],
    () =>
      fetchAllWorkers({
        serviceGroupIds,
        types,
        pageIdx,
        pageSize,
        sortKey,
        orderDirection,
        ...(startDate && { start: startDate }),
        ...(endDate && { end: endDate }),
      }),
    {
      ...opts,
      enabled: Boolean(serviceGroupIds?.length > 0),
    },
  );

  return useMemo(
    () => ({ data: workerData.workers, isFetching, isError, error }),
    [workerData, error, isError, isFetching],
  );
};
