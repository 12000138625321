import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';

// All available columns and groups for site roster
export const SiteRosterColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  // Initial Details
  GROUP_WORKER: 'Worker',
  INITIALS: 'Initials',
  WORKER: 'Worker Name',
  CREW: 'Crew',
  ASSET_NAME: 'Asset',
  JOB_TITLE: 'Job Title',
  PHONE_NUMBER: 'Phone Number',
  EMAIL: 'Email',
  AVAILABILITY: 'Availability',
};

// Site Roster table configuration
export const siteRosterColumnDefs = {
  [SiteRosterColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [SiteRosterColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'site_roster.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [SiteRosterColumns.GROUP_WORKER]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'site_roster.columns.worker_details',
        a11yDefault: 'Worker Details',
      },
    ],
    cols: {
      [SiteRosterColumns.WORKER]: {
        locked: true,
        cell: {
          width: '100px',
        },
        labels: [
          {
            a11yKey: 'site_roster.columns.worker',
            a11yDefault: 'Worker Name',
          },
        ],
      },
      [SiteRosterColumns.INITIALS]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'site_roster.columns.initials',
            a11yDefault: 'Initials',
          },
        ],
      },
      [SiteRosterColumns.CREW]: {
        labels: [
          {
            a11yKey: 'site_roster.columns.crew',
            a11yDefault: 'Crew',
          },
        ],
      },
      [SiteRosterColumns.ASSET_NAME]: {
        labels: [
          {
            a11yKey: 'site_roster.columns.asset_name',
            a11yDefault: 'Asset',
          },
        ],
      },
      [SiteRosterColumns.JOB_TITLE]: {
        labels: [
          {
            a11yKey: 'site_roster.columns.job_title',
            a11yDefault: 'Job Title',
          },
        ],
      },
      [SiteRosterColumns.PHONE_NUMBER]: {
        labels: [
          {
            a11yKey: 'site_roster.columns.phone_number',
            a11yDefault: 'Phone Number',
          },
        ],
      },
      [SiteRosterColumns.EMAIL]: {
        labels: [
          {
            a11yKey: 'site_roster.columns.email',
            a11yDefault: 'Email',
          },
        ],
      },
      [SiteRosterColumns.AVAILABILITY]: {
        labels: [
          {
            a11yKey: 'site_roster.columns.availability',
            a11yDefault: 'Availability',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const siteRosterOmittedCols = [
  SiteRosterColumns.GROUP_SELECTED,
  SiteRosterColumns.SELECTED,
  SiteRosterColumns.AVAILABILITY,
];

// Default order and visibility configuration for planned work columns
export const siteRosterDefaultCols = [
  {
    id: SiteRosterColumns.GROUP_SELECTED,
    cols: [
      {
        id: SiteRosterColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: SiteRosterColumns.GROUP_WORKER,
    cols: [
      {
        id: SiteRosterColumns.WORKER,
        visible: true,
      },
      {
        id: SiteRosterColumns.INITIALS,
        visible: true,
      },
      {
        id: SiteRosterColumns.CREW,
        visible: true,
      },
      {
        id: SiteRosterColumns.ASSET_NAME,
        visible: true,
      },
      {
        id: SiteRosterColumns.JOB_TITLE,
        visible: true,
      },
      {
        id: SiteRosterColumns.PHONE_NUMBER,
        visible: true,
      },
      {
        id: SiteRosterColumns.EMAIL,
        visible: true,
      },
      {
        id: SiteRosterColumns.AVAILABILITY,
        visible: false,
      },
    ],
  },
];
