import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

const ExportIcon = styled(Icon).attrs(() => ({
  size: 14,
  color: globalColors.slate4,
  viewbox: '0 0 12 12',
}))``;

const StyledExportButton = styled.button`
  position: relative;
  @media (min-width: 1145px) {
    /* top: -6.5px; */
  }
  svg {
    /* margin-right: 6px; */
    transform: rotateX(180deg);
  }
`;

const ImportTaskButton = () => {
  return (
    <>
      <StyledExportButton type="button">
        <ExportIcon icon={Icons.EXPORT} />
      </StyledExportButton>
    </>
  );
};

export default ImportTaskButton;
