import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetContext } from '@ge/feat-analyze/context/assetContext';
import {
  StorageAssetKpiHeaderCategories,
  StorageAssetKpiHeaderCategoriesApi,
  EntityType,
  QueryKey,
} from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';

import { useGetData } from '../../data-hooks/use-get-data';
import useKpiHeaderMenu from '../../data-hooks/use-kpi-header-menu';
import { DefaultStorageAssetKpiVisibility } from '../../models/kpi-header-visibility-defaults';
import { fetchAggregatedKpisStorage } from '../../services';
import DashboardHeader from '../dashboard/dashboard-header';
import { SiteHeaderStyledSolar } from '../shared/styled/site-header-styled';

export const AssetOverviewStorageHeader = () => {
  const { ready, t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });

  const assetOverviewCategories = [...StorageAssetKpiHeaderCategories];

  const { assetState } = useContext(AssetContext);

  const {
    kpiDateRange: { startDate, endDate },
  } = assetState;

  const asset = assetState.assets && assetState.assets[0];
  const { data: siteAggregatedKpis } = useGetData(
    {
      assetId: [assetState?.selectedAssetId],
      startDate: startDate.entityTimezone,
      endDate: endDate.entityTimezone,
      kpiList: [StorageAssetKpiHeaderCategoriesApi],
      assetType: EntityType.INVERTER,
    },
    fetchAggregatedKpisStorage,
    QueryKey.SITE_KPI_DATA,
  );
  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_ASSETS,
    defaultVisibility: DefaultStorageAssetKpiVisibility,
    kpiCategories: StorageAssetKpiHeaderCategories,
  });

  let _siteAggregatedKpis = {};
  if (siteAggregatedKpis?.data?.kpis) {
    const { efficiency, activeEnergyUsage, reactiveEnergyUsage } = siteAggregatedKpis.data.kpis;
    _siteAggregatedKpis = {
      ...siteAggregatedKpis.data.kpis,
      efficiencyStorage: {
        value: `${efficiency?.charge?.value}/${efficiency?.discharge?.value}`,
        unit: efficiency?.charge?.unit,
      },
      activeEnergyUsage: {
        value: `${activeEnergyUsage?.charge?.value}/${activeEnergyUsage?.discharge?.value}`,
        unit: activeEnergyUsage?.charge?.unit,
      },
      reactiveEnergyUsage: {
        value: `${reactiveEnergyUsage?.charge?.value}/${reactiveEnergyUsage?.discharge?.value}`,
        unit: reactiveEnergyUsage?.charge?.unit,
      },
    };
  }
  const unitObject = {};
  Object.entries(_siteAggregatedKpis).forEach((item) => {
    Object.assign(unitObject, { [item[0]]: item[1]?.unit });
  });

  if (!ready) return null;

  const isAssetSelected = !!assetState.comparisonAssets?.length;

  return (
    <SiteHeaderStyledSolar>
      <DashboardHeader
        title={t('asset_overview', 'Asset Overview')}
        type={EntityType.ASSET}
        showParent={assetState.comparisonAssets?.length === 1}
        entity={asset}
        data={_siteAggregatedKpis}
        onMenuClose={handleKpiChanges}
        visibleKpiPrefs={visibleKpis}
        kpiCategories={assetOverviewCategories}
        unitObject={unitObject}
        showStats={isAssetSelected}
      />
    </SiteHeaderStyledSolar>
  );
};

AssetOverviewStorageHeader.propTypes = {};

AssetOverviewStorageHeader.defaultProps = {};
