import * as request from '@ge/shared/services/api';

export const fetchWatchlist = async () => request.get('/watchlist');

export const updateWatchlist = async (aWatchlist) => {
  const { watchlist } = await request.put('/watchlist', aWatchlist);

  return watchlist;
};

// TODO: This is a place where it will probably make sense to
// set up some pub/sub websocket updates so the watchlist remains
// up-to-date as data changes underneath in real-time.

/**
 * Dynamically load the watchlist mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/watchlist/watchlist.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
