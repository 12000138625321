import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { DateTimeFormats, Placements } from '@ge/models/constants';

const CalendarIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CALENDAR,
  color: props.theme.filterMenu.searchIcon,
}))`
  position: absolute;
  top: 10px;
  right: 7px;
`;

const DateLabel = styled.div`
  position: relative;
  height: 22px;
  padding: 6px 20px 6px 10px;
  color: ${(props) => props.theme.filterMenu.textColor};
  font-size: 12px;
  line-height: 22px;
  border-top: 1px solid ${(props) => props.theme.filterMenu.inputBorderColor};
`;

export const FilterDatepicker = ({ onChange, value, isMaxDateRequired }) => {
  const [startDate, endDate] = value ?? [];
  var maxDateValue = null;
  if (isMaxDateRequired) {
    maxDateValue = new Date();
  }

  const getCustomDateString = () => {
    // check or end date and if they match dont show for single date selection
    const start = dayjs(startDate).format(DateTimeFormats.DEFAULT_DATE);
    const end = endDate ? dayjs(endDate).format(DateTimeFormats.DEFAULT_DATE) : null;

    return `${start} ${end && start !== end ? `- ${end}` : ''}`;
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={(dates) => onChange(dates)}
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDateValue}
        selectsRange
        shouldCloseOnSelect={false}
        popperPlacement={Placements.BOTTOM_START}
        inline
      />
      <DateLabel>
        {getCustomDateString()} <CalendarIcon />
      </DateLabel>
    </div>
  );
};

FilterDatepicker.propTypes = {
  isMaxDateRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

FilterDatepicker.defaultProps = {
  value: [
    dayjs()
      .startOf('date')
      .toDate(),
    dayjs()
      .endOf('date')
      .toDate(),
  ],
  isMaxDateRequired: false,
};
