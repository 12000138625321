import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { DataLoader } from '@ge/components/data-loader';
import { Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  useGetROCActionTakenOptions,
  useCreateROCActionLog,
} from '@ge/feat-monitor/data-hooks/use-roc-logs-actions';
import {
  EntityType,
  RocLogsActionType,
  NotificationActionType,
  NotificationType,
} from '@ge/models/constants';
import { DateTimeMetaField } from '@ge/shared/components/meta-fields';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { DescriptionField } from '@ge/shared/dynamic-template/form-fields/description-field';
import { Form, FormCol, FormRow } from '@ge/shared/dynamic-template/form-shared';
import { getUserName } from '@ge/shared/services/auth';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

const formMetadata = {
  dateTime: {
    readOnly: false,
    required: false,
    defaultSelection: 'currentDate',
  },
};

const FormSchema = {
  DESCRIPTION: 'description',
  ENTITY_ID: 'entityId',
  ENTITY_TYPE: 'entityType',
  ACTION_TYPE: 'actionType',
  ACTIONS: 'actions',
};

const RocLogDialogSegment = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  margin-top: 20px;
  padding: 20px 25px 10px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const RocLogFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const StyledInput = styled(Input)``;
const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  font: 12px;
  line-height: 14px;
  font-weight: 300;
`;
const ActionTakenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: calc(15px * 10 + 10rem);
  align-content: flex-start;
  div {
    width: 50%;
    height: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
    span {
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
      color: ${(props) => props.theme.entityDetails.notes.entityDescription};
      margin-left: 5px;
    }
    div {
      width: 14px;
      height: 14px;
      margin-bottom: 0px;
    }
  }
`;
const StyledLabel = styled.div`
  display: block;
  font-family: Museo Sans;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;
const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;
const ScrollingWrapper = styled.div`
  display: flex;
  min-height: 50vh;
  overflow: hidden;
  margin-bottom: 20px;

  ${scrollbarWrapperOverride}
`;

const ScrollingFormContainer = styled.div`
  padding: 0 25px;
  .validation-error {
    color: ${(props) => props.theme.dangerColor};
  }
`;

const NotificationMessage = styled.div`
  font: ${typography.weight.regular} 10px/15px ${typography.family.default};
`;

// RocLogsAction component
export const RocLogsAction = ({ onClose, onConfirm, entity, siteType, entityType, mode }) => {
  const { t, ready } = useTranslation(['monitor.issues'], { useSuspense: false });

  // State
  const [actionsTaken, setActionsTaken] = useState([]);
  const [createROCActionLogResponse, setCreateROCActionLogResponse] = useState();
  const [isFormValidationError, setIsFormValidationError] = useState(false);

  const { showSiteDetails, showAssetDetails } = useContext(EntityDetailsContext);

  const entityDetails = {
    assetType: entity?.asset?.type,
    siteType: siteType,
    siteName: entity?.site?.name,
    assetName: entity?.asset?.name,
    entityDescriptionIcon: Icons.EVENT,
    entityDescription: entity?.description,
  };

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { [FormSchema.ACTION_TYPE]: RocLogsActionType.ACTION_TAKEN },
  });

  const { control, handleSubmit, register, setValue } = methods;
  useWatch({ control, name: 'createdTime' });

  const entityId = entity?.id;

  const {
    data: actions,
    isLoading,
    error,
  } = useGetROCActionTakenOptions({
    assetMake: entity?.asset?.make,
    assetModel: entity?.asset?.model,
    controllerType: entity?.asset?.controllerType,
    assetType: entity?.asset?.type,
  });

  const {
    create: createROCActionLog,
    isError,
    error: createROCActionLogError,
  } = useCreateROCActionLog({
    onSuccess: (output) => {
      setCreateROCActionLogResponse(output);
    },
  });

  const { notify } = useNotification();

  const modifyActionTaken = useCallback(
    (value) => {
      const arr = [...actionsTaken];
      var index = arr.indexOf(value);
      index === -1 ? arr.push(value) : arr.splice(index, 1);
      setActionsTaken(arr);
    },
    [actionsTaken],
  );

  const onSubmit = useCallback(
    (values) => {
      if (values?.description || actionsTaken?.length) {
        createROCActionLog({ ...values, actionsTaken, entity });
        setIsFormValidationError(false);
      } else {
        setIsFormValidationError(true);
      }
    },
    [actionsTaken, entity, createROCActionLog],
  );

  const createNotification = useCallback(() => {
    const option = { actions: [] };
    if (entity?.site) {
      option.actions.push({
        label: entity.site?.name,
        type: NotificationActionType.SITE_DETAILS,
        onClick: (e) => {
          killEventPropagation(e);
          showSiteDetails(entity.site.id);
        },
      });
    }
    if (entity?.asset) {
      option.actions.push({
        label: entity.asset?.name,
        type: NotificationActionType.TURBINE_DETAILS,
        onClick: (e) => {
          killEventPropagation(e);
          showAssetDetails(entity.asset.id);
        },
      });
    }
    option.actions.push({
      label: entity?.description,
      type: NotificationActionType.EVENT_DETAILS,
    });
    option.message = (
      <NotificationMessage>
        {isError
          ? `${t('rocLogsAction.failed_to_create', 'Failed to create ROC Action Log. ')} ${
              createROCActionLogError?.response?.data?.error_message ||
              createROCActionLogError?.response?.data?.message
            }`
          : `${t('rocLogsAction.success_in_create', 'ROC Action log created successfully.')}`}
      </NotificationMessage>
    );
    option.type = isError ? NotificationType.ERROR : NotificationType.SUCCESS;
    notify(option);
  }, [entity, isError, createROCActionLogError, notify, t, showAssetDetails, showSiteDetails]);

  // bind hidden/programmatic form values in here
  useEffect(() => {
    // tried to streamline these by setting value in register options but didn't work
    register(FormSchema.ENTITY_ID);
    setValue(FormSchema.ENTITY_ID, entityId);

    register(FormSchema.ENTITY_TYPE);
    setValue(FormSchema.ENTITY_TYPE, entityType);
  }, [entityId, entityType, register, setValue]);

  useEffect(() => {
    if (createROCActionLogResponse || isError) {
      createNotification();
      onConfirm();
    }
  }, [createROCActionLogResponse, isError, createNotification, onConfirm]);

  const getFooter = useMemo(() => {
    return (
      <RocLogFooterWrapper>
        <FooterButtons>
          <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary disabled={actionsTaken?.length === 0} onClick={handleSubmit(onSubmit)}>
            {t('general:save', 'Save')}
          </Button>
        </FooterButtons>
      </RocLogFooterWrapper>
    );
  }, [onClose, t, handleSubmit, onSubmit, actionsTaken]);

  // TOOD: handle errors from backend
  if (error?.template) {
    return null;
  }

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onConfirm}
      header={t('rocLogsAction.log_action', 'LOG ACTION')}
      footer={getFooter}
      padContent={false}
    >
      {entityType === EntityType.CASE && (
        <RocLogDialogSegment>
          <EntityDialogHeader entityDetails={entityDetails} />
        </RocLogDialogSegment>
      )}
      <ScrollingWrapper>
        <ScrollingContainer>
          <ScrollingFormContainer>
            <Form>
              <FormProvider {...methods}>
                <form>
                  <FormRow>
                    <FormCol span="4">
                      <StyledInput
                        name="createdBy"
                        label={t('rocLogsAction.form.createdBy.label', 'Created By')}
                        defaultValue={getUserName()}
                        ref={register}
                        disabled
                      />
                    </FormCol>
                    <FormCol span="8">
                      <DateTimeMetaField
                        defaultSelection={formMetadata.dateTime.defaultSelection}
                        name="createdTime"
                        label={t('rocLogsAction.form.createdTime.label', 'Created Time')}
                        metadata={formMetadata.dateTime}
                      />
                    </FormCol>
                  </FormRow>
                  <DataLoader isLoading={isLoading} type="table" renderCondition>
                    <div>
                      <StyledLabel>
                        {t('rocLogsAction.form.actionTaken.label', 'Action Taken')}
                        <span className={isFormValidationError ? 'validation-error' : ''}>
                          {`* ${
                            isFormValidationError
                              ? t(
                                  'rocLogsAction.form.actionTaken.validation_error_message',
                                  '(Please select at least one checkbox or add a description)',
                                )
                              : ''
                          }`}
                        </span>
                      </StyledLabel>
                      <ActionTakenContainer>
                        {actions?.length &&
                          actions?.map((action) => {
                            return (
                              <div key={action}>
                                <StyledCheckbox
                                  label={action}
                                  checkState={
                                    actionsTaken.includes(action)
                                      ? CheckedState.CHECKED
                                      : CheckedState.UNCHECKED
                                  }
                                  onChange={() => modifyActionTaken(action)}
                                />
                              </div>
                            );
                          })}
                      </ActionTakenContainer>
                    </div>
                  </DataLoader>
                  <FormRow className="description">
                    <FormCol span="12">
                      <DescriptionField
                        mode={mode}
                        name={FormSchema.DESCRIPTION}
                        namespace={{ name: 'monitor.issues', key: 'rocLogsAction' }}
                      />
                    </FormCol>
                  </FormRow>
                </form>
              </FormProvider>
            </Form>
          </ScrollingFormContainer>
        </ScrollingContainer>
      </ScrollingWrapper>
    </Dialog>
  );
};

RocLogsAction.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
  siteType: PropTypes.string,
  isOpen: PropTypes.bool,
  mode: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

RocLogsAction.defaultValues = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
};
