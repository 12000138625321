import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { globalColors } from '@ge/tokens/colors';

import { ReactComponent as FourOhFourSVG } from './404.svg';

const Styled404 = styled.div`
  background-color: ${globalColors.grey};
  width: 336px;
  height: 350px;
  margin: auto;
`;

const FourOhFourComponent = () => {
  return (
    <PageContainer>
      <Styled404>
        <FourOhFourSVG />
      </Styled404>
    </PageContainer>
  );
};

FourOhFourComponent.propTypes = {
  theme: PropTypes.object,
};

FourOhFourComponent.defaultProps = {
  theme: {},
};

export const FourOhFour = withTheme(FourOhFourComponent);
