import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Severity } from '@ge/components/severity';
import {
  NAMESPACE,
  Fields,
  SourceMapping,
  RootCauseOptions,
  RootCauseOption,
} from '@ge/feat-analyze/models/configure-case-template';
import { CasePriority } from '@ge/models/constants';
import {
  SelectMetaField,
  MultiSelectMetaField,
  TextMetaField,
  ToggleButtonMetaField,
  TagsMetaField,
} from '@ge/shared/components/meta-fields';
import { TextareaMetaField } from '@ge/shared/components/meta-fields/textarea-meta-field';
import { useComponentHierarchies } from '@ge/shared/data-hooks/use-component-hierarchy';

import { FormRow, FormCollapsiblePanel } from '../../shared';

const PrioritySelectLabel = styled.span`
  margin-left: 5px;
  text-transform: capitalize;
`;

const TagsInput = styled(TagsMetaField)``;

const Input = styled(TextMetaField)``;

const Select = styled(SelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const MultiSelect = styled(MultiSelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const ToggleButton = styled(ToggleButtonMetaField)`
  .toggle-switch {
    border: none;
  }
`;

const toComponentSelectOption = (v) => ({ value: v.name, label: v.name });

const CaseDetailForm = ({ isOpen }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { data } = useComponentHierarchies();

  const { watch } = useFormContext();
  const selectedComponent1 = watch(Fields.COMPONENT_L1);
  const selectedComponent2 = watch(Fields.COMPONENT_L2);
  const selectedRootCauses = watch(Fields.ROOT_CAUSES);

  const component1Options = useMemo(() => data?.map(toComponentSelectOption) ?? [], [data]);
  const component2Options = useMemo(
    () =>
      data?.find((v) => v.name === selectedComponent1)?.children.map(toComponentSelectOption) ?? [],
    [selectedComponent1, data],
  );
  const component3Options = useMemo(
    () =>
      data
        ?.find((v) => v.name === selectedComponent1)
        ?.children?.find((v) => v.name === selectedComponent2)
        ?.children.map(toComponentSelectOption) ?? [],
    [selectedComponent1, selectedComponent2, data],
  );

  const priorityOptions = useMemo(
    () =>
      Object.values(CasePriority).map((value) => {
        return {
          value,
          label: (
            <>
              <Severity level={value} />
              <PrioritySelectLabel>{value}</PrioritySelectLabel>
            </>
          ),
        };
      }),
    [],
  );

  const sourceOptions = useMemo(
    () =>
      Object.entries(SourceMapping).map(([key, value]) => {
        return {
          value,
          label: t('case_template.dynamic.' + key, value),
        };
      }),
    [t],
  );

  const rootCauses = useMemo(() => RootCauseOptions, []);

  if (!ready) return null;

  return (
    <FormCollapsiblePanel
      isOpen={isOpen}
      title={t('case_template.case_detail', 'Case Detail')}
      allowOverflow={true}
    >
      <FormRow>
        <Input
          name={Fields.CASE_TEMPLATE_ID}
          label={t('case_template.case_template_id', 'Case Template Id')}
          placeholder={t(`case_template.case_template_id_placeholder`, 'Type case template id...')}
          defaultValue=""
          metadata={{ required: true }}
        />
        <Input
          name={Fields.CASE_TITLE}
          label={t('case_template.case_title', 'Case Title')}
          placeholder={t(`case_template.case_title_placeholder`, 'Type case title...')}
          defaultValue=""
          metadata={{ required: true }}
        />
      </FormRow>
      <FormRow>
        <Select
          name={Fields.COMPONENT_L1}
          label={t('case_template.component_l1', 'Component L1')}
          placeholder={t(`case_template.component_l1_placeholder`, 'Please select L1 component')}
          defaultValue={null}
          options={component1Options}
          metadata={{ required: true }}
          maxWidth={144}
        />
        <Select
          name={Fields.COMPONENT_L2}
          label={t('case_template.component_l2', 'Component L2')}
          placeholder={t(`case_template.component_l2_placeholder`, 'Please select L2 component')}
          defaultValue={null}
          options={component2Options}
          metadata={{ required: false, readOnly: !selectedComponent1 }}
          maxWidth={144}
        />
        <Select
          name={Fields.COMPONENT_L3}
          label={t('case_template.component_l3', 'Component L3')}
          placeholder={t(`case_template.component_l3_placeholder`, 'Please select L3 component')}
          defaultValue={null}
          options={component3Options}
          metadata={{
            required: false,
            readOnly: !selectedComponent1 || !selectedComponent2,
          }}
          maxWidth={144}
        />
      </FormRow>
      <FormRow>
        <Select
          name={Fields.CASE_PRIORITY}
          label={t('case_template.priority', 'Priority')}
          placeholder={t(`case_template.priority_placeholder`, 'Please Select a priority')}
          defaultValue={priorityOptions[0]?.value ?? ''}
          options={priorityOptions}
          metadata={{ required: false }}
          searchable={false}
        />
        <Select
          name={Fields.CASE_SOURCE}
          label={t('case_template.source', 'Source')}
          placeholder={t(`case_template.source_placeholder`, 'Please Select a source')}
          defaultValue={sourceOptions[0]?.value ?? ''}
          options={sourceOptions}
          metadata={{ required: false }}
          searchable={false}
        />
        <ToggleButton
          name={Fields.IS_BETA}
          label={t('case_template.beta', 'Beta')}
          defaultValue={false}
          metadata={{ required: false }}
        />
      </FormRow>
      <FormRow>
        <TextareaMetaField
          name={Fields.CASE_DESCRIPTION}
          label={t('case_template.description', 'Description')}
          placeholder={t(`case_template.description_placeholder`, 'Type case description id...')}
          defaultValue=""
          metadata={{ required: true }}
        />
      </FormRow>
      <FormRow>
        <MultiSelect
          name={Fields.ROOT_CAUSES}
          label={t('case_template.root_causes', 'Root Causes')}
          placeholder={t(`case_template.root_causes_placeholder`, 'Please Select root causes')}
          defaultValue={[RootCauseOption.OTHER]}
          options={rootCauses}
          excludeSearch={[RootCauseOption.ADD_NEW]}
          metadata={{ required: true }}
          minWidth={220}
          maxWidth={220}
          isMulti={true}
        />
        {selectedRootCauses?.includes(RootCauseOption.ADD_NEW) && (
          <TagsInput
            name={Fields.ROOT_CAUSES_NEW}
            label={t('case_template.root_causes_text', 'Add New')}
            placeholder={t(`case_template.root_causes_text_placeholder`, 'Type root cause...')}
            defaultValue={[]}
            metadata={{
              required: selectedRootCauses?.includes(RootCauseOption.ADD_NEW),
            }}
            maxWidth={224}
          />
        )}
      </FormRow>
    </FormCollapsiblePanel>
  );
};

CaseDetailForm.propTypes = {
  isOpen: PropTypes.bool,
};

export default CaseDetailForm;
