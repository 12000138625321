import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Severity } from '@ge/components/severity';
import { EntityType } from '@ge/models/constants';
import { useCaseUnlink } from '@ge/shared/data-hooks';

const DialogWrapper = styled.div`
  width: 280px;
  max-width: 100%;
  min-height: 80px;
  padding: 14px 25px;
  position: relative;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const HeaderIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const StyledTitle = styled.h3`
  padding-bottom: 24px;
`;

const StyledEntityTitle = styled.div`
  padding-bottom: 16px;
`;

const StyledDescription = styled.div`
  padding-bottom: 8px;
  font-size: 11px;
  line-height: 18px;
`;

const EntityIcon = styled(Icon).attrs((props) => ({
  color: props.theme.caseLinkDialog.entityIconColor,
}))`
  margin-right: 5px;
`;

const StyledSeverity = styled.span`
  margin-right: 5px;
`;

const StyledEntityDescription = styled.span`
  font-size: 11px;
  line-height: 1.36;
`;

const StyledError = styled.div`
  padding-top: 16px;
  color: ${({ theme }) => theme.fileUpload.errorTextColor};
`;

export const CaseUnlinkDialog = React.memo(
  ({ caseDetail, isOpen, entityId, entityType, onClose, onSave }) => {
    const { t, ready } = useTranslation(['entity-details'], { useSuspense: false });

    const tasks = useStoreState((store) => store.tasks.tasks);

    const { mutate: unlinkCase, error, isLoading, reset } = useCaseUnlink({
      mutationKey: entityId,
      onSuccess: onSave,
    });

    const entity = useMemo(() => {
      if (!entityId) return null;

      if (entityType === EntityType.TASK) {
        const task = tasks[entityId] ?? {};
        return {
          ...task,
          description: task.title,
          level: String(task.priority).toLowerCase(),
          icon: Icons.TASK,
        };
      }

      const entityCase = caseDetail.childCases.find((childCase) => childCase.id === entityId) ?? {};

      return {
        ...entityCase,
        level: String(entityCase.priority).toLowerCase(),
        icon: Icons.ANOMALY,
      };
    }, [entityId, entityType, tasks, caseDetail]);

    const onSubmit = useCallback(() => {
      if (entity && entityType === EntityType.CASE) {
        const body = {
          assetId: entity.asset?.id,
          siteId: entity.site?.id,
          parentId: entity.parentId,
          casesToBeDelinked: [entity.id],
        };
        unlinkCase(body);
      }
    }, [entity, entityType, unlinkCase]);

    useEffect(() => {
      if (entityId) reset();
    }, [entityId, reset]);

    if (!ready || !entity) {
      return null;
    }

    return (
      <Dialog
        isOpen={isOpen}
        contentWidth={true}
        padContent={false}
        onClose={onClose}
        header={
          <>
            <HeaderIcon icon={Icons.CASE} size={11} /> {t('case_unlink_dialog.unlink', 'Unlink')}
          </>
        }
        footer={
          <FooterContainer>
            <Button onClick={onClose} disabled={isLoading}>
              {t('case_unlink_dialog.cancel', 'Cancel')}
            </Button>
            <Button primary onClick={onSubmit} disabled={isLoading}>
              {t('case_unlink_dialog.unlink', 'Unlink')}
            </Button>
          </FooterContainer>
        }
      >
        <DialogWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledTitle>
                {t(
                  'case_unlink_dialog.confirm_title',
                  `Are you sure you'd like to unlink this [Case]?`,
                )}
              </StyledTitle>
              <StyledEntityTitle className="title">
                <EntityIcon icon={entity.icon} color={'transparent'} size={10} />
                {entity.level && (
                  <StyledSeverity>
                    <Severity level={entity.level} size={8} />
                  </StyledSeverity>
                )}
                <StyledEntityDescription>{entity.description}</StyledEntityDescription>
              </StyledEntityTitle>
              <StyledDescription>
                {t(
                  'case_unlink_dialog.confirm_message',
                  `Any notes or tasks associated with this [case] will be removed from the parent case, but are not deleted.`,
                )}
              </StyledDescription>
              {error && <StyledError>{error.message}</StyledError>}
            </>
          )}
        </DialogWrapper>
      </Dialog>
    );
  },
);

CaseUnlinkDialog.propTypes = {
  isOpen: PropTypes.bool,
  entityId: PropTypes.string,
  entityType: PropTypes.oneOf([EntityType.CASE, EntityType.TASK]),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  caseDetail: PropTypes.object,
};

CaseUnlinkDialog.defaultProps = {
  isOpen: false,
  entityId: null,
  entityType: null,
  onClose: () => null,
  onSave: () => null,
};

CaseUnlinkDialog.displayName = 'CaseUnlinkDialog';
