import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tab, Tabs } from '@ge/components/tabs';

import Fhp from '../components/fhp';
import { DetailContainer, SectionContainer } from '../entity-details-shared';

import EventDetailsHeader from './event-details-header';
// Hiding for MVP0 - Story #535
// import EventHistory from './event-history';
import EventOverview from './event-overview';
import { alarms } from './mock-alarms';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const EventDetails = ({ eventId }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const { fetchEventById } = useStoreActions((actions) => actions.issues);
  const { fetchAssetById } = useStoreActions((actions) => actions.assets);
  const { getEventById } = useStoreState((state) => state.issues);
  const { getAssetById } = useStoreState((state) => state.assets);
  const [event, setEvent] = useState(null);
  const [asset, setAsset] = useState(null);
  const [causalEvent, setCausalEvent] = useState(null);
  const [fhp, setFhp] = useState(null);
  const [causalFhpDismissed, setCausalFhpDismissed] = useState(false);

  const assetId = event?.asset.id;

  useEffect(() => {
    fetchEventById(eventId);
  }, [eventId, fetchEventById]);

  useEffect(() => {
    setEvent(getEventById(eventId));
  }, [event, eventId, getEventById]);

  useEffect(() => {
    if (event) {
      fetchAssetById(assetId);
    }
  }, [event, fetchAssetById, assetId]);

  useEffect(() => {
    setAsset(getAssetById(assetId));
  }, [setAsset, getAssetById, assetId]);

  // useCallbacks //
  const handleOpenFhp = useCallback((event) => {
    setFhp(event);
  }, []);

  const handleCausalChange = useCallback(
    (eventId) => {
      if (causalEvent !== eventId) {
        setCausalEvent(eventId);
      } else if (causalEvent === eventId) {
        setCausalEvent(null);
      }
    },
    [causalEvent],
  );

  const handleCloseFhp = useCallback(() => {
    if (causalEvent) {
      setCausalFhpDismissed(true);
    }

    setFhp(null);
  }, [causalEvent]);

  const initialCausalEvent = alarms && alarms.find((alarm) => alarm.causal);

  // Set causal event state if one is present in active alarms.
  useEffect(() => {
    setCausalEvent(initialCausalEvent && initialCausalEvent.id);

    return () => setCausalEvent(null);
  }, [handleOpenFhp, initialCausalEvent]);

  // Open fhp for causal event only if one is present.
  useEffect(() => {
    if (causalEvent && !causalFhpDismissed) {
      handleOpenFhp(initialCausalEvent);
    }
  }, [causalFhpDismissed, causalEvent, handleOpenFhp, initialCausalEvent]);

  if (!event || !asset || !asset?.metrics) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  const spawnWindow = (id) => {
    window.open(
      `/fhp/${id}`,
      '_blank',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780px,height=800px',
    );
  };

  return (
    <DetailContainer>
      <EventDetailsHeader event={event} asset={asset} />
      <ScrollingContainer autoHide={false}>
        <SectionContainer>
          <StyledTabs>
            <Tab small label={t('tabs.overview', 'Overview')}>
              <EventOverview
                event={event}
                asset={asset}
                causalEvent={causalEvent}
                setCausalEvent={handleCausalChange}
                openFhp={handleOpenFhp}
              />
            </Tab>
            {/* Hiding for MVP0 - Story #535 */}
            {/* <Tab small label={t('tabs.event_history', 'Event History')}>
              <EventHistory assetId={asset?.id} />
            </Tab> */}
          </StyledTabs>
        </SectionContainer>
      </ScrollingContainer>
      <Fhp closeFhp={handleCloseFhp} fhp={fhp} spawnWindow={spawnWindow} />
    </DetailContainer>
  );
};

EventDetails.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default EventDetails;
