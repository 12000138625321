import Proptypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '../icon';

import ErrorSvg from './error-icon.svg';

const StyledContainer = styled.div`
  width: 586px;
  height: 70px;
  background: #38434e;
  display: flex;
  align-items: center;
  border-left: 8px solid red;
  border-radius: 5px;
`;

const ErrorIcon = styled.img`
  margin-right: 12px;
  margin-left: 14px;
  width: 22px;
  height: 19px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.dialog.iconColor,
}))`
  display: block;
`;

const CloseButton = styled.button`
  padding: 10px;
  //display: flex;
  align-self: flex-start;
`;

export const ErrorBanner = ({ message, showMessage, closeable }) => {
  const [isClosed, setClosed] = useState(false);
  const toggle = () => {
    setClosed((prev) => !prev);
  };

  if (isClosed || !showMessage) return null;
  return (
    <StyledContainer>
      <ErrorIcon src={ErrorSvg} />
      {message}
      {closeable && (
        <CloseButton onClick={toggle}>
          <CloseIcon icon={Icons.CLOSE} size={10} />
        </CloseButton>
      )}
    </StyledContainer>
  );
};

ErrorBanner.propTypes = {
  message: Proptypes.node,
  showMessage: Proptypes.bool,
  closeable: Proptypes.bool,
};

export default ErrorBanner;
