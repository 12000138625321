import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Filter } from '@ge/components/filter';
import { Select } from '@ge/components/select';
import { EntityType } from '@ge/models/constants';

const UtilityBar = styled.div`
  align-items: flex-end;
  display: flex;
  margin: 30px 5px 20px 5px;
`;

const SearchBar = styled.div`
  margin-left: auto;
  .filter {
    position: relative;
    input {
      background-color: ${(props) => props.theme.input.background};
      box-sizing: border-box;
      border: 1px solid ${(props) => props.theme.input.border};
      border-radius: 2px;
      caret-color: ${(props) => props.theme.newTaskDialog.searchCaret};
      color: ${(props) => props.theme.newTaskDialog.searchText};
      height: 28px;
      padding: 6px 8px;
      width: 100%;
      ::placeholder {
        font-size: 14px;
        font-style: italic;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }
`;

const AssetUtilityBar = ({
  filterText,
  isDisableViewBy,
  onViewByChange,
  onFilterTextChange,
  viewBy,
}) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });

  const viewByOptions = [
    { value: EntityType.SERVICE_GROUP, label: t('create.service_groups', 'Service Groups') },
    { value: EntityType.SITE, label: t('create.sites', 'Sites') },
  ];

  return (
    <>
      <UtilityBar>
        <Select
          disabled={isDisableViewBy}
          label={t('assets.view_by', 'View by')}
          maxWidth={175}
          minWidth={175}
          onChange={(option) => onViewByChange(option.value)}
          options={viewByOptions}
          value={viewByOptions.filter((option) => option.value === viewBy)}
        />
        <SearchBar>
          <Filter
            text={filterText}
            onChange={(e) => onFilterTextChange(e.target.value.toLowerCase())}
            placeholder={t('search', 'Search')}
            hideResultsOnBlur={false}
          />
        </SearchBar>
      </UtilityBar>
    </>
  );
};

export default AssetUtilityBar;

AssetUtilityBar.propTypes = {
  filterText: PropTypes.string,
  isDisableViewBy: PropTypes.bool,
  onViewByChange: PropTypes.func,
  onFilterTextChange: PropTypes.func,
  viewBy: PropTypes.string,
};

AssetUtilityBar.defaultValues = {
  filterText: null,
  isDisableViewBy: false,
  onViewByChange: () => null,
  onFilterTextChange: () => null,
  viewBy: null,
};
