import { PropTypes } from 'prop-types';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { Icon, Icons } from '@ge/components/icon';
import { TaskTemplateModes } from '@ge/models/constants';

import { DynamicSection } from '../dynamic-section/dynamic-section';

const PartsContainer = styled.div`
  margin: 20px 0 10px;
`;

const HeadingRow = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 10px;
`;

const PartsRow = styled(HeadingRow)`
  align-items: center;
  height: 33px;
  ${(props) =>
    props.error &&
    `border: 1px solid ${props.theme.dangerColor};
    border-radius: 7px;
    box-sizing: border-box`}
`;

const PartsColumn = styled.div`
  color: ${(props) => props.theme.table.dataTextColor};
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 15px;
  overflow-wrap: break-word;
  ${(props) =>
    props.error &&
    `border: 1px solid ${props.theme.dangerColor};
    border-radius: 7px;
    box-sizing: border-box;
    padding: 7px`}
`;

const PartsColumnOne = styled(PartsColumn)`
  width: 80px;
`;

const PartsColumnTwo = styled(PartsColumn)`
  width: 290px;
`;

const PartsColumnThree = styled(PartsColumn)`
  align-items: center;
  width: 80px;
`;

const PartsColumnFour = styled(PartsColumn)`
  align-items: center;
  width: 100px;
`;

const PartsColumnFive = styled(PartsColumn)`
  align-items: center;
  width: 50px;
`;

const PartsColumnSix = styled(PartsColumn)`
  width: 80px;
`;

const PartsLabel = styled.div`
  align-items: center;
  color: ${(props) => props.theme.input.labelTextColor};
  display: flex;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 6px;
  text-align: center;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.checkbox.iconColor,
}))``;

export const ViewParts = ({ shippingSection, fieldName, erpData, setDivPartHeight }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const rlvRef = useRef();
  const [refVisible, setRefVisible] = useState(false);
  useEffect(() => {
    if (!refVisible) return;
    setDivPartHeight(rlvRef.current.clientHeight);
    // detected rendering
  }, [refVisible, setDivPartHeight]);

  const partsData = erpData?.[fieldName];
  const showShippingSection = useMemo(
    () => partsData?.some((part) => part.placeOrder || part.replenish),
    [partsData],
  );

  return (
    <PartsContainer
      ref={(el) => {
        rlvRef.current = el;
        setRefVisible(!!el);
      }}
    >
      <PartsRow>
        <PartsColumnOne>
          <PartsLabel>{t('form.part_number', 'Part #')}</PartsLabel>
        </PartsColumnOne>
        <PartsColumnTwo>
          <PartsLabel>{t('form.part_name', 'Part Name')}</PartsLabel>
        </PartsColumnTwo>
        <PartsColumnThree>
          <PartsLabel>{t('form.quantity', 'Quantity')}</PartsLabel>
        </PartsColumnThree>
        <PartsColumnFour>
          <PartsLabel>{t('form.service_activity', 'Service Activity')}</PartsLabel>
        </PartsColumnFour>
        <PartsColumnFive>
          <PartsLabel>{t('form.place_order', 'Place Order')}</PartsLabel>
        </PartsColumnFive>
        <PartsColumnSix>
          <PartsLabel>{t('form.sales_order_number', 'Sales Order #')}</PartsLabel>
        </PartsColumnSix>
      </PartsRow>
      {partsData?.map((part, index) => {
        // N.b.: `placeOrder` is the original name of the checkbox value in the submitted form.
        // `replenish` is its backend counterpart.
        // Ideally these field names would be consistent between the request and response objects,
        // but understanding the terms are essentially interchangeable, we'll just look for both.
        const partOrdered = part.placeOrder || part.replenish;
        return (
          <PartsRow key={index} error={part.error}>
            <PartsColumnOne>{part.number}</PartsColumnOne>
            <PartsColumnTwo>{part.name}</PartsColumnTwo>
            <PartsColumnThree>{part.quantity}</PartsColumnThree>
            <PartsColumnFour>{part.serviceActivity}</PartsColumnFour>
            <PartsColumnFive error={partOrdered && part.orderNumber?.trim().length === 0}>
              {partOrdered && <StyledIcon size={10} icon={Icons.CHECK} className="check" />}
            </PartsColumnFive>
            <PartsColumnSix>
              {partOrdered &&
                (part.orderNumber ||
                  (erpData.chargesCreation === 'YES'
                    ? t('form.sales_order_in_progress', 'In Progress')
                    : '-'))}
            </PartsColumnSix>
          </PartsRow>
        );
      })}
      <ConditionalRender shouldRender={!!showShippingSection}>
        <DynamicSection
          section={shippingSection}
          templateMode={TaskTemplateModes.VIEW}
          erpData={erpData}
        />
      </ConditionalRender>
    </PartsContainer>
  );
};

ViewParts.propTypes = {
  fieldName: PropTypes.string.isRequired,
  shippingSection: PropTypes.object.isRequired,
  erpData: PropTypes.object.isRequired,
  setDivPartHeight: PropTypes.func,
  erpDataLastUpdatedBy: PropTypes.sring,
};
