import { PropTypes } from 'prop-types';
import splitEvery from 'ramda/src/splitEvery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { useMailNotification } from '@ge/shared/data-hooks';

const RECIPIENT_ROW_LENGTH = 3;
const TAG_GUTTER_PX = 5;

const getTagRows = (entries = []) => splitEvery(RECIPIENT_ROW_LENGTH, entries);

const getTagRowClasses = ({ index, options = {} }) =>
  options.hasExpand && index === options.rows.length - 1 ? 'expandable' : undefined;

const getTagRowKey = (entries = []) =>
  entries.flatMap(([value]) => value).reduce((key, value) => (key ? `${key}_${value}` : value), '');

const TagContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
`;

const TagRow = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .expand {
    display: none;
    flex: 1 0;
    text-align: right;
    width: 25%;
  }

  .tags {
    align-items: center;
    display: flex;
    flex: ${({ count }) => count} 1;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;

    > * {
      box-sizing: border-box;
      flex: 0 1;
      max-width: calc(
        (100% / ${({ count }) => count}) -
          (${TAG_GUTTER_PX}px * ${({ count }) => (count - 1) / count})
      );

      + * {
        margin-left: ${TAG_GUTTER_PX}px;
      }
    }
  }

  &.expandable {
    .expand {
      display: block;
    }

    .tags {
      width: 75%;
    }
  }

  + div {
    margin-top: 10px;
  }
`;

const ExpandIcon = styled(Icon).attrs(({ theme }) => ({
  className: 'expand-icon',
  color: theme.alertDialog.expandButtonColor,
  icon: Icons.CHEVRON,
  size: 12,
}))`
  margin-left: 5px;
`;

const ExpandButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: ${({ theme }) => theme.alertDialog.expandButtonColor};
  flex: 0 1;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding: 0;
  text-align: right;
  white-space: pre;

  .expand-icon {
    fill: ${({ theme }) => theme.alertDialog.expandButtonColor};
  }
`;

export const MailRecipientListField = withTheme(({ onRemove, theme, value }) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });
  // get contact info for provided email addresses
  // TODO: handle errors and loading state
  const { data } = useMailNotification({ addresses: value });

  // state
  const [isExpanded, setIsExpanded] = useState(false);

  const entries = Object.entries(data ?? {});

  if (!(ready && Boolean(entries.length))) {
    return null;
  }

  const rows = getTagRows(entries);

  const options = {
    hasExpand: rows.length > 1,
    hiddenCount: entries.length - rows[0].length,
    rotate: 0,
    rows,
    toggle: true,
  };

  if (isExpanded) {
    options.buttonLabel = t('form.mail_recipient.less_button_label', 'Less');
    options.rotate = 180;
    options.toggle = false;
  } else {
    options.buttonLabel =
      options.hiddenCount === 1
        ? t('form.mail_recipient.one_more_button_label', '+1 Other')
        : t('form.mail_recipient.more_button_label', { count: options.hiddenCount });
    options.rows = rows.slice(0, 1);
  }

  return (
    <TagContainer>
      {options.rows.map((row, index) => (
        <TagRow
          className={getTagRowClasses({ index, options })}
          count={row.length}
          key={getTagRowKey(row)}
        >
          <div className="tags">
            {row.map(([value, label]) => (
              <Badge
                color={theme.alertDialog.tagBackgroundColor}
                label={label}
                onDismiss={() => onRemove(value)}
                key={value}
              />
            ))}
          </div>
          <div className="expand">
            <ExpandButton onClick={() => setIsExpanded(options.toggle)}>
              <span>{options.buttonLabel}</span>
              <ExpandIcon rotate={options.rotate} />
            </ExpandButton>
          </div>
        </TagRow>
      ))}
    </TagContainer>
  );
});

MailRecipientListField.propTypes = {
  onRemove: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

MailRecipientListField.defaultProps = {
  onRemove: () => {},
  value: [],
};
