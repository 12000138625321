import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';

// All available columns and groups for planned work
export const PlannedWorkColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  // Asset Details
  GROUP_ASSET_DETAILS: 'Asset Details',
  ASSET_NAME: 'Asset',
  ASSET_SITE: 'Site',
  ASSET_STATE: 'State',

  // Task Details
  GROUP_TASK_DETAILS: 'Task Details',
  TASK_PRIORITY: 'Priority',
  TASK_STATUS: 'Task Status',
  TASK_TITLE: 'Task Title',
  TASK_DESCRIPTION: 'Description',
  TASK_TYPE: 'Task Type',
  TASK_LINKED: 'Linked',
  TASK_COMPONENT: 'Component',
  TASK_ASSIGNED_TECHS: 'Assigned Techs',
  TASK_SR_NUMBER: 'SR Number',
  TASK_SR_STATUS: 'SR Status',
  STATUS: 'Status',

  // Estimates
  GROUP_ESTIMATES: 'Estimates',
  ESTIMATES_NUM_TECHS: 'Est # Techs',
  ESTIMATES_DURATION: 'Est Duration',
  ESTIMATES_LABOR_HOURS: 'Est Labor Hrs',

  // Dates
  GROUP_DATES: 'Dates',
  DATES_CREATED: 'Created',
  DATES_ELIGIBLE_START: 'Eligible Start',
  DATES_SCHEDULED: 'Scheduled',
  DATES_DUE: 'Due',
  DATES_COMPLETED: 'Completed',

  // Parts
  GROUP_PARTS: 'Parts',
  PARTS_EXPECTED: 'Expected',
  PARTS_CONSUMED: 'Consumed',

  // Notes
  GROUP_NOTES: TableBaseCols.GROUP_NOTES,
  NOTES_LATEST_NOTE: TableBaseCols.NOTES_LATEST_NOTE,
  NOTES_RESOLUTION: 'Resolution',
  NOTES_EXTERNAL: TableBaseCols.NOTES_EXTERNAL,
  NOTES_INTERNAL: TableBaseCols.NOTES_INTERNAL,
  NOTES_BOTH: 'Both',
};

// Planned work table configuration
export const plannedWorkColumnDefs = {
  [PlannedWorkColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [PlannedWorkColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'planned_work.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [PlannedWorkColumns.GROUP_ASSET_DETAILS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'planned_work.columns.asset_details',
        a11yDefault: 'Asset Details',
      },
    ],
    cols: {
      [PlannedWorkColumns.ASSET_NAME]: {
        locked: true,
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'planned_work.columns.asset_name',
            a11yDefault: 'Asset Name',
          },
        ],
      },
      [PlannedWorkColumns.ASSET_SITE]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.asset_site',
            a11yDefault: 'Asset Site',
          },
        ],
      },
      [PlannedWorkColumns.ASSET_STATE]: {
        align: 'center',
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'planned_work.columns.asset_state',
            a11yDefault: 'Asset State',
          },
        ],
      },
    },
  },
  [PlannedWorkColumns.GROUP_TASK_DETAILS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'planned_work.columns.task_details',
        a11yDefault: 'Task Details',
      },
    ],
    cols: {
      [PlannedWorkColumns.TASK_PRIORITY]: {
        cell: {
          width: '100px',
        },
        labels: [
          {
            a11yKey: 'planned_work.columns.priority',
            a11yDefault: 'Task Priority',
          },
        ],
      },
      [PlannedWorkColumns.TASK_STATUS]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.status',
            a11yDefault: 'Task Status',
          },
        ],
      },
      [PlannedWorkColumns.TASK_TITLE]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.task_title',
            a11yDefault: 'Task Title',
          },
        ],
      },
      [PlannedWorkColumns.TASK_DESCRIPTION]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.description',
            a11yDefault: 'Task Description',
          },
        ],
      },
      [PlannedWorkColumns.TASK_TYPE]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.task_type',
            a11yDefault: 'Task Type',
          },
        ],
      },
      [PlannedWorkColumns.TASK_LINKED]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.task_linked',
            a11yDefault: 'Linked',
          },
        ],
      },
      [PlannedWorkColumns.TASK_COMPONENT]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.component',
            a11yDefault: 'Component',
          },
        ],
      },
      [PlannedWorkColumns.TASK_ASSIGNED_TECHS]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.assigned_techs',
            a11yDefault: 'Assigned Techs',
          },
        ],
      },
      [PlannedWorkColumns.TASK_SR_NUMBER]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.sr_number',
            a11yDefault: 'SR Number',
          },
        ],
      },
      [PlannedWorkColumns.TASK_SR_STATUS]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.sr_status',
            a11yDefault: 'SR Status',
          },
        ],
      },
    },
  },
  [PlannedWorkColumns.GROUP_ESTIMATES]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'planned_work.columns.estimates',
        a11yDefault: 'Estimates',
      },
    ],
    cols: {
      [PlannedWorkColumns.ESTIMATES_NUM_TECHS]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.est_num_techs',
            a11yDefault: 'Est. # Techs',
          },
        ],
      },
      [PlannedWorkColumns.ESTIMATES_DURATION]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.est_duration',
            a11yDefault: 'Est. Duration',
          },
        ],
      },
      [PlannedWorkColumns.ESTIMATES_LABOR_HOURS]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.est_labor_hours',
            a11yDefault: 'Est. Labor Hrs',
          },
        ],
      },
    },
  },
  [PlannedWorkColumns.GROUP_DATES]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'planned_work.columns.dates',
        a11yDefault: 'Dates',
      },
    ],
    cols: {
      [PlannedWorkColumns.DATES_CREATED]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.created',
            a11yDefault: 'Created',
          },
        ],
      },
      [PlannedWorkColumns.DATES_ELIGIBLE_START]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.eligible_start',
            a11yDefault: 'Eligible Start',
          },
        ],
      },
      [PlannedWorkColumns.DATES_SCHEDULED]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.scheduled',
            a11yDefault: 'Scheduled',
          },
        ],
      },
      [PlannedWorkColumns.DATES_DUE]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.due',
            a11yDefault: 'Due',
          },
        ],
      },
      [PlannedWorkColumns.DATES_COMPLETED]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.completed',
            a11yDefault: 'Completed',
          },
        ],
      },
    },
  },
  [PlannedWorkColumns.GROUP_PARTS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'planned_work.columns.parts',
        a11yDefault: 'Parts',
      },
    ],
    cols: {
      [PlannedWorkColumns.PARTS_EXPECTED]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.expected',
            a11yDefault: 'Expected',
          },
        ],
      }, // TODO: Header alignment customization to include quantity
      [PlannedWorkColumns.PARTS_CONSUMED]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.consumed',
            a11yDefault: 'Consumed',
          },
        ],
      }, // TODO: Header alignment customization to include quantity
    },
  },
  [PlannedWorkColumns.GROUP_NOTES]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'planned_work.columns.notes',
        a11yDefault: 'Notes',
      },
    ],
    cols: {
      [PlannedWorkColumns.NOTES_LATEST_NOTE]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.latest_note',
            a11yDefault: 'Latest Note',
          },
        ],
      },
      [PlannedWorkColumns.NOTES_RESOLUTION]: {
        labels: [
          {
            a11yKey: 'planned_work.columns.resolution',
            a11yDefault: 'Resolution',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const plannedWorkOmittedCols = [
  PlannedWorkColumns.GROUP_SELECTED,
  PlannedWorkColumns.SELECTED,
  PlannedWorkColumns.TASK_SR_STATUS,
  PlannedWorkColumns.TASK_LINKED,
  PlannedWorkColumns.STATUS,
  PlannedWorkColumns.NOTES_BOTH,
];

export const plannedWorkCustomColConfigs = [PlannedWorkColumns.NOTES_LATEST_NOTE];

// Default order and visibility configuration for planned work columns
// NOTE: For state selection, EVERY column must have a position and visibility default!
export const plannedWorkDefaultCols = [
  {
    id: PlannedWorkColumns.GROUP_SELECTED,
    cols: [
      {
        id: PlannedWorkColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: PlannedWorkColumns.GROUP_ASSET_DETAILS,
    cols: [
      {
        id: PlannedWorkColumns.ASSET_NAME,
        visible: true,
      },
      {
        id: PlannedWorkColumns.ASSET_STATE,
        visible: true,
      },
      {
        id: PlannedWorkColumns.ASSET_SITE,
        visible: false,
      },
    ],
  },
  {
    id: PlannedWorkColumns.GROUP_TASK_DETAILS,
    cols: [
      {
        id: PlannedWorkColumns.TASK_TITLE,
        visible: true,
      },
      {
        id: PlannedWorkColumns.TASK_PRIORITY,
        visible: true,
      },
      {
        id: PlannedWorkColumns.TASK_ASSIGNED_TECHS,
        visible: true,
      },
      {
        id: PlannedWorkColumns.TASK_TYPE,
        visible: false,
      },
      {
        id: PlannedWorkColumns.TASK_STATUS,
        visible: false,
      },
      {
        id: PlannedWorkColumns.TASK_DESCRIPTION,
        visible: false,
      },
      {
        id: PlannedWorkColumns.TASK_LINKED,
        visible: false,
      },
      {
        id: PlannedWorkColumns.TASK_COMPONENT,
        visible: false,
      },
      {
        id: PlannedWorkColumns.TASK_SR_NUMBER,
        visible: false,
      },
      {
        id: PlannedWorkColumns.TASK_SR_STATUS,
        visible: false,
      },
    ],
  },
  {
    id: PlannedWorkColumns.GROUP_ESTIMATES,
    cols: [
      {
        id: PlannedWorkColumns.ESTIMATES_NUM_TECHS,
        visible: false,
      },
      {
        id: PlannedWorkColumns.ESTIMATES_DURATION,
        visible: false,
      },
      {
        id: PlannedWorkColumns.ESTIMATES_LABOR_HOURS,
        visible: false,
      },
    ],
  },
  {
    id: PlannedWorkColumns.GROUP_DATES,
    cols: [
      {
        id: PlannedWorkColumns.DATES_CREATED,
        visible: false,
      },
      {
        id: PlannedWorkColumns.DATES_ELIGIBLE_START,
        visible: false,
      },
      {
        id: PlannedWorkColumns.DATES_SCHEDULED,
        visible: false,
      },
      {
        id: PlannedWorkColumns.DATES_DUE,
        visible: false,
      },
      {
        id: PlannedWorkColumns.DATES_COMPLETED,
        visible: false,
      },
    ],
  },
  {
    id: PlannedWorkColumns.GROUP_PARTS,
    cols: [
      {
        id: PlannedWorkColumns.PARTS_EXPECTED,
        visible: true,
      },
      {
        id: PlannedWorkColumns.PARTS_CONSUMED,
        visible: false,
      },
    ],
  },
  {
    id: PlannedWorkColumns.GROUP_NOTES,
    cols: [
      {
        id: PlannedWorkColumns.NOTES_LATEST_NOTE,
        visible: true,
      },
      {
        id: PlannedWorkColumns.NOTES_RESOLUTION,
        visible: false,
      },
      {
        id: PlannedWorkColumns.NOTES_INTERNAL,
        // Should not be counted against max column selections, as this is a preference setting for the Latest Note column and not a column itself
        excludeFromCount: true,
        visible: false,
      },
      {
        id: PlannedWorkColumns.NOTES_EXTERNAL,
        // Should not be counted against max column selections, as this is a preference setting for the Latest Note column and not a column itself
        excludeFromCount: true,
        visible: false,
      },
    ],
  },
];
