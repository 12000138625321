import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { Severity } from '@ge/components/severity';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AssetState } from '@ge/models/constants';
import { formatHoursAndMinutes, getDate, killEventPropagation } from '@ge/shared/util';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';

import { ReportWidgetTable } from '../../tables/report-widget-table';

import { completedWorkColumnDefs, CompletedWorkColumns } from './completed-work-cols';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const PriorityLabel = styled.span`
  padding-left: 5px;
`;

const TechBadge = styled.span`
  background-color: ${({ theme }) => theme.createReport.widget.completedWork.techBadge.background};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.createReport.widget.completedWork.techBadge.border};
  text-transform: uppercase;
  letter-spacing: -0.5px;
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  padding: 3px 5px;
  color: ${({ theme }) => theme.createReport.widget.completedWork.techBadge.textColor};
`;

const TechList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;

  ${TechBadge} {
    margin: 0 -5px 3px 0;
  }
`;

const PartsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const PartsDetails = styled.li`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const PartContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const PartDescription = styled.span``;

const PartNumber = styled.span`
  color: ${({ theme }) =>
    theme.createReport.widget.completedWork.expectedParts.partNumberTextColor};
`;

const PartQty = styled.span`
  min-width: 20px;
  text-align: right;
`;

const NoteContainer = styled.div`
  overflow-wrap: normal;
  white-space: normal;
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.completedWork.includeTextColor};
`;

const ExpectedPartsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 125px;
`;

const NotesHeader = styled.div`
  min-width: 125px;
`;

const Legend = styled.ul`
  display: flex;
  justify-content: flex-end;
`;
const LegendItem = styled.li`
  list-style: none;
  margin-left: 10px;

  span {
    color: ${({ theme }) => theme.createReport.widget.headerTextColor};
    margin-left: 5px;
  }
`;

export const CompletedWorkWidget = ({
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
}) => {
  const { t, ready } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(data.map(({ task }) => task?.id));

  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom table headers for provided columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case CompletedWorkColumns.SELECTED:
          return <IncludeHeader>{t('completed_work.columns.include')}</IncludeHeader>;
        case CompletedWorkColumns.PARTS_EXPECTED:
          return (
            <ExpectedPartsHeader>
              <span>{t('completed_work.columns.expected')}</span>
              <span>{t('completed_work.columns.qty')}</span>
            </ExpectedPartsHeader>
          );
        case CompletedWorkColumns.PARTS_CONSUMED:
          return (
            <ExpectedPartsHeader>
              <span>{t('completed_work.columns.consumed')}</span>
              <span>{t('completed_work.columns.qty')}</span>
            </ExpectedPartsHeader>
          );
        case CompletedWorkColumns.NOTES_LATEST_NOTE:
          return <NotesHeader>{t('completed_work.columns.latest_note')}</NotesHeader>;
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case CompletedWorkColumns.SELECTED:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case CompletedWorkColumns.ASSET_STATE:
          return (
            <Badge
              color={getTurbineStateColor(cellValue)}
              label={getTurbineStateType(cellValue)}
              small
            />
          );
        case CompletedWorkColumns.TASK_PRIORITY:
          return (
            <>
              <Severity level={cellValue} />
              <PriorityLabel>{t(`completed_work.priorities.${cellValue}`)}</PriorityLabel>
            </>
          );
        case CompletedWorkColumns.TASK_ASSIGNED_TECHS:
          return (
            <TechList>
              {cellValue?.map((tech, idx) => (
                <TechBadge key={idx}>{tech}</TechBadge>
              ))}
            </TechList>
          );
        case CompletedWorkColumns.PARTS_EXPECTED:
        case CompletedWorkColumns.PARTS_CONSUMED:
          return (
            <PartsList>
              {cellValue?.map((part, idx) => (
                <PartsDetails key={idx}>
                  <PartContainer>
                    <PartDescription>{part.description}</PartDescription>
                    <PartNumber>{part.partNumber}</PartNumber>
                  </PartContainer>
                  <PartQty>{part.qty}</PartQty>
                </PartsDetails>
              ))}
            </PartsList>
          );
        case CompletedWorkColumns.NOTES_LATEST_NOTE:
          return <NoteContainer>{cellValue}</NoteContainer>;
        default:
          return cellValue;
      }
    },
    [rowsSelected, t, toggleCheckedRow],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback(
    (completedWork) => {
      return {
        [CompletedWorkColumns.SELECTED]: completedWork.task.id,
        [CompletedWorkColumns.ASSET_NAME]: completedWork.asset?.name,
        [CompletedWorkColumns.ASSET_SITE]: completedWork.asset?.siteName,
        [CompletedWorkColumns.ASSET_STATE]: completedWork.asset?.state,
        [CompletedWorkColumns.ASSET_SITE]: completedWork.asset?.siteName,
        [CompletedWorkColumns.TASK_PRIORITY]: completedWork.task.priority,
        [CompletedWorkColumns.TASK_STATUS]: t(
          `task_status.${completedWork.task.status}`,
          completedWork.task.status,
        ),
        [CompletedWorkColumns.TASK_TITLE]: completedWork.task.title,
        [CompletedWorkColumns.TASK_DESCRIPTION]: completedWork.task.description,
        [CompletedWorkColumns.TASK_TYPE]: t(
          `work_scopes.${completedWork.task.type}`,
          completedWork.task.type,
        ),
        [CompletedWorkColumns.TASK_LINKED]: 'N/A', // TODO: Where is this data?
        [CompletedWorkColumns.TASK_COMPONENT]: completedWork.task.component,
        [CompletedWorkColumns.TASK_ASSIGNED_TECHS]: completedWork.task.assignedTechs,
        [CompletedWorkColumns.TASK_SR_NUMBER]: completedWork.task.srNumber,
        [CompletedWorkColumns.TASK_SR_STATUS]: completedWork.task.srStatus,
        [CompletedWorkColumns.ESTIMATES_NUM_TECHS]: completedWork.estimates?.estimatedNumberOfTechs,
        [CompletedWorkColumns.ESTIMATES_DURATION]: formatHoursAndMinutes(
          completedWork.estimates?.estimatedDurationHours,
          completedWork.estimates?.estimatedDurationMinutes,
        ),
        [CompletedWorkColumns.ESTIMATES_LABOR_HOURS]: formatHoursAndMinutes(
          completedWork.estimates?.laborHours,
          completedWork.estimates?.laborMinutes,
        ),
        [CompletedWorkColumns.DATES_CREATED]:
          completedWork.dates?.createdDate && getDate(completedWork.dates.createdDate),
        [CompletedWorkColumns.DATES_ELIGIBLE_START]:
          completedWork.dates?.eligibleStartDate && getDate(completedWork.dates.eligibleStartDate),
        [CompletedWorkColumns.DATES_SCHEDULED]:
          completedWork.dates?.scheduleDate && getDate(completedWork.dates.scheduleDate),
        [CompletedWorkColumns.DATES_DUE]:
          completedWork.dates?.dueDate && getDate(completedWork.dates.dueDate),
        [CompletedWorkColumns.DATES_COMPLETED]:
          completedWork.dates?.completedDate && getDate(completedWork.dates.completedDate),
        [CompletedWorkColumns.PARTS_EXPECTED]: completedWork.parts?.expected,
        [CompletedWorkColumns.PARTS_CONSUMED]: completedWork.parts?.consumed,
        [CompletedWorkColumns.NOTES_LATEST_NOTE]: completedWork.notes?.latest,
        [CompletedWorkColumns.NOTES_RESOLUTION]: completedWork.notes?.resolution,
        [CompletedWorkColumns.TASK_RDSPP]: completedWork.task?.rdspp,
      };
    },
    [t],
  );

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: completedWorkColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  // Inspect column state to determine if the state column is visible. Used to show/hide legend.
  const stateVisible =
    columns
      ?.find((colGrp) => colGrp.id === CompletedWorkColumns.GROUP_ASSET_DETAILS)
      ?.cols.find((col) => col.id === CompletedWorkColumns.ASSET_STATE)?.visible === true;

  if (!ready) {
    return <Loader />;
  }

  return (
    <Container>
      {stateVisible && (
        <Legend>
          {Object.values(AssetState)
            .filter((state) => state !== AssetState.NO_DATA)
            .map((state) => (
              <LegendItem key={state}>
                <Badge
                  color={getTurbineStateColor(state)}
                  label={getTurbineStateType(state)}
                  small
                />
                <span>{t(`asset_state.${state}`)}</span>
              </LegendItem>
            ))}
        </Legend>
      )}

      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty={['task', 'id']}
        isEditing={isEditing}
        variableHeight={true}
      />
    </Container>
  );
};

CompletedWorkWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.string),
};
