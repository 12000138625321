import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import remove from 'ramda/src/remove';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { withTheme } from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { DataExplorerChart } from '@ge/components/charts/data-explorer-chart';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { DashboardChartToggle } from '@ge/feat-analyze/components/dashboard/dashboard-chart-toggle';
import { useAssetContext, useDataExplorerContext } from '@ge/feat-analyze/context';
import { getSignal, hex2rgba, getTooltipStyle } from '@ge/feat-analyze/util/data-explorer-chart';
import { AxisOptions, DateTimeFormats, TimeSignal, PowerCurve } from '@ge/models/constants';
import { useAssetSignalData } from '@ge/shared/data-hooks';
import { chartColors } from '@ge/tokens/charts';
import { getMinMax } from '@ge/util';

import { DataExplorerSignalDialog } from './../data-explorer-signal-dialog';
import { DataExplorerChartHeader } from './data-explorer-chart-header';
import { DraggableContainer, ChartContainer } from './data-explorer-draggable-container';

const TOGGLE_TYPES = [
  { id: ChartType.SCATTER, type: ChartType.SCATTER },
  { id: ChartType.SPLINE, type: ChartType.SPLINE },
];

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  > label {
    color: ${(props) => props.theme.dataExplorer.charts.container.labelColor};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

const ChartHeaderLeft = styled.div`
  display: flex;
  flex: 1;
`;

const SignalBtn = styled.button`
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${(props) => props.theme.dataExplorer.charts.container.selectColor};
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const Legend = styled.span`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 16px;
  &:first-child {
    margin-left: 0;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
`;

const PromoteIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dataExplorer.charts.container.promoteIconColor,
  icon: Icons.EXPAND,
}))`
  vertical-align: initial;
`;

const PromoteButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0;
`;

const RemoveButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0;
  margin-left: 10px;
`;

const RemoveIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dataExplorer.charts.container.promoteIconColor,
  icon: Icons.CLOSE,
}))`
  vertical-align: initial;
`;

const getDateLabel = ({ value }) =>
  value && dayjs.unix(Number(value)).format(DateTimeFormats.DEFAULT_DATE_TIME);

const filterDataPoint = (filter, value) => {
  const { min: _min, max: _max, include } = filter?.filter || {};
  const filterSignal = Number(value);
  const min = Number(_min);
  const max = Number(_max);

  // check against min or max
  if (!max && filterSignal <= min) return true;
  if (!min && filterSignal >= max) return true;

  // check between range and include or exclude
  if (include) {
    if (filterSignal <= min || filterSignal >= max) return true;
  } else {
    if (filterSignal >= min && filterSignal <= max) return true;
  }
};

const tooltipPointFormatter =
  (xAxisSignal = {}, yAxisSignal = {}, asset = {}, series = [], theme = {}) =>
  ({ x, clusteredData }) => {
    const styles = getTooltipStyle({ theme });

    const getTooltipX = (assetName, xMin, xMax) => {
      if (xAxisSignal.id === TimeSignal.id) {
        xMin = getDateLabel({ value: xMin });
        xMax = getDateLabel({ value: xMax });
      }
      return `<span style="${styles.heading}">
      <span style="${styles.assetName}">${assetName ?? ''}</span>
      <span style="${styles.xKpiName}">${
        xAxisSignal.id !== TimeSignal.id ? xAxisSignal.name : ''
      }</span>
      <span style="${styles.xKpiValue}">${xMin} ${xMax && xMax !== xMin ? ` - ${xMax}` : ''}</span>
    </span>`;
    };

    const getTooltipY = (yMin, yMax) => `<span style="${styles.kpi}">
    <span style="${styles.yKpiName}">${yAxisSignal.title ?? yAxisSignal.name}</span>
    <span style="${styles.yKpiValue}">${yMin} ${yMax && yMax !== yMin ? ` - ${yMax}` : ''}</span>
  </span>`;

    const getTooltipSeries = (assetName) =>
      series
        .filter((v) => v.showInLegend && v.visible && v.assetName === assetName)
        .map((v) => {
          const point = v.data.find((point) => point.x === x);
          return `<span style="${styles.kpi}">
          <span style="${styles.yKpiName}">${v.name}</span>
          <span style="${styles.yKpiValue}">${point?.y ?? '-'}</span>
        </span>`;
        })
        .join('');

    if (clusteredData) {
      const [xMin, xMax] = getMinMax(clusteredData.map((data) => data.options.x));
      const [yMin, yMax] = getMinMax(clusteredData.map((data) => data.options.y));
      return `<span style="${styles.container}">
        ${getTooltipX(asset.name, xMin, xMax)}
        <span style="${styles.kpiWrapper}">
          ${getTooltipY(yMin, yMax)}
        </span>
      </span>`;
    }

    let content = '';
    const assetNames = [...new Set(series.flatMap((v) => v.assetName))].filter(Boolean);
    for (let i in assetNames) {
      if (i == 0) {
        content += getTooltipX(assetNames[i], x);
      } else {
        content += `<span style="${styles.subheading}">${assetNames[i]}</span>`;
      }
      content += `<span style="${styles.kpiWrapper}">${getTooltipSeries(assetNames[i])}</span>`;
    }
    return `<span style="${styles.container}">${content}</span>`;
  };

const getSelectedSeriesOptions = ({
  id,
  type,
  color,
  selection,
  ySignal,
  zIndex,
  data,
  assetId,
  theme,
  enableMultiAxis,
}) => {
  const yAxis = enableMultiAxis ? ySignal.yAxis : 0;
  const options = {
    name: '____' + ySignal.title,
    unit: ySignal.unit,
    yAxis,
    type,
    lineWidth: ChartType.SPLINE || type === ChartType.LINE ? 1 : 0,
    zIndex,
    assetId,
    allowPointSelect: false,
    isSelectedPointsSeries: false,
    showInLegend: false,
    opacity: 1,
  };
  if (!selection?.selected) return { ...options, opacity: 0, data: [] };
  if (selection.id === id) {
    if (selection.selected === ySignal.title) {
      return {
        ...options,
        color: theme.dataExplorer.charts.selectedPointsColor,
        data: selection.points,
        isSelectedPointsSeries: true,
      };
    }
    return { ...options, opacity: 0, data: [] };
  }
  const _points = selection.points.flatMap((v) => v.ts);
  return {
    ...options,
    color: enableMultiAxis ? color : theme.dataExplorer.charts.selectedPointsColor,
    data: data.filter((point) => _points.indexOf(point.ts) !== -1),
  };
};

const getChartSeries = ({
  id,
  data = [],
  theme,
  type,
  xAxisSignal,
  yAxisSignal,
  filter,
  comparisonAssets,
  selectedAssetId,
  selection,
  enableMultiAxis,
}) => {
  const colors = theme.dataExplorer.charts.colors;
  let series = [];

  const addSeriesData = (assetData, i) => {
    const asset = assetData.asset;
    const isSelectedAsset = selectedAssetId === assetData.asset.id;
    // drop opacity if asset is unchecked in asset bar
    const opacity = comparisonAssets.includes(assetData.asset.id) ? 1 : 0.3;

    const chartData = assetData.signalData?.reduce((result, signal) => {
      // filtet out data point if point meets filter limits
      for (let ySignal of yAxisSignal) {
        if (ySignal.timeAggr !== assetData.timeAggr) continue;

        const name = ySignal.title;
        const x = xAxisSignal.id === TimeSignal.id ? signal.ts : signal[xAxisSignal.id];
        if (!result[name]) {
          result[name] = [];
        }
        if (filter?.filter?.id && filterDataPoint(filter, Number(signal[filter?.filter?.id]))) {
          result[name].push({ x: null, y: null, ts: null });
        } else {
          result[name].push({
            x: Number(x),
            y: Number(signal[ySignal.id]),
            ts: Number(signal.ts),
          });
        }
      }
      return result;
    }, {});

    // set color for selected asset
    let color = isSelectedAsset
      ? theme.dataExplorer.charts.selectedAssetColor
      : chartColors.level1[i];

    // if the x and y axis equal wind and power get the power curve reference line
    if (
      equals(
        [xAxisSignal.id, yAxisSignal[0]?.id],
        [AxisOptions.POWER_MWH, AxisOptions.WIND_SPEED],
      ) ||
      equals([xAxisSignal.id, yAxisSignal[0]?.id], [AxisOptions.WIND_SPEED, AxisOptions.POWER_MWH])
    ) {
      const axisKey = {
        [AxisOptions.WIND_SPEED]: 'w',
        [AxisOptions.POWER_MWH]: 'p',
      };
      const powerCurveData =
        assetData.asset?.contractPower?.points.map((point) => ({
          x: point[axisKey[xAxisSignal.id]],
          y: point[axisKey[yAxisSignal[0]?.id]],
        })) || [];

      // push power curve series
      series.push({
        name: PowerCurve.name,
        color,
        data: powerCurveData,
        lineWidth: 2,
        tooltipEnabled: false,
        type: ChartType.SPLINE,
        assetId: assetData.asset.id,
        isSelectedAsset,
        isSelectedPointsSeries: false,
        yAxis: 0,
        showInLegend: false,
      });
    }

    const lineWidth = ChartType.SPLINE || type === ChartType.LINE ? 1 : 0;
    yAxisSignal.forEach((ySignal, signalIndex) => {
      let data = chartData?.[ySignal.title] ?? [];
      if (data?.length) {
        let legendColor = color,
          yAxis = 0;
        if (enableMultiAxis) {
          legendColor = colors[signalIndex % colors.length][assetData.assetIndex];
          color = colors[signalIndex % colors.length][assetData.assetIndex];
          if (selection?.points?.length) {
            color = type === ChartType.SCATTER ? hex2rgba(color, 0.1) : hex2rgba(color, 0.3);
          }
          yAxis = ySignal.yAxis;
        }
        series.push({
          name: ySignal.title,
          unit: ySignal.unit,
          yAxis,
          legendColor,
          color,
          data,
          tooltipPointFormatter: tooltipPointFormatter(xAxisSignal, ySignal, asset, series, theme),
          type,
          opacity,
          visible: true,
          lineWidth,
          assetId: assetData.asset.id,
          assetName: assetData.asset.name,
          showInLegend: true,
          allowPointSelect: !!isSelectedAsset,
          isSelectedPointsSeries: false,
          zIndex: signalIndex,
        });
      }
    });
    if (isSelectedAsset && selection?.points?.length) {
      yAxisSignal.forEach((ySignal, signalIndex) => {
        series.push(
          getSelectedSeriesOptions({
            id,
            type,
            color: colors[signalIndex % colors.length][assetData.assetIndex],
            ySignal,
            selection,
            data: chartData?.[ySignal.title] ?? [],
            zIndex: yAxisSignal.length + signalIndex,
            assetId: assetData.asset.id,
            theme,
            enableMultiAxis,
          }),
        );
      });
    }
  };

  data.forEach(addSeriesData);

  series.sort((a, b) => {
    if (a.zIndex > b.zIndex) {
      return 1;
    } else if (a.zIndex < b.zIndex) {
      return -1;
    }
    return 0;
  });

  return series;
};

export const DataExplorerChartContainer = withTheme(
  ({ className, id, theme, height, chartDef, onPromote, index }) => {
    const [series, setSeries] = useState([]);
    const [showSignalDialog, setShowSignalDialog] = useState(false);
    const [xAxisSignal, setXAxisSignal] = useState();
    const [yAxisSignal, setYAxisSignal] = useState([]);
    const [useBoostMode] = useState(true);

    const {
      queryParam,
      assetState: {
        signalData,
        kpiDateRange,
        selectedAssetId,
        chartsArray,
        setChartsArray,
        chartFilter,
        comparisonAssets,
      },
    } = useAssetContext();

    const {
      enableMultiAxis,
      chartApiRefs,
      syncZoom,
      selection,
      setZoom,
      resetZoom,
      setSelection,
      setIsLoading,
      onRemoveChart,
    } = useDataExplorerContext();

    const { isLoading, chartData, refetch, error } = useAssetSignalData({
      assetIds: queryParam.assets,
      xAxisSignal,
      yAxisSignal,
      startDate: kpiDateRange.startDate?.entityTimezone,
      endDate: kpiDateRange.endDate?.entityTimezone,
      chartFilter,
    });

    // api is defined one time when charts are created
    const [chartApi, _setChartApi] = useState();
    const chartRef = useRef();
    const chartApiRef = useRef(chartApi);

    // do we have a custom hook for stateRef already (that can take a callback that accepts prev state)?

    const setChartApi = (api) => {
      let state = api;

      if (typeof api === 'function') {
        state = api(chartApiRef.current);
      }

      chartApiRef.current = state;
      chartApiRefs.current[id] = state;

      _setChartApi(state);
    };

    useEffect(() => {
      setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

    useEffect(() => {
      // get chart signal object by id from signals data
      setXAxisSignal(getSignal(signalData, chartDef.xAxis));
      const ySignals = chartDef.yAxis?.map((ySignal) => getSignal(signalData, ySignal)) ?? [];
      if (enableMultiAxis) {
        setYAxisSignal(ySignals);
      } else {
        setYAxisSignal(ySignals.filter((_, i) => i === 0));
      }
    }, [signalData, chartDef.xAxis, chartDef.yAxis, enableMultiAxis]);

    const handleUpdateChart = useCallback(
      (chart) => {
        // update chart in array list
        const updatedChartsArray = [...chartsArray];
        updatedChartsArray[index] = chart;
        setChartsArray(updatedChartsArray);
      },
      [setChartsArray, chartsArray, index],
    );

    const setCurrentType = useCallback(
      (type) => {
        const updateChart = chartDef;
        updateChart.type = type;
        handleUpdateChart(updateChart);
      },
      [chartDef, handleUpdateChart],
    );

    useEffect(() => {
      if (chartDef.type === ChartType.SCATTER) return;
      // if the x or y changes away from time reset the chart type to scatter
      if (chartDef?.xAxis?.id !== TimeSignal.id && chartDef?.yAxis?.id !== TimeSignal.id)
        setCurrentType(ChartType.SCATTER);
    }, [chartDef.type, chartDef.xAxis, chartDef.yAxis, setCurrentType]);

    useEffect(() => {
      if (chartData.data.length === 0 || isLoading) return setSeries([]);

      const updateSeries = getChartSeries({
        id,
        theme,
        type: chartDef.type,
        xAxisSignal: xAxisSignal,
        yAxisSignal: yAxisSignal,
        data: chartData.data,
        filter: chartFilter,
        comparisonAssets,
        selectedAssetId,
        selection,
        enableMultiAxis,
      });

      setSeries(updateSeries);
    }, [
      id,
      chartData.data,
      chartDef.type,
      xAxisSignal,
      yAxisSignal,
      theme,
      chartFilter,
      comparisonAssets,
      selectedAssetId,
      isLoading,
      selection,
      enableMultiAxis,
    ]);

    const closeSignalModal = useCallback(
      (signals) => {
        const updateChart = chartDef;
        updateChart.xAxis = signals.x;
        updateChart.yAxis = signals.y;

        handleUpdateChart(updateChart);
        setShowSignalDialog(false);
      },
      [setShowSignalDialog, chartDef, handleUpdateChart],
    );

    const handleLegendClick = useCallback((e) => {
      setSeries((prev) =>
        prev.map((v, i) => ({ ...v, visible: i === e.index ? !v.visible : v.visible })),
      );
    }, []);

    const handleRemoveChart = useCallback(() => {
      // remove selected chart and replace with first from chartsArray
      onRemoveChart(id);
      const updatedChartsArray = remove(index, 1, chartsArray);
      setChartsArray(updatedChartsArray);
    }, [id, index, setChartsArray, chartsArray, onRemoveChart]);

    const getAxisTitle = (signal) =>
      signal?.unit ? signal?.name + ' (' + signal?.unit + ')' : signal?.name;

    if (enableMultiAxis) {
      return (
        <DraggableContainer className={className} height={height} index={index}>
          <DataLoader type={chartDef.type} isLoading={isLoading} height={`${height}px`}>
            <DataExplorerChartHeader
              chartDef={chartDef}
              series={series}
              onPromote={onPromote}
              onToggle={(toggle) => setCurrentType(toggle.type)}
              onAddEditSignals={() => setShowSignalDialog(true)}
              onRemove={chartsArray?.length > 1 ? handleRemoveChart : null}
              onLegendClick={handleLegendClick}
            />
            <DataExplorerChart
              ref={chartRef}
              theme={theme}
              isLoading={isLoading}
              noData={!!error}
              height={height}
              type={ChartType.SCATTER}
              xAxis={chartDef.xAxis}
              yAxis={chartDef.yAxis}
              series={series}
              xAxisTitle={getAxisTitle(chartDef.xAxis)}
              xAxisType={chartDef.xAxis?.id === TimeSignal.id ? 'datetime' : undefined}
              xAxisLabelFormatter={xAxisSignal?.id === TimeSignal.id ? getDateLabel : null}
              enableSyncZoom={syncZoom}
              boostThreshold={useBoostMode ? 1 : 0}
              useClustering={true}
              renderCondition={series?.length > 0}
              onRetry={() => refetch({ cancelRefetch: true })}
              onCreated={(chart) => setChartApi(chart)}
              onSelect={(e) => setSelection(e, id)}
              onToggle={(toggle) => setCurrentType(toggle.type)}
              onZoom={(e) => setZoom(e, id)}
              onResetZoom={(e) => resetZoom(e, id)}
            />
            {showSignalDialog && (
              <DataExplorerSignalDialog
                value={{ x: xAxisSignal, y: yAxisSignal }}
                onClose={closeSignalModal}
                onConfirm={closeSignalModal}
                signalData={signalData}
                enableMultiAxis={enableMultiAxis}
              />
            )}
          </DataLoader>
        </DraggableContainer>
      );
    }

    return (
      <>
        <ChartContainer height={height}>
          <ChartHeader>
            <ChartHeaderLeft>
              {onPromote && (
                <PromoteButton onClick={onPromote}>
                  <PromoteIcon />
                </PromoteButton>
              )}
              <SignalBtn onClick={() => setShowSignalDialog(true)} disabled={!signalData}>
                <Legend>
                  <span>X: {chartDef.xAxis?.title ?? chartDef.xAxis?.name ?? '-'}</span>
                </Legend>
                <Legend>
                  <span>Y: {chartDef.yAxis?.[0]?.title ?? '-'}</span>
                </Legend>
              </SignalBtn>
            </ChartHeaderLeft>
            <ToggleContainer>
              {xAxisSignal?.id === AxisOptions.TIME && (
                <DashboardChartToggle
                  currentType={TOGGLE_TYPES.filter((toggle) => toggle.id === chartDef.type)[0]}
                  onToggle={(toggle) => setCurrentType(toggle.type)}
                  types={TOGGLE_TYPES}
                />
              )}
              {chartsArray?.length > 1 && (
                <RemoveButton onClick={() => handleRemoveChart()}>
                  <RemoveIcon />
                </RemoveButton>
              )}
            </ToggleContainer>
          </ChartHeader>
          <Chart
            height={height}
            noDataLabel={''}
            series={series}
            type={chartDef.type}
            yAxisVisible={true}
            xAxisTitle={getAxisTitle(xAxisSignal)}
            yAxisTitle={getAxisTitle(yAxisSignal)}
            yAxisOpposite={false}
            yAxisOffset={-4}
            xAxisLabelFormatter={xAxisSignal?.id === TimeSignal.id ? getDateLabel : null}
            selectable
            onCreated={(chart) => setChartApi(chart)}
            onSelect={(e) => setSelection({ ...e, selected: series?.[0]?.name }, id)}
            zoom
            isLoading={isLoading}
            noData={!!error}
            onRetry={() => refetch({ cancelRefetch: true })}
            renderCondition={series.length !== 0}
            boostThreshold={useBoostMode ? 1 : 0}
            useClustering={true}
          />
        </ChartContainer>
        {showSignalDialog && (
          <DataExplorerSignalDialog
            value={{ x: xAxisSignal, y: yAxisSignal }}
            onClose={closeSignalModal}
            onConfirm={closeSignalModal}
            signalData={signalData}
            enableMultiAxis={enableMultiAxis}
          />
        )}
      </>
    );
  },
);

DataExplorerChartContainer.propTypes = {
  namespace: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.number,
  chartDef: PropTypes.instanceOf(Object),
  yAxisOpposite: PropTypes.bool,
  onPromote: PropTypes.func,
  index: PropTypes.number,
};

DataExplorerChartContainer.defaultProps = {
  namespace: 'analyze.data-explorer',
  className: '',
  id: '',
  height: null,
  yAxisOpposite: true,
  onPromote: null,
  index: 0,
};

DataExplorerChartContainer.displayName = 'DataExplorerChartContainer';
