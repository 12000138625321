import { getLoadable } from '@ge/shared/util';

export const popoutLoadables = {
  site: getLoadable(() => import('./site-popout')),
  turbine: getLoadable(() => import('./asset-popout')),
  event: getLoadable(() => import('./event-popout')),
  case: getLoadable(() => import('./case-popout')),
  fhp: getLoadable(() => import('./fhp-popout')),
  ticket: getLoadable(() => import('./ticket-popout')),
  task: getLoadable(() => import('./task-popout')),
  newTicket: getLoadable(() => import('./new-ticket-popout')),
  caseProcedure: getLoadable(() => import('./case-procedure-popout')),
  inspectionDamage: getLoadable(() => import('./inspection-damage-popout')),
  escalateCase: getLoadable(() => import('./escalate-case-popout')),
};
