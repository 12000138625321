import PropTypes from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { Icon, Icons } from '@ge/components/icon';

const DEFAULT_ICON = Icons.LINE;
const ICON_SIZE = 12;

export const DashboardChartIcon = withTheme(({ size, theme, type, viewbox }) => {
  // TODO: update to support all the icons we need
  const icon = {
    [ChartType.COLUMN_DURATION]: Icons.COLUMN_DURATION,
    [ChartType.COLUMN_SITE_ACTIVE]: Icons.COLUMN_SITE_ACTIVE,
    [ChartType.COLUMN]: Icons.COLUMN,
    [ChartType.DONUT]: Icons.DONUT,
    [ChartType.LINE]: Icons.LINE,
    [ChartType.MIRROR_COLUMN]: Icons.MIRROR_COLUMN,
    [ChartType.PARETO]: Icons.PARETO,
    [ChartType.SCATTER]: Icons.SCATTER,
    [ChartType.SPLINE]: Icons.LINE,
  }[type];

  return (
    <Icon
      icon={icon}
      size={size}
      viewbox={viewbox}
      color={theme.analyze.dashboard.toggleButtonIconColor}
    />
  );
});

DashboardChartIcon.propTypes = {
  size: PropTypes.number,
  type: PropTypes.string,
};

DashboardChartIcon.defaultProps = {
  size: ICON_SIZE,
  type: DEFAULT_ICON,
};

export const DashboardTitle = styled.h3`
  text-transform: capitalize;
  font-size: 14px;
  height: 17px;
  line-height: 17px;
  margin-bottom: 2px;
`;
