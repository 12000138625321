import { ChartType } from '@ge/components/charts';
import { TimeAggr } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import { KpiCategorySeriesType, KPIList } from '../models';

export const storageSeverityOptions = [
  {
    id: 'severity',
    default: 1,
    options: [
      {
        value: 1,
        label: 'Critical',
      },
      {
        value: 2,
        label: 'HIGH',
      },
      {
        value: 3,
        label: 'MED',
      },
      {
        value: 4,
        label: 'LOW',
      },
      {
        value: 5,
        label: 'INFO',
      },
      {
        value: 6,
        label: 'ALL',
      },
    ],
  },
];

/**
 * RULES
 *
 *
 *
 *
 */
export const storageKPISelectorData = [
  {
    id: 'availability',
    value: 'Availability',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'raw-availability',
        value: 'Raw Availability',
        component: {
          props: null,
          path: 'dynamic-components/site/kpi-chart-storage/kpi-chart-storage',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'raw-availability-date', label: 'Date' },
              { value: 'raw-availability-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'contratual-availability',
        value: 'Contractual Availability',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'contratual-availability-date', label: 'Date' },
              { value: 'contratual-availability-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'performance',
    value: 'Performance',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'power',
        value: 'Power',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'power-date', label: 'Date' },
              { value: 'power-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'daSoC',
        value: 'DASoC',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'daSoC-date', label: 'Date' },
              { value: 'daSoC-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'cyclesPerDay',
        value: 'Cycles / day',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'cyclesPerDay-date', label: 'Date' },
              { value: 'cyclesPerDay-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'roundtrip-efficiency',
        value: 'Roundtrip Efficiency',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'roundtrip-efficiency-date', label: 'Date' },
              { value: 'roundtrip-efficiency-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'energy-throughput',
        value: 'Energy Throughput',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'energy-throughput-date', label: 'Date' },
              { value: 'energy-throughput-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'ambTemp',
        value: 'RSU Avg. Ambient Temperature',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'ambTemp-date', label: 'Date' },
              { value: 'ambTemp-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
      {
        id: 'SoH',
        value: 'SoH',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [
          {
            id: 'reportBy',
            options: [
              { value: 'SoH-date', label: 'Date' },
              { value: 'SoH-inverter', label: 'Inverters' },
            ],
          },
        ],
      },
    ],
  },
];

export const storageAssetKPISelectorData = [
  {
    id: 'performance',
    value: 'Performance',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'energy-trend',
        value: 'hybrids-kpi-chart',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
  {
    id: 'availability',
    value: 'Availability',
    component: {
      name: null,
      props: null,
      path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
    },
    childList: [
      {
        id: 'availability-trend',
        value: 'Availability Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
  {
    id: 'reliability',
    value: 'Reliability',
    component: {
      props: null,
      path: 'dynamic-components/storage/reliability/reliability',
    },
    filterList: [
      {
        id: 'reportBy',
        options: [
          { value: 'duration', label: 'Event Duration' },
          { value: 'count', label: 'Event Occurrence' },
          { value: 'mttr', label: 'MTTR' },
        ],
      },
      ...storageSeverityOptions,
    ],
  },
];

const siteStoragePrformanceOptions = {
  'power-date': {
    kpiKeys: [
      {
        key: KPIList.BESS_CHARGE_ENERGY,
        type: ChartType.COLUMN,
        color: globalColors.pine5,
        yAxis: 0,
      },
      {
        key: KPIList.BESS_DISCHARGE_ENERGY,
        type: ChartType.COLUMN,
        color: globalColors.magenta3,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 1,
    timeAggr: TimeAggr.DAILY,
  },
  'power-inverter': {
    kpiKeys: [
      {
        key: KPIList.ACTUAL_POWER,
        type: ChartType.SPLINE,
        color: globalColors.pine5,
        yAxis: 0,
      },
    ],
    type: ChartType.SPLINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
  },
};

const siteStorageAvailabilityOptions = {
  'raw-availability-date': {
    categories: [
      {
        key: KPIList.RAW_AVAILABILITY_DATE,
        type: ChartType.COLUMN,
        color: globalColors.straw5,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisAllowDecimals: true,
    yAxislabelsYPosition: -5,
  },
  'raw-availability-inverter': {
    categories: [
      {
        key: KPIList.RAW_AVAILABILITY_INVERTER,
        type: ChartType.COLUMN,
        color: globalColors.straw5,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.ENTITY,
    timeAggr: TimeAggr.DAILY,
    xAxisLabelVisible: false,
    firstTimeRender: false,
    yAxisAllowDecimals: true,
    yAxislabelsYPosition: -5,
  },
  'contratual-availability-date': {
    kpiKeys: [
      {
        key: KPIList.AVAILABILITY_TREND,
        type: ChartType.SPLINE,
        color: globalColors.straw5,
        yAxis: 0,
      },
    ],
    type: ChartType.SPLINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 1,
    timeAggr: TimeAggr.DAILY,
  },
  'contratual-availability-inverter': {
    kpiKeys: [
      {
        key: KPIList.AVAILABILITY_TREND,
        type: ChartType.COLUMN,
        color: globalColors.straw5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 1,
    timeAggr: TimeAggr.DAILY,
  },
};

export const siteStorageChartConfig = {
  ['performance']: siteStoragePrformanceOptions,
  ['availability']: siteStorageAvailabilityOptions,
};
