import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateRecurrence } from './create-recurrence';
import { EditRecurrence } from './edit-recurrence';
import { ViewRecurrence } from './view-recurrence';

export const RecurrenceSection = ({ section, task, templateMode }) => {
  const { collapsed, metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return <CreateRecurrence collapsed={collapsed} metadata={metadata} templateMode={templateMode} />;
      case TaskTemplateModes.EDIT:
        return <EditRecurrence metadata={metadata} task={task} templateMode={templateMode} />;
      case TaskTemplateModes.VIEW:
        return <ViewRecurrence metadata={metadata} task={task} />;
      default:
        return null;
    }
  }
};

RecurrenceSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

RecurrenceSection.defaultProps = {
  task: {},
};
