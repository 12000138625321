import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import { path } from 'ramda';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { MiniBarChart } from '@ge/components/charts';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { DraggableTable, TableTitle, DynamicTable, TableArrow } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { formatKPIValue } from '@ge/feat-analyze/util';
import { KpiCategoryDefs, Placeholders, EntityTab } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';

import { EntityDetailsContext } from '../../context';
import { AssetsColumnDefs, AssetsColumns } from '../../models/table-col-defs';
import { getTurbineStateColor, getTurbineStateType, getAssetIcon } from '../../util';

// Number of hours to show alert for, will probably become a user setting
const DURATION_ALERT = 4;

const WindIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.WIND_DIRECTION,
  color: props.theme.table.arrowColor,
  rotate: props.rotate || 0,
  viewbox: '0 0 12 20',
}))`
  vertical-align: initial;
`;

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.theme.table.iconLightColor,
}))`
  margin-top: 2px;
  margin-right: 4px;
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ADD,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const TrendArrowIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: props.negativeTrend ? Icons.ARROW_DOWN : Icons.ARROW_UP,
  color: props.negativeTrend ? props.theme.table.iconErrorColor : props.theme.table.iconLightColor,
}))``;

const NewButton = styled.button`
  border: 1px solid ${(props) => props.theme.analyze.table.newButtonBorderColor};
  border-radius: 2px;
  background: linear-gradient(
    179.99deg,
    #445a6c 0%,
    ${globalColors.slate2} 62.55%,
    ${globalColors.slate2} 100%
  );
  padding: 0;
  height: 20px;
  width: 20px;
`;

const NoWrap = styled.div`
  white-space: nowrap;
`;

const SetWidth = styled.span.attrs((props) => ({
  width: props.width,
}))`
  display: inline-block;
  padding-right: 8px;
  text-align: right;
  width: ${(props) => props.width};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiniBarContainer = styled.div`
  padding-left: 20px;
`;

const MetricSeparator = styled.span`
  color: ${(props) => props.theme.table.metricSeparator};

  :after {
    content: '/';
    padding: 0 2px;
  }
`;

const CaseDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

const EmptyCell = styled.span`
  :after {
    content: '-';
  }
`;

const MetricSmall = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
`;

const TwoMetricCell = ({ metric1, metric2 }) => (
  <span>
    <MetricSmall>{metric1}</MetricSmall>
    <MetricSeparator />
    <MetricSmall>{metric2}</MetricSmall>
  </span>
);

TwoMetricCell.propTypes = {
  metric1: PropTypes.string.isRequired,
  metric2: PropTypes.string.isRequired,
};

const PercentTrend = ({ percent, negativeTrend }) => (
  <FlexContainer>
    <TrendArrowIcon negativeTrend={negativeTrend} />
    <MetricSmall>{percent}%</MetricSmall>
  </FlexContainer>
);

PercentTrend.propTypes = {
  percent: PropTypes.number.isRequired,
  negativeTrend: PropTypes.bool.isRequired,
};

const AssetsTable = ({
  assets: assetsArray,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onAssetSelect,
  onDrop,
  scrollable,
  draggable,
  className,
  onFilter,
  onFilterChange,
  filterValues,
  filterDefs,
}) => {
  const { t, ready, i18n } = useTranslation(['monitor.assets'], { useSuspense: false });
  const { getSiteById } = useStoreState((store) => store.sites);

  const sortedDirection = useCallback((metric) => (metric === sortMetric ? sortDirection : ''), [
    sortMetric,
    sortDirection,
  ]);

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilter(columnKey, value);
    },
    [onFilter],
  );

  const handleFilterChange = useCallback((_, key, value) => onFilterChange({ key, value }), [
    onFilterChange,
  ]);

  const getAutomationString = useCallback(
    (automation) => {
      if (automation) {
        return t('table.Y', 'Y');
      }
      return t('table.N', 'N');
    },
    [t],
  );

  const { showAssetDetails } = useContext(EntityDetailsContext);

  const handleAssetSelect = useCallback(
    (e, asset, tab) => {
      killEventPropagation(e);
      showAssetDetails(asset.id, tab);
    },
    [showAssetDetails],
  );

  /**
   * Function used by useTableFactories to bootstrap the cell values map
   * before returning the factory to process cells for that row. This should
   * contain values for all columns that should be expected to show data in
   * the table.
   */
  const cellValueMapFn = useCallback(
    (asset) => {
      if (!asset || !asset.site || !asset.metrics) {
        return {};
      }
      const site = getSiteById(asset.site.id);

      // Required details
      const {
        automation,
        conditions,
        events,
        state,
        performance = {},
        production = {},
        maintenance,
        readings,
        revenue,
        tasks,
      } = asset.metrics;

      const { customer } = site || {};
      const { causalEvent } = events || {};
      const { direction, wind, windSpeedAverage } = conditions || {};
      const formatKpiWithSelectedLanguage = (number) => formatKPIValue(number, i18n.language);

      const getKpiValue = (kpi, isformatKpi) => {
        let value = Placeholders.DASH;
        // return '-' only if value is not available
        if (performance[kpi] || performance[kpi] === 0)
          value = isformatKpi
            ? formatKpiWithSelectedLanguage(performance[kpi])
            : `${performance[kpi]}`;
        return value;
      };

      // Define and set the cell value mapping for each column. This will feed both
      // primitive cells and custom cells.
      return {
        [AssetsColumns.GROUP]: (site && site.group) || Placeholders.DASH,
        [AssetsColumns.SITE]: (site && site.name) || Placeholders.DASH,
        [AssetsColumns.TYPE]: (asset && asset.type) || Placeholders.DASH,
        [AssetsColumns.ASSET]: (asset && asset.name) || Placeholders.DASH,
        [AssetsColumns.ASSET_DETAIL]: (asset && asset) || Placeholders.DASH,
        [AssetsColumns.STATE]: state || Placeholders.DASH,
        [AssetsColumns.ACTUAL]: (production && production.availActual) || Placeholders.DASH,
        [AssetsColumns.RATED]:
          (asset && asset.ratedPower && asset.ratedPower / 1000) || Placeholders.DASH,
        [AssetsColumns.POWER_CHART]: (production && production.availActual) || Placeholders.DASH,
        [AssetsColumns.CODE]: causalEvent ? causalEvent.code : Placeholders.DASH,
        [AssetsColumns.SEVERITY]: causalEvent ? causalEvent.severity : Placeholders.DASH,
        [AssetsColumns.DESCRIPTION]: causalEvent ? causalEvent.description : Placeholders.DASH,
        [AssetsColumns.FLAGGED]:
          (events && events.flaggedCount > 0 && events.flaggedCount) || Placeholders.DASH,
        [AssetsColumns.LOGIC]:
          (events && events.logicCount > 0 && events.logicCount) || Placeholders.DASH,
        [AssetsColumns.STATE_DURATION]: (events && events.stateDuration) || Placeholders.DASH,
        [AssetsColumns.ANOMALIES]:
          (maintenance && maintenance.anomalies > 0 && maintenance.anomalies) || Placeholders.DASH,
        [AssetsColumns.ROC]: maintenance && maintenance.tickets > 0 && maintenance.tickets,
        [AssetsColumns.SERVICE]: maintenance && maintenance.orders > 0 && maintenance.orders,
        [AssetsColumns.TASKS]: tasks && tasks.length > 0 && tasks.length,
        [AssetsColumns.EVENT_RATE]: (production && production?.faultRate) || Placeholders.DASH,
        [AssetsColumns.WEEKLY_AVAILABILITY]: `${production && production.availProjected}%`,
        [AssetsColumns.WIND]: {
          direction,
          windMS: wind,
        },
        [AssetsColumns.AVERAGE_WINDSPEED]: windSpeedAverage || Placeholders.DASH,

        [AssetsColumns.TEMP]: conditions && (`${conditions.temp}°` || Placeholders.DASH),
        [AssetsColumns.SERIAL_NUMBER]: (asset && asset.serialNumber) || Placeholders.DASH,
        [AssetsColumns.SYSTEM_NUMBER]: (asset && asset.systemNumber) || Placeholders.DASH,
        [AssetsColumns.CUSTOMER]: (customer && customer.name) || Placeholders.DASH,
        [AssetsColumns.ASSET_MAKE]: (asset && asset.make) || Placeholders.DASH,
        [AssetsColumns.ASSET_MODEL]: (asset && asset.model) || Placeholders.DASH,
        [AssetsColumns.REACTIVE_POWER]:
          (readings && readings.general && readings.general.reactivePower) || Placeholders.DASH,
        [AssetsColumns.AUTOMATION_ENABLED]:
          (automation && getAutomationString(automation)) || Placeholders.DASH,
        [AssetsColumns.PRICE_MWH]: (revenue && revenue.priceMW) || Placeholders.DASH,
        [AssetsColumns.LOST_REVENUE]: `$${revenue && revenue.lostRevenue}`,
        [AssetsColumns.SERVICE_LEADER]:
          (maintenance && maintenance.serviceLeader) || Placeholders.DASH,
        [AssetsColumns.CUMULATIVE_PROD]: {
          percent: path(['cumulative', 'percent'], production),
          negativeTrend: path(['cumulative', 'trend'], production) === 'negative',
        },
        [AssetsColumns.AVAIL_TECH]: {
          percent: path(['availTechnical', 'percent'], production),
          negativeTrend: path(['availTechnical', 'trend'], production) === 'negative',
        },
        [AssetsColumns.SPEND_BUDGET]: {
          spend: revenue && revenue.spend ? `${revenue && revenue.spend}%` : Placeholders.DASH,
          budget: revenue && revenue.budget ? `${revenue && revenue.budget}%` : Placeholders.DASH,
        },
        [AssetsColumns.COST_ASSET]: {
          cost: revenue && revenue.cost ? `$${revenue && revenue.cost}k` : Placeholders.DASH,
          asset: revenue && revenue.asset ? `${revenue && revenue.asset}%` : Placeholders.DASH,
        },
        [AssetsColumns.TBA]: getKpiValue(KpiCategoryDefs.TBA, false),
        [AssetsColumns.AVAILABILITY_CONTRACT]: getKpiValue(
          KpiCategoryDefs.AVAILABILITY_CONTRACT,
          false,
        ),
        [AssetsColumns.PBA]: getKpiValue(KpiCategoryDefs.PBA, false),
        [AssetsColumns.UNPRODUCED_ENERGY_MWH]: getKpiValue(
          KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
          true,
        ),
        [AssetsColumns.ACTUAL_PRODUCTION_MWH]: getKpiValue(KpiCategoryDefs.PRODUCTION_ACTUAL, true),
        [AssetsColumns.CAPACITY_FACTOR]: getKpiValue(KpiCategoryDefs.CAPACITY_FACTOR, false),
        [AssetsColumns.EVENT_COVERAGE]: getKpiValue(KpiCategoryDefs.EVENT_COVERAGE, false),
      };
    },
    [getSiteById, getAutomationString, i18n.language],
  );

  /**
   * Factory function to generate custom table cell components in
   * the case that the contents are not simply primitives.
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case AssetsColumns.ASSET:
        case AssetsColumns.DESCRIPTION:
          return <TableTitle>{cellValue}</TableTitle>;
        case AssetsColumns.TYPE:
          return <TypeIcon icon={getAssetIcon(cellValue)} />;
        case AssetsColumns.STATE:
          return (
            <Badge
              color={getTurbineStateColor(cellValue)}
              label={getTurbineStateType(cellValue)}
              fullWidth
            />
          );
        case AssetsColumns.POWER_CHART:
          return <MiniBarChart percent={cellValue} altColor />;
        case AssetsColumns.CUMULATIVE_PROD:
          return (
            <FlexContainer>
              {typeof cellValue.negativeTrend !== 'undefined' &&
              typeof cellValue.percent !== 'undefined' ? (
                <PercentTrend percent={cellValue.percent} negativeTrend={cellValue.negativeTrend} />
              ) : (
                <EmptyCell />
              )}
              <MiniBarContainer>
                <MiniBarChart
                  width="90px"
                  percent={cellValue.percent}
                  danger={cellValue.negativeTrend}
                />
              </MiniBarContainer>
            </FlexContainer>
          );
        case AssetsColumns.AVAIL_TECH:
          return typeof cellValue.negativeTrend !== 'undefined' &&
            typeof cellValue.percent !== 'undefined' ? (
            <PercentTrend percent={cellValue.percent} negativeTrend={cellValue.negativeTrend} />
          ) : (
            <span>-</span>
          );
        case AssetsColumns.SPEND_BUDGET:
          return <TwoMetricCell metric1={cellValue.spend} metric2={cellValue.budget} />;
        case AssetsColumns.COST_ASSET:
          return <TwoMetricCell metric1={cellValue.cost} metric2={cellValue.asset} />;
        case AssetsColumns.SEVERITY:
          return cellValue && <Severity level={cellValue} />;
        case AssetsColumns.FLAGGED:
        case AssetsColumns.LOGIC:
        case AssetsColumns.ANOMALIES:
        case AssetsColumns.ROC:
        case AssetsColumns.SERVICE:
          return (
            cellValue > 0 && (
              <Badge color={globalColors.stone1} label={cellValue.toString()} small />
            )
          );
        case AssetsColumns.STATE_DURATION:
          // TODO: &&?
          return (
            <span className={cellValue >= DURATION_ALERT ? 'danger-txt' : ''}>{cellValue}</span>
          );
        case AssetsColumns.TASKS:
          return (
            cellValue > 0 && (
              <Badge color={globalColors.stone1} label={cellValue.toString()} small />
            )
          );
        case AssetsColumns.NEW:
          return (
            <NewButton
              // eslint-disable-next-line no-console
              onClick={() => console.log('New Click')}
            >
              <AddIcon />
            </NewButton>
          );
        case AssetsColumns.WIND:
          return (
            <NoWrap>
              <WindIcon rotate={cellValue.direction} />
              <SetWidth width="32px">{cellValue.windMS || Placeholders.DASH}</SetWidth>
            </NoWrap>
          );
        case AssetsColumns.ASSET_DETAIL:
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          return (
            <CaseDetailButton
              type="button"
              onClick={(e) => handleAssetSelect(e, cellValue, EntityTab.PERFORMANCE)}
            >
              <TableArrow />
            </CaseDetailButton>
          );
        default:
          return null;
      }
    },
    [handleAssetSelect],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: AssetsColumnDefs,
    cellValueMapFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
    filterValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
  });

  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: assetsArray,
    onValueSelect: onAssetSelect,
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
  };

  const tableEl = React.createElement(draggable ? DraggableTable : DynamicTable, dynamicProps);

  return <PageContainer i18nReady={ready}>{tableEl}</PageContainer>;
};

AssetsTable.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onAssetSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  className: PropTypes.string,
  assetConditionData: PropTypes.instanceOf(Object),
  filterValues: PropTypes.object,
  filterDefs: PropTypes.object,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
};

AssetsTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onAssetSelect: () => null,
  onDrop: () => null,
  scrollable: true,
  draggable: false,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
  filterDefs: null,
  assets: [],
};

export default AssetsTable;
