const innerJoin = require('ramda/src/innerJoin');
const path = require('ramda/src/path');

/**
 * Create a map of id -> object
 * @param array the data array
 * @param idPath the id path
 * @param additionalProps additional properties to add to items in the object
 * @param buildObject the object to use (defaults to empty)
 * @returns {*} map of id to single object
 */
const byId = (array, idPath = ['id'], additionalProps = {}, buildObject = {}) =>
  array.reduce((acc, o) => {
    acc[path(idPath, o)] = { ...o, ...additionalProps };
    return acc;
  }, buildObject);

/**
 * Group an array of objects by the specified id
 * @param data the data array
 * @param idPath the id path
 * @returns {*} a map of ids to array of objects
 */
const groupById = (data, idPath) =>
  data.reduce((acc, item) => {
    if (!path(idPath, item)) return acc;
    acc[path(idPath, item)] = acc[path(idPath, item)] || [];
    acc[path(idPath, item)].push(item);
    return acc;
  }, {});

/**
 * Get unique values from an array of objects.
 * @param arr the data array
 * @param valPath the path for uniqueness
 * @returns {*}
 */
const uniqueVals = (arr, valPath = [], lowerCase = false) =>
  arr.reduce(
    (acc, item) => [
      ...new Set([...acc, lowerCase ? path(valPath, item).toLowerCase() : path(valPath, item)]),
    ],
    [],
  );

/**
 * Get a range of numbers.
 * @param size the size
 * @param startAt the starting point
 * @return {unknown[]}
 */
const range = (size, startAt = 0) => [...Array(size).keys()].map((i) => i + startAt);

const removeFalsyVals = (array) => array.filter(Boolean);

const insertAtIndexFn = (array, index, newItem) => {
  const newArray = [...array];
  newArray.splice(index, 0, newItem);
  return newArray;
};

const sortedArrByElement = (array, elementToBeFirst) =>
  array.reduce((acc, element) => {
    if (elementToBeFirst === element) {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []);

const intersectBy = (idPath, items) => {
  const [first, ...rest] = items || [];
  return rest && rest.length > 0
    ? rest.reduce(
        (acc, current) => innerJoin((a, b) => path(idPath, a) === path(idPath, b), acc, current),
        first,
      )
    : first;
};

module.exports = {
  byId,
  groupById,
  range,
  uniqueVals,
  removeFalsyVals,
  insertAtIndexFn,
  sortedArrByElement,
  intersectBy,
};
