import { useStoreState, useStoreActions } from 'easy-peasy';
import React, { useEffect, useContext } from 'react';
import { withTheme } from 'styled-components';

import { SpinLoader } from '@ge/components/loader';

import { PersonContext } from '../person-context';

import { MainWrapper } from './person-assets-components/person-assets-wrappers/wrapper';
import TabsGenerator from './tabs-generator-components/tabs-generator-component';

export const PersonAssets = withTheme(({ theme }) => {
  const { providedAttributes, setProvidedAttributes } = useContext(PersonContext);

  useEffect(() => {
    let filteredAttributes = providedAttributes?.filter(
      (attribute) => attribute.roleName !== 'New_User_Role',
    );
    setProvidedAttributes(() => filteredAttributes);
    // eslint-disable-next-line
  }, []);

  const { activeTab, editStatus, loaderStatus } = useStoreState(
    (state) => state.tabflow.personAssets,
  );
  const { loadChanges } = useStoreActions((actions) => actions.tabflow);

  useEffect(() => {
    loadChanges('load');
  }, [activeTab, editStatus, loadChanges]);

  return (
    <MainWrapper className="person-assets-content">
      <SpinLoader showLoader={loaderStatus} className="person-assets-loader-manual-styles" />

      {editStatus && <TabsGenerator />}

      {editStatus === false && (
        <div
          className="person-assets-view-mode"
          style={{ backgroundColor: theme.admin.panel.entityRow.light }}
        >
          <TabsGenerator />
        </div>
      )}
    </MainWrapper>
  );
});
