import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSection, StyledCollapsiblePanel } from '../../../task-template-shared';

export const CreateNoteAttachment = ({ collapsed }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <StyledCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{t('section_header.note_or_attachment', 'Note or Attachment')}</h4>}
      plusIcon
    >
      <FormSection></FormSection>
    </StyledCollapsiblePanel>
  );
};

CreateNoteAttachment.propTypes = {
  collapsed: PropTypes.bool,
};

CreateNoteAttachment.defaultProps = {
  collapsed: true,
};
