import PropTypes from 'prop-types';

export const pointPropType = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
});

export const regionPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cls: PropTypes.string,
  color: PropTypes.any,
  editingLabels: PropTypes.bool,
  highlighted: PropTypes.bool,
  expandingWidth: PropTypes.number,
  open: PropTypes.bool,
  candidatePoint: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  visible: PropTypes.bool,
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  x: PropTypes.number,
  y: PropTypes.number,
  w: PropTypes.number,
  h: PropTypes.number,
});

export const imagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  attributes: PropTypes.shape({
    blade_index: PropTypes.number,
    defect_flag: PropTypes.oneOf(['false', 'true']),
    frame: PropTypes.number,
    has_annotations: PropTypes.oneOf(['false', 'true']),
    height: PropTypes.number,
    image_distance: PropTypes.number,
    imgname: PropTypes.string,
    included_in_report: PropTypes.oneOf(['false', 'true']),
    jobstable_lid: PropTypes.number,
    num_adr_annotations: PropTypes.number,
    num_human_annotations: PropTypes.number,
    num_3rd_party_annotations: PropTypes.number,
    scaling: PropTypes.number,
    source_video_filename: PropTypes.string,
    viewed: PropTypes.oneOf(['false', 'true']),
    width: PropTypes.number,
  }).isRequired,
});

export const AnnotatorConstants = {
  ON_CLS_ADDED: 'ON_CLS_ADDED',
  MOUSE_MOVE: 'MOUSE_MOVE',
  MOUSE: 'MOUSE',
  MOUSE_UP: 'MOUSE_UP',
  LAST_MOUSE_MOVE_CALL: 'lastMouseMoveCall',
  LAST_ACTION: 'lastAction',
  CREATE_BOX: 'create-box',
  REGIONS: 'regions',
  SELECTED_IMAGE: 'selectedImage',
  SELECTED_IMAGE_FRAME_TIME: 'selectedImageFrameTime',
  INIT: '@@INIT',
  SELECT_IMAGE: 'SELECT_IMAGE',
  CHANGE_REGION: 'CHANGE_REGION',
  CHANGE_IMAGE: 'CHANGE_IMAGE',
  SELECT_REGION: 'SELECT_REGION',
  BEGIN_MOVE_POINT: 'BEGIN_MOVE_POINT',
  BEGIN_MOVE_POLYGON_POINT: 'BEGIN_MOVE_POLYGON_POINT',
  MOVE_REGION: 'MOVE_REGION',
  MODE: 'mode',
  BEGIN_BOX_TRANSFORM: 'BEGIN_BOX_TRANSFORM',
  RESIZE_BOX: 'RESIZE_BOX',
  DRAW_POLYGON: 'DRAW_POLYGON',
  MOVE_POLYGON_POINT: 'MOVE_POLYGON_POINT',
  POINTS: 'points',
  CLS: 'cls',
  TAGS: 'tags',
  FREEDOM: 'freedom',
  DRAW_RULER: 'DRAW_RULER',
  DRAW_EXPANDING_LINE: 'DRAW_EXPANDING_LINE',
  SET_EXPANDING_LINE_WIDTH: 'SET_EXPANDING_LINE_WIDTH',
  EXPANDING_WIDTH: 'expandingWidth',
  MOUSE_DOWN: 'MOUSE_DOWN',
  MOUSE_DOWN_AT: 'mouseDownAt',
  HUMAN: 'human',
  CREATE_POINT: 'create-point',
  POINT: 'point',
  BOX: 'box',
  CREATE_POLYGON: 'create-polygon',
  POLYGON: 'polygon',
  CREATE_RULER: 'create-ruler',
  RULER: 'ruler',
  CREATE_EXPANDING_LINE: 'create-expanding-line',
  EXPANDING_LINE: 'expanding-line',
  EDITING_LABELS: 'editingLabels',
  HIGHLIGHTED: 'highlighted',
  CREATE_POINT_LINE: 'CREATE_POINT_LINE',
  OPEN_REGION_EDITOR: 'OPEN_REGION_EDITOR',
  CLOSE_REGION_EDITOR: 'CLOSE_REGION_EDITOR',
  DELETE_REGION: 'DELETE_REGION',
  HEADER_BUTTON_CLICKED: 'HEADER_BUTTON_CLICKED',
  PREV: 'prev',
  NEXT: 'next',
  CLONE: 'clone',
  IMAGES: 'images',
  SETTINGS: 'settings',
  SETTINGS_OPEN: 'settingsOpen',
  HELP: 'help',
  FULLSCREEN: 'fullscreen',
  FULL_SCREEN: 'fullScreen',
  EXIT_FULLSCREEN: 'exit fullscreen',
  WINDOW: 'window',
  HOTKEYS: 'hotkeys',
  EXIT: 'exit',
  DONE: 'done',
  SELECT_TOOL: 'SELECT_TOOL',
  SHOW_DASH_TAGS: 'show-tags',
  SHOW_TAGS: 'showTags',
  SHOW_DASH_MASK: 'show-mask',
  SHOW_MASK: 'showMask',
  SELECTED_TOOL: 'selectedTool',
  CANCEL: 'CANCEL',
  SELECT: 'select',
  HISTORY: 'history',
  IMAGE: 'image',
  VIDEO: 'video',
  KEYFRAMES: 'keyframes',
  BUTTON_NAME: 'buttonName',
  ADR: 'adr',
  CREATE: 'create',
  PAN: 'pan',
  ZOOM: 'zoom',
  REGION: 'region',
  DIRECTIONS: 'directions',
  POINT_INDEX: 'pointIndex',
  ADD_POLYGON_POINT: 'ADD_POLYGON_POINT',
  IMAGE_LOADED: 'IMAGE_LOADED',
  IMAGE_OR_VIDEO_LOADED: 'IMAGE_OR_VIDEO_LOADED',
  METADATA: 'metadata',
  CHANGE_VIDEO_TIME: 'CHANGE_VIDEO_TIME',
  NEW_TIME: 'newTime',
  CHANGE_VIDEO_PLAYING: 'CHANGE_VIDEO_PLAYING',
  IS_PLAYING: 'isPlaying',
  IMAGES_UPDATED: 'IMAGES_UPDATED',
  CURRENT_IMAGE: 'currentImage',
  IMAGE_ENHANCER: 'image_enhancer',
  THIRD_PARTY: 'third party',
};
