import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Placeholders } from '@ge/models/constants';

const MessageContainer = styled.div`
  text-transform: uppercase;
  .command {
    font-weight: 700;
  }
`;

export const CommandMessage = ({ command, status }) => {
  const { ready, t } = useTranslation(['monitor.assets'], { useSuspense: false });

  if (!ready) {
    return Placeholders.DOUBLE_DASH;
  }

  return (
    <MessageContainer>
      <span className="command">{t(`notification.${command}`, 'Command')}</span>{' '}
      {t(`notification.${status}`)}
    </MessageContainer>
  );
};

CommandMessage.propTypes = {
  command: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
