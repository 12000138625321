import { EntityType, KpiCategoryDefs } from '@ge/models/constants';

import { GaugeCategoryType } from './constants';

// TODO: add defs for specific entity types if they end up being different

const EntityDialGaugeDefs = [
  {
    id: `dialGauge_${KpiCategoryDefs.TBA}`,
    label: ['kpi.tba', 'TBA - Technical'],
    value: KpiCategoryDefs.TBA,
  },
  {
    id: `dialGauge_${KpiCategoryDefs.PBA}`,
    label: ['kpi.pba', 'PBA - Technical'],
    value: KpiCategoryDefs.PBA,
  },
  // removed for current MVP
  // {
  //   id: `dialGauge_${KpiCategoryDefs.AVAILABILITY_CONTRACT}`,
  //   label: ['kpi.contractual_avail', 'Contractual Avail.'],
  //   value: KpiCategoryDefs.AVAILABILITY_CONTRACT,
  // },
];

const EntityKpiDefs = [
  {
    id: `kpi_${KpiCategoryDefs.PRODUCTION_LOST_CONTRACT}`,
    label: ['kpi.lost_production', 'Lost Production'],
    value: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
  },
  {
    id: `kpi_${KpiCategoryDefs.PRODUCTION_ACTUAL}`,
    label: ['kpi.actual_production', 'Actual Production'],
    value: KpiCategoryDefs.PRODUCTION_ACTUAL,
  },
  {
    id: `kpi_${KpiCategoryDefs.CAPACITY_FACTOR}`,
    label: ['kpi.capacity_factor', 'Capacity Factor'],
    value: KpiCategoryDefs.CAPACITY_FACTOR,
  },
  {
    id: `kpi_${KpiCategoryDefs.EVENT_COVERAGE}`,
    label: ['kpi.event_coverage', 'Event Coverage'],
    value: KpiCategoryDefs.EVENT_COVERAGE,
  },
];

// can make these specific to entity types as needed
const EntityDefs = {
  defaultValues: {
    dialGauge1: KpiCategoryDefs.TBA,
    dialGauge2: KpiCategoryDefs.PBA,
    kpi1: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
    kpi2: KpiCategoryDefs.CAPACITY_FACTOR,
  },
  [GaugeCategoryType.DIAL_GAUGE]: EntityDialGaugeDefs,
  [GaugeCategoryType.KPI]: EntityKpiDefs,
};

export const DashboardGaugeDefs = {
  [EntityType.REGION]: EntityDefs,
  [EntityType.SITE]: EntityDefs,
};

// TODO: make these more dynamic for different categories if required
// for current mvp these are expected to be static
export const PercentageDialGaugeDefs = {
  danger: 96,
  max: 100,
  threshold: 98,
  defaultValue: null,
  warning: 98,
};

export const EnergyDialGaugeDefs = {
  danger: 0,
  max: 0,
  threshold: null,
  defaultValue: null,
  warning: 0,
};

// TODO: expand on this as needed
export const DialGaugeDefs = {
  // availability
  [KpiCategoryDefs.AVAILABILITY_CONTRACT]: PercentageDialGaugeDefs,
  [KpiCategoryDefs.PBA]: PercentageDialGaugeDefs,
  [KpiCategoryDefs.TBA]: PercentageDialGaugeDefs,
  // data quality
  [KpiCategoryDefs.EVENT_COVERAGE]: PercentageDialGaugeDefs,
  // performance
  [KpiCategoryDefs.CAPACITY_FACTOR]: PercentageDialGaugeDefs,
  [KpiCategoryDefs.PRODUCTION_ACTUAL]: EnergyDialGaugeDefs,
  [KpiCategoryDefs.PRODUCTION_LOST_CONTRACT]: EnergyDialGaugeDefs,
};
