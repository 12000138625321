import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  Capability,
  PermissionScope,
  QueryKey,
  EntityType,
  PageSource,
} from '@ge/models/constants';
import { Config, useAuth, useTaskSource, useTaskWorkScope } from '@ge/shared/data-hooks';
//import { useAuth, useTaskSource, useTaskWorkScope } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { withDefault } from '@ge/shared/util/table-utils';
import { formatHoursAndMinutes } from '@ge/shared/util/time-date';

import { TasksColumns } from '../models/tasks-table-cols';
import { getServiceGroupIds } from '../util/worker-util';

const TasksTableCapability = {
  FIELD_TASKS_VIEW: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  REAL_TIME_PAGES_VIEW: [
    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
  ],
};

const formatComponentCell = (components) =>
  components.reduce(
    (acc, value, index) => (value ? `${acc} ${index !== 0 ? '|' : ''} ${value}` : acc),
    '',
  );

export const useViewTasksPermission = (assetId = null) => {
  const { isAuthorized } = useAuth();
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const assetDetails = getAssetById(assetId);
  const isAuthorizedToViewTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });
  return { isAuthorizedToViewTasks };
};
/**
 * Get all tasks.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */

export const useTasksTable = ({ filters, search, sortMetric, sortDirection, assetId }) => {
  // store
  const { fetchTasksByAssetId } = useStoreActions((store) => store.tasks);
  const getSortedAssetTasks = useStoreState((store) => store.tasks.getSortedAssetTasks);
  // data hooks
  const { isAuthorized } = useAuth();
  const { data: taskSources, isLoading: isTaskSourcesLoading } = useTaskSource({
    entityType: EntityType.ASSET,
    page: PageSource.EXECUTE,
  });

  const { isLoadingWorkScopes, data: taskWorkScopes } = useTaskWorkScope({
    entityType: EntityType.ASSET,
  });

  // hooks
  const { t } = useTranslation(['tasks'], {
    useSuspense: false,
  });

  const assetIds = useMemo(() => (assetId ? [assetId] : []), [assetId]);
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  //Gives list of sites currently selected
  const { currentView } = useStoreState((state) => state.view);
  const { isAuthorizedToViewTasks } = useViewTasksPermission(assetIds);
  const serviceGroupIds = getServiceGroupIds(sortedServiceGroups, currentView).toString();
  const {
    error: assetError,
    isError: isAssetError,
    isFetching: isAssetLoading,
  } = useQuery(
    [QueryKey.ASSET_TASKS + assetId, assetId],
    () => fetchTasksByAssetId({ assetIds, serviceGroupIds }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isAuthorizedToViewTasks,
    },
  );

  let tasks = getSortedAssetTasks(sortMetric, sortDirection);
  const rows = useMemo(() => {
    if (isTaskSourcesLoading || isLoadingWorkScopes) return [];

    return tasks.map((task) => {
      const {
        asset,
        consumedParts,
        expectedParts,
        estDurationHours,
        estDurationMinutes,
        laborHours,
        laborMinutes,
        component1,
        component2,
        component3,
        site,
        priority,
        status,
        title,
        description,
        linked,
        estTechs,
        assignedTechs,
        attachment,
        note,
        resolutionNotes,
        srNumber,
        srStatus,
        flag,
        rdspp,
      } = task;
      const siteId = asset?.site?.id ?? site?.id;

      const authorization = {
        capabilities: TasksTableCapability.FIELD_TASKS_VIEW,
        siteIds: [siteId],
      };

      // task needs to be scoped to a site and have view permissions to be visible
      // TODO: verify if the task service will already filter this according to field-tasks view metadata
      // would be nice to know if some of this is already handled for us
      if (!(siteId && isAuthorized(authorization))) {
        return tasks;
      }

      // add custom auth flags for table
      const canOpenEntityPanel = isAuthorized({
        capabilities: TasksTableCapability.REAL_TIME_PAGES_VIEW,
        siteIds: [siteId],
      });

      const parsedHrs = parseInt(estDurationHours) || 0;
      const parsedMins = parseInt(estDurationMinutes) || 0;
      const totalDurationMinutes = (estDurationHours ?? 0) * 60 + (estDurationMinutes ?? 0);
      return {
        ...task,
        authorization: {
          canOpenEntityPanel,
        },
        [TasksColumns.ASSET]: asset && { id: asset?.id, name: asset?.name, siteId: site?.id },
        [TasksColumns.SITE]: site && { id: site.id, name: site.name, timezone: site.timezone },
        [TasksColumns.STATE]: asset?.state,
        [TasksColumns.DETAIL]: '',
        [TasksColumns.EST_LABOR_HOURS]: withDefault(
          laborHours ?? laborMinutes,
          formatHoursAndMinutes(laborHours, laborMinutes),
        ),
        [TasksColumns.PARTS_EXPECTED]:
          expectedParts && expectedParts.length
            ? { parts: expectedParts, count: expectedParts.length }
            : undefined,
        [TasksColumns.PARTS_CONSUMED]:
          consumedParts && consumedParts.length
            ? { parts: consumedParts, count: consumedParts.length }
            : undefined,
        [TasksColumns.EST_DURATION]: {
          totalDurationMinutes,
          value: withDefault(parsedHrs ?? parsedMins, formatHoursAndMinutes(parsedHrs, parsedMins)),
        },
        [TasksColumns.COMPONENT]: formatComponentCell([component1, component2, component3]),
        [TasksColumns.PRIORITY]: withDefault(priority),
        [TasksColumns.STATUS]: status,
        [TasksColumns.TITLE]: withDefault(title),
        [TasksColumns.DESCRIPTION]: withDefault(description),
        [TasksColumns.LINKED]: linked,
        [TasksColumns.EST_TECHS]: withDefault(estTechs),
        [TasksColumns.ASSIGNED_TECHS]: assignedTechs, // Not part of MVP0
        [TasksColumns.ATTACHMENT]: withDefault(attachment), // Not part of MVP0
        [TasksColumns.NOTES]: withDefault(note), // Not part of MVP0
        [TasksColumns.RESOLUTION]: resolutionNotes,
        [TasksColumns.SR_NUMBER]: withDefault(srNumber), // Not part of MVP0
        [TasksColumns.SR_STATUS]: withDefault(srStatus), // Not part of MVP0
        ...(taskSources && {
          source: t(`dynamic.types.${task.source}`, task.source),
        }),
        ...(taskWorkScopes && {
          workScope: t(`dynamic.work_scopes.${task.workScope}`, task.workScope),
        }),
        [TasksColumns.FLAG]: withDefault(flag),
        [TasksColumns.RDSPP]: withDefault(rdspp),
      };
    });
  }, [
    isAuthorized,
    t,
    tasks,
    taskSources,
    taskWorkScopes,
    isTaskSourcesLoading,
    isLoadingWorkScopes,
  ]);

  const data = useTableFilter({ data: rows, filters, search, isTaskTable: true });

  return {
    data,
    error: assetError,
    isError: isAssetError,
    isLoading: isTaskSourcesLoading || isLoadingWorkScopes || isAssetLoading,
  };
};
