import { buildSortOptions } from '@ge/util/metric-sorter';

import * as request from './api';

export const fetchEvents = (sort, direction) =>
  request.get('/events', buildSortOptions(sort, direction));

export const fetchEventsWithMetrics = (sort, direction) =>
  request.get('/events', buildSortOptions(sort, direction, true));

export const fetchEventById = (eventId) => request.get(`/events/${eventId}`);

export const fetchEventTickets = (eventId) => request.get(`/events/${eventId}/tickets`);

export const fetchEventsByCaseId = (caseId) => request.get('/events', { params: { caseId } });

/**
 * Dynamically load the customer mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/event/events.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
