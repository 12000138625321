import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import { TaskGroupItem } from './task-group-item';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  > .header {
    padding: 10px;
    border-bottom: solid 1px ${(props) => props.theme.manage.taskItem.borderColor};
    font-size: 14px;
    .button-group {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      button:first-of-type {
        color: ${(props) => props.theme.manage.taskItem.buttonColor};
      }
    }
  }
  .task-groups {
    display: flex;
    flex: 1;
    position: relative;
    padding: 4px;
  }
`;

export const TaskGroupPanel = ({ scheduleData }) => {
  const { t } = useTranslation(['manage.planning']);

  return (
    <Container>
      <div className="header">
        {t('recommended_task_groups', 'Recommended Task Groups')}
        <div className="button-group">
          <button type="button">{t('dismiss_all', 'Dismiss All')}</button>
          <Button primary>{t('schedule_all', 'Schedule All')}</Button>
        </div>
      </div>
      <div className="task-groups">
        <ScrollingContainer>
          {scheduleData.map((taskGroup) => (
            <TaskGroupItem taskGroup={taskGroup} key={taskGroup.id} />
          ))}
        </ScrollingContainer>
      </div>
    </Container>
  );
};

TaskGroupPanel.propTypes = {
  scheduleData: PropTypes.instanceOf(Object),
};

TaskGroupPanel.defaultProps = {
  scheduleData: null,
};
