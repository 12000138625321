import * as request from './api';

const RTMC_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;

export const sendCommand = async (data) =>
  request.post('/rtmc/commands', data, { baseURL: RTMC_BASE_URL });

export const fetchCommandStatus = (id, isGroup) =>
  request.get(`/rtmc/commands/${id}`, { params: { isGroup }, baseURL: RTMC_BASE_URL });

export const fetchCommandList = async (data) =>
  request.post('/rtmc/commands-list', data, { baseURL: RTMC_BASE_URL });

export const fetchAssetHistory = (siteId, assetId, make, controllerType, platform) =>
  request.get(`/rtmc/commands-asset-history/${siteId}`, {
    params: {
      assetId,
      make: make?.toLowerCase(),
      controllerType: controllerType?.toLowerCase(),
      platform: platform?.toLowerCase(),
    },
    baseURL: RTMC_BASE_URL,
  });

export const fetchCommandBlockStatus = async (siteId, assetIds) =>
  request.get(`/rtmc/command-safety-check/${siteId}`, {
    params: { assetIds },
    baseURL: RTMC_BASE_URL,
  });

export const abortCommands = async (id) =>
  request.get(`/rtmc/commands-abort/${id}`, {
    baseURL: RTMC_BASE_URL,
  });
