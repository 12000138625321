import { ChartType } from './chart-type';

export const lineChartOptions = {
  series: [
    {
      lineWidth: 1,
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
      type: ChartType.LINE,
    },
  ],
};
