import React, { useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import { withRouter } from 'react-router-dom';

import { EntityType } from '@ge/models/constants';
import { inertClass } from '@ge/shared/components/entity-details/dismiss-inert';
import { EntityCreateContext } from '@ge/shared/context/entity-create-context';

import { SidePanel } from '../side-panel/side-panel';

import PersonCreate from './person-create/person-create';
import RoleCreate from './role-create/role-create';

const EntityCreatePanelComponent = ({ className }) => {
  const { entityType, hideDetails } = useContext(EntityCreateContext);

  const close = useCallback(
    (e) => {
      const isInert = e && !!e.target.closest(`.${inertClass}`);
      if (!e || !isInert) {
        hideDetails();
      }
    },
    [hideDetails],
  );

  /**
   * Open a new window with the entity-specific view embedded.
   *
   * @param {String} type Type of entity to spawn a window for
   * @param {String} id Entity ID to show
   */
  const spawnWindow = useCallback(
    (type, id) => {
      window.open(
        `/${type}/${id}`,
        '_blank',
        'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780px,height=800px',
      );

      close();
    },
    [close],
  );

  /**
   * Factory to retrieve the correct details component for the
   * selected entity.
   */
  const getEntityCreate = useCallback(() => {
    if (!entityType) {
      return null;
    }

    if (entityType === EntityType.PERSON) {
      return <PersonCreate entityType={entityType} />;
    } else if (entityType === EntityType.ROLE) {
      return <RoleCreate />;
    } else {
      return null;
    }
  }, [entityType]);

  return createPortal(
    <SidePanel className={className} close={close} spawnWindow={() => spawnWindow(entityType)}>
      {getEntityCreate()}
    </SidePanel>,
    document.querySelector('#portal-root'),
  );
};

export const EntityCreatePanel = withRouter(EntityCreatePanelComponent);
