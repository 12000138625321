import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tab, Tabs } from '@ge/components/tabs';
import {
  Capability,
  EntityType,
  PermissionScope,
  AuthStrategy,
  EntityTab,
} from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';

import NotesAttachments from '../components/notes-attachments';
import { DetailContainer, SectionContainer } from '../entity-details-shared';

import AssetDetailsHeader from './asset-details-header';
// Hiding for MVP0 - Story #533
import { AssetHistory } from './panels/asset-history';
import { AssetInfo } from './panels/asset-info';
import { AssetPerformance } from './panels/asset-performance';
import { AssetRealtimeInfo } from './panels/asset-realtime-info';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const AssetDetails = ({
  assetId,
  entityType,
  entityTab,
  handleNewTask,
  setShowLinkModal,
  isDatePersist,
  popsout,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { manageAssetTabs } = useContext(EntityDetailsContext);

  // Commenting as we are not showing events for now
  // const { fetchAssetEvents } = useStoreActions((actions) => actions.assets);

  // // Fetch relevant data.
  // useEffect(() => {
  //   fetchAssetEvents(assetId);
  // }, [fetchAssetEvents, assetId]);
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const assetDetails = getAssetById(assetId);
  const { isAuthorized } = useAuth();

  const isAuthorizedToViewRealTimeInfo = isAuthorized({
    capabilities: [
      { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
      { capability: Capability.CASES, scopes: [PermissionScope.VIEW] },
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
      { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
    ],
  });

  const isAuthorizedToViewAssetHistory = isAuthorized({
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
      { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
      { capability: Capability.CASES, scopes: [PermissionScope.VIEW] },
    ],
    siteIds: [assetDetails?.site?.id],
  });

  const isAuthorizedToViewPerformance = isAuthorized({
    capabilities: [{ capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] }],
  });

  const isAuthorizedToViewNotes = isAuthorized({
    capabilities: [
      { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
    ],
  });

  const isAuthorizedToViewAssetInfo = isAuthorized({
    capabilities: [{ capability: Capability.ASSET_MANAGEMENT, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });

  const isAuthorizedToViewTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedToMonitorCases = isAuthorized({
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedToAnalyzeCases = isAuthorized({
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
    ],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedAlertsHistory = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });

  let defaultTab = entityTab;

  if (!defaultTab || defaultTab === null) {
    if (isAuthorizedToViewRealTimeInfo) {
      defaultTab = EntityTab.REALTIME_INFO;
    }
    if (isAuthorizedToViewAssetHistory) {
      defaultTab = EntityTab.HISTORY;
    }
    if (isAuthorizedToViewPerformance) {
      defaultTab = EntityTab.PERFORMANCE;
    }
    if (isAuthorizedToViewNotes) {
      defaultTab = EntityTab.NOTES_AND_ATTACHMENTS;
    }
  }

  const handleEntityTab = useCallback(
    (e) => {
      manageAssetTabs(assetId, e, entityType);
    },
    [assetId, manageAssetTabs, entityType],
  );

  if (!ready) {
    return <Loader />;
  }

  return (
    <DetailContainer>
      <AssetDetailsHeader assetId={assetId} entityType={entityType} />
      <ScrollingContainer autoHide={false}>
        <SectionContainer>
          <StyledTabs small defaultTab={defaultTab} onChangeHandler={handleEntityTab}>
            <Tab
              capabilities={[
                { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
                { capability: Capability.CASES, scopes: [PermissionScope.VIEW] },
                { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
                { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
              ]}
              small
              label={t('tabs.realtime_info', 'Realtime Info')}
            >
              {isAuthorizedToViewRealTimeInfo ? (
                <AssetRealtimeInfo
                  assetId={assetId}
                  handleNewTask={handleNewTask}
                  setShowLinkModal={setShowLinkModal}
                />
              ) : (
                ''
              )}
            </Tab>
            <Tab
              capabilities={[
                { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
                { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
                { capability: Capability.CASES, scopes: [PermissionScope.VIEW] },
              ]}
              authStrategy={AuthStrategy.ANY}
              label={t('tabs.history', 'History')}
            >
              {isAuthorizedToViewAssetHistory ? (
                <AssetHistory
                  assetId={assetId}
                  isAuthorizedToViewTasks={isAuthorizedToViewTasks}
                  isAuthorizedToMonitorCases={isAuthorizedToMonitorCases}
                  isAuthorizedToAnalyzeCases={isAuthorizedToAnalyzeCases}
                  isAuthorizedAlertsHistory={isAuthorizedAlertsHistory}
                />
              ) : (
                ''
              )}
            </Tab>
            <Tab
              capabilities={[{ capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] }]}
              label={t('tabs.performance', 'Performance')}
            >
              {isAuthorizedToViewPerformance ? (
                <AssetPerformance
                  assetId={assetId}
                  isDatePersist={isDatePersist}
                  popsout={popsout}
                />
              ) : (
                ''
              )}
            </Tab>
            <Tab
              capabilities={[
                { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
              ]}
              label={t('tabs.notes', 'Notes')}
            >
              {isAuthorizedToViewNotes ? (
                <NotesAttachments
                  entity={{ id: assetId }}
                  entityType={EntityType.ASSET}
                  siteType={entityType}
                />
              ) : (
                ''
              )}
            </Tab>
            <Tab
              capabilities={[
                { capability: Capability.ASSET_MANAGEMENT, scopes: [PermissionScope.VIEW] },
              ]}
              label={t('tabs.info', 'Info')}
            >
              {isAuthorizedToViewAssetInfo ? (
                <AssetInfo entity={{ id: assetId }} entityType={EntityType.ASSET} />
              ) : (
                ''
              )}
            </Tab>
            {/* <Tab label={t('tabs.performance', 'Performance')}>
              {site && <AssetPerformance asset={asset} />}
            </Tab>
            <Tab label={t('tabs.events', 'Events')}>
              <div>Events Content</div>
            </Tab>
            <Tab label={t('tabs.operations', 'Operations')}>
              <div>Operations Content</div>
            </Tab> */}
          </StyledTabs>
        </SectionContainer>
      </ScrollingContainer>
    </DetailContainer>
  );
};

AssetDetails.propTypes = {
  assetId: PropTypes.string.isRequired,
  entityType: PropTypes.string,
  entityTab: PropTypes.string,
  setCaseId: PropTypes.any,
  caseId: PropTypes.string,
  handleNewTask: PropTypes.func,
  setShowLinkModal: PropTypes.func,
  isDatePersist: PropTypes.bool,
  popsout: PropTypes.bool,
};

export default AssetDetails;
