import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Tooltip } from '@ge/components/tooltip';
import { AssetCommandStatus } from '@ge/models/constants';
import { elevations, globalColors } from '@ge/tokens';

import eventRefresh from './event-refresh.svg';
import eventRefreshing from './event-refreshing.svg';

const SmallIcon = styled.img`
  height: 2em;
  width: 2em;
`;

const EventRefreshButton = styled.button`
  margin-right: 0.5em;
  margin-bottom: -0.3em;
`;

const StatusSpan = styled.span`
  margin-top: 1em;
  margin-right: 1em;
  color: ${(props) => props.color};
`;

export const EventRefreshButtonWithToolTip = ({ toolTip, onClick, inProgress }) => {
  return (
    <Tooltip title={toolTip} zIndex={elevations.P20} placement="top">
      <EventRefreshButton onClick={onClick}>
        <EventRefreshIconComponent inProgress={inProgress} />
      </EventRefreshButton>
    </Tooltip>
  );
};

export const EventRefreshIconComponent = ({ inProgress }) => {
  return (
    <div>
      <span>{inProgress ? <EventRefreshingIcon /> : <EventRefreshIcon />}</span>
    </div>
  );
};

const calculateTimeRemaining = (dataUpdatedAt) => {
  const timeRemaining = Math.trunc((dataUpdatedAt + 30000 - Date.now()) / 1000);
  return timeRemaining > 0 ? timeRemaining : 0;
};

export const EventRefreshStatus = ({
  eventStatus,
  eventsRefreshing,
  translate,
  nextCycle,
  commandState,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(nextCycle));
  const [status, setStatus] = useState(eventStatus);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(nextCycle));
    }, 1000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setStatus(eventStatus);
    const timer = setTimeout(() => {
      setStatus(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [eventStatus]);

  if (!eventsRefreshing && status && !commandState) {
    if (status !== AssetCommandStatus.SUCCESS) {
      return (
        <StatusSpan color={globalColors.red2}>
          {translate('event_refresh.status.failed', '! Realtime events failed to fetch.')}
        </StatusSpan>
      );
    }
  }
  if (eventsRefreshing) return null;
  return (
    <StatusSpan color={globalColors.blue4}>
      {`${translate('event_refresh.status.next_cycle', 'Next Refresh: ')} ${
        timeRemaining > 0
          ? Math.trunc(timeRemaining).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })
          : '00'
      } s`}
    </StatusSpan>
  );
};

export const EventRefreshIcon = () => <SmallIcon src={eventRefresh} alt="refresh icon" />;

export const EventRefreshingIcon = () => <SmallIcon src={eventRefreshing} alt="refresh icon" />;

EventRefreshIconComponent.propTypes = {
  inProgress: PropTypes.bool,
};

EventRefreshStatus.propTypes = {
  eventStatus: PropTypes.string,
  eventsRefreshing: PropTypes.bool,
  commandState: PropTypes.bool,
  translate: PropTypes.func,
  nextCycle: PropTypes.number,
};

EventRefreshButtonWithToolTip.propTypes = {
  inProgress: PropTypes.bool,
  toolTip: PropTypes.string,
  onClick: PropTypes.func,
};
