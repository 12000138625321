import { clear as idbClear, del, get, set } from 'idb-keyval';

const available = Boolean(window.indexedDB);
if (!available) {
  console.log(
    "Your browser doesn't support a stable version of IndexedDB. Caching will not be available.",
  );
}

const getItem = (key) => (available ? get(key) : undefined);

const setItem = (key, data) => (available ? set(key, data) : undefined);

const removeItem = (key) => (available ? del(key) : undefined);

export const IndexedDb = {
  clear: idbClear,
};

export default {
  getItem,
  setItem,
  removeItem,
};
