import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { withTheme } from 'styled-components';

import { ScrollbarWrapper } from '@ge/components/infinite-scrollbar-wrapper';
import { Loader, SpinLoader } from '@ge/components/loader';
import { EntityAll } from '@ge/models/constants';

import PersonAssetsContentWorkerSubComponent from './person-assets-content-subcomponents/person-assets-content-worker-sub-component';

const ListWrapper = styled.ul`
  li:nth-child(odd) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  }
  li:nth-child(even) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.dark};
  }
  list-style-type: none;
  padding: 2px 2px 2px 2px;
  background: ${(props) => props.theme.admin.panel.entityRow.dark};
`;

const ContentWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 50vh;
`;
const NoRecordsFound = styled.div`
  padding: 20px;
  text-align: center;
`;
export const PersonAssetsGroupServiceGroup = withTheme(({ serviceGroups }) => {
  let [seviceGroupItems, setSeviceGroupItems] = useState(null);
  let [hasMoreItems, setHasMoreItems] = useState(true);
  const { activeTab, existingServiceGroupIds, editStatus, existingSiteIds } = useStoreState(
    (state) => state.tabflow.personAssets,
  );
  const { t } = useTranslation(['admin.people']);
  let allServiceGroups = useMemo(() => {
    let list = [...serviceGroups];
    if (!editStatus && !existingSiteIds.includes(EntityAll.ALL)) {
      list = serviceGroups.filter((item) => existingServiceGroupIds.includes(item.id));
    }
    return [...list];
  }, [editStatus, existingServiceGroupIds, serviceGroups, existingSiteIds]);

  useEffect(() => {
    if (allServiceGroups) {
      setSeviceGroupItems([...allServiceGroups].splice(0, 20));
    }
  }, [activeTab, editStatus, existingServiceGroupIds, allServiceGroups]);

  const updateInfiniteRecords = () => {
    if (seviceGroupItems.length >= allServiceGroups.length) {
      setHasMoreItems(false);
      return;
    }

    if (seviceGroupItems.length <= allServiceGroups.length) {
      let nextItemsToLoad = [...allServiceGroups].splice(
        seviceGroupItems.length,
        seviceGroupItems.length + 20,
      );
      setSeviceGroupItems([...seviceGroupItems, ...nextItemsToLoad]);
    }
  };
  if (!seviceGroupItems) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }
  return (
    <div>
      {seviceGroupItems?.length ? (
        <ScrollbarWrapper>
          <InfiniteScroll
            dataLength={seviceGroupItems.length}
            next={updateInfiniteRecords}
            hasMore={hasMoreItems}
            height={340}
            loader={<SpinLoader className="person-assets-scroll-loader-manual-styles" />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b></b>
              </p>
            }
          >
            <ListWrapper>
              {seviceGroupItems.map((item, index) => (
                <li key={index}>
                  <PersonAssetsContentWorkerSubComponent item={item} index={index} />
                </li>
              ))}
            </ListWrapper>
          </InfiniteScroll>
        </ScrollbarWrapper>
      ) : (
        <ContentWrapper>
          <NoRecordsFound>{t('assets.no_records', 'No records')}</NoRecordsFound>
        </ContentWrapper>
      )}
    </div>
  );
});
PersonAssetsGroupServiceGroup.propTypes = {
  serviceGroups: PropTypes.array,
};
