import * as request from './api';

export const getDamageReportDirect = (data, options) =>
  request.post(`/getDamageReport/`, data, options);

export const fetchPerformanceDashboardReport = (data, options) =>
  request.post(`/dashboard/performance-report/`, data, options);

export const droneSpreadsheetUpload = (formData, options, uploadProgress = () => {}) =>
  request.post(`/processAnnotationForImage/`, formData, {
    ...options,
    onUploadProgress: (p) => {
      console.log('files.js uploadProgress', p);
      uploadProgress(p);
    },
  });

export const createPacCase = (data, options) => request.post(`/pac-integration/`, data, options);
