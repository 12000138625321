import styled from 'styled-components';

export const ChartBorder = styled.div`
  border: ${(props) => (props.hideBorder ? 'none' : '1px solid' + props.theme.charts.borderColor)};
  border-radius: 4px;
  padding: 8px;
`;

export const ChartPadding = styled.div`
  padding-top: 8px;
`;
