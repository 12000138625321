import uniqWith from 'ramda/src/uniqWith';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchCommandList } from '../services/commands';

const getCommandsList = async ({ assets = [] }) => {
  if (assets.length > 1) {
    throw new Error('Differnt Asset Types Selected');
  }
  return assets?.length ? await fetchCommandList({ assets }) : [];
};

const getDistinctMakeControllerTypeAndPlatform = ({ assets = [] }) =>
  uniqWith(
    (a, b) =>
      a.make === b.make && a.controllerType === b.controllerType && a.platform === b.platform,
    assets,
  ).map(({ make, controllerType, platform }) => ({
    make: make?.toLowerCase(),
    controllerType: controllerType?.toLowerCase(),
    platform: platform?.toLowerCase(),
  }));

export const useAssetCommandList = ({ assets, isActive = true }) => {
  const makeControllerTypeAndPlatform = getDistinctMakeControllerTypeAndPlatform({ assets });

  const { data, error, isLoading } = useQuery(
    [QueryKey.ASSET_COMMAND_LIST, makeControllerTypeAndPlatform],
    () => getCommandsList({ assets: makeControllerTypeAndPlatform }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
      retry: false,
    },
  );

  return { data, error, isLoading };
};
