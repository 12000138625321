import * as request from './api';

export const getEventManagementList = ({ id, mapId }) => {
  return request.get('/cmn/event-management-list', {
    params: { id, mapId },
  });
};

export const createEventManagement = (body) => request.post(`/cmn/event-management-list`, body);

export const putEventManagement = (data) =>
  request.put(`/cmn/event-management-list/${data.id}`, data.body);

export const updateEventManagement = (data) =>
  request.patch(`/cmn/event-management-list/${data.id}`, data.body);

export const getProcProperties = ({ id }) => {
  return request.get('/cmn/event/search/proc-properties', {
    params: { id },
  });
};
