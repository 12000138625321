import { useStoreState } from 'easy-peasy';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Icons } from '@ge/components/icon';
import { KpiCategoryDefs } from '@ge/models/constants';
import { formatNumber, roundNumber } from '@ge/util';

import { sitesColumnChartOptions } from './chart-consts';

const StyledColumnChart = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 48px;
  > .title {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 8px 0px 30px;
    border-top: solid 1px ${(props) => props.theme.analyze.columnChart.borderColor};
    height: 13px;
    color: ${(props) => props.theme.analyze.columnChart.textColor};
    text-align: center;
    margin-left: -5px;
  }
  &.active {
    &:before {
      content: '';
      width: calc(100% - 20px);
      height: 78%;
      margin-top: 10px;
      border-right: solid 1px ${(props) => props.theme.analyze.columnChart.activeBorder};
      border-left: solid 1px ${(props) => props.theme.analyze.columnChart.activeBorder};
      background: ${(props) => props.theme.analyze.columnChart.activeBackground};
      position: absolute;
      left: 48%;
      transform: translateX(calc(-51.5% - 1px));
    }
    .title {
      color: ${(props) => props.theme.analyze.columnChart.activeTextColor};
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 24px solid ${(props) => props.theme.analyze.columnChart.arrowColor};
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &:last-child {
    .title {
      padding-right: 30px;
      width: calc(100% - 30px);
      &:after {
        left: calc(50% - 15px);
      }
    }
    &.active:before {
      transform: translateX(calc(-50% - 15px));
      width: calc(100% - 44px);
    }
  }
`;

const ColumnChart = ({
  chartData,
  theme,
  chartData: { name, data, id },
  yaxis,
  active,
  colorMap,
  category,
  maxColumnValue,
  onRegionSelect,
  namespace,
}) => {
  const {
    charts,
    kpiChart,
    analyze: { columnChart },
  } = theme;

  const { t } = useTranslation(namespace ? [namespace] : null);
  const { tooltipBackgroundColor, tooltipColor } = charts;
  const getSiteById = useStoreState((store) => store.sites.getSiteById);
  const { getRegionById } = useStoreState((state) => state.regions);
  const getYAxisCeiling = useMemo(
    () => () => {
      switch (category) {
        case KpiCategoryDefs.PRODUCTION_ACTUAL:
        case KpiCategoryDefs.PRODUCTION_LOST_CONTRACT:
          return maxColumnValue;
        default:
          // default ceiling to 100% via numeric value
          return 100;
      }
    },
    [maxColumnValue, category],
  );

  // Normalizes data length to always contain 10 data objects.
  // This is necessary to control and provide consistent spacing between columns across charts.
  const normalizeColumnData = (data) => {
    const dummyColumnData = {
      y: 0,
    };

    let _data = [...data];

    if (data?.length < 10) {
      for (let i = 0; i < 10 - _data.length; i++) {
        if (_data.length === 9) {
          _data.push(dummyColumnData);
          break;
        }

        _data.push(dummyColumnData);
        _data.unshift(dummyColumnData);
      }
    }

    return _data;
  };

  const ceiling = getYAxisCeiling();

  const getTimeSeriesTooltipStyle = () => ({
    container: `
      border-radius: 4px;
      font-family: "Museo Sans";
      align-items: start;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    `,
    entityIcon: `
      display: inline-block;
      fill: ${tooltipColor};
      vertical-align: middle;
    `,
    regionNameStyle: `
      color: ${columnChart.tooltipPrimaryColor};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    `,
    siteNameStyle: `
      color: ${columnChart.tooltipPrimaryColor};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    `,
    kpiNameStyle: `
      color: ${kpiChart.tooltipSecondaryColor};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
    `,
    kpiValueStyle: `
      color: ${columnChart.kpiValueColor};
      font-size: 16px;
      letter-spacing: -0.33px;
      line-height: 19px;
    `,
    kpiUnitsStyle: `
      color: ${kpiChart.tooltipSecondaryColor};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
    `,
  });

  const {
    container,
    kpiNameStyle,
    entityIcon,
    regionNameStyle,
    siteNameStyle,
    kpiValueStyle,
    kpiUnitsStyle,
  } = getTimeSeriesTooltipStyle();
  const tooltipPointFormatter = ({ kpiName, kpiUnit, siteName, regionName, y }) => {
    return `
        <div style='${container}'>
          <span style='${regionNameStyle}'>
            <svg height='12px' width='12px' rotate='0' style='${entityIcon}' viewBox='0 0 18 20'>
              <path d='${Icons.FLEET}' />
            </svg>
            ${regionName}
          </span>
          <span style='${siteNameStyle}'>
            <svg height='12px' width='12px' rotate='0' style='${entityIcon}' viewBox='0 0 18 20'>
              <path d='${Icons.SITE}' />
            </svg>
            ${siteName}
          </span>
          <span style='${kpiNameStyle}'>${kpiName}</span>
          <span style='${kpiValueStyle}'>
            ${formatNumber(roundNumber(y, 1))}
            <span style='${kpiUnitsStyle}'>${kpiUnit}</span>
          </span>
        </div>
      `;
  };

  const styleData = (data) =>
    data.map((item) => ({
      color: colorMap[item?.id],
      y: item?.y,
      kpiName: t(`dynamic.kpi_chart.${category}`),
      kpiUnit: t(`dynamic.kpi_unit.${category}`),
      siteName: getSiteById(item?.id)?.name,
      regionName: getRegionById(item?.region?.id)?.name,
    }));

  const chartCreated = (chart) => {
    chart.update({
      lang: {
        noData: 'No Data',
      },
      noData: {
        style: {
          transform: 'translate(45%, 48%)',
        },
      },
      chart: {
        spacingRight: yaxis ? 0 : -38,
      },
      yAxis: {
        ceiling: ceiling,
        minRange: ceiling,
      },
      tooltip: {
        backgroundColor: tooltipBackgroundColor,
        style: {
          color: tooltipColor,
        },
      },
    });

    chart.addSeries({
      id,
      data: styleData(normalizeColumnData(data)),
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          const { kpiName, kpiUnit, siteName, regionName, y } = this;
          return tooltipPointFormatter({ kpiName, kpiUnit, siteName, regionName, y });
        },
      },
    });
  };

  const options = { ...sitesColumnChartOptions };
  options.yAxis.gridLineColor = columnChart.gridLineColor;
  options.plotOptions.series.color = columnChart.columnColor;

  return (
    <StyledColumnChart onClick={() => onRegionSelect(chartData)} className={active ? 'active' : ''}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={chartCreated}
        immutable
      />
      <div className="title">{name}</div>
    </StyledColumnChart>
  );
};

ColumnChart.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  chartData: PropTypes.instanceOf(Object).isRequired,
  yaxis: PropTypes.bool,
  onRegionSelect: PropTypes.func,
  active: PropTypes.bool,
  colorMap: PropTypes.instanceOf(Object),
  category: PropTypes.string.isRequired,
  maxColumnValue: PropTypes.number,
  namespace: PropTypes.string,
};

ColumnChart.defaultProps = {
  yaxis: false,
  onRegionSelect: () => null,
  active: false,
  colorMap: {},
  maxColumnValue: 100,
  namespace: undefined,
};

export default withTheme(ColumnChart);
