import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

//@custom components
import { globalColors } from '@ge/tokens';

//@custom styles
const Header = styled.div`
	display: flex;
	padding: 1.5em;
	justify-content: space-between;
	align-items:center
	height: 50px;
	width: auto;
	border-radius: 6px;
	background-color: ${(props) => props.theme.distributionListSidePanel.header};
	box-shadow: inset 0px 6px ${(props) => props.theme.distributionListSidePanel.headerShadow};
	margin: 10px;
`;

const Container = styled.div`
  button {
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const HeaderTitle = styled.h1`
  flex: 0.9;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 10px;
  display: inline-block;
  font-family: 'GE Inspira';
  color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
`;

const ViewTitle = styled.h4`
  color: #80c5d3;
  padding: 0.5em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 0 0 0 0.4em;
`;

const ErrorWrapper = styled.div`
  font-size: 1.2em;
  margin: 0.9em 2em;
  color: ${globalColors.red4};
`;

//@functional component
const Create = ({ data, type, error }) => {
  const { t } = useTranslation(['admin.configure']);

  return (
    <>
      <Header>
        {data && type === 'View' ? (
          <Container>
            <ViewTitle>{t('Event_managment.event', 'EVENT')}</ViewTitle>
            <HeaderTitle>{t(data?.name)}</HeaderTitle>
          </Container>
        ) : (
          <Container>
            <HeaderTitle>
              {type === 'Edit'
                ? t('Event_managment.edit_event', 'Edit Event')
                : t('Event_managment.add_event', 'Add Event')}
            </HeaderTitle>
          </Container>
        )}
      </Header>
      {error ? <ErrorWrapper>{error}</ErrorWrapper> : ''}
    </>
  );
};

Create.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  error: PropTypes.any,
};

export const CreateHeader = withTheme(Create);
