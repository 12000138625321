import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { CreateModes } from '@ge/feat-reporting/models/modes';

import { DefineParameters, ViewParameters } from './parameters';

const HideSidebarCss = css`
  position: absolute;
  transform: translateX(-100%);
`;

const StyledSideBar = styled.aside`
  background: ${({ theme }) => theme.createReport.sidebarBackgroundColor};
  border-radius: 3px;
  display: block;
  align-items: center;
  margin-right: 24px;
  height: 100%;
  max-width: 370px;
  min-width: 370px;
  transition: transform 100ms ease;

  ${({ hide }) => hide && HideSidebarCss}
`;

const SideBarControl = styled.div`
  cursor: pointer;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  padding: 0;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 25px;
  border-radius: 3px;
  background-color: #252c33;
  position: relative;
  box-sizing: border-box;
  transform-style: preserve-3d;

  &::after {
    content: '';
    height: 23px;
    width: 25px;
    border-radius: 4px;
    background-color: #38434e;
    transform: translateZ(-1px);
    top: 1px;
    left: 2px;
    position: absolute;
  }
`;

const ExpandIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  icon: Icons.COLLAPSE,
  color: theme.createReport.sidebar.collapseIconColor,
  viewbox: '0 0 12 12',
}))`
  display: inline-block;
  position: relative;
`;

export const InputStateKeys = {
  NAME: 'name',
  DESCRIPTION: 'description',
  DISTRIBUTION_LIST: 'distributionList',
  TIME_RANGE: 'timeRange',
  SCOPE: 'scope',
  SCOPE_TYPE: 'scopeType',
  SCOPE_NAME: 'scopeName',
  CREATED_TIME: 'createdTime',
  CUSTOM_END_DATE: 'customEndDate',
  CUSTOM_START_DATE: 'customStartDate',
};

export const Sidebar = ({ visible, onClose, onOpen, generatedPdfBase64 }) => {
  const { createMode, setParameters, initalReportState } = useContext(ReportsContext);

  const handleClearAll = useCallback(() => {
    const {
      name: templateName,
      description: templateDescription,
      timeRange: templateTimeRange,
      distributionList: templateDistributionList,
      scope: templateScope,
      scopeType: templateScopeType,
    } = initalReportState ?? {};

    // The 'Clear All' functionality is only supposed to reset the report name, description,
    // time range, distribution list, and report scope to the original values.
    setParameters(InputStateKeys.NAME, templateName);
    setParameters(InputStateKeys.DESCRIPTION, templateDescription);
    // Need to update the time range in both the report state and in the dropdown
    setParameters(InputStateKeys.TIME_RANGE, templateTimeRange);
    setParameters(InputStateKeys.DISTRIBUTION_LIST, templateDistributionList);
    setParameters(InputStateKeys.SCOPE, templateScope);
    setParameters(InputStateKeys.SCOPE_TYPE, templateScopeType);
  }, [setParameters, initalReportState]);

  // Resetting report state during initial render only to clear any prior
  // changes that may be left in state after navigating away from the page
  useEffect(() => {
    handleClearAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sidebarContent = useMemo(() => {
    switch (createMode) {
      case CreateModes.EDIT:
        return <DefineParameters onClearAll={handleClearAll} />;
      case CreateModes.VIEW:
      case CreateModes.PREVIEW:
      default:
        return <ViewParameters onClose={onClose} generatedPdfBase64={generatedPdfBase64} />;
    }
  }, [createMode, handleClearAll, generatedPdfBase64, onClose]);

  return (
    <>
      <StyledSideBar hide={visible}>{sidebarContent}</StyledSideBar>
      <SideBarControl show={visible} onClick={onOpen}>
        <ExpandIcon />
      </SideBarControl>
    </>
  );
};

Sidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  generatedPdfBase64: PropTypes.string,
};

Sidebar.defaultProps = {
  onClose: () => {},
  onOpen: () => {},
};
