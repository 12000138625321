import { Capability, PermissionScope, TableFilterTypes } from '@ge/models/constants';

export const TasksColumns = {
  GROUP_SELECTED: 'group-selected',
  SELECTED: 'selected',

  GROUP_ACTIONS: 'group-actions',
  ACTIONS: 'actions',

  GROUP_DURATION: 'group-duration',
  ACTUAL_DURATION: 'duration',

  GROUP_SITE: 'group-site',
  SITE: 'site',

  GROUP_ASSET: 'group-asset',
  ASSET: 'asset',

  GROUP_STATE: 'group-state',
  STATE: 'state',

  GROUP_PRIORITY: 'group-priority',
  PRIORITY: 'priority',

  GROUP_STATUS: 'group-status',
  STATUS: 'status',

  GROUP_TITLE: 'group-title',
  TITLE: 'title',

  GROUP_FLAG: 'group-flag',
  FLAG: 'flag',

  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'description',

  GROUP_TYPE: 'group-type',
  TYPE: 'type',

  GROUP_SOURCE: 'group-source',
  SOURCE: 'source',

  GROUP_WORK_SCOPE: 'group-work-scope',
  WORK_SCOPE: 'workScope',

  GROUP_LINKED: 'group-linked',
  LINKED: 'linked',

  GROUP_COMPONENT: 'group-component',
  COMPONENT: 'component',

  GROUP_EST_TECHS: 'group-est-techs',
  EST_TECHS: 'estTechs',

  GROUP_EST_DURATION: 'group-est-duration',
  EST_DURATION: 'estDuration',

  GROUP_EST_LABOR_HOURS: 'group-est-labor-hours',
  EST_LABOR_HOURS: 'est-labor-hours',

  GROUP_CREATED_DATE: 'group-created-date',
  CREATED_DATE: 'createDate',

  GROUP_ELIGIBLE_START_DATE: 'eligible-start-date',
  ELIGIBLE_START_DATE: 'eligibleStartDate',

  GROUP_SCHEDULED_DATE: 'group-scheduled-date',
  SCHEDULED_DATE: 'scheduleDate',

  GROUP_DUE_DATE: 'group-due-date',
  DUE_DATE: 'dueDate',

  GROUP_COMMITTED_DATE: 'group-committed-date',
  COMMITTED_DATE: 'committedDate',

  GROUP_STARTED_DATE_TIME: 'group-started-date-time',
  STARTED_DATE_TIME: 'startedDateTime',

  GROUP_COMPLETED_DATE_TIME: 'group-completed-date-time',
  COMPLETED_DATE_TIME: 'completedDateTime',

  GROUP_ASSIGNED_TECHS: 'group-techs',
  ASSIGNED_TECHS: 'techs',

  GROUP_PARTS_EXPECTED: 'group-parts-expected',
  PARTS_EXPECTED: 'parts-expected',

  GROUP_PARTS_CONSUMED: 'group-parts-consumed',
  PARTS_CONSUMED: 'parts-consumed',

  GROUP_ATTACHMENT: 'group-attachment',
  ATTACHMENT: 'attachment',

  GROUP_NOTES: 'group-notes',
  NOTES: 'notes',

  GROUP_RESOLUTION: 'group-resolution',
  RESOLUTION: 'resolution',

  GROUP_SR_NUMBER: 'group-sr-number',
  SR_NUMBER: 'srNumber',

  GROUP_SR_STATUS: 'group-sr-status',
  SR_STATUS: 'srStatus',

  GROUP_RDSPP: 'group-rdspp',
  RDSPP: 'rdspp',

  GROUP_DETAIL: 'group-detail',
  DETAIL: 'detail',
};

// Define all available columns in the tasks table.
export const TasksColumnDefs = {
  [TasksColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [TasksColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'table.selected',
            a11yDefault: '',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [TasksColumns.GROUP_ACTIONS]: {
    cols: {
      [TasksColumns.ACTIONS]: {
        minWidth: '60px',
        labels: [
          {
            a11yKey: 'table.actions',
            a11yDefault: 'Actions',
            sortValue: 'actions',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_DURATION]: {
    align: 'right',
    cols: {
      [TasksColumns.ACTUAL_DURATION]: {
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'form.act_duration',
            a11yDefault: 'Actual Duration',
            sortValue: 'actDuration.totalDurationMinutes',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_SITE]: {
    align: 'left',
    cols: {
      [TasksColumns.SITE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.site',
            a11yDefault: 'Site',
            sortValue: 'site.name',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },
  [TasksColumns.GROUP_ASSET]: {
    align: 'left',
    cols: {
      [TasksColumns.ASSET]: {
        filterType: TableFilterTypes.CHECKBOXES,
        locked: true,
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: 'asset.name',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_STATE]: {
    align: 'left',
    cols: {
      [TasksColumns.STATE]: {
        filterType: TableFilterTypes.SEARCH,
        minWidth: '110px',
        labels: [
          {
            a11yKey: 'table.state',
            a11yDefault: 'State',
            sortValue: 'asset.state',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_PRIORITY]: {
    align: 'left',
    cols: {
      [TasksColumns.PRIORITY]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '110px',
        labels: [
          {
            a11yKey: 'table.priority',
            a11yDefault: 'Priority',
            sortValue: 'priority',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_STATUS]: {
    align: 'left',
    cols: {
      [TasksColumns.STATUS]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.status',
            a11yDefault: 'Status',
            sortValue: 'status',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_TITLE]: {
    align: 'left',
    cols: {
      [TasksColumns.TITLE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '200px',
        labels: [
          {
            a11yKey: 'table.title',
            a11yDefault: 'Task Title',
            sortValue: 'title',
          },
        ],
        className: 'title',
      },
    },
  },
  [TasksColumns.GROUP_FLAG]: {
    align: 'center',
    cols: {
      [TasksColumns.FLAG]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '110px',
        labels: [
          {
            a11yKey: 'table.flag',
            a11yDefault: 'Task Approved',
            sortValue: 'flag',
          },
        ],
        filterTypeDefs: {
          a11yKey: 'tasks:dynamic.task_flag',
          useTranslateFn: true,
        },
      },
    },
  },
  [TasksColumns.GROUP_DESCRIPTION]: {
    align: 'left',
    cols: {
      [TasksColumns.DESCRIPTION]: {
        filterType: TableFilterTypes.SEARCH,
        minWidth: '200px',
        labels: [
          {
            a11yKey: 'table.description',
            a11yDefault: 'Description',
            sortValue: 'description',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_SOURCE]: {
    align: 'left',
    cols: {
      [TasksColumns.SOURCE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '200px',
        labels: [
          {
            a11yKey: 'table.source',
            a11yDefault: 'Source',
            sortValue: 'source',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_WORK_SCOPE]: {
    align: 'left',
    cols: {
      [TasksColumns.WORK_SCOPE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '200px',
        labels: [
          {
            a11yKey: 'table.workScope',
            a11yDefault: 'Work Scope',
            sortValue: 'workScope',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_LINKED]: {
    cols: {
      [TasksColumns.LINKED]: {
        capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
        labels: [
          {
            a11yKey: 'table.linked',
            a11yDefault: 'Linked',
            sortValue: 'linked',
          },
        ],
        minWidth: '100px',
      },
    },
  },
  [TasksColumns.GROUP_COMPONENT]: {
    align: 'left',
    cols: {
      [TasksColumns.COMPONENT]: {
        filterType: TableFilterTypes.PROGRESSIVE_DROPDOWNS,
        minWidth: '200px',
        labels: [
          {
            a11yKey: 'table.component',
            a11yDefault: 'Component',
            sortValue: 'component1',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_EST_TECHS]: {
    align: 'left',
    cols: {
      [TasksColumns.EST_TECHS]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.est_techs',
            a11yDefault: 'Est. # Techs',
            sortValue: 'estTechs',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_EST_DURATION]: {
    align: 'right',
    cols: {
      [TasksColumns.EST_DURATION]: {
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.est_duration',
            a11yDefault: 'Est. Duration',
            sortValue: 'estDuration.totalDurationMinutes',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_EST_LABOR_HOURS]: {
    align: 'right',
    cols: {
      [TasksColumns.EST_LABOR_HOURS]: {
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.est_labor_hours',
            a11yDefault: 'Est. Labor Hrs',
            sortValue: 'estLabor',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_ASSIGNED_TECHS]: {
    align: 'left',
    cols: {
      [TasksColumns.ASSIGNED_TECHS]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.assigned_techs',
            a11yDefault: 'Assigned Techs',
            sortValue: 'technicians',
          },
        ],
        filterTypeDefs: {
          nested: true,
        },
      },
    },
  },
  [TasksColumns.GROUP_CREATED_DATE]: {
    align: 'left',
    cols: {
      [TasksColumns.CREATED_DATE]: {
        filterType: TableFilterTypes.DATE,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.created_date',
            a11yDefault: 'Created Date',
            sortValue: 'createDate',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_ELIGIBLE_START_DATE]: {
    align: 'left',
    cols: {
      [TasksColumns.ELIGIBLE_START_DATE]: {
        filterType: TableFilterTypes.DATE,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.eligible_start_date',
            a11yDefault: 'Eligible Start Date',
            sortValue: 'eligibleStartDate',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_SCHEDULED_DATE]: {
    align: 'left',
    cols: {
      [TasksColumns.SCHEDULED_DATE]: {
        capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.VIEW] }],
        filterType: TableFilterTypes.DATE,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.scheduled_date',
            a11yDefault: 'Scheduled Date',
            sortValue: 'scheduleDate',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_DUE_DATE]: {
    align: 'left',
    cols: {
      [TasksColumns.DUE_DATE]: {
        filterType: TableFilterTypes.DATE,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.due_date',
            a11yDefault: 'Due Date',
            sortValue: 'dueDate',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_COMMITTED_DATE]: {
    align: 'left',
    cols: {
      [TasksColumns.COMMITTED_DATE]: {
        filterType: TableFilterTypes.DATE,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.committed_date',
            a11yDefault: 'Committed Date',
            sortValue: 'committedDate',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_STARTED_DATE_TIME]: {
    align: 'left',
    cols: {
      [TasksColumns.STARTED_DATE_TIME]: {
        minWidth: '150px',
        labels: [
          {
            a11yKey: 'table.start_date',
            a11yDefault: 'Start Date',
            sortValue: 'startedDateTime',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_COMPLETED_DATE_TIME]: {
    align: 'left',
    cols: {
      [TasksColumns.COMPLETED_DATE_TIME]: {
        filterType: TableFilterTypes.DATE,
        minWidth: '150px',
        labels: [
          {
            a11yKey: 'table.completed_date',
            a11yDefault: 'Completed Date',
            sortValue: 'completedDateTime',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_PARTS_EXPECTED]: {
    align: 'left',
    cols: {
      [TasksColumns.PARTS_EXPECTED]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '140px',
        labels: [
          {
            a11yKey: 'table.parts_expected',
            a11yDefault: 'Parts Expected',
            sortValue: 'expectedParts.length',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_PARTS_CONSUMED]: {
    align: 'left',
    cols: {
      [TasksColumns.PARTS_CONSUMED]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '140px',
        labels: [
          {
            a11yKey: 'table.parts_consumed',
            a11yDefault: 'Parts Consumed',
            sortValue: 'consumedParts.length',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_ATTACHMENT]: {
    cols: {
      [TasksColumns.ATTACHMENT]: {
        minWidth: '40px',
        labels: [
          {
            a11yKey: 'table.attachment',
            a11yDefault: 'Attachment',
            sortValue: 'attachment',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_NOTES]: {
    cols: {
      [TasksColumns.NOTES]: {
        capabilities: [
          { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
        ],
        minWidth: '40px',
        labels: [
          {
            a11yKey: 'table.notes',
            a11yDefault: 'Notes',
            sortValue: 'notes',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_RESOLUTION]: {
    cols: {
      [TasksColumns.RESOLUTION]: {
        filterType: TableFilterTypes.SEARCH,
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.resolution',
            a11yDefault: 'Resolution',
            sortValue: 'resolutionNotes',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_SR_NUMBER]: {
    cols: {
      [TasksColumns.SR_NUMBER]: {
        capabilities: [{ capability: Capability.WORK_ORDERS, scopes: [PermissionScope.VIEW] }],
        filterType: TableFilterTypes.SEARCH,
        minWidth: '100px',
        labels: [
          {
            a11yKey: 'table.sr_number',
            a11yDefault: 'SR Number',
            sortValue: 'srNumber',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_SR_STATUS]: {
    cols: {
      [TasksColumns.SR_STATUS]: {
        capabilities: [{ capability: Capability.WORK_ORDERS, scopes: [PermissionScope.VIEW] }],
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '100px',
        locked: false,
        labels: [
          {
            a11yKey: 'table.sr_status',
            a11yDefault: 'SR Status',
            sortValue: 'srStatus',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_DETAIL]: {
    fixedTop: '45px',
    fixedRight: true,
    cols: {
      [TasksColumns.DETAIL]: {
        fixedRight: true,
        locked: true,
        labels: [
          {
            a11yKey: 'table.detail',
            a11yDefault: 'Detail',
            sortValue: 'detail',
          },
        ],
      },
    },
  },
  [TasksColumns.GROUP_RDSPP]: {
    align: 'left',
    cols: {
      [TasksColumns.RDSPP]: {
        capabilities: [{ capability: Capability.WORK_ORDERS, scopes: [PermissionScope.VIEW] }],
        filterType: TableFilterTypes.SEARCH,
        minWidth: '120px',
        labels: [
          {
            a11yKey: 'table.rdspp',
            a11yDefault: 'RDSPP',
            sortValue: 'rdspp',
          },
        ],
      },
    },
  },
};

export const defaultTasksCols = [
  {
    id: TasksColumns.GROUP_SELECTED,
    cols: [
      {
        id: TasksColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_ACTIONS,
    cols: [
      {
        id: TasksColumns.ACTIONS,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_SITE,
    cols: [
      {
        id: TasksColumns.SITE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_ASSET,
    cols: [
      {
        id: TasksColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_STATE,
    cols: [
      {
        id: TasksColumns.STATE,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_PRIORITY,
    cols: [
      {
        id: TasksColumns.PRIORITY,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_STATUS,
    cols: [
      {
        id: TasksColumns.STATUS,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_TITLE,
    cols: [
      {
        id: TasksColumns.TITLE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_FLAG,
    cols: [
      {
        id: TasksColumns.FLAG,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: TasksColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_SOURCE,
    cols: [
      {
        id: TasksColumns.SOURCE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_WORK_SCOPE,
    cols: [
      {
        id: TasksColumns.WORK_SCOPE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_LINKED,
    cols: [
      {
        id: TasksColumns.LINKED,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_COMPONENT,
    cols: [
      {
        id: TasksColumns.COMPONENT,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_EST_TECHS,
    cols: [
      {
        id: TasksColumns.EST_TECHS,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_EST_DURATION,
    cols: [
      {
        id: TasksColumns.EST_DURATION,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_DURATION,
    cols: [
      {
        id: TasksColumns.ACTUAL_DURATION,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_EST_LABOR_HOURS,
    cols: [
      {
        id: TasksColumns.EST_LABOR_HOURS,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_CREATED_DATE,
    cols: [
      {
        id: TasksColumns.CREATED_DATE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_ELIGIBLE_START_DATE,
    cols: [
      {
        id: TasksColumns.ELIGIBLE_START_DATE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_SCHEDULED_DATE,
    cols: [
      {
        id: TasksColumns.SCHEDULED_DATE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_DUE_DATE,
    cols: [
      {
        id: TasksColumns.DUE_DATE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_COMMITTED_DATE,
    cols: [
      {
        id: TasksColumns.COMMITTED_DATE,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_STARTED_DATE_TIME,
    cols: [
      {
        id: TasksColumns.STARTED_DATE_TIME,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_COMPLETED_DATE_TIME,
    cols: [
      {
        id: TasksColumns.COMPLETED_DATE_TIME,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_ASSIGNED_TECHS,
    cols: [
      {
        id: TasksColumns.ASSIGNED_TECHS,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_PARTS_EXPECTED,
    cols: [
      {
        id: TasksColumns.PARTS_EXPECTED,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_PARTS_CONSUMED,
    cols: [
      {
        id: TasksColumns.PARTS_CONSUMED,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_ATTACHMENT,
    cols: [
      {
        id: TasksColumns.ATTACHMENT,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_NOTES,
    cols: [
      {
        id: TasksColumns.NOTES,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_RESOLUTION,
    cols: [
      {
        id: TasksColumns.RESOLUTION,
        visible: true,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_SR_NUMBER,
    cols: [
      {
        id: TasksColumns.SR_NUMBER,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_SR_STATUS,
    cols: [
      {
        id: TasksColumns.SR_STATUS,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_RDSPP,
    cols: [
      {
        id: TasksColumns.RDSPP,
        visible: false,
      },
    ],
  },
  {
    id: TasksColumns.GROUP_DETAIL,
    cols: [
      {
        id: TasksColumns.DETAIL,
        visible: true,
      },
    ],
  },
];
