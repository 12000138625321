import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import {
  NAMESPACE,
  Fields,
  RootCauseOption,
  RootCauseOptions,
  SourceMapping,
} from '@ge/feat-analyze/models/configure-case-template';
import { Capability, CasePriority } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render/auth-render';
import {
  MultiSelectMetaField,
  SelectMetaField,
  TagsMetaField,
  TextareaMetaField,
  TextMetaField,
  ToggleButtonMetaField,
} from '@ge/shared/components/meta-fields';
import {
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '@ge/shared/components/tasks/task-template-shared';
import { useComponentHierarchies } from '@ge/shared/data-hooks';

import { FormCollapsiblePanel, FormRow } from '../../shared';

const Container = styled.div`
  position: relative;
  padding-top: 2rem;
`;

const FieldWrapper = styled.div`
  width: 17%;
`;
const FieldEqualWrapper = styled.div`
  width: 50%;
`;
const FieldComponentWrapper = styled.div`
  width: 33%;
`;
const EditBtnContainer = styled.button`
  position: absolute;
  right: 20px;
  top: 2rem;
  cursor: pointer;
  color: ${(props) => props.theme.entityDetails.tasks.details.inputColor};
`;

const DisplayCaseFeild = styled(DisplayField)`
  text-transform: capitalize;
  margin-top: 4px;
  padding-bottom: 13px;
  line-height: 13px;
  font-size: 13px;
  line-height: 18px;
  word-wrap: break-word;
`;

const DisplayValueField = styled(DisplayField)`
  margin-top: 4px;
  padding-bottom: 13px;
  line-height: 13px;
  font-size: 13px;
  line-height: 18px;
  word-wrap: break-word;
`;

const DisplayCaseTitleValues = styled(DisplayField)`
  margin-top: 7px;
  padding-bottom: 17px;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  width: 480px;
`;

const EditButton = styled(Icon).attrs((props) => ({
  size: 14,
  color: props.theme.entityDetails.cases.details.inputColor,
  icon: Icons.PENCIL,
}))``;

const DescriptionContainer = styled.div`
  padding: 10px 20px 0 20px;
  position: relative;
  overflow: visible;
`;

const DescriptionEditContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.entityDetails.cases.details.rowBorder};
  ${'' /* padding: 10px 20px 0 20px; */}
  position: relative;
  height: auto;
  min-height: 100% !important;
  overflow: visible;
`;

const PrioritySelectLabel = styled.span`
  margin-left: 5px;
  text-transform: capitalize;
`;

const TagsInput = styled(TagsMetaField)``;

const Input = styled(TextMetaField)``;

const Select = styled(SelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
  .select__menu-list {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      height: 233.11px;
      width: 2px;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.table.fixedBorderColor};
    }
  }
`;

const MultiSelect = styled(MultiSelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const ToggleButton = styled(ToggleButtonMetaField)`
  .toggle-switch {
    border: none;
  }
`;

export const CaseDetails = ({ caseDetails, isEditing, toggleIsEditing, isOpen }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { data } = useComponentHierarchies();

  const priorityOptions = useMemo(
    () =>
      Object.values(CasePriority).map((value) => {
        return {
          value,
          label: (
            <>
              <Severity level={value} />
              <PrioritySelectLabel>{value}</PrioritySelectLabel>
            </>
          ),
        };
      }),
    [],
  );

  const sourceOptions = useMemo(
    () =>
      Object.entries(SourceMapping).map(([key, value]) => {
        return {
          value,
          label: t('case_template.dynamic.' + key, value),
        };
      }),
    [t],
  );

  const toComponentSelectOption = (v) => ({ value: v.name, label: v.name });
  const { watch, setValue } = useFormContext();
  const selectedComponent1 = watch(Fields.COMPONENT_L1, caseDetails?.componentTypeIds[0]);
  const selectedComponent2 = watch(Fields.COMPONENT_L2, caseDetails?.componentTypeIds[1]);
  const selectedComponent3 = watch(Fields.COMPONENT_L3, caseDetails?.componentTypeIds[2]);
  const selectedRootCauses = watch(Fields.ROOT_CAUSES, caseDetails?.rootCauses);
  const [selectedRootOptions, setSelectedRootOptions] = useState(selectedRootCauses);

  const component1Options = useMemo(() => data?.map(toComponentSelectOption) ?? [], [data]);
  const component2Options = useMemo(
    () =>
      data?.find((v) => v.name === selectedComponent1)?.children.map(toComponentSelectOption) ?? [],
    [selectedComponent1, data],
  );
  const component3Options = useMemo(
    () =>
      data
        ?.find((v) => v.name === selectedComponent1)
        ?.children?.find((v) => v.name === selectedComponent2)
        ?.children?.map(toComponentSelectOption) ?? [],
    [selectedComponent1, selectedComponent2, data],
  );

  useEffect(() => {
    if (component2Options.length === 0) {
      setValue('componentL2', null);
    }
    if (component3Options.length === 0) {
      setValue('componentL3', null);
    }
  }, [
    component2Options.length,
    component3Options.length,
    selectedComponent2,
    selectedComponent3,
    setValue,
  ]);
  const rootCauses = useMemo(() => RootCauseOptions, []);
  const rootCausesOptions = useMemo(() => {
    const options =
      selectedRootOptions
        ?.filter((filter) => filter != 'Other' && filter != 'Add New')
        .map((name) => ({ label: name, value: name })) ?? [];
    return options.concat(rootCauses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedRootOptions, rootCauses]);

  if (!ready) return null;
  return (
    <Container>
      <FormCollapsiblePanel
        isOpen={isOpen}
        title={t('case_template.case_detail', 'Case Detail')}
        allowOverflow={true}
        headerContent={
          !isEditing && (
            <AuthRender
              capability={Capability.CASES_RECOMMENDATIONS}
              edit
              description="Edit case description button"
              siteLevel={false}
            >
              <EditBtnContainer onClick={toggleIsEditing}>
                <EditButton /> {t('case_template:edit', 'EDIT')}
              </EditBtnContainer>
            </AuthRender>
          )
        }
      >
        <DescriptionContainer>
          {isEditing ? (
            <>
              <FormRow>
                <Input
                  name={Fields.CASE_TEMPLATE_ID}
                  label={t('case_template.case_template_id', 'Case Template Id')}
                  placeholder={t(
                    `case_template.case_template_id_placeholder`,
                    'Type case template id...',
                  )}
                  defaultValue={caseDetails.caseTemplateId}
                  metadata={{ required: true, readOnly: true }}
                />
                <Input
                  name={Fields.CASE_TITLE}
                  label={t('case_template.case_title', 'Case Title')}
                  placeholder={t(`case_template.case_title_placeholder`, 'Type case title...')}
                  defaultValue={caseDetails.title}
                  metadata={{ required: true }}
                />
              </FormRow>
              <FormRow>
                <Select
                  name={Fields.COMPONENT_L1}
                  label={t('case_template.component_l1', 'Component L1')}
                  placeholder={t(
                    `case_template.component_l1_placeholder`,
                    'Please select L1 component',
                  )}
                  defaultValue={
                    caseDetails?.componentTypeIds[0] ? caseDetails?.componentTypeIds[0] : null
                  }
                  options={component1Options}
                  metadata={{ required: true }}
                  maxWidth={144}
                />
                <Select
                  name={Fields.COMPONENT_L2}
                  label={t('case_template.component_l2', 'Component L2')}
                  placeholder={t(
                    `case_template.component_l2_placeholder`,
                    'Please select L2 component',
                  )}
                  defaultValue={
                    caseDetails?.componentTypeIds[1] ? caseDetails?.componentTypeIds[1] : null
                  }
                  options={component2Options}
                  metadata={{ required: false, readOnly: !selectedComponent1 }}
                  maxWidth={144}
                />
                <Select
                  name={Fields.COMPONENT_L3}
                  label={t('case_template.component_l3', 'Component L3')}
                  placeholder={t(
                    `case_template.component_l3_placeholder`,
                    'Please select L3 component',
                  )}
                  defaultValue={
                    caseDetails?.componentTypeIds[2] ? caseDetails?.componentTypeIds[2] : null
                  }
                  options={component3Options}
                  metadata={{
                    required: false,
                    readOnly: !selectedComponent1 || !selectedComponent2,
                  }}
                  maxWidth={144}
                />
              </FormRow>
              <FormRow>
                <Select
                  name={Fields.CASE_PRIORITY}
                  label={t('case_template.priority', 'Priority')}
                  placeholder={t(`case_template.priority_placeholder`, 'Please Select a priority')}
                  defaultValue={caseDetails.priority}
                  options={priorityOptions}
                  metadata={{ required: false }}
                  searchable={false}
                />
                <Select
                  name={Fields.CASE_SOURCE}
                  label={t('case_template.source', 'Source')}
                  placeholder={t(`case_template.source_placeholder`, 'Please Select a source')}
                  defaultValue={caseDetails.source ?? ''}
                  options={sourceOptions}
                  metadata={{ required: false }}
                  searchable={false}
                />
                <ToggleButton
                  name={Fields.IS_BETA}
                  label={t('case_template.beta', 'Beta')}
                  defaultValue={caseDetails.isBeta}
                  metadata={{ required: false }}
                />
              </FormRow>
              <FormRow>
                <TextareaMetaField
                  name={Fields.CASE_DESCRIPTION}
                  label={t('case_template.description', 'Description')}
                  placeholder={t(
                    `case_template.description_placeholder`,
                    'Type case description id...',
                  )}
                  defaultValue={caseDetails.description}
                  metadata={{ required: true }}
                />
              </FormRow>
              <FormRow>
                <MultiSelect
                  name={Fields.ROOT_CAUSES}
                  label={t('case_template.root_causes', 'Root Causes')}
                  placeholder={t(
                    `case_template.root_causes_placeholder`,
                    'Please Select root causes',
                  )}
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  defaultValue={[...new Set([RootCauseOption.OTHER, ...caseDetails?.rootCauses])]}
                  options={rootCausesOptions}
                  excludeSearch={[RootCauseOption.ADD_NEW]}
                  metadata={{ required: true }}
                  minWidth={220}
                  maxWidth={220}
                  isMulti={true}
                  isSelectAll={true}
                />
                {selectedRootCauses?.includes(RootCauseOption.ADD_NEW) && (
                  <TagsInput
                    name={Fields.ROOT_CAUSES_NEW}
                    label={t('case_template.root_causes_text', 'Add New')}
                    placeholder={t(
                      `case_template.root_causes_text_placeholder`,
                      'Type root cause...',
                    )}
                    defaultValue={caseDetails?.rootCauses}
                    metadata={{
                      required: selectedRootCauses?.includes(RootCauseOption.ADD_NEW),
                    }}
                    maxWidth={224}
                  />
                )}
              </FormRow>
            </>
          ) : (
            <>
              <DescriptionEditContainer>
                <FieldGroup>
                  <FieldEqualWrapper className="field-wrapper">
                    <DisplayLabel>{t('Case Template ID')}</DisplayLabel>
                    <DisplayCaseFeild>{caseDetails.caseTemplateId}</DisplayCaseFeild>
                  </FieldEqualWrapper>
                  <FieldEqualWrapper className="field-wrapper">
                    <DisplayLabel>{t('Case Title')}</DisplayLabel>
                    <DisplayValueField>{caseDetails.title}</DisplayValueField>
                  </FieldEqualWrapper>
                </FieldGroup>
                <FieldGroup>
                  {caseDetails.componentTypeIds.map((component, key) => (
                    <FieldComponentWrapper key={component}>
                      <DisplayLabel>{t(`Component ${key + 1}`)}</DisplayLabel>
                      <DisplayCaseFeild>{component}</DisplayCaseFeild>
                    </FieldComponentWrapper>
                  ))}
                </FieldGroup>
                <FieldGroup>
                  <FieldWrapper className="field-wrapper">
                    <DisplayLabel>{t('Priority')}</DisplayLabel>
                    <DisplayCaseFeild>{caseDetails.priority?.toLowerCase()}</DisplayCaseFeild>
                  </FieldWrapper>
                  <FieldWrapper className="field-wrapper">
                    <DisplayLabel>{t('Source')}</DisplayLabel>
                    <DisplayCaseFeild name={Fields.CASE_SOURCE}>
                      {caseDetails.source?.toLowerCase()}
                    </DisplayCaseFeild>
                  </FieldWrapper>
                </FieldGroup>
                <DisplayLabel>{t('Description')}</DisplayLabel>
                <DisplayCaseTitleValues>{caseDetails.description}</DisplayCaseTitleValues>
                <DisplayLabel>{t('Root Causes')}</DisplayLabel>
                {caseDetails.rootCauses.map((cause) => (
                  <DisplayCaseTitleValues key={cause}>{cause}</DisplayCaseTitleValues>
                ))}
              </DescriptionEditContainer>
            </>
          )}
        </DescriptionContainer>
      </FormCollapsiblePanel>
    </Container>
  );
};

CaseDetails.propTypes = {
  caseDetails: PropTypes.instanceOf(Object).isRequired,
  isEditing: PropTypes.bool,
  toggleIsEditing: PropTypes.func,
  isOpen: PropTypes.bool,
};
