import Linkify from 'linkify-react';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Select } from '@ge/components/select';
import { Th } from '@ge/components/table';
import { DraggableTable } from '@ge/components/table';
import { Textarea } from '@ge/components/textarea';
import { globalColors } from '@ge/tokens';
import { urlValidation } from '@ge/util/valid-url-utils';

export const ErrorWrapper = styled.div`
  font-size: 1.5em;
  margin: 0.9em 2em;
  color: ${globalColors.red4};
`;

export const THWrap = styled(Th)`
  background: ${globalColors.grey9} !important;
`;

export const MailFieldsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 187px) 1fr min-content;
  grid-column-gap: 13px;
  justify-content: center;
  line-height: 1px;
  margin-bottom: 5px;
  .eventCode {
    line-height: 1px;
    font-size: 11px;
    color: ${(props) => props.theme.colors.grey4};
  }
`;

export const StyledSelect = styled(Select)`
  .select__placeholder {
    font-style: italic;
  }
  .select__menu-list {
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      height: 100px;
      border: 2px solid transparent;
      background-clip: content-box;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.customSelect.scrollBar};
    }
  }
`;

export const HandlingProcedureFormSection = styled.div`
  border-top: 2px solid ${(props) => props.theme.colors.slate16};
`;

export const StyledTableHeader = styled.p`
  font-weight: 300;
  font-stretch: normal;
  font-size: ${(props) => (props.view ? '15px' : '13px')};
  color: ${(props) => (props.view ? '#fff' : '#999999')};
  line-height: 15px;
  margin: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
`;

export const AddRow = styled(Button)`
  background: transparent;
  border: none;
  text-transform: uppercase;
  cursor: pointer !important;
`;

export const RecipientsWrapper = styled.div`
  div {
    margin: 0.2em;
  }
  .table-block-cls {
    margin: 1em 0;
    table {
      border: none !important;
      border-collapse: collapse;
      color: ${(props) => props.theme.colors.slate5};
      th {
        text-align: left;
      }
      tr:nth-child(odd) {
        background: ${(props) => props.theme.colors.slate12};
        /* td:nth-child(odd) {
          margin-left: 88rem;
        } */
      }
      tr:nth-child(even) {
        background: ${(props) => props.theme.colors.slate16};
      }
    }
  }
`;

export const StyledInput = styled(Input)`
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FormContainer = styled.div`
  background: ${(props) => props.theme.distributionListSidePanel.formBackground};
  padding: 30px 20px;
`;

export const ScrollableDiv = styled.div`
  max-height: calc(100vh - 130px);
  text-transform: capitalize;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
  .label-cls,
  label[for='hp_select'] {
    font-size: 11px;
    color: #999999;
  }
  .label-cls {
    margin: 0 0 5px;
  }
  &.loading-cls {
    display: none;
  }
`;

export const StyledHLPFooter = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 30px 20px;
`;

export const UpdatedBy = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 192px;
  margin-top: 20px;
`;

export const UpdatedLabel = styled.div`
  color: ${globalColors.grey4};
  font: 500 11px/18px Museo Sans;
`;

export const UpdatedValue = styled.div``;

export const StyledOverView = styled.p`
  font-weight: 600;
  font-stretch: normal;
  font-size: 15px;
  line-height: 15px;
  margin: 0;
`;

export const StyledTextarea = styled(Textarea)`
  resize: none !important;
  height: 150px;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .handling-description [data-simplebar] {
    position: static !important;
    display: block !important;
  }
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  padding: 1.5em;
  min-height: 50px;
  width: auto;
  border-radius: 6px;
  background-color: ${(props) => props.theme.distributionListSidePanel.header};
  box-shadow: inset 0px 6px ${(props) => props.theme.distributionListSidePanel.headerShadow};
  margin: 10px;
`;

export const Container = styled.div`
  button {
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
  }
`;

export const ViewTitle = styled.h4`
  color: #80c5d3;
  padding: 0.5em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 0 0 0 0.4em;
`;

export const HeaderTitle = styled.h1`
  display: inline-block;
  color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
  font-family: 'GE Inspira';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 10px;
`;

export const TitleWrapper = styled.div`
  border-right: 1px solid ${(props) => props.theme.distributionListSidePanel.headerTitle};
`;
export const CreateHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewDLHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  align-items: center;
  .hp-delete {
    display: flex;
    padding-bottom: 0px;
  }
`;

export const StyledConfirmationFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const ErrorMsg = styled.p`
  margin: 8px 0;
  color: ${globalColors.red4};
`;

export const StyledSubNavigation = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  padding-left: 18px;
  padding-bottom: 8px;
  align-items: flex-end;
  border-bottom: solid 3px ${(props) => props.theme.subNavigation.linkBorderColor};
`;

export const StyledHPFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledDraggableTable = styled(DraggableTable)`
  th:not(:last-child),
  td:not(:last-child) {
    border-right: solid 1px ${(props) => props.theme.subNavigation.linkBorderColor} !important;
  }
  td {
    &.group-last {
      top: 0px !important;
      z-index: 8 !important;
    }
  }
`;

export const StyledEventMapSelect = styled(Select)`
  .select__menu-list {
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      height: 100px;
      border: 2px solid transparent;
      background-clip: content-box;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.customSelect.scrollBar};
    }
  }
`;
export const AddDLWrapper = styled.div`
  cursor: pointer;
  margin: 0.5em 1em 0 0;
`;

export const StyledIcon = styled(Icon).attrs((props) => ({
  size: props.size,
  icon: props.icon,
  color: props.theme.postProcess.iconButton,
}))``;

export const StyledLinkify = styled(Linkify).attrs((props) => ({
  options: {
    attributes: {
      onClick: (e) => {
        if (urlValidation.test(e.target.href)) {
          e.preventDefault();
          window.open(
            e.target.href,
            '_blank',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=680px,height=700px',
          );
        }
      },
      style: { color: props.theme.caseProcedure.linkColor },
    },
  },
  tagName: 'span',
}))`
  word-break: break-all;
`;

export const ViewMoreIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.alertDialog.expandButtonColor,
}))`
  margin: 3px;
  font-weight: bold;
`;

const scrollbarHeight = `
    .simplebar-content-wrapper {
      height: 100% !important;
    }
  `;

export const ScrollingBar = styled.div`
  display: flex;
  min-height: 5vh;
  overflow: hidden;
  [data-simplebar='init'] {
    padding-right: 10px;
  }
  ${scrollbarHeight}
`;

export const StyledDiv = styled.div`
  .see-more {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    display: inline;
  }
  .toggle-icon {
    padding: 2px;
    cursor: pointer;
    color: ${(props) => props.theme.alertDialog.expandButtonColor};
    margin-left: 7px;
  }
`;
