import { useStoreActions } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';

import { EntityType, QueryKey, SortDirection } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import {
  addNoteAndSI,
  deleteNoteAndSI,
  editNoteAndSI,
  fetchEntitiesNotes,
} from '../services/notes';

import { CasesQueryKeys } from './cases/query-keys';
import useCaseDetailDataByQuery from './cases/use-case-detail-data-by-query';

import { Config } from './';

const assetType = EntityType.CASE;

/**
 * Get notes for case and tasks
 * @param {Object} params
 * @param {Array} params.queryKey -Query key for react query
 * @param {('case'|'task')} params.entityType - Type of entity(case/task) to get notes for
 * @param {Array} params.entityIds - Case and task ids to get notes for
 * @returns {data: Object, isLoading: Boolean}
 */
const useEntityNotes = ({ queryKey, entityType, entityIds }) => {
  const { data, isLoading, ...queryRest } = useQuery(
    queryKey,
    () => fetchEntitiesNotes(entityIds, entityType),
    {
      enabled: Boolean(entityIds.length),
      ...Config.EXECUTE_ONCE,
    },
  );

  const notes = useMemo(
    () => (isLoading || !data?.notes ? [] : data.notes),
    [data?.notes, isLoading],
  );

  return {
    data: notes,
    isLoading,
    ...queryRest,
  };
};

/**
 * This is used to delete case and task notes
 * @returns { { deleteNote: Function, isLoading: Boolean, isError: Boolean, error: Object } }
 */
const useDeleteEntityNote = () => {
  const { fetchCaseWithDetails } = useStoreActions((actions) => actions.cases);
  const queryClient = useQueryClient();
  const {
    mutate: deleteNote,
    isLoading,
    isError,
    error,
  } = useMutation(deleteNoteAndSI, {
    onSettled: (_, error, variables) => {
      if (!error) {
        const caseId =
          variables.entityType === EntityType.CASE ? variables.domainId : variables.caseId;
        const queryKey =
          variables.entityType === EntityType.CASE ? QueryKey.CASE_NOTES : QueryKey.TASK_NOTES;
        queryClient.setQueryData([queryKey, caseId], (data) => {
          return { ...data, notes: data.notes.filter((n) => n.id !== variables.id) };
        });
        queryClient.setQueriesData(CasesQueryKeys.lists(), (prev) => {
          const updatedCases = prev?.data?.map((_case) => {
            if (_case.id === variables.domainId) {
              const caseNotesCount = _case.notes && _case.notes?.caseNotesCount - 1;
              const taskNotesCount = _case.notes && _case.notes?.taskNotesCount;
              const totalCount = caseNotesCount + taskNotesCount;
              const notes = {
                caseNotesCount,
                taskNotesCount,
                totalCount,
              };

              return { ..._case, notes };
            }
            return _case;
          });
          return { ...prev, data: updatedCases };
        });
        fetchCaseWithDetails(caseId);
      }
    },
  });

  return { deleteNote, isLoading, isError, error };
};

/**
 * This is used to edit case and task notes
 * @returns {{ editNote: Function, isLoading: Boolean, isError: Boolean, error: Object }}
 */
const useEditEntityNote = () => {
  const queryClient = useQueryClient();
  const {
    mutate: editNote,
    isLoading,
    isError,
    error,
  } = useMutation(editNoteAndSI, {
    onSettled: (response, error, variables) => {
      if (!error) {
        const queryKey =
          variables.originalData.entityType === EntityType.CASE
            ? QueryKey.CASE_NOTES
            : QueryKey.TASK_NOTES;
        queryClient.setQueryData([queryKey, variables.originalData.domainId], (data) => {
          return {
            ...data,
            notes: data.notes.map((note) =>
              note.id !== variables.originalData.id ? note : { ...note, ...response.updated },
            ),
          };
        });
      }
    },
  });

  return { editNote, isLoading, isError, error };
};

/**
 * This is used to add case and task notes
 * @returns {{ addNote: Function, isLoading: Boolean, isError: Boolean, error: Object }}
 */
const useAddEntityNote = () => {
  const queryClient = useQueryClient();
  const {
    mutate: addNote,
    isLoading,
    isError,
    error,
  } = useMutation(addNoteAndSI, {
    onSuccess: (response, variables) => {
      const queryKey =
        variables.entityType === EntityType.CASE ? QueryKey.CASE_NOTES : QueryKey.TASK_NOTES;
      queryClient.setQueryData([queryKey, response.domainId], (data) => {
        return {
          ...data,
          notes: [...data.notes, response],
        };
      });
    },
  });

  return { addNote, isLoading, isError, error };
};

/**
 * This hook can be used to get case, child cases and task notes. Also this gives the ability to add/edit/delete notes
 * @param {{ caseId: String }} caseId - The identity of the case you wish to operate on it's notes
 * @returns {Object} ret
 * @returns {Array} ret.notes - List of case and task notes including
 * @returns {Function} ret.addNote - Can be used to add a new note
 * @returns {Function} ret.editNote - Can be used to edit and existing note
 * @returns {Function} ret.deleteNote - Can be used to delete an existing note
 * @returns {Boolean} ret.isLoading - Shows whether notes are being fetched, added, edited or updated
 * @returns {{ isCaseNotesLoading: Boolean, isTaskNotesLoading: Boolean, isAdding: Boolean, isEditing: Boolean, isDeleting: Boolean }} ret.loadingStates
 *    Object with individual loading state for each of the operations
 */
const useCaseNotes = ({ caseId }) => {
  const id = caseId;

  const { caseWithDetails, isLoading: isCaseDetailLoading } = useCaseDetailDataByQuery({ caseId });
  const caseIds = useMemo(
    () => (isCaseDetailLoading || !id ? [] : [id, ...caseWithDetails.childCaseIds]),
    [isCaseDetailLoading, id, caseWithDetails?.childCaseIds],
  );

  const taskIds = useMemo(() => {
    if (!id || isCaseDetailLoading) {
      return [];
    }
    const taskIds = caseWithDetails?.taskIds ?? [];
    const childTaskIds = caseWithDetails.childCases?.map((c) => c.taskIds) ?? [];
    return taskIds.concat(childTaskIds.flat(1));
  }, [caseWithDetails?.childCases, caseWithDetails?.taskIds, id, isCaseDetailLoading]);

  // Getting notes for parent and child cases
  const { data: caseNotes, isLoading: isCaseNotesLoading } = useEntityNotes({
    queryKey: [QueryKey.CASE_NOTES, caseId],
    entityType: assetType,
    entityIds: caseIds,
  });

  // Getting notes for parent and child cases tasks
  const { data: taskNotes, isLoading: isTaskNotesLoading } = useEntityNotes({
    queryKey: [QueryKey.TASK_NOTES, caseId],
    entityType: EntityType.TASK,
    entityIds: taskIds,
  });

  const { addNote, isLoading: isAdding } = useAddEntityNote();
  const { editNote, isLoading: isEditing } = useEditEntityNote();
  const { deleteNote, isLoading: isDeleting } = useDeleteEntityNote();

  const notes = useMemo(() => {
    const _caseNotes = caseNotes.map((note) => ({ ...note, entityType: assetType }));
    const _taskNotes = taskNotes.map((note) => ({ ...note, entityType: EntityType.TASK }));
    return [..._caseNotes, ..._taskNotes].sort(sorter('updateDate', SortDirection.DESC));
  }, [caseNotes, taskNotes]);

  return {
    notes,
    addNote,
    editNote,
    deleteNote,
    isLoading:
      isCaseDetailLoading ||
      isCaseNotesLoading ||
      isTaskNotesLoading ||
      isAdding ||
      isEditing ||
      isDeleting,
    loadingStates: {
      isCaseNotesLoading,
      isTaskNotesLoading,
      isAdding,
      isEditing,
      isDeleting,
    },
  };
};

export default useCaseNotes;
