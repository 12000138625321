import { Capability, PermissionScope } from '@ge/models/constants';

const MyReportsTab = {
  capabilities: [{ capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] }],
  label: 'My Reports',
  i18nKey: 'nav:reporting.l3.reports.my_reports',
  route: '/reporting/reports/my-reports',
};

const CreateTab = {
  capabilities: [{ capability: Capability.REPORTING_TEMPLATES, scopes: [PermissionScope.VIEW] }],
  label: 'Create Report',
  i18nKey: 'nav:reporting.l3.reports.create_report',
  route: '/reporting/reports/create',
  withParams: true,
};

export const TabState = { ACTIVE: 'active', DISABLED: 'disabled' };

export const ReportsSubNavItems = {
  [TabState.ACTIVE]: [MyReportsTab, CreateTab],
  [TabState.DISABLED]: [MyReportsTab],
};
