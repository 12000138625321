import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useContext, useState, useCallback } from 'react';
// import { useFormContext } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { DataLoader } from '@ge/components/data-loader';
import { Capability, DataLoaderType, PermissionScope } from '@ge/models/constants';
import { TaskPriority, TaskTemplateModes } from '@ge/models/constants';
import { elevations } from '@ge/tokens';

import {
  getAssetActiveEvents,
  getAssetAllAplicableEvents,
  getCausalEvent,
} from '../../../../../../../apps/web-client/src/app/services/asset-events';
import { EntityDetailsContext } from '../../../../../context/entity-details-context';
import { Config, useAuth } from '../../../../../data-hooks';
import { DateMetaField } from '../../../../meta-fields/date-meta-field';
import { TextMetaField } from '../../../../meta-fields/text-meta-field';
import { TextareaMetaField } from '../../../../meta-fields/textarea-meta-field';
import { EventsSelectWithFilter } from '../../../events-select-with-filter';
import { PrioritySelect } from '../../../task-fields/priority-select';
import { TaskSourceSelect } from '../../../task-fields/task-source-select';
import { TaskWorkScopeSelect } from '../../../task-fields/task-workscope-select';
import {
  DisplayLabel,
  FormRow,
  FormSection,
  StyledDateContainer,
  TaskTypeRow,
} from '../../../task-template-shared';

import { TaskDetailErrors } from './task-detail-errors';

const DataLoaderContainer = styled.div`
  margin: 0 auto;

  img {
    height: 25vh;
  }
`;

const DatesRow = styled(FormRow)`
  justify-content: flex-start;
  position: relative;
  z-index: ${elevations.P2};
`;

const TitleAssigneeRow = styled(FormRow)`
  > div:first-of-type {
    flex-grow: 1;
    margin-right: 0;
  }
`;

const DescriptionRow = styled(FormRow)`
  width: 100%;
`;

const StyledTaskDetailErrors = styled(TaskDetailErrors)`
  margin: 8px 0 10px 0;
`;

const StyledTextAreaMetaField = styled(TextareaMetaField)`
  border: ${(props) =>
    JSON.stringify(props.workStandards) !== '{}' &&
    props.workStandards?.taskDescription === props.val &&
    '2px solid #2b5c6b'};
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 25px 0 0 23px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
`;

export const CreateTaskDetail = ({ metadata, task, rules, isCalledfromTaskEscalationColumn }) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const { setDisableNewTaskSaveBtn } = useContext(EntityDetailsContext);
  const [approvedTask, setApprovedTask] = useState(CheckedState.UNCHECKED);

  // GLOBAL STATE to keep track of selected alarm or selected Asset for form
  const { selectedAlarmOrEvent } = useStoreState((store) => store.tasks);
  const { selectedAssetToEscalate } = useStoreState((store) => store.tasks);
  const assetId =
    selectedAlarmOrEvent !== null
      ? selectedAlarmOrEvent.assetId
      : selectedAssetToEscalate !== null
      ? selectedAssetToEscalate.id
      : null;

  const { workStandards } = useStoreState((store) => store.tasks);
  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

  const { isAuthorized } = useAuth();
  const authorizedFieldTaskApprover = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASK_APPROVER, scopes: [PermissionScope.EDIT] }],
  });

  // TODO: Started validation but waiting on styles from FutureDraft
  const { register, setValue, watch } = useFormContext();
  // const { taskDetailTitle, taskDetailDescription } = errors || {};

  const watchDescription = watch('description');

  useEffect(() => {
    if (task.priority) setValue('priority', task.priority.toLowerCase());
    if (task.description) setValue('description', task.description);
  }, [setValue, task.description, task.priority]);

  useEffect(() => {
    if (workStandards !== null) {
      if (workStandards?.taskDescription !== null) {
        setValue('description', workStandards?.taskDescription);
      } else if (workStandards === null || workStandards?.taskDescription === null) {
        setValue('description', '');
      }
    }
  }, [workStandards?.taskDescription, setValue, workStandards]);

  // Fetch events

  const { data: causalEvents, isLoading: isCausalEventLoading } = useQuery(
    ['causalEvents', selectedAlarmOrEvent, selectedAssetToEscalate],
    async () => getCausalEvent(assetId),
    { ...Config.EXECUTE_ONCE },
  );

  const { data: activeEvents } = useQuery(
    ['activeEvents', selectedAlarmOrEvent, selectedAssetToEscalate],
    async () => getAssetActiveEvents(assetId),
    { ...Config.EXECUTE_ONCE, enabled: Boolean(assetId) },
  );

  let { data: allEventsOne, isLoading: isAllEventsOne } = useQuery(
    ['allEvents', selectedAlarmOrEvent, selectedAssetToEscalate],
    async () => assetId && getAssetAllAplicableEvents({ assetId: assetId, pageIdx: 0 }),
    { ...Config.EXECUTE_ONCE },
  );

  useEffect(
    () => setDisableNewTaskSaveBtn(isCausalEventLoading),
    [isCausalEventLoading, setDisableNewTaskSaveBtn],
  );

  const toggleApprovedTask = useCallback(() => {
    setApprovedTask(
      approvedTask === CheckedState.CHECKED ? CheckedState.UNCHECKED : CheckedState.CHECKED,
    );
  }, [approvedTask, setApprovedTask]);

  return (
    <FormSection>
      <h4>{t('section_header.task_details', 'Task Details')}</h4>
      <TaskTypeRow>
        <TaskSourceSelect task={task} />
        <TaskWorkScopeSelect />
        {!metadata?.priority?.create.hidden && (
          <PrioritySelect
            defaultSelection={metadata.priority.defaultSelection}
            metadata={metadata.priority.create}
            values={metadata.priority.values}
          />
        )}
      </TaskTypeRow>
      {!metadata?.title?.create.hidden && (
        <>
          {/* Conditional Render -> If selectedAlarmOrEvent.description has a value -> render SelectWithFilter component else -> render TextMetaField */}

          {(selectedAlarmOrEvent?.description || selectedAssetToEscalate?.name) &&
          isCalledfromTaskEscalationColumn ? (
            !isAllEventsOne ? (
              <EventsSelectWithFilter
                metadata={metadata}
                causalEvents={causalEvents}
                activeEvents={activeEvents}
                allEventsOne={allEventsOne}
                assetId={assetId}
                templateMode={TaskTemplateModes.CREATE}
              />
            ) : (
              <>
                <DisplayLabel>
                  {t('form.title', 'Title')}
                  {metadata.title.create.required && <span>*</span>}
                </DisplayLabel>
                <DataLoader type={DataLoaderType.GRID} isLoading={isAllEventsOne} />
              </>
            )
          ) : (
            <TitleAssigneeRow>
              <TextMetaField
                defaultValue={task?.title ? task.title : ''}
                label={t('form.title', 'Title')}
                maxLength={metadata.title.maxLength}
                metadata={metadata.title.create}
                name="title"
                placeholder={t('form.task_title', 'Task Title')}
                tabIndex={1}
              />
            </TitleAssigneeRow>
          )}
          {/* {taskDetailTitle && <div>{t('form.field_is_required', 'Field is required')}</div>} */}
        </>
      )}
      {!metadata?.description?.create.hidden && (
        <DescriptionRow>
          {workStandards?.isLoading ? (
            <DataLoaderContainer>
              <DataLoader type={DataLoaderType.GRID} isLoading={workStandards?.isLoading} />
            </DataLoaderContainer>
          ) : (
            <StyledTextAreaMetaField
              label={t('form.description', 'Description')}
              metadata={metadata.description.create}
              maxLength={metadata.description.maxLength}
              name="description"
              placeholder={t('form.description_placeholder', 'Add a Description')}
              tabIndex={2}
              defaultValue={
                workStandards !== null && workStandards?.taskDescription !== null
                  ? workStandards?.taskDescription
                  : ''
              }
              workStandards={workStandards}
              val={watchDescription}
            />
          )}
          {/* {taskDetailDescription && <div>{t('form.field_is_required', 'Field is required')}</div>} */}
        </DescriptionRow>
      )}
      <DatesRow>
        {!metadata?.eligibleStartDate?.create.hidden && (
          <StyledDateContainer>
            <div className="label">{t('form.eligible_start_date', 'Eligible Start Date')}</div>
            <DateMetaField
              defaultSelection={metadata.eligibleStartDate?.defaultSelection}
              metadata={metadata.eligibleStartDate.create}
              name="eligibleStartDate"
              validators={metadata.eligibleStartDate.create?.validators}
              rules={rules}
            />
          </StyledDateContainer>
        )}
        {!metadata?.dueDate?.create.hidden && (
          <StyledDateContainer>
            <div className="label">{t('form.due_date', 'Due Date')}</div>
            <DateMetaField
              defaultSelection={metadata.dueDate?.defaultSelection}
              metadata={metadata.dueDate.create}
              name="dueDate"
              validators={metadata.dueDate.create?.validators}
            />
          </StyledDateContainer>
        )}
        {metadata?.committedDate && !metadata?.committedDate?.create?.hidden && (
          <StyledDateContainer>
            <div className="label">{t('form.committed_date', 'Committed Date')}</div>
            <DateMetaField
              defaultSelection={metadata?.committedDate?.defaultSelection}
              metadata={metadata?.committedDate?.create}
              name="committedDate"
              validators={metadata?.committedDate?.create?.validators}
            />
          </StyledDateContainer>
        )}
        {authorizedFieldTaskApprover &&
          approvalFlag &&
          metadata?.approvalFlag &&
          !metadata?.approvalFlag?.create?.hidden && (
            <StyledCheckbox
              label={t('dynamic.task_flag.approved', 'APPROVED')}
              checkState={approvedTask}
              onChange={toggleApprovedTask}
              name="approvalFlag"
              ref={register({ approvalFlag: true })}
            />
          )}
      </DatesRow>
      <StyledTaskDetailErrors />
    </FormSection>
  );
};

CreateTaskDetail.propTypes = {
  metadata: PropTypes.object.isRequired,
  priority: PropTypes.oneOf(Object.values(TaskPriority)),
  task: PropTypes.object,
  rules: PropTypes.instanceOf(Object),
  isCalledfromTaskEscalationColumn: PropTypes.bool,
};

CreateTaskDetail.defaultProps = {
  priority: undefined,
  task: {},
  rules: null,
  isCalledfromTaskEscalationColumn: null,
};
