import React from 'react';
import styled from 'styled-components';

/* Removing for MVP0 */
// import { SparkLineChart } from '@ge/components/charts';
import { elevations } from '@ge/tokens/elevations';
/* Removing for MVP0 */
// import { regionFooterSparkOptions } from './chart-consts';

const StyledFooter = styled.div`
  height: 20px;
  display: flex;
  padding-left: 10px;
  z-index: ${elevations.P10};
  box-shadow: ${(props) => props.theme.analyze.regionCard.footerBoxShadow};
  border-top: solid 1px ${(props) => props.theme.analyze.regionCard.footerBorder};
  .month-controller {
    width: 40%;
    text-align: right;
    font-size: 11px;
    color: ${(props) => props.theme.analyze.regionCard.footerColor};
    margin-top: 6px;
  }
  .graph {
    width: 60%;
    svg tspan {
      visibility: hidden;
    }
  }
`;

export const RegionPerformanceFooter = () => {
  /* Removing for MVP0 */
  // const generateFooterChartData = () => {
  //   const count = dayjs().daysInMonth();
  //   const values = [...Array(count)].map(() => ({ value: 0 }));
  //   return values;
  // };

  return (
    <StyledFooter>
      {/* Removing for MVP0 */}
      {/* <div className="month-controller">{dayjs().format('MMMM')}</div>
      <div className="graph">
        <SparkLineChart
          data={generateFooterChartData()}
          height={20}
          options={regionFooterSparkOptions}
        />
      </div> */}
    </StyledFooter>
  );
};
