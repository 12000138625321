import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Input } from '@ge/components/input/input';
import { Radio } from '@ge/components/radio';
import { Select } from '@ge/components/select';
import { getDateObject, addUTCOffset } from '@ge/shared/util/time-date';
import { globalColors, typography } from '@ge/tokens';

const StyledInput = styled(Input)`
  width: 30px;
  height: 25px;
  border: 1px solid ${globalColors.grey9};
  background-color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.white}` : `${globalColors.slate1}`};
`;

const StyledRadio = styled(Radio)`
  margin: 0;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0 10px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)`
  margin: 18px 0 16px 0;
`;

const StyledForm = styled.form`
  .shift-range-label {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
    margin-left: 5px;
  }

  .shift-control-input {
    margin-left: 20px;

    .or-label {
      color: ${(props) =>
        props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.grey4}`};
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0;
      line-height: 25px;
      margin: 0 10px;
    }
  }

  .preview-btn-container {
    float: right;
    margin-top: 12.5px;
  }
`;

const RangeToggle = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;

  label {
    display: block;
    height: 13px;
    width: 70px;
    color: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.grey4}`};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
    margin-bottom: 5px;
  }
  button {
    padding: 3px 0px;
    margin-right: 8px;
    font-size: 13px;
    width: 18px;
    text-align: center;
    color: ${(props) => props.theme.manage.rangeToggle.textColor};
    background: ${(props) => props.theme.manage.rangeToggle.backgroundColor};
    border: solid 1px ${(props) => props.theme.manage.rangeToggle.borderColor};
    font-weight: ${typography.weight.medium};
    &:focus {
      outline: none;
    }
    &.active {
      background: ${(props) => props.theme.manage.rangeToggle.activeBackgroundColor};
      border-color: ${(props) => props.theme.manage.rangeToggle.activeBorderColor};
      font-weight: ${typography.weight.bold};
    }
  }
`;

const PreviewButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  color: ${globalColors.white};
  box-sizing: border-box;
  height: 25px;
  width: 80px;
  border: 1px solid ${(props) => props.theme.select.primaryBorder};
  border-radius: 4px;
  background: ${(props) => props.theme.select.primaryBackground2};

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.dialog.taskReschedule.spacerBorderColor};
  margin-top: 21.5px;
`;

const RangeValues = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const AnchorValues = {
  SCHEDULE: 'schedule',
  DUE: 'due',
};

const ShiftPositions = {
  BEFORE: 'before',
  AFTER: 'after',
};

export const ShiftTasksForm = ({ triggerValidation, tasks, setTaskValues }) => {
  const { t } = useTranslation(['manage.cases-tasks']);

  const [days, setDays] = useState([0, 1, 2, 3, 4, 5, 6]);

  const daysNotIncluded = useMemo(() => {
    let numbers = [0, 1, 2, 3, 4, 5, 6];
    let notIncluded = [];
    numbers.forEach((number) => {
      if (!days.includes(number)) {
        notIncluded.push(number);
      }
    });

    return notIncluded;
  }, [days]);

  const DAYS = [
    t('dayInitials.sunday', 'S'),
    t('dayInitials.monday', 'M'),
    t('dayInitials.tuesday', 'T'),
    t('dayInitials.wednesday', 'W'),
    t('dayInitials.thursday', 'T'),
    t('dayInitials.friday', 'F'),
    t('dayInitials.saturday', 'S'),
  ];

  const [ShiftAnchorOptions] = useState([
    { value: AnchorValues.SCHEDULE, label: t('scheduled_date', 'Schedule Date') },
    { value: AnchorValues.DUE, label: t('due_date', 'Due Date') },
  ]);

  const toggleDay = (e, arr, day) => {
    e.preventDefault();
    var idx = arr.indexOf(day);
    setDays(idx > -1 ? arr.filter((d) => d !== day) : [...arr, day]);
  };

  const { handleSubmit: handleSubmitShift, control: shiftControl } = useForm({
    mode: 'onBlur',
    defaultValues: {
      shiftCount: '',
      shiftRange: RangeValues.DAY,
      shiftPosition: ShiftPositions.BEFORE,
      shiftAnchor: ShiftAnchorOptions[0],
      includeWeekends: false,
    },
  });

  const getShiftDays = useCallback(
    (_date, numberOfDays, shiftPosition) => {
      let date = dayjs(_date);
      let count = 0;

      while (count < numberOfDays) {
        if (shiftPosition === ShiftPositions.BEFORE) {
          date = date.subtract(1, RangeValues.DAY);
        } else {
          date = date.add(1, RangeValues.DAY);
        }
        if (daysNotIncluded.includes(date.day())) {
          continue;
        }
        count++;
      }
      return date;
    },
    [daysNotIncluded],
  );

  const handleShiftAll = useCallback(
    (data) => {
      const { shiftCount, shiftPosition, shiftAnchor } = data;
      if (days.length) {
        for (const i in tasks) {
          const task = tasks[i];
          //check if schedule date is null or anchor is set to move by due date.

          if (shiftAnchor.value === AnchorValues.DUE) {
            if (!task.dueDate) continue;

            const date = getDateObject(dayjs(task.dueDate).tz(task.site?.timezone));

            let newDate = getShiftDays(date, shiftCount, shiftPosition);

            setTaskValues(
              `${task.id}.scheduleDate`,
              addUTCOffset(newDate, task.site?.timezone).toDate(),
            );
          } else if (shiftAnchor.value === AnchorValues.SCHEDULE) {
            if (!task.scheduleDate) continue;

            const date = getDateObject(dayjs(task.scheduleDate).tz(task.site?.timezone));

            let newDate = getShiftDays(date, shiftCount, shiftPosition);

            setTaskValues(
              `${task.id}.scheduleDate`,
              addUTCOffset(newDate, task.site?.timezone).toDate(),
            );
          }
        }
      }
      triggerValidation();
    },
    [days.length, triggerValidation, tasks, getShiftDays, setTaskValues],
  );

  return (
    <StyledForm>
      <div>
        <Flex>
          <Controller
            control={shiftControl}
            name="shiftCount"
            rules={{
              required: true,
              min: 1,
            }}
            render={({ onChange, value, ref }, { invalid }) => (
              <StyledInput
                value={value}
                type="number"
                onChange={onChange}
                min={1}
                name="shiftCount"
                error={invalid ? 'error' : null}
                ref={ref}
              />
            )}
          />
          <label className="shift-range-label">{t('days', 'Days')}</label>
          <Controller
            control={shiftControl}
            name="shiftPosition"
            render={({ onChange, value }) => (
              <RadioGroup className="shift-control-input">
                <StyledRadio
                  id="shiftPositionBefore"
                  name="shiftPosition"
                  label={t('before', 'Before')}
                  value={ShiftPositions.BEFORE}
                  checked={value === ShiftPositions.BEFORE}
                  onChange={onChange}
                />
                <span className="or-label">or</span>
                <StyledRadio
                  id="shiftPositionAfter"
                  name="shiftPosition"
                  label={t('after', 'After')}
                  value={ShiftPositions.AFTER}
                  checked={value === ShiftPositions.AFTER}
                  onChange={onChange}
                />
              </RadioGroup>
            )}
          />
        </Flex>

        <Controller
          control={shiftControl}
          name="shiftAnchor"
          render={({ onChange, value }) => (
            <StyledSelect
              maxWidth={150}
              onChange={onChange}
              options={ShiftAnchorOptions}
              value={value}
            />
          )}
        />
        <RangeToggle>
          <label>{t('include', 'Include')}*</label>
          {DAYS.map((day, idx) => (
            <button
              key={`${idx}-day`}
              onClick={(e) => toggleDay(e, days, idx)}
              className={days?.includes(idx) ? 'active' : null}
            >
              {day}
            </button>
          ))}
        </RangeToggle>
      </div>
      <Divider />
      <div className="preview-btn-container">
        <PreviewButton primary onClick={handleSubmitShift(handleShiftAll)}>
          {t('preview', 'Preview')}
        </PreviewButton>
      </div>
    </StyledForm>
  );
};

ShiftTasksForm.propTypes = {
  triggerValidation: PropTypes.func,
  tasks: PropTypes.instanceOf(Array).isRequired,
  setTaskValues: PropTypes.func,
};

ShiftTasksForm.defaultValues = {
  onShift: () => null,
  setTaskValues: () => null,
};
