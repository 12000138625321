import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';

import { PersonAssets } from './panels/person-assets';
import { PersonHistory } from './panels/person-history';
import { PersonOverview } from './panels/person-overview';
// import { PersonRolesPermissions } from './panels/person-roles-permissions';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

export const PersonDetailsWorker = ({ person, entityTab }) => {
  const { t } = useTranslation(['entity-details']);

  return (
    <StyledTabs small defaultTab={entityTab}>
      <Tab label={t('tabs.details', 'Details')}>
        <PersonOverview personDetail={person} />
      </Tab>
      {/* <Tab label={t('tabs.roles_permissions', 'Roles & Permissions')}>
        <PersonRolesPermissions personDetail={person} />
      </Tab> */}
      <Tab label={t('tabs.person_assets', 'Assets')}>
        <PersonAssets personDetail={person} />
      </Tab>
      <Tab label={t('tabs.person_history', 'History')}>
        <PersonHistory personDetail={person} />
      </Tab>
    </StyledTabs>
  );
};

PersonDetailsWorker.propTypes = {
  person: PropTypes.object,
  entityTab: PropTypes.string,
};

PersonDetailsWorker.defaultProps = {
  entityTab: null,
};
