import { AlertType } from '@ge/models/constants';

export const NoCommOutageTemplate = {
  _id: '459b33e8-0baa-11ec-9a03-0242ac130003',
  alertType: AlertType.NOCOMM_OUTAGE,
  entrypoint: 'string',
  sections: [
    {
      collapsed: true,
      hidden: false,
      metadata: {
        description: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'description',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        sendNotification: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'send_notification',
    },
  ],
  operations: ['create', 'edit'],
  tenantId: 'dfsa',
};

export const NoCommOutageMailTemplate = {
  bcc: 'NoCommOutageBCC@mock.com',
  cc: 'NoCommOutageCC@mock.com',
  body: '',
  header: 'This is to inform of a NoComm Outage for {{siteName}} – {{assetName}}',
  maxAttachments: 5,
  maxAttachmentSizeMb: 10,
  subject: 'NoComm Outage Notifications for {{siteName}} – {{assetName}}',
  to: '',
};
