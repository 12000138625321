import { useState } from 'react';
import { useQuery } from 'react-query';

import { QueryKey, EntityType } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { useGlobalFilters } from '../hooks';
import { fetchAssetConditionData, fetchSiteConditionAggregate } from '../services';

/**
 * To fetch condition data
 *
 * @param entityType - entity type
 * @param entityId the site/asset id
 * @param entityIds for fetching multiple assets data
 * @param startDate the start date of range
 * @param endDate the end date of range
 * @param conditionTypes the condition types to fetch data for (e.g. 'temperature', 'wind', etc.) - defaults to all
 */
const useConditionData = ({
  entityType,
  entityId,
  entityIds,
  startDate,
  endDate,
  defaultConditionType,
}) => {
  // only apply filters for entity types greater than asset
  // is there a nicer way of doing this?

  const [selectedConditionType, setSelectedConditionType] = useState(defaultConditionType);
  let filters = useGlobalFilters();
  filters =
    entityType === EntityType.ASSET ? { entityIds: entityId ? [entityId] : entityIds } : filters;

  filters =
    selectedConditionType && selectedConditionType.length
      ? { ...filters, conditionTypes: selectedConditionType }
      : filters;
  const queryKey = {
    endDate,
    entityId,
    entityType,
    filters,
    startDate,
  };

  const fetch = {
    [EntityType.SITE]: fetchSiteConditionAggregate,
    [EntityType.ASSET]: fetchAssetConditionData,
  }[entityType];

  const { data, error, isLoading } = useQuery(
    [QueryKey.ASSET_CONDITION_DATA, queryKey],
    () => fetch(entityId, startDate, endDate, filters),
    {
      enabled: Boolean((entityId || entityIds?.length) && startDate && endDate),
      ...Config.EXECUTE_ONCE,
    },
  );

  return {
    data: entityId && !isLoading ? data?.data?.find((x) => x) : data?.data,
    error,
    isLoading,
    setSelectedConditionType,
  };
};

export default useConditionData;
