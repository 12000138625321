import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { fetchAssetComponentInfo } from '../services/asset';

import { Config } from './config';

/**
 * Get asset component hierarchy.
 *
 * @param assetIds
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useComponentInfo = (componentId) => {
  // TODO - once added to base asset
  // const hierarchyId = assets[assetId]?.components?.hierarchyId;

  const { data: _queryData, isLoading, ...queryRest } = useQuery(
    [QueryKey.COMPONENT_INFO_DATA, componentId],
    async () => {
      return await fetchAssetComponentInfo(componentId);
    },
    {
      enabled: Boolean(componentId),
      ...Config.EXECUTE_ONCE,
    },
  );
  return { data: _queryData, isLoading, queryRest };
};
