import { PropTypes } from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';
import { DateTimeFormats } from '@ge/models/constants';

const placeholderText = '--';

const Container = styled.div`
  align-items: stretch;
  background: ${({ theme }) => theme.createReport.template.headerBackgroundColor};
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  min-height: 120px;
  padding: 16px;
  width: 100%;
`;

const Header = styled.div`
  align-items: stretch;
  align-self: stretch;
  display: flex;
  flex: 1 0;
  flex-flow: row-nowrap;
  justify-content: space-between;
  width: 100%;

  > * {
    flex: 1 0 33.333%;

    + * {
      border-left: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.createReport.widget.headerValueColor};
  min-height: 26px;
  margin-bottom: 8px;
`;

const TemplateInfo = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
`;

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.createReport.widget.headerLabelColor};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;
const InfoValue = styled.span`
  color: ${({ theme }) => theme.createReport.widget.headerValueColor};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
`;

const DescriptionLabel = styled.div`
  color: ${({ theme }) => theme.createReport.widget.headerLabelColor};
  font-size: 12px;
  line-height: 14px;
  padding-top: 34px;

  span {
    font-size: 9px;
    font-style: italic;
    padding-left: 6px;
  }

  .k-pdf-export & span {
    display: none;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.createReport.widget.headerValueColor};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  overflow-wrap: anywhere;
`;

const Attachments = styled.div`
  color: ${({ theme }) => theme.createReport.widget.headerValueColor};
  font-size: 14px;
  font-weight: 500;
  padding-top: 34px;
`;

const AttachmentsIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.createReport.widget.headerIconColor,
  icon: Icons.ATTACHMENT_ICON,
  size: 16,
}))`
  margin-top: 4px;
`;

export const HeaderWidget = ({ name, isPlaceholder }) => {
  const {
    attachments,
    hiddenWidgets,
    reportState: { createdTime, description, scopeName, templateInfo },
  } = useContext(ReportsContext);

  const { t, ready } = useTranslation(['reporting.widgets']);

  const { endDate, startDate } = useReportScope();

  const attachmentsWidgetId = useMemo(
    () =>
      Object.values(templateInfo)
        .flatMap((t) => t)
        .find((t) => t.name === WidgetNames.ATTACHMENTS)?.id,
    [templateInfo],
  );

  const hideAttachments = useMemo(
    () => hiddenWidgets.has(attachmentsWidgetId),
    [hiddenWidgets, attachmentsWidgetId],
  );

  const translations = useMemo(() => {
    let attachmentsLabel = t('header.no_attachments', 'No attachments');

    if (attachments?.length === 1) {
      attachmentsLabel = t('header.attachment', '1 attachment');
    } else if (attachments?.length > 1) {
      attachmentsLabel = t('header.attachments', {
        count: attachments?.length,
        defaultValue: `${attachments?.length} attachments`,
      });
    }

    return {
      attachmentsLabel,
      created: t('header.created', 'Created:'),
      scope: t('header.scope', 'Scope:'),
      timeRange: t('header.timeRange', 'Time Range:'),
      description: t('header.description', 'Description'),
      characterLimit: t('header.characterLimit', '150 char limit'),
    };
  }, [attachments, t]);

  const content = useMemo(() => {
    return (
      <Header>
        <div>
          <Title>{name}</Title>
          <TemplateInfo>
            <InfoLabel>{translations.created}</InfoLabel>
            <InfoValue>
              {createdTime?.format(DateTimeFormats.DEFAULT_DATE) || placeholderText}
            </InfoValue>
            <InfoLabel>{translations.scope}</InfoLabel>
            <InfoValue>{scopeName || placeholderText}</InfoValue>
            <InfoLabel>{translations.timeRange}</InfoLabel>
            <InfoValue>{`${startDate} - ${endDate}`}</InfoValue>
          </TemplateInfo>
        </div>
        <div>
          <DescriptionLabel>
            {translations.description} <span>{translations.characterLimit}</span>
          </DescriptionLabel>
          <Description>{description}</Description>
        </div>
        {!hideAttachments && (
          <Attachments>
            <AttachmentsIcon />
            {translations.attachmentsLabel}
          </Attachments>
        )}
      </Header>
    );
  }, [
    name,
    createdTime,
    description,
    scopeName,
    translations,
    endDate,
    startDate,
    hideAttachments,
  ]);

  if (!ready) {
    return null;
  }

  return <Container>{!isPlaceholder && content}</Container>;
};

HeaderWidget.propTypes = {
  name: PropTypes.string,
  isPlaceholder: PropTypes.bool,
};

HeaderWidget.defaultProps = {
  name: '',
};
