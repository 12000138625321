const Charts = {
  VIBRATION: 'VIBRATION',
  MESH: 'MESH',
  SPECTRUM: 'SPECTRUM',
  MODE: 'MODE',
};

export const ChartDefs = {
  [Charts.VIBRATION]: {
    id: Charts.VIBRATION,
    type: 'SCATTER_PLOT',
  },
  [Charts.MESH]: {
    id: Charts.MESH,
    type: 'SCATTER_PLOT',
  },
  [Charts.SPECTRUM]: {
    id: Charts.SPECTRUM,
    type: 'SCATTER_PLOT',
  },
  [Charts.MODE]: {
    id: Charts.MODE,
    type: 'MODE',
  },
};
