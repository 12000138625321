import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader';
import {
  IconMirror,
  IconGrid,
  IconPareto,
  IconScatter,
  IconSpline,
  IconTable,
} from '@ge/components/svg-icons';
import { Text } from '@ge/components/typography';
import { DataLoaderType } from '@ge/models/constants';
import { typography } from '@ge/tokens/typography';

const fallbackDataType = {
  loadingIcon: IconScatter,
  noDataIcon: Icons.SCATTER,
};

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : 'auto')};
  height: ${({ height }) => height};
`;

const NoDataContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ margin }) => (margin ? margin : 'auto')};
  height: ${({ height }) => height};
`;

const NoDataTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 1,
}))`
  margin-bottom: 6px;
`;

const NoDataDescription = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  margin-bottom: 20px;
  color: ${(props) => props.theme.dataLoader.descriptionColor};
`;

const RetryButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  text-transform: uppercase;
  color: ${(props) => props.theme.dataLoader.retryColor};
`;

const NoDataIcon = styled(Icon).attrs(({ icon, iconColor, theme }) => ({
  size: 50,
  icon: icon ? icon : Icons.SCATTER,
  color: iconColor ? iconColor : theme.dataLoader.iconColor,
}))`
  margin-bottom: 18px;
`;

const DataTypes = {
  [DataLoaderType.SCATTER]: {
    id: DataLoaderType.SCATTER,
    loadingIcon: IconScatter,
    noDataIcon: Icons.SCATTER,
  },
  [DataLoaderType.SPLINE]: {
    id: DataLoaderType.SPLINE,
    loadingIcon: IconSpline,
    noDataIcon: Icons.SPLINE,
  },
  [DataLoaderType.SPLINE_GROUP]: {
    id: DataLoaderType.SPLINE,
    loadingIcon: IconSpline,
    noDataIcon: Icons.SPLINE,
  },
  [DataLoaderType.COLUMN]: {
    id: DataLoaderType.COLUMN,
    loadingIcon: IconMirror,
    noDataIcon: Icons.COLUMN,
  },
  [DataLoaderType.COLUMN_GROUP]: {
    id: DataLoaderType.COLUMN_GROUP,
    loadingIcon: IconMirror,
    noDataIcon: Icons.COLUMN,
  },
  [DataLoaderType.MIRROR_COLUMN]: {
    id: DataLoaderType.MIRROR_COLUMN,
    loadingIcon: IconMirror,
    noDataIcon: Icons.MIRROR_COLUMN,
  },
  [DataLoaderType.PARETO]: {
    id: DataLoaderType.PARETO,
    loadingIcon: IconPareto,
    noDataIcon: Icons.PARETO,
  },
  [DataLoaderType.SPINNER]: {
    id: DataLoaderType.SPINNER,
    // TODO: figure out what we want here,
    noDataIcon: Icons.WARNING,
  },
  // should we need to add 'TABLE' to the chart types list?
  [DataLoaderType.TABLE]: {
    id: DataLoaderType.TABLE,
    loadingIcon: IconTable,
    // TODO need to get table icon from FD
    noDataIcon: Icons.LIST,
  },
  [DataLoaderType.GRID]: {
    id: DataLoaderType.GRID,
    loadingIcon: IconGrid,
    noDataIcon: Icons.GRID,
  },
  [DataLoaderType.AREA]: {
    id: DataLoaderType.SPLINE,
    loadingIcon: IconSpline,
    noDataIcon: Icons.SPLINE,
  },
  [DataLoaderType.AREA_SPLINE]: {
    id: DataLoaderType.SPLINE,
    loadingIcon: IconSpline,
    noDataIcon: Icons.SPLINE,
  },
};

export const DataLoader = ({
  type,
  children,
  isLoading,
  noData,
  noDataTitle,
  noDataDescription,
  noDataIconColor,
  onRetry,
  renderCondition,
  margin,
  height,
}) => {
  const { t } = useTranslation(['general'], { useSuspense: false });
  const dataType = useMemo(
    () => (type && DataTypes[type] ? DataTypes[type] : fallbackDataType),
    [type],
  );

  if (isLoading) {
    if (dataType.id === DataLoaderType.SPINNER) {
      return <MiniLoader />;
    }

    return (
      <LoadingContainer margin={margin} height={height}>
        <dataType.loadingIcon />
      </LoadingContainer>
    );
  }

  if (noData) {
    return (
      <NoDataContainer margin={margin} height={height}>
        <NoDataIcon iconColor={noDataIconColor} icon={dataType.noDataIcon} />
        <NoDataTitle>{noDataTitle || t('no_data_title', 'No Data Available')}</NoDataTitle>
        {noDataDescription && <NoDataDescription>{noDataDescription}</NoDataDescription>}
        {onRetry && <RetryButton onClick={onRetry}>{t('try_again', 'try again')}</RetryButton>}
      </NoDataContainer>
    );
  }

  return renderCondition && children;
};

DataLoader.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(DataLoaderType)),
  delay: PropTypes.number,
  isLoading: PropTypes.bool,
  noData: PropTypes.bool,
  noDataTitle: PropTypes.string,
  noDataDescription: PropTypes.string,
  onRetry: PropTypes.func,
  noDataIconColor: PropTypes.string,
  margin: PropTypes.string,
  height: PropTypes.string,
  renderCondition: PropTypes.bool,
};

DataLoader.defaultProps = {
  type: DataLoaderType.SCATTER,
  delay: undefined,
  isLoading: false,
  noData: false,
  renderCondition: true,
  onRetry: null,
  noDataIconColor: null,
  margin: null,
};
