import dayjs from 'dayjs';
import path from 'ramda/src/path';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { AssetState, SortType, SortValueType } from '@ge/models/constants';

/**
 * Helper method to build the request params object for Axios request.
 *
 * @param {String} sort Metric to sort on
 * @param {String} direction Sort direction
 * @param {Boolean} metrics Include metrics in response
 */
export const buildSortOptions = (sort, direction, metrics = false) => {
  const options = {};

  if (sort && typeof sort === 'string') {
    options.params = {
      sort,
      direction,
      metrics: metrics || undefined,
    };
  }

  return options;
};

const TURBINE_STATUS_ORDER = {},
  CREATE_DATE = 'createDate';
TURBINE_STATUS_ORDER[AssetState.NET_COMM] = 0;
TURBINE_STATUS_ORDER[AssetState.NO_DATA] = 0;
TURBINE_STATUS_ORDER[AssetState.ONLINE] = 1;
TURBINE_STATUS_ORDER[AssetState.AVAILABLE] = 2;
TURBINE_STATUS_ORDER[AssetState.MAINTENANCE] = 3;
TURBINE_STATUS_ORDER[AssetState.REPAIR] = 4;
TURBINE_STATUS_ORDER[AssetState.STOPPED] = 5;
TURBINE_STATUS_ORDER[AssetState.IMPACTED] = 6;
TURBINE_STATUS_ORDER[AssetState.TRIPPED] = 7;

const key = (item, metricString, type) => {
  if (!metricString) {
    return null;
  }

  const metric = path(metricString.split('.'), item);
  //TODO: Should add types to constants..
  if (type === '' || type === 'string' || type === 'number' || type === SortValueType.NUMERIC) {
    return typeof metric === 'string' ? metric.toUpperCase() : metric;
  } else if (type === SortType.TURBINE_STATUS) {
    return metric && metric.value ? TURBINE_STATUS_ORDER[metric.value] : 0;
  } else if (type === SortValueType.ALPHANUMERIC) {
    return metric;
  }
};

export const sorter = (metric, direction, type = '', tabType = '') => (a, b) => {
  if (!Number.isInteger(direction)) {
    direction = direction === SortDirection.ASC ? 1 : -1;
  }
  const varA = key(a, metric, type);
  const varB = key(b, metric, type);

  let comparison = 0;
  if (metric === CREATE_DATE) {
    if (
      a.createDate &&
      b.createDate &&
      dayjs(
        dayjs(a.createDate)
          .tz(a.site && a.site.timezone)
          .format('DD MMM YY HH:mm'),
      ).diff(
        dayjs(
          dayjs(b.createDate)
            .tz(b.site && b.site.timezone)
            .format('DD MMM YY HH:mm'),
        ),
      ) > 0
    ) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  } else if (type === SortValueType.ALPHANUMERIC) {
    comparison = (varA + '').localeCompare(varB, undefined, { numeric: true });
    if (tabType) {
      if (!!varA && typeof varB === 'undefined') {
        comparison = 1;
      } else if (!!varB && typeof varA === 'undefined') {
        comparison = -1;
      }
    }
  } else if (!!varA && typeof varB === 'undefined') {
    comparison = 1;
  } else if (!!varB && typeof varA === 'undefined') {
    comparison = -1;
  } else if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }

  return direction * comparison;
};

export const sortItemsByDate = (metric, dir) => (a, b) => {
  let comparison = 0;
  const date1 = new Date(a[metric]);
  const date2 = new Date(b[metric]);
  comparison = dir === SortDirection.ASC ? date1 - date2 : date2 - date1;

  return comparison;
};
export const sortItemsByNumber = (metric, dir) => (a, b) => {
  const item1 = path(metric.split('.'), a);
  const item2 = path(metric.split('.'), b);
  let comparison = 0;
  comparison = dir === SortDirection.ASC ? item1 - item2 : item2 - item1;

  return comparison;
};

export const sortItemsByText = (metric, direction, collator) => (a, b) => {
  if (!Number.isInteger(direction)) {
    direction = direction === SortDirection.ASC ? 1 : -1;
  }
  const item1 = path(metric.split('.'), a) || '';
  const item2 = path(metric.split('.'), b) || '';
  let comparison = 0;
  comparison = collator.compare(item1, item2);
  return direction * comparison;
};
