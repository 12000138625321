import dayjs from 'dayjs';
import { useStoreActions } from 'easy-peasy';
import { useState, useMemo, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { FormMode, QueryKey, EntityType, NotesCategory } from '@ge/models/constants';
import { addNoteAndSI, editNoteAndSI } from '@ge/shared/services/notes';
import { isBeforeToday } from '@ge/shared/util/time-date';

import { CasesQueryKeys } from '../data-hooks/cases/query-keys';

const updateNotesInCaseListCache = (queryClient, response) => {
  queryClient.setQueriesData(CasesQueryKeys.lists(), (prev) => {
    const updatedCases = prev?.data.map((_case) => {
      if (_case.id === response.domainId) {
        const caseNotesCount = _case.notes ? _case.notes?.caseNotesCount + 1 : 1;
        const taskNotesCount = _case.notes ? _case.notes?.taskNotesCount : 0;
        const totalCount = caseNotesCount + taskNotesCount;
        const notes = {
          caseNotesCount,
          taskNotesCount,
          totalCount,
        };

        return { ..._case, notes };
      }
      return _case;
    });
    return { ...prev, data: updatedCases };
  });
};

export const useNoteDialog = ({ mode, entityType, onComplete }) => {
  const { fetchCaseWithDetails } = useStoreActions((actions) => actions.cases);
  const { fetchTasksByTaskIds } = useStoreActions((actions) => actions.tasks);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutate: add,
    data: addQueryData,
    error: errorAdding,
  } = useMutation(addNoteAndSI, {
    onSettled: (response, error, variables) => {
      if (response) {
        if (entityType === EntityType.CASE) {
          fetchCaseWithDetails(response.domainId);
          queryClient.refetchQueries([QueryKey.CASE_NOTES]);
          updateNotesInCaseListCache(queryClient, response);
        } else if (entityType === EntityType.TASK) {
          fetchCaseWithDetails(variables.entity.caseId);
          fetchTasksByTaskIds([response.domainId]);
          queryClient.refetchQueries([QueryKey.TASK_NOTES]);
        }
      }
      setIsLoading(false);
      onComplete(response, error);
    },
  });

  const {
    mutate: edit,
    data: editQueryData,
    error: errorEditing,
  } = useMutation(editNoteAndSI, {
    onSettled: (response, error, variables) => {
      if (response?.updated) {
        const { original, updated } = response ?? {};
        const queryKey = [
          entityType === EntityType.CASE ? QueryKey.CASE_NOTES : QueryKey.TASK_NOTES,
          entityType === EntityType.CASE ? updated.domainId : variables.entity.caseId,
        ];
        queryClient.setQueryData(queryKey, (data) =>
          data?.notes
            ? {
                ...data,
                notes:
                  data.notes.map((note) =>
                    note.id !== updated.id ? note : { ...note, ...updated },
                  ) ?? [],
              }
            : data,
        );
        if (entityType === EntityType.CASE) {
          fetchCaseWithDetails(updated.domainId);
        }
        response = { ...original, ...updated };
      }
      setIsLoading(false);
      onComplete(response, error);
    },
  });

  const fetch = useCallback(
    (values, originalData, entity) => {
      setIsLoading(true);
      if (mode === FormMode.EDIT) {
        const inputData = {
          ...values,
          entityType,
          noteId: originalData.id,
        };
        if (
          inputData.category === NotesCategory.SPECIAL_INSTRUCTION &&
          inputData.validDateBy &&
          isBeforeToday(inputData.validDateBy)
        ) {
          delete inputData.validDateBy;
        }
        edit({ inputData, originalData, entity });
      } else {
        add({
          ...values,
          entityType,
          createdDate: dayjs(new Date()),
        });
      }
    },
    [mode, entityType, add, edit],
  );

  const { data, error } = useMemo(() => {
    if (mode === FormMode.EDIT) {
      return {
        data: editQueryData,
        error: errorEditing?.message ?? null,
      };
    }
    return {
      data: addQueryData,
      error: errorAdding?.message ?? null,
    };
  }, [mode, addQueryData, editQueryData, errorAdding, errorEditing]);

  return {
    isLoading,
    data,
    error,
    fetch,
  };
};
