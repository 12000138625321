/**
 * Converts a boolean to 1 for true or 0 for false.
 * Prevents string coersion for the boolean when passing as a prop to a styled component.
 *
 * https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
 * https://github.com/styled-components/styled-components/issues/1198
 *
 * @param {boolean} bool A boolean returned 1 for true or 0 for false.
 */
const boolToInt = (bool) => (bool ? 1 : 0);

module.exports = {
  boolToInt,
};
