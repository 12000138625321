import { ChartType } from './chart-type';

export const heatMapChartOptions = {
  chart: {
    type: ChartType.HEAT_MAP,
    plotBorderWidth: 1,
  },

  xAxis: {
    categories: [
      'Inverter_1',
      'Inverter_2',
      'Inverter_3',
      'Inverter_4',
      'Inverter_5',
      'Inverter_6',
      'Inverter_7',
      'Inverter_8',
      'Inverter_9',
      'Inverter_10',
    ],
  },

  legend: {
    align: 'left',
    margin: 0,
    verticalAlign: 'top',
    symbolWidth: 280,
  },
};
