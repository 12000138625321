import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Radio } from '@ge/components/radio';
import { ScrollingContainer } from '@ge/components/scrolling-container';

// went back and forth on managing checked state internally in here, but ended up
// deferring to parent in case there's some external validation that needs to happen
// and also parent can pass in a default or programmatic checked state
// can always change this behavior if it makes more sense

const getKey = ({ id, label, value } = {}) => id ?? value ?? label;

const Container = styled.div`
  align-items: stretch;
  background: ${({ theme }) => theme.radioPanel.background};
  display: flex;
  height: 200px;
  justify-content: stretch;
  width: 100%;

  .radio {
    box-sizing: border-box;
    padding: 8px;

    > * {
      margin: 0;

      input[type='radio'] {
        &:disabled {
          + label {
            color: ${({ theme }) => theme.radioPanel.disabledTextColor};
            cursor: default;
            font-style: italic;

            &:before {
              border-color: ${({ theme }) => theme.radioPanel.disabledTextColor};
            }
          }
        }
      }

      label {
        white-space: pre;
      }
    }

    &.checked {
      background: ${({ theme }) => theme.radioPanel.checkedBackgroundColor};
      border-bottom: 1px solid ${({ theme }) => theme.radioPanel.checkedBorderColor};

      label {
        font-weight: 700;
      }
    }
  }
`;

const RadioCollapsiblePanel = styled(CollapsiblePanel)`
  .title {
    border-bottom: none;

    svg {
      fill: ${({ theme }) => theme.radioPanel.caretColor};
    }

    .collapsible-header {
      font-size: 12px;
      padding: 5px;
    }
  }

  .collapsible-content {
    > .radio {
      padding-left: 20px;
    }
  }
`;

const RadioItem = ({ checked, childItems, disabled, expanded, id, label, onChange, value }) => {
  if (childItems?.length) {
    // can pass expanded into child, but not expecting to nest more than one deep
    return (
      <RadioCollapsiblePanel
        expanded={expanded}
        headerContent={<h3 className="collapsible-header">{label}</h3>}
      >
        <div className="collapsible-content">
          {childItems?.map((item) => (
            <RadioItem key={getKey(item)} onChange={onChange} {...item} />
          ))}
        </div>
      </RadioCollapsiblePanel>
    );
  }

  return (
    <div className={`radio ${checked ? 'checked' : ''}`}>
      <Radio
        checked={checked}
        disabled={disabled}
        id={id ?? value}
        label={label}
        onChange={({ target }) => onChange(target.value)}
        value={value}
      />
    </div>
  );
};

RadioItem.propTypes = {
  checked: PropTypes.bool,
  childItems: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

RadioItem.defaultProps = {
  checked: false,
  childItems: undefined,
  disabled: false,
  expanded: false,
  id: undefined,
  onChange: () => {},
  value: undefined,
};

export const RadioPanel = ({ className, expanded, items, onChange }) => {
  if (!items?.length) {
    // is this what we want to default to here?
    return null;
  }

  return (
    <Container className={className}>
      <ScrollingContainer>
        {items?.map(({ checked, childItems, disabled, id, label, value }) => (
          <RadioItem
            checked={checked}
            childItems={childItems}
            disabled={disabled}
            expanded={expanded}
            id={id}
            key={getKey({ id, label, value })}
            label={label}
            onChange={onChange}
            value={value}
          />
        ))}
      </ScrollingContainer>
    </Container>
  );
};

RadioPanel.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  expanded: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

RadioPanel.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  expanded: false,
  items: undefined,
  onChange: () => {},
};
