import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo, useEffect, useState } from 'react';
import { useQuery, useQueries, useQueryClient } from 'react-query';

import { fetchAnomaliesPageParams } from '@ge/feat-monitor/services';
import { getRibbonState } from '@ge/feat-monitor/util/data-filter';
import { AnomaliesTableCaseStatus, QueryKey, CaseStatus } from '@ge/models/constants';
import { CasesQueryKeys } from '@ge/shared/data-hooks/cases/query-keys';
import { Config } from '@ge/shared/data-hooks/config';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';

import { fetchMonitorCaseTable } from '../services';
import { caseTableRowTransformer } from '../util';

import useSortedCaseTable from './use-sorted-case-table';

// const REFETCH_INTERVAL = 5 * 60 * 1000; // 5 minutes;
//30 seconds
const REFETCH_INTERVAL = 30 * 1000; // 5 minutes;

export const useAnomaliesPageParams = () => {
  const viewSites = useStoreState((state) => state.view.currentView.sites);
  const { resetCases, setLastUpdated } = useStoreActions((actions) => actions.cases);
  const queryClient = useQueryClient();

  const params = useMemo(
    () => ({
      status: AnomaliesTableCaseStatus.join(','),
      siteIds: viewSites.map(({ id }) => id),
    }),
    [viewSites],
  );

  return useQuery(
    [QueryKey.ANOMALIES_TABLE_PAGE_PARAMS, params],
    () => fetchAnomaliesPageParams(params),
    {
      ...Config.EXECUTE_ONCE,
      enabled: params.siteIds?.length > 0,
      placeholderData: [],
      onSuccess: () => {
        queryClient.removeQueries(CasesQueryKeys.list());
        resetCases();
        setLastUpdated();
      },
    },
  );
};

export const useAnomaliesTableRefresh = (isActive) => {
  const [updatedCases, setUpdatedCases] = useState([]);
  const cases = useStoreState((state) => state.cases);
  const setLastUpdated = useStoreActions((state) => state.cases.setLastUpdated);
  const viewSites = useStoreState((state) => state.view.currentView.sites);

  let isSiteChanged = false;
  const siteIds = useMemo(() => viewSites.map((site) => site?.id), [viewSites]).join(',');
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isSiteChanged = true;
  }, [siteIds]);

  const queryClient = useQueryClient();

  const refetchParams = {
    siteIds: viewSites.map(({ id }) => id) ?? [],
    status: [...AnomaliesTableCaseStatus, CaseStatus.CLOSED].join(','),
    //only add lastUpdatedStart if lastUpdated is defined
  };
  const {
    isRefetching,
    data: refreshedData,
    ...rest
  } = useQuery({
    queryKey: CasesQueryKeys.list(refetchParams),
    queryFn: () =>
      fetchMonitorCaseTable({
        ...refetchParams,
        ...(!isSiteChanged && { lastUpdatedStart: Math.floor(cases.lastUpdated / 1000) }),
      }),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    enabled: isActive,
    onSuccess: (result) => {
      if (result?.data?.length) {
        result.data.forEach((_case) =>
          queryClient.invalidateQueries([QueryKey.CASE_DETAIL, _case.id]),
        );
      }
      isSiteChanged = false;
      setLastUpdated();
    },
  });

  useEffect(() => {
    if (!isRefetching && refreshedData?.data?.length) {
      setUpdatedCases((prev) =>
        refreshedData?.data.reduce(
          (acc, item) => {
            const index = prev.findIndex((v) => v.id === item.id);
            if (index !== -1) {
              acc[index] = item;
            } else {
              acc.push(item);
            }
            return acc;
          },
          [...prev],
        ),
      );
    }
  }, [isRefetching, refreshedData]);

  return { isRefetching, data: refreshedData, ...rest, updatedCases };
};

export const useAnomaliesTable = ({
  isActive,
  filters,
  search,
  sortDirection,
  sortMetric,
  filterSequence,
  isAuthorizedToViewTasks,
  type,
}) => {
  const [cases, setCases] = useState([]);
  const [active, setActive] = useState(isActive);

  const getChildCaseIds = useStoreState((state) => state.cases.getChildCaseIds);
  const childCaseIds = getChildCaseIds();
  const { data: pages, isLoading: isPageParamsLoading } = useAnomaliesPageParams();
  const { isRefetching, updatedCases } = useAnomaliesTableRefresh(
    Boolean(isActive && cases?.length > 0),
  );

  const queryClient = useQueryClient();

  const queryDefinitions = useMemo(() => {
    if (isPageParamsLoading || !Array.isArray(pages)) return [];
    return pages?.map((pageParam) => ({
      queryKey: CasesQueryKeys.list(pageParam),
      queryFn: () => fetchMonitorCaseTable(pageParam),
      ...Config.EXECUTE_ONCE,
      enabled: active && pageParam.siteIds.length > 0,
      onSuccess: (result) => {
        if (result?.data?.length) {
          result.data.forEach((_case) =>
            queryClient.invalidateQueries([QueryKey.CASE_DETAIL, _case.id]),
          );
        }
      },
    }));
  }, [active, isPageParamsLoading, pages, queryClient]);

  const caseTableQueries = useQueries(queryDefinitions);

  const isCasesLoading = caseTableQueries.some(({ isLoading }) => isLoading);
  const isFetching = caseTableQueries.some(({ isFetching }) => isFetching);
  const isStale = caseTableQueries.some(({ isStale }) => isStale);
  const dataUpdatedAtSum = caseTableQueries.reduce((acc, cur) => {
    if (cur?.dataUpdatedAt) return acc + cur?.dataUpdatedAt;

    return acc;
  }, 0);

  const sites = useStoreState((state) => state.sites.sites);
  const assets = useStoreState((state) => state.assets.assets);
  const itemMap = (item) => {
    const c = item;
    let data = [];
    if (AnomaliesTableCaseStatus.includes(String(c.status).toUpperCase()) && !c?.parentId) {
      const asset = assets[c.asset?.id] ?? c.asset;
      const site = sites[c.site?.id] ?? c.site;
      const tasks = c.tasks;
      data.push(
        caseTableRowTransformer({
          ...c,
          asset,
          site,
          tasks,
        }),
      );
    }
    return data[0];
  };

  useEffect(() => {
    if (isCasesLoading || isFetching || isRefetching) return;
    const cases = caseTableQueries?.reduce((previousValue, currentValue) => {
      if (currentValue?.data?.data && Array.isArray(currentValue.data.data)) {
        return [...previousValue, ...currentValue.data.data];
      }
      return [...previousValue];
    }, []);

    const caseMap = new Map(
      cases.map((item) => {
        return [item.id, itemMap(item)];
      }),
    );
    if (updatedCases.length > 0)
      updatedCases.forEach(
        (item) => !childCaseIds.includes(item?.id) && caseMap.set(item.id, itemMap(item)),
      );
    if (caseMap.size) {
      //convert map back to array
      setCases([...caseMap.values()]);
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStale, isCasesLoading, isFetching, isRefetching, dataUpdatedAtSum, updatedCases]);

  const isAssetsLoading = useStoreState(({ assets }) => assets.isLoading);

  const isLoading = isPageParamsLoading || isCasesLoading || isAssetsLoading;

  const sortedCases = useSortedCaseTable({ cases, sortDirection, sortMetric });

  const ribbonFilter = useStoreState(({ monitor }) => monitor.ribbonFilter);

  const getFilteredData = useMemo(
    () =>
      getRibbonState({
        isLoading,
        type,
        selected: ribbonFilter[type],
        data: sortedCases,
        isAuthorizedToViewTasks,
      }),
    [isLoading, type, sortedCases, ribbonFilter, isAuthorizedToViewTasks],
  );

  const { data = [], filterValues = [] } = useTableFilter({
    data: sortedCases,
    cases: getFilteredData?.data,
    filters,
    search,
    filterSequence,
  });

  return {
    data,
    filterValues,
    isLoading,
    isRefreshing: isRefetching,
  };
};
