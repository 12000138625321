import { EntityType, QueryKey } from '@ge/models/constants';

import useIecData from './use-iec-data';

/**
 * Use region IEC data.
 *
 * @param regionId the region id
 * @param category the iec category
 * @param startDate the start date
 * @param endDate the end date
 */
const useRegionIecData = ({ regionId, ...rest }) =>
  useIecData({
    entityId: regionId,
    assetQueryKey: QueryKey.REGION_IEC_ASSETS,
    entityType: EntityType.REGION,
    ...rest,
  });

export default useRegionIecData;
