import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Badge } from '@ge/components/badge';
import { Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { EntityType } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';
import EntityDetailHeader, {
  TypeIcon,
} from '@ge/web-client/src/app/components/entity-details/entity-details-header';

export const CaseTemplatePanelHeader = withRouter(({ caseTemplateDetail }) => {
  const { t, ready } = useTranslation(['analyze.troubleshooting'], {
    useSuspense: false,
  });

  if (!ready || !caseTemplateDetail) {
    return null;
  }

  return (
    <EntityDetailHeader type={EntityType.CASE}>
      <div className="left">
        <div className="details">
          <TypeIcon icon={Icons['ANOMALY']} />
          <div className="right">
            <div className="code">
              <span>{t('ID', 'ID')}</span>
              <span>#{caseTemplateDetail.caseTemplateId}</span>
              <span>
                {caseTemplateDetail.isBeta && (
                  <Badge color={globalColors.blue3} label={`BETA`} small />
                )}
              </span>
            </div>
            <div className="title">
              <Severity size={12} level={String(caseTemplateDetail.priority).toLowerCase()} />
              <span>
                {caseTemplateDetail.title.length > 40
                  ? caseTemplateDetail.title.substring(0, 40) + '...'
                  : caseTemplateDetail.title}
              </span>
            </div>
          </div>
        </div>
      </div>
    </EntityDetailHeader>
  );
});

CaseTemplatePanelHeader.propTypes = {
  caseTemplateDetail: PropTypes.instanceOf(Object).isRequired,
};

CaseTemplatePanelHeader.defaultProps = {
  caseTemplateDetail: {},
};
