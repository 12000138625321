import { Capability, PermissionScope, TableFilterTypes } from '@ge/models/constants';

export const SitesColumns = {
  GROUP_DATA: 'group-data',
  DATA: 'data',

  GROUP_REGION_SITE: 'group-region-site',
  GROUP: 'group',
  REGION: 'region',
  SITE: 'site',

  // inverters
  GROUP_INVERTERS: 'group-inverters',
  GROUP_PERCENT_AVAILABLE: 'group-percent-available',
  PERCENT_AVAILABLE: 'percent-available',
  TOTAL: 'total',
  ASSET_COUNT_BAR: 'asset-count-bar',
  ON: 'on',
  AVAILABLE: 'available',
  IMPACTED: 'impacted',
  TRIPPED: 'tripped',
  STOPPED: 'stopped',
  NO_COMM: 'no-comm',
  NO_COMM_ASSETS: 'no-comm-assets',
  MAINTENANCE_L_PADDING: 'maintenance-l-padding',
  MAINTENANCE_R_PADDING: 'maintenance-r-padding',
  MAINTENANCE_COUNT_BAR: 'maintenance-count-bar',
  MAINTENANCE: 'maintenance',
  REPAIR: 'repair',

  // Active Power
  GROUP_ACTIVE_POWER: 'group-active-power',
  POWER_ACTUAL: 'capacity-power-actual',
  POWER_RATED: 'capacity-power-rated',
  SET_POINT_AVAILABILITY: 'set-point-availability',

  GROUP_ALERTS: 'group-alerts',
  ALERTS: 'alerts',

  GROUP_SI: 'group-si',
  SI: 'specialInstructions',

  // Conditions
  GROUP_CONDITIONS: 'group-conditions',
  WIND: 'wind',
  TEMP: 'temp',

  GROUP_MVAR: 'group-mvar',
  MVAR: 'mvar',

  GROUP_OTHER: 'group-other',
  SET_POINT: 'set-point',
  AVAILABILITY: 'availability',

  GROUP_REVENUE: 'group-revenue',
  LOST_REVENUE: 'lost-revenue',
  PRICE: 'price',

  // Assets
  GROUP_ASSETS: 'group-assets',
  GROUP_ASSET_MAKE: 'group-asset-make',
  ASSET_MAKE: 'site-make',
  GROUP_ASSET_MODEL: 'group-asset-model',
  ASSET_MODEL: 'site-model',
  GROUP_STORAGE_ASSETS: 'group-storage-assets',

  GROUP_CUSTOMER: 'group-customer',
  CUSTOMER: 'site-customer',

  GROUP_SOC: 'group-soc',
  SOC: 'soc',

  GROUP_COUNTRY: 'group-country',
  COUNTRY: 'site-country',
  SITE_DETAIL: 'site-detail',

  GROUP_ACTIVE: 'group-active',
  TICKETS: 'tickets',
  ANOMALIES: 'anomalies',
  WORK_ORDERS: 'work-orders',

  GROUP_TASKS: 'group-tasks',
  TASKS: 'tasks',
  NEW_TASK: 'new-task',
};

// Define all available columns in the wind sites table.
export const SitesColumnDefs = {
  [SitesColumns.GROUP_DATA]: {
    cols: {
      [SitesColumns.DATA]: {
        labels: [
          {
            a11yKey: 'table.data',
            a11yDefault: 'Data',
            sortValue: 'site.connStatus',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_REGION_SITE]: {
    labels: [
      {
        a11yKey: 'table.sites',
        a11yDefault: 'SITES',
      },
    ],
    cols: {
      // Hiding for MVP1 - Story #1081
      // [SitesColumns.GROUP]: {
      //   labels: [
      //     {
      //       a11yKey: 'table.group',
      //       a11yDefault: 'Group',
      //       sortValue: 'site.group',
      //     },
      //   ],
      //   cell: {
      //     align: 'left',
      //   },
      // },
      // [SitesColumns.REGION]: {
      //   labels: [
      //     {
      //       a11yKey: 'table.region',
      //       a11yDefault: 'Region',
      //       sortValue: 'region.name',
      //     },
      //   ],
      //   cell: {
      //     align: 'left',
      //   },
      // },
      [SitesColumns.SITE]: {
        locked: true,
        labels: [
          {
            a11yKey: 'table.site',
            a11yDefault: 'Site',
            sortValue: 'site.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [SitesColumns.GROUP_PERCENT_AVAILABLE]: {
    cols: {
      [SitesColumns.PERCENT_AVAILABLE]: {
        labels: [
          {
            a11yKey: 'table.percent_available',
            a11yDefault: 'Avail.',
            sortValue: 'site.assets.availablePercent',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.assets',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [SitesColumns.TOTAL]: {
        labels: [
          {
            a11yKey: 'table.total',
            a11yDefault: 'Total',
            sortValue: 'site.assets.total',
          },
        ],
      },
      [SitesColumns.ON]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.on',
            a11yDefault: 'On',
            sortValue: 'site.assets.online',
          },
        ],
        cell: {
          border: false,
        },
        noRender: true,
      },
      [SitesColumns.AVAILABLE]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.a',
            a11yDefault: 'A',
            sortValue: 'site.assets.available',
          },
        ],
        noRender: true,
      },

      [SitesColumns.IMPACTED]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.i',
            a11yDefault: 'I',
            sortValue: 'site.assets.impacted',
          },
        ],
        noRender: true,
      },
      [SitesColumns.TRIPPED]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.t',
            a11yDefault: 'T',
            sortValue: 'site.assets.tripped',
          },
        ],
        noRender: true,
      },
      [SitesColumns.STOPPED]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.s',
            a11yDefault: 'S',
            sortValue: 'site.assets.stopped',
          },
        ],
        noRender: true,
      },
      [SitesColumns.NO_COMM]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.nc',
            a11yDefault: 'NC',
            sortValue: 'site.noCommOrData',
          },
        ],
        noRender: true,
      },
      [SitesColumns.MAINTENANCE]: {
        locked: true,
        width: 3,
        noPadding: true,
        noRender: true,
        labels: [
          {
            a11yKey: 'abbreviation.m',
            a11yDefault: 'M',
            sortValue: 'site.assets.maintenance',
          },
        ],
      },
      [SitesColumns.REPAIR]: {
        locked: true,
        width: 3,
        noPadding: true,
        labels: [
          {
            a11yKey: 'abbreviation.r',
            a11yDefault: 'R',
            sortValue: 'site.assets.repair',
          },
        ],
        noRender: true,
      },
    },
  },
  [SitesColumns.GROUP_ALERTS]: {
    cols: {
      [SitesColumns.ALERTS]: {
        capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
        labels: [
          {
            a11yKey: 'table.alerts',
            a11yDefault: 'Alerts',
            sortValue: 'site.conditions.alerts.length',
          },
        ],
      },
    },
  },

  [SitesColumns.GROUP_SI]: {
    cols: {
      [SitesColumns.SI]: {
        capabilities: [
          { capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] },
        ],
        labels: [
          {
            a11yKey: 'table.specialInstructions',
            a11yDefault: 'SI',
            sortValue: 'site.conditions.specialInstructions.length',
          },
        ],
      },
    },
  },
  // [SitesColumns.GROUP_TASKS]: {
  //   labels: [
  //     {
  //       a11yKey: 'table.tasks',
  //       a11yDefault: 'TASKS',
  //     },
  //   ],
  //   cols: {
  //     [SitesColumns.TASKS]: {
  //       minWidth: '52px',
  //       labels: [
  //         {
  //           a11yKey: 'table.tasks',
  //           a11yDefault: 'Tasks',
  //           sortValue: 'tasks',
  //         },
  //       ],
  //     },
  //     [SitesColumns.NEW_TASK]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.new_task',
  //           a11yDefault: 'New',
  //         },
  //       ],
  //     },
  //   },
  // },
  [SitesColumns.GROUP_ACTIVE_POWER]: {
    labels: [
      {
        a11yKey: 'table.active_power',
        a11yDefault: 'Active Power (MW)',
      },
    ],
    cols: {
      [SitesColumns.POWER_ACTUAL]: {
        align: 'right',
        labels: [
          {
            a11yKey: 'table.capacity_power_actual',
            a11yDefault: 'Actual',
            sortValue: 'site.production.actualPower',
          },
        ],
        cell: {
          border: false,
        },
      },
      // [SitesColumns.POWER_RATED]: {
      //   align: 'right',
      //   labels: [
      //     {
      //       a11yKey: 'table.capability_power_rated',
      //       a11yDefault: 'Rated',
      //       sortValue: 'production.possibleParkPower',
      //     },
      //   ],
      //   cell: {
      //     border: false,
      //   },
      // },
      // [SitesColumns.SET_POINT_AVAILABILITY]: {
      //   align: 'left',
      //   labels: [
      //     {
      //       a11yKey: 'table.set_point',
      //       a11yDefault: 'Set Point',
      //     },
      //     {
      //       a11yKey: 'table.available',
      //       a11yDefault: 'Available',
      //     },
      //   ],
      //   cell: {
      //     border: true,
      //   },
      // },
    },
  },
  [SitesColumns.GROUP_CONDITIONS]: {
    labels: [
      {
        a11yKey: 'table.conditions',
        a11yDefault: 'CONDITIONS',
      },
    ],
    cols: {
      [SitesColumns.WIND]: {
        labels: [
          {
            a11yKey: 'table.wind',
            a11yDefault: 'Wind (m/s)',
            sortValue: 'site.conditions.windMS',
          },
        ],
      },
      [SitesColumns.TEMP]: {
        labels: [
          {
            a11yKey: 'table.temp',
            a11yDefault: 'Temp °C',
            sortValue: 'site.conditions.tempC',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_MVAR]: {
    cols: {
      [SitesColumns.MVAR]: {
        labels: [
          {
            a11yKey: 'table.mvar',
            a11yDefault: 'MVAr',
            sortValue: 'site.production.reactivePower',
          },
        ],
      },
    },
  },
  // [SitesColumns.GROUP_OTHER]: {
  //   locked: true,
  //   cols: {
  //     [SitesColumns.SET_POINT]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.active_power_set_point',
  //           a11yDefault: 'Active Power\nSet Point',
  //           sortValue: 'production.setPoint',
  //         },
  //       ],
  //     },
  //     [SitesColumns.AVAILABILITY]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.real_time_availability',
  //           a11yDefault: 'Weekly\nAvailability',
  //           sortValue: 'production.realTimeAvailability',
  //         },
  //       ],
  //     },
  //   },
  // },
  // [SitesColumns.GROUP_REVENUE]: {
  //   cols: {
  //     [SitesColumns.LOST_REVENUE]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.lost_revenue',
  //           a11yDefault: 'Lost Revenue\n$ / hr',
  //           sortValue: 'revenue.lostRevenue',
  //         },
  //       ],
  //       tooltip: {
  //         a11yKey: 'table.lost_revenue_desc',
  //         a11yDefault: 'Calculated based on power price and available power.',
  //       },
  //       cell: {
  //         align: 'right',
  //         border: false,
  //       },
  //     },
  //     [SitesColumns.PRICE]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.price_unit',
  //           a11yDefault: 'Price\nMWh / hr',
  //           sortValue: 'revenue.priceMW',
  //         },
  //       ],
  //       tooltip: {
  //         a11yKey: 'table.price_desc',
  //         a11yDefault: 'Market price per turbine provided by external input.',
  //       },
  //       cell: {
  //         align: 'right',
  //       },
  //     },
  //   },
  // },
  [SitesColumns.GROUP_CUSTOMER]: {
    cols: {
      [SitesColumns.CUSTOMER]: {
        labels: [
          {
            a11yKey: 'table.customer',
            a11yDefault: 'Customer',
            sortValue: 'site.customer.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [SitesColumns.GROUP_ASSET_MAKE]: {
    cols: {
      [SitesColumns.ASSET_MAKE]: {
        labels: [
          {
            a11yKey: 'table.site_make',
            a11yDefault: 'Asset Make',
            sortValue: 'site.make',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [SitesColumns.GROUP_ASSET_MODEL]: {
    cols: {
      [SitesColumns.ASSET_MODEL]: {
        labels: [
          {
            a11yKey: 'table.ASSET_MODEL',
            a11yDefault: 'Asset\nModel',
            sortValue: 'site.model',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [SitesColumns.GROUP_COUNTRY]: {
    cols: {
      [SitesColumns.COUNTRY]: {
        labels: [
          {
            a11yKey: 'table.country',
            a11yDefault: 'Country',
            sortValue: 'site.country',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
};

// Define all available columns in the storage sites table.
export const StorageSitesColumnDefs = {
  [SitesColumns.GROUP_REGION_SITE]: {
    labels: [
      {
        a11yKey: 'table.sites',
        a11yDefault: 'SITES',
      },
    ],
    cols: {
      // Hiding for MVP1 - Story #1081
      // [SitesColumns.GROUP]: {
      //   labels: [
      //     {
      //       a11yKey: 'table.group',
      //       a11yDefault: 'Group',
      //       sortValue: 'site.group',
      //     },
      //   ],
      //   cell: {
      //     align: 'left',
      //   },
      // },
      // [SitesColumns.REGION]: {
      //   labels: [
      //     {
      //       a11yKey: 'table.region',
      //       a11yDefault: 'Region',
      //       sortValue: 'region.name',
      //     },
      //   ],
      //   cell: {
      //     align: 'left',
      //   },
      // },
      [SitesColumns.DATA]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.data',
            a11yDefault: 'Data',
            sortValue: 'site.connStatus',
          },
        ],
        cell: {
          align: 'center',
        },
      },
      [SitesColumns.SITE]: {
        align: 'left',
        locked: true,
        labels: [
          {
            a11yKey: 'table.site',
            a11yDefault: 'Site',
            sortValue: 'site.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [SitesColumns.GROUP_INVERTERS]: {
    labels: [
      {
        a11yKey: 'table.inverters',
        a11yDefault: 'INVERTERS',
      },
    ],
    cols: {
      [SitesColumns.PERCENT_AVAILABLE]: {
        labels: [
          {
            a11yKey: 'table.percent_available1',
            a11yDefault: 'Avail %',
            sortValue: 'site.assets.availablePercent',
          },
        ],
      },
      [SitesColumns.TOTAL]: {
        labels: [
          {
            a11yKey: 'table.total',
            a11yDefault: 'Total',
            sortValue: 'site.assets.total',
          },
        ],
      },
      [SitesColumns.ON]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.on',
            a11yDefault: 'On',
            sortValue: 'site.assets.online',
          },
        ],
        cell: {
          border: false,
        },
        noRender: true,
      },
      [SitesColumns.AVAILABLE]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.a',
            a11yDefault: 'A',
            sortValue: 'site.assets.available',
          },
        ],
        noRender: true,
      },

      [SitesColumns.IMPACTED]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.i',
            a11yDefault: 'I',
            sortValue: 'site.assets.impacted',
          },
        ],
        noRender: true,
      },
      [SitesColumns.TRIPPED]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.t',
            a11yDefault: 'T',
            sortValue: 'site.assets.tripped',
          },
        ],
        noRender: true,
      },
      [SitesColumns.STOPPED]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.s',
            a11yDefault: 'S',
            sortValue: 'site.assets.stopped',
          },
        ],
        noRender: true,
      },
      [SitesColumns.NO_COMM]: {
        locked: true,
        noPadding: true,
        width: 2,
        labels: [
          {
            a11yKey: 'abbreviation.nc',
            a11yDefault: 'NC',
            sortValue: 'site.noCommOrData',
          },
        ],
        noRender: true,
      },
      [SitesColumns.MAINTENANCE]: {
        locked: true,
        width: 3,
        noPadding: true,
        noRender: true,
        labels: [
          {
            a11yKey: 'abbreviation.m',
            a11yDefault: 'M',
            sortValue: 'site.assets.maintenance',
          },
        ],
      },
      [SitesColumns.REPAIR]: {
        locked: true,
        width: 3,
        noPadding: true,
        labels: [
          {
            a11yKey: 'abbreviation.r',
            a11yDefault: 'R',
            sortValue: 'site.assets.repair',
          },
        ],
        noRender: true,
      },
    },
  },
  [SitesColumns.GROUP_ALERTS]: {
    cols: {
      [SitesColumns.ALERTS]: {
        capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
        labels: [
          {
            a11yKey: 'table.alerts',
            a11yDefault: 'Alerts',
            sortValue: 'site.conditions.alerts.length',
          },
        ],
      },
    },
  },

  [SitesColumns.GROUP_SI]: {
    cols: {
      [SitesColumns.SI]: {
        capabilities: [
          { capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] },
        ],
        labels: [
          {
            a11yKey: 'table.specialInstructions',
            a11yDefault: 'SI',
            sortValue: 'site.conditions.specialInstructions.length',
          },
        ],
      },
    },
  },
  // [SitesColumns.GROUP_TASKS]: {
  //   labels: [
  //     {
  //       a11yKey: 'table.tasks',
  //       a11yDefault: 'TASKS',
  //     },
  //   ],
  //   cols: {
  //     [SitesColumns.TASKS]: {
  //       minWidth: '52px',
  //       labels: [
  //         {
  //           a11yKey: 'table.tasks',
  //           a11yDefault: 'Tasks',
  //           sortValue: 'tasks',
  //         },
  //       ],
  //     },
  //     [SitesColumns.NEW_TASK]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.new_task',
  //           a11yDefault: 'New',
  //         },
  //       ],
  //     },
  //   },
  // },
  [SitesColumns.GROUP_ACTIVE_POWER]: {
    labels: [
      {
        a11yKey: 'table.active_power',
        a11yDefault: 'Active Power (MW)',
      },
    ],
    cols: {
      [SitesColumns.POWER_ACTUAL]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.capacity_power_actual',
            a11yDefault: 'Active\nPower (MW)',
            sortValue: 'site.production.actualPower',
          },
        ],
        cell: {
          border: false,
          align: 'right',
        },
      },
      // [SitesColumns.POWER_RATED]: {
      //   align: 'right',
      //   labels: [
      //     {
      //       a11yKey: 'table.capability_power_rated',
      //       a11yDefault: 'Rated',
      //       sortValue: 'production.possibleParkPower',
      //     },
      //   ],
      //   cell: {
      //     border: false,
      //   },
      // },
      // [SitesColumns.SET_POINT_AVAILABILITY]: {
      //   align: 'left',
      //   labels: [
      //     {
      //       a11yKey: 'table.set_point',
      //       a11yDefault: 'Set Point',
      //     },
      //     {
      //       a11yKey: 'table.available',
      //       a11yDefault: 'Available',
      //     },
      //   ],
      //   cell: {
      //     border: true,
      //   },
      // },
    },
  },
  [SitesColumns.GROUP_MVAR]: {
    cols: {
      [SitesColumns.MVAR]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.mvar',
            a11yDefault: 'Reactive\nPower (MVar)',
            sortValue: 'site.production.reactivePower',
          },
        ],
        cell: {
          align: 'right',
        },
      },
    },
  },
  [SitesColumns.GROUP_SOC]: {
    cols: {
      [SitesColumns.SOC]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.soc',
            a11yDefault: 'State of Charge %',
            sortValue: 'site.production.soc',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
  // [SitesColumns.GROUP_OTHER]: {
  //   locked: true,
  //   cols: {
  //     [SitesColumns.SET_POINT]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.active_power_set_point',
  //           a11yDefault: 'Active Power\nSet Point',
  //           sortValue: 'production.setPoint',
  //         },
  //       ],
  //     },
  //     [SitesColumns.AVAILABILITY]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.real_time_availability',
  //           a11yDefault: 'Weekly\nAvailability',
  //           sortValue: 'production.realTimeAvailability',
  //         },
  //       ],
  //     },
  //   },
  // },
  // [SitesColumns.GROUP_REVENUE]: {
  //   cols: {
  //     [SitesColumns.LOST_REVENUE]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.lost_revenue',
  //           a11yDefault: 'Lost Revenue\n$ / hr',
  //           sortValue: 'revenue.lostRevenue',
  //         },
  //       ],
  //       tooltip: {
  //         a11yKey: 'table.lost_revenue_desc',
  //         a11yDefault: 'Calculated based on power price and available power.',
  //       },
  //       cell: {
  //         align: 'right',
  //         border: false,
  //       },
  //     },
  //     [SitesColumns.PRICE]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.price_unit',
  //           a11yDefault: 'Price\nMWh / hr',
  //           sortValue: 'revenue.priceMW',
  //         },
  //       ],
  //       tooltip: {
  //         a11yKey: 'table.price_desc',
  //         a11yDefault: 'Market price per turbine provided by external input.',
  //       },
  //       cell: {
  //         align: 'right',
  //       },
  //     },
  //   },
  // },
  [SitesColumns.GROUP_CUSTOMER]: {
    cols: {
      [SitesColumns.CUSTOMER]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.customer',
            a11yDefault: 'Customer',
            sortValue: 'site.customer.name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [SitesColumns.GROUP_STORAGE_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.asset',
        a11yDefault: 'ASSET',
      },
    ],
    cols: {
      [SitesColumns.ASSET_MAKE]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.storage_asset_make',
            a11yDefault: 'Asset\nMake',
            sortValue: 'site.make',
          },
        ],
        minWidth: '70px',
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
      [SitesColumns.ASSET_MODEL]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.storage_asset_model',
            a11yDefault: 'Asset\nModel',
            sortValue: 'site.model',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [SitesColumns.GROUP_COUNTRY]: {
    cols: {
      [SitesColumns.COUNTRY]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.country',
            a11yDefault: 'Country',
            sortValue: 'site.country',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
      [SitesColumns.SITE_DETAIL]: {
        fixedRight: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.site_details',
            a11yDefault: 'Site Details',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '43px',
        },
      },
    },
  },
};

// Define the default visible columns and their order.
export const defaultSiteCols = [
  {
    id: SitesColumns.GROUP_DATA,
    cols: [
      {
        id: SitesColumns.DATA,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_REGION_SITE,
    cols: [
      // Hiding for MVP1 - Story #1081
      // {
      //   id: SitesColumns.GROUP,
      //   visible: true,
      // },
      // {
      //   id: SitesColumns.REGION,
      //   visible: true,
      // },
      {
        id: SitesColumns.SITE,
        visible: true,
      },
    ],
  },
  // {
  //   id: SitesColumns.GROUP_TASKS,
  //   cols: [
  //     {
  //       id: SitesColumns.TASKS,
  //       visible: true,
  //     },
  //     {
  //       id: SitesColumns.NEW_TASK,
  //       visible: true,
  //     },
  //   ],
  // },
  {
    id: SitesColumns.GROUP_PERCENT_AVAILABLE,
    cols: [
      {
        id: SitesColumns.PERCENT_AVAILABLE,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ASSETS,
    cols: [
      {
        id: SitesColumns.TOTAL,
        visible: true,
      },
      {
        id: SitesColumns.ON,
        visible: true,
      },
      {
        id: SitesColumns.AVAILABLE,
        visible: true,
      },
      {
        id: SitesColumns.IMPACTED,
        visible: true,
      },
      {
        id: SitesColumns.TRIPPED,
        visible: true,
      },
      {
        id: SitesColumns.STOPPED,
        visible: true,
      },
      {
        id: SitesColumns.NO_COMM,
        visible: true,
      },
      {
        id: SitesColumns.MAINTENANCE_L_PADDING,
        visible: true,
        width: 0.5,
      },
      {
        id: SitesColumns.MAINTENANCE,
        visible: true,
      },
      {
        id: SitesColumns.REPAIR,
        visible: true,
      },
      {
        id: SitesColumns.MAINTENANCE_R_PADDING,
        visible: true,
        width: 1,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ALERTS,
    cols: [
      {
        id: SitesColumns.ALERTS,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_SI,
    cols: [
      {
        id: SitesColumns.SI,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ACTIVE_POWER,
    cols: [
      {
        id: SitesColumns.POWER_ACTUAL,
        visible: true,
      },
      // {
      //   id: SitesColumns.POWER_RATED,
      //   visible: true,
      // },
      // {
      //   id: SitesColumns.SET_POINT_AVAILABILITY,
      //   visible: true,
      // },
    ],
  },
  {
    id: SitesColumns.GROUP_CONDITIONS,
    cols: [
      {
        id: SitesColumns.WIND,
        visible: true,
        filter: 'wind',
      },
      {
        id: SitesColumns.TEMP,
        visible: true,
        filter: 'wind',
      },
    ],
  },
  {
    id: SitesColumns.GROUP_MVAR,
    cols: [
      {
        id: SitesColumns.MVAR,
        visible: true,
      },
    ],
  },
  // {
  //   id: SitesColumns.GROUP_OTHER,
  //   cols: [
  //     {
  //       id: SitesColumns.SET_POINT,
  //       visible: true,
  //     },
  //     {
  //       id: SitesColumns.AVAILABILITY,
  //       visible: true,
  //     },
  //   ],
  // },
  // {
  //   id: SitesColumns.GROUP_REVENUE,
  //   cols: [
  //     {
  //       id: SitesColumns.LOST_REVENUE,
  //       visible: true,
  //     },
  //     {
  //       id: SitesColumns.PRICE,
  //       visible: true,
  //     },
  //   ],
  // },
  {
    id: SitesColumns.GROUP_ASSET_MAKE,
    cols: [
      {
        id: SitesColumns.ASSET_MAKE,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ASSET_MODEL,
    cols: [
      {
        id: SitesColumns.ASSET_MODEL,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_CUSTOMER,
    cols: [
      {
        id: SitesColumns.CUSTOMER,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_COUNTRY,
    cols: [
      {
        id: SitesColumns.COUNTRY,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ACTIVE,
    cols: [
      {
        id: SitesColumns.TICKETS,
        visible: true,
      },
      {
        id: SitesColumns.ANOMALIES,
        visible: true,
      },
      {
        id: SitesColumns.WORK_ORDERS,
        visible: true,
      },
    ],
  },
];

// Define the default visible columns and their order.
export const defaultStorageSiteCols = [
  {
    id: SitesColumns.GROUP_REGION_SITE,
    cols: [
      {
        id: SitesColumns.DATA,
        visible: true,
      },
      {
        id: SitesColumns.SITE,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_INVERTERS,
    cols: [
      {
        id: SitesColumns.PERCENT_AVAILABLE,
        visible: true,
      },
      {
        id: SitesColumns.TOTAL,
        visible: true,
      },
      {
        id: SitesColumns.ON,
        visible: true,
      },
      {
        id: SitesColumns.AVAILABLE,
        visible: true,
      },
      {
        id: SitesColumns.IMPACTED,
        visible: true,
      },
      {
        id: SitesColumns.TRIPPED,
        visible: true,
      },
      {
        id: SitesColumns.STOPPED,
        visible: true,
      },
      {
        id: SitesColumns.NO_COMM,
        visible: true,
      },
      {
        id: SitesColumns.MAINTENANCE_L_PADDING,
        visible: true,
        width: 0.5,
      },
      {
        id: SitesColumns.MAINTENANCE,
        visible: true,
      },
      {
        id: SitesColumns.REPAIR,
        visible: true,
      },
      {
        id: SitesColumns.MAINTENANCE_R_PADDING,
        visible: true,
        width: 1,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ALERTS,
    cols: [
      {
        id: SitesColumns.ALERTS,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_SI,
    cols: [
      {
        id: SitesColumns.SI,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ACTIVE_POWER,
    cols: [
      {
        id: SitesColumns.POWER_ACTUAL,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_CONDITIONS,
    cols: [
      {
        id: SitesColumns.WIND,
        visible: true,
        filter: 'wind',
      },
      {
        id: SitesColumns.TEMP,
        visible: true,
        filter: 'wind',
      },
    ],
  },
  {
    id: SitesColumns.GROUP_MVAR,
    cols: [
      {
        id: SitesColumns.MVAR,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_SOC,
    cols: [
      {
        id: SitesColumns.SOC,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_CUSTOMER,
    cols: [
      {
        id: SitesColumns.CUSTOMER,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_STORAGE_ASSETS,
    cols: [
      {
        id: SitesColumns.ASSET_MAKE,
        visible: true,
      },
      {
        id: SitesColumns.ASSET_MODEL,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_COUNTRY,
    cols: [
      {
        id: SitesColumns.COUNTRY,
        visible: true,
      },
      {
        id: SitesColumns.SITE_DETAIL,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ACTIVE,
    cols: [
      {
        id: SitesColumns.TICKETS,
        visible: true,
      },
      {
        id: SitesColumns.ANOMALIES,
        visible: true,
      },
      {
        id: SitesColumns.WORK_ORDERS,
        visible: true,
      },
    ],
  },
];
