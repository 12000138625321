import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FileExtensionType, FileType } from '@ge/models/constants';

import { FileIcons, Icon } from '../icon';

export const IconMap = {
  [FileExtensionType[FileType.CSV]]: FileIcons.CSV,
  [FileExtensionType[FileType.MSG]]: FileIcons.MSG,
  [FileExtensionType[FileType.PDF]]: FileIcons.PDF,
  [FileExtensionType[FileType.PPT]]: FileIcons.PPT,
  [FileExtensionType[FileType.PPTX]]: FileIcons.PPT,
  [FileExtensionType[FileType.XLS]]: FileIcons.XLS,
  [FileExtensionType[FileType.XLSX]]: FileIcons.XLS,
  [FileExtensionType[FileType.XLSM]]: FileIcons.XLS,
  [FileExtensionType[FileType.DOC]]: FileIcons.DOC,
  [FileExtensionType[FileType.DOCX]]: FileIcons.DOC,
  [FileExtensionType[FileType.ZIP]]: FileIcons.ZIP,
};

const FileIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.fileUpload.uploadIconColor,
}))``;

const ConditionalDownload = ({ src, children, isDownloadable }) =>
  isDownloadable ? (
    <a target="_blank" rel="noopener noreferrer" download href={src} style={{ color: 'white' }}>
      {children}
    </a>
  ) : (
    children
  );

ConditionalDownload.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDownloadable: PropTypes.bool.isRequired,
};

export const FileThumbnail = ({ src, ext, fileName, download }) => {
  if (
    [
      FileExtensionType[FileType.JPEG],
      FileExtensionType[FileType.JPG],
      FileExtensionType[FileType.GIF],
      FileExtensionType[FileType.PNG],
    ].includes(ext)
  ) {
    return (
      <ConditionalDownload src={src} isDownloadable={download}>
        <img src={src} alt={fileName} download />
      </ConditionalDownload>
    );
  }
  const icon = IconMap[ext];
  if (!icon) {
    return null;
  }
  return (
    <ConditionalDownload src={src} isDownloadable={download}>
      <FileIcon icon={icon} size={100} viewbox="0 0 72 84" color="transparent" />
    </ConditionalDownload>
  );
};

FileThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  ext: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  download: PropTypes.bool,
};

FileThumbnail.defaultProps = {
  download: false,
};
