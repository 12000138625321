import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { DataLoader } from '@ge/components/data-loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import useStateRef from '@ge/hooks/state-ref';
import {
  AlertStatus,
  AlertType,
  DataLoaderType,
  FormMode,
  AlertsEntityType,
  EntityType,
  AlertStatusCodeMapping,
  AlertEmailType,
  Placeholders,
  DateTimeFormats,
} from '@ge/models/constants';
import { SelectMetaField } from '@ge/shared/components/meta-fields/select-meta-field';
import { SeeMore } from '@ge/shared/components/see-more';
import { useDistributionList, useAlertTemplate } from '@ge/shared/data-hooks';
import { getFriendlyTimestamp } from '@ge/shared/util';

import { DescriptionField, SendNotificationField } from './alert-fields';
import { AlertHeader } from './alert-header';
import { AlertViewField, FormContainer, FormCol, FormRow, FormSchema } from './alert-shared';
import { AlertBodyTemplate } from './templates/alert-body-template';

// TODO: add maxHeight prop to component and conditionally add scrolling container if provided

const FORM_MODES = [FormMode.CREATE, FormMode.EDIT, FormMode.VIEW];

const STATIC_METADATA = {
  status: {
    create: {
      readOnly: true,
      required: true,
      hidden: false,
    },
    edit: {
      readOnly: false,
      required: true,
      hidden: false,
    },
  },
  type: {
    create: {
      readOnly: false,
      required: true,
      hidden: false,
    },
    edit: {
      readOnly: true,
      required: true,
      hidden: false,
    },
  },
};

const getDefaultFormValues = ({ alert, alertType }) => ({
  // bind existing alert if defined (do we want to slim this down to only relevant fields?)
  ...alert,
  [FormSchema.BCC]: '',
  [FormSchema.CC]: '',
  [FormSchema.REPLY_TO]: '',
  [FormSchema.STATUS]:
    alert?.status === AlertStatusCodeMapping.CLOSED ? AlertStatus.CLOSED : AlertStatus.OPEN,
  [FormSchema.SUBJECT]: '',
  [FormSchema.TO]: '',
  [FormSchema.TYPE]: alert?.type ?? alertType,
  [FormSchema.ATTACHMENTS]: [],
  [FormSchema.ACTIONABLE_TO]: '',
  [FormSchema.ACTIONABLE_CC]: '',
  [FormSchema.ACTIONABLE_BCC]: '',
  [FormSchema.ACTIONABLE_REPLY_TO]: '',
  [FormSchema.INFORMATIONAL_TO]: '',
  [FormSchema.INFORMATIONAL_CC]: '',
  [FormSchema.INFORMATIONAL_BCC]: '',
  [FormSchema.INFORMATIONAL_REPLY_TO]: '',
  [FormSchema.ATTACHMENTS_ACTIONABLE]: [],
  [FormSchema.ATTACHMENTS_INFORMATIONAL]: [],
});

const getOptions = (val = {}, t = () => {}, prefix = '') =>
  // Object.values handles both object and array inputs
  Object.values(val).map((value) => ({
    label: t(`${prefix}${value}`) ?? value,
    value,
  }));

// don't love this but need to do it so content with absolute positioning
// fills the entire space that scrollbar parent provides
const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  min-height: 50vh;
  overflow: hidden;

  ${scrollbarWrapperOverride}
`;

const ScrollingFormContainer = styled(FormContainer)`
  padding: 20px 25px;
`;

const StyledCheckbox = styled(Checkbox)`
  &.apply-to-all {
    color: ${({ theme }) => theme?.input?.textColor};
    font-size: 11px;
    letter-spacing: 0.37px;
    line-height: 18px;
  }
`;

const StyledAssociatedAssets = styled.div`
  .see-more-assets {
    padding-left: 22px;
    margin-top: 5px;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px;
    color: ${({ theme }) => theme?.input?.textColor};
  }
`;

const ErrorSegment = styled.div`
  margin: 25px 25px 0px 25px;
  padding-bottom: 5px;
  letter-spacing: 0;
  line-height: 15px;
  justify-content: space-between;
  color: ${(props) => props.theme.escalationDialog.textColor};
`;

const ErrorTitle = styled.div`
  text-align: center;
  color: ${(props) => props.theme.dangerColor};
  font-weight: 800;
  font-size: 12px;
`;

const ErrorDescription = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const PanelHeader = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  width: 75%;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.55px;
  line-height: 14px;

  > * {
    flex: 1 1;

    + * {
      margin-left: auto;
      text-transform: none;
      white-space: nowrap;
    }
  }
  svg.indeterminate,
  svg.check {
    fill: ${(props) => props.theme.checkbox.iconColor};
    margin-right: 6px;
  }
  .carot {
    fill: ${(props) => props.theme.escalationDialog.textColor};
    padding: 0;
    & + div {
      margin-left: 6px;
    }
  }
`;

const StyleCheckbox = styled(Checkbox)`
  align-items: center;
  text-transform: capitalize;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #e6e6e6 !important;
  div {
    height: 12px;
    width: 12px;
  }
`;

const NotificationDetails = styled.div`
  &:first-child {
    margin-top: 20px;
  }
  margin: 10px 0px;
  span {
    font-size: 12px;
    letter-spacing: 0.55px;
    line-height: 14px;
  }
  label {
    display: inline-flex;
    & + span {
      padding-left: 10px;
    }
  }
  h5 {
    margin-bottom: 0px;
  }
  .caret {
    transition: transform 0.2s ease;
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(0deg);
    }
`;

const EscalationsDialogSegment = styled.div`
  .carot {
    fill: ${(props) => props.theme.escalationDialog.textColor};
    padding: 0;
    & + div {
      margin-left: 6px;
    }
  }
`;

const NotificationFields = styled.div`
  margin-bottom: 6px;
  .collapsible-panel-content {
    height: auto !important;
  }
  .subject-label {
    color: ${(props) => props.theme.input.labelTextColor};
  }
  .note-include {
    width: 250px;
  }
`;

const CreatedUpdatedBySection = styled.div`
  margin-bottom: 12px;
  > label {
    color: ${({ theme }) => theme?.input?.labelTextColor};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
  }
  > div {
    p {
      color: ${({ theme }) => theme?.input?.textColor};
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 4px 0;
      &:nth-of-type(2) {
        font-size: 11px;
      }
    }
  }
`;

export const StaticFormSection = ({ mode, types, auditProperties }) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });
  const { control } = useFormContext();
  const statusValue = useWatch({ control, name: FormSchema.STATUS });
  const typeValue = useWatch({ control, name: FormSchema.TYPE });

  // state
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  // refresh dropdown values with translated labels
  useEffect(() => {
    setStatusOptions(getOptions(AlertStatus, t, 'form.status.options.'));
    setTypeOptions(getOptions(types ?? AlertType, t, 'form.type.options.'));
  }, [t, types]);

  const getFormattedDateTime = useCallback((input) => {
    const timeZone = getFriendlyTimestamp(new Date()).replace(/.*(\([^)]+\)).*/, '$1');
    return `${dayjs(input).format(DateTimeFormats.DEFAULT_DATE_TIME)} ${timeZone}`;
  }, []);

  if (!ready) {
    return null;
  }

  // revisit the col spans, this is based on what the invision wireframe looks like
  return (
    <>
      {mode === FormMode.VIEW && auditProperties && (
        <FormRow className="divider">
          <FormCol span="5">
            <CreatedUpdatedBySection>
              <label>{t('form.createdBy.label', 'Created By')}</label>
              <div>
                <p>{auditProperties?.createdBy ?? Placeholders.DOUBLE_DASH}</p>
                <p>
                  {auditProperties?.createdDate
                    ? getFormattedDateTime(auditProperties?.createdDate)
                    : Placeholders.DOUBLE_DASH}
                </p>
              </div>
            </CreatedUpdatedBySection>
          </FormCol>
          <FormCol span="7">
            <CreatedUpdatedBySection>
              <label>{t('form.updatedBy.label', 'Updated By')}</label>
              <div>
                <p>{auditProperties?.updatedBy ?? Placeholders.DOUBLE_DASH}</p>
                <p>
                  {auditProperties?.updatedDate
                    ? getFormattedDateTime(auditProperties?.updatedDate)
                    : Placeholders.DOUBLE_DASH}
                </p>
              </div>
            </CreatedUpdatedBySection>
          </FormCol>
        </FormRow>
      )}
      <FormRow>
        <FormCol span="5">
          {[FormMode.CREATE, FormMode.EDIT].includes(mode) ? (
            <SelectMetaField
              label={t('form.status.label', 'Status')}
              metadata={STATIC_METADATA.status[mode]}
              maxWidth={110}
              name={FormSchema.STATUS}
              options={statusOptions}
              searchable={false}
            />
          ) : (
            <AlertViewField
              label={t('form.status.label', 'Status')}
              value={statusOptions.find(({ value }) => statusValue === value)?.label}
            />
          )}
        </FormCol>
        <FormCol span="7">
          {[FormMode.CREATE, FormMode.EDIT].includes(mode) ? (
            <SelectMetaField
              label={t('form.type.label', 'Type')}
              metadata={STATIC_METADATA.type[mode]}
              name={FormSchema.TYPE}
              options={typeOptions}
              primary
              searchable={false}
            />
          ) : (
            <AlertViewField
              label={t('form.type.label', 'Type')}
              value={typeOptions.find(({ value }) => typeValue === value)?.label}
            />
          )}
        </FormCol>
      </FormRow>
    </>
  );
};

StaticFormSection.propTypes = {
  mode: PropTypes.oneOf(FORM_MODES),
  types: PropTypes.arrayOf(PropTypes.string),
  auditProperties: PropTypes.object,
};

StaticFormSection.defaultProps = {
  mode: FormMode.CREATE,
  types: undefined,
};

export const Alert = ({
  alert,
  alertType,
  entity,
  entityType,
  mode: _mode,
  onExpandSendNotification,
  onModeChange,
  onRegisterSubmit,
  onStateChange,
  selectedAssets,
  isBulk,
  groupDetails,
  setIsAssetLevelDLFoundInBulkAlert,
  isAssetLevelDLFoundInBulkAlert,
}) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });
  const methods = useForm({
    defaultValues: getDefaultFormValues({ alert, alertType }),
    mode: 'onBlur',
  });
  const { control, formState, handleSubmit, register, reset, setValue, watch } = methods;

  const currentType = useWatch({ control, defaultValue: alertType, name: FormSchema.TYPE });
  const toFieldValue = watch(FormSchema.TO);
  const toActionFieldValue = watch(FormSchema.ACTIONABLE_TO);
  const toInformationalFieldValue = watch(FormSchema.INFORMATIONAL_TO);

  const [actionable, setActionable, actionableRef] = useStateRef(CheckedState.CHECKED);
  const [informational, setInformational, informationalRef] = useStateRef(CheckedState.CHECKED);
  const [sendNotification, setSendNotification, sendNotificationRef] = useStateRef(
    CheckedState.CHECKED,
  );

  // state
  const [hasNotification, setHasNotification] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isStable, setIsStable] = useState(true);
  const [mode, setMode] = useState(_mode);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [actionableAttachmentFiles, setActionableAttachmentFiles] = useState([]);
  const [informationalAttachmentFiles, setInformationalAttachmentFiles] = useState([]);
  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [associatedOpenAlerts, setAssociatedOpenAlerts] = useState([]);

  const alertId = alert?.id;
  const entityId = entity?.id;
  const startTime = alert?.auditProperties?.createdDate;
  const isClosedAlert = alert?.isClosed;

  // data hooks
  const {
    data: { template = {}, types },
    error,
    isLoading,
  } = useAlertTemplate({ alertType: currentType, entityId, entityType, mode });

  const {
    sections: {
      description: descriptionMetadata,
      sendNotification: sendNotificationMetadata,
      sendActionableInformational: sendActionableInformationalMetadata,
    } = {},
  } = template ?? {};

  const getEmailType = useCallback(() => {
    return `${AlertEmailType.TEMPORARY_CONFIG_CHANGE_ACTIONABLE},${AlertEmailType.TEMPORARY_CONFIG_CHANGE_INFORMATIONAL}`;
  }, []);

  const { data: distributionList, isLoading: isDistributionListLoading } = useDistributionList(
    {
      emailType: currentType === AlertType.TEMPORARY_CONFIG_CHANGE ? getEmailType() : currentType,
      entityType:
        entityType === AlertsEntityType.SITE || entityType === AlertsEntityType.STORAGE_SITE
          ? EntityType.SITE
          : EntityType.ASSET,
      entityId: isBulk ? selectedAssets?.map((asset) => asset.id).join(',') : entityId,
    },
    Boolean(
      [FormMode.CREATE, FormMode.EDIT].includes(mode) &&
        ((sendNotificationMetadata && !sendNotificationMetadata.hidden) ||
          (sendActionableInformationalMetadata &&
            sendActionableInformationalMetadata.showActionableInformational)),
    ),
  );

  const { to, cc, bcc, replyTo } = distributionList?.[0] ?? {};
  const {
    to: actionableTo,
    cc: actionableCc,
    bcc: actionableBcc,
    replyTo: actionableReplyTo,
  } = distributionList?.[0] ?? {};
  const {
    to: informationalTo,
    cc: informationalCc,
    bcc: informationalBcc,
    replyTo: informationalReplyTo,
  } = distributionList?.[1] ?? {};

  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const associatedAssetsName = useMemo(() => {
    let assetsName = '';
    if (alert?.groupDetails && Array.isArray(groupDetails)) {
      const matchedGroup = groupDetails.find(
        (item) => item?.groupId === alert?.groupDetails?.groupId,
      );
      if (matchedGroup) {
        assetsName = matchedGroup.mapping
          .filter((item) => item.assetId !== entity?.id)
          .map((item) => getAssetById(item?.assetId)?.name)
          .join(', ');
      }
    }
    return assetsName;
  }, [alert, groupDetails, getAssetById, entity]);

  const associatedAssetsWithOpenAlert = useMemo(() => {
    let assetsName = '';
    if (alert?.groupDetails && Array.isArray(groupDetails)) {
      const matchedGroup = groupDetails.find(
        (item) => item?.groupId === alert?.groupDetails?.groupId,
      );
      if (matchedGroup) {
        assetsName = matchedGroup.mapping
          .filter(
            (item) => item.assetId !== entity?.id && item.status !== AlertStatusCodeMapping.CLOSED,
          )
          .map((item) => getAssetById(item?.assetId)?.name)
          .join(', ');

        setAssociatedOpenAlerts(
          matchedGroup?.mapping?.filter(
            (item) => item.assetId !== entity?.id && item.status !== AlertStatusCodeMapping.CLOSED,
          ),
        );
      }
    }
    return assetsName;
  }, [alert, groupDetails, getAssetById, entity]);

  const toggleActionableDetails = useCallback(() => {
    setActionable(
      actionableRef.current === CheckedState.CHECKED
        ? CheckedState.UNCHECKED
        : CheckedState.CHECKED,
    );
  }, [setActionable, actionableRef]);

  const toggleInformationalDetails = useCallback(() => {
    setInformational(
      informationalRef.current === CheckedState.CHECKED
        ? CheckedState.UNCHECKED
        : CheckedState.CHECKED,
    );
  }, [setInformational, informationalRef]);

  const toggleSendNotificationDetails = useCallback(() => {
    setSendNotification(
      sendNotificationRef.current === CheckedState.CHECKED
        ? CheckedState.UNCHECKED
        : CheckedState.CHECKED,
    );
  }, [setSendNotification, sendNotificationRef]);

  const actionableHeader = (
    <PanelHeader onClick={(e) => e.stopPropagation()}>
      <StyleCheckbox
        checkState={actionable}
        onChange={toggleActionableDetails}
        label={t('actionable', 'Actionable')}
        name="actionableCase"
      />
    </PanelHeader>
  );

  const informationalHeader = (
    <PanelHeader onClick={(e) => e.stopPropagation()} className="note-include">
      <StyleCheckbox
        checkState={informational}
        onChange={toggleInformationalDetails}
        label={t('informational', 'Informational')}
        name="informationalCase"
      />
    </PanelHeader>
  );

  const sendNotificationHeader = (
    <PanelHeader onClick={(e) => e.stopPropagation()}>
      <StyleCheckbox
        checkState={sendNotification}
        onChange={toggleSendNotificationDetails}
        label={t('send_Notification', 'SEND NOTIFICATION')}
        name="sendNotificationCase"
      />
    </PanelHeader>
  );

  useEffect(() => {
    setHasNotification(
      (Boolean(toFieldValue) && sendNotification === CheckedState.CHECKED) ||
        Boolean(toActionFieldValue && actionable === CheckedState.CHECKED) ||
        Boolean(toInformationalFieldValue && informational === CheckedState.CHECKED),
    );
  }, [
    toFieldValue,
    toActionFieldValue,
    toInformationalFieldValue,
    sendNotification,
    actionable,
    informational,
  ]);

  // reset form when alert type changes
  useEffect(() => {
    // TODO: prompt with confirm dialog before resetting
    reset(getDefaultFormValues({ alert, alertType: currentType }));

    setHasNotification(false);
    setIsStable(false);
  }, [alert, currentType, reset]);

  // removing dom node and then recreating seems to work well for resetting form state
  // so using this to recreate the whole form on reset to avoid any state anomalies
  // it immediately returns flag to stable when it detects that it is false to force the recreate
  // can revisit this approach since it feels a little lazy, but it also is working...
  useEffect(() => {
    if (isStable) {
      return;
    }

    setIsStable(true);
  }, [isStable]);

  // bind hidden/programmatic form values in here
  useEffect(() => {
    // tried to streamline these by setting value in register options but didn't work
    register(FormSchema.ENTITY_ID);
    setValue(FormSchema.ENTITY_ID, entityId);

    register(FormSchema.ENTITY_TYPE);
    setValue(FormSchema.ENTITY_TYPE, entityType);

    register(FormSchema.ID);
    setValue(FormSchema.ID, alertId);

    register(FormSchema.START_TIME);
    setValue(FormSchema.START_TIME, startTime);

    register(FormSchema.ATTACHMENTS);
    setValue(FormSchema.ATTACHMENTS, attachmentFiles);

    register(FormSchema.SELECTED_ASSETS);
    setValue(
      FormSchema.SELECTED_ASSETS,
      isBulk ? selectedAssets?.map((asset) => asset.id).join(',') : entityId,
    );
    register(FormSchema.APPLY_TO_ALL);
    setValue(FormSchema.APPLY_TO_ALL, isApplyToAll);

    register(FormSchema.GROUP_DETAILS);
    setValue(FormSchema.GROUP_DETAILS, associatedOpenAlerts);

    register(FormSchema.ATTACHMENTS_ACTIONABLE);
    setValue(FormSchema.ATTACHMENTS_ACTIONABLE, actionableAttachmentFiles);

    register(FormSchema.ATTACHMENTS_INFORMATIONAL);
    setValue(FormSchema.ATTACHMENTS_INFORMATIONAL, informationalAttachmentFiles);

    register(FormSchema.IS_ACTIONABLE_CHECKED);
    setValue(FormSchema.IS_ACTIONABLE_CHECKED, actionable === CheckedState.CHECKED);

    register(FormSchema.IS_INFORMATIONAL_CHECKED);
    setValue(FormSchema.IS_INFORMATIONAL_CHECKED, informational === CheckedState.CHECKED);

    register(FormSchema.REPLY_TO);
    setValue(FormSchema.REPLY_TO, replyTo ?? '');

    register(FormSchema.ACTIONABLE_REPLY_TO);
    setValue(FormSchema.ACTIONABLE_REPLY_TO, actionableReplyTo ?? '');

    register(FormSchema.INFORMATIONAL_REPLY_TO);
    setValue(FormSchema.INFORMATIONAL_REPLY_TO, informationalReplyTo ?? '');

    register(FormSchema.IS_SEND_NOTIFICATION_CHECKED);
    setValue(FormSchema.IS_SEND_NOTIFICATION_CHECKED, sendNotification === CheckedState.CHECKED);
  }, [
    alertId,
    entityId,
    entityType,
    startTime,
    attachmentFiles,
    isBulk,
    selectedAssets,
    register,
    setValue,
    associatedOpenAlerts,
    actionableAttachmentFiles,
    informationalAttachmentFiles,
    actionable,
    informational,
    replyTo,
    actionableReplyTo,
    informationalReplyTo,
    isApplyToAll,
    sendNotification,
  ]);

  // bind mail template values
  useEffect(() => {
    if (!isStable) {
      return;
    }

    setValue(FormSchema.BCC, bcc ?? '');
    setValue(FormSchema.CC, cc ?? '');
    setValue(FormSchema.TO, to ?? '');
    setValue(FormSchema.ACTIONABLE_TO, actionableTo ?? '');
    setValue(FormSchema.ACTIONABLE_CC, actionableCc ?? '');
    setValue(FormSchema.ACTIONABLE_BCC, actionableBcc ?? '');
    setValue(FormSchema.INFORMATIONAL_TO, informationalTo ?? '');
    setValue(FormSchema.INFORMATIONAL_CC, informationalCc ?? '');
    setValue(FormSchema.INFORMATIONAL_BCC, informationalBcc ?? '');
  }, [
    bcc,
    cc,
    isStable,
    setValue,
    to,
    actionableTo,
    actionableCc,
    actionableBcc,
    informationalTo,
    informationalCc,
    informationalBcc,
  ]);

  useEffect(() => {
    setValue(FormSchema.ATTACHMENTS, attachmentFiles);
    setValue(FormSchema.ATTACHMENTS_ACTIONABLE, actionableAttachmentFiles);
    setValue(FormSchema.ATTACHMENTS_INFORMATIONAL, informationalAttachmentFiles);
  }, [attachmentFiles, actionableAttachmentFiles, informationalAttachmentFiles, setValue]);

  useEffect(() => {
    setValue(FormSchema.APPLY_TO_ALL, isApplyToAll);
  }, [isApplyToAll, setValue]);

  // listen to form state changes in here
  // per doc, need to listen to entire form state otherwise it won't update correctly
  // we parse the form state into local state that we can watch individually to reduce noise
  // can revisit if this is even necessary
  // https://react-hook-form.com/v6/api#formStateRef
  useEffect(() => {
    setIsDirty(formState?.isDirty ?? false);
  }, [formState]);

  useEffect(() => {
    setMode(_mode);
  }, [_mode]);

  // handle state changes to parent
  useEffect(() => {
    onStateChange({ isDirty });
  }, [isDirty, onStateChange]);

  // need to rethink this if it borders on anti-pattern
  useEffect(() => {
    onRegisterSubmit((onSubmit) => handleSubmit(onSubmit));
  }, [entityId, entityType, handleSubmit, onRegisterSubmit]);

  // notify parent that send notificaton section is expanded (so expect to send email with alert)
  useEffect(() => {
    onExpandSendNotification(hasNotification);
  }, [hasNotification, onExpandSendNotification]);

  useEffect(() => {
    if (Array.isArray(distributionList)) {
      if (isBulk && distributionList.length && !distributionList?.[0]) {
        setIsAssetLevelDLFoundInBulkAlert(true);
      } else {
        setIsAssetLevelDLFoundInBulkAlert(false);
      }
    }
  }, [distributionList, isBulk, setIsAssetLevelDLFoundInBulkAlert]);

  const handleEdit = useCallback(() => {
    setMode(FormMode.EDIT);

    onModeChange(FormMode.EDIT);
    setIsStable(false);
  }, [onModeChange]);

  // TOOD: handle errors from backend
  if (error?.template || error?.types) {
    return null;
  }

  // is there a better way to handle this?
  if (!FORM_MODES.includes(mode) || !ready) {
    return null;
  }

  // TODO: add a new data loader type, maybe "form"
  return (
    <div>
      <AlertHeader
        entity={entity}
        entityType={entityType}
        mode={mode}
        onEdit={handleEdit}
        selectedAssets={selectedAssets}
        isBulk={isBulk}
        associatedAssetsName={associatedAssetsName}
        startTime={startTime}
        isClosedAlert={isClosedAlert}
      />
      {isAssetLevelDLFoundInBulkAlert && isBulk && mode === FormMode.CREATE ? (
        <ScrollingWrapper>
          <ScrollingContainer>
            <ErrorSegment>
              <ErrorTitle>
                {t(
                  'different_distribution_list',
                  'The selected assets have different distribution lists',
                )}
              </ErrorTitle>
              <ErrorDescription>
                {t('try_individual_alert', 'Try creating individual alert')}
              </ErrorDescription>
            </ErrorSegment>
          </ScrollingContainer>
        </ScrollingWrapper>
      ) : (
        <ScrollingWrapper>
          <ScrollingContainer>
            <ScrollingFormContainer mode={mode}>
              <FormProvider {...methods}>
                <DataLoader
                  isLoading={isLoading || isDistributionListLoading}
                  renderCondition
                  type={DataLoaderType.SPINNER}
                >
                  {isStable && (
                    <form>
                      <StaticFormSection
                        mode={mode}
                        types={types}
                        auditProperties={alert?.auditProperties}
                      />
                      {associatedAssetsWithOpenAlert && mode === FormMode.EDIT && (
                        <StyledAssociatedAssets>
                          <StyledCheckbox
                            className={'apply-to-all'}
                            label={t(
                              'apply_close_to_all',
                              'Apply the Close action to all associated assets with open alert',
                            )}
                            checkState={
                              isApplyToAll ? CheckedState.CHECKED : CheckedState.UNCHECKED
                            }
                            onChange={() => {
                              setIsApplyToAll(!isApplyToAll);
                            }}
                          />
                          <SeeMore className={'see-more-assets'} charLimit={50}>
                            {associatedAssetsWithOpenAlert}
                          </SeeMore>
                        </StyledAssociatedAssets>
                      )}
                      <AlertBodyTemplate
                        alert={alert}
                        mode={mode}
                        template={template?.sections?.body}
                      />
                      <ConditionalRender shouldRender={!descriptionMetadata?.hidden}>
                        <DescriptionField
                          mode={mode}
                          name={FormSchema.DESCRIPTION}
                          {...descriptionMetadata?.metadata[0]}
                        />
                      </ConditionalRender>
                      <ConditionalRender
                        shouldRender={
                          sendActionableInformationalMetadata?.showActionableInformational ?? false
                        }
                      >
                        <EscalationsDialogSegment>
                          <NotificationDetails>
                            <h5>{t('notification_details', 'NOTIFICATION DETAILS')}</h5>
                          </NotificationDetails>
                          <NotificationFields>
                            <SendNotificationField
                              name={FormSchema.ACTIONABLE}
                              headerLabel={actionableHeader}
                              showLastSent={true}
                              lastSentDate={
                                alert?.notificationProperties?.notificationSentTime
                                  ? new Date(alert.notificationProperties.notificationSentTime)
                                  : ''
                              }
                              actionableLabel={true}
                              lastSentBy={alert?.notificationProperties?.notificationSentBy}
                              isHeaderHidden={true}
                              mode={mode}
                              multipleRecipientFields={true}
                              maxAttachmentSizeMb={5}
                              isAttachmentRequired={true}
                              files={actionableAttachmentFiles}
                              handleUpload={(files) => setActionableAttachmentFiles(files)}
                              emailType={'a'}
                            />
                          </NotificationFields>
                          <NotificationFields>
                            <SendNotificationField
                              name={FormSchema.INFORMATIONAL}
                              headerLabel={informationalHeader}
                              isHeaderHidden={true}
                              mode={mode}
                              multipleRecipientFields={true}
                              maxAttachmentSizeMb={5}
                              isAttachmentRequired={true}
                              files={informationalAttachmentFiles}
                              showLastSent={false}
                              handleUpload={(files) => setInformationalAttachmentFiles(files)}
                              emailType={'i'}
                            />
                          </NotificationFields>
                        </EscalationsDialogSegment>
                      </ConditionalRender>
                      <ConditionalRender
                        shouldRender={
                          !sendNotificationMetadata?.hidden &&
                          !sendActionableInformationalMetadata?.showActionableInformational
                        }
                      >
                        <SendNotificationField
                          mode={mode}
                          name={FormSchema.DESCRIPTION}
                          lastSentDate={
                            alert?.notificationProperties?.notificationSentTime
                              ? new Date(alert.notificationProperties.notificationSentTime)
                              : ''
                          }
                          lastSentBy={alert?.notificationProperties?.notificationSentBy}
                          headerLabel={sendNotificationHeader}
                          isHeaderHidden={true}
                          maxAttachmentSizeMb={5}
                          isAttachmentRequired={true}
                          files={attachmentFiles}
                          handleUpload={(files) => setAttachmentFiles(files)}
                          emailType={'a'}
                          {...sendNotificationMetadata?.metadata[0]}
                        />
                      </ConditionalRender>
                    </form>
                  )}
                </DataLoader>
              </FormProvider>
            </ScrollingFormContainer>
          </ScrollingContainer>
        </ScrollingWrapper>
      )}
    </div>
  );
};

Alert.propTypes = {
  alert: PropTypes.object,
  alertType: PropTypes.oneOf(Object.values(AlertType)),
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.oneOf(Object.values(AlertsEntityType)).isRequired,
  mode: PropTypes.oneOf(FORM_MODES),
  onExpandSendNotification: PropTypes.func,
  onModeChange: PropTypes.func,
  onRegisterSubmit: PropTypes.func,
  onStateChange: PropTypes.func,
  selectedAssets: PropTypes.array,
  isBulk: PropTypes.bool,
  groupDetails: PropTypes.array,
  setIsAssetLevelDLFoundInBulkAlert: PropTypes.func,
  isAssetLevelDLFoundInBulkAlert: PropTypes.bool,
};

Alert.defaultProps = {
  alert: undefined,
  alertType: AlertType.PLANNED_OUTAGE,
  mode: FormMode.VIEW,
  isAssetLevelDLFoundInBulkAlert: false,
  onExpandSendNotification: () => {},
  onModeChange: () => {},
  onRegisterSubmit: () => {},
  onStateChange: () => {},
  setIsAssetLevelDLFoundInBulkAlert: () => {},
};
