import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { NAMESPACE } from '@ge/feat-analyze/models/configure-case-template';
import { typography } from '@ge/tokens';

import NewCaseTemplateDialog from './new-case-template-dialog';

const AddCaseButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  padding-right: 1rem;
  padding-top: 4px;
`;

const PlusIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

export const NewCaseTemplateButton = () => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const [showDialog, setShowDialog] = useState(false);

  if (!ready) return null;

  return (
    <>
      <AddCaseButton onClick={() => setShowDialog(true)}>
        <PlusIcon />
        {t('add_case_template_button', 'Add Case Template')}
      </AddCaseButton>
      <NewCaseTemplateDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={() => setShowDialog(false)}
      />
    </>
  );
};
