import dayjs from 'dayjs';

import { TaskStatus } from '@ge/models';
import { CalendarRanges, DateTimeFormats } from '@ge/models/constants';
import { groupById } from '@ge/util';

import { FilterValues } from '../context/planning-provider';

export const getBundleInfo = (tasks, date) => {
  const bundleInfo = {
    sameAssetTaskCount: 0,
    bundlePresent: false,
    bundleCount: 0,
    completedTasks: 0,
  };
  if (tasks.length > 1) {
    let firstTaskCrew = tasks[0]?.crewIds ? tasks[0]?.crewIds[0] : null;
    tasks.forEach((task) => {
      // if same asset
      if (task.asset.id == tasks[0].asset.id) {
        let nTaskCrew = task?.crewIds ? task?.crewIds[0] : null;
        // if same crew or unassigned
        if (firstTaskCrew === nTaskCrew) {
          bundleInfo.sameAssetTaskCount = bundleInfo.sameAssetTaskCount + 1;
        }
      }
      // if one of the task has bundle flag true set bundlePresent to true
      if (task?.isBundled == true && task.bundledTasks?.length) {
        bundleInfo.bundlePresent = true;
        let bundledTasksForCount = task.bundledTasksList.filter((btask) => {
          if (btask.status === TaskStatus.UNSCHEDULED) {
            return btask;
          }
          if (btask.status === TaskStatus.SCHEDULED) {
            const checkDate = dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
            const scheduleDate = dayjs(btask?.scheduleDateTz).format(DateTimeFormats.DEFAULT_DATE);
            if (!dayjs(checkDate).isSame(dayjs(scheduleDate), 'day')) {
              return btask;
            }
          }
        });
        bundleInfo.bundleCount = bundledTasksForCount.length;
      }
      if (task.status == TaskStatus.COMPLETE) {
        bundleInfo.completedTasks = bundleInfo.completedTasks + 1;
      }
    });
  } else {
    if (tasks[0]?.isBundled && tasks[0]?.bundledTasks?.length) {
      bundleInfo.bundlePresent = true;
      //only tasks which are unscheduled and scheduled for the day of intrest are considered for bundled count.
      let bundledTasksForCount = tasks[0].bundledTasksList.filter((btask) => {
        if (btask.status === TaskStatus.UNSCHEDULED) {
          return btask;
        }
        if (btask.status === TaskStatus.SCHEDULED) {
          const checkDate = dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
          const scheduleDate = dayjs(btask?.scheduleDateTz).format(DateTimeFormats.DEFAULT_DATE);
          if (!dayjs(checkDate).isSame(dayjs(scheduleDate), 'day')) {
            return btask;
          }
        }
      });
      bundleInfo.bundleCount = bundledTasksForCount.length;
    }
  }

  if (bundleInfo.bundlePresent && bundleInfo.bundleCount == 0) {
    bundleInfo.bundlePresent = false;
  }

  return bundleInfo;
};

const zeroPad = (num, places) => String(num).padStart(places, '0');

const getTaskDuration = (task) => {
  const durationHrs = task?.estDurationHours;
  let durationMins = zeroPad(task?.estDurationMinutes, 2);
  return `${durationHrs}:${durationMins}`;
};

export const getNewTaskDuration = (primaryList, bTaskList) => {
  let timeDuration = [];
  primaryList?.filter((pobj) => {
    timeDuration.push(getTaskDuration(pobj));
  });
  bTaskList?.filter((obj) => {
    timeDuration.push(getTaskDuration(obj));
  });
  return timeDuration;
};

/*WIP - Group based on asset/site ids */
const getUpdatedGroupTasks = (tasks, bundleInfo, order, hasMultipleCrews) => {
  let primary = [];
  let secondary = [];
  const updatedTasks = [];
  let finalData = {};
  if (hasMultipleCrews) {
    Object.entries(tasks)?.map((gTasks) => {
      primary = [];
      secondary = [];
      gTasks[1]?.map((gAssets) => {
        gAssets?.primaryTasks?.map((pTasks) => {
          primary.push(pTasks);
        });
        gAssets?.secondaryTasks?.map((sTasks) => {
          secondary.push(sTasks);
        });
      });
      primary = [...new Map(primary.map((m) => [m?.id, m])).values()];
      secondary = [...new Set(secondary)];
      primary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
      secondary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
      secondary = secondary.filter((ar) => !primary.find((rm) => rm?.id === ar?.id));

      finalData = {
        id: primary[0]?.id,
        primaryTasks: primary,
        secondaryTasks: secondary,
        allTasks: primary.concat(secondary),
        mainTask: primary[0],
        allTasksDuration: getNewTaskDuration(primary, secondary),
        bundleInfo: bundleInfo,
        bundledTasks: secondary,
        crewIds: primary[0]?.crewIds ? primary[0]?.crewIds : [],
      };
      updatedTasks.push(finalData);
    });
  } else {
    Object.entries(tasks)?.map((gTasks) => {
      gTasks[1]?.map((gAssets) => {
        gAssets?.primaryTasks?.map((pTasks) => {
          primary.push(pTasks);
        });
        gAssets?.secondaryTasks?.map((sTasks) => {
          secondary.push(sTasks);
        });
      });
    });
    primary = [...new Map(primary.map((m) => [m?.id, m])).values()];
    secondary = [...new Set(secondary)];
    primary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
    secondary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
    secondary = secondary.filter((ar) => !primary.find((rm) => rm?.id === ar?.id));

    finalData = {
      id: primary[0]?.id,
      primaryTasks: primary,
      secondaryTasks: secondary,
      allTasks: primary.concat(secondary),
      mainTask: primary[0],
      allTasksDuration: getNewTaskDuration(primary, secondary),
      bundleInfo: bundleInfo,
      bundledTasks: secondary,
      crewIds: primary[0]?.crewIds ? primary[0]?.crewIds : [],
    };
    updatedTasks.push(finalData);
  }
  return updatedTasks;
};

export const getUpdatedStackTasks = (tasks, date, groupBy, rangeType) => {
  const currentDate = dayjs();
  let updatedTasks = [];
  let primary = [];
  let secondary = [];
  let finalData = {
    primary: primary,
    secondary: secondary,
    mainTask: {},
  };
  const order = [TaskStatus.COMPLETE, TaskStatus.SCHEDULED, TaskStatus.UNSCHEDULED];
  let bundleInfo = getBundleInfo(tasks, date);

  if (tasks.length > 1) {
    tasks?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
    tasks?.forEach((task) => {
      primary = [];
      secondary = [];
      primary.push(task);
      if (task.asset.id == tasks[0].asset.id) {
        if (task?.crewIds && tasks[0]?.crewIds && task?.crewIds[0] == tasks[0]?.crewIds[0]) {
          primary.push(task);
        }
      }
      if (task.status === TaskStatus.COMPLETE) {
        if (!dayjs(currentDate).isSame(dayjs(task?.completedDateTimeTz), 'day')) {
          task.isBundled = false;
        }
        primary.push(task);
      }
      if (task?.isBundled == true && task.bundledTasks?.length) {
        primary.push(task);
        task.bundledTasksList.filter((btask) => {
          if (btask.status === TaskStatus.UNSCHEDULED) {
            secondary.push(btask);
          }
          if (btask.status === TaskStatus.SCHEDULED) {
            const checkDate = dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
            const scheduleDate = dayjs(btask?.scheduleDateTz).format(DateTimeFormats.DEFAULT_DATE);
            if (!dayjs(checkDate).isSame(dayjs(scheduleDate), 'day')) {
              secondary.push(btask);
            }
          }
          if (btask.status === TaskStatus.SCHEDULED && rangeType === CalendarRanges.DAY) {
            secondary.push(btask);
          }
        });
      } else {
        primary.push(task);
      }
      if (!primary.length) {
        primary.push(task);
      }
      // Remove duplicate array objects
      primary = [...new Map(primary.map((m) => [m?.id, m])).values()];
      secondary = [...new Set(secondary)];
      primary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
      secondary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
      secondary = secondary.filter((ar) => !primary.find((rm) => rm?.id === ar?.id));

      finalData = {
        id: task.id,
        primaryTasks: primary,
        secondaryTasks: secondary,
        allTasks: primary.concat(secondary),
        mainTask: task,
        allTasksDuration: getNewTaskDuration(primary, secondary),
        bundleInfo: bundleInfo,
        bundledTasks: task?.bundledTasks,
        crewIds: task?.crewIds ? task?.crewIds : [],
      };
      updatedTasks.push(finalData);
    });
  } else if (tasks?.length === 1) {
    primary = [];
    secondary = [];
    if (tasks[0]?.status == TaskStatus.COMPLETE) {
      if (!dayjs(currentDate).isSame(dayjs(tasks[0]?.completedDateTimeTz), 'day')) {
        tasks[0].isBundled = false;
      }
      primary.push(tasks[0]);
    }
    if (tasks[0]?.isBundled && tasks[0]?.bundledTasks?.length) {
      primary.push(tasks[0]);
      tasks[0].bundledTasksList.filter((btask) => {
        if (btask.status === TaskStatus.UNSCHEDULED) {
          secondary.push(btask);
        }
        if (btask.status === TaskStatus.SCHEDULED) {
          const checkDate = dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
          const scheduleDate = dayjs(btask?.scheduleDateTz).format(DateTimeFormats.DEFAULT_DATE);
          if (!dayjs(checkDate).isSame(dayjs(scheduleDate), 'day')) {
            secondary.push(btask);
          }
        }
        if (btask.status === TaskStatus.SCHEDULED && rangeType === CalendarRanges.DAY) {
          secondary.push(btask);
        }
        if (btask.status == TaskStatus.COMPLETE) {
          primary.push(btask);
        }
      });
    } else {
      primary.push(tasks[0]);
    }
    // Remove duplicate array objects
    primary = [...new Map(primary.map((m) => [m?.id, m])).values()];
    secondary = [...new Set(secondary)];
    primary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
    secondary?.sort((x, y) => order.indexOf(x.status) - order.indexOf(y.status));
    secondary = secondary.filter((ar) => !primary.find((rm) => rm?.id === ar?.id));

    finalData = {
      id: tasks[0]?.id,
      primaryTasks: primary,
      secondaryTasks: secondary,
      allTasks: primary.concat(secondary),
      mainTask: tasks[0],
      allTasksDuration: getNewTaskDuration(primary, secondary),
      bundleInfo: bundleInfo,
      bundledTasks: tasks[0]?.bundledTasks,
      crewIds: tasks[0]?.crewIds ? tasks[0]?.crewIds : [],
    };
    updatedTasks.push(finalData);
  } else {
    updatedTasks = [];
  }

  // Update the tasks based on crew. If multiple crew, group by crew else by asset id
  let groupByCrew = {};
  if (updatedTasks.length > 1) {
    groupByCrew = groupById(updatedTasks, ['crewIds']);

    if (Object.keys(groupByCrew).length === 1) {
      if (groupBy == FilterValues.CREW) {
        const siteTasks = tasks.filter((t) => !t?.asset.id);
        const assetTasks = tasks.filter((t) => t?.asset.id);

        const groupedBySite = groupById(siteTasks, ['site', 'id']) || [];
        const groupedByAsset = groupById(assetTasks, ['asset', 'id']) || [];
        let groupByAssets = [...Object.values(groupedBySite), ...Object.values(groupedByAsset)];
        if (Object.keys(groupByAssets).length === 1) {
          updatedTasks = getUpdatedGroupTasks(groupByCrew, bundleInfo, order, false);
        } else {
          updatedTasks = [];
          Object.values(groupByAssets)?.map((gAssets) => {
            if (gAssets.length > 1) {
              primary = [];
              secondary = [];
              gAssets.map((task) => {
                primary.push(task);
                if (task?.isBundled === true && task?.bundledTasksList.length) {
                  task.bundledTasksList.filter((btask) => {
                    if (btask.status === TaskStatus.UNSCHEDULED) {
                      secondary.push(btask);
                    }
                    if (btask.status === TaskStatus.SCHEDULED) {
                      const checkDate = dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
                      const scheduleDate = dayjs(btask?.scheduleDateTz).format(
                        DateTimeFormats.DEFAULT_DATE,
                      );
                      if (!dayjs(checkDate).isSame(dayjs(scheduleDate), 'day')) {
                        secondary.push(btask);
                      }
                    }
                    if (btask.status === TaskStatus.SCHEDULED && rangeType === CalendarRanges.DAY) {
                      secondary.push(btask);
                    }
                    if (btask.status == TaskStatus.COMPLETE) {
                      primary.push(btask);
                    }
                  });
                }
              });
            } else {
              primary = [];
              secondary = [];
              if (gAssets[0]?.status == TaskStatus.COMPLETE) {
                if (gAssets[0]?.isBundled === true) {
                  gAssets[0].bundledTasksList = [];
                  gAssets[0].bundledTasks = [];
                }
                primary.push(gAssets[0]);
              }
              if (gAssets[0]?.isBundled && gAssets[0]?.bundledTasks?.length) {
                primary.push(gAssets[0]);
                gAssets[0].bundledTasksList.filter((btask) => {
                  if (btask.status === TaskStatus.UNSCHEDULED) {
                    secondary.push(btask);
                  }
                  if (btask.status === TaskStatus.SCHEDULED) {
                    const checkDate = dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
                    const scheduleDate = dayjs(btask?.scheduleDateTz).format(
                      DateTimeFormats.DEFAULT_DATE,
                    );
                    if (!dayjs(checkDate).isSame(dayjs(scheduleDate), 'day')) {
                      secondary.push(btask);
                    }
                  }
                  if (btask.status == TaskStatus.COMPLETE) {
                    primary.push(btask);
                  }
                });
              } else {
                primary.push(gAssets[0]);
              }
            }
            // Remove duplicate array objects
            primary = [...new Map(primary.map((m) => [m?.id, m])).values()];
            secondary = [...new Set(secondary)];
            secondary = secondary.filter((ar) => !primary.find((rm) => rm?.id === ar?.id));

            finalData = {
              id: gAssets[0]?.id,
              primaryTasks: primary,
              secondaryTasks: secondary,
              allTasks: primary.concat(secondary),
              mainTask: gAssets[0],
              allTasksDuration: getNewTaskDuration(primary, secondary),
              bundleInfo: bundleInfo,
              bundledTasks: gAssets[0]?.bundledTasks,
              crewIds: gAssets[0]?.crewIds ? gAssets[0]?.crewIds : [],
            };
            updatedTasks.push(finalData);
          });
        }
      } else {
        updatedTasks = getUpdatedGroupTasks(groupByCrew, bundleInfo, order, false);
      }
    } else {
      updatedTasks = getUpdatedGroupTasks(groupByCrew, bundleInfo, order, true);
    }
  }
  return updatedTasks;
};
