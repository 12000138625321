import { action, computed, thunk, persist } from 'easy-peasy';
import memoize from 'memoizerific';

// TODO (astone): This is an unholy reference to a feature from shared. Must remove!
import { fetchAnalyzeCases } from '@ge/feat-analyze/services';
// TODO (astone): This is an unholy reference to a feature from shared. Must remove!
import { fetchCaseDetails, closeCaseById } from '@ge/feat-monitor/services';
import { AssetState } from '@ge/models/constants';
import { byId, removeFalsyVals } from '@ge/util/array-utils';
import { sorter } from '@ge/util/metric-sorter';
import { mapObject } from '@ge/util/object-utils';

import indexedDb from './storage/indexedDb';

// Define initial state
const defaultCasesState = persist(
  {
    cases: {},
    lastUpdated: new Date().getTime(),
  },
  {
    version: 1,
    storage: indexedDb,
    mergeStrategy: 'mergeShallow',
    allow: ['cases', 'lastUpdated'],
  },
);

// Actions
const casesActions = {
  // eslint-disable-next-line no-unused-vars
  resetCases: action((state) => {
    state = Object.assign(state, defaultCasesState);
  }),
  // To fetch latest cases 1 minute from the current time.
  setLastUpdated: action((state) => {
    state.lastUpdated = new Date().getTime() - 60000;
  }),

  fetchCasesForSites: thunk(async (actions, siteIds, { fail }) => {
    try {
      const { data } = await fetchAnalyzeCases(siteIds);

      actions.updateCases(data);
    } catch (err) {
      fail(err);
    }
  }),

  fetchCaseWithDetails: thunk(async (actions, caseId, { fail, getStoreActions }) => {
    try {
      const {
        entities: { alerts, cases },
      } = await fetchCaseDetails(caseId);
      getStoreActions().alerts.updateAlerts(alerts);
      actions.updateCases(Object.values(cases));
      return { alerts, cases };
    } catch (err) {
      fail(err);
    }
  }),

  updateCases: action((state, caseArr) => {
    state.cases = {
      ...state.cases,
      ...byId(caseArr, ['id']),
    };
  }),

  /**
   * close case
   * @deprecated - `useCaseClose()` instead
   * */
  closeCaseById: thunk(async (actions, { caseId, assetId }) => {
    const closedCase = await closeCaseById(caseId, assetId);
    if (closedCase.data === 'UPDATE Successful') {
      actions.fetchCaseWithDetails(caseId);
      return true;
    }
    return false;
  }),

  // single task
  addTaskToCase: action((state, { caseId, taskId }) => {
    const toModify = state.cases[caseId];
    if (toModify) {
      toModify.taskIds = [...toModify.taskIds, taskId];
      state.cases = {
        ...state.cases,
        [toModify.id]: toModify,
      };
    }
  }),

  // multiple tasks
  addTasksToCase: action((state, { caseId, taskIds }) => {
    const toModify = state.cases[caseId];
    if (toModify) {
      toModify.taskIds = [...toModify.taskIds, ...taskIds];
      state.cases = {
        ...state.cases,
        [toModify.id]: toModify,
      };
    }
  }),
};

// Computed values
const casesComputed = {
  getEntityPanelCase: computed(
    [
      (state) => state.cases,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.assets.assets,
      (_, storeState) => storeState.alerts.alerts,
    ],
    (cases, sites, assets, alerts) =>
      memoize(10)((caseId) => {
        const panelCase = cases[caseId];
        if (!panelCase) return null;

        const caseAsset = assets[panelCase.asset.id];
        if (!caseAsset) return null;
        const site = sites[caseAsset.site.id];
        // TODO - mocking data for now
        const asset = {
          state: AssetState.ONLINE,
          wind: 12.1,
          temp: 27,
          ...assets[panelCase.asset.id],
        };

        const { childCaseIds = [] } = panelCase;
        const childCases = removeFalsyVals(childCaseIds?.map((caseId) => cases[caseId]) || []);

        const mappedAlerts = [panelCase, ...childCases].reduce(
          (acc, c) => [
            ...acc,
            ...(c?.alertIds?.map((id) => ({ id, ...alerts[id], caseId: c.id })) || []),
          ],
          [],
        );

        return {
          ...panelCase,
          site,
          asset,
          childCaseIds,
          childCases,
          alerts: mappedAlerts,
          tasks: [], // TODO - get from task store once data is linked,
        };
      }, 10),
  ),
  getCasesForSite: computed(
    [
      (state) => state.cases,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.assets.assets,
    ],
    (cases, sites, assets) =>
      memoize(10)((siteId, sortMetric, sortDirection) => {
        const siteAssetIds = Object.values(assets)
          .filter((a) => a?.site?.id === siteId)
          .map((a) => a.id);
        const siteCases = Object.values(cases).filter((c) => siteAssetIds.includes(c.asset.id));
        return siteCases
          .map((c) => ({
            ...c,
            site: mapObject(sites[siteId], ['id', 'name']),
            asset: mapObject(assets[c.asset.id], ['id', 'name']),
          }))
          .sort(sorter(sortMetric, sortDirection));
      }),
  ),
  getChildCaseIds: computed([(state) => state.cases], (cases) =>
    memoize(10)(() => {
      const accumulatedChildCaseIds = Object.values(cases)
        .filter((item) => item?.parentId)
        .map((item) => item?.id);
      return accumulatedChildCaseIds;
    }),
  ),
};

const caseModel = {
  ...defaultCasesState,
  ...casesActions,
  ...casesComputed,
};

export default caseModel;
