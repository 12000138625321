import { useState, useCallback } from 'react';

export const useROCActionDialog = () => {
  const [rocLogsDialog, setRocLogsDialog] = useState(false);
  const handleAddRocLogs = useCallback(() => {
    setRocLogsDialog(false);
  }, [setRocLogsDialog]);

  return {
    rocLogsDialog,
    setRocLogsDialog,
    handleAddRocLogs,
  };
};
