import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme, css } from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';

const parentRowDisable = css`
  opacity: 0.7;
`;

const ListItem = styled.li`
  ${({ isDisabled }) => {
    if (isDisabled) return parentRowDisable;
  }}
`;

export const PersonAssetsSiteItem = withTheme(({ theme, item }) => {
  const { viewByServiceGroupsOrSites, activeTab, selectedSiteIds, selectedServiceGroupIds } =
    useStoreState((state) => state.tabflow.personAssets);

  const { setCheckBox } = useStoreActions((actions) => actions.tabflow);
  const getCheckedState = (item) => {
    const childArray = item.serviceGroups;
    if (childArray?.some((childId) => selectedServiceGroupIds?.includes(childId))) {
      return CheckedState.UNCHECKED;
    }
    return selectedSiteIds?.includes(item.id) ? CheckedState.CHECKED : CheckedState.UNCHECKED;
  };

  const getDisabledState = (item) => {
    const childArray = item.serviceGroups;
    if (childArray?.some((childId) => selectedServiceGroupIds?.includes(childId))) {
      return true;
    }
    if (selectedSiteIds?.includes(item.id)) {
      return false;
    }
    return false;
  };

  const ChangeCheckBoxState = (id, checkBoxState) => {
    setCheckBox({
      id: id,
      permission: { idsIndex: activeTab, viewBy: viewByServiceGroupsOrSites },
      currentState: checkBoxState,
    });
  };
  return (
    <ListItem key={item.name} isDisabled={getDisabledState(item)}>
      <div className="person-assets-sites-list-container">
        <Checkbox
          checkState={getCheckedState(item)}
          onChange={(e) => ChangeCheckBoxState(item.id, e)}
          disabled={getDisabledState(item)}
        />

        <div>
          <Icon icon={Icons.TURBINE} size={11} color={theme.admin.panel.entityRow.icon} />
        </div>
        <div className="person-assets-assets-text-margin">{item.name}</div>
      </div>
    </ListItem>
  );
});
PersonAssetsSiteItem.propTypes = {
  item: PropTypes.object,
};
