import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
import { ConfirmationDialog } from '@ge/components/modal';
import { EntityType } from '@ge/models/constants';

import EntityDetailHeader, { HeaderIcon, TypeIcon } from '../entity-details-header';

const HeaderButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const RoleInfo = styled.div`
  display: block;
  margin-left: 10px;
`;

const RoleTitle = styled.div`
  display: block;
  font-size: 22px;
  font-weight: bold;
`;

const RoleDescription = styled.div`
  display: block;
  font-size: 11px;
  margin-top: 5px;
`;

export const RoleDetailsHeader = withRouter(({ roleDetails, handleEditClick }) => {
  const { t } = useTranslation(['entity-details'], { useSuspense: false });
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

  if (!roleDetails) {
    return null;
  }

  const handleDisableClick = () => {
    return null;
  };

  const handleDeleteConfirm = () => {
    // insert delete logic here
    setShowDeleteConfirmationDialog(false);
  };

  return (
    <EntityDetailHeader type={EntityType.ROLE}>
      <div className="left">
        <div className="related">{roleDetails?.tenant?.name}</div>
        <div className="details">
          <div className="left">
            <TypeIcon icon={Icons.PERSON} />
          </div>
          <div className="right">
            <div className="title">
              <RoleInfo>
                <RoleTitle>{roleDetails?.role}</RoleTitle>
                <RoleDescription>{roleDetails?.description}</RoleDescription>
              </RoleInfo>
            </div>
          </div>
        </div>
      </div>

      <div className="actions">
        <HeaderButton onClick={handleDisableClick}>
          <HeaderIcon icon={Icons.CLOSE} /> {t('disable_role', 'disable')}
        </HeaderButton>
        <HeaderButton onClick={handleEditClick}>
          <HeaderIcon icon={Icons.PENCIL} /> {t('edit_role', 'edit')}
        </HeaderButton>
        <HeaderButton onClick={() => setShowDeleteConfirmationDialog(true)}>
          <HeaderIcon icon={Icons.TRASH} /> {t('delete_role', 'delete')}
        </HeaderButton>
      </div>

      <ConfirmationDialog
        contentWidth
        header={t('confirm_delete_header', 'Confirmation')}
        isOpen={showDeleteConfirmationDialog}
        padContent={true}
        onCancel={() => setShowDeleteConfirmationDialog(false)}
        onConfirm={handleDeleteConfirm}
        confirmLabel={t('confirm_delete', 'Delete')}
        cancelLabel={t('confirm_delete_cancel', 'Cancel')}
      >
        <p>{t('confirm_delete_message', 'Are you sure?')}</p>
      </ConfirmationDialog>
    </EntityDetailHeader>
  );
});

RoleDetailsHeader.propTypes = {
  roleDetails: PropTypes.instanceOf(Object),
};

RoleDetailsHeader.defaultProps = {
  roleDetails: null,
};

export default RoleDetailsHeader;
