/* eslint-disable camelcase */
import merge from 'deepmerge';
import { action, computed, thunk, actionOn } from 'easy-peasy';
import memoize from 'memoizerific';
import { svgAsPngUri } from 'save-svg-as-png';

import { DateRange } from '@ge/feat-inspections/models/date-filter-options';
import { lastThreeMonths } from '@ge/feat-inspections/util';
import { getDataFilter } from '@ge/shared/util/data-filter';
import { sorter } from '@ge/util/metric-sorter';

import {
  getImage,
  getVideos,
  updateAnnotation,
  postJob,
  getAllJobs,
} from '../services/inspections';
import { generateAnnotatedImageName, calculateStageStatus } from '../util';

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

const dateRange = lastThreeMonths();

const defaultDateRange = {
  range: DateRange.LAST_3_MONTHS,
  startDate: dateRange.startDate,
  endDate: dateRange.endDate,
};

// Define initial state
const defaultInspectionsState = {
  wsConn: null,
  inspections: {},
  inspectDateRange: defaultDateRange,
  stageStatus: null,
  selectedInspectionId: null,
  selectedVideoId: null,
  selectedBladeIndex: 1,
  selectedImageId: null,
  selectedAnnotationId: null,
  isLoading: false,
  hiddenAnnotations: {},
  ruler: {
    points: [],
    length: 0,
    distance: 0,
    scaling: 0,
  },
};

// helper functions
const saveSvgElementAsPng = ({
  svgEl,
  polygonId,
  width,
  height,
  annotated_imgname,
  getStoreActions,
}) => {
  const newAddedAnnotationIndex = Array.from(svgEl.childNodes).findIndex(
    (n) => n.id === `annotation_${polygonId}`,
  );
  const newAddedAnnotation = svgEl.removeChild(svgEl.childNodes[newAddedAnnotationIndex]);
  newAddedAnnotation.setAttribute('stroke-width', 3);
  newAddedAnnotation.setAttribute('stroke', '#ff0000');
  svgEl.innerHTML = '';
  svgEl.appendChild(newAddedAnnotation);
  svgEl.setAttribute('width', width);
  svgEl.setAttribute('height', height);
  svgEl.removeAttribute('style');

  svgAsPngUri(svgEl).then((uri) => {
    fetch(uri)
      .then((res) => res.blob())
      .then((blob) => {
        getStoreActions()
          .files.getPresignedUrl({
            files: [annotated_imgname],
            type: 'put',
          })
          .then((signedUrls) => {
            getStoreActions().files.putFilesByPresignedUrl({
              presignedUrl: signedUrls[0].url,
              fileData: blob,
            });
          });
      });
  });
};

const updateInspectionProperties = (state, inspection, storeState) => {
  const sitesData = storeState.sites.sites;

  const { users } = storeState.profile;
  const {
    executor_lid,
    inspector_lid,
    asset_site_id,
    stage_status,
    // turbinetype_lid,
    turbine_model,
    uploader_lid,
    workflow_stage,
    uploader: LS_uploader,
    inspector: LS_inspector,
  } = inspection.attributes;

  const siteName = sitesData[asset_site_id].name || '';
  const turbineType = turbine_model || '';
  const retrieveUserName = (id) => {
    const user = users[id];
    return !user ? '' : `${user.firstname} ${user.lastname}`;
  };
  const uploader = LS_uploader ? LS_uploader : retrieveUserName(uploader_lid);
  const inspector = LS_inspector ? LS_inspector : retrieveUserName(inspector_lid);
  const executor = retrieveUserName(executor_lid);

  const stageStatus = calculateStageStatus(workflow_stage, stage_status);

  const newInspection = {
    ...inspection.attributes,
    id: inspection.id,
    turbineType,
    siteName,
    uploader,
    inspector,
    executor,
    scan_date_timeStamp: new Date(inspection.attributes.scan_date).getTime(),
    stages: { ...stageStatus },
    uploadStage: stageStatus.upload,
    executionStage: stageStatus.execution,
    postProcessStage: stageStatus.post_process,
    reportGenerationStage: stageStatus.report_generation,
  };
  const existingInspection = state.inspections[inspection.id];

  state.inspections[inspection.id] = !existingInspection
    ? newInspection
    : merge(existingInspection, newInspection, { arrayMerge: overwriteMerge });
};

// Actions
const inspectionsActions = {
  clearInspectionData: action((state) => {
    state.inspections = [];
  }),

  updateLoadingStatus: action((state, payload) => {
    const { isLoading } = payload;
    state.isLoading = isLoading;
  }),

  deleteInspectionRow: action((state, payload) => {
    const { jobId } = payload;
    delete state.inspections[jobId];
  }),

  updateDateFilter: action((state, payload = defaultInspectionsState.inspectDateRange) => {
    const { endDate, startDate, range } = payload;
    if (startDate && endDate && range) {
      state.inspectDateRange = { endDate, startDate, range };
    }
  }),

  setSiteNames: action((state, payload) => {
    state.siteNames = payload;
  }),

  setTurbineTypes: action((state, payload) => {
    state.turbineTypes = payload;
  }),

  // function to update jobs/inspections table entry for a specific job ID
  postUpdateInspectionJob: thunk(async (actions, payload) => {
    const { jobId, data } = payload;
    const {
      auto_execute,
      blade_serial_number,
      create_date,
      datashark_version_id,
      executioncompletion_date,
      executor_lid,
      inspection_type,
      inspector_lid,
      numberof_defectimages,
      numberof_images,
      numberof_videos,
      postprocessingcompletion_date,
      reportcompletion_date,
      s3,
      scan_date,
      site_lid,
      stage_status,
      turbine_cod,
      turbine_number,
      turbinetype_lid,
      uploadcompletion_date,
      uploader_lid,
      workflow_stage,
    } = data;
    const newData = {
      auto_execute,
      blade_serial_number,
      create_date,
      datashark_version_id,
      executioncompletion_date,
      executor_lid,
      inspection_type,
      inspector_lid,
      numberof_defectimages,
      numberof_images,
      numberof_videos,
      postprocessingcompletion_date,
      reportcompletion_date,
      s3,
      scan_date,
      site_lid,
      stage_status,
      turbine_cod,
      turbine_number,
      turbinetype_lid,
      uploadcompletion_date,
      uploader_lid,
      workflow_stage,
    };
    const options = {};
    const { status } = await postJob(jobId, newData, options);

    return new Promise((resolve) => {
      resolve(status);
    });
  }),

  createAnnotation: thunk(async (actions, payload, { getStoreActions }) => {
    const { flagNew, data, svgMetadata, currentImage } = payload;
    const options = {};
    const { polygonId } = svgMetadata;
    const origSvgEl = document.querySelector(`#image${data.object_id}`);
    const svgEl = origSvgEl.cloneNode(true);
    if (svgEl.childElementCount === 0) {
      console.log('svg creation failed.');
      return;
    }
    actions.updateLoadingStatus({ isLoading: true });
    const { id: annotationId } = await updateAnnotation(flagNew, data, options);
    const annotated_imgname = generateAnnotatedImageName({ img: currentImage, annotationId });
    const { height, width } = currentImage.attributes;

    await updateAnnotation(
      0,
      {
        ...data,
        annotated_imgname,
        included_in_report: true,
        id: +annotationId,
      },
      options,
    );
    actions.updateLoadingStatus({ isLoading: false });
    // Get the annotation svg, remove all other annotations but the new created one
    saveSvgElementAsPng({
      svgEl,
      polygonId,
      width,
      height,
      annotated_imgname,
      getStoreActions,
      annotationId,
    });

    return new Promise((resolve) => {
      resolve({ id: +annotationId, annoAttrs: data });
    });
  }),

  resetInspections: action((state) => {
    state = Object.assign(state, defaultInspectionsState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.inspections = defaultInspectionsState.inspections;
  }),

  fetchAllInspections: thunk(async (actions, payload, { getStoreState }) => {
    const { globalFilters, endDate, startDate } = payload;
    const options = {};
    actions.updateLoadingStatus({ isLoading: true });
    const filters = globalFilters;
    const { result } = await getAllJobs(10000, { filters, endDate, startDate }, options);
    actions.updateLoadingStatus({ isLoading: false });
    actions.updateInspections({ data: result.data, storeState: getStoreState() });
  }),

  fetchImageDetails: thunk(async (actions, payload) => {
    const { imageId } = payload;
    const options = {};
    actions.updateLoadingStatus({ isLoading: true });
    const { result } = await getImage(imageId, options);
    actions.updateLoadingStatus({ isLoading: false });
    return new Promise((resolve) => {
      resolve(result.data);
    });
  }),

  fetchVideoList: thunk(async (actions, payload) => {
    const { jobId } = payload;
    const options = {};
    actions.updateLoadingStatus({ isLoading: true });
    const { results } = await getVideos(jobId, options);
    actions.updatePropertiesByInspectionId({
      jobId,
      data: results?.data.sort((a, b) =>
        a?.attributes.videoname >= b?.attributes.videoname ? 1 : -1,
      ),
      field: 'videos',
    });
    actions.updateLoadingStatus({ isLoading: false });
  }),

  updatePropertiesByInspectionId: action((state, payload) => {
    const { jobId, field, data } = payload;
    const existingInspection = state.inspections[jobId];
    let newInspection = null;
    if (typeof data === 'string') {
      newInspection = {
        ...existingInspection,
        [field]: data,
      };
    } else if (Array.isArray(data)) {
      newInspection = {
        ...existingInspection,
        [field]: [...data],
      };
    } else {
      newInspection = {
        ...existingInspection,
        [field]: { ...data },
      };
    }
    state.inspections[jobId] = merge(existingInspection, newInspection, {
      arrayMerge: overwriteMerge,
    });
  }),

  updateInspections: action((state, payload) => {
    const { data, storeState } = payload;

    data.forEach((inspection) => {
      updateInspectionProperties(state, inspection, storeState);
    });
  }),

  updateInspectionProperties: action((state, payload) => {
    const { inspection, storeState } = payload;
    updateInspectionProperties(state, inspection, storeState);
  }),

  updateSelectedInspectionId: action((state, payload) => {
    const { id } = payload;
    state.selectedInspectionId = id;
  }),
};

// Listeners
const inspectionsListeners = {
  onSiteNamesFetched: actionOn(
    (_, storeActions) => storeActions.app.setSiteNames,
    (state, target) => {
      const ids = Object.keys(state.inspections);
      const siteNames = target.payload.reduce((s, c) => {
        const { id } = c;
        return { ...s, [id]: c.attributes.sitename };
      }, {});
      for (let i = 0, l = ids.length - 1; i <= l; i += 1) {
        const { site_lid } = state.inspections[ids[i]];
        state.inspections[ids[i]].siteName = siteNames[site_lid];
      }
    },
  ),
  onTurbineTypesFetched: actionOn(
    (_, storeActions) => storeActions.app.setTurbineTypes,
    (state, target) => {
      const ids = Object.keys(state.inspections);
      const turbineTypes = target.payload.reduce((s, c) => {
        const { id } = c;
        return { ...s, [id]: c.attributes.turbinetype };
      }, {});
      for (let i = 0, l = ids.length - 1; i <= l; i += 1) {
        const { turbinetype_lid } = state.inspections[ids[i]];
        state.inspections[ids[i]].turbineType = turbineTypes[turbinetype_lid];
      }
    },
  ),
  onUsersFetched: actionOn(
    (_, storeActions) => storeActions.profile.setUsers,
    (state, target) => {
      const ids = Object.keys(state.inspections);
      const users = target.payload.reduce((s, c) => {
        const { id } = c;
        return { ...s, [id]: `${c.attributes.firstname} ${c.attributes.lastname}` };
      }, {});
      for (let i = 0, l = ids.length - 1; i <= l; i += 1) {
        const { uploader_lid, inspector_lid, executor_lid, uploader, inspector } =
          state.inspections[ids[i]];
        uploader ? uploader : (state.inspections[ids[i]].uploader = users[uploader_lid]);
        inspector ? inspector : (state.inspections[ids[i]].inspector = users[inspector_lid]);
        state.inspections[ids[i]].executor = users[executor_lid];
      }
    },
  ),
};

// Computed values
const inspectionsComputed = {
  getInspectionsAsList: computed((state) => () => Object.values(state.inspections)),
  getSortedInspections: computed(
    [(state) => state, (state, storeState) => storeState.view.currentView],
    (state) =>
      memoize(2)((sortMetric, sortDirection, filters) => {
        const data = state.getInspectionsAsList();

        const dataFilter = getDataFilter({ data, filters });

        return {
          ...dataFilter,
          data: dataFilter.data?.sort(sorter(sortMetric, sortDirection)) ?? [],
        };
      }),
  ),
};

const inspectionsModel = {
  ...defaultInspectionsState,
  ...inspectionsActions,
  ...inspectionsListeners,
  ...inspectionsComputed,
};

export default inspectionsModel;
