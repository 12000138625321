import { DateRange, SortDirection, TimeAggr } from '@ge/models/constants';

// to support various types of pre-defined date ranges and frequencies in the app
export const RangeDefs = {
  RANGE: 'range',
  RANGE_BY_MONTH: 'range_by_month',
  RANGE_BY_MONTH_CURRENT: 'range_by_month_current',
};

export const FilterDefs = {
  ...RangeDefs,
  AVAILABILITY: 'availability',
  PERFORMANCE: 'performance',
  GE_INTERNAL: 'ge_internal',
  RELIABILITY: 'reliability',
  DATA_QUALITY: 'data_quality',
  GROUPING: 'grouping',
  SORT: 'sort',
  CUSTOM: 'custom',
  SHOW_HIDE_ENTITIES: 'show_hide_entities',
  SHOW_ALL: 'showAll',
  MAX_ENTITIES: 30,
  MAX_SELECTED_ENTITIES: 10,
  TIME_AGGREGATION: 'time_aggregation',
};

export const FilterOptions = {
  ANALYTIC: [
    {
      label: 'Availability',
      key: FilterDefs.AVAILABILITY,
      a11yKey: `filters.${FilterDefs.AVAILABILITY}`,
    },
    {
      label: 'Data Quality',
      key: FilterDefs.DATA_QUALITY,
      a11yKey: `filters.${FilterDefs.DATA_QUALITY}`,
    },
    // #327 - removing for MVP0
    // {
    //   label: 'GE Internal',
    //   key: FilterDefs.GE_INTERNAL,
    // },
    {
      label: 'Performance',
      key: FilterDefs.PERFORMANCE,
      a11yKey: `filters.${FilterDefs.PERFORMANCE}`,
    },
    // #327 - removing for MVP0
    // temporarily removing since there are no options
    // TODO: add back in when we wire up reliability service
    // {
    //   label: 'Reliability',
    //   key: FilterDefs.RELIABILITY,
    // },
  ],
  [FilterDefs.AVAILABILITY]: [
    {
      label: 'TBA - Technical',
      key: 'tba',
      a11yKey: `filters.tba`,
    },
    {
      label: 'PBA - Technical',
      key: 'pba',
      a11yKey: `filters.pba`,
    },
    {
      label: 'Contractual Availability',
      key: 'contractual_availability',
      a11yKey: `filters.contractual_availability`,
    },
  ],
  [FilterDefs.PERFORMANCE]: [
    {
      label: 'Actual Production',
      key: 'actual_production',
      a11yKey: `filters.actual_production`,
    },
    {
      label: 'Actual vs Expected Production',
      key: 'actual_vs_expected_production',
      a11yKey: `filters.actual_vs_expected_production`,
    },
    {
      label: 'Capacity Factor',
      key: 'capacity_factor',
      a11yKey: `filters.capacity_factor`,
    },
    {
      label: 'Expected Production',
      key: 'expected_production',
      a11yKey: `filters.expected_production`,
    },
    {
      label: 'Lost Production',
      key: 'lost_production',
      a11yKey: `filters.lost_production`,
    },
    // #327 - removing for MVP0
    // {
    //   label: 'Production Ratio',
    //   key: 'production_ratio',
    // },
  ],
  [FilterDefs.GE_INTERNAL]: [
    {
      label: 'PPI – Full Production',
      key: 'ppi_full_production',
      a11yKey: `filters.ppi_full_production`,
    },
    {
      label: 'PPI – Unproduced Energy',
      key: 'ppi_unproduced_energy',
      a11yKey: `filters.ppi_unproduced_energy`,
    },
    {
      label: 'PPI – Bank',
      key: 'ppi_bank',
      a11yKey: `filters.ppi_bank`,
    },
  ],
  [FilterDefs.RELIABILITY]: [
    // #327 - removing for MVP0
    // {
    //   label: 'Mean Time between Forced Outage ( MTBFO )',
    //   key: 'mean_time_forced_outage',
    // },
    // #327 - removing for MVP0
    // {
    //   label: 'Mean Time to Repair ( MTTR )',
    //   key: 'mean_time_repair',
    // },
  ],
  [FilterDefs.DATA_QUALITY]: [
    {
      label: 'Event Coverage',
      key: 'event_coverage',
      a11yKey: `filters.event_coverage`,
    },
    // #DE170238 - removing for future implementation
    // {
    //   label: 'Reporting Turbines Ratio',
    //   key: 'reporting_turbine_ratio',
    //   a11yKey: `filters.reporting_turbine_ratio`,
    // },
  ],
  [FilterDefs.GROUPING]: [
    {
      label: 'By Region',
      key: 'by_region',
      a11yKey: `filters.by_region`,
    },
    {
      label: 'By Platform',
      key: 'by_platform',
      a11yKey: `filters.by_platform`,
    },
    {
      label: 'By Customer',
      key: 'by_customer',
      a11yKey: `filters.by_customer`,
    },
  ],
  [FilterDefs.RANGE]: [
    {
      label: 'Week to date',
      key: DateRange.WEEK_TO_DATE,
      a11yKey: `filters.${DateRange.WEEK_TO_DATE}`,
    },
    {
      label: 'Last 7 days',
      key: DateRange.LAST_7_DAYS,
      a11yKey: `filters.${DateRange.LAST_7_DAYS}`,
    },
    {
      label: 'Month to date',
      key: DateRange.MONTH_TO_DATE,
      a11yKey: `filters.${DateRange.MONTH_TO_DATE}`,
    },
    {
      label: 'Last 30 days',
      key: DateRange.LAST_30_DAYS,
      a11yKey: `filters.${DateRange.LAST_30_DAYS}`,
    },
    // {
    //   label: 'Last 90 days',
    //   key: DateRange.LAST_90_DAYS,
    //   a11yKey: `filters.${DateRange.LAST_90_DAYS}`,
    // },
    {
      label: 'Custom',
      key: DateRange.CUSTOM,
      a11yKey: `filters.${DateRange.CUSTOM}`,
    },
  ],
  [FilterDefs.RANGE_BY_MONTH]: [
    {
      label: 'Year to date',
      key: DateRange.YEAR_TO_DATE,
      a11yKey: `filters.${DateRange.YEAR_TO_DATE}`,
    },
    {
      label: 'Last 12 months',
      key: DateRange.LAST_12_MONTHS,
      a11yKey: `filters.${DateRange.LAST_12_MONTHS}`,
    },
    {
      label: 'Custom',
      key: DateRange.CUSTOM,
      a11yKey: `filters.${DateRange.CUSTOM}`,
    },
  ],
  [FilterDefs.RANGE_BY_MONTH_CURRENT]: [
    {
      label: 'Current Day',
      key: DateRange.CURRENT_DAY,
      a11yKey: `filters.${DateRange.CURRENT_DAY}`,
    },
    {
      label: 'Last Day',
      key: DateRange.LAST_DAY,
      a11yKey: `filters.${DateRange.LAST_DAY}`,
    },
    {
      label: 'Next Day',
      key: DateRange.NEXT_DAY,
      a11yKey: `filters.${DateRange.NEXT_DAY}`,
    },
    {
      label: 'Last Week',
      key: DateRange.LAST_WEEK,
      a11yKey: `filters.${DateRange.LAST_WEEK}`,
    },
    {
      label: 'Next Week',
      key: DateRange.NEXT_WEEK,
      a11yKey: `filters.${DateRange.NEXT_WEEK}`,
    },
    {
      label: 'Last Month',
      key: DateRange.LAST_MONTH,
      a11yKey: `filters.${DateRange.LAST_MONTH}`,
    },
    {
      label: 'Custom',
      key: DateRange.CUSTOM,
      a11yKey: `filters.${DateRange.CUSTOM}`,
    },
  ],
  CURVE: {
    keys: [
      'actual_vs_expected_production',
      'expected_production',
      'lost_production',
      'unproduced_energy',
      'ppi_full_production',
      'ppi_unproduced_energy',
      'ppi_bank',
    ],
    options: [
      {
        label: 'Contract Curve',
        key: 'contract_curve',
        a11yKey: `filters.contract_curve`,
      },
      // #327 - removing for MVP0
      // {
      //   label: 'Learned Curve',
      //   key: 'learned_curve',
      // },
    ],
  },
  [FilterDefs.SORT]: [
    {
      label: 'Ascending',
      value: SortDirection.ASC,
      key: SortDirection.ASC,
      a11yKey: `filters.ascending`,
    },
    {
      label: 'Descending',
      value: SortDirection.DESC,
      key: SortDirection.DESC,
      a11yKey: `filters.descending`,
    },
  ],
  [FilterDefs.TIME_AGGREGATION]: {
    [TimeAggr.DAILY]: {
      label: 'Daily',
      value: TimeAggr.DAILY,
      key: TimeAggr.DAILY,
      a11yKey: 'filters.daily',
    },
    [TimeAggr.WEEKLY]: {
      label: 'Weekly',
      value: TimeAggr.WEEKLY,
      key: TimeAggr.WEEKLY,
      a11yKey: 'filters.weekly',
    },
    [TimeAggr.MONTHLY]: {
      label: 'Monthly',
      value: TimeAggr.MONTHLY,
      key: TimeAggr.MONTHLY,
      a11yKey: 'filters.monthly',
    },
  },
  [FilterDefs.SHOW_HIDE_ENTITIES]: [
    {
      value: undefined,
      label: 'Show all',
      key: FilterDefs.SHOW_ALL,
      a11yKey: `kpi_chart.show_entities`,
    },
    {
      value: FilterDefs.MAX_ENTITIES,
      label: 'Bottom',
      key: SortDirection.ASC,
      a11yKey: `kpi_chart.sort_bottom`,
    },
    {
      value: FilterDefs.MAX_ENTITIES,
      label: 'Top',
      key: SortDirection.DESC,
      a11yKey: `kpi_chart.sort_top`,
    },
  ],
};

export const DefaultPrimaryGroupDef = FilterDefs.AVAILABILITY;
export const DefaultRange = DateRange.LAST_7_DAYS;
export const DefaultRangeByMonth = DateRange.YEAR_TO_DATE;
export const DefaultRangeByMonthCurrent = DateRange.CURRENT_DAY;
