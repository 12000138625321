import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CardStatus } from './card-status';

const ChartWrapper = styled.div`
  padding: 0 10px 6px;
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
`;

export const SiteStatusCard = ({
  assetCount,
  offline,
  availability,
  outputPercent,
  hourTotal24,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <CardStatus speed={assetCount} offline={offline} />
      <ChartWrapper>
        <div>
          <h5>{t('availability', 'Availability')}</h5>
          {availability}
        </div>
        <div>
          <h5>{t('output', 'Output')}</h5>
          {outputPercent}
        </div>
        <div>
          <h5>{t('24hr_total', '24hr Total')}</h5>
          {hourTotal24}
        </div>
      </ChartWrapper>
    </div>
  );
};

SiteStatusCard.propTypes = {
  assetCount: PropTypes.number,
  offline: PropTypes.number,
  availability: PropTypes.element,
  outputPercent: PropTypes.element,
  hourTotal24: PropTypes.element,
};

SiteStatusCard.defaultProps = {
  assetCount: 0,
  offline: 0,
  availability: null,
  outputPercent: null,
  hourTotal24: null,
};
