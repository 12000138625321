import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens/typography';
import { toLocaleKey } from '@ge/util/string-utils';

import { CommandsDialogBulkList } from './command-dialog-bulk-list';
import { CommandsDialogHistory } from './command-dialog-history';

const DialogContainer = styled.div`
  width: 460px;
  overflow: hidden;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  text-align: right;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const AssetDetails = styled.div`
  display: flex;
  margin-top: 6px;
  .spacer {
    margin: 0 8px;
    font-weight: bold;
  }
`;

const StyledHeader = styled.div`
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  align-items: flex-start;
  padding: 0;
`;

const TurbineIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.TURBINE,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const SiteIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SITE,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const HeaderText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 1,
}))``;

export const CommandsDialog = ({
  assets,
  onClose,
  onAbort,
  onExecute,
  command,
  status,
  counts,
  isExecuting,
  isGroupCommandComplete,
}) => {
  const { t } = useTranslation(['entity-details']);

  const [abortDisabled, setAbortDisabled] = useState(false);

  const isSingleCommand = assets?.length === 1;

  const handleExecute = useCallback(() => {
    setAbortDisabled(false);
    // Todo: see if we need to be passed from the data hook
    onExecute();

    if (isSingleCommand) {
      onClose();
    }
  }, [isSingleCommand, onClose, onExecute]);

  const handleAbort = useCallback(async () => {
    await onAbort();
    setAbortDisabled(true);
  }, [onAbort]);

  const getFooter = useMemo(() => {
    return (
      <FooterButtons>
        {isGroupCommandComplete ? (
          <>
            <Button onClick={onClose}>{t('general:close', 'Close')}</Button>
            <Button primary disabled={true} onClick={handleAbort}>
              {t('general:abort', 'Abort')}
            </Button>
          </>
        ) : isExecuting ? (
          <>
            <Button onClick={onClose}>{t('general:close', 'Close')}</Button>
            <Button primary disabled={abortDisabled} onClick={handleAbort}>
              {t('general:abort', 'Abort')}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
            <Button primary onClick={handleExecute}>
              {t('general:execute', 'Execute')}
            </Button>
          </>
        )}
      </FooterButtons>
    );
  }, [onClose, t, handleExecute, handleAbort, isExecuting, abortDisabled, isGroupCommandComplete]);

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onExecute}
      header={`${t(`commands.${toLocaleKey(command?.desc)}`, command?.desc ?? '')} Confirmation`}
      footer={getFooter}
      contentWidth
    >
      <DialogContainer>
        <HeaderText>
          {t('general:commands.attempting_msg', 'You are attempting to')}:{' '}
          <b>{t(`commands.${toLocaleKey(command?.desc)}`, command?.desc ?? '')}</b>
        </HeaderText>

        <AssetDetails>
          <StyledHeader onClick={() => {}}>
            <SiteIcon />
            <HeaderText>
              <b>{assets[0].site.name}</b>
            </HeaderText>
          </StyledHeader>
          <HeaderText className="spacer">{'/'}</HeaderText>
          <StyledHeader onClick={() => {}}>
            <TurbineIcon />
            <HeaderText>
              <b>{isSingleCommand ? assets[0].name : `${assets.length} Assets`}</b>
            </HeaderText>
          </StyledHeader>
        </AssetDetails>

        {isSingleCommand ? (
          <CommandsDialogHistory asset={assets[0]} />
        ) : (
          <CommandsDialogBulkList
            status={status}
            counts={counts}
            setAbortDisabled={setAbortDisabled}
          />
        )}
      </DialogContainer>
    </Dialog>
  );
};

CommandsDialog.propTypes = {
  assets: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func,
  onAbort: PropTypes.func,
  onExecute: PropTypes.func,
  command: PropTypes.object.isRequired,
  history: PropTypes.instanceOf(Object),
  status: PropTypes.instanceOf(Array),
  counts: PropTypes.instanceOf(Object),
  isExecuting: PropTypes.bool,
  isGroupCommandComplete: PropTypes.bool,
};

CommandsDialog.defaultValues = {
  onClose: () => null,
  onExecute: () => null,
  onAbort: () => null,
  history: null,
  status: null,
  counts: {},
  isExecuting: false,
  isGroupCommandComplete: false,
};
