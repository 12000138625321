import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  QueryKey,
  AlertStatus,
  AssetType,
  AlertsEntityType,
  Capability,
  PermissionScope,
  AlertStatusCodeMapping,
  EntityType,
  MonitorCaseStatus,
  CasePatchCallStatus,
  CaseTypeLabel,
  SystemResetStatus,
  CaseType,
  AssetConnectionStatus,
  CaseQueueAssetStateFilter,
  ActiveCaseRibbonFilterTypes,
  MonitorCaseRibbonFilterTypes,
  AssetStatus,
} from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';
import { useStorageInverters } from '@ge/shared/data-hooks/use-storage-inverters';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { sorter } from '@ge/util/metric-sorter';

import { getFixed } from '../../util/number-utils';
import {
  fetchCaseQueue,
  changeCasesStatus,
  editCaseQueue,
  changeBulkCasesStatus,
} from '../services/cases';
import { getMonitorAlertsForAssets } from '../services/monitor-alerts';
import { getActiveSIsForSiteAndAssets } from '../services/notes';

const mockTasks = [
  {
    id: 'TA-2844',
    type: 'Outage',
    title: 'Vok subjuna ceosave mu.',
    source: 'ROC | peza',
    createdDate: '2021-02-08T18:51:00.425Z',
    due: 'immediate',
    status: 'unscheduled',
  },
  {
    id: 'TA-2788',
    type: 'Outage',
    title: 'Mup zelle la telpoma.',
    source: 'ROC | av',
    createdDate: '2021-04-19T23:48:08.356Z',
    due: 'immediate',
    status: 'unscheduled',
  },
];

const getAllAssetIdsForCases = (arr) => arr.map((elem) => elem.asset_id || elem.site_id);

const getAllSiteAndAssetForCases = (arr) => {
  const siteIds = [],
    assetIds = [];
  if (Array.isArray(arr)) {
    arr.forEach((elem) => {
      if (elem.caseLevel === EntityType.ASSET) {
        assetIds.push(elem.asset_id);
      } else if (elem.caseLevel === EntityType.SITE) {
        siteIds.push(elem.site_id);
      }
    });
    if (siteIds.length || assetIds.length) {
      return { siteIds: [...new Set(siteIds)], assetIds: [...new Set(assetIds)] };
    }
  }

  return null;
};

const filterSpecialInstructions = (caseLevel, SIs, siteId, assetId) => {
  let specialInstructions = [];
  const { siteSIs, assetSIs } = SIs;
  if (caseLevel === EntityType.ASSET && Array.isArray(assetSIs)) {
    specialInstructions = assetSIs.filter(({ domainId }) => domainId === assetId);
  } else if (caseLevel === EntityType.SITE && Array.isArray(siteSIs)) {
    specialInstructions = siteSIs.filter(({ domainId }) => domainId === siteId);
  }

  return specialInstructions;
};

const entityTypeMapping = {
  [AssetType.WIND_TURBINE]: AlertsEntityType.WIND_TURBINE,
  [AssetType.SUBSTATION]: AlertsEntityType.SUBSTATION,
  [AssetType.SITE_CONTROLLER]: AlertsEntityType.WIND_SITE_CONTROLLER,
  [AssetType.STORAGE_INVERTER]: AlertsEntityType.INVERTER,
};

export const useMonitorCases = ({
  tableType,
  sortDirection,
  sortMetric,
  filters,
  search,
  selectedRibbonFilter,
  updateRibbonFilterCounts,
}) => {
  const selectedRibbonFilterMetrics = ActiveCaseRibbonFilterTypes[selectedRibbonFilter];
  const { currentView } = useStoreState((state) => state.view);
  const { isLoading: isAssetsLoading } = useStoreState((state) => state.assets);
  const { isLoading: inverterAssetsLoading } = useStorageInverters({
    enabled: !isAssetsLoading,
  });
  const getSiteById = useStoreState((state) => state.sites.getSiteById);
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const { isAuthorized } = useAuth();
  const isAuthorizedToViewAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
  });
  const isAuthorizedToViewSIs = isAuthorized({
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] }],
  });

  const { data: rtData, error, isLoading, isRefetching, refetch: refetchCases } = useQuery(
    [QueryKey.MONITOR_CASES, tableType, currentView, selectedRibbonFilterMetrics],
    // Right now fetching only first 1000 records
    async () =>
      fetchCaseQueue({
        pageNumber: 0,
        pageSize: 1000,
        tableType,
        selectedRibbonFilter: selectedRibbonFilterMetrics,
      }),
    {
      refetchInterval: tableType === 'history' ? false : Config.REFRESH.realTime,
      // setting this to false to avoid unwanted refetch
      // https://github.com/tannerlinsley/react-query/discussions/1118#discussioncomment-90017
      refetchOnWindowFocus: false,
    },
  );

  // Dependent Query for alerts, using isLoading from previous query for enabled flag
  const { data: alerts, error: alertsError, isLoading: isAlertsLoading } = useQuery(
    [QueryKey.MONITOR_ALERTS],
    () =>
      getMonitorAlertsForAssets({
        assetIds: getAllAssetIdsForCases(rtData).join(','),
      }),

    {
      refetchOnWindowFocus: false,
      refetchInterval: Config.REFRESH.realTime,
      enabled: !isLoading && isAuthorizedToViewAlerts,
      select: (data) => {
        return Array.isArray(data?.alerts)
          ? data.alerts.map((elem) => ({
              ...elem,
              ...elem.typeProperties,
              id: elem._id,
              status:
                elem.status !== AlertStatusCodeMapping.CLOSED
                  ? AlertStatus.OPEN
                  : AlertStatus.CLOSED,
              isExpired: elem.status === AlertStatusCodeMapping.EXPIRED,
              isGroup: !!elem?.groupDetails?.groupId,
            }))
          : [];
      },
    },
  );

  // Dependent Query for specialInstructions, using isLoading from previous query for enabled flag
  const siteAndAssetIds = getAllSiteAndAssetForCases(rtData);
  const { data: SIs, error: siError, isLoading: isSIsLoading } = useQuery(
    [QueryKey.ACTIVE_SPECIAL_INSTRUCTIONS, siteAndAssetIds],
    () => getActiveSIsForSiteAndAssets(getAllSiteAndAssetForCases(rtData)),
    {
      refetchOnWindowFocus: false,
      refetchInterval: Config.REFRESH.realTime,
      enabled: Boolean(!isLoading && isAuthorizedToViewSIs && siteAndAssetIds),
    },
  );

  const response = useMemo(() => {
    if (!rtData) return { error, isLoading };
    let modifiedResponse = rtData.map((element) => {
      // TODO: Remove mock asset and mock Site from hook data once everything wired up
      let asset = getAssetById(element.asset_id) || {};
      let site = element.site_id ? getSiteById(element.site_id) : {};
      return {
        ...element,
        asset: {
          ...asset,
          metrics: { state: element.assetState },
          systemNumber: Array.isArray(asset.aliases)
            ? asset.aliases.find((item) => item.key === 'systemNumber')?.value
            : '',
        },
        site,
        assetStateValue:
          element?.assetConnection?.value === AssetConnectionStatus.NOCOMM ||
          element?.assetConnection?.value === AssetConnectionStatus.NO_DATA
            ? CaseQueueAssetStateFilter.NO_COMM
            : element?.assetState?.value,
        alerts: alerts?.filter((elem) =>
          element.caseLevel !== EntityType.ASSET // checking for site level
            ? elem.assetType?.toLowerCase() === EntityType.SITE && elem.siteId === site?.id
            : elem.assetType === entityTypeMapping[asset?.type] && elem.assetId === asset?.id,
        ),
        specialInstructions:
          SIs && filterSpecialInstructions(element.caseLevel, SIs, site?.id, asset?.id),
        readings: {
          general: {
            windSpeed: element.windSpeed !== '' ? getFixed(element.windSpeed) : '',
          },
        },
        // TODO: remove mock tasks with real tasks
        tasks: mockTasks,
        assetType: element.caseLevel === EntityType.ASSET ? asset?.type : element.caseLevel,
        // caseType mapping
        caseTypeLabel: CaseTypeLabel[element.type] || CaseType.UNKNOWN,
        duration: (element.end ? dayjs(element.end) : dayjs()).diff(dayjs(element.start)),
        resetStatus: element.resetStatus ?? SystemResetStatus.NO_AUTOMATION,
        substations: site?.substations?.instanceCount > 0 ? 'Yes' : 'No',
      };
    });
    if (!selectedRibbonFilterMetrics) {
      updateRibbonFilterCounts(modifiedResponse);
    }
    return {
      data: modifiedResponse.length
        ? modifiedResponse.sort(sorter(sortMetric, sortDirection))
        : undefined,
      error,
      isAssetsLoading,
      inverterAssetsLoading,
      isLoading,
      isRefetching,
    };
  }, [
    error,
    getSiteById,
    alerts,
    getAssetById,
    isAssetsLoading,
    inverterAssetsLoading,
    isLoading,
    sortDirection,
    sortMetric,
    rtData,
    isRefetching,
    SIs,
    updateRibbonFilterCounts,
    selectedRibbonFilterMetrics,
  ]);

  let modifiedData = useMemo(() => {
    let filteredData = response.data;
    if (!selectedRibbonFilterMetrics && selectedRibbonFilter && Array.isArray(filteredData)) {
      if (selectedRibbonFilter === MonitorCaseRibbonFilterTypes.NEW_AND_RETURNED) {
        filteredData = filteredData.filter(
          (item) =>
            item.caseState === MonitorCaseStatus.NEW ||
            item.caseState === MonitorCaseStatus.RETURNED,
        );
      }
      if (selectedRibbonFilter === MonitorCaseRibbonFilterTypes.STATION_CHECK) {
        filteredData = filteredData.filter(
          (item) =>
            item.caseState === MonitorCaseStatus.IN_PROGRESS &&
            item?.asset?.metrics?.state?.value !== AssetStatus.REPAIR &&
            item?.asset?.metrics?.state?.value !== AssetStatus.MAINTENANCE,
        );
      }
      if (selectedRibbonFilter === MonitorCaseRibbonFilterTypes.SECOND_LOOK) {
        filteredData = filteredData.filter(
          (item) =>
            item.caseState === MonitorCaseStatus.IN_PROGRESS &&
            item?.asset?.metrics?.state?.value === AssetStatus.TRIPPED,
        );
      }
    }
    return filteredData;
  }, [response.data, selectedRibbonFilter, selectedRibbonFilterMetrics]);

  const { data, filterValues } = useTableFilter({ data: modifiedData, filters, search });

  return {
    ...response,
    isAlertsLoading,
    alertsError,
    isSIsLoading,
    siError,
    filterValues,
    data: data,
    isRefetching,
    refetchCases,
  };
};

const getUpdatedData = (oldData, newData) => {
  if (newData?.[0]?.state === MonitorCaseStatus.CLOSED) {
    return oldData.filter((oldCase) => {
      let newCase = newData.find((item) => item.id === oldCase.id);
      return Boolean(newCase?.status !== CasePatchCallStatus.SUCCESS);
    });
  }

  return oldData.map((oldCase) => {
    let newCase = newData.find((item) => item.id === oldCase.id);
    return newCase?.status === CasePatchCallStatus.SUCCESS
      ? { ...oldCase, ...newCase?.caseObject }
      : oldCase;
  });
};

export const useChangeCasesStatus = () => {
  const queryClient = useQueryClient();
  const { mutate: changeCaseStatus, data, isLoading, isError, error } = useMutation(
    (input_data) => changeCasesStatus(input_data),
    {
      onSuccess: (newData) => {
        if (queryClient.getQueriesData(QueryKey.MONITOR_CASES)) {
          queryClient.setQueriesData(QueryKey.MONITOR_CASES, (oldData) =>
            getUpdatedData(oldData, newData),
          );
        } else {
          queryClient.invalidateQueries(QueryKey.MONITOR_CASES);
        }
        queryClient.invalidateQueries(QueryKey.ASSETS_CASES);
      },
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
    { enabled: true, ...Config.EXECUTE_ONCE },
  );

  return useMemo(() => ({ changeCaseStatus, data, isLoading, isError, error }), [
    changeCaseStatus,
    data,
    error,
    isError,
    isLoading,
  ]);
};

export const useChangeBulkCasesStatus = () => {
  const queryClient = useQueryClient();
  const { mutate: changeBulkCaseStatus, data, isLoading, isError, error } = useMutation(
    (input_data) => changeBulkCasesStatus(input_data),
    {
      onSuccess: (newData) => {
        if (queryClient.getQueriesData(QueryKey.MONITOR_CASES)) {
          queryClient.setQueriesData(QueryKey.MONITOR_CASES, (oldData) =>
            getUpdatedData(oldData, newData),
          );
        } else {
          queryClient.invalidateQueries(QueryKey.MONITOR_CASES);
        }
      },
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
    { enabled: true, ...Config.EXECUTE_ONCE },
  );

  return useMemo(() => ({ changeBulkCaseStatus, data, isLoading, isError, error }), [
    changeBulkCaseStatus,
    data,
    error,
    isError,
    isLoading,
  ]);
};

export const useEditCases = () => {
  const queryClient = useQueryClient();
  const { mutate: editCase, data, isLoading, isError, error } = useMutation(
    (input_data) => editCaseQueue(input_data),
    {
      onSuccess: (newData) => {
        if (queryClient.getQueriesData(QueryKey.MONITOR_CASES)) {
          queryClient.setQueriesData(QueryKey.MONITOR_CASES, (oldData) =>
            getUpdatedData(oldData, newData),
          );
        } else {
          queryClient.invalidateQueries(QueryKey.MONITOR_CASES);
        }
        // Invalidating queries to refetching updated cases
        queryClient.invalidateQueries(QueryKey.ASSETS_CASES);
      },
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );

  return useMemo(() => ({ editCase, data, isLoading, isError, error }), [
    editCase,
    data,
    error,
    isError,
    isLoading,
  ]);
};
