import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import { Icon, Icons } from '@ge/components/icon';
import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader';
import { TableArrowButton, DraggableTable, DynamicTable } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { ResolutionNotesMenu } from '@ge/feat-manage/components/resolution-notes-menu';
import { DateTimeFormats } from '@ge/models/constants';
import { ManageDefs, TableType, EntityType } from '@ge/models/constants';
import { useFilterDefs } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { withDefault } from '@ge/shared/util/table-utils';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';
import { CaseNotesDialog } from '@ge/web-client/src/app/components/entity-details/components/case-notes-dialog';

import { CrewBadge } from '../../../../features/manage/components/crew-badge';
import { PartsMenu } from '../../../../features/manage/components/parts-menu';
import {
  CaseHistoryColumnDefs,
  CaseHistoryColumns,
  defaultCaseHistoryCols,
} from '../../models/table-col-defs/case-history-table-cols';

import PartsConsumed from './icons/parts-consumed.svg';

const StyledDesc = styled.span`
  margin-left: 6px;
`;
const NoteIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.NOTE,
  color: props.theme.table.iconGreyColor,
}))``;
const StyledImg = styled.img`
  height: 15px;
  width: 16px;
  color: ${(props) => props.theme.table.iconGreyColor};
`;
// const ChevronWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `;
const StyledTimeDate = styled.div`
  span {
    display: inline-block;
    font-size: 11px;

    &:first-of-type {
      margin-right: 9px;
    }

    &:last-of-type {
      margin-left: 9px;
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

// const TypeIcon = styled(Icon).attrs((props) => ({
//   size: 12,
//   icon: props.icon,
//   color: props.theme.table.iconLightColor,
// }))`
//   margin-top: 2px;
//   margin-right: 4px;
// `;

const Load = styled.div`
  top: 400px;
  position: relative;
`;
const StyledCrewBadges = styled.span`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 150px !important;
  margin-left: 4px;

  & > div {
    margin-left: -4px;
  }
`;
const CrewPills = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const StyledNotes = styled.div`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const NotesIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.NOTE,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const getEntityTypeFromSiteObj = (site) => {
  if (Array.isArray(site?.type) && site?.type?.length > 0) {
    return site.type[0]?.toLowerCase();
  }
  return EntityType.WIND_SITE;
};

const CaseHistoryTable = ({
  isLoading,
  tableType,
  history,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onEventSelect,
  draggable,
  scrollable,
  onDrop,
  onFilter,
  filterValues,
  onFilterChange,
  onTaskSelect,
}) => {
  const { t, ready } = useTranslation(['tasks', 'monitor.issues'], {
    useSuspense: false,
  });
  const [caseNotesModal, setCaseNotesModal] = useState(false);
  const [caseNotes, setCaseNotes] = useState(null);
  const { getSiteById } = useStoreState((state) => state.sites);
  const { getAssetById } = useStoreState((store) => store.assets);

  const [tableColumns, setTableColumns] = useState(columns);

  const handleCaseNotes = useCallback(
    (e, cellValue) => {
      killEventPropagation(e);
      setCaseNotes(cellValue);
      setCaseNotesModal(true);
    },
    [setCaseNotesModal],
  );

  const closeNoteModal = useCallback(
    (e) => {
      killEventPropagation(e);
      setCaseNotes({});
      setCaseNotesModal(false);
    },
    [setCaseNotesModal],
  );

  const sortedDirection = useCallback((metric) => (metric === sortMetric ? sortDirection : ''), [
    sortMetric,
    sortDirection,
  ]);
  const { filterDefs, onChange: onFilterApply } = useFilterDefs({
    columnDefs: CaseHistoryColumnDefs,
    stateId: ManageDefs.ASSET_HISTORY_FILTER_STATE_ID,
  });
  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilterApply(columnKey, value);
    },
    [onFilterApply],
  );

  const handleFilterChange = useCallback((_, key, value) => onFilterChange({ key, value }), [
    onFilterChange,
  ]);

  useEffect(() => {
    onFilter(filterDefs);
  }, [filterDefs, onFilter]);

  useEffect(() => {
    const filteredColumns = [];
    columns.map((c) => {
      const newCol = { id: c.id, cols: [] };
      const cols = c.cols.filter((f) => f.filter === undefined || f.filter === tableType);
      if (cols.length > 0) {
        newCol.cols = cols;
        filteredColumns.push(newCol);
      }
    });
    setTableColumns(filteredColumns);
  }, [columns, tableType, setTableColumns]);

  const formatDateCell = (date, timezone) =>
    withDefault(
      date,
      date &&
        dayjs(date)
          .tz(timezone)
          .format(DateTimeFormats.TASKS_TABLE_DATE),
    );
  const getColumnValueBasedOnTableType = useCallback(
    ({ code, description, severity, title }) => {
      if (tableType === TableType.TASK_HISTORY) {
        return { code: '', description: title, severity: '', title };
      } else if (tableType === TableType.EVENT) {
        return { code, description, severity, title };
      }
    },

    [tableType],
  );

  const cellValueMapFn = useCallback(
    (event) => {
      if (!event) {
        return {};
      }

      // TODO: Handle open tasks?
      const {
        code,
        description,
        start,
        end,
        eventNote,
        severity,
        consumedParts,
        completedDateTime,
        resolutionNotes,
        source,
        workScope,
        title,
        site,
        site_id,
        asset_id,
        id,
      } = event;
      const timezone = getSiteById(site?.id)?.timezone;
      return {
        [CaseHistoryColumns.TITLE]: getColumnValueBasedOnTableType({
          code,
          description,
          severity,
          title: withDefault(title),
        }),
        [CaseHistoryColumns.LASTNOTE]: eventNote,
        [CaseHistoryColumns.NOTES]: event && {
          id,
          site: getSiteById(site_id),
          asset: getAssetById(asset_id),
          description,
        },
        [CaseHistoryColumns.START]:
          start && getDateTimeBasedOnZone(start, getSiteById(site_id)?.timezone),
        [CaseHistoryColumns.STOP]:
          end && getDateTimeBasedOnZone(end, getSiteById(site_id)?.timezone),
        [CaseHistoryColumns.PARTS_CONSUMED]:
          consumedParts && consumedParts.length
            ? { parts: consumedParts, count: consumedParts.length }
            : undefined,
        [CaseHistoryColumns.COMPLETED_DATE_TIME]: formatDateCell(completedDateTime, timezone),
        [CaseHistoryColumns.ASSIGNED_TECHS]: event?.techs,
        [CaseHistoryColumns.RESOLUTION_NOTES]: resolutionNotes,
        [CaseHistoryColumns.TASK_DETAIL]: '',
        [CaseHistoryColumns.SOURCE]: t(`dynamic.types.${source}`, source),
        [CaseHistoryColumns.WORKSCOPE]: t(`dynamic.work_scopes.${workScope}`, workScope),
        [CaseHistoryColumns.CASE_ID]: id,
      };
    },
    [getColumnValueBasedOnTableType, getSiteById, getAssetById, t],
  );

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        // case CaseHistoryColumns.SUBTYPE:
        //   return (
        //     <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
        //       <TypeIcon icon={Icons.EVENT} />
        //     </TooltipCell>
        //   );
        case CaseHistoryColumns.SOURCE:
          return (
            <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case CaseHistoryColumns.WORKSCOPE:
          return (
            <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case CaseHistoryColumns.TITLE:
          return (
            <TooltipCell tooltip={cellValue?.description} zIndex={elevations.P20}>
              <StyledDesc>{`${cellValue?.code} ${cellValue?.description}`}</StyledDesc>
            </TooltipCell>
          );
        case CaseHistoryColumns.START:
        case CaseHistoryColumns.STOP:
          return (
            <>
              <StyledTimeDate>
                <span>{cellValue?.time}</span>
                <span>{cellValue?.date}</span>
                <span>{cellValue?.tz}</span>
              </StyledTimeDate>
            </>
          );
        case CaseHistoryColumns.LASTNOTE:
          return (
            <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case CaseHistoryColumns.NOTES:
          return (
            <StyledNotes onClick={(e) => handleCaseNotes(e, cellValue)}>
              <NotesIcon />
            </StyledNotes>
          );
        case CaseHistoryColumns.PARTS_CONSUMED:
          return withDefault(
            cellValue,
            <PartsMenu
              titleKey="parts_consumed"
              titleDefault="Parts Consumed"
              parts={cellValue?.parts}
            >
              <StyledImg src={PartsConsumed} alt="Parts Consumed" />
            </PartsMenu>,
          );
        case CaseHistoryColumns.COMPLETED_DATE_TIME:
          return (
            <StyledTimeDate>
              <span>{cellValue}</span>
            </StyledTimeDate>
          );
        case CaseHistoryColumns.ASSIGNED_TECHS:
          return withDefault(
            cellValue?.assignedTechs,
            <StyledCrewBadges>
              <CrewPills>
                {cellValue?.assignedTechs?.length > 0 &&
                !cellValue?.assignedTechs?.includes(null) ? (
                  cellValue?.assignedTechs?.map((technician) => (
                    <TooltipCell
                      key={technician?.id}
                      tooltip={technician?.firstName + ' ' + technician?.lastName}
                      zIndex={elevations.P20}
                    >
                      <CrewBadge
                        key={technician?.id}
                        technician={technician}
                        overlap
                        taskTable={true}
                      />
                    </TooltipCell>
                  ))
                ) : (
                  <span>-</span>
                )}
              </CrewPills>
            </StyledCrewBadges>,
          );
        case CaseHistoryColumns.RESOLUTION_NOTES:
          return withDefault(
            cellValue,
            <ResolutionNotesMenu
              titleKey="resolution_notes"
              titleDefault="Resolution Notes"
              notes={cellValue}
            >
              <NoteIcon />
            </ResolutionNotesMenu>,
          );
        case CaseHistoryColumns.TASK_DETAIL:
          return <TableArrowButton className="row-hover-icon" />;
        default:
          return null;
      }
    },
    [handleCaseNotes],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: CaseHistoryColumnDefs,
    cellValueMapFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
    filterValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
  });
  if (!columns || columns.length === 0) {
    return null;
  }
  const dynamicProps = {
    scrollable,
    compressed: true,
    transparent: true,
    noTitles: true,
    columns: tableColumns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: history,
    onValueSelect: tableType === TableType.TASK_HISTORY ? onTaskSelect : onEventSelect,
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
  };

  if (!ready || isLoading) {
    return (
      <>
        <Load>
          <MiniLoader />
        </Load>
      </>
    );
  }

  const tableEl = React.createElement(draggable ? DraggableTable : DynamicTable, dynamicProps);

  return (
    <>
      {tableEl}
      {
        <CaseNotesDialog
          entity={caseNotes}
          isOpen={caseNotesModal}
          onClose={closeNoteModal}
          siteType={getEntityTypeFromSiteObj(caseNotes?.site)}
        />
      }
    </>
  );
};

CaseHistoryTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isLoading: PropTypes.bool,
  tableType: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  history: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onEventSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  filterValues: PropTypes.object,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  onTaskSelect: PropTypes.func,
};

CaseHistoryTable.defaultProps = {
  columns: defaultCaseHistoryCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onEventSelect: () => null,
  onDrop: () => null,
  scrollable: false,
  draggable: false,
  filterValues: {},
  onFilter: () => null,
  onFilterChange: () => null,
  tasks: [],
  onTaskSelect: () => null,
};

export default CaseHistoryTable;
