import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';

const StyledPage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const PageContainer = forwardRef(({ children, i18nReady }, ref) => (
  <StyledPage ref={ref}>{i18nReady ? children : <Loader />}</StyledPage>
));

PageContainer.displayName = 'PageContainer';

PageContainer.propTypes = {
  children: PropTypes.node,
  i18nReady: PropTypes.bool,
};

PageContainer.defaultProps = {
  children: null,
  i18nReady: true,
};

export { PageContainer };
