import { useMemo } from 'react';

import { EntityType } from '@ge/models/constants';
import { useCaseLinkedTasks } from '@ge/shared/components/use-linked-task-menu';

export const useInThisCase = ({ caseDetail }) => {
  const { tasks, taskIds, isLoading } = useCaseLinkedTasks({ caseId: caseDetail.id });

  const childCasesAndTasks = useMemo(() => {
    const _tasks =
      !isLoading && tasks?.length
        ? tasks.map((task) => ({
            id: task.id,
            type: EntityType.TASK,
            description: task.title,
            start: task.startedDateTime,
            status: task.status,
            taskId: task.taskId,
            createDate: task.createDate,
          }))
        : [];
    return [...caseDetail.childCases, ..._tasks];
  }, [caseDetail.childCases, isLoading, tasks]);

  return {
    tasks,
    taskIds,
    childCasesAndTasks,
    isLoading,
  };
};
