import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from '@ge/components/input';
import { StatusColor } from '@ge/tokens/colors/colors';

const StyledInput = styled(Input)`
  ${({ errors, id, fieldArray }) => {
    const { fieldName, errorIndex, errorName } = fieldArray ?? {};
    return errors[id] || errors[fieldName]?.[errorIndex]?.[errorName]
      ? `border-color: ${StatusColor.DANGER}`
      : '';
  }};
`;

export const NumberMetaField = ({
  className,
  defaultValue,
  fieldArray,
  isInteger,
  label,
  max,
  metadata,
  min,
  name,
  placeholder,
  rules,
}) => {
  const { readOnly, required } = metadata;
  const { control, errors, setValue } = useFormContext();

  const _rules = { ...(rules ?? {}), required, max };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? ''}
      name={name}
      rules={_rules}
      render={({ onChange, value }) => (
        <StyledInput
          className={className}
          disabled={readOnly}
          errors={errors}
          fieldArray={fieldArray}
          label={label}
          id={name}
          min={min}
          max={max}
          onChange={onChange}
          onKeyDown={(e) => {
            e.key === 'e' && e.preventDefault();
            isInteger && e.key === '.' && e.preventDefault();
          }}
          onBlur={() => {
            isInteger && setValue(`${name}`, parseInt(value) || 0);
          }}
          placeholder={placeholder ? placeholder : ''}
          required={required}
          spinner={!readOnly}
          type="number"
          value={value}
        />
      )}
    />
  );
};

NumberMetaField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  fieldArray: PropTypes.instanceOf(Object),
  isInteger: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  metadata: PropTypes.instanceOf(Object).isRequired,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
};

NumberMetaField.defaultProps = {
  className: '',
  defaultValue: null,
  fieldArray: null,
  isInteger: false,
  label: '',
  max: null,
  min: 0,
  placeholder: '',
  rules: {},
};
