import { useStoreState } from 'easy-peasy';
import either from 'ramda/src/either';
import isNil from 'ramda/src/isNil';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { ExportGraphModal } from '@ge/feat-analyze/components/modals';
import { useExportGraph } from '@ge/feat-analyze/data-hooks/use-export-graph';
import { ChartNames } from '@ge/feat-analyze/models';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import {
  AnaylzeDefs,
  PresentationTypes,
  EntityType,
  KpiCategoryDefs,
  KpiCategoriesHeader,
  AlertsEntityType,
} from '@ge/models/constants';
import { DateRange } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { SiteListTable } from '@ge/shared/components/tables/site-list-table';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { AllSitesColumnDefs } from '@ge/shared/models/table-col-defs/sites-list-table-cols';
import { killEventPropagation } from '@ge/shared/util/general';
import { isEmpty } from '@ge/util/object-utils';
import { FourOhFour } from '@ge/web-client/src/app/components/404/404';

/**
 * NOTE: ticket #(DE147538)- Remove chevron
 * !these lines are commented for MVP0
 * !Commented this lines for MVP0 to remove the chevron and the onTitleClick function to route to Site Dashboard
 * AND
 * !commented for MVP0 to not display the toggle option
 */
// eslint-disable-next-line import/order
// import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';
// import SitesTable from '@ge/feat-monitor/components/tables/sites-table';
// import PresentationToggle from '../../app/components/presentation-toggle/presentation-toggle';
// import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import { FilterBar } from '../components/dashboard/dashboard-filter-bar';
import DashboardHeader from '../components/dashboard/dashboard-header';
import { DashboardIECLossCategories } from '../components/dashboard/dashboard-iec-loss-categories';
import { DashboardKpiChart } from '../components/dashboard/dashboard-kpi-chart';
import SiteTableTools from '../components/tables/table-tools';
import useKpiHeaderMenu from '../data-hooks/use-kpi-header-menu';
import useRegionIecData from '../data-hooks/use-region-iec-data';
/* TODO: Uncomment for Site List Update and Site Cards */
// import { SitesGrid } from '../components/region/sites-grid';
import useRegionKpiData from '../data-hooks/use-region-kpi-data';
import useTimeAggregation from '../data-hooks/use-time-aggregation';
import useSitesFromRegion from '../hooks/use-sites-from-region';
import { RegionFilterOptions } from '../models';
import { KpiCategorySeriesType, RegionKpiChartDefs } from '../models';
import { DefaultRegionKpiVisibility } from '../models/kpi-header-visibility-defaults';
import { defaultSitesCols } from '../models/site-list-table-col-defaults';
import {
  getFilterDateRange,
  getKpiCategories,
  getKpiChartDef,
  getTotalReportingAssets,
} from '../util';

import { AnalyzeFilterPanel } from './analyze-filter-panel';

const NAMESPACE = 'analyze.dashboard';

const REGION_SORT_STATE_ID = `${NAMESPACE}.region`;
const REGION_FILTER_STATE_ID = `${NAMESPACE}.region`;
/**
 * Available sort metrics for Sites.
 */
const SitesMetric = {
  NAME: 'name',
  STATE: 'metrics.state',
  PRIORITY: 'priority',
};

const regionOverviewCategories = [...KpiCategoriesHeader];

const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-width: 600px;
  padding: 10px 10px 0;
`;

const ContentLeft = styled.div`
  flex: 1 1 calc(50% - 20px);
  margin-right: 20px;
  min-width: 0;
`;

const ContentRight = styled.div`
  flex: 1 1 50%;
  min-width: 0;
`;

const FullWidthContent = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

const SitesGridHeader = styled.div`
  border-bottom: solid 2px ${(props) => props.theme.analyze.header.borderBottomColor};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
  padding: 10px 0 10px 15px;
  height: 35px;
`;

/**
 * NOTE: ticket #(DE147538)- Remove chevron
 * Set list of view types for Presentation Toggle
 * !commented for MVP0 to not display the toggle option
 */
// const PresentationViews = [PresentationTypes.GRID, PresentationTypes.LIST];

const isReallyEmpty = either(isNil, isEmpty);

export const AnalyzeRegion = () => {
  /* Hooks */
  const { ready, t } = useTranslation([NAMESPACE]);

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ANALYZE_REGION);

  /**
   * NOTE: ticket #(DE147538)- Remove chevron
   *!Commented this lines for MVP0 to remove the chevron and the onTitleClick function to route to Site Dashboard
   */
  // const { showSiteDetails } = useContext();

  /* Router */
  /* Hiding for MVP0 */
  const { push } = useHistory();
  const { search } = useLocation();
  const { region: regionId } = useParams();
  // TODO: get route from somewhere so don't need to hardcode here
  // const routeToSite = ({ id }) => push(`/analyze/site/${id}`);

  /* Store state */
  const { getRegionById } = useStoreState((state) => state.regions);
  const [currentSearch, setCurrentSearch] = useState({});
  /* Local state */
  const [sitesView, setSitesView] = useState(PresentationTypes.GRID);
  const [currentIecCategory, setCurrentIecCategory] = useState(
    KpiCategoryDefs.EVENT_UNAVAILABLE_TIME,
  );
  const [kpiChartState, setKpiChartState] = useState({
    categories: [...regionOverviewCategories],
    def: getKpiChartDef(RegionKpiChartDefs),
  });
  const [kpiSortDirection, setKpiSortDirection] = useState(SortDirection.ASC);
  const [dateRange, setDateRange] = useState({});
  const [graph1Category, setGraph1Category] = useState([KpiCategoryDefs.TBA]);

  const { dateRange: dates, trendGraphSelection } = useStoreState(({ analyze }) => analyze);

  // Set initial date range to default
  useEffect(() => {
    // don't notify if custom date range is incomplete
    if (dates.range === DateRange.CUSTOM && !(dates.endDate && dates.startDate)) {
      return;
    }

    const filterDateRange = getFilterDateRange({
      startDate: dates.startDate,
      range: dates.range,
      endDate: dates.endDate,
    });

    setDateRange({
      startDate: filterDateRange.startDate.entityTimezone,
      endDate: filterDateRange.endDate.entityTimezone,
    });
  }, [dates]);

  const timeAggrProps = useTimeAggregation({
    entityType: EntityType.REGION,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const { timeAggregation, timeAggrOptions } = timeAggrProps;

  /* Data */
  const region = getRegionById(regionId);

  const {
    def: { graph1 },
  } = kpiChartState;

  const { sortEntitiesBy } = graph1 || {};

  const {
    data: iecCategoryData,
    isLoading: isRegionIecLoading,
    error: iecCategoryError,
    setSelectedIecCategoryData,
    isIecAssetDataLoading,
  } = useRegionIecData({
    regionId,
    category: currentIecCategory,
    startDate: trendGraphSelection?.startDate ? trendGraphSelection.startDate : dateRange.startDate,
    endDate: trendGraphSelection?.endDate ? trendGraphSelection.endDate : dateRange.endDate,
  });

  /* Effects */
  // Process URL params for setting initial view type.
  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');
    if (view) {
      setSitesView(view);
    } else {
      setSitesView(PresentationTypes.GRID);
    }
  }, [sitesView, search]);

  /* Table state */
  const {
    visibleCols: visibleSitesCols,
    sortDirection: sitesSortDirection,
    sortMetric: sitesSortMetric,
    updateSortMetric: updateSitesSortMetric,
    updateColumnVisibility,
    setVisibleColumns,
  } = useColumnState({
    columnDefs: AllSitesColumnDefs,
    defaultCols: defaultSitesCols,
    defaultSortMetric: SitesMetric.PRIORITY,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: REGION_SORT_STATE_ID,
  });

  // Update all categories on the page including kpi bar and assets table
  const regionKpiCategories = Array.from(new Set([...regionOverviewCategories, ...graph1Category]));
  const {
    data: regionKpiData,
    isLoading: isRegionKpiLoading,
    graph1QueryState,
  } = useRegionKpiData({
    categories: regionKpiCategories,
    regionId,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    sortBy: sortEntitiesBy,
    sortDirection: kpiSortDirection,
    types: [KpiCategorySeriesType.ENTITY, KpiCategorySeriesType.TIME_SERIES],
    seriesEntityAggr: EntityType.SITE,
    dateEntityAggr: EntityType.REGION,
    timeAggr: timeAggregation,
    graph1Category,
    entityAggr: AlertsEntityType.SITE,
  });
  // Include availability contratc only for site table
  const regionSitesKpiCategories = Array.from(
    new Set([...regionOverviewCategories, KpiCategoryDefs.AVAILABILITY_CONTRACT]),
  );

  const { data: kpiBarData } = useRegionKpiData({
    categories: regionSitesKpiCategories,
    regionId,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    sortBy: sortEntitiesBy,
    sortDirection: kpiSortDirection,
    types: [KpiCategorySeriesType.ENTITY, KpiCategorySeriesType.TIME_SERIES],
    seriesEntityAggr: EntityType.SITE,
    dateEntityAggr: EntityType.REGION,
    timeAggr: timeAggregation && timeAggrOptions[0]?.value,
    entityAggr: AlertsEntityType.SITE,
  });

  const {
    filterSequence,
    filterDefs,
    onChange: setFilterDefs,
    onReset: onReset,
  } = useFilterDefs({
    columnDefs: AllSitesColumnDefs,
    stateId: REGION_FILTER_STATE_ID,
  });

  const {
    isLoading: isSitesTableLoading,
    data: sites,
    filterValues,
  } = useSitesFromRegion({
    regionName: region?.name,
    regionData: kpiBarData,
    regionId,
    search: currentSearch,
    filters: filterDefs,
    filterSequence,
    tableSortMetric: sitesSortMetric,
    tableSortDirection: sitesSortDirection,
  });

  const isDataPresent = !isReallyEmpty(regionKpiData) && !isReallyEmpty(iecCategoryData.data);
  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);
  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_REGION,
    defaultVisibility: DefaultRegionKpiVisibility,
    kpiCategories: regionOverviewCategories,
  });

  const { onVisibilityChange: handleSiteColumnChanges, onSelectionVisibilityChange } =
    useManageColumnVisibility({
      subKey: AnaylzeDefs.SITES,
      featPrefs,
      savePrefs,
      setVisibleColumns,
      updateColumnVisibility,
    });

  const {
    enabled: enabledExport,
    openModal,
    modalProps,
    setCurrentType,
    setChartRefs,
  } = useExportGraph({
    isLoading: isRegionIecLoading || isRegionKpiLoading || isRegionKpiLoading,
    kpiChartState,
    timeAggregation,
    currentIecCategory,
    entityType: EntityType.REGION,
    entity: region,
    dateRange,
  });

  const siteIds = useMemo(() => sites?.map((site) => site.siteId), [sites]);

  /**
   *  NOTE: ticket #(DE147538)- Remove chevron
   * !Commented this lines for MVP0 to remove the onTitleClick function to route to Site Dashboard
   * Handlers
   */
  // const handleSelectSiteTitle = useCallback(
  //   (e, id) => {
  //     killEventPropagation(e);
  //     if (id) {
  //       showSiteDetails(id, EntityTab.PRODUCTION);
  //     }
  //   },
  //   [showSiteDetails],
  // );

  const handleSiteSelect = useCallback(
    (e, { siteId }) => {
      const routeToSite = (siteId) => push(`/analyze/site/${siteId}`);
      killEventPropagation(e);
      routeToSite(siteId);
    },
    [push],
  );

  const handleIecCategoryChange = useCallback(
    (category) => {
      setCurrentIecCategory(category);
      setSelectedIecCategoryData({});
    },
    [setSelectedIecCategoryData],
  );

  // Map filter bar to KPI chart defs
  const handleFilterBarChange = useCallback(
    ({ ...filterBar }) => {
      const def = getKpiChartDef(RegionKpiChartDefs, filterBar);
      // Get list of distinct categories to fetch
      const categories = getKpiCategories(def);

      setKpiChartState({ ...filterBar, categories, def });
      setGraph1Category(categories);
    },
    [setKpiChartState],
  );

  const handleSortChange = useCallback(
    (sortValue) => {
      setKpiSortDirection(sortValue);
    },
    [setKpiSortDirection],
  );

  const handleSeeTop100 = useCallback(
    (selectedCategoryData) => setSelectedIecCategoryData({ ...selectedCategoryData, siteIds }),
    [setSelectedIecCategoryData, siteIds],
  );

  // translations
  const translations = {
    sites: t('sites', 'Sites'),
  };

  if (!region) return <FourOhFour />;

  return (
    <PageContainer i18nReady={ready}>
      <AnalyzeFilterPanel entityId={regionId} entityType={EntityType.REGION} />
      <ScrollingContainer>
        <DashboardHeader
          title={region.name}
          type={EntityType.REGION}
          data={kpiBarData}
          onMenuClose={handleKpiChanges}
          visibleKpiPrefs={visibleKpis}
          kpiCategories={regionOverviewCategories}
        />
        <FilterBar enabledExport={enabledExport} onExportClick={openModal} />
        <Content data-testid={AnalyzeLocators.ANALYZE_REGION_GRAPHS_CONTAINER}>
          <ContentLeft data-testid={AnalyzeLocators.ANALYZE_REGION_KPI_GRAPH}>
            {graph1 && (
              <DashboardKpiChart
                chartDef={graph1}
                data={regionKpiData}
                error={graph1QueryState.error}
                isLoading={graph1QueryState.isLoading}
                filterOptions={RegionFilterOptions}
                entityType={EntityType.REGION}
                namespace={NAMESPACE}
                parentEntity={region}
                onChange={handleSortChange}
                onRetry={graph1QueryState.refetch ?? null}
                inlineTitle={false}
                xAxisTitle={translations.sites}
                handleFilterBarChange={handleFilterBarChange}
                height={392}
                staticChartTitle={t('kpi_chart.region_trends', 'Region Trends')}
                onCurrentTypeChange={setCurrentType}
                ref={(ref) => setChartRefs(ref, ChartNames.TRENDS)}
                {...timeAggrProps}
              />
            )}
          </ContentLeft>
          <ContentRight data-testid={AnalyzeLocators.ANALYZE_REGION_IEC_GRAPH}>
            <DashboardIECLossCategories
              height={334}
              data={{ ...iecCategoryData, siteIds }}
              isLoading={isRegionIecLoading}
              entityType={EntityType.REGION}
              namespace={NAMESPACE}
              onCategoryChange={handleIecCategoryChange}
              error={iecCategoryError}
              iecCategory={currentIecCategory}
              entityId={regionId}
              onSeeAll={handleSeeTop100}
              isTurbineDataLoading={isIecAssetDataLoading}
              isDataPresent={isDataPresent && !isReallyEmpty(sites)}
              totalReportingAssets={getTotalReportingAssets(kpiBarData)}
              ref={(ref) => setChartRefs(ref, ChartNames.PARETO)}
            />
          </ContentRight>
        </Content>
        <FullWidthContent data-testid={AnalyzeLocators.ANALYZE_SITE_TABLE_WRAPPER}>
          <SitesGridHeader>
            <h3>{translations.sites}</h3>
            <SiteTableTools
              isLoading={isSitesTableLoading}
              data={sites}
              columnState={visibleSitesCols}
              entity={region}
              entityType={EntityType.REGION}
              dateRange={dateRange}
              disabledViewToggle={true}
              onMenuClose={handleSiteColumnChanges}
              onClumnSelection={onSelectionVisibilityChange}
              filters={filterDefs}
              onReset={onReset}
            />
          </SitesGridHeader>
          <SiteListTable
            sites={sites}
            region={region.name}
            columns={visibleSitesCols}
            sortMetric={sitesSortMetric}
            sortDirection={sitesSortDirection}
            sortAction={updateSitesSortMetric}
            onSiteSelect={handleSiteSelect}
            isLoading={isSitesTableLoading}
            filterDefs={filterDefs}
            onFilter={setFilterDefs}
            filterValues={filterValues}
            onFilterChange={handleFilterChange}
          />
        </FullWidthContent>
      </ScrollingContainer>
      <ExportGraphModal {...modalProps} enablePng />
    </PageContainer>
  );
};
