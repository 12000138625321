import { useStoreState } from 'easy-peasy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';
import { EntityType } from '@ge/models/constants';
import { LazyLoad } from '@ge/shared/components/lazy-load/lazy-load';

const StorageSite = React.lazy(() => import('@ge/feat-analyze/components/site/site-storage'));
const WindSite = React.lazy(() => import('@ge/feat-analyze/components/site/site-wind'));
const AnalyzeFilterPanel = React.lazy(() => import('@ge/feat-analyze/pages/analyze-filter-panel'));

const NAMESPACE = 'analyze.dashboard';

export const AnalyzeSite = () => {
  const { ready } = useTranslation([NAMESPACE], {
    useSuspense: false,
  });
  const siteId = useParams()?.site;
  const sitesState = useStoreState((state) => state.sites.sites);

  return (
    <PageContainer i18nReady={ready}>
      {sitesState && sitesState[siteId]?.type.includes('Storage') ? (
        <LazyLoad>
          <StorageSite />
        </LazyLoad>
      ) : (
        <>
          <LazyLoad>
            <AnalyzeFilterPanel entityId={siteId} entityType={EntityType.SITE} />
          </LazyLoad>

          <LazyLoad>
            <WindSite />
          </LazyLoad>
        </>
      )}
    </PageContainer>
  );
};
