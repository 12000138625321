import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Table, Tbody, Td, Thead, Th } from '@ge/components/table';
import { AssetCommandStatus } from '@ge/models/constants';

const MiniTd = styled(Td)`
  padding: 2px 12px;
  background: ${(props) => props.theme.eventController.commandDialog.tdBackground};
`;

const StyledTable = styled(Table).attrs((props) => ({
  compressed: true,
  transparent: true,
  backgroundColor: props.theme.eventController.commandDialog.tableBackground,
}))``;

const BulkContainer = styled.div`
  display: flex;
  flex: 1;
  height: ${(props) => (props.count < 10 ? '130px' : '300px')};
`;

const BulkStatus = styled.div`
  margin-top: 16px;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  font-weight: bold;
`;

const BlockedIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dangerColor,
  icon: Icons.OFFLINE,
  rotate: 90,
}))`
  margin-right: 4px;
`;

export const CommandsDialogBulkList = ({ status, counts, setAbortDisabled }) => {
  const { t } = useTranslation(['entity-details']);

  const {
    BLOCKED: blocked,
    SUCCESS: success,
    TIMEOUT: timeOut,
    FAILED: fail,
    INITIATED: initiated,
  } = counts || {};

  // Check if both are undefined
  const failTimeout = (fail || 0) + (timeOut || 0);

  if (initiated === 0) {
    setAbortDisabled(true);
  }

  return (
    <>
      <BulkContainer count={status.length}>
        <StyledTable scrollable>
          <Thead transparent compressed>
            <tr>
              <Th
                labels={{
                  [t('commands_dialog.asset', 'Asset')]: 'asset',
                }}
                align="left"
              />
              <Th labels={{ [t('commands_dialog.status', 'Status')]: 'status' }} align="left" />
              <Th
                labels={{
                  [t('commands_dialog.reason', 'Reason')]: 'reason',
                }}
                align="left"
              />
            </tr>
          </Thead>
          <Tbody transparent compressed>
            {status.map((item, idx) => (
              <tr key={idx}>
                <MiniTd align="left">{item.name}</MiniTd>
                <MiniTd align="left">
                  {item.status === AssetCommandStatus.BLOCKED && <BlockedIcon />}
                  {item.status ? t(`commands_dialog.${item.status}`) : '—'}
                </MiniTd>
                <MiniTd align="left">
                  {item.reason ? t(`commands_dialog.${item.reason}`) : ''}
                </MiniTd>
              </tr>
            ))}
          </Tbody>
        </StyledTable>
      </BulkContainer>
      <BulkStatus>
        <div>
          {t('commands_dialog.blocked', 'blocked')}: {blocked || '-'}
        </div>
        <div>
          {t('commands_dialog.success', 'success')}: {success || '-'}
        </div>
        <div>
          {t('commands_dialog.fail_timeout', 'Fail/Timeout')}: {failTimeout || '-'}
        </div>
      </BulkStatus>
    </>
  );
};

CommandsDialogBulkList.propTypes = {
  status: PropTypes.instanceOf(Array),
  counts: PropTypes.instanceOf(Object),
  setAbortDisabled: PropTypes.func,
};

CommandsDialogBulkList.defaultValues = {
  status: null,
  counts: {},
  setAbortDisabled: () => null,
};
