import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FlagIcon } from '@ge/components/approval-flag-icon';
import { Icon, Icons } from '@ge/components/icon';
import { placements } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { Tooltip } from '@ge/components/tooltip';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
import { numFormat } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';

const TaskContainer = styled.div`
  position: relative;
  display: flex;
  padding: 8px;
  width: calc(100% - 25px);
  border-top: solid 1px ${({ theme }) => theme.manage.taskItem.borderColor};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.manage.taskItem.textColor};
  margin-bottom: ${({ primary }) => (primary ? '10px' : '0')};
  box-shadow: ${({ primary }) => (primary ? '0 2px 2px 0 rgba(0,0,0,0.16)' : 'none')};
  cursor: ${(props) => (props.draggable ? 'pointer' : 'not-allowed')};
  &.draggable {
    cursor: grab;
  }
  &.dragging {
    background: ${({ theme }) => theme.manage.dayCard.scheduled.backgroundColor};
  }
  &.disableDrag {
    cursor: not-allowed;
    &:hover {
      border: 2px solid ${({ theme }) => theme.layout.errorColor2};
    }
  }
  &:hover {
    border-bottom-color: ${({ theme }) => theme.manage.taskItem.hover.borderColor};
    // Commenting out for Bundle tasks
    /* .actions {
      display: flex;
    } */
  }
  // Commenting out for Bundle tasks
  /* > .actions {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    padding: 0 4px;
    display: none;
    background: ${({ theme }) => theme.manage.taskItem.hover.actionsBackground};
    z-index: ${elevations.P3};
    button {
      padding: 0 4px;
    }
  } */
  > .content {
    flex: 1;
    > .location {
      display: flex;
      align-items: baseline;
      button {
        color: ${({ theme }) => theme.manage.taskItem.locationColor};
        font-size: 11px;
        line-height: 15px;
        display: flex;
        align-items: center;
        max-width: 90px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        padding-left: 0;
        padding: 0;
      }
      .divider {
        color: ${({ theme }) => theme.manage.taskItem.iconColor};
      }
    }
    .title {
      letter-spacing: 0;
      font-weight: ${({ primary }) => (primary ? '700' : '300')};
    }
    .type {
      color: ${({ theme }) => theme.manage.taskItem.taskTypeColor};
      text-transform: capitalize;
    }
  }
  .time {
    padding: 0 4px;
    font-size: 11px;
  }
`;

const TaskIcons = styled.div`
  align-self: baseline;
`;

const StyledIcon = styled(Icon).attrs(({ size, theme }) => ({
  size: size || 9,
  color: theme.manage.taskItem.iconColor,
}))`
  margin: 0 3px;
  vertical-align: initial;
`;

const Flag = styled.div`
  display: block;
`;

const ChevronIcon = styled(Icon).attrs(({ theme }) => ({
  icon: Icons.CHEVRON,
  size: 8,
  color: theme.manage.taskItem.iconColor,
  rotate: -90,
}))`
  margin: 0 3px;
`;

const StyledSeverity = styled(Severity)`
  padding-right: 6px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-bottom: 0;
`;

export const TaskItem = forwardRef(
  (
    {
      task,
      assigned,
      primary,
      draggable,
      hasAssetPanel,
      hasSitePanel,
      onDragStart,
      onDragEnd,
      className,
      isNotApprovedTask,
    },
    ref,
  ) => {
    const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

    const { showTaskDetails, showAssetDetails, showSiteDetails } = useContext(EntityDetailsContext);

    const handleTaskClick = (e, id) => {
      killEventPropagation(e);
      showTaskDetails(id);
    };

    const handleAssetClick = (e, id) => {
      killEventPropagation(e);
      showAssetDetails(id);
    };

    const handleSiteClick = (e, id) => {
      killEventPropagation(e);
      showSiteDetails(id);
    };

    // Commenting out for Bundle tasks
    // const handleDismissToBacklog = (e, taskId) => {
    //   e.stopPropagation();
    //   console.log(taskId);
    // };

    // const handleSnooze = (e, taskId) => {
    //   e.stopPropagation();
    //   console.log(taskId);
    // };

    // const handleReschedule = (e, taskId) => {
    //   e.stopPropagation();
    //   console.log(taskId);
    // };

    const { asset, site } = task;
    const { t } = useTranslation(['tasks']);

    const renderAsset = () => {
      if (!asset?.id) {
        return null;
      }

      if (hasAssetPanel) {
        return (
          <>
            <button type="button" onClick={(e) => handleAssetClick(e, asset.id)}>
              <StyledIcon icon={Icons.TURBINE} />
              {asset.name}
            </button>
            <span className="divider">{'/'}</span>
          </>
        );
      }

      return (
        <>
          <span>
            <StyledIcon icon={Icons.TURBINE} />
            {asset.name}
          </span>
          <span className="divider">{'/'}</span>
        </>
      );
    };

    const renderSite = () => {
      if (hasSitePanel) {
        return (
          <>
            <button type="button" onClick={(e) => handleSiteClick(e, site.id)}>
              <StyledIcon icon={Icons.SITE} /> {site.name}
            </button>
            <ChevronIcon />
          </>
        );
      }

      return (
        <span>
          <StyledIcon icon={Icons.SITE} /> {site.name}
        </span>
      );
    };

    const renderTaskContainer = () => (
      <TaskContainer
        draggable={draggable && !isNotApprovedTask}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        primary={primary}
        className={className}
        onClick={(e) => handleTaskClick(e, task.id)}
        ref={ref}
      >
        <TaskIcons>
          <StyledSeverity level={task.priority} />
          {approvalFlag && task.flag && (
            <Flag>
              <FlagIcon value={task?.flag} size={9} />
            </Flag>
          )}
        </TaskIcons>
        <div className="content">
          {!assigned && (
            <div className="location">
              {renderAsset()}
              {renderSite()}
            </div>
          )}
          <div className="title">{task.title}</div>
          {task.source && (
            <div className="type">{t(`dynamic.types.${task.source}`, task.source)}</div>
          )}
        </div>
        {task?.estDurationHours && (
          <div className="time">
            {task.estDurationHours}:{numFormat(task.estDurationMinutes)}
          </div>
        )}
        <ChevronIcon />
      </TaskContainer>
    );

    return approvalFlag && draggable && isNotApprovedTask ? (
      <StyledTooltip
        title={t('not_approved_task', 'Task Not Approved for Scheduling')}
        zIndex={elevations.P20}
        placement={placements.TOP}
      >
        {renderTaskContainer()}
      </StyledTooltip>
    ) : (
      renderTaskContainer()
    );
  },
);

TaskItem.displayName = 'TaskItem';

TaskItem.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  assigned: PropTypes.bool,
  primary: PropTypes.bool,
  draggable: PropTypes.bool,
  hasAssetPanel: PropTypes.bool,
  hasSitePanel: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  className: PropTypes.string,
  isNotApprovedTask: PropTypes.bool,
};

TaskItem.defaultProps = {
  assigned: false,
  primary: false,
  draggable: false,
  hasAssetPanel: false,
  hasSitePanel: false,
  onDragStart: () => {},
  onDragEnd: () => {},
  className: null,
  isNotApprovedTask: false,
};
