import { Entity } from '../entity';

/**
 * An entity representing a geographic area.
 */
export class Region extends Entity {
  /**
   * Display name for the region
   * @type {string}
   */
  name;

  /**
   * @param {Partial<Region>} [initObj] Region object to use to initialize class
   */
  constructor(initObj) {
    super(initObj);

    // Apply all known object values
    Object.assign(this, initObj);
  }
}
