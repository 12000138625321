import styled from 'styled-components';

import { globalColors } from '@ge/tokens/colors/colors';

export const SiteHeaderStyled = styled.div.attrs({ className: 'SiteHeader' })`
  position: relative;
  border-bottom: 3px solid ${globalColors.slate1};
  margin-bottom: 10px;
`;

export const SiteHeaderStyledSolar = styled.div.attrs({ className: 'SiteHeader' })`
  position: relative;
`;

export const FilterBarStyled = styled.div.attrs({ className: 'FilterBar' })`
  position: absolute;
  bottom: 0;
  right: 0;
  > div {
    width: unset;
  }
`;
