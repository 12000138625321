import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { TimeAggr, DateTimeFormats } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';
import { mergeOptionsRight } from '@ge/util';

import { KpiCategorySeriesType } from '.';

dayjs.extend(utc);
dayjs.extend(weekOfYear);

const CHART_HEIGHT = 350;

const getTimeSeriesTooltipStyle = (theme) => ({
  kpiTitle: `
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: ${globalColors.stone3};
  `,
  kpi: `
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: ${globalColors.grey4}
  `,
  kpiUnits: `
    color: ${theme?.kpiChart?.expandButtonColor};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  `,
});

const getDateLabelFn =
  (timeAggr) =>
  ({ value }) => {
    switch (timeAggr) {
      case TimeAggr.MONTHLY:
        return dayjs.utc(value).format('MMM');
      case TimeAggr.WEEKLY:
        return `WK ${dayjs(value).week()}`;
      case TimeAggr.DAILY:
        return dayjs.utc(value).format(DateTimeFormats.DAY_OF_MONTH);
      default:
        return dayjs(value).utc().format(DateTimeFormats.SNOOZE_DATE_FORMAT);
    }
  };

const dateFormatter = (date, timeAggr) => {
  switch (timeAggr) {
    case TimeAggr.DAILY:
      return dayjs(date).utc().format(DateTimeFormats.DEFAULT_DATE);
    default:
      return dayjs(date).utc().format(DateTimeFormats.DEFAULT_DATE_TIME_SECS);
  }
};

/**
 *  Getting time series data points from data and adding points for missing dates
 *
 * @param {*} data
 *
 * @returns Time series data points(eg: [[x1, y1], [x2, y2], [x3, y3], ...])
 */
const getTimeSeriesData = (data, pointInterval) => {
  if (data && data.length > 2) {
    const pointStart = data[0].date;
    const pointEnd = data[data.length - 1].date;
    const days = (pointEnd - pointStart) / pointInterval + 1;
    if (data.length < days) {
      for (let i = 0; i < days; i++) {
        const key = pointStart + i * pointInterval;
        let point = data.find((o) => o.date === key);
        if (!point) {
          data.splice(i, 0, { date: key, value: null });
        }
      }
    }
  }

  return data?.map(({ date, value }) => [date, value]) ?? [];
};

// get options for time series charts
const getTimeSeriesChartOptions = ({ kpiKeys, data: _data, getKpiCategory, timeAggr, theme }) => {
  const xAxisLabelFormatter = getDateLabelFn(timeAggr);
  const xAxisLabelVisible = true;
  const oneDay = 24 * 36e5;
  const pointInterval = oneDay;
  const xAxisMaxPadding = 0.07;

  const series = kpiKeys.reduce((_series, { key, yAxis, type }) => {
    const { timeSeriesData: categoryData } = _data[key] || {};
    const data = getTimeSeriesData(categoryData || [], pointInterval);

    const startDate = dayjs((categoryData || [{}])[0]?.date)
      .utc()
      .format(DateTimeFormats.CREW_TIMING);
    const match = startDate ? startDate.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) : false;
    let pointStart;

    if (match) {
      const [, year, month, day] = match;
      pointStart = Date.UTC(Number(year), Number(month) - 1, Number(day));
    }

    return [
      ..._series,
      {
        name: key,
        data,
        type,
        yAxis: yAxis,
        pointStart,
        ...(timeAggr === TimeAggr.MONTHLY ? { pointIntervalUnit: 'month' } : { pointInterval }),
      },
    ];
  }, []);

  const yAxisOptions = [
    {
      // left y axis
      min: 0,
      opposite: false,
      gridLineWidth: 0.8,
      title: {
        text: null,
      },
      labels: {
        align: 'left',
        x: 0,
        y: -2,
        format: '{value:.,0f}',
      },
      showFirstLabel: true,
      showLastLabel: true,
    },
    {
      // right y axis
      min: 0,
      opposite: true,
      gridLineWidth: 0.8,
      title: {
        text: null,
      },
      labels: {
        align: 'right',
        x: 0,
        y: -2,
        format: '{value:.,0f}',
      },
      showFirstLabel: true,
      showLastLabel: true,
    },
    {
      // tertiary y axis
      min: 0,
      opposite: true,
      visible: false,
      gridLineWidth: 0.8,
      title: {
        text: null,
      },
      labels: {
        align: 'right',
        x: 0,
        y: -2,
        format: '{value:.,0f}',
      },
      showFirstLabel: true,
      showLastLabel: true,
    },
  ];

  let yAxis = kpiKeys.reduce((_axes, { key }) => {
    const { units: _units } = _data[key] || {};
    const kpiName = getKpiCategory(key);
    const title = `${kpiName} (${_units})`;

    return [
      ..._axes,
      {
        title: {
          text: title,
        },
      },
    ];
  }, []);

  yAxis = yAxisOptions.map((value, index) => {
    return { ...value, ...yAxis[index] };
  });

  const { kpiTitle, kpi, kpiUnits } = getTimeSeriesTooltipStyle(theme);

  const tooltipFormatter = ({ points }) => {
    let date = dateFormatter(points[0].x, timeAggr);

    date = date.toUpperCase();

    let tooltip = `
    <table class"historical-chart-tooltip">
      <tr><td colspan="4" class="historical-chart-nowrap" style='${kpiTitle}'>${date}</td></tr>
      <tr><td colspan="4" class="historical-chart-nowrap"><hr size="1" class="historical-chart-tooltip-hr"></td></tr>`;

    for (const point of points) {
      const { units: _units } = _data[point.series.name] || {};
      const kpiName = getKpiCategory(point.series.name);
      tooltip += `<tr>
        <td class="historical-chart-nowrap" style='${kpi}'>${kpiName}</td>
      </tr>
      <tr>
        <td class="historical-chart-nowrap" style='${kpi}'><span style='${kpiUnits}'>${point.y}</span> ${_units}</td>
      </tr>`;
    }

    tooltip += `</table>`;
    return tooltip;
  };

  return {
    series,
    xAxisLabelFormatter,
    xAxisLabelVisible,
    xAxisMaxPadding,
    yAxis,
    tooltipFormatter,
  };
};

const getInnerChartHeight = (height, innerHeightPercent) => {
  return height * innerHeightPercent;
};

// define plot options by data type
const getPlotOptions = ({ height, innerHeightPercent }) => {
  const plotOptions = {
    height: height ? getInnerChartHeight(height, innerHeightPercent) : CHART_HEIGHT,
  };

  return plotOptions;
};

export const siteHistoricalChartOptionsFactory = ({
  kpiKeys = [],
  colors,
  data = {},
  getKpiCategory,
  height,
  innerHeightPercent = 0.63,
  maxEntities,
  seriesType,
  parentEntity,
  theme,
  timeAggr,
}) => {
  const getOptions = {
    [KpiCategorySeriesType.TIME_SERIES]: getTimeSeriesChartOptions,
  }[seriesType];

  // get base options then massage based on chart type
  const chartOptions = getOptions({
    kpiKeys,
    colors,
    data,
    getKpiCategory,
    maxEntities,
    parentEntity,
    theme,
    timeAggr,
  });

  return mergeOptionsRight(
    getPlotOptions({ ...kpiKeys[0]?.key, height, innerHeightPercent }),
    chartOptions,
  );
};
