import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

import { HighlightIcon } from './highlight-icon';

const ROW_HEIGHT = '40px';

export const PanelContainer = styled.div`
  ${({ isLoading }) => {
    return isLoading
      ? css`
          position: relative;
          padding: 40px 20px;
        `
      : ``;
  }};
  .event-detail {
    ${({ isBulkEdit }) => {
      return isBulkEdit
        ? css`
            position: relative;
          `
        : css`
            position: absolute;
            right: 0;
            top: 0;
          `;
    }};
    width: 300px;
    height: calc(100% - 20px);
    &.bulk {
      padding: 18px 0 20px;
    }
    &.hide {
      display: none;
    }
    > div > div {
      position: relative;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  ${({ isEventSelected }) => {
    return isEventSelected
      ? css`
          height: 380px;
        `
      : ``;
  }};
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: ${ROW_HEIGHT};
`;

export const EntityTitle = styled.p`
  margin: 0;
  color: ${(props) => props.theme.analyze.manualAdjustment.chart.entityTitleColor};
  font-size: 11px;
  line-height: 13px;
  text-align: center;
`;

export const Timeline = styled.div`
  flex: 1 1 auto;
  padding: 0 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: ${ROW_HEIGHT};
`;

export const TimelineSlot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const TimelineLabel = styled.span`
  display: block;
  color: ${(props) => props.theme.analyze.manualAdjustment.chart.timelineColor};
  font-family: Helvetica;
  font-size: 10px;
  letter-spacing: 0.45px;
`;

export const TimelineGrid = styled.span`
  margin-top: 2px;
  display: block;
  width: 1px;
  height: 17px;
  background: ${(props) => props.theme.analyze.manualAdjustment.chart.timelineGridColor};
`;

export const EntityCell = styled.div`
  display: flex;
  flex: 0 0 120px;
  text-align: center;
  border-right: 1px solid ${(props) => props.theme.analyze.manualAdjustment.bordersColor};
  height: ${ROW_HEIGHT};
  align-items: center;
`;

export const EntityName = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.analyze.manualAdjustment.chart.entityNameColor};
  font-size: 14px;
`;

export const EntityArrow = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: 'transparent',
  rotate: -90,
}))`
  vertical-align: text-top;
  margin-left: 4px;
  margin-top: -1px;
  color: ${(props) => props.theme.analyze.manualAdjustment.chart.entityIconColor};
`;

export const Row = styled.div`
  background-color: ${(props) => props.theme.analyze.manualAdjustment.chart.rowBackgroundColor};
  &:nth-of-type(even) {
    background-color: ${(props) =>
      props.theme.analyze.manualAdjustment.chart.rowAltBackgroundColor};
  }
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const Cell = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin-right: 8px;
  ${({ isSelected }) => {
    return isSelected
      ? css`
          position: relative;
          height: 60px;
          align-items: baseline;
          border: 1px solid
            ${(props) => props.theme.analyze.manualAdjustment.chart.activeRowBorderColor};
          background-color: ${(props) =>
            props.theme.analyze.manualAdjustment.chart.activeRowBackgroundColor};
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: -16px;
            top: 50%;
            height: 14.19px;
            width: 14.19px;
            transform: translate(0, -50%) rotate(-45deg);
            background-color: ${(props) =>
              props.theme.analyze.manualAdjustment.eventDialog.backgroundColor};
            box-shadow: 0 1px 1px 0
              ${(props) => props.theme.analyze.manualAdjustment.eventDialog.shadowColor};
          }
        `
      : css``;
  }};
`;

export const SelectionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-height: ${ROW_HEIGHT};
`;

export const ChartRow = styled.div`
  flex: 1 1 auto;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: ${ROW_HEIGHT};
  height: ${ROW_HEIGHT};
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns:${(props) => `repeat(${props.numberOfColumns},  minmax(0, 1fr));`}
  grid-template-rows: 1fr;
`;

export const ChartBar = styled.div`
  ${({ isSelected }) => {
    return isSelected
      ? css`
          height: 30px;
        `
      : css`
          height: 15px;
        `;
  }};
  ${({ isClicked }) =>
    isClicked
      ? css`
          box-shadow: 0 0 4px 0 #85cddb;
          border: 1px solid rgba(28, 37, 45, 0.31);
        `
      : css``}
  opacity: 1;
  transition: opacity, 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;

export const EndingColumn = styled.div`
  display: flex;
  box-sizing: border-box;
  border-left: 1px solid ${(props) => props.theme.analyze.manualAdjustment.bordersColor};
  flex: ${(props) => (!props.autoWidth ? '0 0 300px' : '')};
`;

export const AssetKPI = styled.p`
  box-sizing: border-box;
  width: 100px;
  border-left: 1px solid ${(props) => props.theme.analyze.manualAdjustment.bordersColor};
  display: block;
  text-align: center;
  margin: 0;
  line-height: ${ROW_HEIGHT};
  &:first-of-type {
    border-left: none;
  }
`;

export const HighLightIcon = styled(HighlightIcon).attrs((props) => ({
  height: props.isClicked || props.isSelected ? '80%' : '120%',
  icon: Icons.HIGHLIGHT_ICON,
  viewbox: props.isClicked || props.isSelected ? '0 -10 160 32' : '0 0 161 22',
}))`
  margin-top: ${(props) => (props.isClicked || props.isSelected ? '18px' : '')};
  vertical-align: sub;
  transform: scaleY(2);
`;
