import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { Badge } from '@ge/components/badge';
import { Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { EntityType } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';

import HeaderCurrentCustomer from '../components/header-current-customer';
import HeaderCurrentSite from '../components/header-current-site';
import EntityDetailHeader, {
  HeaderIcon,
  ButtonIcon,
  TypeIcon,
  MetricIcon,
  StyledDetailsDismissInert,
} from '../entity-details-header';
import { DetailsCurrent } from '../entity-details-shared';
import EntityDetailsWeather from '../entity-details-weather';

export const EventDetailsHeader = withRouter(({ event, asset }) => {
  const { t } = useTranslation(['entity-details'], { useSuspense: false });
  const { showAssetDetails, showSiteDetails } = useContext(EntityDetailsContext);

  const { conditions } = asset.metrics || {};

  const handleEntitySelect = useCallback(
    (e, id, type) => {
      killEventPropagation(e);
      showAssetDetails(id, type);
    },
    [showAssetDetails],
  );

  const currentSiteClickHandler = useCallback(
    (siteId) => {
      showSiteDetails(siteId);
    },
    [showSiteDetails],
  );

  if (!event || !asset) {
    return null;
  }

  return (
    <EntityDetailHeader type={EntityType.EVENT}>
      <div className="left">
        <div className="related">
          <div className="asset-top">
            <StyledDetailsDismissInert className="left">
              <DetailsCurrent>
                <HeaderCurrentCustomer
                  customerName={event.site?.customer?.name}
                  siteName={event.site?.name}
                />
                <HeaderCurrentSite
                  siteName={event.site?.name}
                  onClick={() => currentSiteClickHandler(event.site?.id)}
                />
              </DetailsCurrent>
              <div className="asset-bottom">
                <button
                  type="button"
                  onClick={(e) => handleEntitySelect(e, event.asset?.id, EntityType.TURBINE)}
                >
                  <HeaderIcon icon={Icons.TURBINE} />
                  <Badge
                    color={getTurbineStateColor(event.asset?.state)}
                    label={getTurbineStateType(event.asset?.state)}
                    medium
                  />
                  &nbsp;
                  <div className="asset-name">{event.asset?.name}</div>
                  <ButtonIcon icon={Icons.CHEVRON} rotate={-90} />
                </button>
              </div>
            </StyledDetailsDismissInert>
            <div className="right">
              <div className="list-right">
                <div className="make-model">
                  {asset.make} {asset.model}
                </div>
                <EntityDetailsWeather weather={conditions} />
                <div className="phone">
                  <button type="button">
                    <MetricIcon icon={Icons.PHONE_CONTACT} />
                  </button>
                </div>
              </div>
              <div className="list-right">
                <div>
                  <MetricIcon icon={Icons.EVENT} />
                  {`${event.asset?.events}`}
                </div>
                <div>
                  <MetricIcon icon={Icons.ANOMALY} />
                  {`${event.asset?.anomalies}`}
                </div>
                <div>
                  <MetricIcon icon={Icons.TASK} />
                  {`${event.asset?.tasks}`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="left">
            <TypeIcon icon={Icons.EVENT} />
          </div>
          <div className="right">
            <div className="source-details">
              <span>Mup be zo wivibuf fefiho.</span>
              <div className="new">
                {t('new', 'New')}
                <HeaderIcon icon={Icons.PENCIL} />
              </div>
            </div>
            <div className="title">
              {event.priority && <Severity level={event.priority} />}
              {event.description}
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <button type="button">
          <HeaderIcon icon={Icons.LINK} /> {t('link', 'link')}
        </button>
        <button type="button">
          <HeaderIcon icon={Icons.NOTE} /> {t('add_note', 'add note')}
        </button>
        <button type="button">
          <HeaderIcon icon={Icons.TASK} /> {t('new_task', 'new task')}
        </button>
        <button type="button">
          <HeaderIcon icon={Icons.PENCIL} /> {t('log_action', 'log action')}
        </button>
        <HeaderIcon icon={Icons.PENCIL} /> {t('log_action', 'log action')}
        <NavLink
          type="button"
          className="last-btn"
          to={`/analyze/asset-overview?assets=${asset.id}`}
        >
          <HeaderIcon icon={Icons.OVERVIEW} /> {t('asset_overview', 'Asset Overview')}
        </NavLink>
      </div>
    </EntityDetailHeader>
  );
});

EventDetailsHeader.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  asset: PropTypes.instanceOf(Object).isRequired,
};

export default EventDetailsHeader;
