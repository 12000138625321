/* eslint-disable react/jsx-key */
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Menu, placements } from '@ge/components/menu';
import { Dialog } from '@ge/components/modal';
import { ManageDefs } from '@ge/models/constants';
import { TaskTrackers } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens';

import {
  getFilteredTasksTrackerData,
  getSelectedTaskTrackerData,
} from '../../util/tasks-tracker-util';

import ReorderIcon from './ReOrder.svg';
import CustomFilter from './custom-filter';
import FavoriteIcon from './favourite.svg';
import FilterButton from './filter-button';

const TasksTrackerContainer = styled.div`
  position: relative;
  top: 10px;
  display: flex;
  align-self: center;
  margin: auto;
  .taskTrackerBtn {
    background-color: ${(props) => props.theme.tasksTracker.buttonBackgroundColor};
    border-radius: 0;
    border-right: 1px solid ${(props) => props.theme.tasksTracker.buttonSeperatorColor};
    color: ${(props) => props.theme.tasksTracker.buttonTextColor};
    height: 41px;
    width: auto;
    padding: 2px 10px;
    vertical-align: middle;
  }
  .firstTrackerBtn {
    border-radius: 4px 0 0 4px;
  }
  .addIconBtn {
    height: 41px;
    min-width: 30px;
    border-radius: 0px 4px 4px 0px;
    background: ${(props) => props.theme.tasksTracker.addIconButtonColor};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  }
  .dropIconBtn {
    height: 41px;
    min-width: 30px;
    border-radius: 4px;
    margin-left: 8px;
    background-color: ${(props) => props.theme.tasksTracker.buttonBackgroundColor};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  }
  .selected {
    color: ${(props) => props.theme.tasksTracker.buttonSelectedTextColor};
  }
  .fav-icon {
    cursor: pointer;
    vertical-align: middle;
    margin-right: 5px;
  }
  .dragTaskTracker {
    cursor: grab;
  }
`;

const TaskTrackerMenuContainer = styled.div`
  width: 215px;
  .dragTaskTracker {
    cursor: grab;
  }
`;

const TaskTrackerMenuScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 350px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const TaskTrackerMenuBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 16px;
  border-top: 1px solid ${(props) => props.theme.tasksTracker.buttonSeperatorColor};
`;

const TaskTrackerMenuBtnSection = styled.div`
  display: flex;
`;

const TaskTrackerMenuButton = styled.span`
  color: ${(props) => props.theme.tasksTracker.whiteColor};
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  margin-left: 6px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledDialog = styled(Dialog)`
  height: auto;
  width: 406px;
  header {
    border-radius: 4px 4px 0 0;
    letter-spacing: 0.5px;
  }
  section {
    padding: 0;
  }
  .manageViewsSection {
    display: flex;
    justify-content: space-between;
  }
  .manageViewsIconSection {
    display: flex;
    align-items: center;
  }
  .manageViewsInput {
    height: 28px;
    width: 337px;
    margin-bottom: 6px;
    color: ${(props) => props.theme.tasksTracker.defaultViewsText};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    &:focus {
      outline: none;
    }
  }
  .isError {
    box-sizing: border-box;
    height: 28px;
    width: 338px;
    border: 1px solid ${(props) => props.theme.tasksTracker.inputTextError};
    border-radius: 2px;
    background-color: ${(props) => props.theme.tasksTracker.inputBackgroundColor};
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  }
  .manageViewsCancelBtn {
    height: 25px;
    width: 80px;
    margin-right: 15px;
    color: ${(props) => props.theme.tasksTracker.whiteColor};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border: 1px solid ${(props) => props.theme.tasksTracker.manageViewsCancelBtnColor};
    border-radius: 4px;
    background: linear-gradient(179.99deg, #38434e 0%, #2d3740 62.55%, #2a323b 100%);
  }
  .manageViewsSaveBtn {
    height: 25px;
    width: 120px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid ${(props) => props.theme.tasksTracker.manageViewsSaveBtnColor};
    border-radius: 2px;
    background: linear-gradient(180deg, #237b8c 0%, #1e6978 100%);
  }
`;

const ErrorText = styled.p`
  color: ${(props) => props.theme.tasksTracker.inputTextError};
  font-size: 10px;
  letter-spacing: 0.45px;
  line-height: 12px;
  margin: 0px 0px 14px;
`;

const CancelButton = styled.button`
  height: 19px;
  width: 67px;
  color: ${(props) => props.theme.tasksTracker.whiteColor};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.tasksTracker.reorderCancelBtnBorderColor};
  border-radius: 2px;
  background-color: ${(props) => props.theme.tasksTracker.buttonBackgroundColor};
`;

const SaveButton = styled.button`
  height: 19px;
  width: 65px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.tasksTracker.reorderSaveBtnBorderColor};
  border-radius: 2px;
  background: linear-gradient(180deg, #237b8c 0%, #1e6978 100%);
`;

const ManageViewContainer = styled.div`
  padding: 16px 25px 0px 18px;
  overflow-y: auto;
  max-height: 350px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const ManageViewsSectionHeader = styled.span`
  color: ${(props) => props.theme.tasksTracker.manageViewsHeaderText};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  margin-right: 16px;
`;

const DefaultViewSection = styled.div`
  margin-left: 20px;
`;

const MyViewSection = styled.div`
  margin: 15px 0;
`;

const ManageViewIcon = styled.span`
  margin: 4px;
  display: inline-block;
  vertical-align: middle;
`;

const MyViewContent = styled.div`
  margin: 5px 0px 0px 20px;
  &:empty {
    height: 30px;
  }
`;

const DefaultViewsText = styled.span`
  color: ${(props) => props.theme.tasksTracker.defaultViewsText};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 200;
  margin-bottom: 5px;
`;

const MyViewsDivider = styled.hr`
  box-sizing: border-box;
  height: 2px;
  border: 1px solid ${(props) => props.theme.tasksTracker.manageViewsDividerColor};
`;

const ManageViewsDivider = styled.hr`
  box-sizing: border-box;
  height: 2px;
  border: 1px solid ${(props) => props.theme.tasksTracker.buttonSeperatorColor};
  margin: 0px;
`;

const ManageViewsBtnContainer = styled.div`
  padding: 15px 0;
  margin-left: 166px;
`;

const ManageText = styled.div`
  color: ${(props) =>
    props.selected
      ? props.theme.tasksTracker.buttonSelectedTextColor
      : props.theme.tasksTracker.buttonTextColor};
  cursor: pointer;
  padding: 4px 16px;
  list-style: none;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
  img {
    padding-right: 4px;
  }
`;

const DropDown = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const ListIcon = styled(Icon)`
  cursor: grab;
  vertical-align: middle;
  margin-right: 5px;
`;

const DraggableContainer = styled.div`
  background-color: #38434e;
  ${({ isDropDown }) =>
    isDropDown &&
    `width: auto;
`}
  ${({ index }) =>
    index &&
    `border-radius: 4px 0 0 4px;
`}
  ${({ isDropDown }) =>
    !isDropDown &&
    `display: flex;
    flex-direction: row;
`}
  &.selectedDrag {
    background-color: #38434e;
    opacity: 1;
    border: 1.8px solid #0fbcd8;
    box-shadow: -1px -1px 4px 0 #1e696c, 1px 1px 4px 0 #1e6978;
  }
`;
const Divider = styled.div`
  box-sizing: border-box;
  background-color: #38434e;
  ${({ highlight, position }) => {
    if (highlight && position === TaskTrackers.POSITION.SIDE) {
      return `width: 5px;
      height: 41px;
    border-left: 1.8px solid #0fbcd8;
    border-right: 1.8px solid #0fbcd8;
    box-shadow: -1px -1px 4px 0 #1e696c, 1px 1px 4px 0 #1e6978;`;
    } else if (highlight && position === TaskTrackers.POSITION.TOP) {
      return `
      height:2px;
      width:auto
      border-top: 1.8px solid #0fbcd8;
      border-bottom: 1.8px solid #0fbcd8;
      box-shadow: -1px -1px 4px 0 #1e696c, 1px 1px 4px 0 #1e6978;`;
    }
  }}
`;

const generateId = (name) => name.toLowerCase().replace(/ /g, '_');

const TasksTracker = ({
  savePrefs,
  featPrefs,
  filters,
  sortMetric,
  sortDirection,
  isTasksLoading,
  loadCompletedTasks,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tasksTrackerCount, setTasksTrackerCount] = useState({});
  const [toggleManageViewsDialog, setToggleManageViewsDialog] = useState(false);
  const [tasksTrackerDataViews, setTasksTrackerDataViews] = useState([]);
  const [editTaskTrackerNames, setEditTaskTrackerNames] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [enableReorder, setEnableReorder] = useState(false);
  const [draggedTaskTracker, setDraggedTaskTracker] = useState({});
  const [currentDraggedIndex, setCurrentDraggedIndex] = useState(null);
  const [initialTasksTrackerData, setInitialTasksTrackerData] = useState([]);
  const draggedItemRef = useRef();
  const {
    tasksTrackerData,
    setTasksTrackerData,
    visibleTasks,
    setVisibleTask,
    taskPagesData: tasks,
  } = useContext(EntityDetailsContext);
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const TASKS_TRACKER = ManageDefs.TASKS_TRACKER;

  const showPencilIcon = useMemo(
    () =>
      tasksTrackerDataViews &&
      tasksTrackerDataViews.some((taskTracker) => !taskTracker?.defaultView),
    [tasksTrackerDataViews],
  );

  useEffect(() => {
    if (featPrefs && featPrefs[TASKS_TRACKER]) {
      setTasksTrackerData(featPrefs[TASKS_TRACKER]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featPrefs]);

  useEffect(() => {
    setVisibleTask(tasksTrackerData?.filter((filter) => filter?.visible));
  }, [setVisibleTask, tasksTrackerData]);

  useEffect(() => {
    if (tasks?.length) {
      const tasksTrackerCountObj = {};
      tasksTrackerData.map((taskTracker) => {
        const filterCount = getFilteredTasksTrackerData(tasks, taskTracker)?.length;
        tasksTrackerCountObj[taskTracker.name] = filterCount;
      });
      setTasksTrackerCount(tasksTrackerCountObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  useEffect(() => {
    toggleManageViewsDialog &&
      setTasksTrackerDataViews(
        tasksTrackerData.map((taskTracker) =>
          !taskTracker?.defaultView
            ? { ...taskTracker, [TaskTrackers?.ERROR]: false, [TaskTrackers?.ERROR_MSG]: '' }
            : taskTracker,
        ),
      );
  }, [tasksTrackerData, toggleManageViewsDialog]);

  const createCustomFilter = useCallback(
    (name) => {
      if (!name) return null;
      let _filters = [];
      for (const [key, value] of Object.entries(filters)) {
        if (value.value)
          _filters.push({
            name: key,
            value: value.value,
            ...(value?.min && { min: value?.min }),
            ...(value?.max && { max: value?.max }),
          });
      }
      return {
        name,
        filters: _filters?.length > 0 ? _filters : null,
        id: generateId(name),
        defaultView: false,
        visible: true,
        selected: true,
        sort: { name: sortMetric, value: sortDirection },
      };
    },
    [filters],
  );

  const transformTaskTrackerData = (taskTrackers, createEditView) => {
    const selectedTaskTracker = getSelectedTaskTrackerData(taskTrackers);
    if (selectedTaskTracker) {
      let selectedTaskTrackerIndex, toElement;
      if (createEditView) {
        const visibleTaskTrackers = taskTrackers?.filter((taskTracker) => taskTracker?.visible);
        selectedTaskTrackerIndex = visibleTaskTrackers?.findIndex(
          (visibleTask) => visibleTask?.id === selectedTaskTracker?.id,
        );
        toElement = visibleTaskTrackers[TaskTrackers?.MAX_FILTER_BUTTONS_INDEX];
      } else {
        selectedTaskTrackerIndex = visibleTasks?.findIndex(
          (visibleTask) => visibleTask?.id === selectedTaskTracker?.id,
        );
        toElement = visibleTasks[TaskTrackers?.MAX_FILTER_BUTTONS_INDEX];
      }
      if (selectedTaskTrackerIndex > TaskTrackers?.MAX_FILTER_BUTTONS_INDEX) {
        const fromIndex = taskTrackers.findIndex(
          (taskTracker) => taskTracker?.id === selectedTaskTracker?.id,
        );
        const toIndex = taskTrackers.findIndex((taskTracker) => taskTracker?.id === toElement?.id);
        const element = taskTrackers?.splice(fromIndex, 1)[0];
        taskTrackers?.splice(toIndex, 0, element);
      }
    }
  };

  const handleClick = (taskTrackerId, customFilterName) => {
    const updatedTasksTrackerData = tasksTrackerData.map((taskTracker = {}) => {
      let taskTrakerVal =
        taskTracker.id === taskTrackerId
          ? { ...taskTracker, selected: !taskTracker.selected }
          : taskTracker.selected
          ? { ...taskTracker, selected: false }
          : taskTracker;
      return taskTrakerVal;
    });

    const customFilter = createCustomFilter(customFilterName);

    if (!taskTrackerId && customFilter) {
      updatedTasksTrackerData.push(customFilter);
    }

    transformTaskTrackerData(
      updatedTasksTrackerData,
      customFilter && TaskTrackers?.CREATE_EDIT_VIEW,
    );

    savePrefs(updatedTasksTrackerData, TASKS_TRACKER);
  };

  const handleChange = (event, taskTracker) => {
    setIsEditing(true);
    const taskTrackerData = tasksTrackerData?.find(
      (taskTrackerData) => taskTrackerData?.id === taskTracker?.id,
    );
    setTasksTrackerDataViews(
      tasksTrackerDataViews.map((taskTrackerView) =>
        taskTrackerView?.id === taskTracker?.id
          ? event.target?.value?.toLowerCase() === taskTrackerData?.name?.toLowerCase()
            ? {
                ...taskTrackerView,
                name: event.target.value,
                [TaskTrackers?.ERROR_MSG]: TaskTrackers?.SAME_NAME_ERROR,
              }
            : !event.target.value.trim().length
            ? {
                ...taskTrackerView,
                name: event.target.value,
                [TaskTrackers?.ERROR_MSG]: TaskTrackers?.LENGTH_ERROR,
              }
            : event.target.value.length > 16
            ? { ...taskTrackerView }
            : {
                ...taskTrackerView,
                name: event.target.value,
                [TaskTrackers?.ERROR]: false,
                [TaskTrackers?.ERROR_MSG]: '',
                [TaskTrackers?.MAX_LENGTH]: false,
              }
          : taskTrackerView,
      ),
    );
  };

  const handleDelete = (taskTracker) => {
    setTasksTrackerDataViews((prevState) =>
      prevState.filter((taskTrackerView) => taskTrackerView?.id !== taskTracker?.id),
    );
  };

  const toggleManageViews = (e) => {
    killEventPropagation(e);
    setAnchorEl(null);
    setToggleManageViewsDialog(true);
  };

  const handleManageViews = (taskTracker) => {
    setTasksTrackerDataViews((prevState) =>
      prevState.map((taskTrackerView) =>
        taskTrackerView?.id === taskTracker?.id
          ? { ...taskTrackerView, visible: !taskTrackerView?.visible }
          : taskTrackerView,
      ),
    );
  };

  const closeManageViews = () => {
    setTasksTrackerDataViews([]);
    setEditTaskTrackerNames(false);
    setToggleManageViewsDialog(false);
  };

  const saveManageViews = () => {
    setIsEditing(false);
    if (editTaskTrackerNames) {
      const filteredTaskTrackerDataViews = tasksTrackerDataViews.map((taskTrackerView) => {
        const isDuplicate = tasksTrackerDataViews.some(
          (taskTracker) =>
            taskTracker?.id !== taskTrackerView?.id &&
            taskTracker?.name?.toLowerCase() === taskTrackerView?.name?.toLowerCase(),
        );
        if (isDuplicate) {
          return taskTrackerView?.[TaskTrackers?.ERROR_MSG] === TaskTrackers?.SAME_NAME_ERROR
            ? taskTrackerView
            : { ...taskTrackerView, [TaskTrackers?.ERROR_MSG]: TaskTrackers?.DUPLICATE_ERROR };
        } else {
          return taskTrackerView?.errorMsg === TaskTrackers?.DUPLICATE_ERROR
            ? { ...taskTrackerView, [TaskTrackers?.ERROR]: false, [TaskTrackers?.ERROR_MSG]: '' }
            : taskTrackerView;
        }
      });
      const updatedTasksTrackerDataViews = filteredTaskTrackerDataViews.map((taskTrackerView) =>
        !taskTrackerView?.defaultView && taskTrackerView?.[TaskTrackers?.ERROR_MSG].length > 0
          ? { ...taskTrackerView, [TaskTrackers?.ERROR]: true }
          : { ...taskTrackerView, id: generateId(taskTrackerView?.name) },
      );
      setTasksTrackerDataViews(updatedTasksTrackerDataViews);
      if (
        !updatedTasksTrackerDataViews.some(
          (taskTrackerView) => taskTrackerView?.[TaskTrackers?.ERROR],
        )
      ) {
        updatedTasksTrackerDataViews.forEach(
          (taskTracker) =>
            TaskTrackers?.ERROR in taskTracker &&
            delete taskTracker?.[TaskTrackers?.ERROR] &&
            TaskTrackers?.ERROR_MSG in taskTracker &&
            delete taskTracker?.[TaskTrackers?.ERROR_MSG] &&
            TaskTrackers?.MAX_LENGTH in taskTracker &&
            delete taskTracker?.[TaskTrackers?.MAX_LENGTH],
        );
        transformTaskTrackerData(updatedTasksTrackerDataViews, TaskTrackers?.CREATE_EDIT_VIEW);
        savePrefs(updatedTasksTrackerDataViews, TASKS_TRACKER);
        setEditTaskTrackerNames(false);
        setToggleManageViewsDialog(false);
      }
    } else {
      transformTaskTrackerData(tasksTrackerDataViews, TaskTrackers?.CREATE_EDIT_VIEW);
      savePrefs(tasksTrackerDataViews, TASKS_TRACKER);
      setEditTaskTrackerNames(false);
      setToggleManageViewsDialog(false);
    }
  };

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleDrag = (event, taskTracker, index) => {
    draggedItemRef.current = index;
    event.preventDefault();
    setDraggedTaskTracker(taskTracker);
  };

  const handleDrop = (event, droppedTaskTracker) => {
    event.preventDefault();
    const reorderTasksTrackerData = new Array(...tasksTrackerData);
    const fromIndex = reorderTasksTrackerData.findIndex(
      (taskTracker) => taskTracker?.id === draggedTaskTracker?.id,
    );
    const toIndex = reorderTasksTrackerData.findIndex(
      (taskTracker) => taskTracker?.id === droppedTaskTracker?.id,
    );
    const element = reorderTasksTrackerData.splice(fromIndex, 1)[0];
    reorderTasksTrackerData.splice(toIndex, 0, element);
    setCurrentDraggedIndex(null);
    setDraggedTaskTracker({});
    setTasksTrackerData(reorderTasksTrackerData);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setCurrentDraggedIndex(index);
  };

  const saveReorder = () => {
    setEnableReorder(false);
    savePrefs(tasksTrackerData, TASKS_TRACKER);
  };

  const getStyles = (index) => (draggedItemRef.current === index ? 'selectedDrag' : '');
  return (
    <>
      <TasksTrackerContainer>
        {visibleTasks?.map(
          (taskTracker, index) =>
            taskTracker?.visible &&
            index <= TaskTrackers.MAX_FILTER_BUTTONS_INDEX && (
              <DraggableContainer
                isDropDown={false}
                className={Object.keys(draggedTaskTracker).length > 0 ? getStyles(index) : ''}
                index={index === 0}
                key={index}
              >
                <Divider
                  highlight={currentDraggedIndex === index}
                  position={TaskTrackers.POSITION.SIDE}
                />
                <FilterButton
                  taskTracker={taskTracker}
                  handleClick={handleClick}
                  tasksTrackerCount={tasksTrackerCount}
                  key={taskTracker?.id}
                  enableReorder={enableReorder}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  identifier={index}
                  onDragStart={() => (draggedItemRef.current = index)}
                  isTasksLoading={isTasksLoading}
                  loadCompletedTasks={loadCompletedTasks}
                />
              </DraggableContainer>
            ),
        )}
        <CustomFilter
          handleClick={handleClick}
          tasksTrackerData={tasksTrackerData}
          enableReorder={enableReorder}
        />
        <DropDown>
          <Button className={'dropIconBtn'} onClick={handleShowMenu}>
            <StyledIcon icon={Icons.CHEVRON} color={globalColors.white} size={11} />
          </Button>
        </DropDown>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          placement={placements.BOTTOM_END}
        >
          <TaskTrackerMenuContainer>
            <TaskTrackerMenuScrollContainer>
              {visibleTasks?.map(
                (taskTracker, index) =>
                  taskTracker?.visible &&
                  index > TaskTrackers.MAX_FILTER_BUTTONS_INDEX && (
                    <DraggableContainer
                      isDropDown={true}
                      className={Object.keys(draggedTaskTracker).length > 0 ? getStyles(index) : ''}
                      key={index}
                    >
                      <Divider
                        highlight={currentDraggedIndex === index}
                        position={TaskTrackers.POSITION.TOP}
                      />
                      <ManageText
                        className={`${enableReorder ? TaskTrackers.DRAG_TASK_TRACKER : ''}`}
                        selected={taskTracker?.selected}
                        onClick={() => !enableReorder && handleClick(taskTracker?.id)}
                        key={taskTracker?.id}
                        draggable={enableReorder}
                        onDrag={(event) => handleDrag(event, taskTracker, index)}
                        onDrop={(event) => handleDrop(event, taskTracker)}
                        onDragOver={(event) => handleDragOver(event, index)}
                        onDragStart={() => (draggedItemRef.current = index)}
                      >
                        {!taskTracker?.defaultView && !enableReorder ? (
                          <img
                            src={FavoriteIcon}
                            alt={t('tasks_tracker.fav_icon', 'favorite-icon')}
                            className="fav-icon"
                          />
                        ) : (
                          enableReorder && (
                            <ListIcon
                              icon={Icons.LIST}
                              color={
                                taskTracker?.selected ? globalColors.white : globalColors.slate3
                              }
                              size={11}
                            />
                          )
                        )}
                        <span>{taskTracker?.name}</span>
                      </ManageText>
                    </DraggableContainer>
                  ),
              )}
            </TaskTrackerMenuScrollContainer>
            <TaskTrackerMenuBtnContainer>
              <TaskTrackerMenuBtnSection>
                {!enableReorder ? (
                  <div onClick={toggleManageViews}>
                    <StyledIcon
                      icon={Icons.SETTINGS}
                      color={globalColors.slate5}
                      size={12}
                      className={'manageText'}
                    />
                    <TaskTrackerMenuButton className={'manageText'}>
                      {t('tasks_tracker.manage', 'MANAGE')}
                    </TaskTrackerMenuButton>
                  </div>
                ) : (
                  <CancelButton
                    onClick={() => {
                      setEnableReorder(false);
                      setTasksTrackerData(initialTasksTrackerData);
                    }}
                  >
                    {t('tasks_tracker.cancel', 'Cancel')}
                  </CancelButton>
                )}
              </TaskTrackerMenuBtnSection>
              {enableReorder && (
                <TaskTrackerMenuBtnSection>
                  {!enableReorder ? (
                    <div
                      onClick={() => {
                        setEnableReorder(true);
                        setInitialTasksTrackerData(tasksTrackerData);
                      }}
                    >
                      <img
                        src={ReorderIcon}
                        alt={t('tasks_tracker.reorder_icon', 'reorder-icon')}
                        className="reorderText"
                      />
                      <TaskTrackerMenuButton className={'reorderText'}>
                        {t('tasks_tracker.reorder', 'REORDER')}
                      </TaskTrackerMenuButton>
                    </div>
                  ) : (
                    <SaveButton primary onClick={saveReorder}>
                      {t('tasks_tracker.save', 'Save')}
                    </SaveButton>
                  )}
                </TaskTrackerMenuBtnSection>
              )}
            </TaskTrackerMenuBtnContainer>
          </TaskTrackerMenuContainer>
        </Menu>
      </TasksTrackerContainer>
      {toggleManageViewsDialog && (
        <StyledDialog
          isOpen={true}
          onClose={closeManageViews}
          header={t('tasks_tracker.manage_views', 'Manage Views')}
        >
          <ManageViewContainer>
            <DefaultViewSection>
              <ManageViewsSectionHeader>
                {t('tasks_tracker.default_views', 'Default Views')}
              </ManageViewsSectionHeader>
              {tasksTrackerDataViews.map(
                (taskTracker) =>
                  taskTracker?.defaultView && (
                    <div className={'manageViewsSection'} key={taskTracker?.id}>
                      <DefaultViewsText>
                        {t(`tasks_tracker.${taskTracker?.id}`, taskTracker?.name)}
                      </DefaultViewsText>
                      <div
                        onClick={() => handleManageViews(taskTracker)}
                        className={'manageViewsIconSection'}
                      >
                        {taskTracker?.visible ? (
                          <StyledIcon
                            icon={Icons.VISIBILITY_ON}
                            color={globalColors.slate5}
                            size={20}
                          />
                        ) : (
                          <StyledIcon
                            icon={Icons.VISIBILITY_OFF}
                            color={globalColors.slate5}
                            size={20}
                          />
                        )}
                      </div>
                    </div>
                  ),
              )}
            </DefaultViewSection>
            <MyViewsDivider />
            <MyViewSection>
              <ManageViewIcon>
                <img src={FavoriteIcon} alt={t('tasks_tracker.fav_icon', 'favorite-icon')} />
              </ManageViewIcon>
              <ManageViewsSectionHeader>
                {t('tasks_tracker.my_views', 'My Views')}
              </ManageViewsSectionHeader>
              <span onClick={() => setEditTaskTrackerNames(true)}>
                {!editTaskTrackerNames && showPencilIcon && (
                  <StyledIcon icon={Icons.PENCIL} color={globalColors.slate5} size={12} />
                )}
              </span>
              <MyViewContent>
                {tasksTrackerDataViews.map((taskTracker) =>
                  !taskTracker?.defaultView && editTaskTrackerNames ? (
                    <>
                      <Input
                        required
                        type="text"
                        value={taskTracker?.name}
                        onChange={(e) => handleChange(e, taskTracker)}
                        key={taskTracker?.id}
                        className={`manageViewsInput ${
                          taskTracker?.[TaskTrackers?.ERROR] && !isEditing && 'isError'
                        }`}
                      />
                      {taskTracker?.[TaskTrackers?.ERROR] && !isEditing && (
                        <ErrorText>
                          {t(`tasks_tracker.${taskTracker?.[TaskTrackers?.ERROR_MSG]}`)}
                        </ErrorText>
                      )}
                    </>
                  ) : (
                    !taskTracker?.defaultView && (
                      <div className={'manageViewsSection'} key={taskTracker?.id}>
                        <DefaultViewsText>
                          {t(`tasks_tracker.${taskTracker?.id}`, taskTracker?.name)}
                        </DefaultViewsText>
                        <div
                          onClick={() => handleDelete(taskTracker)}
                          className={'manageViewsIconSection'}
                        >
                          <StyledIcon icon={Icons.TRASH} color={globalColors.slate5} size={12} />
                        </div>
                      </div>
                    )
                  ),
                )}
              </MyViewContent>
            </MyViewSection>
          </ManageViewContainer>
          <ManageViewsDivider />
          <ManageViewsBtnContainer>
            <Button onClick={closeManageViews} className={'manageViewsCancelBtn'}>
              {t('tasks_tracker.cancel', 'Cancel')}
            </Button>
            <Button primary onClick={saveManageViews} className={'manageViewsSaveBtn'}>
              {t('tasks_tracker.save', 'Save')}
            </Button>
          </ManageViewsBtnContainer>
        </StyledDialog>
      )}
    </>
  );
};

TasksTracker.propTypes = {
  savePrefs: PropTypes.func,
  featPrefs: PropTypes.object,
  filters: PropTypes.object,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  isTasksLoading: PropTypes.bool,
  loadCompletedTasks: PropTypes.bool,
};

export default TasksTracker;
