export const chartOptions = {
  chart: {
    backgroundColor: 'transparent',
    marginBottom: 45,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 15,
    polar: false,
    showAxes: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    tooltip: {
      headerFormat: null,
      pointFormat: null,
    },
  },
  series: [
    {
      states: {
        select: {
          borderColor: 'transparent',
        },
      },
    },
  ],
  title: {
    text: null,
  },
  tooltip: {
    borderColor: 'transparent',
    borderRadius: 9,
    outside: true,
    useHTML: true,
  },
  xAxis: {
    gridLineWidth: 0,
    labels: {
      align: 'center',
      enabled: true,
      fontSize: '10px',
    },
    maxPadding: 0.03,
    minPadding: 0,
    tickLength: 5,
    visible: true,
    plotLines: null,
  },
  yAxis: {
    endOnTick: true,
    labels: {
      align: 'right',
      x: 0,
      y: -4,
    },
    maxPadding: 0.02,
    minPadding: 0.02,
    opposite: true,
    offset: 16,
    title: {
      text: null,
    },
    visible: true,
  },
};
