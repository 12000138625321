/* eslint-disable react-hooks/exhaustive-deps */

import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AnnotatorConstants } from '../../constants';

import hand from './icons/hand.svg';
import image_icon from './icons/images_icon.svg';
import pointer from './icons/pointer.svg';
import polygon from './icons/polygon.svg';
import rectangle from './icons/rectangle.svg';
import ruler from './icons/ruler.svg';
import zoomIcon from './icons/ui_zoom-white.svg';
import SmallToolButton, { SelectedTool } from './small-tool-button';

const IconToolsContainer = styled.div`
  background-color: ${(props) => props.theme.postProcess.iconToolsBackground};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1px;
  z-index: 9;
`;

const ToolIcon = styled.img`
  display: block;
  height: 24px;
  width: 24px;
  color: white;
`;

const defaultTools = [
  AnnotatorConstants.SELECT,
  AnnotatorConstants.CREATE_POINT,
  AnnotatorConstants.CREATE_BOX,
  AnnotatorConstants.CREATE_POLYGON,
  AnnotatorConstants.CREATE_RULER,
  AnnotatorConstants.CREATE_EXPANDING_LINE,
  AnnotatorConstants.IMAGE_ENHANCER,
];

const POST_PROCESSING_NAMESPACE = 'inspections.post-process';

const IconTools = ({
  updateSelectedAnnotationId,
  selectedTool,
  onClickTool,
  enabledTools = defaultTools,
}) => {
  const { t } = useTranslation();
  const translations = {
    selectTool: t(`${POST_PROCESSING_NAMESPACE}.tools.select`, 'Select Region'),
    pan: t(`${POST_PROCESSING_NAMESPACE}.tools.pan`, 'Drag/Pan'),
    createBox: t(`${POST_PROCESSING_NAMESPACE}.tools.create_box`, 'Add Bounding Box'),
    createPolygon: t(`${POST_PROCESSING_NAMESPACE}.tools.create_polygon`, 'Add Polygon'),
    createRuler: t(`${POST_PROCESSING_NAMESPACE}.tools.create_ruler`, 'Add ruler'),
    imageEnhance: t(`${POST_PROCESSING_NAMESPACE}.tools.image_enhancer`, 'Image Enhancer'),
  };

  const selectedToolContextValue = useMemo(
    () => ({ updateSelectedAnnotationId, enabledTools, selectedTool, onClickTool }),
    [enabledTools, selectedTool],
  );

  return (
    <IconToolsContainer>
      <SelectedTool.Provider value={selectedToolContextValue}>
        <SmallToolButton
          alwaysShowing
          id={AnnotatorConstants.PAN}
          name={translations.pan}
          icon={<ToolIcon src={hand} alt={translations.pan} />}
        />
        <SmallToolButton
          id={AnnotatorConstants.SELECT}
          name={translations.selectTool}
          icon={<ToolIcon src={pointer} alt={translations.selectTool} />}
        />
        <SmallToolButton
          id={AnnotatorConstants.CREATE_BOX}
          name={translations.createBox}
          icon={<ToolIcon src={rectangle} alt={translations.createBox} />}
        />
        <SmallToolButton
          id={AnnotatorConstants.CREATE_POLYGON}
          name={translations.createPolygon}
          icon={<ToolIcon src={polygon} alt={translations.createPolygon} />}
        />
        <SmallToolButton
          id={AnnotatorConstants.CREATE_RULER}
          name={translations.createRuler}
          icon={<ToolIcon src={ruler} alt={translations.createRuler} />}
        />
        <SmallToolButton
          id={AnnotatorConstants.IMAGE_ENHANCER}
          name={translations.imageEnhance}
          icon={<ToolIcon src={image_icon} alt={translations.imageEnhance} />}
        />
        <SmallToolButton
          alwaysShowing
          id="zoom"
          name="Zoom In/Out"
          icon={<ToolIcon src={zoomIcon} />}
        />
      </SelectedTool.Provider>
    </IconToolsContainer>
  );
};

IconTools.propTypes = {
  updateSelectedAnnotationId: PropTypes.func,
  selectedTool: PropTypes.string,
  onClickTool: PropTypes.func,
  enabledTools: PropTypes.arrayOf(PropTypes.string),
};

export default IconTools;
