import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { killEventPropagation } from '@ge/shared/util/general';

const FilterIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SEARCH,
  color: props.theme.themeSelector.filterIconColor,
}))``;

export const Filter = ({
  className,
  placeholder,
  text,
  onKeyDown,
  onChange,
  onInputBlur,
  onInputEscaped,
  showResults,
  hideResultsOnBlur,
}) => (
  <div className={`filter ${className}`}>
    <input
      type="text"
      value={text}
      onChange={onChange}
      placeholder={placeholder}
      onFocus={(event) => {
        event.target.placeholder = '';
        showResults(true);
      }}
      onKeyUp={(e) => {
        killEventPropagation(e);
        if (e.key === 'Escape') onInputEscaped(e);
      }}
      onKeyDown={onKeyDown}
      onBlur={(event) => {
        event.target.placeholder = placeholder;
        if (event.target.value.length === 0 && hideResultsOnBlur) showResults(false);
        onInputBlur(event);
      }}
    />
    <FilterIcon className="search-icon" />
  </div>
);

Filter.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onInputBlur: PropTypes.func,
  onInputEscaped: PropTypes.func,
  showResults: PropTypes.func,
  hideResultsOnBlur: PropTypes.bool,
};

Filter.defaultProps = {
  text: '',
  placeholder: '',
  onKeyDown: () => {},
  onChange: () => {},
  onInputBlur: () => {},
  showResults: () => {},
  hideResultsOnBlur: true,
};
