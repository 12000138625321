// @flow
import colorAlpha from 'color-alpha';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { AnnotatorConstants, imagePropType, pointPropType, regionPropType } from '../../constants';

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}

const RegionComponents = {
  point: memo(({ region, iw, ih }) => (
    <g transform={`translate(${region.x * iw} ${region.y * ih})`}>
      <path d="M0 8L8 0L0 -8L-8 0Z" strokeWidth={2} stroke={region.color} fill="transparent" />
    </g>
  )),
  box: memo(({ id, region, iw, ih }) => (
    <g id={`annotation_${id}`} transform={`translate(${region.x * iw} ${region.y * ih})`}>
      <rect
        strokeWidth={4}
        x={0}
        y={0}
        width={region.w * iw}
        height={region.h * ih}
        stroke={colorAlpha(region.color, 0.5)}
        fill={colorAlpha(region.color, 0)}
      />
    </g>
  )),
  polygon: memo(({ id, region, iw, ih }) => {
    const Component = region.open ? 'polyline' : AnnotatorConstants.POLYGON;
    return (
      <Component
        id={`annotation_${id}`}
        points={region.points
          .map(([x, y]) => [x * iw, y * ih])
          .map((a) => a.join(' '))
          .join(' ')}
        strokeWidth={4}
        stroke={colorAlpha(region.color, 0.5)}
        // Andy: original alpth was 0.25
        fill={colorAlpha(region.color, 0)}
      />
    );
  }),
  ruler: memo(({ id, region, iw, ih }) => {
    const Component = 'polyline';
    return (
      <g>
        <Component
          id={`annotationRuler1_${id}`}
          points={region.points
            .slice(0, 2)
            .map(([x, y]) => [x * iw, y * ih])
            .map((a) => a.join(' '))
            .join(' ')}
          strokeWidth={10}
          stroke={colorAlpha('#b7b893', 0.5)}
          fill={colorAlpha(region.color, 0)}
        />
        <Component
          id={`annotationRuler2_${id}`}
          points={region.points
            .slice(0, 2)
            .map(([x, y]) => [x * iw, y * ih])
            .map((a) => a.join(' '))
            .join(' ')}
          strokeWidth={1}
          strokeDasharray="1 2"
          stroke={colorAlpha('#333333', 0.5)}
          fill={colorAlpha(region.color, 0)}
        />
        <Component
          id={`annotationRuler3_${id}`}
          points={region.points
            .slice(0, 2)
            .map(([x, y]) => [x * iw, y * ih])
            .map((a) => a.join(' '))
            .join(' ')}
          strokeWidth={4}
          strokeDasharray="1 9"
          stroke={colorAlpha('#333333', 0.9)}
          fill={colorAlpha(region.color, 0)}
        />
        <Component
          id={`annotationRuler3_${id}`}
          points={region.points
            .slice(0, 2)
            .map(([x, y]) => [x * iw, y * ih])
            .map((a) => a.join(' '))
            .join(' ')}
          strokeWidth={8}
          strokeDasharray="1 49"
          stroke={colorAlpha('#333333', 0.9)}
          fill={colorAlpha(region.color, 0)}
        />
      </g>
    );
  }),
  // eslint-disable-next-line react/display-name
  [AnnotatorConstants.EXPANDING_LINE]: memo(({ region, iw, ih }) => {// eslint-disable-line prettier/prettier, react/prop-types
    // expandingLine: memo(({ region, iw, ih }) => {
    let { expandingWidth = 0.005, points } = region; // eslint-disable-line prettier/prettier, react/prop-types
    expandingWidth = points.slice(-1)[0].width || expandingWidth; // eslint-disable-line prettier/prettier, react/prop-types
    const pointPairs = points.map(({ x, y, angle, width }, i) => { // eslint-disable-line prettier/prettier, react/prop-types
      if (!angle) {
        const n = points[clamp(i + 1, 0, points.length - 1)]; // eslint-disable-line prettier/prettier, react/prop-types
        const p = points[clamp(i - 1, 0, points.length - 1)]; // eslint-disable-line prettier/prettier, react/prop-types
        angle = Math.atan2(p.x - n.x, p.y - n.y) + Math.PI / 2; // eslint-disable-line prettier/prettier, react/prop-types
      }
      const dx = (Math.sin(angle) * (width || expandingWidth)) / 2;
      const dy = (Math.cos(angle) * (width || expandingWidth)) / 2;
      return [
        { x: x + dx, y: y + dy },
        { x: x - dx, y: y - dy },
      ];
    });
    const firstSection = pointPairs.map(([p1]) => p1);
    const secondSection = pointPairs.map(([, p2]) => p2).asMutable();
    secondSection.reverse();
    const lastPoint = points.slice(-1)[0]; // eslint-disable-line prettier/prettier, react/prop-types
    return (
      <>
        <polygon
          points={firstSection
            .concat(region.candidatePoint ? [region.candidatePoint] : []) // eslint-disable-line prettier/prettier, react/prop-types
            .concat(secondSection)
            .map((p) => `${p.x * iw} ${p.y * ih}`)
            .join(' ')}
          strokeWidth={4}
          stroke={colorAlpha(region.color, 0.5)} // eslint-disable-line prettier/prettier, react/prop-types
          fill={colorAlpha(region.color, 0)} // eslint-disable-line prettier/prettier, react/prop-types
        />
        {points.map(({ x, y, angle }) => ( // eslint-disable-line prettier/prettier, react/prop-types
          <g
            key={`${x}_${y}_${angle}`}
            transform={`translate(${x * iw} ${y * ih}) rotate(${(-(angle || 0) * 180) / Math.PI})`}
          >
            <g>
              <rect
                x={-5}
                y={-5}
                width={10}
                height={10}
                strokeWidth={4}
                stroke={colorAlpha(region.color, 0.5)} // eslint-disable-line prettier/prettier, react/prop-types
                fill={colorAlpha(region.color, 0)} // eslint-disable-line prettier/prettier, react/prop-types
              />
            </g>
          </g>
        ))}
        <rect
          x={lastPoint.x * iw - 8}
          y={lastPoint.y * ih - 8}
          width={16}
          height={16}
          strokeWidth={4}
          stroke={colorAlpha(region.color, 0.5)} // eslint-disable-line prettier/prettier, react/prop-types
          fill="transparent"
        />
      </>
    );
  }),
  pixel: () => null,
};

RegionComponents.displayName = 'RegionComponents';
RegionComponents.point.displayName = 'RegionComponents.point';
RegionComponents.point.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: regionPropType,
  iw: PropTypes.number,
  ih: PropTypes.number,
};

RegionComponents.box.displayName = 'RegionComponents.box';
RegionComponents.box.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: regionPropType,
  iw: PropTypes.number,
  ih: PropTypes.number,
};

RegionComponents.polygon.displayName = 'RegionComponents.polygon';
RegionComponents.polygon.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: regionPropType,
  iw: PropTypes.number,
  ih: PropTypes.number,
};

RegionComponents.ruler.displayName = 'RegionComponents.ruler';
RegionComponents.ruler.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: regionPropType,
  iw: PropTypes.number,
  ih: PropTypes.number,
};

RegionComponents[AnnotatorConstants.EXPANDING_LINE].displayName = 'RegionComponents.expanding-line';
RegionComponents[AnnotatorConstants.EXPANDING_LINE].propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: regionPropType,
  iw: PropTypes.number,
  ih: PropTypes.number,
};

export const WrappedRegionList = memo(
  ({ regions, iw, ih, fullSegmentationMode }) =>
    regions
      .filter((r) => r.visible !== false)
      .filter((r) => !r.isAnnotationHidden)
      .map((r) => {
        const Component = RegionComponents[r.type];
        return (
          <Component
            key={r.regionId || r.id}
            id={r.id}
            region={r}
            iw={iw}
            ih={ih}
            fullgeSegmentationMode={fullSegmentationMode}
          />
        );
      }),
  (n, p) => n.regions === p.regions && n.iw === p.iw && n.ih === p.ih,
);

WrappedRegionList.displayName = 'WrappedRegionList';
WrappedRegionList.propTypes = {
  id: PropTypes.number,
  regions: PropTypes.arrayOf(regionPropType),
  iw: PropTypes.number,
  ih: PropTypes.number,
  fullSegmentationMode: PropTypes.bool,
  sso: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const RegionShapes = ({
  imagePosition,
  regions = [],
  fullSegmentationMode,
  currentImage,
}) => {
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  if (isNaN(iw) || isNaN(ih)) return null;
  //This is the polygon line
  if (!currentImage) return null;
  return (
    <svg
      id={`image${currentImage.id}`}
      width={iw}
      height={ih}
      style={{
        position: 'absolute',
        zIndex: 2,
        left: imagePosition.topLeft.x,
        top: imagePosition.topLeft.y,
        pointerEvents: 'none',
        width: iw,
        height: ih,
      }}
      viewBox={`0 0 ${iw} ${ih}`}
    >
      <WrappedRegionList
        regions={regions}
        iw={iw}
        ih={ih}
        fullSegmentationMode={fullSegmentationMode}
      />
    </svg>
  );
};

RegionShapes.propTypes = {
  imagePosition: PropTypes.shape({
    bottomRight: pointPropType,
    topLeft: pointPropType,
  }),
  // expandingLine: PropTypes.instanceOf(Object),
  regions: PropTypes.arrayOf(regionPropType),
  fullSegmentationMode: PropTypes.bool,
  currentImage: imagePropType,
};

RegionShapes.defaultProps = {};

export default RegionShapes;
