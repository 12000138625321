import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { elevations } from '@ge/tokens/elevations';

import SettingsHeader from './shared/settings-header';

const AddButton = styled(Button)`
  padding: 2px 8px;
`;

const WatchlistContainer = styled.div`
  max-height: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: ${elevations.P10};
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: ${(props) => props.theme.sidebar.boxShadow};
    th {
      height: 13px;
      color: ${(props) => props.theme.sidebar.textColor};
      font-family: 'Museo Sans';
      text-align: left;
      padding: 4px 10px;
    }
    tbody {
      tr {
        font-size: 12px;
        line-height: 30px;
        height: 30px;
        background: ${(props) => props.theme.sidebar.evenRowBackground};
        &:nth-child(odd) {
          background: ${(props) => props.theme.sidebar.oddRowBackground};
        }
        td {
          padding: 0 10px;
        }
      }
    }
  }
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CLOSE,
  color: props.theme.sidebar.settingsIconColor,
}))``;

const ListTypeIcon = styled(Icon).attrs((props) => ({
  color: props.theme.sidebar.listIconColor,
}))``;

const ListActionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.sidebar.listActionIconColor,
}))``;

const watchlist = [
  {
    id: '5e4dbaaf424fadbc5bc094c1',
    type: 'TURBINE',
    name: 'WT 2391',
    view: 'overview',
  },
  {
    id: '5e4dgsklo424fadbc5bc094c1',
    type: 'FLEET',
    name: 'Market Price',
    view: 'US, current',
  },
  {
    id: '5e4dbaaf424hdeklp5bc094c1',
    type: 'SITE',
    name: 'ST Augustin',
    view: 'Labor Costs, last 30 days',
  },
  {
    id: '5e4dbabdf42adbc5bc094c1',
    type: 'TURBINE',
    name: 'WT 4406',
    view: 'Overview',
  },
];

const WatchlistSettings = ({ onHideSettings }) => {
  const { t, ready } = useTranslation(['general'], { useSuspense: false });
  if (!ready) {
    return <Loader />;
  }
  return (
    <WatchlistContainer>
      <button type="button" className="close-btn" onClick={onHideSettings}>
        <CloseIcon />
      </button>
      <SettingsHeader
        title={`${t('sidebar.watchlist', 'Watchlist')} ${t('settings', 'settings')}`}
        icon={Icons.WATCHLIST}
      />
      <table>
        <thead>
          <tr>
            <th>{t('sidebar.watchlist_table.type', 'Type')}</th>
            <th>{t('sidebar.watchlist_table.name', 'Name')}</th>
            <th>{t('sidebar.watchlist_table.view', 'View')}</th>
            <th>
              <AddButton primary>+</AddButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {watchlist.map((item) => {
            const icon = Icons[item.type];
            return (
              <tr key={item.id}>
                <td width={20}>
                  <ListTypeIcon icon={icon} size={14} />
                </td>
                <td>{item.name}</td>
                <td>{item.view}</td>
                <td width={20}>
                  <button type="button">
                    <ListActionIcon icon={Icons.PENCIL} size={12} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </WatchlistContainer>
  );
};

WatchlistSettings.propTypes = {
  onHideSettings: PropTypes.func,
};

WatchlistSettings.defaultProps = {
  onHideSettings: () => null,
};

export default WatchlistSettings;
