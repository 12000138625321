import { PropTypes } from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { MiniLoader } from '@ge/components/loader';
import { TaskTemplateSections } from '@ge/models/constants';
import { TaskTemplateModes } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { useTaskTemplate } from '@ge/shared/data-hooks';

import { AssignedCompletedTechsSection } from './sections/assigned-techs-section/assigned-completedtechs-section';
import { PartsConsumedSection } from './sections/parts-consumed-section/parts-consumed-section';
import { ResolutionNoteSection } from './sections/resolution-note-section/resolution-note-section';
import { TimingSection } from './sections/timing-section/timing-section';

export const CloseTaskTemplate = ({
  task,
  taskPanelControls,
  templateMode,
  type,
  workerState,
  setWorkerState,
  removeWorker,
  deleteWrkrIds,
  entity,
  isLoadingMultiplePerson,
}) => {
  const {
    taskState: { taskTemplate, setTaskTemplate, setTimezone },
  } = useContext(TaskContext);

  useEffect(() => {
    setTimezone(task?.site?.timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, data: temp } = useTaskTemplate(task.source);
  useEffect(() => {
    temp && setTaskTemplate(temp);
  }, [setTaskTemplate, temp]);

  if (!taskTemplate) {
    return null;
  }

  if (isLoading) return <MiniLoader />;

  return (
    <>
      {taskTemplate.sections?.map((section, i) => {
        switch (section.type) {
          case TaskTemplateSections.TIMING:
            return (
              <TimingSection
                key={i}
                section={section}
                task={task}
                taskPanelControls={taskPanelControls}
                templateMode={templateMode}
              />
            );
          case TaskTemplateSections.RESOLUTION_NOTE:
            return (
              <ResolutionNoteSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
                entity={entity}
              />
            );
          case TaskTemplateSections.ASSIGNEDTECH:
            return (
              <AssignedCompletedTechsSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
                workerState={workerState}
                setWorkerState={setWorkerState}
                removeWorker={removeWorker}
                deleteWrkrIds={deleteWrkrIds}
                isLoadingMultiplePerson={isLoadingMultiplePerson}
              />
            );
          case TaskTemplateSections.PARTS_CONSUMED:
            return (
              <PartsConsumedSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
                type={type}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

CloseTaskTemplate.propTypes = {
  task: PropTypes.instanceOf(Object),
  taskPanelControls: PropTypes.node,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  type: PropTypes.String,
  workerState: PropTypes.instanceOf(Object),
  setWorkerState: PropTypes.func,
  removeWorker: PropTypes.func,
  deleteWrkrIds: PropTypes.instanceOf(Object),
  entity: PropTypes.instanceOf(Object),
  isLoadingMultiplePerson: PropTypes.instanceOf(Boolean).isRequired,
};

CloseTaskTemplate.defaultProps = {
  task: {},
  taskPanelControls: null,
  entity: {},
};
