import React from 'react';

import { Icons } from '@ge/components/icon';
import { AuthStrategy, Capability, PermissionScope } from '@ge/models/constants';

import { AppFeatures } from '../../../models/features';
import { ViewSelectorSite } from '../../dropdown/view-selector-site';

import NavItem from './nav-item';

// Build the L1 nav items with minimal content. There is no scope associated with
// any of these because the permissions are defined at the L2 nav item level.
const monitorNavItem = new NavItem({
  label: 'Monitor',
  i18nKey: 'nav:monitor.l1',
  route: '/monitor',
});
const analyzeNavItem = new NavItem({
  label: 'Analyze',
  i18nKey: 'nav:analyze.l1',
  route: '/analyze',
});
const executeNavItem = new NavItem({
  label: 'Execute',
  i18nKey: 'nav:manage.l1',
  route: '/execute',
});
const adminNavItem = new NavItem({
  label: 'Admin',
  i18nKey: 'nav:admin.l1',
  route: '/admin',
});
const inspectionsNavItem = new NavItem({
  label: 'Inspect',
  i18nKey: 'nav:inspect.l1',
  route: '/inspect',
});
const reportingsNavItem = new NavItem({
  label: 'Reporting',
  i18nKey: 'nav:reporting.l1',
  route: '/reporting',
  sticky: true,
});

const analyzeSitesSelectorNavItem = new NavItem({
  label: 'Site Selector',
  i18nKey: 'nav:analyze.l3.site_selector',
  dropdownComponent: <ViewSelectorSite />,
});

/*
  TODO: Not yet implemented - DE168797
  const analyzeAssetsNavItem = new NavItem({
    label: 'All Assets',
    i18nKey: 'nav:analyze.l3.all_assets',
    route: '/analyze/assets',
  });
*/

const navMonitorL2Issues = {
  capabilities: [
    { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
  ],
  i18nKey: 'nav:monitor.l2.issues',
  icon: Icons.ANOMALY,
  label: 'Issues',
  parent: monitorNavItem,
  ...AppFeatures.MONITOR_ISSUES,
};

const navAdminL2Configure = {
  capabilities: [{ capability: Capability.FULL_ADMIN, scopes: [PermissionScope.VIEW] }],
  label: 'Configure',
  icon: Icons.SETTINGS,
  i18nKey: 'nav:admin.l2.configure',
  parent: adminNavItem,
  ...AppFeatures.ADMIN_CONFIGURE,
};

const navMonitorL2Sites = {
  capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
  i18nKey: 'nav:monitor.l2.sites',
  label: 'Sites',
  icon: Icons.SITE,
  parent: monitorNavItem,
  ...AppFeatures.MONITOR_SITES,
};

const l2NavItems = [
  // Monitor L2 NavItems
  new NavItem(navMonitorL2Issues),

  // Hiding Assets tab from Monitor
  // new NavItem({
  //   capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
  //   i18nKey: 'nav:monitor.l2.assets',
  //   label: 'Assets',
  //   parent: monitorNavItem,
  //   ...AppFeatures.MONITOR_ASSETS,
  // }),
  new NavItem(navMonitorL2Sites),
  // Analyze L2 NavItems
  // Hiding MAL from left NAV
  new NavItem({
    capabilities: [
      { capability: Capability.CONTRACTUAL_AVAILABILITY_MAL, scopes: [PermissionScope.VIEW] },
    ],
    i18nKey: 'nav:analyze.l2.manual_adjustment',
    label: 'Manual Adjustment',
    icon: Icons.SCHEDULE,
    parent: analyzeNavItem,
    ...AppFeatures.ANALYZE_MANUAL_ADJUSTMENT,
  }),
  new NavItem({
    capabilities: [
      { capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] },
      { capability: Capability.DATA_EXPLORER, scopes: [PermissionScope.EDIT] },
    ],
    authStrategy: AuthStrategy.ANY,
    i18nKey: 'nav:analyze.l2.asset_overview',
    label: 'Assets',
    icon: Icons.TURBINE,
    parent: analyzeNavItem,
    ...AppFeatures.ANALYZE_ASSET_OVERVIEW,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.CORE_KPIS, scopes: [PermissionScope.VIEW] }],
    children: [analyzeSitesSelectorNavItem],
    i18nKey: 'nav:analyze.l2.sites',
    label: 'Sites',
    icon: Icons.SITE,
    parent: analyzeNavItem,
    ...AppFeatures.ANALYZE_SITES,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.FRO_KPIS, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:analyze.l2.fleet_explorer',
    label: 'Fleet Explorer',
    icon: Icons.FLEET,
    parent: analyzeNavItem,
    ...AppFeatures.ANALYZE_FLEET_OVERVIEW,
  }),

  // Manage L2 NavItems
  new NavItem({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:manage.l2.cases_tasks',
    label: 'Tasks',
    icon: Icons.TASK,
    parent: executeNavItem,
    route: 'execute/cases-tasks',
    ...AppFeatures.MANAGE_CASES_TASKS,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:manage.l2.overview',
    label: 'Overview',
    icon: Icons.CALENDAR,
    parent: executeNavItem,
    ...AppFeatures.MANAGE_OVERVIEW,
  }),

  // Inspection L2 NavItems
  // #RENINSPECT MIGRATION NOTES#
  // need to verify that these are the right capablities for these navs
  // also there appears to be fewer nav items here than there are routes defined in app.jsx
  new NavItem({
    capabilities: [
      { capability: Capability.REI_INSPECTION_DASHBOARD, scopes: [PermissionScope.VIEW] },
    ],
    label: 'Analytics Dashboard',
    icon: Icons.ANALYTICS,
    i18nKey: 'nav:inspect.l2.analytics',
    parent: inspectionsNavItem,
    ...AppFeatures.INSPECTIONS_ANALYTICS,
  }),
  new NavItem({
    capabilities: [
      { capability: Capability.REI_INSPECTION_DASHBOARD, scopes: [PermissionScope.VIEW] },
    ],
    label: 'Performance Overview',
    icon: Icons.PERFORMANCE,
    i18nKey: 'nav:inspect.l2.performance',
    parent: inspectionsNavItem,
    ...AppFeatures.INSPECTIONS_PERFORMANCE,
  }),
  new NavItem({
    capabilities: [
      { capability: Capability.REI_INSPECTION_REPORTS, scopes: [PermissionScope.VIEW] },
    ],
    label: 'Inspection Reports',
    icon: Icons.BLADE_DAMAGES,
    i18nKey: 'nav:inspect.l2.reports',
    parent: inspectionsNavItem,
    ...AppFeatures.INSPECTIONS_REPORTS,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] }],
    label: 'Results',
    icon: Icons.NAV_CHECK,
    i18nKey: 'nav:inspect.l2.results',
    parent: inspectionsNavItem,
    ...AppFeatures.INSPECTIONS_RESULTS,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.REI_LISTS, scopes: [PermissionScope.VIEW] }],
    label: 'Lists',
    icon: Icons.TASK,
    i18nKey: 'nav:inspect.l2.lists',
    parent: inspectionsNavItem,
    ...AppFeatures.INSPECTIONS_LIST,
  }),

  // Admin L2 NavItems
  /**
   * TODO : uncomment to enable configure route in admin panel (Distribution list)
   * ! deferred for PI-3 sprint-5
   * * new NavItem(navAdminL2Configure),
   */
  new NavItem({
    capabilities: [{ capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] }],
    label: 'Roles',
    icon: Icons.TECHS,
    i18nKey: 'nav:admin.l2.roles',
    parent: adminNavItem,
    ...AppFeatures.ADMIN_ROLES,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] }],
    label: 'People',
    icon: Icons.PERSON,
    i18nKey: 'nav:admin.l2.people',
    parent: adminNavItem,
    ...AppFeatures.ADMIN_PEOPLE,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.ANALYTICS, scopes: [PermissionScope.ADMIN] }],
    label: 'Application Configuration',
    icon: Icons.SETTINGS,
    i18nKey: 'nav:admin.l2.configure',
    parent: adminNavItem,
    parentGroupLabel: 'Analyze',
    ...AppFeatures.ADMIN_ANALYZE_APPLICATION_CONFIGURATION,
    childSubMenu: [
      new NavItem({
        capabilities: [{ capability: Capability.ANALYTICS, scopes: [PermissionScope.ADMIN] }],
        i18nKey: 'nav:monitor.l3.admin.application-configuration',
        label: 'Case Template',
        icon: Icons.SETTINGS,
        parent: navAdminL2Configure,
        ...AppFeatures.ADMIN_ANALYZE_CASE_TEMPLATES,
      }),
      new NavItem({
        capabilities: [{ capability: Capability.ANALYTICS, scopes: [PermissionScope.ADMIN] }],
        i18nKey: 'nav:monitor.l3.admin.application-configuration',
        label: 'Analysis Template',
        icon: Icons.SETTINGS,
        parent: navAdminL2Configure,
        ...AppFeatures.ADMIN_ANALYZE_ANALYSIS_TEMPLATES,
      }),
    ],
  }),
  new NavItem({
    capabilities: [
      { capability: Capability.FULL_ADMIN, scopes: [PermissionScope.ADMIN] },
      { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.ADMIN] },
      { capability: Capability.FAULT_HANDLING, scopes: [PermissionScope.ADMIN] },
      { capability: Capability.ALARM_MANAGEMENT, scopes: [PermissionScope.ADMIN] },
    ],
    label: 'Application Configuration',
    authStrategy: AuthStrategy.ALL,
    icon: Icons.SETTINGS,
    i18nKey: 'nav:admin.l2.configure',
    parent: adminNavItem,
    parentGroupLabel: 'Monitor',
    ...AppFeatures.ADMIN_MONITOR_APPLICATION_CONFIGURATION,
    childSubMenu: [
      new NavItem({
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.ADMIN] },
        ],
        i18nKey: 'nav:monitor.l3.admin.application-configuration',
        label: 'Distribution Lists',
        icon: Icons.SETTINGS,
        parent: navAdminL2Configure,
        ...AppFeatures.ADMIN_CONFIGURE_DISTRIBUTION_LIST,
      }),
      new NavItem({
        capabilities: [
          { capability: Capability.ALARM_MANAGEMENT, scopes: [PermissionScope.ADMIN] },
        ],
        i18nKey: 'nav:monitor.l3.admin.application-configuration',
        label: 'Event Management',
        icon: Icons.SETTINGS,
        parent: navAdminL2Configure,
        ...AppFeatures.ADMIN_CONFIGURE_EVENT_MANAGEMENT,
      }),
      new NavItem({
        capabilities: [{ capability: Capability.FAULT_HANDLING, scopes: [PermissionScope.ADMIN] }],
        i18nKey: 'nav:monitor.l3.admin.application-configuration',
        label: 'Handling Procedures',
        icon: Icons.SETTINGS,
        parent: navAdminL2Configure,
        ...AppFeatures.ADMIN_CONFIGURE_HANDLING_PROCEDURES,
      }),
    ],
  }),
  new NavItem({
    capabilities: [{ capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:reporting.l2.reports',
    icon: Icons.EMAIL,
    label: 'Reports',
    parent: reportingsNavItem,
    ...AppFeatures.REPORTING_REPORTS,
  }),
];

export const l3NavItems = [
  new NavItem({
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
    ],
    i18nKey: 'nav:monitor.l3.issues.anomalies',
    label: 'Anomalies',
    parent: navMonitorL2Issues,
    ...AppFeatures.MONITOR_ISSUES_ANOMALIES,
  }),
  new NavItem({
    i18nKey: 'nav:monitor.l3.sites.storage',
    label: 'Storage',
    parent: navMonitorL2Sites,
    ...AppFeatures.MONITOR_SITES_STORAGE,
  }),
  new NavItem({
    i18nKey: 'nav:monitor.l3.sites.wind',
    label: 'Wind',
    parent: navMonitorL2Sites,
    ...AppFeatures.MONITOR_SITES_WIND,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:monitor.l3.issues.case_history',
    label: 'Cases History',
    parent: navMonitorL2Issues,
    ...AppFeatures.MONITOR_ISSUES_HISTORY,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:monitor.l3.issues.case_queue',
    label: 'Case Queue',
    parent: navMonitorL2Issues,
    ...AppFeatures.MONITOR_ISSUES_EVENTS,
  }),
  new NavItem({
    capabilities: [{ capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] }],
    i18nKey: 'nav:monitor.l3.admin.distribution-list',
    label: 'Distribution List',
    parent: navAdminL2Configure,
    ...AppFeatures.ADMIN_CONFIGURE_DISTRIBUTION_LISTS,
  }),
];

export default l2NavItems;
