import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SpinLoader } from '@ge/components/loader';
import useTaskNotes from '@ge/shared/data-hooks/use-task-notes';

const StyledTitle = styled.div`
  background: ${({ theme }) => theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${({ theme }) => theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-task-count {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 294px;
  max-height: 200px;
  overflow: auto;
`;

const TaskRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.menu.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;

  &:first-of-type {
    border: none;
  }
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const TaskTitle = styled.div`
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
`;

const DetailColumn = styled.div`
  width: 100%;
`;

export const NotesMenu = ({ taskId, titleKey, titleDefault }) => {
  const { t } = useTranslation(['tasks']);
  const { notes, isLoading } = useTaskNotes({
    caseId: taskId,
  });

  const NotesItems = notes?.map((note, idx) => (
    <TaskRow key={`${note?.id}-${idx}`}>
      <DetailColumn>
        <TaskTitle>{note?.note}</TaskTitle>
      </DetailColumn>
    </TaskRow>
  ));

  return (
    <>
      <StyledTitle>
        <h4 className="menu-title-txt">{t(`menu.${titleKey}`, titleDefault)}</h4>
      </StyledTitle>
      {isLoading ? (
        <SpinLoader showLoader={isLoading} className="spinner" />
      ) : notes.length > 0 ? (
        <Content>{NotesItems}</Content>
      ) : (
        <Content>
          <TaskRow>
            <DetailColumn>
              <TaskTitle>{t('menu.no_data', 'No Data')}</TaskTitle>
            </DetailColumn>
          </TaskRow>
        </Content>
      )}
    </>
  );
};

NotesMenu.propTypes = {
  taskId: PropTypes.string,
  titleKey: PropTypes.string.isRequired,
  titleDefault: PropTypes.string.isRequired,
};

NotesMenu.defaultProps = {
  taskId: null,
};
