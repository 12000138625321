import { request } from './api';

// #RENINSPECT MIGRATION NOTES#
// cut this over to standard request api after bff is added

export const getDataForReportGeneration = (jobId, options) =>
  request.get(`/rei/reports/report-data/${jobId}`, options);

export const updateReport = (jobId, data, options) =>
  request.post(`/rei/reports/update-report/${jobId}`, data, options);

export const reportComplete = (jobId, data, options) =>
  request.post(`/rei/reports/complete/${jobId}`, data, options);

export const getReportStageInfo = (jobId, options) =>
  request.get(`/rei/reports/report-stage-info/${jobId}`, options);
