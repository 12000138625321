import { getLoadable } from '@ge/shared/util';

// Define the available pages for lazy loading.
export const loadables = {
  issues: getLoadable(() => import('./issues')),
  assets: getLoadable(() => import('./assets')),
  reports: getLoadable(() => import('./reports')),
  sites: getLoadable(() => import('./sites')),
};

// @index('./**/*.jsx', f => `export * from '${f.path}';`)
export * from './assets';
export * from './issues';
export * from './reports';
export * from './sites';
// @endindex
