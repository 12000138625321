import { action, useLocalStore, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect } from 'react';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { EventMetric } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

const defaultState = {
  causalEventId: null,
  events: [],
  fhpEvent: null,
  selectedEvent: null,
  sortActiveDirection: SortDirection.DESC,
  sortActiveMetric: EventMetric.START,
};

const storeActions = {
  entityChanged: action((_, events) => {
    const causalEvent = events && events.find(({ causal }) => causal);

    return {
      ...defaultState,
      causalEventId: causalEvent && causalEvent.id,
      events: events.sort(sorter(EventMetric.START, SortDirection.DESC)),
    };
  }),
  setCausalEventId: action((state, id) => ({
    ...state,
    causalEventId: state.causalEventId === id ? null : id,
  })),
  setFhpEvent: action((state, event) => ({
    ...state,
    fhpEvent: event,
  })),
  setSelectedEvent: action((state, event) => ({
    ...state,
    fhpEvent: null,
    selectedEvent: event,
  })),
  sortBy: action((state, metric) => {
    const { events, sortActiveDirection, sortActiveMetric } = state;
    let direction;

    if (sortActiveMetric === metric) {
      direction =
        sortActiveDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      direction = sortActiveDirection;
    }

    return {
      ...state,
      events: events.sort(sorter(metric, direction)),
      sortActiveDirection: direction,
      sortActiveMetric: metric,
    };
  }),
};

const AssetEventsContext = createContext();

export const AssetEventsProvider = ({ children }) => {
  // global state
  const { events } = useStoreState((state) => state.issues);

  // context state and actions
  const [state, actions] = useLocalStore(() => ({ ...defaultState, ...storeActions }));
  const { entityChanged, setCausalEventId, setFhpEvent, setSelectedEvent, sortBy } = actions;

  // update context store if global entity state changes
  useEffect(() => {
    entityChanged(
      // copy into new array to preserve store state
      [...Object.values(events || [])],
    );
  }, [entityChanged, events]);

  // handlers to conveniently wrap dispatches for exposing to the consumer
  const handleSetCausalEventId = useCallback((id) => setCausalEventId(id), [setCausalEventId]);
  const handleSetFhpEvent = useCallback((event) => setFhpEvent(event), [setFhpEvent]);
  const handleSetSelectedEvent = useCallback(
    (event) => setSelectedEvent(event),
    [setSelectedEvent],
  );
  const handleSortBy = useCallback((metric) => sortBy(metric), [sortBy]);

  // expose context store to app in a way that we control
  const provider = {
    state,
    actions,
    handlers: {
      handleSetCausalEventId,
      handleSetFhpEvent,
      handleSetSelectedEvent,
      handleSortBy,
    },
  };

  return <AssetEventsContext.Provider value={provider}>{children}</AssetEventsContext.Provider>;
};

AssetEventsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAssetEventsContext = () => useContext(AssetEventsContext);
