import has from 'lodash/has';
import includes from 'lodash/includes';
import moment from 'moment';
import { svgAsPngUri } from 'save-svg-as-png';

import { AnnotatorConstants } from '@ge/components/image-annotator';
import { InspectionTypes } from '@ge/models/constants';

import { FileConstants, SourceVideoNamePlaceholder, Blades } from '../models/constants';
import { getPresignedUrl, putFilesByPresignedUrl } from '../services/files';

import calOrientedBoundingBox from './orientedBoundingBox';

export const hashCode = (str) => {
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const isSabre = (inspection) =>
  inspection && inspection.inspection_type === InspectionTypes.SABRE;
export const isBurst = (inspection) =>
  inspection && inspection.inspection_type === InspectionTypes.BURST;
export const isPinwheel = (inspection) =>
  inspection && inspection.inspection_type === InspectionTypes.PINWHEEL;
export const isInternal = (inspection) =>
  inspection && inspection.inspection_type === InspectionTypes.INTERNAL;
export const isInternalGEBlade = (inspection) =>
  inspection && inspection.inspection_type === InspectionTypes.INTERNALGEBLADE;
export const isDrone = (inspection) =>
  inspection && inspection.inspection_type === InspectionTypes.DRONE;

export const isJpegFile = (path) => Boolean(path.match(/(\.jpg|\.jpeg)$/i));
export const isPngFile = (path) => Boolean(path.match(/\.png$/i));
export const isJsonFile = (path) => Boolean(path.match(/\.json$/i));
export const isWavFile = (path) => Boolean(path.match(/\.wav$/i));
export const isMp4File = (path) => Boolean(path.match(/\.mp4$/i));

export const isImageFromThisSabreVideo = ({ img = 'img', vdo = 'vdo' }) =>
  vdo.slice(0, vdo.lastIndexOf('.')) === img;

export const get_cod_date = (Date) => {
  return moment(Date).format('DD-MMM-YYYY');
};

export const hanldeDateFormat = (Date) => {
  return moment(Date, moment.defaultFormat).toDate();
};

export const calculateAnnotationChordWidth = ({ annotation, scaling = 0 }) => {
  const waypoints =
    typeof annotation.waypoints === 'string'
      ? JSON.parse(annotation.waypoints)
      : annotation.waypoints;
  const { chord: origChord, length: origLength, width: origWidth, annotation_type } = annotation;
  if (scaling === 0) {
    return {
      chord: origChord,
      length: origLength,
      width: origWidth,
    };
  }

  if (
    annotation_type === AnnotatorConstants.POLYGON ||
    annotation_type === AnnotatorConstants.BOX
  ) {
    /*
          TODO: calculate the actual minimum bounding box of a given polygon.
          For example: https://geidav.wordpress.com/2014/01/23/computing-oriented-minimum-bounding-boxes-in-2d/
        */
    let chord;
    let length;
    let width;

    const bb = calOrientedBoundingBox(waypoints);
    const d1 = Math.sqrt(
      (bb[0].x - bb[1].x) * (bb[0].x - bb[1].x) + (bb[0].y - bb[1].y) * (bb[0].y - bb[1].y),
    );
    const d2 = Math.sqrt(
      (bb[1].x - bb[2].x) * (bb[1].x - bb[2].x) + (bb[1].y - bb[2].y) * (bb[1].y - bb[2].y),
    );

    if (d1 > d2) {
      chord = d1;
      length = d1;
      width = d2;
    } else {
      chord = d2;
      length = d2;
      width = d1;
    }
    return {
      chord: (chord * scaling).toFixed(2),
      length: (length * scaling).toFixed(2),
      width: (width * scaling).toFixed(2),
    };
  }

  return {
    chord: origChord,
    length: origLength,
    width: origWidth,
  };
};

export const generateAnnotatedImageName = ({ img, annotationId }) => {
  if (!img) return;
  if (!annotationId) return;
  const { imgname, source_video_filename } = img.attributes;
  const reImgExt = new RegExp(/.(jpg|png|svg)$/i);

  let idx = 0;
  let annotated_imgname = '';
  if (source_video_filename === SourceVideoNamePlaceholder.BURST) {
    idx = imgname.indexOf('input/');
    annotated_imgname = `${imgname.slice(0, idx + 6).replace('input', 'output')}${imgname
      .slice(idx + 6)
      .replace(reImgExt, `_${annotationId}.$1`)}`;
  } else if (source_video_filename === SourceVideoNamePlaceholder.PINWHEEL) {
    idx = imgname.indexOf('input/');
    annotated_imgname = `${imgname.slice(0, idx + 6).replace('input', 'output')}${imgname
      .slice(idx + 6)
      .replace(reImgExt, `_${annotationId}.$1`)}`;
  } else if (source_video_filename === SourceVideoNamePlaceholder.INTERNAL) {
    idx = imgname.indexOf('input/');
    annotated_imgname = `${imgname.slice(0, idx + 6).replace('input', 'output')}${imgname
      .slice(idx + 6)
      .replace(reImgExt, `_${annotationId}.$1`)}`;
  } else if (source_video_filename === SourceVideoNamePlaceholder.DRONE) {
    idx = imgname.indexOf('input/');
    annotated_imgname = `${imgname.slice(0, idx + 6).replace('input', 'output')}${imgname
      .slice(idx + 6)
      .replace(reImgExt, `_${annotationId}.$1`)}`;
  } else {
    idx = imgname.indexOf(source_video_filename);
    annotated_imgname = `${imgname.slice(0, idx)}annotated/${imgname
      .slice(idx)
      .replace(reImgExt, `_${annotationId}.$1`)}`;
  }
  return annotated_imgname;
};

// This is the reverse function of generateAnnotatedImageName()
export const getAnnotationIdFromAnnotatedImageName = ({ imgName }) => {
  const re = new RegExp(/_(\d+).(jpg|png|svg)$/i);
  const m = imgName.match(re);
  return +m?.[1];
};

export const getBladeNumberFromSabreImageName = (imgName) => {
  // ex: TWTG S 263_Flatridge 2020_2020_10_12_002_F_000358_B_01.png
  const m = imgName.match(/_F_(\d+)_B_(\d\d)\./);
  if (m) return Number(m[2]);
  return 0;
};

export const filename = (url) => url.substring(url.lastIndexOf('/') + 1);
export const dirName = (path) => path.slice(0, path.lastIndexOf('/') + 1);

export const gets3ImageSrc = (imgname) => {
  const inspectionType = imgname.slice(0, imgname.indexOf('/'));
  if (inspectionType === InspectionTypes.BURST) {
    return `${dirName(imgname)}${filename(imgname)}`;
  } else if (inspectionType === InspectionTypes.PINWHEEL) {
    return `${dirName(imgname)}${filename(imgname)}`;
  } else if (inspectionType === InspectionTypes.INTERNAL) {
    return `${dirName(imgname)}${filename(imgname)}`;
  } else if (inspectionType === InspectionTypes.INTERNALGEBLADE) {
    return `${dirName(imgname)}${filename(imgname)}`;
  } else if (inspectionType === InspectionTypes.DRONE) {
    return `${dirName(imgname)}${filename(imgname)}`;
  } else if (inspectionType === InspectionTypes.SABRE) {
    return `${dirName(imgname)}${filename(imgname)}`;
  } else {
    return `${dirName(imgname)}jpg/${filename(imgname).replace('.png', '.jpg')}`;
  }
};

export const getInspectionTypeFromS3Path = (path) => {
  if (!path) return null;
  const inspectionType = path.slice(0, path.indexOf('/'));
  return inspectionType;
};

export const isCrawler360Image = (path) => {
  if (!path) return false;
  if (getInspectionTypeFromS3Path(path) === InspectionTypes.INTERNALGEBLADE) {
    if (path.match(/(-m\d*)\.png$/i)) {
      // this is a 2D image
      return false;
    }
    return true;
  }
  return false;
};

export const getFilename = (path) => path.substring(path.lastIndexOf('/') + 1);

export const getCommaSeparatedFilenames = (files) =>
  files?.map((file) => file.replace(/,/g, FileConstants.COMMA)).join(',');

export const calculateBladeAxisByInputs = ({ axis1_number, axis2_number, axis3_number, col }) => {
  /*
    examples:
    axis1_number, axis2_number, axis3_number
    1, 2, 3
            col   1   2   3
    blade1_axis   1   0   0
    blade2_axis   0   2   0
    blade3_axis   0   0   3
    
    0, 3, 0
            col   1   2   3
    blade1_axis  -1   0   0
    blade2_axis   0   0  -2
    blade3_axis   0   2   0
    '', 2, ''
            col   1   2   3
    blade1_axis       0
    blade2_axis       2
    blade3_axis       0
    2, 0, 1
            col   1   2   3
    blade1_axis   0   0   1
    blade2_axis   2   0   0
    blade3_axis   0  -3   0
  */
  const axes = {
    blade1_axis: 0,
    blade2_axis: 0,
    blade3_axis: 0,
  };
  const axesHaveValues = (arr1, arr2) =>
    (arr1[0] === arr2[0] && arr1[1] === arr2[1]) || (arr1[0] === arr2[1] && arr1[1] === arr2[0]);
  /* eslint-disable */
  // for easy reading
  if (col === 1) {
    if (axis1_number > 0) return { ...axes, [`blade${axis1_number}_axis`]: axis1_number };
    if (axesHaveValues([axis2_number, axis3_number], [1, 2])) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axesHaveValues([axis2_number, axis3_number], [1, 3])) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axesHaveValues([axis2_number, axis3_number], [2, 3])) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axesHaveValues([axis2_number, axis3_number], [0, 1])) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axesHaveValues([axis2_number, axis3_number], [0, 2])) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axesHaveValues([axis2_number, axis3_number], [0, 3])) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axis2_number === 0 && axis3_number === 0) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axis1_number === ''){
      return { ...axes, blade1_axis: Blades.ONE_UNKNOWN}
    }
  } else if (col === 2) {
    if (axis2_number > 0) return { ...axes, [`blade${axis2_number}_axis`]: axis2_number };
    if (axesHaveValues([axis1_number, axis3_number], [1, 2])) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axesHaveValues([axis1_number, axis3_number], [1, 3])) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axesHaveValues([axis1_number, axis3_number], [2, 3])) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axesHaveValues([axis1_number, axis2_number], [0, 1])) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axis1_number === 0 && axis3_number === 2) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axis1_number === 2 && axis3_number === 0) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axis1_number === 0 && axis3_number === 3) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axis1_number === 3 && axis3_number === 0) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axis1_number === 0 && axis3_number === 0) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axis2_number === ''){
      return { ...axes, blade2_axis: Blades.TWO_UNKNOWN}
    }
  } else if (col === 3) {
    if (axis3_number > 0) return { ...axes, [`blade${axis3_number}_axis`]: axis3_number };
    if (axesHaveValues([axis1_number, axis2_number], [1, 2])) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axesHaveValues([axis1_number, axis2_number], [1, 3])) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axesHaveValues([axis1_number, axis2_number], [2, 3])) return { ...axes, blade1_axis: Blades.ONE_UNKNOWN };
    if (axesHaveValues([axis1_number, axis2_number], [0, 1])) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axesHaveValues([axis1_number, axis2_number], [0, 2])) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axesHaveValues([axis1_number, axis2_number], [0, 3])) return { ...axes, blade2_axis: Blades.TWO_UNKNOWN };
    if (axis1_number === 0 && axis2_number === 0) return { ...axes, blade3_axis: Blades.THREE_UNKNOWN };
    if (axis3_number === ''){
      return { ...axes, blade3_axis: Blades.THREE_UNKNOWN}
    }
  }
  /* eslint-enable */
  return axes;
};

export const saveAnnotationAsPng = ({ image, polygonId, annotationId }) => {
  const { height, width } = image.attributes;
  const annotated_imgname = generateAnnotatedImageName({ img: image, annotationId });

  const origSvgEl = document.querySelector(`#image${image.id}`);
  const svgEl = origSvgEl.cloneNode(true);
  if (svgEl.childElementCount > 0) {
    const newAddedAnnotationIndex = Array.from(svgEl.childNodes).findIndex(
      (n) => n.id === `annotation_${polygonId}`,
    );
    const newAddedAnnotation = svgEl.removeChild(svgEl.childNodes[newAddedAnnotationIndex]);
    newAddedAnnotation.setAttribute('stroke-width', 3);
    newAddedAnnotation.setAttribute('stroke', '#ff0000');
    svgEl.innerHTML = '';
    svgEl.appendChild(newAddedAnnotation);
    svgEl.setAttribute('width', width);
    svgEl.setAttribute('height', height);
    svgEl.removeAttribute('style');

    svgAsPngUri(svgEl).then((uri) => {
      fetch(uri)
        .then((res) => res.blob())
        .then((blob) => {
          getPresignedUrl({
            file: annotated_imgname,
            type: 'put',
            expires: 60 * 60 * 12,
          }).then((result) => {
            const options = {};
            const presignedUrl = result.signedUrls[0].url;
            putFilesByPresignedUrl(presignedUrl, blob, options);
          });
        });
    });
  }
};

export const calculateStageStatus = (stage, status) => {
  const result = {
    upload: 'n/a',
    execution: 'n/a',
    post_process: 'n/a',
    report_generation: 'n/a',
  };
  if (stage === 'upload') {
    return {
      ...result,
      upload: status,
    };
  }
  if (stage === 'execution') {
    return {
      ...result,
      upload: 'complete',
      execution: status,
    };
  }
  if (stage === 'post process') {
    return {
      ...result,
      upload: 'complete',
      execution: 'complete',
      post_process: status,
    };
  }
  if (stage === 'download' || stage === 'report') {
    return {
      upload: 'complete',
      execution: 'complete',
      post_process: 'complete',
      report_generation: status,
    };
  }
  return result;
};

export const checkPermissions = (authMetadata, page, permission, assetId) => {
  if (has(authMetadata, page)) {
    if (
      includes(authMetadata[page].ALL, permission) ||
      includes(authMetadata[page][assetId], permission)
    ) {
      return true;
    }
  }

  return false;
};

export const getErrorMessage = (errorCode, t) => {
  if (!errorCode) {
    return t('error_unknown', 'Unknown');
  }
  switch (errorCode) {
    case 401:
      return t('error_401', 'Access Denied');
    case 403:
      return t('error_403', 'Forbidden');
    case 404:
      return t('error_404', '404 Not Found');
    case 500:
      return t('error_500', 'Internal Server Error');
    default:
      return t('error_unknown', 'Unknown');
  }
};

export const isAdrAnnotation = (d) =>
  Boolean(
    String(d).toLowerCase() === String(AnnotatorConstants.ADR).toLowerCase() ||
      String(d?.attributes?.ai_ref).toLowerCase() === String(AnnotatorConstants.ADR).toLowerCase(),
  );

export const isHumanAnnotation = (d) =>
  Boolean(
    String(d).toLowerCase() === String(AnnotatorConstants.HUMAN).toLowerCase() ||
      String(d?.attributes?.ai_ref).toLowerCase() ===
        String(AnnotatorConstants.HUMAN).toLowerCase(),
  );

export const is3rdPartyAnnotation = (d) =>
  Boolean(
    String(d).toLowerCase() === String(AnnotatorConstants.THIRD_PARTY).toLowerCase() ||
      String(d?.attributes?.ai_ref).toLowerCase() ===
        String(AnnotatorConstants.THIRD_PARTY).toLowerCase(),
  );

export * from './parent-entity-nav';
export * from './kpi';
export * from './post-processing';
export * from './inspect-date-filter';
