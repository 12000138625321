import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { typography } from '@ge/tokens';

import { ConditionalRender } from '../../conditional-render';
import { Icon, Icons } from '../../icon';

const ClearAllButton = styled.button`
  color: ${(props) => props.theme.clearColumnFilterBtn.textColor};
  display: flex;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  left: 5px;
  top: 5px;
`;

const ClearIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CLOSE,
  color: props.theme.clearColumnFilterBtn.iconColor,
}))`
  flex-shrink: 0;
  margin: 2px 7px 0;
`;

const ClearColumnFilter = ({ filters, onReset, className }) => {
  const { ready, t } = useTranslation(['general'], {
    useSuspense: false,
  });
  const shouldRender = useMemo(
    () =>
      filters &&
      Object.values(filters).some(
        (filter) => filter.value != null || filter.min != null || filter.max != null,
      ),
    [filters],
  );

  const handleClearAll = useCallback(() => onReset(filters), [filters, onReset]);

  if (!ready) return null;

  return (
    <ConditionalRender shouldRender={shouldRender}>
      <ClearAllButton onClick={handleClearAll} className={className}>
        <ClearIcon />
        {t('filter.clear_column_filters', 'CLEAR COLUMN FILTERS')}
      </ClearAllButton>
    </ConditionalRender>
  );
};

ClearColumnFilter.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onReset: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ClearColumnFilter;
