import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FlagIcon } from '@ge/components/approval-flag-icon';
import { Button } from '@ge/components/button';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Radio } from '@ge/components/radio';
import { TaskFlag, TaskStatus } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.themeSelector.iconColorActive,
}))``;

const StyledFlagIcon = styled(FlagIcon)`
  margin-right: 10px;
`;

const StyledDialog = styled(Dialog)`
  max-height: 550px;
  margin-top: 10px;
  width: 390px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  header {
    border-radius: 6px 6px 0 0;
  }
  section {
    padding: 0;
  }
  footer {
    border-radius: 0 0 4px 4px;
  }
`;

const StyledSpinLoader = styled(SpinLoader)`
  margin-top: -12px;
  left: 45%;
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  .title {
    border-bottom: none;
    margin-top: -40px;
    margin-right: -25px;
    margin-bottom: 15px;
  }
  svg {
    color: globalColors.white;
  }
`;

const EditTasksContainer = styled.div`
  padding: 12px 24px;
  .completedTasksPresent {
    opacity: 0.5;
  }
`;

const EditModalHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

const RemoveTasksSection = styled.div`
  text-align: center;
  padding: 15px 10px;
`;

const RemoveTasksMessage = styled.p`
  color: ${(props) => props.theme.dangerColor};
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin:0;
  text-transform: none;
}
`;

const TaskTitleSection = styled.div`
  opacity: 0.5;
  color: ${(props) => props.theme.taskFlag.caseTitleColor};
  font-size: 10px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 15px 0 0;
`;

const TaskAsset = styled.span`
  display: inline-block;
  width: ${(props) => (props.approvalFlag ? '19%' : '24%')};
  margin-left: 1%;
`;

const TaskTitle = styled.span`
  display: inline-block;
  width: ${(props) => (props.approvalFlag ? '35%' : '50%')};
`;

const TaskApproval = styled.span`
  display: inline-block;
  width: 20%;
  text-align: center;
  p {
    margin-left: 10%;
  }
`;

const TaskStatusText = styled.span`
  display: inline-block;
  width: 25%;
`;

const TaskDetails = styled.div`
  padding: 10px;
  background: #2f3841;
  max-height: ${({ isCompletedTasks, isBulkTask }) =>
    isCompletedTasks && isBulkTask ? '115px' : isCompletedTasks ? '65px' : '85px'};
  border: 1px solid ${(props) => (props.isCompletedTasks ? props.theme.dangerColor : 'none')};
  border-radius: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  overflow: scroll;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const TaskDetailsText = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  letter-spacing: 0;
  line-height: 20px;
  color: ${(props) => props.theme.taskFlag.caseTitleColor};
`;

const TaskDetailsDivider = styled.hr`
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.taskFlag.buttonDividerColor};
  margin: 0;
`;

const RadioButtonText = styled.span`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
`;

const StyledRadio = styled(Radio)`
  display: inline-block;
  margin-right: 12%;
  label {
    line-height: 0;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: end;
`;

const CancelButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.taskFlag.cancelBorderColor};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
`;

const ConfirmButton = styled(Button)`
  margin-left: 10px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  &:disabled {
    cursor: not-allowed;
  }
`;

const RemoveTasksMessageIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.DATA_NETCOMM,
  color: globalColors.red2,
}))``;

export const BulkEditTaskFlagDialog = ({
  tasks,
  onClose,
  onSaveAll,
  isBulkScheduleLoading,
  setBulkEditTaskFlag,
  bulkTask,
  hideDetails,
  setCompletedSR,
  isTaskSelected,
  inprogressSR,
  srPresent,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const [selectedApprovedFlag, setSelectedApprovedFlag] = useState(TaskFlag.NOTAPPROVED);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [nonCompletedTasks, setNonCompletedTasks] = useState([]);
  const isBulkTask = !isTaskSelected && bulkTask?.length >= 1;
  const taskCount = bulkTask?.length - completedTasks?.length;
  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const [inprogressSRTasks, setInprogressSRTasks] = useState([]);

  useEffect(() => {
    setInprogressSRTasks(inprogressSR);
  }, [inprogressSR]);

  useEffect(() => {
    if (tasks?.length) {
      setCompletedTasks(tasks?.filter((task) => task?.status === TaskStatus.COMPLETED));
      setNonCompletedTasks(tasks?.filter((task) => task?.status !== TaskStatus.COMPLETED));
    } else {
      setCompletedTasks(
        bulkTask?.filter((task) => inprogressSRTasks?.some((sr) => sr?.taskId === task?.id)),
      );
      setNonCompletedTasks(
        bulkTask?.filter((task) => task?.srNumber === '-' && task?.srNumber !== ' '),
      );
    }
  }, [tasks, setCompletedSR, bulkTask, inprogressSRTasks]);

  const { handleSubmit } = useForm({
    mode: 'onChange',
  });

  const handleCompletedTask = () => {
    setCompletedTasks([]);
    setNonCompletedTasks([]);
    setInprogressSRTasks([]);
    setCompletedSR(completedTasks);
    if (taskCount === 0) {
      hideDetails();
    }
  };

  const renderTasksSection = (filteredTasks, taskIdentifier, isBulkTask) => (
    <>
      <TaskTitleSection>
        <TaskAsset approvalFlag={approvalFlag}>{t('dynamic.task_flag.asset', 'Asset')}</TaskAsset>
        <TaskTitle approvalFlag={approvalFlag}>
          {t('dynamic.task_flag.task_title', 'Task Title')}
        </TaskTitle>
        {approvalFlag && <TaskApproval>{t('dynamic.task_flag.approval', 'Approval')}</TaskApproval>}
        <TaskStatusText>{t('dynamic.task_flag.status', 'Status')}</TaskStatusText>
      </TaskTitleSection>
      <TaskDetails isCompletedTasks={taskIdentifier} isBulkTask={isBulkTask}>
        {filteredTasks?.map((task, index) => (
          <div key={index}>
            <TaskAsset approvalFlag={approvalFlag}>
              <TaskDetailsText>{task?.asset?.name}</TaskDetailsText>
            </TaskAsset>
            <TaskTitle approvalFlag={approvalFlag}>
              <TaskDetailsText>{task?.title}</TaskDetailsText>
            </TaskTitle>
            {approvalFlag && (
              <TaskApproval>
                <TaskDetailsText>
                  <FlagIcon value={task?.flag} size={12} />
                </TaskDetailsText>
              </TaskApproval>
            )}
            <TaskStatusText>
              <TaskDetailsText>
                {t(`dynamic.task_flag.${task?.status}`, task?.status)}
              </TaskDetailsText>
            </TaskStatusText>
            {tasks?.length - 1 !== index && <TaskDetailsDivider />}
          </div>
        ))}
      </TaskDetails>
    </>
  );

  const handleSaveAll = useCallback(
    async (data) => {
      tasks.forEach((task) => {
        data[task?.id] = {
          taskId: task.taskId,
          flag: selectedApprovedFlag,
          scheduleDate: task?.scheduleDate,
          startTime: task?.startTime,
          assignee: task?.crewIds?.[0],
        };
      });
      onSaveAll(data);
    },
    [onSaveAll, selectedApprovedFlag, tasks],
  );

  const getRadioLabel = useCallback(
    (taskFlagValue) => {
      return (
        <>
          <StyledFlagIcon value={taskFlagValue} size={12} />
          <span>{t(`dynamic.task_flag.${taskFlagValue}`, taskFlagValue)}</span>
        </>
      );
    },
    [t],
  );

  const getFooter = useMemo(() => {
    return (
      <>
        <StyledSpinLoader showLoader={isBulkScheduleLoading} />
        <ButtonSection onClick={(e) => e.stopPropagation()}>
          <CancelButton onClick={() => onClose()}>
            {t('dynamic.task_flag.cancel', 'Cancel')}
          </CancelButton>
          <ConfirmButton
            disabled={isBulkScheduleLoading || completedTasks?.length > 0}
            primary
            onClick={handleSubmit(handleSaveAll)}
          >
            {t('dynamic.task_flag.confirm', 'Confirm')}
          </ConfirmButton>
        </ButtonSection>
      </>
    );
  }, [handleSaveAll, handleSubmit, isBulkScheduleLoading, onClose, t, completedTasks]);

  if (isBulkTask) {
    return (
      <EditTasksContainer>
        {completedTasks?.length > 0 && (
          <>
            <RemoveTasksSection>
              <RemoveTasksMessageIcon />
              {completedTasks?.length > 0 && srPresent?.length > 0 && (
                <RemoveTasksMessage>
                  {completedTasks?.length} {t('Tasks have active service requests')}
                </RemoveTasksMessage>
              )}
              {inprogressSRTasks?.length > 0 && srPresent?.length === 0 && (
                <RemoveTasksMessage>
                  {inprogressSRTasks?.length} {t('Tasks have service request in progress')}
                </RemoveTasksMessage>
              )}
              <Button primary onClick={handleCompletedTask}>
                {t('Remove Tasks with SRs')}
              </Button>
            </RemoveTasksSection>
            <TaskDetailsDivider />
            {renderTasksSection(completedTasks, true, isBulkTask)}
            <TaskDetailsDivider />
          </>
        )}
      </EditTasksContainer>
    );
  } else {
    return (
      <StyledDialog
        isOpen={true}
        header={t('dynamic.task_flag.approve_not_approve', 'APPROVE/NOT APPROVE')}
        onClose={onClose}
        footer={getFooter}
      >
        <EditTasksContainer>
          <EditModalHeader>
            <StyledIcon icon={Icons.TASK} /> {tasks?.length} {t('cases_tasks', 'Case Tasks')}
          </EditModalHeader>
          {completedTasks?.length > 0 && (
            <>
              <RemoveTasksSection>
                <RemoveTasksMessageIcon />
                <RemoveTasksMessage>
                  {completedTasks?.length}{' '}
                  {t('dynamic.task_flag.remove_tasks_message', 'Closed Tasks cannot be changed')}
                </RemoveTasksMessage>
                <Button primary onClick={() => setBulkEditTaskFlag(nonCompletedTasks)}>
                  {t('dynamic.task_flag.remove_closed_tasks', 'Remove Closed Tasks')}
                </Button>
              </RemoveTasksSection>
              <StyledCollapsiblePanel secondary={true} expanded={true}>
                <TaskDetailsDivider />
                {renderTasksSection(completedTasks, true)}
                <TaskDetailsDivider />
              </StyledCollapsiblePanel>
            </>
          )}
          {nonCompletedTasks.length > 0 && renderTasksSection(nonCompletedTasks, false)}
          <RadioButtonText
            className={`${completedTasks?.length > 0 ? 'completedTasksPresent' : ''}`}
          >
            {t('dynamic.task_flag.change_approval', 'CHANGE APPROVAL TO:')}
          </RadioButtonText>
          <div>
            <StyledRadio
              id={TaskFlag.NOTAPPROVED}
              label={getRadioLabel(TaskFlag.NOTAPPROVED)}
              value={TaskFlag.NOTAPPROVED}
              name={TaskFlag.NOTAPPROVED}
              checked={selectedApprovedFlag === TaskFlag.NOTAPPROVED}
              onChange={(e) => setSelectedApprovedFlag(e.target.value)}
              disabled={completedTasks?.length > 0}
              className={`${completedTasks?.length > 0 ? 'completedTasksPresent' : ''}`}
            />
            <StyledRadio
              id={TaskFlag.APPROVED}
              label={getRadioLabel(TaskFlag.APPROVED)}
              value={TaskFlag.APPROVED}
              name={TaskFlag.APPROVED}
              checked={selectedApprovedFlag === TaskFlag.APPROVED}
              onChange={(e) => setSelectedApprovedFlag(e.target.value)}
              disabled={completedTasks?.length > 0}
              className={`${completedTasks?.length > 0 ? 'completedTasksPresent' : ''}`}
            />
          </div>
        </EditTasksContainer>
      </StyledDialog>
    );
  }
};

BulkEditTaskFlagDialog.propTypes = {
  tasks: PropTypes.array,
  onClose: PropTypes.func,
  onSaveAll: PropTypes.func,
  isBulkScheduleLoading: PropTypes.bool,
  setBulkEditTaskFlag: PropTypes.func,
  setCompletedSR: PropTypes.func,
  hideDetails: PropTypes.func,
  bulkTask: PropTypes.array,
  isTaskSelected: PropTypes.bool,
  inprogressSR: PropTypes.array,
  srPresent: PropTypes.array,
};

BulkEditTaskFlagDialog.defaultValues = {
  onClose: () => null,
  onSaveAll: () => null,
  isBulkScheduleLoading: false,
};
