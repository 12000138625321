import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Timing } from '../../../task-fields/timing';
import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditTiming = ({ metadata, task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.timing', 'Timing')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup className="timing">
          {!metadata?.timePeriods.hidden && (
            <Timing metadata={metadata.timePeriods.edit} task={task} />
          )}
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

EditTiming.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};

EditTiming.defaultProps = {
  handleTaskType: () => null,
};
