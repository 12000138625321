import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import isNil from 'ramda/src/isNil';

import { DateTimeFormats } from '@ge/models/constants';
import { roundNumber } from '@ge/util';

dayjs.extend(advanced);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(toObject);
dayjs.extend(utc);

export const getTime = (start) => {
  const time = new Date(start);
  const hours = time.getHours();
  const minutes = numFormat(time.getMinutes());
  return `${hours}:${minutes}`;
};

export const getDate = (date) => dayjs(date).format('MM/DD/YY');

export const getDateTz = (date, timezone) => {
  if (!date) {
    return null;
  }

  // revisit this but for now if we don't have a site timezone, fall back to input date
  if (!timezone) {
    return dayjs(date);
  }

  const dateTz = dayjs(date).tz(timezone);
  const offsetTz = dateTz.utcOffset();

  // dealing with utc so don't need to do anthing extra
  if (offsetTz === 0) {
    return dateTz;
  }

  // dates cast to timezone are still expressed internally in local time, so we enforce offset
  // to avoid crossing date boundaries relative to local date 00:00:00 (because javascript)
  const offsetLocal = dayjs(date).utcOffset();
  const diff = offsetLocal - offsetTz;

  return dateTz.add(diff, 'm');
};

// Will modify/remove after exploring other alternatives ( moment-timezone ?)
export const getDateTimeBasedOnZone = (date, timeZone) => {
  const dateTime = { time: null, date: null, tz: null, timeInSecs: null };
  if (date) {
    const tzone = timeZone ? timeZone : dayjs.tz.guess();
    const dtz = dayjs(date).tz(tzone);
    dateTime.time = dayjs(dtz).format('HH:mm');
    dateTime.timeInSecs = dayjs(dtz).format('HH:mm:ss');
    dateTime.date = dayjs(dtz).format('DD MMM YY');
    dateTime.tz = dayjs.tz(date, tzone).format('z');
  }
  return dateTime;
};

export const getFriendlyTimestamp = (date, timeZone) => {
  const friendly = dayjs.tz(date, timeZone ?? dayjs.tz.guess());

  return dayjs(friendly).format('H:mm (z), D MMM YY');
};

/**
 * Return the GMT offset in minutes.
 *
 * @param {string} timeZone Timezone (e.g. - "America/New_York")
 * @returns number
 */
export const getTimezoneOffset = (timeZone) => {
  if (!timeZone) return 0;
  return dayjs()
    .tz(timeZone)
    .utcOffset();
};

/**
 * Apply the offset associated with the provided time zone string to the provided date object.
 *
 * @param {Date} date Date object
 * @param {string} timeZone Timezone (e.g. - "America/New_York")
 * @returns dayjs object
 */
export const addUTCOffset = (date, timeZone) => {
  return dayjs(date).utcOffset(getTimezoneOffset(timeZone));
};

// Used for task specific site timezone ISO
export const toSiteISOString = (date, timezone) => {
  const dayjsDate = dayjs(date);

  return dayjs
    .tz(
      `${dayjsDate.year()}-${dayjsDate.month() +
        1}-${dayjsDate.date()} ${dayjsDate.hour()}:${dayjsDate.minute()}`,
      timezone,
    )
    .toISOString();
};

// Prevents losing local/timezone offset going into react-datepicker
export const getDateObject = (date) => {
  const dateObj = date.toObject();

  return new Date(
    dateObj.years,
    dateObj.months,
    dateObj.date,
    dateObj.hours,
    dateObj.minutes,
    dateObj.seconds,
  );
};

export const getDuration = (start, end, format = '%ddd %hhh %mmm') => {
  const startDateTime = dayjs(start);
  const endDateTime = end ? dayjs(end) : dayjs();
  const ms = endDateTime.diff(startDateTime);
  const days = endDateTime.diff(startDateTime, 'day');
  const totHours = endDateTime.diff(startDateTime, 'hour');
  const hours = totHours - days * 24;
  const minutes = endDateTime.diff(startDateTime, 'minute') - totHours * 60;

  let formatted = format
    .replace('%DD', numFormat(days))
    .replace('%HH', numFormat(hours))
    .replace('%MM', numFormat(minutes));

  formatted = formatted.replace('%dd', days > 0 ? days.toString(10) : '');
  formatted = formatted.replace('%hh', days > 0 || hours > 0 ? hours.toString(10) : '');

  if (days === 0 && hours === 0) {
    formatted = formatted.replace('%mm', minutes > 0 ? minutes.toString(10) : '<1');
  } else if (days !== 0) {
    formatted = formatted.replace('%mmm', '');
  } else {
    formatted = formatted.replace('%mm', minutes.toString(10));
  }

  return {
    formatted: formatted.replace(/\b([a-zA-Z])\b/g, '').trim(),
    days,
    hours,
    minutes,
    ms,
    totHours,
  };
};

export const getDurationHourMin = (num) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  return `${hours}:${numFormat(minutes)}`;
};

export const getDurationByMinutes = (minutes) => {
  const minutesDuration = dayjs.duration(minutes * 1000 * 60);
  // currently using for estimates, could that possibly get into days??
  // const displayDays = minutesDuration.$d.days ? `${minutesDuration.$d.days}d` : ``;
  const displayHours = minutesDuration.$d.hours ? `${minutesDuration.$d.hours}h` : ``;
  const displayMinutes = minutesDuration.$d.minutes ? `${minutesDuration.$d.minutes}m` : ``;
  return `${displayHours} ${displayMinutes}`.trim();
};

export const getRelativeTimeFromNow = (date) => {
  return date.fromNow();
};

export const numFormat = (num) => {
  if (num === 0) return '00';
  if (num < 10) return `0${num}`;
  return num;
};

export const formatHoursAndMinutes = (hours, minutes) => {
  const formattedHours = hours ? `${hours}h` : '';
  const formattedMinutes = !isNil(minutes) ? `${minutes}m` : '';
  if (hours && !minutes) {
    return formattedHours;
  }
  return `${formattedHours} ${formattedMinutes}`.trim();
};

export const isBeforeToday = (date) => dayjs().isAfter(dayjs(date), 'date');

/**
 * Returns the difference between passed UTC with current UTC
 *
 * @param {Object} connection - Connection status
 * @param {number} connection.timestamp - timestamp at which the connection status is changed
 * @returns number
 */
export const getConnectionStatusDuration = (connection) => {
  if (!connection || !connection.timestamp) return '-';
  let curUTC = dayjs.utc().unix();
  let duration = getDuration(connection.timestamp * 1000, curUTC * 1000).formatted;
  return duration;
};

/**
 * Returns the splited duration based on maximum days
 *
 * @param {*} startDate - Duration start date
 * @param {*} endDate- Duration end date
 * @returns array of start and end dates
 */
export const splitDates = (startDate, endDate, diff = 90) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffDays = Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  if (diffDays > diff) {
    const year = date1.getFullYear();
    const month = date1.getMonth();
    const day = date1.getDate();
    return [...new Array(Math.ceil(diffDays / diff))].map((_, i) => {
      const start = new Date(year, month, day + i * diff, 0, 0, 0);
      const end = new Date(year, month, day + (i + 1) * diff - 1, 23, 59, 59);
      return {
        start,
        end: end < date2 ? end : date2,
      };
    });
  }
  return [{ start: date1, end: date2 }];
};

// Converts date to three-letter abbreviation month
export const getMonth = (date) => {
  //Passing true will change the time zone without changing the current time.
  return dayjs(date)
    .utc(true)
    .format('MMM');
};

// Rounds a number to the nearest whole number
export const getRoundNumber = (num, digits = 0) => {
  return roundNumber(num, digits);
};

// Converts seconds to days
export const secondsToDays = (seconds) => {
  return seconds / 86400;
};

export const getDayEndTime = (endTime, timezone, calendarDate) => {
  let convertedEndTime = endTime;
  if (
    dayjs.tz(endTime, timezone).format(DateTimeFormats.ENDPOINT_PARAM) >
    dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)
  ) {
    convertedEndTime = endTime - 1000;
  }
  return convertedEndTime;
};
