import { PropTypes } from 'prop-types';
import union from 'ramda/src/union';
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { placements } from '@ge/components/menu';
import { TooltipCell } from '@ge/components/table/table';
import { useAllCrews } from '@ge/feat-manage/data-hooks/use-all-crews';
import { useWorkers } from '@ge/feat-manage/data-hooks/use-workers';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const TaskDetailWrapper = styled.div`
  border-top: 1px solid ${globalColors.slate1};
  border-bottom: 1px solid ${globalColors.slate1};
  width: 100%;
  margin-right: 32px;
  padding-bottom: 30px;
  span {
    text-transform: none;
  }
  .details {
    background-color: #1C252D;
    border: 1px solid #2A323B;
    margin-right: 24px;
    padding-right: 5px;
    border-radius: 3px;
    width: 160px;
    color: ${(props) => props.theme.manage.tabs.techs.technician.textColor};
    .tech {
      display: flex;
      padding: 5px 5px 10px;
      width: 155px;
    }
    .nametitle {
      display: inline-block;
      margin-left: 10px;
      width: 100px;
      }
    .name {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        padding-left: 20px;
        padding-right: 2px;
      }
      .title {
        font-size: 10px;
        color: ${globalColors.slate5};
        padding-left: 20px;
      }
    .nickname-pill {
      height: 15px;
      width: 30px;
      border-radius: 12px;
      background-color: #b6e1e9;
    }
    .nickname {
        height: 10px;
        color: #1c252d;
        font-size: 8px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        line-height: 16px;
        text-align: center;
      }
    &.striped {
      background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
      color: ${({ theme }) => theme.manage.cards.unscheduledTextColor};
      background: repeating-linear-gradient(
        145deg,
        #8694a1,
        #8694a1 0.3px,
        #5e6d7b 1px,
        #5e6d7b 6px
      );
    }
    &.solid {      
      border: 1px solid ${globalColors.red2};
      } 
`;

const StyledIcon = styled(Icon).attrs(({ size, theme }) => ({
  size: size || 9,
  color: theme.manage.taskItem.iconColor,
}))`
  margin: 0 3px;
  vertical-align: initial;
  &:hover {
    fill: ${globalColors.red1};
  }
`;

const StyledAssignedTechs = styled.div`
  line-height: 15px;
  padding: 25px 0px 10px 0px;
`;
const StyledAssignedTechnicians = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.dataTitle};
  line-height: 15px;
  padding: 5px 0px 5px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TaskIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tasks.details.columnButtonColor,
}))`
  position: absolute;
  left: 0;
`;

const StyledEditIcon = styled.span`
  position: relative;
  bottom: 10px;
  left: 10px;
  .pencil-icon {
    border: none;
    background: none;
  }
`;

export const AssignedTechsSection = ({
  section,
  task,
  erpData,
  isSrCreated,
  isSrCompleted,
  isEditing,
  setIsEditing,
  onAssignedTechniciansChanged,
  setDivHeight,
  bulkTask,
  isTaskSelected,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const { data: allCrewsData, isFetching: isFetchingCrews } = useAllCrews();
  const { data: workersData, isFetching: isFetchingWorkers } = useWorkers();

  // Returns a subset of `workersData` that is restricted to workers belonging to the following sets, when applicable:
  //   1. Members of the crew assigned to the task: when actively editing technician assignments
  //   2. Workers currently assigned to the SR: when viewing technician assignments
  //   3. Workers that completed the task: when viewing technician assignments
  const { crewForBundledTask } = useContext(EntityDetailsContext);
  const filteredWorkers = useMemo(() => {
    const crewId =
      crewForBundledTask?.isSecondaryBundle && crewForBundledTask?.crewId
        ? crewForBundledTask?.crewId[0]
        : task?.crewIds && task?.crewIds[0];
    // 1. Members of the crew assigned to the task
    const taskCrewMemberIds = (allCrewsData ?? [])
      .filter((crew) => crew._id === crewId)
      .flatMap((filteredCrew) => filteredCrew.members?.map((member) => member.member_id));

    // 2. Workers currently assigned to the SR
    const srAssignedMemberIds = (erpData?.technicians ?? []).map((technician) => {
      const checkWorker = workersData?.filter((w) => w.username == technician.emailAddress);
      if (checkWorker.length != 0) return technician.emailAddress;
      else return technician.id;
    });

    // 3. Workers that completed the task
    const taskCompletedMemberIds = (isSrCompleted ? task?.completedByTech : []) ?? [];

    const memberIdsUnion = isEditing
      ? taskCrewMemberIds
      : union(taskCompletedMemberIds, srAssignedMemberIds);

    return workersData?.filter((w) => memberIdsUnion.includes(w.username)); // TODO: will this work with the data returned in `task.completedByTech`?
  }, [
    task?.crewIds,
    task?.completedByTech,
    crewForBundledTask?.isSecondaryBundle,
    crewForBundledTask.crewId,
    allCrewsData,
    erpData?.technicians,
    isSrCompleted,
    isEditing,
    workersData,
  ]);

  const [initialDataAdded, setInitialDataAdded] = useState(false);
  const [enabledWorkers, setEnabledWorkers] = useState(new Map());

  const showEditButton = isSrCreated && !isEditing;

  const rlvRef = useRef();
  const [refVisible, setRefVisible] = useState(false);
  useEffect(() => {
    if (!refVisible) return;
    setDivHeight(rlvRef.current.clientHeight);
    // detected rendering
  }, [refVisible, setDivHeight]);

  useEffect(() => {
    if (erpData || isEditing) {
      setInitialDataAdded(false);
    }
  }, [erpData, isEditing, setInitialDataAdded]);

  useEffect(() => {
    if (initialDataAdded || !(filteredWorkers?.length > 0)) return;

    const workerMap = new Map();
    if (erpData) {
      erpData.technicians?.forEach((worker) => {
        const filteredWorker = filteredWorkers.find(
          (fw) => fw.username === worker.id || fw.username === worker.emailAddress,
        );
        // TODO: what if the worker isn't found? Should they be looked up in a different collection? Error logged? Etc.
        if (filteredWorker) {
          workerMap.set(filteredWorker.username, filteredWorker);
        }
      });
    }

    if (isEditing && workerMap.size === 0) {
      filteredWorkers.forEach((worker) => workerMap.set(worker.username, worker));
    }

    setEnabledWorkers(workerMap);
    setInitialDataAdded(true);
  }, [
    erpData,
    isEditing,
    filteredWorkers,
    initialDataAdded,
    setEnabledWorkers,
    setInitialDataAdded,
    enabledWorkers,
  ]);

  useEffect(() => {
    const assignedTechnicians = [];
    filteredWorkers?.forEach((worker) => {
      if (enabledWorkers.has(worker.username)) {
        if (worker?.functionalSSO)
          assignedTechnicians.push({ id: `${worker.functionalSSO}`, emailAddress: worker.email });
        else assignedTechnicians.push({ id: `${worker.username}`, emailAddress: worker.email });
      }
    });
    onAssignedTechniciansChanged(assignedTechnicians);
  }, [filteredWorkers, enabledWorkers, onAssignedTechniciansChanged]);

  if (!section || section.hidden) {
    return null;
  }

  if (isFetchingCrews || isFetchingWorkers) {
    return null;
  }

  const isWorkerEnabled = (worker) => {
    return enabledWorkers.has(worker.username);
  };

  const isWorkerInvalid = (worker) => {
    return (erpData?.technicians ?? []).some(
      (data) => data.emailAddress === worker.email && data.error !== undefined && data.error,
    );
  };

  const handleRemoveWorker = (worker) => {
    const updatedWorkers = new Map(enabledWorkers);
    updatedWorkers.delete(worker.username);
    setEnabledWorkers(updatedWorkers);
  };

  const handleUnremoveWorker = (worker) => {
    const updatedWorkers = new Map(enabledWorkers);
    updatedWorkers.set(worker.username, worker);
    setEnabledWorkers(updatedWorkers);
  };

  const formatWorkerTitle = (title) => {
    if (title?.includes('_')) {
      return title.split('_').join(' ');
    } else {
      return title;
    }
  };

  if (!isTaskSelected && bulkTask?.length >= 1) {
    return (
      <>
        <TaskDetailWrapper>
          <StyledAssignedTechs>{section.title}</StyledAssignedTechs>
          <span>
            {t(
              'form.bulk_techs_message',
              'Techs can be updated on an individual task level when mass SR is created.',
            )}
          </span>
        </TaskDetailWrapper>
      </>
    );
  }

  const AssignedTechnician = ({ worker, enabled, invalidWorker }) => {
    if (!worker) return null;
    const workerInitials = worker.initials;
    const firstName = worker.firstName.substring(0, 5);
    const lastName = worker.lastName?.substring(0, 5);
    const title = worker.title;

    return (
      <div className={`details ${!enabled ? 'striped' : ''} ${invalidWorker ? 'solid' : ''}`}>
        <div className="tech">
          <div className="nickname-pill">
            <div className="nickname">{workerInitials || firstName}</div>
          </div>
          <TooltipCell
            placement={placements.TOP}
            tooltip={enabled ? `${firstName} ${lastName}` : null}
            zIndex={elevations.P101}
            customClass="tooltip-close-style"
          >
            <div className="nametitle">
              <div className="name">{`${firstName} ${lastName}`}</div>
              <div className="title">{formatWorkerTitle(title) || 'Worker'}</div>
            </div>
          </TooltipCell>

          {isEditing && (
            <button
              onClick={() => {
                if (!enabled) {
                  handleUnremoveWorker(worker);
                } else {
                  handleRemoveWorker(worker);
                }
              }}
              type="Button"
            >
              <TooltipCell
                placement={placements.TOP}
                tooltip={!enabled ? t('form.click_to_unremove', 'Click to Unremove') : null}
                zIndex={elevations.P101}
                customClass="tooltip-close-style"
              >
                <StyledIcon icon={Icons.CLOSE} size={10} />
              </TooltipCell>
            </button>
          )}
        </div>
      </div>
    );
  };

  AssignedTechnician.propTypes = {
    worker: PropTypes.instanceOf(Object).isRequired,
    enabled: PropTypes.bool,
    invalidWorker: PropTypes.bool,
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      <TaskDetailWrapper
        ref={(el) => {
          rlvRef.current = el;
          setRefVisible(!!el);
        }}
      >
        <StyledAssignedTechs>
          {section.title}
          <StyledEditIcon>
            {showEditButton && (
              <Button className="pencil-icon" onClick={() => handleEditButtonClick()} type="button">
                <TaskIcon size={13} icon={Icons.PENCIL} />
              </Button>
            )}
          </StyledEditIcon>
        </StyledAssignedTechs>
        {filteredWorkers?.length > 0 ? (
          <>
            <StyledAssignedTechnicians>
              <label>{t('form.assigned_technicians', 'Assigned Technicians')}</label>
            </StyledAssignedTechnicians>
            <Wrapper>
              {filteredWorkers.map((worker, index) => {
                const enabled = isWorkerEnabled(worker);
                const invalidWorker = isWorkerInvalid(worker);
                if (!enabled && !isEditing) {
                  // Only show removed technicians when in edit mode
                  return null;
                }
                return (
                  <AssignedTechnician
                    key={index}
                    worker={worker}
                    enabled={enabled}
                    invalidWorker={invalidWorker}
                  />
                );
              })}
            </Wrapper>
          </>
        ) : (
          <span>{t('form.no_techs_message', 'No Tech Assigned for this Task')}</span>
        )}
      </TaskDetailWrapper>
    </>
  );
};

AssignedTechsSection.propTypes = {
  section: PropTypes.object,
  task: PropTypes.instanceOf(Object).isRequired,
  erpData: PropTypes.object,
  isSrCreated: PropTypes.bool,
  isSrCompleted: PropTypes.bool,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  onAssignedTechniciansChanged: PropTypes.func,
  setDivHeight: PropTypes.func,
  bulkTask: PropTypes.array,
  isTaskSelected: PropTypes.bool,
};
