import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, withTheme } from 'styled-components';

import { Table, Td, Th, Thead, TableGroup, TdToggle } from '@ge/components/table';
import { formatKPIValue } from '@ge/feat-analyze/util';
import { CommonLocators } from '@ge/models/data-locators';
import { getL2ChartColors } from '@ge/tokens/charts';
import { globalColors } from '@ge/tokens/colors';
import { roundNumber, hexRgb } from '@ge/util';

import { DataLoader } from '../../data-loader';

import { Donut } from './donut';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.header`
  margin: 0 10px 10px;
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  button:focus {
    outline: none;
  }
  tr {
    &:hover,
    &.selected {
      background: ${(props) => props.theme.analyze.majorLoss.table.hover.background};
      cursor: pointer;
      td {
        border-bottom-color: ${(props) => props.theme.analyze.majorLoss.table.hover.border};
        color: ${(props) => props.theme.analyze.majorLoss.table.hover.text};
        font-weight: 400;
        svg {
          fill: ${(props) => props.theme.analyze.majorLoss.table.hover.text};
        }
      }
    }
  }
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  .left {
    margin-left: 10px;
    .donut {
      width: 200px;
      height: 200px;
      position: relative;
      padding: 20px 20px;
      ${({ hasFilter, theme }) =>
        hasFilter
          ? `
        border-top: 2px solid ${theme.analyze.majorLoss.table.border};
      `
          : ''}
    }
  }
  .filter {
    min-height: 34px;
    margin-top: -6px;
    align-items: center;
  }
`;

const DonutBlock = styled.div`
  ${({ selected, theme }) => {
    return selected
      ? css`
          border: 1px solid ${theme.analyze.majorLoss.donutChart.selected};
          border-radius: 50%;
          padding: 3px;
        `
      : css``;
  }};
`;

const ColorBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  ${({ selected }) => {
    return selected
      ? css`
          border-radius: 4px;
          border: 1px solid ${({ theme }) => theme.analyze.majorLoss.table.selected.border};
          padding: 2px;
        `
      : css``;
  }};
  &:before {
    display: block;
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 2px;
    background-color: ${({ color }) => color};
  }
`;

const NoDataError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.analyze.majorLoss.donutChart.errorMessageColor}
  font-weight: bold;
`;

const isExpanded = (Arr, id) => Arr.indexOf(id) > -1;

const mapChartData = (d) => ({
  y: d.value,
  name: d.title,
  kpiValue: d.kpiValue,
  id:
    d.id ||
    d.title
      ?.trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-'),
});

const getDynamicColor = ({ length, index }) => {
  // Dynamically generate an interval to reduce rgba alpha value based
  // on length of data.
  const interval = 1 / (length + 0.5 * length);
  const alpha = 1 - (length > 10 ? (index + 1) * interval : 0);
  const indexMod = index % 9;

  // Destructure and build css rgba.
  const { red, green, blue } = hexRgb(getL2ChartColors(indexMod)[indexMod]);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

const getDynamicColors = ({ array }) =>
  array?.map((_, index) => getDynamicColor({ length: array?.length, index }));

export const DonutChart = withTheme(
  ({
    theme,
    title,
    donutTitle,
    donutValue,
    donutUnit,
    legendColumns,
    filter,
    data,
    onSelect,
    dataLoaderProps,
    noDataError,
    showZero,
    selected,
    tooltipPointFormatter,
  }) => {
    const { i18n } = useTranslation();
    const showTitle = showZero ? donutValue != null : donutValue;
    const donutLabel = showTitle
      ? `${donutTitle}
          <br />
          ${formatKPIValue(donutValue, i18n.language)}
          <br />
          ${donutUnit}`
      : ' ';

    const defaultState = useMemo(
      () =>
        showZero
          ? {
              selectedId: null,
              selectedIndex: null,
              powerCurveId: null,
              selectedCaseId: null,
              donutData: [{ y: 100, color: globalColors.slate3 }],
              donutColors: globalColors.slate3,
            }
          : {
              selectedId: null,
              selectedIndex: null,
              powerCurveId: null,
              selectedCaseId: null,
              donutData: data?.map(mapChartData),
              donutColors: getDynamicColors({ array: data }),
            },
      [data, showZero],
    );
    const [state, setState] = useState(defaultState);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedValue, setExpandedValue] = useState([]);

    const setActiveRow = useCallback((id) => (state.selectedId === id ? 'selected' : ''), [state]);

    const handleOnSelect = useCallback(
      (id) => {
        if (onSelect && state.selectedId === id) onSelect(id);
      },
      [onSelect, state.selectedId],
    );

    const getNewCategoryChartData = useCallback(
      (id) => {
        const subComponents = data.find((x) => x.id === id).data;
        return [...subComponents.map(mapChartData)];
      },
      [data],
    );

    // Set active category.
    const selectCategory = useCallback(
      (id, index) => {
        setState((prevState) => ({
          ...prevState,
          selectedId: prevState.selectedId === id ? null : id,
          selectedIndex: prevState.selectedId === id ? null : index,
        }));
        handleOnSelect(id);
      },
      [handleOnSelect],
    );

    // Set sub category.
    const selectSubCategory = useCallback(
      (id) => {
        const newDonutData = getNewCategoryChartData(id);
        setState((prevState) => ({
          ...prevState,
          selectedId: null,
          selectedIndex: null,
          donutColors: getDynamicColors({ array: newDonutData }),
          donutData: newDonutData,
        }));
        handleOnSelect(id);
      },
      [handleOnSelect, getNewCategoryChartData],
    );

    const resetCategory = useCallback(() => {
      setState((prevState) => ({
        ...prevState,
        selectedId: null,
        selectedIndex: null,
        donutColors: getDynamicColors({ array: data }),
        donutData: data.map(mapChartData),
      }));
      handleOnSelect(null);
    }, [handleOnSelect, data]);

    const buildTableHeaders = useCallback((columns) => {
      const toggleHeader = [<Th noPadding key="toggle" width="1px" />];

      return [
        ...toggleHeader,
        columns?.map((column, index) => (
          <Th noPadding key={column} align={index === 0 ? 'left' : 'right'}>
            {column}
          </Th>
        )),
      ];
    }, []);

    useEffect(() => {
      setState(defaultState);
      setExpandedRows([]);
    }, [defaultState]);

    useEffect(() => {
      if (expandedRows.length > 0) {
        const dms = data.filter((val) => val.id === expandedRows[0]);
        setExpandedValue(dms[0]?.kpiValue);
      } else {
        setExpandedValue([]);
      }
    }, [setExpandedValue, data, expandedRows]);

    const expandedLabel = showTitle
      ? `${expandedRows[0]}
        <br />
        ${formatKPIValue(expandedValue, i18n.language)}
        <br />
        ${donutUnit}`
      : ' ';

    if (!(state.donutData || (data && data.length))) {
      return null;
    }

    return (
      <ContentWrapper data-testid={CommonLocators.COMMON_DONUT_CHART_WRAPPER}>
        {title && (
          <Header>
            <h3 data-testid={CommonLocators.COMMON_DONUT_CHART_HEADER}>{title}</h3>
          </Header>
        )}
        {noDataError ? (
          <NoDataError className="body-4">{noDataError}</NoDataError>
        ) : (
          <ChartContainer hasFilter={Boolean(filter)}>
            <div className="left">
              {filter && <div className="filter">{filter}</div>}
              <div className="donut" data-testid={CommonLocators.COMMON_DONUT_CHART_RING}>
                <DataLoader renderCondition {...dataLoaderProps}>
                  <DonutBlock selected={selected}>
                    <Donut
                      data={state.donutData}
                      title={expandedRows.length > 0 ? expandedLabel : donutLabel}
                      colors={state.donutColors}
                      selectedIndex={state.selectedIndex}
                      selectSegment={selectCategory}
                      width={selected ? 192 : 200}
                      height={selected ? 192 : 200}
                      tooltipPointFormatter={tooltipPointFormatter}
                    />
                  </DonutBlock>
                </DataLoader>
              </div>
            </div>

            <TableContainer data-testid={CommonLocators.COMMON_DONUT_CHART_TABLE}>
              <Table scrollable compressed backgroundColor={theme.layout.mainBackgroundColor}>
                {legendColumns && (
                  <Thead transparent>
                    <tr>{buildTableHeaders(legendColumns)}</tr>
                  </Thead>
                )}

                {data?.map((item, index, array) => {
                  const expanded = isExpanded(expandedRows, item.id);

                  return (
                    // Probably should find a better solution to assigning these unique keys.
                    // For now appending the index to guarantee they are unique since item.id is not.
                    <TableGroup
                      key={`group-${item.id}-${index}`}
                      id={item.id}
                      expandedArr={expandedRows}
                    >
                      <tr
                        onClick={
                          expandedRows.length === 0 ? () => selectCategory(item.id, index) : null
                        }
                        className={setActiveRow(item.id)}
                        key={item.id}
                      >
                        {item?.data ? (
                          <TdToggle
                            id={item.id}
                            expandedArr={expandedRows}
                            setExpandedArr={setExpandedRows}
                            limit
                            onClick={
                              !expanded
                                ? (e) => selectSubCategory(item.id, index, item?.data, e)
                                : () => resetCategory()
                            }
                          />
                        ) : (
                          <Td />
                        )}
                        <Td align="left" noPadding maxWidth="275px">
                          <ColorBlock
                            color={
                              expanded
                                ? globalColors.grey2
                                : getDynamicColor({
                                    length: array?.length,
                                    index,
                                  })
                            }
                            selected={selected}
                          />
                          {item.title}
                        </Td>
                        <Td align="right">{`${formatKPIValue(item.kpiValue, i18n.language)}`}</Td>
                        <Td align="right">{`${formatKPIValue(item.value, i18n.language)}%`}</Td>
                      </tr>
                      {item.data &&
                        item.data.map((subItem, subIndex, subArray) => (
                          // Probably should find a better solution to assigning these unique keys since id is not unique.
                          <tr
                            key={subItem.id}
                            className={setActiveRow(subItem.id)}
                            onClick={() => selectCategory(subItem.id, subIndex)}
                          >
                            <Td />
                            <Td align="left" title={subItem.title} noPadding>
                              <ColorBlock
                                color={getDynamicColor({
                                  length: subArray?.length,
                                  index: subIndex,
                                })}
                                selected={selected}
                              />
                              {subItem.title}
                            </Td>
                            <Td align="right">{`${roundNumber(subItem.kpiValue)}`}</Td>
                            <Td align="right">{`${roundNumber(subItem.value)}%`}</Td>
                          </tr>
                        ))}
                    </TableGroup>
                  );
                })}
              </Table>
            </TableContainer>
          </ChartContainer>
        )}
      </ContentWrapper>
    );
  },
);

DonutChart.propTypes = {
  title: PropTypes.string,
  donutTitle: PropTypes.string,
  donutValue: PropTypes.number,
  donutUnit: PropTypes.string,
  legendColumns: PropTypes.array,
  filter: PropTypes.element,
  onSelect: PropTypes.func,
  showZero: PropTypes.bool,
  selected: PropTypes.bool,
  tooltipPointFormatter: PropTypes.func,
};

Donut.defaultProps = {
  title: null,
  donutTitle: null,
  donutValue: null,
  legendColumns: [],
  filter: null,
  data: [],
  onSelect: () => {},
  showZero: false,
  selected: false,
  tooltipPointFormatter: () => null,
};

DonutChart.displayName = 'DonutChart';
