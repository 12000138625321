import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const PanelHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const PanelHeaderTitleSite = styled.span`
  display: inline-block;
  font-size: 18px;
  margin-right: 20px;
`;

const PanelHeaderLowestPerformer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const PanelHeaderLowestPerformerLabel = styled.span`
  margin-right: 12px;
`;

const PanelHeaderLowestPerformerName = styled.span`
  font-size: 16px;
`;

const SitesIcons = styled(Icon).attrs((props) => ({
  size: props.size || 22,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))`
  margin-right: 4px;
`;

const Count = styled.span`
  margin-right: 14px;
  display: inline-block;
  font-size: 16px;
`;

export const SitePanelHeader = ({ assetCount, title, lowestPerformer, site }) => (
  <PanelHeader>
    <PanelHeaderTitle>
      <PanelHeaderTitleSite>{site?.name}</PanelHeaderTitleSite>
      <SitesIcons icon={Icons.TURBINE} size={16} />
      <Count>{assetCount}</Count>
    </PanelHeaderTitle>
    {lowestPerformer && (
      <PanelHeaderLowestPerformer>
        <PanelHeaderLowestPerformerLabel>{title}</PanelHeaderLowestPerformerLabel>
        <SitesIcons icon={Icons.TURBINE} size={15} />
        <PanelHeaderLowestPerformerName>{lowestPerformer.name}</PanelHeaderLowestPerformerName>
      </PanelHeaderLowestPerformer>
    )}
  </PanelHeader>
);

SitePanelHeader.propTypes = {
  site: PropTypes.any,
  assetCount: PropTypes.any,
  title: PropTypes.any,
  lowestPerformer: PropTypes.any,
};
