import { TableFilterTypes, Capability, PermissionScope } from '@ge/models/constants';

export const HandlingProcedureColumns = {
  GROUP_EVENT: 'group-event',
  EVENT_CODE: 'code',
  EVENT_NAME: 'name',

  GROUP_HANDLING_PROCEDURE: 'group-handling-procedure',
  HANDLING_PROCEDURE_NAME: 'procTitle',
  HANDLING_PROCEDURE_LAST_EDIT: 'updatedDate',
  HANDLING_PROCEDURE_EDIT_BY: 'updatedBy',

  GROUP_DETAILS: 'group-details',
  DETAILS: 'detail',
};

export const HandlingProcedureColumnDefs = {
  [HandlingProcedureColumns.GROUP_EVENT]: {
    labels: [
      {
        a11yKey: 'group-event',
        a11yDefault: 'EVENT',
      },
    ],
    cols: {
      [HandlingProcedureColumns.EVENT_CODE]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '20px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Event Code',
            sortValue: 'code',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [HandlingProcedureColumns.EVENT_NAME]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '200px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Event Name',
            sortValue: 'name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [HandlingProcedureColumns.GROUP_HANDLING_PROCEDURE]: {
    labels: [
      {
        a11yKey: 'group-handling-procedure',
        a11yDefault: 'HANDLING PROCEDURE',
      },
    ],
    cols: {
      [HandlingProcedureColumns.HANDLING_PROCEDURE_NAME]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '200px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Handling Procedure Name',
            sortValue: 'procTitle',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [HandlingProcedureColumns.HANDLING_PROCEDURE_LAST_EDIT]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '80px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: 'updatedDate',
            a11yDefault: 'Last Edited (GMT+5:30)',
            sortValue: 'updatedDate',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [HandlingProcedureColumns.HANDLING_PROCEDURE_EDIT_BY]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '70px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: 'updatedBy',
            a11yDefault: 'Edited by',
            sortValue: 'updatedBy',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [HandlingProcedureColumns.GROUP_DETAILS]: {
    fixedRight: true,
    cols: {
      [HandlingProcedureColumns.DETAILS]: {
        locked: true,
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.ADMIN] },
        ],
        width: '50px',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Details',
          },
        ],
        cell: {
          align: 'center',
          maxWidth: '50px',
        },
      },
    },
  },
};

// Define the default visible columns and their order.
export const defaultHandlingProcedureColumns = [
  {
    id: HandlingProcedureColumns.GROUP_EVENT,
    cols: [
      {
        id: HandlingProcedureColumns.EVENT_CODE,
        visible: true,
      },
      {
        id: HandlingProcedureColumns.EVENT_NAME,
        visible: true,
      },
    ],
  },
  {
    id: HandlingProcedureColumns.GROUP_HANDLING_PROCEDURE,
    cols: [
      {
        id: HandlingProcedureColumns.HANDLING_PROCEDURE_NAME,
        visible: true,
      },
      {
        id: HandlingProcedureColumns.HANDLING_PROCEDURE_LAST_EDIT,
        visible: true,
      },
      {
        id: HandlingProcedureColumns.HANDLING_PROCEDURE_EDIT_BY,
        visible: true,
      },
    ],
  },
  {
    id: HandlingProcedureColumns.GROUP_DETAILS,
    cols: [
      {
        id: HandlingProcedureColumns.DETAILS,
        visible: true,
      },
    ],
  },
];
