import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

/**
 * Takes a string and bolds the searched for sub-string.
 *
 * @param {string} text The text to be evaluated.
 * @param {string} textBold The text as a sub-string of the full text of the word to be searched.
 * @returns {jsx} The string with the searched for text **bold** as `JSX`.
 */

export const BoldSearch = ({ text, textBold }) => {
  const textBoldLength = textBold.length;
  const subStrStart = text.toLowerCase().indexOf(textBold.toLowerCase());
  const subStrEnd = subStrStart + textBoldLength;

  const textStart = text.slice(0, subStrStart);
  const boldStr = text.slice(subStrStart, subStrEnd);
  const textEnd = text.slice(subStrEnd);

  return subStrStart !== -1 ? (
    <>
      {textStart}
      {boldStr && <Bold>{boldStr}</Bold>}
      {textEnd}
    </>
  ) : (
    text
  );
};

const Bold = styled.b`
  font-weight: 900;
`;

BoldSearch.propTypes = {
  text: PropTypes.string,
  textBold: PropTypes.string,
};

BoldSearch.defaultProps = {
  text: '',
  textBold: '',
};
