import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { PageContainer } from '@ge/components/containers';
import { Loader } from '@ge/components/loader';
import { Menu, placements } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { DraggableTable, DynamicTable, ResizableTable } from '@ge/components/table';
import { TableArrow } from '@ge/components/table/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { NAMESPACE } from '@ge/feat-analyze/models/configure-analysis-template';
import NotesBadge from '@ge/shared/components/notes-badge';
import { useCaseTemplateTable } from '@ge/shared/data-hooks/use-case-template-table';
import { useFilterTemplateData } from '@ge/shared/data-hooks/use-filter-template-data';
import { killEventPropagation } from '@ge/shared/util';
import { globalColors } from '@ge/tokens';

import { CaseTemplateColumnDefs, CaseTemplateColumns } from '../../models/case-template-table-cols';

import DeleteCaseTemplates from './delete-case-templates';
//@custom styles

const CaseDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

const HoverText = styled.div`
  padding: 8px 15px;
  max-width: 200px;
  word-break: break-all;
  hyphens: auto;
`;

const DescriptionContainer = styled.div`
  display: flex;
  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ColumnHover = ({ children, value }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  if (!value) return null;

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        placement={placements.TOP_START}
      >
        <HoverText>{value}</HoverText>
      </Menu>
    </div>
  );
};

ColumnHover.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
};

//@functional component
const CaseTemplatesTable = ({
  columns,
  caseTemplateData,
  sortAction,
  sortMetric,
  sortDirection,
  onDrop,
  onFilter,
  onFilterChange,
  scrollable,
  draggable,
  resizable,
  isLoading,
  filterValues,
  filterDefs,
  showCaseTemplateDetails,
}) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });
  const [clearCaseTemplateSelections, setClearCaseTemplateSelections] = useState(false);
  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const { checkedRows, customHeaderFn, renderCheckbox } = useCaseTemplateTable({
    caseTemplateData,
    clearCaseTemplateSelections,
    setClearCaseTemplateSelections,
  });

  const { checkedRowsData: checkedCaseTemplateData } = useFilterTemplateData({
    checkedRows,
    caseTemplateData,
  });

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilter(columnKey, value);
    },
    [onFilter],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case CaseTemplateColumns.SELECTED:
          return renderCheckbox(cellValue);
        case CaseTemplateColumns.CASE_TEMPLATE_ID:
        case CaseTemplateColumns.TITLE:
        case CaseTemplateColumns.CASE_SOURCE:
        case CaseTemplateColumns.ANALYSIS_TEMPLATE_ID:
        case CaseTemplateColumns.ANALYSIS_SOURCE:
          return (
            <ColumnHover value={cellValue}>
              <DescriptionContainer>
                <span>{cellValue}</span>
              </DescriptionContainer>
            </ColumnHover>
          );
        case CaseTemplateColumns.BETA:
          return cellValue && <Badge color={globalColors.blue3} label={`BETA`} small />;
        case CaseTemplateColumns.PRIORITY:
          return cellValue ? <Severity level={cellValue} /> : <></>;
        case CaseTemplateColumns.TASKS:
          return <NotesBadge onClick={() => null} label={cellValue} />;
        case CaseTemplateColumns.CASE_TEMPLATE_DETAIL:
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          return (
            <CaseDetailButton type="button" onClick={(e) => showCaseTemplateDetails(e, cellValue)}>
              <TableArrow />
            </CaseDetailButton>
          );
        default:
          return null;
      }
    },
    [renderCheckbox],
  );

  const cellValueMapFn = useCallback((casetemplate) => {
    // primitive cells and custom cells.
    const {
      caseTemplateId,
      analysisTemplateId,
      source,
      analyticSource,
      isBeta,
      priority,
      taskCount,
      title,
    } = casetemplate;
    return {
      [CaseTemplateColumns.SELECTED]: { id: caseTemplateId },
      [CaseTemplateColumns.CASE_TEMPLATE_ID]: caseTemplateId,
      [CaseTemplateColumns.TITLE]: title,
      [CaseTemplateColumns.CASE_SOURCE]: source,
      [CaseTemplateColumns.ANALYSIS_TEMPLATE_ID]: analysisTemplateId,
      [CaseTemplateColumns.ANALYSIS_SOURCE]: analyticSource,
      [CaseTemplateColumns.BETA]: isBeta,
      [CaseTemplateColumns.PRIORITY]: priority,
      [CaseTemplateColumns.TASKS]: taskCount,
      [CaseTemplateColumns.CASE_TEMPLATE_DETAIL]: { id: caseTemplateId },
    };
  }, []);

  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: CaseTemplateColumnDefs,
    sortAction,
    sortedDirection,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
    filterValues,
    defaultCellBorder: true,
  });

  const CaseTemplateTable = useMemo(() => {
    if (resizable) return ResizableTable;
    if (draggable) return DraggableTable;
    return DynamicTable;
  }, [resizable, draggable]);

  if (!columns || columns.length === 0) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: caseTemplateData,
    rowKeyProperty: 'caseTemplateId',
    onValueSelect: () => null,
    dropHandler: draggable ? onDrop : () => null,
    isLoading,
    rowsSelected: [...checkedRows],
    draggable,
    TablekeyProperty: 'Casetemplate',
  };

  return (
    <PageContainer i18nReady={ready}>
      {checkedCaseTemplateData?.length > 0 && (
        <DeleteCaseTemplates
          checkedCaseTemplateData={checkedCaseTemplateData}
          setClearCaseTemplateSelections={setClearCaseTemplateSelections}
        />
      )}
      <CaseTemplateTable {...dynamicProps} />
    </PageContainer>
  );
};

CaseTemplatesTable.propTypes = {
  caseTemplateData: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onDrop: PropTypes.func,
  sortAction: PropTypes.func,
  scrollable: PropTypes.bool,
  onRowSelect: PropTypes.func,
  className: PropTypes.string,
  sortMetric: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterDefs: PropTypes.object,
  setFilterDefs: PropTypes.func,
  filterValues: PropTypes.object,
  openDLSidePanel: PropTypes.func,
  sortDirection: PropTypes.string,
  selectedSiteId: PropTypes.string,
  selectedAssetId: PropTypes.string,
  theme: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  showCaseTemplateDetails: PropTypes.func,
};

CaseTemplatesTable.defaultProps = {
  caseTemplateData: [],
  filterDefs: {},
  selectedSiteId: '',
  selectedAssetId: '',
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  scrollable: true,
  onFilter: () => null,
  onFilterChange: () => null,
  draggable: false,
  resizable: false,
  isLoading: false,
  showCaseTemplateDetails: () => null,
};

export default CaseTemplatesTable;
