import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo, useContext } from 'react';
import { useQuery } from 'react-query';

import { DateTimeFormats, QueryKey } from '@ge/models/constants';

import { PlanningContext } from '../context/planning-provider';
import { getServiceGroupIds } from '../util/worker-util';

export const useCrews = (startDate, endDate) => {
  const {
    planningState: { range },
  } = useContext(PlanningContext);

  let start, end;

  if (startDate?.length > 0 && endDate?.length > 0) {
    start = dayjs(startDate).format(DateTimeFormats.CREW_TIMING);
    end = dayjs(endDate).format(DateTimeFormats.CREW_TIMING);
  } else {
    start = dayjs(range.start).format(DateTimeFormats.CREW_TIMING);
    end = dayjs(range.end).format(DateTimeFormats.CREW_TIMING);
  }

  const types = 'Worker';
  const pageIdx = 0;
  const pageSize = 5000;
  const sortKey = 'id';
  const orderDirection = 'ASC';

  //Gives list of service groups
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  //Gives list of sites currently selected
  const { currentView } = useStoreState((state) => state.view);

  const serviceGroupIds = getServiceGroupIds(sortedServiceGroups, currentView).toString();

  const { fetchAllCrews, fetchCrewsOnServiceGroup } = useStoreActions((store) => store.workers);
  const { fetchAllWorkers } = useStoreActions((store) => store.workers);

  const { crews, workers, crewsSg } = useStoreState((state) => state.workers);

  const mapMembersToCrew = (crews, workers) => {
    crews.map((crew) => {
      crew.members = crew.members.map((mem) => {
        let member = workers.find((worker) => worker.username == mem.member_id);
        //TODO: check for isFetching && isFetchingCrew instead
        if (member) {
          return Object.assign(mem, {
            firstName: member.firstName,
            lastName: member.lastName,
            title: member.title,
            username: member.username,
            initials: member.initials,
            id: member.id,
          });
        } else {
          return mem;
        }
      });
      return crew;
    });
    return crews;
  };

  const { isFetchingCrew, isErrorCRew, errorCrew } = useQuery(
    [QueryKey.CREW, { serviceGroupIds, range }],
    () => fetchAllCrews({ serviceGroupIds, start, end }),
    {
      enabled: Boolean(serviceGroupIds?.length > 0),
    },
  );

  const { isFetchingCrewSg, isErrorCRewSg, errorCrewsg } = useQuery(
    [QueryKey.CREWSG, { serviceGroupIds }],
    () => fetchCrewsOnServiceGroup({ serviceGroupIds }),
    {
      enabled: Boolean(serviceGroupIds?.length > 0),
    },
  );
  const { isFetching, isError, error } = useQuery(
    [QueryKey.WORKER, { serviceGroupIds }],
    () =>
      fetchAllWorkers({
        serviceGroupIds,
        types,
        pageIdx,
        pageSize,
        sortKey,
        orderDirection,
        start,
        end,
      }),
    {
      enabled: Boolean(serviceGroupIds?.length > 0),
    },
  );

  return useMemo(
    () => ({
      data: {
        crews: crews.length && workers.length ? mapMembersToCrew(crews, workers) : [],
        workers: workers,
        crewsSg: crewsSg.length && workers.length ? mapMembersToCrew(crewsSg, workers) : [],
      },
      isFetching,
      isError,
      error,
      isFetchingCrew,
      isFetchingCrewSg,
      isErrorCRew,
      errorCrew,
      isErrorCRewSg,
      errorCrewsg,
    }),
    [
      crews,
      crewsSg,
      error,
      errorCrew,
      errorCrewsg,
      isError,
      isErrorCRew,
      isErrorCRewSg,
      isFetching,
      isFetchingCrew,
      isFetchingCrewSg,
      workers,
    ],
  );
};
