/**
 *  merge signal data points with timestamp
 * @returns merged points.
 */
const mergeSignalsByTs = (result, { ts, ...rest }) => {
  const i = result.findIndex((v) => v.td === ts);
  if (i !== -1) {
    result[i] = { ...result[i], ...rest };
  } else {
    result.push({ ts, ...rest });
  }
  return result;
};

/**
 *  merge signal data response to saved response
 *
 * @param {Object} signalDataResponse signal data API response
 * @param {Object} signalDataResponse saved signal data
 *
 * @returns merged signal data response with saved response.
 */
export const mergeSignalData = (oldData, newData = {}) => {
  newData.data = newData?.data?.map(({ assetId, timeAggr, signalData, notFound, ...rest }) => {
    const asset = oldData?.data?.find(
      (data) => data.assetId === assetId && data.timeAggr === timeAggr,
    );
    return {
      assetId,
      timeAggr,
      signalData: asset
        ? [...asset.signalData, ...signalData].reduce(mergeSignalsByTs, [])
        : signalData,
      notFound: asset ? [...asset.notFound, ...notFound] : notFound,
      ...rest,
    };
  });
  if (oldData?.errors?.length > 0) {
    newData.errors = oldData.errors.concat(newData?.errors ?? []);
  }
  return newData;
};
