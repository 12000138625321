import * as request from './api';

/**
 * Get a small object of total assets for user and total offline
 */
export const fetchAssetSummary = () => request.get('/summary/assets');

/**
 * Dynamically load the summary mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/summary/summary.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
