import { PropTypes } from 'prop-types';
import path from 'ramda/src/path';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const PanelNavWrapper = styled.nav`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  span {
    text-transform: uppercase;
  }
`;

const PanelNavActions = styled.div`
  position: absolute;
  right: 5px;
  top: 10px;

  .popout & {
    right: 15px;
    top: 6px;
  }
`;

const PanelNavHistory = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.color || props.theme.entityDetails.headerIconColor,
  rotate: 90,
}))``;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CLOSE,
  color: props.color ? props.color : props.theme.entityDetails.headerIconColor,
}))``;

const WindowIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.NEW_WINDOW,
  color: props.color ? props.color : props.theme.entityDetails.headerIconColor,
}))``;

export const SidePanelNav = ({
  color,
  navBack,
  closePanel,
  disableHistory,
  spawnWindow,
  onHistoryBack,
}) => {
  const history = useHistory();
  const hasHistory = () => path(['location', 'state', 'initial'], history) === false;

  // Provide a smart default for history management.
  const defaultHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <PanelNavWrapper>
      <PanelNavHistory>
        {(navBack || (hasHistory() && !disableHistory)) && (
          <button type="button" onClick={onHistoryBack || defaultHistoryBack}>
            <ChevronIcon color={color} />
          </button>
        )}
      </PanelNavHistory>
      <PanelNavActions>
        {spawnWindow && (
          <button type="button" onClick={spawnWindow}>
            <WindowIcon color={color} />
          </button>
        )}
        {closePanel && (
          <button type="button" onClick={closePanel}>
            <CloseIcon color={color} />
          </button>
        )}
      </PanelNavActions>
    </PanelNavWrapper>
  );
};

SidePanelNav.propTypes = {
  color: PropTypes.string,
  closePanel: PropTypes.func,
  disableHistory: PropTypes.bool,
  spawnWindow: PropTypes.func,
  onHistoryBack: PropTypes.func,
  navBack: PropTypes.bool,
};

SidePanelNav.defaultProps = {
  color: null,
  disableHistory: false,
  spawnWindow: null,
  closePanel: null,
};
