import { useStoreState } from 'easy-peasy';
import { isArray } from 'highcharts';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TooltipCell } from '@ge/components/table/table';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

const Readings = styled.section`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`;

// TODO: confirm if dl is the best element to use here in terms of semantics, etc.
const ReadingsCol = styled.dl`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  height: 100%;
  margin-bottom: 5px;
  line-height: 15px;
  margin-top: 20px;
`;

const ReadingsColLeft = styled(ReadingsCol)`
  flex-basis: 50%;
  div:first-child {
    dt {
      margin-left: 12px;
    }
  }
  div {
    dt {
      flex: 1 1 48%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    dd {
      flex: 1 1 45%;
    }
  }
`;

const ReadingsColRight = styled(ReadingsCol)`
  flex-basis: 50%;
  div:first-child {
    dt {
      margin-left: 6px;
    }
  }
  div {
    dt {
      flex: 1 1 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    dd {
      flex: 1 1 10%;
    }
  }
`;

const ReadingsRow = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  + div {
    margin-top: 6px;
    margin-left: 12px;
  }
  dt {
    color: ${({ theme }) => theme.entityDetails.readings.readingHeaderColor};
    font-size: 12px;
    text-align: right;
  }
  dd {
    color: ${({ theme }) => theme.entityDetails.readings.readingValueColor};
    font-size: 12px;
    font-weight: ${typography.weight.light};
    margin-left: 16px;
    text-align: left;
    opacity: ${(props) => (props.isDisconnected ? 0.3 : 1)};
  }
`;

export const AssetInfoDetails = ({ entity, entityData }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const assetDetails = getAssetById(entity.id);

  const { getSiteById } = useStoreState((store) => store.sites);
  const siteDetails = getSiteById(assetDetails.site.id);

  if (!ready || !entityData) {
    return null;
  }
  const assetData = entityData?.assetInfo;
  //   const { componentList } = entityData
  assetData.siteName = siteDetails?.name;
  const leftData =
    assetData && Object.keys(assetData).slice(0, Math.floor(Object.keys(assetData).length / 2));
  const rightData =
    assetData && Object.keys(assetData).slice(Math.floor(Object.keys(assetData).length / 2));

  return assetData ? (
    <Readings>
      <ReadingsColLeft>
        {leftData?.map((s, k) => {
          let index = k + 1;
          return (
            !isArray(assetData[s]) &&
            typeof assetData[s] !== 'object' && (
              <ReadingsRow key={index}>
                <dt>
                  <TooltipCell
                    tooltip={t(
                      `readings_entity.asset_info_${s}`,
                      `readings_entity.asset_info_${s}`,
                    )}
                    zIndex={elevations.P20}
                  >
                    {t(`readings_entity.asset_info_${s}`, `readings_entity.asset_info_${s}`)}
                  </TooltipCell>
                </dt>
                <dd>{assetData[s]}</dd>
              </ReadingsRow>
            )
          );
        })}
      </ReadingsColLeft>
      <ReadingsColRight>
        {rightData?.map((s, k) => {
          let index = k + 1;
          return (
            !isArray(assetData[s]) &&
            typeof assetData[s] !== 'object' && (
              <ReadingsRow key={index}>
                <dt>
                  <TooltipCell
                    tooltip={t(
                      `readings_entity.asset_info_${s}`,
                      `readings_entity.asset_info_${s}`,
                    )}
                    zIndex={elevations.P20}
                  >
                    {t(`readings_entity.asset_info_${s}`, `readings_entity.asset_info_${s}`)}
                  </TooltipCell>
                </dt>
                <dd>{assetData[s]}</dd>
              </ReadingsRow>
            )
          );
        })}
      </ReadingsColRight>
    </Readings>
  ) : (
    ''
  );
};

AssetInfoDetails.propTypes = {
  entity: PropTypes.instanceOf(Object),
  entityData: PropTypes.instanceOf(Object),
};
