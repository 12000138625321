import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens';

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
  margin-bottom: 10px;
`;

export const TimingErrors = ({ className, index }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { errors } = useFormContext();

  return (
    <div className={className}>
      {errors?.timing?.[index]?.endTime?.type === 'endTimeValid' && (
        <ErrorText>
          {t('form.error_end_time', 'End Time cannot be less than or equal to Start Time')}
        </ErrorText>
      )}
    </div>
  );
};

TimingErrors.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
};

TimingErrors.defaultProps = {
  className: null,
  index: '',
};
