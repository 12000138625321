import { EntityType, SortDirection } from '@ge/models/constants';
import { CaseStatus } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

export * from './solar';
export * from './storage';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;
const BASE_URL_DAV = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_API;
const BASE_URL_DAV_ASSET = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_ASSET_API;
const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_HYBRIDS_API;

/**
 * Fetch analyze fleet kpi data for the specified categories
 * @param {Array} categories IEC Categories
 * @param {Date} endDate The end date
 * @param {string} entityAggr How data should be aggregated, for example at the site or region-level
 * @param filters object containing filters for the query
 * @param {Date} startDate The start date
 * @param {string} timeAggr The time interval for aggregation, for example daily, weekly, or monthly
 */
export const fetchFleetKpiData = ({
  categories = [],
  endDate,
  entityAggr,
  filters,
  startDate,
  timeAggr,
}) =>
  request.post(
    '/dav/fleets/kpi-data',
    {
      categories,
      endDate,
      entityAggr,
      filters,
      startDate,
      timeAggr,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );

/**
 * Fetch analyze fleet site performance data for the specified categories
 * @param {string} category IEC Category
 * @param {Date} endDate The end date
 * @param {string} sortDirection Sort direction on the performance data
 * @param {Date} startDate The start date
 * @param {string} timeAggr The time interval for aggregation, for example daily, weekly, or monthly
 */
export const fetchFleetSitePerformance = ({
  category,
  endDate,
  filters,
  pageIndex,
  pageSize,
  sortDirection = SortDirection.ASC,
  startDate,
  timeAggr,
}) =>
  request.post(
    '/dav/fleets/site-performance',
    {
      category,
      endDate,
      filters,
      pageIndex,
      pageSize,
      sortDirection,
      startDate,
      timeAggr,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );

/**
 * Fetch analyze lowest performing regions
 */
export const fetchLowestPerformingRegions = () =>
  request.get('/dav/regions/lowest-performing', { baseURL: BASE_URL_DAV_ASSET });

/**
 * Fetch Analyze all Sites in a Region by ID
 * @param {String} regionId Region ID
 * @param {Array} kpiCategories IEC Categories
 */
//TODO Delete this? It's no longer being used.
export const fetchRegionSites = ({
  categories = [],
  regionId,
  startDate,
  endDate,
  filters,
  timeAggr,
}) => {
  return request.post(
    `/dav/regions/${regionId}/sites/kpi-data`,
    {
      categories,
      startDate,
      endDate,
      filters,
      timeAggr,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch Analyze Sites by IDs
 * @param {Array} kpiCategories IEC Categories
 */
export const fetchAllSitesSites = ({
  categories = [],
  startDate,
  endDate,
  filters,
  timeAggr,
  entityAggr,
}) => {
  return request.post(
    `/dav/sites/kpi-data`,
    {
      categories,
      startDate,
      endDate,
      filters,
      timeAggr,
      entityAggr,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch Analyze regions system loss
 * @param {String} regionId Region id
 */
export const fetchRegionSystemLoss = (regionId) =>
  request.get(`/dav/regions/${regionId}/system-loss`, {
    baseURL: BASE_URL_DAV_ASSET,
  });

/**
 * Fetch Analyze regions IEC data
 * @param {String} regionId Region id
 * @param {String} category IEC Category
 */
export const fetchRegionIecCategory = ({ categories, endDate, filters, regionId, startDate }) =>
  fetchIecCategory({
    categories,
    endDate,
    entityId: regionId,
    entityType: EntityType.REGION,
    filters,
    startDate,
  });

/**
 * Fetch Analyze regions IEC data
 * @param {String} entityId entity id
 * @param {String} category IEC Category
 */
export const fetchIecCategory = ({
  categories,
  endDate,
  filters,
  entityId,
  entityType,
  startDate,
}) =>
  request.post(
    '/dav/reliability/iec',
    {
      categories,
      endDate,
      entityId,
      entityType,
      filters,
      startDate,
    },
    {
      baseURL: BASE_URL_DAV,
    },
  );

/**
 * Fetch Analyze regions IEC data
 * @param {String} regionId Region id
 * @param {String} category IEC Category
 */
export const fetchIecCategoryMetaData = ({
  category,
  endDate,
  filters,
  entityId,
  startDate,
  entityType,
}) =>
  request.post(
    '/dav/reliability/iec/meta-data',
    {
      category,
      endDate,
      entityId,
      entityType,
      filters,
      startDate,
    },
    {
      baseURL: BASE_URL_DAV,
    },
  );

/**
 * Fetch analyze site overview
 */
export const fetchSiteOverview = (siteId) =>
  request.get(`/dav/sites/${siteId}/overview`, {
    baseURL: BASE_URL_DAV_ASSET,
  });

/**
 * Fetch Analyze site system loss
 * @param {String} siteId Site id
 */
export const fetchSiteSystemLoss = (siteId) =>
  request.get(`/dav/sites/${siteId}/system-loss`, {
    baseURL: BASE_URL_DAV_ASSET,
  });

/**
 * Fetch Analyze sites IEC data
 * @param {Object} params Params for retrieving site IEC data
 */
export const fetchSiteIecCategory = ({ categories, endDate, filters, siteId, startDate }) =>
  request.post(
    '/dav/reliability/iec',
    { categories, endDate, entityId: siteId, entityType: EntityType.SITE, filters, startDate },
    {
      baseURL: BASE_URL_DAV,
    },
  );

/**
 * Fetch Analyze sites IEC data
 * @param {Object} params Params for retrieving site IEC data
 */
export const fetchEntityIecCategoryAssets = ({
  category,
  endDate,
  filters,
  entityId,
  entityType = EntityType.SITE,
  startDate,
  iecState,
  pageSize,
  pageIndex,
}) =>
  request.post(
    '/dav/reliability/iec/assets',
    {
      category,
      endDate,
      entityId,
      entityType,
      filters,
      startDate,
      iecStates: [iecState],
      pageSize,
      pageIndex,
    },
    {
      baseURL: BASE_URL_DAV,
    },
  );

/**
 * Fetch Analyze OEM asset data
 * @param {Object} params Params for retrieving OEM asset data
 */
export const fetchOemAssetData = ({
  category,
  endDate,
  entityId,
  entityType,
  iecStates,
  oemStates,
  pageIndex,
  pageSize,
  startDate,
  filters,
}) =>
  request.post(
    `/dav/reliability/oem/assets`,
    {
      category,
      endDate,
      entityId,
      entityType,
      iecStates,
      oemStates,
      pageIndex,
      pageSize,
      startDate,
      filters,
    },
    {
      baseURL: BASE_URL_DAV,
    },
  );

/**
 * Fetch analyze region kpi data for the specified categories
 * @param {String} regionId Region id
 * @param {Array} kpiCategories IEC Categories
 */
export const fetchRegionKpiData = ({ categories = [], regionId, ...rest }) => {
  return request.post(
    `/dav/regions/${regionId}/region-kpi-data`,
    {
      categories,
      ...rest,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch analyze site IEC data by region
 * @param {String} regionId Region id
 * @param {Array} kpiCategories IEC Categories
 */
export const fetchSiteKpiDataByRegion = (regionId, kpiCategories = []) => {
  const categories = kpiCategories.join(',');
  return request.get(`/dav/regions/${regionId}/sites/region-kpi-data`, {
    baseURL: BASE_URL_DAV_ASSET,
    params: { categories },
  });
};

/**
 * Fetch analyze site kpi data for the specified categories
 * @param {String} siteId Site id
 * @param {Array} kpiCategories IEC Categories
 */
export const fetchSiteKpiData = ({ categories = [], filters, siteId, ...rest }) => {
  return request.post(
    `/dav/sites/sites-kpi-data`,
    {
      categories,
      filters: { ...filters, ...(siteId && { siteIds: [siteId] }) },
      ...rest,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch analyze asset IEC data by site
 * @param {String} siteId Site id
 * @param {Array} kpiCategories IEC Categories
 */
export const fetchAssetKpiDataBySite = (siteId, kpiCategories = []) => {
  const categories = kpiCategories.join(',');
  return request.get(`/dav/sites/${siteId}/assets/site-kpi-data`, {
    baseURL: BASE_URL_DAV_ASSET,
    params: { categories },
  });
};

/**
 * Fetch the power curves for all assets on the specified site
 */
export const fetchSiteAssetPowerCurve = (siteId, { startDate, endDate }) =>
  request.get(`/dav/sites/${siteId}/asset-power-curve`, {
    baseURL: BASE_URL_DAV_ASSET,
    params: { start: startDate, end: endDate },
  });

/**
 * Fetch the power curve for the specified asset
 */
export const fetchAssetPowerCurve = (assetId, params) =>
  request.get(`/dav/assets/${assetId}/power-curve`, {
    baseURL: BASE_URL_DAV_ASSET,
    params,
  });

/**
 * Fetch the signal data for the specified asset
 */
export const fetchAssetSignalData = (assetId, { start, end, signals = [] }) => {
  const signalIds = signals.join(',');
  return request.get(`/dav/assets/${assetId}/signal-data`, {
    baseURL: BASE_URL_DAV_ASSET,
    params: { start, end, signalIds },
  });
};

/**
 * Fetch the details Info of a specified asset
 */
export const fetchAssetInfo = (assetId, params) =>
  request.get(`/dav/assets/${assetId}`, {
    baseURL: BASE_URL_DAV_ASSET,
    params,
  });

/**
 * Fetch site condition aggregates for the specified site and condition types
 */
export const fetchSiteConditionAggregate = (siteId, startDate, endDate, filters) => {
  return request.post(
    `/dav/sites/${siteId}/condition-aggregates`,
    {
      endDate,
      filters,
      startDate,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch analyze asset kpi
 */
export const fetchAllAssetCards = () =>
  request.get('/dav/assets/asset-cards', { baseURL: BASE_URL_DAV });

/**
 * Fetch Analyze asset IEC data
 * @param {Object} params Params for retrieving asset IEC data
 */
export const fetchAssetIecCategory = ({ categories, assetId, startDate, endDate, filters }) =>
  request.post(
    `/dav/assets/${assetId}/iec`,
    { categories, endDate, filters, startDate },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );

/**
 * Fetch cases
 *
 * @param {Array} siteIds
 */
export const fetchAnalyzeCases = (siteIds) => {
  const siteQuery = siteIds.join(',');
  return request.get('/dav/cases', {
    baseURL: BASE_URL_DAV,
    params: { siteIds: siteQuery, status: CaseStatus.OPEN },
  });
};

/**
 * Fetch data explorer overview for the specified assets
 *
 * @param {Array} assetIds Asset ids
 */
export const fetchAssetSignalMaps = () => {
  return request.get('/cmn/assets/signal-maps', {
    baseURL: CMN_BASE_URL,
  });
};

/**
 * Fetch data explorer overview for the specified assets
 *
 * @param {Array} assetIds Asset ids
 */
export const fetchDataExplorerAssetOverview = (assetIds) => {
  return request.get('/cmn/assets/data-explorer/overview', {
    baseURL: CMN_BASE_URL,
    params: { assets: assetIds.join(',') },
  });
};

/**
 * Fetch analyze asset kpi data for the specified categories
 * @param {String} assetId Asset id
 * @param {Array} kpiCategories IEC Categories
 */
export const fetchAssetKpiData = ({
  categories = [],
  endDate,
  filters,
  assetId,
  startDate,
  timeAggr,
}) => {
  return request.post(
    `/dav/assets/${assetId}/asset-kpi-data`,
    {
      categories,
      endDate,
      filters,
      startDate,
      timeAggr,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch analyze condition aggregation data for the specified asset
 * @param {String} assetId Asset id
 * @param startDate
 * @param endDate
 * @param filters
 */
export const fetchAssetConditionData = (_entityId, startDate, endDate, filters) => {
  return request.post(
    `/dav/assets/condition-aggregates`,
    {
      endDate,
      filters,
      startDate,
    },
    {
      baseURL: BASE_URL_DAV_ASSET,
    },
  );
};

/**
 * Fetch case analysis template
 * @param body
 * @return {Promise<unknown>}
 */

export const createCaseTemplate = (body) => {
  return request.post(`/dav/cases/case-template`, body, {
    baseURL: BASE_URL_DAV,
  });
};

export const deleteCaseTemplate = (body) => {
  return request.deleteItemWithBody(`/dav/cases/case-template`, body, {
    baseURL: BASE_URL_DAV,
  });
};

export const updateCaseTemplate = (body) => {
  return request.patch(`/dav/cases/case-template/update`, body, {
    baseURL: BASE_URL_DAV,
  });
};
/**
 * Fetch case analysis template
 * @param templateId
 * @return {Promise<unknown>}
 */
export const createCaseAnalysisTemplate = (body) => {
  return request.post(`/dav/cases/analysis-template`, body, {
    baseURL: BASE_URL_DAV,
  });
};

export const updateCaseAnalysisTemplate = ({ analysisTemplateId, ...body }) => {
  return request.patch(`/dav/cases/analysis-template`, body, {
    baseURL: BASE_URL_DAV,
    params: { analysisTemplateId },
  });
};

export const fetchCaseAnalysisTemplate = (templateIds, assetId) => {
  return request.get(`/dav/cases/analysis-template`, {
    baseURL: BASE_URL_DAV,
    params: {
      templateIds: templateIds.join(','),
      assetId,
    },
  });
};

export const fetchCaseAnalysisTemplates = () => {
  return request.get(`/dav/cases/analysis-templates`, {
    baseURL: BASE_URL_DAV,
  });
};

export const fetchCAnalysisTemplatesData = () => {
  return request.get(`/dav/cases/analysis-data`, {
    baseURL: BASE_URL_DAV,
  });
};

export const fetchAdapterDetails = (siteName) => {
  return request.get(`/dav/adapter`, {
    baseURL: BASE_URL_DAV,
    params: {
      siteId: siteName,
    },
  });
};

export const defaultConfiguration = (adapterId) => {
  return request.get(`/dav/defaultConfiguration`, {
    baseURL: BASE_URL_DAV,
    params: {
      adapterId: adapterId,
    },
  });
};

export const fetchUploadedConfigs = (siteID, adapterId) => {
  return request.get(`/dav/configurations`, {
    baseURL: BASE_URL_DAV,
    params: {
      siteConfigId: siteID,
      adapterId: adapterId,
    },
  });
};

export const uploadConfig = (files, siteId, adapterId, uploadId, base64XML) => {
  const fileName = files[0].file?.name;
  if (uploadId === null) {
    uploadId = '';
  }

  return request.post(
    `/dav/configure`,
    {
      siteId: String(siteId),
      adapterId: String(adapterId),
      uploadId: String(uploadId),
      fileName: String(fileName),
      fileData: String(base64XML),
    },
    {
      baseURL: BASE_URL_DAV,
    },
  );
};

export const fetchSelectFileDetails = (
  startDate,
  endDate,
  selectedAssetIds,
  selectedFileType,
  selectedFileCategory,
  selectedSubCategoryValues,
  selectedSiteID,
) => {
  return request.get('/dav/filedetails', {
    baseURL: BASE_URL_DAV,
    params: {
      siteId: selectedSiteID,
      assetId: selectedAssetIds.join(','),
      categoryId: selectedFileCategory,
      subCategoryId: selectedSubCategoryValues.join(','),
      startTime: startDate,
      endTime: endDate,
      fileType: selectedFileType ?? '',
    },
  });
};
/* return request.post(
    '/dav/filedetails',
    {
      siteId: selectedSiteID,
      assetId: selectedAssetIds,
      categoryId: selectedCategoryType,
      subCategoryId: '',
      startTime: startDate,
      endTime: endDate,
      fileType: selectedFileType,
    },
    { baseURL: BASE_URL },
  );
};*/
/*export const downloadFiles = (selectedKeys) => {
  return request.post(
    '/dav/fileDownloadData',
    {
      selectedKeys,
    },
    { baseURL: BASE_URL },
  );
};*/
export const downloadFiles = (selectedKeys) => {
  return request.get('/dav/fileDownloadData', {
    baseURL: BASE_URL_DAV,
    params: {
      s3FileKey: encodeURIComponent(selectedKeys.join(',')),
    },
  });
};

/**
 * Fetch mal events
 * @param params
 * @return {Promise<unknown>}
 */
export const fetchMalEvents = (params) => {
  return request.post('/dav/mal-events', params, { baseURL: BASE_URL_DAV_ASSET });
};

export const fetchMalCategory = (siteId) => {
  return request.get('/dav/mal-categories', {
    baseURL: BASE_URL_DAV_ASSET,
    params: { siteId: siteId },
  });
};

// To add or edit multiple events
export const createMalEvent = (_events) => {
  return request.put(`/dav/mal-events/event`, _events, {
    baseURL: BASE_URL_DAV_ASSET,
  });
};

// To add or edit multiple events
export const deleteMalEvent = (_events) => {
  return request.deleteItemWithBody(`/dav/mal-events/category/event`, _events, {
    baseURL: BASE_URL_DAV_ASSET,
  });
};

// To approve multiple events
export const approveMalEvent = (_events) => {
  return request.post(`/dav/mal-events/approve`, _events, {
    baseURL: BASE_URL_DAV_ASSET,
  });
};
/**
 * Fetch pareto analysis data by site
 * @param {Array<string>} assetIds Site id
 * @param {Date} startDate selected from global date range
 * @param {Date} endDate selected from global date range
 * @param {String} severity selected from event type
 * @param {string} reportBy selected from report by
 * @param {String} groupBy grouped by events or assets
 */
export const fetchParetoAnalysisDataBySite = ({
  assetIds,
  startDate,
  endDate,
  severity,
  reportBy,
  groupBy,
  siteType,
  ...rest
}) => {
  return request.post(
    `dav/${siteType}/reliability`,
    {
      assetIds,
      startDate,
      endDate,
      severity,
      reportBy,
      groupBy,
      ...rest,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Dynamically load the region mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/analyze.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });

export const fetchCaseTemplateTable = () => {
  return request.get('/dav/cases/case-template-table', {
    baseURL: BASE_URL_DAV,
  });
};

export const fetchAnalyzeCasesByStatus = (siteIds, status) => {
  const siteQuery = siteIds.join(',');
  return request.get('/dav/cases', {
    baseURL: BASE_URL_DAV,
    params: { siteIds: siteQuery, status },
  });
};
