import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Textarea } from '@ge/components/textarea';
import { StatusColor } from '@ge/tokens/colors/colors';

const StyledTextArea = styled(Textarea)`
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
`;

export const TextareaMetaField = ({
  className,
  defaultValue,
  label,
  maxLength,
  metadata,
  name,
  onResize,
  placeholder,
  tabIndex,
}) => {
  const { readOnly, required, completeRequired = false } = metadata;
  const { control, errors } = useFormContext();
  const req = required || completeRequired;
  const rules = {
    required: req,
    validate: (value) => {
      return req ? !!value.trim() : !req;
    },
  };
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ onChange, value }) => (
        <StyledTextArea
          className={className}
          disabled={readOnly}
          errors={errors}
          id={name}
          label={label}
          maxLength={maxLength}
          name={name}
          onChange={onChange}
          onResize={onResize}
          placeholder={placeholder}
          required={required || completeRequired}
          tabIndex={tabIndex}
          value={value}
        />
      )}
    />
  );
};

TextareaMetaField.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  metadata: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onResize: PropTypes.func,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  className: PropTypes.string,
};

TextareaMetaField.defaultProps = {
  defaultValue: '',
  label: '',
  maxLength: null,
  onChange: () => {},
  onResize: () => {},
  placeholder: '',
  tabIndex: null,
  value: '',
};
