import { globalColors } from '@ge/tokens/colors';

export const SolarSeverity = {
  1: globalColors.red2,
  2: globalColors.straw4,
  3: globalColors.blue3,
};

export const StorageSeverity = {
  1: globalColors.red2,
  2: globalColors.orange1,
  3: globalColors.straw4,
  4: globalColors.lavender4,
  5: globalColors.blue3,
};
