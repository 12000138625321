import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { Tab, Tabs } from '@ge/components/tabs';
import { EntityType } from '@ge/models/constants';
import { getUserName } from '@ge/shared/services/auth';

import OpenTickets from './open-tickets';
import SiteContact from './site-contact';
import TicketDetailsForm from './ticket-detail-form';

const TicketSelect = styled(Select)`
  color: ${(props) => props.theme.entityDetails.tickets.actionsColor};
  border-radius: 0;
`;

const TicketWrapper = styled.section`
  margin-top: 30px;
  padding: 0 10px;
  .title {
    display: flex;
    border-bottom: solid 1px ${(props) => props.theme.entityDetails.tickets.titleBorder};
    h2 {
      flex: 1;
    }
    .button-group {
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      button {
        margin-left: 16px;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  .left-col {
    margin-right: 14px;
    width: 200px;
  }
  .new-ticket {
    border-radius: 3px;
    align-self: flex-start;
    margin-bottom: 50px;
    overflow: hidden;
    .action {
      background: ${(props) => props.theme.entityDetails.tickets.background};
      border-bottom: solid 2px ${(props) => props.theme.entityDetails.tickets.borderColor};
      display: flex;
      align-items: center;
      &:last-child {
        border: 0;
      }
    }
    select {
      flex: 1;
    }
    svg {
      margin-right: 10px;
      margin-left: 10px;
    }
    button {
      display: block;
      width: 100%;
      text-align: left;
      padding: 14px 0;
      color: ${(props) => props.theme.entityDetails.tickets.actionsColor};
      font-weight: 700;
    }
  }
  .creator {
    padding: 20px 10px;
    div {
      margin-bottom: 6px;
      h5 {
        color: ${(props) => props.theme.entityDetails.tickets.creatorHeader};
      }
    }
  }
`;

const TicketDetails = styled.div`
  display: flex;
  margin-top: 24px;
`;

const SquareIcon = styled.div`
  width: 12px;
  height: 12px;
  display: block;
  border: solid 1px ${(props) => props.theme.entityDetails.tickets.iconColor};
  border-radius: 1px;
  margin-right: 10px;
  margin-left: 10px;
`;

const SnoozeIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tickets.iconColor,
  icon: Icons.SNOOZE_BELL,
  size: 14,
}))``;

const EscalateIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tickets.iconColor,
  icon: Icons.ESCALATE,
  size: 14,
}))``;

const EditTicket = ({ asset, entityType, ticket }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const [ticketType, setTicketType] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [assetCondition, setAssestCondition] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const userName = getUserName();

  useEffect(() => {
    // TODO: Checking for ticket.id stops the new ticket form from resetting when the
    //  events update. This does NOT solve the high-level problem of the ticket form
    //  being reset by updates. That needs to be addressed before wiring up CRUD services.
    if (ticket.id) {
      setTicketType(ticket.type || null);
      setCreatedDate(ticket.created || null);
    }
  }, [ticket]);

  const selectTicketType = useCallback(
    (option) => {
      setTicketType(option.value);

      // TODO: Change this to look at specific ticket state. We are removing NEW_TICKET type.
      if (entityType === EntityType.NEW_TICKET) {
        setCreatedDate(new Date());
      }
    },
    [entityType],
  );

  const selectTicketStatus = (option) => setTicketStatus(option.value);
  const selectAssetCondition = (option) => setAssestCondition(option.value);

  const contact = {
    name: 'Phillip Rochester',
    title: 'Site Operations Manager',
  };

  const ticketTypeOptions = [
    { value: 'select', label: t('ticket_entity.select_type', 'SELECT TYPE') },
    { value: 'work_action', label: t('ticket_entity.work_action', 'WORK ACTION') },
    { value: 'tea', label: t('ticket_entity.tea', 'TEA') },
    { value: 'outage', label: t('ticket_entity.outage', 'OUTAGE') },
    {
      value: 'onsite-notification',
      label: t('ticket_entity.onsite_notification', 'ONSITE NOTIFICATION'),
    },
  ];

  const ticketStatusOptions = [
    { value: 'open', label: t('ticket_entity.open', 'OPEN') },
    { value: 'closed', label: t('ticket_entity.closed', 'CLOSED') },
  ];

  if (!asset) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  return (
    <TicketWrapper>
      <div className="title">
        <h2>
          {
            // TODO: Change this to look at specific ticket state. We are removing NEW_TICKET type.
            entityType === EntityType.NEW_TICKET
              ? t('ticket_entity.new_ticket', 'NEW TICKET')
              : `${t('ticket_entity.ticket', 'TICKET')} #${ticket.number}`
          }
        </h2>
        <div className="button-group">
          <Button type="button">{t('ticket_entity.cancel', 'Cancel')}</Button>
          <Button type="button" disabled={ticketType === null}>
            {t('ticket_entity.save', 'Save')}
          </Button>
        </div>
      </div>
      <ContentWrapper>
        <div className="left-col">
          <div className="new-ticket">
            <div className="action">
              <SquareIcon />
              <TicketSelect
                onChange={selectTicketType}
                value={ticketTypeOptions[0]}
                options={ticketTypeOptions}
              />
            </div>
            {ticketType !== null && (
              <div>
                <div className="action">
                  <SnoozeIcon />
                  <TicketSelect
                    onChange={selectTicketStatus}
                    value={ticketStatusOptions.filter((option) => option.value === ticketStatus)}
                    options={ticketStatusOptions}
                  />
                </div>
                <div className="action">
                  <button type="button">
                    <EscalateIcon />
                    {t('ticket_entity.escelate', 'ESCALATE')}
                  </button>
                </div>
              </div>
            )}
          </div>
          {ticketType !== null && (
            <div className="creator">
              <div>
                <h5>{t('ticket_entity.opened_by', 'Opened by')}</h5>
                <h4>{userName}</h4>
              </div>
              <div>
                <h5>{t('ticket_entity.time_opened', 'Time Opened')}</h5>
                <h4>{dayjs(createdDate).format('DD MMM YYYY, HH:mm')}</h4>
              </div>
              <div>
                <h5>{t('ticket_entity.closed_by', 'Closed by')}</h5>
                <h4>-</h4>
              </div>
            </div>
          )}
        </div>
        <OpenTickets asset={asset} />
      </ContentWrapper>
      {ticketType !== null && (
        <Tabs small>
          <Tab label="Details">
            <TicketDetails>
              <SiteContact contact={contact} />
              <TicketDetailsForm
                ticket={ticket}
                selectOnChange={selectAssetCondition}
                selectValue={assetCondition}
              />
            </TicketDetails>
          </Tab>
          <Tab label="Messaging" />
        </Tabs>
      )}
    </TicketWrapper>
  );
};

EditTicket.propTypes = {
  asset: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
  ticket: PropTypes.instanceOf(Object),
};

EditTicket.defaultProps = {
  entityType: null,
  ticket: null,
};

export default EditTicket;
