import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchCaseAnalysisTemplates } from '../services';

const useCaseAnalysisTemplates = ({ enabled = false }) => {
  return useQuery([QueryKey.CASE_ANALYSIS_TEMPLATE], () => fetchCaseAnalysisTemplates(), {
    enabled,
    ...Config.EXECUTE_ONCE,
  });
};

export default useCaseAnalysisTemplates;
