import { useStoreActions, useStoreState } from 'easy-peasy';
import hasIn from 'ramda/src/hasIn';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { FullScreenModal } from '@ge/components/full-screen-modal/full-screen-modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import { FullScreenChartModal } from '../components/troubleshooting/full-screen-chart-modal';
import { MetricsPanel } from '../components/troubleshooting/metrics-panel';
import { TroubleshootingSidebar } from '../components/troubleshooting/troubleshooting-sidebar';

const TroubleshootingContainer = styled.div`
  overflow-x: hidden;
  padding: 20px;
`;

const TroubleshootingContent = styled.div`
  display: flex;
  padding: 18px 0 0;
  overflow: hidden;
`;

const AnalyzeTroubleshooting = () => {
  const { anomalyId } = useParams();
  const { fetchAnomalyById } = useStoreActions((actions) => actions.issues);
  const { fetchIssuesByCaseId } = useStoreActions((actions) => actions.issues);
  const { getAnomalyById } = useStoreState((state) => state.issues);
  const { getIssuesByCaseId } = useStoreState((state) => state.issues);
  const [anomaly, setAnomaly] = useState(null);
  const [issues, setIssues] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState();

  useEffect(() => {
    if (anomalyId) {
      fetchAnomalyById(anomalyId);
    }
  }, [anomalyId, fetchAnomalyById]);

  useEffect(() => {
    const currentAnomaly = getAnomalyById(anomalyId);

    if (currentAnomaly && !anomaly) {
      setAnomaly(currentAnomaly);

      // If the anomaly has a case ID fetch the issues
      if (hasIn('caseId', currentAnomaly)) {
        fetchIssuesByCaseId(currentAnomaly.caseId);
      }

      if (hasIn('components', currentAnomaly)) {
        setSelectedComponent(currentAnomaly.components[0]);
      }
    }
  }, [anomaly, getAnomalyById, anomalyId, fetchIssuesByCaseId]);

  useEffect(() => {
    if (anomaly && hasIn('caseId', anomaly)) {
      const currentIssues = getIssuesByCaseId(anomaly.caseId).filter((a) => a.id !== anomaly.id);
      if (currentIssues.length > 0 && !issues) {
        setIssues(currentIssues);
      }
    }
  }, [anomaly, getIssuesByCaseId, issues]);

  const { ready } = useTranslation(['analyze.troubleshooting'], {
    useSuspense: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [initialChart, setInitialChart] = useState(null);

  const handleCloseModal = useCallback(() => {
    setInitialChart(null);
    setShowModal(false);
  }, []);

  const handleOpenModal = useCallback((key) => {
    setInitialChart(key);
    setShowModal(true);
  }, []);

  const handleSelectComponent = useCallback(
    (component) => {
      setSelectedComponent(component);
    },
    [setSelectedComponent],
  );

  if (!ready || !anomaly) {
    return null;
  }

  return (
    <PageContainer i18nReady={ready}>
      <ScrollingContainer>
        <TroubleshootingContainer>
          <h3>Troubleshooting</h3>
          <TroubleshootingContent>
            <TroubleshootingSidebar
              selectComponent={handleSelectComponent}
              anomaly={anomaly}
              issues={issues}
            />
            <MetricsPanel component={selectedComponent} onChevronClick={handleOpenModal} />
          </TroubleshootingContent>
        </TroubleshootingContainer>
      </ScrollingContainer>
      <FullScreenModal showModal={showModal} closeModal={handleCloseModal}>
        <FullScreenChartModal component={selectedComponent} initialChart={initialChart} />
      </FullScreenModal>
    </PageContainer>
  );
};

export default AnalyzeTroubleshooting;
