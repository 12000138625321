import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';

import { Icon, Icons, FileIcons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useReportTemplateFactory } from '@ge/feat-reporting/hooks/use-report-template-factory';
import { globalColors } from '@ge/tokens';

import { CreateModes } from '../models/modes';

import { Sidebar } from './sidebar';
import { TemplateContainer } from './template';

const Container = styled.div`
  font-family: 'GE Inspira';
  display: flex;
  height: 100%;
  padding-top: 16px;
  position: relative;
`;

const LoaderContainer = styled.div`
  height: 100%;
  position: relative;
  width: 55%;
`;

const OffScreenContainer = styled.div`
  & * {
    font-family: 'GE Inspira' !important;
  }

  left: -1000px;
  position: absolute;
  top: 0;

  & .pdf-header {
    align-items: flex-start;
    color: ${({ theme }) => theme.createReport.headerIconColor};
    display: flex;
    left: 20px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    width: 100%;

    & svg {
      fill: ${({ theme }) => theme.createReport.headerBorderColor};
      height: 40px;
      width: 40px;
    }

    & h1 {
      padding-left: 13px;
    }
  }

  & .pdf-footer {
    border-top: 1px solid ${({ theme }) => theme.createReport.widget.borderColor};
    bottom: 0;
    color: ${globalColors.slate2};
    display: flex;
    justify-content: space-between;
    left: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    position: absolute;
    width: 100%;

    & h4 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const PreviewHeader = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: 55%;
`;

const PdfContainer = styled.iframe`
  height: 100%;
  width: 55%;
`;

const ClosePreviewButton = styled.button`
  cursor: pointer;
`;

const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  size: 16,
  icon: Icons.CLOSE,
  color: theme.createReport.widget.headerIconColor,
  viewbox: '0 0 20 20',
}))``;

const PdfIcon = styled(Icon).attrs(({ theme }) => ({
  size: 16,
  icon: FileIcons.PDF,
  color: theme.createReport.widget.headerIconColor,
  viewbox: '0 0 75 85',
}))`
  margin-right: 5px;
`;

export const CreateReport = ({ data, dataUpdatedAt }) => {
  const { t, ready } = useTranslation(['reporting.reports'], { useSuspense: false });

  // Local State //
  const [dataLastUpdatedAt, setDataLastUpdatedAt] = useState(dataUpdatedAt);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [centerTemplate, setCenterTemplate] = useState(false);
  const [generatedPdfBase64, setGeneratedPdfBase64] = useState(null);
  const pdfExportComponent = React.useRef(null);

  const {
    createMode,
    setCreateMode,
    reportState,
    initReportState,
    loadingWidgets,
    hiddenWidgets,
    attachments,
  } = useContext(ReportsContext);

  const { template: reportTemplate } = useReportTemplateFactory({
    data: reportState,
    isPlaceholder: createMode === CreateModes.EDIT,
  });
  const { getThemeJs } = useStoreState((state) => state.prefs);
  const lightTheme = getThemeJs('light');

  // Handlers //
  const handleCloseSidebar = useCallback(() => {
    setSidebarVisible(true);
    setCenterTemplate(true);
  }, [setSidebarVisible, setCenterTemplate]);

  const handleOpenSidebar = useCallback(() => {
    setSidebarVisible(false);
    setCenterTemplate(false);
  }, [setSidebarVisible, setCenterTemplate]);

  const handleClosePreview = useCallback(() => {
    setCreateMode(CreateModes.VIEW);
  }, [setCreateMode]);

  useEffect(() => {
    if (data && dataUpdatedAt) {
      const forceInit = dataUpdatedAt > dataLastUpdatedAt;
      initReportState(data, forceInit);
    }
  }, [data, dataUpdatedAt, dataLastUpdatedAt, initReportState]);

  useEffect(() => {
    setDataLastUpdatedAt(dataUpdatedAt);
  }, [dataUpdatedAt, setDataLastUpdatedAt]);

  useEffect(() => {
    if (createMode === CreateModes.EDIT) {
      setGeneratedPdfBase64(null);
    }
  }, [createMode, reportTemplate, setGeneratedPdfBase64]);

  useEffect(() => {
    if (reportState || hiddenWidgets) {
      setGeneratedPdfBase64(null);
    }
  }, [reportState, hiddenWidgets, attachments?.length, setGeneratedPdfBase64]);

  useEffect(() => {
    if (loadingWidgets.size > 0) {
      setGeneratedPdfBase64(null);
    }
  }, [loadingWidgets.size, setGeneratedPdfBase64]);

  useEffect(() => {
    if (createMode === CreateModes.PREVIEW && pdfExportComponent.current && !generatedPdfBase64) {
      // N.B. regarding the `setTimeout` call below:
      //
      // The purpose of this timeout is to allow for any widget animations, transitions, etc. to
      // be completed before the PDF renders. If the render is too early, it will be a snapshot
      // of the frame that was drawn at that moment. As such, the timeout value should be set to
      // the smallest reasonable value that allows motion to complete.
      //
      // If there's an approach that reliably disables all sources of motion without depending on
      // just waiting it out, feel free to implement it! At time of writing, the only alternatives
      // I can think of involve methodically identifying and disabling these sources manually.
      const tid = setTimeout(() => {
        drawDOM(pdfExportComponent.current, {
          template: (data) => `
          <div>
            <div class="pdf-header">
              <svg viewbox="0 0 20 20">
                <path d="${Icons.GE_ICON}" />
              </svg>
              <h1>${reportState?.name ?? ''}</h1>
            </div>
            <div class="pdf-footer">
              <h4>${reportState?.name ?? ''}</h4>
              <h4>${t('report_page', 'Page')} ${data.pageNum}/${data.totalPages}</h4>
            </div>
          </div>
        `,
          paperSize: 'Letter',
          margin: 30,
          scale: 0.55,
        })
          .then((group) => exportPDF(group))
          .then((dataUri) => setGeneratedPdfBase64(dataUri));
      }, 1000);

      return () => {
        clearTimeout(tid);
      };
    }
  }, [createMode, pdfExportComponent, generatedPdfBase64, reportState, t]);

  if (!(data && reportState && reportTemplate) || data.id !== reportState.id) {
    return null;
  }

  return (
    <Container>
      <Sidebar
        visible={sidebarVisible}
        onClose={handleCloseSidebar}
        onOpen={handleOpenSidebar}
        generatedPdfBase64={generatedPdfBase64}
      />
      {createMode !== CreateModes.PREVIEW && (
        <TemplateContainer centerTemplate={centerTemplate}>{reportTemplate}</TemplateContainer>
      )}
      {createMode === CreateModes.PREVIEW && (
        <>
          <ThemeProvider theme={lightTheme}>
            <OffScreenContainer>
              {loadingWidgets.size === 0 && (
                <TemplateContainer ref={pdfExportComponent} centerTemplate={centerTemplate}>
                  {reportTemplate}
                </TemplateContainer>
              )}
            </OffScreenContainer>
          </ThemeProvider>
          <PreviewContainer>
            <PreviewHeader>
              <h3>
                <PdfIcon />
                {t('report_preview', 'Report Preview')}
              </h3>
              <ClosePreviewButton onClick={handleClosePreview}>
                <CloseIcon />
              </ClosePreviewButton>
            </PreviewHeader>
            {generatedPdfBase64 && (
              <PdfContainer src={`${generatedPdfBase64}#toolbar=1&view=FitH`}></PdfContainer>
            )}
            {/* Adding 'ready' for translation loading fix here. 
                Three loading things needs to be in sink here if this fix does not work
                1. Translation loader
                2. Component level api loader
                3. generatedPdfBase64 var
              */}
            {!generatedPdfBase64 && ready && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
          </PreviewContainer>
        </>
      )}
    </Container>
  );
};

CreateReport.propTypes = {
  data: PropTypes.instanceOf(Object),
  dataUpdatedAt: PropTypes.number,
};
