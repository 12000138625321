import classNames from 'classnames';
import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Selecto from 'react-selecto';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { useBulkScheduleTask } from '@ge/feat-manage/data-hooks/use-bulk-schedule-task';
import { TaskStatus } from '@ge/models';
import {
  DataLoaderType,
  Capability,
  PermissionScope,
  DateTimeFormats,
  crewDisabledTypes,
} from '@ge/models/constants';
import { RescheduleTasksDialog } from '@ge/shared/components/actions-reschedule-tasks-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { debounce } from '@ge/shared/util/general';
import { elevations } from '@ge/tokens/elevations';

import { PlanningContext, DragItemTypes } from '../../../context/planning-provider';
import { sortSchedule } from '../../../data-hooks/use-task-schedule';
import { CalendarDateLine } from '../calendar-date-line';
import { LaneGroupBy } from '../lane-group-by';

import { CalendarMonthCard } from './calendar-month-card';
import {
  getCrewId,
  buildNewLane,
  getHighlitedLane,
  setCellHoverClass,
  getTasksAsArray,
  //WIP
  // eslint-disable-next-line no-unused-vars
  isCrewAvaiable,
} from './month-util';

const Lane = styled.div`
  min-height: 110px;
  width: ${({ width }) => width}px;
  z-index: ${elevations.P2};
  background: ${({ theme }) => theme.manage.calendar.laneEvenBackground};
  position: relative;
  display: flex;
  flex-direction: row;
`;

const DayContainer = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  justify-content: center;
  border-right: solid 1px transparent;
  position: relative;
  &.disabled {
    background-color: ${({ theme }) => theme.manage.timeline.dragDrop.cell.disabled};
  }
  &.notapplied {
    background: repeating-linear-gradient(-40deg, #222, #222 4px, #4a4949 4px, #4a4949 5px);
  }
  &.hovered {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      border: 1px solid ${({ theme }) => theme.manage.timeline.dragDrop.cell.highlight.border};
      background-color: ${({ theme }) => theme.manage.timeline.dragDrop.cell.highlight.background};
      box-shadow: ${({ theme }) => theme.manage.timeline.dragDrop.cell.highlight.boxShadow};
    }
    &:after {
      right: 0;
      left: auto;
    }
  }
`;

const Week = styled.div`
  border-right: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  display: flex;
  z-index: 0;
  &:last-child {
    border-right: 0;
  }
  > div {
    flex: 1;
    display: block;
    &:last-child {
      border-right: 0;
    }
  }
`;

const Container = styled.div`
  ${Lane} {
    &:nth-child(odd) {
      background: ${({ theme }) => theme.manage.calendar.laneEvenBackground};
    }
    &:nth-child(even) {
      background: ${({ theme }) => theme.manage.calendar.laneOddBackground};
    }
    &.isDragover {
      background-color: ${({ theme }) => theme.manage.timeline.dragDrop.lane.background};
      box-shadow: ${({ theme }) => theme.manage.timeline.dragDrop.lane.boxShadow};
      ${DayContainer} {
        box-shadow: inset 0 0 1px ${({ theme }) => theme.manage.timeline.dragDrop.cell.boxShadow};
      }
    }
  }
  // styles for selecto drag box
  > .selecto-selection {
    background: ${({ theme }) => theme.lasso.background} !important;
    border: 1px solid ${({ theme }) => theme.lasso.borderColor};
  }
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }
  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
  width: ${({ width }) => width}px;
  padding-right: 20px;
`;

const TaskGroupContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 110px;
`;

const StyledDataLoader = styled(DataLoader).attrs(({ theme }) => ({
  noDataIconColor: theme.manage.timeline.noDataIconColor,
  type: DataLoaderType.GRID,
}))``;

export const CalendarMonthView = ({
  scheduleData,
  unassignedData,
  refetch,
  isLoading,
  isError,
  parentRef,
  workers,
}) => {
  const {
    planningState: {
      dayWidth,
      sidebarWidth,
      range,
      timelineSchema,
      groupFilter: {
        L1: { value: groupByL1 },
        L2: { value: groupByL2 },
      },
      groupFilter,
      handleDragOver,
      dragItem,
      dragItemType,
      setTempNewLane,
      tempNewLane,
    },
    planningState,
    planningState: { dragItemBacklogType },
  } = useContext(PlanningContext);
  const { t } = useTranslation(['general', 'manage.planning'], { useSuspense: false });
  const bundleTasks = planningState.bundleTasks ? true : false;

  const { showTaskDetails } = useContext(EntityDetailsContext);
  const [dialogTaskReschedule, setDialogTaskReschedule] = useState(false);
  const [shiftCount, setShiftCount] = useState(0);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [hovered, setHovered] = useState(null);
  const [dropDate, setDropDate] = useState(null);
  const [calendarData, setCalendarData] = useState(scheduleData);
  const [highlightedLane, setHighlightedLane] = useState(null);
  const [crewHover, setCrewHover] = useState(null);
  const [tasktobeBundled, setTasktobeBundled] = useState([]);
  const [crewLaneInfo, setCrewLaneInfo] = useState(null);

  const laneWidth = range.daysInMonth * dayWidth + sidebarWidth + range.daysInMonth;
  const selectoRef = useRef();

  const rowVirtualizer = useVirtual({
    size: calendarData?.length,
    parentRef,
    estimateSize: React.useCallback(() => 114, []),
    overscan: 1,
  });

  const virtualizerItems = rowVirtualizer.virtualItems;
  const paddingTop = virtualizerItems.length > 0 ? virtualizerItems[0].start : 0;
  const paddingBottom =
    virtualizerItems.length > 0
      ? rowVirtualizer.totalSize - virtualizerItems[virtualizerItems.length - 1].end
      : 0;

  const handleOnDrop = (e, task, dropDate, dragItemType, crewDetails, allTasks) => {
    setCrewLaneInfo(crewDetails);
    // get the selected card elements
    let selectedCards = selectoRef.current.getSelectedTargets();
    let tasks = [];

    // clear selected targets when dragging from backlog and set selectedCards to empty array
    if (dragItemType === DragItemTypes.BACKLOG) {
      selectoRef?.current?.setSelectedTargets([]);
      setSelectedTasks([]);
      if (selectedCards.length > 0) {
        selectedCards.forEach((el) => {
          el.classList.remove('selected');
        });
      }
      selectedCards = [];
    }

    // check if the user is dragging a card without using lasso
    if (selectedCards.length === 0) {
      tasks = task;
    } else {
      // loop the cards to get the task data from scheduleData
      selectedCards.forEach((el) => {
        const { laneindex, dayindex, secondaryindex } = el.dataset;
        if (groupFilter.L2.value === null) {
          // laneindex = -1 for drag and drap from unassigned data
          if (laneindex >= 0) {
            tasks.push(...calendarData[laneindex].data[dayindex].tasks);
          } else {
            tasks.push(...unassignedData[dayindex].tasks);
          }
        } else {
          //If secondary filter get the tasks from nested secondary array.
          if (laneindex >= 0) {
            tasks.push(
              ...calendarData[laneindex].secondary.schedule[secondaryindex].data[dayindex].tasks,
            );
          } else {
            tasks.push(...unassignedData[dayindex].tasks);
          }
        }
      });
    }

    const { scheduleDate, dueDate, status, site } = task[0] || {};

    let count = 0;

    // if task status is scheduled use scheduled date if not try to use due date
    const countDate = status === TaskStatus.SCHEDULED ? scheduleDate : dueDate || null;

    if (countDate) {
      // Start date (without time) in site timezone
      const startDate = dayjs(countDate).tz(site?.timezone).format('YYYY-MM-DD');
      // End date (without time) in local timezone to represent drop date
      const endDate = dayjs(dayjs(dropDate).format('YYYY-MM-DD'));

      count = startDate && endDate ? endDate.diff(startDate, 'day') : 0;
    }
    let scheduledTasks = [];
    if (bundleTasks) {
      task.map((task) => {
        allTasks?.forEach((eachtask) => {
          if (eachtask.asset.id === task.asset.id) {
            scheduledTasks.push(eachtask);
          }
        });
      });
    }
    //pass the day shift count to the dialog
    setShiftCount(count);
    setDropDate(count === 0 ? dropDate : null);
    setHovered(null);
    setSelectedTasks(tasks);
    setDialogTaskReschedule(true);
    setCrewHover(null);
    setTasktobeBundled(scheduledTasks);
  };

  // using debounce to prevent the hover from firing on every dragEnter.
  const hoverHandler = debounce((id) => setHovered(id), 100);

  // calling nested hover handler with debounce once to prevent calling one for every drag enter.
  const onDragEnter = (id) => hoverHandler(id);

  const checkCrewAvailablity = (index, date, lane, laneSecondary) => {
    return groupFilter.L1.value === 'crew' || groupFilter.L2.value === 'crew'
      ? isCrewAvaiable(
          index,
          dayjs(date).format(DateTimeFormats.CREW_TIMING),
          groupFilter.L1.value === 'crew' ? lane?.crewStartDate : laneSecondary?.crewStartDate,
          groupFilter.L1.value === 'crew' ? lane?.crewEndDate : laneSecondary?.crewEndDate,
          groupFilter.L1.value === 'crew' ? lane?.crewApplyOn : laneSecondary?.crewApplyOn,
        )
      : { disabled: false, type: crewDisabledTypes.CrewOther };
  };

  const buildDayGrid = (index, lane, laneSecondary, rowIndex) =>
    Object.keys(timelineSchema).map((key) => {
      const { days } = timelineSchema[key];
      let crewLaneData = laneSecondary?.groupByCrew
        ? laneSecondary?.groupByCrew?.crew
        : lane?.groupByCrew?.crew;

      // checking for array to provide to bulk reschedule modal
      const tasks = getTasksAsArray(dragItem);

      return (
        <Week key={key}>
          {days.map((day) => {
            const id = `${day.day}-${index}`;

            const { disabled, type } = checkCrewAvailablity(index, day.date, lane, laneSecondary);

            return (
              <DayContainer
                className={classNames({
                  hovered:
                    crewHover && dragItem && hovered === id && !disabled
                      ? crewHover
                      : dragItem &&
                        hovered === id &&
                        !disabled &&
                        highlightedLane === `nested-parent-${rowIndex}`
                      ? true
                      : dragItem &&
                        hovered === id &&
                        !disabled &&
                        setCellHoverClass(lane, laneSecondary, dragItem, groupByL1, groupByL2),
                  disabled: disabled && type == crewDisabledTypes.CrewNotAvailable,
                  notapplied: !disabled && type == crewDisabledTypes.CrewNotApplyOn,
                })}
                width={dayWidth}
                key={id}
                onDrop={(e) =>
                  (highlightedLane === `nested-parent-${rowIndex}` &&
                    hovered === id &&
                    !disabled) ||
                  (crewHover && !disabled) ||
                  (hovered === id && index === highlightedLane && !disabled)
                    ? handleOnDrop(e, tasks, day.date, dragItemType, crewLaneData)
                    : null
                }
                onDragEnter={() => onDragEnter(id)}
                onDragOver={(e) => (!disabled ? handleDragOver(e) : false)}
              />
            );
          })}
        </Week>
      );
    });
  const { fetchTasks } = useStoreActions((store) => store.tasks);
  const { sitesForView } = useStoreState((state) => state.sites);

  const siteIds = useMemo(() => sitesForView.map((s) => s.id), [sitesForView]);

  const { bulkSchedule } = useBulkScheduleTask({
    onSuccess: () => {
      fetchTasks({ start: range.start, end: range.end, siteIds });
      let selectedCards = selectoRef?.current.getSelectedTargets();
      if (selectedCards.length > 0) {
        selectedCards.forEach((el) => {
          el.classList.remove('selected');
        });
      }
      selectoRef?.current?.setSelectedTargets([]);
      setSelectedTasks([]);
      setTempNewLane(null);
      setDialogTaskReschedule(false);
      setShiftCount(0);
    },
    onError: (e) => console.error('Error scheduling', e),
  });

  const handleSaveAll = (data) => bulkSchedule(data);

  const taskCardClick = (e, id) => {
    // if the user is not holding down shift show task details
    if (e.shiftKey) {
      const el = e.currentTarget;
      const selectedTargets = selectoRef.current.getSelectedTargets();
      const index = selectedTargets.indexOf(el);

      // add remove the selected card to the targets list and add/remove selected class
      if (index > -1) {
        selectedTargets.splice(index, 1);
        el.classList.remove('selected');
        selectoRef.current.setSelectedTargets([...selectedTargets]);
      } else {
        el.classList.add('selected');
        selectoRef.current.setSelectedTargets([...selectedTargets, el]);
      }
    } else id && showTaskDetails(id);
  };

  // if no longer dragging and not rescheduling remove the temporary lane
  useEffect(() => {
    if (!dragItem && tempNewLane && !dialogTaskReschedule && !highlightedLane) {
      setTempNewLane(null);
    }
  }, [tempNewLane, dragItem, dialogTaskReschedule, highlightedLane, setTempNewLane]);

  // update schedule data to include temporary new lane and sort.
  useEffect(() => {
    setCalendarData(tempNewLane ? sortSchedule([tempNewLane, ...scheduleData]) : scheduleData);
  }, [setCalendarData, scheduleData, tempNewLane]);

  //On backlog drag item check lanes and add new placeholder if not present
  useEffect(() => {
    if (!dragItem || dragItemType === DragItemTypes.TECH) {
      setHighlightedLane(null);
      setCrewHover(null);
      return;
    }

    //to highlight all the rows if crew is selected for L!
    if (groupByL1 === 'crew') {
      setCrewHover(true);
      return;
    }

    // find highlited lane
    const laneItem = getHighlitedLane(dragItem, groupByL1, groupByL2, calendarData);
    //set the highlighted lane
    if (laneItem.laneKey) {
      if (!Array.isArray(dragItem)) {
        const idx = parseInt(laneItem.laneKey.split('-')[1]);
        rowVirtualizer.scrollToIndex(idx, { align: 'start' });
      }
      setHighlightedLane(laneItem.laneKey);
    }

    //if drag item cannot be found in current lanes build new one.
    if (!laneItem.laneData && !tempNewLane) {
      setTempNewLane(buildNewLane(dragItem, groupByL1, groupByL2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragItem, calendarData, groupByL1, groupByL2, setTempNewLane, tempNewLane]);

  const { isAuthorized } = useAuth();

  // Permissions check for dragging cards.
  const isDraggable = (tasks) => {
    const task = getTasksAsArray(tasks);
    const siteId = task[0]?.site?.id;

    return isAuthorized({
      capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
      siteIds: [siteId],
    });
  };
  return (
    <>
      <Container width={laneWidth} paddingTop={paddingTop} paddingBottom={paddingBottom}>
        <StyledDataLoader
          noData={!calendarData?.length && !unassignedData?.length}
          renderCondition
          onRetry={isError ? refetch : null}
          isLoading={isLoading}
          type={DataLoaderType.GRID}
          margin="100px auto"
          noDataTitle={
            isError
              ? t('no_data_title', 'No Data Available')
              : t('manage.planning:no_tasks', 'No Tasks on Your Plan')
          }
        >
          <Selecto
            ref={selectoRef}
            container={document.body}
            selectableTargets={['.lasso-card']}
            selectByClick={false}
            selectFromInside={false}
            continueSelect={false}
            toggleContinueSelect={'shift'}
            keyContainer={window}
            hitRate={10}
            onSelect={(e) => {
              e.added.forEach((el) => el.classList.add('selected'));
              e.removed.forEach((el) => el.classList.remove('selected'));
            }}
          />

          {/* unassigned row currently in use for crews */}
          {groupFilter.L1.value && unassignedData && (
            <Lane
              key={`lane-unassigned`}
              width={laneWidth}
              className={classNames({
                isDragover: dragItem,
              })}
            >
              <CalendarDateLine />
              <LaneGroupBy unassigned sidebarWidth={sidebarWidth} workers={workers} />
              <TaskGroupContainer>
                {buildDayGrid('lane-unassigned')}

                {unassignedData?.map((day, index) => (
                  <CalendarMonthCard
                    date={day.date}
                    tasks={day.tasks}
                    key={`${day.date}-unassigned`}
                    dayWidth={dayWidth}
                    sidebarWidth={sidebarWidth}
                    duration={day.duration}
                    laneIndex={-1}
                    itemIndex={index}
                    onClick={taskCardClick}
                    onDrop={(e) => {
                      handleOnDrop(
                        e,
                        getTasksAsArray(dragItem),
                        day.date,
                        null,
                        getCrewId(day.tasks),
                        day.tasks,
                      );
                    }}
                    draggable={isDraggable(day.tasks)}
                  />
                ))}
              </TaskGroupContainer>
            </Lane>
          )}
          {groupFilter.L1.value &&
            calendarData &&
            virtualizerItems.map(({ index, measureRef }) => {
              if (calendarData[index]?.secondary?.schedule?.length) {
                return (
                  <div key={`nested-parent-${index}`} ref={measureRef}>
                    {calendarData[index]?.secondary.schedule.map(
                      (laneSecondary, indexSecondary) => (
                        <Lane
                          key={`nested-${index}-${indexSecondary}`}
                          width={laneWidth}
                          className={classNames({
                            isDragover: crewHover
                              ? crewHover
                              : highlightedLane === `nested-parent-${index}`
                              ? true
                              : highlightedLane === `nested-${index}-${indexSecondary}`,
                            even: index !== -1 && index % 2 === 0,
                            odd: index !== -1 && index % 2 === 1,
                          })}
                        >
                          <CalendarDateLine />
                          <LaneGroupBy
                            sidebarWidth={sidebarWidth}
                            groupByL1={calendarData[index]}
                            groupByL2={laneSecondary}
                            showGroupByL1={indexSecondary === 0}
                            workers={workers}
                          />
                          <TaskGroupContainer>
                            {buildDayGrid(
                              `nested-${index}-${indexSecondary}`,
                              calendarData[index],
                              laneSecondary,
                              index,
                            )}
                            {laneSecondary.data.map((day, itemIndex) => (
                              <CalendarMonthCard
                                date={day.date}
                                tasks={day.tasks}
                                key={`${day.date}-${index}-${indexSecondary}`}
                                dayWidth={dayWidth}
                                sidebarWidth={sidebarWidth}
                                duration={day.duration}
                                laneIndex={index}
                                secondaryIndex={indexSecondary}
                                itemIndex={itemIndex}
                                onDrop={(e) => {
                                  const { disabled } = checkCrewAvailablity(
                                    index,
                                    day.date,
                                    calendarData[index],
                                    laneSecondary,
                                  );

                                  if (!disabled) {
                                    handleOnDrop(
                                      e,
                                      getTasksAsArray(dragItem),
                                      day.date,
                                      null,
                                      getCrewId(day.tasks),
                                      day.tasks,
                                    );
                                  }
                                }}
                                onClick={taskCardClick}
                                draggable={isDraggable(day.tasks)}
                              />
                            ))}
                          </TaskGroupContainer>
                        </Lane>
                      ),
                    )}
                  </div>
                );
              } else {
                return (
                  <Lane
                    key={`lane-${index}`}
                    width={laneWidth}
                    className={classNames({
                      isDragover: crewHover ? crewHover : highlightedLane === `lane-${index}`,
                      even: index !== -1 && index % 2 === 0,
                      odd: index !== -1 && index % 2 === 1,
                    })}
                    ref={measureRef}
                  >
                    <CalendarDateLine />
                    <LaneGroupBy
                      sidebarWidth={sidebarWidth}
                      groupByL1={calendarData[index]}
                      groupFilter={groupFilter}
                      showGroupByL1
                      workers={workers}
                    />
                    <TaskGroupContainer>
                      {buildDayGrid(`lane-${index}`, calendarData[index])}
                      {calendarData[index]?.data?.map((day, itemIndex) => (
                        <CalendarMonthCard
                          date={day.date}
                          tasks={day.tasks}
                          key={`${day.date}-${index}`}
                          dayWidth={dayWidth}
                          sidebarWidth={sidebarWidth}
                          duration={day.duration}
                          laneIndex={index}
                          itemIndex={itemIndex}
                          onDrop={(e) => {
                            const { disabled } = checkCrewAvailablity(
                              index,
                              day.date,
                              calendarData[index],
                              null,
                            );

                            if (!disabled) {
                              handleOnDrop(
                                e,
                                getTasksAsArray(dragItem),
                                day.date,
                                null,
                                getCrewId(day.tasks),
                                day.tasks,
                              );
                            }
                          }}
                          onClick={taskCardClick}
                          draggable={isDraggable(day.tasks)}
                        />
                      ))}
                    </TaskGroupContainer>
                  </Lane>
                );
              }
            })}
        </StyledDataLoader>
      </Container>
      {dialogTaskReschedule && (
        <RescheduleTasksDialog
          tasks={selectedTasks}
          onClose={() => {
            setDialogTaskReschedule(false);
            setSelectedTasks([]);
            setTempNewLane(null);
            setShiftCount(0);
          }}
          onSaveAll={handleSaveAll}
          shiftCount={shiftCount}
          dropDate={dropDate}
          crewLaneInfo={crewLaneInfo}
          tasktobeBundled={tasktobeBundled}
          bundleTasks={bundleTasks}
          dragItemBacklogType={dragItemBacklogType}
        />
      )}
    </>
  );
};

CalendarMonthView.propTypes = {
  scheduleData: PropTypes.instanceOf(Array),
  unassignedData: PropTypes.instanceOf(Array),
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  parentRef: PropTypes.instanceOf(Object),
  workers: PropTypes.instanceOf(Object),
};

CalendarMonthView.defaultProps = {
  scheduleData: null,
  unassignedData: null,
  refetch: () => {},
  isLoading: false,
  isError: false,
  workers: null,
};
