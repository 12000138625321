import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Recurrence } from '../../../task-fields/recrurrence';
import { FormRow, FormSection, StyledCollapsiblePanel } from '../../../task-template-shared';

export const CreateRecurrence = ({ collapsed, metadata, templateMode }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <StyledCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{t('section_header.recurrence', 'Recurrence')}</h4>}
      plusIcon
    >
      <FormSection>
        <FormRow>
          {!metadata?.recurrence.hidden && <Recurrence metadata={metadata.recurrence.create} templateMode={templateMode} />}
        </FormRow>
      </FormSection>
    </StyledCollapsiblePanel>
  );
};

CreateRecurrence.propTypes = {
  collapsed: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
};

CreateRecurrence.defaultProps = {
  collapsed: true,
};
