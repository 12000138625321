import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Text } from '@ge/components/typography';
import { globalColors, typography } from '@ge/tokens';

const NoData = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataIcon = styled(Icon).attrs(() => ({
  icon: Icons.DATA_NETCOMM,
  size: 14,
  color: globalColors.grey5,
}))`
  margin: 0 5px 2px 0;
`;

const NoDataTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
}))`
  color: ${({ theme }) => theme.createReport.widget.headerLabelColor};
`;

export const NoDataAvailable = () => {
  const { t } = useTranslation(['reporting.widgets']);

  return (
    <NoData>
      <NoDataIcon />
      <NoDataTitle>{t('no_data_available', 'No Data Available')}</NoDataTitle>
    </NoData>
  );
};
