import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreatePartsConsumed } from './create-parts-consumed';
import { EditPartsConsumed } from './edit-parts-consumed';
import { ViewPartsConsumed } from './view-parts-consumed';

export const PartsConsumedSection = ({ section, task, templateMode, type }) => {
  const { metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return <CreatePartsConsumed metadata={metadata} type={type} />;
      case TaskTemplateModes.EDIT:
        return <EditPartsConsumed metadata={metadata} task={task} />;
      case TaskTemplateModes.VIEW:
        return <ViewPartsConsumed metadata={metadata} task={task} />;
      default:
        return null;
    }
  }
};

PartsConsumedSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  type: PropTypes.String,
};

PartsConsumedSection.defaultProps = {
  task: {},
};
