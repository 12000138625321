import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { killEventPropagation } from '@ge/shared/util';

import { ReportWidgetTable } from '../../tables/report-widget-table';
import { siteRosterColumnDefs, SiteRosterColumns } from '../site-roster-widget/site-roster-cols';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const TechBadge = styled.span`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.createReport.widget.plannedWork.techBadge.background};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.createReport.widget.plannedWork.techBadge.border};
  text-transform: uppercase;
  letter-spacing: -0.5px;
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  padding: 2px 5px;
  color: ${({ theme }) => theme.createReport.widget.plannedWork.techBadge.textColor};
`;

const LeadBadge = styled(Icon).attrs((props) => ({
  size: 8,
  icon: Icons.WORKER,
  color: props.theme.createReport.widget.plannedWork.techBadge.textColor,
}))`
  margin-right: 2px;
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.plannedWork.includeTextColor};
`;

export const SiteRosterWidget = ({
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
}) => {
  const { t } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(data.map(({ id }) => id));

  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom cells for specific columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case SiteRosterColumns.SELECTED:
          return <IncludeHeader>{t('planned_work.columns.include')}</IncludeHeader>;
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case SiteRosterColumns.SELECTED:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case SiteRosterColumns.INITIALS: {
          const { initials, title } = cellValue;
          // TODO: This is temporary because the back-end is returning
          // a key here, not a localized string. This will need to change
          // to point to a reliable key after the string change is complete!
          const isLead = title === 'Site_Lead';

          return initials ? (
            <TechBadge>
              {isLead && <LeadBadge />}
              {initials}
            </TechBadge>
          ) : (
            <></>
          );
        }
        default:
          return cellValue;
      }
    },
    [rowsSelected, toggleCheckedRow],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback((worker) => {
    const assets = worker.crews?.flatMap(({ assets }) => assets?.map((value) => value));
    const uniqueAssets = assets?.filter((value, index, self) => {
      if (value != undefined) {
        return self.indexOf(value) === index;
      }
    });
    return {
      [SiteRosterColumns.SELECTED]: worker.id,
      [SiteRosterColumns.INITIALS]: worker,
      [SiteRosterColumns.WORKER]: `${worker.firstName} ${worker.lastName}`,
      [SiteRosterColumns.CREW]: worker.crews?.map(({ name }) => name).join(', '),
      [SiteRosterColumns.ASSET_NAME]: uniqueAssets?.join(', '),
      [SiteRosterColumns.JOB_TITLE]: worker.title, // TODO: This is currently a "key" value, but needs to be a localized string
      [SiteRosterColumns.PHONE_NUMBER]: worker.phoneNumber,
      [SiteRosterColumns.EMAIL]: worker.email,
      [SiteRosterColumns.AVAILABILITY]: worker.status, // TODO: Validate mapping
    };
  }, []);

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: siteRosterColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  return (
    <Container>
      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty="id"
        isEditing={isEditing}
        variableHeight={true}
      />
    </Container>
  );
};

SiteRosterWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.number),
};
