import * as request from './api';

export const fetchCustomers = () => request.get('/customers');

/**
 * Dynamically load the customer mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () => {
  import('./__mocks__/customer/customers.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
};
