import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { DataLoaderType, TaskTemplateModes } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors/colors';

const DataLoaderContainer = styled.div`
  img {
    height: 25vh;
  }
`;

const PartsRow = styled.div`
  margin: 20px 0 10px;
  .edit-parts & {
    margin-top: 0;
  }
`;

const PartInputWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const PartInput = styled(Input)`
  ${({ errors, fieldArray }) => {
    const { fieldName, errorIndex, errorName } = fieldArray ?? {};
    if (fieldArray)
      return errors[fieldName]?.[errorIndex]?.[errorName]
        ? `border-color: ${StatusColor.DANGER}`
        : '';
  }};
`;

const PartNumberInput = styled(PartInput)`
  width: 80px;
  border: ${(props) =>
    props.workStandards?.parts?.length &&
    props.defaultValue === props.val &&
    props.val !== '' &&
    '2px solid #2b5c6b'};
  border-radius: ${(props) => props.workStandards?.parts?.length && '3px'};
`;

const PartNameInput = styled(PartInput)`
  width: 290px;
  border: ${(props) =>
    props.workStandards?.parts?.length &&
    props.defaultValue === props.val &&
    props.val !== '' &&
    '2px solid #2b5c6b'};
  border-radius: ${(props) => props.workStandards?.parts?.length && '3px'};
`;

const PartQuantityInput = styled(PartInput)`
  width: 80px;
  height: 25px;
  border: ${(props) =>
    props.workStandards?.parts?.length &&
    props.defaultValue === Number(props.val) &&
    '2px solid #2b5c6b'};
  border-radius: ${(props) => props.workStandards?.parts?.length && '3px'};
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-bottom: 8px;
`;

const AddPartButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  margin: 0 4px 15px auto;
  text-transform: uppercase;
`;

const AddPartIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;
const PartFieldConfig = { shouldDirty: true, shouldTouch: true, shouldValidate: true };

export const Parts = ({
  fieldName,
  metadata,
  workStandardsData,
  setWorkStandardsData,
  templateMode,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const [workStandardsCopy, setWorkStandardsCopy] = useState([]);
  const { isWorkStand, setIsWorkStand } = useContext(EntityDetailsContext);

  const { readOnly } = metadata;
  const { control, errors, register, setValue, watch } = useFormContext();
  const watchParts = watch(fieldName);

  const { workStandards } = useStoreState((store) => store.tasks);
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
  });

  const defaultParts = useMemo(
    () => ({
      number: '',
      name: '',
      quantity: '',
    }),
    [],
  );

  useEffect(() => {
    if (isWorkStand && workStandardsData?.parts?.length) {
      setWorkStandardsCopy(workStandardsData?.parts);
    } else if (workStandardsData?.parts === null) {
      setWorkStandardsCopy([]);
    }
  }, [workStandardsData, workStandardsData?.parts, isWorkStand]);

  useEffect(() => {
    if (templateMode === TaskTemplateModes.CREATE && workStandards?.parts?.length) {
      setWorkStandardsCopy(workStandards?.parts);
    } else if (
      workStandards === null ||
      workStandards?.parts === null ||
      workStandards?.length === 0
    ) {
      setWorkStandardsCopy([]);
    }
  }, [workStandards, workStandards?.parts, templateMode]);

  const handleAddWorkStandardPart = () =>
    setWorkStandardsCopy([...workStandardsCopy, { ...defaultParts }]);

  const handleRemoveWorkStandardPart = useCallback(
    (indexToRemove) => {
      const updatedParts = workStandardsCopy?.filter((_part, index) => index !== indexToRemove);
      setWorkStandardsCopy(updatedParts);
      if (workStandardsData && updatedParts.length === 0) {
        const removedParts = {
          ...workStandardsData,
          parts: null,
        };
        setWorkStandardsData(removedParts);
      }
      setIsWorkStand(false);
      remove(indexToRemove);
    },
    [workStandardsCopy, setWorkStandardsData, workStandardsData, remove, setIsWorkStand],
  );

  const handleRemove = useCallback(
    (index) => {
      // Only clear field array values if there is one row, otherwise delete row
      if (watchParts.length === 1) {
        setValue(`${fieldName}[${index}]`, { ...defaultParts }, { shouldDirty: true });
      } else remove(index);
      setIsWorkStand(false);
    },
    [defaultParts, fieldName, remove, setValue, watchParts, setIsWorkStand],
  );

  const getPartNumber = useCallback(() => {
    if (workStandardsData?.parts?.length > 0) {
      setValue(fieldName, workStandardsData.parts, PartFieldConfig);
    }
  }, [workStandardsData, setValue, fieldName]);

  const getNumber = useCallback(
    (part, index) => {
      setValue(`${fieldName}[${index}].number`, part?.partNumber, PartFieldConfig);
      setIsWorkStand(false);
    },
    [setValue, fieldName, setIsWorkStand],
  );

  const getName = useCallback(
    (part, index) => {
      setValue(`${fieldName}[${index}].name`, part?.partDescription, PartFieldConfig);
      setIsWorkStand(false);
    },
    [setValue, fieldName, setIsWorkStand],
  );

  const getQuantity = useCallback(
    (part, index) => {
      setValue(`${fieldName}[${index}].quantity`, part?.quantitySuggested, PartFieldConfig);
      setIsWorkStand(false);
    },
    [setValue, fieldName, setIsWorkStand],
  );

  useEffect(() => {
    if (workStandardsData?.parts?.length) {
      getPartNumber();
    }
  }, [workStandardsData, getPartNumber]);

  if (workStandards?.isLoading)
    return (
      <DataLoaderContainer>
        <DataLoader type={DataLoaderType.GRID} isLoading={workStandards?.isLoading} />
      </DataLoaderContainer>
    );

  return (
    <PartsRow>
      <div>
        {workStandardsCopy?.length
          ? workStandardsCopy.map((part, index) => {
              return (
                <PartInputWrapper className="parts" key={part?.partNumber}>
                  <PartNumberInput
                    defaultValue={isWorkStand ? getNumber(part, index) : part?.partNumber}
                    disabled={readOnly}
                    errors={errors}
                    fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'number' }}
                    label={index === 0 ? t('form.part_number', 'Part #') : ' '}
                    name={`${fieldName}[${index}].number`}
                    val={watch(`${fieldName}[${index}].number`)}
                    placeholder={t('form.part_number', 'Part #')}
                    workStandards={workStandards}
                    ref={register({
                      validate: {
                        required: (value) => {
                          return !value.trim() &&
                            (watchParts[index]?.name || watchParts[index]?.quantity)
                            ? false
                            : true;
                        },
                      },
                    })}
                    type="text"
                  />
                  <PartNameInput
                    defaultValue={isWorkStand ? getName(part, index) : part?.partDescription}
                    disabled={readOnly}
                    errors={errors}
                    fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'name' }}
                    label={index === 0 ? t('form.part_name', 'Part Name') : ' '}
                    name={`${fieldName}[${index}].name`}
                    val={watch(`${fieldName}[${index}].name`)}
                    placeholder={t('form.part_name', 'Part Name')}
                    workStandards={workStandards}
                    ref={register({
                      validate: {
                        required: (value) => {
                          return !value.trim() &&
                            (watchParts[index]?.number || watchParts[index]?.quantity)
                            ? false
                            : true;
                        },
                      },
                    })}
                    type="text"
                  />
                  <PartQuantityInput
                    defaultValue={isWorkStand ? getQuantity(part, index) : part?.quantitySuggested}
                    disabled={readOnly}
                    errors={errors}
                    fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'quantity' }}
                    label={index === 0 ? t('form.quantity', 'Quantity') : ' '}
                    min="0"
                    max="9999"
                    name={`${fieldName}[${index}].quantity`}
                    val={watch(`${fieldName}[${index}].quantity`)}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value && Math.floor(value) !== parseFloat(value)) {
                        if (value.toString().split('.')[1].length > 4) {
                          setValue(`${fieldName}[${index}].quantity`, parseFloat(value).toFixed(4));
                        }
                      }
                      if (value < 0) {
                        setValue(`${fieldName}[${index}].quantity`, 0);
                      } else if (value > 9999.9999) {
                        setValue(`${fieldName}[${index}].quantity`, 9999.9999);
                      }
                    }}
                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                    placeholder={t('form.qty', 'Qty')}
                    workStandards={workStandards}
                    ref={register({
                      validate: {
                        required: (value) => {
                          return !value && (watchParts[index]?.number || watchParts[index]?.name)
                            ? false
                            : true;
                        },
                        //0.0001 - 9999.9999 min and max for quantity
                        validate: (value) => {
                          if (value && (Number(value) < 0.0001 || Number(value) > 9999.9999))
                            return false;
                        },
                      },
                    })}
                    spinner
                    type="number"
                  />
                  <button type="button" onClick={() => handleRemoveWorkStandardPart(index)}>
                    <StyledIcon icon={Icons.TRASH} size={13} />
                  </button>
                </PartInputWrapper>
              );
            })
          : fields.map((part, index) => {
              return (
                <PartInputWrapper className="parts" key={part.id}>
                  <PartNumberInput
                    defaultValue={part.number ? part.number : part.partNumber}
                    disabled={readOnly}
                    errors={errors}
                    fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'number' }}
                    label={index === 0 ? t('form.part_number', 'Part #') : ' '}
                    name={`${fieldName}[${index}].number`}
                    placeholder={t('form.part_number', 'Part #')}
                    workStandards={workStandards}
                    ref={register({
                      validate: {
                        required: (value) => {
                          return !value.trim() &&
                            (watchParts[index].name || watchParts[index].quantity)
                            ? false
                            : true;
                        },
                      },
                    })}
                    type="text"
                  />
                  <PartNameInput
                    defaultValue={part.name ? part.name : part.partDescription}
                    disabled={readOnly}
                    errors={errors}
                    fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'name' }}
                    label={index === 0 ? t('form.part_name', 'Part Name') : ' '}
                    name={`${fieldName}[${index}].name`}
                    placeholder={t('form.part_name', 'Part Name')}
                    workStandards={workStandards}
                    ref={register({
                      validate: {
                        required: (value) => {
                          return !value.trim() &&
                            (watchParts[index].number || watchParts[index].quantity)
                            ? false
                            : true;
                        },
                      },
                    })}
                    type="text"
                  />
                  <PartQuantityInput
                    defaultValue={part.quantity ? part.quantity : part.quantitySuggested}
                    disabled={readOnly}
                    errors={errors}
                    fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'quantity' }}
                    label={index === 0 ? t('form.quantity', 'Quantity') : ' '}
                    min="0"
                    max="9999"
                    name={`${fieldName}[${index}].quantity`}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value && Math.floor(value) !== parseFloat(value)) {
                        if (value.toString().split('.')[1].length > 4) {
                          setValue(`${fieldName}[${index}].quantity`, parseFloat(value).toFixed(4));
                        }
                      }
                      if (value < 0) {
                        setValue(`${fieldName}[${index}].quantity`, 0);
                      } else if (value > 9999.9999) {
                        setValue(`${fieldName}[${index}].quantity`, 9999.9999);
                      }
                    }}
                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                    placeholder={t('form.qty', 'Qty')}
                    workStandards={workStandards}
                    ref={register({
                      validate: {
                        required: (value) => {
                          return !value && (watchParts[index].number || watchParts[index].name)
                            ? false
                            : true;
                        },
                        //0.0001 - 9999.9999 min and max for quantity
                        validate: (value) => {
                          if (value && (Number(value) < 0.0001 || Number(value) > 9999.9999))
                            return false;
                        },
                      },
                    })}
                    spinner
                    type="number"
                  />
                  <button type="button" onClick={() => handleRemove(index)}>
                    <StyledIcon icon={Icons.TRASH} size={13} />
                  </button>
                </PartInputWrapper>
              );
            })}
      </div>
      {workStandardsCopy?.length ? (
        <ConditionalRender shouldRender={fields.length < 50}>
          <AddPartButton type="button" onClick={handleAddWorkStandardPart}>
            <AddPartIcon />
            {t('form.add_part', 'Add Part')}
          </AddPartButton>
        </ConditionalRender>
      ) : (
        <ConditionalRender shouldRender={fields.length < 50}>
          <AddPartButton type="button" onClick={() => append(defaultParts)}>
            <AddPartIcon />
            {t('form.add_part', 'Add Part')}
          </AddPartButton>
        </ConditionalRender>
      )}
    </PartsRow>
  );
};

Parts.propTypes = {
  metadata: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  workStandardsData: PropTypes.object,
  task: PropTypes.object,
  setWorkStandardsData: PropTypes.func,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};
