import { useEffect, useCallback } from 'react';

/**
 * useManageColumnVisibility
 * A util hook that manages table column hide/show functionality.
 * Currently setVisibleColumns and updateColumnVisibility are dependancies provided by useColumnState.
 * In the future could consider moving this here.
 *
 * @param {String} subKey
 * @param {Object} featPrefs
 * @param {Function} savePrefs
 * @param {Function} setVisibleColumns
 * @param {Function} updateColumnVisibility
 * @returns {Object} { onVisibilityChange }
 */

export const useManageColumnVisibility = ({
  subKey,
  featPrefs,
  savePrefs,
  setVisibleColumns,
  updateColumnVisibility,
}) => {
  // Extract and sanitize columns found in feature preferences
  useEffect(() => {
    if (!featPrefs) {
      return;
    }

    const { [subKey]: prefs } = featPrefs;

    const dirtyPrefSave = (cols, dirty, subColumnKey) => {
      // Set columns. If modified during set (via sanitize), save prefs.
      // NOTE: Right now the feature preferences ARE the columns, in the case
      // that we would have add'l non-column prefs, this would need to be
      // extracted from the preferences object before setting.
      if (dirty) {
        savePrefs(cols, subColumnKey);
      }
    };

    if (prefs) {
      const { cols, dirty } = setVisibleColumns(prefs);
      dirtyPrefSave(cols, dirty, subKey);
    }
  }, [featPrefs, savePrefs, setVisibleColumns, subKey]);

  // Save updated site column visibility changes to prefs.
  const onVisibilityChange = useCallback(
    (columnChanges) => {
      const { cols, dirty } = updateColumnVisibility(columnChanges);
      if (dirty) {
        savePrefs(cols, subKey);
      }
    },
    [updateColumnVisibility, savePrefs, subKey],
  );

  const onSelectionVisibilityChange = useCallback(
    (columnChanges) => {
      updateColumnVisibility(columnChanges);
    },
    [updateColumnVisibility],
  );

  return {
    onVisibilityChange,
    onSelectionVisibilityChange,
  };
};
