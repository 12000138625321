import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { BoldSearch } from '@ge/components/bold-search';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Text } from '@ge/components/typography';
import { debounce, killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens/typography';

// can move this into config if needed
const MIN_SEARCH_LENGTH = 1;
const INPUT_DELAY = 300;

const FilterInput = styled.input`
  height: 22px;
  padding-right: 25px;
  width: calc(100% - 31px);
  color: ${(props) => props.theme.filterMenu.textColor};
  font-size: 12px;
  line-height: 15px;
  border: 1px solid ${(props) => props.theme.filterMenu.inputBorderColor};
  border-radius: 2px;
  background-color: ${(props) => props.theme.filterMenu.inputBackgroundColor};
`;

const ResultsContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  flex: 1;
  max-height: 300px;
  width: 100%;
`;

const SearchIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SEARCH,
  color: props.theme.filterMenu.searchIcon,
}))``;

const StyledUl = styled.ul`
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  li {
    padding: 2px 6px;
    margin: 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
`;

export const FilterSearch = ({ active, filterValues, onChange, value }) => {
  const [filterText, setFilterText] = useState('');

  const delayedOnChange = useCallback(
    (search) =>
      debounce(
        () => onChange(search?.length >= MIN_SEARCH_LENGTH ? search : undefined),
        INPUT_DELAY,
        true,
      ),
    [onChange],
  );

  const handleChange = useCallback(
    (search) => {
      setFilterText(search);
      delayedOnChange(search)();
    },
    [setFilterText, delayedOnChange],
  );

  // if value passed in by parent, then get search results
  useEffect(() => {
    if (
      !(value.length === 0 && filterText.length === 0) ||
      !(value.length > 0 && filterText.length === 0)
    ) {
      setFilterText(value);
      return;
    }
    handleChange(value);
  }, [filterText, handleChange, value]);

  // we check for a description prop in case cell has a complex value, otherwise we eval the cell value
  const renderSearch = (searchArray) =>
    searchArray?.map((item, idx) => (
      <li className="list-text" key={`${idx}`} onClick={() => handleChange(item)}>
        <Text type={typography.textTypes.grid} level={2}>
          <BoldSearch text={item} textBold={filterText} />
        </Text>
      </li>
    ));

  return (
    <>
      <div className="filter-input">
        <FilterInput
          type="text"
          onKeyDown={(e) => killEventPropagation(e)}
          onChange={({ target }) => handleChange(target.value)}
          value={filterText}
        />
        <SearchIcon />
      </div>
      <ResultsContainer>
        {filterValues?.length > 0 && (
          <ScrollingContainer relative>
            <StyledUl noPadding>{renderSearch(filterValues)}</StyledUl>
          </ScrollingContainer>
        )}
        {active && !filterValues?.length > 0 && (
          <ScrollingContainer relative>
            <StyledUl noPadding>
              <li className="list-text" onClick={() => handleChange(value)}>
                <Text type={typography.textTypes.grid} level={2}>
                  <BoldSearch text={value} textBold={value} />
                </Text>
              </li>
            </StyledUl>
          </ScrollingContainer>
        )}
      </ResultsContainer>
    </>
  );
};

FilterSearch.propTypes = {
  active: PropTypes.bool,
  filterValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

FilterSearch.defaultProps = {
  active: false,
  filterValues: [],
  value: '',
};
