import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { AnaylzeDefs } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';
import { AllSitesColumnDefs } from '@ge/shared/models/table-col-defs/sites-list-table-cols';
import { killEventPropagation } from '@ge/shared/util/general';

import useSitesFromSiteKpi from '../hooks/use-sites-from-site-kpi';
import { defaultSitesCols } from '../models/site-list-table-col-defaults';

const NAMESPACE = 'analyze.dashboard';

const SitesMetric = {
  NAME: 'name',
  STATE: 'metrics.state',
  PRIORITY: 'priority',
};

const SITE_SORT_STATE_ID = `${NAMESPACE}.allSites`;

const useAllSitesTable = ({ siteKpiData }) => {
  const { push } = useHistory();
  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ANALYZE_REGION);

  /* Table state */
  const {
    visibleCols,
    sortDirection,
    sortMetric,
    setVisibleColumns,
    updateColumnVisibility,
    ...restColumnState
  } = useColumnState({
    columnDefs: AllSitesColumnDefs,
    defaultCols: defaultSitesCols,
    defaultSortMetric: SitesMetric.PRIORITY,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: SITE_SORT_STATE_ID,
  });

  const { isLoading, sites } = useSitesFromSiteKpi({
    allSitesData: siteKpiData,
    tableSortMetric: sortMetric,
    tableSortDirection: sortDirection,
  });

  const handleSiteSelect = useCallback(
    (e, { siteId }) => {
      const routeToSite = (siteId) => push(`/analyze/site/${siteId}`);
      killEventPropagation(e);
      routeToSite(siteId);
    },
    [push],
  );

  const { onVisibilityChange: handleSiteColumnChanges, onSelectionVisibilityChange } =
    useManageColumnVisibility({
      subKey: AnaylzeDefs.SITES,
      featPrefs,
      savePrefs,
      setVisibleColumns,
      updateColumnVisibility,
    });

  return {
    isLoading,
    sortDirection,
    sortMetric,
    sites,
    ...restColumnState,
    visibleCols,
    handleSiteSelect,
    handleSiteColumnChanges,
    onSelectionVisibilityChange,
  };
};
export default useAllSitesTable;
