import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';

import { Icon, Icons } from '../icon';

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  color: ${(props) => props.theme.menu.headerColor};
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  .menu-title-txt {
    text-transform: uppercase;
    height: 13px;
    color: ${(props) => props.theme.menu.titleTextColor};
    font-family: 'Museo Sans';
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 13px;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
  }
`;

const StyledChevron = styled.button`
  margin-left: auto;
  align-items: center;
  display: flex;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.entityDetails.headerIconColor,
}))`
  transform: rotate(-90deg);
`;

export const MenuTitle = ({ title, count, duration, onDetailClick }) => {
  const handleClick = useCallback((e) => killEventPropagation(e) && onDetailClick(e), [
    onDetailClick,
  ]);
  return (
    <StyledTitle>
      <h4 className="menu-title-txt">{title}</h4>
      {!!count && <Badge label={`${count}`} color={globalColors.slate2} small />}
      {duration && <div className="menu-title-duration">{duration}</div>}
      {onDetailClick && (
        <StyledChevron type="button" onClick={handleClick}>
          <ChevronIcon />
        </StyledChevron>
      )}
    </StyledTitle>
  );
};

MenuTitle.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  duration: PropTypes.string,
  onDetailClick: PropTypes.func,
};

MenuTitle.defaultProps = {
  count: null,
  duration: null,
  onDetailClick: null,
};
