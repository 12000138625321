import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DynamicTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import {
  TemplateColumnDefs,
  TemplateColumns,
  globalTemplateCols,
} from '@ge/feat-reporting/models/template-table-cols';
import { AttributeGroups } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { withDefault } from '@ge/shared/util/table-utils';
import { globalColors } from '@ge/tokens';

const StyledDisplay = styled.span`
  color: ${(props) => props.theme.table.columnTextColor};
`;

const ThGroup = styled.span`
  height: 1px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.postProcess.iconButton,
  size: props.size,
  icon: props.icon,
}))``;

const LockIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.LOCK,
  color: globalColors.grey13,
}))`
  flex-shrink: 0;
  margin: 0 5px 5px;
  display: inline-block;
`;

export const TemplateDashboardTable = ({
  templateData,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onNewReportSelect,
  scrollable,
  className,
}) => {
  const { t, ready } = useTranslation(['reporting.reports']);

  const { getViewServiceGroupsSites: viewServiceGroups = [], sitesForView = [] } = useStoreState(
    (state) => state.sites,
  );

  const handleOnClick = (e, cell) => {
    killEventPropagation(e);
    e.preventDefault();
    onNewReportSelect(cell);
  };

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const getScopeName = useCallback(
    (id, type) => {
      if (type === AttributeGroups.SERVICE_GROUP) {
        const serviceGroup = viewServiceGroups.find((sg) => sg?.id === id);
        if (serviceGroup?.name) {
          return serviceGroup.name;
        }
      } else if (type === AttributeGroups.SITES) {
        const site = sitesForView.find((s) => s?.id === id);
        if (site?.name) {
          return site.name;
        }
      }
      return id;
    },
    [sitesForView, viewServiceGroups],
  );

  const cellValueMapFn = useCallback(
    (cell) => {
      if (!cell) {
        return {};
      }

      const { id, name, scope, scopeType, span, baseTemplate } = cell;

      return {
        [TemplateColumns.SCOPE]: getScopeName(scope, scopeType),
        [TemplateColumns.NAME]: { baseTemplate, name },
        [TemplateColumns.SPAN]: span,
        [TemplateColumns.CREATE_REPORT]: id,
      };
    },
    [getScopeName],
  );

  const customHeaderFn = useCallback((columnKey) => {
    switch (columnKey) {
      case TemplateColumns.GROUP_NAME:
      case TemplateColumns.GROUP_SCOPE:
      case TemplateColumns.GROUP_SPAN:
      case TemplateColumns.GROUP_CREATE_REPORT:
        return <ThGroup />;
      default:
        return null;
    }
  }, []);

  const customCellFn = (columnKey, cellValue) => {
    switch (columnKey) {
      case TemplateColumns.NAME:
        return withDefault(
          cellValue,
          <StyledDisplay>
            {cellValue?.baseTemplate ? <LockIcon /> : null}
            {cellValue?.name}
          </StyledDisplay>,
        );
      case TemplateColumns.SCOPE:
        return withDefault(cellValue, <StyledDisplay>{cellValue}</StyledDisplay>);
      case TemplateColumns.SPAN:
        return cellValue;
      case TemplateColumns.CREATE_REPORT:
        return withDefault(
          cellValue,
          <StyledDisplay onClick={(e) => handleOnClick(e, cellValue)}>
            <StyledIcon icon={Icons.ADD} size={12} />
          </StyledDisplay>,
        );
      default:
        return null;
    }
  };

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: TemplateColumnDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
  });

  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: templateData,
    onValueSelect: () => null,
    rowKeyProperty: 'id',
  };

  if (!ready) {
    return <Loader />;
  }

  const tableEl = React.createElement(DynamicTable, dynamicProps);

  return <PageContainer i18nReady={ready}>{tableEl}</PageContainer>;
};

TemplateDashboardTable.propTypes = {
  templateData: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  onNewReportSelect: PropTypes.func,
};

TemplateDashboardTable.defaultProps = {
  columns: globalTemplateCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onNewReportSelect: () => null,
  scrollable: true,
};
