import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';

import { Container, DialogLabel } from './event-dialog-style';

const TimeInput = styled(Container)`
  position: relative;
  padding: 0;
  button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1px;
    padding: 2px 5px 2px 0;
  }
  input {
    width: 100%;
  }
`;

const TimeIcon = styled(Icon).attrs((props) => ({
  size: 14,
  viewbox: '0 0 512 512',
  icon: Icons.TIME_ICON,
  color: props.theme.analyze.manualAdjustment.eventDialog.editButtonColor,
}))`
  flex-shrink: 0;
  opacity: 0.5;
`;

const StyledInput = styled(Input)`
  &:focus {
    &:before {
      display: none;
    }
  }
  &:active {
    &:before {
      display: none;
    }
  }
`;

export const MalEventDateSelect = ({ name, label, defaultValue }) => {
  const { control } = useFormContext();

  const formatTime = useCallback((value) => {
    const values =
      String(value)
        .replace(/[^0-9]/g, '')
        .match(/.{1,2}/g) ?? [];
    const _value = values
      .slice(0, 3)
      .map((val, i) => {
        if (i === 0 && val >= 24) {
          return val % 24;
        } else if (i > 0 && val >= 60) {
          return val % 60;
        }
        return val;
      })
      .join(':');
    return _value;
  }, []);

  return (
    <div>
      <DialogLabel>{label}</DialogLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: true,
        }}
        defaultValue={defaultValue}
        render={({ value, onChange }) => (
          <TimeInput>
            <StyledInput
              type="text"
              placeholder="hh:mm:ss"
              steps="2"
              value={value}
              onChange={(e) => onChange(formatTime(e.target.value))}
            />
            <button type="button">
              <TimeIcon />
            </button>
          </TimeInput>
        )}
      />
    </div>
  );
};

MalEventDateSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
};

MalEventDateSelect.defaultProps = {
  defaultValue: '',
};
