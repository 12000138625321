import * as defaultIsMergeableObject from 'is-mergeable-object';
import { isPlainObject } from 'is-plain-object';

// NOTE: This utility is a temporary addition that will be replaced by a 3rd party
// implementation as soon as https://github.com/TehShrike/deepmerge/issues/208 is released.

const overwriteMerge = (_, sourceArray) => sourceArray;

function emptyTarget(val) {
  return Array.isArray(val) ? [] : {};
}

function firstArrayEntry(arr) {
  return arr && arr.length ? arr[0] : [];
}

function cloneUnlessOtherwiseSpecified(value, options) {
  return options.clone !== false && options.isMergeableObject(value)
    ? deepmerge(emptyTarget(value), value, options)
    : value;
}

function defaultArrayMerge(target, source, options) {
  return target.concat(source).map(function(element) {
    return cloneUnlessOtherwiseSpecified(element, options);
  });
}

function getMergeFunction(key, options) {
  if (!options.customMerge) {
    return deepmerge;
  }
  var customMerge = options.customMerge(key);
  return typeof customMerge === 'function' ? customMerge : deepmerge;
}

function getEnumerableOwnPropertySymbols(target) {
  return Object.getOwnPropertySymbols
    ? Object.getOwnPropertySymbols(target).filter(function(symbol) {
        // eslint-disable-next-line no-prototype-builtins
        return target.propertyIsEnumerable(symbol);
      })
    : [];
}

function getKeys(target) {
  return Object.keys(target).concat(getEnumerableOwnPropertySymbols(target));
}

function propertyIsOnObject(object, property) {
  try {
    return property in object;
  } catch (_) {
    return false;
  }
}

// Protects from prototype poisoning and unexpected merging up the prototype chain.
function propertyIsUnsafe(target, key) {
  return (
    propertyIsOnObject(target, key) && // Properties are safe to merge if they don't exist in the target yet,
    !(
      (Object.hasOwnProperty.call(target, key) && Object.propertyIsEnumerable.call(target, key)) // unsafe if they exist up the prototype chain,
    )
  ); // and also unsafe if they're nonenumerable.
}

// Retrieves either a new object or the appropriate target object to mutate.
function getDestinationObject(target, source, options) {
  const targetDefined = typeof target !== 'undefined';
  const isArray = Array.isArray(target) || Array.isArray(source);

  if (!targetDefined || (options && options.clone !== false)) {
    return isArray ? [] : {};
  }

  return isArray ? firstArrayEntry(target) : target;
}

function mergeObject(target, source, options) {
  var destination = getDestinationObject(target, source, options);
  if (options.isMergeableObject(target)) {
    getKeys(target).forEach(function(key) {
      destination[key] = cloneUnlessOtherwiseSpecified(target[key], options);
    });
  }
  getKeys(source).forEach(function(key) {
    if (propertyIsUnsafe(target, key)) {
      return;
    }

    if (propertyIsOnObject(target, key) && options.isMergeableObject(source[key])) {
      destination[key] = getMergeFunction(key, options)(target[key], source[key], options);
    } else {
      destination[key] = cloneUnlessOtherwiseSpecified(source[key], options);
    }
  });
  return destination;
}

function deepmerge(target, source, options) {
  options = options || {
    arrayMerge: overwriteMerge,
    isMergeableObject: isPlainObject,
    clone: false,

    // cloneUnlessOtherwiseSpecified is added to `options` so that custom arrayMerge()
    // implementations can use it. The caller may not replace it.
    cloneUnlessOtherwiseSpecified: cloneUnlessOtherwiseSpecified,
  };
  options.arrayMerge = options.arrayMerge || defaultArrayMerge;
  options.isMergeableObject = options.isMergeableObject || defaultIsMergeableObject;
  // cloneUnlessOtherwiseSpecified is added to `options` so that custom arrayMerge()
  // implementations can use it. The caller may not replace it.
  options.cloneUnlessOtherwiseSpecified = cloneUnlessOtherwiseSpecified;

  var sourceIsArray = Array.isArray(source);
  var targetIsArray = Array.isArray(target);
  var sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;

  if (!sourceAndTargetTypesMatch) {
    return cloneUnlessOtherwiseSpecified(source, options);
  } else if (sourceIsArray) {
    return options.arrayMerge(target, source, options);
  } else {
    return mergeObject(target, source, options);
  }
}

deepmerge.all = function deepmergeAll(array, options) {
  if (!Array.isArray(array)) {
    throw new Error('first argument should be an array');
  }

  return array.reduce(function(prev, next) {
    return deepmerge(prev, next, options);
  }, getDestinationObject(array, options));
};

export default deepmerge;
