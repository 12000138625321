import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { useCreateCaseTemplate } from '@ge/feat-analyze/data-hooks/use-case-templates';
import {
  NAMESPACE,
  Fields,
  RootCauseOption,
} from '@ge/feat-analyze/models/configure-case-template';
import { QueryKey } from '@ge/models/constants';

import { FormErrorMessage } from '../../shared';

import AnalyticsDetailForm from './case-template-analytics-details';
import CaseDetailForm from './case-template-case-detail';
import CaseTemplateTasks from './case-template-tasks';

const Container = styled.div`
  min-width: 520px;
  min-height: 200px;
  ${({ isLoading }) => (isLoading ? 'position: relative' : '')}
  label {
    color: ${(props) => props.theme.input.labelTextColor};
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

const DialogFooter = ({ onCancel, onSave, isLoading }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  if (!ready) return null;

  return (
    <FooterWrapper>
      <FooterButtons>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('case_template.cancel', 'Cancel')}
        </Button>
        <Button primary onClick={onSave} disabled={isLoading}>
          {t('case_template.save', 'Save')}
        </Button>
      </FooterButtons>
    </FooterWrapper>
  );
};

export const NewCaseTemplateDialog = ({ isOpen, onClose, onConfirm }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { notify } = useNotification();
  const queryClient = useQueryClient();
  const {
    mutate: create,
    isLoading,
    isError,
    error,
  } = useCreateCaseTemplate({
    enabled: true,
    onSuccess: (res) => {
      queryClient.invalidateQueries([QueryKey.CASE_TEMPLATE_TABLE]);
      onConfirm(res);
      notify({ message: t('case_template_created_success', 'Case Template Created Successfully') });
    },
  });

  const methods = useForm({ mode: 'onChange' });

  const onSubmit = useCallback(
    (values) => {
      const componentTypeIds = [values[Fields.COMPONENT_L1]];
      if (values[Fields.COMPONENT_L2]) {
        componentTypeIds.push(values[Fields.COMPONENT_L2]);
        if (values[Fields.COMPONENT_L3]) {
          componentTypeIds.push(values[Fields.COMPONENT_L3]);
        }
      }

      const rootCauses =
        [
          ...new Set(
            [
              ...(values[Fields.ROOT_CAUSES] ? values[Fields.ROOT_CAUSES] : []),
              ...(values[Fields.ROOT_CAUSES_NEW] ? values[Fields.ROOT_CAUSES_NEW] : []),
            ].filter((v) => v && v !== RootCauseOption.ADD_NEW),
          ),
        ] ?? [];

      const payload = {
        [Fields.CASE_TEMPLATE_ID]: values[Fields.CASE_TEMPLATE_ID],
        [Fields.CASE_TITLE]: values[Fields.CASE_TITLE],
        [Fields.CASE_DESCRIPTION]: values[Fields.CASE_DESCRIPTION],
        [Fields.CASE_PRIORITY]: values[Fields.CASE_PRIORITY],
        [Fields.CASE_SOURCE]: values[Fields.CASE_SOURCE],
        [Fields.IS_BETA]: values[Fields.IS_BETA] ? true : false,
        [Fields.COMPONENT_TYPE_IDS]: componentTypeIds,
        [Fields.ROOT_CAUSES]: rootCauses,
        [Fields.ANALYSIS_TEMPLATE_ID]: values[Fields.ANALYSIS_TEMPLATE_ID],
        [Fields.ANALYTICS_SOURCE]: values[Fields.ANALYTICS_SOURCE],
        [Fields.TASK_AUTO_CREATION]: values[Fields.TASK_AUTO_CREATION] ?? [],
        [Fields.TASK_SOURCE]: values[Fields.TASK_SOURCE],
      };
      create(payload);
    },
    [create],
  );

  if (!ready) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={t('case_template.title', 'New Case Template')}
      footer={
        <DialogFooter
          isLoading={isLoading}
          onCancel={onClose}
          onSave={methods.handleSubmit(onSubmit)}
        />
      }
      contentWidth={true}
      padContent={false}
    >
      <FormProvider {...methods}>
        <Container isLoading={isLoading}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <CaseDetailForm />
              <AnalyticsDetailForm />
              <CaseTemplateTasks />
              <FormErrorMessage isError={isError} message={error?.message} />
            </>
          )}
        </Container>
      </FormProvider>
    </Dialog>
  );
};

DialogFooter.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

DialogFooter.defaultValues = {
  isLoading: false,
  onCancel: () => {},
  onSave: () => {},
};

NewCaseTemplateDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedEntityId: PropTypes.object,
};

NewCaseTemplateDialog.defaultValues = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
  selectedEntityId: undefined,
};

export default NewCaseTemplateDialog;
