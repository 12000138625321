import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chart, ChartType } from '@ge/components/charts';
import { CardinalDirection } from '@ge/models/constants';
import { roundNumber } from '@ge/util';
import { isEmpty } from '@ge/util/object-utils';

import { NoDataAvailable } from '../../no-data-available/no-data-available';

const SeriesCategoryLevel = {
  '<0.5': 'wind_direction_speed.rose.min',
  '0.5 to 2.0': 'wind_direction_speed.rose.2',
  '2.0 to 4.0': 'wind_direction_speed.rose.4',
  '4.0 to 6.0': 'wind_direction_speed.rose.6',
  '6.0 to 8.0': 'wind_direction_speed.rose.8',
  '8.0 to 10.0': 'wind_direction_speed.rose.10',
  '>10.0': 'wind_direction_speed.rose.max',
};

export const WindDirectionSpeedWidget = ({ data }) => {
  const { t } = useTranslation(['reporting.widgets', 'general']);

  // translations
  const { cardinal, categoryMap, percent, title, units } = {
    cardinal: Object.values(CardinalDirection).reduce(
      (acc, v) => ({ ...acc, [v]: t(`wind_direction_speed.cardinal.${v}`) }),
      {},
    ),
    categoryMap: Object.keys(SeriesCategoryLevel).reduce(
      (acc, key) => ({ ...acc, [key]: t(`${SeriesCategoryLevel[key]}`) }),
      {},
    ),
    percent: t('wind_direction_speed.percent', '%'),
    title: t('wind_direction_speed.wind_direction_and_speed', 'Wind Direction & Speed'),
    units: t('wind_direction_speed.meters_per_second', 'm/s'),
  };

  const tooltipHeader = `
        <h4>
          ${title}
        </h4>
      `;
  const tooltipPointFormatter = ({ name, y }) => `
        <div class='body-4'>
          ${cardinal[name]}, ${y}${percent}
        </div>
      `;

  const buildSeries = (category, bearingData) => {
    const categoryData = Object.keys(bearingData).map((key) => ({
      name: key,
      y: roundNumber(bearingData[key][category] * 100, 1),
    }));
    return {
      data: categoryData,
      name: categoryMap[category],
      tooltipHeader,
      tooltipPointFormatter,
    };
  };

  const bearingData = {};

  if (!data || isEmpty(data)) {
    return <NoDataAvailable />;
  }

  for (const [, value] of Object.entries(data)) {
    Object.keys(value).map((direction) => {
      if (!bearingData[direction]) bearingData[direction] = {};
      Object.keys(value[direction]).map((cat) => {
        if (!bearingData[direction][cat]) bearingData[direction][cat] = 0;
        bearingData[direction][cat] += Number(value[direction][cat]) || 0;
      });
    });
  }

  const series = Object.keys(SeriesCategoryLevel).map((category) =>
    buildSeries(category, bearingData),
  );

  const categories = Object.values(cardinal);

  return (
    <Chart
      categories={categories}
      height={185}
      legendTitle={units}
      noDataLabel="No data"
      recreateOnUpdate={true}
      series={series.slice()}
      stacked={true}
      type={ChartType.ROSE}
      xAxisTickInterval={4}
      spacingTop={-10}
    />
  );
};

WindDirectionSpeedWidget.propTypes = {
  data: PropTypes.instanceOf(Object),
};
