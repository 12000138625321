import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { roundNumber } from '@ge/util';

import { useGlobalFilters } from '../hooks';
import {
  fetchEntityIecCategoryAssets,
  fetchIecCategory,
  fetchIecCategoryMetaData,
} from '../services';

const useIecData = ({
  entityId,
  entityType,
  category,
  startDate,
  endDate,
  assetQueryKey,
  enabled = true,
}) => {
  const [selectedIecCategoryData, setSelectedIecCategoryData] = useState({});
  const filters = useGlobalFilters();

  const maxPageSize = 100;

  const queryKey = {
    entityId,
    category,
    startDate,
    endDate,
    entityType,
    filters,
  };

  const { data: _queryData, ...queryRest } = useQuery(
    [QueryKey.ALL_SITES_IEC_DATA, queryKey],
    () =>
      fetchIecCategory({
        entityId,
        categories: [category],
        startDate,
        endDate,
        entityType,
        filters,
      }),
    {
      enabled: Boolean(category && startDate && endDate && enabled),
      ...Config.EXECUTE_ONCE,
    },
  );

  const { data: _queryMetaData, isLoading: isMetaDataLoading } = useQuery(
    [queryKey, 'metaData'],
    () =>
      fetchIecCategoryMetaData({
        entityId,
        category,
        startDate,
        endDate,
        entityType,
        filters,
      }),
    {
      enabled: !!_queryData,
      ...Config.EXECUTE_ONCE,
    },
  );

  const { totalTurbines, iecCategory: iecState } = selectedIecCategoryData;

  const { data: _assetData, isLoading: isIecAssetDataLoading } = useQuery(
    [assetQueryKey, queryKey, iecState, filters],
    () =>
      fetchEntityIecCategoryAssets({
        entityId: entityId,
        entityType,
        category,
        startDate,
        endDate,
        pageSize: totalTurbines < maxPageSize ? totalTurbines : maxPageSize,
        pageIndex: 0,
        iecState,
        filters,
      }),
    {
      enabled: Boolean(category && iecState && totalTurbines),
      ...Config.EXECUTE_ONCE,
    },
  );

  const dataMapped = useMemo(() => {
    const categoryData = _queryData?.data[category];

    const data = categoryData?.data.reduce(
      (acc, data) => {
        const metaData =
          _assetData?.data[data.iecCategory] || _queryMetaData?.data[data.iecCategory];

        const iecData = metaData
          ? {
              ...data,
              metadata: {
                ...metaData,
              },
            }
          : data;

        return {
          iec: [iecData, ...acc.iec],
          // eslint-disable-next-line no-unsafe-optional-chaining
          oem: [...data?.details, ...acc.oem],
        };
      },
      {
        iec: [],
        oem: [],
      },
    );

    // Should move this logic to the BFF at somepoint.
    const updatedOemData = data?.oem?.map((detail) => {
      if (categoryData.total > 0) {
        detail.value = roundNumber((detail.kpiValue / categoryData.total) * 100);
      }
      return detail;
    });

    return {
      ...categoryData,
      data: !data
        ? null
        : {
            ...data,
            oem: updatedOemData,
          },
    };
  }, [_queryData?.data, category, _assetData?.data, _queryMetaData?.data]);

  return {
    data: dataMapped,
    setSelectedIecCategoryData,
    isIecAssetDataLoading,
    isMetaDataLoading,
    ...queryRest,
  };
};

export default useIecData;
