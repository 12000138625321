import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Select } from '@ge/components/select';
import { DateTimeFormats } from '@ge/models/constants';
import { getDateObject, addUTCOffset } from '@ge/shared/util/time-date';
import { globalColors } from '@ge/tokens';

const Container = styled.div`
  margin-top: 30px;

  .label {
    color: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.slate4}`};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .input-container {
    margin-top: 28px;
    height: 60px;
    background: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.grey6}` : `${globalColors.slate8}`};
    padding: 10px;

    .time-period-container {
      margin-top: 13px;
    }
  }

  .preview-container {
    float: right;
    margin-top: 12.5px;
  }
`;

const StyledInput = styled(Input)`
  height: 24px;
  border: 1px solid ${globalColors.grey9};
  background-color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.white}` : `${globalColors.slate1}`};
`;

const StyledSelect = styled(Select)`
  .select__control {
    width: 151px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  inset: 200px auto 0px 0px;

  .react-datepicker-popper[data-placement^='top'] {
    padding-bottom: 0;
  }
`;

const CalendarInputIcon = styled(Icon).attrs(({ theme, icon }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor,
  icon,
}))`
  margin-right: 6px;
  vertical-align: text-top;
  &.input {
    margin-left: -20px;
  }
`;

// Hidden until multi-day planning functionality is ready -> 2023

// const AddIcon = styled(Icon).attrs(({ theme, icon }) => ({
//   size: 10,
//   color: theme.analyze.header.calendarIconColor, //TODO update this theme
//   icon,
// }))`
//   margin-right: 6px;
//   vertical-align: middle;
// `;

// const TimePeriodButton = styled.button.attrs(() => ({
//   type: 'button',
// }))`
//   color: currentColor;
//   letter-spacing: 0.5px;
//   line-height: 13px;
//   font-size: 11px;
//   font-weight: ${typography.weight.bold};
//   text-transform: uppercase;
//   padding: 0;
// `;

const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.dialog.taskReschedule.spacerBorderColor};
  margin-top: 21.5px;
`;

const dateTypeValues = {
  CUSTOM: 'custom',
  DUE: 'due',
};

export const SetDateTasksForm = ({
  triggerValidation,
  tasks,
  setTaskValues,
  onGetBundleCrewList,
  onResetCrewDetails,
}) => {
  const { t } = useTranslation(['manage.cases-tasks']);

  const [dateTypeOptions] = useState([
    { value: dateTypeValues.CUSTOM, label: t('custom_dates', 'Custom Date(s)') },
    { value: dateTypeValues.DUE, label: t('due_date', 'Due Date') },
  ]);

  const { control: dateTypeControl, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      dateType: dateTypeOptions[0],
    },
  });

  const watchDateType = watch('dateType');
  const watchCustomDate = watch('customDate');

  const handlePreviewClick = () => {
    const currentTasks = [...tasks];

    if (watchDateType.label === `${t('custom_dates', 'Custom Date(s)')}` && watchCustomDate) {
      const customDateValue = watchCustomDate;

      currentTasks.forEach((task) => {
        setTaskValues(`${task.id}.scheduleDate`, customDateValue);
        onResetCrewDetails(task);
      });
    } else if (watchDateType.label === `${t('due_date', 'Due Date')}`) {
      currentTasks.forEach((task) => {
        const dueDate = task?.dueDate;

        if (Object.prototype.hasOwnProperty.call(task, 'dueDate') && dueDate) {
          setTaskValues(`${task.id}.scheduleDate`, new Date(dueDate));
          onResetCrewDetails(task);
        }
      });
    }

    onGetBundleCrewList();
    triggerValidation();
  };

  return (
    <Container>
      <span className="label">{t('schedule_to', 'Schedule To')}:</span>
      <Controller
        control={dateTypeControl}
        name="dateType"
        render={({ onChange, value }) => (
          <StyledSelect
            maxWidth={151}
            onChange={onChange}
            options={dateTypeOptions}
            value={value}
          />
        )}
      />
      {watchDateType.label === `${t('custom_dates', 'Custom Date(s)')}` && (
        <div className="input-container">
          <span className="label">{t('enter_custom_date', 'Enter Custom Date')}</span>
          <Controller
            control={dateTypeControl}
            name={'customDate'}
            render={({ onChange, value }) => (
              <StyledDatePicker
                placeholderText={t('select_date', 'Select Date')}
                onChange={onChange}
                filterDate={(date) =>
                  dayjs(
                    getDateObject(
                      addUTCOffset(dayjs(), Intl.DateTimeFormat().resolvedOptions().timeZone),
                    ),
                  )
                    .startOf('date')
                    .toDate() <= date
                }
                onCalendarClose={() => {
                  triggerValidation();
                }}
                selected={value || null}
                popperPlacement={'auto'}
                customInput={
                  <div>
                    <StyledInput
                      placeholder={dayjs().format(DateTimeFormats.DEFAULT_DATE)}
                      value={value ? dayjs(value).format(DateTimeFormats.DEFAULT_DATE) : ''}
                      onChange={() => {
                        setTimeout(() => {
                          triggerValidation();
                        }, 100);
                      }}
                    />
                    <CalendarInputIcon icon={Icons.CALENDAR} className="input" />
                  </div>
                }
              />
            )}
          />
          {/* Hidden until multi-day planning functionality is ready -> 2023
          <div className="time-period-container">
            <TimePeriodButton onClick={() => console.log('Time Period Clicked!')}>
              <AddIcon icon={Icons.ADD} className="input" />
              {t('time_period', 'time period')}
            </TimePeriodButton>
          </div>
        */}
        </div>
      )}
      <Divider />
      <div className="preview-container">
        <Button primary onClick={handlePreviewClick}>
          {t('preview', 'Preview')}
        </Button>
      </div>
    </Container>
  );
};

SetDateTasksForm.propTypes = {
  triggerValidation: PropTypes.func,
  tasks: PropTypes.instanceOf(Array).isRequired,
  setTaskValues: PropTypes.func,
  onGetBundleCrewList: PropTypes.func,
  onResetCrewDetails: PropTypes.func,
};

SetDateTasksForm.defaultValues = {
  setTaskValues: () => null,
};
