// All available configuration fields and groups for Availability graph
export const AvailabilityFields = {
  // Filters [affects data fetching]
  GROUP_FILTERS: 'Filters',
  CONTRACTUAL: 'Contractual',
  TBA_TECHNICAL: 'TBA Technical',
  PBA_TECHNICAL: 'PBA Technical',

  // Show [affects field visibility]
  GROUP_SHOW: 'Show',
  AVERAGE: 'Average',
};

// Availability graph field definitions
export const availabilityFieldDefs = {
  [AvailabilityFields.GROUP_FILTERS]: {
    type: 'radio',
    labels: [
      {
        a11yKey: 'availability.filters',
        a11yDefault: 'Filters',
      },
    ],
    fields: {
      [AvailabilityFields.TBA_TECHNICAL]: {
        locked: true, // not supported in MVP
        labels: [
          {
            a11yKey: 'availability.tba_technical',
            a11yDefault: 'TBA Technical',
          },
        ],
      },
      [AvailabilityFields.PBA_TECHNICAL]: {
        locked: true, // not supported in MVP
        labels: [
          {
            a11yKey: 'availability.pba_technical',
            a11yDefault: 'PBA Technical',
          },
        ],
      },
      [AvailabilityFields.CONTRACTUAL]: {
        labels: [
          {
            a11yKey: 'availability.contractual',
            a11yDefault: 'Contractual',
          },
        ],
      },
    },
  },
  [AvailabilityFields.GROUP_SHOW]: {
    labels: [
      {
        a11yKey: 'availability.show',
        a11yDefault: 'Show',
      },
    ],
    fields: {
      [AvailabilityFields.AVERAGE]: {
        labels: [
          {
            a11yKey: 'availability.average',
            a11yDefault: 'Average',
          },
        ],
      },
    },
  },
};

// Default order and visibility configuration for Availability graph fields
// NOTE: For state selection, EVERY field must have a position and visibility default!
export const availabilityDefaultConfig = [
  {
    id: AvailabilityFields.GROUP_FILTERS,
    // Template config in the database has TBA Technical enabled, which is not yet supported.
    // This flag tells the config loader to use the values specified here and not overwrite
    // with configuration values sent from the server.
    ignoreTemplateConfig: true,
    fields: [
      {
        id: AvailabilityFields.TBA_TECHNICAL,
        visible: false, // Not supported yet
      },
      {
        id: AvailabilityFields.PBA_TECHNICAL,
        visible: false, // Not supported yet
      },
      {
        id: AvailabilityFields.CONTRACTUAL,
        visible: true,
      },
    ],
  },
  {
    id: AvailabilityFields.GROUP_SHOW,
    fields: [
      {
        id: AvailabilityFields.AVERAGE,
        visible: true,
      },
    ],
  },
];
