import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeFormats } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { addUTCOffset } from '@ge/shared/util/time-date';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  MultiRow,
} from '../../../task-template-shared';

export const ViewTiming = ({ task, taskPanelControls }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const {
    taskState: { timezone },
  } = useContext(TaskContext);

  return (
    <>
      <div className="task-panel-row">{taskPanelControls}</div>
      <div className="task-panel-row">
        <ColumnTitle>
          <h4>{t('section_header.timing', 'Timing')}</h4>
        </ColumnTitle>
        <ColumnDetail className="column-detail timing">
          <MultiRow>
            <DisplayLabel>{t('form.completed_date', 'Completed Date')}</DisplayLabel>
            <DisplayLabel>{t('form.start_time', 'Start Time')}</DisplayLabel>
            <DisplayLabel>{t('form.end_time', 'End Time')}</DisplayLabel>
            <DisplayLabel>{t('form.duration', 'Duration')}</DisplayLabel>
          </MultiRow>
          {task.timing?.map((timePeriod, idx) => (
            <MultiRow key={idx}>
              <DisplayField>
                {timePeriod.date
                  ? addUTCOffset(timePeriod.date, timezone).format(DateTimeFormats.DEFAULT_DATE)
                  : ''}
              </DisplayField>
              <DisplayField>
                {timePeriod.startTime
                  ? addUTCOffset(timePeriod.startTime, timezone).format(
                      DateTimeFormats.DEFAULT_TIME,
                    )
                  : ''}
              </DisplayField>
              <DisplayField>
                {timePeriod.endTime
                  ? addUTCOffset(timePeriod.endTime, timezone).format(DateTimeFormats.DEFAULT_TIME)
                  : ''}
              </DisplayField>
              <DisplayField>
                {timePeriod.durationHrs}
                <span className="time">{t('form.hrs', 'hrs')}</span>
              </DisplayField>
              <DisplayField>
                {timePeriod.durationMins}
                <span className="time">{t('form.mins', 'mins')}</span>
              </DisplayField>
              <DisplayField></DisplayField>
            </MultiRow>
          ))}
        </ColumnDetail>
      </div>
    </>
  );
};

ViewTiming.propTypes = {
  task: PropTypes.object.isRequired,
  taskPanelControls: PropTypes.node,
};

ViewTiming.defaultProps = {
  taskPanelControls: null,
};
