import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { ScrollingContainer } from '@ge/components/scrolling-container';

const CenteredTemplateCss = css`
  padding-right: 0;
  justify-content: center;
`;

const StyledTemplateContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: all 200ms ease-in-out;

  ${({ centerTemplate }) => centerTemplate && CenteredTemplateCss}
`;

export const TemplateContainer = forwardRef((props, ref) => {
  const { children, centerTemplate } = props;
  return (
    <ScrollingContainer>
      <StyledTemplateContainer ref={ref} centerTemplate={centerTemplate}>
        {children}
      </StyledTemplateContainer>
    </ScrollingContainer>
  );
});

TemplateContainer.displayName = 'TemplateContainer';

TemplateContainer.propTypes = {
  children: PropTypes.node,
  centerTemplate: PropTypes.bool,
};

TemplateContainer.defaultProps = {
  centerTemplate: false,
};
