import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import MeshVibrationChart from '../mesh-vibration-chart';
import { MetricContent } from '../metric-content';
import { ChartDefs } from '../models/chart-definitions';
import SpectrumChart from '../spectrum-chart';
import WaveFormChart from '../wave-form-chart';

const DetailsWrapper = styled.div`
  margin-bottom: 20px;
`;

const DetailItem = styled.div`
  margin-bottom: 18px;
  margin-right: 50px;
`;

const DetailSummary = styled.div`
  display: flex;
`;

const Label = styled.span`
  color: ${(props) => props.theme.analyze.metricsPanel.labelColor};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;

const DetailText = styled.p`
  color: ${(props) => props.theme.analyze.metricsPanel.textColor};
  margin: 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;

  &.light-weight {
    font-weight: 300;
  }
`;

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 40px;
`;

export const HighSpeedPinionContent = ({ onChevronClick, large, component }) => {
  const { t } = useTranslation(['analyze.troubleshooting']);

  return (
    <MetricContent>
      <DetailsWrapper>
        <DetailItem>
          <DetailText>{component.part}</DetailText>
          <h1>{component.name}</h1>
        </DetailItem>
        <DetailSummary>
          <DetailItem>
            <Label>{t('metrics.symptom', 'Symptom')}</Label>
            <DetailText>{component.symptom}</DetailText>
          </DetailItem>
          <DetailItem>
            <Label>{t('metrics.recommendation', 'Recommendation')}</Label>
            <DetailText className="light-weight">{component.recommendation}</DetailText>
          </DetailItem>
        </DetailSummary>
      </DetailsWrapper>
      <ChartsWrapper>
        <MeshVibrationChart
          onChevronClick={() => onChevronClick(ChartDefs.VIBRATION.id)}
          large={large}
          title={t('vibration', 'Vibration')}
        />
        <WaveFormChart
          onChevronClick={() => onChevronClick(ChartDefs.MESH.id)}
          large={large}
          title={t('mesh', 'Mesh')}
        />
      </ChartsWrapper>
      <SpectrumChart
        onChevronClick={() => onChevronClick(ChartDefs.SPECTRUM.id)}
        large={large}
        title={t('spectrum', 'Spectrum')}
      />
    </MetricContent>
  );
};

HighSpeedPinionContent.propTypes = {
  onChevronClick: PropTypes.func,
  large: PropTypes.bool,
  component: PropTypes.instanceOf(Object),
};

HighSpeedPinionContent.defaultProps = {
  onChevronClick: () => null,
  large: false,
  component: null,
};
