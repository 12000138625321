import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { fetchCommandStatus } from '../services/commands';

import { Config } from './config';

const commandStatusTransformer = (resp, assets) => {
  let { status: statusArr } = resp;
  let modifiedStatus = statusArr.map((status, index) => {
    return {
      ...status,
      id: assets[index]?.id,
      name: assets[index]?.name,
    };
  });

  return {
    isComplete: resp.isComplete,
    status: modifiedStatus,
  };
};

const getStatus = async (id, assets, isGroup) => {
  let resp = {};
  try {
    resp = id !== undefined ? await fetchCommandStatus(id, isGroup) : {};
    resp = commandStatusTransformer(resp, assets, id);
  } catch (err) {
    console.error(err);
  }
  return resp;
};

export const useAssetCommandStatus = ({ isActive = true, statusId, assets = [] }) => {
  const isGroup = assets.length > 1;
  const [enabled, setEnabled] = useState(Boolean(statusId));

  const { data, error, isLoading, remove } = useQuery(
    [QueryKey.ASSET_COMMAND_STATUS, statusId],
    () => getStatus(statusId, assets, isGroup),
    {
      ...Config.EXECUTE_ONCE,
      enabled,
      initialData: () => ({ isComplete: false }),
      refetchInterval: Config.REFRESH.commandStatus,
    },
  );

  useEffect(() => {
    setEnabled(isActive && !data?.isComplete && Boolean(statusId));
  }, [isActive, data?.isComplete, setEnabled, statusId]);

  return {
    data: {
      ...data,
      isExecuting: enabled,
      reset: remove,
    },
    error,
    isLoading,
  };
};
