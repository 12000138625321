import { useMutation, useQueryClient } from 'react-query';

import { closeCaseById, updateCase } from '@ge/feat-monitor/services';
import { QueryKey } from '@ge/models/constants';

import { CasesQueryKeys } from './cases/query-keys';
import { updateCasesCacheList } from './use-update-cases';

export const useCloseCase = () => _useUpdateCase(closeCaseById);

export const useUpdateCase = () => _useUpdateCase(updateCase);

const _useUpdateCase = (mutateFn) => {
  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(CasesQueryKeys.detail(data.id));
      queryClient.invalidateQueries([QueryKey.CASE_TASKS, data.id]);
      queryClient.refetchQueries([QueryKey.ASSETS_CASES, data.asset?.id]);
      updateCasesCacheList(queryClient, data);
    },
  });
};
