// import merge from 'deepmerge';
import { action, computed, thunk } from 'easy-peasy';

import { getTurbineTypes, getRocStations } from '../services/app';

// Define initial state
const defaultState = {
  siteNames: {},
  siteRegionNames: {},
  turbineTypes: {},
  regions: {},
  regions_list: {},
  rocStations: {},
  TurbineDetails_list: {},
};

// Actions
const featureActions = {
  fetchRocStations: thunk(async (actions) => {
    const options = {};
    const { result } = await getRocStations(options);
    actions.setRocStations(result.data);
  }),

  fetchTurbineTypes: thunk(async (actions) => {
    const options = {};
    const { result } = await getTurbineTypes(options);
    actions.setTurbineTypes(result.data);
  }),

  setSiteNames: action((state, payload) => {
    state.siteNames = payload.reduce((s, c) => {
      const { id } = c;
      return { ...s, [id]: c.attributes.sitename };
    }, {});
  }),

  setRegionSiteNames: action((state, payload) => {
    state.siteRegionNames = payload;
  }),

  setTurbineTypes: action((state, payload) => {
    state.turbineTypes = payload.reduce((s, c) => {
      const { id } = c;
      return { ...s, [id]: c.attributes.turbinetype };
    }, {});
  }),

  setRegions: action((state, payload) => {
    state.regions = payload;
  }),

  setRocStations: action((state, payload) => {
    state.rocStations = payload;
  }),
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// Listeners
const featureListeners = {};

// Computed values
const featureComputed = {
  getTurbineDetailsAsList: computed((state) => () => Object.values(state.turbineTypes)),
  getSiteNamesAsList: computed((state) => () => Object.values(state.siteNames)),
  getSiteRegionNamesAsList: computed((state) => () => Object.values(state.siteRegionNames)),
  getSiteNamesAsListByregionId: computed((state) => (regionId) => {
    const len = Object.values(state.siteRegionNames).length;
    const options = [];
    for (let i = 0, l = len; i < l; i += 1) {
      if (Object.values(state.siteRegionNames)[i]['region_id'] === regionId) {
        options.push(state.siteRegionNames[i] && state.siteRegionNames[i]);
      }
    }
    return options;
  }),
  getTurbineTypesAsList: computed((state) => () => Object.values(state.turbineTypes)),
  getRegionsAsList: computed((state) => () => Object.values(state.regions)),
  getSiteNameById: computed(
    (state) => (siteId) => state.sites[siteId] && state.sites[siteId].attributes.sitename,
  ),
  // getSiteRegionNamesAsList: computed((state) => () => Object.values(state.siteRegionNames)),
  // getSiteNamesAsList: computed((state) => () => Object.values(state.siteNames)),
  getSiteIdByName: computed((state) => (siteName) => {
    const site_id = getKeyByValue(state.siteNames, siteName);
    return site_id;
  }),
  getTurbineTypeById: computed((state) => (turbineTypeId) => state.turbineTypes[turbineTypeId]),
  getTurbineTypeIdByName: computed((state) => (turbineType) => {
    const turb_id = getKeyByValue(state.turbineTypes, turbineType);
    return turb_id;
  }),
};

const featureModel = {
  ...defaultState,
  ...featureActions,
  ...featureListeners,
  ...featureComputed,
};

export default featureModel;
