import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
// import { Severity } from '@ge/components/severity';
import { Table, Tbody, Td, Th, Thead } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import {
  PanelColumnWidths,
  Capability,
  AlarmType,
  EntityType,
  SeverityType,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { getDuration, getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';

const StyledTr = styled.tr`
  line-height: 16px;
  opacity: ${(props) => (props.isDisconnected ? 0.4 : 1)};
`;

const StyledTd = styled(Td)`
  padding-left: 0;
`;

const StyledCode = styled.span`
  font-size: 12px;
  vertical-align: middle;
`;

const StyledDesc = styled.span`
  margin-left: 6px;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 25px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const FHPIcon = styled(Icon).attrs((props) => ({
  size: 18,
  icon: Icons.CASE_PROCEDURE,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const FHPButton = styled.button`
  padding-top: 4px;
  height: 20px;
  width: 20px;
`;

const SeverityIcon = styled.svg`
  display: inline-block;
  padding-top: 2px;
  vertical-align: middle;
  height: 22px;
  width: 16px;
  & path:nth-child(1) {
    fill: ${(props) => props.theme.eventSeverity.safetyCritical};
  }
  & path:nth-child(2),
  path:nth-child(3) {
    fill: ${(props) => props.theme.eventSeverity.safetyCriticalExclamatory};
  }
`;

const AlarmIcon = styled(Icon).attrs(() => ({
  size: 18,
  icon: Icons.FAULT_ALARM,
  viewbox: '0 0 16 16',
}))`
  padding-top: 2px;
  path {
    fill: none;
    stroke: ${(props) => props.theme.eventSeverity.falutAlarm};
  }
`;

const WarningIcon = styled(Icon).attrs(() => ({
  size: 18,
  icon: Icons.WARNING_SEVERITY,
  viewbox: '0 0 16 16',
}))`
  padding-top: 2px;
  path {
    fill: none;
    stroke: ${(props) => props.theme.eventSeverity.warning};
  }
`;

const InformationIcon = styled(Icon).attrs(() => ({
  size: 18,
  icon: Icons.INFORMATIONAL_SEVERITY,
  viewbox: '0 0 16 16',
}))`
  padding-top: 2px;
  path {
    fill: none;
    stroke: ${(props) => props.theme.eventSeverity.informational};
  }
`;

const AssetAlternateAlarmTable = ({
  alarms,
  sortAction,
  sortMetric,
  sortDirection,
  isLoading,
  isDisconnected,
  scrollable,
  assetId,
  siteId,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { getSiteById } = useStoreState((store) => store.sites);
  const site = getSiteById(siteId);

  const sortedDirection = (metric) => (metric === sortMetric ? sortDirection : '');

  const spawnWindow = useCallback((type, fhpId, fhpCode) => {
    window.open(
      `/${type}/${fhpId}/${fhpCode}`,
      '_blank',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780px,height=800px',
    );
  }, []);

  const getIconType = useCallback((severity) => {
    switch (severity) {
      case SeverityType.ALARM:
        return <AlarmIcon />;
      case SeverityType.WARNING:
        return <WarningIcon />;
      case SeverityType.INFORMATION:
        return <InformationIcon />;
      case SeverityType.CRITICAL:
        return (
          <SeverityIcon viewBox="0 0 16 12">
            <path d={Icons.SAFETY_POLYGON} />
            <path d={Icons.SAFETY_EXCLAMATORY} />
            <path d={Icons.SAFETY_CIRCLE} />
          </SeverityIcon>
        );
      default:
        return '';
    }
  }, []);

  if (!ready) {
    return <Loader />;
  }

  const currentTime = new Date().getTime();

  return (
    <Table height={'400'} compressed isLoading={isLoading} scrollable={scrollable}>
      <Thead transparent noTitles>
        <StyledTr>
          <Th
            labels={{
              [t('alarms_table.sev', 'Sev.')]: 'eventClass',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XXSMALL}
          />
          <Th
            labels={{
              [t('alarms_table.code', 'Code / #')]: 'code',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
            border
          />
          <Th
            labels={{
              [t('alarms_table.description', 'Description')]: 'description',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XLARGE}
          />
          <Th
            labels={{
              [t('alarms_table.duration', 'Duration')]: 'duration',
            }}
            align="right"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.SMALL}
          />
          <Th
            labels={{
              [`${t('alarms_table.start', 'Start')} (${dayjs
                .tz(new Date(), site?.timezone)
                .format('z')})`]: 'start',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.LARGE}
          />
          <AuthRender
            capability={Capability.FAULT_HANDLING}
            view
            description="Handling procedure"
            siteIds={[siteId]}
          >
            <Th
              labels={{
                [t('alarms_table.handling_procedure', 'Handling\nProcedure')]: 'handling procedure',
              }}
              align="center"
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
            />
          </AuthRender>
        </StyledTr>
      </Thead>
      <Tbody transparent>
        {alarms?.map((alarm, idx) => {
          const alarmDateTime =
            alarm.start !== 'Invalid Date'
              ? getDateTimeBasedOnZone(alarm.start, site?.timezone)
              : null;
          return (
            <StyledTr key={`${idx}-${alarm.code}`} isDisconnected={isDisconnected}>
              <Td border align="left" width={PanelColumnWidths.XSMALL}>
                <TooltipCell tooltip={alarm.eventClass} zIndex={elevations.P20}>
                  {alarm?.eventClass ? getIconType(alarm?.eventClass) : ''}
                </TooltipCell>
              </Td>
              <Td border align="left" width={PanelColumnWidths.MEDIUM}>
                <StyledCode maxWidth={PanelColumnWidths.LARGE} title={alarm.code}>
                  {alarm.code}
                </StyledCode>
              </Td>
              {/* <Td align="left" width={PanelColumnWidths.XXSMALL}>
                <Severity level={alarm.severity} />
              </Td> */}
              <StyledTd border align="left" width={PanelColumnWidths.XLARGE}>
                <StyledDesc title={alarm.description}>{alarm.description}</StyledDesc>
              </StyledTd>
              <Td border align="right" width={PanelColumnWidths.SMALL}>
                {getDuration(alarm.start, dayjs(currentTime)).formatted}
              </Td>
              <Td border align="left" width={PanelColumnWidths.LARGE}>
                {alarmDateTime && (
                  <StyledTimeDate>
                    <span>{alarmDateTime.timeInSecs}</span>
                    <span>{alarmDateTime.date}</span>
                  </StyledTimeDate>
                )}
              </Td>
              <AuthRender
                capability={Capability.FAULT_HANDLING}
                view
                description="Handling procedure"
                siteIds={[siteId]}
              >
                <StyledTd border align="center" width={PanelColumnWidths.XSMALL}>
                  {alarm.category === AlarmType.NON_DIAG && (
                    <FHPButton
                      onClick={() =>
                        spawnWindow(EntityType.HANDLING_PROCEDURE, assetId, alarm.eventId)
                      }
                    >
                      <FHPIcon />
                    </FHPButton>
                  )}
                </StyledTd>
              </AuthRender>
            </StyledTr>
          );
        })}
      </Tbody>
    </Table>
  );
};

AssetAlternateAlarmTable.propTypes = {
  alarms: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onEventSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisconnected: PropTypes.bool,
  scrollable: PropTypes.bool,
  assetId: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

AssetAlternateAlarmTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onEventSelect: () => null,
  isLoading: false,
  scrollable: false,
  isDisconnected: false,
};

export default AssetAlternateAlarmTable;
