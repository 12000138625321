import { useMemo } from 'react';

import { getDataFilter, getDataFilterUp } from '@ge/shared/util/data-filter';
import { dedupArray, searchStr } from '@ge/shared/util/general';

/**
 * Get filtered table data.
 *
 * @param data
 * @param filters
 * @param search
 * @returns {data: Array, filterValues: Object}
 */

export const useTableFilter = ({ data, filters, search, isTaskTable, cases, filterSequence }) => {
  const { key, value } = search ?? {};

  const filterhasdata = useMemo(
    () =>
      Object.entries(filters ?? {}).filter(
        ([, { value, max, min }]) =>
          (value !== undefined && value !== null) ||
          (max !== undefined && max !== null && min !== undefined && min !== null),
      ),
    [filters],
  );

  //pass filters has values, min, max data to improve performance
  const { data: filteredData } = useMemo(
    () =>
      filterhasdata.length > 0
        ? getDataFilter({ data, filters: Object.fromEntries(filterhasdata), isTaskTable })
        : data?.length > 0
        ? { data }
        : {},
    [filterhasdata, data, isTaskTable],
  );

  const dataSet = cases ? cases : data;
  const { filterValues } = useMemo(
    () => getDataFilterUp({ data: dataSet, filters, isTaskTable, filterSequence }),
    [dataSet, filters, isTaskTable, filterSequence],
  );

  const searchValues = useMemo(
    () =>
      dedupArray(
        value
          ? data
              ?.map((item) => {
                const itemValue = item[key];

                // TODO: review but right now expecting if cell value is complex that it has a description prop
                // otherwise try to match cell value directly assuming it's text
                return itemValue?.description ?? itemValue?.name ?? itemValue;
              })
              .filter((itemValue) => searchStr(itemValue, value))
          : data,
      ),
    [data, key, value],
  );

  return useMemo(
    () => ({
      data: filteredData,
      filterValues: !(key && value)
        ? filterValues
        : {
            ...filterValues,
            [key]: searchValues,
          },
    }),
    [filterValues, filteredData, key, searchValues, value],
  );
};
