import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { AxisTickPlacement } from '@ge/components/charts/models';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { isEmpty } from '@ge/util/object-utils';

import { NoDataAvailable } from '../../no-data-available/no-data-available';

import { ProductionGraphFields } from './production-graph-config';

const ProductionGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProductionGraphHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
  place-content: space-between;
`;

const XAxisDateLabel = styled.div`
  font-size: 11px;
`;

const XAxisValueLabel = styled.div`
  font-size: 10px;
`;

const ProductionTotal = styled.div`
  color: ${({ theme }) => theme.createReport.widget.productionGraph.labelColor};
  font-size: 11px;

  span {
    color: ${({ theme }) => theme.createReport.widget.productionGraph.totalValueColor};
  }
`;

const ProductionChartLabel = styled.div`
  color: ${({ theme }) => theme.createReport.widget.productionGraph.chartLabelColor};
  font-size: 10px;
  text-transform: uppercase;

  span {
    color: ${({ theme }) => theme.createReport.widget.productionGraph.labelColor};
    font-size: 11px;
    text-transform: none;
  }
`;

const ProductionGraphChart = styled.div``;
const xLabelRotation = -40;

const getDisplayValue = (value, omitFractionDigits, language) =>
  new Intl.NumberFormat(language, {
    maximumFractionDigits: omitFractionDigits ? 0 : 2,
    minimumFractionDigits: omitFractionDigits ? 0 : 2,
  }).format(value);

export const ProductionGraphStateKeys = {
  CONFIG_STATE: 'configState',
};

export const ProductionGraphWidget = withTheme(({ theme, data, id }) => {
  const { t } = useTranslation(['reporting.widgets', 'general']);

  const { getWidgetState } = useContext(ReportsContext);

  const configState = getWidgetState(id, ProductionGraphStateKeys.CONFIG_STATE);

  const showTotal = useMemo(() => {
    const showGroup = configState?.find((group) => group.id === ProductionGraphFields.GROUP_SHOW);
    const totalField = showGroup?.fields?.find((field) => field.id === ProductionGraphFields.TOTAL);
    return totalField?.visible;
  }, [configState]);

  const series = useMemo(
    () => [
      {
        animation: false,
        data: data?.timeSeriesData?.map(({ date, value }) => ({
          x: new Date(date).getTime(),
          y: value,
        })),
        tooltipEnabled: false,
      },
    ],
    [data],
  );

  const xAxisLabelFormatter = (chartObj) => {
    const xValue = chartObj.value;
    const series = chartObj.chart.series[0];

    // We need to display the corresponding Y value with the X axis labels
    // The X Axis labels aren't guaranteed to be on a valid X value - find the closest valid x value
    const closestXValue = series.xData.reduce((prev, curr) =>
      Math.abs(curr - xValue) < Math.abs(prev - xValue) ? curr : prev,
    );
    // Get the index of the closest valid X value
    const xIndex = series.xData.findIndex((x) => x === closestXValue);
    // Use the index of the closes valid X value to get the corresponding Y value
    const yValue = getDisplayValue(series.yData[xIndex]);
    // Format X value for display
    const date = dayjs(xValue).format('MM/DD');
    const xlabel = chartObj.chart.series[0].xAxis;
    const tickPositions = chartObj.chart.series[0].xAxis.tickPositions;
    if (tickPositions.length > 10) {
      xlabel.options.labels.rotation = xLabelRotation;
    }
    // Highchars label formatter doesn't entirely respect markup - add in the line break to render properly
    return renderToString(
      <>
        <XAxisDateLabel style={{ color: theme.createReport.widget.productionGraph.labelColor }}>
          {date}
        </XAxisDateLabel>
        <br />
        <XAxisValueLabel
          style={{ color: theme.createReport.widget.productionGraph.valueLabelColor }}
        >
          {yValue}
        </XAxisValueLabel>
      </>,
    );
  };

  const yAxisLabelFormatter = (chartObj) => {
    return getDisplayValue(chartObj.value, true);
  };

  if (!data?.timeSeriesData || isEmpty(data.timeSeriesData)) {
    return <NoDataAvailable />;
  }

  return (
    <ProductionGraphContainer>
      <ProductionGraphHeader>
        <ProductionTotal>
          {showTotal && (
            <>
              {t('production_graph.total', 'Total: ')}
              <span>
                {getDisplayValue(data?.aggregateValue)} {data?.units}
              </span>
            </>
          )}
        </ProductionTotal>
        <ProductionChartLabel>
          {t('production_graph.yLabel', 'Actual Production ')}
          <span>({data?.units})</span>
        </ProductionChartLabel>
      </ProductionGraphHeader>
      <ProductionGraphChart>
        <Chart
          height={140}
          noDataLabel={t('production_graph.no_data_label', 'No Data')}
          recreateOnUpdate={true}
          series={series}
          type={ChartType.COLUMN}
          xAxisType={'datetime'}
          xAxisLabelFormatter={xAxisLabelFormatter}
          yAxisLabelFormatter={yAxisLabelFormatter}
          xAxisTickmarkPlacement={AxisTickPlacement.ON}
          yAxisOffset={40}
          xAxisLabelsRotation={0}
          xAxisLabelsStep={1}
          yAxisOpposite
          disableSideMargin
        />
      </ProductionGraphChart>
    </ProductionGraphContainer>
  );
});

ProductionGraphWidget.propTypes = {
  data: PropTypes.instanceOf(Object),
};
