import { Capability, PermissionScope, TableFilterTypes } from '@ge/models/constants';

const MAX_FILTERED_VALUES = 1000;

export const AnomaliesColumns = {
  GROUP_SELECTED: 'group-selected',
  SELECTED: 'selected',
  // Assets
  GROUP_ASSETS: 'group-case-asset',
  TYPE: 'type',
  SITE: 'site',
  ASSET: 'asset',

  // ANOMALIES
  GROUP_ANOMALIES: 'group-anomalies',
  COMPONENT: 'component',
  STATUS: 'status',
  ID: 'id',
  DESCRIPTION: 'description',
  PRIORITY: 'priority',

  // NOTES
  NOTES: 'notes',
  GROUP_NOTES: 'group-notes',

  // POSSIBLE ROOT CAUSE - commenting for MVP2 - US493771
  //GROUP_POSSIBLE_ROOT_CAUSES: 'group-possible-root-causes',
  //POSSIBLE_ROOT_CAUSES: 'possible-root-causes',

  // TROUBLESHOOTING
  GROUP_TROUBLESHOOTING: 'group-troubleshooting',
  TROUBLESHOOTING: 'troubleshooting',

  // DUE DATE - commenting for MVP2 - US493771
  //GROUP_DUE_DATE: 'group-due-date',
  //DUE_DATE: 'due-date',

  // TASKS
  GROUP_TASKS: 'group-tasks',
  LINKED: 'linked',
  ESCALATED: 'escalated',
  NEW_TASK: 'newTask',
  TASK_DESCRIPTION: 'taskDescription',

  // Status Detail
  GROUP_STATUS_DETAIL: 'group-status-detail',
  STATUS_DETAIL: 'statusDetail',

  // LAST FLAGGED
  GROUP_LAST_FLAGGED: 'group-last-flagged',
  LAST_FLAGGED: 'lastFlagged',

  // START DATE
  GROUP_START_DATE: 'group-start-date',
  START_DATE: 'startDate',

  // CREATE DATE
  GROUP_CREATE_DATE: 'group-create-date',
  CREATE_DATE: 'createDate',
  // DUE DATE
  GROUP_DUE_DATE: 'group-due-date',
  DUE_DATE: 'dueDate',

  // FIXED DATE
  GROUP_FIXED_DATE: 'group-fixed-date',
  FIXED_DATE: 'fixed',

  // Serial Number
  GROUP_SERIAL_NUMBER: 'group-serial-number',
  SERIAL_NUMBER: 'serialNumber',

  // Asset Make
  GROUP_ASSET_MAKE: 'group-asset-make',
  ASSET_MAKE: 'assetMake',

  GROUP_ASSET_MODEL: 'group-asset-model',
  ASSET_MODEL: 'assetModel',

  // Customer
  GROUP_CUSTOMER: 'group-customer',
  CUSTOMER: 'customer',

  // Country
  GROUP_COUNTRY: 'group-country',
  COUNTRY: 'country',

  // Analytic Source
  GROUP_ANALYTIC_SOURCE: 'group-analytic-source',
  ANALYTIC_SOURCE: 'analyticSource',

  // Source
  GROUP_SOURCE: 'group-source',
  SOURCE: 'source',

  GROUP_CASE_DETAIL: 'group-case-detail',
  CASE_DETAIL: 'caseDetail',
};

// Define all available columns in the case table.
export const AnomaliesColumnDefs = {
  [AnomaliesColumns.GROUP_SELECTED]: {
    locked: true,
    minWidth: '75px',
    cols: {
      [AnomaliesColumns.SELECTED]: {
        labels: [
          {
            a11yKey: 'table.check_box',
            a11yDefault: '',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [AnomaliesColumns.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.assets',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [AnomaliesColumns.TYPE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.type',
            a11yDefault: 'Type',
            sortValue: AnomaliesColumns.TYPE,
          },
        ],
      },
      [AnomaliesColumns.SITE]: {
        minWidth: '100px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        locked: true,
        labels: [
          {
            a11yKey: 'table.site',
            a11yDefault: 'Site',
            sortValue: `${AnomaliesColumns.SITE}.description`,
          },
        ],
        cell: {
          entity: true,
        },
      },
      [AnomaliesColumns.ASSET]: {
        minWidth: '75px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        locked: true,
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: `${AnomaliesColumns.ASSET}.description`,
          },
        ],
        cell: {
          entity: true,
        },
      },
    },
  },
  [AnomaliesColumns.GROUP_ANOMALIES]: {
    labels: [
      {
        a11yKey: 'table.anomalies',
        a11yDefault: 'ANOMALIES',
      },
    ],
    cols: {
      [AnomaliesColumns.COMPONENT]: {
        minWidth: '74px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.component',
            a11yDefault: 'COMPONENT',
            sortValue: AnomaliesColumns.COMPONENT,
          },
        ],
      },
      [AnomaliesColumns.STATUS]: {
        minWidth: '65px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.status',
            a11yDefault: 'STATUS',
            sortValue: AnomaliesColumns.STATUS,
          },
        ],
      },
      [AnomaliesColumns.ID]: {
        minWidth: '50px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.id',
            a11yDefault: 'ID',
            sortValue: AnomaliesColumns.ID,
          },
        ],
      },
      [AnomaliesColumns.DESCRIPTION]: {
        minWidth: '194px',
        align: 'left',
        locked: true,
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.title',
            a11yDefault: 'Title',
            sortValue: `${AnomaliesColumns.DESCRIPTION}.description`,
          },
        ],
      },
      [AnomaliesColumns.PRIORITY]: {
        minWidth: '52px',
        locked: true,
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.priority',
            a11yDefault: 'Priority',
            sortValue: `${AnomaliesColumns.PRIORITY}.value`,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_NOTES]: {
    cols: {
      [AnomaliesColumns.NOTES]: {
        capabilities: [
          { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
        ],
        minWidth: '52px',
        locked: false,
        filterType: TableFilterTypes.NUMBER,
        labels: [
          {
            a11yKey: 'table.notes',
            a11yDefault: 'Notes',
            sortValue: `${AnomaliesColumns.NOTES}.description`,
          },
        ],
      },
    },
  },
  // Commenting 'Possible Root Causes' column for MVP2 - US493771
  /* [AnomaliesColumns.GROUP_POSSIBLE_ROOT_CAUSES]: {
    cols: {
      [AnomaliesColumns.POSSIBLE_ROOT_CAUSES]: {
        minWidth: '186px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.possible_root_causes',
            a11yDefault: 'Possible Root Causes',
            sortValue: 'possibleRootCauses',
          },
        ],
        cell: {
          maxWidth: 'none',
        },
      },
    },
  }, */
  [AnomaliesColumns.GROUP_TROUBLESHOOTING]: {
    cols: {
      [AnomaliesColumns.TROUBLESHOOTING]: {
        capabilities: [{ capability: Capability.DATA_EXPLORER, scopes: [PermissionScope.VIEW] }],
        labels: [
          {
            a11yKey: 'table.troubleshooting',
            a11yDefault: 'Trouble\nshooting',
            sortValue: 'troubleshooting',
          },
        ],
        minWidth: '46px',
      },
    },
  },
  // Commenting 'Due Date' column for MVP2 - US493771
  /* [AnomaliesColumns.GROUP_DUE_DATE]: {
    cols: {
      [AnomaliesColumns.DUE_DATE]: {
        minWidth: '52px',
        filterType: TableFilterTypes.DATE,
        labels: [
          {
            a11yKey: 'table.due_date',
            a11yDefault: 'Due Date',
            sortValue: 'due',
          },
        ],
      },
    },
  }, */
  [AnomaliesColumns.GROUP_TASKS]: {
    labels: [
      {
        a11yKey: 'table.tasks',
        a11yDefault: 'TASKS',
      },
    ],
    cols: {
      [AnomaliesColumns.ESCALATED]: {
        capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
        labels: [
          {
            a11yKey: 'table.escalated',
            a11yDefault: 'Escalated',
            sortValue: `${AnomaliesColumns.ESCALATED}.totalTaskCount`,
          },
        ],
        minWidth: '52px',
        filterType: TableFilterTypes.PROGRESSIVE_MULTI_SELECT,
        filterTypeDefs: {
          titles: [
            {
              a11yKey: 'table.filter_title_escalated',
              a11yDefault: 'ESCALATED',
            },
          ],
          fields: [
            {
              key: 'task_status',
              a11yKey: 'table.status',
              a11yDefault: 'Status',
            },
            {
              key: 'task_work_scope',
              a11yKey: 'table.work_scope',
              a11yDefault: 'Work Scope',
            },
          ],
        },
      },
      [AnomaliesColumns.NEW_TASK]: {
        capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.CREATE] }],
        labels: [
          {
            a11yKey: 'table.new_task',
            a11yDefault: 'New',
          },
        ],
      },
      [AnomaliesColumns.TASK_DESCRIPTION]: {
        capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.taskDescription',
            a11yDefault: 'Task description',
            sortValue: 'taskDescription',
          },
        ],
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [AnomaliesColumns.GROUP_STATUS_DETAIL]: {
    cols: {
      [AnomaliesColumns.STATUS_DETAIL]: {
        labels: [
          {
            a11yKey: 'table.statusDetail',
            a11yDefault: 'Status Detail',
            sortValue: `${AnomaliesColumns.STATUS_DETAIL}.description`,
          },
        ],
        cell: {
          entity: true,
        },
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [AnomaliesColumns.GROUP_LAST_FLAGGED]: {
    cols: {
      [AnomaliesColumns.LAST_FLAGGED]: {
        minWidth: '52px',
        filterType: TableFilterTypes.DATE,
        labels: [
          {
            a11yKey: 'table.last_flagged',
            a11yDefault: 'Last flagged',
            sortValue: AnomaliesColumns.LAST_FLAGGED,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_CREATE_DATE]: {
    cols: {
      [AnomaliesColumns.CREATE_DATE]: {
        minWidth: '52px',
        filterType: TableFilterTypes.DATE,
        labels: [
          {
            a11yKey: 'table.create_date',
            a11yDefault: 'Created Date',
            sortValue: AnomaliesColumns.CREATE_DATE,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_FIXED_DATE]: {
    cols: {
      [AnomaliesColumns.FIXED_DATE]: {
        minWidth: '52px',
        filterType: TableFilterTypes.DATE,
        labels: [
          {
            a11yKey: 'table.fixed_date',
            a11yDefault: 'Fixed Date',
            sortValue: AnomaliesColumns.FIXED_DATE,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_SERIAL_NUMBER]: {
    cols: {
      [AnomaliesColumns.SERIAL_NUMBER]: {
        align: 'left',
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.serial_number',
            a11yDefault: 'Serial #',
            sortValue: AnomaliesColumns.SERIAL_NUMBER,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_ASSET_MAKE]: {
    cols: {
      [AnomaliesColumns.ASSET_MAKE]: {
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.asset_make',
            a11yDefault: 'Asset Make',
            sortValue: AnomaliesColumns.ASSET_MAKE,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_ASSET_MODEL]: {
    cols: {
      [AnomaliesColumns.ASSET_MODEL]: {
        minWidth: '45px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.asset_model',
            a11yDefault: 'Asset Model',
            sortValue: AnomaliesColumns.ASSET_MODEL,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_CUSTOMER]: {
    cols: {
      [AnomaliesColumns.CUSTOMER]: {
        minWidth: '95px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.customer',
            a11yDefault: 'Customer',
            sortValue: AnomaliesColumns.CUSTOMER,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_COUNTRY]: {
    cols: {
      [AnomaliesColumns.COUNTRY]: {
        minWidth: '85px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.country',
            a11yDefault: 'Country',
            sortValue: AnomaliesColumns.COUNTRY,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_ANALYTIC_SOURCE]: {
    cols: {
      [AnomaliesColumns.ANALYTIC_SOURCE]: {
        minWidth: '85px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.analyticSource',
            a11yDefault: 'Analytic Source',
            sortValue: AnomaliesColumns.ANALYTIC_SOURCE,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_SOURCE]: {
    cols: {
      [AnomaliesColumns.SOURCE]: {
        minWidth: '85px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.source',
            a11yDefault: 'Source',
            sortValue: AnomaliesColumns.SOURCE,
          },
        ],
      },
    },
  },
  [AnomaliesColumns.GROUP_CASE_DETAIL]: {
    fixedRight: true,
    cols: {
      [AnomaliesColumns.CASE_DETAIL]: {
        fixedRight: true,
        labels: [
          {
            a11yKey: 'table.case_detail',
            a11yDefault: 'Case Detail',
          },
        ],
        cell: {
          zeroPadding: true,
        },
      },
    },
  },
};
