/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import { action, thunk } from 'easy-peasy';

import {
  getDataForReportGeneration,
  // getReport,
  // updateReport,
  // generateReportPdf,
  // getReportPdfByJobId,
  // fetchReportPdfByJsonData,
} from '../services/report';

// Define initial state
const defaultReportState = {
  id: null,
  report: null,
  pdf: null,
};

// Actions
const reportActions = {
  resetProfile: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, defaultReportState);
  }),
  resetReportData: action((state) => {
    state.report = null;
  }),

  initializeReportData: thunk(async (actions, payload) => {
    const { jobId } = payload;
    const options = {};
    const { result } = await getDataForReportGeneration(jobId, options);
    // const reportVersionData = null;
    // actions.updateDataForReportGeneration({ id: jobId, data: result, reportVersionData: reportVersionData });
    actions.updateDataForReportGeneration({ id: jobId, data: result });
    return result;
  }),
  // getReportPdfByJsonData: thunk(async (state, payload) => {
  //   const options = {
  //     'Content-Type': 'blob',
  //     responseType: 'arraybuffer',
  //   };
  //   const { inspectionType, jsonMetaData } = payload;
  //   const results = await fetchReportPdfByJsonData(inspectionType, jsonMetaData, options);
  //   return new Promise((resolve) => {
  //     resolve(results);
  //   });
  // }),

  // fetchUserReportData: thunk(async (actions, payload) => {
  //   const { jobId } = payload;
  //   const options = {};
  //   const { result } = await getReport(jobId, options);

  //   // const reportVersionData = {
  //   //   is_complete : result.data.attributes.is_complete,
  //   //   jobstable_lid : result.data.attributes.jobstable_lid,
  //   //   report_loc : result.data.attributes.report_loc,
  //   //   revised_by : result.data.attributes.revised_by,
  //   //   revision_date : result.data.attributes.revision_date,
  //   //   revision_number : result.data.attributes.revision_number,
  //   // }

  //   actions.updateDataForReportGeneration({
  //     id: jobId,
  //     data: JSON.parse(result.data.attributes.json_metadata),
  //     // reportVersionData: reportVersionData
  //   });
  // }),

  // updateServerReport: thunk(async (actions, payload) => {
  //   const {
  //     jobId, revised_by, revision_date, json_metadata,
  //   } = payload;
  //   const options = {};
  //   const result = await updateReport(
  //     jobId,
  //     {
  //       revised_by,
  //       revision_date,
  //       json_metadata: JSON.stringify(json_metadata),
  //     },
  //     options,
  //   );
  //   return result;
  // }),

  updateDataForReportGeneration: action((state, payload) => {
    const { id, data } = payload;
    state.id = id;
    state.report = {
      ...data,
    };
    // state.reportVersionData = reportVersionData;
    // state.report['INSPECTION DATA']['Ambient Temperature (C)'] = state.report['INSPECTION DATA']['Ambient Temperature (°C)'];
    // delete state.report['INSPECTION DATA']['Ambient Temperature (°C)'];
    delete state.report['TURBINE MASTER DATA']['Turbine Serial Number'];
  }),

  updateParameterForReportGeneration: action((state, payload) => {
    const { id, data } = payload;
    if (id !== undefined) state.id = id;

    state.report = {
      ...data,
    };
  }),

  updatePdf: action((state, payload) => {
    const { pdf } = payload;
    state.pdf = pdf;
  }),
};

// Listeners
const reportListeners = {};

// Computed values
const reportComputed = {};

const reportModel = {
  ...defaultReportState,
  ...reportActions,
  ...reportListeners,
  ...reportComputed,
};

export default reportModel;
