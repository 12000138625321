import { TaskStatus } from '@ge/models';
import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

/**
 * Fetch site manager task schedule
 */
export const fetchScheduledTasks = ({ type, ids } = {}) => {
  let params = { type, ids };
  if (Array.isArray(ids)) {
    params = { ...params, ids: ids.join(',') };
  }
  return request.get('/site-manager/task-schedule', { params });
};

/**
 * Fetch site manager task backlog
 */
export const fetchBacklogTasks = ({ type, ids } = {}) => {
  let params = { type, ids };
  if (Array.isArray(ids)) {
    params = { ...params, ids: ids.join(',') };
  }
  return request.get('/site-manager/task-backlog', { params });
};

/**
 * Fetch all tasks
 */
export const fetchAllTasks = ({
  startDate,
  endDate,
  pageIdx,
  pageSize,
  siteIds,
  assetIds,
  serviceGroupIds,
  status = [],
  getBundledTasks = false,
  taskStatus = ['unscheduled', 'scheduled', 'completed'],
}) => {
  const params =
    startDate && endDate
      ? {
          startDate,
          endDate,
          pageIdx,
          pageSize,
          siteIds,
          assetIds,
          serviceGroupIds,
          status: status.join(','),
          getBundledTasks,
          taskStatus,
        }
      : { pageIdx, pageSize, siteIds, assetIds, serviceGroupIds, status: status.join(',') };
  return request.post('/mwp/task-pages', params, { baseURL: BASE_URL });
};

/**
 * Fetch tasks by status
 */
export const fetchByStatus = ({ status = [], pageIdx, pageSize, siteIds }) => {
  const params = { status: status.join(','), pageIdx, pageSize, siteIds };
  return request.post('/mwp/task-pages', params, { baseURL: BASE_URL });
};

/**
 * Create a task
 */
export const createTask = (task) => request.post('/mwp/tasks', task, { baseURL: BASE_URL });

// TODO: reconcile this with crews services that got added around the same time, once those are fully implemented
/**
 * Fetch crew
 */
export const fetchCrew = ({ crewId }) =>
  request.get(`/mwp/erp-crews/${crewId}`, { baseURL: BASE_URL });

/**
 * Fetch ERP
 */
export const fetchErpRequest = ({ taskId }) =>
  request.get(`/mwp/tasks/${taskId}/erp-request`, { baseURL: BASE_URL });

/**
 * Fetch ERP
 */
export const fetchErpRequestStatus = ({ taskIds }) =>
  request.post(`/mwp/tasks/erp-request/status`, { taskIds }, { baseURL: BASE_URL });

/**
 * Fetch ERP template
 */
export const fetchErpTemplate = () => request.get('/mwp/erp-template', { baseURL: BASE_URL });

/**
 * Fetch ERP template
 */
export const fetchErpTranslations = ({ language }) =>
  request.get(`/mwp/erp-translations/${language}`, { baseURL: BASE_URL });

/**
 * Create ERP
 */
export const createErpRequest = ({ erpRequest, taskId }) =>
  request.post(`/mwp/tasks/${taskId}/erp-request`, erpRequest, { baseURL: BASE_URL });

export const createBulkErpRequest = ({ bulkErpRequest }) =>
  request.post(`/mwp/tasks/bulk-erp-request`, bulkErpRequest, { baseURL: BASE_URL });

/**
 * Fetch ERP notifications
 */
export const fetchErpNotifications = () =>
  request.get(`/mwp/erp-notifications`, { baseURL: BASE_URL });

/**
 * Edit a task
 */
// TODO - EDIT
export const editTask = (task) =>
  new Promise((resolve, reject) => {
    if (Math.random() <= 0.05) {
      reject('Task edit failed');
    }
    resolve(task);
  });

/**
 * Bulk schedule tasks
 */
export const bulkScheduleTask = (tasks) =>
  new Promise((resolve, reject) => {
    if (Math.random() <= 0.05) {
      reject('Bulk task schedule failed');
    }
    resolve(tasks);
  });

/**
 * Close a task
 */
// TODO - CLOSE
export const closeTask = (task) =>
  new Promise((resolve, reject) => {
    if (Math.random() <= 0.05) {
      reject('Task close failed');
    }
    task.status = TaskStatus.CLOSED;
    resolve(task);
  });

/**
 * Delete a task
 */
// TODO - DELETE
export const deleteTask = (task) =>
  new Promise((resolve, reject) => {
    if (Math.random() <= 0.05) {
      reject('Task delete failed');
    }
    resolve(task);
  });

/**
 * Fetch site manager technicians
 */
export const fetchTechniciansWithHours = () =>
  request.get('/site-manager/technicians', { baseURL: BASE_URL });

export const forceMocks = () => {};

/**
 * Dynamically load the mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/site-manager.mocks')
    .then((mocks) => mocks.registerMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });
