import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { fetchCommandBlockStatus } from '../services/commands';

import { Config } from './config';

export const useAssetCommandBlockStatus = (assets, isActive = true, enableSafetyCheck = true) => {
  const assetIds = assets.map((elem) => elem.id).join(',');
  const { data, error, isLoading } = useQuery(
    [QueryKey.ASSET_COMMAND_BLOCK_STATUS, assetIds, enableSafetyCheck],
    () => fetchCommandBlockStatus(assets[0]?.site?.id, assetIds),
    {
      enabled: isActive && enableSafetyCheck,
      refetchInterval: Config.REFRESH.realTime,
      refetchOnWindowFocus: true,
    },
  );
  return useMemo(() => {
    return { data, error, isLoading };
  }, [data, error, isLoading]);
};
