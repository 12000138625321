import clone from 'ramda/src/clone';
import mergeDeepWith from 'ramda/src/mergeDeepWith';
import { useCallback, useState } from 'react';

import { CreateModes } from '@ge/feat-reporting/models/modes';
import { DateRange } from '@ge/models/constants';

import { InputStateKeys } from '../components/sidebar/sidebar';

const defaultState = {
  timeRange: DateRange.CURRENT_DAY,
};

export const useReportState = () => {
  const [attachments, setAttachments] = useState([]); // TODO: Migrate into the new pattern.
  const [loadingWidgets, setLoadingWidgets] = useState(new Set());
  const [hiddenWidgets, setHiddenWidgets] = useState(new Set());

  /**
   * Create view mode state.
   * This state drives edit/viewing parameters and showing/hidding internal widget content.
   */
  const [createMode, setCreateMode] = useState(CreateModes.EDIT);

  /**
   * Track if this specific report has been sent
   */
  const [hasReportBeenSent, setHasReportBeenSent] = useState(false);

  /**
   * Dynamic editable report state.
   * This is ultimately by components for data/rendering.
   * This state is intended to drive dynamic functional values.
   */
  const [reportState, setReportState] = useState();

  /**
   * Dynamic immutable report state.
   * This state is initalized by the template response and it intended
   * to be the source of truth for reseting / restoring default values.
   */
  const [initalReportState, setInitialReportState] = useState();

  /**
   * Function to keep track of which widgets are in a 'loading' state.
   * This is used to prevent PDF generation from occurring before all widgets have finished loading.
   */
  const trackLoadingWidget = useCallback(
    (widgetName, isLoading) => {
      if (isLoading) {
        setLoadingWidgets((prevState) => prevState.add(widgetName));
      } else {
        setLoadingWidgets((prevState) => {
          if (prevState.delete(widgetName)) {
            return new Set([...prevState]);
          }
          return prevState;
        });
      }
    },
    [setLoadingWidgets],
  );

  /**
   * Function to keep track of which widgets are currently hidden.
   */
  const setWidgetVisibility = useCallback(
    (widgetId, hidden) => {
      if (hidden) {
        setHiddenWidgets((prevState) => {
          if (prevState.has(widgetId)) {
            return prevState;
          }
          setHasReportBeenSent(false);
          return new Set([...prevState.add(widgetId)]);
        });
      } else {
        setHiddenWidgets((prevState) => {
          if (prevState.delete(widgetId)) {
            setHasReportBeenSent(false);
            return new Set([...prevState]);
          }
          return prevState;
        });
      }
    },
    [setHiddenWidgets, setHasReportBeenSent],
  );

  /**
   * Function to holistically intialize report state.
   * This is comprised of two states: A 'mostly' immutable deep clone to maintain service defaults
   * and an active editiable state.
   */
  const initReportState = useCallback(
    (initial, force = false) => {
      // TODO: revisit if this is the right place to merge defaults
      const state = mergeDeepWith(
        (_initial, _default) => _initial ?? _default,
        initial,
        defaultState,
      );

      if (force || !initalReportState || initalReportState.id !== initial.id) {
        setInitialReportState(clone(state));
      }
      if (force || !reportState || reportState.id !== initial.id) {
        // Retain the stateful report parameters that were previously modified, if present
        if (reportState) {
          for (const stateKey of Object.values(InputStateKeys)) {
            const stateValue = reportState[stateKey];
            if (stateValue) {
              state[stateKey] = stateValue;
            }
          }
        }
        setReportState(state);
        setHasReportBeenSent(false);
        setHiddenWidgets(new Set([...(state?.hiddenWidgetIds ?? [])]));
      }
    },
    [
      reportState,
      initalReportState,
      setHasReportBeenSent,
      setReportState,
      setHiddenWidgets,
      setInitialReportState,
    ],
  );

  /**
   * Function to update top level parameter state.
   */
  const setParameters = useCallback(
    (key, value) => {
      setReportState((prevState) => ({ ...prevState, [key]: value }));
      // if parameters have been updated, this is a new report - reset hasReportBeenSent
      setHasReportBeenSent(false);
    },
    [setHasReportBeenSent, setReportState],
  );

  /**
   * This callback enables the ability to dynamic set the value of any key in the desired widget.
   * NOTE: Because this is re-setting the base state of the report, this will trigger any useEffect
   * dependencies on reportState. It might be worth optimizing this so widget state and report state
   * are managed separately to better control repaints.
   *
   * @param widgetId identifies the widget.
   * @param key the key of the value to be updated.
   * @param value is the new incoming value to be set.
   */
  const setWidgetState = useCallback(
    ({ widgetId, key, value }) => {
      setReportState((prevState) => {
        const newState = { ...prevState };
        newState.widgetState = newState.widgetState || {};
        newState.widgetState[widgetId] = newState.widgetState[widgetId] || {};
        newState.widgetState[widgetId][key] = value;

        return newState;
      });

      // Persist the initial value for each `widgetId` + `key` combination to enable change detection
      setInitialReportState((prevState) => {
        const widgetStateObject = prevState.widgetState?.[widgetId];
        if (widgetStateObject && Object.prototype.hasOwnProperty.call(widgetStateObject, key)) {
          return prevState;
        }

        const newState = { ...prevState };
        newState.widgetState = newState.widgetState || {};
        newState.widgetState[widgetId] = newState.widgetState[widgetId] || {};
        newState.widgetState[widgetId][key] = clone(value);

        return newState;
      });

      setHasReportBeenSent(false);
    },
    [setReportState, setInitialReportState, setHasReportBeenSent],
  );

  const getWidgetState = useCallback(
    (id, key) => {
      return reportState?.widgetState?.[id]?.[key];
    },
    [reportState],
  );

  const getInitialWidgetState = useCallback(
    (id, key) => {
      const widgetState = initalReportState.widgetState?.[id];
      if (key) {
        return widgetState?.[key];
      }
      return widgetState;
    },
    [initalReportState],
  );

  /**
   * This callback, like `setWidgetState`, enables storing arbitrary key/value pairs for any widget.
   * Use this interface for any stateful values that are only applicable within the context of the
   * widget instance itself; e.g., row selections within table-type widgets.
   * NOTE: Because this is re-setting the base state of the report, this will trigger any useEffect
   * dependencies on reportState. It might be worth optimizing this so widget state and report state
   * are managed separately to better control repaints.
   *
   * @param widgetId identifies the widget.
   * @param key the key of the value to be updated.
   * @param value is the new incoming value to be set.
   */
  const setWidgetLocalState = useCallback(
    ({ widgetId, key, value }) => {
      setReportState((prevState) => {
        const newState = { ...prevState };
        newState.widgetLocalState = newState.widgetLocalState || {};
        newState.widgetLocalState[widgetId] = newState.widgetLocalState[widgetId] || {};
        newState.widgetLocalState[widgetId][key] = value;

        return newState;
      });
      setHasReportBeenSent(false);
    },
    [setReportState, setHasReportBeenSent],
  );

  const getWidgetLocalState = useCallback(
    (id, key) => {
      return reportState.widgetLocalState?.[id]?.[key];
    },
    [reportState],
  );

  /**
   * Function to revert user state changes to widget configuration only.
   */
  const resetWidgetState = useCallback(() => {
    setReportState((prevState) => {
      const newState = { ...prevState };
      newState.widgetState = clone(initalReportState.widgetState);

      return newState;
    });
    setHiddenWidgets(new Set([...(initalReportState?.hiddenWidgetIds ?? [])]));
    setAttachments([]);
  }, [initalReportState, setReportState, setHiddenWidgets, setAttachments]);

  /**
   * Function to revert user state changes.
   */
  const resetReportState = useCallback(() => {
    // This may be too generic/ wholesale.
    // Might need to get more granular about what gets reset
    // depending on UX but need more clarification.
    // The concern is this will also cancel any widget updates... but that might be ok.
    setReportState(clone(initalReportState));
    setHiddenWidgets(new Set([...(initalReportState?.hiddenWidgetIds ?? [])]));
    setLoadingWidgets(new Set());
    setHasReportBeenSent(false);
    setAttachments([]);
  }, [
    setReportState,
    setHasReportBeenSent,
    setHiddenWidgets,
    setLoadingWidgets,
    setAttachments,
    initalReportState,
  ]);

  return {
    // State Values
    attachments,
    createMode,
    hasReportBeenSent,
    reportState,
    initalReportState,
    loadingWidgets,
    hiddenWidgets,

    // Initializers
    initReportState,

    // Setters
    setAttachments,
    setCreateMode,
    setHasReportBeenSent,
    setParameters,
    setWidgetState,
    setWidgetLocalState,
    setWidgetVisibility,

    // Getters
    getWidgetState,
    getWidgetLocalState,
    getInitialWidgetState,

    // Helpers
    resetReportState,
    resetWidgetState,
    trackLoadingWidget,
  };
};
