import { ChartType } from './chart-type';

export const areaChartOptions = {
  series: [
    {
      fillOpacity: 0.1,
      marker: {
        radius: 1,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      type: ChartType.AREA,
    },
  ],
};
