import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
//import { Severity } from '@ge/components/severity';
import { EntityType } from '@ge/models/constants';
import { getAssetIcon } from '@ge/shared/util/asset-icon';

const EntityInfo = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
  &.dialog-header {
    span {
      max-width: calc(47% - 10px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      &.full-width {
        max-width: 94%;
      }
      &.split {
        margin-left: 3px;
        margin-right: 4px !important;
      }
    }
  }
  &.header-caseinfo {
    span {
      max-width: calc(45% - 10px);
      &.description {
        font-size: 14px;
        color: ${(props) => props.theme.entityDetails.headerBadgeFontColor} !important;
        line-height: 1.5;
        max-width: 90%;
        white-space: normal;
      }
    }
  }
`;

const EntityInfoIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.entityDetails.notes.entityInfo,
}))`
  margin-right: 5px;
  vertical-align: top;
`;

const EntityDescription = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 4px;
  svg {
    margin-right: 5px;
  }
`;

const EntityDescriptionIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.theme.entityDetails.notes.entityDescription,
}))`
  margin-right: 5px;
  vertical-align: top;
`;

export const EntityDialogHeader = ({ entityDetails }) => {
  const entityDialogDescription = useMemo(() => {
    return (
      <>
        {entityDetails?.caseInfoDescription && <br />}
        <EntityDescriptionIcon icon={entityDetails?.entityDescriptionIcon} />
        {/* <Severity level={entityDetails?.severity} /> */}
        {entityDetails?.caseInfoDescription ? (
          <span className="description">{entityDetails?.caseInfoDescription}</span>
        ) : (
          entityDetails?.entityDescription
        )}
      </>
    );
  }, [entityDetails]);

  const getIconType = useMemo(() => {
    switch (entityDetails?.siteType) {
      case EntityType.WIND_SITE:
      case EntityType.SITE:
        return Icons.SITE;
      case EntityType.STORAGE_SITE:
      case EntityType.INVERTER:
        return Icons.STORAGE;
      case EntityType.SOLAR_SITE:
        return Icons.SOLAR;
      case EntityType.HYBRID_SITE:
        return Icons.HYBRID;
      default:
        return Icons.SITE;
    }
  }, [entityDetails]);

  return (
    <>
      <EntityInfo
        className={`${'dialog-header'} ${entityDetails?.caseInfoDescription && 'header-caseinfo'}`}
      >
        {entityDetails?.siteName ? (
          <>
            <EntityInfoIcon icon={getIconType} />
            <span className={`${!entityDetails?.assetName && 'full-width'}`}>
              {entityDetails?.siteName}
            </span>
            {entityDetails?.assetName ? <span className="split">{'/'}</span> : ''}
          </>
        ) : (
          ''
        )}
        {entityDetails?.assetName ? (
          <>
            <EntityInfoIcon icon={getAssetIcon(entityDetails?.assetType)} />
            <span>{entityDetails?.assetName}</span>
          </>
        ) : (
          ''
        )}
        {entityDetails?.caseInfoDescription && entityDialogDescription}
      </EntityInfo>
      {entityDetails?.entityDescription && (
        <EntityDescription>{entityDialogDescription}</EntityDescription>
      )}
    </>
  );
};

EntityDialogHeader.propTypes = {
  entityDetails: PropTypes.object,
};
