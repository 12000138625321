import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { dedupArray } from '@ge/shared/util/general';
import { removeFalsyVals } from '@ge/util/array-utils';

import { fetchCaseTemplate, fetchCaseTemplateRootCause } from '../services/case-template';

export const useCaseTemplateIds = ({ caseTemplateId, childCases }) => {
  return useMemo(() => {
    const childCaseTemplateIds = childCases?.map((v) => v.caseTemplateId) ?? [];
    const caseTemplateIds = [caseTemplateId, ...childCaseTemplateIds];
    return removeFalsyVals(dedupArray(caseTemplateIds))?.join(',') ?? '';
  }, [caseTemplateId, childCases]);
};

export const useCaseTemplate = ({ caseTemplateId }) => {
  const { data, isLoading, ...queryRest } = useQuery(
    [QueryKey.CASE_TEMPLATE, caseTemplateId],
    () => fetchCaseTemplate(caseTemplateId),
    {
      enabled: Boolean(caseTemplateId),
      ...Config.EXECUTE_ONCE,
    },
  );

  return {
    data: data?.caseTemplate,
    isLoading,
    ...queryRest,
  };
};

export const useCaseTemplateRootCause = (issue = {}) => {
  const caseTemplateIds = useCaseTemplateIds(issue);

  const { data, isLoading } = useQuery(
    [QueryKey.CASE_TEMPLATE, caseTemplateIds],
    () => fetchCaseTemplateRootCause(caseTemplateIds),
    {
      enabled: Boolean(caseTemplateIds),
      ...Config.EXECUTE_ONCE,
    },
  );

  return {
    data: data ?? [],
    isLoading,
  };
};

export default useCaseTemplate;
