import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useCallback, useState, useRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { PageContainer } from '@ge/components/containers';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { ToggleButton } from '@ge/components/toggle-button';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import {
  Capability,
  TaskSourceField,
  ManageDefs,
  QueryKey,
  TaskStatus,
  DateTimeFormats,
  Placeholders,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { TaskUploadErrorNotification } from '@ge/shared/components/task-upload-error-notification';
import ImportTaskDialog from '@ge/shared/components/tasks/import-task-dialog';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { UploadInProgressNotification } from '@ge/shared/components/upload-in-progress-notification';
import { WarningMessage } from '@ge/shared/components/warning-message';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { formatHoursAndMinutes } from '@ge/shared/util/time-date';
// import { uniqueVals } from '@ge/util/array-utils';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import ExportTaskTable from '@ge/web-client/src/app/components/entity-details/site-details/table/export-tasks-table';
import ImportTaskButton from '@ge/web-client/src/app/components/entity-details/site-details/table/import-task-button';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';
// import { getTaskTemplate } from '@ge/web-client/src/app/services/task-import';

import TasksTable from '../components/tables/tasks-table';
import TasksTracker from '../components/tables/tasks-tracker';
import { useColumnDefs } from '../data-hooks/use-column-defs';
import { useTasksTable } from '../data-hooks/use-tasks-table';
import { defaultTasksCols, TasksColumns } from '../models/tasks-table-cols';
import { getServiceGroupIds } from '../util/worker-util';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  button {
    align-self: center;
  }
`;

const CaseTaskContainer = styled.div`
  position: relative;
  height: 100%;
`;

const NotificationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Banner = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-25%);
  margin: 110px 0;
`;

const LeftSubNav = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;

  span {
    padding: 3px 5px 3px 0px;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

const SORT_STATE_ID = 'manage-cases-tasks';
const CASES_TASKS = ManageDefs.CASES_TASKS;
const TASKS_TRACKER = ManageDefs.TASKS_TRACKER;
const DEFAULT_TASKS_LOAD = [TaskStatus.SCHEDULED, TaskStatus.UNSCHEDULED];

/**
 * Available sort metrics for Tasks.
 */
export const TaskMetric = {
  NAME: 'task.asset',
  TYPE: 'task.type',
};

const CasesTasks = () => {
  const { t, ready } = useTranslation(['tasks'], {
    useSuspense: false,
  });

  const columnDefinition = useColumnDefs();
  // Table state management
  const {
    updateColumnVisibility,
    setVisibleColumns,
    visibleCols,
    sortDirection,
    sortMetric,
    updateSortMetric,
  } = useColumnState({
    columnDefs: columnDefinition,
    defaultCols: defaultTasksCols,
    defaultSortMetric: TaskMetric.TYPE,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: SORT_STATE_ID,
  });

  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [currentSearch, setCurrentSearch] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedTasksToExport, setSelectedTasksToExport] = useState([]);
  const [showImportTaskModal, setShowImportTaskModal] = useState(false);
  const [taskUploadInProgress, setTaskUploadInProgress] = useState(false);
  const [TaskUpload500Error, setTaskUpload500Error] = useState(false);
  const [taskUploadError, setTaskUploadError] = useState(false);

  const fetchCrewsOnServiceGroup = useStoreActions(
    (state) => state.workers.fetchCrewsOnServiceGroup,
  );
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  const { currentView } = useStoreState((state) => state.view);
  const serviceGroupIds = getServiceGroupIds(sortedServiceGroups, currentView).toString();

  useQuery([QueryKey.CREWSG, { serviceGroupIds }], () =>
    fetchCrewsOnServiceGroup({ serviceGroupIds }),
  );

  const { showTaskDetails, tasksTrackerData, taskWorkers } = useContext(EntityDetailsContext);
  const containerRef = useRef(null);

  const [loadCompletedTasks, setCompletedTasks] = useState(false);
  const [taskStatus, setTasksStatus] = useState(DEFAULT_TASKS_LOAD);
  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.MANAGE_CASES_TASKS);

  const {
    filterDefs,
    filterSequence,
    onChange: setFilterDefs,
    onReset: resetFilterDefs,
  } = useFilterDefs({
    columnDefs: columnDefinition,
    stateId: ManageDefs.CASES_TASKS_FILTER_STATE_ID,
  });

  const {
    data: { data: tasks, filterValues } = {},
    isLoading: isTasksLoading,
    isMax,
  } = useTasksTable({
    filters: filterDefs,
    filterSequence,
    search: currentSearch,
    sortMetric,
    sortDirection,
    status: taskStatus,
  });

  useEffect(() => {
    const menuItem = tasksTrackerData.find(
      (menuFilter) => menuFilter.selected && menuFilter.visible && !menuFilter.defaultView,
    );
    if (menuItem?.filters?.length) {
      menuItem.filters.forEach((ele = {}) => {
        if (ele.min && ele.max) {
          setFilterDefs(ele.name, [ele.min, ele.max]);
        } else {
          setFilterDefs(ele.name, ele.value);
        }
      });
    }
  }, [tasksTrackerData]);

  const toggleButtonHandler = useCallback(() => {
    if (!loadCompletedTasks) {
      setTasksStatus([]);
    } else {
      //setTasksStatus([TaskStatus.UNSCHEDULED, TaskStatus.SCHEDULED]);
      setTasksStatus(DEFAULT_TASKS_LOAD);
    }
    setCompletedTasks(!loadCompletedTasks);
  }, [loadCompletedTasks]);

  // TODO: pull this from somewhere we already have this info

  const { sitesForView } = useStoreState((state) => state.sites);
  const siteIds = useMemo(() => sitesForView.map((s) => s.id), [sitesForView]);

  const { onVisibilityChange: handleColumnChanges } = useManageColumnVisibility({
    subKey: CASES_TASKS,
    featPrefs,
    savePrefs,
    setVisibleColumns,
    updateColumnVisibility,
  });

  const handleTaskSelect = useCallback(
    (_, task) => {
      showTaskDetails(task.id);
    },
    [showTaskDetails],
  );

  const newFieldTask = useCallback(() => {
    setShowNewTaskModal(true);
  }, [setShowNewTaskModal]);

  const closeUploadNotification = useCallback(() => {
    setTaskUploadInProgress(false);
  }, [setTaskUploadInProgress]);

  const closeImportModal = useCallback(() => {
    setShowImportTaskModal(false);
  }, [setShowImportTaskModal]);

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
  }, []);

  const handleSavePrefs = useCallback((trackerData, name) => {
    resetFilterDefs();
    savePrefs(trackerData, name);
  }, []);

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  const xlsxData = useMemo(() => {
    let data = [];
    let loadedTasks = selectedTasksToExport?.length ? selectedTasksToExport : tasks;
    loadedTasks?.forEach((task) => {
      let parsedHrsActualDuration = 0;
      let parsedMinsActualDuration = 0;
      let i = 0;
      while (undefined != task?.timing && i < task?.timing.length) {
        parsedMinsActualDuration += task?.timing[i].durationMins;
        parsedHrsActualDuration += task?.timing[i].durationHrs;
        i++;
      }
      const totalActualDurationinMinutes =
        (parsedHrsActualDuration ?? 0) * 60 + (parsedMinsActualDuration ?? 0);
      const durationValue = formatHoursAndMinutes(
        Math.floor(totalActualDurationinMinutes / 60),
        totalActualDurationinMinutes % 60,
      );

      let filteredTaskObj = {
        site: columnFilters.includes('site') ? task?.site?.name : null,
        asset: columnFilters.includes('asset') ? task?.asset?.name : null,
        state: columnFilters.includes('state')
          ? task?.state === undefined
            ? '-'
            : task?.state
          : null,
        priority: columnFilters.includes('priority') ? task?.priority : null,
        status: columnFilters.includes('status') ? task?.status : null,
        title: columnFilters.includes('title') ? task?.title : null,
        description: columnFilters.includes('description') ? task?.description : null,
        source: columnFilters.includes('source') ? task?.source : null,
        workScope: columnFilters.includes('workScope') ? task?.workScope : null,
        linked: columnFilters.includes('linked')
          ? task?.linked === undefined
            ? '-'
            : task?.linked
          : null,
        component: columnFilters.includes('component') ? task?.component : null,
        estTechs: columnFilters.includes('estTechs') ? task?.estTechs : null,
        estDuration: columnFilters.includes(TasksColumns.EST_DURATION)
          ? `${task?.estDurationHours}h ${task?.estDurationMinutes}m`
          : null,
        duration: columnFilters.includes('duration')
          ? durationValue == '0m'
            ? '-'
            : durationValue
          : null,
        'est-labor-hours': columnFilters.includes('est-labor-hours')
          ? task['est-labor-hours']
          : null,
        createDate: columnFilters.includes(TasksColumns.CREATED_DATE)
          ? task[TasksColumns.CREATED_DATE]
            ? dayjs(task[TasksColumns.CREATED_DATE]).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH
          : null,
        eligibleStartDate: columnFilters.includes(TasksColumns.ELIGIBLE_START_DATE)
          ? task[TasksColumns.ELIGIBLE_START_DATE]
            ? dayjs(task[TasksColumns.ELIGIBLE_START_DATE]).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH
          : null,
        scheduleDate: task[TasksColumns.SCHEDULED_DATE]
          ? dayjs(task[TasksColumns.SCHEDULED_DATE]).format(DateTimeFormats.DEFAULT_DATE)
          : Placeholders.DASH,
        dueDate: columnFilters.includes(TasksColumns.DUE_DATE)
          ? task[TasksColumns.DUE_DATE]
            ? dayjs(task[TasksColumns.DUE_DATE]).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH
          : null,
        committedDate: columnFilters.includes(TasksColumns.COMMITTED_DATE)
          ? task[TasksColumns.COMMITTED_DATE]
            ? dayjs(task[TasksColumns.COMMITTED_DATE]).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH
          : null,
        startedDateTime: columnFilters.includes(TasksColumns.STARTED_DATE_TIME)
          ? task[TasksColumns.STARTED_DATE_TIME]
            ? dayjs(task[TasksColumns.STARTED_DATE_TIME]).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH
          : null,
        completedDateTime: columnFilters.includes(TasksColumns.COMPLETED_DATE_TIME)
          ? task[TasksColumns.COMPLETED_DATE_TIME]
            ? dayjs(task[TasksColumns.COMPLETED_DATE_TIME]).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH
          : null,
        techs: columnFilters.includes('techs')
          ? task?.completedByTech?.length
            ? task?.completedByTech
                ?.map(
                  (techs) => taskWorkers && taskWorkers.find((worker) => worker.username === techs),
                )
                .map((x) => `${x?.firstName} ${x?.lastName}`)
                .join(', ')
            : '-'
          : null,
        'parts-expected': columnFilters.includes('parts-expected')
          ? task?.expectedParts?.length
            ? task?.expectedParts
                ?.map((part) => `${part.number} | ${part.name} | ${part.quantity}`)
                .join('; ')
            : '-'
          : null,
        'parts-consumed': columnFilters.includes('parts-consumed')
          ? task?.consumedParts?.length
            ? task?.consumedParts
                ?.map((part) => `${part.number} | ${part.name} | ${part.quantity}`)
                .join('; ')
            : '-'
          : null,
        attachment: columnFilters.includes('attachment') ? task?.attachment : null,
        resolution: columnFilters.includes('resolution')
          ? task?.resolution === undefined
            ? '-'
            : task?.resolution
          : null,
        srNumber: columnFilters.includes(TasksColumns.SR_NUMBER)
          ? task[TasksColumns.SR_NUMBER]
          : null,
        srStatus: columnFilters.includes(TasksColumns.SR_STATUS)
          ? task[TasksColumns.SR_STATUS]
          : null,
        flag: columnFilters.includes('flag') ? task?.flag : null,
        rdspp: columnFilters.includes(TasksColumns.RDSPP) ? task[TasksColumns.RDSPP] : null,
      };
      Object.keys(filteredTaskObj).forEach((key) => {
        if (filteredTaskObj[key] === null) {
          delete filteredTaskObj[key];
        }
      });
      data.push(filteredTaskObj);
    });
    return data;
  }, [columnFilters, selectedTasksToExport, taskWorkers, tasks]);

  return (
    <CaseTaskContainer>
      <ImportTaskDialog
        isOpen={showImportTaskModal}
        onClose={closeImportModal}
        onSetTaskUploadInProgress={setTaskUploadInProgress}
        onSetTaskUploadError={setTaskUploadError}
        onSetTaskUpload500Error={setTaskUpload500Error}
      />
      <PageContainer i18nReady={ready} ref={containerRef}>
        <Header>
          <h1 className="pageTitle">{ready && t('cases_tasks', 'Tasks')}</h1>
          {!isMax && (
            <TasksTracker
              savePrefs={handleSavePrefs}
              featPrefs={featPrefs}
              filters={filterDefs}
              sortMetric={sortMetric}
              sortDirection={sortDirection}
              isTasksLoading={isTasksLoading}
              loadCompletedTasks={loadCompletedTasks}
            />
          )}
          {!isTasksLoading && (
            <AuthRender
              capability={Capability.FIELD_TASKS}
              create
              description="New Task button"
              enforceView
              siteIds={siteIds}
            >
              <Button primary onClick={newFieldTask}>
                {t('new_task', 'New Task')}
              </Button>
            </AuthRender>
          )}
        </Header>
        <SubNavigation>
          <LeftSubNav>
            <span>{t('include_completed_task', 'Include Completed Task')}</span>
            <ToggleButton
              isToggleOn={loadCompletedTasks}
              handleOnClick={() => toggleButtonHandler()}
            />
          </LeftSubNav>
          <>
            <AuthRender
              capability={Capability.FIELD_TASKS}
              create
              description="Task Import Button"
              siteLevel={false}
            >
              <div onClick={() => setShowImportTaskModal(true)}>
                <ImportTaskButton />
              </div>
            </AuthRender>
            {tasks?.length && <ExportTaskTable xlsxData={xlsxData} columnFilters={columnFilters} />}
          </>
          <ColumnSelectMenu
            translateFn={t}
            containerRef={containerRef}
            columnDef={columnDefinition}
            columnState={visibleCols}
            onMenuClose={handleColumnChanges}
            onSetColumnFilters={setColumnFilters}
          />
        </SubNavigation>
        <TasksTable
          columns={visibleCols}
          tasks={isMax ? [] : tasks}
          isLoading={isTasksLoading}
          sortAction={updateSortMetric}
          sortMetric={sortMetric}
          sortDirection={sortDirection}
          onTaskSelect={handleTaskSelect}
          onDrop={(columnValue) => savePrefs(columnValue, CASES_TASKS)}
          onFilter={setFilterDefs}
          onFilterChange={handleFilterChange}
          filterValues={filterValues}
          onSetSelectedTasksToExport={setSelectedTasksToExport}
          resizable={true}
          featPrefs={(featPrefs && featPrefs[TASKS_TRACKER]) || []}
          filters={filterDefs}
          className="cases-tasks-table"
        />
      </PageContainer>
      {taskUploadInProgress && (
        <NotificationContainer>
          <UploadInProgressNotification
            interval={4000}
            onClose={closeUploadNotification}
            setShowNotification={setTaskUploadInProgress}
            filesUploaded={1}
            message={t(
              'tasks:tasks_upload_in_progress',
              'Task upload in progress. You will receive an email notification once upload is complete.',
            )}
          />
        </NotificationContainer>
      )}
      {TaskUpload500Error && (
        <NotificationContainer>
          <UploadInProgressNotification
            interval={4000}
            onClose={closeUploadNotification}
            setShowNotification={setTaskUpload500Error}
            filesUploaded={1}
            message={t(
              'tasks:tasks_upload_500_error',
              'Task not uploaded. Please contact support administrator',
            )}
          />
        </NotificationContainer>
      )}
      {taskUploadError && (
        <NotificationContainer>
          <TaskUploadErrorNotification
            interval={8000}
            onClose={closeUploadNotification}
            setShowNotification={setTaskUploadError}
            filesUploaded={1}
          />
        </NotificationContainer>
      )}
      {isMax && !isTasksLoading && (
        <Banner>
          <WarningMessage />
        </Banner>
      )}
      {showNewTaskModal && (
        <NewTaskDialog
          onClose={closeModal}
          onConfirm={saveTask}
          taskSource={TaskSourceField.MANUAL}
        />
      )}
    </CaseTaskContainer>
  );
};

export default CasesTasks;
