import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Placements } from '@ge/models/constants';
import { getDurationHourMin } from '@ge/shared/util/time-date';

const ScheduleDate = styled.div`
  display: flex;
  .title {
    font-size: 10px;
  }
  .date {
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
  }
  .scheduledStartBtn {
    margin-top: 7px;
    &:focus {
      outline: none;
    }
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-right: 4px;
`;

// eslint-disable-next-line react/display-name
const DatePickerPencil = forwardRef(({ onClick }, ref) => (
  <button className="scheduledStartBtn" onClick={onClick} ref={ref}>
    <StyledIcon icon={Icons.PENCIL} size={10} />
  </button>
));

DatePickerPencil.propTypes = {
  onClick: PropTypes.func,
};

DatePickerPencil.propTypes = {
  onClick: () => {},
};

export const TaskGroupScheduleData = ({ estimate, scheduledStart, scheduled, editable }) => {
  const { t } = useTranslation(['manage.planning']);
  const [startDate, setStartDate] = useState(new Date(scheduledStart));

  const handleScheduleEdit = (date) => {
    // Todo - update scheduledStart for task group
    setStartDate(date);
  };

  return (
    <ScheduleDate>
      <StyledIcon icon={estimate ? Icons.SNOOZE : Icons.CALENDAR} size={10} />
      <div>
        <div className="title">
          {estimate && t('total', 'Total')}
          {scheduled && scheduledStart && t('date_time', 'Date & Time')}
          {!scheduled && scheduledStart && t('proposed_for', 'Proposed for')}
        </div>
        <div className="date">
          {scheduledStart && dayjs(scheduledStart).format('DD MMM YY - H:mm')}
          {estimate && `${getDurationHourMin(estimate)} hrs`}
        </div>
      </div>
      {editable && (
        <DatePicker
          selected={startDate}
          startDate={startDate}
          onChange={(date) => handleScheduleEdit(date)}
          customInput={<DatePickerPencil />}
          showTimeInput
          shouldCloseOnSelect={false}
          popperPlacement={Placements.BOTTOM_END}
        />
      )}
    </ScheduleDate>
  );
};

TaskGroupScheduleData.propTypes = {
  estimate: PropTypes.number,
  scheduledStart: PropTypes.string,
  scheduled: PropTypes.bool,
  editable: PropTypes.bool,
};

TaskGroupScheduleData.defaultProps = {
  estimate: null,
  scheduledStart: null,
  scheduled: false,
  editable: false,
};
