import dayjs from 'dayjs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Table, Tbody, Td, Th, Thead } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import {
  PanelColumnWidths,
  EntityType,
  Capability,
  AlarmType,
  TaskSourceField,
  SeverityType,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { getDuration, getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';

const StyledTr = styled.tr`
  line-height: 16px;
  opacity: ${(props) => (props.isDisconnected ? 0.4 : 1)};
`;

const StyledTd = styled(Td)`
  padding: 4px 8px;
  font-size: 11px;
`;

const StyledCode = styled.span`
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledDesc = styled.span`
  margin-left: 6px;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const FHPIcon = styled(Icon).attrs((props) => ({
  size: 18,
  icon: Icons.CASE_PROCEDURE,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const FHPButton = styled.button`
  padding-top: 4px;
  height: 20px;
  width: 20px;
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 8.33,
  icon: Icons.ADD,
  color: props.theme.colors.slate5,
}))`
  background: ${(props) => props.theme.colors.slate2};
  border: 1px solid ${(props) => props.theme.colors.slate12};
  padding: 2px;

  &:active {
    border: 1px solid ${(props) => props.theme.themeSelector.iconColorActive};
  }
`;

const SeverityIcon = styled.svg`
  display: inline-block;
  padding-top: 2px;
  vertical-align: middle;
  height: 22px;
  width: 16px;
  & path:nth-child(1) {
    fill: ${(props) => props.theme.eventSeverity.safetyCritical};
  }
  & path:nth-child(2),
  path:nth-child(3) {
    fill: ${(props) => props.theme.eventSeverity.safetyCriticalExclamatory};
  }
`;

const AlarmIcon = styled(Icon).attrs(() => ({
  size: 18,
  icon: Icons.FAULT_ALARM,
  viewbox: '0 0 16 16',
}))`
  padding-top: 2px;
  path {
    fill: none;
    stroke: ${(props) => props.theme.eventSeverity.falutAlarm};
  }
`;

const WarningIcon = styled(Icon).attrs(() => ({
  size: 18,
  icon: Icons.WARNING_SEVERITY,
  viewbox: '0 0 16 16',
}))`
  padding-top: 2px;
  path {
    fill: none;
    stroke: ${(props) => props.theme.eventSeverity.warning};
  }
`;

const InformationIcon = styled(Icon).attrs(() => ({
  size: 18,
  icon: Icons.INFORMATIONAL_SEVERITY,
  viewbox: '0 0 16 16',
}))`
  padding-top: 2px;
  path {
    fill: none;
    stroke: ${(props) => props.theme.eventSeverity.informational};
  }
`;

const AssetAlarmsTable = ({
  alarms,
  sortAction,
  sortMetric,
  sortDirection,
  isLoading,
  isDisconnected,
  scrollable,
  assetId,
  siteId,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);

  const { getSiteById } = useStoreState((store) => store.sites);

  const site = getSiteById(siteId);

  const { getAssetById } = useStoreState((store) => store.assets);
  const asset = getAssetById(assetId);

  const { setSelectedAlarmOrEvent } = useStoreActions((actions) => actions.tasks);
  const { setWorkStandards } = useStoreActions((actions) => actions.tasks);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const getIconType = useCallback((severity) => {
    switch (severity) {
      case SeverityType.ALARM:
        return <AlarmIcon />;
      case SeverityType.WARNING:
        return <WarningIcon />;
      case SeverityType.INFORMATION:
        return <InformationIcon />;
      case SeverityType.CRITICAL:
        return (
          <SeverityIcon viewBox="0 0 16 12">
            <path d={Icons.SAFETY_POLYGON} />
            <path d={Icons.SAFETY_EXCLAMATORY} />
            <path d={Icons.SAFETY_CIRCLE} />
          </SeverityIcon>
        );
      default:
        return '';
    }
  }, []);

  const spawnWindow = useCallback((type, fhpId, fhpCode) => {
    window.open(
      `/${type}/${fhpId}/${fhpCode}`,
      '_blank',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780px,height=800px',
    );
  }, []);

  const newFieldTask = useCallback(
    (alarm, assetId) => {
      setShowNewTaskModal(true);
      alarm.assetId = assetId;
      setSelectedAlarmOrEvent(alarm);
    },
    [setShowNewTaskModal, setSelectedAlarmOrEvent],
  );

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
    setSelectedAlarmOrEvent(null);
    setWorkStandards(null);
  }, [setSelectedAlarmOrEvent, setWorkStandards]);

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
    setSelectedAlarmOrEvent(null);
    setWorkStandards(null);
  }, [setWorkStandards, setSelectedAlarmOrEvent]);

  if (!ready) {
    return <Loader />;
  }

  const currentTime = new Date().getTime();

  return (
    <Table height={'400'} compressed isLoading={isLoading} scrollable={scrollable}>
      <Thead transparent noTitles>
        <StyledTr>
          <Th
            labels={{
              [t('alarms_table.sev', 'Sev.')]: 'eventClass',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XXSMALL}
          />
          <Th
            labels={{
              [t('alarms_table.code', 'Code / #')]: 'code',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XSMALL}
          />
          <Th
            labels={{
              [t('alarms_table.title', 'Title')]: 'description',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XXLARGE}
          />
          <Th
            labels={{
              [t('alarms_table.duration', 'Duration')]: 'duration',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.SMALL}
          />
          <Th
            labels={{
              [`${t('alarms_table.start', 'Start')} (${dayjs
                .tz(new Date(), site?.timezone)
                .format('z')})`]: 'start',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.LARGE}
          />
          <AuthRender
            capability={Capability.FAULT_HANDLING}
            view
            description="Handling procedure"
            siteIds={[siteId]}
          >
            <Th
              labels={{
                [t('alarms_table.handling_procedure', 'Handling\nProcedure')]: 'category',
              }}
              align="center"
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
            />
          </AuthRender>
          <AuthRender
            capability={Capability.FIELD_TASKS}
            create
            description="New Task button"
            siteLevel={false}
          >
            <Th
              labels={{
                [t('alarms_table.task_escalation', 'Task\nEscalation')]: 'task escalation',
              }}
              align="center"
              width={PanelColumnWidths.XXSMALL}
            />
          </AuthRender>
        </StyledTr>
      </Thead>
      <Tbody transparent>
        {alarms?.map((alarm, idx) => {
          //#TODO:  Commenting for MVP0
          // const site = getSiteById(alarm.site?.id);
          // const asset = getAssetById(alarm.asset?.id);

          // // TODO: Putting placeholder data for alarms until we get real or better mock data
          // if (!site || !asset) {
          //   return null;
          // }

          // Required details
          //const { state } = asset.metrics || {};
          //const { conditions } = site.metrics || {};
          //const { tickets } = site;

          // Don't render until we have all the required information.
          // if (!state || !conditions || !tickets) {
          //   return null;
          // }

          const alarmDateTime =
            alarm.start !== 'Invalid Date'
              ? getDateTimeBasedOnZone(alarm.start, site?.timezone)
              : null;

          return (
            <StyledTr key={`${idx}-${alarm.code}`} isDisconnected={isDisconnected}>
              <StyledTd border align="left" width={PanelColumnWidths.XSMALL}>
                <TooltipCell tooltip={alarm.eventClass} zIndex={elevations.P20}>
                  {alarm?.eventClass ? getIconType(alarm?.eventClass) : ''}
                </TooltipCell>
              </StyledTd>
              <StyledTd border align="left" width={PanelColumnWidths.XSMALL}>
                <TooltipCell tooltip={alarm.code} zIndex={elevations.P20}>
                  <StyledCode>{alarm.code}</StyledCode>
                </TooltipCell>
              </StyledTd>
              {/* <StyledTd align="right" width={PanelColumnWidths.XXSMALL}>
                <Severity level={alarm.severity} />
              </StyledTd> */}
              <StyledTd border align="left" width={PanelColumnWidths.XXLARGE}>
                <TooltipCell tooltip={alarm.description} zIndex={elevations.P20}>
                  <StyledDesc>{alarm.description}</StyledDesc>
                </TooltipCell>
              </StyledTd>
              <StyledTd border align="left" width={PanelColumnWidths.MEDIUM}>
                {getDuration(alarm.start, dayjs(currentTime)).formatted}
              </StyledTd>
              <StyledTd border align="left" width={PanelColumnWidths.LARGE}>
                {alarmDateTime && (
                  <StyledTimeDate>
                    <span>{alarmDateTime.timeInSecs}</span>
                    <span>{alarmDateTime.date}</span>
                  </StyledTimeDate>
                )}
              </StyledTd>
              <AuthRender
                capability={Capability.FAULT_HANDLING}
                view
                description="Handling procedure"
                siteIds={[siteId]}
              >
                <StyledTd border align="center" width={PanelColumnWidths.XSMALL}>
                  {alarm.category === AlarmType.NON_DIAG && (
                    <FHPButton
                      onClick={() =>
                        spawnWindow(EntityType.HANDLING_PROCEDURE, assetId, alarm.eventId)
                      }
                    >
                      <FHPIcon />
                    </FHPButton>
                  )}
                </StyledTd>
              </AuthRender>
              <AuthRender
                capability={Capability.FIELD_TASKS}
                create
                description="New Task button"
                siteLevel={false}
              >
                <StyledTd border align="center" width={PanelColumnWidths.XXSMALL}>
                  <button onClick={() => newFieldTask(alarm, assetId)}>
                    <AddIcon />
                  </button>
                </StyledTd>
              </AuthRender>
            </StyledTr>
          );
        })}
        {showNewTaskModal && (
          <NewTaskDialog
            entity={asset}
            onClose={closeModal}
            onConfirm={saveTask}
            taskSource={TaskSourceField.REALTIMECASES}
            isCalledfromTaskEscalationColumn={true}
          />
        )}
      </Tbody>
    </Table>
  );
};

AssetAlarmsTable.propTypes = {
  alarms: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onEventSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  scrollable: PropTypes.bool,
  isDisconnected: PropTypes.bool,
  assetId: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

AssetAlarmsTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onEventSelect: () => null,
  isLoading: false,
  scrollable: false,
  isDisconnected: false,
};

export default AssetAlarmsTable;
