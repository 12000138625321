import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';
import { AssetOverviewTabs } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';

import { AssetContext } from '../../context/assetContext';

import { AssetOverviewDetailStorage } from './details/storage/asset-ov-detail-storage';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
  width: 100%;
  > div:first-child {
    button {
      text-transform: capitalize;
    }
  }
`;

const AssetBarContainer = styled.div`
  display: flex;
  width: calc(100% - 13px);
  background: ${(props) => props.theme.dataExplorer.header.backgroundColor};
  border-bottom: solid 1px ${(props) => props.theme.dataExplorer.header.borderColor};
  padding: 6px 3px 0 3px;
  min-height: 62px;
`;

export const AssetOverviewStorage = () => {
  const { t, ready } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const { queryParam, assetState } = useContext(AssetContext);

  if (!ready) return null;

  return (
    <Container>
      <DetailsDismissInert>
        <AssetBarContainer data-testid={AnalyzeLocators.ANALYZE_ASSET_RIBBON_CONTAINER}>
          Asset Ribbon Component
        </AssetBarContainer>
      </DetailsDismissInert>
      <StyledTabs
        defaultTab={queryParam.tab}
        onChangeHandler={(val) => assetState.setTabsParam(val)}
      >
        <Tab label={t(AssetOverviewTabs.OVERVIEW, AssetOverviewTabs.OVERVIEW)}>
          <AssetOverviewDetailStorage />
        </Tab>
        <Tab label={t(AssetOverviewTabs.DATA_EXPLORER, AssetOverviewTabs.DATA_EXPLORER)}>
          Data Explorer component here
        </Tab>
      </StyledTabs>
    </Container>
  );
};
