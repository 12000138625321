import styled from 'styled-components';

import { Icon } from '@ge/components/icon';

export const NavIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.analyze.header.kpiIconColor,
  ...props,
}))`
  vertical-align: initial;
  margin-right: 4px;
`;
