import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { AnalyzeLocators } from '@ge/models/data-locators';
// TODO (astone): This is an unholy reference to DWF from outside the app! Must remove!

import { FilterOptions, FilterDefs } from '../../models';

const StyledSelect = styled(Select)`
  margin-left: 10px;
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const DashboardKpiFilter = ({ onChange, filterOptions }) => {
  const defaultState = {
    // #327 - changed default for MVP0
    primary: FilterOptions.ANALYTIC[0].key,
    secondary: FilterOptions[FilterDefs.AVAILABILITY][0].key,
    tertiary: undefined,
  };

  const [filterState, setFilter] = useState(defaultState);

  useEffect(() => {
    onChange({ ...filterState });
  }, [filterState, onChange]);

  const setPrimary = useCallback(
    (value) =>
      setFilter((prevState) => ({
        ...prevState,
        primary: value,
        secondary: FilterOptions[value][0].key,
        tertiary: FilterOptions.CURVE.keys.includes(FilterOptions[value][0].key)
          ? FilterOptions.CURVE.options[0].key
          : undefined,
      })),
    [],
  );

  const setSecondary = useCallback(
    (value) =>
      setFilter((prevState) => ({
        ...prevState,
        secondary: value,
        tertiary: FilterOptions.CURVE.keys.includes(value)
          ? FilterOptions.CURVE.options[0].key
          : undefined,
      })),
    [],
  );

  const buildOption = useCallback((filter) => ({ value: filter.key, label: filter.label }), []);

  const primarySelectOptions = useMemo(
    () =>
      (filterOptions ? filterOptions : FilterOptions).ANALYTIC.map((filter) => buildOption(filter)),
    [buildOption, filterOptions],
  );

  const secondarySelectOptions = useMemo(
    () =>
      (filterOptions ? filterOptions : FilterOptions)[filterState.primary].map((filter) =>
        buildOption(filter),
      ),
    [buildOption, filterState.primary, filterOptions],
  );

  /* Commenting tertiary dropdown for PI-2 DE164801 */
  /* const tertiarySelectOptions = useMemo(
    () =>
      FilterOptions.CURVE.keys.includes(filterState.secondary) &&
      FilterOptions.CURVE.options.map((filter) => buildOption(filter)),
    [buildOption, filterState.secondary],
  ); */

  return (
    <StyledHeader data-testid={AnalyzeLocators.ANALYZE_KPI_SELECTS_WRAPPER}>
      <Select
        primary
        //minWidth={210}
        value={primarySelectOptions.filter((option) => option.value === filterState.primary)}
        onChange={(option) => setPrimary(option.value)}
        options={primarySelectOptions}
      />
      {filterState.secondary && (
        <StyledSelect
          options={secondarySelectOptions}
          onChange={(option) => setSecondary(option.value)}
          value={secondarySelectOptions.filter((option) => option.value === filterState.secondary)}
        />
      )}
      {/* Commenting tertiary dropdown for PI-2 DE164801 */}
      {/* {filterState.tertiary !== undefined && (
            <StyledSelect
              value={tertiarySelectOptions.filter(
                (option) => option.value === filterState.tertiary,
              )}
              onChange={(option) => setFilter({ ...filterState, tertiary: option.value })}
              options={tertiarySelectOptions}
            />
          )} */}
    </StyledHeader>
  );
};

DashboardKpiFilter.propTypes = {
  onChange: PropTypes.func,
  filterOptions: PropTypes.instanceOf(Object),
};
DashboardKpiFilter.defaultProps = {
  onChange: () => {},
};
