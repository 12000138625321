import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateAffectedAssets } from './create-affected-assets';
import { EditAffectedAssets } from './edit-affected-assets';

export const AffectedAssetsSection = ({
  entity,
  entityType,
  task,
  templateMode,
  fnHandleTimezone,
}) => {
  switch (templateMode) {
    case TaskTemplateModes.CREATE:
      return <CreateAffectedAssets entity={entity} fnHandleTimezone={fnHandleTimezone} />;
    case TaskTemplateModes.EDIT:
      return <EditAffectedAssets entityType={entityType} task={task} />;
    default:
      return null;
  }
};

AffectedAssetsSection.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.string,
  task: PropTypes.instanceOf(Object),
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  fnHandleTimezone: PropTypes.func,
};

AffectedAssetsSection.defaultProps = {
  entity: undefined,
  entityType: '',
  task: {},
};
