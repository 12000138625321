import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { DataExplorerAxisType } from '@ge/feat-analyze/models/data-explorer-defs';
import { typography } from '@ge/tokens/typography';

const SignalListHeader = styled.div`
  display: flex;
  background: ${(props) => props.theme.dataExplorer.dialog.sectionBorderColor};
  padding: 5px 10px;
`;

const Title = styled.span`
  font-size: 13px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
`;

const Span = styled.span`
  flex: 1;
`;

const Button = styled.button`
  color: ${(props) => props.theme.button.textColor};
  font-size: 13px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  padding: 0px;
  svg {
    margin: 0px 7px;
  }
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.dataExplorer.header.addIconColor,
}))`
  flex-shrink: 0;
`;

const DeleteIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.dataExplorer.header.removeIconColor,
}))`
  flex-shrink: 0;
`;

const AxisType = DataExplorerAxisType;

const DataExplorerSignalListHeader = ({
  axisIndex,
  axisType,
  totalAxisCount,
  maxAxisCount,
  onAddAxis,
  onRemoveAxis,
}) => {
  const { t, ready } = useTranslation(['analyze.data-explorer', 'general'], { useSuspense: false });

  if (!ready) return null;

  return (
    <SignalListHeader>
      {axisType === AxisType.X && <Title>{t('x_axis_select', 'X Axis')}</Title>}
      {axisType === AxisType.Y && maxAxisCount === 1 && (
        <Title>{t('y_axis_select', 'Y Axis')}</Title>
      )}
      {axisType === AxisType.Y && maxAxisCount > 1 && (
        <>
          <Title>
            {t(`y_axis_select_${axisIndex + 1}`, `${AxisType.Y + (axisIndex + 1)} Axis`)}
          </Title>
          {axisIndex > 0 && (
            <Button onClick={() => onRemoveAxis(axisIndex)}>
              <DeleteIcon icon={Icons.TRASH} />
            </Button>
          )}
          <Span />
          {axisIndex === totalAxisCount - 1 && totalAxisCount < maxAxisCount && (
            <Button onClick={onAddAxis}>
              <AddIcon icon={Icons.ADD} />
              {t('y_axis_select', 'Y Axis')}
            </Button>
          )}
        </>
      )}
    </SignalListHeader>
  );
};

DataExplorerSignalListHeader.propTypes = {
  axisIndex: PropTypes.number,
  axisType: PropTypes.string,
  totalAxisCount: PropTypes.number,
  maxAxisCount: PropTypes.number,
  onAddAxis: PropTypes.func,
  onRemoveAxis: PropTypes.func,
};

DataExplorerSignalListHeader.defaultProps = {
  axisIndex: 0,
  axisType: '',
  totalAxisCount: 1,
  maxAxisCount: 1,
  onAddAxis: () => {},
  onRemoveAxis: () => {},
};

export default DataExplorerSignalListHeader;
