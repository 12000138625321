import * as request from '@ge/shared/services/api';
const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

/**
 * Dispatch payload details
 */
export const dispatchToMTM = (postPayload) => {
  return request.post('/mwp/dispatch', postPayload, { baseURL: BASE_URL });
};

/**
 * get dispatch details
 */
export const getDispatchDetails = ({ serviceGroup, dispatchDate, timeZone }) => {
  let params = { serviceGroup, dispatchDate, timeZone };
  return request.get('/mwp/dispatch', { params, baseURL: BASE_URL });
};

/**
 * Patch dispatch details
 */

export const patchDispatch = (id, planChange) =>
  request.patch(`/mwp/dispatch/${id}`, planChange, { baseURL: BASE_URL });
