/**
 * A representation of Latitude and Longitude
 */
export class LatLng {
  /**
   * Latitude (-90 to 90)
   * @type {number}
   */
  lat;

  /**
   * Longitude (-180 to 180)
   * @type {number}
   */
  lng;

  /**
   * @param {LatLng} latLng LatLng object to use to initialize class
   */
  constructor(latLng) {
    const { lat, lng } = latLng || {};

    if (isNaN(lat)) throw new TypeError(`Invalid lat '${lat}'`);
    if (isNaN(lat)) throw new TypeError(`Invalid lng '${lng}'`);

    this.lat = lat;
    this.lng = lng;
  }
}
