import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PacIntegrationIcons } from '@ge/components/icon';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Table, Tbody, Td, Th, Thead, Tr } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { DayJs } from '@ge/models/constants';
import { PanelColumnWidths, SortType } from '@ge/models/constants';
import { elevations } from '@ge/tokens/elevations';
import { isEmpty } from '@ge/util/object-utils';

const StyledHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 0 !important;
  /* Adding top margin for MVP0 - Remove once metrics container added back */
  margin-top: 30px;
  &.indent {
    margin-left: 5px;
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 12,
  rotate: 80,
  color: props.theme.entityDetails.tickets.iconColor,
}))`
  margin-right: 5px;
`;
const StyledChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  rotate: 270,
  color: props.theme.entityDetails.tickets.iconColor,
}))`
  height: 10px;
  width: 3px;
  overflow: visible;
  vertical-align: -webkit-baseline-middle;
  margin-right: 20px;
  margin-top: 6px;
  fill: #98a0a6;
`;

const PacIntegrationTable = ({
  isLoading,
  sortAction,
  sortMetric,
  sortDirection,
  damageDetails,
}) => {
  const { t, ready } = useTranslation(['inspections.results'], {
    useSuspense: false,
  });
  const [bladeResolutionDetailsExist, setBladeResolutionDetailsExist] = useState(false);

  const [pacCaseTitle] = useState('Main Case');

  const [bladeResolutionId, setBladeResolutionId] = useState(null);
  const [bladeResolutionTitle] = useState('Blade Resolution');
  const [bladeResolutionCreatedDate, setBladeResolutionCreatedDate] = useState('');
  const [bladeResolutionCreatedTime, setBladeResolutionCreatedTime] = useState('');
  const [bladeResolutionStatus, setBladeResolutionStatus] = useState('');
  const [bladeResolutionLink, setBladeResolutionLink] = useState('');

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  useEffect(() => {
    if (!isEmpty(damageDetails) && damageDetails.annotationResult) {
      setBladeResolutionLink(damageDetails.annotationResult.data.attributes.blade_resolution_url);
      setBladeResolutionId(damageDetails.annotationResult.data.attributes.blade_resolution_id);
      if (damageDetails.annotationResult.data.attributes.blade_resolution_created_dt) {
        let brCreatedDate =
          damageDetails.annotationResult.data.attributes.blade_resolution_created_dt;
        const formattedBRCreateTime = dayjs(brCreatedDate).format('HH:MM');
        const formattedBRCreateDate = dayjs(brCreatedDate).format('DD MMM YY');
        brCreatedDate = formattedBRCreateDate !== DayJs.INVALID_DATE && formattedBRCreateDate;
        setBladeResolutionCreatedDate(brCreatedDate);
        setBladeResolutionCreatedTime(formattedBRCreateTime);
      }
      setBladeResolutionStatus(
        damageDetails.annotationResult.data.attributes.blade_resolution_status,
      );
    }
  }, [damageDetails]);
  useEffect(() => {
    if (bladeResolutionId && bladeResolutionId !== 'false') {
      setBladeResolutionDetailsExist(true);
    }
  }, [bladeResolutionId]);

  const getFormattedCreatedDate = (createdDate) => {
    if (!createdDate) return '';
    const formattedPacCreateDate = dayjs(createdDate).format('DD MMM YY');
    createdDate = formattedPacCreateDate !== DayJs.INVALID_DATE && formattedPacCreateDate;
    return createdDate;
  };

  const getFormattedCreatedTime = (createdDate) => {
    if (!createdDate) return '';
    const formattedPacCreateTime = dayjs(createdDate).format('HH:MM');
    return formattedPacCreateTime;
  };

  const handleExternalLinkClick = (externalLink) => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
  };

  if (!ready) {
    return <Loader />;
  }
  return (
    <>
      <StyledHeader className="indent">In This Result :</StyledHeader>

      <Table height={'400'} compressed isLoading={isLoading} noData={false}>
        <Thead noTitles transparent>
          <tr>
            <Th
              labels={{
                [t('type', 'Type')]: 'type',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={(e) => sortAction(e, SortType.TURBINE_STATUS)}
              width={PanelColumnWidths.SMALL}
              align="left"
            />
            <Th
              labels={{
                [t('number', '#')]: 'number',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.LARGE}
            />

            <Th
              labels={{
                [t('pac_title', 'Title')]: 'title',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
              align="left"
            />
            <Th
              labels={{
                [t('opened', 'Opened')]: 'opened',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.SMALL}
              whiteSpace="normal"
              align="left"
            />
            <Th
              border
              labels={{
                [t('status', 'Status')]: 'status',
              }}
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.MEDIUM}
              whiteSpace="break"
              align="left"
            />
            <Th
              border
              labels={{
                [t('table.source', 'Source/Details')]: 'source',
              }}
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.MEDIUM}
              whiteSpace="break"
              align="left"
            />
          </tr>
        </Thead>
        <Tbody transparent>
          {damageDetails.integration_results &&
            damageDetails.integration_results.data &&
            damageDetails.integration_results.data.map((pacData) => (
              <Tr key={pacData.attributes.external_system_id}>
                <Td border width={PanelColumnWidths.SMALL}>
                  <StyledIcon icon={Icons.EXPAND} />
                </Td>
                <Td align="left" border width={PanelColumnWidths.LARGE}>
                  <TooltipCell
                    tooltip={t(pacData.attributes.external_system_id)}
                    zIndex={elevations.P20}
                  >
                    {pacData.attributes.external_system_id}
                  </TooltipCell>
                </Td>

                <Td align="left" width={PanelColumnWidths.XSMALL} border>
                  {pacCaseTitle}
                </Td>
                <Td align="left" width={PanelColumnWidths.XSMALL} border>
                  <div style={{ display: 'flex' }}>
                    <p>{getFormattedCreatedDate(pacData.attributes.created_dt)}</p>
                    <p style={{ color: '#919192', paddingLeft: '14px' }}>
                      {getFormattedCreatedTime(pacData.attributes.created_dt)}
                    </p>
                  </div>
                </Td>

                <Td
                  align="left"
                  border
                  width={PanelColumnWidths.SMALL}
                  maxWidth={PanelColumnWidths.SMALL}
                >
                  {pacData.attributes.external_system_status}
                </Td>
                <Td
                  border
                  width={PanelColumnWidths.SMALL}
                  maxWidth={PanelColumnWidths.SMALL}
                  onClick={() => handleExternalLinkClick(pacData.attributes.external_system_url)}
                >
                  <StyledChevronIcon icon={PacIntegrationIcons.GLOBE} />
                </Td>
              </Tr>
            ))}
          {bladeResolutionDetailsExist && (
            <Tr>
              <Td border width={PanelColumnWidths.SMALL}>
                <StyledIcon icon={Icons.EXPAND} />
              </Td>
              <Td align="left" border width={PanelColumnWidths.LARGE}>
                <TooltipCell tooltip={t(bladeResolutionId)} zIndex={elevations.P20}>
                  {bladeResolutionId}
                </TooltipCell>
              </Td>

              <Td align="left" width={PanelColumnWidths.XSMALL} border>
                {bladeResolutionTitle}
              </Td>
              <Td align="left" width={PanelColumnWidths.XSMALL} border>
                <div style={{ display: 'flex' }}>
                  <p>{bladeResolutionCreatedDate}</p>
                  <p style={{ color: '#919192', paddingLeft: '14px' }}>
                    {bladeResolutionCreatedTime}
                  </p>
                </div>
              </Td>

              <Td
                align="left"
                border
                width={PanelColumnWidths.SMALL}
                maxWidth={PanelColumnWidths.SMALL}
              >
                {bladeResolutionStatus ? bladeResolutionStatus : ''}
              </Td>
              <Td
                border
                width={PanelColumnWidths.SMALL}
                maxWidth={PanelColumnWidths.SMALL}
                onClick={() => handleExternalLinkClick(bladeResolutionLink)}
              >
                <StyledChevronIcon icon={PacIntegrationIcons.GLOBE} />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
};

PacIntegrationTable.propTypes = {
  siteId: PropTypes.string,
  assets: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isLoading: PropTypes.bool,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onAssetSelect: PropTypes.func,
  siteType: PropTypes.string,
  damageDetails: PropTypes.any,
};

PacIntegrationTable.defaultProps = {
  isLoading: false,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onAssetSelect: () => null,
};

export default PacIntegrationTable;
