import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme, css } from 'styled-components';

import { Icons, Icon } from '../icon';

import { Modal } from './modal';

const DialogWrapper = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  border-radius: 6px;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.2);
  margin: 90px auto;
  ${(props) =>
    props.contentWidth
      ? css`
          display: inline-block;
          transform: translateX(-50%);
          margin-left: 50%;
        `
      : css`
          width: 465px;
        `}

  color: ${(props) => props.theme.layout.textColor};
  font-family: Museo Sans;

  .button-footer {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 30px;
    width: 140px;
  }
`;

const DialogHeader = styled.header`
  position: relative;
  align-items: center;
  color: ${(props) => props.theme.dialog.headerTextColor};
  background-color: ${(props) => props.theme.dialog.headerBackground};
  display: flex;
  flex-direction: row;
  height: 25px;
  padding-left: 10px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
`;
const DialogContent = styled.section`
  padding: 20px 25px;
`;

const DialogFooter = styled.footer`
  background-color: ${(props) => props.theme.modal.background};
  border-top: 1px solid ${(props) => props.theme.dialog.footerSeparatorColor};
  padding: 17px 25px;
`;

const HeaderIcon = styled(Icon).attrs((props) => ({
  color: props.theme.dialog.iconColor,
}))`
  margin-right: 4px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.dialog.iconColor,
}))`
  display: block;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 6px;
  display: inline-block;
  vertical-align: middle;
  padding: 3px;
`;

const DialogComponent = ({
  className,
  header,
  icon,
  footer,
  onClose,
  isOpen,
  padContent,
  children,
  contentWidth,
  portalId,
  footerIndex,
}) => (
  <Modal isOpen={isOpen} portalId={portalId}>
    <DialogWrapper contentWidth={contentWidth} className={className}>
      <DialogHeader>
        {icon && <HeaderIcon icon={icon} size={12} />}
        <span>{header}</span>
        <CloseButton onClick={onClose}>
          <CloseIcon icon={Icons.CLOSE} size={10} />
        </CloseButton>
      </DialogHeader>
      {padContent ? <DialogContent>{children}</DialogContent> : children}
      {footer && (
        <DialogFooter
          style={{
            zIndex: footerIndex > 0 && footerIndex,
            position: footerIndex > 0 && 'relative',
          }}
        >
          {footer}
        </DialogFooter>
      )}
    </DialogWrapper>
  </Modal>
);

DialogComponent.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  icon: PropTypes.string,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  padContent: PropTypes.bool,
  contentWidth: PropTypes.bool,
  portalId: PropTypes.string,
  footerIndex: PropTypes.number,
};

DialogComponent.defaultProps = {
  className: '',
  isOpen: false,
  children: null,
  footer: null,
  header: null,
  icon: null,
  onClose: () => null,
  padContent: true,
  contentWidth: false,
  portalId: '#portal-root',
  footerIndex: 0,
};

export const Dialog = withTheme(DialogComponent);
