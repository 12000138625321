import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';

import { StackCard } from '@ge/feat-manage/components/planning/month/cards/calendar-month-stack';
import { StackMenu } from '@ge/feat-manage/components/planning/month/cards/stack-card-menu';
import {
  BundledCountBadge,
  TaskCard,
  TaskCardEntity,
} from '@ge/feat-manage/components/planning/shared';
import { DragItemTypes, FilterValues } from '@ge/feat-manage/context';
import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { useCalendarCardDetails } from '@ge/feat-manage/data-hooks/use-calendar-card-details';
import { useCalendarCardMenu } from '@ge/feat-manage/data-hooks/use-calendar-card-menu';

export const CalendarQuarterCard = ({
  tasks,
  dayWidthQuarter,
  date,
  sidebarWidth,
  duration,
  laneIndex,
  secondaryIndex,
  itemIndex,
  onDrop,
  onClick,
  draggable,
}) => {
  const {
    planningState: { handleDragStart, handleDragEnd, groupFilter, handleDragOver, range },
    serviceGroupIds,
  } = usePlanningContext();

  const menuRef = useRef(null);
  const anchorEl = useRef(null);

  const { menuShown, destroyStackMenu, showStackMenu } = useCalendarCardMenu();
  const { bundleInfo, sameAssetTasks, partialCompleted } = useCalendarCardDetails({
    tasks,
    date,
  });

  if (tasks.length === 0) return null;

  // day of month * width of day - 1 day + day of month for border offset
  const position = sidebarWidth + (itemIndex + 1) * dayWidthQuarter - dayWidthQuarter + itemIndex;

  // Todo: update this to reflect real durations - currently defaulting every day to 24 hourss
  const width = Math.round((duration / 24) * dayWidthQuarter) - Math.round(duration / 24) - 3;

  const task = tasks[0];

  return tasks.length > 1 && !sameAssetTasks ? (
    <StackCard
      tasks={tasks}
      position={position}
      width={width}
      laneIndex={laneIndex}
      secondaryIndex={secondaryIndex}
      itemIndex={itemIndex}
      handleDragStart={handleDragStart}
      onDrop={onDrop}
      onClick={onClick}
      draggable={draggable}
      date={date}
    />
  ) : (
    <TaskCard
      position={position}
      width={width}
      className={classNames(
        `${partialCompleted ? 'partial' : task.status}`,
        'lasso-card',
        {
          draggable,
        },
        range.type,
      )}
      draggable={
        serviceGroupIds.length === 1
          ? draggable
          : groupFilter.L1.value === FilterValues.CREW || groupFilter.L2.value === FilterValues.CREW
          ? false
          : draggable
      }
      onMouseEnter={showStackMenu}
      onMouseLeave={destroyStackMenu}
      ref={anchorEl}
      onDragStart={() => {
        destroyStackMenu();
        handleDragStart(
          tasks,
          DragItemTypes.CARD,
          null,
          secondaryIndex !== null ? `${laneIndex}-${secondaryIndex}` : laneIndex,
        );
      }}
      onDragEnd={handleDragEnd}
      onDrop={onDrop}
      data-laneindex={laneIndex}
      data-secondaryindex={secondaryIndex}
      data-dayindex={itemIndex}
      onClick={(e) => onClick(e)}
      onDragOver={(e) => handleDragOver(e)}
    >
      <StackMenu
        open={menuShown}
        tasks={tasks}
        onClose={destroyStackMenu}
        ref={menuRef}
        anchorEl={anchorEl && anchorEl.current}
        date={date}
        bundlePresent={bundleInfo.bundlePresent}
      />
      <TaskCardEntity
        className="content"
        task={task}
        sameAssetTaskCount={bundleInfo.sameAssetTaskCount}
      />
      <BundledCountBadge
        className={range.type}
        bundlePresent={bundleInfo.bundlePresent}
        bundleCount={bundleInfo.bundleCount}
      />
    </TaskCard>
  );
};

CalendarQuarterCard.propTypes = {
  tasks: PropTypes.instanceOf(Array),
  dayWidthQuarter: PropTypes.number,
  date: PropTypes.instanceOf(Object), // dayjs
  sidebarWidth: PropTypes.number,
  duration: PropTypes.number,
  laneIndex: PropTypes.number.isRequired,
  secondaryIndex: PropTypes.number,
  itemIndex: PropTypes.number.isRequired,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
};

CalendarQuarterCard.defaultProps = {
  count: null,
  dayWidthQuarter: null,
  date: null,
  sidebarWidth: null,
  duration: null,
  onDrop: () => {},
  onClick: () => {},
  secondaryIndex: null,
  draggable: false,
};
