import { action, computed, thunk } from 'easy-peasy';

import { EntityType } from '@ge/models/constants';
import merge from '@ge/util/deep-merge';

import { fetchAssetMetricChart } from '../../shared/services/asset';
import { fetchSiteMetricChart } from '../../shared/services/site';

// Define initial state
const defaultMetricsState = {
  metrics: [],
};

// Actions
const metricsActions = {
  // eslint-disable-next-line no-unused-vars
  resetMetrics: action((state) => {
    state = Object.assign(state, defaultMetricsState);
  }),

  fetchAssetMetricChart: thunk(async (actions, payload, { fail }) => {
    try {
      const { assetId, metric } = payload || {};
      const { chartData } = await fetchAssetMetricChart(assetId, metric);

      actions.updateMetricChart({
        entityId: assetId,
        entityType: EntityType.TURBINE,
        chartData,
        metric,
      });
    } catch (err) {
      fail(err);
    }
  }),

  fetchSiteMetricChart: thunk(async (actions, payload, { fail }) => {
    try {
      const { siteId, metric } = payload || {};
      const { chartData } = await fetchSiteMetricChart(siteId, metric);

      actions.updateMetricChart({
        entityId: siteId,
        entityType: EntityType.SITE,
        chartData,
        metric,
      });
    } catch (err) {
      fail(err);
    }
  }),

  updateMetricChart: action((state, payload) => {
    const { entityId, entityType, chartData, metric } = payload;
    const existingChart = state.getMetricChart(entityId, entityType, metric);

    if (existingChart) {
      merge(existingChart, { chartData });
      return;
    }

    state.metrics.push(payload);
  }),
};

// Listeners
const metricsListeners = {};

// Computed values
const metricsComputed = {
  getMetricChart: computed((state) => (entityId, entityType, metric) =>
    state.metrics.find(
      (m) => m.entityId === entityId && m.entityType === entityType && m.metric === metric,
    ),
  ),
};

const metricModel = {
  ...defaultMetricsState,
  ...metricsActions,
  ...metricsComputed,
  ...metricsListeners,
};

export default metricModel;
