import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, ButtonGroup } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';

const FilterGroup = styled.div`
  display: flex;
  gap: 30px;
`;
const StyledRadioInput = styled.div`
  margin: 16px 0;
  margin-left: -5px;
  input[type='radio'] {
    &:checked:before {
      border-color: ${(props) => props.theme.radio.borderColor};
      animation: ripple 0.2s linear forwards;
    }

    &:checked:after {
      transform: scale(1);
    }
  }

  input[type='radio'] {
    height: 14px;
    position: relative;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: 12px;
      height: 12px;
      background: #38434e;
      box-shadow: inset 20px -28px 30px 4px rgb(45 45 52 / 50%);
      border: 1px solid ${(props) => props.theme.radio.borderColor};
    }

    &:after {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      transform: scale(0);
      background: ${(props) => props.theme.radio.activeColor};
    }
  }
`;

const RadioLabel = styled.span`
  margin-left: 6px;
`;

const DialogSegment = styled.div`
  margin-bottom: -9px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
`;

const Footer = ({ onCancel, onExport, disableSubmit, radioSelectedValue }) => {
  return (
    <StyledButtonGroup>
      <Button onClick={onCancel}>Cancel</Button>
      <Button disabled={disableSubmit} value={radioSelectedValue} onClick={onExport} primary>
        Export
      </Button>
    </StyledButtonGroup>
  );
};

Footer.propTypes = {
  onCancel: PropTypes.func,
  onExport: PropTypes.func,
  radioSelectedValue: PropTypes.string,
  disableSubmit: PropTypes.bool,
};

export const ExportFileModal = ({ isOpen, onClose, onSubmit, getRadioSelected }) => {
  const { t, ready } = useTranslation(['analyze.dashboard']);
  const [radioSelected, setradioSelected] = useState('csv');

  if (!ready) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={t('export_modal.title', 'Export')}
      footer={
        <Footer
          onCancel={onClose}
          onExport={onSubmit}
          radioSelectedValue={getRadioSelected(radioSelected)}
          disableSubmit={false}
        />
      }
    >
      <DialogSegment>
        <h4>{t('export_modal.select_format', 'Select Format').toUpperCase()}</h4>
        <FilterGroup>
          <StyledRadioInput>
            <input
              type="radio"
              value={t('CSV', 'CSV')}
              name={'csv'}
              checked={radioSelected === 'csv' ? true : false}
              onChange={() => setradioSelected('csv')}
            />
            <RadioLabel>{t('CSV', 'CSV')}</RadioLabel>
          </StyledRadioInput>
          <StyledRadioInput>
            <input
              type="radio"
              value={t('EXCEL', 'EXCEL')}
              name={'excel'}
              checked={radioSelected === 'excel' ? true : false}
              onChange={() => setradioSelected('excel')}
            />
            <RadioLabel>{t('EXCEL', 'EXCEL')}</RadioLabel>
          </StyledRadioInput>
        </FilterGroup>
      </DialogSegment>
    </Dialog>
  );
};

ExportFileModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  getRadioSelected: PropTypes.func,
  enablePng: PropTypes.bool,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      metadata: PropTypes.object,
    }),
  ),
};

export default ExportFileModal;
