import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { fetchCAnalysisTemplatesData } from '@ge/feat-analyze/services';
import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { sorter } from '@ge/util/metric-sorter';

const analysisTemplateTransformer = (data) => {
  return data?.map((elem) => {
    const id = Object.keys(elem)[1];
    const plots = elem[id] ?? [];
    return {
      id,
      plotCount: plots?.length ?? 0,
      ...plots.reduce((acc, plot, i) => {
        const { signal_x = {}, signals_y = [] } = plot ?? {};
        return {
          ...acc,
          [`plot${i + 1}_x`]: { name: signal_x.signalId ?? '' },
          [`plot${i + 1}_y`]: {
            name: signals_y[0]?.signalId ?? '',
            multiOptions: signals_y.map((signal) => ({
              id: signal.signalId,
              title: signal.signalId,
            })),
          },
        };
      }, {}),
    };
  });
};

export const useAnalysisTemplates = ({
  filterSequence,
  filters,
  search,
  sortDirection,
  sortMetric,
}) => {
  const { data, isLoading: isAnalysisTemplateDataLoading } = useQuery(
    [QueryKey.ANALYSIS_TEMPLATE_DATA],
    async () => {
      const { analysisTemplateList } = await fetchCAnalysisTemplatesData();
      return analysisTemplateList;
    },
    {
      ...Config.EXECUTE_ONCE,
      enabled: true,
    },
  );

  const sortedData = useMemo(() => {
    if (isAnalysisTemplateDataLoading || !data?.length) return [];

    const transformedData = analysisTemplateTransformer(data);

    return transformedData?.sort(sorter(sortMetric, sortDirection));
  }, [isAnalysisTemplateDataLoading, data, sortMetric, sortDirection]);

  const { data: filterdData = [], filterValues = {} } = useTableFilter({
    data: sortedData,
    filterSequence,
    filters,
    search,
  });

  return {
    data: filterdData,
    filterValues,
    isAnalysisTemplateDataLoading,
  };
};
