import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  QueryKey,
  EntityType,
  EntityTypeMapping,
  CaseDLType,
  AlertDLTemplate,
} from '@ge/models/constants';
import { useDlTableFilter } from '@ge/shared/data-hooks/use-dl-table-filter';
import {
  getDistributionList,
  getAllDistributionLists,
  createDistributionList,
  updateDistributionList,
  deleteDistributionList,
} from '@ge/shared/services/distribution-list';
import { sorter } from '@ge/util/metric-sorter';

import { Config } from './config';

export const useDistributionList = ({ emailType, entityType, entityId }, isActive = true) => {
  let assetType;
  const { getAssetById } = useStoreState((store) => store.assets);
  if (entityType?.toLowerCase() === EntityType.ASSET) {
    const assetIds = String(entityId)?.split(',');
    const asset = getAssetById(assetIds?.[0]);
    assetType = EntityTypeMapping[asset?.type]?.replaceAll('_', '') ?? 'WindTurbine';
  }
  const { data: distributionList, error, isLoading } = useQuery(
    [QueryKey.DISTRIBUTION_LIST, emailType, entityType, entityId],
    () =>
      getDistributionList({
        emailType,
        entityType,
        entityId,
        assetType,
      }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
    },
  );

  const data = useMemo(() => {
    if (!Array.isArray(distributionList)) return;
    return distributionList.map((item) => {
      if (item) {
        return {
          to: item?.to?.join(';'),
          cc: item?.cc?.join(';'),
          bcc: item?.bcc?.join(';'),
          replyTo: item?.replyTo?.join(';'),
        };
      }
      return item;
    });
  }, [distributionList]);

  return { data, error, isLoading };
};

export const useDistList = ({ distId }, isActive = true) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.DISTRIBUTION_LIST],
    () => getAllDistributionLists(),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
    },
  );

  const record = useMemo(() => data?.find((value) => value.distId == distId), [distId, data]);

  return { record, error, isLoading };
};

export const useAllDistributionLists = (
  { search, filters, sortDirection, sortMetric },
  isActive = true,
) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.ALL_DISTRIBUTION_LIST],
    () => getAllDistributionLists(),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
    },
  );
  const { sites } = useStoreState((state) => state.sites);
  const { assets, getAssetById } = useStoreState((state) => state.assets);

  const tempFilterValues = { site: [], asset: [] };

  if (data?.length > 0) {
    data.forEach((rec, index) => {
      data[index].template = rec?.distributionType
        ?.map((item) => {
          let label = '';
          if (item?.sourceId === CaseDLType.NOTFAULTED_ACTIONABLE) {
            label = CaseDLType.GENERAL_ACTIONABLE;
          } else if (item?.sourceId === CaseDLType.NOTFAULTED_INFORMATIONAL) {
            label = CaseDLType.GENERAL_INFORMATIONAL;
          } else if (item?.sourceId === AlertDLTemplate.TEMPORARY_CONFIG_ACTIONABLE) {
            label = AlertDLTemplate.TEMPORARY_CONFIG_CHANGE_ACTIONABLE;
          } else if (item?.sourceId === AlertDLTemplate.TEMPORARY_CONFIG_INFORMATIONAL) {
            label = AlertDLTemplate.TEMPORARY_CONFIG_CHANGE_INFORMATIONAL;
          } else {
            label = item?.sourceId;
          }

          return label
            ?.replace(/_+/g, ' ')
            ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
        })
        .filter((rec) => rec)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

      data[index].entity = rec?.entity?.map((rec) => {
        if (rec && !('siteId' in rec) && 'assetId' in rec && rec.assetId?.length) {
          return { ...rec, siteId: getAssetById(rec['assetId'][0])?.site?.id };
        } else {
          return rec;
        }
      });

      const getEnityDetails = (type) => {
        let returnVal = [];
        const storeData = type === 'siteId' ? sites : assets;

        if (rec?.entity?.length > 0) {
          const filterData = rec?.entity?.filter((rec) => rec[type]);

          if (filterData.length > 0) {
            if (type === 'assetId') {
              filterData.forEach((rec) => {
                returnVal = rec[type]
                  .map((val) => storeData[val]?.name?.trim())
                  .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
              });
            } else {
              returnVal = filterData
                .map((val) => storeData[val[type]]?.name?.trim())
                .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
            }

            returnVal = [...new Set(returnVal)];

            returnVal = returnVal.length ? returnVal.filter((rec) => rec) : [];
          }
        }

        return returnVal;
      };

      data[index].site = { value: getEnityDetails('siteId') };
      data[index].asset = { value: getEnityDetails('assetId') };

      tempFilterValues.site.push(...data[index].site.value);
      tempFilterValues.asset.push(...data[index].asset.value);
    });
  }

  const { data: filteredData, filterValues } = useDlTableFilter({ data, filters, search });

  if (filterValues) {
    filterValues.site = [...new Set(tempFilterValues.site)];
    filterValues.asset = [...new Set(tempFilterValues.asset)];
  }

  // cuts a new ref when sorting to update graph accordingly
  const sortedData = useMemo(
    () => (filteredData?.length ? [...filteredData.sort(sorter(sortMetric, sortDirection))] : []),
    [filteredData, sortDirection, sortMetric],
  );

  return useMemo(() => ({ distributionLists: sortedData, filterValues, error, isLoading }), [
    sortedData,
    filterValues,
    error,
    isLoading,
  ]);
};

export const useCreateDistributionList = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: createDL, data, isLoading, isError, error } = useMutation(
    createDistributionList,
    {
      onSuccess: (newData) => {
        if (queryCache.getQueriesData(QueryKey.ALL_DISTRIBUTION_LIST)) {
          queryCache.setQueryData(QueryKey.ALL_DISTRIBUTION_LIST, (oldData) => [
            ...newData,
            ...oldData,
          ]);
        } else {
          queryCache.invalidateQueries(QueryKey.ALL_DISTRIBUTION_LIST);
        }
      },
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return useMemo(() => ({ createDL, data, isLoading, isError, error }), [
    createDL,
    data,
    isLoading,
    isError,
    error,
  ]);
};

const getUpdatedData = (oldData, newData) => {
  const recordIndex = oldData.findIndex((item) => item.distId === newData.updated.distId);
  if (recordIndex > -1) oldData[recordIndex] = newData.updated;
  return oldData;
};

export const useUpdateDistributionList = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: updateDL, data, isLoading, isError, error } = useMutation(
    updateDistributionList,
    {
      onSuccess: (newData) => {
        if (queryCache.getQueriesData(QueryKey.ALL_DISTRIBUTION_LIST)) {
          queryCache.setQueryData(QueryKey.ALL_DISTRIBUTION_LIST, (oldData) =>
            getUpdatedData(oldData, newData),
          );
        } else {
          queryCache.invalidateQueries(QueryKey.ALL_DISTRIBUTION_LIST);
        }
      },
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return useMemo(() => ({ updateDL, data, isLoading, isError, error }), [
    updateDL,
    data,
    isLoading,
    isError,
    error,
  ]);
};

export const useDeleteDistributionList = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: deleteDL, data, isLoading, isError, error } = useMutation(
    deleteDistributionList,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(QueryKey.ALL_DISTRIBUTION_LIST, { exact: true }),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return useMemo(() => ({ deleteDL, data, isLoading, isError, error }), [
    deleteDL,
    data,
    isLoading,
    isError,
    error,
  ]);
};
