import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { globalColors, elevations } from '@ge/tokens';

import { SidePanelNav } from '../../side-panel/side-panel-nav';

const FHP_FULL_HEIGHT = '95%';
const FHP_TRAY_HEIGHT = '340px';

const calcFhpHeight = (props) => {
  const { fullSize, showFhp } = props;

  if (showFhp && fullSize) {
    return FHP_FULL_HEIGHT;
  }

  if (showFhp) {
    return FHP_TRAY_HEIGHT;
  }

  return 0;
};

const StyledFhp = styled.section`
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.entityDetails.fhp.backgroundColor};
  position: absolute;
  width: 100%;
  max-width: ${(props) => (props.fullSize ? '100%' : '801px')};
  height: ${(props) => calcFhpHeight(props)};
  transition: height 300ms ease-in-out;
  z-index: ${elevations.P1};
`;

const FhpContainer = styled.div`
  padding: 15px;
`;

const FhpHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.fhp.borderBottomColor};
  padding: 5px 0;
  display: flex;
`;

const PanelWrapper = styled.div`
  width: 50%;
`;

const FhpContent = styled.div`
  max-width: ${(props) => (props.fullSize ? '100%' : '600px')};
`;

const Fhp = ({ closeFhp, fhp, spawnWindow, fullSize }) => (
  <ConditionalRender shouldRender={false}>
    {/* Hiding for MVP0 - Story #535 - remove conditional render component above */}
    <StyledFhp showFhp={fhp} fullSize={fullSize}>
      <FhpContainer>
        <FhpHeader>
          <PanelWrapper>
            <h3>
              FHP &nbsp;
              {fhp ? fhp.code : null}: &nbsp;
              {fhp ? fhp.description : null}
            </h3>
          </PanelWrapper>
          <PanelWrapper>
            {/* TODO (astone): This navigation component should probably not be used in this context */}
            <SidePanelNav
              closePanel={closeFhp ? () => closeFhp() : null}
              color={globalColors.slate4}
              disableHistory
              spawnWindow={spawnWindow ? () => spawnWindow(fhp.id) : null}
            />
          </PanelWrapper>
        </FhpHeader>
        <FhpContent fullSize={fullSize}>
          <p className="text-4">{fhp ? fhp.fhpText : null}</p>
        </FhpContent>
      </FhpContainer>
    </StyledFhp>
  </ConditionalRender>
);

Fhp.propTypes = {
  closeFhp: PropTypes.func,
  fhp: PropTypes.instanceOf(Object),
  fullSize: PropTypes.bool,
  spawnWindow: PropTypes.func,
};

Fhp.defaultProps = {
  fhp: null,
  closeFhp: null,
  fullSize: null,
  spawnWindow: null,
};

export default Fhp;
