import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '@ge/models/constants';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // process.env.NODE_ENV === 'development', // use the following to get local debug messages: process.env.NODE_ENV === 'development',
    defaultNS: 'general',
    fallbackLng: DEFAULT_LANGUAGE,
    ns: ['general'],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: (a, nameSpaces) => {
        const ns = nameSpaces.shift();
        const nameParts = ns.split('.');
        const fileName = `${nameParts.pop()}.json`;
        const path = ['/locales', '{{lng}}'];
        const hash = process.env.REACT_APP_LOCALE_HASH;

        nameParts.forEach((part) => path.push(part));
        path.push(fileName);

        return hash ? path.join('/') + `?hash=${hash}` : path.join('/');
      },
    },
  });

export default i18n;
