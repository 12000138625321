import { PropTypes } from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { EntityType, EntityMode } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import TabDetailHeader from '../components/tab-detail-header';
import { DetailContainer } from '../entity-details-shared';

import { CaseResolutionEdit } from './case-resolution-edit';
import { CaseResolutionView } from './case-resolution-view';

const BackgroundContainer = styled.div`
  background-color: ${({ theme }) => theme.entityDetails.cases.details.resolutionBackground};
`;

const ResolutionDetailWrapper = styled.div`
  margin-right: 32px;
  padding-bottom: 40px;
  .task-panel-row {
    align-items: flex-start;
    display: flex;
    &:last-of-type {
      .column-detail {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

export const CaseResolution = ({ issue, openEditPanel }) => {
  const [isEditCasePanel, setIsEditCasePanel] = useState(openEditPanel);

  const { updateEntityMode } = useContext(EntityDetailsContext);

  useEffect(() => {
    updateEntityMode(isEditCasePanel ? EntityMode.EDIT : EntityMode.VIEW);
  }, [isEditCasePanel, updateEntityMode]);

  const {
    asset: { id: assetId },
  } = issue ?? {};

  if (!assetId) {
    return null;
  }

  return (
    <DetailContainer>
      <BackgroundContainer>
        <TabDetailHeader entity={issue} entityType={EntityType.CASE} />
        <ResolutionDetailWrapper>
          {isEditCasePanel ? (
            <CaseResolutionEdit
              issue={issue}
              closeEditPanel={() => setIsEditCasePanel(false)}
              showErrors={openEditPanel}
            />
          ) : (
            <CaseResolutionView issue={issue} handleEditClick={() => setIsEditCasePanel(true)} />
          )}
        </ResolutionDetailWrapper>
      </BackgroundContainer>
    </DetailContainer>
  );
};

CaseResolution.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  openEditPanel: PropTypes.bool,
};

CaseResolution.defaultProps = {
  openEditPanel: false,
};
