import { useStoreState, useStoreActions } from 'easy-peasy';
import { useEffect, useCallback, useState } from 'react';

import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { AnaylzeDefs } from '@ge/models/constants';

const FEAT_PREFS_SUB_KEY = AnaylzeDefs.KPI_HEADER_MENU;

const useKpiHeaderMenu = ({ scope, defaultVisibility, kpiCategories }) => {
  const { savePrefs, featPrefs: _featPrefs } = useFeaturePrefs(scope);

  // Kpi Header Prefs
  const featPrefs = _featPrefs?.[FEAT_PREFS_SUB_KEY];
  // Local State
  const [cleanFeatPrefs, setCleanFeatPrefs] = useState(featPrefs ? [...featPrefs] : null);

  const { visibleKpis } = useStoreState((state) => state.analyze);
  const { setVisibleKpis } = useStoreActions(({ analyze }) => analyze);

  const getSanitizedPrefs = useCallback(
    () =>
      kpiCategories
        .reduce(
          (newPrefs, category) => {
            if (!featPrefs.find((pref) => pref?.id === category)) {
              const missingPrefIndex = defaultVisibility.findIndex((item) => item?.id === category);
              const missingPref = defaultVisibility[missingPrefIndex];

              newPrefs.splice(missingPrefIndex, 0, missingPref);
            }
            return newPrefs;
          },
          [...featPrefs],
        )
        .filter((pref) => kpiCategories.some((category) => pref?.id === category)),
    [defaultVisibility, kpiCategories, featPrefs],
  );

  useEffect(() => {
    let visibility;

    // Until a user changes their header preferences they do not exist in dynamo.
    // If they don't we can use the default Visibility def as their preferences.
    // If they do we need to insure they are hydrated/ sanitized with any potential code changes.
    if (featPrefs == null) visibility = defaultVisibility;
    else {
      const sanitizedPrefs = getSanitizedPrefs();
      setCleanFeatPrefs(sanitizedPrefs);
      visibility = sanitizedPrefs;
    }

    // Set visibleKpis state to array of visible id's (ex. ['tba', 'pba']).
    const _visibleKpis = visibility.reduce((arr, pref) => {
      if (pref?.visible) arr.push(pref?.id);
      return arr;
    }, []);

    setVisibleKpis(_visibleKpis);
  }, [savePrefs, featPrefs, defaultVisibility, getSanitizedPrefs, setVisibleKpis]);

  // Handle any visiblity changes triggered by config menu
  const handleKpiChanges = useCallback(
    (kpiChanges) => {
      let newFeatPrefs = defaultVisibility;

      // If no prefs exist, merge default visibility with changes to hydrate prefs.
      // Otherwise merge changes with existing prefs.
      if (featPrefs?.length) {
        newFeatPrefs = cleanFeatPrefs;
      }

      let updatedFeatPrefs = newFeatPrefs.map(({ id, visible }) => {
        if (kpiChanges.has(id)) {
          return { id, visible: kpiChanges.get(id) };
        }
        return { id, visible };
      });

      savePrefs(updatedFeatPrefs, FEAT_PREFS_SUB_KEY);
    },
    [savePrefs, featPrefs, defaultVisibility, cleanFeatPrefs],
  );

  return {
    handleKpiChanges,
    visibleKpis,
  };
};

export default useKpiHeaderMenu;
