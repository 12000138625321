import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Pattern } from '@ge/models/constants';

const StyledScrollingContainer = styled(ScrollingContainer)`
  max-height: 115px;
`;

const RecipientList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const RecipientListItem = styled.li`
  height: 25px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  &:nth-of-type(odd) {
    background-color: #24292d; // mock up had a custom color
  }
`;

const RecipientEmail = styled.span`
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveRecipientButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: auto;
`;

const EmailInput = styled(Input)`
  margin-top: 12px;
  width: calc(100% - 5px);
  ${(props) => props.noEmailError && `border: 1px solid ${props.theme.dangerColor}`};
`;

const InputInfo = styled.p`
  margin: 0 0 0 5px;
  font-style: italic;
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  font-size: 11px;
`;

const EmailError = styled.label`
  color: ${(props) => props.theme.dangerColor};
  margin-left: 5px;
`;

const EMAIL_INPUT_CHAR_LIMIT = 120;
const MAX_EMAILS = 50;

export const EmailRecipients = withTheme(
  ({ theme, distributionList, onDistributionListChanged, noEmailError, readOnly }) => {
    const { t } = useTranslation(['reporting.sidebar'], { useSuspense: false });

    const [newEmail, setNewEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const handleEmailAddition = useCallback(
      (event, distList) => {
        if (readOnly) {
          return;
        }

        setEmailError(false);

        if (event.key === 'Enter') {
          event.preventDefault();

          if (newEmail.match(Pattern.ANY_CHAR_BEFORE_AT_SYMBOL_EMAIL)) {
            const newDistList = [...distList, newEmail];

            if (onDistributionListChanged) {
              onDistributionListChanged(newDistList);
            }

            setNewEmail('');
          } else {
            setEmailError(true);
          }
        }
      },
      [newEmail, onDistributionListChanged, readOnly],
    );

    const handleEmailRemoval = useCallback(
      (emailIndex, distList) => {
        if (readOnly) {
          return;
        }

        const newDistList = distList.filter((email, index) => {
          return index !== emailIndex;
        });

        if (onDistributionListChanged) {
          onDistributionListChanged(newDistList);
        }
      },
      [onDistributionListChanged, readOnly],
    );

    return (
      <>
        <StyledScrollingContainer relative>
          <RecipientList>
            {distributionList?.map((email, i) => (
              <RecipientListItem key={i}>
                <RecipientEmail>{email}</RecipientEmail>
                {!readOnly && (
                  <RemoveRecipientButton onClick={() => handleEmailRemoval(i, distributionList)}>
                    <Icon
                      icon={Icons.CLOSE}
                      size={8}
                      color={theme.createReport.sidebar.siteIconColor}
                    />
                  </RemoveRecipientButton>
                )}
              </RecipientListItem>
            ))}
          </RecipientList>
        </StyledScrollingContainer>
        {!readOnly && (
          <>
            <EmailInput
              type="email"
              placeholder={t('add_recipient', 'Add Recipient')}
              maxLength={EMAIL_INPUT_CHAR_LIMIT}
              onChange={(event) => setNewEmail(event.target.value)}
              onKeyDown={(event) => handleEmailAddition(event, distributionList)}
              value={newEmail}
              disabled={distributionList?.length === MAX_EMAILS}
              noEmailError={noEmailError}
            />
            <InputInfo>{t('press_enter', "Press 'Enter' to add email")}</InputInfo>
            {emailError && <EmailError>{t('invalid_email', 'Invalid email')}</EmailError>}
            {noEmailError && (
              <EmailError>
                {t('no_email', 'No email(s) entered. Please enter at least one email.')}
              </EmailError>
            )}
          </>
        )}
      </>
    );
  },
);

EmailRecipients.propTypes = {
  distributionList: PropTypes.array.isRequired,
  onDistributionListChanged: PropTypes.func,
  noEmailError: PropTypes.bool,
  readOnly: PropTypes.bool,
};
