import classNames from 'classnames';
import dayjs from 'dayjs';
import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useCallback, useState, useMemo, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { StyledLabel } from '@ge/components/input/input';
import { Dialog } from '@ge/components/modal';
import { Text } from '@ge/components/typography';
import { DateTimeFormats } from '@ge/models/constants';
import { Capability, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDateObject } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';

import { PlanningContext, DragItemTypes } from '../../../context/planning-provider';
import {
  isWorkerActive,
  isWorkerSplitByAcross,
  getWeekNames,
  getWeekNumbers,
} from '../../../util/worker-util';
import { CrewBadge } from '../../crew-badge';
import { CrewMemberTimings } from '../crew/crew-member-timings';
// import { getTimeInMinutes } from '../month/month-util';

const Crew = styled.div`
  .members {
    background: ${(props) => props.theme.manage.tabs.techs.crew.backgroundColor};
  }
  .action-buttons {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: end;
    border-top: solid 1px ${(props) => props.theme.manage.tabs.techs.crew.actionButtonsBorder};
    .cancel-button {
      margin-right: 10px;
    }
  }
  .new-time-period {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .member-time-container {
    padding: 5px;
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.manage.tabs.techs.crew.actionButtonsBorder};
  }
`;

const HeaderToggle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    line-height: 25px;
    &.hide {
      display: none;
    }
  }
  .crew-techs {
    display: flex;
    flex-flow: row wrap;
  }
  .crew-title {
    margin-right: 16px;
  }
  .crew-actions {
    display: flex;
    flex: 1;
    .crew-buttons {
      margin-left: auto;
      display: flex;
    }
    .crew-edit {
      display: flex;
    }
  }
  .crew-name input {
    border-radius: 2px 0 0 2px;
    padding: 0 4px;
    width: 113px;
    height: 23px;
  }
`;

const CheckIcon = styled(Icon).attrs((props) => ({
  size: 8,
  icon: Icons.CHECK,
  color: props.theme.manage.tabs.techs.crew.headerText,
}))`
  vertical-align: initial;
`;

const DeleteIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.TRASH,
  color: props.theme.manage.tabs.techs.crew.headerText,
  viewbox: '0 0 13 19',
}))`
  vertical-align: initial;
`;

const EditIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.PENCIL,
  color: props.theme.manage.tabs.techs.crew.headerText,
}))`
  vertical-align: initial;
`;

const CrewMember = styled.div`
  padding: 6px 15px;
  display: flex;
  cursor: ${(props) => (props.draggable ? 'grab' : 'cursor')};
  &.dragging {
    background: ${(props) => props.theme.manage.dayCard.scheduled.backgroundColor};
    cursor: move !important;
  }
  &:nth-of-type(even) {
    background: ${(props) => props.theme.manage.tabs.techs.crew.evenBackgroundColor};
  }
  > .deleteTech {
    display: none;
  }
  &:hover {
    background: ${(props) => props.theme.manage.tabs.techs.crew.hover.background};
    box-shadow: ${(props) => props.theme.manage.tabs.techs.crew.hover.shadow};
    .deleteTech {
      display: block;
    }
  }
  .details {
    margin-left: 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    > div {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

const RangeToggle = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  padding: 5px;
  label {
    display: block;
  }
  button {
    padding: 3px 0px;
    margin-right: 8px;
    font-size: 13px;
    width: 20px;
    text-align: center;
    color: ${(props) => props.theme.manage.rangeToggle.textColor};
    background: ${(props) => props.theme.manage.rangeToggle.backgroundColor};
    border: solid 1px ${(props) => props.theme.manage.rangeToggle.borderColor};
    font-weight: ${typography.weight.medium};
    &:focus {
      outline: none;
    }
    &.active {
      background: ${(props) => props.theme.manage.rangeToggle.activeBackgroundColor};
      border-color: ${(props) => props.theme.manage.rangeToggle.activeBorderColor};
      font-weight: ${typography.weight.bold};
    }
  }
`;

const AddCrewMember = styled.div`
  padding: 6px 15px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.manage.tabs.techs.crew.addTextColor};
  &.dragOver {
    .addCircle {
      border-color: transparent;
      box-shadow: ${(props) => props.theme.manage.dayCard.AddTechCircleBoxShadow};
    }
  }
  &.disabled {
    color: ${(props) => props.theme.button.disabled.textColor};
    font-weight: ${(props) => props.theme.button.disabled.fontWeight};
    cursor: not-allowed;
  }
  .addCircle {
    border-radius: 24px;
    border: 1px dotted ${(props) => props.theme.manage.tabs.techs.iconColor};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    * {
      pointer-events: none;
    }
  }
`;

const DeleteButton = styled.button`
  margin-left: auto;
`;

const SaveButton = styled(Button)`
  height: 25px;
  width: 25px;
  padding: 0;
  border-radius: 0 2px 2px 0;
`;

const DatesContainer = styled.div`
  display: flex;
  padding: 5px;
  .date-input {
    flex: 1;
    &:first-child {
      margin-right: 5px;
    }
  }
  .date-picker-crew input {
    width: 115px;
    .start-end-label {
      margin-bottom: 5px;
    }
  }
  .date-picker-member input {
    width: 95px;
    .start-end-label {
      margin-bottom: 5px;
    }
  }
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 3px;
`;

const SubHeader = styled.div`
  color: ${(props) => props.theme.collapsiblePanel.subHeader};
`;

const DatePickerIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor,
  icon: Icons.CALENDAR,
}))`
  margin-left: -20px;
  margin-top: -2px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const DialogWrapper = styled(Dialog)`
  width: 380px;
`;
const DeleteCrew = styled.div`
  font-family: Museo Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
`;
const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ADD,
  color: props.theme.manage.tabs.techs.iconColor,
}))``;

export const TechsPanelCrew = ({ crew, crewData, workerData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [crewName, setCrewName] = useState(crew.name);
  const { planningState, serviceGroupIds } = useContext(PlanningContext);
  const { username: dragTechId } = planningState.dragItem || {};
  const { range, setCrewEdited } = planningState;
  const {
    deleteCrew,
    updateCrewName,
    addTimingToMember,
    addMemberToCrew,
    removeWorkerFromCrew,
    deleteTimingFromMember,
    removeTimeValuesFromMember,
    updateCrew,
    updatePreviousCrew,
  } = useStoreActions((actions) => actions.workers);
  useEffect(() => {
    if (crew.name === 'New Crew') {
      setIsExpanded(true);
    }
  }, [crew.name]);
  const { isAuthorized } = useAuth();

  const deleteWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.DELETE] }],
    siteIds: [],
  });

  const editWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    siteIds: [],
  });
  const viewWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.VIEW] }],
    siteIds: [],
  });

  const [days, setDays] = useState(getWeekNumbers(crew.apply_on));
  const [daysChanged, setDaysChanged] = useState(false);
  const [collapsibleTrigger, setCollapsibleTrigger] = useState(false);

  const [selectedMembers, setSelectedMembers] = useState([]);

  const { t } = useTranslation(['tasks', 'manage.cases-tasks', 'manage.planning'], {
    useSuspense: false,
  });
  const { _id: id, crewEndDate: crewEndDate } = crew;

  const DAYS = [
    t('manage.cases-tasks:dayInitials.sunday', 'S'),
    t('manage.cases-tasks:dayInitials.monday', 'M'),
    t('manage.cases-tasks:dayInitials.tuesday', 'T'),
    t('manage.cases-tasks:dayInitials.wednesday', 'W'),
    t('manage.cases-tasks:dayInitials.thursday', 'T'),
    t('manage.cases-tasks:dayInitials.friday', 'F'),
    t('manage.cases-tasks:dayInitials.saturday', 'S'),
  ];

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
  });

  // Set crew edit flag in planning context
  useEffect(() => {
    if (methods.formState.isDirty || daysChanged) {
      setCrewEdited(true);
    }
  }, [daysChanged, methods.formState.isDirty, setCrewEdited]);

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    setValue,
    unregister,
    reset,
    formState: { dirtyFields },
  } = methods;

  const handleReset = () => {
    handleExpanding(false);
    reset();
    setDays(getWeekNumbers(crew.apply_on));
    setCrewEdited(false);
  };

  //manually registering the member timings
  useEffect(() => {
    crew.members.forEach((mem) => {
      mem.time_periods.forEach((time, i) => {
        register(`members.${mem.id}.${i}.startDate`);
        setValue(`members.${mem.id}.${i}.startDate`, time.startDate);
        register(`members.${mem.id}.${i}.endDate`);
        setValue(`members.${mem.id}.${i}.endDate`, time.endDate);
        register(`members.${mem.id}.${i}.startTime`);
        setValue(`members.${mem.id}.${i}.startTime`, time.startTime);
        register(`members.${mem.id}.${i}.endTime`);
        setValue(`members.${mem.id}.${i}.endTime`, time.endTime);
      });
    });
  }, [crew, register, setValue]);

  const onMemberClick = (id) => {
    let arr = selectedMembers;
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
    } else {
      arr.push(id);
    }
    setSelectedMembers([...arr]);
  };

  const toggleDay = (e, arr, day) => {
    e.preventDefault();
    var idx = arr.indexOf(day);
    setDays(idx > -1 ? arr.filter((d) => d !== day) : [...arr, day]);
    setDaysChanged(true);
  };

  const isSaveEnabled = () => {
    return dirtyFields?.crewStartDate ? true : dirtyFields?.crewEndDate ? true : false;
  };

  const onCrewNameSave = useCallback(
    (e) => {
      killEventPropagation(e);
      setCrewName(getValues('name'));
      updateCrewName({ crewName: getValues('name'), selectedCrew: crew });
      setShowEdit(false);
      setCrewEdited(false);
    },
    [crew, getValues, setCrewEdited, updateCrewName],
  );

  const handleOnDrop = useCallback(() => {
    if (planningState.dragItemType === DragItemTypes.TECH) {
      addMemberToCrew({
        member: planningState.dragItem,
        memId: dragTechId,
        crew,
      });
    }
    setCrewEdited(false);
  }, [
    planningState.dragItemType,
    planningState.dragItem,
    setCrewEdited,
    addMemberToCrew,
    dragTechId,
    crew,
  ]);

  const handleDragLeaveCrew = (id) => {
    // delay so onDragEnter() can execute before clearing any active drag states.
    setTimeout(() => {
      if (planningState.dragOverId === id) {
        planningState.handleDragLeave();
      }
    }, 100);
  };

  /**
   * Apply the delete for modal confirm
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyDelete = (id) => {
    deleteCrew({ crewId: id });
    setShowModal(false);
  };

  const deleteTechnician = (e, mem) => {
    killEventPropagation(e);
    mem.time_periods.forEach((_time, i) => {
      unregister(`members.${mem.id}.${i}.startDate`);
      unregister(`members.${mem.id}.${i}.endDate`);
      unregister(`members.${mem.id}.${i}.startTime`);
      unregister(`members.${mem.id}.${i}.endTime`);
    });
    removeWorkerFromCrew({ memId: mem.username, crew });
    reset();
    setCrewEdited(false);
  };

  const handleRemove = (e, memIndex, timeIndex, memId) => {
    killEventPropagation(e);
    unregister(`members.${memId}.${timeIndex}.startDate`);
    unregister(`members.${memId}.${timeIndex}.endDate`);
    unregister(`members.${memId}.${timeIndex}.startTime`);
    unregister(`members.${memId}.${timeIndex}.endTime`);
    if (crew.members[memIndex].time_periods.length == 1) {
      removeTimeValuesFromMember({ crewId: id, memIndex, timeIndex });
    } else {
      deleteTimingFromMember({ crewId: id, memIndex, timeIndex });
    }
  };

  /**
   * clear edit state if collapsing panel
   */
  const handleExpanding = (expanded) => {
    if (expanded === false) {
      setShowEdit(false);
      setSelectedMembers([]);
    }
    setIsExpanded(expanded);
    setCollapsibleTrigger(expanded);
  };

  const onAddTimePeriodClick = (e, memId) => {
    killEventPropagation(e);
    addTimingToMember({ memId: memId, crewId: id });
  };

  const isStartDateValid = (value) => {
    for (let i = 0; i < crew.members.length; i++) {
      let time_periods = crew.members[i].time_periods;
      for (let j = 0; j < time_periods.length; j++) {
        if (
          dayjs(getValues(`members.${crew.members[i].id}.${j}.startDate`)).isBefore(
            dayjs(value, 'date'),
          )
        ) {
          return false;
        }
      }
    }
  };

  const isEndDateValid = (value) => {
    for (let i = 0; i < crew.members.length; i++) {
      let time_periods = crew.members[i].time_periods;
      for (let j = 0; j < time_periods.length; j++) {
        if (
          dayjs(getValues(`members.${crew.members[i].id}.${j}.endDate`)).isAfter(
            dayjs(value, 'date'),
          )
        ) {
          return false;
        }
      }
    }
  };

  const formatDate = (date) => (date ? dayjs(date).format(DateTimeFormats.NEW_TASK) : null);

  const onSubmit = useCallback(
    (values) => {
      const members = [];
      if (values?.members) {
        Object.entries(values?.members).forEach((mem) => {
          mem[1].map((time) => {
            return Object.assign(time, {
              startDate:
                time.startDate !== null &&
                time.startTime !== '' &&
                typeof time.startDate !== 'string'
                  ? dayjs(time.startDate).format(DateTimeFormats.CREW_TIMING)
                  : time.startDate,
              endDate:
                time.endDate !== null && time.endDate !== '' && typeof time.endDate !== 'string'
                  ? dayjs(time.endDate).format(DateTimeFormats.CREW_TIMING)
                  : time.endDate,
            });
          });

          members.push({
            member_id: crew.members.find((member) => {
              return member.id === Number(mem[0]);
            }).username,
            time_periods: mem[1],
          });
        });
      }
      const payload = {
        _id: id,
        service_group: crew.service_group,
        name: crewName,
        crewStartDate: values?.crewStartDate
          ? dayjs(values.crewStartDate).format(DateTimeFormats.CREW_TIMING)
          : null,
        crewEndDate: values?.crewEndDate
          ? dayjs(values.crewEndDate).format(DateTimeFormats.CREW_TIMING)
          : null,
        crewShiftStartTime: values.crewShiftStartTime,
        crewShiftEndTime: values.crewShiftEndTime,
        apply_on: getWeekNames(days),
        members: members,
      };
      updatePreviousCrew(payload._id); // Dispatch
      updateCrew(payload);
      handleExpanding(false);
      setCrewEdited(false);
      reset(values);
    },
    [
      crew.members,
      crew.service_group,
      crewName,
      days,
      id,
      reset,
      setCrewEdited,
      updateCrew,
      updatePreviousCrew,
    ],
  );

  const isMemberFromSelectedSg = useCallback(
    (member, crew) => {
      const worker = workerData.find((worker) => worker?.username === member?.username);
      const workerServiceGroups =
        worker?.attributes?.worker_service_group[0]?.groupCombination[0]?.ids;
      if (!workerServiceGroups.includes(crew?.service_group)) {
        return false;
      }
      return true;
    },
    [workerData],
  );

  const crewHeader = useMemo(
    () => (
      <HeaderToggle className="header-toggle">
        {!showEdit && <div className="crew-title">{crewName}</div>}
        {viewWorkPlan && !isExpanded && (
          <div className="crew-techs">
            {crew.members.map(
              (member, i) =>
                !!member?.username &&
                isMemberFromSelectedSg(member, crew) && (
                  <CrewBadge
                    key={member.member_id + i}
                    technician={member}
                    overlap
                    active={isWorkerActive(member, crewData, id, crewEndDate, range)}
                    split={isWorkerSplitByAcross(member, crewData, id)}
                  />
                ),
            )}
          </div>
        )}
        {isExpanded && (
          <div className="crew-actions">
            {showEdit && (
              <div className="crew-name">
                <Input
                  id="name"
                  type="text"
                  name="name"
                  defaultValue={crewName}
                  onClick={(e) => killEventPropagation(e)}
                  ref={register({
                    required: true,
                    minLength: 1,
                    maxLength: 20,
                    pattern: {
                      value: /^(?=.*\S).+$/,
                    },
                  })}
                  error={errors?.name ? 'error' : null}
                  required
                />
                <SaveButton
                  onClick={(e) => (errors?.name ? killEventPropagation(e) : onCrewNameSave(e))}
                >
                  <CheckIcon />
                </SaveButton>
              </div>
            )}
            <div className="crew-buttons">
              {editWorkPlan && !showEdit && (
                <button
                  type="button"
                  onClick={(e) => {
                    killEventPropagation(e);
                    setShowEdit(true);
                  }}
                >
                  <EditIcon />
                </button>
              )}
              {deleteWorkPlan && (
                <button
                  type="button"
                  onClick={(e) => {
                    killEventPropagation(e);
                    setShowModal(true);
                  }}
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
          </div>
        )}
      </HeaderToggle>
    ),
    [
      showEdit,
      crewName,
      viewWorkPlan,
      isExpanded,
      crew,
      register,
      errors?.name,
      editWorkPlan,
      deleteWorkPlan,
      isMemberFromSelectedSg,
      crewData,
      id,
      crewEndDate,
      range,
      onCrewNameSave,
    ],
  );

  const startEndDateHeader = useMemo(() => {
    return <SubHeader> {t('crew.startEndDateHeader', 'START/END DATES')}</SubHeader>;
  }, [t]);

  const shiftDurationHeader = useMemo(() => {
    return <SubHeader>{t('crew.shiftDurationHeader', 'SHIFT DURATION')}</SubHeader>;
  }, [t]);

  const getCancelFooter = useMemo(() => {
    return (
      <FooterWrapper>
        <FooterButtons>
          <Button onClick={() => setShowModal(false)}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={() => applyDelete(crew._id)}>
            {t('manage.planning:delete', 'Delete')}
          </Button>
        </FooterButtons>
      </FooterWrapper>
    );
  }, [applyDelete, crew._id, t]);

  return (
    <Crew>
      <FormProvider {...methods}>
        <CollapsiblePanel
          headerContent={crewHeader}
          onToggleExpand={(expanded) => handleExpanding(expanded)}
          expanded={isExpanded}
          triggerHeight={collapsibleTrigger}
          disableKeyClick={false}
        >
          <div className="members">
            {crew.members.map(
              (member, memIndex) =>
                !!member?.username &&
                isMemberFromSelectedSg(member, crew) && (
                  <div
                    key={member.username + memIndex}
                    style={
                      selectedMembers.includes(member.username)
                        ? { backgroundColor: globalColors.slate2 }
                        : {}
                    }
                  >
                    <CrewMember
                      // WIP - dragging a member from one crew to another
                      // draggable={crew.members.length > 1}
                      // onDragStart={() => planningState.handleDragStart(member, DragItemTypes.TECH)}
                      // onDragEnd={planningState.handleDragEnd}
                      // className={
                      //   planningState.dragItem &&
                      //   planningState.dragItem.id === member.username &&
                      //   'dragging'
                      // }
                      onClick={() => onMemberClick(member.username)}
                    >
                      <CrewBadge
                        overlap
                        technician={member}
                        active={isWorkerActive(member, crewData, id, crewEndDate, range)}
                        split={isWorkerSplitByAcross(member, crewData, id)}
                      />
                      <div className="details">
                        {`${member.firstName} ${member.lastName}`}
                        <div>{member.title}</div>
                      </div>
                      {editWorkPlan && (
                        <DeleteButton
                          className="deleteTech"
                          type="button"
                          onClick={(e) => deleteTechnician(e, member)}
                        >
                          <DeleteIcon />
                        </DeleteButton>
                      )}
                    </CrewMember>
                    {selectedMembers.includes(member.username) && (
                      <CrewMemberTimings
                        member={member}
                        memIndex={memIndex}
                        handleRemove={handleRemove}
                        onAddTimePeriodClick={onAddTimePeriodClick}
                        onSubmit={onSubmit}
                      />
                    )}
                  </div>
                ),
            )}
            <AddCrewMember
              className={classNames(
                {
                  dragOver:
                    planningState.dragOverId === id &&
                    planningState.dragItemType === DragItemTypes.TECH,
                },
                {
                  disabled: serviceGroupIds.length > 1,
                },
              )}
              onDragOver={planningState.handleDragOver}
              onDrop={() => handleOnDrop()}
              onDragEnter={() => planningState.handleDragEnter(null, id)}
              onDragLeave={() => handleDragLeaveCrew(id)}
            >
              <div className="addCircle">
                <AddIcon />
              </div>
              {t('manage.planning:add_tech', 'Add Tech')}
            </AddCrewMember>
            <CollapsiblePanel headerContent={startEndDateHeader} plusIcon>
              <div>
                <DatesContainer>
                  <div className="date-input">
                    <Controller
                      control={control}
                      name={'crewStartDate'}
                      defaultValue={
                        crew.crewStartDate ? getDateObject(dayjs(crew.crewStartDate)) : null
                      }
                      rules={{
                        required: true,
                        validate: {
                          crewStartDateValid: (value) => {
                            return isStartDateValid(value);
                          },
                          isAfterEndDate: (value) => {
                            if (dayjs(value).isAfter(dayjs(getValues('crewEndDate'), 'date'))) {
                              return false;
                            }
                          },
                        },
                      }}
                      render={({ onChange, value }) => (
                        <DatePicker
                          customInput={
                            <div className="date-picker-crew">
                              <StyledLabel className="start-end-label">
                                {t('manage.cases-tasks:start_date', 'Start Date')}*
                              </StyledLabel>
                              <Input
                                onChange={() => null}
                                error={errors?.crewStartDate ? 'error' : null}
                                value={value ? formatDate(value) : ''}
                              />
                              <DatePickerIcon />
                            </div>
                          }
                          onChange={onChange}
                          selected={value || null}
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                  <div className="date-input">
                    <Controller
                      control={control}
                      name={'crewEndDate'}
                      defaultValue={
                        crew.crewEndDate ? getDateObject(dayjs(crew.crewEndDate)) : null
                      }
                      rules={{
                        validate: {
                          crewEndDateValid: (value) => {
                            return isEndDateValid(value);
                          },
                          isBeforeStartDate: (value) => {
                            if (dayjs(value).isBefore(dayjs(getValues('crewStartDate'), 'date'))) {
                              return false;
                            }
                          },
                        },
                      }}
                      render={({ onChange, value }) => (
                        <DatePicker
                          customInput={
                            <div className="date-picker-crew">
                              <StyledLabel>
                                {t('manage.cases-tasks:end_date', 'End Date')}
                              </StyledLabel>
                              <Input
                                onChange={() => null}
                                error={errors?.crewEndDate ? 'error' : null}
                                value={value ? formatDate(value) : ''}
                              />
                              <DatePickerIcon />
                            </div>
                          }
                          onChange={onChange}
                          selected={value || null}
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </DatesContainer>
                {/* validation error messages */}
                <div>
                  {errors?.crewStartDate?.type === 'required' && (
                    <ErrorText>
                      {t('crew.startdate_required_error', 'Start date required')}
                    </ErrorText>
                  )}
                </div>
                <div>
                  {errors?.crewStartDate?.type === 'crewStartDateValid' && (
                    <ErrorText>
                      {t(
                        'crew.startdate_valid_error',
                        'Some member start date is before crew start date',
                      )}
                    </ErrorText>
                  )}
                </div>
                <div>
                  {errors?.crewEndDate?.type === 'crewEndDateValid' && (
                    <ErrorText>
                      {t('crew.enddate_valid_error', 'Some member end date is after crew end date')}
                    </ErrorText>
                  )}
                </div>
                <div>
                  {errors?.crewEndDate?.type === 'isBeforeStartDate' && (
                    <ErrorText>
                      {t('crew.startdate_after_error', 'End date cannot be before start date')}
                    </ErrorText>
                  )}
                  {errors?.crewStartDate?.type === 'isAfterEndDate' && (
                    <ErrorText>
                      {t('crew.startdate_after_enddate', 'Start date cannot be after end date')}
                    </ErrorText>
                  )}
                </div>
              </div>
              <div className="action-buttons">
                <Button className="cancel-button" onClick={() => handleReset()}>
                  {t('general:cancel', 'Cancel')}
                </Button>
                {editWorkPlan && (
                  <Button primary onClick={handleSubmit(onSubmit)} disabled={!isSaveEnabled()}>
                    {t('general:save', 'Save')}
                  </Button>
                )}
              </div>
            </CollapsiblePanel>

            <div>
              <CollapsiblePanel headerContent={shiftDurationHeader} plusIcon>
                <div>
                  <DatesContainer>
                    <div className="date-input">
                      <Controller
                        control={control}
                        name={'crewShiftStartTime'}
                        defaultValue={crew.crewShiftStartTime}
                        rules={{
                          required: true,
                        }}
                        render={({ onChange, value }) => (
                          <Input
                            onChange={onChange}
                            value={value}
                            type="time"
                            label={t('tasks:form.start_time', 'Start Time')}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="date-input">
                      <Controller
                        control={control}
                        name={'crewShiftEndTime'}
                        defaultValue={crew.crewShiftEndTime}
                        rules={{
                          required: true,
                        }}
                        render={({ onChange, value }) => (
                          <Input
                            onChange={onChange}
                            value={value}
                            type="time"
                            label={t('tasks:form.end_time', 'End Time')}
                          />
                        )}
                      />
                    </div>
                  </DatesContainer>
                  <div>
                    {errors?.crewShiftStartTime?.type == 'required' && (
                      <ErrorText>
                        {t('crew.crewShiftStartTime_required_error', 'Start time required')}
                      </ErrorText>
                    )}
                  </div>
                  <div>
                    {errors?.crewShiftEndTime?.type == 'required' && (
                      <ErrorText>
                        {t('crew.crewShiftEndTime_required_error', 'End time required')}
                      </ErrorText>
                    )}
                  </div>
                  <div>
                    {errors?.crewShiftStartTime?.type === 'crewStartTimeValid' && (
                      <ErrorText>
                        {t(
                          'crew.crewShiftStartTimeValid_error',
                          'Some member start time is before crew start time',
                        )}
                      </ErrorText>
                    )}
                  </div>
                  <div>
                    {errors?.crewShiftEndTime?.type === 'crewEndTimeValid' && (
                      <ErrorText>
                        {t(
                          'crew.crewShiftEndTimeValid_error',
                          'Some member end time is after crew end time',
                        )}
                      </ErrorText>
                    )}
                  </div>
                  <div>
                    {errors?.crewShiftEndTime?.type === 'isAfterEndTime' && (
                      <ErrorText>
                        {t('crew.starttime_after_endtime', 'Start time cannot be after end time')}
                      </ErrorText>
                    )}
                  </div>
                  <div>
                    {errors?.crewShiftEndTime?.type === 'isBeforeStartTime' && (
                      <ErrorText>
                        {t('crew.enddate_before_error', 'End time cannot be before start time')}
                      </ErrorText>
                    )}
                  </div>
                  <RangeToggle>
                    <StyledLabel>{t('crew.apply_on', 'Apply On')}</StyledLabel>
                    {DAYS.map((day, idx) => (
                      <button
                        key={`${idx}-day`}
                        onClick={(e) => toggleDay(e, days, idx)}
                        className={days?.includes(idx) ? 'active' : null}
                      >
                        {day}
                      </button>
                    ))}
                  </RangeToggle>
                </div>
                <div className="action-buttons">
                  <Button className="cancel-button" onClick={() => handleReset()}>
                    {t('general:cancel', 'Cancel')}
                  </Button>
                  {editWorkPlan && (
                    <Button primary onClick={handleSubmit(onSubmit)}>
                      {t('general:save', 'Save')}
                    </Button>
                  )}
                </div>
              </CollapsiblePanel>
            </div>
          </div>
          <DialogWrapper
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            header={t('general:confirmation', 'Confirmation')}
            footer={getCancelFooter}
            contentWidth
            padContent={true}
          >
            <p>
              <DeleteCrew>
                {`${t(
                  'manage.planning:confirm_delete_crew',
                  'Are you sure you want to delete the crew',
                )} "${crew.name}"?`}
              </DeleteCrew>
            </p>
          </DialogWrapper>
        </CollapsiblePanel>
      </FormProvider>
    </Crew>
  );
};

TechsPanelCrew.propTypes = {
  crew: PropTypes.instanceOf(Object),
  crewData: PropTypes.instanceOf(Object),
  workerData: PropTypes.instanceOf(Object),
};

TechsPanelCrew.defaultProps = {
  crew: null,
  crewData: null,
  workerData: null,
};
