import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnTitle, ColumnDetail, DisplayLabel, FieldGroup } from '../../../task-template-shared';

import { CompletedTechsSection } from './completedtechs-section';

export const ViewAssignedTechs = ({
  task,
  section,
  workerState,
  setWorkerState,
  removeWorker,
  deleteWrkrIds,
  isLoadingMultiplePerson,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <>
      <div className="task-panel-row">
        <ColumnTitle>
          <h4>{t('section_header.assigned_crews', 'Assigned crews')}</h4>
        </ColumnTitle>
        <ColumnDetail className="column-detail">
          <FieldGroup>
            <div className="field-wrapper">
              <DisplayLabel>{t('section_header.assigned_crews', 'Assigned crews')}</DisplayLabel>
              <CompletedTechsSection
                techType={'view'}
                section={section}
                task={task}
                workerState={workerState}
                setWorkerState={setWorkerState}
                removeWorker={removeWorker}
                deleteWrkrIds={deleteWrkrIds}
                isLoadingMultiplePerson={isLoadingMultiplePerson}
              />
            </div>
          </FieldGroup>
        </ColumnDetail>
      </div>
    </>
  );
};

ViewAssignedTechs.propTypes = {
  task: PropTypes.object.isRequired,
  taskPanelControls: PropTypes.node,
  section: PropTypes.object,
  workerState: PropTypes.instanceOf(Object),
  setWorkerState: PropTypes.func,
  removeWorker: PropTypes.func,
  deleteWrkrIds: PropTypes.instanceOf(Object),
  isLoadingMultiplePerson: PropTypes.instanceOf(Boolean).isRequired,
};

ViewAssignedTechs.defaultProps = {
  taskPanelControls: null,
};
