import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { ButtonGroup, Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { PresentationTypes } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

const PresentationToggle = ({ defaultView, location, viewList }) => {
  const { push } = useHistory();
  const [isActive, setActiveState] = useState(defaultView);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const view = params.get('view');

    if (view) {
      setActiveState(view);
    } else {
      setActiveState(defaultView);
    }
  }, [isActive, location.search, defaultView]);

  const updateViewParam = (view) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search.slice(1));
    if (params.has('view')) {
      params.set('view', view);
    } else {
      params.append('view', view);
    }
    setActiveState(view);
    push(`?${params.toString()}`);
  };

  return (
    <ButtonGroup>
      {viewList.includes(PresentationTypes.DETAIL) && (
        <Button
          onClick={() => updateViewParam(PresentationTypes.DETAIL)}
          primary={isActive === PresentationTypes.DETAIL}
        >
          <Icon
            icon={Icons.DETAIL_EXPLORER}
            size={10}
            color={isActive === PresentationTypes.DETAIL ? 'white' : globalColors.slate4}
            type="button"
          />
        </Button>
      )}
      {viewList.includes(PresentationTypes.GRID) && (
        <Button
          onClick={() => updateViewParam(PresentationTypes.GRID)}
          primary={isActive === PresentationTypes.GRID}
        >
          <Icon
            icon={Icons.GRID}
            size={10}
            color={isActive === 'grid' ? 'white' : globalColors.slate4}
            type="button"
          />
        </Button>
      )}
      {viewList.includes(PresentationTypes.MAP) && (
        <Button
          onClick={() => updateViewParam(PresentationTypes.MAP)}
          primary={isActive === PresentationTypes.MAP}
        >
          <Icon
            icon={Icons.MAP}
            size={11}
            color={isActive === PresentationTypes.MAP ? 'white' : globalColors.slate4}
            type="button"
          />
        </Button>
      )}
      {viewList.includes(PresentationTypes.LIST) && (
        <Button
          onClick={() => updateViewParam(PresentationTypes.LIST)}
          primary={isActive === PresentationTypes.LIST}
        >
          <Icon
            icon={Icons.LIST}
            size={10}
            color={isActive === PresentationTypes.LIST ? 'white' : globalColors.slate4}
            type="button"
          />
        </Button>
      )}
    </ButtonGroup>
  );
};

PresentationToggle.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
  }),
  defaultView: PropTypes.oneOf(Object.values(PresentationTypes)),
  viewList: PropTypes.instanceOf(Array),
};

PresentationToggle.defaultProps = {
  location: null,
  defaultView: PresentationTypes.LIST,
  viewList: Object.values(PresentationTypes),
};

export default withRouter(PresentationToggle);
