import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { TaskTemplateModes, RealTimeCases } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import {
  getAssetActiveEvents,
  getAssetAllAplicableEvents,
  getCausalEvent,
} from '../../../../../../../apps/web-client/src/app/services/asset-events';
import { Config } from '../../../../../data-hooks';
import { DateMetaField } from '../../../../meta-fields/date-meta-field';
import { TextMetaField } from '../../../../meta-fields/text-meta-field';
import { TextareaMetaField } from '../../../../meta-fields/textarea-meta-field';
import { EventsSelectWithFilter } from '../../../events-select-with-filter';
import { PrioritySelect } from '../../../task-fields/priority-select';
import { TaskWorkScopeSelect } from '../../../task-fields/task-workscope-select';
import {
  ColumnTitle,
  ColumnDetail,
  FieldGroup,
  StyledDateContainer,
  DisplayLabel,
} from '../../../task-template-shared';

import { TaskDetailErrors } from './task-detail-errors';

const StyledTaskDetailErrors = styled(TaskDetailErrors)`
  margin: 8px 0 10px 0;
`;

const EntitySelect = styled.div`
  background: ${(props) => props.theme.select.secondaryBackground};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
  min-height: 25px;
  opacity: 0.5;
  padding: 2px 8px;
  text-transform: capitalize;
`;
const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.select.chevronColor,
}))`
  margin-top: 3px;
`;

export const EditTaskDetail = ({
  metadata,
  task,
  taskDescription,
  taskPriority,
  taskTitle,
  rules,
}) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const {
    taskState: { taskSource },
  } = useContext(TaskContext);
  const dueDate = task?.dueDateTz || null;
  const eligibleStartDate = task?.eligibleStartDateTz || null;
  const committedDate = task?.committedDateTz || null;

  const { selectedAlarmOrEvent } = useStoreState((store) => store.tasks);
  const { selectedAssetToEscalate } = useStoreState((store) => store.tasks);
  const { setSelectedAssetToEscalate } = useStoreActions((actions) => actions.tasks);
  const isRealTimeCases = RealTimeCases.includes(task?.source);
  const { selectMetaField } = useContext(EntityDetailsContext);

  // Fetch events

  const { data: causalEvents } = useQuery(
    ['causalEvents', selectedAlarmOrEvent, selectedAssetToEscalate],
    async () => getCausalEvent(task?.asset?.id),
    { ...Config.EXECUTE_ONCE, enabled: isRealTimeCases && selectMetaField },
  );

  const { data: activeEvents } = useQuery(
    ['activeEvents', selectedAlarmOrEvent, selectedAssetToEscalate],
    async () => getAssetActiveEvents(task?.asset?.id),
    {
      ...Config.EXECUTE_ONCE,
      enabled: selectMetaField && isRealTimeCases && Boolean(task?.asset?.id),
    },
  );

  let { data: allEventsOne, isLoading: isAllEventsOne } = useQuery(
    ['allEvents', selectedAlarmOrEvent, selectedAssetToEscalate],
    async () =>
      task?.asset?.id && getAssetAllAplicableEvents({ assetId: task.asset.id, pageIdx: 0 }),
    { ...Config.EXECUTE_ONCE, enabled: selectMetaField && isRealTimeCases },
  );

  useEffect(() => {
    setSelectedAssetToEscalate(task?.asset);
  }, [task?.asset, setSelectedAssetToEscalate]);

  // TODO: Started validation but waiting on styles from FutureDraft
  // const { errors } = useFormContext();
  // const { taskDetailTitle, taskDetailDescription } = errors || {};

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.task_details', 'Task Details')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup className="task-type">
          {/* DE179020:temporary fix for read only task type */}
          {/* <TaskTypeSelect /> Renamed to TaskSourceSelect*/}
          <div className="field-wrapper">
            <DisplayLabel>{t('form.source', 'Source')}</DisplayLabel>
            <EntitySelect>
              {t(`dynamic.types.${taskSource}`, taskSource)}
              <ChevronIcon />
            </EntitySelect>
          </div>
          {/* passing workscope from task obj to taskworkscopeSelect default selection */}
          <TaskWorkScopeSelect />
          {!metadata?.priority.edit.hidden && (
            <PrioritySelect
              defaultSelection={taskPriority ?? metadata.priority.defaultSelectionIndex}
              metadata={metadata.priority.edit}
              values={metadata.priority.values}
            />
          )}
        </FieldGroup>
        {(selectedAlarmOrEvent?.description || selectedAssetToEscalate?.name) &&
          isRealTimeCases && (
            <EventsSelectWithFilter
              metadata={metadata}
              causalEvents={causalEvents}
              activeEvents={activeEvents}
              allEventsOne={allEventsOne}
              assetId={task?.asset?.id}
              templateMode={TaskTemplateModes.EDIT}
              taskTitle={task?.title}
              isAllEventLoading={isAllEventsOne}
            />
          )}
        {!isRealTimeCases && (
          <FieldGroup className="title">
            {!metadata?.title.edit.hidden && (
              <TextMetaField
                defaultValue={taskTitle}
                label={t('form.title', 'Title')}
                maxLength={metadata.title.maxLength}
                metadata={metadata.title?.edit}
                name="title"
                placeholder={t('form.task_title', 'Task Title')}
                tabIndex={1}
              />
            )}
          </FieldGroup>
        )}
        <FieldGroup>
          {!metadata?.description.edit.hidden && (
            <TextareaMetaField
              defaultValue={taskDescription ?? ''}
              label={t('form.description', 'Description')}
              maxLength={metadata.description?.maxLength}
              metadata={metadata.description.edit}
              name="description"
              placeholder={t('form.description_placeholder', 'Add a Description')}
            />
          )}
        </FieldGroup>
        <FieldGroup className="dates-row">
          {!metadata?.eligibleStartDate.edit.hidden && (
            <StyledDateContainer>
              <div className="label">
                {t('form.eligible_start_date', 'Eligible Start Date')}
                {metadata.eligibleStartDate.edit.required && <span>*</span>}
              </div>
              <DateMetaField
                defaultValue={eligibleStartDate}
                defaultSelection={metadata.eligibleStartDate?.defaultSelection}
                metadata={metadata.eligibleStartDate.edit}
                name="eligibleStartDate"
                validators={metadata.eligibleStartDate?.edit.validators}
                rules={rules}
              />
            </StyledDateContainer>
          )}
          {!metadata?.dueDate.edit.hidden && (
            <StyledDateContainer>
              <div className="label">
                {t('form.due_date', 'Due Date')}
                {metadata.dueDate.edit.required && <span>*</span>}
              </div>
              <DateMetaField
                defaultValue={dueDate}
                defaultSelection={metadata.dueDate?.defaultSelection}
                metadata={metadata.dueDate.edit}
                name="dueDate"
                validators={metadata.dueDate.edit?.validators}
              />
            </StyledDateContainer>
          )}
          {metadata?.committedDate && !metadata?.committedDate?.edit.hidden && (
            <StyledDateContainer>
              <div className="label">
                {t('form.committed_date', 'Committed Date')}
                {metadata?.committedDate?.edit.required && <span>*</span>}
              </div>
              <DateMetaField
                defaultValue={committedDate}
                defaultSelection={metadata?.committedDate?.defaultSelection}
                metadata={metadata?.committedDate?.edit}
                name="committedDate"
                validators={metadata?.committedDate?.edit?.validators}
              />
            </StyledDateContainer>
          )}
        </FieldGroup>
        <StyledTaskDetailErrors />
      </ColumnDetail>
    </div>
  );
};

EditTaskDetail.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  taskDescription: PropTypes.string.isRequired,
  taskPriority: PropTypes.string.isRequired,
  taskTitle: PropTypes.string.isRequired,
  rules: PropTypes.instanceOf(Object),
  taskSource: PropTypes.String,
  taskWorkScope: PropTypes.String,
};
