import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AbnormalAssetStates, Placeholders } from '@ge/models/constants';
import { DateTimeFormats } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';

import { ReportsContext } from '../../../context/reports-context';
import { ReportWidgetTable } from '../../tables/report-widget-table';

import { assetsOfConcernColumnDefs, AssetsOfConcernColumns } from './assets-of-concern-cols';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const TruncatedSpan = styled.span`
  white-space: nowrap;
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.plannedWork.includeTextColor};
`;

const Legend = styled.ul`
  display: flex;
  justify-content: flex-end;
`;
const LegendItem = styled.li`
  list-style: none;
  margin-left: 10px;

  span {
    color: ${({ theme }) => theme.createReport.widget.headerTextColor};
    margin-left: 5px;
  }
`;

const TimestampContainer = styled.span`
  color: ${({ theme }) => theme.createReport.widget.timestamp};
`;

export const AssetsOfConcernWidget = ({
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
}) => {
  const { t, ready } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(data.map(({ id }) => id));

  const {
    reportState: { createdTime },
  } = useContext(ReportsContext);

  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom table headers for provided columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case AssetsOfConcernColumns.SELECTED:
          return <IncludeHeader>{t('assets_of_concern.columns.include')}</IncludeHeader>;
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case AssetsOfConcernColumns.SELECTED:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case AssetsOfConcernColumns.ASSET_STATE:
          return (
            <Badge
              color={getTurbineStateColor(cellValue)}
              label={getTurbineStateType(cellValue)}
              small
            />
          );
        default:
          return <TruncatedSpan>{cellValue}</TruncatedSpan>;
      }
    },
    [rowsSelected, toggleCheckedRow],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback((asset) => {
    return {
      [AssetsOfConcernColumns.SELECTED]: asset.id,
      [AssetsOfConcernColumns.ASSET_NAME]: asset.name,
      [AssetsOfConcernColumns.ASSET_SITE]: asset.siteName,
      [AssetsOfConcernColumns.ASSET_STATE]: asset.state,
      [AssetsOfConcernColumns.CASES_EVENTS_FAULT]: asset.faultCode || Placeholders.DASH,
      [AssetsOfConcernColumns.CASES_EVENTS_EVENT]: asset.faultName || Placeholders.DASH,
      [AssetsOfConcernColumns.CASES_EVENTS_DURATION]: asset.duration || Placeholders.DASH,
    };
  }, []);

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: assetsOfConcernColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  // Inspect column state to determine if the state column is visible. Used to show/hide legend.
  const stateVisible =
    columns
      ?.find((colGrp) => colGrp.id === AssetsOfConcernColumns.GROUP_ASSET_DETAILS)
      ?.cols.find((col) => col.id === AssetsOfConcernColumns.ASSET_STATE)?.visible === true;

  const timestamp = useMemo(() => {
    return createdTime?.format(DateTimeFormats.DEFAULT_DATE_TIME_SECS);
  }, [createdTime]);

  if (!ready) {
    return <Loader />;
  }

  return (
    <Container>
      <TopBar>
        {timestamp && <TimestampContainer>{timestamp} | UTC</TimestampContainer>}
        {stateVisible && (
          <Legend>
            {Object.values(AbnormalAssetStates).map((state) => (
              <LegendItem key={state}>
                <Badge
                  color={getTurbineStateColor(state)}
                  label={getTurbineStateType(state)}
                  small
                />
                <span>{t(`asset_state.${state}`)}</span>
              </LegendItem>
            ))}
          </Legend>
        )}
      </TopBar>

      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty="id"
        isEditing={isEditing}
      />
    </Container>
  );
};

AssetsOfConcernWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.string),
};
