import { areaChartOptions } from './area-chart-options';

const areaSeriesOptions = (areaChartOptions.series || [])[0] || {};

export const areaSplineChartOptions = {
  series: [
    {
      ...areaSeriesOptions,
      type: 'areaspline',
    },
  ],
};
