export const frequencyLineChartOptions = {
  chart: {
    animation: false,
    backgroundColor: 'transparent',
    type: 'line',
    height: 242,
    spacingLeft: 0,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    minPadding: 0,
    maxPadding: 0,
    visible: true,
    labels: {
      enabled: true,
      style: {
        fontSize: '10px',
      },
    },
    tickLength: 0,
    gridLineWidth: 0,
    lineWidth: 0,
    startOnTick: true,
    lineColor: 'transparent',
    tickWidth: 0,
  },
  yAxis: {
    visible: true,
    gridLineWidth: 1,
    title: '',
    labels: {
      style: {
        fontSize: '10px',
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: '',
    },
  ],
};
