import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { AlertSortOrder } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import { AlertHeaderIconMenu } from './alert-header-icon-menu';
import { AlertIconTooltip } from './alert-icon-tooltip';

const AlertIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span:not(:first-child) {
    margin-left: 2px;
  }

  & > button,
  & > div {
    margin-left: 3px;
  }
`;

export const AlertHeaderIcons = ({
  alerts = [],
  onClick,
  large,
  isAssetDetail,
  headerAlerts,
  isSiteAlerts,
}) => {
  const sortedAlerts = alerts?.sort((a, b) => AlertSortOrder[a.type] - AlertSortOrder[b.type]);
  let alertsLength = isAssetDetail ? 8 : 6;
  const priorityAlerts = sortedAlerts.splice(
    0,
    sortedAlerts.length <= alertsLength ? alertsLength : alertsLength - 1,
  );

  return (
    <AlertIconsContainer>
      {priorityAlerts?.map((alert) => (
        <AlertIconTooltip
          alert={alert}
          large={large}
          onClick={!isSiteAlerts ? (e, alert) => onClick(e, alert) : undefined}
          key={alert.id}
        />
      ))}
      {/* Remaining alerts to be rendered in count badge. */}
      {sortedAlerts.length ? (
        headerAlerts ? (
          <AlertHeaderIconMenu
            activeAlerts
            alerts={sortedAlerts}
            openAlertDialog={(e, alert) => onClick(e, alert)}
          >
            <Badge color={globalColors.stone1} label={sortedAlerts.length} small />
          </AlertHeaderIconMenu>
        ) : (
          <Badge color={globalColors.stone1} label={sortedAlerts.length} small />
        )
      ) : (
        ''
      )}
    </AlertIconsContainer>
  );
};

AlertHeaderIcons.propTypes = {
  alerts: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  isAssetDetail: PropTypes.bool,
  headerAlerts: PropTypes.bool,
  isSiteAlerts: PropTypes.bool,
};

AlertHeaderIcons.defaultValues = {
  onClick: () => null,
};
