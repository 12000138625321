import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useGlobalFilters } from '@ge/feat-analyze/hooks';
import { EventLevelDataTypes } from '@ge/feat-analyze/models';
import { downloadFile } from '@ge/feat-analyze/util';
import {
  EntityType,
  QueryKey,
  ContentType,
  DateTimeFormats,
  FileType,
  SortDirection,
} from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import { getFilename } from './use-export-graph';

const getCsvData = ({ kpiData, primaryCols, graph, labels }) => {
  if (graph.dataType === EventLevelDataTypes.IEC) {
    return {
      fields: [...primaryCols[0], labels.category, labels.categoryName, labels.kpi, labels.percent],
      data:
        kpiData
          .reduce((result, iecData) => result.concat([{ iecData }]), [])
          .sort(sorter('iecData.kpiValue', SortDirection.DESC))
          .map(({ iecData }) => [
            ...primaryCols[1],
            iecData.iecCategory,
            iecData.type,
            iecData.kpiValue,
            iecData.value,
          ]) ?? [],
    };
  }
  return {
    fields: [
      ...primaryCols[0],
      labels.category,
      labels.categoryName,
      labels.eventName,
      labels.kpi,
      labels.percent,
    ],
    data:
      kpiData
        .reduce(
          (result, iecData) =>
            result.concat(iecData.details.map((oemData) => ({ iecData, oemData })) ?? []),
          [],
        )
        .sort(sorter('oemData.kpiValue', SortDirection.DESC))
        .map(({ oemData, iecData }) => [
          ...primaryCols[1],
          iecData.iecCategory,
          iecData.type,
          `${oemData.type} - ${oemData.text}`,
          oemData.kpiValue,
          oemData.value,
        ]) ?? [],
  };
};

/**
 * Use asset IEC data.
 */
export const useIecDataDownload = () => {
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const queryClient = useQueryClient();
  const _filters = useGlobalFilters();

  const downloadPNG = () => {};

  const downloadCSV = useCallback(
    ({ entity, entityType, graph }) => {
      if (!entity || !entityType || !graph) return null;

      const { endDate, startDate } = graph.metadata ?? {};

      const datarange = t('from_date_to_date', {
        from: dayjs(startDate).format(DateTimeFormats.DEFAULT_DATE),
        to: dayjs(endDate).format(DateTimeFormats.DEFAULT_DATE),
      });

      let queryKey, queryData;

      const primaryCols = [[], []];

      if (entityType === EntityType.ASSET) {
        const siteId = entity.site?.id;
        primaryCols[0] = [t('asset_name', 'Asset Name'), t('date_time', 'Date Range')];
        primaryCols[1] = [entity.name, datarange];
        queryKey = [
          QueryKey.ASSET_IEC_DATA,
          {
            assetId: entity.id,
            category: graph.value,
            startDate,
            endDate,
            siteId,
          },
        ];
      } else if (entityType === EntityType.SITE) {
        const siteId = entity.id;
        primaryCols[0] = [t('site_name', 'Site Name'), t('date_time', 'Date Range')];
        primaryCols[1] = [entity.name, datarange];
        const filters = { ..._filters, siteIds: [siteId] };
        queryKey = [
          QueryKey.SITE_IEC_DATA,
          {
            siteId,
            category: graph.value,
            filters,
            startDate,
            endDate,
          },
        ];
      } else if (entityType === EntityType.SITES) {
        primaryCols[0] = [t('site_name', 'Site Name'), t('date_time', 'Date Range')];
        primaryCols[1] = [entity.name, datarange];
        queryKey = [
          QueryKey.ALL_SITES_IEC_DATA,
          {
            startDate,
            endDate,
            filters: _filters,
          },
        ];
      } else if (entityType === EntityType.REGION) {
        primaryCols[0] = [t('region_name', 'Region Name'), t('date_time', 'Date Range')];
        primaryCols[1] = [entity.name, datarange];
        queryKey = [
          QueryKey.ALL_SITES_IEC_DATA,
          {
            entityId: entity.id,
            entityType: EntityType.REGION,
            startDate,
            endDate,
            filters: _filters,
          },
        ];
      }
      if (entityType === EntityType.ASSET) {
        queryData = queryClient.getQueryData(queryKey)?.data ?? {};
      } else {
        const queries = queryClient.getQueryCache().findAll(queryKey, { active: true });
        queryData = queries.reduce((result, { state }) => {
          return !state.error && state.data ? { ...result, ...state.data.data } : result;
        }, {});
      }

      const unit = queryData[graph.value]?.unit;
      const unitLabel = unit ? '(' + t(`major_loss.unit.${unit}`, unit) + ')' : '';

      const { fields, data } = getCsvData({
        kpiData: queryData[graph.value]?.data ?? [],
        primaryCols,
        graph,
        labels: {
          category: t('major_loss.category', 'IEC Category'),
          categoryName: t('major_loss.category_name', 'IEC Category Name'),
          eventName: t('major_loss.event_name', 'Event Name'),
          kpi: `${graph.label} ${unitLabel}`,
          percent: `${graph.label} (${t('conditions.percent', '%')})`,
        },
      });

      const filename = getFilename(entity, graph, FileType.CSV);

      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [queryClient, _filters, t],
  );

  return { downloadPNG, downloadCSV };
};
