import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { BoldSearch } from '@ge/components/bold-search';
import { Icons, Icon } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { DataExplorerAxisType } from '@ge/feat-analyze/models/data-explorer-defs';

import { SearchInput } from './data-explorer-dialog-search';
import { SignalButton } from './data-explorer-signal-btn';
import SignalCheckbox from './data-explorer-signal-checkbox';

const SignalsContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
`;

const SignalsList = styled.ul`
  position: relative;
  margin: 0;
  padding: ${(props) => props.paddingTop}px 0 ${(props) => props.paddingBottom}px;
  li {
    line-height: 1.5;
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
`;

const AxisType = DataExplorerAxisType;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ARROW_UP,
  rotate: 90,
  color: props.theme.dataExplorer.dialog.arrowColor,
  viewbox: '0 0 11 18',
}))``;

const findSignal = (data, { id, timeAggr }) => {
  if (timeAggr)
    return data.find(
      (v) => v.id === id && String(v.timeAggr).toUpperCase() === String(timeAggr).toUpperCase(),
    );
  return data.find((v) => v.id === id);
};

const DataExplorerSignalListItems = ({
  axisIndex,
  axisType,
  signals,
  selected,
  disabled,
  checkboxes,
  filterText,
  onSelectSignal,
  onChangeFilter,
}) => {
  const scrollRef = useRef();

  const { t, ready } = useTranslation(['analyze.data-explorer', 'general'], { useSuspense: false });

  const rowVirtualizer = useVirtual({
    size: signals.length,
    parentRef: scrollRef,
    estimateSize: React.useCallback(() => 30, []),
    overscan: 108,
  });

  const searchSignals = useCallback(
    (val) => {
      onChangeFilter(val);
    },
    [onChangeFilter],
  );

  const isActive = useCallback((signal) => !!findSignal(selected, signal), [selected]);
  // Signal Should be disabled if
  // 1. number of selected tag is equal to 4
  // 2. unit of signal is not metching with selected signal
  // Signal Should not be disabled if already selected in the same axis.
  const isDisabled = useCallback(
    (signal) => {
      const isSelected = !!findSignal(selected, signal);
      if (isSelected) return false;
      if (axisType === AxisType.Y) {
        if (selected.length >= 4) return true;
        if (selected.length > 0 && selected[0].unit !== signal.unit) return true;
      }
      return !!findSignal(disabled, signal);
    },
    [selected, disabled, axisType],
  );

  const selectSignal = useCallback(
    ({ checked, signal, timeAggr }) => {
      onSelectSignal({ checked, axisIndex, axisType, signal, timeAggr });
    },
    [axisIndex, axisType, onSelectSignal],
  );

  useEffect(() => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      const activeElPos = scrollRef.current.el?.querySelector('.active');
      if (activeElPos) {
        scrollEl.scrollTop = activeElPos.getBoundingClientRect().top - 300;
      }
    }
  }, [scrollRef]);

  if (!ready) return null;

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    signals.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1]?.end : 0;

  return (
    <SignalsContainer>
      <SearchInput
        placeholder={t('general:search', 'Search')}
        onChange={(event) => {
          searchSignals(event.target.value.toLowerCase());
        }}
        value={filterText}
      />
      <ScrollingContainer ref={scrollRef}>
        <SignalsList paddingTop={paddingTop} paddingBottom={paddingBottom}>
          {items.map(({ index, measureRef }) => {
            const signal = signals[index];
            return (
              <li ref={measureRef} key={`${signal.id}__${signal.timeAggr}`} className={'grid-1'}>
                {checkboxes ? (
                  <SignalCheckbox
                    key={`${signal.id}__${signal.timeAggr}`}
                    signal={signal}
                    selectSignal={(checked) =>
                      selectSignal({ checked, signal, timeAggr: signal.timeAggr })
                    }
                    filterText={filterText}
                    disabled={isDisabled(signal)}
                    checked={isActive(signal)}
                    timeAggr={signal.timeAggr}
                  />
                ) : (
                  <SignalButton
                    key={signal.id}
                    className={classNames(
                      {
                        active: !!selected.find((v) => v.id === signal.id),
                      },
                      'grid-1',
                    )}
                    onClick={() => selectSignal({ signal })}
                  >
                    <span>
                      <BoldSearch text={signal.name} textBold={filterText} />
                    </span>{' '}
                    <ArrowIcon />
                  </SignalButton>
                )}
              </li>
            );
          })}
        </SignalsList>
      </ScrollingContainer>
    </SignalsContainer>
  );
};

DataExplorerSignalListItems.propTypes = {
  axisIndex: PropTypes.number,
  axisType: PropTypes.string,
  signals: PropTypes.array,
  selected: PropTypes.array,
  disabled: PropTypes.array,
  checkboxes: PropTypes.bool,
  signalData: PropTypes.instanceOf(Array),
  filterText: PropTypes.string,
  onSelectSignal: PropTypes.func,
  onChangeFilter: PropTypes.func,
};

DataExplorerSignalListItems.defaultProps = {
  axisIndex: 0,
  axisType: '',
  signals: [],
  selected: [],
  disabled: [],
  checkboxes: false,
  filterText: '',
  onSelectSignal: () => {},
  onChangeFilter: () => {},
  signalData: null,
};

export default DataExplorerSignalListItems;
