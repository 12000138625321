import { TableFilterTypes } from '@ge/models/constants';

export const CaseTemplateColumns = {
  GROUP_SELECTED: 'group-selected',
  SELECTED: 'selected',
  GROUP_CASE: 'group-case',
  CASE_TEMPLATE_ID: 'caseTemplateId',
  TITLE: 'title',
  CASE_SOURCE: 'source',
  GROUP_ANALYSIS: 'group-analysis',
  ANALYSIS_TEMPLATE_ID: 'analysisTemplateId',
  ANALYSIS_SOURCE: 'analyticSource',
  GROUP_BETA: 'group-beta',
  BETA: 'beta',
  GROUP_PRIORITY: 'group-priority',
  PRIORITY: 'priority',
  GROUP_TASKS: 'group-tasks',
  TASKS: 'taskCount',
  GROUP_CASE_TEMPLATE_DETAIL: 'group-caseTemplateDetail',
  CASE_TEMPLATE_DETAIL: 'caseTemplateDetail',
};

// Define all available columns in the events table.
export const CaseTemplateColumnDefs = {
  [CaseTemplateColumns.GROUP_SELECTED]: {
    locked: true,
    minWidth: '75px',
    cols: {
      [CaseTemplateColumns.SELECTED]: {
        labels: [
          {
            a11yKey: 'case_template_table.check_box',
            a11yDefault: '',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [CaseTemplateColumns.GROUP_CASE]: {
    labels: [
      {
        a11yKey: 'CASE',
        a11yDefault: 'CASE',
      },
    ],
    cols: {
      [CaseTemplateColumns.CASE_TEMPLATE_ID]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '210px',
        align: 'left',
        labels: [
          {
            a11yKey: 'case_template_table.caseTemplateID',
            a11yDefault: 'Case Template ID',
            sortValue: 'caseTemplateId',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
      },
      [CaseTemplateColumns.TITLE]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '210px',
        align: 'left',
        labels: [
          {
            a11yKey: 'case_template_table.title',
            a11yDefault: 'Title',
            sortValue: 'title',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
      },
      [CaseTemplateColumns.CASE_SOURCE]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '210px',
        align: 'left',
        labels: [
          {
            a11yKey: 'case_template_table.source',
            a11yDefault: 'Source',
            sortValue: 'source',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
      },
    },
  },
  [CaseTemplateColumns.GROUP_ANALYSIS]: {
    labels: [
      {
        a11yKey: 'case_template_table.analysis',
        a11yDefault: 'ANALYSIS',
      },
    ],
    cols: {
      [CaseTemplateColumns.ANALYSIS_TEMPLATE_ID]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '190px',
        align: 'left',
        labels: [
          {
            a11yKey: 'case_template_table.analysisTemplateId',
            a11yDefault: 'Analysis Template ID',
            sortValue: 'analysisTemplateId',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
      },
      [CaseTemplateColumns.ANALYSIS_SOURCE]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '190px',
        align: 'left',
        labels: [
          {
            a11yKey: 'case_template_table.analyticSource',
            a11yDefault: 'Source',
            sortValue: 'analyticSource',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
      },
    },
  },
  [CaseTemplateColumns.GROUP_BETA]: {
    cols: {
      [CaseTemplateColumns.BETA]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '80px',
        align: 'center',
        labels: [
          {
            a11yKey: 'case_template_table.beta',
            a11yDefault: 'Beta',
            sortValue: 'beta',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [CaseTemplateColumns.GROUP_PRIORITY]: {
    cols: {
      [CaseTemplateColumns.PRIORITY]: {
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '80px',
        align: 'center',
        labels: [
          {
            a11yKey: 'case_template_table.priority',
            a11yDefault: 'Priority',
            sortValue: 'priority',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
      },
    },
  },
  [CaseTemplateColumns.GROUP_TASKS]: {
    cols: {
      [CaseTemplateColumns.TASKS]: {
        width: '80px',
        capabilities: [
          // { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        align: 'center',
        labels: [
          {
            a11yKey: 'case_template_table.tasks',
            a11yDefault: 'Tasks',
            sortValue: 'taskCount',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [CaseTemplateColumns.GROUP_CASE_TEMPLATE_DETAIL]: {
    fixedRight: true,
    cols: {
      [CaseTemplateColumns.CASE_TEMPLATE_DETAIL]: {
        fixedRight: true,
        minWidth: '50px',
        labels: [
          {
            a11yKey: 'case_template_table.case_template_detail',
            a11yDefault: 'Template \n Detail',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '43px',
        },
      },
    },
  },
};

// Define the default visible columns and their order.
export const defaultCaseTemplateColumns = [
  {
    id: CaseTemplateColumns.GROUP_SELECTED,
    cols: [
      {
        id: CaseTemplateColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: CaseTemplateColumns.GROUP_CASE,
    cols: [
      {
        id: CaseTemplateColumns.CASE_TEMPLATE_ID,
        visible: true,
      },
      {
        id: CaseTemplateColumns.TITLE,
        visible: true,
      },
      {
        id: CaseTemplateColumns.CASE_SOURCE,
        visible: true,
      },
    ],
  },
  {
    id: CaseTemplateColumns.GROUP_ANALYSIS,
    cols: [
      {
        id: CaseTemplateColumns.ANALYSIS_TEMPLATE_ID,
        visible: true,
      },
      {
        id: CaseTemplateColumns.ANALYSIS_SOURCE,
        visible: true,
      },
      {
        id: CaseTemplateColumns.PRIORITY,
        visible: true,
      },
      {
        id: CaseTemplateColumns.TASKS,
        visible: true,
      },
    ],
  },
  {
    id: CaseTemplateColumns.GROUP_BETA,
    cols: [
      {
        id: CaseTemplateColumns.BETA,
        visible: true,
      },
    ],
  },
  {
    id: CaseTemplateColumns.GROUP_PRIORITY,
    cols: [
      {
        id: CaseTemplateColumns.PRIORITY,
        visible: true,
      },
    ],
  },
  {
    id: CaseTemplateColumns.GROUP_TASKS,
    cols: [
      {
        id: CaseTemplateColumns.TASKS,
        visible: true,
      },
    ],
  },
  {
    id: CaseTemplateColumns.GROUP_CASE_TEMPLATE_DETAIL,
    cols: [
      {
        id: CaseTemplateColumns.CASE_TEMPLATE_DETAIL,
        visible: true,
      },
    ],
  },
];
