import { PropTypes } from 'prop-types';
import React from 'react';

import { FormMode } from '@ge/models/constants';

import { AlertTemplateRow, SECTION_END_CLASS } from '../alert-shared';

// does this get expensive to crunch and do we want to find a more static way of defining key?
const getRowKey = (row = []) => row.map(({ name = '' }) => name).join('_');

export const AlertBodyTemplate = ({ mode, template }) => {
  if (!template || template.hidden) {
    return null;
  }

  return (
    <>
      {template?.metadata?.map((row, i) => (
        <AlertTemplateRow
          className={i === template.metadata.length - 1 ? SECTION_END_CLASS : undefined}
          key={getRowKey(row)}
          mode={mode}
          row={row}
        />
      ))}
    </>
  );
};

AlertBodyTemplate.propTypes = {
  mode: PropTypes.oneOf(Object.values(FormMode)),
  template: PropTypes.object,
};

AlertBodyTemplate.defaultProps = {
  mode: FormMode.VIEW,
  template: undefined,
};
