import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useReducer, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import combineReducers from 'react-image-annotate/Annotator/reducers/combine-reducers';
import historyHandler from 'react-image-annotate/Annotator/reducers/history-handler';
import imageReducer from 'react-image-annotate/Annotator/reducers/image-reducer';
import videoReducer from 'react-image-annotate/Annotator/reducers/video-reducer';
import SettingsProvider from 'react-image-annotate/SettingsProvider';
import makeImmutable, { without } from 'seamless-immutable';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Button } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';
import { useEventCallback } from '@ge/shared/hooks';

import { AnnotatorConstants, imagePropType } from '../../constants';
import { saveAnnotationToServer } from '../image-utils';
import MainLayout from '../main-layout/main-layout';

import generalReducer from './reducers/general-reducer';

const RulerHeader = styled.div`
  border-radius: 6px;
  background-color: ${(props) => props.theme.annotation.containerHeaderColor};
  text-align: left;
  text-transform: uppercase;
`;

const RulerFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const RulerFooter = ({ onClick, onSubmit }) => {
  const { t } = useTranslation(['inspections.post-process']);

  return (
    <RulerFooterWrapper>
      <FooterButtons>
        <Button onClick={onClick}>{t('cancel', 'Cancel')}</Button>
        <Button primary onClick={onSubmit}>
          {t('apply', 'Apply')}
        </Button>
      </FooterButtons>
    </RulerFooterWrapper>
  );
};

const StyledInput = styled.input`
  background-color: ${(props) => props.theme.postProcess.inputBackgroundColor};
  border: ${(props) => props.theme.postProcess.inputBorder};
  border-radius: 2px;
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.postProcess.inputColor};
  margin-top: 8px;
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;
`;

RulerFooter.propTypes = {
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

RulerFooter.defaultProps = {
  onClick: () => {},
  onSubmit: () => {},
};

const annotationType = AnnotatorConstants.IMAGE;

const initialAnnotationParameters = {
  family: '',
  sub_family: '',
  family_desc: '',
  anomaly_mapping: '',
  annotation_type: '',
  implication: '',
  severity: '',
  source: '',
  root_cause: '',
  comments: '',
  action: '',
  side: '',
  ai_ref: '',
  z: '',
  length: '',
  width: '',
  chord: '',
  // visible: 'true',
  defect_type: '',
  inspector_lid: 1,
  included_in_report: '',
};

const Annotator = ({
  allowedArea,
  currentImage,
  selectedImageSrc = currentImage?.src,
  showPointDistances,
  pointDistancePrecision,
  showTags = true,
  enabledTools = [
    AnnotatorConstants.SELECT,
    AnnotatorConstants.CREATE_POINT,
    AnnotatorConstants.CREATE_BOX,
    AnnotatorConstants.CREATE_POLYGON,
    AnnotatorConstants.CREATE_RULER,
    AnnotatorConstants.CREATE_EXPANDING_LINE,
    AnnotatorConstants.SHOW_DASH_MASK,
    AnnotatorConstants.IMAGE_ENHANCER,
  ],
  selectedTool = AnnotatorConstants.PAN,
  regionTagList = [],
  regionClsList = [],
  imageTagList = [],
  imageClsList = [],
  taskDescription,
  fullImageSegmentationMode = false,
  RegionEditLabel,
  videoTime = 0,
  videoName,
  onExit,
  onNextImage,
  onPrevImage,
  autoSegmentationOptions = { type: 'autoseg' },
  selectedTools,
  onModalOpen,
  onChangeImage,
  onRegionSelect,
  onRulerCreate,
  onAnnotationAdd = () => {},
  selectedAnnotationId,
  updateSelectedAnnotationId,
  isLoading,
  SliderValueContext,
  selectedVideo,
}) => {
  const { t } = useTranslation(['inspections.post-process']);
  const [rulerDialog, setRulerDialog] = useState(false);
  const [rulerLength, setRulerLength] = useState(0);
  // TODO: this needs to be moved out of /packages/....
  const { createAnnotation } = useStoreActions((actions) => actions.inspections);
  const user_details = JSON.parse(
    Object.values(sessionStorage).filter((key) => key.includes('profile'))[0],
  );
  const [state, dispatchToReducer] = useReducer(
    historyHandler(
      combineReducers(
        annotationType === AnnotatorConstants.IMAGE ? imageReducer : videoReducer,
        generalReducer,
      ),
    ),

    makeImmutable({
      annotationType,
      showTags,
      allowedArea,
      showPointDistances,
      pointDistancePrecision,
      selectedTool,
      fullImageSegmentationMode,
      autoSegmentationOptions,
      mode: null,
      taskDescription,
      showMask: true,
      labelImages: imageClsList.length > 0 || imageTagList.length > 0,
      regionClsList,
      regionTagList,
      imageClsList,
      imageTagList,
      currentVideoTime: videoTime,
      enabledTools,
      history: [],
      videoName,
      selectedImageSrc,
      currentImage,
      selectedImageFrameTime: currentImage?.frameTime || undefined,
    }),
  );
  const { sliderValue, setSliderValue } = useContext(SliderValueContext);

  const dispatch = useEventCallback((action) => {
    if (action.type === AnnotatorConstants.HEADER_BUTTON_CLICKED) {
      if (['Exit', 'Done', 'Save', 'Complete'].includes(action.buttonName)) {
        return onExit(without(state, AnnotatorConstants.HISTORY));
      }
      if (action.buttonName === 'Next' && onNextImage) {
        return onNextImage(without(state, AnnotatorConstants.HISTORY));
      }
      if (action.buttonName === 'Prev' && onPrevImage) {
        return onPrevImage(without(state, AnnotatorConstants.HISTORY));
      }
    }
    if (action.type === AnnotatorConstants.SELECT_TOOL) {
      onRegionSelect(null);
    }

    dispatchToReducer(action);

    if (
      state.selectedTool === AnnotatorConstants.CREATE_BOX &&
      action.type === AnnotatorConstants.MOUSE_UP &&
      state.mode &&
      state.mode.mode === AnnotatorConstants.RESIZE_BOX
    ) {
      const { regions } = state.currentImage;
      if (regions?.length > 0) {
        const side_val = selectedVideo?.attributes.side || '';
        const box = regions[regions.length - 1];
        const annotation = {
          ...initialAnnotationParameters,
          annotation_type: AnnotatorConstants.BOX,
          ai_ref: AnnotatorConstants.HUMAN,
          // set side to '', so it'll be loaded in the parameter panel
          side: side_val,
          inspector_sso: user_details ? user_details.profile.preferred_username : '',
        };
        const svgMetadata = {
          polygonId: box.id,
        };
        const { x, y, w, h } = box;
        const points = [
          [x, y],
          [x, y + h],
          [x + w, y + h],
          [x + w, y],
        ];
        createAnnotation({
          ...saveAnnotationToServer(currentImage, annotation, points, svgMetadata, true),
        }).then(({ id, annoAttrs }) => {
          onAnnotationAdd({ id, annoAttrs });
        });
        dispatchToReducer({
          type: AnnotatorConstants.SELECT_TOOL,
          selectedTool: AnnotatorConstants.SELECT,
        });
      }
    } else if (
      state.selectedTool === AnnotatorConstants.CREATE_POLYGON &&
      action.type === AnnotatorConstants.BEGIN_MOVE_POLYGON_POINT &&
      state.mode &&
      state.mode.mode === AnnotatorConstants.DRAW_POLYGON
    ) {
      const side_val = selectedVideo?.attributes.side || '';
      const { regions } = state.currentImage;
      if (regions && regions.length > 0) {
        const polygon = regions[regions.length - 1];
        const annotation = {
          ...initialAnnotationParameters,
          annotation_type: AnnotatorConstants.POLYGON,
          ai_ref: AnnotatorConstants.HUMAN,
          // set side to '', so it'll be loaded in the parameter panel
          side: side_val,
          inspector_sso: user_details ? user_details.profile.preferred_username : '',
        };
        const svgMetadata = {
          polygonId: polygon.id,
        };
        const { points } = polygon;
        createAnnotation({
          ...saveAnnotationToServer(currentImage, annotation, points, svgMetadata, true),
        }).then(({ id, annoAttrs }) => {
          onAnnotationAdd({ id, annoAttrs });
        });
        dispatchToReducer({
          type: AnnotatorConstants.SELECT_TOOL,
          selectedTool: AnnotatorConstants.SELECT,
        });
      }
    } else if (
      state.selectedTool === AnnotatorConstants.CREATE_RULER &&
      action.type === AnnotatorConstants.MOUSE_DOWN &&
      state.mode &&
      state.mode.mode === AnnotatorConstants.DRAW_RULER
    ) {
      setRulerDialog(true);
    } else if (
      action.selectedTool === AnnotatorConstants.IMAGE_ENHANCER &&
      action.type === AnnotatorConstants.SELECT_TOOL
    ) {
      setSliderValue({
        ...sliderValue,
        enableImageEnhance: !sliderValue.enableImageEnhance,
      });
    }
  });

  const handleRulerDialogClosed = useCallback(() => {
    const { regions } = state.currentImage;
    if (regions && regions.length > 0) {
      const ruler = regions[regions.length - 1];
      const { points } = ruler;
      const { width: w, height: h } = currentImage?.attributes;
      const distance = Math.sqrt(
        (points[0][0] - points[1][0]) * (points[0][0] - points[1][0]) * w * w +
          (points[0][1] - points[1][1]) * (points[0][1] - points[1][1]) * h * h,
      );
      const scaling = rulerLength / distance;
      if (typeof onRulerCreate === 'function')
        onRulerCreate({
          flagNew: 1,
          data: {
            ...currentImage?.attributes,
            scaling,
          },
        });
    }
    setRulerDialog(false);
    setRulerLength(0);
    dispatchToReducer({
      type: AnnotatorConstants.SELECT_TOOL,
      selectedTool: AnnotatorConstants.CREATE_RULER,
    });
    dispatchToReducer({
      type: AnnotatorConstants.SELECT_TOOL,
      selectedTool: AnnotatorConstants.SELECT,
    });
  }, [currentImage?.attributes, onRulerCreate, rulerLength, state.currentImage]);

  const onRegionClassAdded = useEventCallback((cls) => {
    dispatchToReducer({
      type: AnnotatorConstants.ON_CLS_ADDED,
      cls,
    });
  });

  useDeepCompareEffect(() => {
    if (selectedImageSrc) {
      dispatchToReducer({
        type: AnnotatorConstants.SELECT_IMAGE,
        image: currentImage,
      });
    }
  }, [currentImage, selectedImageSrc]);

  return (
    <SettingsProvider>
      <MainLayout
        key={currentImage.id}
        RegionEditLabel={RegionEditLabel}
        alwaysShowNextButton={Boolean(onNextImage)}
        alwaysShowPrevButton={Boolean(onPrevImage)}
        state={state}
        dispatch={dispatch}
        onRegionClassAdded={onRegionClassAdded}
        selectedTools={selectedTools}
        onModalOpen={onModalOpen}
        onChangeImage={onChangeImage}
        onRegionSelect={onRegionSelect}
        selectedAnnotationId={selectedAnnotationId}
        updateSelectedAnnotationId={updateSelectedAnnotationId}
        isLoading={isLoading}
        SliderValueContext={SliderValueContext}
      />
      <Dialog
        isOpen={rulerDialog}
        onClose={handleRulerDialogClosed}
        header={<RulerHeader>{t('confirm_header', 'Confirmation')}</RulerHeader>}
        footer={
          <RulerFooter
            onClick={() => {
              setRulerDialog(false);
              dispatchToReducer({
                type: AnnotatorConstants.SELECT_TOOL,
                selectedTool: AnnotatorConstants.CREATE_RULER,
              });
              dispatchToReducer({
                type: AnnotatorConstants.SELECT_TOOL,
                selectedTool: AnnotatorConstants.SELECT,
              });
            }}
            onSubmit={handleRulerDialogClosed}
          />
        }
      >
        <div>{t('ruler_dialog', `What's the length of this ruler (in mm)?`)}</div>
        <StyledInput
          type="number"
          value={rulerLength}
          onChange={(e) => setRulerLength(e.target.value)}
        />
      </Dialog>
    </SettingsProvider>
  );
};

Annotator.propTypes = {
  allowedArea: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }),
  selectedImageSrc: PropTypes.string,
  showPointDistances: PropTypes.bool,
  pointDistancePrecision: PropTypes.number,
  showTags: PropTypes.bool,
  enabledTools: PropTypes.arrayOf(PropTypes.string),
  selectedTool: PropTypes.string,
  regionTagList: PropTypes.arrayOf(PropTypes.string),
  regionClsList: PropTypes.arrayOf(PropTypes.string),
  imageTagList: PropTypes.arrayOf(PropTypes.string),
  imageClsList: PropTypes.arrayOf(PropTypes.string),
  taskDescription: PropTypes.string,
  fullImageSegmentationMode: PropTypes.bool,
  RegionEditLabel: PropTypes.elementType,
  videoTime: PropTypes.number,
  videoName: PropTypes.string,
  onExit: PropTypes.func,
  onNextImage: PropTypes.func,
  onPrevImage: PropTypes.func,
  autoSegmentationOptions: PropTypes.shape({
    type: PropTypes.string,
  }),
  currentImage: imagePropType,

  selectedTools: PropTypes.shape({
    size: PropTypes.string.isRequired,
    clear: PropTypes.bool.isRequired,
    adrAnnotation: PropTypes.bool.isRequired,
    humanAnnotation: PropTypes.bool.isRequired,
    thirdPartyAnnotation: PropTypes.bool.isRequired,
    includedInReport: PropTypes.bool.isRequired,
  }),
  onModalOpen: PropTypes.func,
  onChangeImage: PropTypes.func,
  onRegionSelect: PropTypes.func,
  onAnnotationAdd: PropTypes.func,
  onRulerCreate: PropTypes.func,
  selectedAnnotationId: PropTypes.number,
  updateSelectedAnnotationId: PropTypes.func,
  isLoading: PropTypes.bool,
  SliderValueContext: PropTypes.any,
  selectedVideo: PropTypes.instanceOf(Object),
};

export default Annotator;
