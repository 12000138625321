import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { EventIdType, QueryKey, TimeUnits } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { fetchAssetsSignalData } from '@ge/shared/services/signal-data';
import { mergeSignalData, splitDates } from '@ge/shared/util';
import { getDateTz } from '@ge/shared/util/time-date';

const OPERATING_HOURS_CONVERSION = 60 * 60 * 1000;
// are these already defined in enums somewhere?
const OPERATING_HOURS_SIGNAL_ID = 'WTUR.TotOpTm';
const OPERATING_HOURS_SIGNAL = {
  id: OPERATING_HOURS_SIGNAL_ID,
  type: EventIdType.CANONICAL,
  timeAggr: 'SAM_10M',
};

const useSignalData = ({ assetIds, config, endDate, startDate }) => {
  const logger = useLogger();

  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const { dates, queryKey, queryParams } = useMemo(() => {
    let start, end;
    let dates = 0;
    if (dayjs(endDate).diff(dayjs(startDate), 'month') >= 1) {
      dates = splitDates(startDate, endDate, 15);
      ({ start, end } = dates[pageNumber] ?? {});
    } else [start, end] = [startDate, endDate];

    return {
      dates,
      queryKey: {
        assetIds,
        start,
        end,
      },
      queryParams: {
        start,
        end,
        signals: [OPERATING_HOURS_SIGNAL],
      },
    };
  }, [assetIds, startDate, endDate, pageNumber]);

  const { isLoading, error } = useQuery(
    [QueryKey.REPORTING_SIGNAL_DATA, queryKey],
    () => fetchAssetsSignalData(assetIds, queryParams),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(assetIds?.length && endDate && startDate) && (config.enabled ?? true),
      onSuccess: (res) => {
        setData((prev) => mergeSignalData(prev, res));
        if (pageNumber < dates.length - 1) {
          setPageNumber(pageNumber + 1);
        }
      },
    },
  );

  if (error) {
    logger.error(error);
  }
  return { data, error, isLoading };
};

// can make this more generic to support add'l types of historical signal data if needed
export const useWidgetOperatingHours = ({ config = Config.EXECUTE_ONCE, endDate, startDate }) => {
  const { getAssetsBySiteIds } = useStoreState((state) => state.assets);

  const { siteIds, timezone } = useReportScope();

  const assetIds = useMemo(
    () => getAssetsBySiteIds(siteIds).map(({ id }) => id),
    [getAssetsBySiteIds, siteIds],
  );

  const {
    data: signalData,
    error,
    isLoading,
  } = useSignalData({
    assetIds,
    config,
    endDate: getDateTz(endDate, timezone).endOf('d').toISOString(),
    startDate: getDateTz(startDate, timezone).startOf('d').toISOString(),
  });

  const assetCount = assetIds?.length;

  const data = useMemo(() => {
    if (!signalData?.data?.length) {
      return { units: TimeUnits.HOURS };
    }

    // explicitly differentiating between null and 0 values here, can revisit if needed
    const operatingMilliseconds = signalData.data.reduce((operatingHours, { signalData }) => {
      const assetOperatingHoursData =
        signalData?.[signalData?.length - 1]?.[OPERATING_HOURS_SIGNAL_ID] ?? null;

      if (assetOperatingHoursData !== null) {
        const assetOperatingHours = Number(assetOperatingHoursData);

        operatingHours = (operatingHours ?? 0) + assetOperatingHours;
      }

      return operatingHours;
    }, null);

    const value = operatingMilliseconds ? operatingMilliseconds / OPERATING_HOURS_CONVERSION : null;

    return {
      assetCount,
      units: TimeUnits.HOURS,
      value,
    };
  }, [assetCount, signalData]);

  return { data, error, isLoading };
};
