import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useCallback, forwardRef } from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const ToggleSwitch = styled.button`
  background: ${(props) => props.theme.entityDetails.notes.toggleButtonDisabledColor};
  height: 20px;
  width: 34px;
  border-radius: 15px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.entityDetails.notes.toggleButtonBorderColor};
  &.active-button {
    background: ${(props) => props.theme.entityDetails.notes.toggleButtonActiveColor};
  }
`;

const KnobActive = styled.div`
  background: ${(props) => props.theme.entityDetails.notes.knobActiveColor};
  position: relative;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  left: -6px;
  transition: left 0.3s ease-out;
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
  font-size: 10px;
  font-weight: 900;
  &.active {
    background: ${(props) => props.theme.entityDetails.notes.knobDisabledColor};
    box-sizing: border-box;
    left: 10px;
    color: ${(props) => props.theme.entityDetails.notes.tickColor};
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 13px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

export const ToggleButton = forwardRef(
  ({ className, isToggleOn, setIsToggleOn, label, handleOnClick, noIcon, theme }, ref) => {
    const handleClick = useCallback(() => {
      setIsToggleOn && setIsToggleOn((toggle) => !toggle);
      handleOnClick && handleOnClick();
    }, [setIsToggleOn, handleOnClick]);

    return (
      <ToggleWrapper className={className}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <ToggleSwitch
          onClick={handleClick}
          className={classNames('toggle-switch', { 'active-button': isToggleOn })}
          ref={ref}
          tabIndex="0"
          theme={theme}
        >
          <KnobActive theme={theme} className={isToggleOn ? 'active' : ''}>
            {!noIcon && isToggleOn && <>&#10004;</>}
          </KnobActive>
        </ToggleSwitch>
      </ToggleWrapper>
    );
  },
);

ToggleButton.displayName = 'Togglebutton';

ToggleButton.propTypes = {
  isToggleOn: PropTypes.bool,
  setIsToggleOn: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleOnClick: PropTypes.func,
  label: PropTypes.string,
  noIcon: PropTypes.bool,
  theme: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};
