import { AssetsColumns } from '@ge/shared/models/table-col-defs/assets-table-cols';

// Define the default visible columns and their order.
export const defaultAssetsCols = [
  {
    id: AssetsColumns.GROUP_ASSETS,
    cols: [
      {
        id: AssetsColumns.GROUP,
        visible: true,
      },
      {
        id: AssetsColumns.SITE,
        visible: true,
      },
      {
        id: AssetsColumns.TYPE,
        visible: true,
      },
      {
        id: AssetsColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_STATE,
    cols: [
      {
        id: AssetsColumns.STATE,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_ACTIVE_POWER,
    cols: [
      {
        id: AssetsColumns.ACTUAL,
        visible: true,
      },
      {
        id: AssetsColumns.RATED,
        visible: true,
      },
      {
        id: AssetsColumns.POWER_CHART,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_EVENTS,
    cols: [
      {
        id: AssetsColumns.CODE,
        visible: true,
      },
      {
        id: AssetsColumns.SEVERITY,
        visible: true,
      },
      {
        id: AssetsColumns.DESCRIPTION,
        visible: true,
      },
      {
        id: AssetsColumns.FLAGGED,
        visible: true,
      },
      {
        id: AssetsColumns.LOGIC,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_STATE_DURATION,
    cols: [
      {
        id: AssetsColumns.STATE_DURATION,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_TICKETS,
    cols: [
      {
        id: AssetsColumns.ANOMALIES,
        visible: true,
      },
      {
        id: AssetsColumns.ROC,
        visible: true,
      },
      {
        id: AssetsColumns.SERVICE,
        visible: true,
      },
      {
        id: AssetsColumns.TASKS,
        visible: true,
      },
      {
        id: AssetsColumns.NEW,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_WEEKLY_AVAILABILITY,
    cols: [
      {
        id: AssetsColumns.WEEKLY_AVAILABILITY,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_CONDITIONS,
    cols: [
      {
        id: AssetsColumns.WIND,
        visible: true,
      },
      {
        id: AssetsColumns.TEMP,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_SERIAL_NUMBER,
    cols: [
      {
        id: AssetsColumns.SERIAL_NUMBER,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_SYSTEM_NUMBER,
    cols: [
      {
        id: AssetsColumns.SYSTEM_NUMBER,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_CUSTOMER,
    cols: [
      {
        id: AssetsColumns.CUSTOMER,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_ASSET_MAKE,
    cols: [
      {
        id: AssetsColumns.ASSET_MAKE,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_ASSET_MODEL,
    cols: [
      {
        id: AssetsColumns.ASSET_MODEL,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_REACTIVE_POWER,
    cols: [
      {
        id: AssetsColumns.REACTIVE_POWER,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_AUTOMATION_ENABLED,
    cols: [
      {
        id: AssetsColumns.AUTOMATION_ENABLED,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_PRICE_MWH,
    cols: [
      {
        id: AssetsColumns.PRICE_MWH,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_LOST_REVENUE,
    cols: [
      {
        id: AssetsColumns.LOST_REVENUE,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_FAULT_RATE,
    cols: [
      {
        id: AssetsColumns.FAULT_RATE,
        visible: true,
      },
    ],
  },
  {
    id: AssetsColumns.GROUP_SERVICE_LEADER,
    cols: [
      {
        id: AssetsColumns.SERVICE_LEADER,
        visible: true,
      },
    ],
  },
];
