import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentType, DateTimeFormats, FileExtensionType, FileType } from '@ge/models/constants';
import { AnomaliesColumnDefs, AnomaliesColumns } from '@ge/shared/models/table-col-defs';

import { downloadFile } from '../util';

/**
 * Use assets power curve data download.
 */

const getCsvData = ({ visibleAnomalyCols, t, anomaliesData }) => {
  const fields = [];
  const data = [];
  anomaliesData.map((values, key) => {
    data[key] = [];
    visibleAnomalyCols.map((column) => {
      column.cols.map((colmnKey) => {
        if (colmnKey.visible) {
          switch (colmnKey.id) {
            case AnomaliesColumns.SELECTED:
            case AnomaliesColumns.NEW_TASK:
            case AnomaliesColumns.TROUBLESHOOTING:
            case AnomaliesColumns.CASE_DETAIL:
              break;
            case AnomaliesColumns.DESCRIPTION:
            case AnomaliesColumns.ASSET:
            case AnomaliesColumns.SITE:
            case AnomaliesColumns.NOTES:
              data[key].push(values[colmnKey.id]['description']);
              fields.push(
                t(
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yKey'
                  ],
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yDefault'
                  ],
                ),
              );
              break;
            case AnomaliesColumns.PRIORITY:
              data[key].push(values[colmnKey.id]['description']);
              fields.push(
                t(
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yKey'
                  ],
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yDefault'
                  ],
                ),
              );
              break;
            case AnomaliesColumns.ESCALATED: {
              const workScope =
                values[colmnKey.id]['totalTaskCount'] > 0
                  ? values[colmnKey.id]['workScope']
                    ? values[colmnKey.id]['workScope']
                    : 'N/D'
                  : '';
              const taskCount =
                values[colmnKey.id]['totalTaskCount'] > 1
                  ? `+${values[colmnKey.id]['totalTaskCount'] - 1}`
                  : '';
              data[key].push(`${workScope} ${taskCount}`);
              fields.push(
                t(
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yKey'
                  ],
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yDefault'
                  ],
                ),
              );
              break;
            }
            case AnomaliesColumns.STATUS_DETAIL:
              data[key].push(values[colmnKey.id]['description']);
              fields.push(
                t(
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yKey'
                  ],
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yDefault'
                  ],
                ),
              );
              break;
            case AnomaliesColumns.LAST_FLAGGED:
            case AnomaliesColumns.CREATE_DATE:
            case AnomaliesColumns.FIXED_DATE:
              data[key].push(
                values[colmnKey.id]
                  ? dayjs(values[colmnKey.id]).format(DateTimeFormats.DEFAULT_DATE)
                  : '',
              );
              fields.push(
                t(
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yKey'
                  ],
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yDefault'
                  ],
                ),
              );
              break;
            default:
              data[key].push(values[colmnKey.id]);
              fields.push(
                t(
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yKey'
                  ],
                  AnomaliesColumnDefs[`${column.id}`]['cols'][`${colmnKey.id}`]['labels'][0][
                    'a11yDefault'
                  ],
                ),
              );
              break;
          }
        }
      });
    });
  });
  return { fields: [...new Set(fields)], data };
};

export const useExportAnomaliesTable = ({ anomaliesData }) => {
  const { t } = useTranslation(['monitor.issues'], {
    useSuspense: false,
  });

  const onExportData = useCallback(
    (visibleAnomalyCols) => {
      const { fields, data } = getCsvData({ visibleAnomalyCols, t, anomaliesData });
      const filename = `Anomalies${FileExtensionType[FileType.CSV]}`;
      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [anomaliesData, t],
  );

  return { onExportData };
};
