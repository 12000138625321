import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { NavTab } from '@ge/components/navigation';

import { Breakpoints } from '../../../assets/styles/mixins';

const ViewControlsWrapper = styled.div`
  border-bottom: solid 3px ${(props) => props.theme.subNavigation.linkBorderColor};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 0 20px;
  &:last-child {
    margin-left: auto;

    ${Breakpoints.tabletDown`
      align-items: center;
      display: flex;
      min-height: 32px;
      justify-content: flex-end;
  `}
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Ul = styled.ul`
  list-style: none;
  min-height: 35px;
  margin: 0;
  padding: 0;
  display: flex;
`;

const Li = styled.li`
  list-style: none;
  min-width: 140px;
  max-width: 100%;
  position: relative;

  ${Breakpoints.tabletDown`
    transition: width 200ms ease-out;
    width: 118px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`;

const SubNavigation = ({ navItems, className, children }) => {
  const { t } = useTranslation(['nav']);

  return (
    <Nav className={className}>
      {navItems && (
        <Ul>
          {navItems &&
            navItems.map((item) => (
              <Li key={item.route}>
                <NavTab
                  secondary
                  exact={item?.withParams ? false : true}
                  to={item.route}
                  activeClassName="active"
                >
                  {t(item.i18nKey, item.label)}
                </NavTab>
              </Li>
            ))}
        </Ul>
      )}
      <ViewControlsWrapper>{children}</ViewControlsWrapper>
    </Nav>
  );
};

SubNavigation.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  navItems: PropTypes.instanceOf(Array),
};

SubNavigation.defaultProps = {
  className: null,
  children: null,
  navItems: [],
};

export default withRouter(SubNavigation);
