import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateResolutionNote } from './create-resolution-note';
import { EditResolutionNote } from './edit-resolution-note';
import { ViewResolutionNote } from './view-resolution-note';

export const ResolutionNoteSection = ({ section, task, templateMode, entity }) => {
  const { metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return <CreateResolutionNote metadata={metadata} entity={entity} />;
      case TaskTemplateModes.EDIT:
        return <EditResolutionNote metadata={metadata} task={task} />;
      case TaskTemplateModes.VIEW:
        return <ViewResolutionNote metadata={metadata} task={task} />;
      default:
        return null;
    }
  }
};

ResolutionNoteSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

ResolutionNoteSection.defaultProps = {
  task: {},
};
