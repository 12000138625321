import { PropTypes } from 'prop-types';

import { ChartType } from '@ge/components/charts';

export const chartTypePropType = PropTypes.oneOf(Object.values(ChartType));

export const chartIconPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ChartType)).isRequired,
});

export const entityPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
});

export const chartPropTypes = PropTypes.shape({
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.oneOf(Object.values(ChartType)).isRequired,
});

export const chartDefPropTypes = PropTypes.shape({
  alternate: chartPropTypes,
  default: chartPropTypes.isRequired,
});
