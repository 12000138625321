import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useState, useCallback, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { useAssetPowerCurveDownload } from '@ge/feat-analyze/data-hooks/use-asset-power-curve-download';
import { useConditionDataDownload } from '@ge/feat-analyze/data-hooks/use-condition-data-download';
import { useIecDataDownload } from '@ge/feat-analyze/data-hooks/use-iec-data-download';
import { useKpiDataDownload } from '@ge/feat-analyze/data-hooks/use-kpi-data-download';
import { ChartNames } from '@ge/feat-analyze/models/constants';
import { EntityType, ContentType } from '@ge/models/constants';
import { DateTimeFormats, FileExtensionType, FileType } from '@ge/models/constants';
import { exportAsImage } from '@ge/util';

const getKPIChartState = (currentType, graphDef) => {
  if (currentType?.id === 'alternate') {
    const { categories, ...rest } = graphDef?.alternate ?? {};
    return { categories: categories?.map(({ key }) => key) ?? [], ...rest };
  }
  const { categories, ...rest } = graphDef?.default ?? {};
  return { categories: categories?.map(({ key }) => key) ?? [], ...rest };
};

export const getFilename = (entity, graph, extension) =>
  `${entity.name}-${graph.title}-${graph.label}-${dayjs().format(
    DateTimeFormats.FILE_DOWNLOAD_DATE_TIME,
  )}${FileExtensionType[extension]}`;

/**
 * Use export graph actions.
 */
export const useExportGraphActions = () => {
  const themeContext = useContext(ThemeContext);

  const chartRefs = useRef({});

  const { downloadCSV: downloadPowerCurve } = useAssetPowerCurveDownload();
  const { downloadCSV: downloadPareto } = useIecDataDownload();
  const { downloadCSV: downloadConditionData } = useConditionDataDownload();
  const { downloadCSV: downloadKpiData } = useKpiDataDownload();

  const download = ({ exportType, graphs = {}, ...rest }) => {
    if (exportType === ContentType.CSV) {
      if (graphs[ChartNames.POWER_CURVE]) {
        downloadPowerCurve({ graph: graphs[ChartNames.POWER_CURVE], ...rest });
      }
      if (graphs[ChartNames.PARETO]) {
        downloadPareto({ graph: graphs[ChartNames.PARETO], ...rest });
      }
      if (graphs[ChartNames.CONDITION]) {
        downloadConditionData({ graph: graphs[ChartNames.CONDITION], ...rest });
      }
      if (graphs[ChartNames.TRENDS]) {
        downloadKpiData({ graph: graphs[ChartNames.TRENDS], ...rest });
      }
    } else if (exportType === ContentType.PNG) {
      const defaultExportOptions = {
        backgroundColor: themeContext.backgroundColor,
        scale: 1.5,
      };
      let chart, fileName;
      if (graphs[ChartNames.POWER_CURVE]) {
        chart = chartRefs.current[ChartNames.POWER_CURVE];
        fileName = getFilename(rest.entity, graphs[ChartNames.POWER_CURVE], FileType.PNG);
        if (chart)
          exportAsImage(
            chart,
            fileName,
            defaultExportOptions,
            themeContext.select.basicTextColor,
            graphs[ChartNames.POWER_CURVE],
            rest,
          );
      }
      if (graphs[ChartNames.PARETO]) {
        chart = chartRefs.current[ChartNames.PARETO];
        fileName = getFilename(rest.entity, graphs[ChartNames.PARETO], FileType.PNG);
        if (chart)
          exportAsImage(
            chart,
            fileName,
            defaultExportOptions,
            themeContext.select.basicTextColor,
            graphs[ChartNames.PARETO],
            rest,
          );
      }
      if (graphs[ChartNames.CONDITION]) {
        chart = chartRefs.current[ChartNames.CONDITION];
        fileName = getFilename(rest.entity, graphs[ChartNames.CONDITION], FileType.PNG);
        if (chart)
          exportAsImage(
            chart,
            fileName,
            defaultExportOptions,
            themeContext.select.basicTextColor,
            graphs[ChartNames.CONDITION],
            rest,
          );
      }
      if (graphs[ChartNames.TRENDS]) {
        chart = chartRefs.current[ChartNames.TRENDS];
        fileName = getFilename(rest.entity, graphs[ChartNames.TRENDS], FileType.PNG);
        if (chart)
          exportAsImage(
            chart,
            fileName,
            defaultExportOptions,
            themeContext.select.basicTextColor,
            graphs[ChartNames.TRENDS],
            rest,
          );
      }
    }
  };

  return { download, chartRefs };
};

/**
 * Use export graph.
 *
 * @param isLoading the kpi or iec chart is loading
 * @param kpiChartState the kpi chart state
 * @param timeAggregation the kpi chart time aggr
 * @param currentIecCategory the iec category
 * @param selectedAssetIds assetIds for Power Curve metadata
 * @param entity the region or sites entity
 * @param entityType the region or sites entity type
 * @param dateRange the date range object
 */
export const useExportGraph = ({
  isLoading,
  kpiChartState,
  timeAggregation,
  currentIecCategory,
  selectedAssetIds,
  entity,
  entityType,
  dateRange,
}) => {
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const [graphs, setGraphs] = useState([]);
  const [currentType, setCurrentType] = useState();
  const [conditionCategory, setConditionCategory] = useState(null);
  const [isOpen, toggleExportModal] = useState(false);

  const { trendGraphSelection } = useStoreState(({ analyze }) => analyze);

  const { download, chartRefs } = useExportGraphActions();

  const openModal = useCallback(() => {
    if (isLoading) return;

    let _graphs = [];
    const { startDate, endDate } = dateRange ?? {};
    if (entityType === EntityType.ASSET) {
      const { secondary, categories, def } = kpiChartState ?? {};
      const { seriesType } = def?.graph4?.default ?? {};

      _graphs = [
        {
          id: ChartNames.PARETO,
          title: t('major_loss.title', 'Pareto Analysis'),
          label: t(`dynamic.ilfs.${currentIecCategory}`, currentIecCategory),
          value: currentIecCategory,
          metadata: {
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
          },
        },
        {
          id: ChartNames.POWER_CURVE,
          title: t('power_curve.title', 'Power Curve'),
          label: '',
          value: ChartType.SCATTER,
          metadata: {
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
            assetIds: [entity.id],
          },
        },
        {
          id: ChartNames.TRENDS,
          title: t('kpi_chart.asset_trends', 'Asset Trends'),
          label: t(`filters.${secondary}`, secondary),
          value: secondary,
          metadata: {
            seriesType,
            categories,
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
          },
        },
        {
          id: ChartNames.CONDITION,
          title:
            entityType === EntityType.ASSET
              ? t('conditions.asset_title', 'Asset Conditions')
              : t('conditions.site_title', 'Site Conditions'),
          label: conditionCategory?.label,
          value: conditionCategory?.value,
          metadata: {
            startDate: startDate.utc,
            endDate: endDate.utc,
          },
        },
      ];
    } else if (entityType === EntityType.SITE) {
      const { secondary, def } = kpiChartState ?? {};
      const { categories, seriesType } = getKPIChartState(currentType, def?.graph1);

      _graphs = [
        {
          id: ChartNames.TRENDS,
          title: t('kpi_chart.site_trends', 'Site Trends'),
          label: t(`filters.${secondary}`, secondary),
          value: secondary,
          metadata: {
            seriesType,
            categories,
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
          },
        },
        {
          id: ChartNames.POWER_CURVE,
          title: t('power_curve.title', 'Power Curve'),
          label: '',
          value: ChartType.SCATTER,
          metadata: {
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
            assetIds: selectedAssetIds,
          },
        },
        {
          id: ChartNames.PARETO,
          title: t('major_loss.title', 'Pareto Analysis'),
          label: t(`dynamic.ilfs.${currentIecCategory}`, currentIecCategory),
          value: currentIecCategory,
          metadata: {
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
          },
        },
        {
          id: ChartNames.CONDITION,
          title: t('conditions.site_title', 'Site Conditions'),
          label: conditionCategory?.label,
          value: conditionCategory?.value,
          metadata: {
            startDate: startDate.entityTimezone,
            endDate: endDate.entityTimezone,
          },
        },
      ];
    } else {
      const { secondary, def } = kpiChartState ?? {};
      const { categories, seriesType } = getKPIChartState(currentType, def?.graph1);
      _graphs = [
        {
          id: ChartNames.TRENDS,
          title:
            entityType === EntityType.REGION
              ? t('kpi_chart.region_trends', 'Region Trends')
              : t('kpi_chart.site_trends', 'Site Trends'),
          label: t(`filters.${secondary}`, secondary),
          value: secondary,
          metadata: {
            timeAggr: timeAggregation,
            seriesType,
            categories,
            startDate,
            endDate,
          },
        },
        {
          id: ChartNames.PARETO,
          title: t('major_loss.title', 'Pareto Analysis'),
          label: t(`dynamic.ilfs.${currentIecCategory}`, currentIecCategory),
          value: currentIecCategory,
          unit: secondary,
          metadata: {
            startDate: trendGraphSelection?.startDate ? trendGraphSelection.startDate : startDate,
            endDate: trendGraphSelection?.endDate ? trendGraphSelection.endDate : endDate,
          },
        },
      ];
    }
    setGraphs(_graphs);
    toggleExportModal(true);
  }, [
    isLoading,
    dateRange,
    timeAggregation,
    currentType,
    kpiChartState,
    currentIecCategory,
    conditionCategory,
    selectedAssetIds,
    entity,
    entityType,
    trendGraphSelection,
    t,
  ]);

  const onClose = useCallback(() => {
    toggleExportModal(false);
    setGraphs([]);
  }, []);

  const onSubmit = useCallback(
    (values) => {
      if (!values?.graphs?.length) return;

      download({
        ...values,
        graphs: graphs.reduce(
          (result, graph) =>
            graph?.id && values.graphs.includes(graph.id)
              ? { ...result, [graph.id]: graph }
              : result,
          {},
        ),
        entity,
        entityType,
      });
      toggleExportModal(false);
    },
    [graphs, entity, entityType, download],
  );

  const setChartRefs = (ref, chartName) => {
    chartRefs.current[chartName] = ref;
  };
  return {
    enabled: !isLoading,
    openModal,
    modalProps: {
      isOpen,
      onClose,
      onSubmit,
      graphs,
    },
    setCurrentType,
    setConditionCategory,
    setChartRefs,
  };
};
