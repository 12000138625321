/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { nullLiteral } from '@babel/types';
import merge from 'deepmerge';
import { action, computed, thunk, thunkOn, debug, actionOn } from 'easy-peasy';
import { useStoreState } from 'easy-peasy';
import memoize from 'memoizerific';

import { getDataFilter, getDataFilterUp } from '@ge/shared/util/data-filter';
import { sorter } from '@ge/util/metric-sorter';

import {
  // getAllDamages,
  getDamageReport,
  fetchDamageDetailsById,
  getDamagesResult,
} from '../services/damages';
import { getDamageReportDirect } from '../services/downloadFiles';

// Define initial state
const defaultDamagesState = {
  damages: {},
  stageStatus: null,
  damagesCount: {},
  isMaxResults: false,
};
// helper functions
const fetchAllDamages = {};

// Actions
const damagesActions = {
  resetDamages: action((state) => {
    state = Object.assign(state, defaultDamagesState);
  }),
  downloadDamageReport: thunk(async (actions, payload) => {
    const options = {
      responseType: 'arraybuffer',
    };
    const data = {
      damage_ids: payload.selectedDamageIds,
      base_url: window.location.origin,
      turbine_damage_mapping: payload.turbineDamageMapping,
    };
    const results = await getDamageReportDirect(data, options);
    return new Promise((resolve) => {
      resolve(results);
    });
  }),

  updateDamages: action((state, payload) => {
    if (payload !== undefined) {
      const { result, sitesData, status } = payload;
      let allDamages = [];
      let count = 0;
      result?.damages?.forEach((damage) => {
        const assetSiteId = damage['Asset Site Id'];
        let updatedDamage = {
          ...damage,
          Site: sitesData[assetSiteId] ? sitesData[assetSiteId].name : '',
        };
        allDamages.push(updatedDamage);
      });
      if (status === 'Failed: Data is too large to display') {
        state.isMaxResults = true;
      } else {
        state.isMaxResults = false;
      }
      state.damages = allDamages;
      state.damagesCount = result?.damage_counts;
    }
  }),

  fetchDamagesResultTable: thunk(async (actions, payload) => {
    const options = {};
    if (payload !== undefined || payload === null) {
      const { globalFilters, sitesData, endDate, startDate } = payload;
      const filters = globalFilters;
      actions.resetDamages();
      const { result, status } = await getDamagesResult({ filters, endDate, startDate }, options);
      actions.updateDamages({ result, sitesData, status });
    }
  }),

  getDamageDetailsById: thunk(async (actions, payload, { getStoreState }) => {
    const options = {};
    const result = await fetchDamageDetailsById(payload, options);
    return new Promise((resolve) => {
      resolve(result);
    });
  }),

  getDamages: computed([(state) => state], (state) => {
    return memoize(2)((sortMetric, sortDirection) => {
      var data = state.getDamagesAsList();

      return data?.sort(sorter(sortMetric, sortDirection)) ?? [];
    });
  }),
};

// Listeners
const damagesListeners = {};

// Computed values
const damagesComputed = {
  getDamagesAsList: computed((state) => () => Object.values(state.damages)),
};

const damagesModel = {
  ...defaultDamagesState,
  ...damagesActions,
  ...damagesListeners,
  ...damagesComputed,
};

export default damagesModel;
