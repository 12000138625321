// TODO: look into wrapping our assets calls with react-query here
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect, useMemo } from 'react';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { Feature } from '@ge/models/constants';
import { fetchWindTurbines } from '@ge/shared/services/asset';
import { range } from '@ge/util/array-utils';
import merge from '@ge/util/deep-merge';

import { useAuth } from './use-auth';

const STALE_THRESHOLD = 1000 * 60 * 60 * 4; // 4 hours
const PAGE_SIZE = 5000;

// MVP 0
// remove this once we get permissions fully implemented
const MVP_0_FEATURE_FLAGS = Object.values(Feature).reduce(
  (featureFlags, feature) => ({
    ...featureFlags,
    [feature]: true,
  }),
  {},
);

// can revise this but currently just retrying pages once
const fetchPage = async ({ feature, pageIndex, retry = true }) => {
  try {
    const { assets, totalPages } = await fetchWindTurbines({
      pageIndex,
      pageSize: PAGE_SIZE,
      feature,
      sortDirection: SortDirection.ASC,
      sortMetric: 'id',
    });

    const response = { assets, totalPages };
    return response;
  } catch {
    if (retry) {
      return fetchPage({ feature, pageIndex, retry: false });
    }
  }
};

// TODO: move batch fetch into its own hook that can be used by different features
export const useWindTurbines = () => {
  // data hooks
  const { permissions } = useAuth();

  // store
  const { updateAssets, setError, setIsLoaded, setIsLoading, setLastUpdated } = useStoreActions(
    (actions) => actions.assets,
  );
  // not destructuring here in case we lose change detection by mangling reference
  const appState = useStoreState((state) => state.app);
  const state = useStoreState((state) => state.assets);

  const buildBatch = (feature, startIdx, BATCH_SIZE) =>
    range(BATCH_SIZE, startIdx).map((pageIndex) => fetchPage({ feature, pageIndex }));

  useEffect(() => {
    const segments = Object.keys(permissions ?? {});

    // don't reload
    // TODO: add way to force reload to support refreshing assets
    if (!segments.length || state.isLoading || state.isLoaded || appState.isPopout) {
      return;
    }

    // data is not stale
    if (new Date().getTime() - state.lastUpdated < STALE_THRESHOLD) {
      setIsLoaded(true);
      return;
    }

    const load = async () => {
      try {
        // manage asset load internally and update store once to avoid unnecessary re-renders
        let loadMap = {};

        const loadFeature = async (feature) => {
          let pageIndex = 0;
          let hasNextPage = true;
          let batch = 1;

          while (hasNextPage) {
            if (pageIndex > 0) hasNextPage = false;
            try {
              const batchResults = await Promise.allSettled(buildBatch(feature, pageIndex, batch));

              batchResults.forEach(({ value }) => {
                value?.assets?.forEach((_asset) => {
                  // MVP 0
                  // add all features to entity permissions for now because service doesn't
                  // distinguish currently
                  const asset = {
                    ..._asset,
                    featureFlags: MVP_0_FEATURE_FLAGS,
                  };

                  const existingAsset = loadMap[asset.id];
                  loadMap[asset.id] = !existingAsset ? asset : merge(existingAsset, asset);
                });
              });

              // try to push forward eagerly by only evaluating successful calls to see if we hit the last page
              // TOOD: revisit this approach
              //const fulfilled = batchResults.filter(({ value }) => value);
              if (hasNextPage) {
                hasNextPage = batchResults?.[0]?.value?.totalPages > 1 ? true : false;
                pageIndex = 1;
                batch = batchResults?.[0]?.value?.totalPages - 1;
              }
            } catch (err) {
              console.error(err);

              setError(err);

              // TODO: figure out how to handle partial failures
            }
          }
        };

        setIsLoading(true);

        // TODO - are we really always fetching using only the common token?
        await loadFeature(Feature.COMMON);

        updateAssets(Object.values(loadMap));
        setLastUpdated();
        setIsLoaded(true);
      } catch (err) {
        console.error(err);

        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, [
    state.isLoaded,
    state.isLoading,
    appState.isPopout,
    permissions,
    updateAssets,
    setError,
    setIsLoaded,
    setIsLoading,
    state.lastUpdated,
    setLastUpdated,
  ]);

  return useMemo(
    () => ({
      data: !(state.error || state.isLoading) ? state.allAssets : null,
      error: state.error,
      isLoading: state.isLoading,
    }),
    [state.allAssets, state.error, state.isLoading],
  );
};
