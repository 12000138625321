import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { ConditionsKpiCategoryDefs, QueryKey } from '@ge/models/constants';
import { useLogger } from '@ge/shared/hooks';

import { getConditionsKpiData, getKpiData } from '../services/kpi';

import { Config } from './config';

const getKpiService = (category) =>
  ({
    [category]: getKpiData, // default
    [ConditionsKpiCategoryDefs.WIND]: getConditionsKpiData,
    [ConditionsKpiCategoryDefs.WIND_DIRECTION]: getConditionsKpiData,
  }[category]);

export const useKpiData = ({
  categories,
  endDate,
  entityAggr,
  entityId,
  entityType,
  isActive,
  filters,
  startDate,
  swallowErrors,
  timeAggr,
  passThrough,
  isMal,
}) => {
  const logger = useLogger();

  const queries = useQueries(
    categories.map((category) => {
      const params = {
        categories: [category],
        endDate,
        entityAggr,
        entityId,
        entityType,
        filters,
        startDate,
        timeAggr,
        passThrough,
        isMal,
      };

      const service = getKpiService(category);

      // TODO: allow config to be passed in, and define standard behavior in one place
      const config = {
        ...Config.EXECUTE_ONCE,
        enabled: isActive,
        retry: 1,
      };

      return {
        ...config,
        queryFn: async () => {
          if (!swallowErrors) {
            return service(params);
          }

          // TODO: revisit this but for now there's a flag to swallow errors in the query and not bubble up into react-query to avoid unnecessary refetches
          try {
            return await service(params);
          } catch (err) {
            // will handle logging out error below (this matches response from backend that includes errors)
            return { data: {}, errors: [err] };
          }
        },
        // TODO: verify this is caching as expected with how complex params are
        queryKey: [QueryKey.CONDITIONS_KPI_DATA, params],
      };
    }),
  );

  const data = useMemo(
    () =>
      queries.reduce((_data, queryData) => {
        if (queryData.isSuccess) {
          let kpiData = queryData.data;
          const { data: _kpiData, errors } = kpiData; // some endpoints return response along with errors (should standardize)
          kpiData = _kpiData ?? kpiData; // get underlying data (again, standardize)

          // how do we want to handle this? bubble up to consumer?
          if (errors?.length) {
            logger.error(errors);
          }

          return {
            ..._data,
            ...kpiData,
          };
        }

        return _data;
        // can we key off of queries here for change detection?
      }, {}),
    [logger, queries],
  );

  const error = useMemo(() => queries.filter(({ isError }) => isError).map(({ error }) => error), [
    queries,
  ]);

  // do we need to wait for all to finsih loading before returning data?
  const isLoading = queries.some(({ isLoading }) => isLoading);

  return { data, error, isLoading };
};
