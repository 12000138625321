import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { MiniLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { globalColors } from '@ge/tokens/colors/colors';

import { useCreateReport, useSendReport } from '../../data-hooks/use-sent-report';
import { EmailRecipients } from '../email-recipients/email-recipients';

const StyledDialog = styled(Dialog)`
  width: 550px;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 12px;
`;

const CancelButton = styled(Button)`
  border-color: ${globalColors.slate12};
  margin-right: 20px;
`;

const Title = styled.h1`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${({ theme }) => theme.createReport.sendReportModal.titleColor};
  margin-bottom: 10px;
  font-weight: 500;
`;

const Label = styled.label`
  display: block;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  color: ${({ theme }) => theme.createReport.sendReportModal.labelColor};
  margin-bottom: 2px;
`;

const ErrorLabel = styled(Label)`
  color: ${({ theme }) => theme.createReport.sendReportModal.errorLabelColor};
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
`;

const ParameterValue = styled.p`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  word-wrap: break-word;
  margin: 0 0 10px;
  color: ${({ theme }) => theme.createReport.sendReportModal.parameterColor};
`;

const SendToLabel = styled.label`
  display: block;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  color: ${({ theme }) => theme.createReport.sendReportModal.labelColor};
  text-transform: uppercase;
  border-top: 2px solid ${({ theme }) => theme.createReport.sendReportModal.separationColor};
  padding-top: 10px;
  margin-bottom: 5px;
  font-weight: 700;
`;

const NAMESPACE = 'reporting.sidebar';

export const SendReportModal = ({
  isOpen,
  onModalClose,
  sentReportData,
  distributionList,
  onDistributionListChanged,
  generatedPdfBase64,
  attachments,
}) => {
  const { t } = useTranslation([NAMESPACE], {
    useSuspense: false,
  });

  const sentReportId = sentReportData?.reportId;

  const { reportState = {} } = useContext(ReportsContext);

  const [noEmailError, setNoEmailError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sendError, setSendError] = useState(false);

  useEffect(() => {
    setNoEmailError(!distributionList || distributionList.length < 1);
  }, [distributionList]);

  const { name: templateName, scopeName } = sentReportData ?? reportState;
  const { timeRange } = sentReportData ?? {};

  const { endDate, startDate } = useReportScope(timeRange);

  const sendReportCallbacks = {
    // TODO:
    // This response is currently a confirmation message string with the ID of the saved report.
    // Should this be persisted in report state?
    onSuccess: (/*response*/) => {
      setSendError(false);
      setIsSending(false);
      onModalClose(true);
    },
    // TODO: logging?
    onError: (/*error*/) => {
      setSendError(true);
      setIsSending(false);
    },
  };

  const { mutate: sendReport } = useSendReport(sendReportCallbacks);
  const { mutate: createReport } = useCreateReport(sendReportCallbacks);

  const handleCancel = useCallback(() => {
    setSendError(false);
    onModalClose();
  }, [setSendError, onModalClose]);

  const sendNewReport = () => {
    createReport({
      reportState,
      distributionList,
      attachments,
      reportFile: generatedPdfBase64,
      sendMail: true,
    });
  };

  const forwardReport = () => {
    sendReport({ reportId: sentReportId, distributionList });
  };

  const handleSendReport = () => {
    // making sure we don't send the report if there isn't a distro list (even though there are checks before this)
    if (!distributionList || distributionList.length < 1) {
      setNoEmailError(true);
      return;
    }

    setNoEmailError(false);
    setIsSending(true);

    // if a sentReportId is passed to this component, forward that report and don't create a new one
    if (sentReportId) {
      forwardReport();
    } else {
      sendNewReport();
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      onClose={handleCancel}
      header={t('send_report', 'Send Report')}
      footer={
        <>
          <ButtonContainer>
            {isSending && (
              <LoaderContainer>
                <MiniLoader />
              </LoaderContainer>
            )}
            <CancelButton onClick={handleCancel}>{t('cancel', 'Cancel')}</CancelButton>
            <Button onClick={() => handleSendReport()} disabled={isSending || noEmailError} primary>
              {t('send_report', 'Send Report')}
            </Button>
          </ButtonContainer>
          {sendError && (
            <ErrorLabel>
              {t(
                'send_report_error',
                'An error occurred and your report was not sent. Please try again or contact support.',
              )}
            </ErrorLabel>
          )}
        </>
      }
      padContent={true}
    >
      <Title>{templateName}</Title>
      <Label>{t('time_range', 'Time Range')}</Label>
      <ParameterValue>{`${startDate} - ${endDate}`}</ParameterValue>
      <Label>{t('scope', 'Scope')}</Label>
      <ParameterValue>{scopeName || t('na', 'N/A')}</ParameterValue>
      <SendToLabel>{t('send_to', 'Send To')}</SendToLabel>
      <EmailRecipients
        distributionList={distributionList}
        onDistributionListChanged={onDistributionListChanged}
        noEmailError={noEmailError}
      />
    </StyledDialog>
  );
};

SendReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
  sentReportData: PropTypes.object,
  distributionList: PropTypes.array,
  onDistributionListChanged: PropTypes.func,
  generatedPdfBase64: PropTypes.string,
  attachments: PropTypes.array,
};
