import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  formatDataForDropdown,
  findObjectById,
  findComponentById,
  formatFilters,
} from '../../../util';
import { CustomDropdown } from '../custom-dropdown/custom-dropdown';

import { KpiChartSelectorStyled } from './kpi-chart-selector-styled';
/**
 * KpiChartSelector component
 */
export const KpiChartSelector = (props) => {
  const { kpiSelectorData, onChange, namespace, siteType } = props;
  const fileName = namespace + '.' + siteType + '-kpi';
  const [childList, setChildList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  const defaultOptionState = {
    value: null,
    label: null,
  };
  const defaultComponentState = {
    path: null,
    props: null,
  };
  const combinedState = {
    option: defaultOptionState,
    component: defaultComponentState,
  };
  // selectedCombinedState is responsible for holding selected state for all presented dropdowns
  const [selectedCombinedState, setSelectedCombinedState] = useState({
    kpi: combinedState,
    chart: combinedState,
    filter: {},
  });
  const options = useMemo(() => {
    return formatDataForDropdown(kpiSelectorData);
  }, [kpiSelectorData]);

  const kpiChangeHandlar = useCallback(
    (option) => {
      const selectedKPIData = findObjectById(kpiSelectorData, option.value);
      if (selectedKPIData?.filterList && selectedKPIData.filterList.length > 0) {
        setFilterList(selectedKPIData.filterList);
      } else if (
        selectedKPIData?.childList[0]?.filterList &&
        selectedKPIData.childList[0].filterList.length > 0
      ) {
        setFilterList(selectedKPIData.childList[0].filterList);
      }
      setChildList(formatDataForDropdown(findObjectById(kpiSelectorData, option.value)?.childList));
      const component = findComponentById(kpiSelectorData, option.value);

      setSelectedCombinedState((selectedCombinedState) => {
        return {
          ...selectedCombinedState,
          kpi: {
            option: option,
            component: component,
          },
          filter: {},
        };
      });
    },
    [kpiSelectorData],
  );

  const chartChangeHandlar = useCallback(
    (option) => {
      setFilterList(findObjectById(kpiSelectorData, option.value)?.filterList);
      const component = findComponentById(kpiSelectorData, option.value);
      setSelectedCombinedState((selectedCombinedState) => {
        return {
          ...selectedCombinedState,
          chart: {
            option: option,
            component: component,
          },
        };
      });
    },
    [kpiSelectorData],
  );

  const filterChangeHandlar = useCallback((option) => {
    setSelectedCombinedState((selectedCombinedState) => {
      return {
        ...selectedCombinedState,
        filter: { ...selectedCombinedState.filter, [option.id]: option.option.value },
        chart: {
          ...selectedCombinedState.chart,
          ['option']: { value: option.option.value, label: option.option.label },
        },
      };
    });
  }, []);

  // for loading all options, only for first time
  useEffect(() => {
    kpiChangeHandlar(options[0]);
    const component = findComponentById(kpiSelectorData, options[0].value);
    setSelectedCombinedState((selectedCombinedState) => {
      return {
        ...selectedCombinedState,
        kpi: {
          option: options[0],
          component: component,
        },
      };
    });
  }, []);

  // For loading child dropdown components for first time.
  useEffect(() => {
    if (childList && childList.length > 0) {
      const component = findComponentById(kpiSelectorData, childList[0].value);
      setSelectedCombinedState((selectedCombinedState) => {
        return {
          ...selectedCombinedState,
          chart: {
            option: childList[0],
            component: component,
          },
        };
      });
    } else {
      setSelectedCombinedState((selectedCombinedState) => {
        return {
          ...selectedCombinedState,
          chart: {
            option: defaultOptionState,
            component: defaultComponentState,
          },
        };
      });
    }
  }, [childList]);

  useEffect(() => {
    let chartComponent = defaultComponentState;
    if (selectedCombinedState.kpi.option.value !== 'reliability') {
      const currentKPI = findObjectById(kpiSelectorData, selectedCombinedState.kpi.option.value);
      chartComponent = { path: currentKPI?.childList[0]?.component?.path, props: null };
    }
    if (filterList && filterList.length > 0) {
      setSelectedCombinedState((selectedCombinedState) => {
        return {
          ...selectedCombinedState,
          filter: formatFilters(filterList),
          chart: {
            option: filterList[0].options[0],
            component: chartComponent,
          },
        };
      });
    } else {
      setSelectedCombinedState((selectedCombinedState) => {
        return {
          ...selectedCombinedState,
          filter: {},
        };
      });
    }
  }, [filterList]);

  useEffect(() => {
    onChange(selectedCombinedState);
  }, [onChange, selectedCombinedState]);

  return (
    <KpiChartSelectorStyled>
      <CustomDropdown onChange={kpiChangeHandlar} fileName={fileName} options={options} />

      {childList && childList.length > 0 && (
        <CustomDropdown onChange={chartChangeHandlar} fileName={fileName} options={childList} />
      )}

      {filterList &&
        filterList?.map((item, index) => {
          return (
            <CustomDropdown
              key={index}
              siteType={siteType}
              onChange={filterChangeHandlar}
              options={item.options}
              dropDownId={item.id}
            />
          );
        })}
    </KpiChartSelectorStyled>
  );
};

KpiChartSelector.propTypes = {
  onChange: PropTypes.func,
  siteType: PropTypes.string,
  namespace: PropTypes.string,
  kpiSelectorData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      component: PropTypes.shape({
        path: PropTypes.string,
        props: PropTypes.object,
      }),
      childList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string,
          component: PropTypes.shape({
            path: PropTypes.string,
            props: PropTypes.object,
          }),
          childList: PropTypes.array,
          filterList: PropTypes.array,
        }),
      ),
      filterList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
              label: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
};

KpiChartSelector.defaultProps = {
  siteType: 'solar',
  namespace: 'analyze',
  onChange: () => {},
};
