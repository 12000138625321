import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { QueryKey, TaskStatus } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { getAssetCaseHistory, getAssetTaskHistory } from '@ge/shared/services/cases';
import { sorter } from '@ge/util/metric-sorter';

import { taskPage } from '../services/task-page';

const queryConfig = {
  ...Config.EXECUTE_ONCE,
  refetchOnMount: true,
  refetchOnReconnect: true,
};

export const useAssetCaseHistory = ({ assetId, days, sortMetric, sortDirection, enabled }) => {
  const { data, error, isLoading, refetch, isRefetching } = useQuery(
    [QueryKey.CASE_NOTES, assetId, days],
    () => getAssetCaseHistory(assetId, days),
    {
      enabled: Boolean(enabled && assetId && days),
      refetchOnMount: true,
    },
  );

  return useMemo(() => {
    return {
      data: data?.length ? data.sort(sorter(sortMetric, sortDirection)) : [],
      error,
      isLoading,
      refetch,
      isRefetching,
    };
  }, [data, error, isLoading, sortDirection, sortMetric, refetch, isRefetching]);
};

export const useAssetTaskHistory = ({
  assetId,
  sortMetric,
  sortDirection,
  filters,
  search,
  enabled,
  taskUpdateForAsset,
}) => {
  const { t } = useTranslation(['tasks'], {
    useSuspense: false,
  });
  const { sites } = useStoreState((state) => state.sites);
  const { assets } = useStoreState((state) => state.assets);

  const siteId = assets[assetId]?.site?.id;
  const serviceGroupIds = sites[siteId]?.serviceGroups?.toString();

  const { data, error, isFetching } = useQuery(
    [QueryKey.ASSET_TASK_HISTORY, assetId, taskUpdateForAsset],
    async () => {
      let data = [];
      if (serviceGroupIds) {
        data = await getAssetTaskHistory([assetId], serviceGroupIds);
      } else {
        const response = await taskPage({ assetIds: [assetId], status: [TaskStatus.COMPLETED] });
        data = response?.tasks;
      }
      if (data?.length) {
        let results = data.map((task) => {
          task.techs = {
            assignedTechs: task?.assignedTechs,
            value: task?.assignedTechs?.map(
              (tech) => tech?.initials || tech?.firstName?.substring(0, 5),
            ),
          };
          task.source = t(`dynamic.types.${task.source}`, task.source);
          return task;
        });
        return results;
      }
    },
    {
      enabled: Boolean(enabled && assetId),
      ...queryConfig,
    },
  );

  const filteredData = useTableFilter({ data, filters, search });
  return useMemo(() => {
    return {
      data: filteredData.data?.length
        ? filteredData?.data?.sort(sorter(sortMetric, sortDirection))
        : [],
      filterValues: filteredData?.filterValues,
      error,
      isLoading: isFetching,
    };
  }, [filteredData, sortMetric, sortDirection, error, isFetching]);
};
