import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import path from 'ramda/src/path';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import useStateRef from '@ge/hooks/state-ref';
import { CommonLocators } from '@ge/models/data-locators';

import { ResizableTableColumns } from './resizable-table-columns';
import { Table, Tbody, Thead, Tfoot, Tr } from './table';

/**
 * The table body needs calculated padding to capture the height
 * of the scrolling container.
 */
const StyledTbody = styled(Tbody)`
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const StyledTr = styled(Tr)`
  ${(props) => {
    // Only override the row styling if the table is virualized ("scrollable")
    if (props.scrollable) {
      return `background: ${
        props.odd ? props.theme.table.evenRowColor : props.theme.table.oddRowColor
      } !important;
      `;
    }
  }}
`;

/**
 * Dynamically generated Table component that takes a formatted
 * configuration and renders column groups, columns, rows, and cells
 * based on the provided factories that key off of configuration ids
 * to return a fully built JSX component.
 */
export const DynamicTable = ({
  scrollable,
  compressed,
  transparent,
  noTitles,
  columnGroupFactory,
  columnFactory,
  cellFactory,
  columns,
  values,
  onValueSelect,
  rowKeyProperty,
  className,
  resizable,
  TablekeyProperty,
  // data loader props
  isLoading,
  noData,
  noDataDescription,
  noDataTitle,
  onRetry,
  renderCondition,
  rowsSelected,
  rowKey,
}) => {
  const parentRef = useRef();

  // Internal state used to manage JSX components built from configuration.
  const [colGroups, setColGroups, colGroupsRef] = useStateRef(null);
  const [cols, setCols, colsRef] = useStateRef(null);

  // Since we are using a hook for row virtualization, we cannot conditionally
  // virtualize based on whether or not the table is scrollable. This is potentially
  // an unnecessary memory consumption, but not easily avoided.
  const rowVirtualizer = useVirtual({
    size: values.length,
    parentRef,
    estimateSize: React.useCallback(() => 36, []),
    overscan: 1,
  });

  /**
   * Filter an array of columns based on visibility.
   */
  const getVisibleColumns = useCallback(
    (someColumns, excludeMarkupOnly) =>
      someColumns.filter((col) =>
        excludeMarkupOnly ? col.visible && !col.markupOnly : col.visible,
      ),
    [],
  );

  /**
   * Memoize the currently visible columns.
   */
  const visibleCols = useMemo(() => {
    if (!columns.length) {
      return [];
    }

    return columns.reduce((idArray, columnGroup) => {
      const visibleColIds = getVisibleColumns(columnGroup.cols).map((col) => col.id);
      idArray.push({ cols: [...visibleColIds], groupKey: columnGroup.id });
      return idArray;
    }, []);
  }, [columns, getVisibleColumns]);

  // Build renderable table header components (column groups and columns) and reference
  // the computed values until something changes that affects the computation.
  useEffect(() => {
    const { builtGroups, builtCols } = columns.reduce(
      (colMap, columnGroup) => {
        const colKeys = getVisibleColumns(columnGroup.cols, true).map((col) => col.id);

        colMap.builtGroups = [...colMap.builtGroups, columnGroupFactory(columnGroup.id, colKeys)];
        colMap.builtCols = [
          ...colMap.builtCols,
          ...colKeys.map((columnKey, idx, arr) =>
            columnFactory(columnGroup.id, columnKey, idx, arr.length),
          ),
        ];

        return colMap;
      },
      { builtGroups: [], builtCols: [] },
    );

    // Update state if the computed values have changed.
    if (!equals(colGroupsRef, builtGroups)) {
      setColGroups(builtGroups);
    }
    if (!equals(colsRef, builtCols)) {
      setCols(builtCols);
    }
  }, [
    columns,
    columnGroupFactory,
    columnFactory,
    colGroupsRef,
    colsRef,
    setColGroups,
    setCols,
    getVisibleColumns,
  ]);

  const getRowDefs = useCallback(
    (value) => {
      const factoryInstance = cellFactory(value);
      return {
        children: (
          <>
            {visibleCols?.map(({ cols, groupKey }) =>
              cols.map((colKey, idx, arr) => factoryInstance(groupKey, colKey, idx, arr.length)),
            )}
          </>
        ),
        key: Array.isArray(rowKeyProperty) ? path(rowKeyProperty, value) : value[rowKeyProperty],
        value,
        onClick: (e) => onValueSelect(e, value),
      };
    },
    [cellFactory, rowKeyProperty, visibleCols, onValueSelect],
  );

  /**
   * Build a specific row for the provided value and visible columns.
   */
  const buildRow = useCallback(
    ({ index, measureRef }, idx) => {
      // Handle both virtualized and non-virtualized scenarios. If the table is not
      // scrollable, we cannot virtualize the rows because they are all shown.
      const value = scrollable ? values[index] : values[idx];

      if (!value) {
        return null;
      }

      const { children, key, onClick } = getRowDefs(value);

      // check if the provided list of selected row Id's matches the current row.
      const isSelected = rowsSelected?.includes(key);

      return (
        <StyledTr
          ref={measureRef}
          key={rowKey ? value[rowKey] : key}
          onClick={onClick}
          selected={isSelected}
          odd={!!(index % 2)}
          scrollable={scrollable}
        >
          {children}
        </StyledTr>
      );
    },
    [values, rowsSelected, scrollable, getRowDefs],
  );

  if (!visibleCols.length) {
    return null;
  }

  // Calculate the padding to be applied to the scrolling container.
  // If the table is not scrollable, ignore padding and reference raw values.
  const items = scrollable ? rowVirtualizer.virtualItems : values;
  const paddingTop = items?.length > 0 && scrollable ? items[0].start : 0;
  const paddingBottom =
    items?.length > 0 && scrollable ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  return (
    <Table
      scrollable={scrollable}
      ref={parentRef}
      compressed={compressed}
      className={className}
      isLoading={isLoading}
      noData={noData}
      noDataDescription={noDataDescription}
      noDataTitle={noDataTitle}
      onRetry={onRetry}
      type="table"
      renderCondition={renderCondition}
      TablekeyProperty={TablekeyProperty}
    >
      <Thead noTitles={noTitles} transparent={transparent}>
        {!noTitles && <Tr>{colGroups}</Tr>}
        <Tr data-testid={CommonLocators.COMMON_DYNAMIC_TABLE_HEADER_ROW}>{cols}</Tr>
      </Thead>
      <StyledTbody
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        transparent={transparent}
        data-testid={CommonLocators.COMMON_DYNAMIC_TABLE_BODY}
      >
        {items?.map((value, idx) => buildRow(value, idx))}
      </StyledTbody>
      {resizable && (
        <Tfoot>
          <ResizableTableColumns parentRef={parentRef} cols={cols} />
        </Tfoot>
      )}
    </Table>
  );
};

DynamicTable.propTypes = {
  ...Table.propTypes,
  values: PropTypes.arrayOf(PropTypes.object),
  TablekeyProperty: PropTypes.string,
};

DynamicTable.defaultProps = {
  values: [],
};
