import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader, SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { QueryKey } from '@ge/models/constants';

import { useDeleteCaseTemplate } from '../../data-hooks/use-case-templates';
import { NAMESPACE } from '../../models/configure-analysis-template';

const DeleteContainer = styled.div`
  padding: 15px;
  background: ${(props) => props.theme.deleteCaseTemplate.backgroundColor};
  border-bottom: 3px solid ${(props) => props.theme.deleteCaseTemplate.borderBottomColor};
  font-weight: 500;
`;

const VericalRuler = styled.span`
  margin: 0 10px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.deleteCaseTemplate.iconColor,
}))``;

const DeleteIconSection = styled.span`
  cursor: pointer;
`;

const CloseIconSection = styled.span`
  float: right;
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  max-height: 380px;
  width: 390px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  header {
    border-radius: 6px 6px 0 0;
  }
  section {
    padding: 0;
  }
`;

const DeleteCaseContainer = styled.div`
  padding: 12px 24px 30px 24px;
`;

const CaseTitleSection = styled.div`
  opacity: 0.5;
  color: ${(props) => props.theme.deleteCaseTemplate.caseTitleColor};
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
`;

const CaseTitleText = styled.span`
  display: inline-block;
  margin: 17px 51% 0 15px;
`;

const CaseTemplateDetails = styled.div`
  background: #2f3841;
  max-height: 210px;
  border-radius: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const CaseTemplateTitleSection = styled.div`
  display: inline-block;
  width: 70%;
`;

const CaseTemplateTitle = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(85%);
  margin: 0 0 0 6%;
  color: ${(props) => props.theme.deleteCaseTemplate.caseTitleColor};
  letter-spacing: 0;
  line-height: 20px;
`;

const CaseTemplateSourceSection = styled.div`
  display: inline-block;
  width: 30%;
`;

const CaseTemplateSource = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(85%);
  margin: 0;
  color: ${(props) => props.theme.deleteCaseTemplate.caseTitleColor};
  letter-spacing: 0;
  line-height: 20px;
`;

const CaseTemplateDetailsDivider = styled.hr`
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.deleteCaseTemplate.buttonDividerColor};
  margin: 0;
`;

const DeleteModalHeader = styled.span`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

const ButtonDivider = styled.hr`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid ${(props) => props.theme.deleteCaseTemplate.modalDividerColor};
  opacity: 0.5;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: end;
  margin: 14px 24px 0px 0;
`;

const CancelButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.deleteCaseTemplate.cancelBorderColor};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
`;

const ConfirmButton = styled(Button)`
  margin-left: 10px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
`;

const SpinLoaderSection = styled.div`
  position: relative;
  margin-top: -3px;
`;

const DeleteCaseTemplates = ({ checkedCaseTemplateData, setClearCaseTemplateSelections }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });
  const [deleteCaseTemplateModal, setDeleteCaseTemplateModal] = useState(false);

  const status = <p>{checkedCaseTemplateData.length} Case Templates</p>;
  const message = <NotificationMessage message={t('delete_case_template.deleted', 'DELETED')} />;
  const timestamp = <p>DETAILS | {dayjs().format('HH:mm (UTCZ), DD MMM YY')}</p>;

  const { notify } = useNotification();
  const queryClient = useQueryClient();
  const { mutate: deleteCaseTemplate, isLoading } = useDeleteCaseTemplate({
    enabled: true,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CASE_TEMPLATE_TABLE]);
      setDeleteCaseTemplateModal(false);
      notify({ status, message, timestamp });
    },
  });

  const handleBulkDelete = useCallback(() => {
    const accumulatedCaseTemplateIds = checkedCaseTemplateData.map(
      (caseTemplate) => caseTemplate?.caseTemplateId,
    );
    const payload = { caseTemplateIds: accumulatedCaseTemplateIds };
    deleteCaseTemplate(payload);
  }, [checkedCaseTemplateData, deleteCaseTemplate]);

  if (!ready) {
    return <Loader />;
  }

  return (
    <>
      <DeleteContainer>
        <span>
          {checkedCaseTemplateData?.length} {t('delete_case_template.selected', 'SELECTED')}
        </span>
        <VericalRuler> | </VericalRuler>
        <DeleteIconSection onClick={() => setDeleteCaseTemplateModal(true)}>
          <StyledIcon icon={Icons.TRASH} /> {t('delete_case_template.delete', 'DELETE')}
        </DeleteIconSection>
        <CloseIconSection onClick={() => setClearCaseTemplateSelections(true)}>
          <StyledIcon icon={Icons.CLOSE} />
        </CloseIconSection>
      </DeleteContainer>
      {deleteCaseTemplateModal && (
        <StyledDialog
          isOpen={true}
          header={t('delete_case_template.modal_header', 'DELETE TEMPLATES')}
          onClose={() => setDeleteCaseTemplateModal(false)}
        >
          <DeleteCaseContainer>
            <DeleteModalHeader>
              <StyledIcon icon={Icons.ANOMALY} /> {checkedCaseTemplateData?.length}{' '}
              {t('delete_case_template.case_template', 'Case Templates')}
            </DeleteModalHeader>
            <CaseTitleSection>
              <CaseTitleText>{t('delete_case_template.case_title', 'Case Title')}</CaseTitleText>
              <span>{t('delete_case_template.source', 'Source')}</span>
            </CaseTitleSection>
            <CaseTemplateDetails>
              {checkedCaseTemplateData.map((caseTemplate, index) => (
                <div key={index}>
                  <CaseTemplateTitleSection>
                    <CaseTemplateTitle>{caseTemplate?.title}</CaseTemplateTitle>
                  </CaseTemplateTitleSection>
                  <CaseTemplateSourceSection>
                    <CaseTemplateSource>{caseTemplate?.source}</CaseTemplateSource>
                  </CaseTemplateSourceSection>
                  {checkedCaseTemplateData.length - 1 !== index && <CaseTemplateDetailsDivider />}
                </div>
              ))}
            </CaseTemplateDetails>
          </DeleteCaseContainer>
          <ButtonDivider />
          <ButtonSection>
            <SpinLoaderSection>
              <SpinLoader showLoader={isLoading} />
            </SpinLoaderSection>
            <CancelButton onClick={() => setDeleteCaseTemplateModal(false)}>
              {t('delete_case_template.cancel', 'Cancel')}
            </CancelButton>
            <ConfirmButton primary disabled={isLoading} onClick={() => handleBulkDelete()}>
              {t('delete_case_template.confirm', 'Confirm')}
            </ConfirmButton>
          </ButtonSection>
        </StyledDialog>
      )}
    </>
  );
};

DeleteCaseTemplates.propTypes = {
  checkedCaseTemplateData: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setClearCaseTemplateSelections: PropTypes.func,
};

DeleteCaseTemplates.defaultProps = {
  checkedCaseTemplateData: [],
};

export default DeleteCaseTemplates;
