import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
// import { Config } from '@ge/shared/data-hooks';
import * as request from '@ge/shared/services/api';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const lookupPerson = async (searchId) =>
  request.get(`/cmn/users/record/${searchId}`, { baseURL: USERS_BASE_URL });

export const useLookupPerson = (searchId) => {
  const {
    error,
    isError,
    status,
    refetch,
    isLoading: isLookupLoading,
    isFetching: isLookupFetching,
    data: lookedUpPerson,
  } = useQuery([QueryKey.LOOKUP_PERSON, { searchId }], () => lookupPerson(searchId), {
    refetchOnWindowFocus: false,
    enabled: false,
    keepPreviousData: false,
    cacheTime: 0,
  });

  return {
    isLoading: isLookupLoading,
    isFetching: isLookupFetching,
    isError,
    status,
    error,
    refetch,
    data: lookedUpPerson,
  };
};
