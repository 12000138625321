import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';

import { maLegendValues } from '../../models/manual-adjustment-legends';

import {
  DialogGrid2,
  DialogLabel,
  DialogValue,
  EventCategory,
  EventCategoryName,
  EventCategoryColor,
} from './event-dialog-style';

const DialogGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-bottom: 8px;
`;

export const MalEventDetailDialog = ({
  eventsData,
  calculateDuration,
  malCategoryData,
  timezone,
  assetEventsKind,
}) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  if (!ready) {
    return <Loader />;
  }

  return eventsData?.map((event) => (
    <>
      <EventCategory>
        <EventCategoryColor
          backgroundColor={
            maLegendValues[assetEventsKind]?.find(
              ({ category }) => category === event.eventCategory,
            )?.color
          }
          size={'10px'}
        />
        <EventCategoryName>
          {t(
            `legend.${
              maLegendValues[assetEventsKind]?.find(
                ({ category }) => category === event.eventCategory,
              )?.a11yKey
            }.dialog_title`,
            event.eventCategory,
          )}
        </EventCategoryName>
      </EventCategory>
      <DialogGrid>
        <div>
          <DialogLabel>{t('event_details_dialog.event_code', 'Code / #')}</DialogLabel>
          <DialogValue>{event.oemCategory ? event.oemCategory : event.malCategory}</DialogValue>
        </div>
        <div>
          <DialogLabel>{t('event_details_dialog.event_title', 'Title')}</DialogLabel>
          <DialogValue>
            {malCategoryData.find((element) => element.id === event.malCategory)?.description}
          </DialogValue>
        </div>
      </DialogGrid>
      <DialogGrid2>
        <div>
          <DialogLabel>{t('event_details_dialog.event_duration', 'Duration')}</DialogLabel>
          <DialogValue>
            {calculateDuration(
              dayjs.utc(event.startTime * 1000).tz(timezone),
              dayjs.utc(event.endTime * 1000).tz(timezone),
            )}
          </DialogValue>
        </div>
        <div>
          <DialogLabel>{t('event_details_dialog.event_start', 'Start')}</DialogLabel>
          <DialogValue>
            {dayjs
              .utc(event.startTime * 1000)
              .tz(timezone)
              .format('HH:mm:ss')}
          </DialogValue>
        </div>
        <div>
          <DialogLabel>{t('event_details_dialog.event_end', 'End')}</DialogLabel>
          <DialogValue>
            {dayjs
              .utc(event.endTime * 1000)
              .tz(timezone)
              .format('HH:mm:ss')}
          </DialogValue>
        </div>
      </DialogGrid2>
    </>
  ));
};

MalEventDetailDialog.propTypes = {
  eventsData: PropTypes.instanceOf(Object).isRequired,
  calculateDuration: PropTypes.func.isRequired,
  malCategoryData: PropTypes.instanceOf(Object),
  timezone: PropTypes.string,
  assetEventsKind: PropTypes.string,
};
