import { PropTypes } from 'prop-types';
// Removing imports for MVP0 - Story #417
import React, { useCallback, useContext } from 'react'; // , useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Removing for MVP0 - Story #417
// import { Select } from '@ge/components/select';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { SortType, MonitorDefs } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useSiteRealTimeInfo } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';

// Removing for MVP0 - Story #417
// import { OverviewActivePower } from '../../components/overview-active-power-block';
// import OverviewProduction from '../../components/overview-production-block';
// import { OverviewReactivePower } from '../../components/overview-reactive-power-block';
import { TabDetailContainer } from '../../entity-details-shared';
import SiteAssetTable from '../site-asset-table';

// Removing for MVP0 - Story #417
// const MetricsContainer = styled.div`
//   align-items: stretch;
//   background: ${(props) => props.theme.entityDetails.sitePanelBlockBackground};
//   border-radius: 3px;
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: space-between;
//   margin: 8px 10px 30px;
//   padding: 9px 15px 12px 22px;
// `;

// Removing for MVP0 - Story #417
// const ContainerLeft = styled.div`
//   flex: 1 1 55%;
// `;

// Removing for MVP0 - Story #417
// const ContainerRight = styled.div`
//   align-items: stretch;
//   display: flex;
//   flex: 1 1 45%;
//   flex-flow: column nowrap;
//   justify-content: flex-start;
//   margin-left: 40px;
//   > div {
//     &:last-of-type {
//       margin-top: 20px;
//     }
//   }
// `;

const StyledHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 0 !important;
  /* Adding top margin for MVP0 - Remove once metrics container added back */
  margin-top: 30px;
  &.indent {
    margin-left: 35px;
  }
`;

// Removing for MVP0 - Story #417
// const SiteControllerSelect = styled(Select)`
//   margin: 8px 0 5px 6px;
// `;

const AssetsTableContainer = styled.div`
  margin-bottom: 40px;

  table {
    border-top: 0 !important;
    > th {
      border-right: 0;
    }
  }
`;

const AssetMetric = {
  NAME: 'name',
  STATE: 'state',
};

// Removing site for MVP0 - Story #417
const SiteRealtimeInfo = ({ siteId, type }) => {
  // , site }) => {
  // Removing for MVP0 - Story #417
  // const { t } = useTranslation(['entity-details'], {
  //   useSuspense: false,
  // });

  // Removing for MVP0 - Story #417
  // const translations = {
  //   assets: t('assets', 'Assets'),
  //   siteController: t('site_controller', 'Site Controller'),
  // };

  // Removing for MVP0 - Story #417
  // const { performance, production, reactivePower } = site.metrics || {};
  // const { production, reactivePower } = site.metrics ?? {};

  // hooks
  // only want to re-render chart if data actually changed
  // Removing for MVP0 - Story #417
  // const overviewProduction = useMemo(
  //   () => performance && <OverviewProduction performance={performance} />,
  //   [performance],
  // );

  // Removing for MVP0 - Story #417
  // const siteControllerOptions = [
  //   { value: 'SC0001', label: `${translations.siteController}: SC0001` },
  //   { value: 'SC0002', label: `${translations.siteController}: SC0002` },
  // ];

  // Removing for MVP0 - Story #417
  // const [siteController, setSiteController] = useState(siteControllerOptions[0]);

  const {
    showAssetDetails,
    showStorageAssetDetails,
    showSolarAssetDetails,
    showHybridAssetDetails,
  } = useContext(EntityDetailsContext);

  // Table state management
  const { sortDirection, sortMetric, updateSortMetric, sortType } = useColumnState({
    defaultSortMetric: AssetMetric.STATE,
    defaultSortDirection: SortDirection.DESC,
    defaultSortType: SortType.TURBINE_STATUS,
  });

  // data hooks
  const { data, isLoading } = useSiteRealTimeInfo({
    siteId,
    sortDirection,
    sortMetric,
    sortType,
    type,
  });

  // Respond to selection in asset table
  const handleAssetSelect = useCallback(
    (e, asset) => {
      killEventPropagation(e);
      switch (type) {
        case MonitorDefs.WIND_SITES:
          showAssetDetails(asset.id);
          break;
        case MonitorDefs.STORAGE_SITES:
          showStorageAssetDetails(asset.id);
          break;
        case MonitorDefs.SOLAR_SITES:
          showSolarAssetDetails(asset.id);
          break;
        case MonitorDefs.HYBRID_SITES:
          showHybridAssetDetails(asset.id);
          break;
        default:
          showAssetDetails(asset.id);
      }
    },
    [
      showAssetDetails,
      showStorageAssetDetails,
      showSolarAssetDetails,
      showHybridAssetDetails,
      type,
    ],
  );

  // Removing for MVP0 - Story #417
  // const handleSiteControllerSelect = (value) => {
  //   setSiteController(value);
  // };

  return (
    <TabDetailContainer>
      {/* Removing for MVP0 - Story #417 */}
      {/* <MetricsContainer>
        <ContainerLeft>
          <StyledHeader>Site Metrics</StyledHeader>
          {overviewProduction}
        </ContainerLeft>
        <ContainerRight>
          <SiteControllerSelect
            small
            transparent
            maxWidth={200}
            options={siteControllerOptions}
            value={siteController}
            onChange={handleSiteControllerSelect}
          />
          {production && <OverviewActivePower production={production} />}
          {reactivePower && <OverviewReactivePower reactivePower={reactivePower} />}
        </ContainerRight>
      </MetricsContainer> */}
      <StyledHeader className="indent">Assets</StyledHeader>
      <AssetsTableContainer>
        <SiteAssetTable
          siteId={siteId}
          assets={data?.assets}
          isLoading={isLoading}
          sortAction={updateSortMetric}
          sortMetric={sortMetric}
          sortDirection={sortDirection}
          onAssetSelect={handleAssetSelect}
          siteType={type}
        />
      </AssetsTableContainer>
    </TabDetailContainer>
  );
};

SiteRealtimeInfo.propTypes = {
  siteId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default SiteRealtimeInfo;
