import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useCaseAnalysisTemplates from '@ge/feat-analyze/data-hooks/use-case-analysis-templates';
import { NAMESPACE, Fields } from '@ge/feat-analyze/models/configure-case-template';
import { SelectMetaField, TextMetaField } from '@ge/shared/components/meta-fields';
import {
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '@ge/shared/components/tasks/task-template-shared';

import { FormCollapsiblePanel, FormRow } from '../../shared';

const Container = styled.div`
  position: relative;
`;

const FieldEqualWrapper = styled.div`
  width: 50%;
`;

const DisplayCaseFeild = styled(DisplayField)`
  text-transform: capitalize;
  margin-top: 4px;
  padding-bottom: 13px;
  line-height: 13px;
  font-size: 13px;
  line-height: 18px;
`;

const DescriptionContainer = styled.div`
  padding: 10px 20px 0 20px;
  position: relative;
  overflow: visible;
`;

const DescriptionEditContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.entityDetails.cases.details.rowBorder};
  ${'' /* padding: 10px 20px 0 20px; */}
  position: relative;
  height: auto;
  min-height: 100% !important;
  overflow: visible;
`;

const PaddingElement = styled.div`
  padding-bottom: 26px;
`;
const Select = styled(SelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
  .select__menu-list {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      height: 233.11px;
      width: 2px;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.table.fixedBorderColor};
    }
  }
`;

export const AnalyticsDetails = ({ caseDetails, isEditing, isOpen }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { data } = useCaseAnalysisTemplates({ enabled: true });

  const options = useMemo(
    () => data?.analysisTemplateIds?.sort().map((v) => ({ label: v, value: v })) ?? [],
    [data],
  );

  if (!ready) return null;

  return (
    <Container>
      <FormCollapsiblePanel
        isOpen={isOpen}
        title={t('case_template.analytics_detail', 'Analytics Details')}
        allowOverflow={true}
      >
        <DescriptionContainer>
          {isEditing ? (
            <FormRow>
              <Select
                name={Fields.ANALYSIS_TEMPLATE_ID}
                label={t('case_template.analysis_template_id', 'Analysis Template ID')}
                placeholder={t(
                  `case_template.analysis_template_id_placeholder`,
                  'Please select...',
                )}
                defaultValue={caseDetails.analysisTemplateId}
                options={options}
                metadata={{ required: true }}
                minWidth={220}
                maxWidth={220}
              />
              <TextMetaField
                name={Fields.ANALYTICS_SOURCE}
                label={t('case_template.analytics_source', 'Analytics Source')}
                placeholder={t(`case_template.analytics_source_placeholder`, 'Type Source...')}
                defaultValue={caseDetails.analyticSource}
                metadata={{ required: true }}
              />
            </FormRow>
          ) : (
            <>
              <DescriptionEditContainer>
                <FieldGroup>
                  <FieldEqualWrapper className="field-wrapper">
                    <DisplayLabel>{t('Analysis Template ID')}</DisplayLabel>
                    <DisplayCaseFeild>{caseDetails.analysisTemplateId}</DisplayCaseFeild>
                  </FieldEqualWrapper>
                  <FieldEqualWrapper className="field-wrapper">
                    <DisplayLabel>{t('Source')}</DisplayLabel>
                    <DisplayCaseFeild>{caseDetails.analyticSource}</DisplayCaseFeild>
                  </FieldEqualWrapper>
                </FieldGroup>
                <PaddingElement />
              </DescriptionEditContainer>
            </>
          )}
        </DescriptionContainer>
      </FormCollapsiblePanel>
    </Container>
  );
};

AnalyticsDetails.propTypes = {
  caseDetails: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  isEditing: PropTypes.bool,
};
