import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DynamicTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { placements, Tooltip } from '@ge/components/tooltip';
import {
  SentReportListColumnDefs,
  SentReportListColumns,
  globalSentReportListCols,
} from '@ge/feat-reporting/models/sent-report-list-cols';
import { AttributeGroups, DateTimeFormats } from '@ge/models/constants';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { useFilterDefs } from '@ge/shared/hooks';
import {
  ActionKeys,
  ActionsMenuItems,
  SentReportsMenuItems,
} from '@ge/shared/models/actions-menu-items';
import { withDefault } from '@ge/shared/util/table-utils';
import { globalColors, typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

import { SendReportModal } from '../send-report-modal/send-report-modal';

const ThGroup = styled.span`
  height: 1px;
`;

const StyledDisplay = styled.span`
  color: ${(props) => props.theme.table.columnTextColor};
`;

const StyledLi = styled.li`
  padding: 5px 0px;
  color: white;
  font-size: 12px;
`;

const Title = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  font-weight: ${typography.weight.bold};
  color: white;
  padding: 8px 35px 5px 5px;
  text-align: left;
  border-radius: 3px 3px 0px 0px;
  background: ${globalColors.slate25};
`;

const StyledUl = styled.ul`
  list-style: none;
  padding: 5px;
  margin: 0;
  text-align: left;
`;

const StyledTooltip = styled(Tooltip)`
  padding: 0;
  text-align: left;
  word-break: break-word;
  li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  }
`;

const ReportNameTooltip = styled(Tooltip)`
  word-break: break-word;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.color,
  size: props.size,
  icon: props.icon,
}))``;

export const SentReportList = ({
  sentReportListData,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  scrollable,
  className,
  onFilter,
  onFilterChange,
  filterValues,
}) => {
  const { t, ready } = useTranslation(['reporting.reports']);

  const { getViewServiceGroupsSites: viewServiceGroups = [], sitesForView = [] } = useStoreState(
    (state) => state.sites,
  );

  const getScopeName = useCallback(
    (id, type) => {
      if (type === AttributeGroups.SERVICE_GROUP) {
        const serviceGroup = viewServiceGroups.find((sg) => sg?.id === id);
        if (serviceGroup?.name) {
          return serviceGroup.name;
        }
      } else if (type === AttributeGroups.SITES) {
        const site = sitesForView.find((s) => s?.id === id);
        if (site?.name) {
          return site.name;
        }
      }
      return id;
    },
    [sitesForView, viewServiceGroups],
  );

  const [isSendReportModalOpen, setIsSendReportModalOpen] = useState(false);
  const [sentReportData, setSentReportData] = useState(null);
  const [sentReportDistributionList, setSentReportDistributionList] = useState([]);

  const getSentReportsMenuItems = useActionsMenu({ menuItems: SentReportsMenuItems });
  const getActionsMenuItems = useCallback(
    ({ siteIds }) => {
      return getSentReportsMenuItems(siteIds.map((sid) => ({ site: { id: sid } })));
    },
    [getSentReportsMenuItems],
  );

  const getDownloadMenuItems = useCallback(({ pdfFile, zipFile, hasAttachments }) => {
    const menuItems = [];

    if (pdfFile) {
      const downloadPdf = { ...ActionsMenuItems[ActionKeys.DOWNLOAD_REPORT] };
      downloadPdf.href = pdfFile.file;
      downloadPdf.target = '_blank';
      downloadPdf.download = pdfFile.fileName;
      menuItems.push(downloadPdf);
    }

    // Without attachments, zip file may still be present but will only contain the PDF
    if (zipFile && hasAttachments) {
      const downloadZip = { ...ActionsMenuItems[ActionKeys.DOWNLOAD_REPORT_AND_ATTACHMENTS] };
      downloadZip.href = zipFile.file;
      downloadZip.download = zipFile.fileName;
      menuItems.push(downloadZip);
    }

    return menuItems;
  }, []);

  const handleAction = useCallback(
    ({ entity, type }) => {
      if (type === ActionKeys.FORWARD_REPORT) {
        const scopeName = getScopeName(entity.scope, entity.scopeType);
        const timeRange = {
          startDate: entity.startDate,
          endDate: entity.endDate,
        };
        setSentReportData({ ...entity, scopeName, timeRange });
        if (entity.distributionList?.length > 0) {
          setSentReportDistributionList([...entity.distributionList]);
        }
        setIsSendReportModalOpen(true);
      }
    },
    [getScopeName],
  );

  const handleUpdateDistributionList = useCallback(
    (updatedList) => {
      setSentReportDistributionList(updatedList);
    },
    [setSentReportDistributionList],
  );

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const { filterDefs, onChange: onFilterApply } = useFilterDefs({
    columnDefs: SentReportListColumnDefs,
  });

  useEffect(() => {
    onFilter(filterDefs);
  }, [filterDefs, onFilter]);

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilterApply(columnKey, value);
    },
    [onFilterApply],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const displayAttachmentList = (attachments) => {
    const sortedFileNames = attachments?.map((file) => file.fileName).sort() ?? [];
    const attachmentFileName = sortedFileNames.map((file) => {
      return <StyledLi key={`${file}`}>{file}</StyledLi>;
    });

    return (
      <>
        <Title>{t('sent_reports.attachments', 'ATTACHMENTS')}</Title>
        <StyledUl>{attachmentFileName}</StyledUl>
      </>
    );
  };

  // Using forwardRef in order for Badge component to render w/ tooltip
  const BadgeComponent = forwardRef(function BadgeComponent(props, ref) {
    return <div {...props} ref={ref}></div>;
  });

  const cellValueMapFn = useCallback(
    (cell) => {
      if (!cell) {
        return {};
      }

      const {
        reportId,
        name,
        scope,
        scopeType,
        createdBy,
        dateSent,
        attachments,
        fileLink,
        reportAndAttachmentZipFile,
        reportType,
      } = cell;

      const downloadFileList = {
        hasAttachments: attachments?.length > 0,
        pdfFile: fileLink,
        zipFile: reportAndAttachmentZipFile,
      };

      const attachmentList = attachments?.map(({ fileName, contentType }) => {
        return {
          id: reportId,
          fileName: fileName,
          contentType: contentType,
        };
      });

      const displayDateFormatted = dateSent
        ? dayjs(dateSent).format(DateTimeFormats.DEFAULT_DATE)
        : null;

      const reportScopeSiteIds =
        {
          [AttributeGroups.SERVICE_GROUP]: viewServiceGroups[scope]?.sites?.map(({ id }) => id),
          [AttributeGroups.SITES]: [scope],
        }[scopeType] ?? [];

      return {
        [SentReportListColumns.SENT_REPORT_NAME]: name,
        [SentReportListColumns.DOWNLOAD]: downloadFileList,
        [SentReportListColumns.ATTACHMENTS]: attachmentList,
        [SentReportListColumns.TEMPLATE]: reportType,
        [SentReportListColumns.SENT_REPORT_SCOPE]: getScopeName(scope, scopeType),
        [SentReportListColumns.CREATED_BY]: createdBy,
        [SentReportListColumns.DATE_SENT]: displayDateFormatted,
        [SentReportListColumns.ACTIONS]: { ...cell, siteIds: reportScopeSiteIds },
      };
    },
    [getScopeName, viewServiceGroups],
  );

  const customHeaderFn = useCallback((columnKey) => {
    switch (columnKey) {
      case SentReportListColumns.GROUP_SENT_REPORT_NAME:
      case SentReportListColumns.GROUP_DOWNLOAD:
      case SentReportListColumns.GROUP_ATTACHMENTS:
      case SentReportListColumns.GROUP_TEMPLATE:
      case SentReportListColumns.GROUP_SENT_REPORT_SCOPE:
      case SentReportListColumns.GROUP_CREATED_BY:
      case SentReportListColumns.GROUP_DATE_SENT:
      case SentReportListColumns.GROUP_ACTIONS:
        return <ThGroup />;
      default:
        return null;
    }
  }, []);

  const customCellFn = (columnKey, cellValue) => {
    switch (columnKey) {
      case SentReportListColumns.SENT_REPORT_NAME:
        return (
          cellValue &&
          withDefault(
            <ReportNameTooltip
              title={cellValue}
              placement={placements.BOTTOM}
              zIndex={elevations.P20}
            >
              <span>{cellValue}</span>
            </ReportNameTooltip>,
          )
        );
      case SentReportListColumns.TEMPLATE:
      case SentReportListColumns.SENT_REPORT_SCOPE:
      case SentReportListColumns.CREATED_BY:
      case SentReportListColumns.DATE_SENT:
        return withDefault(cellValue, <StyledDisplay>{cellValue}</StyledDisplay>);
      case SentReportListColumns.DOWNLOAD:
        return withDefault(
          <ActionsMenu
            entity={cellValue}
            actionMenuIcon={
              <StyledIcon color={globalColors.slate3} icon={Icons.EXPORT} size={20} />
            }
            menuItems={getDownloadMenuItems(cellValue)}
          />,
        );
      case SentReportListColumns.ATTACHMENTS:
        return (
          cellValue?.length > 0 &&
          withDefault(
            <StyledTooltip
              title={displayAttachmentList(cellValue)}
              placement={placements.BOTTOM_END}
              zIndex={elevations.P20}
            >
              <BadgeComponent>
                <Badge label={cellValue?.length} color={globalColors.slate3} />
              </BadgeComponent>
            </StyledTooltip>,
          )
        );
      case SentReportListColumns.ACTIONS:
        return withDefault(
          <ActionsMenu
            entity={cellValue}
            actionMenuIcon={
              <StyledIcon color={globalColors.slate3} icon={Icons.POINTER} size={20} />
            }
            menuItems={getActionsMenuItems(cellValue)}
            onAction={handleAction}
          />,
        );
      default:
        return null;
    }
  };

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: SentReportListColumnDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    filterValues,
    filters: filterDefs,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
  });

  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: sentReportListData,
    onValueSelect: () => null,
    rowKeyProperty: 'reportId',
  };

  if (!ready) {
    return <Loader />;
  }

  const tableEl = React.createElement(DynamicTable, dynamicProps);

  return (
    <PageContainer i18nReady={ready}>
      {tableEl}
      <SendReportModal
        isOpen={isSendReportModalOpen}
        onModalClose={() => setIsSendReportModalOpen(false)}
        sentReportData={sentReportData}
        distributionList={sentReportDistributionList}
        onDistributionListChanged={handleUpdateDistributionList}
      />
    </PageContainer>
  );
};

SentReportList.propTypes = {
  sentReportListData: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  onNewReportSelect: PropTypes.func,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
};

SentReportList.defaultProps = {
  columns: globalSentReportListCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onNewReportSelect: () => null,
  scrollable: true,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
};
