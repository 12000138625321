import { useStoreActions, useStoreState } from 'easy-peasy';
import { useQuery } from 'react-query';

import { EntityType, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

// TODO: cut everything over to using this so we don't make redundant calls for events
// TODO: think about standard params for data hooks to support different entity types...
export const useEvents = ({ entityId, entityType = EntityType.SITE, isActive }) => {
  const fetchEvents = useStoreActions((actions) => actions.issues.fetchEvents);

  const { error, isLoading } = useQuery([QueryKey.EVENTS], fetchEvents, {
    ...Config.EXECUTE_ONCE,
    enabled: isActive,
  });

  const getEventsByAssetId = useStoreState((state) => state.issues.getEventsByAssetId);
  const getEventsBySiteId = useStoreState((state) => state.issues.getEventsBySiteId);

  // TODO: add support for additional entity types here
  const getEvents = entityType === EntityType.SITE ? getEventsBySiteId : getEventsByAssetId;

  const data = entityId ? getEvents(entityId) : null;

  return {
    data,
    error,
    isLoading,
  };
};
