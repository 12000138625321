import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo, useContext } from 'react';
import { useMutation } from 'react-query';

import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { toSiteISOString } from '@ge/shared/util/time-date';

/* Sample Task Format
https://devcloud.swcoe.ge.com/devspace/display/NBJXM/Low+Level+Design+for+Field+Task
{
  "assetSourceKeys": [
    "A01"
  ],
  "priority": "LOW",
  "properties": {
    "title": "test-2",
    "description": "test description",
    "scheduleDate": "2021-04-30T11:33:43",
    "eligibleStartDate": "2021-04-30T11:33:43.00",
    "estimatedDuration": "10:10",
    "dueDate": "2021-04-30T11:33:43.00",
    "taskAddedOn": "2021-04-30T11:33:43.00",
    "recurrence": {
      "recurrence": "5",
      "recurrenceType": "EndByDate",
      "recurrenceCycle": "1 Days",
      "recurrenceCycleOccurrence": "Days",
      "recurrenceEndByCount": "",
      "recurrenceEndByDate": "2021-04-26T11:33:43.00"
    },
    "group": "Hub"
  },
  "siteGroupSourceKey": "Site1",
  "taskStatus": "planned",
  "taskType": "Maintenance",
  "tenantId": "dfsa",
  "taskCategory": "turbine"
}
 */

/**
 * Bulk schedule tasks.
 *
 * @param opts useMutation options
 * @returns {{bulkSchedule: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useBulkScheduleTask = (opts = {}) => {
  const { sites: sitesById } = useStoreState((state) => state.sites);

  const { bulkScheduleTask } = useStoreActions((store) => store.tasks);
  const { tasks } = useStoreState((store) => store.tasks);
  const { taskPagesData, updateBulkScheduleTask } = useContext(EntityDetailsContext);

  // Manage lifecycle with react-query mutation
  const {
    mutate: bulkSchedule,
    isLoading,
    isError,
    error,
  } = useMutation((bulkTasks = {}) => {
    const payload = Object.entries(bulkTasks).map(
      ([
        id,
        {
          scheduleDate,
          startTime,
          assignee,
          isBundled,
          bundledTasks,
          flag,
          taskId,
          task = taskPagesData.length > 0
            ? taskPagesData?.find((task) => id === task?.id)
            : Object.values(tasks)?.find((task) => id === task?.id),
        },
      ]) => ({
        id,
        taskId: taskId ? taskId : task?.taskId, // Multiple 'task' ids make this confusing
        scheduledDate: scheduleDate
          ? toSiteISOString(
              dayjs(dayjs(scheduleDate).format('YYYY-MM-DD')),
              sitesById[task?.site?.id].timezone,
            )
          : null,
        startTime: startTime !== '' && startTime !== null ? startTime : null,
        crewIds: assignee ? [assignee] : null,
        isBundled: isBundled,
        bundledTasks: bundledTasks,
        approvalFlag: flag ? flag : task?.flag,
      }),
    );

    // Removing crewIds attribute if the value is null or undefined
    Object.entries(payload).forEach((key) => {
      return key?.map((data) => {
        if (
          data?.crewIds === null ||
          data?.crewIds === undefined ||
          data?.scheduledDate === null ||
          data?.scheduledDate === undefined
        ) {
          delete data?.crewIds;
        }
      });
    });

    return bulkScheduleTask({ bulkTasks: payload, updateBulkScheduleTask, tasks });
  }, opts);
  return useMemo(
    () => ({ bulkSchedule, isLoading, isError, error }),
    [bulkSchedule, error, isError, isLoading],
  );
};
