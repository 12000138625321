import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { AssetType, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchMalEvents } from '../services';

export const useMalEvents = ({ startDate, endDate, siteIds }) => {
  const {
    data: _data,
    error,
    isLoading,
  } = useQuery(
    [QueryKey.CONTRACTUAL_REPORTING_MAL_EVENTS, startDate, endDate, siteIds],
    () =>
      fetchMalEvents({
        assetType: AssetType.SITE,
        assetValues: siteIds,
        startDate,
        endDate,
        onlyMal: true,
      }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(siteIds?.length > 0),
    },
  );

  const data = useMemo(() => {
    const malEvents = _data?.malEvents;
    const restructuredMalEvents = [];

    malEvents?.forEach((asset) => {
      asset.events.forEach((malEvent, idx) => {
        const newMalEvent = Object.assign(malEvent, {});
        newMalEvent.id = `${asset.assetId}.${asset.day}.${idx}`;
        newMalEvent.assetId = asset.assetId;
        restructuredMalEvents.push(newMalEvent);
      });
    });

    return restructuredMalEvents;
  }, [_data]);

  return { data, error, isLoading };
};
