import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { ConditionalWrapper } from '@ge/components/conditional-wrapper';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { useNotification } from '@ge/components/notification';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import {
  EventRefreshStatus,
  EventRefreshButtonWithToolTip,
} from '@ge/feat-monitor/components/event-refresh';
import { NewCaseDialog } from '@ge/feat-monitor/components/new-case-dialog';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import {
  DataLoaderType,
  Capability,
  IssueType,
  EntityType,
  DateTimeFormats,
  AssetConnectionStatus,
  PermissionScope,
  CustomEventRefreshCommand,
  AssetCommandStatus,
  QueryKey,
  TaskSourceField,
} from '@ge/models/constants';
import { AssetType } from '@ge/models/entities/asset/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import Breadcrumbs from '@ge/shared/components/breadcrumbs';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAssetRealTimeData, useAssetCommands } from '@ge/shared/data-hooks';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens';

import SensorReadingsList from '../../components/sensor-readings-list';
import { TabDetailContainer, EntityCollapsiblePanel } from '../../entity-details-shared';
import AssetAlarmTable from '../asset-alarm-table';
import AssetAlternateAlarmTable from '../asset-alternate-alarm-table';
import { AssetOpenCasesAndTasks } from '../asset-cases-and-tasks';

import { AssetBreakers } from './asset-breakers';
import { AssetController } from './asset-controller';

// Hiding for MVP0 - Story #533
// import AssetEventTable from '../asset-event-table';

export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.entityDetails.overviewLink};
  font-weight: ${typography.weight.medium};
  letter-spacing: 0.5px;
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 125px;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const LinkIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const SensorControllerContainer = styled.div`
  display: flex;
  height: auto;
`;

const StyledSensorReadings = styled.div`
  background: ${(props) => props.theme.entityDetails.sitePanelBlockBackground};
  border-radius: 3px;
  margin: 10px 10px 18px 3px;
  padding: 5px 5px 5px 0px;
  position: relative;
  flex: 1;
  > div {
    margin-bottom: 0px;
    padding-left: 0;
  }
  .collapsible-panel-content {
    > section {
      margin-bottom: 30px;
    }
  }
  &.with-asset-controller {
    margin: 10px 10px 7px;
    padding: 5px;
    margin-right: 15px;
  }
`;

const ScrollingTableContainer = styled.div`
  > div {
    min-height: 272px;
  }
`;

const NewCaseButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  line-height: 13px;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  width: 115px;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const Header = styled.div`
  position: absolute;
  top: 5px;
  right: 35px;
  .new-task-button {
    display: flex;
    font-family: 'Museo Sans';
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    color: white;
    padding: 0 !important;
    border-radius: 0px !important;
    background: none !important;
    border: none !important;
  }
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const CaseAndTasksHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const EventMetric = {
  START: 'start',
};

const TableScrollRowCount = 8;

const EventRefreshWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 99;
`;

const StyledEntityCollapsiblePanel = styled(EntityCollapsiblePanel)`
  margin-bottom: 20px;
  position: relative;
`;

export const AssetRealtimeInfo = ({ assetId, handleNewTask, setShowLinkModal }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const { notify } = useNotification();
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [showNewCaseModal, setShowNewCaseModal] = useState(false);
  const [commandState, setCommandState] = useState(true);
  const { showDetails } = useContext(EntityDetailsContext);
  const queryClient = useQueryClient();
  const [eventsRefreshing, setEventsRefreshing] = useState(false);
  const { isAuthorized } = useAuth();
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const assetDetails = getAssetById(assetId);
  const getSiteById = useStoreState((store) => store.sites.getSiteById);
  const isAuthorizedToViewTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedToMonitorCases = isAuthorized({
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedToAnalyzeCases = isAuthorized({
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
    ],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedToCreateAnalyzeCase = isAuthorized({
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.CREATE] },
    ],
    siteIds: [assetDetails?.site?.id],
  });
  const isAuthorizedForEventRefresh = isAuthorized({
    capabilities: [{ capability: Capability.ROC_COMMANDS, scopes: [PermissionScope.CREATE] }],
    siteIds: [assetDetails?.site?.id],
  });

  //#TODO: Commenting for MVP0
  //const getSortedEvents = useStoreState((state) => state.issues.getSortedEvents);

  const { showEventDetails } = useContext(EntityDetailsContext);
  // const assetId = asset.id;

  // Table state management
  const {
    sortDirection: sortActiveDirection,
    sortMetric: sortActiveMetric,
    updateSortMetric: toggleActiveSort,
  } = useColumnState({
    defaultSortMetric: EventMetric.START,
    defaultSortDirection: SortDirection.DESC,
  });

  const {
    data: asset,
    isLoading,
    dataUpdatedAt,
    isFetching,
  } = useAssetRealTimeData({
    assetId,
    sortDirection: sortActiveDirection,
    sortMetric: sortActiveMetric,
  });

  const { data: eventCommand } = useAssetCommands({
    assets: [assetDetails],
    isActive: isAuthorizedForEventRefresh,
    enableSafetyCheck: commandState,
  });

  const { connection } = asset?.metrics ?? {};
  const [selectedTable, setSelectedTable] = useState(
    isAuthorizedToMonitorCases || isAuthorizedToAnalyzeCases
      ? IssueType.OPEN_CASES
      : IssueType.FIELD_TASK,
  );
  const tableOptions = useMemo(
    () => [
      {
        value: IssueType.OPEN_CASES,
        label: t('cases_and_tasks_options.open_cases', 'Open Cases'),
      },
      {
        value: IssueType.FIELD_TASK,
        label: t('cases_and_tasks_options.field_tasks', 'Field Task'),
      },
    ],
    [t],
  );
  const handleSelect = useCallback((option) => setSelectedTable(option.value), []);

  const selectedTableValue = useMemo(
    () => tableOptions.filter((option) => option.value === selectedTable),
    [selectedTable, tableOptions],
  );

  //#TODO: Commenting for MVP0
  //const events = getSortedEvents(sortActiveMetric, sortActiveDirection, assetId);

  const handleEventSelect = useCallback(
    (e, event) => {
      killEventPropagation(e);
      showEventDetails(event.id);
    },
    [showEventDetails],
  );

  useEffect(() => {
    if (eventCommand?.isComplete) {
      if (eventCommand?.status?.[0]?.status === AssetCommandStatus.SUCCESS && eventsRefreshing) {
        queryClient.invalidateQueries(QueryKey.ASSET_REALTIME_DATA);
      }
      setEventsRefreshing(false);
      setCommandState(true);
    }
  }, [eventCommand, queryClient, eventsRefreshing]);

  const handleEventRefresh = useCallback(
    async (e) => {
      killEventPropagation(e);
      setCommandState(false);
      const resp = await eventCommand.execute(CustomEventRefreshCommand);
      if (resp?.statusId) {
        setEventsRefreshing(true);
      }
    },
    [eventCommand, setEventsRefreshing],
  );

  const newFieldTask = useCallback(() => {
    setShowNewTaskModal(true);
  }, [setShowNewTaskModal]);

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
  }, []);

  const handleEntitySelect = useCallback(
    (e, id, type, isAuthorized) => {
      killEventPropagation(e);
      if (isAuthorized) showDetails(id, type);
    },
    [showDetails],
  );

  const handleNewCase = useCallback(() => {
    setShowNewCaseModal(true);
  }, [setShowNewCaseModal]);

  const handleNewCaseDialogClose = useCallback(
    (caseData) => {
      const cases = caseData?.cases;
      if (caseData && caseData.id) {
        cases.push(caseData);
      }

      if (cases?.length > 0) {
        cases.map((caseData) => {
          const asset = getAssetById(caseData?.asset.id);
          const site = getSiteById(caseData?.site.id);

          const status = (
            <Breadcrumbs onBreadCrumbClick={handleEntitySelect} site={site} asset={asset} />
          );

          const message = (
            <NotificationMessage
              message={t('case_created_success', 'Case Created Successfully').toUpperCase()}
            />
          );

          const timestamp = dayjs(caseData.createDate).format(DateTimeFormats.NOTIFICATION_TIME);

          return notify({ status, message, timestamp });
        });
      }

      setShowNewCaseModal(false);
    },
    [getAssetById, getSiteById, handleEntitySelect, notify, t],
  );

  const getAlarmTableHeaderContent = () => {
    switch (asset?.type) {
      case AssetType.SITE_CONTROLLER:
        return t('asset_panel.site_controller_alarms', 'Site Controller Alarms');
      case AssetType.SUBSTATION:
        return t('asset_panel.substation_alarms', 'Substation Alarms');
      default:
        return t('asset_panel.active_alarms_events', 'Active Alarms & Events');
    }
  };

  const getActiveCasesAndTasksHeaderContent = useCallback(() => {
    return isAuthorizedToViewTasks && (isAuthorizedToMonitorCases || isAuthorizedToAnalyzeCases)
      ? t('asset_panel.active_cases_and_tasks', 'Active Cases & Tasks')
      : isAuthorizedToViewTasks
      ? t('asset_panel.active_tasks', 'Active Tasks')
      : t('asset_panel.active_cases', 'Active Cases');
  }, [isAuthorizedToViewTasks, isAuthorizedToMonitorCases, isAuthorizedToAnalyzeCases, t]);

  const tableShouldScroll = useMemo(
    () => asset.alarms?.length >= TableScrollRowCount,
    [asset.alarms],
  );

  const getAlarmTable = () => {
    switch (asset?.type) {
      case AssetType.SITE_CONTROLLER:
      case AssetType.SUBSTATION:
        return (
          <AssetAlternateAlarmTable
            alarms={asset.alarms || []}
            isLoading={isLoading}
            isDisconnected={connection?.value === AssetConnectionStatus.NOCOMM}
            sortAction={toggleActiveSort}
            sortMetric={sortActiveMetric}
            sortDirection={sortActiveDirection}
            onEventSelect={handleEventSelect}
            scrollable={tableShouldScroll}
            assetId={assetId}
            siteId={assetDetails?.site?.id}
          />
        );
      default:
        return (
          <AssetAlarmTable
            alarms={asset.alarms || []}
            isLoading={isLoading}
            isDisconnected={connection?.value === AssetConnectionStatus.NOCOMM}
            sortAction={toggleActiveSort}
            sortMetric={sortActiveMetric}
            sortDirection={sortActiveDirection}
            onEventSelect={handleEventSelect}
            scrollable={tableShouldScroll}
            assetId={assetId}
            siteId={assetDetails?.site?.id}
          />
        );
    }
  };

  if (!ready || !asset) {
    return null;
  }

  return (
    <TabDetailContainer>
      <SensorControllerContainer>
        <AuthRender
          capability={Capability.ROC_COMMANDS}
          create
          description="ROC Commands"
          siteLevel={false}
        >
          {asset?.type === AssetType.WIND_TURBINE && <AssetController asset={asset} />}
        </AuthRender>
        <StyledSensorReadings
          className={asset?.type === AssetType.WIND_TURBINE && 'with-asset-controller'}
        >
          <EntityCollapsiblePanel
            headerContent={<h2>{t('asset_panel.sensor_readings', 'Sensor Readings')}</h2>}
            expanded={true}
            triggerHeight={isLoading} // To ensure that the new height is considered by the collapsiblePanel
          >
            <DataLoader isLoading={isLoading} type={DataLoaderType.TABLE} renderCondition={true}>
              <SensorReadingsList asset={asset} />
            </DataLoader>
          </EntityCollapsiblePanel>
        </StyledSensorReadings>
      </SensorControllerContainer>
      {asset?.type === AssetType.SUBSTATION && (
        <DataLoader isLoading={isLoading} type={DataLoaderType.TABLE} renderCondition={true}>
          <AssetBreakers asset={asset} />
        </DataLoader>
      )}
      <StyledEntityCollapsiblePanel
        headerContent={<h2 className="add-margin">{getAlarmTableHeaderContent()}</h2>}
        expanded={true}
        triggerHeight={isFetching} // To ensure that the new height is considered by the collapsiblePanel
      >
        {/* Hiding for MVP0 - Story #533 */}
        {/* <AssetEventTable
          events={events}
          sortAction={toggleActiveSort}
          sortMetric={sortActiveMetric}
          sortDirection={sortActiveDirection}
          onEventSelect={handleEventSelect}
        /> */}
        {/* Using events data as alarms until we get alarms data */}
        <AuthRender
          capability={Capability.ROC_COMMANDS}
          create
          description="ROC Commands"
          siteLevel={false}
        >
          <EventRefreshWrapper>
            <EventRefreshStatus
              eventStatus={eventCommand?.status?.[0]?.status}
              eventsRefreshing={eventsRefreshing}
              translate={t}
              nextCycle={dataUpdatedAt}
              commandState={commandState}
            />
            <EventRefreshButtonWithToolTip
              onClick={handleEventRefresh}
              inProgress={eventsRefreshing}
              toolTip={
                eventsRefreshing
                  ? t('event_refresh.tooltip.in_progress', 'Refresh in Progress')
                  : t('event_refresh.tooltip.manual_refresh', 'Manual Refresh Events')
              }
            />
          </EventRefreshWrapper>
        </AuthRender>
        <ConditionalWrapper
          condition={tableShouldScroll}
          wrapper={(children) => <ScrollingTableContainer>{children}</ScrollingTableContainer>}
        >
          {getAlarmTable()}
        </ConditionalWrapper>
      </StyledEntityCollapsiblePanel>
      {(isAuthorizedToViewTasks || isAuthorizedToMonitorCases || isAuthorizedToAnalyzeCases) &&
        assetDetails?.site?.id && (
          <EntityCollapsiblePanel
            headerContent={
              <CaseAndTasksHeaderWrapper>
                <h2>{getActiveCasesAndTasksHeaderContent()}</h2>
                {isAuthorizedToCreateAnalyzeCase && selectedTable === IssueType.OPEN_CASES ? (
                  <NewCaseButton onClick={handleNewCase}>
                    <AddIcon />
                    {t('asset_panel.new_case', 'New Case')}
                  </NewCaseButton>
                ) : isAuthorizedToViewTasks && selectedTable === IssueType.FIELD_TASK ? (
                  <Header>
                    <AuthRender
                      capability={Capability.FIELD_TASKS}
                      create
                      description="New Task button"
                      siteLevel={false}
                    >
                      <Button className="new-task-button" primary onClick={newFieldTask}>
                        <AddIcon /> {t('new_task', 'New Task')}
                      </Button>
                    </AuthRender>
                  </Header>
                ) : null}
              </CaseAndTasksHeaderWrapper>
            }
            triggerHeight={isLoading}
            expanded={true}
          >
            <ConditionalWrapper
              condition={true}
              wrapper={(children) => <ScrollingTableContainer>{children}</ScrollingTableContainer>}
            >
              <AssetOpenCasesAndTasks
                handleSelect={handleSelect}
                selectedTable={selectedTable}
                selectedTableValue={selectedTableValue}
                tableOptions={tableOptions}
                assetId={assetId}
                isAuthorizedToViewTasks={isAuthorizedToViewTasks}
                isAuthorizedToMonitorCases={isAuthorizedToMonitorCases}
                isAuthorizedToAnalyzeCases={isAuthorizedToAnalyzeCases}
                siteId={assetDetails?.site?.id}
                handleNewTask={handleNewTask}
                setShowLinkModal={setShowLinkModal}
              />
            </ConditionalWrapper>
          </EntityCollapsiblePanel>
        )}
      {showNewTaskModal && (
        <NewTaskDialog
          entity={asset}
          entityType={EntityType.ASSET}
          onClose={closeModal}
          onConfirm={saveTask}
          taskSource={TaskSourceField.MANUAL}
        />
      )}
      {showNewCaseModal && (
        <NewCaseDialog
          isOpen={true}
          selectedEntityId={assetId}
          onClose={handleNewCaseDialogClose}
        />
      )}
    </TabDetailContainer>
  );
};

AssetRealtimeInfo.propTypes = {
  assetId: PropTypes.string,
  causalEvent: PropTypes.string,
  setCausalEvent: PropTypes.func,
  openFhp: PropTypes.func,
  handleNewTask: PropTypes.func,
  setShowLinkModal: PropTypes.func,
};
