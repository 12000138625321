import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Button } from '@ge/components/button';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Icon, Icons } from '@ge/components/icon';
import { SpinLoader } from '@ge/components/loader';
import { useNotification } from '@ge/components/notification';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { EntityType, QueryKey, TaskFlag } from '@ge/models/constants';
import { Capability, TaskTemplateModes, TaskRecurrence, TaskStatus } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import {
  ColumnTitle,
  ColumnDetail,
  ReopenButton,
} from '@ge/shared/components/tasks/task-template-shared';
import { CloseTaskTemplate } from '@ge/shared/components/tasks/templates/close-task-template';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useCloseTask, useReopenTask } from '@ge/shared/data-hooks';
import { toSiteISOString } from '@ge/shared/util/time-date';

import { useFetchMultiplePerson } from '../../entity-details/person-details/hooks/use-fetch-multiple-person';
import TabDetailHeader from '../components/tab-detail-header';
import { TabDetailContainer } from '../entity-details-shared';

dayjs.extend(isSameOrBefore);

const TaskDetailWrapper = styled.div`
  margin-right: 32px;
  padding-bottom: 40px;
  .task-panel-row {
    align-items: flex-start;
    display: flex;
    &:last-of-type {
      .column-detail {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

const TaskIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tasks.details.columnButtonColor,
}))`
  position: absolute;
  left: 0;
`;
const Footer = styled.div`
  margin-left: 260px;
  .spinner {
    left: calc(67% - 30px);
    margin-top: -7px;
  }
`;

const DisplayTaskContainer = styled.section``;
const EditTaskContainer = styled.section``;

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 10px 218px;
`;

export const TaskResolution = ({
  task,
  workerData,
  allCrews,
  continueDirtyCheck,
  setContinueDirtyCheck,
}) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const queryClient = useQueryClient();
  const [deleteWrkrIds, setDeleteWrkrIds] = useState([]);

  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const { enabledApprovalFlag } = useStoreState((state) => state.tasks);
  const isDisabledReopenTask =
    task.status === TaskStatus.COMPLETED && task?.recurrence?.recurrence !== TaskRecurrence.NONE
      ? true
      : false;

  const {
    taskState: { timezone },
  } = useContext(TaskContext);
  const entityDetailsContext = useContext(EntityDetailsContext);

  const { setDirtyFields, crewForBundledTask, setCrewForBundledTask } = entityDetailsContext;

  const closeSecondaryTask = useCallback(
    (crewId, isSecondaryBundle) => {
      setCrewForBundledTask((prevState) => ({
        ...prevState,
        crewId,
        isSecondaryBundle,
      }));
    },
    [setCrewForBundledTask],
  );

  task.timing = task.timing
    ? task.timing
    : [
        {
          date: '',
          durationHrs: 0,
          durationMins: 0,
          startTime: '',
          endTime: '',
        },
      ];

  const crewId =
    crewForBundledTask?.isSecondaryBundle && crewForBundledTask?.crewId
      ? crewForBundledTask?.crewId[0]
      : task?.crewIds && task?.crewIds[0];
  const filteredCrewData = allCrews
    ?.filter((crew) => crew?._id === crewId)
    ?.map((filteredCrew) => filteredCrew.members);
  const members = [].concat.apply([], filteredCrewData);
  const allMemberIds = members?.map((mm) => mm?.member_id);
  const memIds = Object.keys(task).includes('completedByTech')
    ? members?.map((mm) =>
        task?.completedByTech?.find((mem) => mm.member_id === mem && mm.member_id),
      )
    : [];
  const validMember = memIds.filter((mId) => mId !== undefined);
  const memberIds =
    task?.completedByTech?.length > 0
      ? task?.completedByTech
      : crewId && !Object.keys(task).includes('completedByTech')
      ? allMemberIds
      : validMember && validMember.length
      ? validMember
      : [];
  const allWorkerIds = workerData?.map((w) => w?.username);
  const filteredWorkerIds = task?.completedByTech?.filter((m) => !allWorkerIds.includes(m));
  const {
    data: fetchedMultiplePerson,
    refetch,
    isLoading: isLoadingMultiplePerson,
  } = useFetchMultiplePerson(filteredWorkerIds ? filteredWorkerIds : '');
  useEffect(() => {
    refetch();
  }, [refetch]);

  const lookedUpMember = useMemo(() => {
    let fetchWorkers = fetchedMultiplePerson ? fetchedMultiplePerson : [];
    return fetchWorkers;
  }, [fetchedMultiplePerson]);

  const allvalidWorkers = workerData?.filter((w) => memberIds.includes(w.username));
  const allWorkers = useMemo(() => {
    let allFilteredWorkers;
    if (task?.completedByTech) {
      let validWorker = allvalidWorkers.concat(lookedUpMember);
      allFilteredWorkers = validWorker?.filter((m) => m !== undefined);
    } else {
      allFilteredWorkers = allvalidWorkers;
    }
    return allFilteredWorkers;
  }, [task, allvalidWorkers, lookedUpMember]);
  const filteredWorkers = useMemo(() => {
    return deleteWrkrIds && deleteWrkrIds.length
      ? allWorkers?.filter((w) => !deleteWrkrIds.includes(w.username))
      : allWorkers;
  }, [allWorkers, deleteWrkrIds]);

  const [workerState, setWorkerState] = useState([]);
  useDeepCompareEffect(() => {
    const defaultFilteredWorkers = () => {
      const crewId =
        crewForBundledTask?.isSecondaryBundle && crewForBundledTask?.crewId
          ? crewForBundledTask?.crewId[0]
          : task?.crewIds && task?.crewIds[0];
      const taskCrewMemberIds = (allCrews ?? [])
        .filter((crew) => crew._id === crewId)
        .flatMap((filteredCrew) => filteredCrew.members?.map((member) => member.member_id));
      return workerData?.filter((w) => taskCrewMemberIds.includes(w.username));
    };
    const finalAssignedWorkers = filteredWorkers?.length
      ? filteredWorkers
      : defaultFilteredWorkers();
    setWorkerState(finalAssignedWorkers);
  }, [allWorkers]);
  let techsValidation = (values, updatedFilterWorkers) => {
    setWorkerState(values?.completedByTech?.length ? updatedFilterWorkers : []);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetEditPanel = {
    resolution: task?.resolution,
    consumedParts:
      task?.consumedParts && task?.consumedParts.length > 0
        ? task.consumedParts
        : task?.expectedParts && !task?.completedDateTime
        ? task?.expectedParts
        : [
            {
              number: '',
              name: '',
              quantity: '',
            },
          ],
    timing: task?.timing,
    completedByTech: task?.completedByTech,
    resolutionNotes: task?.resolutionNotes,
  };
  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onChange',
    defaultValues: resetEditPanel,
  });
  const siteId = task?.asset?.site?.id ?? task?.site?.id;

  const [isEditTaskPanel, setIsEditTaskPanel] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [shouldRender, setshouldRender] = useState(task.status === 'completed' ? true : false);
  const { notify } = useNotification();

  useEffect(() => {
    if (continueDirtyCheck) {
      setIsEditTaskPanel(false);
      setContinueDirtyCheck(false);
    }
  }, [continueDirtyCheck, setContinueDirtyCheck, setIsEditTaskPanel]);

  useEffect(() => {
    if (isEditTaskPanel) {
      setDirtyFields(true);
    } else {
      setDirtyFields(false);
    }
  }, [isEditTaskPanel, methods.formState.isDirty, setDirtyFields]);

  const [isError, setError] = useState(false);
  const setEditPanel = (data, result) => {
    let setData = {};
    if (result.task?.resolutionNotes) setData.resolutionNotes = result.task.resolutionNotes;
    if (result.task?.consumedParts) setData.consumedParts = result.task.consumedParts;
    if (result.task?.timing) setData.timing = result.task.timing;
    if (result.task?.completedByTech) setData.completedByTech = result.task.completedByTech;
    if (setData) methods.reset(setData);
    setIsEditTaskPanel(false);
    setError(false);
  };

  const { close } = useCloseTask({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([QueryKey.ASSET_TASK_HISTORY, task?.asset?.id]);
      if (task?.caseId) {
        queryClient.invalidateQueries([QueryKey.CASE_DETAIL, task.caseId]);
      }
      queryClient.invalidateQueries([QueryKey.ASSET_TASKS + task?.asset?.id, task?.asset?.id]);
      setEditPanel(data, variables);
    },
    onError: () => {
      setError(true);
    },
  });

  const { reopen } = useReopenTask({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.ASSET_TASK_HISTORY, task?.asset?.id]);
      if (task?.caseId) {
        queryClient.invalidateQueries([QueryKey.CASE_DETAIL, task.caseId]);
      }
    },
    onError: () => {
      setError(true);
    },
  });

  const removeWorker = useCallback(
    (username) => {
      if (deleteWrkrIds.indexOf(username) === -1) {
        setDeleteWrkrIds([...deleteWrkrIds, username]);
      } else {
        const removedWorker = deleteWrkrIds.filter((item) => item !== username);
        setDeleteWrkrIds(removedWorker);
      }
    },
    [deleteWrkrIds],
  );

  const onSubmit = useCallback(
    async (values) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.updating_task', 'Updating Task')}
          isProgressLoader={true}
        />
      );
      notify({ message });
      const id = task.id;
      setSpinLoader(true);
      //sending consumed parts correctly as expected
      const consumedParts = values.consumedParts?.filter(
        (part) => part?.number && part?.name && part?.quantity,
      );
      const timing = values?.timing?.map((time) => {
        return {
          ...time,
          date: time.date ? toSiteISOString(time.date, timezone) : null,
          startTime: time?.startTime ? time?.startTime.toISOString() : null,
          endTime: time?.endTime ? time?.endTime.toISOString() : null,
        };
      });
      values.timing = timing?.length ? timing : null;
      values.consumedParts = consumedParts?.length ? consumedParts : null;
      values.completedByTech = [];
      workerState.map((filtered) => values.completedByTech.push(filtered.username));
      values.completedByTech = values.completedByTech.filter((x) => !deleteWrkrIds.includes(x));

      let updatedFilterWorkers = workerState.filter((o) => {
        return values.completedByTech.includes(o.username);
      });
      const status = await close({ id: id, task: values });
      techsValidation(values, updatedFilterWorkers);
      setDeleteWrkrIds([]);
      if (status) {
        setSpinLoader(false);
        setIsEditTaskPanel(false);
        methods.reset();
        const message = (
          <NotificationMessage
            message={t('task_notification.task_updation', 'Task Updated Successfully')}
          />
        );
        notify({ message });
      }
    },
    [close, deleteWrkrIds, methods, notify, t, task.id, timezone, workerState],
  );

  const onCloseTask = useCallback(
    async (values) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.closing_task', 'Closing Task')}
          isProgressLoader={true}
        />
      );
      notify({ message });
      const id = task?.id;
      setSpinLoader(true);
      values.status = 'completed';
      const consumedParts = values.consumedParts?.filter(
        (part) => part.number && part.name && part.quantity,
      );
      const timing = values?.timing?.map((time) => {
        return {
          ...time,
          date: time?.date ? toSiteISOString(time.date, timezone) : null,
          startTime: time?.startTime ? time.startTime.toISOString() : null,
          endTime: time?.endTime ? time.endTime.toISOString() : null,
        };
      });
      values.timing = timing?.length ? timing : null;
      values.consumedParts = consumedParts?.length ? consumedParts : null;
      values.completedByTech = [];
      workerState.map((filtered) => values.completedByTech.push(filtered.username));
      values.completedByTech = values.completedByTech.filter((x) => !deleteWrkrIds.includes(x));
      let updatedFilterWorkers = workerState.filter((o) => {
        return values.completedByTech.includes(o.username);
      });
      const { isSecondaryBundle, crewId } = crewForBundledTask;
      if (isSecondaryBundle && crewId) {
        values.crewIds = crewForBundledTask?.crewId;
      }
      const status = await close({ id: id, task: values });
      if (status) {
        closeSecondaryTask(null, null);
        setSpinLoader(false);
        setshouldRender(true);
        const message = (
          <NotificationMessage
            message={t('task_notification.task_close', 'Task Closed Successfully')}
          />
        );
        notify({ message });
      }
      techsValidation(values, updatedFilterWorkers);
      setDeleteWrkrIds([]);
      methods.reset();
    },
    [
      t,
      notify,
      task.id,
      workerState,
      crewForBundledTask,
      close,
      methods,
      timezone,
      deleteWrkrIds,
      closeSecondaryTask,
    ],
  );

  const onReopenTask = useCallback(
    async (values) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.reopening_task', 'Reopening Task')}
          isProgressLoader={true}
        />
      );
      notify({ message });
      const id = task?.id;
      setSpinLoader(true);
      const taskScheduleDate = task?.scheduleDate
        ? dayjs(task?.scheduleDate)?.format('YYYY-MM-DD')
        : null;
      const currentDate = dayjs().format('YYYY-MM-DD');
      if (
        taskScheduleDate < currentDate ||
        taskScheduleDate === null ||
        task.scheduleDate === null
      ) {
        values.status = 'unscheduled';
        values.completedByTech = null;
      } else {
        values.status = 'scheduled';
      }
      const status = await reopen({ id: id, task: values });
      if (status) {
        setSpinLoader(false);
        setshouldRender(false);
        const message = (
          <NotificationMessage
            message={t('task_notification.task_reopen', 'Task Reopened Successfully')}
          />
        );
        notify({ message });
      }
      methods.reset();
    },
    [t, notify, task.id, task.scheduleDate, reopen, methods],
  );

  const getTaskPanelControls = useCallback(() => {
    return (
      <AuthRender
        capability={Capability.FIELD_TASKS}
        description="Resolution panel controls"
        edit
        siteIds={[siteId]}
      >
        <ColumnTitle>
          <Button
            type="button"
            onClick={() => {
              setIsEditTaskPanel(true);
              setError(false);
              setSpinLoader(false);
              methods.reset(resetEditPanel);
            }}
          >
            <TaskIcon size={11} icon={Icons.PENCIL} />
            {t('form.edit_resolution', 'Edit Resolution')}
          </Button>
          {/* <Button type="button">
            <TaskIcon size={13} icon={Icons.TRASH} />
            {t('form.delete_resolution', 'Delete Resolution')}
          </Button> */}
        </ColumnTitle>
        <ColumnDetail></ColumnDetail>
      </AuthRender>
    );
  }, [methods, resetEditPanel, siteId, t]);

  const getSaveTaskButtons = useCallback(() => {
    return (
      <>
        <Button type="button" onClick={() => setIsEditTaskPanel(false)}>
          {t('general:cancel', 'Cancel')}
        </Button>
        <Footer>
          <SpinLoader showLoader={spinLoader} className="spinner" />
          <Button type="button" disabled={spinLoader} onClick={methods.handleSubmit(onSubmit)}>
            {t('general:save', 'Save')}
          </Button>
          <Button
            primary
            type="button"
            disabled={
              spinLoader ||
              (approvalFlag && task?.flag === TaskFlag.NOTAPPROVED && enabledApprovalFlag)
            }
            onClick={methods.handleSubmit(onCloseTask)}
          >
            {t('form.close_task', 'Close Task')}
          </Button>
        </Footer>
      </>
    );
  }, [t, spinLoader, methods, onSubmit, onCloseTask, task, approvalFlag, enabledApprovalFlag]);

  return (
    <TabDetailContainer>
      <TabDetailHeader entity={task} entityType={EntityType.TASK} />
      <TaskDetailWrapper>
        <FormProvider {...methods}>
          {!isEditTaskPanel && (
            <DisplayTaskContainer>
              <div className="task-panel-row">
                <ColumnTitle />
                <AuthRender
                  capability={Capability.FIELD_TASKS}
                  description="Reopen Task Button"
                  edit
                  siteIds={[siteId]}
                >
                  <ReopenButton className="column-detail buttons">
                    <SpinLoader showLoader={spinLoader} className="spinner" />
                    <ConditionalRender shouldRender={shouldRender}>
                      <Button
                        primary
                        type="button"
                        disabled={spinLoader || isDisabledReopenTask}
                        onClick={methods.handleSubmit(onReopenTask)}
                      >
                        {t('form.reopen_task', 'Reopen Task')}
                      </Button>
                    </ConditionalRender>
                  </ReopenButton>
                </AuthRender>
              </div>
              <CloseTaskTemplate
                taskPanelControls={getTaskPanelControls()}
                templateMode={TaskTemplateModes.VIEW}
                task={task}
                workerState={workerState}
                setWorkerState={setWorkerState}
                removeWorker={removeWorker}
                deleteWrkrIds={deleteWrkrIds}
                isLoadingMultiplePerson={isLoadingMultiplePerson}
              />
            </DisplayTaskContainer>
          )}
          {isEditTaskPanel && (
            <EditTaskContainer>
              {isError && <StyledError />}
              <div className="task-panel-row">
                <ColumnTitle />
                <ColumnDetail className="column-detail buttons">
                  {getSaveTaskButtons()}
                </ColumnDetail>
              </div>
              <CloseTaskTemplate
                templateMode={TaskTemplateModes.EDIT}
                task={task}
                workerState={workerState}
                setWorkerState={setWorkerState}
                removeWorker={removeWorker}
                deleteWrkrIds={deleteWrkrIds}
                isLoadingMultiplePerson={isLoadingMultiplePerson}
              />
              {isError && <StyledError />}
              <div className="task-panel-row">
                <ColumnTitle />
                <ColumnDetail className="column-detail buttons">
                  {getSaveTaskButtons()}
                </ColumnDetail>
              </div>
            </EditTaskContainer>
          )}
        </FormProvider>
      </TaskDetailWrapper>
    </TabDetailContainer>
  );
};

TaskResolution.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  workerData: PropTypes.instanceOf(Object),
  allCrews: PropTypes.instanceOf(Object),
};
