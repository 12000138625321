import { useStoreState } from 'easy-peasy';
import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icons } from '@ge/components/icon';
import { placements } from '@ge/components/menu';
import ActionsMenu, { MenuIcon, MenuHeader } from '@ge/shared/components/actions-menu';
import { CaseCloseDialog } from '@ge/shared/components/cases/case-close-dialog';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { ActionKeys, AnomalyTableMenuItems } from '@ge/shared/models/actions-menu-items';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';
import { killEventPropagation } from '@ge/shared/util';

const MAX_SELECTION_LIMIT = 100;

const ActionsMenuContainer = styled.div`
  margin-top: -33px;
`;

const ActionsMenuButton = styled.div`
  display: flex;
  border-radius: 2px;
  background-color: ${(props) => props.theme.table.actionsMenuButtonBackgroundColor};
  border: 1px solid ${(props) => props.theme.select.actionsMenuButtonBorderColor};
`;

const StyledMenuLabel = styled.span`
  padding: 2px 0px 0px 4px;
  color: ${(props) => props.theme.table.headerSortColor};
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
`;

const StyledMenuIcon = styled(MenuIcon).attrs(({ theme }) => ({
  icon: Icons.POINTER,
  color: theme?.table.actionsMenuButtonIconColor ?? 'transparent',
}))``;

const StyledMenuHeader = styled(MenuHeader)`
  color: ${(props) => props.theme.table.actionsMenuButtonColor};
  background-color: ${(props) => props.theme.table.rowHoverBackground};
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
`;

const StyledHeaderCheckbox = styled(Checkbox)`
  margin-top: 8px;
  justify-content: center;
  span {
    display: none;
  }
`;

const StyledCheckbox = styled.div`
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

export const useAnomalyTable = ({ anomalies = [] }) => {
  const { t } = useTranslation(['monitor.issues'], { useSuspense: false });
  const [checkedRows, setCheckedRows] = useState([]);
  const [showCaseCloseDialog, setShowCaseCloseDialog] = useState(false);

  const containerRef = useRef(null);

  const cases = useStoreState((store) => store.cases.cases);

  const getAuthorizedMenuItems = useActionsMenu({ menuItems: AnomalyTableMenuItems });

  const renderActionMenu = useMemo(
    () => () => {
      return (
        <ActionsMenuContainer>
          <ActionsMenu
            containerRef={containerRef}
            placement={placements.RIGHT_END}
            padding={'24px 0px 0px 0px'}
            disabled={!checkedRows.length}
            onAction={({ type }) => {
              if (type === ActionKeys.CASE_CLOSE) {
                setShowCaseCloseDialog(true);
              }
            }}
            actionMenuIcon={
              checkedRows.length > 0 ? (
                <ActionsMenuButton>
                  <StyledMenuLabel>{checkedRows.length}</StyledMenuLabel>
                  <StyledMenuIcon menuIconSmall />
                </ActionsMenuButton>
              ) : (
                <></>
              )
            }
            menuItems={getAuthorizedMenuItems(checkedRows?.map((id) => cases[id] ?? {}))}
          >
            <StyledMenuHeader>
              {t('table.cases_selected', { count: checkedRows.length })}
            </StyledMenuHeader>
          </ActionsMenu>
        </ActionsMenuContainer>
      );
    },
    [checkedRows, cases, getAuthorizedMenuItems, t],
  );

  const rows = useMemo(
    () =>
      anomalies.length > MAX_SELECTION_LIMIT ? anomalies.slice(0, MAX_SELECTION_LIMIT) : anomalies,
    [anomalies],
  );

  /**
   * Factory function to generate custom table cell header components in
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case AnomaliesColumns.SELECTED:
          return (
            <>
              {renderActionMenu()}
              <StyledHeaderCheckbox
                checkState={
                  checkedRows.length === rows.length ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                onChange={(checked) => {
                  setCheckedRows(checked ? rows.map((row) => row[AnomaliesColumns.ID]) : []);
                }}
                label={''}
              />
            </>
          );
        default:
          return null;
      }
    },
    [checkedRows.length, rows, renderActionMenu],
  );

  const renderCaseCloseDialog = useCallback(() => {
    return (
      <CaseCloseDialog
        isOpen={showCaseCloseDialog}
        caseIds={checkedRows}
        onClose={() => setShowCaseCloseDialog(false)}
        onConfirm={() => setShowCaseCloseDialog(false)}
      />
    );
  }, [showCaseCloseDialog, checkedRows]);

  const renderCheckbox = useCallback(
    (cellValue) => {
      return (
        <StyledCheckbox
          onClick={(e) => {
            killEventPropagation(e);
            e.preventDefault();

            if (checkedRows.includes(cellValue.id)) {
              setCheckedRows(checkedRows.filter((row) => row !== cellValue.id));
            } else if (checkedRows.length < rows.length) {
              setCheckedRows([...checkedRows, cellValue.id]);
            }
          }}
          className={
            checkedRows.length >= rows.length && !checkedRows.includes(cellValue.id)
              ? 'disabled'
              : ''
          }
        >
          <Checkbox
            checkState={
              checkedRows.includes(cellValue.id) ? CheckedState.CHECKED : CheckedState.UNCHECKED
            }
            label={''}
          />
        </StyledCheckbox>
      );
    },
    [checkedRows, rows],
  );

  useEffect(
    () =>
      setCheckedRows((prev) =>
        prev.filter((id) => rows.find((row) => row[AnomaliesColumns.ID] === id)),
      ),
    [rows],
  );

  return {
    checkedRows,
    renderCheckbox,
    customHeaderFn,
    renderCaseCloseDialog,
  };
};
