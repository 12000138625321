import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Table, Tbody, Td, Thead, Th, UnitCell } from '@ge/components/table';
import { useColumnState } from '@ge/components/table/use-column-state';
import { DataLoaderType, KpiCategoryDefs, SortDirection } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors/colors';
import { sorter } from '@ge/util/metric-sorter';

import { useFleetSitePerformanceTable } from '../../data-hooks';
import { formatKPIValue } from '../../util';

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .color-square-cell {
    padding-right: 0;
  }
`;

const ColorChip = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => (color ? color : globalColors.stone3)};
  border-radius: 1px;
`;

const AssetMetric = {
  NAME: 'name',
  STATE: 'metrics.state',
};

const SitePerformanceTable = ({ onSiteSelect, sites, colorMap, title }) => {
  const { t, i18n } = useTranslation(['analyze.dashboard'], { useSuspense: false });

  // data hooks
  const { data, isLoading, noData, refetch } = useFleetSitePerformanceTable({
    siteIds: sites?.map(({ id }) => id),
  });

  // Table state management
  const {
    sortDirection: tableSortDirection,
    sortMetric: tableSortMetric,
    updateSortMetric: updateTableSortMetric,
  } = useColumnState({
    defaultSortMetric: AssetMetric.STATE,
    defaultSortDirection: SortDirection.DESC,
  });

  const sortedDirection = useCallback(
    (metric) => (metric === tableSortMetric ? tableSortDirection : ''),
    [tableSortMetric, tableSortDirection],
  );

  const sortedData = useMemo(() => {
    return [...(data ?? [])].sort(sorter(tableSortMetric, tableSortDirection));
  }, [data, tableSortMetric, tableSortDirection]);

  const formatKpi = useCallback((value) => formatKPIValue(value, i18n.language), [i18n.language]);
  return (
    <TableContainer>
      <DataLoader
        isLoading={isLoading}
        noData={noData}
        onRetry={refetch?.length ? refetch : null}
        renderCondition
        type={DataLoaderType.TABLE}
      >
        {title && <h3>{title}</h3>}
        <Table scrollable compressed>
          <Thead transparent>
            <tr>
              <Th className="color-square-cell" />
              <Th
                labels={{
                  [t('region.table.region', 'Region')]: 'region.name',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('region.table.site', 'Site')]: 'name',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('performance_table_kpi.tba', 'TBA\nTechnical %')]: 'performance.tba',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('performance_table_kpi.pba', 'PBA\nTechnical %')]: 'performance.pba',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('performance_table_kpi.table.unproduced_energy', 'Unproduced Energy\nMWh')]:
                    'performance.unproducedEnergyContract',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('performance_table_kpi.table.actual_production', 'Actual Production\nMWh')]:
                    'performance.actualProduction',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('performance_table_kpi.capacity_factor', 'Capacity\nFactor %')]:
                    'performance.capacityFactor',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
              <Th
                labels={{
                  [t('performance_table_kpi.event_coverage', 'Event\nCoverage %')]:
                    'performance.eventCoverage',
                }}
                align="left"
                onClick={updateTableSortMetric}
                sortedDirection={sortedDirection}
              />
            </tr>
          </Thead>
          <Tbody transparent>
            {sortedData.map(({ id, name, performance, region }) => (
              <tr
                key={id}
                onClick={(e) => {
                  onSiteSelect(e, { id });
                }}
              >
                <Td className="color-square-cell" align="left">
                  <ColorChip color={colorMap[id]} />
                </Td>
                <Td align="left">{region?.name}</Td>
                <Td align="left">{name}</Td>
                <Td align="left">
                  <UnitCell value={performance?.[KpiCategoryDefs.TBA]} />
                </Td>
                <Td align="left">
                  <UnitCell value={performance?.[KpiCategoryDefs.PBA]} />
                </Td>
                <Td align="left">
                  <UnitCell
                    value={formatKpi(performance?.[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT])}
                  />
                </Td>
                <Td align="left">
                  <UnitCell value={formatKpi(performance?.[KpiCategoryDefs.ACTUAL_PRODUCTION])} />
                </Td>

                <Td align="left">
                  <UnitCell value={performance?.[KpiCategoryDefs.CAPACITY_FACTOR]} />
                </Td>
                <Td align="left">
                  <UnitCell value={performance?.[KpiCategoryDefs.EVENT_COVERAGE]} />
                </Td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </DataLoader>
    </TableContainer>
  );
};

SitePerformanceTable.propTypes = {
  sites: PropTypes.instanceOf(Object).isRequired,
  onSiteSelect: PropTypes.func,
  colorMap: PropTypes.instanceOf(Object),
  title: PropTypes.string,
};

SitePerformanceTable.defaultProps = {
  onSiteSelect: () => null,
  colorMap: {},
};

export default SitePerformanceTable;
