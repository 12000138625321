import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';

import { PersonOverview } from './panels/person-overview';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

export const PersonDetailsContact = ({ person, entityTab }) => {
  const { t } = useTranslation(['entity-details']);

  return (
    <StyledTabs small defaultTab={entityTab}>
      <Tab label={t('tabs.details', 'Details')}>
        <PersonOverview personDetail={person} />
      </Tab>
    </StyledTabs>
  );
};

PersonDetailsContact.propTypes = {
  person: PropTypes.object,
  entityTab: PropTypes.string,
};

PersonDetailsContact.defaultProps = {
  entityTab: null,
};
