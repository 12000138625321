import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useEffect, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { MenuPlacements, Select } from '@ge/components/select';
import { Text } from '@ge/components/typography';
import { useAllCrews } from '@ge/feat-manage/data-hooks/use-all-crews';
import { DateTimeFormats } from '@ge/models/constants';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { useEditTask } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDateObject, addUTCOffset, toSiteISOString } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';

import { PlanningContext } from '../../../../../features/manage/context/planning-provider';

const FooterButtons = styled.div`
  margin-left: auto;
  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
  .spinner {
    margin-left: -10px;
    margin-top: -12px;
    height: 45px;
  }
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
  margin-bottom: 5px;
`;

const StyledSelect = styled(Select)`
  .select__menu {
    .select__menu-list {
      max-height: 200px;
      &::-webkit-scrollbar {
        width: 4px;
        height: 0px;
      }
      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.scrollbar.trackBackground};
      }
      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollbar.thumbBackground};
        border-radius: 2.5px;
      }
    }
  }
`;

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 10px;
`;

const MainContainer = styled.div`
  width: 300px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DatePickerContainer = styled.div`
  .react-datepicker {
    .react-datepicker__header {
      .react-datepicker__current-month {
        display: none;
      }
    }
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
      border-bottom: solid 1px ${(props) => props.theme.manage.filterMenu.borderColor};
    }
    .react-datepicker__month-read-view,
    .react-datepicker__year-read-view {
      border-top: solid 1px ${(props) => props.theme.manage.filterMenu.borderColor};
      color: ${globalColors.white};
      border: 1px solid ${(props) => props.theme.select.primaryBorder};
      padding: 5px 28px 5px 8px;
      margin-top: 1px;
      margin-bottom: 12px;
      margin-right: 4px;
      background: ${(props) => props.theme.select.primaryBackground};
      display: flex;
      right: 0;
      visibility: visible !important;
      align-items: center;
      height: 10px;
      width: 60px;
    }
    .react-datepicker__month-dropdown {
      background-color: ${globalColors.slate2};
      border: 1px solid ${(props) => props.theme.select.secondaryBorder};
      color: ${globalColors.grey7};
      font-size: 11px;
      left: 5px;
      margin-top: 4px;
      font-family: 'Museo Sans';
      font-weight: 500;
      line-height: 13px;
    }
    .react-datepicker__year-dropdown {
      background-color: ${globalColors.slate2};
      border: 1px solid ${(props) => props.theme.select.secondaryBorder};
      color: ${globalColors.grey7};
      font-size: 11px;
      left: 45%;
      margin-top: 4px;
      font-family: 'Museo Sans';
      font-weight: 500;
      line-height: 13px;
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      margin-right: 20px;
      margin-top: 4px;
      height: 5px;
      width: 5px;
      border-color: white;
      border-width: 2px 2px 0 0;
    }
    .react-datepicker__navigation.react-datepicker__navigation--next {
      right: -8px;
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
      left: -8px;
    }
    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover {
      background: ${(props) => props.theme.select.primaryBackground};
    }
    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      background: none;
      color: ${globalColors.white};
    }
  }
`;

const DateTimeContainer = styled.div`
  display: flex;
  width: 230px;
  padding: 5px;
  .input-name {
    margin-left: 5px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.dialog.taskReschedule.labelColor};
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TimeContainer = styled.div`
  flex: 1;
`;

const AssigneeContainer = styled.div`
  width: 225px;
  margin-bottom: 5px;
  .input-name {
    margin-left: 5px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.dialog.taskReschedule.labelColor};
  }
`;

const ErrorsContainer = styled.div`
  width: 225px;
`;

const CalendarIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CALENDAR,
  color: props.theme.filterMenu.searchIcon,
}))`
  margin-left: -20px;
`;

export const SnoozeRescheduleDialog = ({
  tasks,
  allTasks,
  onSaveAll,
  isError,
  scheduleType,
  onClose,
  isLoading,
}) => {
  const { t } = useTranslation(['manage.cases-tasks', 'tasks'], { useSuspense: false });
  const { serviceGroupIds } = useContext(PlanningContext);

  const { handleSubmit, control, errors, trigger, setValue, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const { edit, isLoading: isLoadingSnooze } = useEditTask({
    onSuccess: () => {
      onClose();
    },
    onError: () => {},
  });

  const [selectedDate, setSelectedDate] = useState(null);
  // const [spinLoader, setSpinLoader] = useState(false);

  let datapickError = errors['scheduledate'];

  const [task] = tasks ?? [];

  const [selectedStartTime, setSelectedStartTime] = useState(
    task.startTime ? task.startTime : null,
  );

  const prefLang = useStoreState((state) => state.prefs.language);

  let defaultLanguage = 'en';

  if (prefLang === 'pt-BR') {
    defaultLanguage = 'pt';
  } else {
    defaultLanguage = 'en';
  }

  let [defaultDate, setDefaultDate] = useState(
    scheduleType != 'eligible'
      ? (task?.scheduleDateTz &&
          dayjs(task?.scheduleDateTz).locale('en').format(DateTimeFormats.DEFAULT_DATE)) ||
          ''
      : (task?.eligibleStartDateTz &&
          dayjs(task?.eligibleStartDateTz).locale('en').format(DateTimeFormats.DEFAULT_DATE)) ||
          '',
  );

  let [defaultDateSchedule, setDefaultDateSchedule] = useState(
    scheduleType != 'eligible'
      ? (task?.scheduleDateTz && task?.scheduleDateTz) || null
      : (task?.eligibleStartDateTz && task?.eligibleStartDateTz) || null,
  );

  const [scheduleTypeDate, setScheduleTypeDate] = useState(
    defaultDate ? new Date(defaultDate) : '',
  );

  const { data: allCrews } = useAllCrews();

  let crews = [];

  if (scheduleType != 'eligible' && allCrews) {
    crews = allCrews?.map((crew) => {
      return {
        value: crew?._id,
        label: crew?.name,
      };
    });
  }

  const updateDefaultValue = useCallback(
    (task) => {
      if (selectedDate === null) {
        const returnValue = task?.crewIds?.length ? task?.crewIds[0] : null;
        return returnValue;
      } else {
        return null;
      }
    },
    [selectedDate],
  );

  const getCrewList = useCallback(() => {
    let scheduleDate = selectedDate
      ? dayjs(selectedDate).format(DateTimeFormats.CREW_TIMING)
      : dayjs(defaultDateSchedule).format(DateTimeFormats.CREW_TIMING);

    let updateCrewList = allCrews
      ?.map(
        (crew) =>
          (selectedDate || defaultDateSchedule) &&
          scheduleDate &&
          dayjs(scheduleDate).isBetween(
            dayjs(crew.crewStartDate),
            dayjs(crew.crewEndDate),
            null,
            '[]',
          ) && {
            value: crew._id,
            label: crew.name,
          },
      )
      .filter((crew) => crew);
    return updateCrewList;
  }, [allCrews, defaultDateSchedule, selectedDate]);

  const updateCrewSelected = (value) => {
    setValue('assignee', value);
  };

  const updateCrewSelection = (date, value) => {
    const selectedAssignee = getValues('assignee') ? getValues('assignee') : null;
    let assignee = value;
    if (selectedAssignee !== null) assignee = selectedAssignee;
    if (assignee === null) {
      setValue('assignee', null);
      return;
    }

    let scheduleDate = date
      ? dayjs(date).format(DateTimeFormats.CREW_TIMING)
      : dayjs(defaultDateSchedule).format(DateTimeFormats.CREW_TIMING);
    let updateCrewList = allCrews?.filter(
      (crew) =>
        (date || defaultDateSchedule) &&
        scheduleDate &&
        dayjs(scheduleDate).isBetween(
          dayjs(crew.crewStartDate),
          dayjs(crew.crewEndDate),
          null,
          '[]',
        ),
    );
    const crewAvailable = updateCrewList.filter((c) => c._id == assignee);
    if (crewAvailable.length > 0) setValue('assignee', assignee);
    else setValue('assignee', null);
  };

  const handleSaveAll = useCallback(() => {
    if (scheduleType != 'eligible') {
      // setSpinLoader(true);
      if (allTasks && allTasks.length) {
        allTasks.map((task) => {
          const id = task.id;
          const dataToSubmit = {};
          let remoteData = selectedDate ? selectedDate : defaultDateSchedule;
          let time = selectedStartTime ? selectedStartTime : null;
          let selectedAssignee = getValues('assignee') ? getValues('assignee') : null;
          if (task?.isBundled === true) {
            dataToSubmit[id] = {
              isBundled: task?.isBundled,
              bundledTasks: task?.bundledTasks,
              scheduleDate: remoteData,
              startTime: time,
              assignee: selectedAssignee,
            };
          } else {
            dataToSubmit[id] = {
              scheduleDate: remoteData,
              startTime: time,
              assignee: selectedAssignee,
            };
          }
          setTimeout(() => onSaveAll(dataToSubmit), 500);
        });
      } else {
        const id = tasks[0]?.id;
        const dataToSubmit = {};
        let remoteData = selectedDate ? selectedDate : defaultDateSchedule;
        let time = selectedStartTime ? selectedStartTime : null;
        let selectedAssignee = getValues('assignee') ? getValues('assignee') : null;
        tasks.map((task) => {
          if (task?.isBundled === true) {
            dataToSubmit[id] = {
              isBundled: task?.isBundled,
              bundledTasks: task?.bundledTasks,
              scheduleDate: remoteData,
              startTime: time,
              assignee: selectedAssignee,
            };
          } else {
            dataToSubmit[id] = {
              scheduleDate: remoteData,
              startTime: time,
              assignee: selectedAssignee,
            };
          }
        });
        setTimeout(() => onSaveAll(dataToSubmit), 500);
      }
    } else {
      // setSpinLoader(true);
      if (allTasks && allTasks.length) {
        allTasks.map((task) => {
          const id = task.id;
          let remoteData = selectedDate
            ? toSiteISOString(selectedDate, task?.site.timezone)
            : defaultDateSchedule;
          const payload = { eligibleStartDate: remoteData };
          setTimeout(() => edit({ id, task: payload }), 500);
        });
      } else {
        const id = tasks[0]?.id;
        let remoteData = selectedDate
          ? toSiteISOString(selectedDate, task?.site.timezone)
          : defaultDateSchedule;
        const payload = { eligibleStartDate: remoteData };
        setTimeout(() => edit({ id, task: payload }), 500);
      }
    }
  }, [
    scheduleType,
    tasks,
    allTasks,
    selectedDate,
    defaultDateSchedule,
    selectedStartTime,
    getValues,
    onSaveAll,
    task?.site.timezone,
    edit,
  ]);

  // const setDiscardTask = () => {
  //   setSpinLoader(false);
  // };

  useEffect(() => {
    // trigger validation after initial render
    trigger();
  }, [trigger]);

  const [snoozeError, setSnoozeError] = useState(null);
  const [scheduleError, setScheduleError] = useState(null);
  const [startTimeError, setStartTimeError] = useState(null);

  useEffect(() => {
    if (
      selectedDate &&
      task?.scheduleDateTz &&
      dayjs(task.scheduleDateTz)
        .startOf('date')
        .isBefore(dayjs(selectedDate).startOf('date').subtract(1, 'day'))
    ) {
      setSnoozeError(true);
    } else {
      setSnoozeError(false);
    }
    if (
      selectedDate &&
      task?.eligibleStartDateTz &&
      dayjs(task.eligibleStartDateTz).startOf('date').isAfter(dayjs(selectedDate).startOf('date'))
    ) {
      setScheduleError(true);
    } else {
      setScheduleError(false);
    }
    if ((selectedDate || defaultDateSchedule) && !selectedStartTime) {
      setStartTimeError(true);
    } else {
      setStartTimeError(false);
    }
    // For bulk edit validation
    if (allTasks && allTasks.length) {
      allTasks.map((allTask) => {
        if (
          selectedDate &&
          allTask?.scheduleDateTz &&
          dayjs(allTask.scheduleDateTz)
            .startOf('date')
            .isBefore(dayjs(selectedDate).startOf('date').subtract(1, 'day'))
        ) {
          setSnoozeError(true);
        } else {
          setSnoozeError(false);
        }
        if (
          selectedDate &&
          allTask?.eligibleStartDateTz &&
          dayjs(allTask.eligibleStartDateTz)
            .startOf('date')
            .isAfter(dayjs(selectedDate).startOf('date'))
        ) {
          setScheduleError(true);
        } else {
          setScheduleError(false);
        }
        if ((selectedDate || defaultDateSchedule) && !selectedStartTime) {
          setStartTimeError(true);
        } else {
          setStartTimeError(false);
        }
      });
    }
  }, [
    selectedDate,
    task.eligibleStartDateTz,
    task.scheduleDateTz,
    selectedStartTime,
    defaultDateSchedule,
    allTasks,
  ]);

  const getCustomDateString = () => {
    const start = selectedDate
      ? dayjs(selectedDate).locale(defaultLanguage).format(DateTimeFormats.DEFAULT_DATE)
      : dayjs(defaultDate).locale(defaultLanguage).format(DateTimeFormats.DEFAULT_DATE);
    return start !== 'Invalid Date' ? `${start}` : '';
  };

  const getDefault = useCallback(
    (task) => {
      // if no scheduleDate and dueDate
      if (task.scheduleDate === undefined && task.dueDate === undefined) return null;
      const date = scheduleType != 'eligible' ? task.scheduleDate : task.eligibleStartDate || null;
      return date ? getDateObject(dayjs(date).tz(task.site?.timezone)) : null;
    },
    [scheduleType],
  );

  //   dialog footer
  const getFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <SpinLoader showLoader={isLoading || isLoadingSnooze} className="spinner" />
          <Button
            onClick={() => {
              setSelectedDate(null);
              setSelectedStartTime(task.startTime === null);
              setValue('scheduledate', null);
              setScheduleTypeDate(null);
              setDefaultDate(null);
              setDefaultDateSchedule(null);
              setValue('assignee', null);
            }}
          >
            {t('general:clear', 'Clear')}
          </Button>
          <Button
            primary
            disabled={
              scheduleType != 'eligible'
                ? scheduleError || isLoading || startTimeError
                : snoozeError || isLoading
            }
            onClick={handleSubmit(handleSaveAll)}
          >
            {t('general:apply', 'Apply')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [
    handleSaveAll,
    handleSubmit,
    isLoading,
    isLoadingSnooze,
    scheduleError,
    scheduleType,
    setValue,
    snoozeError,
    startTimeError,
    t,
    task.startTime,
  ]);

  return (
    <>
      <Dialog
        isOpen={true}
        onClose={onClose}
        header={
          scheduleType !== 'eligible'
            ? t('tasks:schedule_reschedule_task', 'Schedule / Reschedule Tasks')
            : t('eligible_start_date', 'Eligible start date')
        }
        footer={getFooter}
        contentWidth
        padContent={false}
      >
        <MainContainer onClick={(e) => killEventPropagation(e)}>
          {tasks.map((task, idx) => (
            <>
              <DatePickerContainer key={task.id + idx}>
                <Controller
                  control={control}
                  name="scheduledate"
                  defaultValue={getDefault(task)}
                  rules={{
                    validate: {
                      pastDate: (selectedDate) =>
                        selectedDate &&
                        dayjs(getDateObject(addUTCOffset(dayjs(), task.site?.timezone)))
                          .subtract(1, 'day')
                          .startOf('date')
                          .isBefore(dayjs(selectedDate).startOf('date')),
                    },
                  }}
                  render={({ onChange }) => (
                    <DatePicker
                      inline
                      placeholderText="Select date"
                      showWeekNumbers={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      calendarStartDay={1}
                      onChange={(date) => {
                        onChange();
                        setSelectedDate(date);
                        // Clear the taskCrew
                        updateCrewSelection(date, task?.crewIds?.length ? task?.crewIds[0] : null);
                      }}
                      selected={selectedDate ? selectedDate : scheduleTypeDate}
                      popperPlacement={idx !== 0 ? 'top-start' : 'bottom-start'}
                      filterDate={(date) =>
                        dayjs(getDateObject(addUTCOffset(dayjs(), task.site?.timezone)))
                          .startOf('date')
                          .toDate() <= date
                      }
                    />
                  )}
                />
              </DatePickerContainer>

              {/* SCHEDULE / UNSCHEDULE */}
              {scheduleType !== 'eligible' && (
                <DateTimeContainer>
                  <DateContainer>
                    <div className="input-name">{t('scheduled_date', 'Scheduled Date')}</div>
                    <DateWrapper>
                      <Input
                        value={getCustomDateString()}
                        disabled
                        style={{
                          width: '100px',
                          marginLeft: '5px',
                          fontWeight: '300',
                        }}
                      />
                      <CalendarIcon />
                    </DateWrapper>
                  </DateContainer>
                  <TimeContainer
                    onClick={(e) => killEventPropagation(e)}
                    onKeyDown={(e) => killEventPropagation(e)}
                  >
                    <div className="input-name">{t('start_time', 'Start Time')}</div>
                    <Input
                      onChange={(e) => {
                        setSelectedStartTime(e.target.value);
                      }}
                      type="time"
                      style={{
                        width: '100px',
                        marginLeft: '5px',
                        fontWeight: '300',
                      }}
                      value={selectedStartTime}
                      error={startTimeError}
                    />
                  </TimeContainer>
                </DateTimeContainer>
              )}

              {/* SNOOZE */}
              {scheduleType == 'eligible' && (
                <DateTimeContainer>
                  <DateContainer>
                    <DateWrapper>
                      <Input
                        value={getCustomDateString()}
                        disabled
                        style={{
                          width: '220px',
                          fontWeight: '300',
                        }}
                      />
                      <CalendarIcon />
                    </DateWrapper>
                  </DateContainer>
                </DateTimeContainer>
              )}

              {/* Error Messages */}
              <ErrorsContainer>
                {scheduleType != 'eligible' &&
                datapickError &&
                datapickError['type'] === 'pastDate' ? (
                  <ErrorText>
                    {t('error_past_date', 'Scheduled date cannot be in the past')}
                  </ErrorText>
                ) : null}
                {scheduleType === 'eligible' && snoozeError && (
                  <ErrorText>
                    {t(
                      'error_after_schedule_date_snooze',
                      'Eligible start date cannot be after scheduled date',
                    )}
                  </ErrorText>
                )}
                {scheduleType != 'eligible' && scheduleError && (
                  <ErrorText>
                    {t(
                      'error_before_eligible_start',
                      'Scheduled Date cannot be prior to Eligible Start Date',
                    )}
                  </ErrorText>
                )}
                {scheduleType != 'eligible' && startTimeError && (
                  <ErrorText>{t('start_time_required', 'Start time required')}</ErrorText>
                )}
                {isError && <StyledError />}
              </ErrorsContainer>

              {/* assignee dropdown */}
              {scheduleType !== 'eligible' && (
                <AssigneeContainer>
                  <div className="input-name">{t('tasks:form.assignee', 'Assignee')}</div>
                  <Controller
                    control={control}
                    defaultValue={updateDefaultValue(task)}
                    name="assignee"
                    render={({ onChange, value }) => (
                      <StyledSelect
                        defaultValue={value}
                        placeholder={t('tasks:form.unassigned', 'Unassigned')}
                        onChange={(val) => {
                          onChange(val.value);
                          updateCrewSelected(val.value);
                        }}
                        options={getCrewList()}
                        minWidth={120}
                        value={crews.find((val) => val.value === value)}
                        disabled={serviceGroupIds.length > 1 ? true : false}
                        menuPlacement={MenuPlacements.TOP}
                      />
                    )}
                  />
                </AssigneeContainer>
              )}
            </>
          ))}
        </MainContainer>
      </Dialog>
    </>
  );
};

SnoozeRescheduleDialog.propTypes = {
  tasks: PropTypes.instanceOf(Array).isRequired,
  allTasks: PropTypes.instanceOf(Array).isRequired,
  scheduleType: PropTypes.string,
  onSaveAll: PropTypes.func,
  onClose: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SnoozeRescheduleDialog.defaultProps = {
  tasks: [],
  allTasks: [],
  scheduleType: null,
  onSaveAll: () => null,
  onClose: () => null,
  isError: false,
  isLoading: false,
};
