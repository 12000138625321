import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { EntityType } from '@ge/models/constants';

// can revisit the logic in here but ultimately we are parsing generic entity info into specific params to check for permissions
// TODO: look into refactoring so this can be baked into an existing auth component or hook

export const useAuthEntityScope = ({ entityId, entityType }) => {
  // store
  const assets = useStoreState((state) => state.assets.assets);
  const sitesByRegion = useStoreState((state) => state.sites.sitesByRegion);

  return useMemo(() => {
    let enforceView = false;
    let siteIds = undefined;
    let siteLevel = true;

    switch (entityType) {
      // TODO: add support for add'l entity types if needed
      case EntityType.ASSET:
      case EntityType.TURBINE: {
        const asset = assets[entityId];
        const siteId = asset?.site?.id;

        if (siteId) {
          siteIds = [siteId];
        }

        break;
      }
      case EntityType.SITE: {
        siteIds = [entityId];

        break;
      }
      case EntityType.REGION:
        // can revisit this but we will be filtering regions by sites so probably want to enforce view
        enforceView = true;
        siteIds = (sitesByRegion.get(entityId) ?? []).map(({ id }) => id);
        siteLevel = true;

        break;
      case EntityType.FLEET:
        // fleet includes all sites in current view so enforce
        enforceView = true;
        siteLevel = false;

        break;
    }

    return { enforceView, siteIds, siteLevel };
  }, [assets, entityId, entityType, sitesByRegion]);
};
