import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from '@ge/components/input';
import { StatusColor } from '@ge/tokens/colors/colors';
const TimepickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
`;

const StyledInput = styled(Input)`
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
  &:disabled {
    cursor: not-allowed;
  }
`;

// TimeStrMetaField will take time as input in HH:MM format and returns time in the same format
export const TimeStrMetaField = ({ defaultValue, label, metadata, name, rules }) => {
  const { readOnly, required } = metadata;
  const { control, errors } = useFormContext();
  if (required) rules = { ...rules, required };
  return (
    <TimepickerWrapper>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        render={({ onChange, value }) => (
          <StyledInput
            disabled={readOnly}
            errors={errors}
            id={name}
            label={label}
            onChange={onChange}
            required={required}
            type="time"
            value={value}
          />
        )}
      />
    </TimepickerWrapper>
  );
};

TimeStrMetaField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  metadata: PropTypes.instanceOf(Object).isRequired,
  rules: PropTypes.instanceOf(Object),
};

TimeStrMetaField.defaultProps = {
  defaultValue: undefined,
  label: '',
  rules: null,
};
