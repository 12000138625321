const ONE_THIRD = '331px';
const TWO_THRID = '677px';
const FULL_WIDTH = '1024px';

// These are not necessarily correct. Currently pulling these values from the visualizationType values seen in the service reponse.
// Thinking about these against the various widgets... I am not sure these have enough granulatrity.
// For now using them to keep moving but will need to revisit this.
// Also need to get the service to send keb-case values...
export const WidgetTypes = {
  AVAILABILITY: 'Availability',
  BAR: 'Bar',
  LINE: 'Line',
  LIST: 'List',
  ROSE: 'Rose',
  TEXT: 'Text',
  TABLE: 'Table',
  DONUT: 'Donut',
};

export const ColumnSizes = {
  ONE_THIRD: 'one-third',
  TWO_THIRD: 'two-third',
  ONE_HALF: 'one-half',
  FULL_WIDTH: 'full-width',
};

const WidgetColumnWidths = {
  [ColumnSizes.ONE_THIRD]: ONE_THIRD,
  [ColumnSizes.TWO_THIRD]: TWO_THRID,
  [ColumnSizes.FULL_WIDTH]: FULL_WIDTH,
};

export const WidgetHeights = {
  SMALL: 250,
  LARGE: 400,
};

export const WidgetNames = {
  ASSETS_OF_CONCERN: 'Assets of Concern',
  ASSET_STATUS: 'Asset Status',
  ATTACHMENTS: 'Attachments',
  AVAILABILITY: 'Availability',
  AVERAGE_WIND_SPEED: 'Wind Speed',
  COMPLETED_WORK: 'Completed Work',
  CONTRACTUAL_AVAILABILITY: 'Contractual Availability',
  MANUALLY_ADJUSTED_EVENTS: 'Manually Adjusted Events',
  NOTES: 'Notes',
  NOTES_WIDE: 'Notes ', // NB: bit of a hack here to work around naming uniqueness assumption
  PERFORMANCE_SUMMARY: 'Performance Summary',
  PLANNED_WORK: 'Planned Work',
  PRODUCTION: 'Production',
  PRODUCTION_GRAPH: 'Production (Graph)',
  PRODUCTION_TABLE: 'Production (Table)',
  SITE_ROSTER: 'Site Roster',
  WIND_DIRECTION_SPEED: 'Wind Direction and Speed',
  OPEN_CASES: 'Open Cases',
  CLOSED_CASES: 'Closed Cases',
};

// Define all available widgets, by name
// Switched from defining by visualizationType to name based requriremnets shifting
export const WidgetDefs = {
  [WidgetNames.ASSETS_OF_CONCERN]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.LIST,
  },
  [WidgetNames.ASSET_STATUS]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.ONE_THIRD],
    type: WidgetTypes.LIST,
  },
  [WidgetNames.AVAILABILITY]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.TWO_THIRD],
    type: WidgetTypes.LIST,
  },
  [WidgetNames.AVERAGE_WIND_SPEED]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.ONE_THIRD],
    type: WidgetTypes.LINE,
  },
  [WidgetNames.COMPLETED_WORK]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.CONTRACTUAL_AVAILABILITY]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.MANUALLY_ADJUSTED_EVENTS]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.NOTES]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.ONE_THIRD],
    type: WidgetTypes.TEXT,
  },
  [WidgetNames.NOTES_WIDE]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TEXT,
  },
  [WidgetNames.PERFORMANCE_SUMMARY]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.ONE_THIRD],
    type: WidgetTypes.LIST,
  },
  [WidgetNames.PLANNED_WORK]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.PRODUCTION_GRAPH]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.TWO_THIRD],
    type: WidgetTypes.BAR,
  },
  [WidgetNames.PRODUCTION_TABLE]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.SITE_ROSTER]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.WIND_DIRECTION_SPEED]: {
    height: WidgetHeights.SMALL,
    width: WidgetColumnWidths[ColumnSizes.ONE_THIRD],
    type: WidgetTypes.LINE,
  },
  [WidgetNames.OPEN_CASES]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
  [WidgetNames.CLOSED_CASES]: {
    height: WidgetHeights.LARGE,
    width: WidgetColumnWidths[ColumnSizes.FULL_WIDTH],
    type: WidgetTypes.TABLE,
  },
};
