import * as PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';

const StyledCheckboxGroup = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  margin: ${({ margin }) => margin};
  align-items: center;
`;

export const CheckBoxGroupMetaField = ({
  className,
  name,
  defaultValue,
  choices,
  required,
  margin,
}) => {
  const { control } = useFormContext();

  const handleChange = (checked, value, selected) => {
    const values = new Set(selected);
    if (checked) {
      values.add(value);
    } else {
      values.delete(value);
    }
    return values.size > 0 ? Array.from(values) : null;
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required,
      }}
      render={({ ref, value: selectedValue, onChange, onBlur }) => (
        <StyledCheckboxGroup>
          {choices.map((choice) => (
            <StyledCheckBox
              ref={ref}
              className={className}
              name={name}
              key={choice.id}
              id={choice.id}
              label={choice.label}
              onChange={(checked) => onChange(handleChange(checked, choice.value, selectedValue))}
              onBlur={onBlur}
              checkState={
                selectedValue?.includes(choice.value)
                  ? CheckedState.CHECKED
                  : CheckedState.UNCHECKED
              }
              disabled={choice.disabled}
              margin={margin}
            />
          ))}
        </StyledCheckboxGroup>
      )}
    />
  );
};

CheckBoxGroupMetaField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.array,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      disabled: PropTypes.bool,
    }),
  ),
  required: PropTypes.bool,
  margin: PropTypes.string,
};

CheckBoxGroupMetaField.defaultProps = {
  name: `choices`,
  choices: [],
  required: false,
};
