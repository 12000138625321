import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { EntityType, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { roundNumber } from '@ge/util';

import { useGlobalFilters } from '../hooks';
import {
  fetchSiteIecCategory,
  fetchEntityIecCategoryAssets,
  fetchIecCategoryMetaData,
} from '../services';

/**
 * Use site IEC data.
 *
 * @param siteId the site id
 * @param category the iec category
 * @param startDate the start date
 * @param endDate the end date
 */
const useSiteIecData = ({ isActive = true, siteId, category, startDate, endDate }) => {
  const [selectedIecCategoryData, setSelectedIecCategoryData] = useState({});

  const _filters = useGlobalFilters();
  const filters = { ..._filters, siteIds: [siteId] };

  const queryKey = {
    siteId,
    category,
    filters,
    startDate,
    endDate,
  };

  const {
    data: _queryData,
    error,
    isLoading: isIecDataLoading,
    ...queryRest
  } = useQuery(
    [QueryKey.SITE_IEC_DATA, queryKey],
    () =>
      fetchSiteIecCategory({
        siteId,
        categories: [category],
        // can revisit but seems odd that we could get a filter that doesn't include the current site
        // or that we're potentially passing in a bigger list of sites when we only need one
        filters,
        startDate,
        endDate,
      }),
    {
      enabled: isActive && Boolean(siteId && category && startDate && endDate),
      ...Config.EXECUTE_ONCE,
    },
  );

  const { data: _queryMetaData, isLoading: isMetaDataLoading } = useQuery(
    [queryKey, 'metaData'],
    () =>
      fetchIecCategoryMetaData({
        entityId: siteId,
        category,
        startDate,
        endDate,
        entityType: EntityType.SITE,
        filters,
      }),
    {
      enabled: !!_queryData,
      ...Config.EXECUTE_ONCE,
    },
  );

  const { totalTurbines, iecCategory: iecState } = selectedIecCategoryData;

  const { data: _assetData, isLoading: isIecAssetDataLoading } = useQuery(
    [QueryKey.SITE_IEC_ASSETS, queryKey, iecState, filters],
    () =>
      fetchEntityIecCategoryAssets({
        entityId: siteId,
        entityType: EntityType.SITE,
        category,
        startDate,
        endDate,
        pageSize: totalTurbines,
        pageIndex: 0,
        iecState,
        filters,
      }),
    {
      enabled: Boolean(siteId && category && iecState && totalTurbines),
      ...Config.EXECUTE_ONCE,
    },
  );

  const dataMapped = useMemo(() => {
    const categoryData = _queryData?.data[category];

    const data = categoryData?.data.reduce(
      (acc, data) => {
        const metaData =
          _assetData?.data[data.iecCategory] || _queryMetaData?.data[data.iecCategory];

        const iecData = metaData
          ? {
              ...data,
              metadata: {
                ...metaData,
              },
            }
          : data;

        return {
          iec: [iecData, ...acc.iec],
          // eslint-disable-next-line no-unsafe-optional-chaining
          oem: [...data?.details, ...acc.oem],
        };
      },
      {
        iec: [],
        oem: [],
      },
    );

    const updatedIecData = data?.iec?.map((detail) => {
      if (categoryData.total > 0) {
        detail.value = roundNumber((detail.kpiValue / categoryData.total) * 100);
      }
      return detail;
    });

    // Should move this logic to the BFF at somepoint.
    const updatedOemData = data?.oem?.map((detail) => {
      if (categoryData.total > 0) {
        detail.value = roundNumber((detail.kpiValue / categoryData.total) * 100);
      }
      return detail;
    });

    return {
      ...categoryData,
      data: !data
        ? null
        : {
            ...data,
            iec: updatedIecData,
            oem: updatedOemData,
          },
    };
  }, [_queryData?.data, category, _assetData?.data, _queryMetaData?.data]);

  return {
    data: dataMapped,
    error,
    setSelectedIecCategoryData,
    isLoading: isIecDataLoading,
    isIecAssetDataLoading,
    isMetaDataLoading,
    ...queryRest,
  };
};

export default useSiteIecData;
