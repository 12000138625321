import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { EntityType } from '@ge/models/constants';
import { getFriendlyTimestamp } from '@ge/shared/util';

import { FormSection } from '../../task-template-shared';

const ParentEntityIcon = styled(Icon).attrs(({ icon, theme }) => ({
  color: theme?.newTaskDialog?.headerSubtitleColor,
  icon,
  size: 12,
}))``;

// TODO: pull entity details shared components out of app so can be shared here
export const EntityIcon = styled(Icon).attrs(({ theme }) => ({
  size: 20,
  color: theme?.newTaskDialog?.headerIconColor,
}))``;

const Container = styled.div`
  margin-bottom: 12px;

  .components {
    text-transform: capitalize;
  }

  .entity-title {
    align-items: start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .icons {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      > * {
        + * {
          margin-left: 4px;
        }
      }
    }

    .timestamp {
      color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
      margin-left: auto;
      text-align: right;
    }

    .title {
      .name {
        color: ${({ theme }) => theme?.newTaskDialog?.headerTitleColor};
        font-size: 14px;
        font-weight: 500;
        padding: 3px 0 8px;
      }
    }

    > * {
      + * {
        margin-left: 4px;
      }
    }
  }

  .parent-details {
    .name {
      margin-left: 4px;
    }

    .entity-name {
      &:first-of-type {
        margin-right: 4px;
      }

      &:last-of-type {
        margin-left: 4px;
      }
    }
  }

  .subtitle {
    color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
    font-size: 12px;
    font-weight: 300;
  }

  > * {
    + * {
      margin-top: 8px;
    }
  }
`;

const ParentEntityContainer = ({ name, type }) => {
  // TODO: add support for more types in here as needed
  const icon = {
    [EntityType.SITE]: Icons.SITE,
    [EntityType.TURBINE]: Icons.TURBINE,
  }[type];

  return (
    <span className="entity-name">
      <ParentEntityIcon icon={icon} />
      <span className="name subtitle">{name}</span>
    </span>
  );
};

ParentEntityContainer.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(EntityType)).isRequired,
};

export const EntityHeaderSection = ({ entity, entityType }) => {
  let detail;
  // if we need to allow providing a specific time can get in props and pass in here
  const timestamp = getFriendlyTimestamp();
  const [time, date] = timestamp.split(', ');

  // TODO: add support for readonly views for other entity types as needed
  switch (entityType) {
    case EntityType.CASE: {
      const { asset = {}, componentIds = [], site = {} } = entity;

      detail = (
        <>
          <div className="parent-details">
            <ParentEntityContainer name={site.name} type={EntityType.SITE} />{' '}
            <span className="subtitle">/</span>{' '}
            <ParentEntityContainer name={asset.name} type={EntityType.TURBINE} />
          </div>
          <div className="entity-title">
            <div className="icons">
              <EntityIcon icon={Icons.ANOMALY} />
              <Severity level={entity.priority} size={12} viewBox="0 0 20 15" />
            </div>
            <div className="title">
              <div className="name">{entity.description}</div>
              <div className="components subtitle">{componentIds?.join(' / ')}</div>
            </div>
            <div className="timestamp">
              <div>{time}</div>
              <div>{date}</div>
            </div>
          </div>
        </>
      );

      break;
    }

    default:
      detail = null;
  }

  if (!detail) {
    return null;
  }

  return (
    <FormSection>
      <Container>{detail}</Container>
    </FormSection>
  );
};

EntityHeaderSection.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.oneOf(Object.values(EntityType)).isRequired,
};
