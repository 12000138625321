import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ExportGraphModal } from '@ge/feat-analyze/components/modals';
import { useExportGraph } from '@ge/feat-analyze/data-hooks/use-export-graph';
import useSitesFromSiteKpi from '@ge/feat-analyze/hooks/use-sites-from-site-kpi.js';
import { ChartNames } from '@ge/feat-analyze/models';
import { PresentationTypes, EntityType, EntityTab, AlertsEntityType } from '@ge/models/constants';
import { SiteListTable } from '@ge/shared/components/tables/site-list-table';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useFilterDefs } from '@ge/shared/hooks';
import { AllSitesColumnDefs } from '@ge/shared/models/table-col-defs';
import { killEventPropagation } from '@ge/shared/util/general';

import useAllSitesKpi from '../../data-hooks/use-all-sites-kpi';
import useAllSitesTable from '../../data-hooks/use-all-sites-table';
import useIecDataGraph from '../../data-hooks/use-iec-data-graph';
import useTimeAggregation from '../../data-hooks/use-time-aggregation';
import { getTotalReportingAssets } from '../../util';
import { DashboardIECLossCategories } from '../dashboard/dashboard-iec-loss-categories';
import { DashboardKpiChart } from '../dashboard/dashboard-kpi-chart';
import { SitesGrid } from '../region/sites-grid';
import SiteTableTools from '../tables/table-tools';

// constants
const NAMESPACE = 'analyze.dashboard';
const SITE_FILTER_STATE_ID = `${NAMESPACE}.region`;

const DashboardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .header {
    border-bottom: solid 2px ${(props) => props.theme.analyze.header.borderBottomColor};
    margin-bottom: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .tools {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      margin-left: auto;
      > * {
        + * {
          margin-left: 16px;
        }
      }
    }
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  > * {
    flex: 1 1;
  }
`;

// TODO: unhardcode this
//const regionId = 'reg_nam';

export const SitesWind = forwardRef((props, ref) => {
  const { dateRange, onExportEnabled } = props;
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const { showSiteDetails } = useContext(EntityDetailsContext);
  const [isActive, setActiveState] = useState(PresentationTypes.LIST);
  const { search } = useLocation();
  const { trendGraphSelection } = useStoreState(({ analyze }) => analyze);

  const [currentSearch, setCurrentSearch] = useState({});

  const timeAggrProps = useTimeAggregation({
    entityType: EntityType.REGION,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const { timeAggregation, timeAggrOptions } = timeAggrProps;

  const timeAggr = useMemo(
    () => timeAggrOptions?.find((item) => item.value === timeAggregation)?.value,
    [timeAggregation, timeAggrOptions],
  );

  const {
    data: regionIecLoss,
    isLoading: isIecLoading,
    error: iecCategoryError,
    isIecAssetDataLoading,
    currentIecCategory,
    handleIecCategoryChange,
    handleSeeTop100,
  } = useIecDataGraph({
    dateRange: trendGraphSelection ? trendGraphSelection : dateRange,
  });

  const {
    data: siteKpiData,
    isLoading: isSiteKpiLoading,
    handleSortChange,
    handleFilterBarChange,
    graph1,
    graph1QueryState,
    kpiChartState,
  } = useAllSitesKpi({ dateRange, timeAggr });

  const {
    visibleCols: visibleSitesCols,
    sortDirection: sitesSortDirection,
    sortMetric: sitesSortMetric,
    updateSortMetric: updateSitesSortMetric,
    handleSiteSelect,
    handleSiteColumnChanges,
    onSelectionVisibilityChange,
  } = useAllSitesTable({ siteKpiData });

  const {
    filterSequence,
    filterDefs,
    onChange: setFilterDefs,
    onReset: onReset,
  } = useFilterDefs({
    columnDefs: AllSitesColumnDefs,
    stateId: SITE_FILTER_STATE_ID,
  });

  const { data: kpiTableData } = useAllSitesKpi({
    dateRange,
    timeAggr: timeAggr && timeAggrOptions[0]?.value,
    entityAggr: AlertsEntityType.SITE,
  });

  const {
    isLoading: isRegionSitesLoading,
    data: sites,
    filterValues,
  } = useSitesFromSiteKpi({
    allSitesData: kpiTableData,
    tableSortDirection: sitesSortDirection,
    tableSortMetric: sitesSortMetric,
    search: currentSearch,
    filters: filterDefs,
    filterSequence,
  });

  // Process URL params for setting initial view type.
  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');
    if (view) {
      setActiveState(view);
    } else {
      setActiveState(PresentationTypes.LIST);
    }
  }, [isActive, search]);

  const handleSelectSiteTitle = useCallback(
    (e, id) => {
      killEventPropagation(e);
      if (id) {
        showSiteDetails(id, EntityTab.PRODUCTION);
      }
    },
    [showSiteDetails],
  );

  const {
    enabled: enabledExport,
    openModal,
    modalProps,
    setCurrentType,
    setChartRefs,
  } = useExportGraph({
    isLoading: isIecLoading || isSiteKpiLoading,
    kpiChartState,
    timeAggregation,
    currentIecCategory,
    entityType: EntityType.SITES,
    entity: { name: t('all_sites', 'All Sites') },
    dateRange,
  });

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  useEffect(() => {
    onExportEnabled(enabledExport);
  }, [enabledExport, onExportEnabled]);

  const translations = {
    sites: t('sites', 'Sites'),
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <>
      <DashboardContainer>
        <ChartWrapper>
          <DashboardKpiChart
            chartDef={graph1}
            data={siteKpiData}
            error={graph1QueryState.error}
            entityType={EntityType.SITES}
            namespace={NAMESPACE}
            isLoading={graph1QueryState.isLoading}
            onRetry={graph1QueryState.refetch ?? null}
            onChange={handleSortChange}
            handleFilterBarChange={handleFilterBarChange}
            inlineTitle={false}
            height={384}
            xAxisTitle={translations.sites}
            staticChartTitle={t('kpi_chart.site_trends', 'Site Trends')}
            onCurrentTypeChange={setCurrentType}
            ref={(ref) => setChartRefs(ref, ChartNames.TRENDS)}
            {...timeAggrProps}
          />
          <DashboardIECLossCategories
            title={t('tilc.title', 'Top IEC Loss Categories')}
            data={regionIecLoss}
            isLoading={isIecLoading}
            error={iecCategoryError}
            iecCategory={currentIecCategory}
            onSeeAll={handleSeeTop100}
            isTurbineDataLoading={isIecAssetDataLoading}
            onCategoryChange={handleIecCategoryChange}
            namespace={NAMESPACE}
            entityType={EntityType.FLEET}
            totalReportingAssets={getTotalReportingAssets(siteKpiData)}
            ref={(ref) => setChartRefs(ref, ChartNames.PARETO)}
          />
        </ChartWrapper>
        <div className="header">
          <h3>{t('sites', 'Sites')}</h3>
          <SiteTableTools
            isLoading={isSiteKpiLoading}
            data={sites}
            columnState={visibleSitesCols}
            entityType={EntityType.SITES}
            dateRange={dateRange}
            onMenuClose={handleSiteColumnChanges}
            onClumnSelection={onSelectionVisibilityChange}
            filters={filterDefs}
            onReset={onReset}
          >
            {/* <DashboardGaugeConfiguration
                entityType={EntityType.SITE}
                onApply={(value) => savePrefs(value, GAUGES_FEAT_PREFS_SUB_KEY)}
                values={featPrefs?.[GAUGES_FEAT_PREFS_SUB_KEY]}
              /> */}
          </SiteTableTools>
        </div>

        {isActive === PresentationTypes.GRID && (
          <SitesGrid
            sites={sites}
            onSelectSite={handleSiteSelect}
            onSelectSiteTitle={handleSelectSiteTitle}
          />
        )}
        {isActive === PresentationTypes.LIST && (
          <SiteListTable
            sites={sites}
            columns={visibleSitesCols}
            sortMetric={sitesSortMetric}
            sortDirection={sitesSortDirection}
            sortAction={updateSitesSortMetric}
            onSiteSelect={handleSiteSelect}
            isLoading={isRegionSitesLoading}
            onFilter={setFilterDefs}
            filterDefs={filterDefs}
            onFilterChange={handleFilterChange}
            filterValues={filterValues}
          />
        )}
      </DashboardContainer>
      <ExportGraphModal {...modalProps} enablePng />
    </>
  );
});

SitesWind.propTypes = {
  dateRange: PropTypes.instanceOf(Object),
  onExportEnabled: PropTypes.func,
};

SitesWind.defaultProps = {
  dateRange: {},
  onExportEnabled: () => {},
};

SitesWind.displayName = 'SitesWind';
