import { makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { PropTypes } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  // removes spaces between notifications
  // collapseWrapperDense: {
  //   'margin-bottom': 0,
  //   'margin-top': 0,
  // },
  // need this so notification fills the container
  collapseWrapperInner: {
    width: '100% !important',
  },
  // look into why containerAnchorOriginBottomCenter wasn't applying
  // this won't be an issue unless we want to override default positioning somewhere
  containerRoot: {
    bottom: '5% !important',
    left: '5% !important',
    right: '5% !important',
    transform: 'none',
    alignItems: 'stretch',
  },
}));

// can expose this and make configurable as needed
export const Config = {
  COMPACT: true,
  DUPLICATES: false,
  ICONS: false,
  MAX_NOTIFICATIONS: 3,
  POSITION: {
    horizontal: 'center',
    vertical: 'bottom',
  },
  VISIBLE_DURATION: 10000,
};

export const NotificationProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      anchorOrigin={Config.POSITION}
      autoHideDuration={Config.VISIBLE_DURATION}
      classes={classes}
      dense={Config.COMPACT}
      hideIconVariant={!Config.ICONS}
      maxSnack={Config.MAX_NOTIFICATIONS}
      preventDuplicate={!Config.DUPLICATES}
    >
      {children}
    </SnackbarProvider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
