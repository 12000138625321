import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';

import { Modal } from './modal';

const AlertContent = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  border-top: 4px solid ${(props) => props.theme.modal.alertBorderTop};
  border-radius: 6px;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.2);
  margin: 90px auto;
  max-width: 380px;

  .content {
    color: ${(props) => props.theme.layout.textColor};
    padding: 40px 30px 30px 30px;
  }

  .button-footer {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 30px;
    width: 140px;
  }
`;

const AlertComponent = ({ cancelText, confirmText, isOpen, onClose, onConfirm, children }) => {
  const confirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <AlertContent>
        <div className="content kpi-1">{children}</div>
        <div className="button-footer">
          <Button onClick={onClose} type="button">
            {cancelText}
          </Button>
          <Button primary onClick={confirm} type="button">
            {confirmText}
          </Button>
        </div>
      </AlertContent>
    </Modal>
  );
};

AlertComponent.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
};

AlertComponent.defaultProps = {
  cancelText: 'cancel',
  confirmText: 'OK',
  isOpen: false,
  children: null,
  onConfirm: () => null,
};

export const Alert = withTheme(AlertComponent);
