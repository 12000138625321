import { getLoadable } from '@ge/shared/util';

// Define the available pages for lazy loading.
export const loadables = {
  roles: getLoadable(() => import('./roles')),
  people: getLoadable(() => import('./people')),
  configure: getLoadable(() => import('./configure')),
  distributionlists: getLoadable(() => import('../components/distribution-lists')),
  handlingprocedures: getLoadable(() => import('../components/handling-procedures')),
  eventmanagement: getLoadable(() => import('../components/event-management')),
};

// @index('./**/*.jsx', f => `export * from '${f.path}';`)
export * from './roles';
export * from './people';
export * from './configure';
export * from '../components/distribution-lists';
export * from '../components/handling-procedures';
export * from '../components/event-management';
// @endindex
