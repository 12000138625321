import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader';
import { AssetCommand } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';
import { toLocaleKey } from '@ge/util/string-utils';

import { CommandsDialog } from './command-dialog';

const ControlButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  box-sizing: content-box;
  color: ${(props) => props.theme.menu.actionsMenu.textColor};
  align-items: center;
  padding: 0px 6px 0px 12px;
  text-align: left;
  font-size: 11px;
  font-weight: 700;
  line-height: 30px;
  width: calc(100% - 18px);
  border-bottom: solid 1px ${(props) => props.theme.menu.actionsMenu.borderColor};
  &:hover {
    background-color: ${(props) => props.theme.menu.actionsMenu.hoverBackground};
    background-image: none;
    border-bottom-color: ${(props) => props.theme.menu.actionsMenu.hoverBorder};
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
  }
`;

const ButtonIcon = styled(Icon).attrs((props) => ({
  size: 14,
  color: props.color ? props.color : props.theme.eventController.iconColor,
}))`
  margin-right: 6px;
`;

const SquareIcon = styled.div`
  background: ${(props) => (props.color ? props.color : props.theme.eventController.iconColor)};
  border-radius: 1px;
  display: inline-block;
  height: 12px;
  margin-right: 6px;
  margin-bottom: -2px;
  width: 12px;
`;

const CommandObject = {
  [AssetCommand.BATTERY_TEST]: {
    iconType: ButtonIcon,
    icon: Icons.WARNING,
    color: globalColors.orange1,
  },
  [AssetCommand.TEST]: {
    iconType: ButtonIcon,
    icon: Icons.BELL,
    color: globalColors.red2,
  },
  [AssetCommand.HARD_RESET]: {
    iconType: SquareIcon,
    icon: Icons.WARNING,
    color: globalColors.red2,
  },
  [AssetCommand.IDLE_RESET]: {
    iconType: ButtonIcon,
    icon: Icons.IDLE_SPINNER,
  },
  [AssetCommand.LOAD_SHUTDOWN]: {
    iconType: SquareIcon,
    color: globalColors.teal2,
  },
  [AssetCommand.RESET]: {
    iconType: ButtonIcon,
    icon: Icons.ACTION_RESET,
  },
  [AssetCommand.START]: {
    iconType: ButtonIcon,
    icon: Icons.WARNING,
    color: globalColors.teal2,
  },
  [AssetCommand.STOP]: {
    iconType: SquareIcon,
  },
};

export const CommandsController = ({
  iconColor,
  assets,
  commandsData = {},
  disabled,
  onClose,
  onExecute,
}) => {
  const { t, ready } = useTranslation(['entity-details'], { useSuspense: false });

  const [dialogCommand, setDialogCommand] = useState(null);

  const {
    commands,
    execute,
    status,
    history,
    counts,
    isExecuting,
    abortGroupCommand,
    isGroupCommandComplete,
  } = commandsData;

  const buildCommands = () => {
    return commands?.map((command, idx) => {
      const ctrl = CommandObject[command?.commandId];
      if (!ctrl) return;
      return (
        <ControlButton key={idx} onClick={() => setDialogCommand(command)} disabled={disabled}>
          <ctrl.iconType icon={ctrl.icon} color={ctrl.color ? ctrl.color : iconColor} />
          {t(`commands.${toLocaleKey(command?.desc)}`, command?.desc || '')}
        </ControlButton>
      );
    });
  };

  const handleOnClose = () => {
    setDialogCommand(null);
    onClose();
  };

  const handleOnExecute = async (command) => {
    const context = await execute(command);
    onExecute(context);
  };

  const handleOnAbort = async () => {
    await abortGroupCommand();
  };

  if (!ready) {
    return <MiniLoader />;
  }

  return (
    <>
      <div className="asset-controls">{buildCommands()}</div>
      {dialogCommand && (
        <CommandsDialog
          assets={assets}
          onClose={handleOnClose}
          onAbort={handleOnAbort}
          command={dialogCommand}
          onExecute={() => handleOnExecute(dialogCommand)}
          history={history}
          status={status}
          counts={counts}
          isExecuting={isExecuting}
          isGroupCommandComplete={isGroupCommandComplete}
        />
      )}
    </>
  );
};

CommandsController.propTypes = {
  iconColor: PropTypes.string,
  assets: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool,
  commandsData: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func,
  onExecute: PropTypes.func,
};

CommandsController.defaultProps = {
  iconColor: null,
  disabled: false,
  onClose: () => {},
  onExecute: () => {},
};
