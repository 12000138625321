import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

export const fetchTaskTemplates = (taskSource) =>
  request.get('/mwp/task-templates', { baseURL: BASE_URL, params: { source: taskSource } });

export const fetchTaskSource = ({ assetType, page, language }) =>
  request.get('/mwp/task-sources', { baseURL: BASE_URL, params: { assetType, page, language } });

export const fetchTaskWorkScope = ({ assetType, language }) =>
  request.get('/mwp/task-work-scopes', { baseURL: BASE_URL, params: { assetType, language } });
