/* eslint-disable */
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import React, { useEffect, useCallback, useState, useContext } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { useEditPerson } from '@ge/feat-admin/data-hooks/use-edit-person';
import { Pattern } from '@ge/models/constants';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors';

import { FormError, FormLoading } from '../../../entity-create/entity-create-shared';
import { PersonContext } from '../person-context';

const PersonOverviewContainer = styled.div`
  position: relative;
  margin: 0 20px 0 10px;
  padding-top: 30px;
  height: 70vh;
`;

const PersonOverviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const PersonDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const PersonDetailItem = styled.div`
  margin-right: 10px;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span.select-label {
    display: block;
    margin: 0 0 5px 0;
    font-size: 11px;
    line-height: 13px;
    color: ${(props) => props.theme.input.labelTextColor};
  }

  input:read-only {
    opacity: 0.7;
    border: none;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
  }
`;

const PersonDetailSelectItem = styled.div`
  margin-right: 10px;
  width: 175px;
  span.select-label {
    display: block;
    margin: 0 0 5px 0;
    font-size: 11px;
    line-height: 13px;
    color: ${(props) => props.theme.input.labelTextColor};
  }

  input:read-only {
    opacity: 0.7;
    border: none;
    &:focus {
      border: none;
    }
  }
`;

const PersonDetailLabel = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.resolutionText};
  font-size: 11px;
  padding-bottom: 8px;
`;

const FieldError = styled.div`
  color: ${StatusColor.DANGER};
  font-weight: ${typography.weight.bold};
  margin-top: 5px;
`;

const PersonDetailValue = styled.div`
  font-size: 14px;
`;

const StyledSelect = styled(Select)`
  .select__control {
    padding: 1.5px 0;
  }
`;

const EditButton = withTheme(({ theme, ...rest }) => {
  const { iconColor } = theme.themeSelector;
  return (
    <button {...rest}>
      <Icon icon={Icons.PENCIL} size={11} color={iconColor} />
    </button>
  );
});

const SaveButtonRow = styled(PersonDetailRow)`
  border-top: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
`;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.tooltip.backgroundColor,
    color: theme.tooltip.textColor,
    boxShadow: theme.tooltip.boxShadow,
    fontSize: 11,
  },
}));

const DetailItem = ({ item, label }) => {
  if (!item) return null;

  return (
    <PersonDetailItem>
      <PersonDetailLabel>{label}</PersonDetailLabel>
      <LightTooltip placement="top" title={item}>
        <PersonDetailValue>{item}</PersonDetailValue>
      </LightTooltip>
    </PersonDetailItem>
  );
};

DetailItem.propTypes = {
  item: PropTypes.string,
  label: PropTypes.string,
};

const DetailEdit = ({ item, label, id, required, error, readOnly }) => {
  // if (!item) return null;

  return (
    <>
      {id === 'phone' ? (
        <PersonDetailItem>
          <Controller
            name={id}
            rules={{
              required: required,
              minLength: { value: 7, message: 'Invalid phone number' },
            }}
            defaultValue={item ? item : ''}
            render={({ onChange, value, onBlur }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                label={label + (required ? '*' : '')}
                type="text"
                value={value}
                error={error}
                readOnly={readOnly}
              />
            )}
          />
          {error && <FieldError>Invalid phone number</FieldError>}
        </PersonDetailItem>
      ) : id === 'email' ? (
        <PersonDetailItem>
          <Controller
            name={id}
            rules={{
              required: required,
              pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
            }}
            defaultValue={item ? item : ''}
            render={({ onChange, value, onBlur }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                label={label + (required ? '*' : '')}
                type="text"
                value={value}
                error={error}
                readOnly={readOnly}
              />
            )}
          />
          {error && <FieldError>Invalid email address</FieldError>}
        </PersonDetailItem>
      ) : (
        <PersonDetailItem>
          <Controller
            name={id}
            rules={{
              required: required,
            }}
            defaultValue={item ? item : ''}
            render={({ onChange, value, onBlur }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                label={label + (required ? '*' : '')}
                type="text"
                value={value}
                error={error}
                readOnly={readOnly}
              />
            )}
          />
        </PersonDetailItem>
      )}
    </>
  );
};

DetailEdit.propTypes = {
  item: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

DetailEdit.defaultProps = {
  required: false,
};

// const formatPhone = (number) => {
//   const phoneNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
//   return phoneNumber;
// };

const checkIfEmail = (str) => {
  // Regular expression to check if string is email
  const regexExp = Pattern.EMAIL;
  return regexExp.test(str);
};

const jobTitleOptions = ['Site_Manager', 'Site_Lead', 'Technician', 'Contractor', 'Other'];

export const PersonOverview = () => {
  const { t } = useTranslation(['entity-details']);
  const [isEditMode, setIsEditMode] = useState(false);
  const { fetchedPerson, providedAttributes, setProvidedAttributes } = useContext(PersonContext);
  const methods = useForm();

  // KEEP AT TOP AS FIRST useEffect
  useEffect(() => {
    let filteredAttributes = providedAttributes?.filter(
      (attribute) => attribute.roleName !== 'New_User_Role',
    );
    setProvidedAttributes(() => filteredAttributes);
  }, []);

  const handleSuccess = () => {
    setIsEditMode(false);
  };

  const { edit, isLoading: isEditing, isError } = useEditPerson({
    onSuccess: handleSuccess,
  });

  const onSubmit = useCallback(
    (values) => {
      let updatedPerson = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        functionalSSO: values.functionalSSO ? values.functionalSSO : null,
        phoneNumber: values.phone ? values.phone : null,
        initials: values.initials ? values.initials : null,
        title: values.jobTitle.value ? values.jobTitle.value : null,
      };
      edit({
        ...fetchedPerson,
        ...updatedPerson,
      });
    },
    [edit, fetchedPerson],
  );

  const personDetailView = () => {
    return (
      <>
        <PersonDetailRow>
          <DetailItem item={fetchedPerson.email} label={t('person.email', 'Email')} />

          <DetailItem item={fetchedPerson.firstName} label={t('person.first_name', 'First Name')} />

          <DetailItem item={fetchedPerson.lastName} label={t('person.last_name', 'Last Name')} />

          {fetchedPerson.type[0] !== 'Contact' ? (
            checkIfEmail(fetchedPerson.username) === false ? (
              <DetailItem item={fetchedPerson.username} label={t('person.sso', 'SSO')} />
            ) : fetchedPerson.functionalSSO ? (
              <DetailItem
                item={fetchedPerson.functionalSSO}
                label={t('person.functional_sso', 'Shared SSO')}
              />
            ) : null
          ) : null}
        </PersonDetailRow>

        <PersonDetailRow>
          <DetailItem
            item={fetchedPerson.phoneNumber ? fetchedPerson.phoneNumber : null}
            label={t('person.phone', 'Phone')}
          />

          <DetailItem
            item={fetchedPerson.initials}
            label={t('person.initials', 'Initials (up to 5 characters)')}
          />

          <DetailItem
            item={fetchedPerson.title ? fetchedPerson.title.replace(/[_-]/g, ' ') : null}
            label={t('person.title', 'Job Title')}
          />
        </PersonDetailRow>
      </>
    );
  };

  const personDetailEdit = () => {
    return (
      <FormProvider {...methods}>
        <PersonDetailRow>
          <DetailEdit
            id="email"
            item={fetchedPerson.email}
            label={t('person.email', 'Email')}
            required
            error={methods.errors?.email}
            readOnly={fetchedPerson.validated === 1 ? true : false}
          />

          <DetailEdit
            id="firstName"
            item={fetchedPerson.firstName}
            label={t('person.first_name', 'First Name')}
            required
            error={methods.errors?.firstName}
            readOnly={fetchedPerson.validated === 1 ? true : false}
          />

          <DetailEdit
            id="lastName"
            item={fetchedPerson.lastName}
            label={t('person.last_name', 'Last Name')}
            required
            error={methods.errors?.lastName}
            readOnly={fetchedPerson.validated === 1 ? true : false}
          />

          {fetchedPerson.type[0] !== 'Contact' ? (
            checkIfEmail(fetchedPerson.username) === false ? (
              <DetailItem item={fetchedPerson.username} label={t('person.sso', 'SSO')} />
            ) : (
              <DetailEdit
                id="functionalSSO"
                item={fetchedPerson.functionalSSO}
                label={t('person.functional_sso', 'shared SSO')}
              />
            )
          ) : null}
        </PersonDetailRow>

        <PersonDetailRow>
          <DetailEdit
            id="phone"
            item={fetchedPerson.phoneNumber ? fetchedPerson.phoneNumber : null}
            label={t('person.phone', 'Phone')}
            error={methods.errors?.phone}
          />

          <DetailEdit
            id="initials"
            item={fetchedPerson.initials}
            label={t('person.initials', 'Initials (up to 5 characters)')}
            error={methods.errors?.initials}
          />

          <PersonDetailSelectItem>
            <span className="select-label">{t('person.title', 'Job Title')}</span>
            <Controller
              name="jobTitle"
              defaultValue={
                fetchedPerson.title
                  ? {
                      value: fetchedPerson.title,
                      label: t(fetchedPerson.title, fetchedPerson.title.replace(/[_-]/g, ' ')),
                    }
                  : { value: '', label: t('person.select_title', 'Select a Job Title') }
              }
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <StyledSelect
                  maxWidth={175}
                  minWidth={175}
                  name="jobTitle"
                  options={jobTitleOptions.map((option) => ({
                    value: option,
                    label: t(option, option.replace(/[_-]/g, ' ')),
                  }))}
                  value={value}
                  onChange={onChange}
                />
              )} // props contains: onChange, onBlur and value
            />
          </PersonDetailSelectItem>
        </PersonDetailRow>

        <SaveButtonRow>
          <Button type="button" onClick={() => setIsEditMode(false)}>
            {t('general.cancel', 'Cancel')}
          </Button>
          <Button
            type="button"
            primary
            onClick={methods.handleSubmit(onSubmit)}
            disabled={!methods.formState.isDirty ? true : false}
          >
            {t('general.save', 'Save')}
          </Button>
        </SaveButtonRow>
      </FormProvider>
    );
  };

  return (
    <PersonOverviewContainer>
      <PersonOverviewHeader>
        {fetchedPerson?.firstName && (
          <>
            {fetchedPerson.firstName}&lsquo;s {t('person.details', 'Details')}
          </>
        )}
        {!isEditMode && <EditButton onClick={() => setIsEditMode(true)} />}
      </PersonOverviewHeader>
      {isEditMode ? personDetailEdit() : personDetailView()}
      {isEditing && (
        <FormLoading>
          <Loader />
        </FormLoading>
      )}
      {isError && <FormError>An error occurred. Person failed to be edited.</FormError>}
    </PersonOverviewContainer>
  );
};
