import merge from 'deepmerge';
import { action, computed, thunk } from 'easy-peasy';

import { getJobsStatistics, getAnnotationCounts } from '../services/overview';

// Define initial state
const defaultOverviewState = {
  statisticsByDate: [],
  annotationsCount: [],
};

// Actions
const overviewActions = {
  resetOverview: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, defaultOverviewState);
  }),
  fetchJobsStatistics: thunk(async (actions) => {
    const options = {};
    const today = new Date();
    const monthOldDate = new Date();
    monthOldDate.setMonth(today.getMonth() - 1);

    const epochStart = Math.floor(monthOldDate.getTime() / 1000);
    const epochEnd = Math.floor(today.getTime() / 1000);
    const { result } = await getJobsStatistics(epochStart, epochEnd, options);

    actions.setStatisticsByDate(result);
  }),
  setStatisticsByDate: action((state, payload) => {
    state.statisticsByDate = merge(state.statisticsByDate, payload);
  }),
  fetchAnnotationsCount: thunk(async (actions) => {
    const options = {};
    const today = new Date();
    const monthOldDate = new Date();
    monthOldDate.setMonth(today.getMonth() - 1);

    const inspectionType = 'Sabre IR';
    const epochStart = Math.floor(monthOldDate.getTime() / 1000);
    const epochEnd = Math.floor(today.getTime() / 1000);
    const { results } = await getAnnotationCounts(inspectionType, epochStart, epochEnd, options);

    actions.setAnnotationsCount(results);
  }),
  setAnnotationsCount: action((state, payload) => {
    state.annotationsCount = merge(state.annotationsCount, payload);
  }),
};

// Listeners
const overviewListeners = {};

// Computed values
const overviewComputed = {
  getStatisticsByDay: computed((state) => () => state.statisticsByDate),
  getStatisticsByWeek: computed((state) => () => state.statisticsByDate),
  getStatisticsByMonth: computed((state) => () => state.statisticsByDate),
  getAnnotationsCount: computed((state) => () => state.annotationsCount),
};

const overviewModel = {
  ...defaultOverviewState,
  ...overviewActions,
  ...overviewListeners,
  ...overviewComputed,
};

export default overviewModel;
