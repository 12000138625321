import { Capability, PermissionScope } from '@ge/models/constants';

export const NAMESPACE = 'analyze.configure';

export const CONFIGURE_BASE_PATH = '/admin/application configuration/analyze/';

export const ConfigureNavItem = {
  CASE_TEMPLATES: 'case templates',
  ANALYSIS_TEMPLATES: 'analysis templates',
};

export const ConfigureNavItems = [
  {
    capabilities: [{ capability: Capability.ANALYTICS, scopes: [PermissionScope.ADMIN] }],
    label: 'Case Templates',
    i18nKey: 'nav:admin.l3.configure.case_templates',
    route: CONFIGURE_BASE_PATH + ConfigureNavItem.CASE_TEMPLATES,
  },
  {
    capabilities: [{ capability: Capability.ANALYTICS, scopes: [PermissionScope.ADMIN] }],
    label: 'Analysis Templates',
    i18nKey: 'nav:admin.l3.configure.analysis_templates',
    route: CONFIGURE_BASE_PATH + ConfigureNavItem.ANALYSIS_TEMPLATES,
  },
];
