import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

/* Removing for MVP0 **/
// import { Icons, Icon } from '@ge/components/icon';
import { DotsLoader } from '@ge/components/loader';
import { Placeholders } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';

import { formatKPIValue } from '../../util';

const StyledKpi = styled.div`
  min-width: 110px;
  max-width: 225px;
  padding: 0 10px;
  justify-content: center;
  flex: 1;
`;

const KpiTitle = styled.div`
  height: ${(props) => (props.compressed ? 'auto' : '30px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KpiTitleText = styled.h3`
  text-align: center;
  color: ${(props) => props.theme.analyze.header.kpiTitleColor};
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  margin-bottom: ${(props) => (props.compressed ? '0' : '6px')};
`;

const KpiValuesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const KpiContainer = styled.div`
  display: flex;
  text-align: center;
  color: ${(props) => props.theme.analyze.header.kpiColor};
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  &:nth-of-type(2) {
    margin-left: 4px;
    &:before {
      content: '/';
      font-weight: 100;
      margin-right: 4px;
    }
  }
`;

const KpiUnit = styled.sup`
  color: ${(props) => props.theme.analyze.header.kpiDescColor};
  font-size: 10px;
  letter-spacing: 0.45px;
  line-height: 12px;
  margin: 4px 0 0 1px;
  align-self: flex-start;
`;

/* Removing for MVP0 **/
// const ArrowIcon = styled(Icon).attrs((props) => ({
//   size: 10,
//   color: props.theme.analyze.header.kpiIconColor,
// }))``;

export const DashboardKPI = ({ title, data, compressed, renderWhileLoading }) => {
  const { t, i18n } = useTranslation(['analyze.dashboard']);

  const getKpiCellFn = useCallback((data) => {
    if (data?.length > 0) return data.map((d, i) => <KpiCell key={i} data={d} />);
    if (data?.aggregateValue || data?.value || data?.aggregateValue === 0 || data?.value === 0)
      return <KpiCell data={data} />;
    return (
      <KpiContainer data-testid={AnalyzeLocators.ANALYZE_KPIBAR_EMPTY_VALUE}>
        {Placeholders.DOUBLE_DASH}
      </KpiContainer>
    );
  }, []);

  if (!renderWhileLoading && !data) {
    return null;
  }

  const KpiCellFormat = ({ currency, value, unit }) => (
    <div data-testid={AnalyzeLocators.ANALYZE_KPIBAR_VALID_VALUE} className="kpi-2">
      {/* Removing for MVP0 **/}
      {/* {change && (
        <ArrowIcon icon={change === KPI_CHANGE.INCREASE ? Icons.ARROW_UP : Icons.ARROW_DOWN} />
      )} */}
      {currency && t('kpi.currency', '$')}
      {value}
      {unit && <KpiUnit>{unit}</KpiUnit>}
    </div>
  );

  const KpiCell = ({ data }) => {
    const value = data?.value ?? data?.aggregateValue;
    const valueFormat = formatKPIValue(value, i18n.language);
    return (
      <KpiContainer>
        <KpiCellFormat
          currency={data?.currency}
          value={valueFormat}
          unit={data?.unit ?? data?.units}
          change={data?.change}
        />
      </KpiContainer>
    );
  };

  return (
    <StyledKpi compressed={compressed}>
      {title && (
        <KpiTitle compressed={compressed}>
          <KpiTitleText
            data-testid={AnalyzeLocators.ANALYZE_KPIBAR_NAME.concat(t(title?.i18nKey))}
            compressed={compressed}
          >
            {t(title.i18nKey, title.i18nDefault)}
          </KpiTitleText>
        </KpiTitle>
      )}
      <KpiValuesContainer
        data-testid={AnalyzeLocators.ANALYZE_KPIBAR_VALUE.concat(t(title?.i18nKey))}
      >
        {data?.isLoading ? <DotsLoader /> : getKpiCellFn(data)}
      </KpiValuesContainer>
    </StyledKpi>
  );
};

DashboardKPI.propTypes = {
  title: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object),
  compressed: PropTypes.bool,

  currency: PropTypes.bool,
  value: PropTypes.number,
  unit: PropTypes.string,
  change: PropTypes.string,
  idx: PropTypes.number,
  renderWhileLoading: PropTypes.bool,
};

DashboardKPI.defaultProps = {
  compressed: false,
  data: undefined,
  renderWhileLoading: false,
};

/* Removing for MVP0 **/
// const KPI_CHANGE = {
//   INCREASE: 'increase',
//   DECREASE: 'decrease',
// };
