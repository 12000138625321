import { useQuery } from 'react-query';

import { fetchTaskTemplates } from '../services/task-template';

import { Config } from './config';

/**
 * Use task template.
 *
 * @param taskSource
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useTaskTemplate = (taskSource) => {
  return useQuery(['task-template', taskSource], () => fetchTaskTemplates(taskSource), {
    ...Config.EXECUTE_ONCE,
    enabled: Boolean(taskSource),
  });
};
