import { PropTypes } from 'prop-types';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CollapsiblePanel, TRANSITION_TIME } from '@ge/components/collapsible-panel';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import {
  useReportTemplateDashboard,
  useSentReportListTemplate,
} from '@ge/feat-reporting/data-hooks';
import { CreateModes } from '@ge/feat-reporting/models/modes';
import {
  globalSentReportListCols,
  SentReportListColumnDefs,
} from '@ge/feat-reporting/models/sent-report-list-cols';
import {
  globalTemplateCols,
  TemplateColumnDefs,
} from '@ge/feat-reporting/models/template-table-cols';
import { Capability, PermissionScope } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useAuth } from '@ge/shared/data-hooks';

import { TemplateDashboardTable, SentReportList } from './tables';

/**
 * Available sort metrics for Template Name.
 */
const TemplateDashboardMetric = {
  SCOPE: 'scope',
};

const ReportTableMetric = {
  DATE_SENT: 'dateSent',
};

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  margin-bottom: 30px;
  .title {
    font-size: 18px;
    font-weight: 500;
    font-family: 'GE Inspira';
    color: ${(props) => props.theme.navigation.linkColor};
    display: flex;
    align-items: center;
    height: 57px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: none;
    svg {
      fill: ${(props) => props.theme.navigation.chevronColor};
    }
    &:hover {
      color: ${(props) => props.theme.navigation.linkActiveColor};
      svg {
        fill: ${(props) => props.theme.navigation.linkActiveColor};
      }
    }
    &.active {
      background-color: ${(props) => props.theme.navigation.linkActiveBackgroundColor};
    }
  }
`;

const Container = styled.div`
  height: 400px;
  th:nth-of-type(3) {
    width: 500px;
  }
`;

const SentReportsContainer = styled.div`
  height: 400px;
  .sent-reports-table {
    table-layout: fixed;
  }
`;

const DashboardContainer = styled.div`
  padding: 30px;
`;

const Title = styled.div`
  font-size: 13px;
  font-style: italic;
`;

export const MyReports = ({ disableNavigation }) => {
  const { t } = useTranslation(['reporting.reports', 'general'], { useSuspense: false });
  const [currentReportsSearch, setCurrentReportsSearch] = useState({});
  const [reportsFilterDefs, setReportsFilterDefs] = useState({});
  const [myTemplatesExpanded, setMyTemplatesExpanded] = useState(true);
  const [sentReportsExpanded, setSentReportsExpanded] = useState(true);

  const { setCreateMode } = useContext(ReportsContext);

  const { push } = useHistory();

  const routeToCreateReport = useCallback(
    (id) => {
      disableNavigation(false);
      setCreateMode(CreateModes.EDIT);
      push(`/reporting/reports/create/${id}`);
    },
    [disableNavigation, setCreateMode, push],
  );

  const handleNewReportSelect = (dashboardData) => {
    routeToCreateReport(dashboardData);
  };

  const {
    sortDirection: templateTableSortDirection,
    sortMetric: templateTableSortMetric,
    updateSortMetric: updateTemplateTableSortMetric,
  } = useColumnState({
    columnDefs: TemplateColumnDefs,
    defaultCols: globalTemplateCols,
    defaultSortMetric: TemplateDashboardMetric.SCOPE,
    defaultSortDirection: SortDirection.ASC,
  });

  const {
    allTemplates,
    data: reportTemplateDashboardData,
    isLoading: reportTemplateLoading,
    hasNextPage: reportTemplateHasNextPage,
    fetchNextPage: reportTemplateFetchNextPage,
  } = useReportTemplateDashboard({
    sortMetric: templateTableSortMetric,
    sortDirection: templateTableSortDirection,
  });

  useEffect(() => {
    reportTemplateHasNextPage && reportTemplateFetchNextPage();
  }, [allTemplates, reportTemplateFetchNextPage, reportTemplateHasNextPage]);

  const {
    sortDirection: reportTableSortDirection,
    sortMetric: reportTableSortMetric,
    updateSortMetric: updateReportTableSortMetric,
  } = useColumnState({
    columnDefs: SentReportListColumnDefs,
    defaultCols: globalSentReportListCols,
    defaultSortMetric: ReportTableMetric.DATE_SENT,
    defaultSortDirection: SortDirection.DESC,
  });

  const handleReportsFilter = useCallback(
    (updateDefs) => {
      setReportsFilterDefs(updateDefs);
    },
    [setReportsFilterDefs],
  );

  const handleReportsFilterChange = useCallback(
    (search) => setCurrentReportsSearch(search),
    [setCurrentReportsSearch],
  );

  const {
    allReports,
    data: sentReportListData,
    filters: reportsFilterValues,
    isLoading: sentReportsListLoading,
    hasNextPage: sentReportsHasNextPage,
    fetchNextPage: sentReportsFetchNextPage,
  } = useSentReportListTemplate({
    filters: reportsFilterDefs,
    search: currentReportsSearch,
    sortMetric: reportTableSortMetric,
    sortDirection: reportTableSortDirection,
  });

  useEffect(() => {
    sentReportsHasNextPage && sentReportsFetchNextPage();
  }, [allReports, sentReportsFetchNextPage, sentReportsHasNextPage]);

  const { isAuthorized } = useAuth();
  const authorizedToCreateMyTemplates = isAuthorized({
    capabilities: [
      { capability: Capability.REPORTING_TEMPLATES, scopes: [PermissionScope.CREATE] },
    ],
  });

  const filterBaseTemplatesWithPermissions = (templateData) => {
    return templateData.filter((template) => {
      if (template.baseTemplate) {
        // only return base templates if user has create permissions
        return authorizedToCreateMyTemplates && template;
      }
      return template;
    });
  };

  return (
    <ScrollingContainer>
      <DashboardContainer>
        <AuthRender capability={Capability.REPORTING_TEMPLATES} view siteLevel={false}>
          <StyledCollapsiblePanel
            expanded
            headerContent={
              <div>
                <h2>{t('my_reports.table_title', 'My Templates')}</h2>
                <Title>{t('my_reports.table_sub_title', 'These are predefined templates')}</Title>
              </div>
            }
            onToggleExpand={(open) => {
              if (open) {
                setMyTemplatesExpanded(open);
              } else {
                setTimeout(() => {
                  setMyTemplatesExpanded(open);
                }, TRANSITION_TIME * 1000);
              }
            }}
          >
            <Container>
              {reportTemplateLoading ? (
                <Loader />
              ) : (
                <TemplateDashboardTable
                  scrollable={myTemplatesExpanded}
                  columns={globalTemplateCols}
                  templateData={filterBaseTemplatesWithPermissions(reportTemplateDashboardData)}
                  sortAction={updateTemplateTableSortMetric}
                  sortMetric={templateTableSortMetric}
                  sortDirection={templateTableSortDirection}
                  onNewReportSelect={handleNewReportSelect}
                />
              )}
            </Container>
          </StyledCollapsiblePanel>
        </AuthRender>
        <StyledCollapsiblePanel
          expanded
          headerContent={
            <div>
              <h2>{t('sent_reports.list_title', 'Sent Reports')}</h2>
              <Title>
                {t(
                  'sent_reports.list_sub_title',
                  'These are all Reports sent for the current scope',
                )}
              </Title>
            </div>
          }
          onToggleExpand={(open) => {
            if (open) {
              setSentReportsExpanded(open);
            } else {
              setTimeout(() => {
                setSentReportsExpanded(open);
              }, TRANSITION_TIME * 1000);
            }
          }}
        >
          <SentReportsContainer>
            {sentReportsListLoading ? (
              <Loader />
            ) : (
              <SentReportList
                scrollable={sentReportsExpanded}
                columns={globalSentReportListCols}
                sentReportListData={sentReportListData}
                sortAction={updateReportTableSortMetric}
                sortMetric={reportTableSortMetric}
                sortDirection={reportTableSortDirection}
                onNewReportSelect={handleNewReportSelect}
                className="sent-reports-table"
                onFilter={handleReportsFilter}
                onFilterChange={handleReportsFilterChange}
                filterValues={reportsFilterValues}
              />
            )}
          </SentReportsContainer>
        </StyledCollapsiblePanel>
      </DashboardContainer>
    </ScrollingContainer>
  );
};

MyReports.propTypes = {
  disableNavigation: PropTypes.func,
};
