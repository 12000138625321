import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ScrollingContainer } from '@ge/components/scrolling-container';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { DefaultKpiCategoryDef, EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { DateRange } from '@ge/models/constants';
import { useFilterDateRange } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { formatNumber } from '@ge/util';

import { DashboardKpiChart } from '../../components/dashboard/dashboard-kpi-chart';
import useFleetOverviewKpiData from '../../data-hooks/use-fleet-overview-kpi-data';
import useTimeAggregation from '../../data-hooks/use-time-aggregation';
import { FleetKpiChartDefs, FleetFilterOptions } from '../../models';
import { getKpiCategories, getKpiChartDef, getFilterDateRange } from '../../util';

import { DashboardGaugeConfiguration } from './dashboard-gauge-configuration';
import RegionCard from './region-card';
import { RegionPerformanceFooter } from './region-performance-footer';

/* Constants  */
const GAUGES_FEAT_PREFS_SUB_KEY = 'gauges';
const NAMESPACE = 'analyze.dashboard';
const REGION_CARD_CHART_HEIGHT = 182;

const StyledContainer = styled.div`
  padding: 1px 15px 18px 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.layout.textColor};
  }
  .header {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify: space-between;
    padding-left: 10px;
  }
  .config {
    padding-left: 96.1%;
    padding-top: 9px;
    position: absolute;
    z-index: 1;
  }
  .ledgend {
    margin-top: 10px;
    display: flex;
    margin-left: auto;
    text-transform: uppercase;
    > div {
      display: flex;
      align-items: center;
      margin-right: 3%;
      font-size: 11px;
      color: ${(props) => props.theme.analyze.regionCard.ledgendColor};
    }
  }
  .cards {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 15px;

    .overall-kpi-chart {
      margin-bottom: 10px;
    }
  }
`;

export const RegionPerformance = ({ defaultCategory, onKpiCategoryChange }) => {
  /* Translations  */
  const { t } = useTranslation(['analyze.dashboard']);
  const translations = {
    assets: t('assets', 'Assets'),
  };

  const { featPrefs, savePrefs } = useFeaturePrefs(AppScopes.ANALYZE_FLEET_OVERVIEW);
  const gaugeDefs = featPrefs?.[GAUGES_FEAT_PREFS_SUB_KEY];

  /* Store State  */
  const sitesForView = useStoreState((state) => state.sites.sitesForView);

  /* Local state */
  const [kpiChartState, setKpiChartState] = useState({
    categories: [defaultCategory],
    def: getKpiChartDef(FleetKpiChartDefs),
  });

  const [kpiSortDirection, setKpiSortDirection] = useState(SortDirection.ASC);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: undefined,
    endDate: undefined,
    range: undefined,
  });

  const { dateRange: dates } = useStoreState(({ analyze }) => analyze);

  // Set initial date range to default
  useEffect(() => {
    // don't notify if custom date range is incomplete
    if (dates.range === DateRange.CUSTOM && !(dates.endDate && dates.startDate)) {
      return;
    }

    const filterDateRange = getFilterDateRange({
      startDate: dates.startDate,
      range: dates.range,
      endDate: dates.endDate,
    });

    setDateRange({
      startDate: filterDateRange.startDate.entityTimezone,
      endDate: filterDateRange.endDate.entityTimezone,
      range: dates.range,
    });
  }, [dates]);

  const timeAggrProps = useTimeAggregation({
    entityType: EntityType.FLEET,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const { timeAggregation, timeAggrOptions, onTimeAggrChange } = timeAggrProps;

  const timeAggr = useMemo(
    () => timeAggrOptions?.find((item) => item.value === timeAggregation)?.value ?? null,
    [timeAggregation, timeAggrOptions],
  );

  const {
    def: { graph1 },
  } = kpiChartState;
  const { sortEntitiesBy } = graph1 || {};

  const { categories } = kpiChartState;

  /* Data Hooks */
  const { endDate, startDate, filterDateRange } = useFilterDateRange({ dateRange });
  const { data: fleetKpiData, isLoading: isFleetKpiLoading } = useFleetOverviewKpiData({
    endDate: endDate,
    entityTimezone: filterDateRange.entityTimezone,
    range: filterDateRange.range,
    startDate: startDate,
    categories,
    sortBy: sortEntitiesBy,
    sortDirection: kpiSortDirection,
    timeAggr,
  });

  // Get region ids from view selector for region cards.
  // Some sites may contain undefined regions so these are filtered out.
  // Good to note here though this should be evaluated in the data, as this may skew results.

  const regionIds = useMemo(
    () =>
      [...new Set(sitesForView.map((site) => site?.region?.id))]
        .filter(Boolean)
        .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
    [sitesForView],
  );

  /* Handlers */
  const handleSortChange = useCallback(
    (sort) => {
      const sortDirection =
        {
          ascending: SortDirection.ASC,
          descending: SortDirection.DESC,
        }[sort] ?? sort;
      setKpiSortDirection(sortDirection);
    },
    [setKpiSortDirection],
  );

  const handleSelectedAssets = useCallback(
    (newSelection) =>
      setSelectedAssets((prevSelection) =>
        equals(prevSelection, newSelection) ? prevSelection : newSelection,
      ),
    [setSelectedAssets],
  );

  // map filter bar to kpi chart defs
  const handleFilterBarChange = useCallback(
    ({ ...filterBar }) => {
      const def = getKpiChartDef(FleetKpiChartDefs, filterBar);
      // get list of distinct categories to fetch
      const categories = getKpiCategories(def);

      const kpi = def.kpi;
      const primaryGroup = filterBar?.primary;

      if (kpi) {
        onKpiCategoryChange({ kpi, primaryGroup });
      }

      setKpiChartState({ categories, def });
    },

    // kpiSortDirection needed as a dep to trigger data fetching in ASC or DESC order
    // when selection from the Sort Dropdown on graph 1

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setKpiChartState, kpiSortDirection],
  );

  const yAxisLabelFormatter = ({ value }) => formatNumber(value);

  return (
    <StyledContainer>
      {/* Removing for MVP0 */}
      {/* <div className="ledgend">
          <div>
            <Line className="actual" />
            {t('actual_tba', 'actual tba')}
          </div>
          <div>
            <Line className="projected" />
            {t('projected', 'projected')}
          </div>
          <div>
            <Line className="target" />
            {t('target_tba', 'target tba')}
          </div>
        </div> */}
      <div className="cards">
        <ScrollingContainer offsetX={12} right>
          <div className="config">
            <DashboardGaugeConfiguration
              entityType={EntityType.REGION}
              onApply={(value) => savePrefs(value, GAUGES_FEAT_PREFS_SUB_KEY)}
              values={gaugeDefs}
            />
          </div>
          <DashboardKpiChart
            chartDef={graph1}
            className={'overall-kpi-chart'}
            data={fleetKpiData}
            entityType={EntityType.REGION}
            filterOptions={FleetFilterOptions}
            handleFilterBarChange={handleFilterBarChange}
            isLoading={isFleetKpiLoading}
            namespace={NAMESPACE}
            onChange={handleSortChange}
            onSelected={handleSelectedAssets}
            selectedEntities={selectedAssets}
            showToggle={false}
            xAxisTitle={translations.assets}
            height={361}
            {...timeAggrProps}
            yAxisLabelFormatter={yAxisLabelFormatter}
            staticChartTitle={t('fleet_trends', 'Fleet Trends')}
          />
          {regionIds.map((regionId) => (
            <RegionCard
              chartDef={graph1}
              chartHeight={REGION_CARD_CHART_HEIGHT}
              endDate={endDate}
              startDate={startDate}
              categories={categories}
              sortBy={sortEntitiesBy}
              sortDirection={kpiSortDirection}
              key={regionId}
              regionId={regionId}
              namespace={NAMESPACE}
              onSelected={handleSelectedAssets}
              selectedEntities={selectedAssets}
              showTitle={false}
              xAxisTitle={translations.assets}
              gaugeDefs={gaugeDefs}
              timeAggregation={timeAggr}
              timeAggrOptions={timeAggrOptions}
              onTimeAggrChange={onTimeAggrChange}
            />
          ))}
        </ScrollingContainer>
        <RegionPerformanceFooter />
      </div>
    </StyledContainer>
  );
};

RegionPerformance.propTypes = {
  defaultCategory: PropTypes.oneOf(Object.values(KpiCategoryDefs)),
  onKpiCategoryChange: PropTypes.func,
};

RegionPerformance.defaultProps = {
  defaultCategory: DefaultKpiCategoryDef,
  onKpiCategoryChange: () => {},
};
