import { TaskListSize } from '@ge/models/entities/task/constants';
import * as request from '@ge/shared/services/api';
const BASE_URL_MWP = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

/**
 * get asset tasks by status "not completed"
 */
export const getAssetTasks = (assetIds = [], pageIdx) => {
  return request.get('/mwp/asset-task', {
    baseURL: BASE_URL_MWP,
    params: { assetIds: assetIds, pageIdx: pageIdx, pageSize: TaskListSize.SIZE },
  });
};

export const postAssetTasks = (assetIds = [], pageIdx) => {
  return request.post(
    '/mwp/asset-task',
    { assetIds: assetIds, pageIdx: pageIdx, pageSize: TaskListSize.SIZE },
    { baseURL: BASE_URL_MWP },
  );
};
