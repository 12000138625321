import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSection, StyledCollapsiblePanel } from '../../../task-template-shared';

export const CreateSendNotification = ({ collapsed }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <StyledCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{t('section_header.send_notification', 'Send Notification')}</h4>}
      plusIcon
    >
      <FormSection></FormSection>
    </StyledCollapsiblePanel>
  );
};

CreateSendNotification.propTypes = {
  collapsed: PropTypes.bool,
};

CreateSendNotification.defaultProps = {
  collapsed: true,
};
