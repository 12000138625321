import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from '@ge/components/input';
import { DateTimeFormats } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { StatusColor } from '@ge/tokens/colors/colors';
const TimepickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 13px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

const StyledInput = styled(Input)`
  ${({ errors, id, fieldArray }) => {
    const { fieldName, errorIndex, errorName } = fieldArray ?? {};
    return errors[id] || errors[fieldName]?.[errorIndex]?.[errorName]
      ? `border-color: ${StatusColor.DANGER}`
      : '';
  }}

  &[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(99%) sepia(0%) saturate(6040%) hue-rotate(291deg) brightness(129%) contrast(90%);
  }
`;
const TimeField = ({
  date: _date,
  errors,
  onChange,
  setDuration,
  readOnly,
  required,
  value: _value,
  name,
  fieldArray,
}) => {
  const {
    taskState: { timezone },
  } = useContext(TaskContext);
  const [value, setValue] = useState();

  const getTime = (date) =>
    date
      ? dayjs(date)
          .tz(timezone)
          .format(DateTimeFormats.DEFAULT_TIME)
      : '';
  const elementRef = useRef();
  // time field is a date object, defaults to current date if a date prop is not passed
  const [date, setDate] = useState(dayjs(_date || new Date()).format(DateTimeFormats.DEFAULT_DATE));
  useEffect(() => {
    setDate(dayjs(_date).format(DateTimeFormats.DEFAULT_DATE));
    const updateDate =
      elementRef.current.value &&
      dayjs(`${dayjs(_date).format(DateTimeFormats.DEFAULT_DATE)} ${elementRef.current.value}`).tz(
        timezone,
        true,
      );
    if (dayjs(updateDate).isValid()) {
      setValue(updateDate);
      onChange(updateDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_date]);
  useEffect(() => {
    // clear time field, only fires when the clear button is hit in timing component
    if (!_value) setValue('');
    if (dayjs(_value).isValid()) setValue(_value);
  }, [_value]);
  const handleTimeChange = ({ target: { value: timeValue } }) => {
    const match = timeValue?.match(/^(\d{1,2}):(\d{2})(?::(\d{2}))?$/);
    if (!timeValue || !match) return null;
    const updateDate = dayjs(`${date} ${timeValue}`).tz(timezone, true);
    if (dayjs(updateDate).isValid()) setValue(updateDate);
    setDuration(updateDate);
    onChange(updateDate);
  };
  return (
    <StyledInput
      disabled={readOnly}
      errors={errors}
      id={name}
      fieldArray={fieldArray}
      onChange={handleTimeChange}
      required={required}
      type="time"
      value={getTime(value)}
      ref={elementRef}
    />
  );
};
TimeField.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  errors: PropTypes.object,
  onChange: PropTypes.func,
  setDuration: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  fieldArray: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
};
TimeField.defaultProps = {
  date: '',
  errors: undefined,
  onChange: () => {},
  setDuration: () => {},
  readOnly: false,
  required: false,
  fieldArray: null,
  name: '',
  value: '',
};
export const TimeMetaField = ({
  date,
  defaultValue,
  label,
  metadata,
  name,
  rules,
  fieldArray,
  setDuration,
}) => {
  const { readOnly, required } = metadata;
  const { control, errors } = useFormContext();
  if (required) rules = { ...rules, required };
  return (
    <TimepickerWrapper>
      <StyledLabel htmlFor={name}>
        {label}
        {required && <>*</>}
      </StyledLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        render={({ onChange, value }) => (
          <TimeField
            date={date}
            errors={errors}
            metadata={metadata}
            onChange={onChange}
            setDuration={setDuration}
            readOnly={readOnly}
            required={required}
            value={value}
            name={name}
            fieldArray={fieldArray}
          />
        )}
      />
    </TimepickerWrapper>
  );
};
TimeMetaField.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  label: PropTypes.string,
  metadata: PropTypes.instanceOf(Object).isRequired,
  rules: PropTypes.instanceOf(Object),
  fieldArray: PropTypes.instanceOf(Object),
  setDuration: PropTypes.func,
};
TimeMetaField.defaultProps = {
  date: '',
  defaultValue: undefined,
  label: '',
  rules: null,
  fieldArray: null,
  setDuration: () => {},
};
