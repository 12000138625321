import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';

import { SortDirection, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { sorter } from '@ge/util/metric-sorter';

import {
  createCaseAnalysisTemplate,
  updateCaseAnalysisTemplate,
  fetchAssetSignalMaps,
} from '../services';

const signalSourceType = {
  HISTORICAL: 'historical',
};

const SignalSourceMap = {
  [signalSourceType.HISTORICAL]: 'HistoricalMachineData',
};

export const useCreateCaseAnalysisTemplate = (opt) => {
  return useMutation(createCaseAnalysisTemplate, opt);
};

export const useUpdateAnalysisTemplate = (opt) => {
  return useMutation(updateCaseAnalysisTemplate, opt);
};

export const useCaseAnalysisTemplateQuery = ({ isActive }) => {
  const {
    isLoading,
    data: queryData,
    ...rest
  } = useQuery([QueryKey.ASSET_SIGNAL_MAPS], fetchAssetSignalMaps, {
    ...Config.EXECUTE_ONCE,
    enabled: Boolean(isActive),
  });

  const { assetSignalMap, signalMappings } = useMemo(() => {
    if (!isActive || isLoading) return {};

    return queryData?.data ?? {};
  }, [isActive, isLoading, queryData]);
  return { isLoading, ...rest, assetSignalMap, signalMappings };
};

export const useCaseAnalysisTemplateDetails = ({
  assetSignalMap,
  signalMappings,
  selectedAssetTypeId,
  selectedSignalMapIds,
}) => {
  const signalMaps = useMemo(() => {
    if (!assetSignalMap || !signalMappings) return [];

    const signalMapIds = selectedAssetTypeId
      ? Object.keys(assetSignalMap).filter(
          (key) => assetSignalMap[key].assetTypeId === selectedAssetTypeId,
        )
      : Object.keys(assetSignalMap);

    const signalMaps =
      signalMapIds.map((id) => {
        const { categoryId, dataMode } = assetSignalMap[id] ?? {};
        const mappings = signalMappings[id] ?? {};
        return {
          ...mappings,
          categoryId,
          signals:
            mappings.signals
              ?.map((signal) => {
                return {
                  ...signal,
                  categoryId,
                  signalSource: SignalSourceMap[dataMode],
                };
              })
              .sort(sorter('name', SortDirection.ASC)) ?? [],
        };
      }) ??
      [] ??
      [];

    return signalMaps;
  }, [assetSignalMap, signalMappings, selectedAssetTypeId]);

  const signalData = useMemo(() => {
    if (!signalMaps?.length || !selectedSignalMapIds?.length) {
      return [];
    }

    const signals = signalMaps
      .filter((v) => selectedSignalMapIds.includes(v.id))
      .reduce((result, v) => v.signals.reduce((acc, v) => ({ ...acc, [v.id]: v }), result), {});
    return Object.values(signals).sort(sorter('name', SortDirection.ASC));
  }, [signalMaps, selectedSignalMapIds]);

  return { signalMaps, signalData, selectedAssetTypeId };
};
