import { action, computed, thunk } from 'easy-peasy';

import merge from '@ge/util/deep-merge';

import { fetchAssetSummary } from '../../shared/services/summary';

// Define initial state
const defaultSummaryState = {
  assets: {
    total: 0,
    offline: 0,
  },
};

// Actions
const summaryActions = {
  /**
   * Reset state to defaults
   */
  // eslint-disable-next-line no-unused-vars
  resetSummary: action((state) => {
    state = Object.assign(state, defaultSummaryState);
  }),

  /**
   * Retrieve asset summary from API and update state.
   */
  fetchAssetSummary: thunk(async (actions, _, { fail }) => {
    try {
      const { assets } = await fetchAssetSummary();
      actions.updateAssets(assets);
    } catch (err) {
      fail(err);
    }
  }),

  updateAssets: action((state, payload) => {
    state.assets = merge(state.assets, payload);
  }),
};

// Listeners
const summaryListeners = {};

// Computed values
const summaryComputed = {
  getAssetSummary: computed((state) => () => state.assets),
};

// Compile the view store object for export
const summaryModel = {
  ...defaultSummaryState,
  ...summaryActions,
  ...summaryComputed,
  ...summaryListeners,
};

export default summaryModel;
