import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';

import { TaskTemplateModes, ErpType } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import { useDynamicSection } from '../../../../../hooks/use-dynamic-section';

import { CreateDynamicSection } from './create-dynamic-section';
import { ViewDynamicSection } from './view-dynamic-section';

export const DynamicSection = ({
  section = {},
  task = {},
  templateMode,
  erpType = ErpType.SERVICE_REQUEST,
  erpData = null,
  hideHeading,
  isTaskSelected,
}) => {
  const { bulkTask } = useContext(EntityDetailsContext);

  const metadataRows = useDynamicSection({
    erpData,
    erpType,
    section,
    task,
    bulkTask,
  });
  if (!metadataRows) {
    return null;
  }

  switch (templateMode) {
    case TaskTemplateModes.CREATE:
      return (
        <CreateDynamicSection
          title={section.title}
          metadata={section.metadata}
          metadataByRows={metadataRows}
          task={task}
          erpType={erpType}
          erpData={erpData}
          hideHeading={hideHeading}
          bulkTask={bulkTask}
          isTaskSelected={isTaskSelected}
        />
      );
    case TaskTemplateModes.VIEW:
      return (
        <ViewDynamicSection
          metadataByRows={metadataRows}
          erpType={erpType}
          erpData={erpData}
          task={task}
        />
      );
    default:
      return null;
  }
};

DynamicSection.propTypes = {
  section: PropTypes.object,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  erpType: PropTypes.string,
  erpData: PropTypes.object,
  hideHeading: PropTypes.bool,
  isTaskSelected: PropTypes.bool,
};
