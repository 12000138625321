import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tabs, Tab } from '@ge/components/tabs';
import { EntityType, SiteTypeTabs } from '@ge/models/constants';

import { AnalyzeAssetsHeader } from '../components/asset/assets-header';
import { AnalyzeAssetsSolar } from '../components/asset/solar/assets-solar';
import { AnalyzeAssetsStorage } from '../components/asset/storage/assets-storage';
import { AnalyzeAssetsWind } from '../components/asset/wind/assets-wind';
import { DashboardDateFilter } from '../components/dashboard/dashboard-date-filter';

import { AnalyzeFilterPanel } from './analyze-filter-panel';

export const FilterBarStyled = styled.div.attrs({ className: 'FilterBar' })`
  margin-bottom: -30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

// TODO: wire these up
const siteId = '5000615';

export const AnalyzeAssets = () => {
  const { ready, t } = useTranslation(['general'], {
    useSuspense: false,
  });
  const [type, setType] = useState();

  return (
    <PageContainer i18nReady={ready}>
      <AnalyzeFilterPanel entityId={siteId} entityType={EntityType.SITE} />
      <ScrollingContainer>
        <AnalyzeAssetsHeader type={type} />
        <FilterBarStyled>
          <DashboardDateFilter />
        </FilterBarStyled>
        <Tabs
          defaultTab={t(SiteTypeTabs.WIND).toLowerCase()}
          onChangeHandler={(val) => setType(val)}
        >
          <Tab label={t(SiteTypeTabs.WIND)}>
            <AnalyzeAssetsWind />
          </Tab>
          <Tab label={t(SiteTypeTabs.SOLAR)}>
            <AnalyzeAssetsSolar />
          </Tab>
          <Tab label={t(SiteTypeTabs.STORAGE)}>
            <AnalyzeAssetsStorage />
          </Tab>
        </Tabs>
      </ScrollingContainer>
    </PageContainer>
  );
};
