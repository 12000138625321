import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { DateTimeFormats, TemplateDateValue } from '@ge/models/constants';
import { StyledDateContainer } from '@ge/shared/components/tasks/task-template-shared';
import { getFriendlyTimestamp } from '@ge/shared/util';
import { StatusColor } from '@ge/tokens/colors/colors';

const formatDate = (date) => (date ? dayjs(date).format(DateTimeFormats.DEFAULT_DATE) : '');
const getTime = (date) => (date ? dayjs(date).format(DateTimeFormats.DEFAULT_TIME) : '');

const DateTimeContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  label,
  .label {
    color: ${({ theme }) => theme?.input?.labelTextColor};
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 5px;

    &.time-zone {
      margin-bottom: 0;
    }
  }
`;

const InputContainer = styled.div`
  border: 1px solid transparent;
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  input {
    border-radius: 0;
    font-size: 12px;
    font-weight: 300;
  }

  .date-picker {
    .date-picker-input {
      border-radius: 0;
      width: 75px;
    }
  }

  .time-picker {
    margin-left: 8px;
  }

  > * {
    + * {
      margin-left: 10px;
    }
  }
`;

// TODO: move some of this out to a place that can be shared with other date fields
const StyledInput = styled(Input)`
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
  &[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(99%) sepia(0%) saturate(6040%) hue-rotate(291deg) brightness(129%) contrast(90%);
  }
`;

const StyledInputIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-right: 4px;
`;

// eslint-disable-next-line react/display-name
const DatePickerIcon = forwardRef(({ onClick }, refDate) => (
  <button type="button" className="task-dialog-date" onClick={onClick} ref={refDate}>
    <StyledInputIcon icon={Icons.CALENDAR} size={10} />
  </button>
));

DatePickerIcon.propTypes = {
  onClick: PropTypes.func,
};

DatePickerIcon.propTypes = {
  onClick: () => {},
};

const DateTimeField = ({
  className,
  errors,
  label,
  metadata,
  onChange,
  validators,
  value: _value,
  timezone,
  filterDate,
  name,
}) => {
  // state
  const [value, setValue] = useState();

  const { readOnly, required } = metadata;
  const { earliestDate } = validators?.find(({ earliestDate }) => Boolean(earliestDate)) ?? {};
  const minDate = earliestDate === TemplateDateValue.CURRENT_DATE ? new Date() : null;

  // MVP0 - temporarily getting tz label to show next to time input
  const timeZone = getFriendlyTimestamp(new Date(), timezone).replace(/.*(\([^)]+\)).*/, '$1');

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleDateChange = (dateValue) => {
    if (!dateValue) {
      return;
    }

    const updateValue = value ? new Date(value) : new Date();
    updateValue.setFullYear(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());

    setValue(updateValue);

    onChange(updateValue);
  };

  const handleTimeChange = ({ target: { value: timeValue } }) => {
    // seems like there should be an easier way of doing this
    const match = timeValue?.match(/^(\d{1,2}):(\d{2})(?::(\d{2}))?$/);

    if (!match) {
      return;
    }

    const [, ...time] = match;

    const updateValue = value ? new Date(value) : new Date();
    updateValue.setHours(...time.filter(Boolean).map(Number));

    setValue(updateValue);

    onChange(updateValue);
  };

  return (
    <DateTimeContainer className={className}>
      {label && (
        <div className="label">
          {label}
          {required && <>*</>}
        </div>
      )}
      <InputContainer errors={errors} id={name}>
        <StyledDateContainer>
          <DatePicker
            customInput={
              <div className="date-picker">
                <Input
                  className="date-picker-input"
                  value={formatDate(value)}
                  onChange={() => {}}
                />
                <DatePickerIcon />
              </div>
            }
            disabled={readOnly}
            minDate={minDate}
            onChange={handleDateChange}
            required={required}
            selected={value}
            startDate={value}
            filterDate={filterDate}
          />
        </StyledDateContainer>
        <StyledInput
          className="time-picker"
          disabled={readOnly}
          errors={errors}
          onChange={handleTimeChange}
          required={required}
          type="time"
          value={getTime(value)}
        />
        <span className="label time-zone">{timeZone}</span>
      </InputContainer>
    </DateTimeContainer>
  );
};

DateTimeField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  metadata: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func,
  validators: PropTypes.instanceOf(Array),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
  timezone: PropTypes.string,
  filterDate: PropTypes.func,
  name: PropTypes.string,
};

DateTimeField.defaultProps = {
  className: undefined,
  errors: undefined,
  label: undefined,
  onChange: () => {},
  validators: [],
  value: '',
};

export const DateTimeMetaField = ({
  defaultSelection,
  defaultValue: _defaultValue,
  label,
  metadata,
  name,
  validators,
  timezone,
  filterDate,
}) => {
  const { control, errors } = useFormContext();
  const { required } = metadata;
  const rules = {
    required: required,
    validate: (value) => {
      return required ? value : !required;
    },
  };

  let defaultValue = null;

  if (_defaultValue) {
    defaultValue = new Date(_defaultValue);
  } else if (defaultSelection) {
    switch (defaultSelection) {
      // TODO: add support for various keywords (like currentDate) if more added
      case TemplateDateValue.CURRENT_DATE:
        defaultValue = new Date();
        break;
      default:
        defaultValue = new Date(defaultSelection);
        break;
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }) => (
        <DateTimeField
          errors={errors}
          label={label}
          metadata={metadata}
          onChange={onChange}
          validators={validators}
          value={value}
          timezone={timezone}
          filterDate={filterDate}
          name={name}
        />
      )}
    />
  );
};

DateTimeMetaField.propTypes = {
  defaultSelection: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string,
  ]),
  defaultValue: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  metadata: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.instanceOf(Array),
  timezone: PropTypes.string,
  filterDate: PropTypes.func,
};

DateTimeMetaField.defaultProps = {
  defaultSelection: '',
  defaultValue: null,
  label: undefined,
  validators: [],
};
