// TODO: add tool tip/accessibility cues to toggle buttons?
// TODO: find out if toggle can contain multiple of same type,
// which would reqiure moving to index-based instead of type-based differentiator

import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { AnalyzeLocators } from '@ge/models/data-locators';

import { chartIconPropType } from '../../models';

import { DashboardChartIcon } from './dashboard-shared';

const Container = styled.div`
  display: flex;

  > * {
    + * {
      margin-left: 2px;
    }
  }
`;

const ToggleButton = styled.button`
  background: ${({ theme }) => theme.analyze.regionCard.toggleButtonInactiveColor};
  border-radius: 2px;
  color: ${({ theme }) => theme.analyze.regionCard.toggleButtonInactiveTextColor};
  padding: 3px 10px 4px;

  svg {
    fill: ${({ theme }) => theme.analyze.regionCard.toggleButtonInactiveTextColor};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.analyze.regionCard.toggleButtonInactiveColor};
    color: ${({ theme }) => theme.analyze.regionCard.toggleButtonInactiveTextColor};
    svg {
      fill: ${({ theme }) => theme.analyze.regionCard.toggleButtonInactiveTextColor};
    }
  }
  &.active {
    background: ${({ theme }) => theme.analyze.regionCard.toggleButtonActiveColor};
    color: ${({ theme }) => theme.analyze.regionCard.toggleButtonActiveTextColor};
    svg {
      fill: ${({ theme }) => theme.analyze.regionCard.toggleButtonActiveTextColor};
    }
  }
`;

const compareTypes = (a, b) => a && a.id && a.type && a.id === b.id && a.type === b.type;

export const DashboardChartToggle = ({
  currentType: _currentType,
  onToggle,
  setChartToggle,
  types,
}) => {
  const [currentType, setCurrentType] = useState(null);

  // effects
  // reset state when types change
  useEffect(() => setCurrentType(_currentType || (types && types[0])), [_currentType, types]);

  if (!(types && types.length)) {
    return null;
  }

  // wrap in useCallback?
  const handleToggle = (type) => {
    onToggle(type);
    setCurrentType(type);
    setChartToggle(type.id === 'alternate' && type.type === 'column');
  };

  return (
    <Container data-testid={AnalyzeLocators.ANALYZE_TOGGLE_BUTTON_CONTAINER}>
      {types.map((type) => (
        <ToggleButton
          data-testid={AnalyzeLocators.ANALYZE_TOGGLE_BUTTON.concat(type.type)}
          className={compareTypes(currentType, type) ? 'active' : ''}
          disabled={type.disabled}
          key={type.id}
          onClick={() => handleToggle(type)}
        >
          <DashboardChartIcon
            type={type.type}
            viewbox={
              type.type === ChartType.COLUMN_SITE_ACTIVE || type.type === ChartType.COLUMN_DURATION
                ? '1 1 10 10'
                : '0 0 20 20'
            }
          />
        </ToggleButton>
      ))}
    </Container>
  );
};

DashboardChartToggle.propTypes = {
  currentType: chartIconPropType,
  onToggle: PropTypes.func.isRequired,
  setChartToggle: PropTypes.func,
  types: PropTypes.arrayOf(chartIconPropType).isRequired,
};

DashboardChartToggle.defaultProps = {
  currentType: undefined,
  setChartToggle: () => {},
};

export default DashboardChartToggle;
