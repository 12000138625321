import { action, computed } from 'easy-peasy';

// Define initial state
const defaultNeedsAttentionState = {
  assetsData: {},
  attentionError: null,
  assetsCount: {
    assetsTrippedCount: 0,
    assetsImpactedCount: 0,
    assetsStoppedCount: 0,
    assetsRepairCount: 0,
    assetsMaintenanceCount: 0,
    assetsNetcomCount: 0,
  },
  globalCount: 0,
  needsAttention: computed((state) => state.assetsData.length > 0),
};

// Actions
const needsAttentionActions = {
  /* Reset state to defaults */

  resetNeedsAttentionState: action(
    (state) => (state = Object.assign(state, defaultNeedsAttentionState)),
  ),

  setAttentionError: action((state, payload) => {
    state.attentionError = payload;
  }),

  setAssetsData: action((state, payload) => {
    let assetsArr = [];

    Object.entries(payload?.assets).forEach((x) => {
      const sortOrder = ['tripped', 'impacted', 'stopped', 'repair', 'maintenance', 'no_comm'];
      let assetId = x[0];
      let assetObj = x[1];
      assetObj.assetId = assetId;

      if (
        (assetObj?.state?.value === 'tripped' && assetObj?.connection?.value === 'connected') ||
        (assetObj?.state?.value === 'impacted' && assetObj?.connection?.value === 'connected') ||
        (assetObj?.state?.value === 'stopped' && assetObj?.connection?.value === 'connected') ||
        (assetObj?.state?.value === 'repair' && assetObj?.connection?.value === 'connected') ||
        (assetObj?.state?.value === 'maintenance' && assetObj?.connection?.value === 'connected')
      ) {
        assetsArr.push(assetObj);
      } else if (!Object.hasOwn(assetObj, 'state') && assetObj?.connection?.value === 'no_comm') {
        assetObj.state = { value: 'no_comm' };
        assetsArr.push(assetObj);
      } else if (assetObj?.connection?.value === 'no_comm') {
        assetObj.state = { value: 'no_comm' };
        assetsArr.push(assetObj);
      }

      assetsArr.sort((a, b) => sortOrder.indexOf(a.state.value) - sortOrder.indexOf(b.state.value));
    });
    state.assetsData = assetsArr;
  }),

  setAssetsTrippedCount: action((state, payload) => {
    state.assetsCount.assetsTrippedCount = payload.filter(
      (asset) => asset.state?.value === 'tripped',
    ).length;
  }),

  setAssetsImpactedCount: action((state, payload) => {
    state.assetsCount.assetsImpactedCount = payload.filter(
      (asset) => asset.state?.value === 'impacted',
    ).length;
  }),

  setAssetsStoppedCount: action((state, payload) => {
    state.assetsCount.assetsStoppedCount = payload.filter(
      (asset) => asset.state?.value === 'stopped',
    ).length;
  }),

  setAssetsRepairCount: action((state, payload) => {
    state.assetsCount.assetsRepairCount = payload.filter(
      (asset) => asset.state?.value === 'repair',
    ).length;
  }),

  setAssetsMaintenanceCount: action((state, payload) => {
    state.assetsCount.assetsMaintenanceCount = payload.filter(
      (asset) => asset.state?.value === 'maintenance',
    ).length;
  }),

  setAssetsNetcomCount: action((state, payload) => {
    state.assetsCount.assetsNetcomCount = payload.filter(
      (asset) => asset.state?.value === 'no_comm',
    ).length;
  }),

  setGlobalCount: action((state) => {
    state.globalCount = Object.values(state.assetsCount).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
  }),
};

// Compile the view store object for export
const needsAttentionModel = {
  ...defaultNeedsAttentionState,
  ...needsAttentionActions,
};

export default needsAttentionModel;
