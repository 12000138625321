import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { DataLoaderType } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { globalColors } from '@ge/tokens/colors/colors';

import { NumberMetaField } from '../../meta-fields/number-meta-field';
import { DisplayField, DisplayLabel } from '../task-template-shared';

const DataLoaderContainer = styled.div`
  margin: 0 auto;

  img {
    height: 25vh;
  }
`;

const StyledNumberMetaField = styled(NumberMetaField)`
  color: ${(props) => props.theme.entityDetails.tasks.details.inputColor};
  width: 45px;
  height: 25px;
`;

const StyledEstHoursNumberMetaField = styled(StyledNumberMetaField)`
  border: ${(props) =>
    props.workStandards !== null &&
    props.workStandards?.labour !== null &&
    props.defaultWorkStandard === props.val &&
    '2px solid #2b5c6b'};
  border: ${(props) =>
    props.val === 0 && props.minutesVal === 0 && `1px solid ${globalColors.red2}`};
  border-radius: ${(props) =>
    props.workStandards !== null &&
    props.workStandards?.labour !== null &&
    props.workStandards?.labour !== null &&
    props.val !== 0 &&
    '3px'};
`;

const StyledEstMinutesNumberMetaField = styled(StyledNumberMetaField)`
  border: ${(props) =>
    props.workStandards !== null &&
    props.workStandards?.labour !== null &&
    props.defaultWorkStandard === props.val &&
    '2px solid #2b5c6b'};
  border-radius: ${(props) =>
    props.workStandards !== null &&
    props.workStandards?.labour !== null &&
    props.workStandards?.labour !== null &&
    props.hoursVal !== 0 &&
    '3px'};
`;

const StyledEstTechsNumberMetaField = styled(StyledNumberMetaField)`
  border: ${(props) =>
    props.workStandards !== null &&
    props.workStandards?.labour !== null &&
    props.workStandards?.labour?.numberOfTechs === props.val &&
    '2px solid #2b5c6b'};
  border-radius: ${(props) =>
    props.workStandards !== null &&
    props.workStandards?.labour !== null &&
    props.workStandards?.labour !== null &&
    props.workStandards?.labour?.numberOfTechs === props.val &&
    '3px'};
`;

export const LaborEstimates = ({ metadata, mode, task, workStandardsData }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const { register, setValue, trigger, unregister, watch } = useFormContext();

  const { workStandards } = useStoreState((store) => store.tasks);
  const { isWorkStand, setIsWorkStand } = useContext(EntityDetailsContext);

  const watchEstDurationHours = watch('estDurationHours') || 0;
  const watchEstDurationMinutes = watch('estDurationMinutes') || 0;
  const watchEstTechs = watch('estTechs') || 0;
  const watchLaborHours = watch('laborHours');
  const watchLaborMinutes = watch('laborMinutes');

  const parseDuration = (num) => {
    let str = ('0' + (Math.floor(num) % 24)).slice(-2) + ':' + ((num % 1) * 60 + '0').slice(0, 2);
    return str.split(':');
  };

  useEffect(() => {
    if (!(task?.estDurationHours || task?.estDurationHours === 0)) {
      setValue('estDurationHours', parseInt(metadata.estimatedDurationHours.defaultSelection));
    }
  }, [metadata?.estimatedDurationHours, task?.estDurationHours, setValue]);

  useEffect(() => {
    const parsedHours = parseInt(watchEstDurationHours);
    const parsedMinutes = parseInt(watchEstDurationMinutes);
    const parsedTechs = parseInt(watchEstTechs);
    register('laborHours');
    register('laborMinutes');
    if (parsedHours >= 0 && parsedMinutes >= 0 && parsedTechs >= 0) {
      const durationMinutes = (parsedHours * 60 + parsedMinutes) * parsedTechs;
      setValue('laborHours', Math.floor(durationMinutes / 60));
      setValue('laborMinutes', durationMinutes % 60);
    } else {
      setValue('laborHours', 0);
      setValue('laborMinutes', 0);
    }
    trigger(['estDurationHours', 'estDurationMinutes']);
  }, [
    register,
    setValue,
    trigger,
    unregister,
    watchEstDurationHours,
    watchEstDurationMinutes,
    watchEstTechs,
  ]);

  useEffect(() => {
    // if editing, skip default value assignments
    if (mode !== 'edit') {
      if (workStandards === null || workStandards?.labour === null || workStandards.length === 0) {
        setValue('estDurationHours', parseInt(metadata.estimatedDurationHours.defaultSelection), {
          shouldValidate: true,
          shouldDirty: false,
        });
        setValue(
          'estDurationMinutes',
          parseInt(metadata.estimatedDurationMinutes.defaultSelection),
          {
            shouldValidate: true,
            shouldDirty: false,
          },
        );
        setValue('estTechs', parseInt(metadata.estTechs.defaultSelection), {
          shouldValidate: true,
          shouldDirty: false,
        });
      } else if (workStandards !== null && workStandards?.labour !== null) {
        setValue(
          'estDurationHours',
          Number(parseDuration(workStandards?.labour?.estimatedDuration)[0]),
          {
            shouldValidate: true,
            shouldDirty: true,
          },
        );
        setValue(
          'estDurationMinutes',
          Number(parseDuration(workStandards?.labour?.estimatedDuration)[1], {
            shouldValidate: true,
            shouldDirty: true,
          }),
        );
        setValue('estTechs', workStandards?.labour?.numberOfTechs, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  }, [
    setValue,
    workStandards,
    metadata.estTechs.defaultSelection,
    metadata.estimatedDurationHours.defaultSelection,
    metadata.estimatedDurationMinutes.defaultSelection,
    mode,
  ]);

  useEffect(() => {
    trigger(['estDurationHours', 'estDurationMinutes']);
  }, [trigger, watchEstDurationHours, watchEstDurationMinutes]);

  const getEstDurationHours = useCallback(() => {
    let estDurationHours;
    if (workStandardsData && workStandardsData?.labour?.estimatedDuration) {
      setValue(
        'estDurationHours',
        Number(parseDuration(workStandardsData?.labour?.estimatedDuration)[0]),
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    } else {
      estDurationHours =
        task?.estDurationHours || task?.estDurationHours === 0
          ? parseInt(task.estDurationHours)
          : parseInt(metadata.estimatedDurationHours.defaultSelection);
      setValue('estDurationHours', estDurationHours, {
        shouldValidate: true,
        shouldDirty: false,
      });
    }
    return estDurationHours;
  }, [
    metadata.estimatedDurationHours.defaultSelection,
    setValue,
    task.estDurationHours,
    workStandardsData,
  ]);

  const getEstDurationMinutes = useCallback(() => {
    let estDurationMinutes;
    if (workStandardsData && workStandardsData?.labour?.estimatedDuration) {
      setValue(
        'estDurationMinutes',
        Number(parseDuration(workStandardsData?.labour?.estimatedDuration)[1]),
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    } else {
      estDurationMinutes =
        task?.estDurationMinutes || task?.estDurationMinutes === 0
          ? parseInt(task.estDurationMinutes)
          : parseInt(metadata.estimatedDurationMinutes.defaultSelection);
      setValue('estDurationMinutes', estDurationMinutes, {
        shouldValidate: true,
        shouldDirty: false,
      });
    }
    return estDurationMinutes;
  }, [
    metadata.estimatedDurationMinutes.defaultSelection,
    setValue,
    task.estDurationMinutes,
    workStandardsData,
  ]);

  const getEstTechs = useCallback(() => {
    let estTechs;
    if (workStandardsData && workStandardsData?.labour?.numberOfTechs) {
      setValue('estTechs', workStandardsData?.labour?.numberOfTechs, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      estTechs =
        task?.estTechs || task?.estTechs === 0
          ? parseInt(task.estTechs)
          : parseInt(metadata.estTechs.defaultSelection);
      setValue('estTechs', estTechs, {
        shouldValidate: true,
        shouldDirty: false,
      });
    }
    return estTechs;
  }, [metadata.estTechs.defaultSelection, setValue, task.estTechs, workStandardsData]);

  useEffect(() => {
    if (isWorkStand) {
      getEstDurationHours();
      getEstDurationMinutes();
      getEstTechs();
      setIsWorkStand(false);
    }
  }, [
    workStandardsData,
    getEstDurationHours,
    getEstDurationMinutes,
    getEstTechs,
    setIsWorkStand,
    isWorkStand,
  ]);

  if (workStandards?.isLoading)
    return (
      <DataLoaderContainer>
        <DataLoader type={DataLoaderType.GRID} isLoading={workStandards?.isLoading} />
      </DataLoaderContainer>
    );

  return (
    <>
      <div className="field-group">
        {!metadata?.estimatedDurationHours.hidden && (
          <StyledEstHoursNumberMetaField
            defaultValue={getEstDurationHours}
            label={t('form.est_duration', 'Est. Duration')}
            isInteger
            metadata={metadata.estimatedDurationHours?.[mode]}
            max={metadata.estimatedDurationHours?.max}
            min={parseInt(watchEstDurationHours) == 0 ? 1 : 0}
            name="estDurationHours"
            val={watchEstDurationHours}
            minutesVal={watchEstDurationMinutes}
            placeholder="0"
            workStandards={workStandards}
            defaultWorkStandard={Number(parseDuration(workStandards?.labour?.estimatedDuration)[0])}
            rules={
              !parseInt(watchEstDurationHours) && !parseInt(watchEstDurationMinutes)
                ? {
                    required: metadata.estimatedDurationHours?.[mode].required,
                    max: metadata.estimatedDurationHours?.max,
                    min: 1,
                  }
                : { max: metadata.estimatedDurationHours?.max }
            }
          />
        )}
        {!metadata?.estimatedDurationMinutes.hidden && (
          <StyledEstMinutesNumberMetaField
            defaultValue={getEstDurationMinutes}
            isInteger
            metadata={metadata.estimatedDurationMinutes?.[mode]}
            max={metadata.estimatedDurationMinutes?.max}
            min={metadata.estimatedDurationMinutes?.min}
            name="estDurationMinutes"
            val={watchEstDurationMinutes}
            hoursVal={watchEstDurationHours}
            placeholder="0"
            workStandards={workStandards}
            defaultWorkStandard={Number(parseDuration(workStandards?.labour?.estimatedDuration)[1])}
            rules={
              !parseInt(watchEstDurationHours) && !parseInt(watchEstDurationMinutes)
                ? {
                    required: metadata.estimatedDurationMinutes?.[mode].required,
                    max: metadata.estimatedDurationMinutes?.max,
                    min: 1,
                  }
                : { max: metadata.estimatedDurationMinutes?.max }
            }
          />
        )}
      </div>
      <div className="field-group">
        {!metadata?.estTechs.hidden && (
          <StyledEstTechsNumberMetaField
            defaultValue={getEstTechs}
            isInteger
            metadata={metadata.estTechs?.create}
            label={t('form.est_techs', 'Est. Techs')}
            max={metadata.estTechs?.max}
            min={metadata.estTechs?.min}
            name="estTechs"
            val={watchEstTechs}
            placeholder="0"
            workStandards={workStandards}
          />
        )}
      </div>
      <div>
        <DisplayLabel>{t('form.labor_hours', 'Labor Hours')}</DisplayLabel>
        <DisplayField className="read-only">
          <span>
            {watchLaborHours} {t('form.hrs', 'Hrs')}
          </span>
          <span>
            {watchLaborMinutes} {t('form.min', 'Min')}
          </span>
        </DisplayField>
      </div>
    </>
  );
};

LaborEstimates.propTypes = {
  metadata: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  task: PropTypes.object,
  workStandardsData: PropTypes.object,
};

LaborEstimates.defaultProps = {
  task: {},
};
