import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

// keyframes are currently defined in the web-client, could move into packages so can be shared here too
export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const IconAnimation = {
  rotate: css`
    animation: ${rotate} 2s linear infinite;
  `, // can add support for different speeds
};

export const StyledIcon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.color};
  &:hover {
    fill: ${(props) => props.hover};
  }
`;

export const MultiPathIcons = ({
  className,
  color,
  width,
  height,
  icon,
  rotate,
  viewbox,
  animate,
  hover,
  transform,
}) => (
  <StyledIcon
    animate={animate}
    color={color}
    width={`${width}px`}
    height={`${height}px`}
    viewBox={viewbox}
    className={className}
    rotate={rotate}
    hover={hover}
  >
    {icon.length > 0 && <path d={icon[0]} id="Shape" />}
    {icon.length > 1 && <path d={icon[1]} id="Path" />}
    {icon.length > 2 && <path d={icon[2]} id="Path-Copy" transform={transform} />}
  </StyledIcon>
);

MultiPathIcons.propTypes = {
  animate: PropTypes.oneOf(Object.keys(IconAnimation)),
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  hover: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  icon: PropTypes.array.isRequired,
  rotate: PropTypes.number,
  viewbox: PropTypes.string,
  transform: PropTypes.string,
};

MultiPathIcons.defaultProps = {
  className: null,
  rotate: 0,
  viewbox: '0 0 25 25',
  hover: '',
  width: 15,
  height: 15,
};
