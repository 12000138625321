import { PropTypes } from 'prop-types';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import useDismissListeners from '@ge/hooks/use-dismiss-listeners';
import { elevations } from '@ge/tokens/elevations';

import { ScrollingContainer } from '../scrolling-container';

const FILTER_ICON_SIZE_PX = 18;
const CHEVRON_ICON_SIZE_PX = 10;

const baseCSS = css`
  border-radius: 0 4px 4px 0;
  background: ${(props) => props.theme.filterPanel.backgroundColor};
  border-top: solid 2px ${(props) => props.theme.filterPanel.borderColor};
  box-shadow: ${(props) => props.theme.filterPanel.boxShadow};
`;

const ClearButton = styled(Button)`
  visibility: ${(props) => (props.showButton ? 'visible' : 'hidden')};
`;

const StyledFilterPanel = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  z-index: ${elevations.P10};
`;

const FilterTab = styled.button`
  ${baseCSS};
  min-width: 54px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  outline: none;
  svg {
    margin-right: 5px;
  }
  &.applied {
    background: ${({ theme }) => theme.filterPanel.appliedBackgroundColor};
    color: ${({ theme }) => theme.filterPanel.iconColor};
    font-size: 12px;
    font-weight: 500;
  }
  .count {
    padding: 0 2px;
  }
  .filter-panel-chevron-icon {
    flex: 1 0 ${CHEVRON_ICON_SIZE_PX}px;
  }
  .filter-panel-icon {
    flex: 1 0 ${FILTER_ICON_SIZE_PX}px;
  }
`;

const FilterContainer = styled.div`
  ${baseCSS};
  box-shadow: inset 20px -20px 30px 4px ${(props) => props.theme.analyze.filter.shadow};
  position: absolute;
  top: 0;
  width: 0;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  z-index: ${elevations.P100};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &.active {
    opacity: 1;
    width: 237px;
  }
  .title {
    display: flex;
    padding: 9px;
    color: ${(props) => props.theme.filterPanel.titleColor};
    text-align: left;
    align-items: center;
    h2 {
      flex: 1;
      line-height: 20px;
      margin-left: 10px;
    }
    .count {
      margin-right: 3px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    position: relative;
  }
  .footer {
    background: ${(props) => props.theme.filterPanel.footerBackgroundColor};
    padding: 10px 14px;
    display: flex;
    justify-content: space-between;
  }
`;

const FilterIcon = styled(Icon).attrs((props) => ({
  size: FILTER_ICON_SIZE_PX,
  icon: Icons.FILTER,
  color: props.theme.filterPanel.iconColor,
}))``;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: CHEVRON_ICON_SIZE_PX,
  icon: Icons.CHEVRON,
  color: props.theme.filterPanel.chevronColor,
}))``;

export const FilterPanel = ({
  children,
  filterCount,
  onApply,
  onDismiss,
  onClear,
  showClearButton,
}) => {
  const [isActive, setIsActiveState] = useState(false);
  const componentRef = useRef(null);
  const containerRef = useRef(null);

  /**
   * Toggle the visible state of the view selector panel
   */
  const toggleFilterPanel = () => {
    if (isActive) {
      closeFilterPanel();
    } else {
      openFilterPanel();
    }
  };

  /**
   * Show the panel.
   */
  const openFilterPanel = () => {
    setIsActiveState(true);
  };

  /**
   * Hide the panel and remove close listeners.
   */
  const closeFilterPanel = () => {
    setIsActiveState(false);
  };

  /**
   * Hide the panel and clears selection.
   */
  const dismissFilterPanel = () => {
    setIsActiveState(false);
    onDismiss();
  };

  /**
   * clear filter and close panel
   */
  const clearFilter = () => {
    onClear();
    setIsActiveState(false);
  };

  /**
   * apply filter and close panel
   */
  const applyFilter = () => {
    onApply();

    // handle filter apply
    toggleFilterPanel();
  };

  // Apply click-off dismiss handlers.
  useDismissListeners(componentRef, dismissFilterPanel);

  return (
    <StyledFilterPanel ref={componentRef}>
      <FilterTab
        className={Boolean(filterCount) && 'applied'}
        onClick={toggleFilterPanel}
        type="button"
      >
        <FilterIcon className="filter-panel-icon" />
        {Boolean(filterCount) && <span className="count">{filterCount}</span>}
        <ChevronIcon className="filter-panel-chevron-icon" rotate={-90} />
      </FilterTab>
      {/* TODO: Inject this into portals w/ CSSTranistion */}
      <FilterContainer className={isActive && 'active'}>
        <button className="title" onClick={toggleFilterPanel} type="button">
          <FilterIcon />
          <h2>Filters</h2>
          {Boolean(filterCount) && <span className="count">SELECTED {filterCount}</span>}
          <ChevronIcon rotate={90} />
        </button>
        <div className="content">
          <ScrollingContainer style={{ height: 'calc(90vh - 260px)' }} ref={containerRef} relative>
            {children}
          </ScrollingContainer>
        </div>
        <div className="footer">
          <ClearButton type="button" showButton={showClearButton} onClick={clearFilter}>
            Clear
          </ClearButton>
          <Button type="button" primary onClick={applyFilter}>
            Apply
          </Button>
        </div>
      </FilterContainer>
    </StyledFilterPanel>
  );
};

FilterPanel.propTypes = {
  children: PropTypes.instanceOf(Object),
  filterCount: PropTypes.number,
  onApply: PropTypes.func,
  onDismiss: PropTypes.func,
  onClear: PropTypes.func,
  showClearButton: PropTypes.bool,
};

FilterPanel.defaultProps = {
  children: null,
  filterCount: undefined,
  onApply: () => {},
  onDismiss: () => {},
  onClear: () => {},
  showClearButton: true,
};
