import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useCaseAnalysisTemplates from '@ge/feat-analyze/data-hooks/use-case-analysis-templates';
import {
  NAMESPACE,
  Fields,
  AnalysisTemplateOption,
} from '@ge/feat-analyze/models/configure-case-template';
import { SelectMetaField, TextMetaField } from '@ge/shared/components/meta-fields';

import { FormRow, FormCollapsiblePanel } from '../../shared';

const Select = styled(SelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const AnalyticsDetailForm = ({ isOpen }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { data } = useCaseAnalysisTemplates({ enabled: true });

  const options = useMemo(
    () => data?.analysisTemplateIds?.sort().map((v) => ({ label: v, value: v })) ?? [],
    [data],
  );

  if (!ready) return null;

  return (
    <FormCollapsiblePanel
      isOpen={isOpen}
      title={t('case_template.analytics_detail', 'Analytics Details')}
      allowOverflow={true}
    >
      <FormRow>
        <Select
          name={Fields.ANALYSIS_TEMPLATE_ID}
          label={t('case_template.analysis_template_id', 'Analysis Template ID')}
          placeholder={t(`case_template.analysis_template_id_placeholder`, 'Please select...')}
          defaultValue={AnalysisTemplateOption.DEFAULT}
          options={options}
          metadata={{ required: true }}
          minWidth={220}
          maxWidth={220}
        />
        <TextMetaField
          name={Fields.ANALYTICS_SOURCE}
          label={t('case_template.analytics_source', 'Analytics Source')}
          placeholder={t(`case_template.analytics_source_placeholder`, 'Type Source...')}
          defaultValue={''}
          metadata={{ required: true }}
        />
      </FormRow>
    </FormCollapsiblePanel>
  );
};

AnalyticsDetailForm.propTypes = {
  isOpen: PropTypes.bool,
};

export default AnalyticsDetailForm;
