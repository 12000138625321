import { getLoadable } from '@ge/shared/util';

// Define the available pages for lazy loading.
export const loadables = {
  reports: getLoadable(() => import('./reports')),
  list: getLoadable(() => import('./list')),
  upload: getLoadable(() => import('./upload')),
  preview: getLoadable(() => import('./preview-new')),
  execution: getLoadable(() => import('./execution')),
  postProcess: getLoadable(() => import('./post-process')),
  reportGeneration: getLoadable(() => import('./report-generation')),
  results: getLoadable(() => import('./results')),
  imageViewer: getLoadable(() => import('./image-viewer')),
  performanceDashboard: getLoadable(() => import('./performance')),
  analyticDashboard: getLoadable(() => import('./analytics')),
};
