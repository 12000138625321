import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateExpectedParts } from './create-expected-parts';
import { EditExpectedParts } from './edit-expected-parts';
import { ViewExpectedParts } from './view-expected-parts';

export const ExpectedPartsSection = ({
  section,
  task,
  templateMode,
  workStandardsData,
  setWorkStandardsData,
}) => {
  const { workStandards } = useStoreState((store) => store.tasks);
  const { collapsed, metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return (
          <CreateExpectedParts
            collapsed={workStandards !== null ? !collapsed : collapsed}
            metadata={metadata}
            templateMode={templateMode}
          />
        );
      case TaskTemplateModes.EDIT:
        return (
          <EditExpectedParts
            metadata={metadata}
            task={task}
            workStandardsData={workStandardsData}
            setWorkStandardsData={setWorkStandardsData}
            templateMode={templateMode}
          />
        );
      case TaskTemplateModes.VIEW:
        return <ViewExpectedParts metadata={metadata} task={task} />;
      default:
        return null;
    }
  }
};

ExpectedPartsSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  workStandardsData: PropTypes.object,
  setWorkStandardsData: PropTypes.func,
};

ExpectedPartsSection.defaultProps = {
  task: {},
  workStandardsData: {},
  setWorkStandardsData: () => {},
};
