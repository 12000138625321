import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';

import { StackCard } from '@ge/feat-manage/components/planning/month/cards/calendar-month-stack';
import { StackMenu } from '@ge/feat-manage/components/planning/month/cards/stack-card-menu';
import {
  BundledCountBadge,
  TaskCard,
  TaskCardEntity,
  TaskCardInfo,
} from '@ge/feat-manage/components/planning/shared';
import { DAY_PADDING, DragItemTypes, FilterValues } from '@ge/feat-manage/context';
import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { useCalendarCardDetails } from '@ge/feat-manage/data-hooks/use-calendar-card-details';
import { useCalendarCardMenu } from '@ge/feat-manage/data-hooks/use-calendar-card-menu';
import { TasksPriority } from '@ge/models/constants';

export const CalendarWeekCard = ({
  tasks,
  weekWidth,
  date,
  sidebarWidth,
  laneIndex,
  secondaryIndex,
  itemIndex,
  onDrop,
  onClick,
  draggable,
}) => {
  const {
    planningState: { handleDragStart, handleDragEnd, groupFilter, handleDragOver, range },
    serviceGroupIds,
  } = usePlanningContext();

  const menuRef = useRef(null);
  const anchorEl = useRef(null);

  const { menuShown, destroyStackMenu, showStackMenu } = useCalendarCardMenu();
  const { bundleInfo, sameAssetTasks, partialCompleted } = useCalendarCardDetails({
    tasks,
    date,
  });

  if (tasks.length === 0) return null;

  //sorting tasks based on priority
  tasks.sort(function (a, b) {
    let x = TasksPriority.indexOf(a.priority);
    let y = TasksPriority.indexOf(b.priority);
    //-1 for asc and 1 for desc
    let order = 1;
    if (x < y) return -1 * order;
    if (x > y) return 1 * order;
    return 0;
  });

  const position = sidebarWidth + itemIndex * weekWidth + DAY_PADDING - 2;
  const width = weekWidth - DAY_PADDING * 2;

  const task = tasks[0];

  return tasks.length > 1 && !sameAssetTasks ? (
    <StackCard
      tasks={tasks}
      position={position}
      width={width}
      laneIndex={laneIndex}
      secondaryIndex={secondaryIndex}
      itemIndex={itemIndex}
      handleDragStart={handleDragStart}
      onDrop={onDrop}
      onClick={onClick}
      draggable={draggable}
      bundlePresent={bundleInfo.bundlePresent}
      date={date}
    />
  ) : (
    <TaskCard
      position={position}
      width={width}
      className={classNames(
        `${partialCompleted ? 'partial' : task.status}`,
        'lasso-card',
        {
          draggable,
        },
        range.type,
      )}
      draggable={
        serviceGroupIds.length === 1
          ? draggable
          : groupFilter.L1.value === FilterValues.CREW || groupFilter.L2.value === FilterValues.CREW
          ? false
          : draggable
      }
      onMouseEnter={showStackMenu}
      onMouseLeave={destroyStackMenu}
      ref={anchorEl}
      onDragStart={() => {
        destroyStackMenu();
        handleDragStart(
          tasks,
          DragItemTypes.CARD,
          null,
          secondaryIndex !== null ? `${laneIndex}-${secondaryIndex}` : laneIndex,
        );
      }}
      onDragEnd={handleDragEnd}
      onDrop={onDrop}
      data-laneindex={laneIndex}
      data-secondaryindex={secondaryIndex}
      data-dayindex={itemIndex}
      onClick={(e) => onClick(e)}
      onDragOver={(e) => handleDragOver(e)}
    >
      <StackMenu
        open={menuShown}
        tasks={tasks}
        onClose={destroyStackMenu}
        ref={menuRef}
        anchorEl={anchorEl && anchorEl.current}
        date={date}
        bundlePresent={bundleInfo.bundlePresent}
      />
      <TaskCardEntity className="asset-name" task={task} />
      <TaskCardInfo task={task} sameAssetTaskCount={bundleInfo.sameAssetTaskCount} />
      <BundledCountBadge
        className={range.type}
        bundlePresent={bundleInfo.bundlePresent}
        bundleCount={bundleInfo.bundleCount}
      />
    </TaskCard>
  );
};

CalendarWeekCard.propTypes = {
  tasks: PropTypes.instanceOf(Array),
  weekWidth: PropTypes.number,
  date: PropTypes.instanceOf(Object), // dayjs
  sidebarWidth: PropTypes.number,
  laneIndex: PropTypes.number.isRequired,
  secondaryIndex: PropTypes.number,
  itemIndex: PropTypes.number.isRequired,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
};

CalendarWeekCard.defaultProps = {
  count: null,
  weekWidth: null,
  date: null,
  sidebarWidth: null,
  onDrop: () => {},
  onClick: () => {},
  secondaryIndex: null,
  draggable: false,
};
