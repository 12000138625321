import { action, computed, thunk, thunkOn } from 'easy-peasy';
import memoize from 'memoizerific';

import merge from '@ge/util/deep-merge';
import { sorter } from '@ge/util/metric-sorter';

import { fetchAnomalies, fetchAnomaliesWithMetrics, fetchAnomalyById } from '../services/anomalies';
import { fetchEventById, fetchEvents, fetchEventsWithMetrics } from '../services/event';
import { fetchEventHistory, fetchIssuesByCaseId } from '../services/issues';
import { filterByView } from '../util/view-utils';

// Define initial state
const defaultIssuesState = {
  issues: {},
  events: {},
  anomalies: {},
  history: {},
};

// Actions
const issuesActions = {
  resetIssues: action((state) => {
    state = Object.assign(state, defaultIssuesState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.events = {};
  }),

  // Issues
  fetchIssuesByCaseId: thunk(async (actions, payload, { fail }) => {
    try {
      const { issues } = await fetchIssuesByCaseId(payload);
      actions.updateIssues(issues);
    } catch (err) {
      fail(err);
    }
  }),

  updateIssues: action((state, payload) => {
    payload.forEach((issue) => {
      const existingIssue = state.issues[issue.id];
      state.issues[issue.id] = !existingIssue ? issue : merge(existingIssue, issue);
    });
  }),

  // Events

  fetchEvents: thunk(async (actions, payload, { fail }) => {
    try {
      const { sortMetric, sortDirection } = payload;

      const { events } = await fetchEvents(sortMetric, sortDirection);
      actions.updateEvents(events);
    } catch (err) {
      fail(err);
    }
  }),

  fetchEventsWithMetrics: thunk(async (actions, payload, { fail }) => {
    try {
      const { sortMetric, sortDirection } = payload;

      const { events } = await fetchEventsWithMetrics(sortMetric, sortDirection);
      actions.updateEvents(events);
    } catch (err) {
      fail(err);
    }
  }),

  fetchEventById: thunk(async (actions, payload, { fail }) => {
    try {
      const { event } = await fetchEventById(payload);
      actions.updateEvents([event]);
    } catch (err) {
      fail(err);
    }
  }),

  setEvents: action((state, payload) => {
    state.events = payload;
  }),

  updateEvents: action((state, payload) => {
    payload.forEach((event) => {
      const existingEvent = state.events[event.id];
      state.events[event.id] = !existingEvent ? event : merge(existingEvent, event);
    });
  }),

  fetchEventHistory: thunk(async (actions, payload, { fail }) => {
    try {
      const { history } = await fetchEventHistory(payload);
      actions.updateHistory(history);
    } catch (err) {
      fail(err);
    }
  }),

  updateHistory: action((state, payload) => {
    payload.forEach((event) => {
      const existingHistory = state.history[event.id];
      state.history[event.id] = !existingHistory ? event : merge(existingHistory, event);
    });
  }),

  // Anomalies

  fetchAnomalies: thunk(async (actions, _, { fail }) => {
    try {
      const { anomalies } = await fetchAnomalies();
      actions.updateAnomalies(anomalies);
    } catch (err) {
      fail(err);
    }
  }),

  fetchAnomaliesWithMetrics: thunk(async (actions, payload, { fail }) => {
    try {
      const { sortMetric, sortDirection } = payload;
      const { anomalies } = await fetchAnomaliesWithMetrics(sortMetric, sortDirection);
      actions.updateAnomalies(anomalies);
    } catch (err) {
      fail(err);
    }
  }),

  fetchAnomalyById: thunk(async (actions, payload, { fail }) => {
    try {
      const { anomaly } = await fetchAnomalyById(payload);
      actions.updateAnomalies([anomaly]);
    } catch (err) {
      fail(err);
    }
  }),

  updateAnomalies: action((state, payload) => {
    payload.forEach((anomaly) => {
      const existingAnomaly = state.anomalies[anomaly.id];
      state.anomalies[anomaly.id] = !existingAnomaly ? anomaly : merge(existingAnomaly, anomaly);
    });
  }),
};

// Listeners
const issuesListeners = {
  onFetchAssetEvents: thunkOn(
    (_, storeActions) => storeActions.assets.fetchAssetEvents,
    (actions, target) => {
      const { events } = target.result;
      actions.updateEvents(events);
    },
  ),
};

// Computed values
const issuesComputed = {
  getSortedEvents: computed(
    [(state) => state, (state, storeState) => storeState.view.currentView],
    (state, view) =>
      memoize(10)((sortMetric, sortDirection, assetId) => {
        let eventArray = assetId ? state.getEventsByAssetId(assetId) : Object.values(state.events);

        // Apply view filter if one is provided.
        if (view) {
          eventArray = filterByView(eventArray, view);
        }

        return eventArray.sort(sorter(sortMetric, sortDirection));
      }),
  ),

  getEventsBySiteId: computed(
    [(state) => state.events, (_, storeState) => storeState.view.currentView],
    (eventState, view) =>
      memoize(10)((siteId) => {
        let events = Object.values(eventState);

        if (view) {
          events = filterByView(events, view);
        }

        return events.filter(({ site }) => site?.id === siteId);
      }),
  ),

  getEventsByAssetId: computed((state) => (assetId) => {
    const eventArray = Object.values(state.events);
    return eventArray.filter((event) => assetId === event.asset.id);
  }),

  getEventById: computed((state) => (eventId) =>
    Object.values(state.events).find((event) => eventId === event.id),
  ),

  getEventHistory: computed((state) => () => Object.values(state.history)),

  getSortedHistory: computed((state) =>
    memoize(10)((sortMetric, sortDirection) => {
      const historyArray = Object.values(state.history);
      return historyArray.sort(sorter(sortMetric, sortDirection));
    }),
  ),

  getAnomalyById: computed((state) => (anomalyId) =>
    Object.values(state.anomalies).find((anomaly) => anomalyId === anomaly.id),
  ),

  getAnomaliesByAssetId: computed((state) => (assetId) => {
    const anomaliesArray = Object.values(state.anomalies);
    return anomaliesArray.filter((anomaly) => assetId === anomaly.asset.id);
  }),

  getAnomaliesBySiteId: computed((state) => (siteId) => {
    const anomaliesArray = Object.values(state.anomalies);
    return anomaliesArray.filter((anomaly) => anomaly.site && anomaly.site.id === siteId);
  }),

  getSortedAnomalies: computed(
    [(state) => state, (state, storeState) => storeState.view.currentView],
    (state, view) =>
      memoize(10)((sortMetric, sortDirection, assetId, siteId) => {
        let anomaliesArray = assetId // if asset id is present get anomalies for that asset (asset takes precedence over site)
          ? state.getAnomaliesByAssetId(assetId)
          : siteId // if site id is present get anomalies for that site
          ? state.getAnomaliesBySiteId(siteId)
          : Object.values(state.anomalies); // otherwise all anomalies

        // Apply view filter if one is provided.
        if (view) {
          anomaliesArray = filterByView(anomaliesArray, view);
        }

        return anomaliesArray.sort(sorter(sortMetric, sortDirection));
      }),
  ),

  getIssuesByCaseId: computed((state) => (caseId) => {
    const issuesArray = Object.values(state.issues);
    return issuesArray.filter((issue) => caseId === issue.caseId);
  }),
};

const monitorIssuesModel = {
  ...defaultIssuesState,
  ...issuesActions,
  ...issuesComputed,
  ...issuesListeners,
};

export default monitorIssuesModel;
