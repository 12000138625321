import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { useAssetContext } from '@ge/feat-analyze/context/assetContext';
import { useAssetDetail } from '@ge/feat-analyze/data-hooks/use-asset-detail';
import { AssetKpiHeaderCategories, EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { AppScopes } from '@ge/shared/models/scopes';

import useAssetKpiBarData from '../../data-hooks/use-asset-kpi-bar-data';
import useKpiHeaderMenu from '../../data-hooks/use-kpi-header-menu';
import { DefaultAssetKpiVisibility } from '../../models/kpi-header-visibility-defaults';
import DashboardHeader from '../dashboard/dashboard-header';

import { AssetDialog } from './asset-overview-asset-dialog';
import AssetOverviewTitle from './asset-overview-title';
import AssetRibbon from './asset-ribbon';

const assetKpiCategories = [...AssetKpiHeaderCategories];

const StyledHeader = styled(DashboardHeader)`
  ${({ enableMultiAxis }) => (enableMultiAxis ? `padding-left: 40px` : '')}
`;

const AssetBarContainer = styled.div`
  display: flex;
  width: calc(100% - 13px);
  background: ${(props) => props.theme.dataExplorer.header.backgroundColor};
  border-bottom: solid 1px ${(props) => props.theme.dataExplorer.header.borderColor};
  padding: 6px 3px 0 3px;
  min-height: 62px;
`;

const AddAssetButton = styled.button`
  padding: 10px;
  position: relative;
  > div {
    border: ${(props) =>
      props.isAssetSelected
        ? 'none'
        : `2px solid ${props.theme.assetOverview.selectAssetInfo.borderColor}`};
    padding: 5px;
    border-radius: 2px;
  }
  &:focus {
    outline: none;
  }
  margin-right: 5px;
`;

const SelectAsset = styled.div`
  color: ${(props) => props.theme.assetOverview.selectAssetInfo.textColor};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const AddAssetIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: Icons.ADD,
  color: props.theme.entityDetails.headerIconColor,
}))``;

export const AssetOverviewHeader = () => {
  const { ready, t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const [showAssetDialog, setShowAssetDialog] = useState(false);

  const { assetState, queryParam } = useAssetContext();

  const asset = useAssetDetail({ assetId: queryParam.assets?.[0] });

  const {
    kpiDateRange: { startDate, endDate },
  } = assetState;

  const { data: assetKpiData } = useAssetKpiBarData({
    assetId: assetState.selectedAssetId,
    categories: assetKpiCategories,
    iecCategory: KpiCategoryDefs.EVENT_RATES,
    startDate: startDate.entityTimezone,
    endDate: endDate.entityTimezone,
  });

  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_ASSET_OVERVIEW,
    defaultVisibility: DefaultAssetKpiVisibility,
    kpiCategories: assetKpiCategories,
  });

  if (!ready) return null;

  const isAssetSelected = !!queryParam.assets?.length;

  const { enableMultiAxis } = queryParam;

  return (
    <DetailsDismissInert>
      <StyledHeader
        title={t('asset_overview', 'Asset Overview')}
        type={EntityType.ASSET}
        showParent={!enableMultiAxis && queryParam.assets?.length === 1}
        entity={asset}
        data={assetKpiData}
        onMenuClose={handleKpiChanges}
        visibleKpiPrefs={visibleKpis}
        kpiCategories={assetKpiCategories}
        showStats={isAssetSelected}
        noTitle={enableMultiAxis}
        enableMultiAxis={enableMultiAxis}
      >
        {enableMultiAxis && (
          <AssetOverviewTitle onClickAssetButton={() => setShowAssetDialog(true)} />
        )}
      </StyledHeader>
      {!enableMultiAxis && (
        <AssetBarContainer data-testid={AnalyzeLocators.ANALYZE_ASSET_RIBBON_CONTAINER}>
          <AddAssetButton
            isAssetSelected={isAssetSelected}
            onClick={() => setShowAssetDialog(true)}
            data-testid={AnalyzeLocators.ANALYZE_ADD_ASSET_BUTTON}
          >
            <div>
              <AddAssetIcon />
            </div>
          </AddAssetButton>
          {!isAssetSelected && <SelectAsset>{t('select_asset', 'Select an asset')}</SelectAsset>}
          <AssetRibbon assetState={assetState} assets={queryParam.assets} />
        </AssetBarContainer>
      )}
      {showAssetDialog && (
        <AssetDialog
          assetsSelected={queryParam.assets}
          selectedSiteId={asset?.site?.id}
          maxSelectCount={enableMultiAxis ? 1 : 10}
          onClose={() => setShowAssetDialog(false)}
          onConfirm={() => setShowAssetDialog(false)}
        />
      )}
    </DetailsDismissInert>
  );
};
