/**
 * Urgency of task completion
 * TODO (astone): Is this still valid or does TaskPriority replce this?
 *
 * @enum {string}
 * @readonly
 */
export const TaskDue = {
  IMMEDIATE: 'immediate',
};

/**
 * Priority of task completion
 *
 * @enum {string}
 * @readonly
 */
export const TaskPriority = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  IMMEDIATE: 'immediate',
};

/**
 * Source of task
 *
 * @enum {string}
 * @readonly
 */
export const TaskSource = {
  ROC: 'roc',
};

/**
 * Task status
 *
 * @enum {string}
 * @readonly
 */
export const TaskStatus = {
  UNSCHEDULED: 'unscheduled',
  SCHEDULED: 'scheduled',
  CLOSED: 'closed',
  COMPLETE: 'completed',
};

/**
 * Type of task
 *
 * @enum {string}
 * @readonly
 *
 * // TODO: The rest
 */
export const TaskType = {
  APM_CASE: 'APM Case',
  BLADE_REPAIR: 'Blade Repair',
  EMERGING_ISSUE: 'Emerging Issue',
  IMPACTED: 'Impacted',
  FAULTED: 'Faulted',
  MAINTENANCE: 'Maintenance',
  ODOMETER_MAINTENANCE: 'Odometer Maintenance',
  PUNCH_LIST: 'Punch List',
  RETROFITS: 'Retrofits',
  STOPPED: 'Stopped',
  WATCHLIST: 'Watchlist',
};

/**
 * Task status
 *
 * @enum {string}
 * @readonly
 */
export const TasksTime = {
  DEFAULT_START_TIME: '07:00',
};

export const TaskListSize = {
  SIZE: 1000,
};
