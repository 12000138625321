import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

const GaugeBackground = ({ ...props }) => {
  const {
    theme: { gaugeStatChart },
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 73 65"
    >
      <defs>
        <linearGradient id="statGaugeGradient" x1="31.028%" x2="31.037%" y1="0%" y2="100%">
          <stop stopColor={gaugeStatChart.startGradient} offset="0%" />
          <stop stopColor={gaugeStatChart.endGradient} offset="100%" />
        </linearGradient>
        <filter
          id="statGaugeFilter"
          width="105.6%"
          height="106.2%"
          x="-2.8%"
          y="-3.1%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5" />
          <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
        </filter>
        <path
          id="statGaugePath"
          d="M311.844 192.805V141a5 5 0 00-5-5h-62a5 5 0 00-5 5v51.806a3 3 0 002.536 2.964L275.8 201l33.507-5.231a3 3 0 002.537-2.964z"
        />
      </defs>
      <g fill="none" transform="translate(-239 -136)">
        <use fill="url(#statGaugeGradient)" href="#statGaugePath" />
        <use fill="#000" filter="url(#statGaugeFilter)" href="#statGaugePath" />
      </g>
    </svg>
  );
};

GaugeBackground.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
};

export default withTheme(GaugeBackground);
