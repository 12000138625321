import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import styled from 'styled-components';

import { placements } from './models/placements';

const StyledTooltip = styled.div`
  background: ${({ theme }) => theme.tooltip.backgroundColor};
  color: ${({ theme }) => theme.tooltip.textColor};
  max-width: 200px;
  padding: 10px;
  margin: 10px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.tooltip.boxShadow};
  font-family: 'Museo Sans';
  font-size: 13px;
  transition: opacity ease 0.3s;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '')};
  &.tooltip-backlog {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 2px;
    border-radius: 3px;
    font-weight: bold;
    height: 10px;
    width: 71px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-snooze {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 28px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-snoozed {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 76px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-schedule {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 35px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-reschedule {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 43px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-close {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 22px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-edit {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 16px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-details {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    height: 10px;
    width: 26px;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-erp-status {
    background-color: ${({ theme }) => theme.tooltip.backgroundColorActions};
    padding: 3px;
    border-radius: 2px;
    font-weight: bold;
    color: ${({ theme }) => theme.tooltip.textColorActions};
    font-family: 'Museo Sans';
    font-size: 8px;
    letter-spacing: 0;
    line-height: 10px;
  }
  &.tooltip-system-reset-status {
    white-space: pre-line;
    line-height: 12px;
  }
  &.tooltip-close-style {
    padding: 4px 0 0 4px;
    line-height: 12px;
    height: 20px;
    width: 113px;
  }
`;

export const Tooltip = ({
  children,
  title,
  placement,
  portalId,
  zIndex,
  customClass,
  className,
}) => {
  const [isActive, setIsActiveState] = useState(false);

  const buildRef = (child, ref) =>
    React.cloneElement(child, {
      ref,
      onMouseEnter: () => setIsActiveState(true),
      onMouseLeave: () => setIsActiveState(false),
    });

  if (!title) {
    return <>{children}</>;
  }

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          Array.isArray(children)
            ? children.map((child) => buildRef(child, ref))
            : buildRef(children, ref)
        }
      </Reference>
      {isActive &&
        title &&
        ReactDOM.createPortal(
          <Popper placement={placement} strategy={'fixed'}>
            {({ ref, placement, style }) => (
              <StyledTooltip
                className={`${customClass} ${className}`}
                ref={ref}
                style={{ ...style }}
                data-placement={placement}
                zIndex={zIndex}
              >
                {title}
              </StyledTooltip>
            )}
          </Popper>,
          document.querySelector(portalId),
        )}
    </Manager>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  placement: PropTypes.oneOf(Object.values(placements)),
  portalId: PropTypes.string,
  zIndex: PropTypes.number,
  customClass: PropTypes.string,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: placements.RIGHT,
  title: null,
  portalId: '#portal-root',
  customClass: '',
  className: '',
};
