import { useStoreState } from 'easy-peasy';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { QueryKey, AlertsEntityType } from '@ge/models/constants';
import { useAlertsHeader } from '@ge/shared/data-hooks';

export const useAlertDialog = ({ entity, entityType }, enabled = true) => {
  const queryClient = useQueryClient();
  const [currentAlert, setCurrentAlert] = useState();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  const {
    data: _alerts,
    groupDetails,
    saveAsync,
    editAsync,
  } = useAlertsHeader(
    {
      entityId: entity?.id,
      entityType,
    },
    enabled,
  );

  const handleShowAlertDialog = () => setShowAlertDialog(true);

  const handleCancelAlertDialog = () => {
    setCurrentAlert();
    setShowAlertDialog(false);
  };

  const handleSaveAlert = async (alert) => {
    try {
      const selectedAssets = alert?.selectedAssets?.split(',');
      const selectedAssetDetails = {};
      if (selectedAssets?.length > 1) {
        selectedAssets.forEach((selectedAsset) => {
          selectedAssetDetails[selectedAsset] = getAssetById(selectedAsset);
        });
      }
      let asset, site;
      if (
        alert.entityType === AlertsEntityType.SITE ||
        alert.entityType === AlertsEntityType.STORAGE_SITE
      ) {
        site = getSiteById(alert.entityId);
      } else {
        asset = getAssetById(alert.entityId);
        site = getSiteById(asset?.site?.id);
      }

      await saveAsync(alert, site, asset, selectedAssetDetails);
      queryClient.invalidateQueries([QueryKey.MONITOR_ALERTS]);
      queryClient.invalidateQueries([QueryKey.SITE_REAL_TIME_INFO]);
      setCurrentAlert();
      setShowAlertDialog(false);
    } catch (err) {
      console.error(err);

      // TODO: handle when save fails
    }
  };

  const handleEditAlert = async (alert) => {
    try {
      const groupDetails = alert?.groupDetails;
      const selectedAssetDetails = {};
      if (groupDetails?.length) {
        groupDetails.forEach(({ assetId, alertId }) => {
          if (assetId) {
            selectedAssetDetails[assetId] = getAssetById(assetId);
            if (selectedAssetDetails[assetId]) {
              selectedAssetDetails[assetId].alertId = alertId;
            }
          }
        });
      }
      let asset, site;
      if (
        alert.entityType === AlertsEntityType.SITE ||
        alert.entityType === AlertsEntityType.STORAGE_SITE
      ) {
        site = getSiteById(alert.entityId);
      } else {
        asset = getAssetById(alert.entityId);
        site = getSiteById(asset?.site?.id);
      }

      await editAsync(alert, site, asset, selectedAssetDetails);
      // Invalidating queries because it will not return cached data
      queryClient.invalidateQueries([QueryKey.MONITOR_ALERTS, alert.entityType]);
      queryClient.invalidateQueries([QueryKey.SITE_REAL_TIME_INFO]);
      setCurrentAlert();
      setShowAlertDialog(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleViewAlert = (alert) => {
    setCurrentAlert(alert);
    setShowAlertDialog(true);
  };

  return {
    alerts: _alerts || [],
    groupDetails,
    showAlertDialog,
    currentAlert,
    // Actions
    setCurrentAlert,
    handleSaveAlert,
    handleEditAlert,
    handleViewAlert,
    handleCancelAlertDialog,
    handleShowAlertDialog,
  };
};
