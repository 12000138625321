import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Menu, placements } from '@ge/components/menu';
import { globalColors } from '@ge/tokens';

import { killEventPropagation } from '../../util';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  gap: 3px;
  align-items: center;
`;

const PlatformContainer = styled.div`
  overflow: hidden;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Platform = styled.div`
  padding: 8px 15px;
  border-bottom: 1px solid ${globalColors.grey9};
  &:last-child {
    border-bottom: none;
  }
`;

const SiteListTablePlatform = ({ platforms }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  if (!Array.isArray(platforms)) return null;

  return (
    <Container>
      <PlatformContainer>
        <div>{platforms?.length > 0 ? platforms?.join(', ') : '-'}</div>
      </PlatformContainer>
      <ConditionalRender shouldRender={platforms?.length > 1}>
        <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
          <Badge color={globalColors.slate3} label={`${platforms.length}`} small />
          <Menu
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            onClose={handleMenuClose}
            placement={placements.BOTTOM_START}
          >
            {platforms.map((platform) => (
              <Platform key={platform}>{platform}</Platform>
            ))}
          </Menu>
        </div>
      </ConditionalRender>
    </Container>
  );
};

SiteListTablePlatform.propTypes = {
  platforms: PropTypes.arrayOf(PropTypes.string),
};

SiteListTablePlatform.defaultProps = {
  platforms: [],
};

export default React.memo(SiteListTablePlatform);
