import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '../icon';

const CardStatusComponent = ({ className, speed, offline, danger, ...props }) => {
  const {
    theme: { assetCard },
  } = props;
  return (
    <div className={className}>
      <div>
        <Icon size={12} icon={Icons.TURBINE} color={assetCard.iconColor} />
        <span className="body-2">{speed}</span>
      </div>
      <div>
        <Icon
          size={12}
          icon={Icons.OFFLINE}
          color={danger ? assetCard.dangerColor : assetCard.iconColor}
          danger={danger}
        />
        <span className={danger ? 'danger body-2' : 'body-2'}>{offline}</span>
      </div>
    </div>
  );
};

CardStatusComponent.propTypes = {
  speed: PropTypes.number,
  offline: PropTypes.number,
  danger: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.instanceOf(Object).isRequired,
};

CardStatusComponent.defaultProps = {
  speed: 0,
  offline: 0,
  danger: false,
  className: '',
};

const styledCardStatus = styled(CardStatusComponent)`
  padding: 6px;
  overflow: hidden;
  svg {
    margin-right: 3px;
  }
  div {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    margin-right: 10px;
    vertical-align: middle;
    color: ${(props) => props.theme.assetCard.textColor};
    span {
      vertical-align: top;
      &.danger {
        color: ${(props) => props.theme.assetCard.dangerColor};
        font-weight: 500;
      }
    }
  }
`;

export const CardStatus = withTheme(styledCardStatus);
