import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { FilterPanel } from '@ge/components/filter-panel';
import { AnalyzeGlobalFilterKeys, Capability, EntityType } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useAuthEntityScope } from '@ge/shared/hooks';

import useFilterPanel from '../hooks/use-filter-panel';

import { AnalyzeFilters } from './filters';

export const AnalyzeFilterPanel = ({ entityId, entityType, siteIdsToFilterBy }) => {
  // store
  const setGlobalFilters = useStoreActions((actions) => actions.analyze.setGlobalFilters);

  // hooks
  const {
    models,
    platforms,
    state,
    filters,
    setFilters,
    filterCount,
    setFilterCount,
    getFilterCount,
  } = useFilterPanel({ entityType, siteIdsToFilterBy });

  const { enforceView, siteIds, siteLevel } = useAuthEntityScope({ entityId, entityType });

  useEffect(() => {
    const filterCount = getFilterCount(filters);

    setFilterCount(filterCount);
  }, [filters, getFilterCount, setFilterCount]);

  // handlers
  const handleApply = useCallback(() => {
    const filterCount = getFilterCount(filters);
    delete filters?.[AnalyzeGlobalFilterKeys.ALL];
    setFilterCount(filterCount);
    setGlobalFilters(filters);
  }, [filters, getFilterCount, setFilterCount, setGlobalFilters]);

  const handleClear = useCallback(() => {
    setFilters({});
    setFilterCount(0);
    setGlobalFilters({});
  }, [setFilterCount, setFilters, setGlobalFilters]);

  const handleDismiss = useCallback(() => {
    setFilters(state);
  }, [setFilters, state]);

  const hasAtLeastOnePlatformOrModelSelected = Boolean(
    filters?.platforms?.length > 0 ||
      filters?.models?.length > 0 ||
      filters.nonCoreFleet ||
      filters.coreFleet ||
      filters.inWarranty ||
      filters.outOfWarranty ||
      filters.exclude3MonthsPastCod,
  );

  return (
    <AuthRender
      capability={Capability.FRO_KPIS}
      enforceView={enforceView}
      siteIds={siteIds}
      siteLevel={siteLevel}
      view
    >
      <FilterPanel
        filterCount={filterCount}
        onApply={handleApply}
        onClear={handleClear}
        onDismiss={handleDismiss}
        showClearButton={hasAtLeastOnePlatformOrModelSelected}
      >
        <AnalyzeFilters
          filters={filters}
          setFilters={setFilters}
          models={models}
          platforms={platforms}
        />
      </FilterPanel>
    </AuthRender>
  );
};

AnalyzeFilterPanel.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  entityType: PropTypes.oneOf(Object.values(EntityType)).isRequired,
  siteIdsToFilterBy: PropTypes.arrayOf(PropTypes.string),
};

AnalyzeFilterPanel.defaultProps = {
  entityId: undefined,
};

export default AnalyzeFilterPanel;
