// @flow

import { grey } from '@material-ui/core/colors';

export default {
  regionInfo: {
    fontSize: 12,
    cursor: 'default',
    transition: 'opacity 200ms',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      opacity: 0.9,
      cursor: 'pointer',
    },
    '&.highlighted': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      opacity: 0.9,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        opacity: 1,
      },
    },
    // pointerEvents: "none",
    fontWeight: 600,
    color: grey[900],
    padding: 8,
    '& .name': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .circle': {
        marginRight: 4,
        boxShadow: '0px 0px 2px rgba(0,0,0,0.4)',
        width: 10,
        height: 10,
        borderRadius: 5,
      },
    },
    '& .tags': {
      '& .tag': {
        color: grey[700],
        display: 'inline-block',
        margin: 1,
        fontSize: 10,
        textDecoration: 'underline',
      },
    },
  },
};
