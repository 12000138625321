import { globalColors } from '@ge/tokens/colors';
import { mergeOptionsRight } from '@ge/util';

import { useSeverityName } from '../hooks';
import { getSeverityColor, getUnitsForSeverity } from '../util';

import { reliabilitySeriesType, SolarSeverity, FilterIds } from '.';

const getEventChartOptions = ({ data: _data, filterSelection, isEventSelectableClicked, t }) => {
  const categories = [];
  const seriesData = [];
  const reportBy = filterSelection.toLowerCase();

  _data.length > 0 &&
    _data.sort((a, b) => {
      return a[reportBy] < b[reportBy] ? 1 : -1;
    });

  _data.length > 0 &&
    _data.forEach((item) => {
      categories.push(item.desc);
      seriesData.push({
        id: item.id,
        name: `${item.id} - ${item.desc}`,
        y: item[filterSelection.toLowerCase()],
        color: getSeverityColor(item.severity_id),
        marker: {
          enabled: isEventSelectableClicked,
          states: {
            hover: {
              enabled: isEventSelectableClicked,
            },
            select: {
              enabled: isEventSelectableClicked,
            },
          },
          symbol: null,
        },
      });
    });

  if (categories.length < 20) {
    for (let i = categories.length; i <= 20; i++) {
      categories.push('');
    }
  }

  const xAxisMaxRangeEvent = 7;

  const workingSeries = [seriesData];

  const series = workingSeries.map((_data) => {
    const tooltipPointFormatter = ({ color, name, y }) => {
      const unitObj = getUnitsForSeverity(filterSelection.toUpperCase());
      let unit = '';
      if (unitObj) {
        unit = y > 1 ? unitObj.units : unitObj.unit;
      }
      let title = '';
      if (isEventSelectableClicked) {
        title = t('reliability.total_events');
      } else {
        title = t('reliability.events');
      }
      let tt = `
      <style>.column { padding: 0px 2px; } </style>
      <table>
      <tr colspan="5"> ${title} ${filterSelection.toUpperCase()}</tr>
      <tr colspan="5"><hr size="1" style="height: 1px;"></tr>
      <tr>
        <td class="column"><div style="float: left; background-color: ${color}; height: 3px; width: 6px;"></div></td>
        <td class="column">${name}</td>
        <td class="column">:</td>
        <td class="column">${y} ${unit}</td>
      </tr>
      </table>`;

      if (isEventSelectableClicked) {
        const noteTip = t('reliability.assets');
        tt += `<hr size="1" style="height: 1px; color: ${globalColors.white}"><p>${t(
          'reliability.tooltip_note_pareto_chart_bar',
        )} ${noteTip}</p>`;
      }

      return tt;
    };

    return {
      data: _data,
      tooltipPointFormatter,
    };
  });

  const eventChartTitle = t('reliability.PARETO_SUMMARY_BY_EVENTS');

  return {
    series,
    categories,
    xAxisMaxRangeEvent,
    eventChartTitle,
  };
};

const getAssetChartOptions = ({ data: _data, filterSelection, isAssetSelectableClicked, t }) => {
  const categories = [];
  const seriesData = [];
  const intermediateObject = {};
  const reportBy = filterSelection.toLowerCase();

  const tooltipFormatter = ({ points }) => {
    const unitObj = getUnitsForSeverity(filterSelection.toUpperCase());
    let unit = '';
    let tt = '';
    if (isAssetSelectableClicked) {
      let noteTip = t('reliability.events');
      tt += `<style>.column { padding: 0px 2px; } </style>
    <table>
    <tr colspan="5">${t('reliability.total_events')} ${filterSelection.toUpperCase()} FOR ${
        points[0].x
      }</tr>
    <tr colspan="5"><hr size="1" style="height: 1px; color: #FFFFFF"></tr>`;
      for (const point of points) {
        if (unitObj) {
          unit = point.y > 1 ? unitObj.units : unitObj.unit;
        }
        tt += `<tr>
    <td class="column"><div style="float: left; background-color: ${point.color}; height: 3px; width: 6px;"></div></td>
    <td class="column">${point.series.name}</td>
    <td class="column">:</td>
    <td class="column">${point.y} ${unit}</td>
    </tr>`;
      }

      tt += `</table>`;

      tt += `<hr size="1" style="height: 1px; color: ${globalColors.White}"><p>${t(
        'reliability.tooltip_note_pareto_chart_bar',
      )} ${noteTip}</p>`;
    } else {
      const currentPoint = [];
      points.forEach((item) => {
        if (item.y !== 0) {
          currentPoint.push(item);
        }
      });
      if (unitObj) {
        unit = points[0].y > 1 ? unitObj.units : unitObj.unit;
      }
      tt += `<style>.column { padding: 0px 2px; } </style>
  <table>
  <tr colspan="5">${t('reliability.events')} ${filterSelection.toUpperCase()}</tr>
  <tr colspan="5"><hr size="1" style="height: 1px; color: ${globalColors.White}"></tr>
  <tr>
  <td class="column"><div style="float: left; background-color: ${
    currentPoint[0].color
  }; height: 3px; width: 6px;"></div></td>
  <td class="column">${currentPoint[0].x}</td>
  <td class="column">:</td>
  <td class="column">${currentPoint[0].y} ${unit}</td>
  </tr></table>`;
    }

    return tt;
  };

  SolarSeverity.forEach((sev) => {
    intermediateObject[sev.id] = {
      name: useSeverityName(sev.id),
      color: sev.color,
      data: [],
    };
  });

  _data.length > 0 &&
    _data.sort((a, b) => {
      let aCount = 0;
      let bCount = 0;

      for (const item of a.events_summary) {
        aCount += item[reportBy];
      }

      for (const item of b.events_summary) {
        bCount += item[reportBy];
      }
      return aCount < bCount ? 1 : -1;
    });

  _data.length > 0 &&
    _data.forEach((item) => {
      categories.push(item.name);

      SolarSeverity.forEach((sev) => {
        const eventObj = item.events_summary.find((elem) => elem.severity_id === sev.id);
        intermediateObject[sev.id].data.push(
          eventObj
            ? {
                y: eventObj[reportBy],
                marker: {
                  enabled: isAssetSelectableClicked,
                  states: {
                    hover: {
                      enabled: isAssetSelectableClicked,
                    },
                    select: {
                      enabled: isAssetSelectableClicked,
                    },
                  },
                  symbol: null,
                },
              }
            : 0,
        );
      });
    });

  for (const sev of SolarSeverity) {
    seriesData.push(intermediateObject[sev.id]);
  }

  const series = seriesData.map((_data) => {
    return _data;
  });

  if (categories.length < 20) {
    for (let i = categories.length; i <= 20; i++) {
      categories.push('');
    }
  }

  const xAxisMaxRangeAsset = reportBy === FilterIds.count ? 7 : 20;

  const assetChartTitle = t('reliability.PARETO_SUMMARY_BY_ASSETS');

  return {
    series,
    categories,
    shared: true,
    tooltipFormatter,
    xAxisMaxRangeAsset,
    assetChartTitle,
  };
};

// define plot options by data type
const getPlotOptions = ({ height }) => {
  const plotOptions = {
    height,
  };

  return plotOptions;
};

export const reliabilityChartOptionsFactory = ({
  data = {},
  height,
  seriesType,
  filterSelection,
  isEventSelectableClicked,
  isAssetSelectableClicked,
  t,
}) => {
  const getOptions = {
    [reliabilitySeriesType.EVENTS]: getEventChartOptions,
    [reliabilitySeriesType.ASSETS]: getAssetChartOptions,
  }[seriesType];

  // get base options then massage based on chart type
  const chartOptions = getOptions({
    data,
    filterSelection,
    isEventSelectableClicked,
    isAssetSelectableClicked,
    t,
  });

  return mergeOptionsRight(mergeOptionsRight(getPlotOptions({ height }), chartOptions));
};
