import * as request from './api';

export const fetchTickets = () => request.get('/tickets');

export const fetchTicketById = (ticketId) => request.get(`/tickets/${ticketId}`);

export const fetchTicketsByEvent = (eventId) =>
  request.get('/tickets', { params: { event: eventId } });

/**
 * Dynamically load the ticket mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/ticket/tickets.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
