import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { useAssetContext } from '@ge/feat-analyze/context/assetContext';
import { useAssetDetail } from '@ge/feat-analyze/data-hooks/use-asset-detail';
import { getParentEntityUrl } from '@ge/feat-analyze/util';
import { EntityType } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

const Container = styled.div`
  display: flex;
`;

const AddAssetButton = styled.button.attrs(() => ({ type: 'button' }))`
  margin-top: 12px;
  margin-right: 20px;
  padding: 0px;
  outline: none;
  border-radius: 2px;
  ${(props) =>
    props.isAssetSelected
      ? css`
          height: 34px;
          width: 34px;
          border: 2px solid ${props.theme.assetOverview.header.assetIconBorder};
        `
      : css`
          display: flex;
        `};
`;

const AssetSelectIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: props.isAssetSelected ? Icons.SWAP : Icons.ADD,
  color: props.theme.assetOverview.header.iconColor,
}))`
  margin: ${(props) => (props.isAssetSelected ? ` 2px 0px 0px 4px;` : '')};
`;

const SelectAssetLabel = styled.div`
  color: ${(props) => props.theme.assetOverview.selectAssetInfo.textColor};
  font-size: 16px;
  margin-left: 10px;
`;

const BackLink = styled(NavLink)`
  color: ${({ theme }) => theme.assetOverview.header.backButtonTextColor};
  width: 20px;
`;

const SiteLink = styled.button.attrs(() => ({ type: 'button' }))`
  margin-left: 2px;
  color: ${({ theme }) => theme.assetOverview.header.secondaryTextColor};
  font-size: 12px;
  line-height: 14px;
`;

const EntityName = styled.h1`
  color: ${({ theme }) => theme.assetOverview.header.textColor};
  font-size: 30px;
  line-height: 35px;
`;

const BackIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  color: theme.assetOverview.header.iconColor,
  icon: Icons.CHEVRON,
  rotate: 90,
}))`
  margin-right: 4px;
`;

const ArrowIcon = styled(Icon).attrs(({ size, theme }) => ({
  size,
  color: theme.assetOverview.header.iconColor,
  icon: Icons.CHEVRON,
  rotate: -90,
}))`
  vertical-align: initial;
  margin-left: ${({ margin }) => (margin ? `${margin}px` : '2px')};
`;

const SiteIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.assetOverview.header.iconColor,
  icon: Icons.SITE,
}))`
  vertical-align: initial;
  margin-right: 4px;
`;

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.theme.assetOverview.header.iconColor,
}))`
  vertical-align: initial;
  margin-right: 10px;
`;

const AssetOverviewTitle = ({ onClickAssetButton }) => {
  const { ready, t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });

  const isAssetsLoading = useStoreState((state) => state.assets.isLoading);

  const { queryParam } = useAssetContext();

  const entity = useAssetDetail({ assetId: queryParam.assets?.[0] });

  const { showSiteDetails, showAssetDetails } = useContext(EntityDetailsContext);

  const handleSiteSelect = useCallback(() => {
    showSiteDetails(entity.site.id);
  }, [entity, showSiteDetails]);

  const handleAssetSelect = useCallback(() => {
    showAssetDetails(entity.id);
  }, [entity, showAssetDetails]);

  if (!ready || isAssetsLoading) return <Container />;

  const { assets = [] } = queryParam;

  return (
    <Container>
      <AddAssetButton
        isAssetSelected={assets.length}
        onClick={onClickAssetButton}
        data-testid={AnalyzeLocators.ANALYZE_ADD_ASSET_BUTTON}
      >
        <AssetSelectIcon isAssetSelected={assets.length} />
        {assets.length == 0 && (
          <SelectAssetLabel>{t('select_asset', 'Select an asset')}</SelectAssetLabel>
        )}
      </AddAssetButton>
      {assets.length > 0 && (
        <div>
          <div data-testid={AnalyzeLocators.ANALYZE_HEADER_HIGHER_VIEW_LINK}>
            {assets.length === 1 && (
              <BackLink
                data-testid={AnalyzeLocators.ANALYZE_HEADER_BACK_BUTTON}
                to={getParentEntityUrl(EntityType.ASSET, entity?.site?.id)}
                shouldAlignCenter={true}
              >
                <BackIcon />
              </BackLink>
            )}
            <SiteLink onClick={handleSiteSelect}>
              <SiteIcon />
              {entity.site.name}
              <ArrowIcon size={8} />
            </SiteLink>
          </div>
          <EntityName
            data-testid={AnalyzeLocators.ANALYZE_KPIBAR_HEADER}
            onClick={handleAssetSelect}
          >
            <TypeIcon icon={Icons.TURBINE} />
            {entity.name}
            <ArrowIcon size={20} margin={6} />
          </EntityName>
        </div>
      )}
    </Container>
  );
};

AssetOverviewTitle.propTypes = {
  onClickAssetButton: PropTypes.func,
};

AssetOverviewTitle.defaultProps = {
  onClickAssetButton: () => {},
};

export default withRouter(AssetOverviewTitle);
