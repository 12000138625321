import { useState, useRef, useEffect } from 'react';
export const useCrossTabState = (stateKey, defaultValue, popup = false) => {
  const [state, setState] = useState(defaultValue);
  const isNewSession = useRef(true);
  useEffect(() => {
    if (isNewSession.current) {
      const currentState = localStorage.getItem(stateKey);
      if (currentState) {
        setState(JSON.parse(currentState));
      } else {
        setState(defaultValue);
      }
      isNewSession.current = false;
      return;
    }
    try {
      JSON.stringify(localStorage.getItem(stateKey)) !== JSON.stringify(state) &&
        localStorage.setItem(stateKey, JSON.stringify(state));
    } catch (error) {
      console.error(error);
    }
  }, [state, stateKey, defaultValue]);
  useEffect(() => {
    if (!popup) {
      const onReceieveMessage = (e) => {
        const { key, newValue } = e;
        if (key === stateKey) {
          setState(JSON.parse(newValue));
        }
      };
      window.addEventListener('storage', onReceieveMessage);
      return () => window.removeEventListener('storage', onReceieveMessage);
    }
  }, [stateKey, setState, popup]);
  return [state, setState];
};
