import { useStoreState } from 'easy-peasy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container/scrolling-container';
import { Select } from '@ge/components/select';
import { useAssetContext } from '@ge/feat-analyze/context/assetContext';
import { useViewTasksPermission } from '@ge/feat-manage/data-hooks/use-tasks-table-asset';
import { Capability, IssueType, AssetOverviewTabs } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { NewCaseDialog } from '@ge/shared/components/cases/new-case-dialog';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { globalColors } from '@ge/tokens';

import useAssetIssueData from '../../../data-hooks/use-asset-issue-data';

import { IssueItem } from './issue-item';

const Container = styled.div`
  margin: 20px;
  min-width: 264px;
  max-width: 264px;
  border-radius: 4px 4px 0 4px;
  background-color: ${(props) => props.theme.assetOverview.issuesSidebar.backgroundColor};
  max-height: 871px;
  overflow: hidden;
  position: relative;
`;

const ContactScrollingWrapper = styled.div`
  display: flex;
  min-height: 801px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 12px 12px;
  border-bottom: 1px solid ${(props) => props.theme.assetOverview.issuesSidebar.headerBorderColor};
  align-items: center;
`;

const StyledSelect = styled(Select)`
  margin-right: 10px;
`;

const NewButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: currentColor;
  padding: 0;
  height: 13px;
  color: currentColor;
  font-family: 'Museo Sans';
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  text-transform: uppercase;
`;

const AddIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.ADD,
  color: globalColors.white,
}))`
  margin-right: 6px;
`;

const ExpandButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  padding: 8px 10px 0px;
  ${({ expanded }) => {
    if (!expanded)
      return css`
        margin: 20px;
        background-color: ${(props) => props.theme.assetOverview.issuesSidebar.backgroundColor};
        border-radius: 4px 4px 0 4px;
      `;
    return css`
      position: absolute;
      right: 0;
      top: 0;
    `;
  }}
`;

const ExpandIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.EXPORT,
  color: props.expanded ? globalColors.white : globalColors.slate4,
  viewbox: '0 0 12 12',
  rotate: props.expanded ? 90 : -90,
}))``;

export const IssuesSidebar = () => {
  // hooks
  const { t } = useTranslation(['analyze.asset', 'general'], { useSuspense: false });

  const location = useLocation();
  const dataExplorerLocation = location?.search?.split('=').includes('data-explorer');

  // store
  const assets = useStoreState((state) => state.assets.assets);

  const {
    assetState: { selectedAssetId },
    queryParam: { tab },
  } = useAssetContext();

  const asset = assets[selectedAssetId];
  const site = asset?.site;

  const { isAuthorizedToViewTasks } = useViewTasksPermission(selectedAssetId);
  const options = [
    { value: IssueType.OPEN_CASES, label: t('open_cases', 'Open Cases') },
    { value: IssueType.CLOSED_CASES, label: t('closed_cases', 'Closed Cases') },
    isAuthorizedToViewTasks && {
      value: IssueType.OPEN_TASKS,
      label: t('open_tasks', 'Open Tasks'),
    },
    isAuthorizedToViewTasks && {
      value: IssueType.CLOSED_TASKS,
      label: t('closed_tasks', 'Closed Tasks'),
    },
    { value: IssueType.ACTIVE_ALARMS, label: t('active_alarms', 'Active Alarms') },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [showNewCaseDialog, setShowNewCaseDialog] = useState(false);

  const [expanded, setExpanded] = useState(true);

  const { data: issues } = useAssetIssueData({
    assetId: selectedAssetId,
    issueType: selectedOption.value,
  });

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  if (!expanded) {
    return (
      <ExpandButton expanded={expanded} onClick={() => setExpanded(true)}>
        <ExpandIcon expanded={expanded} />
      </ExpandButton>
    );
  }

  return (
    <Container>
      {tab === AssetOverviewTabs.DATA_EXPLORER && (
        <ExpandButton expanded={expanded} onClick={() => setExpanded(false)}>
          <ExpandIcon expanded={expanded} />
        </ExpandButton>
      )}
      <Header>
        <StyledSelect
          small
          maxWidth={140}
          options={options.filter((task) =>
            dataExplorerLocation ? task.value !== IssueType.ACTIVE_ALARMS : task,
          )}
          value={selectedOption}
          onChange={handleSelect}
        />
        {dataExplorerLocation ? (
          selectedOption.value !== IssueType.CLOSED_TASKS &&
          selectedOption.value !== IssueType.OPEN_TASKS && (
            <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteIds={[site?.id]} edit>
              <NewButton onClick={() => setShowNewCaseDialog(true)}>
                <AddIcon />
                {t('new', 'new')}
              </NewButton>
            </AuthRender>
          )
        ) : (
          <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteIds={[site?.id]} edit>
            <NewButton onClick={() => setShowNewCaseDialog(true)}>
              <AddIcon />
              {t('new', 'new')}
            </NewButton>
          </AuthRender>
        )}
      </Header>
      <ContactScrollingWrapper>
        <ScrollingContainer>
          <DetailsDismissInert>
            {issues?.map((issue) => (
              <IssueItem key={issue.id} issue={issue} issueType={selectedOption.value} />
            ))}
          </DetailsDismissInert>
        </ScrollingContainer>
      </ContactScrollingWrapper>
      <NewCaseDialog
        isOpen={showNewCaseDialog}
        onClose={() => setShowNewCaseDialog(false)}
        selectedEntityId={selectedAssetId}
      />
    </Container>
  );
};
