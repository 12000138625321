import { action } from 'easy-peasy';

// Define initial state
const defaultAppState = {
  isPopout: false,
};

const appActions = {
  /* eslint-disable-next-line no-unused-vars */
  resetApp: action((state) => {
    state = Object.assign(state, defaultAppState);
  }),

  setIsPopout: action((state, payload) => {
    state.isPopout = payload;
  }),
};

const appModel = {
  ...defaultAppState,
  ...appActions,
};

export default appModel;
