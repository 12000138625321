import { KpiCategoryDefs, TableFilterTypes } from '@ge/models/constants';
const MAX_FILTERED_VALUES = 1000;
export const AssetsColumns = {
  // Assets
  GROUP_ASSETS: 'group-assets',
  GROUP: 'group',
  SITE: 'site',
  TYPE: 'type',
  ASSET: 'assetName',

  // State
  GROUP_STATE: 'group-state',
  STATE: 'state',

  // Active Power
  GROUP_ACTIVE_POWER: 'group-active-power',
  ACTUAL: 'actual',
  RATED: 'rated',
  POWER_CHART: 'power-chart',

  // Events
  GROUP_EVENTS: 'group-events',
  CODE: 'code',
  SEVERITY: 'severity',
  DESCRIPTION: 'description',
  FLAGGED: 'flagged',
  LOGIC: 'logic',

  // State Duration
  GROUP_STATE_DURATION: 'group-state-duration',
  STATE_DURATION: 'state-duration',

  // Tickets
  GROUP_TICKETS: 'group-tickets',
  ANOMALIES: 'anomalies',
  ROC: 'roc',
  SERVICE: 'service',
  TASKS: 'tasks',
  NEW: 'new',

  // Weekly Availability
  GROUP_WEEKLY_AVAILABILITY: 'group-weekly-availability',
  WEEKLY_AVAILABILITY: 'weekly-availability',

  // Conditions
  GROUP_CONDITIONS: 'group-conditions',
  WIND: 'wind',
  TEMP: 'temp',

  // Serial Number
  GROUP_SERIAL_NUMBER: 'group-serial-number',
  SERIAL_NUMBER: 'serialNumber',

  // System Number
  GROUP_SYSTEM_NUMBER: 'group-system-number',
  SYSTEM_NUMBER: 'systemNumber',

  // Customer
  GROUP_CUSTOMER: 'group-customer',
  CUSTOMER: 'customer',

  // Asset Make
  GROUP_ASSET_MAKE: 'group-asset-make',
  ASSET_MAKE: 'assetMake',

  // Asset Model
  GROUP_ASSET_MODEL: 'group-asset-model',
  ASSET_MODEL: 'assetModel',

  // Reactive Power
  GROUP_REACTIVE_POWER: 'group-reactive-power',
  REACTIVE_POWER: 'reactive-power',

  // Automation Enabled
  GROUP_AUTOMATION_ENABLED: 'group-automation-enabled',
  AUTOMATION_ENABLED: 'automation-enabled',

  // Price MWh/hr
  GROUP_PRICE_MWH: 'group-price-mwh',
  PRICE_MWH: 'price-mwh',

  // LOST REVENUE
  GROUP_LOST_REVENUE: 'group-lost-revenue',
  LOST_REVENUE: 'lost-revenue',

  // Event Rate
  GROUP_EVENT_RATE: 'group-event-rate',
  EVENT_RATE: 'event-rate',

  // Service Leader
  GROUP_SERVICE_LEADER: 'group-service-leader',
  SERVICE_LEADER: 'service-leader',

  // Cumulative Production
  GROUP_CUMULATIVE_PROD: 'group-cumulative-prod',
  CUMULATIVE_PROD: 'cumulative-prod',

  // Availability Technical
  GROUP_AVAIL_TECH: 'group-avail-tech',
  AVAIL_TECH: 'avail-tech',

  // Spend / Budget
  GROUP_SPEND_BUDGET: 'group-spend-budget',
  SPEND_BUDGET: 'spend-budget',

  // Cost / Asset
  GROUP_COST_ASSET: 'group-cost-asset',
  COST_ASSET: 'cost-asset',

  // New headers
  // TBA
  GROUP_TBA: 'group-tba',
  TBA: 'tba',

  // PBA
  GROUP_PBA: 'group-pba',
  PBA: 'pba',

  // Contractual Availability
  GROUP_AVAILABILITY_CONTRACT: 'group-availability-contract',
  AVAILABILITY_CONTRACT: 'availabilityContract',

  // Unproduced Energy
  GROUP_UNPRODUCED_ENERGY_MWH: 'group-unproduced-energy-mwh',
  UNPRODUCED_ENERGY_MWH: 'unproducedEnergyMwh',

  // Actual Production
  GROUP_ACTUAL_PRODUCTION_MWH: 'group-actual-production-mwh',
  ACTUAL_PRODUCTION_MWH: 'actualProductionMwh',

  // Capacity Factor
  GROUP_CAPACITY_FACTOR: 'group-capacity-factor',
  CAPACITY_FACTOR: 'capacityFactor',

  // Event Coverage
  GROUP_EVENT_COVERAGE: 'group-event-coverage',
  EVENT_COVERAGE: 'eventCoverage',

  GROUP_AVERAGE_WINDSPEED: 'group-average-windspeed',
  AVERAGE_WINDSPEED: 'averageWindSpeed',

  GROUP_ASSET_DETAIL: 'group-asset-detail',
  ASSET_DETAIL: 'asset-detail',
};

// Define all available columns in the assets table.
export const AssetsColumnDefs = {
  [AssetsColumns.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.assets',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [AssetsColumns.GROUP]: {
        align: 'left',
        minWidth: '35px',
        labels: [
          {
            a11yKey: 'table.group',
            a11yDefault: 'Group',
            sortValue: 'site.group',
          },
        ],
      },
      [AssetsColumns.SITE]: {
        locked: true,
        align: 'left',
        minWidth: '98px',
        labels: [
          {
            a11yKey: 'table.site',
            a11yDefault: 'Site',
            sortValue: 'site.name',
          },
        ],
      },
      [AssetsColumns.TYPE]: {
        labels: [
          {
            a11yKey: 'table.type',
            a11yDefault: 'Type',
            sortValue: 'type',
          },
        ],
      },
      [AssetsColumns.ASSET]: {
        locked: true,
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: AssetsColumns.ASSET,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_STATE]: {
    cols: {
      [AssetsColumns.STATE]: {
        minWidth: '41px',
        labels: [
          {
            a11yKey: 'table.state',
            a11yDefault: 'State',
            sortValue: 'metrics.state',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ACTIVE_POWER]: {
    labels: [
      {
        a11yKey: 'table.active_power',
        a11yDefault: 'ACTIVE POWER (MW)',
      },
    ],
    cols: {
      [AssetsColumns.ACTUAL]: {
        labels: [
          {
            a11yKey: 'table.actual',
            a11yDefault: 'Actual',
            sortValue: 'metrics.production.availActual',
          },
        ],
      },
      [AssetsColumns.RATED]: {
        labels: [
          {
            a11yKey: 'table.rated',
            a11yDefault: 'Rated',
            sortValue: 'ratedPower',
          },
        ],
      },
      [AssetsColumns.POWER_CHART]: {},
    },
  },
  [AssetsColumns.GROUP_EVENTS]: {
    labels: [
      {
        a11yKey: 'table.events',
        a11yDefault: 'EVENTS',
      },
    ],
    cols: {
      [AssetsColumns.CODE]: {
        locked: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.code',
            a11yDefault: 'Code',
            sortValue: 'metrics.events.causalEvent.code',
          },
        ],
        cell: {
          align: 'center',
        },
      },
      [AssetsColumns.SEVERITY]: {
        labels: [
          {
            a11yKey: 'table.severity',
            a11yDefault: 'Sev.',
            sortValue: 'metrics.events.causalEvent.severity',
          },
        ],
      },
      [AssetsColumns.DESCRIPTION]: {
        locked: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.state_event_description',
            a11yDefault: 'State Event Description',
            sortValue: 'metrics.events.causalEvent.description',
          },
        ],
        cell: {
          maxWidth: '280px',
        },
      },
      [AssetsColumns.FLAGGED]: {
        labels: [
          {
            a11yKey: 'table.flagged',
            a11yDefault: 'Flagged',
            sortValue: 'metrics.events.flaggedCount',
          },
        ],
      },
      [AssetsColumns.LOGIC]: {
        minWidth: '40px',
        labels: [
          {
            a11yKey: 'table.logic',
            a11yDefault: 'Logic',
            sortValue: 'metrics.events.logicCount',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_STATE_DURATION]: {
    cols: {
      [AssetsColumns.STATE_DURATION]: {
        align: 'right',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: 'table.state_duration',
            a11yDefault: 'State Duration',
            sortValue: 'metrics.state-duration',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_TICKETS]: {
    labels: [
      {
        a11yKey: 'table.tickets',
        a11yDefault: 'Tickets',
      },
    ],
    cols: {
      [AssetsColumns.ANOMALIES]: {
        labels: [
          {
            a11yKey: 'table.anomalies',
            a11yDefault: 'Anomalies',
            sortValue: 'metrics.anomalies',
          },
        ],
      },
      [AssetsColumns.ROC]: {
        labels: [
          {
            a11yKey: 'table.roc',
            a11yDefault: 'ROC',
            sortValue: 'metrics.roc',
          },
        ],
      },
      [AssetsColumns.SERVICE]: {
        labels: [
          {
            a11yKey: 'table.service',
            a11yDefault: 'Service',
            sortValue: 'metrics.service',
          },
        ],
      },
      [AssetsColumns.TASKS]: {
        labels: [
          {
            a11yKey: 'table.tasks',
            a11yDefault: 'Tasks',
          },
        ],
      },
      [AssetsColumns.NEW]: {
        labels: [
          {
            a11yKey: 'table.new',
            a11yDefault: 'New',
          },
        ],
      },
    },
  },
  /*   [AssetsColumns.GROUP_WEEKLY_AVAILABILITY]: {
    cols: {
      [AssetsColumns.WEEKLY_AVAILABILITY]: {
        whiteSpace: 'wrap',
        align: 'right',
        labels: [
          {
            a11yKey: 'table.weekly_availability',
            a11yDefault: 'Weekly\nAvailability',
            sortValue: 'metrics.production.availProjected',
          },
        ],
      },
    },
  }, */
  [AssetsColumns.GROUP_CONDITIONS]: {
    labels: [
      {
        a11yKey: 'table.conditions',
        a11yDefault: 'Conditions',
      },
    ],
    cols: {
      [AssetsColumns.WIND]: {
        labels: [
          {
            a11yKey: 'table.wind',
            a11yDefault: 'Wind (m/s)',
            sortValue: 'metrics.conditions.wind',
          },
        ],
      },
      [AssetsColumns.TEMP]: {
        labels: [
          {
            a11yKey: 'table.temp',
            a11yDefault: 'Temp (C)',
            sortValue: 'metrics.conditions.temp',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_AVERAGE_WINDSPEED]: {
    cols: {
      [AssetsColumns.AVERAGE_WINDSPEED]: {
        filterType: TableFilterTypes.NUMBER,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.average_wind_speed',
            a11yDefault: 'Average Wind Speed (m/sec)',
            sortValue: 'metrics.conditions.windSpeedAverage',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_SERIAL_NUMBER]: {
    cols: {
      [AssetsColumns.SERIAL_NUMBER]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.serial_number',
            a11yDefault: 'Serial #',
            sortValue: 'serialNumber',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_SYSTEM_NUMBER]: {
    cols: {
      [AssetsColumns.SYSTEM_NUMBER]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.system_number',
            a11yDefault: 'System #',
            sortValue: 'systemNumber',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_CUSTOMER]: {
    cols: {
      [AssetsColumns.CUSTOMER]: {
        minWidth: '95px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.customer',
            a11yDefault: 'Customer',
            sortValue: 'site.customer.name',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ASSET_MAKE]: {
    cols: {
      [AssetsColumns.ASSET_MAKE]: {
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.asset_make',
            a11yDefault: 'Asset Make',
            sortValue: AssetsColumns.ASSET_MAKE,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ASSET_MODEL]: {
    cols: {
      [AssetsColumns.ASSET_MODEL]: {
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.asset_model',
            a11yDefault: 'Asset Model',
            sortValue: AssetsColumns.ASSET_MODEL,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_REACTIVE_POWER]: {
    cols: {
      [AssetsColumns.REACTIVE_POWER]: {
        whiteSpace: 'wrap',
        minWidth: '80px',
        align: 'right',
        labels: [
          {
            a11yKey: 'table.reactive_power',
            a11yDefault: 'Reactive Power (MVar)',
            sortValue: 'metrics.readings.general.reactivePower',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_AUTOMATION_ENABLED]: {
    cols: {
      [AssetsColumns.AUTOMATION_ENABLED]: {
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: 'table.automation_enabled',
            a11yDefault: 'Automation Enabled',
            sortValue: 'metrics.automation',
          },
        ],
      },
    },
  },
  /*   [AssetsColumns.GROUP_PRICE_MWH]: {
    cols: {
      [AssetsColumns.PRICE_MWH]: {
        whiteSpace: 'wrap',
        align: 'right',
        labels: [
          {
            a11yKey: 'table.price_mwh',
            a11yDefault: 'Price MWh/hr',
            sortValue: 'metrics.revenue.priceMW',
          },
        ],
      },
    },
  }, */
  /*   [AssetsColumns.GROUP_LOST_REVENUE]: {
    cols: {
      [AssetsColumns.LOST_REVENUE]: {
        whiteSpace: 'wrap',
        align: 'right',
        minWidth: '70px',
        labels: [
          {
            a11yKey: 'table.lost_revenue',
            a11yDefault: 'Lost Revenue $ / hr',
            sortValue: 'metrics.revenue.lostRevenue',
          },
        ],
      },
    },
  }, */
  [AssetsColumns.GROUP_EVENT_RATE]: {
    cols: {
      [AssetsColumns.EVENT_RATE]: {
        whiteSpace: 'wrap',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.event_rate',
            a11yDefault: 'Event Rate',
            sortValue: 'metrics.production.faultRate',
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_SERVICE_LEADER]: {
    cols: {
      [AssetsColumns.SERVICE_LEADER]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.service_leader',
            a11yDefault: 'Service Leader',
            sortValue: 'metrics.maintenance.serviceLeader',
          },
        ],
      },
    },
  },
  /* [AssetsColumns.GROUP_AVAIL_TECH]: {
    cols: {
      [AssetsColumns.AVAIL_TECH]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.avail_tech',
            a11yDefault: 'Availability\nTechnical',
            sortValue: 'metrics.production.availTechnical.percent',
          },
        ],
      },
    },
  }, */
  /* [AssetsColumns.GROUP_SPEND_BUDGET]: {
    cols: {
      [AssetsColumns.SPEND_BUDGET]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.spend_budget',
            a11yDefault: 'Spend/\nBudget',
            sortValue: 'metrics.revenue.spend',
          },
        ],
      },
    },
  }, */
  /* [AssetsColumns.GROUP_COST_ASSET]: {
    cols: {
      [AssetsColumns.COST_ASSET]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.cost_asset',
            a11yDefault: 'Cost/\nAsset',
            sortValue: 'metrics.revenue.cost',
          },
        ],
      },
    },
  }, */
  [AssetsColumns.GROUP_TBA]: {
    cols: {
      [AssetsColumns.TBA]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.tba',
            a11yDefault: 'TBA - Technical\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.TBA}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_AVAILABILITY_CONTRACT]: {
    cols: {
      [AssetsColumns.AVAILABILITY_CONTRACT]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.contractual_availability',
            a11yDefault: 'Contractual Availability\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.AVAILABILITY_CONTRACT}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_PBA]: {
    cols: {
      [AssetsColumns.PBA]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.pba',
            a11yDefault: 'PBA - Technical\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.PBA}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_UNPRODUCED_ENERGY_MWH]: {
    cols: {
      [AssetsColumns.UNPRODUCED_ENERGY_MWH]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.unproduced_energy_mwh',
            a11yDefault: 'Unproduced Energy\nMWh',
            sortValue: `metrics.performance.${KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ACTUAL_PRODUCTION_MWH]: {
    cols: {
      [AssetsColumns.ACTUAL_PRODUCTION_MWH]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.actual_production_mwh',
            a11yDefault: 'Actual Production\nMWh',
            sortValue: `metrics.performance.${KpiCategoryDefs.PRODUCTION_ACTUAL}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_CAPACITY_FACTOR]: {
    cols: {
      [AssetsColumns.CAPACITY_FACTOR]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.capacity_factor',
            a11yDefault: 'Capacity Factor\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.CAPACITY_FACTOR}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_EVENT_COVERAGE]: {
    cols: {
      [AssetsColumns.EVENT_COVERAGE]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.event_coverage',
            a11yDefault: 'Event Coverage\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.EVENT_COVERAGE}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ASSET_DETAIL]: {
    fixedRight: true,
    align: 'left',
    cols: {
      [AssetsColumns.ASSET_DETAIL]: {
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.asset_detail',
            a11yDefault: 'Asset\nDetails',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '43px',
        },
      },
    },
  },
};
