import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Input } from '@ge/components/input';
import { StatusColor } from '@ge/tokens/colors/colors';

const InputWrapper = styled.div`
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'initial')};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'initial')};
`;

const StyledInput = styled(Input)`
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))`
  margin-right: 7px;
  margin-top: 9px;
`;

export const TagsMetaField = ({
  name,
  label,
  defaultValue,
  placeholder,
  metadata,
  className,
  maxWidth,
  minWidth,
}) => {
  const { readOnly, required } = metadata;
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? null}
      name={name}
      rules={{ required: required }}
      render={({ onChange, value }) => (
        <InputWrapper maxWidth={maxWidth} minWidth={minWidth}>
          <StyledInput
            className={className}
            disabled={readOnly}
            errors={errors}
            id={name}
            label={label}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                const values = new Set(value);
                if (!values.has(e.target.value)) {
                  values.add(e.target.value);
                }
                e.target.value = '';
                onChange(values.size > 0 ? [...values] : []);
              }
            }}
            placeholder={placeholder}
            required={required}
            type="text"
          />
          {value?.map((v) => (
            <StyledBadge
              key={v}
              label={v}
              onDismiss={() => {
                onChange(value.filter((val) => val !== v) ?? []);
              }}
            />
          ))}
        </InputWrapper>
      )}
    />
  );
};

TagsMetaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
  metadata: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
};

TagsMetaField.defaultProps = {
  label: '',
  placeholder: null,
  defaultValue: [],
};
