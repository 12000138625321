import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { CalendarFilters } from '@ge/models/constants';

import { usePlanningContext } from '../../context/planning-context';
import { DefaultFilters } from '../../context/planning-provider';

const ScheduledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.manage.timeline.scheduleBadge,
}))`
  margin-left: 8px;
`;

const UnscheduledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.manage.timeline.unscheduledBadge,
}))`
  margin-left: 8px;
`;

const CompletedBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.manage.timeline.completedBadge,
}))`
  margin-left: 8px;
`;

const SummaryContainer = styled.div`
  background: ${({ theme }) => theme.manage.calendar.backgroundColor};
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  > div {
    margin-right: 10px;
    > span {
      margin-left: 8px;
      color: ${({ theme }) => theme.manage.summary.badgeLabelColor};
    }
  }
`;

const FilterBtn = styled.div`
  border-radius: 3px;
  padding: 4px 6px 5px;
  margin-top: 1px;
  background: ${(props) =>
    props.active ? props.theme.select.primaryBackground : props.theme.select.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15px;
  button {
    background: none;
    border: none;
    padding: 4px 2px;
    .btn-txt {
      display: inline-block;
      width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
    }
  }
`;

export const CalendarSummary = ({ scheduled, dueUnScheduled, completed }) => {
  const { t, ready } = useTranslation(['manage.planning'], { useSuspense: false });

  const {
    planningState: { groupFilter, handleSetGroupFilter },
  } = usePlanningContext();

  const filterState = useMemo(
    () => groupFilter.filters?.[CalendarFilters.STATUS] ?? DefaultFilters[CalendarFilters.STATUS],
    [groupFilter],
  );

  const handleChange = useCallback(
    (updateValue) => {
      const updateValues = filterState.value.includes(updateValue)
        ? filterState.value.filter((key) => key !== updateValue)
        : [...filterState.value, updateValue];

      const filters = {
        ...groupFilter.filters,
        [CalendarFilters.STATUS]: {
          ...filterState,
          value: updateValues,
        },
      };

      handleSetGroupFilter(groupFilter.L1, groupFilter.L2, filters);
    },
    [filterState, groupFilter, handleSetGroupFilter],
  );

  if (!ready) return null;

  return (
    <SummaryContainer>
      <FilterBtn
        active={filterState.value.includes('unscheduled')}
        title={t('due_Unscheduled', 'Due & Unscheduled')}
      >
        <Button type="button" onClick={() => handleChange('unscheduled')}>
          <span className="btn-txt">{t('due_Unscheduled', 'Due & Unscheduled')}</span>
          <UnscheduledBadge medium label={dueUnScheduled || '-'} />
        </Button>
      </FilterBtn>
      <FilterBtn active={filterState.value.includes('scheduled')}>
        <Button type="button" onClick={() => handleChange('scheduled')}>
          {t('scheduled', 'Scheduled')}
          <ScheduledBadge medium label={scheduled || '-'} />
        </Button>
      </FilterBtn>
      <FilterBtn active={filterState.value.includes('completed')}>
        <Button type="button" onClick={() => handleChange('completed')}>
          {t('completed', 'Completed')}
          <CompletedBadge medium label={completed || '-'} />
        </Button>
      </FilterBtn>
    </SummaryContainer>
  );
};

CalendarSummary.propTypes = {
  scheduled: PropTypes.number,
  dueUnScheduled: PropTypes.number,
  completed: PropTypes.number,
};

CalendarSummary.defaultProps = {
  scheduled: null,
  dueUnScheduled: null,
  completed: null,
};
