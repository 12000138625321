import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Table, Tbody, Td } from '@ge/components/table';

const TicketWrapper = styled.section`
  border-radius: 6px;
  overflow: hidden;
  background: ${(props) => props.theme.entityDetails.tickets.background};
  justify-content: space-between;
  flex: 1;
  margin-bottom: 50px;
  align-self: flex-start;

  .tickets-title {
    border-bottom: solid 2px ${(props) => props.theme.entityDetails.tickets.borderColor};
    padding: 14px 18px;
    width: 100%;
  }

  .loader {
    min-height: 136px;
    position: relative;
  }

  .content {
    padding-bottom: 18px;
    table {
      td {
        border-bottom: solid 1px ${(props) => props.theme.entityDetails.tickets.borderColor};
        border-right: solid 1px ${(props) => props.theme.entityDetails.tickets.borderColor};
        padding: 12px 20px;
        h3 {
          font-weight: 700;
          font-size: 13px;
        }
        h4 {
          color: #8b8f98;
        }
        &:last-child {
          border-right: 0px;
        }
      }
    }
  }
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tickets.arrowIcon,
  icon: Icons.CHEVRON,
  size: 14,
  rotate: -90,
}))``;

const TicketCountBadge = styled(Badge).attrs((props) => ({
  color: props.theme.entityDetails.tickets.badgeColor,
}))`
  margin-left: 6px;
  vertical-align: bottom;
`;

const OpenTickets = ({ asset }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const { fetchTicketsByAsset } = useStoreActions((store) => store.tickets);
  const { getTicketsByAsset } = useStoreState((store) => store.tickets);

  const [openTickets, setOpenTickets] = useState(null);

  useEffect(() => {
    fetchTicketsByAsset(asset.id);
  }, [asset.id, fetchTicketsByAsset]);

  useEffect(() => {
    setOpenTickets(getTicketsByAsset(asset.id, true));
  }, [asset.id, getTicketsByAsset]);

  if (!ready) {
    return <Loader />;
  }

  return (
    <TicketWrapper>
      <div className="open-tickets">
        <div className="tickets-title">
          <span>
            {t('ticket_entity.open_tickets', 'Open Tickets for')} <b>{asset.name}</b>
          </span>
          {openTickets && <TicketCountBadge label={`${openTickets.length}`} />}
        </div>
        <div className="content">
          {!openTickets && (
            <div className="loader">
              <Loader />
            </div>
          )}
          {openTickets && (
            <Table>
              <Tbody transparent>
                {openTickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <Td align="left">
                      <h3>{t(`ticket_entity.${ticket.type}`, ticket.type)}</h3>
                      <h4>#{ticket.number}</h4>
                    </Td>
                    <Td align="left">
                      <h3>{dayjs(ticket.created).format('MM/DD/YYYY')}</h3>
                      <h4>{dayjs(ticket.created).format('HH:mm')}</h4>
                    </Td>
                    <Td>
                      <h4>{ticket.detail}</h4>
                    </Td>
                    <Td>
                      <ArrowIcon />
                    </Td>
                  </tr>
                ))}
              </Tbody>
            </Table>
          )}
        </div>
      </div>
    </TicketWrapper>
  );
};

OpenTickets.propTypes = {
  asset: PropTypes.instanceOf(Object).isRequired,
};

export default OpenTickets;
