import { useQuery, useMutation, useQueryClient } from 'react-query';

import { linkCases } from '@ge/feat-monitor/services';
import { fetchCasesForAssetIdsAndStatus } from '@ge/feat-monitor/services';
import { AnomaliesTableCaseStatus, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { CasesQueryKeys } from './cases/query-keys';
import { updateCasesCache } from './use-update-cases';

/**
 * Use case fusion data.
 *
 * @param assetId an asset id
 */
export const useCaseFusion = ({ assetId, caseId, isActive = false }) => {
  const queryClient = useQueryClient();
  const status = AnomaliesTableCaseStatus.join(',');
  const includeChildCases = false;
  const { data, isLoading: isLoadingCases } = useQuery(
    [QueryKey.ASSETS_CASES, assetId, caseId, includeChildCases],
    () => fetchCasesForAssetIdsAndStatus([assetId], status, includeChildCases),
    {
      enabled: isActive && Boolean(assetId),
      ...Config.EXECUTE_ONCE,
      refetchInterval: Config.REFRESH.realTime,
    },
  );

  const { mutate: fuse, isLoading: isMutating } = useMutation(
    (input_data) => linkCases(input_data),
    {
      onSettled: (data) => {
        updateCasesCache(queryClient, data.cases);
        queryClient.invalidateQueries(CasesQueryKeys.detail(data.id));
        queryClient.invalidateQueries([QueryKey.ASSETS_CASES, assetId]);
      },
    },
  );

  return {
    cases: data?.data ?? [],
    isLoading: isLoadingCases || isMutating,
    fuse,
  };
};
