import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { FeatureCapability, QueryKey } from '@ge/models/constants';
import { Config, useAuth, useWindTurbines } from '@ge/shared/data-hooks';

export const useContractualReportingOverview = () => {
  // store
  const { sitesForView } = useStoreState((state) => state.sites);
  // const { assetsBySiteId } = useStoreState((state) => state.assets);
  const { data: assets } = useWindTurbines();
  // data hooks
  const { isAuthorized } = useAuth();

  const getAssetCount = (siteId) => {
    const sitesCount = [];
    assets?.map((asset) => {
      if (asset.site.id === siteId && asset.type === 'wind-turbine') {
        sitesCount.push(asset);
      }
    });
    return sitesCount.length;
  };

  const authorizedSites = useMemo(
    () =>
      sitesForView.reduce((sites, site) => {
        const authorized = isAuthorized({
          capabilities: FeatureCapability.CONTRACTUAL_REPORTING_OVERVIEW,
          enforceView: true,
          siteIds: [site.id],
        });
        site.assetCount = getAssetCount(site.id);
        if (authorized) {
          sites.push(site);
        }

        return sites;
      }, []),
    [isAuthorized, sitesForView, assets],
  );

  const mockFetch = async () => {
    const bySite = authorizedSites.map((s) => ({
      site: {
        id: s.id,
        name: s.name,
        timezone: s.timezone,
      },
      assetCount: s.assetCount,
      availability: {
        current: 74.98,
        mtd: 88.06,
        reference: 94.83,
      },
      // Commenting lowest performing asset for mock data
      // lowestPerformer: assetsBySiteId[s.id]?.[0],
    }));
    const totalCount = {
      sites: bySite.length,
      assets: bySite.reduce((acc, { assetCount }) => acc + assetCount, 0),
    };
    return {
      totalCount,
      bySite,
    };
  };

  return useQuery(
    [QueryKey.CONTRACTUAL_AVAILABILITY_OVERVIEW, { authorizedSites }],
    () => mockFetch(),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(authorizedSites?.length),
    },
  );
};
