import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { maLegendValues } from '@ge/feat-analyze/models/manual-adjustment-legends';

const StyledDialog = styled(Dialog)`
  section {
    max-height: 350px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.scrollbar.trackBackground};
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scrollbar.thumbBackground};
      border-radius: 2.5px;
    }
  }
`;

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: linear-gradient(180deg, rgba(28, 37, 45, 0.85) 0%, rgba(22, 22, 23, 0.7) 100%);
  padding: 10px;
`;

const LegendShowAll = styled.div`
  display: flex;
`;

const EventCategory = styled.span`
  font-family: 'Museo Sans';
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
`;

const Legend = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  height: 13px;
  max-width: 90%;
`;

const LegendItem = styled.li`
  display: inline-block;
  max-width: 20%;
  margin-left: 10px;
  text-transform: uppercase;
  font-family: 'Museo Sans';
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: 600;
  line-height: 12px;
`;

const LegendColor = styled.span`
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: 2px;
  display: inline-block;
  margin-right: 5px;
`;

const HelpIconButton = styled.button`
  margin-left: 12px;
`;

const HelpIcon = styled(Icon).attrs((props) => ({
  size: 18,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))``;

const DialogList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const DialogListItem = styled.li`
  padding: 0 10px 10px;
  margin: 0 0 10px 0;
  display: grid;
  grid-template-columns: 1fr 6fr;
  border-bottom: 1px solid ${(props) => props.theme.analyze.manualAdjustment.bordersColor};
  font-family: 'Museo Sans';
  font-size: 10px;
  font-weight: 500;
  &:last-of-type {
    margin: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const DialogListItemTitle = styled.p`
  margin: 0 0 3px 0;
  font-size: 11px;
`;

const DialogListItemDescr = styled.p`
  margin: 0;
  font-weight: 300;
`;

export const LegendMenu = ({ assetEventsKind }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const legendRef = React.createRef();

  const legendValues = maLegendValues[assetEventsKind]?.filter((v) => v.primary);

  if (!ready) return null;

  return (
    <LegendContainer>
      <LegendShowAll>
        <EventCategory>{t(`${assetEventsKind}`, assetEventsKind)}:</EventCategory>
        <Legend ref={legendRef}>
          {legendValues?.map((e, i) => (
            <LegendItem key={i}>
              <LegendColor backgroundColor={e.color} size={'10px'} />
              {t(`legend.${e.a11yKey}.legend_abbrev`, e.eventCategory)}
            </LegendItem>
          ))}
        </Legend>
        <HelpIconButton type="button" onClick={() => setDialogOpen(true)}>
          <HelpIcon icon={Icons.HELP} />
        </HelpIconButton>
      </LegendShowAll>
      <StyledDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        header={t(`legend_dialog.${assetEventsKind}`, `${assetEventsKind} Information Categories`)}
        padContent
      >
        <DialogList>
          {legendValues.map((e, i) => (
            <DialogListItem key={i}>
              <div>
                <LegendColor backgroundColor={e.color} size={'8px'} />
                {t(`legend.${e.a11yKey}.dialog_abbrev`, e.eventCategory)}
              </div>
              <div>
                <DialogListItemTitle>
                  {t(`legend.${e.a11yKey}.dialog_title`, '')}
                </DialogListItemTitle>
                <DialogListItemDescr>
                  {t(`legend.${e.a11yKey}.dialog_descr`, '')}
                </DialogListItemDescr>
              </div>
            </DialogListItem>
          ))}
        </DialogList>
      </StyledDialog>
    </LegendContainer>
  );
};

LegendMenu.propTypes = {
  assetEventsKind: PropTypes.string,
};
