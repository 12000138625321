import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
// import { MapView } from '@ge/components/map-view';
import { useNotification } from '@ge/components/notification';
import ClearColumnFilter from '@ge/components/table/filters/clear-column-filter';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { TableRefreshButton } from '@ge/components/table/table-refresh-button';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useExportAnomaliesTable } from '@ge/feat-analyze/data-hooks/use-export-anomalies-table';
import { EventRefreshButtonWithToolTip } from '@ge/feat-monitor/components/event-refresh';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { DateTimeFormats } from '@ge/models/constants';
import {
  EntityType,
  PresentationTypes,
  TaskSourceField,
  MonitorDefs,
  Capability,
  PermissionScope,
  EntityTab,
  QueryKey,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import Breadcrumbs from '@ge/shared/components/breadcrumbs';
import { NewCaseDialog } from '@ge/shared/components/cases/new-case-dialog';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import AnomalyTable from '@ge/shared/components/tables/anomaly-table';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useActiveCaseCount, useAuth } from '@ge/shared/data-hooks';
import useCaseDetailDataByQuery from '@ge/shared/data-hooks/cases/use-case-detail-data-by-query';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { AnomaliesColumnDefs, AnomaliesColumns } from '@ge/shared/models/table-col-defs';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors, typography } from '@ge/tokens';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';

import NotificationMessage from '../components/notification-message';
import PageHeader from '../components/page-header';
import EventsTable from '../components/tables/events-table';
import { useAnomaliesTable, useRibbonFilter } from '../data-hooks';
import { defaultEventCols, EventsColumnDefs, defaultAnomaliesCols, NavItems } from '../models';

const EVENTS = MonitorDefs.EVENTS;
const HISTORY = MonitorDefs.HISTORY;
const ANOMALIES = MonitorDefs.ANOMALIES;
const CASES = MonitorDefs.CASES;

const EventMetric = {
  DURATION: 'duration',
};

const AnomalyMetric = {
  PRIORITY: 'priority.value',
};

const IssuesView = {
  EVENTS,
  HISTORY,
  ANOMALIES,
};

const AddCaseButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  width: 115px;
  left: 5px;
  top: 5px;
`;

const StyledEventRefresh = styled.div`
  position: relative;
  bottom: 5px;
`;

const PlusIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const ExportButton = styled.button`
  display: flex;
  margin-right: 15px;
  margin-top: 4px;
  padding: 0;
  pointer-events: ${(props) => (props.isLoading ? 'none' : '')};
`;

const ExportIcon = styled(Icon).attrs(() => ({
  size: 14,
  color: globalColors.slate4,
}))``;
/**
 * Set list of view tyoes for Presentation Toggle
 */
const PresentationViews = [PresentationTypes.LIST];

const MonitorIssues = (props) => {
  const { t, ready } = useTranslation(['monitor.issues'], {
    useSuspense: false,
  });

  const queryClient = useQueryClient();

  const { isAuthorized } = useAuth();

  const isAuthorizedToViewMonitorCases = isAuthorized({
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
  });

  const isAuthorizedToViewTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  });

  const { showCaseDetails, showEventDetails, showDetails } = useContext(EntityDetailsContext);

  const { filter } = useParams();

  const {
    location: { search },
  } = props;

  const getAssetById = useStoreState((store) => store.assets.getAssetById);
  const getSiteById = useStoreState((store) => store.sites.getSiteById);

  const [isActive, setActiveState] = useState(PresentationTypes.LIST);
  const [currentSearch, setCurrentSearch] = useState({});
  const [newTaskCaseId, setNewTaskCaseId] = useState();
  const [showNewCaseDialog, setShowNewCaseDialog] = useState(false);
  const [selectedMonitorFilter, setSelectedMonitorFilter] = useState(null);
  const [caseHistoryRefreshing, setCaseHistoryRefreshing] = useState(false);

  const {
    filterDefs,
    filterSequence,
    onChange: setFilterDefs,
    onReset,
  } = useFilterDefs({
    columnDefs: AnomaliesColumnDefs,
    stateId: MonitorDefs.ANOMALIES_FILTER_STATE_ID,
  });

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.MONITOR_ISSUES);

  // Table state management
  const {
    updateColumnVisibility: updateEventColumnVisibility,
    setVisibleColumns: setVisibleEventCols,
    visibleCols: visibleEventCols,
    sortDirection: eventSortDirection,
    sortMetric: eventSortMetric,
    updateSortMetric: updateEventSortMetric,
  } = useColumnState({
    columnDefs: EventsColumnDefs,
    defaultCols: defaultEventCols,
    defaultSortMetric: EventMetric.DURATION,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: MonitorDefs.EVENTS_SORT_STATE_ID,
  });

  const {
    updateColumnVisibility: updateAnomalyColumnVisibility,
    setVisibleColumns: setVisibleAnomalyCols,
    visibleCols: visibleAnomalyCols,
    sortDirection: anomalySortDirection,
    sortMetric: anomalySortMetric,
    updateSortMetric: updateAnomalySortMetric,
  } = useColumnState({
    columnDefs: AnomaliesColumnDefs,
    defaultCols: defaultAnomaliesCols,
    defaultSortMetric: AnomalyMetric.PRIORITY,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: MonitorDefs.ANOMALIES_SORT_STATE_ID,
  });

  // data hooks
  const {
    isLoading,
    data: anomalies,
    filterValues,
  } = useAnomaliesTable({
    isActive: filter === ANOMALIES,
    filters: filterDefs,
    filterSequence: filterSequence,
    search: currentSearch,
    sortDirection: anomalySortDirection,
    sortMetric: anomalySortMetric,
    isAuthorizedToViewTasks,
    type: ANOMALIES,
  });

  const { caseWithDetails } = useCaseDetailDataByQuery({ caseId: newTaskCaseId });

  const ribbonState = useRibbonFilter(
    filter === ANOMALIES
      ? {
          isLoading,
          type: ANOMALIES,
          data: anomalies,
          isAuthorizedToViewTasks,
        }
      : {},
  );

  const { onVisibilityChange: handleEventColumnChanges } = useManageColumnVisibility({
    subKey: EVENTS,
    featPrefs,
    savePrefs,
    setVisibleColumns: setVisibleEventCols,
    updateColumnVisibility: updateEventColumnVisibility,
  });

  const { onVisibilityChange: handleAnomalyColumnChanges, onSelectionVisibilityChange } =
    useManageColumnVisibility({
      subKey: ANOMALIES,
      featPrefs,
      savePrefs,
      setVisibleColumns: setVisibleAnomalyCols,
      updateColumnVisibility: updateAnomalyColumnVisibility,
    });

  const { onExportData: handleExportData } = useExportAnomaliesTable({
    isActive: filter === ANOMALIES,
    filters: filterDefs,
    search: currentSearch,
    sortDirection: anomalySortDirection,
    sortMetric: anomalySortMetric,
    filter,
    anomaliesData: ribbonState.data,
  });

  const { notify } = useNotification();

  const { data: cases } = ribbonState;

  // Monitor Active Case count
  const { data: activeMonitorCaseCount, updateRibbonFilterCounts } = useActiveCaseCount({
    isEnable:
      isActive === PresentationTypes.LIST &&
      filter === IssuesView.EVENTS &&
      isAuthorizedToViewMonitorCases,
  });

  const { filterDefs: caseQueueFilterDefs, onChange: setCaseQueueFilterDefs } = useFilterDefs({
    columnDefs: EventsColumnDefs,
    stateId: MonitorDefs.EVENTS_SORT_STATE_ID,
  });

  const modifiedActiveMonitorCaseCount = useMemo(() => {
    if (!activeMonitorCaseCount || !isAuthorizedToViewMonitorCases) return;
    return {
      filters: activeMonitorCaseCount,
      selected: selectedMonitorFilter,
      updateFilter: (id) => {
        setSelectedMonitorFilter(activeMonitorCaseCount.find((element) => element.id === id));
      },
    };
  }, [activeMonitorCaseCount, selectedMonitorFilter, isAuthorizedToViewMonitorCases]);

  const modifiedNavItems = useMemo(() => {
    return NavItems.filter(({ capabilities }) =>
      capabilities ? isAuthorized({ capabilities }) : true,
    );
  }, [isAuthorized]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');

    if (view) {
      setActiveState(view);
    } else {
      setActiveState(PresentationTypes.LIST);
    }
  }, [isActive, search]);

  const handleSelectCase = useCallback(
    (e, selectedCase) => {
      killEventPropagation(e);
      showCaseDetails(selectedCase.id);
    },
    [showCaseDetails],
  );

  const handleSelectNote = useCallback(
    (e, selectedCase) => {
      killEventPropagation(e);
      showCaseDetails(selectedCase.id, EntityTab.NOTES);
    },
    [showCaseDetails],
  );

  const handleSelectEvent = useCallback(
    (e, event) => {
      // TODO: Create draft version of ticket to pass to entity instead of NEW_TICKET type.
      killEventPropagation(e);
      showEventDetails(event.id);
    },
    [showEventDetails],
  );

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  const handleNewTask = useCallback((e, _case) => {
    killEventPropagation(e);
    setNewTaskCaseId(_case.id);
  }, []);

  const handleCloseNewTaskDialog = useCallback(() => {
    setNewTaskCaseId();
  }, []);

  const handleSaveNewTask = () => {
    setNewTaskCaseId();
  };

  const handleEntitySelect = useCallback(
    (e, id, type, isAuthorized) => {
      killEventPropagation(e);
      if (isAuthorized) showDetails(id, type);
    },
    [showDetails],
  );

  const handleNewCaseDialogClose = useCallback(
    (caseData) => {
      const cases = caseData?.cases;
      if (caseData && caseData.id) {
        cases.push(caseData);
      }

      if (cases?.length > 0) {
        cases.map((caseData) => {
          const asset = getAssetById(caseData?.asset.id);
          const site = getSiteById(caseData?.site.id);

          const status = (
            <Breadcrumbs onBreadCrumbClick={handleEntitySelect} site={site} asset={asset} />
          );

          const message = (
            <NotificationMessage
              message={t('case_created_success', 'Case Created Successfully').toUpperCase()}
            />
          );

          const timestamp = dayjs(caseData.createDate).format(DateTimeFormats.NOTIFICATION_TIME);

          notify({ status, message, timestamp });
        });
      }

      setShowNewCaseDialog(false);
    },
    [getAssetById, getSiteById, handleEntitySelect, notify, t],
  );

  const getRibbonState = useCallback(() => {
    if (
      filter === IssuesView.EVENTS &&
      modifiedActiveMonitorCaseCount &&
      isAuthorizedToViewMonitorCases
    ) {
      return modifiedActiveMonitorCaseCount;
    }
    return ribbonState;
  }, [filter, modifiedActiveMonitorCaseCount, ribbonState, isAuthorizedToViewMonitorCases]);

  const handleEventRefresh = useCallback(
    async (e) => {
      killEventPropagation(e);
      queryClient.invalidateQueries(QueryKey.MONITOR_CASES, 'history');
    },
    [queryClient],
  );

  if (!ready) {
    return null;
  }

  return (
    <PageContainer i18nReady={ready}>
      <PageHeader title={t('cases', 'Cases')} ribbonState={getRibbonState()} />
      <SubNavigation navItems={modifiedNavItems}>
        <ConditionalRender shouldRender={filter === MonitorDefs.ANOMALIES && !isLoading}>
          <TableRefreshButton
            title={filter}
            manualRefresh={false}
            presentationType={isActive}
            queryKey={[QueryKey.ANOMALIES_TABLE_REFRESH]}
          />
        </ConditionalRender>
        {isActive === PresentationTypes.LIST && filter === IssuesView.HISTORY && (
          <StyledEventRefresh>
            <EventRefreshButtonWithToolTip
              onClick={handleEventRefresh}
              inProgress={caseHistoryRefreshing}
              toolTip={
                caseHistoryRefreshing
                  ? t('event_refresh.tooltip.in_progress', 'Refresh in Progress')
                  : t('event_refresh.tooltip.manual_refresh', 'Manual Refresh Events')
              }
            />
          </StyledEventRefresh>
        )}
        {isActive === PresentationTypes.LIST &&
          filter === IssuesView.EVENTS &&
          isAuthorizedToViewMonitorCases && (
            <ColumnSelectMenu
              translateFn={t}
              columnDef={EventsColumnDefs}
              columnState={visibleEventCols}
              onMenuClose={handleEventColumnChanges}
            />
          )}
        {filter === IssuesView.ANOMALIES && (
          <>
            <ClearColumnFilter filters={filterDefs} onReset={onReset} />
            <AuthRender
              capability={Capability.CASES_RECOMMENDATIONS}
              create
              description="New Case button"
              siteLevel={false}
            >
              <AddCaseButton onClick={() => setShowNewCaseDialog(true)}>
                <PlusIcon />
                {t('add_case_button', 'Add Case')}
              </AddCaseButton>
            </AuthRender>
          </>
        )}
        {isActive === PresentationTypes.LIST && filter === IssuesView.ANOMALIES && (
          <>
            <ExportButton
              isLoading={isLoading}
              onClick={() => handleExportData(visibleAnomalyCols)}
            >
              <ExportIcon viewbox={'0 0 12 12'} icon={Icons.EXPORT} />
            </ExportButton>
            <ColumnSelectMenu
              translateFn={t}
              columnDef={AnomaliesColumnDefs}
              columnState={visibleAnomalyCols}
              onMenuClose={handleAnomalyColumnChanges}
              onClumnSelection={onSelectionVisibilityChange}
            />
          </>
        )}
        <PresentationToggle viewList={PresentationViews} />
      </SubNavigation>
      {isActive === PresentationTypes.LIST &&
        filter === IssuesView.EVENTS &&
        isAuthorizedToViewMonitorCases && (
          <DetailsDismissInert>
            <EventsTable
              columns={visibleEventCols}
              tableType={CASES}
              sortAction={updateEventSortMetric}
              sortMetric={eventSortMetric}
              sortDirection={eventSortDirection}
              onEventSelect={handleSelectEvent}
              onDrop={(columnValue) => savePrefs(columnValue, EVENTS)}
              draggable
              selectedRibbonFilter={selectedMonitorFilter}
              filterDefs={caseQueueFilterDefs}
              setFilterDefs={setCaseQueueFilterDefs}
              updateRibbonFilterCounts={updateRibbonFilterCounts}
            />
          </DetailsDismissInert>
        )}
      {isActive === PresentationTypes.LIST &&
        filter === IssuesView.HISTORY &&
        isAuthorizedToViewMonitorCases && (
          <DetailsDismissInert>
            <EventsTable
              columns={visibleEventCols}
              // events={events_history}
              tableType={HISTORY}
              sortAction={updateEventSortMetric}
              sortMetric={eventSortMetric}
              sortDirection={eventSortDirection}
              onEventSelect={handleSelectEvent}
              onDrop={(columnValue) => savePrefs(columnValue, HISTORY)}
              draggable
              filterDefs={caseQueueFilterDefs}
              setFilterDefs={setCaseQueueFilterDefs}
              setCaseHistoryRefreshing={setCaseHistoryRefreshing}
            />
          </DetailsDismissInert>
        )}
      {isActive === PresentationTypes.LIST && filter === IssuesView.ANOMALIES && (
        <DetailsDismissInert>
          <AnomalyTable
            columns={
              isAuthorizedToViewTasks
                ? visibleAnomalyCols
                : visibleAnomalyCols.filter(
                    (arrOfAnomalyColumns) =>
                      arrOfAnomalyColumns.id !== AnomaliesColumns.GROUP_TASKS,
                  )
            }
            anomalies={cases}
            sortAction={updateAnomalySortMetric}
            sortMetric={anomalySortMetric}
            sortDirection={anomalySortDirection}
            onCreateTicket={handleNewTask}
            onDrop={(columnValue) => savePrefs(columnValue, ANOMALIES)}
            onCaseSelect={handleSelectCase}
            onFilter={setFilterDefs}
            onFilterChange={handleFilterChange}
            draggable
            resizable
            isLoading={isLoading}
            filterValues={filterValues}
            filterDefs={filterDefs}
            onNotesSelect={handleSelectNote}
          />
        </DetailsDismissInert>
      )}
      {/* {isActive === PresentationTypes.MAP && <MapView />} */}
      {caseWithDetails && newTaskCaseId && (
        <NewTaskDialog
          associationId={caseWithDetails.id}
          entity={caseWithDetails}
          entityType={EntityType.CASE}
          entityId={caseWithDetails.asset?.id || caseWithDetails.site?.id}
          onClose={handleCloseNewTaskDialog}
          onConfirm={handleSaveNewTask}
          priority={caseWithDetails.priority}
          taskSource={TaskSourceField.MANUALSITE}
          title={caseWithDetails.description}
          siteTimezone={caseWithDetails.site.timezone}
        />
      )}

      <NewCaseDialog isOpen={showNewCaseDialog} onClose={handleNewCaseDialogClose} />
    </PageContainer>
  );
};

MonitorIssues.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

MonitorIssues.defaultProps = {
  location: null,
};

export default withRouter(MonitorIssues);
