import { useStoreState } from 'easy-peasy';

export const useFeatureAssets = () => {
  const getFeatureAssets = useStoreState((state) => state.assets.getFeatureAssets);
  const { isLoading } = useStoreState((state) => state.assets);

  const assets = getFeatureAssets();

  return { isLoading, assets };
};
