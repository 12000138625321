import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { MenuPlacements, Select } from '@ge/components/select';
import { Severity } from '@ge/components/severity';
import { Text } from '@ge/components/typography';
import { TaskStatus } from '@ge/models';
import { TasksTime } from '@ge/models';
import { DateTimeFormats, TaskFlag } from '@ge/models/constants';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { getDateObject, addUTCOffset } from '@ge/shared/util/time-date';
import { globalColors, typography, elevations } from '@ge/tokens';
import { globalGradients } from '@ge/tokens/colors';

import { DragItemTypes } from '../../../features/manage/context/planning-provider';
import { useServiceGroups } from '../../../features/manage/util/worker-util';

import { DistributeTasksForm } from './distribute-tasks-form';
import { SetDateTasksForm } from './set-date-tasks-form';
import { ShiftTasksForm } from './shift-tasks-form';

const StyledSelect = styled(Select)`
  .select__control {
    width: 135px;
  }
  .select__menu {
    .select__menu-list {
      max-height: ${({ moreOptions }) => (moreOptions ? '100px' : '200px')};
      &::-webkit-scrollbar {
        width: 4px;
        height: 0px;
      }
      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.scrollbar.trackBackground};
      }
      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollbar.thumbBackground};
        border-radius: 2.5px;
      }
    }
  }
`;

const DialogContainer = styled.div`
  width: 784px;
  height: 874px;
  max-height: calc(100vh - 195px);
  max-height: ${(props) =>
    props.tasks?.length === 2 && !props.moreOptions && `calc(100vh - 240px)`};
  display: flex;
  flex-direction: column;
  font-family: ${typography.family.default};
`;

const FooterButtons = styled.div`
  margin-left: auto;
  text-align: right;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const CalendarInputIcon = styled(Icon).attrs(({ theme, icon }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor, //TODO update this theme
  icon,
}))`
  margin-right: 6px;
  vertical-align: text-top;
  &.input {
    margin-left: -20px;
  }
`;

const ModalHeaderIcon = styled(Icon).attrs(({ theme, icon }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor, //TODO update this theme
  icon,
}))`
  margin-right: 6px;
  vertical-align: text-top;
`;

const OptionsHeaderIcon = styled(Icon).attrs(({ icon }) => ({
  size: 20,
  color: globalColors.slate4, //TODO update this theme
  icon,
}))`
  position: relative;
  top: 5px;
`;

const OptionsHeaderIconLeft = styled(Icon).attrs(({ icon }) => ({
  size: 20,
  color: globalColors.slate4, //TODO update this theme
  icon,
}))`
  position: relative;
  bottom: 7px;
  right: 8px;
  rotate: 180deg;
`;

const StyledSeverity = styled(Severity)`
  margin-right: 5px;
  vertical-align: baseline;
`;

const TaskText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dialog.taskReschedule.labelColor};
  &.value {
    color: currentColor;
  }
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
  margin-top: 6px;
`;

const EntityIcon = styled(Icon).attrs(({ theme, icon }) => ({
  size: 9,
  color: theme.dialog.taskReschedule.iconColor,
  icon,
}))`
  margin-right: 5px;
  vertical-align: initial;
`;

const EntitySiteIcon = styled(Icon).attrs(({ theme, icon }) => ({
  size: 9,
  color: theme.dialog.taskReschedule.iconColor,
  icon,
}))`
  margin-right: 2px;
  position: relative;
  bottom: 1px;
`;

const FieldSet = styled.fieldset`
  border: 0;
  padding: 0;
`;

const InputWrapper = styled.div`
  margin-top: 6px;
`;

const InputContainer = styled.div`
  display: flex;

  .input-container {
    margin-bottom: 10px;
    margin-right: 10px;

    .input-header {
      margin: 10px 0 5px 0;
    }
  }
`;

const FooterContinueText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  margin-bottom: 12px;
  display: block;
`;

const TabOptions = {
  SET_DATE: 'set date',
  SHIFT: 'shift',
  DISTRIBUTE: 'distribute',
};

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 10px;
`;

const StyledForm = styled.form`
  /* border: 1px solid red; */
`;

const MainContainer = styled.div`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }
`;

const DialogTopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  min-height: 42px;
  border-bottom: solid 1px ${(props) => props.theme.dialog.taskReschedule.spacerBorderColor};
`;

const DialogSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${(props) => (props.moreOptions ? '65px' : '45px')};
  background-color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.grey6}` : `${globalColors.slate8}`};
  padding: 0px 10px;
  position: sticky;
  top: 0;
  z-index: ${elevations.P1};
`;

const RemoveTasksSection = styled.div`
  text-align: center;
  padding: 15px 10px;
`;

const RemoveTasksMessageIcon = styled(Icon).attrs(() => ({
  size: 10,
  icon: Icons.DATA_NETCOMM,
  color: globalColors.red2,
}))`
  margin: 10px 0px;
`;

const RemoveTasksMessage = styled.p`
  color: ${(props) => props.theme.dangerColor};
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin:0;
}
`;

const RemoveTasksButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: ${(props) => props.theme.dataLoader.retryColor};
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;

const RemoveTasksIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.TRASH,
  color: props.theme.dataLoader.retryColor,
}))`
  margin: 0px 0 4px;
`;

const SubHeaderInputContainer = styled(InputContainer)`
  position: ${(props) => props.moreOptions && 'relative'};
  top: ${(props) => props.moreOptions && '10px'};
`;

const OptionsButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 6px 0;
  text-transform: uppercase;
  color: currentColor;
  font-size: 11px;
  font-weight: ${typography.weight.bold};
  letter-spacing: 0.5px;
`;

const ApplyButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.black}` : `${globalColors.white}`};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  box-sizing: border-box;
  width: 64px;
  margin-left: 10px;
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 2px;
  background: ${(props) => props.theme.button.backgroundColor};

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const TaskSelectedText = styled.h3`
  color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.black}` : `${globalColors.grey5}`};
  font-size: 12px;
  font-weight: ${typography.weight.bold};
  letter-spacing: 0.55px;
  line-height: 14px;
  text-transform: uppercase;
`;

const AssignAllText = styled.h3`
  color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.black}` : `${globalColors.grey5}`};
  font-size: 11px;
  font-weight: ${typography.weight.bold};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 13px;
  margin-left: 12px;
  position: ${(props) => props.moreOptions && 'relative'};
  left: ${(props) => props.moreOptions && '26.2em'};
  bottom: ${(props) => props.moreOptions && '15px'};
`;

const AssignAllSubText = styled.h3`
  color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.grey4}`};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  position: relative;
  top: 10px;
  left: ${(props) =>
    props.userLang === 'de' ? '8.2em' : props.userLang === 'pt-BR' ? '6.4em' : '6em'};
`;

const TaskSchedule = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.moreOptions ? 'column' : 'row')};
  align-items: ${(props) => props.moreOptions && 'end'};
  justify-content: space-between;
  padding: 10px 25px;
  height: ${(props) => props.tasks.length === 1 && '160px'};
  border-bottom: solid 1px ${(props) => props.theme.dialog.taskReschedule.spacerBorderColor};
  &:last-child {
    border: 0;
  }
  .schedule-flex {
    margin-top: 6px;
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
      margin-right: 14px;
      span {
        margin-bottom: 4px;
      }
    }
  }

  .task-details {
    position: relative;
    bottom: 6px;
  }

  .entity-info-main {
    width: 270px;
    overflow-wrap: break-word;
  }

  .entity-info {
    .entity-text {
      color: ${(props) =>
        props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.slate5}`};
    }
  }
`;

const TaskTitleInfo = styled.div`
  margin-top: ${(props) => props.moreOptions && '5px'};
`;

// More options side panel styles

const OptionsContainer = styled.div`
  height: 761px;
  max-height: -webkit-fill-available;
  width: 260px;
  padding: 12px;
  position: fixed;
  z-index: ${elevations.P2};
  left: 0;
  background-color: ${globalColors.slate2};
  border-right: 2px solid ${globalColors.teal1};
  background: ${(props) => props.theme.assetCard.titleBackgroundColor};
  box-shadow: 4px 0 16px 0 rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  transition: 0.5s;

  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }

  .set-date-form-container {
    overflow-y: auto;
    height: 305px;
  }

  .shift-form-container {
    margin-top: 25.36px;
    overflow-y: auto;
    height: 260px;
  }

  .options-menu-header {
    color: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.black}` : `${globalColors.grey5}`};
    font-size: 11px;
    font-weight: ${typography.weight.bold};
    letter-spacing: 0.5px;
    line-height: 13px;
    text-transform: uppercase;
  }

  .tab-buttons {
    margin-top: 10px;
  }

  .current-tab {
    color: ${(props) => props.theme.name === 'Light' && 'rgba(255,255,255,0.87)'};
    border: 1px solid ${globalColors.teal11};
    border-radius: 2px 0 0 2px;
    background: ${globalGradients.tealGradiend5};
  }

  .description-container {
    margin-top: 13px;
    text-align: center;

    .tab-description {
      color: ${(props) =>
        props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.grey4}`};
      font-size: 11px;
      font-style: italic;
      letter-spacing: 0;
      line-height: 12px;
      text-align: center;
    }
  }
`;

const TabButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: currentColor;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  text-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 85px;
  border: 1px solid ${globalColors.slate12};
  border-radius: 0 2px 2px 0;
  background-color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.grey6}` : `${globalColors.slate2}`};
`;

const StyledInput = styled(Input)`
  &:disabled {
    cursor: not-allowed;
  }
`;

export const RescheduleTasksDialog = ({
  tasks,
  onClose,
  onSaveAll,
  onRemoveTasks,
  shiftCount,
  shiftCountMins,
  dropDate,
  dropTime,
  isError,
  crewLaneInfo,
  tasktobeBundled,
  bundleTasks,
  dragItemBacklogType,
}) => {
  const { t } = useTranslation(['manage.cases-tasks', 'tasks'], { useSuspense: false });
  const { handleSubmit, control, setValue, getValues, errors, trigger } = useForm({
    mode: 'onChange',
  });
  const serviceGroupIds = useServiceGroups();
  const backlogData = useStoreState((state) => state.siteManager.hydratedTaskBacklog);
  const { crewsSg } = useStoreState((state) => state.workers);
  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const getProfileLang = useStoreState((state) => state.prefs.language);
  const { enabledApprovalFlag } = useStoreState((store) => store.tasks);

  let crews = [];

  if (crewsSg) {
    crews = crewsSg?.map((crew) => {
      return {
        value: crew?._id,
        label: crew?.name,
      };
    });
  }

  const onChangeCrew = useCallback((task, crewIds) => {
    task.crewIds = [crewIds];
    task.crewChanged = true;
    return task;
  }, []);

  const resetCrewDetails = (task) => {
    task.crewIds = null;
    task.crewChanged = false;
    const crewDefaultData = crewLaneInfo ? crewLaneInfo.value : null;
    setValue(`${task.id}.assignee`, crewDefaultData);
    return;
  };

  const assignCrewOnDrop = useCallback(
    (taskIdAssignee, scheduleDate, startTime) => {
      let data;
      if (crewsSg && crewLaneInfo?.value) {
        data = crewsSg?.find((crew) => crew._id == crewLaneInfo.value);
        const crewStartDateTime = data?.crewStartDate + ' ' + data?.crewShiftStartTime;
        const crewEndDateTime =
          data?.crewEndDate && data?.crewEndDate + ' ' + data?.crewShiftEndTime;
        const dataValue = data?.crewEndDate
          ? dayjs(scheduleDate + ' ' + startTime).isBetween(
              dayjs(crewStartDateTime).format(DateTimeFormats.CREW_TIMING),
              dayjs(crewEndDateTime).format(DateTimeFormats.CREW_TIMING),
              'day',
              [],
            )
          : dayjs(scheduleDate + ' ' + startTime).isAfter(data?.crewStartDate);
        const crewValue = dataValue && crewLaneInfo ? crewLaneInfo.value : null;
        setValue(taskIdAssignee, crewValue);
        return crewValue;
      }
    },
    [crewsSg, crewLaneInfo, setValue],
  );
  const updateDefaultValue = (task) => {
    if (!task.crewChanged) {
      let scheduleDate = getValues(`${task.id}.scheduleDate`);
      scheduleDate = scheduleDate ? dayjs(scheduleDate).format(DateTimeFormats.CREW_TIMING) : null;
      let startTime = getValues(`${task.id}.startTime`);

      const taskScheduledDate = task?.scheduleDateTz
        ? dayjs(task?.scheduleDateTz).format(DateTimeFormats.CREW_TIMING)
        : null;
      if (scheduleDate !== taskScheduledDate) {
        assignCrewOnDrop(`${task.id}.assignee`, scheduleDate, startTime);
        return null;
      } else {
        const returnValue = crewLaneInfo?.value
          ? assignCrewOnDrop(`${task.id}.assignee`, scheduleDate, startTime)
          : null;
        task.crewIds = [returnValue];
        task.crewChanged = false;
        setValue(`${task.id}.assignee`, returnValue);
        return returnValue;
      }
    } else {
      const changedCrewValue = task?.crewIds?.length ? task?.crewIds[0] : null;
      return changedCrewValue;
    }
  };

  const handleChange = useCallback(() => {
    onClose();
    tasks.map((obj) => {
      obj.crewChanged = false;
    });
  }, [onClose, tasks]);

  const getCrewList = (task) => {
    let scheduleDate = getValues(`${task.id}.scheduleDate`);
    scheduleDate = scheduleDate ? dayjs(scheduleDate).format(DateTimeFormats.CREW_TIMING) : null;

    let updateCrewList = crewsSg
      ?.map(
        (crew) =>
          scheduleDate &&
          dayjs(scheduleDate).isBetween(
            dayjs(crew.crewStartDate),
            dayjs(crew.crewEndDate),
            null,
            '[]',
          ) && {
            value: crew._id,
            label: crew.name,
          },
      )
      .filter((crew) => crew);
    return updateCrewList;
  };

  // Handle's assigning a crew to all scheduled tasks if one is available

  const getBundleCrewList = useCallback(() => {
    let crews = [...crewsSg];
    let taskIds = tasks?.map((task) => task.id);
    let listOfScheduledTaskDates = [];
    let crewsAvailable = [];

    taskIds?.forEach((taskId) => {
      let scheduleDate = getValues(`${taskId}.scheduleDate`);

      if (scheduleDate) listOfScheduledTaskDates.push(scheduleDate);
    });

    if (listOfScheduledTaskDates.length) {
      crews.forEach((crew) => {
        let crewAvailable = listOfScheduledTaskDates.every(
          (date) =>
            date &&
            dayjs(date).isBetween(dayjs(crew.crewStartDate), dayjs(crew.crewEndDate), null, []),
        );
        if (crewAvailable) crewsAvailable.push(crew);
      });
    }

    return crewsAvailable.map((crew) => {
      return {
        value: crew._id,
        label: crew.name,
      };
    });
  }, [crewsSg, getValues, tasks]);

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [moreOptionsTab, setMoreOptionsTab] = useState(TabOptions.SET_DATE);
  const [showContinue, setShowContinue] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);

  const scheduleDateRefs = useMemo(() => [], []);

  const tasksNotToBeScheduled = useMemo(() => {
    return approvalFlag && enabledApprovalFlag && onRemoveTasks
      ? tasks.filter((task) => task.flag !== TaskFlag.APPROVED)
      : [];
  }, [approvalFlag, enabledApprovalFlag, onRemoveTasks, tasks]);

  const tasksToBeScheduled = useMemo(() => {
    return approvalFlag && enabledApprovalFlag && onRemoveTasks
      ? tasks.filter((task) => task.flag === TaskFlag.APPROVED)
      : tasks;
  }, [approvalFlag, enabledApprovalFlag, onRemoveTasks, tasks]);

  const handleSaveAll = useCallback(
    (data) => {
      delete data.assignAllCrew;
      const nullScheduleDates = !!Object.values(data).find(
        ({ scheduleDate }) => scheduleDate === null,
      );

      if (!showContinue && nullScheduleDates) {
        setShowContinue(true);
        return;
      }
      setSpinLoader(true);
      const getBundledTasksListArray = (id) => {
        // debugger;
        const bundledTaskArrayList = [];
        const [taskID] = tasks; //dragging task
        const task = data[taskID.id];
        tasks.map((eachtask) => {
          backlogData?.forEach((asset) => {
            if (
              asset.asset.id === eachtask.asset.id &&
              eachtask.id !== asset.id &&
              !tasktobeBundled.includes(asset.id)
            ) {
              bundledTaskArrayList.push(asset.id);
            }
          });
        });
        if (id !== undefined) {
          let backlogTask = backlogData.find((asset) => asset.id === id); // scheduled task
          if (
            backlogTask?.id !== taskID.id &&
            // task.scheduleDate === backlogTask.scheduleDate &&
            task?.startTime !== backlogTask?.startTime
          ) {
            bundledTaskArrayList.push(taskID.id);
          }
        } else {
          let tasktobeBundledData = [];
          backlogData.filter((res) => {
            if (tasktobeBundled && tasktobeBundled.indexOf(res.id) > -1) {
              tasktobeBundledData.push(res);
            }
          });
          tasktobeBundledData.forEach((val) => {
            if (
              taskID.id !== val.id &&
              // task.scheduleDate === val.scheduleDate &&
              task?.startTime !== val?.startTime
            ) {
              bundledTaskArrayList.push(val.id);
            }
          });
        }
        return [...new Set(bundledTaskArrayList)];
      };
      const getSchDateStrTime = (id, DateOrTime) => {
        const { scheduleDateTz, startTime } = tasktobeBundled.find((asset) => asset.id === id);
        return DateOrTime == 'scheduleDate' ? scheduleDateTz?.$d : startTime;
      };
      if (bundleTasks && dragItemBacklogType === DragItemTypes.BACKLOG) {
        !!Object.values(data).map((item) => {
          item.isBundled = true;
          item.bundledTasks = getBundledTasksListArray();
        });
        tasktobeBundled.map(
          (task) =>
            (data[task?.id] = {
              isBundled: true,
              bundledTasks: getBundledTasksListArray(task?.id),
              scheduleDate: getSchDateStrTime(task?.id, 'scheduleDate'),
              startTime: getSchDateStrTime(task?.id, 'startTime'),
            }),
        );
      }
      if (dragItemBacklogType !== DragItemTypes.BACKLOG) {
        tasks.map((task) => {
          if (task?.isBundled === true) {
            !!Object.values(data).map((item) => {
              item.isBundled = task?.isBundled;
              item.bundledTasks = task?.bundledTasks;
            });
          }
        });
      }
      // Update crewIds to null if scheduleDate is null
      !!Object.values(data).map((item) => {
        if (item.scheduleDate === null) {
          item.crewIds = null;
        }
        if (!bundleTasks && dragItemBacklogType === DragItemTypes.BACKLOG) {
          item.isBundled = false;
          item.bundledTasks = null;
        }
      });
      setTimeout(() => {
        onSaveAll(data);
      }, 0);
    },
    [
      backlogData,
      bundleTasks,
      dragItemBacklogType,
      onSaveAll,
      showContinue,
      tasks,
      tasktobeBundled,
      setSpinLoader,
    ],
  );

  const onError = useCallback(() => {
    setSpinLoader(false);
    const errorKeys = Object.keys(errors);
    if (errorKeys?.length) {
      scheduleDateRefs[errorKeys[0]].scrollIntoView(true);
    }
  }, [errors, scheduleDateRefs]);

  //takes startTime and returns time in minutes
  const getTimeInMinutes = (val) => {
    if (val !== undefined) {
      let t1 = val.split(':');
      return Number(t1[0]) * 60 + Number(t1[1]);
    } else {
      return 0;
    }
  };

  //takes minutes and returns time in HH:MM
  const getTimeInHoursMinutes = (min) => {
    //when multiple tasks are dropped and a task goes to previous day
    //setting startTime as starting of current day
    if (min < 0) {
      return '00:00';
    }
    var hours = Math.floor(min / 60);
    var minutes = min % 60;

    //always in hh:mm format
    hours = hours <= 9 ? '0' + hours : hours;
    minutes = minutes <= 9 ? '0' + minutes : minutes;
    return hours + ':' + minutes;
  };

  useEffect(() => {
    // trigger validation after initial render
    getBundleCrewList();
    trigger();
  }, [getBundleCrewList, trigger]);

  const getFooter = useMemo(() => {
    return (
      <>
        {showContinue && (
          <FooterContinueText>
            {t(
              'not_scheduled_message',
              'Some tasks are not scheduled. Are you sure you want to continue?',
            )}
          </FooterContinueText>
        )}
        {isError && <StyledError />}
        <FooterButtons onClick={(e) => e.stopPropagation()}>
          <SpinLoader showLoader={spinLoader} />
          <Button onClick={handleChange}>{t('general:cancel', 'Cancel')}</Button>
          {showContinue ? (
            <Button primary onClick={handleSubmit(handleSaveAll, onError)}>
              {t('general:continue', 'Continue')}
            </Button>
          ) : (
            <Button
              primary
              disabled={spinLoader || tasksNotToBeScheduled.length > 0}
              onClick={handleSubmit(handleSaveAll, onError)}
            >
              {t('general:save', 'Save')}
            </Button>
          )}
        </FooterButtons>
      </>
    );
  }, [
    handleChange,
    t,
    handleSaveAll,
    onError,
    handleSubmit,
    showContinue,
    isError,
    spinLoader,
    tasksNotToBeScheduled,
  ]);

  const getDefault = useCallback(
    (task) => {
      // if no lane, due or schedule pass the drop date through and use as the default date.
      if (dropDate) return getDateObject(dayjs(dropDate));
      // if no scheduleDate and dueDate
      if (task.scheduleDate === undefined && task.dueDate === undefined) return null;

      // onChange value was available on more/less button click
      if (getValues(`${task.id}.scheduleDate`)) {
        return getValues(`${task.id}.scheduleDate`);
      }

      // no shift and a schedule date is present (action on task table)
      if (shiftCount === 0 && task.scheduleDate) return getDateObject(dayjs(task.scheduleDateTz));

      // no shift count
      if (shiftCount === 0) return null;

      // handle shifting tasks on calendar by shiftCount
      const date = task.status === TaskStatus.SCHEDULED ? task.scheduleDate : task.dueDate || null;
      if (date == null) {
        return null;
      }
      return date
        ? getDateObject(
            dayjs(date)
              .tz(task.site?.timezone)
              .add(shiftCount, 'day'),
          )
        : getDateObject(
            dayjs(date)
              .tz(task.site?.timezone)
              .subtract(Math.abs(shiftCount), 'day'),
          );
    },
    [dropDate, getValues, shiftCount],
  );

  const getDefaultTime = useCallback(
    (startTime) => {
      if (dropTime) {
        return dropTime;
      }

      if (startTime == undefined) {
        return TasksTime.DEFAULT_START_TIME;
      }

      if (shiftCountMins == undefined) {
        return startTime;
      }

      let st = getTimeInMinutes(startTime);

      //add/subtract shift minutes
      if (shiftCountMins < 0) {
        st = st - Math.abs(shiftCountMins);
      } else {
        st = st + shiftCountMins;
      }

      return getTimeInHoursMinutes(st);
    },
    [dropTime, shiftCountMins],
  );

  const handleApplyClick = () => {
    const currentTasks = [...tasks];
    const assignAllValue = getValues('assignAllCrew');

    if (assignAllValue) {
      currentTasks.forEach((task) => {
        let taskScheduled = getValues(`${task.id}.scheduleDate`);
        if (taskScheduled) {
          setValue(`${task.id}.assignee`, assignAllValue);
          onChangeCrew(task, assignAllValue);
        }
      });
    }
  };

  const renderTaskDetails = (task, idx, taskArr) => {
    return (
      <TaskSchedule key={idx} moreOptions={showMoreOptions} tasks={taskArr}>
        {!showMoreOptions && (
          <div className="entity-info-main">
            {task.site?.name && (
              <span className="entity-text">
                <EntityIcon icon={Icons.SITE} />
                {task.site.name}
              </span>
            )}

            {task.asset?.name && (
              <>
                <span style={{ color: globalColors.grey3 }}>{' / '}</span>
                <span className="entity-text">
                  <EntitySiteIcon icon={Icons.TURBINE} />
                  {task.asset.name}
                </span>
              </>
            )}
            <TaskTitleInfo moreOptions={showMoreOptions}>
              <StyledSeverity level={task.priority} />
              <TaskText className="value">{task.title}</TaskText>
            </TaskTitleInfo>
          </div>
        )}

        <div className="task-flex">
          <div className="task-details">
            {showMoreOptions && (
              <div className="entity-info" style={{ marginTop: '20px' }}>
                {task.site?.name && (
                  <span className="entity-text">
                    <EntityIcon icon={Icons.SITE} />
                    {task.site.name}
                  </span>
                )}

                {task.asset?.name && (
                  <>
                    <span style={{ color: globalColors.grey3 }}>{' / '}</span>
                    <span className="entity-text">
                      <EntitySiteIcon icon={Icons.TURBINE} />
                      {task.asset.name}
                    </span>
                  </>
                )}
                <TaskTitleInfo moreOptions={showMoreOptions}>
                  <StyledSeverity level={task.priority} />
                  <TaskText className="value">
                    {task.title.length > 60 ? task.title.substring(0, 60) + '...' : task.title}
                  </TaskText>
                </TaskTitleInfo>
              </div>
            )}
            <FieldSet name={task.id} key={task.id}>
              <div>
                <InputWrapper>
                  <InputContainer>
                    <div className="input-container">
                      <div>
                        <TaskText>{t('eligible_start_date', 'Eligible Start Date')}</TaskText>
                        <TaskText className="value">
                          {task?.eligibleStartDate ? (
                            <div>
                              {dayjs(task.eligibleStartDateTz).format(DateTimeFormats.DEFAULT_DATE)}
                            </div>
                          ) : (
                            <div>—</div>
                          )}
                        </TaskText>
                      </div>
                      <div className="input-header">
                        <TaskText>{t('scheduled_date', 'Scheduled Date')}</TaskText>
                      </div>
                      <Controller
                        control={control}
                        name={`${task.id}.scheduleDate`}
                        defaultValue={getDefault(task)}
                        rules={{
                          validate: {
                            pastDate: (value) =>
                              value &&
                              dayjs(getDateObject(addUTCOffset(dayjs(), task.site?.timezone)))
                                .subtract(1, 'day')
                                .startOf('date')
                                .isBefore(dayjs(value).startOf('date')),
                            eligibleStart: (value) =>
                              value &&
                              task?.eligibleStartDate &&
                              dayjs(task.eligibleStartDate)
                                .subtract(1, 'day')
                                .startOf('date')
                                .isBefore(dayjs(value)),
                          },
                        }}
                        render={({ onChange, value }) => (
                          <DatePicker
                            className="input"
                            placeholderText={t('select_date', 'Select Date')}
                            onChange={onChange}
                            disabled={
                              approvalFlag &&
                              task?.flag?.toLowerCase() !== TaskFlag.APPROVED &&
                              enabledApprovalFlag
                            }
                            onCalendarClose={() => {
                              trigger();
                              resetCrewDetails(task);
                              setValue('assignAllCrew', null);
                            }}
                            selected={value || null}
                            popperPlacement={'auto'}
                            filterDate={(date) =>
                              dayjs(getDateObject(addUTCOffset(dayjs(), task.site?.timezone)))
                                .startOf('date')
                                .toDate() <= date
                            }
                            customInput={
                              <div>
                                <StyledInput
                                  style={{ height: '24px' }}
                                  value={
                                    value ? dayjs(value).format(DateTimeFormats.DEFAULT_DATE) : ''
                                  }
                                  ref={(ele) => (scheduleDateRefs[task.id] = ele)}
                                  onChange={() => {
                                    setTimeout(() => {
                                      trigger();
                                    }, 100);
                                  }}
                                  error={errors[task.id]?.scheduleDate && 'error'}
                                  disabled={
                                    approvalFlag &&
                                    task?.flag?.toLowerCase() !== TaskFlag.APPROVED &&
                                    enabledApprovalFlag
                                  }
                                />
                                <CalendarInputIcon icon={Icons.CALENDAR} className="input" />
                              </div>
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="input-container">
                      <div>
                        <TaskText>{t('scheduled_date', 'Scheduled Date')}</TaskText>
                        <TaskText className="value">
                          {task?.scheduleDate ? (
                            <div>
                              {dayjs(task.scheduleDateTz).format(DateTimeFormats.DEFAULT_DATE)}
                            </div>
                          ) : (
                            <div>—</div>
                          )}
                        </TaskText>
                      </div>
                      <div className="input-header">
                        <TaskText>{t('start_time', 'Start Time')}</TaskText>
                      </div>
                      <InputContainer>
                        <Controller
                          control={control}
                          name={`${task.id}.startTime`}
                          defaultValue={getDefaultTime(task.startTime)}
                          rules={{
                            //required: getValues(`${task.id}.scheduleDate`) ? true : false,
                            validate: {
                              required: (value) => {
                                if (
                                  getValues(`${task.id}.scheduleDate`) &&
                                  (value == null || value == '')
                                ) {
                                  return false;
                                }
                              },
                            },
                          }}
                          render={({ onChange, value }) => (
                            <StyledInput
                              onChange={onChange}
                              type="time"
                              style={{
                                height: '24px',
                                width: '100px',
                                marginRight: '10px',
                              }}
                              value={value}
                              error={errors[task.id]?.startTime}
                              disabled={
                                approvalFlag &&
                                task?.flag?.toLowerCase() !== TaskFlag.APPROVED &&
                                enabledApprovalFlag
                              }
                            />
                          )}
                        />
                      </InputContainer>
                    </div>

                    <div className="input-container">
                      <div>
                        <TaskText>{t('due_date', 'Due Date')}</TaskText>
                        <TaskText className="value">
                          {task?.dueDate ? (
                            <div>{dayjs(task.dueDateTz).format(DateTimeFormats.DEFAULT_DATE)}</div>
                          ) : (
                            <div>—</div>
                          )}
                        </TaskText>
                      </div>
                      <div className="input-header">
                        <TaskText>{t('tasks:form.assignee', 'Assignee')}</TaskText>
                      </div>
                      <InputContainer>
                        <Controller
                          control={control}
                          name={`${task.id}.assignee`}
                          defaultValue={updateDefaultValue(task)}
                          render={({ onChange, value }) => (
                            <StyledSelect
                              defaultValue={value}
                              placeholder={t('tasks:form.unassigned', 'Unassigned')}
                              onChange={(val) => {
                                onChange(val.value);
                                onChangeCrew(task, val.value);
                                getBundleCrewList();
                              }}
                              options={getCrewList(task)}
                              minWidth={120}
                              value={crews.find((val) => val.value === value)}
                              style={{ height: '26px', zIndex: 999 }}
                              disabled={
                                serviceGroupIds.length > 1 ||
                                !getValues(`${task.id}.scheduleDate`) ||
                                errors[task.id] ||
                                (approvalFlag &&
                                  task?.flag?.toLowerCase() !== TaskFlag.APPROVED &&
                                  enabledApprovalFlag)
                              }
                              menuPlacement={
                                showMoreOptions ? MenuPlacements.TOP : MenuPlacements.AUTO
                              }
                              moreOptions={showMoreOptions}
                            />
                          )}
                        />
                      </InputContainer>
                    </div>
                  </InputContainer>
                </InputWrapper>
                {errors[task.id]?.scheduleDate &&
                  errors[task.id]?.scheduleDate.type === 'pastDate' && (
                    <ErrorText>
                      {t('error_past_date', 'Scheduled date cannot be in the past')}
                    </ErrorText>
                  )}
                {errors[task.id]?.scheduleDate &&
                  errors[task.id]?.scheduleDate.type === 'eligibleStart' && (
                    <ErrorText>
                      {t(
                        'error_before_eligible_start',
                        'Scheduled date cannot be before eligible start date',
                      )}
                    </ErrorText>
                  )}
                {errors[task.id]?.startTime && errors[task.id]?.startTime.type === 'required' && (
                  <ErrorText>{t('start_time_required', 'Start time required')}</ErrorText>
                )}
              </div>
            </FieldSet>
          </div>
        </div>
      </TaskSchedule>
    );
  };

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onSaveAll}
      header={
        <>
          <ModalHeaderIcon icon={Icons.CALENDAR} /> {t('schedule_tasks', 'schedule Tasks')}
        </>
      }
      footer={getFooter}
      footerIndex={2}
      contentWidth
      padContent={false}
    >
      <DialogContainer tasks={tasks} moreOptions={showMoreOptions}>
        <DialogTopHeader>
          <OptionsButton onClick={() => setShowMoreOptions(!showMoreOptions)}>
            {showMoreOptions ? (
              <>
                <OptionsHeaderIconLeft icon={Icons.ARROW_RIGHT} />
                {t('less_options', 'less options')}
              </>
            ) : (
              <>
                <OptionsHeaderIcon icon={Icons.ARROW_RIGHT} />
                {t('more_options', 'more options')}
              </>
            )}
          </OptionsButton>
          <TaskSelectedText>
            {tasks.length} {t('tasks_selected', 'Tasks Selected')}
          </TaskSelectedText>
        </DialogTopHeader>
        <MainContainer>
          {showMoreOptions && (
            <OptionsContainer>
              <span className="options-menu-header">{t('schedule_to', 'schedule to')}:</span>
              <div className="tab-buttons">
                <TabButton
                  className={moreOptionsTab === 'set date' && 'current-tab'}
                  onClick={() => setMoreOptionsTab(TabOptions.SET_DATE)}
                >
                  {t('set_date', 'Set Date')}
                </TabButton>
                <TabButton
                  className={moreOptionsTab === 'shift' && 'current-tab'}
                  onClick={() => setMoreOptionsTab(TabOptions.SHIFT)}
                >
                  {t('shift', 'Shift')}
                </TabButton>
                <TabButton
                  className={moreOptionsTab === 'distribute' && 'current-tab'}
                  onClick={() => setMoreOptionsTab(TabOptions.DISTRIBUTE)}
                >
                  {t('distribute', 'Distribute')}
                </TabButton>
              </div>

              {/* MORE OPTIONS -> SET DATE TAB */}

              {moreOptionsTab === 'set date' && (
                <div className="set-date-tab-container">
                  <div className="description-container">
                    <span className="tab-description">
                      {t('schedule_selected_tasks', 'Schedule selected tasks to a specific date')}
                    </span>
                  </div>
                  <div className="set-date-form-container">
                    <SetDateTasksForm
                      triggerValidation={trigger}
                      tasks={tasks}
                      setTaskValues={setValue}
                      onGetBundleCrewList={getBundleCrewList}
                      onResetCrewDetails={resetCrewDetails}
                    />
                  </div>
                </div>
              )}

              {/* MORE OPTIONS -> SHIFT TAB */}

              {moreOptionsTab === 'shift' && (
                <div className="shift-tab-container">
                  <div className="description-container">
                    <span className="tab-description">
                      {t(
                        'shift_selected_tasks',
                        'Shift selected tasks forward or backward from the selected date',
                      )}
                    </span>
                  </div>
                  <div className="shift-form-container">
                    <ShiftTasksForm
                      triggerValidation={trigger}
                      tasks={tasks}
                      setTaskValues={setValue}
                    />
                  </div>
                </div>
              )}

              {/* MORE OPTIONS -> DISTRIBUTE TAB */}

              {moreOptionsTab === 'distribute' && (
                <div className="distribute-tab-container">
                  <div className="description-container">
                    <span className="tab-description">
                      {t(
                        'pick_two_parameters',
                        'Pick two parameters for your distribution and the days it will apply',
                      )}
                    </span>
                  </div>
                  <DistributeTasksForm
                    triggerValidation={trigger}
                    tasks={tasks}
                    setTaskValues={setValue}
                  />
                </div>
              )}
            </OptionsContainer>
          )}
          {onRemoveTasks && tasksNotToBeScheduled.length > 0 && (
            <>
              <RemoveTasksSection>
                <RemoveTasksMessageIcon />
                <RemoveTasksMessage>
                  {t('tasks:tasks_reschedule_dialog.not_approved_tasks', {
                    count: tasksNotToBeScheduled.length,
                  })}
                </RemoveTasksMessage>
                <RemoveTasksButton
                  onClick={() => onRemoveTasks(tasksNotToBeScheduled.map((v) => v.id))}
                >
                  <RemoveTasksIcon />
                  {t('tasks:tasks_reschedule_dialog.remove_tasks', 'Remove not approved task(s)')}
                </RemoveTasksButton>
              </RemoveTasksSection>
              <StyledForm flexColumn={tasks.length <= 0} moreOptions={showMoreOptions}>
                {tasksNotToBeScheduled.map(renderTaskDetails)}
              </StyledForm>
            </>
          )}
          <DialogSubHeader moreOptions={showMoreOptions}>
            <AssignAllText moreOptions={showMoreOptions}>
              {t('assign_all_to', 'Assign All To')}:{' '}
            </AssignAllText>
            {showMoreOptions && (
              <AssignAllSubText userLang={getProfileLang}>
                {t('crews_available', 'Crews available on scheduled date(s)')}
              </AssignAllSubText>
            )}
            <SubHeaderInputContainer moreOptions={showMoreOptions}>
              <Controller
                control={control}
                name="assignAllCrew"
                render={({ onChange, value }) => (
                  <StyledSelect
                    placeholder={t('select_crew', 'Select Crew')}
                    onChange={(val) => {
                      onChange(val.value);
                    }}
                    options={getBundleCrewList()}
                    minWidth={120}
                    value={crews.find((val) => val.value === value)}
                    disabled={serviceGroupIds.length > 1 || !getBundleCrewList().length}
                    menuPlacement={showMoreOptions ? MenuPlacements.BOTTOM : MenuPlacements.AUTO}
                    moreOptions={showMoreOptions}
                  />
                )}
              />
              <ApplyButton
                onClick={handleApplyClick}
                disabled={serviceGroupIds.length > 1 || !getBundleCrewList().length}
              >
                {t('apply', 'Apply')}
              </ApplyButton>
            </SubHeaderInputContainer>
          </DialogSubHeader>
          <StyledForm flexColumn={tasks.length <= 0} moreOptions={showMoreOptions}>
            {tasksToBeScheduled.map(renderTaskDetails)}
          </StyledForm>
        </MainContainer>
      </DialogContainer>
    </Dialog>
  );
};

RescheduleTasksDialog.propTypes = {
  tasks: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func,
  onSaveAll: PropTypes.func,
  onRemoveTasks: PropTypes.func,
  shiftCount: PropTypes.number,
  shiftCountMins: PropTypes.number,
  dropDate: PropTypes.instanceOf(Object),
  dropTime: PropTypes.string,
  isError: PropTypes.bool,
  crewLaneInfo: PropTypes.instanceOf(Object),
  tasktobeBundled: PropTypes.instanceOf(Object),
  bundleTasks: PropTypes.instanceOf(Object),
  dragItemBacklogType: PropTypes.instanceOf(Object),
};

RescheduleTasksDialog.defaultValues = {
  onClose: () => null,
  onSaveAll: () => null,
  shiftCount: 0,
  shiftCountMins: 0,
  dropDate: null,
  dropTime: null,
  isError: false,
  crewLaneInfo: null,
  tasktobeBundled: null,
  bundleTasks: null,
  dragItemBacklogType: null,
};

Controller.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.func,
};
