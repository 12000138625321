import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { EventType, PredicateMatch, SegmentOffering } from '@ge/models/constants';

import { AppScopes } from '../models/scopes';

import { getUserAccessToken, getSubject, isOfflineMode, logout } from './auth';

// Wait 10 * 500ms (5 seconds) for token to show up in session storage
const MAX_ATTEMPTS = 10;

let updatingTokens = false;
const setUpdating = (bool) => {
  updatingTokens = bool;
};

const getAccessTokenKey = (subject, segmentOffering) => `${segmentOffering}.access.${subject}`;

const isTokenExpired = (jwt) => {
  if (!jwt || jwt.indexOf('.') < 0) return true;
  const { exp } = jwt_decode(jwt);
  if (!exp || exp === 0) return true;
  // // Give a 1 second buffer
  return Date.now() >= exp * 1000 - 1;
};

export const getIacToken = async (accessToken) =>
  updateIacTokens(accessToken, { sub: getSubject() });

const updateIacTokens = async (jwt, { sub }) => {
  let response;

  try {
    setUpdating(true);

    const options = {
      headers: {
        authorization: jwt,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API}/auth/access-tokens`,
      options,
    );

    const { segmentTokens, metaData } = response.data;

    window.dispatchEvent(
      new CustomEvent(EventType.PERMISSION_METADATA, {
        detail: { payload: metaData },
      }),
    );

    segmentTokens.forEach((token) => {
      sessionStorage.setItem(getAccessTokenKey(sub, token.segmentOffering), token.accessToken);
    });

    return {
      segmentTokens: segmentTokens.reduce(
        (acc, token) => ({
          ...acc,
          [token.segmentOffering]: token.accessToken,
        }),
        {},
      ),
      metadata: metaData,
    };
  } catch (err) {
    // attempt to extract response from axios error
    response = err.response;
  } finally {
    setUpdating(false);
  }

  // really want to test specifically for 403, but getting errors with undefined response so including for now
  // TODO: review why we are getting errors without response when network tab shows 403
  if (!response || response.status === 403) {
    // without await, it was causing the app to hang on next login with some oidc state being broken
    await logout();
    // this was the existing logic, leaving in place to avoid breaking things (but should review)
  } else {
    // it seems like OIDC and IAC get out of sync...
    sessionStorage.clear();

    window.location.reload();
  }
};

const getSegmentTokenFromStorage = async (segmentOffering, attempt = 0) => {
  if (attempt > MAX_ATTEMPTS) {
    return Promise.reject(`Unable to fetch IAC token '${segmentOffering}' from storage.`);
  }
  const sub = getSubject();
  // Get stored tokens

  const accessToken = sessionStorage.getItem(getAccessTokenKey(sub, segmentOffering));
  if (!accessToken || isTokenExpired(accessToken)) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(getSegmentTokenFromStorage(segmentOffering, ++attempt)), 500),
    );
  }
  return Promise.resolve(accessToken);
};

/**
 * Get the Access token for the specified segment offering.
 * @param segmentOffering The current segment offering
 * @returns {Promise<String>}
 */
const getIacSegmentAccessToken = async (segmentOffering) => {
  if (isOfflineMode) return Promise.resolve('iac.mock.token');

  if (!Object.values(SegmentOffering).includes(segmentOffering)) {
    return Promise.resolve(getUserAccessToken());
  }

  const sub = getSubject();
  const jwt = getUserAccessToken();
  const accessToken = sessionStorage.getItem(getAccessTokenKey(sub, segmentOffering));

  if (!accessToken || isTokenExpired(accessToken)) {
    if (updatingTokens) {
      return getSegmentTokenFromStorage(segmentOffering);
    }

    const { segmentTokens: updatedTokens } = await updateIacTokens(jwt, { sub });
    return Promise.resolve(updatedTokens[segmentOffering]);
  }

  if (!sessionStorage.getItem('tenantId')) {
    const {
      tenant: { tenantId },
    } = jwt_decode(accessToken);
    sessionStorage.setItem('tenantId', tenantId);
  }

  return Promise.resolve(accessToken);
};

const getAuthorizedScopes = () => {
  // TODO: Get the authorized scopes for the user
  // from the access tokens.
  return Object.values(AppScopes);
};

export const isAuthorized = (scopes, matchType) => {
  const scopeArray = scopes instanceof Array ? scopes : [scopes];
  const matcher = matchType || PredicateMatch.SOME;

  switch (matcher) {
    case PredicateMatch.ALL:
      return scopeArray.every((scope) => getAuthorizedScopes().includes(scope));
    case PredicateMatch.SOME:
      return scopeArray.some((scope) => getAuthorizedScopes().includes(scope));
    case PredicateMatch.NONE:
      return !scopeArray.some((scope) => getAuthorizedScopes().includes(scope));
    default:
      return false;
  }
};

export { getIacSegmentAccessToken };
