import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';

const SectionSegment = styled(CollapsiblePanel)`
  margin: 0px 12px 15px;
  &:first-of-type {
    margin-top: 20px;
  }
  .title {
    flex: 1;
    border: 0;
    svg {
      fill: ${(props) => props.theme.entityDetails.cases.details.collapseArrow};
    }
    h2 {
      flex: 1;
      font-size: 18px;
      line-height: 21px;
      &.add-margin {
        margin-top: 10px;
      }
    }
    .button-link {
      display: none;
    }
    &.expanded {
      .button-link {
        display: block;
      }
    }
  }
  .collapsible-panel-content {
    padding: 12px 0px 12px 21px;
    &.expanded {
      border-bottom: 1px solid #1c252d;
    }
  }
  &:last-of-type {
    .collapsible-panel-content {
      &.expanded {
        border-bottom: none;
      }
    }
  }
`;

export const FormCollapsiblePanel = ({
  isOpen,
  className,
  title,
  headerContent,
  children,
  ...rest
}) => {
  const [triggerHeight, setTriggerHeight] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTriggerHeight(isOpen);
    });
    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <SectionSegment
      className={className}
      headerContent={
        <>
          <h2>{title}</h2>
          {headerContent}
        </>
      }
      expanded={true}
      triggerHeight={triggerHeight}
      {...rest}
    >
      {children}
    </SectionSegment>
  );
};

FormCollapsiblePanel.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  headerContent: PropTypes.node,
  children: PropTypes.node,
};

FormCollapsiblePanel.defaultValues = {
  className: '',
  title: '',
  headerContent: <></>,
  children: <></>,
};
