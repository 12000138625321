import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';
import { Placeholders } from '@ge/models/constants';

// All available columns and groups for Contractual Availability table
export const ContractualAvailabilityColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  // Filters [affects data fetching]
  GROUP_SHOW_BY: 'Show By',
  SHOW_BY_ASSET: 'Asset',
  SHOW_BY_SITE: 'Site',
  SHOW_BY_REPORTSCOPE: 'Report Scope',

  // Asset Details
  GROUP_ASSET_DETAILS: 'group-asset-details',
  ASSET: 'padNumber',

  // Static dimensions* for aggregated KPI values
  // *NB:
  //  For most table-type widgets, data is one-dimensional and is shown in a columnar format.
  //  Users are then able to choose which columns to display or hide for their report.
  //  This widget displays tabular data, and supports configuration options for controlling
  //  visibility of certain data shown in both columns and rows.
  //  Whether data is shown in columns, rows, or another format is just an implementation detail.
  //  I.e., the values for these config options are as specified in the server template data;
  //  don't get thrown off by the "columns" vs "rows" distinction - it doesn't matter here.
  GROUP_ENTITY_AGGR_KPI: 'Columns',
  ENTITY_WEIGHTED_KPI: 'Average per Asset/Site',
  GROUP_TIME_AGGR_KPI: 'Rows',
  TIME_WEIGHTED_KPI: 'Average Availability',

  // Dynamic columns for asset KPI values:
  // Widget supports displaying up to 11 dynamic columns of data.
  // Column labels and visibility are determined at runtime based on API response.
  GROUP_DYNAMIC_COLUMNS: 'group-dynamic-cols',
  ONE: 'dynamic-col-0',
  TWO: 'dynamic-col-1',
  THREE: 'dynamic-col-2',
  FOUR: 'dynamic-col-3',
  FIVE: 'dynamic-col-4',
  SIX: 'dynamic-col-5',
  SEVEN: 'dynamic-col-6',
  EIGHT: 'dynamic-col-7',
  NINE: 'dynamic-col-8',
  TEN: 'dynamic-col-9',
  ELEVEN: 'dynamic-col-10',
};

// Contractual Availability table configuration
export const contractualAvailabilityColumnDefs = {
  [ContractualAvailabilityColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [ContractualAvailabilityColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'contractual_availability.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  // This only a configuration option group; these fields do not actually appear in the rendered table.
  [ContractualAvailabilityColumns.GROUP_SHOW_BY]: {
    type: 'radio',
    labels: [
      {
        a11yKey: 'contractual_availability.options.show_by',
        a11yDefault: 'Show By',
      },
    ],
    cols: {}, // For compatibility with table factory, but we don't want any columns created for this group.
    fields: {
      [ContractualAvailabilityColumns.SHOW_BY_ASSET]: {
        labels: [
          {
            a11yKey: 'contractual_availability.options.show_by_asset',
            a11yDefault: 'Asset',
          },
        ],
      },
      [ContractualAvailabilityColumns.SHOW_BY_SITE]: {
        locked: true, // not supported in MVP
        labels: [
          {
            a11yKey: 'contractual_availability.options.show_by_site',
            a11yDefault: 'Site',
          },
        ],
      },
      [ContractualAvailabilityColumns.SHOW_BY_REPORTSCOPE]: {
        locked: true, // not supported in MVP
        labels: [
          {
            a11yKey: 'contractual_availability.options.show_by_reportscope',
            a11yDefault: 'Report Scope',
          },
        ],
      },
    },
  },
  [ContractualAvailabilityColumns.GROUP_ASSET_DETAILS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'contractual_availability.columns.asset_details',
        a11yDefault: 'Asset Details',
      },
    ],
    cols: {
      [ContractualAvailabilityColumns.ASSET]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.asset_name',
            a11yDefault: 'Asset',
          },
        ],
      },
    },
  },
  [ContractualAvailabilityColumns.GROUP_DYNAMIC_COLUMNS]: {
    cols: {
      [ContractualAvailabilityColumns.ONE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.TWO]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.THREE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.FOUR]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.FIVE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.SIX]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.SEVEN]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.EIGHT]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.NINE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.TEN]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ContractualAvailabilityColumns.ELEVEN]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
    },
  },
  [ContractualAvailabilityColumns.GROUP_ENTITY_AGGR_KPI]: {
    labels: [
      {
        a11yKey: 'contractual_availability.columns.group_entity_aggr',
        a11yDefault: 'Columns',
      },
    ],
    cols: {
      [ContractualAvailabilityColumns.ENTITY_WEIGHTED_KPI]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'contractual_availability.columns.entity_weighted_kpi',
            a11yDefault: 'Average per Asset/Site',
          },
        ],
      },
    },
  },
  [ContractualAvailabilityColumns.GROUP_TIME_AGGR_KPI]: {
    labels: [
      {
        a11yKey: 'contractual_availability.columns.group_time_aggr',
        a11yDefault: 'Rows',
      },
    ],
    cols: {}, // For compatibility with table factory, but we don't want any columns created for this group.
    fields: {
      [ContractualAvailabilityColumns.TIME_WEIGHTED_KPI]: {
        labels: [
          {
            a11yKey: 'contractual_availability.columns.time_weighted_kpi',
            a11yDefault: 'Average Availability',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const contractualAvailabilityOmittedCols = [
  ContractualAvailabilityColumns.GROUP_SELECTED,
  ContractualAvailabilityColumns.SELECTED,
  ContractualAvailabilityColumns.GROUP_ASSET_DETAILS,
  ContractualAvailabilityColumns.ASSET,
  ContractualAvailabilityColumns.GROUP_DYNAMIC_COLUMNS,
  ContractualAvailabilityColumns.ONE,
  ContractualAvailabilityColumns.TWO,
  ContractualAvailabilityColumns.THREE,
  ContractualAvailabilityColumns.FOUR,
  ContractualAvailabilityColumns.FIVE,
  ContractualAvailabilityColumns.SIX,
  ContractualAvailabilityColumns.SEVEN,
  ContractualAvailabilityColumns.EIGHT,
  ContractualAvailabilityColumns.NINE,
  ContractualAvailabilityColumns.TEN,
  ContractualAvailabilityColumns.ELEVEN,
];

// Default order and visibility configuration for contractual availability columns
// NOTE: For state selection, EVERY column must have a position and visibility default!
export const contractualAvailabilityDefaultCols = [
  {
    id: ContractualAvailabilityColumns.GROUP_SELECTED,
    cols: [
      {
        id: ContractualAvailabilityColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: ContractualAvailabilityColumns.GROUP_SHOW_BY,
    // Only one of these options is currently supported.
    // This flag tells the config loader to use the values specified here
    // and not overwrite with configuration values sent from the server.
    ignoreTemplateConfig: true,
    // NB: the name `cols` is misleading here as these are only configuration
    // options for the module; they are not displayed in the table itself.
    cols: [
      {
        id: ContractualAvailabilityColumns.SHOW_BY_ASSET,
        visible: true,
      },
      {
        id: ContractualAvailabilityColumns.SHOW_BY_SITE,
        visible: false, // Not supported yet
      },
      {
        id: ContractualAvailabilityColumns.SHOW_BY_REPORTSCOPE,
        visible: false, // Not supported yet
      },
    ],
  },
  {
    id: ContractualAvailabilityColumns.GROUP_ASSET_DETAILS,
    cols: [
      {
        id: ContractualAvailabilityColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: ContractualAvailabilityColumns.GROUP_DYNAMIC_COLUMNS,
    cols: [
      {
        id: ContractualAvailabilityColumns.ONE,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.TWO,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.THREE,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.FOUR,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.FIVE,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.SIX,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.SEVEN,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.EIGHT,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.NINE,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.TEN,
        visible: false,
      },
      {
        id: ContractualAvailabilityColumns.ELEVEN,
        visible: false,
      },
    ],
  },
  {
    id: ContractualAvailabilityColumns.GROUP_ENTITY_AGGR_KPI,
    cols: [
      {
        id: ContractualAvailabilityColumns.ENTITY_WEIGHTED_KPI,
        visible: true,
      },
    ],
  },
  {
    id: ContractualAvailabilityColumns.GROUP_TIME_AGGR_KPI,
    // NB: the name `cols` is misleading here as this data is displayed as a row, not
    // a column, but the distinction is not terribly important. The configuration is
    // necessary for the purpose of hiding/showing the row from the sidebar module panel.
    cols: [
      {
        id: ContractualAvailabilityColumns.TIME_WEIGHTED_KPI,
        visible: true,
      },
    ],
  },
];
