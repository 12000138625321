import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';

// NOTE(greg): This is just a placeholder for now so that the CA reports don't error when trying to load info for the table modules.

// All available columns and groups for manually adjusted events
export const ManuallyAdjustedEventsColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  GROUP_EVENT: 'Event',
  ADJUSTED_BY: 'Adjusted By',
  ADJUSTED_DATE_TIME: 'Adjusted Date/Time',
  APPROVAL_DATE: 'Approval Date',
  ASSET_NAME: 'Asset Name',
  CODE: 'Code',
  DURATION: 'Duration',
  EVENT_CATEGORY: 'Event Category',
  EVENT_IMPACT: 'Event Impact',
  START_END_TIME: 'Start / End Time',
  TITLE: 'Title',
};

// Assets of Concern table configuration
export const manuallyAdjustedEventsColumnDefs = {
  [ManuallyAdjustedEventsColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [ManuallyAdjustedEventsColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [ManuallyAdjustedEventsColumns.GROUP_EVENT]: {
    align: 'left',
    cols: {
      [ManuallyAdjustedEventsColumns.ASSET_NAME]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.asset_name',
            a11yDefault: 'Asset Name',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.CODE]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.code',
            a11yDefault: 'Code',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.EVENT_CATEGORY]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.event_category',
            a11yDefault: 'Event Category',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.TITLE]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.title',
            a11yDefault: 'Title',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.START_END_TIME]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.start_end_time',
            a11yDefault: 'Start / End Time',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.DURATION]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.duration',
            a11yDefault: 'Duration',
          },
        ],
      },
      // Commenting out this column for now as the data doesn't support it
      // [ManuallyAdjustedEventsColumns.EVENT_IMPACT]: {
      //   labels: [
      //     {
      //       a11yKey: 'manually_adjusted_events.columns.event_impact',
      //       a11yDefault: 'Event Impact',
      //     },
      //   ],
      // },
      [ManuallyAdjustedEventsColumns.ADJUSTED_DATE_TIME]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.adjusted_date_time',
            a11yDefault: 'Adjusted Date/Time',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.ADJUSTED_BY]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.adjusted_by',
            a11yDefault: 'Adjusted By',
          },
        ],
      },
      [ManuallyAdjustedEventsColumns.APPROVAL_DATE]: {
        labels: [
          {
            a11yKey: 'manually_adjusted_events.columns.approval_date',
            a11yDefault: 'Approval Date',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const manuallyAdjustedEventsOmittedCols = [
  ManuallyAdjustedEventsColumns.GROUP_SELECTED,
  ManuallyAdjustedEventsColumns.SELECTED,
  // Omitting this column for now as the data doesn't support it
  ManuallyAdjustedEventsColumns.EVENT_IMPACT,
];

// Default order and visibility configuration for manually adjusted events columns
// NOTE: For state selection, EVERY column must have a position and visibility default!
export const manuallyAdjustedEventsDefaultCols = [
  {
    id: ManuallyAdjustedEventsColumns.GROUP_SELECTED,
    cols: [
      {
        id: ManuallyAdjustedEventsColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: ManuallyAdjustedEventsColumns.GROUP_EVENT,
    cols: [
      {
        id: ManuallyAdjustedEventsColumns.ASSET_NAME,
        visible: true,
      },
      {
        id: ManuallyAdjustedEventsColumns.CODE,
        visible: true,
      },
      {
        id: ManuallyAdjustedEventsColumns.EVENT_CATEGORY,
        visible: true,
      },
      {
        id: ManuallyAdjustedEventsColumns.TITLE,
        visible: true,
      },
      {
        id: ManuallyAdjustedEventsColumns.START_END_TIME,
        visible: false,
      },
      {
        id: ManuallyAdjustedEventsColumns.DURATION,
        visible: true,
      },
      // Commenting out this column for now as the data doesn't support it
      // {
      //   id: ManuallyAdjustedEventsColumns.EVENT_IMPACT,
      //   visible: false,
      // },
      {
        id: ManuallyAdjustedEventsColumns.ADJUSTED_DATE_TIME,
        visible: false,
      },
      {
        id: ManuallyAdjustedEventsColumns.ADJUSTED_BY,
        visible: true,
      },
      {
        id: ManuallyAdjustedEventsColumns.APPROVAL_DATE,
        visible: true,
      },
    ],
  },
];
