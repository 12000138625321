import { ChartType } from '@ge/components/charts';
import { chartColors } from '@ge/tokens/charts';
import { globalColors, globalGradients, StateColor, StatusColor } from '@ge/tokens/colors';

// eslint-disable-next-line
export const darkTheme = {
  name: 'Dark',
  colors: globalColors,
  backgroundColor: globalColors.grey,
  layout: {
    errorColor: globalColors.straw5,
    errorColor2: StatusColor.DANGER,
    textColor: globalColors.grey6,
    mainBackgroundColor: globalColors.grey,
    sidebarBackgroundColor: globalColors.slate15,
    sidebarBoxShadow: `-1px 0 0 0 ${globalColors.grey0}, 5px 0 16px 0 rgba(0,0,0,0.3)`,
    sidebarBorderTopColor: globalColors.slate25,
  },
  profile: {
    selectBorderColor: globalColors.stone3,
    borderTopColor: globalColors.grey,
  },
  assetCard: {
    titleBackgroundColor: globalColors.slate2,
    contentBackgroundColor: globalColors.slate2,
    textColor: globalColors.grey6,
    secondaryTextColor: globalColors.grey6,
    borderColor: globalColors.slate1,
    iconColor: globalColors.grey6,
    chevronColor: globalColors.slate3,
    dangerColor: globalColors.red2,
  },
  dangerColor: globalColors.red2,
  mainNav: {
    textColor: globalColors.slate4,
    dataTextColor: globalColors.slate5,
    backgroundColor: globalColors.slate1,
    iconColor: globalColors.slate4,
    sectionBorderColors: globalColors.slate2,
  },
  navigation: {
    iconColor: globalColors.slate5,
    borderColor: globalColors.grey,
    currentRouteDividerColor: globalColors.slate3,
    l1BorderColor: globalColors.grey9,
    linkColor: globalColors.slate5,
    linkActiveBackgroundColor: globalColors.grey9,
    linkActiveColor: globalColors.white,
    linkBorderColor: globalColors.slate2,
    linkActiveBorderColor: globalColors.teal3,
    backgroundColor: globalColors.slate1,
    chevronColor: globalColors.slate4,
    chevronHoverColor: globalColors.teal3,
    dropdownBorderBottomColor: globalColors.teal,
  },
  subNavigation: {
    linkColor: globalColors.grey4,
    linkActiveColor: globalColors.white,
    linkBorderColor: globalColors.slate2,
    linkActiveBorderColor: globalColors.teal3,
  },
  themeSelector: {
    iconColor: globalColors.teal3,
    iconColorActive: globalColors.white,
    chevronColor: globalColors.slate2,
    headerColor: globalColors.slate7,
    tabsBackgroundColor: globalColors.slate1,
    tabsIconColor: globalColors.white,
    tabsTextColor: globalColors.white,
    tabsActiveBackgroundColor: globalColors.teal2,
    tabsActiveTextColor: globalColors.white,
    panelBackgroundColor: globalColors.slate12,
    footerBackgroundColor: globalColors.slate2,
    badgeColor: globalColors.slate4,
    siteActiveBackground: globalColors.straw5,
    toggleTextColor: globalColors.slate5,
    toggleChevronColor: globalColors.slate4,
    toggleChevronActiveColor: globalColors.white,
    toggleTypeIcon: globalColors.slate4,
    togglePanelBackground: globalColors.slate6,
    filterIconColor: globalColors.slate2,
    filterInputColor: globalColors.white,
    listHeaderColor: globalColors.grey6,
    listBackgroundColor: globalColors.slate12,
    listBackgroundColorOdd: globalColors.slate12,
    listBackgroundActiveColor: globalColors.teal7,
    listButtonColor: globalColors.grey6,
    listBorderColor: globalColors.slate1,
    selectedTextColor: globalColors.grey7,
    siteIconColor: globalColors.grey6,
    someSitesSelectedIconColor: globalColors.grey6,
    selectAllActiveBackgroundColor: globalColors.teal6,
    borderNavbarInput: globalColors.grey9,
    viewSelector: {
      footerBackgroundColor: globalColors.grey9,
      tabsBackgroundColor: globalColors.slate16,
      iconContainerBackgroundColor: globalColors.slate3,
      backgroundColor: globalColors.grey9,
      textColor: globalColors.grey7,
    },
    modal: {
      backgroundColor: globalColors.slate2,
      boxShadow: '0px 0 25px 0 rgba(0, 0, 0, 0.5)',
      textColor: globalColors.grey5,
    },
    panel: {
      textColor: globalColors.grey6,
      groupOddBackground: globalColors.grey6,
      selectAllColor: globalColors.grey6,
    },
    customView: {
      saveButtonColor: globalColors.white,
      inputBorderColor: globalColors.slate4,
      inputColor: globalColors.white,
    },
  },
  profileNav: {
    backgroundColor: globalColors.slate1,
    borderTopColor: globalColors.grey,
    chevronColor: globalColors.slate4,
    textColor: globalColors.grey7,
    linkIconTextColor: globalColors.slate5,
    linkIconTextActiveColor: globalColors.white,
    linkBorderColor: globalColors.slate2,
    linkActiveBorderColor: globalColors.teal3,
  },
  badge: {
    dismissIconBackgroundColor: globalColors.slate1,
    dismissIconColor: globalColors.slate5,
  },
  button: {
    backgroundColor: 'linear-gradient(-179deg, #38434E 0%, #2D3740 62%, #2A323B 100%)',
    backgroundColor2: 'linear-gradient(179.99deg, #38434e 0%, #2d3740 62.55%, #2a323b 100%)',
    border: `solid 1px ${globalColors.slate2}`,
    textColor: globalColors.white,
    iconColor: globalColors.slate3,
    fontWeight: 500,
    disabled: {
      fontWight: 300,
      textColor: globalColors.grey4,
    },
    primary: {
      backgroundColor: globalGradients.tealgradient4,
      border: `solid 1px ${globalColors.teal1}`,
      textColor: globalColors.white,
      textShadow: '1px 1px 2px rgba(0,0,0,0.30)',
      fontWeight: 700,
    },
  },
  radioPanel: {
    background: globalColors.slate1,
    caretColor: globalColors.grey3,
    checkedBackgroundColor: globalColors.teal4,
    checkedBorderColor: globalColors.teal,
    disabledTextColor: globalColors.grey2,
  },
  select: {
    primaryBackground: 'linear-gradient(-180deg, #237B8C 0%, #1E6978 100%)',
    primaryBackground2: 'linear-gradient(180deg, #237B8C 0%, #1E6978 100%)',
    primaryBorder: globalColors.teal1,
    primaryTextColor: globalColors.white,
    secondaryBackground: 'linear-gradient(-179deg, #445A6C 0%, #38434E 62%, #38434E 100%)',
    secondaryBorder: '#283038',
    secondaryTextColor: globalColors.white,
    tertiaryBackground: 'transparent',
    tertiaryBorder: 'transparent',
    tertiaryTextColor: globalColors.white,
    basicBackground: 'transparent',
    basicTextColor: globalColors.white,
    labelTextColor: globalColors.grey4,
    optionBackground: globalColors.slate2,
    optionColor: globalColors.grey7,
    iconColor: globalColors.slate5,
    hoverColor: globalColors.teal1,
    chevronColor: globalColors.slate3,
    chevronPrimaryColor: globalColors.white,
    checkmarkColor: globalColors.slate5,
    errorBorder: `solid 1px ${globalColors.red1}`,
    searchBackground: globalColors.slate1,
    searchBorderColor: globalColors.grey9,
    searchPlaceholderColor: globalColors.grey3,
    footerBoxShadow: globalColors.slate12,
    borderColor: globalColors.slate9,
    recurrenceCustomMenu: {
      backgroundColor: globalColors.slate8,
    },
  },
  videoPlayer: {
    controlBarBackgroundColor: 'rgba(216, 216, 216, 0.15)',
    fullscreenExitIcon: globalColors.slate4,
    iconColor: globalColors.white,
    progressBarBackgroundColor: 'rgba(0, 0, 0, 0.34)',
    progressBarColor: globalColors.white,
  },
  input: {
    border: globalColors.grey9,
    borderNavbarInput: globalColors.grey9,
    invalidBorder: globalColors.red1,
    background: globalColors.slate1,
    textColor: globalColors.white,
    labelTextColor: globalColors.grey4,
    placeholderTextColor: globalColors.grey3,
  },
  checkbox: {
    iconColor: globalColors.teal2,
  },
  radio: {
    borderColor: globalColors.slate4,
    activeColor: globalColors.teal2,
  },
  collapsiblePanel: {
    carotIconColor: globalColors.black,
    borderBottomColor: globalColors.stone3,
    subHeader: globalColors.teal2,
  },
  ribbonFilter: {
    textColor: globalColors.slate4,
    dataTextColor: globalColors.grey5,
    backgroundColor: globalColors.slate2,
    iconColor: globalColors.slate3,
    borderColor: globalColors.grey0,
    activeTextColor: globalColors.white,
    activeBackgroundColor: globalColors.slate2,
  },
  clearColumnFilterBtn: {
    textColor: globalColors.white,
    iconColor: globalColors.slate4,
  },
  table: {
    fixedBorderColor: globalColors.slate25,
    sectionTextColor: globalColors.grey5,
    columnTextColor: globalColors.grey4,
    sidebarHeaderBackgroundColor: globalColors.slate15,
    evenRowBackground: globalColors.slate16,
    headerBackgroundColor: globalColors.grey,
    headerBorderColor: globalColors.grey1,
    headerSortColor: globalColors.white,
    dataTextColor: globalColors.grey7,
    dataTextColorLight: globalColors.grey4,
    oddRowColor: globalColors.grid0,
    evenRowColor: globalColors.grid1,
    rowColor: 'transparent',
    border: globalColors.grey,
    borderTransparent: globalColors.slate12,
    arrowColor: globalColors.slate4,
    arrowColorRed: globalColors.red2,
    iconErrorColor: globalColors.red2,
    iconInactiveColor: globalColors.slate3,
    iconGreyColor: globalColors.slate4,
    iconLightColor: globalColors.slate5,
    actionsMenuButtonTextColor: globalColors.grey7,
    actionsMenuButtonIconColor: globalColors.slate5,
    actionsMenuButtonBackgroundColor: globalColors.teal1,
    actionsMenuButtonBorderColor: '#237383',
    dataColor: globalColors.slate5,
    noDataColor: globalColors.red2,
    subTitleTextColor: globalColors.grey4,
    hoverColor: globalColors.teal1,
    dragDropBackgroundColor: globalColors.teal2,
    dragDropBorderColor: globalColors.teal1,
    warningIconColor: globalColors.white,
    warningIconBackgroundColor: globalColors.stone1,
    troubleshootingIconColor: globalColors.white,
    hoverBackground: globalColors.teal4,
    textLightColor: globalColors.stone3,
    metricSeparator: globalColors.grey2,
    entityCell: globalColors.teal3,
    iconHighlightColor: globalColors.white,
    rowHoverBackground: '#232B2D',
    rowSelectedBackground: globalColors.teal4,
    personIconColor: globalColors.slate2,
    iconCompletedColor: globalColors.pine3,
    iconNotEligibleColor: globalColors.grey2,
  },
  tasksTracker: {
    buttonBackgroundColor: globalColors.slate2,
    buttonSeperatorColor: globalColors.slate1,
    buttonTextColor: globalColors.slate5,
    buttonSelectedTextColor: globalColors.white,
    addIconButtonColor: globalColors.teal1,
    whiteColor: globalColors.white,
    manageViewsHeaderText: globalColors.grey4,
    defaultViewsText: globalColors.grey7,
    manageViewsCancelBtnColor: globalColors.slate9,
    manageViewsSaveBtnColor: globalColors.teal12,
    inputTextError: globalColors.red2,
    inputBackgroundColor: globalColors.slate1,
    manageViewsDividerColor: globalColors.grey9,
    reorderCancelBtnBorderColor: globalColors.slate12,
    reorderSaveBtnBorderColor: globalColors.teal1,
  },
  signal: {
    active: globalColors.grey6,
    disabled: globalColors.grey2,
    warning: globalColors.red2,
  },
  siteStatusBar: {
    backgroundColor: globalColors.grey0,
    available: StateColor.AVAILABLE,
    online: StateColor.ONLINE,
    impacted: StateColor.IMPACTED,
    tripped: StateColor.TRIPPED,
    stopped: StateColor.STOPPED,
    noData: StateColor.NO_DATA,
    netComm: StateColor.NO_DATA,
    maintenance: StateColor.MAINTENANCE,
    repair: StateColor.REPAIR,
  },
  siteProductionBar: {
    backgroundColor: globalColors.grey0,
    spacerColor: globalColors.grey1,
    fillColor: globalColors.grey3,
  },
  miniBarChart: {
    altBackgroundColor: globalColors.blue3,
    backgroundColor: globalColors.stone3,
    warningBackgroundColor: globalColors.orange1,
    dangerBackgroundColor: globalColors.red2,
    fillColor: globalColors.stone1,
    altFillColor: globalColors.blue1,
    warningFillColor: globalColors.stone1,
    dangerFillColor: globalColors.stone1,
    indicatorColor: globalColors.green3,
    upperIndicatorColor: globalColors.blue6,
    altIndicatorFillColor: globalColors.white,
    warningIndicatorBorderColor: 'rgba(217,133,67,0.50)',
    dangerIndicatorColor: globalColors.red1,
    dangerIndicatorBorderColor: globalColors.red2,
  },
  tabs: {
    borderColor: globalColors.slate2,
    activeBorderColor: globalColors.teal3,
    textColor: globalColors.grey4,
    activeTextColor: globalColors.grey7,
  },
  entityDetails: {
    detailsPanelAfter: globalColors.slate12,
    backgroundColor: globalColors.slate1,
    popoutHeaderBorderColor: globalColors.slate25,
    borderColor: globalColors.teal1,
    headerBackground: 'linear-gradient(#38434d, #44596a)',
    headerIconColor: globalColors.slate5,
    hoverIconColor: globalColors.white,
    headerBadgeColor: globalColors.slate3,
    headerBadgeFontColor: globalColors.grey7,
    headerBorderColor: globalColors.grey9,
    tabContentBackground: globalColors.grey9,
    tableHeaderBorder: globalColors.slate15,
    tableRowBorder: globalColors.slate12,
    sitePanelBlockBackground: globalColors.slate12,
    sitePanelBlockBorder: globalColors.slate15,
    overviewBlockBackground: globalColors.slate15,
    overviewBorderColor: globalColors.slate2,
    overviewBlockInsetBackground: globalColors.slate12,
    overviewTableBorderColor: globalColors.slate2,
    overViewTableHeadTextColor: '#9BA1A9',
    overviewTableBodyTextColor: globalColors.grey5,
    badgeColor: globalColors.slate3,
    alertIconColor: globalColors.white,
    alertBorderColor: globalColors.red2,
    alertIconInvalidBackgroundColor: globalColors.red2,
    alertIconBackgroundColor: globalColors.slate3,
    buttonInactiveTextColor: globalColors.grey4,
    overviewLink: globalColors.white,
    actionButtons: {
      borderColor: globalColors.grey9,
      textColor: globalColors.white,
    },
    tickets: {
      background: globalColors.slate1,
      iconColor: globalColors.white,
      textColor: globalColors.white,
      borderColor: globalColors.grey9,
      titleBorder: globalColors.white,
      actionsColor: globalColors.straw4,
      badgeColor: globalColors.grey5,
      arrowIcon: globalColors.slate3,
      creatorHeader: globalColors.slate3,
    },
    readings: {
      readingHeaderColor: globalColors.grey13,
      readingValueColor: globalColors.grey7,
    },
    statusBar: {
      titleBorderColor: globalColors.white,
      goodColor: globalColors.pine1,
      warnColor: globalColors.straw4,
      errorColor: globalColors.red4,
    },
    actions: {
      backgroundColor: globalColors.slate1,
      border: 'none',
      linkColor: globalColors.white,
    },
    fhp: {
      backgroundColor: globalColors.slate2,
      borderBottomColor: globalColors.grey9,
    },
    tasks: {
      details: {
        headerBackground: globalColors.slate2,
        headerText: globalColors.white,
        contentBackground: globalColors.slate12,
        borderRightColor: globalColors.grey5,
        columnButtonColor: globalColors.slate4,
        columnBorder: globalColors.slate1,
        labelColor: globalColors.grey4,
        inputColor: globalColors.grey7,
      },
    },
    cases: {
      details: {
        issueHeaderBackground: globalColors.slate2,
        contentBackground: globalColors.slate15,
        collapseArrow: globalColors.slate3,
        tableHeaderBorder: globalColors.slate2,
        rowBorder: globalColors.slate1,
        columnSeparator: globalColors.grey2,
        dataTitle: globalColors.stone3,
        dataDefinition: globalColors.grey7,
        taskHeader: globalColors.grey9,
        resolutionText: globalColors.grey4,
        resolutionDisplay: globalColors.grey7,
        taskRow: globalColors.slate12,
        selectedTaskRow: globalColors.teal7,
        errorColor: globalColors.red2,
        resolutionBackground: globalColors.slate2,
        iconColor: globalColors.slate5,
      },
    },
    notes: {
      addNote: globalColors.white,
      separator: globalColors.slate1,
      entityInfo: globalColors.grey4,
      entityDescription: globalColors.grey7,
      tableCopyHeader: globalColors.grey4,
      validSpecialInstruction: globalColors.slate3,
      invalidSpecialInstruction: globalColors.red2,
      toggleButtonActiveColor: globalColors.teal1,
      toggleButtonDisabledColor: globalColors.slate1,
      toggleButtonBorderColor: globalColors.slate4,
      knobActiveColor: globalColors.slate4,
      knobDisabledColor: globalColors.grey10,
      tickColor: globalColors.teal2,
    },
    battery: {
      openColor: globalColors.green2,
      textColor: globalColors.grey4,
      borderColor: globalColors.slate1,
    },
    specialInstructions: {
      badgeCount: globalColors.stone1,
      badgeBackground: globalColors.slate3,
      pastDue: globalColors.straw6,
      oddListBackground: globalColors.slate6,
      evenListBackground: globalColors.slate5,
      headerColor: globalColors.teal1,
      headerBorder: globalColors.teal8,
      showMore: globalColors.teal1,
      textBorder: globalColors.slate1,
    },
  },
  sensorReadingsChart: {
    labelColor: globalColors.grey4,
    alertLine: globalColors.red2,
    contextLine: globalColors.stone5,
    backgroundColor: { primary: globalColors.slate12 },
    borderColor: { secondary: globalColors.grey9 },
    plotBackground: { primary: '#181E24' },
    xAxisLabelColor: globalColors.white,
    xAxisTitleColor: globalColors.white,
    legendColor: '#d9d9d9',
    legendText: globalColors.grey7,
    legendValue: '#A7ACB0',
    legendBackground: { primary: 'linear-gradient(180deg, #181e24 0%, #1f262c 100%)' },
    legendBorderColor: { secondary: globalColors.grey2 },
    disabledLegendColor: globalColors.grey4,
    disabledIconColor: globalColors.grey4,
    plotBandColor: globalColors.teal3,
    plotLineColors: {
      trip: globalColors.red2,
      high: globalColors.red1,
      medium: globalColors.orange1,
      low: globalColors.blue3,
    },
    colors: [
      [
        globalColors.lavender4,
        globalColors.lavender3,
        globalColors.lavender2,
        globalColors.lavender1,
      ],
      [globalColors.magenta4, globalColors.magenta3, globalColors.magenta2, globalColors.magenta1],
      [globalColors.straw4, globalColors.straw3, globalColors.straw2, globalColors.straw1],
      [globalColors.pine4, globalColors.pine3, globalColors.pine2, globalColors.pine1],
    ],
  },
  gaugeChart: {
    hashes: 'rgba(22,22,23,0.3)',
    levels: {
      default: {
        bgStart: [globalColors.slate2, 1],
        bgEnd: [globalColors.slate1, 1],
      },
      warning: {
        bgStart: [globalColors.orange1, 0.1],
        bgEnd: [globalColors.orange2, 1],
      },
      danger: {
        bgStart: [globalColors.red3, 0.1],
        bgEnd: [globalColors.red4, 1],
      },
      extreme: {
        bgStart: [globalColors.yellow, 1],
        bgEnd: [globalColors.red4, 1],
      },
    },
    needle: {
      colors: {
        default: globalColors.white,
        warning: globalColors.white,
        danger: globalColors.red1,
      },
      border: {
        colors: {
          default: 'transparent',
          warning: globalColors.orange1,
          danger: globalColors.red1,
        },
      },
      boxShadow: {
        default: 'none',
        warning: 'none',
        danger: `0 0 4px 3px ${globalColors.white}`,
      },
    },
    title: globalColors.grey4,
    thresholdBackground: 'rgba(0, 0, 0, 0.2)',
    thresholdLine: globalColors.grey4,
    thresholdZIndex: 1,
    thresholdBgOpacity: 0.75,
  },
  gaugeStatChart: {
    startGradient: '#283139',
    endGradient: '#222A31',
    textColor: globalColors.grey3,
  },
  sparkLineChart: {
    areaBgColor: globalColors.blue6,
    dangerColor: globalColors.red2,
    defaultLineColor: globalColors.teal3,
  },
  kpiChart: {
    expandButtonColor: globalColors.white,
    tooltipSecondaryColor: globalColors.grey4,
  },
  scrollbar: {
    trackBackground: globalColors.slate12,
    trackLightBackground: globalColors.slate2,
    thumbBackground: globalColors.slate25,
    trackActiveBackground: globalColors.slate1,
    thumbActiveBackground: globalColors.slate5,
    thumbActiveBorder: globalColors.slate1,
  },
  sidebar: {
    badgeColor: globalColors.slate3,
    iconColor: globalColors.slate5,
    settingsIconColor: globalColors.slate4,
    boxShadow: '0px 4px 5px #282f35',
    evenRowBackground: globalColors.grey1,
    oddRowBackground: globalColors.grey0,
    textColor: globalColors.grey7,
    editHeaderBackground: globalColors.slate15,
    editBackground: globalColors.slate2,
    editFooterBackground: globalColors.slate15,
    borderColor: globalColors.slate1,
    listIconColor: globalColors.slate4,
    listActionIconColor: globalColors.slate4,
  },
  countblock: {
    textColor: globalColors.grey3,
    textColorActive: globalColors.grey7,
    textShadow: '1px 1px 0 rgba(0,0,0,0.50)',
    boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.34)',
    transparentize: 0.8,
  },
  modal: {
    overlay: 'rgba(22,22,22,0.3)',
    background: globalColors.slate2,
    alertBorderTop: globalColors.slate4,
  },
  dialog: {
    background: globalColors.grey6,
    headerBackground: '#445a6c',
    headerTextColor: globalColors.grey5,
    iconColor: globalColors.grey5,
    closeIconColor: globalColors.grey5,
    footerSeparatorColor: globalColors.slate1,
    taskReschedule: {
      spacerBorderColor: globalColors.slate1,
      labelColor: globalColors.grey4,
      iconColor: globalColors.grey4,
    },
  },
  caseCloseDialog: {
    titleColor: globalColors.grey7,
    badgeColor: globalColors.slate2,
  },
  caseLinkDialog: {
    titleColor: globalColors.grey7,
    textColor: '#B3B3B3',
    inputColor: globalColors.slate4,
    iconColor: globalColors.slate5,
    entityInfo: globalColors.grey4,
    assetIconColor: globalColors.slate4,
    entityIconColor: globalColors.slate5,
    headerTitleColor: globalColors.grey7,
    headerSeparatorColor: globalColors.slate1,
    separatorColor: globalColors.grey9,
    errorTextColor: globalColors.red2,
    badgeColor: globalColors.slate3,
    tabBackground: globalColors.slate1,
  },
  filterPanel: {
    titleColor: globalColors.white,
    iconColor: globalColors.slate5,
    chevronColor: globalColors.slate4,
    appliedBackgroundColor: globalColors.teal1,
    backgroundColor: globalColors.slate2,
    borderColor: globalColors.slate3,
    footerBackgroundColor: globalColors.slate1,
    boxShadow: '4px 4px 16px 0 rgba(22,22,23,0.15)',
  },
  menu: {
    backgroundColor: globalColors.slate2,
    textColor: globalColors.white,
    headerColor: globalColors.grey5,
    titleBackground: globalColors.slate3,
    escalationTitleBackground: globalColors.slate25,
    titleTextColor: globalColors.grey8,
    borderColor: globalColors.slate12,
    tableHeaderTextColor: globalColors.grey4,
    tableBodyTextColor: globalColors.grey7,
    tableChevronColor: globalColors.slate3,
    hoverBackground: globalColors.slate1,
    labelColor: globalColors.slate5,
    actionsMenu: {
      borderColor: globalColors.grey9,
      textColor: globalColors.grey5,
      hoverBackground: globalColors.teal4,
      hoverBorder: globalColors.teal7,
    },
  },
  eventController: {
    iconColor: globalColors.white,
    commandDialog: {
      tdBackground: globalColors.slate15,
      tableBackground: globalColors.slate2,
    },
  },
  tasks: {
    backgroundColor: {
      O: globalColors.blue1,
      T: globalColors.blue2,
      W: globalColors.blue3,
      default: globalColors.slate3,
    },
    newButton: {
      border: `1px solid ${globalColors.slate12}`,
      background: `linear-gradient(
            180deg,
            #445A6C 0%,
            ${globalColors.slate2} 62.55%,
            ${globalColors.slate2} 100%
          );`,
    },
  },
  taskStatusBadge: {
    textColor: {
      default: globalColors.white,
      unscheduled: globalColors.white,
      scheduled: globalColors.slate1,
      completed: globalColors.slate1,
      closed: globalColors.white,
    },
    backgroundColor: {
      default: globalColors.slate2,
      unscheduled: globalColors.slate2,
      scheduled: globalColors.teal3,
      completed: globalColors.green4,
      closed: globalColors.slate2,
    },
  },
  taskApprovalFlag: {
    approved: globalColors.pine3,
    notApproved: globalColors.red2,
    bundled: globalColors.lightblue,
  },
  newTaskDialog: {
    label: globalColors.grey4,
    actionButtonText: globalColors.white,
    sectionBorder: globalColors.slate1,
    collapsibleIcon: globalColors.slate5,
    recurrenceCustom: globalColors.slate15,
    customEditIcon: globalColors.grey6,
    okButton: globalColors.teal2,
    dayUnchecked: globalColors.white,
    dayChecked: globalColors.teal2,
    searchText: globalColors.white,
    searchCaret: globalColors.teal3,
    selectMenuBorder: globalColors.slate12,
    selectMenuIcon: globalColors.slate4,
    selectMenuHoverBackground: globalColors.teal4,
    selectMenuHoverBorder: globalColors.teal7,
    headerTitleColor: globalColors.grey7,
    headerIconColor: globalColors.slate5,
    headerSubtitleColor: globalColors.grey4,
  },
  alertDialog: {
    blockedActionIconColor: globalColors.teal2,
    expandButtonColor: globalColors.teal2,
    tagBackgroundColor: globalColors.slate3,
    tagTextColor: globalColors.grey7,
    viewValueTextColor: globalColors.white,
  },
  tooltip: {
    backgroundColor: globalColors.slate2,
    textColor: '#9BA1A9',
    boxShadow: '0 1px 5px 3px rgba(0,0,0,0.15)',
    backgroundColorActions: globalColors.slate5,
    textColorActions: globalColors.slate1,
  },
  colorPalatte: {
    color: globalColors.white,
    backgroundColor: globalColors.slate2,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 1px 0px;',
    colorNameBorder: `1px solid ${globalColors.white};`,
  },
  filterMenu: {
    titleColor: globalColors.grey5,
    textColor: globalColors.grey7,
    borderColor: globalColors.slate0,
    searchIcon: globalColors.slate4,
    inputBorderColor: globalColors.grey9,
    inputBackgroundColor: globalColors.slate1,
    inputspinBackgroundColor: '#161d23',
    icon: {
      default: globalColors.slate3,
      active: globalColors.teal3,
      hover: globalColors.white,
      hoverStroke: globalColors.teal3,
    },
  },
  analyze: {
    header: {
      borderBottomColor: globalColors.slate2,
      filterBackground: globalColors.slate1,
      filterBorder: globalColors.slate2,
      statusBorder: globalColors.grey1,
      kpiTitleColor: globalColors.grey4,
      kpiColor: globalColors.grey7,
      kpiDescColor: globalColors.grey5,
      kpiIconColor: globalColors.grey7,
      ledgendColor: globalColors.grey5,
      backButtonTextColor: globalColors.grey7,
      calendarIconColor: globalColors.grey5,
    },
    dashboard: {
      settingsButtonIconColor: globalColors.grey4,
      toggleButtonIconColor: globalColors.white,
      badgeColor: globalColors.slate2,
    },
    filter: {
      shadow: 'rgba(22, 22, 23, 0.5)',
      groupBorder: globalColors.slate12,
      groupHeader: globalColors.slate4,
      collapseArrow: globalColors.slate3,
      collapseHeader: globalColors.grey5,
      showMoreButton: globalColors.grey5,
    },
    regionCard: {
      backgroundColor: globalColors.slate12,
      detailsBackground: globalColors.slate12,
      boxShadow: `3px 0 20px ${globalColors.grey}`,
      titleBorderColor: globalColors.slate1,
      chartBackgroundColor: globalColors.slate1,
      regionIcon: globalColors.slate5,
      chevronIcon: globalColors.slate3,
      footerColor: globalColors.slate3,
      footerBoxShadow: `0 -10px 30px ${globalColors.grey}`,
      footerBorder: globalColors.grey2,
      defaultLine: globalColors.mint4,
      targetLine: globalColors.slate2,
      axisLineColor: globalColors.slate2,
      gridLineColor: globalColors.grey,
      dayVerticalLineColor: globalColors.slate2,
      unitsTextColor: globalColors.grey4,
      toggleButtonInactiveTextColor: globalColors.slate3,
      toggleButtonInactiveColor: 'linear-gradient(0deg, #2E3740 0%, #38434E 100%)',
      toggleButtonActiveTextColor: globalColors.white,
      toggleButtonActiveColor: 'linear-gradient(0deg, #38434E 0%, #1E6978 100%)',
      toggleButtonIconColor: globalColors.white,
    },
    table: {
      titleColor: globalColors.white,
      newButtonBorderColor: globalColors.grey9,
    },
    columnChart: {
      borderColor: globalColors.grey2,
      textColor: globalColors.grey4,
      activeTextColor: globalColors.white,
      gridLineColor: globalColors.grey2,
      dangerFill: globalColors.terracotta4,
      arrowColor: globalColors.slate1,
      activeBackground: 'rgba(38,38,38,0.6)',
      activeBorder: globalColors.slate2,
      columnColor: globalColors.stone3,
      tooltipPrimaryColor: globalColors.white,
      kpiValueColor: globalColors.grey7,
      legendColor: globalColors.stone4,
    },
    pareto: {
      buttonColor: globalColors.slate1,
      disabledButtonColor: globalColors.slate2,
      arrowIconColor: globalColors.slate3,
      titleColor: globalColors.white,
    },
    powerCurveChart: {
      colors: [globalColors.mint3],
      powerCurveColor: globalColors.mint3,
      referenceLineColor: globalColors.stone4,
    },
    splineChart: {
      axisLabelColor: globalColors.grey4,
      gridLineColor: globalColors.grey,
    },
    stackedColumnChart: {
      axisLabelColor: globalColors.white,
      colorRamp: [
        globalColors.straw5,
        globalColors.straw4,
        globalColors.straw3,
        globalColors.straw2,
        globalColors.straw1,
        globalColors.terracotta5,
        globalColors.terracotta4,
        globalColors.terracotta3,
      ],
      columnBorderColor: globalColors.grey,
      gridLineColor: globalColors.grey2,
      negativeColor: globalColors.teal,
    },
    waterfallChart: {
      gridLineColor: globalColors.grey2,
      gridLabelColor: globalColors.grey3,
      barColor: globalColors.straw4,
      barNestedColor: globalColors.straw2,
      barToggleIconColor: globalColors.slate3,
      highlightedRowBackgroundColor: globalColors.yellow,
    },
    tableBackground: globalColors.slate1,
    frequencyLineChart: {
      headerIconColor: globalColors.slate3,
      colors: [globalColors.stone3],
      colorsLarge: globalColors.teal3,
      gridLineColor: globalColors.grey2,
      titleColor: globalColors.grey5,
      badgeColor: globalColors.teal1,
    },
    modeChart: {
      colors: [globalColors.stone3],
      gridLineColor: globalColors.grey2,
      titleColor: globalColors.grey5,
    },
    regionSparkLineChart: {
      xAxisGridLineColor: globalColors.grey,
      xAxisLineColor: '',
      xAxisPlotLineColor: globalColors.teal3,
      yAxisGridLineColor: StatusColor.DEFAULT,
      chevronColor: globalColors.slate3,
    },
    metricsPanel: {
      backgroundColor: globalColors.slate12,
      textColor: globalColors.grey7,
      labelColor: globalColors.stone3,
    },
    scatterChart: {
      pointColor: globalColors.white,
      gridLineColor: globalColors.grey2,
      averageLineColor: globalColors.teal2,
      averageLineColorLight: globalColors.white,
    },
    regionSiteCard: {
      backgroundColor: globalColors.slate2,
      borderColor: globalColors.grey9,
      dangerBoxShadow: '0 3px 8px 0 rgba(0,0,0,0.65), 0 5px 18px 1px rgba(239,83,83,0.5)',
      dangerBorder: '1px solid rgba(239,83,83,0.35)',
      siteSelectHoverBorderColor: globalColors.teal3,
      siteSelectedHoverShadow: '0 0 0 2px rgba(30,105,120,0.5)',
    },
    majorLoss: {
      table: {
        text: globalColors.grey4,
        border: globalColors.grey1,
        hover: {
          background: globalColors.teal4,
          border: globalColors.teal1,
          text: globalColors.white,
        },
        selected: {
          border: globalColors.mint3,
        },
      },
      donutChart: {
        border: globalColors.teal4,
        title: globalColors.grey7,
        errorMessageColor: globalColors.grey3,
        selected: globalColors.mint3,
      },
    },
    fullScreenChartModal: {
      backgroundColor: globalColors.slate1,
      borderColor: globalColors.slate2,
      dateTimeColor: globalColors.grey7,
      kpiHeaderColor: globalColors.grey5,
      kpiTextColor: globalColors.grey7,
      kpiUnitColor: globalColors.grey5,
    },
    site: {
      yAxisTitleColor: globalColors.white,
      chartTitleBorder: globalColors.slate2,
      tableSeparator: globalColors.grey2,
    },
    troubleshooting: {
      header: {
        background: 'linear-gradient(#38434d,#44596a)',
        detailsBackground: globalColors.slate12,
        actionsBackground: globalColors.teal1,
        actionsBorderColor: globalColors.pine1,
        statusColor: globalColors.teal3,
        shadow: '0 1px 4px 0 rgba(0,0,0,0.2)',
        sourceDetails: {
          textColor: globalColors.slate4,
          borderColor: globalColors.slate1,
        },
      },
      card: {
        background: globalColors.slate12,
        borderColor: globalColors.slate2,
      },
    },
    manualAdjustment: {
      iconsColor: globalColors.slate3,
      panelsColor: globalColors.slate12,
      kpiTitleColor: globalColors.grey4,
      kpiColor: globalColors.grey7,
      kpiDescColor: globalColors.grey5,
      bordersColor: globalColors.grey9,
      eventDialog: {
        backgroundColor: globalColors.slate12,
        headerBackgroundColor: '#5a6978',
        categoryColor: globalColors.grey5,
        labelColor: globalColors.grey4,
        valueColor: globalColors.grey7,
        bordersColor: globalColors.slate1,
        editButtonColor: globalColors.white,
        editButtonIconColor: globalColors.slate4,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        newEventIconColor: globalColors.slate2,
      },
      chart: {
        timelineColor: globalColors.slate4,
        timelineGridColor: globalColors.slate1,
        entityTitleColor: globalColors.grey4,
        entityNameColor: globalColors.grey7,
        entityIconColor: globalColors.slate2,
        rowBackgroundColor: globalColors.grid0,
        rowAltBackgroundColor: globalColors.grid1,
        activeRowBorderColor: globalColors.teal1,
        activeRowBackgroundColor: 'rgba(32, 57, 61, 0.3)',
      },
    },
    gaugeConfiguration: {
      gaugeCardBackground: globalColors.slate15,
      gaugeCardBoxShadow: '0 2px 2px 1px rgba(0,0,0,0.4)',
      panelBorderColor: globalColors.slate25,
    },
  },
  fullscreenModal: {
    backgroundColor: globalColors.slate2,
  },
  timeline: {
    tickMark: globalColors.grey2,
    handleColor: globalColors.teal2,
    rangeBackground: 'rgba(38,38,38,0.6)',
  },
  manage: {
    dateHeaderColor: globalColors.white,
    dateChevronColor: globalColors.slate4,
    rangeToggle: {
      textColor: globalColors.white,
      activeBackgroundColor: globalGradients.tealGradiend5,
      activeBorderColor: globalColors.teal7,
      borderColor: globalColors.slate12,
      backgroundColor: globalColors.slate2,
    },
    calendar: {
      backgroundColor: globalColors.slate1,
      sideBarBorderColor: globalColors.slate2,
      rangeColor: globalColors.teal1,
      laneBorder: globalColors.slate2,
      groupBySiteAssetIcon: globalColors.slate5,
      laneExpandCaret: globalColors.slate3,
      laneOddBackground: globalColors.slate12,
      laneEvenBackground: globalColors.grid0,
      newCrewBackground: globalColors.grey0,
      newCrewColor: globalColors.slate5,
      newCrewHighlight:
        'inset 0 -2px 0 0 #b8c1c8, inset 0 -4px 6px 0 #a8d1d7, inset 0 1px 6px 0 #a8d1d7',
      editLaneBackground: globalColors.slate2,
      unassignedBackground: globalColors.grey0,
      dragOverBoxShadow:
        'inset 0 -2px 0 0 #b8c1c8, inset 0 -4px 6px 0 #a8d1d7, inset 0 1px 6px 0 #a8d1d7,',
      dispatch: {
        backgroundColor: globalColors.white,
        color: globalColors.black,
        borderColor: globalColors.white,
        textColor: globalColors.straw3,
        turbineIcon: {
          textColor: globalColors.white,
        },
        inprogressMessage: {
          backgroundColor: globalColors.slate3,
        },
        successMessage: {
          backgroundColor: globalColors.teal1,
        },
        failedMessage: {
          backgroundColor: globalColors.red2,
        },
      },
      dispatchTime: {
        color: globalColors.teal1,
      },
    },
    summary: {
      badgeLabelColor: globalColors.slate4,
    },
    timeline: {
      noDataIconColor: globalColors.slate3,
      backgroundColor: globalColors.grey,
      textColor: globalColors.slate4,
      tickColor: globalColors.slate3,
      scheduleBadge: globalColors.teal3,
      unscheduledBadge: globalColors.slate2,
      completedBadge: globalColors.green4,
      borderColor: globalColors.grey9,
      titleColor: globalColors.white,
      dateDarkColor: globalColors.grey3,
      isTodayColor: globalColors.teal2,
      dragDrop: {
        lane: {
          background: globalColors.grey,
          boxShadow: `inset 0 -3px 6px 0 ${globalColors.teal1}, inset 0 1px 6px 0 ${globalColors.teal1}`,
        },
        cell: {
          boxShadow: globalColors.grey5,
          highlight: {
            border: globalColors.teal3,
            background: globalColors.white,
            boxShadow: `-1px -1px 4px 0 ${globalColors.teal1}, 1px 1px 4px 0 ${globalColors.teal1}`,
          },
          disabled: globalColors.grey2,
        },
      },
    },
    filterMenu: {
      background: globalColors.slate12,
      borderColor: globalColors.slate0,
      chevronColor: globalColors.slate4,
      color: globalColors.grey5,
    },
    crew: {
      titleColor: globalColors.white,
      memberColor: globalColors.slate4,
      shadowColor: globalColors.grey0,
    },
    tabs: {
      backgroundColor: globalColors.grey,
      textColor: globalColors.grey3,
      borderColor: globalColors.slate2,
      iconColor: globalColors.grey3,
      badge: {
        textColor: globalColors.grey1,
        backgroundColor: globalColors.slate5,
      },
      active: {
        backgroundColor: globalColors.slate1,
        borderColor: globalColors.teal2,
        textColor: globalColors.grey7,
        iconColor: globalColors.teal3,
      },
      taskGroup: {
        dashedBorderColor: globalColors.slate4,
        boxShadow: '2px 2px 2px 0 rgba(0,0,0,0.16)',
        textColor: globalColors.white,
        iconColor: globalColors.white,
      },
      techs: {
        borderColor: globalColors.slate2,
        primaryColor: globalColors.teal2,
        boxShadow: '0 1px 2px 0 rgb(35 35 35)',
        iconColor: globalColors.slate4,
        crew: {
          containerColor: globalColors.slate1,
          descriptionTitle: globalColors.slate4,
          backgroundColor: globalColors.grid0,
          evenBackgroundColor: globalColors.grid1,
          headerText: globalColors.white,
          deleteBoxShadow: 'inset 0 -1px 2px 0 rgb(0 0 0)',
          addTextColor: globalColors.white,
          hover: {
            background: globalColors.slate0,
            shadow: 'inset 0 1px 0 0 #9bd9e4, inset 0 -1px 0 0 #9bd9e4',
          },
          actionButtonsBorder: globalColors.slate2,
        },
        technician: {
          textColor: globalColors.white,
          hoursBackground: globalColors.slate1,
          chevronBackground: globalColors.slate1,
          statusColor: globalColors.pine4,
        },
      },
    },
    search: {
      borderColor: `1px solid ${globalColors.grey5}`,
      shadow: 'inset 0 0 2px 0 rgba(0,0,0,0.3)',
      iconColor: globalColors.white,
      filterTextColor: globalColors.teal2,
    },
    taskItem: {
      borderColor: globalColors.slate2,
      borderBottomColor: globalColors.slate1,
      locationColor: globalColors.grey5,
      textColor: globalColors.grey5,
      iconColor: globalColors.slate4,
      buttonColor: globalColors.slate2,
      taskTypeColor: globalColors.teal3,
      hover: {
        backgroundColor: globalColors.slate2,
        borderColor: globalColors.teal3,
        actionsBackground: globalColors.slate2,
      },
    },
    dayCard: {
      siteIconColor: globalColors.stone3,
      dashedBorderColor: globalColors.slate4,
      borderColor: globalColors.slate1,
      textColor: globalColors.grey4,
      iconColor: globalColors.slate5,
      crewBackgroundColor: globalColors.shadow,
      crewTextColor: globalColors.slate1,
      crewTextColorInActive: globalColors.slate1,
      crewBackgroundColorActive: globalColors.lightblue,
      crewBorderColor: globalColors.slate1,
      specialistBackgroundColor: globalColors.violet6,
      specialistTextColor: globalColors.slate2,
      AddTechCircleBoxShadow: '0 0 0 3px rgba(144, 211, 224, 0.5)',
      estimate: {
        boxShadow: '0 2px 2px 0 rgba(11,13,14,0.7)',
      },
      gradients: {
        default: `repeating-linear-gradient(45deg, ${globalColors.slate0}, ${globalColors.slate0} 3px, #232c32 3px, #232c32 4.5px);`,
        edit: `repeating-linear-gradient(45deg, ${globalColors.slate0}, ${globalColors.slate0} 3px, #232c32 3px, #232c32 4.5px);`,
        estimate: `repeating-linear-gradient(45deg, ${globalColors.slate1}, ${globalColors.slate1} 2px, ${globalColors.slate2} 2px, ${globalColors.slate15} 4px);`,
        estimateScheduled: `repeating-linear-gradient(45deg, ${globalColors.slate1}, ${globalColors.slate1} 2px, #53595e 2px, #53595e 4px);`,
        recommendedHover: `repeating-linear-gradient(45deg, #29404a, #29404a 2px, #1c353f 2px, #1c353f 4px);`,
      },
      detail: {
        borderColor: globalColors.slate2,
        dashedBorderColor: globalColors.slate3,
        taskBackground: globalColors.slate0,
      },
      scheduled: {
        backgroundColor: globalColors.mint0,
        borderColor: 'rgba(28,37,45,0.18)',
        boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.7)',
        textColor: globalColors.white,
        gradient: `repeating-linear-gradient(45deg, ${globalColors.slate1}, ${globalColors.slate1} 3px, ${globalColors.slate15} 3px, ${globalColors.slate15} 4.5px);`,
        crewBackgroundColor: globalColors.teal5,
        crewTextColor: globalColors.slate2,
      },
      travel: {
        textColor: globalColors.stone3,
        iconColor: globalColors.stone3,
      },
      location: {
        textColor: globalColors.stone3,
      },
      hoverText: globalColors.white,
      headerHover: globalColors.teal1,
      hoverBoxShadow: globalColors.teal6,
      hoverBorder: globalColors.teal1,
      editBoxShadow: globalColors.teal1,
    },
    cards: {
      scheduledColor: globalColors.teal3,
      unscheduledColor: globalColors.slate3,
      border: '1px solid rgba(28, 37, 45, 0.18)',
      scheduledTextColor: globalColors.grey1,
      unscheduledTextColor: globalColors.white,
      scheduledTypeTextColor: globalColors.teal1,
      unscheduledTypeTextColor: globalColors.teal3,
      selectedBorder: globalColors.teal1,
      selectedHalo: globalColors.teal6,
      scheduledIconColor: globalColors.slate2,
      unscheduledIconColor: globalColors.slate5,
      completedColor: globalColors.green4,
      completedTextColor: globalColors.grey1,
      completedIconColor: globalColors.slate2,
      completedTypeTextColor: globalColors.teal1,
      stack: {
        boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.7)',
      },
      trBorderBottom: 'rgb(149 149 149 / 0.35)!important',
      thBorderBottom: globalColors.slate2,
      bundleBadge: globalColors.grey3,
      bundleTaskBorder: `2px solid ${globalColors.white}`,
    },
    weather: {
      backgroundColor: globalColors.slate1,
      tempIconColor: globalColors.terracotta4,
      windIconColor: globalColors.teal2,
      expand: {
        backgroundColor: globalColors.grey0,
        textColor: globalColors.grey5,
        iconColor: globalColors.white,
      },
    },
  },
  admin: {
    popup: {
      backgroundColor: globalColors.slate2,
      borderColor: globalColors.slate12,
    },
    actionHeader: {
      borderColor: globalColors.slate2,
      iconColor: globalColors.slate4,
      linkColor: globalColors.white,
      removeContainerBackground: globalColors.teal10,
    },
    tenantHeader: {
      background: globalGradients.tealgradient4,
      borderColor: globalColors.teal2,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.2)',
      iconColor: globalColors.white,
      textColor: globalColors.white,
    },
    people: {
      backToGlobalButtonColor: globalColors.slate4,
    },
    panel: {
      backgroundColor: globalColors.slate1,
      border: globalColors.slate2,
      header: {
        backgroundColor: globalColors.slate12,
        borderColor: globalColors.grey4,
        gradient: 'linear-gradient(180deg, #38434E 0%, #445A6C 100%)',
        iconColor: globalColors.slate4,
      },
      steps: {
        number: globalColors.slate2,
        numberActive: globalColors.teal2,
        textColor: globalColors.slate4,
        textColorActive: globalColors.white,
      },
      body: {
        backgroundColor: globalColors.slate15,
        border: globalColors.slate1,
        detailButton: globalColors.white,
        subHeader: globalColors.slate4,
        collapseArrow: globalColors.slate3,
        check: globalColors.slate5,
        panelHighlight: 'rgba(32, 57, 61, 0.5)',
        textButton: globalColors.white,
      },
      entityRow: {
        border: globalColors.slate12,
        dark: globalColors.slate1,
        highlight: 'rgba(32, 57, 61, 0.5)',
        highlightBorder: globalColors.teal7,
        icon: globalColors.slate4,
        light: globalColors.slate12,
      },
    },
  },
  charts: {
    [ChartType.AREA]: {
      color: globalColors.blue4,
    },
    [ChartType.AREA_SPLINE]: {
      color: globalColors.blue4,
    },
    [ChartType.ROSE]: {
      colors: [
        globalColors.mint5,
        globalColors.mint3,
        globalColors.blue4,
        globalColors.blue3,
        globalColors.straw5,
        globalColors.straw4,
        globalColors.terracotta4,
      ],
    },
    axisTitleColor: globalColors.grey4,
    colors: [globalColors.stone4, ...chartColors.level1],
    [ChartType.COLUMN]: {
      negativeColor: globalColors.terracotta3,
    },
    [ChartType.MIRROR_COLUMN]: {
      negativeColors: [
        {
          color: globalColors.stone3,
          patternColor: globalColors.stone1,
          selectedColor: globalColors.mint3,
          selectedPatternColor: globalColors.mint1,
        },
        {
          color: globalColors.terracotta3,
          patternColor: globalColors.terracotta1,
          selectedColor: globalColors.mint3,
          selectedPatternColor: globalColors.mint1,
        },
      ],
    },
    gridLineColor: globalColors.grey2,
    legendTitleColor: globalColors.grey4,
    lineColor: globalColors.grey2,
    [ChartType.PARETO]: {
      selectedColor: globalColors.mint3,
      dangerColor: globalColors.red2,
      barToggleIconColor: globalColors.slate3,
      colors: [
        globalColors.straw5,
        globalColors.straw4,
        globalColors.straw3,
        globalColors.straw2,
        globalColors.straw1,
        globalColors.terracotta5,
        globalColors.terracotta4,
        globalColors.terracotta3,
      ],
      nestedColor: globalColors.straw2,
    },
    selectedColor: globalColors.mint3,
    tooltipBackgroundColor: globalColors.slate2,
    tooltipColor: globalColors.grey6,
    borderColor: globalColors.slate3,
    selectedPoint: {
      fillColor: globalColors.teal0,
      lineColor: globalColors.teal2,
    },
    [ChartType.HEAT_MAP]: {
      nullColor: globalColors.pine3,
      stops: [
        [0.0, globalColors.red2],
        [0.3, globalColors.terracotta5],
        [0.5, globalColors.grey10],
        [0.7, globalColors.blue3],
        [1.0, globalColors.pine3],
      ],
    },
  },
  quickEdit: {
    borderColor: globalColors.slate1,
    labelColor: globalColors.grey4,
    saveColor: globalColors.teal3,
    addButtonText: globalColors.white,
  },
  datePicker: {
    backgroundColor: globalColors.slate2,
    monthTitleColor: globalColors.white,
    dayHeaderColor: globalColors.grey4,
    chevronColor: globalColors.slate4,
    day: {
      color: globalColors.white,
      selectedBackground: globalColors.teal1,
      selectedColor: globalColors.white,
      outsideMonthColor: globalColors.grey3,
      highlightColor: globalColors.teal2,
      highlightTextColor: globalColors.slate0,
      hoverTextColor: globalColors.black,
    },
    time: {
      backgroundColor: globalColors.slate1,
      inputColor: globalColors.slate4,
    },
  },
  assetOverview: {
    header: {
      textColor: globalColors.grey7,
      secondaryTextColor: globalColors.white,
      backButtonTextColor: globalColors.grey7,
      iconColor: globalColors.slate5,
      assetIconBorder: globalColors.teal2,
    },
    issuesSidebar: {
      backgroundColor: globalColors.slate1,
      headerBorderColor: globalColors.slate2,
      itemBorderColor: globalColors.grey9,
    },
    selectAssetInfo: {
      borderColor: globalColors.red2,
      textColor: globalColors.red2,
    },
  },
  dataExplorer: {
    focusAssetColor: globalColors.stone5,
    header: {
      backgroundColor: globalColors.slate1,
      borderColor: globalColors.slate2,
      iconColor: globalColors.slate5,
      addIconColor: globalColors.slate4,
      removeIconColor: globalColors.slate4,
      assetBar: {
        backgroundColor: `linear-gradient(180deg, ${globalColors.slate2} 0%,  #445a6c 100%)`,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)',
        actionsBorderColor: globalColors.slate1,
        textColor: globalColors.grey7,
        borderColor: globalColors.slate1,
        selectedBorderColor: globalColors.white,
        assetBoxShadow: 'rgba(30, 105, 120, 0.5)',
      },
    },
    filters: {
      headerButton: globalColors.white,
      headerIconColor: globalColors.slate4,
      searchIconColor: globalColors.slate5,
      layoutIconSelected: globalColors.white,
      layoutIcon: globalColors.slate3,
    },
    charts: {
      selectedAssetColor: globalColors.stone4,
      selectedPointsColor: globalColors.mint4,
      container: {
        labelColor: globalColors.grey5,
        selectColor: globalColors.grey7,
        promoteIconColor: globalColors.slate4,
      },
      editIconColor: globalColors.slate3,
      buttonColor: globalColors.white,
      buttonBackground: globalColors.slate1,
      buttonIconColor: globalColors.slate5,
      iconColor: globalColors.slate5,
      tooltipColor: globalColors.grey4,
      tooltipBorderColor: globalColors.slate1,
      tooltipHeadingColor: globalColors.slate5,
      tooltipKpiNameColor: globalColors.grey4,
      tooltipKpiValueColor: globalColors.grey7,
      xLegendColor: globalColors.grey7,
      yLegendColor: globalColors.grey4,
      xAxisTitleColor: globalColors.grey3,
      yAxisTitleColor: globalColors.grey3,
      xAxisLabelColor: globalColors.grey3,
      yAxisLabelColor: globalColors.grey3,
      gridLineColor: globalColors.grey2,
      plotBandColor: globalColors.teal3,
      plotLineColors: {
        trip: globalColors.red2,
        high: globalColors.red1,
        medium: globalColors.orange1,
        low: globalColors.blue3,
      },
      colors: [
        chartColors.level2[6],
        chartColors.level2[7],
        chartColors.level2[2],
        chartColors.level2[1],
        chartColors.level2[3],
        chartColors.level2[4],
        chartColors.level2[5],
        chartColors.level2[8],
        chartColors.level2[0],
      ],
    },
    dialog: {
      backgroundColor: globalColors.slate1,
      activeSectionBackgroundColor: globalColors.slate12,
      listBorderColor: globalColors.slate12,
      listActiveColor: globalColors.teal4,
      arrowColor: globalColors.slate2,
      activeArrowColor: globalColors.white,
      sectionBorderColor: globalColors.slate25,
      search: {
        iconColor: globalColors.slate4,
        placeholderColor: globalColors.slate3,
        textColor: globalColors.white,
        borderColor: globalColors.grey9,
      },
      listHoverBackground: globalColors.teal4,
      listHoverBorder: globalColors.teal7,
    },
  },
  dataLoader: {
    retryColor: globalColors.teal2,
    iconColor: globalColors.grey1,
    descriptionColor: globalColors.grey4,
  },
  underConstruction: {
    iconColor: globalColors.slate5,
    titleColor: globalColors.slate5,
    descriptionColor: globalColors.white,
  },
  notification: {
    background: globalColors.teal1,
    borderError: globalColors.red1,
    borderSuccess: globalColors.teal3,
    textColor: globalColors.grey7,
    failureBackground: globalColors.red2,
  },
  taskBadge: {
    backgroundColorClosed: globalColors.blue2,
    backgroundColorOpen: globalColors.blue3,
    borderColorClosed: globalColors.blue2,
    borderColorOpen: globalColors.blue1,
    textColorOpen: globalColors.white,
    textColorClosed: globalColors.slate4,
  },
  severity: {
    immediateFillColor: globalColors.red2,
    highFillColor: globalColors.orange1,
    mediumFillColor: globalColors.straw4,
    lowFillColor: globalColors.lavender4,
    defaultFillColor: globalColors.lavender4,
  },
  lasso: {
    background: 'rgba(133, 205, 219, 0.18)',
    borderColor: globalColors.teal1,
  },
  fileUpload: {
    backgroundColor: globalColors.slate15,
    borderColor: globalColors.slate1,
    filenameTextColor: globalColors.grey4,
    textColor: globalColors.white,
    trashIconColor: globalColors.grey4,
    uploadButtonBackgroundColor: globalColors.slate2,
    uploadButtonBorderColor: globalColors.slate12,
    uploadIconColor: globalColors.slate5,
    errorBorderColor: globalColors.red2,
    errorTextColor: globalColors.red2,
  },
  statusMenu: {
    borderColor: globalColors.grey1,
  },
  postProcess: {
    annotationsBackgroundColor: globalColors.slate2,
    annotationsHeaderBackgroundColor: globalColors.slate25,
    annotationsHeaderColor: globalColors.grey5,
    containerHeaderColor: globalColors.slate3,
    infoBackgroundColor: globalColors.slate2,
    parameterCardTitleColor: globalColors.grey4,
    settingsLabel: globalColors.grey4,
    backgroundColor: globalColors.slate1,
    editIconColor: globalColors.slate5,
    videoGalleryBackground: globalColors.slate1,
    iconToolsBackground: 'rgba(0, 0, 0, 0.4)',
    iconButton: '#979797',
    selectedIconButton: '#2373legendbackground83',
    noImageContainerColor: globalColors.grey5,
    hotkeyColor: '#efefef',
    annotationLabelColor: globalColors.grey4,
    inputBackgroundColor: globalColors.grey1,
    inputColor: globalColors.slate3,
    inputBorder: `solid 1px ${globalColors.grey9}`,
    disabledSelectBackground: globalColors.slate2,
    disabledSelectBorder: `solid 1px ${globalColors.slate12}`,
  },
  annotation: {
    containerBackgroundColor: globalColors.slate2,
    containerHeaderColor: globalColors.slate25,
    capsuleColor: globalColors.grey7,
    capsuleSelectedColor: '#efefef',
    ADRColor: globalColors.pine3,
    humanColor: globalColors.terracotta4,
    thirdPartyColor: globalColors.straw3,
    checkedBorderColor: globalColors.teal2,
    actionMenu: {
      menuHeaderBackgroundColor: globalColors.slate15,
      menuHeaderTextColor: globalColors.teal2,
      menuItemBackgroundColor: globalColors.slate2,
      menuItemTextColor: globalColors.grey5,
      menuItemSeparatorColor: globalColors.slate15,
      badgeBackgroundColor: globalColors.teal1,
      badgeBorderColor: globalColors.teal2,
      badgeTextColor: globalColors.grey7,
    },
  },
  reportGeneration: {
    borderColor: globalColors.teal1,
    forms: {
      labels: {
        textColor: globalColors.grey4,
      },
      enabled: {
        backgroundColor: globalColors.slate1,
        borderColor: globalColors.grey9,
        textColor: globalColors.grey7,
      },
      disabled: {
        backgroundColor: globalColors.slate1,
        borderColor: globalColors.grey9,
        textColor: globalColors.slate3,
      },
    },
  },
  escalationDialog: {
    textColor: globalColors.slate4,
    contactInfoColor: globalColors.white,
  },
  caseProcedure: {
    subHeader: globalColors.grey12,
    linkColor: globalColors.blue5,
    headerColor: globalColors.grey5,
    titleColor: globalColors.teal3,
    subTitleColor: globalColors.white,
    noCaseProcedure: globalColors.straw6,
    handlingProcedureHead: globalColors.grey4,
    handlingProcedureText: globalColors.white,
  },
  createReport: {
    sidebarBackgroundColor: globalColors.slate12,
    headerBorderColor: globalColors.slate1,
    headerIconColor: globalColors.white,
    headerTextColor: globalColors.white,
    importTextDownload: globalColors.white,
    sidebar: {
      altTextColor: globalColors.grey4,
      separationColor: globalColors.slate2,
      altSeparationColor: globalColors.slate1,
      titleColor: globalColors.grey7,
      labelColor: globalColors.grey5,
      errorLabelColor: globalColors.red4,
      siteIconColor: globalColors.slate4,
      collapseIconColor: globalColors.teal3,
      readOnlyLabelColor: globalColors.grey4,
      parameterValueColor: globalColors.grey7,
      moduleBackgroundColor: globalColors.slate1,
      moduleBorderColor: globalColors.slate4,
      moduleChevronColor: globalColors.slate3,
      moduleHeaderColor: globalColors.grey5,
      moduleHeaderIconColor: globalColors.slate4,
      moduleHeaderLinkColor: globalColors.slate5,
      moduleHeaderColorNotVisible: globalColors.slate4,
      moduleIconColorActive: globalColors.teal2,
      moduleIconColorDisabled: globalColors.slate3,
      moduleIconColorVisible: globalColors.slate4,
      moduleIconColorNotVisible: globalColors.slate5,
      clearAllColor: globalColors.slate5,
    },
    template: {
      headerBackgroundColor: 'linear-gradient(-179deg, #445A6C 0%, #38434E 62%, #38434E 100%)',
    },
    widget: {
      backgroundColor: globalColors.slate2,
      borderColor: 'transparent',
      headerBorderColor: globalColors.grey1,
      headerIconColor: globalColors.slate4,
      headerTextColor: globalColors.grey5,
      headerLabelColor: globalColors.stone3,
      headerValueColor: globalColors.grey7,
      timestamp: globalColors.grey4,
      list: {
        oddRowColor: globalColors.slate15,
        tableCellTextColor: globalColors.grey6,
        unitsTextColor: globalColors.grey4,
      },
      notes: {
        titleTextColor: globalColors.grey7,
        bodyTextColor: globalColors.grey7,
      },
      table: {
        backgroundColor: '#38434E',
        evenRowColor: 'none',
        oddRowColor: '#333c45',
        entriesLabelColor: globalColors.teal3,
        unitsLabelColor: globalColors.grey4,
        variationLabelColor: globalColors.grey4,
      },
      assetStatus: {
        turbineIconColor: globalColors.slate4,
        borderColor: globalColors.slate15,
      },
      plannedWork: {
        includeTextColor: '#6fa4b3',
        techBadge: {
          background: globalColors.lightblue,
          border: globalColors.slate1,
          textColor: globalColors.slate1,
        },
        expectedParts: {
          partNumberTextColor: '#6a7278',
        },
      },
      completedWork: {
        includeTextColor: '#6fa4b3',
        techBadge: {
          background: '#5e6d7b',
          border: '#1c252d',
          textColor: '#aeb3b8',
        },
        expectedParts: {
          partNumberTextColor: '#6a7278',
        },
      },
      contractualAvailability: {
        includeTextColor: '#6fa4b3',
        rowBorderColor: globalColors.slate3,
      },
      availability: {
        filterLabelColor: globalColors.grey5,
        lineLabelColor: globalColors.grey4,
        lineUnitsColor: globalColors.white,
      },
      averageWindSpeed: {
        lineLabelColor: globalColors.grey4,
        lineColor: globalColors.blue4,
        lineUnitsColor: globalColors.white,
      },
      productionGraph: {
        chartLabelColor: globalColors.grey5,
        labelColor: globalColors.grey4,
        totalValueColor: globalColors.white,
        valueLabelColor: globalColors.grey7,
      },
      manuallyAdjustedEvents: {
        includeTextColor: globalColors.teal3,
      },
      productionTable: {
        includeTextColor: '#6fa4b3',
        rowBorderColor: globalColors.slate3,
      },
    },
    sendReportModal: {
      titleColor: globalColors.grey7,
      labelColor: globalColors.grey4,
      errorLabelColor: globalColors.red4,
      parameterColor: globalColors.grey7,
      separationColor: globalColors.slate1,
    },
  },
  customSelect: {
    optionsBorder: globalColors.slate12,
    optionHover: globalColors.teal7,
    optionSelected: globalColors.teal4,
    scrollBar: globalColors.slate25,
  },
  distributionListTable: {
    recipients: globalColors.slate3,
    recipientsCount: globalColors.white,
    title: globalColors.white,
    borderColor: globalColors.darkgray,
  },
  distributionListSidePanel: {
    header: globalColors.slate12,
    headerShadow: globalColors.slate25,
    headerIcon: globalColors.grey13,
    headerTitle: globalColors.white,
    tabNumber: globalColors.slate2,
    tabNumberSelected: globalColors.teal2,
    tabTextSelected: globalColors.white,
    tabText: globalColors.slate4,
    formBackground: globalColors.grey9,
    checkbox: globalColors.grey7,
    checkboxContainer: globalColors.grey4,
    defaultTextLabel: globalColors.grey5,
    InputWrapper: globalColors.slate1,
  },
  customDropdown: {
    backgroundColor: globalColors.slate2,
    border: globalColors.slate2,
    color: globalColors.white,
    icon: globalColors.slate3,
  },
  comingSoon: {
    backgroundColor: globalColors.grey,
    titleColor: globalColors.grey7,
    descriptionColor: globalColors.grey4,
    linkColor: globalColors.teal2,
  },
  errorBoundaryDialog: {
    errorMessageText: globalColors.white,
  },
  eventSeverity: {
    safetyCriticalExclamatory: globalColors.white,
    safetyCritical: globalColors.red2,
    falutAlarm: globalColors.red2,
    warning: globalColors.straw4,
    informational: globalColors.blue3,
  },
  deleteCaseTemplate: {
    backgroundColor: globalColors.slate1,
    borderBottomColor: globalColors.teal3,
    buttonDividerColor: globalColors.grey9,
    cancelBorderColor: globalColors.black,
    iconColor: globalColors.white,
    caseTitleColor: globalColors.grey5,
    modalDividerColor: globalColors.slate12,
  },
  taskFlag: {
    caseTitleColor: globalColors.grey5,
    cancelBorderColor: globalColors.black,
    buttonDividerColor: globalColors.grey9,
  },
  limitSelectAll: {
    backgroundColor: globalColors.slate2,
    tooltipTextColor: globalColors.grey7,
    modalTextColor: globalColors.red2,
  },
};
