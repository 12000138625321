import { IncludeFields } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;
const TURBINE_EVENT = '/rtmc/asset/rt-status';

/**
 * get active events
 */
export const getAssetActiveEvents = (assetIds, eventIdType = 'all') => {
  return request.get('/rtmc/asset/rt-events', {
    baseURL: BASE_URL,
    params: { id: assetIds, eventIdType: eventIdType },
  });
};

export const getAssetAllAplicableEvents = (body) => {
  return request.post('/rtmc/asset/all-events', body, { baseURL: BASE_URL });
};

export const getFaultState = (assetIds, includeFields) => {
  return request.get(TURBINE_EVENT, {
    baseURL: BASE_URL,
    params: { assetIds: assetIds, includeFields: includeFields },
  });
};

export const getCausalEvent = (assetIds) => {
  return request.get(TURBINE_EVENT, {
    baseURL: BASE_URL,
    params: { assetIds: assetIds, includeFields: IncludeFields.includeFields, idType: 'canonical' },
  });
};
