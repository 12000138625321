import React from 'react';

import { Icon, Icons } from '@ge/components/icon';

const PersonAssetsSitesComponent = ({ list, theme }) => {
  const RenderList = list.map((item, index) => (
    <div
      className="person-assets-sites-sublist"
      key={index}
      style={{
        backgroundColor:
          index % 2 === 0 ? theme.admin.panel.entityRow.dark : theme.admin.panel.entityRow.light,
      }}
    >
      <div>
        <Icon icon={Icons.TURBINE} size={11} color={theme.admin.panel.entityRow.icon} />
      </div>
      <div>{item.name}</div>
      <div></div>
    </div>
  ));

  const content = <div>{RenderList}</div>;

  return content;
};

export default PersonAssetsSitesComponent;
