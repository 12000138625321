/**
 * function - havingAllInServiceGroup
 * @param {Array.<object>} data - This is array of objects
 * @return {boolean} - Returns true if any service any of ServiceGroup is 'ALL' otherwise returns false
 */
export const havingAllInServiceGroup = (data) => {
  return typeof data?.find(({ serviceGroup }) => serviceGroup === 'ALL') === 'object'
    ? true
    : false;
};

/**
 * function - transformStoreSelectedServiceGroupsData
 * @param {Array.<object>} data - This is array of objects
 * @return {Array.<object>} - Returns transformed array
 */
export const transformStoreSelectedServiceGroupsData = (data) => {
  return (
    data?.map((obj) => ({
      path: '',
      serviceGroup: obj.id,
      serviceGroupName: obj.name,
      worker: false,
      userRole: '',
    })) ?? []
  );
};

/**
 * function - filterdSelectedGroup
 * @param {Array.<object>} apiComingServiceGroups - This is array of objects
 * @param {Array.<object>} storeSelectedServiceGroups - This is array of objects
 * @return {Array.<object>} - Returns array of objects
 */
export const filterdSelectedGroup = (apiComingServiceGroups, storeSelectedServiceGroups) => {
  if (
    // checking ALL service group
    havingAllInServiceGroup(apiComingServiceGroups) ||
    havingAllInServiceGroup(storeSelectedServiceGroups)
  ) {
    const combinedServiceGroups = [...apiComingServiceGroups, ...storeSelectedServiceGroups];
    // combinedServiceGroups have duplicates, lets remove the duplicates using Set
    let set = new Set();
    // returns union( common + distinct in both) of data
    return (
      combinedServiceGroups?.filter((item) => {
        if (!set.has(item.serviceGroup)) {
          set.add(item.serviceGroup);
          return true;
        }
        return false;
      }, set) ?? []
    );
  } else {
    // return intersection( only common in both)
    return commonServiceGroups(apiComingServiceGroups, storeSelectedServiceGroups);
  }
};

/**
 * function - commonServiceGroups
 * @param {Array.<object>} apiComingServiceGroups - This is array of objects
 * @param {Array.<object>} storeSelectedServiceGroups - This is array of objects
 * @return {Array.<object>} - Returns array of objects
 */
export const commonServiceGroups = (apiComingServiceGroups, storeSelectedServiceGroups) => {
  // return intersection( only common in both)

  let storeSelectedServiceGroupsModified = transformStoreSelectedServiceGroupsData(
    storeSelectedServiceGroups,
  );
  return storeSelectedServiceGroupsModified
    ?.map((SSSG) => {
      return apiComingServiceGroups?.filter((ACSG) => SSSG.serviceGroup === ACSG.serviceGroup);
    })
    ?.flat();
};

/**
 * function - getNamedData
 * @param {Array.<object>} data - This is array of objects
 * @return {Array.<object>} - Returns array of objects
 */

export const getNamedData = (finalFormatedData, storeSelectedServiceGroup) => {
  const storeSelectedServiceGroupsModified =
    transformStoreSelectedServiceGroupsData(storeSelectedServiceGroup);
  return {
    ...finalFormatedData,
    data: finalFormatedData?.data?.map((data) => ({
      ...data,
      serviceGroup:
        storeSelectedServiceGroupsModified.find((elem) => data.serviceGroup === elem.serviceGroup)
          ?.serviceGroupName || data.serviceGroup,
      userRole: Array.isArray(data?.userRole)
        ? data?.userRole
            ?.map((role) => role?.replace(/[_-]/g, ' '))
            .filter((elem) => elem)
            .join(', ')
        : data?.userRole?.replace(/[_-]/g, ' '),
    })),
  };
};

/**
 * function - getFinalData
 * @param {Array.<object>} data - This is array of objects
 * @param {object} person - This is person objects
 * @param {object} header - This is header objects
 * @return {Array.<object>} - Returns array of objects
 */
export const getFinalFormatedData = (data, person, header) => {
  // return intersection( only common in both)
  const role = person?.type.join('');
  if (role === 'UserWorker') {
    return { header: header, data: dataAfterKeyRemoval(data) };
  } else if (role === 'User') {
    // return service group and user => remove worker
    return {
      header: removeKey(header, 'worker'),
      data: dataAfterKeyRemoval(data, 'worker'),
    };
  } else {
    // return only service group => remove worker and userRole
    return {
      header: removeKey(removeKey(header, 'userRole'), 'worker'),
      data: dataAfterKeyRemoval(dataAfterKeyRemoval(data, 'userRole'), 'worker'),
    };
  }
};

/**
 * function - dataAfterKeyRemoval
 * @param {Array.<object>} data - This is array of objects
 * @param {string} key - This is person objects
 * @return {Array.<object>} - Returns array of objects
 */

export const dataAfterKeyRemoval = (data, key = '') => {
  return data?.map((obj) =>
    removeKey(
      {
        serviceGroup: obj?.serviceGroup,
        worker: obj?.worker,
        userRole: obj?.userRole,
      },
      key,
    ),
  );
};

/**
 * function - removeKey
 * @param {object} data - This is data
 * @param {string} key - This is key
 * @return {object} - Returns object
 */

export const removeKey = (data, key) => {
  delete data[key];
  return data;
};

/**
 * function - combinedDataObjects
 * @param {Array.<object>} data - This is array of objects
 * @return {Array.<object>} - Returns array of objects
 */
export const combinedDataObjects = (data) =>
  data?.reduce((acc, cObj) => {
    let index = acc.findIndex((oq) => oq.serviceGroup === cObj.serviceGroup);
    if (index > -1) {
      acc[index] = {
        serviceGroup: cObj.serviceGroup,
        ...(cObj.path && {
          path: [
            ...(Array.isArray(acc[index].path) ? acc[index].path : [acc[index].path]),
            cObj.path,
          ],
        }),
        worker: acc[index].worker || cObj.worker,
        userRole: [
          ...(Array.isArray(acc[index].userRole) ? acc[index].userRole : [acc[index].userRole]),
          cObj.userRole,
        ],
      };
    } else {
      acc.push(cObj);
    }
    return acc;
  }, []);

/**
 * function - rolesByServiceGroups
 * @param {Array.<object>} data - This is array of objects
 * @param {Array.<object>} limitedUserRoles - This is array of objects
 * @return {Array.<object>} - Returns array of objects
 */
export const rolesByServiceGroups = (data, limitedUserRoles) => {
  const {
    attributes: { entity_group, worker_service_group },
  } = data;
  return [
    ...mapRoleWithServiceGroup(entity_group, limitedUserRoles, false, true),
    ...mapRoleWithServiceGroup(worker_service_group, limitedUserRoles, true, false),
  ];
};

/**
 * function - mapRoleWithServiceGroup
 * @param {Array.<object>} data - This is array of objects
 * @param {Array.<object>} limitedUserRoles - This is array of objects
 * @param {boolean} filterRole - If true then filterThisRole function will activate
 * @return {Array.<object>} - Returns array of objects
 */
export const mapRoleWithServiceGroup = (
  data = [],
  limitedUserRoles = [],
  worker = false,
  filterRole = false,
) => {
  const byServiceGroup = [];
  data.forEach((obj) => {
    const { roleName = '', groupCombination = [] } = obj;
    // If role is not matching then will skip
    if (filterRole && filterThisRole(limitedUserRoles, roleName)) return;
    const serviceGroupIds =
      groupCombination?.find(({ groupName }) => groupName === 'service_group')?.ids || [];
    serviceGroupIds.forEach((group) => {
      let path = `/attributes/${worker ? 'worker_service_group' : 'entity_group'}/${
        roleName ? roleName + '/' : ''
      }groupCombination/service_group/ids`;
      if (group === 'ALL') return; // skipping service groups 'ALL'
      byServiceGroup.push({
        serviceGroup: group,
        worker: worker,
        path: path,
        userRole: roleName,
      });
    });
  });
  return byServiceGroup;
};

/**
 * function - filterRole
 * @param {Array.<object>} data - This is array of objects
 * @param {string} roleName - This is role name
 * @return {boolean} - Returns true if any role is matching otherwise false
 */
export const filterThisRole = (data, roleName) => {
  return !data?.some((item) => item.a11yKey === roleName);
};
