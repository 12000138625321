// TODO: can move collapsible panel out of here if it makes sense

// TODO: move some of the task template stuff we're using in alerts up a level?

import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUpload } from '@ge/components/file-upload';
import { FormMode, FileType } from '@ge/models/constants';

import { AlertCollapsiblePanel } from '../alert-shared';

const AllowedFileTypes = [
  FileType.PDF,
  FileType.JPEG,
  FileType.PNG,
  FileType.MSG,
  FileType.PPT,
  FileType.PPTX,
  FileType.XLS,
  FileType.XLSX,
  FileType.CSV,
];

export const AttachmentsField = ({
  collapsed,
  hidden,
  maxAttachments,
  isEscalateCase,
  maxAttachmentSizeMb,
  mode,
  onToggleExpand,
  handleUpload,
  files,
  emailType,
}) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });

  // state
  const [resizeToggle, setResizeToggle] = useState(false);

  // TODO: look into collapsible panel resizing automatically when contents change size
  useEffect(() => {
    // wrapping in setTimeout so it will resize correctly once child is fully rendered
    const cancelId = setTimeout(() => setResizeToggle((prev) => !prev));

    return () => clearTimeout(cancelId);
  }, [files]);

  if (!ready || hidden || mode === FormMode.VIEW) {
    return null;
  }

  const translations = {
    headerLabel: t('form.attachments.label', 'Attachments'),
  };

  return (
    <AlertCollapsiblePanel
      divider={false}
      expanded={!collapsed}
      header={translations.headerLabel}
      mode={mode}
      onToggleExpand={onToggleExpand}
      renderToggle={resizeToggle}
      plusIcon={true}
    >
      <FileUpload
        maxFiles={maxAttachments}
        isEscalateCase={isEscalateCase}
        maxFileSizeMb={maxAttachmentSizeMb}
        onUpload={handleUpload}
        allowedFileTypes={AllowedFileTypes}
        emailType={emailType}
      />
    </AlertCollapsiblePanel>
  );
};

AttachmentsField.propTypes = {
  collapsed: PropTypes.bool,
  hidden: PropTypes.bool,
  maxAttachments: PropTypes.number,
  maxAttachmentSizeMb: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  onToggleExpand: PropTypes.func,
  handleUpload: PropTypes.func,
  files: PropTypes.array,
  emailType: PropTypes.string,
  isEscalateCase: PropTypes.bool,
};

AttachmentsField.defaultProps = {
  collapsed: true,
  hidden: false,
  maxAttachments: undefined,
  maxAttachmentSizeMb: undefined,
  mode: FormMode.CREATE,
  onToggleExpand: () => {},
  handleUpload: () => {},
  files: [],
  emailType: '',
};
