import dayjs from 'dayjs';
import { action, computed, thunk, persist } from 'easy-peasy';
import memoize from 'memoizerific';

import { getFilterDateRange } from '@ge/feat-analyze/util';
import { caseTableRowTransformer } from '@ge/feat-monitor/util/data-filter';
import { AssetType } from '@ge/models';
import {
  AnalyzeGlobalFilterKeys,
  AnomaliesTableCaseStatus,
  SortDirection,
  SortValueType,
} from '@ge/models/constants';
import {
  DateRange,
  DateTimeFormats,
  KpiCategoryDefs,
  KpiCategoriesHeader,
  TimeAggr,
} from '@ge/models/constants';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';
import { filterByView } from '@ge/shared/util/view-utils';
import { roundNumber } from '@ge/util';
import { uniqueVals } from '@ge/util/array-utils';
import { sorter } from '@ge/util/metric-sorter';
import { mapObject } from '@ge/util/object-utils';

import { DataExplorerTemplates, DataExplorerOptions } from '../models/data-explorer-defs';
import {
  fetchLowestPerformingRegions,
  fetchRegionSystemLoss,
  fetchRegionIecCategory,
  fetchSiteSystemLoss,
  fetchAllAssetCards,
  fetchAssetKpiDataBySite,
  fetchSiteConditionAggregate,
  fetchRegionKpiData,
  fetchSiteKpiDataByRegion,
  fetchDataExplorerAssetOverview,
  fetchAssetSignalData,
  fetchSiteKpiData,
} from '../services';

const defaultDateRange = {
  range: DateRange.LAST_7_DAYS,
  startDate: dayjs(),
  endDate: dayjs(),
};

const defaultKpiChartSummary = {
  total: 0,
  units: '',
};

// Define initial state
const defaultAnalyzeState = {
  systemLossByRegionId: {},
  systemLossBySiteId: {},
  fleetKpiData: {},
  regionOverviewById: {},
  regionKpiDataById: {},
  siteKpiDataByRegionId: {},
  siteCardsById: {},
  siteKpiDataById: {},
  assetCardsById: {},
  siteOverviewById: {},
  assetKpiDataBySiteId: {},
  lowestPerformingRegions: [],
  siteConditionAggregatesById: {},
  signalsByAlertId: {},
  signalsById: {},
  assetSignalMapping: {},
  dataExplorer: { charts: DataExplorerTemplates[DataExplorerOptions.AD_HOC].charts },
  dateRange: defaultDateRange,
  panelDateRange: persist(defaultDateRange, {
    storage: 'localStorage',
    mergeStrategy: 'overwrite',
  }),
  visibleKpis: [],
  globalFilters: {},
  trendGraphSelection: null,
  kpiChartSummary: defaultKpiChartSummary,
  defaultParetoDownloadState: false,
};

// Actions
const analyzeActions = {
  /**
   * Reset state to defaults
   */
  /* eslint-disable-next-line no-unused-vars */
  resetAnalyze: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, defaultAnalyzeState);
  }),

  resetDateRange: action((state) => {
    state.dateRange = defaultDateRange;
    state.panelDateRange = defaultDateRange;
  }),

  fetchRegionSystemLoss: thunk(async (actions, payload, { fail, getStoreActions }) => {
    try {
      const {
        data,
        entities: { regions },
      } = await fetchRegionSystemLoss(payload);
      getStoreActions().regions.updateRegions(Object.values(regions));
      actions.updateRegionSystemLoss({ [payload]: data });
    } catch (err) {
      fail(err);
    }
  }),

  fetchRegionIecCategory: thunk(async (actions, payload, { fail }) => {
    try {
      // set defaults if not provided in payload
      const { endDate: defaultEndDate, startDate: defaultStartDate } = getDefaultDateRange();
      const {
        category,
        endDate = defaultEndDate,
        regionId,
        startDate = defaultStartDate,
      } = payload;
      const {
        data,
        // entities: { regions },
      } = await fetchRegionIecCategory({ categories: [category], endDate, regionId, startDate });
      // getStoreActions().regions.updateRegions(Object.values(regions));
      actions.updateRegionIecCategoryData({ regionId, data });
    } catch (err) {
      fail(err);
    }
  }),

  fetchSiteSystemLoss: thunk(async (actions, payload, { fail }) => {
    try {
      const { data } = await fetchSiteSystemLoss(payload);
      actions.updateSiteIecCategoryData({ [payload]: data });
    } catch (err) {
      fail(err);
    }
  }),

  /**
   * @deprecated Use `useRegionKpiData()` instead.
   */
  fetchRegionKpiData: thunk(async (actions, { regionId, kpiCategories }) => {
    const { data } = await fetchRegionKpiData({ regionId, categories: kpiCategories });
    actions.updateRegionKpiData({ regionId, data });
  }),

  /**
   * @deprecated Use `useSitesKpiData()` instead.
   */
  fetchSiteKpiDataByRegion: thunk(async (actions, { regionId, kpiCategories }, { fail }) => {
    try {
      const { data } = await fetchSiteKpiDataByRegion(regionId, kpiCategories);

      actions.updateSiteKpiByRegion({ regionId, data });
    } catch (err) {
      fail(err);
    }
  }),

  fetchSiteKpiData: thunk(async (actions, payload, { fail }) => {
    try {
      // set defaults if not provided in payload
      // can revisit this since we might want to enforce explicit param setting
      // especially if these defaults are relatively expensive
      const { endDate: defaultEndDate, startDate: defaultStartDate } = getDefaultDateRange();
      const {
        endDate = defaultEndDate,
        kpiCategories: categories,
        siteId,
        startDate = defaultStartDate,
        timeAggr = TimeAggr.DAILY,
      } = payload;

      const { data } = await fetchSiteKpiData({ categories, endDate, siteId, startDate, timeAggr });

      actions.updateSiteKpiData({ siteId, data });
    } catch (err) {
      fail(err);
    }
  }),

  fetchAssetKpiDataBySite: thunk(async (actions, payload, { fail, getStoreActions }) => {
    try {
      const { siteId, kpiCategories } = payload;
      const {
        data,
        entities: { assets },
      } = await fetchAssetKpiDataBySite(siteId, kpiCategories);

      getStoreActions().assets.updateAssets(Object.values(assets));
      actions.updateAssetKpiDataBySite({ siteId, data });
    } catch (err) {
      fail(err);
    }
  }),

  fetchAlertSignalData: thunk(async (actions, alertId, { fail, getStoreState }) => {
    try {
      const alert = getStoreState().alerts.alerts[alertId];
      if (!alert) {
        fail(`Alert ${alertId} not found.`);
        return;
      }
      const alertStart = dayjs(alert.start);
      const alertEnd = dayjs(alert.end);
      const secDuration = alertEnd.diff(alertStart, 'second');
      const dataStart = alertStart.subtract(secDuration, 'seconds').toISOString();
      const dataEnd = alertEnd.add(secDuration, 'seconds').toISOString();
      const { data } = await fetchAssetSignalData(alert.asset.id, {
        start: dataStart,
        end: dataEnd,
        signals: [alert.signalId],
      });
      const signalData = data.length ? data[0].signalData : [];
      actions.updateAssetSignalData({ signalData, alertId });
    } catch (err) {
      fail(err);
    }
  }),

  fetchAssetCards: thunk(async (actions, payload, { fail }) => {
    try {
      const { data } = await fetchAllAssetCards();

      actions.updateAssetCards(data);
    } catch (err) {
      fail(err);
    }
  }),

  fetchLowestPerformingRegions: thunk(async (actions, _, { fail, getStoreActions }) => {
    try {
      const {
        data,
        entities: { regions },
      } = await fetchLowestPerformingRegions();
      getStoreActions().regions.updateRegions(Object.values(regions));
      actions.setLowestPerformingRegions(data);
    } catch (err) {
      fail(err);
    }
  }),

  fetchSiteConditionAggregate: thunk(async (actions, payload, { fail }) => {
    try {
      const { endDate: defaultEndDate, startDate: defaultStartDate } = getDefaultDateRange();
      const { siteId, startDate = defaultStartDate, endDate = defaultEndDate } = payload;
      const { data } = await fetchSiteConditionAggregate(siteId, startDate, endDate);
      actions.updateSiteConditionAggregate(data);
    } catch (err) {
      fail(err);
    }
  }),

  fetchDataExplorerOverview: thunk(async (actions, assetIds, { fail }) => {
    try {
      const res = await fetchDataExplorerAssetOverview(assetIds);
      const { signalMappings = {}, assetSignalMap = {} } = res?.data ?? {};
      actions.updateSignalMappings(signalMappings);
      actions.updateAssetSignalMap(assetSignalMap);
      return res;
    } catch (err) {
      fail(err);
    }
  }),

  updateSignalMappings: action((state, payload) => {
    state.signalsById = {
      ...state.signalsById,
      ...payload,
    };
  }),

  updateAssetSignalMap: action((state, payload) => {
    state.assetSignalMapping = {
      ...state.assetSignalMapping,
      ...payload,
    };
  }),

  updateSiteConditionAggregate: action((state, payload) => {
    const siteId = payload.site.id;
    state.siteConditionAggregatesById[siteId] = {
      ...state.siteConditionAggregatesById[siteId],
      ...payload,
    };
  }),

  updateSiteIecCategoryData: action((state, payload) => {
    state.systemLossBySiteId = { ...state.systemLossBySiteId, ...payload };
  }),

  updateRegionSystemLoss: action((state, payload) => {
    state.systemLossByRegionId = { ...state.systemLossByRegionId, ...payload };
  }),

  updateRegionOverview: action((state, payload) => {
    state.regionOverviewById = { ...state.regionOverviewById, ...payload };
  }),

  updateSiteCards: action((state, payload) => {
    state.siteCardsById = { ...state.siteCardsById, ...payload };
  }),

  updateRegionKpiData: action((state, payload) => {
    const { regionId, data } = payload;
    state.regionKpiDataById = {
      ...state.regionKpiDataById,
      [regionId]: { ...state.regionKpiDataById[regionId], ...data },
    };
  }),

  updateSiteKpiByRegion: action((state, payload) => {
    const { regionId, data } = payload;
    state.siteKpiDataByRegionId[regionId] = {
      ...state.siteKpiDataByRegionId[regionId],
      ...data,
    };
  }),

  updateSiteKpiData: action((state, payload) => {
    const { siteId, data } = payload;
    state.siteKpiDataById = {
      ...state.siteKpiDataById,
      [siteId]: { ...state.siteKpiDataById[siteId], ...data },
    };
  }),

  updateAssetKpiDataBySite: action((state, payload) => {
    const { siteId, data } = payload;
    state.assetKpiDataBySiteId[siteId] = {
      ...state.assetKpiDataBySiteId[siteId],
      ...data,
    };
  }),

  updateAssetSignalData: action((state, { signalData, alertId }) => {
    state.signalsByAlertId[alertId] = signalData;
  }),

  updateAssetCards: action((state, payload) => {
    state.assetCardsById = { ...state.assetCardsById, ...payload };
  }),

  setLowestPerformingRegions: action((state, payload) => {
    state.lowestPerformingRegions = payload;
  }),

  updateDataExplorer: action((state, payload) => {
    state.dataExplorer = {
      ...state.dataExplorer,
      ...payload,
    };
  }),

  updateDateRange: action((state, payload) => {
    state.dateRange = {
      ...state.dateRange,
      ...payload,
    };
  }),

  updatePanelDateRange: action((state, payload) => {
    state.panelDateRange = {
      ...state.panelDateRange,
      ...payload,
    };
  }),

  setVisibleKpis: action((state, payload) => {
    state.visibleKpis = payload;
  }),

  setGlobalFilters: action((state, payload) => {
    state.globalFilters = payload;
  }),

  updateTrendGraphSelection: action((state, payload) => {
    state.trendGraphSelection = payload;
  }),

  updateKpiChartSummary: action((state, payload) => {
    state.defaultKpiChartSummary = payload;
  }),

  updateParetoDownloadState: action((state, payload) => {
    state.defaultParetoDownloadState = payload;
  }),
};

// Listeners
const analyzeListeners = {};

// Computed values
const analyzeComputed = {
  /**
   * @deprecated KPI data moved to react-query hooks
   */
  getAssetsBySite: computed(
    [(_, storeState) => storeState.assets.getAssetsBySiteId, (state) => state.siteKpiDataById],
    (getAssetsBySiteId, siteKpiDataById = {}) =>
      memoize(5)((siteId) => {
        const assets = getAssetsBySiteId(siteId);

        const assetKpiData = Object.entries(siteKpiDataById[siteId] ?? {}).reduce(
          (_assetKpiData, [category, { entityData }]) => ({
            ..._assetKpiData,
            [category]: entityData?.reduce(
              (kpiAssets, { entity: { id }, value }) => ({
                ...kpiAssets,
                [id]: value,
              }),
              {},
            ),
          }),
          {},
        );

        if (!Object.keys(assetKpiData).length) {
          return [];
        }

        return assets.map((asset) => ({
          ...asset,
          metrics: {
            ...asset.metrics,
            performance: {
              [KpiCategoryDefs.CAPACITY_FACTOR]: roundNumber(
                assetKpiData[KpiCategoryDefs.CAPACITY_FACTOR]?.[asset.id],
                2,
              ),
              [KpiCategoryDefs.EVENT_COVERAGE]: roundNumber(
                assetKpiData[KpiCategoryDefs.EVENT_COVERAGE]?.[asset.id],
                2,
              ),
              [KpiCategoryDefs.PRODUCTION_ACTUAL]: roundNumber(
                assetKpiData[KpiCategoryDefs.PRODUCTION_ACTUAL]?.[asset.id],
                2,
              ),
              [KpiCategoryDefs.PRODUCTION_LOST_CONTRACT]: roundNumber(
                assetKpiData[KpiCategoryDefs.PRODUCTION_LOST_CONTRACT]?.[asset.id],
                2,
              ),
              [KpiCategoryDefs.TBA]: roundNumber(assetKpiData[KpiCategoryDefs.TBA]?.[asset.id], 2),
            },
          },
        }));
      }),
  ),

  getLowestPerformingRegions: computed(
    [
      (state) => state.lowestPerformingRegions,
      (_, storeState) => storeState.regions.regions,
      (_, storeState) => storeState.view.currentView,
      (_, storeState) => storeState.sites.sites,
    ],
    (lowestPerformingRegions, regions) => {
      // Commenting this out for mocking / wiring. May revist or remove as needed.
      // const storedSites = Object.values(sites);

      // Commenting this out for mocking / wiring. May revist or remove as needed.
      // const filteredRegions = filterByView(storedSites, view).map((site) => site?.region?.id);
      // TODO - doing this because sites don't explicitly come back with this call.
      // If the region concept stays, we could update this

      // Commenting this out for mocking / wiring. May revist or remove as needed.
      // const viewFilter = ({ region }) => {
      //   if (!storedSites.length) return true;
      //   return filteredRegions.includes(region.id);
      // };
      return (
        lowestPerformingRegions
          // Commenting this out for mocking / wiring. May revist or remove as needed.
          // .filter(viewFilter)
          .map((data) => ({ ...data, region: regions[data.region.id] }))
          .sort((a, b) => a.availability.value - b.availability.value)
      );
    },
  ),

  getOverviewByRegion: computed([(state) => state.regionKpiDataById], (regionKpiDataById) =>
    memoize(3)((regionId) => {
      const regionData = regionKpiDataById[regionId];
      if (!regionData) return {};

      const kpiProperty = (acc, curr) => {
        const aggregateValue = regionData?.[curr]?.aggregateValue ?? null;

        const value = isNaN(aggregateValue) ? aggregateValue : roundNumber(aggregateValue, 2);
        const units = regionData?.[curr]?.units;

        return { ...acc, [curr]: { value, units } };
      };

      return KpiCategoriesHeader.reduce(kpiProperty, {});
    }),
  ),

  getSiteCardsByRegion: computed(
    [
      (state) => state.siteCardsById,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.view.currentView,
    ],
    (siteCardsById, sitesById, view) =>
      memoize(5)((regionId) => {
        const data = Object.values(siteCardsById)
          .filter((card) => card.region.id === regionId)
          .map((data) => {
            const siteId = data.site.id;
            if (!sitesById[siteId]) return data;
            const cleanedSite = {
              id: siteId,
              name: sitesById[siteId].name,
            };
            return { ...data, site: cleanedSite };
          });

        return filterByView(data, view).sort(
          sorter('site.name', SortDirection.ASC, SortValueType.ALPHANUMERIC),
        );
      }),
  ),

  getSiteCards: computed(
    [(state) => state.siteCardsById, (state, storeState) => storeState.sites.sites],
    (siteCardsById, sitesById) =>
      memoize(2)((filterSites = []) => {
        const filter = (card) =>
          filterSites.length ? filterSites.map((s) => s.id).includes(card.site.id) : true;
        return Object.values(siteCardsById)
          .filter(filter)
          .map((data) => {
            const siteId = data.site.id;
            if (!sitesById[siteId]) return data;
            const cleanedSite = {
              id: siteId,
              name: sitesById[siteId].name,
            };

            // this is still being mocked in the bff
            const gauge = {
              [KpiCategoryDefs.TBA]: data.gauge?.availability,
              [KpiCategoryDefs.CUMULATIVE_PRODUCTION]: data.gauge?.cumulative,
            };

            // TODO: get below kpi data in service
            const kpi = {
              [KpiCategoryDefs.PRODUCTION_LOST_CONTRACT]: [
                {
                  value: 92.1,
                  unit: 'mwh',
                },
              ],
              [KpiCategoryDefs.PRODUCTION_ACTUAL]: [
                {
                  value: 80.7,
                  unit: 'mwh',
                },
              ],
            };

            return { ...data, gauge, kpi, site: cleanedSite };
          })
          .sort(sorter('site.name', SortDirection.ASC, SortValueType.ALPHANUMERIC));
      }),
  ),

  getRegionKpiData: computed(
    [(state) => state.regionKpiDataById, (_, storeState) => storeState.regions.regions],
    (regionKpiDataById, regionsById) =>
      memoize(2)((regionId) => {
        const region = regionsById[regionId];

        if (!region) return {};

        const { categories, ...regionData } = regionKpiDataById[regionId] || {};

        return (
          categories &&
          categories.reduce(
            (acc, category) => ({
              ...acc,
              [category]: regionData[category] || {},
            }),
            { region },
          )
        );
      }),
  ),

  getSiteKpiDataByRegion: computed(
    [
      (state) => state.siteKpiDataByRegionId,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.view.currentView,
    ],
    (siteKpiDataByRegionId, sitesById, view) =>
      memoize(5)((regionId, sortByKpi, sortDirection = SortDirection.ASC) => {
        const regionData = siteKpiDataByRegionId[regionId] || {};
        const viewSiteIds = filterByView(Object.values(sitesById), view).map((site) => site.id);
        const entityData = Object.entries(regionData).reduce((entityData, [key, { data }]) => {
          return {
            ...entityData,
            [key]: {
              data: data
                .filter(({ site }) => viewSiteIds.includes(site.id))
                .map(({ site, value }) => ({
                  entity: {
                    ...site,
                    name: sitesById[site.id].name,
                  },
                  value: roundNumber(value, 2),
                })),
            },
          };
        }, {});

        return getSortedKpiData(entityData, sortByKpi, sortDirection);
      }),
  ),

  getAssetCards: computed(
    [(state) => state.assetCardsById, (state, storeState) => storeState.assets.assets],
    (assetCardsById, assetsById) =>
      memoize(1)((filterSites = []) => {
        const filter = (card) =>
          filterSites.length ? filterSites.map((s) => s.id).includes(card.site.id) : true;
        return (
          Object.values(assetCardsById)
            .filter(filter)
            .map((data) => {
              const assetId = data.asset.id;
              if (!assetsById[assetId]) return data;
              const cleanedAsset = {
                id: assetId,
                name: assetsById[assetId].name,
              };

              // TODO: get real kpi data for these
              const gauge = {
                [KpiCategoryDefs.TBA]: data.gauge?.availability,
                [KpiCategoryDefs.CUMULATIVE_PRODUCTION]: data.gauge?.cumulative,
              };

              const kpi = {
                [KpiCategoryDefs.PRODUCTION_LOST_CONTRACT]: [
                  {
                    value: 92.1,
                    unit: 'mwh',
                  },
                ],
                [KpiCategoryDefs.PRODUCTION_ACTUAL]: [
                  {
                    value: 80.7,
                    unit: 'mwh',
                  },
                ],
              };

              // TODO - hack remove site mapping after we have asset specific card
              return { ...data, asset: cleanedAsset, site: cleanedAsset, gauge, kpi };
            })
            .sort(sorter('asset.name', SortDirection.ASC, SortValueType.ALPHANUMERIC))
            // TODO - Return the first 100 for now because WOW
            .slice(0, 100)
        );
      }),
  ),

  getSiteConditionAggregates: computed(
    [(state) => state.siteConditionAggregatesById],
    (siteConditionAggregatesById) =>
      memoize(2)((siteId, conditionArray) => {
        if (
          !(
            siteConditionAggregatesById &&
            Object.keys(siteConditionAggregatesById).length &&
            siteConditionAggregatesById[siteId]
          )
        ) {
          return {};
        }

        return conditionArray.reduce(
          (acc, condition) => ({
            ...acc,
            [condition]: siteConditionAggregatesById[siteId][condition],
          }),
          { site: { id: siteId } },
        );
      }),
  ),

  getOverviewBySite: computed(
    [(state) => state.siteKpiDataById, (_, storeState) => storeState.assets.assets],
    (siteKpiDataById) =>
      memoize(5)((siteId) => {
        const siteData = siteKpiDataById[siteId];

        if (!siteData) return {};

        const kpiProperty = (acc, curr) => {
          const aggregateValue = siteData?.[curr]?.aggregateValue ?? null;

          const value = isNaN(aggregateValue) ? aggregateValue : roundNumber(aggregateValue, 2);
          const units = siteData?.[curr]?.units;

          return { ...acc, [curr]: { value, units } };
        };

        return KpiCategoriesHeader.reduce(kpiProperty, {});
      }),
  ),

  getSystemLossBySite: computed(
    [(state) => state.systemLossBySiteId],
    (systemLossBySiteId) => (siteId) => systemLossBySiteId[siteId],
  ),

  getDataExplorerOverview: computed(
    [
      (state) => state.signalsById,
      (state) => state.assetSignalMapping,
      (_, storeState) => storeState.assets.assets,
      (_, storeState) => storeState.sites.sites,
    ],
    (signalsById, assetSignalMapping, assets, sites) =>
      memoize(10)((assetIds = []) => {
        if (!Object.keys(assets).length || !Object.keys(sites).length) return [];
        if (!assetIds?.every((id) => Object.keys(assets).includes(id))) return [];

        const hasAssetSignalMappings = Object.keys(assetSignalMapping).length > 0;
        const signalsWithAssets = hasAssetSignalMappings
          ? mapAssetSignals({ assetIds, assetSignalMapping, signalsById })
          : [];

        const mappedAssets = assetIds.map((id) => {
          const asset = assets[id];
          const site = sites[asset.site.id];
          return {
            asset: {
              id: asset.id,
              name: asset.name,
              make: asset.make,
              model: asset.model,
              events: asset.events,
              tasks: asset.tasks,
              cases: asset.cases,
            },
            site: {
              id: site.id,
              name: site.name,
            },
          };
        });
        return { assets: mappedAssets, signals: signalsWithAssets };
      }),
  ),

  getAlertSignalData: computed(
    [(state) => state.signalsByAlertId, (_, storeState) => storeState.alerts.alerts],
    (signalsById, alertsById) =>
      memoize(10)((alertId) => {
        const alert = alertsById[alertId];
        if (!alert) return {};

        const signalData = signalsById[alertId];
        if (!signalData) return {};

        const { start, end } = alert;
        const alertStartSec = dayjs(start).unix();
        const alertEndSec = dayjs(end).unix();
        const alertStartIdx = signalData.findIndex(({ ts }) => ts >= alertStartSec);
        const alertEndIdx = signalData.findIndex(({ ts }) => ts > alertEndSec);
        return {
          signal: alert.signalId,
          alerts: [signalData.slice(alertStartIdx, alertEndIdx)],
          context: [signalData.slice(0, alertStartIdx + 1), signalData.slice(alertEndIdx - 1)],
        };
      }),
  ),

  getSiteCaseTable: computed(
    [
      (_, storeState) => storeState.assets.assets,
      (_, storeState) => storeState.cases.cases,
      (_, storeState) => storeState.sites.sites,
    ],
    (assets, cases, sites) =>
      memoize(2)((siteId, sortMetric, sortDirection) => {
        const siteAssetIds = Object.values(assets)
          .filter((a) => a?.site?.id === siteId)
          .map((a) => a.id);
        const siteCases = Object.values(cases).filter(
          (c) =>
            AnomaliesTableCaseStatus.includes(String(c.status).toUpperCase()) &&
            siteAssetIds.includes(c.asset.id) &&
            !c.parentId,
        );
        const sortType =
          sortMetric === `${AnomaliesColumns.ASSET}.description` ? SortValueType.ALPHANUMERIC : '';
        return siteCases
          .map((c) =>
            caseTableRowTransformer({
              ...c,
              site: mapObject(sites[siteId], ['id', 'name']),
              asset: mapObject(assets[c.asset.id], ['id', 'name']),
            }),
          )
          .sort(sorter(sortMetric, sortDirection, sortType));
      }),
  ),

  getDateRange: computed([(_, storeState) => storeState.analyze.dateRange], (dateRange) =>
    memoize(2)((entityTimezone) => getFilterDateRange({ ...dateRange, entityTimezone })),
  ),

  getGlobalFiltersWithSiteIds: computed(
    [
      (_, storeState) => storeState.analyze.globalFilters,
      (_, storeState) => storeState.view.currentView,
    ],
    (globalFilters, view) => {
      const filters = {
        ...globalFilters,
        [AnalyzeGlobalFilterKeys.SITE_IDS]: view?.sites?.map(({ id }) => id),
      };

      // strip out any filters that don't have actual values
      // this is to reduce redundant calls when something gets toggled back and forth
      // such as when an array is added, then cleared but still passes an empty array along which looks like a new call
      return Object.entries(filters).reduce((_filters, [key, value]) => {
        if (value && (!Array.isArray(value) || value.length)) {
          _filters[key] = value;
        }

        return _filters;
      }, {});
    },
  ),

  getFilteredAssetsBySiteId: computed(
    [(state) => state.globalFilters, (_, storeState) => storeState.assets.getAssetsBySiteIdAndType],
    (globalFilters, getAssetsBySiteIdAndType) =>
      memoize(1)((siteId, assetType = AssetType.WIND_TURBINE) => {
        const assets = getAssetsBySiteIdAndType(siteId, assetType) ?? [];
        const predicate = globalFiltersAssetPredicateFn(globalFilters);

        return assets.filter(predicate);
      }),
  ),
};

const getSortedKpiData = (kpiData, sortByKpi, sortDirection = SortDirection.ASC) => {
  const { entityData, ..._data } = kpiData[sortByKpi] ?? {};

  if (!entityData) {
    return kpiData;
  }

  const compare = (a, b) => (sortDirection === SortDirection.DESC ? b - a : a - b);
  const sorted = entityData.sort(({ value: a = 0 }, { value: b = 0 }) => compare(a, b));
  let data = { [sortByKpi]: { entityData: sorted, ..._data } };

  // sort other kpis based on sorted
  const adjacentData = Object.entries(kpiData).reduce(
    (adjacent, [kpi, { entityData: adjacentKpi = [], ...adjacentRest }]) =>
      kpi === sortByKpi
        ? adjacent
        : {
            ...adjacent,
            [kpi]: {
              ...adjacentRest,
              // compact sort by another array from https://stackoverflow.com/a/54913401
              entityData: sorted.map(
                ({ entity: { id: sortedId, ...sortedEntity } }) =>
                  adjacentKpi.find(({ entity: { id } }) => sortedId === id) ?? {
                    // if for some reason we don't have an adjacent entity,
                    // we just create a placeholder with null value
                    // can revisit this, because a) it should't happen and
                    // b) we might want to reflect this differently
                    entity: { id: sortedId, ...sortedEntity },
                    value: null,
                  },
              ),
            },
          },
    {},
  );

  const result = {
    ...data,
    ...adjacentData,
  };

  return result;
};

const getDefaultDateRange = () => ({
  endDate: dayjs().format(DateTimeFormats.ENDPOINT_PARAM),
  startDate: dayjs().startOf('M').format(DateTimeFormats.ENDPOINT_PARAM),
});

// TODO: figure out if it needs to match all or any in here
// going with all for now and behavior in backend filters seems to support this
const globalFiltersAssetPredicateFn =
  (filters = {}) =>
  ({ controlCode, model, platform }) => {
    // TODO: expand on which filters we care about here
    // not sure what to do with scope filters currently
    const { controlCodes = [], models = [], platforms = [] } = filters;

    if (controlCodes.length && !controlCodes.includes(controlCode)) {
      return false;
    }

    if (models.length && !models.includes(model)) {
      return false;
    }

    return !platforms.length || platforms.includes(platform);
  };

export const mapAssetSignals = ({ assetIds, assetSignalMapping, signalsById }) => {
  const selectedSignalMappings = uniqueVals(
    assetIds.map((id) => assetSignalMapping[id]).flat(),
  ).filter(Boolean);
  const signalAssetIdMap = assetIds
    .map((id) => ({ mapping: assetSignalMapping[id], assetId: id }))
    .reduce(
      (acc, { mapping, assetId }) => {
        if (!mapping?.length) return acc;

        mapping.forEach((mapId) => {
          acc[mapId] = [...acc[mapId], assetId];
        });

        return acc;
      },
      selectedSignalMappings.reduce((acc, m) => {
        if (!m) return acc;

        acc[m] = [];
        return acc;
      }, {}),
    );

  const signals = selectedSignalMappings
    ?.map((signalId) =>
      signalsById[signalId]?.map((signal) => ({ ...signal, signalMappings: [signalId] })),
    )
    .flat();

  const combinedSignals = signals.reduce((acc, obj) => {
    if (acc[obj.id]) {
      acc[obj.id].signalMappings = acc[obj.id].signalMappings.concat(obj.signalMappings);
    } else {
      acc[obj.id] = obj;
    }
    return acc;
  }, {});

  const signalsWithAssets = Object.values(combinedSignals)
    .map((signal) => {
      const { signalMappings, ...rest } = signal;
      const assetIds = signalMappings
        .map((signalMapping) => signalAssetIdMap[signalMapping])
        .flat();
      return {
        ...rest,
        assetIds,
      };
    })
    .sort(sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC));
  return signalsWithAssets;
};

// Compile the view store object for export
const analyzeModel = {
  ...defaultAnalyzeState,
  ...analyzeActions,
  ...analyzeComputed,
  ...analyzeListeners,
};

export default analyzeModel;
