import { getLoadable } from '@ge/shared/util';

// Define the available pages for lazy loading.
export const loadables = {
  reports: getLoadable(() => import('./reports')),
};

// @index('./**/*.jsx', f => `export * from '${f.path}';`)
export * from './reports';
// @endindex
