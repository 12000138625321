import { TableFilterTypes } from '@ge/models/constants';

export const RolesColumns = {
  GROUP_ROLE: 'group-role',
  ROLE: 'role',

  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'description',

  GROUP_TENANT: 'group-tenant',
  TENANT: 'tenant',
};

// Define all available columns in the roles table.
export const RolesColumnDefs = {
  [RolesColumns.GROUP_ROLE]: {
    cols: {
      [RolesColumns.ROLE]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.role',
            a11yDefault: 'Role',
            sortValue: 'role',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [RolesColumns.GROUP_DESCRIPTION]: {
    cols: {
      [RolesColumns.DESCRIPTION]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.description',
            a11yDefault: 'Description',
            sortValue: 'description',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [RolesColumns.GROUP_TENANT]: {
    cols: {
      [RolesColumns.TENANT]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.tenant',
            a11yDefault: 'Tenant',
            sortValue: 'tenant.id',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },
};

export const globalRolesCols = [
  {
    id: RolesColumns.GROUP_ROLE,
    cols: [
      {
        id: RolesColumns.ROLE,
        visible: true,
      },
    ],
  },
  {
    id: RolesColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: RolesColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: RolesColumns.GROUP_TENANT,
    cols: [
      {
        id: RolesColumns.TENANT,
        visible: true,
      },
    ],
  },
];

export const tenantRolesCols = [
  {
    id: RolesColumns.GROUP_ROLE,
    cols: [
      {
        id: RolesColumns.ROLE,
        visible: true,
      },
    ],
  },
  {
    id: RolesColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: RolesColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
];
