import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { ChartBorder } from '@ge/components/charts';
import { useDataExplorerContext } from '@ge/feat-analyze/context';

const ChartLayout = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const ChartContainerStyle1 = css`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.height}px;
  .highcharts-container,
  .highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }
`;

const ChartContainerStyle2 = css`
  border-radius: 4px;
  border-color: ${({ theme }) => theme.sensorReadingsChart.borderColor[ChartLayout.SECONDARY]};
  padding: 10px 7px;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.height}px;
  .highcharts-container,
  .highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }
  .highcharts-plot-background {
    fill: ${({ theme, layout }) => theme.sensorReadingsChart.plotBackground[layout]};
  }
  .highcharts-yaxis {
    .highcharts-axis-line {
      stroke-width: 0.5;
      stroke: ${({ theme }) => theme.charts.gridLineColor};
    }
  }
`;

const dragHoverCSS = css`
  background: ${(props) => props.theme.table.dragDropBackgroundColor};
`;

export const ChartContainer = styled(ChartBorder)`
  ${({ enableMultiAxis }) => (!enableMultiAxis ? ChartContainerStyle1 : ChartContainerStyle2)}
  ${({ dragOver }) => dragOver && dragHoverCSS};
  &.main-chart {
    min-width: calc(68% - 16px);
    margin-bottom: ${({ enableMultiAxis }) => (!enableMultiAxis ? `24px;` : `8px`)};
  }
`;

export const DraggableContainer = ({ className, height, index, children }) => {
  const { enableMultiAxis, draggable, draggableContext } = useDataExplorerContext();
  const { handleDragStart, handleDragOver, handleDragEnter, handleDrop, handleDragEnd } =
    draggableContext?.handlers ?? {};
  const { dragOver } = draggableContext?.state ?? {};

  if (!draggable) {
    return (
      <ChartContainer
        className={className}
        index={index}
        height={height}
        enableMultiAxis={enableMultiAxis}
      >
        {children}
      </ChartContainer>
    );
  }

  return (
    <ChartContainer
      className={className}
      index={index}
      height={height}
      enableMultiAxis={enableMultiAxis}
      draggable={draggable}
      dragOver={dragOver === index}
      onDragStart={(e) => handleDragStart(e, index)}
      onDragOver={handleDragOver}
      onDragEnter={(e) => handleDragEnter(e, index)}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
    >
      {children}
    </ChartContainer>
  );
};

DraggableContainer.propTypes = {
  children: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  height: PropTypes.number,
  index: PropTypes.number,
};
