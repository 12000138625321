import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Radio } from '@ge/components/radio/radio';
import { Severity } from '@ge/components/severity';
import { Table, TableArrow, Tbody, Td, Th, Thead, Tr } from '@ge/components/table';
import { DateTimeFormats, PanelColumnWidths } from '@ge/models/constants';
import { typography } from '@ge/tokens';

const TaskIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tasks.details.columnButtonColor,
  icon: Icons.TASK,
  size: 12,
}))``;

const StyledTh = styled(Th)`
  color: ${({ theme }) => theme.entityDetails.cases.details.resolutionText};
  padding: 0;
`;

const StyledTd = styled(Td)`
  padding: 0;
  text-overflow: clip;
`;

const StyledTr = styled(Tr)`
  background-color: ${({ theme }) => theme.entityDetails.cases.details.taskRow};
`;

const StyledRadio = styled(StyledTd)`
  padding-left: 5px;
`;

const StyledDateDisplay = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.dataDefinition};
  font-weight: ${typography.weight.medium};
  font-size: 12px;
  span {
    color: ${({ theme }) => theme.entityDetails.cases.details.dataTitle};
    margin-left: 10px;
  }
`;

const getDateDisplay = (date, timezone) => {
  return (
    <StyledDateDisplay>
      {dayjs(date).tz(timezone).format(DateTimeFormats.DEFAULT_TIME)}
      <span>{dayjs(date).tz(timezone).format(DateTimeFormats.ISSUE_HEADER_DATE)}</span>
    </StyledDateDisplay>
  );
};

export const CaseResolutionTaskTable = ({
  caseTasks,
  handleTaskSelect,
  selectedRowId,
  openTaskPanel,
}) => {
  const { t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  return (
    <Table height="400" compressed transparent>
      <Thead noTitles transparent>
        <tr>
          <StyledTh width={PanelColumnWidths.XXSMALL} />
          <StyledTh
            labels={{
              [t('case_panel.resolution.task_table.type', 'Type')]: 'type',
            }}
            align="center"
            width={PanelColumnWidths.XXSMALL}
          />
          <StyledTh
            labels={{
              [t('case_panel.resolution.task_table.task_id', '#')]: 'taskId',
            }}
            align="center"
            width={PanelColumnWidths.XSMALL}
          />
          <StyledTh width={PanelColumnWidths.XXSMALL} />
          <StyledTh
            labels={{
              [t('case_panel.resolution.task_table.description', 'Description')]: 'title',
            }}
            align="left"
            width={PanelColumnWidths.LARGE}
          />
          <StyledTh
            labels={{
              [t('case_panel.resolution.task_table.opened', 'Opened')]: 'createdDate',
            }}
            width={PanelColumnWidths.SMALL}
          />
          <StyledTh
            width={PanelColumnWidths.SMALL}
            labels={{
              [t('case_panel.resolution.task_table.closed', 'Closed')]: 'completedDateTime',
            }}
          />
          <StyledTh width={PanelColumnWidths.XXSMALL} />
        </tr>
      </Thead>
      <Tbody transparent>
        {caseTasks?.map((taskData) => {
          const { id, taskId, title, createDate, completedDateTime, severity } = taskData;

          return (
            <StyledTr key={id} onClick={(e) => handleTaskSelect(e, taskData)}>
              <StyledRadio width={PanelColumnWidths.XXSMALL}>
                <Radio
                  id={id}
                  name={title}
                  label=""
                  value={id}
                  checked={selectedRowId === id}
                  readOnly
                />
              </StyledRadio>
              <StyledTd
                align="center"
                width={PanelColumnWidths.XXSMALL}
                maxWidth={PanelColumnWidths.XXSMALL}
              >
                <TaskIcon />
              </StyledTd>
              <StyledTd width={PanelColumnWidths.XSMALL} maxWidth={PanelColumnWidths.XSMALL}>
                {taskId}
              </StyledTd>
              <StyledTd
                align="center"
                width={PanelColumnWidths.XXSMALL}
                maxWidth={PanelColumnWidths.XXSMALL}
              >
                <Severity level={severity} />
              </StyledTd>
              <Td width={PanelColumnWidths.LARGE}>{title}</Td>
              <Td width={PanelColumnWidths.LARGE}>
                {createDate ? getDateDisplay(createDate) : null}
              </Td>
              <Td width={PanelColumnWidths.SMALL}>
                {completedDateTime ? getDateDisplay(completedDateTime) : null}
              </Td>
              <StyledTd border width={PanelColumnWidths.XXSMALL} noPadding>
                <button type="button" onClick={(e) => openTaskPanel(e, id)}>
                  <TableArrow />
                </button>
              </StyledTd>
            </StyledTr>
          );
        })}
      </Tbody>
    </Table>
  );
};

CaseResolutionTaskTable.propTypes = {
  caseTasks: PropTypes.arrayOf(PropTypes.object),
  handleTaskSelect: PropTypes.func,
  selectedRowId: PropTypes.string,
  openTaskPanel: PropTypes.func,
};

CaseResolutionTaskTable.defaultProps = {
  handleTaskSelect: () => null,
  openTaskPanel: () => null,
};
