export const globalColors = {
  geBlue: '#005FB8',
  green0: '#00FF00',
  green1: '#788442',
  green2: '#A3AE6E',
  green3: '#7ED6A7',
  green4: '#C4F0DA', // adding updated color for completed tasks
  orange1: '#D98543',
  orange2: '#FF9E51',
  red1: '#B90000',
  red2: '#EF5353',
  red3: '#F88B8B',
  red4: '#FF2600',
  pine1: '#13452C',
  pine2: '#207D4E',
  pine3: '#2CB470',
  pine4: '#6CD9A3',
  pine5: '#91E3BA',
  mint0: '#263940',
  mint1: '#134541',
  mint2: '#207D75',
  mint3: '#2CB4A8',
  mint4: '#6CD9D0',
  mint5: '#91E3DC',
  mint6: '#DEEBEE',
  blue1: '#10384F',
  blue2: '#19587D',
  blue3: '#3287B9',
  blue4: '#61B7E8',
  blue5: '#99DAFF',
  blue6: '#8ab9e5',
  lavender1: '#23316D',
  lavender2: '#2E4293',
  lavender3: '#536DD5',
  lavender4: '#778EE9',
  lavender5: '#AEBEFF',
  violet1: '#3A1C5D',
  violet2: '#582F88',
  violet3: '#7844B3',
  violet4: '#A66BE9',
  violet5: '#CFA6FF',
  violet6: '#e0d6f7',
  magenta1: '#5A1845',
  magenta2: '#882F6B',
  magenta3: '#B34490',
  magenta4: '#E96BC1',
  magenta5: '#FFA6E3',
  terracotta1: '#542117',
  terracotta2: '#7D2F20',
  terracotta3: '#BA513C',
  terracotta4: '#E9745D',
  terracotta5: '#FFAA99',
  stone1: '#545250',
  stone2: '#73706D',
  stone3: '#B3AEAA',
  stone4: '#D9D3CE',
  stone5: '#E6E0DA',
  straw1: '#544017',
  straw2: '#7D5E20',
  straw3: '#BA903C',
  straw4: '#E9BA5D',
  straw5: '#FFDD99',
  straw6: '#E02020',
  slate0: '#20272D',
  slate1: '#1C252D',
  slate12: '#252C33',
  slate15: '#2E3740',
  slate16: '#232A30',
  slate2: '#38434E',
  slate25: '#445A6C',
  slate3: '#5E6D7B',
  slate4: '#8694A1',
  slate5: '#C5D2DE',
  slate6: '#B9C5D1',
  slate7: '#424242',
  slate8: '#2e3841',
  slate9: '#283038',
  teal: '#0A9CCD',
  teal0: '#E6F5F7',
  teal1: '#1E6978',
  teal2: '#47ADC1',
  teal3: '#85CDDB',
  teal4: '#20393D',
  teal5: '#C7E9EF',
  teal6: '#597D86',
  teal7: '#1A5966',
  teal8: '#006a7a',
  teal9: '#c6e6ec',
  teal10: '#2F899C',
  teal11: '#274b56',
  teal12: '#237383',
  yellow: '#FFC63E',
  grey: '#161617',
  grey0: '#1F1F1F',
  grey1: '#262626',
  grey2: '#4D4D4D',
  grey3: '#656566',
  grey4: '#999999',
  grey5: '#D9D9D9',
  grey6: '#E6E6E6',
  grey7: '#F2F2F2',
  grey8: '#FAFAFA',
  grey9: '#2A323B',
  grey10: '#E4EBF1',
  grey11: '#C4CED6',
  grey12: '#9B9B9B',
  grey13: '#BABABA',
  white: '#FFFFFF',
  black: '#000000',
  grid0: '#202021',
  grid1: '#232324',
  lightblue: '#B6E1E9',
  shadow: '#768F95',
  line85: '#383838',
  darkgray: '#313131',
};

export const globalGradients = {
  tealgradient1: 'linear-gradient(0deg, #0A9CCD 0%, #10BFD9 100%)',
  tealgradient2: 'linear-gradient(180deg, #ffffff 0%, #BEF4FF 100%)',
  tealgradient3: 'linear-gradient(90deg, #0A9CCD 0%, #0EB2D5 100%)',
  tealgradient4: `linear-gradient(180deg, #287b8c 0%, ${globalColors.teal1} 100%)`,
  tealGradiend5: `linear-gradient(180deg, #325A68 0%, #2C4C51 100%);`,
};

export const StatusColor = {
  DANGER: globalColors.red2,
  AMBER: globalColors.orange1,
  WARNING: '#EABB55',
  DEFAULT: '#333333',
};

export const StateColor = {
  ONLINE: globalColors.pine1,
  IMPACTED: globalColors.violet4,
  AVAILABLE: globalColors.blue3,
  TRIPPED: globalColors.red2,
  STOPPED: globalColors.straw3,
  NO_COMM: globalColors.stone2,
  NO_DATA: globalColors.stone2,
  MAINTENANCE: globalColors.magenta1,
  REPAIR: globalColors.magenta2,
};

export const RoleColor = {
  GLOBAL: '#2F89CC',
  TENANT: globalColors.violet3,
  QTY: globalColors.slate2,
};
