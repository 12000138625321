import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@ge/components/select';
import { TabFlowModelVariables } from '@ge/models/constants';

const ViewModeDropdownComponent = ({ dropdownItems }) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });

  const { viewByServiceGroupsOrSites, activeTab, role } = useStoreState(
    (state) => state.tabflow.personAssets,
  );
  const { changeState } = useStoreActions((actions) => actions.tabflow);

  const isDisabled =
    activeTab === 0 &&
    (role === TabFlowModelVariables.ROLESTYPE.WORKER ||
      role === TabFlowModelVariables.ROLESTYPE.USER_AND_WORKER);
  return (
    <div>
      <div>
        <div className="person-assets-sites-filter-content">
          <div className="person-assets-align-self">
            <Select
              disabled={isDisabled}
              label={t('assets.view_by', 'View by')}
              maxWidth={175}
              minWidth={175}
              onChange={(option) =>
                changeState({
                  name: TabFlowModelVariables.VIEWBYSERVICEGROUPORSITES,
                  value: option.value,
                })
              }
              options={dropdownItems}
              value={dropdownItems.filter((option) => option.value === viewByServiceGroupsOrSites)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ViewModeDropdownComponent.propTypes = {
  dropdownItems: PropTypes.array,
};
export default ViewModeDropdownComponent;
