// import { TaskStatus } from '@ge/models';
import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

/**
 * Fetch all workers
 */
export const fetchAllWorkers = ({
  serviceGroupIds,
  types,
  sortKey,
  orderDirection,
  pageSize,
  pageIdx,
  start,
  end,
}) => {
  const params = {
    serviceGroupIds,
    types,
    sortKey,
    orderDirection,
    pageIdx,
    pageSize,
    ...(start && { start }),
    ...(end && { end }),
  };
  return request.post('/cmn/workers', params, { baseURL: BASE_URL });
};

/**
 * Create a crew
 */
export const createCrew = (crew) => request.post('/mwp/crews', crew, { baseURL: BASE_URL });

/**
 * Delete a crew
 */
export const deleteCrew = (id) => request.deleteItem(`/mwp/crews/${id}`, { baseURL: BASE_URL });

/**
 * Edit a crew
 */
export const editCrew = (id, crew) => request.put(`/mwp/crews/${id}`, crew, { baseURL: BASE_URL });

/**
 * Fetch all SG sites
 */
export const fetchAllCrews = ({ serviceGroupIds, start, end } = {}) => {
  let params = { serviceGroup: serviceGroupIds, start, end };
  return request.get('/mwp/crews', { params, baseURL: BASE_URL });
};

/**
 * Fetch all the crews for specified SG sites
 */
export const fetchCrews = ({ serviceGroupIds, startDate, endDate } = {}) => {
  let params = {
    serviceGroup: serviceGroupIds,
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
  };

  return request.get('/mwp/crews', { params, baseURL: BASE_URL });
};

/**
 * Delete all the crews for specified Service group
 **/

export const deleteAllCrews = ({ serviceGroup }) => {
  let params = { serviceGroup: serviceGroup };
  return request.deleteItem('/mwp/crews', { params, baseURL: BASE_URL });
};
