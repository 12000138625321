import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SourceTypes } from '@ge/models/constants';
import { uniqueVals } from '@ge/util/array-utils';

import { FilterMultiSelect } from './filter-multi-select';

const SelectWrapper = styled.div`
  margin-bottom: 10px;
  max-width: 188px;

  .select__menu {
    position: relative;
    width: 186px;
  }
`;

const SOURCE_TYPES = Object.values(SourceTypes);

export const FilterProgressiveMultiSelect = ({
  values,
  filterValues,
  filterTypeDefs,
  onChange,
}) => {
  const { t } = useTranslation(['general'], { useSuspense: false });

  const fields = useMemo(
    () =>
      filterTypeDefs?.fields?.map((def, i) => {
        const options = uniqueVals(filterValues.map((item) => item[i])).sort();
        return {
          ...def,
          name: SOURCE_TYPES[i],
          options: options.map((value) => ({
            value,
            label: value
              ? t(`filter_menu.${def.key}.${value}`, value)
              : t(`filter_menu.not_defined`, 'Not Defined'),
          })),
        };
      }) ?? [],
    [filterValues, filterTypeDefs, t],
  );

  const handleChange = useCallback(
    (selected, name) => {
      const [primary = [], secondary = []] = values ?? [];
      if (name === SourceTypes.PRIMARY) {
        onChange([selected]);
      } else if (name === SourceTypes.SECONDARY) {
        onChange([primary, selected]);
      } else if (name === SourceTypes.TERTIARY) {
        onChange([primary, secondary, selected]);
      }
    },
    [onChange, values],
  );

  return (
    <div>
      {fields.map(({ a11yKey, a11yDefault, name, options }, i) => (
        <SelectWrapper key={name}>
          <FilterMultiSelect
            name={name}
            label={t(a11yKey, a11yDefault)}
            selected={values?.[i] ?? []}
            disabled={!options?.length || (i > 0 && !values?.[i - 1]?.length)}
            options={options}
            onChange={(val) => handleChange(val, name)}
          />
        </SelectWrapper>
      ))}
    </div>
  );
};

FilterProgressiveMultiSelect.propTypes = {
  values: PropTypes.instanceOf(Object),
  filterValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  filterTypeDefs: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
};

FilterProgressiveMultiSelect.defaultProps = {
  filterValues: [],
  filterTypeDefs: { fields: [] },
};
