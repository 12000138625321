import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { useAuth } from '@ge/shared/data-hooks';

export const useFeatureSites = ({ capabilities }) => {
  const getFeatureSites = useStoreState((state) => state.sites.getFeatureSites);
  const { isLoading, assetsBySiteId } = useStoreState((state) => state.assets);

  const { isAuthorized } = useAuth();

  const sites = useMemo(() => {
    if (isLoading || !assetsBySiteId) return [];

    let _sites = getFeatureSites();

    if (capabilities?.length) {
      _sites = _sites.filter(({ id }) => isAuthorized({ capabilities, siteIds: [id] }));
    }

    _sites.forEach((s) => {
      s.assets =
        (assetsBySiteId[s.id] &&
          assetsBySiteId[s.id].sort((a, b) =>
            a.name?.localeCompare(b.name, undefined, { numeric: true }),
          )) ||
        [];
    });
    return _sites;
  }, [assetsBySiteId, capabilities, getFeatureSites, isAuthorized, isLoading]);

  return { isLoading, sites };
};
