import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Radio } from '@ge/components/radio/radio';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Severity } from '@ge/components/severity';
import { Tabs, Tab } from '@ge/components/tabs';
import { DateTimeFormats, Placeholders } from '@ge/models/constants';

import { useCaseLinkContext } from './case-link-provider';

const TabsContainer = styled.div`
  background-color: ${(props) => props.theme.caseLinkDialog.tabBackground};
  border-radius: 5px;
  margin-bottom: 16px;
`;

const CaseListItemIcon = styled(Icon).attrs((props) => ({
  color: props.theme.caseLinkDialog.iconColor,
  size: 13,
}))`
  margin-right: 5px;
`;

const CaseListItemSeverity = styled(Severity).attrs(() => ({
  size: 9,
}))`
  margin-top: 3px;
`;

const CaseListLoaderContainer = styled.div`
  height: 165px;
  position: relative;
`;

const CaseListContainer = styled(ScrollingContainer)`
  max-height: 165px;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.45px;
  color: ${(props) => props.theme.caseLinkDialog.textColor};
`;

const CaseListItem = styled.div`
  display: flex;
  padding: 8px 8px 7px;
  border-bottom: 1px solid ${(props) => props.theme.caseLinkDialog.separatorColor};
  &:last-child {
    border: none;
  }
  > div {
    margin: 0;
  }
`;

const CaseListItemCell = styled.div`
  padding: 0 7px;
  &.text-align-end {
    text-align: end;
  }
  &.full-width {
    flex-grow: 1;
  }
`;

const CaseListItemTitle = styled.div`
  color: ${(props) => props.theme.caseLinkDialog.titleColor};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const CaseListItemStatus = styled.div`
  font-weight: 500;
`;

const CaseListItemDate = styled.div`
  padding-top: 5px;
  white-space: nowrap;
`;

export const CaseLinkParentSection = React.memo(() => {
  const {
    selectedParentCaseId,
    handleParentCaseChange: onChange,
    cases,
    isCasesLoading,
    entity,
  } = useCaseLinkContext();

  const handleSelection = useCallback(
    ({ target }) => {
      onChange(target.value);
    },
    [onChange],
  );

  const toSiteTimezone = useCallback(
    (value) =>
      dayjs(value)
        .tz(entity?.site?.timezone)
        .format(DateTimeFormats.ISSUE_HEADER_DATE),
    [entity?.site?.timezone],
  );

  return isCasesLoading ? (
    <CaseListLoaderContainer>
      <Loader />
    </CaseListLoaderContainer>
  ) : (
    <TabsContainer>
      <Tabs>
        <Tab label="Parent Cases">
          <CaseListContainer relative={true}>
            {cases?.map((cellValue) => (
              <CaseListItem key={cellValue.id}>
                <Radio
                  label=""
                  id={cellValue.id}
                  name="parent-case"
                  value={cellValue.id}
                  checked={selectedParentCaseId === cellValue.id}
                  onChange={handleSelection}
                />
                <CaseListItemIcon
                  icon={cellValue.childCases?.length ? Icons.CASE : Icons.ANOMALY}
                  color={'transparent'}
                />
                <CaseListItemSeverity level={cellValue.priority} />
                <CaseListItemCell className="full-width">
                  <CaseListItemTitle>{cellValue.description}</CaseListItemTitle>
                  <span>{cellValue.source}</span>
                </CaseListItemCell>
                <CaseListItemCell className="text-align-end">
                  <CaseListItemStatus>{cellValue.status}</CaseListItemStatus>
                  <CaseListItemDate>
                    {cellValue.start ? toSiteTimezone(cellValue.start) : Placeholders.DOUBLE_DASH}
                  </CaseListItemDate>
                </CaseListItemCell>
              </CaseListItem>
            ))}
          </CaseListContainer>
        </Tab>
      </Tabs>
    </TabsContainer>
  );
});

CaseLinkParentSection.displayName = 'CaseLinkParentSection';
