/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
// import merge from 'deepmerge';
import { action, computed, thunk } from 'easy-peasy';

import { getUsers } from '../services/profile';

// Define initial state
const defaultProfileState = {
  cloudStorageKeys: {},
  users: [],
  user: {},
};

// Actions
const profileActions = {
  fetchUsers: thunk(async (actions) => {
    const options = {};
    const { results } = await getUsers(options);
    actions.setUsers(results.data);
  }),

  setUsers: action((state, payload) => {
    state.users = payload.reduce((s, c) => {
      const { id } = c;
      return { ...s, [id]: c.attributes };
    }, {});
  }),
};

// Listeners
const profileListeners = {};

// Computed values
const profileComputed = {
  getUsersAsList: computed((state) => () => Object.values(state.users)),
  getUserById: computed((state) => (userId) => state.users[userId]),
  getUserBySSO: computed((state) => (sso) => {
    for (const id in state.users) {
      if (String(state.users[id].sso) === String(sso)) {
        return { id: +id, user: state.users[id] };
      }
    }
    return null;
  }),
};

const profileModel = {
  ...defaultProfileState,
  ...profileActions,
  ...profileListeners,
  ...profileComputed,
};

export default profileModel;
