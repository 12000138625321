import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
import { EntityType } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';

import { ChevronIcon as BaseChevronIcon } from './icons/chevron-icon';
import { NavIcon as BaseNavIcon } from './icons/nav-icon';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledBreadCrumb = styled.button`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.analyze.header.kpiIconColor};
  font-size: 12px;
  font-weight: 300;
  padding-left: 0;
  padding-right: 5px;

  & > svg {
    margin-left: 0;
  }

  svg:last-of-type {
    margin-left: 2px;
  }

  & > * {
    margin-left: 5px;
    margin-right: 5px;
  }

  span {
    margin-right: 0;
  }

  .shortTxt {
    width: auto;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const NavIcon = styled(BaseNavIcon)`
  margin-right: 0;
`;

export const ChevronIcon = styled(BaseChevronIcon)`
  margin-top: 1px;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 10px;
  height: 1em;
`;

const BreadCrumb = ({ name, separator, showChevron, color, icon, onClick, displayShortTxt }) => (
  <StyledBreadCrumb onClick={onClick}>
    <NavIcon color={color} icon={icon} />
    <span className={`${displayShortTxt ? 'shortTxt' : ''}`}>{name}</span>
    {showChevron && <ChevronIcon color={globalColors.white} rotate={-90} />}
    <span>{separator}</span>
  </StyledBreadCrumb>
);

BreadCrumb.propTypes = {
  name: PropTypes.any,
  showChevron: PropTypes.any,
  separator: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  displayShortTxt: PropTypes.bool,
};

const Breadcrumbs = ({
  region,
  site,
  asset,
  title,
  event,
  showChevron,
  separator = '/',
  onBreadCrumbClick,
}) => {
  return (
    <Container>
      {region && (
        <BreadCrumb
          name={region.name}
          showChevron={showChevron}
          separator={separator}
          color={globalColors.slate3}
          icon={Icons.FLEET}
          onClick={(e) => onBreadCrumbClick(e, region.id, EntityType.Region, true)}
          displayShortTxt={false}
        />
      )}
      {site && (
        <BreadCrumb
          name={site.name}
          showChevron={showChevron}
          separator={separator}
          color={globalColors.grey7}
          icon={Icons.SITE}
          onClick={(e) => onBreadCrumbClick(e, site.id, EntityType.SITE, true)}
          displayShortTxt={true}
        />
      )}
      {asset && (
        <BreadCrumb
          name={asset.name}
          showChevron={showChevron}
          separator={event ? separator : ''}
          color={globalColors.grey7}
          icon={Icons.TURBINE}
          onClick={(e) => onBreadCrumbClick(e, asset.id, EntityType.TURBINE, true)}
          displayShortTxt={true}
        />
      )}
      {title && (
        <BreadCrumb
          name={title.name}
          showChevron={showChevron}
          separator={event ? separator : ''}
          color={globalColors.grey7}
          icon={Icons.TASK}
          onClick={(e) => onBreadCrumbClick(e, title.id, EntityType.TASK, true)}
          displayShortTxt={true}
        />
      )}
      {event && (
        <BreadCrumb name={event.name} showChevron={showChevron} color={globalColors.grey7} />
      )}
    </Container>
  );
};

const entity = {
  name: PropTypes.string,
  id: PropTypes.string,
};

Breadcrumbs.propTypes = {
  region: PropTypes.shape(entity),
  site: PropTypes.shape(entity),
  asset: PropTypes.shape(entity),
  title: PropTypes.shape(entity),
  event: PropTypes.shape(entity),
  showChevron: PropTypes.bool,
  separator: PropTypes.string,
  onBreadCrumbClick: PropTypes.func,
};

export default Breadcrumbs;
