import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { typography } from '@ge/tokens';

export const Container = styled.div`
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  font-family: ${typography.family.default};
  input {
    width: 75px;
    height: 22px;
    border: 1px solid #2a323b;
    color: #5e6d7b;
    border-radius: 2px;
    background: #1c252d;
    font-size: 11px;
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  }
  ul {
    background: #1c252d;
    font-size: 11px;
    color: #5e6d7b;
    &::-webkit-scrollbar {
      width: 2px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      width: 2px;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.table.fixedBorderColor};
    }
  }
`;

export const EventCategory = styled.div`
  display: flex;
  align-items: baseline;
  text-transform: uppercase;
  font-family: ${typography.family.default};
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 500;
`;

export const EventCategoryName = styled.span`
  color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.categoryColor};
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 500;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const EventCategoryColor = styled.span`
  display: block;
  height: 8px;
  width: 8px;
  margin: 1px 5px 16px 0px;
  border-radius: 2px;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.analyze.manualAdjustment.eventDialog.newEventIconColor};
`;

export const DialogGrid2 = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns ?? '80px 90px 80px'};
  padding-bottom: 12px;
  border-bottom: ${(props) =>
    props.addDialog
      ? ''
      : `1px solid ${props.theme.analyze.manualAdjustment.eventDialog.bordersColor}`};
  margin-bottom: ${(props) => (props.addDialog ? '' : '12px')};
  > div:not(:first-child) {
    margin-left: 5px;
  }
`;

export const DialogLabel = styled.label`
  font-size: 10px;
  color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.labelColor};
`;

export const DialogValue = styled.p`
  margin: 1px 0px 0px;
  color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.valueColor};
  font-size: 11px;
`;

export const StyledSelect = styled.div`
  margin-bottom: 10px;
  max-height: 150px;
  .select__placeholder {
    margin-top: 2px;
  }
  div {
    position: relative;
    align-items: inherit;
    max-height: 240px;
    letter-spacing: 0.5px;
    font-family: ${typography.family.default};
    font-weight: 200;
    font-size: 11px;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      width: 2px;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.table.fixedBorderColor};
    }
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CTAButtons = styled.div`
  width: -webkit-fill-available;
  display: flex;
`;

export const CTAAddButtons = styled.div`
  margin-left: -5px;
  display: grid;
  grid-template-columns: 108px 80px 82px;
`;

export const StyledButton = styled(Button)`
  margin-left: 5px;
  width: -webkit-fill-available;
`;

export const IconButton = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  align-items: center;
  width: inherit;
`;

export const IconButtonContent = styled.span`
  color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.editButtonColor};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const IconButtonIcon = styled(Icon).attrs((props) => ({
  size: props.size ?? 10,
  icon: props.icon,
  color: props.theme.analyze.manualAdjustment.eventDialog.editButtonColor,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

export const TimeIcon = styled(Icon).attrs((props) => ({
  size: 14,
  viewbox: '0 0 512 512',
  icon: Icons.TIME_ICON,
  color: props.theme.analyze.manualAdjustment.eventDialog.editButtonColor,
}))`
  flex-shrink: 0;
  margin: 0px 16px 0px -26px;
  opacity: 0.5;
`;

export const Spacer = styled.div`
  height: 12px;
  width: 269px;
  opacity: 0.2;
`;

export const Devider = styled.div`
  opacity: 0.2;
  box-sizing: border-box;
  height: 2px;
  width: 285px;
  border: 1px solid #1c252d;
  margin-left: -10px;
`;
