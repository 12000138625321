import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Config } from '@ge/shared/data-hooks';

/**
 * Use kpi site historical data.
 *
 * @param {object} payload payload for Service
 * @param {function} serviceFunc - Its a Service Function
 * @param {string} queryKey - Unique key
 */
export const useGetData = (payload, serviceFunc, queryKey = '') => {
  const { data, error, isLoading } = useQuery(
    [queryKey ? queryKey : serviceFunc.name, payload],
    () => serviceFunc(payload),
    {
      enabled: Boolean(Object.keys(payload).length > 0),
      ...Config.EXECUTE_ONCE,
    },
  );

  return useMemo(
    () => ({
      data: data,
      error,
      isLoading,
    }),
    [data, error, isLoading],
  );
};
