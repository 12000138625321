import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';

import { ParetoChartContext } from './pareto-chart-context';

export const ParetoChartProvider = ({ children }) => {
  const [barIndex, setBarIndex] = useState(null);

  const providerValue = useMemo(() => ({ barIndex, setBarIndex }), [barIndex, setBarIndex]);

  return (
    <ParetoChartContext.Provider value={providerValue}>{children}</ParetoChartContext.Provider>
  );
};

ParetoChartProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

ParetoChartProvider.defaultProps = {
  children: null,
};
