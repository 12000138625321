import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';

import { SiteTypeTabs } from '@ge/models/constants';

import { SitesContext } from './sitesContext';

export const SitesProvider = ({ children }) => {
  const [queryParam, setQueryParam] = useQueryParams({
    type: withDefault(StringParam, SiteTypeTabs.ALL),
  });

  const setType = (type) => {
    setQueryParam({ type });
  };

  const setKpiDateRange = (kpiDateRange) => {
    setSitesState((prevState) => ({
      ...prevState,
      kpiDateRange,
    }));
  };

  const defaultState = {
    kpiDateRange: {
      startDate: {},
      endDate: {},
    },
    setType,
    setKpiDateRange,
  };

  const [sitesState, setSitesState] = useState(defaultState);

  const providerValue = useMemo(
    () => ({ sitesState, setSitesState, queryParam }),
    [sitesState, setSitesState, queryParam],
  );

  return <SitesContext.Provider value={providerValue}>{children}</SitesContext.Provider>;
};

SitesProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

SitesProvider.defaultProps = {
  children: null,
};
