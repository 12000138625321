import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeFormats, DefaultTime } from '@ge/models/constants';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '../../../task-template-shared';

export const ViewSchedule = ({ task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { crewsSg } = useStoreState((state) => state.workers);

  const scheduleDate = dayjs(task?.scheduleDateTz).format(DateTimeFormats.ENDPOINT_PARAM);
  const updatedCrewList = crewsSg
    ?.map(
      (crew) =>
        scheduleDate &&
        dayjs(scheduleDate).isBetween(
          dayjs(crew.crewStartDate),
          dayjs(crew.crewEndDate),
          null,
          '[]',
        ) && {
          value: crew?._id,
          label: crew?.name,
        },
    )
    .filter((crew) => crew);

  const getCrewName = task?.crewIds
    ? updatedCrewList?.map((crew) => crew?.value === task?.crewIds[0] && crew?.label)
    : '';

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.schedule', 'Schedule')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.schedule_date', 'Schedule Date')}</DisplayLabel>
            <DisplayField>
              {task.scheduleDateTz
                ? task.scheduleDateTz.format(DateTimeFormats.DEFAULT_DATE)
                : t('form.unscheduled', 'Unscheduled')}
            </DisplayField>
          </div>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.start_time', 'Start Time')}</DisplayLabel>
            <DisplayField>
              {task?.startTime ? task.startTime : DefaultTime.TASK_DEFAULT_START_TIME}
            </DisplayField>
          </div>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.assignee', 'Assignee')}</DisplayLabel>
            <DisplayField>{task?.crewIds ? getCrewName : ''}</DisplayField>
          </div>
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

ViewSchedule.propTypes = {
  task: PropTypes.object.isRequired,
};
