import { useCallback, useState, useMemo } from 'react';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { KpiCategoriesHeader, KpiCategoryDefs } from '@ge/models/constants';
import { AlertsEntityType } from '@ge/models/constants';
import { InputEntityType } from '@ge/serverless-models/src/rendigital/enums';

import { useGlobalFilters } from '../hooks';
import { RegionKpiChartDefs } from '../models';
import { getKpiCategories, getKpiChartDef } from '../util';

import useAllSitesKpiData from './use-all-sites-kpi-data';

const useAllSitesKpi = ({
  dateRange,
  timeAggr,
  enabled = true,
  entityAggr = AlertsEntityType.SITE,
}) => {
  const [graph1Category, setGraph1Category] = useState([KpiCategoryDefs.TBA]);
  const { siteIds } = useGlobalFilters();

  const hasSitesSelected = !!siteIds?.length;
  const regionOverviewCategories = useMemo(
    () =>
      Array.from(
        new Set([...KpiCategoriesHeader, ...graph1Category, KpiCategoryDefs.AVAILABILITY_CONTRACT]),
      ),
    [graph1Category],
  );

  const [kpiChartState, setKpiChartState] = useState({
    categories: [...regionOverviewCategories],
    def: getKpiChartDef(RegionKpiChartDefs),
  });

  const [kpiSortDirection, setKpiSortDirection] = useState(SortDirection.ASC);

  const handleSortChange = useCallback(
    (sortValue) => {
      setKpiSortDirection(sortValue);
    },
    [setKpiSortDirection],
  );

  const {
    def: { graph1 },
    categories,
  } = kpiChartState;

  const { sortEntitiesBy } = graph1 || {};

  const handleFilterBarChange = useCallback(({ ...filterBar }) => {
    const def = getKpiChartDef(RegionKpiChartDefs, filterBar);
    // Get list of distinct categories to fetch
    const categories = getKpiCategories(def);

    setKpiChartState({ ...filterBar, categories, def });
    setGraph1Category(categories);
  }, []);

  const queryData = useAllSitesKpiData({
    categories: regionOverviewCategories,
    def: categories,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    sortMetric: sortEntitiesBy,
    sortDirection: kpiSortDirection,
    entityType: InputEntityType.FLEET,
    seriesEntityAggr: InputEntityType.SITE,
    dateEntityAggr: InputEntityType.FLEET,
    enabled: hasSitesSelected && enabled,
    timeAggr: timeAggr,
    ...(entityAggr && { entityAggr }),
  });

  const graph1QueryState = useMemo(() => {
    if (!queryData?.kpiQueryState) return { isLoading: true };
    const category = graph1.default?.categories?.[0]?.key;
    return queryData.kpiQueryState[category] ?? {};
  }, [graph1, queryData.kpiQueryState]);

  return {
    ...queryData,
    data: queryData?.data ?? [],
    categories: regionOverviewCategories,
    graph1,
    graph1QueryState,
    sortEntitiesBy,
    kpiChartState,
    setKpiChartState,
    handleSortChange,
    handleFilterBarChange,
  };
};

export default useAllSitesKpi;
