import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { DataLoaderType } from '@ge/models/constants';
import { FormMode } from '@ge/models/constants';
import { FileType } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';
import { getTaskTemplate, postTaskList } from '@ge/web-client/src/app/services/task-import';

import { AttachmentField } from '../meta-fields';

const FormData = require('form-data');

const AllowedFileTypes = [FileType.XLSX];

const AttachmentContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const LoaderContainer = styled.div`
  padding: 40px;
`;

const Instructions = styled.p`
  font-size: 14px;
`;

const ExportIcon = styled(Icon).attrs(() => ({
  size: 14,
  color: globalColors.slate4,
  viewbox: '0 0 12 12',
}))``;

const StyledExportButton = styled.button`
  position: relative;
  padding: 0;
  display: ${(props) => props.downloadTemplateLoading && 'none'};

  .download-label {
    position: relative;
    top: 2px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.createReport.importTextDownload};
    margin-left: 4px;
  }
`;

const FooterDivider = styled.div`
  border-top: 1px solid black;
  margin: 20px -26px 0px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  margin-top: 15px;
  button:not(:last-of-type) {
    margin-right: 25px;
  }
`;

const ImportTaskDialog = ({
  isOpen,
  onClose,
  onSetTaskUploadInProgress,
  onSetTaskUploadError,
  onSetTaskUpload500Error,
}) => {
  const [uploadTemplateMode] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [isDownloadTemplateLoading, setIsDownloadTemplateLoading] = useState(false);

  const { currentView } = useStoreState((state) => state.view);

  const siteIds = currentView.sites.map((site) => site.id).join(',');

  const { t } = useTranslation(['general', 'tasks']);

  const handleCloseDialog = () => {
    setAttachments([]);
    onClose();
  };

  const handleUpload = useCallback(
    (selectedFiles) => {
      setAttachments(selectedFiles);
    },
    [setAttachments],
  );

  const handleDownloadTemplate = async () => {
    setIsDownloadTemplateLoading(true);
    const response = await getTaskTemplate(siteIds);
    let link = document.createElement('a');
    link.download = 'TaskTemplate.xlsx';
    link.href = response;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloadTemplateLoading(false);
  };

  const handleUploadTemplate = async () => {
    if (attachments.length === 1) {
      const formData = new FormData();
      formData.append('files', attachments[0].file, attachments[0].file.name);
      try {
        // uncomment next line to throw error for qa and demo purposes
        // throw new Error('an error');
        await postTaskList(formData).then((result) => {
          if (result?.message?.includes('500')) {
            onSetTaskUpload500Error(true);
            onSetTaskUploadInProgress(false);
          } else {
            onSetTaskUploadInProgress(true);
          }
        });
        onClose();
      } catch (error) {
        onSetTaskUploadError(true);
        onClose();
        console.error(error);
      }
      setAttachments([]);
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleCloseDialog}
        header={t('tasks:import_tasks', 'Import Tasks')}
      >
        <Instructions>
          {t(
            'tasks:download_template_instructions',
            'Download template to import tasks, then upload below',
          )}
          :
        </Instructions>
        <StyledExportButton
          type="button"
          onClick={handleDownloadTemplate}
          downloadTemplateLoading={isDownloadTemplateLoading}
        >
          <ExportIcon icon={Icons.EXPORT} /> <span className="download-label">Download</span>
        </StyledExportButton>
        {isDownloadTemplateLoading ? (
          <LoaderContainer>
            <DataLoader type={DataLoaderType.GRID} isLoading={isDownloadTemplateLoading} />
          </LoaderContainer>
        ) : (
          <AttachmentContainer>
            <AttachmentField
              id="excel-attachment"
              name="attachment"
              mode={FormMode.CREATE}
              collapsed={false}
              hideCollapsiblePanel={true}
              handleUpload={handleUpload}
              maxFiles={1}
              maxFileSizeMb={7}
              files={attachments}
              uploadTemplateMode={uploadTemplateMode}
              allowedFileTypes={AllowedFileTypes}
            />
          </AttachmentContainer>
        )}
        <FooterDivider></FooterDivider>
        <FooterContainer>
          <FooterButtons>
            <Button onClick={handleCloseDialog}>{t('general:cancel', 'Cancel')}</Button>
            <Button primary onClick={handleUploadTemplate} disabled={attachments.length !== 1}>
              {t('general:upload', 'Upload')}
            </Button>
          </FooterButtons>
        </FooterContainer>
      </Dialog>
    </>
  );
};

ImportTaskDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetTaskUploadInProgress: PropTypes.func,
  onSetTaskUploadError: PropTypes.func,
  onSetTaskUpload500Error: PropTypes.func,
};

export default ImportTaskDialog;
