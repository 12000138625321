import React from 'react';

import SidebarMetricStatusCard from './sidebar-metric-status-card';
import SidebarSiteStatusCard from './sidebar-site-status-card';

/**
 * Factory for building components for a mixed array of alert and entity types.
 */
const StatusCardFactory = {
  /**
   * Construct the appropriate component for the provided alert.
   */
  build: function StatusCardBuilder(status, index) {
    // Metric statuses should be handled the same regardless of associated entity type
    const hasMetric = {}.hasOwnProperty.call(status, 'metric');
    if (hasMetric) {
      return <SidebarMetricStatusCard key={index} status={status} />;
    }

    // Entity-specific statuses will have entity-specific presentation needs
    const isSite = {}.hasOwnProperty.call(status, 'site');
    if (isSite) {
      return <SidebarSiteStatusCard key={index} status={status} />;
    }

    // In the future, we will handle financial statuses (per region? fleet-level?)
    const isRegion = {}.hasOwnProperty.call(status, 'region');
    if (isRegion) {
      return null;
    }

    return null;
  },
};

export default StatusCardFactory;
