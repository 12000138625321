import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Input } from '@ge/components/input';
import { FLAGGED_STATES } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

const CheckboxWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  font: 300 11px/13px Museo Sans;
  letter-spacing: 0;
  //color: ${(props) => props.theme.distributionListSidePanel.checkbox};
  color: ${globalColors.grey4};
`;

const StyledInput = styled(Input)`
  width: 45px !important;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 10px;
  font: 300 12px/15px Museo Sans;
  color: ${globalColors.grey13};
  width: fit-content;
  span {
    color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
  }
`;

const FlagWrapper = styled.span`
  width: 16px;
  height: 14px;
  padding: 2px 3px;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
`;

export const EMCheckboxes = () => {
  const { t } = useTranslation(['admin.configure']);
  const { control } = useFormContext();

  const handleChange = (onChange, val) => {
    onChange(val);
  };

  const flaggedComponent = (type) => {
    return Object.keys(FLAGGED_STATES).map((key, index) => {
      if (type === 'initial' ? index > 3 : index < 4) {
        return;
      }
      return (
        <Controller
          key={index}
          control={control}
          name={key.toLowerCase()}
          defaultValue={false}
          render={({ onChange, value }) => (
            <StyledCheckbox
              onChange={(val) => handleChange(onChange, val)}
              checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              label={
                <div>
                  <FlagWrapper key={index} style={{ background: FLAGGED_STATES[key].color }}>
                    {FLAGGED_STATES[key].letter}
                  </FlagWrapper>
                  {key}
                </div>
              }
            />
          )}
        />
      );
    });
  };

  return (
    <CheckboxWrapper>
      <div>
        <Controller
          control={control}
          name="maxResetLimit"
          render={({ onChange, onBlur, value }) => (
            <StyledInput
              label={t('DL_form_fields.maxResetLimit', 'Max Reset imit')}
              onChange={onChange}
              onBlur={onBlur}
              type="number"
              maxWidth={'50px'}
              value={value}
              placeholder={'00'}
              min="0"
              max="999"
            />
          )}
        />
      </div>
      <div>
        <label className="title">{t('block', 'Block')}</label>
        <Controller
          control={control}
          name={'blockCommands'}
          defaultValue={false}
          render={({ onChange, value }) => (
            <StyledCheckbox
              onChange={(val) => handleChange(onChange, val)}
              checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              label={'Block Commands'}
            />
          )}
        />
        <Controller
          control={control}
          name={'blockAutomation'}
          defaultValue={false}
          render={({ onChange, value }) => (
            <StyledCheckbox
              onChange={(val) => handleChange(onChange, val)}
              checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              label={'Block Automation'}
            />
          )}
        />
      </div>
      <div>
        <label className="title">{t('states', 'Select States to flag')}</label>
        {flaggedComponent('initial')}
      </div>
      <div style={{ marginTop: '15px' }}>{flaggedComponent('final')}</div>
    </CheckboxWrapper>
  );
};
