import { PropTypes } from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { DataExplorerSignalDialog } from '@ge/feat-analyze/components/asset-overview/data-explorer/data-explorer-signal-dialog';
import {
  NAMESPACE,
  Fields,
  PlotFields,
  SignalFields,
} from '@ge/feat-analyze/models/configure-analysis-template';
import { TimeSignal } from '@ge/models/constants';

import { FormCollapsiblePanel, FormRow } from '../../shared';

import ChartDetails from './analysis-template-chart-details';

const StyledButton = styled.button`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) =>
    props.disabled ? props.theme.button.disabled.textColor : props.theme.button.textColor};
  display: flex;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 0px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.button.iconColor,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

// eslint-disable-next-line react/display-name
const Charts = forwardRef(({ isLoading, isOpen, signalData }, ref) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const [showSignalDialog, setShowSignalDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [charts, setCharts] = useState([{}]);

  const { register, setValue } = useFormContext();

  register(Fields.PLOTS, { required: true });

  const signalAxisIds = useMemo(() => {
    return charts[selectedIndex]
      ? {
          x: charts[selectedIndex].signals?.x ?? TimeSignal,
          y: charts[selectedIndex].signals?.y.filter(Boolean) ?? [],
        }
      : { x: {}, y: [] };
  }, [selectedIndex, charts]);

  const handleAddSignalsClick = useCallback((index) => {
    setShowSignalDialog(true);
    setSelectedIndex(index);
  }, []);

  const handleEditSignalsClick = useCallback((index) => {
    setShowSignalDialog(true);
    setSelectedIndex(index);
  }, []);

  const handleDeleteClick = useCallback((index) => {
    setCharts((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handlePlotTypeChange = useCallback((index, type) => {
    setCharts((prev) =>
      prev.map((chart, i) => {
        if (i === index) {
          chart[PlotFields.PLOT_TYPE] = type;
        }
        return chart;
      }),
    );
  }, []);

  const handleApplySignal = useCallback(
    (signals) => {
      setCharts((prev) =>
        prev.map((chart, i) => {
          if (i === selectedIndex) {
            chart.signals = {
              x: signals.x,
              y: signals.y
                .filter(Boolean)
                .map((signal) => ({ ...signal, axis: `Y${signal.yAxis + 1}` })),
            };
          }
          return chart;
        }),
      );
      setShowSignalDialog(false);
    },
    [selectedIndex],
  );

  useEffect(() => {
    setValue(
      Fields.PLOTS,
      charts.map((chart, index) => {
        const { signals } = chart;
        const signals_y = signals?.y.map((signal) => ({
          [SignalFields.ID]: signal.id,
          [SignalFields.AGGREGATION]: signal.timeAggr,
          [SignalFields.SOURCE]: signal.signalSource,
          [SignalFields.AXIS_Y]: signal.yAxis,
        }));
        const signal_x = {
          [SignalFields.ID]: signals?.x?.id,
          [SignalFields.AGGREGATION]: signals?.x?.timeAggr,
          [SignalFields.SOURCE]: signals?.x?.signalSource,
        };
        return {
          [PlotFields.PLOT_ID]: `${index + 1}`,
          [PlotFields.SIGNALS_Y]: signals_y,
          [PlotFields.SIGNAL_X]: signal_x,
        };
      }),
    );
  }, [charts, setValue]);

  useImperativeHandle(ref, () => ({
    setCharts,
  }));

  if (!ready) return null;

  return (
    <>
      <FormCollapsiblePanel
        isOpen={isOpen}
        title={t('analysis_template.charts', 'Charts')}
        headerContent={
          <StyledButton
            onClick={(e) => {
              e.stopPropagation();
              if (charts?.length < 10) {
                setCharts((prev) => [...prev, {}]);
              }
            }}
            className={'add-button'}
          >
            <StyledIcon icon={Icons.ADD} />
            {t('analysis_template.add_chart_button', 'Add Chart')}
          </StyledButton>
        }
      >
        {charts?.map((chart, index) => (
          <FormRow key={`${Fields.PLOTS}.${index}`}>
            <ChartDetails
              index={index}
              name={`${Fields.PLOTS}.${index}`}
              type={chart.type}
              signals={chart.signals}
              onPlotTypeChange={handlePlotTypeChange}
              onAddSignals={handleAddSignalsClick}
              onEditSignals={handleEditSignalsClick}
              onDeleteClick={handleDeleteClick}
            />
          </FormRow>
        ))}
      </FormCollapsiblePanel>
      {showSignalDialog && (
        <DataExplorerSignalDialog
          isLoading={isLoading}
          onClose={() => setShowSignalDialog(false)}
          onConfirm={handleApplySignal}
          value={signalAxisIds}
          signalData={signalData}
          enableMultiAxis={true}
        />
      )}
    </>
  );
});

Charts.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  signalData: PropTypes.array,
};

Charts.defaultValues = {
  signalData: [],
};

export default Charts;
