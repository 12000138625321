import { SortDirection } from '@ge/components/table/models/sort-direction';
import { Asset } from '@ge/models/entities';

import * as request from './api';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;

export const fetchStorageInverters = async ({
  pageIndex = 0,
  pageSize = 1000,
  sortDirection = SortDirection.ASC,
  sortMetric = 'inverterId',
} = {}) =>
  request.post(
    '/cmn/assets/storage-inverters',
    {
      pageIndex,
      pageSize,
      sortDirection,
      sortMetric,
    },
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => ({
          assets: Array.isArray(data) ? data?.map((asset) => new Asset(asset)) : data,
        }),
      ],
    },
  );

export const fetchStorageInvertersBySiteId = async ({
  pageIndex,
  pageSize,
  siteId,
  sortDirection = SortDirection.ASC,
  sortMetric = 'inverterId',
} = {}) =>
  request.post(
    `/cmn/sites/storage-inverters/${siteId}`,
    { pageIndex, pageSize, sortDirection, sortMetric },
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => ({
          assets: Array.isArray(data) ? data?.map((asset) => new Asset(asset)) : data,
        }),
      ],
    },
  );
