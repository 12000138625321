import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { FrequencyLineChart } from '@ge/components/charts';
import { generateSpectrum } from '@ge/components/charts/frequency-line-chart/mocks';

const SpectrumChart = ({ alignChevronLeft, className, onChevronClick, large, title }) => {
  const data = useRef(generateSpectrum(250));

  return (
    <FrequencyLineChart
      alignChevronLeft={alignChevronLeft}
      className={className}
      data={data.current}
      large={large}
      onChevronClick={onChevronClick}
      title={title}
      xAxisMax={250}
      yInterval={25}
    />
  );
};

SpectrumChart.propTypes = {
  alignChevronLeft: PropTypes.bool,
  className: PropTypes.string,
  onChevronClick: PropTypes.func,
  large: PropTypes.bool,
  title: PropTypes.string,
};

SpectrumChart.defaultProps = {
  alignChevronLeft: false,
  className: '',
  onChevronClick: null,
  large: false,
  title: null,
};

export default SpectrumChart;
