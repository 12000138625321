import { useStoreState } from 'easy-peasy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParam, encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params';

import { hydrateTask, updateTimezoneTaskFields } from '@ge/feat-manage/util/tasks-util';
import useStateRef from '@ge/hooks/state-ref';
import {
  Capability,
  Delimiters,
  EntityType,
  PermissionScope,
  DefaultFilters,
  EntityMode,
  DistributionLists,
  DefaultDirtyEntityFields,
} from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util';

const buildEntityParamObj = (id, type, tab) => {
  const newEntityInfo = { entityId: id, entityType: type };
  if (typeof tab !== 'undefined') {
    newEntityInfo.entityTab = tab;
  }
  return newEntityInfo;
};

const entityArrayParam = {
  encode: (obj) => {
    const array = obj && Object.keys(obj).map((key) => `${key}${Delimiters.KEY_VALUE}${obj[key]}`);
    return encodeDelimitedArray(array, Delimiters.PARAM);
  },

  decode: (arrayStr) => {
    return decodeDelimitedArray(arrayStr, Delimiters.PARAM);
  },
};

const useEntityDetailAuth = () => {
  // store
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const {
    currentView: { sites },
  } = useStoreState((state) => state.view);
  const { tasks } = useStoreState((state) => state.tasks);

  // data hooks
  const { audit } = useAuth();

  return useCallback(
    ({ entityId, entityTab, entityType, taskPagesData }) => {
      if (!entityId) {
        return false;
      }

      let siteId = null;

      switch (entityType) {
        case EntityType.ASSET:
        case EntityType.TURBINE: {
          const asset = getAssetById(entityId);
          siteId = asset?.site?.id;
          const isSiteSelected = sites.find((site) => site?.id === siteId);
          if (!isSiteSelected) {
            return false;
          }
          break;
        }

        case EntityType.SITE:
          siteId = entityId;
          break;

        case EntityType.TASK: {
          const task = taskPagesData[entityId];
          if (task) {
            siteId = task?.site?.id;
            const isSiteSelected = sites.find((site) => site?.id === siteId);
            if (!isSiteSelected) {
              return false;
            }
          } else {
            return true;
          }
          break;
        }

        default:
          // for now just returning true for all other entity types
          // TODO: remove this once we support other entity types
          return true;
      }

      if (!siteId) {
        return false;
      }

      return audit({
        capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
        siteIds: [siteId],
        source: `'${entityType}' entity panel${entityTab ? ` - '${entityTab}' tab` : ''}`,
        type: 'Entity panel blocked',
      });
    },
    [audit, getAssetById, sites, tasks],
  );
};

export const useEntityDetailsState = (popout) => {
  const history = useHistory();
  const [entityParam, setEntityParam] = useQueryParam('entity', entityArrayParam);
  const isAuthorized = useEntityDetailAuth();
  const location = useLocation();

  const [panelShown, setPanelShown] = useState(false);
  const [dirtyFields, setDirtyFields] = useState(false);
  const [entityId, setEntityId, entityIdRef] = useStateRef();
  const [entityType, setEntityType] = useStateRef();
  const [entityTab, setEntityTab] = useStateRef();
  const [entityMode, setEntityMode, entityModeRef] = useStateRef();
  const [crewForBundledTask, setCrewForBundledTask] = useState({
    crewId: null,
    isSecondaryBundle: false,
  });
  const [tasksTrackerData, setTasksTrackerData] = useState(DefaultFilters);
  const [visibleTasks, setVisibleTask] = useState(DefaultFilters);
  const [disableNewTaskSaveBtn, setDisableNewTaskSaveBtn] = useState(false);
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const paramValues = useMemo(() => params.get(DistributionLists.MODE)?.split('__'), [params]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [bulkTask, setBulkTask] = useState([]);
  const [entityFieldsDirtyCheck, setEntityFieldsDirtyCheck] = useState(DefaultDirtyEntityFields);
  const [isWorkStand, setIsWorkStand] = useState(false);
  const [taskPagesData, setTaskPagesData] = useState([]);
  const [taskWorkers, setTaskWorkers] = useState([]);
  const [isAllEventLoading, setIsAllEventLoading] = useState(false);
  const [selectMetaField, setSelectMetaField] = useState(false);

  const { sites } = useStoreState((state) => state.sites);
  const { assets } = useStoreState((state) => state.assets);
  const { technicians } = useStoreState((state) => state.technicians);

  // Selected entity state management
  const entitySearchParams = useMemo(() => {
    const entityEntries = entityParam
      ? new Map(
          entityParam.map((entity) => {
            const entityKeyVal = entity.split(Delimiters.KEY_VALUE);
            return [entityKeyVal[0], entityKeyVal[1]];
          }),
        )
      : [];
    return Object.fromEntries(entityEntries);
  }, [entityParam]);

  const updateHistory = useCallback(
    (obj, initial) => {
      // Handle navigation in popouts differently in order to
      // maintain pathing.
      if (popout) {
        history.push({
          pathname: `/${obj.entityType}/${obj.entityId}`,
          state: {
            initial: false,
          },
        });
        return;
      }

      // Push a new history entry with the "initial" state for
      // tracking whether or not there should be a back chevron
      // on the details panel.
      history.push({
        pathname: history.pathname,
        search: history.location.search,
        state: {
          initial,
        },
      });

      setEntityParam(obj, 'replaceIn');
    },
    [setEntityParam, history, popout],
  );

  const updateModeHistory = useCallback(
    (obj) => {
      if (obj.entityType === DistributionLists.NEW) {
        history.push({
          pathname: history.pathname,
          search: `?mode=${obj.entityType}`,
          state: {
            initial: false,
          },
        });
        return;
      }

      history.push({
        pathname: history.pathname,
        search: `?mode=${obj.entityType}__${obj.entityId}`,
        state: {
          initial: false,
        },
      });
      return;
    },
    [history],
  );

  // Use programmatic access to modify the history and allow the query
  // param watchers to handle hiding and showing.
  const showDetails = useCallback(
    (id, type, tab, mode) => {
      const changed = entityIdRef.current !== id || entityIdRef.tab !== tab;

      if (!changed) {
        return;
      }

      if (entityIdRef.tab !== tab) {
        setEntityTab(tab);
      }

      setEntityMode(mode ?? EntityMode.VIEW);
      // If we have an entity on initial load, set initial router state.
      const initial = id && !entityIdRef.current;
      if (
        type === DistributionLists.VIEW ||
        type === DistributionLists.EDIT ||
        type === DistributionLists.NEW
      ) {
        return updateModeHistory(buildEntityParamObj(id, type, tab), initial);
      }
      updateHistory(buildEntityParamObj(id, type, tab), initial);
    },
    [updateHistory, updateModeHistory, entityIdRef, setEntityTab, setEntityMode],
  );

  const openDLSidePanel = useCallback(
    (id, type) => {
      showDetails(id, type);
    },
    [showDetails],
  );

  const hideDLSidePanel = useCallback(
    (e) => {
      history.push({ pathname: location.pathname });
      setSelectedRow([]);
      killEventPropagation(e);
    },
    [history, location],
  );

  // Reset the shown details panel state and remove form URL.
  const hideDetails = useCallback(() => {
    updateHistory(undefined);
  }, [updateHistory]);

  // Reset the entity state information.
  // NOTE: This is here for cleanup AFTER animations complete.
  const resetDetails = useCallback(() => {
    setEntityId(undefined);
    setEntityType(undefined);
    setEntityTab(undefined);
    setEntityMode(undefined);
  }, [setEntityId, setEntityType, setEntityTab, setEntityMode]);

  // When the search params, update the stored details state.
  useEffect(() => {
    const { entityId, entityType, entityTab } = entitySearchParams;

    // handles initial load to not update history
    if (!entityId) {
      setPanelShown(false);

      return;
    }
    // commented as a defect fix - Remove permission check on launch of the asset and site panels
    // if (!isAuthorized(entitySearchParams)) {
    //   updateHistory(undefined);
    //   setPanelShown(false);

    //   return;
    // }

    const selectedEntityChanged = entityId !== entityIdRef.current;

    // Only trigger the show here if the entity has changed.
    if (entityId && entityType && selectedEntityChanged) {
      setEntityId(entityId);
      setEntityType(entityType);
      setEntityTab(entityTab);
      setPanelShown(true);
    }
  }, [
    entitySearchParams,
    isAuthorized,
    showDetails,
    setEntityId,
    setEntityType,
    setEntityTab,
    entityIdRef,
    updateHistory,
  ]);

  // Show details for the specified wind site.
  const showSiteDetails = (id, tab) => {
    showDetails(id, EntityType.SITE, tab);
  };

  // Show details for the specified storage site.
  const showStorageSiteDetails = (id, tab) => {
    showDetails(id, EntityType.STORAGE_SITE, tab);
  };

  // Show details for the specified solar site.
  const showSolarSiteDetails = (id, tab) => {
    showDetails(id, EntityType.SOLAR_SITE, tab);
  };

  // Show details for the specified hybrid site.
  const showHybridSiteDetails = (id, tab) => {
    showDetails(id, EntityType.HYBRID_SITE, tab);
  };

  // Show details for the specified asset.
  const showAssetDetails = (id, tab) => {
    showDetails(id, EntityType.TURBINE, tab);
  };

  // Show details for the specified storage inverter asset.
  const showStorageAssetDetails = (id, tab) => {
    showDetails(id, EntityType.INVERTER, tab);
  };

  // Show details for the specified solar inverter asset.
  const showSolarAssetDetails = (id, tab) => {
    showDetails(id, EntityType.INVERTER, tab);
  };

  // Show details for the specified hybrid inverter asset.
  const showHybridAssetDetails = (id, tab) => {
    showDetails(id, EntityType.INVERTER, tab);
  };

  // Show details for the specified event.
  const showEventDetails = (id, tab) => {
    showDetails(id, EntityType.EVENT, tab);
  };

  // Show details for the specified case.
  const showCaseDetails = (id, tab, mode) => {
    showDetails(id, EntityType.CASE, tab, mode);
  };

  // Show details for the specified ticket.
  const showTaskDetails = (id, tab) => {
    showDetails(id, EntityType.TASK, tab);
  };

  const showBulkTaskDetails = useCallback(
    (id, tab) => {
      showDetails(id, EntityType.BULK_TASK, tab);
    },
    [showDetails],
  );

  // Show details for the specified task.
  const showTicketDetails = (id, tab) => {
    showDetails(id, EntityType.TICKET, tab);
  };

  // Show details for the specified person.
  const showPersonDetails = (id, tab) => {
    showDetails(id, EntityType.PERSON, tab);
  };

  const showRoleDetails = (id, tab) => {
    showDetails(id, EntityType.ROLE, tab);
  };

  // Show new ticket details panel.
  const showNewTicketDetails = (id, tab) => {
    showDetails(id, EntityType.NEW_TICKET, tab);
  };

  // show details for the specified inspection damage
  const showDamageDetails = (id, tab) => {
    showDetails(id, EntityType.INSPECTION_DAMAGE, tab);
  };

  // Change the anomaly tab based on entityTab.
  const manageCaseTabs = (id, tab) => {
    showDetails(id, EntityType.CASE, tab);
  };

  // Change the anomaly tab based on entityTab.
  const manageSiteTabs = (id, tab, entityType = EntityType.SITE) => {
    showDetails(id, entityType, tab);
  };

  const manageAssetTabs = (id, tab, entityType = EntityType.ASSET) => {
    showDetails(id, entityType, tab);
  };

  const manageTurbineTabs = (id, tab) => {
    showDetails(id, EntityType.TURBINE, tab);
  };

  const managePersonTabs = (id, tab) => {
    showDetails(id, EntityType.PERSON, tab);
  };

  const updateEntityMode = (mode) => {
    if (entityModeRef.current !== mode) {
      setEntityMode(mode);
    }
  };

  // Create Task
  const createTasks = useCallback(
    (payload) => {
      const hydrateCreatedTask = payload.map((task) =>
        hydrateTask(task, { assets, sites, technicians }),
      );
      updateTimezoneTaskFields(hydrateCreatedTask, sites);
      const updatedTasks = hydrateCreatedTask.concat(taskPagesData);
      setTaskPagesData(updatedTasks);
    },
    [assets, sites, taskPagesData, technicians],
  );

  // Update Task
  const updateTasks = useCallback(
    (payload) => {
      const updatedTask = taskPagesData.map((task) => {
        if (task.id !== payload.id) {
          return task;
        } else {
          const hydrateEditedTask = hydrateTask(payload, { assets, sites, technicians });
          updateTimezoneTaskFields([hydrateEditedTask], sites);
          return hydrateEditedTask;
        }
      });
      setTaskPagesData(updatedTask);
    },
    [assets, sites, taskPagesData, technicians],
  );

  // Update Bulk Schedule Task
  const updateBulkScheduleTask = useCallback(
    (payload) => {
      const hydrateBulkScheduledTasks = payload.map((task) =>
        hydrateTask(task, { assets, sites, technicians }),
      );
      updateTimezoneTaskFields(hydrateBulkScheduledTasks, sites);
      const updatedTasks = taskPagesData.map((task) => {
        const updatedTaskResult = hydrateBulkScheduledTasks.find(
          (element) => element.id === task.id,
        );
        if (updatedTaskResult) {
          task = updatedTaskResult;
        }
        return task;
      });
      setTaskPagesData(updatedTasks);
    },
    [assets, sites, taskPagesData, technicians],
  );

  // Delete Task
  const removeTask = useCallback(
    (taskId) => {
      const updatedTasks = taskPagesData.filter((task) => task?.id !== taskId);
      setTaskPagesData(updatedTasks);
    },
    [taskPagesData],
  );

  // Delete Bulk Task
  const removeBulkTasks = useCallback(
    (payload) => {
      const hydrateBulkRemovedTasks = taskPagesData.filter(
        (task) => payload.findIndex((element) => element.id === task.id) === -1,
      );
      setTaskPagesData(hydrateBulkRemovedTasks);
    },
    [taskPagesData, setTaskPagesData],
  );

  return {
    // State
    entityId,
    entityType,
    entityTab,
    entityMode,
    panelShown,
    dirtyFields,
    isWorkStand,
    isAllEventLoading,
    selectMetaField,
    crewForBundledTask,
    tasksTrackerData,
    visibleTasks,
    disableNewTaskSaveBtn,
    entityFieldsDirtyCheck,
    taskPagesData,
    taskWorkers,
    setDirtyFields,
    setPanelShown,
    setCrewForBundledTask,
    setTasksTrackerData,
    setVisibleTask,
    setDisableNewTaskSaveBtn,
    bulkTask,
    setBulkTask,
    setEntityFieldsDirtyCheck,
    setIsWorkStand,
    setTaskPagesData,
    setTaskWorkers,
    setIsAllEventLoading,
    setSelectMetaField,

    // Actions
    hideDetails,
    showDetails,
    resetDetails,
    showCaseDetails,
    showSiteDetails,
    showAssetDetails,
    showEventDetails,
    showTaskDetails,
    showPersonDetails,
    showRoleDetails,
    showTicketDetails,
    showNewTicketDetails,
    showDamageDetails,
    manageCaseTabs,
    manageSiteTabs,
    manageAssetTabs,
    manageTurbineTabs,
    managePersonTabs,
    showStorageSiteDetails,
    showSolarSiteDetails,
    showHybridSiteDetails,
    showStorageAssetDetails,
    showSolarAssetDetails,
    showHybridAssetDetails,
    updateEntityMode,
    openDLSidePanel,
    hideDLSidePanel,
    paramValues,
    selectedRow,
    setSelectedRow,
    search,
    params,
    showBulkTaskDetails,
    createTasks,
    updateTasks,
    updateBulkScheduleTask,
    removeTask,
    removeBulkTasks,
  };
};
