import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import clone from 'ramda/src/clone';
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { ConfirmationDialog } from '@ge/components/modal';
import { Textarea } from '@ge/components/textarea/textarea';
import { DateTimeFormats, Capability, MAX_NOTE_CHAR } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useEditEventNote, useDeleteEventNote } from '@ge/shared/data-hooks/use-event-notes';
import { getPreferredUserName } from '@ge/shared/services/auth';

const ShowMoreButton = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${(props) => props.theme.checkbox.iconColor};
  text-transform: lowercase;
`;

const ShowMoreIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.checkbox.iconColor,
}))`
  margin: 2px;
  font-weight: bold;
`;

const NoteContainer = styled.div`
  &.open {
    display: block;
  }
  &.close {
    display: none;
  }
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  padding: 10px 0px;
`;

const CaseNotesFormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:not(nth-child(3)) {
    display: flex;
    justify-content: flex-end;
  }
  dl {
    padding-right: 30px;
    margin: 0;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    line-height: 2;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
  dl:last-child {
    padding-right: 2px;
  }
  div:first-child {
    display: flex;
    justify-content: flex-start;
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
    padding: 10px 2px 2px 0px;
  }
  &.save-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    border-bottom: none;
    button:first-child {
      margin-right: 10px;
      padding: 4px 14px;
    }
    button {
      font-size: 11px;
    }
  }
  &.invalid {
    textarea {
      border: 1px solid ${(props) => props.theme.input.invalidBorder};
    }
  }
`;

const StyledNote = styled.div`
  white-space: pre-wrap;
  padding: 15px 0px;
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
`;

const StyledTextArea = styled(Textarea)`
  height: 60px;
  font-size: 12px;
  font-weight: inherit;
`;

const EditButton = styled(
  withTheme(({ theme, ...rest }) => (
    <button {...rest}>
      <Icon icon={Icons.PENCIL} size={12} color={theme.navigation.iconColor} />
    </button>
  )),
)`
  font-weight: bold;
  cursor: pointer;
  padding-right: 20px;
`;

const LastUpdated = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  font: 300 11px/15px;
  line-height: 2;
  margin-top: 12px;
`;

const DeleteButton = styled(
  withTheme(({ theme, ...rest }) => (
    <button {...rest}>
      <Icon icon={Icons.TRASH} size={12} color={theme.navigation.iconColor} />
    </button>
  )),
)`
  font-weight: bold;
  cursor: pointer;
`;

export const CaseNoteItem = ({ noteItem, onToggle, active, setInactive }) => {
  const { t } = useTranslation(['entity-details']);
  const [showMore, setShowMore] = useState(false);
  const originalData = clone(noteItem);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isInvalidNote, setIsInvalidNote] = useState(false);
  const noteData = noteItem?.note;
  const preferredUserName = getPreferredUserName();
  const createdBy = noteItem?.updatedBy ?? noteItem?.createdBy;

  const { edit } = useEditEventNote();

  const { deleteNote } = useDeleteEventNote();

  const editNote = useCallback(
    (values, noteId) => {
      values = { ...values, noteId };
      edit({ inputData: values, originalData });
    },
    [edit, originalData],
  );

  const deleteNoteItem = useCallback(
    (values) => {
      setShowDeleteConfirmationDialog(true);
      setNoteToDelete(values);
      setInactive('');
    },
    [setNoteToDelete, setInactive],
  );

  const closeEditMode = useCallback(() => {
    setInactive('');
    setIsInvalidNote(false);
  }, [setInactive]);

  const { handleSubmit, register } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (values) => {
      if (values?.note.trim()) {
        editNote(values, noteItem?.id);
        setInactive('');
      }
    },
    [editNote, noteItem, setInactive],
  );

  const toggleShowMore = useCallback(() => {
    setShowMore((prevState) => !prevState);
  }, [setShowMore]);

  const showMoreLess = () => {
    if (showMore) {
      return (
        <>
          <ShowMoreIcon rotate={-180} /> {t('table.show_less', 'show less')}
        </>
      );
    } else {
      return (
        <>
          <span className="ellipsis">{'... '}</span>
          <ShowMoreIcon /> {t('table.show_more', 'show more')}
        </>
      );
    }
  };

  const handleDeleteConfirm = useCallback(() => {
    if (noteToDelete) {
      const values = { ...noteToDelete };
      deleteNote(values);
      setShowDeleteConfirmationDialog(false);
      setNoteToDelete(null);
    }
  }, [noteToDelete, deleteNote]);

  const handleDeleteCancel = () => {
    setShowDeleteConfirmationDialog(false);
    setNoteToDelete(null);
  };

  const onNoteChange = useCallback(({ currentTarget }) => {
    currentTarget?.value.trim() ? setIsInvalidNote(false) : setIsInvalidNote(true);
  }, []);

  return (
    <>
      <CaseNotesFormRow>
        <div>
          <dl>
            <dt>{t('notes.created_on', 'Created On')}</dt>
            <dd>
              {dayjs(noteItem.creationDate).format(DateTimeFormats.DEFAULT_DATE_TIME_TIMEZONE)}
            </dd>
          </dl>

          <dl>
            <dt>{t('notes.contributed_by', 'Contributed By')}</dt>
            <dd>{noteItem.createdBy}</dd>
          </dl>
        </div>
        <div>
          {createdBy === preferredUserName && (
            <>
              <AuthRender
                capability={Capability.NOTES_AND_ATTACHMENTS}
                edit
                description="Notes and attachments table action menu"
                siteLevel={false}
              >
                <EditButton onClick={() => onToggle()} />
              </AuthRender>
              <AuthRender
                capability={Capability.NOTES_AND_ATTACHMENTS}
                delete
                description="Notes and attachments table action menu"
                siteLevel={false}
              >
                <DeleteButton onClick={() => deleteNoteItem(noteItem)} />
              </AuthRender>
            </>
          )}
        </div>
      </CaseNotesFormRow>

      {active ? (
        <NoteContainer className={active ? 'open' : 'close'}>
          <CaseNotesFormRow className={isInvalidNote ? 'invalid' : ''}>
            <StyledTextArea
              id="notes-description-textarea"
              name="note"
              label={t('notes.note', 'Note')}
              ref={register}
              defaultValue={noteData}
              onChange={onNoteChange}
              required
            />
          </CaseNotesFormRow>
          <CaseNotesFormRow className="save-button">
            <Button onClick={closeEditMode}>{t('general:cancel', 'Cancel')}</Button>
            <Button primary onClick={handleSubmit(onSubmit)}>
              {t('general:save', 'Save')}
            </Button>
          </CaseNotesFormRow>
        </NoteContainer>
      ) : (
        <StyledNote>
          {showMore ? `${noteData} ` : `${noteData.slice(0, MAX_NOTE_CHAR)} `}
          {noteData?.length > MAX_NOTE_CHAR && (
            <ShowMoreButton onClick={toggleShowMore}>{showMoreLess()}</ShowMoreButton>
          )}
          {noteItem?.updateDate > noteItem?.creationDate && (
            <LastUpdated>
              {t('notes.last_updated', 'Last Updated')}{' '}
              {dayjs(noteItem?.updateDate).format(DateTimeFormats.DEFAULT_DATE_TIME_TIMEZONE)}
            </LastUpdated>
          )}
        </StyledNote>
      )}
      <ConfirmationDialog
        contentWidth
        header={t('notes.confirm_delete_header', 'Confirmation')}
        isOpen={showDeleteConfirmationDialog}
        padContent={true}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        confirmLabel={t('notes.confirm_delete', 'Delete')}
        cancelLabel={t('notes.confirm_delete_cancel', 'Cancel')}
      >
        <p>{t('notes.confirm_delete_message', 'Are you sure?')}</p>
      </ConfirmationDialog>
    </>
  );
};

CaseNoteItem.propTypes = {
  noteItem: PropTypes.instanceOf(Object).isRequired,
  entity: PropTypes.instanceOf(Object),
  closeEditMode: PropTypes.func,
  onToggle: PropTypes.func,
  active: PropTypes.bool,
  setInactive: PropTypes.func,
};

CaseNoteItem.defaultProps = {
  noteItem: null,
};
