import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateTaskDetail } from './create-task-detail';
import { EditTaskDetail } from './edit-task-detail';
import { ViewTaskDetail } from './view-task-detail';

export const TaskDetailSection = ({
  taskPanelControls,
  handleTaskType,
  section,
  task,
  templateMode,
  isCalledfromTaskEscalationColumn,
}) => {
  const { metadata } = section || {};
  const { trigger, watch } = useFormContext();
  const watchDueDate = watch('dueDate');
  const watchEligibleStartDate = watch('eligibleStartDate');
  const [startDateFlag, setStartDateFlag] = useState(false);

  useEffect(() => {
    if (startDateFlag && watchDueDate && watchEligibleStartDate) {
      trigger('eligibleStartDate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, watchDueDate, watchEligibleStartDate]);

  useEffect(() => {
    watchDueDate && watchEligibleStartDate && setStartDateFlag(true);
  }, [watchDueDate, watchEligibleStartDate]);

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return (
          <CreateTaskDetail
            handleTaskType={handleTaskType}
            metadata={metadata}
            task={task}
            isCalledfromTaskEscalationColumn={isCalledfromTaskEscalationColumn}
          />
        );
      case TaskTemplateModes.EDIT:
        return (
          <EditTaskDetail
            handleTaskType={handleTaskType}
            metadata={metadata}
            task={task}
            taskDescription={task.description}
            taskPriority={task.priority}
            taskTitle={task.title}
          />
        );
      case TaskTemplateModes.VIEW:
        return <ViewTaskDetail task={task} taskPanelControls={taskPanelControls} />;
      default:
        return null;
    }
  }
};

TaskDetailSection.propTypes = {
  taskPanelControls: PropTypes.node,
  handleTaskType: PropTypes.func,
  section: PropTypes.object,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  isCalledfromTaskEscalationColumn: PropTypes.bool,
};

TaskDetailSection.defaultProps = {
  taskPanelControls: null,
  handleTaskType: () => null,
  section: {},
  task: {},
  isCalledfromTaskEscalationColumn: null,
};
