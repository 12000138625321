import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@ge/components/button';

import { StyledHLPFooter } from './hp-styled-components';

const HandlingProcedureFooter = (props) => {
  const { t } = useTranslation(['admin.configure']);
  const { onSubmit, onCreateHP, onCancel } = props;

  const formatData = (data) => {
    const { title, desc, eventsProp } = data;
    let isError = false;
    const commonEventCodes = new Set();
    const modifyEventProp = eventsProp.reduce((previousVal, currentValue) => {
      const records = previousVal;
      //if @record doesn't exits
      if (!previousVal?.[currentValue.EventMap.value]) {
        records[currentValue.EventMap.value] = {
          eventMapId: currentValue.EventMap.value,
          eventIds: [currentValue.EventCode.value],
        };
        return records;
      }
      const record = records[currentValue.EventMap.value];
      if (record.eventIds.includes(currentValue.EventCode.value)) {
        isError = true;
        commonEventCodes.add(currentValue.EventCode.label);
      }
      record.eventIds.push(currentValue.EventCode.value);
      return records;
    }, {});
    const result = {
      procProp: {
        title,
        desc,
      },
      eventProp: Object.values(modifyEventProp),
    };
    return { result, isError, commonEventCodes: Array.from(commonEventCodes) };
  };

  const validateOnSubmit = (data) => {
    onCreateHP(formatData(data));
  };

  return (
    <StyledHLPFooter>
      <Button onClick={onCancel}>{t('cancel', 'Cancel')}</Button>
      <Button primary onClick={onSubmit(validateOnSubmit)}>
        {t('save', 'Save')}
      </Button>
    </StyledHLPFooter>
  );
};

HandlingProcedureFooter.propTypes = {
  onSubmit: PropTypes.func,
  onCreateHP: PropTypes.func,
  onCancel: PropTypes.func,
};

export default HandlingProcedureFooter;
