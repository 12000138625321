import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { AnalyzeGlobalFilterKeys } from '@ge/models/constants';
import CollapsibleFilterGroup from '@ge/shared/components/collapsible-filter-group';

const scopFilter = [
  AnalyzeGlobalFilterKeys.ALL,
  AnalyzeGlobalFilterKeys.NON_CORE_FLEET,
  AnalyzeGlobalFilterKeys.CORE_FLEET,
  AnalyzeGlobalFilterKeys.IN_WARRANTY,
  AnalyzeGlobalFilterKeys.OUT_OF_WARRANTY,
];
const FilterContainer = styled.div`
  margin-top: 15px;
`;

const FilterGroup = styled.div`
  border-top: 1px solid ${(props) => props.theme.analyze.filter.groupBorder};
  margin: 0 15px;
  padding: 12px 0;
  &:first-of-type {
    padding-bottom: 0;
  }
`;

const PanelHeader = styled.div`
  color: ${(props) => props.theme.analyze.filter.groupHeader};
  font: 700 11px/13px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  margin-left: 1px;
  padding: 6px 0;
  span {
    font: 300 12px/15px;
  }
`;

const StyledRadioInput = styled.div`
  margin: 16px 0;
  input[type='radio'] {
    &:checked:before {
      border-color: ${(props) => props.theme.radio.borderColor};
      animation: ripple 0.2s linear forwards;
    }

    &:checked:after {
      transform: scale(1);
    }
  }

  input[type='radio'] {
    height: 14px;
    position: relative;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: 12px;
      height: 12px;
      background: #38434e;
      box-shadow: inset 20px -28px 30px 4px rgb(45 45 52 / 50%);
      border: 1px solid ${(props) => props.theme.radio.borderColor};
    }

    &:after {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      transform: scale(0);
      background: ${(props) => props.theme.radio.activeColor};
    }
  }
`;

const RadioLabel = styled.span`
  margin-left: 6px;
`;

export const AnalyzeFilters = ({ filters, setFilters, platforms, models }) => {
  const { t } = useTranslation(['general'], {
    useSuspense: false,
  });
  const [radioSelected, setRadioSelected] = useState({});
  useEffect(() => {
    setRadioSelected(
      !filters[AnalyzeGlobalFilterKeys.NON_CORE_FLEET] &&
        !filters[AnalyzeGlobalFilterKeys.CORE_FLEET] &&
        !filters[AnalyzeGlobalFilterKeys.IN_WARRANTY] &&
        !filters[AnalyzeGlobalFilterKeys.OUT_OF_WARRANTY]
        ? {
            ...filters,
            [AnalyzeGlobalFilterKeys.ALL]: true,
          }
        : filters,
    );
  }, [filters]);
  const handleChange = (key, value) => {
    let updated = {};
    if (key === AnalyzeGlobalFilterKeys.EXCLUDE_3_MONTHS_PAST_COD) {
      updated = {
        ...filters,
        [key]: value,
      };
    } else {
      if (scopFilter.includes(key)) {
        scopFilter.map((key) => {
          delete filters?.[key];
        });
      }
      updated = {
        ...filters,
        [key]: value,
      };
    }
    setFilters(updated);
    setRadioSelected(updated);
  };

  return (
    <FilterContainer>
      <FilterGroup>
        <PanelHeader>{t('filter.turbines', 'Turbines')}</PanelHeader>
        {Boolean(platforms?.length) && (
          <CollapsibleFilterGroup
            group={platforms}
            onChange={(value) => handleChange(AnalyzeGlobalFilterKeys.PLATFORMS, value)}
            selectAll={true}
            state={filters?.[AnalyzeGlobalFilterKeys.PLATFORMS]}
            title={t('filter.platforms', 'Platforms')}
          />
        )}
        {Boolean(models?.length) && (
          <CollapsibleFilterGroup
            group={models}
            onChange={(value) => handleChange(AnalyzeGlobalFilterKeys.MODELS, value)}
            selectAll={true}
            state={filters?.[AnalyzeGlobalFilterKeys.MODELS]}
            title={t('filter.models', 'Models')}
          />
        )}
        {/* {Boolean(controlCodes?.length) && (
          <CollapsibleFilterGroup
            group={controlCodes}
            onChange={(value) => handleChange(AnalyzeGlobalFilterKeys.CONTROL_CODES, value)}
            selectAll={true}
            state={filters?.[AnalyzeGlobalFilterKeys.CONTROL_CODES]}
            title={t('filter.control_codes', 'ControlCodes')}
          />
        )} */}
      </FilterGroup>
      {/* commenting out for current MVP, will be added back in in future version */}
      {/* <FilterGroup>
        <PanelHeader>{t('filter.contract_status', 'Contract Status')}</PanelHeader>
        <StyledCheckbox
          checkState={
            filters?.[AnalyzeGlobalFilterKeys.UNDER_WARRANTY]
              ? CheckedState.CHECKED
              : CheckedState.UNCHECKED
          }
          label={t('filter.under_warranty', 'Under Warranty')}
          onChange={(value) => handleChange(AnalyzeGlobalFilterKeys.UNDER_WARRANTY, value)}
        />
        <StyledCheckbox
          checkState={
            filters?.[AnalyzeGlobalFilterKeys.SERVICE_AGREEMENT]
              ? CheckedState.CHECKED
              : CheckedState.UNCHECKED
          }
          label={t('filter.service_agreement', 'Service Agreement')}
          onChange={(value) => handleChange(AnalyzeGlobalFilterKeys.SERVICE_AGREEMENT, value)}
        />
      </FilterGroup> */}
      <FilterGroup>
        <PanelHeader>{t('filter.scope', 'Scope')}</PanelHeader>
        <StyledRadioInput>
          <input
            type="radio"
            value={t('filter.all', 'All')}
            checked={radioSelected?.[AnalyzeGlobalFilterKeys.ALL] ? true : false}
            onChange={() => handleChange(AnalyzeGlobalFilterKeys.ALL, true)}
          />
          <RadioLabel>{t('filter.all', 'All')}</RadioLabel>
        </StyledRadioInput>
        <StyledRadioInput>
          <input
            type="radio"
            value={t('filter.core_fleet', 'FRO Core Fleet')}
            checked={radioSelected?.[AnalyzeGlobalFilterKeys.CORE_FLEET] ? true : false}
            onChange={() => handleChange(AnalyzeGlobalFilterKeys.CORE_FLEET, true)}
          />
          <RadioLabel>{t('filter.core_fleet', 'FRO Core Fleet')}</RadioLabel>
        </StyledRadioInput>
        <StyledRadioInput>
          <input
            type="radio"
            checked={radioSelected?.[AnalyzeGlobalFilterKeys.NON_CORE_FLEET] ? true : false}
            value={t('filter.non_core_fleet', 'Non FRO Core Fleet')}
            onChange={() => handleChange(AnalyzeGlobalFilterKeys.NON_CORE_FLEET, true)}
          />
          <RadioLabel>{t('filter.non_core_fleet', 'Non FRO Core Fleet')}</RadioLabel>
        </StyledRadioInput>
        <>
          {' '}
          <StyledRadioInput>
            <input
              type="radio"
              checked={radioSelected?.[AnalyzeGlobalFilterKeys.IN_WARRANTY] ? true : false}
              value={t('filter.in_warranty', 'Exclude 3 months past COD')}
              onChange={() => handleChange(AnalyzeGlobalFilterKeys.IN_WARRANTY, true)}
            />
            <RadioLabel>{t('filter.in_warranty', 'Exclude 3 months past COD')}</RadioLabel>
          </StyledRadioInput>
          <StyledRadioInput>
            <input
              type="radio"
              checked={radioSelected?.[AnalyzeGlobalFilterKeys.OUT_OF_WARRANTY] ? true : false}
              value={t('filter.out_of_warranty', 'Exclude 3 months past COD')}
              onChange={() => handleChange(AnalyzeGlobalFilterKeys.OUT_OF_WARRANTY, true)}
            />
            <RadioLabel>{t('filter.out_of_warranty', 'Exclude 3 months past COD')}</RadioLabel>
          </StyledRadioInput>
        </>
        <StyledCheckbox
          checkState={
            filters?.[AnalyzeGlobalFilterKeys.EXCLUDE_3_MONTHS_PAST_COD]
              ? CheckedState.CHECKED
              : CheckedState.UNCHECKED
          }
          label={t('filter.exclude_3_months_past_cod', 'Exclude 3 months past COD')}
          onChange={(value) =>
            handleChange(AnalyzeGlobalFilterKeys.EXCLUDE_3_MONTHS_PAST_COD, value)
          }
        />
      </FilterGroup>
    </FilterContainer>
  );
};

AnalyzeFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  platforms: PropTypes.array,
  models: PropTypes.array,
};

AnalyzeFilters.defaultProps = {
  setFilters: () => {},
  filters: {},
};
