import { PropTypes } from 'prop-types';
import React from 'react';

import { AssetCommandStatus } from '@ge/models/constants';

import { useAssetCommandStatus } from '../../data-hooks/use-asset-command-status';

import { CommandMessage } from './command-message';

// allows for live updates to the message in the notification
export const MutableCommandMessage = ({ assets, command, statusId }) => {
  const { data: { isComplete } = {} } = useAssetCommandStatus({ assets, statusId });

  const status = isComplete ? AssetCommandStatus.COMPLETE : AssetCommandStatus.IN_PROGRESS;

  return <CommandMessage command={command} status={status} />;
};

MutableCommandMessage.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
  command: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
};

MutableCommandMessage.defaultProps = {
  assets: undefined,
};
