import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks/config';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { sorter } from '@ge/util/metric-sorter';

import { fetchCaseTemplateTable } from '../services';

export const useCaseTemplateTable = ({
  filterSequence,
  filters,
  search,
  sortDirection,
  sortMetric,
}) => {
  const { data: caseTemplateData, isLoading: isCaseTemplateDataLoading } = useQuery(
    [QueryKey.CASE_TEMPLATE_TABLE],
    async () => fetchCaseTemplateTable(),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(true),
      select: (res) => {
        res.data = res.data.map((item) => {
          item.beta = item.isBeta ? 'Beta' : '';
          return item;
        });
        return res;
      },
    },
  );
  if (caseTemplateData?.data) {
    caseTemplateData?.data.forEach((data) => (data.taskCount = String(data.taskCount)));
  }
  const { data: filteredData, filterValues } = useTableFilter({
    data: caseTemplateData?.data.sort(sorter(sortMetric, sortDirection)),
    filterSequence,
    filters,
    search,
  });
  if (filteredData) {
    filteredData?.forEach((data) => (data.taskCount = Number(data.taskCount)));
  }
  const sortedData = useMemo(
    () => (filteredData?.length ? [...filteredData.sort(sorter(sortMetric, sortDirection))] : []),
    [filteredData, sortDirection, sortMetric],
  );

  return {
    data: sortedData,
    filterValues,
    isCaseTemplateDataLoading,
  };
};
