import mergeDeepRight from 'ramda/src/mergeDeepRight';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { AlertDataSource, AlertType, FormMode, QueryKey } from '@ge/models/constants';
import { delay, snakeToCamelCase } from '@ge/shared/util';

import { Config } from './config';
import {
  PlannedOutageTemplate,
  TemporaryConfigChangeTemplate,
  WorkerPresentTemplate,
  NoCommOutageTemplate,
  UnPlannedOutageTemplate,
  CommissioningTemplate,
  ReliabilityRunTemplate,
  NOTAMTemplate,
  NonOperatingAssetTemplate,
} from './mocks';
import { useAlertTypes } from './use-alert-types';
import { useEvents } from './use-events';

// can push some of this down into bff as needed
// we flatten metadata by mode, bind data sources, and rearrange into rows/cols in here
const transformTemplate = ({ data = {}, mode = FormMode.CREATE, template }) => {
  if (!template) {
    return null;
  }

  const { sections, ..._template } = template;

  return {
    ..._template,
    sections: sections?.reduce((_sections, { type, ...section }) => {
      const { metadata, ..._section } = section;

      const metadataDict = Object.entries(metadata ?? {});

      const transformedMetadata = metadataDict.reduce((_metadata, [key, field]) => {
        const {
          [FormMode.CREATE]: _create,
          [FormMode.EDIT]: _edit,
          [FormMode.VIEW]: _view,
          position: _position,
          ..._field
        } = field;

        const { position: modePosition, ...fieldMetadata } = mergeDeepRight(
          _field,
          field[mode] ?? {},
        );
        // get most specific positioning (if specified at the mode-level)
        const position = modePosition || _position;

        fieldMetadata.name = key;

        // bind data source to field (can add additional as needed)
        if (
          Boolean(data?.[AlertDataSource.EVENTS]?.length) &&
          fieldMetadata.data === AlertDataSource.EVENTS
        ) {
          fieldMetadata.values = data[AlertDataSource.EVENTS];
        }

        if (!position) {
          fieldMetadata.col = 1;
          fieldMetadata.span = 12;

          // can look into making sure this won't be overwritten if there are other rows with specified positioning
          _metadata.push([fieldMetadata]);

          return _metadata;
        }

        const { col, row, span } = position;
        // row from template has index starting at 1
        const rowIndex = row - 1;
        const metadataRow = _metadata[rowIndex];

        fieldMetadata.col = col;
        fieldMetadata.span = span;

        // new row
        if (!metadataRow) {
          _metadata[rowIndex] = [fieldMetadata];

          return _metadata;
        }

        // update existing row
        metadataRow.push(fieldMetadata);
        metadataRow.sort(({ col: a }, { col: b }) => a - b);

        return _metadata;
      }, []);

      // if section is hidden or all fields are marked as hidden, then hide
      const hidden = section.hidden || metadataDict.every(([, { [mode]: _mode }]) => _mode?.hidden);

      return {
        ..._sections,
        [snakeToCamelCase(type)]: {
          ..._section,
          hidden,
          metadata: transformedMetadata,
        },
      };
    }, {}),
  };
};

export const useAlertTemplate = (
  { alertType, isActive = true, entityId, entityType, mode } = { isActive: true },
) => {
  const {
    data: types,
    error: typesError,
    isLoading: isTypesLoading,
  } = useAlertTypes({
    entityType,
    isActive,
  });

  const {
    data: _template,
    error: templateError,
    isLoading: isTemplateLoading,
  } = useQuery(
    [QueryKey.ALERT_TEMPLATE, alertType],
    () =>
      // TODO: unmock once backend ready
      delay(() => {
        const template = {
          [AlertType.PLANNED_OUTAGE]: PlannedOutageTemplate,
          [AlertType.TEMPORARY_CONFIG_CHANGE]: TemporaryConfigChangeTemplate,
          [AlertType.WORKER_PRESENT]: WorkerPresentTemplate,
          [AlertType.NOCOMM_OUTAGE]: NoCommOutageTemplate,
          [AlertType.COMMISSIONING_OUTAGE]: CommissioningTemplate,
          [AlertType.RELIABILITY_RUN]: ReliabilityRunTemplate,
          [AlertType.UNPLANNED_OUTAGE]: UnPlannedOutageTemplate,
          [AlertType.NOTAM]: NOTAMTemplate,
          [AlertType.NON_OPERATING_ASSET]: NonOperatingAssetTemplate,
        }[alertType];

        if (!template) {
          throw new Error(`Template for alert '${alertType}' not found`);
        }

        return template;
      }, 500),
    {
      ...Config.EXECUTE_ONCE,
      // only fetch if active and type provided
      enabled: isActive && Boolean(alertType),
    },
  );

  // get data sources for fields
  // MVP0 - temporarily turning this off until we get search capability
  // this will apply for the temporary config change template in the future
  const hasEventsDataSource = false;

  const {
    data: events,
    error: eventsError,
    isLoading: isEventsLoading,
  } = useEvents({
    entityId,
    entityType,
    isActive: isActive && hasEventsDataSource,
  });
  const eventsDataSource = useMemo(
    () =>
      events
        ?.map(({ description: label, id: value }) => ({ label, value }))
        .sort(({ label: a }, { label: b }) => a?.localeCompare(b)) ?? [],
    [events],
  );

  const template = transformTemplate({
    data: { [AlertDataSource.EVENTS]: eventsDataSource },
    mode,
    template: _template,
  });

  // do we want to track these loading states separately?
  const isLoading = isEventsLoading || isTemplateLoading || isTypesLoading;

  // revisit how we want to handle all these errors
  const error = {
    template: templateError ?? eventsError,
    types: typesError,
  };

  return {
    data: {
      template,
      types,
    },
    error,
    isLoading,
  };
};
