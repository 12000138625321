import { useStoreState } from 'easy-peasy';

import useSitesKpiData from './use-sites-kpi-data';

/**
 * Use Site KPI data.
 *
 * @param siteIds the site ids
 * @param def
 * @param categories the kpi categories
 * @param timeAggr the time aggregate
 * @param startDate the start date
 * @param endDate the end date
 * @param sortMetric the sort KPI metric
 * @param sortDirection the sort direction
 * @param entityAggr aggregate both entity and date
 * @param seriesEntityAggr aggregate entity (all metrics kpi only)
 * @param dateEntityAggr aggregate date (all metrics kpi only)
 */
const useAllSitesKpiData = (params) => {
  const { sites: sitesById } = useStoreState(({ sites }) => sites);
  return useSitesKpiData({ hydrateBy: sitesById, ...params });
};
export default useAllSitesKpiData;
