// @flow
import isEqual from 'lodash/isEqual';
import convertExpandingLineToPolygon from 'react-image-annotate/Annotator/reducers/convert-expanding-line-to-polygon';
import fixTwisted from 'react-image-annotate/Annotator/reducers/fix-twisted';
import { saveToHistory } from 'react-image-annotate/Annotator/reducers/history-handler.js';
import { moveRegion } from 'react-image-annotate/ImageCanvas/region-tools.js';
import colors from 'react-image-annotate/colors';
import { getIn, setIn } from 'seamless-immutable';

import { AnnotatorConstants } from '../../../constants';

import getActiveImage from './get-active-image';

const getRandomId = () =>
  Math.random()
    .toString()
    .split('.')[1];

export default (state, action) => {
  if (action.type === AnnotatorConstants.ON_CLS_ADDED && action.cls && action.cls !== '') {
    const oldRegionClsList = state.regionClsList;
    const newState = {
      ...state,
      regionClsList: oldRegionClsList.concat(action.cls),
    };
    return newState;
  }

  // Throttle certain actions
  if (action.type === AnnotatorConstants.MOUSE_MOVE) {
    if (Date.now() - (state.lastMouseMoveCall || 0) < 16) return state;
    state = setIn(state, [AnnotatorConstants.LAST_MOUSE_MOVE_CALL], Date.now());
  }
  if (!action.type.includes(AnnotatorConstants.MOUSE)) {
    state = setIn(state, [AnnotatorConstants.LAST_ACTION], action);
  }
  // So that box creation can be recorded.
  if (
    action.type === AnnotatorConstants.MOUSE_UP &&
    state.selectedTool === AnnotatorConstants.CREATE_BOX
  ) {
    state = setIn(state, [AnnotatorConstants.LAST_ACTION], action);
  }

  if (action.type === AnnotatorConstants.IMAGES_UPDATED) {
    state = setIn(state, ['images'], action.images);
  }

  const { pathToActiveImage, activeImage } = getActiveImage(state);

  const getRegionIndex = (region) => {
    const regionId = typeof region === 'string' ? region : region.id;
    if (!activeImage) return null;
    const regionIndex = (activeImage.regions || []).findIndex((r) => r.id === regionId);
    return regionIndex === -1 ? null : regionIndex;
  };
  const getRegion = (regionId) => {
    if (!activeImage) return null;
    const regionIndex = getRegionIndex(regionId);
    if (regionIndex === null) return [null, null];
    const region = activeImage.regions[regionIndex];
    return [region, regionIndex];
  };
  const modifyRegion = (regionId, obj) => {
    const [region, regionIndex] = getRegion(regionId);
    if (!region) return state;
    if (obj !== null) {
      return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex], {
        ...region,
        ...obj,
      });
    }
    // delete region
    const { regions } = activeImage;
    return setIn(
      state,
      [...pathToActiveImage, AnnotatorConstants.REGIONS],
      (regions || []).filter((r) => r.id !== region.id),
    );
  };

  const closeEditors = (state) => {
    return setIn(
      state,
      [...pathToActiveImage, AnnotatorConstants.REGIONS],
      (activeImage.regions || []).map((r) => ({
        ...r,
        editingLabels: false,
      })),
    );
  };

  const setNewImage = (img) => {
    const { frameTime } = typeof img === 'object' ? img : { src: img };
    return setIn(
      setIn(state, [AnnotatorConstants.CURRENT_IMAGE], img),
      [AnnotatorConstants.SELECTED_IMAGE_FRAME_TIME],
      frameTime,
    );
  };

  switch (action.type) {
    case AnnotatorConstants.INIT: {
      return state;
    }
    case AnnotatorConstants.SELECT_IMAGE: {
      return setNewImage(action.image);
    }
    case AnnotatorConstants.CHANGE_REGION: {
      const regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;
      const oldRegion = activeImage.regions[regionIndex];
      if (oldRegion.cls !== action.region.cls) {
        state = saveToHistory(state, 'Change Region Classification'); // TODO: does this need translated?
        const clsIndex = state.regionClsList.indexOf(action.region.cls);
        if (clsIndex !== -1) {
          action.region.color = colors[clsIndex % colors.length];
        }
      }
      if (!isEqual(oldRegion.tags, action.region.tags)) {
        state = saveToHistory(state, 'Change Region Tags'); // TODO: does this need translated?
      }
      return setIn(
        state,
        [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex],
        action.region,
      );
    }
    case AnnotatorConstants.CHANGE_IMAGE: {
      if (!activeImage) return state;
      const { delta } = action;
      for (const key of Object.keys(delta)) {
        if (key === AnnotatorConstants.CLS) saveToHistory(state, 'Change Image Class'); // TODO: does this need translated?
        if (key === AnnotatorConstants.TAGS) saveToHistory(state, 'Change Image Tags'); // TODO: does this need translated?
        state = setIn(state, [...pathToActiveImage, key], delta[key]);
      }
      return state;
    }
    case AnnotatorConstants.SELECT_REGION: {
      const { region } = action;
      const regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;
      const regions = [...(activeImage.regions || [])].map((r) => ({
        ...r,
        highlighted: r.id === region.id,
        editingLabels: r.id === region.id,
      }));
      return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS], regions);
    }
    case AnnotatorConstants.BEGIN_MOVE_POINT: {
      state = closeEditors(state);
      return setIn(state, [AnnotatorConstants.MODE], {
        mode: AnnotatorConstants.MOVE_REGION,
        regionId: action.point.id,
      });
    }
    // case AnnotatorConstants.BEGIN_BOX_TRANSFORM: {
    //   const { box, directions } = action;
    //   state = closeEditors(state);
    //   if (directions[0] === 0 && directions[1] === 0) {
    //     return setIn(state, [AnnotatorConstants.MODE], { mode: AnnotatorConstants.MOVE_REGION, regionId: box.id });
    //   } else {
    //     return setIn(state, [AnnotatorConstants.MODE], {
    //       mode: AnnotatorConstants.RESIZE_BOX,
    //       regionId: box.id,
    //       freedom: directions,
    //       original: { x: box.x, y: box.y, w: box.w, h: box.h },
    //     });
    //   }
    // }
    case AnnotatorConstants.BEGIN_MOVE_POLYGON_POINT: {
      const { polygon, pointIndex } = action;
      state = closeEditors(state);
      if (state.mode && state.mode.mode === AnnotatorConstants.DRAW_POLYGON && pointIndex === 0) {
        return setIn(
          modifyRegion(polygon, {
            points: polygon.points.slice(0, -1),
            open: false,
          }),
          [AnnotatorConstants.MODE],
          null,
        );
      }
      state = saveToHistory(state, 'Move Polygon Point'); // TODO: does this need translated?

      return setIn(state, [AnnotatorConstants.MODE], {
        mode: AnnotatorConstants.MOVE_POLYGON_POINT,
        regionId: polygon.id,
        pointIndex,
      });
    }
    case AnnotatorConstants.MOUSE_MOVE: {
      const { x, y } = action;
      if (!state.mode) return state;
      if (!activeImage) return state;
      switch (state.mode.mode) {
        case AnnotatorConstants.MOVE_POLYGON_POINT: {
          const { pointIndex, regionId } = state.mode;
          const regionIndex = getRegionIndex(regionId);
          if (regionIndex === null) return state;
          return setIn(
            state,
            [
              ...pathToActiveImage,
              AnnotatorConstants.REGIONS,
              regionIndex,
              AnnotatorConstants.POINTS,
              pointIndex,
            ],
            [x, y],
          );
        }
        case AnnotatorConstants.MOVE_REGION: {
          const { regionId } = state.mode;
          const regionIndex = getRegionIndex(regionId);
          if (regionIndex === null) return state;
          return setIn(
            state,
            [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex],
            moveRegion(activeImage.regions[regionIndex], x, y),
          );
        }
        case AnnotatorConstants.RESIZE_BOX: {
          const {
            regionId,
            freedom: [xFree, yFree],
            original: { x: ox, y: oy, w: ow, h: oh },
          } = state.mode;
          const regionIndex = getRegionIndex(regionId);
          if (regionIndex === null) return state;
          const box = activeImage.regions[regionIndex];

          const dx = xFree === 0 ? ox : xFree === -1 ? Math.min(ox + ow, x) : ox;
          const dw =
            xFree === 0 ? ow : xFree === -1 ? ow + (ox - dx) : Math.max(0, ow + (x - ox - ow));
          const dy = yFree === 0 ? oy : yFree === -1 ? Math.min(oy + oh, y) : oy;
          const dh =
            yFree === 0 ? oh : yFree === -1 ? oh + (oy - dy) : Math.max(0, oh + (y - oy - oh));

          // determine if we should switch the freedom
          if (dw <= 0.001) {
            state = setIn(
              state,
              [AnnotatorConstants.MODE, AnnotatorConstants.FREEDOM],
              [xFree * -1, yFree],
            );
          }
          if (dh <= 0.001) {
            state = setIn(
              state,
              [AnnotatorConstants.MODE, AnnotatorConstants.FREEDOM],
              [xFree, yFree * -1],
            );
          }

          return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex], {
            ...box,
            x: dx,
            w: dw,
            y: dy,
            h: dh,
          });
        }
        case AnnotatorConstants.DRAW_POLYGON: {
          const { regionId } = state.mode;
          const [region, regionIndex] = getRegion(regionId);
          if (!region) return setIn(state, [AnnotatorConstants.MODE], null);
          return setIn(
            state,
            [
              ...pathToActiveImage,
              AnnotatorConstants.REGIONS,
              regionIndex,
              AnnotatorConstants.POINTS,
              region.points.length - 1,
            ],
            [x, y],
          );
        }
        case AnnotatorConstants.DRAW_RULER: {
          const { regionId } = state.mode;
          const [region, regionIndex] = getRegion(regionId);
          if (!region) return setIn(state, [AnnotatorConstants.MODE], null);
          return setIn(
            state,
            [
              ...pathToActiveImage,
              AnnotatorConstants.REGIONS,
              regionIndex,
              AnnotatorConstants.POINTS,
              region.points.length - 1,
            ],
            [x, y],
          );
        }
        case AnnotatorConstants.DRAW_EXPANDING_LINE: {
          const { regionId } = state.mode;
          const [expandingLine, regionIndex] = getRegion(regionId);
          if (!expandingLine) return state;
          const isMouseDown = Boolean(state.mouseDownAt);
          if (isMouseDown) {
            // If the mouse is down, set width/angle
            const lastPoint = expandingLine.points.slice(-1)[0];
            const mouseDistFromLastPoint = Math.sqrt(
              (lastPoint.x - x) ** 2 + (lastPoint.y - y) ** 2,
            );
            if (mouseDistFromLastPoint < 0.002 && !lastPoint.width) return state;

            const newState = setIn(
              state,
              [
                ...pathToActiveImage,
                AnnotatorConstants.REGIONS,
                regionIndex,
                AnnotatorConstants.POINTS,
              ],
              expandingLine.points.slice(0, -1).concat([
                {
                  ...lastPoint,
                  width: mouseDistFromLastPoint * 2,
                  angle: Math.atan2(lastPoint.x - x, lastPoint.y - y),
                },
              ]),
            );
            return newState;
          }
          // If mouse is up, move the next candidate point
          return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex], {
            ...expandingLine,
            candidatePoint: { x, y },
          });
        }
        case AnnotatorConstants.SET_EXPANDING_LINE_WIDTH: {
          const { regionId } = state.mode;
          const [expandingLine, regionIndex] = getRegion(regionId);
          if (!expandingLine) return state;
          const lastPoint = expandingLine.points.slice(-1)[0];
          return setIn(
            state,
            [
              ...pathToActiveImage,
              AnnotatorConstants.REGIONS,
              regionIndex,
              AnnotatorConstants.EXPANDING_WIDTH,
            ],
            Math.sqrt((lastPoint.x - x) ** 2 + (lastPoint.y - y) ** 2),
          );
        }
        default:
          return state;
      }
    }
    case AnnotatorConstants.MOUSE_DOWN: {
      if (!activeImage) return state;
      const { x, y } = action;
      state = setIn(state, [AnnotatorConstants.MOUSE_DOWN_AT], { x, y });

      if (state.allowedArea) {
        // TODO clamp x/y instead of giving up
        // TODO or image bounds
        const aa = state.allowedArea;
        if (x < aa.x || x > aa.x + aa.w || y < aa.y || y > aa.y + aa.h) {
          return state;
        }
      }

      if (state.mode) {
        switch (state.mode.mode) {
          case AnnotatorConstants.DRAW_POLYGON: {
            const [polygon, regionIndex] = getRegion(state.mode.regionId);
            if (!polygon) break;
            return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex], {
              ...polygon,
              points: polygon.points.concat([[x, y]]),
            });
          }
          case AnnotatorConstants.DRAW_RULER: {
            const [polygon, regionIndex] = getRegion(state.mode.regionId);
            if (!polygon) break;
            if (polygon.points.length > 2) {
              break;
            }
            return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex], {
              ...polygon,
              points: polygon.points.concat([[x, y]]),
            });
          }
          case AnnotatorConstants.DRAW_EXPANDING_LINE: {
            const [expandingLine, regionIndex] = getRegion(state.mode.regionId);
            if (!expandingLine) break;
            const lastPoint = expandingLine.points.slice(-1)[0];
            if (
              expandingLine.points.length > 1 &&
              Math.sqrt((lastPoint.x - x) ** 2 + (lastPoint.y - y) ** 2) < 0.002
            ) {
              if (!lastPoint.width) {
                return setIn(state, [AnnotatorConstants.MODE], {
                  mode: AnnotatorConstants.SET_EXPANDING_LINE_WIDTH,
                  regionId: state.mode.regionId,
                });
              }
              return state
                .setIn(
                  [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex],
                  convertExpandingLineToPolygon(expandingLine),
                )
                .setIn([AnnotatorConstants.MODE], null);
            }

            // Create new point
            return setIn(
              state,
              [
                ...pathToActiveImage,
                AnnotatorConstants.REGIONS,
                regionIndex,
                AnnotatorConstants.POINTS,
              ],
              expandingLine.points.concat([
                {
                  x,
                  y,
                  angle: null,
                  width: null,
                },
              ]),
            );
          }
          case AnnotatorConstants.SET_EXPANDING_LINE_WIDTH: {
            const [expandingLine, regionIndex] = getRegion(state.mode.regionId);
            if (!expandingLine) break;
            const { expandingWidth } = expandingLine;
            return state
              .setIn(
                [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex],
                convertExpandingLineToPolygon({
                  ...expandingLine,
                  points: expandingLine.points.map((p) =>
                    p.width ? p : { ...p, width: expandingWidth },
                  ),
                  expandingWidth: undefined,
                }),
              )
              .setIn([AnnotatorConstants.MODE], null);
          }
          default:
            break;
        }
      }

      let newRegion;
      const defaultRegionCls = AnnotatorConstants.HUMAN;
      let defaultRegionColor = '#ff0000';
      switch (state.selectedTool) {
        case AnnotatorConstants.CREATE_POINT: {
          state = saveToHistory(state, 'Create Point'); // TODO: does this need translated?
          newRegion = {
            type: AnnotatorConstants.POINT,
            x,
            y,
            highlighted: true,
            editingLabels: true,
            color: defaultRegionColor,
            id: getRandomId(),
            cls: defaultRegionCls,
          };
          break;
        }
        case AnnotatorConstants.CREATE_BOX: {
          state = saveToHistory(state, 'Create Box'); // TODO: does this need translated?
          newRegion = {
            type: AnnotatorConstants.BOX,
            x,
            y,
            w: 0.01,
            h: 0.01,
            highlighted: true,
            editingLabels: false,
            color: defaultRegionColor,
            cls: defaultRegionCls,
            id: getRandomId(),
          };
          state = setIn(state, [AnnotatorConstants.MODE], {
            mode: AnnotatorConstants.RESIZE_BOX,
            editLabelEditorAfter: false,
            regionId: newRegion.id,
            freedom: [1, 1],
            original: {
              x,
              y,
              w: newRegion.w,
              h: newRegion.h,
            },
            isNew: true,
          });
          break;
        }
        case AnnotatorConstants.CREATE_POLYGON: {
          if (state.mode && state.mode.mode === AnnotatorConstants.DRAW_POLYGON) break;
          state = saveToHistory(state, 'Create Polygon'); // TODO: does this need translated?
          newRegion = {
            type: AnnotatorConstants.POLYGON,
            points: [
              [x, y],
              [x, y],
            ],
            open: true,
            highlighted: true,
            color: defaultRegionColor,
            cls: defaultRegionCls,
            id: getRandomId(),
          };
          state = setIn(state, [AnnotatorConstants.MODE], {
            mode: AnnotatorConstants.DRAW_POLYGON,
            regionId: newRegion.id,
          });
          break;
        }
        case AnnotatorConstants.CREATE_RULER: {
          if (state.mode && state.mode.mode === AnnotatorConstants.DRAW_RULER) break;
          state = saveToHistory(state, 'Create Ruler'); // TODO: does this need translated?
          newRegion = {
            type: AnnotatorConstants.RULER,
            points: [
              [x, y],
              [x, y],
            ],
            open: true,
            highlighted: true,
            color: defaultRegionColor,
            cls: AnnotatorConstants.RULER,
            id: getRandomId(),
          };
          state = setIn(state, [AnnotatorConstants.MODE], {
            mode: AnnotatorConstants.DRAW_RULER,
            regionId: newRegion.id,
          });
          break;
        }
        case AnnotatorConstants.CREATE_EXPANDING_LINE: {
          state = saveToHistory(state, 'Create Expanding Line'); // TODO: does this need translated?
          newRegion = {
            type: AnnotatorConstants.EXPANDING_LINE,
            unfinished: true,
            points: [
              {
                x,
                y,
                angle: null,
                width: null,
              },
            ],
            open: true,
            highlighted: true,
            color: defaultRegionColor,
            cls: defaultRegionCls,
            id: getRandomId(),
          };
          state = setIn(state, [AnnotatorConstants.MODE], {
            mode: AnnotatorConstants.DRAW_EXPANDING_LINE,
            regionId: newRegion.id,
          });
          break;
        }
        default:
          break;
      }

      const regions = [...(getIn(state, pathToActiveImage).regions || [])]
        .map((r) =>
          setIn(r, [AnnotatorConstants.EDITING_LABELS], false).setIn(
            [AnnotatorConstants.HIGHLIGHTED],
            false,
          ),
        )
        .concat(newRegion ? [newRegion] : []);
      return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS], regions);
    }
    case AnnotatorConstants.MOUSE_UP: {
      const { x, y } = action;
      const { mouseDownAt = { x, y } } = state;
      if (!state.mode) return state;
      state = setIn(state, [AnnotatorConstants.MOUSE_DOWN_AT], null);
      switch (state.mode.mode) {
        case AnnotatorConstants.RESIZE_BOX: {
          if (state.mode.isNew) {
            if (
              Math.abs(state.mode.original.x - x) < 0.002 &&
              Math.abs(state.mode.original.y - y) < 0.002
            ) {
              return setIn(
                modifyRegion(state.mode.regionId, null),
                [AnnotatorConstants.MODE],
                null,
              );
            }
          }
          if (state.mode.editLabelEditorAfter) {
            return {
              ...modifyRegion(state.mode.regionId, { editingLabels: true }),
              mode: null,
            };
          }
          return state;
        }
        case AnnotatorConstants.MOVE_REGION:
        case AnnotatorConstants.MOVE_POLYGON_POINT: {
          return { ...state, mode: null };
        }
        case AnnotatorConstants.CREATE_POINT_LINE: {
          return state;
        }
        case AnnotatorConstants.DRAW_EXPANDING_LINE: {
          const [expandingLine, regionIndex] = getRegion(state.mode.regionId);
          if (!expandingLine) return state;
          let newExpandingLine = expandingLine;
          const lastPoint =
            expandingLine.points.length !== 0 ? expandingLine.points.slice(-1)[0] : mouseDownAt;
          const mouseDistFromLastPoint = Math.sqrt((lastPoint.x - x) ** 2 + (lastPoint.y - y) ** 2);
          if (mouseDistFromLastPoint > 0.002) {
            // The user is drawing has drawn the width for the last point
            const newPoints = [...expandingLine.points];
            for (let i = 0; i < newPoints.length - 1; i++) {
              if (newPoints[i].width) continue;
              newPoints[i] = {
                ...newPoints[i],
                width: lastPoint.width,
              };
            }
            newExpandingLine = setIn(
              expandingLine,
              [AnnotatorConstants.POINTS],
              fixTwisted(newPoints),
            );
          } else {
            return state;
          }
          return setIn(
            state,
            [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex],
            newExpandingLine,
          );
        }
        default:
          return state;
      }
    }
    case AnnotatorConstants.OPEN_REGION_EDITOR: {
      const regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;
      const newRegions = setIn(
        activeImage.regions.map((r) => ({
          ...r,
          highlighted: false,
          editingLabels: false,
        })),
        [regionIndex],
        {
          ...(activeImage.regions || [])[regionIndex],
          highlighted: true,
          editingLabels: true,
        },
      );
      return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS], newRegions);
    }
    case AnnotatorConstants.CLOSE_REGION_EDITOR: {
      const regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;
      return setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS, regionIndex], {
        ...(activeImage.regions || [])[regionIndex],
        highlighted: false,
        editingLabels: false,
      });
    }
    case AnnotatorConstants.DELETE_REGION: {
      const regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;
      return setIn(
        state,
        [...pathToActiveImage, AnnotatorConstants.REGIONS],
        (activeImage.regions || []).filter((r) => r.id !== action.region.id),
      );
    }
    case AnnotatorConstants.SELECT_TOOL: {
      if (action.selectedTool === AnnotatorConstants.SHOW_DASH_TAGS) {
        return setIn(state, [AnnotatorConstants.SHOW_TAGS], !state.showTags);
      }
      if (action.selectedTool === AnnotatorConstants.SHOW_DASH_MASK) {
        return setIn(state, [AnnotatorConstants.SHOW_MASK], !state.showMask);
      }
      if (action.selectedTool === AnnotatorConstants.CREATE_RULER) {
        const { regions } = activeImage;
        const rulerRegion = regions.find((r) => r.type === AnnotatorConstants.RULER);
        if (rulerRegion) {
          state = setIn(
            state,
            [...pathToActiveImage, AnnotatorConstants.REGIONS],
            (activeImage.regions || []).filter((r) => r.id !== rulerRegion.id),
          );
        }
      }
      state = setIn(state, [AnnotatorConstants.MODE], null);

      const regions = getIn(state, pathToActiveImage)?.regions?.map((r) =>
        setIn(r, [AnnotatorConstants.EDITING_LABELS], false).setIn(
          [AnnotatorConstants.HIGHLIGHTED],
          false,
        ),
      );
      state = setIn(state, [...pathToActiveImage, AnnotatorConstants.REGIONS], regions);

      return setIn(state, [AnnotatorConstants.SELECTED_TOOL], action.selectedTool);
    }
    case AnnotatorConstants.CANCEL: {
      const { mode } = state;
      if (mode) {
        switch (mode.mode) {
          case AnnotatorConstants.DRAW_EXPANDING_LINE:
          case AnnotatorConstants.SET_EXPANDING_LINE_WIDTH:
          case AnnotatorConstants.DRAW_RULER:
          case AnnotatorConstants.DRAW_POLYGON: {
            const { regionId } = mode;
            return modifyRegion(regionId, null);
          }
          case AnnotatorConstants.MOVE_POLYGON_POINT:
          case AnnotatorConstants.RESIZE_BOX:
          case AnnotatorConstants.MOVE_REGION: {
            return setIn(state, [AnnotatorConstants.MODE], null);
          }
          default:
            return state;
        }
      }
      // Close any open boxes
      const { regions } = activeImage;
      if (regions && regions.some((r) => r.editingLabels)) {
        return setIn(
          state,
          [...pathToActiveImage, AnnotatorConstants.REGIONS],
          regions.map((r) => ({
            ...r,
            editingLabels: false,
          })),
        );
      }
      if (regions) {
        return setIn(
          state,
          [...pathToActiveImage, AnnotatorConstants.REGIONS],
          regions.map((r) => ({
            ...r,
            highlighted: false,
          })),
        );
      }
      break;
    }
    default:
      break;
  }
  return state;
};
