import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { BoldSearch } from '@ge/components/bold-search';
import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { SortDirection, SortValueType } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { elevations, globalColors } from '@ge/tokens';
import { sorter } from '@ge/util/metric-sorter';

import { PlanningContext } from '../../../context/planning-provider';
import useAssetSearch from '../../../data-hooks/use-asset-search';

export const ViewSelectorAssetsComponent = ({
  className,
  backlogData,
  assetsSelected,
  onClose,
  onConfirm,
  setActive,
  state,
}) => {
  const {
    planningState: {
      backlogFilter,
      backlogTaskFilter,
      handleSetBacklogFilter,
      handleSetBacklogTaskFilter,
    },
  } = useContext(PlanningContext);
  // state
  const [filterText, setFilterText] = useState('');
  const [expandedSites, setExpandedSites] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState(assetsSelected);

  const { data, isLoading } = useAssetSearch(filterText);
  const [assetsFiltered, sitesFiltered] = data;
  const [sitesBacklog, setSites] = useState(sitesFiltered);
  const [assetsBacklog, setAssets] = useState(assetsFiltered);
  useEffect(() => {
    const assetsItem = assetsFiltered?.filter((a) => backlogData.some((b) => a.id === b.asset?.id));
    setAssets(assetsItem);
    const sitesItem = sitesFiltered?.filter((a) => backlogData.some((b) => a.id === b.site?.id));
    setSites(sitesItem);
  }, [
    assetsFiltered,
    assetsSelected,
    backlogData,
    backlogFilter.filters,
    handleSetBacklogFilter,
    backlogTaskFilter,
    handleSetBacklogTaskFilter,
    sitesFiltered,
  ]);
  const { t } = useTranslation(['manage.planning'], { useSuspense: false });
  const parentRef = useRef();
  const rowVirtualizer = useVirtual({
    size: sitesBacklog.length,
    parentRef,
    estimateSize: React.useCallback(() => 25, []),
    overscan: 10,
  });

  const getCheckState = useCallback(
    (assetId) => (selectedAssets.includes(assetId) ? CheckedState.CHECKED : CheckedState.UNCHECKED),
    [selectedAssets],
  );

  const selectAssets = useCallback(() => {
    if (state === 'backlog') {
      setActive(backlogFilter.filters != null);
      handleSetBacklogFilter(backlogFilter.filters, selectedAssets);
    } else {
      setActive(backlogTaskFilter.filters != null);
      handleSetBacklogTaskFilter(backlogTaskFilter.filters, selectedAssets);
    }
    onConfirm();
  }, [
    state,
    onConfirm,
    setActive,
    backlogFilter.filters,
    handleSetBacklogFilter,
    selectedAssets,
    backlogTaskFilter.filters,
    handleSetBacklogTaskFilter,
  ]);

  const siteAssets = useCallback(
    (site) => assetsBacklog.filter((asset) => asset?.site?.id === site.id),
    [assetsBacklog],
  );

  const onClear = useCallback(() => {
    setActive(false);
    // clear selected assets and filter text to reset
    setSelectedAssets([]);
    setFilterText('');

    if (state === 'backlog') {
      handleSetBacklogFilter(backlogFilter.filters, []);
    } else {
      handleSetBacklogTaskFilter(backlogTaskFilter.filters, []);
    }
  }, [
    backlogFilter.filters,
    backlogTaskFilter.filters,
    handleSetBacklogFilter,
    handleSetBacklogTaskFilter,
    setActive,
    state,
  ]);

  const dropdownSite = useCallback(
    ({ id }) =>
      expandedSites.includes(id)
        ? setExpandedSites((prevExpandedSites) => prevExpandedSites.filter((el) => el !== id))
        : setExpandedSites((prevExpandedSites) => [...prevExpandedSites, id]),
    [expandedSites, setExpandedSites],
  );

  const toggleCheckbox = useCallback(
    (e, assetId) => {
      killEventPropagation(e);
      return selectedAssets.includes(assetId)
        ? setSelectedAssets((prevselectedAssets) =>
            prevselectedAssets.filter((el) => el !== assetId),
          )
        : setSelectedAssets((prevselectedAssets) => [...prevselectedAssets, assetId]);
    },
    [selectedAssets, setSelectedAssets],
  );

  useMemo(() => {
    if (sitesBacklog.length === 1) {
      setExpandedSites([sitesBacklog[0].id]);
    } else if (filterText.length > 1) {
      const sitesToExpand = sitesBacklog.map((s) => s.id);
      setExpandedSites(sitesToExpand);
    } else {
      setExpandedSites([]);
    }
  }, [filterText.length, sitesBacklog]);

  const hasSelectedAssets = useCallback(
    (site) => siteAssets(site).filter((asset) => selectedAssets.includes(asset.id)).length > 0,
    [selectedAssets, siteAssets],
  );

  const renderAssets = useCallback(
    (assets) =>
      assets.sort(sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC)).map((asset) => (
        <AssetItem key={`${asset.id}`} onMouseDown={(e) => toggleCheckbox(e, asset.id)}>
          <AssetIcon />
          <AssetName>
            <BoldSearch text={asset.name} textBold={filterText} />
          </AssetName>
          <StyledCheckbox checkState={getCheckState(asset.id)} />
        </AssetItem>
      )),
    [filterText, getCheckState, toggleCheckbox],
  );

  const buildSite = useCallback(
    ({ index, measureRef }) => {
      const site = sitesBacklog[index];
      if (!site) return;

      return (
        <SiteItem key={`${site.id}`} onMouseDown={() => dropdownSite(site)} ref={measureRef}>
          <div>
            <CarotIcon className={expandedSites.includes(site.id) ? 'carot rotate' : 'carot'} />
            <SiteIcon />
            <SiteName>
              <BoldSearch text={site.name} textBold={filterText} />
            </SiteName>
            {hasSelectedAssets(site) && <CheckedIcon />}
            <Badge
              medium
              label={siteAssets(site).length.toString()}
              color={`${globalColors.slate2}`}
              className="badge"
            />
          </div>
          {expandedSites.includes(site.id) && <ul>{renderAssets(siteAssets(site))}</ul>}
        </SiteItem>
      );
    },
    [
      filterText,
      sitesBacklog,
      dropdownSite,
      expandedSites,
      hasSelectedAssets,
      renderAssets,
      siteAssets,
    ],
  );
  // Build the footer to pass into the Dialog component.
  const getFooter = useMemo(() => {
    return (
      <ResultsButtons>
        <CancelButton onMouseDown={onClear} className="view-btn">
          {t('clear', 'Clear')}
        </CancelButton>
        <span>
          {selectedAssets.length} {t('selected', 'Selected')}
        </span>
        <Button
          primary
          disabled={selectedAssets.length === 0}
          onClick={selectAssets}
          className="view-btn"
        >
          {t('apply', 'Apply')}
        </Button>
      </ResultsButtons>
    );
  }, [t, selectedAssets, selectAssets, onClear]);

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  return (
    <Dialog
      isOpen={true}
      footer={getFooter}
      onClose={onClose}
      onConfirm={onConfirm}
      icon={Icons.TURBINE}
      header={t('select_assets', 'Select Asset(s)')}
      contentWidth
      padContent={false}
    >
      <div className={className}>
        <div className="panel">
          <div className="header">
            <Filter
              text={filterText}
              onChange={(event) => setFilterText(event.target.value.toLowerCase())}
              placeholder={t('search', 'Search')}
              hideResultsOnBlur={false}
              onKeyDown={(e) => killEventPropagation(e)}
            />
          </div>
          <>
            <ResultsHeader>
              <ColType>{t('type', 'Type')}</ColType>
              <ColName>{t('name', 'Name')}</ColName>
              <ColAssets>{t('assets', 'Assets')}</ColAssets>
            </ResultsHeader>
            {isLoading ? (
              <Loading>Loading...</Loading>
            ) : (
              <Results>
                <ScrollingContainer ref={parentRef}>
                  <StyledUl paddingTop={paddingTop} paddingBottom={paddingBottom}>
                    {items?.map((item) => buildSite(item))}
                  </StyledUl>
                </ScrollingContainer>
              </Results>
            )}
          </>
        </div>
      </div>
    </Dialog>
  );
};

ViewSelectorAssetsComponent.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.instanceOf(Object).isRequired,
  assetsSelected: PropTypes.instanceOf(Array),
  assets: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  backlogData: PropTypes.instanceOf(Object),
  setActive: PropTypes.func,
  state: PropTypes.instanceOf(String),
};

ViewSelectorAssetsComponent.defaultProps = {
  className: null,
  onClose: () => null,
  onConfirm: () => null,
  setActive: () => null,
  assetsSelected: [],
  assets: [],
  backlogData: null,
  state: 'backlog',
};

const SiteIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.SITE,
  color: globalColors.slate4,
}))`
  margin-left: 10px;
  margin-top: 4px;
`;

const CarotIcon = styled(Icon).attrs(() => ({
  size: 8,
  icon: Icons.CARET,
  color: globalColors.slate4,
}))`
  margin-left: 10px;
`;

const AssetIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.TURBINE,
  color: globalColors.slate4,
}))`
  margin-left: 49px;
`;

const CheckedIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.CHECK,
  color: globalColors.slate4,
}))`
  margin-right: 6px;
  align-self: center;
`;

const ColHeader = styled.div`
  position: absolute;
  color: ${globalColors.grey4};
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
`;

const ColType = styled(ColHeader)`
  left: 40px;
`;

const ColName = styled(ColHeader)`
  left: 67px;
`;

const ColAssets = styled(ColHeader)`
  right: 0;
  margin-right: 20px;
`;

const Loading = styled.div`
  color: ${globalColors.grey7};
  font-weight: 300;
  font-family: 'Museo Sans';
  font-size: 10px;
  text-align: center;
  margin: 20px;
`;

const ListItem = styled.li`
  color: ${globalColors.grey7};
  font-weight: 300;
  font-family: 'Museo Sans';
  font-size: 10px;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
`;

const SiteItem = styled(ListItem)`
  > div {
    display: flex;
    padding: 2px 0;
    border-bottom: 1px solid ${globalColors.slate12};
    :hover {
      background-color: ${globalColors.teal4};
      border-bottom: 1px solid ${globalColors.teal7};
    }
  }
  > ul {
    padding: 0;
  }
  .carot {
    transition: transform 0.2s ease;
    padding: 6px;
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(0deg);
    }
  }
  .badge {
    margin-top: 4px;
    right: 0;
    margin-right: 15px;
  }
`;

const AssetItem = styled(ListItem)`
  padding: 6px 0;
  display: flex;
  border-bottom: 1px solid ${globalColors.slate12};
  :hover {
    background-color: ${globalColors.teal4};
    border-bottom: 1px solid ${globalColors.teal7};
  }
`;

const SiteName = styled.div`
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
  flex: 1;
`;

const AssetName = styled.div`
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledCheckbox = styled(Checkbox)`
  right: 6px;
  margin-right: 12px;
  position: absolute;
  input + div {
    width: 12px;
    height: 12px;
  }
  span {
    display: none;
  }
`;

const CancelButton = styled.button`
  color: ${globalColors.white};
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  margin-left: 10px;
`;

const Results = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  height: 450px;
  width: 100%;
`;

const ResultsHeader = styled.div`
  color: ${globalColors.grey5};
  font-size: 11px;
  font-weight: 700;
  margin: 5px 3px 5px 0;
  display: flex;
  min-height: 15px;
`;

const ResultsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${globalColors.slate2};
  font-size: 11px;
  font-weight: 700;
`;

const StyledUl = styled.ul`
  position: relative;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }
  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const StyledViewSelectorAssets = styled(ViewSelectorAssetsComponent)`
  display: flex;
  .panel {
    width: 400px;
    height: 500px;
    max-height: calc(75vh - 171px);
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    color: ${globalColors.grey1};
    z-index: ${elevations.P2};
    transition: false;
    > .header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px 6px 6px 11px;
      > .filter {
        position: relative;
        input {
          color: ${globalColors.white};
          box-sizing: border-box;
          border: 1px solid ${(props) => props.theme.input.borderNavbarInput};
          border-radius: 2px;
          background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
          width: calc(100% - 8px);
          padding: 4px;
          caret-color: ${globalColors.teal3};
          ::placeholder {
            font-size: 14px;
            font-style: italic;
          }
          :focus {
            outline: none;
          }
        }
        .search-icon {
          position: absolute;
          top: 5px;
          right: 15px;
        }
      }
    }
  }
`;

export const AssetDialog = withTheme(StyledViewSelectorAssets);
