import clone from 'ramda/src/clone';

import { mergeOptionsRight } from '@ge/util';

import { mapsChartOptions, ChartType, heatMapChartOptions } from './models';

const AXIS_TITLE_HEIGHT = 25;

// used for rounding corners on columns
const getBorderRadius = ({ borderRadius = 4, negative = false } = {}) => {
  const borderRadiusPx = `${borderRadius}px`;

  if (negative) {
    return {
      borderRadiusBottomLeft: borderRadiusPx,
      borderRadiusBottomRight: borderRadiusPx,
    };
  }

  return {
    borderRadiusTopLeft: borderRadiusPx,
    borderRadiusTopRight: borderRadiusPx,
  };
};

const getTypeOptions = (type) => {
  let options;

  switch (type) {
    case ChartType.HEAT_MAP:
      options = heatMapChartOptions;
      break;

    default:
      options = {};
  }

  // return a clone so we don't mutate the original options
  return clone(options);
};

const getThemeOptions = ({ colors: overrideColors, theme, type }) => {
  if (!theme) {
    return {};
  }

  const { charts } = theme;
  // TODO: colors currently are high contrast (level 1)
  const {
    axisTitleColor,
    colors: chartColors,
    gridLineColor,
    legendTitleColor,
    lineColor,
    selectedColor,
    tooltipBackgroundColor,
    tooltipColor,
    selectedPoint,
  } = charts;
  // use override colors if provided
  const { borderColor, color, colors: typeColors, negativeColor, stops, nullColor } =
    charts[type] || {};
  const colors = overrideColors || typeColors || chartColors;

  const axisOptions = {
    gridLineColor,
    labels: {
      style: {
        color: axisTitleColor,
      },
    },
    lineColor,
    tickColor: gridLineColor,
    title: {
      style: {
        color: axisTitleColor,
      },
    },
  };

  return {
    colors,
    colorAxis: {
      stops,
    },
    legend: {
      itemStyle: {
        // can define this separate from title color if needed
        color: legendTitleColor,
      },
      itemHoverStyle: {
        color: legendTitleColor,
      },
      title: {
        style: {
          color: legendTitleColor,
        },
      },
    },
    series: [
      {
        nullColor,
        borderColor,
        borderWidth: borderColor ? 1 : 0,
        color,
        negativeColor,
        states: {
          select: {
            color: selectedColor,
          },
        },
        marker: {
          radius: 2,
          states: {
            select: {
              radius: 4,
              lineWidth: 2,
              fillColor: selectedPoint.fillColor,
              lineColor: selectedPoint.lineColor,
            },
          },
        },
      },
    ],
    title: {
      style: {
        color: legendTitleColor,
      },
    },
    subtitle: {
      style: {
        color: legendTitleColor,
      },
    },
    tooltip: {
      backgroundColor: tooltipBackgroundColor,
      style: {
        color: tooltipColor,
      },
    },
    xAxis: {
      ...axisOptions,
    },
    yAxis: {
      ...axisOptions,
    },
  };
};

// options for stuff like h/w, axis labels, ticks, intervals, etc
const getPlotOptions = ({
  categories,
  height,
  legendTitle,
  xMin,
  xMax,
  max,
  min,
  negative,
  noDataLabel,
  inverted,
  // for side-by-side
  title,
  subTitle,
  boostThreshold,
  width,
  xAxisLabelFormatter,
  xAxisLabelHeight,
  xAxisTickInterval,
  xAxisTitle,
  xAxisLabelVisible,
  xAxisTickLength,
  xAxisTickWidth,
  xAxisMaxPadding,
  xShowEmpty,
  xAxisReversed,
  xAxisLabelsRotation,
  xAxisStaggerLines,
  xAxisAllowOverflow,
  yAxisLabelFormatter,
  yAxisTickInterval,
  yAxisTitle,
  yAxisOffset,
  yAxisTickLength,
  yAxisTickPosition,
  yShowEmpty,
  zoom,
  marginLeft,
  marginRight,
  marginTop,
  disableSideMargin,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingBottom,
  useUTC,
  endOnTick,
  grouped,
  tooltipCrosshairs,
}) => {
  const borderRadius = grouped ? {} : getBorderRadius({ negative });
  const marginBottom = xAxisLabelHeight;

  return {
    chart: {
      // if we have bottom margin to account for x axis labels and/or title, then bump up the height
      // so chart axes will line up across charts regardless of whether they have x axes labels
      height: height + marginBottom,
      // if margin is 0, then we bump up to 1 so it doesn't obscure the x-axis
      marginBottom: marginBottom === 0 ? 1 : marginBottom,

      // If margin is not disabled apply marginLeft based on yAxisOpposite
      // TODO: Need to handle this in a better way after understanding all graphs using this component
      marginLeft: !disableSideMargin ? 60 : marginLeft,

      // If margin is not disabled decide marginRight based on y axis title
      // we don't account for label height because y axis labels are inset
      // TODO: Need to handle this in a better way after understanding all graphs using this component.
      marginRight: !disableSideMargin && yAxisTitle ? AXIS_TITLE_HEIGHT : marginRight,
      marginTop,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      width,
      zoomType: zoom ? 'xy' : null,
      inverted,
    },
    lang: {
      // wrap data label in our own typography styling
      noData: `
        <div class='body-4'>
          ${noDataLabel}
        <div>
      `,
    },
    colorAxis: {
      min: 0,
      max: 100,
      labels: {
        format: '{value}%',
      },
    },
    legend: {
      title: {
        text: legendTitle,
      },
    },
    noData: {
      position: {
        y: -12,
      },
      useHTML: true,
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subTitle,
    },
    tooltip: {
      crosshairs: tooltipCrosshairs,
      useHTML: true,
      formatter: function() {
        return this.series?.tooltipOptions?.pointFormatter?.call(this.point);
      },
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        boostThreshold: boostThreshold,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function() {
                  return this.value.charAt(0);
                },
              },
            },
          },
        },
      ],
    },
    series: [
      {
        ...borderRadius,
        point: {
          events: {
            mouseOver: function() {
              if (this.series.halo) {
                this.series.halo
                  .attr({
                    class: 'highcharts-tracker',
                  })
                  .toFront();
              }
            },
          },
        },
      },
    ],
    xAxis: {
      categories,
      labels: {
        formatter: xAxisLabelFormatter
          ? function() {
              return xAxisLabelFormatter({
                axis: this.axis,
                chart: this.chart,
                index: this.pos,
                isFirst: this.isFirst,
                isLast: this.isLast,
                label: this.axis.defaultLabelFormatter.call(this),
                value: this.value,
              });
            }
          : null,
        enabled: xAxisLabelVisible,
        rotation: xAxisLabelsRotation,
        staggerLines: xAxisStaggerLines,
        overflow: xAxisAllowOverflow,
      },
      tickInterval: xAxisTickInterval,
      tickLength: xAxisTickLength,
      tickWidth: xAxisTickWidth,
      title: {
        text: xAxisTitle,
      },
      min: xMin,
      max: xMax,
      maxPadding: xAxisMaxPadding,
      showEmpty: xShowEmpty,
      reversed: xAxisReversed,
    },
    yAxis: {
      labels: {
        formatter() {
          if (this.value < 0) {
            // push labels for negative values below gridline
            // TODO: revisit if this is the best place to do this
            this.axis.options.labels.y = 12;
            this.axis.options.showFirstLabel = false;
          } else if (this.value === 0 && !negative) {
            this.axis.options.labels.y = -4;
          }

          return yAxisLabelFormatter
            ? yAxisLabelFormatter({
                axis: this.axis,
                chart: this.chart,
                index: this.pos,
                isFirst: this.isFirst,
                isLast: this.isLast,
                label: this.axis.defaultLabelFormatter.call(this),
                value: this.value,
              })
            : this.value;
        },
      },
      max,
      min,
      endOnTick,
      tickInterval: yAxisTickInterval,
      title: {
        text: yAxisTitle,
      },
      offset: yAxisOffset,
      tickLength: yAxisTickLength,
      tickPosition: yAxisTickPosition,
      showEmpty: yShowEmpty,
    },
    time: {
      useUTC: useUTC,
    },
  };
};

// options specific to individual series
const getSeriesOptions = ({ colors = [], series = [], type }) => {
  const options = {
    series: series.map(
      (
        {
          color,
          tooltipEnabled,
          tooltipHeader,
          tooltipPoint,
          tooltipPointFormatter,
          type,
          ...options
        },
        i,
      ) => {
        // grab type options for the series
        const { series: typeSeries = [] } = getTypeOptions(type);
        const typeOptions = typeSeries[0] || {};

        return {
          ...typeOptions,
          ...options,
          // this fixes a bug with colors "drifting" when toggling back and forth between charts
          color: color ?? colors[i],
          // only disable (suppress tooltips) if tooltipEnabled flag explicitly set to false
          // https://www.highcharts.com/forum/viewtopic.php?t=12837
          enableMouseTracking: tooltipEnabled !== false,
          tooltip: {
            headerFormat: tooltipHeader || '',
            pointFormat: tooltipPoint,
            pointFormatter: tooltipPointFormatter
              ? function() {
                  const { color, id, name, x, y, value, series } = this;

                  // can expand how much stuff we pass in here
                  return tooltipPointFormatter({
                    color,
                    id,
                    name,
                    x,
                    y,
                    value,
                    series,
                  });
                }
              : null,
          },
        };
      },
    ),
  };

  // there could be an issue if the top series has a value of 0 for a given category
  // and it shows the next series at the top with squared borders
  // I talked to dave le about this and he's okay with dealing with that situation if we encounter it
  if (type !== ChartType.ROSE) {
    const { borderRadiusTopLeft, borderRadiusTopRight } = getBorderRadius();
    const topSeries = options.series && options.series[0];

    if (topSeries) {
      topSeries.borderRadiusTopLeft = borderRadiusTopLeft;
      topSeries.borderRadiusTopRight = borderRadiusTopRight;
    }
  }

  return options;
};

// parses our chart options into the highcharts options to get the look and behavior we expect
export const mapsChartOptionsFactory = ({
  categories,
  colors,
  height,
  legendTitle,
  xMin,
  xMax,
  max,
  min,
  negative,
  noDataLabel,
  onClick,
  onSelect,
  onSelected,
  inverted,
  title,
  subTitle,
  series,
  theme,
  boostThreshold,
  type,
  width,
  xAxisLabelFormatter,
  xAxisLabelHeight,
  xAxisTickInterval,
  xAxisTitle,
  xAxisTitleHeight,
  xAxisLabelVisible,
  xAxisTickLength,
  xAxisTickWidth,
  xAxisMaxPadding,
  xShowEmpty,
  xAxisReversed,
  xAxisLabelsRotation,
  xAxisStaggerLines,
  xAxisAllowOverflow,
  yAxis,
  yAxisLabelFormatter,
  yAxisTickInterval,
  yAxisTitle,
  yAxisOffset,
  yAxisTickLength,
  yAxisTickPosition,
  yShowEmpty,
  zoom,
  marginLeft,
  marginRight,
  marginTop,
  disableSideMargin,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingBottom,
  useUTC,
  endOnTick,
  tooltip,
  grouped,
  tooltipCrosshairs,
} = {}) =>
  mergeOptionsRight(
    // generic -> specific
    mergeOptionsRight(mapsChartOptions, { tooltip }),
    getTypeOptions(type),
    getThemeOptions({ colors, theme, type }),
    getPlotOptions({
      categories,
      height,
      legendTitle,
      xMin,
      xMax,
      max,
      min,
      negative,
      noDataLabel,
      onClick,
      onSelect,
      onSelected,
      inverted,
      title,
      subTitle,
      theme,
      boostThreshold,
      width,
      xAxisLabelFormatter,
      xAxisLabelHeight,
      xAxisTickInterval,
      xAxisTitle,
      xAxisTitleHeight,
      xAxisLabelVisible,
      xAxisTickLength,
      xAxisTickWidth,
      xAxisMaxPadding,
      xShowEmpty,
      xAxisReversed,
      xAxisLabelsRotation,
      xAxisStaggerLines,
      xAxisAllowOverflow,
      yAxis,
      yAxisLabelFormatter,
      yAxisTickInterval,
      yAxisTitle,
      yAxisOffset,
      yAxisTickLength,
      yAxisTickPosition,
      yShowEmpty,
      zoom,
      marginLeft,
      marginRight,
      marginTop,
      disableSideMargin,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      useUTC,
      endOnTick,
      grouped,
      tooltipCrosshairs,
    }),
    getSeriesOptions({ colors, type, series }),
  );

// when updating an existing chart
// mainly for data updates
export const mapsChartOptionsUpdateFactory = ({ colors, series = [], theme, type }) =>
  mergeOptionsRight(
    type ? mergeOptionsRight(getTypeOptions(type), getThemeOptions({ colors, theme, type })) : {},
    getSeriesOptions({ series }),
  );
