import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const LowestPerformerContainer = styled.div`
  margin-left: auto;
  margin-top: -35px;
  padding: 10px 0 10px 15px;
  border-left: 2px solid ${(props) => props.theme.analyze.manualAdjustment.bordersColor};
`;

const LowestPerformer = styled.p`
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const LowestPerformerTitle = styled.p`
  margin: 0 auto 4px;
  width: 50%;
  text-align: center;
`;

const SitesIcons = styled(Icon).attrs((props) => ({
  size: props.size || 22,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))`
  margin-right: 4px;
`;

export const LowestPerformerHeader = ({ entity }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  if (!ready || !entity) return null;

  return (
    <LowestPerformerContainer>
      <LowestPerformerTitle>{t('lowest_performer', 'Lowest Performer')}</LowestPerformerTitle>
      <div>
        <LowestPerformer>
          <SitesIcons icon={Icons.SITE} />
          {entity.name}
        </LowestPerformer>
      </div>
    </LowestPerformerContainer>
  );
};

LowestPerformerHeader.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string,
  }),
};
