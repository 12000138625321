import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { MultiPathIcons, Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import useStateRef from '@ge/hooks/state-ref';
import useModalDismissListeners from '@ge/hooks/use-dismiss-listeners';
import { Placeholders } from '@ge/models/constants';
import { getViewSiteCount } from '@ge/shared/util/view-utils';
import { elevations, globalColors } from '@ge/tokens';

import { Theme } from '../../models/theme';

import Filter from './filter';

const Tabs = {
  CUSTOM: 'CUSTOM',
  ROC_STATIONS: 'ROC_STATIONS',
  REGIONS: 'REGIONS',
  SITES: 'SITES',
  ASSETS: 'ASSETS',
  CUSTOMERS: 'CUSTOMERS',
  SERVICE_GROUPS: 'SERVICE_GROUPS',
};
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;
const DialogWrapper = styled(Dialog)`
  width: 380px;
`;
const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const Container = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  border-right: 1px solid
    ${(props) => props.theme.themeSelector.viewSelector.iconContainerBackgroundColor};
  padding-right: 15px;
`;

const SiteIconContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  border-right: 1px solid
    ${(props) => props.theme.themeSelector.viewSelector.iconContainerBackgroundColor};
  padding: 0px 15px;
  svg {
    padding-top: 2px;
  }
`;

const SiteCountContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  span {
    padding-right: 13px;
  }
`;

// Temporarily hiding custom views until functional
// const SAVE_CUSTOM_VIEWS = false;

const ViewSelectorComponent = ({ className, onClose, ...props }) => {
  const {
    theme: { themeSelector },
  } = props;
  // const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTabState] = useState(Tabs.SITES);
  const [customView, setCustomView, customViewRef] = useStateRef(null);
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation(['nav', 'general']);

  const componentRef = useRef(null);

  const { currentView, viewTurbineCount, viewInverterCount } = useStoreState((state) => state.view);
  const { fetchSavedViews, saveViewPrefs } = useStoreActions((actions) => actions.view);

  const { getCustomerMap, getServiceGroupSites, sitesByRegion, sortedServiceGroups, sites } =
    useStoreState((state) => state.sites);
  const { sortedRegions } = useStoreState((state) => state.regions);
  const { rocStations, sitesLength } = useStoreState((state) => state.rocStations);
  const { expandGroup } = useStoreActions((actions) => actions.rocStations);

  const { getThemeJs } = useStoreState((state) => state.prefs);
  const lightTheme = getThemeJs(Theme.LIGHT);
  const allSitesSorted = useMemo(
    () => Object.values(sites).sort((a, b) => a.name?.localeCompare(b.name)),
    [sites],
  );

  // changes as per new UX for view selector pop up warning when any site preference is changed by user

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  /**
   * Apply the custom view configuration globally and close the view selector panel.
   */
  const applyView = useCallback(() => {
    // save current view
    saveViewPrefs(customViewRef.current);
    setShowCancelConfirmation(false);
    onClose();
  }, [onClose, saveViewPrefs, customViewRef]);

  const handleCancelClose = useCallback(() => {
    setShowCancelConfirmation(false);
    onClose();
  }, [setShowCancelConfirmation, onClose]);

  const getCancelFooter = useMemo(() => {
    return (
      <FooterWrapper>
        <FooterButtons>
          <Button onClick={handleCancelClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={applyView}>
            {t('general:apply', 'Apply')}
          </Button>
        </FooterButtons>
      </FooterWrapper>
    );
  }, [handleCancelClose, applyView, t]);

  const assetCount = useMemo(() => viewTurbineCount(customView));
  const inverterCount = useMemo(() => viewInverterCount(customView));

  const siteCount = useMemo(() => {
    const selectedSites = customView ? customView.sites : currentView.sites;
    return getViewSiteCount(selectedSites, allSitesSorted);
  }, [currentView.sites, customView, allSitesSorted]);

  const customerMap = getCustomerMap();
  const sortedCustomers = useMemo(
    () => Array.from(customerMap.values()).sort((a, b) => a.name?.localeCompare(b.name)),
    [customerMap],
  );

  // Open with the initial local state aligned with global state.
  useEffect(() => {
    setCustomView(currentView);
  }, [currentView, setCustomView]);

  // Trigger required loads for view selector.
  useEffect(() => {
    fetchSavedViews();

    // clear any filters
    setFilterText('');

    // clear saving state
    // setIsSaving(false);
    // }, [fetchSavedViews, setFilterText, setIsSaving]);
  }, [fetchSavedViews, setFilterText]);

  /**
   * Run through state checks before closing modal.
   */
  const triggerCloseViewPanel = () => {
    const stateChanged = !equals(customViewRef.current, currentView);

    if (!stateChanged) {
      setShowCancelConfirmation(false);
      onClose();
      return;
    }

    setShowCancelConfirmation(true);
  };

  // Set up the click / escape listeners for the overlay
  useModalDismissListeners(componentRef, triggerCloseViewPanel);

  /**
   * Remove all of the provided Site IDs from the provided array of Site IDs.
   *
   * @param {Number[]} toRemoveArray Site IDs to remove from the full array
   * @param {Number[]} sitesArray All site IDs in current custom view
   */
  const removeSitesFromSitesArray = (toRemoveArray, sitesArray) =>
    sitesArray
      .filter((site) => !toRemoveArray.map((x) => x.id).includes(site.id))
      .concat(
        toRemoveArray
          .filter((x) => !sitesArray.map((site) => site.id).includes(x.id))
          .map((site) => ({ id: site.id })),
      );

  /**
   * Add the provided Site IDs to the provided array of Site IDs.
   *
   * @param {Number[]} toAddArray Site IDs to add to the full array
   * @param {Number[]} sitesArray All site IDs in current custom view
   */
  const addSitesToSitesArray = (toAddArray, sitesArray) =>
    sitesArray.concat(
      toAddArray
        .filter((x) => !sitesArray.map((site) => site.id).includes(x.id))
        .map((site) => ({ id: site.id, totalAssets: site.totalAssets })),
    );

  /**
   * Determine if all of the provided sites are listed in the array of
   * IDs provided.
   *
   * @param {Site[]} arr1 Sites to check against a list of selected sites
   * @param {Number[]} arr2 Array of selected sites
   */
  const allSitesSelected = (arr1, arr2) =>
    arr1.every((elem) => {
      const site = arr2.find((x) => elem.id === x.id);
      return typeof site !== 'undefined';
    });

  /**
   * Determine if some of the provided sites are listed in the array of
   * IDs provided.
   *
   * @param {Site[]} arr1 Sites to check against a list of selected sites
   * @param {Number[]} arr2 Array of selected sites
   */
  const someSitesSelected = (arr1, arr2) =>
    arr1.some((elem) => {
      const site = arr2.find((x) => elem.id === x.id);
      return typeof site !== 'undefined';
    });

  /**
   * Deterimine what the checked state of a parent checkbox should be given the
   *
   * @param {Site[]} sitesArray Array of Sites to check against an array of site IDs
   * @param {Number[]} siteIdArray Array of site IDs to be checked against
   */
  const getCheckState = (sitesArray, siteIdArray) => {
    if (someSitesSelected(sitesArray, siteIdArray) && !allSitesSelected(sitesArray, siteIdArray)) {
      return CheckedState.INDETERMINATE;
    }

    if (allSitesSelected(sitesArray, siteIdArray)) {
      return CheckedState.CHECKED;
    }

    return CheckedState.UNCHECKED;
  };

  /**
   * Get value to filter on by tab type.
   * @param {Site} site Site to get value from.
   */

  const getValueByTabType = useCallback(
    (site) => {
      switch (activeTab) {
        case Tabs.CUSTOMERS:
          return site?.customer?.name;
        case Tabs.REGIONS:
          return sortedRegions.find((region) => region?.id === site?.region?.id)?.name;
        default:
          return site?.name;
      }
    },
    [activeTab, sortedRegions],
  );

  /**
   * Filter sites array down by active tab.
   * @param {Site[]} arr Sites to filter.
   * @param {Boolean} filterByParentType Indicates if the partent type(active tab type) should drive the value of what is filtered.
   */

  const getFilteredSites = useCallback(
    (arr, filterByParentType = false) => {
      if (filterByParentType && activeTab === Tabs.SERVICE_GROUPS) {
        return arr;
      }
      return filterText
        ? arr.filter((site) => {
            const searchValue = filterByParentType ? getValueByTabType(site) : site?.name;
            return (
              searchValue
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(filterText) || searchValue?.toLowerCase().includes(filterText)
            );
          })
        : arr;
    },
    [filterText, activeTab, getValueByTabType],
  );

  /**
   * Toggle the sites selected in the custom view. If all provided `Site`s
   * are already included, remove them all. If some or none of them are
   * included, add them all.
   *
   * @param {Site[]} sitesArray Sites to toggle in the view state
   */

  const toggleSites = useCallback(
    (sitesArray) => {
      const filteredSites = getFilteredSites(sitesArray, true); //filtered particular item
      const all = allSitesSelected(filteredSites, customView.sites); //checker if we checked return false for all, if we did't find filtered item in custom view return false
      const theCustomView = { ...customView };

      if (all) {
        theCustomView.sites = removeSitesFromSitesArray(filteredSites, customView.sites); //1 param marked items,2 all sites
      } else {
        theCustomView.sites = addSitesToSitesArray(filteredSites, customView.sites);
      }

      setCustomView(theCustomView);
    },
    [customView, getFilteredSites, setCustomView],
  );

  /**
   * Update the current view to add or remove the sites associated with the provided Customer.
   *
   * @param {Customer} customer Customer to toggle
   */
  const toggleCustomerInView = (customerId) => {
    toggleSites(customerMap.get(customerId)?.sites ?? []);
  };

  /**
   * Update the current view to add or remove the sites associated with the provided Service Group.
   *
   * @param {ServiceGroup} serviceGroup ServiceGroup to toggle
   */
  const toggleServiceGroupInView = (serviceGroup) => {
    toggleSites(getServiceGroupSites(serviceGroup));
  };

  /**
   * Update the current view to add or remove the sites associated with the provided Region
   * .
   * @param {Region} region Region to toggle
   */
  const toggleRegionInView = (regionId) => {
    toggleSites(sitesByRegion.get(regionId));
  };

  /**
   * Update the current view to add or remove the sites associated with the provided ROCStation
   * .
   * @param {rocStationId} rocStation  to toggle
   */
  const toggleRocStationsInView = (currentRockStationSites) => {
    toggleSites(currentRockStationSites);
  };

  const toggleAllSites = (group) => {
    const filteredSites = getFilteredSites(allSitesSorted, true);
    const isAllSitesSelected = customView?.sites
      ? allSitesSelected(filteredSites, customView.sites)
      : false;
    const Element = group ? 'div' : 'li';
    const checkState = isAllSitesSelected ? CheckedState.CHECKED : CheckedState.UNCHECKED;

    return (
      <Element key="select-all" className={`select-all ${isAllSitesSelected ? 'active' : ''}`}>
        <Checkbox checkState={checkState} onChange={() => toggleSites(allSitesSorted)} />
        <button type="button" onClick={() => toggleSites(allSitesSorted)}>
          {t('view_selector.select_all', 'Select All')}
        </button>
        <Badge
          medium
          label={filteredSites?.length.toString()}
          color={themeSelector.badgeColor}
          className="badge"
        />
      </Element>
    );
  };

  const toggleAllSitesForRockStation = (group) => {
    const filteredSites = getFilteredSites(allSitesSorted, true);
    const isAllSitesSelected = customView?.sites
      ? allSitesSelected(filteredSites, customView.sites)
      : false;
    const Element = group ? 'div' : 'li';
    const checkState = isAllSitesSelected ? CheckedState.CHECKED : CheckedState.UNCHECKED;

    return (
      <Element key="select-all" className={`select-all ${isAllSitesSelected ? 'active' : ''}`}>
        <Checkbox checkState={checkState} onChange={() => toggleSites(allSitesSorted)} />
        <button type="button" onClick={() => toggleSites(allSitesSorted)}>
          {t('view_selector.select_all', 'Select All')}
        </button>
        <Badge
          medium
          label={sitesLength.toString()}
          color={themeSelector.badgeColor}
          className="badge"
        />
      </Element>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderSite = ({ site, ref, isOdd }) => {
    const oddClass = isOdd ? 'odd' : '';
    return (
      <li
        ref={ref}
        // eslint-disable-next-line react/prop-types
        key={`${site.id}`}
        className={
          // eslint-disable-next-line react/prop-types
          customView?.sites.map((x) => x.id).includes(site.id) ? `active ${oddClass}` : oddClass
        }
      >
        <Checkbox
          checkState={
            // eslint-disable-next-line react/prop-types
            customView?.sites.map((x) => x.id).includes(site.id)
              ? CheckedState.CHECKED
              : CheckedState.UNCHECKED
          }
          onChange={() => toggleSites([site])}
        />
        <button type="button" onClick={() => toggleSites([site])}>
          <SiteIcon />
          {
            // eslint-disable-next-line react/prop-types
            site.name
          }
        </button>
      </li>
    );
  };

  /**
   * Render an array of `Site` objects to `SiteListItem`s
   *
   * @param {Site[]} sitesArray Site array to render as `SiteListItem`s
   */
  const renderAllSites = (sitesArray) =>
    sitesArray
      .sort((a, b) => a.name?.localeCompare(b.name))
      .reduce((arr, site, idx) => {
        arr.push(renderSite({ site, isOdd: !(idx % 2) }));
        return arr;
      }, []);

  const renderSitesVirtualized = (virtualItems) => {
    const filteredSites = getFilteredSites(allSitesSorted) || [];
    const items = virtualItems.reduce((arr, { index, measureRef }) => {
      const site = filteredSites[index];
      if (filteredSites.length > index) {
        arr.push(renderSite({ site, ref: measureRef, isOdd: Boolean(index % 2) }));
      }
      return arr;
    }, []);

    return (
      <StyledUl noPadding>
        {toggleAllSites()}
        {items}
      </StyledUl>
    );
  };

  /**
   * Render a grouping of sites assocated with a parent entity. Genericized to handle
   * both Customer, ServiceGroup, and Region groupings.
   *
   * @param {Customer | ServiceGroup | Region} parentEntity Entity to associate with the array of sites
   * @param {Site[]} sitesArray Sites to render under the parentEntity
   * @param {Function} headerClickFn Callback Fn to handle a click on the group header
   */

  const renderGroupWithSites = (parentEntity, sitesArray, groupIcon, headerClickFn, ref, isOdd) => {
    if (!sitesArray?.length || !parentEntity.name?.toLowerCase().includes(filterText)) {
      return null;
    }

    const className = isOdd ? 'group odd' : 'group';
    return (
      <div ref={ref} key={`${parentEntity.id}`} className={className}>
        <CollapsiblePanel
          isActiveTitle={allSitesSelected(sitesArray, customView.sites)}
          headerContent={
            <SectionHeader>
              <CheckboxContainer onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checkState={getCheckState(sitesArray, customView.sites)}
                  onChange={headerClickFn}
                />
              </CheckboxContainer>
              <GroupIcon
                icon={groupIcon}
                color={
                  someSitesSelected(sitesArray, customView.sites)
                    ? themeSelector.someSitesSelectedIconColor
                    : globalColors.slate4
                }
              />
              <span>{parentEntity.name}</span>
              <Badge
                medium
                label={sitesArray?.length.toString()}
                color={themeSelector.badgeColor}
                className="badge"
              />
            </SectionHeader>
          }
        >
          <StyledUl className={customView.active && 'opened'}>
            {renderAllSites(sitesArray)}
          </StyledUl>
        </CollapsiblePanel>
      </div>
    );
  };

  const renderAllSitesCustom = (sitesArray) => {
    return sitesArray
      .sort((a, b) => a.name?.localeCompare(b.name))
      .reduce((arr, site, idx) => {
        arr.push(renderSite({ site, isOdd: !(idx % 2) }));
        return arr;
      }, []);
  };

  // eslint-disable-next-line react/prop-types

  /**
   * Render an unordered list of sites with a customer header and associated listeners
   * for updating the selected view state.
   */

  const renderSitesByCustomer = (virtualItems) => {
    const filteredCustomers = getFilteredSites(sortedCustomers);
    return virtualItems.reduce((arr, { index, measureRef }) => {
      const customer = filteredCustomers[index];
      const customerSites = customer.sites;
      return [
        ...arr,
        renderGroupWithSites(
          customer,
          customerSites,
          Icons.PERSON,
          () => toggleCustomerInView(customer.id),
          measureRef,
          Boolean(index % 2),
        ),
      ];
    }, new Array(toggleAllSites(true)));
  };

  /**
   * Render an unordered list of sites with a service group header and associated listeners
   * for updating the selected view state.
   */
  const renderSitesByServiceGroup = (virtualItems) => {
    const filteredGroups = getFilteredSites(sortedServiceGroups);
    return virtualItems.reduce((arr, { index, measureRef }) => {
      const serviceGroup = filteredGroups[index];
      const serviceGroupSites = [...getServiceGroupSites(serviceGroup).values()].flat();
      return [
        ...arr,
        renderGroupWithSites(
          serviceGroup,
          serviceGroupSites,
          Icons.SERVICE_GROUP,
          () => toggleServiceGroupInView(serviceGroup),
          measureRef,
          Boolean(index % 2),
        ),
      ];
    }, new Array(toggleAllSites(true)));
  };

  /**
   * get Region by Sites
   */
  const getRegionSitesByRegionId = () => {
    return Object.values(sites).reduce((map, site) => {
      map.set(site.region?.id, [site, ...(map.get(site.region?.id) || [])]);
      return map;
    }, new Map());
  };

  /**
   * Render an unordereed list of sites with a region header and associated listeners
   * for updating the selected view state.
   */
  const renderSitesByRegion = (virtualItems) => {
    let regionSites;
    const filteredRegions = getFilteredSites(sortedRegions);
    return virtualItems.reduce((arr, { index, measureRef }) => {
      const region = filteredRegions[index];
      if (sitesByRegion.size === 0) {
        const getRgionSites = getRegionSitesByRegionId();
        regionSites = getRgionSites.get(region.id);
      } else {
        regionSites = sitesByRegion.get(region.id);
      }
      return [
        ...arr,
        renderGroupWithSites(
          region,
          regionSites,
          Icons.FLEET,
          () => toggleRegionInView(region.id),
          measureRef,
          Boolean(index % 2),
        ),
      ];
    }, new Array(toggleAllSites(true)));
  };

  /**
   * Render a list of existing saved custom views.
   */

  /**
   * Set active tab.
   */
  const setTab = (tab) => {
    setActiveTabState(tab);
    setFilterText('');
  };

  /**
   * Show or hide the custom view save inputs.
   */
  // const toggleCustomViewSave = () => {
  //   if (isSaving) {
  //     setIsSaving(false);
  //   } else {
  //     setIsSaving(true);
  //   }
  // };

  /**
   * Show or hide the custom view save inputs.
   */
  // const saveCustomView = () => {
  //   // TODO: save custom view
  //   setTab('CUSTOM');
  //   toggleCustomViewSave();
  // };

  const customerCount = useMemo(() => {
    let count = 0;
    const selectedSites = customView?.sites ?? [];

    if (selectedSites.length) {
      customerMap.forEach(({ sites }) => {
        if (someSitesSelected(sites, selectedSites)) count += 1;
      });
    }

    return count;
  }, [customView, customerMap]);

  const RenderGroupWithSitesCustomRockStation = ({
    rocStationItem,
    sitesArray,
    groupIcon,
    headerClickFn,
    isOdd,
  }) => {
    if (!sitesArray?.length || !rocStationItem?.siteGroupName.toLowerCase().includes(filterText)) {
      // when we don't have an item
      return null;
    }

    const checkExpanded = (rocStationItem) => {
      if (rocStationItem?.expanded && rocStationItem?.expanded === true) {
        return true;
      } else {
        return false;
      }
    };

    const className = isOdd ? 'group odd' : 'group';
    return (
      <div key={rocStationItem.siteGroupId} className={className}>
        <CollapsiblePanel
          isActiveTitle={allSitesSelected(sitesArray, customView.sites)}
          expanded={checkExpanded(rocStationItem)}
          onToggleExpand={(state) => expandGroup({ state: state, item: rocStationItem })}
          headerContent={
            <SectionHeader>
              <CheckboxContainer onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checkState={getCheckState(sitesArray, customView.sites)}
                  onChange={headerClickFn}
                />
              </CheckboxContainer>
              <GroupIcon icon={groupIcon} color={globalColors.grey6} />
              <span>{rocStationItem.siteGroupName}</span>
              <Badge
                medium
                label={sitesArray?.length.toString()}
                color={themeSelector.badgeColor}
                className="badge"
              />
            </SectionHeader>
          }
        >
          <StyledUl className={customView.active && 'opened'}>
            {renderAllSitesCustom(sitesArray)}
          </StyledUl>
        </CollapsiblePanel>
      </div>
    );
  };

  RenderGroupWithSitesCustomRockStation.propTypes = {
    rocStationItem: PropTypes.object.isRequired,
    sitesArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    groupIcon: PropTypes.string.isRequired,
    headerClickFn: PropTypes.func.isRequired,
    isOdd: PropTypes.bool.isRequired,
  };

  const RenderSitesByRocStations = () => {
    const Header = () => {
      return toggleAllSitesForRockStation(true);
    };

    const list = rocStations.map((item, index) => {
      return (
        <RenderGroupWithSitesCustomRockStation
          key={index}
          rocStationItem={item}
          sitesArray={item.attributes[0].schema}
          groupIcon={Icons.CUSTOM_VIEW}
          headerClickFn={() => toggleRocStationsInView(item.attributes[0].schema)}
          isOdd={Boolean(index % 2)}
        />
      );
    });

    const content = (
      <>
        <Header />
        {list}
      </>
    );

    return content;
  };
  //11
  const MainRockStationRender = () => {
    return (
      <div className="panel">
        <div className="header">
          <h4>
            {t('view_selector.select', 'select')}
            &nbsp;
            {t('view_selector.roc_stations', 'ROC Stations')}
          </h4>
        </div>
        <ScrollingContainer theme={lightTheme}>
          <StyledVirtualResults paddingTop={0} paddingBottom={20}>
            <RenderSitesByRocStations />
          </StyledVirtualResults>
        </ScrollingContainer>
      </div>
    );
  };

  return createPortal(
    <>
      <div ref={componentRef} className={className}>
        <div className="view-modal active">
          <div className="view-header">
            <div className="selected-header">
              <h4>{t('view_selector.your_view_selections', 'Your view selections')}:</h4>
              <div className="selected-counts">
                <Container>
                  <Icon icon={Icons.PERSON} size={12} color={themeSelector.tabsIconColor} />
                  <span>{customerCount ?? Placeholders.DOUBLE_DASH}</span>
                </Container>
                <SiteIconContainer>
                  <MultiPathIcons
                    icon={[Icons.NEW_SITE1, Icons.NEW_SITE2, Icons.NEW_SITE3]}
                    width={15}
                    height={11}
                    viewbox={'0 0 16 12'}
                    transform={
                      'translate(2.381733, 4.123282) scale(-1, 1) translate(-2.381733, -4.123282)'
                    }
                    color={themeSelector.tabsIconColor}
                  />
                  <span>{siteCount ?? Placeholders.DOUBLE_DASH}</span>
                </SiteIconContainer>
                <SiteCountContainer>
                  {(assetCount !== null || assetCount !== undefined) && (
                    <div>
                      <Icon icon={Icons.TURBINE} size={10} color={themeSelector.tabsIconColor} />
                      <span>{assetCount ?? Placeholders.DOUBLE_DASH}</span>
                    </div>
                  )}
                  {(inverterCount !== null || inverterCount !== undefined) && (
                    <div>
                      <Icon icon={Icons.BATTERY} size={10} color={themeSelector.tabsIconColor} />
                      <span>{inverterCount ?? Placeholders.DOUBLE_DASH}</span>
                    </div>
                  )}
                </SiteCountContainer>
              </div>
            </div>
            {/* {SAVE_CUSTOM_VIEWS && (
              <div className="save-custom-view">
                <div className={`save-view ${isSaving && 'active'}`}>
                  <h4>{t('view_selector.name_view', 'NAME THIS VIEW')}:</h4>
                  <div className="form">
                    <input type="text" />
                    <Button primary type="button" onClick={saveCustomView}>
                      {t('view_selector.save', 'Save')}
                    </Button>
                  </div>
                </div>
                {!isSaving ? (
                  <button onClick={toggleCustomViewSave} type="button" className="save-custom-btn">
                    {t('view_selector.save_custom_view', 'Save Custom View')}
                  </button>
                ) : null}
              </div>
            )} */}
          </div>
          <div className="view-tabs-wrapper">
            <ScrollingContainer relative>
              <div className="view-tabs">
                <h4>{t('view_selector.browse', 'Browse By')}</h4>
                <ul>
                  <li className={activeTab === Tabs.SITES ? 'active' : ''}>
                    <button
                      onClick={() => {
                        setTab(Tabs.SITES);
                      }}
                      type="button"
                    >
                      <MultiPathIcons
                        icon={[Icons.NEW_SITE1, Icons.NEW_SITE2, Icons.NEW_SITE3]}
                        width={30}
                        height={28}
                        viewbox={'0 0 16 12'}
                        transform={
                          'translate(2.381733, 4.123282) scale(-1, 1) translate(-2.381733, -4.123282)'
                        }
                        color={themeSelector.tabsIconColor}
                      />
                      <span>{t('view_selector.sites', 'Sites')}</span>
                    </button>
                  </li>
                  <li className={activeTab === Tabs.SERVICE_GROUPS ? 'active' : ''}>
                    <button
                      onClick={() => {
                        setTab(Tabs.SERVICE_GROUPS);
                      }}
                      type="button"
                    >
                      <Icon
                        icon={Icons.SERVICE_GROUP}
                        color={themeSelector.tabsIconColor}
                        size={28}
                      />
                      <span>{t('view_selector.service_groups', 'Service Groups')}</span>
                    </button>
                  </li>
                  <li className={activeTab === Tabs.REGIONS ? 'active' : ''}>
                    <button
                      onClick={() => {
                        setTab(Tabs.REGIONS);
                      }}
                      type="button"
                    >
                      <Icon icon={Icons.FLEET} color={themeSelector.tabsIconColor} size={28} />
                      <span>{t('view_selector.regions', 'Regions')}</span>
                    </button>
                  </li>
                  <li className={activeTab === Tabs.CUSTOMERS ? 'active' : ''}>
                    <button
                      onClick={() => {
                        setTab(Tabs.CUSTOMERS);
                      }}
                      type="button"
                    >
                      <Icon icon={Icons.PERSON} color={themeSelector.tabsIconColor} size={28} />
                      <span>{t('view_selector.customers', 'Customers')}</span>
                    </button>
                  </li>
                  {/* <li className={activeTab === Tabs.ASSETS ? 'active' : ''}>
                    <button
                      onClick={() => {
                        setTab(Tabs.ASSETS);
                      }}
                      type="button"
                    >
                      <Icon icon={Icons.TURBINE} color={themeSelector.tabsIconColor} size={28} />
                      <span>{t('view_selector.assets', 'assets')}</span>
                    </button>
                  </li> */}
                  <li className={activeTab === Tabs.ROC_STATIONS ? 'active' : ''}>
                    <button
                      onClick={() => {
                        setTab(Tabs.ROC_STATIONS);
                      }}
                      type="button"
                    >
                      <Icon
                        icon={Icons.CUSTOM_VIEW}
                        color={themeSelector.tabsIconColor}
                        size={32}
                        className="custom-view-icon"
                      />
                      <span>{t('view_selector.roc_stations', 'ROC Stations')}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </ScrollingContainer>

            {/* {activeTab === Tabs.CUSTOM && (
              <div className="panel">
                <div className="header">
                  <h4>
                    {t('view_selector.select', 'select')}
                    &nbsp;
                    {t('view_selector.custom', 'Custom Views')}
                  </h4>
                  <Filter
                    text={filterText}
                    onChange={(event) => setFilterText(event.target.value.toLowerCase())}
                  />
                </div>
                <ScrollingContainer theme={lightTheme}>
                  <StyledUl noPadding>{renderCustomViews()}testing</StyledUl>
                </ScrollingContainer>
              </div>
            )} */}

            {
              //21
              activeTab === Tabs.ROC_STATIONS && <MainRockStationRender />
            }

            {activeTab === Tabs.CUSTOMERS && (
              <EntityContent
                header={t('view_selector.customers', 'Customers')}
                entities={getFilteredSites(sortedCustomers)}
                renderFn={renderSitesByCustomer}
                theme={lightTheme}
                filterText={filterText}
                setFilterText={setFilterText}
              />
            )}

            {activeTab === Tabs.SERVICE_GROUPS && (
              <EntityContent
                header={t('view_selector.service_groups', 'Service Groups')}
                entities={getFilteredSites(sortedServiceGroups)}
                renderFn={renderSitesByServiceGroup}
                theme={lightTheme}
                filterText={filterText}
                setFilterText={setFilterText}
              />
            )}

            {activeTab === Tabs.REGIONS && (
              <EntityContent
                header={t('view_selector.regions', 'Regions')}
                entities={getFilteredSites(sortedRegions)}
                renderFn={renderSitesByRegion}
                theme={lightTheme}
                filterText={filterText}
                setFilterText={setFilterText}
              />
            )}

            {activeTab === Tabs.SITES && (
              <EntityContent
                header={t('view_selector.sites', 'Sites')}
                entities={getFilteredSites(allSitesSorted)}
                renderFn={renderSitesVirtualized}
                theme={lightTheme}
                filterText={filterText}
                setFilterText={setFilterText}
              />
            )}

            {activeTab === Tabs.ASSETS && (
              <div className="panel">
                <div className="header">
                  <h4>
                    {t('view_selector.select', 'select')}
                    &nbsp;
                    {t('view_selector.assets', 'Assets')}
                  </h4>
                  <Filter
                    text={filterText}
                    onChange={(event) => setFilterText(event.target.value.toLowerCase())}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="tabs-footer">
            <Button onClick={triggerCloseViewPanel} type="button">
              {t('general:cancel', 'Cancel')}
            </Button>
            <Button primary onClick={applyView} type="button">
              {t('view_selector.apply_custom_view', 'Apply to View')}
            </Button>
          </div>
        </div>
      </div>

      {showCancelConfirmation && (
        <DialogWrapper
          isOpen={true}
          onClose={handleCancelClose}
          header={t('general:confirmation', 'Confirmation')}
          footer={getCancelFooter}
          contentWidth
          padContent={true}
        >
          <p className="kpi-5">
            {t(
              'view_selector.sure_to_apply',
              'Do you want to apply these selections to your current view?',
            )}
          </p>
        </DialogWrapper>
      )}
    </>,
    document.querySelector('#portal-root'),
  );
};

ViewSelectorComponent.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  theme: PropTypes.instanceOf(Object).isRequired,
};

ViewSelectorComponent.defaultProps = {
  className: null,
  onClose: () => null,
};

const SiteIcon = styled(Icon).attrs((props) => ({
  size: 16,
  icon: Icons.SITE,
  color: props.theme.themeSelector.siteIconColor,
}))`
  margin-right: 3px;
  margin-top: -3px;
`;

const GroupIcon = styled(Icon).attrs(() => ({
  size: 16,
}))`
  margin-right: 3px;
`;

const SectionHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 8px;

  span {
    margin-left: 8px;
    font-size: 14px;
    flex: 1;
  }

  label {
    margin-top: 2px;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
`;

const StyledUl = styled.ul`
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  li {
    padding: 5px 20px;
    font-weight: 500;
    font-size: 14px;
    display: block;
    padding-left: ${(props) => (props.noPadding ? '0px' : '40px')};
    display: flex;
    background: ${(props) => props.theme.themeSelector.listBackgroundColor};
    border-bottom: 1px solid ${(props) => props.theme.themeSelector.listBorderColor};
    &.odd {
      background: ${(props) => props.theme.themeSelector.listBackgroundColorOdd};
    }
    button {
      flex: 1;
      text-align: left;
      font-size: 14px;
      line-height: 14px;
      color: ${(props) => props.theme.themeSelector.listButtonColor};
      padding-top: 2px;
      padding-bottom: 1px;
    }
    label {
      margin-left: 15px;
      span {
        margin: 0;
      }
    }
    &.active {
      background: ${(props) => props.theme.themeSelector.listBackgroundActiveColor};
    }
  }
`;

const StyledViewSelector = styled(ViewSelectorComponent)`
  display: flex;
  .view-modal {
    position: absolute;
    top: 45px;
    right: 270px;
    width: 475px;
    height: 800px;
    background: white;
    overflow: hidden;
    z-index: ${elevations.P100};
    max-height: 0;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    box-shadow: ${(props) => props.theme.themeSelector.modal.boxShadow};
    display: flex;
    flex-direction: column;
    &.active {
      opacity: 1;
      max-height: 85vh;
    }
    .view-header {
      height: 47px;
      padding: 14px 12px 12px 17px;
      background: ${(props) => props.theme.themeSelector.viewSelector.backgroundColor};
      color: ${(props) => props.theme.themeSelector.viewSelector.textColor};
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      h4 {
        color: #d9d9d9;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
      .selected-header {
        .selected-counts {
          display: flex;
          span {
            color: ${(props) => props.theme.themeSelector.selectedTextColor};
            display: inline-block;
            font-size: 14px;
            line-height: 21px;
            margin-left: 8px;
            font-size: 11px;
          }
        }
      }
      .save-custom-view {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        .save-custom-btn {
          font-size: 13px;
          line-height: 15px;
          text-align: center;
          color: ${(props) => props.theme.themeSelector.customView.saveButtonColor};
          box-sizing: border-box;
          height: 20px;
          border: 1px solid #283038;
          border-radius: 2px;
          background: linear-gradient(179.99deg, #445a6c 0%, #38434e 62.55%, #38434e 100%);
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .save-view {
          opacity: 0;
          transition: all 0s;
          &.active {
            opacity: 1;
            transition: all 0.4s ease-in;
          }
        }
        .form {
          margin-bottom: 4px;
          display: flex;
          input {
            box-sizing: border-box;
            height: 22px;
            border: 1px solid ${(props) => props.theme.themeSelector.customView.inputBorderColor};
            border-radius: 2px;
            margin-right: 8px;
            background: transparent;
            color: ${(props) => props.theme.themeSelector.customView.inputColor};
            width: 160px;
          }
        }
      }
    }
    .view-tabs-wrapper {
      display: flex;
      flex: 1;
      max-height: calc(100% - 113px);
      .simplebar-wrapper {
        max-height: 100%;
      }
      .simplebar-mask {
        background: ${(props) => props.theme.themeSelector.viewSelector.tabsBackgroundColor};
      }
    }
    .view-tabs {
      background: ${(props) => props.theme.themeSelector.viewSelector.tabsBackgroundColor};
      display: block;
      overflow: hidden;
      max-height: 100%;
      .toggle {
        padding: 6px 10px 0 15px;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        .view-icon {
          margin-right: 10px;
        }
      }
      .custom-view-icon {
        margin-bottom: -4px;
      }
      h4 {
        width: 100%;
        text-align: center;
        color: ${(props) => props.theme.themeSelector.tabsTextColor};
        margin: 23px 0 11px;
        text-transform: uppercase;
      }
      ul {
        list-style: none;
        width: 115px;
        height: auto;
        padding: 0;
        margin: 0;
        li {
          margin: 0 3px 4px 6px;
          &.active,
          &:hover {
            background: ${(props) => props.theme.themeSelector.tabsActiveBackgroundColor};
            border-radius: 2px;
            span {
              color: ${(props) => props.theme.themeSelector.tabsActiveTextColor};
            }
          }
          button {
            outline: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            height: 70px;
            color: ${(props) => props.theme.themeSelector.tabsTextColor};
            font-size: 11px;
            font-weight: 700;
            line-height: 13px;
            width: 100%;
            cursor: pointer;
            span {
              margin-top: 8px;
            }
          }
          span {
            text-transform: uppercase;
          }
        }
      }
    }

    .panel {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex: 3;
      background: ${(props) => props.theme.themeSelector.panelBackgroundColor};
      color: ${(props) => props.theme.themeSelector.panel.textColor};
      z-index: ${elevations.P2};
      border-left: 3px solid ${(props) => props.theme.themeSelector.tabsActiveBackgroundColor};
      transition: false;
      .header {
        display: flex;
        justify-content: space-between;
        .filter {
          align-self: flex-end;
          text-align: right;
          padding: 11px 13px;
          input {
            box-sizing: border-box;
            height: 22px;
            border: 1px solid #b3b3b3;
            border-radius: 2px;
            margin-right: 8px;
            background: transparent;
            width: 130px;
            color: ${(props) => props.theme.themeSelector.filterInputColor};
          }
        }
      }
      h4 {
        display: block;
        padding: 22px 16px 11px 18px;
        text-transform: uppercase;
        color: ${(props) => props.theme.themeSelector.listHeaderColor};
      }
      .group {
        position: relative;
        background: ${(props) => props.theme.themeSelector.listBackgroundColor};
        border-bottom: 1px solid ${(props) => props.theme.themeSelector.listBorderColor};
        &.odd {
          background: ${(props) => props.theme.themeSelector.listBackgroundColorOdd};
        }
        .title {
          padding-left: 15px;
          border: 0px;
          &.active {
            background: ${(props) => props.theme.themeSelector.selectAllActiveBackgroundColor};
          }
        }
        li {
          padding-left: 60px;
        }
      }
      .select-all {
        display: flex;
        padding: 6px 8px 6px 0;
        background: ${(props) => props.theme.themeSelector.listBackgroundColorOdd};
        &.active {
          background: ${(props) => props.theme.themeSelector.selectAllActiveBackgroundColor};
        }
        button {
          flex: 1;
          text-align: left;
          font-size: 14px;
          line-height: 14px;
          color: ${(props) => props.theme.themeSelector.panel.selectAllColor};
        }
        label {
          margin-left: 15px;
        }
      }
    }
  }
  .tabs-footer {
    height: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    background: ${(props) => props.theme.themeSelector.viewSelector.footerBackgroundColor};
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
  }
`;

const StyledVirtualResults = styled.div`
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const EntityContent = ({ header, entities, renderFn, theme, filterText, setFilterText }) => {
  const { t } = useTranslation(['nav']);
  const parentRef = useRef();
  const rowVirtualizer = useVirtual({
    size: entities.length ?? 0,
    parentRef,
    estimateSize: React.useCallback(() => 26, []),
    overscan: 8,
  });

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items?.length > 0 ? items[0].start : 0;
  const paddingBottom =
    entities.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1]?.end : 0;

  return (
    <div className="panel">
      <div className="header">
        <h4>
          {t('view_selector.select', 'select')}
          &nbsp;
          {header}
        </h4>
        <Filter
          text={filterText}
          onChange={(event) => setFilterText(event.target.value.toLowerCase())}
        />
      </div>
      <ScrollingContainer ref={parentRef} theme={theme}>
        <StyledVirtualResults paddingTop={paddingTop} paddingBottom={paddingBottom}>
          {renderFn(items)}
        </StyledVirtualResults>
      </ScrollingContainer>
    </div>
  );
};

EntityContent.propTypes = {
  header: PropTypes.string.isRequired,
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderFn: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  filterText: PropTypes.string.isRequired,
  setFilterText: PropTypes.func.isRequired,
};

export const ViewSelector = withTheme(StyledViewSelector);
