import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu, placements } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledTitle = styled.div`
  background: ${({ theme }) => theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${({ theme }) => theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-task-count {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 294px;
  max-height: 200px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const TaskRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.menu.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;

  &:first-of-type {
    border: none;
  }
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const TaskTitle = styled.div`
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const DisplayLabel = styled.div`
  color: ${({ theme }) => theme.menu.labelColor};
  font-family: 'Museo Sans';
  font-size: 11px;
  font-weight: 300;
  text-transform: capitalize;
`;

const DetailColumn = styled.div`
  width: 100%;
`;

const QuantityColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;

  span {
    font-weight: 500;
    font-size: 12px;
  }
`;

export const PartsMenu = ({ containerRef, children, parts, titleKey, titleDefault }) => {
  const { t } = useTranslation(['tasks']);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  const PartItems = parts?.map((part, idx) => (
    <TaskRow key={`${part?.id}-${idx}`}>
      <DetailColumn>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <TaskTitle>{part?.name}</TaskTitle>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <DisplayLabel>{part?.number}</DisplayLabel>
      </DetailColumn>
      <QuantityColumn>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <span>{part?.quantity}</span>
      </QuantityColumn>
    </TaskRow>
  ));

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.LEFT_END}
        onClick={handleMenuClick}
      >
        <StyledTitle>
          {/* TODO(#994): Revist and replace with a Typography component */}
          <h4 className="menu-title-txt">{t(`menu.${titleKey}`, titleDefault)}</h4>
        </StyledTitle>
        <Content>{PartItems}</Content>
      </Menu>
    </div>
  );
};

PartsMenu.propTypes = {
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
  parts: PropTypes.instanceOf(Array),
  titleKey: PropTypes.string.isRequired,
  titleDefault: PropTypes.string.isRequired,
};

PartsMenu.defaultProps = {
  containerRef: null,
  parts: null,
};
