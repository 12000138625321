import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';
import { FormMode } from '@ge/models/constants';

import { Alert } from './alert';

const TRANSLATION_KEYS = {
  confirmCancelButton: {
    [FormMode.CREATE]: ['create_confirm_cancel_button', 'Ok'],
    [FormMode.EDIT]: ['edit_confirm_cancel_button', 'Okay'],
    [FormMode.VIEW]: [],
  },
  header: {
    [FormMode.CREATE]: ['create_dialog_title', 'CREATE NEW ALERT'],
    [FormMode.EDIT]: ['edit_dialog_title', 'EDIT ALERT'],
    [FormMode.VIEW]: ['view_dialog_title', 'VIEW ALERT'],
  },
};

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap
  justify-content: flex-start;
`;

const DialogFooter = ({
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
  onClose,
  isAssetLevelDLFoundInBulkAlert,
}) => {
  const { t } = useTranslation([], { useSuspense: false });

  return (
    <FooterContainer>
      <FooterButtons>
        {onCancel && (
          <Button onClick={isAssetLevelDLFoundInBulkAlert ? onClose : onCancel}>
            {isAssetLevelDLFoundInBulkAlert
              ? t('general:close', 'Close')
              : cancelLabel ?? t('general:cancel', 'Cancel')}
          </Button>
        )}
        {!isAssetLevelDLFoundInBulkAlert && onConfirm && (
          <Button onClick={onConfirm} primary type="submit">
            {confirmLabel ?? t('general:save', 'Save')}
          </Button>
        )}
      </FooterButtons>
    </FooterContainer>
  );
};

DialogFooter.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  isAssetLevelDLFoundInBulkAlert: PropTypes.bool,
};

DialogFooter.defaultProps = {
  cancelLabel: undefined,
  confirmLabel: undefined,
  onCancel: undefined,
  onConfirm: undefined,
  onClose: () => {},
  isAssetLevelDLFoundInBulkAlert: false,
};

export const AlertDialog = ({
  alert,
  groupDetails,
  isOpen,
  mode: _mode,
  onCancel,
  onConfirm,
  selectedAssets,
  isBulk,
  ...props
}) => {
  const { t } = useTranslation(['alerts'], { useSuspense: false });

  // state
  const [formState, setFormState] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [mode, setMode] = useState(_mode);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isAssetLevelDLFoundInBulkAlert, setIsAssetLevelDLFoundInBulkAlert] = useState(false);

  // using a registration pattern to wrap submit callback in form logic
  const [onSubmit, setOnSubmit] = useState(() => {
    return onConfirm;
  });

  useEffect(() => {
    setMode(_mode);
  }, [_mode]);

  // handlers
  const handleCancel = useCallback(() => {
    if (formState?.isDirty) {
      setShowCancelConfirmation(true);

      return;
    }

    onCancel();
  }, [formState, onCancel]);

  const handleCancelConfirm = useCallback(() => {
    setShowCancelConfirmation(false);

    onCancel();
  }, [onCancel]);

  const handleCancelConfirmClose = useCallback(() => {
    setShowCancelConfirmation(false);
  }, [setShowCancelConfirmation]);

  const handleModeChange = useCallback(
    (updateMode) => {
      setMode(updateMode);
    },
    [setMode],
  );

  // bind alert form submit to dialog confirm button
  const handleRegisterSubmit = useCallback(
    (register) => {
      // makes sure not to execute func being stored in state
      setOnSubmit(() => {
        return register(onConfirm);
      });
    },
    [onConfirm],
  );

  const handleStateChange = useCallback(
    (state) => {
      setFormState(state);
    },
    [setFormState],
  );

  const footerOptions = {
    onCancel,
  };

  if (mode === FormMode.VIEW) {
    footerOptions.cancelLabel = t('close_button', 'Close');
  } else {
    footerOptions.confirmLabel = hasNotification
      ? t('save_send_buttons', 'Save & Send')
      : undefined;
    footerOptions.onCancel = handleCancel;
    footerOptions.onConfirm = onSubmit;
  }

  return (
    <Dialog
      footer={
        <DialogFooter
          cancelLabel={footerOptions.cancelLabel}
          confirmLabel={footerOptions.confirmLabel}
          onCancel={footerOptions.onCancel}
          onConfirm={footerOptions.onConfirm}
          onClose={onCancel}
          isAssetLevelDLFoundInBulkAlert={isAssetLevelDLFoundInBulkAlert}
        />
      }
      header={t(...TRANSLATION_KEYS.header[mode])}
      isOpen={isOpen}
      onClose={handleCancel}
      padContent={false}
    >
      <Alert
        alert={alert}
        mode={mode}
        onExpandSendNotification={setHasNotification}
        onModeChange={handleModeChange}
        onStateChange={handleStateChange}
        onRegisterSubmit={handleRegisterSubmit}
        selectedAssets={selectedAssets}
        isBulk={isBulk}
        groupDetails={groupDetails}
        setIsAssetLevelDLFoundInBulkAlert={setIsAssetLevelDLFoundInBulkAlert}
        isAssetLevelDLFoundInBulkAlert={isAssetLevelDLFoundInBulkAlert}
        {...props}
      />
      <Dialog
        contentWidth
        footer={
          <DialogFooter
            confirmLabel={t(...TRANSLATION_KEYS.confirmCancelButton[mode])}
            onCancel={handleCancelConfirmClose}
            onConfirm={handleCancelConfirm}
          />
        }
        header={t('confirm_cancel_header', 'Confirmation')}
        isOpen={showCancelConfirmation}
        onClose={handleCancelConfirmClose}
        onConfirm={handleCancelConfirm}
        padContent={true}
      >
        <p>{t('create_confirm_cancel_message', 'Are you sure you want to discard the Alert?')}</p>
      </Dialog>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  alert: PropTypes.object,
  isOpen: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedAssets: PropTypes.array,
  isBulk: PropTypes.bool,
  groupDetails: PropTypes.array,
};

AlertDialog.defaultProps = {
  alert: undefined,
  isOpen: false,
  mode: FormMode.VIEW,
  onCancel: () => {},
  onConfirm: () => {},
  isBulk: false,
};
