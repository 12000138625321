import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { DataLoaderType, DateTimeFormats } from '@ge/models/constants';
import { useComponentInfo } from '@ge/shared/data-hooks/use-component-info';
import { typography } from '@ge/tokens';

const ComponentInfoCard = styled.div`
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.assetCard.titleBackgroundColor};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  float: right;
  font-family: ${typography.family.default};
  h4 {
    color: ${({ theme }) => theme.input.labelTextColor};
    line-height: 13px;
    font-weight: ${typography.weight.regular};
    font-family: ${typography.family.default};
    font-size: 11px;
    padding-bottom: 5px;
  }
  p {
    margin-top: 1px;
    color: ${({ theme }) => theme.themeSelector.selectedTextColor};
    line-height: 18px;
    font-family: ${typography.family.default};
    font-size: 14px;
  }
`;

const ComponentInfoHeader = styled.div`
  height: 25px;
  width: 233px;
  border-radius: 5px 5px 0 0;
  background-color: ${({ theme }) => theme.assetCard.chevronColor};
`;

const NameContent = styled.div`
  height: auto;
  width: 202px;
  padding: 16px;
  font-family: ${typography.family.default};
  word-brak: break-word;
`;

const HeaderContent = styled.div`
  height: 13px;
  color: ${({ theme }) => theme.entityDetails.overviewTableBodyTextColor};
  font-family: ${typography.family.default};
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 7px 0 0 16px;
`;

const LeftContent = styled.div`
  float: left;
  width: 105px;
  word-break: break-all;
`;
const RightContent = styled.div`
  float: right;
  width: auto;
  padding-right: 8px;
`;

const ContentContainer = styled.div`
  height: 80px;
  width: 233px;
  p {
    font-weight: ${typography.weight.regular};
    padding-left: 16px;
    line-height: 15px;
    font-family: ${typography.family.default};
    font-size: 12px;
  }
  h4 {
    line-height: 13px;
    padding-left: 16px;
    font-size: 12px;
    font-weight: ${typography.weight.regular};
    padding-bottom: 5px;
    font-family: ${typography.family.default};
  }
`;

export const AssetComponentDetails = ({ componentId, componentName }) => {
  const { data: componentData, isLoading } = useComponentInfo(componentId);
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  if (!ready) {
    return null;
  }
  return (
    <DataLoader
      isLoading={isLoading}
      type={DataLoaderType.TABLE}
      renderCondition={true}
      margin="10px"
    >
      {componentData ? (
        <ComponentInfoCard>
          <ComponentInfoHeader>
            <HeaderContent>{componentName}</HeaderContent>
          </ComponentInfoHeader>
          <NameContent>
            <h4 className="title">{t('component_info.mfg_name', 'Vendor/Mfg Name')}</h4>
            <p>{componentData.properties.make ? componentData.properties.make : '-'}</p>
          </NameContent>
          <ContentContainer>
            <LeftContent>
              <h4>{t('component_info.model', 'Model')}</h4>
              <p>{componentData.properties.model ? componentData.properties.model : '-'}</p>
              <h4>{t('component_info.serial_number', 'Serial Number')}</h4>
              <p>
                {componentData.properties.serialNumber
                  ? componentData.properties.serialNumber
                  : '-'}
              </p>
            </LeftContent>
            <RightContent>
              <h4>{t('component_info.part_number', 'Part Number')}</h4>
              <p>
                {componentData.properties.partNumber ? componentData.properties.partNumber : '-'}
              </p>
              <h4>{t('component_info.date_installer', 'Date Installed')}</h4>
              <p>
                {componentData.properties.installDate
                  ? dayjs(componentData.properties.installDate).format(DateTimeFormats.DEFAULT_DATE)
                  : '-'}
              </p>
            </RightContent>
          </ContentContainer>
        </ComponentInfoCard>
      ) : (
        ''
      )}
    </DataLoader>
  );
};

AssetComponentDetails.propTypes = {
  componentId: PropTypes.string,
  componentName: PropTypes.string,
};
