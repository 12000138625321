import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParam, encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params';

import useStateRef from '@ge/hooks/state-ref';
import { Capability, Delimiters, EntityType, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

const buildEntityParamObj = (type) => {
  const newEntityInfo = { entityType: type };
  return newEntityInfo;
};

const entityArrayParam = {
  encode: (obj) => {
    const array = obj && Object.keys(obj).map((key) => `${key}${Delimiters.KEY_VALUE}${obj[key]}`);
    return encodeDelimitedArray(array, Delimiters.PARAM);
  },

  decode: (arrayStr) => {
    return decodeDelimitedArray(arrayStr, Delimiters.PARAM);
  },
};

const useEntityDetailAuth = () => {
  // data hooks
  const { audit } = useAuth();

  return useCallback(
    ({ entityId, entityType }) => {
      if (entityId || !entityType) {
        return false;
      } else {
        //capability modified as discussed with RAM. This function only used one time
        return audit({
          capabilities: [
            { capability: Capability.LIMITED_USER_MANAGEMENT, scopes: [PermissionScope.CREATE] },
          ],
          source: `'${entityType}' entity create`,
          type: 'Entity panel blocked',
        });
      }
    },
    [audit],
  );
};

export const useEntityCreateState = (popout) => {
  const history = useHistory();
  const [entityParam, setEntityParam] = useQueryParam('entity', entityArrayParam);
  const isAuthorized = useEntityDetailAuth();

  const [panelShown, setPanelShown] = useState(false);
  const [entityType, setEntityType] = useStateRef();

  // Selected entity state management
  const entitySearchParams = useMemo(() => {
    const entityEntries = entityParam
      ? new Map(
          entityParam.map((entity) => {
            const entityKeyVal = entity.split(Delimiters.KEY_VALUE);
            return [entityKeyVal[0], entityKeyVal[1]];
          }),
        )
      : [];
    return Object.fromEntries(entityEntries);
  }, [entityParam]);

  const updateHistory = useCallback(
    (obj, initial) => {
      // Handle navigation in popouts differently in order to
      // maintain pathing.
      if (popout) {
        history.push({
          pathname: `/${obj.entityType}`,
          state: {
            initial: false,
          },
        });
        return;
      }

      // Push a new history entry with the "initial" state for
      // tracking whether or not there should be a back chevron
      // on the details panel.
      history.push({
        pathname: history.pathname,
        search: history.location.search,
        state: {
          initial,
        },
      });

      setEntityParam(obj, 'replaceIn');
    },
    [setEntityParam, history, popout],
  );

  // Use programmatic access to modify the history and allow the query
  // param watchers to handle hiding and showing.
  const showCreatePanel = useCallback(
    (type) => {
      updateHistory(buildEntityParamObj(type), false);
    },
    [updateHistory],
  );

  // Reset the shown details panel state and remove form URL.
  const hideDetails = useCallback(() => {
    updateHistory(undefined);
  }, [updateHistory]);

  // Reset the entity state information.
  // NOTE: This is here for cleanup AFTER animations complete.
  const resetDetails = useCallback(() => {
    setEntityType(undefined);
  }, [setEntityType]);

  // When the search params, update the stored details state.
  useEffect(() => {
    const { entityId, entityType } = entitySearchParams;

    // handles initial load to not update history
    if (entityId || !entityType) {
      setPanelShown(false);

      return;
    }

    if (!isAuthorized(entitySearchParams)) {
      updateHistory(undefined);
      setPanelShown(false);

      return;
    }

    // Only trigger the show here if the entity has changed.
    if (entityType) {
      setEntityType(entityType);
      setPanelShown(true);
    }
  }, [entitySearchParams, isAuthorized, setEntityType, updateHistory]);

  // Show entity create panel for person.
  const showCreatePerson = () => {
    showCreatePanel(EntityType.PERSON);
  };

  const showCreateRole = () => {
    showCreatePanel(EntityType.ROLE);
  };

  return {
    // State
    entityType,
    panelShown,
    setPanelShown,

    // Actions
    hideDetails,
    showCreatePanel,
    resetDetails,
    showCreatePerson,
    showCreateRole,
  };
};
