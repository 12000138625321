import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu, MenuTable, placements } from '@ge/components/menu';
import { Placeholders, SystemResetStatus } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
`;

const StyledTr = styled.tr`
  td {
    font-size: 11px;
    letter-spacing: 0;
    color: ${(props) => props.theme.table.dataTextColor};
    font-weight: ${(props) => (props.isBold ? 700 : 300)};
    div {
      text-transform: none;
      text-align: left;
    }
    &.heading {
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    &:nth-of-type(2) {
      min-width: 130px;
      max-width: 200px;
      opacity: 0.6;
    }
    &:last-of-type {
      div {
        padding-right: 11px;
      }
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export const AutomationStatus = ({ status, name, result, reason, children, containerRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['monitor.issues'], { useSuspense: false });
  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      <div>{children}</div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.AUTO}
        onClick={(e) => {
          e.preventDefault();
          killEventPropagation(e);
        }}
      >
        <StyledTitle>
          <h4 className="menu-title-txt">
            {t(`systemResetStatus.${status?.toLowerCase() ?? 'no_automation'}`, 'NO AUTOMATION')}
          </h4>
        </StyledTitle>
        <MenuTable disableHover={true}>
          <table>
            <tbody>
              {status !== SystemResetStatus.NO_AUTOMATION ? (
                <>
                  <StyledTr>
                    <td className="heading">{t('systemResetStatus.name', 'Name:')}</td>
                    <td>
                      <div>{name ?? Placeholders.DASH}</div>
                    </td>
                  </StyledTr>
                  {status !== SystemResetStatus.IN_PROGRESS && (
                    <StyledTr>
                      <td className="heading">{t('systemResetStatus.result', 'Result:')}</td>
                      <td>
                        <div>{result ?? Placeholders.DASH}</div>
                      </td>
                    </StyledTr>
                  )}
                  {status !== SystemResetStatus.IN_PROGRESS && (
                    <StyledTr>
                      <td className="heading">{t('systemResetStatus.reason', 'Reason:')}</td>
                      <td>
                        <div>{reason ?? Placeholders.DASH}</div>
                      </td>
                    </StyledTr>
                  )}
                </>
              ) : (
                <StyledTr>
                  <td colSpan="2">
                    <div>
                      {t(
                        'systemResetStatus.no_automation_assigned',
                        'No automation assigned to this case',
                      )}
                    </div>
                  </td>
                </StyledTr>
              )}
            </tbody>
          </table>
        </MenuTable>
      </Menu>
    </div>
  );
};

AutomationStatus.propTypes = {
  status: PropTypes.string,
  name: PropTypes.string,
  result: PropTypes.string,
  reason: PropTypes.string,
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
};
