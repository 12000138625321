import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { useMalEvents } from '@ge/feat-analyze/data-hooks/use-mal-events';
import { useAllCrews } from '@ge/feat-manage/data-hooks/use-all-crews';
import { useWorkers } from '@ge/feat-manage/data-hooks/use-workers';
import { assetStatusStatuses } from '@ge/feat-reporting/components/sidebar/modules/asset-status-module';
import { BarWidget } from '@ge/feat-reporting/components/widgets/bar-widget';
import { DonutWidget } from '@ge/feat-reporting/components/widgets/donut-widget';
import { LineWidget } from '@ge/feat-reporting/components/widgets/line-widget';
import { ListWidget } from '@ge/feat-reporting/components/widgets/list-widget';
import { NotesWidget } from '@ge/feat-reporting/components/widgets/notes-widget';
import { RoseWidget } from '@ge/feat-reporting/components/widgets/rose-widget';
import { TableWidget } from '@ge/feat-reporting/components/widgets/table-widget';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useCaseData } from '@ge/feat-reporting/data-hooks/use-open-case-data';
import { useReportAssetState } from '@ge/feat-reporting/data-hooks/use-report-asset-state';
import { useTaskWorkData } from '@ge/feat-reporting/data-hooks/use-task-work-data';
import { useWidgetAssetState } from '@ge/feat-reporting/data-hooks/use-widget-asset-state';
import { useWidgetKpiData } from '@ge/feat-reporting/data-hooks/use-widget-kpi-data';
import { useWidgetPerformanceSummary } from '@ge/feat-reporting/data-hooks/use-widget-performance-summary';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';
import { TaskStatus } from '@ge/models';
import { AttributeGroups, NotesScope, CaseStatus } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { TableBaseCols } from '../components/widgets/table-widget/table-base-cols';
import { TableWidgetStateKeys } from '../components/widgets/table-widget/table-widget';

const TurbineIcon = styled(Icon).attrs(({ theme }) => ({
  size: 10,
  icon: Icons.TURBINE,
  color: theme.createReport.widget.headerIconColor,
}))``;

const BaseWidgetPropTypes = {
  createdTimestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  isInteractive: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  name: PropTypes.oneOf(Object.values(WidgetNames)),
  scope: PropTypes.string,
  scopeType: PropTypes.oneOf(Object.values(AttributeGroups)),
  siteIds: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.string,
};

// used for completed work and planned work widgets, can rename accordingly
const TaskWorkBaseWidget = ({
  createdTimestamp,
  id,
  isInteractive,
  isPlaceholder,
  name,
  status,
}) => {
  const { trackLoadingWidget, getWidgetState, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  // Grab the custom notes configuration from report state to scope data from the task hook.
  const notesScope = useMemo(() => {
    const notesConfig = getWidgetState(id, TableWidgetStateKeys.COLUMN_CONFIG_STATE)?.find(
      (configItem) => configItem?.id === TableBaseCols.GROUP_NOTES,
    );

    if (notesConfig?.cols) {
      // Determine scope from incoming config
      const notesInternal = notesConfig.cols.find(
        (col) => col?.id === TableBaseCols.NOTES_INTERNAL,
      );
      const notesExternal = notesConfig.cols.find(
        (col) => col?.id === TableBaseCols.NOTES_EXTERNAL,
      );
      if (notesInternal?.visible && notesExternal?.visible) {
        return [NotesScope.INTERNAL, NotesScope.EXTERNAL];
      } else if (notesInternal?.visible) {
        return [NotesScope.INTERNAL];
      } else if (notesExternal?.visible) {
        return [NotesScope.EXTERNAL];
      }
    }

    // Return no scope if none can be parsed.
    return undefined;
  }, [id, getWidgetState]);

  const { data, isLoading } = useTaskWorkData({
    config: {
      ...Config.EXECUTE_ONCE,
      enabled: !isPlaceholder && Boolean(createdTimestamp),
    },
    notesScope,
    queryKey: createdTimestamp,
    status,
  });

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  return (
    <TableWidget
      id={id}
      data={data}
      selectedRows={selectedRows}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
    />
  );
};

TaskWorkBaseWidget.propTypes = {
  ...BaseWidgetPropTypes,
  status: PropTypes.oneOf(Object.values(TaskStatus)),
};

const AssetsOfConcernWidget = ({ createdTimestamp, id, isInteractive, isPlaceholder }) => {
  const name = WidgetNames.ASSETS_OF_CONCERN;

  const { trackLoadingWidget, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  const { data: _data, isLoading } = useReportAssetState({
    config: {
      ...Config.EXECUTE_ONCE,
      enabled: !isPlaceholder && Boolean(createdTimestamp),
    },
    queryKey: createdTimestamp,
  });

  // massage data into format expected by table (with id field for key)
  // can move this into data hook if it makes sense
  const data = useMemo(
    () =>
      Object.entries(_data ?? {}).reduce((mapped, [key, value]) => {
        mapped.push({ id: key, ...value });

        return mapped;
      }, []),
    [_data],
  );

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  return (
    <TableWidget
      id={id}
      data={data}
      selectedRows={selectedRows}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
    />
  );
};

AssetsOfConcernWidget.propTypes = BaseWidgetPropTypes;

// TODO: move this into separate file
const AssetStatusWidget = ({ id, isPlaceholder }) => {
  const name = WidgetNames.ASSET_STATUS;

  const { trackLoadingWidget } = useContext(ReportsContext);
  const { data: _data, isLoading } = useWidgetAssetState({ isActive: !isPlaceholder });

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  const data = useMemo(() => {
    if (!_data?.length) {
      return []; // is this the right default?
    }

    return _data.reduce(
      (acc, site) => {
        acc.assetCount += site.assets.total;
        for (const [key, value] of Object.entries(site.assets)) {
          let statusInfo = acc.statuses.find((status) => status.name === key);
          if (statusInfo) {
            statusInfo.value += value;
          }
        }
        return acc;
      },
      {
        assetCount: 0,
        statuses: Object.keys(assetStatusStatuses).map((status) => ({
          name: status,
          value: 0,
        })),
      },
    );
  }, [_data]);

  return (
    <DonutWidget
      id={id}
      name={name}
      data={data}
      isLoading={isLoading}
      isPlaceholder={isPlaceholder}
      showTimestamp
    />
  );
};

AssetStatusWidget.propTypes = BaseWidgetPropTypes;

const AvailabilityWidget = ({ id, endDate, isInteractive, isPlaceholder, startDate }) => {
  const name = WidgetNames.AVAILABILITY;

  const { trackLoadingWidget } = useContext(ReportsContext);
  const { data, isLoading } = useWidgetKpiData({
    endDate,
    isActive: !isPlaceholder,
    name,
    startDate,
  });

  const { units, values = [] } = data?.[WidgetNames.AVAILABILITY] ?? {};
  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  return (
    <LineWidget
      id={id}
      data={values}
      name={name}
      isInteractive={isInteractive}
      isLoading={isLoading}
      isPlaceholder={isPlaceholder}
      units={units}
    />
  );
};

AvailabilityWidget.propTypes = BaseWidgetPropTypes;

const AverageWindSpeedWidget = ({ id, endDate, isInteractive, isPlaceholder, startDate }) => {
  const name = WidgetNames.AVERAGE_WIND_SPEED;

  const { trackLoadingWidget } = useContext(ReportsContext);
  const { data, isLoading } = useWidgetKpiData({
    endDate,
    isActive: !isPlaceholder,
    name,
    startDate,
  });

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  const windSpeedData = data[WidgetNames.AVERAGE_WIND_SPEED]?.wind;

  return (
    <LineWidget
      id={id}
      data={windSpeedData}
      name={WidgetNames.AVERAGE_WIND_SPEED}
      isInteractive={isInteractive}
      isLoading={isLoading}
      isPlaceholder={isPlaceholder}
    />
  );
};

AverageWindSpeedWidget.propTypes = BaseWidgetPropTypes;

const CompletedWorkWidget = ({
  createdTimestamp,
  id,
  endDate,
  isInteractive,
  isPlaceholder,
  scope,
  scopeType,
  startDate,
}) => {
  const name = WidgetNames.COMPLETED_WORK;
  const status = TaskStatus.COMPLETE;

  const props = {
    createdTimestamp,
    id,
    endDate,
    isInteractive,
    isPlaceholder,
    name,
    scope,
    scopeType,
    startDate,
    status,
  };

  return <TaskWorkBaseWidget {...props} />;
};

CompletedWorkWidget.propTypes = BaseWidgetPropTypes;

const PerformanceSummaryWidget = ({ id, endDate, isPlaceholder, startDate }) => {
  const name = WidgetNames.PERFORMANCE_SUMMARY;

  const { trackLoadingWidget } = useContext(ReportsContext);

  const { data, isLoading } = useWidgetPerformanceSummary({
    config: {
      ...Config.EXECUTE_ONCE,
      enabled: !isPlaceholder,
      retry: 1,
    },
    endDate,
    name,
    startDate,
  });

  const assetCount = data?.assetCount ?? null; // fallback to null so easier to test

  const headerContent = useMemo(() => {
    if (isPlaceholder || assetCount === null) {
      return null;
    }

    return (
      <span>
        <TurbineIcon />
        {` ${assetCount}`}
      </span>
    );
  }, [assetCount, isPlaceholder]);

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  return (
    <ListWidget
      data={data?.data}
      headerContent={headerContent}
      id={id}
      isLoading={isLoading}
      isPlaceholder={isPlaceholder}
      name={name}
    />
  );
};

PerformanceSummaryWidget.propTypes = BaseWidgetPropTypes;

const ContractualAvailabilityWidget = ({
  endDate,
  id,
  isInteractive,
  isPlaceholder,
  startDate,
}) => {
  const name = WidgetNames.CONTRACTUAL_AVAILABILITY;

  const { trackLoadingWidget, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  const { data, isLoading } = useWidgetKpiData({
    endDate,
    isActive: !isPlaceholder,
    name,
    startDate,
  });

  const { timeAggr, units, values = [] } = data?.[WidgetNames.CONTRACTUAL_AVAILABILITY] ?? {};
  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  const variationKey = timeAggr && `contractual_availability.variations.${timeAggr.toLowerCase()}`;

  return (
    <TableWidget
      id={id}
      data={values}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
      selectedRows={selectedRows}
      timeAggr={timeAggr}
      units={units}
      variationKey={variationKey}
    />
  );
};

ContractualAvailabilityWidget.propTypes = BaseWidgetPropTypes;

const ManuallyAdjustedEventsWidget = ({ id, isInteractive, isPlaceholder, startDate, endDate }) => {
  const name = WidgetNames.MANUALLY_ADJUSTED_EVENTS;
  const { trackLoadingWidget, getWidgetLocalState } = useContext(ReportsContext);
  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);
  const { siteIds } = useReportScope();
  const { data, isLoading } = useMalEvents({
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
    siteIds,
  });
  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  return (
    <TableWidget
      id={id}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
      data={data ?? []}
      selectedRows={selectedRows}
      isLoading={isLoading}
    />
  );
};

ManuallyAdjustedEventsWidget.propTypes = BaseWidgetPropTypes;

const PlannedWorkWidget = ({
  createdTimestamp,
  id,
  endDate,
  isInteractive,
  isPlaceholder,
  scope,
  scopeType,
  startDate,
}) => {
  const name = WidgetNames.PLANNED_WORK;
  const status = TaskStatus.SCHEDULED;

  const props = {
    createdTimestamp,
    id,
    endDate,
    isInteractive,
    isPlaceholder,
    name,
    scope,
    scopeType,
    startDate,
    status,
  };

  return <TaskWorkBaseWidget {...props} />;
};

PlannedWorkWidget.propTypes = BaseWidgetPropTypes;

const ProductionGraphWidget = ({ id, isInteractive, isPlaceholder, endDate, startDate }) => {
  const name = WidgetNames.PRODUCTION_GRAPH;

  const { trackLoadingWidget } = useContext(ReportsContext);
  const { data, isLoading } = useWidgetKpiData({
    endDate,
    isActive: !isPlaceholder,
    name,
    startDate,
  });

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  const productionGraphData = data[name]?.productionActual;

  return (
    <BarWidget
      id={id}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      isLoading={isLoading}
      name={WidgetNames.PRODUCTION}
      data={productionGraphData}
    />
  );
};

ProductionGraphWidget.propTypes = BaseWidgetPropTypes;

const ProductionTableWidget = ({ endDate, id, isInteractive, isPlaceholder, startDate }) => {
  const name = WidgetNames.PRODUCTION_TABLE;

  const { trackLoadingWidget, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  const { data, isLoading } = useWidgetKpiData({
    endDate,
    isActive: !isPlaceholder,
    name,
    startDate,
  });

  const { timeAggr, units, values = [] } = data?.[WidgetNames.PRODUCTION_TABLE] ?? {};
  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  const variationKey = timeAggr && `production_table.variations.${timeAggr.toLowerCase()}`;

  return (
    <TableWidget
      id={id}
      data={values}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={WidgetNames.PRODUCTION}
      selectedRows={selectedRows}
      timeAggr={timeAggr}
      units={units}
      variationKey={variationKey}
    />
  );
};

ProductionTableWidget.propTypes = BaseWidgetPropTypes;

const SiteRosterWidget = ({
  createdTimestamp,
  id,
  endDate,
  isInteractive,
  isPlaceholder,
  startDate,
}) => {
  const name = WidgetNames.SITE_ROSTER;

  const { serviceGroupIds } = useReportScope();

  const config = {
    ...Config.EXECUTE_ONCE,
    enabled: !isPlaceholder && Boolean(serviceGroupIds?.length) && Boolean(createdTimestamp),
  };

  const { trackLoadingWidget, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  const { data: allCrewsData, isFetching: isCrewsDataLoading } = useAllCrews(
    startDate,
    endDate,
    serviceGroupIds,
    config,
    createdTimestamp,
  );
  const { data: workerData, isFetching: isWorkersDataLoading } = useWorkers(
    startDate,
    endDate,
    serviceGroupIds,
    config,
    createdTimestamp,
  );

  // Map crew information to each worker if the worker belongs to a crew.
  const data = useMemo(() => {
    return workerData?.map((worker) => {
      worker.crews = allCrewsData?.filter((crew) => {
        return crew.members?.find((member) => member.member_id === worker.username);
      });

      return worker;
    });
  }, [allCrewsData, workerData]);

  const isLoading = isCrewsDataLoading || isWorkersDataLoading;
  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  return (
    <TableWidget
      id={id}
      data={data}
      selectedRows={selectedRows}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
    />
  );
};

SiteRosterWidget.propTypes = BaseWidgetPropTypes;

const WindDirectionSpeedWidget = ({ id, endDate, isPlaceholder, startDate }) => {
  const name = WidgetNames.WIND_DIRECTION_SPEED;

  const { trackLoadingWidget } = useContext(ReportsContext);
  const { data, isLoading } = useWidgetKpiData({
    endDate,
    isActive: !isPlaceholder,
    name,
    startDate,
  });

  useEffect(() => {
    trackLoadingWidget(name, isLoading);
    return () => trackLoadingWidget(name, false);
  }, [name, isLoading, trackLoadingWidget]);

  const windDirectionData = data[name]?.windDirection;

  return (
    <RoseWidget
      id={id}
      data={windDirectionData}
      name={name}
      isLoading={isLoading}
      isPlaceholder={isPlaceholder}
    />
  );
};

WindDirectionSpeedWidget.propTypes = BaseWidgetPropTypes;

const CaseWorkBaseWidget = ({
  createdTimestamp,
  id,
  isInteractive,
  isPlaceholder,
  name,
  status,
}) => {
  const { trackLoadingWidget, getWidgetState, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  // Grab the custom notes configuration from report state to scope data from the task hook.
  const notesScope = useMemo(() => {
    const notesConfig = getWidgetState(id, TableWidgetStateKeys.COLUMN_CONFIG_STATE)?.find(
      (configItem) => configItem?.id === TableBaseCols.GROUP_NOTES,
    );

    if (notesConfig?.cols) {
      // Determine scope from incoming config
      const notesInternal = notesConfig.cols.find(
        (col) => col?.id === TableBaseCols.NOTES_INTERNAL,
      );
      const notesExternal = notesConfig.cols.find(
        (col) => col?.id === TableBaseCols.NOTES_EXTERNAL,
      );
      if (notesInternal?.visible && notesExternal?.visible) {
        return [NotesScope.INTERNAL, NotesScope.EXTERNAL];
      } else if (notesInternal?.visible) {
        return [NotesScope.INTERNAL];
      } else if (notesExternal?.visible) {
        return [NotesScope.EXTERNAL];
      }
    }

    // Return no scope if none can be parsed.
    return undefined;
  }, [id, getWidgetState]);

  const { data, isLoading } = useCaseData({
    config: {
      ...Config.EXECUTE_ONCE,
      enabled: !isPlaceholder && Boolean(createdTimestamp),
    },
    notesScope,
    queryKey: createdTimestamp,
    status,
  });

  useEffect(() => trackLoadingWidget(name, isLoading), [name, isLoading, trackLoadingWidget]);

  return (
    <TableWidget
      id={id}
      data={data}
      selectedRows={selectedRows}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
    />
  );
};

CaseWorkBaseWidget.propTypes = {
  ...BaseWidgetPropTypes,
  status: PropTypes.oneOf(Object.values(CaseStatus)),
};

const ClosedCaseWorkBaseWidget = ({
  createdTimestamp,
  id,
  isInteractive,
  isPlaceholder,
  name,
  status,
}) => {
  const { trackLoadingWidget, getWidgetState, getWidgetLocalState } = useContext(ReportsContext);

  const selectedRows = getWidgetLocalState(id, TableWidgetStateKeys.ROW_CONFIG_STATE);

  // Grab the custom notes configuration from report state to scope data from the task hook.
  const notesScope = useMemo(() => {
    const notesConfig = getWidgetState(id, TableWidgetStateKeys.COLUMN_CONFIG_STATE)?.find(
      (configItem) => configItem?.id === TableBaseCols.GROUP_NOTES,
    );

    if (notesConfig?.cols) {
      // Determine scope from incoming config
      const notesInternal = notesConfig.cols.find(
        (col) => col?.id === TableBaseCols.NOTES_INTERNAL,
      );
      const notesExternal = notesConfig.cols.find(
        (col) => col?.id === TableBaseCols.NOTES_EXTERNAL,
      );
      if (notesInternal?.visible && notesExternal?.visible) {
        return [NotesScope.INTERNAL, NotesScope.EXTERNAL];
      } else if (notesInternal?.visible) {
        return [NotesScope.INTERNAL];
      } else if (notesExternal?.visible) {
        return [NotesScope.EXTERNAL];
      }
    }

    // Return no scope if none can be parsed.
    return undefined;
  }, [id, getWidgetState]);

  const { data, isLoading } = useCaseData({
    config: {
      ...Config.EXECUTE_ONCE,
      enabled: !isPlaceholder && Boolean(createdTimestamp),
    },
    notesScope,
    queryKey: createdTimestamp,
    status,
  });

  useEffect(() => trackLoadingWidget(name, isLoading), [name, isLoading, trackLoadingWidget]);

  return (
    <TableWidget
      id={id}
      data={data}
      selectedRows={selectedRows}
      isLoading={isLoading}
      isInteractive={isInteractive}
      isPlaceholder={isPlaceholder}
      name={name}
    />
  );
};

ClosedCaseWorkBaseWidget.propTypes = {
  ...BaseWidgetPropTypes,
  status: PropTypes.oneOf(Object.values(CaseStatus)),
};

const OpenCasesWidget = ({
  createdTimestamp,
  id,
  endDate,
  isInteractive,
  isPlaceholder,
  scope,
  scopeType,
  startDate,
}) => {
  const name = WidgetNames.OPEN_CASES;
  const status = CaseStatus.OPEN;
  const props = {
    createdTimestamp,
    id,
    endDate,
    isInteractive,
    isPlaceholder,
    name,
    scope,
    scopeType,
    startDate,
    status,
  };

  return <CaseWorkBaseWidget {...props} />;
};

OpenCasesWidget.propTypes = BaseWidgetPropTypes;

const ClosedCasesWidget = ({
  createdTimestamp,
  id,
  endDate,
  isInteractive,
  isPlaceholder,
  scope,
  scopeType,
  startDate,
}) => {
  const name = WidgetNames.CLOSED_CASES;
  const status = CaseStatus.CLOSED;
  const props = {
    createdTimestamp,
    id,
    endDate,
    isInteractive,
    isPlaceholder,
    name,
    scope,
    scopeType,
    startDate,
    status,
  };

  return <ClosedCaseWorkBaseWidget {...props} />;
};

ClosedCasesWidget.propTypes = BaseWidgetPropTypes;

const WidgetMap = {
  [WidgetNames.ASSETS_OF_CONCERN]: AssetsOfConcernWidget,
  [WidgetNames.ASSET_STATUS]: AssetStatusWidget,
  [WidgetNames.AVAILABILITY]: AvailabilityWidget,
  [WidgetNames.AVERAGE_WIND_SPEED]: AverageWindSpeedWidget,
  [WidgetNames.COMPLETED_WORK]: CompletedWorkWidget,
  [WidgetNames.CONTRACTUAL_AVAILABILITY]: ContractualAvailabilityWidget,
  [WidgetNames.MANUALLY_ADJUSTED_EVENTS]: ManuallyAdjustedEventsWidget,
  [WidgetNames.NOTES]: NotesWidget,
  [WidgetNames.NOTES_WIDE]: NotesWidget,
  [WidgetNames.PERFORMANCE_SUMMARY]: PerformanceSummaryWidget,
  [WidgetNames.PLANNED_WORK]: PlannedWorkWidget,
  [WidgetNames.PRODUCTION_GRAPH]: ProductionGraphWidget,
  [WidgetNames.PRODUCTION_TABLE]: ProductionTableWidget,
  [WidgetNames.SITE_ROSTER]: SiteRosterWidget,
  [WidgetNames.WIND_DIRECTION_SPEED]: WindDirectionSpeedWidget,
  [WidgetNames.OPEN_CASES]: OpenCasesWidget,
  [WidgetNames.CLOSED_CASES]: ClosedCasesWidget,
};

// TODO: how do we want to handle network errors in here?
export const useWidgetFactory = ({
  isInteractive,
  isPlaceholder,
  id,
  name,
  scope,
  scopeType,
  serviceUrls,
}) => {
  // context
  const { createdTimestamp, endDate, startDate } = useReportScope();

  const Widget = WidgetMap[name];

  let widget = null;

  // TODO: throw error/warning or display something in report if widget doesn't exist?
  if (Widget) {
    const props = {
      createdTimestamp,
      endDate,
      isInteractive,
      isPlaceholder,
      id,
      name,
      scope,
      scopeType,
      serviceUrls,
      startDate,
    };

    widget = React.createElement(Widget, props);
  }

  return { widget };
};
