import clone from 'ramda/src/clone';

import { mergeOptionsRight } from '@ge/util';

import {
  areaChartOptions,
  areaSplineChartOptions,
  chartOptions,
  ChartType,
  columnChartOptions,
  columnGroupChartOptions,
  lineChartOptions,
  roseChartOptions,
  scatterChartOptions,
  splineChartOptions,
} from './models';

const AXIS_TITLE_HEIGHT = 25;

// maps composite charts to base chart types for theming, etc
// don't like having to do this
export const getBaseChartType = (type = ChartType.LINE) => {
  switch (type) {
    case ChartType.MIRROR_COLUMN:
      return ChartType.COLUMN;

    default:
      return type;
  }
};

// used for rounding corners on columns
const getBorderRadius = ({ borderRadius = 4, negative = false } = {}) => {
  const borderRadiusPx = `${borderRadius}px`;

  if (negative) {
    return {
      borderRadiusBottomLeft: borderRadiusPx,
      borderRadiusBottomRight: borderRadiusPx,
    };
  }

  return {
    borderRadiusTopLeft: borderRadiusPx,
    borderRadiusTopRight: borderRadiusPx,
  };
};

const getTypeOptions = (type) => {
  let options;

  switch (type) {
    case ChartType.AREA:
      options = areaChartOptions;
      break;

    case ChartType.AREA_SPLINE:
      options = areaSplineChartOptions;
      break;

    case ChartType.COLUMN:
    case ChartType.MIRROR_COLUMN:
    case ChartType.STACKED_COLUMN:
    case ChartType.COLUMN_DURATION:
    case ChartType.COLUMN_SITE_ACTIVE:
      options = columnChartOptions;
      break;
    case ChartType.COLUMN_GROUP:
      options = columnGroupChartOptions;
      break;
    case ChartType.LINE:
      options = lineChartOptions;
      break;

    case ChartType.ROSE:
      options = roseChartOptions;
      break;

    case ChartType.SCATTER:
      options = scatterChartOptions;
      break;

    case ChartType.SPLINE:
      options = splineChartOptions;
      break;

    default:
      options = {};
  }

  // return a clone so we don't mutate the original options
  return clone(options);
};

// returns the underlying set of points for a clustered point, or just the point given if not clustered
export const getOriginalPoints = (point) => {
  if (point.clusteredData) {
    const seriesData = {
      options: {
        custom: {
          assetId: point.series.options.custom.assetId,
        },
      },
    };
    return point.clusteredData.map((data) => ({
      ts: data.options.ts,
      x: data.options.x,
      y: data.options.y,
      series: seriesData,
    }));
  }

  return [point];
};

const getThemeOptions = ({ colors: overrideColors, theme, type }) => {
  if (!theme) {
    return {};
  }

  const { charts } = theme;
  // TODO: colors currently are high contrast (level 1)
  // but need to be able to pull in colors on scale (level 2) for stacked columns
  // can use the stacked option to decide what colors to pull in
  const {
    axisTitleColor,
    colors: chartColors,
    gridLineColor,
    legendTitleColor,
    lineColor,
    selectedColor,
    tooltipBackgroundColor,
    tooltipColor,
    selectedPoint,
  } = charts;
  // use override colors if provided
  const { borderColor, color, colors: typeColors, negativeColor } = charts[type] || {};
  const colors = overrideColors || typeColors || chartColors;

  const axisOptions = {
    gridLineColor,
    labels: {
      style: {
        color: axisTitleColor,
      },
    },
    lineColor,
    tickColor: gridLineColor,
    title: {
      style: {
        color: axisTitleColor,
      },
    },
  };

  return {
    colors,
    legend: {
      itemStyle: {
        // can define this separate from title color if needed
        color: legendTitleColor,
      },
      itemHoverStyle: {
        color: legendTitleColor,
      },
      title: {
        style: {
          color: legendTitleColor,
        },
      },
    },
    series: [
      {
        borderColor,
        borderWidth: borderColor ? 1 : 0,
        color,
        negativeColor,
        states: {
          select: {
            color: selectedColor,
          },
        },
        marker: {
          radius: 2,
          states: {
            select: {
              radius: 4,
              lineWidth: 2,
              fillColor: selectedPoint.fillColor,
              lineColor: selectedPoint.lineColor,
            },
          },
        },
      },
    ],
    tooltip: {
      backgroundColor: tooltipBackgroundColor,
      style: {
        color: tooltipColor,
      },
    },
    xAxis: {
      ...axisOptions,
    },
    yAxis: {
      ...axisOptions,
    },
  };
};

// options for stuff like h/w, axis labels, ticks, intervals, etc
const getPlotOptions = ({
  categories,
  height,
  legendTitle,
  xMin,
  xMax,
  max,
  maxSelect,
  min,
  negative,
  noDataLabel,
  onClick,
  onSelect,
  onSelected,
  selectable,
  // for stacked vs. side-by-side
  stacked,
  turboThreshold,
  boostThreshold,
  width,
  xAxisLabelFormatter,
  xAxisLabelHeight,
  xAxisScrollable,
  xAxisTickInterval,
  xAxisTitle,
  xAxisType,
  xAxisVisible,
  xAxisLabelVisible,
  xAxisLabelsRotation,
  xAxisLabelsStep,
  xAxisTickLength,
  xAxisTickWidth,
  xAxisPlotLines,
  xAxisMaxPadding,
  xAxisTickmarkPlacement,
  yAxisAllowDecimals,
  yAxislabelsYPosition,
  yAxisLabelFormatter,
  yAxisTickInterval,
  yAxisTitle,
  yAxisVisible,
  yAxisOpposite,
  yAxisPlotLines,
  yAxisOffset,
  yAxisTickWidth,
  yAxisTickLength,
  yAxisTickPosition,
  zoom,
  marginLeft,
  marginRight,
  disableSideMargin,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingBottom,
  useUTC,
  ceiling,
  endOnTick,
  grouped,
  useClustering,
}) => {
  const borderRadius = stacked || grouped ? {} : getBorderRadius({ negative });
  const marginBottom = xAxisLabelHeight;

  return {
    chart: {
      events: {
        // draggable selection
        selection:
          onSelect &&
          function(event) {
            const { originalEvent, xAxis, yAxis } = event || {};
            const { ctrlKey, metaKey } = originalEvent || {};
            const multi = ctrlKey || metaKey;

            // check for multi keys or shift or if zoom is added to chart props
            if (multi || !zoom) {
              event.preventDefault();
            }

            let selectedPoints = multi ? this.getSelectedPoints() || [] : [];

            // select points in envelope (until we hit max selection if defined)
            if (xAxis !== undefined && yAxis !== undefined && multi) {
              if (!maxSelect || selectedPoints.length < maxSelect) {
                loop1: for (const { options } of this.series) {
                  const { allowPointSelect, data, custom } = options;
                  if (!allowPointSelect || custom.isSelectedPointsSeries) {
                    continue loop1;
                  }
                  for (const point of data) {
                    if (
                      point.x >= xAxis[0].min &&
                      point.x <= xAxis[0].max &&
                      point.y >= yAxis[0].min &&
                      point.y <= yAxis[0].max
                    ) {
                      // TODO: add chart id so point correlation logic can be skipped for the chart the points were selected on originally
                      const selectedPoint = {
                        x: point.x,
                        y: point.y,
                        ts: point.ts,
                      };
                      selectedPoints.push(selectedPoint);
                    }
                    if (selectedPoints.length === maxSelect) {
                      break loop1;
                    }
                  }
                }
              }
            }

            // The set of selected points may include clustered points.
            // Translate to the set of all selected individual points in the underlying data.
            const selection = selectedPoints.flatMap((point) => getOriginalPoints(point));

            onSelect({ multi, selection });
          },

        click: function(event) {
          // using event to check if user has clicked on the reset button
          const resetZoomClicked = event.srcElement.firstChild?.data === 'Reset zoom';

          const currentSelection = this.getSelectedPoints() || [];

          // set all points to false if user has not clicked on reset zoom button
          if (!resetZoomClicked) {
            currentSelection.forEach((point) => point.select(false));
            onSelect({ multi: false, selection: [] });
          } else if (currentSelection.length) {
            const selection = currentSelection.flatMap((point) => getOriginalPoints(point));
            onSelect({ multi: false, selection });
          }
        },
      },
      // if we have bottom margin to account for x axis labels and/or title, then bump up the height
      // so chart axes will line up across charts regardless of whether they have x axes labels
      height: height + marginBottom,
      // if margin is 0, then we bump up to 1 so it doesn't obscure the x-axis
      marginBottom: marginBottom === 0 ? 1 : marginBottom,

      // If margin is not disabled apply marginLeft based on yAxisOpposite
      // TODO: Need to handle this in a better way after understanding all graphs using this component
      marginLeft: !disableSideMargin && !yAxisOpposite ? 60 : marginLeft,

      // If margin is not disabled decide marginRight based on y axis title
      // we don't account for label height because y axis labels are inset
      // TODO: Need to handle this in a better way after understanding all graphs using this component.
      marginRight: !disableSideMargin && yAxisTitle ? AXIS_TITLE_HEIGHT : marginRight,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      width,
      zoomType: selectable || zoom ? 'xy' : null,
    },
    lang: {
      // wrap data label in our own typography styling
      noData: `
        <div class='body-4'>
          ${noDataLabel}
        <div>
      `,
    },
    legend: {
      title: {
        text: legendTitle,
      },
    },
    noData: {
      position: {
        y: -12,
      },
      useHTML: true,
    },
    tooltip: {
      formatter: function() {
        return this.series?.tooltipOptions?.pointFormatter?.call(this.point);
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: selectable,
        stacking: stacked ? 'normal' : null,
        cursor: 'pointer',
        turboThreshold: turboThreshold || 0,
        boostThreshold: boostThreshold,
        cluster: !useClustering
          ? {}
          : {
              enabled: true,
              drillToCluster: false,
              layoutAlgorithm: {
                type: 'grid',
                gridSize: 5,
              },
              dataLabels: {
                enabled: false,
              },
              marker: {
                // TODO: this is duplicated from scatter-chart-options
                enabled: true,
                radius: 2,
                symbol: 'circle',
              },
            },
      },
    },
    series: [
      {
        ...borderRadius,
        point: {
          events: {
            mouseOver: function() {
              if (this.series.halo) {
                this.series.halo
                  .attr({
                    class: 'highcharts-tracker',
                  })
                  .toFront();
              }
            },
            // get selected points from click event
            click:
              onClick &&
              function(event) {
                const { ctrlKey, metaKey, point } = event;
                const multi = ctrlKey || metaKey;

                // if selection enabled then return running total of selected points
                if (selectable && this.series.options.allowPointSelect) {
                  let selection;

                  const { selected } = point;
                  const originalPoints = getOriginalPoints(point);
                  const originalPointsTs = originalPoints.map((op) => op.ts);

                  // The set of selected points for the series rendered on the chart, which may include clustered points.
                  const selectedPoints = this.series.chart.getSelectedPoints() || [];

                  // selecting multiple points
                  if (multi) {
                    // Translate selected points to the set of all selected individual points for the series in the underlying data.
                    selection = selectedPoints.flatMap((point) => getOriginalPoints(point));

                    // point is being unselected so remove from selection
                    if (selected) {
                      selection = selection.filter(
                        ({ ts: pointTs }) => !originalPointsTs.includes(pointTs),
                      );
                      // no max selection or haven't hit max so add point
                    } else if (!maxSelect || selection.length < maxSelect) {
                      selection = [...selection, ...originalPoints];
                    } else {
                      // use current selection without adding point and suppress high charts from selecting point since we've hit max
                      event.preventDefault();
                    }
                    // in the process of deselecting
                  } else if (selected) {
                    // ran into an issue with highcharts not correctly deselecting points in some scenarios
                    // so we override the behavior here with our own logic to clear selection
                    selectedPoints.forEach((point) => point.select(false));

                    event.preventDefault();

                    selection = [];
                    // selecting a single point
                  } else {
                    selection = originalPoints;
                  }

                  onSelect({ multi, point, selection });
                }

                onClick({ multi, point });
              },
            ...// individual point selection
            (selectable
              ? {
                  select: function() {
                    const { series } = this;
                    // grab all selected, if user is indivudally selecting multiple with cmd key
                    const selection = series.chart.getSelectedPoints() || [];

                    onSelected({ selection });
                  },
                  // does exact same thing as select function currently so could make more dry
                  unselect: function() {
                    onSelected({ selection: this.series.chart.getSelectedPoints() || [] });
                  },
                }
              : {}),
          },
        },
      },
    ],
    xAxis: {
      categories,
      labels: {
        formatter: xAxisLabelFormatter
          ? function() {
              return xAxisLabelFormatter({
                name: this.name,
                axis: this.axis,
                chart: this.chart,
                index: this.pos,
                isFirst: this.isFirst,
                isLast: this.isLast,
                label: this.axis.defaultLabelFormatter.call(this),
                value: this.value,
              });
            }
          : null,
        enabled: xAxisLabelVisible,
        rotation: xAxisLabelsRotation,
        step: xAxisLabelsStep,
      },
      scrollbar: {
        enabled: xAxisScrollable,
      },
      tickmarkPlacement: xAxisTickmarkPlacement,
      tickInterval: xAxisTickInterval,
      tickLength: xAxisTickLength,
      tickWidth: xAxisTickWidth,
      title: {
        text: xAxisTitle,
      },
      type: xAxisType,
      visible: xAxisVisible,
      plotLines: xAxisPlotLines,
      min: xMin,
      max: xMax,
      maxPadding: xAxisMaxPadding,
    },
    yAxis: {
      allowDecimals: yAxisAllowDecimals,
      labels: {
        formatter() {
          if (this.value < 0) {
            // push labels for negative values below gridline
            // TODO: revisit if this is the best place to do this
            this.axis.options.labels.y = 12;
            this.axis.options.showFirstLabel = false;
          } else if (this.value === 0 && !negative) {
            this.axis.options.labels.y = -4;
          }

          return yAxisLabelFormatter
            ? yAxisLabelFormatter({
                axis: this.axis,
                chart: this.chart,
                index: this.pos,
                isFirst: this.isFirst,
                isLast: this.isLast,
                label: this.axis.defaultLabelFormatter.call(this),
                value: this.value,
              })
            : this.value;
        },
        x: !yAxisOpposite ? -15 : null,
        y: !yAxislabelsYPosition ? (!yAxisOpposite ? (min ? 5 : -5) : null) : yAxislabelsYPosition,
      },
      max,
      min,
      ceiling,
      endOnTick,
      tickInterval: yAxisTickInterval,
      title: {
        text: yAxisTitle,
      },
      visible: yAxisVisible,
      opposite: yAxisOpposite,
      plotLines: yAxisPlotLines,
      offset: yAxisOffset,
      tickWidth: yAxisTickWidth,
      tickLength: yAxisTickLength,
      tickPosition: yAxisTickPosition,
    },
    time: {
      useUTC: useUTC,
    },
  };
};

// options specific to individual series
const getSeriesOptions = ({ colors = [], series = [], type, stacked = false }) => {
  const options = {
    series: series.map(
      (
        {
          color,
          tooltipEnabled,
          tooltipHeader,
          tooltipPoint,
          tooltipPointFormatter,
          type,
          assetId,
          isSelectedPointsSeries,
          ...options
        },
        i,
      ) => {
        // grab type options for the series
        const { series: typeSeries = [] } = getTypeOptions(type);
        const typeOptions = typeSeries[0] || {};

        return {
          ...typeOptions,
          ...options,
          // this fixes a bug with colors "drifting" when toggling back and forth between charts
          color: color ?? colors[i],
          // only disable (suppress tooltips) if tooltipEnabled flag explicitly set to false
          // https://www.highcharts.com/forum/viewtopic.php?t=12837
          enableMouseTracking: tooltipEnabled !== false,
          custom: {
            assetId,
            isSelectedPointsSeries,
          },
          tooltip: {
            headerFormat: tooltipHeader || '',
            pointFormat: tooltipPoint,
            pointFormatter: tooltipPointFormatter
              ? function() {
                  const { color, id, name, x, y, date, ts, clusteredData } = this;

                  // can expand how much stuff we pass in here
                  return tooltipPointFormatter({ color, id, name, x, y, date, ts, clusteredData });
                }
              : null,
          },
        };
      },
    ),
  };

  // if series is stacked, we only apply border radius to the top series
  // there could be an issue if the top series has a value of 0 for a given category
  // and it shows the next series at the top with squared borders
  // I talked to dave le about this and he's okay with dealing with that situation if we encounter it
  if (stacked && type !== ChartType.ROSE) {
    const { borderRadiusTopLeft, borderRadiusTopRight } = getBorderRadius();
    const topSeries = options.series && options.series[0];

    if (topSeries) {
      topSeries.borderRadiusTopLeft = borderRadiusTopLeft;
      topSeries.borderRadiusTopRight = borderRadiusTopRight;
    }
  }

  return options;
};

// parses our chart options into the highcharts options to get the look and behavior we expect
export const chartOptionsFactory = ({
  categories,
  colors,
  height,
  legendTitle,
  xMin,
  xMax,
  max,
  maxSelect,
  min,
  negative,
  noDataLabel,
  onClick,
  onSelect,
  onSelected,
  selectable,
  series,
  stacked,
  theme,
  turboThreshold,
  boostThreshold,
  type,
  width,
  xAxisLabelFormatter,
  xAxisLabelHeight,
  xAxisScrollable,
  xAxisTickInterval,
  xAxisTitle,
  xAxisTitleHeight,
  xAxisType,
  xAxisVisible,
  xAxisLabelVisible,
  xAxisLabelsRotation,
  xAxisLabelsStep,
  xAxisTickLength,
  xAxisTickWidth,
  xAxisPlotLines,
  xAxisMaxPadding,
  xAxisTickmarkPlacement,
  yAxisAllowDecimals,
  yAxislabelsYPosition,
  yAxisLabelFormatter,
  yAxisTickInterval,
  yAxisTitle,
  yAxisVisible,
  yAxisOpposite,
  yAxisPlotLines,
  yAxisOffset,
  yAxisTickWidth,
  yAxisTickLength,
  yAxisTickPosition,
  zoom,
  marginLeft,
  marginRight,
  disableSideMargin,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingBottom,
  useUTC,
  endOnTick,
  ceiling,
  tooltip,
  grouped,
  useClustering,
} = {}) =>
  mergeOptionsRight(
    // generic -> specific
    mergeOptionsRight(chartOptions, { tooltip }),
    getTypeOptions(type),
    getThemeOptions({ colors, theme, type }),
    getPlotOptions({
      categories,
      height,
      legendTitle,
      xMin,
      xMax,
      max,
      maxSelect,
      min,
      negative,
      noDataLabel,
      onClick,
      onSelect,
      onSelected,
      selectable,
      stacked,
      turboThreshold,
      boostThreshold,
      width,
      xAxisLabelFormatter,
      xAxisLabelHeight,
      xAxisScrollable,
      xAxisTickInterval,
      xAxisTitle,
      xAxisTitleHeight,
      xAxisType,
      xAxisVisible,
      xAxisLabelVisible,
      xAxisLabelsRotation,
      xAxisLabelsStep,
      xAxisTickLength,
      xAxisTickWidth,
      xAxisPlotLines,
      xAxisMaxPadding,
      xAxisTickmarkPlacement,
      yAxisAllowDecimals,
      yAxislabelsYPosition,
      yAxisLabelFormatter,
      yAxisTickInterval,
      yAxisTitle,
      yAxisVisible,
      yAxisOpposite,
      yAxisPlotLines,
      yAxisOffset,
      yAxisTickWidth,
      yAxisTickLength,
      yAxisTickPosition,
      zoom,
      marginLeft,
      marginRight,
      disableSideMargin,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      useUTC,
      ceiling,
      endOnTick,
      grouped,
      useClustering,
    }),
    getSeriesOptions({ colors, type, series, stacked }),
  );

// when updating an existing chart
// mainly for data updates
export const chartOptionsUpdateFactory = ({ colors, series = [], theme, type }) =>
  mergeOptionsRight(
    type ? mergeOptionsRight(getTypeOptions(type), getThemeOptions({ colors, theme, type })) : {},
    getSeriesOptions({ series }),
  );
