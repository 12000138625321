import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { elevations } from '@ge/tokens/elevations';

import GaugeBG from './stat-gauge-bg';

const StyledGauge = styled.div`
  height: 42px;
  width: 72px;
  padding: 10px 0;
  position: relative;
  div {
    z-index: ${elevations.P2};
    position: relative;
  }
  span {
    font-size: 11px;
    font-weight: 500;
    color: ${(props) => props.theme.gaugeStatChart.textColor};
    display: block;
    width: 100%;
    line-height: 11px;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${elevations.P1};
  }
`;

export const BasicGauage = ({ value1, value2 }) => (
  <StyledGauge>
    <GaugeBG />
    <div className="kpi-1">
      {value1}
      <span>{value2}</span>
    </div>
  </StyledGauge>
);

BasicGauage.propTypes = {
  value1: PropTypes.string,
  value2: PropTypes.string,
};

BasicGauage.defaultProps = {
  value1: '',
  value2: '',
};
