import { PropTypes } from 'prop-types';
import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { AnalyzeLocators } from '@ge/models/data-locators';

import { DataLoader } from '../../data-loader';
import { Chart } from '../chart';
import { ChartType } from '../models';

const ChartRegion = {
  LOWER: 'lower',
  UPPER: 'upper',
};

const getSignedSeries = (series = {}, negative = false) => {
  const sign = negative ? -1 : 1;
  const signCoercion = (val) => {
    // coerce all data to sign
    // TODO: look into whether we need to discard values
    // when data has mixed -/+ values
    const y = sign * Math.abs(val.y || val);
    return val.y ? { ...val, y } : y;
  };

  return {
    ...series,
    data: series.data ? series.data.map(signCoercion) : [],
  };
};

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
  > * {
    flex: 1;
    overflow: visible !important;
  }
`;

const mirrorColumnChartApi = ({ lower = {}, upper = {} }) => {
  // chart hasn't been initliazed yet
  if (!(lower && upper)) {
    return {};
  }

  return {
    selectBy: (args) => {
      upper.selectBy(args);
      lower.selectBy(args);
    },
    setUpperData: ({ data = [] }) => {
      const { data: positiveData } = getSignedSeries({ data });

      upper.setData({ data: positiveData });
    },
    setLowerData: ({ data = [] }) => {
      const { data: negativeData } = getSignedSeries({ data }, true);

      lower.setData({ data: negativeData });
    },
  };
};

export const MirrorColumnChart = forwardRef(
  (
    {
      categories,
      height,
      lowerChart,
      maxSelect,
      onCreated,
      onSelected,
      selectable,
      upperChart,
      width,
      xAxisVisible,
      xAxisTitle,
      isLoading,
    },
    ref,
  ) => {
    // api is defined one time when charts are created
    const [chartApi, _setChartApi] = useState({ lower: null, upper: null });
    const chartApiRef = useRef(chartApi);

    // do we have a custom hook for stateRef already (that can take a callback that accepts prev state)?
    const setChartApi = (api) => {
      let state = api;

      if (typeof api === 'function') {
        state = api(chartApiRef.current);
      }

      chartApiRef.current = state;

      _setChartApi(state);
    };

    useImperativeHandle(ref, () => mirrorColumnChartApi(chartApiRef.current));

    // emit created event when both charts are created
    useEffect(() => {
      const { lower, upper } = chartApiRef.current || {};

      lower && upper && onCreated(mirrorColumnChartApi({ lower, upper }));
    }, [chartApi, onCreated]);

    if (!(upperChart && lowerChart)) {
      return null;
    }

    const handleSelected = (region, { selection = [] }) => {
      const { lower, upper } = chartApiRef.current || {};

      const [mirrorApi, mirrorRegion] = {
        [ChartRegion.LOWER]: [upper, ChartRegion.UPPER],
        [ChartRegion.UPPER]: [lower, ChartRegion.LOWER],
      }[region];
      // select same points in mirror chart
      const mirrorSelection = mirrorApi && mirrorApi.selectByX({ x: selection.map(({ x }) => x) });

      onSelected({
        [region]: selection,
        [mirrorRegion]: mirrorSelection,
      });
    };

    const mirrorHeight = Math.round(height / 2);

    // force values to positive/negative for upper/lower series, respectively
    const positiveSeries = upperChart.series ? [getSignedSeries(upperChart.series[0])] : [];
    const negativeSeries = lowerChart.series ? [getSignedSeries(lowerChart.series[0], true)] : [];

    return (
      <Container data-testid={AnalyzeLocators.ANALYZE_MIRROR_CHART_DATA_WRAP}>
        <DataLoader isLoading={isLoading} type={ChartType.MIRROR_COLUMN} renderCondition>
          <Chart
            categories={categories}
            height={mirrorHeight}
            max={upperChart.max}
            maxSelect={maxSelect}
            min={upperChart.min}
            noDataLabel={upperChart.noDataLabel}
            onCreated={(upper) => setChartApi(({ lower }) => ({ lower, upper }))}
            onSelect={(event) => handleSelected(ChartRegion.UPPER, event)}
            selectable={selectable}
            series={positiveSeries}
            tooltipHeader={upperChart.tooltipHeader}
            tooltipPoint={upperChart.tooltipPoint}
            tooltipPointFormatter={upperChart.tooltipPointFormatter}
            type={ChartType.COLUMN}
            width={width}
            xAxisLabelFormatter={upperChart.xAxisLabelFormatter}
            xAxisLabelHeight={0}
            xAxisType={upperChart.xAxisType}
            xAxisVisible={false}
            yAxisLabelFormatter={upperChart.yAxisLabelFormatter}
            yAxisTickInterval={upperChart.yAxisTickInterval}
            disableSideMargin
          />
          {negativeSeries && (
            <>
              <Chart
                categories={categories}
                height={mirrorHeight}
                max={!lowerChart.min || lowerChart.min >= 0 ? 0 : -lowerChart.min}
                maxSelect={maxSelect}
                min={lowerChart.max && -lowerChart.max}
                negative
                noDataLabel={lowerChart.noDataLabel}
                onCreated={(lower) => setChartApi(({ upper }) => ({ lower, upper }))}
                onSelect={(event) => handleSelected(ChartRegion.LOWER, event)}
                selectable={selectable}
                series={negativeSeries}
                tooltipHeader={lowerChart.tooltipHeader}
                tooltipPoint={lowerChart.tooltipPoint}
                tooltipPointFormatter={lowerChart.tooltipPointFormatter}
                type={ChartType.COLUMN}
                width={width}
                xAxisLabelFormatter={lowerChart.xAxisLabelFormatter}
                xAxisType={lowerChart.xAxisType}
                xAxisVisible={xAxisVisible}
                xAxisLabelVisible={false}
                xAxisTitle={xAxisTitle}
                xAxisTickLength={0}
                yAxisLabelFormatter={lowerChart.yAxisLabelFormatter}
                yAxisTickInterval={lowerChart.yAxisTickInterval}
                disableSideMargin
              />
            </>
          )}
        </DataLoader>
      </Container>
    );
  },
);

const seriesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          name: PropTypes.string,
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ]),
    ).isRequired,
  }),
);

// a lot of overlap with chart props defined in chart component, can look at reusing
const chartPropTypes = PropTypes.shape({
  colors: PropTypes.arrayOf(PropTypes.string),
  max: PropTypes.number,
  min: PropTypes.number,
  noDataLabel: PropTypes.string,
  series: seriesPropType.isRequired,
  tickInterval: PropTypes.number,
  title: PropTypes.string,
  tooltipHeader: PropTypes.string,
  tooltipPoint: PropTypes.string,
  tooltipPointFormatter: PropTypes.func,
  xAxisLabelFormatter: PropTypes.func,
  xAxisType: PropTypes.string,
  yAxisLabelFormatter: PropTypes.func,
});

MirrorColumnChart.displayName = 'MirrorColumnChart';

MirrorColumnChart.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  height: PropTypes.number,
  isLoading: PropTypes.bool,
  lowerChart: chartPropTypes.isRequired,
  maxSelect: PropTypes.number,
  onCreated: PropTypes.func,
  onSelected: PropTypes.func,
  selectable: PropTypes.bool,
  upperChart: chartPropTypes.isRequired,
  width: PropTypes.number,
  xAxisVisible: PropTypes.bool,
  xAxisTitle: PropTypes.string,
};

MirrorColumnChart.defaultProps = {
  categories: undefined,
  isLoading: false,
  maxSelect: undefined,
  onCreated: () => {},
  onSelected: () => {},
  selectable: false,
  width: undefined,
  xAxisVisible: false,
  xAxisTitle: undefined,
};
