import { useMemo } from 'react';

import { useWidgetKpiData } from '@ge/feat-reporting/data-hooks/use-widget-kpi-data';
import { useWidgetOperatingHours } from '@ge/feat-reporting/data-hooks/use-widget-operating-hours';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';

export const useWidgetPerformanceSummary = ({
  config,
  endDate,
  name = WidgetNames.PERFORMANCE_SUMMARY,
  startDate,
}) => {
  const { data: kpiData, isLoading: isKpiDataLoading } = useWidgetKpiData({
    endDate,
    isActive: config?.enabled ?? true,
    includePlaceholderData: true,
    name,
    startDate,
  });

  const { data: operatingHoursData, isLoading: isOperatingHoursDataLoading } =
    useWidgetOperatingHours({
      config,
      endDate,
      startDate,
    });

  const data = useMemo(() => {
    const kpiEntries = Object.entries(kpiData?.[name] ?? {});

    // no data so return default value
    if (!(operatingHoursData || kpiEntries.length)) {
      return null;
    }

    const performanceSummaryKpis = kpiEntries.reduce(
      (mapped, [kpiKey, { aggregateValue: value, units } = {}]) => {
        mapped[kpiKey] = {
          units,
          value,
        };

        return mapped;
      },
      {},
    );

    const { assetCount, ..._operatingHoursData } = operatingHoursData ?? {};

    return {
      assetCount,
      data: {
        ...performanceSummaryKpis,
        operatingHours: _operatingHoursData,
      },
    };
  }, [kpiData, name, operatingHoursData]);

  const isLoading = isKpiDataLoading || isOperatingHoursDataLoading;

  return { data, isLoading };
};
