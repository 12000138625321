import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ClearColumnFilter from '@ge/components/table/filters/clear-column-filter';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import {
  AnalysisColumnsDefs,
  GlobalAnalysisTemplates,
} from '@ge/feat-analyze/models/analysis-template-col';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { AdminDefs, AnaylzeDefs, EntityType } from '@ge/models/constants';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { killEventPropagation } from '@ge/shared/util';

import { useAnalysisTemplates } from '../../../../data-hooks/use-analysis-table';

import AnalysisTable from './analysis-table';

const ColumnSelectMenuDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 122px;
  display: flex;
  flex-direction: row-reverse;
`;

const ClearColumnDiv = styled.div`
  position: absolute;
  top: 124px;
  right: 213px;
  display: flex;
  flex-direction: row-reverse;
`;

export const AnalysisTemplates = () => {
  const { t, ready } = useTranslation(['admin.cases'], {
    useSuspense: false,
  });
  const [currentSearch, setCurrentSearch] = useState({});

  const { showDetails } = useContext(EntityDetailsContext);

  const ANALYSIS = AnaylzeDefs.ANALYSIS_TEMPLATE;

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ANALYSIS_TEMPLATE);

  const {
    filterSequence,
    filterDefs,
    onChange: setFilterDefs,
    onReset,
  } = useFilterDefs({
    columnDefs: AnalysisColumnsDefs,
    stateId: AdminDefs.ANALYSIS_TEMPLATE_ID,
  });

  // Table state management
  const {
    updateColumnVisibility: updateAnalysisColumnVisibility,
    setVisibleColumns: setVisibleAnomalyCols,
    visibleCols: AnalysisColumns,
    sortDirection: analysisSortDirection,
    sortMetric: analysisSortMetric,
    updateSortMetric: updateAnalysisSortMetric,
  } = useColumnState({
    columnDefs: AnalysisColumnsDefs,
    defaultCols: GlobalAnalysisTemplates,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: AnaylzeDefs.ANAYSIS_SORT_STATE_ID,
  });

  const { onVisibilityChange: handleAnomalyColumnChanges, onSelectionVisibilityChange } =
    useManageColumnVisibility({
      subKey: ANALYSIS,
      featPrefs,
      savePrefs,
      setVisibleColumns: setVisibleAnomalyCols,
      updateColumnVisibility: updateAnalysisColumnVisibility,
    });

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);
  // data hooks
  const {
    data: cases,
    filterValues,
    isAnalysisTemplateDataLoading,
  } = useAnalysisTemplates({
    filterSequence,
    filters: filterDefs,
    search: currentSearch,
    sortDirection: analysisSortDirection,
    sortMetric: analysisSortMetric,
  });

  const handleSelectTemplate = useCallback(
    (e, selectedTemplate) => {
      killEventPropagation(e);
      showDetails(selectedTemplate?.id, EntityType.ANALYSIS_TEMPLATE);
    },
    [showDetails],
  );

  if (!ready) {
    return null;
  }

  return (
    <DetailsDismissInert>
      <ClearColumnDiv>
        <ClearColumnFilter filters={filterDefs} onReset={onReset} />
      </ClearColumnDiv>
      <ColumnSelectMenuDiv>
        <ColumnSelectMenu
          translateFn={t}
          columnDef={AnalysisColumnsDefs}
          columnState={AnalysisColumns}
          onMenuClose={handleAnomalyColumnChanges}
          onClumnSelection={onSelectionVisibilityChange}
        />
      </ColumnSelectMenuDiv>
      <AnalysisTable
        data={cases}
        columns={AnalysisColumns}
        sortAction={updateAnalysisSortMetric}
        sortMetric={analysisSortMetric}
        sortDirection={analysisSortDirection}
        onFilterChange={handleFilterChange}
        onFilter={setFilterDefs}
        filterValues={filterValues}
        filterDefs={filterDefs}
        isLoading={isAnalysisTemplateDataLoading}
        onSelectTemplate={handleSelectTemplate}
        draggable
        resizable
      />
    </DetailsDismissInert>
  );
};

AnalysisTemplates.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

AnalysisTemplates.defaultProps = {
  location: null,
};

export default withRouter(AnalysisTemplates);
