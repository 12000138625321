/* eslint-disable react/button-has-type */
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import React, { useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Title } from '@ge/components/typography';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { PresentationTypes } from '@ge/models/constants';
import { CalendarRanges, ManageDefs } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';
import { fetchPrefs } from '@ge/shared/services/common';
import { typography } from '@ge/tokens';
// TODO (astone): This is an unholy reference to DWF from outside the app! Must remove!
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';

import { PlanningContext, DateFormats } from '../../context/planning-provider';

dayjs.extend(isoWeek);

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 14px 14px 0;
  .date {
    justify-content: space-between;
    display: flex;
    align-items: center;
    min-width: 255px;
    button:focus {
      outline: none;
    }
  }
`;

const RangeToggle = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  button {
    padding: 6px 0px;
    font-size: 13px;
    width: 30px;
    text-align: center;
    color: ${(props) => props.theme.manage.rangeToggle.textColor};
    background: ${(props) => props.theme.manage.rangeToggle.backgroundColor};
    border: solid 1px ${(props) => props.theme.manage.rangeToggle.borderColor};
    font-weight: ${typography.weight.medium};
    &:focus {
      outline: none;
    }
    &.active {
      background: ${(props) => props.theme.manage.rangeToggle.activeBackgroundColor};
      border-color: ${(props) => props.theme.manage.rangeToggle.activeBorderColor};
      font-weight: ${typography.weight.bold};
    }
  }
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.manage.dateChevronColor,
}))`
  padding: 0 4px;
`;

const CalendarIcon = styled(Icon).attrs((props) => ({
  size: 15,
  icon: Icons.CALENDAR,
  color: props.theme.manage.dateChevronColor,
}))`
  margin-right: 8px;
  margin-top: -3px;
`;

const DateText = styled(Title).attrs(() => ({
  level: 2,
}))`
  color: ${(props) => props.theme.manage.dateHeaderColor};
  cursor: pointer;
`;

/**
 * Set list of view tyoes for Presentation Toggle
 */
const PresentationViews = [PresentationTypes.GRID];

export const CalendarHeader = () => {
  const FEAT_PREFS_SUB_KEY = ManageDefs.OVERVIEW;
  const { t } = useTranslation(['manage.planning']);
  const MANAGE_OVERVIEW = AppScopes.MANAGE_OVERVIEW;
  const { featPrefs: _featPrefs } = useFeaturePrefs(MANAGE_OVERVIEW);
  const featPrefs = _featPrefs?.[FEAT_PREFS_SUB_KEY];
  const {
    planningState: { setRange, range, date, prevDate, handleEditEnd, nextDate, setDatePickerDate },
  } = useContext(PlanningContext);

  useEffect(() => {
    async function fetchPrefsAPI() {
      const { prefs } = await fetchPrefs();
      return prefs;
    }
    if (!featPrefs) {
      const response = fetchPrefsAPI();
      let featManageOverview;
      response.then((result) => {
        featManageOverview = result?.featurePrefs[MANAGE_OVERVIEW]?.overview;
        if (featManageOverview && featManageOverview.length > 0) {
          setRange(featManageOverview && featManageOverview[0]?.cols, featManageOverview[1]?.cols);
        } else {
          setRange(featPrefs && featPrefs[0]?.cols);
        }
      });
    } else {
      setRange(featPrefs && featPrefs[0]?.cols, featPrefs[1]?.cols);
    }
  }, [range]);
  const getDate = useMemo(() => {
    const formattedDate =
      range.type !== CalendarRanges[range.type]
        ? dayjs(date).format(DateFormats[range.type])
        : null;

    if (range.type === CalendarRanges.DAY && dayjs().isSame(date, 'day')) {
      return `${t('today', 'Today')} ${formattedDate}`;
    }

    if (range.type === CalendarRanges.WEEK) {
      let weekNo = dayjs(date).isoWeek(); //to display correct isoweek in header
      let weekStart = dayjs(range.start).format(DateFormats.week_start);
      let weekEnd = dayjs(range.end).format(DateFormats.week_end);
      return `${t('rangeOptions.week', 'W')} ${weekNo} / ${weekStart} - ${weekEnd}`;
    }

    if (range.type === CalendarRanges.QUARTER) {
      const quarterNo = dayjs(date).quarter();
      const year = dayjs(date).year();
      return `${t('rangeOptions.quarter', 'Q')} ${quarterNo} / ${year}`;
    }

    return formattedDate;
  }, [date, t, range]);

  const ActiveClass = (type) => (range.type === type ? 'active' : null);

  return (
    <Header>
      <RangeToggle>
        {/* // Updates for MVP delivery #1566 */}
        <button
          onClick={() => setRange(CalendarRanges.DAY)}
          className={ActiveClass(CalendarRanges.DAY)}
        >
          {t('rangeOptions.day', 'D')}
        </button>
        <button
          onClick={() => setRange(CalendarRanges.WEEK)}
          className={ActiveClass(CalendarRanges.WEEK)}
        >
          {t('rangeOptions.week', 'W')}
        </button>
        <button
          onClick={() => setRange(CalendarRanges.MONTH)}
          className={ActiveClass(CalendarRanges.MONTH)}
        >
          {t('rangeOptions.month', 'M')}
        </button>
        <button
          onClick={() => setRange(CalendarRanges.QUARTER)}
          className={ActiveClass(CalendarRanges.QUARTER)}
        >
          {t('rangeOptions.quarter', 'Q')}
        </button>
        {/* Commenting out until other date CalendarRanges are completed */}
        {/* <button onClick={() => setRange(CalendarRanges.YEAR)} className={ActiveClass(CalendarRanges.YEAR)}>
          {t('rangeOptions.year', 'Y')}{' '}
        </button> */}
      </RangeToggle>
      <div className="date">
        <button
          onClick={() => {
            prevDate(date, range.type);
            handleEditEnd();
          }}
        >
          <ChevronIcon rotate={90} />
        </button>
        <DateText>
          <DatePicker
            className="input"
            placeholderText="Select date"
            showMonthYearPicker={range.type === CalendarRanges.MONTH}
            showWeekNumbers={range.type === CalendarRanges.WEEK}
            showQuarterYearPicker={range.type === CalendarRanges.QUARTER}
            calendarStartDay={1}
            selected={date}
            onChange={(date) => setDatePickerDate(range.type, date)}
            customInput={
              <div>
                <CalendarIcon />
                {getDate}
              </div>
            }
          />
        </DateText>
        <button
          onClick={() => {
            nextDate(date, range.type);
            handleEditEnd();
          }}
        >
          <ChevronIcon rotate={-90} />
        </button>
      </div>

      <PresentationToggle viewList={PresentationViews} defaultView={PresentationTypes.GRID} />
    </Header>
  );
};
