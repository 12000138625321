import classNames from 'classnames';
import React, { useCallback } from 'react';

import { TableFilterTypes } from '@ge/models/constants';
import { uniqueVals, removeFalsyVals } from '@ge/util/array-utils';

import { DraggableCell, DraggableColumn, DraggableColumnGroup } from './draggable-table';
import FilterMenu from './filters/filter-menu';
import { buildA11yLabels, buildTooltip, Td, Th } from './table';

const DEFAULT_ALIGNMENT = 'center';
const DEFAULT_CELL_BORDER = false;

/**
 * Build the appropriate list of classes to apply to an element based on
 * it's position within it's parent column group.
 *
 * @param {number} colIdx index of the column being processed within it's column group
 * @param {number} colCount total number of columns in the parent column group
 * @param {number} entity entity
 */
const getClassNames = (colIdx, colCount, entity, header) => {
  if (
    (typeof colIdx === 'undefined' || typeof colCount === 'undefined') &&
    typeof entity === 'undefined'
  ) {
    return '';
  }

  return classNames({
    'column-header': header,
    'entity-cell': entity === true,
    'group-first': colIdx === 0,
    'group-last': colIdx === colCount - 1,
  });
};

export const useTableFactories = ({
  t,
  columnDefs,
  cellValueMapFn,
  customHeaderFn,
  customCellFn,
  sortAction,
  sortedDirection,
  draggable,
  filterValues,
  onFilterApply,
  onFilterChange,
  filters,
  rowsSelected = false,
  defaultCellBorder = DEFAULT_CELL_BORDER,
}) => {
  /**
   * Factory function to dynamically build the provided column group
   * based on the provided table definitions (`columnDefs`).
   *
   * TODO: Account for permissions here?
   */
  const columnGroupFactory = useCallback(
    (groupKey, columnArray) => {
      // Do not render a column group if there are no associated
      // columns visible underneath it.
      if (columnArray.length === 0) {
        return null;
      }

      const columnGroup = columnDefs[groupKey];

      // If no column group is defined for this key, bail.
      if (!columnGroup) {
        return null;
      }

      const dynamicProps = {
        className: 'column-group-header',
        isTitle: true,
        colSpan: columnArray.length,
        labels: columnArray.length > 1 ? buildA11yLabels(t, columnGroup) : {},
        key: groupKey,
        dataKey: groupKey,
        groupKey,
        fixedLeft: columnGroup.fixedLeft,
        fixedRight: columnGroup.fixedRight,
        resizable: columnGroup.resizable,
      };

      return React.createElement(draggable ? DraggableColumnGroup : Th, dynamicProps);
    },
    [columnDefs, t, draggable],
  );

  const getFilterValue = useCallback(
    (colum, columnKey) => {
      const _filterVal = filters?.[columnKey];

      if (!_filterVal) return null;

      const { min, max, value } = _filterVal;
      const { filterType } = colum;

      switch (filterType) {
        case TableFilterTypes.DATE:
          return min && max && [min, max];
        case TableFilterTypes.NUMBER:
          return min && max ? [min, max] : value;
        case TableFilterTypes.SEARCH:
          return value?.[0];
        case TableFilterTypes.CHECKBOXES:
        case TableFilterTypes.PROGRESSIVE_DROPDOWNS:
        case TableFilterTypes.PROGRESSIVE_MULTI_SELECT:
          return value;
        default:
          return null;
      }
    },
    [filters],
  );

  /**
   * Factory function to dynamically build individual column headers
   * based on the related table definition file.
   */
  const columnFactory = useCallback(
    (groupKey, columnKey, colIdx, colCount) => {
      const columnGroup = columnDefs[groupKey];
      const column = columnGroup && columnGroup.cols[columnKey];
      let colFilterValues = filterValues?.[columnKey];
      const defaultFilterValue = getFilterValue(column, columnKey);
      // If no column is defined for this key, bail.
      if (!column) {
        return null;
      }
      if (column?.filterTypeDefs?.nested) {
        colFilterValues = uniqueVals(colFilterValues?.reduce((acc, v) => [...acc, ...v], []) ?? []);
        colFilterValues = removeFalsyVals(colFilterValues);
        colFilterValues.sort((a, b) => {
          return String(a).localeCompare(b, undefined, { sensitivity: 'base' });
        });
      }

      const {
        noPadding,
        zeroPadding,
        align,
        width,
        color,
        minWidth,
        whiteSpace,
        filterType,
        cell,
      } = column;

      const { border } = cell ?? {};

      const dynamicProps = {
        className: getClassNames(colIdx, colCount, null, true),
        labels: buildA11yLabels(t, column),
        tooltip: buildTooltip(t, column),
        underline: color,
        sortedDirection,
        onClick: sortAction,
        key: columnKey,
        columnKey,
        groupKey,
        noPadding,
        minWidth,
        fixedLeft: columnGroup.fixedLeft,
        fixedRight: columnGroup.fixedRight,
        zeroPadding: zeroPadding,
        whiteSpace,
        align: align || columnGroup.align || DEFAULT_ALIGNMENT,
        border: typeof border !== 'undefined' ? border : defaultCellBorder,
        width,
        filter: filterType && (
          <FilterMenu
            columnKey={columnKey}
            columnDef={column}
            filterValues={colFilterValues}
            onApply={(value) => onFilterApply(groupKey, columnKey, value)}
            onChange={(value) => onFilterChange(groupKey, columnKey, value)}
            translateFn={t}
            value={defaultFilterValue}
            rowsSelected={rowsSelected}
          />
        ),
      };

      const customHeader = customHeaderFn && customHeaderFn(columnKey);
      return React.createElement(draggable ? DraggableColumn : Th, dynamicProps, customHeader);
    },
    [
      columnDefs,
      t,
      sortedDirection,
      sortAction,
      customHeaderFn,
      draggable,
      filterValues,
      onFilterApply,
      onFilterChange,
      getFilterValue,
      rowsSelected,
      defaultCellBorder,
    ],
  );

  /**
   * Factory function to dynamically generate table cells based on the provided
   * values leveraging the associated table definitions.
   *
   * NOTE: This is a function that returns a function so we can re-use the site
   * context on each cell in a row without rebuilding the context every time.
   *
   * TODO: Account for permissions here?
   */
  const cellFactory = useCallback(
    (value) => {
      // Define and set the cell value mapping for each column. This will feed both
      // primitive cells and custom cells. The function that returns this mapping must
      // be provided by the consumer when initializing the useTableFactories hook.
      const cellValues = cellValueMapFn(value);

      // The cell factory returns a function that executes against the context
      // that was created at the time of instantiation to avoid re-initializing.
      return (groupKey, columnKey, colIdx, colCount) => {
        // If there are no items in the values map, bail and wait for another re-render.
        if (!cellValues || !Object.keys(cellValues).length) {
          return null;
        }

        // Get Column configurations and associated cell preferences from table definitions file.
        const group = columnDefs[groupKey];
        const column = group && group.cols[columnKey];

        if (!column) {
          return null;
        }

        const cellValue = cellValues[columnKey] && cellValues[columnKey];
        const { align, border, width, maxWidth, colSpan, noPadding, entity, zeroPadding, style } =
          column.cell || {};

        // Build a props object that will be used to generate a new component programmatically.
        const dynamicProps = {
          className: getClassNames(colIdx, colCount, entity),
          key: columnKey,
          groupKey,
          columnKey,
          align: align || column.align || group.align || DEFAULT_ALIGNMENT, // Chain of override/fallback values
          border: typeof border !== 'undefined' ? border : defaultCellBorder,
          colSpan,
          noPadding,
          zeroPadding: zeroPadding,
          width,
          style,
          maxWidth,
          fixed: group.fixed,
          fixedTop: group.fixedTop,
          fixedLeft: group.fixedLeft,
          fixedRight: group.fixedRight,
        };

        // If the cell needs non-primitive rendering, get the JSX for the cell from the factory.
        const customCell = customCellFn && customCellFn(columnKey, cellValue, value, group, column);

        if (!customCell && typeof cellValue === 'undefined' && !!column.noRender) {
          return null;
        }

        return React.createElement(
          draggable ? DraggableCell : Td,
          dynamicProps,
          customCell || cellValue,
        );
      };
    },
    [cellValueMapFn, columnDefs, customCellFn, draggable, defaultCellBorder],
  );

  return [columnGroupFactory, columnFactory, cellFactory];
};
