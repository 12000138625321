import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import { EntityType } from '@ge/models/constants';
import TabDetailHeader from '@ge/web-client/src/app/components/entity-details/components/tab-detail-header';
import { TabDetailContainer } from '@ge/web-client/src/app/components/entity-details/entity-details-shared';

import CaseSensorReadingsChartFP from './case-sensor-readings-chart-fp';
import CaseSensorReadingsChartSignal from './case-sensor-readings-chart-signal';
import CaseSensorReadingsFields from './case-sensor-readings-fields';

const SensorReadingsContainer = styled.div`
  margin: 0 25px 40px 15px;
  min-height: 354px;
`;

const CaseSensorReadings = ({ issue, alert }) => {
  const [currentAlert, setCurrentAlert] = useState(null);

  useEffect(() => {
    setCurrentAlert(issue?.alerts?.find((a) => a.id === alert?.id));
  }, [issue, alert]);

  const renderChart = useCallback(() => {
    if (!currentAlert?.start) return null;

    if (currentAlert.FPObjectId && currentAlert.FPDetectionId) {
      return (
        <CaseSensorReadingsChartFP alert={currentAlert} asset={issue.asset} site={issue.site} />
      );
    }

    return (
      <CaseSensorReadingsChartSignal alert={currentAlert} asset={issue.asset} site={issue.site} />
    );
  }, [currentAlert, issue]);

  return (
    <TabDetailContainer>
      <TabDetailHeader entity={issue} entityType={EntityType.CASE} />
      <SensorReadingsContainer>
        <CaseSensorReadingsFields issue={issue} alert={currentAlert} onChange={setCurrentAlert} />
        {renderChart()}
      </SensorReadingsContainer>
    </TabDetailContainer>
  );
};

export default CaseSensorReadings;

CaseSensorReadings.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.instanceOf(Object),
};

CaseSensorReadings.defaultProps = {
  alert: null,
};
