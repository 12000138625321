import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const NoteMessageContainer = styled.div`
  color: ${({ theme }) => theme.fileUpload.errorTextColor};
`;

const NoteMessage = ({ message }) => {
  return message ? <NoteMessageContainer>{message}</NoteMessageContainer> : null;
};

NoteMessage.propTypes = {
  namespace: PropTypes.string,
  message: PropTypes.string,
};

NoteMessage.defaultProps = {
  namespace: 'entity-details',
  message: null,
};

export default NoteMessage;
