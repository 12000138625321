import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { typography } from '@ge/tokens';

const StyledGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 280px repeat(5, 80px);
  justify-items: center;
  padding: 20px 0 0 28px;

  .capabilityGroup {
    font-weight: ${typography.weight.bold};
    justify-self: start;
    /* padding-top: 15px; */
    text-transform: uppercase;
    &.headings {
      padding-top: 0;
    }
  }
  .heading-span {
    grid-column: 1 / span 6;
  }
  .capability {
    justify-self: start;
    /* padding: 17px 0 10px 20px; */
    padding: 10px 0 10px 20px;
  }
  .permission {
    align-items: center;
  }
`;

const CheckIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.admin.panel.body.check,
  size: 10,
}))``;

const StyledCheckbox = styled(Checkbox)`
  div {
    border: none;
  }

  span {
    margin-left: 0;
  }
`;

const PersonRoleDetail = ({ capabilityGroups }) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });
  const [checkedPermissions, setCheckedPermissions] = useState([]);

  // Find checked capability permissions and update checkedPermissions state
  useEffect(() => {
    capabilityGroups.forEach((group) => {
      group.permissions.forEach((permission) => {
        setCheckedPermissions((prevCheckedPermissions) => {
          const capabilityKey = `${group.name}_${permission}`;
          return prevCheckedPermissions.includes(capabilityKey)
            ? prevCheckedPermissions
            : [...prevCheckedPermissions, capabilityKey];
        });
      });
    });
  }, [capabilityGroups]);

  const getPermissions = useCallback(
    (capabilityGroup) => {
      return capabilityGroup?.permissions.map((permission, id) => {
        return (
          <React.Fragment key={id}>
            <div className="permission">
              {permission.length > 0 && !permission.readOnly ? (
                <StyledCheckbox
                  checkState={
                    checkedPermissions.includes(`${capabilityGroup.name}_${permission}`)
                      ? CheckedState.CHECKED
                      : CheckedState.UNCHECKED
                  }
                />
              ) : permission ? (
                <CheckIcon icon={Icons.CHECK} />
              ) : null}
            </div>
          </React.Fragment>
        );
      });
    },
    [checkedPermissions],
  );

  const getCapabilities = useCallback(
    (capabilityGroup) => {
      return (
        <React.Fragment>
          <div className="capability">
            {t(`capabilities.${capabilityGroup.name}`, capabilityGroup.name)}
          </div>
          {capabilityGroup?.permissions && getPermissions(capabilityGroup)}
        </React.Fragment>
      );
    },
    [getPermissions, t],
  );

  const getGridHeadings = useCallback(() => {
    return (
      <>
        <div className="heading">{t('assign_roles.view', 'View')}</div>
        <div className="heading">{t('assign_roles.edit', 'Edit')}</div>
        <div className="heading">{t('assign_roles.create', 'Create')}</div>
        <div className="heading">{t('assign_roles.delete', 'Delete')}</div>
        <div className="heading">{t('assign_roles.administer', 'Administer')}</div>
      </>
    );
  }, [t]);
  // removed {t(`capabilities.${capabilityGroup.name}`, capabilityGroup.name)} from line 159

  return (
    <StyledGrid className="capability-grid">
      {capabilityGroups.map((capabilityGroup, id) => {
        return (
          <React.Fragment key={id}>
            <div className={`capabilityGroup ${id && 'heading-span'}`}></div>
            {id === 0 && getGridHeadings()}
            {capabilityGroup?.permissions && getCapabilities(capabilityGroup)}
          </React.Fragment>
        );
      })}
    </StyledGrid>
  );
};

export default PersonRoleDetail;

PersonRoleDetail.propTypes = {
  capabilityGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditMode: PropTypes.bool,
};
