import { PropTypes } from 'prop-types';
import React from 'react';

import { SelectDefault } from './select-default';
import { SelectSearch } from './select-search';

// Setting to 0 for now. Will get updated at somepoint.
const SEARCH_THRESHOLD = 0;

export const Select = ({ searchable, options, ...rest }) => {
  return searchable && options?.length > SEARCH_THRESHOLD ? (
    <SelectSearch options={options} {...rest} />
  ) : (
    <SelectDefault options={options} {...rest} />
  );
};

Select.displayName = 'CustomSelect';

Select.propTypes = {
  options: PropTypes.instanceOf(Array),
  searchable: PropTypes.bool,
};

Select.defaultProps = {
  options: [],
  searchable: false,
};
