import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens';

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
`;

export const TaskDetailErrors = ({ className }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { errors } = useFormContext();

  return (
    <div className={className}>
      {errors?.eligibleStartDate?.type === 'eligibleStartDate' && (
        <ErrorText>
          {t('error_eligible_after_due', 'Eligible Start Date cannot be after Due Date')}
        </ErrorText>
      )}
    </div>
  );
};

TaskDetailErrors.propTypes = {
  className: PropTypes.string,
};

TaskDetailErrors.defaultProps = {
  className: null,
};
