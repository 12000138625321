import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { AbnormalAssetStates, AssetState, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { fetchAssetStatuses, fetchSiteMetrics } from '@ge/shared/services/site';
import { getDuration } from '@ge/shared/util';

const ASSET_STATUS_EVENT_FIELD = 'event';
const NO_COMM_BACKEND_CODE = 'no_comm';

// can look into consolidating this with useTaskAssetState
export const useReportAssetState = ({ config = Config.EXECUTE_ONCE, queryKey }) => {
  const logger = useLogger();
  const { siteIds } = useReportScope();

  // store
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const getAssetsBySiteId = useStoreState((state) => state.assets.getAssetsBySiteId);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  const {
    data: state,
    error,
    isLoading: isStateLoading,
  } = useQuery(
    [QueryKey.REPORTING_ASSET_STATE, getAssetsBySiteId, siteIds, queryKey],
    async () =>
      siteIds.reduce(async (response, siteId) => {
        const siteAssets = getAssetsBySiteId(siteId);

        const assetIds = siteAssets?.map(({ id }) => id);

        const { assets } = await fetchSiteMetrics(siteId, assetIds);

        const _response = await response;

        Object.entries(assets ?? {}).forEach(([assetId, value]) => {
          // do some light mapping while we're in here
          const {
            connection: { value: connectionState } = {},
            faultState: { value: faultCode } = {},
            state: { timestamp, value: _state } = {},
          } = value;

          // would be nice for bff to handle this for us
          const state = connectionState === NO_COMM_BACKEND_CODE ? AssetState.NET_COMM : _state;

          // filter out uninteresting assets here to make dependent calls more performant
          if (AbnormalAssetStates.includes(state)) {
            _response[assetId] = { faultCode, state, timestamp };
          }
        });

        return _response;
      }, {}),
    {
      ...config,
      enabled: (config?.enabled ?? true) && Boolean(siteIds?.length),
    },
  );

  const { data: events, isLoading: isEventsLoading } = useQuery(
    [QueryKey.REPORTING_ASSET_EVENTS, state, queryKey],
    async () => {
      const assetIds = Object.keys(state);

      const response = await fetchAssetStatuses(assetIds, ASSET_STATUS_EVENT_FIELD);

      return response?.assetState?.reduce((mapped, { assetId, event }) => {
        const { code, name } = event ?? {};

        if (code && name) {
          mapped[assetId] = { code, name };
        }

        return mapped;
      }, {});
    },
    {
      ...config,
      enabled: (config?.enabled ?? true) && Boolean(state),
    },
  );

  if (error) {
    logger.error(error);
  }

  const isLoading = isEventsLoading || isStateLoading;

  const data = useMemo(() => {
    if (isLoading) {
      return null;
    }

    return Object.entries(state ?? {}).reduce((_data, [assetId, assetState]) => {
      const { name, site } = getAssetById(assetId) ? getAssetById(assetId) : '';
      const assetSite = getSiteById(site?.id);
      const { faultCode, state, timestamp } = assetState ?? {};
      const duration = timestamp ? getDuration(timestamp * 1000, new Date())?.formatted : null;

      const event = events?.[assetId] ?? {};
      let faultName;

      // second check here should be unnecessary but just in case
      if (event?.name && event?.code === faultCode) {
        faultName = event.name;
      }

      _data[assetId] = {
        duration,
        faultCode,
        faultName,
        id: assetId,
        name,
        siteName: assetSite?.name,
        state,
      };

      return _data;
    }, {});
  }, [events, getAssetById, getSiteById, isLoading, state]);

  // TODO: surface multi-source errors in standard way
  return { data, error, isLoading };
};
