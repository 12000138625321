import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tabs, Tab } from '@ge/components/tabs';
import { globalColors } from '@ge/tokens/colors';

import MeshVibrationChart from './mesh-vibration-chart';
import { MetricHeader } from './metric-header';
import { ChartDefs } from './models/chart-definitions';
import SpectrumChart from './spectrum-chart';
import WaveFormChart from './wave-form-chart';

const StyledContainer = styled.div`
  background: ${(props) => props.theme.analyze.fullScreenChartModal.backgroundColor};
  border-top: 4px solid ${globalColors.teal3};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 4px;
`;

const StyledContent = styled.div`
  display: flex;
`;

const LargeChartWrapper = styled.div`
  padding: 11px 0 14px 14px;
  width: calc(78% - 14px);
`;

const SelectableChartWrapper = styled.div`
  border-left: 2px solid ${(props) => props.theme.analyze.fullScreenChartModal.borderColor};
  padding: 20px 10px 40px;
  width: calc(28% - 20px);

  .selectable-chart {
    margin: 0 0 41px;
  }
`;

const ChartsWapper = styled.div`
  padding: 0 14px;
`;

const StyledTabs = styled(Tabs)`
  select {
    min-width: 192px;
    margin-bottom: 20px;
  }
`;

const StyledTab = styled(Tab)`
  overflow: initial;
`;

export const FullScreenChartModal = ({ initialChart, component }) => {
  const { t } = useTranslation(['analyze.troubleshooting']);
  const [selectionCharts, setSelectionCharts] = useState(Object.keys(ChartDefs));
  const [activeChart, setActiveChart] = useState(null);

  const findKeyIndex = useCallback(
    (newChartState) => {
      const matchActiveKey = (chart) => chart === activeChart;
      return newChartState.findIndex(matchActiveKey);
    },
    [activeChart],
  );

  useEffect(() => {
    setActiveChart(initialChart);
  }, [initialChart]);

  // Local State
  useEffect(() => {
    const newChartState = [...Object.keys(ChartDefs)];

    newChartState.splice(findKeyIndex(newChartState), 1);
    setSelectionCharts(newChartState);
  }, [activeChart, findKeyIndex]);

  // Handler funcitons
  const handleActiveChart = (chartKey) => setActiveChart(chartKey);

  // Render functions
  const chartSwitch = useCallback(
    (key, large) => {
      switch (key) {
        case ChartDefs.VIBRATION.id:
          return (
            <MeshVibrationChart
              alignChevronLeft
              key={key}
              className="selectable-chart"
              large={!!large}
              onChevronClick={!large ? () => handleActiveChart(key) : null}
              title={`${large ? component.name : ''} ${t('vibration', 'Vibration')}`}
            />
          );
        case ChartDefs.MESH.id:
          return (
            <WaveFormChart
              alignChevronLeft
              key={key}
              className="selectable-chart"
              large={!!large}
              onChevronClick={!large ? () => handleActiveChart(key) : null}
              title={`${large ? component.name : ''} ${t('mesh', 'Mesh')}`}
            />
          );
        case ChartDefs.SPECTRUM.id:
          return (
            <SpectrumChart
              alignChevronLeft
              key={key}
              className="selectable-chart"
              large={!!large}
              onChevronClick={!large ? () => handleActiveChart(key) : null}
              title={`${large ? component.name : ''} ${t('spectrum', 'Spectrum')}`}
            />
          );
        default:
          return null;
      }
    },
    [component.name, t],
  );

  const renderCharts = useCallback(
    (large) => {
      if (large) {
        return chartSwitch(activeChart, large);
      }

      return selectionCharts.map((key) => chartSwitch(key, large));
    },
    [activeChart, chartSwitch, selectionCharts],
  );

  return (
    <StyledContainer>
      <MetricHeader />
      <StyledContent>
        <LargeChartWrapper>{renderCharts(true)}</LargeChartWrapper>
        <SelectableChartWrapper>
          <StyledTabs dropdown>
            <StyledTab label={t('tabs.related', 'Related')}>
              <ChartsWapper>{renderCharts(false)}</ChartsWapper>
            </StyledTab>
          </StyledTabs>
        </SelectableChartWrapper>
      </StyledContent>
    </StyledContainer>
  );
};

FullScreenChartModal.propTypes = {
  initialChart: PropTypes.string,
  component: PropTypes.instanceOf(Object),
};

FullScreenChartModal.defaultProps = {
  initialChart: '',
  component: null,
};
