import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchCrew } from '../services';

export const useCrew = ({ crewId }) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.TECHNICIAN_CREW],
    () => fetchCrew({ crewId }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(crewId),
    },
  );

  return { data, error, isLoading };
};
