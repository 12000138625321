import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { useBulkScheduleTask } from '@ge/feat-manage/data-hooks/use-bulk-schedule-task';
import { TasksPriority } from '@ge/models/constants';
// import { TaskSourceField } from '@ge/models/constants';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
// import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { useLogger } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';
// import { elevations } from '@ge/tokens/elevations';
import { groupById } from '@ge/util';

import { TabOptions } from '../../../context/planning-provider';

import { AddTaskBacklogPanel } from './add-task-backlog';

const StyledNewTaskDialog = styled(Dialog)`
  width: 30vw;
  header {
    display: none;
  }
  footer {
    padding: unset;
  }
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CLOSE,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  justify-content: flex-end;
  text-align: right;
  height: 100%;
  button:not(:last-of-type) {
    margin-right: 13px;
  }
`;

const TabsContainer = styled.div`
  ul {
    background: ${(props) => props.theme.dialog.headerBackground};
    margin: 0;
    padding: 0;
    display: flex;
    li {
      min-width: 100px;
      border-radius: 12px 3px 0 0;
      overflow: hidden;
      > .tab-container{
        display:flex;
        padding: 6px;
        align-items: center;
        justify-content: center;
        .total-backlog-count{
          margin-left:10px;
        }
        .tab-title{
          text-transform: uppercase;
          font-weight: 700;
        }
      }
      &.close{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center
        button{
          align-items:center
          margin-right: 3px;
          width:unset;
        }
      }
    }
  }
  .tab-content {
    height: 56vh;
  }
`;
const NewTaskFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 17px 25px;
  button {
    width: 120px;
    height: 25px;
    text-transform: capitalize;
  }
`;

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 10px;
`;

// const AddTaskButton = styled.button`
//   width: 107px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${globalColors.white};
//   font-size: 11px;
//   letter-spacing: 0.5px;
//   .addTask {
//     position: relative;
//     top: 2px;
//     text-transform: uppercase;
//   }
// `;
// const AddIcon = styled(Icon).attrs((props) => ({
//   size: 10,
//   icon: Icons.ADD,
//   color: globalColors.slate4,
//   hover: props.theme.entityDetails.iconColor,
// }))`
//   margin-right: 0px;
//   padding-right: 2px;
// `;

export const AddTaskPanel = ({ onClose, tasks, destoryStack }) => {
  const { t } = useTranslation(['manage.cases-tasks', 'tasks'], { useSuspense: false });
  const backlogData = useStoreState((state) => state.siteManager.hydratedTaskBacklog);
  const { sites: sitesById } = useStoreState((state) => state.sites);
  const [isBulkScheduleError, setIsBulkScheduleError] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  // const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const logger = useLogger();
  const tab = TabOptions.BACKLOG;

  let IdsToBeIgnored = [];
  tasks.map((t) => {
    IdsToBeIgnored.push(t.id);
    // eslint-disable-next-line no-unsafe-optional-chaining
    t?.isBundled && t?.bundledTasks && IdsToBeIgnored.push(...t?.bundledTasks);
  });
  IdsToBeIgnored = [...new Set(IdsToBeIgnored)];

  const siteTasks = tasks.filter((t) => !t?.asset.id);
  const assetTasks = tasks.filter((t) => t?.asset.id);

  const groupedByAsset = groupById(assetTasks, ['asset', 'id']);
  let listOfAssets = Object.keys(groupedByAsset);

  const filteredSiteTasks =
    siteTasks.length > 0
      ? backlogData.filter((t) => !t?.asset.id && !IdsToBeIgnored.includes(t?.id))
      : [];

  const filteredAssetTasks = [];
  assetTasks.length > 0 &&
    backlogData.filter((item) => {
      if (listOfAssets.includes(item?.asset?.id) && !IdsToBeIgnored.includes(item?.id))
        filteredAssetTasks.push(item);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let allTask = [...new Set([...filteredSiteTasks, ...filteredAssetTasks])];

  // const newFieldTask = useCallback(() => {
  //   setShowNewTaskModal(true);
  // }, []);
  // const closeModal = useCallback(() => {
  //   onClose();
  //   setShowNewTaskModal(false);
  // }, [onClose]);
  // const saveTask = useCallback(() => {
  //   onClose();
  //   setShowNewTaskModal(false);
  // }, [onClose]);

  const snoozedTasks = allTask
    .filter((t) => t?.eligibleStartDate)
    .sort(
      (a, b) =>
        a?.eligibleStartDate - b?.eligibleStartDate ||
        TasksPriority.indexOf(a.priority) - TasksPriority.indexOf(b.priority) ||
        a.title.localeCompare(b.title),
    );

  const scheduledTask = allTask
    .filter((t) => !t.eligibleStartDate)
    .sort(
      (a, b) =>
        TasksPriority.indexOf(a.priority) - TasksPriority.indexOf(b.priority) ||
        a.title.localeCompare(b.title),
    );

  allTask = [...scheduledTask, ...snoozedTasks];

  const { bulkSchedule } = useBulkScheduleTask({
    onSuccess: () => {
      setIsBulkScheduleError(false);
      setSpinLoader(false);
      onClose();
      setSelectedTask([]);
      destoryStack();
    },
    onError: (e) => {
      setIsBulkScheduleError(true);
      setSpinLoader(false);
      logger.error('Error scheduling', e);
    },
  });

  const toBeBundleTasks = useMemo(() => {
    return selectedTask.length
      ? allTask.filter((reqTask) => selectedTask.includes(reqTask.id))
      : [];
  }, [allTask, selectedTask]);
  const bundleSiteTasks = useMemo(() => {
    return toBeBundleTasks.length ? toBeBundleTasks.filter((t) => !t?.asset.id) : [];
  }, [toBeBundleTasks]);
  const bundleAssetTasks = useMemo(() => {
    return toBeBundleTasks.length ? groupById(toBeBundleTasks, ['asset', 'id']) : [];
  }, [toBeBundleTasks]);

  const handleApply = useCallback(() => {
    setSpinLoader((prev) => !prev);
    const data = {};
    siteTasks?.length &&
      siteTasks.map((task) => {
        let siteTaskIds = bundleSiteTasks.map((task) => task.id) || [];
        let bundledTasks = [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...new Set([...(task?.bundledTasks ? task?.bundledTasks : []), ...siteTaskIds]),
        ];
        data[task.id] = {
          bundledTasks: bundledTasks,
          scheduleDate:
            task?.scheduleDate && task.scheduleDate != null
              ? dayjs(task?.scheduleDate).tz(sitesById[task?.site?.id].timezone)
              : null,
          startTime: task?.startTime && task.startTime != null ? task.startTime : null,
          isBundled: true,
          assignee: task?.crewIds?.[0],
        };
      });
    listOfAssets?.length &&
      listOfAssets.map((assetKey) => {
        groupedByAsset?.[assetKey] &&
          groupedByAsset?.[assetKey].map((task) => {
            let assetTaskIds = bundleAssetTasks[assetKey]?.map((task) => task.id) || [];
            let bundledTasks = [
              ...new Set([
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...(task?.bundledTasks ? task?.bundledTasks : []),
                ...(assetTaskIds?.length ? assetTaskIds : []),
              ]),
            ];
            data[task.id] = {
              bundledTasks: bundledTasks,
              scheduleDate:
                task?.scheduleDate && task.scheduleDate != null
                  ? dayjs(task?.scheduleDate).tz(sitesById[task?.site?.id].timezone)
                  : null,
              startTime: task?.startTime && task.startTime != null ? task.startTime : null,
              isBundled: true,
              assignee: task?.crewIds?.[0],
            };
          });
      });
    bulkSchedule(data);
  }, [
    siteTasks,
    listOfAssets,
    bulkSchedule,
    bundleSiteTasks,
    groupedByAsset,
    bundleAssetTasks,
    sitesById,
  ]);

  const getFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        {isBulkScheduleError && <StyledError />}
        <FooterButtons>
          {/* WIP
          <AddTaskButton>
            <AddIcon />{' '}
            <span className="addTask" onClick={() => newFieldTask()}>
              {t('new_task', 'New Task')}
            </span>
          </AddTaskButton> */}
          <SpinLoader showLoader={spinLoader} className="spinner" />
          <Button
            primary
            disabled={!(selectedTask.length || spinLoader) ? true : false}
            onClick={() => handleApply(selectedTask)}
          >
            {t('general:apply', 'Apply')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [handleApply, isBulkScheduleError, selectedTask, spinLoader, t]);

  const renderPanel = () => {
    switch (tab) {
      case TabOptions.BACKLOG:
        return (
          <AddTaskBacklogPanel
            backlogData={allTask}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
          />
        );
      default:
        return (
          <AddTaskBacklogPanel
            backlogData={allTask}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
          />
        );
    }
  };

  return (
    <StyledNewTaskDialog
      isOpen={true}
      footer={getFooter}
      contentWidth
      padContent={false}
      className={'inner-div'}
    >
      <TabsContainer
        onClick={(e) => killEventPropagation(e)}
        onKeyDown={(e) => killEventPropagation(e)}
      >
        <ul>
          <li className={tab === TabOptions.BACKLOG ? 'active' : ''}>
            <div className="tab-container">
              <div className="tab-title">{t('backlog', 'Backlog')}</div>
              {allTask.length > 0 && (
                <Badge
                  className="total-backlog-count"
                  color={globalColors.stone1}
                  label={allTask.length.toString()}
                  small
                />
              )}
            </div>
          </li>
          <li className="close">
            <button onClick={() => onClose()}>
              <CloseIcon />
            </button>
          </li>
        </ul>

        <div className="tab-content">{renderPanel()}</div>
      </TabsContainer>
      {/* WIP
      {showNewTaskModal && (
        <NewTaskDialog
          onClose={closeModal}
          onConfirm={saveTask}
          taskSource={TaskSourceField.MANUAL}
        />
      )} */}
    </StyledNewTaskDialog>
  );
};

AddTaskPanel.propTypes = {
  tasks: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  destoryStack: PropTypes.func,
};

AddTaskPanel.defaultProps = {
  tasks: [],
  onClose: () => null,
  destoryStack: () => null,
};
