import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { Menu, placements } from '@ge/components/menu';
import { KpiCategoryDefs } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { insertAtIndexFn } from '@ge/util/array-utils';

const MenuContainer = styled.div`
  text-align: left;
  position: relative;

  h3 {
    color: ${(props) => props.theme.layout.textColor};
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 14px 0 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: ${(props) => props.theme.layout.textColor};
      display: flex;
      font-size: 15px;
      margin: 10px 0;
    }
  }
`;

const MenuContent = styled.div`
  height: 100%;
  padding: 14px;
  overflow: hidden;

  .col-menu-scroll {
    height: 195px;
    padding: 14px;
  }
`;

const IconButton = styled.button`
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 28px;
  padding: 0;
`;

const SettingsIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SETTINGS,
  color: props.theme.sidebar.settingsIconColor,
}))``;

export const DashboardHeaderMenu = ({
  anchorEl,
  kpiCategories,
  visibleKpis,
  onSetAnchorEl,
  onMenuClose,
  unitObject,
}) => {
  /** HOOKS */
  const { ready, t } = useTranslation(['analyze.dashboard']);
  const changeHistory = useRef(new Map());

  const [kpis, setKpis] = useState(visibleKpis);

  useEffect(() => setKpis(visibleKpis), [setKpis, visibleKpis]);

  /** HANDLERS */
  const handleShowMenu = useCallback(
    (event) => {
      event.stopPropagation();
      onSetAnchorEl(event.currentTarget);
    },
    [onSetAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    onSetAnchorEl(null);
    onMenuClose(changeHistory.current);
    changeHistory.current.clear();
  }, [onSetAnchorEl, onMenuClose]);

  const handleColumnChange = useCallback(
    (category) => {
      const hide = kpis.includes(category);
      const newKpiState = hide
        ? kpis.filter((key) => key !== category)
        : insertAtIndexFn(
            kpis,
            kpiCategories.findIndex((el) => el === category),
            category,
          );

      let filteredNewKpiState = newKpiState;

      if (category === KpiCategoryDefs.REPORTING_SITES_RATIO) {
        filteredNewKpiState = newKpiState.filter(
          (key) => key !== KpiCategoryDefs.REPORTING_ASSETS_RATIO,
        );

        changeHistory.current.set(KpiCategoryDefs.REPORTING_ASSETS_RATIO, false);
      } else if (category === KpiCategoryDefs.REPORTING_ASSETS_RATIO) {
        filteredNewKpiState = newKpiState.filter(
          (key) => key !== KpiCategoryDefs.REPORTING_SITES_RATIO,
        );

        changeHistory.current.set(KpiCategoryDefs.REPORTING_SITES_RATIO, false);
      }

      setKpis(filteredNewKpiState);

      if (changeHistory.current.has(category)) {
        changeHistory.current.delete(category);
        return;
      }

      changeHistory.current.set(category, !hide);
      onMenuClose(changeHistory.current);
      changeHistory.current.clear();
    },

    [kpis, changeHistory, setKpis, kpiCategories, onMenuClose],
  );

  // Render the kpi options
  const renderCategoryOption = useCallback(() => {
    const checkboxes = kpiCategories?.map((category) => {
      let label = t(`dynamic.kpi_chart.${category}`, '');
      if (Object.keys(unitObject).length > 0 && unitObject[category]) {
        label = label + ` (${unitObject[category]})`;
      }

      const checkedState = kpis?.includes(category) ? CheckedState.CHECKED : CheckedState.UNCHECKED;

      return (
        <li key={label} className="body-1">
          <Checkbox checkState={checkedState} onChange={() => handleColumnChange(category)} />
          {label}
        </li>
      );
    });

    if (!checkboxes?.length) {
      return null;
    }

    return (
      <section>
        <ul>{checkboxes}</ul>
      </section>
    );
  }, [kpis, kpiCategories, handleColumnChange, t, unitObject]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <IconButton type="button" onClick={(e) => handleShowMenu(e)}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        placement={placements.TOP_END}
        onClick={(e) => killEventPropagation(e)}
      >
        <MenuContainer>
          <MenuContent>
            {/* <ScrollingContainer className="col-menu-scroll" autoHide={false}> */}
            <h3>{t('show_kpis', 'SHOW KPIS')}</h3>
            {renderCategoryOption()}
            {/* </ScrollingContainer> */}
          </MenuContent>
        </MenuContainer>
      </Menu>
    </>
  );
};

DashboardHeaderMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Object),
  kpiCategories: PropTypes.instanceOf(Object).isRequired,
  visibleKpis: PropTypes.instanceOf(Array).isRequired,
  onSetAnchorEl: PropTypes.func.isRequired,
  onSetVisibleKpis: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  unitObject: PropTypes.object,
  onClumnSelection: PropTypes.func,
};

DashboardHeaderMenu.defaultProps = {
  anchorEl: null,
  unitObject: {},
};
