import { useStoreActions } from 'easy-peasy';
import { useMemo, useContext } from 'react';
import { useMutation } from 'react-query';

import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

/**
 * Bulk delete tasks.
 *
 * @param opts useMutation options
 * @returns {{bulkDelete: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useBulkDeleteTask = (opts = {}) => {
  const { bulkDeleteTask } = useStoreActions((store) => store.tasks);
  const { removeBulkTasks } = useContext(EntityDetailsContext);
  const {
    mutate: bulkDelete,
    isLoading,
    isError,
    error,
  } = useMutation((bulkDelete = {}) => {
    const payload = Object.entries(bulkDelete).map(([ids, { taskId, id }]) => ({
      id,
      deleteId: ids,
      taskId: taskId,
    }));

    return bulkDeleteTask({ bulkDelete: payload, removeBulkTasks });
  }, opts);

  return useMemo(
    () => ({ bulkDelete, isLoading, isError, error }),
    [bulkDelete, error, isError, isLoading],
  );
};
