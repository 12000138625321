import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Tab, Tabs } from '@ge/components/tabs';
import { AssetOverviewTabs, AuthStrategy, Capability, PermissionScope } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';

import { AssetContext } from '../../context/assetContext';

import { AssetDialog } from './asset-overview-asset-dialog';
import AssetRibbon from './asset-ribbon';
import { DataExplorer } from './data-explorer/data-explorer';
import { AssetOverviewDetail } from './details/asset-ov-detail';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
  width: 100%;
  > div:first-child {
    button {
      text-transform: capitalize;
    }
  }
`;

const AssetBarContainer = styled.div`
  display: flex;
  width: calc(100% - 13px);
  background: ${(props) => props.theme.dataExplorer.header.backgroundColor};
  border-bottom: solid 1px ${(props) => props.theme.dataExplorer.header.borderColor};
  padding: 6px 3px 0 3px;
  min-height: 62px;
`;

const AddAssetButton = styled.button`
  padding: 10px;
  position: relative;
  > div {
    border: ${(props) =>
      props.isAssetSelected
        ? 'none'
        : `2px solid ${props.theme.assetOverview.selectAssetInfo.borderColor}`};
    padding: 5px;
    border-radius: 2px;
  }
  &:focus {
    outline: none;
  }
  margin-right: 5px;
`;

const SelectAsset = styled.div`
  color: ${(props) => props.theme.assetOverview.selectAssetInfo.textColor};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const AddAssetIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: Icons.ADD,
  color: props.theme.entityDetails.headerIconColor,
}))``;

export const AssetOverviewWind = () => {
  const { t, ready } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const { queryParam, assetState } = useContext(AssetContext);
  const { fetchAssetEvents } = useStoreActions((actions) => actions.assets);
  const { fetchEventHistory } = useStoreActions((actions) => actions.issues);
  const [showAssetDialog, setShowAssetDialog] = useState(false);

  // Todo: setting to pull first id in array but need to update to get all the other assets.
  const assetId = assetState.comparisonAssets ? assetState.comparisonAssets[0] : null;

  // Fetch necessary information for the page to render.
  useEffect(() => {
    if (assetId) {
      fetchEventHistory(assetId);
      fetchAssetEvents(assetId);
    }
  }, [assetId, fetchAssetEvents, fetchEventHistory]);

  if (!ready) return null;

  const isAssetSelected = !!assetState.comparisonAssets?.length;

  // we show data explorer tab if any of sites in current view have correct permissions, can revisit
  return (
    <Container>
      <DetailsDismissInert>
        <AssetBarContainer data-testid={AnalyzeLocators.ANALYZE_ASSET_RIBBON_CONTAINER}>
          <AddAssetButton
            isAssetSelected={isAssetSelected}
            onClick={() => setShowAssetDialog(true)}
            data-testid={AnalyzeLocators.ANALYZE_ADD_ASSET_BUTTON}
          >
            <div>
              <AddAssetIcon />
            </div>
          </AddAssetButton>
          {!isAssetSelected && <SelectAsset>{t('select_asset', 'Select an asset')}</SelectAsset>}
          {showAssetDialog && (
            <AssetDialog
              assetsSelected={queryParam.assets}
              onClose={() => setShowAssetDialog(false)}
              onConfirm={() => setShowAssetDialog(false)}
            />
          )}
          <AssetRibbon assetState={assetState} assets={assetState.comparisonAssets} />
        </AssetBarContainer>
      </DetailsDismissInert>
      <StyledTabs
        defaultTab={queryParam.tab}
        onChangeHandler={(val) => assetState.setTabsParam(val)}
      >
        <Tab
          capabilities={[
            {
              capability: Capability.CORE_KPIS,
              scopes: [PermissionScope.VIEW],
            },
            {
              capability: Capability.CASES_RECOMMENDATIONS,
              scopes: [PermissionScope.VIEW],
            },
          ]}
          authStrategy={AuthStrategy.ANY}
          label={t(AssetOverviewTabs.OVERVIEW, AssetOverviewTabs.OVERVIEW)}
        >
          <AssetOverviewDetail />
        </Tab>
        <Tab
          capabilities={[
            {
              capability: Capability.DATA_EXPLORER,
              scopes: [PermissionScope.VIEW, PermissionScope.EDIT],
            },
          ]}
          enforceView
          label={t(AssetOverviewTabs.DATA_EXPLORER, AssetOverviewTabs.DATA_EXPLORER)}
          siteLevel={false}
        >
          <DataExplorer />
        </Tab>
      </StyledTabs>
    </Container>
  );
};
