import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import { useState, useEffect } from 'react';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Tooltip } from '@ge/components/tooltip';
import { EntityType } from '@ge/models/constants';
import { elevations } from '@ge/tokens';

import HeaderCurrentSite from '../components/header-current-site';
import EntityDetailHeader from '../entity-details-header';
import { DetailsCurrent } from '../entity-details-shared';
import { DetailsCurrentSeparator } from '../entity-details-shared';

import DamageDetailsWeather from './damage-details-weather';

const StyledEntityDetailHeader = styled(EntityDetailHeader)`
  background-image: ${(props) => props.theme.entityDetails.headerBackground};
  align-items: center;
  padding: 20px;
  margin-left: 0;
  margin-right: 0;
`;
const TurbineIcon = styled(Icon).attrs((props) => ({
  size: 35,
  icon: Icons.TURBINE,
  color: props.theme.manage.dayCard.iconColor,
}))`
  margin-right: 10px;
`;
const AnamoliesWeatherContainer = styled.div`
  flex: 1 0;
`;
const Anamolies = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
`;
const StyledTurbineName = styled.h2`
  font-size: 25px;
  margin-top: 5px;
`;
const StyledAddress = styled.h2`
  font-size: 12px;
`;
const TurbineModel = styled.p`
  margin: 0;
`;
const StyledDetailHeader = styled.div`
  max-width: 360px;
  display: inline-block;
`;
const StyledHeaderCurrentCustomer = styled.div`
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  align-items: center;
  padding: 0px;
`;
const CustomerName = styled.h5`
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.entityDetails.tickets.iconColor,
}))`
  margin-right: 5px;
`;

const DamageDetailsHeader = ({ damageDetails, assetSiteId }) => {
  const [customerName, setCustomerName] = useState('');
  const [regionName, setRegionName] = useState('');
  const allSites = useStoreState((state) => state.sites.sites);
  const siteData = allSites[assetSiteId] ? allSites[assetSiteId] : null;
  const allRegion = useStoreState((state) => state.regions.regions);
  const regionData = siteData && allRegion ? allRegion[siteData.region.id] : null;

  useEffect(() => {
    if (siteData) {
      setCustomerName(siteData.customer.name ? siteData.customer.name : '');
      setRegionName(regionData ? regionData.name : '');
    } else {
      setCustomerName(damageDetails.reportJsonMetadata['TURBINE MASTER DATA'].Customer);
      setRegionName(damageDetails.reportJsonMetadata.Header['Project Name']);
    }
  }, [damageDetails.reportJsonMetadata, regionData, siteData]);

  const contactAddress = `${damageDetails.reportJsonMetadata['TURBINE MASTER DATA']['Site Address']} ${damageDetails.reportJsonMetadata['TURBINE MASTER DATA']['Site State/Province']} ${damageDetails.reportJsonMetadata['TURBINE MASTER DATA']['Site Country']}`;
  const turbineNumber =
    damageDetails.reportJsonMetadata['TURBINE MASTER DATA']['Turbine/Pad Number'];
  const turbineModel =
    damageDetails.reportJsonMetadata['TURBINE MASTER DATA']['Turbine Model'] !== 'UNKNOWN'
      ? damageDetails.reportJsonMetadata['TURBINE MASTER DATA']['Turbine Model']
      : '';
  const temperatureData = {
    temperature: damageDetails.reportJsonMetadata['INSPECTION DATA']['Ambient Temperature (C)'],
    windSpeed:
      damageDetails.reportJsonMetadata['INSPECTION DATA']['Wind Speed during inspection (m/s)'],
    humidity: damageDetails.reportJsonMetadata['INSPECTION DATA']['Ambient Humidity (%)'],
  };

  return (
    <StyledEntityDetailHeader type={EntityType.INSPECTION_DAMAGE}>
      <TurbineIcon />
      <StyledDetailHeader>
        <DetailsCurrent>
          <StyledHeaderCurrentCustomer>
            <StyledIcon icon={Icons.PERSON} />
            <Tooltip title={customerName} zIndex={elevations.P20} placement="top">
              <CustomerName>{customerName}</CustomerName>
            </Tooltip>
            <DetailsCurrentSeparator>/</DetailsCurrentSeparator>
          </StyledHeaderCurrentCustomer>
          <HeaderCurrentSite siteName={regionName} />
        </DetailsCurrent>
        <StyledTurbineName>{turbineNumber}</StyledTurbineName>
        <StyledAddress>{contactAddress}</StyledAddress>
      </StyledDetailHeader>
      <AnamoliesWeatherContainer>
        <Anamolies>
          <TurbineModel>{turbineModel}</TurbineModel>
        </Anamolies>
        <DamageDetailsWeather weather={temperatureData} />
      </AnamoliesWeatherContainer>
    </StyledEntityDetailHeader>
  );
};

DamageDetailsHeader.propTypes = {
  damageDetails: PropTypes.instanceOf(Object),
  assetSiteId: PropTypes.any,
};

export default DamageDetailsHeader;
