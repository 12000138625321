import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@ge/components/button';
import { Icons } from '@ge/components/icon';
import { killEventPropagation } from '@ge/shared/util/general';

import { useGetServiceGroups } from '../hooks/use-get-service-groups';
import { usePatchPerson } from '../hooks/use-patch-person';
import UserServiceGroupRole from '../user-service-group-role/user-service-group-role';

import {
  RemoveContainerStyled,
  StyledDialog,
  FooterButtons,
  RemoveIcon,
  ErrorMsg,
} from './remove-container-styled';

/**
 * RemoveContainer component
 */
const RemoveContainer = (props) => {
  const { person } = props;
  const { t } = useTranslation(['admin.people', 'entity-details'], { useSuspense: false });
  const [isOpen, setIsOpen] = useState(false);
  const getPersonType = useMemo(() => person?.type && person?.type.join('/'), [person]);
  const apostrophe = "'";
  const tittle = `${t('remove', 'Remove')} ${person?.firstName} ${
    person?.lastName
  } ${apostrophe}s ${getPersonType.toLowerCase()} ${t('assignment', 'assignment')} ${t(
    'from',
    'from',
  )}  `;

  const removeButtonText = t('remove.remove', 'Remove');
  const removeLinkText = t('remove', 'Remove') + ' ' + getPersonType;
  const noRecordText = t('norecord', 'No Record');
  const [loading, setLoading] = useState(false);
  const header = {
    serviceGroup: t('serviceGroup', 'Service groups'),
    worker: t('worker', 'Worker'),
    userRole: t('userRole', 'User roles'),
  };
  const { finalData, patchBody } = useGetServiceGroups(person, header);
  const openServiceGroup = () => {
    setIsOpen(true);
  };
  const onClose = (event) => {
    killEventPropagation(event);
    setIsOpen(false);
  };
  // patching
  const onPatchSuccess = (event) => {
    killEventPropagation(event);
    setIsOpen(false);
  };
  const { patch, personPatching, isError } = usePatchPerson({
    onSuccess: onPatchSuccess,
  });
  useEffect(() => {
    setLoading(personPatching);
  }, [personPatching]);
  //const patchErrorMessage = patchError?.response?.data?.message;
  const removeServiceGroup = useCallback(() => {
    patch({ username: person.username, body: patchBody });
  }, [patch, person.username, patchBody]);

  const getFooter = useMemo(() => {
    return (
      <FooterButtons>
        {isOpen && isError && <ErrorMsg>{'Something went wrong'}</ErrorMsg>}
        <Button className={'cancelButton'} onClick={onClose}>
          {t('general:cancel', 'Cancel')}
        </Button>

        <Button
          {...(finalData?.data?.length === 0 && { disabled: true })}
          primary
          onClick={removeServiceGroup}
        >
          {removeButtonText}
        </Button>
      </FooterButtons>
    );
  }, [t, removeButtonText, removeServiceGroup, isError, isOpen, finalData]);

  return (
    <RemoveContainerStyled>
      <button type="button" onClick={openServiceGroup}>
        <RemoveIcon icon={Icons.TRASH} /> {removeLinkText}
      </button>
      {isOpen && (
        <StyledDialog isOpen={isOpen} onClose={onClose} header={removeLinkText} footer={getFooter}>
          <UserServiceGroupRole
            loading={loading}
            noRecordText={noRecordText}
            title={tittle}
            {...finalData}
          />
        </StyledDialog>
      )}
    </RemoveContainerStyled>
  );
};

RemoveContainer.propTypes = {
  removeButtonText: PropTypes.string,
  removeLinkText: PropTypes.string,
  person: PropTypes.object,
};
export default RemoveContainer;
