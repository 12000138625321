import dayjs from 'dayjs';

import { DateTimeFormats } from '@ge/models/constants';

export const calculateDuration = (startTime, endTime, calendarDate) => {
  if (!startTime || !endTime) return `0 hr 0 m 0 s`;
  const day = dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM);
  let st = dayjs(`${day} ${startTime}`);
  let et = dayjs(`${day} ${endTime}`);
  if (startTime instanceof Date || typeof startTime === 'number') {
    st = dayjs(`${day} ${dayjs(startTime).format('HH:mm:ss')}`);
  }
  if (endTime instanceof Date || typeof endTime === 'number') {
    et = dayjs(`${day} ${dayjs(endTime).format('HH:mm:ss')}`);
  }
  const secs = dayjs(et).diff(st, 'seconds', true);
  const mins = dayjs(et).diff(st, 'minutes', true);
  const hours = parseInt(mins / 60);
  let minutes = dayjs().minute(mins).$m;
  let seconds = dayjs().second(secs).$s;
  if (mins < 0) {
    minutes = mins > -60 ? mins : mins % 60;
  }
  seconds = secs < 0 ? -seconds : seconds;
  return `${hours} hr ${minutes} m ${seconds} s`;
};
