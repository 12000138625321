import { PropTypes } from 'prop-types';
import React, { useCallback, forwardRef } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { KeyCode } from '@ge/models/constants';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';

import { Severity } from '../severity';

import { CheckedState } from './checked-state';

const CheckboxContainer = styled.label`
  display: flex;
  vertical-align: middle;
  span {
    margin-left: 6px;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 0;
  width: 0;
  padding: 0;
  visibility: hidden;
  display: none;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  transition: all 150ms;
  border: solid 1px #8b9cad;
  position: relative;
  text-align: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &.check {
      visibility: ${(props) => (props.isChecked ? 'visible' : 'hidden')};
    }
    &.indeterminate {
      visibility: ${(props) => (props.isIndeterminate ? 'visible' : 'hidden')};
    }
  }
  &.invalid {
    border: ${(props) => props.theme.select.errorBorder};
  }
`;

const StyledSeverity = styled(Severity)`
  margin-right: -7px;
  margin-top: 4px;
  margin-left: 6px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.checkbox.iconColor,
}))``;

export const Checkbox = forwardRef(
  (
    {
      className,
      id,
      checkState,
      name,
      onChange,
      label,
      disabled,
      error,
      readOnly,
      columnKey,
      value,
    },
    ref,
  ) => {
    const toggleState = useCallback(
      (e) => {
        // Don't emit an event if it is a keypress that shouldn't toggle.
        if (
          e.keyCode &&
          e.keyCode !== KeyCode.RETURN &&
          e.keyCode !== KeyCode.ENTER &&
          e.keyCode !== KeyCode.SPACE
        ) {
          return;
        }
        if (!readOnly) {
          onChange(e.target.checked, id, e.stopPropagation());
        }
      },
      [readOnly, onChange, id],
    );

    return (
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          id={id}
          name={name}
          disabled={disabled}
          checked={checkState === CheckedState.CHECKED}
          indeterminate={checkState === CheckedState.INDETERMINATE}
          onChange={toggleState}
          ref={ref}
        />
        <StyledCheckbox
          className={`${!!error && 'invalid'}`}
          disabled={disabled}
          tabIndex={disabled ? -1 : 0}
          onKeyDown={toggleState}
          isChecked={checkState === CheckedState.CHECKED}
          id={id}
          isIndeterminate={checkState === CheckedState.INDETERMINATE}
        >
          <StyledIcon size={10} icon={Icons.INDETERMINATE} className="indeterminate" />
          <StyledIcon size={10} icon={Icons.CHECK} className="check" />
        </StyledCheckbox>

        {columnKey === AnomaliesColumns.PRIORITY && <StyledSeverity level={value} />}
        <span className={checkState}>{label && label}</span>
      </CheckboxContainer>
    );
  },
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkState: PropTypes.oneOf([
    CheckedState.CHECKED,
    CheckedState.UNCHECKED,
    CheckedState.INDETERMINATE,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.object,
  columnKey: PropTypes.string,
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  id: null,
  name: null,
  label: null,
  checkState: CheckedState.UNCHECKED,
  onChange: () => null,
  disabled: false,
};
