import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Radio } from '@ge/components/radio';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useLogger } from '@ge/shared/hooks';

import { WidgetNames } from '../../../../models/widgets';
import {
  productionGraphDefaultConfig,
  productionGraphFieldDefs,
} from '../../../widgets/production-graph-widget/production-graph-config';
import { ProductionGraphStateKeys } from '../../../widgets/production-graph-widget/production-graph-widget';

const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.createReport.sidebar.moduleBorderColor};
  padding-bottom: 5px;
  padding-top: 10px;
`;

const FieldsHeader = styled.span`
  text-transform: uppercase;
  font-weight: 700;
`;

const CheckboxContainer = styled.ul`
  display: flex;
  flex-flow: row wrap;
  padding: 5px 0 5px 14px;
  margin: 0;
`;

const CheckboxWrapper = styled.li`
  flex: 1 0 calc(50% - 10px);
  list-style: none;
  margin: 5px 0;
  padding: 0;

  & .module-config-radio {
    margin-bottom: auto;
    margin-top: auto;
  }

  & input[disabled] + label {
    filter: brightness(0.5);
  }
`;

// Map widgets to field definitions
const getFieldDefs = (widgetName) => {
  const widgetMap = {
    [WidgetNames.PRODUCTION_GRAPH]: productionGraphFieldDefs,
  };

  return widgetMap[widgetName];
};

// Map widgets to the key name used for storing its config state
const getWidgetConfigStateKey = (widgetName) => {
  const widgetMap = {
    [WidgetNames.PRODUCTION_GRAPH]: ProductionGraphStateKeys.CONFIG_STATE,
  };

  return widgetMap[widgetName];
};

// Get default configuration for the specified widget and apply overrides from the template.
const getConfigDefaults = (widgetName, configuration, logger) => {
  const [_fieldDefaults, fieldsToOmit] = {
    [widgetName]: [[], null],
    [WidgetNames.PRODUCTION_GRAPH]: [productionGraphDefaultConfig, null],
  }[widgetName];

  const fieldDefaults = [..._fieldDefaults];
  if (configuration) {
    let flatFields;
    for (const [configKey, configValue] of Object.entries(configuration)) {
      if (typeof configValue === 'object') {
        // Assume config is for a Field Group; find the matching group in the config defaults
        const fieldGroup = fieldDefaults.find((cd) => cd.id === configKey);
        if (fieldGroup) {
          if (fieldGroup.ignoreTemplateConfig === true) {
            continue;
          }
          if (fieldGroup.fields?.length) {
            for (const [fieldConfigKey, fieldConfigValue] of Object.entries(configValue)) {
              if (typeof fieldConfigValue !== 'boolean') {
                logger.warn(
                  'Unsupported config value present for field config key: ',
                  fieldConfigKey,
                );
                continue;
              }
              const field = fieldGroup.fields.find((c) => c.id === fieldConfigKey);
              if (field) {
                field.visible = fieldConfigValue;
              } else if (!fieldsToOmit?.includes(fieldConfigKey)) {
                logger.warn('Could not find field configuration with name: ', fieldConfigKey);
              }
            }
          } else {
            logger.warn('Field config missing for field group: ', configKey);
          }
        } else {
          logger.warn('Could not find field group configuration with name: ', configKey);
        }
      } else if (typeof configValue === 'boolean') {
        // Assume config is a single field that does not belong to a group.
        if (!flatFields) {
          flatFields = fieldDefaults.flatMap((cd) => cd.fields);
        }
        const field = flatFields.find((c) => c.id === configKey);
        if (field) {
          field.visible = configValue;
        } else if (!fieldsToOmit?.includes(configKey)) {
          logger.warn('Could not find field configuration with name: ', configKey);
        }
      } else {
        logger.warn('Unsupported config value present for config key: ', configKey);
        continue;
      }
    }
  }

  return fieldDefaults;
};

/**
 * Merge field definitions and defaults into a single array that can be used to
 * render the checkbox lists generically.
 *
 * @param {array} fieldDefs All available fields and related definitions
 * @param {array} defaultConfig Default configuration for field visibility
 * @param {array} omittedFields Field keys that should not be displayed in the selection list
 *
 * @returns Array of field groups with selectable fields inside
 */
const getGroupConfigVisibilityArray = (fieldDefs, defaultConfig, omittedFields) => {
  return Object.keys(fieldDefs).reduce((groupAcc, groupKey) => {
    const groupObj = fieldDefs[groupKey];

    // Extract default visible fields
    const groupDefaults = defaultConfig.find((groupDefaultObj) => groupDefaultObj.id === groupKey);
    const fieldDefaults = groupDefaults?.fields.filter(
      (fieldDefaultObj) => fieldDefaultObj.visible,
    );
    const visibleFields = fieldDefaults?.map((fieldDefaultObj) => fieldDefaultObj.id);

    // Build an object that can be rendered easily as checkboxes using table configs
    const fields = Object.entries(groupObj.fields).reduce((fieldAcc, [fieldKey, fieldObj]) => {
      const isOmitted = !!omittedFields?.includes(fieldKey);

      // Ignore explicitly omitted fields, make "locked" fields disabled
      if (!isOmitted) {
        fieldAcc.push({
          key: fieldKey,
          a11yKey: fieldObj.labels?.[0].a11yKey,
          a11yDefault: fieldObj.labels?.[0].a11yDefault,
          visible: visibleFields?.includes(fieldKey),
          disabled: fieldObj.locked,
        });
      }

      return fieldAcc;
    }, []);

    // Only include field groups that have selectable fields inside
    if (fields.length) {
      groupAcc.push({
        key: groupKey,
        a11yKey: groupObj.labels?.[0].a11yKey,
        a11yDefault: groupObj.labels?.[0].a11yDefault,
        fields: fields,
        type: groupObj.type,
      });
    }

    return groupAcc;
  }, []);
};

export const TotalModule = ({ id, name, configuration }) => {
  const configStateKey = getWidgetConfigStateKey(name);
  const logger = useLogger();
  const { t } = useTranslation(['reporting.widgets', 'reporting.sidebar']);
  const { getWidgetState, setWidgetState } = useContext(ReportsContext);
  const configState = getWidgetState(id, configStateKey);

  const [fieldDefs, setFieldDefs] = useState();

  const fieldDefaults = useMemo(
    () => getConfigDefaults(name, configuration, logger),
    [name, configuration, logger],
  );

  // Bootstrap the field definitions based on the widget name.
  useEffect(() => {
    const fieldDefs = getFieldDefs(name);
    setFieldDefs(fieldDefs);
  }, [name]);

  // Initialize default configuration in widget state
  useEffect(() => {
    if (!configState) {
      setWidgetState({
        widgetId: id,
        key: configStateKey,
        value: fieldDefaults,
      });
    }
  }, [configState, configStateKey, fieldDefaults, id, setWidgetState]);

  // Update the visible state when a checkbox is selected.
  const handleCheckChange = useCallback(
    (groupKey, fieldKey, newState) => {
      // Clone field state, modify the appropriate field , and re-set
      const currentState = [...configState];

      // NOTE: This relies on defaults for EVERY field to be defined,
      // or this will fail to find the field by id/key.
      const fieldObj = currentState
        .find((group) => group.id === groupKey)
        ?.fields.find((field) => field.id === fieldKey);
      fieldObj.visible = newState;

      // Update the report state with the updated field configuration when changed.
      setWidgetState({
        widgetId: id,
        key: configStateKey,
        value: currentState,
      });
    },
    [id, configState, configStateKey, setWidgetState],
  );

  // Generate the structure to render selectable configuration items once per widget "name"
  const groupConfigArr = useMemo(() => {
    if (!fieldDefs || !configState) {
      return [];
    }

    return getGroupConfigVisibilityArray(fieldDefs, configState);
  }, [fieldDefs, configState]);

  // Render a generic checkbox for a field per its configuration.
  const renderCheckbox = useCallback(
    (group, field) => {
      return (
        <Checkbox
          name={`${id}-${group.key}-${field.key}`}
          label={t(field.a11yKey, field.a11yDefault)}
          checkState={field.visible ? CheckedState.CHECKED : CheckedState.UNCHECKED}
          onChange={(newState) => handleCheckChange(group.key, field.key, newState)}
          disabled={field.disabled}
        />
      );
    },
    [id, handleCheckChange, t],
  );

  // Render a generic radio button for a field per its configuration.
  const renderRadio = useCallback(
    (group, field) => {
      return (
        <Radio
          checked={field.visible === true}
          className="module-config-radio"
          disabled={field.disabled}
          id={field.key}
          label={t(field.a11yKey, field.a11yDefault)}
          name={`${id}-${group.key}`}
          onChange={(newState) => handleCheckChange(group.key, field.key, newState)}
          value={field.key}
        />
      );
    },
    [id, handleCheckChange, t],
  );

  const buildGroupCheckboxes = useCallback(
    (group) => {
      const renderItemFn = group.type === 'radio' ? renderRadio : renderCheckbox;
      return (
        group && (
          <CheckboxContainer>
            {group.fields?.map((field) => (
              <CheckboxWrapper key={field.key}>{renderItemFn(group, field)}</CheckboxWrapper>
            ))}
          </CheckboxContainer>
        )
      );
    },
    [renderCheckbox, renderRadio],
  );

  // Memoized panel configurations
  const configPanels = useMemo(() => {
    return groupConfigArr?.map((group) => (
      <React.Fragment key={group.key}>
        <FieldsWrapper>
          <FieldsHeader>{t(group.a11yKey, group.a11yDefault)}</FieldsHeader>
        </FieldsWrapper>
        {buildGroupCheckboxes(group)}
      </React.Fragment>
    ));
  }, [groupConfigArr, t, buildGroupCheckboxes]);

  return <>{configPanels}</>;
};

TotalModule.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  configuration: PropTypes.object,
};
