import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetContext } from '@ge/feat-analyze/context/assetContext';
import { SolarAssetKpiHeaderCategories, EntityType } from '@ge/models/constants';
import { QueryKey } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';

import { useGetData } from '../../data-hooks/use-get-data';
import useKpiHeaderMenu from '../../data-hooks/use-kpi-header-menu';
import { DefaultSolarAssetKpiVisibility } from '../../models/kpi-header-visibility-defaults';
import { fetchAggregatedKpiSolar } from '../../services';
import DashboardHeader from '../dashboard/dashboard-header';
import { SiteHeaderStyledSolar } from '../shared/styled/site-header-styled';

export const AssetOverviewSolarHeader = () => {
  const { ready, t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });

  const assetOverviewCategories = [...SolarAssetKpiHeaderCategories];

  const { assetState } = useContext(AssetContext);

  const {
    kpiDateRange: { startDate, endDate },
  } = assetState;

  const asset = assetState.assets && assetState.assets[0];
  const { data: siteAggregatedKpis } = useGetData(
    {
      assetId: [assetState?.selectedAssetId],
      startDate: startDate.entityTimezone,
      endDate: endDate.entityTimezone,
      kpiList: [SolarAssetKpiHeaderCategories],
      assetType: EntityType.INVERTER,
    },
    fetchAggregatedKpiSolar,
    QueryKey.SITE_KPI_DATA,
  );
  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_ASSETS,
    defaultVisibility: DefaultSolarAssetKpiVisibility,
    kpiCategories: SolarAssetKpiHeaderCategories,
  });

  const unitObject = {};
  if (siteAggregatedKpis?.data?.kpis) {
    Object.entries(siteAggregatedKpis.data.kpis).forEach((item) => {
      if (item && item[0]) {
        Object.assign(unitObject, {
          [`${item[0]}`]: item[1]?.unit,
        });
      }
    });
  }

  if (!ready) return null;

  const isAssetSelected = !!assetState.comparisonAssets?.length;

  return (
    <SiteHeaderStyledSolar>
      <DashboardHeader
        title={t('asset_overview', 'Asset Overview')}
        type={EntityType.ASSET}
        showParent={assetState.comparisonAssets?.length === 1}
        entity={asset}
        data={siteAggregatedKpis?.data?.kpis}
        onMenuClose={handleKpiChanges}
        visibleKpiPrefs={visibleKpis}
        kpiCategories={assetOverviewCategories}
        unitObject={unitObject}
        showStats={isAssetSelected}
      />
    </SiteHeaderStyledSolar>
  );
};

AssetOverviewSolarHeader.propTypes = {};

AssetOverviewSolarHeader.defaultProps = {};
