import PropTypes from 'prop-types';
import React from 'react';

import { TabHeader } from '@ge/components/tabs';
import { PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

import { serializeLabel } from './tabs-shared';

export const AuthTab = ({
  activeTab,
  badgeColor,
  badgeValue,
  badgePlacement,
  capabilities,
  description,
  enforceView,
  label,
  large,
  onTabClick,
  siteIds,
  small,
  authStrategy,
}) => {
  // data hooks
  const { audit } = useAuth();

  const authorization = {
    capabilities,
    description,
    enforceView,
    siteIds,
    source: `Tab - ${label}`,
    type: 'Tab blocked',
    authStrategy,
  };

  if (capabilities?.length && !audit(authorization)) {
    return null;
  }

  const id = serializeLabel(label);

  return (
    <TabHeader
      activeTab={id === activeTab}
      badgeValue={badgeValue}
      badgeColor={badgeColor}
      key={id}
      label={label}
      large={large}
      onClick={() => onTabClick(id)}
      small={small}
      badgePlacement={badgePlacement}
    >
      {label}
    </TabHeader>
  );
};

AuthTab.propTypes = {
  activeTab: PropTypes.string,
  badgeColor: PropTypes.string,
  badgeValue: PropTypes.string,
  badgePlacement: PropTypes.string,
  capabilities: PropTypes.arrayOf(
    PropTypes.shape({
      capability: PropTypes.object,
      scopes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PermissionScope))),
    }),
  ),
  description: PropTypes.string,
  enforceView: PropTypes.bool,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  onTabClick: PropTypes.func,
  siteIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  small: PropTypes.bool,
  authStrategy: PropTypes.string,
};

AuthTab.defaultProps = {
  activeTab: undefined,
  badgeColor: undefined,
  badgeValue: undefined,
  badgePlacement: undefined,
  capabilities: undefined,
  description: undefined,
  enforceView: false,
  large: false,
  onTabClick: () => {},
  siteIds: undefined,
  small: false,
};
