import { useMemo, useCallback, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { sorter } from '@ge/util/metric-sorter';

import {
  getHandlingProcedure,
  deleteHandlingProcedure,
  getEventMaps,
  getEventDesc,
  createHandlingProcedure,
  fetchAllHandlingProcedure,
  updateHandlingProcedure,
} from '../services/handling-procedure';
import { getDateTimeBasedOnZone } from '../util';

import { Config } from './config';

export const useFetchHPRecord = (props) => {
  const { id, isActive, params = {} } = props;
  const { data, error, isLoading } = useQuery(
    [QueryKey.GET_HANDLING_PROCEDURE, id],
    () => getHandlingProcedure({ params }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: !!isActive,
      select: (data) => {
        const [rec] = data;
        return { ...rec, eventsProp: handlingProcedureModifier(rec.eventsProp) };
      },
    },
  );
  return {
    data,
    hpRecordErrorMsg: error?.message,
    isLoading,
  };
};

const handlingProcedureModifier = (data = []) => {
  const records = [];
  data.forEach((event) => {
    const { eventMapId, eventMapName, events } = event;
    events.forEach((eventCode) => {
      records.push({
        EventMap: {
          label: eventMapName,
          value: eventMapId,
        },
        EventCode: {
          label: eventCode.sourceEventId,
          value: eventCode.eventId,
        },
        name: eventCode.name,
      });
    });
  });
  return records;
};

export const useEventMaps = () => {
  const { data, error, isLoading } = useQuery([QueryKey.EVENT_MAPS], getEventMaps, {
    ...Config.EXECUTE_ONCE,
    select: (data) => ({
      res: data,
      modifiedRes: evetMapModifier(data),
    }),
  });

  const getEventsMaps = useCallback(() => {
    if (!data?.res?.length) return [];
    return data.res.map((event) => ({
      label: event.name,
      value: event.eventMapId,
    }));
  }, [data]);

  const getEventCodes = useCallback(
    (eventMapId) => {
      if (!data?.res?.length) return [];
      return data.modifiedRes?.[eventMapId];
    },
    [data],
  );

  return {
    eventMapsData: data?.res || [],
    eventMapsError: error,
    eventMapsLoading: isLoading,
    getEventsMaps,
    getEventCodes,
    eventMapErrorMsg: error?.message,
  };
};

const evetMapModifier = (data) => {
  if (!data?.length) return {};
  return data.reduce((previous, item) => {
    previous[item['eventMapId']] = item;
    return previous;
  }, {});
};

export const useDeleteHP = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: deleteHP, isLoading, isError, error, isSuccess } = useMutation(
    deleteHandlingProcedure,
    {
      onSuccess: () => queryCache.invalidateQueries(QueryKey.HANDLING_PROCEDURES),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return useMemo(
    () => ({
      deleteHP,
      isLoading,
      isError,
      error,
      isSuccess,
      deleteErrorMsg: error?.response?.data?.statusMsg,
    }),
    [deleteHP, isLoading, isError, error, isSuccess],
  );
};

export const useCreateHP = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: createHP, isLoading, isError, error, isSuccess } = useMutation(
    createHandlingProcedure,
    {
      onSuccess: () => queryCache.invalidateQueries(QueryKey.HANDLING_PROCEDURES),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return {
    createHP,
    isLoading,
    isError,
    error,
    isSuccess,
    createErrorMsg: error?.response?.data?.statusMsg,
  };
};

export const useUpdateHP = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: updateHP, isLoading, isError, error, isSuccess } = useMutation(
    updateHandlingProcedure,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(QueryKey.GET_HANDLING_PROCEDURE);
        queryCache.invalidateQueries(QueryKey.HANDLING_PROCEDURES);
      },
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return {
    updateHP,
    isLoading,
    isError,
    error,
    isSuccess,
    updateErrorMsg: error?.response?.data?.statusMsg,
  };
};

export const useEventName = () => {
  const queryCache = useQueryClient();

  const {
    mutateAsync: getEventName,
    data: eventName,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useMutation(getEventDesc, {
    onSuccess: () => queryCache.invalidateQueries(QueryKey.EVENT_DESC, { exact: true }),
    enabled: true,
    ...Config.EXECUTE_ONCE,
  });
  return useMemo(
    () => ({
      getEventName,
      isLoading,
      isError,
      error,
      isSuccess,
      eventName,
      eventNameErrorMsg: error?.message,
    }),
    [getEventName, isLoading, isError, error, isSuccess, eventName],
  );
};

export const useFetchAllHP = (id) => {
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    [QueryKey.HANDLING_PROCEDURES, id],
    () => fetchAllHandlingProcedure(id),
    {
      ...Config.EXECUTE_ONCE,
      enabled: !!id,
      select: (data) => {
        if (!data || !data?.length) return [];
        const list = [];
        data.forEach(({ code, name, auditProperties = {}, procProperties }) => {
          if (procProperties?.procId && procProperties?.procId !== '0')
            list.push({
              code: +code,
              name,
              ...auditProperties,
              updatedDate: getDateTimeBasedOnZone(auditProperties.updatedDate).date,
              ...procProperties,
            });
        });
        return list;
      },
    },
  );

  return useMemo(() => ({ tableData: data, isLoading, isError, error, isSuccess }), [
    data,
    isLoading,
    isError,
    error,
    isSuccess,
  ]);
};

export const useHPTableFilter = ({ tableData = [], sortDirection, sortMetric, filterDefs }) => {
  const [searchText, setSearchText] = useState('');
  if (filterDefs?.code?.value) {
    tableData?.forEach((data) => (data.code = String(data.code)));
  }
  const { data: filteredData, filterValues } = useTableFilter({
    data: tableData,
    filters: filterDefs,
    search: searchText,
  });
  const code = filterValues?.code?.map(String);
  // cuts a new ref when sorting to update graph accordingly
  const sortedData = useMemo(
    () => (filteredData?.length ? [...filteredData.sort(sorter(sortMetric, sortDirection))] : []),
    [filteredData, sortDirection, sortMetric],
  );

  return {
    filteredTableData: sortedData,
    setSearchText,
    filterValues: { ...filterValues, code: code },
  };
};
