import classNames from 'classnames';
import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FlagIcon } from '@ge/components/approval-flag-icon';
import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { Severity } from '@ge/components/severity';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { Capability, NotificationType, PermissionScope, TaskStatus } from '@ge/models/constants';
import {
  DateTimeFormats,
  EntityType,
  TaskRecurrenceEnds,
  TaskRecurrence,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth, useEditTask } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';
import { typography } from '@ge/tokens';

import EntityDetailHeader, {
  HeaderIcon,
  TypeIcon,
  MetricIcon,
  StyledDetailsDismissInert,
} from '../entity-details-header';

const StyledButton = styled.button`
  cursor: ${({ isAuthorized }) => (isAuthorized ? 'pointer' : 'default')};
`;

const CaseNameButton = styled.button`
  color: currentcolor;
  cursor: pointer;
  font-weight: 100;
  span {
    margin-right: 11px;
  }
`;

const Flag = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: ${typography.weight.bold};
  button {
    background: none;
    border: none;
    padding: inherit;
  }
`;

const DropDown = styled.div`
  position: relative;
  display: contents;
`;

const StyleApprovalFlagText = styled.p`
  border-radius: 4px;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  font-size: 11px;
  &:hover {
    background-color: ${(props) => props.theme.select.hoverColor};
  }
  .check-icon {
    float: right;
  }
`;

const TaskFlagText = styled.div`
  margin-top: 10px;
  .task-flag-icon {
    margin-right: 5px;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
`;

const CancelButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.deleteCaseTemplate.cancelBorderColor};
`;

const ConfirmButton = styled(Button)`
  border-radius: 4px;
  margin: 0 20px 0 10px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.select.checkmarkColor,
}))``;

export const TaskDetailsHeader = withRouter(({ task, caseDetails, showConfirmationPrompt }) => {
  const { t } = useTranslation(['tasks', 'entity-details'], { useSuspense: false });

  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

  const entityDetailsContext = useContext(EntityDetailsContext);
  const { showDetails, showCaseDetails, dirtyFields, setEntityFieldsDirtyCheck } =
    entityDetailsContext;
  const [showEditFlagModal, setShowEditFlagModal] = useState(false);
  const [selectedTaskFlagValue, setSelectedTaskFlagValue] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { notify } = useNotification();
  const logger = useLogger();
  const { recurrence } = task ?? {};

  const { isAuthorized } = useAuth();
  const capabilities = [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }];
  const siteId = task.site?.id;
  const authorized = isAuthorized({ capabilities, siteId });

  const editApprovalFlagOptions = [
    { label: 'Not Approved', value: 'notApproved' },
    { label: 'Approved', value: 'approved' },
  ];

  const displayRecurrence = () => {
    if (recurrence?.recurrence === TaskRecurrence.NONE || !recurrence?.recurrence) {
      return '';
    } else {
      return recurrence?.recurrenceType === TaskRecurrenceEnds.END_BY_DATE
        ? `${t('recurring', 'Recurring: ')} ${t('form.ends', 'Ends')} ${t('form.on', 'On')} ${dayjs(
            recurrence.recurrenceEndByDate,
          ).format(DateTimeFormats.DEFAULT_DATE)}`
        : `${t('recurring', 'Recurring: ')} ${t('form.ends', 'Ends')} ${t('form.after', 'After')} ${
            recurrence.recurrenceEndByCount
          } ${t('form.occurences', 'Occurrences')}`;
    }
  };
  const handleEntitySelect = useCallback(
    (e, id, type, isAuthorized) => {
      killEventPropagation(e);
      if (isAuthorized) {
        if (dirtyFields) {
          if (EntityType.SITE === type) {
            setEntityFieldsDirtyCheck((prevState) => ({ ...prevState, [EntityType.SITE]: true }));
            showConfirmationPrompt();
          } else if (EntityType.TURBINE === type) {
            setEntityFieldsDirtyCheck((prevState) => ({
              ...prevState,
              [EntityType.TURBINE]: true,
            }));
            showConfirmationPrompt();
          }
        } else {
          showDetails(id, type);
        }
      }
    },
    [showDetails, dirtyFields],
  );
  const handleCaseDetails = (caseId) => {
    if (dirtyFields) {
      setEntityFieldsDirtyCheck((prevState) => ({ ...prevState, [EntityType.CASE]: true }));
      showConfirmationPrompt();
    } else {
      showCaseDetails(caseId);
    }
  };

  const handleApprovalFlagSelection = (event, index) => {
    event.stopPropagation();
    setSelectedTaskFlagValue(editApprovalFlagOptions[index]);
    setShowEditFlagModal(true);
  };

  const { edit } = useEditTask({
    onSuccess: () => {
      const message = (
        <NotificationMessage
          message={t('task_notification.task_updation', 'Task Updated Successfully')}
        />
      );
      notify({ message });
    },
    onError: (e) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.task_update_failed', 'Task Update Failed')}
        />
      );
      const type = NotificationType.FAILURE;
      notify({ message, type });
      logger.error('Error updating task', e);
    },
  });

  const onConfirm = useCallback(async () => {
    setShowEditFlagModal(false);
    const updatedPayload = {};
    const message = (
      <NotificationMessage
        message={t('task_notification.updating_task', 'Updating Task')}
        isProgressLoader={true}
      />
    );
    notify({ message });
    const id = task?.id;
    updatedPayload.flag = selectedTaskFlagValue?.value ? selectedTaskFlagValue?.value : task?.flag;
    edit({ id, task: updatedPayload });
  }, [t, notify, task.id, task.flag, selectedTaskFlagValue, edit]);

  const getFooter = () => {
    return (
      <ButtonSection>
        <CancelButton onClick={() => setShowEditFlagModal(false)}>
          {t('dynamic.task_flag.cancel', 'Cancel')}
        </CancelButton>
        <ConfirmButton primary onClick={onConfirm}>
          {t('dynamic.task_flag.confirm', 'Confirm=')}
        </ConfirmButton>
      </ButtonSection>
    );
  };

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  if (!task) {
    return null;
  }

  return (
    <EntityDetailHeader type={EntityType.TASK}>
      <div className="left">
        <div className="related">
          <div className="asset-top">
            <StyledDetailsDismissInert className="left">
              {/* <HeaderIcon icon={Icons.PERSON} />
              {'Avangrid / '} */}
              {task.site?.id && (
                <StyledButton
                  type="button"
                  onClick={(e) => handleEntitySelect(e, siteId, EntityType.SITE, authorized)}
                  isAuthorized={authorized}
                >
                  <HeaderIcon icon={Icons.SITE} />
                  <span>{task.site.name ? task.site.name : ''}</span>
                  {authorized && <HeaderIcon icon={Icons.CHEVRON} rotate={-90} />}
                </StyledButton>
              )}
              {task.asset?.id && (
                <StyledButton
                  type="button"
                  onClick={(e) =>
                    handleEntitySelect(e, task.asset.id, EntityType.TURBINE, authorized)
                  }
                  isAuthorized={authorized}
                >
                  <HeaderIcon icon={Icons.TURBINE} />
                  <Badge
                    color={getTurbineStateColor(task.asset.state)}
                    label={getTurbineStateType(task.asset.state)}
                    medium
                  />
                  &nbsp;
                  <div className="asset-name">{task.asset.name}</div>
                  {authorized && <HeaderIcon icon={Icons.CHEVRON} rotate={-90} />}
                </StyledButton>
              )}
            </StyledDetailsDismissInert>
            {/* commenting the code temporary as a part of defect -DE179074*/}
            {/* {task.asset && (
              <div className="right list-right">
                {task.asset.events !== undefined && (
                  <div>
                    <MetricIcon icon={Icons.EVENT} />
                    {`${task.asset.events}`}
                  </div>
                )}
                {task.asset.events !== undefined && (
                  <div>
                    <MetricIcon icon={Icons.ANOMALY} />
                    {`${task.asset.anomalies}`}
                  </div>
                )}
                {task.asset.events !== undefined && (
                  <div>
                    <MetricIcon icon={Icons.TASK} />
                    {`${task.asset.tasks}`}
                  </div>
                )}
              </div>
            )} */}
          </div>
        </div>
        <div className="details">
          <div className="left">
            <TypeIcon icon={Icons.TASK} />
          </div>
          <div className="right">
            <div className="source-details">
              {caseDetails && (
                <CaseNameButton type="button" onClick={() => handleCaseDetails(caseDetails?.id)}>
                  <MetricIcon icon={Icons.ANOMALY} />
                  <span>{caseDetails?.description ? 'CASE: ' + caseDetails.description : ''}</span>
                  <HeaderIcon icon={Icons.CHEVRON} rotate={-90} />
                </CaseNameButton>
              )}
              {/* Hiding for MVP0 - Story #1078 */}
              {/* <MetricIcon icon={Icons.EVENT} />
              <span>Mup be zo wivibuf fefiho.</span> */}
              {/* <HeaderIcon icon={Icons.CHEVRON} rotate={-90} /> */}
              {/* <div className="source">
                {t('source', 'Source')}: {task.source}
              </div> */}
            </div>
            <div className="task-type">{t(`dynamic.types.${task.source}`, task.source)}</div>
            <div className="title">
              {task.priority && <Severity size={12} level={task.priority} />}
              {task.title}
              <div className="task-details">
                {approvalFlag && task.flag && (
                  <Flag>
                    <FlagIcon value={task?.flag} size={10} className={'task-flag-icon'} />
                    {task?.flag ? t(`dynamic.task_flag.${task.flag}`, task.flag) : ''}
                    {task.status !== TaskStatus.COMPLETED && (
                      <AuthRender
                        capability={Capability.FIELD_TASK_APPROVER}
                        edit
                        siteIds={[siteId]}
                      >
                        <DropDown>
                          <Button
                            onClick={(e) => {
                              if (dirtyFields) {
                                setEntityFieldsDirtyCheck((prevState) => ({
                                  ...prevState,
                                  [EntityType.TASK]: true,
                                }));
                                showConfirmationPrompt();
                              } else {
                                showMenu(e);
                              }
                            }}
                          >
                            <HeaderIcon size={10} icon={Icons.PENCIL} />
                          </Button>
                        </DropDown>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          portalId=".task-details"
                          width={'150'}
                          padding={'0px'}
                        >
                          {editApprovalFlagOptions.map((flagOption, index) => (
                            <StyleApprovalFlagText
                              key={index}
                              onClick={(e) => handleApprovalFlagSelection(e, index)}
                            >
                              {flagOption.label.toUpperCase()}
                              {task?.flag === flagOption?.value && (
                                <StyledIcon icon={Icons.CHECK} className="check-icon" />
                              )}
                            </StyleApprovalFlagText>
                          ))}
                        </Menu>
                      </AuthRender>
                    )}
                  </Flag>
                )}
                <div className={classNames(`${task.status}`, 'lasso-card')}>
                  {task?.status ? task.status : ''}
                  {/* Hiding for MVP0 - Story #1078 */}
                  {/* <HeaderIcon icon={Icons.PENCIL} /> */}
                </div>
                <div className="recurring">{displayRecurrence()}</div>
              </div>
              {showEditFlagModal && (
                <Dialog
                  isOpen={true}
                  onClose={() => setShowEditFlagModal(false)}
                  onConfirm={onConfirm}
                  header={t('dynamic.task_flag.approve_not_approve', 'APPROVE/NOT APPROVE')}
                  footer={getFooter()}
                >
                  <div>
                    {t(
                      'dynamic.task_flag.confirm_edit_msg',
                      'Are you sure you want to change approval to:',
                    )}
                  </div>
                  <TaskFlagText>
                    <FlagIcon
                      value={selectedTaskFlagValue?.value}
                      size={10}
                      className={'task-flag-icon'}
                    />
                    {selectedTaskFlagValue?.label}
                  </TaskFlagText>
                </Dialog>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Hiding for MVP0 - Story #1078 */}
      {/* <div className="actions">
        <button type="button">
          <HeaderIcon icon={Icons.PENCIL} /> {t('add_note', 'add note')}
        </button>
        <button type="button">
          <HeaderIcon icon={Icons.CALENDAR} /> {t('schedule', 'schedule')}
        </button>
        <button type="button">
          <HeaderIcon icon={Icons.ARROW_UP} /> {t('enter_erp', 'enter erp')}
        </button>
        <button type="button">
          <HeaderIcon icon={Icons.ARROW_UP} rotate={90} /> {t('close_task', 'close task')}
        </button>
      </div> */}
    </EntityDetailHeader>
  );
});

TaskDetailsHeader.propTypes = {
  task: PropTypes.instanceOf(Object),
};

TaskDetailsHeader.defaultProps = {
  task: null,
};

TaskDetailsHeader.displayName = 'TaskDetailsHeader';
