import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { ConditionalRender } from '@ge/components/conditional-render';
import {
  Menu,
  MenuTitle,
  MenuTable,
  MenuChevron,
  placements,
  MenuLoader,
} from '@ge/components/menu';
import { globalColors } from '@ge/tokens/colors';

const StyledTicketsMenu = styled.div`
  font-size: 11px;
  text-align: left;
  display: inline;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'inherit')};
`;

const TicketsMenu = ({ count, tickets, onMenuOpen, onSelectHandler, containerRef, disabled }) => {
  const { t } = useTranslation(['monitor.issues']);
  const [anchorEl, setAnchorEl] = useState(null);

  const showMenu = (event) => {
    event.stopPropagation();

    if (disabled || count === 0) return;
    onMenuOpen(event);
    setAnchorEl(event.currentTarget);
  };

  // Disallow all clicks
  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTicketsMenu
      onClick={(e) => handleClick(e)}
      onMouseEnter={(e) => showMenu(e)}
      onMouseLeave={() => handleMenuClose()}
      disabled={disabled}
    >
      <Badge color={globalColors.slate5} label={`${count}`} small arrow />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.BOTTOM_START}
      >
        <MenuTitle title={t('ticketsMenu.tickets', 'TICKETS')} />
        <ConditionalRender shouldRender={Boolean(anchorEl) && !tickets}>
          <MenuLoader />
        </ConditionalRender>
        <ConditionalRender shouldRender={Boolean(anchorEl) && !!tickets}>
          <MenuTable>
            <table>
              <thead>
                <tr>
                  <th>{t('ticketsMenu.type', 'Type')}</th>
                  <th>{t('ticketsMenu.ticket', 'Ticket')} #</th>
                  <th>{t('ticketsMenu.status', 'Status')}</th>
                  <th aria-label="actions" />
                </tr>
              </thead>
              <tbody>
                {tickets &&
                  tickets.map((ticket) => (
                    <tr
                      key={ticket.number}
                      onClick={(e) => {
                        onSelectHandler(e, ticket);
                        handleMenuClose();
                      }}
                    >
                      <td>{ticket.type}</td>
                      <td>{ticket.number}</td>
                      <td>{ticket.status}</td>
                      <td>
                        <MenuChevron />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </MenuTable>
        </ConditionalRender>
      </Menu>
    </StyledTicketsMenu>
  );
};

TicketsMenu.propTypes = {
  count: PropTypes.number,
  tickets: PropTypes.arrayOf(Object),
  onMenuOpen: PropTypes.func,
  onSelectHandler: PropTypes.func,
  containerRef: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
};

TicketsMenu.defaultProps = {
  count: 0,
  tickets: null,
  onMenuOpen: () => null,
  onSelectHandler: () => null,
  containerRef: null,
  disabled: false,
};

export default TicketsMenu;
