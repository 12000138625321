import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { DataLoader } from '@ge/components/data-loader';
import { Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Textarea } from '@ge/components/textarea/textarea';
import {
  useGetROCActionTakenOptions,
  useCreateROCActionLog,
  useCreateBulkROCActionLog,
} from '@ge/feat-monitor/data-hooks/use-roc-logs-actions';
import { EntityType, RocLogsActionType, SEE_MORE_CHAR } from '@ge/models/constants';
import { DateTimeMetaField } from '@ge/shared/components/meta-fields';
import { SeeMore } from '@ge/shared/components/see-more';
import { StyledCollapsiblePanel } from '@ge/shared/components/tasks/task-template-shared';
import { Form, FormCol, FormRow } from '@ge/shared/dynamic-template/form-shared';
import { useAddNoteDialog } from '@ge/shared/hooks';
import { ActionKeys } from '@ge/shared/models/actions-menu-items';
import { getUserName } from '@ge/shared/services/auth';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

const formMetadata = {
  dateTime: {
    readOnly: false,
    required: false,
    defaultSelection: 'currentDate',
  },
};

const FormSchema = {
  DESCRIPTION: 'description',
  ENTITY_ID: 'entityId',
  ENTITY_TYPE: 'entityType',
  ACTION_TYPE: 'actionType',
  ACTIONS: 'actions',
};

const RocLogDialogSegment = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  margin-top: 20px;
  padding: 20px 25px 10px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const RocLogFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const StyledInput = styled(Input)``;
const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  font: 12px;
  line-height: 14px;
  font-weight: 300;
`;

const ActionTakenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: calc(15px * 10 + 10rem);
  align-content: flex-start;
  div {
    width: 50%;
    height: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
    span {
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
      color: ${(props) => props.theme.entityDetails.notes.entityDescription};
      margin-left: 5px;
    }
    div {
      width: 14px;
      height: 14px;
      margin-bottom: 0px;
    }
  }
`;
const StyledLabel = styled.div`
  display: block;
  font-family: Museo Sans;
  font-size: 11px;
  font-weight: 00;
  line-height: 20px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

const StyledLabelBold = styled.div`
  display: block;
  font-family: Museo Sans;
  font-size: 11px;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: -1rem;
`;
const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;
const ScrollingWrapper = styled.div`
  display: flex;
  min-height: 50vh;
  overflow: hidden;
  margin-bottom: 20px;

  ${scrollbarWrapperOverride}
`;

const ScrollingFormContainer = styled.div`
  padding: 0 25px;
  .validation-error {
    color: ${(props) => props.theme.dangerColor};
  }
`;

const CaseNoteCollapsiblePanel = styled(StyledCollapsiblePanel)`
  margin-left: 0;

  #caseNote {
    box-sizing: border-box;
    width: 100%;
  }
`;

const CaseNoteTextArea = styled(Textarea)`
  width: 100%;
  height: 60px;
`;

const BulkAssets = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  & > div:first-child {
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 12px;
    color: ${(props) => props.theme.entityDetails.tickets.textColor};
    text-align: left;
    margin-bottom: 12px;
    font-weight: 400;
    margin-top: 1rem;
  }
`;

const EntityInfo = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
  padding: 0 25px;
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))`
  margin-left: 7px;
`;

// RocLogsAction component
export const CloseCaseAction = ({
  onClose,
  onConfirm,
  entity,
  siteType,
  entityType,
  collapsed,
  selectedAssets,
  eventsChecked,
}) => {
  const { t, ready } = useTranslation(['monitor.issues'], { useSuspense: false });

  // State
  const [actionsTaken, setActionsTaken] = useState([]);
  const [createROCActionLogResponse, setCreateROCActionLogResponse] = useState();
  const [createBulkROCActionLogResponse, setCreateBulkROCActionLogResponse] = useState();
  const [isFormValidationError, setIsFormValidationError] = useState(false);

  const isBulkROCAction = useMemo(() => selectedAssets?.length > 1, [selectedAssets]);

  const entityDetails = {
    assetType: entity?.asset?.type,
    siteType: siteType,
    siteName: entity?.site?.name,
    assetName: entity?.asset?.name,
    entityDescriptionIcon: Icons.EVENT,
    entityDescription: entity?.description,
  };

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { [FormSchema.ACTION_TYPE]: RocLogsActionType.ACTION_TAKEN },
  });

  const { control, handleSubmit, register, setValue } = methods;
  useWatch({ control, name: 'createdTime' });

  const entityId = entity?.id;

  const { data: actions, isLoading } = useGetROCActionTakenOptions({
    assetMake: entity?.asset?.make,
    assetModel: entity?.asset?.model,
    controllerType: entity?.asset?.controllerType,
    assetType: entity?.asset?.type,
  });

  const { create: createROCActionLog, isError } = useCreateROCActionLog({
    onSuccess: (output) => {
      setCreateROCActionLogResponse(output);
    },
  });

  const { create: createBulkROCActionLog, isBulkError } = useCreateBulkROCActionLog({
    onSuccess: (output) => {
      setCreateBulkROCActionLogResponse(output);
    },
  });

  const { createEventNote, createBulkEventNotes } = useAddNoteDialog(entity);

  const modifyActionTaken = useCallback(
    (value) => {
      const arr = [...actionsTaken];
      var index = arr.indexOf(value);
      index === -1 ? arr.push(value) : arr.splice(index, 1);
      setActionsTaken(arr);
    },
    [actionsTaken],
  );

  const onSubmit = useCallback(
    (values) => {
      if (actionsTaken?.length) {
        if (selectedAssets?.length > 1) {
          createBulkROCActionLog({ ...values, actionsTaken, entity: selectedAssets });
        } else {
          createROCActionLog({ ...values, actionsTaken, entity });
        }
        if (values.note) {
          if (selectedAssets?.length > 1) {
            createBulkEventNotes({ ...values, caseDetails: selectedAssets });
          } else {
            createEventNote({ ...values, entity: { id: values.entityId } });
          }
        }
        setIsFormValidationError(false);
      } else {
        setIsFormValidationError(true);
      }
      onConfirm({
        state: ActionKeys.EVENT_CLOSE,
        caseId: selectedAssets && selectedAssets?.length > 1 ? eventsChecked : values.entityId,
      });
    },
    [
      actionsTaken,
      onConfirm,
      createROCActionLog,
      entity,
      selectedAssets,
      createBulkEventNotes,
      createBulkROCActionLog,
      createEventNote,
      eventsChecked,
    ],
  );

  // bind hidden/programmatic form values in here
  useEffect(() => {
    // tried to streamline these by setting value in register options but didn't work
    register(FormSchema.ENTITY_ID);
    setValue(FormSchema.ENTITY_ID, entityId);

    register(FormSchema.ENTITY_TYPE);
    setValue(FormSchema.ENTITY_TYPE, entityType);
  }, [entityId, entityType, register, setValue]);

  useEffect(() => {
    if (createROCActionLogResponse || isError) {
      onConfirm();
    }
  }, [createROCActionLogResponse, isError, onConfirm]);

  useEffect(() => {
    if (createBulkROCActionLogResponse || isBulkError) {
      onConfirm();
    }
  }, [createBulkROCActionLogResponse, isBulkError, onConfirm]);

  const getFooter = useMemo(() => {
    return (
      <RocLogFooterWrapper>
        <FooterButtons>
          <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary disabled={actionsTaken?.length === 0} onClick={handleSubmit(onSubmit)}>
            {t('closeCaseAction.save_close', 'Save & Close')}
          </Button>
        </FooterButtons>
      </RocLogFooterWrapper>
    );
  }, [onClose, t, handleSubmit, onSubmit, actionsTaken]);

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onConfirm}
      header={t('closeCaseAction.close_action', 'CLOSE CASE')}
      footer={getFooter}
      padContent={false}
    >
      {entityType === EntityType.CASE && !isBulkROCAction ? (
        <RocLogDialogSegment>
          <EntityDialogHeader entityDetails={entityDetails} />
        </RocLogDialogSegment>
      ) : (
        <BulkAssets>
          <EntityInfo>
            <span>
              {t('escalate_case.assets_selected', 'Asset(s) Selected: All Asset(s)')}
              <StyledBadge label={selectedAssets?.length} small />
            </span>
          </EntityInfo>
          <EntityInfo>
            <SeeMore charLimit={SEE_MORE_CHAR}>
              {selectedAssets.map((item) => item.assetName).join(', ')}
            </SeeMore>
          </EntityInfo>
        </BulkAssets>
      )}
      <ScrollingWrapper>
        <ScrollingContainer>
          <ScrollingFormContainer>
            <Form>
              <FormProvider {...methods}>
                <form>
                  <FormRow>
                    <FormCol span="12">
                      <StyledLabelBold>
                        {t('closeCaseAction.log_action', 'LOG ACTION')}
                      </StyledLabelBold>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol span="4">
                      <StyledInput
                        name="createdBy"
                        label={t('closeCaseAction.form.createdBy.label', 'Created By')}
                        defaultValue={getUserName()}
                        ref={register}
                        disabled
                      />
                    </FormCol>
                    <FormCol span="8">
                      <DateTimeMetaField
                        defaultSelection={formMetadata.dateTime.defaultSelection}
                        name="createdTime"
                        label={t('closeCaseAction.form.createdTime.label', 'Created Time')}
                        metadata={formMetadata.dateTime}
                      />
                    </FormCol>
                  </FormRow>
                  <DataLoader isLoading={isLoading} type="table" renderCondition>
                    <div>
                      <StyledLabel>
                        {t('closeCaseAction.form.actionTaken.label', 'Action Taken')}
                        <span className={isFormValidationError ? 'validation-error' : ''}>
                          {`* ${
                            isFormValidationError
                              ? t(
                                  'closeCaseAction.form.actionTaken.validation_error_message',
                                  '(Please select at least one checkbox or add a description)',
                                )
                              : ''
                          }`}
                        </span>
                      </StyledLabel>
                      <ActionTakenContainer>
                        {actions?.length &&
                          actions?.map((action) => {
                            return (
                              <div key={action}>
                                <StyledCheckbox
                                  label={action}
                                  checkState={
                                    actionsTaken.includes(action)
                                      ? CheckedState.CHECKED
                                      : CheckedState.UNCHECKED
                                  }
                                  onChange={() => modifyActionTaken(action)}
                                />
                              </div>
                            );
                          })}
                      </ActionTakenContainer>
                    </div>
                  </DataLoader>
                  <FormRow className="description">
                    <FormCol span="12">
                      <CaseNoteCollapsiblePanel
                        expanded={collapsed}
                        headerContent={
                          <h4>
                            {t('closeCaseAction.form.case_note.label', 'CASE NOTE (OPTIONAL)')}
                          </h4>
                        }
                        plusIcon
                      >
                        <CaseNoteTextArea
                          id="textarea"
                          placeholder={t(
                            'closeCaseAction.form.case_note.placeholder_label',
                            'Type your note here...',
                          )}
                          name="note"
                          defaultValue={''}
                          ref={register}
                        />
                      </CaseNoteCollapsiblePanel>
                    </FormCol>
                  </FormRow>
                </form>
              </FormProvider>
            </Form>
          </ScrollingFormContainer>
        </ScrollingContainer>
      </ScrollingWrapper>
    </Dialog>
  );
};

CloseCaseAction.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
  siteType: PropTypes.string,
  isOpen: PropTypes.bool,
  mode: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  collapsed: PropTypes.bool,
  selectedAssets: PropTypes.instanceOf(Array),
  eventsChecked: PropTypes.instanceOf(Array),
};

CloseCaseAction.defaultValues = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  collapsed: false,
  defaultValue: undefined,
};
