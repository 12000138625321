import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TaskTemplateModes } from '@ge/models/constants';

import { LaborEstimates } from '../../../task-fields/labor-estimates';
import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

const EstimateRow = styled.div`
  display: flex;
  justify-content: flex-start;
  .field-group {
    display: flex;
    align-items: flex-end;
    margin-right: 40px;
  }
`;

export const EditLaborEstimates = ({ metadata, task, workStandardsData }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.labor_estimates', 'Labor Estimates')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          <EstimateRow>
            <LaborEstimates
              metadata={metadata}
              task={task}
              mode={TaskTemplateModes.EDIT}
              workStandardsData={workStandardsData}
            />
          </EstimateRow>
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

EditLaborEstimates.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  workStandardsData: PropTypes.object,
};

EditLaborEstimates.defaultProps = {
  handleTaskType: () => null,
};
