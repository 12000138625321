import { PropTypes } from 'prop-types';
import React, { useMemo, useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalWrapper } from '@ge/components/conditional-wrapper';
import { DataLoader } from '@ge/components/data-loader';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { useColumnState } from '@ge/components/table/use-column-state';
import useAssetCaseData from '@ge/feat-analyze/data-hooks/use-asset-case-data';
import { useTasksTable } from '@ge/feat-manage/data-hooks/use-tasks-table-asset';
import { defaultTasksCols, TasksColumnDefs } from '@ge/feat-manage/models/tasks-table-cols';
import {
  IssueType,
  SortDirection,
  SortValueType,
  TaskSourceField,
  EntityTab,
  EntityMode,
  DispositionOptions,
  DispositionOptionValues,
} from '@ge/models/constants';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useCloseCase } from '@ge/shared/data-hooks/use-update-case';
import { killEventPropagation } from '@ge/shared/util';

import AssetCasesTable from './asset-cases-table';
import AssetTasksTable from './asset-tasks-table';

const TableScrollRowCount = 8;

const Container = styled.div`
  min-height: 120px;
`;

const ScrollingTableContainer = styled.div`
  > div {
    min-height: 300px;
  }
`;

const SelectWrapper = styled.div`
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
`;

const DispositionValues = DispositionOptions.filter((option) =>
  [DispositionOptionValues.RESOLVED, DispositionOptionValues.INCORRECT].includes(option.value),
).flatMap((v) => v.label);

export const TaskMetric = {
  NAME: 'task.asset',
  TYPE: 'task.type',
  CREATE_DATE: 'task.createDate',
};

export const AssetOpenCasesAndTasks = ({
  selectedTable,
  handleSelect,
  selectedTableValue,
  tableOptions,
  assetId,
  isAuthorizedToViewTasks,
  isAuthorizedToMonitorCases,
  isAuthorizedToAnalyzeCases,
  handleNewTask,
  setShowLinkModal,
}) => {
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const SORT_STATE_ID = 'manage-cases-tasks';
  const { showTaskDetails, showCaseDetails } = useContext(EntityDetailsContext);

  const { ready } = useTranslation(['analyze.asset'], {
    useSuspense: false,
  });

  // Table state management
  const {
    sortDirection: sortTaskDirection,
    sortMetric: sortTaskMetric,
    updateSortMetric: toggleTaskSort,
  } = useColumnState({
    columnDefs: TasksColumnDefs,
    defaultCols: defaultTasksCols,
    defaultSortMetric: 'createDate',
    defaultSortDirection: SortDirection.DESC,
    defaultSortType: SortValueType.ALPHANUMERIC,
    sortStateId: SORT_STATE_ID,
  });

  // Table state management
  const {
    sortDirection,
    sortMetric,
    updateSortMetric: toggleAssetSort,
    sortType,
  } = useColumnState({
    defaultSortMetric: 'opened',
    defaultSortDirection: SortDirection.DESC,
    defaultSortType: SortValueType.ALPHANUMERIC,
  });

  const { data, isLoading, refetch, isRefetching } = useAssetCaseData({
    assetId,
    sortDirection,
    sortMetric,
    sortType,
    isAuthorizedToMonitorCases,
    isAuthorizedToAnalyzeCases,
  });

  const { data: { data: tasks, filterValues } = {}, isLoading: isTasksLoading } = useTasksTable({
    sortDirection: sortTaskDirection,
    sortMetric: sortTaskMetric,
    assetId,
  });

  const { mutate: closeCase } = useCloseCase();

  const assetTableProps = useMemo(() => {
    const dynamicProps = [
      {
        // isLoading: isTaskHisotryDataLoading,
        // history: taskHistoryData,
        sortAction: toggleAssetSort,
        sortMetric,
        sortDirection,
        tableType: IssueType.OPEN_CASES,
      },
      {
        // isLoading: isCaseHistoryDataLoading,
        // history: caseHistoryData,
        sortAction: toggleTaskSort,
        sortDirection: sortTaskDirection,
        sortMetric: sortTaskMetric,
        tableType: IssueType.FIELD_TASK,
      },
    ];
    return dynamicProps.find((prop) => prop.tableType === selectedTable);
  }, [
    toggleAssetSort,
    sortMetric,
    sortDirection,
    toggleTaskSort,
    sortTaskDirection,
    sortTaskMetric,
    selectedTable,
  ]);

  const tableShouldScroll = useMemo(() => data?.length >= TableScrollRowCount, [data]);

  const handleCaseSelect = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showCaseDetails(id);
    },
    [showCaseDetails],
  );

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
  }, []);

  const handleTaskSelect = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showTaskDetails(id);
    },
    [showTaskDetails],
  );

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const handleCloseCase = useCallback(
    ({ id, disposition, rootCause, ...rest }) => {
      if ((disposition && rootCause) || (DispositionValues.includes(disposition) && !rootCause)) {
        closeCase({ id, disposition, rootCause, ...rest });
      } else {
        showCaseDetails(id, EntityTab.RESOLUTION, EntityMode.EDIT);
      }
    },
    [closeCase, showCaseDetails],
  );

  if (!ready) {
    return <Loader />;
  }

  return (
    <Container>
      {isAuthorizedToViewTasks && (isAuthorizedToMonitorCases || isAuthorizedToAnalyzeCases) && (
        <SelectWrapper>
          <Select onChange={handleSelect} value={selectedTableValue} options={tableOptions} />
        </SelectWrapper>
      )}
      {selectedTable === IssueType.OPEN_CASES && (
        <DataLoader
          isLoading={isLoading || (isRefetching && data?.length === 0)}
          type="table"
          renderCondition={true}
        >
          <ConditionalWrapper
            condition={tableShouldScroll}
            wrapper={(children) => <ScrollingTableContainer>{children}</ScrollingTableContainer>}
          >
            <AssetCasesTable
              cases={data}
              isLoading={isLoading}
              sortMetric={sortMetric}
              sortDirection={sortDirection}
              onCaseSelect={handleCaseSelect}
              handleCloseCase={handleCloseCase}
              handleNewTask={handleNewTask}
              onRetry={refetch}
              scrollable={tableShouldScroll}
              setShowLinkModal={setShowLinkModal}
              {...assetTableProps}
            />
          </ConditionalWrapper>
        </DataLoader>
      )}
      {selectedTable === IssueType.FIELD_TASK && (
        <DataLoader
          isLoading={isLoading || (isRefetching && data?.length === 0)}
          type="table"
          renderCondition={true}
        >
          <ConditionalWrapper
            condition={tableShouldScroll}
            wrapper={(children) => <ScrollingTableContainer>{children}</ScrollingTableContainer>}
          >
            <AssetTasksTable
              tasks={tasks}
              isLoading={isTasksLoading}
              sortMetric={sortMetric}
              sortDirection={sortDirection}
              filterValues={filterValues}
              onTaskSelect={handleTaskSelect}
              onRetry={refetch}
              scrollable={tableShouldScroll}
              assetId={assetId}
              {...assetTableProps}
            />
            {showNewTaskModal && (
              <NewTaskDialog
                onClose={closeModal}
                onConfirm={saveTask}
                taskSource={TaskSourceField.REALTIMECASES}
              />
            )}
          </ConditionalWrapper>
        </DataLoader>
      )}
    </Container>
  );
};

AssetOpenCasesAndTasks.propTypes = {
  assetId: PropTypes.string,
  isAuthorizedToViewTasks: PropTypes.bool,
  isAuthorizedToMonitorCases: PropTypes.bool,
  isAuthorizedToAnalyzeCases: PropTypes.bool,
  tasksData: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  selectedTable: PropTypes.string,
  handleSelect: PropTypes.func,
  selectedTableValue: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  tableOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  siteId: PropTypes.string,
  handleNewTask: PropTypes.func,
  setShowLinkModal: PropTypes.func,
};

AssetOpenCasesAndTasks.defaultProps = {
  isAuthorizedToViewTasks: false,
  isAuthorizedToMonitorCases: false,
  isAuthorizedToAnalyzeCases: false,
};
