import { action, thunk, computed } from 'easy-peasy';

// import { AuthMatch } from '../../shared/models/auth';
// import Cookies from 'js-cookie';

import { AppScopes } from '@ge/shared/models/scopes';

// Default state to apply on initialization or reset.
const defaultSessionState = {
  email: '',
  sso: '212310120',
  idToken: null,
  inPopout: false,
  name: '',
  scopes: [],
  userInfo: { cn: 'unknown', sso: '212310120' },
};

const offlineSessionState = {
  name: 'Mock User',
  email: 'mock@cruxpoc.com',
  sso: '212310120',
  idToken: { jwtToken: 'mock_token' },
  // Give access to all available scopes
  scopes: Object.values(AppScopes),
  userInfo: { cn: 'unknown', sso: '212310120' },
};

// Actions
const sessionActions = {
  resetSession: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, defaultSessionState);

    // We dont need to do this anymore
    //Cookies.remove('RSOMyLogin');
    //Cookies.remove('reninspect_oauth_cookie');
  }),

  setInPopout: action((state, payload) => {
    state.inPopout = payload;
  }),

  setMockSessionData: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, offlineSessionState);
  }),

  setSessionData: action((state, cognitoSession) => {
    if (!cognitoSession) {
      return;
    }

    const decodedToken = cognitoSession.idToken.decodePayload();
    state.idToken = cognitoSession.idToken || state.idToken;
    state.name = decodedToken.name || state.name;
    state.email = decodedToken.email || state.email;

    // state.scopes = decodedToken['cognito:groups'];
    // Temporarily mock scopes all the time until we get real access tokens.
    state.scopes = Object.values(AppScopes);
  }),

  // New method to set user sso obtained from GE session token
  setSessionSSO: action((state, newSSO) => {
    state.sso = newSSO;
  }),

  setUserInfo: action((state, newUserInfo) => {
    //console.log('saving userInfo: ',newUserInfo);
    state.userInfo = newUserInfo;
  }),

  fetchUserInfo: thunk(async (actions) => {
    console.log('fetchUserInfo() called');
    let resp = await fetch('/user_info');
    if (resp.status === 200) {
      const info = await resp.json();
      console.log('GET /user_info returned:', info);
      actions.setUserInfo(info);
    }
  }),

  setSSO: action((state, newSSO) => {
    //console.log('saving userInfo: ',newUserInfo);
    state.sso = newSSO;
  }),

  fetchSSO: thunk(async (actions) => {
    console.log('fetchSSO() called');
    let resp = await fetch('/sso');
    if (resp.status === 200) {
      const info = await resp.json();
      console.log('GET /sso returned:', info);
      actions.setSSO(info);
    }
  }),
};

// Computed values
const sessionComputed = {
  // TODO: Should this be tied directly into Auth.currentSession to be more accurate?
  // The problem has been using async functions in JSX... this is sync which is easier.
  // isAuthenticated: computed((state) => !!state.idToken),

  // isAuthorized: computed((state) => (scopes, matchType) => {
  //   const scopeArray = scopes instanceof Array ? scopes : [scopes];
  //   const matcher = matchType || AuthMatch.SOME;

  //   switch (matcher) {
  //     case AuthMatch.ALL:
  //       return scopeArray.every((scope) => state.scopes.includes(scope));
  //     case AuthMatch.SOME:
  //       return scopeArray.some((scope) => state.scopes.includes(scope));
  //     case AuthMatch.NONE:
  //       return !scopeArray.some((scope) => state.scopes.includes(scope));
  //     default:
  //       return false;
  //   }
  // }),

  isInPopout: computed((state) => !!state.inPopout),

  // getSSO: computed( (state) => () => {
  //   // console.log('------------ Cookies ---------------------',Cookies.get());
  //   let ssoCookie = Cookies.get('RSOMyLogin');
  //   if (ssoCookie) {
  //     let ssoArray = ssoCookie.split(';');
  //     // console.log('Update session sso to: ',ssoArray[0]);
  //     state.sso = ssoArray[0];
  //   }
  //   // console.log('Current user session data: ',sessionModel);
  //   return state.sso;
  // }),

  getSSO: computed((state) => () => {
    return state.sso;
  }),
};

// Compile the state store object for export
const sessionModel = {
  ...defaultSessionState,
  ...sessionActions,
  ...sessionComputed,
};

export default sessionModel;
