import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { fetchAnalyzeCasesByStatus } from '@ge/feat-analyze/services';
import { useTaskAssetState } from '@ge/feat-reporting/data-hooks/use-task-asset-state';
import { useTaskNotes } from '@ge/feat-reporting/data-hooks/use-task-notes';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { CaseStatus, EntityType, QueryKey, TaskDateKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

dayjs.extend(isBetween);
dayjs.extend(timezone);

const TaskStatusDateKeyMap = {
  ['Open']: [TaskDateKey.CREATE_DATE, TaskDateKey.CREATE_DATE],
};

const getDateTz = (date, timezone) => {
  if (!date) {
    return null;
  }

  // revisit this but for now if we don't have a site timezone, fall back to input date
  if (!timezone) {
    return dayjs(date);
  }

  const dateTz = dayjs(date).tz(timezone);
  const offsetTz = dateTz.utcOffset();

  // dealing with utc so don't need to do anthing extra
  if (offsetTz === 0) {
    return dateTz;
  }

  // dates cast to timezone are still expressed internally in local time, so we enforce offset
  // to avoid crossing date boundaries relative to local date 00:00:00 (because javascript)
  const offsetLocal = dayjs(date).utcOffset();
  const diff = offsetLocal - offsetTz;

  return dateTz.add(diff, 'm');
};

const getUtcDateArray = (date) => {
  const isoDate = date?.toISOString();

  const match = isoDate?.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?Z$/);

  if (!match) {
    return null;
  }

  const [, year, month, day, hours, minutes, seconds, milliseconds] = match;

  return [
    Number(year),
    Number(month) - 1, // to month index
    Number(day),
    Number(hours),
    Number(minutes),
    Number(seconds),
    Number(milliseconds),
  ];
};

const isTaskInScopeFn = ({ endDate, startDate, status, timezone }) => {
  // can review this but it took some massaging to remove local datetime artifacts
  const endDateTz = getDateTz(endDate, timezone).endOf('d');
  const endDateTokens = getUtcDateArray(endDateTz);
  const endDateUtcTimestamp = Date.UTC(...endDateTokens);
  const startDateTz = getDateTz(startDate, timezone).startOf('d');
  const startDateTokens = getUtcDateArray(startDateTz);
  const startDateUtcTimestamp = Date.UTC(...startDateTokens);

  // used for simple optimization to avoid crunching the same conversions/comparisons over and over
  const compareCache = {};

  return (task) => {
    const { status: taskStatus } = task;

    if (!taskStatus === status) {
      return false;
    }

    const [compareDateKey] = TaskStatusDateKeyMap['Open'] ?? [];

    // how do we want to handle this?  "shoudln't" ever hit this scenario...
    if (!compareDateKey) {
      return false;
    }

    const compareDate = task[compareDateKey];

    if (!compareDate) {
      return false;
    }

    let compare = compareCache[compareDate];

    if (typeof compare === 'boolean') {
      return compare;
    }
    const compareDateTokens = getUtcDateArray(dayjs(compareDate));
    const compareDateUtcTimestamp = Date.UTC(...compareDateTokens);

    compare =
      status === CaseStatus.CLOSED
        ? startDateUtcTimestamp <= compareDateUtcTimestamp &&
          compareDateUtcTimestamp <= endDateUtcTimestamp
        : true;

    compareCache[compareDate] = compare;
    return compare;
  };
};

export const useCaseData = ({ config = Config.EXECUTE_ONCE, notesScope, queryKey, status }) => {
  // store
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  const { endDate, siteIds, startDate, timezone } = useReportScope();
  const query = status === CaseStatus.OPEN ? QueryKey.OPEN_CASES : QueryKey.CLOSED_CASES;
  const {
    data: _cases,
    error,
    isLoading: isTasksLoading,
  } = useQuery(
    [query, endDate, queryKey, siteIds, startDate, timezone],
    async () => {
      const { data } = await fetchAnalyzeCasesByStatus(siteIds, status);
      return data;
    },
    config,
  );

  let cases = [];
  cases = useMemo(() => {
    const isTaskInScope = isTaskInScopeFn({ endDate, startDate, status, timezone });

    return _cases?.filter(isTaskInScope) ?? [];
  }, [endDate, startDate, status, _cases, timezone]);

  const { data: assetState, isLoading: isAssetStateLoading } = useTaskAssetState({
    config,
    queryKey,
    cases,
  });
  const { data: taskNotes, isLoading: isNotesLoading } = useTaskNotes({
    config,
    queryKey,
    tasks: cases,
    entityType: EntityType.CASE,
  });
  // static data not dependent on sidebar config
  const staticData = useMemo(() => {
    return cases.reduce((mapped, _case) => {
      const {
        asset: taskAsset,
        createDate: createdDate,
        id,
        priority,
        site,
        lastFlagged,
        start: startedDate,
        status,
        statusDetails,
        source,
        closed,
        fixed,
        // workScope: type, // is this right?
      } = _case;

      const caseDetail = {
        description: { description: _case?.task?.description },
        id,
        priority,
        status,
        title: _case?.description,
        statusDetails,
        source,
      }; // being explicit about what we grab here but can loosen up as needed

      let asset;
      const _site = getSiteById(site?.id);

      if (_site?.name) {
        asset = { siteName: _site.name };
      }

      const _asset = getAssetById(taskAsset?.id) ?? {};

      if (_asset?.id) {
        const assetId = _asset.id;

        asset = asset ?? {};
        asset.id = assetId;
        asset.name = _asset.name;
      }

      let dates;

      if (lastFlagged || createdDate || closed || startedDate || fixed) {
        const { timezone } = site ?? {};

        // display dates in site timezone if provided
        dates = {
          lastFlagged: getDateTz(lastFlagged, timezone),
          createdDate: getDateTz(createdDate, timezone),
          startedDate: getDateTz(startedDate, timezone),
          closedDate: getDateTz(closed, timezone),
          fixedDate: getDateTz(fixed, timezone),
        };
      }
      const notesForTask = taskNotes?.[id];
      let notes;

      if (notesForTask?.length) {
        notes = notes ?? {};
        // default to latest note ignoring scope
        notes.latest = notesForTask[0].note;
        notes.notes = notesForTask;
      }

      mapped.push({ asset, dates, id, notes, case: caseDetail });

      return mapped;
    }, []);
  }, [assetState, getAssetById, getSiteById, taskNotes, cases]);

  // dynamic data driven by sidebar config
  const dynamicData = useMemo(() => {
    if (!notesScope || notesScope.length !== 1) {
      return staticData;
    }

    return staticData?.map((row) => {
      if (row?.notes) {
        const { notes } = row.notes;

        if (notes?.length) {
          return {
            ...row,
            notes: {
              ...row.notes,
              latest: notes?.find(({ scope }) => scope === notesScope[0])?.note,
            },
          };
        }
      }

      return row;
    });
  }, [staticData, notesScope]);

  const isLoading = isAssetStateLoading || isNotesLoading || isTasksLoading;

  return { data: dynamicData, error, isLoading };
};
