import React, { useCallback, useContext } from 'react';

import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import GlobalPeople from '../components/global-people';
import TenantPeople from '../components/tenant-people';
import { TenantContext } from '../context/tenant-context';

const AdminPeople = () => {
  const {
    tenantState: { selectedTenant },
  } = useContext(TenantContext);

  const { showPersonDetails } = useContext(EntityDetailsContext);

  const handlePersonSelect = useCallback(
    (_, person) => {
      showPersonDetails(person.userId);
    },
    [showPersonDetails],
  );

  return (
    <>
      {selectedTenant ? (
        <TenantPeople handlePersonSelect={handlePersonSelect} />
      ) : (
        <GlobalPeople handlePersonSelect={handlePersonSelect} />
      )}
    </>
  );
};

export default AdminPeople;
