import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme, css } from 'styled-components';

const clipPathCSS = css`
  clip-path: polygon(0 0%, 0% 0, 100% 0, 100% 0%, 100% 70%, 75% 100%, 100% 100%, 0% 100%, 0% 0%);
`;

const closedCSS = css`
  background-color: ${({ theme }) => theme.taskBadge.borderColorClosed};
  color: ${({ theme }) => theme.taskBadge.textColorClosed};

  &::before {
    background-color: ${({ theme }) => theme.taskBadge.backgroundColorClosed};
  }
`;

const StyledTaskBadge = styled.div`
  // creates container and border effect
  align-items: center;
  background-color: ${({ theme }) => theme.taskBadge.borderColorOpen};
  color: ${({ theme }) => theme.taskBadge.textColorOpen};
  display: flex;
  height: 18px;
  justify-content: center;
  position: relative;
  width: 22px;

  ${clipPathCSS}

  // creates background color effect
  &::before {
    background-color: ${({ theme }) => theme.taskBadge.backgroundColorOpen};
    content: '';
    display: block;
    height: 16px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 20px;
    z-index: 1;

    ${clipPathCSS};
  }

  ${({ closed }) => closed && closedCSS}
`;

const StyledLabel = styled.span`
  font-size: 9px;
  font-family: 'Museo Sans';
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10px;
  text-transform: uppercase;
  z-index: 2;
`;

const TaskBadgeComponent = ({ className, closed, label }) => (
  <StyledTaskBadge className={className} closed={closed}>
    <StyledLabel>{label}</StyledLabel>
  </StyledTaskBadge>
);

TaskBadgeComponent.propTypes = {
  className: PropTypes.string,
  closed: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

TaskBadgeComponent.defaultProps = {
  className: '',
  closed: false,
  label: '',
};

export const TaskBadge = withTheme(TaskBadgeComponent);
