import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Tooltip } from '@ge/components/tooltip';
import { Capability, EntityType } from '@ge/models/constants';
import { AssetType } from '@ge/models/entities/asset/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { elevations } from '@ge/tokens/elevations';

const StyledHeaderCurrentSite = styled.button`
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  align-items: center;
  padding: 0;

  span {
    margin-left: 3px;

    > * {
      + * {
        margin-left: 3px;
      }
    }
  }

  h5 {
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const getSiteIconFromAssetType = (assetType, entityType) => {
  switch (assetType) {
    case AssetType.WIND_TURBINE:
      return Icons.SITE;
    case AssetType.STORAGE_INVERTER:
      return Icons.STORAGE;
    case AssetType.SITE_CONTROLLER:
    case AssetType.SUBSTATION:
      if (entityType === EntityType.INVERTER) {
        return Icons.STORAGE;
      }
      return Icons.SITE;
    default:
      return Icons.SITE;
  }
};

const SiteIcon = styled(Icon).attrs((props) => ({
  size: 12,
  viewBox: '0 0 12 12',
  icon: getSiteIconFromAssetType(props?.assetType, props?.entityType),
  color: props.theme.entityDetails.tickets.iconColor,
}))`
  margin-right: 5px;
`;

const Chevron = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.entityDetails.tickets.iconColor,
}))`
  margin: 5px;
  transform: rotate(-90deg);
`;

const HeaderCurrentSite = ({ className, siteName, onClick, alerts, assetType, entityType }) => (
  <StyledHeaderCurrentSite className={className} onClick={onClick}>
    <SiteIcon assetType={assetType} entityType={entityType} />
    <Tooltip title={siteName} zIndex={elevations.P20} placement="top">
      <h5>{siteName}</h5>
    </Tooltip>
    <AuthRender
      capability={Capability.ALERTS}
      view
      description="Header current site alerts"
      siteLevel={false}
    >
      {alerts && <span>{alerts}</span>}
    </AuthRender>
    <Chevron />
  </StyledHeaderCurrentSite>
);

HeaderCurrentSite.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  siteName: PropTypes.string,
  alerts: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  assetType: PropTypes.string,
  entityType: PropTypes.string,
};

HeaderCurrentSite.defaultProps = {
  className: null,
  onClick: () => null,
  siteName: '',
  assetType: '',
};

export default HeaderCurrentSite;
