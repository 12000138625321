import { assignIfDefined } from '@ge/util/object-utils';

/**
 * Generic Entity base object that all Entities should extend.
 */
export class Entity {
  /**
   * @type {string} Entity ID
   */
  id;

  /**
   * @param {Partial<Entity>} initObj Object to use to initialize class.
   */
  constructor(initObj) {
    // Apply all properties from the initialization object.
    const { id } = initObj || {};
    assignIfDefined(this, 'id', id);
  }
}
