import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Select } from '@ge/components/select';
import { typography } from '@ge/tokens';

import RolesGrid from '../components/roles-grid';
import { AdminCollapsiblePanel, Description, Header } from '../entity-create-shared';
import { useAllRoles } from '../hooks/use-all-roles';
// import usePermissions from '../hooks/use-permissions';

const StyledTab = styled.div`
  margin-top: 15px;

  .permissions {
    > div {
      height: calc(100vh - 550px);
    }
  }
`;

const StyledSelect = styled(Select)`
  .select__control.select__control--is-disabled {
    opacity: 0.7;
  }
`;

const UtilityBar = styled.div`
  margin: 30px 5px 20px 0;
  position: relative;

  button {
    color: ${(props) => props.theme.admin.panel.body.textButton};
    font-size: 12px;
    font-weight: ${typography.weight.bold};
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
  }

  .role-desc {
    margin: 10px 0 0 15px;
    font-style: italic;
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.admin.panel.body.textButton,
}))`
  margin: 0 4px 2px 0;
`;

const CollapsePanels = styled.div`
  border-bottom: ${(props) => props.theme.admin.panel.body.border} 1px solid;
`;

const RolesTab = ({ index, onRemoveRole, onRoleChange, roleName, roles }) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });

  const { setValue, watch } = useFormContext();

  const watchSelectedRoles = watch('selectedRoles');

  const { isLoading: isAllRolesLoading, filteredPermission } = useAllRoles();
  const [selectedRole, setSelectedRole] = useState(roleName);
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    if (roleName) {
      let filtered = filteredPermission(roleName);
      if (filtered) {
        setPermissions(filtered[0]);
      }
    }
  }, [filteredPermission, roleName, selectedRole]);

  const handleRoleChange = useCallback(
    (value) => {
      setValue(`selectedRoles[${index}].name`, value);
      setSelectedRole(value);
      onRoleChange(value);
    },
    [index, onRoleChange, setValue],
  );

  if (isAllRolesLoading) return null;

  const roleOptions = [
    { value: '', label: t('assign_roles.select_role', 'Select a Role') },
    ...roles.map((role) => ({
      value: role.a11yKey,
      label: t(role.a11yKey, role.defaultLabel),
    })),
  ];

  const unselectedRoleOptions = [
    { value: '', label: t('assign_roles.select_role', 'Select a Role') },
    ...roles.reduce((arr, role) => {
      if (
        watchSelectedRoles.find((selectedRole) => selectedRole.name === role.a11yKey)?.name !==
        role.a11yKey
      ) {
        arr.push({
          value: role.a11yKey,
          label: t(role.a11yKey, role.defaultLabel),
        });
      }
      return arr;
    }, []),
  ];
  // prettier-ignore
  return (
    <StyledTab>
      {roles.map(role => role.a11yKey).includes(selectedRole) || selectedRole === '' ? (
        <>
        <Header>{t('assign_roles.select_role', 'Select a Role')}</Header>
        <Description>
          {t('assign_roles.roles_desc', 'This role will be assigned to this user.')}
        </Description>
        <UtilityBar>
        {
          // Show disabled selected role
          Boolean(selectedRole) && (
            <StyledSelect
              disabled={Boolean(selectedRole)}
              maxWidth={250}
              minWidth={250}
              options={roleOptions}
              value={roleOptions.filter((option) => option.value === selectedRole)}
              primary
            />
          )
        }
        {
          // Show role options less those selected in other tabsk
          Boolean(!selectedRole) && (
            <StyledSelect
              maxWidth={250}
              minWidth={250}
              onChange={(option) => handleRoleChange(option.value)}
              options={unselectedRoleOptions}
              value={roleOptions.filter((option) => option.value === selectedRole)}
              primary
            />
          )
        }
        {selectedRole && (
          <p className="role-desc">
            {roles.filter((option) => option.a11yKey === selectedRole)?.[0].defaultDesc}
          </p>
        )}
        {watchSelectedRoles.length !== 1 && (
          <button onClick={() => onRemoveRole(index)} type="Button">
            <StyledIcon icon={Icons.REMOVE} />
            {t('create.remove_role', 'Remove Role')}
          </button>
        )}
      </UtilityBar>
      </>
      ) : null}
      <Header>{t('assign_roles.product_permissions', 'Product Permissions')}</Header>
      {!selectedRole && (
        <Description>
          {t('assign_roles.please_select_role', 'Please select a role above.')}
        </Description>
      )}
      {selectedRole && (
        <>
          <Description className="roles">
            {t(
              'assign_roles.permissions_desc',
              'These are the products and features allowed in this role.',
            )}
          </Description>
          <div className="permissions">
            <ScrollingContainer>
              <CollapsePanels>
                {permissions?.segmentOfferings.map((segment, id) => (
                  <AdminCollapsiblePanel
                    headerContent={
                      <div>
                        <h4>{t(`segment_offerings.${segment.name}`, segment.name)}</h4>
                        <p>{segment.description}</p>
                      </div>
                    }
                    key={id}
                  >
                    <RolesGrid capabilityGroups={segment.capabilities} />
                  </AdminCollapsiblePanel>
                ))}
              </CollapsePanels>
            </ScrollingContainer>
          </div>
        </>
      )}
    </StyledTab>
  );
};

RolesTab.propTypes = {
  index: PropTypes.number.isRequired,
  onRoleChange: PropTypes.func,
  onRemoveRole: PropTypes.func,
  roleName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

RolesTab.defaultProps = {
  onRoleChange: () => null,
  onRemoveRole: () => null,
};

export default RolesTab;
