import React from 'react';
import styled from 'styled-components';

import { DataExplorerProvider } from '@ge/feat-analyze/context';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';

import { IssuesSidebar } from '../details/issues-sidebar';

import { ChartLayoutFactory } from './charts/chart-layout-factory';
import { DataExplorerFilters } from './data-explorer-filters';

const AssetDetailsContainer = styled.div`
  display: flex;
`;

const ChartExplorer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DataExplorer = () => {
  return (
    <DataExplorerProvider>
      <AssetDetailsContainer>
        <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteLevel={false} view>
          <IssuesSidebar />
        </AuthRender>
        <ChartExplorer>
          <DataExplorerFilters />
          <ChartLayoutFactory />
        </ChartExplorer>
      </AssetDetailsContainer>
    </DataExplorerProvider>
  );
};
