import * as PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from '@ge/components/input';
import { Radio } from '@ge/components/radio';

const StyledRadioGroup = styled.div`
  display: flex;
  ${({ userEditable }) => (userEditable ? 'justify-content: space-between' : '')}};

  div {
    margin: ${({ margin }) => margin};
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const RadioValue = styled.p`
  margin-left: 20px;
`;

export const RadioGroupMetaField = ({
  className,
  name,
  defaultValue,
  choices,
  margin,
  required = false,
}) => {
  const { control } = useFormContext();
  const isEditable = choices.some((choice) => choice.editable);
  const userEntryInputRef = useRef(null);
  const rules = {
    required: required,
    validate: (value) => {
      return required ? !!value.trim() : !required;
    },
  };

  const getChoiceValue = (choice) => {
    if (choice.editable) {
      return userEntryInputRef.current?.value ?? '';
    }
    return choice.value;
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      required={required}
      rules={rules}
      render={({ ref, value, onChange, onBlur }) => {
        const selectedValueUpperCase = value.toUpperCase();
        const handleChange = (e, choice) => {
          if (choice.editable) {
            userEntryInputRef.current?.focus();
          }
          onChange(e.target.value);
        };

        return (
          <StyledRadioGroup margin={margin} userEditable={isEditable}>
            {choices.map((choice) => (
              <RadioWrapper key={choice.id}>
                <Radio
                  ref={ref}
                  className={className}
                  name={name}
                  id={choice.id}
                  label={choice.label}
                  value={getChoiceValue(choice)}
                  onChange={(e) => handleChange(e, choice)}
                  onBlur={onBlur}
                  checked={selectedValueUpperCase === getChoiceValue(choice).toUpperCase()}
                  disabled={choice.disabled}
                />
                {isEditable && !choice.editable && <RadioValue>{choice.value}</RadioValue>}
                {isEditable && choice.editable && (
                  <Input
                    required={selectedValueUpperCase === getChoiceValue(choice).toUpperCase()}
                    onChange={(e) => onChange(e.target.value)}
                    type="text"
                    ref={userEntryInputRef}
                  />
                )}
              </RadioWrapper>
            ))}
          </StyledRadioGroup>
        );
      }}
    />
  );
};

const choiceValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

RadioGroupMetaField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  defaultValue: choiceValueType,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: choiceValueType,
      disabled: PropTypes.bool,
    }),
  ),
  margin: PropTypes.string,
  required: PropTypes.bool,
};

RadioGroupMetaField.defaultProps = {
  name: `choices`,
  defaultValue: null,
  choices: [],
  required: false,
};
