import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetKpiHeaderCategories, EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { AppScopes } from '@ge/shared/models/scopes';

import { AssetContext } from '../../context/assetContext';
import useAssetKpiBarData from '../../data-hooks/use-asset-kpi-bar-data';
import useKpiHeaderMenu from '../../data-hooks/use-kpi-header-menu';
import { DefaultAssetKpiVisibility } from '../../models/kpi-header-visibility-defaults';
import DashboardHeader from '../dashboard/dashboard-header';

const assetKpiCategories = [...AssetKpiHeaderCategories];

export const AssetOverviewWindHeader = () => {
  const { ready, t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });

  const { assetState } = useContext(AssetContext);

  const asset = assetState.assets && assetState.assets[0];

  const {
    kpiDateRange: { startDate, endDate },
  } = assetState;

  const { data: assetKpiData } = useAssetKpiBarData({
    assetId: assetState.selectedAssetId,
    categories: assetKpiCategories,
    iecCategory: KpiCategoryDefs.EVENT_RATES,
    startDate: startDate.entityTimezone,
    endDate: endDate.entityTimezone,
  });

  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_ASSET_OVERVIEW,
    defaultVisibility: DefaultAssetKpiVisibility,
    kpiCategories: assetKpiCategories,
  });

  if (!ready) return null;

  const isAssetSelected = !!assetState.comparisonAssets?.length;

  return (
    <DetailsDismissInert>
      <DashboardHeader
        title={t('asset_dashboard', 'Asset Dashboard')}
        type={EntityType.ASSET}
        showParent={assetState.comparisonAssets?.length === 1}
        entity={asset}
        data={assetKpiData}
        onMenuClose={handleKpiChanges}
        visibleKpiPrefs={visibleKpis}
        kpiCategories={assetKpiCategories}
        showStats={isAssetSelected}
      />
    </DetailsDismissInert>
  );
};
