import { useState, useCallback } from 'react';

import { useCreateEventNote, useBulkEventNotes } from '@ge/shared/data-hooks';

export const useAddNoteDialog = (
  currentEvent,
  selectedAssets,
  setSelectedAssets,
  setEventsChecked,
) => {
  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const [isAddNoteClicked, setIsAddNoteClicked] = useState(false);

  const {
    create: createEventNote,
    error: createNoteError,
    isError: isCreateNoteError,
  } = useCreateEventNote();

  const {
    create: createBulkEventNotes,
    data: bulkEventNotesData,
    error: createBulkNotesError,
    isError: isCreateBulkNotesError,
  } = useBulkEventNotes(setSelectedAssets, setEventsChecked);

  const handleAddNote = useCallback(
    (data) => {
      if (selectedAssets?.length > 1) {
        createBulkEventNotes({ ...data, caseDetails: selectedAssets });
      } else {
        createEventNote({ ...data, entity: currentEvent });
      }
      setIsAddNoteClicked(true);
      setAddNoteDialog(false);
    },
    [createEventNote, setIsAddNoteClicked, currentEvent, createBulkEventNotes, selectedAssets],
  );

  return {
    addNoteDialog,
    setAddNoteDialog,
    isAddNoteClicked,
    setIsAddNoteClicked,
    createEventNote,
    createNoteError,
    isCreateNoteError,
    handleAddNote,
    createBulkEventNotes,
    bulkEventNotesData,
    createBulkNotesError,
    isCreateBulkNotesError,
  };
};
