import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_API;

const buildSortOptions = (sort, direction, metrics) => {
  const options = {};
  if (sort && typeof sort === 'string') {
    options.params = {
      sort,
      direction,
      metrics,
    };
  }
  return options;
};

export const fetchAnomalies = () => request.get('/dav/anomalies', { baseURL: BASE_URL });

export const fetchAnomaliesByCaseId = (caseId) =>
  request.get('/dav/anomalies', { baseURL: BASE_URL, params: { caseId } });

export const fetchAnomalyById = (anomalyId) =>
  request.get(`/dav/anomalies/${anomalyId}`, { baseURL: BASE_URL });

export const fetchAnomaliesWithMetrics = (sortMetric, sortDirection) =>
  request.get('/dav/anomalies', {
    baseURL: BASE_URL,
    ...buildSortOptions(sortMetric, sortDirection, true),
  });

/**
 * Dynamically load the anomalies mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/anomalies/anomaly.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
