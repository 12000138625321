import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import uniq from 'ramda/src/uniq';
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { MiniLoader } from '@ge/components/loader';
import { SearchInput } from '@ge/feat-manage/components/planning/panels/search-input';
import { Capability, PermissionScope, TaskTemplateModes } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { elevations } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';
import { getWorkStandards } from '@ge/web-client/src/app/services/work-standards';

import { Config } from '../../data-hooks';
import { SelectMetaField } from '../meta-fields/select-meta-field';
import { TextMetaField } from '../meta-fields/text-meta-field';

import { FormRow } from './task-template-shared';

const TitleSelectAssigneeRow = styled(FormRow)`
  > div:first-of-type {
    flex-grow: 1;
    margin-right: 0;
  }
`;

const StyledTextMetaField = styled(TextMetaField)`
  display: none; // <- Hidden in replace for searchInput but still needed the TextMetaField component present so that the dropdown options may be selected
`;

const StyledSelectMetaField = styled(SelectMetaField)`
  z-index: ${elevations.P2};

  & .select__menu-list {
    display: none; /* <- Original dropdown menu hidden so that the custom dropdown can replace */
  }

  & .select__control .select__indicators .select__dropdown-indicator svg {
    fill: ${(props) => props.theme.select.chevronPrimaryColor};
    transform: ${(props) => props.selectMetaFieldClicked && 'rotate(180deg)'};
    transition: 300ms linear;
  }
`;

const StyledDropdownMenu = styled.div`
  font-size: 12px;
  line-height: 15px;
  background: ${(props) => props.theme.select.optionBackground};
  z-index: ${elevations.P1};
  position: relative;
  top: -19px;
  border-radius: 5px;
  border: 1px solid ${globalColors.slate12};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  & .dropdown-searchbar {
    width: 95%;
    margin: 0 auto;
    padding: 10px 0 8px;
    border-bottom: 1px solid ${globalColors.slate12};
  }

  & .dropdown-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;

    ::-webkit-scrollbar {
      width: 5px;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.scrollbar.trackBackground};
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scrollbar.thumbBackground};
    }

    ::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
    }
  }

  & .options-border-top {
    outline: 1px solid #2c343c;
    width: 95%;
    position: relative;
    left: 13px;
  }

  & .options-divider {
    min-width: 515px;
    outline: 1px solid #2c343c;
    background-color: #2f3236;
    margin: 0px 0 3px;
    position: relative;
    right: 1px;
  }

  & .dropdown-populate-container {
    height: 28px;
    box-shadow: 0 -1px 4px 0 rgb(0 0 0 / 40%);
  }
`;

const StyledSearchInput = styled(SearchInput)`
  border: none;
  background-color: ${globalColors.slate1};
`;

const StyledOption = styled.option`
  padding: 7px 25px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.select.optionColor};
  font-weight: 100;

  &:hover {
    cursor: default;
    color: ${(props) => props.theme.select.optionColor};
    width: inherit;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 7px 10px;

  > div {
    width: 10px;
    height: 10px;
  }

  > span {
    color: ${globalColors.white};
    font-weight: 100;
  }

  svg {
    width: 8px;
  }
`;

const DataLoaderContainer = styled.div`
  margin: 0 auto;
  height: 25vh;
  position: relative;
`;

export const EventsSelectWithFilter = ({
  metadata,
  causalEvents,
  activeEvents,
  allEventsOne,
  assetId,
  templateMode,
  taskTitle,
  isAllEventLoading,
}) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const { setValue, register } = useFormContext();

  // GLOBAL STATE: Keep track of selected alarm or selected asset for form
  const { selectedAlarmOrEvent } = useStoreState((store) => store.tasks);
  const { selectedAssetToEscalate } = useStoreState((store) => store.tasks);

  // GLOBAL ACTION: Keep track of whether or not we pre-popluate work standards
  const { setWorkStandards } = useStoreActions((store) => store.tasks);

  const { taskWorkStandardsFlag } = useStoreState((state) => state.tenant.featureFlags);

  const { setIsAllEventLoading, setSelectMetaField } = useContext(EntityDetailsContext);

  // Causal events

  const handleGetCausalEventOptions = useCallback(() => {
    let options = [];
    causalEvents?.assetState.forEach((event) => {
      options.push({
        value:
          event.event.desc !== ''
            ? `${event.event.code} — ${event.event.desc}`
            : `${event.event.code} — ${event.event.name}`,
        label:
          event.event.desc !== ''
            ? `${event.event.code} — ${event.event.desc}`
            : `${event.event.code} — ${event.event.name}`,
      });
    });
    return options;
  }, [causalEvents]);

  // Active events

  const handleGetActiveEventOptions = useCallback(() => {
    let options = [];
    activeEvents?.events.forEach((event) => {
      options.push({
        value: `${event.code} — ${event.description}`,
        label: `${event.code} — ${event.description}`,
      });
    });
    return options;
  }, [activeEvents]);

  // All events

  const handleGetAllEventOptions = useCallback(() => {
    let options = [];
    allEventsOne?.forEach((event) => {
      options.push({
        value: `${event?.event?.code} — ${event?.event?.name}`,
        label: `${event?.event?.code} — ${event?.event?.name}`,
      });
    });

    return options;
  }, [allEventsOne]);

  // Conditionally render -> defaultSelectedEvent based on selectedAlarmOrEvent or selectedAssetToEscalate

  const defaultSelectedEvent = useMemo(
    () =>
      selectedAlarmOrEvent?.description
        ? {
            value: `${selectedAlarmOrEvent?.code} — ${selectedAlarmOrEvent?.description}`,
            label: `${selectedAlarmOrEvent?.code} — ${selectedAlarmOrEvent?.description}`,
          }
        : selectedAssetToEscalate?.id && causalEvents?.assetState[0]
        ? {
            value:
              causalEvents?.assetState[0]?.event?.desc !== ''
                ? `${causalEvents?.assetState[0]?.event?.code} — ${causalEvents?.assetState[0]?.event?.desc}`
                : `${causalEvents?.assetState[0]?.event?.code} — ${causalEvents?.assetState[0]?.event?.name}`,
            label:
              causalEvents?.assetState[0]?.event?.desc !== ''
                ? `${causalEvents?.assetState[0]?.event?.code} — ${causalEvents?.assetState[0]?.event?.desc}`
                : `${causalEvents?.assetState[0]?.event?.code} — ${causalEvents?.assetState[0]?.event?.name}`,
          }
        : {},
    [
      selectedAlarmOrEvent?.description,
      selectedAlarmOrEvent?.code,
      selectedAssetToEscalate?.id,
      causalEvents?.assetState,
    ],
  );

  // Causal event, active events, & all events combined

  const allOptions = useMemo(
    () => [
      defaultSelectedEvent,
      ...handleGetCausalEventOptions(),
      ...handleGetActiveEventOptions(),
      ...handleGetAllEventOptions(),
    ],
    [
      defaultSelectedEvent,
      handleGetCausalEventOptions,
      handleGetActiveEventOptions,
      handleGetAllEventOptions,
    ],
  );

  // LOCAL STATE
  const [selectedOption, setSelectedOption] = useState(
    templateMode === TaskTemplateModes.CREATE ? allOptions[0].value : taskTitle,
  );
  const [selectedOptionFaultCode, setSelectedOptionFaultCode] = useState(null);
  const [selectMetaFieldClicked, setSelectMetaFieldClicked] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [isTitleChanged, setIsTitleChanged] = useState(false);

  const [isChecked, setIsChecked] = useState(CheckedState.CHECKED);

  // Get's the length of the causalEvents array when being filtered

  const uniqOptions = useMemo(
    () =>
      uniq([
        ...handleGetCausalEventOptions(),
        ...handleGetActiveEventOptions(),
        ...handleGetAllEventOptions(),
      ]),
    [handleGetCausalEventOptions, handleGetActiveEventOptions, handleGetAllEventOptions],
  );

  // Handle's selecting options from the dropdown
  const handleSelectOption = useCallback(
    (selectedOption) => {
      const index = allOptions.findIndex((option) => option.value === selectedOption.value);
      if (templateMode === TaskTemplateModes.EDIT) {
        setIsTitleChanged(true);
      }
      setSelectedOption(allOptions[index].value);
      setSelectMetaFieldClicked(false);
      setFilterValue('');
    },
    [allOptions, templateMode],
  );

  // Fetch work standards data
  const { isLoading: workStandardsLoading, data: workStandardsData } = useQuery(
    ['workStandardsData', selectedOptionFaultCode, assetId],

    async () => getWorkStandards(assetId, selectedOptionFaultCode),

    {
      enabled:
        taskWorkStandardsFlag &&
        isChecked === 'checked' &&
        templateMode === TaskTemplateModes.CREATE &&
        selectedOptionFaultCode !== null
          ? true
          : false,
      ...Config.EXECUTE_ONCE,
    },
  );

  useEffect(() => {
    if (workStandardsLoading) {
      setWorkStandards({ isLoading: true });
    } else if (workStandardsData !== undefined && !workStandardsLoading) {
      if (
        workStandardsData?.message?.includes('GE Service Error') ||
        workStandardsData.length === 0
      ) {
        setWorkStandards(null);
      } else {
        setWorkStandards(workStandardsData);
      }
    } else if (!workStandardsLoading && workStandardsData === undefined) {
      setWorkStandards(null);
    }
  }, [setWorkStandards, workStandardsLoading, workStandardsData]);

  const getCheckState = () => {
    if (isChecked === CheckedState.UNCHECKED || isChecked === CheckedState.INDETERMINATE) {
      return setIsChecked(CheckedState.CHECKED);
    }
    return setIsChecked(CheckedState.UNCHECKED);
  };

  useEffect(() => {
    if (templateMode === TaskTemplateModes.CREATE) {
      setValue('title', selectedOption, { shouldValidate: true, shouldDirty: true });
    }
    if (templateMode === TaskTemplateModes.EDIT) {
      if (isTitleChanged) {
        setValue('title', selectedOption, { shouldValidate: true, shouldDirty: true });
      } else {
        setValue('title', selectedOption, { shouldValidate: true, shouldDirty: false });
      }
    }
    if (selectedOption !== undefined)
      setSelectedOptionFaultCode(selectedOption.substring(0, selectedOption.indexOf('—')));
  }, [selectedOption, setValue, setSelectedOptionFaultCode, templateMode, isTitleChanged]);

  useEffect(() => {
    if (selectedOption === undefined && templateMode === TaskTemplateModes.CREATE) {
      setSelectedOption(allOptions[0].value);
    }
    if (selectedOption !== undefined)
      setSelectedOptionFaultCode(selectedOption.substring(0, selectedOption.indexOf('—')));
  }, [allOptions, selectedOption, setValue, templateMode]);

  useEffect(() => {
    if (templateMode === TaskTemplateModes.EDIT) {
      setIsAllEventLoading(isAllEventLoading);
    }
  }, [isAllEventLoading, setIsAllEventLoading, templateMode]);

  return (
    <>
      <div
        className="select-field"
        onClick={() => {
          setSelectMetaFieldClicked(!selectMetaFieldClicked);
          if (templateMode === TaskTemplateModes.EDIT) {
            setSelectMetaField(!selectMetaFieldClicked);
            setIsAllEventLoading(isAllEventLoading);
          }
        }}
      >
        <TitleSelectAssigneeRow>
          {selectedOption !== undefined && (
            <StyledSelectMetaField
              {...register('title')}
              onChange={handleSelectOption}
              label={t('form.title', 'Title')}
              name="title"
              value={selectedOption}
              defaultValue={selectedOption}
              metadata={metadata.title.create}
              options={allOptions}
              maxWidth={templateMode === TaskTemplateModes.CREATE ? 517 : 530}
              selectMetaFieldClicked={selectMetaFieldClicked}
              searchable={false}
              placeholder={templateMode === TaskTemplateModes.EDIT ? selectedOption : ''}
            />
          )}
        </TitleSelectAssigneeRow>
      </div>
      {selectMetaFieldClicked && (
        <StyledDropdownMenu>
          <div className="dropdown-searchbar">
            <StyledSearchInput
              placeholder={t('search_task_title', 'Search Task Title')}
              onChange={(e) => setFilterValue(e.currentTarget.value)}
            />
            {/* This metafield is hidden, but used for the custom dropdown menu */}
            <StyledTextMetaField
              maxLength={metadata.title.maxLength}
              metadata={metadata.title.create}
              name="title"
              tabIndex={1}
            />{' '}
          </div>
          {templateMode === TaskTemplateModes.EDIT && isAllEventLoading ? (
            <DataLoaderContainer>
              <MiniLoader color={globalColors.slate2} />
            </DataLoaderContainer>
          ) : (
            <div className="dropdown-list-container">
              <div className="dropdown-list">
                {/* Map over all event options */}
                <div className="all-events-options-section">
                  {uniqOptions
                    .filter(
                      (x) =>
                        filterValue === '' ||
                        x.value.toLowerCase().includes(filterValue.toLowerCase().trim()),
                    )
                    .sort((a, b) => parseFloat(a.value) - parseFloat(b.value))
                    .map((option, index) => {
                      return (
                        <div key={index}>
                          {index === 1 ? (
                            <div className="options-divider"></div>
                          ) : (
                            <div className="options-border-top"></div>
                          )}
                          <StyledOption onClick={() => handleSelectOption(option)}>
                            {option.value.length > 70
                              ? option.value.substring(0, 70) + '...'
                              : option.value}
                          </StyledOption>
                        </div>
                      );
                    })}
                </div>
              </div>
              {templateMode === TaskTemplateModes.CREATE && (
                <div
                  className="dropdown-populate-container"
                  // eslint-disable-next-line react/no-unknown-property
                  capabilities={[
                    {
                      capability: Capability.FIELD_TASKS,
                      scopes: [PermissionScope.CREATE, PermissionScope.EDIT],
                    },
                  ]}
                >
                  {taskWorkStandardsFlag && (
                    <StyledCheckbox
                      checkState={isChecked}
                      label={t('form.pre_populate_work_standards', 'Pre-populate work standards')}
                      onChange={() => getCheckState()}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </StyledDropdownMenu>
      )}
    </>
  );
};

EventsSelectWithFilter.propTypes = {
  metadata: PropTypes.object.isRequired,
  activeEvents: PropTypes.object,
  allEventsOne: PropTypes.array,
  causalEvents: PropTypes.object,
  assetId: PropTypes.string,
  templateMode: PropTypes.string,
  taskTitle: PropTypes.string,
  isAllEventLoading: PropTypes.bool,
};
