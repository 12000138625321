import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;
const CMNU_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

export const fetchViewSelector = () => request.get('/cmn/view-selector', { baseURL: BASE_URL });

export const fetchPrefs = () => request.get('/cmn/users/prefs', { baseURL: CMNU_BASE_URL });

export const savePrefs = (prefsObj) =>
  request.post('/cmn/users/prefs', prefsObj, { baseURL: CMNU_BASE_URL });

export const fetchSitesAssetsCount = async (siteIds = {}) => {
  return request.post(
    '/cmn/asset-count',
    { siteIds },
    {
      baseURL: BASE_URL,
    },
  );
};

export const fetchFeatureFlags = async () => {
  return request.get('/cmn/feature-flags', {
    baseURL: BASE_URL,
  });
};

/**
 * Dynamically load the ticket mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/common/common.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
