import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TemplateFieldType, TaskTemplateModes } from '@ge/models/constants';
import {
  DateTimeMetaField,
  SelectMetaField,
  TextMetaField,
} from '@ge/shared/components/meta-fields';

export const FormField = ({ defaultValue, mode, name, namespace, ...metadata }) => {
  const { ready, t } = useTranslation([namespace.name], { useSuspense: false });

  if (!ready) {
    return null;
  }

  const translations = {
    label: t(`${namespace.key}.form.${name}.label`, name),
    placeholderLabel: t(`${namespace.key}.form.${name}.placeholder_label`, ''),
  };

  switch (metadata?.type) {
    case TemplateFieldType.DATE_TIME:
      return (
        <DateTimeMetaField
          defaultSelection={metadata.defaultSelection}
          defaultValue={defaultValue}
          label={translations.label}
          metadata={metadata}
          name={name}
          validators={metadata.validators}
        />
      );

    case TemplateFieldType.SELECT: {
      return (
        <SelectMetaField
          defaultValue={defaultValue ?? metadata.defaultSelection ?? metadata.values?.[0]?.value}
          label={translations.label}
          metadata={metadata}
          name={name}
          options={metadata.values}
          primary={metadata.emphasis === 1}
          searchable={false}
        />
      );
    }

    case TemplateFieldType.TEXT:
      return (
        <TextMetaField
          defaultValue={defaultValue}
          label={translations.label}
          maxLength={metadata.maxLength}
          metadata={metadata}
          name={name}
          placeholder={translations.placeholderLabel}
          tabIndex={1}
        />
      );

    default:
      return null;
  }
};

FormField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hidden: PropTypes.bool,
  maxLength: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(TaskTemplateModes)),
  name: PropTypes.string.isRequired,
  namespace: PropTypes.object,
};

FormField.defaultProps = {
  defaultValue: undefined,
  hidden: false,
  maxLength: undefined,
  mode: TaskTemplateModes.VIEW,
};
