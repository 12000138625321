import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Card, CardStatus } from '@ge/components/card';
import { Icons } from '@ge/components/icon';
import { KpiCategoryDefs } from '@ge/models/constants';

import { DashboardGaugeCard } from '../dashboard/dashboard-gauge-card';

const StyledCard = styled(Card)`
  /**
    * NOTE: ticket #(DE147538)- Remove chevron
    * !adding this lines for MVP0 to remove the random spaces above and below on hover
  */
  border: 2px solid transparent;
  &:hover {
    cursor: pointer;
    /**
      * NOTE: ticket #(DE147538)- Remove chevron
      * !adding this lines for MVP0 to add the border color on hover
    */
    border: 2px solid ${({ theme }) => theme.analyze.regionSiteCard.siteSelectHoverBorderColor};
    box-shadow: ${({ theme }) => theme.analyze.regionSiteCard.siteSelectedHoverShadow};
  }
  &.danger {
    box-shadow: ${(props) => props.theme.analyze.regionSiteCard.dangerBoxShadow};
    border: ${(props) => props.theme.analyze.regionSiteCard.dangerBorder};
    border-width: 2px;
    &:hover {
      border: 2px solid ${(props) => props.theme.dangerColor};
      box-sizing: border-box;
    }
  }
  .title h3 {
    font-size: 14px;
  }
  .content {
    overflow: visible;
  }
`;

const StatusContainer = styled.div`
  border-top: solid 1px ${(props) => props.theme.analyze.regionSiteCard.borderColor};
  text-align: left;
`;

// can pull this out of here if it will be used in more places
const CardButton = styled.button.attrs({
  type: 'button',
})`
  /**
    * NOTE: ticket #(DE147538)- Remove chevron
    * !adding this line for MVP0 to remove the extra padding between the cards
  */
  padding: 0px;
  /**
   * NOTE: ticket #(DE147538)- Remove chevron
   * !Commented this lines for MVP0 to remove the border color on hover inside the button
  */
  // border: 2px solid transparent;
  // box-sizing: border-box;
  // color: inherit;
  // width: 100%;
  // &:hover {
  //   border: 2px solid ${({ theme }) => theme.analyze.regionSiteCard.siteSelectHoverBorderColor};
  //   border-radius: 1px 1px 3px 3px;
  //   box-shadow: ${({ theme }) => theme.analyze.regionSiteCard.siteSelectedHoverShadow};
  // }
`;

const StyledRegionSiteCard = ({ ...props }) => {
  const {
    theme,
    title,
    danger,
    gauge,
    kpi,
    assetCount,
    offline,
    id,
    onSelect,
    /**
     * NOTE: ticket #(DE147538)- Remove chevron
     * !Commented this lines for MVP0 to remove onTitleClick function to route to Site Dashboard
     */
    // onTitleClick,
  } = props;

  // TODO: unhardcode categories and values for gauge card in here

  const dialGauge1 = {
    ...gauge[KpiCategoryDefs.PBA],
    aggregateValue: gauge[KpiCategoryDefs.PBA]?.value,
    type: KpiCategoryDefs.PBA,
    units: '%',
  };

  const dialGauge2 = {
    ...gauge[KpiCategoryDefs.TBA],
    aggregateValue: gauge[KpiCategoryDefs.TBA]?.value,
    type: KpiCategoryDefs.TBA,
    units: '%',
  };

  const kpi1 = {
    ...kpi[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT],
    aggregateValue: kpi[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]?.[0]?.value,
    type: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    units: kpi[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]?.[0]?.unit,
  };
  const kpi2 = {
    ...kpi[KpiCategoryDefs.PRODUCTION_ACTUAL],
    aggregateValue: kpi[KpiCategoryDefs.PRODUCTION_ACTUAL]?.[0]?.value,
    type: KpiCategoryDefs.PRODUCTION_ACTUAL,
    units: kpi[KpiCategoryDefs.PRODUCTION_ACTUAL]?.[0]?.unit,
  };
  return (
    <CardButton onClick={() => onSelect(id)}>
      <StyledCard
        title={title}
        icon={Icons.SITE}
        danger={danger}
        theme={theme}
        /**
         *  NOTE: ticket #(DE147538)- Remove chevron
         * !Commented this lines for MVP0 to remove the chevron and the onTitleClick function to route to Site Dashboard
         */
        // titleChevron
        //onTitleClick={onTitleClick}
      >
        {/* <CardButton onClick={() => onSelect(id)}> */}
        <DashboardGaugeCard
          dialGauge1={dialGauge1}
          dialGauge2={dialGauge2}
          kpi1={kpi1}
          kpi2={kpi2}
        />
        {assetCount && offline && (
          <StatusContainer>
            <CardStatus theme={theme} speed={assetCount} offline={offline} />
          </StatusContainer>
        )}
        {/* Commented for MVP0 */}
        {/* </CardButton> */}
      </StyledCard>
    </CardButton>
  );
};

StyledRegionSiteCard.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  danger: PropTypes.bool,
  gauge: PropTypes.instanceOf(Object),
  kpi: PropTypes.instanceOf(Object),
  assetCount: PropTypes.number,
  offline: PropTypes.number,
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  onTitleClick: PropTypes.func,
};

StyledRegionSiteCard.defaultProps = {
  danger: false,
  gauge: {},
  kpi: {},
  assetCount: null,
  offline: null,
  onSelect: () => {},
  onTitleClick: () => {},
};

export const RegionSiteCard = withTheme(StyledRegionSiteCard);
