import { action, computed, thunk } from 'easy-peasy';

import merge from '@ge/util/deep-merge';

import { fetchCustomers } from '../../shared/services/customer';

// Define initial state
const defaultCustomerState = {
  customers: {},
};

// Actions
const customerActions = {
  /**
   * Reset state to defaults
   */
  resetCustomers: action((state) => {
    state = Object.assign(state, defaultCustomerState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.customers = defaultCustomerState.customers;
  }),

  /**
   * Set the customers state
   */
  updateCustomers: action((state, payload) => {
    payload.forEach((customer) => {
      const existingCustomer = state.customers[customer.id];
      state.customers[customer.id] = !existingCustomer
        ? customer
        : merge(existingCustomer, customer);
    });
  }),

  /**
   * Retrieve customers from API and update state.`
   */
  fetchCustomers: thunk(async (actions, payload, { fail }) => {
    try {
      const { customers } = await fetchCustomers(payload);
      actions.updateCustomers(Object.values(customers));
    } catch (err) {
      fail(err);
    }
  }),
};

// Computed values
const customerComputed = {
  /**
   * Return the customer object by its ID.
   */
  getCustomerById: computed((state) => (customerId) => state.customers[customerId]),
};

// Compile the view store object for export
const customerModel = {
  ...defaultCustomerState,
  ...customerActions,
  ...customerComputed,
};

export default customerModel;
