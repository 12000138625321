import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey, WorkerQueryParams } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

import { getServiceGroupIds } from '../util/worker-util';

const getWorkerData = async (
  serviceGroupIds,
  types,
  sortKey,
  orderDirection,
  pageIdx,
  pageSize,
) => {
  const params = { serviceGroupIds, types, sortKey, orderDirection, pageIdx, pageSize };
  return request.post('/cmn/workers', params, { baseURL: BASE_URL });
};

export const useSiteWorkers = (selectedSite) => {
  const types = WorkerQueryParams.types;
  const pageIdx = WorkerQueryParams.pageIdx;
  const pageSize = WorkerQueryParams.pageSize;
  const sortKey = WorkerQueryParams.sortKey;
  const orderDirection = WorkerQueryParams.orderDirection;
  //Gives list of service groups
  const { sortedServiceGroups } = useStoreState((state) => state.sites);

  const selectedView = {
    sites: [{ id: selectedSite }],
  };
  const serviceGroupIds = getServiceGroupIds(sortedServiceGroups, selectedView).toString();

  const {
    isFetching,
    isError,
    error,
    data: workerData,
  } = useQuery(
    [QueryKey.WORKER, { serviceGroupIds, types, sortKey, orderDirection, pageIdx, pageSize }],
    () => getWorkerData(serviceGroupIds, types, sortKey, orderDirection, pageIdx, pageSize),
  );

  return useMemo(
    () => ({ data: workerData, isFetching, isError, error }),
    [workerData, error, isError, isFetching],
  );
};
