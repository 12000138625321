import { action, computed, thunk } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';

// Define initial state
const defaultTechnicianState = {
  technicians: {},
  crews: {},
};

// Actions
const technicianActions = {
  /**
   * Reset state to defaults
   */
  // eslint-disable-next-line no-unused-vars
  resetTechnicians: action((state) => {
    state = Object.assign(state, defaultTechnicianState);
  }),

  updateTechnicians: action((state, payload) => {
    state.technicians = { ...state.technicians, ...payload };
  }),

  updateCrews: action((state, payload) => {
    state.crews = { ...state.crews, ...payload };
  }),

  createTechnicianCrew: thunk(async (actions, { crewName, techId }) => {
    // TODO - API call to create technician crew
    const crew = { name: crewName, id: uuidv4() };

    actions.createCrew(crew);
    if (techId) actions.addTechnicianToCrew({ techId, crewId: crew.id });
  }),

  updateCrew: thunk(async (actions, { crewId, crew }) => {
    // TODO - API call to update crew

    actions.modifyCrew({ crewId, crew });
  }),

  deleteTechnicianCrew: thunk(async (actions, { crewId }, { getStoreActions }) => {
    // TODO - API call to delete a crew / update tasks
    getStoreActions().siteManager.removeCrewFromTasks({ crewId });
    actions.deleteCrew({ crewId });
  }),

  updateTechnicianCrew: thunk(async (actions, { techId, crewId }) => {
    // TODO - API call to add technician to crew -> create the new crew here if necessary
    if (crewId) {
      actions.addTechnicianToCrew({ techId, crewId });
    } else {
      actions.removeTechnicianFromCrew({ techId });
    }
  }),

  createCrew: action((state, payload) => {
    state.crews = { ...state.crews, [payload.id]: payload };
  }),

  addTechnicianToCrew: action((state, { techId, crewId }) => {
    state.technicians[techId].crew = { id: crewId };
  }),

  modifyCrew: action((state, { crewId, crew }) => {
    state.crews[crewId] = { ...state.crews[crewId], ...crew };
  }),

  deleteCrew: action((state, { crewId }) => {
    const affectedTechIds = Object.values(state.technicians)
      .filter((t) => t.crew && t.crew.id === crewId)
      .map((t) => t.id);

    affectedTechIds.forEach((techId) => {
      delete state.technicians[techId].crew;
    });
    delete state.crews[crewId];
  }),

  removeTechnicianFromCrew: action((state, { techId }) => {
    delete state.technicians[techId].crew;
  }),
};

// Computed values
const technicianComputed = {
  getTechnicianById: computed((state) => (technicianId) => state.technicians[technicianId]),

  getTechniciansInCrew: computed((state) => (crewId) =>
    Object.values(state.technicians).filter((tech) => tech.crew.id === crewId),
  ),

  getTechniciansSorted: computed((state) => (prop = 'last') =>
    Object.values(state.technicians).sort((a, b) => a[prop].localeCompare(b[prop])),
  ),
};

// Compile the view store object for export
const technicianModel = {
  ...defaultTechnicianState,
  ...technicianActions,
  ...technicianComputed,
};

export default technicianModel;
