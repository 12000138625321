import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { Icon, Icons } from '@ge/components/icon';
import { Menu, placements } from '@ge/components/menu';
import { DashboardChartToggle } from '@ge/feat-analyze/components/dashboard/dashboard-chart-toggle';
import { useAssetContext, useDataExplorerContext } from '@ge/feat-analyze/context';
import { DataExplorerLayouts, AxisOptions } from '@ge/models/constants';

import DataExplorerChartLegends, { Legend } from './data-explorer-chart-legends';

const Layouts = DataExplorerLayouts;

const TOGGLE_TYPES = [
  { id: ChartType.SCATTER, type: ChartType.SCATTER },
  { id: ChartType.SPLINE, type: ChartType.SPLINE },
];

const ChartLayout = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const Container = styled.div`
  ${({ layout }) =>
    layout === Layouts.DETAIL
      ? css`
          padding-bottom: 8px;
          margin-bottom: 8px;
          border-bottom: 1px solid
            ${({ theme }) => theme.sensorReadingsChart.borderColor[ChartLayout.SECONDARY]};
        `
      : ''}
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0px 5px;
  > label {
    color: ${(props) => props.theme.dataExplorer.charts.container.labelColor};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

const ChartHeaderLeft = styled.div`
  display: flex;
  flex: 1;
`;

const ChartHeaderRight = styled.div`
  display: flex;
`;

const SignalBtn = styled.button`
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.dataExplorer.charts.container.selectColor};
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  svg {
    vertical-align: middle;
    margin-right: 8px;
    margin-top: 2px;
  }
  ${({ layout }) => {
    if (layout === Layouts.DETAIL) {
      return css`
        margin-right: 16px;
      `;
    }
    return css``;
  }}
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: start;
`;

const PromoteIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dataExplorer.charts.container.promoteIconColor,
  icon: Icons.EXPAND,
}))`
  vertical-align: initial;
  transform: ${(props) => (props.expended ? 'rotate(180deg)' : '')};
`;

const PromoteButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0;
  height: 13px;
`;

const DragButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0px 8px;
  height: 13px;
  display: flex;
  align-items: center;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const DragIcon = styled(Icon).attrs((props) => ({
  size: 9,
  color: props.theme.dataExplorer.charts.container.promoteIconColor,
  icon: Icons.LIST,
}))`
  vertical-align: initial;
  transform: rotate(90deg);
`;

const EditIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dataExplorer.charts.editIconColor,
  icon: Icons.PENCIL,
}))`
  vertical-align: initial;
`;

const RemoveButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0;
  margin-left: 10px;
`;

const RemoveIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dataExplorer.charts.container.promoteIconColor,
  icon: Icons.CLOSE,
}))`
  vertical-align: initial;
`;

const SignalMenu = styled.div``;

const SignalMenuButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.dataExplorer.filters.headerButton};
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.dataExplorer.filters.headerButton,
  rotate: props.open ? -180 : 0,
}))`
  margin-left: 5px;
`;

const HoverLegendMenu = ({ children }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const [anchorElement, setAnchorElement] = useState(null);

  if (!ready) return null;

  return (
    <SignalMenu>
      <SignalMenuButton onClick={(e) => setAnchorElement(!anchorElement ? e.currentTarget : null)}>
        {t('signals_button', 'Signals')}
        <ArrowIcon open={!!anchorElement} />
      </SignalMenuButton>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
        placement={placements.BOTTOM_END}
      >
        {children}
      </Menu>
    </SignalMenu>
  );
};

export const DataExplorerChartHeader = ({
  chartDef,
  series: _series,
  onPromote,
  onToggle,
  onAddEditSignals,
  onRemove,
  onLegendClick,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { queryParam } = useAssetContext();

  const { draggable, draggableContext } = useDataExplorerContext();

  const series = useMemo(() => _series?.filter((v) => v.showInLegend) ?? [], [_series]);

  if (!ready) return null;

  const { layout } = queryParam ?? {};
  const { type, xAxis } = chartDef;
  const { handleDragButton } = draggableContext?.handlers ?? {};

  return (
    <Container layout={layout}>
      <ChartHeader>
        <ChartHeaderLeft>
          {onPromote && (
            <PromoteButton onClick={onPromote}>
              <PromoteIcon expended={chartDef.expended} />
            </PromoteButton>
          )}
          {draggable && (
            <DragButton onMouseDown={handleDragButton}>
              <DragIcon />
            </DragButton>
          )}
          {layout === Layouts.DETAIL && (
            <>
              <Legend className="x-axis" layout={layout}>
                <span>
                  <strong>X: </strong>
                  {xAxis?.title ?? xAxis?.name ?? '-'}
                </span>
              </Legend>
              {!chartDef.expended && (
                <DataExplorerChartLegends
                  layout={layout}
                  xAxis={xAxis}
                  series={series}
                  onLegendClick={onLegendClick}
                />
              )}
            </>
          )}
        </ChartHeaderLeft>
        <ChartHeaderRight>
          {layout !== Layouts.DETAIL && (
            <HoverLegendMenu>
              <DataExplorerChartLegends
                layout={layout}
                xAxis={xAxis}
                series={series}
                onLegendClick={onLegendClick}
              />
            </HoverLegendMenu>
          )}
          <ToggleContainer>
            <SignalBtn onClick={onAddEditSignals} layout={layout}>
              <EditIcon />
              {layout === Layouts.DETAIL && t('signal_button', 'Add/Edit Signals')}
            </SignalBtn>
            {xAxis?.id === AxisOptions.TIME && (
              <DashboardChartToggle
                currentType={TOGGLE_TYPES.filter((toggle) => toggle.id === type)[0]}
                onToggle={onToggle}
                types={TOGGLE_TYPES}
              />
            )}
            {onRemove && (
              <RemoveButton onClick={onRemove}>
                <RemoveIcon />
              </RemoveButton>
            )}
          </ToggleContainer>
        </ChartHeaderRight>
      </ChartHeader>
      {layout === Layouts.DETAIL && chartDef.expended && (
        <DataExplorerChartLegends
          layout={layout}
          xAxis={xAxis}
          series={series}
          onLegendClick={onLegendClick}
        />
      )}
    </Container>
  );
};

HoverLegendMenu.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
};

DataExplorerChartHeader.propTypes = {
  chartDef: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    xAxis: PropTypes.object,
    yAxis: PropTypes.array,
    expended: PropTypes.bool,
  }),
  series: PropTypes.array,
  onPromote: PropTypes.func,
  onToggle: PropTypes.func,
  onAddEditSignals: PropTypes.func,
  onRemove: PropTypes.func,
  onLegendClick: PropTypes.func,
};

DataExplorerChartHeader.defaultProps = {
  chartDef: {},
  series: [],
  onCreated: () => {},
  onSelect: () => {},
  onRetry: () => {},
  onPromote: () => {},
  onToggle: () => {},
  onAddEditSignals: () => {},
  onRemove: () => {},
  onLegendClick: () => {},
};

DataExplorerChartHeader.displayName = 'DataExplorerChartHeader';

export default DataExplorerChartHeader;
