import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { Textarea } from '@ge/components/textarea/textarea';
import { ToggleButton } from '@ge/components/toggle-button';
import { EntityType, FormMode, SpecialInstructionStatus } from '@ge/models/constants';
import { DateTimeMetaField } from '@ge/shared/components/meta-fields';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

const Instructiondialogsegment = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  margin-top: 20px;
  padding-bottom: 10px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const InstructionFormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  div:first-of-type {
    margin-right: 0px;
  }
  &:first-of-type {
    align-items: center;
  }
  &:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }
  input[type='text'] {
    width: 80%;
  }
  textarea {
    width: 100%;
  }
  &.date-section {
    span {
      margin-left: 5px;
      margin-right: 25px;
    }
  }
`;

const InstructionFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const StyledLabel = styled.label`
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 20px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 5px;
`;

const metadata = {
  defaultSelection: 'currentDate',
  required: true,
  readOnly: false,
  validators: [
    {
      earliestDate: 'currentDate',
    },
  ],
};

export const SpecialInstructionDialog = ({
  onClose,
  onConfirm,
  entity,
  entityType,
  mode,
  data,
  isOpen,
  siteType,
}) => {
  const { t } = useTranslation(['entity-details']);

  const [isToggleOn, setIsToggleOn] = useState(true);

  const { getSiteById } = useStoreState((state) => state.sites);
  const [site, setSite] = useState(null);

  const getSiteName = useMemo(() => {
    if (site) {
      return site?.name;
    } else if (entity?.name) {
      return entity?.name;
    } else {
      return entity?.site?.name;
    }
  }, [entity, site]);

  const getAssetName = useMemo(() => {
    if (siteType === EntityType.TURBINE || siteType === EntityType.INVERTER) {
      return entity?.name;
    } else if (entity?.asset?.id) {
      return entity?.asset?.name;
    }
  }, [entity, siteType]);

  const entityDetails = {
    siteName: getSiteName,
    assetName: getAssetName,
    assetType: (entity?.asset?.id && entity?.asset?.type) || entity?.type,
    entityDescriptionIcon: Icons.STORAGE,
    siteType: siteType,
  };

  useEffect(() => {
    setIsToggleOn(
      mode === FormMode.EDIT && data.status === SpecialInstructionStatus.INACTIVE ? false : true,
    );
  }, [data, mode]);

  useEffect(() => {
    if (entityType === EntityType.ASSET && entity?.site?.id) {
      setSite(getSiteById(entity.site.id));
    }
  }, [setSite, getSiteById, entity, entityType]);

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
  });

  const { control, handleSubmit, register } = methods;
  useWatch({
    control,
    name: 'selectedDate',
  });

  const onSubmit = useCallback(
    (values) => {
      onConfirm({
        ...values,
        title: 'Special Instructions',
        status: isToggleOn ? SpecialInstructionStatus.ACTIVE : SpecialInstructionStatus.INACTIVE,
        validDateBy: values.selectedDate,
      });
    },
    [onConfirm, isToggleOn],
  );

  const getFooter = useMemo(() => {
    return (
      <InstructionFooterWrapper>
        <FooterButtons>
          <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleSubmit(onSubmit)}>
            {t('general:save', 'Save')}
          </Button>
        </FooterButtons>
      </InstructionFooterWrapper>
    );
  }, [onClose, t, handleSubmit, onSubmit]);

  return (
    <FormProvider {...methods}>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        icon={Icons.WATCHLIST}
        onConfirm={onConfirm}
        header={
          mode === FormMode.EDIT
            ? t('notes.edit_special_instruction', 'Edit Special Instruction')
            : t('notes.new_special_instruction', 'New Special Instruction')
        }
        footer={getFooter}
      >
        <Instructiondialogsegment>
          <EntityDialogHeader entityDetails={entityDetails} />
        </Instructiondialogsegment>

        <Instructiondialogsegment>
          <InstructionFormRow className="date-section">
            <div>
              <StyledLabel>{t('notes.valid_until', 'Valid Until')}</StyledLabel>
              <DateTimeMetaField
                defaultSelection={metadata.defaultSelection}
                defaultValue={
                  mode === FormMode.EDIT && data.validDateBy
                    ? new Date(data.validDateBy)
                    : new Date(dayjs().add(1, 'hour'))
                }
                filterDate={(date) => dayjs().startOf('date').toDate() <= date}
                name="selectedDate"
                metadata={metadata}
                validators={metadata.validators}
              />
            </div>
            <div>
              <ToggleButton
                isToggleOn={isToggleOn}
                setIsToggleOn={setIsToggleOn}
                label={isToggleOn ? t('notes.active', 'Active') : t('notes.inactive', 'Inactive')}
              />
            </div>
          </InstructionFormRow>
          <InstructionFormRow>
            <div>
              <StyledLabel>{t('notes.title', 'Title')}</StyledLabel>
            </div>
            <div>
              <Text>{t('notes.special_instruction', 'Special Instruction')}</Text>
            </div>
          </InstructionFormRow>
          <InstructionFormRow>
            <Textarea
              id="task-description-textarea"
              label={t('notes.special_instruction', 'Special Instruction')}
              placeholder={t('notes.instruction_placeholder', 'Add a instruction')}
              name="note"
              defaultValue={mode === FormMode.EDIT ? data.note : ''}
              ref={register}
            />
          </InstructionFormRow>
          {/* <InstructionFormRow>
            <Button primary>{t('notes.attach_file', 'Attach File')}</Button>
          </InstructionFormRow> */}
        </Instructiondialogsegment>
      </Dialog>
    </FormProvider>
  );
};

SpecialInstructionDialog.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  mode: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  siteType: PropTypes.string,
};

SpecialInstructionDialog.defaultValues = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
};
