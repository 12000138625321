import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Filter } from '@ge/components/filter';
import { Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useSiteWorkers } from '@ge/feat-manage/data-hooks/use-site-workers';
import { useWorkers } from '@ge/feat-manage/data-hooks/use-workers';
import { killEventPropagation } from '@ge/shared/util/general';
import { elevations, globalColors } from '@ge/tokens';

export const ViewSelectorAssetsComponent = ({
  className,
  onClose,
  workerState,
  setWorkerState,
  isOpen,
  siteID,
  modalType,
}) => {
  const [filterText, setFilterText] = useState('');
  const { data: workerData } = useSiteWorkers(siteID);
  const { data: workersData } = useWorkers();
  const allWorkers = workerData?.users;
  let filterWorkers = workerState?.map((x) => x.username);
  const workerList = allWorkers?.filter((item) => {
    return filterWorkers?.indexOf(item.username) === -1;
  });

  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const { t } = useTranslation(['manage.planning'], { useSuspense: false });
  const parentRef = useRef();
  const rowVirtualizer = useVirtual({
    size: modalType === 'newTask' ? workersData?.length : allWorkers?.length,
    parentRef,
    estimateSize: React.useCallback(() => 25, []),
    overscan: 10,
  });

  const searchMatch = useCallback(
    (worker) => {
      let completeName = worker?.firstName?.toLowerCase() + ' ' + worker?.lastName?.toLowerCase();
      if (!filterText) {
        return true;
      }
      if (
        worker?.firstName?.toLowerCase().includes(filterText) ||
        worker?.lastName?.toLowerCase().includes(filterText) ||
        completeName?.includes(filterText)
      ) {
        return true;
      }
      return false;
    },
    [filterText],
  );
  const getCheckState = useCallback(
    (username) =>
      selectedWorkers?.includes(username) ? CheckedState.CHECKED : CheckedState.UNCHECKED,
    [selectedWorkers],
  );

  const toggleCheckbox = useCallback(
    (e, username) => {
      killEventPropagation(e);
      return selectedWorkers.includes(username)
        ? setSelectedWorkers((prevselectedWorkers) =>
            prevselectedWorkers.filter((el) => el !== username),
          )
        : setSelectedWorkers((prevselectedWorkers) => [...prevselectedWorkers, username]);
    },
    [selectedWorkers, setSelectedWorkers],
  );
  const selectWorkers = useCallback(() => {
    const selectedWorkerList =
      modalType === 'newTask'
        ? workersData?.filter((item) => selectedWorkers.indexOf(item.username) !== -1)
        : allWorkers?.filter((item) => selectedWorkers.indexOf(item.username) !== -1);
    setWorkerState([...workerState, selectedWorkerList].flat());
    setSelectedWorkers(selectedWorkerList);
    onClose();
  }, [allWorkers, modalType, onClose, selectedWorkers, setWorkerState, workerState, workersData]);

  const onClear = useCallback(() => {
    setSelectedWorkers('');
  }, []);

  const listOfWorkers = useCallback(
    ({ index, measureRef }) => {
      const worker = modalType === 'newTask' ? workersData[index] : workerList[index];
      if (!worker) return;

      if (searchMatch(worker)) {
        return (
          <div>
            <WorkerItem key={worker.username} ref={measureRef}>
              <StyledCheckbox
                checkState={getCheckState(worker.username)}
                onChange={(e) => toggleCheckbox(e, worker.username)}
              />
              <div className="details">
                <div className="tech">
                  <div className="nickname-pill">
                    <div className="nickname">
                      {worker?.initials || worker?.firstName?.substring(0, 5)}
                    </div>
                  </div>
                  <div className="nametitle">
                    <div className="name">{`${worker?.firstName?.substring(
                      0,
                      5,
                    )} ${worker?.lastName?.substring(0, 5)}`}</div>
                  </div>
                </div>
              </div>
            </WorkerItem>
          </div>
        );
      }
    },
    [getCheckState, modalType, searchMatch, toggleCheckbox, workerList, workersData],
  );
  // Build the footer to pass into the Dialog component.
  const getFooter = useMemo(() => {
    return (
      <ResultsButtons>
        <CancelButton onMouseDown={onClear} className="view-btn">
          {t('clear', 'Clear')}
        </CancelButton>
        <span>
          {selectedWorkers?.length} {t('selected', 'Selected')}
        </span>
        <Button
          primary
          disabled={selectedWorkers?.length === 0}
          onClick={selectWorkers}
          className="view-btn"
        >
          {t('apply', 'Apply')}
        </Button>
      </ResultsButtons>
    );
  }, [onClear, t, selectedWorkers, selectWorkers]);

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      icon={Icons.PERSON}
      footer={getFooter}
      header={t('add_worker', 'Add worker')}
      contentWidth
      padContent={false}
    >
      <div className={className} onClick={(e) => killEventPropagation(e)}>
        <div className="panel">
          <div className="header">
            <Filter
              text={filterText}
              onChange={(event) => setFilterText(event.target.value.toLowerCase())}
              placeholder={t('search_worker', 'Search Worker')}
              hideResultsOnBlur={false}
            />
          </div>
          <>
            <Results>
              <ScrollingContainer ref={parentRef}>
                <StyledUl paddingTop={paddingTop} paddingBottom={paddingBottom}>
                  {items?.map((item) => listOfWorkers(item))}
                </StyledUl>
              </ScrollingContainer>
            </Results>
          </>
        </div>
      </div>
    </Dialog>
  );
};

ViewSelectorAssetsComponent.propTypes = {
  className: PropTypes.string,
  modalType: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  workerState: PropTypes.instanceOf(Object),
  setWorkerState: PropTypes.func,
  siteID: PropTypes.number,
};

ViewSelectorAssetsComponent.defaultProps = {
  isOpen: false,
  className: null,
  modalType: null,
  onClose: () => null,
  workerState: null,
};

const ListItem = styled.li`
  color: ${globalColors.grey7};
  font-weight: 300;
  font-family: 'Museo Sans';
  font-size: 10px;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  overflow-y: hidden;
`;

const WorkerItem = styled(ListItem)`
  padding: 2px 0px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${globalColors.slate12};
  :hover {
    background-color: ${globalColors.teal4};
    border-bottom: 1px solid ${globalColors.teal7};
  }
  .details {
    margin: 2px;
    width: 160px;
    color: ${(props) => props.theme.dialog.headerTextColor};
    .tech {
      display: flex;
      padding: 5px;
      width: 155px;
    }
    .nametitle {
      display: inline-block;
      margin-left: 10px;
      width: 100px;
      }
    .name {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      }
    .nickname-pill {
      height: 15px;
      width: 30px;
      border-radius: 12px;
      background-color: #b6e1e9;
    }
    .nickname {
        height: 10px;
        color: #1c252d;
        font-size: 8px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        line-height: 16px;
        text-align: center;
      }
`;

const StyledCheckbox = styled(Checkbox)`
  input + div {
    width: 12px;
    height: 12px;
  }
`;

const CancelButton = styled.button`
  color: ${globalColors.white};
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  margin-left: 10px;
`;

const Results = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  height: 450px;
  width: 100%;
`;

const ResultsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${globalColors.slate2};
  font-size: 11px;
  font-weight: 700;
`;

const StyledUl = styled.ul`
  position: relative;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }
  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const StyledViewSelectorAssets = styled(ViewSelectorAssetsComponent)`
  display: flex;
  .panel {
    width: 400px;
    height: 530px;
    max-height: calc(75vh - 171px);
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    color: ${globalColors.grey1};
    z-index: ${elevations.P2};
    transition: false;
    > .header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px 6px 6px 11px;
      > .filter {
        position: relative;
        input {
          color: ${globalColors.white};
          box-sizing: border-box;
          border: 1px solid ${(props) => props.theme.input.borderNavbarInput};
          border-radius: 2px;
          background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
          width: calc(100% - 8px);
          padding: 4px;
          caret-color: ${globalColors.teal3};
          ::placeholder {
            font-size: 14px;
            font-style: italic;
          }
          :focus {
            outline: none;
          }
        }
        .search-icon {
          position: absolute;
          top: 5px;
          right: 15px;
        }
      }
    }
  }
`;

export const WorkerDialog = withTheme(StyledViewSelectorAssets);
