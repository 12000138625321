/**
 * This function returns a bool for determining
 * if the time elapsed is more than the time alloted.
 *
 * @param {string} start - Date string "2021-01-01" - "YYYY-MM-DD"
 * @param {string} end - Date string "2021-06-01" - "YYYY-MM-DD"
 * @param {number} days - Max number of days between dates (optional | 90 day def.)
 * @return {boolean} Number of days difference is more than the start/end dates
 */

const maxDayDiff = (start, end, days = 90) => {
  // 1 Day in milliseconds
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const MAX_DAY_DIFF = ONE_DAY * days;

  const dateStart = new Date(start);
  const dateEnd = new Date(end);

  const timeDiff = dateStart.getTime() - dateEnd.getTime();

  return Math.abs(timeDiff) < MAX_DAY_DIFF;
};

module.exports = {
  maxDayDiff,
};
