import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';
import { sortBySelected } from '@ge/feat-analyze/util/data-explorer-chart';

import DataExplorerSignalListHeader from './data-explorer-signal-list-header';
import DataExplorerSignalListItems from './data-explorer-signal-list-items';

const SignalListContainer = styled.div`
  min-width: 240px;
  min-height: 400px;
  background: ${(props) => props.theme.dataExplorer.dialog.backgroundColor};
  .auth-tabs {
    display: flex;
    .auth-tab {
      flex: 1;
      min-width: inherit;
      padding: 7px 20px;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  background-color: ${(props) => props.theme.dataExplorer.dialog.backgroundColor};
  width: 100%;
`;

export const DataExplorerSignalList = ({
  axisIndex,
  selected,
  disabled,
  value,
  checkboxes,
  onSelectSignal,
  signalData,
  axisType,
  totalAxisCount,
  maxAxisCount,
  onAddAxis,
  onRemoveAxis,
}) => {
  const [filterText, setFilterText] = useState('');

  const { t, ready } = useTranslation(['analyze.data-explorer', 'general'], { useSuspense: false });

  const filteredData = useMemo(() => {
    if (filterText) {
      const val = String(filterText).toLowerCase();
      return signalData
        .filter((signal) => String(signal.name).toLowerCase().includes(val))
        .sort(sortBySelected(value));
    }
    return signalData.sort(sortBySelected(value));
  }, [filterText, signalData, value]);

  const selectedTabIndex = useMemo(
    () =>
      selected.length > 0 && selected.filter((v) => v.categoryId === 2).length === selected.length
        ? 1
        : 0,
    [selected],
  );

  const tabs = useMemo(
    () => [
      {
        label: t('analyze.data-explorer.scada', 'Scada'),
        signals:
          filteredData?.filter((signal) => signal.id === 'TIME' || signal.categoryId === 0) ?? [],
        enabled: true,
      },
      {
        label: t('analyze.data-explorer.cms', 'CMS'),
        signals:
          filteredData?.filter((signal) => signal.id === 'TIME' || signal.categoryId === 2) ?? [],
        enabled: !!signalData?.filter((signal) => signal.categoryId === 2 && signal.id !== 'TIME')
          ?.length,
      },
    ],
    [filteredData, signalData, t],
  );

  if (!ready) return null;

  return (
    <SignalListContainer>
      <DataExplorerSignalListHeader
        axisIndex={axisIndex}
        axisType={axisType}
        totalAxisCount={totalAxisCount}
        maxAxisCount={maxAxisCount}
        onAddAxis={onAddAxis}
        onRemoveAxis={onRemoveAxis}
      />
      <StyledTabs defaultTab={tabs[selectedTabIndex].label}>
        {tabs.map(
          ({ label, signals, enabled }) =>
            enabled && (
              <Tab key={label} label={label}>
                <DataExplorerSignalListItems
                  axisIndex={axisIndex}
                  axisType={axisType}
                  signals={signals}
                  selected={selected}
                  disabled={disabled}
                  checkboxes={checkboxes}
                  filterText={filterText}
                  onChangeFilter={setFilterText}
                  onSelectSignal={onSelectSignal}
                />
              </Tab>
            ),
        )}
      </StyledTabs>
    </SignalListContainer>
  );
};

DataExplorerSignalList.propTypes = {
  selected: PropTypes.array,
  disabled: PropTypes.array,
  value: PropTypes.array,
  checkboxes: PropTypes.bool,
  signalData: PropTypes.instanceOf(Array),
  axisType: PropTypes.string,
  axisIndex: PropTypes.number,
  totalAxisCount: PropTypes.number,
  maxAxisCount: PropTypes.number,
  onSelectSignal: PropTypes.func,
  onAddAxis: PropTypes.func,
  onRemoveAxis: PropTypes.func,
};

DataExplorerSignalList.defaultProps = {
  selected: [],
  disabled: [],
  value: [],
  checkboxes: false,
  signalData: null,
  axisType: '',
  axisIndex: 0,
  totalAxisCount: 1,
  maxAxisCount: 1,
  onSelectSignal: () => {},
  onAddAxis: () => {},
  onRemoveAxis: () => {},
};

export default DataExplorerSignalList;
