import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';

import { calendarViewScroll } from '@ge/feat-manage/util';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { useLocalStorage } from '@ge/hooks/use-local-storage';
import { CalendarRanges, CalendarFilters, ManageDefs, WorkersFilters } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';

import { useServiceGroups } from '../util/worker-util';

import ExecuteOverviewContext from './planning-context';
import { getWeeksAndDaysInMonth, getDaysInWeek, getWeeksInQuarter, getHoursInDay } from './utils';

const LOCAL_STATE_PREFIX = 'planning-calendar-filter-state';
const LOCAL_STATE_PREFIX_BACKLOG = 'backlog-filter-state';
const LOCAL_STATE_PREFIX_WORKERS = 'workers-filter-state';

export const PlanningContext = ExecuteOverviewContext;

dayjs.extend(weekOfYear);

export const DAY_PADDING = 7; // padding to offset the cards into the day slightly

const FEAT_PREFS_SUB_KEY = ManageDefs.OVERVIEW;

export const TabOptions = {
  TECHS: 'techs',
  BACKLOG: 'backlog',
  RECOMMENDED: 'recommended',
};

export const DragItemTypes = {
  CARD: 'card',
  BACKLOG: 'backlog',
  TECH: 'tech',
  LANE: 'lane',
  CREW: 'crew',
};

//TODO: add week/quarter/year
export const DateFormats = {
  day: 'MMMM DD YYYY',
  month: 'MMMM YYYY',
  year: 'YYYY',
  week_start: 'DD MMMM',
  week_end: 'DD MMMM YYYY',
};

export const FilterValues = {
  TASK: 'task',
  SITE_ASSET: 'site_asset',
  CREW: 'crew',
};

export const FilterOptions = {
  L1: [
    { value: FilterValues.TASK, labelValue: 'Task Title', label: 'general:task_title' },
    { value: FilterValues.SITE_ASSET, labelValue: 'Site/Asset', label: 'general:site_assets' },
    { value: FilterValues.CREW, labelValue: 'general:crew', label: 'general:crew' },
  ],
  [FilterValues.TASK]: [
    { value: null, labelValue: 'None', label: 'general:none' },
    { value: FilterValues.SITE_ASSET, labelValue: 'Site/Asset', label: 'general:site_assets' },
    { value: FilterValues.CREW, labelValue: 'Crew', label: 'general:crew' },
  ],
  [FilterValues.SITE_ASSET]: [
    { value: null, labelValue: 'None', label: 'general:none' },
    { value: FilterValues.TASK, labelValue: 'Task Title', label: 'general:task_title' },
    { value: FilterValues.CREW, labelValue: 'Crew', label: 'general:crew' },
  ],
  [FilterValues.CREW]: [
    { value: null, labelValue: 'None', label: 'general:none' },
    { value: FilterValues.TASK, labelValue: 'Task Title', label: 'general:task_title' },
    { value: FilterValues.SITE_ASSET, labelValue: 'Site/Asset', label: 'general:site_assets' },
  ],
};

export const DefaultFilters = {
  [CalendarFilters.SOURCE]: { type: 'checkboxes', value: [] },
  [CalendarFilters.WORKSCOPE]: { type: 'checkboxes', value: [] },
  [CalendarFilters.PRIORITY]: { type: 'checkboxes', value: [] },
  [CalendarFilters.STATUS]: { type: 'checkboxes', value: [] },
  [CalendarFilters.FLAG]: { type: 'checkboxes', value: [] },
};

export const DefaultFiltersWorkers = {
  [WorkersFilters.TITLE]: { type: 'checkboxes', value: [] },
};

export const PlanningProvider = ({ children }) => {
  const { savePrefs, featPrefs: _featPrefs } = useFeaturePrefs(AppScopes.MANAGE_OVERVIEW);
  const featPrefs = _featPrefs?.[FEAT_PREFS_SUB_KEY];
  const scrollRef = useRef();
  let cleanFeatPrefs = featPrefs;
  const [queryParam, setQueryParam] = useQueryParams({
    date: withDefault(NumberParam, dayjs().valueOf()),
    range: withDefault(StringParam, CalendarRanges.MONTH),
    tab: StringParam,
  });

  const setTab = useCallback(
    (tab) => {
      setPlanning((prevState) => ({
        ...prevState,
        tab,
      }));
      setQueryParam({ tab });
    },
    [setQueryParam],
  );

  useEffect(() => {
    setDateWithRange(queryParam.range, queryParam.date);
  }, [queryParam.range, queryParam.date]);

  // set range url param
  const setRange = (range) => {
    if (range) {
      let groupBy =
        cleanFeatPrefs && cleanFeatPrefs[1]?.cols ? cleanFeatPrefs[1]?.cols : FilterValues.CREW;
      let secondGroupBy =
        cleanFeatPrefs && cleanFeatPrefs[2]?.cols ? cleanFeatPrefs[2]?.cols : null;
      let urlRange = { range: range };
      let cols = [
        {
          id: ManageDefs.CALENDAR,
          cols: range,
        },
        {
          id: ManageDefs.GROUP_BY,
          cols: groupBy || FilterValues.CREW,
        },
        {
          id: ManageDefs.SECOND_GROUP_BY,
          cols: secondGroupBy || null,
        },
      ];
      setQueryParam(urlRange);
      savePrefs(cols, FEAT_PREFS_SUB_KEY);
      cleanFeatPrefs = cols;
    } else {
      let urlRange = { range: CalendarRanges.DAY };
      let cols = [
        {
          id: ManageDefs.CALENDAR,
          cols: CalendarRanges.DAY,
        },
        {
          id: ManageDefs.GROUP_BY,
          cols: FilterValues.CREW,
        },
        {
          id: ManageDefs.SECOND_GROUP_BY,
          cols: null,
        },
      ];
      setQueryParam(urlRange);
      savePrefs(cols, FEAT_PREFS_SUB_KEY);
      cleanFeatPrefs = cols;
    }
  };

  //if isoweek range is there then 1 week(7days) to be added as per dayjs supporting ranges
  // +1 of range type to state date
  const nextDate = (d, range) => {
    const date = dayjs(d)
      .add(1, range === 'isoweek' ? 'week' : range)
      .valueOf();
    setQueryParam({ range, date });
  };

  //if isoweek range is there then 1 week to be subtracted as per dayjs supporting ranges
  // -1 of range type from state date
  const prevDate = (d, range) => {
    const date = dayjs(d)
      .subtract(1, range === 'isoweek' ? 'week' : range)
      .valueOf();
    setQueryParam({ range, date });
  };

  const setDatePickerDate = (range, d) => {
    const date = dayjs(d).valueOf();
    setDateWithRange(range, date);
    setQueryParam({ range, date });
  };

  // set date and range oject in state
  const setDateWithRange = (range, date) => {
    setPlanning((prevState) => ({
      ...prevState,
      date: date || prevState.date,
      range: {
        type: range,
        start: dayjs(date).startOf(range),
        end: dayjs(date).endOf(range),
        daysInMonth: dayjs(date).daysInMonth(),
        daysInQuarter: Math.round(
          dayjs(date).endOf(range).diff(dayjs(date).startOf(range), 'days', true),
        ),
      },
      timelineSchema: getWeeksAndDaysInMonth({
        start: dayjs(date).startOf(range),
        daysInMonth: dayjs(date).daysInMonth(),
      }),
      timelineSchemaQuarter: getWeeksInQuarter({
        start: dayjs(date).startOf(range),
        daysInQuarter: Math.round(
          dayjs(date).endOf(range).diff(dayjs(date).startOf(range), 'days', true),
        ),
      }),
      timelineSchemaWeek: getDaysInWeek({
        start: dayjs(date).startOf(range),
      }),
      timelineSchemaDay: getHoursInDay({
        start: dayjs(date).startOf(range),
      }),
    }));
  };

  const handleDragStart = (item = null, type = DragItemTypes.CARD, i = null, laneIndex = null) => {
    setPlanning((prevState) => ({
      ...prevState,
      dragItemIndex: i,
      dragItem: item,
      dragItemLaneIndex: laneIndex,
      dragItemType: type,
      dragItemBacklogType: type,
      // isStackPopUpOpen: false,
    }));
    calendarViewScroll({ active: true, ref: scrollRef });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (i = null, id = null) => {
    setPlanning((prevState) => ({
      ...prevState,
      dragOverIndex: i,
      dragOverId: id,
    }));
  };

  const handleDragLeave = () => {
    setPlanning((prevState) => ({
      ...prevState,
      dragOverIndex: null,
      dragOverId: null,
    }));
  };

  const handleDragEnd = () => {
    setPlanning((prevState) => ({
      ...prevState,
      dragOverIndex: null,
      dragOverId: null,
      dragItemIndex: null,
      dragItem: null,
      dragItemLaneIndex: null,
      dragItemType: null,
    }));
    calendarViewScroll({ active: false, ref: scrollRef });
  };

  const toggleBundleTasks = () => {
    setPlanning((prevState) => ({
      ...prevState,
      bundleTasks: !prevState.bundleTasks,
    }));
  };

  const setHoverItemId = (id = null) => {
    setPlanning((prevState) => ({
      ...prevState,
      hoverItemId: id,
    }));
  };

  const handleEditStart = (editItem, editLane) => {
    setPlanning((prevState) => ({
      ...prevState,
      editItem,
      editLane,
    }));
  };

  const handleEditEnd = () => {
    setPlanning((prevState) => ({
      ...prevState,
      editItem: null,
      editLane: null,
    }));
  };

  const handleSetGroupFilter = (L1, L2 = L1, filters) => {
    let groupBy = L1.value;
    let secondGroupBy = L2.value;
    let cols = [
      {
        id: ManageDefs.CALENDAR,
        cols:
          cleanFeatPrefs && cleanFeatPrefs[0]?.cols ? cleanFeatPrefs[0]?.cols : CalendarRanges.DAY,
      },
      {
        id: ManageDefs.GROUP_BY,
        cols: groupBy || FilterValues.CREW,
      },
      {
        id: ManageDefs.SECOND_GROUP_BY,
        cols: secondGroupBy || null,
      },
    ];
    savePrefs(cols, FEAT_PREFS_SUB_KEY);
    cleanFeatPrefs = cols;
    setPlanningFilterState((prevState) => ({
      ...prevState,
      groupFilter: { L1, L2, filters },
    }));
  };

  const handleSetBacklogFilter = (filters, assets) => {
    setPlanningBacklogFilterState((prevState) => ({
      ...prevState,
      backlogFilter: { filters, assets },
    }));
  };

  const handleSetBacklogTaskFilter = (filters, assets) => {
    setPlanningBacklogFilterState((prevState) => ({
      ...prevState,
      backlogTaskFilter: { filters, assets },
    }));
  };

  const handleSetWorkersFilter = (filters) => {
    setPlanningWorkersFilterState((prevState) => ({
      ...prevState,
      workersFilter: { filters },
    }));
  };

  const setDragItemLaneIndex = (i) => {
    setPlanning((prevState) => ({
      ...prevState,
      dragItemLaneIndex: i,
    }));
  };

  const setTempNewLane = (tempNewLane) => {
    setPlanning((prevState) => ({
      ...prevState,
      tempNewLane,
    }));
  };

  const setCrewEdited = (crewEdited) => {
    setPlanning((prevState) => ({
      ...prevState,
      crewEdited,
    }));
  };

  // WIP
  // const setStackPopUpOpen = (isStackPopUpOpen) => {
  //   setPlanning((prevState) => ({
  //     ...prevState,
  //     isStackPopUpOpen,
  //   }));
  // };

  const setIsBundleToggle = (isBundleToggle) => {
    setPlanning((prevState) => ({
      ...prevState,
      isBundleToggle,
    }));
  };

  const defaultState = {
    scrollRef,
    hour: 120,
    quarterHour: 29,
    dayWidth: 34,
    dayWidthQuarter: 15,
    weekWidth: 200,
    sidebarWidth: 300,
    date: queryParam.date,
    range: {
      type: queryParam.range,
      start: dayjs(queryParam.date).startOf(queryParam.range),
      end: dayjs(queryParam.date).endOf(queryParam.range),
      daysInMonth: null,
    },
    timelineSchema: getWeeksAndDaysInMonth({
      start: dayjs(queryParam.date).startOf(queryParam.range),
      daysInMonth: null,
    }),
    timelineSchemaWeek: getDaysInWeek({
      start: dayjs(queryParam.date).startOf(queryParam.range),
    }),
    timelineSchemaDay: getHoursInDay({
      start: dayjs(queryParam.date).startOf(queryParam.range),
    }),
    timelineSchemaQuarter: getWeeksInQuarter({
      start: dayjs(queryParam.date).startOf(queryParam.range),
      daysInQuarter: null,
    }),
    groupFilter: {
      L1:
        FilterOptions.L1.filter((column) => {
          if (column.value === featPrefs && featPrefs[1]?.cols) {
            return column;
          }
        })[0] || FilterOptions.L1[0],
      L2: FilterOptions[FilterOptions.L1[1].value][0],
      filters: DefaultFilters,
    },
    backlogFilter: {
      filters: DefaultFilters,
      assets: [],
    },
    workersFilter: {
      filters: DefaultFiltersWorkers,
    },
    backlogTaskFilter: {
      filters: DefaultFilters,
      assets: [],
    },
    tab: queryParam.tab ? queryParam.tab : TabOptions.BACKLOG,
    setRange,
    setTab,
    nextDate,
    prevDate,
    handleDragStart,
    handleDragEnter,
    handleDragOver,
    handleDragEnd,
    handleDragLeave,
    toggleBundleTasks,
    setHoverItemId,
    handleEditStart,
    handleEditEnd,
    handleSetGroupFilter,
    handleSetBacklogFilter,
    handleSetWorkersFilter,
    handleSetBacklogTaskFilter,
    dragItem: null,
    dragItemIndex: null,
    dragItemLaneIndex: null,
    setDragItemLaneIndex,
    setDatePickerDate,
    dragItemType: null,
    dragItemBacklogType: null,
    dragOverId: null,
    dragOverIndex: null,
    hoverItemId: null,
    bundleTasks: false,
    editItem: null,
    editLane: null,
    tempNewLane: null,
    setTempNewLane,
    crewEdited: false,
    isBundleToggle: false,
    setIsBundleToggle,
    setCrewEdited,
    // isStackPopUpOpen: false,
    // setStackPopUpOpen,
  };

  const [planningState, setPlanning] = useState(defaultState);
  const serviceGroupIds = useServiceGroups();

  // seperate filter local storage state to persists values.
  const [planningFilterState, setPlanningFilterState] = useLocalStorage(
    `${LOCAL_STATE_PREFIX}.${ManageDefs.PLANNING_CALENDAR_FILTER_STATE_ID}`,
  );

  const [planningBacklogFilterState, setPlanningBacklogFilterState] = useLocalStorage(
    `${LOCAL_STATE_PREFIX_BACKLOG}.${ManageDefs.BACKLOG_FILTER_STATE_ID}`,
  );

  const [planningWorkersFilterState, setPlanningWorkersFilterState] = useLocalStorage(
    `${LOCAL_STATE_PREFIX_WORKERS}.${ManageDefs.WORKERS_FILTER_STATE_ID}`,
  );

  // combine and set planningState with persisted filter state.
  useEffect(() => {
    setPlanning((prevState) => ({
      ...prevState,
      ...planningFilterState,
      ...planningBacklogFilterState,
      ...planningWorkersFilterState,
    }));
  }, [setPlanning, planningFilterState, planningBacklogFilterState, planningWorkersFilterState]);

  const providerValue = useMemo(
    () => ({ planningState, setPlanning, serviceGroupIds }),
    [planningState, setPlanning, serviceGroupIds],
  );

  return <PlanningContext.Provider value={providerValue}>{children}</PlanningContext.Provider>;
};

PlanningProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

PlanningProvider.defaultProps = {
  children: null,
};
