import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

import { DeleteButton, HLP_ACTIONS } from './hp-common';
import {
  Container,
  CreateHeaderWrapper,
  Header,
  HeaderTitle,
  TitleWrapper,
  ViewDLHeaderWrapper,
  ViewTitle,
} from './hp-styled-components';

export const HLPHeader = withTheme((props) => {
  const { theme, action, name = '', onDelete } = props;
  const { t } = useTranslation(['admin.configure']);
  const { headerIcon } = theme.distributionListSidePanel;

  const renderHeader = useMemo(() => {
    switch (action) {
      case HLP_ACTIONS.VIEW:
        return (
          <ViewDLHeaderWrapper>
            <TitleWrapper>
              <ViewTitle>{t('Handling_procedures.view_HP_title')}</ViewTitle>
              <HeaderTitle>{name}</HeaderTitle>
            </TitleWrapper>
            <div>
              <DeleteButton
                className={'hp-delete'}
                theme={headerIcon}
                label={t('delete')}
                onClick={onDelete}
              />
            </div>
          </ViewDLHeaderWrapper>
        );
      case HLP_ACTIONS.EDIT:
      case HLP_ACTIONS.NEW:
      default:
        return (
          <CreateHeaderWrapper>
            <Icon icon={Icons.PERSON} size={30} color={headerIcon} />
            <HeaderTitle>
              {action === 'edit'
                ? t('Handling_procedures.edit_HP_title')
                : t('Handling_procedures.create_HP_title')}
            </HeaderTitle>
          </CreateHeaderWrapper>
        );
    }
  }, [action, headerIcon, name, onDelete, t]);

  return (
    <Header>
      <Container>{renderHeader}</Container>
    </Header>
  );
});
