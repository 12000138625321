import { PropTypes } from 'prop-types';
import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';

import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useWidgetFactory } from '@ge/feat-reporting/data-hooks/use-widget-factory';
import { CreateModes } from '@ge/feat-reporting/models/modes';
import { WidgetDefs } from '@ge/feat-reporting/models/widgets';

const WidgetContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.createReport.widget.borderColor};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  background: ${({ theme }) => theme.createReport.widget.backgroundColor};
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 15px;
  min-height: 220px;
  padding: 12px;
  width: ${({ displayWidth }) => displayWidth};
  overflow: hidden;

  ${({ isInteractive, displayHeight }) =>
    isInteractive && displayHeight && `height: ${displayHeight}px`};

  &:last-of-type {
    margin-right: 0px;
  }
`;

export const Widget = ({
  name,
  id,
  // TODO: Need to have another discussion about visualization type, we have now mapped everything to the widget name
  //visualizationType,
  isPlaceholder,
  serviceUrls,
}) => {
  // TODO: handle scenario where we don't get a widget def
  // it could be valid for modules that don't have a corresponding widget like attachments
  // maybe filter those out upstream in the flow?
  const { createMode, hiddenWidgets, reportState } = useContext(ReportsContext);
  const { scope, scopeType } = reportState ?? {};
  const isInteractive = createMode !== CreateModes.PREVIEW;
  const isVisible =
    createMode === CreateModes.VIEW ||
    createMode === CreateModes.PREVIEW ||
    createMode === CreateModes.EDIT;
  // TODO: This is not accurate, need to address that visualizationTypes can be different widgets with different sizes
  const { height, width } = WidgetDefs[name] ?? {};

  const hideWidget = useMemo(() => hiddenWidgets.has(id), [hiddenWidgets, id]);

  const { widget } = useWidgetFactory({
    isInteractive,
    isPlaceholder,
    id,
    name,
    serviceUrls,
    scope,
    scopeType,
  });

  if (!isVisible) return null;

  return (
    <WidgetContainer
      isInteractive={isInteractive}
      displayHeight={height}
      displayWidth={width}
      hidden={hideWidget}
    >
      {widget}
    </WidgetContainer>
  );
};

Widget.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  visualizationType: PropTypes.string.isRequired,
  isPlaceholder: PropTypes.bool,
  serviceUrls: PropTypes.arrayOf(PropTypes.string),
  timeRange: PropTypes.string, // can make this more specifc to enum values
  values: PropTypes.instanceOf(Object),
};
