import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Tooltip } from '@ge/components/tooltip';
import { elevations } from '@ge/tokens/elevations';

import { DetailsCurrentSeparator } from '../entity-details-shared';

const StyledHeaderCurrentCustomer = styled.div`
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  align-items: center;
  padding: 0;
  h5 {
  }
  .full-width {
    max-width: 270px;
  }
`;

const PersonIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.PERSON,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const HeaderCurrentCustomer = ({ className, customerName, siteName }) => (
  <StyledHeaderCurrentCustomer className={className}>
    <PersonIcon />
    <Tooltip title={customerName} zIndex={elevations.P20} placement="top">
      <h5 className={`${!siteName && 'full-width'}`}>{customerName}</h5>
    </Tooltip>

    {siteName && <DetailsCurrentSeparator>/</DetailsCurrentSeparator>}
  </StyledHeaderCurrentCustomer>
);

HeaderCurrentCustomer.propTypes = {
  className: PropTypes.string,
  customerName: PropTypes.string,
  siteName: PropTypes.string,
};

HeaderCurrentCustomer.defaultProps = {
  className: null,
  customerName: '',
};

export default HeaderCurrentCustomer;
