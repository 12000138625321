import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateSchedule } from './create-schedule';
import { EditSchedule } from './edit-schedule';
import { ViewSchedule } from './view-schedule';

dayjs.extend(isSameOrBefore);
export const ScheduleSection = ({ section, task, templateMode, workPlanMode }) => {
  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const { enabledApprovalFlag } = useStoreState((state) => state.tasks);

  const { collapsed, metadata } = section;
  const { trigger, watch } = useFormContext();
  const watchDueDate = watch('dueDate');
  const watchEligibleStartDate = watch('eligibleStartDate');
  const watchScheduleDate = watch('scheduleDate');
  const [scheduleFlag, setScheduleFlag] = useState(false);

  useEffect(() => {
    if (scheduleFlag && watchScheduleDate) trigger('scheduleDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, watchDueDate, watchEligibleStartDate, watchScheduleDate]);

  useEffect(() => {
    watchScheduleDate && setScheduleFlag(true);
  }, [watchScheduleDate]);

  const rules = {
    create: {
      validate: {
        eligibleStartDate: (watchScheduleDate) =>
          watchEligibleStartDate &&
          watchScheduleDate &&
          dayjs(watchEligibleStartDate)
            .startOf('date')
            .isSameOrBefore(dayjs(watchScheduleDate)),
        dueDate: (watchScheduleDate) =>
          watchDueDate &&
          watchScheduleDate &&
          dayjs(watchScheduleDate)
            .subtract(1, 'day')
            .startOf('date')
            .isBefore(dayjs(watchDueDate)),
      },
    },
    edit: {
      validate: {
        eligibleStartDate: (watchScheduleDate) =>
          watchEligibleStartDate &&
          watchScheduleDate &&
          dayjs(watchEligibleStartDate)
            .startOf('date')
            .isSameOrBefore(dayjs(watchScheduleDate)),
      },
    },
  };

  if (!section.hidden) {
    /* checking workPlanMode prop to handle schedule section in 
    create task mode for users with no work plan edit permissions */
    if (workPlanMode) {
      if (templateMode === TaskTemplateModes.CREATE) {
        return null;
      } else {
        return <ViewSchedule task={task} />;
      }
    } else {
      switch (templateMode) {
        case TaskTemplateModes.CREATE:
          if (!approvalFlag || !enabledApprovalFlag) {
            return (
              <CreateSchedule
                collapsed={collapsed}
                metadata={metadata}
                rules={rules.create}
                templateMode={templateMode}
              />
            );
          } else {
            return null;
          }
        case TaskTemplateModes.EDIT:
          return (
            <EditSchedule
              metadata={metadata}
              task={task}
              rules={rules.edit}
              templateMode={templateMode}
            />
          );
        case TaskTemplateModes.VIEW:
          return <ViewSchedule task={task} />;
        default:
          return null;
      }
    }
  }
};

ScheduleSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

ScheduleSection.defaultProps = {
  task: {},
};
