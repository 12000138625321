import { PropTypes } from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';

const StyledLabel = styled.label`
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 13px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

const StyledTextarea = styled.textarea`
  border: 1px solid ${(props) => props.theme.input.border};
  border-radius: 3px;
  resize: vertical;
  height: 90px;
  padding: 5px;
  background-color: ${(props) => props.theme.input.background};
  color: ${(props) => props.theme.input.textColor};
  font-family: Museo Sans;

  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  :-moz-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  ::-moz-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  &.invalid {
    border: ${(props) => props.theme.select.errorBorder};
  }
`;

const TextareaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextareaComponent = forwardRef(
  ({ className, label, name, id, onResize, required, error, ...props }, ref) => {
    const [currentHeight, setCurrentHeight] = useState();
    const [currentWidth, setCurrentWidth] = useState();

    useEffect(() => {
      onResize({ height: currentHeight, width: currentWidth });
    }, [currentHeight, currentWidth, onResize]);

    const handleResize = ({ target }) => {
      const { clientHeight, clientWidth } = target;

      setCurrentHeight(clientHeight);
      setCurrentWidth(clientWidth);
    };

    const textareaEl = (
      <StyledTextarea
        className={`${className} ${!!error && 'invalid'}`}
        ref={ref}
        name={name}
        id={id}
        onMouseMove={handleResize}
        {...props}
      />
    );

    if (label) {
      return (
        <TextareaWrapper>
          <StyledLabel htmlFor={id}>
            {label}
            {required && <>*</>}
          </StyledLabel>
          {textareaEl}
        </TextareaWrapper>
      );
    }

    return textareaEl;
  },
);

TextareaComponent.displayName = 'TextareaComponent';

TextareaComponent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  onResize: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  dark: PropTypes.bool,
  error: PropTypes.object,
};

TextareaComponent.defaultProps = {
  onResize: () => {},
};

export const Textarea = withTheme(TextareaComponent);
