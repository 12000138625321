import { useStoreState } from 'easy-peasy';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Placeholders } from '@ge/models/constants';
import { useWindTurbines } from '@ge/shared/data-hooks';

import { useSitesAssetsCount } from '../../data-hooks/use-sites-assets-count';

const Container = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.mainNav.sectionBorderColors};
  padding-right: 20px;
`;

const SiteAssetContainer = styled.div`
  margin-right: 15px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.mainNav.dataTextColor};
  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.mainNav.iconColor,
  size: 15,
}))`
  margin: 0px 10px 3px;
`;

const StyledIconStorage = styled(Icon).attrs((props) => ({
  color: props.theme.mainNav.iconColor,
  size: 14,
}))`
  margin: 0px 10px 3px;
`;

const StyledIconSite = styled.svg`
  display: inline-block;
  padding-top: 2px;
  vertical-align: middle;
  height: 22px;
  width: 16px;
  fill: white;
  margin-right: 10px;
`;

const SitesAssetsCount = () => {
  const { currentView } = useStoreState((state) => state.view);
  const { data: viewCount } = useSitesAssetsCount(currentView.sites);

  //Data hooks
  useWindTurbines();

  return (
    <>
      <Container>
        <SiteAssetContainer>
          <StyledIconSite viewBox="0 0 16 12">
            <path d={Icons.NEW_SITE1} id="Shape"></path>
            <path d={Icons.NEW_SITE2} id="Path"></path>
            <path
              d={Icons.NEW_SITE3}
              id="Path-Copy"
              transform="translate(2.381733, 4.123282) scale(-1, 1) translate(-2.381733, -4.123282) "
            ></path>
          </StyledIconSite>
          <h3>{viewCount?.siteCount ?? Placeholders.DOUBLE_DASH}</h3>
        </SiteAssetContainer>
      </Container>
      <Container>
        {viewCount?.windTurbine !== 0 && (
          <SiteAssetContainer>
            <StyledIcon icon={Icons.TURBINE} />
            <h3>{viewCount?.windTurbine ?? Placeholders.DOUBLE_DASH}</h3>
          </SiteAssetContainer>
        )}
        {viewCount?.storageInverter !== 0 && (
          <SiteAssetContainer>
            <StyledIconStorage icon={Icons.BATTERY} />
            <h3>{viewCount?.storageInverter ?? Placeholders.DOUBLE_DASH}</h3>
          </SiteAssetContainer>
        )}
      </Container>
    </>
  );
};

export default SitesAssetsCount;
