import { SortDirection } from '@ge/components/table/models/sort-direction';
import { AssetType, EventIdType } from '@ge/models/constants';
import { Asset } from '@ge/models/entities';

import * as request from './api';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;
const DAV_ASSET_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_ASSET_API;
const RTMC_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;
/**
 * Helper method to build the request params object for Axios request.
 *
 * @param {String} sort Metric to sort on
 * @param {String} direction Sort direction
 * @param {Boolean} metrics Include metrics in response
 */
const buildSortOptions = (sort, direction, metrics) => {
  const options = {};

  if (sort && typeof sort === 'string') {
    options.params = {
      sort,
      direction,
      metrics,
    };
  }

  return options;
};

/**
 * Build a request options object with date range
 * params associated wth it.
 *
 * @param {Date} startDate Start date
 * @param {Date} endDate End date
 */
const buildDateRangeOptions = (startDate, endDate) => {
  const options = {
    params: {},
  };

  if (startDate) {
    options.params.startDate = startDate;
  }

  if (endDate) {
    options.params.endDate = endDate;
  }

  return options;
};

/**
 * Get a sorted array of basic asset objects.
 */
export const fetchWindTurbines = async ({
  pageIndex = 0,
  pageSize = 1000,
  sortDirection = SortDirection.ASC,
  sortMetric = 'id',
} = {}) =>
  request.post(
    '/cmn/assets/wind-turbines',
    { pageIndex, pageSize, sortDirection, sortMetric },
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => ({
          assets: Array.isArray(data?.turbines)
            ? data.turbines?.map((asset) => new Asset(asset))
            : data,
          totalPages: data.totalPages,
        }),
      ],
    },
  );

export const fetchWindTurbinesBySiteId = async ({
  pageIndex,
  pageSize,
  siteId,
  sortDirection = SortDirection.ASC,
  sortMetric = 'id',
} = {}) =>
  request.post(
    `/cmn/sites/wind-turbines/${siteId}`,
    { pageIndex, pageSize, sortDirection, sortMetric },
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => ({
          assets: data?.turbines?.map((asset) => new Asset(asset)) ?? [],
        }),
      ],
    },
  );

export const fetchSiteControllers = async () =>
  request.post(
    '/cmn/assets/site-controllers',
    {},
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => ({
          assets: Array.isArray(data) ? data?.map((asset) => new Asset(asset)) : data,
        }),
      ],
    },
  );

export const fetchSubstations = async () =>
  request.post(
    '/cmn/assets/substations',
    {},
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => ({
          assets: Array.isArray(data) ? data?.map((asset) => new Asset(asset)) : data,
        }),
      ],
    },
  );

/**
 * Get a sorted array of assets with associated metricss.
 *
 * @param {String} sortMetric Metric to sort on
 * @param {String} direction Sort direction
 */
export const fetchAssetsWithMetrics = (sortMetric, direction) =>
  request.get('/assets', buildSortOptions(sortMetric, direction, true));

/**
 * Retrieve a detailed asset object by ID.
 *
 * @param {String} assetId Asset ID
 */
export const fetchAssetById = (assetId) => request.get(`/assets/${assetId}`);

export const fetchAssetEventsWithMetrics = (assetId) => request.get(`/assets/${assetId}/events`);

export const fetchAssetEvents = (assetId, eventIdType = 'all') =>
  request.get(`/dav/asset/rt-events`, {
    baseURL: RTMC_BASE_URL,
    params: { eventIdType, id: assetId },
  });

export const fetchAssetEventsByType = ({ assetId, type = EventIdType.ALL }) => {
  return request.get('/rtmc/asset/rt-events', {
    baseURL: RTMC_BASE_URL,
    params: { id: assetId, eventIdType: type },
  });
};

export const fetchAssetTickets = (assetId) => request.get(`/assets/${assetId}/tickets`);

export const fetchRealTimeSignalsForAsset = (assetId, make, controllerType, platform) =>
  request.get(`/dav/asset/rt-values`, {
    baseURL: DAV_ASSET_BASE_URL,
    params: {
      id: assetId,
      make: make?.toLowerCase(),
      controllerType: controllerType?.toLowerCase(),
      platform: platform?.toLowerCase(),
    },
  });

export const fetchRealTimeDataForAsset = (asset) => {
  const { id, type, make, model, controllerType, platform } = asset;
  let urlAndOptions = { ur: '', options: { baseURL: RTMC_BASE_URL } };

  switch (type) {
    case AssetType.WIND_TURBINE:
      urlAndOptions.url = `/rtmc/asset/${id}/rt-data`;
      urlAndOptions.options.params = {
        make: make?.toLowerCase(),
        controllerType: controllerType?.toLowerCase(),
        platform: platform?.toLowerCase(),
      };
      break;
    case AssetType.SITE_CONTROLLER:
      urlAndOptions.url = `/rtmc/site-controller/${id}/rt-data`;
      urlAndOptions.options.params = {
        make: make?.toLowerCase(),
        model: model?.toLowerCase(),
        controllerType: controllerType?.toLowerCase(),
        platform: platform?.toLowerCase(),
      };
      break;
    case AssetType.SUBSTATION:
      urlAndOptions.url = `/rtmc/substation/${id}/rt-data`;
      break;
    case AssetType.STORAGE_INVERTER:
      urlAndOptions.url = `/rtmc/inverter/${id}/rt-data`;
      urlAndOptions.options.params = {
        make: make?.toLowerCase(),
        model: model?.toLowerCase(),
        type: type,
      };
      break;
  }

  return request.get(urlAndOptions.url, urlAndOptions.options);
};

export const fetchAssetsComponentHierarchies = () =>
  request.get(`/cmn/assets/component-hierarchies`, { baseURL: CMN_BASE_URL });

export const fetchAssetComponentHierarchy = (assetId) =>
  request.get(`/cmn/assets/${assetId}/component-hierarchy`, { baseURL: CMN_BASE_URL });

export const fetchAssetComponentInfo = (componentId) =>
  request.get(`/cmn/components/${componentId}`, { baseURL: CMN_BASE_URL });
// TODO: We added the start and end date parameters to this function but they are not being used.
//  This range may come from a filter object or a global config or something else.
export const fetchAssetMetricChart = (assetId, metric, startDate, endDate) =>
  request.get(`/dav/assets/${assetId}/metrics/${metric}/chart`, {
    ...buildDateRangeOptions(startDate, endDate),
    baseURL: DAV_ASSET_BASE_URL,
  });

export const getCaseProcedureData = (assetId, eventCode) => {
  const params = { eventCodeId: eventCode };
  return request.get(`/rtmc/events/${assetId}/case-procedures`, {
    baseURL: RTMC_BASE_URL,
    params,
  });
};

/**
 * Dynamically load the asset mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */

export const registerMocks = () =>
  import('./__mocks__/asset/assets.mocks')
    .then((mocks) => mocks.registerMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });

export const fetchAssetsDataAssignments = (assetIds = []) =>
  request.get(`/cmn/sites/assets-data-assignments`, {
    baseURL: CMN_BASE_URL,
    params: { assetIds: assetIds.join(',') },
  });
