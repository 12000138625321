import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

import Point from './images/Point.svg';

const StyledHeader = styled.div`
  display: flex;
  border-bottom: 2px solid ${(props) => props.theme.analyze.fullScreenChartModal.borderColor};
  padding: 10px;
  height: 44px;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  &.right-wrapper {
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 116px;
  }

  .point {
    height: 18px;
    width: 18px;
  }
`;

const StyledTitle = styled.h2`
  margin-left: 23px;
  line-height: 26px;
`;

const DateTimeWrapper = styled.div`
  display: flex;
`;

const StyledDateTime = styled.p`
  color: ${(props) => props.theme.analyze.fullScreenChartModal.dateTimeColor};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  margin: 0 0 0 6px;
  padding-top: 3px;
`;

const MetricWapper = styled.div`
  margin-left: 35px;
`;

const KpiTitle = styled.span`
  display: block;
  color: ${(props) => props.theme.analyze.fullScreenChartModal.kpiHeaderColor};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const Kpi = styled.span`
  color: ${(props) => props.theme.analyze.fullScreenChartModal.kpiHeaderColor};
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 29px;
  position: relative;

  &:after {
    content: '${(props) => (props.kpiUnit ? props.kpiUnit : '')}';
    position: absolute;
    right: -20px;
    top: 0;
    color: ${(props) => props.theme.analyze.fullScreenChartModal.kpiHeaderColor};
    font-size: 10px;
    letter-spacing: 0.45px;
    line-height: 12px;
  }
`;

const WindIcon = styled(Icon).attrs((props) => ({
  size: 16,
  icon: Icons.WIND_DIRECTION,
  color: props.theme.entityDetails.headerIconColor,
}))`
  vertical-align: initial;
`;

export const MetricHeader = () => (
  <StyledHeader>
    <HeaderWrapper>
      <DateTimeWrapper>
        <img className="point" src={Point} alt="" />
        <StyledDateTime>13:54 PST April 12, 2020</StyledDateTime>
      </DateTimeWrapper>
      <StyledTitle>1218 HSS DnWind Cage</StyledTitle>
    </HeaderWrapper>
    <HeaderWrapper className="right-wrapper">
      <MetricWapper>
        <KpiTitle>wind</KpiTitle>
        <Kpi kpiUnit="kts">
          <WindIcon /> <span>22.5</span>
        </Kpi>
      </MetricWapper>
      <MetricWapper>
        <KpiTitle>power</KpiTitle>
        <Kpi kpiUnit="kw">1,012</Kpi>
      </MetricWapper>
    </HeaderWrapper>
  </StyledHeader>
);
