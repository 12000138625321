import { useCallback } from 'react';

import { Placeholders } from '@ge/models/constants';
import { useLogger } from '@ge/shared/hooks';
import { isDevMode, isObject } from '@ge/shared/util';
import { globalColors } from '@ge/tokens/colors';

const titleStyle = `
  color: ${globalColors.grey};
  background: ${globalColors.terracotta5};
`;

const lineStyle = `
  font-size: smaller;
`;

const serializeValue = (value) => {
  let serialized = value ?? Placeholders.DOUBLE_DASH;

  if (isObject(value)) {
    serialized = JSON.stringify(value);
  }

  return serialized;
};

// TODO: can wire this into a main logger that supports log levels
export const useAuthLogger = () => {
  const logger = useLogger();

  return useCallback(
    ({ capabilities, description, entity, permissions, source, type }) => {
      if (!isDevMode()) {
        return;
      }

      const message = [
        `Type: ${type ?? Placeholders.DOUBLE_DASH}`,
        `Description: ${description ?? Placeholders.DOUBLE_DASH}`,
        `Source: ${serializeValue(source)}`,
        `Capabilities: ${serializeValue(capabilities)}`,
        `Entity: ${serializeValue(entity)}`,
        `Permissions: ${serializeValue(permissions)}`,
      ].join('\n');

      console.groupCollapsed('%c  ⛔ Auth Log ⛔  ', titleStyle);
      logger.debug(`%c${message}`, lineStyle);
      console.groupEnd();
    },
    [logger],
  );
};
