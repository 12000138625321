import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

import { defaultOptions } from './area-chart-default-options';

const AreaChartComponent = ({ ...props }) => {
  const {
    theme: {
      charts: { area },
    },
    data,
    width,
    height,
    xAxisTitle,
    xAxisPlotLines,
    yAxisTitle,
    tooltipHeader,
    tooltipPointFormat,
    pointStart,
    pointInterval,
  } = props;

  const chartOptions = { ...defaultOptions };
  chartOptions.series[0].data = data;
  chartOptions.series[0].color = area.color;
  chartOptions.series[0].pointStart = pointStart;
  chartOptions.series[0].pointInterval = pointInterval;
  chartOptions.yAxis.gridLineColor = area.gridLineColor;
  chartOptions.xAxis.lineColor = area.gridLineColor;
  chartOptions.xAxis.title.text = xAxisTitle;
  chartOptions.xAxis.plotLines = xAxisPlotLines;
  chartOptions.yAxis.title.text = yAxisTitle;

  chartOptions.plotOptions.area.tooltip.headerFormat = tooltipHeader;
  chartOptions.plotOptions.area.tooltip.pointFormat = tooltipPointFormat;

  chartOptions.chart.height = height;
  chartOptions.chart.width = width;

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

AreaChartComponent.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  pointInterval: PropTypes.number,
  pointStart: PropTypes.number,
  tooltipHeader: PropTypes.string,
  tooltipPointFormat: PropTypes.string,
  xAxisTitle: PropTypes.string,
  xAxisPlotLines: PropTypes.array,
  yAxisTitle: PropTypes.string,
};

AreaChartComponent.defaultProps = {
  width: null,
  height: 320,
  pointInterval: null,
  pointStart: null,
  xAxisTitle: null,
  xAxisPlotLines: null,
  yAxisTitle: null,
  tooltipHeader: null,
  tooltipPointFormat: null,
};

export const AreaChart = withTheme(AreaChartComponent);
