import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { Title } from '@ge/components/typography';
import { CalendarFilters } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

import { PlanningContext, DefaultFilters } from '../../context/planning-provider';

const FilterMenu = styled.div``;

const FilterBtn = styled.div`
  border-radius: 3px;
  padding: 4px 6px 5px;
  margin-top: 1px;
  background: ${(props) =>
    props.active ? props.theme.select.primaryBackground : props.theme.select.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15px;
`;

const FilterIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.FILTER,
  color: props.theme.filterPanel.iconColor,
}))`
  margin-right: 2px;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.active ? props.theme.select.chevronPrimaryColor : props.theme.select.chevronColor,
}))`
  margin-left: 2px;
`;

const FilterCountText = styled(Title).attrs(() => ({
  level: 4,
}))`
  text-transform: uppercase;
  margin: 0 4px;
`;

const StyledContent = styled.div`
  > .title {
    text-transform: uppercase;
    padding: 8px;
  }
  .footer {
    padding: 6px;
    border-top: solid 1px ${(props) => props.theme.manage.filterMenu.borderColor};
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;
    background: ${(props) => props.theme.manage.filterMenu.background};
  }
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  .title {
    border: 0;
    padding: 8px 0;
    margin: 0 8px;
    border-top: solid 1px ${(props) => props.theme.manage.filterMenu.background};
    font-weight: 700;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.manage.filterMenu.chevronColor};
    }
  }
  label {
    margin-bottom: 8px;
    margin-left: 18px;
    text-transform: capitalize;
  }
`;

export const CalendarFilterMenu = ({ filters }) => {
  const { t } = useTranslation(['tasks', 'general', 'manage.planning'], { useSuspense: false });

  const {
    planningState: { groupFilter, handleSetGroupFilter },
  } = useContext(PlanningContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setActive] = useState(false);
  const [filterState, setFilterState] = useState(groupFilter.filters);

  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setFilterState(groupFilter.filters);
  };

  const handleApply = () => {
    setActive(filterState != null);
    handleSetGroupFilter(groupFilter.L1, groupFilter.L2, filterState);
    handleMenuClose();
  };

  const handleClear = () => {
    setAnchorEl(null);
    setActive(null);
    handleSetGroupFilter(groupFilter.L1, groupFilter.L2, DefaultFilters);
  };

  const handleChange = (filterType, updateValue) => {
    const prevDef = filterState[filterType];
    const hide = prevDef.value.includes(updateValue);

    const updateValues = hide
      ? prevDef.value.filter((key) => key !== updateValue)
      : [...prevDef.value, updateValue];

    const newState = {
      ...filterState,
      [filterType]: {
        ...prevDef,
        value: updateValues,
      },
    };

    setFilterState(newState);
  };

  const buildCheckboxs = (filter, value, label) => {
    return (
      <Checkbox
        label={label}
        checkState={
          filterState[filter]?.value.includes(value) ? CheckedState.CHECKED : CheckedState.UNCHECKED
        }
        onChange={() => handleChange(filter, value)}
        key={value}
      />
    );
  };

  useEffect(() => {
    // track the filter context and update the local filter state to re-check the checkboxes on menu open.
    setFilterState(groupFilter.filters);
  }, [groupFilter.filters]);

  const filterCounts = useMemo(
    () =>
      groupFilter.filters[CalendarFilters.SOURCE]?.value?.length +
      groupFilter.filters[CalendarFilters.PRIORITY]?.value?.length +
      groupFilter.filters[CalendarFilters.STATUS]?.value?.length +
      groupFilter.filters[CalendarFilters.WORKSCOPE]?.value?.length +
      groupFilter.filters[CalendarFilters.FLAG]?.value?.length,
    [groupFilter.filters],
  );

  // check and initliaze active state on load if persisted filter state exists.
  useEffect(() => {
    if (filterCounts > 0) setActive(filterState != null);
  }, [filterCounts, filterState, setActive]);

  const checkIsExpanded = useCallback(
    (filter) => filterState[filter].value.length !== 0,
    [filterState],
  );

  const sourceFilter = filters?.source?.map((value) => ({
    label: t(`dynamic.types.${value}`, value),
    value: value,
  }));
  sourceFilter?.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return (
    <FilterMenu onClick={(e) => killEventPropagation(e)}>
      <FilterBtn onClick={(e) => showMenu(e)} active={isActive}>
        <FilterIcon />
        {isActive && <FilterCountText>{filterCounts}</FilterCountText>}
        <ChevronIcon active={isActive} />
      </FilterBtn>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <StyledContent>
          <div className="title">{t('manage.planning:filter_by', 'FILTER BY')}</div>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:task_source', 'TASK SOURCE')}</Title>}
            expanded={checkIsExpanded(CalendarFilters.SOURCE)}
          >
            {sourceFilter?.map((value) =>
              buildCheckboxs(CalendarFilters.SOURCE, value.value, value.label),
            )}
          </StyledCollapsiblePanel>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:work_scope', 'TASK WORK SCOPE')}</Title>}
            expanded={checkIsExpanded(CalendarFilters.WORKSCOPE)}
          >
            {filters?.workScope?.map((value) =>
              buildCheckboxs(
                CalendarFilters.WORKSCOPE,
                value,
                t(`dynamic.work_scopes.${value}`, value),
              ),
            )}
          </StyledCollapsiblePanel>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:task_priority', 'TASK PRIORITY')}</Title>}
            expanded={checkIsExpanded(CalendarFilters.PRIORITY)}
          >
            {filters?.priority?.map((value) =>
              buildCheckboxs(CalendarFilters.PRIORITY, value, value),
            )}
          </StyledCollapsiblePanel>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:task_status', 'TASK STATUS')}</Title>}
            expanded={checkIsExpanded(CalendarFilters.STATUS)}
          >
            {filters?.status?.map((value) => buildCheckboxs(CalendarFilters.STATUS, value, value))}
          </StyledCollapsiblePanel>
          {approvalFlag && (
            <StyledCollapsiblePanel
              headerContent={<Title level={4}>{t('general:task_flag', 'TASK APPROVED')}</Title>}
              expanded={checkIsExpanded(CalendarFilters.FLAG)}
            >
              {filters?.flag?.map((value) =>
                buildCheckboxs(
                  CalendarFilters.FLAG,
                  value,
                  t(`tasks:dynamic.task_flag.${value}`, value),
                ),
              )}
            </StyledCollapsiblePanel>
          )}
          <div className="footer">
            <Button onClick={handleClear}>{t('general:clear', 'Clear')}</Button>
            <Button primary onClick={handleApply}>
              {t('general:apply', 'Apply')}
            </Button>
          </div>
        </StyledContent>
      </Menu>
    </FilterMenu>
  );
};

CalendarFilterMenu.propTypes = {
  filters: PropTypes.instanceOf(Object),
};

CalendarFilterMenu.defaultProps = {
  filters: null,
};
