import { AlertType } from '@ge/models/constants';

export const isInvalid = (alert) => {
  let output = false;
  switch (alert.type) {
    case AlertType.TEMPORARY_CONFIG_CHANGE:
    case AlertType.NOTAM:
      output = alert.isExpired ? true : false;
      break;
    default:
      output = false;
      break;
  }

  return output;
};
