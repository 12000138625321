import { CaseStatus, AnomaliesTableCaseStatus, MonitorDefs } from '@ge/models/constants';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';

import { DrivetrainComponents } from './drivetrain-components';

const DrivetrainComponentList = Object.values(DrivetrainComponents).reduce(
  (result, component) => [
    ...result,
    String(component.componentTypeId).toUpperCase(),
    String(component.description).toUpperCase(),
  ],
  [],
);

export const RibbonFilterTypes = {
  ANOMALIES: MonitorDefs.ANOMALIES,
};

export const RibbonFilterType = {
  NONE: 'none',
  ALL_OPEN: 'all_open',
  NEW_CASES: 'new_cases',
  DRIVETRAIN_CASES: 'drivetrain_cases',
  CLOSED_TASKS: 'closed_tasks',
};

export const RibbonFilterDefs = {
  [RibbonFilterTypes.ANOMALIES]: [
    {
      id: RibbonFilterType.ALL_OPEN,
      label: 'All Open',
      i18nKey: 'ribbon_menu.open_cases',
      filterFn: (_case) =>
        AnomaliesTableCaseStatus.includes(String(_case[AnomaliesColumns.STATUS]).toUpperCase()),
    },
    {
      id: RibbonFilterType.NEW_CASES,
      name: AnomaliesColumns.CREATE_DATE,
      label: 'New Cases',
      i18nKey: 'ribbon_menu.new_cases',
      filterFn: (_case) =>
        [CaseStatus.NEW, CaseStatus.RETURNED].includes(
          String(_case[AnomaliesColumns.STATUS]).toUpperCase(),
        ),
    },
    {
      id: RibbonFilterType.DRIVETRAIN_CASES,
      label: 'Drivetrain Cases',
      i18nKey: 'ribbon_menu.drivetrain_cases',
      filterFn: (_case) => {
        const val = _case?.[AnomaliesColumns.COMPONENT]?.toUpperCase() ?? '';
        return (
          AnomaliesTableCaseStatus.includes(String(_case[AnomaliesColumns.STATUS]).toUpperCase()) &&
          DrivetrainComponentList.some((component) => val.includes(component))
        );
      },
    },
    {
      id: RibbonFilterType.CLOSED_TASKS,
      label: 'Closed Tasks',
      i18nKey: `ribbon_menu.closed_tasks`,
      filterFn: (_case) => {
        const val = _case[AnomaliesColumns.ESCALATED]?.tasks?.map((task) =>
          task.status?.toLowerCase().includes('completed'),
        );
        if (val.length && !val?.includes(false)) {
          return _case[AnomaliesColumns.ESCALATED]?.status?.toLowerCase() == 'completed';
        }
      },
    },
  ],
};

export const RibbonFilterDefault = {
  [RibbonFilterTypes.ANOMALIES]: { id: RibbonFilterDefs[RibbonFilterTypes.ANOMALIES][0].id },
};
