import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { EntityType, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';

import { useGlobalFilters } from '../hooks';
import { fetchFleetKpiData } from '../services';

import { KPI, splitKpisCategoryByApi } from './util';

/**
 * Use Fleet Overview KPI data.
 */
const useFleetOverviewKpiData = ({
  categories,
  endDate,
  entityAggr = EntityType.FLEET,
  startDate,
  timeAggr,
}) => {
  const { allKpiMetricsEndpointCategories, otherKpiEndpointsCategories } =
    splitKpisCategoryByApi(categories);
  const logger = useLogger();
  const regionsById = useStoreState((state) => state.regions.regions);

  const filters = useGlobalFilters();

  const queries = useQueries(
    [allKpiMetricsEndpointCategories, otherKpiEndpointsCategories].map((categories) => {
      const params = {
        categories,
        endDate,
        entityAggr,
        filters,
        startDate,
        timeAggr,
      };

      return {
        queryKey: [QueryKey.FLEET_KPI_DATA, params],
        queryFn: async () => fetchFleetKpiData(params),
        enabled: Boolean(categories?.length && timeAggr),
        ...Config.EXECUTE_ONCE,
      };
    }),
  );

  const dataMapped = useMemo(() => {
    const mappedQueries = queries.map((query) => query.data?.data);
    const fleetData = Object.assign({}, ...mappedQueries);

    const hydrated = fleetData && KPI.hydrate(fleetData, regionsById, timeAggr);
    // TODO: Will be moving this to BFF in an upcoming story as the sorting of time series data is already done in BFF.
    const sortedTimeSeriesData = KPI.getSortedTimeSeriesData(hydrated);
    return {
      sortedKpi: sortedTimeSeriesData,
    };
  }, [queries, regionsById, timeAggr]);

  const isLoading = queries.some((query) => query.isLoading);

  const error = useMemo(
    () => (isLoading ? null : queries.find((query) => query.isError)?.error),
    [isLoading, queries],
  );

  if (error) {
    logger.error(error);
  }

  return { data: dataMapped.sortedKpi, error, isLoading };
};

export default useFleetOverviewKpiData;
