import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const FilterIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SEARCH,
  color: props.theme.themeSelector.filterIconColor,
}))``;

const Filter = ({ text, onChange }) => (
  <div className="filter">
    <input type="text" value={text} onChange={onChange} />
    <FilterIcon className="search-icon" />
  </div>
);

Filter.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
};

Filter.defaultProps = {
  text: '',
  onChange: () => {},
};

export default Filter;
