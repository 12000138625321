import { request } from './api';

export const submitFile = (data, options) => request.post('/submitFile/', data, options);
export const submitMultiPartsFile = (data, options) =>
  request.post('/submitMultiPartsFile/', data, options);
// export const getFileFromS3 = (key, options) => request.get(`/getFileFromS3/${key}`, options);
// export const getBigFileFromS3 = (key, options) => request.get(`/getBigFileFromS3/${key}`, options);
// export const getListFromS3 = (prefix, options) => request.get(`/getListFromS3/${prefix}`, options);
export const getBulkReportDownloadUrl = (data, options) =>
  request.post(`/rei/reports/download-reports-pdf`, data, options);

// export const getBulkReportDownloadUrl = (data,options) => request.post(`/downloadReportsPdf/`,data,options);

// export const getImages = (jobId, options) => request.get(`/getImages/${jobId}`, options);
export const updateImage = (flagNew, data, options) =>
  request.post(`/updateImage/${flagNew}`, data, options);
// export const getVideos = (jobId, options) => request.get(`/getVideos/${jobId}`, options);
// export const updateManufacturerByJobId = (jobId, data, options) =>
//   request.post(`/rei/upload/update-manufacturer/${jobId}`, data, options);
// export const getPresignedUrl = (files, type = 'get', expires = 1000, options) => request.get(`/getImagePresignedUrl/?file=${files.join(',')}&type=${type}&expires=${expires}`, options);
export const getPresignedUrl = (data, options) =>
  request.post('/rei/upload/image-presigned-url/', data, options);
export const putFilesByPresignedUrl = (
  presignedUrl,
  fileData,
  options,
  uplaodProgress = () => {},
) =>
  request.putPresignedUpload(presignedUrl, fileData, {
    ...options,
    headers: {
      'x-amz-server-side-encryption': 'aws:kms',
      'x-amz-server-side-encryption-aws-kms-key-id': `${process.env.REACT_APP_INSPECTION_API_KMS_KEY}`,
    },
    onUploadProgress: (p) => {
      uplaodProgress(p);
    },
  });

export const droneSpreadsheetUpload = (formData, headers, uploadProgress = () => {}) =>
  request.post(`/rei/upload/process-annotation-for-image`, formData, headers, {
    onUploadProgress: (p) => {
      console.log('files.js uploadProgress', p);
      uploadProgress(p);
    },
  });

export const getSqsEventCallback = (options) => request.get('/sqsEventCallback/', options);
