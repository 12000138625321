import { EntityType } from '@ge/models/constants';

export const getParentEntityUrl = (type, id) => {
  switch (type) {
    case EntityType.SITE:
      return `/analyze/region/${id}`;
    case EntityType.TURBINE:
      return `/analyze/site/${id}`;
    default:
      return '/analyze/fleet-overview/region';
  }
};
