import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { placements, Tooltip } from '@ge/components/tooltip';
import { elevations } from '@ge/tokens/elevations';

import { PlotLinesIds } from './sensor-readings-chart-options-factory';

const ChartLayout = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const LegendContainer = styled.div`
  position: relative;
  ${({ layout }) =>
    layout === ChartLayout.PRIMARY
      ? css`
          padding: 5px 7px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            opacity: 0.8;
            background: ${({ theme }) =>
              theme.sensorReadingsChart.legendBackground[ChartLayout.PRIMARY]};
          }
        `
      : css`
          border-bottom: 1px solid
            ${({ theme }) => theme.sensorReadingsChart.legendBorderColor[ChartLayout.SECONDARY]};
        `};
`;

const Legends = styled.div`
  position: relative;
  display: flex;
`;

const LegendGroup = styled.div`
  overflow: hidden;
  margin-right: ${({ isAxisX }) => (isAxisX ? '15px' : '8px')};
  &:last-child {
    margin-right: 0px;
  }
`;

const Legend = styled.div`
  font-size: 11px;
  min-width: 60px;
  position: relative;
  display: flex;
  margin-bottom: 8px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
  &.disabled {
    .icon {
      fill: ${({ theme }) => theme.sensorReadingsChart.disabledIconColor};
    }
    .title {
      color: ${({ theme }) => theme.sensorReadingsChart.disabledLegendColor};
    }
    .value {
      visibility: hidden;
    }
  }
`;

const LegendSep = styled.span`
  margin-right: 5px;
  margin-right: ${({ isAxisX }) => (isAxisX ? '15px' : '5px')};
  color: ${({ theme }) => theme.sensorReadingsChart.legendText};
  &::after {
    content: ':';
  }
`;

const LegendAxis = styled.span`
  color: ${({ theme }) => theme.sensorReadingsChart.legendColor};
  &::after {
    content: '${({ name }) => name}';
  }
`;

const LegendTitle = styled.span`
  color: ${({ theme }) => theme.sensorReadingsChart.legendText};
  font-size: 12px;
  user-select: none;
  margin-left: 3px;
  display: flex;
  overflow: hidden;
`;

const LegendText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const LegendTextPad = styled.span``;

const LegendValue = styled.span`
  user-select: none;
  color: ${({ theme }) => theme.sensorReadingsChart.legendValue};
  font-size: 12px;
  &::before {
    color: ${({ theme }) => theme.sensorReadingsChart.legendText};
    content: ':';
    margin-right: 3px;
  }
  &:[hidden] {
    display: none;
  }
`;

export const SensorReadingChartLegend = ({
  seriesGroups,
  plotLines,
  point,
  layout,
  chartRef,
  className,
}) => {
  const { ready, t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const [legendValues, setLegendValues] = useState({});

  useEffect(() => {
    setLegendValues(point?.values ?? {});
  }, [point]);

  const togglePlotLine = useCallback(
    (e, id) => {
      if (chartRef?.current?.chart) {
        const plotLine = plotLines.find((v) => v.id === id) ?? {};
        const chart = chartRef.current.chart;
        if (e.currentTarget.classList.contains('disabled')) {
          chart.xAxis[0].addPlotLine(plotLine);
        } else {
          chart.xAxis[0].removePlotLine(id);
        }
        e.currentTarget.classList.toggle('disabled');
      }
    },
    [plotLines, chartRef],
  );

  const handleLegendClick = useCallback(
    (e, name) => {
      if (chartRef?.current?.chart) {
        const currentseries = chartRef.current.chart.series.find((v) => v.name === name);
        if (currentseries) {
          if (currentseries.visible) {
            currentseries.hide();
            e.currentTarget.classList.add('disabled');
          } else {
            currentseries.show();
            e.currentTarget.classList.remove('disabled');
          }
        }
      }
    },
    [chartRef],
  );

  if (!ready) return null;

  return (
    <LegendContainer layout={layout} className={className}>
      <Legends>
        <LegendGroup isAxisX={true}>
          <Legend>
            <LegendAxis name={'X'} />
            <LegendSep />
            <LegendTitle>{t('sensor_readings.x_axis_legend', 'Time')}</LegendTitle>
          </Legend>
        </LegendGroup>
        {plotLines
          .filter((v) => v.showInLegend)
          .map(({ id, name, iconColor }) => (
            <LegendGroup key={id} isAxisX={true}>
              <Legend onClick={(e) => togglePlotLine(e, id)}>
                {id === PlotLinesIds.TRIP ? (
                  <Icon
                    icon={Icons.TRIP_ICON}
                    color={iconColor}
                    size={11}
                    viewbox={'0 0 9 11'}
                    className={'icon'}
                  />
                ) : (
                  <Icon icon={Icons.ADD} color={iconColor} size={11} className={'icon'} />
                )}
                <LegendSep />
                <LegendTitle className={'title'}>
                  {t(`sensor_readings.legends.${id}`, name)}
                </LegendTitle>
              </Legend>
            </LegendGroup>
          ))}
      </Legends>
      <Legends>
        {seriesGroups?.map((series, i) => (
          <LegendGroup key={i}>
            {series.map(({ name, axis, color = 'transparent' }, i) => (
              <Legend key={name + '__' + i} onClick={(e) => handleLegendClick(e, name)}>
                <LegendAxis name={axis} />
                <LegendSep />
                {
                  <Icon
                    icon={Icons.SQUARE_LEGEND_ICON}
                    color={color}
                    size={13}
                    className={'icon'}
                  />
                }
                {name?.length > 3 ? (
                  <Tooltip title={name} placement={placements.BOTTOM} zIndex={elevations.P25}>
                    <LegendTitle className={'title'}>
                      <LegendText>{name.substring(0, name.length - 1)}</LegendText>
                      <LegendTextPad>{name.substring(name.length - 1)}</LegendTextPad>
                    </LegendTitle>
                  </Tooltip>
                ) : (
                  <LegendTitle className={'title'}>{name}</LegendTitle>
                )}
                <LegendValue className="value" hidden={legendValues[name] === undefined}>
                  {legendValues[name]}
                </LegendValue>
              </Legend>
            ))}
          </LegendGroup>
        ))}
      </Legends>
    </LegendContainer>
  );
};

SensorReadingChartLegend.propTypes = {
  seriesGroups: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  plotLines: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  layout: PropTypes.string.isRequired,
  point: PropTypes.instanceOf(Object),
  chartRef: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};

export default SensorReadingChartLegend;
