import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ErpCustomFieldType, TaskTemplateModes } from '@ge/models/constants';

import { ServiceRequestParts } from '../../../service-request-fields/service-request-parts';

import { ViewParts } from './view-parts';

export const SectionHeading = styled.h4`
  margin-bottom: 10px;
`;

export const PartDetailsSection = ({
  task,
  section,
  shippingSection,
  templateMode,
  erpType,
  erpData,
  setDivPartHeight,
  erpDataStatus,
  erpDataLastUpdatedBy,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { metadata } = section;

  const getPartsSection = () => {
    if (!section.hidden) {
      switch (templateMode) {
        case TaskTemplateModes.CREATE:
        case TaskTemplateModes.EDIT:
          return (
            <ServiceRequestParts
              task={task}
              metadata={metadata}
              shippingSection={shippingSection}
              fieldName={ErpCustomFieldType.PARTS}
              templateMode={templateMode}
              erpType={erpType}
              erpData={erpData}
              erpDataStatus={erpDataStatus}
            />
          );
        case TaskTemplateModes.VIEW:
          return (
            <ViewParts
              metadata={metadata}
              shippingSection={shippingSection}
              fieldName={ErpCustomFieldType.PARTS}
              erpData={erpData}
              setDivPartHeight={setDivPartHeight}
              erpDataLastUpdatedBy={erpDataLastUpdatedBy}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <section>
      <SectionHeading>{t('section_header.parts', 'Parts')}</SectionHeading>
      {getPartsSection()}
    </section>
  );
};

PartDetailsSection.propTypes = {
  section: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  task: PropTypes.object.isRequired,
  erpType: PropTypes.string,
  erpData: PropTypes.object,
  shippingSection: PropTypes.object.isRequired,
  setDivPartHeight: PropTypes.func,
  erpDataStatus: PropTypes.object,
  erpDataLastUpdatedBy: PropTypes.string,
};
