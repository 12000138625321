import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import toArray from 'dayjs/plugin/toArray';
import { is, isEmpty, isNil } from 'ramda';
import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';

import {
  QueryKey,
  ErpBindingPropertyName,
  ErpLaborType,
  ErpDefaultValue,
  ErpType,
  ErpStatus,
  DateTimeFormats,
  ErpCustomFieldType,
} from '@ge/models/constants';

import {
  fetchErpRequest,
  createErpRequest,
  createBulkErpRequest,
  fetchErpRequestStatus,
} from '../services';

dayjs.extend(timezone);
dayjs.extend(toArray);

// TODO: figure out if we should be taking an sr/wo number here
export const useErpRequest = ({ isActive = true, taskId, erpRefetchInterval }) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.ERP_REQUEST, taskId],
    () => fetchErpRequest({ taskId }),
    {
      enabled: Boolean(taskId) && isActive,
      refetchOnMount: true,
      refetchInterval: erpRefetchInterval,
    },
  );

  return { data, error, isLoading };
};

/**
 * Create an ERP request
 *
 * @param opts useMutation options
 * @returns {{mutate: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useCreateErpRequest = (opts = {}) => {
  const { mutate, isLoading, isError, error } = useMutation(
    ({
      assetId,
      assetModel,
      erpFormValues,
      siteId,
      siteTimezone,
      id,
      taskId,
      type,
      reportedDate,
    }) => {
      if (!(erpFormValues && siteId && taskId && type)) {
        return () => {};
      }

      const payload = {
        assetId,
        assetModel,
        metadata: erpFormValues,
        reportedDate,
        siteId,
        siteTimezone,
        id,
        taskId,
        type,
      };

      return createErpRequest({ erpRequest: payload, taskId });
    },
    opts,
  );
  return useMemo(
    () => ({ mutate, isLoading, isError, error }),
    [mutate, error, isError, isLoading],
  );
};

export const useCreateBulkErpRequest = ({ onSettled }) => {
  const { mutate, isLoading, isError, error } = useMutation(
    ({ getSiteAssetName, erpFormValues, sourceStatuses, errorCodes, type }) => {
      if (!(getSiteAssetName && erpFormValues)) {
        return () => {};
      }

      const dateTransformer = (value) =>
        dayjs(value).format(DateTimeFormats.ERP_METADATA_DATE_TIME);

      const transformedData = (value) => {
        return dayjs(value).isValid() && dayjs(value).toISOString() === value
          ? dateTransformer(value)
          : value;
      };

      const laborHoursTransformer = (val) => {
        if (isNil(val)) {
          return null;
        }

        if (isEmpty(val)) {
          return null;
        }

        const transformed = Number(val);

        if (isNaN(transformed)) {
          return null;
        }

        return transformed;
      };

      const updateLaborDetails = (_repairHours, _troubleshootingHours, erpMetadata) => {
        const laborDetails = (erpMetadata[ErpBindingPropertyName.LABOR_DETAILS] = []);
        if (is(Number, _repairHours)) {
          laborDetails.push({
            labortype: ErpLaborType.REPAIR,
            quantity: _repairHours,
            rowNumber: 1,
            serviceActivity: ErpDefaultValue.SERVICE_ACTIVITY,
          });
        }
        if (is(Number, _troubleshootingHours)) {
          laborDetails.push({
            labortype: ErpLaborType.TROUBLESHOOTING,
            quantity: _troubleshootingHours,
            rowNumber: 2,
            serviceActivity: ErpDefaultValue.SERVICE_ACTIVITY,
          });
        }
        return laborDetails;
      };

      const typeCheckTechnicians = (_technicians, erpMetadata, type) => {
        if (_technicians && _technicians.length) {
          const { assignedTechEmailIds, techAssignments } = _technicians.reduce(
            (transformedTechnicians, { emailAddress, id: _id }) => {
              transformedTechnicians.assignedTechEmailIds.push(emailAddress);
              transformedTechnicians.techAssignments.push(_id);

              return transformedTechnicians;
            },
            { assignedTechEmailIds: [], techAssignments: [] },
          );

          erpMetadata.assignedTechEmailIds = assignedTechEmailIds;
          erpMetadata.techAssignments = techAssignments;
        } else if (type === ErpType.SERVICE_REQUEST) {
          // this is the default value we're supposed to send if no techs assigned
          erpMetadata.techAssignments = [];
          erpMetadata.assignedTechEmailIds = [];
        }
        return erpMetadata;
      };

      const {
        repairHours,
        troubleshootingHours,
        address,
        instructions,
        needByDate,
        parts,
        phoneNumber,
        priority,
        [ErpCustomFieldType.TECHNICIANS]: _technicians,
        ..._metadata
      } = erpFormValues;

      const metadataDict = Object.entries(_metadata);

      if (!metadataDict.length) {
        return null;
      }

      const metadata = metadataDict.reduce((transformedMetadata, [key, value]) => {
        transformedMetadata[key] = transformedData(value);
        return transformedMetadata;
      }, {});

      const { status: metadataStatus = ErpStatus.OPEN } = sourceStatuses || {};

      let erpMetadata = {
        ...metadata,
        status: metadataStatus,
      };

      const _repairHours = laborHoursTransformer(repairHours);
      const _troubleshootingHours = laborHoursTransformer(troubleshootingHours);
      if (is(Number, _repairHours) || is(Number, _troubleshootingHours)) {
        // eslint-disable-next-line no-unused-vars
        const laborDetails = updateLaborDetails(_repairHours, _troubleshootingHours, erpMetadata);
      }

      if (parts && parts.length) {
        erpMetadata[ErpBindingPropertyName.PART_DETAILS] = parts.map(
          (
            {
              name: partName,
              number: partNumber,
              orderNumber,
              placeOrder: replenish,
              quantity = 0,
              serviceActivity,
            },
            index,
          ) => {
            const partDetail = {
              instructions,
              needByDate: dateTransformer(needByDate),
              orderNumber,
              partName,
              partNumber,
              partQuantity: Number(quantity),
              phoneNumber,
              priority,
              replenish,
              rowNumber: index + 1,
              serviceActivity,
            };

            if (!replenish) {
              delete partDetail.needByDate;
            }

            if (
              address &&
              address.toLocaleLowerCase() === ErpDefaultValue.ADDRESS.toLocaleLowerCase()
            ) {
              partDetail.alternateAddress = '';
              partDetail.shippingAddress = ErpDefaultValue.ADDRESS;
            } else {
              partDetail.alternateAddress = address;
              partDetail.shippingAddress = '';
            }

            return partDetail;
          },
        );
      }
      erpMetadata = typeCheckTechnicians(_technicians, erpMetadata, type);

      const payload = {
        erpErrorMessage: errorCodes,
        items: getSiteAssetName(),
        erpMetadata: erpMetadata,
      };
      return createBulkErpRequest({ bulkErpRequest: payload });
    },
    {
      onSettled: (data, error) => {
        onSettled && onSettled(data, error);
      },
    },
  );
  return useMemo(
    () => ({ mutate, isLoading, isError, error }),
    [mutate, error, isError, isLoading],
  );
};

export const useFetchErpRequestStatus = ({ taskIds }) => {
  const { data } = useQuery(
    [QueryKey.ERP_TASK_STATUS_REQUEST, taskIds],
    async () => await fetchErpRequestStatus({ taskIds }),
    {
      enabled: Boolean(taskIds),
      refetchOnMount: true,
    },
  );
  return { data };
};
