import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { globalColors } from '@ge/tokens/colors';

import { ReactComponent as FourOhOneSVG } from './401.svg';

const Styled403 = styled.div`
  background-color: ${globalColors.grey};
  width: 336px;
  height: 350px;
  margin: auto;
`;

const FourOhThreeComponent = () => {
  return (
    <PageContainer>
      <Styled403>
        <FourOhOneSVG />
      </Styled403>
    </PageContainer>
  );
};

FourOhThreeComponent.propTypes = {
  theme: PropTypes.object,
};

FourOhThreeComponent.defaultProps = {
  theme: {},
};

export const FourOhThree = withTheme(FourOhThreeComponent);
