import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { AlertType, EntityType, QueryKey, TemplateVar } from '@ge/models/constants';
import { delay } from '@ge/shared/util';

import { Config } from './config';
import { PlannedOutageMailTemplate, TemporaryConfigChangeMailTemplate } from './mocks';

// mail template is a separate service from template used to dynamically build alert form
// and current requirement is for mail template to only load when user expands send notification section

// TODO: if we want to change the template var format, need to update in here
// can put this somewhere more central if we need this functionality in other areas
const templateVarFormatter = (val) => `{{${val}}}`;

const templateVarRegex = new RegExp(templateVarFormatter('([^}]+)'), 'g');

// TODO: how do we want to handle failed match, right now just returning original template param as it's pretty conspicuous...
const interpolate = ({ template = '', vars = {} }) =>
  template.replace(templateVarRegex, (match, key) => vars[key] ?? match);

const transformTemplate = ({ entity, entityType, template }) => {
  const vars = {};

  // TODO: add additional template vars in here
  switch (entityType) {
    case EntityType.ASSET:
    case EntityType.TURBINE:
      vars[TemplateVar.ASSET_NAME] = entity.name;
      vars[TemplateVar.SITE_NAME] = entity.site?.name;
      break;
    case EntityType.SITE:
      vars[TemplateVar.SITE_NAME] = entity.name;
      break;
  }

  const { body, header, subject } = template;

  return {
    ...template,
    body: interpolate({ template: body, vars }),
    header: interpolate({ template: header, vars }),
    subject: interpolate({ template: subject, vars }),
  };
};

export const useAlertMailTemplate = ({ alertType, entity, entityType, isActive = true }) => {
  const { data: template, error, isLoading } = useQuery(
    [QueryKey.ALERT_MAIL_TEMPLATE, alertType],
    // TODO: get these template types from backend
    () =>
      delay(
        () =>
          ({
            [AlertType.PLANNED_OUTAGE]: PlannedOutageMailTemplate,
            [AlertType.TEMPORARY_CONFIG_CHANGE]: TemporaryConfigChangeMailTemplate,
          }[alertType]),
        500,
      ),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
    },
  );

  const data = useMemo(
    () => (template ? transformTemplate({ entity, entityType, template }) : undefined),
    [entity, entityType, template],
  );

  return { data, error, isLoading };
};
