import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Textarea } from '@ge/components/textarea/textarea';
import { Text } from '@ge/components/typography';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useContactInfo, useUpdateContactInfo } from '@ge/shared/data-hooks';
import { typography } from '@ge/tokens';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

import { useSSO } from '../../../../features/inspections/data-hooks/use-session';

const ContactFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const ContactDialogSegment = styled.div`
  margin-top: 20px;
  padding: 10px 15px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const ContactDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  div:first-of-type {
    margin-right: 0px;
    width: 450px;
  }
  &:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledLabel = styled.label`
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 20px;
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  margin-bottom: 5px;
`;

const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const ContactScrollingWrapper = styled.div`
  display: flex;
  min-height: 30vh;
  overflow: hidden;
  overflow-wrap: break-word;

  ${scrollbarWrapperOverride}
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  margin-bottom: 5px;
`;

const StyledDescription = styled.span`
  white-space: pre-wrap;
  display: block;
  padding-right: 15px;
`;

const EditButton = styled(
  withTheme(({ theme, ...rest }) => (
    <button {...rest}>
      <Icon icon={Icons.PENCIL} size={11} color={theme.entityDetails.notes.entityInfo} />
    </button>
  )),
)`
  float: right;
`;

export const ContactDialog = ({ entity, siteId, onClose, siteType }) => {
  const { t } = useTranslation(['entity-details']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditError, setShowEditError] = useState(false);
  const { handleSubmit, register } = useForm({
    mode: 'onBlur',
  });
  const { data: sso } = useSSO();

  const entityDetails = {
    siteName: entity?.site?.name || entity?.name,
    siteType: siteType,
  };

  const handleVerifyClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const { data: contactInfo, isLoading } = useContactInfo(siteId);
  const { updateContactInfo } = useUpdateContactInfo(siteId);

  const closeEditMode = useCallback(() => {
    showEditError && setShowEditError(false);
    setIsEditMode(false);
  }, [showEditError]);

  const saveContact = useCallback(
    async (values) => {
      updateContactInfo({
        createdBy: sso,
        desc: values.contactDetails,
        isNew: !contactInfo.length,
      })
        .then(() => closeEditMode())
        .catch(() => setShowEditError(true));
    },
    [updateContactInfo, sso, contactInfo, closeEditMode],
  );

  // Build the footer to pass into the Dialog component.
  const getFooter = useMemo(() => {
    return (
      <ContactFooterWrapper>
        <FooterButtons>
          {isEditMode ? (
            <>
              <Button onClick={closeEditMode}>{t('general:cancel', 'Cancel')}</Button>
              <Button primary onClick={handleSubmit(saveContact)}>
                {t('general:save', 'Save')}
              </Button>
            </>
          ) : (
            <Button primary onClick={handleVerifyClose}>
              {t('general:close', 'Close')}
            </Button>
          )}
        </FooterButtons>
      </ContactFooterWrapper>
    );
  }, [handleVerifyClose, t, isEditMode, handleSubmit, saveContact, closeEditMode]);

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      header={
        isEditMode
          ? t('contact_info.edit_contact_info', 'Edit Contact Info')
          : t('contact_info.contact_info', 'Contact Info')
      }
      footer={getFooter}
      padContent={false}
    >
      <ContactDialogSegment>
        <StyledLabel>{t('contact_info.site', 'Site')}</StyledLabel>
        <AuthRender
          capability={Capability.CONTACTS}
          edit
          description="site contact details edit button"
          siteIds={[siteId]}
        >
          <EditButton onClick={() => setIsEditMode(true)} />
        </AuthRender>
        <EntityDialogHeader entityDetails={entityDetails} />
        <ContactDescription>
          <StyledLabel>{t('contact_info.description', 'Description')}</StyledLabel>
          {isEditMode ? (
            <>
              {showEditError && <ErrorText>{t('contact_info.edit_error_text')}</ErrorText>}
              <Textarea
                id="contact-description-textarea"
                placeholder={t('contact_info.edit_placeholder', 'Type description...')}
                name="contactDetails"
                ref={register}
                defaultValue={contactInfo?.map(({ description }) => description).join('\n')}
                disabled={isLoading}
              />
            </>
          ) : (
            <ContactScrollingWrapper>
              <ScrollingContainer>
                <div>
                  {contactInfo.map((el, index) => (
                    <div key={index}>
                      <StyledDescription>{el.description}</StyledDescription>
                    </div>
                  ))}
                </div>
              </ScrollingContainer>
            </ContactScrollingWrapper>
          )}
        </ContactDescription>
      </ContactDialogSegment>
    </Dialog>
  );
};

ContactDialog.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func,
  siteId: PropTypes.string,
  siteType: PropTypes.string,
};

ContactDialog.defaultValues = {
  onClose: () => null,
};
