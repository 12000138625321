import { useMemo } from 'react';

import { Capability, EntityType, PermissionScope } from '@ge/models/constants';

import { useFeatureSites } from './use-feature-sites';

// defining statically to avoid re-renders
const CAPABILITIES = [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.CREATE] }];

export const useTaskEntityFilter = (entityType, filterText) => {
  const { sites } = useFeatureSites({
    capabilities: CAPABILITIES,
  });

  const sitesFiltered = useMemo(() => {
    if (filterText.length < 2) {
      return sites;
    }
    if (EntityType.SITE === entityType) {
      return sites.filter((site) => site.name?.toLowerCase().includes(filterText));
    } else {
      return sites
        .map((site) => {
          return {
            ...site,
            assets: site.assets.filter((asset) => asset.name?.toLowerCase().includes(filterText)),
          };
        })
        .filter((site) => site.assets.length !== 0);
    }
  }, [entityType, filterText, sites]);

  return { sitesFiltered };
};
