import * as FullStory from '@fullstory/browser';
import { useEffect } from 'react';

import { Capability, DefaultRoleType, UserFullStory } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { getUserRolesFromAccessToken, getUserProfileInfo } from '@ge/shared/services/auth';

/**
 * set logged in user attributes in fullstory to identity user in fullstory
 */
const useAttributesForFullStory = () => {
  const { permissions } = useAuth();
  // Set user attributes in fullstory.
  useEffect(() => {
    const fullStoryLoaded = sessionStorage.getItem('fullStoryLoaded');

    if (fullStoryLoaded !== 'true') {
      FullStory.init({ orgId: '15Y1C8' });

      let userInfo = getUserProfileInfo();
      if (userInfo) {
        let userName = userInfo.preferred_username;
        let domainName = userInfo.email?.replace(/.*@/, '');
        let filteredRoles = [];
        let userEntityGroups = getUserRolesFromAccessToken();
        if (userEntityGroups) {
          filteredRoles =
            userEntityGroups
              ?.filter(
                (roleName) =>
                  roleName !== DefaultRoleType.MONITOR_ADMIN &&
                  roleName !== DefaultRoleType.ANALYTICS_VIEW &&
                  roleName !== DefaultRoleType.MONITOR_VIEW,
              )
              .map((role) => role?.replace(/[_-]/g, ' ')) ?? [];
        }
        let userRoles = filteredRoles?.reduce(
          (role, value, index) => Object.assign(role, { [`Role${index + 1}`]: value }),
          {},
        );

        let internalAccessCapability = Capability.INTERNAL_ACCESS;
        let hasInternalAccess =
          permissions?.[internalAccessCapability.segmentOffering]?.[internalAccessCapability.value];

        sessionStorage.setItem('fullStoryLoaded', 'true');

        FullStory.identify(userName, {
          displayName: userName,
          email: domainName,
          userType: hasInternalAccess ? UserFullStory.INTERNAL : UserFullStory.EXTERNAL,
          reviewsWritten_int: 14,
          ...userRoles,
        });
      }
    }
  }, [permissions]);
};

export default useAttributesForFullStory;
