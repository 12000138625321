import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { AlertSortOrder } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import { AlertHeaderIconMenu } from './alert-header-icon-menu';
import { AlertIconMenu } from './alert-icon-menu';
import { AlertIconTooltip } from './alert-icon-tooltip';

const AlertIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span:not(:first-child) {
    margin-left: 2px;
  }

  & > button,
  & > div {
    margin-left: 3px;
  }
`;

export const AlertTableIcons = ({ alerts = [], containerRef, onClick, isSiteAssetTable }) => {
  const _alerts = [...alerts]?.sort((a, b) => AlertSortOrder[a.type] - AlertSortOrder[b.type]);

  // Get first 2 alerts to diplay outside of count badge.
  // TODO: Will need to ensure alerts are order by priority. Example workerPresent will always need to be outside badge.
  // Need to establish pattern before implmenting here.
  const priorityAlerts = _alerts.splice(0, _alerts.length <= 3 ? 3 : 2);

  // Remaining alerts to be rendered in count badge.
  const remainingAlerts = _alerts;

  return (
    <AlertIconsContainer>
      {priorityAlerts.length > 0 &&
        priorityAlerts?.map((alert, index) => (
          <AlertIconTooltip
            key={alert?.id || index}
            alert={alert}
            onClick={(e, alert) => {
              e.stopPropagation();
              return onClick && onClick(e, alert);
            }}
            large
          />
        ))}
      {remainingAlerts.length > 0 && isSiteAssetTable && (
        <AlertHeaderIconMenu
          alerts={remainingAlerts}
          containerRef={containerRef}
          openAlertDialog={(e, alert) => onClick(e, alert)}
          isSiteAssetTable={isSiteAssetTable}
        >
          <Badge color={globalColors.stone1} label={remainingAlerts.length} small />
        </AlertHeaderIconMenu>
      )}
      {remainingAlerts.length > 0 && !isSiteAssetTable && (
        <AlertIconMenu
          alerts={remainingAlerts}
          containerRef={containerRef}
          openAlertDialog={(e, alert) => onClick(e, alert)}
        >
          <Badge color={globalColors.stone1} label={remainingAlerts.length} small />
        </AlertIconMenu>
      )}
    </AlertIconsContainer>
  );
};

AlertTableIcons.propTypes = {
  alerts: PropTypes.instanceOf(Object).isRequired,
  containerRef: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  isSiteAssetTable: PropTypes.bool,
};
