import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { AssetType } from '@ge/models/constants';

const useAssetSearch = (filterText) => {
  const isAssetsLoading = useStoreState((state) => state.assets.isLoading);
  const isSitesLoading = useStoreState((state) => state.sites.isLoading);

  const getAssets = useStoreState((state) => state.assets.getFilteredAssetsAndSites);
  const getAssetsBySiteIds = useStoreState((state) => state.assets.getAssetsBySiteIds);
  const getSites = useStoreState((state) => state.sites.getFilteredSites);

  const isLoading = isAssetsLoading || isSitesLoading;

  const data = useMemo(() => {
    if (isLoading) return [[], []];

    const sites = getSites(filterText);
    const siteIds = sites.map((site) => site.id);
    if (siteIds.length > 0) {
      const assets = getAssetsBySiteIds(siteIds);
      return [assets, sites];
    }

    return getAssets(filterText, AssetType.WIND_TURBINE);
  }, [isLoading, filterText, getAssets, getSites, getAssetsBySiteIds]);

  return { data, isLoading };
};

export default useAssetSearch;
