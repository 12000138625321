import { useStoreActions } from 'easy-peasy';
import React, { useCallback, useRef } from 'react';

import { Config, useNotification } from '@ge/components/notification';
import { NotificationActionType, NotificationType } from '@ge/models/constants';
import { MutableCommandMessage, MutableCommandStatus } from '@ge/shared/components/notifications';

const getNotifyOptions = ({
  assets,
  command,
  onSuccess,
  onSiteDetails,
  statusId,
  onError,
  notificationRef,
}) => {
  // TODO: figure out if we need additional logic here to ensure all assets are for the same site
  const site = assets[0]?.site;

  const actions = [
    {
      // TODO: get site name from store if not on asset model already
      label: site?.name,
      onClick: () => onSiteDetails(site?.id),
      type: NotificationActionType.SITE_DETAILS,
    },
  ];

  return {
    actions,
    // this defines uniqueness to avoid duplicate banners
    id: `${site?.id}_${statusId}`,
    message: <MutableCommandMessage assets={assets} command={command} statusId={statusId} />,
    persist: true,
    status: (
      <MutableCommandStatus
        assets={assets}
        onSuccess={onSuccess}
        onError={onError}
        statusId={statusId}
      />
    ),
    ref: notificationRef,
  };
};

export const useAssetCommandNotifications = () => {
  // store
  const addCommand = useStoreActions((actions) => actions.assets.addCommand);

  // data hooks
  const { dismiss, notify } = useNotification();

  const notificationRef = useRef(null);

  const commandNotify = useCallback(
    ({ assets, command, onSiteDetails = () => {}, onAssetDetails = () => {}, statusId }) => {
      const isSingleCommand = assets?.length === 1;

      // for single command it issues multiple notifications so progress on execution is queued and tracked in the store
      // since there can be more than one command executing at a time, it is tracked individually at the component level
      if (isSingleCommand) {
        addCommand({ assets, command, statusId, onSiteDetails, onAssetDetails });

        // issues multiple notifications so no key to return
        return null;
      }

      // for multi commands notification it updates in place so treat it as fire-and-forget/event-driven
      let notificationId;

      // auto-dismiss notification on success only
      const onSuccess = () => setTimeout(() => dismiss(notificationId), Config.VISIBLE_DURATION);

      const onError = () => {
        notificationRef?.current?.classList.add(NotificationType.ERROR);
        setTimeout(() => dismiss(notificationId), Config.VISIBLE_DURATION);
      };

      const options = getNotifyOptions({
        assets,
        command,
        onSuccess,
        onSiteDetails,
        statusId,
        onError,
        notificationRef,
      });

      notificationId = notify(options);

      return notificationId;
    },
    [dismiss, notify, addCommand],
  );

  return {
    dismiss,
    notify: commandNotify,
  };
};
