import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnTitle, ColumnDetail, FieldGroup, DisplayLabel } from '../../../task-template-shared';

import { CompletedTechsSection } from './completedtechs-section';

export const EditAssignedTechs = ({
  metadata,
  task,
  workerState,
  setWorkerState,
  removeWorker,
  deleteWrkrIds,
  isLoadingMultiplePerson,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  return (
    <>
      <div className="task-panel-row">
        <ColumnTitle>
          <h4>{t('section_header.assigned_crews', 'Assigned crews')}</h4>
        </ColumnTitle>
        <ColumnDetail className="column-detail">
          <DisplayLabel>{t('section_header.assigned_crews', 'Assigned crews')}</DisplayLabel>
          <FieldGroup className="timing">
            {!metadata?.completedByTech.hidden && (
              <CompletedTechsSection
                modalType="editTask"
                techType="completed"
                section={metadata.completedByTech.edit}
                task={task}
                workerState={workerState}
                setWorkerState={setWorkerState}
                removeWorker={removeWorker}
                deleteWrkrIds={deleteWrkrIds}
                isLoadingMultiplePerson={isLoadingMultiplePerson}
              />
            )}
          </FieldGroup>
        </ColumnDetail>
      </div>
    </>
  );
};

EditAssignedTechs.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  workerState: PropTypes.instanceOf(Object),
  setWorkerState: PropTypes.func,
  removeWorker: PropTypes.func,
  deleteWrkrIds: PropTypes.instanceOf(Object),
  isLoadingMultiplePerson: PropTypes.instanceOf(Boolean).isRequired,
};

EditAssignedTechs.defaultProps = {
  handleTaskType: () => null,
};
