import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { fetchCrews } from '../services/worker';
import { getServiceGroupIds } from '../util/worker-util';

export const useAllCrews = (startDate, endDate, _serviceGroupIds, opts = {}, queryKey) => {
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  const { currentView } = useStoreState((state) => state.view);
  const serviceGroupIds = (
    _serviceGroupIds ?? getServiceGroupIds(sortedServiceGroups, currentView)
  ).toString();

  const { data, isFetching, isError, error } = useQuery(
    [QueryKey.ALL_CREW, { endDate, serviceGroupIds, startDate }, queryKey],
    () => fetchCrews({ endDate, serviceGroupIds, startDate }),
    {
      ...opts,
      enabled: Boolean(serviceGroupIds?.length > 0),
    },
  );

  return useMemo(
    () => ({
      data,
      isFetching,
      isError,
      error,
    }),
    [data, error, isError, isFetching],
  );
};
