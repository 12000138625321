import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { TaskSourceField, EntityType } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { AppScopes } from '@ge/shared/models/scopes';
import { findScopesByRoute } from '@ge/web-client/src/app/services/feature';

import { BuildOption } from '../task-template-shared';

const StyledSelect = styled(Select)`
  text-transform: capitalize;
`;

export const TaskSourceSelect = ({ task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const {
    taskState: { taskSource, taskSources, setTaskSource, setTaskWorkScope },
  } = useContext(TaskContext);

  const { control, setValue } = useFormContext();

  const [typeSelectOptions, setTypeSelectOptions] = useState([{}]);
  const [isSourceDisable, setIsSourceDisable] = useState(false);
  const path = useLocation().pathname;
  const appScope = findScopesByRoute(path);
  const scopePath = AppScopes.MANAGE_CASES_TASKS;
  const scopePathOverview = AppScopes.MANAGE_OVERVIEW;
  // The taskType determines which task template is loaded via a service. This component, that changes
  // the task type, is itself rendered by that template. This gets particularly confusing considering
  // that the taskType can also be set via a prop, but has to be done at a higher level because otherwise
  // the task template service wouldn't know which task template to load in order to ever render this component.
  // So, the form value for taskType is managed here by setValue in useFormContext, and the taskType for the template
  // service is managed via the TaskProvider context at a higher level in the task-template.jsx
  useEffect(() => {
    setValue('taskSource', taskSource);
    if (task?.entType == EntityType.CASE) setIsSourceDisable(true);
  }, [setValue, taskSource, task]);

  useEffect(() => {
    const allTypes = Object.entries(taskSources).map(([key, val]) => BuildOption(key, val));
    let selectOptions;
    if (appScope === scopePath || appScope === scopePathOverview) {
      selectOptions = allTypes.filter((option) => option.value !== TaskSourceField.ANOMALY);
    } else {
      selectOptions = allTypes;
    }
    setTypeSelectOptions(selectOptions);
  }, [taskSources, setTypeSelectOptions, appScope, scopePath, scopePathOverview]);

  const handleTypeSelect = useCallback(
    (option) => {
      setTaskSource(option.value);
      setTaskWorkScope('');
      setTimeout(() => {
        setValue('taskSource', option.value);
      }, 0);
    },
    [setTaskSource, setValue, setTaskWorkScope],
  );

  return (
    <Controller
      control={control}
      defaultValue={taskSource}
      name="taskSource"
      render={(value) => (
        <StyledSelect
          id="taskSourceSelect"
          label={t('form.source', 'Source')}
          onChange={(option) => handleTypeSelect(option)}
          options={typeSelectOptions}
          primary
          value={typeSelectOptions.filter((option) => option.value === value.value)}
          disabled={isSourceDisable}
        />
      )}
    />
  );
};

TaskSourceSelect.propTypes = {
  task: PropTypes.object,
};

TaskSourceSelect.defaultProps = {
  task: {},
};
