import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { DataExplorerLayouts, SortDirection } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

const Layouts = DataExplorerLayouts;

const Legends = styled.div`
  ${({ layout }) => {
    if (layout === Layouts.DETAIL) {
      return css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0px 16px;
      `;
    }
    return css`
      display: block;
      padding: 5px;
      background: linear-gradient(180deg, rgba(28, 37, 45, 0.85) 0%, rgba(22, 22, 23, 0.8) 100%);
    `;
  }}
`;

export const Legend = styled.div`
  display: flex;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  white-space: nowrap;
  &:first-child {
    margin-left: 0;
  }
  strong {
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  &.x-axis {
    flex-shrink: 0;
    margin-left: 8px;
    margin-right: 16px;
    color: ${(props) => props.theme.dataExplorer.charts.xLegendColor};
  }
  &.y-axis {
    flex: 1;
    color: ${(props) => props.theme.dataExplorer.charts.yLegendColor};
    .title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 3px;
    }
  }
  ${({ layout }) =>
    layout === Layouts.DETAIL
      ? css`
          overflow: hidden;
        `
      : css`
          padding: 4px 8px;
        `}
`;

const SignalButton = styled.button.attrs(() => ({ type: 'button' }))`
  color: ${({ visible, theme }) => (!visible ? theme.signal.disabled : 'inherit')};
  font-size: inherit;
  padding: 0px 2px;
  display: flex;
  ${({ layout }) =>
    layout === Layouts.DETAIL
      ? css`
          display: flex;
          overflow: hidden;
        `
      : css`
          flex: 1;
        `}
`;

const LegendIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.SQUARE_LEGEND_ICON,
}))`
  margin-left: 3px;
  margin-right: 3px;
`;

const SignalAssets = styled.span`
  display: flex;
  ${({ layout }) => {
    if (layout === Layouts.DETAIL) {
      return css`
        flex-direction: column;
        > span {
          margin-bottom: 7px;
        }
      `;
    }
    return css`
      margin-left: 5px;
      > span {
        display: flex;
        svg {
          margin: 0 7px;
        }
      }
    `;
  }}
`;

const DataExplorerChartLegends = ({ xAxis, series, layout, onLegendClick }) => {
  const assets = useStoreState((state) => state.assets.assets);

  const yAxisLegends = useMemo(() => {
    return Object.values(
      series.reduce((result, v, index) => {
        if (!result[v.name]) {
          result[v.name] = {
            ...v,
            index,
            color: v.legendColor,
            assets: [
              {
                index,
                color: v.legendColor,
                id: v.assetId,
                name: assets[v.assetId]?.name ?? v.assetId,
                visible: v.visible,
              },
            ],
            title: v.name,
            visible: v.visible,
          };
        } else {
          result[v.name].assets.push({
            index,
            color: v.legendColor,
            id: v.assetId,
            name: assets[v.assetId]?.name ?? v.assetId,
            visible: v.visible,
          });
        }
        return result;
      }, {}),
    ).sort(sorter('yAxis', SortDirection.ASC));
  }, [series, assets]);

  return (
    <Legends layout={layout}>
      {layout !== Layouts.DETAIL && (
        <Legend className="x-axis" layout={layout}>
          <span>
            <strong>X: </strong>
            {xAxis?.title ?? xAxis?.name ?? '-'}
          </span>
        </Legend>
      )}
      {yAxisLegends.map((signal) => (
        <Legend key={signal.title} className="y-axis" layout={layout}>
          <span>
            <strong>{`Y${signal.yAxis ? signal.yAxis + 1 : 1}: `}</strong>
          </span>
          <SignalButton
            onClick={() => onLegendClick(signal)}
            visible={signal.visible}
            layout={layout}
          >
            {signal.assets?.length === 1 && <LegendIcon color={signal.color}></LegendIcon>}
            <span className="title">{signal.title}</span>
          </SignalButton>
          {signal.assets?.length > 1 && (
            <SignalAssets layout={layout}>
              {signal.assets.map((asset) => (
                <SignalButton
                  key={asset.id}
                  onClick={() => onLegendClick(asset)}
                  visible={asset.visible}
                  layout={layout}
                >
                  <LegendIcon color={asset.color}></LegendIcon>
                  {asset.name}
                </SignalButton>
              ))}
            </SignalAssets>
          )}
        </Legend>
      ))}
    </Legends>
  );
};

DataExplorerChartLegends.propTypes = {
  series: PropTypes.array,
  layout: PropTypes.string,
  xAxis: PropTypes.object,
  onLegendClick: PropTypes.func,
};

DataExplorerChartLegends.defaultProps = {
  onLegendClick: () => {},
};

DataExplorerChartLegends.displayName = 'DataExplorerChartLegends';

export default DataExplorerChartLegends;
