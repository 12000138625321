import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { DotsLoader } from '@ge/components/loader';
import { Menu, placements } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import {
  DraggableTable,
  DynamicTable,
  EntityCell,
  ResizableTable,
  TableArrow,
} from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AssetOverviewTabs, DateTimeFormats, EntityType, Placeholders } from '@ge/models/constants';
import { useAnomalyTable } from '@ge/shared/data-hooks/use-anomaly-table';
import { getAssetIcon, killEventPropagation } from '@ge/shared/util';
import { globalColors } from '@ge/tokens/colors/colors';

import { EntityDetailsContext } from '../../context';
import { AnomaliesColumnDefs, AnomaliesColumns } from '../../models/table-col-defs';
import { EscalatedBadge } from '../escalated-badge';
import LinkedTaskMenu from '../linked-task-menu';
import NotesBadge from '../notes-badge';

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.theme.table.iconLightColor,
}))`
  margin-top: 2px;
  margin-right: 4px;
`;

const StyledPossibleRootCause = styled.span`
  display: flex;
  justify-content: space-between;

  h3 {
    display: inline-block;
    margin-right: 40px;
    white-space: nowrap;
  }
`;

const TasksWrapper = styled.div`
  display: flex;
  div:not(:first-child) {
    margin-left: 8px;
  }
`;

const TroubleShootingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ADD,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const NewButton = styled.button`
  border: ${(props) => props.theme.tasks.newButton.border};
  border-radius: 2px;
  background: ${(props) => props.theme.tasks.newButton.background};
  padding: 0;
  height: 20px;
  width: 20px;
`;

const TroubleshootingIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.TROUBLESHOOT,
  color: props.theme.table.troubleshootingIconColor,
  viewbox: '0 0 18 18',
}))`
  vertical-align: initial;
`;

const CaseIcon = styled(Icon).attrs((props) => ({
  size: 11,
  icon: Icons.CASE,
  color: props.theme.table.troubleshootingIconColor,
}))`
  height: 100%;
`;

const IdSpan = styled.span`
  color: ${(props) => props.theme.table.dataTextColorLight};
`;

const StatusSpan = styled.span`
  text-transform: capitalize;
`;

const DescriptionContainer = styled.div`
  display: flex;
  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CaseDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

const HoverText = styled.div`
  padding: 8px 15px;
  max-width: 200px;
  word-break: break-all;
  hyphens: auto;
`;

const Seperator = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 253px;
  border: 1px solid #252c33;
  opacity: 0.5;
`;

const StatusDetailTitle = styled.div`
  padding-left: 10px;
  height: 20px;
  padding-top: 10px;
  width: 244px;
  border-radius: 5px 5px 0 0;
  background-color: #5a6978;
`;

const StatusDetailFooter = styled.div`
  padding-left: 10px;
  height: 20px;
  padding-top: 10px;
  width: 244px;
  border-radius: 5px 5px 0 0;
  color: #b3b3b3;
`;

const ColumnHover = ({ children, value, header, footer }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  if (!value) return null;

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        placement={placements.TOP_START}
      >
        {header}
        <HoverText>{value}</HoverText>
        {footer}
      </Menu>
    </div>
  );
};

ColumnHover.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
};

const AnomalyTable = ({
  columns,
  anomalies,
  sortAction,
  sortMetric,
  sortDirection,
  onCaseSelect,
  onCreateTicket,
  onDrop,
  onFilter,
  onFilterChange,
  scrollable,
  draggable,
  resizable,
  isLoading,
  filterValues,
  filterDefs,
  onNotesSelect,
}) => {
  const { t, ready } = useTranslation(['monitor.issues'], { useSuspense: false });
  const containerRef = useRef(null);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const { entityId, entityType, showSiteDetails, showAssetDetails } =
    useContext(EntityDetailsContext);

  const rowsSelected = useMemo(
    () => (!entityId || !entityType || entityType !== EntityType.CASE ? [] : [entityId]),
    [entityId, entityType],
  );

  const { checkedRows, customHeaderFn, renderCheckbox, renderCaseCloseDialog } = useAnomalyTable({
    anomalies,
  });

  const handleSiteClick = useCallback(
    (e, site) => {
      killEventPropagation(e);
      showSiteDetails(site.id);
    },
    [showSiteDetails],
  );

  const handleAssetClick = useCallback(
    (e, asset) => {
      killEventPropagation(e);
      showAssetDetails(asset.id);
    },
    [showAssetDetails],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilter(columnKey, value);
    },
    [onFilter],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => {
      onFilterChange({ key, value });
    },
    [onFilterChange],
  );

  const buildAnalyzeLink = ({ asset, caseId }) => {
    const base = '/analyze/asset-overview';
    const assetsParam = `assets=${asset?.id}`;
    const caseIdParam = `caseId=${caseId}`;
    const tabParam = `tab=${AssetOverviewTabs.DATA_EXPLORER}`;
    return `${base}?${assetsParam}&${caseIdParam}&${tabParam}`;
  };

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      if (cellValue?.isLoading) {
        return <DotsLoader />;
      }
      switch (columnKey) {
        case AnomaliesColumns.SELECTED:
          return renderCheckbox(cellValue);
        case AnomaliesColumns.SITE:
          return (
            <ColumnHover value={cellValue.description}>
              <EntityCell onClick={(e) => handleSiteClick(e, cellValue)}>
                {cellValue.description}
              </EntityCell>
            </ColumnHover>
          );
        case AnomaliesColumns.DESCRIPTION:
          return (
            <ColumnHover value={cellValue.description}>
              <DescriptionContainer>
                <span>{cellValue.description}</span>
                {cellValue.isParent && <CaseIcon />}
              </DescriptionContainer>
            </ColumnHover>
          );
        case AnomaliesColumns.TYPE:
          return <TypeIcon icon={getAssetIcon(cellValue)} />;
        case AnomaliesColumns.ASSET:
          return (
            <ColumnHover value={cellValue.description}>
              <EntityCell onClick={(e) => handleAssetClick(e, cellValue)}>
                {cellValue.description}
              </EntityCell>
            </ColumnHover>
          );
        case AnomaliesColumns.ID:
          return <IdSpan>{cellValue}</IdSpan>;
        case AnomaliesColumns.STATUS:
          return <StatusSpan>{cellValue}</StatusSpan>;
        case AnomaliesColumns.PRIORITY:
          return cellValue?.description ? <Severity level={cellValue.description} /> : <></>;
        case AnomaliesColumns.NOTES:
          return (
            <NotesBadge
              onClick={(e) => onNotesSelect(e, cellValue)}
              label={cellValue.description}
            />
          );
        case AnomaliesColumns.TROUBLESHOOTING:
          return (
            <TroubleShootingWrapper>
              <NavLink
                to={buildAnalyzeLink(cellValue)}
                onClick={(e) => killEventPropagation(e)}
                className="row-hover-icon"
              >
                <TroubleshootingIcon />
              </NavLink>
            </TroubleShootingWrapper>
          );
        case AnomaliesColumns.LINKED:
          return (
            <TasksWrapper>
              {cellValue.totalTaskCount > 0 && (
                <LinkedTaskMenu caseId={cellValue.caseId} containerRef={containerRef}>
                  <Badge color={globalColors.slate3} label={`${cellValue.totalTaskCount}`} small />
                </LinkedTaskMenu>
              )}
            </TasksWrapper>
          );
        case AnomaliesColumns.ESCALATED:
          return (
            <TasksWrapper>
              <EscalatedBadge
                caseId={cellValue.caseId}
                taskId={cellValue.taskId}
                status={cellValue.status}
                workScope={cellValue.workScope}
                totalTaskCount={cellValue.totalTaskCount}
                containerRef={containerRef}
              />
            </TasksWrapper>
          );
        case AnomaliesColumns.TASK_DESCRIPTION:
          return (
            <ColumnHover value={cellValue}>
              <DescriptionContainer>
                <span>{cellValue}</span>
              </DescriptionContainer>
            </ColumnHover>
          );
        case AnomaliesColumns.STATUS_DETAIL:
          return (
            <ColumnHover
              value={cellValue?.description}
              header={<StatusDetailTitle>{t('status_detail', 'STATUS DETAIL')}</StatusDetailTitle>}
              footer={
                <>
                  <Seperator />
                  <StatusDetailFooter>
                    {t('lastUpdatedOn', 'Last Updated On')}:{' '}
                    {dayjs(cellValue?.value * 1000).format(DateTimeFormats.TASKS_TABLE_DATE_TIME)}
                  </StatusDetailFooter>
                </>
              }
            >
              <DescriptionContainer>
                <span>{cellValue?.description}</span>
              </DescriptionContainer>
            </ColumnHover>
          );
        case AnomaliesColumns.DUE_DATE:
        case AnomaliesColumns.CREATE_DATE:
        case AnomaliesColumns.LAST_FLAGGED:
        case AnomaliesColumns.FIXED_DATE:
          return cellValue ? dayjs(cellValue).format(DateTimeFormats.DEFAULT_DATE) : null;
        // case AnomaliesColumns.START_DATE:
        case AnomaliesColumns.NEW_TASK:
          return (
            <NewButton onClick={(e) => onCreateTicket(e, cellValue)}>
              <AddIcon />
            </NewButton>
          );
        case AnomaliesColumns.POSSIBLE_ROOT_CAUSES:
          return (
            <StyledPossibleRootCause>
              {cellValue.description ?? Placeholders.DOUBLE_DASH}
              {<Badge color={globalColors.slate3} label={`${cellValue.length ?? 0}`} small />}
            </StyledPossibleRootCause>
          );
        case AnomaliesColumns.CASE_DETAIL:
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          return (
            <CaseDetailButton type="button" onClick={(e) => onCaseSelect(e, cellValue)}>
              <TableArrow />
            </CaseDetailButton>
          );
        default:
          return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleAssetClick,
      onNotesSelect,
      handleSiteClick,
      onCaseSelect,
      onCreateTicket,
      renderCheckbox,
    ],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: AnomaliesColumnDefs,
    cellValueMapFn: (anomaly) => anomaly,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
    filterValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
    defaultCellBorder: true,
  });

  const CaseTable = useMemo(() => {
    if (resizable) return ResizableTable;
    if (draggable) return DraggableTable;
    return DynamicTable;
  }, [resizable, draggable]);

  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: anomalies,
    onValueSelect: (e, cellValue) => onCaseSelect(e, cellValue),
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
    isLoading,
    rowsSelected: [...rowsSelected, ...checkedRows],
    draggable,
  };

  return (
    <PageContainer i18nReady={ready}>
      <CaseTable {...dynamicProps} />
      {renderCaseCloseDialog()}
    </PageContainer>
  );
};

AnomalyTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  anomalies: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onCaseSelect: PropTypes.func,
  onCreateTicket: PropTypes.func,
  onDrop: PropTypes.func,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterValues: PropTypes.object,
  filterDefs: PropTypes.object,
  setCaseModalId: PropTypes.func,
  onNotesSelect: PropTypes.func,
};

AnomalyTable.defaultProps = {
  anomalies: [],
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onCaseSelect: () => null,
  onCreateTicket: () => null,
  onDrop: () => null,
  onFilter: () => null,
  onFilterChange: () => null,
  scrollable: true,
  draggable: false,
  resizable: false,
  isLoading: false,
  filterValues: {},
  filterDefs: null,
  onNotesSelect: () => null,
};

export default AnomalyTable;
