import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';

const StyledWrapper = styled.div`
  width: 30px;
  user-select: none;
`;

const StyledBadge = styled(Badge)`
  font-size: 7.5px;
  font-weight: 700;
  border-radius: 12px;
  padding-left: 0;
  padding-right: 0;
  color: ${({ status, theme }) =>
    status ? theme.taskStatusBadge.textColor[status] : theme.taskStatusBadge.textColor.default};
  background: ${({ status, theme }) =>
    status
      ? theme.taskStatusBadge.backgroundColor[status]
      : theme.taskStatusBadge.backgroundColor.default};
`;

export const TaskStatusBadge = ({ label, status, onClick }) => (
  <StyledWrapper onClick={onClick}>
    <StyledBadge status={status} label={label} color={'transparent'} fullWidth />
  </StyledWrapper>
);

TaskStatusBadge.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func,
};

TaskStatusBadge.defaultProps = {
  label: '',
  status: '',
  onClick: () => {},
};
