import { darken } from 'polished';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledWrapper = styled.div`
  background: ${(props) => props.theme.siteStatusBar.backgroundColor};
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.34);
  width: 80px;
  padding: 2px;
  padding: ${(props) => (props.condensed ? '1px' : '2px')};
  border-radius: 3px;
  height: ${(props) => (props.condensed ? '7px' : '16px')};
  display: block;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .issues {
    border-radius: 1px;
    overflow: hidden;
    height: ${(props) => (props.condensed ? '5px' : '13px')};
    width: ${(props) => (props.percent ? 'inherit' : '')};
    display: ${(props) => (props.percent ? 'flex' : '')};
  }
`;

const baseCSS = css`
  height: 100%;
  display: inline-block;
  width: ${(props) => props.scale} ${(props) => (props.percent ? '%' : 'px')};
  vertical-align: top;
`;

const StyledAvailable = styled.div`
  background: ${(props) => props.theme.siteStatusBar.available};
  border-radius: 1px;
  ${baseCSS}
`;

const StyledImpacted = styled.div`
  background: ${(props) => props.theme.siteStatusBar.impacted};
  border-right: solid 1px ${(props) => darken(0.3, props.theme.siteStatusBar.impacted)};
  ${baseCSS}
`;

const StyledTripped = styled.div`
  background: ${(props) => props.theme.siteStatusBar.tripped};
  border-right: solid 1px ${(props) => darken(0.3, props.theme.siteStatusBar.tripped)};
  ${baseCSS}
`;

const StyledStopped = styled.div`
  background: ${(props) => props.theme.siteStatusBar.stopped};
  border-right: solid 1px ${(props) => darken(0.3, props.theme.siteStatusBar.stopped)};
  ${baseCSS}
`;

const StyledNoData = styled.div`
  background: ${(props) => props.theme.siteStatusBar.noData};
  ${baseCSS}
`;

export const SiteStatusBar = ({
  available,
  impacted,
  tripped,
  stopped,
  noData,
  condensed,
  percent,
}) => (
  <StyledWrapper condensed={condensed} percent={percent}>
    <StyledAvailable scale={available} percent={percent} />
    <div className="issues">
      <StyledImpacted scale={impacted} percent={percent} />
      <StyledTripped scale={tripped} percent={percent} />
      <StyledStopped scale={stopped} percent={percent} />
      <StyledNoData scale={noData} percent={percent} />
    </div>
  </StyledWrapper>
);

SiteStatusBar.propTypes = {
  available: PropTypes.number,
  impacted: PropTypes.number,
  tripped: PropTypes.number,
  stopped: PropTypes.number,
  noData: PropTypes.number,
  condensed: PropTypes.bool,
  percent: PropTypes.bool,
};

SiteStatusBar.defaultProps = {
  available: 0,
  impacted: 0,
  tripped: 0,
  stopped: 0,
  noData: 0,
  condensed: false,
  percent: false,
};
