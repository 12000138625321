import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { AssetState } from '@ge/models/constants';
import { useCaseDetailQuery } from '@ge/shared/data-hooks';
import { removeFalsyVals } from '@ge/util/array-utils';

const useCaseDetailDataByQuery = ({ caseId, enabled }) => {
  const { cases, alerts, isLoading } = useCaseDetailQuery(caseId, enabled);
  const sites = useStoreState((state) => state.sites.sites);
  const assets = useStoreState((state) => state.assets.assets);

  const caseWithDetails = useMemo(() => {
    if (isLoading || !cases) return null;
    const panelCase = cases[caseId];

    if (!panelCase) return null;

    const caseAsset = assets[panelCase.asset.id];

    if (!caseAsset) return null;
    const site = sites[caseAsset.site.id];
    // TODO - mocking data for now
    const asset = {
      state: AssetState.ONLINE,
      wind: 12.1,
      temp: 27,
      ...assets[panelCase.asset.id],
    };

    const { childCaseIds = [] } = panelCase;
    const childCases = removeFalsyVals(childCaseIds?.map((caseId) => cases[caseId]) || []);

    const mappedAlerts = [panelCase, ...childCases].reduce(
      (acc, c) => [
        ...acc,
        ...(c?.alertIds?.map((id) => ({ id, ...alerts[id], caseId: c.id })) || []),
      ],
      [],
    );

    return {
      ...panelCase,
      site,
      asset,
      childCaseIds,
      childCases,
      alerts: mappedAlerts,
      tasks: [], // TODO - get from task store once data is linked,
    };
  }, [alerts, assets, caseId, cases, isLoading, sites]);

  return { caseWithDetails, isLoading };
};
export default useCaseDetailDataByQuery;
