import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FlagIcon } from '@ge/components/approval-flag-icon';
import { BoldSearch } from '@ge/components/bold-search';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Text } from '@ge/components/typography';
import { TasksColumns } from '@ge/feat-manage/models/tasks-table-cols';
import { typography } from '@ge/tokens/typography';

const StyledCheckbox = styled(Checkbox)`
  div {
    min-width: 14px;
    max-width: 14px;
  }
  span {
    text-transform: capitalize;
    display: flex;
  }
  &.title {
    span {
      text-transform: none;
    }
  }
  &.show_all {
    padding-bottom: 6px;
    border-bottom: solid 1px ${(props) => props.theme.filterMenu.borderColor};
    padding-top: 9px;
  }
`;

const StyledFlagIcon = styled(FlagIcon)`
  margin-right: 6px;
  margin-top: 2px;
`;

export const FilterCheckbox = ({
  className,
  label,
  value,
  filterText,
  checkState,
  onChange,
  columnKey,
  filterTypeDefs,
}) => {
  const { t, ready } = useTranslation(['general'], { useSuspense: false });

  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

  const inputRef = useRef();

  if (!ready) {
    return null;
  }

  const _label = filterTypeDefs?.useTranslateFn
    ? t(`${filterTypeDefs.a11yKey}.${label}`, label)
    : label;

  return (
    <StyledCheckbox
      className={className}
      label={
        <>
          {approvalFlag && value && columnKey === TasksColumns.FLAG && (
            <StyledFlagIcon value={value} size={11} />
          )}
          <Text type={typography.textTypes.grid} level={2}>
            {filterText ? <BoldSearch text={_label} textBold={filterText} /> : _label}
          </Text>
        </>
      }
      checkState={checkState}
      onChange={() => onChange({ checked: inputRef?.current?.checked, value })}
      ref={inputRef}
      columnKey={columnKey}
      value={value}
    />
  );
};

FilterCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  filterText: PropTypes.string,
  checkState: PropTypes.string,
  onChange: PropTypes.func,
  columnKey: PropTypes.string,
  filterTypeDefs: PropTypes.object,
};

FilterCheckbox.defaultProps = {
  className: '',
  label: '',
  value: '',
  filterText: '',
  checkState: CheckedState.UNCHECKED,
  onChange: () => null,
};

FilterCheckbox.displayName = 'FilterCheckbox';
