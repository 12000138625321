import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { Loader } from '@ge/components/loader';
import { Menu, placements } from '@ge/components/menu';
import { DynamicTable, TableArrow } from '@ge/components/table';
import { DraggableTable, ResizableTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import {
  AnalysisColumns,
  AnalysisColumnsDefs,
  GlobalAnalysisTemplates,
} from '@ge/feat-analyze/models/analysis-template-col';
import AnalysisPlotsMenu from '@ge/shared/components/analysis-plots-menu';
import NotesBadge from '@ge/shared/components/notes-badge';
import { killEventPropagation } from '@ge/shared/util';

import { useAnalysisTemplate } from './use-analysis-template';

const StatusSpan = styled.span``;

const CaseDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

const HoverText = styled.div`
  padding: 8px 15px;
  max-width: 200px;
  word-break: break-all;
  hyphens: auto;
`;

const ColumnHover = ({ children, value }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  if (!value) return null;

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        placement={placements.TOP_START}
      >
        <HoverText>{value}</HoverText>
      </Menu>
    </div>
  );
};

ColumnHover.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
};

export const AnalysisTable = ({
  data,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onSelectTemplate,
  scrollable,
  className,
  onFilter,
  onFilterChange,
  filterValues,
  onDrop,
  draggable,
  resizable,
  filterDefs,
  isLoading,
}) => {
  const { t, ready } = useTranslation(['admin.analysisTable']);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const containerRef = useRef(null);

  const { checkedRows, customHeaderFn, renderCheckbox } = useAnalysisTemplate({
    analysisTemplateData: data,
  });

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilter(columnKey, value);
    },
    [onFilter],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const cellValueMapFn = useCallback((value) => {
    return {
      ...value,
      [AnalysisColumns.SELECTED]: { id: value.id },
      [AnalysisColumns.TEMPLATE_DETAIL]: { id: value.id },
    };
  }, []);

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      if (!cellValue) return null;

      if (columnKey.includes('x')) {
        return <StatusSpan>{cellValue?.name}</StatusSpan>;
      } else if (columnKey.includes('y')) {
        return (
          <StatusSpan>
            <AnalysisPlotsMenu
              containerRef={containerRef}
              title={cellValue?.name}
              tasks={cellValue?.multiOptions}
              menuPlacement={placements.BOTTOM_START}
              isLoading={false}
            >
              {cellValue?.name}{' '}
              {cellValue?.multiOptions?.length > 1 ? (
                <NotesBadge
                  onClick={() => null}
                  label={`+${cellValue?.multiOptions?.length - 1}`}
                />
              ) : (
                ''
              )}
            </AnalysisPlotsMenu>
          </StatusSpan>
        );
      }

      switch (columnKey) {
        case AnalysisColumns.SELECTED:
          return renderCheckbox(cellValue);
        case AnalysisColumns.ANALYSIS_TEMPLATE_ID:
          return <StatusSpan>{cellValue}</StatusSpan>;
        case AnalysisColumns.PLOTS:
          return <StatusSpan>{cellValue}</StatusSpan>;
        case AnalysisColumns.TEMPLATE_DETAIL:
          return (
            <CaseDetailButton type="button" onClick={(e) => onSelectTemplate(e, cellValue)}>
              <TableArrow />
            </CaseDetailButton>
          );
        default:
          return null;
      }
    },
    [renderCheckbox, onSelectTemplate],
  );

  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: AnalysisColumnsDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    filterValues,
    filters: filterDefs,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    defaultCellBorder: true,
  });

  const CaseTable = useMemo(() => {
    if (resizable) return ResizableTable;
    if (draggable) return DraggableTable;
    return DynamicTable;
  }, [resizable, draggable]);

  if (!columns || columns.length === 0) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: data,
    onValueSelect: onSelectTemplate,
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
    rowsSelected: [...checkedRows],
    draggable,
    isLoading,
  };

  return (
    <PageContainer i18nReady={ready}>
      <CaseTable {...dynamicProps} />
    </PageContainer>
  );
};

AnalysisTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onSelectTemplate: PropTypes.func,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
  anomalies: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onCreateTicket: PropTypes.func,
  onDrop: PropTypes.func,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterDefs: PropTypes.object,
  setCaseModalId: PropTypes.func,
  onNotesSelect: PropTypes.func,
};

AnalysisTable.defaultProps = {
  columns: GlobalAnalysisTemplates,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onSelectTemplate: () => null,
  scrollable: true,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
  onCreateTicket: () => null,
  onDrop: () => null,
  draggable: false,
  resizable: false,
  isLoading: false,
  filterDefs: null,
  onNotesSelect: () => null,
};

export default AnalysisTable;
