import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery, useQueries } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { Config } from '../data-hooks';
import useCaseDetailDataByQuery from '../data-hooks/cases/use-case-detail-data-by-query';
import { fetchTasksByTaskIds } from '../services/task';

const useLinkedTaskQuery = ({ caseId, taskIds, enabled = true }) => {
  return useQuery({
    queryKey: [QueryKey.CASE_TASKS, caseId, { taskIds }],
    queryFn: () => fetchTasksByTaskIds(taskIds),
    enabled: Boolean(enabled && taskIds?.length > 0),
    ...Config.EXECUTE_ONCE,
  });
};

const useLinkedTaskMenu = ({ caseId, taskIds = [], enabled }) => {
  const { fetchTasksByTaskIds } = useStoreActions((store) => store.tasks);
  const { getTaskById } = useStoreState((store) => store.tasks);

  const queryResult = useQuery({
    queryKey: [QueryKey.CASE_TASKS, caseId, { taskIds }],
    queryFn: () => fetchTasksByTaskIds(taskIds),
    enabled: Boolean(enabled && taskIds?.length > 0),
    ...Config.EXECUTE_ONCE,
  });

  const tasks = useMemo(
    () =>
      queryResult.isLoading || !queryResult.data
        ? []
        : queryResult.data
            .map(({ id }) => {
              return getTaskById(id);
            })
            .filter((task) => task),
    [getTaskById, queryResult.data, queryResult.isLoading],
  );

  return { tasks, ...queryResult };
};

const getCaseTasks = (caseDetails) => {
  const taskIds = caseDetails?.taskIds ?? [];
  const childTaskIds = caseDetails?.childCases?.map((c) => c.taskIds) ?? [];
  return taskIds.concat(childTaskIds.flat());
};

export const useCaseLinkedTaskIds = ({ caseId, enabled }) => {
  const { caseWithDetails, isLoading } = useCaseDetailDataByQuery({ caseId, enabled });
  const taskIds = useMemo(
    () => (isLoading ? [] : getCaseTasks(caseWithDetails)),
    [caseWithDetails, isLoading],
  );
  return {
    taskIds,
    isLoading,
  };
};

export const useCaseLinkedTasks = ({ caseId, taskId, enabled }) => {
  const { taskIds, isLoading: isCaseDetailLoading } = useCaseLinkedTaskIds({ caseId, enabled });
  const { data, isLoading } = useLinkedTaskQuery({
    caseId,
    taskIds: taskId ? [taskId] : taskIds,
    enabled,
  });

  return {
    taskIds,
    tasks: data,
    isLoading: isLoading || isCaseDetailLoading,
  };
};

export const useCasesTaskIds = ({ cases }) =>
  useMemo(() => {
    const caseTaskIdsMapped = cases.reduce((acc, _case) => {
      acc[_case.id] = getCaseTasks(_case);
      return acc;
    }, {});
    return {
      caseTaskIdsMapped,
      taskIds: Object.values(caseTaskIdsMapped).flat(),
    };
  }, [cases]);

export const useCasesTasks = ({ cases }) => {
  const fetchTasksByTaskIds = useStoreActions((store) => store.tasks.fetchTasksByTaskIds);

  const { caseTaskIdsMapped: caseTaskIds, taskIds } = useCasesTaskIds({ cases });

  const queries = useMemo(
    () =>
      cases.map((_case) => {
        const taskIds = caseTaskIds[_case.id];
        return {
          queryKey: [QueryKey.CASE_TASKS, _case.id, { taskIds }],
          queryFn: () => fetchTasksByTaskIds(taskIds),
          enabled: taskIds?.length > 0,
          ...Config.EXECUTE_ONCE,
        };
      }),
    [caseTaskIds, cases, fetchTasksByTaskIds],
  );

  const results = useQueries(queries);

  const isLoading = results.some((result) => result.isLoading);
  const tasks = useMemo(() => {
    const allTasks = [];
    if (!isLoading) {
      results &&
        results.map((task) => {
          task?.data?.map((data) => {
            allTasks.push(data);
          });
        });
      return allTasks;
    }
  }, [isLoading, results]);
  return {
    tasks,
    taskIds,
    isLoading,
  };
};

export default useLinkedTaskMenu;
