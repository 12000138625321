export const formatDataForDropdown = (data) => {
  return data?.map((option) => {
    return {
      value: option.id,
      label: option.value,
    };
  });
};

export const formatDataForDropdownFromFilterList = (data) => {
  return data?.map((val) => {
    return val?.options?.map((option) => {
      return {
        value: option.id,
        label: option.value,
      };
    });
  });
};

export const findObjectById = (object, key) => {
  if (object.id === key) return object;

  for (let i = 0; i < Object.keys(object).length; i++) {
    const nextObject = object[Object.keys(object)[i]];
    if (nextObject && typeof nextObject === 'object') {
      let o = findObjectById(nextObject, key);
      if (o != null) return o;
    }
  }
  return null;
};

export const findComponentById = (object, key) => {
  if (object.id === key) return object.component;

  for (let i = 0; i < Object.keys(object).length; i++) {
    const nextObject = object[Object.keys(object)[i]];
    if (nextObject && typeof nextObject === 'object') {
      let o = findComponentById(nextObject, key);
      if (o != null) return o;
    }
  }
  return null;
};

/**
 * @function getSolarChartDef
 *
 * Gets SOLAR chart def for the current filter bar state
 *
 * @param {*} chartDefs The chart definitions
 * @param {*} filterBarState The filter bar state
 *
 * @returns The SOLAR chart def
 */
export const getSolarChartDef = (chartDefs = {}, filterBarState = {}) => {
  const { primary, secondary, tertirary } = filterBarState;

  // revisit this if we can have charts for primary only selection
  let def = chartDefs[primary] || {};
  def = tertirary ? (def[secondary] || {})[tertirary] : def[secondary];

  // TODO: how do we want to handle a missing def?

  // TODO: do we still need to define a default here?  if so we should pull it from
  // a more central place (sadly the keys in the defs don't line up with KpiCategoryDefs enum)
  return def || chartDefs.availability.tba;
};
export const formatFilters = (filterList) => {
  let obj = {};
  for (let item of filterList) {
    var key = item.id;
    obj[key] = item.options[0].value;
  }
  return obj;
};
