import styled from 'styled-components';

import { NumberMetaField } from '@ge/shared/components/meta-fields';
import { StatusColor } from '@ge/tokens/colors/colors';

export const SectionContainer = styled.div`
  width: 100%;
`;

export const SectionTopHeading = styled.h4`
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const SectionHeading = styled.h4`
  margin-bottom: 10px;
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  width: 50%;
  align-items: center;
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-left: 40px;

  &:first-child {
    margin-left: 0;
  }

  & label {
    color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
  }
`;

export const SectionTroubleshooting = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledErrorMessage = styled.span`
  color: ${StatusColor.DANGER};
  margin-top: 5px;

  &:before {
    content: '\\2296'; // &ominus;
    font-size: 13px;
    padding-right: 2px;
  }
`;

export const StyledNumberMetaField = styled(NumberMetaField)`
  color: ${(props) => props.theme.entityDetails.tasks.details.inputColor};
  width: 70px;
  height: 25px;
`;
