import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Button } from '@ge/components/button';
import { ConditionalRender } from '@ge/components/conditional-render';
import { DataLoader } from '@ge/components/data-loader';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Select, MenuPlacements } from '@ge/components/select';
import { Severity } from '@ge/components/severity';
import { Textarea } from '@ge/components/textarea/textarea';
import { Capability, DataLoaderType, DateTimeFormats, CaseStatus } from '@ge/models/constants';
import { Placements } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render/auth-render';
import {
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '@ge/shared/components/tasks/task-template-shared';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { useUpdateCase } from '@ge/shared/data-hooks/use-update-case';
import { CaseStatusMenuItems } from '@ge/shared/models/actions-menu-items';
import { globalColors } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors';

import { EntityCollapsiblePanel } from '../entity-details-shared';

const Container = styled.div`
  position: relative;
`;

const FieldWrapper = styled.div`
  width: 17%;
`;

const EditBtnContainer = styled.button`
  position: absolute;
  right: 20px;
  top: 0;
  cursor: pointer;
`;

const DisplayCaseFeild = styled(DisplayField)`
  text-transform: capitalize;
  margin-top: 4px;
  padding-bottom: 13px;
  line-height: 13px;
  font-size: 13px;
  line-height: 18px;
`;

const DisplayCaseTitleValues = styled(DisplayField)`
  margin-top: 7px;
  padding-bottom: 17px;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  width: 480px;
`;

const DisplayCaseDescriptionValues = styled(DisplayField)`
  margin-bottom: 17px;
  font-size: 13px;
  line-height: 18px;
  height: auto;
  max-height: 72px;
  width: 702.11px;
  overflow: clip;
`;

const EditButton = styled(Icon).attrs((props) => ({
  size: 14,
  color: props.theme.entityDetails.cases.details.iconColor,
  icon: Icons.PENCIL,
}))``;

const descriptionStyles = css`
  color: #f2f2f2;
  font-family: 'Museo Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

const DescriptionContainer = styled.div`
  padding: 10px 20px 0 20px;
  position: relative;
  overflow: visible;
`;

const DescriptionEditContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.entityDetails.cases.details.rowBorder};
  ${'' /* padding: 10px 20px 0 20px; */}
  position: relative;
  height: auto;
  min-height: 100% !important;
  overflow: visible;
`;

const PaddingElement = styled.div`
  padding-bottom: 26px;
`;

const StyledTitleTextArea = styled(Textarea)`
  ${descriptionStyles};
  box-sizing: border-box;
  height: 28px;
  width: 519px;
  border: 1px solid #2a323b;
  border-radius: 2px;
  background-color: #1c252d;
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  resize: none;
  classname: customscroll-track;
  overflow: hidden;
  margin-bottom: 17px;
  ${({ error }) => !!error && `border: 1px solid ${globalColors.red1};`}
`;

const StyledStatusDetailTextArea = styled(Textarea)`
  ${descriptionStyles};
  box-sizing: border-box;
  height: 28px;
  width: 727px;
  border: 1px solid #2a323b;
  border-radius: 2px;
  background-color: #1c252d;
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  resize: none;
  classname: customscroll-track;
  overflow: hidden;
  margin-bottom: 17px;
  ${({ error }) => !!error && `border: 1px solid ${globalColors.red1};`}
`;

const StyledTextAreaDescription = styled(Textarea)`
  ${descriptionStyles};
  box-sizing: border-box;
  height: 70px;
  width: 727px;
  border: 1px solid #2a323b;
  border-radius: 2px;
  background-color: #1c252d;
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  resize: none;
  overflow: auto;
  margin-bottom: 17px;

  ${({ error }) => !!error && `border: 1px solid ${globalColors.red1};`}
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    height: 37.16px;
    width: 2px;
    border-radius: 2.5px;
    background-color: #445a6c;
  }
  &::-webkit-scrollbar-track {
    height: 68px;
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-bottom: 20px;

  button:first-of-type {
    margin-right: 10px;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 3px;
  color: ${globalColors.slate4};
  font-style: italic;
  font-size: 13px;
  width: 120px;
  height: 25px;
`;

const CalendarIconContainer = styled.div`
  position: absolute;
  bottom: 9px;
  right: 4px;
`;

const CalendarIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
  icon: Icons.CALENDAR,
  size: 10,
}))`
  margin-right: 4px;
`;

const FieldError = styled.div`
  color: ${StatusColor.DANGER};
  margin-top: -10px;
`;

export const CaseDescription = ({ caseDetails, isLoading }) => {
  const { t } = useTranslation(['entity-details']);

  const [snoozeDate, setSnoozeDate] = useState();
  const getAuthorizedMenuItems = useActionsMenu({ menuItems: CaseStatusMenuItems });
  const formattedSnoozeDate = useMemo(
    () =>
      snoozeDate
        ? dayjs(snoozeDate).format(DateTimeFormats.SNOOZE_DATE_FORMAT)
        : caseDetails.snoozeDate
        ? dayjs(caseDetails.snoozeDate).format(DateTimeFormats.SNOOZE_DATE_FORMAT)
        : null,
    [caseDetails.snoozeDate, snoozeDate],
  );

  const [isEditing, setIsEditing] = useState(false);
  const [triggerHeight, setTriggerHeight] = useState(false);
  const { mutate: updateCase, isLoading: isCaseUpdating } = useUpdateCase();
  const [selectedStatus, setCaseStatus] = useState({
    value: caseDetails.status.toUpperCase(),
    label: caseDetails.status
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' '),
  });
  const [selectedPriority, setCasePriority] = useState({
    value: caseDetails.priority?.toLowerCase(),
    label: (
      <>
        <Severity level={caseDetails.priority?.toLowerCase()} />
        <span className="option-w-icon">{caseDetails.priority?.toLowerCase()}</span>
      </>
    ),
  });
  const [RenderSnoozeDatePicker, setRenderSnoozeDatePicker] = useState(
    caseDetails.status === CaseStatus.SNOOZED ? true : false,
  );

  const { errors, handleSubmit, register } = useForm({
    mode: 'onChange',
  });

  const PriorityOptions = [
    { immediate: 'immediate' },
    { high: 'high' },
    { medium: 'medium' },
    { low: 'low' },
  ];
  const prioritySelectOptions = PriorityOptions.flatMap((priority) =>
    Object.entries(priority).map(([key, value]) => ({
      value: key,
      label: (
        <>
          <Severity level={key} />
          <span className="option-w-icon">{value}</span>
        </>
      ),
    })),
  );

  const onChange = (date) => {
    setSnoozeDate(date);
  };

  const minDate = useMemo(() => dayjs().add(1, 'day').toDate(), []);

  const statusMenuItemsFormatted = Object.values(getAuthorizedMenuItems([caseDetails])).map(
    (statusMenuItems) => ({
      value: statusMenuItems.title.toUpperCase(),
      label: statusMenuItems.title
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' '),
    }),
  );

  const handleStatusSelect = (label) => {
    setCaseStatus({ value: label.toUpperCase(), label: label });
    setRenderSnoozeDatePicker(label === 'Snooze' ? true : false);
  };

  const handlePrioritySelect = (label) => {
    setCasePriority({ value: label.props.children[1].props.children.toLowerCase(), label: label });
  };

  const toggleIsEditing = useCallback(() => {
    setIsEditing((editing) => !editing);
  }, []);

  const StyledSelect = styled(Select)`
    text-transform: capitalize;
    margin-right: 20px;
    width: 204px;
    height: 25px;
    font-size: 13px;
    line-height: 15px;
    radius: 4px;
    position: relative;
  `;

  const onSubmit = useCallback(
    (values) => {
      updateCase({
        id: caseDetails.id,
        asset: {
          id: caseDetails.asset.id,
        },
        body: values.caseDescription?.trim(),
        description: values.caseTitle,
        snoozeDate: snoozeDate ? snoozeDate.getTime() / 1000 : null,
        status: selectedStatus.label.toUpperCase(),
        priority: selectedPriority.value.toLowerCase(),
        statusDetails: {
          statusDetail: values.statusDetail,
        },
      });
      setIsEditing(false);
    },
    [
      caseDetails.asset.id,
      caseDetails.id,
      selectedPriority.value,
      selectedStatus.label,
      snoozeDate,
      updateCase,
    ],
  );

  useEffect(() => {
    setTriggerHeight((t) => !t);
  }, [isCaseUpdating, isEditing, isLoading]);

  return (
    <Container>
      <AuthRender
        capability={Capability.CASES_RECOMMENDATIONS}
        edit
        description="Edit case description button"
        siteLevel={false}
      >
        {!isEditing && !isCaseUpdating && (
          <EditBtnContainer onClick={toggleIsEditing}>
            <EditButton />
          </EditBtnContainer>
        )}
      </AuthRender>
      <EntityCollapsiblePanel
        headerContent={<h2>{t('case_panel.case_details', 'Case Detail')}</h2>}
        expanded={true}
        triggerHeight={triggerHeight}
      >
        <DataLoader type={DataLoaderType.TABLE} isLoading={isCaseUpdating}>
          <DescriptionContainer>
            {isEditing ? (
              <DescriptionEditContainer>
                <DisplayLabel>{t('Title*')}</DisplayLabel>
                <StyledTitleTextArea
                  id="case-title-textarea"
                  placeholder={t('case_panel.description_placeholder', 'Add a  description')}
                  name="caseTitle"
                  defaultValue={caseDetails.description}
                  ref={register({ maxLength: 1000 })}
                  error={errors.caseDescription}
                />
                <DisplayLabel>{t('Description')}</DisplayLabel>
                <StyledTextAreaDescription
                  id="case-description-textarea"
                  placeholder={t('case_panel.description_placeholder', 'Add a description')}
                  name="caseDescription"
                  defaultValue={caseDetails.body}
                  ref={register({ maxLength: 1000 })}
                  error={errors.caseDescription}
                />
                <FieldGroup>
                  <div className="field-wrapper">
                    <DisplayLabel>{t('Priority')}</DisplayLabel>
                    <StyledSelect
                      name="casepriority"
                      onChange={(option) => handlePrioritySelect(option.label)}
                      options={prioritySelectOptions}
                      value={selectedPriority}
                      minWidth={150}
                      marginRight={20}
                      menuPlacement={MenuPlacements.TOP}
                    />
                  </div>
                  <div className="field-wrapper">
                    <DisplayLabel>{t('Status')}</DisplayLabel>
                    <StyledSelect
                      name="casestatus"
                      onChange={(option) => handleStatusSelect(option.label)}
                      options={statusMenuItemsFormatted}
                      value={selectedStatus}
                      minWidth={150}
                      marginRight={20}
                      menuPlacement={MenuPlacements.TOP}
                    />
                  </div>
                  <ConditionalRender shouldRender={RenderSnoozeDatePicker}>
                    <div className="field-wrapper">
                      <DatePicker
                        name="snoozedate"
                        selected={snoozeDate}
                        startDate={snoozeDate}
                        minDate={minDate}
                        onChange={onChange}
                        popperPlacement={Placements.LEFT}
                        customInput={
                          <div>
                            <StyledInput
                              autoComplete="off"
                              label={t('case_panel.snooze_until', 'Snooze until')}
                              onChange={() => null}
                              required
                              value={formattedSnoozeDate}
                            />
                            <CalendarIconContainer>
                              <CalendarIcon />
                            </CalendarIconContainer>
                          </div>
                        }
                      />
                      {/* <PaddingElementforEditScreen /> */}
                    </div>
                  </ConditionalRender>
                </FieldGroup>
                <DisplayLabel>{t('case_panel.status_detail', 'Status Detail')}</DisplayLabel>
                <StyledStatusDetailTextArea
                  id="case-statusDetail-textarea"
                  placeholder={t(
                    'case_panel.statusDetail_placeholder',
                    'Type status detail here...',
                  )}
                  name="statusDetail"
                  defaultValue={caseDetails?.statusDetails?.statusDetail}
                  ref={register({
                    maxLength: {
                      value: 200,
                      message: 'Maximum 200 characters are allowed.',
                    },
                  })}
                  error={errors.statusDetail}
                />
                {errors?.statusDetail?.message && (
                  <FieldError>{errors.statusDetail.message}</FieldError>
                )}
                <ActionButtons>
                  <Button onClick={toggleIsEditing}>{t('general:cancel', 'Cancel')}</Button>
                  <Button primary onClick={handleSubmit(onSubmit)}>
                    {t('general:save', 'Save')}
                  </Button>
                </ActionButtons>
              </DescriptionEditContainer>
            ) : (
              <>
                <DescriptionEditContainer>
                  <DisplayLabel>{t('Title')}</DisplayLabel>
                  <DisplayCaseTitleValues>{caseDetails.description}</DisplayCaseTitleValues>
                  <DisplayLabel>{t('Description')}</DisplayLabel>
                  <DisplayCaseDescriptionValues>{caseDetails.body}</DisplayCaseDescriptionValues>
                  <FieldGroup>
                    <FieldWrapper className="field-wrapper">
                      <DisplayLabel>{t('Priority')}</DisplayLabel>
                      <DisplayCaseFeild>{caseDetails.priority?.toLowerCase()}</DisplayCaseFeild>
                    </FieldWrapper>
                    <FieldWrapper className="field-wrapper">
                      <DisplayLabel>{t('Status')}</DisplayLabel>
                      <DisplayCaseFeild>{caseDetails.status?.toLowerCase()}</DisplayCaseFeild>
                    </FieldWrapper>
                    <ConditionalRender shouldRender={caseDetails.snoozeDate != null}>
                      <FieldWrapper className="field-wrapper">
                        {selectedStatus?.value !== 'SNOOZE' ? (
                          ''
                        ) : (
                          <>
                            <DisplayLabel>
                              {t('case_panel.snooze_until', 'Snooze Until')}
                            </DisplayLabel>
                            <DisplayCaseFeild>
                              {dayjs(caseDetails.snoozeDate)?.format(
                                DateTimeFormats.SNOOZE_DATE_FORMAT,
                              )}
                            </DisplayCaseFeild>
                          </>
                        )}
                      </FieldWrapper>
                    </ConditionalRender>
                  </FieldGroup>
                  <DisplayLabel>
                    {t('case_panel.status_detail', 'Status Detail')}{' '}
                    {caseDetails?.statusDetails?.lastUpdatedDate &&
                      `(Last Updated On: ${dayjs(
                        caseDetails?.statusDetails?.lastUpdatedDate * 1000,
                      ).format(DateTimeFormats.ISSUE_DETAIL_DATE_TIME)})`}
                  </DisplayLabel>
                  <DisplayCaseTitleValues>
                    {caseDetails?.statusDetails?.statusDetail}
                  </DisplayCaseTitleValues>
                  <PaddingElement />
                </DescriptionEditContainer>
              </>
            )}
          </DescriptionContainer>
        </DataLoader>
      </EntityCollapsiblePanel>
    </Container>
  );
};

CaseDescription.propTypes = {
  caseDetails: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
