import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { DraggableTable, DynamicTable } from '@ge/components/table';

import { TableResizableContext } from './resizable-table-context';

/**
 * Resizable enabled DynamicTable that provides context and state management
 * for resizing of user-defined column.
 */
export const ResizableTable = ({ draggable, ...props }) => {
  const [columnKey, setColumnKey] = useState('');
  const [groupKey, setGroupKey] = useState('');

  const updateColumn = useCallback((id, groupId) => {
    setColumnKey(id);
    setGroupKey(groupId);
  }, []);

  const value = {
    isResizing: Boolean(columnKey),
    columnKey,
    groupKey,
    updateColumn,
  };

  return (
    <TableResizableContext.Provider value={value}>
      {draggable ? (
        <DraggableTable {...props} resizable={true} />
      ) : (
        <DynamicTable {...props} resizable={true} />
      )}
    </TableResizableContext.Provider>
  );
};

ResizableTable.propTypes = {
  ...DynamicTable.propTypes,
  draggable: PropTypes.bool,
};
