import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Table, Tbody, Td, Thead } from '@ge/components/table';
import { useEventMaps } from '@ge/shared/data-hooks/use-handling-procedure';

import { DEFAULT_EVENT_PROPS, HLP_ACTIONS } from './hp-common';
import EventEditTable from './hp-edit-table-row';
import {
  AddRow,
  HandlingProcedureFormSection,
  HeaderWrapper,
  RecipientsWrapper,
  StyledTableHeader,
  THWrap,
  ErrorMsg,
} from './hp-styled-components';

export const HandlingProcedureTable = withTheme((props) => {
  const { action, theme, hasDuplicateRecords, setHasDuplicateRecords } = props;
  const { t } = useTranslation(['admin.configure']);
  const { control } = useFormContext();
  const { getEventsMaps } = useEventMaps();
  const { fields, append, remove } = useFieldArray({ control, name: 'eventsProp' });

  const onAppend = () => {
    append(DEFAULT_EVENT_PROPS);
  };
  /**
   * @view table
   */
  const renderViewTable = useCallback(() => {
    return (
      <RecipientsWrapper>
        <div className="table-block-cls">
          <Table height={'600'} compressed>
            <Thead transparent>
              <tr>
                <THWrap minWidth="120px">
                  <span>{t('Handling_procedures.event_map', 'Event Map')}</span>
                </THWrap>
                <THWrap minWidth="120px">
                  <span>{t('Handling_procedures.event_code', 'Event Code')}</span>
                </THWrap>
                <THWrap minWidth="120px">
                  <span>{t('Handling_procedures.event_name', 'Event Name')}</span>
                </THWrap>
              </tr>
            </Thead>
            <Tbody>
              {fields.map((event) => {
                return (
                  <tr key={event.id}>
                    <Td align="left" title={event?.EventMap?.label}>
                      {event?.EventMap?.label}
                    </Td>
                    <Td align="left">{event?.EventCode?.label}</Td>
                    <Td align="left" title={event.name}>
                      {event.name}
                    </Td>
                  </tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      </RecipientsWrapper>
    );
  }, [fields, t]);

  /**
   * @edit table
   */
  const renderEditTable = useCallback(() => {
    const eventMapOptions = getEventsMaps();
    return (
      <>
        {fields.map((field, index) => {
          return (
            <EventEditTable
              key={field.id}
              index={index}
              remove={() => remove(index)}
              control={control}
              eventMapOptions={eventMapOptions}
              totalFields={fields.length}
              setHasDuplicateRecords={setHasDuplicateRecords}
              hasDuplicateRecords={hasDuplicateRecords}
              updatedEventProps={field}
            />
          );
        })}
      </>
    );
  }, [control, fields, getEventsMaps, hasDuplicateRecords, remove, setHasDuplicateRecords]);

  return (
    <HandlingProcedureFormSection>
      <HeaderWrapper>
        <StyledTableHeader view={action === HLP_ACTIONS.VIEW}>
          {t('Handling_procedures.assigned_events')}
        </StyledTableHeader>
        {action === HLP_ACTIONS.VIEW ? null : (
          <AddRow onClick={onAppend}>
            <Icon icon={Icons.ADD} size={10} color={theme.button.primary.textColor} />
            <label>{t('Handling_procedures.add_event', 'Add Event')}</label>
          </AddRow>
        )}
      </HeaderWrapper>
      {hasDuplicateRecords && (
        <ErrorMsg>
          {t(
            'Handling_procedures.duplicate_error_msg',
            'Following event codes already have handling procedure configured',
          )}{' '}
          : {hasDuplicateRecords}
        </ErrorMsg>
      )}
      {action === HLP_ACTIONS.VIEW ? renderViewTable() : renderEditTable()}
    </HandlingProcedureFormSection>
  );
});
