import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

import { ViewSelector } from './view-selector';

const Container = styled.div`
  border-right: 1px solid ${globalColors.slate2};
  margin-right: 20px;
  padding-right: 20px;
`;

const StyledButton = styled.button`
  padding: 0;
  color: ${(props) => props.theme.themeSelector.toggleTextColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  h4 {
    font-weight: 700;
    margin-right: 10px;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .chevron {
    margin-left: 10px;
    transition: transform 0.6s ease;
    transform: rotate(0deg);
    &.active {
      transform: rotate(-180deg);
    }
  }
  .type-icon {
    margin-right: 6px;
  }
`;

const ViewSelectorStatusComponent = ({ ...props }) => {
  const {
    theme: { themeSelector },
  } = props;
  const { t } = useTranslation(['nav', 'general']);

  const { currentView } = useStoreState((state) => state.view);
  const { getSiteById } = useStoreState((state) => state.sites);
  const { getCombinedData } = useStoreActions((actions) => actions.rocStations);

  const [isActive, setIsActiveState] = useState(false);

  /**
   * Render a list of selected sites for top nav bar.
   */
  const getViewShortname = useCallback(() => {
    if (!currentView.sites || Object.values(currentView.sites).length === 0) {
      return t('view_selector.select', 'Select');
    }

    const fullSites = currentView.sites.map((site) => getSiteById(site.id));

    // If the sites haven't loaded yet, the getSiteById() will return undefined.
    if (fullSites.includes(undefined)) {
      return null;
    }

    const maxSiteNameLength = 20;
    const sortedSites = fullSites.sort((a, b) => a.name?.localeCompare(b.name));
    const firstSlice = sortedSites.slice(0, 3);
    const hiddenCount = sortedSites.length - firstSlice.length;
    const hiddenStr = hiddenCount > 0 ? ` ${t('general:and_n_more', { count: hiddenCount })}` : '';

    return `${firstSlice
      .map((site) =>
        site.name && site.name.length > maxSiteNameLength
          ? `${site.name.substring(0, maxSiteNameLength)}...`
          : site.name,
      )
      .join(' / ')}${hiddenStr}`;
  }, [currentView, getSiteById, t]);

  useEffect(() => {
    if (isActive) {
      getCombinedData();
    }
  }, [isActive, getCombinedData]);

  return (
    <Container>
      <StyledButton onClick={() => !isActive && setIsActiveState(true)} type="button" tabIndex="0">
        <Icon
          icon={Icons.SITE}
          size={14}
          color={themeSelector.toggleTypeIcon}
          className="type-icon"
        />
        {getViewShortname()}
        <Icon
          icon={Icons.CHEVRON}
          size={12}
          color={themeSelector.toggleChevronColor}
          className={`chevron ${isActive && 'active'}`}
        />
      </StyledButton>

      {/* Inject the view selector via portal */}
      {isActive && <ViewSelector onClose={() => setIsActiveState(false)} />}
    </Container>
  );
};

ViewSelectorStatusComponent.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
};

export const ViewSelectorStatus = withTheme(ViewSelectorStatusComponent);
