import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { elevations, globalColors } from '@ge/tokens';

const Container = styled.div`
  z-index: ${elevations.P2};
  background-color: ${globalColors.red1};
  height: 42px;
  width: 1100px;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  bottom: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & .message-files-not-uploaded,
  .message,
  .message-timestamp {
    color: ${globalColors.white};
    font-family: 'Museo Sans';
  }

  & .message-files-not-uploaded {
    height: 15px;
    font-size: 12px;
    position: relative;
    right: 20px;
  }

  & .message {
    height: 18px;
    font-size: 14px;
  }

  & .message-timestamp {
    font-size: 12px;
  }
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.dialog.iconColor,
}))`
  display: inline-block;
  position: relative;
  left: 30px;
  cursor: pointer;
`;

export const TaskUploadErrorNotification = ({
  interval,
  onClose,
  setShowNotification,
  filesUploaded,
  message,
}) => {
  const { t } = useTranslation(['tasks']);

  const timestamp = () => {
    const day = new Date().toJSON().slice(8, 10);
    const month = new Date().toString().slice(4, 7);
    const year = new Date().toJSON().slice(2, 4);
    const time = new Date().toString().slice(16, 21);
    const timezone = new Date()
      .toLocaleTimeString('en-us', {
        timeZoneName: 'short',
      })
      .split(' ')[2];

    return `${time} (${timezone}), ${day} ${month} ${year}`;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotification(false);
    }, interval);

    return () => {
      clearTimeout(timer);
    };
  }, [interval, setShowNotification]);

  return (
    <Container>
      <span className="message-files-not-uploaded">
        {t('tasks:task_upload_error', 'Error, tasks not uploaded')}: {filesUploaded}
      </span>
      <span className="message">{message}</span>
      <div className="message-right">
        <span className="message-timestamp">{timestamp()}</span>
        <span onClick={() => onClose()}>
          <CloseIcon icon={Icons.CLOSE} size={10} />
        </span>
      </div>
    </Container>
  );
};

TaskUploadErrorNotification.propTypes = {
  interval: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  setShowNotification: PropTypes.func.isRequired,
  filesUploaded: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};

TaskUploadErrorNotification.defaultProps = {
  interval: 4000,
};
