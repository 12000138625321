import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { killEventPropagation } from '@ge/shared/util/general';

import { FilterCheckbox } from './filter-checkbox';

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.fileUpload.errorTextColor};
  padding-bottom: 10px;
`;

const FilterInput = styled.input`
  height: 22px;
  padding-right: 25px;
  width: calc(100% - 31px);
  color: ${(props) => props.theme.filterMenu.textColor};
  font-size: 12px;
  line-height: 15px;
  border: 1px solid ${(props) => props.theme.filterMenu.inputBorderColor};
  background: transparent;
  border-radius: 2px;
  background-color: ${(props) => props.theme.filterMenu.inputBackgroundColor};
`;

const StyledUl = styled.ul`
  position: relative;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const SearchIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SEARCH,
  color: props.theme.filterMenu.searchIcon,
}))``;

export const FilterCheckboxes = forwardRef(
  (
    {
      filterValues,
      onChange,
      value,
      className,
      enableShowAll,
      maxFilteredValues,
      columnKey,
      filterTypeDefs,
    },
    inputRef,
  ) => {
    const { t, ready } = useTranslation(['general'], {
      useSuspense: false,
    });

    const [filterText, setFilterText] = useState('');
    const parentRef = useRef();

    const { filteredValue, filteredValues } = useMemo(() => {
      if (filterText) {
        return {
          filteredValue:
            value?.filter((val) => val?.toLowerCase().includes(filterText.toLowerCase())) ?? [],
          filteredValues:
            filterValues?.filter((filterValue) =>
              filterValue?.toLowerCase().includes(filterText.toLowerCase()),
            ) ?? [],
        };
      }
      return {
        filteredValue: value ?? [],
        filteredValues: filterValues?.filter((filterValue) => filterValue) ?? [],
      };
    }, [filterText, value, filterValues]);

    const handleChange = useCallback(
      ({ value: updateValue }) => {
        if (!value?.length) {
          onChange([updateValue]);

          return;
        }
        const hide = value.includes(updateValue);
        const updateValues = hide
          ? value.filter((key) => key !== updateValue)
          : [...value, updateValue];

        onChange(updateValues);
      },
      [value, onChange],
    );

    const handleSelectAll = useCallback(
      ({ checked }) => {
        if (checked) {
          onChange(filteredValues);
          return;
        }
        onChange([]);
      },
      [filteredValues, onChange],
    );

    const items = useMemo(() => {
      return maxFilteredValues > 0 ? filteredValues.slice(0, maxFilteredValues) : filteredValues;
    }, [maxFilteredValues, filteredValues]);

    const rowVirtualizer = useVirtual({
      size: items.length,
      estimateSize: React.useCallback(() => 25, []),
      parentRef,
      overscan: 20,
    });

    const itemsArr = rowVirtualizer.virtualItems;
    const paddingTop = itemsArr.length > 0 ? itemsArr[0].start : 0;
    const paddingBottom =
      items.length > 0 ? rowVirtualizer.totalSize - itemsArr[itemsArr.length - 1]?.end : 0;

    if (!ready) {
      return null;
    }

    return (
      <>
        <div className="filter-input">
          <FilterInput
            type="text"
            onKeyDown={(e) => killEventPropagation(e)}
            onChange={(e) => {
              killEventPropagation(e);
              setFilterText(e.target.value);
            }}
            ref={inputRef}
          />
          <SearchIcon />
        </div>
        <div className="header-checkbox">
          {enableShowAll && (
            <FilterCheckbox
              className={'show_all'}
              label={t('filter_menu.show_all', 'Show All')}
              checkState={
                filteredValue.length > 0 && filteredValue.length === filteredValues.length
                  ? CheckedState.CHECKED
                  : CheckedState.UNCHECKED
              }
              onChange={handleSelectAll}
            />
          )}
        </div>
        <StyledUl paddingTop={paddingTop} paddingBottom={paddingBottom} ref={parentRef}>
          {itemsArr.map(({ index, measureRef }) => {
            const filterValue = items[index];
            return (
              <li ref={measureRef} key={filterValue}>
                <FilterCheckbox
                  key={filterValue}
                  className={className}
                  label={filterValue}
                  value={filterValue}
                  filterText={filterText}
                  checkState={
                    value?.includes(filterValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                  }
                  onChange={handleChange}
                  columnKey={columnKey}
                  filterTypeDefs={filterTypeDefs}
                />
              </li>
            );
          })}
        </StyledUl>
        {items.length < filteredValues.length ? (
          <StyledErrorMessage>
            {t('filter_menu.too_many_results', 'Too many results. Please narrow your search')}
          </StyledErrorMessage>
        ) : null}
      </>
    );
  },
);

FilterCheckboxes.propTypes = {
  className: PropTypes.string,
  filterValues: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  enableShowAll: PropTypes.bool,
  maxFilteredValues: PropTypes.number,
  columnKey: PropTypes.string,
  filterTypeDefs: PropTypes.object,
};

FilterCheckboxes.defaultProps = {
  className: '',
  value: [],
  onChange: () => null,
  enableShowAll: false,
  maxFilteredValues: null,
};
