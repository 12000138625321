import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Severity } from '@ge/components/severity';
import { TaskPriority } from '@ge/models/constants';

import { SelectMetaField } from '../../meta-fields/select-meta-field';

export const PrioritySelect = ({ metadata, values, defaultSelection }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const prioritySelectOptions = values.flatMap((priority) =>
    Object.entries(priority).map(([key, value]) => ({
      value: key,
      label: (
        <>
          <Severity level={key} />
          <span className="option-w-icon">{value}</span>
        </>
      ),
    })),
  );

  return (
    <SelectMetaField
      defaultValue={defaultSelection ?? TaskPriority.MEDIUM}
      name="priority"
      label={t('form.priority', 'Priority')}
      metadata={metadata}
      minWidth={130}
      options={prioritySelectOptions}
      searchable={false}
    />
  );
};

PrioritySelect.propTypes = {
  defaultSelection: PropTypes.string,
  metadata: PropTypes.object.isRequired,
  priority: PropTypes.oneOf(Object.values(TaskPriority)),
  values: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

PrioritySelect.defaultProps = {
  defaultSelection: '',
  priority: undefined,
};
