import { Icons } from '@ge/components/icon';

export const EventStatus = {
  RETURNED: {
    key: 'returned',
    translationKey: 'status.returned',
    icon: Icons.EVENT_RETURNED,
    viewbox: '-2 -2 18 18',
  },
  SNOOZED: {
    key: 'snoozed',
    translationKey: 'status.snoozed',
    icon: Icons.EVENT_SNOOZED,
    viewbox: '-2 -2 18 18',
  },
  ACKNOWLEDGED: {
    key: 'acknowledged',
    translationKey: 'status.acknowledged',
    icon: Icons.EVENT_ACKNOWLEDGED,
  },
  NEW: {
    key: 'new',
    translationKey: 'status.new',
    icon: Icons.EVENT_NEW,
    viewbox: '-2 -2 18 18',
  },
  CLOSED: {
    key: 'closed',
    translationKey: 'status.closed',
    icon: Icons.EVENT_CLOSED,
    viewbox: '-2 -2 18 18',
  },
  INPROGRESS: {
    key: 'inProgress',
    translationKey: 'status.inprogress',
    icon: Icons.EVENT_INPROGRESS,
    viewbox: '-2 -2 18 18',
  },
  PENDINGINSPECTION: {
    key: 'pendingInspection',
    translationKey: 'status.pending_inspection',
    icon: Icons.EVENT_PENDING_INSPECTION,
    viewbox: '-2 -2 18 18',
  },
  PENDINGREPAIR: {
    key: 'pendingRepair',
    translationKey: 'status.pending_repair',
    icon: Icons.EVENT_PENDING_REPAIR,
    viewbox: '-2 -2 18 18',
  },
};

export const EventType = {
  FLAGGED: {
    key: 'flagged',
    translationKey: 'type.flagged',
    icon: Icons.FLAGGED,
    viewbox: '-2 -2 18 18',
  },
  STATE: {
    key: 'state',
    translationKey: 'type.state',
  },
  LOGIC: {
    key: 'logic',
    translationKey: 'type.logic',
  },
  FAULTED: {
    key: 'faulted',
    translationKey: 'type.faulted',
    icon: Icons.FAULTED,
    viewbox: '-2 -2 18 18',
  },
  SITENOCOMM: {
    key: 'siteNoComm',
    translationKey: 'type.system',
    icon: Icons.SYSTEM,
  },
  FLEETNOCOMM: {
    key: 'fleetNoComm',
    translationKey: 'type.system',
    icon: Icons.SYSTEM,
  },
  STOPPED: {
    key: 'stopped',
    translationKey: 'type.system',
    icon: Icons.SYSTEM,
  },
  EXTERNAL: {
    key: 'externalApi',
    translationKey: 'type.externalApi',
    icon: Icons.EXTERNAL,
    viewbox: '-2 -2 18 18',
  },
  ASSETNOCOMM: {
    key: 'assetNoComm',
    translationKey: 'type.system',
    icon: Icons.SYSTEM,
  },
  SYSTEM: {
    key: 'system',
    translationKey: 'type.system',
    icon: Icons.SYSTEM,
    viewbox: '-2 -2 18 18',
  },
  UNKNOWN: {
    key: 'unknown',
    translationKey: 'type.unknown',
  },
  BOP_BREAKER: {
    key: 'bopBreaker',
    translationKey: 'type.system',
    icon: Icons.SYSTEM,
  },
  ANOMALY: {
    key: 'anomaly',
    translationKey: 'type.anomaly',
    icon: Icons.ANOMALY,
  },
  RNS: {
    key: 'rns',
    translationKey: 'type.rns',
    viewbox: '-2 -2 18 18',
  },
};

export const getMappedEventStatus = (status) => {
  let eventStatus;
  switch (status) {
    case EventStatus.ACKNOWLEDGED.key:
      eventStatus = EventStatus.ACKNOWLEDGED;
      break;
    case EventStatus.CLOSED.key:
      eventStatus = EventStatus.CLOSED;
      break;
    case EventStatus.NEW.key:
      eventStatus = EventStatus.NEW;
      break;
    case EventStatus.RETURNED.key:
      eventStatus = EventStatus.RETURNED;
      break;
    case EventStatus.SNOOZED.key:
      eventStatus = EventStatus.SNOOZED;
      break;
    case EventStatus.INPROGRESS.key:
      eventStatus = EventStatus.INPROGRESS;
      break;
    case EventStatus.PENDINGINSPECTION.key:
      eventStatus = EventStatus.PENDINGINSPECTION;
      break;
    case EventStatus.PENDINGREPAIR.key:
      eventStatus = EventStatus.PENDINGREPAIR;
      break;
    default:
      eventStatus = EventStatus.NEW;
      break;
  }
  return eventStatus;
};

export const getMappedEventType = (type) => {
  let eventType;
  switch (type) {
    case EventType.FLAGGED.key:
      eventType = EventType.FLAGGED;
      break;
    case EventType.LOGIC.key:
      eventType = EventType.LOGIC;
      break;
    case EventType.STATE.key:
      eventType = EventType.STATE;
      break;
    case EventType.FAULTED.key:
      eventType = EventType.FAULTED;
      break;
    case EventType.SITENOCOMM.key:
      eventType = EventType.SITENOCOMM;
      break;
    case EventType.FLEETNOCOMM.key:
      eventType = EventType.FLEETNOCOMM;
      break;
    case EventType.STOPPED.key:
      eventType = EventType.STOPPED;
      break;
    case EventType.EXTERNAL.key:
      eventType = EventType.EXTERNAL;
      break;
    case EventType.ASSETNOCOMM.key:
      eventType = EventType.ASSETNOCOMM;
      break;
    case EventType.BOP_BREAKER.key:
      eventType = EventType.BOP_BREAKER;
      break;
    case EventType.SYSTEM.key:
      eventType = EventType.SYSTEM;
      break;
    case EventType.ANOMALY.key:
      eventType = EventType.ANOMALY;
      break;
    case EventType.RNS.key:
      eventType = EventType.RNS;
      break;
    default:
      eventType = EventType.UNKNOWN;
  }
  return eventType;
};
