import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useContext, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tab, Tabs } from '@ge/components/tabs';
import { Capability, EntityType, PermissionScope, WorkScopes } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { TaskProvider } from '@ge/shared/components/tasks/task-provider';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import useCaseDetailDataByQuery from '@ge/shared/data-hooks/cases/use-case-detail-data-by-query';

import { DetailContainer, SectionContainer } from '../entity-details-shared';

import { TaskDetailsHeader } from './task-details-header';
import { TaskDetailsOverview } from './task-details-overview';
import { TaskErp } from './task-erp';
import { TaskNotesAttachments } from './task-notes-attachments';
import { TaskResolution } from './task-resolution';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const TaskEntity = forwardRef(({ taskId, entityTab, showConfirmationPrompt }, ref) => {
  const { t, ready } = useTranslation(['entity-details', 'general', 'tasks'], {
    useSuspense: false,
  });

  const { sites } = useStoreState((store) => store.sites);
  const getTaskById = useStoreState((state) => state.tasks.getTaskById);
  const { fetchTasksByTaskIds } = useStoreActions((store) => store.tasks);
  const {
    dirtyFields,
    setDirtyFields,
    showCaseDetails,
    entityFieldsDirtyCheck,
    setEntityFieldsDirtyCheck,
    showDetails,
    taskPagesData,
  } = useContext(EntityDetailsContext);
  const taskData = taskPagesData?.find((task) => taskId === task?.id);
  const task = taskData ? taskData : getTaskById(taskId);
  useEffect(() => {
    if (!task) fetchTasksByTaskIds([taskId]);
  }, [task, fetchTasksByTaskIds, taskId]);

  let [defaultTab, setDefaultTab] = useState(entityTab ? entityTab : null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [continueDirtyCheck, setContinueDirtyCheck] = useState(false);
  const caseId = task?.caseId ? task.caseId : null;
  const { caseWithDetails } = useCaseDetailDataByQuery({ caseId });
  const { crews, workers } = useStoreState((state) => state.workers);
  useImperativeHandle(
    ref,
    () => ({
      setTab: () => {
        if (entityFieldsDirtyCheck[EntityType.CASE]) {
          showCaseDetails(caseId);
          setEntityFieldsDirtyCheck((prevState) => ({ ...prevState, [EntityType.CASE]: false }));
        } else if (entityFieldsDirtyCheck[EntityType.SITE]) {
          showDetails(task?.site?.id, EntityType.SITE);
          setEntityFieldsDirtyCheck((prevState) => ({ ...prevState, [EntityType.SITE]: false }));
        } else if (entityFieldsDirtyCheck[EntityType.TURBINE]) {
          showDetails(task?.asset?.id, EntityType.TURBINE);
          setEntityFieldsDirtyCheck((prevState) => ({ ...prevState, [EntityType.TURBINE]: false }));
        } else if (entityFieldsDirtyCheck[EntityType.TASK]) {
          setEntityFieldsDirtyCheck((prevState) => ({ ...prevState, [EntityType.TASK]: false }));
          setContinueDirtyCheck(true);
        } else {
          setDefaultTab(selectedTab);
        }
        setDirtyFields(false);
      },
    }),
    [selectedTab, setDirtyFields, entityFieldsDirtyCheck, setContinueDirtyCheck], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!task || !ready) {
    return null;
  }

  const siteId = task.asset?.site?.id ?? task.site?.id;

  const isBopSiteTask = () => sites[siteId]?.aliases?.some((alias) => alias.key === 'racesBopId');

  // Resolution tab is not loading when profile in PT-BR
  if (defaultTab === 'resolution') {
    defaultTab = t(`tabs.${defaultTab}`).toLowerCase();
  }

  const checkForDirtyFeilds = (label) => {
    if (dirtyFields) {
      setSelectedTab(label);
      showConfirmationPrompt();
    } else {
      setDefaultTab(label);
    }
  };

  // TODO: look into handling auth render at the entity panel level
  return (
    <TaskProvider>
      <AuthRender capability={Capability.FIELD_TASKS} siteIds={[siteId]} view>
        <DetailContainer>
          <TaskDetailsHeader
            task={task}
            caseDetails={caseWithDetails}
            showConfirmationPrompt={showConfirmationPrompt}
          />

          <ScrollingContainer autoHide={false}>
            {task && (
              <SectionContainer>
                <StyledTabs
                  small
                  defaultTab={defaultTab}
                  onChangeHandler={(val) => checkForDirtyFeilds(val)}
                  setActiveTabFromParent={true}
                >
                  <Tab
                    capabilities={[
                      { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
                    ]}
                    label={t('tasks:section_header.task_details', 'Task Details')}
                    siteIds={[siteId]}
                  >
                    <TaskDetailsOverview
                      task={task}
                      key={task.id}
                      continueDirtyCheck={continueDirtyCheck}
                      setContinueDirtyCheck={setContinueDirtyCheck}
                    />
                  </Tab>
                  <Tab
                    capabilities={[
                      {
                        capability: Capability.NOTES_AND_ATTACHMENTS,
                        scopes: [PermissionScope.VIEW],
                      },
                    ]}
                    label={t('tabs.notes', 'Notes')}
                    siteIds={[siteId]}
                  >
                    <TaskNotesAttachments task={task} />
                  </Tab>
                  <Tab
                    capabilities={[
                      { capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] },
                    ]}
                    label={t('tabs.resolution', 'Resolution')}
                    siteIds={[siteId]}
                  >
                    <TaskResolution
                      task={task}
                      workerData={workers}
                      allCrews={crews}
                      continueDirtyCheck={continueDirtyCheck}
                      setContinueDirtyCheck={setContinueDirtyCheck}
                    />
                  </Tab>
                  {(task.taskLevel === EntityType.ASSET ||
                    (task.taskLevel === EntityType.SITE &&
                      isBopSiteTask() &&
                      task.workScope === WorkScopes.BOP)) && (
                    <Tab
                      capabilities={[
                        { capability: Capability.WORK_ORDERS, scopes: [PermissionScope.VIEW] },
                      ]}
                      label={t('tabs.erp', 'ERP')}
                      siteIds={[siteId]}
                    >
                      <TaskErp task={task} key={task.id} isTaskSelected />
                    </Tab>
                  )}
                </StyledTabs>
              </SectionContainer>
            )}
          </ScrollingContainer>
        </DetailContainer>
      </AuthRender>
    </TaskProvider>
  );
});

TaskEntity.propTypes = {
  taskId: PropTypes.string.isRequired,
  entityTab: PropTypes.string,
  showConfirmationPrompt: PropTypes.func,
};

TaskEntity.defaultProps = {
  entityTab: null,
  showConfirmationPrompt: null,
};

TaskEntity.displayName = 'TaskEntity';

export default TaskEntity;
