import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';

import { TaskContext } from './task-context';

export const TaskProvider = ({ children }) => {
  const setEntityType = (entityType) => {
    setTaskState((prevState) => ({
      ...prevState,
      entityType: entityType,
    }));
  };

  const setTaskType = (taskType) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskType: taskType,
    }));
  };

  const setTaskTypes = (taskTypes) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskTypes: taskTypes,
    }));
  };

  const setTaskTemplate = (taskTemplate) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskTemplate: taskTemplate,
    }));
  };

  const setTimezone = (timezone) => {
    setTaskState((prevState) => ({
      ...prevState,
      timezone: timezone,
    }));
  };

  const setTaskSource = (taskSource) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskSource: taskSource,
    }));
  };

  const setTaskSources = (taskSources) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskSources: taskSources,
    }));
  };

  const setTaskWorkScope = (taskWorkScope) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskWorkScope: taskWorkScope,
    }));
  };

  const setTaskWorkScopes = (taskWorkScopes) => {
    setTaskState((prevState) => ({
      ...prevState,
      taskWorkScopes: taskWorkScopes,
    }));
  };

  // Set non-form context for UI updates. Managing state with formContext causes re-render issues when
  // templates driven by input values trigger the template to re-render the sections containing the inputs
  const defaultState = {
    entityType: '',
    taskType: '',
    taskTypes: {},
    taskSource: '',
    taskSources: {},
    taskWorkScope: '',
    taskWorkScopes: {},
    taskTemplate: {},
    timezone: '',
    setEntityType,
    setTaskType,
    setTaskTypes,
    setTaskTemplate,
    setTimezone,
    setTaskSource,
    setTaskSources,
    setTaskWorkScope,
    setTaskWorkScopes,
  };

  const [taskState, setTaskState] = useState(defaultState);

  const providerValue = useMemo(() => ({ taskState, setTaskState }), [taskState, setTaskState]);

  return <TaskContext.Provider value={providerValue}>{children}</TaskContext.Provider>;
};

TaskProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

TaskProvider.defaultProps = {
  children: null,
};
