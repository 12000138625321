import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { SensorReadingChart } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { useCaseSensorData } from '@ge/shared/data-hooks/use-case-sensor-data';

const CHART_HEIGHT = 354;

const Layout = styled.div`
  position: relative;
`;

export const CaseSensorReadingsChart = ({ alert, chartHeight, className }) => {
  const { isLoading, isError, error, data } = useCaseSensorData(alert);

  if (!alert?.start) return null;

  return (
    <Layout>
      <DataLoader
        isLoading={isLoading}
        noData={isError ?? !data}
        noDataDescription={error?.message ?? ''}
        type={ChartType.SPLINE}
        height={`${chartHeight}px`}
      >
        {data?.charts?.map((chartOptions, i) => (
          <SensorReadingChart
            key={i}
            className={className}
            chartOptions={chartOptions}
            height={chartHeight}
            tooltipEnabled={true}
          />
        ))}
      </DataLoader>
    </Layout>
  );
};

CaseSensorReadingsChart.propTypes = {
  alert: PropTypes.instanceOf(Object),
  chartHeight: PropTypes.number,
  className: PropTypes.string,
};

CaseSensorReadingsChart.defaultProps = {
  alert: null,
  className: null,
  chartHeight: CHART_HEIGHT,
};

export default CaseSensorReadingsChart;
