import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import enableExport from 'highcharts/modules/exporting';
import enableOfflineExport from 'highcharts/modules/offline-exporting';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { withTheme } from 'styled-components';

import { chartColors } from '@ge/tokens/charts';

enableExport(Highcharts);
enableOfflineExport(Highcharts);

export const Donut = withTheme(({ ...props }) => {
  const chart = useRef();

  const {
    theme: {
      analyze: { majorLoss },
    },
    colors,
    data,
    theme,
    title,
    selectedIndex,
    selectSegment,
    width,
    height,
    // interactive,
    innerSize,
    tooltipPointFormatter,
  } = props;

  if (!data || !theme) {
    return null;
  }

  const [chartOptions] = useState({
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      width: width,
      height: height,
    },
    title: {
      text: props.title,
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontSize: '13px',
        color: majorLoss.donutChart.title,
      },
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: true,
    },
    tooltip: {
      borderColor: 'transparent',
      borderRadius: 9,
      outside: true,
      useHTML: true,
      backgroundColor: theme.charts.tooltipBackgroundColor,
      followPointer: false,
      style: {
        fontSize: '11px',
        color: theme.kpiChart.tooltipSecondaryColor,
      },
      formatter: tooltipPointFormatter
        ? function() {
            // can expand how much stuff we pass in here
            return tooltipPointFormatter(this.point);
          }
        : null,
    },
    credits: {
      enabled: false,
    },
    colors: colors ? [...props.colors] : chartColors.level1,
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
        slicedOffset: 1,
      },
      series: {
        cursor: 'pointer',
        events: {
          click: (event) => {
            selectSegment(event.point.id, event.point.index);
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        cursor: 'pointer',
        innerSize: innerSize ?? '80%',
        borderWidth: 1,
        borderColor: majorLoss.donutChart.border,
        data: props.data,

        states: {
          select: {
            borderColor: 'white',
          },
          hover: {
            halo: {
              size: 2,
              attributes: {
                'stroke-width': 2,
              },
            },
          },
        },
      },
    ],
  });

  useEffect(() => {
    chart.current.chart.series[0].setData(data, true, true, false);
    chart.current.chart.update({
      colors: colors,
      title: {
        text: title,
        style: {
          fontSize: '13px',
          color: theme.analyze.majorLoss.donutChart.title,
        },
      },
    });
  }, [data, colors, title, theme]);

  useEffect(() => {
    chart.current?.chart?.series?.[0]?.data.map((d) => d.select(false, false));

    if (selectedIndex !== null) {
      chart.current?.chart?.series[0]?.data?.[selectedIndex]?.select(true, true);
    }
  }, [selectedIndex]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chart} />;
});

Donut.propTypes = {
  theme: PropTypes.shape({
    donutChart: PropTypes.instanceOf(Object),
  }),
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  selectSegment: PropTypes.func,
  selectedIndex: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  interactive: PropTypes.bool,
  innerSize: PropTypes.string,
  tooltipPointFormatter: PropTypes.func,
};

Donut.defaultProps = {
  theme: null,
  data: null,
  colors: null,
  title: null,
  selectSegment: () => null,
  selectedIndex: null,
  width: 140,
  height: 140,
  interactive: true,
  innerSize: '80%',
  tooltipPointFormatter: () => null,
};

Donut.displayName = 'Donut';
