import dayjs from 'dayjs';
import { useStoreActions } from 'easy-peasy';
import { useCallback, useEffect, useRef, useState } from 'react';

import { KpiCategorySeriesType } from '@ge/feat-analyze/models';
import { DateTimeFormats, TimeAggr } from '@ge/models/constants';
import { isFunction } from '@ge/shared/util';

const getSelectedDates = ({ timeAggr, selected }) => {
  if (selected) {
    if (timeAggr === TimeAggr.MONTHLY) {
      const date = dayjs(selected).utc();
      const startDate = date.startOf('month').utc().format(DateTimeFormats.ENDPOINT_PARAM);
      const endDate = date.endOf('month').utc().format(DateTimeFormats.ENDPOINT_PARAM);
      const isAfter = dayjs().isAfter(dayjs(endDate));
      return {
        startDate,
        endDate: isAfter ? endDate : dayjs().format(DateTimeFormats.ENDPOINT_PARAM),
      };
    } else if (timeAggr === TimeAggr.WEEKLY) {
      const date = dayjs(selected).add(1, 'week').utc();
      const startDate = date.startOf('isoWeek').utc().format(DateTimeFormats.ENDPOINT_PARAM);
      const endDate = date
        .startOf('isoWeek')
        .add(6, 'days')
        .utc()
        .format(DateTimeFormats.ENDPOINT_PARAM);
      const isAfter = dayjs().isAfter(dayjs(endDate));
      return {
        startDate,
        endDate: isAfter ? endDate : dayjs().format(DateTimeFormats.ENDPOINT_PARAM),
      };
    }
    const date = dayjs(selected).utc().format(DateTimeFormats.ENDPOINT_PARAM);
    return {
      startDate: date,
      endDate: date,
    };
  }
  return null;
};

/**
 * Hook to handle kpi chart.
 * @param seriesType
 * @param timeAggr
 * @param categories
 * @param onChange
 * @returns *
 */
export const useKpiChartSelection = ({
  selectable,
  seriesType,
  timeAggr,
  categories,
  onChange,
}) => {
  const [currentPoint, setCurrentPoint] = useState(null);
  const currentPointRef = useRef(null);
  const update = useStoreActions(({ analyze }) => analyze.updateTrendGraphSelection);

  useEffect(() => {
    setCurrentPoint(null);
  }, [selectable, timeAggr, seriesType, categories, update]);

  useEffect(() => {
    if (isFunction(currentPointRef?.current?.update)) {
      currentPointRef.current.update({
        marker: {
          enabled: false,
        },
      });
    }
    const dates = getSelectedDates({ timeAggr, selected: currentPoint?.x });
    update(dates);
    currentPointRef.current = currentPoint;
  }, [timeAggr, currentPoint, update]);

  const onSelect = useCallback(
    function ({ multi, point, selection = [] }) {
      if (point && selectable && seriesType === KpiCategorySeriesType.TIME_SERIES) {
        point.update({
          marker: {
            enabled: true,
          },
        });
        setCurrentPoint(point);
      } else {
        setCurrentPoint(null);
      }
      onChange({ selection, multi, point });
    },
    [selectable, seriesType, onChange],
  );

  return { onSelect };
};
