// TODO: can move this out of here if it needs to show up on another form

import { PropTypes } from 'prop-types';
import uniq from 'ramda/src/uniq';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Pattern } from '@ge/models/constants';

import { RECIPIENT_DELIMITER } from '../alert-shared';

import { MailRecipientListField } from './mail-recipient-list-field';
import { MailRecipientTextField } from './mail-recipient-text-field';

const Container = styled.div`
  width: 100%;

  > * {
    + * {
      margin-top: 12px;
    }
  }
`;

const ControllerField = ({
  errors,
  label,
  metadata,
  name,
  onChange,
  placeholderLabel,
  value: _value,
}) => {
  //state
  const [value, setValue] = useState([]);

  useEffect(() => {
    let formValue = _value?.trim();

    if (!formValue) {
      setValue([]);

      return;
    }

    const formValues = formValue.split(RECIPIENT_DELIMITER);
    setValue((prev) => uniq([...prev, ...formValues]));
  }, [_value]);

  const handleChange = (updateValue) => {
    const _value = [...value];
    if (!_value.includes(updateValue)) {
      _value.push(updateValue);
      setValue(_value);
    }

    // serialize recipients in semicolon delimited list before passing up to form
    // TODO: revisit this once we have better idea how this gets submitted to backend
    onChange(_value.join(RECIPIENT_DELIMITER));
  };

  const handleRemove = (removeValue) => {
    const _value = value.filter((prevValue) => prevValue !== removeValue);

    setValue(_value);

    onChange(_value.join(RECIPIENT_DELIMITER));
  };

  return (
    <Container>
      <MailRecipientTextField
        errors={errors}
        label={label}
        maxLength={metadata.maxLength}
        metadata={metadata}
        name={name}
        onChange={handleChange}
        placeholder={placeholderLabel}
      />
      <MailRecipientListField onRemove={handleRemove} value={value} />
    </Container>
  );
};

ControllerField.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  metadata: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholderLabel: PropTypes.string,
  value: PropTypes.string,
};

ControllerField.defaultProps = {
  errors: undefined,
  label: undefined,
  onChange: () => {},
  placeholderLabel: undefined,
  value: undefined,
};

// can add support for modes if needed, but right now send notification is fire-and-forget
// so no real view mode supported
export const MailRecipientField = ({ label, name, placeholderLabel, ...metadata }) => {
  const { control, errors } = useFormContext();

  const { required } = metadata;

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
        validate: (val) => {
          // not enforcing required in here
          if (!val) {
            return true;
          }

          // TODO: add custom error messages with translation support?
          return val
            .split(RECIPIENT_DELIMITER)
            .every((address) => Boolean(address?.match(Pattern.EMAIL)));
        },
      }}
      render={({ onChange, value }) => (
        <ControllerField
          errors={errors}
          label={label}
          metadata={metadata}
          name={name}
          onChange={onChange}
          placeholderLabel={placeholderLabel}
          value={value}
        />
      )}
    />
  );
};

MailRecipientField.propTypes = {
  hidden: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholderLabel: PropTypes.string,
};

MailRecipientField.defaultProps = {
  hidden: false,
  label: undefined,
  placeholderLabel: undefined,
};
