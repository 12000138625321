import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { DonutWidgetStateKeys } from '@ge/feat-reporting/components/widgets/donut-widget';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { AssetStatus } from '@ge/models/constants';

const Header = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.sidebar.moduleBorderColor};
  display: flex;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
`;

const CheckboxWrapper = styled.div`
  display: flexbox;
  flex-wrap: wrap;
  margin: 15px 0 0 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-bottom: 15px;
  min-width: 110px;
`;

// Map of status names used in saved template configs to the correct names.
// This should be fixed in the DB but probably easier to just deal with here.
const assetStatusConfigNames = {
  Tripped: AssetStatus.TRIPPED,
  Stopped: AssetStatus.STOPPED,
  Impacted: AssetStatus.IMPACTED,
  Repair: AssetStatus.REPAIR,
  Maintenance: AssetStatus.MAINTENANCE,
  'No Comm': AssetStatus.NET_COMM,
  Online: AssetStatus.ONLINE,
  Available: AssetStatus.AVAILABLE,
};

export const assetStatusStatuses = {
  [AssetStatus.TRIPPED]: true,
  [AssetStatus.STOPPED]: true,
  [AssetStatus.IMPACTED]: true,
  [AssetStatus.REPAIR]: true,
  [AssetStatus.MAINTENANCE]: true,
  [AssetStatus.NET_COMM]: true,
  [AssetStatus.ONLINE]: true,
  [AssetStatus.AVAILABLE]: true,
};

const getAssetStatusDefaults = (config) => {
  const assetStatuses = { ...assetStatusStatuses };

  if (config) {
    for (const [_configKey, configValue] of Object.entries(config)) {
      if (typeof configValue === 'boolean') {
        let configKey;
        if (Object.prototype.hasOwnProperty.call(assetStatuses, _configKey)) {
          configKey = _configKey;
        } else {
          const altConfigKey = assetStatusConfigNames[_configKey];
          if (altConfigKey && Object.prototype.hasOwnProperty.call(assetStatuses, altConfigKey)) {
            configKey = altConfigKey;
          }
        }

        if (configKey) {
          const defaultStatus = assetStatuses[configKey];
          if (defaultStatus !== configValue) {
            assetStatuses[configKey] = configValue;
          }
        }
      }
    }
  }

  return assetStatuses;
};

export const AssetStatusModule = ({ id, configuration }) => {
  const { t } = useTranslation(['reporting.widgets', 'reporting.sidebar']);
  const { getWidgetState, setWidgetState } = useContext(ReportsContext);
  const currentWidgetState = getWidgetState(id, DonutWidgetStateKeys.CHECKED_STATUSES);

  // Initialize report context checked statuses
  useEffect(() => {
    if (!currentWidgetState) {
      const widgetState = getAssetStatusDefaults(configuration);
      setWidgetState({
        widgetId: id,
        key: DonutWidgetStateKeys.CHECKED_STATUSES,
        value: widgetState,
      });
    }
  }, [id, currentWidgetState, configuration, setWidgetState]);

  const handleStatusUpdated = useCallback(
    (targetValue, checkedStatus) => {
      if (currentWidgetState) {
        currentWidgetState[checkedStatus] = targetValue;
        setWidgetState({
          widgetId: id,
          key: DonutWidgetStateKeys.CHECKED_STATUSES,
          value: { ...currentWidgetState },
        });
      }
    },
    [id, currentWidgetState, setWidgetState],
  );

  return (
    <>
      <Header>{t('status', { defaultValue: 'Status', ns: 'reporting.sidebar' })}</Header>
      <CheckboxWrapper>
        {Object.keys(assetStatusStatuses).map((status) => (
          <StyledCheckbox
            checkState={
              currentWidgetState?.[status] ? CheckedState.CHECKED : CheckedState.UNCHECKED
            }
            label={t(`asset_state.${status}`, status)}
            key={status}
            onChange={(e) => handleStatusUpdated(e, status)}
          />
        ))}
      </CheckboxWrapper>
    </>
  );
};

AssetStatusModule.propTypes = {
  id: PropTypes.string.isRequired,
  configuration: PropTypes.object,
};
