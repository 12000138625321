/**
 * Mock data for Roles
 */
const useRoles = () => {
  const roles = [
    {
      a11yKey: 'technician',
      defaultLabel: 'Technician',
      defaultDesc: 'Basic Technician access to DWF and MTM',
    },
    {
      a11yKey: 'technician_viewer',
      defaultLabel: 'Technician Viewer',
      defaultDesc: 'Default Description',
    },
  ];

  return {
    data: roles,
    isLoading: false,
  };
};

export default useRoles;
