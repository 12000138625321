import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { Icon } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

import { getDefaultRoute } from '../../services/nav';

const StyledSubMenuHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: 'GE Inspira';
  color: ${(props) => props.theme.navigation.linkColor};
  display: flex;
  align-items: center;
  height: 57px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.navigation.l1BorderColor};
`;

const L2List = styled.ul`
  padding: 0 0 0px 0px;
  margin: 0;
  list-style: none;
`;

const StyledNavLink = styled(NavLink)`
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.navigation.linkColor};
  svg {
    fill: ${(props) => props.theme.navigation.linkColor};
    margin-right: 8px;
  }
  text-decoration: none;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
  &.active {
    font-weight: 300;
    text-decoration: none;
    background-color: ${(props) => props.theme.navigation.linkActiveBorderColor};
    color: ${(props) => props.theme.navigation.linkActiveColor};
    svg {
      fill: ${(props) => props.theme.navigation.linkActiveColor};
    }
  }
`;

const L2ListItem = styled.li`
  list-style: none;
  position: relative;
  &:hover,
  &:focus,
  &:focus-within {
    ul {
      display: block;
    }
  }
`;

const L2NavItem = ({ navMapValue, pathname, parentLabel }) => {
  const { t } = useTranslation(['nav']);
  const l2LinkRefArray = useRef([]);

  const { resetDateRange } = useStoreActions(({ analyze }) => analyze);

  const getL2NavItems = (value) => {
    const navItems = value.map((navItem, i) => (
      <L2ListItem key={getDefaultRoute(navItem)} ref={(el) => (l2LinkRefArray.current[i] = el)}>
        <StyledNavLink
          className={pathname.includes(navItem.rootRoute) ? 'active' : ''}
          to={Array.isArray(navItem.route) ? navItem.route[0] : navItem.route}
          onClick={() => {
            resetDateRange();
          }}
        >
          {navItem.icon && <Icon size={16} icon={navItem.icon} color={globalColors.white} />}
          {t(navItem.i18nKey, navItem.label)}
        </StyledNavLink>
      </L2ListItem>
    ));
    return (
      <>
        <StyledSubMenuHeader>{parentLabel}</StyledSubMenuHeader>
        <L2List>{navItems}</L2List>
      </>
    );
  };
  return <li>{getL2NavItems(navMapValue)}</li>;
};

L2NavItem.propTypes = {
  navMapValue: PropTypes.array,
  pathname: PropTypes.string,
  parentLabel: PropTypes.string,
};

export default withRouter(withTheme(L2NavItem));
