import { useStoreState } from 'easy-peasy';
import { useMemo, useCallback } from 'react';
import { useQueries } from 'react-query';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { KpiCategoryDefs } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { sortedArrByElement } from '@ge/util';

import { useGlobalFilters } from '../hooks';
import { fetchRegionKpiData } from '../services';

import { KPI, splitKpisCategoryByApi } from './util';

/**
 * Use region IEC data.
 *
 * @param regionId the region id
 * @param categories the kpi categories
 * @param startDate the start date
 * @param endDate the end date
 * @param sortBy the sort KPI metric
 * @param sortDirection the sort direction
 * @param isActive query only runs when isActive = true (defaults to true)
 * @param entityAggr Aggregate data based on Site or Region
 * @param types Series type. Can be one or both [KpiCategorySeriesType.ENTITY, KpiCategorySeriesType.TIME_SERIES]
 * @param seriesEntityAggr
 * @param dateEntityAggr
 * @param timeAggr Can be daily, weekly or monthly
 */
const useRegionKpiData = ({
  regionId,
  categories,
  startDate,
  endDate,
  sortBy,
  sortDirection = SortDirection.ASC,
  isActive = true,
  types,
  entityAggr,
  seriesEntityAggr,
  dateEntityAggr,
  timeAggr,
  graph1Category,
}) => {
  const { visibleKpis } = useStoreState((state) => state.analyze);

  const endpointCategories = useMemo(() => {
    const { allKpiMetricsEndpointCategories, otherKpiEndpointsCategories } =
      splitKpisCategoryByApi(categories);

    const sortedOtherKpiEndpointsCategories = sortedArrByElement(
      otherKpiEndpointsCategories,
      KpiCategoryDefs.TBA,
    );

    const otherCategories = sortedOtherKpiEndpointsCategories.map((category) => [category]);

    return [allKpiMetricsEndpointCategories, ...otherCategories].filter(
      (categories) => categories?.length > 0,
    );
  }, [categories]);

  const sitesById = useStoreState((state) => state.sites.sites);

  const filters = useGlobalFilters();
  const { siteIds: allSiteIds = [], ...rest } = filters;
  const siteIds = allSiteIds.filter((siteId) => sitesById[siteId]?.region?.id === regionId);

  const isSelected = useCallback(
    (categories) => {
      if (!categories?.length) return false;
      if (
        categories.includes(KpiCategoryDefs.REPORTING_ASSETS_RATIO) ||
        categories.includes(KpiCategoryDefs.REPORTING_SITES_RATIO)
      ) {
        return categories.every((category) => visibleKpis.includes(category));
      }
      return true;
    },
    [visibleKpis],
  );

  const queries = useQueries(
    endpointCategories.map((categories) => {
      const queryKey = {
        regionId,
        categories,
        startDate,
        endDate,
        timeAggr,
        filters,
        types,
        entityAggr,
        seriesEntityAggr,
        dateEntityAggr,
      };
      return {
        queryKey: [queryKey],
        queryFn: () =>
          fetchRegionKpiData({
            regionId,
            categories,
            startDate,
            endDate,
            timeAggr,
            filters: { siteIds, ...rest },
            types,
            entityAggr,
            seriesEntityAggr,
            dateEntityAggr,
          }),
        enabled: Boolean(
          isActive &&
            regionId &&
            siteIds?.length &&
            startDate &&
            endDate &&
            timeAggr &&
            isSelected(categories),
        ),
        ...Config.EXECUTE_ONCE,
        retry: false,
      };
    }),
  );

  const isLoading = queries.some((query) => query.isLoading);

  const dataMapped = useMemo(() => {
    const initialData = isLoading
      ? endpointCategories.flat().reduce((acc, val) => ({ ...acc, [val]: { isLoading } }), {})
      : {};
    const regionData = queries
      .filter((o) => !o.isLoading && !o.isFetching && o.data?.data)
      .reduce((data, result) => ({ ...data, ...result?.data?.data }), initialData);

    const hydrated = regionData && KPI.hydrate(regionData, sitesById, timeAggr);
    const sortedKpi = KPI.getSorted(hydrated, sortBy, sortDirection);
    // TODO: Will be moving this to BFF in an upcoming story as the sorting of time series data is already done in BFF.
    const sortedTimeSeriesData = KPI.getSortedTimeSeriesData(sortedKpi);
    return {
      sortedKpi: sortedTimeSeriesData,
    };
  }, [isLoading, queries, sitesById, sortBy, sortDirection, timeAggr, endpointCategories]);

  const error = useMemo(
    () => (isLoading ? null : queries.find((query) => query.isError)?.error),
    [isLoading, queries],
  );

  const graph1QueryState = useMemo(() => {
    if (!graph1Category) return {};

    const state = endpointCategories.reduce(
      (acc, val, i) =>
        val.reduce((result, category) => ({ ...result, [category]: queries[i] }), acc),
      {},
    );

    return state[graph1Category] ?? {};
  }, [isLoading, graph1Category, endpointCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data: dataMapped.sortedKpi, isLoading, error, graph1QueryState };
};

export default useRegionKpiData;
