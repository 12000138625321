import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { ConditionalRender } from '@ge/components/conditional-render';
import { DataLoader } from '@ge/components/data-loader';
import { Input } from '@ge/components/input/input';
import { Select } from '@ge/components/select';
import {
  DispositionOptionValues,
  DispositionOptions,
  RootCauseOptionValues,
} from '@ge/models/constants';
import { useCaseTemplateRootCause } from '@ge/shared/data-hooks/use-case-template';
import { useCloseCase, useUpdateCase } from '@ge/shared/data-hooks/use-update-case';

import { SectionContainer } from '../entity-details-shared';

import { CaseDetailRow, StyledCaseDetailRow } from './case-resolution-detail-rows';
import { CaseResolutionTasks } from './case-resolution-tasks';

const InputWrapper = styled.div`
  display: inline-block;
  min-width: 400px;
  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const SectionLabel = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.resolutionText};
  font-size: 11px;
  padding-bottom: 5px;
`;

const ErrorDisplay = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.errorColor};
  font-size: 10px;
  margin: 5px 0;
`;

const EditCaseContainer = styled.section``;

const ResolutionEditSelect = ({ name, label, placeholder, message, defaultValue, options }) => {
  const { control, errors = {}, setValue, trigger } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue);
    trigger(name);
  }, [name, defaultValue, setValue, trigger]);

  return (
    <StyledCaseDetailRow
      detail={
        <>
          <SectionLabel>{label}</SectionLabel>
          <Controller
            name={name}
            control={control}
            rules={{
              required: message,
            }}
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
              <InputWrapper>
                <Select
                  name={name}
                  menuPosition="fixed"
                  primary
                  onChange={onChange}
                  options={options}
                  value={value}
                  placeholder={placeholder}
                  error={errors[name]?.message ?? ''}
                  styles={{
                    menuPortal: (provided) => ({ ...provided, zIndex: 21 }),
                  }}
                />
              </InputWrapper>
            )}
          />
          {errors[name] && <ErrorDisplay>{errors[name].message}</ErrorDisplay>}
        </>
      }
    />
  );
};

const ResolutionEditInput = ({ name, placeholder, defaultValue, message }) => {
  const { control, errors = {}, trigger } = useFormContext();

  useEffect(() => {
    trigger(name);
  }, [name, trigger]);

  return (
    <StyledCaseDetailRow
      detail={
        <Controller
          name={name}
          control={control}
          rules={{
            required: message,
          }}
          defaultValue={defaultValue ?? ''}
          render={({ onChange, value }) => (
            <InputWrapper>
              <Input
                type="text"
                label="&nbsp;"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                error={errors[name]?.message ?? ''}
              />
            </InputWrapper>
          )}
        />
      }
    />
  );
};

const formFields = {
  dispositionControlName: 'disposition',
  rootCauseControlName: 'rootCause',
};

export const CaseResolutionEdit = ({ issue, closeEditPanel }) => {
  const { t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { data: caseTemplate, isLoading } = useCaseTemplateRootCause(issue);

  const { mutate: close } = useCloseCase();
  const { mutate: update } = useUpdateCase();

  const methods = useForm({ mode: 'onChange' });
  const { formState, handleSubmit } = methods;

  const watchDisposition = methods.watch(formFields.dispositionControlName);
  const watchRootCause = methods.watch(formFields.rootCauseControlName);

  const onSubmit = useCallback(
    (values) => {
      if (values.disposition) {
        issue.disposition = values.disposition.label;
      }
      if (values.rootCause) {
        issue.rootCause = values.rootCauseText ? values.rootCauseText : values.rootCause.label;
      }

      update({
        id: issue.id,
        asset: issue.asset,
        disposition: issue.disposition,
        rootCause: issue.rootCause,
      });

      closeEditPanel();
    },
    [issue, closeEditPanel, update],
  );

  const onCloseCase = useCallback(
    (values) => {
      issue.disposition = values.disposition?.label;
      issue.rootCause = values.rootCauseText ? values.rootCauseText : values.rootCause?.label;
      close(issue);
      closeEditPanel();
    },
    [close, closeEditPanel, issue],
  );

  const getSaveCaseButtons = useCallback(() => {
    return (
      <>
        <Button type="button" onClick={closeEditPanel}>
          {t('general.cancel', 'Cancel')}
        </Button>
        <Button type="button" onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {t('general.save', 'Save')}
        </Button>
        <Button
          primary
          type="button"
          onClick={handleSubmit(onCloseCase)}
          disabled={!formState.isValid}
        >
          {t('case_panel.resolution.close_case', 'Close Case')}
        </Button>
      </>
    );
  }, [closeEditPanel, formState, handleSubmit, onSubmit, onCloseCase, t]);

  const {
    hasRootCause = true,
    rootCause: defaultRootCause,
    rootCauses: rootCauseOptions,
    rootCauseText,
  } = useMemo(() => {
    if (isLoading) return {};

    let rootCauses = [],
      rootCause = null;
    if (watchDisposition?.value === DispositionOptionValues.CORRECTED) {
      rootCauses = caseTemplate?.rootCauses?.map((val) => ({ label: val, value: val })) ?? [];
      if (rootCauses.length === 1) {
        rootCause = rootCauses[0];
      } else {
        rootCause = issue.rootCause
          ? rootCauses.find((opt) => opt.label === issue.rootCause) ??
            rootCauses.find((opt) => opt.value === RootCauseOptionValues.OTHER)
          : null;
      }
    } else if (watchDisposition?.value === DispositionOptionValues.OTHER) {
      rootCauses =
        caseTemplate?.rootCauses
          ?.filter((val) => val === RootCauseOptionValues.OTHER)
          .map((val) => ({ label: val, value: val })) ?? [];
      rootCause = rootCauses.find((opt) => opt.value === RootCauseOptionValues.OTHER);
    }

    return {
      rootCause,
      rootCauses,
      hasRootCause: !(
        watchDisposition?.value === DispositionOptionValues.RESOLVED ||
        watchDisposition?.value === DispositionOptionValues.INCORRECT
      ),
      rootCauseText:
        issue.rootCause && rootCause?.value === RootCauseOptionValues.OTHER
          ? issue.rootCause
          : null,
    };
  }, [isLoading, caseTemplate, watchDisposition, issue]);

  return (
    <DataLoader isLoading={isLoading}>
      <FormProvider {...methods}>
        <EditCaseContainer>
          <CaseDetailRow detail={getSaveCaseButtons()} detailClasses="buttons" />

          <CaseDetailRow
            detail={<h2>{t('case_panel.resolution.resolution', 'Case Resolution')}</h2>}
          />

          <SectionContainer>
            <ResolutionEditSelect
              name={formFields.dispositionControlName}
              issue={issue}
              label={`${t('case_panel.resolution.disposition', 'Disposition')}*`}
              placeholder={t('case_panel.resolution.placeholder_select', 'Select From List')}
              message={t('case_panel.resolution.select_error', 'Please make a selection')}
              defaultValue={
                issue.disposition
                  ? DispositionOptions.find((opt) => opt.label === issue.disposition)
                  : null
              }
              options={DispositionOptions}
            />
            <ConditionalRender shouldRender={hasRootCause}>
              <ResolutionEditSelect
                name={formFields.rootCauseControlName}
                issue={issue}
                label={`${t('case_panel.resolution.root_cause', 'Root Cause')}*`}
                placeholder={t('case_panel.resolution.placeholder_select', 'Select From List')}
                message={t('case_panel.resolution.select_error', 'Please make a selection')}
                defaultValue={defaultRootCause}
                options={rootCauseOptions}
              />
            </ConditionalRender>
            <ConditionalRender shouldRender={watchRootCause?.value === RootCauseOptionValues.OTHER}>
              <ResolutionEditInput
                name="rootCauseText"
                defaultValue={rootCauseText}
                placeholder={t(
                  'case_panel.resolution.placeholder_text',
                  'Please provide the root cause',
                )}
                message={t('case_panel.resolution.input_error', 'Please enter a valid root cause')}
              />
            </ConditionalRender>
          </SectionContainer>

          <CaseResolutionTasks issue={issue} />

          <CaseDetailRow detail={getSaveCaseButtons()} detailClasses="buttons" />
        </EditCaseContainer>
      </FormProvider>
    </DataLoader>
  );
};

CaseResolutionEdit.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  closeEditPanel: PropTypes.func,
};

CaseResolutionEdit.defaultProps = {
  issue: {},
  closeEditPanel: () => {},
};

ResolutionEditSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  defaultValue: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
};

ResolutionEditSelect.defaultProps = {
  defaultValue: null,
  options: [],
};

ResolutionEditInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  defaultValue: PropTypes.string,
};

ResolutionEditInput.defaultProps = {
  defaultValue: '',
};
