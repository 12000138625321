import { transparentize } from 'polished';
import styled from 'styled-components';

import { elevations } from '@ge/tokens';

const CountBar = styled.div`
  display: flex;
  height: 18px;
  position: relative;
  & > div:first-of-type {
    border-radius: 6px 0 0 6px;
  }
  & > div:last-of-type {
    border-radius: 0 6px 6px 0;
  }
`;

const CountCell = styled.div`
  flex: 1;
  padding: 0 14px;
  background: ${(props) =>
    props.active === 0
      ? transparentize(props.theme.countblock.transparentize, props.color)
      : props.color};
  color: ${(props) =>
    props.active === 0 ? props.theme.countblock.textColor : props.theme.countblock.textColorActive};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-shadow: ${(props) => props.theme.countblock.textShadow};
  font-family: 'Museo Sans';
  position: relative;
  overflow: hidden;
  border-radius: inherit;
  &:last-child {
    border-right: 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: ${(props) => props.theme.countblock.boxShadow};
    align-items: stretch;
    z-index: ${elevations.P2};
  }
`;

export { CountBar, CountCell };
