import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { FilterValues } from '@ge/feat-manage/context/planning-provider';
import { CalendarRanges, Placeholders, QueryKey } from '@ge/models/constants';
import { DateTimeFormats } from '@ge/models/constants';
import { TaskPriority, TaskStatus } from '@ge/models/entities/task';
import { groupById } from '@ge/util';

dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(dayOfYear);
dayjs.extend(quarterOfYear);

const statusOrder = [TaskStatus.UNSCHEDULED, TaskStatus.SCHEDULED];

const PriorityOrder = [
  TaskPriority.IMMEDIATE,
  TaskPriority.HIGH,
  TaskPriority.MEDIUM,
  TaskPriority.LOW,
];

export const sortSchedule = (arr) =>
  arr.sort((a, b) => {
    const nameCompare = a.site?.name?.localeCompare(b.site?.name) ?? 0;
    if (nameCompare === 0) {
      // Sites first
      if (!a.asset && !b.asset) return 0;
      if (a.asset && b.asset) return a.asset.name?.localeCompare(b.asset.name) ?? 0;
      return a.asset ? 1 : -1;
    }
    return nameCompare;
  });

const GroupBy = {
  Crew: ({ tasksToGroup, groupTasks, assetsById, sitesById, groupByL2, crews, groupByL1 }) => {
    tasksToGroup?.map((tasks) => {
      if (!tasks?.crewIds) {
        tasks.crewIds = [null];
      }
    });
    // TODO - crews not yet defined, all unassigned for now
    let tasksForCrew = {};
    let noTasksForCrew = [];
    if (crews?.length === 0) {
      noTasksForCrew.push(...tasksToGroup.flat());
    }
    crews?.forEach((crew) => {
      let groupedTask = [];
      tasksToGroup.forEach((taskCrew) => {
        let scheduleDate = dayjs(taskCrew?.scheduleDateTz).format(DateTimeFormats.ENDPOINT_PARAM);
        if (taskCrew?.crewIds && crew?._id === taskCrew?.crewIds[0]) {
          scheduleDate &&
          dayjs(scheduleDate).isBetween(
            dayjs(crew.crewStartDate),
            dayjs(crew.crewEndDate),
            null,
            '[]',
          )
            ? groupedTask.push(taskCrew)
            : noTasksForCrew.push(taskCrew);
        } else if (!taskCrew?.crewIds || !taskCrew?.crewIds[0]) {
          noTasksForCrew.push(taskCrew);
        }
      });
      tasksForCrew[crew?._id] = groupedTask;
    });
    const groupedByCrew =
      groupByL1 === FilterValues.CREW ? tasksForCrew : groupById(tasksToGroup, ['crewIds']);
    const crewSchedule = Object.entries(groupedByCrew)?.map(([crewIds, taskArr]) => {
      const data_Crew = crews.find((crew) => crew._id === crewIds);
      const grouped = groupTasks(taskArr);
      const crewName = data_Crew?.name;
      return {
        groupByCrew: {
          crewName: crewName,
          crewMem: data_Crew?.members,
          crew: { value: crewIds, label: crewName },
        },
        crewEndDate: data_Crew?.crewEndDate,
        crewStartDate: data_Crew?.crewStartDate,
        crewShiftEndTime: data_Crew?.crewShiftEndTime,
        crewShiftStartTime: data_Crew?.crewShiftStartTime,
        crewApplyOn: data_Crew?.apply_on,
        groupByType: FilterValues.CREW,
        crewData: crews,
        crewId: data_Crew?._id,
        data: grouped,
        ...(groupByL2 && {
          secondary: secondaryGroup({
            tasksToGroup: grouped.map((g) => g.tasks).flat(),
            groupTasks,
            assetsById,
            sitesById,
            groupByL2,
            crews,
          }),
        }),
      };
    });
    const crewData = crewSchedule;
    const unassignedTasks = [...new Set(noTasksForCrew)];
    return {
      unassigned: groupTasks(unassignedTasks),
      schedule: crewData,
    };
  },
  Entity: ({ tasksToGroup, groupTasks, assetsById, sitesById, groupByL2, crews }) => {
    const siteTasks = tasksToGroup.filter((t) => !t?.asset.id);
    const assetTasks = tasksToGroup.filter((t) => t?.asset.id);

    const groupedBySite = groupById(siteTasks, ['site', 'id']);
    const groupedByAsset = groupById(assetTasks, ['asset', 'id']);

    const siteSchedule = Object.entries(groupedBySite).map(([siteId, taskArr]) => {
      const grouped = groupTasks(taskArr);
      const { id: _siteId, name: _siteName } = sitesById[siteId] ?? {
        id: siteId,
        name: Placeholders.DASH,
      };
      return {
        site: { id: _siteId, name: _siteName },
        groupByType: FilterValues.SITE_ASSET,
        data: grouped,
        secondary: secondaryGroup({
          tasksToGroup: grouped.map((g) => g.tasks).flat(),
          groupTasks,
          assetsById,
          sitesById,
          groupByL2,
          crews,
        }),
      };
    });

    const assetSchedule = Object.entries(groupedByAsset).map(([assetId, taskArr]) => {
      const grouped = groupTasks(taskArr);
      const { id: _siteId, name: _siteName } =
        grouped.find((g) => g.tasks.length)?.tasks[0]?.site ?? {};
      const { id: _assetId, name: _assetName } = assetsById[assetId] ?? { id: assetId };
      return {
        site: { id: _siteId, name: _siteName },
        asset: { id: _assetId, name: _assetName, state: 'online' }, // TODO asset state
        groupByType: FilterValues.SITE_ASSET,
        data: grouped,
        ...(groupByL2 && {
          secondary: secondaryGroup({
            tasksToGroup: grouped.map((g) => g.tasks).flat(),
            groupTasks,
            assetsById,
            sitesById,
            groupByL2,
            crews,
          }),
        }),
      };
    });

    return {
      schedule: sortSchedule([...siteSchedule, ...assetSchedule]),
    };
  },
  Title: ({ tasksToGroup, groupTasks, assetsById, sitesById, groupByL2, crews }) => {
    const groupedByTitle = groupById(tasksToGroup, ['title']);
    const titleSchedule = Object.entries(groupedByTitle).map(([title, taskArr]) => {
      const grouped = groupTasks(taskArr);
      return {
        groupBy: title,
        groupByType: FilterValues.TASK,
        data: grouped,
        ...(groupByL2 && {
          secondary: secondaryGroup({
            tasksToGroup: grouped.map((g) => g.tasks).flat(),
            groupTasks,
            assetsById,
            sitesById,
            groupByL2,
            crews,
          }),
        }),
      };
    });
    return { schedule: titleSchedule.sort((a, b) => a.groupBy.localeCompare(b.groupBy)) };
  },
};

const secondaryGroup = ({ tasksToGroup, groupTasks, assetsById, sitesById, groupByL2, crews }) => {
  if (groupByL2 === FilterValues.SITE_ASSET) {
    return GroupBy.Entity({ tasksToGroup, groupTasks, assetsById, sitesById, crews });
  } else if (groupByL2 === FilterValues.TASK) {
    return GroupBy.Title({ tasksToGroup, groupTasks, crews });
  } else if (groupByL2 === FilterValues.CREW) {
    return GroupBy.Crew({ tasksToGroup, groupTasks, crews });
  }
};

const sortFilters = (data, orderBy) =>
  data.sort((a, b) => (orderBy.indexOf(a) > orderBy.indexOf(b) ? 1 : -1));

/**
 * Gets isoweek for given date
 * formatted date to get the iso week correctly in expected range
 */
const getIsoWeek = (date) => dayjs(date.format(DateTimeFormats.NOTES_DATE)).isoWeek();

/**
 * Use task schedule.
 *
 * @param start
 * @param end
 * @param chunkUnit
 * @param groupByL1
 * @param groupByL2
 * @param groupByFilters
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useTaskSchedule = ({
  start,
  end,
  chunkUnit = CalendarRanges.DAY,
  groupByL1,
  groupByL2,
  groupByFilters,
}) => {
  const { sitesForView, sites: sitesById } = useStoreState((state) => state.sites);
  const { assets: assetsById, isLoading: isAssetsLoading } = useStoreState((state) => state.assets);
  const { fetchTasks } = useStoreActions((store) => store.tasks);
  const { crews, refreshTask } = useStoreState((state) => state.workers);
  const getSchedulerTasks = useStoreState((store) => store.tasks.getSchedulerTasks);
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  // TODO:remove if not necessary
  const backlogData = useStoreState((state) => state.siteManager.hydratedTaskBacklog);

  const siteIds = useMemo(() => sitesForView.map((s) => s.id), [sitesForView]);

  const { isError, isFetching, error } = useQuery(
    [QueryKey.TASK_SCHEDULE, { start, end, chunkUnit, siteIds, refreshTask }],
    () => fetchTasks({ start, end, siteIds }),
  );

  const isLoading = isFetching || isAssetsLoading;

  /**
   * Get an array of start and end times within the range
   * @type {[]}
   */
  const dateArray = useMemo(() => {
    const _start = dayjs(start).startOf(chunkUnit);
    const _end = dayjs(end).endOf(chunkUnit);
    const arr = [];
    let current = _start;
    while (_end.isAfter(current)) {
      const next = current.add(1, chunkUnit);
      arr.push({ start: current, end: next });
      current = next;
    }
    return arr;
  }, [end, chunkUnit, start]);

  const tasksInRange = getSchedulerTasks(start, end);

  /**
   * Get scheduled and unscheduled tasks
   * @type {{unscheduled: *, scheduled: *}}
   */
  const buildTasks = useCallback(
    ({ isLoading, tasks, filters }) => {
      if (isLoading) return {};

      const _filters = Object.entries(filters).filter((el) => el[1]?.value?.length);

      const filteredData = _filters.length
        ? tasks.filter((task) => _filters.every(([key, val]) => val.value.includes(task[key])))
        : tasks;

      const totalWeeks = dayjs(start).isoWeeksInYear() + 1;
      const daysInYear = dayjs(start).isLeapYear() ? 366 : 365;

      return filteredData.reduce(
        (acc, t) => {
          const task = {
            ...t,
            asset: {
              id: assetsById[t.asset?.id]?.id ?? t.asset?.id,
              name: assetsById[t.asset?.id]?.name ?? Placeholders.DASH,
            },
            site: {
              id: sitesById[t.site?.id]?.id ?? t.site?.id,
              name: sitesById[t.site?.id]?.name ?? Placeholders.DASH,
              utcOffset: sitesById[t.site?.id]?.utcOffset,
              timezone: sitesById[t.site?.id].timezone,
            },
          };

          if (task.status === TaskStatus.UNSCHEDULED) {
            const day = dayjs(task.dueDateTz).dayOfYear();
            acc.countsByDays[day].unscheduled = acc.countsByDays[day].unscheduled + 1;
            const week = getIsoWeek(dayjs(task.dueDateTz));
            acc.countsByWeek[week].unscheduled = acc.countsByWeek[week].unscheduled + 1;

            acc.unscheduled.push(task);
          } else if (task.status === TaskStatus.COMPLETE) {
            const day = dayjs(task.completedDateTimeTz).dayOfYear();
            acc.countsByDays[day].completed = acc.countsByDays[day].completed + 1;
            const week = getIsoWeek(dayjs(task.completedDateTimeTz));
            acc.countsByWeek[week].completed = acc.countsByWeek[week].completed + 1;

            acc.completed.push(task);
          } else if (task.status === TaskStatus.SCHEDULED) {
            const day = dayjs(task.scheduleDateTz).dayOfYear();
            acc.countsByDays[day].scheduled = acc.countsByDays[day].scheduled + 1;
            const week = getIsoWeek(dayjs(task.scheduleDateTz));
            acc.countsByWeek[week].scheduled = acc.countsByWeek[week].scheduled + 1;

            acc.scheduled.push(task);
          }

          acc.all.push(task);

          return acc;
        },
        {
          all: [],
          unscheduled: [],
          scheduled: [],
          completed: [],
          /* to handle range correctly to handle 365th day of the year */
          countsByDays: [...Array(daysInYear).keys()].reduce(
            (acc, k) => ({ ...acc, [k + 1]: { scheduled: 0, unscheduled: 0, completed: 0 } }),
            {},
          ),
          /* to handle iso weeks coming from previous year and also setting the range correctly to handle those weeks */
          countsByWeek: [...Array(totalWeeks).keys()].reduce(
            (acc, k) => ({ ...acc, [k + 1]: { scheduled: 0, unscheduled: 0, completed: 0 } }),
            {},
          ),
        },
      );
    },
    [assetsById, sitesById, start],
  );

  // TODO: need to look at how we can optimize this as the date calcs are pretty heavy in high volumes
  /**
   * Chunk tasks by date
   * @param tasks to chunk
   * @return {{date: *, tasks: *}[]}
   */
  const groupTasks = useCallback(
    (tasks) => {
      return dateArray.map((dateItem) => {
        const chunkTasks = tasks?.filter((t) => {
          let compareObj;
          if (t.status === TaskStatus.SCHEDULED) {
            compareObj = dayjs(t.scheduleDateTz).toObject();
          } else if (t.status === TaskStatus.UNSCHEDULED) {
            compareObj = dayjs(t.dueDateTz).toObject();
          } else if (t.status === TaskStatus.COMPLETE) {
            compareObj = dayjs(t.completedDateTimeTz).toObject();
          }
          const { years, months, date } = compareObj;
          const {
            years: startYears,
            months: startMonths,
            date: startDate,
            hours: startHours,
            minutes: startMin,
            seconds: startSec,
          } = dateItem.start.toObject();
          const {
            years: endYears,
            months: endMonths,
            date: endDate,
            hours: endHours,
            minutes: endMin,
            seconds: endSec,
          } = dateItem.end.toObject();
          return dayjs(new Date(years, months, date)).isBetween(
            new Date(startYears, startMonths, startDate, startHours, startMin, startSec),
            new Date(endYears, endMonths, endDate, endHours, endMin, endSec),
            null,
            '[)',
          );
        });

        // get bundled task details from backlog
        chunkTasks.map((task) => {
          if (task?.isBundled && task?.bundledTasks.length) {
            let bundledTasksList = backlogData.filter((o) => {
              return task?.bundledTasks.includes(o.id);
            });
            return (task.bundledTasksList = bundledTasksList);
          }
        });

        return {
          date: dateItem.start,
          duration: 24, // TODO
          tasks: chunkTasks,
        };
      });
    },
    [dateArray, backlogData],
  );

  const buildSchedule = useCallback(
    (allTasks) => {
      if (groupByL1 === FilterValues.SITE_ASSET) {
        return GroupBy.Entity({
          tasksToGroup: allTasks,
          groupTasks,
          assetsById,
          sitesById,
          groupByL2,
          crews,
        });
      } else if (groupByL1 === FilterValues.TASK) {
        return GroupBy.Title({
          tasksToGroup: allTasks,
          groupTasks,
          assetsById,
          sitesById,
          groupByL2,
          crews,
        });
      } else if (groupByL1 === FilterValues.CREW) {
        return GroupBy.Crew({
          tasksToGroup: allTasks,
          groupTasks,
          assetsById,
          sitesById,
          groupByL1,
          groupByL2,
          crews,
        });
      }
    },
    [assetsById, crews, groupByL1, groupByL2, groupTasks, sitesById],
  );

  const buildFilters = useCallback(
    ({ isLoading, tasks }) => {
      let source = [],
        workScope = [],
        priority = [],
        status = [],
        flag = [];

      if (!isLoading) {
        tasks.forEach((task) => {
          source.push(t(`dynamic.types.${task.source}`, task.source));
          workScope.push(task.workScope);
          priority.push(String(task.priority).toLowerCase());
          status.push(String(task.status).toLowerCase());
          if (task.flag) flag.push(task.flag);
        });
      }

      return {
        source: [...new Set(source)].sort(),
        workScope: [...new Set(workScope)].sort(),
        priority: sortFilters([...new Set(priority)], PriorityOrder),
        status: sortFilters([...new Set(status)], statusOrder),
        flag: [...new Set(flag)].sort(),
      };
    },
    [t],
  );

  const data = useMemo(() => {
    const chunkedTasks = buildTasks({ isLoading, tasks: tasksInRange, filters: groupByFilters });

    return {
      filters: buildFilters({ isLoading, tasks: tasksInRange }),
      counts: {
        byWeek: chunkedTasks.countsByWeek ?? {},
        byDays: chunkedTasks.countsByDays ?? {},
        byQuarter: chunkedTasks.countsByWeek ?? {}, // in future can be updated to countsByQuarter as per requirement for quarterly view
        scheduled: chunkedTasks.scheduled?.length ?? 0,
        unscheduled: chunkedTasks.unscheduled?.length ?? 0,
        completed: chunkedTasks.completed?.length ?? 0,
      },
      ...buildSchedule(chunkedTasks.all ?? []),
    };
  }, [isLoading, tasksInRange, groupByFilters, buildSchedule, buildTasks, buildFilters]);

  return { data, isLoading, isFetching, isError, error };
};
