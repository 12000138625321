import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useCallback, useMemo, useState, useEffect } from 'react';

import { DateTimeFormats } from '@ge/models/constants';
import { WeekNames } from '@ge/models/constants';

/**
 * returns the array of selected SC ID's
 * @param sortedServiceGroups list of available service groups
 * @param currentView currently selected sites
 * @returns {*}
 */

const dateFormat = DateTimeFormats.CREW_TIMING;

export const getServiceGroupIds = (sortedServiceGroups, currentView) => {
  const serviceGroupIds = [];

  sortedServiceGroups.forEach((serviceGroup) => {
    serviceGroup.sites.some((elem) => {
      const match = currentView.sites.find((x) => elem.id === x.id);

      if (typeof match !== 'undefined') {
        serviceGroupIds.push(serviceGroup.id);
      }
    });
  });
  return [...new Set(serviceGroupIds)];
};

export const getWeekNames = (numbers) => {
  const names = [];
  numbers.forEach((num) => {
    names.push(WeekNames[num]);
  });
  return names;
};

export const getWeekNumbers = (weeknames) => {
  const numbers = [];
  weeknames?.forEach((name) => {
    numbers.push(WeekNames.indexOf(name));
  });
  return numbers;
};

export const useServiceGroups = () => {
  //Gives list of all service groups
  const { sortedServiceGroups } = useStoreState((state) => state.sites);

  //Gives list of sites currently selected
  const { currentView } = useStoreState((state) => state.view);
  const [serviceGroups, setServiceGroups] = useState([]);

  const getServiceGroups = useCallback(() => {
    const serviceGroups = [];
    sortedServiceGroups.forEach((serviceGroup) => {
      // List of all selected sites with and without service groups
      let ListOfServiceGroupSites = currentView.sites.filter((elem) =>
        serviceGroup.sites.some((x) => elem.id !== x.id),
      );
      if (ListOfServiceGroupSites) {
        serviceGroup.sites.some((o2) => {
          const match = currentView.sites.find((o1) => o1.id === o2.id);
          // Array Push for service group which is under service Group
          if (typeof match !== 'undefined') {
            serviceGroups.push(serviceGroup.id);
          }
        });
      }
    });
    return [...new Set(serviceGroups)];
  }, [currentView.sites, sortedServiceGroups]);
  const filteredServiceGroups = useMemo(() => getServiceGroups(), [getServiceGroups]);
  useEffect(() => {
    setServiceGroups(filteredServiceGroups);
  }, [filteredServiceGroups]);
  if (serviceGroups.length > 1) {
    return [...new Set(serviceGroups)];
  } else {
    sortedServiceGroups.forEach((serviceGroup) => {
      // List of all selected sites with and without service groups
      let ListOfServiceGroupSites = currentView.sites.filter((elem) =>
        serviceGroup.sites.some((x) => elem.id !== x.id),
      );
      // List of sites only under service Groups
      let ListOfSites = serviceGroup.sites.filter((o1) =>
        currentView.sites.some((o2) => o1.id === o2.id),
      );
      if (ListOfSites && ListOfSites.length) {
        let SitesMatch = ListOfServiceGroupSites.filter(
          (e) => !ListOfSites.find((a) => e.id === a.id),
        );
        // Array Push for all sites
        SitesMatch.forEach((result) => {
          serviceGroups.push(result.id);
        });
      }
      // Array Push for sites which is not coming under service Group
      else if (ListOfServiceGroupSites && ListOfServiceGroupSites.length === 1) {
        ListOfServiceGroupSites.some((result) => {
          if (serviceGroups.length !== 1) {
            if (serviceGroups.includes(result.id) === false) serviceGroups.push(result.id);
          }
        });
      }
    });
  }
  return [...new Set(serviceGroups)];
};

const getDatesDiff = (start_date, end_date, date_format = dateFormat) => {
  if (start_date && end_date) {
    const getDateAsArray = (date) => {
      return dayjs(date.split(/\D+/), date_format);
    };
    const diff = getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    const dates = [];
    for (let i = 0; i < diff; i++) {
      const nextDate = getDateAsArray(start_date).add(i, 'day');
      dates.push(nextDate.format(date_format));
    }
    return dates;
  }
};
const getMultiCrewSplit = (member, crewData, id, crewEndDate, range) => {
  const endDte = dayjs(range?.end?.$d).format(dateFormat);
  const crewEndDte = crewEndDate ? crewEndDate : endDte;
  let matchingValues = [];
  crewData.map((crew) => {
    if (crew._id !== id) {
      let sameMemberList = crew.members.find((mem) => mem.member_id === member.member_id);
      if (sameMemberList) {
        let date_log1;
        let date_log2;
        let isMatch;
        const newObjt = {};
        sameMemberList.time_periods.forEach((value2) => {
          const { startDate, endDate } = value2;
          date_log1 =
            startDate && endDate
              ? getDatesDiff(startDate, endDate)
              : getDatesDiff(startDate, crewEndDte);
          member.time_periods.forEach((value) => {
            date_log2 =
              value.startDate && value.endDate
                ? getDatesDiff(value.startDate, value.endDate)
                : getDatesDiff(value.startDate, crewEndDte);
            isMatch =
              date_log2 &&
              date_log2.filter((el) => {
                return (
                  date_log1 &&
                  date_log1.some((el2) => {
                    return el == el2;
                  })
                );
              });
          });
        });
        if (isMatch && isMatch.length) {
          newObjt['date_log'] = isMatch;
          matchingValues.push(newObjt);
        }
      } else {
        return getActvOrInactv(member, crewEndDate);
      }
    }
  });
  return matchingValues;
};
const getActvOrInactv = (member, crewEndDate) => {
  const newArr = [];
  const currentDate = dayjs().format(dateFormat);
  member?.time_periods?.find((value) => {
    const obj = {};
    let date_log;
    const endDt = crewEndDate ? crewEndDate : currentDate;
    if (value?.startDate && value?.endDate) {
      date_log = getDatesDiff(value?.startDate, value?.endDate);
    } else {
      date_log = getDatesDiff(value?.startDate, endDt);
    }
    obj['date_log'] = date_log;
    newArr.push(obj);
  });
  return newArr;
};
const getCommonDate = (member, crewData, id, crewEndDate, isSplit, range) => {
  if (isSplit && crewData) {
    return getMultiCrewSplit(member, crewData, id, crewEndDate, range);
  } else {
    return getActvOrInactv(member, crewEndDate);
  }
};
const getMergeDate = (member, crewData, id, crewEndDate, isSplit, range) => {
  const commonDtList = getCommonDate(member, crewData, id, crewEndDate, isSplit, range);
  if (commonDtList) {
    let commonDtArr = [];
    for (const i in commonDtList) {
      commonDtArr.push(commonDtList[i].date_log);
    }
    let mergeArr = [];
    commonDtArr.forEach((item) => {
      mergeArr = mergeArr.concat(item);
    });
    commonDtArr = mergeArr;
    return commonDtArr;
  }
};
const isSplitOrNot = (dateList, strtDte, endDte) => {
  const betweenDateList = getDatesDiff(strtDte, endDte);
  const splitDayslist = betweenDateList.filter((date) => dateList.includes(date));
  return splitDayslist && splitDayslist?.length ? true : false;
};
const getDay = (dateList, startDate) => {
  return dateList.find((date) => dayjs(startDate).isSame(date));
};
const getWeek = (dateList, weekStartDate, weekEndDate) => {
  return isSplitOrNot(dateList, weekStartDate, weekEndDate);
};
const getMonth = (dateList, wkStrtDte) => {
  const allMonDates = dateList.filter(
    (resDate) => dayjs(wkStrtDte).format('MMMM') === dayjs(resDate).format('MMMM'),
  );
  return allMonDates && allMonDates.length ? true : false;
};
const getYear = (dateList, quarterStart, quarterEnd) => {
  return isSplitOrNot(dateList, quarterStart, quarterEnd);
};
const getActive = (dateList, now) => {
  return dateList.find((date) => dayjs(now).isSame(date));
};
const getRange = (range, resultDate, isSplit) => {
  const now = dayjs().format(dateFormat);
  const startDate = dayjs(range?.start?.$d).format(dateFormat);
  const endDate = dayjs(range?.end?.$d).format(dateFormat);
  if (range?.type == 'day' && isSplit) {
    return getDay(resultDate, startDate);
  } else if (range?.type == 'isoweek' && isSplit) {
    return getWeek(resultDate, startDate, endDate);
  } else if (range?.type == 'month' && isSplit) {
    return getMonth(resultDate, startDate);
  } else if (range?.type == 'quarter' && isSplit) {
    return getYear(resultDate, startDate, endDate);
  } else {
    return getActive(resultDate, now);
  }
};
const getActiveAndSplitVal = (member, crewData, id, crewEndDate, isSplit, range) => {
  const allCommonDates = getMergeDate(member, crewData, id, crewEndDate, isSplit, range);
  return [...new Set(allCommonDates)];
};
export const isWorkerSplit = (member, crewData, id, crewEndDate, range) => {
  const isSplit = true;
  const allSplitValues = getActiveAndSplitVal(member, crewData, id, crewEndDate, isSplit, range);
  return getRange(range, allSplitValues, isSplit);
};
export const isWorkerActive = (member, crewData, id, crewEndDate, range) => {
  const isSplit = false;
  const allActiveValues = getActiveAndSplitVal(member, crewData, id, crewEndDate, isSplit, range);
  return getRange(range, allActiveValues, isSplit);
};
export const isWorkerSplitByAcross = (member, crewData, id) => {
  let memberExist;
  crewData.map((crew) => {
    if (crew._id !== id) {
      let isSameMember = crew.members.some((mem) => mem.member_id === member.member_id);
      if (isSameMember) {
        memberExist = true;
      }
    }
  });
  return memberExist;
};

export const getSortCrewMember = (crew) => {
  crew?.members.sort((a, b) => (a?.index < b?.index ? -1 : 1));
  return crew;
};
