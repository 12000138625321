import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { BoldSearch } from '@ge/components/bold-search';
import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { StatusColor } from '@ge/tokens/colors/colors';
import { elevations } from '@ge/tokens/elevations';

const SelectContainer = styled.div`
  margin-left: 30px;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'initial')};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'initial')};
`;

const SelectMenuWrapper = styled.div`
  position: relative;
`;

const SelectLebel = styled.label`
  color: ${(props) => props.theme.select.labelTextColor};
`;

const SelectButton = styled.div`
  background: ${(props) => props.theme.select.secondaryBackground};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  cursor: default;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
  min-height: 25px;
  padding: 2px 8px;
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER} !important` : '')};
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SelectMenu = styled.div`
  background: ${(props) => props.theme.menu.backgroundColor};
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.15);
  left: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: opacity 0s;
  visibility: hidden;
  width: 273px;
  z-index: ${elevations.N1};
  &.show {
    opacity: 1;
    transition: opacity 0.1s 0.1s;
    visibility: visible;
    z-index: ${elevations.P10};
  }
`;

const StyledFilter = styled(Filter)`
  margin: 8px 10px;
  position: relative;
  input {
    background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.input.borderNavbarInput};
    border-radius: 2px;
    caret-color: ${(props) => props.theme.newTaskDialog.searchCaret};
    color: ${(props) => props.theme.newTaskDialog.searchText};
    height: 28px;
    padding: 6px 8px;
    width: 100%;
    ::placeholder {
      font-size: 14px;
      font-style: italic;
    }
    :focus {
      outline: none;
    }
  }
  .search-icon {
    position: absolute;
    top: 7px;
    right: 7px;
  }
`;

const SelectMenuOptions = styled.div`
  max-height: 200px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const Footer = styled.div`
  box-shadow: 0 1px 7px 7px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  position: relative;
  .cancel-button {
    background: none;
    font-size: 11px;
    margin-left: 7px;
    padding-left: 5px;
    text-transform: uppercase;
  }
  .selected-count {
    margin-right: 5px;
  }
  .select-button {
    margin-right: 12px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
  padding: 8px 10px;
  border-top: 1px solid ${(props) => props.theme.newTaskDialog.selectMenuBorder};
  &:first-of-type {
    border-top: none;
  }
  span {
    margin-left: 10px;
    flex: 1;
    color: ${(props) => props.theme.select.primaryTextColor};
  }
  &:hover {
    background-color: ${(props) => props.theme.newTaskDialog.selectMenuHoverBackground};
    border-color: ${(props) => props.theme.newTaskDialog.selectMenuHoverBorder};
  }
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.select.chevronColor,
}))`
  margin-top: 3px;
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))`
  margin-right: 7px;
  margin-top: 9px;
`;

const SelectMenuFooter = ({ count, onCancel, onApply }) => {
  const { t, ready } = useTranslation(['general'], { useSuspense: false });

  if (!ready) return null;

  return (
    <Footer>
      <Button className="cancel-button" onClick={onCancel} type="button">
        {t('general:cancel', 'Cancel')}
      </Button>
      <span className="selected-count">
        {count} {t('form.selected', 'Selected')}
      </span>
      <Button className="select-button" onClick={onApply} primary type="button">
        {t('form.select', 'Select')}
      </Button>
    </Footer>
  );
};
export const MultiSelectMetaField = ({
  defaultValue,
  label,
  maxWidth,
  metadata,
  minWidth,
  name,
  options,
  excludeSearch,
  showBadge,
  isSelectAll,
}) => {
  const { t, ready } = useTranslation(['general'], { useSuspense: false });

  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selected, setSelected] = useState([]);

  const { required } = metadata;
  const { control, errors } = useFormContext();

  const filteredOptions = useMemo(
    () =>
      filterText
        ? options.filter((v) => {
            if (excludeSearch.includes(v.value)) return true;

            return String(v.label).toLowerCase().includes(String(filterText).toLowerCase());
          })
        : options,
    [filterText, options, excludeSearch],
  );

  const handleSearch = useCallback((e) => {
    if (e.target.value) {
      setFilterText(e.target.value);
    } else {
      setFilterText('');
    }
  }, []);

  const renderSelectText = useCallback(
    (selected) => {
      switch (selected?.length ?? 0) {
        case 0:
          return t('form.select_options', 'Select Option(s)');
        case 1:
          return filteredOptions.find((v) => v.value === selected[0])?.label ?? '';
        default:
          return `${selected.length} ${t('form.options_selected', 'Selected')}`;
      }
    },
    [t, filteredOptions],
  );

  const handleSelectAll = useCallback(
    (checked) => {
      if (!checked || !filteredOptions?.length) {
        setSelected([]);
      } else {
        setSelected(filteredOptions.map((item) => item.value));
      }
    },
    [filteredOptions, setSelected],
  );

  const getCheckedState = useCallback(() => {
    if (!selected?.length || !filteredOptions?.length) {
      return CheckedState.UNCHECKED;
    }
    if (selected.length > 0 && selected.length < filteredOptions.length) {
      return CheckedState.INDETERMINATE;
    }
    return CheckedState.CHECKED;
  }, [selected, filteredOptions]);

  if (!ready) return null;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ required: required }}
      render={({ onChange, value }) => (
        <SelectContainer maxWidth={maxWidth} minWidth={minWidth}>
          <SelectLebel>
            {label}
            {required && <>*</>}
          </SelectLebel>
          <SelectMenuWrapper>
            <SelectButton
              errors={errors}
              id={name}
              onClick={() => {
                setSelected(value);
                setShowSelectMenu((prev) => !prev);
              }}
            >
              <span>{renderSelectText(value)}</span>
              <ChevronIcon />
            </SelectButton>
            <SelectMenu className={showSelectMenu ? 'show' : ''}>
              {filteredOptions?.filter((v) => !excludeSearch.includes(v.value)).length ? (
                <StyledFilter
                  text={filterText}
                  onChange={handleSearch}
                  placeholder={t('form.search', 'Search')}
                  hideResultsOnBlur={false}
                />
              ) : null}
              <SelectMenuOptions>
                {isSelectAll ? (
                  <StyledCheckbox
                    checkState={getCheckedState()}
                    label={t('view_selector.select_all', 'Select All')}
                    onChange={handleSelectAll}
                  />
                ) : (
                  ``
                )}
                {filteredOptions.map((item) => (
                  <StyledCheckbox
                    key={item.value}
                    label={<BoldSearch text={item.label} textBold={filterText} />}
                    checkState={
                      selected?.includes(item.value) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                    }
                    onChange={(checked) => {
                      const values = new Set(selected);
                      if (checked) {
                        values.add(item.value);
                      } else {
                        values.delete(item.value);
                      }
                      setSelected([...values]);
                    }}
                  />
                ))}
              </SelectMenuOptions>
              <SelectMenuFooter
                count={selected?.length ?? 0}
                onCancel={() => {
                  setShowSelectMenu(false);
                }}
                onApply={() => {
                  setShowSelectMenu(false);
                  onChange(selected);
                }}
              />
            </SelectMenu>
          </SelectMenuWrapper>
          {showBadge &&
            value
              ?.filter((v) => !excludeSearch.includes(v))
              .map((v) => (
                <StyledBadge
                  key={v}
                  label={v}
                  onDismiss={() => {
                    onChange(value.filter((val) => val !== v) ?? []);
                  }}
                />
              ))}
        </SelectContainer>
      )}
    />
  );
};

SelectMenuFooter.propTypes = {
  count: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

MultiSelectMetaField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  label: PropTypes.string,
  maxWidth: PropTypes.number,
  metadata: PropTypes.object.isRequired,
  minWidth: PropTypes.number,
  options: PropTypes.array,
  excludeSearch: PropTypes.array,
  showBadge: PropTypes.bool,
  isSelectAll: PropTypes.bool,
};

MultiSelectMetaField.defaultProps = {
  defaultValue: [],
  label: '',
  maxWidth: null,
  minWidth: null,
  options: [],
  excludeSearch: [],
  showBadge: true,
};
