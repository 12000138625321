import { globalColors } from '@ge/tokens/colors';

export const defaultOptions = {
  chart: {
    animation: false,
    backgroundColor: 'transparent',
    type: 'area',
    showAxes: true,
    spacingRight: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    marginTop: 20,
  },
  title: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    tickLength: 5,
    tickInterval: 2,
    startOnTick: true,
    gridLineWidth: 0,
    min: 0,
    max: 25,
    title: {
      text: null,
    },
    labels: {
      enabled: true,
    },
    minPadding: 0,
    maxPadding: 0,
    visible: true,
  },
  yAxis: {
    visible: true,
    opposite: true,
    min: 0,
    tickInterval: 2,
    title: {
      text: null,
    },
    labels: {
      align: 'left',
      style: {
        fontSize: '10px',
      },
      x: -10,
      y: -5,
    },
    tickPosition: 'inside',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    area: {
      color: globalColors.white,
      fillOpacity: 0.1,
      marker: {
        radius: 1,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat: null,
        pointFormat: null,
      },
    },
  },
  series: [
    {
      type: 'area',
    },
  ],
};
