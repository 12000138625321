import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader';
import {
  Capability,
  PermissionScope,
  TaskTemplateModes,
  TaskTemplateSections,
  EntityType,
  CaseSourceMapping,
  TaskSourceField,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { useTaskTemplate, useSiteDetails } from '@ge/shared/data-hooks';
import { typography } from '@ge/tokens';

import { ExpectedPartsSection } from './sections/expected-parts-section/expected-parts-section';
import { LaborEstimatesSection } from './sections/labor-estimates-section/labor-estimates-section';
import { NoteAttachmentSection } from './sections/note-attachment-section/note-attachment-section';
import { RecurrenceSection } from './sections/recurrence-section/recurrence-section';
import { ScheduleSection } from './sections/schedule-section/schedule-section';
import { SendNotificationSection } from './sections/send-notification-section/send-notification-section';
import { TaskDetailSection } from './sections/task-detail-section/task-detail-section';

const AddWorkStandardsButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  margin-left: 13rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

const AddWorkStandards = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const permissionScopeMap = {
  [TaskTemplateModes.CREATE]: PermissionScope.CREATE,
  [TaskTemplateModes.EDIT]: PermissionScope.EDIT,
  [TaskTemplateModes.VIEW]: PermissionScope.VIEW,
};
// TODO: currently new task dialog expects work-plan edit permission for schedule section
// if that changes to create then can remove this and just use permission scope map
const workPlanScopeMap = {
  ...permissionScopeMap,
  [TaskTemplateModes.CREATE]: PermissionScope.EDIT,
};

export const TaskTemplate = ({
  taskPanelControls,
  task,
  taskSources: _taskSources,
  taskWorkScopes: _taskWorkScopes,
  templateMode,
  entity,
  entType: _entityType,
  isLoading: _isLoading,
  isCalledfromTaskEscalationColumn,
  setWorkStandardDialog,
  workStandardsData,
  setWorkStandardsData,
}) => {
  const {
    taskState: {
      entityType,
      taskSource,
      taskWorkScope,
      setTaskTemplate,
      setTimezone,
      setTaskSource,
      setTaskSources,
      setTaskWorkScope,
      setTaskWorkScopes,
    },
  } = useContext(TaskContext);

  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });

  const { taskWorkStandardsFlag } = useStoreState((state) => state.tenant.featureFlags);

  // Set the taskSources via the data hook one level above eg. new task dialog or entity detail panel
  useEffect(() => {
    setTaskSources(_taskSources);
    setTaskWorkScopes(_taskWorkScopes);
  }, [_taskSources, setTaskSources, _taskWorkScopes, setTaskWorkScopes]);

  // Data hook returns template based on taskType. Then the task-source-select, rendered by this template, manages taskSource
  // Note: changing the entity type in the templates, will trigger the taskSources to change, in turn changing the taskSource
  const { isLoading, data: template } = useTaskTemplate(taskSource);
  // Set the initial task source if a taskSource is provided from the prop, otherwise set to the first entry in taskSources

  useEffect(() => {
    //consider workscope defaultselection from template
    const workScopeDefaultSelection =
      template?.sections?.[0]?.metadata?.workScope?.defaultSelection;
    const workScopeDefault = workScopeDefaultSelection ?? '';

    if (_entityType != null && _entityType == EntityType.CASE) {
      let filtered = Object.entries(CaseSourceMapping).find(
        ([key]) => entity.source.toUpperCase() === key,
      )?.[0];

      setTaskSource(CaseSourceMapping[filtered] ?? '');
      setTaskWorkScope(taskWorkScope ? taskWorkScope : workScopeDefault);
    } else if (task?.source) {
      //if (templateMode == TaskTemplateModes.CREATE && taskSource) task.source = taskSource;
      //handle task radio selection asset / site
      if (templateMode == TaskTemplateModes.CREATE) {
        let existingSource = Object.entries(_taskSources).find(([key]) => taskSource === key)?.[0];
        if (taskSource) task.source = taskSource;
        if (!existingSource && _entityType == EntityType.SITE)
          task.source = Object.keys(_taskSources ?? [{}])[0];
        if (
          !existingSource &&
          _entityType == EntityType.ASSET &&
          task.source !== TaskSourceField.REALTIMECASES
        )
          task.source = TaskSourceField.MANUAL;
        setTaskSource(task.source);
      } else setTaskSource(task.source ?? Object.keys(_taskSources ?? [{}])[0]);

      let existingWorkScope = Object.entries(_taskWorkScopes).find(
        ([key]) => taskWorkScope === key,
      )?.[0];
      if (task?.workScope) setTaskWorkScope(existingWorkScope ? taskWorkScope : task.workScope);
      else if (_entityType == EntityType.ASSET)
        setTaskWorkScope(existingWorkScope ? taskWorkScope : workScopeDefault);
      else
        setTaskWorkScope(
          existingWorkScope ? taskWorkScope : Object.keys(_taskWorkScopes ?? [{}])[0],
        );
    } else {
      setTaskSource(Object.keys(_taskSources ?? [{}])[0]);
      setTaskWorkScope(
        _entityType == EntityType.ASSET
          ? workScopeDefault
          : Object.keys(_taskWorkScopes ?? [{}])[0],
      );
    }
  }, [
    entityType,
    task?.source,
    task?.workScope,
    _taskSources,
    setTaskSource,
    _taskWorkScopes,
    setTaskWorkScope,
    entity,
    _entityType,
    taskSource,
    task,
    templateMode,
    taskWorkScope,
    template?.sections,
  ]);

  const siteId = task?.asset?.site?.id ?? task?.site?.id;

  const {
    data: { site },
    isLoading: isSiteDetailsLoading,
  } = useSiteDetails({ siteId });
  const siteTimezone = site?.timezone;

  useEffect(() => {
    setTaskWorkScope('');
  }, [setTaskWorkScope]);

  // Set template for use in other areas of task templates eg. close task template or entity details resolution tab
  useEffect(() => {
    setTaskTemplate(template);
  }, [setTaskTemplate, template]);

  useEffect(() => {
    setTimezone(siteTimezone);
  }, [setTimezone, siteTimezone]);

  if (isLoading || isSiteDetailsLoading || _isLoading) return <MiniLoader />;

  const requiredWorkPlanScope = { [workPlanScopeMap[templateMode]]: true };

  return (
    <>
      {template?.sections?.map((section, i) => {
        switch (section.type) {
          case TaskTemplateSections.TASK_DETAIL:
            return (
              <TaskDetailSection
                taskPanelControls={taskPanelControls}
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
                timezone={siteTimezone}
                isCalledfromTaskEscalationColumn={isCalledfromTaskEscalationColumn}
              />
            );
          case TaskTemplateSections.RECURRENCE:
            return (
              <RecurrenceSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
              />
            );
          case TaskTemplateSections.LABOR_ESTIMATES:
            return (
              <>
                {taskWorkStandardsFlag && templateMode === 'edit' && (
                  <div>
                    <AddWorkStandardsButton
                      onClick={() => setWorkStandardDialog(true)}
                      type="button"
                    >
                      <AddWorkStandards />
                      {t('work_standard.apply_work_standard', 'APPLY WORK STANDARDS')}
                    </AddWorkStandardsButton>
                  </div>
                )}
                <LaborEstimatesSection
                  key={i}
                  section={section}
                  task={task}
                  templateMode={templateMode}
                  workStandardsData={workStandardsData}
                />
              </>
            );
          case TaskTemplateSections.SCHEDULE:
            // TODO: add ability to drop auth down a level when condition isn't met
            // rather than passing in another auth render to fallback
            /* added workPlanMode prop in fallback ScheduleSection component to handle schedule section in
               create task mode for users with no work plan edit permissions */
            return (
              <AuthRender
                capability={Capability.WORK_PLAN}
                description="Schedule section - edit"
                fallback={
                  <AuthRender
                    capability={Capability.WORK_PLAN}
                    description="Schedule section - view"
                    siteIds={[siteId]}
                    view
                  >
                    <ScheduleSection
                      section={section}
                      task={task}
                      templateMode={templateMode}
                      workPlanMode={TaskTemplateModes.VIEW}
                    />
                  </AuthRender>
                }
                key={i}
                siteIds={[siteId]}
                {...requiredWorkPlanScope}
              >
                <ScheduleSection section={section} task={task} templateMode={templateMode} />
              </AuthRender>
            );
          case TaskTemplateSections.EXPECTED_PARTS:
            return (
              <ExpectedPartsSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
                workStandardsData={workStandardsData}
                setWorkStandardsData={setWorkStandardsData}
              />
            );
          case TaskTemplateSections.NOTE_ATTACHMENT:
            return (
              <NoteAttachmentSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
              />
            );
          case TaskTemplateSections.SEND_NOTIFICATION:
            return (
              <SendNotificationSection
                key={i}
                section={section}
                task={task}
                templateMode={templateMode}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

TaskTemplate.propTypes = {
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  task: PropTypes.object,
  taskTypes: PropTypes.object,
  taskSources: PropTypes.object,
  taskWorkScopes: PropTypes.object,
  taskPanelControls: PropTypes.node,
  entity: PropTypes.object,
  entType: PropTypes.string,
  isLoading: PropTypes.string,
  isCalledfromTaskEscalationColumn: PropTypes.bool,
  setWorkStandardDialog: PropTypes.func,
  workStandardsData: PropTypes.object,
  setWorkStandardsData: PropTypes.func,
};

TaskTemplate.defaultProps = {
  task: {},
  taskTypes: {},
  taskSources: {},
  taskWorkScopes: {},
  taskPanelControls: null,
  isLoading: null,
  isCalledfromTaskEscalationColumn: null,
  setWorkStandardDialog: () => {},
  workStandardsData: {},
  setWorkStandardsData: () => {},
};
