import styled from 'styled-components';

import { globalColors } from '@ge/tokens/colors';

export const ContentBlock = styled.div`
  padding: 20px;
  background: ${globalColors.grey7};
  font-family: 'Museo Sans';
  font-weight: 300;
`;
