import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Capability, PermissionScope } from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';

import { postAssetTasks } from '../services/asset-tasks.js';

export const useAssetNotCompletedTasks = (assetIds) => {
  const { isAuthorized } = useAuth();
  const isAuthorizedToGetAssetTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  });
  const {
    data = null,
    error,
    isLoading,
  } = useQuery(['getAssetNotCompletedTastks', assetIds], () => postAssetTasks(assetIds), {
    enabled: !!assetIds && isAuthorizedToGetAssetTasks,
    ...Config.EXECUTE_ONCE,
  });
  return useMemo(
    () => ({
      data,
      error,
      isLoading,
    }),
    [data, error, isLoading],
  );
};
