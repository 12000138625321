import { action, computed, thunk, persist } from 'easy-peasy';
import memoize from 'memoizerific';

import { RibbonFilterDefault } from '@ge/feat-monitor/models';
import { fetchMonitorCaseTable, fetchMonitorSites } from '@ge/feat-monitor/services';
import { filterByView } from '@ge/shared/util/view-utils';
import { sorter } from '@ge/util/metric-sorter';

// Define initial state
const defaultMonitorState = {
  monitorSitesTable: [],
  ribbonFilter: persist(RibbonFilterDefault, {
    storage: 'localStorage',
    mergeStrategy: 'overwrite',
  }),
};

// Actions
const monitorActions = {
  /**
   * Reset state to defaults
   */
  resetMonitor: action((state) => {
    /* eslint-disable-next-line no-unused-vars */
    state = Object.assign(state, defaultMonitorState);
  }),

  fetchMonitorSitesTable: thunk(async (actions, payload, { fail, getStoreActions }) => {
    const { sortMetric, sortDirection } = payload;

    try {
      const {
        data,
        entities: { regions },
      } = await fetchMonitorSites(sortMetric, sortDirection);
      getStoreActions().regions.updateRegions(Object.values(regions));

      actions.setMonitorSitesTable(data);
    } catch (error) {
      fail(error);
    }
  }),

  fetchMonitorCaseTable: thunk(async (actions, payload, { fail }) => {
    try {
      const { data, ...rest } = await fetchMonitorCaseTable(payload);

      return { data, ...rest };
    } catch (error) {
      fail(error);
      return { data: [] };
    }
  }),

  setMonitorSitesTable: action((state, payload) => {
    state.monitorSitesTable = payload;
  }),

  setRibbonFilter: action((state, payload) => {
    state.ribbonFilter = {
      ...state.ribbonFilter,
      ...payload,
    };
  }),
};

// Listeners
const monitorListeners = {};

// Computed values
const monitorComputed = {
  getSortedSitesTable: computed(
    [
      (state) => state.monitorSitesTable,
      (state, storeState) => storeState.regions.regions,
      (state, storeState) => storeState.sites.sites,
      (state, storeState) => storeState.view.currentView,
    ],
    (monitorSitesTable, regions, sites, view) => (sortMetric, sortDirection) => {
      let siteArray = monitorSitesTable.map((data) => ({
        ...data,
        region: regions[data.region.id],
        siteId: data.site.id,
        site: sites[data.site.id],
      }));

      // Apply view filter if one is provided.
      if (view) {
        siteArray = filterByView(siteArray, view);
      }

      return siteArray.sort(sorter(sortMetric, sortDirection));
    },
  ),

  getEntityPanelCase: computed(
    [
      (_, storeState) => storeState.assets.assets,
      (_, storeState) => storeState.cases.cases,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.tasks.getTaskById,
    ],
    (assets, cases, sites, getTaskById) =>
      memoize(2)((caseId) => {
        const panelCase = cases[caseId];
        if (!panelCase) return null;

        const { taskIds = [] } = panelCase;
        const asset = assets[panelCase.asset?.id] ?? {};
        const site = sites[asset.site?.id] ?? {};
        const tasks = taskIds?.map((taskId) => getTaskById(taskId)) ?? [];

        return {
          ...panelCase,
          asset,
          site,
          tasks,
        };
      }),
  ),
};

// Compile the view store object for export
const monitorModel = {
  ...defaultMonitorState,
  ...monitorActions,
  ...monitorComputed,
  ...monitorListeners,
};

export default monitorModel;
