import { Entity } from '../entity';

/**
 * An entity representing an owner of Assets.
 */
export class Customer extends Entity {
  /**
   * Display name for the customer
   * @type {string}
   */
  name;

  /**
   * @param {Partial<Customer>} [customerObj] Customer object to use to initialize class
   */
  constructor(customerObj) {
    super(customerObj);

    // Apply all known object values
    Object.assign(this, customerObj);
  }
}
