import styled from 'styled-components';

export const ScrollbarWrapper = styled.div`
  div ::-webkit-scrollbar {
    height: 4px;
    width: 0px;
  }

  div:hover::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  div ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
  }

  div ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
    height: 4px;
  }

  // For Internet Explorer
  div {
    scrollbar-face-color: ${(props) => props.theme.scrollbar.trackBackground};
    scrollbar-track-color: ${(props) => props.theme.scrollbar.trackBackground};
  }
`;
