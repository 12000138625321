import { CalcConvexHull } from './convexhull';
import { CalcOmbb } from './ombb';
import { Vector } from './vector';

const calOrientedBoundingBox = (waypoints) => {
  // convert waypoints to Vector array
  const points = waypoints.map((pts) => new Vector(pts[0][0], pts[0][1]));
  const convexHull = CalcConvexHull(points);
  const oobb = CalcOmbb(convexHull);

  return oobb;
};

export default calOrientedBoundingBox;
