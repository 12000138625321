import { PropTypes } from 'prop-types';
import React from 'react';

import { WidgetNames } from '@ge/feat-reporting/models/widgets';

import { AttachmentsModule } from '../attachments-module';

// going with how this logic is being handled for tables
const getListByName = (name) => {
  switch (name) {
    case WidgetNames.ATTACHMENTS:
      return <AttachmentsModule name={name} />;
    default:
      // temporary content for modules not yet implemented
      return (
        <div
          style={{
            height: 50,
            display: 'flex',
            alignzItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>Coming Soon</p>
        </div>
      );
  }
};

export const ListModule = ({ name }) => {
  const module = getListByName(name);

  return <>{module}</>;
};

ListModule.propTypes = {
  name: PropTypes.string.isRequired,
};
