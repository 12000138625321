import { AlertType } from '@ge/models/constants';

export const WorkerPresentTemplate = {
  _id: 'bde8d4af-29bc-43be-879f-294b3aa05eae',
  alertType: AlertType.WORKER_PRESENT,
  entrypoint: 'string',
  sections: [
    {
      collapsed: false,
      hidden: false,
      metadata: {
        block: {
          position: {
            col: 1,
            row: 1,
            span: 12,
          },
          type: 'blockedActions',
          values: ['Block Commands'],
        },
        contactInfo: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          position: {
            col: 1,
            row: 4,
            span: 12,
          },
          type: 'text',
        },
        requestedBy: {
          create: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          position: {
            col: 1,
            row: 2,
            span: 5,
          },
          type: 'text',
        },
        requestedTime: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          defaultSelection: 'currentDate',
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          position: {
            col: 2,
            row: 2,
            span: 7,
          },
          type: 'dateTime',
        },
        reason: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          position: {
            col: 1,
            row: 3,
            span: 12,
          },
          type: 'text',
        },
        validTo: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          position: {
            col: 1,
            row: 5,
            span: 7,
          },
          type: 'dateTime',
        },
      },
      type: 'body',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        description: {
          create: {
            readOnly: false,
            required: false,
            hidden: false,
          },
          edit: {
            readOnly: false,
            required: false,
            hidden: false,
          },
        },
      },
      type: 'description',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        sendNotification: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'send_notification',
    },
  ],
  operations: ['create', 'edit'],
  tenantId: 'dfsa',
};
