import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TaskTemplateModes } from '@ge/models/constants';
import { TextareaMetaField } from '@ge/shared/components/meta-fields/textarea-meta-field';
import { StyledCollapsiblePanel } from '@ge/shared/components/tasks/task-template-shared';

import { FormViewField } from '../form-shared';

const DescriptonCollapsiblePanel = styled(StyledCollapsiblePanel)`
  margin-left: 0;

  #description {
    box-sizing: border-box;
    width: 100%;
  }

  #descriptionView {
    margin-left: 0;
  }
`;

export const DescriptionField = ({ defaultValue, mode, name, namespace, ...metadata }) => {
  const { ready, t } = useTranslation([namespace.name], { useSuspense: false });
  const { control } = useFormContext();
  const value = useWatch({ control, defaultValue, name });
  const [triggerPanelResize, setTriggerPanelResize] = useState();

  // would be nice to pull this behavior out into a reusable collapsible textarea
  const handleResize = useCallback(() => {
    setTriggerPanelResize((prev) => !prev);
  }, [setTriggerPanelResize]);

  if (!(ready && metadata) || metadata.hidden) {
    return null;
  }

  const collapsed = metadata.collapsed && !value;
  const translations = {
    headerLabel: t(`${namespace.key}.form.description.label`, 'Description'),
    placeholderLabel: t(`${namespace.key}.form.description.placeholder_label`, 'Add a description'),
  };

  return (
    <DescriptonCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{translations.headerLabel}</h4>}
      plusIcon
      triggerHeight={triggerPanelResize}
    >
      {[TaskTemplateModes.CREATE, TaskTemplateModes.EDIT].includes(mode) ? (
        <TextareaMetaField
          defaultValue={defaultValue}
          metadata={metadata}
          maxLength={metadata.maxLength}
          name={name}
          placeholder={translations.placeholderLabel}
          onResize={handleResize}
        />
      ) : (
        <FormViewField id="descriptionView" value={value} />
      )}
    </DescriptonCollapsiblePanel>
  );
};

DescriptionField.propTypes = {
  collapsed: PropTypes.bool,
  defaultValue: PropTypes.string,
  hidden: PropTypes.bool,
  maxLength: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(TaskTemplateModes)),
  name: PropTypes.string,
  namespace: PropTypes.object,
};

DescriptionField.defaultProps = {
  collapsed: true,
  defaultValue: undefined,
  hidden: false,
  mode: TaskTemplateModes.VIEW,
  name: 'description',
};
