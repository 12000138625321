import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

// keyframes are currently defined in the web-client, could move into packages so can be shared here too
export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const IconAnimation = {
  rotate: css`
    animation: ${rotate} 2s linear infinite;
  `, // can add support for different speeds
};

export const StyledIcon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.color};
  transform: rotate(${(props) => props.rotate}deg);
  ${({ animate }) => IconAnimation[animate]}
  &:hover {
    fill: ${(props) => props.hover};
  }
`;

export const Icon = ({ className, color, size, icon, rotate, viewbox, animate, hover }) => (
  <StyledIcon
    animate={animate}
    color={color}
    width={`${size}px`}
    height={`${size}px`}
    viewBox={viewbox}
    className={className}
    rotate={rotate}
    hover={hover}
  >
    <path d={icon} />
  </StyledIcon>
);

Icon.propTypes = {
  animate: PropTypes.oneOf(Object.keys(IconAnimation)),
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  hover: PropTypes.string,
  size: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  rotate: PropTypes.number,
  viewbox: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  rotate: 0,
  viewbox: '0 0 20 20',
  hover: '',
};
