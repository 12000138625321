import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { SitesTableCategories } from '@ge/feat-analyze/models';
import { KpiCategoryDefs } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { roundNumber } from '@ge/util';
import { sorter } from '@ge/util/metric-sorter';

/**
 * Hook takes the result of KPI call for sites and produces an array that the table component can consume.
 * @param allSitesData
 * @param tableSortMetric
 * @param tableSortDirection
 * @returns {{sites: *[]}|{sites: *}}
 */
const useSitesFromSiteKpi = ({
  allSitesData = [],
  filters,
  filterSequence,
  search,
  tableSortMetric,
  tableSortDirection,
}) => {
  const sitesPlatforms = useStoreState((state) => state.sites.sitesPlatforms);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);
  const { getRegionById } = useStoreState((state) => state.regions);

  const { isLoading, sites } = useMemo(() => {
    if (Object.keys(allSitesData).length === 0) return { sites: [] };

    const isLoading = SitesTableCategories.some((category) => allSitesData?.[category]?.isLoading);

    const kpiLookup = Object.entries(allSitesData).reduce(
      (lookup, [category, { entityData = [], ...categoryData }]) => {
        entityData.forEach(({ entity: { id, name }, value }) => {
          const site = getSiteById(id);
          lookup.sites[id] = {
            ...lookup.sites[id],
            siteId: id,
            site: name,
            regionName: getRegionById(site?.region?.id)?.name,
            platform: sitesPlatforms[id],
            platForm: sitesPlatforms[id]?.join(', ') ?? '',
            [category]: {
              value: roundNumber(value),
              aggregateValue: value,
              units: categoryData.units,
            },
          };
          lookup.categories[category] = category;
        });
        // const kpis = Object.keys(kpiLookup.categories);
        return lookup;
      },
      { sites: {}, categories: {} },
    );

    const kpis = Object.keys(kpiLookup.categories);
    if (kpis.length === 1 && kpis[0] === KpiCategoryDefs.AVAILABILITY_CONTRACT) {
      return { isLoading, sites: [] };
    }
    return {
      isLoading,
      sites: Object.values(kpiLookup.sites)?.sort(sorter(tableSortMetric, tableSortDirection)),
    };
  }, [
    allSitesData,
    sitesPlatforms,
    getRegionById,
    getSiteById,
    tableSortMetric,
    tableSortDirection,
  ]);

  const { data, filterValues } = useTableFilter({ data: sites, filterSequence, filters, search });

  return { isLoading, data, filterValues };
};

export default useSitesFromSiteKpi;
