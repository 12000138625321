import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const SearchContainer = styled.div`
  position: relative;
  margin: 6px;
  width: calc(100% - 18px);
`;

const Input = styled.input`
  display: block;
  width: calc(100% - 32px);
  height: 28px;
  border: 1px solid ${(props) => props.theme.dataExplorer.dialog.search.borderColor};
  border-radius: 2px;
  background-color: transparent;
  padding: 0 25px 0 7px;
  font-style: italic;
  color: ${(props) => props.theme.dataExplorer.dialog.search.textColor};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.dataExplorer.dialog.search.iconColor};
  }
`;

const SearchIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SEARCH,
  color: props.theme.dataExplorer.dialog.search.iconColor,
}))`
  position: absolute;
  top: 7px;
  right: 3px;
`;

export const SearchInput = ({ placeholder, onChange, value }) => (
  <SearchContainer>
    <Input type="input" placeholder={placeholder} onChange={onChange} value={value} />
    <SearchIcon />
  </SearchContainer>
);

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: '',
  onChange: () => {},
  value: '',
};

export default SearchInput;
