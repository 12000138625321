import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icons, Icon } from '@ge/components/icon';
import { Menu, MenuTable, placements } from '@ge/components/menu';
import { DateTimeFormats } from '@ge/models/constants';
import { AlertIcon } from '@ge/shared/components/alerts/alert-icon';
import { isInvalid } from '@ge/shared/util/alert-utils';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-alarms-count {
    margin-left: 10px;
  }
`;

const StyledThead = styled.tr`
  && > th {
    font-size: 10px;
    font-weight: 500;
    padding: 6px;
  }
  .alert {
    padding-right: 0;
  }
  .start-time {
    padding-left: 5px;
  }
`;

const StyledTr = styled.tr`
  td {
    font-size: 11px;
    font-weight: ${(props) => (props.isBold ? 700 : 300)};
    div {
      text-overflow: ellipsis;
      text-transform: none;
      overflow: hidden;
      white-space: nowrap;
    }
    &:first-of-type {
      display: flex;
      align-items: center;
      div {
        flex: 1;
        max-width: 100px; // Should change based on the FD designs
        margin-right: 11px;
      }
    }
    &:nth-of-type(2) {
      div {
        max-width: 200px;
      }
    }
    &:last-of-type {
      div {
        text-align: right;
        padding-right: 11px;
      }
    }
    &.alert {
      justify-content: center;
      padding-right: 0;
      & > div {
        margin: 0 0 0 4px;
      }
    }
    .duration {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 6px;
  display: inline-block;
  vertical-align: middle;
  padding: 3px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.dialog.iconColor,
}))`
  display: block;
`;

export const AlertIconMenu = ({ alerts, children, containerRef, openAlertDialog }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['alerts'], { useSuspense: false });

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e) => {
    killEventPropagation(e);
    setAnchorEl(null);
  };

  const getStartTime = (val) => dayjs(val).format(DateTimeFormats.ALERT_START_TIME);

  return (
    <div onClick={handleShowMenu}>
      {children}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.BOTTOM_START}
        onClick={(e) => {
          e.preventDefault();
          killEventPropagation(e);
        }}
      >
        <StyledTitle>
          <h4 className="menu-title-txt">{t(`table.active_alerts`, 'Active Alerts')}</h4>
          <CloseButton onClick={handleMenuClose}>
            <CloseIcon icon={Icons.CLOSE} size={10} />
          </CloseButton>
        </StyledTitle>
        <MenuTable>
          <table>
            <thead>
              <StyledThead>
                <th className="alert">{t('table.alert', 'Alert')}</th>
                <th>{t('table.type', 'Type')}</th>
                <th className="start-time">{t('table.start_time', 'Start Time')}</th>
              </StyledThead>
            </thead>
            <tbody>
              {alerts?.map((alert, id) => (
                <StyledTr
                  key={id}
                  onClick={(e) => {
                    openAlertDialog(e, alert);
                    handleMenuClose(e);
                  }}
                >
                  <td className="alert">
                    <div>
                      <AlertIcon
                        type={alert.type}
                        invalid={isInvalid(alert)}
                        large={true}
                        isGroup={alert?.isGroup}
                      />
                    </div>
                  </td>
                  <td>
                    <div>{t(`form.type.options.${alert.type}`, '')}</div>
                  </td>
                  <td>
                    {/* Todo: Update to dynamic value when we figure out what these are coming from */}
                    <div>{getStartTime(alert?.auditProperties?.createdDate)}</div>
                  </td>
                </StyledTr>
              ))}
            </tbody>
          </table>
        </MenuTable>
      </Menu>
    </div>
  );
};

AlertIconMenu.propTypes = {
  alerts: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
  openAlertDialog: PropTypes.func,
};

AlertIconMenu.defaultValues = {
  openAlertDialog: () => null,
};
