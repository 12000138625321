import classNames from 'classnames';
import dayjs from 'dayjs';
// import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import { PropTypes } from 'prop-types';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { CalendarRanges } from '@ge/models/constants';
import { typography } from '@ge/tokens';

import { PlanningContext } from '../../context/planning-provider';

import { CalendarDateLine } from './calendar-date-line';

// adding to get the current week of a date
dayjs.extend(isoWeek);

const TimelineContainer = styled.div`
  display: flex;
`;

const MonthContainer = styled.div`
  background: ${({ theme }) => theme.manage.timeline.backgroundColor};
  color: ${({ theme }) => theme.manage.timeline.textColor};
  text-align: center;
  .month-container {
    display: flex;
  }
  .title-text {
    color: ${({ theme }) => theme.manage.timeline.titleColor};
  }
  > div,
  > span {
    line-height: 30px;
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  }
`;

const Weeks = styled.div`
  display: flex;
`;

const Week = styled.div`
  border-right: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  &:last-child {
    border-right: 0;
  }
  > div {
    flex: 1;
    border-bottom: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  }
`;

const Days = styled.div`
  display: flex;
  &.quarter-days {
    height: 0px;
    visibility: hidden;
  }
  span {
    width: ${({ dayWidth }) => dayWidth}px;
    line-height: 24px;
    border-right: solid 1px transparent;
    &.isWeekend {
      color: ${({ theme }) => theme.manage.timeline.dateDarkColor};
    }
    &.isToday {
      color: ${({ theme }) => theme.manage.timeline.isTodayColor};
      font-weight: ${typography.weight.bold};
    }
    &:last-child {
      border-right: 0;
    }
  }
`;

const Counts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

const ScheduledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.manage.timeline.scheduleBadge,
}))`
  flex: none;
  margin-right: 8px;
`;

const UnscheduledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.manage.timeline.unscheduledBadge,
}))`
  flex: none;
  margin-right: 8px;
`;

const CompletedBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.manage.timeline.completedBadge,
}))`
  flex: none;
`;

const Hours = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.manage.timeline.backgroundColor};
  width: ${({ hour, sidebarWidth }) => hour * 24 + sidebarWidth}px;
  position: relative;
  > .hour {
    width: ${({ hour }) => hour}px;
    align-items: flex-end;
    display: flex;
    min-height: 30px;
    &:after {
      content: '';
      width: 1px;
      background: ${({ theme }) => theme.manage.timeline.tickColor};
      height: 10px;
      top: 10px;
      left: 0;
      display: block;
    }
    span {
      color: ${({ theme }) => theme.manage.timeline.textColor};
      font-size: 10px;
      letter-spacing: 0.45px;
      line-height: 12px;
      text-align: center;
      width: 30px;
      position: absolute;
      margin-top: 7px;
      margin-bottom: 10px;
      transform: translateX(-50%);
    }
  }
`;

const MonthWrapper = styled.div`
  display: flex;
`;

const WeekWrapper = styled.div`
  display: flex;
`;

const WeekContainer = styled.div`
  background: ${({ theme }) => theme.manage.timeline.backgroundColor};
  color: ${({ theme }) => theme.manage.timeline.textColor};
  text-align: center;
  .title-text {
    color: ${({ theme }) => theme.manage.timeline.titleColor};
  }
  > div,
  > span {
    line-height: 30px;
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  }
`;

const WeekDays = styled.div`
  display: flex;
`;

const Day = styled.div`
  border-right: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  &:last-child {
    border-right: 0;
  }
  > div {
    flex: 1;
    border-bottom: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
    &.isWeekend {
      color: ${({ theme }) => theme.manage.timeline.dateDarkColor};
    }
    &.isToday {
      color: ${({ theme }) => theme.manage.timeline.isTodayColor};
      font-weight: ${typography.weight.bold};
    }
  }
`;

const DayName = styled.div`
  width: ${({ weekWidth }) => weekWidth}px;
`;

const MonthSection = styled.div`
  min-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  flex: 1;
  border-right: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
`;

export const CalendarTimeline = ({ filter, monthCounts, weekCounts }) => {
  const {
    planningState: {
      hour,
      dayWidth,
      dayWidthQuarter,
      range,
      date,
      sidebarWidth,
      timelineSchema,
      timelineSchemaWeek,
      timelineSchemaQuarter,
      weekWidth,
    },
  } = useContext(PlanningContext);
  const dayTimeline = useMemo(() => {
    return (
      <Hours hour={hour} sidebarWidth={sidebarWidth}>
        {filter}
        {[...Array(24).keys()].map((n) => (
          <div key={n} className="hour">
            <span>{dayjs().hour(n).format('HH:00')}</span>
          </div>
        ))}
      </Hours>
    );
  }, [hour, filter, sidebarWidth]);

  const monthTimeline = useMemo(() => {
    // build out week components with days
    const weeks = [];
    Object.keys(timelineSchema).forEach((key) => {
      const { week, days } = timelineSchema[key];
      // to avoid any undefined error for any week not defined in monthCounts
      const { scheduled = 0, unscheduled = 0, completed = 0 } = monthCounts[week] ?? {};
      weeks.push(
        <Week key={key}>
          <div>{week}</div>
          <Days dayWidth={dayWidth}>
            {days.map((day) => (
              <span
                className={classNames({ isWeekend: day.isWeekend }, { isToday: day.isToday })}
                key={day.day}
              >
                {day.day}
              </span>
            ))}
          </Days>
          {days.length > 1 && (
            <Counts>
              <ScheduledBadge label={scheduled} small />
              <UnscheduledBadge label={unscheduled} small />
              <CompletedBadge label={completed} small />
            </Counts>
          )}
        </Week>,
      );
    });

    return (
      <MonthWrapper>
        {filter}
        <CalendarDateLine top />
        <MonthContainer
          daysCount={range.daysInMonth}
          dayWidth={dayWidth}
          weeks={Object.keys(timelineSchema).length}
        >
          <div className="title-text">{dayjs(date).format('MMMM')}</div>
          <Weeks>{weeks}</Weeks>
        </MonthContainer>
      </MonthWrapper>
    );
  }, [timelineSchema, filter, range.daysInMonth, dayWidth, date, monthCounts]);

  const quarterTimeline = useMemo(() => {
    // build out week components with days
    const weeks = [];
    const monthNamesObj = [];
    let currentDateInQtr = 0;
    Object.keys(timelineSchemaQuarter).forEach((key) => {
      const { week, days } = timelineSchemaQuarter[key];

      days.map((item) => {
        if (item.date.date() === 1) {
          monthNamesObj.push({ day: item.day, date: item.date });
        }
        if (item.isToday) {
          currentDateInQtr = item.day;
        }
      });

      const { scheduled = 0, unscheduled = 0, completed = 0 } = monthCounts[week] ?? {};
      weeks.push(
        <Week key={key}>
          <div>{week}</div>
          <Days dayWidth={dayWidthQuarter} className="quarter-days">
            {days.map((day) => (
              <span
                className={classNames({ isWeekend: day.isWeekend }, { isToday: day.isToday })}
                key={day.day}
              >
                {day.day}
              </span>
            ))}
          </Days>
          {days.length > 3 && (
            <Counts>
              <ScheduledBadge label={scheduled} small />
              <UnscheduledBadge label={unscheduled} small />
              <CompletedBadge label={completed} small />
            </Counts>
          )}
        </Week>,
      );
    });
    const updatedMonthObj = monthNamesObj.map((item, i, arr) => {
      if (arr[i + 1]) {
        item.diff = arr[i + 1].day - arr[i].day;
        item.width = item.diff * dayWidthQuarter + item.diff - 1;
      }
      return item;
    });
    return (
      <MonthWrapper>
        {filter}
        <CalendarDateLine currentDateInQtr={currentDateInQtr} top />
        <MonthContainer
          daysCount={range.daysInMonth}
          dayWidth={dayWidthQuarter}
          weeks={Object.keys(timelineSchemaQuarter).length}
        >
          <div className="month-container">
            {updatedMonthObj.map((item) => (
              <MonthSection key={item.day} width={item.width}>
                {dayjs(item.date).format('MMMM')}
              </MonthSection>
            ))}
          </div>
          <Weeks>{weeks}</Weeks>
        </MonthContainer>
      </MonthWrapper>
    );
  }, [timelineSchemaQuarter, filter, range.daysInMonth, dayWidthQuarter, monthCounts]);

  const weekTimeline = useMemo(() => {
    const days = [];
    Object.keys(timelineSchemaWeek).forEach((key) => {
      const { dayOfMonth, dayName, dayOfYear, isWeekend, isToday } = timelineSchemaWeek[key];
      // to avoid any undefined error for any day not defined in weekCounts
      const { scheduled = 0, unscheduled = 0, completed = 0 } = weekCounts[dayOfYear] ?? {};
      days.push(
        <Day key={key}>
          <DayName
            weekWidth={weekWidth}
            className={classNames({ isWeekend: isWeekend }, { isToday: isToday })}
          >
            {dayName}
          </DayName>
          <div className={classNames({ isWeekend: isWeekend }, { isToday: isToday })}>
            {dayOfMonth}
          </div>
          <Counts>
            <ScheduledBadge label={scheduled} small />
            <UnscheduledBadge label={unscheduled} small />
            <CompletedBadge label={completed} small />
          </Counts>
        </Day>,
      );
    });
    return (
      <WeekWrapper>
        {filter}
        <WeekContainer>
          <WeekDays>{days}</WeekDays>
        </WeekContainer>
      </WeekWrapper>
    );
  }, [filter, timelineSchemaWeek, weekWidth, weekCounts]);

  const getTimeline = useMemo(() => {
    switch (range.type) {
      case CalendarRanges.DAY:
        return dayTimeline;
      case CalendarRanges.MONTH:
        return monthTimeline;
      case CalendarRanges.WEEK:
        return weekTimeline;
      case CalendarRanges.QUARTER:
        return quarterTimeline;
      default:
        return monthTimeline;
    }
  }, [range.type, dayTimeline, monthTimeline, weekTimeline, quarterTimeline]);

  return <TimelineContainer>{getTimeline}</TimelineContainer>;
};

CalendarTimeline.propTypes = {
  filter: PropTypes.element,
  monthCounts: PropTypes.objectOf(
    PropTypes.shape({
      scheduled: PropTypes.number,
      unscheduled: PropTypes.number,
      completed: PropTypes.number,
    }),
  ).isRequired,
  weekCounts: PropTypes.objectOf(
    PropTypes.shape({
      scheduled: PropTypes.number,
      unscheduled: PropTypes.number,
      completed: PropTypes.number,
    }),
  ).isRequired,
};

CalendarTimeline.defaultProps = {
  filter: null,
  monthCounts: {},
  weekCounts: {},
};
