import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import styled, { withTheme, css } from 'styled-components';

import { Icon, Icons } from '../icon';

const hoverCSS = css`
  &:hover {
    padding: 12px;
    border: 2px solid ${({ theme }) => theme.analyze.regionSiteCard.siteSelectHoverBorderColor};
    border-radius: 1px 1px 3px 3px;
    box-shadow: ${({ theme }) => theme.analyze.regionSiteCard.siteSelectedHoverShadow};
  }
`;

const CardTitle = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid transparent;
  border-bottom: solid 1px ${(props) => props.theme.assetCard.borderColor};
  box-sizing: border-box;
  color: inherit;
  width: 100%;
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: text-top;
    margin-top: -2px;
  }
  h3 {
    color: ${(props) => props.theme.assetCard.textColor};
    font-weight: 500;
    font-size: 12px;
  }
  .icon-chevron {
    margin-left: auto;
  }
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  ${(props) => props.onClick && hoverCSS}
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.analyze.regionCard.chevronIcon,
  icon: Icons.ARROW_UP,
  rotate: 90,
}))`
  margin-left: auto;
`;

const CardComponent = ({
  className,
  title,
  icon,
  danger,
  titleChevron,
  onTitleClick,
  children,
  ...props
}) => {
  const content = useRef(null);
  const {
    theme: { assetCard },
  } = props;

  return (
    <div className={`${className} ${danger ? 'danger' : ''}`}>
      <CardTitle onClick={onTitleClick} className="title">
        <Icon size={12} icon={icon} className="icon" alt="site icon" color={assetCard.iconColor} />
        <div>
          <h3>{title}</h3>
          <ArrowIcon />
        </div>
        {titleChevron && (
          <Icon
            size={12}
            icon={Icons.CHEVRON}
            className="icon-chevron"
            alt="chevron icon"
            rotate={-90}
            color={assetCard.iconColor}
          />
        )}
      </CardTitle>
      <div className="content" ref={content}>
        {children}
      </div>
    </div>
  );
};

CardComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  danger: PropTypes.bool,
  icon: PropTypes.string,
  onTitleClick: PropTypes.func,
  theme: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  titleChevron: PropTypes.bool,
};

CardComponent.defaultProps = {
  children: [],
  className: '',
  danger: false,
  icon: '',
  onTitleClick: null,
  title: '',
  titleChevron: false,
};

const styledCard = styled(CardComponent)`
  display: block;
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.assetCard.borderColor},
    0 1px 0 0 ${(props) => props.theme.assetCard.borderColor};
  border-radius: 3px;
  background: ${(props) => props.theme.assetCard.titleBackgroundColor};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);

  .content {
    background: ${(props) => props.theme.assetCard.contentBackgroundColor};
    color: ${(props) => props.theme.assetCard.textColor};
    transition: max-height 0.6s ease;
    overflow: hidden;
    border-radius: 0 0 3px 3px;
  }
`;

export const Card = withTheme(styledCard);
