import { TableFilterTypes } from '@ge/models/constants';

export const PeopleColumns = {
  GROUP_TENANT: 'group-tenant',
  TENANT: 'tenant',

  GROUP_NAME: 'group-name',
  NAME: 'name',

  GROUP_ROLES: 'group-roles',
  ROLES: 'roles',

  GROUP_USER_ID: 'group-user-id',
  USER_ID: 'userId',

  GROUP_STATUS: 'group-status',
  STATUS: 'status',

  GROUP_LAST_LOGIN: 'group-last-login',
  LAST_LOGIN: 'last-login',

  GROUP_ALLOW_ACCESS: 'group-allow-access',
  ALLOW_ACCESS: 'allow-access',

  GROUP_EMAIL: 'group-email',
  EMAIL: 'email',
};

// Define all available columns in the people table.
export const PeopleColumnDefs = {
  [PeopleColumns.GROUP_TENANT]: {
    cols: {
      [PeopleColumns.TENANT]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.tenant',
            a11yDefault: 'Tenant',
            sortValue: 'tenant.id',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PeopleColumns.GROUP_NAME]: {
    cols: {
      [PeopleColumns.NAME]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.name',
            a11yDefault: 'Name',
            sortValue: 'name',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PeopleColumns.GROUP_ROLES]: {
    cols: {
      [PeopleColumns.ROLES]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.roles',
            a11yDefault: 'Roles',
            sortValue: 'roles',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PeopleColumns.GROUP_USER_ID]: {
    cols: {
      [PeopleColumns.USER_ID]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.user-id',
            a11yDefault: 'User ID',
            sortValue: 'userId',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PeopleColumns.GROUP_STATUS]: {
    cols: {
      [PeopleColumns.STATUS]: {
        filterType: TableFilterTypes.CHECKBOXES,
        width: '100px',
        labels: [
          {
            a11yKey: 'table.status',
            a11yDefault: 'Status',
            sortValue: 'status',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PeopleColumns.GROUP_LAST_LOGIN]: {
    cols: {
      [PeopleColumns.LAST_LOGIN]: {
        width: '100px',
        labels: [
          {
            a11yKey: 'table.last-login',
            a11yDefault: 'Last Login',
            sortValue: 'lastLogin',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PeopleColumns.GROUP_EMAIL]: {
    cols: {
      [PeopleColumns.EMAIL]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.email',
            a11yDefault: 'Email',
            sortValue: 'email',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },
};

export const tenantPeopleCols = [
  {
    id: PeopleColumns.GROUP_NAME,
    cols: [
      {
        id: PeopleColumns.NAME,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_ROLES,
    cols: [
      {
        id: PeopleColumns.ROLES,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_USER_ID,
    cols: [
      {
        id: PeopleColumns.USER_ID,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_STATUS,
    cols: [
      {
        id: PeopleColumns.STATUS,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_LAST_LOGIN,
    cols: [
      {
        id: PeopleColumns.LAST_LOGIN,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_EMAIL,
    cols: [
      {
        id: PeopleColumns.EMAIL,
        visible: true,
      },
    ],
  },
];

export const globalPeopleCols = [
  {
    id: PeopleColumns.GROUP_TENANT,
    cols: [
      {
        id: PeopleColumns.TENANT,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_NAME,
    cols: [
      {
        id: PeopleColumns.NAME,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_ROLES,
    cols: [
      {
        id: PeopleColumns.ROLES,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_USER_ID,
    cols: [
      {
        id: PeopleColumns.USER_ID,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_STATUS,
    cols: [
      {
        id: PeopleColumns.STATUS,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_LAST_LOGIN,
    cols: [
      {
        id: PeopleColumns.LAST_LOGIN,
        visible: true,
      },
    ],
  },
  {
    id: PeopleColumns.GROUP_EMAIL,
    cols: [
      {
        id: PeopleColumns.EMAIL,
        visible: true,
      },
    ],
  },
];
