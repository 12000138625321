/* eslint-disable */
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useVirtual } from 'react-virtual';
import styled, { css } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { BoldSearch } from '@ge/components/bold-search';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  EntityType,
  NewPersonField,
  Placeholders,
  DefaultRoleType,
  AttributeGroups,
  PersonCheckboxValue,
} from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

const EntityPickerContainer = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 540px);
  min-height:66px;
  overflow: hidden;
  margin-bottom:5px;
  position:relative;
`;

const StyledVirtualResults = styled.div`
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const ParentEntityWrapper = styled.div`
  position: relative;
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.dark};
  }
  &:nth-child(even) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  }
`;

const parentRowHighlight = css`
  background-color: ${(props) => props.theme.admin.panel.entityRow.highlight};
`;

const parentRowDisable = css`
  opacity: 0.7;
`;

const ParentRow = styled.div`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.admin.panel.entityRow.border};
  cursor: pointer;
  display: flex;
  padding: 8px 6px;
  span {
    flex: 1;
  }
  .caret {
    transition: transform 0.2s ease;
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(0deg);
    }
  }
  ${({ isChecked }) => {
    if (isChecked === CheckedState.CHECKED) return parentRowHighlight;
  }}
  ${({ isDisabled }) => {
    if (isDisabled) return parentRowDisable;
  }}
`;

const childRowHighlight = css`
  && {
    background-color: ${(props) => props.theme.admin.panel.entityRow.highlight};
  }
  &:last-of-type {
    border-color: ${(props) => props.theme.admin.panel.entityRow.highlightBorder};
  }
`;

const CheckboxWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.admin.panel.entityRow.border};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0;
  label {
    margin-left: ${({ isNested }) => (isNested ? '30px' : '10px')};
  }
  > svg {
    margin-right: 8px;
    margin-left: ${({ isDisableChildren }) => (isDisableChildren ? '30px' : '0')};
  }
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  }
  &:nth-child(even) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.dark};
  }
  ${({ isChecked }) => {
    if (isChecked === CheckedState.CHECKED) return childRowHighlight;
  }}
  ${({ isDisabled }) => {
    if (isDisabled) return parentRowDisable;
  }}
`;

const StyledCheckbox = styled(Checkbox)`
  input + div {
    width: 12px;
    height: 12px;
  }
`;

const CaretIcon = styled(Icon).attrs((props) => ({
  size: 8,
  icon: Icons.CARET,
  color: props.theme.admin.panel.entityRow.icon,
}))`
  margin: 0 8px;
`;

const EntityTypeIcon = styled(Icon).attrs(({ icon, theme }) => ({
  size: 14,
  icon: Icons[Object.keys(EntityType).find((key) => EntityType[key] === icon)],
  color: theme.admin.panel.entityRow.icon,
}))`
  margin-right: 5px;
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  medium: true,
  color: props.theme.select.chevronColor,
}))`
  margin-right: 8px;
  margin-left: auto;
  position: relative;
  left: ${({ isNested }) => (isNested ? '-7px' : '0')};
`;

const DynamicAllCurtain = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 37, 45, 0.4);
`;

const EntityPicker = ({
  childEntityType,
  entityCollection,
  filterText,
  isDisableChildren,
  isAssetCount,
  fieldName,
  appliedEntities,
  parentEntityType,
  personAttributes,
  dynamicAllKey,
  entityIndex,
}) => {
  const allSites = useStoreState((state) => state.sites.sitesForView);
  const parentRef = useRef();
  const isInitialLoad = useRef(false);
  const { register, setValue, watch } = useFormContext();
  register(fieldName);

  const watchWorker = watch(PersonCheckboxValue.WORKER);
  const watchUser = watch(PersonCheckboxValue.USER);
  const watchSelectedRoles = watch(PersonCheckboxValue.SELECTED_ROLES);
  const watchApplyWorkerUser = watch(PersonCheckboxValue.APPLY_WORKER_USER);
  const watchApplyAllUserRoles = watch(PersonCheckboxValue.APPLY_ALL_USER_ROLES);
  const watchUserDynamicAll = watch(`userDynamicAll-${entityIndex}`);

  const [affectedEntities, setAffectedEntities] = useState({
    [parentEntityType]: [],
    [childEntityType]: [],
  });
  const [initialEntityState, setInitialEntityState] = useState({
    [parentEntityType]: [],
    [childEntityType]: [],
  });

  const [expandedParents, setExpandedParents] = useState([]);

  const applyDynamicAll = useCallback(() => {
    if (isInitialLoad.current) {
      if (watchUserDynamicAll) {
        setAffectedEntities({
          [EntityType.SERVICE_GROUP]: [],
          [EntityType.SITE]: allSites.map((site) => site.id),
        });
        setValue(dynamicAllKey, true);
      } else {
        setAffectedEntities(initialEntityState);
        setValue(dynamicAllKey, false);
      }
    }
  }, [watchUserDynamicAll, initialEntityState]);

  useEffect(() => {
    setValue(fieldName, affectedEntities);
  }, [affectedEntities, fieldName, setValue]);

  useEffect(() => {
    if (
      (watchWorker && personAttributes) ||
      (watchWorker &&
        !watchApplyWorkerUser &&
        watchApplyAllUserRoles &&
        fieldName === NewPersonField.WORKER_ASSETS &&
        personAttributes)
    ) {
      let attributeIds = personAttributes.worker_service_group?.[0]?.groupCombination?.[0]?.ids;
      let filteredEntities = entityCollection.filter((entity) => attributeIds?.includes(entity.id));
      let serviceGroups = filteredEntities?.map((entity) => entity.id);
      let sites = filteredEntities?.flatMap((entity) => entity.sites);
      let siteIds = sites?.map((site) => site.id);
      setAffectedEntities({
        [parentEntityType]: serviceGroups,
        [childEntityType]: siteIds,
      });
    }
  }, [personAttributes]);

  useEffect(() => {
    if (
      (!watchWorker && watchApplyAllUserRoles && personAttributes) ||
      (watchUser &&
        watchWorker &&
        !watchApplyWorkerUser &&
        watchApplyAllUserRoles &&
        fieldName === NewPersonField.ALL_USER_ROLES &&
        personAttributes)
    ) {
      let filteredGroups = personAttributes?.entity_group?.filter(
        (group) =>
          group.roleName !== DefaultRoleType.MONITOR_VIEW &&
          group.roleName !== DefaultRoleType.ANALYTICS_VIEW &&
          group.roleName !== DefaultRoleType.MONITOR_ADMIN,
      );
      let serviceGroups =
        filteredGroups !== undefined
          ? filteredGroups
              .flatMap((group) => group.groupCombination)
              .filter((group) => group.groupName === AttributeGroups.SERVICE_GROUP)
              .flatMap((group) => group.ids)
              .filter((group) => group !== undefined)
          : [];
      let sites =
        filteredGroups !== undefined
          ? filteredGroups
              .flatMap((group) => group.groupCombination)
              .filter((group) => group.groupName === AttributeGroups.SITES)
              .flatMap((group) => group.ids)
              .filter((group) => group !== undefined)
          : [];

      let uniqueGroups = [...new Set(serviceGroups)];
      let uniqueSites = [...new Set(sites)];

      setAffectedEntities({
        [parentEntityType]: uniqueGroups,
        [childEntityType]: uniqueSites,
      });
    }
  }, [personAttributes]);

  useEffect(() => {
    if (watchUser && !watchApplyAllUserRoles && appliedEntities) {
      let serviceGroups =
        appliedEntities.serviceGroup !== undefined ? appliedEntities.serviceGroup : [];
      let sites = appliedEntities.site !== undefined ? appliedEntities.site : [];

      if (sites.includes('ALL') || watchSelectedRoles[entityIndex].isDynamicAll) {
        setAffectedEntities({
          [EntityType.SERVICE_GROUP]: [],
          [EntityType.SITE]: allSites.map((site) => site.id),
        });
        setValue(`userDynamicAll-${entityIndex}`, true);
        isInitialLoad.current = true;
      } else {
        setAffectedEntities({
          [EntityType.SERVICE_GROUP]: serviceGroups,
          [EntityType.SITE]: sites,
        });
        setInitialEntityState({
          [EntityType.SERVICE_GROUP]: serviceGroups,
          [EntityType.SITE]: sites,
        });
        isInitialLoad.current = true;
      }
    }
  }, []);

  useEffect(() => {
    if (isInitialLoad) {
      applyDynamicAll();
    }
  }, [applyDynamicAll, watchUserDynamicAll]);

  const rowVirtualizer = useVirtual({
    size: entityCollection.length,
    parentRef,
    estimateSize: React.useCallback(() => 30, []),
    overscan: 4,
  });

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  const getChildCheckState = useCallback(
    (entity, entityType) => {
      const childArray = entity.serviceGroups;
      if (
        childArray?.some((childId) => affectedEntities[EntityType.SERVICE_GROUP]?.includes(childId))
      ) {
        return CheckedState.UNCHECKED;
      }
      if (affectedEntities[entityType]?.includes(entity.id)) {
        return CheckedState.CHECKED;
      }
      return CheckedState.UNCHECKED;
    },
    [affectedEntities],
  );

  const toggleChildCheckbox = useCallback(
    (entity, entityType) => {
      return affectedEntities[entityType]?.includes(entity.id)
        ? setAffectedEntities((prevaffectedEntities) => {
            return {
              ...prevaffectedEntities,
              [entityType]: prevaffectedEntities[entityType]?.filter((id) => id !== entity.id),
            };
          })
        : setAffectedEntities((prevaffectedEntities) => {
            return {
              ...prevaffectedEntities,
              [entityType]: [...prevaffectedEntities[entityType], entity.id],
            };
          });
    },
    [affectedEntities, setAffectedEntities],
  );

  const renderEntityList = useCallback(
    (childEntity, entityType) => {
      return (
        <div className="renderEntityList">
          {childEntity?.map((entity) => {
            return (
              <CheckboxWrapper
                key={entity.id}
                isChecked={getChildCheckState(entity, entityType)}
                isNested={!!childEntityType}
                isDisableChildren={isDisableChildren}
              >
                {!isDisableChildren && (
                  <StyledCheckbox
                    onChange={() => toggleChildCheckbox(entity, entityType)}
                    disabled={isDisabledChildCheckState(entity, entityType)}
                    checkState={getChildCheckState(entity, entityType)}
                  />
                )}
                <EntityTypeIcon icon={entityType} />
                <BoldSearch text={entity.name} textBold={filterText} />
                {isAssetCount && (
                  <StyledBadge
                    label={entity.assetCount || Placeholders.DASH}
                    isNested={!!childEntityType}
                  />
                )}
              </CheckboxWrapper>
            );
          })}
        </div>
      );
    },
    [
      childEntityType,
      filterText,
      getChildCheckState,
      isAssetCount,
      isDisableChildren,
      toggleChildCheckbox,
    ],
  );

  const renderEntityListItems = useCallback(
    (item, entityType) => {
      const entity = entityCollection[item.index];
      if (!entity) return;
      return (
        <CheckboxWrapper
          key={entity.id}
          isChecked={getChildCheckState(entity, entityType)}
          isNested={!!childEntityType}
          isDisableChildren={isDisableChildren}
          isDisabled={isDisabledChildCheckState(entity, entityType)}
        >
          {!isDisableChildren && (
            <StyledCheckbox
              onChange={() => toggleChildCheckbox(entity, entityType)}
              disabled={isDisabledChildCheckState(entity, entityType)}
              checkState={getChildCheckState(entity, entityType)}
            />
          )}
          <EntityTypeIcon icon={entityType} />
          <BoldSearch text={entity.name} textBold={filterText} />
          {isAssetCount && (
            <StyledBadge
              label={entity.assetCount || Placeholders.DASH}
              isNested={!!childEntityType}
            />
          )}
        </CheckboxWrapper>
      );
    },
    [
      childEntityType,
      filterText,
      getChildCheckState,
      isAssetCount,
      isDisableChildren,
      toggleChildCheckbox,
    ],
  );

  const dropdownParent = useCallback(
    ({ id }) =>
      expandedParents.includes(id)
        ? setExpandedParents((prevExpandedParents) => prevExpandedParents.filter((el) => el !== id))
        : setExpandedParents((prevExpandedParents) => [...prevExpandedParents, id]),
    [expandedParents, setExpandedParents],
  );

  const getParentCheckState = useCallback(
    (entity) => {
      if (affectedEntities[parentEntityType]?.includes(entity.id)) {
        return CheckedState.CHECKED;
      }
      return CheckedState.UNCHECKED;
    },
    [affectedEntities, parentEntityType],
  );

  const isDisabledParentCheckState = useCallback(
    (entity, childType) => {
      if (affectedEntities[parentEntityType]?.includes(entity.id)) {
        return false;
      }
      const childArray = entity[`${childType}s`]?.map((child) => child.id);
      if (childArray?.some((childId) => affectedEntities[childEntityType].includes(childId))) {
        return true;
      }
      return false;
    },
    [affectedEntities, childEntityType, parentEntityType],
  );

  const isDisabledChildCheckState = useCallback(
    (entity, childType) => {
      const childArray = entity.serviceGroups;
      if (
        childArray?.some((childId) => affectedEntities[EntityType.SERVICE_GROUP]?.includes(childId))
      ) {
        return true;
      }
      if (affectedEntities[childType]?.includes(entity.id)) {
        return false;
      }
      return false;
    },
    [affectedEntities],
  );

  const toggleParentCheckbox = useCallback(
    (entity, childType) => {
      toggleChildCheckbox(entity, parentEntityType);
      const childArray = entity[`${childType}s`]?.map((child) => child.id);
      if (childArray && childArray.length) {
        childArray.forEach((child) => {
          if (affectedEntities[childEntityType].includes(child)) {
            setAffectedEntities((prevaffectedEntities) => {
              return {
                ...prevaffectedEntities,
                [childEntityType]: prevaffectedEntities[childEntityType].filter(
                  (item) => item !== child,
                ),
              };
            });
          }
        });
      }
    },
    [affectedEntities, parentEntityType, childEntityType],
  );

  const renderNestedEntityList = useCallback(
    ({ index }) => {
      const parentEntity = entityCollection[index];

      if (!parentEntity) return;

      return (
        <ParentEntityWrapper key={`${parentEntity.id}`}>
          <ParentRow
            isChecked={getParentCheckState(parentEntity)}
            onClick={() => dropdownParent(parentEntity)}
            isDisabled={isDisabledParentCheckState(parentEntity, childEntityType)}
          >
            <CaretIcon
              className={expandedParents.includes(parentEntity.id) ? 'caret rotate' : 'caret'}
            />
            <div onClick={(e) => killEventPropagation(e)}>
              <StyledCheckbox
                onChange={() => toggleParentCheckbox(parentEntity, childEntityType)}
                disabled={isDisabledParentCheckState(parentEntity, childEntityType)}
                checkState={getParentCheckState(parentEntity, childEntityType)}
              />
            </div>
            <EntityTypeIcon icon={parentEntityType} />
            <span>{parentEntity.name}</span>
            <StyledBadge
              label={
                isAssetCount
                  ? parentEntity.assetCount || Placeholders.DASH
                  : parentEntity[`${childEntityType}s`]?.length.toString() || Placeholders.DASH
              }
            />
          </ParentRow>
          {expandedParents.includes(parentEntity.id) &&
            renderEntityList(parentEntity[`${childEntityType}s`], childEntityType)}
        </ParentEntityWrapper>
      );
    },
    [
      childEntityType,
      dropdownParent,
      entityCollection,
      expandedParents,
      getParentCheckState,
      isAssetCount,
      parentEntityType,
      renderEntityList,
      toggleParentCheckbox,
    ],
  );

  return (
    <EntityPickerContainer>
      <ScrollingContainer ref={parentRef}>
        <StyledVirtualResults paddingTop={paddingTop} paddingBottom={paddingBottom}>
          {childEntityType
            ? items.map((item) => renderNestedEntityList(item, parentEntityType))
            : items.map((item) => renderEntityListItems(item, parentEntityType))}
        </StyledVirtualResults>
      </ScrollingContainer>
      {watchUserDynamicAll ? <DynamicAllCurtain></DynamicAllCurtain> : null}
    </EntityPickerContainer>
  );
};

EntityPicker.propTypes = {
  childEntityType: PropTypes.oneOf(Object.values(EntityType)),
  entityCollection: PropTypes.arrayOf(PropTypes.instanceOf(Object).isRequired),
  filterText: PropTypes.string,
  isDisableChildren: PropTypes.bool,
  isAssetCount: PropTypes.bool,
  fieldName: PropTypes.string,
  parentEntityType: PropTypes.oneOf(Object.values(EntityType)).isRequired,
  personType: PropTypes.string,
  personAttributes: PropTypes.object,
  appliedEntities: PropTypes.object,
  appliedEntitiesGrouped: PropTypes.array,
  entityIndex: PropTypes.number,
};

EntityPicker.defaultProps = {
  childEntityType: null,
  filterText: undefined,
  isDisableChildren: false,
  isAssetCount: false,
  fieldName: NewPersonField.ENTITY_LIST,
};

export default EntityPicker;
