import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import {
  EntityType,
  DateTimeFormats,
  MAX_INSTRUCTIONS_CHAR,
  AssetType,
} from '@ge/models/constants';
import { isBeforeToday } from '@ge/shared/util/time-date';

const InstructionBody = styled.div`
  display: flex;
  align-items: baseline;
  padding: 3px 12px 3px 0px;
`;

const InstructionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  margin: 0px 25px 0px 35px;
  height: 18px;
  div:first-child {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
  }
`;

const UserValidDate = styled.div`
  color: ${(props) => props.theme.table.metricSeparator};
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.45px;
  line-height: 12px;
  text-transform: uppercase;
`;

const LastUpdatedInfo = styled.div`
  margin: 0 0 1px 10px;
  color: ${(props) => props.theme.table.metricSeparator};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`;

const Text = styled.div`
  margin: 4px 9px 2px 2px;
  color: ${(props) => props.theme.table.metricSeparator};
  text-align: left;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  span {
    color: ${(props) => props.theme.entityDetails.specialInstructions.showMore};
  }
  inline-size: 700px;
  overflow-wrap: break-word;
  &.show-more {
    white-space: pre-wrap;
  }
`;

const SiteIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.SITE,
  color: props.theme.themeSelector.headerColor,
}))`
  margin: 0px 5px 0px 16px;
`;

const TurbineIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.TURBINE,
  color: props.theme.themeSelector.headerColor,
}))`
  margin: 0px 5px 0px 16px;
`;

const SubstationIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.SUBSTATION,
  color: props.theme.themeSelector.headerColor,
}))`
  margin: 0px 5px 0px 16px;
`;

const SiteControllerIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.SITE_CONTROLLER,
  color: props.theme.themeSelector.headerColor,
}))`
  margin: 0px 5px 0px 16px;
`;

const StorageSiteIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.STORAGE,
  color: props.theme.themeSelector.headerColor,
}))`
  margin: 0px 5px 0px 16px;
`;

const StorageInverterIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.STORAGE_INVERTER,
  color: props.theme.themeSelector.headerColor,
}))`
  margin: 0px 5px 0px 16px;
`;

const ShowMoreIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.entityDetails.specialInstructions.showMore,
}))`
  margin: 2px;
  font-weight: bold;
`;

const ShowMoreButton = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${(props) => props.theme.entityDetails.specialInstructions.showMore};
  text-transform: lowercase;
`;

const SpecialInstructionItems = ({
  content,
  userName,
  lastUpdatedDate,
  validDateBy,
  entityType,
  isAssetSI,
  assetType,
}) => {
  const { t } = useTranslation(['monitor.sites']);
  const [showMore, setShowMore] = useState(false);
  const lastUpdated = dayjs(lastUpdatedDate).format(DateTimeFormats.DEFAULT_DATE);

  const toggleShowMore = useCallback(() => {
    setShowMore((prevState) => !prevState);
  }, [setShowMore]);

  const showMoreLess = () => {
    if (showMore) {
      return (
        <>
          <ShowMoreIcon rotate={-180} /> {t('table.show_less', 'show less')}
        </>
      );
    } else {
      return (
        <>
          <span className="ellipsis">{'... '}</span>
          <ShowMoreIcon /> {t('table.show_more', 'show more')}
        </>
      );
    }
  };

  const getIconsType = useMemo(() => {
    if (!entityType) {
      return null;
    }

    if (!isAssetSI && assetType?.type === AssetType.STORAGE_INVERTER) {
      return <StorageSiteIcon />;
    }

    if (!isAssetSI && !(entityType === EntityType.STORAGE_SITE)) {
      return <SiteIcon />;
    }

    switch (entityType) {
      case EntityType.SITE:
        return <SiteIcon />;
      case EntityType.TURBINE:
        return <TurbineIcon />;
      case EntityType.SUBSTATION:
        return <SubstationIcon />;
      case EntityType.SITE_CONTROLLER:
        return <SiteControllerIcon />;
      case EntityType.STORAGE_SITE:
        return <StorageSiteIcon />;
      case EntityType.INVERTER:
        return <StorageInverterIcon />;
      default:
        return null;
    }
  }, [entityType, isAssetSI, assetType]);

  return (
    <>
      <InstructionBody>
        <span>{getIconsType} </span>
        <Text className={showMore ? 'show-more' : ''}>
          {showMore ? `${content} ` : `${content.slice(0, MAX_INSTRUCTIONS_CHAR)}`}
          {content?.length > MAX_INSTRUCTIONS_CHAR && (
            <ShowMoreButton onClick={toggleShowMore}>{showMoreLess()}</ShowMoreButton>
          )}
        </Text>
      </InstructionBody>
      <InstructionFooter>
        <UserValidDate className={classNames({ 'past-due': isBeforeToday(validDateBy) })}>
          {t('table.valid_until', 'Valid Until')}
          {` ${dayjs(validDateBy).format(DateTimeFormats.DEFAULT_DATE)}`}
          {isBeforeToday(validDateBy) && ` - ${t('table.past_due', 'PAST DUE')}`}
        </UserValidDate>
        <LastUpdatedInfo>
          {`${userName} - ${t('table.last_update', 'Last update')}: ${lastUpdated}`}
        </LastUpdatedInfo>
      </InstructionFooter>
    </>
  );
};

SpecialInstructionItems.propTypes = {
  notification: PropTypes.number,
  entityType: PropTypes.string,
  content: PropTypes.string,
  userName: PropTypes.string,
  lastUpdatedDate: PropTypes.string,
  validDateBy: PropTypes.string,
  isAssetSI: PropTypes.bool,
  assetType: PropTypes.string,
};

SpecialInstructionItems.defaultProps = {
  notification: null,
  entityType: null,
  content: null,
  userName: null,
  lastUpdatedDate: null,
  validDateBy: null,
};

export default SpecialInstructionItems;
