import { darken, transparentize } from 'polished';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { killEventPropagation } from '@ge/shared/util/general';

import { useResizableContext } from './resizable-table-context';

const StyledTd = styled.td`
  position: sticky;
  bottom: 0px;
  height: 0;
`;

const StyledDivider = styled.span`
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: ${({ height, headerHeight }) => `${height - headerHeight}px`};
  ${(props) => {
    if (props.active)
      return css`
        display: block;
        background: ${props.theme.table.dragDropBackgroundColor};
      `;
    if (props.hover)
      return css`
        display: block;
        background: ${transparentize(0.8, props.theme.table.dragDropBackgroundColor)};
      `;
    return css`
      display: none;
    `;
  }};
`;

const StyledResizer = styled.span`
  display: block;
  cursor: col-resize;
  position: absolute;
  bottom: 0;
  right: -2.5px;
  width: 5px;
  height: ${({ height, showColumnGroups }) =>
    showColumnGroups ? `${height - 30}px` : `${height}px`}
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 1px;
    width: 1px;
    height: 100%;
  }
  &:hover {
    &:before {
      background: ${(props) => transparentize(0.8, props.theme.table.dragDropBackgroundColor)};
    }
  }
  &.active {
    &:before {
      background: ${(props) => props.theme.table.dragDropBackgroundColor};
      border-right: 1px solid ${(props) => darken(0.1, props.theme.table.dragDropBorderColor)};
      border-left: 1px solid ${(props) => darken(0.1, props.theme.table.dragDropBorderColor)};
    }
  }
  &.group-last {
    height: ${({ height }) => `${height}px`}
  }
`;

const getColumnWidth = (e, startPoint) => {
  return startPoint.current.startWidth + (e.clientX - startPoint.current.startX);
};

const updateColumnWidth = (ref, width) => {
  if (ref?.current && width > 30) {
    ref.current.style.minWidth = width + 'px';
    ref.current.setAttribute('data-width', width);
  }
};

export const ResizableTableColumn = ({
  className,
  height,
  headerHeight,
  showColumnGroups,
  columnKey,
  groupKey,
}) => {
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);

  const thRef = useRef();
  const ref = useRef();
  const startPoint = useRef(null);

  const { updateColumn } = useResizableContext();

  useEffect(() => {
    if (active) {
      updateColumn(columnKey, groupKey);
    } else {
      updateColumn('', '');
    }
  }, [active, columnKey, groupKey, updateColumn]);

  const handleStart = useCallback(
    (e) => {
      e.preventDefault();
      killEventPropagation(e);
      startPoint.current = {
        startX: e.clientX,
        startWidth: thRef.current.clientWidth,
      };
      setActive(true);
      if (ref?.current) {
        ref.current.classList.add('active');
      }
    },
    [startPoint, thRef, ref],
  );

  const handleEnd = useCallback(
    (e) => {
      e.preventDefault();
      setActive(false);
      if (ref?.current) {
        ref.current.classList.remove('active');
      }
    },
    [ref],
  );

  const handleResize = useCallback(
    (e) => {
      e.preventDefault();
      killEventPropagation(e);
      updateColumnWidth(thRef, getColumnWidth(e, startPoint));
    },
    [startPoint, thRef],
  );

  const handleReset = useCallback(
    (e) => {
      killEventPropagation(e);
      if (e.detail === 2 && thRef?.current) {
        thRef.current.removeAttribute('style');
      }
    },
    [thRef],
  );

  useEffect(() => {
    if (active) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleEnd);
      return () => {
        document.removeEventListener('mousemove', handleResize);
        document.removeEventListener('mouseup', handleEnd);
      };
    }
  }, [handleResize, handleEnd, active]);

  return (
    <StyledTd ref={thRef}>
      <StyledDivider active={active} hover={hover} height={height} headerHeight={headerHeight} />
      <StyledResizer
        ref={ref}
        height={height}
        showColumnGroups={showColumnGroups}
        onClick={handleReset}
        onMouseDown={handleStart}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={className}
      />
    </StyledTd>
  );
};

ResizableTableColumn.propTypes = {
  className: PropTypes.string,
  columnKey: PropTypes.string,
  groupKey: PropTypes.string,
  height: PropTypes.number,
  headerHeight: PropTypes.number,
  showColumnGroups: PropTypes.bool,
};
