export const CaseOverviewColumns = {
  GROUP_INCLUDE: 'group-include',
  INCLUDE: 'include',

  GROUP_TYPE: 'group-type',
  TYPE: 'type',

  GROUP_CODE: 'group-code',
  CODE: 'code',

  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'description',

  GROUP_START: 'group-start',
  START: 'start',

  GROUP_END: 'group-end',
  END: 'end',

  // TODO: Remove this?
  GROUP_OPEN_TASKS: 'group-open-tasks',
  OPEN_TASKS: 'open-tasks',

  GROUP_ACTIONS: 'group-actions',
  ACTIONS: 'actions',

  GROUP_SOURCE: 'group-source',
  SOURCE: 'source',
};

// Define all available columns in the case overview table.
export const CaseOverviewColumnDefs = {
  [CaseOverviewColumns.GROUP_INCLUDE]: {
    width: '50px',
    cols: {
      [CaseOverviewColumns.INCLUDE]: {
        labels: [
          {
            a11yKey: 'table.include',
            a11yDefault: 'Include\nBelow',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [CaseOverviewColumns.GROUP_TYPE]: {
    width: '50px',
    cols: {
      [CaseOverviewColumns.TYPE]: {
        labels: [
          {
            a11yKey: 'table.type',
            a11yDefault: 'Type',
            sortValue: 'type',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [CaseOverviewColumns.GROUP_CODE]: {
    align: 'center',
    minWidth: '50px',
    cols: {
      [CaseOverviewColumns.CODE]: {
        labels: [
          {
            a11yKey: '',
            a11yDefault: '#',
            sortValue: 'code',
          },
        ],
        cell: {
          align: 'left',
          width: '50px',
        },
      },
    },
  },
  [CaseOverviewColumns.GROUP_DESCRIPTION]: {
    align: 'left',
    cols: {
      [CaseOverviewColumns.DESCRIPTION]: {
        minWidth: '130px',
        labels: [
          {
            a11yKey: 'table.title',
            a11yDefault: 'Title',
            sortValue: 'description',
          },
        ],
      },
    },
  },
  [CaseOverviewColumns.GROUP_START]: {
    align: 'left',
    minWidth: '118px',
    cols: {
      [CaseOverviewColumns.START]: {
        labels: [
          {
            a11yKey: 'table.opened',
            a11yDefault: 'Opened',
            sortValue: 'start',
          },
        ],
      },
    },
  },
  [CaseOverviewColumns.GROUP_END]: {
    align: 'left',
    minWidth: '118px',
    cols: {
      [CaseOverviewColumns.END]: {
        labels: [
          {
            a11yKey: 'table.status_closed',
            a11yDefault: 'Status/Closed',
            sortValue: 'end',
          },
        ],
      },
    },
  },
  [CaseOverviewColumns.GROUP_OPEN_TASKS]: {
    align: 'left',

    cols: {
      [CaseOverviewColumns.OPEN_TASKS]: {
        labels: [
          {
            a11yKey: 'table.openTasks',
            a11yDefault: 'Open Tasks',
            sortValue: 'openTasks',
          },
        ],
      },
    },
  },
  [CaseOverviewColumns.GROUP_ACTIONS]: {
    align: 'left',
    cols: {
      [CaseOverviewColumns.ACTIONS]: {
        minWidth: '42px',
        labels: [
          {
            a11yKey: 'table.actions',
            a11yDefault: 'Actions',
          },
        ],
      },
    },
  },
  [CaseOverviewColumns.GROUP_SOURCE]: {
    align: 'left',
    cols: {
      [CaseOverviewColumns.SOURCE]: {
        minWidth: '42px',
        labels: [
          {
            a11yKey: 'table.source_details',
            a11yDefault: 'Source/Details',
            sortValue: 'source',
          },
        ],
      },
    },
  },
};

// Define the default visible columns and their order.
// NOTE: Defining this in one place because currently all
// implementations of the case overview table have the same
// order. When presentations diverge, move this definition
// closer to the implementation and modify accordingly.
export const defaultCaseOverviewCols = [
  {
    id: CaseOverviewColumns.GROUP_INCLUDE,
    cols: [
      {
        id: CaseOverviewColumns.INCLUDE,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_TYPE,
    cols: [
      {
        id: CaseOverviewColumns.TYPE,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_CODE,
    cols: [
      {
        id: CaseOverviewColumns.CODE,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: CaseOverviewColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_START,
    cols: [
      {
        id: CaseOverviewColumns.START,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_END,
    cols: [
      {
        id: CaseOverviewColumns.END,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_ACTIONS,
    cols: [
      {
        id: CaseOverviewColumns.ACTIONS,
        visible: true,
      },
    ],
  },
  {
    id: CaseOverviewColumns.GROUP_SOURCE,
    cols: [
      {
        id: CaseOverviewColumns.SOURCE,
        visible: true,
      },
    ],
  },
];
