import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { NonAbsoluteLoader } from '@ge/components/loader';
import { Table, Tbody, Td, Thead, Th } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { placements } from '@ge/components/tooltip';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';
import { toLocaleKey } from '@ge/util/string-utils';

import { useAssetCommandsHistory } from '../../shared/data-hooks/use-asset-command-history';

const RequestsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 120px;
  margin: 6px;
  overflow: hidden;
`;

const MiniTd = styled(Td)`
  padding: 2px 12px;
  background: ${(props) => props.theme.eventController.commandDialog.tdBackground};
`;

const StyledTable = styled(Table).attrs((props) => ({
  compressed: true,
  transparent: true,
  backgroundColor: props.theme.eventController.commandDialog.tableBackground,
}))``;

const getFormattedDateTimeFromZone = ({ dateTime, timezone }) => {
  const { time, date } = getDateTimeBasedOnZone(dateTime, timezone);
  return `${time}, ${date}`;
};

export const CommandsDialogHistory = ({ asset }) => {
  const { t } = useTranslation(['entity-details']);
  const {
    data: { faultedTurbineEvents = {}, commandRequests = {}, commandsHistory = {} },
    isLoading,
  } = useAssetCommandsHistory(asset);
  // store
  const { getSiteById } = useStoreState((state) => state.sites);

  const site = getSiteById(asset?.site?.id);

  if (isLoading) {
    return <NonAbsoluteLoader />;
  }

  return (
    <>
      <StyledTable>
        <Thead transparent compressed>
          <tr>
            <Th
              labels={{ [t('commands_dialog.asset_history', 'Asset History')]: 'history' }}
              align="left"
            />
            <Th labels={{ [t('commands_dialog.hours', '24 Hrs')]: 'hours' }} />
            <Th labels={{ [t('commands_dialog.days', '7 Days')]: 'days' }} />
          </tr>
        </Thead>
        <tbody>
          <tr>
            <Td align="left">
              {t('commands_dialog.faulted_turbine_cases', 'Faulted Turbine Cases')}
            </Td>
            <Td>{faultedTurbineEvents?.day}</Td>
            <Td>{faultedTurbineEvents?.week}</Td>
          </tr>
          <tr>
            <Td align="left">{t('commands_dialog.command_requests', 'Command Requests')}</Td>
            <Td>{commandRequests?.day}</Td>
            <Td>{commandRequests?.week}</Td>
          </tr>
        </tbody>
      </StyledTable>

      {commandsHistory?.length > 0 && (
        <RequestsContainer>
          <StyledTable scrollable>
            <Thead transparent compressed>
              <tr>
                <Th
                  labels={{
                    [t('commands_dialog.command_type', 'Command Type')]: 'command_type',
                  }}
                  align="left"
                />
                <Th labels={{ [t('commands_dialog.status', 'Status')]: 'status' }} align="left" />
                <Th
                  labels={{
                    [t('commands_dialog.issued_on', `Issued on`) +
                    ` (${dayjs()
                      .tz(site?.timezone)
                      .format('z')})`]: 'issued_on',
                  }}
                  align="left"
                />
                <Th
                  labels={{ [t('commands_dialog.issued_by', 'Issued By')]: 'status' }}
                  align="left"
                />
              </tr>
            </Thead>
            <Tbody transparent compressed>
              {commandsHistory.map((item, idx) => (
                <tr key={idx}>
                  <MiniTd align="left">
                    {t(`commands.${toLocaleKey(item?.type)}`, item?.type ?? '')}
                  </MiniTd>
                  <MiniTd align="left">{t(`commands_dialog.${item.status}`)} </MiniTd>
                  <MiniTd align="left">
                    {getFormattedDateTimeFromZone({
                      dateTime: item.date,
                      timezone: site?.timezone,
                    })}
                  </MiniTd>
                  <MiniTd align="left">
                    <TooltipCell
                      tooltip={item.username}
                      placement={placements.TOP}
                      zIndex={elevations.P100}
                    >
                      {item.username}
                    </TooltipCell>
                  </MiniTd>
                </tr>
              ))}
            </Tbody>
          </StyledTable>
        </RequestsContainer>
      )}
    </>
  );
};

CommandsDialogHistory.propTypes = {
  asset: PropTypes.instanceOf(Object),
};

CommandsDialogHistory.defaultValues = {
  asset: null,
};
