import { PropTypes } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { components } from 'react-select';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { globalColors } from '@ge/tokens';

import { CheckedState } from '../checkbox';
import { Checkbox } from '../checkbox/checkbox';
import { Icon } from '../icon';

import { Select } from './select';

const StyledMenu = styled.div`
  max-height: ${(props) => props.maxHeight}px;
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.customSelect.scrollBar};
  }
  .select__option {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.customSelect.optionsBorder};
    padding: 8px 12px;
    .option-label {
      margin-left: 8px;
    }
    &.select__option--is-focused {
      background-color: inherit;
    }
    &:hover {
      box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.customSelect.optionHover},
        inset 0 1px 0 0 ${(props) => props.theme.customSelect.optionHover};
    }
    &.select__option--is-selected {
      background-color: ${(props) => props.theme.customSelect.optionSelected};
      box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.customSelect.optionHover};
    }
  }
`;

const MultiSelectWithCount = (Component) => {
  const NewComponent = (props) => {
    const [value, setValue] = useState();
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    useEffect(() => {
      props?.options?.length === props?.value?.length ? setSelectAll(true) : setSelectAll(false);
    }, [props]);

    useEffect(() => {
      if (value?.length && Array.isArray(value)) {
        const updatedValue = value?.filter(({ value: selectedAssetId }) =>
          props.options?.some(({ value: assetId }) => assetId === selectedAssetId),
        );
        props.onSelect(updatedValue);
        setValue(updatedValue);
      }
    }, [props.options]);

    const onCancel = (handleClose) => {
      setValue(props.value);
      handleClose();
    };

    const onSelect = (handleClose) => {
      props.onSelect(value);
      handleClose();
    };
    const MenuList = ({ children, maxHeight }) => {
      const childrenOptions = React.Children.toArray(children);
      const parentRef = useRef();

      const rowVirtualizer = useVirtual({
        size: childrenOptions.length ?? 0,
        parentRef,
        estimateSize: React.useCallback(() => 30, []),
        overscan: 16,
      });

      const items = rowVirtualizer.virtualItems;
      const paddingTop = items.length > 0 ? items[0].start : 0;
      const paddingBottom =
        items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1]?.end : 0;

      const renderItem = ({ index, measureRef }) => {
        const key = props.options[index].value;
        return (
          <div key={key} ref={measureRef}>
            {childrenOptions[index]}
          </div>
        );
      };
      renderItem.propTypes = {
        measureRef: PropTypes.object,
        index: PropTypes.number,
      };

      const handleSelectAll = (value) => {
        value ? setValue(props.options) : setValue([]);
        setSelectAll(value);
      };

      return (
        <StyledMenu
          maxHeight={maxHeight}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          ref={parentRef}
        >
          <div
            onClick={() => handleSelectAll(!selectAll)}
            className={`select__option ${selectAll ? 'select__option--is-selected' : null}`}
          >
            <Checkbox checkState={selectAll ? CheckedState.CHECKED : CheckedState.UNCHECKED} />
            <Icon icon={props.labelIcon} size={12} color={globalColors.slate4} />
            <label className="option-label">All</label>
          </div>
          {items.map((item) => renderItem(item))}
        </StyledMenu>
      );
    };

    MenuList.propTypes = {
      children: PropTypes.node,
      maxHeight: PropTypes.number.isRequired,
      labelIcon: PropTypes.node,
    };

    const Option = ({ isSelected, children, ...rest }) => {
      return (
        <components.Option {...rest} className={isSelected ? 'select__option--is-selected' : null}>
          <Checkbox checkState={isSelected ? CheckedState.CHECKED : CheckedState.UNCHECKED} />
          <Icon icon={props.labelIcon} size={12} color={globalColors.slate4} />
          <label className="option-label">{children}</label>
        </components.Option>
      );
    };

    Option.propTypes = {
      children: PropTypes.node.isRequired,
      isSelected: PropTypes.bool,
      labelIcon: PropTypes.node,
    };

    const handleSelect = (selectedOptions) => {
      if (selectedOptions.length === props.options.length) {
        setSelectAll(true);
      } else if (value.length > selectedOptions.length && selectAll) {
        setSelectAll(false);
      }
      setValue(selectedOptions);
    };

    return (
      <Component
        {...props}
        value={value}
        onChange={handleSelect}
        onCancel={onCancel}
        onSelect={onSelect}
        additionalComponents={{ Option, MenuList }}
        autoClose={false}
      />
    );
  };

  NewComponent.propTypes = {
    onSelect: PropTypes.func,
    value: PropTypes.instanceOf(Object),
    options: PropTypes.array,
    isMulti: PropTypes.bool,
    id: PropTypes.string,
  };

  return NewComponent;
};

// **we can extend functionality for select-default for now use only with searchable

export const CustomSelect = MultiSelectWithCount(Select);
