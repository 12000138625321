import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useManualAdjustmentContext } from '../../context/manual-adjustment-context';
import { calculateDuration } from '../../util/manual-adjustment';

import { DialogLabel, DialogValue } from './event-dialog-style';

export const MalEventDuration = ({ fieldName, index }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { calendarDate } = useManualAdjustmentContext();

  const { watch } = useFormContext();
  const events = watch(fieldName);

  const getDuration = useCallback(() => {
    const { startTime, endTime } = events?.[index] ?? {};
    return calculateDuration(startTime, endTime, calendarDate);
  }, [index, events, calendarDate]);

  if (!ready) return null;

  return (
    <div>
      <DialogLabel>{t('event_details_dialog.event_duration', 'Duration')}</DialogLabel>
      <DialogValue>{getDuration()}</DialogValue>
    </div>
  );
};

MalEventDuration.propTypes = {
  index: PropTypes.number,
  fieldName: PropTypes.string,
};

MalEventDuration.defaultProps = {
  fieldName: '',
};
