import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledListTable = styled.table.attrs(() => ({
  cellSpacing: 0,
}))`
  tr {
    &:nth-child(odd) {
      background: ${({ theme }) => theme.createReport.widget.list.oddRowColor};
    }

    > th,
    td {
      color: ${({ theme }) => theme.createReport.widget.list.tableCellTextColor};
      font-size: 12px;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    th,
    .value {
      font-weight: 500;
    }

    th {
      padding-left: 6px;
      text-align: left;
    }

    .units {
      color: ${({ theme }) => theme.createReport.widget.list.unitsTextColor};
      font-weight: 300;
      padding-left: 4px;
      padding-right: 6px;
      text-align: left;
    }

    .value {
      text-align: right;
    }
  }
`;

// making this opinionated for now, expecting data that includes title, value, and units
// can make more generic if needed
export const ListTable = ({ data }) => {
  // include 'no data' state in here or let parent control that always?
  if (!data?.length) {
    return null;
  }

  return (
    <StyledListTable>
      <tbody>
        {data.map(({ id, title, units, value }) => {
          return (
            <tr key={id ?? title}>
              <th>{title}</th>
              <td className="value">{value}</td>
              <td className="units">{units}</td>
            </tr>
          );
        })}
      </tbody>
    </StyledListTable>
  );
};

ListTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      units: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};
