import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey, TimeSignal } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { dedupArray } from '@ge/shared/util';
import { removeFalsyVals } from '@ge/util/array-utils';
import { isEmpty } from '@ge/util/object-utils';

import { fetchCaseAnalysisTemplate } from '../services';

// TODO: Temp Fix - Remove when data is smoothed out
const mockData = {
  analysisIds: ['SensorList25MW_Voltage_Phase_A___High'],
  plots: [
    {
      plotType: 'spline',
      plotId: '1_1',
      signals_y: [
        {
          signalSource: 'machineData',
          signalAggregation: 'avg_10m',
          signalId: 'WTUR.GriPh1V',
        },
      ],
      signal_x: {
        signalSource: 'machineData',
        signalAggregation: 'sam_10m',
        signalId: TimeSignal.id,
      },
    },
    {
      plotType: 'scatter',
      plotId: '1_2',
      signals_y: [
        {
          signalSource: 'machineData',
          signalAggregation: 'avg_10m',
          signalId: 'WTUR.GriPh1V',
        },
      ],
      signal_x: {
        signalSource: 'machineData',
        signalAggregation: 'sam_10m',
        signalId: TimeSignal.id,
      },
    },
    {
      plotType: 'scatter',
      plotId: '2_1',
      signals_y: [
        {
          signalSource: 'machineData',
          signalAggregation: 'avg_10m',
          signalId: 'WTUR.GriPh2V',
        },
      ],
      signal_x: {
        signalSource: 'machineData',
        signalAggregation: 'sam_10m',
        signalId: TimeSignal.id,
      },
    },
    {
      plotType: 'scatter',
      plotId: '2_2',
      signals_y: [
        {
          signalSource: 'machineData',
          signalAggregation: 'avg_10m',
          signalId: 'WTUR.GriPh3V',
        },
      ],
      signal_x: {
        signalSource: 'machineData',
        signalAggregation: 'sam_10m',
        signalId: TimeSignal.id,
      },
    },
    {
      plotType: 'scatter',
      plotId: '3_1',
      signals_y: [
        {
          signalSource: 'machineData',
          signalAggregation: 'avg_10m',
          signalId: 'WTUR.GriPh3V',
        },
      ],
      signal_x: {
        signalSource: 'machineData',
        signalAggregation: 'sam_10m',
        signalId: TimeSignal.id,
      },
    },
  ],
};

const useCaseAnalysisTemplate = ({ caseWithDetails = {}, isLoading: isCaseDetailsLoading }) => {
  const analysisTemplates = useMemo(() => {
    if (isEmpty(caseWithDetails)) {
      return {};
    }
    const childAnalysisTemplates =
      caseWithDetails.childCases?.map((childCase) => childCase.analysisTemplateId)?.flat() ?? [];
    return removeFalsyVals(
      dedupArray([caseWithDetails.analysisTemplateId, ...childAnalysisTemplates]),
    );
  }, [caseWithDetails]);

  const {
    data,
    isLoading: isAnalysisTemplateLoading,
    error,
  } = useQuery(
    [QueryKey.CASE_ANALYSIS_TEMPLATE, analysisTemplates, caseWithDetails?.asset?.id],
    () => fetchCaseAnalysisTemplate(analysisTemplates, caseWithDetails?.asset?.id),
    {
      enabled: Boolean(!isCaseDetailsLoading && analysisTemplates?.length),
      ...Config.REFRESH,
    },
  );

  // TODO: Temp Fix - Remove when data is smoothed out
  const _data = !isCaseDetailsLoading && !analysisTemplates?.length ? mockData : data;

  return useMemo(
    () => ({
      data: _data,
      isLoading: isAnalysisTemplateLoading || isCaseDetailsLoading,
      error,
    }),
    [_data, isAnalysisTemplateLoading, isCaseDetailsLoading, error],
  );
};

export default useCaseAnalysisTemplate;
