import { AlertType } from '@ge/models/constants';

export const UnPlannedOutageTemplate = {
  _id: '459b31ae-0baa-11ec-9a03-0242ac130003',
  alertType: AlertType.UNPLANNED_OUTAGE,
  entrypoint: 'string',
  sections: [
    {
      collapsed: false,
      hidden: false,
      metadata: {
        block: {
          position: {
            col: 1,
            row: 1,
            span: 12,
          },
          type: 'blockedActions',
          values: ['Block Cases', 'Block Commands'],
        },
      },
      type: 'body',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        description: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'description',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        sendNotification: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'send_notification',
    },
  ],
  operations: ['create', 'edit'],
  tenantId: 'dfsa',
};

export const UnPlannedOutageMailTemplate = {
  bcc: 'UnPlannedOutageBCC@mock.com',
  cc: 'UnPlannedOutageCC@mock.com',
  body: '',
  header: 'This is to inform of a UnPlanned Outage for {{siteName}} – {{assetName}}',
  maxAttachments: 5,
  maxAttachmentSizeMb: 10,
  subject: 'UnPlanned Outage Notifications for {{siteName}} – {{assetName}}',
  to: '',
};
