import { AppScopes } from '@ge/shared/models/scopes';

/**
 * Definition of all available features and the routes that are proteced
 * by the associated scopes.
 */
export const AppFeatures = {
  // Profile
  PROFILE: {
    route: '/profile',
    scope: AppScopes.PROFILE,
  },

  // Analyze
  ANALYZE_MANUAL_ADJUSTMENT: {
    route: '/analyze/manual-adjustment',
    scope: AppScopes.ANALYZE_MANUAL_ADJUSTMENT,
  },
  ANALYZE_REPORTS: {
    route: '/analyze/reports',
    scope: AppScopes.ANALYZE_REPORTS,
  },
  ANALYZE_ASSET_OVERVIEW: {
    route: ['/analyze/asset-overview'],
    scope: AppScopes.ANALYZE_ASSET_OVERVIEW,
  },
  ANALYZE_CASES_TASKS: {
    rootRoute: '/analyze/cases/',
    route: ['/analyze/cases/anomalies', '/analyze/cases/events', '/analyze/cases/tasks'],
    scope: AppScopes.ANALYZE_CASES_TASKS,
  },
  ANALYZE_ASSETS: {
    rootRoute: '/analyze/assets',
    route: '/analyze/assets',
    scope: AppScopes.ANALYZE_CONTRACTS,
  },
  ANALYZE_SITES: {
    rootRoute: '/analyze/sites',
    route: '/analyze/sites',
    scope: AppScopes.ANALYZE_SITES,
  },
  ANALYZE_SITES_TEST: {
    rootRoute: '/analyze/test',
    route: '/analyze/test',
    scope: AppScopes.ANALYZE_SITES,
  },
  ANALYZE_FLEET_OVERVIEW: {
    rootRoute: '/analyze/fleet-overview',
    route: [
      '/analyze/fleet-overview/region',
      '/analyze/fleet-overview/platform',
      '/analyze/region/:region',
      '/analyze/site/:site',
      '/analyze/data-download',
    ],
    scope: AppScopes.ANALYZE_FLEET_OVERVIEW,
  },
  ANALYZE_TROUBLESHOOTING: {
    route: ['/analyze/troubleshooting/:anomalyId', '/case/:id'],
    scope: AppScopes.ANALYZE_TROUBLESHOOTING,
  },

  // Monitor
  MONITOR_SITES: {
    route: ['/monitor/sites', '/monitor/sites/:filter', '/site/:id'],
    scope: AppScopes.MONITOR_SITES,
  },
  MONITOR_SITES_WIND: {
    route: ['/monitor/sites/wind'],
    scope: AppScopes.MONITOR_SITES_WIND,
  },
  MONITOR_SITES_STORAGE: {
    route: ['/monitor/sites/storage'],
    scope: AppScopes.MONITOR_SITES_STORAGE,
  },
  MONITOR_REPORTS: {
    route: '/monitor/reports',
    scope: AppScopes.MONITOR_REPORTS,
  },
  MONITOR_ISSUES: {
    route: [
      '/monitor/cases',
      '/monitor/cases/:filter',
      '/event/:id',
      '/ticket/:id',
      '/newticket/:eventId',
    ],
    scope: AppScopes.MONITOR_ISSUES,
  },
  MONITOR_ISSUES_EVENTS: {
    route: ['/monitor/cases/events'],
    scope: AppScopes.MONITOR_ISSUES_EVENTS,
  },
  MONITOR_ISSUES_HISTORY: {
    route: ['/monitor/cases/history'],
    scope: AppScopes.MONITOR_ISSUES_HISTORY,
  },
  MONITOR_ISSUES_ANOMALIES: {
    route: ['/monitor/cases/anomalies'],
    scope: AppScopes.MONITOR_ISSUES_ANOMALIES,
  },
  MONITOR_ASSETS: {
    route: [
      '/monitor/assets',
      '/turbine/:id',
      '/fhp/:id',
      '/monitor/assets/:filter',
      '/caseprocedure/:assetId/:eventCode',
    ],
    scope: AppScopes.MONITOR_ASSETS,
  },
  MONITOR_STATUS: {
    route: '/monitor/current-status',
    scope: AppScopes.MONITOR_STATUS,
  },

  // Execute
  MANAGE_REPORTS: {
    route: '/execute/reports',
    scope: AppScopes.MANAGE_REPORTS,
  },
  MANAGE_CASES_TASKS: {
    route: '/execute/cases-tasks',
    scope: AppScopes.MANAGE_CASES_TASKS,
  },
  MANAGE_LABOR: {
    route: '/execute/labor',
    scope: AppScopes.MANAGE_LABOR,
  },
  MANAGE_SERVICE: {
    route: '/execute/service',
    scope: AppScopes.MANAGE_SERVICE,
  },
  MANAGE_OVERVIEW: {
    route: ['/execute/overview', '/task/:id'],
    scope: AppScopes.MANAGE_OVERVIEW,
  },

  // Admin
  ADMIN_PEOPLE: {
    route: ['/admin/people', '/admin/people/new-person', '/admin/people/new-person/:step'],
    scope: AppScopes.ADMIN_PEOPLE,
  },
  ADMIN_ROLES: {
    route: ['/admin/roles'],
    scope: AppScopes.ADMIN_ROLES,
  },
  ADMIN_CONFIGURE: {
    route: ['/admin/configure', '/admin/configure/:filter'],
    scope: AppScopes.ADMIN_CONFIGURE,
  },
  ADMIN_CONFIGURE_DISTRIBUTION_LISTS: {
    route: ['/admin/configure/distribution-list'],
    scope: AppScopes.ADMIN_CONFIGURE_DISTRIBUTION_LISTS,
  },
  ADMIN_MONITOR_APPLICATION_CONFIGURATION: {
    rootRoute: '/admin/application-configuration/monitor',
    route: ['/admin/application-configuration/monitor/distribution-lists'],
    scope: AppScopes.MONITOR_APPLICATION_CONFIGURATION,
  },
  ADMIN_CONFIGURE_HANDLING_PROCEDURES: {
    route: ['/admin/application-configuration/monitor/handling-procedures'],
    scope: AppScopes.ADMIN_CONFIGURE_HANDLING_PROCEDURES,
  },
  ADMIN_CONFIGURE_EVENT_MANAGEMENT: {
    route: ['/admin/application-configuration/monitor/event-management'],
    scope: AppScopes.ADMIN_CONFIGURE_EVENT_MANAGEMENT,
  },
  ADMIN_CONFIGURE_DISTRIBUTION_LIST: {
    route: ['/admin/application-configuration/monitor/distribution-lists'],
    scope: AppScopes.ADMIN_CONFIGURE_DISTRIBUTION_LIST,
  },
  ADMIN_ANALYZE_APPLICATION_CONFIGURATION: {
    rootRoute: '/admin/application configuration/analyze',
    route: ['/admin/application configuration/analyze/case templates'],
    scope: AppScopes.ANALYZE_APPLICATION_CONFIGURATION,
  },
  ADMIN_ANALYZE_CASE_TEMPLATES: {
    route: ['/admin/application configuration/analyze/case templates'],
    scope: AppScopes.ANALYZE_CASE_TEMPLATE,
  },
  ADMIN_ANALYZE_ANALYSIS_TEMPLATES: {
    route: ['/admin/application configuration/analyze/analysis templates'],
    scope: AppScopes.ANALYZE_ANALYSIS_TEMPLATE,
  },
  // Reporting
  REPORTING_REPORTS: {
    route: ['/reporting/reports'],
    scope: AppScopes.REPORTING_REPORTS,
  },
  REPORTING_REPORTS_MY_REPORTS: {
    route: ['/reporting/reports/my-reports'],
    scope: AppScopes.REPORTING_REPORTS_MY_REPORTS,
  },
  REPORTING_REPORTS_CREATE: {
    route: ['/reporting/reports/create/:id'],
    scope: AppScopes.REPORTING_REPORTS_CREATE,
  },

  // Inspections
  INSPECTIONS_REPORTS: {
    route: '/inspect/inspection-reports',
    scope: AppScopes.INSPECTIONS_REPORTS,
  },
  INSPECTIONS_LIST: {
    route: '/inspect/lists',
    scope: AppScopes.INSPECTIONS_LIST,
  },
  INSPECTIONS_UPLOAD: {
    route: '/inspect/upload/:id',
    scope: AppScopes.INSPECTIONS_UPLOAD,
  },
  INSPECTIONS_PREVIEW: {
    route: '/inspect/preview/:id',
    scope: AppScopes.INSPECTIONS_PREVIEW,
  },
  INSPECTIONS_EXECUTION: {
    route: '/inspect/execution/:id',
    scope: AppScopes.INSPECTIONS_EXECUTION,
  },
  INSPECTIONS_POST_PROCESS: {
    route: '/inspect/post-process/:id',
    scope: AppScopes.INSPECTIONS_POST_PROCESS,
  },
  INSPECTIONS_REPORT_GENERATION: {
    route: '/inspect/report-generation/:id',
    scope: AppScopes.INSPECTIONS_REPORT_GENERATION,
  },
  INSPECTIONS_RESULTS: {
    route: ['/inspect/results', '/inspection-results/:id'],
    scope: AppScopes.INSPECTIONS_RESULTS,
  },
  INSPECTIONS_IMAGE_VIEWER: {
    route: '/inspect/image-viewer/:img/:anno',
    scope: AppScopes.INSPECTIONS_IMAGE_VIEWER,
  },
  INSPECTIONS_PERFORMANCE: {
    route: '/inspect/performance',
    scope: AppScopes.INSPECTIONS_PERFORMANCE,
  },
  INSPECTIONS_ANALYTICS: {
    route: '/inspect/analytics',
    scope: AppScopes.INSPECTIONS_ANALYTICS,
  },
};
