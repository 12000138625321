import { StatusLabel } from '@ge/models/constants';
import { StatusColor } from '@ge/tokens/colors/colors';

export const getStatusColor = (status) => {
  switch (status) {
    case StatusLabel.DANGER:
      return StatusColor.DANGER;
    case StatusLabel.AMBER:
      return StatusColor.AMBER;
    case StatusLabel.WARNING:
      return StatusColor.WARNING;
    default:
      return StatusColor.DEFAULT;
  }
};

export const getStatusAverageColor = (average) => {
  switch (true) {
    case average < 93.5:
      return StatusColor.DANGER;
    case average < 94:
      return StatusColor.AMBER;
    case average < 95:
      return StatusColor.WARNING;
    default:
      return StatusColor.DEFAULT;
  }
};
