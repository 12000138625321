import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { placements, Tooltip } from '@ge/components/tooltip';
import { useManualAdjustmentContext } from '@ge/feat-analyze/context/manual-adjustment-context';
import { AvailabilityKpi } from '@ge/feat-analyze/models/manual-adjustment-defs';
import { Placeholders } from '@ge/models/constants';

import {
  Row,
  Cell,
  SelectionColumn,
  ChartRow,
  EndingColumn,
  AssetKPI,
  ChartBar,
  EntityCell,
  EntityName,
  EntityArrow,
} from './site-panel-chart-style';

const getBarStyle = (e, startOfDay) => {
  const columnStart = (e.startTime - startOfDay) / 1000 || 1;
  const columnEnd = (e.endTime - startOfDay) / 1000;
  return {
    gridColumnStart: columnStart,
    gridColumnEnd: columnEnd,
    backgroundColor: e.color,
    marginTop: e.type === 'MAL' ? '-40px' : '',
  };
};

const getMALBarStyle = (e, startOfDay, isSelected) => {
  const columnStart = (e.startTime - startOfDay) / 1000 || 1;
  const columnEnd = (e.endTime - startOfDay) / 1000;

  return {
    gridColumnStart: columnStart,
    gridColumnEnd: columnEnd,
    backgroundColor: '#C15A47',
    marginTop: isSelected ? '37px' : '17px',
    height: '12px',
    backgroundImage: 'linear-gradient(#782D1E 75%, #C15A47 85%, #DB8585 100%)',
    WebkitMaskImage:
      '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)))',
  };
};

const SitePanelChartRow = ({ data, site, startOfDay }) => {
  const ref = useRef(null);

  const {
    selectedEvent: selected,
    setSelectedEvent,
    selectedAssets,
    setSelectedAssets,
    autoWidth,
  } = useManualAdjustmentContext();

  const isSelected = useMemo(() => data.asset.id === selected?.id, [data, selected]);
  const eventData = [...data.assetEvents, ...data.malEvents];
  const kpiCells = useCallback(
    () =>
      Object.values(AvailabilityKpi).map((def) => {
        const kpiValue = data.availability[def.id];
        if (kpiValue === null || kpiValue === undefined || kpiValue === '') {
          return <AssetKPI key={def.id}>{Placeholders.DOUBLE_DASH}</AssetKPI>;
        }
        return <AssetKPI key={def.id}>{`${kpiValue}${def.unit}`}</AssetKPI>;
      }),
    [data],
  );

  const handleSelectAsset = useCallback(
    (checked) => {
      setSelectedAssets((prev) =>
        checked ? [...prev, data.asset.id] : prev.filter((id) => id !== data.asset.id),
      );
    },
    [data, setSelectedAssets],
  );

  const handleSelectEvent = useCallback(
    (e) => {
      setSelectedAssets([]);
      setSelectedEvent(e);
    },
    [setSelectedAssets, setSelectedEvent],
  );

  return (
    <Row>
      <Cell isSelected={isSelected}>
        <SelectionColumn>
          <Checkbox
            checkState={
              selectedAssets.includes(data.asset?.id)
                ? CheckedState.CHECKED
                : CheckedState.UNCHECKED
            }
            label={''}
            onChange={handleSelectAsset}
          />
        </SelectionColumn>
        <EntityCell>
          <EntityName>{data.asset?.name}</EntityName>
          <EntityArrow />
        </EntityCell>
        <ChartRow numberOfColumns={86400}>
          {eventData.map((event, i) => (
            <Tooltip
              key={i}
              title={event.type === 'IEC' ? event.oemDescription : event.malCategoryName}
              placement={placements.TOP}
            >
              <ChartBar
                ref={ref}
                isClicked={data.asset?.id === selected?.id && selected?.index === i}
                isSelected={isSelected}
                style={getBarStyle(event, startOfDay)}
                onClick={() => handleSelectEvent({ id: data.asset?.id, site, event, index: i })}
              >
                <ConditionalRender shouldRender={event.type === 'MAL'}>
                  <ChartBar
                    style={getMALBarStyle(event, startOfDay, isSelected)}
                    eventType={event.type}
                  />
                </ConditionalRender>
              </ChartBar>
            </Tooltip>
          ))}
        </ChartRow>
      </Cell>
      <EndingColumn autoWidth={autoWidth}>{kpiCells()}</EndingColumn>
    </Row>
  );
};

SitePanelChartRow.propTypes = {
  startOfDay: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  site: PropTypes.instanceOf(Object),
};

export default SitePanelChartRow;
