import { PropTypes } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { useNotification } from '@ge/components/notification';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useUpdateCaseTemplate } from '@ge/feat-analyze/data-hooks/use-case-templates';
import {
  NAMESPACE,
  Fields,
  RootCauseOption,
} from '@ge/feat-analyze/models/configure-case-template';
import { QueryKey, TaskTemplateModes } from '@ge/models/constants';
import useCaseTemplate from '@ge/shared/data-hooks/use-case-template';
import { killEventPropagation } from '@ge/shared/util';
import {
  DetailContainer,
  SectionContainer,
} from '@ge/web-client/src/app/components/entity-details/entity-details-shared';

import { FormErrorMessage } from '../../shared';
import CaseTemplateTasks from '../case-template/case-template-tasks';

import { AnalyticsDetails } from './analytics-details';
import { CaseDetails } from './case-details';
import { CaseTemplatePanelHeader } from './case-template-panel-header';

const StyledDiv = styled.div`
  padding-top: 1rem;
`;

const Container = styled.div`
  min-width: 520px;
  min-height: 200px;
  ${({ isLoading }) => (isLoading ? 'position: relative' : '')}
  label {
    color: ${(props) => props.theme.input.labelTextColor};
  }
`;
const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  button:first-of-type {
    margin-right: 10px;
  }
  z-index: 1;
`;

const ActionSaveButtons = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-bottom: 20px;

  button:first-of-type {
    margin-right: 10px;
  }
`;

const ActionCancelButtons = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 20px;

  button:first-of-type {
    margin-right: 10px;
  }
`;

const CaseTemplateDetailsPanel = ({ entityType, caseTemplateId, closePanel }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });
  const methods = useForm({ mode: 'onChange' });

  const { data: caseTemplateData, isLoading: isCaseTemplateDataLoading } = useCaseTemplate({
    caseTemplateId: caseTemplateId,
  });

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = (e) => {
    e.preventDefault();
    killEventPropagation(e);
    setIsEditing(!isEditing);
  };
  const { notify } = useNotification();
  const queryClient = useQueryClient();
  const {
    mutate: update,
    isLoading,
    isError,
    error,
  } = useUpdateCaseTemplate({
    enabled: true,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CASE_TEMPLATE_TABLE]);
      queryClient.invalidateQueries([QueryKey.CASE_TEMPLATE, caseTemplateId]);
      closePanel();
      notify({ message: t('case_template_created_success', 'Case Template Updated Successfully') });
    },
  });

  const onSubmit = useCallback(
    (values) => {
      const componentTypeIds = [values[Fields.COMPONENT_L1]];
      if (values[Fields.COMPONENT_L2]) {
        componentTypeIds.push(values[Fields.COMPONENT_L2]);
        if (values[Fields.COMPONENT_L3]) {
          componentTypeIds.push(values[Fields.COMPONENT_L3]);
        }
      }

      const rootCauses =
        [
          ...new Set(
            [
              ...(values[Fields.ROOT_CAUSES] ? values[Fields.ROOT_CAUSES] : []),
              ...(values[Fields.ROOT_CAUSES_NEW] ? values[Fields.ROOT_CAUSES_NEW] : []),
            ].filter((v) => v && v !== RootCauseOption.ADD_NEW),
          ),
        ] ?? [];

      const payload = {
        [Fields.CASE_TEMPLATE_ID]: values[Fields.CASE_TEMPLATE_ID],
        [Fields.CASE_TITLE]: values[Fields.CASE_TITLE],
        [Fields.CASE_DESCRIPTION]: values[Fields.CASE_DESCRIPTION],
        [Fields.CASE_PRIORITY]: values[Fields.CASE_PRIORITY],
        [Fields.CASE_SOURCE]: values[Fields.CASE_SOURCE],
        [Fields.IS_BETA]: values[Fields.IS_BETA] ? true : false,
        [Fields.COMPONENT_TYPE_IDS]: componentTypeIds,
        [Fields.ROOT_CAUSES]: rootCauses,
        [Fields.ANALYSIS_TEMPLATE_ID]: values[Fields.ANALYSIS_TEMPLATE_ID],
        [Fields.ANALYTICS_SOURCE]: values[Fields.ANALYTICS_SOURCE],
        [Fields.TASK_AUTO_CREATION]: values[Fields.TASK_AUTO_CREATION] ?? [],
        ...(values[Fields.TASK_AUTO_CREATION].length && {
          [Fields.TASK_SOURCE]: values[Fields.TASK_SOURCE],
        }),
      };
      update(payload);
    },
    [update],
  );

  if (!ready) {
    return <Loader />;
  }
  return (
    <DetailContainer>
      {caseTemplateData && (
        <CaseTemplatePanelHeader caseTemplateDetail={caseTemplateData} entityType={entityType} />
      )}
      <FormProvider {...methods}>
        <ScrollingContainer autoHide={false}>
          {caseTemplateData && (
            <StyledDiv>
              <SectionContainer>
                <Container isLoading={isLoading}>
                  {isLoading || isCaseTemplateDataLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <CaseDetails
                        caseDetails={caseTemplateData}
                        isLoading={isCaseTemplateDataLoading}
                        isEditing={isEditing}
                        toggleIsEditing={toggleIsEditing}
                      />
                      <AnalyticsDetails
                        caseDetails={caseTemplateData}
                        isLoading={isCaseTemplateDataLoading}
                        isEditing={isEditing}
                      />
                      <CaseTemplateTasks
                        caseTemplateData={caseTemplateData}
                        isEditing={isEditing}
                        templateMode={TaskTemplateModes.VIEW}
                      />
                    </>
                  )}
                </Container>
              </SectionContainer>
            </StyledDiv>
          )}
        </ScrollingContainer>
        {isEditing && (
          <>
            <FormErrorMessage isError={isError} message={error?.response?.data?.description} />
            <ActionButtons>
              <ActionSaveButtons>
                <Button onClick={(e) => toggleIsEditing(e)}>{t('general:cancel', 'Cancel')}</Button>
              </ActionSaveButtons>
              <ActionCancelButtons>
                <Button primary onClick={methods.handleSubmit(onSubmit)}>
                  {t('analysis_template:save', 'Save & Exit')}
                </Button>
              </ActionCancelButtons>
            </ActionButtons>
          </>
        )}
      </FormProvider>
    </DetailContainer>
  );
};

CaseTemplateDetailsPanel.propTypes = {
  entityType: PropTypes.string.isRequired,
  closePanel: PropTypes.func,
  caseTemplateData: PropTypes.any,
  isCaseTemplateDataLoading: PropTypes.any,
  caseTemplateId: PropTypes.any,
};

CaseTemplateDetailsPanel.defaultProps = {
  entityType: null,
};

export default CaseTemplateDetailsPanel;
