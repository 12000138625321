import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';

import { EventType } from '@ge/models/constants';

/**
 * Work-around of app structure to update the easy-peasy store with permissions when the IAC tokens
 * (and therefore associated metadata) are renewed.
 */
const usePermissionListener = () => {
  const { setMetadata } = useStoreActions((actions) => actions.auth);

  useEffect(() => {
    const updateMetadata = (e) => setMetadata(e.detail.payload);

    window.addEventListener(EventType.PERMISSION_METADATA, updateMetadata);
    return () => {
      window.removeEventListener(EventType.PERMISSION_METADATA, updateMetadata);
    };
  }, [setMetadata]);
};

export default usePermissionListener;
