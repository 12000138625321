export const DateRange = {
  CUSTOM: 'custom',
  LAST_3_MONTHS: 'last_3_months',
  LAST_6_MONTHS: 'last_6_months',
};

export const FilterOptions = [
  {
    label: 'Last 3 months',
    key: DateRange.LAST_3_MONTHS,
    a11yKey: `filters.${DateRange.LAST_3_MONTHS}`,
  },
  {
    label: 'Last 6 months',
    key: DateRange.LAST_6_MONTHS,
    a11yKey: `filters.${DateRange.LAST_6_MONTHS}`,
  },
  {
    label: 'Custom',
    key: DateRange.CUSTOM,
    a11yKey: `filters.${DateRange.CUSTOM}`,
  },
];
