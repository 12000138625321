import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { BoldSearch } from '@ge/components/bold-search';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { TimeSignal } from '@ge/models/constants';
import { typography } from '@ge/tokens/typography';

const StyledCheckbox = styled(Checkbox)`
  > div {
    margin-top: 3px;
  }
`;

const Container = styled.div`
  padding: 0px 20px;
  border-bottom: solid 1px ${(props) => props.theme.dataExplorer.dialog.listBorderColor};
  label {
    padding: 6px 0px;
    cursor: pointer;
    span {
      flex: 1;
    }
  }
  ${({ checked }) =>
    checked
      ? css`
          background: ${(props) => props.theme.dataExplorer.dialog.listActiveColor};
          font-weight: ${typography.weight.bold};
        `
      : ``}
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          opacity: 0.4;
          pointer-events: none;
        `
      : ``}
`;

const SignalCheckbox = ({ checked, signal, timeAggr, selectSignal, filterText, disabled }) => {
  return (
    <Container key={`${signal.id}__${timeAggr}`} checked={checked} disabled={disabled}>
      <StyledCheckbox
        checkState={checked ? CheckedState.CHECKED : CheckedState.UNCHECKED}
        onChange={(e) => selectSignal(e, { ...signal, timeAggr })}
        label={
          <BoldSearch
            text={
              timeAggr && signal.id !== TimeSignal.id
                ? signal.name + ' [' + timeAggr.split('_')[0] + ']'
                : signal.name
            }
            textBold={filterText}
          />
        }
        disabled={disabled}
      />
    </Container>
  );
};

SignalCheckbox.propTypes = {
  checked: PropTypes.bool,
  signal: PropTypes.object,
  timeAggr: PropTypes.string,
  filterText: PropTypes.string,
  disabled: PropTypes.bool,
  selectSignal: PropTypes.func,
};

SignalCheckbox.defaultProps = {
  checked: false,
  signal: {},
  timeAggr: '',
  filterText: '',
  selectSignal: () => {},
};

export default SignalCheckbox;
