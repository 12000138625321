import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { DatePicker } from '@ge/components/datepicker';
import { Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { Text } from '@ge/components/typography';
import { DateTimeFormats } from '@ge/models/constants';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { getDayEndTime } from '@ge/shared/util';
import { typography } from '@ge/tokens';

import { useManualAdjustmentContext } from '../../context/manual-adjustment-context';
import {
  useContractualReportingMalCategory,
  useCreateMalEvent,
} from '../../data-hooks/use-contractual-reporting-events';
import { maLegendValues } from '../../models/manual-adjustment-legends';

import AddEventDetailDialog from './add-event-detail-dialog';
import {
  Container,
  CTAAddButtons,
  CTAContainer,
  DialogGrid2,
  DialogLabel,
  DialogValue,
  IconButtonIcon,
  EventCategory,
  EventCategoryName,
  EventCategoryColor,
  StyledButton,
  StyledSelect,
  TimeIcon,
} from './event-dialog-style';

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 14px 0px;
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  margin: 2px -3px -10px -2px;
  color: ${(props) => props.theme.dangerColor};
  display: block;
  .custom-body-2 {
    font-size: 11px;
    line-height: 10px;
  }
`;

const IconButton = styled.button`
  color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.editButtonColor};
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  text-transform: uppercase;
`;

const getTimezoneOffsetUsingDate = (timezone, selectedDate) => {
  if (!timezone) return 0;
  return dayjs(selectedDate).tz(timezone).utcOffset();
};

const getConvertedTime = (time, calendarDate, timezone) => {
  let selectedTime = time;

  if (!dayjs(time).isSame(dayjs(calendarDate))) {
    selectedTime = dayjs(calendarDate)
      .set('hour', dayjs(time).get('hour'))
      .set('minute', dayjs(time).get('minute'))
      .set('second', dayjs(time).get('second')).$d;
  }
  const selectedDate = `${selectedTime.toString().split('GMT')[0]} GMT`;
  let timeZoneOffset = getTimezoneOffsetUsingDate(timezone, selectedDate) / 60;
  timeZoneOffset = timeZoneOffset > 0 ? `+${timeZoneOffset}` : timeZoneOffset;
  const timeString = `${selectedDate}${timeZoneOffset}`;
  return Date.parse(timeString) / 1000;
};

const formatHours = (value) => {
  const timeInput = value.target;
  const intValidNum = timeInput.value;

  let hours = intValidNum.split(':')[0];
  const regex = new RegExp('^[0-9]+$');
  if (intValidNum < 24 && intValidNum.length == 2) {
    timeInput.value = timeInput.value + ':';
  }
  if (intValidNum == 24 && intValidNum.length == 2) {
    timeInput.value = timeInput.value.length - 2 + '0:';
  }
  if (hours > 23 || hours.length > 2) {
    timeInput.value = timeInput.value.slice(0, 1);
  }
  if (intValidNum > 24 && intValidNum.length == 2) {
    timeInput.value = '';
  }
  if (hours && !regex.test(hours)) {
    timeInput.value = '';
  }
  return timeInput.value;
};
const formatMins = (value) => {
  const timeInput = value.target;
  const intValidNum = timeInput.value;
  let mins = intValidNum.split(':')[1];

  const regex = new RegExp('^[0-9]+$');

  if (intValidNum.length == 5 && intValidNum.slice(-2) > 60) {
    timeInput.value = timeInput.value.slice(0, 2) + ':00';
  }
  if (intValidNum.length == 5 && intValidNum.slice(-2) == 60) {
    timeInput.value = timeInput.value.slice(0, 2) + ':00';
  }
  if (mins && !regex.test(mins)) {
    timeInput.value = '';
  }
  return timeInput.value;
};
const formatSecs = (value) => {
  const timeInput = value.target;
  const intValidNum = timeInput.value;
  let secs = intValidNum.split(':')[2];
  const regex = new RegExp('^[0-9]+$');
  if (intValidNum.length == 8 && intValidNum.slice(-2) > 60) {
    timeInput.value = timeInput.value.slice(0, 5) + ':00';
  }
  if (intValidNum.length == 8 && intValidNum.slice(-2) == 60) {
    timeInput.value = timeInput.value.slice(0, 5) + ':00';
  }
  if (secs && !regex.test(secs)) {
    timeInput.value = '';
  }
  return timeInput.value;
};

const formatTime = (value) => {
  const timeInput = value.target;
  timeInput.value = formatHours(value);
  timeInput.value = formatMins(value);
  timeInput.value = formatSecs(value);
  return timeInput.value;
};

export const EditEventDetailDialog = ({
  calculateDuration,
  setEditMode,
  timezone,
  showMalEvents,
  assetEventsKind,
}) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { calendarDate, selectedEvent, selectedSite } = useManualAdjustmentContext();
  const { event } = selectedEvent;
  const [malEventsData, setMalEventsData] = useState();
  const { data: malCategoryData, isLoading: malCategoryLoading } =
    useContractualReportingMalCategory({
      siteId: selectedSite.id,
    });

  const onSuccess = () => {
    setEditMode(false);
    showMalEvents(malEventsData, malCategoryData);
  };

  const { create, isError } = useCreateMalEvent({
    siteId: selectedSite.id,
    onSuccess,
  });

  const methods = useForm({ mode: 'onChange' });

  const { control, handleSubmit } = methods;
  const [selectEventCategory, setSelectEventCategory] = useState(
    event.type === 'MAL' ? event.eventCategory : '',
  );
  const [selectEventName, setSelectEventName] = useState(
    event.type === 'MAL' ? event.malCategory : '',
  );
  const childRef = useRef();
  const [addCounter, setAddCounter] = useState(0);
  const startTime = dayjs(event.startTime).tz(timezone); // dayjs(event.startTime).utc(true).tz(timezone);
  const endTime = dayjs(getDayEndTime(event.endTime, timezone, calendarDate)).tz(timezone); // dayjs(event.endTime).utc(true).tz(timezone);
  const [editStartTime, setEditStartTime] = useState(dayjs(startTime).$d);
  const [editEndTime, setEditEndTime] = useState(dayjs(endTime).$d);
  const [showStartTime, setShowStartTime] = useState(dayjs(startTime).format('HH:mm:ss'));
  const [showEndTime, setShowEndTime] = useState(dayjs(endTime).format('HH:mm:ss'));
  const [timeDuration, setTimeDuration] = useState();

  useEffect(() => {
    setTimeDuration(calculateDuration(editStartTime, editEndTime));
  }, [editStartTime, editEndTime]);

  const handleEventNameChange = useCallback(
    ({ value }) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { eventCategory } = malCategoryData?.find((data) => data.id === value);
      setSelectEventCategory(eventCategory);
      setSelectEventName(value);
    },
    [malCategoryData],
  );
  const user_details = JSON.parse(
    Object.values(sessionStorage).filter((key) => key.includes('profile'))[0],
  );

  const categoryOptions = useMemo(
    () =>
      maLegendValues[assetEventsKind]?.map((e) => ({
        label: t(`legend.${e.a11yKey}.dialog_title`),
        value: e.category,
      })),
    [assetEventsKind, t],
  );

  const eventOptions = useMemo(
    () =>
      typeof malCategoryData === 'object'
        ? malCategoryData?.map((e) => ({
            label: e.description,
            value: e.id,
          }))
        : [],
    [malCategoryData],
  );

  const onEditStartTimeChange = (value) => {
    setEditStartTime(value);
    setTimeDuration(calculateDuration(value, editEndTime));
  };
  const onEditEndTimeChange = (value) => {
    setEditEndTime(value);
    setTimeDuration(calculateDuration(editStartTime, value));
  };

  const timeDurationError = (
    <ErrorText>
      <span className="custom-body-2">
        {t(
          'event_details_dialog.invalid_starttime_error',
          ' Start time should be less than end time.',
        )}
      </span>
    </ErrorText>
  );
  const onSubmit = useCallback(
    (values) => {
      if (
        dayjs(
          `${dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)} ${dayjs(
            values.editStartTime,
          ).format('HH:mm:ss')}`,
        ) >
        dayjs(
          `${dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)} ${dayjs(
            values.editEndTime,
          ).format('HH:mm:ss')}`,
        )
      ) {
        setTimeDuration(timeDurationError);
        return false;
      }
      const editMALCategory =
        typeof values.editMALCategory === 'object'
          ? values.editMALCategory.value
          : values.editMALCategory;
      const eventCategory = malCategoryData?.filter((data) => data.id === editMALCategory);
      let malEvents = [
        {
          startTime: getConvertedTime(values.editStartTime, calendarDate, timezone),
          endTime: getConvertedTime(values.editEndTime, calendarDate, timezone),
          assetId: selectedEvent.id,
          adjustedBy: user_details ? user_details.profile.email : '',
          adjustedTime: getConvertedTime(dayjs().$d, calendarDate, timezone),
          day: dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM),
          eventCategory: eventCategory[0]?.eventCategory,
          malCategory: editMALCategory,
        },
      ];

      for (let index = 0; index < addCounter; index++) {
        if (
          dayjs(
            `${dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)} ${dayjs(
              values[`addStartTime${index}`],
            ).format('HH:mm:ss')}`,
          ) >
          dayjs(
            `${dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)} ${dayjs(
              values[`addEndTime${index}`],
            ).format('HH:mm:ss')}`,
          )
        ) {
          childRef.current.setTimeDuration(timeDurationError);
          return false;
        }
        const eventCategory = malCategoryData?.filter(
          (data) => data.id === values[`addMALCategory${index}`].value,
        );
        malEvents.push({
          startTime: getConvertedTime(values[`addStartTime${index}`], calendarDate, timezone),
          endTime: getConvertedTime(values[`addEndTime${index}`], calendarDate, timezone),
          assetId: selectedEvent.id,
          adjustedBy: user_details ? user_details.profile.email : '',
          adjustedTime: getConvertedTime(dayjs().$d, calendarDate, timezone),
          day: dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM),
          eventCategory: eventCategory[0]?.eventCategory,
          malCategory: values[`addMALCategory${index}`].value,
        });
      }
      setMalEventsData(malEvents);
      create({ malEvents });
    },
    [create, malCategoryData, addCounter],
  );

  if (!ready || malCategoryLoading) {
    return <Loader />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <ConditionalRender shouldRender={isError}>
          <StyledError />
        </ConditionalRender>
        <EventCategory>
          <EventCategoryColor
            backgroundColor={
              maLegendValues[assetEventsKind]?.find(
                ({ category }) => category === selectEventCategory,
              )?.color
            }
            size={'10px'}
          />
          <EventCategoryName>
            {categoryOptions?.find(({ value }) => value === selectEventCategory)?.label ??
              t('event_details_dialog.eventcategory_placeholder', 'Event Category')}
          </EventCategoryName>
        </EventCategory>
        <StyledSelect>
          <Controller
            control={control}
            name="editMALCategory"
            id="editMALCategory"
            rules={{
              required: true,
            }}
            defaultValue={selectEventName ?? null}
            render={({ onChange }) => (
              <Select
                onChange={(val) => {
                  onChange(val);
                  handleEventNameChange(val);
                }}
                name="editMALCategory"
                placeholder={t(
                  'event_details_dialog.dropdown_placeholder',
                  'Please select an option',
                )}
                options={eventOptions}
                value={eventOptions && eventOptions?.find(({ value }) => value === selectEventName)}
                error={methods.errors?.editMALCategory?.type}
              />
            )}
          />
        </StyledSelect>
        <DialogGrid2>
          <div>
            <DialogLabel>{t('event_details_dialog.event_duration', 'Duration')}</DialogLabel>
            <DialogValue>{timeDuration}</DialogValue>
          </div>
          <div>
            <DialogLabel>{t('event_details_dialog.event_start', 'Start')}</DialogLabel>
            <Container>
              <Controller
                control={control}
                name="editStartTime"
                defaultValue={editStartTime}
                render={({ onChange }) => (
                  <DatePicker
                    showTimeSelect
                    dateFormat={DateTimeFormats.DEFAULT_TIME_W_SECS}
                    timeFormat="HH:mm:ss"
                    onChange={(val) => {
                      onChange(val);
                      onEditStartTimeChange(val);
                    }}
                    customInput={
                      <div className="date-picker-member">
                        <Input
                          type="text"
                          placeholder="hh:mm:ss"
                          maxLength={8}
                          value={showStartTime}
                          onChange={(e) => setShowStartTime(formatTime(e))}
                        />
                      </div>
                    }
                    selected={editStartTime}
                    timeIntervals={1}
                    open={false}
                  />
                )}
              />
              <IconButton>
                <TimeIcon />
              </IconButton>
            </Container>
          </div>
          <div>
            <DialogLabel>{t('event_details_dialog.event_end', 'End')}</DialogLabel>
            <Container>
              <Controller
                control={control}
                name="editEndTime"
                defaultValue={editEndTime}
                render={({ onChange }) => (
                  <DatePicker
                    showTimeSelect
                    dateFormat={DateTimeFormats.DEFAULT_TIME_W_SECS}
                    timeFormat="HH:mm:ss"
                    onChange={(val) => {
                      onChange(val);
                      onEditEndTimeChange(val);
                    }}
                    customInput={
                      <div className="date-picker-member">
                        <Input
                          type="text"
                          placeholder="hh:mm:ss"
                          maxLength={8}
                          value={showEndTime}
                          onChange={(e) => setShowEndTime(formatTime(e))}
                        />
                      </div>
                    }
                    selected={editEndTime}
                    timeIntervals={1}
                    open={false}
                  />
                )}
              />
              <IconButton>
                <TimeIcon />
              </IconButton>
            </Container>
          </div>
        </DialogGrid2>
        {[...Array(addCounter)].map((e, i) => (
          <AddEventDetailDialog
            malCategoryOption={malCategoryData}
            index={i}
            key={i}
            event={event}
            setEditMode={setEditMode}
            categoryOptions={categoryOptions}
            eventOptions={eventOptions}
            calculateDuration={calculateDuration}
            timezone={timezone}
            formatTime={formatTime}
            ref={childRef}
            assetEventsKind={assetEventsKind}
          />
        ))}
        <CTAContainer>
          <CTAAddButtons>
            <div>
              <IconButton type="button" onClick={() => setAddCounter(addCounter + 1)}>
                <IconButtonIcon icon={Icons.ADD} />
                {t('event_details_dialog.add_event_button', 'Add Event')}
              </IconButton>
            </div>
            <div>
              <StyledButton onClick={() => setEditMode(false)}>
                {t('event_details_dialog.cancel_button', 'Cancel')}
              </StyledButton>
            </div>
            <div>
              <StyledButton primary onClick={handleSubmit(onSubmit)}>
                {t('event_details_dialog.save_button', 'Save')}
              </StyledButton>
            </div>
          </CTAAddButtons>
        </CTAContainer>
      </FormProvider>
    </>
  );
};

EditEventDetailDialog.propTypes = {
  calculateDuration: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  showMalEvents: PropTypes.func.isRequired,
  assetEventsKind: PropTypes.string,
};
