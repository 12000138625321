import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const StyledMenuTable = styled.div`
  width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
    background: transparent;
    td,
    th {
      padding: 6px 10px;
    }
    td:last-child {
      padding: 6px;
    }
    tr {
      border-bottom: solid 1px ${(props) => props.theme.menu.borderColor};
      background: transparent;
    }
    thead tr th {
      color: ${(props) => props.theme.menu.tableHeaderTextColor};
      font-weight: 500;
      font-size: 13px;
      text-align: left;
      cursor: default;
    }
    tbody tr {
      color: ${(props) => props.theme.menu.tableBodyTextColor};
      font-weight: 500;
      font-size: 12px;
      cursor: ${(props) => (props.disableHover ? 'default' : 'pointer')};
      text-transform: capitalize;
      &:hover {
        background: ${(props) =>
          props.disableHover ? 'transparent' : props.theme.menu.hoverBackground};
      }
    }
  }
`;

export const MenuTable = ({ style, children, disableHover }) => {
  return (
    <StyledMenuTable style={style} disableHover={disableHover}>
      {children}
    </StyledMenuTable>
  );
};

MenuTable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disableHover: PropTypes.bool,
  style: PropTypes.object,
};

MenuTable.defaultProps = {
  children: {},
  disableHover: false,
  style: {},
};

export const MenuChevron = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.menu.tableChevronColor,
  rotate: -90,
}))``;

export const MenuNavigateChevron = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ARROW_UP,
  color: props.theme.menu.tableChevronColor,
  rotate: 90,
}))``;

export const MenuAllButton = styled.button`
  border: 0;
  background: transparent;
  color: ${(props) => props.theme.menu.tableBodyTextColor};
  width: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 13px;
  font-family: 'Museo Sans';
  font-weight: 500;
  height: 30px;
  cursor: pointer;
`;
