import { PropTypes } from 'prop-types';
import React, { useContext, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { elevations } from '@ge/tokens/elevations';

import { PlanningContext, TabOptions } from '../../context/planning-provider';

import { BacklogPanel } from './panels/backlog-panel';
import { TaskGroupPanel } from './panels/task-groups-panel';
import { TechsPanel } from './panels/techs-panel';
import Worker from './worker.svg';

const TabsContainer = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.manage.tabs.backgroundColor};
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      border-radius: 3px 3px 0 0;
      overflow: hidden;
      z-index: ${elevations.P1};
      background: ${(props) => props.theme.manage.tabs.backgroundColor};
      border-bottom: solid 2px ${(props) => props.theme.manage.tabs.borderColor};
      margin-left: 2px;
      &:first-child {
        margin-left: 0px;
      }
      button {
        height: 55px;
        width: 86px;
        text-align: center;
        padding: 0;
        &:focus {
          outline: none;
        }
        span {
          display: block;
          color: ${(props) => props.theme.manage.tabs.textColor};
          font-size: 11px;
          letter-spacing: -0.5px;
          font-weight: 600;
          line-height: 17px;
          margin-top: 2px;
        }
      }
      &.active {
        border-radius: 3px 3px 0 0;
        background: ${(props) => props.theme.manage.tabs.active.backgroundColor};
        border-bottom: solid 2px ${(props) => props.theme.manage.tabs.active.borderColor};
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
        z-index: ${elevations.P2};
        button span {
          color: ${(props) => props.theme.manage.tabs.active.textColor};
        }
        svg {
          fill: ${(props) => props.theme.manage.tabs.active.iconColor};
        }
      }
    }
  }
  .tab-content {
    background: ${(props) => props.theme.manage.tabs.active.backgroundColor};
    z-index: ${elevations.P2};
    height: calc(100% - 40px);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  }
`;

const TabIcon = styled(Icon).attrs((props) => ({
  size: 14,
  color: props.theme.manage.tabs.textColor || '',
}))``;

const CountBadge = styled.div`
  color: ${(props) => props.theme.manage.tabs.badge.textColor};
  font-weight: 500;
  font-size: 7px;
  text-align: center;
  height: 11px;
  line-height: 11px;
  width: 17px;
  background-color: ${(props) => props.theme.manage.tabs.badge.backgroundColor};
  border-radius: 4px;
  display: inline-block;
  margin-left: -4px;
  z-index: 10;
  position: relative;
  top: -3px;
`;

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

export const PlanningTabs = ({
  scheduleData,
  backlogData,
  isFetching,
  workerData,
  crewData,
  isFetchingWorkers,
}) => {
  const { t } = useTranslation(['manage.planning', 'tasks', 'entity-details', 'general'], {
    useSuspense: false,
  });
  const { planningState } = useContext(PlanningContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedTab, setSelectedTab] = useState(false);

  const setTab = (tab) => {
    if (planningState.crewEdited) {
      setShowConfirmation(true);
      setSelectedTab(tab);
    } else {
      planningState.setTab(tab);
    }
  };

  const renderPanel = () => {
    switch (planningState.tab) {
      case TabOptions.TECHS:
        return (
          <TechsPanel
            workerData={workerData}
            crewData={crewData}
            isFetchingWorkers={isFetchingWorkers}
          />
        );
      case TabOptions.BACKLOG:
        return <BacklogPanel backlogData={backlogData} isFetching={isFetching} />;
      default:
        return <TaskGroupPanel scheduleData={scheduleData} />;
    }
  };

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const handleContinue = useCallback(() => {
    planningState.setTab(selectedTab);
    setShowConfirmation(false);
    planningState.setCrewEdited(false);
  }, [planningState, selectedTab]);

  const getCancelFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={handleCancel}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleContinue}>
            {t('general:continue', 'Continue')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [handleCancel, handleContinue, t]);

  return (
    <TabsContainer>
      <ul>
        {/* need to remove this going forward and make it */}
        <AuthRender
          capability={Capability.PERSONNEL_MANAGEMENT}
          view
          description="View workers tab"
          siteLevel={false}
        >
          <li className={planningState.tab === TabOptions.TECHS ? 'active' : ''}>
            <button
              onClick={() => {
                setTab(TabOptions.TECHS);
              }}
              type="button"
            >
              <img src={Worker} alt="workers" />
              <span>{t('workers', 'Workers')}</span>
            </button>
          </li>
        </AuthRender>
        <li className={planningState.tab === TabOptions.BACKLOG ? 'active' : ''}>
          <button
            onClick={() => {
              setTab(TabOptions.BACKLOG);
            }}
            type="button"
          >
            <TabIcon icon={Icons.SI} />
            {backlogData.length > 0 && <CountBadge>{backlogData.length}</CountBadge>}
            <span>{t('backlog', 'Backlog')}</span>
          </button>
        </li>
        {/* Hiding for MVP0 - Story #1150 */}
        {/*<li className={planningState.tab === TabOptions.RECOMMENDED ? 'active' : ''}>
          <button
            onClick={() => {
              setTab(TabOptions.RECOMMENDED);
            }}
            type="button"
          >
            <TabIcon icon={Icons.TASK_GROUP} />
            {scheduleData.length > 0 && <CountBadge>{scheduleData.length}</CountBadge>}
            <span>{t('recommended', 'Recommended')}</span>
          </button>
          </li>*/}
      </ul>

      <div className="tab-content">{renderPanel()}</div>
      {showConfirmation && (
        <Dialog
          isOpen={true}
          onClose={handleCancel}
          onConfirm={handleContinue}
          header={t('general:confirmation', 'Confirmation')}
          footer={getCancelFooter}
          contentWidth
          padContent={true}
        >
          <p>
            {t(
              'tasks:form.continue_without_saving',
              'Are you sure you want to continue without saving changes?',
            )}
          </p>
        </Dialog>
      )}
    </TabsContainer>
  );
};

PlanningTabs.propTypes = {
  scheduleData: PropTypes.instanceOf(Object).isRequired,
  backlogData: PropTypes.instanceOf(Object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  workerData: PropTypes.instanceOf(Object).isRequired,
  crewData: PropTypes.instanceOf(Object).isRequired,
  isFetchingWorkers: PropTypes.bool.isRequired,
};
