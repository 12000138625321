import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ErpStatusIcon } from '@ge/components/erp-status-icon';
import { Icon, Icons } from '@ge/components/icon';
import { placements } from '@ge/components/menu';
import { TooltipCell } from '@ge/components/table/table';
import { TaskStatus } from '@ge/models';
import { DateTimeFormats, TaskFlag, TaskSourceField } from '@ge/models/constants';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { toSiteISOString } from '@ge/shared/util/time-date';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const ActionsMenuItems = styled.div`
  border-radius: 1px;
  padding: 10px 8px 10px 6px;
  margin-top: 1px;
  background-color: ${globalColors.slate2};
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  height: 20px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CLOSE,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const SnoozeIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.SNOOZE,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const SnoozedIcon = styled(Icon).attrs(() => ({
  size: 15,
  icon: Icons.EVENT_SNOOZED,
  color: '#FFDD99',
}))`
  margin-top: 8px;
  padding-right: 1px;
`;

const CalendarIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CALENDAR,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const CompletedIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CHECK,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const ActionsButton = styled.button`
  border-radius: 2px;
  position: relative;
  &:focus {
    outline: none;
  }
  padding: 2.9px;
  background: transparent;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const ActionsButtonErp = styled(ActionsButton)`
  padding: 0;
  margin-right: 5px;
`;

const LeftIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CHEVRON,
  rotate: -90,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

export const Actions = ({
  task,
  erpStatus,
  siteId,
  setShowConfirmMessage,
  crewIds,
  isBundled,
  onActionClick,
  onClose,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const { enabledApprovalFlag } = useStoreState((state) => state.tasks);

  const currentDate = toSiteISOString(dayjs(dayjs(new Date()).format('YYYY-MM-DD')));
  const { showTaskDetails, setCrewForBundledTask } = useContext(EntityDetailsContext);

  const closeSecondaryTask = (crewId, isSecondaryBundle) => {
    setCrewForBundledTask((prevState) => ({
      ...prevState,
      crewId,
      isSecondaryBundle,
    }));
  };

  const getErpStatusIconMarkup = (task) => (
    <span>
      <AuthRender
        capability={Capability.WORK_ORDERS}
        description="ERP Status"
        edit
        siteIds={[siteId]}
      >
        <ActionsButtonErp
          onClick={() => {
            showTaskDetails(task.id, 'erp');
            onClose();
            closeSecondaryTask(crewIds, isBundled);
          }}
        >
          <ErpStatusIcon
            task={task}
            erpStatus={erpStatus}
            tooltipCustomClass="tooltip-erp-status"
            tooltipZIndex={elevations.P101}
            width="12px"
            backgroundOverride={TaskStatus.UNSCHEDULED}
          />
        </ActionsButtonErp>
      </AuthRender>
    </span>
  );

  return (
    <>
      <ActionsMenuItems>
        {/* send to backlog */}
        {task.status !== TaskStatus.COMPLETE && (
          <span>
            {task?.status === TaskStatus.UNSCHEDULED && task?.source === TaskSourceField.ANOMALY ? (
              <ActionsButton
                onClick={() =>
                  setShowConfirmMessage({
                    isOpen: true,
                    key: task.id,
                    status: task.status,
                    type: task.source,
                    isMultiSelect: false,
                  })
                }
              >
                <TooltipCell
                  customClass="tooltip-backlog"
                  placement={placements.TOP}
                  tooltip={t('tasks:form.send_to_backlog', 'Send to Backlog')}
                  zIndex={elevations.P101}
                >
                  <CloseIcon />
                </TooltipCell>
              </ActionsButton>
            ) : (
              <AuthRender
                capability={Capability.WORK_PLAN}
                description="Send to backlog"
                edit
                siteIds={[siteId]}
              >
                <ActionsButton
                  onClick={() =>
                    setShowConfirmMessage({
                      isOpen: true,
                      key: task.id,
                      status: task.status,
                      type: task.source,
                    })
                  }
                >
                  <TooltipCell
                    customClass="tooltip-backlog"
                    placement={placements.TOP}
                    tooltip={t('tasks:form.send_to_backlog', 'Send to Backlog')}
                    zIndex={elevations.P101}
                  >
                    <CloseIcon />
                  </TooltipCell>
                </ActionsButton>
              </AuthRender>
            )}
          </span>
        )}
        {/* snooze */}
        {task.status !== TaskStatus.COMPLETE && (
          <span>
            <AuthRender
              capability={Capability.WORK_PLAN}
              description="Snooze"
              edit
              siteIds={[siteId]}
            >
              <ActionsButton onClick={(event) => onActionClick(event, task, 'snooze')}>
                <TooltipCell
                  customClass={
                    task?.eligibleStartDate && task?.eligibleStartDate > currentDate
                      ? 'tooltip-snoozed'
                      : 'tooltip-snooze'
                  }
                  placement={placements.TOP}
                  tooltip={
                    task?.eligibleStartDate && task?.eligibleStartDate > currentDate
                      ? `${t('tasks:form.snoozed', 'Snoozed:')} ${task?.eligibleStartDateTz?.format(
                          DateTimeFormats.DEFAULT_DATE,
                        )}`
                      : t('tasks:form.snooze', 'Snooze')
                  }
                  zIndex={elevations.P101}
                >
                  {task?.eligibleStartDate && task?.eligibleStartDate > currentDate ? (
                    <SnoozedIcon />
                  ) : (
                    <SnoozeIcon />
                  )}
                </TooltipCell>
              </ActionsButton>
            </AuthRender>
          </span>
        )}
        {/* schedule/reschedule */}
        {task.status !== TaskStatus.COMPLETE && (
          <span>
            <AuthRender
              capability={Capability.WORK_PLAN}
              description="Schedule/Reschedule"
              edit
              siteIds={[siteId]}
            >
              <ActionsButton
                onClick={(event) => onActionClick(event, task, 'schedule')}
                disabled={approvalFlag && task.flag !== TaskFlag.APPROVED && enabledApprovalFlag}
              >
                <TooltipCell
                  customClass={
                    task.status === TaskStatus.UNSCHEDULED
                      ? 'tooltip-schedule'
                      : 'tooltip-reschedule'
                  }
                  placement={placements.TOP}
                  tooltip={
                    task.status === TaskStatus.UNSCHEDULED
                      ? t('tasks:form.schedule', 'Schedule')
                      : t('tasks:form.reschedule', 'Reschedule')
                  }
                  zIndex={elevations.P101}
                >
                  <CalendarIcon />
                </TooltipCell>
              </ActionsButton>
            </AuthRender>
          </span>
        )}
        {/* ERP status icon */}
        {getErpStatusIconMarkup(task)}
        {/* close */}
        <span>
          <AuthRender
            capability={Capability.WORK_PLAN}
            description="Complete"
            edit
            siteIds={[siteId]}
          >
            <ActionsButton
              onClick={() => {
                showTaskDetails(task.id, 'resolution');
                onClose();
                closeSecondaryTask(crewIds, isBundled);
              }}
            >
              <TooltipCell
                customClass="tooltip-close"
                placement={placements.TOP}
                tooltip={t('tasks:form.close', 'Close')}
                zIndex={elevations.P101}
              >
                <CompletedIcon />
              </TooltipCell>
            </ActionsButton>
          </AuthRender>
        </span>
        {/* task detail */}
        <span>
          <ActionsButton
            onClick={() => {
              showTaskDetails(task.id);
              onClose();
              closeSecondaryTask(crewIds, isBundled);
            }}
          >
            <TooltipCell
              customClass="tooltip-details"
              placement={placements.TOP}
              tooltip={t('tasks:form.details', 'Details')}
              zIndex={elevations.P101}
            >
              <LeftIcon />
            </TooltipCell>
          </ActionsButton>
        </span>
      </ActionsMenuItems>
    </>
  );
};

Actions.propTypes = {
  task: PropTypes.instanceOf(Object),
  erpStatus: PropTypes.object,
  siteId: PropTypes.string,
  primaryTaskDetails: PropTypes.instanceOf(Object),
  setShowConfirmMessage: PropTypes.func,
  crewIds: PropTypes.string,
  isBundled: PropTypes.bool,
  onActionClick: PropTypes.func,
  onClose: PropTypes.func,
};

Actions.defaultProps = {
  task: null,
  erpStatus: null,
  siteId: null,
  setShowConfirmMessage: () => null,
  crewIds: null,
  isBundled: false,
  onActionClick: () => null,
  onClose: () => null,
};
