import { PropTypes } from 'prop-types';
import React, { useEffect, useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import useStateRef from '@ge/hooks/state-ref';
import { FormMode, EntityType, NotesScope, Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const ExternalViewing = styled.div`
  display: flex;
  span {
    font: 300 12px/15px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
`;

const NoteFooter = forwardRef(
  ({ isLoading, mode, data, entityType, onCancel, onSubmit, assetsLength }, parentRef) => {
    const { t } = useTranslation(['entity-details']);
    const [externalViewing, setExternalViewing, externalViewingRef] = useStateRef(
      CheckedState.UNCHECKED,
    );

    useEffect(() => {
      setExternalViewing(
        mode === FormMode.EDIT && data.scope === NotesScope.EXTERNAL
          ? CheckedState.CHECKED
          : CheckedState.UNCHECKED,
      );
    }, [data, mode, setExternalViewing]);

    const toggleExternalViewing = useCallback(() => {
      setExternalViewing(
        externalViewingRef.current === CheckedState.CHECKED
          ? CheckedState.UNCHECKED
          : CheckedState.CHECKED,
      );
    }, [setExternalViewing, externalViewingRef]);

    return (
      <FooterContainer>
        <AuthRender capability={Capability.INTERNAL_ACCESS} edit siteLevel={false}>
          {entityType !== EntityType.EVENT && (
            <ExternalViewing>
              <StyledCheckbox
                id="externalViewing"
                label={t('notes.allow-external_viewing', 'Allow External Viewing')}
                name="externalViewing"
                checkState={externalViewing}
                onChange={toggleExternalViewing}
                ref={parentRef}
              />
            </ExternalViewing>
          )}
        </AuthRender>
        <FooterButtons>
          <Button onClick={onCancel} disabled={isLoading}>
            {t('general:cancel', 'Cancel')}
          </Button>
          <Button primary onClick={onSubmit} disabled={isLoading}>
            {assetsLength > 1 ? t('general:save_all', 'Save All') : t('general:save', 'Save')}
          </Button>
        </FooterButtons>
      </FooterContainer>
    );
  },
);

NoteFooter.propTypes = {
  namespace: PropTypes.string,
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  data: PropTypes.instanceOf(Object),
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  onCancel: PropTypes.any,
  onSubmit: PropTypes.func,
  assetsLength: PropTypes.number,
};

NoteFooter.defaultProps = {
  namespace: 'entity-details',
  isLoading: false,
  mode: FormMode.CREATE,
  data: null,
  entityType: null,
  onCancel: () => {},
  onSubmit: () => {},
};

NoteFooter.displayName = 'NoteFooter';

export default NoteFooter;
