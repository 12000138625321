import * as PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';

const StyledCheckBox = styled(Checkbox)`
  margin: ${({ margin }) => margin};
`;

export const CheckBoxMetaField = ({ className, name, label, defaultValue, metadata, margin }) => {
  const { control } = useFormContext();

  const { readOnly, required } = metadata;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required,
      }}
      render={({ ref, value, onChange, onBlur }) => (
        <StyledCheckBox
          ref={ref}
          className={className}
          name={name}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
          disabled={readOnly}
          margin={margin}
        />
      )}
    />
  );
};

CheckBoxMetaField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  metadata: PropTypes.instanceOf(Object).isRequired,
  margin: PropTypes.string,
};

CheckBoxMetaField.defaultProps = {
  name: `choices`,
  label: ``,
  defaultValue: false,
};
