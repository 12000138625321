import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Capability, CaseStatus } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';

import { CaseDetailRow, StyledCaseDetailRow } from './case-resolution-detail-rows';
import { CaseResolutionTasks } from './case-resolution-tasks';

const CaseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tasks.details.columnButtonColor,
}))`
  position: absolute;
  left: 0;
`;

const ViewResolutionContainer = styled.section``;

const ResolutionDisplay = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.resolutionDisplay};
  font-size: 14px;
  margin: 5px 0;
`;

const StyledResolutionDisplay = styled(ResolutionDisplay)`
  font-style: italic;
`;

export const CaseResolutionView = ({ issue, handleEditClick }) => {
  const { t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  return (
    <ViewResolutionContainer>
      <CaseDetailRow
        title={
          issue.status !== CaseStatus.CLOSED && (
            <AuthRender
              capability={Capability.CASES_RECOMMENDATIONS}
              siteIds={[issue.site?.id]}
              edit
            >
              <Button type="button" onClick={handleEditClick}>
                <CaseIcon size={11} icon={Icons.PENCIL} />
                {t('case_panel.resolution.edit_resolution', 'Edit Resolution')}
              </Button>
            </AuthRender>
          )
        }
        detail={<h2>{t('case_panel.resolution.resolution', 'Case Resolution')}</h2>}
      />

      <StyledCaseDetailRow detail={<>{t('case_panel.resolution.disposition', 'Disposition')}</>} />
      <CaseDetailRow
        detail={
          <>
            {issue.disposition ? (
              <StyledResolutionDisplay>{issue.disposition}</StyledResolutionDisplay>
            ) : (
              <ResolutionDisplay>
                {t('case_panel.resolution.not_selected', 'Not Yet Selected')}
              </ResolutionDisplay>
            )}
          </>
        }
      />

      <StyledCaseDetailRow detail={<>{t('case_panel.resolution.root_cause', 'Root Cause')}</>} />
      <CaseDetailRow
        detail={
          <>
            {issue.rootCause ? (
              <StyledResolutionDisplay>{issue.rootCause}</StyledResolutionDisplay>
            ) : (
              <ResolutionDisplay>
                {t('case_panel.resolution.not_selected', 'Not Yet Selected')}
              </ResolutionDisplay>
            )}
          </>
        }
      />

      <CaseResolutionTasks issue={issue} />
    </ViewResolutionContainer>
  );
};

CaseResolutionView.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  handleEditClick: PropTypes.func,
};
