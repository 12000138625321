import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadFile } from '@ge/feat-analyze/util';
import { formatKPIValue } from '@ge/feat-analyze/util';
import {
  Placeholders,
  KpiCategoryDefs,
  ContentType,
  DateTimeFormats,
  FileExtensionType,
  FileType,
  PresentationTypes,
  EntityType,
} from '@ge/models/constants';
import {
  AssetsColumnDefs,
  AssetsColumns,
  AssetsColumnsStorage,
  AnomalyColumnsStorage,
  SitesColumns,
  AllSitesColumnDefs,
  AssetsColumnStorageDefs,
  AnomalyColumnStorageDefs,
  ManualAdjustmentColumnsStorage,
  ManualAdjustmentColumnStorageDefs,
} from '@ge/shared/models/table-col-defs';

import { usePresentationType } from './use-presentation-type';

const InitialColumns = {
  DATE_RANGE: 'date_range',
};

const getPrimaryColumnMap = ({ dateRange, t }) => {
  return Object.values(InitialColumns).map((id) => {
    if (id === InitialColumns.DATE_RANGE) {
      const { startDate, endDate } = dateRange ?? {};
      return {
        id,
        label: t('date_range', 'Date Range'),
        defaultValue: t('from_date_to_date', {
          from: dayjs(startDate).format(DateTimeFormats.DEFAULT_DATE),
          to: dayjs(endDate).format(DateTimeFormats.DEFAULT_DATE),
        }),
        visible: true,
      };
    }
    return {
      id,
      label: '',
      defaultValue: '',
      visible: true,
    };
  });
};

const getAssetColumnMap = (presentationType) => {
  if (presentationType === PresentationTypes.GRID) {
    return {
      [AssetsColumns.ASSET]: AssetsColumns.ASSET,
      [AssetsColumns.ACTUAL_PRODUCTION_MWH]: KpiCategoryDefs.PRODUCTION_ACTUAL,
      [AssetsColumns.UNPRODUCED_ENERGY_MWH]: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
      [AssetsColumns.PBA]: KpiCategoryDefs.PBA,
      [AssetsColumns.TBA]: KpiCategoryDefs.TBA,
    };
  }
  return {
    [AssetsColumns.ASSET]: AssetsColumns.ASSET,
    [AssetsColumns.ASSET_MAKE]: AssetsColumns.ASSET_MAKE,
    [AssetsColumns.ASSET_MODEL]: AssetsColumns.ASSET_MODEL,
    [AssetsColumns.TBA]: KpiCategoryDefs.TBA,
    [AssetsColumns.AVAILABILITY_CONTRACT]: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    [AssetsColumns.PBA]: KpiCategoryDefs.PBA,
    [AssetsColumns.UNPRODUCED_ENERGY_MWH]: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    [AssetsColumns.ACTUAL_PRODUCTION_MWH]: KpiCategoryDefs.PRODUCTION_ACTUAL,
    [AssetsColumns.CAPACITY_FACTOR]: KpiCategoryDefs.CAPACITY_FACTOR,
    [AssetsColumns.AVERAGE_WINDSPEED]: KpiCategoryDefs.AVERAGE_WINDSPEED,
    [AssetsColumns.EVENT_COVERAGE]: KpiCategoryDefs.EVENT_COVERAGE,
  };
};

const getAssetColumnMapStorage = (entityType) => {
  if (entityType === EntityType.STORAGE_SITE) {
    return {
      [AssetsColumnsStorage.ASSET]: AssetsColumnsStorage.ASSET,
      [AssetsColumnsStorage.CONTRACTUAL_AVAILABILITY]:
        AssetsColumnsStorage.CONTRACTUAL_AVAILABILITY,
      [AssetsColumnsStorage.RAW_AVAILABILITY]: AssetsColumnsStorage.RAW_AVAILABILITY,
      [AssetsColumnsStorage.DAILY_AVG_SOC]: AssetsColumnsStorage.DAILY_AVG_SOC,
      [AssetsColumnsStorage.AVERAGE_CYCLES_PER_DAY]: AssetsColumnsStorage.AVERAGE_CYCLES_PER_DAY,
      [AssetsColumnsStorage.STATE_OF_HEALTH_SOH]: AssetsColumnsStorage.STATE_OF_HEALTH_SOH,
      [AssetsColumnsStorage.TOTAL_ENERGY_THROUGHPUT]: AssetsColumnsStorage.TOTAL_ENERGY_THROUGHPUT,
    };
  } else if (entityType === EntityType.ANOMALY) {
    return {
      [AnomalyColumnsStorage.ASSET]: AnomalyColumnsStorage.ASSET,
      [AnomalyColumnsStorage.COMPONENT]: AnomalyColumnsStorage.COMPONENT,
      [AnomalyColumnsStorage.TITLE]: AnomalyColumnsStorage.TITLE,
      [AnomalyColumnsStorage.PRIORITY]: AnomalyColumnsStorage.PRIORITY,
      [AnomalyColumnsStorage.TROUBLE_SHOOTING]: AnomalyColumnsStorage.TROUBLE_SHOOTING,
      [AnomalyColumnsStorage.LAST_FLAGGED]: AnomalyColumnsStorage.LAST_FLAGGED,
    };
  } else {
    return {
      [ManualAdjustmentColumnsStorage.EVENT]: ManualAdjustmentColumnsStorage.EVENT,
      [ManualAdjustmentColumnsStorage.START]: ManualAdjustmentColumnsStorage.START,
      [ManualAdjustmentColumnsStorage.STOP]: ManualAdjustmentColumnsStorage.STOP,
      [ManualAdjustmentColumnsStorage.STATUS]: ManualAdjustmentColumnsStorage.STATUS,
      [ManualAdjustmentColumnsStorage.DATE_SUBMITTED]:
        ManualAdjustmentColumnsStorage.DATE_SUBMITTED,
      [ManualAdjustmentColumnsStorage.ASSET]: ManualAdjustmentColumnsStorage.ASSET,
    };
  }
};

const getSiteColumnMap = (presentationType) => {
  if (presentationType === PresentationTypes.GRID) {
    return {
      [SitesColumns.SITE]: SitesColumns.SITE,
      [SitesColumns.ACTUAL_PRODUCTION]: KpiCategoryDefs.PRODUCTION_ACTUAL,
      [SitesColumns.UNPRODUCED_ENERGY_MWH]: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
      [SitesColumns.PBA]: KpiCategoryDefs.PBA,
      [SitesColumns.TBA]: KpiCategoryDefs.TBA,
    };
  }
  return {
    [SitesColumns.REGION]: SitesColumns.REGION,
    [SitesColumns.SITE]: SitesColumns.SITE,
    [SitesColumns.PLATFORM]: SitesColumns.PLATFORM,
    [SitesColumns.TBA]: KpiCategoryDefs.TBA,
    [SitesColumns.AVAILABILITY_CONTRACT]: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    [SitesColumns.PBA]: KpiCategoryDefs.PBA,
    [SitesColumns.UNPRODUCED_ENERGY_MWH]: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    [SitesColumns.ACTUAL_PRODUCTION]: KpiCategoryDefs.PRODUCTION_ACTUAL,
    [SitesColumns.CAPACITY_FACTOR]: KpiCategoryDefs.CAPACITY_FACTOR,
    [SitesColumns.EVENT_COVERAGE]: KpiCategoryDefs.EVENT_COVERAGE,
    [SitesColumns.REPORTING_VS_EXPECTED]: KpiCategoryDefs.REPORTING_ASSETS_RATIO,
  };
};

const formatKpiValue = (kpiValue, language) => {
  if (kpiValue || kpiValue === 0) {
    return `${formatKPIValue(kpiValue, language)}`;
  }
  return Placeholders.DASH;
};

const cellHeaderMapFn = ({ columnDefs, columns, t }) => {
  return columns.map(({ groupId, id, kpi, label }) => {
    switch (id) {
      case InitialColumns.DATE_RANGE:
        return label;
      case AssetsColumns.ASSET:
      case AssetsColumns.ASSET_MAKE:
      case AssetsColumns.ASSET_MODEL:
      case SitesColumns.REGION:
      case SitesColumns.SITE:
      case SitesColumns.PLATFORM:
      case SitesColumns.REPORTING_VS_EXPECTED:
        return (
          columnDefs[groupId].cols[id]?.labels
            .map(({ a11yKey, a11yDefault }) => t(a11yKey, a11yDefault))
            .join('/') ?? ''
        );
      default:
        return t(`dynamic.kpi_chart.${kpi}`);
    }
  });
};

const cellHeaderMapFnStorage = ({ columnDefs, columns, t }) => {
  return columns.map(({ groupId, id, label }) => {
    switch (id) {
      case InitialColumns.DATE_RANGE:
        return label;
      case AssetsColumnsStorage.ASSET:
      case AssetsColumnsStorage.CONTRACTUAL_AVAILABILITY:
      case AssetsColumnsStorage.RAW_AVAILABILITY:
      case AssetsColumnsStorage.STATE_OF_HEALTH_SOH:
      case AssetsColumnsStorage.DAILY_AVG_SOC:
      case AssetsColumnsStorage.AVERAGE_CYCLES_PER_DAY:
      case AssetsColumnsStorage.TOTAL_ENERGY_THROUGHPUT:
      case AnomalyColumnsStorage.ASSET:
      case AnomalyColumnsStorage.COMPONENT:
      case AnomalyColumnsStorage.TITLE:
      case AnomalyColumnsStorage.PRIORITY:
      case AnomalyColumnsStorage.TROUBLE_SHOOTING:
      case AnomalyColumnsStorage.LAST_FLAGGED:
      case ManualAdjustmentColumnsStorage.EVENT:
      case ManualAdjustmentColumnsStorage.STATUS:
      case ManualAdjustmentColumnsStorage.START:
      case ManualAdjustmentColumnsStorage.STOP:
      case ManualAdjustmentColumnsStorage.DATE_SUBMITTED:
      case ManualAdjustmentColumnsStorage.ASSET:
        return (
          columnDefs[groupId].cols[id]?.labels
            .map(({ a11yKey, a11yDefault }) => t(a11yKey, a11yDefault))
            .join('/') ?? ''
        );
      default:
        return t(``);
    }
  });
};

const assetDataMap =
  ({ language, columns }) =>
  (value) => {
    if (!value) return {};

    return columns.map(({ id, kpi, defaultValue }) => {
      switch (id) {
        case InitialColumns.DATE_RANGE:
          return defaultValue;
        case AssetsColumns.ASSET:
          return `="${value.name}"`;
        case AssetsColumns.ASSET_MAKE:
          return `="${value.make}"`;
        case AssetsColumns.ASSET_MODEL:
          return `="${value.model}"`;
        case AssetsColumns.AVERAGE_WINDSPEED:
          return value.metrics?.conditions?.windSpeedAverage;
        default:
          return formatKpiValue(value.metrics?.performance?.[kpi], language);
      }
    });
  };

const assetDataMapStorage =
  ({ columns }) =>
  (value) => {
    if (!value) return {};

    return columns.map(({ id, defaultValue }) => {
      switch (id) {
        case InitialColumns.DATE_RANGE:
          return defaultValue;
        case AssetsColumnsStorage.ASSET:
          return `="${value.asset}"`;
        case AssetsColumnsStorage.CONTRACTUAL_AVAILABILITY:
          return `="${value.contractual_availability}"`;
        case AssetsColumnsStorage.RAW_AVAILABILITY:
          return `="${value.raw_availability}"`;
        case AssetsColumnsStorage.STATE_OF_HEALTH_SOH:
          return `="${value.state_of_health_soh}"`;
        case AssetsColumnsStorage.DAILY_AVG_SOC:
          return `="${value.daily_avg_soc}"`;
        case AssetsColumnsStorage.AVERAGE_CYCLES_PER_DAY:
          return `="${value.average_cycles_per_day}"`;
        case AssetsColumnsStorage.TOTAL_ENERGY_THROUGHPUT:
          return `="${value.total_energy_throughput}"`;
        case AnomalyColumnsStorage.ASSET:
          return `="${value.asset}"`;
        case AnomalyColumnsStorage.COMPONENT:
          return `="${value.component}"`;
        case AnomalyColumnsStorage.TITLE:
          return `="${value.title}"`;
        case AnomalyColumnsStorage.PRIORITY:
          return `="${value.priority}"`;
        case AnomalyColumnsStorage.TROUBLE_SHOOTING:
          return `="${value.trobule_shooting}"`;
        case AnomalyColumnsStorage.LAST_FLAGGED:
          return `="${value.last_flagged}"`;
        case ManualAdjustmentColumnsStorage.EVENT:
          return `="${value.event}"`;
        case ManualAdjustmentColumnsStorage.START:
          return `="${value.start}"`;
        case ManualAdjustmentColumnsStorage.STATUS:
          return `="${value.status}"`;
        case ManualAdjustmentColumnsStorage.STOP:
          return `="${value.stop}"`;
        case ManualAdjustmentColumnsStorage.DATE_SUBMITTED:
          return `="${value.dateSubmitted}"`;
        case ManualAdjustmentColumnsStorage.ASSET:
          return `="${value.asset}"`;
        default:
          return;
      }
    });
  };

const siteDataMap =
  ({ language, entity, entityType, columns }) =>
  (value) => {
    if (!value) return {};

    return columns.map(({ id, kpi, defaultValue }) => {
      switch (id) {
        case InitialColumns.DATE_RANGE:
          return defaultValue;
        case SitesColumns.REGION:
          if (entityType === EntityType.SITES) {
            return value?.regionName ?? '';
          }
          return entity?.name ?? '';
        case SitesColumns.SITE:
          return value[id];
        case SitesColumns.PLATFORM:
          return value[id] ? value[id] : '';
        default:
          return formatKpiValue(value[kpi]?.aggregateValue, language);
      }
    });
  };

export const useAssetsTableDownload = () => {
  const { t, i18n } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const presentationType = usePresentationType();

  const downloadCSV = useCallback(
    ({ data: assets, columns, entity, entityType, dateRange }) => {
      const primaryCols = getPrimaryColumnMap({ dateRange, t });

      const colMap = getAssetColumnMap(presentationType);
      const cols = columns.reduce(
        (acc, { id: groupId, cols }) => [
          ...acc,
          ...cols
            .filter(({ id, visible }) => visible && colMap[id])
            .map(({ id, ...rest }) => ({ id, kpi: colMap[id], groupId, ...rest })),
        ],
        primaryCols,
      );

      const fields = cellHeaderMapFn({ columnDefs: AssetsColumnDefs, columns: cols, t });
      const data = assets.map(
        assetDataMap({
          language: i18n.language,
          columns: cols,
          entity,
          entityType,
        }),
      );

      const filename = `${entity?.name + ' ' + t('assets', 'Assets')}-${dayjs().format(
        DateTimeFormats.FILE_DOWNLOAD_DATE_TIME,
      )}${FileExtensionType[FileType.CSV]}`;

      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [presentationType, t, i18n],
  );

  const downloadPNG = () => {};

  return {
    downloadPNG,
    downloadCSV,
  };
};

export const useAssetsTableDownloadStorage = () => {
  const { t, i18n } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const presentationType = usePresentationType();

  const downloadCSV = useCallback(
    ({ data: assets, columns, entity, entityType, dateRange, radioSelected }) => {
      const primaryCols = getPrimaryColumnMap({ dateRange, t });
      const colMap = getAssetColumnMapStorage(entityType);
      const cols = columns.reduce(
        (acc, { id: groupId, cols }) => [
          ...acc,
          ...cols
            .filter(({ id, visible }) => visible && colMap[id])
            .map(({ id, ...rest }) => ({ id, kpi: colMap[id], groupId, ...rest })),
        ],
        primaryCols,
      );
      const fields = cellHeaderMapFnStorage({
        columnDefs:
          entityType === EntityType.STORAGE_SITE
            ? AssetsColumnStorageDefs
            : entityType === EntityType.ANOMALY
            ? AnomalyColumnStorageDefs
            : ManualAdjustmentColumnStorageDefs,
        columns: cols,
        t,
      });
      const data = assets.map(
        assetDataMapStorage({
          columns: cols,
          entity,
          entityType,
        }),
      );
      const name =
        entityType === EntityType.STORAGE_SITE
          ? t('assets', 'Assets')
          : entityType === EntityType.ANOMALY
          ? t('anomalies', 'Anomalies')
          : t('manual adjustment', 'Manual Adjustment');
      const filename = `${entity?.name + ' ' + name}-${dayjs().format(
        DateTimeFormats.FILE_DOWNLOAD_DATE_TIME,
      )}${FileExtensionType[radioSelected === 'csv' ? FileType.CSV : FileType.XLS]}`;

      downloadFile({
        fields,
        data,
        filename,
        type: radioSelected === 'csv' ? ContentType.CSV : ContentType.XLS,
      });
    },
    [presentationType, t, i18n],
  );

  const downloadPNG = () => {};

  return {
    downloadPNG,
    downloadCSV,
  };
};

export const useSitesTableDownload = () => {
  const { t, i18n } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const presentationType = usePresentationType();

  const downloadCSV = useCallback(
    ({ data: sites, columns, entity, entityType, dateRange }) => {
      const primaryCols = getPrimaryColumnMap({ dateRange, t });

      const colMap = getSiteColumnMap(presentationType);
      const cols = columns.reduce(
        (acc, { id: groupId, cols }) => [
          ...acc,
          ...cols
            .filter(({ id, visible }) =>
              presentationType === PresentationTypes.GRID ? colMap[id] : visible && colMap[id],
            )
            .map(({ id, ...rest }) => ({ id, kpi: colMap[id], groupId, ...rest })),
        ],
        primaryCols,
      );

      const fields = cellHeaderMapFn({ columnDefs: AllSitesColumnDefs, columns: cols, t });
      const data = sites.map(
        siteDataMap({
          language: i18n.language,
          columns: cols,
          entity,
          entityType,
        }),
      );

      const entityName = entity?.name ?? '';
      const filename = `${
        entityType === EntityType.SITES
          ? t('all_sites', 'All Sites')
          : entityName + ' ' + t('sites', 'Sites')
      }-${dayjs().format(DateTimeFormats.FILE_DOWNLOAD_DATE_TIME)}${
        FileExtensionType[FileType.CSV]
      }`;

      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [presentationType, t, i18n],
  );

  const downloadPNG = () => {};

  return {
    downloadPNG,
    downloadCSV,
  };
};
