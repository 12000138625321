import { TableFilterTypes } from '@ge/models/constants';

export const AnomalyColumnsStorage = {
  // Assets
  GROUP_ASSETS: 'group-assets',
  ASSET: 'asset',

  // Component
  GROUP_COMPONENT: 'group-component',
  COMPONENT: 'component',

  // Title
  GROUP_TITLE: 'group-title',
  TITLE: 'title',

  // Priority
  GROUP_PRIORITY: 'group-priority ',
  PRIORITY: 'priority',

  // Trouble Shooting
  GROUP_TROUBLE_SHOOTING: 'group-trouble-shooting',
  TROUBLE_SHOOTING: 'trouble-shooting',

  // Last Flagged
  GROUP_LAST_FLAGGED: 'group-last-flagged',
  LAST_FLAGGED: 'last_flagged',

  GROUP_CASE_DETAIL: 'group-case-detail',
  CASE_DETAIL: 'case-detail',
};

// Define all available columns in the assets table.
export const AnomalyColumnStorageDefs = {
  [AnomalyColumnsStorage.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.assets',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [AnomalyColumnsStorage.ASSET]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        locked: true,
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: 'asset',
          },
        ],
      },
    },
  },
  [AnomalyColumnsStorage.GROUP_COMPONENT]: {
    cols: {
      [AnomalyColumnsStorage.COMPONENT]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.component',
            a11yDefault: 'Component',
            sortValue: 'component',
          },
        ],
      },
    },
  },
  [AnomalyColumnsStorage.GROUP_TITLE]: {
    cols: {
      [AnomalyColumnsStorage.TITLE]: {
        align: 'left',
        minWidth: '240px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.title',
            a11yDefault: 'Title',
            sortValue: 'title',
          },
        ],
      },
    },
  },
  [AnomalyColumnsStorage.GROUP_PRIORITY]: {
    cols: {
      [AnomalyColumnsStorage.PRIORITY]: {
        align: 'center',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.priority',
            a11yDefault: 'Priority',
            sortValue: 'priority',
          },
        ],
      },
    },
  },
  [AnomalyColumnsStorage.GROUP_TROUBLE_SHOOTING]: {
    cols: {
      [AnomalyColumnsStorage.TROUBLE_SHOOTING]: {
        align: 'center',
        minWidth: '140px',
        labels: [
          {
            a11yKey: 'table.Trouble Shooting',
            a11yDefault: 'Trouble shooting',
            sortValue: 'trouble_shooting',
          },
        ],
      },
    },
  },
  [AnomalyColumnsStorage.GROUP_LAST_FLAGGED]: {
    cols: {
      [AnomalyColumnsStorage.LAST_FLAGGED]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.DATE,
        labels: [
          {
            a11yKey: 'table.last_flagged',
            a11yDefault: 'Last flagged',
            sortValue: 'last_flagged',
          },
        ],
      },
    },
  },
  [AnomalyColumnsStorage.GROUP_CASE_DETAIL]: {
    fixedRight: true,
    align: 'left',
    cols: {
      [AnomalyColumnsStorage.CASE_DETAIL]: {
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        locked: true,
        labels: [
          {
            a11yKey: 'table.case_detail',
            a11yDefault: 'Case\nDetails',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '43px',
        },
      },
    },
  },
};
