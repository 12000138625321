import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { ReportModules } from '@ge/feat-reporting/components/sidebar/modules/module';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { CreateModes } from '@ge/feat-reporting/models/modes';
import { AttributeGroups, Capability, PermissionScope, QueryKey } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';

import { useSaveTemplateConfig } from '../../../data-hooks/use-save-template';
import { WidgetNames, WidgetTypes } from '../../../models/widgets';
import { SendReportModal } from '../../send-report-modal/send-report-modal';
import { assetsOfConcernOmittedCols } from '../../widgets/assets-of-concern-widget/assets-of-concern-cols';
import { AvailabilityStateKeys } from '../../widgets/availability-widget/availability-widget';
import { completedWorkOmittedCols } from '../../widgets/completed-work-widget/completed-work-cols';
import { contractualAvailabilityOmittedCols } from '../../widgets/contractual-availability-widget/contractual-availability-cols';
import { DonutWidgetStateKeys } from '../../widgets/donut-widget';
import { manuallyAdjustedEventsOmittedCols } from '../../widgets/manually-adjusted-events-widget/manually-adjusted-events-cols';
import { NotesWidgetStateKeys } from '../../widgets/notes-widget';
import { plannedWorkOmittedCols } from '../../widgets/planned-work-widget/planned-work-cols';
import { ProductionGraphStateKeys } from '../../widgets/production-graph-widget/production-graph-widget';
import { productionTableOmittedCols } from '../../widgets/production-table-widget/production-table-cols';
import { siteRosterOmittedCols } from '../../widgets/site-roster-widget/site-roster-cols';
import { TableWidgetStateKeys } from '../../widgets/table-widget/table-widget';
import { ActionButtons } from '../action-buttons/actions-buttons';

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 14px 14px 34px;
`;

const SidebarHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TemplateTitle = styled.p`
  font-size: 11px;
  color: ${({ theme }) => theme.createReport.sidebar.titleColor};
  margin: 0 0 15px;
`;

const TitleLabel = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  margin-right: 2px;
`;

const Label = styled.h4`
  margin-bottom: 2px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.sidebar.readOnlyLabelColor};
  font-family: 'Museo Sans';
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
`;

const ErrorLabel = styled.label`
  color: ${({ theme }) => theme.createReport.sidebar.errorLabelColor};
  display: block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: 15px;
  text-align: center;
`;

const Scope = styled.p`
  font-family: 'Museo Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const CollapseIconWrapper = styled.div`
  cursor: pointer;
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  border-bottom: none;

  &.expanded {
    border-bottom: 2px solid ${({ theme }) => theme.createReport.headerBorderColor};
  }

  .title {
    border-bottom: 2px solid ${({ theme }) => theme.createReport.headerBorderColor};
    padding: 0 14px 14px;
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.createReport.headerTextColor};

    svg {
      fill: ${(props) => props.theme.navigation.chevronColor};
    }
  }
`;

const EditPencil = styled.div`
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 26px;
`;

const ReadParameters = styled.div`
  padding: 14px 31px;
`;

const ParameterWrapper = styled.div`
  margin-bottom: 10px;
`;

const ParameterValue = styled.div`
  color: ${({ theme }) => theme.createReport.sidebar.parameterValueColor};
  font-family: 'Museo Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  word-wrap: break-word;
`;

const ScrollArea = styled.div`
  height: 100%;

  & > div {
    min-height: calc(100% - 58px);
  }
`;

const CollapseIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  icon: Icons.COLLAPSE,
  color: theme.createReport.sidebar.collapseIconColor,
  viewbox: '0 0 12 12',
}))`
  display: inline-block;
  position: relative;
  transform: rotate(180deg);
`;

const PencilIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  icon: Icons.PENCIL,
  color: theme.createReport.sidebar.collapseIconColor,
  viewbox: '0 0 20 20',
}))`
  display: inline-block;
  position: relative;
`;

const SiteIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  icon: Icons.SITE,
  color: theme.createReport.sidebar.siteIconColor,
  viewbox: '0 0 20 20',
}))`
  display: inline-block;
  position: relative;
  margin-right: 6px;
`;

const GroupIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  icon: Icons.SERVICE_GROUP,
  color: theme.createReport.sidebar.siteIconColor,
  viewbox: '0 0 20 20',
}))`
  display: inline-block;
  position: relative;
  margin-right: 6px;
`;

// Map table widgets by name to omitted column definitions
const getTableWidgetOmittedColumns = (widgetName) => {
  const widgetColMap = {
    [WidgetNames.PLANNED_WORK]: plannedWorkOmittedCols,
    [WidgetNames.COMPLETED_WORK]: completedWorkOmittedCols,
    [WidgetNames.SITE_ROSTER]: siteRosterOmittedCols,
    [WidgetNames.ASSETS_OF_CONCERN]: assetsOfConcernOmittedCols,
    [WidgetNames.MANUALLY_ADJUSTED_EVENTS]: manuallyAdjustedEventsOmittedCols,
    [WidgetNames.CONTRACTUAL_AVAILABILITY]: contractualAvailabilityOmittedCols,
    [WidgetNames.PRODUCTION_TABLE]: productionTableOmittedCols,
  };
  return widgetColMap[widgetName];
};

const NAMESPACE = 'reporting.sidebar';

export const ViewParameters = ({ onClose, generatedPdfBase64 }) => {
  const logger = useLogger();
  const queryClient = useQueryClient();
  const { t } = useTranslation([NAMESPACE], {
    useSuspense: false,
  });

  const {
    createMode,
    setCreateMode,
    hasReportBeenSent,
    setHasReportBeenSent,
    hiddenWidgets,
    reportState,
    initalReportState,
    loadingWidgets,
    attachments,
    getInitialWidgetState,
  } = useContext(ReportsContext);
  const [isSendReportModalOpen, setIsSendReportModalOpen] = useState(false);
  const [sendReportDistributionList, setSendReportDistributionList] = useState([]);
  const [isSavingTemplateConfig, setIsSavingTemplateConfig] = useState(false);
  const [saveTemplateConfigError, setSaveTemplateConfigError] = useState(null);

  const { name: templateName } = initalReportState ?? {};
  const {
    name: reportName,
    description,
    scopeName,
    scopeType,
    id: templateId,
    templateInfo,
    distributionList,
  } = reportState ?? {};

  const { endDate, startDate } = useReportScope();

  const handleEnableEdit = useCallback(
    (e) => {
      killEventPropagation(e);
      setCreateMode(CreateModes.EDIT);
    },
    [setCreateMode],
  );

  const handleCollapse = useCallback(() => {
    onClose();
  }, [onClose]);

  const handlePreviewPdf = useCallback(() => {
    setCreateMode(CreateModes.PREVIEW);
  }, [setCreateMode]);

  const handlePreviewCancel = useCallback(() => {
    setCreateMode(CreateModes.EDIT);
  }, [setCreateMode]);

  const handleOpenSendReportModal = useCallback(() => {
    if (distributionList?.length > 0) {
      setSendReportDistributionList([...distributionList]);
    }
    setIsSendReportModalOpen(true);
  }, [distributionList, setSendReportDistributionList, setIsSendReportModalOpen]);

  const handleSendReportCancel = useCallback(() => {
    setCreateMode(CreateModes.VIEW);
  }, [setCreateMode]);

  const handleUpdateDistributionList = useCallback(
    (updatedList) => {
      setSendReportDistributionList(updatedList);
    },
    [setSendReportDistributionList],
  );

  const { mutate: saveTemplateConfig } = useSaveTemplateConfig({
    onSuccess: () => {
      setIsSavingTemplateConfig(false);
      setSaveTemplateConfigError(null);
      queryClient.invalidateQueries([QueryKey.REPORT_TEMPLATE, templateId]);
    },
    onError: (error) => {
      logger.error('Save template config error: ', error);
      setIsSavingTemplateConfig(false);
      setSaveTemplateConfigError(error ?? 'Unspecified error');
    },
  });

  const { isAuthorized } = useAuth();
  const canEditCurrentTemplate =
    reportState?.baseTemplate === false &&
    isAuthorized({
      capabilities: [
        { capability: Capability.REPORTING_TEMPLATES, scopes: [PermissionScope.EDIT] },
      ],
    });
  const authorizedToEditCreateTemplate = isAuthorized({
    capabilities: [
      {
        capability: Capability.REPORTING_TEMPLATES,
        scopes: [PermissionScope.CREATE, PermissionScope.EDIT],
      },
    ],
  });

  const saveTemplateEnabled = useMemo(() => {
    if (isSavingTemplateConfig) return false;
    if (!(reportState && initalReportState && canEditCurrentTemplate)) {
      return false;
    }

    // NB: `widgetState` objects must be carefully maintained for comparison purposes.
    // For example, sorting operations on these objects must use a stable algorithm,
    // as an unstable sort would result in intermittent, difficult-to-debug defects.
    // Similarly, if you need to track a set of items (i.e., with items being added and
    // removed regularly), use an actual Set, or ensure that re-added items are replaced
    // in their original position relative to the other items. Otherwise, sets that are
    // actually equivalent will not compare cleanly due to out-of-order keys.
    const widgetStateChanged = !equals(reportState.widgetState, initalReportState.widgetState);
    const widgetVisibilityChanged = !equals(
      hiddenWidgets,
      new Set([...(initalReportState.hiddenWidgetIds ?? [])]),
    );
    return widgetStateChanged || widgetVisibilityChanged;
  }, [
    isSavingTemplateConfig,
    hiddenWidgets,
    reportState,
    initalReportState,
    canEditCurrentTemplate,
  ]);

  const handleSaveTemplate = useCallback(() => {
    if (!reportState?.widgetState) return;

    const widgetConfigItems = [];

    const widgetTemplates = Object.values(reportState.templateInfo).flatMap((t) => t);

    for (const [_widgetId, widgetState] of Object.entries(reportState.widgetState)) {
      if (!widgetState) continue;

      let widgetId = _widgetId;

      // NB: assumes the Attachment widget may only exist once in any given template
      if (_widgetId === WidgetNames.ATTACHMENTS) {
        widgetId = widgetTemplates.find((wt) => wt.name === WidgetNames.ATTACHMENTS)?.id;
        if (!widgetId) {
          continue;
        }
      }

      // Inspect column configuration per widget for changes
      const {
        configuration: templateConfiguration,
        visualizationType,
        name: widgetName,
      } = widgetTemplates.find((wt) => wt.id === widgetId) ?? {};
      if (!templateConfiguration) {
        continue;
      }

      const initialWidgetState = getInitialWidgetState(_widgetId);
      if (!initialWidgetState) {
        continue;
      }

      if (equals(widgetState, initialWidgetState)) {
        continue;
      }

      let newTemplateConfiguration = null;
      // Helper function to pull config items, which may exist across collections of various names, depending on
      // widget type, and organize them into the `newTemplateConfiguration` object for persisting to the template.
      const extractConfigItems = (id, items) => {
        if (items?.length > 0) {
          const newConfigItem = {};
          for (const configItem of items) {
            if (!configItem?.id) {
              continue;
            }
            newConfigItem[configItem.id] = configItem.visible;
          }
          if (Object.keys(newConfigItem).length > 0) {
            newTemplateConfiguration = newTemplateConfiguration ?? {};
            newTemplateConfiguration[id] = newConfigItem;
          }
        }
      };

      switch (visualizationType) {
        case WidgetTypes.LINE:
        case WidgetTypes.LIST:
          break;
        case WidgetTypes.DONUT:
          newTemplateConfiguration = widgetState[DonutWidgetStateKeys.CHECKED_STATUSES];
          break;
        case WidgetTypes.TEXT:
          newTemplateConfiguration = widgetState[NotesWidgetStateKeys.VALUES];
          break;
        case WidgetTypes.TABLE: {
          const omittedCols = getTableWidgetOmittedColumns(widgetName) ?? [];
          const columnConfigState = widgetState[TableWidgetStateKeys.COLUMN_CONFIG_STATE];
          if (columnConfigState) {
            for (const colConfigItem of columnConfigState) {
              if (!colConfigItem || !colConfigItem.id || omittedCols.includes(colConfigItem.id)) {
                continue;
              }
              extractConfigItems(colConfigItem.id, colConfigItem.cols);
            }
          }
          break;
        }
        default:
      }

      if (newTemplateConfiguration === null) {
        switch (widgetName) {
          case WidgetNames.AVAILABILITY: {
            const configState = widgetState[AvailabilityStateKeys.CONFIG_STATE];
            if (configState) {
              for (const configItem of configState) {
                if (!configItem || !configItem.id) {
                  continue;
                }
                extractConfigItems(configItem.id, configItem.fields);
              }
            }
            break;
          }
          case WidgetNames.PRODUCTION_GRAPH: {
            const configState = widgetState[ProductionGraphStateKeys.CONFIG_STATE];
            if (configState) {
              for (const configItem of configState) {
                if (!configItem || !configItem.id) {
                  continue;
                }
                extractConfigItems(configItem.id, configItem.fields);
              }
            }
            break;
          }
          default:
        }
      }

      if (
        Object.keys(newTemplateConfiguration ?? {}).length > 0 &&
        !equals(newTemplateConfiguration, templateConfiguration)
      ) {
        widgetConfigItems.push({
          id: widgetId,
          configuration: newTemplateConfiguration,
        });
      }
    }

    const requestParams = { templateId };

    if (!equals(hiddenWidgets, new Set([...(initalReportState.hiddenWidgetIds ?? [])]))) {
      requestParams.hiddenWidgetIds = [...hiddenWidgets];
    }

    if (widgetConfigItems.length > 0) {
      requestParams.widgetConfigurations = widgetConfigItems;
    }

    setIsSavingTemplateConfig(true);
    saveTemplateConfig(requestParams);
  }, [
    templateId,
    hiddenWidgets,
    reportState?.templateInfo,
    reportState?.widgetState,
    initalReportState?.hiddenWidgetIds,
    getInitialWidgetState,
    saveTemplateConfig,
  ]);

  return (
    <>
      <SidebarHeader>
        <SidebarHeaderRow>
          <TemplateTitle>
            <TitleLabel>{t('template', 'Template')}:</TitleLabel>
            {templateName}
          </TemplateTitle>
          <CollapseIconWrapper onClick={handleCollapse}>
            <CollapseIcon />
          </CollapseIconWrapper>
        </SidebarHeaderRow>
      </SidebarHeader>
      <ScrollArea>
        <ScrollingContainer>
          <StyledCollapsiblePanel
            headerContent={
              <SidebarHeaderRow>
                <h2>{t('parameters', 'Parameters')}</h2>
                <EditPencil onClick={handleEnableEdit}>
                  <PencilIcon />
                </EditPencil>
              </SidebarHeaderRow>
            }
            expanded
          >
            <ReadParameters>
              <ParameterWrapper>
                <Label>{t('name', 'Name')}</Label>
                <ParameterValue>{reportName}</ParameterValue>
              </ParameterWrapper>
              <ParameterWrapper>
                <Label>{t('description', 'Description')}</Label>
                <ParameterValue>{description}</ParameterValue>
              </ParameterWrapper>
              <ParameterWrapper>
                <Label>{t('time_range', 'Time Range')}</Label>
                <ParameterValue>{`${startDate} - ${endDate}`}</ParameterValue>
              </ParameterWrapper>
              <ParameterWrapper>
                <Label>{t('scope', 'Scope')}</Label>
                <ParameterValue>
                  {scopeName && (
                    <Scope>
                      {scopeType === AttributeGroups.SITES && <SiteIcon />}
                      {scopeType === AttributeGroups.SERVICE_GROUP && <GroupIcon />}
                      {scopeName}
                    </Scope>
                  )}
                </ParameterValue>
              </ParameterWrapper>
              <ParameterWrapper>
                <Label>{t('recipients', 'Recipients')}</Label>
                <ParameterValue>
                  {distributionList?.length > 0 ? (
                    <>
                      {distributionList[0]}
                      {distributionList.length > 1 && (
                        <>
                          &nbsp;&amp; {distributionList.length - 1} {t('more', 'more')}
                        </>
                      )}
                    </>
                  ) : (
                    <>{t('na', 'N/A')}</>
                  )}
                </ParameterValue>
              </ParameterWrapper>
            </ReadParameters>
          </StyledCollapsiblePanel>
          <ReportModules data={templateInfo} />
          <>
            {saveTemplateConfigError && (
              <ErrorLabel>
                {t(
                  'save_template_error',
                  'An error occurred and your changes were not saved. Please try again or contact support.',
                )}
              </ErrorLabel>
            )}
            {createMode === CreateModes.PREVIEW ? (
              <ActionButtons
                primaryEnabled={
                  generatedPdfBase64 && !hasReportBeenSent && authorizedToEditCreateTemplate
                }
                primaryLabel={t('send_report', 'Send Report')}
                onPrimaryClick={handleOpenSendReportModal}
                onSecondaryClick={handleSendReportCancel}
                showAlternate={canEditCurrentTemplate}
                alternateLabel={t('save_to_template', 'Save To Template')}
                alternateEnabled={saveTemplateEnabled}
                onAlternateClick={handleSaveTemplate}
              />
            ) : (
              <ActionButtons
                primaryEnabled={loadingWidgets.size === 0}
                primaryLabel={t('preview_pdf', 'Preview PDF')}
                onPrimaryClick={handlePreviewPdf}
                onSecondaryClick={handlePreviewCancel}
                showAlternate={canEditCurrentTemplate}
                alternateLabel={t('save_to_template', 'Save To Template')}
                alternateEnabled={saveTemplateEnabled}
                onAlternateClick={handleSaveTemplate}
              />
            )}
          </>
        </ScrollingContainer>
      </ScrollArea>
      <SendReportModal
        isOpen={isSendReportModalOpen}
        onModalClose={(sent = false) => {
          setIsSendReportModalOpen(false);
          setHasReportBeenSent(sent);
        }}
        distributionList={sendReportDistributionList}
        onDistributionListChanged={handleUpdateDistributionList}
        generatedPdfBase64={generatedPdfBase64}
        attachments={attachments}
      />
    </>
  );
};

ViewParameters.propTypes = {
  onClose: PropTypes.func,
  generatedPdfBase64: PropTypes.string,
};

ViewParameters.defaultProps = {
  onClose: () => {},
};
