import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';

import { byId } from '@ge/util/array-utils';

/**
 * Return a subset of an array that includes only sites that are present
 * in the provided view object.
 *
 * @param {Array} entities Array of entity objects to filter
 * @param {*} view A view to use for filtering sites
 */
export const filterByView = (entities, view) => {
  // Create an array of site IDs from the view
  const viewIds = pluck('id', pathOr([], ['sites'], view));

  // If there are no sites in the view selector, create pass through for all entities
  if (!viewIds.length) {
    return entities;
  }

  // Otherwise filter entities for given sites
  return entities.filter((entity) => {
    const siteId = pathOr(entity?.id, ['site', 'id'], entity);
    return viewIds.includes(siteId);
  });
};

/**
 * Return a filtered array of entities from the provided entity map object.
 *
 * @param entity Entity by id object to filter
 * @param view Current view
 * @returns {*}
 */
export const getViewEntity = (entity, view) => {
  const { currentView } = view;
  const arr = Object.values(entity);
  // Apply view filter if one is provided.
  if (currentView) {
    return filterByView(arr, currentView);
  }
  return arr;
};

// TODO (astone): MOVE THESE INTO APP

export const getViewSiteCount = (selectedSites, allSites) => {
  // no selection provided
  if (!selectedSites?.length) {
    return 0;
  }

  const viewById = byId(selectedSites);

  return (allSites ?? []).filter(({ id }) => viewById[id]).length;
};

// END TODO (astone)
