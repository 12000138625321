import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { RadioPanel } from '@ge/components/radio-panel';
import { DashboardGaugeDefs, GaugeCategoryType } from '@ge/feat-analyze/models';
import { Capability, EntityType, PermissionScope } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';

// constants
const DEFAULT_CAPABILITIES = [
  { capability: Capability.ADVANCED_KPIS, scopes: [PermissionScope.VIEW] },
];
const ROOT_NAMESPACE = 'analyze.dashboard';
const NAMESPACE = 'gauge_config';

// gets items with unique ids and default checked state for lists shared between panels
const getDefaultPanelItems = ({ defaultValue1, defaultValue2, items, t }) =>
  items.reduce(
    (panelItems, { childItems, label: _label, ...item }) => {
      const label = t(..._label);

      if (childItems?.length) {
        const childPanelItems = getDefaultPanelItems({
          defaultValue1,
          defaultValue2,
          items: childItems,
          t,
        });

        panelItems.forEach((_items, i) =>
          _items.push({
            ...item,
            childItems: childPanelItems[i],
            label,
          }),
        );
      } else {
        const { id } = item;

        const [panelItems1, panelItems2] = panelItems;
        const isItem1Checked = item.value === defaultValue1;
        const isItem2Checked = item.value === defaultValue2;

        panelItems1.push({
          ...item,
          checked: isItem1Checked,
          disabled: isItem2Checked,
          id: `item1_${id}`,
          label,
        });

        panelItems2.push({
          ...item,
          checked: isItem2Checked,
          disabled: isItem1Checked,
          id: `item2_${id}`,
          label,
        });
      }

      return panelItems;
    },
    [[], []],
  );

// shared state update logic between panels
const updatePanelItems = ({ index, items, value }) => {
  const [activeUpdate, reactiveUpdate] = index ? [...items].reverse() : items;

  // update current panel
  activeUpdate.forEach((item) => {
    item.checked = item.value === value;

    if (item.childItems?.length) {
      item.childItems.forEach((childItem) => {
        childItem.checked = childItem.value === value;
      });
    }
  });

  // update other panel to make selection unavailable
  reactiveUpdate.forEach((item) => {
    item.disabled = item.value === value;

    if (item.childItems?.length) {
      item.childItems.forEach((childItem) => {
        childItem.disabled = childItem.value === value;
      });
    }
  });
};

const SettingsButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 0;
`;

const SettingsIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.analyze.dashboard.settingsButtonIconColor,
  icon: Icons.SETTINGS,
  size: 14,
}))``;

const Title = styled.h2`
  margin-bottom: 24px;
`;

const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  min-width: 500px;
  width: 35vw;

  .col-1 {
    margin-right: 12px;
  }

  .col-2 {
    margin-left: 12px;
  }

  > * {
    align-items: center;
    display: flex;
    flex: 1 0;
    flex-flow: column nowrap;
    justify-content: center;

    .panel {
      align-items: start;
      flex-flow: column nowrap;
      justify-content: flex-start;
      width: 100%;

      h4 {
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      .kpi-panel {
        height: 150px;
      }

      .radio-panel {
        border: 1px solid ${({ theme }) => theme.analyze.gaugeConfiguration.panelBorderColor};
      }

      + .panel {
        margin-top: 24px;
      }
    }
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap
  justify-content: flex-start;
`;

const DialogFooter = ({ applyLabel, cancelLabel, onApply, onCancel }) => (
  <FooterContainer>
    <FooterButtons>
      <Button onClick={onCancel}>{cancelLabel}</Button>
      <Button onClick={onApply} primary type="submit">
        {applyLabel}
      </Button>
    </FooterButtons>
  </FooterContainer>
);

DialogFooter.propTypes = {
  applyLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// TODO: look into whether parent should own auth completely, or if we want to bake it into the component like this
export const DashboardGaugeConfiguration = ({ capabilities, entityType, onApply, values }) => {
  // hooks
  const { t } = useTranslation([ROOT_NAMESPACE], { useSuspense: false });

  // state
  const [isOpen, setIsOpen] = useState(false);
  const [dialGaugeItems, setDialGaugeItems] = useState([]);
  const [kpiItems, setKpiItems] = useState([]);
  const [dialGauge1, setDialGauge1] = useState();
  const [dialGauge2, setDialGauge2] = useState();
  const [kpi1, setKpi1] = useState();
  const [kpi2, setKpi2] = useState();

  const stateByType = {
    [GaugeCategoryType.DIAL_GAUGE]: {
      items: dialGaugeItems,
      setItems: setDialGaugeItems,
      setValue1: setDialGauge1,
      setValue2: setDialGauge2,
    },
    [GaugeCategoryType.KPI]: {
      items: kpiItems,
      setItems: setKpiItems,
      setValue1: setKpi1,
      setValue2: setKpi2,
    },
  };

  // effects
  useEffect(() => {
    const defs = DashboardGaugeDefs[entityType];
    const {
      defaultValues: _defaultValues,
      [GaugeCategoryType.DIAL_GAUGE]: defaultDialGaugeItems,
      [GaugeCategoryType.KPI]: defaultKpiItems,
    } = defs;
    const defaultValues = {
      dialGauge1: values?.dialGauge1 ?? _defaultValues?.dialGauge1,
      dialGauge2: values?.dialGauge2 ?? _defaultValues?.dialGauge2,
      kpi1: values?.kpi1 ?? _defaultValues?.kpi1,
      kpi2: values?.kpi2 ?? _defaultValues?.kpi2,
    };

    setDialGaugeItems(
      getDefaultPanelItems({
        defaultValue1: defaultValues.dialGauge1,
        defaultValue2: defaultValues.dialGauge2,
        items: defaultDialGaugeItems,
        t,
      }),
    );
    setKpiItems(
      getDefaultPanelItems({
        defaultValue1: defaultValues.kpi1,
        defaultValue2: defaultValues.kpi2,
        items: defaultKpiItems,
        t,
      }),
    );
    setDialGauge1(defaultValues.dialGauge1);
    setDialGauge2(defaultValues.dialGauge2);
    setKpi1(defaultValues.kpi1);
    setKpi2(defaultValues.kpi2);
  }, [entityType, t, values]);

  // handlers
  const handleApply = (e) => {
    e.preventDefault();

    setIsOpen(false);

    onApply({
      dialGauge1,
      dialGauge2,
      kpi1,
      kpi2,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();

    setIsOpen(false);
  };

  const handleChange = ({ index, type, value }) => {
    const { items, setItems, setValue1, setValue2 } = stateByType[type];

    updatePanelItems({ index, items, value });

    setItems(items);

    // panel 2 change
    if (index) {
      // active update
      setValue2(value);

      // panel 1 change
    } else {
      // active update
      setValue1(value);
    }
  };

  const handleOpen = (e) => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <AuthRender
        capabilities={capabilities}
        description="Configure dashboard gauges"
        siteLevel={false}
      >
        <SettingsButton onClick={handleOpen}>
          <SettingsIcon />
        </SettingsButton>
        <Dialog
          contentWidth
          footer={
            <DialogFooter
              applyLabel={t(`${NAMESPACE}.apply_label`, 'Apply')}
              cancelLabel={t(`${NAMESPACE}.cancel_label`, 'Cancel')}
              onApply={handleApply}
              onCancel={handleCancel}
            />
          }
          header={t(`${NAMESPACE}.title`, 'Settings')}
          isOpen={isOpen}
          onClose={handleCancel}
        >
          <Title>{t(`${NAMESPACE}.${entityType}_title`, 'Site Card Configuration')}</Title>
          <Content>
            <div className="col-1">
              <div className="panel">
                <h4>{t(`${NAMESPACE}.dial_gauge_1_title`, 'Dial Gauge 1')}</h4>
                <RadioPanel
                  className="radio-panel"
                  expanded
                  items={dialGaugeItems[0]}
                  onChange={(value) =>
                    handleChange({ index: 0, type: GaugeCategoryType.DIAL_GAUGE, value })
                  }
                />
              </div>
              <div className="panel">
                <h4>{t(`${NAMESPACE}.kpi_1_title`, 'KPI 1')}</h4>
                <RadioPanel
                  className="radio-panel kpi-panel"
                  expanded
                  items={kpiItems[0]}
                  onChange={(value) =>
                    handleChange({ index: 0, type: GaugeCategoryType.KPI, value })
                  }
                />
              </div>
            </div>
            <div className="col-2">
              <div className="panel">
                <h4>{t(`${NAMESPACE}.dial_gauge_2_title`, 'Dial Gauge 2')}</h4>
                <RadioPanel
                  className="radio-panel"
                  expanded
                  items={dialGaugeItems[1]}
                  onChange={(value) =>
                    handleChange({ index: 1, type: GaugeCategoryType.DIAL_GAUGE, value })
                  }
                />
              </div>
              <div className="panel">
                <h4>{t(`${NAMESPACE}.kpi_2_title`, 'KPI 2')}</h4>
                <RadioPanel
                  className="radio-panel kpi-panel"
                  expanded
                  items={kpiItems[1]}
                  onChange={(value) =>
                    handleChange({ index: 1, type: GaugeCategoryType.KPI, value })
                  }
                />
              </div>
            </div>
          </Content>
        </Dialog>
      </AuthRender>
    </>
  );
};

DashboardGaugeConfiguration.propTypes = {
  capabilities: PropTypes.arrayOf(PropTypes.object),
  entityType: PropTypes.oneOf(Object.values(EntityType)).isRequired,
  onApply: PropTypes.func,
  values: PropTypes.object,
};

DashboardGaugeConfiguration.defaultProps = {
  // do we have a default here or defer to parent entirely?
  capabilities: DEFAULT_CAPABILITIES,
  onApply: () => {},
  values: undefined,
};
