import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';

import HandlingProcedureTable from '../tables/handling-procedure-table';

import { useMode, HLP_ACTIONS } from './hp-common';
import HPSidepanel from './hp-sidepanel';

const HandlingProcedure = () => {
  const { ready } = useTranslation(['admin.configure']);
  const history = useHistory();
  const { action } = useMode();
  const [selectedRow, setSelectedRow] = useState([]);

  useEffect(() => {
    if (!action && selectedRow.length) {
      setSelectedRow([]);
    }
  }, [action]);

  const openDLSidePanel = (type) => {
    history.push({
      pathname: location.pathname,
      search: `?mode=${type || HLP_ACTIONS.NEW}`,
    });
  };

  return (
    <PageContainer i18nReady={ready}>
      {action ? <HPSidepanel /> : null}
      <HandlingProcedureTable
        openDLSidePanel={openDLSidePanel}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </PageContainer>
  );
};

export default HandlingProcedure;
