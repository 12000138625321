import clone from 'ramda/src/clone';
import { useMemo, useCallback, useEffect, useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAllCrews } from '@ge/feat-manage/data-hooks/use-all-crews';
import { useWorkers } from '@ge/feat-manage/data-hooks/use-workers';
import { ErpType, ErpDynamicFieldType, ErpFieldType } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { getUserName } from '@ge/shared/services/auth';
import { isEmpty } from '@ge/util/object-utils';

const getOptions = ({ key, values }) =>
  values
    ? Object.entries(values).map(([value, label], index) => {
        const option = {
          label,
          value,
        };

        if (key) {
          option.id = `${key}-${index}`;
        }

        return option;
      })
    : null;

const getFieldOptions = ({ key, metadata, parentValues }) => {
  const { values, valuesBy, valuesByValues } = metadata ?? {};

  if (!(values || parentValues[valuesBy])) {
    return null;
  }

  if (values) {
    return getOptions({ key, values });
  }

  const parentValue = parentValues[valuesBy];
  const { values: childValues } =
    valuesByValues.find(({ parentValues: parentValuesMatch }) =>
      parentValuesMatch.includes(parentValue),
    ) ?? {};

  return getOptions({ key, values: childValues });
};

const getSiteAssetName = (bulkTask) => {
  const tagEntries = bulkTask?.map((task) => {
    if (task?.asset?.name) {
      return `${task?.site?.name} | ${task?.asset?.name}`;
    } else {
      return task?.site?.name;
    }
  });
  return [...new Set(tagEntries)];
};

const getTextFieldDefaultValue = (metadataKey, defaultSelection, task, bulkTask) => {
  switch (metadataKey) {
    case 'owner':
      return getUserName(true);
    case 'assetSerialNumber':
      return bulkTask?.length >= 1
        ? getSiteAssetName(bulkTask).toString()
        : task?.taskLevel === 'site'
        ? `${task?.site?.name}`
        : `${task?.site?.name} | ${task?.asset?.name}`;
    case 'shortDesc':
      return task?.asset?.name + ' ' + task?.title;
    default:
      return defaultSelection ?? '';
  }
};

const getErpDataValue = (metadataKey, erpData) => {
  if (!erpData) return null;

  let _metadataKey = metadataKey;

  // Account for any data fields which are submitted under one name, but returned from the server as another name.
  switch (metadataKey) {
    case 'assetSerialNumber':
      _metadataKey = 'tag';
      break;
    default:
  }

  return erpData[_metadataKey];
};

const isDependentField = (fieldMetadata) => {
  return !!fieldMetadata?.showBy;
};

const isDependentFieldEnabled = (fieldMetadata, parentValues) => {
  const { showBy, showByValues } = fieldMetadata ?? {};

  // Show the dependent dropdown if the parent dropdown currently has one of the configured trigger values selected
  return showByValues?.includes(parentValues[showBy]);
};

// could look into making this a little DRYer with similar logic between showBy and requiredBy
const isDependentFieldRequired = (fieldMetadata, parentValues) => {
  const { requiredBy, requiredByValues } = fieldMetadata ?? {};

  return requiredByValues?.includes(parentValues[requiredBy]);
};

const getFieldDefaultValue = ({ defaultSelection, type } = {}, options, task = {}, key = '') => {
  // for fields with options like dropdowns we make sure the default selection exists otherwise set to empty value to avoid passing back placeholders like 'Please Select'
  // testing by field type instead of presence of options for fields with dependent options that might not exist for certain parent values
  if ([ErpFieldType.DROPDOWN, ErpFieldType.RADIO, ErpFieldType.CHECKBOX].includes(type)) {
    const defaultExists = options?.some(({ value }) => value === defaultSelection);

    return defaultExists ? defaultSelection : '';
  } else {
    if (key === 'longDesc') return task?.title + '  ' + task?.description;
    return defaultSelection ?? '';
  }
};

const getDropdownDefaults = (dropdownMetadata, options) => {
  const { defaultSelection } = dropdownMetadata ?? {};

  const placeholder = defaultSelection;
  const defaultValue = getFieldDefaultValue(dropdownMetadata, options);

  return { placeholder, defaultValue };
};

export const replaceXXX = (task, options) => {
  const taskAssetId = task?.asset?.id;
  const tunbineNumber = taskAssetId ? taskAssetId.split('-') : '';
  const output = Array.isArray(options)
    ? options.map((opt) => {
        if (opt?.label) {
          opt.label = opt?.label?.replace(/TUR_XXX/g, tunbineNumber[1]);
          opt.value = opt?.value?.replace(/TUR_XXX/g, tunbineNumber[1]);
          return opt;
        } else return opt.replace(/TUR_XXX/g, tunbineNumber[1]);
      })
    : options?.replace(/TUR_XXX/g, tunbineNumber[1]);
  return output;
};

/**
 * Transforms a template section into rows and columns to display on a dynamic form.
 * Manages showing/hiding fields dynamically based on rules defined in the template.
 * @param {Object} options - The options the hook expects
 * @param {Object} [options.erpData] - Data for an existing ERP request, if it exists
 * @param {string} options.erpType - The type of ERP request (for example, Service Request or Work Order)
 * @param {Object} options.section - The section object from the template
 * @param {Task} options.task - The task associated with the ERP request
 * @returns An array of rows with two columns of metadata
 */
export const useDynamicSection = ({ erpData, erpType, section, task, bulkTask }) => {
  const parentDropdownKeys = useMemo(() => {
    // set for handling dupes
    const result = new Set();

    if (section?.metadata) {
      const metadataEntries = Object.values(section?.metadata);
      for (const { requiredBy, showBy, valuesBy } of metadataEntries) {
        [requiredBy, showBy, valuesBy].filter(Boolean).forEach((key) => result.add(key));
      }
    }

    return Array.from(result);
  }, [section]);

  const parentDropdownErpValues = useMemo(() => {
    if (isEmpty(parentDropdownKeys ?? {}) || isEmpty(erpData ?? {})) return null;

    return Object.fromEntries(parentDropdownKeys.map((key) => [key, erpData[key]]));
  }, [parentDropdownKeys, erpData]);

  const { setValue, reset, getValues } = useFormContext();
  const watchParentDropdowns = useWatch({
    name: parentDropdownKeys,
    defaultValue: parentDropdownErpValues ?? {},
  });

  //options not loading for child dropdowns on edit mode for work order
  useEffect(() => {
    if (parentDropdownErpValues) {
      Object.entries(parentDropdownErpValues).map(([key, value]) => setValue(key, value));
      reset();
    }
  }, [parentDropdownErpValues, setValue, reset]);

  const config = {
    refetchOnMount: true,
    enabled: erpType === ErpType.WORK_ORDER,
  };
  const { data: allCrewsData } = useAllCrews(null, null, null, config);
  const { data: workersData } = useWorkers(null, null, null, config);
  const { crewForBundledTask } = useContext(EntityDetailsContext);

  const getDropDownOptions = useCallback(
    (key, options) => {
      let output = [];
      switch (key) {
        case 'location': {
          output = replaceXXX(task, options);
          break;
        }
        case 'lead': {
          const crewId =
            crewForBundledTask?.isSecondaryBundle && crewForBundledTask?.crewId
              ? crewForBundledTask?.crewId[0]
              : task?.crewIds && task?.crewIds[0];
          // 1. Members of the crew assigned to the task
          const taskCrewMemberIds = (allCrewsData ?? [])
            .filter((crew) => crew._id === crewId)
            .flatMap((filteredCrew) => filteredCrew.members?.map((member) => member.member_id));
          const options = {};
          workersData
            ?.filter((w) => taskCrewMemberIds.includes(w.username))
            .map((item) => {
              options[item.email] = item.email;
            });
          output = getOptions({ key, values: options });
          break;
        }
        default:
      }
      return output;
    },
    [allCrewsData, workersData, task],
  );
  const metadataEntries = useMemo(() => {
    if (!section || !section.metadata || section.hidden) {
      return null;
    }
    return Object.entries(section.metadata).reduce((acc, [key, fielditem]) => {
      let field = fielditem;
      if (key == 'asset') {
        const newField = field.valuesByValues.map((data) => {
          data['parentValues'] = replaceXXX(task, data['parentValues']);
          return data;
        }, {});
        field.valuesByValues = newField;
      }
      const hide = isDependentField(field) && !isDependentFieldEnabled(field, watchParentDropdowns);
      let options = getFieldOptions({
        key,
        metadata: field,
        parentValues: watchParentDropdowns,
      });
      if ((key == 'lead' || key == 'location') && erpType == ErpType.WORK_ORDER)
        options = getDropDownOptions(key, options);

      if (hide) {
        // reset value of field if it becomes disabled (can revisit this logic)
        setValue(key, getFieldDefaultValue(field, options));
      } else {
        // Don't mutate original metadata
        const _field = clone(field);

        if (_field.requiredBy) {
          _field.create.required = isDependentFieldRequired(_field, watchParentDropdowns);
        }

        switch (_field.type) {
          case ErpDynamicFieldType.LABEL:
          case ErpDynamicFieldType.TEXT:
            _field.defaultSelection =
              getErpDataValue(key, erpData) ??
              getTextFieldDefaultValue(key, _field?.defaultSelection, task, bulkTask);
            break;

          case ErpDynamicFieldType.TEXTAREA:
            _field.defaultSelection =
              getErpDataValue(key, erpData) ?? getFieldDefaultValue(_field, [], task, key);
            break;

          case ErpDynamicFieldType.DROPDOWN: {
            const { placeholder, defaultValue } = getDropdownDefaults(_field, options) ?? {};
            _field.defaultSelection = getErpDataValue(key, erpData) ?? defaultValue;
            //child dropdowns submitting old value when new parent value selected and child value not selected
            //child values reset to null when parent dropdown value changes
            if (
              erpType == ErpType.WORK_ORDER &&
              parentDropdownKeys &&
              !parentDropdownKeys.includes(key) &&
              options?.length > 0
            ) {
              const currentValue = getValues(key);
              const checkInOpt = options.find((item) => {
                return item.value == currentValue;
              });
              if (!checkInOpt) setValue(key, '');
            }

            _field.placeholder = placeholder;
            _field.options = options;
            break;
          }

          case ErpDynamicFieldType.DATE:
            // date field has default selection and default value so passing in both (where applicable)
            _field.defaultValue = getErpDataValue(key, erpData);
            break;

          case ErpDynamicFieldType.RADIO:
            _field.defaultSelection =
              getErpDataValue(key, erpData) ?? _field.defaultSelection.toUpperCase();
            _field.options = options;
            break;

          case ErpDynamicFieldType.CHECKBOX:
            _field.defaultSelection =
              getErpDataValue(key, erpData) ?? getFieldDefaultValue(_field, [], task, key);
            _field.options = options;
        }

        acc.push([key, _field]);
      }

      return acc;
    }, []);
  }, [
    erpData,
    section,
    setValue,
    task,
    watchParentDropdowns,
    erpType,
    getDropDownOptions,
    getValues,
    parentDropdownKeys,
    bulkTask,
  ]);

  // Reshape the metadata to fit the desired columnar layout
  const metadataRows = useMemo(() => {
    if (!metadataEntries) {
      return null;
    }

    const COLUMNS = 2;
    const TWO_COLUMNS_STARTING = 2;
    const LEAD_COLUMN = 10;

    return metadataEntries.reduce((acc, _, i) => {
      if (erpType === ErpType.SERVICE_REQUEST) {
        if (i % COLUMNS === 0) {
          acc.push(metadataEntries.slice(i, i + COLUMNS));
        }
      } else if (erpType === ErpType.WORK_ORDER) {
        if (i < TWO_COLUMNS_STARTING) {
          acc.push(metadataEntries.slice(i, i + 1));
        } else if (i === LEAD_COLUMN) {
          acc.push([...metadataEntries.slice(i, i + 1), ['', { type: 'emptyField' }]]);
        } else {
          if (i < LEAD_COLUMN) {
            if (i % COLUMNS === 0) {
              acc.push(metadataEntries.slice(i, i + COLUMNS));
            }
          } else {
            if (i % COLUMNS === 1) {
              acc.push(metadataEntries.slice(i, i + COLUMNS));
            }
          }
        }
      }
      return acc;
    }, []);
  }, [erpType, metadataEntries]);

  return metadataRows;
};
