import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '../../../task-template-shared';

export const ViewResolutionNote = ({ metadata, task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const resolutionNotes = task?.resolutionNotes;
  return (
    <>
      <div className="task-panel-row">
        <ColumnTitle>
          <h4>{t('section_header.resolution_note', 'Resolution Note')}</h4>
        </ColumnTitle>
        <ColumnDetail className="column-detail">
          <FieldGroup>
            <div className="field-wrapper">
              <DisplayLabel>{t('form.note', 'Note')}</DisplayLabel>
              <DisplayField>
                {metadata?.resolutionNotes.type == 'select'
                  ? resolutionNotes?.replace(/;/g, ', ')
                  : resolutionNotes}
              </DisplayField>
            </div>
          </FieldGroup>
        </ColumnDetail>
      </div>
    </>
  );
};

ViewResolutionNote.propTypes = {
  task: PropTypes.object.isRequired,
  metadata: PropTypes.object,
  taskPanelControls: PropTypes.node,
};

ViewResolutionNote.defaultProps = {
  taskPanelControls: null,
};
