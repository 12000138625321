import { DateRange } from '@ge/models/constants';

import { FilterDefs } from './analyze-filter-defaults';

export const FleetFilterOptions = {
  ANALYTIC: [
    {
      label: 'Availability',
      key: FilterDefs.AVAILABILITY,
      a11yKey: `filters.${FilterDefs.AVAILABILITY}`,
    },
    {
      label: 'Performance',
      key: FilterDefs.PERFORMANCE,
      a11yKey: `filters.${FilterDefs.PERFORMANCE}`,
    },
  ],
  [FilterDefs.AVAILABILITY]: [
    {
      label: 'TBA - Technical',
      key: 'tba',
      a11yKey: `filters.tba`,
    },
    {
      label: 'PBA - Technical',
      key: 'pba',
      a11yKey: `filters.pba`,
    },
    /* As per Product, not needed for now {
      label: 'Contractual Availability',
      key: 'contractual_availability',
      a11yKey: `filters.contractual_availability`,
    }, */
  ],
  [FilterDefs.PERFORMANCE]: [
    {
      label: 'Actual Production',
      key: 'actual_production',
      a11yKey: `filters.actual_production`,
    },
    {
      label: 'Capacity Factor',
      key: 'capacity_factor',
      a11yKey: `filters.capacity_factor`,
    },
    {
      label: 'Lost Production',
      key: 'lost_production',
      a11yKey: `filters.lost_production`,
    },
  ],
  [FilterDefs.GROUPING]: [
    {
      label: 'By Region',
      key: 'by_region',
      a11yKey: `filters.by_region`,
    },
    {
      label: 'By Platform',
      key: 'by_platform',
      a11yKey: `filters.by_platform`,
    },
    {
      label: 'By Customer',
      key: 'by_customer',
      a11yKey: `filters.by_customer`,
    },
  ],
  [FilterDefs.RANGE]: [
    {
      label: 'Yesterday',
      key: DateRange.YESTERDAY,
      a11yKey: `filters.${DateRange.YESTERDAY}`,
    },
    {
      label: 'Week to date',
      key: DateRange.WEEK_TO_DATE,
      a11yKey: `filters.${DateRange.WEEK_TO_DATE}`,
    },
    {
      label: 'Month to date',
      key: DateRange.MONTH_TO_DATE,
      a11yKey: `filters.${DateRange.MONTH_TO_DATE}`,
    },
    {
      label: 'Year to date',
      key: DateRange.YEAR_TO_DATE,
      a11yKey: `filters.${DateRange.YEAR_TO_DATE}`,
    },
    {
      label: 'Custom',
      key: DateRange.CUSTOM,
      a11yKey: `filters.${DateRange.CUSTOM}`,
    },
  ],
  CURVE: {
    keys: [
      'actual_vs_expected_production',
      'expected_production',
      'lost_production',
      'unproduced_energy',
      'ppi_full_production',
      'ppi_unproduced_energy',
      'ppi_bank',
    ],
    options: [
      {
        label: 'Contract Curve',
        key: 'contract_curve',
        a11yKey: `filters.contract_curve`,
      },
      // #327 - removing for MVP0
      // {
      //   label: 'Learned Curve',
      //   key: 'learned_curve',
      // },
    ],
  },
  [FilterDefs.SORT]: [
    {
      label: 'Ascending',
      key: 'ascending',
      a11yKey: `filters.ascending`,
    },
    {
      label: 'Descending',
      key: 'descending',
      a11yKey: `filters.descending`,
    },
  ],
};
