import clone from 'ramda/src/clone';

import { mergeOptionsRight } from '@ge/util';

import {
  areaChartOptions,
  areaSplineChartOptions,
  stockChartOptions,
  ChartType,
  columnChartOptions,
  columnGroupChartOptions,
  lineChartOptions,
  roseChartOptions,
  scatterChartOptions,
  splineChartOptions,
  barChartOptions,
  stackedBarChartOptions,
} from './models';

const AXIS_TITLE_HEIGHT = 25;

// used for rounding corners on columns
const getBorderRadius = ({ borderRadius = 4, negative = false } = {}) => {
  const borderRadiusPx = `${borderRadius}px`;

  if (negative) {
    return {
      borderRadiusBottomLeft: borderRadiusPx,
      borderRadiusBottomRight: borderRadiusPx,
    };
  }

  return {
    borderRadiusTopLeft: borderRadiusPx,
    borderRadiusTopRight: borderRadiusPx,
  };
};

const getTypeOptions = (type) => {
  let options;

  switch (type) {
    case ChartType.AREA:
      options = areaChartOptions;
      break;

    case ChartType.AREA_SPLINE:
      options = areaSplineChartOptions;
      break;

    case ChartType.COLUMN:
      options = columnChartOptions;
      break;
    case ChartType.MIRROR_COLUMN:
    case ChartType.STACKED_COLUMN:
    case ChartType.COLUMN_DURATION:
    case ChartType.COLUMN_SITE_ACTIVE:
      options = columnChartOptions;
      break;
    case ChartType.COLUMN_GROUP:
      options = columnGroupChartOptions;
      break;
    case ChartType.LINE:
      options = lineChartOptions;
      break;

    case ChartType.ROSE:
      options = roseChartOptions;
      break;

    case ChartType.SCATTER:
      options = scatterChartOptions;
      break;

    case ChartType.SPLINE:
      options = splineChartOptions;
      break;

    case ChartType.BAR:
      options = barChartOptions;
      break;

    case ChartType.STACKED_BAR:
      options = stackedBarChartOptions;
      break;

    default:
      options = {};
  }

  // return a clone so we don't mutate the original options
  return clone(options);
};

const getThemeOptions = ({ colors: overrideColors, theme, type }) => {
  if (!theme) {
    return {};
  }

  const { charts, scrollbar } = theme;
  // TODO: colors currently are high contrast (level 1)
  // but need to be able to pull in colors on scale (level 2) for stacked columns
  // can use the stacked option to decide what colors to pull in
  const {
    axisTitleColor,
    colors: chartColors,
    gridLineColor,
    legendTitleColor,
    lineColor,
    selectedColor,
    tooltipBackgroundColor,
    tooltipColor,
    selectedPoint,
  } = charts;

  const { thumbBackground, trackBackground } = scrollbar;
  // use override colors if provided
  const { borderColor, color, colors: typeColors, negativeColor } = charts[type] || {};
  const colors = overrideColors || typeColors || chartColors;

  const axisOptions = {
    gridLineColor,
    labels: {
      style: {
        color: axisTitleColor,
      },
    },
    lineColor,
    tickColor: gridLineColor,
    title: {
      style: {
        color: axisTitleColor,
      },
    },
  };

  return {
    colors,
    legend: {
      itemStyle: {
        // can define this separate from title color if needed
        color: legendTitleColor,
      },
      itemHoverStyle: {
        color: legendTitleColor,
      },
      title: {
        style: {
          color: legendTitleColor,
        },
      },
    },
    scrollbar: {
      barBackgroundColor: thumbBackground,
      trackBackgroundColor: trackBackground,
      buttonBackgroundColor: 'transparent',
      buttonBorderColor: 'transparent',
      buttonArrowColor: 'transparent',
      rifleColor: 'transparent',
    },
    series: [
      {
        borderColor,
        borderWidth: borderColor ? 1 : 0,
        color,
        negativeColor,
        states: {
          select: {
            color: selectedColor,
          },
        },
        marker: {
          radius: 2,
          states: {
            select: {
              radius: 4,
              lineWidth: 2,
              fillColor: selectedPoint.fillColor,
              lineColor: selectedPoint.lineColor,
            },
          },
        },
      },
    ],
    title: {
      style: {
        color: legendTitleColor,
      },
    },
    subtitle: {
      style: {
        color: legendTitleColor,
      },
    },
    tooltip: {
      backgroundColor: tooltipBackgroundColor,
      style: {
        color: tooltipColor,
      },
    },
    xAxis: {
      ...axisOptions,
      scrollbar: {
        barBackgroundColor: thumbBackground,
        trackBackgroundColor: trackBackground,
        buttonBackgroundColor: 'transparent',
        buttonBorderColor: 'transparent',
        buttonArrowColor: 'transparent',
        rifleColor: 'transparent',
      },
    },
    yAxis: {
      ...axisOptions,
    },
  };
};

// options for stuff like h/w, axis labels, ticks, intervals, etc
const getPlotOptions = ({
  categories,
  height,
  legendTitle,
  xMin,
  xMax,
  max,
  min,
  negative,
  noDataLabel,
  minHeight,
  minWidth,
  opacity,
  scrollPositionX,
  scrollPositionY,
  inverted,
  theme,
  // for stacked vs. side-by-side
  title,
  subTitle,
  stacked,
  turboThreshold,
  boostThreshold,
  columnPointWidth,
  columnPointPadding,
  barPointWidth,
  barPointPadding,
  width,
  scrollbarEnabled,
  navigatorEnabled,
  rangeSelectorEnabled,
  xAxisLabelFormatter,
  xAxisLabelHeight,
  xAxisScrollable,
  xAxisTickInterval,
  xAxisTitle,
  xAxisType,
  xAxisVisible,
  xAxisLabelVisible,
  xAxisTickLength,
  xAxisTickWidth,
  xAxisPlotLines,
  xAxisMaxPadding,
  xShowEmpty,
  xAxisReversed,
  xAxisTickmarkPlacement,
  xAxisLabelsRotation,
  xAxisStaggerLines,
  xAxisAllowOverflow,
  yAxis,
  yAxisLabelFormatter,
  yAxislabelsXPosition,
  yAxisTickInterval,
  yAxisTitle,
  yAxisVisible,
  yAxisOpposite,
  yAxisPlotLines,
  yAxisOffset,
  yAxisTickWidth,
  yAxisTickLength,
  yAxisTickPosition,
  yShowEmpty,
  zoom,
  marginLeft,
  marginRight,
  marginTop,
  disableSideMargin,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingBottom,
  useUTC,
  ceiling,
  endOnTick,
  grouped,
  useClustering,
  tooltipCrosshairs,
  tooltipShared,
  tooltipSplit,
  tooltipFormatter,
}) => {
  const borderRadius = stacked || grouped ? {} : getBorderRadius({ negative });
  const marginBottom = xAxisLabelHeight;

  return {
    chart: {
      // if we have bottom margin to account for x axis labels and/or title, then bump up the height
      // so chart axes will line up across charts regardless of whether they have x axes labels
      height: height + marginBottom,
      // if margin is 0, then we bump up to 1 so it doesn't obscure the x-axis
      marginBottom: marginBottom === 0 ? 1 : marginBottom,

      // If margin is not disabled apply marginLeft based on yAxisOpposite
      // TODO: Need to handle this in a better way after understanding all graphs using this component
      marginLeft: !disableSideMargin && !yAxisOpposite ? 60 : marginLeft,

      // If margin is not disabled decide marginRight based on y axis title
      // we don't account for label height because y axis labels are inset
      // TODO: Need to handle this in a better way after understanding all graphs using this component.
      marginRight: !disableSideMargin && yAxisTitle ? AXIS_TITLE_HEIGHT : marginRight,
      marginTop,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      width,
      zoomType: zoom ? 'xy' : null,
      scrollablePlotArea: {
        minHeight,
        minWidth,
        opacity,
        scrollPositionX,
        scrollPositionY,
      },
      inverted,
    },
    lang: {
      // wrap data label in our own typography styling
      noData: `
        <div class='body-4'>
          ${noDataLabel}
        <div>
      `,
    },
    legend: {
      title: {
        text: legendTitle,
      },
    },
    navigator: {
      enabled: navigatorEnabled,
    },
    noData: {
      position: {
        y: -12,
      },
      useHTML: true,
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subTitle,
    },
    tooltip: {
      crosshairs: tooltipCrosshairs,
      shared: tooltipShared,
      useHTML: true,
      split: tooltipSplit,
      formatter: tooltipFormatter
        ? function() {
            return tooltipFormatter({ points: this.points });
          }
        : function() {
            return this.series?.tooltipOptions?.pointFormatter?.call(this.point);
          },
    },
    plotOptions: {
      series: {
        stacking: stacked ? 'normal' : null,
        cursor: 'pointer',
        turboThreshold: turboThreshold || 0,
        boostThreshold: boostThreshold,
        cluster: !useClustering
          ? {}
          : {
              enabled: true,
              drillToCluster: false,
              layoutAlgorithm: {
                type: 'grid',
                gridSize: 5,
              },
              dataLabels: {
                enabled: false,
              },
              marker: {
                // TODO: this is duplicated from scatter-chart-options
                enabled: true,
                radius: 2,
                symbol: 'circle',
              },
            },
      },
      column: {
        pointWidth: columnPointWidth,
        pointPadding: columnPointPadding,
      },
      bar: {
        pointWidth: barPointWidth,
        pointPadding: barPointPadding,
      },
    },
    rangeSelector: {
      enabled: rangeSelectorEnabled,
    },
    scrollbar: {
      enabled: scrollbarEnabled,
      liveRedraw: false,
      barBorderWidth: 0,
      barBorderRadius: 7,
      trackBorderWidth: 0,
      buttonBorderWidth: 0,
      buttonBorderRadius: 7,
      height: 6,
    },
    series: [
      {
        ...borderRadius,
        point: {
          events: {
            mouseOver: function() {
              if (this.series.halo) {
                this.series.halo
                  .attr({
                    class: 'highcharts-tracker',
                  })
                  .toFront();
              }
            },
          },
        },
      },
    ],
    xAxis: {
      categories,
      dateTimeLabelFormats: {
        day: {
          main: '%e %b',
        },
      },
      labels: {
        formatter: xAxisLabelFormatter
          ? function() {
              return xAxisLabelFormatter({
                axis: this.axis,
                chart: this.chart,
                index: this.pos,
                isFirst: this.isFirst,
                isLast: this.isLast,
                label: this.axis.defaultLabelFormatter.call(this),
                value: this.value,
              });
            }
          : null,
        enabled: xAxisLabelVisible,
        rotation: xAxisLabelsRotation,
        staggerLines: xAxisStaggerLines,
        overflow: xAxisAllowOverflow,
      },
      scrollbar: {
        enabled: xAxisScrollable,
        liveRedraw: false,
        barBorderWidth: 0,
        barBorderRadius: 7,
        trackBorderWidth: 0,
        buttonBorderWidth: 0,
        buttonBorderRadius: 7,
      },
      tickmarkPlacement: xAxisTickmarkPlacement,
      tickInterval: xAxisTickInterval,
      tickLength: xAxisTickLength,
      tickWidth: xAxisTickWidth,
      title: {
        text: xAxisTitle,
      },
      type: xAxisType,
      visible: xAxisVisible,
      plotLines: xAxisPlotLines,
      min: xMin,
      max: xMax,
      maxPadding: xAxisMaxPadding,
      showEmpty: xShowEmpty,
      reversed: xAxisReversed,
    },
    yAxis: yAxis
      ? getYAxisOptions({
          yAxis,
          theme,
        })
      : {
          labels: {
            formatter() {
              if (this.value < 0) {
                // push labels for negative values below gridline
                // TODO: revisit if this is the best place to do this
                this.axis.options.labels.y = 12;
                this.axis.options.showFirstLabel = false;
              } else if (this.value === 0 && !negative) {
                this.axis.options.labels.y = -4;
              }

              return yAxisLabelFormatter
                ? yAxisLabelFormatter({
                    axis: this.axis,
                    chart: this.chart,
                    index: this.pos,
                    isFirst: this.isFirst,
                    isLast: this.isLast,
                    label: this.axis.defaultLabelFormatter.call(this),
                    value: this.value,
                  })
                : this.value;
            },
            x: !yAxislabelsXPosition ? (!yAxisOpposite ? -15 : null) : yAxislabelsXPosition,
            y: !yAxisOpposite ? (min ? 5 : -5) : null,
          },
          max,
          min,
          ceiling,
          endOnTick,
          tickInterval: yAxisTickInterval,
          title: {
            text: yAxisTitle,
          },
          visible: yAxisVisible,
          opposite: yAxisOpposite,
          plotLines: yAxisPlotLines,
          offset: yAxisOffset,
          tickWidth: yAxisTickWidth,
          tickLength: yAxisTickLength,
          tickPosition: yAxisTickPosition,
          showEmpty: yShowEmpty,
        },
    time: {
      useUTC: useUTC,
    },
  };
};

// options specific to individual series
const getSeriesOptions = ({ colors = [], series = [], type, stacked = false }) => {
  const options = {
    series: series.map(
      (
        {
          color,
          tooltipEnabled,
          tooltipHeader,
          tooltipPoint,
          tooltipPointFormatter,
          type,
          ...options
        },
        i,
      ) => {
        // grab type options for the series
        const { series: typeSeries = [] } = getTypeOptions(type);
        const typeOptions = typeSeries[0] || {};

        return {
          ...typeOptions,
          ...options,
          // this fixes a bug with colors "drifting" when toggling back and forth between charts
          color: color ?? colors[i],
          // only disable (suppress tooltips) if tooltipEnabled flag explicitly set to false
          // https://www.highcharts.com/forum/viewtopic.php?t=12837
          enableMouseTracking: tooltipEnabled !== false,
          tooltip: {
            headerFormat: tooltipHeader || '',
            pointFormat: tooltipPoint,
            pointFormatter: tooltipPointFormatter
              ? function() {
                  const {
                    color,
                    id,
                    name,
                    x,
                    y,
                    date,
                    clusteredData,
                    category,
                    series,
                    stackTotal,
                  } = this;

                  // can expand how much stuff we pass in here
                  return tooltipPointFormatter({
                    color,
                    id,
                    name,
                    x,
                    y,
                    date,
                    clusteredData,
                    category,
                    series,
                    stackTotal,
                  });
                }
              : null,
          },
        };
      },
    ),
  };

  // if series is stacked, we only apply border radius to the top series
  // there could be an issue if the top series has a value of 0 for a given category
  // and it shows the next series at the top with squared borders
  // I talked to dave le about this and he's okay with dealing with that situation if we encounter it
  if (stacked && type !== ChartType.ROSE) {
    const { borderRadiusTopLeft, borderRadiusTopRight } = getBorderRadius();
    const topSeries = options.series && options.series[0];

    if (topSeries) {
      topSeries.borderRadiusTopLeft = borderRadiusTopLeft;
      topSeries.borderRadiusTopRight = borderRadiusTopRight;
    }
  }

  return options;
};

// options specific to individual yAxis
const getYAxisOptions = ({ yAxis = [], theme }) => {
  const options = yAxis.map(({ ...options }) => {
    if (!theme) {
      return {};
    }

    const { charts } = theme;
    // TODO: colors currently are high contrast (level 1)
    // but need to be able to pull in colors on scale (level 2) for stacked columns
    // can use the stacked option to decide what colors to pull in
    const { axisTitleColor, gridLineColor, lineColor } = charts;

    const axisOptions = {
      gridLineColor,
      labels: {
        style: {
          color: axisTitleColor,
        },
      },
      lineColor,
      tickColor: gridLineColor,
      title: {
        style: {
          color: axisTitleColor,
        },
      },
    };

    return mergeOptionsRight(axisOptions, options);
  });

  return options;
};

// parses our chart options into the highcharts options to get the look and behavior we expect
export const stockChartOptionsFactory = ({
  categories,
  colors,
  height,
  legendTitle,
  xMin,
  xMax,
  max,
  min,
  negative,
  noDataLabel,
  onClick,
  onSelect,
  onSelected,
  minHeight,
  minWidth,
  opacity,
  scrollPositionX,
  scrollPositionY,
  inverted,
  title,
  subTitle,
  series,
  stacked,
  theme,
  turboThreshold,
  boostThreshold,
  columnPointWidth,
  columnPointPadding,
  barPointWidth,
  barPointPadding,
  type,
  width,
  scrollbarEnabled,
  navigatorEnabled,
  rangeSelectorEnabled,
  xAxisLabelFormatter,
  xAxisLabelHeight,
  xAxisScrollable,
  xAxisTickInterval,
  xAxisTitle,
  xAxisTitleHeight,
  xAxisType,
  xAxisVisible,
  xAxisLabelVisible,
  xAxisTickLength,
  xAxisTickWidth,
  xAxisPlotLines,
  xAxisMaxPadding,
  xShowEmpty,
  xAxisReversed,
  xAxisTickmarkPlacement,
  xAxisLabelsRotation,
  xAxisStaggerLines,
  xAxisAllowOverflow,
  yAxis,
  yAxisLabelFormatter,
  yAxislabelsXPosition,
  yAxisTickInterval,
  yAxisTitle,
  yAxisVisible,
  yAxisOpposite,
  yAxisPlotLines,
  yAxisOffset,
  yAxisTickWidth,
  yAxisTickLength,
  yAxisTickPosition,
  yShowEmpty,
  zoom,
  marginLeft,
  marginRight,
  marginTop,
  disableSideMargin,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingBottom,
  useUTC,
  endOnTick,
  ceiling,
  tooltip,
  grouped,
  useClustering,
  tooltipCrosshairs,
  tooltipShared,
  tooltipSplit,
  tooltipFormatter,
} = {}) =>
  mergeOptionsRight(
    // generic -> specific
    mergeOptionsRight(stockChartOptions, { tooltip }),
    getTypeOptions(type),
    getThemeOptions({ colors, theme, type }),
    getPlotOptions({
      categories,
      height,
      legendTitle,
      xMin,
      xMax,
      max,
      min,
      negative,
      noDataLabel,
      onClick,
      onSelect,
      onSelected,
      minHeight,
      minWidth,
      opacity,
      scrollPositionX,
      scrollPositionY,
      inverted,
      title,
      subTitle,
      stacked,
      theme,
      turboThreshold,
      boostThreshold,
      columnPointWidth,
      columnPointPadding,
      barPointWidth,
      barPointPadding,
      width,
      scrollbarEnabled,
      navigatorEnabled,
      rangeSelectorEnabled,
      xAxisLabelFormatter,
      xAxisLabelHeight,
      xAxisScrollable,
      xAxisTickInterval,
      xAxisTitle,
      xAxisTitleHeight,
      xAxisType,
      xAxisVisible,
      xAxisLabelVisible,
      xAxisTickLength,
      xAxisTickWidth,
      xAxisPlotLines,
      xAxisMaxPadding,
      xShowEmpty,
      xAxisReversed,
      xAxisTickmarkPlacement,
      xAxisLabelsRotation,
      xAxisStaggerLines,
      xAxisAllowOverflow,
      yAxis,
      yAxisLabelFormatter,
      yAxislabelsXPosition,
      yAxisTickInterval,
      yAxisTitle,
      yAxisVisible,
      yAxisOpposite,
      yAxisPlotLines,
      yAxisOffset,
      yAxisTickWidth,
      yAxisTickLength,
      yAxisTickPosition,
      yShowEmpty,
      zoom,
      marginLeft,
      marginRight,
      marginTop,
      disableSideMargin,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      useUTC,
      ceiling,
      endOnTick,
      grouped,
      useClustering,
      tooltipCrosshairs,
      tooltipShared,
      tooltipSplit,
      tooltipFormatter,
    }),
    getSeriesOptions({ colors, type, series, stacked }),
  );

// when updating an existing chart
// mainly for data updates
export const stockChartOptionsUpdateFactory = ({ colors, series = [], theme, type }) =>
  mergeOptionsRight(
    type ? mergeOptionsRight(getTypeOptions(type), getThemeOptions({ colors, theme, type })) : {},
    getSeriesOptions({ series }),
  );
