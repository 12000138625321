import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@ge/components/containers';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import { AssetOverview } from '../components/asset-overview/asset-ov';
import { AssetProvider } from '../context/asset-ov-provider';

export const AnalyzeAssetOv = () => {
  const { ready } = useTranslation(['analyze.data-explorer'], {
    useSuspense: false,
  });

  return (
    <PageContainer i18nReady={ready}>
      <AssetProvider>
        <ScrollingContainer>
          <AssetOverview />
        </ScrollingContainer>
      </AssetProvider>
    </PageContainer>
  );
};
