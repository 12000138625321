import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { ConditionsKpiCategoryDefs, KpiCategoryDefs, Placeholders } from '@ge/models/constants';

import { NoDataAvailable } from '../../no-data-available/no-data-available';
import { ListTable } from '../../tables/list-table';

const DefaultRowOrder = {
  [KpiCategoryDefs.PRODUCTION_ACTUAL]: 0,
  [KpiCategoryDefs.CAPACITY_FACTOR]: 1,
  [KpiCategoryDefs.AVAILABILITY_CONTRACT]: 2,
  operatingHours: 3,
  [ConditionsKpiCategoryDefs.WIND]: 4,
};

const getDisplayValue = (value, language) =>
  new Intl.NumberFormat(language, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);

const ListContainer = styled.div`
  height: 100%;
`;

const LoaderContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.widget.headerTextColor};
`;

export const ListWidget = ({ data, headerContent, name, isLoading, isPlaceholder }) => {
  const { t } = useTranslation(['reporting.widgets']);

  const rows = useMemo(() => {
    if (!data) {
      return null;
    }

    const entries = Object.entries(data);

    return entries
      .reduce((mapped, [key, _value]) => {
        if (_value) {
          const { units, value } = _value;

          mapped.push({
            id: key,
            title: t(`kpi.${key}`, key),
            units: t(`kpi.units.${units}`, units),
            value: value ? getDisplayValue(value) : Placeholders.DOUBLE_DASH,
          });
        }

        return mapped;
      }, [])
      .sort(({ id: a }, { id: b }) => DefaultRowOrder[a] - DefaultRowOrder[b]);
  }, [data, t]);

  if (isLoading) {
    return (
      <>
        <StyledHeader>
          <h4>{name}</h4>
        </StyledHeader>
        <ListContainer>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </ListContainer>
      </>
    );
  }

  if (!data) {
    return <NoDataAvailable />;
  }

  if (rows?.length === 0) {
    return (
      <>
        <StyledHeader>
          <h4>{name}</h4>
        </StyledHeader>
        {!isPlaceholder && (
          <ListContainer>
            <NoDataAvailable />
          </ListContainer>
        )}
      </>
    );
  }

  return (
    <>
      <StyledHeader>
        <h4>{name}</h4>
        {headerContent}
      </StyledHeader>
      {!isPlaceholder && <ListTable data={rows} />}
    </>
  );
};

ListWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  headerContent: PropTypes.node,
  isLoading: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
};

ListWidget.defaultProps = {
  data: {},
  headerContent: null,
};
