import { action, computed, thunk } from 'easy-peasy';
import memoize from 'memoizerific';

import { AlertStatus } from '@ge/models/constants';
import { groupById } from '@ge/util';
import { byId } from '@ge/util/array-utils';
import merge from '@ge/util/deep-merge';

import { fetchAlerts } from '../services/alerts';

// Default state to apply on initialization or reset.
const defaultAlertsState = {
  alerts: {},
};

const alertsActions = {
  // eslint-disable-next-line no-unused-vars
  resetAlerts: action((state) => {
    state = Object.assign(state, defaultAlertsState);
  }),

  updateAlerts: action((state, payload) => {
    Object.values(payload ?? {}).forEach((alert) => {
      const existingAlert = state.alerts[alert.id];
      state.alerts[alert.id] = !existingAlert ? alert : merge(existingAlert, alert);
    });
  }),

  fetchAlerts: thunk(async (actions, _, { fail }) => {
    try {
      const { alerts } = await fetchAlerts();
      actions.updateAlerts(byId(alerts));
    } catch (error) {
      fail(error);
    }
  }),
};

// TODO: figure out if we need to get back both asset and site level alerts for asset
// should we filter on only open alerts here?
const alertsComputed = {
  getAlertsByEntityId: computed([(state) => state.alerts], (alerts) =>
    memoize(10)(({ entityId, entityType }) =>
      Object.values(alerts).filter(
        ({ [entityType]: entity, status }) =>
          // can revisit if we want to filter out closed alerts by default here
          entity?.id === entityId && status !== AlertStatus.CLOSED,
      ),
    ),
  ),

  alertsByAssetId: computed([(state) => state.alerts], (alerts) => {
    const assetAlerts = Object.values(alerts).filter((a) => a.asset);
    return groupById(assetAlerts, ['asset', 'id']);
  }),
};

const alertsModel = {
  ...defaultAlertsState,
  ...alertsActions,
  ...alertsComputed,
};

export default alertsModel;
