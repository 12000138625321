import countBy from 'ramda/src/countBy';
import groupBy from 'ramda/src/groupBy';
import pathEq from 'ramda/src/pathEq';
import pathSatisfies from 'ramda/src/pathSatisfies';
import * as type from 'ramda/src/type';

import { ActionKeys } from '@ge/shared/models/actions-menu-items';
import { getPreferredUserName } from '@ge/shared/services/auth';

export const breakDownProxies = (obj) => {
  if (!isObject(obj)) {
    return obj;
  }

  return Object.fromEntries(
    // convert to array, map, and then fromEntries gives back the object
    Object.entries({ ...obj }).map(([key, value]) => [key, breakDownProxies(value)]),
  );
};

export const calcWindDirection = (dir) => {
  switch (dir) {
    case 'N':
      return 0;
    case 'NE':
      return 45;
    case 'E':
      return 90;
    case 'SE':
      return 135;
    case 'S':
      return 180;
    case 'SW':
      return 225;
    case 'W':
      return 270;
    case 'NW':
      return 315;
    default:
      return null;
  }
};

export const countWhere = (path, value) => {
  const isWhere = pathEq(path.split('.'), value);
  return countBy(isWhere);
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;

      if (!immediate) {
        func.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, args);
    }
  };
};

export const groupByEventId = groupBy((entity) => entity.event.id);

export const isObject = (value) => {
  const valType = typeof value;
  return valType === 'function' || (valType === 'object' && !!value);
};

export const killEventPropagation = (e) => {
  if (!e) {
    return;
  }

  if (isFunction(e, 'stopPropagation')) {
    e.stopPropagation();
  }

  if (isFunction(e, 'nativeEvent.stopImmediatePropagation')) {
    e.nativeEvent.stopImmediatePropagation();
  }
};

export const noop = () => undefined;

export const isFunction = (obj, path) => {
  if (!path) {
    return type(obj) === 'Function';
  }

  if (type(path) === 'String') {
    path = path.split('.');
  }

  return type(path) === 'Array' ? pathSatisfies((o) => type(o) === 'Function', path, obj) : false;
};

export const getDefaultTranslation = (key) => {
  if (typeof key !== 'string') return key;
  return (key || '')
    .split(/[-_]/)
    .map((token) => token.charAt(0).toLocaleUpperCase() + token.substr(1))
    .join(' ');
};

export const arrayify = (value) => (Array.isArray(value) ? value : [value]);

// can make case sensitivity configurable if needed
export const searchStr = (source = '', searchTerm = '') => {
  if (typeof source === 'number') return source === searchTerm ?? source;
  else
    return String(source)
      ?.toLocaleLowerCase()
      ?.trim()
      .includes(searchTerm?.toLocaleLowerCase()?.trim());
};

/**
 * Executes provided callback after a delay
 * @param {function} fn
 * @param {number} durationInMilliseconds
 * @returns {Promise} A promise that resolves callback after specified delay
 */
export const delay = (fn, durationInMilliseconds) =>
  new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        // wrapping in promisable just in case
        const result = await fn();

        resolve(result);
      } catch (err) {
        reject(err);
      }
    }, durationInMilliseconds);
  });

/**
 * Executes provided callback after a delay and returns a cancel function
 * @param {function} fn
 * @param {number} durationInMilliseconds
 * @returns {{cancel: function, promise: Promise }} A cancel function and the promise that resolves the callback
 */
export const cancelableDelay = (fn, durationInMilliseconds) => {
  let cancelId;

  const promise = new Promise((resolve, reject) => {
    cancelId = setTimeout(async () => {
      try {
        // wrapping in promisable just in case
        const result = await fn();

        resolve(result);
      } catch (err) {
        reject(err);
      }
    }, durationInMilliseconds ?? 0);
  });

  return {
    promise,
    cancel: () => clearTimeout(cancelId),
  };
};

export const dedupArray = (array) => Array.from(new Set(array));

export const snakeToCamelCase = (text) =>
  text?.replace(/_(.)/g, (_, match) => match.toLocaleUpperCase());

export const getOptions = (val = {}, t = () => {}, prefix = '') =>
  // Object.values handles both object and array inputs
  Object.values(val).map((value) => {
    return {
      label: t(`${prefix}${value}`, value) ?? value,
      value,
    };
  });

/**
 * Reverses key and value in a basic dictionary object
 * @param {object} dictionary A basic object with string values
 * @returns An object with key and value reversed
 */
export const reverseDictionary = (dictionary) =>
  Object.entries(dictionary ?? {}).reduce(
    (reverse, [key, value]) => ({ ...reverse, [value]: key }),
    {},
  );

export const getNotesDisabledActions = (note, isNotesAdmin) => {
  const preferredUserName = getPreferredUserName();
  if (!isNotesAdmin && note?.createdBy !== preferredUserName) {
    const isDisabled = note?.createdBy !== preferredUserName;
    return {
      [ActionKeys.NOTE_EDIT]: isDisabled,
      [ActionKeys.NOTE_DELETE]: isDisabled,
      // [ActionKeys.SI_EDIT]: isDisabled,
      // [ActionKeys.SI_DELETE]: isDisabled,
    };
  } else {
    return {};
  }
};

export const getNotesDisabledEyeActions = (note, isNotesAdmin) => {
  const preferredUserName = getPreferredUserName();
  if (isNotesAdmin) return true;
  if (note?.createdBy !== preferredUserName) {
    return false;
  } else {
    return true;
  }
};

export const isDevMode = () => process.env.REACT_APP_ENV === 'development';
