import { PropTypes } from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import styled, { withTheme, css } from 'styled-components';

const ModalWrapper = styled.div`
  background-color: ${(props) => props.theme.modal.overlay};
  position: fixed;
  ${(props) =>
    !props.isTaskTable
      ? css`
          right: 34%;
          top: 10%;
          z-index: 1;
        `
      : css`
          top: 190px;
          left: 35px;
          z-index: 15;
        `}
`;

const DialogWrapper = styled.div`
  background-color: ${(props) => props.theme.modal.background};
  box-shadow: ${(props) => props.theme.layout.sidebarBoxShadow};
  border-radius: 6px !important;
  ${(props) =>
    props.contentWidth
      ? css`
          display: inline-block;
          transform: translateX(-50%);
          margin-left: 50%;
          width: 345px;
        `
      : css`
          width: 465px;
        `}

  color: ${(props) => props.theme.layout.textColor};
  font-family: Museo Sans;
`;

const DialogContent = styled.section`
  padding: 0px 25px 20px 25px;
`;

const DialogFooter = styled.footer`
  background-color: ${(props) => props.theme.modal.background};
  border-top: 1px solid ${(props) => props.theme.dialog.footerSeparatorColor};
  padding: 17px 25px;
  border-radius: 0 0 6px 6px;
`;

const WarningDialogComponent = ({
  className,
  footer,
  isOpen,
  padContent,
  children,
  contentWidth,
  portalId,
}) => {
  if (!isOpen) {
    return null;
  }
  return createPortal(
    <ModalWrapper isOpen={isOpen} isTaskTable={portalId === '.cases-tasks-table'}>
      <DialogWrapper contentWidth={contentWidth} className={className}>
        {padContent ? <DialogContent>{children}</DialogContent> : children}
        {footer && <DialogFooter>{footer}</DialogFooter>}
      </DialogWrapper>
    </ModalWrapper>,
    document.querySelector(portalId),
  );
};

WarningDialogComponent.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  footer: PropTypes.node,
  padContent: PropTypes.bool,
  contentWidth: PropTypes.bool,
  portalId: PropTypes.string,
};

WarningDialogComponent.defaultProps = {
  className: '',
  isOpen: false,
  children: null,
  footer: null,
  padContent: true,
  contentWidth: false,
  portalId: '#portal-root',
};

export const WarningDialog = withTheme(WarningDialogComponent);
