import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { CheckedState } from '@ge/components/checkbox';
import { DataLoader } from '@ge/components/data-loader';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { DataLoaderType, Capability, PermissionScope } from '@ge/models/constants';
import { WarningMessage } from '@ge/shared/components/warning-message';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';

import { PlanningContext } from '../../../context/planning-provider';
import useAssetSearch from '../../../data-hooks/use-asset-search';

import { AddTaskItem } from './add-task-item';
import { BacklogFilter } from './backlog-filter';
import { AssetDialog } from './backlog-panel-asset-dialog';

const Container = styled.div`
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  .header {
    padding: 10px;
    border-bottom: solid 1px ${(props) => props.theme.manage.taskItem.borderColor};
    .filter {
      position: relative;
      left: -5px;
      display: flex;
      align-items: center;
    }
    label span {
      font-size: 12px;
      line-height: 14px;
      color: ${(props) => props.theme.manage.taskItem.textColor};
    }
  }
  .backlog {
    display: flex;
    flex: 1;
    position: relative;
  }
  .newtask {
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.21);
    margin-top: auto;
    padding: 16px 10px 10px;
    position: relative;
  }
`;

const FilterBtn = styled.div`
  font-size: 12px;
  color: inherit;
  padding: 0;
`;

const StyledTasksList = styled.div`
  position: relative;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }
  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const AddAssetButton = styled.button`
  border-radius: 2px;
  position: relative;
  &:focus {
    outline: none;
  }
  width: 30px;
  height: 24px;
  background: ${(props) =>
    props.active ? props.theme.select.primaryBackground : props.theme.select.secondaryBackground};
  border: solid 1px ${({ theme }) => theme.select.borderColor};
`;

const SearchBar = styled.div`
  flex: 1;
  margin-left: 10px;
  .filter {
    position: relative;
    input {
      background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
      box-sizing: border-box;
      border: 1px solid ${(props) => props.theme.themeSelector.borderNavbarInput};
      border-radius: 2px;
      caret-color: ${(props) => props.theme.newTaskDialog.searchCaret};
      color: ${(props) => props.theme.newTaskDialog.searchText};
      height: 26px;
      padding: 6px 8px;
      width: 100%;
      ::placeholder {
        font-size: 14px;
        font-style: italic;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }
`;

const AddAssetIcon = styled(Icon).attrs((props) => ({
  size: 16,
  icon: Icons.TURBINE,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const StyledDataLoader = styled(DataLoader).attrs(({ theme }) => ({
  noDataIconColor: theme.manage.timeline.noDataIconColor,
  type: DataLoaderType.GRID,
}))``;

export const AddTaskBacklogPanel = ({ backlogData, isFetching, selectedTask, setSelectedTask }) => {
  const { t } = useTranslation(['tasks', 'manage.planning']);
  const { planningState } = useContext(PlanningContext);
  const { data } = useAssetSearch();
  const [assetsFiltered] = data;
  const { isAuthorized } = useAuth();
  const pagination = useStoreState((store) => store.tasks.taskPageStatus);
  const [showAssetDialog, setShowAssetDialog] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isBacklogFilterVisible, setBacklogFilterVisible] = useState(true);
  const [backlog, setBacklog] = useState(backlogData);
  const [filterText, setFilterText] = useState('');
  const [assetsSelected, setfilteredAsset] = useState(planningState.backlogTaskFilter?.assets);
  const parentRef = useRef();
  const authRef = useRef({});
  useEffect(() => {
    if (!filterText.length && !planningState.backlogTaskFilter) return setBacklog(backlogData);
    const { status, source, workScope, priority, flag } = planningState.backlogTaskFilter.filters;
    const assetIds = planningState.backlogTaskFilter?.assets?.filter((a) =>
      assetsFiltered.some((b) => a === b.id),
    );
    setfilteredAsset(assetIds);
    const filteredData = backlogData?.filter(
      (task) =>
        task?.title?.toLowerCase().includes(filterText.toLowerCase()) &&
        (assetIds?.length ? assetIds.includes(task?.asset?.id) : true) &&
        (status?.value?.length ? status.value.includes(task?.status) : true) &&
        (source?.value?.length ? source.value.includes(task?.source) : true) &&
        (workScope?.value?.length ? workScope.value.includes(task?.workScope) : true) &&
        (priority?.value?.length ? priority.value.includes(task?.priority) : true) &&
        (flag?.value?.length ? flag.value.includes(task?.flag) : true),
    );

    setBacklog(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backlogData, filterText, assetsFiltered, planningState.backlogTaskFilter]);

  const handleModalOpen = () => {
    setBacklogFilterVisible(true);
  };

  const getCheckState = useCallback(
    (taskId) => (selectedTask.includes(taskId) ? CheckedState.CHECKED : CheckedState.UNCHECKED),
    [selectedTask],
  );

  const toggleCheckbox = useCallback(
    (e, assetId) => {
      killEventPropagation(e);
      return selectedTask.includes(assetId)
        ? setSelectedTask((prevselectedAssets) => prevselectedAssets.filter((el) => el !== assetId))
        : setSelectedTask((prevselectedAssets) => [...prevselectedAssets, assetId]);
    },
    [selectedTask, setSelectedTask],
  );

  const rowVirtualizer = useVirtual({
    size: backlog.length,
    parentRef,
    estimateSize: React.useCallback(() => 58, []),
    overscan: 5,
  });

  const buildTask = useCallback(
    ({ index, measureRef }) => {
      const task = backlog[index];
      if (!task) return;

      const siteId = task.site?.id;

      // check for cached auth
      let auth = authRef.current?.[siteId];

      if (!auth) {
        auth = {
          workPlanEdit: isAuthorized({
            capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
            enforceView: true,
            siteIds: [siteId],
          }),
          realTimePagesView: isAuthorized({
            capabilities: [
              { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
            ],
            enforceView: true,
            siteIds: [siteId],
          }),
        };

        authRef.current[siteId] = auth;
      }

      return (
        <AddTaskItem
          hasAssetPanel={auth.realTimePagesView}
          hasSitePanel={auth.realTimePagesView}
          className={classNames({
            dragging: planningState.dragItem && planningState.dragItem.id === task.id,
          })}
          task={task}
          key={task.id}
          getCheckState={getCheckState}
          toggleCheckbox={toggleCheckbox}
          ref={measureRef}
        />
      );
    },
    [backlog, planningState.dragItem, getCheckState, toggleCheckbox, isAuthorized],
  );

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  if (!items) return null;
  const filters = {
    status: [...new Set(backlogData?.map((e) => e.status))],
    source: [...new Set(backlogData?.map((e) => e.source))],
    workScope: [...new Set(backlogData?.map((e) => e.workScope))],
    priority: [...new Set(backlogData?.map((e) => e.priority))],
    flag: [...new Set(backlogData?.map((e) => e.flag))].filter(Boolean),
  };
  filters.workScope.sort();

  return (
    <>
      <Container>
        <div className="header">
          <div className="filter">
            <SearchBar>
              <Filter
                text={filterText}
                onKeyDown={(e) => {
                  killEventPropagation(e);
                }}
                onChange={(e) => {
                  killEventPropagation(e);
                  setFilterText(e.target.value);
                }}
                placeholder={t('search', 'Search')}
                hideResultsOnBlur={false}
              />
            </SearchBar>
            <FilterBtn>
              <span>
                <button onClick={handleModalOpen}>
                  {isBacklogFilterVisible && <BacklogFilter filters={filters} state={'addtask'} />}
                </button>
              </span>
              <AddAssetButton onClick={() => setShowAssetDialog(true)} active={isActive}>
                <AddAssetIcon />
              </AddAssetButton>
              {showAssetDialog && (
                <AssetDialog
                  backlogData={backlogData}
                  assetsSelected={assetsSelected}
                  setActive={setActive}
                  onClose={() => setShowAssetDialog(false)}
                  onConfirm={() => setShowAssetDialog(false)}
                  state={'addtask'}
                />
              )}
            </FilterBtn>
          </div>
        </div>
        <div className="backlog">
          <StyledDataLoader isLoading={isFetching} type={DataLoaderType.GRID} margin="100px auto">
            <ScrollingContainer style={{ maxHeight: '100%' }} ref={parentRef}>
              {pagination?.isMax ? (
                <WarningMessage />
              ) : (
                <StyledTasksList paddingTop={paddingTop} paddingBottom={paddingBottom}>
                  {items?.map((item) => buildTask(item))}
                </StyledTasksList>
              )}
            </ScrollingContainer>
          </StyledDataLoader>
        </div>
      </Container>
    </>
  );
};

AddTaskBacklogPanel.propTypes = {
  backlogData: PropTypes.instanceOf(Object),
  selectedTask: PropTypes.instanceOf(Array),
  setSelectedTask: PropTypes.func,
  isFetching: PropTypes.bool,
};

AddTaskBacklogPanel.defaultProps = {
  backlogData: [],
  selectedTask: {},
  setSelectedTask: () => {},
  isFetching: false,
};
