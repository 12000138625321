import { action, computed, persist, thunk } from 'easy-peasy';
import memoize from 'memoizerific';

import { SiteType } from '@ge/models/constants';
import { fetchViewSelector, fetchPrefs, savePrefs } from '@ge/shared/services/common';
import indexedDb from '@ge/shared/state/storage/indexedDb';

// Define initial state
const defaultViewState = persist(
  {
    currentView: { sites: [] },
    savedViews: [],
  },
  {
    version: 1,
    storage: indexedDb,
    mergeStrategy: 'overwrite',
  },
);

// Actions
const viewActions = {
  /**
   * Reset state to defaults
   */
  resetViews: action((state) => {
    state = Object.assign(state, defaultViewState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.currentView = defaultViewState.currentView;
  }),

  setViewPrefs: action((state, payload) => {
    state.currentView = payload.currentView;
  }),

  fetchViewPrefs: thunk(async (actions, _, { fail }) => {
    try {
      const { prefs } = await fetchPrefs();
      actions.setViewPrefs(prefs);
    } catch (error) {
      fail(error);
    }
  }),

  saveViewPrefs: thunk(async (actions, payload, { fail, getStoreState }) => {
    try {
      const currentView = { ...payload };

      // make sure that view includes sites user has access to
      // if user permissions change this will clear out sites that are no longer in scope
      // do we want to be more proactive here and verify view whenever site/static data loads?
      // can look at applying this to other data on the current view if needed
      // would be nice to verify static data is loaded before filtering in here
      if (currentView?.sites?.length) {
        const { sites, storageSites } = getStoreState().sites;

        currentView.sites = currentView.sites.filter(({ id }) => sites[id]);
        currentView.storageSites = currentView.sites.filter(({ id }) => storageSites[id]);
      }

      // TODO: should we await save so current view only updates on success
      // this ensures consistency between local and persisted views
      // and ensures that ui calls to bff that access persisted view are in sync
      await savePrefs({ currentView });
      actions.setViewPrefs({ currentView });
    } catch (error) {
      fail(error);
    }
  }),

  /**
   * Set the savedViews state array.
   */
  setSavedViews: action((state, payload) => {
    state.savedViews = payload;
  }),

  /**
   * Retrieve saved custom views from the API and update state.
   */
  fetchSavedViews: thunk(async (actions, _, { getStoreActions, fail }) => {
    try {
      const {
        data,
        entities: { customers },
      } = await fetchViewSelector();
      getStoreActions().customers.updateCustomers(Object.values(customers));
      actions.setSavedViews(data);
    } catch (err) {
      fail(err);
    }
  }),

  /**
   * Save the specified custom view via the API.
   */
  saveView: thunk(async (actions, payload) => {
    // TODO API CALL
    actions.setSavedViews(payload);
  }),
};

// Computed values
const viewComputed = {
  isViewDirty: computed((state) => (aView) => {
    const savedView = state.savedViews.find((view) => aView.id === view.id);
    if (!savedView) {
      return null;
    }

    const areSitesEqual =
      savedView.sites
        .map((site) => site.id)
        .every((x) => aView.sites.map((site) => site.id).includes(x)) &&
      aView.sites
        .map((site) => site.id)
        .every((x) => savedView.sites.map((site) => site.id).includes(x));

    const areNamesEqual = savedView.name === aView.name;

    return !areSitesEqual || !areNamesEqual;
  }),
  viewTurbineCount: computed(
    [(state) => state.currentView, (_, storeState) => storeState.sites.sites],
    (currentView, sites) => (customView) => {
      const view = customView ?? currentView;
      return view?.sites?.reduce((count, { id }) => {
        const { turbineTypes } = sites[id] ?? {};
        // getting asset count from site
        // TODO: figure out if this also supports non-turbine asset counts
        const siteAssetCount =
          turbineTypes?.reduce(
            (_siteAssetCount, { modelCount }) => _siteAssetCount + (modelCount ?? 0),
            0,
          ) ?? 0;
        return count + siteAssetCount;
      }, 0);
    },
  ),
  viewInverterCount: computed(
    [(state) => state.currentView, (_, storeState) => storeState.sites.sites],
    (currentView, sites) => (customView) => {
      const view = customView ?? currentView;
      return view?.sites?.reduce((count, { id }) => {
        const { inverters } = sites[id] ?? {};
        if (inverters?.types !== undefined) {
          const siteAssetCount =
            inverters?.types?.reduce((_siteAssetCount, current) => {
              if (current.type === SiteType.STORAGE) _siteAssetCount += current.typeCount;
              return _siteAssetCount;
            }, 0) ?? 0;
          return count + siteAssetCount;
        } else {
          return count;
        }
      }, 0);
    },
  ),
  sortedSavedViews: computed([(state) => state.savedViews], (savedViews) =>
    (savedViews ?? []).sort((a, b) => a.name?.localeCompare(b.name)),
  ),
  getViewSiteIds: computed([(state) => state.currentView], (currentView) =>
    memoize(1)(() => currentView?.sites?.flatMap((v) => v?.id) ?? []),
  ),
};

// Compile the view store object for export
const viewModel = {
  ...defaultViewState,
  ...viewActions,
  ...viewComputed,
};

export default viewModel;
