import styled from 'styled-components';

export const KpiChartSelectorStyled = styled.div.attrs({
  className: 'KpiChartSelector',
})`
  display: flex;
  .CustomDropdown {
    margin-right: 10px;
  }
  flex: 1 80%;
  padding: 10px 0 6px 0;
  .CustomDropdown {
    :not(:first-child) {
      .select__indicator svg {
        fill: ${({ theme }) => theme.customDropdown.icon};
      }
      .select__control {
        background: ${({ theme }) => theme.customDropdown.backgroundColor};
        border: 1px solid ${({ theme }) => theme.customDropdown.border};
        color: ${({ theme }) => theme.customDropdown.color};
      }
      .css-1pahdxg-control {
        background: ${({ theme }) => theme.customDropdown.backgroundColor};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.customDropdown.backgroundColor};
      }
    }
  }
`;
