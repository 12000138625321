import { TableFilterTypes, Capability, PermissionScope } from '@ge/models/constants';

export const EventManagementColumns = {
  GROUP_EVENTS: 'group-events',
  EVENT_MAP: 'event-map',
  EVENT_CODE: 'code',
  EVENT_NAME: 'name',
  GROUP_SITE: 'group-site',
  INCLUDED_SITES: 'includedsites',
  EXCLUDED_SITES: 'excludedsites',
  GROUP_ASSET: 'group-asset',
  MAX_REST_LIMIT: 'maxResetLimit',
  FLAGGED_STATES: 'flaggedStates',
  BLOCK_AUTOMATION: 'blockAutomation',
  BLOCK_ALL_COMMANDS: 'blockCommands',
  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'eventRemarks',
  GROUP_DETAIL: 'group-detail',
  EVENT_DETAIL: 'event-detail',
};

export const EventManagementColumnsFiltersArrayType = [
  EventManagementColumns.INCLUDED_SITES,
  EventManagementColumns.EXCLUDED_SITES,
];

// Define all available columns in the events table.
export const EventManagementColumnsDefs = {
  [EventManagementColumns.GROUP_EVENTS]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'EVENTS',
      },
    ],
    cols: {
      [EventManagementColumns.EVENT_CODE]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '50px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Event Code',
            sortValue: 'code',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [EventManagementColumns.EVENT_NAME]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '200px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Event Name',
            sortValue: 'name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventManagementColumns.GROUP_SITE]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'SITES',
      },
    ],
    cols: {
      [EventManagementColumns.INCLUDED_SITES]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '150px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Included\nSites',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [EventManagementColumns.EXCLUDED_SITES]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '150px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Excluded\nSites',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventManagementColumns.GROUP_ASSET]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'ASSET',
      },
    ],
    cols: {
      [EventManagementColumns.MAX_REST_LIMIT]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '70px',
        align: 'left',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Max Reset Limit',
            sortValue: 'commandProperties.maxResetLimit',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [EventManagementColumns.FLAGGED_STATES]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '210px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Flagged States',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [EventManagementColumns.BLOCK_AUTOMATION]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '50px',
        align: 'center',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Block Automation',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
      [EventManagementColumns.BLOCK_ALL_COMMANDS]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '50px',
        align: 'center',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Block All Commands',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventManagementColumns.GROUP_DESCRIPTION]: {
    cols: {
      [EventManagementColumns.DESCRIPTION]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '300px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Description',
            sortValue: 'eventRemarks',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [EventManagementColumns.GROUP_DETAIL]: {
    fixedRight: true,
    cols: {
      [EventManagementColumns.EVENT_DETAIL]: {
        locked: true,
        width: '10px',
        align: 'center',
        whiteSpace: 'wrap',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Event Detail',
          },
        ],
      },
    },
  },
};

// Define the default visible columns and their order.
export const defaultEventManagementColumns = [
  {
    id: EventManagementColumns.GROUP_EVENTS,
    cols: [
      {
        id: EventManagementColumns.EVENT_MAP,
        visible: true,
      },
      {
        id: EventManagementColumns.EVENT_CODE,
        visible: true,
      },
      {
        id: EventManagementColumns.EVENT_NAME,
        visible: true,
      },
    ],
  },
  {
    id: EventManagementColumns.GROUP_SITE,
    cols: [
      {
        id: EventManagementColumns.INCLUDED_SITES,
        visible: true,
      },
      {
        id: EventManagementColumns.EXCLUDED_SITES,
        visible: true,
      },
    ],
  },
  {
    id: EventManagementColumns.GROUP_ASSET,
    cols: [
      {
        id: EventManagementColumns.MAX_REST_LIMIT,
        visible: true,
      },
      {
        id: EventManagementColumns.FLAGGED_STATES,
        visible: true,
      },
      {
        id: EventManagementColumns.BLOCK_AUTOMATION,
        visible: true,
      },
      {
        id: EventManagementColumns.BLOCK_ALL_COMMANDS,
        visible: true,
      },
    ],
  },
  {
    id: EventManagementColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: EventManagementColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: EventManagementColumns.GROUP_DETAIL,
    cols: [
      {
        id: EventManagementColumns.EVENT_DETAIL,
        visible: true,
      },
    ],
  },
];
