const mergeDeepWith = require('ramda/src/mergeDeepWith');

const generateIncrements = ({ total, amount }) => {
  const roundedTotal = Math.ceil(total);
  const base = roundedTotal / amount;

  const gridValues = [];

  for (let i = 1; i <= amount; i++) {
    gridValues.push(base === 0 ? 1 : base * (i + 1));
  }

  return gridValues;
};

// gets grid increment based on the desired number of grid lines (gridLineAmount)
// and the standard grid increment (increments) which expands into a series by order of magnitude
const getGridIncrement = ({
  gridLineAmount = 5,
  increments = [1, 2, 2.5, 5],
  total = 1000,
  dynamicScaling = false,
}) => {
  const _increments = dynamicScaling ? generateIncrements({ total, amount: 8 }) : increments;
  const interval = total / gridLineAmount;
  const max = Math.max(..._increments);
  const min = Math.min(..._increments);
  // if there is a relatively large gap between max and next magnitude min
  // then set max to the difference to avoid bias toward larger interval
  // can make this better
  // could also pad min if needed
  const paddedMax = (10 * min + max) / 2;

  if (interval <= min) {
    return min;
  }

  if (min < interval && interval < paddedMax) {
    for (const increment of _increments) {
      // attempt snapping to the closest standard interval
      const index = Math.round(interval / increment);

      if (index === 1) {
        return increment;
      }
    }
  }

  // increase magnitude and try again
  return getGridIncrement({
    gridLineAmount,
    increments: _increments.map((increment) => increment * 10),
    total,
  });
};

const mergeOptionsRight = (...options) =>
  options.reduce((left, right) => mergeDeepWith(resolveMergedOptions, left, right), {});

// resolves merge conflicts in options
const resolveMergedOptions = (left, right) => {
  // reductive case
  if (right instanceof Array) {
    // we assume that options to the left are only defined for the first element (static config)
    // and rightmost options are based on incoming data so can contain more than one element
    // should probably revisit this assumption
    // would left ever *not* be an array if right is?
    const leftOptions = left instanceof Array ? left[0] : [];

    return [
      ...right.map((rightOptions) =>
        mergeDeepWith(resolveMergedOptions, leftOptions, rightOptions),
      ),
    ];
  }

  // base case
  // revisit this, but if no value specified to the right (undefined), then go with left
  // to explicitly remove value to the left, set right to null
  return typeof right === 'undefined' ? left : right;
};

const selectBy = ({
  accumulate = false,
  chart = {},
  predicate = () => false,
  seriesIndex = null,
  assetId = null,
  skipPointSelection = false,
}) => {
  let { series = [] } = chart;

  if (assetId) {
    series = series.filter((s) => s.options.custom.assetId === assetId);
  }

  if (!series.length) {
    return [];
  }

  if (!skipPointSelection) {
    const selectedPoints = chart.getSelectedPoints();

    // clear current selection if accumulate false
    !accumulate && selectedPoints.forEach((point) => point.select(false));
  }

  let points = [];
  // iterate over all series

  const filterAndSelectPoints = (seriesItem) => {
    const seriesPoints = skipPointSelection ? seriesItem.options.data : seriesItem.points;
    const filteredPoints = seriesPoints.filter(predicate);
    if (!skipPointSelection) {
      filteredPoints.forEach((point) => point.select(true, true));
    }
    points.push(...filteredPoints);
  };

  if (!seriesIndex) {
    // get points for all series
    series.forEach((seriesItem) => filterAndSelectPoints(seriesItem));
  } else {
    // get points for series by index
    const seriesItem = series[seriesIndex];
    filterAndSelectPoints(seriesItem);
  }

  return points;
};

module.exports = {
  getGridIncrement,
  mergeOptionsRight,
  resolveMergedOptions,
  selectBy,
};
