import { globalColors } from '@ge/tokens/colors/colors';

export const maLegendValues = {
  'iec-1': [
    {
      category: 'IAOGFP',
      a11yKey: 'full_perf',
      color: globalColors.pine2,
      primary: true,
    },
    {
      category: 'IAOGPPE',
      a11yKey: 'partial_perf_ext',
      color: globalColors.violet2,
      primary: true,
    },
    {
      category: 'IAOGPP',
      a11yKey: 'partial_perf',
      color: globalColors.violet2,
    },
    {
      category: 'IAOGPPI',
      a11yKey: 'partial_perf_int',
      color: globalColors.violet4,
      primary: true,
    },
    {
      category: 'IAOGPPCX',
      a11yKey: 'partial_perf_set',
      color: globalColors.violet2,
    },
    {
      category: 'IAONGTS',
      a11yKey: 'tech_standby',
      color: globalColors.pine4,
      primary: true,
    },
    {
      category: 'IAONGEN',
      a11yKey: 'environmental',
      color: globalColors.blue2,
      primary: true,
    },
    {
      category: 'IAONGENC',
      a11yKey: 'env_calm_winds',
      color: globalColors.blue2,
    },
    {
      category: 'IAONGENO',
      a11yKey: 'env_other',
      color: globalColors.blue2,
    },
    {
      category: 'IAONGENS',
      a11yKey: 'env_spec_storm',
      color: globalColors.blue2,
    },
    {
      category: 'IAONGRS',
      a11yKey: 'requested_shutdown',
      color: globalColors.straw3,
      primary: true,
    },
    {
      category: 'IAONGRSE',
      a11yKey: 'non_generating',
      color: globalColors.straw3,
    },
    {
      category: 'IAONGEL',
      a11yKey: 'electrical',
      color: globalColors.blue4,
      primary: true,
    },
    {
      category: 'IANOSM',
      a11yKey: 'scheduled_maintenance',
      color: globalColors.terracotta5,
      primary: true,
    },
    {
      category: 'IANOPCA',
      a11yKey: 'planned_corrective_action',
      color: globalColors.terracotta3,
      primary: true,
    },
    {
      category: 'IANOFO',
      a11yKey: 'forced_outage',
      color: globalColors.red2,
      primary: true,
    },
    {
      category: 'IANOS',
      a11yKey: 'suspended',
      color: globalColors.orange1,
      primary: true,
    },
    {
      category: 'IAFM',
      a11yKey: 'force_majeure',
      color: globalColors.stone3,
      primary: true,
    },
    {
      category: 'IU',
      a11yKey: 'info_unavailable',
      color: globalColors.stone2,
      primary: true,
    },
  ],
  'counters-1': [
    {
      category: 'SOT',
      a11yKey: 'system_ok_time',
      color: globalColors.pine2,
      primary: true,
    },
    {
      category: 'WOT',
      a11yKey: 'weather_out_time',
      color: globalColors.blue2,
      primary: true,
    },
    {
      category: 'EECT',
      a11yKey: 'external_energy_curtailment_time',
      color: globalColors.magenta3,
      primary: true,
    },
    {
      category: 'EST',
      a11yKey: 'external_stop_time',
      color: globalColors.straw2,
      primary: true,
    },
    {
      category: 'WDCT',
      a11yKey: 'wind_direction_curtailment_time',
      color: globalColors.lavender3,
      primary: true,
    },
    {
      category: 'CST',
      a11yKey: 'customer_stop_time',
      color: globalColors.straw4,
      primary: true,
    },
    {
      category: 'LOT',
      a11yKey: 'line_out_time',
      color: globalColors.blue4,
      primary: true,
    },
    {
      category: 'MT',
      a11yKey: 'maintenance_time',
      color: globalColors.terracotta5,
      primary: true,
    },
    {
      category: 'RT',
      a11yKey: 'repair_time',
      color: globalColors.terracotta3,
      primary: true,
    },
    {
      category: 'DT',
      a11yKey: 'down_time',
      color: globalColors.red2,
      primary: true,
    },
    {
      category: 'IU',
      a11yKey: 'info_unavailable',
      color: globalColors.stone2,
      primary: true,
    },
  ],
  'counters-2': [
    {
      category: 'SOT',
      a11yKey: 'system_ok_time',
      color: globalColors.pine2,
      primary: true,
    },
    {
      category: 'WOT',
      a11yKey: 'weather_out_time',
      color: globalColors.blue2,
      primary: true,
    },
    {
      category: 'EECT',
      a11yKey: 'external_energy_curtailment_time',
      color: globalColors.magenta3,
      primary: true,
    },
    {
      category: 'EST',
      a11yKey: 'external_stop_time',
      color: globalColors.straw2,
      primary: true,
    },
    {
      category: 'WDCT',
      a11yKey: 'wind_direction_curtailment_time',
      color: globalColors.lavender3,
      primary: true,
    },
    {
      category: 'CST',
      a11yKey: 'customer_stop_time',
      color: globalColors.straw4,
      primary: true,
    },
    {
      category: 'LOT',
      a11yKey: 'line_out_time',
      color: globalColors.blue4,
      primary: true,
    },
    {
      category: 'MT',
      a11yKey: 'maintenance_time',
      color: globalColors.terracotta5,
      primary: true,
    },
    {
      category: 'RT',
      a11yKey: 'repair_time',
      color: globalColors.terracotta3,
      primary: true,
    },
    {
      category: 'DT',
      a11yKey: 'down_time',
      color: globalColors.red2,
      primary: true,
    },
    {
      category: 'INT_CDOT',
      a11yKey: 'internal_curtailed_derated_operating_time',
      color: globalColors.violet4,
      primary: true,
    },
    {
      category: 'IU',
      a11yKey: 'info_unavailable',
      color: globalColors.stone2,
      primary: true,
    },
  ],
};
