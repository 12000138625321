export const PowerCurveData = [
  [
    {
      x: 6.81,
      y: 0.65,
    },
    {
      x: 6.5,
      y: 0.64,
    },
    {
      x: 6.58,
      y: 0.66,
    },
    {
      x: 6.62,
      y: 0.66,
    },
    {
      x: 6.67,
      y: 0.64,
    },
    {
      x: 6.62,
      y: 0.62,
    },
    {
      x: 6.95,
      y: 0.61,
    },
    {
      x: 7.57,
      y: 0.65,
    },
    {
      x: 7.13,
      y: 0.66,
    },
    {
      x: 6.85,
      y: 0.72,
    },
    {
      x: 7.14,
      y: 0.79,
    },
    {
      x: 7.25,
      y: 0.74,
    },
    {
      x: 6.94,
      y: 0.8,
    },
    {
      x: 7.5,
      y: 0.71,
    },
    {
      x: 7.33,
      y: 0.77,
    },
    {
      x: 7.37,
      y: 0.76,
    },
    {
      x: 7.05,
      y: 0.73,
    },
    {
      x: 7.86,
      y: 0.81,
    },
    {
      x: 8.37,
      y: 0.83,
    },
    {
      x: 7.32,
      y: 0.84,
    },
    {
      x: 8.36,
      y: 0.88,
    },
    {
      x: 8.38,
      y: 0.82,
    },
    {
      x: 8.35,
      y: 0.81,
    },
    {
      x: 7.59,
      y: 0.84,
    },
    {
      x: 8.31,
      y: 0.85,
    },
    {
      x: 8.37,
      y: 0.9,
    },
    {
      x: 8.12,
      y: 0.88,
    },
    {
      x: 7.64,
      y: 0.89,
    },
    {
      x: 7.57,
      y: 0.84,
    },
    {
      x: 7.17,
      y: 0.85,
    },
    {
      x: 8.33,
      y: 0.89,
    },
    {
      x: 8.28,
      y: 0.9,
    },
    {
      x: 7.31,
      y: 0.83,
    },
    {
      x: 8.61,
      y: 0.93,
    },
    {
      x: 8.45,
      y: 1.0,
    },
    {
      x: 7.48,
      y: 0.96,
    },
    {
      x: 7.88,
      y: 0.95,
    },
    {
      x: 8.48,
      y: 0.99,
    },
    {
      x: 7.52,
      y: 0.95,
    },
    {
      x: 7.92,
      y: 0.99,
    },
    {
      x: 7.73,
      y: 1.0,
    },
    {
      x: 8.08,
      y: 0.97,
    },
    {
      x: 8.61,
      y: 0.99,
    },
    {
      x: 7.73,
      y: 0.99,
    },
    {
      x: 8.2,
      y: 0.97,
    },
    {
      x: 8.67,
      y: 1.0,
    },
    {
      x: 7.69,
      y: 0.98,
    },
    {
      x: 7.72,
      y: 0.94,
    },
    {
      x: 7.95,
      y: 0.95,
    },
    {
      x: 8.36,
      y: 0.94,
    },
    {
      x: 7.61,
      y: 0.94,
    },
    {
      x: 8.46,
      y: 0.94,
    },
    {
      x: 8.13,
      y: 0.94,
    },
    {
      x: 7.36,
      y: 0.95,
    },
    {
      x: 7.47,
      y: 0.98,
    },
    {
      x: 8.21,
      y: 1.05,
    },
    {
      x: 8.33,
      y: 1.04,
    },
    {
      x: 7.68,
      y: 1.05,
    },
    {
      x: 8.2,
      y: 1.04,
    },
    {
      x: 7.77,
      y: 1.08,
    },
    {
      x: 8.68,
      y: 1.08,
    },
    {
      x: 8.62,
      y: 1.06,
    },
    {
      x: 7.83,
      y: 1.03,
    },
    {
      x: 7.77,
      y: 1.04,
    },
    {
      x: 8.61,
      y: 1.07,
    },
    {
      x: 7.99,
      y: 1.09,
    },
    {
      x: 8.13,
      y: 1.07,
    },
    {
      x: 8.84,
      y: 1.03,
    },
    {
      x: 7.4,
      y: 1.02,
    },
    {
      x: 7.65,
      y: 1.08,
    },
    {
      x: 8.24,
      y: 1.05,
    },
    {
      x: 8.01,
      y: 1.06,
    },
    {
      x: 7.6,
      y: 1.03,
    },
    {
      x: 8.38,
      y: 1.07,
    },
    {
      x: 8.76,
      y: 1.06,
    },
    {
      x: 8.7,
      y: 1.07,
    },
    {
      x: 7.83,
      y: 1.07,
    },
    {
      x: 8.28,
      y: 1.18,
    },
    {
      x: 8.86,
      y: 1.17,
    },
    {
      x: 8.84,
      y: 1.2,
    },
    {
      x: 7.9,
      y: 1.15,
    },
    {
      x: 9.18,
      y: 1.14,
    },
    {
      x: 9.16,
      y: 1.18,
    },
    {
      x: 8.84,
      y: 1.19,
    },
    {
      x: 8.24,
      y: 1.18,
    },
    {
      x: 8.91,
      y: 1.14,
    },
    {
      x: 6.98,
      y: 0.71,
    },
    {
      x: 7.47,
      y: 0.8,
    },
    {
      x: 7.84,
      y: 0.71,
    },
    {
      x: 7.67,
      y: 0.77,
    },
    {
      x: 7.93,
      y: 0.7,
    },
    {
      x: 7.97,
      y: 0.77,
    },
    {
      x: 6.97,
      y: 0.77,
    },
    {
      x: 7.14,
      y: 0.73,
    },
    {
      x: 1.42,
      y: 0.13,
    },
    {
      x: 2.87,
      y: 0.14,
    },
    {
      x: 2.77,
      y: 0.15,
    },
    {
      x: 0.99,
      y: 0.2,
    },
    {
      x: 0.71,
      y: 0.19,
    },
    {
      x: 0.94,
      y: 0.16,
    },
    {
      x: 1.91,
      y: 0.2,
    },
    {
      x: 1.03,
      y: 0.16,
    },
    {
      x: 0.79,
      y: 0.15,
    },
    {
      x: 0.58,
      y: 0.12,
    },
    {
      x: 2.86,
      y: 0.13,
    },
    {
      x: 2.49,
      y: 0.17,
    },
    {
      x: 2.21,
      y: 0.12,
    },
    {
      x: 1.5,
      y: 0.16,
    },
    {
      x: 1.06,
      y: 0.18,
    },
    {
      x: 1.65,
      y: 0.14,
    },
    {
      x: 1.81,
      y: 0.14,
    },
    {
      x: 2.43,
      y: 0.15,
    },
    {
      x: 1.72,
      y: 0.14,
    },
    {
      x: 4.14,
      y: 0.24,
    },
    {
      x: 4.97,
      y: 0.21,
    },
    {
      x: 5.95,
      y: 0.29,
    },
    {
      x: 5.15,
      y: 0.3,
    },
    {
      x: 5.7,
      y: 0.2,
    },
    {
      x: 5.65,
      y: 0.25,
    },
    {
      x: 2.03,
      y: 0.3,
    },
    {
      x: 4.23,
      y: 0.22,
    },
    {
      x: 3.52,
      y: 0.23,
    },
    {
      x: 3.22,
      y: 0.26,
    },
    {
      x: 5.79,
      y: 0.24,
    },
    {
      x: 3.06,
      y: 0.24,
    },
    {
      x: 3.93,
      y: 0.26,
    },
    {
      x: 4.91,
      y: 0.3,
    },
    {
      x: 3.79,
      y: 0.22,
    },
    {
      x: 5.84,
      y: 0.3,
    },
    {
      x: 5.27,
      y: 0.28,
    },
    {
      x: 5.04,
      y: 0.24,
    },
    {
      x: 3.98,
      y: 0.29,
    },
    {
      x: 3.61,
      y: 0.3,
    },
    {
      x: 4.81,
      y: 0.21,
    },
    {
      x: 4.59,
      y: 0.29,
    },
    {
      x: 4.46,
      y: 0.26,
    },
    {
      x: 4.28,
      y: 0.25,
    },
    {
      x: 4.25,
      y: 0.24,
    },
  ],
  [
    {
      x: 4.11,
      y: 0.25,
    },
    {
      x: 4.56,
      y: 0.27,
    },
    {
      x: 4.68,
      y: 0.23,
    },
    {
      x: 4.17,
      y: 0.24,
    },
    {
      x: 4.38,
      y: 0.23,
    },
    {
      x: 4.66,
      y: 0.39,
    },
    {
      x: 4.18,
      y: 0.3,
    },
    {
      x: 4.15,
      y: 0.37,
    },
    {
      x: 4.5,
      y: 0.34,
    },
    {
      x: 4.59,
      y: 0.37,
    },
    {
      x: 4.5,
      y: 0.37,
    },
    {
      x: 4.69,
      y: 0.26,
    },
    {
      x: 4.7,
      y: 0.27,
    },
    {
      x: 3.42,
      y: 0.23,
    },
    {
      x: 4.9,
      y: 0.24,
    },
    {
      x: 4.88,
      y: 0.27,
    },
    {
      x: 3.66,
      y: 0.21,
    },
    {
      x: 4.14,
      y: 0.21,
    },
    {
      x: 3.05,
      y: 0.24,
    },
    {
      x: 3.49,
      y: 0.24,
    },
    {
      x: 3.86,
      y: 0.22,
    },
    {
      x: 4.43,
      y: 0.37,
    },
    {
      x: 4.78,
      y: 0.3,
    },
    {
      x: 4.55,
      y: 0.31,
    },
    {
      x: 4.6,
      y: 0.32,
    },
    {
      x: 7.44,
      y: 0.53,
    },
    {
      x: 6.48,
      y: 0.54,
    },
    {
      x: 7.08,
      y: 0.5,
    },
    {
      x: 6.18,
      y: 0.55,
    },
    {
      x: 6.18,
      y: 0.59,
    },
    {
      x: 6.59,
      y: 0.54,
    },
    {
      x: 6.67,
      y: 0.54,
    },
    {
      x: 7.13,
      y: 0.51,
    },
    {
      x: 7.48,
      y: 0.53,
    },
    {
      x: 6.39,
      y: 0.51,
    },
    {
      x: 6.19,
      y: 0.52,
    },
    {
      x: 6.81,
      y: 0.56,
    },
    {
      x: 7.01,
      y: 0.55,
    },
    {
      x: 6.68,
      y: 0.52,
    },
    {
      x: 6.13,
      y: 0.59,
    },
    {
      x: 6.87,
      y: 0.6,
    },
    {
      x: 7.25,
      y: 0.72,
    },
    {
      x: 7.44,
      y: 0.79,
    },
    {
      x: 7.52,
      y: 0.74,
    },
    {
      x: 7.5,
      y: 0.71,
    },
    {
      x: 7.65,
      y: 0.73,
    },
    {
      x: 7.55,
      y: 0.77,
    },
    {
      x: 7.43,
      y: 0.79,
    },
    {
      x: 7.41,
      y: 0.74,
    },
    {
      x: 7.42,
      y: 0.72,
    },
    {
      x: 7.46,
      y: 0.77,
    },
    {
      x: 7.11,
      y: 0.73,
    },
    {
      x: 7.8,
      y: 0.71,
    },
    {
      x: 7.48,
      y: 0.7,
    },
    {
      x: 6.82,
      y: 0.73,
    },
    {
      x: 7.46,
      y: 0.71,
    },
    {
      x: 7.32,
      y: 0.77,
    },
    {
      x: 6.91,
      y: 0.52,
    },
    {
      x: 6.83,
      y: 0.54,
    },
    {
      x: 6.51,
      y: 0.59,
    },
    {
      x: 6.62,
      y: 0.59,
    },
    {
      x: 6.23,
      y: 0.57,
    },
    {
      x: 6.84,
      y: 0.5,
    },
    {
      x: 6.35,
      y: 0.55,
    },
    {
      x: 6.36,
      y: 0.55,
    },
    {
      x: 6.5,
      y: 0.6,
    },
    {
      x: 6.75,
      y: 0.52,
    },
    {
      x: 7.18,
      y: 0.54,
    },
    {
      x: 6.76,
      y: 0.55,
    },
    {
      x: 6.48,
      y: 0.51,
    },
    {
      x: 6.54,
      y: 0.54,
    },
    {
      x: 7.16,
      y: 0.52,
    },
    {
      x: 6.73,
      y: 0.53,
    },
    {
      x: 7.24,
      y: 0.66,
    },
    {
      x: 6.94,
      y: 0.68,
    },
    {
      x: 6.98,
      y: 0.62,
    },
    {
      x: 7.67,
      y: 0.61,
    },
    {
      x: 6.51,
      y: 0.6,
    },
    {
      x: 7.12,
      y: 0.61,
    },
    {
      x: 6.89,
      y: 0.63,
    },
    {
      x: 8.99,
      y: 1.16,
    },
    {
      x: 8.64,
      y: 1.18,
    },
    {
      x: 8.72,
      y: 1.13,
    },
    {
      x: 8.93,
      y: 1.16,
    },
    {
      x: 8.75,
      y: 1.2,
    },
    {
      x: 8.29,
      y: 1.17,
    },
    {
      x: 9.15,
      y: 1.15,
    },
    {
      x: 8.79,
      y: 1.1,
    },
    {
      x: 8.76,
      y: 1.19,
    },
    {
      x: 8.7,
      y: 1.15,
    },
    {
      x: 9.01,
      y: 1.17,
    },
    {
      x: 8.39,
      y: 1.17,
    },
    {
      x: 8.71,
      y: 1.15,
    },
    {
      x: 9.47,
      y: 1.23,
    },
    {
      x: 8.91,
      y: 1.26,
    },
    {
      x: 8.98,
      y: 1.22,
    },
    {
      x: 8.36,
      y: 1.28,
    },
    {
      x: 8.47,
      y: 1.29,
    },
    {
      x: 8.56,
      y: 1.23,
    },
    {
      x: 9.43,
      y: 1.28,
    },
    {
      x: 8.95,
      y: 1.21,
    },
    {
      x: 8.1,
      y: 1.22,
    },
    {
      x: 8.18,
      y: 1.25,
    },
    {
      x: 9.03,
      y: 1.25,
    },
    {
      x: 8.85,
      y: 1.26,
    },
    {
      x: 9.14,
      y: 1.27,
    },
    {
      x: 6.28,
      y: 0.44,
    },
    {
      x: 5.27,
      y: 0.42,
    },
    {
      x: 6.1,
      y: 0.46,
    },
    {
      x: 6.76,
      y: 0.42,
    },
    {
      x: 5.32,
      y: 0.46,
    },
    {
      x: 5.93,
      y: 0.4,
    },
    {
      x: 6.85,
      y: 0.66,
    },
    {
      x: 7.87,
      y: 0.63,
    },
    {
      x: 7.34,
      y: 0.64,
    },
    {
      x: 6.61,
      y: 0.65,
    },
    {
      x: 6.99,
      y: 0.61,
    },
    {
      x: 7.12,
      y: 0.64,
    },
    {
      x: 7.17,
      y: 0.67,
    },
    {
      x: 7.21,
      y: 0.63,
    },
    {
      x: 6.66,
      y: 0.6,
    },
    {
      x: 7.31,
      y: 0.7,
    },
    {
      x: 6.74,
      y: 0.66,
    },
    {
      x: 7.05,
      y: 0.66,
    },
    {
      x: 7.8,
      y: 0.64,
    },
    {
      x: 7.96,
      y: 0.6,
    },
    {
      x: 6.77,
      y: 0.7,
    },
    {
      x: 6.76,
      y: 0.63,
    },
    {
      x: 6.62,
      y: 0.61,
    },
    {
      x: 7.6,
      y: 0.63,
    },
    {
      x: 7.67,
      y: 0.04,
    },
    {
      x: 8.26,
      y: 0.82,
    },
    {
      x: 11.57,
      y: 0.54,
    },
    {
      x: 7.81,
      y: 0.66,
    },
    {
      x: 11.89,
      y: 0.46,
    },
    {
      x: 7.91,
      y: 0.29,
    },
    {
      x: 14.4,
      y: 0.89,
    },
    {
      x: 6.28,
      y: 0.41,
    },
    {
      x: 6.54,
      y: 0.48,
    },
    {
      x: 6.2,
      y: 0.38,
    },
    {
      x: 6.69,
      y: 0.33,
    },
    {
      x: 6.05,
      y: 0.37,
    },
    {
      x: 6.85,
      y: 0.37,
    },
    {
      x: 6.19,
      y: 0.31,
    },
    {
      x: 6.81,
      y: 0.39,
    },
    {
      x: 6.87,
      y: 0.48,
    },
    {
      x: 6.95,
      y: 0.45,
    },
    {
      x: 6.98,
      y: 0.3,
    },
    {
      x: 6.37,
      y: 0.38,
    },
    {
      x: 6.4,
      y: 0.46,
    },
    {
      x: 6.87,
      y: 0.33,
    },
    {
      x: 6.72,
      y: 0.41,
    },
    {
      x: 6.12,
      y: 0.34,
    },
    {
      x: 6.01,
      y: 0.35,
    },
    {
      x: 6.47,
      y: 0.37,
    },
    {
      x: 6.37,
      y: 0.42,
    },
    {
      x: 6.99,
      y: 0.43,
    },
    {
      x: 10.79,
      y: 1.36,
    },
    {
      x: 9.83,
      y: 1.34,
    },
    {
      x: 10.2,
      y: 1.33,
    },
    {
      x: 10.51,
      y: 1.4,
    },
    {
      x: 10.92,
      y: 1.32,
    },
    {
      x: 10.03,
      y: 1.38,
    },
    {
      x: 10.71,
      y: 1.35,
    },
    {
      x: 10.22,
      y: 1.38,
    },
    {
      x: 9.82,
      y: 1.34,
    },
    {
      x: 10.02,
      y: 1.4,
    },
    {
      x: 10.84,
      y: 1.4,
    },
    {
      x: 9.86,
      y: 1.36,
    },
    {
      x: 9.69,
      y: 1.3,
    },
    {
      x: 10.67,
      y: 1.37,
    },
    {
      x: 10.31,
      y: 1.35,
    },
    {
      x: 9.76,
      y: 1.4,
    },
    {
      x: 10.76,
      y: 1.31,
    },
    {
      x: 10.77,
      y: 1.3,
    },
    {
      x: 9.76,
      y: 1.3,
    },
    {
      x: 10.07,
      y: 1.31,
    },
    {
      x: 7.31,
      y: 0.87,
    },
    {
      x: 7.36,
      y: 0.88,
    },
    {
      x: 7.32,
      y: 0.87,
    },
    {
      x: 7.22,
      y: 0.88,
    },
    {
      x: 7.33,
      y: 0.9,
    },
    {
      x: 7.36,
      y: 0.86,
    },
    {
      x: 7.34,
      y: 0.86,
    },
    {
      x: 7.33,
      y: 0.88,
    },
    {
      x: 7.3,
      y: 0.9,
    },
    {
      x: 7.26,
      y: 0.89,
    },
    {
      x: 7.31,
      y: 0.88,
    },
    {
      x: 7.35,
      y: 0.91,
    },
    {
      x: 7.36,
      y: 0.89,
    },
    {
      x: 7.34,
      y: 0.87,
    },
    {
      x: 7.29,
      y: 0.88,
    },
    {
      x: 7.37,
      y: 0.87,
    },
    {
      x: 7.28,
      y: 0.9,
    },
    {
      x: 7.22,
      y: 0.88,
    },
    {
      x: 7.3,
      y: 0.88,
    },
    {
      x: 7.33,
      y: 0.91,
    },
    {
      x: 7.36,
      y: 0.88,
    },
    {
      x: 7.24,
      y: 0.89,
    },
    {
      x: 7.27,
      y: 0.89,
    },
    {
      x: 7.28,
      y: 0.91,
    },
    {
      x: 7.28,
      y: 0.88,
    },
    {
      x: 7.32,
      y: 0.89,
    },
    {
      x: 7.34,
      y: 0.89,
    },
    {
      x: 7.22,
      y: 0.87,
    },
    {
      x: 7.37,
      y: 0.89,
    },
    {
      x: 7.26,
      y: 0.87,
    },
    {
      x: 8.05,
      y: 0.9,
    },
    {
      x: 8.3,
      y: 0.88,
    },
    {
      x: 8.19,
      y: 0.9,
    },
    {
      x: 7.83,
      y: 0.91,
    },
    {
      x: 7.78,
      y: 0.88,
    },
    {
      x: 7.81,
      y: 0.89,
    },
    {
      x: 8.11,
      y: 0.89,
    },
    {
      x: 8.24,
      y: 0.91,
    },
    {
      x: 7.67,
      y: 0.89,
    },
    {
      x: 7.88,
      y: 0.9,
    },
    {
      x: 8.28,
      y: 0.91,
    },
    {
      x: 8.32,
      y: 0.9,
    },
    {
      x: 8.24,
      y: 0.91,
    },
    {
      x: 8.22,
      y: 0.91,
    },
    {
      x: 7.86,
      y: 0.9,
    },
    {
      x: 8.0,
      y: 0.9,
    },
    {
      x: 7.9,
      y: 0.88,
    },
    {
      x: 8.32,
      y: 0.88,
    },
    {
      x: 8.19,
      y: 0.89,
    },
    {
      x: 8.1,
      y: 0.9,
    },
    {
      x: 7.74,
      y: 0.89,
    },
    {
      x: 8.08,
      y: 0.9,
    },
    {
      x: 8.24,
      y: 0.88,
    },
    {
      x: 8.09,
      y: 0.9,
    },
    {
      x: 7.8,
      y: 0.89,
    },
    {
      x: 7.92,
      y: 0.86,
    },
    {
      x: 8.04,
      y: 0.9,
    },
    {
      x: 8.27,
      y: 0.89,
    },
    {
      x: 7.87,
      y: 0.88,
    },
    {
      x: 7.68,
      y: 0.9,
    },
  ],
  [
    {
      x: 11.27,
      y: 1.47,
    },
    {
      x: 10.0,
      y: 1.48,
    },
    {
      x: 10.11,
      y: 1.42,
    },
    {
      x: 10.93,
      y: 1.46,
    },
    {
      x: 10.42,
      y: 1.41,
    },
    {
      x: 11.44,
      y: 1.41,
    },
    {
      x: 9.53,
      y: 1.5,
    },
    {
      x: 10.26,
      y: 1.41,
    },
    {
      x: 9.51,
      y: 1.42,
    },
    {
      x: 11.46,
      y: 1.47,
    },
    {
      x: 10.26,
      y: 1.44,
    },
    {
      x: 9.12,
      y: 1.41,
    },
    {
      x: 11.11,
      y: 1.46,
    },
    {
      x: 10.75,
      y: 1.5,
    },
    {
      x: 10.03,
      y: 1.4,
    },
    {
      x: 9.82,
      y: 1.41,
    },
    {
      x: 9.43,
      y: 1.47,
    },
    {
      x: 10.95,
      y: 1.46,
    },
    {
      x: 10.47,
      y: 1.47,
    },
    {
      x: 10.1,
      y: 1.44,
    },
    {
      x: 9.8,
      y: 1.43,
    },
    {
      x: 11.19,
      y: 1.47,
    },
    {
      x: 10.15,
      y: 1.41,
    },
    {
      x: 9.68,
      y: 1.43,
    },
    {
      x: 10.7,
      y: 1.54,
    },
    {
      x: 11.12,
      y: 1.45,
    },
    {
      x: 9.77,
      y: 1.48,
    },
    {
      x: 10.38,
      y: 1.53,
    },
    {
      x: 10.21,
      y: 1.51,
    },
    {
      x: 10.15,
      y: 1.47,
    },
    {
      x: 10.62,
      y: 1.48,
    },
    {
      x: 10.65,
      y: 1.47,
    },
    {
      x: 11.92,
      y: 1.52,
    },
    {
      x: 11.77,
      y: 1.47,
    },
    {
      x: 10.4,
      y: 1.46,
    },
    {
      x: 11.15,
      y: 1.52,
    },
    {
      x: 11.9,
      y: 1.48,
    },
    {
      x: 10.19,
      y: 1.52,
    },
    {
      x: 10.88,
      y: 1.5,
    },
    {
      x: 11.34,
      y: 1.51,
    },
    {
      x: 11.41,
      y: 1.52,
    },
    {
      x: 10.83,
      y: 1.51,
    },
    {
      x: 11.93,
      y: 1.46,
    },
    {
      x: 11.45,
      y: 1.47,
    },
    {
      x: 11.21,
      y: 1.52,
    },
    {
      x: 10.55,
      y: 1.49,
    },
    {
      x: 11.77,
      y: 1.51,
    },
    {
      x: 10.88,
      y: 1.5,
    },
    {
      x: 11.23,
      y: 1.51,
    },
    {
      x: 12.21,
      y: 1.53,
    },
    {
      x: 11.32,
      y: 1.47,
    },
    {
      x: 10.75,
      y: 1.53,
    },
    {
      x: 11.42,
      y: 1.53,
    },
    {
      x: 11.06,
      y: 1.48,
    },
    {
      x: 11.7,
      y: 1.55,
    },
    {
      x: 12.74,
      y: 1.47,
    },
    {
      x: 12.31,
      y: 1.53,
    },
    {
      x: 10.92,
      y: 1.52,
    },
    {
      x: 10.49,
      y: 1.48,
    },
    {
      x: 11.37,
      y: 1.53,
    },
    {
      x: 10.18,
      y: 1.51,
    },
    {
      x: 10.29,
      y: 1.51,
    },
    {
      x: 12.21,
      y: 1.52,
    },
    {
      x: 12.62,
      y: 1.53,
    },
    {
      x: 12.68,
      y: 1.48,
    },
    {
      x: 11.42,
      y: 1.55,
    },
    {
      x: 12.22,
      y: 1.55,
    },
    {
      x: 11.98,
      y: 1.48,
    },
    {
      x: 10.03,
      y: 1.48,
    },
    {
      x: 12.61,
      y: 1.55,
    },
    {
      x: 10.67,
      y: 1.54,
    },
    {
      x: 11.04,
      y: 1.47,
    },
    {
      x: 11.44,
      y: 1.48,
    },
    {
      x: 11.47,
      y: 1.53,
    },
    {
      x: 14.4,
      y: 1.54,
    },
    {
      x: 13.89,
      y: 1.55,
    },
    {
      x: 13.88,
      y: 1.52,
    },
    {
      x: 14.76,
      y: 1.52,
    },
    {
      x: 13.0,
      y: 1.54,
    },
    {
      x: 12.44,
      y: 1.5,
    },
    {
      x: 14.41,
      y: 1.54,
    },
    {
      x: 12.3,
      y: 1.56,
    },
    {
      x: 12.42,
      y: 1.56,
    },
    {
      x: 13.01,
      y: 1.53,
    },
    {
      x: 12.34,
      y: 1.53,
    },
    {
      x: 13.3,
      y: 1.53,
    },
    {
      x: 13.93,
      y: 1.51,
    },
    {
      x: 12.85,
      y: 1.56,
    },
    {
      x: 12.56,
      y: 1.55,
    },
    {
      x: 12.03,
      y: 1.54,
    },
    {
      x: 14.16,
      y: 1.52,
    },
    {
      x: 14.19,
      y: 1.57,
    },
    {
      x: 13.44,
      y: 1.54,
    },
    {
      x: 5.85,
      y: 0.31,
    },
    {
      x: 5.87,
      y: 0.4,
    },
    {
      x: 4.53,
      y: 0.36,
    },
    {
      x: 5.21,
      y: 0.38,
    },
    {
      x: 5.35,
      y: 0.31,
    },
  ],
  [
    {
      x: 8.86,
      y: 1.29,
    },
    {
      x: 8.88,
      y: 1.23,
    },
    {
      x: 8.98,
      y: 1.29,
    },
    {
      x: 9.5,
      y: 1.26,
    },
    {
      x: 9.0,
      y: 1.26,
    },
    {
      x: 9.23,
      y: 1.23,
    },
    {
      x: 8.76,
      y: 1.26,
    },
    {
      x: 8.29,
      y: 1.22,
    },
    {
      x: 9.23,
      y: 1.23,
    },
    {
      x: 8.69,
      y: 1.29,
    },
    {
      x: 9.19,
      y: 1.21,
    },
    {
      x: 8.91,
      y: 1.22,
    },
    {
      x: 9.07,
      y: 1.31,
    },
    {
      x: 8.58,
      y: 1.35,
    },
    {
      x: 9.97,
      y: 1.34,
    },
    {
      x: 8.6,
      y: 1.36,
    },
    {
      x: 9.56,
      y: 1.3,
    },
    {
      x: 8.99,
      y: 1.34,
    },
    {
      x: 8.99,
      y: 1.38,
    },
    {
      x: 8.54,
      y: 1.38,
    },
    {
      x: 9.02,
      y: 1.38,
    },
    {
      x: 9.21,
      y: 1.39,
    },
    {
      x: 9.73,
      y: 1.36,
    },
    {
      x: 8.69,
      y: 1.3,
    },
    {
      x: 9.9,
      y: 1.36,
    },
    {
      x: 8.86,
      y: 1.32,
    },
    {
      x: 9.12,
      y: 1.38,
    },
    {
      x: 9.88,
      y: 1.37,
    },
    {
      x: 9.12,
      y: 1.38,
    },
    {
      x: 9.6,
      y: 1.35,
    },
    {
      x: 8.71,
      y: 1.35,
    },
    {
      x: 9.62,
      y: 1.31,
    },
    {
      x: 9.61,
      y: 1.38,
    },
    {
      x: 8.69,
      y: 1.32,
    },
    {
      x: 8.8,
      y: 1.39,
    },
    {
      x: 9.13,
      y: 1.31,
    },
    {
      x: 8.69,
      y: 1.37,
    },
    {
      x: 9.04,
      y: 1.37,
    },
    {
      x: 9.07,
      y: 1.34,
    },
    {
      x: 8.74,
      y: 1.37,
    },
    {
      x: 8.88,
      y: 1.3,
    },
    {
      x: 8.89,
      y: 1.39,
    },
    {
      x: 9.38,
      y: 1.39,
    },
    {
      x: 8.82,
      y: 1.33,
    },
    {
      x: 9.21,
      y: 1.39,
    },
    {
      x: 9.22,
      y: 1.32,
    },
    {
      x: 9.4,
      y: 1.37,
    },
    {
      x: 8.81,
      y: 1.39,
    },
    {
      x: 9.35,
      y: 1.33,
    },
    {
      x: 8.77,
      y: 1.31,
    },
    {
      x: 8.72,
      y: 1.38,
    },
    {
      x: 9.01,
      y: 1.31,
    },
    {
      x: 8.89,
      y: 1.4,
    },
    {
      x: 8.78,
      y: 1.3,
    },
    {
      x: 9.42,
      y: 1.33,
    },
    {
      x: 9.31,
      y: 1.32,
    },
    {
      x: 9.01,
      y: 1.39,
    },
    {
      x: 9.19,
      y: 1.39,
    },
    {
      x: 9.21,
      y: 1.34,
    },
    {
      x: 9.26,
      y: 1.33,
    },
    {
      x: 8.68,
      y: 1.38,
    },
    {
      x: 9.45,
      y: 1.32,
    },
    {
      x: 10.16,
      y: 1.43,
    },
    {
      x: 5.42,
      y: 0.35,
    },
    {
      x: 5.76,
      y: 0.34,
    },
    {
      x: 5.29,
      y: 0.36,
    },
    {
      x: 4.77,
      y: 0.3,
    },
    {
      x: 6.2,
      y: 0.38,
    },
    {
      x: 4.92,
      y: 0.36,
    },
    {
      x: 5.03,
      y: 0.34,
    },
    {
      x: 5.39,
      y: 0.31,
    },
    {
      x: 5.63,
      y: 0.32,
    },
    {
      x: 5.44,
      y: 0.32,
    },
    {
      x: 5.84,
      y: 0.38,
    },
    {
      x: 5.43,
      y: 0.35,
    },
    {
      x: 6.12,
      y: 0.36,
    },
    {
      x: 6.83,
      y: 0.41,
    },
    {
      x: 5.41,
      y: 0.47,
    },
    {
      x: 5.13,
      y: 0.42,
    },
    {
      x: 5.23,
      y: 0.45,
    },
    {
      x: 5.6,
      y: 0.41,
    },
    {
      x: 5.97,
      y: 0.42,
    },
    {
      x: 5.4,
      y: 0.49,
    },
    {
      x: 5.26,
      y: 0.5,
    },
    {
      x: 5.57,
      y: 0.47,
    },
    {
      x: 5.7,
      y: 0.5,
    },
    {
      x: 5.68,
      y: 0.44,
    },
    {
      x: 5.35,
      y: 0.49,
    },
  ],
];

export const PowerCurveSparkData = [
  {
    x: 0,
    y: 0.15,
  },
  {
    x: 3,
    y: 0.24,
  },
  {
    x: 6,
    y: 0.5,
  },
  {
    x: 9,
    y: 1.45,
  },
  {
    x: 12,
    y: 1.51,
  },
  {
    x: 13.5,
    y: 1.51,
  },
  {
    x: 14,
    y: 0.15,
  },
  {
    x: 15,
    y: 0.15,
  },
];
