import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icons, Icon } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { DateTimeFormats } from '@ge/models/constants';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { globalColors, typography } from '@ge/tokens';

import { useManualAdjustmentContext } from '../../context/manual-adjustment-context';
import {
  useContractualReportingMalCategory,
  useCreateMalEvent,
} from '../../data-hooks/use-contractual-reporting-events';

import {
  DialogGrid2,
  EventCategory,
  EventCategoryName,
  EventCategoryColor,
} from './event-dialog-style';
import { MalEventCategorySelect } from './mal-event-category-select';
import { MalEventDateSelect } from './mal-event-date-select';
import { MalEventDuration } from './mal-event-duration';

const StyledDialog = styled(Dialog)`
  display: block;
  position: relative;
  margin: 0;
  width: 96%;
  height: 100%;
  min-height: 200px;
  transform: inherit;
  background-color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.backgroundColor};
  header {
    border-radius: 5px 5px 0px 0px;
    height: 25px;
    background-color: ${(props) =>
      props.theme.analyze.manualAdjustment.eventDialog.headerBackgroundColor};
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

const DialogButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddEventButton = styled.button.attrs(() => ({ type: 'button' }))`
  color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.editButtonColor};
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  text-transform: uppercase;
  margin-left: -5px;
  svg {
    marging-left: 0px;
  }
`;

const SaveButton = styled(Button)`
  margin-left: 5px;
`;

const DialogContent = styled.div`
  padding: 12px;
  max-height: 310px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.scrollbar.trackLightBackground};
  }
  &::-webkit-scrollbar-thumb {
    width: 7px;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.scrollbar.thumbBackground};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          display: none;
        `
      : ''}
`;

const DialogContentTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 10px;
  border-bottom: 1px solid
    ${(props) => props.theme.analyze.manualAdjustment.eventDialog.bordersColor};
  margin-bottom: 10px;
  .assets {
    overflow: hidden;
  }
  .count {
    margin-left: 1px;
    &:before {
      content: '...';
    }
  }
`;

const AssetIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.TURBINE,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))`
  margin: 0 5px;
  flex-shrink: 0;
`;

const AddEventIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.analyze.manualAdjustment.eventDialog.editButtonColor,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 14px 0px;
`;

const fieldName = 'malEvents';

const MAX_ASSET_COUNT = 2;

export const EventBulkEditDialog = ({ site, data, onClose, portalId, assetEventsKind }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const [addCounter, setAddCounter] = useState(1);

  const timezone = useMemo(() => site?.timezone ?? 'UTC', [site]);

  const { calendarDate, selectedSite, selectedAssets } = useManualAdjustmentContext();

  const { data: malCategoryData, isLoading: malCategoryLoading } =
    useContractualReportingMalCategory({
      siteId: selectedSite.id,
    });

  const { create, isError } = useCreateMalEvent({
    siteId: selectedSite.id,
    onSuccess: () => {
      onClose();
    },
  });

  const methods = useForm({ mode: 'onChange' });
  const { formState, handleSubmit } = methods;

  const assetNames = useMemo(() => {
    return data
      .filter((item) => selectedAssets.includes(item.asset.id))
      .map((item) => item.asset.name)
      .sort((a, b) => a.name?.localeCompare(b.name));
  }, [data, selectedAssets]);

  const onSubmit = useCallback(
    (values) => {
      const malEvents = [];
      const day = dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM);

      const user_details = JSON.parse(
        Object.values(sessionStorage).filter((key) => key.includes('profile'))[0],
      );

      for (let index = 0; index < addCounter; index++) {
        const currentValue = values[fieldName][index] ?? {};
        const { startTime, endTime } = currentValue;
        if (dayjs(`${day} ${startTime}`) >= dayjs(`${day} ${endTime}`)) {
          continue;
        }
        const eventCategory = malCategoryData?.filter((data) => data.id === currentValue.id);
        const malEvent = {
          day,
          startTime: dayjs.tz(`${day} ${startTime}`, timezone).valueOf() / 1000,
          endTime: dayjs.tz(`${day} ${endTime}`, timezone).valueOf() / 1000,
          adjustedBy: user_details ? user_details.profile.email : '',
          adjustedTime: Math.floor(dayjs().valueOf() / 1000),
          eventCategory: eventCategory[0]?.eventCategory,
          malCategory: currentValue.id,
        };
        selectedAssets.forEach((assetId) => {
          malEvents.push({ ...malEvent, assetId });
        });
      }
      create({ malEvents });
    },
    [create, malCategoryData, addCounter, selectedAssets, calendarDate, timezone],
  );

  if (!ready) return null;

  return (
    <StyledDialog
      isOpen={true}
      onClose={onClose}
      header={t('event_details_dialog.header_bulk_add', 'Add Event: Bulk')}
      portalId={portalId}
      padContent={false}
      className={'event-detail-dialog'}
    >
      {malCategoryLoading && <Loader />}
      <FormProvider {...methods}>
        <DialogContent isLoading={malCategoryLoading}>
          <DialogContentTitle>
            <span>{site?.name + ` / `}</span>
            <AssetIcon />
            <span className="assets">{assetNames.slice(0, MAX_ASSET_COUNT).join(', ')}</span>
            {assetNames.length > MAX_ASSET_COUNT && (
              <span className="count">+{assetNames.length - MAX_ASSET_COUNT}</span>
            )}
          </DialogContentTitle>
          {isError && <StyledError />}
          {[...Array(addCounter)].map((_, index) => (
            <div key={index}>
              {index > 0 && (
                <EventCategory>
                  <EventCategoryColor size={'8px'} backgroundColor={globalColors.slate2} />
                  <EventCategoryName>
                    {t('event_details_dialog.new_event', 'New Event')}
                  </EventCategoryName>
                </EventCategory>
              )}
              <MalEventCategorySelect
                name={`${fieldName}[${index}][id]`}
                data={malCategoryData}
                assetEventsKind={assetEventsKind}
              />
              <DialogGrid2 columns="90px 90px 80px">
                <MalEventDuration fieldName={fieldName} index={index} />
                <MalEventDateSelect
                  label={t('event_details_dialog.event_start', 'Start')}
                  name={`${fieldName}[${index}][startTime]`}
                />
                <MalEventDateSelect
                  name={`${fieldName}[${index}][endTime]`}
                  label={t('event_details_dialog.event_end', 'End')}
                />
              </DialogGrid2>
            </div>
          ))}
          <DialogButtonGroup>
            <AddEventButton onClick={() => setAddCounter(addCounter + 1)}>
              <AddEventIcon />
              {t('event_details_dialog.add_event_button', 'Add Event')}
            </AddEventButton>
            <SaveButton
              primary
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty || !formState.isValid}
            >
              {t('event_details_dialog.save_button', 'Save')}
            </SaveButton>
          </DialogButtonGroup>
        </DialogContent>
      </FormProvider>
    </StyledDialog>
  );
};

EventBulkEditDialog.propTypes = {
  site: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  portalId: PropTypes.string,
  assetEventsKind: PropTypes.string,
};

EventBulkEditDialog.defaultProps = {
  data: [],
};
