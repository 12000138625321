import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Dialog } from '@ge/components/modal';
import {
  FormMode,
  EntityType,
  NotesCategory,
  NotesScope,
  Capability,
  PermissionScope,
  DataLoaderType,
} from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { useNoteDialog } from '@ge/shared/hooks';

import NoteFooter from './note-footer';
import NoteForm from './note-form';
import NoteHeader from './note-header';
import NoteMessage from './note-message';
const NAMESPACE = 'entity-details';

const DialogWrapper = styled.div`
  width: 480px;
`;

export const TaskNoteDialog = ({
  isOpen,
  mode,
  data,
  entity,
  entityType,
  onClose,
  onSaveNote,
  enableFileUpload,
  maxFiles,
  maxFileSizeMb,
}) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const [attachments, setAttachments] = useState([]);

  const {
    isLoading: isTaskNoteLoading,
    error: taskNoteError,
    fetch: fetchTaskNote,
  } = useNoteDialog({
    mode,
    entityType: EntityType.TASK,
    onComplete: (response, error) => {
      if (!error) {
        onSaveNote(response, mode);
      }
    },
  });

  const { isAuthorized } = useAuth();
  const isInternalUser = isAuthorized({
    capabilities: [{ capability: Capability.INTERNAL_ACCESS, scopes: [PermissionScope.EDIT] }],
  });
  // Configure form hook and define logic for submission.
  const { handleSubmit, register, reset, control: noteControl, errors } = useForm({
    mode: 'onSubmit',
  });

  const handleUpload = useCallback(
    (selectedFiles) => {
      setAttachments(selectedFiles);
    },
    [setAttachments],
  );

  const onCancel = useCallback(() => {
    setAttachments([]);
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    (fields) => {
      const { title, note, externalViewing } = fields;
      const values = {
        title,
        note,
        attachments,
        category: mode === FormMode.EDIT ? data.category : NotesCategory.NOTE,
        scope: isInternalUser
          ? externalViewing
            ? NotesScope.EXTERNAL
            : NotesScope.INTERNAL
          : NotesScope.EXTERNAL,
      };
      if (mode === FormMode.CREATE) {
        fetchTaskNote({ ...values, entity }, data);
      } else {
        fetchTaskNote(values, data, entity);
      }
      setAttachments([]);
      reset();
    },
    [attachments, mode, data, reset, entity, fetchTaskNote],
  );

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      contentWidth={true}
      onClose={onCancel}
      header={
        mode === FormMode.EDIT ? t('notes.edit_note', 'Edit Note') : t('notes.new_note', 'New Note')
      }
      footer={
        <NoteFooter
          isLoading={isTaskNoteLoading}
          namespace={NAMESPACE}
          mode={mode}
          data={data}
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
          ref={register}
        />
      }
    >
      <DialogWrapper>
        <NoteHeader
          namespace={NAMESPACE}
          mode={mode}
          data={data}
          entity={entity}
          entityType={entityType}
        />
        <DataLoader type={DataLoaderType.GRID}>
          <NoteForm
            namespace={NAMESPACE}
            mode={mode}
            isLoading={isTaskNoteLoading}
            data={data}
            files={attachments}
            enableFileUpload={enableFileUpload}
            maxFiles={maxFiles}
            maxFileSizeMb={maxFileSizeMb}
            handleUpload={handleUpload}
            ref={register}
            entity={entity}
            entityType={entityType}
            noteControl={noteControl}
            errors={errors}
          />
          <NoteMessage namespace={NAMESPACE} message={taskNoteError} />
        </DataLoader>
      </DialogWrapper>
    </Dialog>
  );
};

TaskNoteDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveNote: PropTypes.func,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  data: PropTypes.instanceOf(Object),
  entity: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    createdBy: PropTypes.string,
    creationDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    asset: PropTypes.shape({ name: PropTypes.string }),
    site: PropTypes.shape({ name: PropTypes.string }),
    taskIds: PropTypes.arrayOf(PropTypes.string),
  }),
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  enableFileUpload: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxFileSizeMb: PropTypes.number,
};

TaskNoteDialog.defaultProps = {
  isOpen: false,
  onClose: () => null,
  onSaveNote: () => null,
  mode: null,
  entity: null,
  entityType: null,
  enableFileUpload: true,
  maxFiles: 5,
  maxFileSizeMb: 8,
};
