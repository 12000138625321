// @flow
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, memo } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { asMutable } from 'seamless-immutable';

import { regionPropType } from '../../constants';

import styles from './styles';

const useStyles = makeStyles(styles);

export const RegionLabel = ({
  region,
  editing,
  allowedClasses,
  allowedTags,
  onChange,
  onClose,
  onOpen,
  onRegionClassAdded,
  onSelectRegion = () => {},
  selectedAnnotationId,
  isLoading,
}) => {
  const classes = useStyles();
  useEffect(() => {
    const { id } = region;
    if (id === selectedAnnotationId) onOpen(region);
  }, [selectedAnnotationId, onOpen, region]);

  return (
    <Paper
      onClick={() => {
        if (!isLoading) {
          onSelectRegion(region.id === selectedAnnotationId ? null : region.id);
          if (selectedAnnotationId === null) {
            return onOpen(region);
          } else if (selectedAnnotationId === region.id) {
            return onClose(region);
          }
          return null;
        }
      }}
      style={{
        cursor: isLoading ? 'not-allowed' : 'pointer',
      }}
      className={classnames(classes.regionInfo, {
        highlighted: region.highlighted,
      })}
    >
      {!editing ? (
        <div>
          {region.cls && (
            <div className="name">
              <div className="circle" style={{ backgroundColor: region.color }} />
              {region.id}
            </div>
          )}
          {region.tags && (
            <div className="tags">
              {region.tags.map((t) => (
                <div key={t} className="tag">
                  {t}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div style={{ width: 200 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                backgroundColor: region.color,
                color: '#fff',
                padding: 4,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 4,
                fontWeight: 'bold',
                textShadow: '0px 0px 5px rgba(0,0,0,0.4)',
              }}
            >
              {region.type}
            </div>
            <div style={{ flexGrow: 1 }} />
            {/* <IconButton
              onClick={() => onDelete(region)}
              tabIndex={-1}
              style={{ width: 22, height: 22 }}
              size="small"
              variant="outlined"
            >
              <TrashIcon style={{ marginTop: -8, width: 16, height: 16 }} />
            </IconButton> */}
          </div>
          {(allowedClasses || []).length > 0 && (
            <div style={{ marginTop: 6 }}>
              <CreatableSelect
                placeholder="Classification"
                onChange={(o, actionMeta) => {
                  if (actionMeta.action == 'create-option') {
                    onRegionClassAdded(o.value);
                  }
                  return onChange({
                    ...region,
                    cls: o.value,
                  });
                }}
                value={region.cls ? { label: region.cls, value: region.cls } : null}
                options={asMutable(allowedClasses.map((c) => ({ value: c, label: c })))}
              />
            </div>
          )}
          {(allowedTags || []).length > 0 && (
            <div style={{ marginTop: 4 }}>
              <Select
                onChange={(newTags) =>
                  onChange({
                    ...region,
                    tags: newTags.map((t) => t.value),
                  })
                }
                placeholder="Tags"
                value={(region.tags || []).map((c) => ({ label: c, value: c }))}
                isMulti
                options={asMutable(allowedTags.map((c) => ({ value: c, label: c })))}
              />
            </div>
          )}
          {onClose && (
            <div style={{ marginTop: 4, display: 'flex' }}>
              <div style={{ flexGrow: 1 }} />
              <Button
                onClick={() => onClose(region)}
                size="small"
                variant="contained"
                color="primary"
              >
                <CheckIcon />
              </Button>
            </div>
          )}
        </div>
      )}
    </Paper>
  );
};

RegionLabel.propTypes = {
  region: regionPropType,
  editing: PropTypes.bool,
  isLoading: PropTypes.bool,
  allowedClasses: PropTypes.arrayOf(PropTypes.string),
  allowedTags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onRegionClassAdded: PropTypes.func,
  onSelectRegion: PropTypes.func,
  selectedAnnotationId: PropTypes.number,
};

export default memo(
  RegionLabel,
  (prevProps, nextProps) =>
    prevProps.editing === nextProps.editing &&
    prevProps.region.x === nextProps.region.x &&
    prevProps.region.y === nextProps.region.y &&
    prevProps.region.tags === nextProps.region.tags &&
    prevProps.region.cls === nextProps.region.cls &&
    prevProps.region.color === nextProps.region.color &&
    prevProps.region.highlighted === nextProps.region.highlighted,
);
