import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { TooltipCell } from '@ge/components/table/table';
import { Tooltip } from '@ge/components/tooltip';
import {
  AssetType,
  Capability,
  EntityType,
  EntityTypeMapping,
  // AlertsEntityType,
  FormMode,
  TaskSourceField,
  NoteSelectOptions,
  NotesPageSize,
  AlertType,
  PermissionScope,
  Placeholders,
} from '@ge/models/constants';
import { AlertDialog } from '@ge/shared/components/alerts/alert-dialog';
import { AlertHeaderIcons } from '@ge/shared/components/alerts/alert-header-icons';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ContactDialog } from '@ge/shared/components/contacts/contact-dialog';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { TurbineModelMenu } from '@ge/shared/components/turbine-model-menu';
import { useSiteRealTimeInfo, useAuth } from '@ge/shared/data-hooks';
import { useAlertDialog } from '@ge/shared/hooks';
import { elevations } from '@ge/tokens/elevations';

import { useNotesPaginationDetails } from '../../../hooks/use-notes-details';
import HeaderCurrentCustomer from '../components/header-current-customer';
import HeaderCurrentRegion from '../components/header-current-region';
import SpecialInstructions from '../components/special-instructions';
import EntityDetailHeader, { HeaderIcon, StyledDetailHeader } from '../entity-details-header';
import { DetailsCurrent } from '../entity-details-shared';
import EntityDetailsWeather from '../entity-details-weather';

const getIconFromSiteType = (type) => {
  let icon = Icons.SITE;
  switch (type) {
    case EntityType.STORAGE_SITE:
      icon = Icons.STORAGE;
      break;
    case EntityType.SOLAR_SITE:
      icon = Icons.SOLAR;
      break;
    case EntityType.HYBRID_SITE:
      icon = Icons.HYBRID;
      break;
    default:
      icon = Icons.SITE;
  }
  return icon;
};

const SiteIcon = styled(Icon).attrs((props) => ({
  size: props.size ?? 36,
  icon: getIconFromSiteType(props.type),
  color: props.theme.entityDetails.headerIconColor,
}))`
  align-self: center;
  margin: 0 10px 0 20px;
`;

const SiteName = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  max-width: 350px;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  > * {
    + * {
      margin-left: 5px;
    }
  }

  > button {
    margin-top: 1px;
  }
`;

// removed for MVP 0 - #612
// const SmallIcon = styled(Icon).attrs((props) => ({
//   size: 12,
//   color: props.theme.entityDetails.headerIconColor,
// }))`
//   margin-right: 5px;
//   padding-bottom: 3px;
// `;

const StyledModel = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1px 10px;
  text-transform: uppercase;
  white-space: nowrap;
  &:first-of-type {
    margin-left: 0;
  }
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  color: props.theme.entityDetails.headerBadgeColor,
  small: true,
}))`
  border-radius: 4px;
  color: ${(props) => props.theme.entityDetails.badgeFonColor};
  margin-left: 5px;
  padding: 0 2px;
`;

const StyledEntityDetailHeader = styled(EntityDetailHeader)`
  &.instruction-header {
    margin-bottom: ${(props) => (props.isShowMore ? '114px' : '102px')};
  }
`;

const Asset = {
  MAKE: 'make',
  MODEL: 'model',
};

const SiteDetailsHeader = ({ site, entityType }) => {
  const { t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const { isAuthorized } = useAuth();
  const siteId = site?.id;
  const authorizedSI = isAuthorized({
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });

  const authorizedAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });

  const { data: instructionData } = useNotesPaginationDetails(
    {
      assetType: EntityType.SITE,
      id: site?.id,
      notesType: NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION,
      pageSize: NotesPageSize,
    },
    authorizedSI,
  );

  const instructionItems = instructionData?.pages?.[0]?.notes?.length;

  // store
  const getSortedAssets = useStoreState((store) => store.assets.getSortedAssets);
  const sortedAssets = getSortedAssets(Asset.MAKE, SortDirection.DESC, site.id);

  // data hooks
  const { data, isLoading } = useSiteRealTimeInfo({
    siteId: site.id,
    type: entityType === EntityType.SITE ? entityType : null,
  });

  // util hooks
  const {
    alerts,
    currentAlert,
    showAlertDialog,
    handleSaveAlert,
    handleEditAlert,
    handleCancelAlertDialog,
    handleShowAlertDialog,
    handleViewAlert,
  } = useAlertDialog({ entity: site, entityType: EntityTypeMapping[entityType] }, authorizedAlerts);

  // state
  // TODO: swap this out with region hook and revisit once we figure out how to reconcile regions and sites in the store
  const [region, setRegion] = useState(null);
  const [showNewTaskDialog, setShowNewTaskDialog] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const { getRegionById } = useStoreState((state) => state.regions);

  useEffect(() => {
    if (!site.region) {
      return;
    }
    setRegion(getRegionById(site.region.id));
  }, [site, setRegion, getRegionById]);

  const keys = [Asset.MAKE, Asset.MODEL];

  // reduce assets to make/model key/values, then reduce to unique make/model count
  const siteModels = sortedAssets
    .filter((asset) => asset.type === AssetType.WIND_TURBINE) // Show only turbines
    .map((asset) =>
      keys.reduce((acc, key) => {
        return { ...acc, [key]: asset[key] };
      }, {}),
    )
    .reduce((acc, asset) => {
      acc[`${asset.make} ${asset.model}`] = !acc[`${asset.make} ${asset.model}`]
        ? 1
        : (acc[`${asset.make} ${asset.model}`] += 1);
      return acc;
    }, {});

  const priorityTurbineModels = Object.entries(siteModels).splice(0, 2);
  const remainingTurbineModels = Object.entries(siteModels).splice(
    2,
    Object.entries(siteModels).length - 1,
  );

  if (!site) {
    return null;
  }

  // eslint-disable-next-line no-console
  const currentCustomerClickHandler = () => console.log('Customer Clicked');
  const currentRegionClickHandler = () => console.log('Region Clicked');

  const handleNewTask = () => setShowNewTaskDialog(true);
  const handleContact = () => setShowContactDialog(!showContactDialog);

  const handleCloseNewTaskDialog = () => setShowNewTaskDialog(false);

  const handleSaveNewTask = () => setShowNewTaskDialog(false);

  return (
    <StyledEntityDetailHeader
      type={EntityType.SITE}
      className={instructionItems && authorizedSI ? 'instruction-header' : ''}
      isShowMore={instructionItems > 1}
    >
      <SiteIcon type={entityType} />
      <StyledDetailHeader>
        <DetailsCurrent>
          {site.customer?.name && (
            <HeaderCurrentCustomer
              customerName={site.customer?.name}
              siteName={region?.name}
              onClick={currentCustomerClickHandler}
            />
          )}
          {region?.name && (
            <HeaderCurrentRegion regionName={region?.name} onClick={currentRegionClickHandler} />
          )}
        </DetailsCurrent>
        <SiteName>
          <Tooltip title={site?.name} zIndex={elevations.P20} placement="top-start">
            <h2>{site?.name}</h2>
          </Tooltip>
          <AuthRender
            capability={Capability.ALERTS}
            view
            description="Site details header Alert icons"
            siteIds={[siteId]}
          >
            <AlertHeaderIcons
              alerts={alerts}
              onClick={(_, alert) => handleViewAlert(alert)}
              headerAlerts
              large
            />
          </AuthRender>
        </SiteName>
        <TooltipCell tooltip={site?.country} zIndex={elevations.P20}>
          <h5>{site?.country}</h5>
        </TooltipCell>
      </StyledDetailHeader>
      <section className="metrics">
        <div className="model-count">
          {priorityTurbineModels.map(([key, value]) => (
            <StyledModel key={key}>
              {key}
              <StyledBadge label={value?.toString()} />
            </StyledModel>
          ))}
          {remainingTurbineModels.length ? (
            <TurbineModelMenu turbineModels={remainingTurbineModels}>
              <Badge color="transparent" label={Placeholders.TRIPLE_DOT} small />
            </TurbineModelMenu>
          ) : (
            ''
          )}
        </div>
        {!isLoading && entityType === EntityType.SITE && (
          <EntityDetailsWeather className="details-weather" weather={data.site} />
        )}
        {/* removed for MVP 0 - #612 */}
        {/* <div className="list-right">
          <SmallIcon icon={Icons.TASK} />
          {site.tasks?.length ?? 0}
        </div> */}
      </section>
      <div className="actions">
        <AuthRender
          capability={Capability.CONTACTS}
          view
          description="Site details header - contact"
          siteIds={[siteId]}
        >
          <button type="button" onClick={handleContact}>
            <HeaderIcon icon={Icons.PHONE_CONTACT} /> {t('contact', 'Contact')}
          </button>
        </AuthRender>
        <AuthRender
          capability={Capability.CORE_KPIS}
          view
          description="Site details header - Analyze Site"
          siteIds={[siteId]}
        >
          <NavLink type="button" to={`/analyze/site/${site.id}`}>
            <HeaderIcon icon={getIconFromSiteType(entityType)} />{' '}
            {t('analyze_site', 'Analyze Site')}
          </NavLink>
        </AuthRender>
        <AuthRender
          capability={Capability.FIELD_TASKS}
          create
          description="Site details header - new task button"
          siteIds={[site.id]}
        >
          <button type="button" onClick={handleNewTask}>
            <HeaderIcon icon={Icons.TASK} /> {t('new_task', 'New Task')}
          </button>
        </AuthRender>
        <AuthRender
          capability={Capability.ALERTS}
          create
          description="Site details header new alert button"
          siteIds={[siteId]}
        >
          <button type="button" onClick={handleShowAlertDialog}>
            <HeaderIcon icon={Icons.ALERT_NEW} viewbox="0 0 12 12" /> {t('new_alert', 'New Alert')}
          </button>
        </AuthRender>
      </div>
      {(entityType === EntityType.SITE || entityType === EntityType.STORAGE_SITE) &&
        !!instructionItems && (
          <AuthRender
            capability={Capability.SPECIAL_INSTRUCTION}
            view
            description="Site details header specialInstructions"
            siteIds={[siteId]}
          >
            <SpecialInstructions
              site={site}
              entityType={entityType}
              instructionData={instructionData.pages[0].notes}
              totalCount={instructionData?.pages?.[0]?.pagination?.totalRecords}
            />
          </AuthRender>
        )}
      {showNewTaskDialog && (
        <NewTaskDialog
          entity={site}
          entityType={EntityType.SITE}
          onClose={handleCloseNewTaskDialog}
          onConfirm={handleSaveNewTask}
          taskSource={TaskSourceField.MANUALSITE}
        />
      )}
      {showContactDialog && (
        <ContactDialog
          entity={site}
          siteId={site.id}
          onClose={handleContact}
          siteType={entityType}
        />
      )}
      {showAlertDialog && (
        <AlertDialog
          alert={currentAlert}
          entity={site}
          entityType={EntityTypeMapping[entityType]}
          isOpen
          mode={currentAlert ? FormMode.VIEW : FormMode.CREATE}
          onCancel={handleCancelAlertDialog}
          onConfirm={currentAlert ? handleEditAlert : handleSaveAlert}
          alertType={AlertType.PLANNED_OUTAGE}
        />
      )}
    </StyledEntityDetailHeader>
  );
};

SiteDetailsHeader.propTypes = {
  site: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
};

export default SiteDetailsHeader;
