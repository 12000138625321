import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect, useImperativeHandle } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@ge/components/datepicker';
import { Input } from '@ge/components/input';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { DateTimeFormats } from '@ge/models/constants';
import { getDayEndTime } from '@ge/shared/util';

import { useManualAdjustmentContext } from '../../context/manual-adjustment-context';
import { maLegendValues } from '../../models/manual-adjustment-legends';

import {
  Container,
  DialogGrid2,
  DialogLabel,
  DialogValue,
  IconButton,
  EventCategory,
  EventCategoryName,
  EventCategoryColor,
  StyledSelect,
  TimeIcon,
} from './event-dialog-style';

const AddEventDetailDialog = (
  {
    index,
    malCategoryOption,
    categoryOptions,
    eventOptions,
    calculateDuration,
    timezone,
    formatTime,
    assetEventsKind,
  },
  ref,
) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { selectedEvent, calendarDate } = useManualAdjustmentContext();
  const { event } = selectedEvent;
  const { errors, control } = useFormContext();

  const [selectEventCategory, setSelectEventCategory] = useState();
  const [selectEventName, setSelectEventName] = useState();
  const defaultStartTime = dayjs(event.startTime).tz(timezone);
  const defaultEndTime = dayjs(getDayEndTime(event.endTime, timezone, calendarDate)).tz(timezone);
  const [startTime, setStartTime] = useState(dayjs(defaultStartTime).$d);
  const [endTime, setEndTime] = useState(dayjs(defaultEndTime).$d);
  const [showStartTime, setShowStartTime] = useState(dayjs(defaultStartTime).format('HH:mm:ss'));
  const [showEndTime, setShowEndTime] = useState(dayjs(defaultEndTime).format('HH:mm:ss'));
  const [timeDuration, setTimeDuration] = useState();

  useEffect(() => {
    setTimeDuration(calculateDuration(startTime, endTime));
  }, [startTime, endTime]);

  useImperativeHandle(ref, () => ({
    setTimeDuration,
  }));

  const handleEventNameChange = useCallback(
    (value) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { eventCategory } = malCategoryOption?.find((data) => data.id === value);
      setSelectEventCategory(eventCategory);
      setSelectEventName(value);
    },
    [malCategoryOption],
  );

  const onStartTimeChange = (value) => {
    setStartTime(value);
    setTimeDuration(calculateDuration(value, endTime));
  };

  const onEndTimeChange = (value) => {
    setEndTime(value);
    setTimeDuration(calculateDuration(startTime, value));
  };

  if (!ready) {
    return <Loader />;
  }

  return (
    <>
      <EventCategory>
        <EventCategoryColor size={'8px'} backgroundColor="#38434E" />
        <EventCategoryName>{t('event_details_dialog.new_event', 'New Event')}</EventCategoryName>
      </EventCategory>
      <EventCategory>
        <EventCategoryColor
          backgroundColor={
            maLegendValues[assetEventsKind]?.find(
              ({ category }) => category === selectEventCategory,
            )?.color
          }
          size={'10px'}
        />
        <EventCategoryName>
          {categoryOptions.find(({ value }) => value === selectEventCategory)?.label ??
            t('event_details_dialog.eventcategory_placeholder', 'Event Category')}
        </EventCategoryName>
      </EventCategory>
      <StyledSelect>
        <Controller
          name={`addMALCategory${index}`}
          id={`addMALCategory${index}`}
          rules={{
            required: true,
          }}
          defaultValue={null}
          render={({ onChange }) => (
            <Select
              onChange={(val) => {
                onChange(val);
                handleEventNameChange(val.value);
              }}
              placeholder={t(
                'event_details_dialog.dropdown_placeholder',
                'Please select an option',
              )}
              name={`addMALCategory${index}`}
              options={eventOptions}
              value={eventOptions?.find(({ value }) => value === selectEventName)}
              error={errors[`addMALCategory${index}`]?.type}
            />
          )}
        />
      </StyledSelect>
      <DialogGrid2>
        <div>
          <DialogLabel>{t('event_details_dialog.event_duration', 'Duration')}</DialogLabel>
          <DialogValue>{timeDuration}</DialogValue>
        </div>
        <div>
          <DialogLabel>{t('event_details_dialog.event_start', 'Start')}</DialogLabel>
          <Container>
            <Controller
              control={control}
              name={`addStartTime${index}`}
              defaultValue={startTime}
              render={({ onChange }) => (
                <DatePicker
                  showTimeSelect
                  dateFormat={DateTimeFormats.DEFAULT_TIME_W_SECS}
                  timeFormat="HH:mm:ss"
                  onChange={(val) => {
                    onChange(val);
                    onStartTimeChange(val);
                  }}
                  customInput={
                    <div className="date-picker-member">
                      <Input
                        type="text"
                        placeholder="hh:mm:ss"
                        maxLength={8}
                        value={showStartTime}
                        onChange={(e) => setShowStartTime(formatTime(e))}
                      />
                    </div>
                  }
                  selected={startTime}
                  timeIntervals={1}
                  open={false}
                />
              )}
            />
            <IconButton>
              <TimeIcon />
            </IconButton>
          </Container>
        </div>
        <div>
          <DialogLabel>{t('event_details_dialog.event_end', 'End')}</DialogLabel>
          <Container>
            <Controller
              control={control}
              name={`addEndTime${index}`}
              defaultValue={endTime}
              render={({ onChange }) => (
                <DatePicker
                  showTimeSelect
                  dateFormat={DateTimeFormats.DEFAULT_TIME_W_SECS}
                  timeFormat="HH:mm:ss"
                  onChange={(val) => {
                    onChange(val);
                    onEndTimeChange(val);
                  }}
                  customInput={
                    <div className="date-picker-member">
                      <Input
                        type="text"
                        placeholder="hh:mm:ss"
                        maxLength={8}
                        value={showEndTime}
                        onChange={(e) => setShowEndTime(formatTime(e))}
                      />
                    </div>
                  }
                  selected={endTime}
                  timeIntervals={1}
                  open={false}
                />
              )}
            />
            <IconButton>
              <TimeIcon />
            </IconButton>
          </Container>
        </div>
      </DialogGrid2>
    </>
  );
};

AddEventDetailDialog.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  malCategoryOption: PropTypes.instanceOf(Object).isRequired,
  categoryOptions: PropTypes.instanceOf(Object).isRequired,
  eventOptions: PropTypes.instanceOf(Object).isRequired,
  calculateDuration: PropTypes.func.isRequired,
  index: PropTypes.number,
  timezone: PropTypes.string,
  formatTime: PropTypes.func,
  assetEventsKind: PropTypes.string,
};
export default React.forwardRef(AddEventDetailDialog);
