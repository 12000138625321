import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { ActionController } from '@ge/components/controllers/actions-controller';
import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader/loader';
import { Menu, placements } from '@ge/components/menu';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { globalColors } from '@ge/tokens/colors';

import { killEventPropagation } from '../util/general';

const MENU_WIDTH_PX = 143;

const StyledActionsMenu = styled.div`
  font-size: 11px;
  text-align: left;
  display: inline;
  position: relative;
  .loader {
    min-height: 100px;
    position: relative;
  }
`;

export const MenuHeader = styled.div`
  padding: 6px 8px;
  text-align: left;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.5px;
`;

export const MenuIcon = styled(Icon).attrs(({ menuIconSmall, icon, color, theme }) => ({
  size: menuIconSmall ? 18 : 27,
  icon: icon ? icon : Icons.POINTER,
  color: color ? color : theme?.table.arrowColor,
}))`
  path {
    transform: translate(5px, 2px);
  }
`;

const ActionControlsWrapper = styled.div`
  .entity-controls {
    padding: 3px;
    div:first-of-type button {
      &:hover {
        border-radius: 2px 2px 0 0;
      }
    }
    div:last-of-type button {
      border: none;
      &:hover {
        border-radius: 0 0 2px 2px;
      }
    }
  }
`;

const MenuButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const ActionsMenu = ({
  children,
  disabled,
  placement,
  padding,
  entity,
  isLoading,
  menuIconSmall,
  actionMenuIcon,
  menuItems,
  onAction,
  onClick,
  onClose,
  selected,
  disabledActions,
}) => {
  // state
  const [anchorEl, setAnchorEl] = useState(null);

  const hasActions = Boolean(Object.values(menuItems)?.length);

  // handlers
  const handleOpen = (e) => {
    e.stopPropagation();

    onClick();

    if (anchorEl === null && hasActions) {
      setAnchorEl(e.currentTarget);
    } else {
      handleClose();
    }
  };

  const handleClose = (e) => {
    killEventPropagation(e);

    onClose();

    setAnchorEl(null);
  };

  const handleAction = (e, type, data) => {
    killEventPropagation(e);

    onAction({ data, entity, type });

    handleClose();
  };

  return (
    <div onClick={(e) => killEventPropagation(e)}>
      <StyledActionsMenu>
        <MenuButton onClick={(e) => handleOpen(e, 'actions')} disabled={disabled || !hasActions}>
          {actionMenuIcon ? actionMenuIcon : <MenuIcon menuIconSmall={menuIconSmall} />}
        </MenuButton>
        <Menu
          anchorEl={anchorEl}
          fixed
          onClose={handleClose}
          open={Boolean(anchorEl)}
          placement={placement}
          padding={padding}
          width={MENU_WIDTH_PX}
        >
          <DetailsDismissInert>
            <ConditionalRender shouldRender={isLoading}>
              <div className="loader">
                <MiniLoader />
              </div>
            </ConditionalRender>
            <ConditionalRender shouldRender={!isLoading}>
              <ActionControlsWrapper>
                {children}
                <ActionController
                  menuItems={menuItems}
                  onActionHandler={handleAction}
                  iconColor={globalColors.slate5}
                  entity={entity}
                  selected={selected}
                  disabledActions={disabledActions}
                />
              </ActionControlsWrapper>
            </ConditionalRender>
          </DetailsDismissInert>
        </Menu>
      </StyledActionsMenu>
    </div>
  );
};

ActionsMenu.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.oneOf(Object.values(placements)),
  padding: PropTypes.string,
  disabled: PropTypes.bool,
  entity: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  menuIconSmall: PropTypes.bool,
  actionMenuIcon: PropTypes.instanceOf(Object),
  menuItems: PropTypes.instanceOf(Object).isRequired,
  onAction: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  selected: PropTypes.string,
  disabledActions: PropTypes.instanceOf(Object),
};

ActionsMenu.defaultProps = {
  children: null,
  placement: placements.BOTTOM_END,
  disabled: false,
  entity: undefined,
  isLoading: false,
  onAction: () => {},
  onClick: () => {},
  onClose: () => {},
  menuIconSmall: false,
  selected: null,
  disabledActions: {},
};

export default ActionsMenu;
