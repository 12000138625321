import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Radio } from '@ge/components/radio';
import { Select } from '@ge/components/select';
import {
  DateTimeFormats,
  RepeatInterval,
  TaskRecurrence,
  TaskRecurrenceEnds,
  TaskStatus,
  TaskTemplateModes,
} from '@ge/models/constants';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors/colors';

import { DateMetaField } from '../../meta-fields/date-meta-field';
import { NumberMetaField } from '../../meta-fields/number-meta-field';
import { SelectMetaField } from '../../meta-fields/select-meta-field';
import { BuildOption, FormRow, StyledDateContainer } from '../task-template-shared';

const RecurrenceRow = styled(FormRow)`
  flex-flow: wrap;
  justify-content: flex-start;
  position: relative;
`;

const RecurrenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  &.read-only {
    opacity: 0.5;
  }
`;

const RecurrenceCustom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  &.read-only {
    opacity: 0.5;
  }
`;

const RecurrenceEndsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  label {
    text-transform: capitalize;
  }
`;

const RecurrenceDateContainer = styled(StyledDateContainer)`
  margin: 0 25px 0 5px;
  && input {
    width: 100px;
  }
`;

const RecurrenceCustomMenu = styled.div`
  background-color: ${(props) => props.theme.select.recurrenceCustomMenu.backgroundColor}
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  position: absolute;
  left: 150px;
  top: 30px;
  z-index: 1;
`;

const RepeatContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  .react-datepicker,
  .react-datepicker__header {
    background-color: ${(props) => props.theme.input.background};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  button {
    background: none;
    border: 0;
    font-size: 10px;
    padding-left: 2px;
    padding-right: 2px;
    text-transform: uppercase;
    &:last-of-type {
      color: ${(props) => props.theme.newTaskDialog.okButton};
      margin-left: 15px;
    }
  }
`;

const OccurrencesInputWrapper = styled.div`
  margin: 0 5px;
  > div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  label {
    margin: 0 0 0 5px;
  }
`;

const OccurrencesInput = styled(NumberMetaField)`
  width: 32px;
  height: 25px;
`;

const NumberInput = styled(Input)`
  width: 45px;
  height: 25px;
  ${(props) => (props.error ? `border: 1px solid ${globalColors.red1};` : '')}
`;

const EditCustomButton = styled(Button).attrs(() => ({ type: 'button' }))`
  background: 0;
  padding: 0;
`;

const EditIcon = styled(Icon).attrs((props) => ({
  size: 8,
  color: props.theme.newTaskDialog.customEditIcon,
  icon: Icons.PENCIL,
}))`
  padding-left: 4px;
`;

const RecurrenceWeekContainer = styled.div`
  display: flex;
  margin: 8px 0 10px;
`;

const RecurrenceWeekCheckbox = styled(Checkbox)`
  position: relative;
  margin-right: 15px;
  &:last-of-type {
    margin-right: 0;
  }
  div {
    opacity: 0;
  }
  span {
    color: ${(props) => props.theme.newTaskDialog.dayUnchecked};
    cursor: pointer;
    font-size: 12px;
    font-weight: ${typography.weight.bold};
    position: absolute;
    margin-left: 0;
    &.checked {
      color: ${(props) => props.theme.newTaskDialog.dayChecked};
    }
  }
`;

const Days = {
  SUNDAY: 'Sun',
  MONDAY: 'Mon',
  TUESDAY: 'Tues',
  WEDNESDAY: 'Weds',
  THURSDAY: 'Thurs',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
};

const CustomRecurrenceFieldConfig = { shouldDirty: true, shouldTouch: true, shouldValidate: true };

export const Recurrence = ({ metadata, task, templateMode }) => {
  const { t, ready } = useTranslation(['tasks'], { useSuspense: false });
  const { recurrence: _recurrence } = task ?? {};
  const { register, setValue, watch } = useFormContext();
  // The form fields that trigger the state values for cycle and cycle type, when registered do not correctly manage the values.
  // To fix I removed their ref and manually register the fields here. Their valuea are magaged on custom edit ie. onRecurrenceCustomOk
  register('recurrenceCycle');
  register('recurrenceCycleType');

  const watchRecurrence = watch(
    'recurrence',
    _recurrence ? _recurrence.recurrence : TaskRecurrence.NONE,
  );

  const watchRecurrenceCycle = watch('recurrenceCycle', _recurrence?.recurrenceCycle ?? 1);
  const watchRecurrenceCycleType = watch(
    'recurrenceCycleType',
    _recurrence?.recurrenceCycleType ?? RepeatInterval.DAYS,
  );
  const watchRecurrenceRepeatWeek = watch('recurrenceRepeatWeek');
  const watchRecurrenceRepeatMonth = watch('recurrenceRepeatMonth');

  const watchRecurrenceType = watch(
    'recurrenceType',
    _recurrence && _recurrence?.recurrenceType
      ? _recurrence.recurrenceType
      : TaskRecurrenceEnds.END_BY_DATE,
  );

  const [customRecurrenceValues, setCustomRecurrenceValues] = useState({
    recurrenceRepeatWeek: [],
    recurrenceRepeatMonth: new Date(),
  });

  useEffect(() => {
    const recurrenceCycle = _recurrence?.recurrenceCycle ?? 1;
    const recurrenceCycleType = _recurrence?.recurrenceCycleType ?? RepeatInterval.DAYS;
    setCustomRecurrenceValues((prev) => ({ ...prev, recurrenceCycle, recurrenceCycleType }));
    setShowRecurrenceCustom(
      _recurrence?.recurrence === TaskRecurrence.CUSTOM && !_recurrence?.recurrenceCycle,
    );
  }, [_recurrence, setValue]);

  const [showRecurrenceCustom, setShowRecurrenceCustom] = useState(false);

  const handleRecurrenceChange = useCallback((val) => {
    setShowRecurrenceCustom(val === TaskRecurrence.CUSTOM);
  }, []);

  const handleRecurrenceCustom = useCallback((updated) => {
    setCustomRecurrenceValues((prev) => ({ ...prev, ...updated }));
  }, []);

  const recurrenceCustomSummary = useMemo(() => {
    if (watchRecurrence !== TaskRecurrence.CUSTOM) {
      return '';
    }
    const customSummary =
      watchRecurrenceCycle === '1'
        ? ` ${watchRecurrenceCycleType.slice(0, -1)} `
        : ` ${watchRecurrenceCycle} ${watchRecurrenceCycleType} `;
    let extendedSummary = '';
    if (watchRecurrenceCycleType === RepeatInterval.WEEKS) {
      extendedSummary = `${t('form.on', 'on')} `;
      Object.entries(Days).forEach((day, idx) => {
        if (watchRecurrenceRepeatWeek?.includes(day[0])) {
          if (idx !== 0) extendedSummary += ', ';
          extendedSummary += day[1];
        }
      });
    } else if (watchRecurrenceCycleType === RepeatInterval.MONTHS) {
      extendedSummary = `${t('form.on', 'on')} ${dayjs(watchRecurrenceRepeatMonth).format(
        DateTimeFormats.DEFAULT_DATE,
      )}`;
    }
    // Hiding for MVP0 - Story #836
    // When this is added back remove the varible and ternary below
    // Added this bool to reference extendedSummary and pass linting
    const showExtendedSummary = false;
    extendedSummary = showExtendedSummary ? extendedSummary : '';
    return `${t('form.every', 'Every')}${customSummary}${extendedSummary}`;
  }, [
    t,
    watchRecurrence,
    watchRecurrenceCycle,
    watchRecurrenceCycleType,
    watchRecurrenceRepeatWeek,
    watchRecurrenceRepeatMonth,
  ]);

  const recurrenceSelectOptions = useMemo(
    () =>
      Object.values(TaskRecurrence).map((recurrence) =>
        BuildOption(recurrence, t(`dynamic.recurrence.${recurrence}`, recurrence)),
      ) || [],
    [t],
  );

  const repeatIntervalSelectOptions = useMemo(
    () =>
      Object.values(RepeatInterval).map((repeat) =>
        BuildOption(repeat, t(`dynamic.repeat_interval.${repeat}`, repeat)),
      ) || [],
    [t],
  );

  const onRecurrenceTypeChange = useCallback(
    (value) => {
      setValue('recurrenceType', value);
      value === TaskRecurrenceEnds.END_BY_DATE && setValue('recurrenceEndByCount', '');
      value === TaskRecurrenceEnds.END_AFTER && setValue('recurrenceEndByDate', '');
    },
    [setValue],
  );

  const onRecurrenceCustomOk = useCallback(() => {
    // Recurrence Cycle fields are hidden and therefore are not registered
    // Setting the form hook values to the state values
    setValue(
      'recurrenceCycle',
      customRecurrenceValues.recurrenceCycle,
      CustomRecurrenceFieldConfig,
    );
    setValue(
      'recurrenceCycleType',
      customRecurrenceValues.recurrenceCycleType,
      CustomRecurrenceFieldConfig,
    );
    setShowRecurrenceCustom(false);
    //customRecurrenceValues.recurrenceCycleType
  }, [customRecurrenceValues, setValue]);

  const onRecurrenceCustomCancel = useCallback(() => {
    setCustomRecurrenceValues((prev) => ({
      ...prev,
      recurrenceCycle:
        _recurrence?.recurrence === TaskRecurrence.CUSTOM
          ? _recurrence?.recurrenceCycle?.toString()
          : '1',
      recurrenceCycleType:
        _recurrence?.recurrence === TaskRecurrence.CUSTOM
          ? _recurrence?.recurrenceCycleType
          : RepeatInterval.DAYS,
    }));
    setShowRecurrenceCustom(false);
  }, [_recurrence]);

  const toggleRecurrenceRepeatWeek = useCallback((day) => {
    setCustomRecurrenceValues((prev) => ({
      ...prev,
      recurrenceRepeatWeek: prev.recurrenceRepeatWeek.includes(day)
        ? prev.recurrenceRepeatWeek.filter((checkedDay) => checkedDay !== day)
        : [...prev.recurrenceRepeatWeek, day],
    }));
  }, []);

  if (!ready) return null;

  const isDisabledRecurrence =
    task.status === TaskStatus.COMPLETED && _recurrence.recurrence !== TaskRecurrence.NONE
      ? true
      : false;

  const dateValidators = [{ earliestDate: 'currentDate' }];

  const endAfterOccurrences = watchRecurrenceType === TaskRecurrenceEnds.END_AFTER;

  return (
    <RecurrenceRow>
      <SelectMetaField
        defaultValue={watchRecurrence}
        label={t('form.recurrence', 'Recurrence')}
        metadata={metadata}
        minWidth={144}
        name="recurrence"
        disabled={isDisabledRecurrence}
        options={!isDisabledRecurrence ? recurrenceSelectOptions : []}
        placeholder={isDisabledRecurrence ? watchRecurrence : ''}
        handleChange={handleRecurrenceChange}
      />
      {watchRecurrence !== TaskRecurrence.NONE && (
        <RecurrenceContainer className={isDisabledRecurrence ? 'read-only' : ''}>
          <div className="label">{t('form.ends', 'Ends')}</div>
          <RecurrenceEndsWrapper>
            <Radio
              id="recurrence-ends-on"
              name="recurrenceType"
              label={t('form.on', 'on')}
              value={TaskRecurrenceEnds.END_BY_DATE}
              ref={register}
              checked={watchRecurrenceType === TaskRecurrenceEnds.END_BY_DATE}
              onChange={(e) => onRecurrenceTypeChange(e.target.value)}
              disabled={isDisabledRecurrence}
            />
            <RecurrenceDateContainer>
              <DateMetaField
                defaultValue={_recurrence ? _recurrence.recurrenceEndByDate : ''}
                metadata={{
                  ...metadata,
                  readOnly:
                    isDisabledRecurrence || watchRecurrenceType !== TaskRecurrenceEnds.END_BY_DATE,
                  required: watchRecurrenceType === TaskRecurrenceEnds.END_BY_DATE ? true : false,
                }}
                name="recurrenceEndByDate"
                validators={dateValidators}
              />
            </RecurrenceDateContainer>
            <Radio
              id="recurrence-ends-after"
              name="recurrenceType"
              label={t('form.after', 'After')}
              value={TaskRecurrenceEnds.END_AFTER}
              ref={register}
              checked={watchRecurrenceType === TaskRecurrenceEnds.END_AFTER}
              onChange={(e) => onRecurrenceTypeChange(e.target.value)}
              disabled={isDisabledRecurrence}
            />
            <OccurrencesInputWrapper>
              <OccurrencesInput
                defaultValue={_recurrence ? parseInt(_recurrence.recurrenceEndByCount) : null}
                metadata={{
                  ...metadata,
                  readOnly:
                    isDisabledRecurrence || watchRecurrenceType !== TaskRecurrenceEnds.END_AFTER,
                  required: endAfterOccurrences,
                }}
                isInteger
                label={t('form.occurrences', 'occurrences')}
                name="recurrenceEndByCount"
                min={templateMode === TaskTemplateModes.CREATE ? 1 : 0}
                placeholder="#"
                rules={{
                  validate: {
                    lessThanOne: (value) => {
                      if (!endAfterOccurrences) return true;
                      return templateMode === TaskTemplateModes.CREATE ? value > 0 : value >= 0;
                    },
                  },
                }}
              />
            </OccurrencesInputWrapper>
          </RecurrenceEndsWrapper>
        </RecurrenceContainer>
      )}
      {watchRecurrence === TaskRecurrence.CUSTOM && (
        <RecurrenceCustom className={isDisabledRecurrence ? 'read-only' : ''}>
          {recurrenceCustomSummary}
          {!isDisabledRecurrence && (
            <EditCustomButton onClick={() => setShowRecurrenceCustom(true)}>
              <EditIcon />
            </EditCustomButton>
          )}
        </RecurrenceCustom>
      )}
      {!isDisabledRecurrence &&
        watchRecurrence === TaskRecurrence.CUSTOM &&
        showRecurrenceCustom && (
          <RecurrenceCustomMenu>
            <RepeatContainer>
              <NumberInput
                label={t('form.repeat_every', 'Repeat Every')}
                type="number"
                onChange={(e) => handleRecurrenceCustom({ recurrenceCycle: e.target.value })}
                defaultValue={
                  customRecurrenceValues.recurrenceCycle > 0
                    ? Number(customRecurrenceValues.recurrenceCycle)
                    : ''
                }
                min={1}
                spinner
                error={!customRecurrenceValues.recurrenceCycle}
                required={true}
              />
              <Select
                primary
                value={repeatIntervalSelectOptions.find(
                  (option) => option.value === customRecurrenceValues.recurrenceCycleType,
                )}
                onChange={(option) => handleRecurrenceCustom({ recurrenceCycleType: option.value })}
                minWidth={100}
                options={repeatIntervalSelectOptions}
                error={!customRecurrenceValues.recurrenceCycleType}
                required={true}
              />
            </RepeatContainer>
            {/* Hiding for MVP0 - Story #836 */}
            <ConditionalRender shouldRender={false}>
              {customRecurrenceValues.recurrenceCycleType === RepeatInterval.WEEKS && (
                <div>
                  <div className="label">{t('form.repeat_on', 'Repeat on')}</div>
                  <RecurrenceWeekContainer>
                    {Object.entries(Days).map((day) => (
                      <RecurrenceWeekCheckbox
                        key={day[0]}
                        label={day[1].charAt(0)}
                        checkState={
                          customRecurrenceValues.recurrenceRepeatWeek.includes(day[0])
                            ? CheckedState.CHECKED
                            : CheckedState.UNCHECKED
                        }
                        onChange={() => {
                          toggleRecurrenceRepeatWeek(day[0]);
                        }}
                      />
                    ))}
                  </RecurrenceWeekContainer>
                </div>
              )}
              {customRecurrenceValues.recurrenceCycleType === RepeatInterval.MONTHS && (
                <RepeatContainer>
                  <StyledDateContainer>
                    <div className="label">{t('form.repeat_on', 'Repeat on')}</div>
                    <DatePicker
                      selected={customRecurrenceValues.recurrenceRepeatMonth}
                      startDate={customRecurrenceValues.recurrenceRepeatMonth}
                      onChange={(date) => handleRecurrenceCustom({ recurrenceRepeatMonth: date })}
                      inline={true}
                    />
                  </StyledDateContainer>
                </RepeatContainer>
              )}
            </ConditionalRender>
            <ButtonContainer>
              <Button type="button" onClick={onRecurrenceCustomCancel}>
                {t('general.cancel', 'Cancel')}
              </Button>
              <Button
                type="button"
                onClick={onRecurrenceCustomOk}
                disabled={customRecurrenceValues.recurrenceCycle < 1}
              >
                {t('general.ok', 'Ok')}
              </Button>
            </ButtonContainer>
          </RecurrenceCustomMenu>
        )}
    </RecurrenceRow>
  );
};

Recurrence.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.string,
};

Recurrence.defaultProps = {
  task: {},
};
