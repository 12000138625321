import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SortDirection, TimeAggr } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';

import { chartDefPropTypes, FilterOptions, FilterDefs } from '../../models';

const NAMESPACE = 'analyze.dashboard';
const sortOptions = FilterOptions[FilterDefs.SORT];
const entitiesOptions = FilterOptions[FilterDefs.SHOW_HIDE_ENTITIES];

const LinkContainer = styled.div`
  display: flex;
`;

const LinkTextGroup = styled.div`
  display: flex;
  margin-left: 10px;
`;

const LinkText = styled.span`
  color: ${({ active, theme }) =>
    active ? theme.subNavigation.linkActiveColor : theme.subNavigation.linkColor};
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
`;

const LinkTextSep = styled.span`
  color: ${({ theme }) => theme.subNavigation.linkColor};
  margin-left: 5px;
  cursor: default;
`;

export const DashboardChartStats = ({
  namespace,
  chartDef,
  showSort,
  showDateAggregation,
  sortDirection,
  dataCount,
  maxEntities,
  maxEntitiesSelect,
  handleSortChange,
  handleMaxEntities,
  timeAggrOptions,
  timeAggregation,
  onTimeAggrChange,
}) => {
  const { ready, t } = useTranslation([namespace], {
    useSuspense: false,
  });

  const [sortState, setSortState] = useState(sortDirection);
  const [maxEntitiesState, setMaxEntitiesState] = useState(maxEntities);

  const maxEntitiesOptions = useMemo(
    () => entitiesOptions.filter(({ key }) => key === FilterDefs.SHOW_ALL || key === sortState),
    [sortState],
  );

  useEffect(() => {
    setSortState(sortDirection);
    setMaxEntitiesState(maxEntities);
  }, [sortDirection, maxEntities, chartDef]);

  useEffect(() => {
    handleSortChange(sortState);
  }, [sortState, handleSortChange]);

  useEffect(() => {
    handleMaxEntities(maxEntitiesState);
  }, [maxEntitiesState, handleMaxEntities]);

  const handleTimeAggrChange = useCallback(
    (e) => {
      if (e.target?.getAttribute('value')) onTimeAggrChange(e.target.getAttribute('value'));
    },
    [onTimeAggrChange],
  );

  if (!ready) {
    return null;
  }

  return (
    <>
      <LinkContainer>
        {maxEntitiesSelect && dataCount > maxEntities && (
          <LinkTextGroup>
            {maxEntitiesOptions.map((option, i) => (
              <span
                data-testid={AnalyzeLocators.ANALYZE_CHART_DATA.concat(option.label)}
                key={option.key}
              >
                <LinkText
                  onClick={() => setMaxEntitiesState(option.value)}
                  active={maxEntitiesState === option.value}
                >
                  {t('kpi_chart.hide_entities', {
                    sort: t(option.a11yKey, option.label),
                    count: option.value,
                  })}
                </LinkText>
                {i % 2 == 0 && <LinkTextSep>{t('kpi_chart.separator', '|')}</LinkTextSep>}
              </span>
            ))}
          </LinkTextGroup>
        )}
        {showSort && (
          <LinkTextGroup>
            {sortOptions.map((option, i) => (
              <span key={option.key}>
                <LinkText
                  data-testid={AnalyzeLocators.ANALYZE_CHART_DATA.concat(option.value)}
                  onClick={() => setSortState(option.value)}
                  active={sortState === option.value}
                >
                  {t(option.a11yKey, option.label)}
                </LinkText>
                {i % 2 == 0 && <LinkTextSep>{t('kpi_chart.separator', '|')}</LinkTextSep>}
              </span>
            ))}
          </LinkTextGroup>
        )}
        {showDateAggregation && (
          <LinkTextGroup>
            {timeAggrOptions.map((option, i) => (
              <span key={option.key}>
                {i !== 0 && <LinkTextSep>{t('kpi_chart.separator', '|')}</LinkTextSep>}
                <LinkText
                  data-testid={AnalyzeLocators.ANALYZE_CHART_DATA.concat(option.value)}
                  onClick={handleTimeAggrChange}
                  active={timeAggregation === option.value}
                  value={option.value}
                >
                  {t(option.a11yKey, option.label)}
                </LinkText>
              </span>
            ))}
          </LinkTextGroup>
        )}
      </LinkContainer>
    </>
  );
};

DashboardChartStats.propTypes = {
  namespace: PropTypes.string,
  chartDef: chartDefPropTypes.isRequired,
  showSort: PropTypes.bool,
  showDateAggregation: PropTypes.bool,
  sortDirection: PropTypes.string,
  timeAggregation: PropTypes.string,
  timeAggrOptions: PropTypes.arrayOf(PropTypes.object),
  dataCount: PropTypes.number,
  maxEntities: PropTypes.number,
  maxEntitiesSelect: PropTypes.bool,
  handleSortChange: PropTypes.func,
  onTimeAggrChange: PropTypes.func,
  handleMaxEntities: PropTypes.func,
};

DashboardChartStats.defaultProps = {
  children: null,
  namespace: NAMESPACE,
  chartDef: null,
  showSort: false,
  showDateAggregation: false,
  sortDirection: SortDirection.ASC,
  timeAggregation: TimeAggr.DAILY,
  timeAggrOptions: [],
  dataCount: null,
  maxEntities: FilterDefs.MAX_ENTITIES,
  maxEntitiesSelect: null,
  handleSortChange: () => {},
  onTimeAggrChange: () => {},
  handleMaxEntities: () => {},
};

DashboardChartStats.displayName = 'DashboardChartStats';

export default DashboardChartStats;
