import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DataExplorerAxisType } from '@ge/feat-analyze/models/data-explorer-defs';

const FooterButtons = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignalLabels = styled.div`
  flex: 1;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  padding: 0 20px;
  display: flex;
  justify-content: end;
  span {
    display: flex;
  }
`;

const AxisName = styled.span`
  margin-left: 16px;
  &:after {
    content: ':';
    margin-right: 8px;
  }
`;

const StyledButton = styled(Button)`
  text-transform: capitalize;
`;

const AxisType = DataExplorerAxisType;

const DataExplorerSignalDialogFooter = ({
  onClose,
  onConfirm,
  selected,
  yAxisCount,
  yAxisMaxCount,
}) => {
  const { t, ready } = useTranslation(['analyze.data-explorer', 'general'], { useSuspense: false });

  if (!ready) return null;

  return (
    <FooterButtons>
      <StyledButton onClick={onClose}>{t('general:cancel', 'Cancel')}</StyledButton>
      <SignalLabels>
        <span>
          <AxisName>{AxisType.X}</AxisName>
          <span>{selected[AxisType.X]?.name ?? ' - '}</span>
        </span>
        {yAxisMaxCount === 1 ? (
          <span key={AxisType.Y}>
            <AxisName>{AxisType.Y}</AxisName>
            <span>
              {selected[AxisType.Y]?.[0]
                ?.map((signal) =>
                  signal.timeAggr
                    ? signal.name + ' [' + signal.timeAggr.split('_')[0] + ']'
                    : signal.name,
                )
                .join(', ') ?? ' - '}
            </span>
          </span>
        ) : (
          [...new Array(yAxisCount)].map((_, axisIndex) => {
            return (
              <span key={AxisType.Y + axisIndex}>
                <AxisName>{AxisType.Y + (axisIndex + 1)}</AxisName>
                <span>
                  {selected[AxisType.Y]?.[axisIndex]
                    ?.map((signal) =>
                      signal.timeAggr
                        ? signal.name + ' [' + signal.timeAggr.split('_')[0] + ']'
                        : signal.name,
                    )
                    .join(', ') ?? ' - '}
                </span>
              </span>
            );
          })
        )}
      </SignalLabels>
      <StyledButton
        primary
        onClick={onConfirm}
        disabled={!selected[AxisType.X] || !selected[AxisType.Y]?.length}
      >
        {t('dialogs.view', 'View')}
      </StyledButton>
    </FooterButtons>
  );
};

DataExplorerSignalDialogFooter.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  selected: PropTypes.object,
  yAxisCount: PropTypes.number,
  yAxisMaxCount: PropTypes.number,
};

DataExplorerSignalDialogFooter.defaultProps = {
  onClose: () => null,
  onConfirm: () => null,
  selected: [],
  yAxisCount: 1,
  yAxisMaxCount: 1,
};

export default DataExplorerSignalDialogFooter;
