import { TableFilterTypes } from '@ge/models/constants';

export const SentReportListColumns = {
  GROUP_SENT_REPORT_NAME: 'group-sent-report-name',
  SENT_REPORT_NAME: 'name',

  GROUP_DOWNLOAD: 'group-download',
  DOWNLOAD: 'download',

  GROUP_ATTACHMENTS: 'group-attachments',
  ATTACHMENTS: 'attachments',

  GROUP_TEMPLATE: 'group-template',
  TEMPLATE: 'reportType',

  GROUP_SENT_REPORT_SCOPE: 'group-sent-report-scope',
  SENT_REPORT_SCOPE: 'scope',

  GROUP_CREATED_BY: 'group-created-by',
  CREATED_BY: 'createdBy',

  GROUP_DATE_SENT: 'group-date-sent',
  DATE_SENT: 'date-sent',

  GROUP_ACTIONS: 'group-actions',
  ACTIONS: 'actions',
};

// Define all available columns in the roles table.
export const SentReportListColumnDefs = {
  [SentReportListColumns.GROUP_SENT_REPORT_NAME]: {
    cols: {
      [SentReportListColumns.SENT_REPORT_NAME]: {
        align: 'left',
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.name',
            a11yDefault: 'Report Name',
            sortValue: 'name',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_DOWNLOAD]: {
    cols: {
      [SentReportListColumns.DOWNLOAD]: {
        align: 'center',
        labels: [
          {
            a11yKey: 'table.download',
            a11yDefault: 'Download',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_ATTACHMENTS]: {
    cols: {
      [SentReportListColumns.ATTACHMENTS]: {
        labels: [
          {
            a11yKey: 'table.attachments',
            a11yDefault: 'Attachments',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_TEMPLATE]: {
    cols: {
      [SentReportListColumns.TEMPLATE]: {
        align: 'left',
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.template',
            a11yDefault: 'Template',
            sortValue: 'reportType',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_SENT_REPORT_SCOPE]: {
    cols: {
      [SentReportListColumns.SENT_REPORT_SCOPE]: {
        align: 'left',
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.scope',
            a11yDefault: 'Scope',
            sortValue: 'scope',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_CREATED_BY]: {
    cols: {
      [SentReportListColumns.CREATED_BY]: {
        align: 'left',
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.created-by',
            a11yDefault: 'Created By',
            sortValue: 'createdBy',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_DATE_SENT]: {
    cols: {
      [SentReportListColumns.DATE_SENT]: {
        align: 'center',
        filterType: TableFilterTypes.DATE,
        labels: [
          {
            a11yKey: 'table.date-sent',
            a11yDefault: 'Date Sent',
            sortValue: 'dateSent',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },

  [SentReportListColumns.GROUP_ACTIONS]: {
    cols: {
      [SentReportListColumns.ACTIONS]: {
        align: 'center',
        labels: [
          {
            a11yKey: 'table.actions',
            a11yDefault: 'Actions',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
};

export const globalSentReportListCols = [
  {
    id: SentReportListColumns.GROUP_SENT_REPORT_NAME,
    cols: [
      {
        id: SentReportListColumns.SENT_REPORT_NAME,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_DOWNLOAD,
    cols: [
      {
        id: SentReportListColumns.DOWNLOAD,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_ATTACHMENTS,
    cols: [
      {
        id: SentReportListColumns.ATTACHMENTS,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_TEMPLATE,
    cols: [
      {
        id: SentReportListColumns.TEMPLATE,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_SENT_REPORT_SCOPE,
    cols: [
      {
        id: SentReportListColumns.SENT_REPORT_SCOPE,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_CREATED_BY,
    cols: [
      {
        id: SentReportListColumns.CREATED_BY,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_DATE_SENT,
    cols: [
      {
        id: SentReportListColumns.DATE_SENT,
        visible: true,
      },
    ],
  },
  {
    id: SentReportListColumns.GROUP_ACTIONS,
    cols: [
      {
        id: SentReportListColumns.ACTIONS,
        visible: true,
      },
    ],
  },
];
