import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MiniBarChart } from '@ge/components/charts';
import { Icon } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Table, TableArrow, Tbody, Td, Th, Thead } from '@ge/components/table';
import { KpiCategoryDefs, PanelColumnWidths, Placeholders } from '@ge/models/constants';
import { getAssetIcon } from '@ge/shared/util/asset-icon';

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.theme.table.iconLightColor,
}))`
  margin-top: 2px;
`;

// MVP0 - removed because trend not supported yet
// const ArrowIcon = styled(Icon).attrs((props) => ({
//   size: 10,
//   color: props.down ? `${props.theme.table.arrowColorRed}` : `${props.theme.table.arrowColor}`,
// }))`
//   transform: ${(props) => (props.down ? `rotate(180deg)` : `rotate(0deg)`)};
// `;

const CapacityFactor = styled.div`
  display: flex;
  align-items: center;
  > .percent {
    margin: 0 8px 0 4px;
  }
`;

const AssetPerformanceTable = ({
  assets,
  sortAction,
  sortMetric,
  sortDirection,
  onAssetSelect,
}) => {
  const { t, ready } = useTranslation(['monitor.assets'], {
    useSuspense: false,
  });

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  if (!ready) {
    return <Loader />;
  }

  return (
    <Table height={400} compressed>
      <Thead transparent noTitles>
        <tr>
          <Th
            labels={{
              [t('table.type', 'Type')]: 'type',
            }}
            border
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XSMALL}
          />
          <Th
            labels={{
              [t('table.name', 'Name')]: 'name',
            }}
            border
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
          />
          <Th
            labels={{
              [t(
                'table.tba_availability_technical',
                'TBA - Technical (%)',
              )]: `metrics.performance.${KpiCategoryDefs.TBA}`,
            }}
            border
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
            whiteSpace="normal"
          />
          <Th
            labels={{
              [t(
                'table.unproduced_energy_mwh',
                'Unproduced Energy (MWh)',
              )]: `metrics.performance.${KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT}`,
            }}
            border
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
            whiteSpace="normal"
          />
          <Th
            labels={{
              [t(
                'table.actual_production_mwh',
                'Actual Production (MWh)',
              )]: `metrics.performance.${KpiCategoryDefs.PRODUCTION_ACTUAL}`,
            }}
            border
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
            whiteSpace="normal"
          />
          <Th
            labels={{
              [t(
                'table.capacity_factor',
                'Capacity Factor (%)',
              )]: `metrics.performance.${KpiCategoryDefs.CAPACITY_FACTOR}`,
            }}
            border
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.LARGE}
            whiteSpace="normal"
          />
          <Th
            labels={{
              [t(
                'table.event_coverage',
                'Event Coveragge (%)',
              )]: `metrics.performance.${KpiCategoryDefs.EVENT_COVERAGE}`,
            }}
            border
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
            whiteSpace="normal"
          />
          <Th
            border
            width={PanelColumnWidths.SMALL}
            labels={{
              [t('table.asset_details', 'Asset\nDetails')]: 'asset_details',
            }}
          />
        </tr>
      </Thead>
      <Tbody transparent>
        {assets?.map((asset) => {
          const { performance } = asset.metrics ?? {};

          return (
            <tr key={asset.id} onClick={(e) => onAssetSelect(e, asset)}>
              <Td align="center" border width={PanelColumnWidths.XSMALL}>
                <TypeIcon icon={getAssetIcon(asset.type)} />
              </Td>
              <Td align="left" border width={PanelColumnWidths.MEDIUM}>
                {asset.name}
              </Td>
              <Td align="left" border width={PanelColumnWidths.MEDIUM}>
                {performance?.[KpiCategoryDefs.TBA]
                  ? `${performance[KpiCategoryDefs.TBA]}%`
                  : Placeholders.DASH}
              </Td>
              <Td align="left" border width={PanelColumnWidths.MEDIUM}>
                {performance?.[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]
                  ? performance[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]
                  : Placeholders.DASH}
              </Td>
              <Td align="left" border width={PanelColumnWidths.MEDIUM}>
                {performance?.[KpiCategoryDefs.PRODUCTION_ACTUAL]
                  ? performance[KpiCategoryDefs.PRODUCTION_ACTUAL]
                  : Placeholders.DASH}
              </Td>
              <Td align="center" border width={PanelColumnWidths.LARGE}>
                <CapacityFactor>
                  {/* MVP0 disabling trend as it's not available yet */}
                  {/* <ArrowIcon icon={Icons.ARROW_UP} down /> */}
                  <div className="percent">
                    {performance?.[KpiCategoryDefs.CAPACITY_FACTOR]?.toFixed(1)}
                  </div>
                  <MiniBarChart percent={performance?.[KpiCategoryDefs.CAPACITY_FACTOR]} />
                </CapacityFactor>
              </Td>
              <Td align="center" border width={PanelColumnWidths.MEDIUM}>
                {performance?.[KpiCategoryDefs.EVENT_COVERAGE]
                  ? `${performance[KpiCategoryDefs.EVENT_COVERAGE]}%`
                  : Placeholders.DASH}
              </Td>
              <Td border width={PanelColumnWidths.SMALL}>
                <TableArrow />
              </Td>
            </tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

AssetPerformanceTable.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onAssetSelect: PropTypes.func,
};

AssetPerformanceTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onAssetSelect: () => null,
};

export default AssetPerformanceTable;
