import React, { useContext } from 'react';

import GlobalRoles from '../components/global-roles';
import TenantRoles from '../components/tenant-roles';
import { TenantContext } from '../context/tenant-context';

const AdminRoles = () => {
  const {
    tenantState: { selectedTenant },
  } = useContext(TenantContext);

  return <>{selectedTenant ? <TenantRoles /> : <GlobalRoles />}</>;
};

export default AdminRoles;
