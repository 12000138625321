import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { EntityType } from '@ge/models/constants';
import { elevations } from '@ge/tokens/elevations';

import { DashboardKpiChart } from '../../components/dashboard/dashboard-kpi-chart';
import useRegionKpiData from '../../data-hooks/use-region-kpi-data';
import { chartDefPropTypes, DashboardGaugeDefs, entityPropType } from '../../models';

import { regionCardSparkOptions } from './chart-consts';
import { DashboardGaugeCard } from './dashboard-gauge-card';

const DefaultGaugeDefs = DashboardGaugeDefs[EntityType.REGION].defaultValues;

const StyledRegionCard = styled.div`
  display: flex;
  background: ${(props) => props.theme.analyze.regionCard.backgroundColor};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  min-height: 200px;
  &:first-child {
    margin-top: 5px;
  }
  .region-details {
    background: ${(props) => props.theme.analyze.regionCard.detailsBackground};
    padding-bottom: 12px;
    min-width: 240px;
    width: 30%;
    border-radius: 4px 0 0 4px;
    box-shadow: ${(props) => props.theme.analyze.regionCard.boxShadow};
    z-index: ${elevations.P1};
    overflow: hidden;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-bottom: 1px solid ${(props) => props.theme.analyze.regionCard.titleBorderColor};

      > .name-container,
      .icon-container {
        display: flex;
        align-items: center;
      }

      > .icon-container {
        padding-top: 5px;
      }
    }
  }
  .region-graph {
    background: ${(props) => props.theme.analyze.regionCard.chartBackgroundColor};
    width: 70%;
    border-radius: 0 4px 4px 0;
  }
`;

const RegionIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.theme.analyze.regionCard.regionIcon,
  icon: Icons.FLEET,
}))`
  margin-right: 6px;
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.analyze.regionCard.chevronIcon,
  icon: Icons.ARROW_UP,
  rotate: 90,
}))`
  margin-left: auto;
`;

const RegionCard = ({
  chartDef,
  chartHeight,
  categories: _categories,
  regionId,
  endDate,
  startDate,
  sortBy,
  sortDirection,
  namespace,
  onSelected,
  parentEntity,
  selectedEntities,
  showTitle,
  theme: {
    analyze: { regionCard },
  },
  xAxisTitle,
  gaugeDefs,
  timeAggregation,
  timeAggrOptions,
  onTimeAggrChange,
}) => {
  const { getRegionById } = useStoreState((state) => state.regions);

  const categories = Array.from(new Set([...Object.values(gaugeDefs), ..._categories]));

  const { data: regionKpiData, isLoading } = useRegionKpiData({
    categories,
    endDate: endDate,
    startDate: startDate,
    regionId,
    timeAggr: timeAggregation,
    sortBy,
    sortDirection,
    entityAggr: EntityType.REGION,
  });

  // Region data
  const regionData = getRegionById(regionId);
  const data = { region: regionData, ...regionKpiData };
  const {
    region,
    [gaugeDefs.dialGauge1]: _dialGauge1,
    [gaugeDefs.dialGauge2]: _dialGauge2,
    [gaugeDefs.kpi1]: _kpi1,
    [gaugeDefs.kpi2]: _kpi2,
  } = data;

  const dialGauge1 = { type: gaugeDefs.dialGauge1, ..._dialGauge1 };
  const dialGauge2 = { type: gaugeDefs.dialGauge2, ..._dialGauge2 };
  const kpi1 = { type: gaugeDefs.kpi1, ..._kpi1 };
  const kpi2 = { type: gaugeDefs.kpi2, ..._kpi2 };

  const to = region?.id ? `/analyze/region/${region?.id}` : '';

  // theme spark chart
  regionCardSparkOptions.xAxis.gridLineColor = regionCard.gridLineColor;
  regionCardSparkOptions.xAxis.plotLines[0].color = regionCard.dayVerticalLineColor;
  regionCardSparkOptions.xAxis.plotLines[0].label.style.fill = regionCard.dayVerticalLineColor;
  regionCardSparkOptions.xAxis.lineColor = regionCard.axisLineColor;
  regionCardSparkOptions.yAxis.gridLineColor = regionCard.gridLineColor;
  regionCardSparkOptions.yAxis.plotLines[0].color = regionCard.targetLine;

  const CardComponent = (
    <StyledRegionCard>
      <div className="region-details">
        <div className="title">
          <div className="name-container">
            {region?.id && <RegionIcon />}
            <h3>{region?.name}</h3>
          </div>
          <div className="icon-container">
            <ArrowIcon />
          </div>
        </div>
        <DashboardGaugeCard
          dialGauge1={dialGauge1}
          dialGauge2={dialGauge2}
          kpi1={kpi1}
          kpi2={kpi2}
        />
      </div>
      <div className="region-graph">
        {/* Removing for MVP0+ */}
        {/* <SparkLineChart data={availability.data} height={200} options={regionCardSparkOptions} /> */}
        <DashboardKpiChart
          chartDef={chartDef}
          height={chartHeight}
          data={data}
          entityType={EntityType.REGION}
          hideBorder
          isLoading={isLoading}
          namespace={namespace}
          onSelected={onSelected}
          parentEntity={parentEntity}
          selectedEntities={selectedEntities}
          showTitle={showTitle}
          showToggle={false}
          xAxisTitle={xAxisTitle}
          timeAggregation={timeAggregation}
          timeAggrOptions={timeAggrOptions}
          onTimeAggrChange={onTimeAggrChange}
          showTimeAggr={false}
        />
      </div>
    </StyledRegionCard>
  );

  return <div>{region?.id ? <NavLink to={to}>{CardComponent}</NavLink> : CardComponent}</div>;
};

RegionCard.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  categories: PropTypes.instanceOf(Array).isRequired,
  chartDef: chartDefPropTypes.isRequired,
  chartHeight: PropTypes.number,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  onSelected: PropTypes.func,
  parentEntity: entityPropType,
  regionId: PropTypes.string.isRequired,
  selectedEntities: PropTypes.arrayOf(entityPropType),
  showTitle: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  xAxisTitle: PropTypes.string,
  gaugeDefs: PropTypes.object,
  timeAggregation: PropTypes.string,
  timeAggrOptions: PropTypes.arrayOf(PropTypes.object),
  onTimeAggrChange: PropTypes.func,
};

RegionCard.defaultProps = {
  gaugeDefs: DefaultGaugeDefs,
};

export default withRouter(withTheme(RegionCard));
