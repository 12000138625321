import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useGlobalFilters } from '@ge/feat-analyze/hooks';
import { KpiCategorySeriesType } from '@ge/feat-analyze/models/constants';
import { downloadFile } from '@ge/feat-analyze/util';
import {
  TimeAggr,
  EntityType,
  QueryKey,
  DateTimeFormats,
  ContentType,
  FileType,
  SortDirection,
} from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import { getFilename } from './use-export-graph';

const getQueryKey = ({ entity, entityType, graph, filters }) => {
  const { startDate, endDate, timeAggr = TimeAggr.DAILY } = graph.metadata ?? {};
  if (entityType === EntityType.ASSET) {
    return [
      QueryKey.ASSET_KPI_DATA,
      {
        assetId: entity.id,
        startDate,
        endDate,
        timeAggr,
        siteId: entity.site?.id,
      },
    ];
  } else if (entityType === EntityType.SITE) {
    const queryKey = { startDate, endDate, timeAggr };
    if (filters) {
      queryKey.filters = { ...filters, siteIds: [entity.id] };
    }
    return [entity.id, queryKey];
  } else if (entityType === EntityType.SITES) {
    return [
      QueryKey.SITES_KPI_DATA,
      {
        startDate,
        endDate,
        timeAggr,
        filters,
      },
    ];
  } else if (entityType === EntityType.REGION) {
    return [
      {
        regionId: entity.id,
        startDate,
        endDate,
        timeAggr,
        filters,
      },
    ];
  }
  return [];
};

const dateFormatter = (date, timeAggr) => {
  switch (timeAggr) {
    case TimeAggr.MONTHLY:
      return date;
    case TimeAggr.WEEKLY:
      return `WK ${String(date).substr(4, 2)}`;
    default:
      return dayjs(date).format(DateTimeFormats.DEFAULT_DATE);
  }
};

const transformCsvData = ({ kpiData, entity, entityType, graph, entities, t }) => {
  const {
    startDate,
    endDate,
    timeAggr = TimeAggr.DAILY,
    categories = [],
    seriesType,
  } = graph.metadata ?? {};
  if (seriesType === KpiCategorySeriesType.TIME_SERIES) {
    const primaryCols = [[], []];
    if (entityType === EntityType.ASSET) {
      primaryCols[0] = [t(`asset_name`, 'Asset Name')];
      primaryCols[1] = [entity.name];
    } else if (entityType === EntityType.SITE) {
      primaryCols[0] = [t(`site_name`, 'Site Name')];
      primaryCols[1] = [entity.name];
    } else if (entityType === EntityType.SITES) {
      primaryCols[0] = [t(`site_name`, 'Site Name')];
      primaryCols[1] = [entity.name];
    } else if (entityType === EntityType.REGION) {
      primaryCols[0] = [t(`region_name`, 'Region Name')];
      primaryCols[1] = [entity.name];
    }
    const fields = [...primaryCols[0], t('date', 'Date')];
    const values = new Map();
    categories.forEach((category) => {
      fields.push(t(`dynamic.kpi_chart.${category}`, category));
      kpiData[category]?.timeSeriesData?.forEach((v) => {
        const item = values.get(v.date) ?? {};
        values.set(v.date, {
          [category]: v.value,
          ...item,
        });
      });
    });
    const data = Array.from(values).map(([date, val]) => [
      ...primaryCols[1],
      dateFormatter(date, timeAggr),
      ...categories.map((category) => val[category]),
    ]);

    return { fields, data };
  } else {
    const fields = [];
    if (entityType === EntityType.ASSET || entityType === EntityType.SITE) {
      fields.push(t(`asset_name`, 'Asset Name'));
    } else if (entityType === EntityType.SITES || entityType === EntityType.REGION) {
      fields.push(t(`site_name`, 'Site Name'));
    }
    fields.push(t('date_range', 'Date Range'));
    const values = new Map();
    categories.forEach((category) => {
      fields.push(t(`dynamic.kpi_chart.${category}`, category));
      kpiData[category]?.entityData?.sort(sorter('value', SortDirection.ASC)).forEach((v) => {
        const item = values.get(v.entity.id) ?? {};
        values.set(v.entity.id, {
          [category]: v.value,
          ...item,
        });
      });
    });

    const datarange = t('from_date_to_date', {
      from: dayjs(startDate).format(DateTimeFormats.DEFAULT_DATE),
      to: dayjs(endDate).format(DateTimeFormats.DEFAULT_DATE),
    });

    const data = Array.from(values).map(([key, val]) => [
      entities[key]?.name ?? '',
      datarange,
      ...categories.map((category) => val[category]),
    ]);

    return { fields, data };
  }
};

/**
 * Use asset KPI data.
 */
export const useKpiDataDownload = () => {
  const { sites } = useStoreState((state) => state.sites);
  const { assets } = useStoreState((state) => state.assets);

  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });
  const queryClient = useQueryClient();
  const filters = useGlobalFilters();

  const downloadPNG = () => {};

  const downloadCSV = useCallback(
    ({ entity, entityType, graph }) => {
      if (!entity || !entityType || !graph) return null;

      const queryKey = getQueryKey({
        entity,
        entityType,
        graph,
        filters,
      });

      const queries = queryClient.getQueryCache().findAll(queryKey, { active: true });
      const queryData = queries.reduce((result, { state }) => {
        return !state.error && state.data ? { ...result, ...state.data.data } : result;
      }, {});

      const { fields, data } = transformCsvData({
        kpiData: queryData,
        entity,
        entityType,
        graph,
        entities:
          entityType === EntityType.SITES || entityType === EntityType.REGION ? sites : assets,
        t,
      });

      const filename = getFilename(entity, graph, FileType.CSV);

      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [filters, sites, assets, queryClient, t],
  );

  return { downloadPNG, downloadCSV };
};
