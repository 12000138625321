import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';
// import { MapView } from '@ge/components/map-view';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useSitesTable } from '@ge/feat-monitor/data-hooks';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { MonitorDefs, PresentationTypes, EntityType } from '@ge/models/constants';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { FourOhFour } from '@ge/web-client/src/app/components/404/404';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';
// TODO (astone): This is an unholy reference to DWF from a feature! Must remove!
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';

import SitesTable from '../components/tables/sites-table';
import StorageSitesTable from '../components/tables/storage-sites-table';
import {
  defaultSiteCols,
  defaultStorageSiteCols,
  SitesColumnDefs,
  StorageSitesColumnDefs,
  SitesNavItems,
} from '../models';

const SORT_WIND_STATE_ID = 'monitor-wind-sites';
const SORT_STORAGE_STATE_ID = 'monitor-storage-sites';
const SITES = MonitorDefs.SITES;

const VALID_ENDPOINTS = ['wind', 'storage'];

/**
 * Available sort metrics for Sites.
 */
export const SiteMetric = {
  NAME: 'site.name',
};

const stateIdMap = {
  [EntityType.WIND_SITE]: SORT_WIND_STATE_ID,
  [EntityType.STORAGE_SITE]: SORT_STORAGE_STATE_ID,
};

const columnDefsMap = {
  [EntityType.WIND_SITE]: SitesColumnDefs,
  [EntityType.STORAGE_SITE]: StorageSitesColumnDefs,
};
/**
 * Set list of view tyoes for Presentation Toggle
 */
const PresentationViews = [PresentationTypes.LIST];

const MonitorSites = (props) => {
  const { t, ready } = useTranslation(['monitor.sites'], {
    useSuspense: false,
  });

  const { isAuthorized } = useAuth();
  const {
    showSiteDetails,
    showStorageSiteDetails,
    showSolarSiteDetails,
    showHybridSiteDetails,
    panelShown,
  } = useContext(EntityDetailsContext);

  const {
    location: { search },
  } = props;

  const [isActive, setActiveState] = useState(PresentationTypes.LIST);
  const [currentSearch, setCurrentSearch] = useState({});

  // One custom hook call for Wind Site Filters
  const {
    filterDefs: windSitesFilterDefs,
    filterSequence: windSitesFilterSequence,
    onChange: setWindSitesFilterDefs,
  } = useFilterDefs({
    columnDefs: SitesColumnDefs,
    stateId: SORT_WIND_STATE_ID,
  });

  // One more custom hook call for Storage Site Filters
  const {
    filterDefs: storageSitesFilterDefs,
    filterSequence: storageSitesFilterSequence,
    onChange: setStorageSitesFilterDefs,
  } = useFilterDefs({
    columnDefs: StorageSitesColumnDefs,
    stateId: SORT_STORAGE_STATE_ID,
  });
  const [currentRow, setCurrentRow] = useState();

  useEffect(() => {
    !panelShown && setCurrentRow([]);
  }, [panelShown]);

  const containerRef = useRef(null);

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.MONITOR_SITES);

  const { filter } = useParams();

  // Table state management
  const {
    updateColumnVisibility: updateColumnVisibilityWind,
    setVisibleColumns: setVisibleColumnWind,
    visibleCols: visibleColsWind,
    sortDirection: sortDirectionWind,
    sortMetric: sortMetricWind,
    updateSortMetric: updateSortMetricWind,
  } = useColumnState({
    columnDefs: columnDefsMap[EntityType.WIND_SITE],
    defaultCols: defaultSiteCols,
    defaultSortMetric: SiteMetric.NAME,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: stateIdMap[EntityType.WIND_SITE],
  });

  const {
    updateColumnVisibility: updateColumnVisibilityStorage,
    setVisibleColumns: setVisibleColumnStorage,
    visibleCols: visibleColsStorage,
    sortDirection: sortDirectionStorage,
    sortMetric: sortMetricStorage,
    updateSortMetric: updateSortMetricStorage,
  } = useColumnState({
    columnDefs: columnDefsMap[EntityType.STORAGE_SITE],
    defaultCols: defaultStorageSiteCols,
    defaultSortMetric: SiteMetric.NAME,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: stateIdMap[EntityType.STORAGE_SITE],
  });

  const UpdateColAndSort = {
    [EntityType.STORAGE_SITE]: {
      updateColumnVisibility: updateColumnVisibilityStorage,
      setVisibleColumns: setVisibleColumnStorage,
      visibleCols: visibleColsStorage,
      sortDirection: sortDirectionStorage,
      sortMetric: sortMetricStorage,
      updateSortMetric: updateSortMetricStorage,
    },
    [EntityType.WIND_SITE]: {
      updateColumnVisibility: updateColumnVisibilityWind,
      setVisibleColumns: setVisibleColumnWind,
      visibleCols: visibleColsWind,
      sortDirection: sortDirectionWind,
      sortMetric: sortMetricWind,
      updateSortMetric: updateSortMetricWind,
    },
  };

  const FilterDefsFromSiteTypeFilter = {
    [EntityType.WIND_SITE]: windSitesFilterDefs,
    [EntityType.STORAGE_SITE]: storageSitesFilterDefs,
  };

  const FilterDefsFilterSequence = {
    [EntityType.WIND_SITE]: windSitesFilterSequence,
    [EntityType.STORAGE_SITE]: storageSitesFilterSequence,
  };

  const SetFilterDefsFromSiteTypeFilter = {
    [EntityType.WIND_SITE]: setWindSitesFilterDefs,
    [EntityType.STORAGE_SITE]: setStorageSitesFilterDefs,
  };

  // TODO: add logic in below hook for different sites table type storage and wind
  const {
    data: sites,
    filterValues,
    siteTypesToShow,
  } = useSitesTable({
    sortDirection:
      UpdateColAndSort[filter]?.sortDirection ||
      UpdateColAndSort[EntityType.WIND_SITE]?.sortDirection,
    sortMetric:
      UpdateColAndSort[filter]?.sortMetric || UpdateColAndSort[EntityType.WIND_SITE]?.sortMetric,
    search: currentSearch,
    filters: FilterDefsFromSiteTypeFilter[filter],
    filterSequence: FilterDefsFilterSequence[filter],
    siteType: filter,
  });

  const { onVisibilityChange: handleColumnChanges } = useManageColumnVisibility({
    subKey: SITES,
    featPrefs,
    savePrefs,
    setVisibleColumns:
      UpdateColAndSort[filter]?.setVisibleColumns ||
      UpdateColAndSort[EntityType.WIND_SITE]?.setVisibleColumns,
    updateColumnVisibility:
      UpdateColAndSort[filter]?.updateColumnVisibility ||
      UpdateColAndSort[EntityType.WIND_SITE]?.updateColumnVisibility,
  });

  const modifiedNavItems = useMemo(() => {
    return SitesNavItems.filter(({ capabilities }) =>
      capabilities ? isAuthorized({ capabilities }) : true,
    )?.filter((navItem) => siteTypesToShow[navItem?.label?.toLowerCase()]);
  }, [isAuthorized, siteTypesToShow]);

  // Process URL params for setting initial view type.
  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');

    if (view) {
      setActiveState(view);
    } else {
      setActiveState(PresentationTypes.LIST);
    }
  }, [isActive, search]);

  const handleSiteRowSelect = useCallback(
    (e, row) => {
      const {
        site: { id: siteId },
      } = row;
      e.stopPropagation();
      switch (filter) {
        case MonitorDefs.WIND_SITES:
          showSiteDetails(siteId);
          break;
        case MonitorDefs.STORAGE_SITES:
          showStorageSiteDetails(siteId);
          break;
        case MonitorDefs.SOLAR_SITES:
          showSolarSiteDetails(siteId);
          break;
        case MonitorDefs.HYBRID_SITES:
          showHybridSiteDetails(siteId);
          break;
        default:
          showSiteDetails(siteId);
      }
      setCurrentRow([siteId]);
    },
    [showSiteDetails, showStorageSiteDetails, showHybridSiteDetails, showSolarSiteDetails, filter],
  );

  if (!VALID_ENDPOINTS.includes(filter)) {
    return <FourOhFour />;
  }

  if (!sites) {
    return null;
  }

  return (
    <PageContainer i18nReady={ready} ref={containerRef}>
      <h1 className="pageTitle">{ready && t('sites', 'Sites')}</h1>
      <SubNavigation navItems={modifiedNavItems}>
        <ColumnSelectMenu
          translateFn={t}
          containerRef={containerRef}
          columnDef={columnDefsMap[filter]}
          columnState={UpdateColAndSort[filter]?.visibleCols}
          onMenuClose={handleColumnChanges}
        />
        <PresentationToggle viewList={PresentationViews} />
      </SubNavigation>
      {isActive === PresentationTypes.LIST && (
        <DetailsDismissInert>
          {filter === EntityType.WIND_SITE && (
            <SitesTable
              columns={UpdateColAndSort[filter]?.visibleCols}
              sites={sites}
              sortAction={UpdateColAndSort[filter]?.updateSortMetric}
              sortMetric={UpdateColAndSort[filter]?.sortMetric}
              sortDirection={UpdateColAndSort[filter]?.sortDirection}
              onRowSelect={handleSiteRowSelect}
              onDrop={(columnValue) => savePrefs(columnValue, SITES)}
              filterDefs={FilterDefsFromSiteTypeFilter[filter]}
              setFilterDefs={SetFilterDefsFromSiteTypeFilter[filter]}
              setCurrentSearch={setCurrentSearch}
              filterValues={filterValues}
              currentRow={currentRow}
              type={filter}
            />
          )}
          {filter === EntityType.STORAGE_SITE && (
            <StorageSitesTable
              columns={UpdateColAndSort[filter]?.visibleCols}
              sites={sites}
              sortAction={UpdateColAndSort[filter]?.updateSortMetric}
              sortMetric={UpdateColAndSort[filter]?.sortMetric}
              sortDirection={UpdateColAndSort[filter]?.sortDirection}
              onRowSelect={handleSiteRowSelect}
              onDrop={(columnValue) => savePrefs(columnValue, SITES)}
              filterDefs={FilterDefsFromSiteTypeFilter[filter]}
              setFilterDefs={SetFilterDefsFromSiteTypeFilter[filter]}
              setCurrentSearch={setCurrentSearch}
              filterValues={filterValues}
              currentRow={currentRow}
              type={filter}
            />
          )}
        </DetailsDismissInert>
      )}
      {/* {isActive === PresentationTypes.MAP && <MapView />} */}
    </PageContainer>
  );
};

MonitorSites.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

MonitorSites.defaultProps = {
  location: null,
};

export default withRouter(MonitorSites);
