import { PropTypes } from 'prop-types';
import path from 'ramda/src/path';
import React, { useCallback } from 'react';
import styled, { withTheme } from 'styled-components';

import { MiniLoader } from '@ge/components/loader';
import { elevations } from '@ge/tokens/elevations';

import GaugeBackground from './components/background';
import GaugeMask from './components/mask';
import levels from './levels';

const Chart = styled.div`
  position: relative;
  width: 114px;
`;

const ChartContainer = styled.div`
  height: 61px;
  position: relative;
  width: 114px;
  overflow: hidden;
`;

const ChartBackground = styled.div`
  height: 61px;
  left: 0;
  position: absolute;
  top: 0;
  width: 114px;
  z-index: ${elevations.P1};

  > svg {
    height: 100%;
    width: 100%;
  }
`;

const ChartMask = styled.div`
  height: 28px;
  left: 24px;
  position: absolute;
  top: 34px;
  width: 66px;
  z-index: ${elevations.P3};

  > svg {
    height: auto;
    width: 100%;
  }
`;

const ChartMaskShadow = styled.div`
  background-color: transparent;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  height: 14px;
  left: 12px;
  position: absolute;
  top: 7px;
  width: 42px;
  z-index: ${elevations.N1};
`;

const ChartTitle = styled.div.attrs((props) => ({
  style: {
    color: props.color,
  },
}))`
  color: ${(props) => props.color};
  font-size: 11px;
  font-weight: 500;
  height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 7px;
`;

const ChartValue = styled.div.attrs((props) => ({
  style: {
    background: props.pageColor,
    zIndex: elevations.P4,
  },
}))`
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: -3px;
  margin-bottom: 12px;
  > .input {
    font-size: 14px;
    font-weight: 400;
    left: 0;
    position: absolute;
    top: -18px;
    width: 100%;

    > .gauge-loader {
      background: transparent;

      > img {
        position: static;
        margin: -8px auto 0;
        height: auto;
        width: 35px;
      }
    }
  }

  > .warning {
    display: block;
    font-size: 11px;
    opacity: 0.5;
  }
`;

const Needle = styled.span.attrs((props) => ({
  style: {
    backgroundColor: props.color,
    borderColor: props.borderColor,
    boxShadow: props.boxShadow,
    transform: `rotate(${props.deg}deg)`,
    zIndex: elevations.P2,
  },
}))`
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border: 1px solid transparent;
  display: inline-block;
  height: 56px;
  left: 55px;
  position: absolute;
  top: 3px;
  transform-origin: bottom;
  transition: transform 2s;
  width: 3px;
`;

const GaugeChartComponent = ({
  danger,
  max,
  pageColor,
  reverse,
  theme,
  threshold,
  title,
  value: input,
  warning,
  unit,
  label,
}) => {
  const { gaugeChart } = theme;
  const needleTheme = path(['needle'], gaugeChart);
  const titleColor = path(['title'], gaugeChart);

  const DEGREE_CONSTANT = 80;
  const CENTER_VALUE = 95;

  const maxDeg = DEGREE_CONSTANT;
  const minDeg = -maxDeg;
  const percentDeg = DEGREE_CONSTANT / 5;

  const calcDeg = useCallback(({ input, percentDeg, centerValue, minDeg, maxDeg }) => {
    if (input <= 90) return minDeg;
    else if (input >= 100) return maxDeg;
    else if (input > centerValue) return (input - centerValue) * percentDeg;
    else if (input < centerValue) return -((centerValue - input) * percentDeg);
    return 0;
  }, []);

  const getNeedleStyles = (level) => {
    const styles = {};
    switch (level) {
      case levels.DANGER:
        styles.color = needleTheme.colors.danger;
        styles.borderColor = needleTheme.border.colors.danger;
        styles.boxShadow = needleTheme.boxShadow.danger;
        break;
      case levels.WARNING:
        styles.color = needleTheme.colors.warning;
        styles.borderColor = needleTheme.border.colors.warning;
        styles.boxShadow = needleTheme.boxShadow.warning;
        break;
      default:
        styles.color = needleTheme.colors.default;
        styles.borderColor = needleTheme.border.colors.default;
        styles.boxShadow = needleTheme.boxShadow;
        break;
    }
    return styles;
  };

  let level = levels.DEFAULT;

  if (!(danger && reverse ? input <= danger : input >= danger)) {
    level = levels.DANGER;
  } else if (!(warning && reverse ? input <= warning : input >= warning)) {
    level = levels.WARNING;
  }

  if (!input) {
    level = levels.EXTREME;
  }

  if (!pageColor) {
    pageColor = path(['layout', 'mainBackgroundColor'], theme);
  }

  const deg = calcDeg({ input, percentDeg, centerValue: CENTER_VALUE, minDeg, maxDeg });
  const needleStyles = getNeedleStyles(level);

  return (
    <Chart>
      {title && <ChartTitle color={titleColor}>{title}</ChartTitle>}
      <ChartContainer>
        <ChartBackground>
          <GaugeBackground level={level} threshold={threshold} reverse={reverse} max={max} />
        </ChartBackground>
        <Needle
          deg={deg}
          color={needleStyles.color}
          borderColor={needleStyles.borderColor}
          boxShadow={needleStyles.boxShadow}
        />
        <ChartMask>
          <ChartMaskShadow />
          <GaugeMask pageColor={pageColor} className="mask" />
        </ChartMask>
      </ChartContainer>
      <ChartValue pageColor={pageColor}>
        <span className="input">
          {input || input === 0 ? (
            <>
              {label ?? input}
              {unit}
            </>
          ) : (
            <MiniLoader className="gauge-loader" />
          )}
        </span>
      </ChartValue>
    </Chart>
  );
};

GaugeChartComponent.propTypes = {
  pageColor: PropTypes.string,
  danger: PropTypes.number,
  max: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
  theme: PropTypes.shape({
    gaugeChart: PropTypes.instanceOf(Object),
  }).isRequired,
  threshold: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.number,
  warning: PropTypes.number,
  unit: PropTypes.string,
  label: PropTypes.string,
};

GaugeChartComponent.defaultProps = {
  danger: null,
  pageColor: null,
  reverse: false,
  title: '',
  threshold: null,
  warning: null,
  unit: '%',
  value: null,
  label: undefined,
};

export const GaugeChart = withTheme(GaugeChartComponent);
