import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Menu, MenuTable, placements } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';

const TurbineModal = styled.div`
  cursor: default;
`;

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-alarms-count {
    margin-left: 10px;
  }
`;

const StyledModel = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1px 10px;
  text-transform: uppercase;
  white-space: nowrap;
  &:first-of-type {
    margin-left: 0;
  }
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  color: props.theme.entityDetails.headerBadgeColor,
  small: true,
}))`
  border-radius: 4px;
  color: ${(props) => props.theme.entityDetails.badgeFonColor};
  margin-left: 5px;
  padding: 0 2px;
  .asset-models {
    background: none;
  }
`;

const StyledTr = styled.tr`
  td {
    font-size: 11px;
    font-weight: ${(props) => (props.isBold ? 700 : 300)};
    &:first-of-type {
      display: flex;
      align-items: center;
      div {
        flex: 1;
        max-width: 100px; // Should change based on the FD designs
        margin-right: 11px;
      }
    }
    &:nth-of-type(2) {
      div {
        max-width: 200px;
      }
    }
    &:last-of-type {
      div {
        text-align: right;
        padding-right: 11px;
      }
    }
    &.turbine-model {
      justify-content: left;
      padding-right: 0;
      & > div {
        margin: 0 0 0 4px;
      }
    }
    .duration {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const TurbineModelMenu = ({ turbineModels, children, containerRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['entity-details'], { useSuspense: false });

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  return (
    <TurbineModal onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.BOTTOM_START}
        onClick={(e) => {
          e.preventDefault();
          killEventPropagation(e);
        }}
        width="150"
      >
        <StyledTitle>
          <h4 className="menu-title-txt">{t(`turbine_models.turbine_models`, 'Turbine Models')}</h4>
        </StyledTitle>
        <MenuTable disableHover={true}>
          <table>
            <tbody>
              {turbineModels?.map(([key, value]) => (
                <StyledTr key={key}>
                  <td className="turbine-model">
                    <StyledModel key={key}>
                      {key}
                      <StyledBadge label={value?.toString()} />
                    </StyledModel>
                  </td>
                </StyledTr>
              ))}
            </tbody>
          </table>
        </MenuTable>
      </Menu>
    </TurbineModal>
  );
};

TurbineModelMenu.propTypes = {
  turbineModels: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
};
