import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AnalysisTemplateOverview } from '@ge/feat-analyze/components/configure/cases/analysis-template';
import { EntityType } from '@ge/models/constants';

import EntityDetailHeader, { StyledDetailHeader } from '../entity-details-header';
import { DetailContainer, SectionContainer } from '../entity-details-shared';

const HeaderInfo = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  max-width: 350px;
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  > * {
    + * {
      margin-left: 5px;
    }
  }
  > button {
    margin-top: 1px;
  }
`;

const AnalysisTemplateDetails = ({ entityId, closePanel }) => {
  return (
    <DetailContainer>
      <EntityDetailHeader type={EntityType.ANALYSIS_TEMPLATE}>
        <StyledDetailHeader>
          <HeaderInfo>
            <h2>{entityId}</h2>
          </HeaderInfo>
        </StyledDetailHeader>
      </EntityDetailHeader>
      <SectionContainer>
        <AnalysisTemplateOverview entityId={entityId} onClose={closePanel} onConfirm={() => {}} />
      </SectionContainer>
    </DetailContainer>
  );
};

AnalysisTemplateDetails.propTypes = {
  entityId: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,
};

AnalysisTemplateDetails.defaultProps = {
  entityId: '',
};

export default AnalysisTemplateDetails;
