/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import { action, computed, thunk, thunkOn, persist } from 'easy-peasy';
import memoize from 'memoizerific';

import { createTask } from '@ge/feat-manage/services';
import {
  getFilteredTasksTrackerData,
  getSelectedTaskTrackerData,
} from '@ge/feat-manage/util/tasks-tracker-util';
import { TaskStatus } from '@ge/models';
import { SortValueType, TaskDateKey, RealTimeCases } from '@ge/models/constants';
import { byId } from '@ge/util/array-utils';
import { sorter } from '@ge/util/metric-sorter';

import {
  fetchTaskById,
  editTask,
  deleteTask,
  bulkTask,
  fetchTasksByTaskIds,
  deleteBulkTask,
} from '../services/task';
import { taskPage } from '../services/task-page';
import { taskPageStatus } from '../services/task-page-status';
import { filterByView } from '../util/view-utils';

import indexedDb from './storage/indexedDb';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(toObject);
// Define initial state
const defaultTasksState = persist(
  {
    tasks: {},
    //taskWorkers: [],
    // Dispatch
    dispatchTask: null,
    selectedAlarmOrEvent: null,
    workStandards: null,
    previousTask: null,
    taskUpdateForAsset: [],
    selectedAssetToEscalate: {},
    assetsTasks: {}, //asset not completed task for realtimecases
    assetTasks: {},
  },
  {
    version: 1,
    storage: indexedDb,
    mergeStrategy: 'mergeShallow',
    allow: ['tasks'],
  },
);
const paginationState = {
  taskPage: {
    count: 0,
    isMax: false,
  },
  taskPageStatus: {
    count: 0,
    isMax: false,
  },
};
// Actions
const tasksActions = {
  resetTasks: action((state) => {
    state = Object.assign(state, defaultTasksState);
  }),
  setPagination: action((state, payload) => {
    state.taskPage = payload;
  }),
  setPaginationStatus: action((state, payload) => {
    state.taskPageStatus = payload;
  }),
  fetchTaskById: thunk(async (actions, payload, { fail }) => {
    try {
      if (!payload) return;
      const task = await fetchTaskById(payload);
      if (task) {
        actions.updateTasks({ [task.id]: task });
      }
    } catch (err) {
      fail(err);
    }
  }),

  fetchTasksByTaskIds: thunk(async (actions, payload, { fail }) => {
    let tasks = [];
    if (!payload || payload.length === 0) return tasks;
    try {
      if (Array.isArray(payload)) {
        tasks = await fetchTasksByTaskIds(payload);
      } else {
        tasks = await fetchTasksByTaskIds(payload?.taskIds, true);
      }

      if (tasks?.length) {
        const toUpdate = tasks.reduce((acc, task) => {
          acc[task.id] = task;
          return acc;
        }, {});

        actions.updateTasks(toUpdate);
        return tasks;
      }
    } catch (err) {
      fail(err);
    }
  }),

  fetchTasks: thunk(async (actions, { start, end, siteIds, serviceGroupIds, status = [] } = {}) => {
    // Clear stored tasks list
    if (status?.length > 0) {
      actions.resetTasks();
    }
    let result = await taskPage({
      siteIds,
      serviceGroupIds,
      ...(start && { startDate: dayjs(start).toISOString() }),
      ...(end && { endDate: dayjs(end).toISOString() }),
      status,
    });

    let data = result;
    if (data.isMax) {
      actions.setPagination(data);
    } else {
      actions.setPagination({ isMax: false });
      actions.updateTasks(
        data.tasks.reduce((acc, task) => {
          acc[task.id] = task;
          return acc;
        }, {}),
      );
      //actions.updateTaskWorkers(data.workers);
    }
  }),

  fetchByStatus: thunk(async (actions, { status = [], siteIds }) => {
    const data = await taskPageStatus({ status, siteIds });

    if (data.isMax) {
      actions.setPaginationStatus(data);
    } else {
      actions.setPaginationStatus({ isMax: false });
      actions.updateTasks(
        data.reduce((acc, task) => {
          acc[task.id] = task;
          return acc;
        }, {}),
      );
    }
  }),

  createTask: thunk(
    async (
      actions,
      { task, entityIds, entityType, associationId, createTasks },
      { getStoreState, getStoreActions },
    ) => {
      const assetsById = getStoreState().assets.assets;
      const taskToCreate = {
        ...task,
        site:
          entityType === 'asset'
            ? { id: assetsById[entityIds[0]]?.site?.id }
            : { id: entityIds[0] },
        ...(entityType === 'asset' && { asset: { id: entityIds[0] } }),
        taskLevel: entityType,
        entityList: entityIds,
      };
      const createdTasks = await createTask(taskToCreate);
      actions.updateTasks(byId(createdTasks));
      createTasks(createdTasks);
      // Dispatch
      actions.redispatchTaskCond(createdTasks[0]);

      createdTasks.map((data) => {
        if (RealTimeCases.includes(data.source)) {
          actions.updateAssetsTasks({ [data.id]: data });
        }
      });

      if (associationId) {
        let taskIds = [];
        createdTasks.map((data) => {
          taskIds.push(data.id);
        });
        if (taskIds.length > 0) {
          getStoreActions().cases.addTasksToCase({
            caseId: associationId,
            taskIds: taskIds,
          });
        }
      }
      return createdTasks;
    },
  ),

  editTask: thunk(
    async (
      actions,
      { id, task, associationId, bundledTaskIds, updateTasks },
      { getStoreActions },
    ) => {
      actions.updatePreviousTask(id);
      const editedTask = await editTask(id, task);
      // Dispatch
      actions.redispatchTaskCond(editedTask);
      if (!editedTask.errorDetails) {
        actions.updateTasks({ [editedTask.id]: editedTask });
        updateTasks(editedTask);
        bundledTaskIds && actions.fetchTasksByTaskIds(bundledTaskIds);
        // For asset history table update
        actions.dispatchTaskForAsset({ [editedTask.asset.id]: editedTask });
        if (associationId) {
          getStoreActions().cases.addTaskToCase({ caseId: associationId, taskId: editedTask.id });
        }
        return editedTask;
      }
    },
  ),

  bulkScheduleTask: thunk(
    async (actions, { bulkTasks, updateBulkScheduleTask, tasks }, { getState, fail }) => {
      actions.updatePreviousTask(bulkTasks[0]?.id);
      const stateTasksById = getState().tasks;
      try {
        const scheduledTasks = await bulkTask(bulkTasks.map(({ id, ...rest }) => rest));
        const updatedTasks = scheduledTasks.reduce(
          (acc, { id, scheduleDate, crewIds, ...rest }) => {
            acc[id] = {
              ...(stateTasksById[id] ?? tasks?.find((task) => id === task?.id)),
              ...rest,
              scheduleDate: scheduleDate ? scheduleDate : null,
              crewIds: crewIds ? crewIds : null,
            };
            return acc;
          },
          {},
        );
        actions.updateTasks(updatedTasks);
        updateBulkScheduleTask(Object.values(updatedTasks));
        // Dispatch
        actions.redispatchTaskCond(scheduledTasks[0]);
        actions.fetchTasksByTaskIds(scheduledTasks?.[0]?.bundledTasks);
      } catch (e) {
        const initialState = bulkTasks.reduce((acc, { id }) => {
          acc[id] = stateTasksById[id] ?? tasks?.find((task) => id === task?.id);
          return acc;
        }, {});
        actions.updateTasks(initialState);
        updateBulkScheduleTask(Object.values(initialState));
        fail(e);
      }
    },
  ),

  bulkDeleteTask: thunk(async (actions, { bulkDelete, removeBulkTasks }, { fail }) => {
    const payload = bulkDelete.map(({ taskId }) => ({
      taskId: taskId,
    }));
    try {
      await deleteBulkTask(payload);
      actions.removeBulkTasks(bulkDelete);
      removeBulkTasks(bulkDelete);
    } catch (e) {
      fail(e);
    }
  }),

  closeTask: thunk(
    async (actions, { id, task, associationId, updateTasks }, { getStoreActions }) => {
      const closedTask = await editTask(id, task);
      if (!closedTask.errorDetails) {
        actions.updateTasks({ [closedTask.id]: closedTask });
        updateTasks(closedTask);
        actions.fetchTasksByTaskIds({ taskIds: [closedTask?.taskId] });
        // For asset history table update
        actions.dispatchTaskForAsset({ [closedTask.asset.id]: closedTask });
        if (associationId) {
          getStoreActions().cases.addTaskToCase({ caseId: associationId, taskId: closedTask.id });
        }
        return closedTask;
      }
    },
  ),

  reopenTask: thunk(
    async (actions, { id, task, associationId, updateTasks }, { getStoreActions }) => {
      const reopenTask = await editTask(id, task);
      if (!reopenTask.errorDetails) {
        actions.updateTasks({ [reopenTask.id]: reopenTask });
        updateTasks(reopenTask);
        // For asset history table update
        actions.dispatchTaskForAsset({ [reopenTask.asset.id]: reopenTask });
        if (associationId) {
          getStoreActions().cases.addTaskToCase({ caseId: associationId, taskId: reopenTask.id });
        }
        return reopenTask;
      }
    },
  ),

  deleteTask: thunk(async (actions, { id, removeTask }) => {
    await deleteTask(id);
    actions.removeTask(id);
    removeTask(id);
  }),

  updateTasks: action((state, payload) => {
    state.tasks = { ...state.tasks, ...payload };
  }),

  // updateTaskWorkers: action((state, payload) => {
  //   state.taskWorkers = payload;
  // }),

  // Dispatch
  redispatchTaskCond: action((state, payload) => {
    state.dispatchTask = payload;
  }),

  updatePreviousTask: action((state, payload) => {
    let prevState = JSON.parse(JSON.stringify({ ...state.tasks[payload] }));
    state.previousTask = prevState;
  }),

  dispatchTaskForAsset: action((state, payload) => {
    state.taskUpdateForAsset = payload;
  }),

  updateAssetsTasks: action((state, payload) => {
    state.assetsTasks = { ...state.assetsTasks, ...payload };
  }),

  updateTaskDates: action((state, payload) => {
    payload.forEach((taskDateInfo) => {
      const task = state.tasks[taskDateInfo.id];
      task[TaskDateKey.COMPLETED_DATE_TIME_TZ] = taskDateInfo[TaskDateKey.COMPLETED_DATE_TIME_TZ];
      task[TaskDateKey.CREATE_DATE_TZ] = taskDateInfo[TaskDateKey.CREATE_DATE_TZ];
      task[TaskDateKey.DUE_DATE_TZ] = taskDateInfo[TaskDateKey.DUE_DATE_TZ];
      task[TaskDateKey.ELIGIBLE_START_DATE_TZ] = taskDateInfo[TaskDateKey.ELIGIBLE_START_DATE_TZ];
      task[TaskDateKey.SCHEDULE_DATE_TZ] = taskDateInfo[TaskDateKey.SCHEDULE_DATE_TZ];
      task[TaskDateKey.START_DATE_TIME_TZ] = taskDateInfo[TaskDateKey.START_DATE_TIME_TZ];
      task[TaskDateKey.COMMITTED_DATE_TZ] = taskDateInfo[TaskDateKey.COMMITTED_DATE_TZ];
    });
  }),

  removeTask: action((state, taskId) => {
    delete state.tasks[taskId];
  }),

  removeBulkTasks: action((state, payload) => {
    payload.forEach((task) => {
      delete state.tasks[task.id];
    });
  }),

  markTasksScheduled: action((state, payload) => {
    payload.forEach((taskId) => {
      const task = { ...state.tasks[taskId], scheduled: true };
      state.tasks = { ...state.tasks, [taskId]: task };
    });
  }),

  markTasksUnscheduled: action((state, payload) => {
    payload.forEach((taskId) => {
      const task = { ...state.tasks[taskId], scheduled: false };
      state.tasks = { ...state.tasks, [taskId]: task };
    });
  }),

  updateTaskGroup: action((state, { taskId, groupId }) => {
    state.tasks[taskId].group.id = groupId;
  }),

  setTasks: action((state, payload) => {
    state.tasks = payload;
  }),

  setSelectedAlarmOrEvent: action((state, payload) => {
    state.selectedAlarmOrEvent = payload;
  }),

  setWorkStandards: action((state, payload) => {
    state.workStandards = payload;
  }),

  setSelectedAssetToEscalate: action((state, payload) => {
    state.selectedAssetToEscalate = payload;
  }),

  fetchTasksByAssetId: thunk(async (actions, { start, end, assetIds, serviceGroupIds } = {}) => {
    let result = await taskPage({
      assetIds,
      serviceGroupIds,
      ...(start && { startDate: dayjs(start).toISOString() }),
      ...(end && { endDate: dayjs(end).toISOString() }),
    });
    let data = result;
    if (data.isMax) {
      actions.setPagination(data);
    } else {
      actions.setPagination({ isMax: false });
      //actions.putTasks(
      actions.putAssetTasks(
        data.tasks.reduce((acc, task) => {
          acc[task.id] = task;
          return acc;
        }, {}),
      );
      //actions.updateTaskWorkers(data.workers);
    }
  }),

  putAssetTasks: action((state, payload) => {
    state.assetTasks = payload;
  }),

  putTasks: action((state, payload) => {
    state.tasks = payload;
  }),
};

// Listeners
const tasksListeners = {
  // wasn't sure all the different ways that tasks might be getting selected out of the store currently,
  // so updating on input seemed like a safe option to avoid anomalies
  // sadly we can't just calculate the utc offset once per site and apply to all dates that fall into that site
  // because the offset varies with daylight savings, so the specific date must be used (might need to confirm this)
  // TODO: look into other solutions to crunching timezones
  onUpdateTasks: thunkOn(
    (actions) => actions.updateTasks,
    async (actions, { payload }, { getStoreState }) => {
      const sitesById = getStoreState().sites.sites;

      // this speeds up crunching quite a bit but feels like a bandaid where an entirely different solution would make more sense
      const dateCache = memoize(500)((date, timezone) => dayjs(date).tz(timezone));

      const taskDates = Object.entries(payload).map(([id, task]) => {
        const { site } = task;
        const { timezone } = sitesById[site?.id] ?? {};
        const taskDateInfo = { id };

        if (timezone) {
          taskDateInfo[TaskDateKey.COMPLETED_DATE_TIME_TZ] =
            task[TaskDateKey.COMPLETED_DATE_TIME] &&
            dateCache(task[TaskDateKey.COMPLETED_DATE_TIME], timezone);
          taskDateInfo[TaskDateKey.CREATE_DATE_TZ] =
            task[TaskDateKey.CREATE_DATE] && dateCache(task[TaskDateKey.CREATE_DATE], timezone);
          taskDateInfo[TaskDateKey.DUE_DATE_TZ] =
            task[TaskDateKey.DUE_DATE] && dateCache(task[TaskDateKey.DUE_DATE], timezone);
          taskDateInfo[TaskDateKey.ELIGIBLE_START_DATE_TZ] =
            task[TaskDateKey.ELIGIBLE_START_DATE] &&
            dateCache(task[TaskDateKey.ELIGIBLE_START_DATE], timezone);
          taskDateInfo[TaskDateKey.SCHEDULE_DATE_TZ] =
            task[TaskDateKey.SCHEDULE_DATE] && dateCache(task[TaskDateKey.SCHEDULE_DATE], timezone);
          taskDateInfo[TaskDateKey.START_DATE_TIME_TZ] =
            task[TaskDateKey.START_DATE_TIME] &&
            dateCache(task[TaskDateKey.START_DATE_TIME], timezone);
          taskDateInfo[TaskDateKey.COMMITTED_DATE_TZ] =
            task[TaskDateKey.COMMITTED_DATE] &&
            dateCache(task[TaskDateKey.COMMITTED_DATE], timezone);
        }

        return taskDateInfo;
      });

      actions.updateTaskDates(taskDates);
    },
  ),
};

// Computed values
const tasksComputed = {
  //need to take a call when to remove getSortedTasks(), getTasks() and hydratedTasks()
  getSortedTasks: computed(
    [(state) => state.hydratedTasks, (state, storeState) => storeState.view.currentView],
    (hydratedTasks, currentView) =>
      memoize(2)((sortMetric, sortDirection, tasksTrackerData) => {
        let taskArray = Object.values(hydratedTasks);

        if (currentView) {
          taskArray = filterByView(taskArray, currentView);
        }
        const sortedTasks = taskArray.sort(
          sorter(sortMetric, sortDirection, SortValueType.ALPHANUMERIC),
        );
        const taskTrackerData = getSelectedTaskTrackerData(tasksTrackerData);
        return taskTrackerData && sortedTasks?.length
          ? getFilteredTasksTrackerData(sortedTasks, taskTrackerData)
          : sortedTasks;
      }),
  ),

  getTasks: computed(
    [(state) => state.hydratedTasks, (state, storeState) => storeState.view.currentView],
    (hydratedTasks, currentView) =>
      memoize(1)(() => {
        const data = Object.values(hydratedTasks) ?? [];
        return currentView ? filterByView(data, currentView) : data;
      }),
  ),

  getAssetTasks: computed([(state) => state.assetsTasks], (tasks) =>
    memoize(1)(() => {
      if (!tasks) return [];
      const data = Object.values(tasks) ?? [];
      return data;
    }),
  ),

  hydratedTasks: computed(
    [
      (state) => state.tasks,
      (state, storeState) => storeState.sites.sites,
      (state, storeState) => storeState.assets.assets,
      (state, storeState) => storeState.technicians.technicians,
    ],
    (tasksById, sites, assets, technicians) => {
      if (!Object.keys(tasksById).length) return [];
      return Object.values(tasksById).map((t) => hydrateTask(t, { assets, sites, technicians }));
    },
  ),

  getTaskById: computed(
    [
      (state) => state.tasks,
      (state, storeState) => storeState.sites.sites,
      (state, storeState) => storeState.assets.assets,
      (state, storeState) => storeState.technicians.technicians,
    ],
    (tasksById, sites, assets, technicians) =>
      memoize(5)((taskId) => {
        if (!tasksById[taskId]) return null;
        return hydrateTask(tasksById[taskId], { assets, sites, technicians });
      }),
  ),

  getRelatedTasks: computed(
    [(state) => state.tasks, (_, storeState) => storeState.siteManager.taskGroups],
    (tasksById, taskGroupsById) =>
      memoize(5)((taskGroupId) => {
        const task = Object.values(tasksById).find((t) => t.group.id === taskGroupId);
        if (!task) return [];
        const tasksIdsInGroup = Object.values(taskGroupsById).reduce(
          (acc, tg) => [...acc, ...tg.tasks],
          [],
        );
        return Object.values(tasksById)
          .filter((t) => t.asset && t.asset.id === task.asset.id)
          .filter((t) => !tasksIdsInGroup.includes(t.id))
          .filter((t) => t.group.id !== taskGroupId);
      }),
  ),

  getSchedulerTasks: computed(
    [(state) => state.tasks, (_, storeState) => storeState.view.currentView],
    (tasksById, currentView) =>
      memoize(5)((start, end) => {
        const viewTasks = filterByView(Object.values(tasksById), currentView);
        return viewTasks.filter((t) => {
          const startObj = start.toObject();
          const endObj = end.toObject();
          let compareObj;
          if (t.status === TaskStatus.SCHEDULED) {
            if (!t.scheduleDateTz) return false;
            compareObj = dayjs(t.scheduleDateTz).toObject();
          } else if (t.status === TaskStatus.UNSCHEDULED) {
            if (!t.dueDateTz) return false;
            compareObj = dayjs(t.dueDateTz).toObject();
          } else if (t.status === TaskStatus.COMPLETE) {
            if (!t.completedDateTimeTz) return false;
            compareObj = dayjs(t.completedDateTimeTz).toObject();
          } else {
            return false;
          }

          return dayjs(
            new Date(
              compareObj.years,
              compareObj.months,
              compareObj.date,
              compareObj.hours,
              compareObj.minutes,
              compareObj.seconds,
            ),
          ).isBetween(
            new Date(
              startObj.years,
              startObj.months,
              startObj.date,
              startObj.hours,
              startObj.minutes,
              startObj.seconds,
            ),
            new Date(
              endObj.years,
              endObj.months,
              endObj.date,
              endObj.hours,
              endObj.minutes,
              endObj.seconds,
            ),
            null,
            '[)',
          );
        });
      }),
  ),

  pullAssetTasks: computed([(state) => state.assetTasks], (assetTask) =>
    memoize(1)(() => {
      if (!assetTask) return [];
      const data = Object.values(assetTask) ?? [];
      return data;
    }),
  ),
  pullTasks: computed([(state) => state.tasks], (task) =>
    memoize(1)(() => {
      if (!task) return [];
      const data = Object.values(task) ?? [];
      return data;
    }),
  ),
  getSortedAssetTasks: computed(
    [(state) => state.assetTasks, (state, storeState) => storeState.view.currentView],
    (assetTasks, currentView) =>
      memoize(2)((sortMetric, sortDirection) => {
        let taskArray = Object.values(assetTasks);
        if (currentView) {
          taskArray = filterByView(taskArray, currentView);
        }
        return taskArray.sort(sorter(sortMetric, sortDirection, SortValueType.ALPHANUMERIC));
      }),
  ),
};

export const hydrateTask = (task, { assets, sites, technicians }) => {
  const hydratedTask = { ...task };
  if (task?.asset?.id && assets[task.asset.id]) {
    const taskAsset = assets[task.asset.id];
    hydratedTask.asset = {
      id: taskAsset.id,
      name: taskAsset.name,
      state: taskAsset.metrics?.state,
      // TODO - we need to get this from some association
      events: 4,
      anomalies: 2,
      tasks: 4,
      openEvents: 1,
      openAnomalies: 2,
    };
  }
  if (task?.site && sites[task.site.id]) {
    const taskSite = sites[task.site.id];
    hydratedTask.site = {
      id: taskSite.id,
      name: taskSite.name,
      timezone: taskSite.timezone,
    };
  }
  if (task?.crew) {
    hydratedTask.crew = Object.values(technicians)
      .filter((tech) => tech.crew && tech.crew.id === task.crew.id)
      .map((tech) => {
        const { crew, ...rest } = tech;
        return rest;
      });
  }
  if (task?.specialist && technicians[task.specialist.id]) {
    hydratedTask.specialist = technicians[task.specialist.id];
  }

  return hydratedTask;
};

const tasksModel = {
  ...defaultTasksState,
  ...paginationState,
  ...tasksActions,
  ...tasksComputed,
  ...tasksListeners,
};

export default tasksModel;
