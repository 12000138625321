import { PropTypes } from 'prop-types';
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// TODO: add loading state

// Hiding for MVP0 - Story #406
import { Badge } from '@ge/components/badge';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Table, Tbody, Td, Th, Thead, Tr, StyledTh } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { placements } from '@ge/components/tooltip';
import { CommandsMenu } from '@ge/feat-monitor/components';
import {
  AlertsEntityType,
  AssetConnectionStatus,
  Capability,
  FormMode,
  PanelColumnWidths,
  Placeholders,
  SortType,
  AssetType,
  MonitorDefs,
  EntityType,
  PermissionScope,
  AuthStrategy,
  AlertType,
  AlertStatusCodeMapping,
} from '@ge/models/constants';
// Hiding for MVP0 - Story #406
// import { AssetType } from '@ge/models/constants';
// import { getAssetIcon } from '@ge/shared/util/asset-icon';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { AlertDialog } from '@ge/shared/components/alerts/alert-dialog';
import { AlertTableIcons } from '@ge/shared/components/alerts/alert-table-icons';
import { AssetPreviousState } from '@ge/shared/components/asset-previous-state';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EscalatedInfo } from '@ge/shared/components/escalated-info';
import { useAuth } from '@ge/shared/data-hooks';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { useAlertDialog } from '@ge/shared/hooks';
import { ActionKeys } from '@ge/shared/models/actions-menu-items';
import { PanelTableActionsMenuItems } from '@ge/shared/models/actions-menu-items';
import { killEventPropagation } from '@ge/shared/util/general';
import { getConnectionStatusDuration } from '@ge/shared/util/time-date';
import { isBeforeToday } from '@ge/shared/util/time-date';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

import TaskEscalationColumn from './table/task-escalation-column';

// Hiding for MVP0 - Story #406
// const TypeIcon = styled(Icon).attrs((props) => ({
//   size: 12,
//   icon: props.icon,
//   color: props.theme.table.iconLightColor,
// }))`
//   margin-top: 2px;
// `;

// const NoWrap = styled.div`
//   white-space: nowrap;
// `;

// const SetWidth = styled.span.attrs((props) => ({
//   width: props.width,
// }))`
//   display: inline-block;
//   padding-right: 8px;
//   text-align: right;
//   width: ${(props) => props.width};
// `;

// const WindIcon = styled(Icon).attrs((props) => ({
//   size: 12,
//   icon: Icons.WIND_DIRECTION,
//   color: props.theme.table.arrowColor,
//   rotate: props.rotate || 0,
//   viewbox: '0 0 12 20',
// }))``;

const capabilitiesForCheckbox = [
  { capability: Capability.ROC_COMMANDS, scopes: [PermissionScope.ADMIN] },
  { capability: Capability.ALERTS, scopes: [PermissionScope.CREATE] },
];

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const CheckboxTh = styled(StyledTh).attrs(() => ({
  border: true,
  align: 'center',
  width: PanelColumnWidths.XXSMALL,
}))`
  label {
    justify-content: center;
  }
  span {
    display: none;
  }
`;

const StyledTd = styled(Td)`
  span {
    opacity: ${(props) => (props.isDisconnected ? 0.4 : 1)};
  }
`;

const IconContainer = styled.div`
  align-items: center;
  background: ${(props) =>
    props.invalid
      ? props.theme.entityDetails.notes.invalidSpecialInstruction
      : props.theme.entityDetails.notes.validSpecialInstruction};
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  top: 1px;
`;

const SpecialInstructionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.notes.entityDescription,
  size: 12,
  icon: props.icon,
  viewbox: '-2.5 -1 12 12',
}))`
  position: absolute;
  right: 0;
`;

const MailButton = styled.button`
  border: ${(props) => props.theme.tasks.newButton.border};
  border-radius: 2px;
  background: ${(props) => props.theme.tasks.backgroundColor.W};
  padding: 0;
  height: 20px;
  width: 20px;
`;

const MailIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.EMAIL,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const MAX_ASSET_FOR_BULK_ALERT = 100;

const getAlertsEntityAssetType = (siteType) => {
  switch (siteType) {
    case MonitorDefs.WIND_SITES:
      return AlertsEntityType.WIND_TURBINE;
    case MonitorDefs.STORAGE_SITES:
    case MonitorDefs.STORAGE_INVERTER:
      return AlertsEntityType.INVERTER;
    case MonitorDefs.SUBSTATION:
      return AlertsEntityType.SUBSTATION;
    case MonitorDefs.SITE_CONTROLLER:
      return AlertsEntityType.WIND_SITE_CONTROLLER;
    default:
      return AlertsEntityType.WIND_TURBINE;
  }
};

const SiteAssetTable = ({
  siteId,
  assets,
  isLoading,
  sortAction,
  sortMetric,
  sortDirection,
  onAssetSelect,
  siteType,
}) => {
  const { t, ready } = useTranslation(['monitor.assets'], {
    useSuspense: false,
  });

  // state
  const [currentAssets, setCurrentAssets] = useState([]);
  const { isAuthorized } = useAuth();
  const authorizedAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });

  // data hooks
  const {
    currentAlert,
    groupDetails,
    showAlertDialog,
    handleSaveAlert,
    handleCancelAlertDialog,
    handleShowAlertDialog,
    handleEditAlert,
    handleViewAlert,
  } = useAlertDialog(
    {
      entity: currentAssets,
      entityType: getAlertsEntityAssetType(currentAssets?.type),
    },
    authorizedAlerts,
  );

  const getAuthorizedMenuItems = useActionsMenu({ menuItems: PanelTableActionsMenuItems });

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const containerRef = useRef(null);

  const [rowsChecked, setRowsChecked] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);

  // handlers
  const handleMenuAction = useCallback(
    ({ entity }) => {
      setCurrentAssets(entity);
      handleShowAlertDialog();
      if (selectedAssets?.length <= 1) {
        setRowsChecked([]);
        setSelectedAssets([]);
      }
    },
    [handleShowAlertDialog, selectedAssets],
  );

  const handleCancelAlert = useCallback(() => {
    setCurrentAssets([]);
    handleCancelAlertDialog();
  }, [handleCancelAlertDialog]);

  const handleConfirmAlert = useCallback(
    (data) => {
      currentAlert ? handleEditAlert(data) : handleSaveAlert(data);
    },
    [handleSaveAlert, handleEditAlert, currentAlert],
  );

  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsChecked.includes(rowId)) {
        setRowsChecked(rowsChecked.filter((row) => row !== rowId));
        setSelectedAssets(selectedAssets?.filter((asset) => asset.id !== rowId));
      } else {
        setRowsChecked([...rowsChecked, rowId]);
        const asset = assets?.filter((asset) => asset.id === rowId);
        setSelectedAssets([...selectedAssets, ...asset]);
      }
    },
    [setRowsChecked, rowsChecked, selectedAssets, setSelectedAssets, assets],
  );

  // Handle change on the table header the for all row checkbox selections
  const [isChecked, setIsChecked] = useState(CheckedState.UNCHECKED);
  const getCheckState = () => {
    if (isChecked === CheckedState.UNCHECKED || isChecked === CheckedState.INDETERMINATE) {
      setRowsChecked(assets.map((asset) => asset.id));
      setSelectedAssets(assets);
      return setIsChecked(CheckedState.CHECKED);
    }
    setRowsChecked([]);
    setSelectedAssets([]);
    return setIsChecked(CheckedState.UNCHECKED);
  };
  useEffect(() => {
    setRowsChecked([]);
  }, [siteId]);

  // Handle change in the table checkbox selections
  useEffect(() => {
    if (rowsChecked.length === 0) {
      setIsChecked(CheckedState.UNCHECKED);
    } else {
      if (rowsChecked.length !== assets?.length) {
        setIsChecked(CheckedState.INDETERMINATE);
      } else {
        setIsChecked(CheckedState.CHECKED);
      }
    }
  }, [rowsChecked, selectedAssets, setIsChecked, assets]);

  useEffect(() => {
    if (!showAlertDialog) {
      setRowsChecked([]);
      setSelectedAssets([]);
    }
  }, [showAlertDialog]);

  const disabledActions = useMemo(
    () => ({ [ActionKeys.CREATE_ALERT]: selectedAssets?.length > MAX_ASSET_FOR_BULK_ALERT }),
    [selectedAssets],
  );

  if (!ready) {
    return <Loader />;
  }

  const noData = !(isLoading || Boolean(assets?.length));

  const getTranslatedValues = (alerts) => {
    return alerts.map((alert) => ({
      ...alert,
      id: alert._id,
      type: alert.type,
      validTo: alert?.validTo || alert?.typeProperties?.validTo,
      isExpired: alert.status === AlertStatusCodeMapping.EXPIRED,
      transText: t(`dynamic.warning.${alert}`, 'Warning'),
      auditProperties: alert.auditProperties,
      isGroup: !!alert?.groupDetails?.groupId,
      contactInfo: alert?.typeProperties?.contactInfo,
      description: alert?.typeProperties?.description,
      estimatedEndDate: alert?.typeProperties?.estimatedEndDate,
      requestedBy: alert?.typeProperties?.requestedBy,
      requestedTime: alert?.typeProperties?.requestedTime,
      event: alert?.typeProperties?.event,
      newValue: alert?.typeProperties?.newValue,
      oldValue: alert?.typeProperties?.oldValue,
      parameterName: alert?.typeProperties?.parameterName,
      reason: alert?.typeProperties?.reason,
      enr: alert?.typeProperties?.enr,
      gps: alert?.typeProperties?.gps,
      nearestAirport: alert?.typeProperties?.nearestAirport,
    }));
  };

  return (
    <>
      <Table height={'400'} compressed isLoading={isLoading} noData={noData}>
        <Thead noTitles transparent>
          <tr>
            <AuthRender
              capabilities={capabilitiesForCheckbox}
              description="Site-Asset Table Checkbox"
              siteLevel={false}
              authStrategy={AuthStrategy.ANY}
            >
              <CheckboxTh>
                <Checkbox checkState={isChecked} onChange={() => getCheckState()} label={''} />
              </CheckboxTh>
            </AuthRender>
            {/* Hiding for MVP0 - Story #406 */}
            {/* <Th
            labels={{
              [t('table.type', 'Type')]: 'type',
            }}
            border
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XSMALL}
          />*/}
            <Th
              labels={{
                [t('table.state', 'State')]: 'state',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={(e) => sortAction(e, SortType.TURBINE_STATUS)}
              width={PanelColumnWidths.SMALL}
            />
            <Th
              labels={{
                [t('table.name', 'Name')]: 'name',
              }}
              border
              align="left"
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.LARGE}
            />

            <Th
              labels={{
                [t('table.duration', 'Duration')]: 'duration',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
            />
            <AuthRender
              capability={Capability.ALERTS}
              view
              description="Site asset table alerts column"
              siteLevel={false}
            >
              <Th
                labels={{
                  [t('table.alerts', 'Alerts')]: 'alerts',
                }}
                border
                sortedDirection={sortedDirection}
                onClick={sortAction}
                width={PanelColumnWidths.MEDIUM}
                maxWidth={PanelColumnWidths.MEDIUM}
              />
            </AuthRender>
            <AuthRender
              capability={Capability.SPECIAL_INSTRUCTION}
              view
              description="Site asset table specialInstruction column"
              siteLevel={false}
            >
              <Th
                labels={{
                  [t('table.specialInstrunctions', 'SI')]: 'specialInstructions',
                }}
                border
                sortedDirection={sortedDirection}
                onClick={sortAction}
                width={PanelColumnWidths.SMALL}
                maxWidth={PanelColumnWidths.SMALL}
              />
            </AuthRender>
            <Th
              labels={{
                [t('table.power', 'Power (kW)')]: 'power',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.SMALL}
              whiteSpace="normal"
            />
            {(siteType === MonitorDefs.WIND_SITES || siteType === EntityType.SITE) && (
              <Th
                labels={{
                  [t('table.wind', 'Wind\n(m/s)')]: 'wind',
                }}
                border
                sortedDirection={sortedDirection}
                onClick={sortAction}
                width={PanelColumnWidths.SMALL}
                whiteSpace="break"
              />
            )}
            <AuthRender
              capability={Capability.REAL_TIME_PAGES}
              view
              description="Fault Code"
              siteLevel={false}
            >
              <Th
                border
                labels={{
                  [t('table.fault_code', 'Fault Code')]: 'fault_code',
                }}
                sortedDirection={sortedDirection}
                onClick={sortAction}
                width={PanelColumnWidths.MEDIUM}
                whiteSpace="break"
              />
            </AuthRender>
            <AuthRender
              capability={Capability.FIELD_TASKS}
              view
              description="Task Escalations"
              siteLevel={false}
            >
              <Th
                labels={{
                  [t('table.task_escalation', 'Task\nEscalation')]: 'taskEscalation',
                }}
                border
                width={PanelColumnWidths.SMALL}
                sortedDirection={sortedDirection}
                onClick={sortAction}
              />
            </AuthRender>
            <AuthRender
              capability={Capability.CASES}
              view
              description="Fault Escalation"
              siteLevel={false}
            >
              <Th
                labels={{
                  [t('table.escalations', 'Escalations')]: 'escalatedCases',
                }}
                border
                width={PanelColumnWidths.SMALL}
                sortedDirection={sortedDirection}
                onClick={sortAction}
                tooltip={{
                  title: t(
                    'table.escaltions_column_hover',
                    'Shows faulted assets that have an Faulted case which is escalated, Stopped assets which have Stopped cases (S401) that have been escalated',
                  ),
                  placement: placements.TOP,
                  zIndex: elevations.P20,
                }}
              />
            </AuthRender>
            {(siteType === MonitorDefs.WIND_SITES || siteType === EntityType.SITE) && (
              <AuthRender
                capability={Capability.ROC_COMMANDS}
                admin
                description="ROC Commands"
                siteLevel={false}
              >
                <Th
                  noPadding
                  labels={{
                    [t('table.cmd', 'Cmd')]: 'cmd',
                  }}
                />
              </AuthRender>
            )}
            <AuthRender
              capability={Capability.ALERTS}
              edit
              description="Site asset table alerts td"
              siteLevel={false}
            >
              <Th
                noPadding
                labels={{
                  [t('table.actions', 'Actions')]: 'actions',
                }}
              />
            </AuthRender>
            {/* commenting for user story #257 */}
            {/* <Th
              border
              width={PanelColumnWidths.SMALL}
              labels={{
                [t('table.asset_details', 'Asset\nDetails')]: 'asset_details',
              }}
            /> */}
            {(siteType === MonitorDefs.WIND_SITES || siteType === EntityType.SITE) && (
              <AuthRender
                capability={Capability.REAL_TIME_PAGES_ONPREM}
                view
                description="real-time-pages"
                siteLevel={false}
              >
                <Th
                  labels={{
                    [t('table.system_number', 'System #')]: 'systemNumber',
                  }}
                  border
                  align="left"
                  sortedDirection={sortedDirection}
                  onClick={sortAction}
                  width={PanelColumnWidths.MEDIUM}
                />
              </AuthRender>
            )}
          </tr>
        </Thead>
        <Tbody transparent>
          {assets?.map((asset) => {
            // MVP0 - add direction, duration, and state back in
            const {
              alerts,
              specialInstructions,
              power,
              wind,
              state,
              prevState,
              connection,
              taskEscalation,
              type,
              fault_code,
              tasksData,
              escalatedCases,
            } = asset;
            const isDisconnected =
              connection?.value === AssetConnectionStatus.NOCOMM ||
              connection?.value === AssetConnectionStatus.NO_DATA;
            return (
              <Tr
                key={asset.id}
                onClick={(e) => onAssetSelect(e, asset)}
                selected={rowsChecked.includes(asset.id)}
              >
                <AuthRender
                  capabilities={capabilitiesForCheckbox}
                  description="Site-Asset Table Checkbox"
                  siteLevel={false}
                  authStrategy={AuthStrategy.ANY}
                >
                  <Td width={PanelColumnWidths.XXSMALL} noPadding border>
                    <StyledCheckbox
                      onClick={(e) => {
                        killEventPropagation(e);
                        e.preventDefault();
                        toggleCheckedRow(e, asset.id);
                      }}
                    >
                      <Checkbox
                        checkState={
                          rowsChecked.includes(asset.id)
                            ? CheckedState.CHECKED
                            : CheckedState.UNCHECKED
                        }
                        label={''}
                      />
                    </StyledCheckbox>
                  </Td>
                </AuthRender>
                {/* Hiding for MVP0 - Story #406 */}
                {/* <Td align="center" border width={PanelColumnWidths.XSMALL}>
                  <TypeIcon icon={getAssetIcon(asset.type)} />
                )}
              </Td>*/}

                <Td border width={PanelColumnWidths.SMALL}>
                  <AssetPreviousState prevState={prevState} connectionStatus={connection}>
                    <Badge
                      color={getTurbineStateColor(state?.value)}
                      label={getTurbineStateType(state?.value)}
                      medium
                      isDisconnected={isDisconnected}
                    />
                  </AssetPreviousState>
                </Td>
                <Td align="left" border width={PanelColumnWidths.LARGE}>
                  <TooltipCell tooltip={t(asset.name)} zIndex={elevations.P20}>
                    {asset.name}
                  </TooltipCell>
                </Td>

                <Td width={PanelColumnWidths.XSMALL} border>
                  {getConnectionStatusDuration(state) ?? Placeholders.DASH}
                </Td>
                <AuthRender
                  capability={Capability.ALERTS}
                  view
                  description="Site asset table alerts td"
                  siteLevel={false}
                >
                  <Td border width={PanelColumnWidths.SMALL} maxWidth={PanelColumnWidths.SMALL}>
                    {alerts && (
                      <AlertTableIcons
                        alerts={getTranslatedValues(alerts)}
                        onClick={(_, alert) => {
                          setCurrentAssets(asset);
                          handleViewAlert(alert);
                        }}
                        isSiteAssetTable
                      />
                    )}
                  </Td>
                </AuthRender>
                <AuthRender
                  capability={Capability.SPECIAL_INSTRUCTION}
                  view
                  description="Site asset table specialInstructions td"
                  siteLevel={false}
                >
                  <Td border width={PanelColumnWidths.SMALL} maxWidth={PanelColumnWidths.SMALL}>
                    {specialInstructions?.length === 1 ? (
                      <IconContainer invalid={isBeforeToday(specialInstructions[0]?.validDateBy)}>
                        <SpecialInstructionIcon icon={Icons.SPECIAL_INSTRUCTION} />
                      </IconContainer>
                    ) : specialInstructions?.length > 1 ? (
                      <Badge color={globalColors.stone1} label={specialInstructions.length} small />
                    ) : (
                      ''
                    )}
                  </Td>
                </AuthRender>
                <StyledTd width={PanelColumnWidths.SMALL} border isDisconnected={isDisconnected}>
                  <span>{power ?? Placeholders.DASH}</span>
                </StyledTd>
                {(siteType === MonitorDefs.WIND_SITES || siteType === EntityType.SITE) && (
                  <StyledTd width={PanelColumnWidths.SMALL} border isDisconnected={isDisconnected}>
                    {/* Hiding for MVP0 - Story #406 */}
                    {/* <NoWrap>
                  <WindIcon rotate={direction} />
                  <SetWidth width="32px">{wind ?? Placeholders.DASH}</SetWidth>
                </NoWrap> */}
                    <span>{wind ?? Placeholders.DASH}</span>
                  </StyledTd>
                )}
                <AuthRender
                  capability={Capability.REAL_TIME_PAGES}
                  view
                  description="Fault code"
                  siteLevel={false}
                >
                  <Td border width={PanelColumnWidths.XXSMALL} noPadding>
                    {fault_code}
                  </Td>
                </AuthRender>
                <AuthRender
                  capability={Capability.FIELD_TASKS}
                  view
                  description="Task Escalations"
                  siteLevel={false}
                >
                  <StyledTd width={PanelColumnWidths.SMALL} border>
                    <TaskEscalationColumn
                      taskEscalation={taskEscalation}
                      tasksData={tasksData}
                      entityId={asset.id}
                      entity={asset}
                    />
                  </StyledTd>
                </AuthRender>
                <AuthRender
                  capability={Capability.CASES}
                  view
                  description="Fault Escalation"
                  siteLevel={false}
                >
                  <StyledTd width={PanelColumnWidths.SMALL} border>
                    {Array.isArray(escalatedCases) && escalatedCases.length ? (
                      <EscalatedInfo escalated={escalatedCases[0]}>
                        <MailButton>
                          <MailIcon />
                        </MailButton>
                      </EscalatedInfo>
                    ) : null}
                  </StyledTd>
                </AuthRender>
                {(siteType === MonitorDefs.WIND_SITES || siteType === EntityType.SITE) && (
                  <AuthRender
                    capability={Capability.ROC_COMMANDS}
                    admin
                    description="ROC Commands"
                    siteLevel={false}
                  >
                    <Td border width={PanelColumnWidths.XXSMALL} noPadding>
                      {type === AssetType.WIND_TURBINE ? (
                        <CommandsMenu
                          containerRef={containerRef}
                          assets={selectedAssets.length > 0 ? selectedAssets : [asset]}
                          disabled={rowsChecked.length > 0 && !rowsChecked.includes(asset.id)}
                          onExecute={() => {
                            setRowsChecked([]);
                            setSelectedAssets([]);
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Td>
                  </AuthRender>
                )}
                <AuthRender
                  capability={Capability.ALERTS}
                  edit
                  description="Site asset table alerts td"
                  siteLevel={false}
                >
                  <Td border width={PanelColumnWidths.XXSMALL} noPadding>
                    <ActionsMenu
                      containerRef={containerRef}
                      entity={asset}
                      menuIconSmall
                      menuItems={getAuthorizedMenuItems([asset])}
                      onAction={handleMenuAction}
                      disabled={rowsChecked.length > 0 && !rowsChecked.includes(asset.id)}
                      disabledActions={disabledActions}
                    />
                  </Td>
                </AuthRender>
                {/* commenting for user story #257 */}
                {/* <Td border width={PanelColumnWidths.XXSMALL} noPadding>
                  <TableArrow />
                </Td> */}
                {(siteType === MonitorDefs.WIND_SITES || siteType === EntityType.SITE) && (
                  <AuthRender
                    capability={Capability.REAL_TIME_PAGES_ONPREM}
                    view
                    description="real-time-pages"
                    siteLevel={false}
                  >
                    <Td align="left" border width={PanelColumnWidths.MEDIUM}>
                      {asset.systemNumber ?? Placeholders.DASH}
                    </Td>
                  </AuthRender>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {showAlertDialog && (
        <AlertDialog
          alert={currentAlert}
          groupDetails={groupDetails}
          entity={currentAssets}
          entityType={getAlertsEntityAssetType(siteType)}
          isOpen
          mode={currentAlert ? FormMode.VIEW : FormMode.CREATE}
          onCancel={handleCancelAlert}
          onConfirm={handleConfirmAlert}
          selectedAssets={selectedAssets}
          isBulk={selectedAssets?.length > 1}
          alertType={AlertType.PLANNED_OUTAGE}
        />
      )}
    </>
  );
};

SiteAssetTable.propTypes = {
  siteId: PropTypes.string,
  assets: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isLoading: PropTypes.bool,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onAssetSelect: PropTypes.func,
  siteType: PropTypes.string,
};

SiteAssetTable.defaultProps = {
  isLoading: false,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onAssetSelect: () => null,
};

export default SiteAssetTable;
