import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

dayjs.extend(isBetween);

import { PlanningContext } from '../../context/planning-provider';

const Line = styled.div`
  width: 1px;
  background: #999999;
  position: absolute;
  left: ${({ left }) => left}px;
  z-index: 3;

  ${({ top }) =>
    top
      ? css`
          height: 30px;
          bottom: 0;
          &:before {
            content: '';
            width: 6px;
            height: 6px;
            background: #999999;
            position: absolute;
            top: 0;
            left: -3px;
            border-radius: 6px;
          }
        `
      : css`
          height: 100%;
          top: 0;
        `};
`;

export const CalendarDateLine = ({ top, currentDateInQtr }) => {
  const {
    planningState: { sidebarWidth, dayWidth, dayWidthQuarter, range },
  } = useContext(PlanningContext);

  // check for current month
  if (!dayjs().isBetween(range.start, range.end, null, '[]')) return null;

  const leftPos =
    currentDateInQtr > 0
      ? sidebarWidth + currentDateInQtr * dayWidthQuarter - dayWidthQuarter + currentDateInQtr
      : sidebarWidth + dayjs().date() * dayWidth - dayWidth + dayjs().date();

  return <Line className="dateline" left={leftPos} top={top} />;
};

CalendarDateLine.propTypes = {
  top: PropTypes.bool,
  currentDateInQtr: PropTypes.number,
};

CalendarDateLine.defaultProps = {
  top: false,
  currentDateInQtr: 0,
};
