import { AlertType } from '@ge/models/constants';

export const NonOperatingAssetTemplate = {
  _id: '44aa2f2c-0bc4-4d43-b0c1-33dd25df23cc',
  alertType: AlertType.NON_OPERATING_ASSET,
  entrypoint: 'string',
  sections: [
    {
      collapsed: true,
      hidden: false,
      metadata: {
        description: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'description',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        sendNotification: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'send_notification',
    },
  ],
  operations: ['create', 'edit'],
  tenantId: 'dfsa',
};
