import dayjs from 'dayjs';

import { globalColors } from '@ge/tokens/colors';
import { formatNumber } from '@ge/util';

const dayOfMonth = dayjs().date();

export const regionCardSparkOptions = {
  chart: {
    animation: false,
    backgroundColor: 'transparent',
    spacingTop: 20,
    spacingRight: 10,
    spacingBottom: -10,
    spacingLeft: 0,
    type: 'spline',
    showAxes: true,
  },
  title: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    tickLength: 0,
    tickInterval: 7,
    startOnTick: true,
    gridLineWidth: 1,
    title: {
      text: null,
    },
    labels: {
      enabled: true,
    },
    minPadding: 0,
    maxPadding: 0,
    visible: true,
    plotLines: [
      {
        width: 2,
        value: dayOfMonth,
        label: {
          text: '▲',
          style: {
            transform: 'translate(-10px, 159px)',
          },
        },
      },
    ],
  },
  yAxis: {
    visible: true,
    opposite: true,
    min: 75,
    max: 100,
    tickInterval: 5,
    title: '',
    plotLines: [
      {
        color: globalColors.slate25,
        width: 1,
        value: 92,
        dashStyle: 'dash',
      },
    ],
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    },
  },
  rangeSelector: {
    enabled: true,
  },
  series: [
    {},
    {
      type: 'spline',
      name: 'values',
      lineWidth: 2,
      zoneAxis: 'x',
      zones: [
        {
          value: dayOfMonth,
        },
        {
          dashStyle: 'dash',
        },
      ],
    },
  ],
};

export const regionFooterSparkOptions = {
  chart: {
    animation: false,
    backgroundColor: 'transparent',
    spacingTop: -1,
    spacingRight: 10,
    spacingBottom: 0,
    spacingLeft: 0,
    type: 'spline',
    showAxes: true,
  },
  title: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    startOnTick: true,
    tickWidth: 1,
    tickLength: 0,
    tickInterval: 7,
    step: 7,
    gridLineWidth: 1,
    title: {
      text: null,
    },
    labels: {
      enabled: true,
      formatter() {
        if (this.value === 0) {
          return ' ';
        }
        return `${this.value}`;
      },
    },
    minPadding: 0,
    maxPadding: 0,
    visible: true,
    lineColor: globalColors.slate4,
  },
  yAxis: {
    visible: true,
    opposite: true,
    min: 0,
    max: 100,
    title: '',
    labels: {
      enabled: true,
      formatter() {
        return '<span>100</span>';
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    },
  },
  rangeSelector: {
    enabled: true,
  },
  series: [
    {},
    {
      type: 'spline',
      name: 'values',
      lineWidth: 2,
      zoneAxis: 'x',
      zones: [
        {
          value: dayOfMonth,
        },
        {
          dashStyle: 'dash',
        },
      ],
    },
  ],
};

export const sitesColumnChartOptions = {
  tooltip: {
    enabled: true,
    borderColor: 'transparent',
    borderRadius: 9,
    outside: true,
    useHTML: true,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: '',
  },
  chart: {
    type: 'column',
    animation: false,
    backgroundColor: 'transparent',
    height: 221,
    spacingLeft: -4,
    spacingBottom: 0,
  },
  xAxis: {
    title: {
      enabled: false,
    },
    labels: {
      enabled: false,
    },
    tickLength: 0,
    gridLineWidth: 0,
    startOnTick: false,
    minPadding: 0.2,
    maxPadding: 0.2,
  },
  yAxis: {
    // TOOD: revisit this if it works for all kpi values
    ceiling: 100,
    visible: true,
    opposite: true,
    gridLineWidth: 1,
    tickInterval: 5,
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        if (this?.value !== 0) {
          return formatNumber(this.value);
        }
      },
      y: 2,
    },
    min: 0,
  },
  plotOptions: {
    series: {
      shadow: false,
      borderWidth: 0,
      animation: false,
    },
    column: {
      pointWidth: 5,
    },
  },
  series: [],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: '100%',
        },
      },
    ],
  },
};

export const SITE_PERFORMANCE_CHART_COLOR_MAP = {
  0: globalColors.terracotta2,
  1: globalColors.terracotta3,
  2: globalColors.magenta2,
  3: globalColors.blue2,
  4: globalColors.mint2,
  5: globalColors.magenta3,
  6: globalColors.terracotta4,
  7: globalColors.magenta4,
  8: globalColors.mint3,
  9: globalColors.blue3,
};
