import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditSendNotification = () => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.send_notification', 'Send Notification')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup></FieldGroup>
      </ColumnDetail>
    </div>
  );
};
