import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SiteStatusCard } from '@ge/components/card';
import { MiniBarChart } from '@ge/components/charts';
import { Icons } from '@ge/components/icon';

import SidebarCard from '../sidebar-card';

// TODO: Should we manage thresholds elsewhere? Constants file? Server-side?
const OFFLINE_THRESHOLD_PERCENT = 15;

const AVAILABILITY_THRESHOLD = 80;
const AVAILABILITY_WARNING_THRESHOLD = 90;
const AVAILABILITY_DANGER_THRESHOLD = 83;

const OUTPUT_THRESHOLD = 40;
const OUTPUT_WARNING_THRESHOLD = 65;
const OUTPUT_DANGER_THRESHOLD = 55;

const TOTAL_THRESHOLD = 60;
const TOTAL_WARNING_THRESHOLD = 80;
const TOTAL_DANGER_THRESHOLD = 70;

const SidebarSiteStatusCard = ({ status }) => {
  const [site, setSite] = useState(null);
  const { getSiteById } = useStoreState((state) => state.sites);

  const availabilityDanger = status.availability < AVAILABILITY_DANGER_THRESHOLD;
  const outputDanger = status.outputPercent < OUTPUT_DANGER_THRESHOLD;
  const totalDanger = status.hourTotal24 < TOTAL_DANGER_THRESHOLD;

  const availabilityWarning = status.availability < AVAILABILITY_WARNING_THRESHOLD;
  const outputWarning = status.outputPercent < OUTPUT_WARNING_THRESHOLD;
  const totalWarning = status.hourTotal24 < TOTAL_WARNING_THRESHOLD;

  useEffect(() => {
    setSite(getSiteById(status.site.id));
  }, [status, setSite, getSiteById]);

  /**
   * Determine if the status values should result in a "danger" decoration.
   */
  const isDanger = () => {
    const offlineDanger = status.offline > site.assets.length * (OFFLINE_THRESHOLD_PERCENT / 100);
    return offlineDanger || availabilityDanger || outputDanger || totalDanger;
  };

  if (!site || !site.assets) {
    return null;
  }

  return (
    <SidebarCard icon={Icons.SITE} title={site.name} danger={isDanger()}>
      <SiteStatusCard
        assetCount={site.assets.length}
        offline={status.offline}
        availability={
          <MiniBarChart
            lowerThreshold={AVAILABILITY_THRESHOLD}
            percent={status.availability}
            danger={availabilityDanger}
            warning={availabilityWarning}
            altIndicatorStyle
          />
        }
        outputPercent={
          <MiniBarChart
            lowerThreshold={OUTPUT_THRESHOLD}
            percent={status.hourTotal24}
            danger={outputDanger}
            warning={outputWarning}
            altIndicatorStyle
          />
        }
        hourTotal24={
          <MiniBarChart
            lowerThreshold={TOTAL_THRESHOLD}
            percent={status.outputPercent}
            danger={totalDanger}
            warning={totalWarning}
            altIndicatorStyle
          />
        }
      />
    </SidebarCard>
  );
};

SidebarSiteStatusCard.propTypes = {
  status: PropTypes.instanceOf(Object).isRequired,
};

export default SidebarSiteStatusCard;
