import { PropTypes } from 'prop-types';
import is from 'ramda/src/is';
import React from 'react';
import styled, { css } from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { FormMode, Placeholders } from '@ge/models/constants';
import { getFriendlyTimestamp } from '@ge/shared/util';
import { roundNumber } from '@ge/util';

import { AlertField } from './alert-fields';

export const COLLAPSIBLE_CLASS = 'collapsible';
export const FORM_COL_GUTTER_PX = 25;
export const RECIPIENT_DELIMITER = ';';
export const SECTION_END_CLASS = 'section-end';

export const FormSchema = {
  BCC: 'bcc',
  CC: 'cc',
  REPLY_TO: 'replyTo',
  DESCRIPTION: 'description',
  ACTIONABLE: 'actionable',
  INFORMATIONAL: 'informational',
  ENTITY_ID: 'entityId',
  ENTITY_TYPE: 'entityType',
  ID: 'id',
  STATUS: 'status',
  SUBJECT: 'subject',
  TO: 'to',
  TYPE: 'type',
  CASE_DESCRIPTION: 'caseDescription',
  NOTE: 'note',
  START_TIME: 'startTime',
  ATTACHMENTS: 'attachments',
  SELECTED_ASSETS: 'selectedAssets',
  APPLY_TO_ALL: 'applyToAll',
  GROUP_DETAILS: 'groupDetails',
  ACTIONABLE_TO: 'actionable_to',
  ACTIONABLE_CC: 'actionable_cc',
  ACTIONABLE_BCC: 'actionable_bcc',
  INFORMATIONAL_TO: 'informational_to',
  INFORMATIONAL_CC: 'informational_cc',
  INFORMATIONAL_BCC: 'informational_bcc',
  ATTACHMENTS_ACTIONABLE: 'attachments_actionable',
  ATTACHMENTS_INFORMATIONAL: 'attachments_informational',
  IS_ACTIONABLE_CHECKED: 'is_actionable_checked',
  IS_INFORMATIONAL_CHECKED: 'is_informational_checked',
  ACTIONABLE_REPLY_TO: 'actionable_reply_to',
  INFORMATIONAL_REPLY_TO: 'informational_reply_to',
  IS_SEND_NOTIFICATION_CHECKED: 'is_send_notification_checked',
};

// parses value into string for view mode
export const parseViewModeValue = (value = '') =>
  value?.toISOString
    ? getFriendlyTimestamp(value)
        .split(', ')
        .reverse()
        .join(' ')
    : value?.toString();

export const AlertViewContainer = styled.div`
  p {
    color: ${({ theme }) => theme?.alertDialog?.viewValueTextColor};
    font-size: 12px;
    font-weight: 300;
    margin: 4px 0;
  }
`;

export const FormContainer = styled.div`
  form {
    input {
      font-size: 12px;
      font-weight: 300;
    }

    label,
    .label {
      color: ${({ theme }) => theme?.input?.labelTextColor};
      font-size: 11px;
      font-weight: 500;
      white-space: pre;
    }

    > * {
      + * {
        margin-top: 16px;
      }
    }

    ${({ mode }) =>
      mode === FormMode.VIEW
        ? ''
        : css`
            .${SECTION_END_CLASS} {
              border-bottom: 1px solid ${({ theme }) => theme?.newTaskDialog?.sectionBorder};
              padding-bottom: 16px;
            }
          `}
  }
`;

export const FormRow = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;

  #alertTypeSelect {
    min-width: 225px;
  }
  &.divider {
    border-bottom: 1px solid ${({ theme }) => theme?.newTaskDialog?.sectionBorder};
  }
`;

export const CollapsibleFormRow = styled(FormRow).attrs(() => ({
  className: 'collapsible',
}))`
  &.collapsible {
    + .collapsible {
      margin-top: 0px;
    }
  }
`;

// have to set width explicitly (instead of via flex-basis)
// because it's not being enforced everywhere otherwise
export const StyledFormCol = styled.div`
  box-sizing: border-box;
  flex: 0 1 ${({ width }) => width};
  width: ${({ width }) => width};

  + div {
    margin-left: ${FORM_COL_GUTTER_PX}px;
  }
`;

const StyledPara = styled.p`
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FormCol = ({ children, count, span }) => {
  let width = `${roundNumber((Number(span) * 100) / 12, 3)}%`;

  // if multiple cols then calc width with gutters
  if (count > 1) {
    width = `calc(${width} - ${roundNumber((FORM_COL_GUTTER_PX * (count - 1)) / count, 3)}px)`;
  }

  return <StyledFormCol width={width}>{children}</StyledFormCol>;
};

FormCol.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  span: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FormCol.defaultProps = {
  count: 1,
  span: 12,
};

// TODO: should we bake this functionality into the meta field components?
export const AlertViewField = ({ className, id, label, value: _value }) => {
  const value = parseViewModeValue(_value);

  return (
    <AlertViewContainer className={className} id={id}>
      {label && <label>{label}</label>}
      <StyledPara>{value?.trim() ? value : Placeholders.DOUBLE_DASH}</StyledPara>
    </AlertViewContainer>
  );
};

AlertViewField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
};

AlertViewField.defaultProps = {
  className: undefined,
  id: undefined,
  label: undefined,
  value: Placeholders.DOUBLE_DASH,
};

export const AlertTemplateCol = ({ col }) => {
  if (!col) {
    return null;
  }

  return (
    <FormCol count={col.count} span={col.span}>
      <AlertField {...col} />
    </FormCol>
  );
};

AlertTemplateCol.propTypes = {
  col: PropTypes.object,
};

AlertTemplateCol.defaultProps = {
  col: undefined,
};

export const AlertTemplateRow = ({ className, mode, row }) => {
  if (!row?.length) {
    return null;
  }

  return (
    <FormRow className={className}>
      {row?.map((col) => (
        <AlertTemplateCol key={col?.name} col={{ count: row.length, mode, ...col }} />
      ))}
    </FormRow>
  );
};

AlertTemplateRow.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  row: PropTypes.arrayOf(PropTypes.object),
};

AlertTemplateRow.defaultProps = {
  className: undefined,
  mode: FormMode.VIEW,
  row: undefined,
};

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  flex: 1 0;
  margin-left: 0;

  .collapsible-panel-content {
    &.expanded {
      transition: max-height 0s ease;
      overflow: visible;
    }

    .panel-content {
      margin-top: 4px;

      &.divider {
        border-bottom: 1px solid ${({ theme }) => theme.newTaskDialog.sectionBorder};
        margin-bottom: 15px;
        padding-bottom: 16px;
      }

      > * {
        + * {
          margin-top: 16px;
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .title {
    border-bottom: 0;
    text-transform: uppercase;
  }

  svg {
    fill: ${(props) => props.theme.newTaskDialog.collapsibleIcon};
    padding-left: 0;
  }

  textarea {
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
  }
`;

// TODO: look into how we are causing resizing updates on the collapsible panel
// so we can avoid having to manually check for expand and force updates to resize
export const AlertCollapsiblePanel = ({
  children,
  divider,
  expanded,
  header: _header,
  mode,
  onToggleExpand,
  renderToggle,
  view: _view,
  plusIcon,
}) => {
  const header = is(String, _header) ? <h4>{_header}</h4> : _header;
  const view = is(String, _view) ? <AlertViewField value={_view} /> : _view;

  return (
    <CollapsibleFormRow>
      <FormCol span="12">
        <StyledCollapsiblePanel
          divider={divider}
          expanded={expanded}
          headerContent={header}
          onToggleExpand={onToggleExpand}
          triggerHeight={renderToggle}
          plusIcon={plusIcon}
        >
          {[FormMode.CREATE, FormMode.EDIT].includes(mode) ? (
            <div className={`panel-content ${divider ? 'divider' : ''}`}>{children}</div>
          ) : (
            <div className="panel-view-mode">{view}</div>
          )}
        </StyledCollapsiblePanel>
      </FormCol>
    </CollapsibleFormRow>
  );
};

AlertCollapsiblePanel.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  expanded: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  onToggleExpand: PropTypes.func,
  renderToggle: PropTypes.bool,
  view: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  plusIcon: PropTypes.bool,
};

AlertCollapsiblePanel.defaultProps = {
  divider: true,
  expanded: false,
  mode: FormMode.VIEW,
  onToggleExpand: () => {},
  renderToggle: false,
  view: null,
  plusIcon: false,
};
