import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Input } from '@ge/components/input';
import { StatusColor } from '@ge/tokens/colors/colors';

const StyledInput = styled(Input)`
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
`;

const MASK_TOKEN_CONFIG = {
  '#': (char) => char >= '0' && char <= '9',
};

export const TextMetaField = ({
  defaultValue,
  label,
  mask,
  metadata,
  minLength,
  maxLength,
  name,
  placeholder,
  pattern,
  tabIndex,
  className,
}) => {
  const { t } = useTranslation(['tasks']);
  const { readOnly, required } = metadata;
  const { control, errors } = useFormContext();
  const rules = {
    required: required,
    validate: (value) => {
      const trimmedValue = value.trim();
      if (minLength && trimmedValue.length > 0 && trimmedValue.length < minLength) {
        return t('form.invalid_value');
      }

      if (pattern && value.length) {
        try {
          const re = new RegExp(pattern);
          return re.test(value) || t('form.invalid_value');
        } catch (e) {
          console.error(e);
        }
      }

      return required ? !!trimmedValue : !required;
    },
  };

  const maskPrefix = useMemo(() => {
    if (!mask?.length) {
      return '';
    }

    let idx;

    for (const token of Object.keys(MASK_TOKEN_CONFIG)) {
      const tokenIdx = mask.indexOf(token);
      if (tokenIdx > 0) {
        if (!idx) {
          idx = tokenIdx;
        } else {
          idx = Math.min(idx, tokenIdx);
        }
      }
    }

    return idx ? mask.substring(0, idx) : '';
  }, [mask]);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => {
        const handleChange = (e) => {
          if (mask?.length) {
            const [...iterMask] = mask;
            const [...iterValue] = e.target.value;

            let isValidForMask = true;
            if (iterValue.length > iterMask.length) {
              isValidForMask = false;
            } else {
              let insertOffset = 0;
              for (let i = 0; i < iterValue.length; i++) {
                if (i < maskPrefix.length && iterValue[i] === iterMask[i]) {
                  continue;
                }

                const maskTokenTest = MASK_TOKEN_CONFIG[iterMask[i]];
                if (maskTokenTest) {
                  if (typeof maskTokenTest === 'function') {
                    if (!maskTokenTest(iterValue[i])) {
                      isValidForMask = false;
                      break;
                    }
                  }
                } else {
                  if (iterValue[i] !== iterMask[i]) {
                    const first = `${e.target.value.slice(0, i + insertOffset)}`;
                    const last = `${e.target.value.slice(i + insertOffset)}`;
                    e.target.value = `${first}${iterMask[i]}${last}`;
                  }
                  insertOffset++;
                }
              }
            }

            if (!isValidForMask) {
              e.preventDefault();
              return;
            }
          }

          onChange(e);
        };

        const handleBlur = (e) => {
          if (maskPrefix.length && e.target.value === maskPrefix) {
            e.target.value = '';
          }

          onBlur(e);
        };

        const handleFocus = (e) => {
          if (maskPrefix.length && !e.target.value.startsWith(maskPrefix)) {
            e.target.value = maskPrefix;
          }
        };

        return (
          <StyledInput
            className={className}
            disabled={readOnly}
            errors={errors}
            id={name}
            label={label}
            minLength={minLength}
            maxLength={maxLength}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={placeholder}
            required={required}
            tabIndex={tabIndex}
            type="text"
            value={value}
          />
        );
      }}
    />
  );
};

TextMetaField.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  metadata: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
};

TextMetaField.defaultProps = {
  defaultValue: '',
  label: '',
  mask: null,
  minLength: null,
  maxLength: null,
  placeholder: '',
  pattern: null,
  tabIndex: null,
};
