import {
  SitesColumns,
  SolarSitesColumns,
} from '@ge/shared/models/table-col-defs/sites-list-table-cols';

// Define the default visible columns and their order.
export const defaultSitesCols = [
  {
    id: SitesColumns.GROUP_REGION,
    cols: [
      {
        id: SitesColumns.REGION,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_SITE,
    cols: [
      {
        id: SitesColumns.SITE,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_PLATFORM,
    cols: [
      {
        id: SitesColumns.PLATFORM,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_TBA,
    cols: [
      {
        id: SitesColumns.TBA,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_AVAILABILITY_CONTRACT,
    cols: [
      {
        id: SitesColumns.AVAILABILITY_CONTRACT,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_PBA,
    cols: [
      {
        id: SitesColumns.PBA,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_UNPRODUCED_ENERGY_MWH,
    cols: [
      {
        id: SitesColumns.UNPRODUCED_ENERGY_MWH,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_ACTUAL_PRODUCTION,
    cols: [
      {
        id: SitesColumns.ACTUAL_PRODUCTION,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_CAPACITY_FACTOR,
    cols: [
      {
        id: SitesColumns.CAPACITY_FACTOR,
        visible: true,
      },
    ],
  },
  {
    id: SitesColumns.GROUP_EVENT_COVERAGE,
    cols: [
      {
        id: SitesColumns.EVENT_COVERAGE,
        visible: true,
      },
    ],
  },
  // {
  //   id: SitesColumns.GROUP_REPORTING_VS_EXPECTED,
  //   cols: [
  //     {
  //       id: SitesColumns.REPORTING_VS_EXPECTED,
  //       visible: false,
  //     },
  //   ],
  // },
  {
    id: SitesColumns.GROUP_SITE_DETAIL,
    cols: [
      {
        id: SitesColumns.SITE_DETAIL,
        visible: true,
      },
    ],
  },
];

// Define the default visible columns and their order.
export const defaultSolarSitesCols = [
  {
    id: SolarSitesColumns.GROUP_SITES,
    cols: [
      {
        id: SolarSitesColumns.LOCATION,
        visible: true,
      },
      {
        id: SolarSitesColumns.SITE,
        visible: true,
      },
    ],
  },
  {
    id: SolarSitesColumns.GROUP_ASSETS,
    cols: [
      {
        id: SolarSitesColumns.TOTAL,
        visible: true,
      },
      {
        id: SolarSitesColumns.AVAIL,
        visible: true,
      },
    ],
  },
  {
    id: SolarSitesColumns.GROUP_PRODUCTION,
    cols: [
      {
        id: SolarSitesColumns.PRODUCTION,
        visible: true,
      },
    ],
  },
  {
    id: SolarSitesColumns.GROUP_REVENUE,
    cols: [
      {
        id: SolarSitesColumns.MTD,
        visible: true,
      },
      {
        id: SolarSitesColumns.PERFORMANCE_RATIO,
        visible: true,
      },
      {
        id: SolarSitesColumns.YIELD,
        visible: true,
      },
      {
        id: SolarSitesColumns.CAPACITY_FACTOR,
        visible: true,
      },
    ],
  },
  {
    id: SolarSitesColumns.GROUP_WEATHER,
    cols: [
      {
        id: SolarSitesColumns.WIND,
        visible: true,
      },
      {
        id: SolarSitesColumns.POA_IRRADIANCE,
        visible: true,
      },
      {
        id: SolarSitesColumns.AMBIENT_TEMPERATURE,
        visible: true,
      },
    ],
  },
];
