import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import { useNavigation } from '../../hooks/use-navigation';

import L1NavItem from './l1-nav-item';
import L1StickyNavItem from './l1-sticky-nav-item';
import L2NavItem from './l2-nav-item';

const Nav = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  &.open {
    > div {
      visibility: visible;
    }
    .simplebar-track.simplebar-horizontal,
    .customscroll-track.simplebar-horizontal,
    .simplebar-track.simplebar-vertical,
    .customscroll-track.simplebar-vertical {
      display: block;
    }
  }
`;

const MenuIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: Icons.MENU,
  color: props.theme.navigation.iconColor,
}))`
  margin-top: 4px;
  cursor: pointer;
  padding: 20px 12px;
`;

const MenuContainer = styled.div`
  height: calc(100vh - 50px);
  position: absolute;
  top: 50px;
  left: -58px;
  .simplebar-track.simplebar-horizontal,
  .customscroll-track.simplebar-horizontal,
  .simplebar-track.simplebar-vertical,
  .customscroll-track.simplebar-vertical {
    display: none;
  }
`;

const StyledScrollingContainer = styled(ScrollingContainer)`
  width: 300px;
  box-sizing: border-box;
  border-top: 1px solid ${(props) => props.theme.navigation.borderColor};
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0 0 ${(props) => props.stickyNavHeight}px 0;
  background-color: ${(props) => props.theme.navigation.backgroundColor};
  ${({ mainNav }) => {
    if (mainNav) {
      return `height: calc(100vh - 50px);`;
    }
    return `max-height: calc(100vh - 50px);`;
  }}
`;

const L1List = styled.ul`
  list-style: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;

const L1StickyList = styled(L1List)`
  position: absolute;
  bottom: 0;
  height: auto;
  background-color: ${(props) => props.theme.navigation.backgroundColor};
  border-top: 2px solid ${(props) => props.theme.navigation.l1BorderColor};
`;

const CurrentRoute = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.navigation.linkColor};
  font-family: 'GE Inspira';
  font-size: 18px;
  cursor: pointer;
  border-bottom: 3px solid ${(props) => props.theme.navigation.linkActiveBorderColor};
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > span {
    display: flex;
    align-items: center;
  }
`;

const CurrentRouteDivider = styled.span`
  display: inline-block;
  margin: 7px 6px 0;
  color: ${(props) => props.theme.navigation.currentRouteDividerColor};
  font-size: 22px;
`;

const CurrentRouteText = styled.span`
  text-transform: capitalize;
  font-weight: bold;
  margin-top: 3px;
`;

const SubMenuContainer = styled.div`
  visibility: hidden;
  position: absolute;
  top: ${(props) => props.top}px;
  left: 242px;
  .simplebar-track.simplebar-horizontal,
  .customscroll-track.simplebar-horizontal,
  .simplebar-track.simplebar-vertical,
  .customscroll-track.simplebar-vertical {
    display: none;
  }
`;

const Navigation = ({ previousPath, ...props }) => {
  // Close the menu if user clicks outside the nav.
  const navigation = useRef(null);
  const stickyNav = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [stickyNavHeight, setStickyNavHeight] = useState(0);
  const stickyNavClientHeight = stickyNav.current?.clientHeight;

  useEffect(() => {
    setStickyNavHeight(stickyNavClientHeight);
  }, [stickyNavClientHeight]);

  const useAutoClose = ({ setIsOpen, navigation, setIsSubmenuOpen }) => {
    const handleClosure = useCallback(
      (event) => !navigation.current.contains(event.target) && setIsOpen(false),
      [setIsOpen, navigation],
    );
    const handleSubClosure = useCallback(
      (event) => !navigation.current.contains(event.target) && setIsSubmenuOpen(false),
      [setIsSubmenuOpen, navigation],
    );

    useEffect(() => {
      window.addEventListener('click', handleClosure);
      window.addEventListener('focusin', handleClosure);
      window.addEventListener('focusin', handleSubClosure);
      return () => {
        window.removeEventListener('click', handleClosure);
        window.removeEventListener('focusin', handleClosure);
        window.removeEventListener('focusin', handleSubClosure);
      };
    }, [handleClosure, navigation, handleSubClosure]);
  };

  useAutoClose({ setIsOpen, navigation, setIsSubmenuOpen });

  // Converts site and region IDs to names
  const { site: siteId } = useParams();
  const { region: regionId } = useParams();
  const getSiteById = useStoreState((store) => store.sites.getSiteById);
  const { getRegionById } = useStoreState((state) => state.regions);
  const site = getSiteById(siteId);
  const region = getRegionById(regionId);

  const [subMenuTopMargin, setSubMenuTopMargin] = useState(0);

  const {
    location: { pathname },
  } = props;
  const { navMap } = useNavigation();
  const l1NavItems = [];
  const l1StickyNavItems = [];
  const l2NavItems = [];
  const { t } = useTranslation('breadcrumb');

  const handleSubmenuOpen = (val, parentId) => {
    let elem = document.getElementById(parentId);
    let rect = elem?.getBoundingClientRect();
    setSubMenuTopMargin(rect?.top);
    setIsSubmenuOpen(val);
  };

  const formattedPathname = (name) => {
    const _name = name.replace(/^(\/)/, '');
    const pathnameStringArray = _name.replace(/-/g, '_').split('/');

    let pathnameStrings = pathnameStringArray;

    // If the second element of pathnameStringArray is 'site' or 'region'
    // remove the third element (which is the site or region ID) and
    // replace it with the corresponding site or region name.
    if (pathnameStringArray[1] === 'site' && site?.name) {
      pathnameStrings = pathnameStringArray.slice(0, -1).concat([site.name]);
    }
    if (pathnameStringArray[1] === 'region' && region?.name) {
      pathnameStrings = pathnameStringArray.slice(0, -1).concat([region.name]);
    }

    // Removes 'Region' from the end of breadcrumb for Fleet Overview page.
    // This will likely be removed later.
    if (pathnameStringArray[1] === 'fleet_overview') {
      pathnameStrings = pathnameStringArray.slice(0, -1);
    }

    const htmlPathname = [];

    pathnameStrings.map((formattedName, i) => {
      const localizedName = t(formattedName, formattedName);
      return htmlPathname.push(
        <span key={i}>
          {i !== 0 && <CurrentRouteDivider>/</CurrentRouteDivider>}
          <CurrentRouteText>{localizedName}</CurrentRouteText>
        </span>,
      );
    });
    return htmlPathname;
  };

  // Iterate over each item in the map and generate the full nested
  // NavItem structure for the DOM. This is equivalent to a reduce()
  // process, but since the Map is an Iterable, we can't reduce() it.
  navMap?.size &&
    navMap.forEach((value, key) => {
      if (!key.sticky) {
        l1NavItems.push(
          <L1NavItem
            key={key.route}
            activeL1={pathname.includes(key.route)}
            navMapValue={value}
            navMapKey={key}
            pathname={pathname}
            handleOnHover={handleSubmenuOpen}
          />,
        );
        value.map(
          (childVal) =>
            childVal.childSubMenu &&
            l2NavItems.push(
              <L2NavItem
                key={childVal.route}
                activeL1={pathname.includes(childVal.route)}
                navMapValue={childVal.childSubMenu}
                navMapKey={key}
                pathname={pathname}
                parentLabel={childVal.parentGroupLabel}
              />,
            ),
        );
      } else {
        l1StickyNavItems.push(
          <L1StickyNavItem
            key={key.route}
            navMapValue={value}
            navMapKey={key}
            pathname={pathname}
          />,
        );
      }
    });

  useEffect(() => {
    if (previousPath === pathname) {
      return;
    }

    // If the user chose a different route,
    // close the menu by setting isActive to false.
    setIsOpen(false);
  }, [pathname, previousPath]);

  return (
    <Nav className={isOpen ? 'open' : ''} ref={navigation}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon />
      </button>
      {isOpen && (
        <MenuContainer>
          <StyledScrollingContainer relative stickyNavHeight={stickyNavHeight} mainNav>
            <L1List>{l1NavItems}</L1List>
          </StyledScrollingContainer>
          <L1StickyList ref={stickyNav}>{l1StickyNavItems}</L1StickyList>
        </MenuContainer>
      )}
      {isSubmenuOpen && l2NavItems.length > 0 ? (
        <SubMenuContainer top={subMenuTopMargin}>
          <StyledScrollingContainer relative stickyNavHeight={stickyNavHeight}>
            <L1List>{l2NavItems}</L1List>
          </StyledScrollingContainer>
        </SubMenuContainer>
      ) : (
        ''
      )}
      <button onClick={() => setIsOpen(!isOpen)}>
        <CurrentRoute>{formattedPathname(pathname)}</CurrentRoute>
      </button>
    </Nav>
  );
};

Navigation.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  previousPath: PropTypes.string,
};

export default withRouter(withTheme(Navigation));
