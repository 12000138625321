import PropTypes from 'prop-types';
import path from 'ramda/src/path';
import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import { withTheme } from 'styled-components';

import levels from '../levels';

import ThresholdRadial from './threshold-radial';

const GaugeBackground = ({ level, theme, reverse, threshold, max }) => {
  const { gaugeChart } = theme;
  const startGradient = path(['levels', level, 'bgStart'], gaugeChart);
  const endGradient = path(['levels', level, 'bgEnd'], gaugeChart);
  const hashColor = gaugeChart.hashes;

  return (
    <SVGUniqueID>
      <svg
        width="99px"
        height="53px"
        viewBox="0 0 99 53"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient x1="50%" y1="-20%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stopColor={startGradient[0]} stopOpacity={startGradient[1]} offset="0%" />
            <stop stopColor={endGradient[0]} stopOpacity={endGradient[1]} offset="100%" />
          </linearGradient>
          <path
            d="M99.0735294,45.6765357 L99.0735294,5 C99.0735294,2.23857625 96.8349532,-3.69989488e-13 94.0735294,-3.69482223e-13 L49.5735294,-3.69482223e-13 L5.07352941,-3.69482223e-13 C2.31210566,-3.68974957e-13 0.0735294118,2.23857625 0.0735294118,5 L0.0735294118,45.6771583 C0.0735294118,47.2266009 1.2534817,48.5211342 2.79629403,48.664321 L49.5124748,53 L49.5124748,53 L96.3500876,48.6637611 C97.8931919,48.5209 99.0735294,47.226239 99.0735294,45.6765357 Z"
            id="path-2"
          />
          <filter
            x="-2.0%"
            y="-4.0%"
            width="104.0%"
            height="107.9%"
            filterUnits="objectBoundingBox"
            id="filter-4"
          >
            <feGaussianBlur stdDeviation="1.5" in="SourceAlpha" result="shadowBlurInner1" />
            <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
              result="shadowInnerInner1"
            />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
              type="matrix"
              in="shadowInnerInner1"
            />
          </filter>
        </defs>
        <g id="Components:-Data-Vis" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="GE-VD---Gauges" transform="translate(-419.000000, -546.000000)">
            <g id="Group-3-Copy-15" transform="translate(418.000000, 527.000000)">
              <g id="Dial" transform="translate(0.926471, 19.000000)">
                <mask id="mask-3" fill="white">
                  <use xlinkHref="#path-2" />
                </mask>

                <g id="Combined-Shape">
                  <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2" />
                  <use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-2" />
                </g>
                {threshold && <ThresholdRadial threshold={threshold} max={max} reverse={reverse} />}
                <g
                  id="Grid"
                  style={{ mixBlendMode: 'multiply' }}
                  mask="url(#mask-3)"
                  strokeLinecap="round"
                >
                  <g transform="translate(-12.407938, -10.000000)">
                    <path
                      d="M61.9814676,14.5 L61.9814676,0.357910156"
                      id="Line-2-Copy"
                      stroke={hashColor}
                    />
                    <path
                      d="M7.57352941,56.5941945 L7.57352941,42.4521047"
                      id="Line-2-Copy-2"
                      stroke={hashColor}
                      transform="translate(8.000000, 49.594195) rotate(-75.000000) translate(-8.000000, -49.594195) "
                    />
                    <path
                      d="M13.2708097,43.1306656 L13.2708097,28.9885758"
                      id="Line-2-Copy-3"
                      stroke={hashColor}
                      transform="translate(13.697280, 36.130666) rotate(-60.000000) translate(-13.697280, -36.130666) "
                    />
                    <path
                      d="M17.30883,33.6507125 L17.30883,5.31776184"
                      id="Line-2-Copy-4"
                      stroke={hashColor}
                      transform="translate(17.735301, 19.650713) rotate(-45.000000) translate(-17.735301, -19.650713) "
                    />
                    <path
                      d="M33.9243313,22.7893421 L33.9243313,8.64725225"
                      id="Line-2-Copy-5"
                      stroke={hashColor}
                      transform="translate(34.350802, 15.789342) rotate(-30.000000) translate(-34.350802, -15.789342) "
                    />
                    <path
                      d="M47.4730694,16.297775 L47.4730694,2.15568517"
                      id="Line-2-Copy-6"
                      stroke={hashColor}
                      transform="translate(47.899540, 9.297775) rotate(-15.000000) translate(-47.899540, -9.297775) "
                    />
                    <path
                      d="M76.4608025,16.5185324 L76.4608025,2.37644259"
                      id="Line-2-Copy-7"
                      stroke={hashColor}
                      transform="translate(76.887273, 9.518532) rotate(-345.000000) translate(-76.887273, -9.518532) "
                    />
                    <path
                      d="M89.9243313,23.2158127 L89.9243313,9.07372284"
                      id="Line-2-Copy-8"
                      stroke={hashColor}
                      transform="translate(90.350802, 16.215813) rotate(-330.000000) translate(-90.350802, -16.215813) "
                    />
                    <path
                      d="M106.404284,34.253833 L106.404284,6.65936125"
                      id="Line-2-Copy-9"
                      stroke={hashColor}
                      transform="translate(106.830755, 20.253833) rotate(-315.000000) translate(-106.830755, -20.253833) "
                    />
                    <path
                      d="M110.265655,43.8693344 L110.265655,29.7272445"
                      id="Line-2-Copy-10"
                      stroke={hashColor}
                      transform="translate(110.692125, 36.869334) rotate(-300.000000) translate(-110.692125, -36.869334) "
                    />
                    <path
                      d="M115.757222,57.4180724 L115.757222,43.2759826"
                      id="Line-2-Copy-11"
                      stroke={hashColor}
                      transform="translate(116.183693, 50.418072) rotate(-285.000000) translate(-116.183693, -50.418072) "
                    />
                  </g>
                </g>
                <path
                  d="M99.0735294,45.6765357 L99.0735294,5 C99.0735294,2.23857625 96.8349532,-4.47896705e-13 94.0735294,-4.54747351e-13 L5.07352941,-4.53859172e-13 C2.31210566,-4.53351907e-13 0.0735294118,2.23857625 0.0735294118,5 L0.0735294118,45.6771583 C0.0735294118,47.2266009 1.2534817,48.5211342 2.79629403,48.664321 L49.5124748,53 L96.3500876,48.6637611 C97.8931919,48.5209 99.0735294,47.226239 99.0735294,45.6765357 Z"
                  id="Mask"
                  mask="url(#mask-3)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SVGUniqueID>
  );
};

GaugeBackground.propTypes = {
  level: PropTypes.string,
  theme: PropTypes.shape({
    gaugeChart: PropTypes.instanceOf(Object),
  }).isRequired,
  threshold: PropTypes.number,
  max: PropTypes.number,
  reverse: PropTypes.bool,
};

GaugeBackground.defaultProps = {
  level: levels.DEFAULT,
  threshold: 0,
  max: 0,
  reverse: false,
};

export default withTheme(GaugeBackground);
