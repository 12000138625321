import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { globalColors, typography } from '@ge/tokens';

import { AssetComponentDetails } from '../asset-details/asset-component-details';

const StyledComponent = styled.div`
  padding: 16px;
  height: 600px;
  width: 745px;
`;

const StyledComponentHierarchy = styled.div`
  width: 506px;
  height: 423px;
  float: left;
  border-bottom: ${(props) => props.theme.button.border};
`;

const HeaderComponent = styled.div`
  width: 504px;
  border-bottom: solid 1.5px ${(props) => props.theme.assetOverview.issuesSidebar.headerBorderColor};
  display: flex;
`;

const LavelContent1 = styled.div`
  max-height: 410px;
  width: 165px;
  float: left;
`;

const LavelContent2 = styled.div`
  max-height: 410px;
  width: 165px;
  float: left;
  padding-left: 8px;
  opacity: ${(props) => props.changeOpacity};
  pointer-events: ${(props) => (props.changeOpacity ? 'none' : 'auto')};
`;

const LavelContent3 = styled.div`
  max-height: 410px;
  width: 160px;
  float: left;
  opacity: ${(props) => props.changeOpacity};
  pointer-events: ${(props) => (props.changeOpacity ? 'none' : '')};
  padding-left: 8px;
`;

const LevelHeader = styled.div`
  height: 14px;
  color: ${(props) => props.theme.table.columnTextColor};
  font-family: ${typography.family.default};
  opacity: ${(props) => props.changeOpacity};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  width: 170px;
  padding-right: 8px;
`;

const ContentRow = styled.dt`
  height: auto;
  width: auto;
  box-sizing: border-box;
  border-bottom: 1px solid #1c252d;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    background: #20393D;
    box-shadow: inset 0 -1px 0 0 #1A5966;
  `}
  .component-content {
    width: auto;
    color: ${(props) =>
      props.activatedLevel
        ? props.theme.themeSelector.tabsTextColor
        : props.theme.table.columnTextColor};
    font-family: ${typography.family.default};
    size: 12px;
    letter-spacing: 0;
    line-height: 30px;
    height: auto;
    word-break: break-word;
  }
`;

const IconButton = styled.div`
  float: right;
  margin-top: -20px;
  &:focus {
    outline: none;
  }
  //
`;

const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  min-height: 57vh;
  overflow: hidden;
  [data-simplebar='init'] {
    padding-right: 10px;
  }
  ${scrollbarWrapperOverride}
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: props.activatedLevel ? Icons.ARROW_RIGHT_ACTIVE : Icons.ARROW_RIGHT,
  color: props.activatedLevel ? globalColors.white : globalColors.slate4,
}))``;

const getComponentId = (component, componentList) => {
  const filterObj = componentList.filter((item) => item.componentType === component.type);
  return filterObj[component.componentTypeNumber - 1]
    ? filterObj[component.componentTypeNumber - 1].componentId
    : '';
};

export const AssetComponentHierarchy = ({
  componentList,
  componentHierarchy,
  level2Component,
  level3Component,
  onComponentSelect,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const [selectedComponentId, setSelectedComponentId] = useState();
  const [selectedComponentHierarchy = [], setSelectedComponentHierarchy] = useState();
  const [currentSelectedComponent, setCurrentSelectedComponent] = useState();
  const handleComponentSelect = (e, data, level) => {
    e.preventDefault();
    onComponentSelect(data, level);
    selectedComponentHierarchy[level] = data.name;
    setSelectedComponentHierarchy(selectedComponentHierarchy);
    setCurrentSelectedComponent(data.name);
    if (level === 'level1') {
      setSelectedComponentId();
    }
    if (level === 'level2' || level === 'level3') {
      setSelectedComponentId(getComponentId(data, componentList));
    }
  };

  if (!ready || !componentHierarchy) {
    return null;
  }

  return (
    <StyledComponent>
      <StyledComponentHierarchy>
        <HeaderComponent>
          <LevelHeader>{t('asset_panel.Level1', 'Level1')}</LevelHeader>
          <LevelHeader changeOpacity={selectedComponentHierarchy['level1'] ? '' : '0.4'}>
            {t('asset_panel.Level2', 'Level2')}
          </LevelHeader>
          <LevelHeader changeOpacity={selectedComponentHierarchy['level2'] ? '' : '0.4'}>
            {t('asset_panel.Level3', 'Level3')}
          </LevelHeader>
        </HeaderComponent>
        <LavelContent1>
          <ScrollingWrapper>
            <ScrollingContainer autoHide={false}>
              {componentHierarchy?.map((component) => {
                return (
                  <ContentRow
                    key={component.name}
                    active={
                      currentSelectedComponent && currentSelectedComponent === component.name
                        ? true
                        : false
                    }
                    activatedLevel={
                      selectedComponentHierarchy['level1'] &&
                      selectedComponentHierarchy['level1'] === component.name
                        ? true
                        : false
                    }
                    onClick={(e) => handleComponentSelect(e, component, 'level1')}
                  >
                    <div className="component-content">{component.name}</div>
                    <IconButton>
                      <ArrowIcon
                        activatedLevel={
                          selectedComponentHierarchy['level1'] &&
                          selectedComponentHierarchy['level1'] === component.name
                            ? true
                            : false
                        }
                      />
                    </IconButton>
                  </ContentRow>
                );
              })}
            </ScrollingContainer>
          </ScrollingWrapper>
        </LavelContent1>
        <LavelContent2 changeOpacity={selectedComponentHierarchy['level1'] ? '' : '0.4'}>
          <ScrollingWrapper>
            <ScrollingContainer autoHide={false}>
              {level2Component?.map((component) => {
                return (
                  <>
                    <ContentRow
                      active={
                        currentSelectedComponent && currentSelectedComponent === component.name
                          ? true
                          : false
                      }
                      activatedLevel={
                        selectedComponentHierarchy['level2'] &&
                        selectedComponentHierarchy['level2'] === component.name
                          ? true
                          : false
                      }
                      onClick={(e) => handleComponentSelect(e, component, 'level2')}
                    >
                      <div className="component-content">{component.name}</div>
                      <IconButton>
                        <ArrowIcon
                          activatedLevel={
                            selectedComponentHierarchy['level2'] &&
                            selectedComponentHierarchy['level2'] === component.name
                              ? true
                              : false
                          }
                        />
                      </IconButton>
                    </ContentRow>
                  </>
                );
              })}
            </ScrollingContainer>
          </ScrollingWrapper>
        </LavelContent2>
        <LavelContent3 changeOpacity={selectedComponentHierarchy['level2'] ? '' : '0.4'}>
          <ScrollingWrapper>
            <ScrollingContainer autoHide={false}>
              {level3Component?.map((component) => {
                return (
                  <>
                    <ContentRow
                      active={
                        currentSelectedComponent && currentSelectedComponent === component.name
                          ? true
                          : false
                      }
                      activatedLevel={
                        selectedComponentHierarchy['level3'] &&
                        selectedComponentHierarchy['level3'] === component.name
                          ? true
                          : false
                      }
                      onClick={(e) => handleComponentSelect(e, component, 'level3')}
                    >
                      <div className="component-content">{component.name}</div>
                      <IconButton>
                        <ArrowIcon
                          activatedLevel={
                            selectedComponentHierarchy['level3'] &&
                            selectedComponentHierarchy['level3'] === component.name
                              ? true
                              : false
                          }
                        />
                      </IconButton>
                    </ContentRow>
                  </>
                );
              })}
            </ScrollingContainer>
          </ScrollingWrapper>
        </LavelContent3>
      </StyledComponentHierarchy>
      {selectedComponentId && selectedComponentId !== '' ? (
        <AssetComponentDetails
          componentId={selectedComponentId}
          componentName={currentSelectedComponent}
        />
      ) : (
        ''
      )}
    </StyledComponent>
  );
};

AssetComponentHierarchy.propTypes = {
  componentHierarchy: PropTypes.instanceOf(Object),
  componentList: PropTypes.instanceOf(Object),
  level2Component: PropTypes.instanceOf(Object),
  level3Component: PropTypes.instanceOf(Object),
  onComponentSelect: PropTypes.func,
};
