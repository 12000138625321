import { PropTypes } from 'prop-types';
import difference from 'ramda/src/difference';
import intersection from 'ramda/src/intersection';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { BoldSearch } from '@ge/components/bold-search';
import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { EntityType } from '@ge/models/constants';
import { AssetComponents } from '@ge/shared/components/tasks/task-fields/asset-components';
import {
  FormRow,
  FormSection,
  TaskTypeRow,
} from '@ge/shared/components/tasks/task-template-shared';
import { useCaseEntityFilter } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { StatusColor } from '@ge/tokens/colors/colors';
import { elevations } from '@ge/tokens/elevations';

const AffectedSiteAssetRow = styled(TaskTypeRow)`
  justify-content: flex-start;
`;

const EntitySelectContainer = styled.div`
  width: 275px;
`;

const EntitySelect = styled.div`
  background: ${(props) => props.theme.select.secondaryBackground};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  cursor: default;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
  min-height: 25px;
  padding: 2px 8px;
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER} !important` : '')};
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.select.chevronColor,
}))`
  margin-top: 3px;
`;

const EntitySelectMenu = styled.div`
  position: relative;
`;

const SelectMenu = styled.div`
  background: ${(props) => props.theme.menu.backgroundColor};
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.15);
  left: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: opacity 0s;
  visibility: hidden;
  width: 273px;
  z-index: ${elevations.N1};
  &.show {
    opacity: 1;
    transition: opacity 0.1s 0.1s;
    visibility: visible;
    z-index: ${elevations.P10};
  }
`;

const SelectResultsContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  height: 250px;
`;

const ParentRow = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px 6px;
  &:hover {
    background-color: ${(props) => props.theme.newTaskDialog.selectMenuHoverBackground};
    border-color: ${(props) => props.theme.newTaskDialog.selectMenuHoverBorder};
  }
  span {
    flex: 1;
  }
  .caret {
    transition: transform 0.2s ease;
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(0deg);
    }
  }
`;

const StyledAssetComponents = styled(AssetComponents)`
  display: flex;
  &.asset-select {
    .select__menu {
      .select__menu-list {
        max-height: 200px;
        &::-webkit-scrollbar {
          width: 4px;
          height: 0px;
        }
        &::-webkit-scrollbar-track {
          background: ${(props) => props.theme.scrollbar.trackBackground};
        }
        &::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.scrollbar.thumbBackground};
          border-radius: 2.5px;
        }
      }
    }
  }
  > div {
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const CaretIcon = styled(Icon).attrs((props) => ({
  size: 8,
  icon: Icons.CARET,
  color: props.theme.newTaskDialog.selectMenuIcon,
}))`
  margin: 0 8px;
`;

const SiteIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SITE,
  color: props.theme.newTaskDialog.selectMenuIcon,
}))`
  margin-right: 5px;
`;

const AssetIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.TURBINE,
  color: props.theme.newTaskDialog.selectMenuIcon,
}))`
  margin-right: 5px;
`;

const StyledVirtualResults = styled.div`
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const ParentEntityWrapper = styled.div`
  position: relative;
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  medium: true,
  color: props.theme.select.chevronColor,
}))`
  margin-right: 8px;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0;
  label {
    margin-left: ${(props) => (props.childType === EntityType.SITE ? '10px' : '30px')};
  }
  > svg {
    margin-right: 8px;
  }
  &:hover {
    background-color: ${(props) => props.theme.newTaskDialog.selectMenuHoverBackground};
    border-color: ${(props) => props.theme.newTaskDialog.selectMenuHoverBorder};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  input + div {
    width: 12px;
    height: 12px;
  }
`;

const SelectMenuFooter = styled.div`
  box-shadow: 0 1px 7px 7px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  position: relative;
  .cancel-button {
    background: none;
    font-size: 11px;
    margin-left: 7px;
    padding-left: 5px;
    text-transform: uppercase;
  }
  .selected-count {
    margin-right: 5px;
  }
  .select-button {
    margin-right: 12px;
  }
`;

const SearchWrapper = styled.div`
  padding: 8px 10px;
  .filter {
    position: relative;
    input {
      background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
      box-sizing: border-box;
      border: 1px solid ${(props) => props.theme.input.borderNavbarInput};
      border-radius: 2px;
      caret-color: ${(props) => props.theme.newTaskDialog.searchCaret};
      color: ${(props) => props.theme.newTaskDialog.searchText};
      height: 28px;
      padding: 6px 8px;
      width: 100%;
      ::placeholder {
        font-size: 14px;
        font-style: italic;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }
`;

export const CaseAffectedAssets = ({ entity, entityType }) => {
  const { t, ready } = useTranslation(['tasks', 'general'], { useSuspense: false });

  const { errors, register, setValue, trigger } = useFormContext();

  register('entityList', { required: true });

  const [expandedParents, setExpandedParents] = useState(entity?.site?.id ? [entity.site.id] : []);
  const [affectedEntities, setAffectedEntities] = useState({
    [EntityType.ASSET]: entityType === EntityType.ASSET && entity ? [entity] : [],
  });
  const [selectedEntities, setSelectedEntities] = useState(affectedEntities);
  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const [isSelectMenuApplied, setIsSelectMenuApplied] = useState(false);
  const [filterText, setFilterText] = useState('');

  const { sitesFiltered, isLoading } = useCaseEntityFilter(filterText);
  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: sitesFiltered.length,
    parentRef,
    estimateSize: React.useCallback(() => 29, []),
    overscan: 4,
  });

  useEffect(() => {
    affectedEntities.asset.length && setValue('entityList', affectedEntities.asset);
  }, [affectedEntities, setValue, entityType]);

  const toggleSelectMenu = useCallback(() => {
    setShowSelectMenu(!showSelectMenu);
  }, [showSelectMenu]);

  const siteAssets = useMemo(() => {
    return sitesFiltered
      .map((site) => site.assets || [])
      .flat()
      .reduce((acc, asset) => {
        acc[asset.id] = asset;
        return acc;
      }, {});
  }, [sitesFiltered]);

  useEffect(() => {
    sitesFiltered.filter((objItem) => {
      if (objItem.name?.toLowerCase().includes(filterText)) {
        return objItem;
      }
    });
    if (sitesFiltered.length === 1) {
      setExpandedParents([sitesFiltered[0]?.id]);
    } else if (filterText.length > 1) {
      const parentsToExpand = sitesFiltered.map((s) => s.id).reduce((acc, id) => [...acc, id], []);
      setExpandedParents(parentsToExpand);
    } else {
      setExpandedParents([]);
    }
  }, [filterText, sitesFiltered]);

  // if menu is pre-populated then set selected state
  useEffect(() => {
    setIsSelectMenuApplied(Boolean(entity));
  }, [entity]);

  const getParentCheckState = useCallback(
    (entity) => {
      if (affectedEntities[EntityType.ASSET].length === 0) {
        return CheckedState.UNCHECKED;
      }
      const assetsArray = entity.assets?.map((asset) => asset.id);
      if (assetsArray.every((assetId) => affectedEntities[EntityType.ASSET].includes(assetId))) {
        return CheckedState.CHECKED;
      }
      if (assetsArray.some((assetId) => affectedEntities[EntityType.ASSET].includes(assetId))) {
        return CheckedState.INDETERMINATE;
      }
      return CheckedState.UNCHECKED;
    },
    [affectedEntities],
  );

  const dropdownParent = useCallback(
    ({ id }) =>
      expandedParents.includes(id)
        ? setExpandedParents((prevExpandedParents) => prevExpandedParents.filter((el) => el !== id))
        : setExpandedParents((prevExpandedParents) => [...prevExpandedParents, id]),
    [expandedParents, setExpandedParents],
  );

  const toggleParentCheckbox = useCallback(
    (entity) => {
      if (isSelectMenuApplied) setIsSelectMenuApplied(false);
      const assetsArray = entity.assets?.map((asset) => asset.id);
      return assetsArray.every((asset) => affectedEntities[EntityType.ASSET].includes(asset))
        ? setAffectedEntities((prevaffectedEntities) => {
            return {
              ...prevaffectedEntities,
              [EntityType.ASSET]: prevaffectedEntities[EntityType.ASSET].filter((asset) =>
                difference(affectedEntities[EntityType.ASSET], assetsArray).includes(asset),
              ),
            };
          })
        : setAffectedEntities((prevaffectedEntities) => {
            return {
              ...prevaffectedEntities,
              [EntityType.ASSET]: prevaffectedEntities[EntityType.ASSET].concat(
                difference(
                  assetsArray,
                  intersection(affectedEntities[EntityType.ASSET], assetsArray),
                ),
              ),
            };
          });
    },
    [affectedEntities, isSelectMenuApplied],
  );

  const getChildCheckState = useCallback(
    (entityId, entityType) =>
      affectedEntities[entityType]?.includes(entityId)
        ? CheckedState.CHECKED
        : CheckedState.UNCHECKED,
    [affectedEntities],
  );

  const toggleChildCheckbox = useCallback(
    (e, entity, entityType) => {
      if (isSelectMenuApplied) setIsSelectMenuApplied(false);
      killEventPropagation(e);
      return affectedEntities[entityType]?.includes(entity.id)
        ? setAffectedEntities((prevaffectedEntities) => {
            return {
              ...prevaffectedEntities,
              [entityType]: prevaffectedEntities[entityType]?.filter((id) => id !== entity.id),
            };
          })
        : setAffectedEntities((prevaffectedEntities) => {
            return {
              ...prevaffectedEntities,
              [entityType]: [...prevaffectedEntities[entityType], entity.id],
            };
          });
    },
    [affectedEntities, setAffectedEntities, isSelectMenuApplied],
  );

  // do we need to be able to reset to pre-selected state here?
  const selectMenuCancel = useCallback(
    (e, entityType) => {
      setAffectedEntities((prevaffectedEntities) => {
        return {
          ...prevaffectedEntities,
          [entityType]: selectedEntities[entityType],
        };
      });
      toggleSelectMenu(e);
    },
    [toggleSelectMenu, selectedEntities],
  );

  const selectMenuApply = useCallback(
    (e) => {
      // TODO: Change create task service to accept arrays of assets/sites
      if (entityType === EntityType.ASSET) {
        setValue('entityList', affectedEntities.asset.length ? affectedEntities.asset : null);
      } else if (entityType === EntityType.SITE) {
        setValue('entityList', affectedEntities.site.length ? affectedEntities.site : null);
      }
      setValue('component1', '', { shouldDirty: true });
      setValue('component2', '', { shouldDirty: true });
      setValue('component3', '', { shouldDirty: true });
      setIsSelectMenuApplied(true);
      toggleSelectMenu(e);
      trigger('entityList');
      setSelectedEntities(affectedEntities);
    },
    [affectedEntities, setValue, toggleSelectMenu, trigger, entityType],
  );

  const selectionMenu = useCallback(
    (e) => {
      if (!showSelectMenu) {
        toggleSelectMenu();
      } else {
        selectMenuApply(e);
      }
    },
    [showSelectMenu, toggleSelectMenu, selectMenuApply],
  );

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  const renderChildEntity = useCallback(
    (childEntity, childType) => {
      return (
        <div>
          {childEntity?.map((entity) => {
            return (
              <CheckboxWrapper
                key={entity.id}
                onMouseDown={(e) => toggleChildCheckbox(e, entity, childType)}
                childType={childType}
              >
                <StyledCheckbox
                  checkState={getChildCheckState(entity.id, childType)}
                  childType={childType}
                />
                {childType === EntityType.SITE && <SiteIcon />}
                {childType === EntityType.ASSET && <AssetIcon />}
                <BoldSearch text={entity.name} textBold={filterText} />
              </CheckboxWrapper>
            );
          })}
        </div>
      );
    },
    [filterText, getChildCheckState, toggleChildCheckbox],
  );

  const renderParentEntity = useCallback(
    ({ index, measureRef }, parentType) => {
      const parentEntity = sitesFiltered[index];
      if (!parentEntity) return;

      return (
        <ParentEntityWrapper key={`${parentEntity.id}`} ref={measureRef}>
          <ParentRow onClick={() => dropdownParent(parentEntity)}>
            <CaretIcon
              className={expandedParents.includes(parentEntity.id) ? 'caret rotate' : 'caret'}
            />
            <div
              onClick={(e) => killEventPropagation(e)}
              onMouseDown={() => toggleParentCheckbox(parentEntity)}
            >
              <StyledCheckbox checkState={getParentCheckState(parentEntity)} />
            </div>
            {parentType === EntityType.SITE && <SiteIcon />}
            <span>{parentEntity.name}</span>
            <StyledBadge label={parentEntity.assets?.length.toString()} />
          </ParentRow>
          {expandedParents.includes(parentEntity.id) &&
            renderChildEntity(parentEntity.assets, EntityType.ASSET)}
        </ParentEntityWrapper>
      );
    },
    [
      sitesFiltered,
      dropdownParent,
      expandedParents,
      renderChildEntity,
      getParentCheckState,
      toggleParentCheckbox,
    ],
  );

  const renderEntitySelectResults = (entityType) => {
    switch (entityType) {
      case EntityType.ASSET:
        return (
          <StyledVirtualResults paddingTop={paddingTop} paddingBottom={paddingBottom}>
            {items.map((item) => renderParentEntity(item, EntityType.SITE))}
          </StyledVirtualResults>
        );
      case EntityType.SITE:
        return renderChildEntity(sitesFiltered, EntityType.SITE);
      default:
        return null;
    }
  };

  const renderEntitySelect = (entityType) => {
    switch (affectedEntities[EntityType.ASSET]?.length ?? 0) {
      case 0:
        return <>{t('new_task.select_assets', 'Select Site / Asset(s)')}</>;
      case 1:
        return <>{siteAssets[affectedEntities[entityType][0]]?.name}</>;
      default:
        return (
          <>
            {`${affectedEntities[entityType].length} ${t(
              'new_task.assets_selected',
              'Assets Selected',
            )}`}
          </>
        );
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <FormSection>
      <FormRow>
        <h4>{t('section_header.affected_assets', 'Affected Asset(s)')}</h4>
      </FormRow>
      <AffectedSiteAssetRow>
        <EntitySelectContainer>
          <div>{t('case.assets', 'Site / Asset(s) *')}</div>
          <EntitySelectMenu>
            <EntitySelect errors={errors} id="entityList" onClick={(e) => selectionMenu(e)}>
              {renderEntitySelect(entityType)}
              <ChevronIcon />
            </EntitySelect>
            <SelectMenu className={showSelectMenu ? 'show' : ''}>
              <SearchWrapper>
                <Filter
                  text={filterText}
                  onChange={(event) => setFilterText(event.target.value.toLowerCase())}
                  placeholder={t('form.search', 'Search')}
                  hideResultsOnBlur={false}
                />
              </SearchWrapper>
              <SelectResultsContainer>
                {isLoading ? (
                  <MiniLoader />
                ) : (
                  <ScrollingContainer ref={parentRef}>
                    {renderEntitySelectResults(entityType)}
                  </ScrollingContainer>
                )}
              </SelectResultsContainer>
              <SelectMenuFooter>
                <Button
                  className="cancel-button"
                  onClick={(e) => selectMenuCancel(e, entityType)}
                  type="button"
                >
                  {t('general.cancel', 'Cancel')}
                </Button>
                <span className="selected-count">
                  {affectedEntities[entityType]?.length ?? 0} {t('form.selected', 'Selected')}
                </span>
                <Button
                  className="select-button"
                  onClick={(e) => selectMenuApply(e)}
                  primary
                  type="button"
                >
                  {t('form.select', 'Select')}
                </Button>
              </SelectMenuFooter>
            </SelectMenu>
          </EntitySelectMenu>
        </EntitySelectContainer>
      </AffectedSiteAssetRow>
      <FormRow>
        <StyledAssetComponents
          assetIds={affectedEntities[EntityType.ASSET]}
          className="asset-select"
          width={160}
          reset={isSelectMenuApplied}
          requiredL1={affectedEntities[EntityType.ASSET].length > 1 ? false : true}
        />
      </FormRow>
    </FormSection>
  );
};

CaseAffectedAssets.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.oneOf(Object.values(EntityType)),
};

CaseAffectedAssets.defaultProps = {
  entity: undefined,
  entityType: EntityType.ASSET,
};
