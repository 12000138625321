import { useCallback, useState } from 'react';

import { KpiCategoryDefs, QueryKey } from '@ge/models/constants';
import { InputEntityType } from '@ge/serverless-models/src/rendigital/enums';

import { useGlobalFilters } from '../hooks';

import useIecData from './use-iec-data';

const useIecDataGraph = ({ dateRange }) => {
  const { siteIds } = useGlobalFilters();
  const hasSitesSelected = !!siteIds?.length;

  const [currentIecCategory, setCurrentIecCategory] = useState(
    KpiCategoryDefs.EVENT_UNAVAILABLE_TIME,
  );

  const { setSelectedIecCategoryData, ...iecDataRest } = useIecData({
    entityType: InputEntityType.FLEET,
    category: currentIecCategory,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    assetQueryKey: QueryKey.ALL_SITES_IEC_ASSETS,
    enabled: hasSitesSelected,
  });

  const handleIecCategoryChange = useCallback(
    (category) => {
      setCurrentIecCategory(category);
      setSelectedIecCategoryData({});
    },
    [setSelectedIecCategoryData],
  );

  const handleSeeTop100 = useCallback(
    (selectedCategoryData) => setSelectedIecCategoryData({ ...selectedCategoryData, siteIds }),
    [setSelectedIecCategoryData, siteIds],
  );

  return { currentIecCategory, handleIecCategoryChange, ...iecDataRest, handleSeeTop100 };
};

export default useIecDataGraph;
