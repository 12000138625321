import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { DataLoaderType } from '@ge/models/constants';
import { TaskProvider } from '@ge/shared/components/tasks/task-provider';
import { ViewPartsConsumed } from '@ge/shared/components/tasks/templates/sections/parts-consumed-section/view-parts-consumed';
import { ViewResolutionNote } from '@ge/shared/components/tasks/templates/sections/resolution-note-section/view-resolution-note';
import { ViewTiming } from '@ge/shared/components/tasks/templates/sections/timing-section/view-timing';
import { useCaseLinkedTasks } from '@ge/shared/components/use-linked-task-menu';
import { EntityDetailsContext } from '@ge/shared/context';
import { killEventPropagation } from '@ge/shared/util';

import { CaseDetailRow } from './case-resolution-detail-rows';
import { CaseResolutionTaskTable } from './case-resolution-task-table';

const TaskDetailWrapper = styled.div`
  margin-right: 32px;
  padding-bottom: 40px;
  .task-panel-row {
    align-items: flex-start;
    display: flex;
    &:last-of-type {
      .column-detail {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

export const CaseResolutionTasks = ({ issue }) => {
  const { t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const [selectedTask, setSelectedTask] = useState();

  const { tasks, isLoading: isTasksLoading } = useCaseLinkedTasks({ caseId: issue.id });

  const handleTaskSelect = useCallback((_, value) => setSelectedTask(value), [setSelectedTask]);

  const { showTaskDetails } = useContext(EntityDetailsContext);

  const handleTaskPanelOpen = useCallback(
    (e, taskId) => {
      killEventPropagation(e);
      showTaskDetails(taskId);
    },
    [showTaskDetails],
  );

  return (
    <TaskProvider>
      <CaseDetailRow
        title={t('case_panel.resolution.tasks', 'Tasks')}
        detail={
          <DataLoader type={DataLoaderType.TABLE} isLoading={isTasksLoading}>
            <CaseResolutionTaskTable
              caseTasks={tasks}
              handleTaskSelect={handleTaskSelect}
              selectedRowId={selectedTask?.id}
              openTaskPanel={handleTaskPanelOpen}
            />
          </DataLoader>
        }
      />

      {selectedTask && (
        <TaskDetailWrapper>
          <ViewTiming task={selectedTask} />
          <ViewResolutionNote task={selectedTask} />
          <ViewPartsConsumed task={selectedTask} />
        </TaskDetailWrapper>
      )}
    </TaskProvider>
  );
};

CaseResolutionTasks.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
};
