import * as request from '@ge/shared/services/api';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

/**
 * Fetch people for admin people table
 */
export const fetchPeopleTable = () => {
  return request.get('/cmn/admin/users', {
    baseURL: USERS_BASE_URL,
  });
};

export const fetchPersonByUsername = (username) => {
  return request.get('/cmn/admin/users', {
    baseURL: USERS_BASE_URL,
    params: {
      username: username,
    },
  });
};

/**
 * Update a person
 */
export const updatePerson = async (person) =>
  request.put(`/cmn/admin/users/${person.username}`, person, { baseURL: USERS_BASE_URL });

/**
 * Delete a person
 */
export const deletePerson = (person) => {
  return request.deleteItem(`/cmn/admin/users`, person, {
    baseURL: USERS_BASE_URL,
  });
};
