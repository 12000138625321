import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap
  justify-content: flex-start;
`;

const ConfirmationDialogComponent = ({
  header,
  children,
  isOpen,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
}) => (
  <Dialog
    contentWidth
    footer={
      <FooterContainer>
        <FooterButtons>
          {onCancel && cancelLabel && <Button onClick={onCancel}>{cancelLabel}</Button>}
          {onConfirm && (
            <Button onClick={onConfirm} primary type="submit">
              {confirmLabel}
            </Button>
          )}
        </FooterButtons>
      </FooterContainer>
    }
    header={header}
    isOpen={isOpen}
    onClose={onCancel}
    onConfirm={onConfirm}
    padContent={true}
  >
    {children}
  </Dialog>
);

ConfirmationDialogComponent.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmationDialogComponent.defaultProps = {
  isOpen: false,
  children: null,
  header: null,
  cancelLabel: undefined,
  confirmLabel: undefined,
  onCancel: undefined,
  onConfirm: undefined,
};

export const ConfirmationDialog = withTheme(ConfirmationDialogComponent);
