import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { DateTimeFormats } from '@ge/models/constants';
import { useCaseSignalData } from '@ge/shared/data-hooks/use-case-signal-data';
import { timezoneFormat } from '@ge/util/dayjs-utils';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(timezoneFormat);

const CHART_HEIGHT = 260;

const mapSensorData = ({ site, asset, xAxisTitle, yAxisTitle }) => ({ lineColor, data }) => {
  const { timezone: siteTimeZone = 'UTC' } = site ?? {};

  return data.map((series) => {
    return {
      data: series.map((coord) => {
        const localDate = dayjs.unix(coord.ts).tz(siteTimeZone);
        return {
          x: localDate.$d,
          y: parseFloat(coord[alert.signalId]),
          date: `${localDate.format(DateTimeFormats.DEFAULT_DATE_TIME_TIMEZONE)}`,
        };
      }),
      color: lineColor,
      tooltipHeader: `<h4>${site.name} ${asset.name}</h4>`,
      tooltipPointFormatter: ({ y, date }) => `
      <div class="body-4">
        ${date} <b>${xAxisTitle}</b><br/>
        ${y} <b>${yAxisTitle}</b>
      </div>
    `,
    };
  });
};

const getChartOptions = ({ theme, site, asset, xAxisTitle, yAxisTitle, sensorData }) => {
  const { alerts = [], context = [] } = sensorData ?? {};
  const { alertLine, contextLine } = theme?.sensorReadingsChart ?? {};

  const dataMapFn = mapSensorData({ site, asset, xAxisTitle, yAxisTitle });

  const series = [
    dataMapFn({ lineColor: alertLine, data: alerts }),
    dataMapFn({ lineColor: contextLine, data: context }),
  ].flat();

  return {
    xAxisTitle,
    yAxisTitle,
    series,
  };
};

const SensorReadingChart = ({ theme, alert, asset, site }) => {
  const { ready, t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { isLoading, data: sensorData, error } = useCaseSignalData(alert);

  const { xAxisTitle, yAxisTitle, series = [] } = useMemo(() => {
    if (!sensorData?.alerts && !sensorData?.context) return {};

    return getChartOptions({
      sensorData,
      theme,
      site,
      asset,
      xAxisTitle: t('sensor_readings.x_axis_title', 'Time'),
      yAxisTitle: alert?.signalId ?? '',
    });
  }, [alert, site, asset, sensorData, t, theme]);

  const { noData, noDataDescription } = useMemo(() => {
    if (error || !alert?.start) {
      return {
        noData: true,
        noDataDescription: error?.message ?? '',
      };
    }
    return {};
  }, [alert, error]);

  if (!ready) {
    return null;
  }

  return (
    <DataLoader
      isLoading={isLoading}
      noData={noData}
      noDataDescription={noDataDescription}
      type={ChartType.SPLINE}
      height={`${CHART_HEIGHT}px`}
    >
      <Chart
        height={CHART_HEIGHT}
        recreateOnUpdate
        series={series}
        type={ChartType.SPLINE}
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        yAxisOpposite={false}
        xAxisType={'datetime'}
        noDataLabel={t('sensor_readings.no_data_label', 'No Data')}
        disableSideMargin
        useUTC={false}
        tooltip={{ outside: false }}
      />
    </DataLoader>
  );
};

SensorReadingChart.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.instanceOf(Object),
  asset: PropTypes.instanceOf(Object),
  site: PropTypes.instanceOf(Object),
};

SensorReadingChart.defaultProps = {
  alert: null,
  asset: {},
  site: {},
};

SensorReadingChart.displayName = 'SensorReadingChart';

export default withTheme(SensorReadingChart);
