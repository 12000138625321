import React from 'react';
import Loadable from 'react-loadable';

import { Loader } from '@ge/components/loader';

/**
 * Define a JSX component as a lazy loadable bundle to defer loading of feature
 * specific javascript until a route is invoked after authorization.
 *
 * @param {function} importFn Function that invokes webpack import()
 * @param {*} namedExport Named export to reference from imported package
 */
export const getLoadable = (importFn, namedExport) =>
  Loadable({
    loader: importFn,
    loading: Loader,
    render: function RenderedLoadable(loaded, props) {
      const Component = loaded[namedExport] || loaded.default;
      /* eslint-disable react/jsx-props-no-spreading */
      return <Component {...props} />;
    },
  });
