import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AnalyzeLocators } from '@ge/models/data-locators';

import { chartIconPropType } from '../../models';
import { formatKPIValue } from '../../util';

import DashboardChartToggle from './dashboard-chart-toggle';

const NAMESPACE = 'analyze.dashboard';

// TODO: make theme path more configurable
const Title = styled.div`
  align-items: center;
  border-bottom: 2px solid ${(props) => props.theme.analyze.site.chartTitleBorder};
  display: flex;
  flex-flow: row nowrap;
  height: 24px;
  justify-content: space-between;
  padding-bottom: 6px;
  margin-bottom: 6px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalContainer = styled.div`
  display: flex;
  padding-right: 30px;
`;

const Total = styled.p`
  color: ${(props) => props.theme.layout.textColor};
  font-size: 24px;
  align-self: left;
`;

const TotalLabel = styled.p`
  height: 24px;
  font-size: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  text-align: right;
  align-self: center;
`;

const TotalText = styled(TotalLabel)`
  max-width: 30px;
`;

const AverageText = styled(TotalLabel)`
  max-width: 43px;
`;

const Units = styled.sup`
  color: ${(props) => props.theme.layout.textColor};
  font-size: 12px;
  align-self: right;
`;

export const DashboardChartTitle = ({
  chartTypes,
  currentType,
  children,
  className,
  namespace,
  onToggle,
  title,
  metadata,
  showToggle,
}) => {
  const { ready, t, i18n } = useTranslation([namespace], {
    useSuspense: false,
  });

  const translations = useMemo(
    () => ({
      title: t(title),
    }),
    [t, title],
  );

  if (!ready) {
    return null;
  }

  return (
    <>
      <Title className={className}>
        {children || (title ? <h3>{translations.title}</h3> : <div />)}
        <TitleContainer>
          {typeof metadata?.total != 'undefined' && (
            <TotalContainer data-testid={AnalyzeLocators.ANALYZE_IEC_GRAPH_TOTAL_WRAP}>
              {metadata.isAverage ? (
                <AverageText>{t('conditions.average', 'Average Value')}</AverageText>
              ) : (
                <TotalText>{t('kpi.total_value', 'Total Value')}</TotalText>
              )}
              <Total>
                {formatKPIValue(metadata.total, i18n.language)}
                <Units>{metadata.unit}</Units>
              </Total>
            </TotalContainer>
          )}

          {chartTypes?.length && showToggle ? (
            <DashboardChartToggle
              currentType={currentType}
              onToggle={onToggle}
              types={chartTypes}
            />
          ) : null}
        </TitleContainer>
      </Title>
    </>
  );
};

DashboardChartTitle.propTypes = {
  chartTypes: PropTypes.arrayOf(chartIconPropType),
  currentType: chartIconPropType,
  children: PropTypes.node,
  className: PropTypes.string,
  namespace: PropTypes.string,
  onToggle: PropTypes.func,
  title: PropTypes.string,
  metadata: PropTypes.instanceOf(Object),
  currentChart: PropTypes.string,
  showToggle: PropTypes.bool,
};

DashboardChartTitle.defaultProps = {
  chartTypes: null,
  currentType: undefined,
  children: null,
  className: null,
  namespace: NAMESPACE,
  onToggle: () => {},
  selectedChartIndex: undefined,
  title: null,
  currentChart: null,
  showToggle: true,
};
