import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const StyledWrapper = styled.div`
  background: ${(props) => props.theme.siteProductionBar.backgroundColor};
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.34);
  width: 64px;
  padding: 2px;
  border-radius: 3px;
  height: 16px;
  display: block;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  box-sizing: border-box;
`;

const PercentageFill = styled.div`
  width: calc(100% - 4px);
  background: ${(props) => {
    if (props.color)
      return `linear-gradient(to right, ${props.color} ${props.percent}%, transparent  0)`;
    return `linear-gradient(to right, ${props.theme.siteProductionBar.fillColor} ${props.percent}%, transparent  0)`;
  }};
  height: 13px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 1px;
`;

const Seperator = styled.div`
  background: ${(props) => props.theme.siteProductionBar.spacerColor};
  width: 1px;
  height: 13px;
  z-index: ${elevations.P1};
`;

export const HorizontalQuarterChart = ({ percent, color }) => (
  <StyledWrapper>
    <Seperator />
    <Seperator />
    <Seperator />
    <PercentageFill percent={percent} color={color} />
  </StyledWrapper>
);

HorizontalQuarterChart.propTypes = {
  percent: PropTypes.number,
  color: PropTypes.string,
};

HorizontalQuarterChart.defaultProps = {
  percent: 0,
  color: globalColors.black,
};
