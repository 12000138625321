import { keyframes } from 'styled-components';

export const fadeOutLeft = keyframes`
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: -100%;
  }
`;

export const fadeOutRight = keyframes`
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
`;

export const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

export const fadeInRight = keyframes`
  0% {
    opacity: 0%;
    left: -100%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;
