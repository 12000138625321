import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

import { TenantContext } from '../context/tenant-context';

const Chevron = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.admin.people.backToGlobalButtonColor,
}))`
  margin: 5px;
  transform: rotate(90deg);
`;

const BackButton = styled.button`
  margin-top: 50px;
`;

const BackButtonText = styled.span`
  color: ${(props) => props.theme.admin.people.backToGlobalButtonColor};
  vertical-align: middle;
`;

const BackToGlobalTablesButton = () => {
  const { t } = useTranslation(['admin.people', 'general'], { useSuspense: false });

  const {
    tenantState: { clearSelectedTenant },
  } = useContext(TenantContext);

  return (
    <BackButton onClick={clearSelectedTenant}>
      <Chevron />
      <BackButtonText>{t('backToGlobal', 'All Tenants & Customers')}</BackButtonText>
    </BackButton>
  );
};

export default BackToGlobalTablesButton;
