/**
 * Mock data for Roles
 */
const usePermissions = (role) => {
  const permissions = {
    new_role: [
      {
        segmentOffering: 'global',
        defaultTranslation: 'Global',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
        capabilityGroups: [
          {
            name: 'common',
            defaultTranslation: 'Common',
            capabilities: [
              {
                name: 'notes_attachments',
                defaultTranslation: 'Notes and Attachments',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: true,
                  },
                  edit: {
                    readOnly: false,
                    checked: true,
                  },
                  create: {
                    readOnly: false,
                    checked: true,
                  },
                  delete: {
                    readOnly: false,
                    checked: true,
                  },
                  administer: {
                    readOnly: false,
                    checked: false,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        segmentOffering: 'manage',
        defaultTranslation: 'Manage',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
        capabilityGroups: [
          {
            name: 'work_planning',
            defaultTranslation: 'Work Planning',
            capabilities: [
              {
                name: 'field_tasks',
                defaultTranslation: 'Field Tasks',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: false,
                    checked: false,
                  },
                },
              },
              {
                name: 'work_plan',
                defaultTranslation: 'Work Plan',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: false,
                    checked: false,
                  },
                },
              },
              {
                name: 'personnel_management',
                defaultTranslation: 'Personnel Management',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: false,
                    checked: false,
                  },
                },
              },
              {
                name: 'mobile_assignments',
                defaultTranslation: 'Mobile Assignments',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: false,
                    checked: false,
                  },
                },
              },
            ],
          },
          {
            name: 'erp_integrations',
            defaultTranslation: 'ERP Integrations',
            capabilities: [
              {
                name: 'work_orders',
                defaultTranslation: 'Work Orders',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: false,
                    checked: false,
                  },
                },
              },
            ],
          },
        ],
      },
    ],
    technician: [
      {
        segmentOffering: 'global',
        defaultTranslation: 'Global',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
        capabilityGroups: [
          {
            name: 'common',
            defaultTranslation: 'Common',
            capabilities: [
              {
                name: 'notes_attachments',
                defaultTranslation: 'Notes and Attachments',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: true,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: true,
                    checked: false,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        segmentOffering: 'manage',
        defaultTranslation: 'Manage',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
        capabilityGroups: [
          {
            name: 'work_planning',
            defaultTranslation: 'Work Planning',
            capabilities: [
              {
                name: 'field_tasks',
                defaultTranslation: 'Field Tasks',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: true,
                    checked: false,
                  },
                },
              },
              {
                name: 'work_plan',
                defaultTranslation: 'Work Plan',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: true,
                    checked: false,
                  },
                  create: {
                    readOnly: true,
                    checked: false,
                  },
                  delete: {
                    readOnly: true,
                    checked: false,
                  },
                  administer: {},
                },
              },
              {
                name: 'personnel_management',
                defaultTranslation: 'Personnel Management',
                permissions: {
                  view: {
                    readOnly: true,
                    checked: false,
                  },
                  edit: {
                    readOnly: true,
                    checked: false,
                  },
                  create: {
                    readOnly: true,
                    checked: false,
                  },
                  delete: {
                    readOnly: true,
                    checked: false,
                  },
                  administer: {},
                },
              },
              {
                name: 'mobile_assignments',
                defaultTranslation: 'Mobile Assignments',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: true,
                    checked: false,
                  },
                },
              },
            ],
          },
          {
            name: 'erp_integrations',
            defaultTranslation: 'ERP Integrations',
            capabilities: [
              {
                name: 'work_orders',
                defaultTranslation: 'Work Orders',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {},
                },
              },
            ],
          },
        ],
      },
    ],
    technician_viewer: [
      {
        segmentOffering: 'manage',
        defaultTranslation: 'Global',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
        capabilityGroups: [
          {
            name: 'work_planning',
            defaultTranslation: 'Work Planning',
            capabilities: [
              {
                name: 'field_tasks',
                defaultTranslation: 'Field Tasks',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: true,
                    checked: false,
                  },
                },
              },
              {
                name: 'work_plan',
                defaultTranslation: 'Work Plan',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: true,
                    checked: false,
                  },
                  create: {
                    readOnly: true,
                    checked: false,
                  },
                  delete: {
                    readOnly: true,
                    checked: false,
                  },
                  administer: {},
                },
              },
              {
                name: 'personnel_management',
                defaultTranslation: 'Personnel Management',
                permissions: {
                  view: {
                    readOnly: true,
                    checked: false,
                  },
                  edit: {
                    readOnly: true,
                    checked: false,
                  },
                  create: {
                    readOnly: true,
                    checked: false,
                  },
                  delete: {
                    readOnly: true,
                    checked: false,
                  },
                  administer: {},
                },
              },
              {
                name: 'mobile_assignments',
                defaultTranslation: 'Mobile Assignments',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {
                    readOnly: true,
                    checked: false,
                  },
                },
              },
            ],
          },
          {
            name: 'erp_integrations',
            defaultTranslation: 'ERP Integrations',
            capabilities: [
              {
                name: 'work_orders',
                defaultTranslation: 'Work Orders',
                permissions: {
                  view: {
                    readOnly: false,
                    checked: false,
                  },
                  edit: {
                    readOnly: false,
                    checked: false,
                  },
                  create: {
                    readOnly: false,
                    checked: false,
                  },
                  delete: {
                    readOnly: false,
                    checked: false,
                  },
                  administer: {},
                },
              },
            ],
          },
        ],
      },
    ],
  };

  return {
    data: permissions[role],
    isLoading: false,
  };
};

export default usePermissions;
