import { action, computed, thunk, persist } from 'easy-peasy';
import memoize from 'memoizerific';

import { fetchFeatureFlags } from '@ge/shared/services/common';

// Default state to apply on initialization or reset.
const defaultTenantState = {
  ...persist(
    { featureFlags: {} },
    {
      storage: 'localStorage',
    },
  ),
};

const tenantActions = {
  // eslint-disable-next-line no-unused-vars
  resetTenant: action((state) => {
    state = Object.assign(state, defaultTenantState);
  }),

  /**
   * Set the feature flags state.
   */
  setFeatureFlags: action((state, payload) => {
    state.featureFlags = payload;
  }),

  /**
   * Retrieve feature flags for tenantId from the API and update state.
   */
  fetchFeatureFlags: thunk(async (actions, _, { fail }) => {
    try {
      const {
        approvalFlag,
        futureFlag,
        massSRFlag,
        srNewFieldsFlag,
        ...rest
      } = await fetchFeatureFlags();
      actions.setFeatureFlags({
        approvalFlag: Boolean(approvalFlag),
        futureFlag: Boolean(futureFlag),
        massSRFlag: Boolean(massSRFlag),
        srNewFieldsFlag: Boolean(srNewFieldsFlag),
        ...rest,
      });
    } catch (err) {
      fail(err);
    }
  }),
};

// Computed values
const tenantComputed = {
  getApprovalFlag: computed([(state) => state.featureFlags], (featureFlags) =>
    memoize(2)(() => {
      return featureFlags && featureFlags.approvalFlag ? true : false;
    }),
  ),
};

export default {
  ...defaultTenantState,
  ...tenantActions,
  tenantComputed,
};
