import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

import { Loader } from '@ge/components/loader';
import { TaskStatus } from '@ge/models';
import {
  EntityType,
  ErpStatusFlag,
  ErpStatusIconMap,
  WorkScopes,
  ErpSource,
  Tenant,
} from '@ge/models/constants';

import { Icon, Icons } from '../icon';
import { Tooltip, placements } from '../tooltip';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledImage = styled.img`
  cursor: pointer;
  ${(props) =>
    props.rotateIcon &&
    css`
      animation: ${rotation} 2s linear infinite;
    `}
`;

const DefaultERPStatusIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.DEFAULT_ERP,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  padding-right: 2px;
`;

const LoaderContainer = styled.div`
  position: relative;
  height: ${(props) => props.size};
  width: ${(props) => props.size};

  // overriding the loader styles so it shows correctly
  img {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline;
    left: 0;
    top: 0;
  }
`;

export const ErpStatusIcon = ({
  isLoading,
  task,
  erpStatus,
  tooltipCustomClass,
  tooltipZIndex,
  width = '12px',
  backgroundOverride,
  onClick,
  className,
  hideDefaultIcon,
}) => {
  const { t } = useTranslation(['manage.erp-statuses']);

  const { sites } = useStoreState((store) => store.sites);
  const siteId = task.asset?.site?.id ?? task.site?.id;

  const isBopSiteTask = () => sites[siteId]?.aliases?.some((alias) => alias.key === 'racesBopId');
  const tenantId = sessionStorage.getItem('tenantId');
  const erpSource = tenantId.startsWith(Tenant.DTE) ? ErpSource.DTE_MAXIMO : ErpSource.RACES;

  const title = useMemo(() => {
    if (!erpStatus)
      return erpSource === ErpSource.DTE_MAXIMO
        ? `${t('work_order', 'Work Order')}`
        : `${t('service_request', 'Service Req.')}`;
    const { id, status, subStatus } = erpStatus ?? {};

    const erpId = id ? `${id} | ` : '';
    const erpSubStatus = subStatus.consolidated
      ? ` | ${t(`subStatus.${subStatus.consolidated}`, '')}`
      : '';

    return `${erpId}${t(`status.${status}`, '')}${erpSubStatus}`;
  }, [erpStatus, t, erpSource]);

  if (isLoading) {
    return (
      <Tooltip
        title={t('loading', 'Loading...')}
        placement={placements.TOP}
        zIndex={tooltipZIndex}
        customClass={tooltipCustomClass}
      >
        <LoaderContainer size={width}>
          <Loader />
        </LoaderContainer>
      </Tooltip>
    );
  }

  const getIconImage = () => {
    const backgroundStatus = backgroundOverride || task.status;
    const erpIconId = erpStatus?.iconId;
    if (
      task.taskLevel === EntityType.ASSET ||
      (task.taskLevel === EntityType.SITE && isBopSiteTask() && task.workScope === WorkScopes.BOP)
    ) {
      if (backgroundStatus && erpIconId) {
        return (
          <StyledImage
            src={require(`./icons/${backgroundStatus}/${erpIconId}-${backgroundStatus}.svg`)}
            width={width}
            rotateIcon={erpIconId === ErpStatusIconMap[ErpStatusFlag.SR_CREATION_INP]} // gear icon
            onClick={onClick}
            className={className}
            alt={title || 'erp status'}
          />
        );
      }

      if (!hideDefaultIcon) {
        return <DefaultERPStatusIcon className={className} onClick={onClick} />;
      }
    }

    return null;
  };

  const StatusIcon = () => {
    const image = getIconImage();
    if (!image) {
      return null;
    }

    return (
      <Tooltip
        title={title}
        placement={placements.TOP}
        zIndex={tooltipZIndex}
        customClass={tooltipCustomClass}
      >
        <span>{image}</span>
      </Tooltip>
    );
  };

  return <StatusIcon />;
};

ErpStatusIcon.propTypes = {
  isLoading: PropTypes.bool,
  task: PropTypes.object.isRequired,
  erpStatus: PropTypes.object,
  tooltipCustomClass: PropTypes.string,
  tooltipZIndex: PropTypes.number,
  width: PropTypes.string,
  // this will include a variant of "in progress" eventually
  backgroundOverride: PropTypes.oneOf([
    TaskStatus.SCHEDULED,
    TaskStatus.UNSCHEDULED,
    TaskStatus.COMPLETE,
  ]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  hideDefaultIcon: PropTypes.bool,
};

ErpStatusIcon.defaultProps = {
  isLoading: false,
  erpStatus: null,
};
