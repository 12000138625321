import { PropTypes } from 'prop-types';
import React, { Suspense, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import loadingIcon from './loader.svg';

const flashing = ([color, alternateColor]) => keyframes`
0% {
  background-color: ${color}
}
50%,
100% {
  background-color: ${alternateColor}
}
`;

const DotsContainer = styled.div`
  span {
    margin: ${({ margin }) => margin};
    display: inline-block;
    top: 0;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-radius: 100%;
    background-color: ${({ theme }) => theme.table.dataTextColor}
    animation: ${({ theme }) =>
      flashing([theme.table.dataTextColor, theme.table.dataTextColorLight])} 1s infinite alternate;
    animation-delay: 1s;
    &:nth-child(2n) {   
      animation: ${({ theme }) =>
        flashing([
          theme.table.dataTextColor,
          theme.table.dataTextColorLight,
        ])} 1s infinite linear alternate;
      animation-delay: 0.5s;
    }
  }
`;

const LoadContainer = styled.div`
  background: ${(props) => (!props.color ? props.theme.backgroundColor : props.color)};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const NonAbsoluteContainer = styled.div`
  background: ${(props) => (!props.color ? props.theme.backgroundColor : props.color)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LargeIcon = styled.img`
  display: block;
  height: 200px;
  left: calc(50% - 100px);
  position: absolute;
  top: calc(50% - 100px);
  width: 200px;
`;

const SmallIcon = styled.img`
  display: block;
  height: 100px;
  left: calc(50% - 50px);
  position: absolute;
  top: calc(50% - 50px);
  width: 100px;
`;

const MiniIcon = styled.img`
  display: block;
  height: 70px;
  left: calc(50% - 35px);
  position: absolute;
  top: calc(50% - 35px);
  width: 70px;
`;

const SpinIcon = styled.img`
  display: block;
  height: 45px;
  position: absolute;
  left: calc(55% - 65px);
  margin-top: -9px;
  width: 60px;
`;

export const FullLoader = ({ color, className }) => (
  <LoadContainer color={color} className={className}>
    <LargeIcon src={loadingIcon} alt="theme icon" />
  </LoadContainer>
);

FullLoader.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FullLoader.defaultProps = {
  color: 'transparent',
  className: '',
};

export const Loader = ({ color }) => (
  <LoadContainer color={color}>
    <SmallIcon src={loadingIcon} alt="theme icon" />
  </LoadContainer>
);

Loader.propTypes = {
  color: PropTypes.string,
};

Loader.defaultProps = {
  color: 'transparent',
};

export const NonAbsoluteLoader = ({ color }) => (
  <NonAbsoluteContainer Container color={color}>
    <SmallIcon src={loadingIcon} alt="theme icon" />
  </NonAbsoluteContainer>
);

NonAbsoluteLoader.propTypes = {
  color: PropTypes.string,
};

NonAbsoluteLoader.defaultProps = {
  color: 'transparent',
};

export const ComponentLoader = ({ color, className }) => (
  <LoadContainer color={color} className={className}>
    <LargeIcon src={loadingIcon} alt="Component Loader" />
  </LoadContainer>
);

ComponentLoader.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ComponentLoader.defaultProps = {
  color: 'transparent',
  className: 'ComponentLoader',
};

export const SpinLoader = ({ showLoader, className }) => {
  return showLoader && <SpinIcon src={loadingIcon} className={className} alt="theme icon" />;
};

SpinLoader.propTypes = {
  showLoader: PropTypes.bool,
  className: PropTypes.string,
};
SpinLoader.defaultProps = {
  showLoader: false,
  className: '',
};
export const MiniLoader = ({ color, className }) => (
  <LoadContainer color={color} className={className}>
    <MiniIcon src={loadingIcon} alt="theme icon" />
  </LoadContainer>
);

MiniLoader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

MiniLoader.defaultProps = {
  className: '',
  color: '',
};

export const WiseLoader = ({ delay, children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(() => setReady(true), delay);
  }, [delay]);

  return ready ? <Suspense fallback={<FullLoader />}>{children}</Suspense> : <FullLoader />;
};

WiseLoader.propTypes = {
  delay: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export const DotsLoader = ({ size, margin, title }) => {
  return (
    <DotsContainer size={size} margin={margin} title={title}>
      <span />
      <span />
      <span />
    </DotsContainer>
  );
};

DotsLoader.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
};

DotsLoader.defaultProps = {
  title: '',
  size: '5px',
  margin: '2px',
};
