import React from 'react';
import styled from 'styled-components';

import { ribbonStatePropType } from '@ge/feat-monitor/models';

import NavItem from './nav-item';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
`;

const Nav = ({ selected, filters, updateFilter }) => {
  return (
    <Container>
      {filters?.map(({ isLoading, id, label, i18nKey, count }) => (
        <NavItem
          key={id}
          isLoading={isLoading}
          id={id}
          label={label}
          i18nKey={i18nKey}
          count={count}
          active={selected?.id === id}
          onSelect={updateFilter}
        />
      ))}
    </Container>
  );
};

Nav.propTypes = ribbonStatePropType;

Nav.defaultProps = {
  selected: null,
  filters: [],
  updateFilter: () => {},
};

Nav.displayName = 'RibbonFilterNav';

export default Nav;
