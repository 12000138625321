import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DynamicTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AdminDefs } from '@ge/models/constants';
import { useFilterDefs } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { withDefault } from '@ge/shared/util/table-utils';
import { getDuration } from '@ge/shared/util/time-date';

import { TenantContext } from '../../context/tenant-context';
import logo from '../../images/logo.svg';
import { PeopleColumnDefs, PeopleColumns, globalPeopleCols } from '../../models/people-table-cols';

import PeopleTableRoleCell from './people-table-role-cell';

const StyledDisplay = styled.span`
  color: ${(props) => props.theme.table.columnTextColor};
`;

const StyledName = styled.span`
  display: flex;
  font-size: 14px;
  padding: 5px;
`;

const StyledTenant = styled.span`
  align-items: center;
  display: flex;
  font-size: 14px;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.table.personIconColor,
  rotate: -90,
}))`
  margin-left: 10px;
`;

const CustomerIcon = styled(Icon).attrs((props) => ({
  size: 18,
  icon: Icons.FLEET,
  color: props.theme.table.personIconColor,
}))`
  margin-right: 10px;
`;

const GeIcon = styled.img.attrs(() => ({
  src: logo,
}))`
  height: 18px;
  margin-right: 10px;
  width: 18px;
`;

const PersonIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.PERSON,
  color: props.theme.table.personIconColor,
}))`
  margin-right: 10px;
`;

const ThGroup = styled.span`
  height: 1px;
`;

const PeopleTable = ({
  people,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onPersonSelect,
  scrollable,
  className,
  onFilter,
  onFilterChange,
  filterValues,
}) => {
  const { t, ready } = useTranslation(['admin.people']);

  const {
    tenantState: { setSelectedTenant },
  } = useContext(TenantContext);

  const cellValues = {
    GE: 'geservgEuc',
  };

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const { filterDefs, onChange: onFilterApply } = useFilterDefs({
    columnDefs: PeopleColumnDefs,
    stateId: AdminDefs.PEOPLE_FILTER_STATE_ID,
  });

  useEffect(() => {
    onFilter(filterDefs);
  }, [filterDefs, onFilter]);

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilterApply(columnKey, value);
    },
    [onFilterApply],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const currentTime = new Date().getTime();

  const cellValueMapFn = useCallback(
    (person) => {
      if (!person) {
        return {};
      }

      const { tenant, name, roles, userId, status, lastLogin, email } = person;

      return {
        [PeopleColumns.TENANT]: { name: tenant?.name, id: tenant?.id } || {},
        [PeopleColumns.NAME]: name,
        [PeopleColumns.ROLES]: roles,
        [PeopleColumns.USER_ID]: userId,
        [PeopleColumns.STATUS]: status,
        [PeopleColumns.LAST_LOGIN]: getDuration(lastLogin, dayjs(currentTime)).formatted,
        [PeopleColumns.EMAIL]: withDefault(email),
      };
    },
    [currentTime],
  );

  // TODO: figure out why this isnt rendering in the column header
  const customHeaderFn = useCallback((columnKey) => {
    switch (columnKey) {
      case PeopleColumns.GROUP_TENANT:
      case PeopleColumns.GROUP_NAME:
      case PeopleColumns.GROUP_ROLES:
      case PeopleColumns.GROUP_USER_ID:
      case PeopleColumns.GROUP_STATUS:
      case PeopleColumns.GROUP_LAST_LOGIN:
      case PeopleColumns.GROUP_EMAIL:
        return <ThGroup />;
      default:
        return null;
    }
  }, []);

  const customCellFn = (columnKey, cellValue) => {
    switch (columnKey) {
      case PeopleColumns.TENANT:
        return withDefault(
          cellValue,
          <StyledTenant
            onClick={(e) => {
              killEventPropagation(e);
              e.preventDefault();
              setSelectedTenant(cellValue);
            }}
          >
            {cellValue?.id === cellValues.GE ? <GeIcon /> : <CustomerIcon />}
            {cellValue?.name}
          </StyledTenant>,
        );
      case PeopleColumns.NAME:
        return withDefault(
          cellValue,
          <StyledName>
            <PersonIcon />
            {cellValue}
            <ChevronIcon />
          </StyledName>,
        );
      case PeopleColumns.ROLES:
        return withDefault(cellValue, <PeopleTableRoleCell roles={cellValue} />);
      case PeopleColumns.USER_ID:
      case PeopleColumns.STATUS:
      case PeopleColumns.LAST_LOGIN:
        return withDefault(cellValue, <StyledDisplay>{cellValue}</StyledDisplay>);
      default:
        return null;
    }
  };

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: PeopleColumnDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    filterValues,
    filters: filterDefs,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
  });

  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: people,
    onValueSelect: onPersonSelect,
    rowKeyProperty: 'userId',
  };

  if (!ready) {
    return <Loader />;
  }

  const tableEl = React.createElement(DynamicTable, dynamicProps);

  return <PageContainer i18nReady={ready}>{tableEl}</PageContainer>;
};

PeopleTable.propTypes = {
  people: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onPersonSelect: PropTypes.func,
  handleScroll: PropTypes.func,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
};

PeopleTable.defaultProps = {
  columns: globalPeopleCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onPersonSelect: () => null,
  scrollable: true,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
};

export default PeopleTable;
