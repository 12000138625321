import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import { useStoreState } from 'easy-peasy';
import isNil from 'ramda/src/isNil';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useAllCrews } from '@ge/feat-manage/data-hooks/use-all-crews';
import { useErpRequestStatus } from '@ge/feat-manage/data-hooks/use-erp-request-status';
import { useWorkers } from '@ge/feat-manage/data-hooks/use-workers';
import { useTaskAssetState } from '@ge/feat-reporting/data-hooks/use-task-asset-state';
import { useTaskNotes } from '@ge/feat-reporting/data-hooks/use-task-notes';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { TaskStatus } from '@ge/models';
import { QueryKey, TaskDateKey } from '@ge/models/constants';
import { DateTimeFormats } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { taskPage } from '@ge/shared/services/task-page';
import { getDateTz } from '@ge/shared/util/time-date';

dayjs.extend(isBetween);
dayjs.extend(timezone);

const TaskStatusDateKeyMap = {
  [TaskStatus.COMPLETE]: [TaskDateKey.COMPLETED_DATE_TIME, TaskDateKey.COMPLETED_DATE_TIME_TZ],
  [TaskStatus.SCHEDULED]: [TaskDateKey.SCHEDULE_DATE, TaskDateKey.SCHEDULE_DATE_TZ],
};

const getUtcDateArray = (date) => {
  const isoDate = date?.toISOString();

  const match = isoDate?.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?Z$/);

  if (!match) {
    return null;
  }

  const [, year, month, day, hours, minutes, seconds, milliseconds] = match;

  return [
    Number(year),
    Number(month) - 1, // to month index
    Number(day),
    Number(hours),
    Number(minutes),
    Number(seconds),
    Number(milliseconds),
  ];
};

const isTaskInScopeFn = ({ endDate, startDate, status, timezone }) => {
  // can review this but it took some massaging to remove local datetime artifacts
  const endDateTz = getDateTz(endDate, timezone).endOf('d');
  const endDateTokens = getUtcDateArray(endDateTz);
  const endDateUtcTimestamp = Date.UTC(...endDateTokens);
  const startDateTz = getDateTz(startDate, timezone).startOf('d');
  const startDateTokens = getUtcDateArray(startDateTz);
  const startDateUtcTimestamp = Date.UTC(...startDateTokens);
  const SCHEDULED = TaskStatus.SCHEDULED;

  // used for simple optimization to avoid crunching the same conversions/comparisons over and over
  const compareCache = {};

  return (task) => {
    const { status: taskStatus } = task;

    if (status === SCHEDULED) {
      return true;
    } else if (taskStatus !== status) {
      return false;
    }
    const [compareDateKey] = TaskStatusDateKeyMap[status] ?? [];

    // how do we want to handle this?  "shoudln't" ever hit this scenario...
    if (!compareDateKey) {
      return false;
    }

    const compareDate = task[compareDateKey];

    if (!compareDate) {
      return false;
    }

    let compare = compareCache[compareDate];

    if (typeof compare === 'boolean') {
      return compare;
    }

    const compareDateTokens = getUtcDateArray(dayjs(compareDate));
    const compareDateUtcTimestamp = Date.UTC(...compareDateTokens);

    compare =
      startDateUtcTimestamp <= compareDateUtcTimestamp &&
      compareDateUtcTimestamp <= endDateUtcTimestamp;

    compareCache[compareDate] = compare;

    return compare;
  };
};

const partTransformer = ({ name: description, number: partNumber, quantity: qty }) => ({
  description,
  partNumber,
  qty,
});

export const useTaskWorkData = ({ config = Config.EXECUTE_ONCE, notesScope, queryKey, status }) => {
  // store
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);
  const SCHEDULED = TaskStatus.SCHEDULED;
  const UNSCHEDULED = TaskStatus.UNSCHEDULED;

  const { endDate, serviceGroupIds, siteIds, startDate, timezone } = useReportScope();
  const { data: allCrews, isFetching: isAllCrewsLoading } = useAllCrews(
    startDate,
    endDate,
    serviceGroupIds,
    config,
    queryKey,
  );
  const { data: workers, isFetching: isWorkersLoading } = useWorkers(
    startDate,
    endDate,
    serviceGroupIds,
    config,
    queryKey,
  );

  const {
    data: _tasks,
    error,
    isLoading: isTasksLoading,
  } = useQuery(
    [QueryKey.TASKS, endDate, queryKey, siteIds, startDate, timezone, status],
    async () => {
      const { tasks } = await taskPage({
        endDate: dayjs(endDate).format(DateTimeFormats.ENDPOINT_PARAM),
        siteIds,
        startDate: dayjs(startDate).format(DateTimeFormats.ENDPOINT_PARAM),
        getBundledTasks: status === SCHEDULED ? true : false,
        taskStatus: status === SCHEDULED ? [status, UNSCHEDULED] : [status],
      });
      return tasks;
    },
    config,
  );

  const tasks = useMemo(() => {
    const isTaskInScope = isTaskInScopeFn({ endDate, startDate, status, timezone });
    return _tasks?.filter(isTaskInScope) ?? [];
  }, [endDate, startDate, status, _tasks, timezone]);

  const { data: erpRequestStatus, isLoading: isErpRequestStatusLoading } = useErpRequestStatus(
    {
      taskIds: tasks?.map(({ id }) => id),
    },
    config,
    queryKey,
  );
  const { data: assetState, isLoading: isAssetStateLoading } = useTaskAssetState({
    config,
    queryKey,
    tasks,
  });
  const { data: taskNotes, isLoading: isNotesLoading } = useTaskNotes({ config, queryKey, tasks });

  //Planned task assets data allocation for Sites Roster
  if (status == SCHEDULED) {
    allCrews?.filter((crew) => {
      const assetDataMap = [];
      let taskCrews = [];
      tasks?.map((task) => {
        if (task?.crewIds?.length) {
          let assetdata;
          const _asset = getAssetById(task.asset?.id) ?? {};
          if (_asset?.id) {
            assetdata = assetdata ?? {};
            assetdata.id = _asset?.id;
            assetdata.name = _asset.name;
          }
          if (!task?.crewIds.includes(null) && task?.crewIds.includes(crew._id) === true) {
            if (assetdata?.name != undefined) {
              assetDataMap.push(assetdata);
            }
          }
          if (task?.bundledTasks?.length) {
            task?.bundledTasks?.map((id) => {
              const taskBundled = tasks?.find((task) => task?.id === id);
              taskCrews = taskBundled?.crewIds;
              if (taskCrews && taskCrews[0] && taskCrews.length) {
                task.crewIds?.map((val) => {
                  if (!taskCrews?.includes(val)) {
                    taskCrews.push(val);
                  }
                });
              } else if (taskBundled && task?.crewIds) {
                taskBundled.crewIds = [];
                task?.crewIds.map((crewId) => {
                  taskBundled.crewIds.push(crewId);
                });
              }
            });
          }
        }
      });
      if (assetDataMap?.length) {
        crew.assets = assetDataMap
          ?.map(({ name }) => name)
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    });
  }

  // static data not dependent on sidebar config
  const staticData = useMemo(() => {
    return tasks.reduce((mapped, _task) => {
      const {
        asset: taskAsset,
        completedDateTime: completedDate,
        component1,
        component2,
        component3,
        consumedParts,
        createDate: createdDate,
        crewIds,
        description,
        dueDate,
        eligibleStartDate,
        estDurationHours: estimatedDurationHours,
        estDurationMinutes: estimatedDurationMinutes,
        estTechs: estimatedNumberOfTechs,
        expectedParts,
        id,
        laborHours,
        laborMinutes,
        priority,
        resolutionNotes: resolution,
        scheduleDate,
        site,
        srNumber,
        rdspp,
        startedDateTime: startedDate,
        status,
        title,
        workScope: type, // is this right?
      } = _task;

      const task = {
        component:
          component1 || component2 || component3
            ? [component1, component2, component3].filter(Boolean)
            : undefined,
        description,
        id,
        priority,
        srNumber,
        rdspp,
        status,
        title,
        type,
      }; // being explicit about what we grab here but can loosen up as needed

      if (crewIds?.length) {
        const crewMemberIds = allCrews
          ?.filter(({ _id: id }) => crewIds.includes(id))
          .flatMap(({ members }) => members?.map(({ member_id }) => member_id));

        // if all crews data is missing, do we want to reflect that somehow (logging/display error)?
        if (crewMemberIds?.length) {
          task.assignedTechs = workers
            ?.filter(({ username }) => crewMemberIds.includes(username))
            .map(({ firstName, lastName }) => `${firstName} ${lastName}`);
        }
      }

      const taskErpRequestStatus = erpRequestStatus?.find(({ taskId }) => taskId === id);

      if (taskErpRequestStatus) {
        // do we care about substatus here?
        const { status: srStatus } = taskErpRequestStatus;

        task.srStatus = srStatus;
      }

      let asset;
      const _site = getSiteById(site?.id);

      if (_site?.name) {
        asset = { siteName: _site.name };
      }

      const _asset = getAssetById(taskAsset?.id) ?? {};

      if (_asset?.id) {
        const assetId = _asset.id;

        asset = asset ?? {};
        asset.id = assetId;
        asset.name = _asset.name;
        asset.state = assetState?.[assetId];
      }

      let dates;

      if (
        completedDate ||
        createdDate ||
        dueDate ||
        eligibleStartDate ||
        scheduleDate ||
        startedDate
      ) {
        const { timezone } = site ?? {};

        // display dates in site timezone if provided
        dates = {
          completedDate: getDateTz(completedDate, timezone),
          createdDate: getDateTz(createdDate, timezone),
          dueDate: getDateTz(dueDate, timezone),
          eligibleStartDate: getDateTz(eligibleStartDate, timezone),
          scheduleDate: getDateTz(scheduleDate, timezone),
          startedDate: getDateTz(startedDate, timezone),
        };
      }

      let estimates;

      if (
        !isNil(estimatedDurationHours) ||
        !isNil(estimatedDurationMinutes) ||
        !isNil(estimatedNumberOfTechs) ||
        !isNil(laborHours) ||
        !isNil(laborMinutes)
      ) {
        estimates = {
          estimatedDurationHours,
          estimatedDurationMinutes,
          estimatedNumberOfTechs,
          laborHours,
          laborMinutes,
        };
      }

      const notesForTask = taskNotes?.[id];
      let notes;

      if (resolution) {
        notes = { resolution };
      }

      if (notesForTask?.length) {
        notes = notes ?? {};
        // default to latest note ignoring scope
        notes.latest = notesForTask[0].note;
        notes.notes = notesForTask;
      }

      let parts;

      if (expectedParts?.length) {
        parts = {
          expected: expectedParts.map(partTransformer),
        };
      }

      if (consumedParts?.length) {
        parts = parts ?? {};
        parts.consumed = consumedParts.map(partTransformer);
      }

      mapped.push({ asset, dates, estimates, id, notes, parts, task });

      return mapped;
    }, []);
  }, [
    allCrews,
    assetState,
    erpRequestStatus,
    getAssetById,
    getSiteById,
    taskNotes,
    tasks,
    workers,
  ]);

  // dynamic data driven by sidebar config
  const dynamicData = useMemo(() => {
    if (!notesScope || notesScope.length !== 1) {
      return staticData;
    }

    return staticData?.map((row) => {
      if (row?.notes) {
        const { notes } = row.notes;

        if (notes?.length) {
          return {
            ...row,
            notes: {
              ...row.notes,
              latest: notes?.find(({ scope }) => scope === notesScope[0])?.note,
            },
          };
        }
      }

      return row;
    });
  }, [staticData, notesScope]);

  const isLoading =
    isAllCrewsLoading ||
    isAssetStateLoading ||
    isErpRequestStatusLoading ||
    isNotesLoading ||
    isTasksLoading ||
    isWorkersLoading;

  return { data: dynamicData, error, isLoading };
};
