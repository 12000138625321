import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import {
  DefaultRoleType,
  HistoryActionStatus,
  QueryKey,
  UserAuditHistory,
} from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import * as request from '@ge/shared/services/api';
import { sorter } from '@ge/util/metric-sorter';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchAuditHistoryByUsername = async (username) =>
  request.get(`/cmn/users/audit/${username}`, { baseURL: USERS_BASE_URL });

export const useFetchAuditHistory = ({
  filters,
  search,
  sortMetric,
  sortDirection,
  username,
  dateRangeValue,
}) => {
  let filteredAuditData = [];
  const {
    error,
    isError,
    status,
    refetch,
    isLoading: isLoadingAuditHistory,
    isFetching: isFetchingAuditHistory,
    data: fetchedAuditHistory,
  } = useQuery(
    [QueryKey.FETCH_AUDIT_HISTORY, { username }],
    () => fetchAuditHistoryByUsername(username),
    {
      refetchOnWindowFocus: false,
    },
  );
  filteredAuditData = useMemo(() => {
    return (
      fetchedAuditHistory
        ?.filter((item) => {
          let isItemWithInRange = false;
          if (dateRangeValue !== 0) {
            const actionTime = dayjs(item.actionTime);
            const currentDate = dayjs();
            let hours = currentDate.diff(actionTime, 'hours');
            const days = Math.floor(hours / 24);
            isItemWithInRange = days <= dateRangeValue;
          }
          return (
            item.role !== DefaultRoleType.MONITOR_ADMIN &&
            item.role !== DefaultRoleType.ANALYTICS_VIEW &&
            item.role !== DefaultRoleType.MONITOR_VIEW &&
            (item.action === HistoryActionStatus.INSERTED ||
              item.action === HistoryActionStatus.DELETED) &&
            (dateRangeValue === 0 || (dateRangeValue !== 0 && isItemWithInRange))
          );
        })
        ?.map((history, index) => {
          return {
            ...history,
            historyId: `${index}-${uuidv4()}`,
            action: UserAuditHistory[history.action],
            serviceGroup:
              history.serviceGroup && history.serviceGroup !== UserAuditHistory.NULL
                ? history.serviceGroup
                : '',
            actionTime: dayjs(history.actionTime),
            type:
              history.type === UserAuditHistory.User
                ? history.role?.replace(/[_-]/g, ' ')
                : history.type,
          };
        })
        ?.sort(sorter(sortMetric, sortDirection)) ?? []
    );
  }, [sortMetric, sortDirection, fetchedAuditHistory, dateRangeValue]);
  let data = useTableFilter({ data: filteredAuditData, filters, search });

  return {
    isLoading: isLoadingAuditHistory,
    isFetching: isFetchingAuditHistory,
    isError,
    status,
    error,
    refetch,
    data: data,
  };
};
