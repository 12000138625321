import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { elevations } from '@ge/tokens/elevations';

import { ResizableTableColumn } from './resizable-table-column';

const StyledTr = styled.tr`
  position: relative;
  z-index: ${elevations.P10};
`;

export const ResizableTableColumns = ({ cols, showColumnGroups, parentRef }) => {
  if (!parentRef?.current) return null;

  const height = parentRef.current.offsetHeight ?? 0;
  const headerHeight = parentRef.current.querySelector('thead')?.offsetHeight ?? 62;

  return (
    <StyledTr>
      {cols.map(
        ({ key, props: { className = '', fixedRight, columnKey, groupKey } }) =>
          !fixedRight && (
            <ResizableTableColumn
              key={key}
              parentRef={parentRef}
              height={height}
              headerHeight={headerHeight}
              showColumnGroups={showColumnGroups}
              className={className}
              columnKey={columnKey}
              groupKey={groupKey}
            />
          ),
      )}
    </StyledTr>
  );
};

ResizableTableColumns.propTypes = {
  cols: PropTypes.array,
  showColumnGroups: PropTypes.bool,
  parentRef: PropTypes.instanceOf(Object),
};

ResizableTableColumns.defaultProps = {
  cols: [],
  showColumnGroups: true,
  parentRef: null,
};
