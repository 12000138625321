const randomNumber = (min, max) => Math.random() * (max - min) + min;

export const generateWaveForm = (positiveDataPoints, negativeDataPoints) => {
  const twoPi = 2 * Math.PI;
  const k = twoPi / 2;
  const omega = twoPi * 10;

  const motionOscillator = (a, k, omega, t) => {
    return a * Math.cos(k * t - omega * t);
  };

  const negativeData = [...Array(negativeDataPoints)]
    .map((__, index) => {
      const a = randomNumber(0, 0.9);
      return [-index, motionOscillator(a, k, omega, -index)];
    })
    .reverse();

  const positiveData = [...Array(positiveDataPoints)].map((__, index) => {
    const a = randomNumber(0, 0.9);
    return [index * 2, motionOscillator(a, k, omega, index)];
  });

  return [...negativeData, ...positiveData];
};

export const generateVibration = (dataPoints) => {
  return [...Array(dataPoints)].map((__, index) => {
    index = index * 2;

    switch (index) {
      case 100:
      case 200:
      case 300:
        return { name: `1${index / 100}:00`, x: index, y: randomNumber(0, 90) };
      default:
        return { name: null, x: index, y: randomNumber(0, 90) };
    }
  });
};

export const generateSpectrum = (dataPoints) => {
  return [...Array(dataPoints)].map((__, index) => {
    if (index % 10 === 0) {
      return { x: index, y: randomNumber(0, 110) };
    } else {
      return { x: index, y: randomNumber(0, 30) };
    }
  });
};
