import { useMemo } from 'react';

import { CaseTemplateColumns } from '@ge/feat-analyze/models/case-template-table-cols';

export const useFilterTemplateData = ({ checkedRows = [], caseTemplateData = [] }) => {
  const checkedRowsData = useMemo(
    () =>
      caseTemplateData.filter((templateData) =>
        checkedRows.includes(templateData[CaseTemplateColumns.CASE_TEMPLATE_ID]),
      ),
    [caseTemplateData, checkedRows],
  );

  return {
    checkedRowsData,
  };
};
