import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { PersonCreateType, PersonType } from '@ge/models/constants';

import { FormLoading } from '../../entity-create/entity-create-shared';
import { DetailContainer, SectionContainer } from '../entity-details-shared';

import { useFetchPerson } from './hooks/use-fetch-person';
import { usePatchPerson } from './hooks/use-patch-person';
import { PersonProvider } from './person-context';
import { PersonDetailsContact } from './person-details-contact';
import PersonDetailsHeader from './person-details-header';
import { PersonDetailsUser } from './person-details-user';
import { PersonDetailsWorker } from './person-details-worker';

const LoadingWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 75vh;
`;

const PersonDetails = ({ personId }) => {
  const { t } = useTranslation(['entity-details']);
  const { data: fetchedPerson, isLoading: isLoadingPerson, status } = useFetchPerson(personId);
  const { setPersonInStore, setProvidedTab } = useStoreActions((actions) => actions.tabflow);
  const { providedTab } = useStoreState((state) => state.tabflow);
  const { limitedUserRoles: mappedLimitedRoles, isUserAttributesLoading } = useStoreState(
    (state) => state.userRole,
  );
  const [isEditMode, setIsEditMode] = useState(false);

  const [providedAttributes, setProvidedAttributes] = useState(null);
  const [removedRoles, setRemovedRoles] = useState([]);
  const [comparedRoles, setComparedRoles] = useState(0);
  const [roleStart, setRoleStart] = useState([]);
  let personType = null;

  if (
    fetchedPerson &&
    !fetchedPerson?.type.includes(PersonCreateType.WORKER) &&
    !fetchedPerson?.type.includes(PersonCreateType.USER)
  ) {
    personType = PersonCreateType.CONTACT;
  } else if (
    fetchedPerson &&
    fetchedPerson?.type.includes(PersonCreateType.WORKER) &&
    !fetchedPerson?.type.includes(PersonCreateType.USER)
  ) {
    personType = PersonCreateType.WORKER;
  } else if (
    (fetchedPerson &&
      !fetchedPerson?.type.includes(PersonCreateType.WORKER) &&
      fetchedPerson?.type.includes(PersonCreateType.USER)) ||
    (fetchedPerson &&
      fetchedPerson?.type.includes(PersonCreateType.WORKER) &&
      fetchedPerson?.type.includes(PersonCreateType.USER))
  ) {
    personType = PersonCreateType.USER;
  } else {
    personType = PersonCreateType.CONTACT;
  }

  const methods = useForm();

  useEffect(() => {
    let filteredAttributes = fetchedPerson?.attributes?.entity_group?.filter(
      (role) =>
        role.roleName !== 'monitoring-admin' &&
        role.roleName !== 'analytics-view' &&
        role.roleName !== 'monitoring-view' &&
        mappedLimitedRoles?.some((item) => item.a11yKey === role.roleName),
    );
    setProvidedAttributes(filteredAttributes);
    setRoleStart(filteredAttributes?.map((attribute) => attribute.roleName));
  }, [fetchedPerson, mappedLimitedRoles]);

  useEffect(() => {
    if (status === 'success') {
      setPersonInStore({ fetchedPerson, mappedLimitedRoles });
    }
  }, [setPersonInStore, fetchedPerson, mappedLimitedRoles, status]);

  const handleSuccess = useCallback(() => {
    setProvidedTab('details');
  }, [setProvidedTab]);

  const { patch, personPatching, isError } = usePatchPerson({
    onSuccess: handleSuccess,
  });

  // HANDLER FOR SAVE BUTTON IN ROLES_PERMISSIONS TAB
  const rolesSave = useCallback(() => {
    let username = fetchedPerson?.username;
    let body = removedRoles.map((role) => {
      return {
        path: '/attributes/entity_group',
        value: role,
        op: 'REMOVE',
      };
    });
    patch({ username, body });
  }, [patch, fetchedPerson, removedRoles]);

  // HANDLER THAT PASSES HANDLE_SUBMIT METHOD THROUGH CONTEXT TO ROLES COMPONENT
  const handleEditRolesSaveButton = useCallback(() => {
    return (
      <Button
        type="button"
        onClick={methods.handleSubmit(rolesSave)}
        disabled={comparedRoles === 0 ? true : false}
        primary
      >
        {t('general.save', 'Save')}
      </Button>
    );
  }, [comparedRoles, methods, rolesSave, t]);

  // RENDERS PANELS BY USER_TYPE
  const getPanelsByUserType = () => {
    if (isLoadingPerson || !personType) {
      return null;
    }

    switch (personType.toLowerCase()) {
      case PersonType.USER_AND_WORKER:
      case PersonType.USER:
        return <PersonDetailsUser person={fetchedPerson} entityTab={providedTab} />;
      case PersonType.WORKER:
        return <PersonDetailsWorker person={fetchedPerson} entityTab={providedTab} />;
      case PersonType.CONTACT:
      default:
        return <PersonDetailsContact person={fetchedPerson} entityTab={providedTab} />;
    }
  };

  return (
    <DetailContainer>
      <PersonProvider
        value={{
          providedAttributes,
          setProvidedAttributes,
          removedRoles,
          setRemovedRoles,
          fetchedPerson,
          providedTab,
          setProvidedTab,
          handleEditRolesSaveButton,
          roleStart,
          comparedRoles,
          setComparedRoles,
          personPatching,
          isEditMode,
          setIsEditMode,
          isError,
        }}
      >
        <PersonDetailsHeader person={fetchedPerson} />
        <FormProvider {...methods}>
          <ScrollingContainer autoHide={false}>
            <SectionContainer>
              {isLoadingPerson || !personType || isUserAttributesLoading ? (
                <LoadingWrapper>
                  <FormLoading>
                    <Loader />
                  </FormLoading>
                </LoadingWrapper>
              ) : (
                getPanelsByUserType()
              )}
            </SectionContainer>
          </ScrollingContainer>
        </FormProvider>
      </PersonProvider>
    </DetailContainer>
  );
};

PersonDetails.propTypes = {
  personId: PropTypes.string.isRequired,
  entityTab: PropTypes.string,
};

PersonDetails.defaultProps = {
  entityTab: null,
};

export default PersonDetails;
