import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { StyledLabel } from '@ge/components/input/input';
import { Text } from '@ge/components/typography';
import { DateTimeFormats } from '@ge/models/constants';
import { getDateObject } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';

import { PlanningContext } from '../../../context/planning-provider';
import { getTimeInMinutes } from '../month/month-util';

const SubHeaderMember = styled.div`
  color: 'white';
  padding: ${(props) => (props.expandWithError ? '10px' : '0px')};
`;

const DatesContainer = styled.div`
  display: flex;
  padding: 5px;
  .date-input {
    flex: 1;
    &:first-child {
      margin-right: 5px;
    }
  }
  .date-picker-crew input {
    width: 115px;
    .start-end-label {
      margin-bottom: 5px;
    }
  }
  .date-picker-member input {
    width: 95px;
    .start-end-label {
      margin-bottom: 5px;
    }
  }
`;

const AddTimeButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  text-transform: uppercase;
`;

const AddTimeIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-bottom: 8px;
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const DatePickerIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor,
  icon: Icons.CALENDAR,
}))`
  margin-left: -20px;
  margin-top: -2px;
`;

export const CrewMemberTimings = ({
  member,
  memIndex,
  handleRemove,
  onAddTimePeriodClick,
  onSubmit,
}) => {
  const {
    errors,
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useFormContext();
  const [isExpanded, setIsExpanded] = useState(true);
  const formatDate = (date) => (date ? dayjs(date).format(DateTimeFormats.NEW_TASK) : null);
  const { t } = useTranslation(['tasks', 'manage.cases-tasks', 'manage.planning'], {
    useSuspense: false,
  });
  const { planningState } = useContext(PlanningContext);
  const { setCrewEdited } = planningState;
  const handleReset = () => {
    handleExpanding(false);
    reset();
    setCrewEdited(false);
  };

  const memberTimingHeader = useMemo(() => {
    return (
      <SubHeaderMember expandWithError={true}>
        {t('crew.memberTimingHeader', 'START/END DATES AND TIME')}
      </SubHeaderMember>
    );
  }, [t]);

  const handleExpanding = (expanded) => {
    setIsExpanded(expanded);
  };

  return (
    <CollapsiblePanel
      onToggleExpand={(expanded) => handleExpanding(expanded)}
      expanded={isExpanded}
      headerContent={memberTimingHeader}
      plusIcon
      // enableToggle={expandWithError === true ? false : true}  : working on DE181047: expand issue
    >
      {member?.time_periods?.map((timeperiod, i) => (
        <div className="member-time-container" key={'time' + i}>
          <div style={{ flex: '1' }}>
            <StyledLabel>{t('member_start_date_time', 'Start Date & Time')}*</StyledLabel>
            <DatesContainer>
              <div className="date-input">
                <Controller
                  control={control}
                  name={`members.${member.id}.${i}.startDate`}
                  defaultValue={
                    timeperiod.startDate ? getDateObject(dayjs(timeperiod.startDate)) : null
                  }
                  rules={{
                    required: true,
                    validate: {
                      memberStartDateValid: (value) => {
                        if (dayjs(value).isBefore(dayjs(getValues('crewStartDate'), 'date'))) {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ onChange, value }) => (
                    <DatePicker
                      customInput={
                        <div className="date-picker-member">
                          <Input
                            onChange={() => null}
                            error={errors?.members?.[member.id]?.[i]?.startDate ? 'error' : null}
                            value={value ? formatDate(value) : ''}
                          />
                          <DatePickerIcon />
                        </div>
                      }
                      onChange={onChange}
                      selected={value ? new Date(value) : null}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
              <div className="date-input">
                <Controller
                  control={control}
                  name={`members.${member.id}.${i}.startTime`}
                  defaultValue={timeperiod.startTime}
                  rules={{
                    required: true,
                    validate: {
                      memberStartTimeValid: (value) => {
                        if (
                          getTimeInMinutes(value) <
                          getTimeInMinutes(getValues('crewShiftStartTime'))
                        ) {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ onChange, value }) => (
                    <div className="date-picker-member">
                      <Input
                        onChange={onChange}
                        value={value}
                        type="time"
                        error={errors?.members?.[member.id]?.[i]?.startTime ? 'error' : null}
                      />
                    </div>
                  )}
                />
              </div>
            </DatesContainer>
            <div>
              {errors?.members?.[member.id]?.[i]?.startTime?.type === 'memberStartTimeValid' && (
                <ErrorText>
                  {t(
                    'crew.memberStartTimeValid_error',
                    'Start time cannot be before crew start time',
                  )}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.startDate?.type === 'required' && (
                <ErrorText>
                  {t('crew.member_start_date_required_error', 'Start date required')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.startTime?.type === 'required' && (
                <ErrorText>
                  {t('crew.member_starttime_required_error', 'Start time required')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.startDate?.type === 'memberStartDateValid' && (
                <ErrorText>
                  {t(
                    'crew.member_start_date_valid_error',
                    'Start date cannot be before crew start date',
                  )}
                </ErrorText>
              )}
            </div>
            <StyledLabel>{t('crew.member_end_date_time', 'End Date & Time')}</StyledLabel>
            <DatesContainer>
              <div className="date-input">
                <Controller
                  control={control}
                  name={`members.${member.id}.${i}.endDate`}
                  defaultValue={
                    timeperiod.endDate ? getDateObject(dayjs(timeperiod.endDate)) : null
                  }
                  rules={{
                    required: getValues(`members.${member.id}.${i}.endTime`)
                      ? true
                      : member.time_periods.length > i + 1
                      ? true
                      : false,
                    validate: {
                      memberEndDateValid: (value) => {
                        if (dayjs(value).isAfter(dayjs(getValues('crewEndDate'), 'date'))) {
                          return false;
                        }
                      },
                      isAfterStartDate: (value) => {
                        if (
                          dayjs(value).isBefore(
                            dayjs(getValues(`members.${member.id}.${i}.startDate`), 'date'),
                          )
                        ) {
                          return false;
                        }
                      },
                    },
                  }}
                  render={({ onChange, value }) => (
                    <DatePicker
                      customInput={
                        <div className="date-picker-member">
                          <Input
                            onChange={() => null}
                            error={errors?.members?.[member.id]?.[i]?.endDate ? 'error' : null}
                            value={value ? formatDate(value) : ''}
                          />
                          <DatePickerIcon />
                        </div>
                      }
                      onChange={onChange}
                      selected={value ? new Date(value) : null}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
              <div className="date-input">
                <Controller
                  control={control}
                  name={`members.${member.id}.${i}.endTime`}
                  defaultValue={timeperiod.endTime}
                  rules={{
                    required: getValues(`members.${member.id}.${i}.endDate`)
                      ? true
                      : member.time_periods.length > i + 1
                      ? true
                      : false,
                    validate: {
                      memberEndTimeValid: (value) => {
                        if (
                          dayjs(getValues('crewEndDate'), 'date').isSame(
                            dayjs(getValues(`members.${member.id}.${i}.endDate`), 'date'),
                          )
                        ) {
                          if (
                            getTimeInMinutes(value) >
                            getTimeInMinutes(getValues('crewShiftEndTime'))
                          ) {
                            return false;
                          }
                        }
                      },
                      isAfterStartTime: (value) => {
                        if (
                          dayjs(getValues(`members.${member.id}.${i}.endDate`), 'date').isSame(
                            dayjs(getValues(`members.${member.id}.${i}.startDate`), 'date'),
                          )
                        ) {
                          if (
                            getTimeInMinutes(value) <
                            getTimeInMinutes(getValues(`members.${member.id}.${i}.startTime`))
                          ) {
                            return false;
                          }
                        }
                      },
                    },
                  }}
                  render={({ onChange, value }) => (
                    <div className="date-picker-member">
                      <Input
                        onChange={onChange}
                        value={value}
                        type="time"
                        error={errors?.members?.[member.id]?.[i]?.endTime ? 'error' : null}
                      />
                    </div>
                  )}
                />
              </div>
            </DatesContainer>
            <div>
              {errors?.members?.[member.id]?.[i]?.endTime?.type === 'memberEndTimeValid' && (
                <ErrorText>
                  {t('crew.memberEndTimeValid_error', 'End time cannot be after crew end time')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.endDate?.type === 'required' && (
                <ErrorText>
                  {t('crew.member_end_date_required_error', 'End date required')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.endTime?.type === 'required' && (
                <ErrorText>
                  {t('crew.member_end_time_required_error', 'End time required')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.endDate?.type === 'memberEndDateValid' && (
                <ErrorText>
                  {t('crew.member_end_date_valid_error', 'End date cannot be after crew end date')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.endDate?.type === 'isAfterStartDate' && (
                <ErrorText>
                  {t('crew.startdate_after_error', 'End date cannot be before start date')}
                </ErrorText>
              )}
              {errors?.members?.[member.id]?.[i]?.endTime?.type === 'isAfterStartTime' && (
                <ErrorText>
                  {t('crew.enddate_before_error', 'End time cannot be before start time')}
                </ErrorText>
              )}
            </div>
          </div>
          <div style={{ marginTop: '47px' }}>
            <button type="button" onClick={(e) => handleRemove(e, memIndex, i, member.id)}>
              <StyledIcon icon={Icons.TRASH} size={16} />
            </button>
          </div>
        </div>
      ))}
      <div className="new-time-period">
        <AddTimeButton type="button" onClick={(e) => onAddTimePeriodClick(e, member.username)}>
          <AddTimeIcon />
          {t('form.time_period', 'Time Period')}
        </AddTimeButton>
      </div>
      <div className="action-buttons">
        <Button className="cancel-button" onClick={() => handleReset()}>
          {t('general:cancel', 'Cancel')}
        </Button>
        <Button primary onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
          {t('general:save', 'Save')}
        </Button>
      </div>
    </CollapsiblePanel>
  );
};

CrewMemberTimings.propTypes = {
  member: PropTypes.object,
  memIndex: PropTypes.number,
  handleRemove: PropTypes.func,
  onAddTimePeriodClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

CrewMemberTimings.defaultProps = {
  member: {},
  memIndex: null,
  handleRemove: () => {},
  onAddTimePeriodClick: () => {},
  onSubmit: () => {},
};
