import dayjs from 'dayjs';
import toObject from 'dayjs/plugin/toObject';
import { useStoreState } from 'easy-peasy';
import { useState, useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import useSitesKpiData from '@ge/feat-analyze/data-hooks/use-sites-kpi-data';
import { ContractualAvailabilitySortMetric } from '@ge/feat-analyze/models';
import {
  DateTimeFormats,
  KpiCategoryDefs,
  QueryKey,
  SortDirection,
  SortValueType,
  TimeAggr,
} from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { ManualAdjustmentsColumns } from '@ge/shared/models/table-col-defs/manual-adjustments-table-cols';
import { sorter } from '@ge/util/metric-sorter';

import { maLegendValues } from '../models/manual-adjustment-legends';
import {
  approveMalEvent,
  createMalEvent,
  deleteMalEvent,
  fetchMalCategory,
  fetchMalEvents,
} from '../services';

dayjs.extend(toObject);

const categories = [KpiCategoryDefs.AVAILABILITY_CONTRACT];

const assetEventsSortFn = (a, b) =>
  a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0;

export const useContractualReportingEvents = ({
  siteId,
  sortDirection = SortDirection.ASC,
  sortMetric = ContractualAvailabilitySortMetric.PERFORMANCE,
  startDate,
  endDate,
}) => {
  const [assetEventsKind, setAssetEventsKind] = useState('');
  const { assetsBySiteId } = useStoreState((state) => state.assets);

  const {
    data: siteKpiData,
    error: siteKpiDataError,
    isLoading: isSiteKpiDataLoading,
  } = useSitesKpiData({
    categories,
    def: categories,
    endDate,
    siteIds: [siteId],
    startDate,
    timeAggr: TimeAggr.DAILY,
  });

  const {
    data: malEventsData,
    error: malEventsError,
    isLoading: isMalEventsLoading,
  } = useQuery(
    [QueryKey.CONTRACTUAL_REPORTING_MAL_EVENTS, siteId, { endDate, startDate }],
    // TODO: unmock this
    async () =>
      startDate !== null &&
      endDate !== null &&
      fetchMalEvents({
        assetType: 'Site',
        assetValues: [siteId],
        endDate,
        startDate,
      }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(siteId),
    },
  );

  const data = useMemo(() => {
    if (isMalEventsLoading || !malEventsData) return [];

    const assets = assetsBySiteId[siteId];
    const assetKpiData = siteKpiData?.[KpiCategoryDefs.AVAILABILITY_CONTRACT]?.entityData ?? [];
    setAssetEventsKind(malEventsData?.assetEventsKind);

    const assetEventsMapFn = (e) => {
      const def = maLegendValues[assetEventsKind]?.find((v) => v.category === e.eventCategory);
      const type = e.oemCategory ? 'IEC' : 'MAL';
      return {
        ...e,
        startTime: e.startTime * 1000,
        endTime: e.endTime * 1000,
        a11yKey: def?.a11yKey ?? e.eventCategory,
        color: def?.color ?? 'transparent',
        type,
      };
    };

    return assets.reduce((events, asset) => {
      const value = assetKpiData?.find((a) => a.entity.id === asset.id)?.value;

      const assetEvents =
        malEventsData.assetEvents?.find((v) => v.assetId === asset.id)?.events ?? [];
      const malEvents = malEventsData.malEvents?.find((v) => v.assetId === asset.id)?.events ?? [];

      if (!assetEvents?.length && !malEvents?.length) return events;

      events.push({
        asset,
        availability: {
          current: value,
          // TODO: unmock these
          // mtd: 88.06,
          // reference: 94.83,
        },
        assetEvents: assetEvents.map(assetEventsMapFn).sort(assetEventsSortFn),
        malEvents: malEvents.map(assetEventsMapFn).sort(assetEventsSortFn),
      });

      return events;
    }, []);
  }, [isMalEventsLoading, assetsBySiteId, malEventsData, siteId, siteKpiData, assetEventsKind]);

  const sortedData = useMemo(() => {
    // if shape of data changes, need to update these metrics accordingly
    let metric = 'availability.current';
    let type = SortValueType.NUMERIC;

    if (sortMetric === ContractualAvailabilitySortMetric.NAME) {
      metric = 'asset.name';
      type = SortValueType.ALPHANUMERIC;
    }

    return data.slice().sort(sorter(metric, sortDirection, type));
  }, [data, sortDirection, sortMetric]);

  return {
    data: sortedData,
    malEventsData,
    assetEventsKind,
    // how do we want to pass back errors?
    error: [siteKpiDataError, malEventsError].filter(Boolean),
    isLoading: isSiteKpiDataLoading || isMalEventsLoading,
  };
};

const GetMaleventRow = (eventData, siteId, timeZone) => {
  let malEventData = [];
  const { assetsBySiteId } = useStoreState((state) => state.assets);
  const assets = assetsBySiteId[siteId];
  let id = 0;
  eventData?.malEvents?.map((malEvent) => {
    malEvent.events.map((event) => {
      malEventData.push({
        [ManualAdjustmentsColumns.SELECTED]: {
          id,
          assetId: malEvent?.assetId,
          start: event?.startTime,
          stop: event?.endTime,
          malCategory: event?.malCategory,
          status: event?.status,
          dateSubmitted: event?.adjustedTime,
          day: malEvent?.day,
        },
        [ManualAdjustmentsColumns.ASSET]: assets.find((v) => v.id === malEvent?.assetId)?.name,
        [ManualAdjustmentsColumns.EVENT]: event?.malCategoryName,
        [ManualAdjustmentsColumns.START]: dayjs(event?.startTime * 1000)
          .tz(timeZone)
          .format(DateTimeFormats.ISSUE_DETAIL_DATE_TIME),
        [ManualAdjustmentsColumns.STOP]: dayjs(event?.endTime * 1000)
          .tz(timeZone)
          .format(DateTimeFormats.ISSUE_DETAIL_DATE_TIME),
        [ManualAdjustmentsColumns.DATE_SUBMITTED]: dayjs(event?.adjustedTime * 1000)
          .tz(timeZone)
          .format(DateTimeFormats.ISSUE_DETAIL_DATE_TIME),
        [ManualAdjustmentsColumns.STATUS]: event.status && event.status !== '' ? event.status : '-',
      });
      id += 1;
    });
  });
  return malEventData;
};

export const useContractualReportingMalTableData = ({
  malEventData,
  filterSequence,
  filters,
  sortMetric,
  sortDirection,
  siteId,
  timeZone,
}) => {
  const tableData = GetMaleventRow(malEventData, siteId, timeZone);
  return useTableFilter({
    data: tableData.sort(sorter(sortMetric, sortDirection)),
    filterSequence,
    filters,
    // search,
  });
};

export const useContractualReportingMalCategory = ({ siteId }) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.CONTRACTUAL_REPORTING_MAL_CATEGORY, siteId],
    async () => fetchMalCategory(siteId),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(siteId),
    },
  );

  return { data, error, isLoading };
};

export const useCreateMalEvent = ({ siteId, onSuccess }) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: create,
    isLoading,
    isError,
    error,
  } = useMutation(createMalEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CONTRACTUAL_REPORTING_MAL_EVENTS, siteId]);
      onSuccess();
    },
    onError: (error) => {
      console.log(`there has been an error ${error}`);
    },
  });

  return { create, isLoading, isError, error };
};

export const useDeleteMalEvent = ({ siteId, onSuccess }) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: deleteEvent,
    isLoading,
    isError,
    error,
  } = useMutation(deleteMalEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CONTRACTUAL_REPORTING_MAL_EVENTS, siteId]);
      onSuccess();
    },
    onError: (error) => {
      console.log(`there has been an error ${error}`);
    },
  });

  return { deleteEvent, isLoading, isError, error };
};

export const useApproveMalEvents = ({ siteId, onSuccess, onError }) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: approveEvent,
    isLoading,
    isError,
    error,
  } = useMutation(approveMalEvent, {
    onSuccess: (result, context) => {
      queryClient.invalidateQueries([QueryKey.CONTRACTUAL_REPORTING_MAL_EVENTS, siteId]);
      onSuccess(context);
    },
    onError: (error, context) => {
      onError(context, error);
      console.log(`there has been an error ${error}`);
    },
  });

  return { approveEvent, isLoading, isError, error };
};
