import * as request from './api';

export const getDistributionList = ({ emailType, entityType, entityId, assetType }) => {
  return request.get(`/cmn/distribution-list`, {
    params: {
      emailType,
      entityType,
      entityId,
      assetType,
    },
  });
};

export const getAllDistributionLists = () => request.get(`/cmn/all-distribution-list`);

export const createDistributionList = (body) => request.post(`/cmn/distribution-list`, body);

export const updateDistributionList = (data) =>
  request.patch(`/cmn/distribution-list/${data.id}`, data.body);

export const deleteDistributionList = (id) => request.deleteItem(`/cmn/distribution-list/${id}`);
