import { darken, transparentize } from 'polished';
import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css, withTheme } from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { placements, Tooltip } from '@ge/components/tooltip';
import { Placeholders } from '@ge/models/constants';
import { CommonLocators } from '@ge/models/data-locators';
import { elevations } from '@ge/tokens/elevations';

import { SortDirection } from './models/sort-direction';
import { useResizableContext } from './resizable-table-context';

export const noop = () => undefined;

export const EntityArrow = styled(Icon).attrs((props) => ({
  size: 20,
  icon: Icons.CHEVRON,
  color: props.theme.table.entityCell,
  rotate: -90,
}))``;

const StyledEntityCell = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    ${({ authorized, theme }) => {
      if (authorized) {
        return `span {
            color: ${theme.table.entityCell};
            font-weight: 500;
          }`;
      }
    }}
    svg {
      opacity: 1;
    }
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  svg {
    opacity: 0;
    height: 10px;
  }
`;

export const TooltipCell = ({ children, tooltip, zIndex, customClass }) => (
  <Tooltip title={tooltip} placement={placements.TOP} zIndex={zIndex} customClass={customClass}>
    <span>{children}</span>
  </Tooltip>
);

TooltipCell.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  zIndex: PropTypes.number,
  customClass: PropTypes.string,
};

TooltipCell.defaultProps = {
  tooltip: '',
  children: null,
  customClass: '',
};

export const EntityCell = ({ children, tooltip, onClick, authorized, className }) => {
  return (
    <StyledEntityCell onClick={authorized ? onClick : undefined} authorized={authorized}>
      {authorized ? (
        <>
          <Tooltip title={tooltip} placement={placements.TOP}>
            <span className={className}>{children}</span>
          </Tooltip>
          <EntityArrow />
        </>
      ) : (
        <Tooltip title={tooltip} placement={placements.TOP}>
          <span className={className}>{children}</span>
        </Tooltip>
      )}
    </StyledEntityCell>
  );
};

EntityCell.propTypes = {
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  authorized: PropTypes.bool,
  className: PropTypes.string,
};

EntityCell.defaultProps = {
  tooltip: '',
  onClick: () => {},
  children: null,
  authorized: true,
};

export const UnitCell = ({ unit, value }) => (
  <>{value || value === 0 ? `${value}${unit}` : Placeholders.DOUBLE_DASH}</>
);

UnitCell.propTypes = {
  unit: PropTypes.string,
  value: PropTypes.number.isRequired,
};

UnitCell.defaultProps = {
  unit: '',
};

const StyledTable = styled.table`
  position: relative;
  border-collapse: collapse;
  font-family: 'Museo Sans';
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  & > thead > tr > th {
    background: ${(props) => {
      const { backgroundColor, compressed } = props;
      if (backgroundColor) {
        return backgroundColor;
      }
      if (compressed) {
        return props.theme.table.sidebarHeaderBackgroundColor;
      }
      return props.theme.table.headerBackgroundColor;
    }};
    ${(props) => (props.compressed ? 'padding: 10px 8px 4px' : '')};
  }
`;

export const Table = forwardRef(
  ({ className, compressed, backgroundColor, children, TablekeyProperty, ...props }, ref) => {
    const { isLoading, noData, noDataDescription, noDataTitle, onRetry, renderCondition } = props;

    const table = props.scrollable ? (
      <ScrollingContainer ref={ref} TablekeyProperty={TablekeyProperty}>
        <StyledTable
          compressed={compressed}
          backgroundColor={backgroundColor}
          className={className}
        >
          {children}
        </StyledTable>
      </ScrollingContainer>
    ) : (
      <StyledTable compressed={compressed} backgroundColor={backgroundColor}>
        {children}
      </StyledTable>
    );

    return (
      <DataLoader
        isLoading={isLoading}
        noData={noData}
        noDataDescription={noDataDescription}
        noDataTitle={noDataTitle}
        onRetry={onRetry}
        type="table"
        renderCondition={renderCondition}
      >
        {table}
      </DataLoader>
    );
  },
);

Table.displayName = 'Table';

Table.propTypes = {
  ...DataLoader.propTypes,
  scrollable: PropTypes.bool,
  compressed: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  TablekeyProperty: PropTypes.string,
};

Table.defaultProps = {
  scrollable: false,
  compressed: false,
  children: null,
  backgroundColor: null,
  isLoading: false,
  noData: false,
  noDataDescription: undefined,
  noDataTitle: undefined,
  onRetry: () => {},
  renderCondition: true,
  rowsSelected: [],
};

const onClickThCss = css`
  cursor: pointer;
`;

const fixedLeftThCss = css`
  position: sticky;
  right: auto;
  left: 0;
  border-right: solid 1px ${(props) => props.theme.table.fixedBorderColor} !important;
  z-index: ${elevations.P15} !important;
  &:hover {
    cursor: not-allowed;
  }
`;

const fixedRightThCss = css`
  position: sticky;
  right: 0;
  left: auto;
  border-left: solid 1px ${(props) => props.theme.table.fixedBorderColor};
  z-index: ${elevations.P15} !important;
  &:hover {
    cursor: not-allowed;
  }
`;

const groupThHoverCss = css`
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: ${elevations.P0};
`;

const dragDropHoverCss = css`
  &.group-hover {
    &:before {
      ${groupThHoverCss}
      background: ${(props) => transparentize(0.8, props.theme.table.hoverColor)};
      left: 0;
    }

    &.group-first {
      &:before {
        width: calc(100% - 1px);
        border-left: solid 1px ${(props) => props.theme.table.hoverColor};
      }
    }
    &.group-last {
      &:after {
        ${groupThHoverCss}
        right: 0px;
        border-right: solid 1px ${(props) => props.theme.table.hoverColor};
      }
    }
  }
`;

const defaultThCss = css`
  border-right: 1px solid
    ${(props) => (props.border ? props.theme.table.headerBorderColor : `transparent`)};
  color: ${(props) => props.theme.table.columnTextColor};
  font-weight: 500;
  padding: ${(props) => (props.noPadding ? '0px 1px 0px 0' : '0px 12px')};
`;

const titleThCss = css`
  text-transform: uppercase;
  color: ${(props) => props.theme.table.sectionTextColor};
  font-weight: 700;
  border-right: 1px solid ${(props) => props.theme.table.headerBorderColor};
  padding: 6px 12px 2px;
`;

export const StyledTh = styled.th`
  text-align: ${(props) => (props.align ? props.align : 'center')};
  font-size: 11px;
  line-height: 13px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : null)};
  width: ${(props) => (props.width ? `${props.width}` : 'initial')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};

  &:empty {
    height: 30px;
  }

  ${(props) => (props.onClick ? onClickThCss : '')};

  ${(props) => {
    if (props.isTitle) return titleThCss;
    return defaultThCss;
  }};

  > svg {
    display: inline;
    margin-left: 4px;
  }
  .isSorted {
    font-weight: 700;
    color: ${(props) => props.theme.table.headerSortColor};
  }

  ${({ isTitle, draggable, isResizing, fixedLeft, fixedRight }) =>
    draggable && !isResizing && isTitle && !fixedLeft && !fixedRight && isTitleHoverCss};
  ${({ dragOver }) => dragOver && dragHoverCSS};
  &:active {
    cursor: grab;
  }

  ${(props) => props.fixedLeft && fixedLeftThCss};
  ${(props) => props.fixedRight && fixedRightThCss};
  ${(props) => !props.fixedLeft && !props.fixedRight && dragDropHoverCss};
`;

const dragHoverCSS = css`
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    content: '';
    background: ${(props) => props.theme.table.dragDropBackgroundColor};
    border-right: 1px solid ${(props) => darken(0.1, props.theme.table.dragDropBorderColor)};
    border-left: 1px solid ${(props) => darken(0.1, props.theme.table.dragDropBorderColor)};
  }
`;

const isTitleHoverCss = css`
  &:hover {
    cursor: grab;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 2px);
      height: 100%;
      background: ${(props) => transparentize(0.8, props.theme.table.hoverColor)};
      border-left: solid 1px ${(props) => props.theme.table.hoverColor};
      border-right: solid 1px ${(props) => props.theme.table.hoverColor};
      z-index: ${elevations.N1};
    }
  }
`;

const StyledLabel = styled.span`
  ${(props) => (props.onClick ? onClickThCss : '')};
`;

export const Thead = styled.thead`
  th {
    vertical-align: top;
    box-sizing: border-box;
    position: sticky;
    top: 28px;
    bottom: 0;
    z-index: ${elevations.P10};
    border-bottom: ${(props) =>
      props.transparent ? `2px solid ${props.theme.table.borderTransparent}` : 'none'};

    ${(props) => (props.noTitles ? 'padding: 35px 12px 8px' : '')};
    ${(props) => (props.compressed ? 'padding: 10px 8px 4px' : '')};

    &.group-first-drop {
      ${dragHoverCSS}
    }

    &.group-last {
      border-right: solid 1px;
      border-right-color: ${(props) =>
        props.transparent
          ? props.theme.table.borderTransparent
          : props.theme.table.headerBorderColor};
    }
  }

  tr:first-child th {
    top: 0px;
  }
`;

export const Tfoot = styled.tfoot``;

const stripedRows = css`
  &:nth-child(even) {
    background: ${(props) => props.theme.table.evenRowColor};
  }
  &:nth-child(odd) {
    background: ${(props) => props.theme.table.oddRowColor};
  }
`;

const transparentRows = css`
  background: ${(props) => props.theme.table.rowColor};

  td {
    border-bottom: ${(props) =>
      props.transparent ? `1px solid ${props.theme.table.borderTransparent}` : 'none'};
    border-right-color: ${(props) => props.theme.table.borderTransparent};
  }
`;

const HeaderLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

export const Tbody = styled.tbody`
  max-height: 100%;
  height: auto;

  td.entity-cell:hover ~ td {
    .row-hover-icon {
      transform: scale(1);
      &:before {
        display: none;
      }
    }
  }

  & > tr {
    border-bottom: ${(props) => (props.transparent ? '1px solid #252c33' : 'none')};
    cursor: pointer;
    ${(props) => (props.transparent ? transparentRows : stripedRows)};

    &:hover {
      background: ${(props) => props.theme.table.rowHoverBackground};

      .row-hover-icon {
        transform: scale(1.2);
        svg {
          fill: ${(props) => props.theme.table.iconHighlightColor};
        }
        &:before {
          content: '';
          position: absolute;
          width: 0px;
          height: 0px;
          top: 50%;
          left: 45%;
          box-shadow: 1px 0px 18px 4px ${(props) => props.theme.table.iconHighlightColor};
        }
      }

      td {
        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          background: #205762;
          height: 1px;
          z-index: ${elevations.P10};
        }
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
        }
      }
    }
  }

  td {
    &.group-last {
      border-right: solid 1px;
      border-right-color: ${(props) =>
        props.transparent ? props.theme.table.borderTransparent : props.theme.table.border};
    }
  }
`;

const fixedLeftTdCss = css`
  position: sticky;
  ${(props) => (props.fixedTop ? `top: ${props.fixedTop}` : '')};
  left: 0;
  right: auto;
  background: inherit;
  border-right: solid 1px ${(props) => props.theme.table.fixedBorderColor} !important;
  z-index: ${elevations.P1};
`;

const fixedRightTdCss = css`
  position: sticky;
  top: ${(props) => (props.fixedTop ? props.fixedTop : '60px')};
  right: 0;
  left: auto;
  z-index: ${elevations.P15}
  background: inherit;
  border-left: solid 1px ${(props) => props.theme.table.fixedBorderColor};
`;

export const Td = styled.td`
  box-sizing: border-box;
  border-right: ${(props) => (props.border ? `1px solid ${props.theme.table.border}` : 'none')};
  color: ${(props) => (props.color ? props.color : props.theme.table.dataTextColor)};
  font-size: ${(props) => (props.style && props.style.fontSize ? props.style.fontSize : '12px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : null)};
  padding: ${(props) => (props.zeroPadding ? '0px' : props.noPadding ? '8px 0px' : '8px 12px')};
  position: relative;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  width: ${(props) => (props.width ? `${props.width}` : 'initial')};

  /* Truncate table cells */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '140px')};
  white-space: nowrap;

  /* Truncate nested titles */
  > h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100px')};
    white-space: nowrap;
  }

  &.group-first-drop {
    ${dragHoverCSS}
  }
  ${(props) => !props.fixedLeft && !props.fixedRight && dragDropHoverCss}
  ${(props) => (props.fixedLeft ? fixedLeftTdCss : '')};
  ${(props) => (props.fixedRight ? fixedRightTdCss : '')};
`;

export const Tr = styled.tr`
  ${(props) =>
    props.selected &&
    css`
      > td {
        background: ${props.theme.table.rowSelectedBackground};
      }
    `}
`;

const Underline = styled.div`
  height: 2px;
  width: 100%;
  border-radius: 1px;
  margin-top: 4px;
  background-color: ${(props) => props.color};
`;

export const TableArrow = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.table.arrowColor,
}))`
  transform: rotate(-90deg) scale(1);
  &:hover {
    transform: rotate(-90deg) scale(1.2);
    fill: ${(props) => props.theme.table.iconHighlightColor};
    stroke: ${(props) => props.theme.filterMenu.icon.hoverStroke};
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      top: 50%;
      left: 45%;
      box-shadow: 1px 0px 18px 4px ${(props) => props.theme.table.iconHighlightColor};
    }
  }
`;

export const TableArrowButton = () => {
  return (
    <button type="button">
      <TableArrow />
    </button>
  );
};

export const TableSubTitle = styled.h5`
  font-weight: 500;
  color: ${(props) => props.theme.table.subTitleTextColor};
`;

const TableTitleCss = css`
  text-transform: capitalize;
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : null)};
  display: block;
`;

export const TableTitle = styled.h3`
  ${TableTitleCss}
  font-size: 13px;
`;

const ThIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.color,
}))`
  margin-right: 10px;
`;

const ThComponent = ({
  align,
  underline,
  labels,
  colSpan,
  border,
  sortedDirection,
  isTitle,
  onClick,
  noPadding,
  zeroPadding,
  width,
  style,
  icon,
  children,
  tooltip,
  filter,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
  onDragEnter,
  dragOver,
  dataKey,
  className,
  onMouseEnter,
  onMouseLeave,
  minWidth,
  whiteSpace,
  fixedTop,
  fixedLeft,
  fixedRight,
  ...props
}) => {
  const { isResizing } = useResizableContext();

  const {
    theme: { table },
  } = props;

  const entries = labels && Object.entries(labels);

  // Blank header
  if ((!entries || !entries.length) && !children) {
    return (
      <StyledTh
        colSpan={colSpan}
        border={border}
        noPadding={noPadding}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        dragOver={dragOver}
        data-key={dataKey}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isTitle={isTitle}
        minWidth={minWidth}
        fixedTop={fixedTop}
        fixedLeft={fixedLeft}
        fixedRight={fixedRight}
        zeroPadding={zeroPadding}
        isResizing={isResizing}
      />
    );
  }

  // Multiple labels requires slightly different markup
  if (entries.length > 1) {
    return (
      <StyledTh
        isTitle={isTitle}
        sort={sortedDirection}
        colSpan={colSpan}
        border={border}
        align={align}
        noPadding={noPadding}
        width={width}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        dragOver={dragOver}
        data-key={dataKey}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        minWidth={minWidth}
        fixedTop={fixedTop}
        fixedLeft={fixedLeft}
        fixedRight={fixedRight}
        zeroPadding={zeroPadding}
        isResizing={isResizing}
      >
        <Tooltip title={tooltip.title} placement={tooltip.placement}>
          {children ||
            entries.map((currentEntry, index) => {
              const [label, key] = currentEntry;
              const sorted = sortedDirection(key);
              return (
                <div key={label}>
                  <span>
                    <StyledLabel className={sorted ? 'isSorted' : ''} onClick={() => onClick(key)}>
                      {label}
                    </StyledLabel>
                    {sorted && (
                      <Icon
                        icon={sorted === SortDirection.ASC ? Icons.ARROW_UP : Icons.ARROW_DOWN}
                        size={10}
                        color={table.headerSortColor}
                      />
                    )}
                    {index !== entries.length - 1 && ' / '}
                  </span>
                  {filter}
                </div>
              );
            })}
        </Tooltip>
        <Underline color={underline} />
      </StyledTh>
    );
  }

  if (children)
    return (
      <StyledTh
        isTitle={isTitle}
        colSpan={colSpan}
        border={border}
        align={align}
        noPadding={noPadding}
        width={width}
        style={style}
        draggable={draggable}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        dragOver={dragOver}
        data-key={dataKey}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        minWidth={minWidth}
        whiteSpace={whiteSpace}
        fixedTop={fixedTop}
        fixedLeft={fixedLeft}
        fixedRight={fixedRight}
        zeroPadding={zeroPadding}
        isResizing={isResizing}
      >
        {children}
      </StyledTh>
    );

  // Single label: most common path
  const [label, key] = entries[0] || [children];
  const sorted = sortedDirection(key);

  return (
    <StyledTh
      isTitle={isTitle}
      colSpan={colSpan}
      border={border}
      align={align}
      onClick={() => (onClick ? onClick(key) : noop())}
      noPadding={noPadding}
      style={style}
      width={width}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      dragOver={dragOver}
      data-key={dataKey}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      minWidth={minWidth}
      whiteSpace={whiteSpace}
      fixedTop={fixedTop}
      fixedLeft={fixedLeft}
      fixedRight={fixedRight}
      zeroPadding={zeroPadding}
      isResizing={isResizing}
    >
      {icon && <ThIcon icon={icon} color={table.headerSortColor} />}
      <Tooltip title={tooltip.title} placement={tooltip.placement} zIndex={tooltip.zIndex}>
        <HeaderLabel
          data-testid={CommonLocators.COMMON_TABLE_HEADER_SPAN}
          className={sorted ? 'isSorted' : ''}
        >
          {label.includes('\n')
            ? label.split('\n').map((part) => (
                <div key={part} data-testid={CommonLocators.COMMON_TABLE_HEADER_SUB_DIV}>
                  {part}
                </div>
              ))
            : label}
        </HeaderLabel>
      </Tooltip>
      {sorted && (
        <Icon
          icon={sorted === SortDirection.ASC ? Icons.ARROW_UP : Icons.ARROW_DOWN}
          size={10}
          color={table.headerSortColor}
        />
      )}
      <Underline color={underline} />
      {filter}
    </StyledTh>
  );
};

ThComponent.propTypes = {
  labels: PropTypes.instanceOf(Object),
  underline: PropTypes.string,
  align: PropTypes.string,
  colSpan: PropTypes.number,
  border: PropTypes.bool,
  theme: PropTypes.instanceOf(Object).isRequired,
  sortedDirection: PropTypes.func,
  isTitle: PropTypes.bool,
  onClick: PropTypes.func,
  noPadding: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.instanceOf(Object),
  icon: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    placement: PropTypes.oneOf(Object.values(placements)),
    zIndex: PropTypes.number,
  }),
  filter: PropTypes.element,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  onDragEnter: PropTypes.func,
  dragOver: PropTypes.bool,
  dataKey: PropTypes.string,
  columnKey: PropTypes.string,
  groupKey: PropTypes.string,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  minWidth: PropTypes.string,
  whiteSpace: PropTypes.string,
  fixedTop: PropTypes.string,
  fixedLeft: PropTypes.bool,
  fixedRight: PropTypes.bool,
  zeroPadding: PropTypes.bool,
};

ThComponent.defaultProps = {
  labels: {},
  underline: '',
  align: '',
  colSpan: null,
  border: false,
  sortedDirection: () => null,
  isTitle: false,
  onClick: null,
  noPadding: false,
  width: null,
  style: null,
  icon: null,
  children: null,
  tooltip: {
    title: undefined,
    placement: 'left',
  },
  filter: null,
  draggable: false,
  onDragStart: () => null,
  onDragOver: () => null,
  onDrop: () => null,
  onDragEnter: () => null,
  dragOver: false,
  dataKey: '',
  columnKey: '',
  groupKey: '',
  className: null,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  minWidth: null,
  whiteSpace: 'nowrap',
  fixedTop: 'auto',
  fixedLeft: false,
  fixedRight: false,
  zeroPadding: false,
};

// Build an a11ynext object to represent column labels.
export const buildA11yLabels = (t, columnObj) => {
  if (!columnObj.labels) {
    return null;
  }

  return columnObj.labels.reduce((labelObj, label) => {
    labelObj[t(label.a11yKey, label.a11yDefault)] = label.sortValue || null;
    return labelObj;
  }, {});
};

export const buildTooltip = (t, columnObj) => {
  const { tooltip } = columnObj;
  if (!tooltip) {
    return undefined;
  }

  const { a11yKey, a11yDefault, placement } = tooltip;
  return { title: t(a11yKey, a11yDefault), placement: placement || placements.LEFT };
};

export const Th = withTheme(ThComponent);
