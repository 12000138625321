import styled from 'styled-components';

export const SBDSDemoSectionHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 8px;

  span {
    margin-left: 8px;
    font-size: 14px;
    flex: 1;
  }

  label {
    margin-top: 2px;
  }
`;
