import { useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Capability,
  DateTimeFormats,
  PermissionScope,
  EntityType,
  PageSource,
  AnomalyCasesResolutionDropdown,
} from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth, useTaskSource, useTaskWorkScope } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { withDefault } from '@ge/shared/util/table-utils';
import { formatHoursAndMinutes } from '@ge/shared/util/time-date';

import { getDefaultFilterDefs } from '../../manage/components/tables/tasks-table';
import { TasksColumns } from '../models/tasks-table-cols';
const TasksTableCapability = {
  FIELD_TASKS_VIEW: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  REAL_TIME_PAGES_VIEW: [
    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
  ],
};

const formatComponentCell = (components) =>
  components.reduce(
    (acc, value, index) => (value ? `${acc} ${index !== 0 ? '|' : ''} ${value}` : acc),
    '',
  );

const formatDateCell = (date) =>
  withDefault(date, date?.format ? date?.format(DateTimeFormats?.TASKS_TABLE_DATE) : null);
/**
 * Get all tasks.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useCustomFilterCount = () => {
  const [filterDefs, setFilterDefs] = useState({});

  const { taskPagesData: tasks, taskWorkers } = useContext(EntityDetailsContext);
  // data hooks
  const { isAuthorized } = useAuth();
  const { data: taskSources, isLoading: isTaskSourcesLoading } = useTaskSource({
    entityType: EntityType.ASSET,
    page: PageSource.EXECUTE,
  });

  const { isLoadingWorkScopes, data: taskWorkScopes } = useTaskWorkScope({
    entityType: EntityType.ASSET,
  });

  // hooks
  const { t } = useTranslation(['tasks'], {
    useSuspense: false,
  });
  const rows = useMemo(() => {
    if (isTaskSourcesLoading || isLoadingWorkScopes) return [];

    return tasks?.map((task) => {
      const {
        asset,
        createDateTz,
        completedDateTimeTz,
        consumedParts,
        dueDateTz,
        eligibleStartDateTz,
        expectedParts,
        scheduleDateTz,
        estDurationHours,
        estDurationMinutes,
        laborHours,
        laborMinutes,
        startedDateTimeTz,
        component1,
        component2,
        component3,
        site,
        priority,
        status,
        title,
        description,
        linked,
        estTechs,
        completedByTech,
        attachment,
        note,
        resolutionNotes,
        srNumber,
        srStatus,
        timing,
        flag,
        rdspp,
      } = task;
      const allTechs =
        completedByTech?.reduce((acc, v) => {
          const tech = taskWorkers?.find((worker) => worker?.username === v);
          const techName = tech?.initials || tech?.firstName?.substring(0, 5);
          return techName ? [...acc, techName] : acc;
        }, []) ?? [];

      const siteId = asset?.site?.id ?? site?.id;
      let resolution = resolutionNotes;
      if (resolutionNotes && AnomalyCasesResolutionDropdown.includes(task?.source))
        resolution = resolutionNotes.replace(/;/g, ', ');

      const authorization = {
        capabilities: TasksTableCapability.FIELD_TASKS_VIEW,
        siteIds: [siteId],
      };

      // task needs to be scoped to a site and have view permissions to be visible
      // TODO: verify if the task service will already filter this according to field-tasks view metadata
      // would be nice to know if some of this is already handled for us
      if (!(siteId && isAuthorized(authorization))) {
        return tasks;
      }
      // add custom auth flags for table
      const canOpenEntityPanel = isAuthorized({
        capabilities: TasksTableCapability.REAL_TIME_PAGES_VIEW,
        siteIds: [siteId],
      });

      const totalDurationMinutes = (estDurationHours ?? 0) * 60 + (estDurationMinutes ?? 0);

      let parsedHrsActualDuration = 0;
      let parsedMinsActualDuration = 0;
      let i = 0;
      while (undefined != timing && i < timing.length) {
        parsedMinsActualDuration += timing[i].durationMins;
        parsedHrsActualDuration += timing[i].durationHrs;
        i++;
      }
      const totalDurationMinutesActualDuration =
        (parsedHrsActualDuration ?? 0) * 60 + (parsedMinsActualDuration ?? 0);

      return {
        ...task,
        authorization: {
          canOpenEntityPanel,
        },
        [TasksColumns.ASSET]: asset && { id: asset?.id, name: asset?.name, siteId: site?.id },
        [TasksColumns.SITE]: site && { id: site.id, name: site.name, timezone: site.timezone },
        [TasksColumns.STATE]: asset?.state,
        [TasksColumns.DETAIL]: '',
        [TasksColumns.EST_LABOR_HOURS]: withDefault(
          laborHours ?? laborMinutes,
          formatHoursAndMinutes(laborHours, laborMinutes),
        ),
        [TasksColumns.CREATED_DATE]: formatDateCell(createDateTz),
        [TasksColumns.DUE_DATE]: formatDateCell(dueDateTz),
        [TasksColumns.SCHEDULED_DATE]: formatDateCell(scheduleDateTz),
        [TasksColumns.ELIGIBLE_START_DATE]: formatDateCell(eligibleStartDateTz),
        [TasksColumns.STARTED_DATE_TIME]: formatDateCell(startedDateTimeTz),
        [TasksColumns.COMPLETED_DATE_TIME]: formatDateCell(completedDateTimeTz),
        [TasksColumns.ACTUAL_DURATION]: {
          totalDurationMinutesActualDuration,
          value: withDefault(
            (Math.floor(totalDurationMinutesActualDuration / 60) == 0
              ? null
              : Math.floor(totalDurationMinutesActualDuration / 60)) ??
              totalDurationMinutesActualDuration % 60,
            formatHoursAndMinutes(
              Math.floor(totalDurationMinutesActualDuration / 60),
              totalDurationMinutesActualDuration % 60,
            ),
          ),
        },
        [TasksColumns.PARTS_EXPECTED]:
          expectedParts && expectedParts.length
            ? { parts: expectedParts, count: expectedParts.length }
            : undefined,
        [TasksColumns.PARTS_CONSUMED]:
          consumedParts && consumedParts.length
            ? { parts: consumedParts, count: consumedParts.length }
            : undefined,
        [TasksColumns.EST_DURATION]: {
          totalDurationMinutes,
          value: withDefault(
            (Math.floor(totalDurationMinutes / 60) == 0
              ? null
              : Math.floor(totalDurationMinutes / 60)) ?? totalDurationMinutes % 60,
            formatHoursAndMinutes(Math.floor(totalDurationMinutes / 60), totalDurationMinutes % 60),
          ),
        },
        [TasksColumns.COMPONENT]: formatComponentCell([component1, component2, component3]),
        [TasksColumns.PRIORITY]: withDefault(priority),
        [TasksColumns.STATUS]: status,

        [TasksColumns.TITLE]: withDefault(title),
        [TasksColumns.DESCRIPTION]: withDefault(description),
        [TasksColumns.LINKED]: linked,
        [TasksColumns.EST_TECHS]: withDefault(estTechs),
        [TasksColumns.ASSIGNED_TECHS]: { value: allTechs },
        // Not part of MVP0
        [TasksColumns.ATTACHMENT]: withDefault(attachment), // Not part of MVP0
        [TasksColumns.NOTES]: withDefault(note), // Not part of MVP0
        [TasksColumns.RESOLUTION]: resolution,
        [TasksColumns.SR_NUMBER]: withDefault(srNumber), // Not part of MVP0
        [TasksColumns.SR_STATUS]: withDefault(srStatus), // Not part of MVP0
        ...(taskSources && {
          source: t(`dynamic.types.${task.source}`, task.source),
        }),
        ...(taskWorkScopes && {
          workScope: t(`dynamic.work_scopes.${task.workScope}`, task.workScope),
        }),
        [TasksColumns.FLAG]: withDefault(flag),
        [TasksColumns.RDSPP]: withDefault(rdspp),
      };
    });
  }, [
    isTaskSourcesLoading,
    isLoadingWorkScopes,
    tasks,
    isAuthorized,
    taskSources,
    t,
    taskWorkScopes,
    taskWorkers,
  ]);

  const allTasks = useTableFilter({
    data: rows,
    filters: filterDefs,
    search: {},
    isTaskTable: true,
  });

  const getTaskCount = (taskTracker) => {
    if (!taskTracker.defaultView && taskTracker.filters) {
      const filters = getDefaultFilterDefs();
      taskTracker?.filters.map((ele) => {
        filters[ele.name].value = ele.value;
        if (ele.min && ele.max) {
          filters[ele.name].min = ele?.min;
          filters[ele.name].max = ele?.max;
        }
      });
      setFilterDefs(filters);
    }
  };

  return useMemo(
    () => ({
      getTaskCount,
      filterTask: allTasks?.data,
    }),
    [allTasks],
  );
};
