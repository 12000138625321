import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ToggleButton } from '@ge/components/toggle-button';
import { logout, getUserName } from '@ge/shared/services/auth';

import { Theme } from '../../models/theme';

const StyledDropdown = styled.ul`
  list-style: none;
  position: relative;
  color: ${(props) => props.theme.mainNav.dataTextColor};
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  > li {
    span {
      cursor: pointer;
      padding: 20px 20px 16px;
      display: block;
      svg {
        margin-left: 10px;
        transition: transform 0.6s ease;
        transform: rotate(0deg);
      }
    }
  }
  &:hover,
  &:focus,
  &:focus-within {
    ul {
      display: block;
    }
    > li {
      span {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;

const MenuList = styled.ul`
  position: absolute;
  left: -65px;
  list-style: none;
  width: 155px;
  display: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid ${(props) => props.theme.profileNav.borderTopColor};
  background: ${(props) => props.theme.profileNav.backgroundColor};
  border-radius: 0 0 4px 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  font-size: 12px;
  color: ${(props) => props.theme.profileNav.linkIconTextColor};
  border-bottom: solid 2px ${(props) => props.theme.profileNav.linkBorderColor};
  &:hover,
  &.active {
    border-color: ${(props) => props.theme.profileNav.linkActiveBorderColor};
    color: ${(props) => props.theme.profileNav.linkIconTextActiveColor};
    button {
      color: ${(props) => props.theme.profileNav.linkIconTextActiveColor};
    }
    svg {
      fill: ${(props) => props.theme.profileNav.linkIconTextActiveColor};
    }
  }
`;

const MenuLink = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  font-size: 12px;
  color: ${(props) => props.theme.profileNav.linkIconTextColor};
`;

const MenuLinkIcon = styled(Icon)`
  margin: 0 10px 0 16px;
`;

const ThemeToggleLabel = styled.label`
  margin-right: 10px;
`;

const Profile = ({ className, ...props }) => {
  const { t } = useTranslation();
  const {
    theme: { profileNav },
  } = props;
  const userName = getUserName();

  const profileWindow = () => window.open('/profile', '_self');

  const { toggleTheme } = useStoreActions((actions) => actions.prefs);
  const themeName = props.theme.name.toLowerCase();
  const { getThemeJs } = useStoreState((state) => state.prefs);
  const darkTheme = getThemeJs(Theme.DARK);

  return (
    <StyledDropdown tabIndex="0">
      <li cy-id="profile-dropdown">
        <span>
          {userName}
          <Icon size={12} icon={Icons.CHEVRON} color={profileNav.chevronColor} />
        </span>
        <MenuList>
          <ListItem>
            <MenuLink type="button" onClick={profileWindow} tabIndex="0">
              <MenuLinkIcon size={12} icon={Icons.PERSON} color={profileNav.linkIconTextColor} />
              {t('profile', 'Profile')}
            </MenuLink>
          </ListItem>
          <ListItem>
            <MenuLinkIcon size={12} icon={Icons.LIGHT_BULB} color={profileNav.linkIconTextColor} />
            <ThemeToggleLabel>{t('dark_theme', 'Dark Theme')}</ThemeToggleLabel>
            <ToggleButton
              isToggleOn={themeName === Theme.DARK}
              handleOnClick={toggleTheme}
              noIcon={true}
              theme={darkTheme}
            />
          </ListItem>
          <ListItem>
            <MenuLink type="button" className={className} onClick={logout} tabIndex="0">
              <MenuLinkIcon size={12} icon={Icons.LOAD_FILE} color={profileNav.linkIconTextColor} />
              {t('log_out', 'Log Out')}
            </MenuLink>
          </ListItem>
        </MenuList>
      </li>
    </StyledDropdown>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.instanceOf(Object),
  theme: PropTypes.instanceOf(Object).isRequired,
};

Profile.defaultProps = {
  className: null,
  user: null,
};

export default withTheme(Profile);
