import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AssetCommandStatus, Placeholders } from '@ge/models/constants';

import { useAssetCommandStatus } from '../../data-hooks/use-asset-command-status';

const StatusContainer = styled.div`
  text-transform: capitalize;
  .count {
    font-weight: 700;
  }
`;

// allows for live updates to the status in the notification
export const MutableCommandStatus = ({ assets, onError, onSuccess, statusId }) => {
  const { ready, t } = useTranslation(['monitor.assets'], { useSuspense: false });
  const { data: { isComplete, status } = {} } = useAssetCommandStatus({ assets, statusId });

  const counts = status?.reduce(
    (_counts, { status }) => {
      // default to in progress if not status provided yet (can review this)
      let key = status ?? AssetCommandStatus.IN_PROGRESS;
      // group all fails together
      key = [
        AssetCommandStatus.FAIL,
        AssetCommandStatus.TIME_OUT,
        AssetCommandStatus.BLOCKED,
      ].includes(status)
        ? AssetCommandStatus.FAIL
        : key;

      _counts[key] = _counts[key] + 1;

      return _counts;
    },
    {
      [AssetCommandStatus.FAIL]: 0,
      [AssetCommandStatus.IN_PROGRESS]: 0,
      [AssetCommandStatus.SUCCESS]: 0,
    },
  ) ?? {
    [AssetCommandStatus.FAIL]: Placeholders.DOUBLE_DASH,
    [AssetCommandStatus.IN_PROGRESS]: Placeholders.DOUBLE_DASH,
    [AssetCommandStatus.SUCCESS]: Placeholders.DOUBLE_DASH,
  };

  const isError =
    counts[AssetCommandStatus.IN_PROGRESS] === 0 && counts[AssetCommandStatus.FAIL] > 0;
  const isSuccess = !isError && counts[AssetCommandStatus.SUCCESS] === status?.length;

  useEffect(() => {
    if (isComplete) {
      if (isSuccess) {
        onSuccess();
      } else if (isError) {
        onError();
      }
    }
  }, [isComplete, isError, isSuccess, onError, onSuccess]);

  if (!ready) {
    return null;
  }

  const translations = {
    count: t('notification.count_label', { count: assets?.length ?? Placeholders.DOUBLE_DASH }),
    fail: `${t(`notification.${AssetCommandStatus.FAIL}`)}/${t(
      `notification.${AssetCommandStatus.TIME_OUT}`,
    )}/${t(`notification.${AssetCommandStatus.BLOCKED}`)}`,
    inProgress: t(`notification.${AssetCommandStatus.IN_PROGRESS}`),
    success: t(`notification.${AssetCommandStatus.SUCCESS}`),
  };

  return (
    <StatusContainer>
      <span className="count">{translations.count}</span> - {translations.inProgress}:{' '}
      <span className="count">{counts[AssetCommandStatus.IN_PROGRESS]}</span> /{' '}
      {translations.success}: <span className="count">{counts[AssetCommandStatus.SUCCESS]}</span> /{' '}
      {translations.fail}: <span className="count">{counts[AssetCommandStatus.FAIL]}</span>
    </StatusContainer>
  );
};

MutableCommandStatus.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  statusId: PropTypes.string.isRequired,
};

MutableCommandStatus.defaultProps = {
  onError: () => {},
  onSuccess: () => {},
};
