import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { QueryKey, SortDirection, TimeAggr } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';

import { useGlobalFilters } from '../hooks';
import { SitePerformanceChartType } from '../models/constants';
import { fetchFleetSitePerformance } from '../services';

const chartTypeValueKeyMap = {
  [SitePerformanceChartType.SNAPSHOT]: 'value',
  [SitePerformanceChartType.UNAVAILABILITY]: 'unavailabilityValue',
};

export const useFleetSitePerformance = ({
  category,
  endDate,
  isActive,
  pageIndex = 0,
  pageSize = 10,
  sortDirection = SortDirection.ASC,
  startDate,
  timeAggr = TimeAggr.DAILY,
  type = SitePerformanceChartType.SNAPSHOT,
}) => {
  const { t } = useTranslation(['analyze.dashboard']);

  const logger = useLogger();

  const filters = useGlobalFilters();

  // state
  const regions = useStoreState((state) => state.regions.regions);

  const {
    data: _data,
    error,
    isLoading,
  } = useQuery(
    [
      QueryKey.FLEET_SITE_PERFORMANCE,
      category,
      endDate,
      filters,
      pageIndex,
      pageSize,
      startDate,
      timeAggr,
      sortDirection,
    ],
    async () => {
      const params = {
        category,
        endDate,
        filters,
        pageIndex,
        pageSize,
        sortDirection,
        startDate,
        timeAggr,
      };

      const { data } = await fetchFleetSitePerformance(params);

      return data;
    },
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(category) && isActive,
    },
  );

  // hydrate entity data and transform chart data
  const data = useMemo(() => {
    // for pareto we use unavailability
    const valueKey = chartTypeValueKeyMap[type];

    const HIGHEST_PERFORMERS = 'highest-performers';
    const LOWEST_PERFORMERS = 'lowest-performers';

    const topBottomRegions = {
      [LOWEST_PERFORMERS]: {
        id: LOWEST_PERFORMERS,
        name: t(`site_performance.${LOWEST_PERFORMERS}`, 'Bottom 10 Sites'),
      },
      [HIGHEST_PERFORMERS]: {
        id: HIGHEST_PERFORMERS,
        name: t(`site_performance.${HIGHEST_PERFORMERS}`, 'Top 10 Sites'),
      },
    };

    const _regions = {
      ...regions,
      ...topBottomRegions,
    };

    const transformedData = Object.entries(_data ?? {})
      .reduce(
        (transformed, [regionId, regionSites]) => [
          ...transformed,
          {
            data: regionSites.map(({ regionId: siteRegionId, [valueKey]: y, ...siteData }) => ({
              region: { id: siteRegionId },
              ...siteData,
              y,
            })),
            id: regionId,
            name: _regions[regionId]?.name,
            points: regionSites.map(({ [valueKey]: y }) => ({ y })),
          },
        ],
        [],
      )
      .sort(({ name: a }, { name: b }) => (a < b ? -1 : a > b ? 1 : 0));

    // Find index of top or bottom performers object.
    const foundIndex = transformedData.findIndex(
      (x) =>
        x?.name === topBottomRegions[LOWEST_PERFORMERS]?.name ||
        x?.name === topBottomRegions[HIGHEST_PERFORMERS]?.name,
    );

    // Move top or bottom performers object to start of array.
    if (foundIndex !== -1) {
      transformedData.splice(0, 0, transformedData.splice(foundIndex, 1)[0]);
    }

    return transformedData;
  }, [_data, regions, type, t]);

  // do we want to do this here?
  if (error) {
    logger.error(error);
  }

  return { data, error, isLoading };
};
