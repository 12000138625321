import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Select } from '@ge/components/select';
import { Tab, Tabs } from '@ge/components/tabs';
import { globalColors } from '@ge/tokens/colors';

import {
  AdminCollapsiblePanel,
  FormError,
  FormLoading,
} from '../../../entity-create/entity-create-shared';
import { useAllRoles } from '../../../entity-create/hooks/use-all-roles';
import { PersonContext } from '../person-context';

import PersonRoleDetail from './person-role-detail';

const PersonRolesContainer = styled.div`
  position: relative;
  padding: 20px 20px 0 10px;
  height: 100%;
  background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  h3 {
    text-transform: uppercase;
  }

  p {
    margin: 5px 0 0;
  }
`;

const LoadingWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 75vh;
`;

const PersonRolesHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 18px;
  justify-content: space-between;

  h2 {
    font-size: 18px;
    text-transform: uppercase;
  }
`;

const PageTagline = styled.div`
  display: block;
  padding: 9px 0 15px 0;
  font-size: 12px;
  color: ${globalColors.grey4};
`;

const StyledTabs = styled(Tabs)`
  height: calc(100vh - 350px) !important;
  overflow: hidden;

  .permissions {
    > div {
      height: calc(100vh - 550px);
    }
  }
`;

const RolesViewHeader = styled.div`
  margin: 12px 0 9px !important;
`;

const EditViewHeader = styled.div`
  margin: 12px 0 9px !important;
`;

const EditApplyHeader = styled.div`
  margin: 12px 0 9px !important;
`;

const StyledSelect = styled(Select)`
  .select__control.select__control--is-disabled {
    opacity: 0.7;
  }
`;

const EditFooterActions = styled.div`
  position: relative;
  margin: 0;
  padding: 14px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .add-role {
    color: ${(props) => props.theme.admin.panel.body.textButton};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
`;

const CollapsePanels = styled.div`
  border-bottom: ${(props) => props.theme.admin.panel.body.border} 1px solid;
`;

const UtilityBar = styled.div`
  position: relative;
  margin: 0 0 16px 0;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: ${(props) => props.theme.admin.panel.body.textButton};
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.admin.panel.body.textButton,
}))`
  margin: 0 4px 2px 0;
`;

const EditButton = withTheme(({ theme, ...rest }) => {
  const { iconColor } = theme.themeSelector;
  return (
    <button {...rest}>
      <Icon icon={Icons.PENCIL} size={11} color={iconColor} />
    </button>
  );
});

export const PersonRolesPermissions = ({ personDetail }) => {
  const { t } = useTranslation(['entity-details']);
  const [permissions, setPermissions] = useState(null);
  const [roleOptions, setRoleOptions] = useState(null);
  const [activeTab, setActiveTab] = useState('');
  const [roleLoaded, setRoleLoaded] = useState(false);
  const [attributeRoles, setAttributeRoles] = useState(null);
  const [selectValue, setSelectValue] = useState({
    value: '',
    label: t('person_edit_roles.select_role', 'Select a Role'),
  });

  const { setProvidedAttributesInStore } = useStoreActions((actions) => actions.tabflow);

  const { isLoading: isAllRolesLoading, mappedRoles, mappedPermissions } = useAllRoles();
  const { limitedUserRoles: mappedLimitedRoles, isUserAttributesLoading: isLimitedRolesLoading } =
    useStoreState((state) => state.userRole);
  const {
    providedAttributes,
    setProvidedAttributes,
    removedRoles,
    setRemovedRoles,
    fetchedPerson,
    setProvidedTab,
    roleStart,
    comparedRoles,
    setComparedRoles,
    handleEditRolesSaveButton,
    personPatching,
    isEditMode,
    setIsEditMode,
    isError,
  } = useContext(PersonContext);

  const defaultSelectValue = {
    value: '',
    label: t('person_edit_roles.select_role', 'Select a Role'),
  };

  // SET PERMISSIONS FOR ALL ROLES BY TENANT ID
  useEffect(() => {
    if (providedAttributes && !isAllRolesLoading && mappedPermissions.length > 0) {
      let filteredByTenant = mappedPermissions.filter(
        (permission) => permission.tenantId === fetchedPerson.tenantId,
      );
      setPermissions(filteredByTenant);
    }
  }, [mappedPermissions, fetchedPerson, providedAttributes, isAllRolesLoading]);

  // LOAD ALL ROLE OPTIONS BY TENANT ID AND PERSON ATTRIBUTES
  useEffect(() => {
    if (providedAttributes && !isAllRolesLoading && mappedRoles.length > 0) {
      const filteredRoles = mappedRoles.filter((role) => role.tenantId === fetchedPerson.tenantId);
      let filteredAttributeRoles = providedAttributes.map((attribute) => attribute.roleName);
      setRoleOptions(filteredRoles);
      setAttributeRoles(filteredAttributeRoles);
    }
  }, [fetchedPerson, providedAttributes, isAllRolesLoading, mappedRoles]);

  // HANDLER FUNCTIONS DURING EDIT MODE
  const handleAddRole = useCallback(() => {
    const holdRole = {
      roleName: t('person_edit_roles.new_role', 'New_User_Role'),
      groupCombination: [
        { groupName: 'sites', ids: [] },
        { groupName: 'site_group', ids: [] },
        { groupName: 'service_group', ids: [] },
      ],
    };
    setProvidedAttributes([...providedAttributes, holdRole]);
    setProvidedAttributesInStore([...providedAttributes, holdRole]);
    setRoleLoaded(true);
  }, [providedAttributes, setProvidedAttributes, setProvidedAttributesInStore, t]);

  const handleSelectChange = useCallback(
    (e) => {
      const selectedRole = {
        roleName: e.value,
        groupCombination: [
          { groupName: 'sites', ids: [] },
          { groupName: 'site_group', ids: [] },
          { groupName: 'service_group', ids: [] },
        ],
      };
      setSelectValue({ value: e.value, label: t(e.value, e.label) });
      providedAttributes.pop();
      setProvidedAttributes([...providedAttributes, selectedRole]);
      setProvidedAttributesInStore({ value: e.value, action: 'add' });
      setActiveTab(e.value.replace(/_/g, '-').toLowerCase());
      setRoleLoaded(false);
    },
    [providedAttributes, setProvidedAttributes, t, setProvidedAttributesInStore],
  );

  const handleRemoveRole = useCallback(
    (e) => {
      let attributeList = providedAttributes.filter(
        (attribute) => attribute.roleName !== e.target.dataset.rolename,
      );
      setProvidedAttributes(attributeList);
      setProvidedAttributesInStore({ value: e.target.dataset.rolename, action: 'delete' });
      setRemovedRoles([...removedRoles, e.target.dataset.rolename]);
      setActiveTab(providedAttributes[0].roleName.replace(/_/g, '-').toLowerCase());
    },
    [
      providedAttributes,
      removedRoles,
      setProvidedAttributes,
      setRemovedRoles,
      setProvidedAttributesInStore,
    ],
  );

  const handleEditButton = useCallback(() => {
    setIsEditMode(true);
    setProvidedTab('roles-&-permissions');
  }, [setIsEditMode, setProvidedTab]);

  const handleCancelButton = useCallback(() => {
    let filteredAttributes = providedAttributes?.filter(
      (attribute) => attribute.roleName !== 'New_User_Role',
    );
    setProvidedAttributes(() => filteredAttributes);
    setRoleLoaded(false);
    setIsEditMode(false);
  }, [providedAttributes, setIsEditMode, setProvidedAttributes]);

  useEffect(() => {
    if (providedAttributes && !providedAttributes.length && !removedRoles.length) {
      setIsEditMode(true);
      handleAddRole();
    }
  }, [providedAttributes, handleAddRole, setIsEditMode, removedRoles]);

  // HANDLER FUNCTION TO COMPARE IF ROLES HAVE CHANGED - 0=DISABLE; 1=SAVE; 2=NEXT
  const compareRoles = useCallback(
    (roleStart, providedAttributes) => {
      let mappedNewRoles = providedAttributes?.map((role) => role.roleName);
      if (!roleStart || !providedAttributes) {
        setComparedRoles(0);
      } else if (mappedNewRoles.length === roleStart.length) {
        let includedRoles = roleStart.map((role) => mappedNewRoles.includes(role));
        if (includedRoles.every((role) => role === true)) {
          setComparedRoles(0);
        } else {
          setComparedRoles(2);
        }
      } else if (mappedNewRoles.length < roleStart.length) {
        let includedRoles = mappedNewRoles.map((role) => roleStart.includes(role));
        if (includedRoles.every((role) => role === true)) {
          setComparedRoles(1);
        } else {
          setComparedRoles(2);
        }
      } else {
        setComparedRoles(2);
      }
    },
    [setComparedRoles],
  );

  // COMPARE IF ROLES HAVE CHANGED
  useEffect(() => {
    compareRoles(roleStart, providedAttributes);
  }, [compareRoles, providedAttributes, roleStart]);

  // ROLES VIEW (rendered below)
  const personRolesView = () => {
    return (
      <StyledTabs isForceRenderTabs={true}>
        {providedAttributes?.map((attribute, idx) => (
          <Tab
            key={idx}
            label={t(`roletab.${attribute.roleName}`, attribute.roleName.replace(/[_-]/g, ' '))}
          >
            <RolesViewHeader>
              <p>
                {t(
                  'person_edit_roles.permissions_desc',
                  `These are the products and features allowed for ${attribute.roleName.replace(
                    /[_-]/g,
                    ' ',
                  )}.`,
                )}
              </p>
            </RolesViewHeader>
            <div className="permissions">
              <ScrollingContainer>
                <CollapsePanels>
                  {permissions
                    ? permissions
                        .find((permission) => permission.roleName === attribute.roleName)
                        .segmentOfferings?.map((segment, id) => (
                          <AdminCollapsiblePanel
                            headerContent={
                              <div>
                                <h4>{t(`segment_offerings.${segment.name}`, segment.name)}</h4>
                                <p>{t('segment_desc', segment.description)}</p>
                              </div>
                            }
                            key={id}
                          >
                            <PersonRoleDetail capabilityGroups={segment.capabilities} />
                          </AdminCollapsiblePanel>
                        ))
                    : null}
                </CollapsePanels>
              </ScrollingContainer>
            </div>
          </Tab>
        ))}
      </StyledTabs>
    );
  };

  // ROLES EDIT - ADD AND REMOVE ROLES (rendered below)
  const personRolesEdit = () => {
    return (
      <>
        <StyledTabs defaultTab={activeTab} isForceRenderTabs={true}>
          {providedAttributes?.map((attribute, idx) =>
            attribute.roleName !== 'New_User_Role' ? (
              <Tab
                key={idx}
                label={t(`roletab.${attribute.roleName}`, attribute.roleName.replace(/[_-]/g, ' '))}
              >
                <EditViewHeader>
                  <p>
                    {`${attribute.roleName.replace(/[_-]/g, ' ')} role has been applied for 
                        ${personDetail.firstName}.`}
                  </p>
                </EditViewHeader>
                <UtilityBar>
                  <StyledSelect
                    maxWidth={250}
                    minWidth={250}
                    disabled={true}
                    options={roleOptions
                      ?.filter((option) => !attributeRoles?.includes(option.a11yKey))
                      .map((option) => {
                        return {
                          value: option.a11yKey,
                          label: t(option.a11yKey, option.defaultLabel),
                        };
                      })}
                    value={roleOptions
                      ?.filter((option) => option.a11yKey === attribute.roleName)
                      .map((option) => {
                        return {
                          value: option.a11yKey,
                          label: t(option.a11yKey, option.defaultLabel),
                        };
                      })}
                    primary
                  />
                  <button
                    data-rolename={attribute.roleName}
                    onClick={handleRemoveRole}
                    type="Button"
                  >
                    <StyledIcon icon={Icons.REMOVE} />
                    {t('person_edit_roles.select_role.remove_role', 'Remove Role')}
                  </button>
                </UtilityBar>
                <div className="permissions">
                  <h3>{t('person_edit_roles.permissions', 'Product Permissions')}</h3>
                  <p>
                    {t(
                      'person_edit_roles.permissions_desc',
                      'These are the products and features allowed in this role.',
                    )}
                  </p>
                  <ScrollingContainer>
                    <CollapsePanels>
                      {permissions
                        ? permissions
                            .find((permission) => permission.roleName === attribute.roleName)
                            .segmentOfferings?.map((segment, id) => (
                              <AdminCollapsiblePanel
                                headerContent={
                                  <div>
                                    <h4>{t(`segment_offerings.${segment.name}`, segment.name)}</h4>
                                    <p>{t('segment_desc', segment.description)}</p>
                                  </div>
                                }
                                key={id}
                              >
                                <PersonRoleDetail capabilityGroups={segment.capabilities} />
                              </AdminCollapsiblePanel>
                            ))
                        : null}
                    </CollapsePanels>
                  </ScrollingContainer>
                </div>
              </Tab>
            ) : (
              <Tab
                key={idx}
                label={t(`roletab.${attribute.roleName}`, attribute.roleName.replace(/[_-]/g, ' '))}
              >
                <EditApplyHeader>
                  <h3>{t('person_edit_roles.select_role', 'Select A Role')}</h3>
                  <p>
                    {t('person_edit_roles.role_desc', 'This role will be assigned to this user')}
                  </p>
                </EditApplyHeader>
                <UtilityBar>
                  <StyledSelect
                    maxWidth={250}
                    minWidth={250}
                    disabled={false}
                    defaultValue={selectValue}
                    options={mappedLimitedRoles
                      ?.filter((option) => !attributeRoles?.includes(option.a11yKey))
                      .map((option) => {
                        return {
                          value: option.a11yKey,
                          label: t(option.a11yKey, option.defaultLabel),
                        };
                      })}
                    value={defaultSelectValue}
                    onChange={handleSelectChange}
                    primary
                  />
                  <button type="Button">
                    <StyledIcon icon={Icons.REMOVE} />
                    {t('person_edit_roles.remove_role', 'Remove Role')}
                  </button>
                </UtilityBar>
                <h3>Product Permissions</h3>
                <p>Please select a role above</p>
              </Tab>
            ),
          )}
        </StyledTabs>
        <EditFooterActions>
          <Button type="button" onClick={handleCancelButton}>
            {t('general.cancel', 'Cancel')}
          </Button>
          <ButtonsWrapper>
            {!roleLoaded ? (
              <button className="add-role" onClick={handleAddRole} type="Button">
                <StyledIcon icon={Icons.ADD} />
                {t('personEdit.add_role', 'Add Another Role')}
              </button>
            ) : null}
            {comparedRoles < 2 ? (
              handleEditRolesSaveButton()
            ) : (
              <Button
                type="button"
                onClick={() => setProvidedTab('assets')}
                disabled={roleLoaded ? true : false}
                primary
              >
                {t('person_edit_roles.assign_assets', 'Next: Associate Assets')}
              </Button>
            )}
          </ButtonsWrapper>
        </EditFooterActions>
      </>
    );
  };

  if (isLimitedRolesLoading && isAllRolesLoading && !permissions)
    return (
      <LoadingWrapper>
        <FormLoading>
          <Loader />
        </FormLoading>
      </LoadingWrapper>
    );

  return (
    <PersonRolesContainer>
      <PersonRolesHeader>
        <h2>Roles</h2>
        {!isEditMode && <EditButton onClick={handleEditButton} />}
      </PersonRolesHeader>
      <PageTagline>{`${personDetail.firstName} is a ${personDetail.type} and can be scheduled for work. These are ${personDetail.firstName}'s Roles and Permissions.`}</PageTagline>
      {/* EDIT MODE FALSE */}
      {!isEditMode ? personRolesView() : personRolesEdit()}
      {personPatching && (
        <FormLoading>
          <Loader />
        </FormLoading>
      )}
      {isError && <FormError>{t('An error occurred. Person failed to be edited.')}</FormError>}
    </PersonRolesContainer>
  );
};

PersonRolesPermissions.propTypes = {
  personDetail: PropTypes.object,
};
