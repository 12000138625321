import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens';

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
`;

export const ScheduleErrors = ({ className }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { errors } = useFormContext();

  return (
    <div className={className}>
      {errors?.scheduleDate && errors?.scheduleDate?.type === 'eligibleStartDate' && (
        <ErrorText>
          {t(
            'tasks:form.error_eligible_start_date',
            'Schedule Date cannot be prior to Eligible Start Date',
          )}
        </ErrorText>
      )}
      {errors?.scheduleDate && errors?.scheduleDate?.type === 'dueDate' && (
        <ErrorText>
          {t('tasks:form.error_due_date', 'Schedule Date cannot be after Due Date')}
        </ErrorText>
      )}
      {errors?.startTime && errors?.startTime.type === 'required' && (
        <ErrorText>{t('tasks:form.start_time_required', 'Start time required')}</ErrorText>
      )}
    </div>
  );
};

ScheduleErrors.propTypes = {
  className: PropTypes.string,
};

ScheduleErrors.defaultProps = {
  className: null,
};
