import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import {
  EntityType,
  TaskRecurrence,
  TaskLabors,
  PageSource,
  Recurrence,
  RepeatInterval,
  RealTimeCases,
} from '@ge/models/constants';
import { Capability, TaskTemplateModes } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { WorkStandardsDialog } from '@ge/shared/components/tasks/task-fields/work-standards-dialog';
import { ActionsDetail, ColumnTitle } from '@ge/shared/components/tasks/task-template-shared';
import { AffectedAssetsSection } from '@ge/shared/components/tasks/templates/sections/affected-assets-section/affected-assets-section';
import { TaskTemplate } from '@ge/shared/components/tasks/templates/task-template';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useTaskSource, useRemoveTask, useEditTask, useTaskWorkScope } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { toSiteISOString } from '@ge/shared/util/time-date';

import TabDetailHeader from '../components/tab-detail-header';
import { TabDetailContainer } from '../entity-details-shared';

const TaskDetailWrapper = styled.div`
  margin-right: 32px;
  padding-bottom: 40px;
  .task-panel-row {
    align-items: flex-start;
    display: flex;
    &:last-of-type {
      .column-detail {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

const TaskIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tasks.details.columnButtonColor,
}))`
  position: absolute;
  left: 0;
`;

const FooterButtons = styled.div`
  display: table;
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;
const Footer = styled.div`
  .spinner {
    left: calc(70% - 30px);
    margin-top: -7px;
  }
`;

const DisplayTaskContainer = styled.section``;
const EditTaskContainer = styled.section``;

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 10px 218px;
`;

export const TaskDetailsOverview = ({ task, continueDirtyCheck, setContinueDirtyCheck }) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const {
    taskState: { timezone, taskTemplate },
  } = useContext(TaskContext);

  const [isEditTaskPanel, setIsEditTaskPanel] = useState(false);
  const [isDeleteTaskPanel, setIsDeleteTaskPanel] = useState(false);
  const [isError, setError] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const { notify, dismiss } = useNotification();
  const [workStandardDialog, setWorkStandardDialog] = useState(false);
  const [workStandardsData, setWorkStandardsData] = useState({});
  const [updatedTaskTitle, setUpdatedTaskTitle] = useState('');

  const entityType = task?.asset?.id ? EntityType.ASSET : EntityType.SITE;
  const siteId = task?.asset?.site?.id ?? task?.site?.id;

  const { isLoading, data: taskSources } = useTaskSource({
    entityType: entityType === EntityType.ASSET ? EntityType.ASSET : EntityType.SITE,
    page: PageSource.EXECUTE,
  });

  const { isLoadingWorkScopes, data: taskWorkScopes } = useTaskWorkScope({
    entityType: entityType === EntityType.ASSET ? EntityType.ASSET : EntityType.SITE,
  });

  const { hideDetails, setDirtyFields, setIsWorkStand, isAllEventLoading } =
    useContext(EntityDetailsContext);

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      entityType: entityType,
      taskDetailTitle: task?.title,
      taskDetailDescription: task?.description,
      expectedParts: task?.expectedParts
        ? task.expectedParts
        : [
            {
              number: '',
              name: '',
              quantity: '',
            },
          ],
      recurrence: task?.recurrence?.recurrence ?? TaskRecurrence.NONE,
      recurrenceCycle: task?.recurrence?.recurrenceCycle ?? 1,
      recurrenceCycleType: task?.recurrence?.recurrenceCycleType ?? RepeatInterval.DAYS,
      taskTemplate: {},
      taskSource: task?.source,
      taskWorkScope: task?.workScope,
    },
  });

  const handleWorkStandardData = useCallback(
    (data) => {
      if (Array.isArray(data?.workStandardsData)) {
        if (data?.workStandardsData?.length === 0) {
          setWorkStandardDialog(false);
          return;
        }
      }
      if (data && data?.workStandardsError) {
        setWorkStandardDialog(false);
        setIsWorkStand(false);
        return;
      }
      if (data && data?.workStandardsData) {
        setWorkStandardsData(data?.workStandardsData);
        setIsWorkStand(true);
      }
      setWorkStandardDialog(false);
    },
    [setWorkStandardDialog, setWorkStandardsData, setIsWorkStand],
  );

  useEffect(() => {
    if (continueDirtyCheck) {
      setIsEditTaskPanel(false);
      setWorkStandardsData(null);
      setContinueDirtyCheck(false);
    }
  }, [continueDirtyCheck, setContinueDirtyCheck]);

  useEffect(() => {
    if (isEditTaskPanel) {
      setDirtyFields(methods.formState.isDirty);
    } else {
      setDirtyFields(false);
    }
  }, [methods.formState.isDirty, setDirtyFields, isEditTaskPanel]);

  useEffect(() => {
    const UpdatedTitle = methods.formState?.dirtyFields?.title ? methods.getValues('title') : '';
    const isRealTimeCases = RealTimeCases.includes(task?.source);
    if (methods.formState?.dirtyFields?.title && !UpdatedTitle && isRealTimeCases) {
      methods.reset();
    }
    if (methods.formState?.dirtyFields?.title && UpdatedTitle) {
      setUpdatedTaskTitle(UpdatedTitle);
    }
  }, [methods, methods.formState?.dirtyFields?.title, task?.source]);

  const setEditPanel = (data, result) => {
    let setData = {};
    setError(false);
    if (result?.task?.expectedParts) {
      setData.expectedParts = result?.task?.expectedParts;
    } else {
      setData.expectedParts = data?.expectedParts;
    }
    setData.recurrence = result.task?.recurrence?.recurrence;
    if (setData) methods.reset(setData);
    setIsEditTaskPanel(false);
  };
  const { edit } = useEditTask({
    onSuccess: (data, variables) => {
      dismiss();
      setEditPanel(data, variables);
      setWorkStandardsData(null);
      const message = (
        <NotificationMessage
          message={t('task_notification.task_updation', 'Task Updated Successfully')}
        />
      );
      notify({ message });
    },
    onError: () => {
      setError(true);
    },
  });

  const { remove } = useRemoveTask({
    onSuccess: () => {
      const message = (
        <NotificationMessage
          message={t('task_notification.task_deletion', 'Task Deleted Successfully')}
        />
      );
      notify({ message });
    },
  });

  const onSubmit = useCallback(
    (values) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.updating_task', 'Updating Task')}
          isProgressLoader={true}
        />
      );
      notify({ message });
      const expectedParts = values.expectedParts?.filter(
        (part) => part.number && part.name && part.quantity,
      );
      const dueDate = values?.dueDate ? toSiteISOString(values.dueDate, timezone) : null;
      const eligibleStartDate = values?.eligibleStartDate
        ? toSiteISOString(values.eligibleStartDate, timezone)
        : null;
      const scheduleDate = values?.scheduleDate
        ? toSiteISOString(values.scheduleDate, timezone)
        : null;
      const payload = {
        priority: values.priority,
        title: values.title,
        description: values.description,
        component1: values.component1,
        component2: values.component2,
        component3: values.component3,
        dueDate: dueDate,
        eligibleStartDate: eligibleStartDate,
        estDurationHours: values.estDurationHours || 0,
        estDurationMinutes: values.estDurationMinutes || 0,
        estTechs: values.estTechs || 0,
        laborHours: values.laborHours,
        laborMinutes: values.laborMinutes,
        scheduleDate: scheduleDate,
        startTime: values.startTime,
        recurrence: {
          ...(values.recurrence !== TaskRecurrence.NONE && {
            recurrence: values.recurrence,
            recurrenceType: values.recurrenceType,
            recurrenceEndByDate: dayjs(values.recurrenceEndByDate).isValid()
              ? dayjs(values.recurrenceEndByDate).toISOString()
              : null,
            recurrenceEndByCount: values.recurrenceEndByCount,
            recurrenceCycle:
              values?.recurrence === TaskRecurrence.CUSTOM ? values.recurrenceCycle : null,
            recurrenceCycleType:
              values?.recurrence === TaskRecurrence.CUSTOM ? values.recurrenceCycleType : null,
          }),
        },
        expectedParts: expectedParts?.length ? expectedParts : null,
        workScope: values.taskWorkScope,
      };
      setSpinLoader(true);
      const id = task.id;
      const updatedFields = Object.keys(methods.formState.dirtyFields);
      const updatedPayload = {};
      updatedFields.map((key) => {
        if (
          key === TaskLabors.EST_DURATION_HRS ||
          key === TaskLabors.EST_DURATION_MINUTES ||
          key === TaskLabors.EST_TECHS
        ) {
          updatedPayload.laborHours = payload.laborHours;
          updatedPayload.laborMinutes = payload.laborMinutes;
        }
        if (
          key === Recurrence.RECURRENCE ||
          key === Recurrence.RECURRENCE_TYPE ||
          key === Recurrence.RECURRENCE_ENDBY_DATE ||
          key === Recurrence.RECURRENCE_ENDBY_COUNT ||
          key === Recurrence.RECURRENCE_CYCLE ||
          key === Recurrence.RECURRENCE_CYCLE_TYPE
        ) {
          updatedPayload.recurrence = {
            recurrence: payload.recurrence.recurrence,
            recurrenceType: payload.recurrence.recurrenceType,
            recurrenceEndByDate: payload.recurrence.recurrenceEndByDate,
            recurrenceEndByCount: payload.recurrence.recurrenceEndByCount,
            recurrenceCycle: payload.recurrence.recurrenceCycle ?? task.recurrence.recurrenceCycle,
            recurrenceCycleType:
              payload.recurrence.recurrenceCycleType ?? task.recurrence.recurrenceCycleType,
          };
        }
        return (updatedPayload[key] = payload[key]);
      });

      updatedPayload.crewIds = [values?.assignee];
      if (methods.formState?.dirtyFields?.taskWorkScope) {
        updatedPayload.workScope = values.taskWorkScope;
        delete updatedPayload.taskWorkScope;
      }
      //Remove crewIds if scheduleDate is removed
      if (scheduleDate === null || scheduleDate === undefined) {
        updatedPayload.crewIds = [null];
      }
      if (task?.scheduleDate && !scheduleDate && task?.bundledTasks?.length > 0) {
        updatedPayload.bundledTasks = [];
        updatedPayload.isBundled = false;
      }
      if (Object.keys(updatedPayload).length) {
        edit({ id, task: updatedPayload, bundledTaskIds: task?.bundledTasks });
      }
    },
    [t, notify, timezone, task.id, methods.formState.dirtyFields, edit],
  );

  const onClose = () => {
    setIsDeleteTaskPanel(false);
  };

  const onConfirm = useCallback(async () => {
    const message = (
      <NotificationMessage
        message={t('task_notification.deleting_task', 'Deleting Task')}
        isProgressLoader={true}
      />
    );
    notify({ message });
    const id = task.id;
    await remove({ id });
    setIsDeleteTaskPanel(false);
    hideDetails();
  }, [hideDetails, notify, remove, t, task.id]);

  const getFooter = () => {
    return (
      <FooterButtons>
        <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
        <Button onClick={onConfirm}>{t('general:deletetask', 'Delete Task')}</Button>
      </FooterButtons>
    );
  };

  const getTaskPanelControls = useCallback(() => {
    return (
      <>
        <AuthRender
          capability={Capability.FIELD_TASKS}
          description="Edit button"
          edit
          siteIds={[siteId]}
        >
          <Button
            type="button"
            onClick={() => {
              methods.reset();
              setIsEditTaskPanel(true);
              setError(false);
              setSpinLoader(false);
            }}
          >
            <TaskIcon size={11} icon={Icons.PENCIL} />
            {t('form.edit_task', 'Edit Task')}
          </Button>
        </AuthRender>
        {taskTemplate?.delete && (
          <AuthRender
            capability={Capability.FIELD_TASKS}
            delete
            description="Delete button"
            siteIds={[siteId]}
          >
            <Button type="button" onClick={() => setIsDeleteTaskPanel(true)}>
              <TaskIcon size={13} icon={Icons.TRASH} />
              {t('form.delete_task', 'Delete Task')}
            </Button>
          </AuthRender>
        )}
      </>
    );
  }, [siteId, t, methods, taskTemplate]);

  const getSaveTaskButtons = useCallback(() => {
    return (
      <>
        <div className="actions-left">
          {taskTemplate?.delete && (
            <AuthRender
              capability={Capability.FIELD_TASKS}
              delete
              description="Delete button"
              siteIds={[siteId]}
            >
              <Button type="button" onClick={() => setIsDeleteTaskPanel(true)}>
                {t('form.delete_task', 'Delete Task')}
              </Button>
            </AuthRender>
          )}
        </div>
        <Footer className="actions-right">
          <SpinLoader showLoader={spinLoader} className="spinner" />
          <Button
            type="button"
            onClick={() => {
              setWorkStandardsData(null);
              setIsEditTaskPanel(false);
            }}
          >
            {t('general:cancel', 'Cancel')}
          </Button>
          <Button
            disabled={isAllEventLoading || !methods.formState?.isDirty || spinLoader}
            primary
            type="button"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t('general:save', 'Save')}
          </Button>
        </Footer>
      </>
    );
  }, [isAllEventLoading, methods, onSubmit, siteId, spinLoader, t, taskTemplate?.delete]);

  if (isLoading || isLoadingWorkScopes) return null;

  return (
    <TabDetailContainer>
      <TabDetailHeader entity={task} entityType={EntityType.TASK} />
      <TaskDetailWrapper>
        <FormProvider {...methods}>
          {!isEditTaskPanel && (
            <DisplayTaskContainer>
              <TaskTemplate
                taskPanelControls={getTaskPanelControls()}
                taskSources={taskSources}
                taskWorkScopes={taskWorkScopes}
                templateMode={TaskTemplateModes.VIEW}
                task={task}
              />
            </DisplayTaskContainer>
          )}
          {isEditTaskPanel && (
            <EditTaskContainer>
              {isError && <StyledError />}
              <div className="task-panel-row">
                <ColumnTitle />
                <ActionsDetail className="column-detail actions">
                  {getSaveTaskButtons()}
                </ActionsDetail>
              </div>
              <AffectedAssetsSection
                entityType={entityType}
                task={task}
                templateMode={TaskTemplateModes.EDIT}
              />
              <TaskTemplate
                taskSources={taskSources}
                taskWorkScopes={taskWorkScopes}
                templateMode={TaskTemplateModes.EDIT}
                task={task}
                setWorkStandardDialog={setWorkStandardDialog}
                setWorkStandardsData={setWorkStandardsData}
                workStandardsData={workStandardsData}
              />
              {isError && <StyledError />}
              <div className="task-panel-row">
                <ColumnTitle />
                <ActionsDetail className="column-detail actions">
                  {getSaveTaskButtons()}
                </ActionsDetail>
              </div>
            </EditTaskContainer>
          )}
          {isDeleteTaskPanel && (
            <Dialog
              isOpen={true}
              onClose={onClose}
              onConfirm={onConfirm}
              header={t('task_delete', 'Delete Task Confirmation')}
              footer={getFooter()}
            >
              {t('task_delete_message', 'Are you sure you want to permanently delete this task?')}
            </Dialog>
          )}
          {workStandardDialog && (
            <WorkStandardsDialog
              task={task}
              UpdatedTitle={updatedTaskTitle}
              onConfirm={handleWorkStandardData}
              onClose={(e) => {
                killEventPropagation(e);
                setWorkStandardDialog(false);
                setIsWorkStand(false);
              }}
            />
          )}
        </FormProvider>
      </TaskDetailWrapper>
    </TabDetailContainer>
  );
};

TaskDetailsOverview.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
};
