import { useQuery } from 'react-query';

import { Config } from '@ge/shared/data-hooks';

const SESSION_DATA_REFRESH_INTERVAL = 1000 * 60 * 60 * 24;

export const useSSO = () => {
  const {
    data = null,
    error,
    isLoading,
  } = useQuery(
    ['useSSO'],
    async () => {
      const resp = await fetch('/sso');
      try {
        if (resp.status === 200) {
          const sso = await resp.json();
          return sso;
        }
      } catch (err) {
        console.error(err);
        return '212310120';
      }
      return '212310120';
    },
    {
      ...Config.EXECUTE_ONCE,
      staleTime: SESSION_DATA_REFRESH_INTERVAL,
    },
  );
  return {
    data,
    error,
    isLoading,
  };
};

export const useUserInfo = () => {
  const {
    data = null,
    error,
    isLoading,
  } = useQuery(
    ['useUserInfo'],
    async () => {
      const resp = await fetch('/user_info');
      if (resp.status === 200) {
        const info = await resp.json();
        return info;
      }
    },
    {
      ...Config.EXECUTE_ONCE,
      staleTime: SESSION_DATA_REFRESH_INTERVAL,
    },
  );
  return {
    data,
    error,
    isLoading,
  };
};
