import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

const standardOptions = {
  chart: {
    animation: false,
    backgroundColor: 'transparent',
    spacingTop: 0,
    spacingRight: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    type: 'spline',
  },
  title: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    minPadding: 0,
    maxPadding: 0,
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: 'averages',
      type: 'areaspline',
      lineWidth: 0,
      fillOpacity: '0.2',
    },
    {
      name: 'values',
      lineWidth: 1,
    },
  ],
};

const SparkLine = ({ danger, data, height, lineColor, theme, options }) => {
  if (!data) {
    return null;
  }

  const { sparkLineChart: chartColors } = theme;
  const { areaBg, dangerColor, defaultLineColor } = chartColors;

  if (!lineColor) {
    lineColor = danger ? dangerColor : defaultLineColor;
  }

  const chartOptions = options ? { ...options } : { ...standardOptions };

  chartOptions.chart.height = height;
  chartOptions.series[0].color = areaBg;
  chartOptions.series[1].color = lineColor;

  chartOptions.series[0].data = data.map((v) => v.average);
  chartOptions.series[1].data = data.map((v) => v.value);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

SparkLine.propTypes = {
  danger: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number,
  lineColor: PropTypes.string,
  theme: PropTypes.shape({
    sparkLineChart: PropTypes.instanceOf(Object),
  }).isRequired,
  options: PropTypes.object,
};

SparkLine.defaultProps = {
  danger: false,
  data: null,
  height: 30,
  lineColor: null,
  options: null,
};

export const SparkLineChart = withTheme(SparkLine);
