import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';

// Define the default visible columns and their order.
export const defaultAnomaliesCols = [
  {
    id: AnomaliesColumns.GROUP_SELECTED,
    cols: [
      {
        id: AnomaliesColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_ASSETS,
    cols: [
      {
        id: AnomaliesColumns.TYPE,
        visible: true,
      },
      {
        id: AnomaliesColumns.SITE,
        visible: true,
      },
      {
        id: AnomaliesColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_ANOMALIES,
    cols: [
      {
        id: AnomaliesColumns.COMPONENT,
        visible: true,
      },
      {
        id: AnomaliesColumns.STATUS,
        visible: true,
      },
      {
        id: AnomaliesColumns.ID,
        visible: true,
      },
      {
        id: AnomaliesColumns.DESCRIPTION,
        visible: true,
      },
      {
        id: AnomaliesColumns.PRIORITY,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_NOTES,
    cols: [{ id: AnomaliesColumns.NOTES, visible: true }],
  },
  {
    id: AnomaliesColumns.GROUP_POSSIBLE_ROOT_CAUSES,
    cols: [
      {
        id: AnomaliesColumns.POSSIBLE_ROOT_CAUSES,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_TROUBLESHOOTING,
    cols: [
      {
        id: AnomaliesColumns.TROUBLESHOOTING,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_DUE_DATE,
    cols: [
      {
        id: AnomaliesColumns.DUE_DATE,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_TASKS,
    cols: [
      {
        id: AnomaliesColumns.LINKED,
        visible: true,
      },
      {
        id: AnomaliesColumns.ESCALATED,
        visible: true,
      },
      {
        id: AnomaliesColumns.NEW_TASK,
        visible: true,
      },
      {
        id: AnomaliesColumns.TASK_DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_STATUS_DETAIL,
    cols: [
      {
        id: AnomaliesColumns.STATUS_DETAIL,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_LAST_FLAGGED,
    cols: [
      {
        id: AnomaliesColumns.LAST_FLAGGED,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_CREATE_DATE,
    cols: [
      {
        id: AnomaliesColumns.CREATE_DATE,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_FIXED_DATE,
    cols: [
      {
        id: AnomaliesColumns.FIXED_DATE,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_SERIAL_NUMBER,
    cols: [
      {
        id: AnomaliesColumns.SERIAL_NUMBER,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_ASSET_MAKE,
    cols: [
      {
        id: AnomaliesColumns.ASSET_MAKE,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_ASSET_MODEL,
    cols: [
      {
        id: AnomaliesColumns.ASSET_MODEL,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_CUSTOMER,
    cols: [
      {
        id: AnomaliesColumns.CUSTOMER,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_COUNTRY,
    cols: [
      {
        id: AnomaliesColumns.COUNTRY,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_ANALYTIC_SOURCE,
    cols: [
      {
        id: AnomaliesColumns.ANALYTIC_SOURCE,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_SOURCE,
    cols: [
      {
        id: AnomaliesColumns.SOURCE,
        visible: true,
      },
    ],
  },
  {
    id: AnomaliesColumns.GROUP_CASE_DETAIL,
    cols: [
      {
        id: AnomaliesColumns.CASE_DETAIL,
        visible: true,
      },
    ],
  },
];
