import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TaskTemplateModes } from '@ge/models/constants';

import { DateMetaField } from '../../../../meta-fields/date-meta-field';
import { TimeStrMetaField } from '../../../../meta-fields/time-str-meta-field';
import { AssigneeSelect } from '../../../task-fields/assignee-select';
import {
  FormRow,
  FormSection,
  StyledCollapsiblePanel,
  StyledDateContainer,
} from '../../../task-template-shared';

import { ScheduleErrors } from './schedule-errors';

const StyledFormRow = styled(FormRow)`
  margin-bottom: 0;
  justify-content: flex-start;
`;

const StyledScheduleErrors = styled(ScheduleErrors)`
  margin: 8px 0 10px 0;
`;

//styling changes to be added
const StyledAssigneeContainer = styled.div`
  position: relative;
`;

export const CreateSchedule = ({ collapsed, metadata, rules, templateMode }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const { watch } = useFormContext();
  const watchScheduleDate = watch('scheduleDate');

  return (
    <StyledCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{t('section_header.schedule', 'Schedule')}</h4>}
      plusIcon
    >
      <FormSection>
        <StyledFormRow>
          {!metadata?.scheduleDate.hidden && (
            <StyledDateContainer>
              <div className="label">{t('form.schedule_date', 'Schedule Date')}</div>
              <DateMetaField
                defaultSelection={metadata.scheduleDate?.defaultSelection}
                metadata={metadata.scheduleDate.create}
                name="scheduleDate"
                rules={rules}
                validators={metadata.scheduleDate.create?.validators}
              />
            </StyledDateContainer>
          )}
          {!metadata?.startTime.hidden && (
            <StyledDateContainer>
              <div className="label">{t('form.start_time', 'Start Time')}</div>
              <TimeStrMetaField
                // defaultValue={TasksTime.DEFAULT_START_TIME}
                defaultValue={null}
                metadata={metadata.startTime.create}
                name="startTime"
                rules={{
                  required: watchScheduleDate ? true : false,
                }}
              />
            </StyledDateContainer>
          )}
          {!metadata?.crewIds.hidden && (
            <StyledAssigneeContainer>
              <AssigneeSelect metadata={metadata.crewIds.create} templateMode={templateMode} />
            </StyledAssigneeContainer>
          )}
        </StyledFormRow>
        <StyledScheduleErrors />
      </FormSection>
    </StyledCollapsiblePanel>
  );
};

CreateSchedule.propTypes = {
  collapsed: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
  rules: PropTypes.instanceOf(Object),
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

CreateSchedule.defaultProps = {
  collapsed: true,
  rules: null,
};
