import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import ClearColumnFilter from '@ge/components/table/filters/clear-column-filter';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { NAMESPACE } from '@ge/feat-analyze/models/configure-analysis-template';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { AnaylzeDefs, EntityType, SortDirection } from '@ge/models/constants';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { EntityDetailsContext } from '@ge/shared/context';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { killEventPropagation } from '@ge/shared/util';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';

import { useCaseTemplateTable } from '../../../../data-hooks/use-case-template-table';
import {
  CaseTemplateColumnDefs,
  defaultCaseTemplateColumns,
} from '../../../../models/case-template-table-cols';
import CaseTemplatesTable from '../../../tables/case-templates-table';
import { NewCaseTemplateButton } from '../case-template';

const CASETEMPLATETABLE = AnaylzeDefs.CASETEMPLATETABLE;

export const CaseTemplateMetric = {
  PRIORITY: 'name',
};

const StyledSubNavigation = styled(SubNavigation)`
  margin-top: -2rem;
  div {
    border-bottom: 0px;
  }
`;

export const CaseTemplates = () => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ANALYZE_CONFIGURE_CASE_TEMPLATES);
  const [currentSearch, setCurrentSearch] = useState({});
  const {
    filterSequence,
    filterDefs,
    onChange: setFilterDefs,
    onReset,
  } = useFilterDefs({
    columnDefs: CaseTemplateColumnDefs,
    stateId: AnaylzeDefs.CASE_TEMPLATE_FILTER_STATE_ID,
  });
  const { showDetails } = useContext(EntityDetailsContext);
  const {
    updateColumnVisibility: updateCaseTemplateColumnVisibility,
    setVisibleColumns: setVisibleCaseTemplateCols,
    visibleCols: visibleCaseTemplateCols,
    sortDirection: caseTemplateSortDirection,
    sortMetric: caseTemplateSortMetric,
    updateSortMetric: updatecaseTemplateSortMetric,
  } = useColumnState({
    columnDefs: CaseTemplateColumnDefs,
    defaultCols: defaultCaseTemplateColumns,
    defaultSortMetric: CaseTemplateMetric.PRIORITY,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: AnaylzeDefs.CASE_TEMPLATE_SORT_STATE_ID,
  });

  // data hooks
  let {
    data: caseTemplateData,
    filterValues,
    isCaseTemplateDataLoading,
  } = useCaseTemplateTable({
    filterSequence,
    search: currentSearch,
    filters: filterDefs,
    sortDirection: caseTemplateSortDirection,
    sortMetric: caseTemplateSortMetric,
  });

  const { onVisibilityChange: handleCaseTemplateColumnChanges, onSelectionVisibilityChange } =
    useManageColumnVisibility({
      subKey: CASETEMPLATETABLE,
      featPrefs,
      savePrefs,
      setVisibleColumns: setVisibleCaseTemplateCols,
      updateColumnVisibility: updateCaseTemplateColumnVisibility,
    });

  const handleEntitySelect = useCallback(
    (e, selectedTemplate) => {
      killEventPropagation(e);
      showDetails(selectedTemplate.id, EntityType.CASE_TEMPLATE);
    },
    [showDetails],
  );

  /**
   * TODO: should open DL details on row select
   * (e,row)
   */
  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  return (
    <PageContainer i18nReady={ready}>
      <StyledSubNavigation navItems={[]}>
        <ClearColumnFilter filters={filterDefs} onReset={onReset} />
        <NewCaseTemplateButton />
        <ColumnSelectMenu
          translateFn={t}
          columnDef={CaseTemplateColumnDefs}
          columnState={visibleCaseTemplateCols}
          onMenuClose={handleCaseTemplateColumnChanges}
          onClumnSelection={onSelectionVisibilityChange}
        />
      </StyledSubNavigation>
      <CaseTemplatesTable
        columns={visibleCaseTemplateCols}
        caseTemplateData={caseTemplateData}
        sortAction={updatecaseTemplateSortMetric}
        sortMetric={caseTemplateSortMetric}
        sortDirection={caseTemplateSortDirection}
        onDrop={(columnValue) => savePrefs(columnValue, CASETEMPLATETABLE)}
        onFilter={setFilterDefs}
        onFilterChange={handleFilterChange}
        draggable
        resizable
        isLoading={isCaseTemplateDataLoading}
        filterValues={filterValues}
        filterDefs={filterDefs}
        showCaseTemplateDetails={handleEntitySelect}
      />
    </PageContainer>
  );
};

export default CaseTemplates;
