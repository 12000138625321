import { ErrorMessage } from '@hookform/error-message';
import { PropTypes } from 'prop-types';
import React, { useState, useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { placements, Tooltip } from '@ge/components/tooltip';
import { ErpDynamicFieldName, ErpDynamicFieldType } from '@ge/models/constants';
import { ErpType } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';
import { StatusColor } from '@ge/tokens/colors/colors';
import { elevations } from '@ge/tokens/elevations';

import { CheckBoxGroupMetaField, RadioGroupMetaField } from '../../../../meta-fields';
import { DateMetaField } from '../../../../meta-fields/date-meta-field';
import { SelectMetaField } from '../../../../meta-fields/select-meta-field';
import { TextMetaField } from '../../../../meta-fields/text-meta-field';
import { TextareaMetaField } from '../../../../meta-fields/textarea-meta-field';
import { ShippingAddress } from '../../../service-request-fields/service-request-shipping';

const SectionContainer = styled.div`
  width: 100%;

  // making sure the select menus don't go out of their container
  // when they're displayed above the select menu button
  .select__menu-list {
    max-height: 200px;
  }
`;

const SectionHeading = styled.h4`
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
  width: 100%;
`;

const SectionRowSmall = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
  width: 40%;
`;

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-left: 40px;

  &:first-child {
    margin-left: 0;
  }

  & label {
    // TODO: add theme values for ERP tab or refactor to unify theme values used commonly across other tabs?
    color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
  }

  & input[type='text']:disabled {
    background: transparent;
    padding-left: 0;
  }
`;

const StyledErrorMessage = styled.span`
  color: ${StatusColor.DANGER};
  margin-top: 5px;

  &:before {
    content: '\\2296'; // &ominus;
    font-size: 13px;
    padding-right: 2px;
  }
`;

const IconContainer = styled.span`
  margin-left: 10px;
`;

const SelectMetaFieldDropdown = styled(SelectMetaField)`
  .select__menu {
    .select__menu-list {
      max-height: 200px;
      &::-webkit-scrollbar {
        width: 4px;
        height: 0px;
      }
      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.scrollbar.trackBackground};
      }
      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollbar.thumbBackground};
        border-radius: 2.5px;
      }
    }
  }
`;

const StyledTextareaMetaField = styled(TextareaMetaField)`
  &::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const StyledRadioGroupMetaField = styled(RadioGroupMetaField)`
  label {
    color: ${(props) => props.theme.input.textColor};
  }
`;

const StyledCheckboxGroup = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    > label {
      margin: 10px 20px 0px 0px;
      color: ${(props) => props.theme.input.textColor};
    }
  }
`;

const StyledTextMetaField = styled(TextMetaField)`
  color: ${(props) => props.theme.menu.tableBodyTextColor};
  font-family: 'Museo Sans';
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTitle = styled.div`
  background: ${({ theme }) => theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.actionsMenu.textColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-task-count {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`;

const TaskRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.menu.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;

  &:first-of-type {
    border: none;
  }
`;

const TaskTitle = styled.div`
  color: ${(props) => props.theme.menu.actionsMenu.textColor};
  font-family: 'Museo Sans';
  font-size: 11px;
  line-height: 13px;
`;

const DetailColumn = styled.div`
  width: 100%;
`;

const MenuContent = styled.div`
  display: flex;
  height: ${({ heightOffset }) =>
    heightOffset === 1 ? '40px' : `calc(200px - ${230 / heightOffset}px)`};
  overflow: hidden;
`;

export const CreateDynamicSection = ({
  title = '',
  metadataByRows,
  erpType = ErpType.SERVICE_REQUEST,
  hideHeading,
  bulkTask,
  task,
  isTaskSelected,
}) => {
  const [anchorEll, setAnchorEll] = useState(null);
  const containerRef = useRef(null);
  const { errors, setValue } = useFormContext();
  const { t } = useTranslation(['tasks']);
  const filteredSRTask = bulkTask?.filter((task) => task.srNumber === '-');
  const handleSrTypeChange = () => {
    setValue(ErpDynamicFieldName.MAJOR_COMPONENT_SUPPLIER, '');
    setValue(ErpDynamicFieldName.PROBLEM_CODE, '');
  };

  const handleTags = (e) => {
    if (filteredSRTask?.length > 0) {
      killEventPropagation(e);
      setAnchorEll(e.currentTarget);
    } else {
      setAnchorEll(null);
    }
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  const handleMenuClose = () => setAnchorEll(null);

  const tagName = useMemo(() => {
    if (task?.asset?.name) {
      return `${task.site.name} | ${task.asset.name}`;
    } else {
      return task.site.name;
    }
  }, [task]);

  const getSiteAssetName = useMemo(() => {
    const tagEntries = filteredSRTask?.map((task) => {
      if (task?.asset?.name) {
        return `${task?.site?.name} | ${task?.asset?.name}`;
      } else {
        return task?.site?.name;
      }
    });
    return [...new Set(tagEntries)];
  }, [filteredSRTask]);

  const TagsMenu = () => {
    const { t } = useTranslation(['tasks']);

    const TagItems = getSiteAssetName?.map((tag, idx) => (
      <TaskRow key={`${tag}-${idx}`}>
        <DetailColumn>
          <TaskTitle>{tag}</TaskTitle>
        </DetailColumn>
      </TaskRow>
    ));

    return (
      <>
        <StyledTitle>
          <h4 className="menu-title-txt">{t('menu.title_tag', 'Tag')}</h4>
        </StyledTitle>
        {bulkTask?.length >= 1 ? (
          <MenuContent heightOffset={getSiteAssetName.length}>
            <ScrollingContainer>
              <Content>{TagItems}</Content>
            </ScrollingContainer>
          </MenuContent>
        ) : (
          <Content>
            <TaskRow>
              <DetailColumn>
                <TaskTitle>{t('menu.no_data', 'No Data')}</TaskTitle>
              </DetailColumn>
            </TaskRow>
          </Content>
        )}
      </>
    );
  };

  const getGenericErrorMessage = (metadataField) => {
    switch (metadataField.type) {
      case ErpDynamicFieldType.LABEL:
      case ErpDynamicFieldType.TEXT:
      case ErpDynamicFieldType.TEXTAREA:
      case ErpDynamicFieldType.DATE:
      case ErpDynamicFieldType.ADDRESS:
        return t('form.please_provide_value', { fieldName: metadataField.title });
      case ErpDynamicFieldType.DROPDOWN:
        return t('form.please_select_an_option');
      case ErpDynamicFieldType.CHECKBOX:
      default:
        return null;
    }
  };

  const getDynamicField = (metadataKey, metadataField) => {
    switch (metadataField.type) {
      case ErpDynamicFieldType.LABEL:
      case ErpDynamicFieldType.TEXT:
        return metadataKey !== 'assetSerialNumber' ? (
          <>
            <TextMetaField
              defaultValue={metadataField.defaultSelection}
              label={metadataField.title}
              mask={metadataField.mask}
              pattern={metadataField.create.validators?.[0]}
              minLength={metadataField.minLength}
              maxLength={metadataField.maxLength}
              metadata={metadataField.create}
              name={metadataKey}
            />
            <ErrorMessage
              errors={errors}
              name={metadataKey}
              render={({ message }) => (
                <StyledErrorMessage role="alert">
                  {message || getGenericErrorMessage(metadataField)}
                </StyledErrorMessage>
              )}
            />
          </>
        ) : bulkTask?.length >= 1 && !isTaskSelected ? (
          <div onMouseEnter={(e) => handleTags(e)} onMouseLeave={handleMenuClose}>
            <StyledTextMetaField
              defaultValue={getSiteAssetName?.length > 0 ? getSiteAssetName.toString() : '-'}
              label={metadataField.title}
              mask={metadataField.mask}
              pattern={metadataField.create.validators?.[0]}
              minLength={metadataField.minLength}
              maxLength={metadataField.maxLength}
              metadata={metadataField.create}
              name={metadataKey}
            />
            <ErrorMessage
              errors={errors}
              name={metadataKey}
              render={({ message }) => (
                <StyledErrorMessage role="alert">
                  {message || getGenericErrorMessage(metadataField)}
                </StyledErrorMessage>
              )}
            />
            <Menu
              anchorEl={anchorEll}
              open={Boolean(anchorEll)}
              container={containerRef}
              onClose={handleMenuClose}
              onClick={handleMenuClick}
              placement={placements.BOTTOM_END}
              width={300}
            >
              <TagsMenu titleDefault="Tag" />
            </Menu>
          </div>
        ) : (
          <Tooltip title={tagName} zIndex={elevations.P20} placement={placements.TOP}>
            <div>
              <StyledTextMetaField
                defaultValue={tagName || metadataField.defaultSelection}
                label={metadataField.title}
                mask={metadataField.mask}
                pattern={metadataField.create.validators?.[0]}
                minLength={metadataField.minLength}
                maxLength={metadataField.maxLength}
                metadata={metadataField.create}
                name={metadataKey}
              />
              <ErrorMessage
                errors={errors}
                name={metadataKey}
                render={({ message }) => (
                  <StyledErrorMessage role="alert">
                    {message || getGenericErrorMessage(metadataField)}
                  </StyledErrorMessage>
                )}
              />
            </div>
          </Tooltip>
        );
      case ErpDynamicFieldType.TEXTAREA:
        return (
          <>
            <StyledTextareaMetaField
              defaultValue={metadataField.defaultSelection}
              label={metadataField.title}
              maxLength={metadataField.maxLength}
              metadata={metadataField.create}
              name={metadataKey}
            />
            <ErrorMessage
              errors={errors}
              name={metadataKey}
              render={({ message }) => (
                <StyledErrorMessage role="alert">
                  {message || getGenericErrorMessage(metadataField)}
                </StyledErrorMessage>
              )}
            />
          </>
        );
      case ErpDynamicFieldType.DROPDOWN:
        // can revisit what rules define disabled behavior
        return (
          <>
            {metadataField.title === 'SR Type' ? (
              <SelectMetaFieldDropdown
                disabled={!metadataField.options?.length}
                placeholder={metadataField.placeholder}
                defaultValue={metadataField.defaultSelection}
                label={metadataField.title}
                metadata={metadataField.create}
                name={metadataKey}
                options={metadataField.options}
                handleChange={handleSrTypeChange}
                searchable
              />
            ) : (
              <SelectMetaFieldDropdown
                disabled={!metadataField.options?.length}
                placeholder={metadataField.placeholder}
                defaultValue={metadataField.defaultSelection}
                label={metadataField.title}
                metadata={metadataField.create}
                name={metadataKey}
                options={metadataField.options}
                searchable
              />
            )}
            <ErrorMessage
              errors={errors}
              name={metadataKey}
              render={({ message }) => (
                <StyledErrorMessage role="alert">
                  {message || getGenericErrorMessage(metadataField)}
                </StyledErrorMessage>
              )}
            />
          </>
        );
      case ErpDynamicFieldType.DATE:
        return (
          <>
            <label htmlFor={metadataKey}>
              {metadataField.title}
              {metadataField.create.required && '*'}
              {!!metadataField.hint && (
                <Tooltip
                  title={metadataField.hint}
                  placement={placements.RIGHT}
                  zIndex={elevations.P20}
                >
                  <IconContainer>
                    <Icon icon={Icons.HELP} size={14} color={globalColors.slate4} />
                  </IconContainer>
                </Tooltip>
              )}
            </label>
            <DateMetaField
              defaultSelection={metadataField.defaultSelection}
              defaultValue={metadataField.defaultValue}
              metadata={metadataField.create}
              validators={(metadataField.create.validators, [{ earliestDate: 'currentDate' }])}
              name={metadataKey}
            />
            <ErrorMessage
              errors={errors}
              name={metadataKey}
              render={({ message }) => (
                <StyledErrorMessage role="alert">
                  {message || getGenericErrorMessage(metadataField)}
                </StyledErrorMessage>
              )}
            />
          </>
        );
      case ErpDynamicFieldType.RADIO:
        return (
          <>
            <label htmlFor={metadataKey}>
              {metadataField.title}
              {metadataField.create.required && '*'}
            </label>
            <StyledRadioGroupMetaField
              name={metadataKey}
              required={metadataField.create.required}
              defaultValue={metadataField.defaultSelection}
              choices={metadataField.options}
              margin={
                metadataField.title === 'High Risk Activity(HRA)'
                  ? `5px 10px 0px 0px`
                  : `10px 40px 16px 0px`
              }
            />
            <ErrorMessage
              errors={errors}
              name={metadataKey}
              render={({ message }) => (
                <StyledErrorMessage role="alert">
                  {message || getGenericErrorMessage(metadataField)}
                </StyledErrorMessage>
              )}
            />
          </>
        );
      case ErpDynamicFieldType.ADDRESS:
        // Only supporting Shipping Address field for now
        return (
          metadataField.shippingAddressTitle && (
            <>
              <ShippingAddress name={metadataKey} metadata={metadataField} />
              <ErrorMessage
                errors={errors}
                name={metadataKey}
                render={({ message }) => (
                  <StyledErrorMessage role="alert">
                    {message ||
                      getGenericErrorMessage({
                        type: metadataField.type,
                        title: metadataField.shippingAddressTitle,
                      })}
                  </StyledErrorMessage>
                )}
              />
            </>
          )
        );
      case ErpDynamicFieldType.CHECKBOX:
        return (
          <>
            <label htmlFor={metadataKey}>
              {metadataField.title}
              {metadataField.create.required && '*'}
            </label>
            <StyledCheckboxGroup>
              <CheckBoxGroupMetaField
                name={metadataKey}
                required={metadataField.create.required}
                defaultValue={metadataField.defaultSelection}
                choices={metadataField.options}
                margin={`5px 0px`}
              />
            </StyledCheckboxGroup>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <SectionContainer>
      {hideHeading && <SectionHeading>{title}</SectionHeading>}
      {metadataByRows.map((metadataRow, rowIndex) => {
        let Row = SectionRow;
        if (erpType === ErpType.WORK_ORDER) {
          if (rowIndex === 0) {
            Row = SectionRowSmall;
          }
        }

        return (
          <Row key={rowIndex}>
            {metadataRow.map((metadataColumn, columnIndex) => {
              return (
                <SectionColumn key={columnIndex}>
                  {getDynamicField(...metadataColumn)}
                </SectionColumn>
              );
            })}
          </Row>
        );
      })}
    </SectionContainer>
  );
};

CreateDynamicSection.propTypes = {
  metadata: PropTypes.object.isRequired,
  metadataByRows: PropTypes.array.isRequired,
  title: PropTypes.string,
  task: PropTypes.object.isRequired,
  erpType: PropTypes.string,
  erpData: PropTypes.object,
  hideHeading: PropTypes.bool,
  bulkTask: PropTypes.array,
  isTaskSelected: PropTypes.bool,
};
