import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { ChartBorder } from '@ge/components/charts';
import { Icon, Icons } from '@ge/components/icon';
import { Select } from '@ge/components/select';
import { fetchSiteMetricsByRegion } from '@ge/shared/services/region';
import { getStatusAverageColor } from '@ge/shared/util/status-colors';

import { regionSparkOptions } from './region-spark-chart-options';

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.analyze.regionSparkLineChart.chevronColor,
  onClick: props.onClick,
}))``;

const IconButton = styled.button`
  margin: 0 5px;
  padding: 0;
`;

const ChartContainer = styled(ChartBorder)`
  .chart-settings {
    display: flex;
    margin-bottom: 6px;
    select:first-child {
      margin-left: 4px;
    }
    .label {
      font-size: 11px;
      margin: 5px 0 0 auto;
      text-transform: uppercase;
    }
  }
  .month-controller {
    text-align: center;
    font-size: 11px;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: 10px;
`;

const StyledSparkChart = React.memo(function Chart(props) {
  const [chartData, setChartData] = useState(null);
  const { t } = useTranslation(['analyze.dashboard']);
  const chartOptions = { ...regionSparkOptions };
  const dayOfMonth = dayjs().date();

  const {
    theme: {
      analyze: { regionSparkLineChart },
    },
    regionId,
  } = props;

  const fetchData = async () => {
    const end = dayjs().toISOString();
    const start = dayjs().subtract(7, 'day').toISOString();
    try {
      const assets = await fetchSiteMetricsByRegion(regionId, 'availability', start, end, '24h');
      setChartData(assets);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // fetch chart data on intial load.
  useEffect(() => {
    if (chartData === null) {
      fetchData();
    }
  });

  if (chartData && chartData.length > 0) {
    chartData.map((series) =>
      chartOptions.series.push({
        data: series.data.map((d) => d.avail),
        color: getStatusAverageColor(series.mean_avail),
        lineWidth: 1,
        zoneAxis: 'x',
        id: series.id || null,
        zones: [
          {
            value: dayOfMonth,
          },
          {
            dashStyle: 'dash',
          },
        ],
      }),
    );
  }

  chartOptions.xAxis.gridLineColor = regionSparkLineChart.xAxisGridLineColor;
  chartOptions.xAxis.lineColor = regionSparkLineChart.xAxisLineColor;
  chartOptions.xAxis.tickColor = regionSparkLineChart.xAxisLineColor;
  chartOptions.xAxis.plotLines[0].color = regionSparkLineChart.xAxisPlotLineColor;
  chartOptions.xAxis.plotLines[0].label.style.fill = regionSparkLineChart.xAxisPlotLineColor;
  chartOptions.yAxis.gridLineColor = regionSparkLineChart.yAxisGridLineColor;

  const selectOptions1 = [
    { value: 'lowest_performing', label: t('select.lowest_performing', 'Lowest Performing') },
  ];
  const selectOptions2 = [
    { value: 'availability_trend', label: t('select.availability_trend', 'Availability Trend') },
  ];

  return (
    <ChartContainer>
      <div className="chart-settings">
        <StyledSelect maxWidth={160} small value={selectOptions1[0]} options={selectOptions1} />
        <StyledSelect maxWidth={160} small value={selectOptions2[0]} options={selectOptions2} />
        <div className="label">
          {t('select.time_based_availability', 'time based availability')}
          (%)
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <div className="month-controller">
        <IconButton type="button">
          <ChevronIcon rotate={90} />
        </IconButton>
        {dayjs().format('MMMM')}
        <IconButton type="button">
          <ChevronIcon rotate={-90} />
        </IconButton>
      </div>
    </ChartContainer>
  );
});

StyledSparkChart.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  regionId: PropTypes.string,
};

StyledSparkChart.defaultProps = {
  regionId: null,
};

export const RegionSparkChart = withTheme(StyledSparkChart);
