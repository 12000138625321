import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  ScrollingBar,
  StyledDiv,
  StyledLinkify,
  ViewMoreIcon,
} from '@ge/feat-admin/components/handling-procedures/hp-styled-components';

export const EditButton = withTheme(({ theme, ...rest }) => {
  const { iconColorActive } = theme.themeSelector;
  return (
    <button {...rest}>
      <Icon icon={Icons.PENCIL} size={14} color={iconColorActive} />
    </button>
  );
});
export const DeleteButton = withTheme(({ theme, label = '', ...rest }) => {
  return (
    <button {...rest}>
      <Icon icon={Icons.TRASH} size={14} color={theme} />
      {label && <label>{label}</label>}
    </button>
  );
});
/**
 * @CONSTANTS
 */
export const HLP_ACTIONS = {
  VIEW: 'view',
  EDIT: 'edit',
  NEW: 'new',
};
/**
 * @HOOKS
 */
export const useMode = () => {
  const { search, pathname } = useLocation();
  const res = useMemo(() => {
    if (search) {
      const params = new URLSearchParams(search);
      // eslint-disable-next-line no-unsafe-optional-chaining
      const [mode, id] = params.get('mode')?.split('__');
      return { action: mode, id, pathname };
    }
    return {};
  }, [pathname, search]);

  return res;
};

export const DEFAULT_DROPDOWN_VALUE = {
  label: '',
  value: '',
};

export const DEFAULT_EVENT_PROPS = {
  EventMap: DEFAULT_DROPDOWN_VALUE,
  EventCode: DEFAULT_DROPDOWN_VALUE,
  name: '',
};

export const SeeMoreDescription = ({ children, charLimit, className }) => {
  const text = children;
  const { t } = useTranslation(['entity-details']);
  const [isSeeMore, setIsSeeMore] = useState(false);

  const toggleSeeMore = useCallback(() => {
    setIsSeeMore(!isSeeMore);
  }, [isSeeMore]);

  return (
    <StyledDiv className={className}>
      <ScrollingBar>
        <ScrollingContainer>
          <span>
            {isSeeMore ? (
              <span className="bulk-assets">
                <StyledLinkify>{text}</StyledLinkify>
              </span>
            ) : (
              <span>
                <StyledLinkify>{text?.slice(0, charLimit)}</StyledLinkify>
                {text?.length > charLimit && ' ...'}
              </span>
            )}
          </span>
          <span onClick={toggleSeeMore} className="toggle-icon">
            {text?.length > charLimit &&
              (isSeeMore ? (
                <>
                  <ViewMoreIcon rotate={-180} />
                  <span>{t('escalate_case.show_less', 'show less')}</span>
                </>
              ) : (
                <>
                  <ViewMoreIcon /> <span>{t('escalate_case.show_more', 'show more')}</span>
                </>
              ))}
          </span>
        </ScrollingContainer>
      </ScrollingBar>
    </StyledDiv>
  );
};

SeeMoreDescription.propTypes = {
  children: PropTypes.string,
  charLimit: PropTypes.number,
  className: PropTypes.string,
};

SeeMoreDescription.defaultProps = {
  charLimit: 55,
  children: '',
  className: '',
};
