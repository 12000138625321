import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { globalColors } from '@ge/tokens/colors';

import { DLCheckboxes } from './dl-checkboxes';
import { DLdropdowns } from './dl-dropdowns';
import { DLMailFields } from './dl-mails';

const FormContainer = styled.div`
  background: ${(props) => props.theme.distributionListSidePanel.formBackground};
  .container1 {
    padding: 20px 20px 0px 3.6em;
  }
  .container2 {
    padding: 20px 10px 20px 3.6em;
  }
  .hidden {
    display: none;
  }
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.distributionListSidePanel.defaultTextLabel};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
const StyledMailLabel = styled.div`
  color: ${(props) => props.theme.distributionListSidePanel.defaultTextLabel};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-bottom: 35px;
  padding-left: 4px;
`;

const InputWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};
  div {
    flex-grow: 1;
    &:first-of-type {
      flex-grow: 0;
      width: 192px;
      margin-right: 20px;
    }
    label {
      font: 500 11px/18px Museo Sans;
    }

    .label-cls {
      color: ${globalColors.grey4};
      font: 500 11px/18px Museo Sans;
    }
  }
`;

const OverviewWrap = styled.div`
  margin: 1em 0;

  .edit-icon-cls {
    float: right;
    cursor: pointer;
  }
`;

const ErrorName = styled.div`
  color: ${globalColors.red1};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  padding-top: 5px;
`;

export const CreateDLForm = ({
  type,
  mails,
  setMails,
  isTabbed,
  details,
  currentTab,
  isExistedName,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['admin.configure']);
  const { control } = useFormContext();

  const { getAssetById, getAllAssetsBySiteIds } = useStoreState((state) => state.assets);
  const { getSiteById } = useStoreState((state) => state.sites);

  useEffect(() => {
    if (details) {
      const mails = [];
      const distProps = details?.distProperties;

      if (distProps) {
        Object.keys(distProps).forEach((key) => {
          if (key !== 'name' && key !== 'replyToList' && distProps[key]?.length > 0) {
            distProps[key].forEach((rec) => mails.push({ type: key.replace('List', ''), ...rec }));
          }
        });
      }

      control.setValue('name', details?.name);
      control.setValue('desc', details?.desc);
      control.setValue('mails', mails);
    }
  }, [details]);

  useEffect(() => {
    const data = details?.entity;
    if (data) {
      const vals = { sites: [], assets: [] };

      data.forEach((rec) => {
        if (rec?.siteId) {
          vals.sites.push(rec.siteId);

          if (rec?.assetId) {
            vals.assets = [...vals.assets, ...rec.assetId];
          }
        }
      });

      vals.sites = [...new Set(vals.sites)];
      vals.assets = [...new Set(vals.assets)];

      const assets =
        details?.entity?.length === 1 && details?.entity[0]?.assetId?.length
          ? formatData(vals.assets.map((item) => getAssetById(item)))
          : details?.entity?.length === 1 && details?.entity[0]?.assetId?.length === 0
          ? []
          : formatData(getAllAssetsBySiteIds(vals.sites));
      control.setValue('sites', formatData(vals.sites.map((item) => getSiteById(item))));
      control.setValue('assets', assets);
    }
  }, []);

  const formatData = (arg) => {
    if (!arg || !arg.length) return [];
    return arg.map((val) => ({
      label: val && val.name ? val.name : '',
      value: val && val.id ? val.id : '',
    }));
  };

  return (
    <FormContainer>
      <div className={isTabbed && currentTab === '2' ? 'hidden' : 'container1'}>
        <OverviewWrap>
          {type === 'Edit' ? <StyledLabel>{t('overview', 'OVERVIEW')}</StyledLabel> : ''}
          {type === 'View' ? (
            <>
              <div
                className="edit-icon-cls"
                onClick={() => {
                  history.push({ pathname: location.pathname, search: `?mode=edit` });
                }}
              >
                <Icon size={12} icon={Icons.PENCIL} color={'White'} />
              </div>
              <StyledLabel>{t('list_overview', 'LIST OVERVIEW')}</StyledLabel>
            </>
          ) : (
            ''
          )}
        </OverviewWrap>

        <InputWrapper>
          <Controller
            control={control}
            name="name"
            defaultValue={''}
            rules={{ required: true, validate: (val) => /^[^!-/:-@[-`{-~]+$/.test(val) }}
            render={({ onChange, onBlur, value }, { invalid }) =>
              type === 'view' ? (
                <div>
                  <p className="label-cls">List Name</p>
                  <p>{value}</p>
                </div>
              ) : (
                <Input
                  required
                  error={invalid ? 'DL name is invalid' : ''}
                  label={t('DL_form_fields.listName', 'List Name')}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="text"
                  value={value}
                />
              )
            }
          />
          <Controller
            control={control}
            name="desc"
            defaultValue={''}
            render={({ onChange, onBlur, value }) =>
              type === 'view' ? (
                <div>
                  <p className="label-cls">Description</p>
                  <p>{value}</p>
                </div>
              ) : (
                <Input
                  label={t('DL_form_fields.description', 'Description')}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="text"
                  value={value}
                  maxLength="250"
                />
              )
            }
          />
        </InputWrapper>

        <StyledLabel>{t('apply_to', 'APPLY TO')}</StyledLabel>
        {isExistedName ? (
          <ErrorName>
            {t('DL_form_fields.name_unique_error', 'Name already exists for this selection')}
          </ErrorName>
        ) : null}
        <DLdropdowns data={details?.entity} type={type} />
        <DLCheckboxes data={details?.distributionType} />
      </div>

      <div
        className={(isTabbed && currentTab === '2') || type === 'Edit' ? 'container2' : 'hidden'}
      >
        {type === 'Edit' ? <StyledMailLabel>{t('recipients', 'RECIPIENTS')}:</StyledMailLabel> : ''}
        <DLMailFields mails={mails} setMails={setMails} type={type} />
      </div>
    </FormContainer>
  );
};

CreateDLForm.propTypes = {
  currentTab: PropTypes.string,
  isTabbed: PropTypes.bool,
  type: PropTypes.string,
  details: PropTypes.object,
  mails: PropTypes.array,
  setMails: PropTypes.func,
  isExistedName: PropTypes.bool,
};
