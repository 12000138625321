import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NAMESPACE = 'analyze.dashboard';

const Container = styled.div`
  margin: 0 1px 15px 0;
`;

const ToggleText = styled.span`
  color: ${({ active, theme }) =>
    active ? theme.subNavigation.linkActiveColor : theme.subNavigation.linkColor};
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
`;

const Seperator = styled.span`
  color: ${({ theme }) => theme.subNavigation.linkColor};
  margin-left: 5px;
  cursor: default;
`;

export const DashboardIECDataToggle = ({ namespace, toggleTypes, defaultType, onChange }) => {
  const { ready, t } = useTranslation([namespace], {
    useSuspense: false,
  });
  const [active, setActive] = useState(defaultType);

  const handleClick = useCallback(
    (type) => {
      setActive(type);
      onChange(type);
    },
    [setActive, onChange],
  );

  if (!ready) {
    return null;
  }

  return (
    <Container>
      {toggleTypes?.map((type, index) => (
        <React.Fragment key={type}>
          {index !== 0 && <Seperator>{t('sperator', '|')}</Seperator>}
          <ToggleText active={active === type} onClick={() => handleClick(type)}>
            {t(`data_toggle.${type}`)}
          </ToggleText>
        </React.Fragment>
      ))}
    </Container>
  );
};

DashboardIECDataToggle.propTypes = {
  namespace: PropTypes.string,
  toggleTypes: PropTypes.arrayOf(PropTypes.string),
  defaultType: PropTypes.string,
  onChange: PropTypes.func,
};

DashboardIECDataToggle.defaultProps = {
  namespace: NAMESPACE,
  toggleTypes: [],
  onChange: () => {},
};

DashboardIECDataToggle.displayName = 'DashboardIECDataToggle';

export default DashboardIECDataToggle;
