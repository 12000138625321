import { computed, thunk, action } from 'easy-peasy';
import concat from 'ramda/src/concat';
import flatten from 'ramda/src/flatten';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import values from 'ramda/src/values';

import merge from '@ge/util/deep-merge';

import { fetchAssetTickets } from '../services/asset';
import { fetchEventTickets } from '../services/event';
import { fetchTicketById } from '../services/ticket';
import { groupByEventId } from '../util/general';

// Define initial state
const defaultTicketsState = {
  tickets: {},
};

// Actions
const ticketActions = {
  // eslint-disable-next-line no-unused-vars
  resetTickets: action((state) => {
    state = Object.assign(state, defaultTicketsState);
  }),

  fetchTicketById: thunk(async (actions, payload, { fail }) => {
    try {
      const { ticket } = await fetchTicketById(payload);
      if (ticket) {
        actions.updateTickets({ [ticket.event.id]: [ticket] });
      }
    } catch (err) {
      fail(err);
    }
  }),

  fetchTicketsByEvent: thunk(async (actions, eventId, { fail }) => {
    try {
      const { tickets } = await fetchEventTickets(eventId);
      actions.updateTickets({ [eventId]: tickets });
    } catch (err) {
      fail(err);
    }
  }),

  fetchTicketsByAsset: thunk(async (actions, assetId, { fail }) => {
    try {
      const { tickets } = await fetchAssetTickets(assetId);
      actions.updateTickets(groupByEventId(tickets));
    } catch (err) {
      fail(err);
    }
  }),

  updateTickets: action((state, ticketsGrouped) => {
    forEachObjIndexed((tickets, eventId) => {
      const existing = state.tickets[eventId];
      state.tickets[eventId] = !existing ? tickets : merge(existing, tickets);
    }, ticketsGrouped);
  }),
};

// Listeners
const ticketsListeners = {};

// Computed values
const ticketsComputed = {
  getTicketsByAsset: computed((state) => (assetId, openOnly) => {
    const allTicketGroups = values(state.tickets);
    const filterFn = (ticket) => {
      if (openOnly && ticket.status !== 'open') {
        return false;
      }
      return ticket.asset.id === assetId;
    };
    const reducer = (memo, tickets) => concat(memo, tickets.filter(filterFn));

    return allTicketGroups.reduce(reducer, []);
  }),

  getTicketsByEvent: computed((state) => (eventId) => state.tickets[eventId]),

  getTicketById: computed((state) => (ticketId) => {
    const allTicketGroups = flatten(values(state.tickets));
    return allTicketGroups.find((ticket) => ticket.id === ticketId);
  }),
};

const monitorEventsModel = {
  ...defaultTicketsState,
  ...ticketActions,
  ...ticketsComputed,
  ...ticketsListeners,
};

export default monitorEventsModel;
