import { action, thunk, computed, thunkOn, persist } from 'easy-peasy';
import mergeDeepRight from 'ramda/src/mergeDeepRight';

import { fetchPrefs, savePrefs } from '@ge/shared/services/common';

import { darkTheme } from '../../assets/themes/dark';
import { lightTheme } from '../../assets/themes/light';
import { Language } from '../models/language';
import { Theme } from '../models/theme';

// Default state to apply on initialization or reset.
const defaultPrefsState = {
  ...persist(
    { theme: Theme.DARK, language: Language.EN, featurePrefs: {} },
    {
      storage: 'localStorage',
    },
    {
      isError: false,
    },
  ),
};

const prefsActions = {
  // eslint-disable-next-line no-unused-vars
  resetPrefs: action((state) => {
    state = Object.assign(state, defaultPrefsState);
  }),

  setPrefs: action((state, payload) => {
    state = Object.assign(state, mergeDeepRight(defaultPrefsState, payload));
  }),

  setLanguage: action((state, payload) => {
    state.language = payload;
  }),

  setTheme: action((state, payload) => {
    state.theme = payload;
  }),

  setError: action((state, payload) => {
    state.isError = payload;
  }),

  setFeaturePrefs: action((state, payload) => {
    const { feature, value } = payload;
    state.featurePrefs[feature] = value;
  }),

  toggleTheme: thunk((actions, _, { getStoreState }) => {
    const newTheme = getStoreState().prefs.theme === Theme.DARK ? Theme.LIGHT : Theme.DARK;
    actions.setTheme(newTheme);
  }),

  fetchPrefs: thunk(async (actions, _, { fail, getStoreActions }) => {
    try {
      const { prefs } = await fetchPrefs();
      actions.setPrefs(prefs);
      getStoreActions().view.setViewPrefs(prefs);
    } catch (error) {
      fail(error);
    }
  }),

  savePrefs: thunk(async (actions, __, { fail, getStoreState }) => {
    try {
      // in BFF pref and view Pref commbined and saved separately
      const featPrefs = {
        language: getStoreState().prefs.language,
        theme: getStoreState().prefs.theme,
        featurePrefs: getStoreState().prefs.featurePrefs,
      };
      await savePrefs(featPrefs);
    } catch (error) {
      fail(error);
      actions.setError(true);
    }
  }),
};

// Listeners
const prefsListeners = {
  // When the app updates the theme, save the prefs.
  onSetTheme: thunkOn(
    (_, storeActions) => storeActions.prefs.setTheme,
    (actions) => {
      actions.savePrefs();
    },
  ),

  onSetLanguage: thunkOn(
    (_, storeActions) => storeActions.prefs.setLanguage,
    (actions) => {
      actions.savePrefs();
    },
  ),

  onSetFeaturePrefs: thunkOn(
    (_, storeActions) => storeActions.prefs.setFeaturePrefs,
    (actions) => {
      actions.savePrefs();
    },
  ),
};

const prefsComputed = {
  getThemeJs: computed((state) => (specificTheme) => {
    switch (specificTheme || state.theme) {
      case Theme.LIGHT:
        return lightTheme;
      case Theme.DARK:
        return darkTheme;
      default:
        return lightTheme;
    }
  }),
  getFeaturePrefs: computed((state) => (featureId) => state.featurePrefs[featureId]),
};

const prefsModel = {
  ...defaultPrefsState,
  ...prefsActions,
  ...prefsListeners,
  ...prefsComputed,
};

export default prefsModel;
