/**
 * Supported asset types
 *
 * @enum {string}
 * @readonly
 */
export const AssetType = {
  WIND_TURBINE: 'wind-turbine',
  SUBSTATION: 'substation',
  SITE_CONTROLLER: 'site-controller',
  STORAGE_INVERTER: 'storage-inverter',
  SOLAR_INVERTER: 'solar-inverter',
  HYBRID_INVERTER: 'hybrid-inverter',
};
