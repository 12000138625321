import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { dedupArray } from '@ge/shared/util/general';
import { sorter } from '@ge/util/metric-sorter';

import { fetchPeopleTable } from '../services';

import { useTenants } from './use-tenants';

export const usePeopleTable = ({ filters, search, sortMetric, sortDirection, tenantId }) => {
  const { data: allTenants, applyTenantName } = useTenants();
  const {
    error,
    isError,
    isLoading: isPeopleLoading,
    data: people,
  } = useQuery([QueryKey.PEOPLE_TABLE], fetchPeopleTable, {
    refetchOnWindowFocus: false,
  });

  const mappedPeople = useMemo(() => {
    if (people && allTenants) {
      const formattedPpl = people.users.map((ppl) => {
        return {
          tenant: {
            id: ppl.tenantId,
            name: applyTenantName(ppl.tenantId),
          },
          roles: ppl?.attributes?.entity_group?.map((p) => p.roleName),
          name: `${ppl.firstName} ${ppl.lastName}`,
          userId: ppl.username,
          email: ppl.email,
          status: ppl.status === 0 ? 'Inactive' : ppl.status === 1 ? 'Active' : 'Suspended',
          lastLogin: '2021-10-05T02:52:12.470Z',
          allowAccess: true,
        };
      });
      return formattedPpl
        ?.sort(sorter(sortMetric, sortDirection))
        .filter((person) => (tenantId ? person.tenant.id === tenantId : true));
    }
  }, [sortMetric, sortDirection, people, tenantId, allTenants, applyTenantName]);

  const { data, filterValues } = useTableFilter({ data: mappedPeople, filters, search });

  const mappedFilters = {
    ...filterValues,
    roles: dedupArray(filterValues?.roles?.flat()),
  };

  return {
    isLoading: isPeopleLoading,
    isError,
    error,
    data: { data, filterValues: mappedFilters },
  };
};

export const usePerson = (personId) => {
  const { data: people } = useQuery([QueryKey.PEOPLE_TABLE], fetchPeopleTable, {
    refetchOnWindowFocus: false,
  });

  const person = people?.users?.find((user) => user.username === personId);

  return person
    ? {
        ...person,
        fullName: `${person.firstName} ${person.lastName}`,
      }
    : {};
};
