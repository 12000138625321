import { PropTypes } from 'prop-types';
import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Capability, PermissionScope } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { globalColors } from '@ge/tokens/colors';

import { PlanningContext, DragItemTypes } from '../../../context/planning-provider';

const Technician = styled.div`
  display: flex;
  background: ${(props) => props.theme.manage.tabs.active.backgroundColor};
  cursor: ${(props) => (props.draggable ? 'grab' : 'not-allowed')};
  &.dragging {
    background: ${(props) => props.theme.manage.dayCard.scheduled.backgroundColor};
    cursor: move;
  }
  .details {
    flex: 1;
    border-bottom: solid 2px ${(props) => props.theme.manage.tabs.techs.borderColor};
    color: ${(props) => props.theme.manage.tabs.techs.technician.textColor};
    .tech {
      display: flex;
      justify-content: space-between;
      padding: 5px 5px 10px;
    }
    .name {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
    .title {
      font-size: 10px;
      color: ${globalColors.slate5};
    }
    .hours {
      display: flex;
      justify-content: space-between;
      background: ${(props) => props.theme.manage.tabs.techs.technician.hoursBackground};
      font-size: 9px;
      letter-spacing: 0;
      line-height: 10px;
      padding: 5px;
    }
    .status {
      color: ${(props) => props.theme.manage.tabs.techs.technician.statusColor};
      font-size: 8px;
      letter-spacing: 0;
      line-height: 10px;
      text-align: right;
      text-transform: uppercase;
      font-weight: 700;
    }
    .nickname-pill {
      height: 15px;
      width: 30px;
      border-radius: 12px;
      background-color: #b6e1e9;
    }
    .nickname {
      height: 10px;
      /* width: 23px; */
      color: #1c252d;
      font-size: 8px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: -0.5px;
      /* line-height: 10px; */
      line-height: 16px;
      text-align: center;
    }
    .phone {
      margin-top: 6px;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 10px;
    }
  }
  .chevron {
    width: 13px;
    background-color: ${(props) => props.theme.manage.tabs.techs.technician.chevronBackground};
    border-bottom: solid 2px ${(props) => props.theme.manage.tabs.techs.borderColor};
    display: flex;
    align-items: center;
    padding: 2px;
  }
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 8,
  icon: Icons.CHEVRON,
  color: props.theme.manage.tabs.techs.iconColor,
  rotate: -90,
}))``;

const ContactIcon = styled(Icon).attrs((props) => ({
  size: 8,
  icon: Icons.PHONE_CONTACT,
  color: props.theme.manage.tabs.techs.iconColor,
}))`
  margin-right: 5px;
`;

const formatWorkerTitle = (title) => {
  if (title?.includes('_')) {
    return title.split('_').join(' ');
  } else {
    return title;
  }
};

const formatPhoneNumber = (phoneNumber) => {
  let numArr = phoneNumber?.toString().split('');

  if (numArr?.length === 11) {
    let countryCode = numArr.shift();
    let areaCode = numArr.splice(0, 3).join('');
    let exchangeCode = numArr.splice(0, 3).join('');
    let lineNumber = numArr.splice(0).join('');
    return `${countryCode}-${areaCode}-${exchangeCode}-${lineNumber}`;
  } else if (numArr?.length === 10) {
    let areaCode = numArr.splice(0, 3).join('');
    let exchangeCode = numArr.splice(0, 3).join('');
    let lineNumber = numArr.splice(0).join('');
    return `${areaCode}-${exchangeCode}-${lineNumber}`;
  } else {
    return numArr;
  }
};

export const TechsPanelTechnician = ({ worker }) => {
  const { planningState, serviceGroupIds } = useContext(PlanningContext);
  const { showPersonDetails } = useContext(EntityDetailsContext);
  const { isAuthorized } = useAuth();

  const editPersonnelMgmt = isAuthorized({
    capabilities: [{ capability: Capability.PERSONNEL_MANAGEMENT, scopes: [PermissionScope.EDIT] }],
    siteIds: [],
  });

  const editWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    siteIds: [],
  });

  const handlePersonSelect = useCallback(
    (username) => {
      showPersonDetails(username);
    },
    [showPersonDetails],
  );

  return (
    <Technician
      draggable={editWorkPlan && serviceGroupIds.length === 1 ? true : false}
      onDragStart={() => planningState.handleDragStart(worker, DragItemTypes.TECH)}
      onDragEnd={planningState.handleDragEnd}
      className={
        planningState.dragItem && planningState.dragItem.id === worker.username && 'dragging'
      }
      key={worker.username}
      onClick={() => (editPersonnelMgmt ? handlePersonSelect(worker?.username) : void 0)}
    >
      <div className="details">
        <div className="tech">
          <div>
            <div className="name">{`${worker.firstName} ${worker.lastName}`}</div>
            <div className="title">{formatWorkerTitle(worker.title) || 'Worker'}</div>
            <div className="phone">
              {worker?.phoneNumber && <ContactIcon />}
              {worker?.phoneNumber && formatPhoneNumber(worker.phoneNumber)}
            </div>
          </div>
          <div className="nickname-pill">
            <div className="nickname">{worker.initials || worker.firstName.substring(0, 5)}</div>
          </div>
        </div>
      </div>
      <div className="chevron">
        <ChevronIcon />
      </div>
    </Technician>
  );
};

TechsPanelTechnician.propTypes = {
  tech: PropTypes.instanceOf(Object),
  worker: PropTypes.instanceOf(Object),
};

TechsPanelTechnician.defaultProps = {
  tech: null,
};
