import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ErpStatusIcon } from '@ge/components/erp-status-icon';
import { Icon, Icons } from '@ge/components/icon';
import { MenuChevron } from '@ge/components/menu';
import { Text, Title } from '@ge/components/typography';
import { TaskStatus } from '@ge/models';
import { DateTimeFormats } from '@ge/models/constants';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { toSiteISOString } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

import { PlanningContext } from '../../../../context/planning-provider';
import { Actions } from '../../actions/actions';

const DefaultText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
  component: typography.elementTypes.div,
}))``;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom-color: ${({ theme }) => theme.manage.cards.trBorderBottom};
  .bundleText {
    color: ${globalColors.slate5};
    margin-left: -5px;
  }
  .crewNameBlack {
    color: ${globalColors.slate2};
    position: relative;
    left: 40px;
    font-weight: bold;
  }
  .crewNameGrey {
    color: ${globalColors.slate5};
    position: relative;
    top: 8px;
    left: 45px;
    font-weight: bold;
  }
  .crewNameBottom {
    color: ${globalColors.slate5};
    position: relative;
    left: 45px;
    font-weight: bold;
  }
  &.bundleCount {
    .bundleSection {
      padding: 0px;
      height: 27px;
      &: last-child {
        height: ${({ isExpanded }) => (isExpanded ? 'auto' : '27px')};
      }
    }
  }
  &.scheduled {
    background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
    &.striped {
      background: repeating-linear-gradient(
        145deg,
        #8694a1,
        #8694a1 0.1px,
        #85cddb 2px,
        #85cddb 6px
      );
      &:hover {
        background: repeating-linear-gradient(
          145deg,
          #8694a1,
          #8694a1 0.1px,
          #85cddb 2px,
          #85cddb 6px
        );
      }
      > td {
        border-left: 0px;
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    }
    > td .type {
      color: ${({ theme }) => theme.manage.cards.scheduledTypeTextColor};
    }
    > td {
      border-left: 2px solid ${({ theme }) => theme.manage.cards.scheduledColor};
    }
    &:last-child {
      border-bottom: 1px;
    }
  }
  &.unscheduled {
    background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    color: ${({ theme }) => theme.manage.cards.unscheduledTextColor};
    &.striped {
      background: repeating-linear-gradient(
        145deg,
        #8694a1,
        #8694a1 0.3px,
        #5e6d7b 1px,
        #5e6d7b 6px
      );

      &:hover {
        background: repeating-linear-gradient(
          145deg,
          #8694a1,
          #8694a1 0.3px,
          #5e6d7b 1px,
          #5e6d7b 6px
        );
      }
      > td {
        border-left: 0px !important;
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    }
    > td .type {
      color: ${({ theme }) => theme.manage.cards.unscheduledTypeTextColor};
    }
    > td {
      border-left: 2px solid ${({ theme }) => theme.manage.cards.unscheduledColor};
    }
    &:last-child {
      border-bottom: 1px;
    }
  }
  &.completed {
    background-color: ${({ theme }) => theme.manage.cards.completedColor};
    color: ${({ theme }) => theme.manage.cards.completedTextColor};
    &:hover {
      background-color: ${({ theme }) => theme.manage.cards.completedColor};
    }
    > td .type {
      color: ${({ theme }) => theme.manage.cards.completedTypeTextColor};
    }
    > td {
      border-left: 2px solid ${({ theme }) => theme.manage.cards.completedColor};
    }
    &:last-child {
      border-bottom: 1px;
    }
  }
  th {
    border-bottom: 2px solid ${({ theme }) => theme.manage.cards.thBorderBottom};
  }
`;

const BundleIcon = styled(Icon).attrs((props) => ({
  size: 15,
  icon: Icons.BUNDLE,
  color: props.theme.clearColumnFilterBtn.iconColor,
  hover: props.theme.clearColumnFilterBtn.iconColor,
}))`
  margin-left: 2px;
  padding: 0px;
`;

const BundleIconDetails = styled(Icon).attrs(() => ({
  size: 15,
  icon: Icons.BUNDLE,
  color: globalColors.slate3,
  hover: globalColors.slate3,
}))`
  margin-top: 3px;
  padding: 0px 2px;
`;

const BundleBottomDetails = styled.span`
  margin-top: 2px;
  margin-left: -5px;
`;

const BundleLeftIcon = styled(Icon).attrs(() => ({
  size: 10,
  icon: Icons.CARET,
  color: globalColors.slate3,
  hover: globalColors.slate3,
}))`
  margin-top: 3px;
  padding: 0px 2px;
`;

const BundleTasks = styled.span`
  height: 43px;
  display: flex;
  margin-left: 12px;
  .box {
    display: flex;
    flex-flow: column nowrap;
  }
`;

const BundleLine = styled.span`
  height: 23px;
  width: 1px;
  border-right: 1px solid #8694a1;
  padding-left: 4px;
`;

const BundleTxt = styled.span`
  height: 10px;
  width: 35px;
  color: ${globalColors.slate4};
  font-size: 9px;
  font-style: italic;
  line-height: 10px;
  margin-top: 22px;
  margin-left: 0px;
`;

const BundleCountList = styled.span`
  height: ${({ isLastBundledTask }) => (isLastBundledTask ? '0' : '15px')};
  z-index: ${({ isLastBundledTask }) => (isLastBundledTask ? '3' : 'unset')};
  display: flex;
  justify-content: center;
  position: relative;
  top: -6px;
  .countDetails {
    padding: 0px;
    display: flex;
    justify-content: center;
    background: ${globalColors.slate5};
    border: 1px solid ${globalColors.slate5};
    border-radius: 0 0 3px 3px;
    color: ${globalColors.slate3};
    height: 15px;
    width: 52px;
    font-size: 11px;
    &.bundleExpand {
      position: relative;
      top: -4px;
    }
    &.bundleCollapse {
      position: relative;
      top: -8px;
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const StyledMenuChevron = styled(MenuChevron).attrs(({ scheduled, theme }) => ({
  color: scheduled
    ? theme.manage.cards.scheduledTextColor
    : theme.manage.cards.unscheduledTextColor,
}))``;

const StyledMenuChevronCompleted = styled(MenuChevron).attrs(({ theme }) => ({
  color: theme.manage.cards.completedTextColor,
}))``;

export const BundleTasksLists = ({
  bTaskList,
  allTasksDuration,
  siteId,
  isBundled,
  erpStatusByTaskId,
  erpStatusLoading,
  setShowConfirmMessage,
  destroyStack,
  hoverActions,
  setHoverActions,
  selectedTasks,
  toggleCheckbox,
  crewIds,
  primaryTaskDetails,
  crewName,
  hasSecondaryTasks,
  isLastBundledTask,
  onActionClick,
  onClose,
}) => {
  const { t, ready } = useTranslation(['tasks'], { useSuspense: false });
  const { showTaskDetails, setCrewForBundledTask } = useContext(EntityDetailsContext);

  const {
    planningState: { setIsBundleToggle },
  } = useContext(PlanningContext);

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  let [isExpanded, setExpanded] = useState(true);

  const getTaskDuration = (task) => {
    const durationHrs = task?.estDurationHours;
    let durationMins = zeroPad(task?.estDurationMinutes, 2);
    return `${durationHrs}:${durationMins}`;
  };

  const addTimes = (timeArray) => {
    if (timeArray) {
      let mins = timeArray.reduce((acc, time) => {
        let [h, m] = time.split(':');
        acc += h * 60 + m * 1;
        return acc;
      }, 0);
      return ((mins / 60) | 0) + ':' + ('0' + (mins % 60)).slice(-2);
    } else {
      return '0:00';
    }
  };

  const currentDate = toSiteISOString(dayjs(dayjs(new Date()).format('YYYY-MM-DD')));

  const displayBundleRow = (event) => {
    event.stopPropagation();
    setExpanded(!isExpanded);
    setIsBundleToggle(true);
  };

  const handleTaskClick = useCallback(
    (task, crewId, isSecondaryBundle) => {
      showTaskDetails(task.id);
      onClose();
      setCrewForBundledTask((prevState) => ({
        ...prevState,
        crewId,
        isSecondaryBundle,
      }));
    },
    [showTaskDetails, onClose, setCrewForBundledTask],
  );

  if (!ready) return null;

  return (
    <>
      {bTaskList &&
        bTaskList?.map((task, index) => (
          <React.Fragment key={`${primaryTaskDetails?.id}_${task.id}`}>
            {isExpanded && (
              <Tr
                key={`${primaryTaskDetails?.id}_${task.id}`}
                onMouseEnter={() =>
                  setHoverActions({
                    hover: true,
                    key: `${primaryTaskDetails?.id}_${task.id}`,
                  })
                }
                onMouseLeave={destroyStack}
                className={`${isBundled ? 'bundleTaskTr' : ''} ${task.status} ${
                  task?.eligibleStartDate && task?.eligibleStartDate > currentDate ? 'striped' : ''
                }`}
              >
                <td className="secondaryTask">
                  {task?.status !== TaskStatus.COMPLETE ? (
                    <CheckboxWrapper onMouseDown={(e) => toggleCheckbox(e, task.id)}>
                      <Checkbox
                        checkState={
                          selectedTasks.includes(task.id)
                            ? CheckedState.CHECKED
                            : CheckedState.UNCHECKED
                        }
                      />
                    </CheckboxWrapper>
                  ) : null}
                </td>
                {isBundled && (
                  <td valign="top" align="center" className="bundleSection">
                    <BundleTasks onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                      <span className="box">
                        <BundleLine /> <BundleIcon />
                      </span>
                      <BundleTxt className="box bundleText">{t('bundled', 'Bundled')}</BundleTxt>
                    </BundleTasks>
                  </td>
                )}
                {!isBundled && (
                  <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                    {task?.asset?.id ? (
                      <>
                        <Title level={5}>{task?.site?.name}</Title>
                        <DefaultText className="asset">{task?.asset?.name}</DefaultText>
                      </>
                    ) : (
                      <DefaultText className="asset">{task?.site?.name}</DefaultText>
                    )}
                  </td>
                )}
                <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                  <DefaultText>{task.title} </DefaultText>
                  <Text className={'type'} type={typography.textTypes.kpi} level={4}>
                    {t(`tasks:dynamic.types.${task.source}`, task.source)}
                  </Text>
                </td>
                <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                  <DefaultText>
                    {task.dueDate ? task.dueDateTz?.format(DateTimeFormats.DEFAULT_DATE) : '—'}
                  </DefaultText>
                </td>
                <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                  <DefaultText>
                    {task.scheduleDate
                      ? task.scheduleDateTz?.format(DateTimeFormats.DEFAULT_DATE)
                      : '—'}
                  </DefaultText>
                </td>
                <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                  <DefaultText>{getTaskDuration(task)}</DefaultText>
                </td>
                <AuthRender
                  capability={Capability.WORK_ORDERS}
                  description="ERP Status"
                  edit
                  siteIds={[siteId]}
                >
                  <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                    <ErpStatusIcon
                      task={task}
                      erpStatus={erpStatusByTaskId[task.id]}
                      isLoading={erpStatusLoading}
                      tooltipCustomClass="tooltip-erp-status"
                      tooltipZIndex={elevations.P101}
                      width="20px"
                      hideDefaultIcon
                    />
                  </td>
                </AuthRender>
                <td onClick={() => handleTaskClick(task, crewIds, isBundled)}>
                  {task?.status === TaskStatus.COMPLETE ? (
                    <StyledMenuChevronCompleted />
                  ) : (
                    <StyledMenuChevron scheduled={task?.status === TaskStatus.SCHEDULED} />
                  )}
                </td>

                {/* ACTIONS */}
                {hoverActions?.key === `${primaryTaskDetails?.id}_${task.id}` && (
                  <Actions
                    task={task}
                    erpStatus={erpStatusByTaskId[task?.id]}
                    siteIds={[siteId]}
                    primaryTaskDetails={primaryTaskDetails}
                    setShowConfirmMessage={setShowConfirmMessage}
                    crewIds={crewIds}
                    isBundled={isBundled}
                    onActionClick={onActionClick}
                    onClose={onClose}
                  ></Actions>
                )}
              </Tr>
            )}

            {isBundled && isExpanded && index === bTaskList.length - 1 && (
              <Tr className="bundleDuration">
                <td colSpan={5}>
                  <span className="crewNameBlack">{crewName}</span>
                </td>
                <td>
                  <DefaultText>{addTimes(allTasksDuration)}</DefaultText>
                </td>
                <td colSpan={3}></td>
              </Tr>
            )}

            {isBundled && index === bTaskList.length - 1 && (
              <Tr className="bundleCount" isExpanded={isExpanded}>
                <td colSpan={9} className="bundleSection">
                  {!isExpanded && <span className="crewNameGrey">{crewName}</span>}
                  <BundleCountList isLastBundledTask={isLastBundledTask && isExpanded}>
                    <span
                      className={`countDetails ${isExpanded ? 'bundleExpand' : 'bundleCollapse'}`}
                      onClick={(event) => displayBundleRow(event)}
                    >
                      <BundleLeftIcon rotate={isExpanded ? 0 : -90} />
                      <BundleIconDetails />
                      <BundleBottomDetails>+{bTaskList.length}</BundleBottomDetails>
                    </span>
                  </BundleCountList>
                </td>
              </Tr>
            )}

            {!hasSecondaryTasks && index === bTaskList.length - 1 && (
              <Tr className="bundleCount">
                <td colSpan={9}>
                  {isExpanded && <span className="crewNameBottom">{crewName}</span>}
                </td>
              </Tr>
            )}
          </React.Fragment>
        ))}
    </>
  );
};

BundleTasksLists.propTypes = {
  bTaskList: PropTypes.instanceOf(Object),
  allTasksDuration: PropTypes.array,
  siteId: PropTypes.string,
  isBundled: PropTypes.bool,
  erpStatusByTaskId: PropTypes.object,
  erpStatusLoading: PropTypes.bool,
  setShowConfirmMessage: PropTypes.func,
  destroyStack: PropTypes.func,
  hoverActions: PropTypes.instanceOf(Object),
  setHoverActions: PropTypes.func,
  selectedTasks: PropTypes.array,
  toggleCheckbox: PropTypes.func,
  crewIds: PropTypes.array,
  primaryTaskDetails: PropTypes.instanceOf(Object),
  crewName: PropTypes.string,
  hasSecondaryTasks: PropTypes.bool,
  isLastBundledTask: PropTypes.bool,
  onActionClick: PropTypes.func,
  onClose: PropTypes.func,
};

BundleTasksLists.defaultProps = {
  bTaskList: null,
  allTasksDuration: [],
  siteId: null,
  isBundled: false,
  setShowConfirmMessage: () => null,
  destroyStack: () => null,
  hoverActions: () => null,
  setHoverActions: () => null,
  selectedTasks: [],
  toggleCheckbox: () => null,
  crewIds: null,
  primaryTaskDetails: null,
  crewName: null,
  hasSecondaryTasks: false,
  isLastBundledTask: false,
  onActionClick: () => null,
  onClose: () => null,
};
