import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { typographyClasses } from '@ge/tokens/typography';

const BaseElement = ({ component, children, className, ...restProps }) => {
  const Component = component;
  return (
    <Component className={className} {...restProps}>
      {children}
    </Component>
  );
};

BaseElement.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.string.isRequired,
  className: PropTypes.string,
};

BaseElement.defaultProps = {
  className: null,
};

export const Base = styled(BaseElement)`
  ${typographyClasses}
`;
