import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

// import { SortValueType } from '@ge/models/constants';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';
import { filterByView } from '@ge/shared/util/view-utils';
import { sortItemsByText, sortItemsByDate, sortItemsByNumber } from '@ge/util/metric-sorter';

const useSortedCaseTable = ({ cases, sortDirection, sortMetric }) => {
  const view = useStoreState((state) => state.view.currentView);
  return useMemo(() => {
    if (!cases || cases.length < 1) return [];
    let data = Object.values(cases);
    // Apply view filter if one is provided.
    if (view) {
      data = filterByView(data, view);
    }

    if (sortMetric.toUpperCase().includes('DATE') || sortMetric === AnomaliesColumns.LAST_FLAGGED)
      return data?.sort(sortItemsByDate(sortMetric, sortDirection));
    else if (
      sortMetric === `${AnomaliesColumns.NOTES}.description` ||
      sortMetric === `${AnomaliesColumns.PRIORITY}.value`
    )
      return data?.sort(sortItemsByNumber(sortMetric, sortDirection));
    else if (sortMetric) {
      const collator = new Intl.Collator(undefined, { caseFirst: 'upper' });
      return data?.sort(sortItemsByText(sortMetric, sortDirection, collator));
    }
  }, [cases, sortDirection, sortMetric, view]);
};

export default useSortedCaseTable;
