import { AppFeatures } from '../models/features';

/**
 * Get the Feature scope by the route
 */
export const findScopesByRoute = (route) => {
  const feature = Object.values(AppFeatures).find((feat) => {
    if (Array.isArray(feat.route)) {
      return feat.route.includes(route);
    }

    return feat.route === route;
  });

  return (feature && feature.scope) || null;
};
