import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { QueryKey, TimeAggr } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks/config';

import { fetchAssetKpiData } from '../services';

import { splitKpisCategoryByApi } from './util';

/**
 * Use asset KPI data.
 *
 * @param assetId the asset id
 * @param categories the kpi categories
 * @param timeAggr the time aggregate
 * @param startDate the start date
 * @param endDate the end date
 */
const useAssetKpiData = ({
  assetId,
  categories,
  startDate,
  endDate,
  timeAggr = TimeAggr.DAILY,
}) => {
  const { allKpiMetricsEndpointCategories, otherKpiEndpointsCategories } =
    splitKpisCategoryByApi(categories);
  // store
  const getAssetById = useStoreState((store) => store.assets.getAssetById);

  const asset = getAssetById(assetId);
  const siteId = asset?.site?.id;

  const queries = useQueries(
    [allKpiMetricsEndpointCategories, otherKpiEndpointsCategories].map((categories) => {
      const queryKey = {
        assetId,
        startDate,
        endDate,
        timeAggr,
        siteId,
      };

      return {
        queryKey: [QueryKey.ASSET_KPI_DATA, queryKey, categories],
        queryFn: () =>
          fetchAssetKpiData({
            assetId,
            categories,
            startDate,
            endDate,
            timeAggr,
          }),
        enabled: Boolean(assetId && categories.length && startDate && endDate),
        ...Config.EXECUTE_ONCE,
      };
    }),
  );

  const dataMapped = useMemo(() => {
    const mappedQueries = queries.map((query) => query.data?.data);
    return Object.assign({}, ...mappedQueries);
  }, [queries]);

  const isLoading = queries.some((query) => query.isLoading);

  const error = useMemo(
    () => (isLoading ? null : queries.find((query) => query.isError)?.error),
    [isLoading, queries],
  );

  return { data: dataMapped, isLoading, error };
};

export default useAssetKpiData;
