import { ErrorMessage } from '@hookform/error-message';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Select } from '@ge/components/select';
import { placements, Tooltip } from '@ge/components/tooltip';
import { TaskTemplateModes, ErpStatus } from '@ge/models/constants';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors/colors';
import { elevations } from '@ge/tokens/elevations';

import { DynamicSection } from '../templates/sections/dynamic-section/dynamic-section';

const PartsRow = styled.div`
  margin: 20px 0 10px;
  .edit-parts & {
    margin-top: 0;
  }
`;

const PartsColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${({ widthPx }) => (widthPx ? `width: ${widthPx}px` : '')};

  &:last-of-type {
    align-self: center;
  }
  &.trash-icon {
    margin-top: auto;
  }
`;

const PartsLabel = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 6px;
`;

const PlaceOrderWrapper = styled.div`
  margin-top: -15px;
`;

const CheckBoxWrapper = styled.div`
  margin-top: 5px;
`;

const PartInputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const StyledErrorMessage = styled.span`
  padding-left: 1.5em;
  text-indent: -1.5em;
  color: ${StatusColor.DANGER};
  margin-top: 5px;

  &:before {
    content: '\\2296'; // &ominus;
    font-size: 13px;
    padding-right: 2px;
  }
`;

const getErrorStyles = ({ errors, fieldArray }) => {
  if (fieldArray) {
    const { fieldName, errorIndex, errorName } = fieldArray;
    if (errors[fieldName]?.[errorIndex]?.[errorName]) {
      return `border-color: ${StatusColor.DANGER}`;
    }
  }
};

const StyledTextField = styled(Input)`
  ${(props) => getErrorStyles(props)};
`;

const StyledNumberField = styled(Input)`
  ${(props) => getErrorStyles(props)};
`;

const StyledSelectField = styled(Select)`
  ${(props) => getErrorStyles(props)};
`;

const PartNumberInput = styled(StyledTextField)`
  width: 80px;
`;

const PartNameInput = styled(StyledTextField)`
  width: 290px;
`;

const PartQuantityInput = styled(StyledNumberField)`
  width: 80px;
  height: 25px;
`;

const PartServiceActivityDropdown = styled(StyledSelectField)`
  width: 100px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-bottom: 8px;
`;

const AddPartButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  margin: 0 4px 15px auto;
  text-transform: uppercase;
`;

const AddPartIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

export const ServiceRequestParts = ({
  task,
  fieldName,
  metadata,
  shippingSection,
  templateMode,
  erpType,
  erpData,
  erpDataStatus,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  // get the failed SR status
  const srCreationFailed = erpDataStatus === ErpStatus.SR_CREATION_FAILED;

  const [userPartsRowsRendered, setUserPartsRowsRendered] = useState(0);
  const [isPlaceOrderSelectAll, setIsPlaceOrderSelectAll] = useState(false);
  const placeOrderSelectAllCheckedState = isPlaceOrderSelectAll
    ? CheckedState.CHECKED
    : CheckedState.UNCHECKED;

  const { readOnly } = metadata;
  const { control, errors, register, setValue, watch, reset } = useFormContext();
  const watchParts = watch(fieldName);

  const PARTS_QUANTITY = Object.freeze({
    maxValue: 9999.9999,
    minValue: 0.0001,
  });

  let { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
  });

  const serviceActivityOptions = useMemo(() => {
    const metadataValues = metadata.partDetails?.serviceActivityValues;
    if (!metadataValues) {
      return null;
    }

    const defaultOption = {
      label: t('form.select', 'Select'),
      value: '',
    };

    const options = Object.entries(metadataValues).map(([key, value]) => ({
      label: value,
      value: key,
    }));

    return [defaultOption, ...options];
  }, [metadata.partDetails?.serviceActivityValues, t]);

  const getServiceActivitySelectedOption = useCallback(
    (index) => {
      let selectedValue = watchParts?.[index]?.serviceActivity;

      // Hack: the value for this field gets lost after adding parts rows from the `erpData` prop.
      // Not sure where this fix should actually go, but for the time being this will have to do.
      if (selectedValue === undefined && index < (erpData?.parts?.length ?? 0)) {
        selectedValue = erpData.parts[index].serviceActivity;
      }

      if (selectedValue) {
        return serviceActivityOptions?.find((option) => option.value === selectedValue);
      }
      return serviceActivityOptions?.[0];
    },
    [watchParts, erpData, serviceActivityOptions],
  );

  const defaultParts = useMemo(
    () => ({
      number: '',
      name: '',
      quantity: '',
      serviceActivity: serviceActivityOptions?.[0].value,
      placeOrder: isPlaceOrderSelectAll,
    }),
    [serviceActivityOptions, isPlaceOrderSelectAll],
  );

  const showShippingSection = useMemo(
    () => watchParts?.some((part) => part.placeOrder),
    [watchParts],
  );

  // Populate parts rows from user-submitted data, if available
  // N.b.: react-hook-form requires that only one row be appended per render.
  useEffect(() => {
    if (!(erpData?.parts?.length > 0)) return;

    if (userPartsRowsRendered < erpData.parts.length) {
      if (userPartsRowsRendered === 0) {
        setIsPlaceOrderSelectAll(erpData.parts.every((p) => p.placeOrder || p.replenish));
      }

      const erpDataPart = erpData.parts[userPartsRowsRendered];
      const part = {
        placeOrder: erpDataPart.placeOrder || erpDataPart.replenish,
        ...erpDataPart,
      };
      const shouldFocus = false;
      append(part, shouldFocus);
      setUserPartsRowsRendered(userPartsRowsRendered + 1);
    }
  }, [erpData, userPartsRowsRendered, setUserPartsRowsRendered, setIsPlaceOrderSelectAll, append]);

  // Always display at least one row
  useEffect(() => {
    // Don't add default row if user-provided row values are being added
    if (erpData?.parts?.length > 0 && userPartsRowsRendered < erpData.parts.length) {
      return;
    }

    if (watchParts?.length === 0) {
      const shouldFocus = false;
      append(defaultParts, shouldFocus);
    }
  }, [erpData, userPartsRowsRendered, defaultParts, watchParts, append]);

  // Set service activity dropdown values from user-submitted data
  useEffect(() => {
    if (!erpData || erpData.parts?.length === 0 || !erpData.parts) {
      return;
    }

    if (watchParts?.length > 0) {
      watchParts.forEach((part, index) => {
        if (part.serviceActivity === undefined) {
          const selectedOption = getServiceActivitySelectedOption(index);
          if (selectedOption) {
            setValue(`${fieldName}[${index}].serviceActivity`, selectedOption.value, {
              shouldValidate: true,
            });
            return; // only set one value per render
          }
        }
      });
    }
  }, [erpData, watchParts, fieldName, getServiceActivitySelectedOption, setValue, remove]);

  const handleChangePlaceOrderSelectAll = (selected) => {
    if (selected !== isPlaceOrderSelectAll) {
      setIsPlaceOrderSelectAll(selected);
    }

    // Sync "Select All" checkbox -> individual "Place Order" checkboxes
    watchParts.forEach((part, index) => {
      if (part.placeOrder !== selected) {
        setValue(`${fieldName}[${index}].placeOrder`, selected, { shouldValidate: true });
      }
    });
  };

  const handleChangePlaceOrder = (index, selected) => {
    setValue(`${fieldName}[${index}].placeOrder`, selected, { shouldValidate: true });

    // Sync individual "Place Order" checkboxes -> "Select All" checkbox
    let areAllSelected = selected;
    if (selected) {
      watchParts.forEach((part, i) => {
        if (i !== index) {
          areAllSelected &= part.placeOrder;
        }
      });
    }
    if (areAllSelected != isPlaceOrderSelectAll) {
      setIsPlaceOrderSelectAll(areAllSelected);
    }
  };

  const getPlaceOrderCheckedState = (index) => {
    return watchParts?.[index]?.placeOrder ? CheckedState.CHECKED : CheckedState.UNCHECKED;
  };

  const handleChangeServiceActivity = (index, selectedOption) => {
    setValue(`${fieldName}[${index}].serviceActivity`, selectedOption.value, {
      shouldValidate: true,
    });
  };

  const handleRemove = (index) => {
    // Reusing logic in this handler to keep the "Select All" checkbox in sync
    handleChangePlaceOrder(index, watchParts.length > 1);
    remove(index);
  };

  // Pull expected part details from task detail tab to ERP
  const setPartValue = useCallback(async () => {
    const taskExParts = task?.expectedParts?.map((item) => {
      return {
        name: item?.name,
        number: item?.number,
        quantity: item?.quantity,
      };
    });
    if (
      taskExParts !== undefined &&
      taskExParts !== '' &&
      taskExParts?.length > 0 &&
      !srCreationFailed
    ) {
      reset();
      // remove autofocus on available data
      const shouldFocus = false;
      append(taskExParts, shouldFocus);
    }
  }, [append, reset, srCreationFailed, task?.expectedParts]);

  useEffect(() => {
    setPartValue();
  }, [setPartValue]);

  return (
    <PartsRow>
      <div>
        {fields.map((part, index) => {
          return (
            <PartInputWrapper className="parts" key={part.id}>
              <PartsColumn widthPx={78}>
                {index === 0 && <PartsLabel>{t('form.part_number', 'Part #')}</PartsLabel>}
                <PartNumberInput
                  defaultValue={part.number}
                  disabled={readOnly}
                  errors={errors}
                  fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'number' }}
                  name={`${fieldName}[${index}].number`}
                  placeholder={t('form.part_number', 'Part #')}
                  maxLength={40}
                  ref={register({
                    required: false,
                    validate: {
                      conditionalRequired: (value) => {
                        const isEmptyWhileRequired =
                          (typeof value !== 'string' || !value.trim()) &&
                          (watchParts[index]?.name ||
                            watchParts[index]?.quantity ||
                            watchParts[index]?.placeOrder);
                        return (
                          !isEmptyWhileRequired ||
                          t('form.invalid_part_number', 'Invalid part number')
                        );
                      },
                    },
                  })}
                  type="text"
                />
                <ErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].number`}
                  render={({ message }) => (
                    <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                  )}
                />
              </PartsColumn>
              <PartsColumn widthPx={290}>
                {index === 0 && <PartsLabel>{t('form.part_name', 'Part Name')}</PartsLabel>}
                <PartNameInput
                  defaultValue={part.name}
                  disabled={readOnly}
                  errors={errors}
                  fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'name' }}
                  name={`${fieldName}[${index}].name`}
                  placeholder={t('form.part_name', 'Part Name')}
                  maxLength={250}
                  ref={register({
                    required: false,
                    validate: {
                      conditionalRequired: (value) => {
                        const isEmptyWhileRequired =
                          (typeof value !== 'string' || !value.trim()) &&
                          (watchParts[index]?.number ||
                            watchParts[index]?.quantity ||
                            watchParts[index]?.placeOrder);
                        return (
                          !isEmptyWhileRequired || t('form.invalid_part_name', 'Invalid part name')
                        );
                      },
                    },
                  })}
                  type="text"
                />
                <ErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].name`}
                  render={({ message }) => (
                    <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                  )}
                />
              </PartsColumn>
              <PartsColumn widthPx={80}>
                {index === 0 && <PartsLabel>{t('form.quantity', 'Quantity')}</PartsLabel>}
                <PartQuantityInput
                  defaultValue={part.quantity}
                  disabled={readOnly}
                  errors={errors}
                  fieldArray={{ fieldName: fieldName, errorIndex: index, errorName: 'quantity' }}
                  min={PARTS_QUANTITY.minValue}
                  max={PARTS_QUANTITY.maxValue}
                  pattern={/^\d+\.?\d{0,4}$/}
                  step={1}
                  name={`${fieldName}[${index}].quantity`}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (value < PARTS_QUANTITY.minValue) {
                      setValue(`${fieldName}[${index}].quantity`, PARTS_QUANTITY.minValue, {
                        shouldValidate: true,
                      });
                    } else if (value > PARTS_QUANTITY.maxValue) {
                      setValue(`${fieldName}[${index}].quantity`, PARTS_QUANTITY.maxValue, {
                        shouldValidate: true,
                      });
                    } else {
                      setValue(`${fieldName}[${index}].quantity`, value, {
                        shouldValidate: true,
                      });
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue(`${fieldName}[${index}].quantity`, value, {
                      shouldValidate: true,
                    });
                  }}
                  onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                  placeholder={t('form.qty', 'Qty')}
                  ref={register({
                    required: false,
                    validate: {
                      validateRange: (value) => {
                        //0.0001 - 9999.9999 min and max for quantity
                        const errorMessage = t('form.invalid_quantity', 'Invalid quantity');
                        const pattern = /^\d+\.?\d{0,4}$/;

                        if (value) {
                          const valueAsNumber = Number(value);
                          const isValidValue =
                            pattern.test(value) &&
                            valueAsNumber >= PARTS_QUANTITY.minValue &&
                            valueAsNumber <= PARTS_QUANTITY.maxValue;
                          return isValidValue || errorMessage;
                        }

                        const isRequired =
                          watchParts[index]?.number ||
                          watchParts[index]?.name ||
                          watchParts[index]?.placeOrder;
                        return !isRequired || errorMessage;
                      },
                    },
                  })}
                  spinner
                  type="number"
                />
                <ErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].quantity`}
                  render={({ message }) => (
                    <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                  )}
                />
              </PartsColumn>
              <PartsColumn widthPx={100}>
                {index === 0 && (
                  <PartsLabel>{t('form.service_activity', 'Service Activity')}</PartsLabel>
                )}
                <PartServiceActivityDropdown
                  disabled={readOnly}
                  errors={errors}
                  fieldArray={{
                    fieldName: fieldName,
                    errorIndex: index,
                    errorName: 'serviceActivity',
                  }}
                  name={`${fieldName}[${index}].serviceActivity`}
                  onChange={(value) => handleChangeServiceActivity(index, value)}
                  options={serviceActivityOptions}
                  value={getServiceActivitySelectedOption(index)}
                  ref={register(`${fieldName}[${index}].serviceActivity`, {
                    required: false,
                    validate: {
                      conditionalRequired: (value) => {
                        const isEmptyWhileRequired =
                          (typeof value !== 'string' || !value?.trim()) &&
                          (watchParts[index]?.number ||
                            watchParts[index]?.name ||
                            watchParts[index]?.quantity ||
                            watchParts[index]?.placeOrder);
                        return !isEmptyWhileRequired || t('form.please_select_an_option');
                      },
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].serviceActivity`}
                  render={({ message }) => (
                    <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                  )}
                />
              </PartsColumn>
              <PartsColumn widthPx={50}>
                {index === 0 && (
                  <PlaceOrderWrapper>
                    <Tooltip
                      title={t('form.select_all', 'Select All')}
                      placement={placements.TOP}
                      zIndex={elevations.P20}
                    >
                      <PartsLabel>
                        <Checkbox
                          checkState={placeOrderSelectAllCheckedState}
                          onChange={handleChangePlaceOrderSelectAll}
                        />
                        {t('form.place_order', 'Place Order')}
                      </PartsLabel>
                    </Tooltip>
                  </PlaceOrderWrapper>
                )}
                <CheckBoxWrapper>
                  <Checkbox
                    checkState={getPlaceOrderCheckedState(index)}
                    onChange={(value) => handleChangePlaceOrder(index, value)}
                    name={`${fieldName}[${index}].placeOrder`}
                    ref={register({
                      required: false,
                    })}
                  />
                </CheckBoxWrapper>
              </PartsColumn>
              <PartsColumn className="trash-icon">
                <button type="button" onClick={() => handleRemove(index)}>
                  <StyledIcon icon={Icons.TRASH} size={13} />
                </button>
              </PartsColumn>
            </PartInputWrapper>
          );
        })}
      </div>
      <ConditionalRender shouldRender={fields.length < 50}>
        <AddPartButton type="button" onClick={() => append(defaultParts, false)}>
          <AddPartIcon />
          {t('form.add_part', 'Add Part')}
        </AddPartButton>
      </ConditionalRender>
      <ConditionalRender shouldRender={showShippingSection}>
        <DynamicSection
          section={shippingSection}
          task={task}
          templateMode={templateMode}
          erpType={erpType}
          erpData={erpData}
        />
      </ConditionalRender>
    </PartsRow>
  );
};

ServiceRequestParts.propTypes = {
  metadata: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  erpType: PropTypes.string,
  erpData: PropTypes.object,
  erpDataStatus: PropTypes.string,
  shippingSection: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};
