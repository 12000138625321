import { useStoreState } from 'easy-peasy';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import {
  DefaultKpiCategoryDef,
  EntityType,
  FleetKpiHeaderCategories,
  TimeAggr,
} from '@ge/models/constants';
import { useFilterDateRange } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { maxDayDiff } from '@ge/util';

import { FilterBar } from '../components/dashboard/dashboard-filter-bar';
import DashboardHeader from '../components/dashboard/dashboard-header';
import { RegionPerformance } from '../components/dashboard/dashboard-region-performance';
import SitePerformance from '../components/dashboard/dashboard-site-performance';
import useFleetOverviewKpiData from '../data-hooks/use-fleet-overview-kpi-data';
import useKpiHeaderMenu from '../data-hooks/use-kpi-header-menu';
import { DefaultPrimaryGroupDef } from '../models/analyze-filter-defaults';
import { DefaultFleetKpiVisibility } from '../models/kpi-header-visibility-defaults';

import { AnalyzeFilterPanel } from './analyze-filter-panel';

const DashboardContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.analyze.header.borderBottomColor};
  margin-top: 13px;
`;

const AnalyzeFleetOverview = () => {
  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_FLEET_OVERVIEW,
    defaultVisibility: DefaultFleetKpiVisibility,
    kpiCategories: FleetKpiHeaderCategories,
  });

  const [currentCategory, setCurrentCategory] = useState(DefaultKpiCategoryDef);
  const [currentPrimaryGroup, setCurrentPrimaryGroup] = useState(DefaultPrimaryGroupDef);

  // state
  const { dateRange } = useStoreState(({ analyze }) => analyze);

  // data hooks
  const { endDate, startDate } = useFilterDateRange({ dateRange });

  const timeAggr = useMemo(() => {
    if (!startDate || !endDate) return null;
    return maxDayDiff(startDate, endDate, 92) ? TimeAggr.DAILY : TimeAggr.WEEKLY;
  }, [startDate, endDate]);

  const { data: fleetOverviewKpiData } = useFleetOverviewKpiData({
    categories: FleetKpiHeaderCategories,
    endDate,
    // might need to make this dynamic for platforms, not sure
    entityAggr: EntityType.REGION,
    startDate,
    timeAggr,
  });

  const { ready } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const handleKpiCategoryChange = useCallback(
    ({ kpi, primaryGroup }) => {
      setCurrentCategory(kpi);
      setCurrentPrimaryGroup(primaryGroup);
    },
    [setCurrentCategory, setCurrentPrimaryGroup],
  );

  return (
    <PageContainer i18nReady={ready}>
      <AnalyzeFilterPanel entityType={EntityType.FLEET} />
      <DashboardHeader
        data={fleetOverviewKpiData}
        kpiCategories={FleetKpiHeaderCategories}
        onMenuClose={handleKpiChanges}
        visibleKpiPrefs={visibleKpis}
      />
      <FilterBar />
      <DashboardContainer>
        <RegionPerformance
          defaultCategory={DefaultKpiCategoryDef}
          onKpiCategoryChange={handleKpiCategoryChange}
        />
        <SitePerformance category={currentCategory} primaryGroup={currentPrimaryGroup} />
      </DashboardContainer>
    </PageContainer>
  );
};

export default AnalyzeFleetOverview;
