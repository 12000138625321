import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { UserManager, WebStorageStateStore, Log } from 'oidc-client';

import { IndexedDb } from '../state/storage/indexedDb';

const OIDC_CONFIG_ENDPOINT = `${process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API}/auth/oidc`;
export const getOidcConfig = (idp) => {
  let config = {
    ...(idp && {
      params: {
        idpName: idp,
      },
    }),
  };
  return axios.get(OIDC_CONFIG_ENDPOINT, config).then(({ data }) => data);
};

export const getDefaultRoute = () => '/monitor';

const getConfig = ({ baseUrl, clientId, clientSecret, idpName, isMutiTenant }) => {
  const port = window.location.port ? `:${window.location.port}` : '';
  const logoutUrl = isMutiTenant ? '/signin' : '/logout';
  return {
    IDENTITY_CONFIG: {
      authority: `${baseUrl}/broker/${idpName}/login`,
      client_id: `${clientId}`,
      client_secret: clientSecret,
      redirect_uri: `${window.location.protocol}//${window.location.hostname}${port}/login/oauth2/`,
      login: `${window.location.origin}/login`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${port}/silentrenew`,
      loadUserInfo: false,
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${port}${logoutUrl}`,
      response_type: 'code',
      grantType: 'authorization_code',
      scope: 'openid profile',
      webAuthResponseType: 'code',
    },

    METADATA_OIDC: {
      issuer: `${process.env.REACT_APP_IAC_OIDC_CONFIG_URL}/auth/realms/RENDS`,
      jwks_uri: `${process.env.REACT_APP_IAC_OIDC_CONFIG_URL}/auth/RENDS/certs`,
      authorization_endpoint: `${process.env.REACT_APP_IAC_OIDC_CONFIG_URL}/auth/RENDS/auth`,
      token_endpoint: `${process.env.REACT_APP_IAC_OIDC_CONFIG_URL}/auth/RENDS/token`,
      userinfo_endpoint: `${process.env.REACT_APP_IAC_OIDC_CONFIG_URL}/auth/RENDS/userinfo`,
      end_session_endpoint: `${process.env.REACT_APP_IAC_OIDC_CONFIG_URL}/auth/RENDS/logout`,
    },
  };
};

let USER_MANAGER;
let SESSION_STORAGE_KEY;
export const initOidc = ({ baseUrl, clientId, clientSecret, idpName, isMutiTenant }) => {
  const config = getConfig({ baseUrl, clientId, clientSecret, idpName, isMutiTenant });

  SESSION_STORAGE_KEY = `oidc.user:${config.IDENTITY_CONFIG.authority}:${config.IDENTITY_CONFIG.client_id}`;

  USER_MANAGER = new UserManager({
    ...config.IDENTITY_CONFIG,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    metadata: {
      ...config.METADATA_OIDC,
    },
  });

  Log.logger = console;
  if (process.env.NODE_ENV === 'development') {
    Log.level = Log.DEBUG;
  }

  USER_MANAGER.events.addSilentRenewError((e) => {
    Log.warn('Silent renew error', e.message);
    Log.debug('logging user out');
    logout();
  });
};

export const isOfflineMode =
  process.env.REACT_APP_USE_MOCK_DATA === 'true' || process.env.NODE_ENV === 'test';

const offlineIntercept = (fn, offlineFn) => {
  if (isOfflineMode) {
    return offlineFn;
  }
  return fn;
};

export const getClientId = () => {
  return USER_MANAGER.client_id;
};

//returns promise with user info saved in session/local storage
export const signinRedirectCallback = (url) => {
  USER_MANAGER.signinRedirectCallback(url).then(() => {
    Log.debug('Sign in redirect callback.');
    window.location.replace(localStorage.getItem('redirectUri'));
  });
};

export const getUser = async () => {
  const user = await USER_MANAGER.getUser();
  if (!user) {
    return await USER_MANAGER.signinRedirectCallback();
  }
  return user;
};

export const signinRedirect = () => {
  Log.debug('Attempting sign in redirect.');
  localStorage.setItem('redirectUri', window.location.pathname + window.location.search);
  USER_MANAGER.signinRedirect();
};

export const isAuthenticated = offlineIntercept(
  () => {
    const userSession = getUserSessionInfo();
    return !!userSession && !!userSession.access_token;
  },
  () => true,
);

export const signinSilent = () => {
  Log.debug('Attempting silent sign in.');
  USER_MANAGER.signinSilent();
};

export const signinSilentCallback = () => {
  Log.debug('Silent sign in callback.');
  USER_MANAGER.signinSilentCallback();
};

export const createSigninRequest = () => {
  return USER_MANAGER.createSigninRequest();
};

export const logout = offlineIntercept(
  () => {
    return USER_MANAGER.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    }).finally(() => {
      localStorage.clear();
      sessionStorage.clear();
      IndexedDb.clear();
      USER_MANAGER.clearStaleState();
    });
  },
  () =>
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}:${window.location.port}/logout`,
    ),
);

export const signoutRedirectCallback = () => {
  USER_MANAGER.signoutRedirectCallback().finally(() => {
    localStorage.clear();
    sessionStorage.clear();
  });
  USER_MANAGER.clearStaleState();
};

export const getIdToken = () => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    return userInfo.id_token;
  }
  return null;
};

export const getUserAccessToken = () => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    return userInfo.access_token;
  }
  return null;
};

export const getSubject = () => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    return userInfo.profile.sub;
  }
  return null;
};

export const getUserName = (fullname = false) => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    return fullname ? userInfo.profile.name : userInfo.profile.given_name;
  }
  return '';
};

const getUserSessionInfo = () => {
  return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
};

export const getPreferredUserName = () => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    return userInfo.profile.preferred_username;
  }
  return '';
};

export const getUserProfileInfo = () => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    return userInfo.profile;
  }
  return '';
};

export const getUserRolesFromAccessToken = () => {
  const userInfo = getUserSessionInfo();
  if (userInfo) {
    let userDetails = jwt_decode(userInfo.access_token);
    if (userDetails?.entity_group) {
      let entityGroup = JSON.parse(userDetails.entity_group);
      return entityGroup?.flatMap((group) => Object.keys(group));
    }
  }
  return '';
};
export const registerMocks = () =>
  import('./__mocks__/auth/auth.mocks')
    .then((mocks) => mocks.register(OIDC_CONFIG_ENDPOINT))
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });

export const OIDC_CONFIG_KEY = `dwf.oidc.config.${window.location.hostname}`;
const OIDC_TENANT_INFO_KEY = `dwf.oidc.tenant.${window.location.hostname}`;

export const getOidcInfo = async () => {
  let oidcConfig = null;
  const storedConfig = Cookies.get(OIDC_CONFIG_KEY);
  if (storedConfig) {
    try {
      oidcConfig = JSON.parse(atob(storedConfig));
    } catch (error) {
      Cookies.remove(OIDC_CONFIG_KEY);
    }
  }
  if (!oidcConfig) {
    oidcConfig = await getOidcConfig();
    Cookies.set(OIDC_CONFIG_KEY, btoa(JSON.stringify(oidcConfig)), { expires: 1 / 96 }); // 15 minutes
  }
  return oidcConfig;
};

//init oidc config if already exists when signin with multitenant idp
export const initOidcForSelectedIdp = async () => {
  let oidcData = sessionStorage.getItem(OIDC_TENANT_INFO_KEY);
  if (oidcData) {
    let oidcConfig = JSON.parse(atob(oidcData));
    const { baseUrl, clientId, clientChallenge: clientSecret, idpName } = oidcConfig;
    initOidc({ baseUrl, clientId, clientSecret, idpName, isMutiTenant: true });
  }
};

//set oidc config when signin with multitenant idp
export const initOidcBasedOnIdp = (oidcInfo) => {
  sessionStorage.setItem(OIDC_TENANT_INFO_KEY, btoa(JSON.stringify(oidcInfo)));
  initOidcForSelectedIdp();
};

export const isSignedInWithIdp = () => {
  return sessionStorage.getItem(OIDC_TENANT_INFO_KEY);
};
