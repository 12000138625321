import { request } from './api';

export const getDefectFileAttributes = (defectId, options) =>
  request.get(`/getDefectFileAttributes/${defectId}`, options);
export const getAllDefects = (number = 1000, options) =>
  request.get(`/getAllDefects/${number}`, options);
export const getAllDefectsWSConn = () =>
  new WebSocket(`${process.env.REACT_APP_DIGITAL_WIND_FARM_INSPECT_WS}/getAllDefects/`);

// #RENINSPECT MIGRATION NOTES#
// cut this over to standard request api after bff is added
export const getReportCompletedJobs = (options) => request.get(`/getReportCompletedJobs/`, options);
export const getAllReportCompletedJobs = (filters, options) =>
  request.post(`/rei/reports/report-completed-jobs`, filters, options);
