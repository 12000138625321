import { registerMocks as registerAnomalyMocks } from '../anomalies';
import { registerMocks as registerAssetMocks } from '../asset';
import { registerMocks as registerAuthMocks } from '../auth';
import { registerMocks as registerCommonMocks } from '../common';
import { registerMocks as registerCustomerMocks } from '../customer';
import { registerMocks as registerEventMocks } from '../event';
import { registerMocks as registerIssueMocks } from '../issues';
import { registerMocks as registerRegionMocks } from '../region';
import { registerMocks as registerSiteMocks } from '../site';
import { registerMocks as registerSummaryMocks } from '../summary';
import { registerMocks as registerTaskMocks } from '../task';
import { registerMocks as registerTicketMocks } from '../ticket';

export const registerMocks = () =>
  Promise.all([
    registerAnomalyMocks(),
    registerAssetMocks(),
    registerAuthMocks(),
    registerCommonMocks(),
    registerCustomerMocks(),
    registerEventMocks(),
    registerIssueMocks(),
    registerRegionMocks(),
    registerSiteMocks(),
    registerSummaryMocks(),
    registerTicketMocks(),
    registerTaskMocks(),
  ]);
