import dayjs from 'dayjs';

const dayOfMonth = dayjs().date();

export const regionSparkOptions = {
  chart: {
    height: 260,
    animation: false,
    backgroundColor: 'transparent',
    spacingBottom: 4,
    spacingRight: 0,
    spacingLeft: 0,
    type: 'spline',
    showAxes: true,
  },
  title: {
    text: '',
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    tickLength: 5,
    tickInterval: 1,
    startOnTick: true,
    gridLineWidth: 1,
    title: {
      text: null,
    },
    showFirstLabel: false,
    labels: {
      enabled: true,
    },
    minPadding: 0,
    maxPadding: 0,
    visible: true,
    plotLines: [
      {
        width: 2,
        value: dayOfMonth,
        zIndex: 10,
        label: {
          text: '▲',
          style: {
            transform: 'translate(-10px, 215px)',
          },
        },
      },
    ],
  },
  yAxis: {
    visible: true,
    opposite: true,
    min: 92,
    max: 96,
    tickInterval: 2,
    title: '',
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
    },
  },
  rangeSelector: {
    enabled: true,
  },
  series: [
    {
      type: 'spline',
      name: 'values',
      lineWidth: 1,
      zoneAxis: 'x',
      zones: [
        {
          value: dayOfMonth,
        },
        {
          dashStyle: 'dash',
        },
      ],
      data: [],
    },
  ],
};
