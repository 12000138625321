import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useStoreState, useStoreActions } from 'easy-peasy';
import React, { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import {
  CalendarRanges,
  DateTimeFormats,
  Capability,
  PermissionScope,
  DispStatus,
  TaskStatus,
} from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

import { PlanningContext } from '../../../context/planning-provider';
import { GetDispatch, useDispatch, usePatchDispatch } from '../../../data-hooks/use-dispatch';

import DispatchIcon from './dispatched.svg';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advanced);
dayjs.extend(isSameOrAfter);

//style for button
const DispatchSection = styled.div`
  background: ${({ theme }) => theme.manage.calendar.newCrewColor};
  padding: 5px;
  z-index: 1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  .dispatchMessage {
    color: ${({ theme }) => theme.manage.calendar.dispatch.color};
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
    float: left;
    margin-top: 5px;
    display: flex;
  }
  .dispatchTimeStyle {
    color: ${({ theme }) => theme.manage.calendar.dispatchTime.color};
    padding-left: 5px;
  }
  .updateAlertStyle {
    float: left;
  }
  .imgDispatched {
    margin-right: 5px;
  }
  .btn-dispatch {
    margin-right: 50px;
    float: right;
    &.btn-disable {
      background: ${({ theme }) => theme.manage.calendar.dispatch.backgroundColor};
      color: ${({ theme }) => theme.manage.calendar.dispatch.color};
      text-shadow: none;
      border-color: ${({ theme }) => theme.manage.calendar.dispatch.borderColor};
    }
  }
  .updateAlertStyle span {
    padding-left: 5px;
    font-style: italic;
  }
  .loader {
    border: 2px solid ${({ theme }) => theme.manage.calendar.dispatch.borderColor};
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 11px;
    height: 11px;
    -webkit-animation: spin 0.5s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .wrapper {
    position: absolute;
    bottom: 33px;
    right: 1px;
    overflow: hidden;
    width: 100%;
  }
  .toast {
    &.inprogress {
      background-color: ${({ theme }) =>
        theme.manage.calendar.dispatch.inprogressMessage.backgroundColor};
    }
    &.success {
      background-color: ${({ theme }) =>
        theme.manage.calendar.dispatch.successMessage.backgroundColor};
    }
    &.failed {
      background-color: ${({ theme }) =>
        theme.manage.calendar.dispatch.failedMessage.backgroundColor};
    }
  }
  .container {
    font-family: Museo Sans;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    cursor: default;
  }
  .alignIcon {
    padding: 1px;
    vertical-align: top;
    margin-right: 5px;
  }
  .hidden {
    visibility: hidden;
  }
`;

const DisIcon = styled(Icon).attrs((props) => ({
  size: 14,
  color: props.theme.manage.calendar.dispatch.textColor || '',
}))`
  margin-right: 5px;
`;

const TurbineIcon = styled(Icon).attrs((props) => ({
  size: 14,
  color: props.theme.manage.calendar.dispatch.turbineIcon.textColor || '',
}))``;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.manage.calendar.dispatch.turbineIcon.textColor || '',
}))`
  vertical-align: middle;
  padding: 0px 10px;
`;

const CloseMessageIcon = styled(Icon).attrs((props) => ({
  size: 9,
  color: props.theme.manage.calendar.dispatch.turbineIcon.textColor || '',
}))`
  cursor: pointer;
  margin: 5px;
`;

export const DispatchContainer = () => {
  const { t } = useTranslation(['general', 'manage.planning'], { useSuspense: false });

  // Capability checking for dispatch
  const { isAuthorized } = useAuth();
  const editWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    siteIds: [],
  });

  const viewMobileAssignments = isAuthorized({
    capabilities: [{ capability: Capability.MOBILE_ASSIGNMENTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [],
  });

  // Statep
  const {
    planningState: { range, date },
    serviceGroupIds,
  } = useContext(PlanningContext);

  const { dispatchTask, previousTask } = useStoreState((state) => state.tasks);
  const { dispatchCrew, previousCrew, dispDelCrew } = useStoreState((state) => state.workers);
  const { sitesForView } = useStoreState((state) => state.sites); // get list of sites
  const serviceGroupsSites = useStoreState((state) => state.sites.getViewServiceGroupsSites); // get service groups name

  const getUtcDateTimeZone = useMemo(() => dayjs.utc().format(DateTimeFormats.EVENT_DATE_TIME), []);

  const getTimeZone = useMemo(() => sitesForView[0]?.timezone, [sitesForView]);

  const { updatedDispatchCrew, updatePreviousCrew, updateDeletedCrew } = useStoreActions(
    (actions) => actions.workers,
  );
  const { redispatchTaskCond, updatePreviousTask } = useStoreActions((actions) => actions.tasks);

  const serviceGroup = useMemo(() => serviceGroupIds.toString(), [serviceGroupIds]); // get service group id
  const serviceName = serviceGroupsSites.map((elem) => elem.name).toString();

  //Handler for get all associated site from selected service groups
  const { sortedServiceGroups } = useStoreState((state) => state.sites); // Fetches all Service Groups
  const { currentView } = useStoreState((state) => state.view); //Gives list of sites currently selected

  const getAllSitesAssToSerGroup = useCallback(() => {
    const allSitesId = [];
    sortedServiceGroups.forEach((serviceGroup) => {
      serviceGroup.sites.some((elem) => {
        const match = currentView.sites.find((x) => elem.id === x.id);
        if (typeof match !== 'undefined') {
          allSitesId.push(serviceGroup.sites);
        }
      });
    });
    return allSitesId.flat();
  }, [currentView.sites, sortedServiceGroups]);

  const updatedSitesIds = getAllSitesAssToSerGroup();
  const siteIds = [...new Set(updatedSitesIds.map((item) => item.id))];

  const [buttonStatus, setButtonStatus] = useState(true); // enable & disable button flag
  const [refetchDispatch, setRefetchStatus] = useState(false); // enable & disable button flag
  const [hideMessage, setHideMessage] = useState(false); // hide and show message
  const [updateAlert, setUpdateAlert] = useState(false); // hide and show update message
  const [serviceGrpLength, setServiceGrpLen] = useState(serviceGroupIds.length); // set service group length
  const serviceGroupCheck = serviceGrpLength === 1;
  const getSiteTimeZoneDate = useCallback(
    (status) => {
      if (!sitesForView[0]) return null;
      const format = status ? DateTimeFormats.DESPATCH_TIME : DateTimeFormats.ENDPOINT_PARAM;
      return dayjs().tz(getTimeZone).format(format);
    },
    [getTimeZone, sitesForView],
  );
  const [successMessage, setSuccessMessage] = useState(false); // hide and show success message
  const [failedMessage, setFailedMessage] = useState(false); // hide and show success message
  const [isDispatchStatus, setIsDispatchStatus] = useState(false); // hide and show success message
  const [conflictMessage, setConflictMessage] = useState(false); // hide and show conflict message

  //Handle button state on post/get/patch dispatch api success
  const getHandleSuccess = (getData) => {
    const { status, message } = getData;
    if (message) {
      const { dispatched, dispatchStatus } = message[0];
      if (message.length === 1 && status !== DispStatus.NO_CONTENT) {
        if (dispatchStatus === DispStatus.INPROGRESS) {
          setRefetchStatus(true);
        }
        if (dispatchStatus === DispStatus.SUCCESS || dispatchStatus === DispStatus.FAILURE) {
          setRefetchStatus(false);
          setConflictMessage(false);
        }
        if (!dispatched && dispatchStatus === DispStatus.SUCCESS) {
          setButtonStatus(false);
          setUpdateAlert(true);
          setHideMessage(false);
        }
        if (dispatchStatus === DispStatus.SUCCESS && dispatched) {
          setButtonStatus(true);
          changeButtonStatus(false, true);

          showAlertMessageTimer(setSuccessMessage);
        }
        if (dispatchStatus === DispStatus.FAILURE) {
          setButtonStatus(false);
          changeButtonStatus(false, false);

          showAlertMessageTimer(setFailedMessage);
        }
      }
      if (message.length > 1) {
        setButtonStatus(true);
        changeButtonStatus(false, false);
      }
    }
    if (status === DispStatus.NO_CONTENT) {
      emptyState();
      setButtonStatus(false);
      changeButtonStatus(false, false);
    }
  };

  // clear message- timer
  const showAlertMessageTimer = (updateState) => {
    if (isDispatchStatus) {
      updateState(true);
      setIsDispatchStatus(false);
      const messageTime = setTimeout(() => {
        updateState(false);
      }, 10000);
      return () => clearTimeout(messageTime);
    }
  };

  const getHandleError = (getData) => {
    const { status } = getData;
    if (status === DispStatus.CONFLICT) {
      setRefetchStatus(true);
      setConflictMessage(true);
    }
  };

  //Call custom data-hook
  const { mutate: dispatchDetails, data: postDispatch } = useDispatch({
    onSuccess: getHandleSuccess,
    onError: getHandleError,
  });

  const { patchDispatchId } = usePatchDispatch({
    onSuccess: getHandleSuccess,
    onError: getHandleError,
  });

  const { data: dispatchedDetail } = GetDispatch({
    serviceGroup,
    refetchDispatch,
    dispatchDate: getUtcDateTimeZone,
    onSuccess: getHandleSuccess,
    timeZone: getTimeZone,
    onError: getHandleError,
    range,
  });

  // get timezone for site services
  const getFormatedDate = useCallback(
    (newDate, isTask) => {
      const timeZoneDate = newDate ? dayjs(newDate).format(DateTimeFormats.ENDPOINT_PARAM) : null;
      return isTask
        ? dayjs(newDate).tz(getTimeZone).format(DateTimeFormats.ENDPOINT_PARAM)
        : timeZoneDate;
    },
    [getTimeZone],
  );

  const getEventSiteTimeAndZone = useCallback(() => {
    let isCurrTimezone = false;
    if (serviceGroupIds.length > 1) isCurrTimezone = true;
    if (sitesForView.length && serviceGroupIds.length === 1) {
      const calenderDate = getFormatedDate(date);
      if (getSiteTimeZoneDate() === calenderDate) isCurrTimezone = true;
    }
    return isCurrTimezone;
  }, [serviceGroupIds.length, sitesForView.length, getFormatedDate, date, getSiteTimeZoneDate]);

  // Re-Dispatch condition
  const changeButtonStatus = useCallback(
    (isAlert, isMesgHide) => {
      setUpdateAlert(isAlert);
      setHideMessage(isMesgHide);
      const { status, message } = dispatchedDetail || {};
      if (isAlert && message && status !== DispStatus.NO_CONTENT) patchDispatchId(message[0].id);
    },
    [dispatchedDetail, patchDispatchId],
  );

  const emptyState = useCallback(() => {
    updatedDispatchCrew(null);
    updatePreviousCrew(null);
    redispatchTaskCond(null);
    updatePreviousTask(null);
    updateDeletedCrew(null);
  }, [
    redispatchTaskCond,
    updatePreviousCrew,
    updatePreviousTask,
    updatedDispatchCrew,
    updateDeletedCrew,
  ]);

  //check previous and current date for crew, members and tasks
  const checkTaskPreviousDate = useCallback(
    (prevDate, currentDate) => {
      return (
        (dayjs(getSiteTimeZoneDate()).isSame(prevDate) ||
          dayjs(getSiteTimeZoneDate()).isSame(currentDate)) &&
        !dayjs(currentDate).isSame(prevDate)
      );
    },
    [getSiteTimeZoneDate],
  );

  //check previous and current date for crew, members and tasks
  const checkPreviousTask = useCallback(
    (currCrewIds) => {
      if (!previousTask) return true;
      const { crewIds: prevCrewIds } = previousTask;
      return (
        !prevCrewIds || (prevCrewIds && prevCrewIds.length && prevCrewIds[0] !== currCrewIds[0])
      );
    },
    [previousTask],
  );

  const checkbundledTaskId = useCallback((currtask, prevTask) => {
    if (currtask?.length !== prevTask?.length) return true;
    if (currtask?.length === prevTask?.length) {
      const filteredTask = currtask.filter((item) => prevTask.indexOf(item) == -1);
      return filteredTask.length ? true : false;
    }
  }, []);

  const checkIsBundledTask = useCallback(
    (currtask, prevTask, btnStatus) => {
      let isButtonDisabled = btnStatus;
      const { isBundled, bundledTasks, status } = currtask;
      const {
        bundledTasks: prevBundledTasks,
        isBundled: prevIsBundled,
        status: prevStatus,
      } = prevTask || {};

      if (!updateAlert && isBundled && checkbundledTaskId(bundledTasks, prevBundledTasks)) {
        isButtonDisabled = false;
        changeButtonStatus(true, false);
      }

      if (
        (!updateAlert && !isBundled && prevIsBundled) ||
        (!updateAlert && isBundled && !prevIsBundled) ||
        (!isBundled &&
          status === TaskStatus.UNSCHEDULED &&
          prevIsBundled &&
          prevStatus === TaskStatus.SCHEDULED)
      ) {
        isButtonDisabled = false;
        changeButtonStatus(true, false);
      }
      return isButtonDisabled;
    },
    [changeButtonStatus, updateAlert, checkbundledTaskId],
  );

  const checkTaskAvaibilty = useCallback(
    (task, prevTask) => {
      let isButtonDisabled = buttonStatus;
      const { status, scheduleDate, crewIds } = task;
      const { scheduleDate: prevScheduleDate } = prevTask || {};

      if (scheduleDate && status === TaskStatus.SCHEDULED) {
        if (
          !updateAlert &&
          ((dayjs(getSiteTimeZoneDate()).isSame(getFormatedDate(scheduleDate, true)) &&
            checkPreviousTask(crewIds)) ||
            checkTaskPreviousDate(
              getFormatedDate(prevScheduleDate, true),
              getFormatedDate(scheduleDate, true),
            ))
        ) {
          isButtonDisabled = false;
          changeButtonStatus(true, false);
        }
      }
      isButtonDisabled =
        dayjs(getSiteTimeZoneDate()).isSame(getFormatedDate(scheduleDate, true)) &&
        checkIsBundledTask(task, prevTask, isButtonDisabled);
      emptyState();
      return isButtonDisabled;
    },
    [
      buttonStatus,
      emptyState,
      getSiteTimeZoneDate,
      checkPreviousTask,
      checkTaskPreviousDate,
      getFormatedDate,
      changeButtonStatus,
      updateAlert,
      checkIsBundledTask,
    ],
  );

  // check all member start date added to crew to fall on current site timezone
  const checkStartEndDates = useCallback(
    (startDate, endDate) => {
      return endDate
        ? dayjs(getSiteTimeZoneDate()).isBetween(startDate, endDate, null, '[]')
        : dayjs(getSiteTimeZoneDate()).isSameOrAfter(startDate);
    },
    [getSiteTimeZoneDate],
  );

  const checkPreviousCrewDate = useCallback(
    (curStartDate, index, prevCrew) => {
      if (!prevCrew) return false;
      const { crewStartDate: prevStartDate, members } = prevCrew;
      if (members && members[index]) {
        const { startDate: prevMemDate } = members[index].time_periods[0];
        return checkTaskPreviousDate(prevMemDate, curStartDate);
      } else if (!index) return checkTaskPreviousDate(prevStartDate, curStartDate);
      return false;
    },
    [checkTaskPreviousDate],
  );

  // check all member start date added to crew to fall on current site timezone
  const checkMemberStartDate = useCallback(
    (members, prevCrew) => {
      let isAvailDate = false;
      members.map((mem, index) => {
        const { startDate, endDate } = mem.time_periods[0];
        if (checkStartEndDates(startDate, endDate) && !prevCrew) isAvailDate = true;
        if (checkPreviousCrewDate(startDate, index, prevCrew) && prevCrew) isAvailDate = true;
      });
      return isAvailDate;
    },
    [checkStartEndDates, checkPreviousCrewDate],
  );
  // check all member start date added to crew to fall on current site timezone
  const checkCrewAvailibilty = useCallback(
    (crew, prevCrew, isDel) => {
      let isButtonDisabled = buttonStatus;
      const { crewStartDate, crewEndDate, members } = crew;
      const checkDate = checkStartEndDates(crewStartDate, crewEndDate);
      const checkCrewConditions = isDel
        ? checkDate && checkMemberStartDate(members)
        : checkDate ||
          checkPreviousCrewDate(crewStartDate, null, prevCrew) ||
          (checkMemberStartDate(members, prevCrew) && checkDate);
      if (checkCrewConditions && !updateAlert) {
        isButtonDisabled = false;
        changeButtonStatus(true, false);
      }
      emptyState();
      return isButtonDisabled;
    },
    [
      buttonStatus,
      checkStartEndDates,
      checkMemberStartDate,
      checkPreviousCrewDate,
      emptyState,
      changeButtonStatus,
      updateAlert,
    ],
  );

  const checkPrevCrewDel = useCallback(
    (currCrew, prvCrew) => {
      const { startDate, endDate } = prvCrew.members[0].time_periods[0];
      let isButtonDisabled = buttonStatus;
      if (
        !updateAlert &&
        prvCrew.members.length === 1 &&
        !currCrew.members.length &&
        checkStartEndDates(startDate, endDate)
      ) {
        isButtonDisabled = false;
        changeButtonStatus(true, false);
      }
      emptyState();
      return isButtonDisabled;
    },
    [buttonStatus, changeButtonStatus, checkStartEndDates, emptyState, updateAlert],
  );

  const checkDispatchConditions = useCallback(() => {
    /** enable/disable dispatch button while new crew create, any crew when updated,
     *  any members are added to the crew for the following to the site timezone **/
    if (dispatchCrew && dispatchCrew._id && dispatchCrew.members.length)
      setButtonStatus(checkCrewAvailibilty(dispatchCrew, previousCrew));
    //check if current crew member is deleted
    if (dispatchCrew && previousCrew && dispatchCrew.members.length < previousCrew.members.length)
      setButtonStatus(checkPrevCrewDel(dispatchCrew, previousCrew));
    /** enable/disable dispatch button while any crew deleted**/
    if (dispDelCrew && dispDelCrew._id && dispDelCrew.members.length)
      setButtonStatus(checkCrewAvailibilty(dispDelCrew, null, true));
    //enable/disable dispatch button while crew is being created and scheduled to current site date
    const { taskId, crewIds } = dispatchTask || {};
    if (taskId && crewIds && crewIds.length && crewIds[0] !== null)
      setButtonStatus(checkTaskAvaibilty(dispatchTask, previousTask));
  }, [
    checkCrewAvailibilty,
    checkPrevCrewDel,
    checkTaskAvaibilty,
    dispDelCrew,
    dispatchCrew,
    dispatchTask,
    previousCrew,
    previousTask,
  ]);

  const checkEnableDisable = useCallback(() => {
    // enable/disable dispatch button when the service group ids are > 1
    const len = serviceGroupIds.length;
    if (serviceGrpLength !== len && len === 1) {
      setServiceGrpLen(len);
      setButtonStatus(len > 1);
    }
    const { status } = dispatchedDetail;
    if (status !== DispStatus.NO_CONTENT && serviceGroupCheck) {
      checkDispatchConditions();
    }
  }, [
    serviceGroupIds.length,
    serviceGrpLength,
    dispatchedDetail,
    serviceGroupCheck,
    checkDispatchConditions,
  ]);

  useEffect(() => {
    checkEnableDisable();
  }, [checkEnableDisable]);

  // Send dispatch-details to hook
  const dispatchData = () => {
    const postPayload = {
      serviceGroups: [
        {
          serviceGroup,
          serviceName,
          siteIds,
          timeZone: getTimeZone,
        },
      ],
      dispatchedDate: dayjs.utc().format(DateTimeFormats.EVENT_DATE_TIME),
    };
    const { status } = dispatchedDetail;
    if (status === DispStatus.NO_CONTENT) emptyState();
    dispatchDetails(postPayload);
    setIsDispatchStatus(true);
  };

  const getProgressStatus = () => {
    const dispData = refetchDispatch ? postDispatch : dispatchedDetail;
    if (!dispData.message) return false;
    const { dispatchStatus } = dispData.message[0];
    return dispatchStatus === DispStatus.INPROGRESS;
  };

  // Last dispatched time
  const lastDispatchTime = useCallback(() => {
    if (!dispatchedDetail.message) return '';
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { lastDispatchedOn } = dispatchedDetail?.message[0];

    return lastDispatchedOn
      ? dayjs(lastDispatchedOn).utc('Z').tz(getTimeZone).format(DateTimeFormats.DESPATCH_TIME)
      : '';
  }, [dispatchedDetail?.message, getTimeZone]);

  const getProgressMessage = () => {
    return conflictMessage
      ? t('general:dispatch_in_already_progress', 'DISPATCH ALREADY IN PROGRESS')
      : t('general:dispatch_in_progress', 'DISPATCH IN PROGRESS');
  };

  useEffect(() => {
    setButtonStatus(true);
    setRefetchStatus(false);
    setHideMessage(false);
    setUpdateAlert(false);
    setIsDispatchStatus(false);
  }, []);

  return (
    <>
      {editWorkPlan && viewMobileAssignments ? (
        <>
          {range.type === CalendarRanges.DAY &&
          getEventSiteTimeAndZone() &&
          serviceGroupIds.length === 1 ? (
            <DispatchSection>
              {successMessage && (
                <div className="wrapper">
                  <div className="toast success">
                    <div className="container">
                      <div>
                        <TurbineIcon icon={Icons.SITE} className="alignIcon" />
                        {serviceGroup}
                        <ChevronIcon rotate={-90} />
                      </div>{' '}
                      <span>{t('general:dispatch_successful', 'DISPATCH SUCCESSFUL')}</span>
                      <span onClick={() => setSuccessMessage(false)}>
                        <CloseMessageIcon icon={Icons.CLOSE} />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {failedMessage && (
                <div className="wrapper">
                  <div className="toast failed">
                    <div className="container">
                      <div>
                        <TurbineIcon icon={Icons.SITE} className="alignIcon" />
                        {serviceGroup}
                        <ChevronIcon rotate={-90} />
                      </div>{' '}
                      <span>{t('general:dispatch_failed', 'DISPATCH FAILED')}</span>
                      <span onClick={() => setFailedMessage(false)}>
                        <CloseMessageIcon icon={Icons.CLOSE} />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {hideMessage && (
                <div className="dispatchMessage">
                  <img src={DispatchIcon} alt="Send" className="imgDispatched" />{' '}
                  {t('general:dispatched', 'Dispatched')}{' '}
                  <div className="dispatchTimeStyle"> at {lastDispatchTime(true)}</div>
                </div>
              )}
              {updateAlert && (
                <div className="dispatchMessage">
                  <div className="updateAlertStyle">
                    <DisIcon icon={Icons.BELL_ALT_2} />
                    <span>{t('general:updated', 'Updated')}</span>
                  </div>
                </div>
              )}
              <Button
                className={`btn-dispatch ${buttonStatus && 'btn-disable'}`}
                primary
                onClick={() => dispatchData()}
                disabled={buttonStatus}
              >
                {getProgressStatus() || conflictMessage ? (
                  <>
                    <div className="loader"></div>
                    <div className="wrapper">
                      <div className="toast inprogress">
                        <div className="container">
                          <div>
                            <TurbineIcon icon={Icons.SITE} className="alignIcon" />
                            {serviceGroup}
                            <ChevronIcon rotate={-90} />
                          </div>{' '}
                          <span>{getProgressMessage()}</span>
                          <CloseMessageIcon icon={Icons.CLOSE} className="hidden" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>{t('general:dispatch', 'Dispatch')}</>
                )}
              </Button>
            </DispatchSection>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};
