import { PropTypes } from 'prop-types';
import uniq from 'ramda/src/uniq';
import React, { useMemo, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ScrollableDiv } from '@ge/feat-admin/components/handling-procedures/hp-styled-components';
import { getTaskById } from '@ge/feat-manage/util/tasks-util';
import { EntityType, DataLoaderType } from '@ge/models/constants';
import { ErpFormTemplate } from '@ge/shared/components/tasks/templates/erp-form-template';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import EntityDetailHeader, {
  HeaderIcon,
  TypeIcon,
  StyledDetailsDismissInert,
} from '../entity-details-header';

const StyledEntityDetailHeader = styled(EntityDetailHeader)`
  .asset-top {
    padding: 8px;
  }
  .details {
    display: flex;
    padding: 10px 15px;
    .left {
      padding-left: 12px;
      background-color: none;
    }
    .title {
      padding: 13px;
    }
    .buttons {
      margin-left: auto;
    }
  }
`;

const LoaderContainer = styled.div`
  padding: 40px;
`;

const DisplayTaskContainer = styled.section`
  background: ${(props) => props.theme.distributionListSidePanel.formBackground};
  margin-top: 10px;
`;

const BulkServiceRequestPanel = ({ taskId }) => {
  const { t, ready } = useTranslation(['tasks'], {
    useSuspense: false,
  });
  const { bulkTask, taskPagesData: tasks } = useContext(EntityDetailsContext);
  const task = getTaskById(tasks, taskId);

  const differencesOfSelectedTasks = useMemo(() => {
    const differentSite = uniq(bulkTask.map(({ site }) => site.id))?.length;
    const differentAsset = uniq(bulkTask.map(({ asset }) => asset.id))?.length;
    const differentTask = uniq(bulkTask.map(({ id }) => id))?.length;
    return {
      differentSite,
      differentAsset,
      differentTask,
    };
  }, [bulkTask]);

  const { differentSite, differentAsset, differentTask } = differencesOfSelectedTasks;

  if (!ready) {
    return <Loader />;
  }

  return (
    <>
      <StyledEntityDetailHeader type={EntityType.TASK}>
        <div className="left">
          <div className="related">
            <div className="asset-top">
              <StyledDetailsDismissInert className="left">
                <HeaderIcon icon={Icons.SITE} />
                &nbsp;
                <span>
                  {differentSite} {t('bulk_task.sites', 'Sites')}
                </span>
                &nbsp;&nbsp;
                <HeaderIcon icon={Icons.TURBINE} />
                &nbsp;
                <span>
                  {differentAsset} {t('bulk_task.assets', 'Assets')}
                </span>
                &nbsp;&nbsp;
                <HeaderIcon icon={Icons.TASK} />
                &nbsp;
                <span>
                  {differentTask} {t('bulk_task.tasks', 'Tasks')}
                </span>
              </StyledDetailsDismissInert>
            </div>
          </div>
          <div className="details">
            <div>
              <TypeIcon icon={Icons.TASK} />
            </div>
            <div className="title">
              {t('bulk_task.create_service', 'Create New Service Request')}
            </div>
          </div>
        </div>
      </StyledEntityDetailHeader>
      <ScrollableDiv>
        <DisplayTaskContainer>
          {bulkTask?.length >= 1 ? (
            <ErpFormTemplate task={task} />
          ) : (
            <LoaderContainer>
              <DataLoader isLoading={true} type={DataLoaderType.TABLE} />
            </LoaderContainer>
          )}
        </DisplayTaskContainer>
      </ScrollableDiv>
    </>
  );
};

BulkServiceRequestPanel.propTypes = {
  taskId: PropTypes.string.isRequired,
  setShowServiceRequest: PropTypes.func,
  bulkTask: PropTypes.array,
};

BulkServiceRequestPanel.defaultProps = {
  bulkTask: [],
};

export default memo(BulkServiceRequestPanel);
