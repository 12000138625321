import moment from 'moment';

import { crewDisabledTypes } from '@ge/models/constants';

import { FilterValues } from '../../../context/planning-provider';

/**
 * Return a new lane object to inject into month calendar schedule
 *
 * @param dragItem dragging objext from planning provider context
 * @param groupByL1 groupFilter.L1.value from planning provider
 * @param groupByL2 groupFilter.L2.value from planning provider
 * @returns {*}
 */

export const buildNewLane = (dragItem, groupByL1, groupByL2) => {
  const defaultItem = {
    site: dragItem?.site || {},
    asset: dragItem?.asset || {},
    data: [],
    groupBy: dragItem?.title || '',
  };
  return {
    ...defaultItem,
    groupByType: groupByL1,
    secondary: groupByL2
      ? {
          schedule: [
            {
              ...defaultItem,
              groupByType: groupByL2,
            },
          ],
        }
      : null,
  };
};

/**
 * returns the correct group by filter for different group by and lanes
 *
 * @param check one of FilterValues
 * @param lane  lane object from schedule
 * @param item dragItem from planning provider
 * @returns {*}
 */
export const checkDragOverByFilters = (check, lane, item) => {
  const siteAssetCheck = item?.asset?.id
    ? item?.asset?.id === lane?.asset?.id
    : !lane?.asset?.id && item?.site?.id === lane?.site?.id;
  const filterChecks = {
    [FilterValues.SITE_ASSET]: siteAssetCheck,
    [FilterValues.TASK]: lane?.groupBy === item?.title,
    [FilterValues.CREW]: lane?.crew?.id === item?.crew?.value,
  };

  return filterChecks[check];
};

/**
 * returns drag item as array.
 * @param dragItem dragging object from planning provider context
 * @returns {*}
 */
export const getTasksAsArray = (dragItem) => (Array.isArray(dragItem) ? dragItem : [dragItem]);
export const getCrewId = (dragItem) => {
  let crewData = {};
  if (Array.isArray(dragItem)) {
    const value = dragItem[0]?.crewIds;
    crewData['label'] = '';
    crewData['value'] = value ? value[0] : null;
    return crewData;
  } else {
    const value = dragItem?.crewIds[0];
    crewData['lable'] = '';
    crewData['value'] = value ? value[0] : null;
    return crewData;
  }
};

export const getStrTime = (dragItem) => {
  if (Array.isArray(dragItem)) {
    const StrTime = dragItem[0]?.startTime;
    return StrTime;
  }
};

/**
 * returns the first item being dragged.
 * @param dragItem dragging object from planning provider context
 * @returns {*}
 */
export const getSingleDragItem = (dragItem) => (Array.isArray(dragItem) ? dragItem[0] : dragItem);

/**
 * find and highlight matching lane by level 1 and 2 filters.
 * @param groupByL2 groupFilter.L2.value from planning provider
 * @param laneSecondary lane data from calendar
 * @param item dragging object from planning provider context
 * @returns {boolean}
 */

const secondaryFilterCheck = (groupByL2, laneSecondary, item) =>
  groupByL2 ? checkDragOverByFilters(groupByL2, laneSecondary, item) : true;

/**
 * Return a new lane object to inject into month calendar schedule
 *
 * @param dragItem dragging object from planning provider context
 * @param groupByL1 groupFilter.L1.value from planning provider
 * @param groupByL2 groupFilter.L2.value from planning provider
 * @param calendarData schedule data from calendar
 * @returns {laneItem, laneKey}
 */

export const getHighlitedLane = (dragItem, groupByL1, groupByL2, calendarData) => {
  const item = getSingleDragItem(dragItem);
  let laneData = null;
  let laneKey = null;

  calendarData.forEach((lane, index) => {
    if (checkDragOverByFilters(groupByL1, lane, item)) {
      // TODO: remove when crews are implemented - everything is currently in unassigned lane.

      if (groupByL2 === null) {
        laneData = item;
        laneKey = `lane-${index}`;
        return;
      }

      const secondaryScheule = lane.secondary.schedule || null;

      if (secondaryScheule) {
        secondaryScheule.forEach((secondaryLane, indexSecondary) => {
          if (groupByL2 !== 'crew' && secondaryFilterCheck(groupByL2, secondaryLane, item)) {
            laneData = secondaryLane;
            laneKey = `nested-${index}-${indexSecondary}`;
            return;
          }
          if (groupByL2 === 'crew' && secondaryFilterCheck(groupByL2, secondaryLane, item)) {
            laneData = secondaryLane;
            laneKey = `nested-parent-${index}`;
            return;
          }
        });
        return;
      }
    }
  });

  return { laneData, laneKey };
};

/**
 * Return a new lane object to inject into month calendar schedule
 *
 * @param dragItem dragging object from planning provider context
 * @param groupByL1 groupFilter.L1.value from planning provider
 * @param groupByL2 groupFilter.L2.value from planning provider
 * @param lane schedule data from calendar
 * @returns {laneItem, laneKey}
 */

export const setCellHoverClass = (lane, laneSecondary, dragItem, groupByL1, groupByL2) => {
  const item = getSingleDragItem(dragItem);
  const secondryFilterCheck = groupByL2
    ? checkDragOverByFilters(groupByL2, laneSecondary, item)
    : true;
  // check for group by 1 and 2 filter lane types
  if (checkDragOverByFilters(groupByL1, lane, item) && secondryFilterCheck) {
    return true;
  }
};

//takes startTime and returns time in minutes
export const getTimeInMinutes = (val) => {
  if (val !== undefined) {
    let t1 = val.split(':');
    return Number(t1[0]) * 60 + Number(t1[1]);
  } else {
    return 0;
  }
};

//Disabling fields where crew is unavailable
export const isCrewAvaiable = (index, currentDate, crewStartDate, crewEndDate, applyOn) => {
  let currentDay = moment(currentDate).format('dddd').toUpperCase();

  //no disabling for unassigned rows
  if (index === 'lane-unassigned') {
    return { disabled: false, type: crewDisabledTypes.CrewOther };
  }
  //if current date is not part of apply on days
  if (applyOn && !applyOn.includes(currentDay)) {
    if (crewEndDate == null && moment(currentDate).isSameOrAfter(crewStartDate)) {
      return { disabled: false, type: crewDisabledTypes.CrewNotApplyOn };
    }
    if (
      crewEndDate !== null &&
      moment(currentDate).isSameOrBefore(crewEndDate) &&
      moment(currentDate).isSameOrAfter(crewStartDate)
    ) {
      return { disabled: false, type: crewDisabledTypes.CrewNotApplyOn };
    }
  }
  //when secondary filter is active and task assigned to no crews will be treated as unassiged
  if (crewStartDate == undefined && crewEndDate == undefined) {
    return { disabled: false, type: crewDisabledTypes.CrewNotAvailable };
  }
  //when no endDate is present that means crew has no end
  if (crewEndDate == null) {
    if (moment(currentDate).isSameOrAfter(crewStartDate)) {
      return { disabled: false, type: crewDisabledTypes.CrewNotAvailable };
    }
  }
  //when both dates are present check if current is between or equal to start and end
  if (moment(currentDate).isBetween(crewStartDate, crewEndDate, undefined, '[]')) {
    return { disabled: false, type: crewDisabledTypes.CrewNotAvailable };
  }
  //else return true
  return { disabled: true, type: crewDisabledTypes.CrewNotAvailable };
};

//considering time in day view
export const isCrewAvaiableDayView = (index, currentTime, crewStartTime, crewEndTime) => {
  if (index === 'lane-unassigned') {
    return false;
  }

  let ct = getTimeInMinutes(currentTime);
  let cst = getTimeInMinutes(crewStartTime);
  let cet = getTimeInMinutes(crewEndTime);
  // night shift time
  let nst = cet <= cst;

  if (!nst) {
    if (ct >= cst && ct <= cet) {
      return false;
    }
  } else {
    if (ct <= cet) {
      return false;
    } else if (ct >= cst) {
      return false;
    }
  }
  //else return true
  return true;
};
