import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Recurrence } from '../../../task-fields/recrurrence';
import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditRecurrence = ({ metadata, task, templateMode }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.recurrence', 'Recurrence Section')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          {!metadata?.recurrence.hidden && (
            <Recurrence metadata={metadata.recurrence.edit} task={task} templateMode={templateMode} />
          )}
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

EditRecurrence.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};
