import { EntityType } from '@ge/models/constants';

import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_API;
const BASE_URL_ASSETS = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_ASSET_API;

export const getConditionsKpiData = ({
  categories,
  endDate,
  entityAggr,
  entityIds,
  entityType,
  filters,
  startDate,
  timeAggr,
}) =>
  request.post(
    '/dav/conditions-kpi-data',
    {
      categories,
      endDate,
      entityAggr,
      entityIds,
      entityType,
      filters,
      startDate,
      timeAggr,
    },
    {
      baseURL: BASE_URL,
    },
  );

export const getKpiData = ({
  categories,
  endDate,
  entityAggr,
  entityId,
  entityType,
  filters,
  startDate,
  timeAggr,
  passThrough,
  isMal,
}) => {
  const url = {
    [EntityType.FLEET]: '/dav/fleets/kpi-data',
    [EntityType.REGION]: `/dav/regions/${entityId}/region-kpi-data`,
    [EntityType.SITE]: '/dav/sites/sites-kpi-data',
    [EntityType.TURBINE]: `/dav/assets/${entityId}/asset-kpi-data`,
  }[entityType];

  // is there a better way to handle this error?
  if (!url) {
    const error = `Entity type '${entityType}' is not recognized`;

    throw new Error(error);
  }

  return request.post(
    url,
    {
      categories,
      endDate,
      entityAggr,
      entityType,
      filters,
      startDate,
      timeAggr,
      passThrough,
      isMal,
    },
    {
      baseURL: BASE_URL_ASSETS,
    },
  );
};
