import { useStoreState } from 'easy-peasy';

const useSiteSearch = (filterText) => {
  const getFilteredSites = useStoreState((state) => state.sites.getFilteredSites);
  const sites = getFilteredSites(filterText);

  return { sites };
};

export default useSiteSearch;
