import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AccordionList = ({ children, showCount, onAccordionToggled }) => {
  const { t } = useTranslation(['general'], {
    useSuspense: false,
  });
  const [showAll, setShowAll] = useState(false);

  const toggleVisible = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    onAccordionToggled(showAll);
  }, [onAccordionToggled, showAll]);

  return (
    <>
      {children.map((child, i) => (i < showCount || showAll) && child)}
      {children.length > showCount && (
        <button type="button" onClick={() => toggleVisible()}>
          {showAll ? t('less', 'Less') : t('more', 'More')}
        </button>
      )}
    </>
  );
};

AccordionList.propTypes = {
  children: PropTypes.node.isRequired,
  showCount: PropTypes.number,
  onAccordionToggled: PropTypes.func,
};

AccordionList.defaultProps = {
  showCount: 0,
  onAccordionToggled: () => null,
};

export default AccordionList;
