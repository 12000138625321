import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { Capability, PermissionScope, TaskSourceField } from '@ge/models/constants';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util';

import TaskEscalationMenu from '../menu/task-escalation-menu';

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))``;

const AddTaskEscalationIcon = styled(Icon).attrs(({ theme }) => ({
  size: 10,
  icon: Icons.ADD,
  color: theme.table.iconLightColor,
}))``;

const StyledEscalationColumn = styled.div`
  height: 14px;
`;

const TaskEscalationColumn = ({ taskEscalation, tasksData, entityId, entity }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const { setSelectedAssetToEscalate } = useStoreActions((actions) => actions.tasks);

  const { isAuthorized } = useAuth();
  const authorizedForTaskEscalation = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.CREATE] }],
  });

  const handleAddTaskEscalation = (e, entity) => {
    setSelectedAssetToEscalate(entity);
    killEventPropagation(e);
    setShowCreateTaskModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateTaskModal(false);
    setSelectedAssetToEscalate({});
    setAnchorEl(null);
  };

  return (
    <StyledEscalationColumn
      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
      onClick={(e) => killEventPropagation(e)}
    >
      {taskEscalation > 0 ? (
        <TaskEscalationMenu
          tasksData={tasksData}
          titleDefault="FIELD TASK"
          entityId={entityId}
          entity={entity}
        >
          <StyledBadge small label={taskEscalation} />
        </TaskEscalationMenu>
      ) : (
        anchorEl &&
        authorizedForTaskEscalation && (
          <div onClick={(e) => handleAddTaskEscalation(e, entity)}>
            <AddTaskEscalationIcon />
          </div>
        )
      )}
      {showCreateTaskModal && (
        <NewTaskDialog
          onClose={handleCloseModal}
          onConfirm={() => setShowCreateTaskModal(false)}
          taskSource={TaskSourceField.REALTIMECASES}
          entityId={entityId}
          entity={entity}
          isCalledfromTaskEscalationColumn={true}
          setAnchorEl={setAnchorEl}
        />
      )}
    </StyledEscalationColumn>
  );
};

TaskEscalationColumn.propTypes = {
  taskEscalation: PropTypes.number,
  tasksData: PropTypes.instanceOf(Object),
  entityId: PropTypes.number,
  entity: PropTypes.instanceOf(Object),
};

export default TaskEscalationColumn;
