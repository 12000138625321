import classNames from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Selecto from 'react-selecto';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { useBulkScheduleTask } from '@ge/feat-manage/data-hooks/use-bulk-schedule-task';
import { TasksTime } from '@ge/models';
import { DataLoaderType, Capability, PermissionScope } from '@ge/models/constants';
import { RescheduleTasksDialog } from '@ge/shared/components/actions-reschedule-tasks-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { debounce } from '@ge/shared/util/general';
import { elevations } from '@ge/tokens/elevations';

import { PlanningContext, FilterValues, DragItemTypes } from '../../../context/planning-provider';
import { sortSchedule } from '../../../data-hooks/use-task-schedule';
import { LaneGroupBy } from '../lane-group-by';
import {
  buildNewLane,
  getHighlitedLane,
  setCellHoverClass,
  getTasksAsArray,
  getTimeInMinutes,
  isCrewAvaiableDayView,
  getStrTime,
  getCrewId,
} from '../month/month-util';

import { CalendarDayCard } from './calendar-day-card';

dayjs.extend(duration);

const Lane = styled.div`
  min-height: 110px;
  width: ${({ width }) => width}px;
  z-index: ${elevations.P2};
  position: relative;
  display: flex;
  flex-direction: row;
`;

const LaneItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const WeekContainer = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  justify-content: center;
  border-right: solid 1px transparent;
  position: relative;
  &.disabled {
    background: repeating-linear-gradient(-40deg, #222, #222 4px, #4a4949 4px, #4a4949 5px);
  }
  &.hovered {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      border: 1px solid ${({ theme }) => theme.manage.timeline.dragDrop.cell.highlight.border};
      background-color: ${({ theme }) => theme.manage.timeline.dragDrop.cell.highlight.background};
      box-shadow: ${({ theme }) => theme.manage.timeline.dragDrop.cell.highlight.boxShadow};
    }
    &:after {
      right: 0;
      left: auto;
    }
  }
`;

const Container = styled.div`
  ${Lane} {
    &:nth-child(odd) {
      background: ${({ theme }) => theme.manage.calendar.laneEvenBackground};
    }
    &:nth-child(even) {
      background: ${({ theme }) => theme.manage.calendar.laneOddBackground};
    }
    &.isDragover {
      background-color: ${({ theme }) => theme.manage.timeline.dragDrop.lane.background};
      box-shadow: ${({ theme }) => theme.manage.timeline.dragDrop.lane.boxShadow};
      ${WeekContainer} {
        box-shadow: inset 0 0 1px ${({ theme }) => theme.manage.timeline.dragDrop.cell.boxShadow};
      }
    }
  }
  // styles for selecto drag box
  > .selecto-selection {
    background: ${({ theme }) => theme.lasso.background} !important;
    border: 1px solid ${({ theme }) => theme.lasso.borderColor};
  }
  width: ${({ width }) => width}px;
  padding-right: 20px;
`;

const TaskGroupContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 110px;
`;

const WeekWrapper = styled.div`
  border-right: solid 1px ${({ theme }) => theme.manage.timeline.borderColor};
  display: flex;
  z-index: 0;
  &:last-child {
    border-right: 0;
  }
  > div {
    flex: 1;
    display: block;
    &:last-child {
      border-right: 0;
    }
  }
`;

const StyledDataLoader = styled(DataLoader).attrs(({ theme }) => ({
  noDataIconColor: theme.manage.timeline.noDataIconColor,
  type: DataLoaderType.GRID,
}))``;

export const CalendarDayView = ({
  scheduleData,
  unassignedData,
  refetch,
  isLoading,
  isError,
  workers,
}) => {
  const {
    planningState: {
      hour,
      quarterHour,
      sidebarWidth,
      timelineSchemaDay,
      groupFilter: {
        L1: { value: groupByL1 },
        L2: { value: groupByL2 },
      },
      groupFilter,
      handleDragOver,
      dragItem,
      dragItemType,
      setTempNewLane,
      tempNewLane,
      range,
    },
    serviceGroupIds,
    planningState,
    planningState: { dragItemBacklogType },
  } = useContext(PlanningContext);
  const { t } = useTranslation(['general', 'manage.planning'], { useSuspense: false });
  const bundleTasks = planningState.bundleTasks ? true : false;
  const { showTaskDetails } = useContext(EntityDetailsContext);
  const [calendarData, setCalendarData] = useState(null);
  const [calendarDataUnassigned, setCalendarDataUnassigned] = useState(null);
  const [highlightedLane, setHighlightedLane] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [shiftCount, setShiftCount] = useState(0);
  const [shiftCountMins, setShiftCountMins] = useState(0);
  const [hovered, setHovered] = useState(null);
  const [dropDate, setDropDate] = useState(null);
  const [dropTime, setDropTime] = useState(null);
  const [dialogTaskReschedule, setDialogTaskReschedule] = useState(false);
  const [tasktobeBundled, setTasktobeBundled] = useState([]);
  const [crewHover, setCrewHover] = useState(null);
  const [crewLaneInfo, setCrewLaneInfo] = useState(null);

  const laneWidth = 24 * hour + sidebarWidth;
  const selectoRef = useRef();

  const handleOnDrop = (e, task, dropDate, dropTime, dragItemType, crewDetails, allTasks) => {
    setCrewLaneInfo(crewDetails);
    // get the selected card elements
    let selectedCards = selectoRef.current.getSelectedTargets();
    let tasks = [];
    // clear selected targets when dragging from backlog and set selectedCards to empty array
    if (dragItemType === DragItemTypes.BACKLOG) {
      selectoRef?.current?.setSelectedTargets([]);
      setSelectedTasks([]);
      if (selectedCards.length > 0) {
        selectedCards.forEach((el) => {
          el.classList.remove('selected');
        });
      }
      selectedCards = [];
    }
    // check if the user is dragging a card without using lasso
    if (selectedCards.length === 0) {
      tasks = task;
    } else {
      //loop the cards to get the task data from scheduleData
      selectedCards.forEach((el) => {
        const { laneindex, dayindex, secondaryindex, id } = el.dataset;
        let ids = id.split(',');
        //if selected is crew take tasks from calendarDataUnassigned
        if (groupFilter.L1.value === FilterValues.CREW) {
          calendarDataUnassigned[laneindex].tasks[dayindex].tasks.forEach((task) => {
            if (ids.includes(task.id)) {
              tasks.push(task);
            }
          });
        } else {
          if (groupFilter.L2.value === null || groupFilter.L2.value === FilterValues.CREW) {
            calendarData[laneindex].data[dayindex].tasks.forEach((task) => {
              if (ids.includes(task.id)) {
                tasks.push(task);
              }
            });
          } else {
            //If secondary filter get the tasks from nested secondary array.
            calendarData[laneindex].secondary.schedule[secondaryindex].data[dayindex].tasks.forEach(
              (task) => {
                if (ids.includes(task.id)) {
                  tasks.push(task);
                }
              },
            );
          }
        }
      });
    }
    const { startTime } = task[0] || {};
    let count = 0;
    if (startTime && dropTime) {
      const start = getTimeInMinutes(startTime);
      const end = getTimeInMinutes(dropTime);
      count = start && end ? end - start : 0;
    }
    let scheduledTasks = [];
    if (bundleTasks) {
      task.map((task) => {
        allTasks?.forEach((eachtask) => {
          if (eachtask.asset.id === task.asset.id) {
            scheduledTasks.push(eachtask);
          }
        });
      });
    }
    //pass the day shift count to the dialog
    setShiftCountMins(count);
    setShiftCount(0);
    setDropDate(dropDate);
    setDropTime(dropTime);
    setHovered(null);
    setSelectedTasks(tasks);
    setDialogTaskReschedule(true);
    setCrewHover(null);
    setTasktobeBundled(scheduledTasks);
  };

  // using debounce to prevent the hover from firing on every dragEnter.
  const hoverHandler = debounce((id) => setHovered(id), 100);

  // calling nested hover handler with debounce once to prevent calling one for every drag enter.
  const onDragEnter = (id) => {
    hoverHandler(id);
  };

  // Checks if a task overlaps with another task in the same lane
  const checkOverlappingTask = useCallback((tasks, task) => {
    if (tasks.length == 0) {
      return false;
    }

    let x1 = getStartEndPositions(task.startTime, task.estDurationHours, task.estDurationMinutes);

    for (var i = 0; i < tasks.length; i++) {
      //check for same asset and crew here
      if (task.asset?.id == tasks[i].asset?.id && task?.startTime == tasks[i].startTime) {
        return false;
      }

      let x2 = getStartEndPositions(
        tasks[i].startTime,
        tasks[i].estDurationHours,
        tasks[i].estDurationMinutes,
      );
      //equal
      if (x1.start == x2.start && x1.end == x2.end) {
        return false;
      }
      //behind
      else if (x1.start < x2.start && x1.end < x2.start) {
        return false;
      }
      //overlapping
      else if (x1.start > x2.start && x1.start < x2.end) {
        return true;
      } else if (x1.start < x2.start && x1.end > x2.start) {
        return true;
      } else if (x1.start < x2.start && x1.end > x2.end) {
        return true;
      } else if (x1.start == x2.start && x1.start < x2.end) {
        return true;
      }
    }
    return false;
  }, []);

  const buildLanes = useCallback(
    (arr) => {
      //If startTime not present in a task set it to DEFAULT_START_TIME
      const tasks = arr.map((x) => {
        let task = Object.assign({}, x);
        task.startTime = task.startTime ? task.startTime : TasksTime.DEFAULT_START_TIME;
        return task;
      });

      // sort so that earliest comes first in the lane
      tasks.sort((a, b) => getTimeInMinutes(a.startTime) - getTimeInMinutes(b.startTime));
      let laneData = [];
      laneData.push({ tasks: [] });

      tasks.forEach((task) => {
        for (var i = 0; i < laneData.length; i++) {
          let doesOverlap = checkOverlappingTask(laneData[i].tasks, task);
          //if doesnot overlap push to same lane
          if (!doesOverlap) {
            laneData[i].tasks.push(task);
            break;
          } //if overlaps - create new lane and push
          else if (doesOverlap && i == laneData.length - 1) {
            let data = [];
            data.push(task);
            laneData.push({ tasks: data });
            break;
          }
        }
      });

      return laneData;
    },
    [checkOverlappingTask],
  );

  // if no longer dragging and not rescheduling remove the temporary lane
  useEffect(() => {
    if (!dragItem && tempNewLane && !dialogTaskReschedule && !highlightedLane) {
      setTempNewLane(null);
    }
  }, [tempNewLane, dragItem, dialogTaskReschedule, highlightedLane, setTempNewLane]);

  // update data to support multiple containers in single lane
  useEffect(() => {
    let temp = tempNewLane ? sortSchedule([tempNewLane, ...scheduleData]) : scheduleData;
    const calData = temp.map((obj) => {
      if (obj?.secondary?.schedule.length) {
        obj.secondary.schedule.forEach((item) => {
          if (item.data.length) {
            item.data = buildLanes(item.data[0].tasks);
          }
        });
        return obj;
      } else {
        if (obj.data.length > 0) {
          return { ...obj, data: buildLanes(obj.data[0].tasks) };
        }
        return obj;
      }
    });
    setCalendarData(calData);
  }, [buildLanes, scheduleData, tempNewLane]);

  //update to support multiple containers in single lane
  useEffect(() => {
    //for unassigned data : when CREW is selected
    if (unassignedData !== null && unassignedData !== undefined && unassignedData[0].tasks.length) {
      unassignedData.forEach((item) => {
        item.tasks = buildLanes(item.tasks);
      });
      setCalendarDataUnassigned(unassignedData);
    } else {
      setCalendarDataUnassigned(null);
    }
  }, [buildLanes, unassignedData]);

  //On backlog drag item check lanes and add new placeholder if not present
  useEffect(() => {
    if (!dragItem || dragItemType === DragItemTypes.TECH) {
      setHighlightedLane(null);
      setCrewHover(null);
      return;
    }

    if (groupByL1 === 'crew') {
      setCrewHover(true);
      return;
    }

    // find highlited lane
    const laneItem = getHighlitedLane(dragItem, groupByL1, groupByL2, calendarData);

    //set the highlighted lane
    if (laneItem.laneKey) {
      setHighlightedLane(laneItem.laneKey);
    }

    //if drag item cannot be found in current lanes build new one.
    if (!laneItem.laneData && !tempNewLane) {
      setTempNewLane(buildNewLane(dragItem, groupByL1, groupByL2));
    }
  }, [dragItem, calendarData, groupByL1, groupByL2, setTempNewLane, tempNewLane, dragItemType]);

  const { isAuthorized } = useAuth();

  const buildDayGrid = (index, lane, laneSecondary, rowIndex) =>
    Object.keys(timelineSchemaDay).map((key) => {
      const { time, date } = timelineSchemaDay[key];
      let crewLaneData = laneSecondary?.groupByCrew
        ? laneSecondary?.groupByCrew?.crew
        : lane?.groupByCrew?.crew;

      // checking for array to provide to bulk reschedule modal
      const tasks = getTasksAsArray(dragItem);
      const id = `lane-${time}-${index}`;

      const disabled =
        groupFilter.L1.value === 'crew' || groupFilter.L2.value === 'crew'
          ? isCrewAvaiableDayView(
              index,
              time,
              groupFilter.L1.value === 'crew'
                ? lane?.crewShiftStartTime
                : laneSecondary?.crewShiftStartTime,
              groupFilter.L1.value === 'crew'
                ? lane?.crewShiftEndTime
                : laneSecondary?.crewShiftEndTime,
            )
          : false;
      return (
        <WeekWrapper key={key}>
          <WeekContainer
            className={classNames({
              hovered:
                crewHover && dragItem && hovered === id
                  ? crewHover
                  : dragItem && hovered === id && highlightedLane === `nested-parent-${rowIndex}`
                  ? true
                  : dragItem &&
                    hovered === id &&
                    setCellHoverClass(lane, laneSecondary, dragItem, groupByL1, groupByL2),
              disabled: disabled,
            })}
            width={quarterHour}
            key={id}
            onDrop={(e) =>
              (highlightedLane === `nested-parent-${rowIndex}` && hovered === id) ||
              crewHover ||
              (hovered === id && index === highlightedLane)
                ? handleOnDrop(e, tasks, date, time, dragItemType, crewLaneData)
                : null
            }
            onDragEnter={() => onDragEnter(id)}
          />
        </WeekWrapper>
      );
    });
  const { fetchTasks } = useStoreActions((store) => store.tasks);
  const { sitesForView } = useStoreState((state) => state.sites);
  const siteIds = useMemo(() => sitesForView.map((s) => s.id), [sitesForView]);

  const { bulkSchedule } = useBulkScheduleTask({
    onSuccess: () => {
      fetchTasks({ start: range.start, end: range.end, siteIds });
      let selectedCards = selectoRef?.current.getSelectedTargets();
      if (selectedCards.length > 0) {
        selectedCards.forEach((el) => {
          el.classList.remove('selected');
        });
      }
      selectoRef?.current?.setSelectedTargets([]);
      setSelectedTasks([]);
      setTempNewLane(null);
      setDialogTaskReschedule(false);
      setShiftCount(0);
    },
    onError: (e) => console.error('Error scheduling', e),
  });

  const handleSaveAll = (data) => {
    bulkSchedule(data);
  };

  const taskCardClick = (e, id) => {
    // if the user is not holding down shift show task details
    if (e.shiftKey) {
      const el = e.currentTarget;
      const selectedTargets = selectoRef.current.getSelectedTargets();
      const index = selectedTargets.indexOf(el);

      // add remove the selected card to the targets list and add/remove selected class
      if (index > -1) {
        selectedTargets.splice(index, 1);
        el.classList.remove('selected');
        selectoRef.current.setSelectedTargets([...selectedTargets]);
      } else {
        el.classList.add('selected');
        selectoRef.current.setSelectedTargets([...selectedTargets, el]);
      }
    } else id && showTaskDetails(id);
  };

  // Permissions check for dragging cards.
  const isDraggable = (tasks) => {
    const task = getTasksAsArray(tasks);
    const siteId = task[0]?.site?.id;

    return isAuthorized({
      capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
      siteIds: [siteId],
    });
  };

  const getStartEndPositions = (val, h, m) => {
    let t1 = val.split(':');
    return {
      start: t1.length ? Number(t1[0]) * 60 + Number(t1[1]) : 0,
      end: t1.length ? Number(t1[0]) * 60 + Number(t1[1]) + h * 60 + m : 0,
    };
  };

  return (
    <>
      <Container width={laneWidth}>
        <StyledDataLoader
          noData={!calendarData?.length && !calendarDataUnassigned?.length}
          renderCondition
          onRetry={isError ? refetch : null}
          isLoading={isLoading}
          type={DataLoaderType.GRID}
          margin="100px auto"
          noDataTitle={
            isError
              ? t('no_data_title', 'No Data Available')
              : t('manage.planning:no_tasks', 'No Tasks on Your Plan')
          }
        >
          <Selecto
            ref={selectoRef}
            container={document.body}
            selectableTargets={['.lasso-card']}
            selectByClick={false}
            selectFromInside={false}
            continueSelect={false}
            toggleContinueSelect={'shift'}
            keyContainer={window}
            hitRate={10}
            onSelect={(e) => {
              e.added.forEach((el) => el.classList.add('selected'));
              e.removed.forEach((el) => el.classList.remove('selected'));
            }}
          />
          {/* unassigned row currently in use for crews */}
          {groupFilter.L1.value === FilterValues.CREW && calendarDataUnassigned && (
            <Lane
              key={`lane-unassigned`}
              width={laneWidth}
              className={classNames({
                isDragover: dragItem,
              })}
            >
              <LaneGroupBy unassigned sidebarWidth={sidebarWidth} workers={workers} />
              <LaneItems>
                {calendarDataUnassigned[0].tasks.map(({ tasks }, itemIndex) => (
                  <TaskGroupContainer
                    key={`tgc-${itemIndex}`}
                    onDragOver={(e) => handleDragOver(e)}
                  >
                    {buildDayGrid('lane-unassigned')}

                    <CalendarDayCard
                      date={range.start}
                      tasks={tasks}
                      sidebarWidth={sidebarWidth}
                      top={itemIndex * 110 + 20}
                      laneIndex={0}
                      itemIndex={itemIndex}
                      // onDrop={(e) => handleOnDrop(e, getTasksAsArray(dragItem), null, null)}
                      onClick={taskCardClick}
                      draggable={
                        serviceGroupIds.length === 1
                          ? isDraggable(tasks)
                          : groupFilter.L1.value === FilterValues.CREW ||
                            groupFilter.L2.value === FilterValues.CREW
                          ? false
                          : isDraggable(tasks)
                      }
                    />
                  </TaskGroupContainer>
                ))}
              </LaneItems>
            </Lane>
          )}
          {groupFilter.L1.value &&
            calendarData &&
            calendarData.map((lane, index) => {
              if (lane?.secondary?.schedule.length) {
                return lane?.secondary.schedule.map((laneSecondary, indexSecondary) => (
                  <Lane
                    key={`nested-${index}-${indexSecondary}`}
                    width={laneWidth}
                    className={classNames({
                      isDragover: crewHover
                        ? crewHover
                        : highlightedLane === `nested-parent-${index}`
                        ? true
                        : highlightedLane === `nested-${index}-${indexSecondary}`,
                    })}
                  >
                    <LaneGroupBy
                      sidebarWidth={sidebarWidth}
                      groupByL1={lane}
                      groupByL2={laneSecondary}
                      showGroupByL1={indexSecondary === 0}
                      workers={workers}
                    />

                    <LaneItems>
                      {laneSecondary.data.map(({ tasks }, itemIndex) => (
                        <TaskGroupContainer
                          key={`tgc-${itemIndex}`}
                          onDragOver={(e) => handleDragOver(e)}
                        >
                          {buildDayGrid(
                            `nested-${index}-${indexSecondary}`,
                            lane,
                            laneSecondary,
                            index,
                          )}

                          <CalendarDayCard
                            date={range.start}
                            tasks={tasks}
                            sidebarWidth={sidebarWidth}
                            top={itemIndex * 110 + 20}
                            laneIndex={index}
                            itemIndex={itemIndex}
                            secondaryIndex={indexSecondary}
                            // onDrop={(e) => handleOnDrop(e, getTasksAsArray(dragItem), null, null)}
                            onClick={taskCardClick}
                            draggable={
                              serviceGroupIds.length === 1
                                ? isDraggable(tasks)
                                : groupFilter.L1.value === FilterValues.CREW ||
                                  groupFilter.L2.value === FilterValues.CREW
                                ? false
                                : isDraggable(tasks)
                            }
                          />
                        </TaskGroupContainer>
                      ))}

                      {/* create grid when lane created dynamically */}
                      {laneSecondary?.data?.length == 0 && (
                        <TaskGroupContainer
                          key={`tgc-dynamic`}
                          onDragOver={(e) => handleDragOver(e)}
                        >
                          {buildDayGrid(`nested-${index}-${indexSecondary}`, lane, laneSecondary)}
                        </TaskGroupContainer>
                      )}
                    </LaneItems>
                  </Lane>
                ));
              } else {
                return (
                  <>
                    <Lane
                      key={`lane-${index}`}
                      width={laneWidth}
                      className={classNames({
                        isDragover: crewHover ? crewHover : highlightedLane === `lane-${index}`,
                      })}
                    >
                      <LaneGroupBy
                        sidebarWidth={sidebarWidth}
                        groupByL1={lane}
                        showGroupByL1
                        workers={workers}
                      />

                      <LaneItems>
                        {lane.data.map(({ tasks }, itemIndex) => (
                          <TaskGroupContainer
                            key={`tgc-${itemIndex}`}
                            onDragOver={(e) => handleDragOver(e)}
                          >
                            {buildDayGrid(`lane-${index}`, lane)}

                            <CalendarDayCard
                              date={range.start}
                              tasks={tasks}
                              sidebarWidth={sidebarWidth}
                              top={itemIndex * 110 + 20}
                              laneIndex={index}
                              itemIndex={itemIndex}
                              onDrop={(e) =>
                                handleOnDrop(
                                  e,
                                  getTasksAsArray(dragItem),
                                  range.start,
                                  getStrTime(tasks),
                                  null,
                                  getCrewId(tasks),
                                  tasks,
                                )
                              }
                              onClick={taskCardClick}
                              draggable={
                                serviceGroupIds.length === 1
                                  ? isDraggable(tasks)
                                  : groupFilter.L1.value === FilterValues.CREW ||
                                    groupFilter.L2.value === FilterValues.CREW
                                  ? false
                                  : isDraggable(tasks)
                              }
                            />
                          </TaskGroupContainer>
                        ))}

                        {/* create grid when lane created dynamically */}
                        {lane?.data?.length == 0 && (
                          <TaskGroupContainer
                            key={`tgc-dynamic`}
                            onDragOver={(e) => handleDragOver(e)}
                          >
                            {buildDayGrid(`lane-${index}`, lane)}
                          </TaskGroupContainer>
                        )}
                      </LaneItems>
                    </Lane>
                  </>
                );
              }
            })}
        </StyledDataLoader>
      </Container>
      {dialogTaskReschedule && (
        <RescheduleTasksDialog
          tasks={selectedTasks}
          onClose={() => {
            setDialogTaskReschedule(false);
            setSelectedTasks([]);
            setTempNewLane(null);
            setShiftCount(0);
          }}
          onSaveAll={handleSaveAll}
          shiftCount={shiftCount}
          shiftCountMins={shiftCountMins}
          dropDate={dropDate}
          dropTime={dropTime}
          isDayView
          crewLaneInfo={crewLaneInfo}
          tasktobeBundled={tasktobeBundled}
          bundleTasks={bundleTasks}
          dragItemBacklogType={dragItemBacklogType}
        />
      )}
    </>
  );
};

CalendarDayView.propTypes = {
  scheduleData: PropTypes.instanceOf(Array),
  unassignedData: PropTypes.instanceOf(Array),
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  workers: PropTypes.instanceOf(Object),
};

CalendarDayView.defaultProps = {
  scheduleData: null,
  unassignedData: null,
  refetch: () => {},
  isLoading: false,
  isError: false,
  workers: null,
};
