import { useEffect, useRef, useState } from 'react';

import { KeyCode, EntityType } from '@ge/models/constants';
import { arrayify } from '@ge/shared/util/general';

const useDismissListeners = (containerRefs, onDismiss, entityType, entityTab) => {
  const handleEvent = useRef(null);
  const [containerRefsArray] = useState(arrayify(containerRefs));

  /**
   * Initialize the listeners based on the provided container ref.
   */
  useEffect(() => {
    let dismiss = false;

    // Event handler for this open panel
    handleEvent.current = (e) => {
      // Handle mouse event
      if (e.type === 'mousedown' && entityType !== EntityType.PERSON) {
        dismiss =
          (entityTab !== EntityType.HISTORY || entityType !== EntityType.TURBINE) &&
          !!containerRefsArray.find((containerRef) => !containerRef.current?.contains(e.target));
      }
      // Handle keyboard event
      if (e.type === 'keydown') {
        dismiss = e.keyCode && e.keyCode === KeyCode.ESCAPE;
      }
      //handle the dismiss event when the calss name appering in the parent element
      if (e.target.closest('.no-dismiss-alert')) {
        dismiss = false;
      }

      if (dismiss) {
        onDismiss(e);
      }
    };

    // Add event handler to document events
    document.addEventListener('mousedown', handleEvent.current, false);
    document.addEventListener('keydown', handleEvent.current, false);

    return () => {
      document.removeEventListener('mousedown', handleEvent.current, false);
      document.removeEventListener('keydown', handleEvent.current, false);
    };
  }, [handleEvent, onDismiss, containerRefsArray, entityTab, entityType]);
};

export default useDismissListeners;
