import { KpiCategoryDefs } from '@ge/models/constants';

export const SitesTableCategories = [
  KpiCategoryDefs.TBA,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.PRODUCTION_ACTUAL,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.AVAILABILITY_CONTRACT,
];
