import { Site } from '@ge/models/entities';

import * as request from './api';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;
const DAV_ASSET_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_ASSET_API;
const RTMC_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;
/**
 * Helper method to build the request params object for Axios request.
 *
 * @param {String} sort Metric to sort on
 * @param {String} direction Sort direction
 * @param {Boolean} metrics Include metrics in response
 */
const buildSortOptions = (sort, direction, metrics) => {
  const options = {};

  if (sort && typeof sort === 'string') {
    options.params = {
      sort,
      direction,
      metrics,
    };
  }

  return options;
};

const buildDateRangeOptions = (startDate, endDate) => {
  const options = {
    params: {},
  };

  if (startDate) {
    options.params.startDate = startDate;
  }

  if (endDate) {
    options.params.endDate = endDate;
  }

  return options;
};

export const fetchSites = async () =>
  request.post(
    '/cmn/sites',
    {},
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => {
          let sites = data;

          if (sites?.length) {
            sites = sites.map((site) => new Site(site));
          }

          return { sites };
        },
      ],
    },
  );

export const fetchSiteById = async ({ id }) =>
  request.post(
    `/cmn/sites/${id}`,
    {},
    {
      baseURL: CMN_BASE_URL,
      transformResponse: [
        (data) => {
          return { site: new Site(data) };
        },
      ],
    },
  );

export const fetchSiteMetrics = (siteId, assetIds = [], type = 'wind') =>
  request.post(
    `/rtmc/sites/${siteId}/rt-values`,
    { assetIds },
    {
      baseURL: RTMC_BASE_URL,
      params: { siteType: type },
    },
  );

export const fetchAssetStatuses = (assetIds, includeFields) => {
  return request.get('/rtmc/asset/rt-status', {
    baseURL: RTMC_BASE_URL,
    params: {
      assetIds: assetIds?.join(','),
      idType: 'canonical',
      includeFields,
    },
  });
};

export const fetchSiteAssetsWithMetrics = (siteId) =>
  request.get(`/sites/${siteId}/assets`, {
    transformResponse: [
      (data) => {
        if (data?.sites) {
          data.sites = data.sites?.map((site) => new Site(site));
        }

        return data;
      },
    ],
  });

export const fetchSitesWithMetrics = (sortMetric, sortDirection) =>
  request.get('/sites', {
    ...buildSortOptions(sortMetric, sortDirection, true),
    transformResponse: [
      (data) => {
        if (data?.sites) {
          data.sites = data.sites?.map((site) => new Site(site));
        }

        return data;
      },
    ],
  });

// TODO: We added the start and end date parameters to this function but they are not being used.
//  This range may come from a filter object or a global config or something else.
export const fetchSiteMetricChart = (siteId, metric, startDate, endDate) =>
  request.get(`/dav/sites/${siteId}/metrics/${metric}/chart`, {
    ...buildDateRangeOptions(startDate, endDate),
    baseURL: DAV_ASSET_BASE_URL,
  });

/**
 * Get array of assets by site and states.
 *
 * @param {String} siteId Site id
 * @param {String|Array} state Asset state
 */
export const fetchAssetsBySiteAndState = (siteId, state) => {
  const param = Array.isArray(state) ? state.join(',') : state;
  return request.get(`/sites/${siteId}/assets`, { params: { state: param } });
};

/**
 * Get array of asset metrics by site.
 *
 * @param {String} siteId Site id
 * @param {String} measure the measure
 * @param {String|Number} start query start time (epoch or ISO)
 * @param {String|Number} end query end time (epoch or ISO)
 * @param {String} interval query time interval
 */
export const fetchAssetMetricsBySite = (siteId, measure, start, end, interval) =>
  request.get(`/sites/${siteId}/assets/metrics`, {
    params: {
      measure,
      start,
      end,
      interval,
    },
  });

/**
 * Get array of service groups
 */
export const fetchServiceGroups = () =>
  request.get('/cmn/service-groups', { baseURL: CMN_BASE_URL });

/**
 * Features
 */

// TODO: can this be removed and use foundational service instead?
// also added /search to the end because it was causing false match for /dav/sites/site-cards
// can look into the the order of route defs as an alternative solution
/**
 * Fetch Analyze sites
 * @param {String} siteId Site id
 */
export const fetchAnalyzeSiteById = (siteId) =>
  request.get(`/dav/sites/${siteId}/search`, { baseURL: DAV_ASSET_BASE_URL });

/**
 * Dynamically load the site mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/site/sites.mocks')
    .then((mocks) => mocks.registerMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });

export const getServiceGroupsByRocStations = () => {
  return request.get('/cmn/site-groups', { baseURL: CMN_BASE_URL });
};
