import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { AlertTypeValue } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { sorter } from '@ge/util/metric-sorter';

import { getMonitorAlertsHistory } from '../services/monitor-alerts';

export const useAssetAlertHistory = ({
  assetId,
  days,
  filters,
  search,
  enabled,
  sortMetric,
  sortDirection,
}) => {
  const { data, error, isLoading, refetch, isRefetching } = useQuery(
    [QueryKey.MONITOR_ALERTS, assetId, days],
    () => getMonitorAlertsHistory(assetId, days),
    {
      enabled: Boolean(enabled && assetId && days),
      refetchOnMount: true,
    },
  );
  const [filterDefs, setFilterDefs] = useState();
  useEffect(() => {
    if (filters && filters.type && filters.type.value && search.key === 'type') {
      const value = [];
      filters.type.value.forEach((element) => {
        value.push(Object.keys(AlertTypeValue).find((key) => AlertTypeValue[key] === element));
      });
      return setFilterDefs({ ...filters, type: { ...filters.type, value: value } });
    } else {
      return setFilterDefs(filters);
    }
  }, [filters, search.key]);

  const filterData = useTableFilter({ data: data?.alerts, filters: filterDefs, search });
  const alertFIlter = filterData.filterValues ? filterData?.filterValues.type : [];
  const GetFiltersValues = () => {
    if (filters && search.key === 'typeProperties' && search.value) {
      return filterData.filterValues;
    }
    if (alertFIlter && filters && filters.type && filters.type.type === 'checkboxes') {
      const Type = [];
      alertFIlter.forEach((element) => {
        Type.push(AlertTypeValue[element]);
      });
      return {
        type: Type,
      };
    }
  };
  const getAlertFiltersValues = GetFiltersValues();

  return useMemo(() => {
    return {
      data: Array.isArray(filterData.data)
        ? filterData.data.sort(sorter(sortMetric, sortDirection))
        : [],
      error,
      search,
      isLoading,
      refetch,
      isRefetching,
      filterValues: getAlertFiltersValues,
    };
  }, [
    filterData,
    sortMetric,
    sortDirection,
    error,
    search,
    isLoading,
    refetch,
    isRefetching,
    getAlertFiltersValues,
  ]);
};
