import { TableFilterTypes, Capability, PermissionScope } from '@ge/models/constants';

export const DistributionColumns = {
  GROUP_NAME: 'group-name',
  NAME: 'name',
  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'desc',
  GROUP_SITE: 'group-site',
  SITE: 'site',
  GROUP_ASSET: 'group-asset',
  ASSET: 'asset',
  GROUP_TEMPLATE: 'group-templates',
  TEMPLATE: 'template',
  GROUP_DETAIL: 'group-detail',
  DETAIL: 'detail',
};

// Define all available columns in the events table.
export const DistributionColumnDefs = {
  [DistributionColumns.GROUP_NAME]: {
    cols: {
      [DistributionColumns.NAME]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '210px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Name',
            sortValue: 'name',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [DistributionColumns.GROUP_SITE]: {
    cols: {
      [DistributionColumns.SITE]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '210px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Site',
            sortValue: 'site.value',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [DistributionColumns.GROUP_ASSET]: {
    cols: {
      [DistributionColumns.ASSET]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '180px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Asset',
            sortValue: 'asset.value',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [DistributionColumns.GROUP_DESCRIPTION]: {
    cols: {
      [DistributionColumns.DESCRIPTION]: {
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '300px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Description',
            sortValue: 'desc',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
      },
    },
  },
  [DistributionColumns.GROUP_TEMPLATE]: {
    cols: {
      [DistributionColumns.TEMPLATE]: {
        width: '300px',
        align: 'left',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Template',
            sortValue: 'template',
          },
        ],
      },
    },
  },
  [DistributionColumns.GROUP_DETAIL]: {
    fixedRight: true,
    cols: {
      [DistributionColumns.DETAIL]: {
        locked: true,
        capabilities: [
          { capability: Capability.NOTIFICATION_LIST, scopes: [PermissionScope.VIEW] },
        ],
        width: '100px',
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Detail',
          },
        ],
        cell: {
          align: 'center',
          maxWidth: '150px',
        },
      },
    },
  },
};

// Define the default visible columns and their order.
export const defaultDistributionColumns = [
  {
    id: DistributionColumns.GROUP_NAME,
    cols: [
      {
        id: DistributionColumns.NAME,
        visible: true,
      },
    ],
  },
  {
    id: DistributionColumns.GROUP_SITE,
    cols: [
      {
        id: DistributionColumns.SITE,
        visible: true,
      },
    ],
  },
  {
    id: DistributionColumns.GROUP_ASSET,
    cols: [
      {
        id: DistributionColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: DistributionColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: DistributionColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: DistributionColumns.GROUP_TEMPLATE,
    cols: [
      {
        id: DistributionColumns.TEMPLATE,
        visible: true,
      },
    ],
  },
  {
    id: DistributionColumns.GROUP_DETAIL,
    cols: [
      {
        id: DistributionColumns.DETAIL,
        visible: true,
      },
    ],
  },
];
