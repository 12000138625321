import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import { Checkbox } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { placements } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { Table, TableArrow, Tbody, Td, Th, Thead, Tr } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { CloseCaseAction } from '@ge/feat-monitor/components/close-case-action';
import { RocLogsAction } from '@ge/feat-monitor/components/roc-logs-action';
import rnsImg from '@ge/feat-monitor/components/tables/rns.svg';
import {
  getMappedEventStatus,
  getMappedEventType,
} from '@ge/feat-monitor/models/event-type-status';
import {
  PanelColumnWidths,
  Feature,
  Capability,
  PermissionScope,
  AuthStrategy,
  EntityType,
  MonitorCaseStatus,
  FormMode,
  CaseType,
} from '@ge/models/constants';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EscalatedBadge } from '@ge/shared/components/escalated-badge';
import { EscalatedInfo } from '@ge/shared/components/escalated-info';
import { useActionsMenu } from '@ge/shared/data-hooks';
import {
  useCreateNotification,
  useSnoozeDialog,
  useAddNoteDialog,
  useROCActionDialog,
  useCloseCaseActionDialog,
} from '@ge/shared/hooks';
import {
  AssetMonitorCaseMenuItems,
  AssetAnalyzeCaseMenuItems,
  ActionKeys,
} from '@ge/shared/models/actions-menu-items';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';
import { CaseNotesDialog } from '@ge/web-client/src/app/components/entity-details/components/case-notes-dialog';
import { AddNoteDialog } from '@ge/web-client/src/app/components/entity-details/components/notes-add-note-dialog';
import { SnoozeDialog } from '@ge/web-client/src/app/components/entity-details/components/snooze-dialog';

import AssetCasesHandle from './asset-cases-handle';
import { ChangeCausalAlarmDialog } from './change-causal-alarm-dialog';

// const StyledCheckbox = styled.div`
//   label {
//     display: inline-block;
//     margin-top: 2px;
//   }
//   span {
//     margin-left: 0;
//   }
// `;

const MailButton = styled.button`
  border: ${(props) => props.theme.tasks.newButton.border};
  border-radius: 2px;
  background: ${(props) => props.theme.tasks.backgroundColor.W};
  padding: 0;
  height: 20px;
  width: 20px;
`;

const MailIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.EMAIL,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const StyledSpan = styled.span`
  white-space: pre;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const getEntityTypeFromSiteObj = (site) => {
  if (Array.isArray(site?.type) && site?.type?.length > 0) {
    return site.type[0]?.toLowerCase();
  }
  return EntityType.WIND_SITE;
};

const AssetCasesTable = ({
  cases,
  isLoading,
  sortAction,
  sortMetric,
  sortDirection,
  onCaseSelect,
  onRetry,
  scrollable,
  handleCloseCase,
}) => {
  const { t, ready } = useTranslation(['monitor.assets'], {
    useSuspense: false,
  });

  const { getSiteById } = useStoreState((store) => store.sites);
  const site = getSiteById(cases?.[0]?.siteId);

  const [isAnalyzeCaseAvailable, setIsAnalyzeCaseAvailable] = useState(false);
  const [showChangeCausalAlarmDialog, setShowChangeCausalAlarmDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const containerRef = useRef(null);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [caseNotesModal, setCaseNotesModal] = useState(false);
  const [caseDetail, setCaseDetail] = useState(null);

  const [showNewTaskDialog, setShowNewTaskDialog] = useState(false);
  const [localCaseId, setLocalCaseId] = useState('');
  const getMonitorCaseMenuItems = useActionsMenu({ menuItems: AssetMonitorCaseMenuItems });
  const getAnalyzeCaseMenuItems = useActionsMenu({ menuItems: AssetAnalyzeCaseMenuItems });
  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );
  const capabilitiesForNotes = [
    { capability: Capability.CASES, scopes: [PermissionScope.VIEW] },
    { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
  ];

  const {
    changeCaseStatus,
    handleSubmitSnoozeValue,
    snoozeDialog,
    setSnoozeDialog,
    isCaseStateChanged,
    setIsCaseStateChanged,
    updatedData,
    isError,
    error,
  } = useSnoozeDialog();

  const {
    addNoteDialog,
    setAddNoteDialog,
    isAddNoteClicked,
    setIsAddNoteClicked,
    createNoteError,
    isCreateNoteError,
    handleAddNote,
  } = useAddNoteDialog(currentEvent);

  const {
    handleCloseCaseStatus,
    closeCaseDialog,
    setCloseCaseDialog,
    isCloseCaseStateChanged,
    setIsCloseCaseStateChanged,
    updatedCloseData,
    isCloseError,
    closeError,
  } = useCloseCaseActionDialog();

  const { rocLogsDialog, setRocLogsDialog, handleAddRocLogs } = useROCActionDialog();

  const { createNotification, addNoteNotification } = useCreateNotification(
    currentEvent,
    updatedData,
    isError,
    error,
  );

  const { createNotification: closeCreateNotification } = useCreateNotification(
    currentEvent,
    updatedCloseData,
    isCloseError,
    closeError,
  );

  useEffect(() => {
    if (isCaseStateChanged && cases?.length && (updatedData?.length || isError)) {
      createNotification();
      setIsCaseStateChanged(false);
    }
  }, [
    isCaseStateChanged,
    updatedData,
    cases,
    isError,
    error,
    createNotification,
    setIsCaseStateChanged,
  ]);

  useEffect(() => {
    if (isCloseCaseStateChanged && cases?.length && (updatedCloseData?.length || isCloseError)) {
      closeCreateNotification();
      setIsCloseCaseStateChanged(false);
    }
  }, [
    isCloseCaseStateChanged,
    updatedCloseData,
    cases,
    isCloseError,
    closeError,
    closeCreateNotification,
    setIsCloseCaseStateChanged,
  ]);

  useEffect(() => {
    if (isAddNoteClicked && cases?.length && isCreateNoteError) {
      setIsAddNoteClicked(false);
      addNoteNotification();
    }
  }, [
    addNoteNotification,
    cases,
    isAddNoteClicked,
    isCreateNoteError,
    createNoteError,
    setAddNoteDialog,
    setIsAddNoteClicked,
  ]);

  useEffect(() => {
    if (Array.isArray(cases) && cases.find(({ domain }) => domain === Feature.ANALYZE)) {
      setIsAnalyzeCaseAvailable(true);
    }
  }, [cases]);

  const getActionMenus = useCallback(
    (isMonitorCase, siteId) => {
      return isMonitorCase
        ? getMonitorCaseMenuItems([{ site: { id: siteId } }])
        : getAnalyzeCaseMenuItems([{ site: { id: siteId } }]);
    },
    [getMonitorCaseMenuItems, getAnalyzeCaseMenuItems],
  );

  const eventsActionHandler = useCallback(
    ({ entity, type }) => {
      switch (type) {
        case ActionKeys.EVENT_CHANGE_CAUSAL_ALARM:
          setCurrentEvent(entity);
          setShowChangeCausalAlarmDialog(true);
          break;
        case ActionKeys.EVENT_IN_PROGRESS:
          setCurrentEvent(entity);
          changeCaseStatus({ state: type, id: entity.id });
          setIsCaseStateChanged(true);
          break;
        case ActionKeys.EVENT_CLOSE:
          setCurrentEvent(entity);
          setCloseCaseDialog(true);
          break;
        case ActionKeys.EVENT_SNOOZE:
          setCurrentEvent(entity);
          setSnoozeDialog(true);
          break;
        case ActionKeys.EVENT_ADD_NOTE:
          setCurrentEvent(entity);
          setAddNoteDialog(true);
          break;
        case ActionKeys.CASE_CLOSE:
          handleCloseCase(entity);
          break;
        case ActionKeys.EVENT_LOG_ACTION:
          setCurrentEvent(entity);
          setRocLogsDialog(true);
          break;
        case ActionKeys.CASE_NEW:
          setLocalCaseId(entity.id);
          setShowNewTaskDialog(true);
          break;
        case ActionKeys.CASE_TASK_LINK:
          setLocalCaseId(entity.id);
          setShowLinkModal(true);
          break;
        default:
          break;
      }
    },
    [
      handleCloseCase,
      setAddNoteDialog,
      setSnoozeDialog,
      setRocLogsDialog,
      changeCaseStatus,
      setCloseCaseDialog,
      setIsCaseStateChanged,
    ],
  );

  const getDisabledActions = useCallback(({ caseStatus }) => {
    if (caseStatus) {
      return {
        [ActionKeys?.EVENT_IN_PROGRESS]: caseStatus === MonitorCaseStatus.IN_PROGRESS,
        [ActionKeys?.EVENT_SNOOZE]: caseStatus === MonitorCaseStatus.SNOOZED,
        [ActionKeys?.EVENT_CLOSE]: caseStatus === MonitorCaseStatus.CLOSED,
      };
    } else {
      return {};
    }
  }, []);

  const handleCaseNotes = useCallback(
    (e, cellValue) => {
      killEventPropagation(e);
      setCaseDetail(cellValue);
      setCaseNotesModal(true);
    },
    [setCaseNotesModal],
  );

  const closeNoteModal = useCallback(
    (e) => {
      killEventPropagation(e);
      setCaseDetail({});
      setCaseNotesModal(false);
    },
    [setCaseNotesModal],
  );

  if (!ready) {
    return <Loader />;
  }

  const noData = !(isLoading || Boolean(cases?.length));

  return (
    <>
      <Table
        height={'400'}
        compressed
        isLoading={isLoading}
        noData={noData}
        onRetry={onRetry}
        scrollable={scrollable}
      >
        <Thead noTitles transparent>
          <tr>
            {/* <Th border align="center" width={PanelColumnWidths.XSMALL} /> */}
            {isAnalyzeCaseAvailable && (
              <Th
                labels={{
                  [t('asset_case_table.priority', 'Priority')]: 'priority',
                }}
                border
                sortedDirection={sortedDirection}
                onClick={sortAction}
                width={PanelColumnWidths.XSMALL}
              />
            )}
            <Th
              labels={{
                [t('asset_case_table.title', 'Title')]: 'title',
              }}
              align="left"
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XXLARGE}
            />
            <Th
              labels={{
                [`${t('asset_case_table.opened', 'Opened')} (${dayjs
                  .tz(new Date(), site?.timezone)
                  .format('z')})`]: 'opened',
              }}
              sortedDirection={sortedDirection}
              onClick={sortAction}
              border
              width={PanelColumnWidths.LARGE}
            />
            <Th
              labels={{
                [t('asset_case_table.case_type', 'Case Type')]: 'caseType',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.SMALL}
            />
            <Th
              labels={{
                [t('asset_case_table.status', 'Status')]: 'status',
              }}
              border
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
            />
            <AuthRender
              capabilities={capabilitiesForNotes}
              siteLevel={false}
              authStrategy={AuthStrategy.ALL}
            >
              <Th
                labels={{
                  [t('asset_case_table.notes', 'Notes')]: 'notes',
                }}
                border
                width={PanelColumnWidths.XSMALL}
              />
            </AuthRender>
            <Th
              labels={{
                [t('asset_case_table.escalated', 'Escalated')]: 'escalated',
              }}
              border
              width={PanelColumnWidths.SMALL}
            />
            <Th
              labels={{
                [t('asset_case_table.action', 'Action')]: 'action',
              }}
              border
              width={PanelColumnWidths.XSMALL}
            />
            {isAnalyzeCaseAvailable && <Th border width={PanelColumnWidths.XSMALL} />}
          </tr>
        </Thead>
        <Tbody transparent>
          {cases?.map((caseData) => {
            const {
              domain,
              id,
              siteId,
              priority,
              title,
              opened,
              caseType,
              caseStatus,
              escalated,
              task,
              totalTaskCount,
            } = caseData;
            const statusObj = caseStatus && getMappedEventStatus(caseStatus);
            const caseTypeObj = caseType && getMappedEventType(caseType);
            const isMonitorCase = domain === Feature.MONITOR;
            const actionMenus = getActionMenus(isMonitorCase, siteId);
            const caseDateTime = getDateTimeBasedOnZone(opened, site?.timezone);
            return (
              <Tr key={id}>
                {/* <Td width={PanelColumnWidths.XSMALL} border noPadding>
                <StyledCheckbox>
                  <Checkbox label={''} />
                </StyledCheckbox>
              </Td> */}
                {isAnalyzeCaseAvailable && (
                  <Td width={PanelColumnWidths.XSMALL} border noPadding>
                    {!isMonitorCase && <Severity level={priority?.toLowerCase()} />}
                  </Td>
                )}
                <Td align="left" width={PanelColumnWidths.XXLARGE} maxWidth={'180px'} border>
                  <TooltipCell tooltip={title} zIndex={elevations.P20}>
                    <StyledSpan>{title}</StyledSpan>
                  </TooltipCell>
                </Td>
                <Td width={PanelColumnWidths.LARGE} border noPadding>
                  <StyledTimeDate>
                    <span>{caseDateTime.time}</span>
                    <span>{caseDateTime.date}</span>
                  </StyledTimeDate>
                </Td>
                <Td width={PanelColumnWidths.SMALL} border noPadding>
                  {caseTypeObj.key === CaseType.RNS ? (
                    <TooltipCell tooltip={t(caseTypeObj.translationKey)} zIndex={elevations.P20}>
                      <img src={rnsImg} alt={t(caseTypeObj.translationKey)} />
                    </TooltipCell>
                  ) : (
                    caseTypeObj?.icon && (
                      <TooltipCell tooltip={t(caseTypeObj.translationKey)} zIndex={elevations.P20}>
                        <Icon
                          icon={caseTypeObj.icon}
                          size={16}
                          color={globalColors.slate5}
                          viewbox={caseTypeObj.viewbox}
                        ></Icon>
                      </TooltipCell>
                    )
                  )}
                </Td>
                <Td width={PanelColumnWidths.XSMALL} border noPadding>
                  {statusObj?.icon && (
                    <TooltipCell tooltip={t(statusObj.translationKey)} zIndex={elevations.P20}>
                      <Icon icon={statusObj.icon} size={16} color={globalColors.slate5}></Icon>
                    </TooltipCell>
                  )}
                </Td>
                <AuthRender
                  capabilities={capabilitiesForNotes}
                  siteLevel={false}
                  authStrategy={AuthStrategy.ALL}
                >
                  <Td width={PanelColumnWidths.XSMALL} border noPadding>
                    <div onClick={(e) => handleCaseNotes(e, caseData)}>
                      <Icon icon={Icons.NOTE} size={14} color={globalColors.slate5} />
                    </div>
                  </Td>
                </AuthRender>
                <Td width={PanelColumnWidths.SMALL} border>
                  {Array.isArray(escalated) && escalated.length ? (
                    isMonitorCase ? (
                      <EscalatedInfo escalated={escalated[0]}>
                        <MailButton>
                          <MailIcon />
                        </MailButton>
                      </EscalatedInfo>
                    ) : null
                  ) : (
                    <EscalatedBadge
                      caseId={id}
                      status={task?.status}
                      workScope={task?.workScope}
                      totalTaskCount={totalTaskCount}
                      containerRef={containerRef}
                      taskId={task?.taskId}
                      menuPlacement={placements.RIGHT_END}
                    />
                  )}
                </Td>
                <Td width={PanelColumnWidths.XSMALL} border noPadding>
                  <ActionsMenu
                    containerRef={containerRef}
                    entity={caseData}
                    menuIconSmall
                    menuItems={actionMenus}
                    onAction={eventsActionHandler}
                    disabledActions={getDisabledActions(caseData)}
                  />
                </Td>
                {isAnalyzeCaseAvailable && (
                  <Td border width={PanelColumnWidths.XSMALL} noPadding>
                    {!isMonitorCase && (
                      <div onClick={(e) => onCaseSelect(e, id)}>
                        <TableArrow />
                      </div>
                    )}
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {showChangeCausalAlarmDialog && (
        <ChangeCausalAlarmDialog
          entity={currentEvent}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent(null);
            setShowChangeCausalAlarmDialog(false);
          }}
        />
      )}
      {addNoteDialog && (
        <AddNoteDialog
          isOpen={addNoteDialog}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          entity={currentEvent}
          entityType={EntityType.EVENT}
          onConfirm={handleAddNote}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setAddNoteDialog(false);
          }}
        />
      )}
      {snoozeDialog && (
        <SnoozeDialog
          entities={[currentEvent]}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          onConfirm={handleSubmitSnoozeValue}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setSnoozeDialog(false);
          }}
        />
      )}
      {closeCaseDialog && (
        <CloseCaseAction
          entity={currentEvent}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          entityType={EntityType.CASE}
          onConfirm={handleCloseCaseStatus}
          mode={FormMode.CREATE}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setCloseCaseDialog(false);
          }}
        />
      )}
      {caseNotesModal && (
        <CaseNotesDialog
          entity={caseDetail}
          isOpen={caseNotesModal}
          onClose={closeNoteModal}
          siteType={getEntityTypeFromSiteObj(caseDetail?.site)}
        />
      )}
      {rocLogsDialog && (
        <RocLogsAction
          entity={currentEvent}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          entityType={EntityType.CASE}
          onConfirm={handleAddRocLogs}
          mode={FormMode.CREATE}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setRocLogsDialog(false);
          }}
        />
      )}
      <AssetCasesHandle
        showNewTaskDialog={showNewTaskDialog}
        setShowNewTaskDialog={setShowNewTaskDialog}
        localCaseId={localCaseId}
        showLinkModal={showLinkModal}
        setShowLinkModal={setShowLinkModal}
      />
    </>
  );
};

AssetCasesTable.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isLoading: PropTypes.bool,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onCaseSelect: PropTypes.func,
  onRetry: PropTypes.func,
  scrollable: PropTypes.bool,
  testCaseSelect: PropTypes.func,
  handleCloseCase: PropTypes.func,
  handleNewTask: PropTypes.func,
  setShowLinkModal: PropTypes.func,
};

AssetCasesTable.defaultProps = {
  isLoading: false,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onCaseSelect: () => null,
  onRetry: () => null,
  scrollable: false,
};

export default AssetCasesTable;
