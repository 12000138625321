import { CaseStatus } from '@ge/models/constants';
import { Region, Site } from '@ge/models/entities';
import * as request from '@ge/shared/services/api';
import { buildSortOptions } from '@ge/util/metric-sorter';

const DAV_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_API;
const RTMC_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;

/**
 * Fetch case table
 */
export const fetchMonitorCaseTable = (body) => {
  return request.post('/dav/case-table', body, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Fetch case table metrics
 */
export const fetchAnomaliesPageParams = (body) => {
  return request.post('/dav/case-table/page-params', body, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Fetch case details for the entity panel
 */
export const fetchCaseDetails = (caseId) => {
  return request.get(`/dav/case-details/${caseId}`, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Create a case
 */
export const createCase = (_case) => {
  return request.post(`/dav/case/`, _case, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * To create multiple cases
 */
export const createCases = (_case) => {
  return request.post(`/dav/cases/`, _case, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Link cases
 */
export const linkCases = (body) => {
  return request.post(`/dav/cases/link`, body, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Unlink cases
 */
export const unlinkCases = (body) => {
  return request.post(`/dav/cases/unlink`, body, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Update a case
 */
export const updateCase = (_case) => {
  return request.put(`/dav/case/${_case.id}`, _case, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Bulk Update cases
 */
export const updateCases = (body) => {
  return request.patch(`/dav/cases`, body, {
    baseURL: DAV_BASE_URL,
  });
};

/**
 * Close case by case ID
 */
export const closeCaseById = ({ id: caseId, asset, disposition, rootCause }) => {
  const payload = {
    disposition,
    rootCause,
    id: caseId,
    asset: {
      id: asset.id,
    },
    status: CaseStatus.CLOSED,
  };
  return updateCase(payload);
};

/**
 *  Fetch cases for asset ids based on case status
 * @param assetIds  - The Asset Ids
 * @param status    - Status of cases to be fetched
 * @param includeChildCases - determined if we should include child cases that have been fused
 * @returns {Promise<{ data }>}
 */
export const fetchCasesForAssetIdsAndStatus = (assetIds, status, includeChildCases) => {
  return request.get('/dav/assets/cases', {
    baseURL: DAV_BASE_URL,
    params: { assets: assetIds.join(','), status, includeChildCases },
  });
};

/**
 * Fetch case metrics details for assets
 *
 * @param assetIds The Asset Ids
 * @returns {Promise<{ data }>}
 */
export const fetchCaseMetricsForAssetIds = (assetIds) => {
  return request.get('/dav/case/metrics', {
    baseURL: DAV_BASE_URL,
    params: { assets: assetIds.join(',') },
  });
};

export const fetchSitesRealTimeData = (siteType = 'wind') => {
  return request.get('/rtmc/sites/rt-data', {
    baseURL: RTMC_BASE_URL,
    params: { siteType },
  });
};

/**
 * Fetch analyze fleet overview
 */
export const fetchMonitorSites = (sortMetric, sortDirection) =>
  request.get('/monitor/sites', {
    ...buildSortOptions(sortMetric, sortDirection),
    transformResponse: [
      (data) => {
        if (data?.entities) {
          // Cast Site entities to type
          data.entities.sites = Object.entries(data.entities.sites).reduce(
            (acc, [siteId, value]) => {
              acc[siteId] = new Site(value);
              return acc;
            },
            {},
          );

          // Cast Region entities to type
          data.entities.regions = Object.entries(data.entities.regions).reduce(
            (acc, [regionId, value]) => {
              acc[regionId] = new Region(value);
              return acc;
            },
            {},
          );
        }

        return data;
      },
    ],
  });

export const forceMocks = () => {};

/**
 * Dynamically load the region mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/monitor.mocks')
    .then((mocks) => mocks.registerMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });
