import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Select } from '@ge/components/select';
import { EventLevelDataTypes } from '@ge/feat-analyze/models';
import { handleKpiError } from '@ge/feat-analyze/util/kpi';
import { Capability, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { getDefaultTranslation } from '@ge/shared/util';

import { DashboardChartTitle } from '../dashboard/dashboard-chart-title';
import { DashboardIECDataToggle } from '../dashboard/dashboard-iec-data-toggle';
import { IecLossDonutChart } from '../dashboard/dashboard-iec-loss-donut';
import { DashboardTitle } from '../dashboard/dashboard-shared';

const StyledSelect = styled(Select)`
  margin-left: 2px;
  optgroup {
    text-transform: uppercase;
    color: black;
  }
`;

const DataToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const removeValueZero = (data) => data.value !== 0;
const sortHighToLow = (a, b) => b.value - a.value;

const zeroData = {
  data: [],
  total: 0,
  unit: 'hours',
};

// eslint-disable-next-line react/display-name
const MajorLossChart = React.forwardRef(
  (
    {
      namespace,
      selectOptions,
      currentCategory,
      changeCategory,
      data: _data,
      error,
      isLoading,
      height,
      customError,
      showZero,
      makeVisible,
    },
    ref,
  ) => {
    const { t, ready } = useTranslation([namespace]);
    const { isAuthorized } = useAuth();

    const [dataType, setDataType] = useState(EventLevelDataTypes.IEC);
    const [chartData, setChartData] = useState([]);

    // Data
    const { data = [], total, unit, siteIds } = showZero ? zeroData : _data || {};
    const { oem, iec } = data ?? {};

    const capability = Capability.CORE_KPIS;

    const oemAuthorized = isAuthorized({
      capabilities: [{ capability, scopes: [PermissionScope.VIEW] }],
      siteIds,
    });

    const translatedIecData = useMemo(
      () =>
        iec
          ?.filter(removeValueZero)
          .sort(sortHighToLow)
          .map((category) => ({
            ...category,
            title: t(`dynamic.tilc.${category.type}`, getDefaultTranslation(category.type)),
            id: isNaN(category.type)
              ? category.type.trim().toLocaleLowerCase().replace(/\s+/g, '-')
              : category.type,
            details: category.details
              .filter(removeValueZero)
              .sort(sortHighToLow)
              .map((childCategory) => ({
                ...childCategory,
                title: `${childCategory.type} - ${childCategory.text}`,
              })),
            iecCategory: category.iecCategory,
          })),
      [iec, t],
    );

    const translatedOemData = useMemo(
      () =>
        oem
          ?.filter(removeValueZero)
          .sort(sortHighToLow)
          .map((category) => ({
            ...category,
            id: isNaN(category.type)
              ? category.type.trim().toLocaleLowerCase().replace(/\s+/g, '-')
              : category.type,
            title: `${category.type} - ${category.text}`,
          })),
      [oem],
    );

    useEffect(() => {
      if (dataType === EventLevelDataTypes.OEM) {
        setChartData(translatedOemData);
      } else {
        setChartData(translatedIecData);
      }
    }, [setChartData, dataType, translatedOemData, translatedIecData]);

    // Handlers
    const handleCategoryChange = useCallback(
      (option) => {
        changeCategory(option);
      },
      [changeCategory],
    );

    const handleDataToggleChange = useCallback(
      (val) => {
        setDataType(val);
      },
      [setDataType],
    );
    // error handler
    const { noData, noDataTitle, noDataDescription } = useMemo(() => {
      if (!data) {
        return { noData: true };
      }

      return handleKpiError({ error, t });
    }, [data, error, t]);

    if (!ready) {
      return null;
    }

    return (
      <div ref={ref}>
        {}
        {makeVisible ? (
          <>
            <DashboardTitle>{t('major_loss.title', 'Pareto Analysis')}</DashboardTitle>
            <DashboardChartTitle displayLegend={false}>
              <StyledSelect
                small
                minWidth={300}
                value={currentCategory}
                onChange={handleCategoryChange}
                options={selectOptions}
              />
            </DashboardChartTitle>
          </>
        ) : (
          <></>
        )}
        <DataLoader
          isLoading={isLoading}
          //type={type} Need to get UX confirmation on loading icon type
          noData={noData}
          noDataTitle={noDataTitle}
          noDataDescription={noDataDescription}
          renderCondition={!error}
          onRetry={null}
          height={`${height}px`}
        >
          <DataToggleContainer>
            <DashboardIECDataToggle
              capability={capability}
              toggleTypes={
                oemAuthorized
                  ? [EventLevelDataTypes.IEC, EventLevelDataTypes.OEM]
                  : [EventLevelDataTypes.IEC]
              }
              defaultType={dataType}
              onChange={handleDataToggleChange}
            />
          </DataToggleContainer>
          <IecLossDonutChart
            data={chartData}
            value={total}
            unit={unit}
            noDataError={customError}
            showZero={showZero}
          />
        </DataLoader>
      </div>
    );
  },
);

MajorLossChart.propTypes = {
  namespace: PropTypes.string,
  selectOptions: PropTypes.array,
  changeCategory: PropTypes.func,
  currentCategory: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  height: PropTypes.number,
  customError: PropTypes.string,
  showZero: PropTypes.bool,
  makeVisible: PropTypes.bool,
};

MajorLossChart.defaultProps = {
  namespace: 'analyze.dashboard',
  selectOptions: [],
  changeCategory: () => {},
  currentCategory: null,
  data: {},
  isLoading: true,
  height: 280,
  customError: null,
  showZero: false,
  makeVisible: null,
};

export default MajorLossChart;
