import dayjs from 'dayjs';
import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { CheckedState } from '@ge/components/checkbox';
import { Checkbox } from '@ge/components/checkbox';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Severity } from '@ge/components/severity';
import { Tabs, Tab } from '@ge/components/tabs';
import {
  Capability,
  DataLoaderType,
  DateTimeFormats,
  PermissionScope,
  Placeholders,
  Placements,
} from '@ge/models/constants';

import { useRemoveTask } from '../../data-hooks';
import { AuthRender } from '../auth-render';

import { useCaseLinkContext } from './case-link-provider';

const TabsContainer = styled.div`
  background-color: ${(props) => props.theme.caseLinkDialog.tabBackground};
  border-radius: 5px;
  margin-bottom: 16px;
`;

const CaseListItemIcon = styled(Icon).attrs((props) => ({
  color: props.theme.caseLinkDialog.iconColor,
  size: 13,
}))`
  margin-right: 5px;
`;

const DeleteIcon = styled(Icon).attrs((props) => ({
  color: props.theme.caseLinkDialog.iconColor,
  size: 15,
  icon: Icons.TRASH,
}))`
  margin-right: 5px;
  cursor: pointer;
`;

const CaseListItemSeverity = styled(Severity).attrs(() => ({
  size: 9,
}))`
  margin-top: 3px;
`;

const CaseListContainer = styled(ScrollingContainer)`
  max-height: 165px;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.45px;
  color: ${(props) => props.theme.caseLinkDialog.textColor};
`;

const CaseListItem = styled.div`
  display: flex;
  padding: 8px 8px 7px;
  border-bottom: 1px solid ${(props) => props.theme.caseLinkDialog.separatorColor};
  &:last-child {
    border: none;
  }
`;

const CaseListItemCell = styled.div`
  padding: 0 7px;
  &.text-align-end {
    text-align: end;
  }
  &.full-width {
    flex-grow: 1;
  }
`;

const CaseListItemTitle = styled.div`
  color: ${(props) => props.theme.caseLinkDialog.titleColor};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const CaseListItemStatus = styled.div`
  font-weight: 500;
`;

const CaseListItemDate = styled.div`
  padding-top: 5px;
  white-space: nowrap;
`;

export const CaseLinkChildSection = React.memo(() => {
  const theme = useContext(ThemeContext);

  const { remove, isLoading: isDeleting } = useRemoveTask();

  const {
    cases,
    entity,
    selectedChildCaseIds,
    selectedParentCaseId,
    handleSelection: onChange,
    activeTab,
    setActiveTab: onTabChange,
    tasks,
    taskIds,
    isTasksLoading,
  } = useCaseLinkContext();

  const handleChange = useCallback(
    (_, id) => {
      onChange(id);
    },
    [onChange],
  );

  const toSiteTimezone = (value) =>
    dayjs(value)
      .tz(entity?.site?.timezone)
      .format(DateTimeFormats.ISSUE_HEADER_DATE);

  const handleDeleteTask = (taskId) => {
    const taskToBeDeleted = tasks.find((task) => task.id === taskId);
    remove(taskToBeDeleted);
  };

  const formatValue = (value, converter = (v) => v) =>
    value ? converter(value) : Placeholders.DOUBLE_DASH;

  return (
    <TabsContainer>
      <Tabs onChangeHandler={onTabChange} defaultTab={activeTab} setActiveTabFromParent>
        <Tab
          label="Cases"
          badgeColor={theme.caseLinkDialog.badgeColor}
          badgeValue={String(selectedChildCaseIds?.length)}
          badgePlacement={Placements.RIGHT}
        >
          <CaseListContainer relative={true}>
            {cases?.map((cellValue) => (
              <CaseListItem key={cellValue.id}>
                <Checkbox
                  name={'link-case'}
                  id={cellValue.id}
                  disabled={cellValue.id === selectedParentCaseId}
                  onChange={handleChange}
                  checkState={
                    selectedChildCaseIds.includes(cellValue.id)
                      ? CheckedState.CHECKED
                      : CheckedState.UNCHECKED
                  }
                />
                <CaseListItemIcon
                  icon={cellValue.childCases?.length ? Icons.CASE : Icons.ANOMALY}
                  color={'transparent'}
                />
                <CaseListItemSeverity level={cellValue.priority} />
                <CaseListItemCell className="full-width">
                  <CaseListItemTitle>{cellValue.description}</CaseListItemTitle>
                  <span>{formatValue(cellValue.source)}</span>
                </CaseListItemCell>
                <CaseListItemCell className="text-align-end">
                  <CaseListItemStatus>{cellValue.status}</CaseListItemStatus>
                  <CaseListItemDate>
                    {formatValue(cellValue.start, toSiteTimezone)}
                  </CaseListItemDate>
                </CaseListItemCell>
              </CaseListItem>
            ))}
          </CaseListContainer>
        </Tab>
        {taskIds?.length && (
          <Tab
            label="Tasks"
            badgeColor={theme.caseLinkDialog.badgeColor}
            badgeValue={String(taskIds?.length)}
            badgePlacement={Placements.RIGHT}
            capabilities={[{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }]}
          >
            <DataLoader isLoading={isTasksLoading || isDeleting} type={DataLoaderType.TABLE}>
              <CaseListContainer relative={true}>
                {tasks?.map((task) => (
                  <CaseListItem key={task.id}>
                    <AuthRender
                      capability={Capability.FIELD_TASKS}
                      delete
                      description="Delete Task"
                      siteIds={[entity?.site?.id]}
                    >
                      <span onClick={() => handleDeleteTask(task.id)}>
                        <DeleteIcon />
                      </span>
                    </AuthRender>
                    <CaseListItemIcon icon={Icons.TASK} color={'transparent'} />
                    <CaseListItemSeverity level={task.priority} />
                    <CaseListItemCell className="full-width">
                      <CaseListItemTitle>{task.title}</CaseListItemTitle>
                      <span>{formatValue(task.source)}</span>
                    </CaseListItemCell>
                    <CaseListItemCell className="text-align-end">
                      <CaseListItemStatus>{task.status}</CaseListItemStatus>
                      <CaseListItemDate>
                        {formatValue(task.createDate, toSiteTimezone)}
                      </CaseListItemDate>
                    </CaseListItemCell>
                  </CaseListItem>
                ))}
              </CaseListContainer>
            </DataLoader>
          </Tab>
        )}
      </Tabs>
    </TabsContainer>
  );
});

CaseLinkChildSection.displayName = 'CaseLinkChildSection';
