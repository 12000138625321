import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';

import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useManualAdjustmentContext } from '@ge/feat-analyze/context/manual-adjustment-context';

import { EventDetailDialog } from './event-detail-dialog';
import SitePanelChartHeader from './site-panel-chart-header';
import SitePanelChartRow from './site-panel-chart-row';
import { ChartContainer } from './site-panel-chart-style';

const StyledScrollingContainer = styled(ScrollingContainer)`
  display: flex;
  position: relative;
  flex: 1;
  max-height: 320px;
  width: 100%;
  margin-bottom: 20px;
`;

export const SitePanelChart = ({ startOfDay, data, site, assetEventsKind }) => {
  const anchorEl = useRef(null);

  const { selectedEvent, setSelectedEvent } = useManualAdjustmentContext();

  return (
    <ChartContainer
      ref={anchorEl}
      isEventSelected={selectedEvent && selectedEvent.site.id === site.id}
    >
      <SitePanelChartHeader data={data} />
      <StyledScrollingContainer relative>
        {data.map((item) => (
          <SitePanelChartRow key={item.asset?.id} data={item} site={site} startOfDay={startOfDay} />
        ))}
      </StyledScrollingContainer>
      {selectedEvent && !selectedEvent.bulk && (
        <EventDetailDialog
          isOpen={Boolean(selectedEvent)}
          onClose={() => setSelectedEvent(null)}
          site={site}
          event={selectedEvent?.event ?? null}
          anchorEl={anchorEl}
          portalId={`#eventDetailDialog-${site?.id}`}
          assetEventsKind={assetEventsKind}
        />
      )}
      <div
        id={`eventDetailDialog-${site?.id}`}
        className={`event-detail ${!selectedEvent ? 'hide' : ''}`}
      ></div>
    </ChartContainer>
  );
};

SitePanelChart.propTypes = {
  isLoading: PropTypes.bool,
  site: PropTypes.instanceOf(Object).isRequired,
  startOfDay: PropTypes.number.isRequired,
  data: PropTypes.array,
  assetEventsKind: PropTypes.string,
};

SitePanelChart.defaultProps = {
  isLoading: false,
  data: [],
};
