import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_HYBRIDS_API;

/**
 * Fetch Solar inverters list
 */
export const fetchSolarInvertersList = ({ startDate, endDate }) => {
  return request.post(
    `/dav/solar/inverterList`,
    {
      startDate,
      endDate,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch details of specific Site ID
 * @param {String} siteId Site ID
 */
export const fetchSiteDetailsSolar = ({ siteId }) => {
  return request.post(
    '/dav/solar/siteDetails',
    {
      id: siteId,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch pareto analysis data by site
 * @param {Array<string>} assetIds Site id
 * @param {Date} startDate selected from global date range
 * @param {Date} endDate selected from global date range
 * @param {String} severity selected from event type
 * @param {string} reportBy selected from report by
 * @param {String} groupBy grouped by events or assets
 */
export const fetchParetoAnalysisDataBySite = ({
  assetIds,
  startDate,
  endDate,
  severity,
  reportBy,
  groupBy,
  ...rest
}) => {
  return request.post(
    `dav/solar/reliability`,
    {
      assetIds,
      startDate,
      endDate,
      severity,
      reportBy,
      groupBy,
      ...rest,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch pareto analysis data by site
 * @param {Array<string>} assetIds Site id
 * @param {Date} startDate selected from global date range
 * @param {Date} endDate selected from global date range
 * @param {String} severity selected from event type
 * @param {string} reportBy selected from report by
 * @param {String} groupBy grouped by events or assets
 */
export const getSiteHistoricalData = ({
  assetIds,
  startDate,
  endDate,
  kpiList,
  siteType = 'solar',
  type = 'site',
}) => {
  return request.post(
    `/dav/${siteType}/kpi/${type}/historical`,
    {
      assetIds,
      startDate,
      endDate,
      kpiList,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch aggregated details of specific Site
 */
export const fetchAggregatedKpiSolar = ({ assetId, startDate, endDate, kpiList, assetType }) => {
  return request.post(
    '/dav/solar/agreegated/kpis',
    {
      assetId,
      startDate,
      endDate,
      kpiList,
      assetType,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch Solar Site list
 */
export const fetchSolarSitesList = () => {
  return request.post(
    `/dav/solar/agreegated/kpis/byAsset`,
    {},
    {
      baseURL: BASE_URL,
    },
  );
};
/**
 * Dynamically load the region mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/solar.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
