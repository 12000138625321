import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TaskTemplateModes, DefaultTime, TaskFlag } from '@ge/models/constants';

import { DateMetaField } from '../../../../meta-fields/date-meta-field';
import { TimeStrMetaField } from '../../../../meta-fields/time-str-meta-field';
import { AssigneeSelect } from '../../../task-fields/assignee-select';
import {
  ColumnTitle,
  ColumnDetail,
  FieldGroup,
  StyledDateContainer,
} from '../../../task-template-shared';

import { ScheduleErrors } from './schedule-errors';

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 0;
  display: flex;
`;

const StyledScheduleErrors = styled(ScheduleErrors)`
  margin-top: 8px;
`;

const StyledAssigneeContainer = styled.div`
  position: relative;
`;

export const EditSchedule = ({ metadata, rules, task, templateMode }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const { enabledApprovalFlag } = useStoreState((state) => state.tasks);

  const { readOnly, required } = metadata.scheduleDate.edit;
  const scheduleDate = task?.scheduleDateTz || null;
  const { watch } = useFormContext();
  const watchScheduleDate = watch('scheduleDate');

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.schedule', 'Schedule')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <StyledFieldGroup>
          {!metadata?.scheduleDate.hidden && (
            <StyledDateContainer>
              <div className="label">{t('form.schedule_date', 'Schedule Date')}</div>
              <DateMetaField
                defaultValue={scheduleDate}
                defaultSelection={metadata.scheduleDate?.defaultSelection}
                metadata={{
                  readOnly:
                    readOnly ||
                    (approvalFlag && task?.flag !== TaskFlag.APPROVED && enabledApprovalFlag),
                  required,
                }}
                name="scheduleDate"
                rules={rules}
                validators={metadata.scheduleDate.edit?.validators}
              />
            </StyledDateContainer>
          )}
          {!metadata?.startTime.hidden && (
            <StyledDateContainer>
              <div className="label">{t('form.start_time', 'Start Time')}</div>

              <TimeStrMetaField
                defaultValue={
                  task?.startTime ? task.startTime : DefaultTime.TASK_DEFAULT_START_TIME
                }
                metadata={{
                  readOnly:
                    readOnly ||
                    (approvalFlag && task?.flag !== TaskFlag.APPROVED && enabledApprovalFlag),
                  required,
                }}
                name="startTime"
                rules={{
                  required: watchScheduleDate ? true : false,
                }}
              />
            </StyledDateContainer>
          )}

          {!metadata?.crewIds.hidden && (
            <StyledAssigneeContainer>
              <AssigneeSelect
                defaultValue={task?.crewIds?.length ? task?.crewIds[0] : null}
                metadata={metadata?.crewIds.create}
                task={task}
                templateMode={templateMode}
                disabled={approvalFlag && task?.flag !== TaskFlag.APPROVED && enabledApprovalFlag}
              />
            </StyledAssigneeContainer>
          )}
        </StyledFieldGroup>
        <StyledScheduleErrors />
      </ColumnDetail>
    </div>
  );
};

EditSchedule.propTypes = {
  metadata: PropTypes.object.isRequired,
  rules: PropTypes.instanceOf(Object),
  task: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

EditSchedule.defaultProps = {
  rules: null,
};
