import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchAssetInfo } from '../services';

/**
 * Get case Metrics data for the asset
 *
 * @param assetId assetId to get the case metrics data
 * @returns
 */

const assetInfoDataTransformer = (elem) => {
  return {
    assetInfo: {
      id: elem.windTurbineId,
      controllerType: elem?.properties.controllerType,
      platform: elem?.properties.platform,
      make: elem?.properties.make,
      model: elem?.properties.model,
      serialNumber: elem?.properties.serialNumber,
      controlCodeVersion: elem?.properties.controlCodeVersion,
      ratedPower: `${elem?.properties.ratedPower} kW`,
      rotorDiameter: `${elem?.properties.rotorDiameter} m`,
      hubHeight: `${elem?.properties.hubHeight} m`,
      gridFrequency: `${elem?.properties.gridFrequency} Hz`,
      COD: elem?.properties.commercialOperationDate,
      latitude: elem?.properties?.location?.latitude,
      longitude: elem?.properties?.location?.longitude,
      elevation: elem?.properties?.location?.elevation,
      siteName: elem?.connections.siteId,
    },
    componentList: elem?.components?.componentList,
  };
};

const useAssetInfoData = (assetId) => {
  const {
    data: _queryData,
    error,
    isLoading,
  } = useQuery(
    [QueryKey.ASSET_INFO_DATA, assetId],
    async () => {
      const responses = await fetchAssetInfo([assetId]);
      return assetInfoDataTransformer(responses);
    },
    {
      enabled: Boolean(assetId),
      ...Config.EXECUTE_ONCE,
    },
  );
  return { data: _queryData, error, isLoading };
};

export default useAssetInfoData;
