const charSplit = (text) => {
  const split = text.slice(0, 20);
  return split + '...';
};
export const toolTipFormatter = (theme) => (point) => {
  const textColor = theme.themeSelector.tabsTextColor;
  const lineColor = theme.backgroundColor;

  const tableNames = point.name.length < 20 ? point.name : charSplit(point.name);
  var tooltip = '';
  const header =
    '<table><tr><th colspan="2" style="padding-bottom: 10px;border-bottom:1px solid ' +
    lineColor +
    ' ;color:' +
    textColor +
    ' ">' +
    '<b>' +
    tableNames +
    '</b>';
  ('</th></tr>');
  tooltip += header;
  const footer =
    '<tr><td>Percentage: </td>' +
    '<td style="text-align: right;color:' +
    textColor +
    '">' +
    '<b>' +
    point.y +
    '</b>%</td></tr>' +
    '<tr><td>Kpi Value: </td>' +
    '<td style="text-align: right;color:' +
    textColor +
    '">' +
    '<b>' +
    point.kpiValue +
    '</b></td></tr></table>';
  tooltip += footer;

  return tooltip;
};
