import { action, persist, thunk } from 'easy-peasy';

import { fetchSites } from '../../shared/services/site';
import { getRocStations } from '../services/rockstations';

// Define initial state
const defaultRocStationState = persist({
  rocStations: [],
  rocStationsReserv: [],
  rocFilterText: '',
  sitesLength: 0,
  rockStationsLastUpdated: 0,
});

// Actions
const regionActions = {
  //rocStations
  setRocStations: action((state, payload) => {
    state.rocStations = payload;
    state.rocStationsReserv = payload;
  }),
  setSitesLength: action((state, payload) => {
    state.sitesLength = payload;
  }),

  fetchSites: thunk(async () => {
    return await fetchSites();
  }),

  fetchRocStations: thunk(async () => {
    const options = {};
    return await getRocStations(options);
  }),

  setRockLastUpdated: action((state) => {
    state.rockStationsLastUpdated = new Date().getTime();
  }),

  getCombinedData: thunk(async (actions, _, { getStoreState }) => {
    const promiseHelper = async () => {
      const getData = new Promise((resolve) => {
        const sites = { sites: Object.assign([], getStoreState().sites.sortedSites) };
        if (sites) {
          actions.fetchRocStations().then((roc) => {
            resolve({
              sites: sites,
              roc: roc,
            });
          });
        }
      });

      return getData;
    };

    await promiseHelper().then((object) => {
      const { rocStations, sitesLength } = filterSitesByUserRoles(object.sites, object.roc);
      const { removedNotFoundSites, removeCount } = removeNotFoundElementsFromSchema(rocStations);
      const resultCount = sitesLength - removeCount;
      actions.setRocStations(removedNotFoundSites);
      actions.setSitesLength(resultCount);
      actions.setRockLastUpdated();
    });
  }),

  setRocFilter: action((state, payload) => {
    state.rocFilterText = payload;
    const rocStations = state.rocStations;
    if (payload.length > 0) {
      state.rocStations = rocStations.filter((item) => {
        let value = item.siteGroupName.toLowerCase();
        return value.indexOf(payload) >= 0;
      });
    } else {
      state.rocStations = state.rocStationsReserv;
    }
  }),

  expandGroup: action((state, payload) => {
    const booleanState = payload.state;
    const siteGroupId = payload.item.siteGroupId;
    const newEditedRocStationList = [...state.rocStations];

    for (let i = 0; i < newEditedRocStationList.length; i++) {
      if (newEditedRocStationList[i].siteGroupId === siteGroupId) {
        if (booleanState) {
          newEditedRocStationList[i].expanded = true;
        } else {
          newEditedRocStationList[i].expanded = false;
        }
        state.rocStations = newEditedRocStationList;
        break;
      }
    }
  }),

  init: thunk(async (actions) => {
    actions.getCombinedData();
  }),
};

//separate logic functions

const filterSitesByUserRoles = (sitesObj, rocStations) => {
  const { sites } = sitesObj;

  let sitesLength = 0;

  for (let i = 0; i < rocStations.length; i++) {
    let schemes = rocStations[i].attributes[0].schema;
    sitesLength += schemes.length;
    for (let l = 0; l < schemes.length; l++) {
      for (let j = 0; j < sites.length; j++) {
        let siteId = sites[j].id;
        if (siteId == schemes[l]) {
          schemes[l] = sites[j];
        }
      }
    }
  }

  return {
    rocStations: rocStations,
    sitesLength: sitesLength,
  };
};

const removeNotFoundElementsFromSchema = (rocStations) => {
  let removeCount = 0;

  rocStations.sort((a, b) => a.siteGroupName.localeCompare(b.siteGroupName));

  for (let i = 0; i < rocStations.length; i++) {
    let schemes = [...new Set(rocStations[i].attributes[0].schema)];

    const filteredSites = schemes.filter((item) => {
      if (item?.id) {
        return item;
      } else {
        removeCount++;
      }
    });

    rocStations[i].attributes[0].schema = filteredSites;
  }

  return {
    removedNotFoundSites: rocStations,
    removeCount: removeCount,
  };
};

// Compile the view store object for export
const rocStationsModel = {
  ...defaultRocStationState,
  ...regionActions,
};

export default rocStationsModel;
