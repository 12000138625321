import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
import { Select } from '@ge/components/select';
import { DateTimeFormats } from '@ge/models/constants';

const StyledSectionContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SelectWrapper = styled.div`
  min-width: 254px;
  &:last-of-type {
    margin-left: 20px;
    flex: 1;
  }
  label {
    color: ${({ theme }) => theme.sensorReadingsChart.labelColor};
  }
`;

const CaseSensorReadingsFields = ({ issue, alert, onChange }) => {
  const { t } = useTranslation(['entity-details']);

  const { id: alertId, caseId } = useMemo(() => alert ?? { caseId: issue?.id }, [alert, issue]);

  const alerts = useMemo(() => issue?.alerts ?? [], [issue]);

  const handleCaseSelect = useCallback(
    (val) => {
      const selectedAlert = alerts.filter((a) => a.caseId === val)[0] ?? {};
      onChange({ ...selectedAlert, caseId: val });
    },
    [alerts, onChange],
  );

  const handleAlertSelect = useCallback(
    (id) => {
      onChange(alerts.find((a) => a.id === id));
    },
    [alerts, onChange],
  );

  const caseSelectOptions = useMemo(
    () =>
      [issue, ...issue?.childCases].map((anomaly) => ({
        value: anomaly.id,
        label: anomaly.description,
      })),
    [issue],
  );

  const alertSelectOptions = useMemo(
    () =>
      alerts
        .filter((a) => a.caseId === caseId)
        .map((v) => ({
          value: v.id,
          label: `${v.info} • ${dayjs(v.start).format(DateTimeFormats.ISSUE_DETAIL_DATE_TIME)}`,
        })) ?? [],
    [caseId, alerts],
  );

  return (
    <StyledSectionContainer>
      <SelectWrapper>
        <Select
          primary
          label={t('case_panel.sensor_readings.case', 'Case')}
          placeholder={t('case_panel.sensor_readings.case_placeholder', 'Select a case')}
          onChange={(option) => handleCaseSelect(option.value)}
          icon={Icons.ANOMALY}
          options={caseSelectOptions}
          value={caseSelectOptions.find((v) => v.value === caseId)}
        />
      </SelectWrapper>
      <SelectWrapper>
        <Select
          label={t('case_panel.sensor_readings.select_evidence', 'Select Evidence')}
          placeholder={t('case_panel.sensor_readings.evidence_placeholder', 'Select an evidence')}
          onChange={(option) => handleAlertSelect(option.value)}
          value={alertSelectOptions.find((v) => v.value === alertId)}
          options={alertSelectOptions}
        />
      </SelectWrapper>
    </StyledSectionContainer>
  );
};

export default CaseSensorReadingsFields;

CaseSensorReadingsFields.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

CaseSensorReadingsFields.defaultProps = {
  issue: null,
  alert: null,
  onChange: () => {},
};
