import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import { useMemo } from 'react';

import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';
import {
  ConditionsKpiCategoryDefs,
  DateTimeFormats,
  EntityType,
  KpiCategoryDefs,
  Placeholders,
  TimeAggr,
} from '@ge/models/constants';
import { InputEntityType } from '@ge/serverless-models/src/rendigital/enums';
import { useKpiData } from '@ge/shared/data-hooks';

dayjs.extend(isoWeek);

const NameKpiMap = {
  [WidgetNames.AVAILABILITY]: [KpiCategoryDefs.AVAILABILITY_CONTRACT],
  [WidgetNames.CONTRACTUAL_AVAILABILITY]: [KpiCategoryDefs.AVAILABILITY_CONTRACT],
  [WidgetNames.AVERAGE_WIND_SPEED]: [ConditionsKpiCategoryDefs.WIND],
  [WidgetNames.PERFORMANCE_SUMMARY]: [
    KpiCategoryDefs.AVAILABILITY_CONTRACT,
    KpiCategoryDefs.CAPACITY_FACTOR,
    KpiCategoryDefs.PRODUCTION_ACTUAL,
    ConditionsKpiCategoryDefs.WIND,
  ],
  [WidgetNames.PRODUCTION_GRAPH]: [KpiCategoryDefs.PRODUCTION_ACTUAL],
  [WidgetNames.PRODUCTION_TABLE]: [KpiCategoryDefs.ACTUAL_PRODUCTION_KPI],
  [WidgetNames.WIND_DIRECTION_SPEED]: [ConditionsKpiCategoryDefs.WIND_DIRECTION],
};

// using for placeholder values, the actual units come back with the data
const KpiUnitsMap = {
  [KpiCategoryDefs.AVAILABILITY_CONTRACT]: '%',
  [KpiCategoryDefs.CAPACITY_FACTOR]: '%',
  [KpiCategoryDefs.PRODUCTION_ACTUAL]: 'MWh',
  [ConditionsKpiCategoryDefs.WIND]: 'm/sec',
};

// TODO: might need to refactor this to make calls with different combinations of params depending on the specific widget needs
// TODO: update params to expose config and queryKey props similar to other hooks that were updated
export const useWidgetKpiData = ({
  endDate: _endDate,
  includePlaceholderData = false,
  isActive = true,
  name,
  startDate: _startDate,
}) => {
  const categories = NameKpiMap[name];

  const { siteIds } = useReportScope();

  let passThrough = false;
  let isMal = false;
  let entityAggr = InputEntityType.FLEET;
  let entityType = EntityType.FLEET;
  let timeAggr = TimeAggr.DAILY;

  let endDate = _endDate;
  let startDate = _startDate;

  if (name === WidgetNames.AVAILABILITY) {
    passThrough = true;
    isMal = true;
    entityType = EntityType.SITE;
    // entityAggr is set in the BFF in this case
  } else if (name === WidgetNames.CONTRACTUAL_AVAILABILITY) {
    passThrough = true;
    isMal = true;
    entityAggr = InputEntityType.SITE;
    entityType = EntityType.TURBINE;

    const maxCols = 11;
    const dayCount = dayjs(endDate).diff(startDate, 'day') + 1;

    if (dayCount / 7 > maxCols) {
      timeAggr = TimeAggr.MONTHLY;
    } else if (dayCount > maxCols) {
      timeAggr = TimeAggr.WEEKLY;
    }
  }

  if (name === WidgetNames.PRODUCTION_TABLE) {
    passThrough = true;
    entityAggr = InputEntityType.SITE;
    entityType = EntityType.TURBINE;

    const maxCols = 11;
    const dayCount = dayjs(endDate).diff(startDate, 'day') + 1;

    if (dayCount / 7 > maxCols) {
      timeAggr = TimeAggr.MONTHLY;
    } else if (dayCount > maxCols) {
      timeAggr = TimeAggr.WEEKLY;
    }
  }

  if (name === WidgetNames.PRODUCTION_TABLE || name === WidgetNames.PRODUCTION_GRAPH) {
    // Backend endpoint for this data will return an error if any future dates are passed
    const currentDate = dayjs();
    if (dayjs(endDate) > currentDate) {
      endDate = currentDate;
    }
    if (dayjs(startDate) > currentDate) {
      startDate = currentDate;
    }
  }

  const {
    data: _data,
    error,
    isLoading,
  } = useKpiData({
    categories,
    endDate: dayjs(endDate).format(DateTimeFormats.ENDPOINT_PARAM),
    // not ideal having to mix these entity type enums, should clean this up in backend at some point
    // this is working as expected (get a single entry for each date in time series), but don't see support for 'Fleet' aggr in swagger
    entityAggr,
    entityType,
    filters: { siteIds },
    isActive: isActive && Boolean(categories?.length),
    startDate: dayjs(startDate).format(DateTimeFormats.ENDPOINT_PARAM),
    swallowErrors: true,
    timeAggr,
    passThrough,
    isMal,
  });

  const data = useMemo(() => {
    if (!_data) {
      return null;
    }

    let mapped = _data;

    if (includePlaceholderData) {
      const placeholder = categories.reduce((mapped, category) => {
        mapped[category] = {
          units: KpiUnitsMap[category] ?? Placeholders.DOUBLE_DASH,
          value: null,
        };

        return mapped;
      }, {});

      mapped = mergeDeepLeft(_data, placeholder);
    }

    if (name === WidgetNames.AVAILABILITY) {
      const [category] = NameKpiMap[name];
      const { seriesData, kpiUnit } = _data[category] ?? {};
      if (!seriesData || seriesData.length === 0) {
        return null;
      }

      return {
        [name]: {
          units: kpiUnit,
          values: seriesData,
          timeAggr,
        },
      };
    } else if (
      name === WidgetNames.CONTRACTUAL_AVAILABILITY ||
      name === WidgetNames.PRODUCTION_TABLE
    ) {
      const normalizeDateFn = (dateValue) => {
        let normalizedDate = null;

        const weeklyAggrFormat = /^\d{6}$/; // YYYYWW
        const monthlyAggrFormat = /^[a-zA-Z]{3}-\d{4}$/; // MMM-YYYY

        if (timeAggr === TimeAggr.WEEKLY && weeklyAggrFormat.test(dateValue)) {
          const year = dateValue.substring(0, 4);
          const week = dateValue.substring(4);
          normalizedDate = dayjs().year(year).isoWeek(week);
        } else if (timeAggr === TimeAggr.MONTHLY && monthlyAggrFormat.test(dateValue)) {
          normalizedDate = dayjs(dateValue, DateTimeFormats.MONTHLY_AGGR_DATE);
        }

        return normalizedDate ?? dayjs(dateValue);
      };

      const [category] = NameKpiMap[name];
      const { seriesData: _seriesData, kpiUnit } = _data[category] ?? {};
      if (!_seriesData || _seriesData.length === 0) {
        return null;
      }

      const seriesData = _seriesData.map((value) => ({
        ...value,
        requestedDate: normalizeDateFn(value.requestedDate),
      }));

      // Master list of dates across all entities in the series, which may not be consistent from one entity to the next.
      // This is used to provide consistent data shape and sort order when creating the accumulator object for each entity.
      const allSeriesDates = Object.fromEntries(
        [...seriesData.reduce((acc, { requestedDate }) => acc.add(requestedDate), new Set())]
          .sort((a, b) => a - b)
          .map((d) => [d, null]),
      );

      const dataByAsset = seriesData.reduce((acc, value) => {
        const { assetId, assetName, padNumber, kpiValue, seriesWeightedValue, requestedDate } =
          value;
        const accValue = acc[assetId] ?? { id: assetId, assetName, padNumber, seriesWeightedValue };
        const accKpiValues = accValue.kpiValues ?? { ...allSeriesDates };
        return {
          ...acc,
          [assetId]: {
            ...accValue,
            kpiValues: {
              ...accKpiValues,
              [requestedDate]: kpiValue,
            },
          },
        };
      }, {});
      const dataByDate = seriesData.reduce((acc, value) => {
        const { totalValue, dateWeightedValue, requestedDate } = value;
        const accKpiValues = acc.kpiValues ?? { ...allSeriesDates };
        return {
          id: '0',
          kpiValues: {
            ...accKpiValues,
            [requestedDate]: dateWeightedValue,
          },
          totalValue,
        };
      }, {});
      dataByAsset.ALL_ASSETS = dataByDate;
      return {
        [name]: {
          units: kpiUnit,
          values: Object.values(dataByAsset ?? {}),
          timeAggr,
        },
      };
    }

    return {
      [name]: mapped,
    };
  }, [_data, categories, includePlaceholderData, name, timeAggr]);

  return { data, error, isLoading };
};
