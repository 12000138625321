import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { killEventPropagation } from '@ge/shared/util';

import { AnalysisColumns } from '../../../../models/analysis-template-col';

const MAX_SELECTION_LIMIT = 100;

const StyledHeaderCheckbox = styled(Checkbox)`
  margin-top: -2px;
  justify-content: center;
  span {
    display: none;
  }
`;

const StyledCheckbox = styled.div`
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

export const useAnalysisTemplate = ({ analysisTemplateData = [] }) => {
  const [checkedRows, setCheckedRows] = useState([]);

  const rows = useMemo(
    () =>
      analysisTemplateData.length > MAX_SELECTION_LIMIT
        ? analysisTemplateData.slice(0, MAX_SELECTION_LIMIT)
        : analysisTemplateData,
    [analysisTemplateData],
  );

  /**
   * Factory function to generate custom table cell header components in
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case AnalysisColumns.SELECTED:
          return (
            <>
              <StyledHeaderCheckbox
                checkState={
                  checkedRows.length === rows.length ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                onChange={(checked) => {
                  setCheckedRows(
                    checked ? rows.map((row) => row[AnalysisColumns.ANALYSIS_TEMPLATE_ID]) : [],
                  );
                }}
                label={''}
              />
            </>
          );
        default:
          return null;
      }
    },
    [checkedRows.length, rows],
  );

  const renderCheckbox = useCallback(
    (cellValue) => {
      return (
        <StyledCheckbox
          onClick={(e) => {
            killEventPropagation(e);
            e.preventDefault();

            if (checkedRows.includes(cellValue.id)) {
              setCheckedRows(checkedRows.filter((row) => row !== cellValue.id));
            } else if (checkedRows.length < rows.length) {
              setCheckedRows([...checkedRows, cellValue.id]);
            }
          }}
          className={
            checkedRows.length >= rows.length && !checkedRows.includes(cellValue.id)
              ? 'disabled'
              : ''
          }
        >
          <Checkbox
            checkState={
              checkedRows.includes(cellValue.id) ? CheckedState.CHECKED : CheckedState.UNCHECKED
            }
            label={''}
          />
        </StyledCheckbox>
      );
    },
    [checkedRows, rows],
  );
  useEffect(
    () =>
      setCheckedRows((prev) =>
        prev.filter((id) => rows.find((row) => row[AnalysisColumns.ANALYSIS_TEMPLATE_ID] === id)),
      ),
    [rows],
  );

  return {
    checkedRows,
    renderCheckbox,
    customHeaderFn,
  };
};
