export const ChartType = {
  COLUMN_SITE_ACTIVE: 'columnSiteActive',
  COLUMN_DURATION: 'columnDuration',
  COLUMN_GROUP: 'columnGroup',
  COLUMN: 'column',
  AREA: 'area',
  AREA_SPLINE: 'areaSpline',
  DONUT: 'donut',
  LINE: 'line',
  MIRROR_COLUMN: 'mirrorColumn',
  PARETO: 'pareto', // we're calling this a waterfall chart in the code
  ROSE: 'rose',
  SCATTER: 'scatter',
  SPLINE: 'spline',
  SPLINE_GROUP: 'splineGroup',
  STACKED_COLUMN: 'stackedColumn',
  BAR: 'bar',
  STACKED_BAR: 'stackedBar',
  HEAT_MAP: 'heatmap',
};
