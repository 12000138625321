import * as request from './api';

const SITE_CONTACT_BASE_URL = `/cmn/site-contact`;

export const getSiteContact = (siteId) => {
  return request.get(SITE_CONTACT_BASE_URL, {
    params: { siteId },
  });
};

export const setSiteContact = ({ siteId, desc, createdBy, isNew }) => {
  const body = { siteIds: [siteId], contact: { desc }, auditProperties: { createdBy } };
  return isNew
    ? request.post(SITE_CONTACT_BASE_URL, body, { params: { override: false } })
    : request.put(`${SITE_CONTACT_BASE_URL}/${siteId}`, body);
};
