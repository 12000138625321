import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchErpNotifications } from '../services';

export const useErpNotifications = (loadErpNotify) => {
  let { data, error, isFetching } = useQuery(
    [QueryKey.ERP_NOTIFICATIONS, loadErpNotify],
    () => fetchErpNotifications(),
    {
      enabled: !!loadErpNotify,
      staleTime: Config.REFRESH.realTime,
      refetchOnMount: true,
      refetchInterval: Config.REFRESH.reFetchErpNotification,
    },
  );

  return useMemo(() => ({ data, error, isFetching }), [data, error, isFetching]);
};
