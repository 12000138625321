import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu, placements } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledTitle = styled.div`
  background: ${({ theme }) => theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${({ theme }) => theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  h4 {
    text-transform: uppercase;
    margin-right: 6px;
  }
`;

const Content = styled.div`
  width: 294px;
  max-height: 200px;
  overflow: auto;
  padding: 10px 14px;
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const NotesText = styled.div`
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
`;

export const ResolutionNotesMenu = ({ containerRef, children, notes, titleKey, titleDefault }) => {
  const { t } = useTranslation(['tasks']);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleCloseMenu}>
      {children}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        container={containerRef}
        placement={placements.LEFT_END}
        onClick={handleMenuClick}
      >
        <StyledTitle>
          <h4>{t(`menu.${titleKey}`, titleDefault)}</h4>
        </StyledTitle>
        <Content>
          <NotesText>{notes}</NotesText>
        </Content>
      </Menu>
    </div>
  );
};

ResolutionNotesMenu.propTypes = {
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
  notes: PropTypes.string,
  titleKey: PropTypes.string.isRequired,
  titleDefault: PropTypes.string.isRequired,
};

ResolutionNotesMenu.defaultProps = {
  containerRef: null,
  notes: null,
};
