import { PropTypes } from 'prop-types';
import React from 'react';

import { WidgetNames } from '@ge/feat-reporting/models/widgets';

import { TotalModule } from '../total-module';

// going with how this logic is being handled for tables
const getBarByName = (name, id, configuration) => {
  switch (name) {
    case WidgetNames.PRODUCTION_GRAPH:
      return <TotalModule name={name} id={id} configuration={configuration} />;
    default:
      // temporary content for modules not yet implemented
      return (
        <div
          style={{
            height: 50,
            display: 'flex',
            alignzItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>Coming Soon</p>
        </div>
      );
  }
};

export const BarModule = ({ name, id, configuration }) => {
  const module = getBarByName(name, id, configuration);

  return <>{module}</>;
};

BarModule.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  configuration: PropTypes.object,
};
