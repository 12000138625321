import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { useManualAdjustmentContext } from '@ge/feat-analyze/context/manual-adjustment-context';
import { DateTimeFormats, Placeholders, Placements } from '@ge/models/constants';

import { LowestPerformerHeader } from './lowest-performer-header';

const PageTitle = styled.h1`
  margin-bottom: 32px;
`;

const MAHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.analyze.manualAdjustment.bordersColor};
  margin-bottom: 12px;
`;

const MySites = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const MySitesHeading = styled.h2`
  margin-right: 15px;
`;

const SitesCounts = styled.div`
  display: flex;
  align-items: center;
`;

const SitesIcons = styled(Icon).attrs((props) => ({
  size: props.size || 22,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))`
  margin-right: 4px;
`;

const Count = styled.span`
  margin-right: 14px;
  display: inline-block;
  font-size: 16px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: unset;
  margin: auto;
`;

const DateValue = styled.p`
  font-size: 22px;
  margin: 0;
  cursor: pointer;
`;

const LeftChevronButton = styled.button`
  margin-right: 10px;
  height: 17px;
  display: flex;
`;

const LeftChevron = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))`
  transform: rotate(90deg);
`;

const RightChevronButton = styled.button`
  margin-left: 10px;
  height: 17px;
  display: flex;
`;

const RightChevron = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.analyze.manualAdjustment.iconsColor,
}))`
  transform: rotate(-90deg);
`;

export const SitesHeader = ({ overviewData }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { calendarDate, handlePrevNextCalendarDate, handleCalendarDate } =
    useManualAdjustmentContext();

  const nextButtonVisibility = useMemo(
    () =>
      calendarDate >= dayjs().startOf('date').subtract(1, 'day').valueOf() ? 'hidden' : 'visible',
    [calendarDate],
  );

  const handleFilterDate = (date) => dayjs().startOf('date').toDate() > date;

  if (!ready) return null;

  return (
    <>
      <PageTitle>{t('page_title', 'Contractual Availability')}</PageTitle>
      <MAHeaderContainer>
        <MySites>
          <MySitesHeading>{t('my_sites', 'My Sites')}</MySitesHeading>
          <SitesCounts>
            <SitesIcons icon={Icons.SITE} />
            <Count>{overviewData?.totalCount?.sites ?? Placeholders.DOUBLE_DASH}</Count>
          </SitesCounts>
          <SitesCounts>
            <SitesIcons icon={Icons.TURBINE} />
            <Count>{overviewData?.totalCount?.assets ?? Placeholders.DOUBLE_DASH}</Count>
          </SitesCounts>
        </MySites>
        <DatePickerContainer>
          <LeftChevronButton type="button" onClick={() => handlePrevNextCalendarDate(true)}>
            <LeftChevron />
          </LeftChevronButton>
          <DatePicker
            tabIndex="0"
            selected={calendarDate}
            onChange={handleCalendarDate}
            popperPlacement={Placements.BOTTOM}
            filterDate={handleFilterDate}
            shouldCloseOnSelect={true}
            customInput={
              <div>
                <DateValue>{dayjs(calendarDate).format(DateTimeFormats.DEFAULT_DATE)}</DateValue>
              </div>
            }
          />
          <RightChevronButton
            type="button"
            onClick={() => handlePrevNextCalendarDate(false)}
            style={{
              visibility: nextButtonVisibility,
            }}
          >
            <RightChevron />
          </RightChevronButton>
        </DatePickerContainer>
        <LowestPerformerHeader />
      </MAHeaderContainer>
    </>
  );
};

SitesHeader.propTypes = {
  overviewData: PropTypes.object.isRequired,
};
