import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@ge/components/icon';

const StyledButton = styled.button`
  color: ${(props) => props.theme.button.textColor};
  display: flex;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 0px;
`;

const StyledButtonIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.button.iconColor,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const StyledIconButton = styled.button``;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.button.iconColor,
}))``;

export const ButtonLink = ({ className, label, icon, iconColor, onClick }) => (
  <StyledButton onClick={onClick} className={className}>
    {icon && <StyledButtonIcon icon={icon} color={iconColor} />}
    {label}
  </StyledButton>
);

export const IconButton = ({ title, icon, iconColor, onClick }) => (
  <StyledIconButton title={title} onClick={onClick}>
    <StyledIcon icon={icon} color={iconColor} />
  </StyledIconButton>
);

ButtonLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonLink.defaultValues = {
  className: 'button-link',
};

IconButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
};

IconButton.defaultValues = {
  className: 'button-link',
};
