import { useEffect, useMemo, useState } from 'react';

import { EntityType, TimeAggr } from '@ge/models/constants';
import { maxDayDiff } from '@ge/util';

import { FilterDefs, FilterOptions } from '../models';

const useTimeAggregation = ({ entityType, startDate, endDate }) => {
  const [timeAggregation, setTimeAggregation] = useState(null);

  // Changing the timeAggregation to default value when date range changes
  useEffect(() => {
    if (!startDate || !endDate) return;
    let timeAggr = null;
    switch (entityType) {
      case EntityType.REGION:
      case EntityType.FLEET:
      case EntityType.SITE:
        timeAggr = maxDayDiff(startDate, endDate, 31)
          ? TimeAggr.DAILY
          : maxDayDiff(startDate, endDate)
          ? TimeAggr.WEEKLY
          : maxDayDiff(startDate, endDate, 92)
          ? TimeAggr.WEEKLY
          : TimeAggr.MONTHLY;
        break;
      default:
        timeAggr = maxDayDiff(startDate, endDate, 92) ? TimeAggr.DAILY : TimeAggr.WEEKLY;
    }
    setTimeAggregation(timeAggr);
  }, [endDate, entityType, startDate]);

  const timeAggrOptions = useMemo(() => {
    if (!startDate || !endDate) return [];
    const allTimeAggrOptions = FilterOptions[FilterDefs.TIME_AGGREGATION];
    return maxDayDiff(startDate, endDate, 92)
      ? [allTimeAggrOptions[TimeAggr.DAILY], allTimeAggrOptions[TimeAggr.WEEKLY]]
      : [allTimeAggrOptions[TimeAggr.WEEKLY], allTimeAggrOptions[TimeAggr.MONTHLY]];
  }, [endDate, startDate]);

  return {
    showTimeAggr: true,
    timeAggregation,
    timeAggrOptions,
    onTimeAggrChange: setTimeAggregation,
  };
};

export default useTimeAggregation;
