import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { AlertType } from '@ge/models/constants';

const getIcon = (type) => {
  switch (type) {
    case AlertType.NOTAM:
      return 'NOTAM';
    case AlertType.WORKER_PRESENT:
      return 'WORKER_PRESENT';
    case AlertType.COMMISSIONING_OUTAGE:
      return 'COMMISSIONING_OUTAGE';
    case AlertType.RELIABILITY_RUN:
      return 'RELIABILITY_RUN';
    case AlertType.PLANNED_OUTAGE:
    case AlertType.UNPLANNED_OUTAGE:
    case AlertType.NOCOMM_OUTAGE:
      return 'OUTAGE';
    case AlertType.TEMPORARY_CONFIG_CHANGE:
      return 'TEMP_CONFIG';
    case AlertType.NON_OPERATING_ASSET:
      return 'NON_OPERATING_ASSET';
    default:
      return 'NOTAM';
  }
};

const StyledImage = styled.img`
  cursor: pointer;
  &.large-icon {
    width: 14px;
    height: 14px;
    &.group-alert {
      position: relative;
      bottom: 2px;
      width: 17px;
      height: 20px;
    }
  }
`;

export const AlertIcon = ({ large, type, invalid, isGroup }) => {
  const image = useMemo(() => {
    const iconType = isGroup ? 'GROUPED_' : '';
    let alertType = getIcon(type);
    alertType += invalid ? '_INVALID' : '';
    const size = large ? 'large' : 'small';
    return require(`./icons/${size}/${iconType}${alertType}.svg`);
  }, [isGroup, type, invalid, large]);

  return (
    <StyledImage
      className={`${large ? 'large-icon' : 'small-icon'} ${isGroup ? 'group-alert' : ''} `}
      src={image}
    />
  );
};

AlertIcon.propTypes = {
  large: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(AlertType)).isRequired,
  invalid: PropTypes.bool,
  isGroup: PropTypes.bool,
};

AlertIcon.defaultProps = {
  large: false,
  invalid: false,
  isGroup: false,
};
