// TODO: can move collapsible panel out of here if it makes sense

// TODO: move some of the task template stuff we're using in alerts up a level?

import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormMode } from '@ge/models/constants';
import { TextareaMetaField } from '@ge/shared/components/meta-fields/textarea-meta-field';

import { AlertCollapsiblePanel } from '../alert-shared';

export const DescriptionField = ({ defaultValue, mode, name, ...metadata }) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });
  const { control } = useFormContext();
  const value = useWatch({ control, defaultValue, name });
  const [resizeToggle, setResizeToggle] = useState();

  // would be nice to pull this behavior out into a reusable collapsible textarea
  const handleResize = useCallback(() => {
    setResizeToggle((prev) => !prev);
  }, [setResizeToggle]);

  if (!(ready && metadata) || metadata.hidden) {
    return null;
  }

  const collapsed = mode == 'view' ? !metadata.collapsed : metadata.collapsed && !value;
  const translations = {
    headerLabel: t('form.description.label', 'Description'),
    placeholderLabel: t('form.description.placeholder_label', 'Add a description'),
  };

  return (
    <AlertCollapsiblePanel
      expanded={!collapsed}
      header={translations.headerLabel}
      mode={mode}
      name={name}
      renderToggle={resizeToggle}
      view={value}
      plusIcon={true}
    >
      <TextareaMetaField
        defaultValue={defaultValue}
        metadata={metadata}
        maxLength={metadata.maxLength}
        name={name}
        placeholder={translations.placeholderLabel}
        onResize={handleResize}
      />
    </AlertCollapsiblePanel>
  );
};

DescriptionField.propTypes = {
  collapsed: PropTypes.bool,
  defaultValue: PropTypes.string,
  hidden: PropTypes.bool,
  maxLength: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  name: PropTypes.string,
};

DescriptionField.defaultProps = {
  collapsed: true,
  defaultValue: undefined,
  hidden: false,
  mode: FormMode.VIEW,
  name: 'description',
};
