import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SparkLineChart } from '@ge/components/charts';

const MetricWrapper = styled.div`
  padding: 10px;
`;

const MetricValue = styled.div`
  display: flex;
  margin-bottom: 6px;
  align-items: baseline;
  margin-top: 4px;

  &.danger-txt {
    color: ${(props) => props.theme.assetCard.dangerColor};
  }

  h5 {
    margin-left: 3px;
  }
`;

const ChartContainer = styled.div`
  height: 30px;
  width: 100%;
`;

const checkDanger = (value, threshold) => value >= threshold;

export const MetricStatusCard = ({ chartData, metric, threshold, unit, values }) => {
  const { t } = useTranslation();
  const currentValue = values[values.length - 1];
  const danger = checkDanger(currentValue, threshold);
  const metricLabel = metric.replace(/-/g, '_');
  const unitLabel = unit.replace(/-/g, '_');

  return (
    <MetricWrapper>
      <h3>{t(metricLabel, '')}</h3>
      <MetricValue className={danger ? 'kpi-2 danger-txt' : 'kpi-2'}>
        {values[values.length - 1]}
        <h5>{t(unitLabel, '')}</h5>
      </MetricValue>
      <ChartContainer>
        <SparkLineChart danger={danger} data={chartData} height={30} />
      </ChartContainer>
    </MetricWrapper>
  );
};

MetricStatusCard.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  metric: PropTypes.string,
  threshold: PropTypes.number,
  unit: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number),
};

MetricStatusCard.defaultProps = {
  chartData: [],
  metric: 0,
  threshold: 0,
  unit: 0,
  values: [],
};
