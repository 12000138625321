import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { FLAGGED_STATES } from '@ge/models/constants';
import { useEditEventManagement, useUpdateEventManagement } from '@ge/shared/data-hooks';
import {
  useProcProperties,
  useEventValidationList,
} from '@ge/shared/data-hooks/use-event-management';

import { useSSO } from '../../../inspections/data-hooks/use-session';

import { CreateEMForm } from './create-form';
import { CreateHeader } from './em-headers';

const StyledButton = styled(Button)`
  float: right;
  margin: 1em 0.5em;
`;

const ScrollableDiv = styled.div`
  max-height: calc(100vh - 200px);
  text-transform: capitalize;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;
const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;
const SitesType = {
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
};

export const CreateEM = ({ details, close, type, toggleConfirmPopup, ispopup }) => {
  const { t } = useTranslation(['admin.configure']);
  const history = useHistory();
  const { control, formState } = useFormContext();
  const { data: sso } = useSSO();
  const { editEM } = useEditEventManagement();
  const { updateEM } = useUpdateEventManagement();

  const [values, setValues] = useState({});
  const [procDetails, setProcDetails] = useState({
    id: '',
    title: '',
  });
  const [selectedEventId, setSelectedEventId] = useState();
  const [loading, setLoading] = useState({ load: false, error: '' });

  useEffect(() => {
    if (details) {
      details?.flaggedProperties?.eligableStates?.forEach((rec) => {
        return control.setValue(rec.toLowerCase(), true);
      });
      control.setValue(
        'sitesType',
        details?.flaggedProperties?.excludeSitesFilter?.length
          ? SitesType.EXCLUDE
          : SitesType.INCLUDE,
      );
      control.setValue('blockCommands', details?.commandProperties?.blockCommands || false);
      control.setValue('blockAutomation', details?.automationProperties?.blockAutomation || false);
      control.setValue('maxResetLimit', details?.commandProperties?.maxResetLimit || 0);
      control.setValue('desc', details?.eventRemarks);

      setSelectedEventId(details?.eventId);
    }
  }, [control, details]);

  const getValues = useCallback(() => {
    return {
      eventMapId: control.getValues('eventsProp.EventMap')?.value,
      eventRemarks: control.getValues('desc'),
      eventId: control.getValues('eventsProp.EventCode')?.value,
      sites: control.getValues('sites'),
      sitesType: control.getValues('sitesType'),
      maxResetLimit: control.getValues('maxResetLimit') || 0,
      resetTimeRange: control.getValues('resetTimeRange'),
      blockCommands: control.getValues('blockCommands'),
      blockAutomation: control.getValues('blockAutomation'),
    };
  }, [control]);

  const onSave = useCallback(() => {
    setLoading({ load: true, error: '' });

    const {
      eventMapId,
      eventRemarks,
      eventId,
      sites,
      sitesType,
      maxResetLimit,
      blockCommands,
      blockAutomation,
    } = getValues();

    if (parseInt(maxResetLimit) < 0 || parseInt(maxResetLimit) > 999) {
      setLoading({
        load: false,
        error: 'Range of Max Reset Limit is 0-999',
      });
      return;
    }

    const flaggedProperties = {
      enabled: false,
      eligableStates: Object.keys(FLAGGED_STATES).filter((key) => {
        return control.getValues(key.toLowerCase());
      }),
      [sitesType === SitesType.INCLUDE ? 'includeSitesFilter' : 'excludeSitesFilter']: sites.map(
        (rec) => {
          return { siteId: rec.value };
        },
      ),
      [sitesType === SitesType.INCLUDE ? 'excludeSitesFilter' : 'includeSitesFilter']: [],
    };

    flaggedProperties.enabled = flaggedProperties?.eligableStates?.length > 0;

    const data = {
      eventMapId,
      events: [
        {
          eventId,
          eventRemarks,
          ...values,
          flaggedProperties,
          commandProperties: {
            maxResetLimit: maxResetLimit || 0,
            resetTimeRange: 0,
            blockCommands: blockCommands || false,
          },
          automationProperties: {
            blockAutomation: blockAutomation || false,
          },
          procProperties: {
            procId: procDetails.id,
            procTitle: procDetails.title,
          },
          auditProperties: {
            createdBy: sso,
            updatedBy: sso,
            createdTime: Date.now(),
            updatedTime: Date.now(),
          },
        },
      ],
    };

    const triggerDLRequest = () => {
      if (type === 'Edit') {
        data.eventMapId = details.eventMapId;
        data.events[0].eventId = details.eventId;

        return editEM({ id: data.eventMapId, body: data });
      } else {
        return updateEM({ id: data.eventMapId, body: data });
      }
    };

    triggerDLRequest()
      .then(() => {
        setLoading({ load: false, error: '' });
        if (type === 'Edit') {
          history.goBack();
        } else {
          close();
        }
      })
      .catch((err) => {
        setLoading({
          load: false,
          error: err?.response?.data?.message || err?.response?.statusText || err?.message,
        });
      });
  }, [
    close,
    control,
    details?.eventId,
    details?.eventMapId,
    editEM,
    getValues,
    history,
    procDetails?.id,
    procDetails?.title,
    sso,
    type,
    updateEM,
    values,
  ]);

  const { data } = useProcProperties(selectedEventId);

  const { isExist } = useEventValidationList({
    id: control.getValues('eventsProp.EventCode')?.value,
    mapId: control.getValues('eventsProp.EventMap')?.value,
  });

  useEffect(() => {
    setProcDetails({
      id: data?.events[0].procProperties?.procId || '',
      title: data?.events[0].procProperties?.procTitle || '',
    });
  }, [data]);

  const isValid = useCallback(() => {
    const { eventId, eventMapId } = getValues();

    let bool = eventMapId && eventId;

    return bool;
  }, [getValues]);

  const buttonProps = useCallback(() => {
    let obj = {
      primary: true,
    };

    if (isExist) {
      obj.disabled = true;
    } else if ((type && type === 'Edit') || isValid()) {
      obj['onClick'] = () => onSave();
    } else {
      obj.disabled = true;
    }

    return obj;
  }, [isExist, isValid, onSave, type]);

  const getCancelFooter = () => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={toggleConfirmPopup}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={onOkClick}>
            {t('general:ok', 'OK')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  };

  const onCancel = () => {
    console.log(formState.isDirty, 'dirty-1');
    if (Object.keys(formState?.dirtyFields).length) {
      toggleConfirmPopup();
    } else {
      close();
    }
  };
  const onOkClick = () => {
    toggleConfirmPopup();
    close();
  };

  return (
    <>
      {loading.load ? (
        <Loader />
      ) : (
        <>
          <CreateHeader type={type} data={details} error={loading.error} />
          <ScrollableDiv>
            <CreateEMForm
              details={details}
              type={type}
              isExist={isExist}
              values={values}
              setValues={setValues}
              procDetails={procDetails}
              setSelectedEventId={setSelectedEventId}
            />
            <StyledButton {...buttonProps()}>{t('save', 'Save')}</StyledButton>

            <Dialog
              isOpen={ispopup}
              onClose={toggleConfirmPopup}
              onConfirm={close}
              header={t('general:confirmation', 'Confirmation')}
              footer={getCancelFooter()}
              contentWidth
              padContent={true}
            >
              <p>
                {t(
                  'Event_managment.cancel_confirmation_message',
                  'Are you sure you want to cancel event?',
                )}
              </p>
            </Dialog>

            {type !== 'view' ? (
              <StyledButton onClick={onCancel}>{t('cancel', 'Cancel')}</StyledButton>
            ) : (
              ''
            )}
          </ScrollableDiv>
        </>
      )}
    </>
  );
};

CreateEM.propTypes = {
  details: PropTypes.object,
  close: PropTypes.any,
  type: PropTypes.string,
  setProcId: PropTypes.string,
  setProcTitle: PropTypes.string,
  toggleConfirmPopup: PropTypes.func,
  ispopup: PropTypes.bool,
};
