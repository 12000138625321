import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { CreateReport } from '@ge/feat-reporting/components/create-report';
import { MyReports } from '@ge/feat-reporting/components/my-reports';
import { ReportsProvider } from '@ge/feat-reporting/context/reports-provider';
import { useReportTemplate } from '@ge/feat-reporting/data-hooks';
import { ReportsSubNavItems, TabState } from '@ge/feat-reporting/models/reports-sub-nav-items';
import { ReportViews } from '@ge/models/constants';
import { globalColors, StatusColor } from '@ge/tokens/colors';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  position: relative;
  button {
    align-self: center;
    position: absolute;
    right: 20px;
  }
`;

const StyledDialog = styled(Dialog)`
  width: 400px;
`;

const ErrorBoundaryMessage = styled.p`
  color: ${(props) => props.theme.errorBoundaryDialog.errorMessageText};
  font-size: 12px;
  font-weight: bold;
`;

const ContactSupport = styled.span`
  color: ${globalColors.teal3};
`;

const ErrorBoundaryContent = styled.div`
  text-align: center;
  color: ${StatusColor.DANGER};
  font-weight: bold;
`;

const StyledIcon = styled(Icon).attrs(() => ({
  size: 10,
  color: StatusColor.DANGER,
}))``;

const Reports = () => {
  const { t, ready } = useTranslation(['reporting.reports', 'general'], {
    useSuspense: false,
  });

  const { filter, id } = useParams();

  const { data: reportTemplate, dataUpdatedAt } = useReportTemplate({ templateId: id });

  const [disableTabState, setDisabledTabState] = useState(filter === ReportViews.MY_REPORTS);

  const setDisabledComponent = useCallback((value) => {
    setDisabledTabState(value);
  }, []);

  const currentView = useMemo(() => {
    switch (filter) {
      case ReportViews.CREATE:
        return <CreateReport data={reportTemplate} dataUpdatedAt={dataUpdatedAt} />;
      case ReportViews.MY_REPORTS:
        return <MyReports disableNavigation={setDisabledComponent} />;
      default:
        return null;
    }
  }, [filter, reportTemplate, dataUpdatedAt, setDisabledComponent]);

  useEffect(() => {
    if (filter === ReportViews.MY_REPORTS && !disableTabState) {
      setDisabledTabState(true);
    }
  }, [disableTabState, filter, setDisabledTabState]);

  // TODO: make this a global component that all error boundaries can use
  const errorFallback = (errorBoundary) => {
    return (
      <StyledDialog
        isOpen={true}
        onClose={errorBoundary?.resetErrorBoundary}
        header={t('general:unhandled_error.title', 'Unhandled Error')}
        footer={
          <>
            <ErrorBoundaryMessage>{errorBoundary?.error?.message}</ErrorBoundaryMessage>
            <p>
              {t('general:unhandled_error.need_assistance', 'Need assistance?')}{' '}
              <ContactSupport>
                {t(
                  'general:unhandled_error.contact_support',
                  'Please contact Lifespan.Support@ge.com',
                )}
              </ContactSupport>
            </p>
          </>
        }
      >
        <ErrorBoundaryContent>
          <StyledIcon icon={Icons.DATA_NETCOMM} />
          <p>
            {t(
              'general:unhandled_error.message',
              'The application has encountered an unhandled error',
            )}
          </p>
          <Button primary onClick={errorBoundary?.resetErrorBoundary}>
            {t('general:unhandled_error.reload_button', 'Reload')}
          </Button>
        </ErrorBoundaryContent>
      </StyledDialog>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={errorFallback}>
      <ReportsProvider>
        <PageContainer i18nReady={ready}>
          <Header>
            <h1 className="pageTitle">{t('reports', 'Reports')}</h1>
          </Header>
          <SubNavigation
            navItems={ReportsSubNavItems[disableTabState ? TabState.DISABLED : TabState.ACTIVE]}
          />
          {currentView}
        </PageContainer>
      </ReportsProvider>
    </ErrorBoundary>
  );
};

export default withRouter(Reports);
