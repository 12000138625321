const {
  AssetState,
  EntityType,
  KpiCategoryDefs,
  AssetConnectionStatus,
} = require('@ge/models/constants');

const DisplayType = {
  asset: 'Wind_Turbine',
  park: 'Site',
};

const IdType = {
  canonical: 'canonical',
  source: 'source',
  all: 'all',
};

// this is based on naming from old version of api
// so can update to a more relevant name if needed
const InputEntityType = {
  CUSTOMER: 'Customer',
  FLEET: 'Fleet',
  REGION: 'Region',
  SITE: 'Site',
  SITE_CONTROLLER: 'Site Controller',
  SUBSTATION: 'Substation',
  TURBINE: 'Turbine',
  STORAGE_INVERTER: 'Storage Inverter',
  INVERTER: 'Inverter',
};

// extends basic entity types with specific types of fetches (like by site id, etc)
const EntityApiType = {
  ...InputEntityType,
  SITE: 'siteById',
  TURBINE_BY_SITE: 'turbineBySiteId',
  TURBINE_BY_SITES: 'assetTurbineBySiteId',
  STORAGE_INVERTER_BY_SITE: 'storageBySiteId',
  SOLAR_INVERTER_BY_SITE: 'solarBySiteId',
  ASSET_COUNT_BY_SITES: 'assetCountBySites',
  ASSET_COUNT_BY_SITE_ID: 'assetCountBySiteId',
};

// maps ui entity type enum to values expected in backend
const EntityTypeMap = {
  [EntityType.CUSTOMER]: InputEntityType.CUSTOMER,
  [EntityType.FLEET]: InputEntityType.FLEET,
  [EntityType.REGION]: InputEntityType.REGION,
  [EntityType.SITE]: InputEntityType.SITE,
  [EntityType.TURBINE]: InputEntityType.TURBINE,
  // TODO: add add'l mappings as needed
};

const CaseEntityParams = {
  TENANT_ID: 'tenantId',
  SITE_ID: 'siteIds',
  ALERT_IDS: 'alertIds',
  STATUS: 'status',
  SORT_DIRECTION: 'direction',
  SORT_METRIC: 'sortKey',
  PAGE_INDEX: 'pageNum',
  PAGE_SIZE: 'pageSize',
  LAST_UPDATED_START: 'lastUpdatedStart',
};

const KpiSubType = {
  CONTRACT: 'contract',
  LEARNED: 'learned',
};

const ReportingKpiSubType = {
  TURBINES: 'REPTURB',
  SITES: 'REPSITES',
};

const SignalIds = {
  Power: 'WTUR.W',
  Speed: 'WNAC.WdSpd',
  TurbineStatus: 'WTUR.TurStStn',
  InvPower: 'BINV.W',
};

// TODO: consolidate this with enum in @ge/models/constants
const TimeAggrType = {
  daily: 'daily',
  monthly: 'monthly',
  weekly: 'weekly',
};

const AggregatedTurbineStatusSignals = {
  ONLINE: 'WAPC.NumOpTur',
  AVAILABLE: 'WAPC.NumAvlTur',
  IMPACTED: 'WAPC.NumImpTur',
  MAINTENANCE: 'WAPC.NumMntTur',
  REPAIR: 'WAPC.NumRprTur',
  NON_COMMUNICATING: 'WAPC.NumNcmTur',
  STOPPED: 'WAPC.NumStpTur',
  FAULTED: 'WAPC.NumFltTur',
  NO_DATA: 'WAPC.NumNoData',
};

const AggregatedStorageInverterStatusSignals = {
  ONLINE: 'BAPC.NumOpTur',
  AVAILABLE: 'BAPC.NumAvlTur',
  IMPACTED: 'BAPC.NumImpTur',
  MAINTENANCE: 'BAPC.NumMntTur',
  REPAIR: 'BAPC.NumRprTur',
  NON_COMMUNICATING: 'BAPC.NumNcmTur',
  STOPPED: 'BAPC.NumStpTur',
  FAULTED: 'BAPC.NumFltTur',
  NO_DATA: 'BAPC.NumNoData',
};

const SiteAggregatedSignals = {
  POWER: 'WAPC.PIW', // Sum of Turbines Power
  WIND_SPEED: 'WAPC.WdSpd', // Average of Wind Speed
  TEMP: 'WAPC.ExTmp', // Average of Turbine temp.
  AVAILABILITY: 'WAPC.Avl', // Availability
  REACTIVE_POWER: 'WAPC.VAr', // Sum of turbines reactive power or Site controllers Reactive power
  CONNC_STATUS: 'WAPC.CONNStatus', // Indicates whether the site is fully connected, partially connected, disconnected.
  ASSET_CONNC_STATUS: 'WTUR.CONNStatus', // Indicates whether the asset is fully connected, partially connected, disconnected.
  BOP_SC_CONNC_STATUS: 'MMXU.CONNStatus', // Indicates whether the BOP/SC is fully connected, partially connected, disconnected.
  STORAGE_POWER: 'BAPC.GriAtW', // Sum of Inverters Power
  STORAGE_AVAILABILITY: 'BAPC.Avl', // Availability
  STORAGE_REACTIVE_POWER: 'BAPC.GriVAr', // Sum of inverters reactive power or Site controllers Reactive power
  STORAGE_SOC: 'BAPC.ChaSt',
  STORAGE_CONNC_STATUS: 'BAPC.CONNStatus', // Indicates whether the site is fully connected, partially connected, disconnected.
  STORAGE_ASSET_CONNC_STATUS: 'BINV.CONNStatus', // Indicates whether the asset is fully connected, partially connected, disconnected.
};

// Reference:  https://devcloud.swcoe.ge.com/devspace/display/NBJXM/OEM+Business+Logic
const TurbineStatusMapping = {
  1: AssetState.ONLINE,
  2: AssetState.IMPACTED,
  3: AssetState.STOPPED,
  4: AssetState.AVAILABLE,
  5: AssetState.STOPPED,
  6: AssetState.TRIPPED,
  7: AssetState.MAINTENANCE,
  8: AssetState.REPAIR,
  9: AssetState.TRIPPED,
  10: AssetState.STOPPED,
  11: AssetState.NO_DATA,
  12: AssetState.NO_DATA,
  13: AssetState.ONLINE,
  14: AssetState.AVAILABLE,
  15: AssetState.AVAILABLE,
  16: AssetState.STOPPED,
  17: AssetState.NO_DATA,
};

const ConnectionStatus = {
  1: AssetConnectionStatus.CONNECTED,
  '-1': AssetConnectionStatus.NO_DATA,
  0: AssetConnectionStatus.NOCOMM,
};

// values expected in rendigital api
const KpiCategory = {
  EVENT_DURATION: 'AVAIL',
  EVENT_OCCURRENCE: 'TOC',
  EVENT_RATES: 'FR',
  PRODUCTION_ACTUAL: 'TAP',
  EVENT_UNAVAILABLE_TIME: 'UNAVAIL',
  UNPRODUCED_ENERGY_CONTRACT: 'TLPC',
  UNPRODUCED_ENERGY_LEARNED: 'TLPL',
};

const KpiCategoryMap = {
  [KpiCategoryDefs.EVENT_DURATION]: KpiCategory.EVENT_DURATION,
  [KpiCategoryDefs.EVENT_OCCURRENCE]: KpiCategory.EVENT_OCCURRENCE,
  [KpiCategoryDefs.EVENT_RATES]: KpiCategory.EVENT_RATES,
  [KpiCategoryDefs.EVENT_UNAVAILABLE_TIME]: KpiCategory.EVENT_UNAVAILABLE_TIME,
  [KpiCategoryDefs.PRODUCTION_ACTUAL]: KpiCategory.PRODUCTION_ACTUAL,
  [KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]: KpiCategory.UNPRODUCED_ENERGY_CONTRACT,
  [KpiCategoryDefs.UNPRODUCED_ENERGY_LEARNED]: KpiCategory.UNPRODUCED_ENERGY_LEARNED,
};

module.exports = {
  DisplayType,
  EntityApiType,
  EntityTypeMap,
  IdType,
  InputEntityType,
  CaseEntityParams,
  KpiCategory,
  KpiCategoryMap,
  KpiSubType,
  SignalIds,
  TimeAggrType,
  AggregatedTurbineStatusSignals,
  AggregatedStorageInverterStatusSignals,
  SiteAggregatedSignals,
  TurbineStatusMapping,
  ConnectionStatus,
  ReportingKpiSubType,
};
