import { TableFilterTypes } from '@ge/models/constants';

const MAX_FILTERED_VALUES = 1000;

export const ManualAdjustmentsColumns = {
  GROUP_SELECTED: 'group-selected',
  SELECTED: 'selected',
  // Assets
  GROUP_MAL_DATA: 'group-mal-data',
  ASSET: 'assets',
  EVENT: 'event',
  START: 'start',
  STOP: 'stop',
  DATE_SUBMITTED: 'dateSubmitted',
  STATUS: 'status',
};

export const ManualAdjustmentsColumnDefs = {
  [ManualAdjustmentsColumns.GROUP_SELECTED]: {
    locked: true,
    minWidth: '75px',
    cols: {
      [ManualAdjustmentsColumns.SELECTED]: {
        labels: [
          {
            a11yKey: 'table.check_box',
            a11yDefault: '',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [ManualAdjustmentsColumns.GROUP_MAL_DATA]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: '',
      },
    ],
    cols: {
      [ManualAdjustmentsColumns.ASSET]: {
        // minWidth: '50px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        locked: true,
        labels: [
          {
            a11yKey: 'table.assets',
            a11yDefault: 'Assets',
            sortValue: ManualAdjustmentsColumns.ASSET,
          },
        ],
        cell: {
          entity: true,
        },
      },
      [ManualAdjustmentsColumns.EVENT]: {
        minWidth: '74px',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.event',
            a11yDefault: 'Event',
            sortValue: ManualAdjustmentsColumns.EVENT,
          },
        ],
      },
      [ManualAdjustmentsColumns.START]: {
        locked: false,
        filterType: TableFilterTypes.DATE,
        align: 'center',
        labels: [
          {
            a11yKey: 'table.start',
            a11yDefault: 'Start',
            sortValue: 'selected.start',
          },
        ],
        cell: {
          maxWidth: '80px',
        },
      },
      [ManualAdjustmentsColumns.STOP]: {
        locked: false,
        filterType: TableFilterTypes.DATE,
        align: 'center',
        labels: [
          {
            a11yKey: 'table.stop',
            a11yDefault: 'Stop',
            sortValue: 'selected.stop',
          },
        ],
        cell: {
          maxWidth: '80px',
        },
      },
      [ManualAdjustmentsColumns.DATE_SUBMITTED]: {
        locked: false,
        filterType: TableFilterTypes.DATE,
        align: 'center',
        labels: [
          {
            a11yKey: 'table.datesubmitted',
            a11yDefault: 'Date Submitted',
            sortValue: 'selected.dateSubmitted',
          },
        ],
        cell: {
          width: '220px',
          maxWidth: '220px',
        },
      },
      [ManualAdjustmentsColumns.STATUS]: {
        filterType: TableFilterTypes.CHECKBOXES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.status',
            a11yDefault: 'status',
            sortValue: 'status',
          },
        ],
      },
    },
  },
};
