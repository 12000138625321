// the controller allows for multiple commands to be issued and displayed concurrently
// it was difficult to try to create this behavior at the hook level, managing a queue of requests
// so the behavior was decomposed into a component that could render multiple components with their own hooks
// can discuss/revisit this approach

import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useNotification } from '@ge/components/notification';
import { AssetCommandStatus, NotificationActionType, NotificationType } from '@ge/models/constants';
import { CommandMessage } from '@ge/shared/components/notifications';
import { useAssetCommandStatus } from '@ge/shared/data-hooks';

const notificationTypeMap = {
  // shouldn't be getting status of blocked from executed commands but just in case
  [AssetCommandStatus.BLOCKED]: NotificationType.ERROR,
  [AssetCommandStatus.FAIL]: NotificationType.ERROR,
  [AssetCommandStatus.IN_PROGRESS]: NotificationType.INFO,
  [AssetCommandStatus.SUCCESS]: NotificationType.SUCCESS,
  [AssetCommandStatus.TIME_OUT]: NotificationType.ERROR,
};

const getNotifyOptions = ({
  assets = [],
  command,
  onAssetDetails,
  onSiteDetails,
  status: _status = [],
}) => {
  // assuming single asset passed in, can validate this more explicitly if needed
  const asset = assets[0];
  const { id, name, site } = asset;
  const { status = AssetCommandStatus.IN_PROGRESS } = _status[0] ?? {};

  const actions = [
    {
      // TODO: get site name from store if not on asset model already
      label: site.name,
      onClick: () => onSiteDetails(site.id),
      type: NotificationActionType.SITE_DETAILS,
    },
    {
      label: name,
      onClick: () => onAssetDetails(id),
      type: NotificationActionType.TURBINE_DETAILS,
    },
  ];

  return {
    actions,
    // this defines uniqueness to avoid duplicate banners
    id: `${site.id}_${asset.id}_${command}_${status}`,
    message: <CommandMessage command={command} status={status} />,
    // will still persist errors, can revise this behavior if needed
    persist: false,
    type: notificationTypeMap[status],
  };
};

const AnchorContainer = styled.div`
  display: none;
`;

const CommandNotificationAnchor = ({
  assets,
  command,
  onAssetDetails,
  onSiteDetails,
  onUnregister,
  statusId,
}) => {
  const [notificationId, setNotificationId] = useState('');

  // data hooks
  const { notify } = useNotification();
  const {
    data: { isComplete, isExecuting, status },
  } = useAssetCommandStatus({ assets, statusId });

  // watch command execution for updates
  useEffect(() => {
    if (!isExecuting) {
      return;
    }

    const options = getNotifyOptions({ assets, command, onAssetDetails, onSiteDetails, status });

    // TODO: track status changes and dismiss stale status (per demo discussion)?
    setNotificationId(notify(options));
  }, [assets, command, isExecuting, notify, onAssetDetails, onSiteDetails, status]);

  // unregister anchor when command completes
  useEffect(() => {
    if (isComplete) {
      onUnregister(statusId);
    }
  }, [isComplete, onUnregister, statusId]);

  if (!notificationId) {
    return null;
  }

  return <AnchorContainer id={notificationId} />;
};

CommandNotificationAnchor.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  command: PropTypes.string.isRequired,
  onAssetDetails: PropTypes.func,
  onSiteDetails: PropTypes.func,
  onUnregister: PropTypes.func,
  statusId: PropTypes.string.isRequired,
};

CommandNotificationAnchor.defaultProps = {
  onAssetDetails: () => {},
  onSiteDetails: () => {},
  onUnregister: () => {},
};

// could make this more generic for any task that involves a notification queue
// and then have a type prop to deteremine which specific anchor to drop in the controller
export const CommandNotificationController = () => {
  // store
  const removeCommand = useStoreActions((actions) => actions.assets.removeCommand);
  const commandMap = useStoreState((state) => state.assets.commands);

  const commands = Object.values(commandMap ?? {});

  // when anchor unregisters, remove command from store since we don't need to track it anymore
  const handleUnregister = useCallback(
    (statusId) => {
      removeCommand(statusId);
    },
    [removeCommand],
  );

  return (
    <>
      {commands.map(({ statusId, ...props }) => (
        <CommandNotificationAnchor
          key={statusId}
          onUnregister={handleUnregister}
          statusId={statusId}
          {...props}
        />
      ))}
    </>
  );
};
