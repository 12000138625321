import { PropTypes } from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const baseCss = css`
  line-height: 16px;
  padding: 20px 10px 8px;
  color: ${(props) => props.theme.navigation.linkColor};
  border-bottom: solid 3px ${(props) => props.theme.navigation.linkBorderColor};
  &:hover,
  &.active {
    font-weight: bold;
    border-color: ${(props) => props.theme.navigation.linkActiveBorderColor};
    color: ${(props) => props.theme.navigation.linkActiveColor};
  }
`;

const secondaryCss = css`
  padding: 8px 10px 8px;
  line-height: 16px;
  color: ${(props) => props.theme.subNavigation.linkColor};
  border-bottom: solid 3px ${(props) => props.theme.subNavigation.linkBorderColor};
  &:hover,
  &.active {
    font-weight: bold;
    border-color: ${(props) => props.theme.subNavigation.linkActiveBorderColor};
    color: ${(props) => props.theme.subNavigation.linkActiveColor};
  }
`;

const StyledNavTab = styled(NavLink)`
  font-family: 'GE Inspira';
  font-size: 14px;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: 0 2px 0;
  ${(props) => {
    if (props.secondary) return secondaryCss;
    return baseCss;
  }};
`;

export const NavTab = ({ secondary, to, exact, children, className }) => (
  <StyledNavTab
    secondary={secondary ? `${secondary}` : null}
    to={to}
    exact={exact}
    className={className}
    tabIndex="0"
  >
    {children}
  </StyledNavTab>
);

NavTab.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  secondary: PropTypes.bool,
  className: PropTypes.string,
};

NavTab.defaultProps = {
  secondary: undefined,
  exact: false,
  className: '',
};

export const NavButton = styled.button`
  font-family: 'GE Inspira';
  font-size: 14px;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: 0 2px 0;
  width: calc(100% - 4px);
  ${baseCss}
`;
