import { useStoreActions, useStoreState } from 'easy-peasy';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks/config';

export const useCaseSignalData = (alert) => {
  const getAlertSignalData = useStoreState(({ analyze }) => analyze.getAlertSignalData);
  const { fetchAlertSignalData } = useStoreActions((actions) => actions.analyze);

  const { id } = alert ?? {};

  const { isLoading, error } = useQuery(
    [QueryKey.CASE_SENSOR_DATA, { id }],
    () => fetchAlertSignalData(id),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(id),
      retry: false,
    },
  );

  const sensorData = getAlertSignalData(alert?.id);

  return { isLoading, data: sensorData, error };
};
