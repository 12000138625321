import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MiniLoader } from '@ge/components/loader';
import { DraggableTable, DynamicTable } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import {
  ManageDefs,
  Capability,
  PermissionScope,
  AlertsEntityType,
  AlertStatusCodeMapping,
  FormMode,
  AlertTypeValue,
  EntityTypeMapping,
} from '@ge/models/constants';
import { AlertDialog } from '@ge/shared/components/alerts/alert-dialog';
import { AlertTableIcons } from '@ge/shared/components/alerts/alert-table-icons';
import { useAuth } from '@ge/shared/data-hooks';
import { useFilterDefs } from '@ge/shared/hooks';
import { useAlertDialog } from '@ge/shared/hooks';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { elevations } from '@ge/tokens/elevations';

import {
  AlertHistoryColumns,
  AlertHistoryColumnDefs,
  defaultAlertHistoryCols,
} from '../../models/table-col-defs/alert-history-table-cols';

const StyledDesc = styled.span`
  margin-left: 6px;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;
    font-size: 11px;

    &:first-of-type {
      margin-right: 9px;
    }

    &:last-of-type {
      margin-left: 9px;
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const Load = styled.div`
  top: 400px;
  position: relative;
`;

const AlertHistoryTable = ({
  isLoading,
  tableType,
  history,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onEventSelect,
  draggable,
  scrollable,
  onDrop,
  onFilter,
  filterValues,
  onFilterChange,
}) => {
  const { t, ready } = useTranslation(['alerts'], {
    useSuspense: false,
  });
  const getSiteById = useStoreState((state) => state.sites.getSiteById);
  const getAssetById = useStoreState((state) => state.assets.getAssetById);
  const [siteDetails, setSiteDetails] = useState({});
  const [tableColumns, setTableColumns] = useState(columns);
  const { isAuthorized } = useAuth();
  const siteId = siteDetails?.id;
  const authorizedAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });

  const {
    groupDetails,
    currentAlert,
    showAlertDialog,
    handleEditAlert,
    handleCancelAlertDialog,
    handleViewAlert,
  } = useAlertDialog({ entity: siteDetails, entityType: AlertsEntityType.SITE }, authorizedAlerts);

  const sortedDirection = useCallback((metric) => (metric === sortMetric ? sortDirection : ''), [
    sortMetric,
    sortDirection,
  ]);
  const { filterDefs, onChange: onFilterApply } = useFilterDefs({
    columnDefs: AlertHistoryColumnDefs,
    stateId: ManageDefs.ALERT_HISTORY_FILTER_STATE_ID,
  });
  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilterApply(columnKey, value);
    },
    [onFilterApply],
  );

  const handleFilterChange = useCallback((_, key, value) => onFilterChange({ key, value }), [
    onFilterChange,
  ]);

  useEffect(() => {
    onFilter(filterDefs);
  }, [filterDefs, onFilter]);

  useEffect(() => {
    const filteredColumns = [];
    columns.map((c) => {
      const newCol = { id: c.id, cols: [] };
      const cols = c.cols.filter((f) => f.filter === undefined || f.filter === tableType);
      if (cols.length > 0) {
        newCol.cols = cols;
        filteredColumns.push(newCol);
      }
    });
    setTableColumns(filteredColumns);
  }, [columns, tableType, setTableColumns]);

  /**
   * Get all the translated weather icons
   */
  const getTranslatedValues = useCallback(
    (alert) => {
      let alerts = [];
      alerts.push({
        ...alert,
        id: alert._id,
        type: alert.type,
        validTo: alert?.validTo || alert?.typeProperties?.validTo,
        isClosed: alert.status === AlertStatusCodeMapping.CLOSED,
        transText: t(`dynamic.warning.${alert}`, 'Warning'),
        auditProperties: alert.auditProperties,
        isGroup: !!alert?.groupDetails?.groupId,
        contactInfo: alert?.typeProperties?.contactInfo,
        description: alert?.typeProperties?.description,
        estimatedEndDate: alert?.typeProperties?.estimatedEndDate,
        requestedBy: alert?.typeProperties?.requestedBy,
        requestedTime: alert?.typeProperties?.requestedTime,
        event: alert?.typeProperties?.event,
        newValue: alert?.typeProperties?.newValue,
        oldValue: alert?.typeProperties?.oldValue,
        parameterName: alert?.typeProperties?.parameterName,
        reason: alert?.typeProperties?.reason,
        enr: alert?.typeProperties?.enr,
        gps: alert?.typeProperties?.gps,
        nearestAirport: alert?.typeProperties?.nearestAirport,
      });
      return alerts;
    },
    [t],
  );

  const cellValueMapFn = useCallback(
    (event) => {
      if (!event) {
        return {};
      }
      // TODO: Handle open tasks?

      return {
        [AlertHistoryColumns.ALERT_TYPE]: AlertTypeValue[event?.type],
        [AlertHistoryColumns.DESCRIPTION]: event?.typeProperties?.description,
        [AlertHistoryColumns.OPEN_DATE]:
          event?.auditProperties?.createdDate &&
          getDateTimeBasedOnZone(
            event?.auditProperties?.createdDate,
            getSiteById(event?.siteId)?.timezone,
          ),
        [AlertHistoryColumns.CLOSED_DATE]:
          event?.auditProperties?.closedDate &&
          getDateTimeBasedOnZone(
            event?.auditProperties?.closedDate,
            getSiteById(event?.siteId)?.timezone,
          ),
        [AlertHistoryColumns.ALERT_ICON]: event ? event : [],
      };
    },
    [getSiteById],
  );

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case AlertHistoryColumns.ALERT_TYPE:
          return (
            <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case AlertHistoryColumns.DESCRIPTION:
          return (
            <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case AlertHistoryColumns.OPEN_DATE:
        case AlertHistoryColumns.CLOSED_DATE:
          return (
            <>
              <StyledTimeDate>
                <span>{cellValue?.time}</span>
                <span>{cellValue?.date}</span>
                <span>{cellValue?.tz}</span>
              </StyledTimeDate>
            </>
          );
        case AlertHistoryColumns.ALERT_ICON:
          return (
            <AlertTableIcons
              alerts={getTranslatedValues(cellValue)}
              onClick={(_, alert) => {
                const site = getSiteById(alert.siteId);
                const assetDetails = getAssetById(alert.assetId);
                setSiteDetails({ ...assetDetails, site: site });
                handleViewAlert(alert);
              }}
            />
          );
        default:
          return null;
      }
    },
    [getAssetById, getSiteById, getTranslatedValues, handleViewAlert],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: AlertHistoryColumnDefs,
    cellValueMapFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
    filterValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
  });
  if (!columns || columns.length === 0) {
    return null;
  }
  const dynamicProps = {
    scrollable,
    compressed: true,
    transparent: true,
    noTitles: true,
    columns: tableColumns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: history,
    onValueSelect: onEventSelect,
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
  };

  if (!ready || isLoading) {
    return (
      <>
        <Load>
          <MiniLoader />
        </Load>
      </>
    );
  }

  const tableEl = React.createElement(draggable ? DraggableTable : DynamicTable, dynamicProps);

  return (
    <>
      {tableEl}
      {showAlertDialog && (
        <AlertDialog
          alert={currentAlert}
          groupDetails={groupDetails}
          entity={siteDetails}
          entityType={EntityTypeMapping[siteDetails?.type]}
          isOpen
          mode={FormMode.VIEW}
          onCancel={handleCancelAlertDialog}
          onConfirm={handleEditAlert}
        />
      )}
    </>
  );
};

AlertHistoryTable.propTypes = {
  isLoading: PropTypes.bool,
  tableType: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  history: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onEventSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  filterValues: PropTypes.object,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
};

AlertHistoryTable.defaultProps = {
  columns: defaultAlertHistoryCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onEventSelect: () => null,
  onDrop: () => null,
  scrollable: false,
  draggable: false,
  filterValues: {},
  onFilter: () => null,
  onFilterChange: () => null,
};

export default AlertHistoryTable;
