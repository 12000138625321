import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { ConditionalWrapper } from '@ge/components/conditional-wrapper';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { Radio } from '@ge/components/radio/radio';
import { Table, Tbody, Td, Th, Thead, Tr } from '@ge/components/table';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { TooltipCell } from '@ge/components/table/table';
import { useColumnState } from '@ge/components/table/use-column-state';
import {
  PanelColumnWidths,
  AlarmType,
  NotificationActionType,
  NotificationType,
  DataLoaderType,
} from '@ge/models/constants';
import { useAssetRealTimeData, useEditCases } from '@ge/shared/data-hooks';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const EntityInfo = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityDescription};
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
`;

const EntityDescription = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityDescription};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 4px;
  svg {
    margin-right: 5px;
  }
`;

const EntityDescriptionIcon = styled(Icon).attrs((props) => ({
  size: 16,
  color: props.theme.entityDetails.notes.entityDescription,
}))`
  margin-right: 5px;
`;

const DialogSegment = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  padding: 20px 25px 10px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
    padding-top: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  z-index: ${elevations.P1};
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const StyledLabel = styled.div`
  display: block;
  font-family: Museo Sans;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  margin-bottom: 5px;
  margin-top: 15px;
`;

const ScrollingTableContainer = styled.div`
  border: 2px solid transparent;
  &.validation-error {
    border-color: ${(props) => props.theme.dangerColor};
  }
  > div,
  table {
    min-height: 240px;
  }
  .customscroll-track {
    background-color: transparent;
  }
  .customscroll-track.simplebar-vertical {
    top: 30px;
  }
`;
const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const RadioWrapper = styled.div`
  > div {
    margin: 0;
    label {
      padding-left: 15px;
    }
  }
`;

const StyledTr = styled(Tr)`
  &.disabled {
    td,
    label {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

const NotificationMessage = styled.div`
  font: ${typography.weight.regular} 10px/15px ${typography.family.default};
`;

const TableScrollRowCount = 8;

const EmptyAlarms = styled.td.attrs(() => ({ colSpan: '4' }))`
  padding: 50px;
  margin: 0px 30px;
`;

// ChangeCausalAlarmDialog component
export const ChangeCausalAlarmDialog = ({ onClose, entity }) => {
  const { t, ready } = useTranslation(['monitor.assets'], { useSuspense: false });

  // State
  const [checkedRadio, setCheckedRadio] = useState(null);
  const [isFormValidationError, setIsFormValidationError] = useState(false);

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
  });

  const { handleSubmit, register } = methods;

  // Table state management
  const {
    sortDirection,
    sortMetric,
    updateSortMetric: sortAction,
  } = useColumnState({
    defaultSortMetric: 'start',
    defaultSortDirection: SortDirection.DESC,
  });

  // Get asset realtime data
  const { data: asset, isLoading } = useAssetRealTimeData({
    assetId: entity.assetId,
    sortDirection,
    sortMetric,
  });

  // Data hook call to edit monitor case
  const { editCase, data, isError } = useEditCases();

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const onSubmit = useCallback(
    (values) => {
      if (values.selectedAlarm) {
        const selectedAlarm = asset?.alarms.find((alarm) => alarm.code === values.selectedAlarm);
        editCase({
          responsibleAlarm: selectedAlarm?.code,
          alarmTime: dayjs(selectedAlarm?.start).valueOf(),
          id: entity?.id,
        });
        setIsFormValidationError(false);
      } else {
        setIsFormValidationError(true);
      }
    },
    [editCase, entity, asset],
  );

  const { notify } = useNotification();

  const createNotification = useCallback(() => {
    const option = { actions: [] };
    if (asset?.site) {
      option.actions.push({
        label: asset.site?.name,
        type: NotificationActionType.SITE_DETAILS,
      });
    }
    if (asset) {
      option.actions.push({
        label: asset?.name,
        type: NotificationActionType.TURBINE_DETAILS,
      });
    }
    option.actions.push({
      label: entity?.title,
      type: NotificationActionType.EVENT_DETAILS,
    });
    option.message = (
      <NotificationMessage>
        {t('changeCausalAlarm.failed_to_change_alarm', 'Failed to change the causal alarm.')}
      </NotificationMessage>
    );
    option.type = NotificationType.ERROR;
    notify(option);
    onClose();
  }, [entity, asset, notify, onClose, t]);

  useEffect(() => {
    data && onClose();
    if (isError) {
      createNotification();
    }
  }, [isError, data, onClose, createNotification]);

  const tableShouldScroll = useMemo(
    () => Array.isArray(asset?.alarms) && asset.alarms.length >= TableScrollRowCount,
    [asset],
  );

  const getFooter = useMemo(() => {
    return (
      <FooterWrapper>
        <FooterButtons>
          <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleSubmit(onSubmit)}>
            {t('general:save', 'Save')}
          </Button>
        </FooterButtons>
      </FooterWrapper>
    );
  }, [onClose, t, handleSubmit, onSubmit]);

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      header={t('changeCausalAlarm.change_causal_alarm', 'CHANGE CAUSAL ALARM')}
      footer={getFooter}
      padContent={false}
    >
      <DialogSegment>
        <EntityInfo>
          {t('changeCausalAlarm.current_causal_alarm', 'Current causal alarm for this case:')}
        </EntityInfo>
        <EntityDescription>
          <EntityDescriptionIcon icon={Icons.EVENT} />
          {entity?.title}
        </EntityDescription>
      </DialogSegment>
      <DialogSegment>
        <FormProvider {...methods}>
          <form>
            <DataLoader isLoading={isLoading} type={DataLoaderType.TABLE} renderCondition>
              <StyledLabel>
                {t('changeCausalAlarm.active_alarms_and_events', 'Active Alarms and Events')}
              </StyledLabel>
              <ConditionalWrapper
                condition={tableShouldScroll}
                wrapper={(children) => (
                  <ScrollingTableContainer
                    className={isFormValidationError ? 'validation-error' : ''}
                  >
                    {children}
                  </ScrollingTableContainer>
                )}
              >
                <Table
                  backgroundColor={globalColors.slate2}
                  isLoading={isLoading}
                  scrollable={tableShouldScroll}
                >
                  <Thead>
                    <tr>
                      <Th align="center" width={PanelColumnWidths.XSMALL} noPadding />
                      <Th
                        labels={{
                          [t('changeCausalAlarm.code', 'Code')]: 'code',
                        }}
                        align="left"
                        sortedDirection={sortedDirection}
                        onClick={sortAction}
                        width={PanelColumnWidths.XSMALL}
                      />
                      <Th
                        labels={{
                          [t('changeCausalAlarm.description', 'Description')]: 'description',
                        }}
                        align="left"
                        sortedDirection={sortedDirection}
                        onClick={sortAction}
                        width={PanelColumnWidths.LARGE}
                        noPadding
                      />
                      <Th
                        labels={{
                          [`${t('changeCausalAlarm.start_time', 'Start')} (${dayjs
                            .tz(new Date(), asset?.site?.timezone)
                            .format('z')})`]: 'start',
                        }}
                        align="left"
                        sortedDirection={sortedDirection}
                        onClick={sortAction}
                        width={PanelColumnWidths.SMALL}
                      />
                    </tr>
                  </Thead>
                  <Tbody>
                    {Array.isArray(asset?.alarms) ? (
                      asset.alarms
                        .filter((alarm) => alarm.category === AlarmType.NON_DIAG)
                        .map(({ code, description, start }) => {
                          const isDisabled = code === entity?.title?.split(' ')[0];
                          const alarmDateTime = getDateTimeBasedOnZone(
                            start,
                            asset?.site?.timezone,
                          );

                          return (
                            <StyledTr key={code} className={isDisabled ? 'disabled' : ''}>
                              <Td width={PanelColumnWidths.XSMALL} noPadding>
                                <RadioWrapper>
                                  <Radio
                                    label=""
                                    name="selectedAlarm"
                                    id={code}
                                    value={code}
                                    ref={register}
                                    checked={code === checkedRadio}
                                    onChange={({ target }) => {
                                      setCheckedRadio(target?.value);
                                      setIsFormValidationError(false);
                                    }}
                                    disabled={isDisabled}
                                  />
                                </RadioWrapper>
                              </Td>
                              <Td align="left" width={PanelColumnWidths.XSMALL}>
                                {code}
                              </Td>
                              <Td align="left" width={PanelColumnWidths.LARGE} noPadding>
                                <TooltipCell tooltip={description} zIndex={elevations.P100}>
                                  {description}
                                </TooltipCell>
                              </Td>
                              <Td align="left" width={PanelColumnWidths.SMALL}>
                                <StyledTimeDate>
                                  <span>{alarmDateTime.time}</span>
                                  <span>{alarmDateTime.date}</span>
                                </StyledTimeDate>
                              </Td>
                            </StyledTr>
                          );
                        })
                    ) : (
                      <tr>
                        <EmptyAlarms>
                          {t('changeCausalAlarm.no_active_alarms', 'No Active Alarms.')}
                        </EmptyAlarms>
                      </tr>
                    )}
                  </Tbody>
                </Table>
              </ConditionalWrapper>
            </DataLoader>
          </form>
        </FormProvider>
      </DialogSegment>
    </Dialog>
  );
};

ChangeCausalAlarmDialog.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func,
};

ChangeCausalAlarmDialog.defaultValues = {
  onClose: () => null,
};
