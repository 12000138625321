import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ChartType, ParetoChart, ChartBorder } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { Select } from '@ge/components/select';
import { EventLevelDataTypes, iecCategorySelect } from '@ge/feat-analyze/models';
import { handleKpiError } from '@ge/feat-analyze/util/kpi';
import { Capability, PermissionScope, EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useAuth } from '@ge/shared/data-hooks';
import { getDefaultTranslation } from '@ge/shared/util';
import { roundNumber } from '@ge/util';

import { useOemAssetData } from '../../data-hooks';

import { DashboardChartTitle } from './dashboard-chart-title';
import { DashboardIECDataToggle } from './dashboard-iec-data-toggle';
import { DashboardIecLossCategoriesAssetSelector } from './dashboard-iec-loss-categories-asset-selector';
import { DashboardIecLossCategoriesTooltip } from './dashboard-iec-loss-categories-tooltip';
import { IecLossDonutChart } from './dashboard-iec-loss-donut';
import { DashboardTitle } from './dashboard-shared';

const chartTypes = [
  { id: ChartType.PARETO, type: ChartType.PARETO },
  { id: ChartType.DONUT, type: ChartType.DONUT },
];

const defaultCategory = iecCategorySelect[1];
const offset = 28;

const removeValueZero = (data) => data.value !== 0;

const sortHightoLow = (a, b) => b.value - a.value;

const getTooltipData = ({ activeId, data = [], expandedType }) => {
  let _data;

  if (!expandedType || expandedType === activeId) {
    _data = data;
  } else {
    const { details = [] } = data.find(({ type }) => type === expandedType) || {};
    _data = details;
  }

  const tooltipData = _data.find(({ type, id }) => type === activeId || id === activeId);

  if (!tooltipData) {
    return null;
  }

  const { metadata: { assets, totalTurbines } = {}, title, iecCategory, kpiValue } = tooltipData;

  return {
    data: assets,
    title,
    totalTurbines,
    iecCategory,
    kpiValue,
  };
};

const getOemTitle = ({ activeId, data, activeIecCategoryInfo, expandedIecCategory }) => {
  if (!expandedIecCategory) {
    return activeIecCategoryInfo?.title;
  }

  return data
    .find((iecItem) => iecItem.id === expandedIecCategory)
    ?.details.find((detail) => detail.id === activeId)?.title;
};

const Container = styled(ChartBorder)``;

const StyledSelect = styled(Select)`
  margin-left: 2px;
  optgroup {
    text-transform: uppercase;
    color: black;
  }
`;

const DonutContainer = styled.div`
  height: ${(props) => props.height}px;
`;

const DataToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const zeroData = {
  data: [],
  total: 0,
  unit: 'hours',
};

// eslint-disable-next-line react/display-name
export const DashboardIECLossCategories = React.forwardRef(
  (
    {
      data: _data,
      error,
      isLoading,
      height: _height,
      entityType,
      namespace,
      onCategoryChange,
      onSeeAll,
      isTurbineDataLoading,
      entityId,
      iecCategory,
      hideBorder,
      showHover,
      showTitle,
      isDataPresent,
      showZero,
      totalReportingAssets,
    },
    ref,
  ) => {
    // hooks
    const { t, ready } = useTranslation([namespace], { useSuspense: false });
    const { push } = useHistory();

    const [currentType, setCurrentType] = useState(ChartType.PARETO);
    const [currentCategory, setCurrentCategory] = useState({
      group: defaultCategory.grouping,
      value: defaultCategory.categories[3],
      title: t(`dynamic.ilfs.${defaultCategory.grouping}`),
    });

    const [seeAll, setSeeAll] = useState(false);
    const [expandedIecCategory, setExpandedIecCategory] = useState();
    const [iecCategoryId, setIecCategoryId] = useState();
    const [assetData, setAssetData] = useState();
    const [showAssetSelector, setShowAssetSelector] = useState(false);
    const [modalDataType, setModalDataType] = useState();
    const [showTooltip, setShowTooltip] = useState(false);
    const [activeId, setActiveId] = useState();
    const [dataType, setDataType] = useState(EventLevelDataTypes.IEC);
    const [chartData, setChartData] = useState([]);
    const barElementRef = useRef();
    const { isAuthorized } = useAuth();

    const assets = useStoreState((state) => state.assets.assets);
    const selected = useStoreState(({ analyze }) => analyze.trendGraphSelection);

    const height = _height - offset;

    const { data = [], total, unit, siteIds } = showZero ? zeroData : _data || {};
    const { oem, iec } = data ?? {};

    const capability = Capability.CORE_KPIS;

    const oemAuthorized = isAuthorized({
      capabilities: [{ capability, scopes: [PermissionScope.VIEW] }],
      siteIds: siteIds ? siteIds : [entityId],
    });

    const { data: oemAssetData } = useOemAssetData({
      entityId,
      category: iecCategory,
      entityType,
      pageIndex: 0,
      pageSize: seeAll ? 100 : 5,
      enabled: isDataPresent,
    });

    const flatOemAssetData = useMemo(() => {
      return (
        oemAssetData &&
        Object.values(oemAssetData).reduce((newObject, category) => {
          return { ...newObject, ...category };
        }, {})
      );
    }, [oemAssetData]);

    const selectedIecOemData = useMemo(
      () => oemAssetData?.[iecCategoryId],
      [oemAssetData, iecCategoryId],
    );

    const translatedIecData = useMemo(
      () =>
        iec
          ?.filter(removeValueZero)
          .sort(sortHightoLow)
          .map((category) => ({
            ...category,
            title: t(`dynamic.tilc.${category.type}`, getDefaultTranslation(category.type)),
            id: isNaN(category.type)
              ? category.type.trim().toLocaleLowerCase().replace(/\s+/g, '-')
              : category.type,
            details: category.details
              .filter(removeValueZero)
              .sort(sortHightoLow)
              .map((childCategory) => ({
                ...childCategory,
                title: `${childCategory.type} - ${childCategory.text}`,
              })),
            iecCategory: category.iecCategory,
          })) ?? [],
      [iec, t],
    );

    const translatedOemData = useMemo(
      () =>
        oem
          ?.filter(removeValueZero)
          .sort(sortHightoLow)
          .map((category) => ({
            ...category,
            title: `${category.type} - ${category.text}`,
          })) ?? [],
      [oem],
    );

    // iec category hash map
    const iecIdMap = useMemo(
      () =>
        translatedIecData?.reduce((acc, iecItem) => {
          acc[iecItem?.id] = iecItem?.iecCategory;
          return acc;
        }, {}),
      [translatedIecData],
    );

    //translations
    const { headers, percentSymbol, seeAllButton, seeTop100AssetsButton } = useMemo(
      () => ({
        headers: {
          assetHeader: t('tilc.tooltip.headers.asset', 'Asset'),
          siteHeader: t('tilc.tooltip.headers.site', 'Site'),
          percentHeader: t('tilc.tooltip.headers.percent', '%'),
          valueHeader: `${t('tilc.tooltip.headers.value', 'Value')} (${unit})`,
        },
        percentSymbol: t('tilc.tooltip.percent_symbol', '%'),
        seeAllButton: t('tilc.tooltip.see_all_button', 'See All'),
        seeTop100AssetsButton: t('tilc.tooltip.see_top_100_assets_button', 'See top 100 assets'),
      }),
      [t, unit],
    );

    const assetTableData = useMemo(() => {
      const iecCategoryData =
        data?.iec?.find(({ iecCategory }) => iecCategory === assetData?.iecCategory) || {};
      return (
        iecCategoryData?.metadata?.assets?.map((asset) => ({
          ...asset,
          assetName: assets[asset.assetId]?.name,
        })) ?? []
      );
    }, [assetData?.iecCategory, data, assets]);

    const transformTooltipData = useMemo(
      () =>
        ({ data, sum, iecCategory }) => {
          const transformedData = data?.data?.map((asset) => {
            const pctValue = asset?.kpiValue / (sum === undefined ? total : sum);
            // Need to move this to API. % for Event Rates KPI includes Turbine count in the denominator
            const pct = roundNumber(
              iecCategory === KpiCategoryDefs.EVENT_RATES
                ? (asset?.kpiValue / (sum * totalReportingAssets)) * 100
                : pctValue * 100,
              2,
            );
            return {
              ...asset,
              assetName: assets[asset.assetId]?.name,
              pct,
            };
          });

          return {
            ...data,
            data: transformedData,
          };
        },
      [total, assets, totalReportingAssets],
    );

    useEffect(() => {
      if (dataType === EventLevelDataTypes.OEM) {
        setChartData(translatedOemData);
      } else {
        setChartData(translatedIecData);
      }
    }, [setChartData, dataType, translatedOemData, translatedIecData]);

    useEffect(() => {
      if (dataType === EventLevelDataTypes.OEM) {
        const activeOemData = flatOemAssetData?.[activeId];
        const activeIecCategoryInfo = translatedOemData.find((oemItem) => oemItem.id === activeId);

        const oemTooltipData = {
          title: getOemTitle({ activeId, activeIecCategoryInfo, data: translatedOemData }),
          data: activeOemData?.assets,
          totalTurbines: activeOemData?.totalTurbines,
          iecCategory,
        };

        const activeData = transformTooltipData({
          data: oemTooltipData,
          sum: activeIecCategoryInfo?.kpiValue,
          iecCategory,
        });

        setAssetData(activeData);
        return;
      }

      if (modalDataType === EventLevelDataTypes.OEM) {
        const activeOemData = selectedIecOemData?.[activeId];
        const activeIecCategoryInfo = translatedOemData.find((oemItem) => oemItem.id === activeId);

        const oemTooltipData = {
          title: getOemTitle({
            activeId,
            activeIecCategoryInfo,
            data: translatedIecData,
            expandedIecCategory,
          }),
          data: activeOemData?.assets,
          totalTurbines: activeOemData?.totalTurbines,
          iecCategory,
        };

        const activeData = transformTooltipData({
          data: oemTooltipData,
          sum: activeIecCategoryInfo?.kpiValue,
          iecCategory,
        });

        setAssetData(activeData);

        return;
      }

      const iecTooltipData = getTooltipData({
        activeId,
        data: translatedIecData,
        expandedType: expandedIecCategory,
      });

      if (iecTooltipData?.data) {
        const activeData = transformTooltipData({
          data: iecTooltipData,
          sum: iecTooltipData.kpiValue,
          iecCategory,
        });

        setAssetData(activeData);
      }
    }, [
      activeId,
      expandedIecCategory,
      iecCategory,
      modalDataType,
      selectedIecOemData,
      transformTooltipData,
      translatedIecData,
      dataType,
      flatOemAssetData,
      translatedOemData,
    ]);

    const getModalData = useCallback(() => {
      switch (modalDataType) {
        case EventLevelDataTypes.OEM:
          return assetData?.data;
        case EventLevelDataTypes.IEC:
          return assetTableData;
        default:
          break;
      }
    }, [modalDataType, assetTableData, assetData]);

    // handlers
    const handleChartToggle = useCallback(
      ({ type }) => {
        setCurrentType(type);
      },
      [setCurrentType],
    );

    const handleCategoryChange = useCallback(
      (option) => {
        setCurrentCategory({ value: option.value, group: option.group, title: option.group });
        onCategoryChange(option.value);
      },
      [setCurrentCategory, onCategoryChange],
    );

    const handleExpand = (category) => {
      setIecCategoryId(iecIdMap[category]);
      setExpandedIecCategory(category);
    };

    const handleHover = useCallback(
      ({ active, element, id: activeId, isChild: isOem }) => {
        if (!showHover || showZero) {
          return;
        }

        if (active) {
          barElementRef.current = element;

          const modalDataType = isOem ? EventLevelDataTypes.OEM : EventLevelDataTypes.IEC;
          const enableToolTip = !isOem || oemAuthorized;

          setShowTooltip(enableToolTip);
          setModalDataType(modalDataType);
          setActiveId(activeId);

          return;
        }

        setShowTooltip(false);
      },
      [showHover, showZero, oemAuthorized],
    );

    const handleSeeAll = useCallback(() => {
      setShowTooltip(false);
      onSeeAll(assetData);
      setShowAssetSelector(true);
    }, [setShowTooltip, onSeeAll, setShowAssetSelector, assetData]);

    const handleSelectAsset = (id) => {
      setAssetData(null);

      // TODO: define these routes in a more central place and access via enum or config
      push(`/analyze/asset-overview?assets=${id}`);
    };

    const handleAssetSelectorClose = () => setShowAssetSelector(false);

    const handleDataToggleChange = useCallback(
      (val) => {
        setIecCategoryId();
        setExpandedIecCategory();
        setDataType(val);
        setModalDataType(val);
      },
      [setIecCategoryId, setExpandedIecCategory, setDataType],
    );

    const tooltip = showTooltip && assetData && (
      <AuthRender
        capability={capability}
        view
        description="IEC Loss Categories Tooltip"
        siteIds={siteIds ? siteIds : [entityId]}
      >
        <DashboardIecLossCategoriesTooltip
          anchorEl={barElementRef.current}
          data={assetData.data}
          entityType={entityType}
          headers={headers}
          onSeeAll={handleSeeAll}
          onSelectAsset={handleSelectAsset}
          open={true}
          percentSymbol={percentSymbol}
          seeAllButton={
            entityType === EntityType.REGION || entityType === EntityType.FLEET
              ? seeTop100AssetsButton
              : seeAllButton
          }
          title={assetData.title}
          count={assetData.totalTurbines}
          setSeeAll={setSeeAll}
        />
      </AuthRender>
    );

    const selectOptions = useMemo(() => {
      const options = [];

      iecCategorySelect.map(({ grouping, categories }) => {
        const categoriesArr = [];
        categories.map((category) =>
          categoriesArr.push({
            value: category,
            label: t(`dynamic.ilfs.${category}`),
            group: grouping,
          }),
        );

        return options.push({
          label: t(`dynamic.ilfs.${grouping}`),
          group: grouping,
          options: categoriesArr,
        });
      });

      return options;
    }, [t]);

    const selectedValue = useMemo(() => {
      const groupOption = selectOptions.filter((group) => group.group === currentCategory.group);
      return groupOption[0].options.filter((option) => option.value === currentCategory.value);
    }, [selectOptions, currentCategory]);

    // error handler
    const { noData, noDataTitle, noDataDescription } = useMemo(() => {
      if (!showZero && (!data || total === 0)) {
        return { noData: true };
      }

      return handleKpiError({ error, t });
    }, [data, error, showZero, t, total]);

    if (!ready) {
      return null;
    }

    return (
      <Container hideBorder={hideBorder} ref={ref}>
        {showTitle && (
          <>
            <DashboardTitle data-testid={AnalyzeLocators.ANALYZE_IEC_GRAPH_TITLE}>
              {t('major_loss.title', 'Pareto Analysis')}
            </DashboardTitle>
            <DashboardChartTitle
              chartTypes={chartTypes}
              displayLegend={false}
              onToggle={handleChartToggle}
              metadata={currentType === ChartType.PARETO && { total, unit }}
              currentChart={currentType}
            >
              <StyledSelect
                small
                minWidth={300}
                value={selectedValue}
                onChange={handleCategoryChange}
                options={selectOptions}
              />
            </DashboardChartTitle>
          </>
        )}
        <DataLoader
          isLoading={isLoading}
          type={currentType}
          noData={noData}
          noDataTitle={noDataTitle}
          noDataDescription={noDataDescription}
          renderCondition={!error}
          onRetry={null}
          height={`${_height}px`}
        >
          <DataToggleContainer>
            <DashboardIECDataToggle
              capability={capability}
              namespace={namespace}
              toggleTypes={
                oemAuthorized
                  ? [EventLevelDataTypes.IEC, EventLevelDataTypes.OEM]
                  : [EventLevelDataTypes.IEC]
              }
              defaultType={dataType}
              onChange={handleDataToggleChange}
            />
          </DataToggleContainer>
          {currentType === ChartType.DONUT && (
            <DonutContainer height={height}>
              <IecLossDonutChart
                showZero={showZero}
                data={chartData}
                value={total}
                unit={unit}
                selected={selected}
              />
            </DonutContainer>
          )}
          {currentType === ChartType.PARETO && (
            <ParetoChart
              barChildren={tooltip}
              selected={selected}
              data={chartData}
              isLoading={isLoading}
              total={total}
              height={height}
              lv5Hover={oemAuthorized}
              onHover={handleHover}
              onExpand={handleExpand}
              isOem={dataType === EventLevelDataTypes.OEM}
            />
          )}
        </DataLoader>
        {showAssetSelector && (
          <DashboardIecLossCategoriesAssetSelector
            data={getModalData()}
            entityType={entityType}
            headers={headers}
            onClose={handleAssetSelectorClose}
            onSelectAsset={handleSelectAsset}
            percentSymbol={percentSymbol}
            title={assetData.title}
            isLoading={isTurbineDataLoading}
            count={assetData.totalTurbines}
          />
        )}
      </Container>
    );
  },
);

DashboardIECLossCategories.propTypes = {
  data: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  height: PropTypes.number,
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  namespace: PropTypes.string,
  onCategoryChange: PropTypes.func,
  onSeeAll: PropTypes.func,
  isTurbineDataLoading: PropTypes.bool,
  entityId: PropTypes.string,
  iecCategory: PropTypes.string,
  hideBorder: PropTypes.bool,
  showHover: PropTypes.bool,
  showTitle: PropTypes.bool,
  isDataPresent: PropTypes.bool,
  showZero: PropTypes.bool,
  totalReportingAssets: PropTypes.number,
};

DashboardIECLossCategories.defaultProps = {
  data: {},
  isLoading: false,
  // this ensures that x axis lines up with the x axis on adjacent highcharts graphs
  height: 305,
  entityType: EntityType.SITE,
  onCategoryChange: () => {},
  onSeeAll: () => {},
  isTurbineDataLoading: false,
  hideBorder: false,
  showHover: true,
  showTitle: true,
};
