/* eslint-disable camelcase */
import merge from 'deepmerge';
import { action, computed, thunk, actionOn } from 'easy-peasy';
import memoize from 'memoizerific';

import { getDataFilter, getDataFilterUp } from '@ge/shared/util/data-filter';
import { sorter } from '@ge/util/metric-sorter';

import { getAllReportCompletedJobs } from '../services/defects';
import { calculateStageStatus } from '../util';

// Define initial state
const defaultDefectsState = {
  defects: {},
  stageStatus: null,
  siteNamesWithReportCompleted: {},
};

const updateDefectProperties = (state, defect, storeState) => {
  const { turbineTypes } = storeState.inspectionsFeature;
  const siteNames = storeState.sites.sites;
  const { asset_site_id, stage_status, turbinetype_lid, workflow_stage } = defect.attributes;

  const siteName = siteNames[asset_site_id].name || '';
  const turbineType = turbineTypes[turbinetype_lid] || '';

  const newDefect = {
    ...defect.attributes,
    id: defect.id,
    turbineType,
    siteName,
    scan_date_timeStamp: new Date(defect.attributes.scan_date).getTime(),
    stages: calculateStageStatus(workflow_stage, stage_status),
  };
  const existingDefect = state.defects[defect.id];
  state.defects[defect.id] = !existingDefect ? newDefect : merge(existingDefect, newDefect, {});
};

// Actions
const defectsActions = {
  resetDefect: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, defaultDefectsState);
  }),
  setSiteNames: action((state, payload) => {
    state.siteNames = payload;
  }),
  setTurbineTypes: action((state, payload) => {
    state.turbineTypes = payload;
  }),

  fetchDefectsBySiteId: thunk(async (actions, payload, { getStoreState }) => {
    const options = {};
    if (payload !== undefined || payload !== null) {
      const { globalFilters, endDate, startDate } = payload;
      const filters = globalFilters;
      const { result } = await getAllReportCompletedJobs({ filters, endDate, startDate }, options);
      actions.resetDefect({ storeState: getStoreState() });
      actions.updateDefects({ data: result.data, storeState: getStoreState() });
      return result.data;
    }
  }),

  setSiteNamesWithReportCompleted: action((state, payload) => {
    state.siteNamesWithReportCompleted = payload.reduce((s, c, i) => {
      return { ...s, [i]: c.attributes.sitename };
    }, {});
  }),

  updateDefects: action((state, payload) => {
    const { data, storeState } = payload;

    data.forEach((defect) => {
      updateDefectProperties(state, defect, storeState);
    });
  }),
  updateDefectProperties: action((state, payload) => {
    const { defect, storeState } = payload;
    updateDefectProperties(state, defect, storeState);
  }),

  getJobsWithReportsCompleted: computed(
    [(state) => state, (state, storeState) => storeState.view.currentView],
    (state) =>
      memoize(2)((sortMetric, sortDirection, filters) => {
        var data = state.getDefectsAsList();
        const dataFilter = getDataFilter({ data, filters });
        const { filterValues } = getDataFilterUp({ data, filters });

        return {
          ...dataFilter,
          filterValues,
          data: dataFilter.data?.sort(sorter(sortMetric, sortDirection)) ?? [],
        };
      }),
  ),
};

// Listeners
const defectsListeners = {
  onSiteNamesFetched: actionOn(
    (_, storeActions) => storeActions.inspectionsFeature.setSiteNames,
    (state, target) => {
      const ids = Object.keys(state.defects);
      const siteNames = target.payload.reduce((s, c) => {
        const { id } = c;
        return { ...s, [id]: c.attributes.sitename };
      }, {});
      for (let i = 0, l = ids.length - 1; i <= l; i += 1) {
        const { site_lid } = state.defects[ids[i]];
        state.defects[ids[i]].siteName = siteNames[site_lid];
      }
    },
  ),
  onTurbineTypesFetched: actionOn(
    (_, storeActions) => storeActions.inspectionsFeature.setTurbineTypes,
    (state, target) => {
      const ids = Object.keys(state.defects);
      const turbineTypes = target.payload.reduce((s, c) => {
        const { id } = c;
        return { ...s, [id]: c.attributes.turbinetype };
      }, {});
      for (let i = 0, l = ids.length - 1; i <= l; i += 1) {
        const { turbinetype_lid } = state.defects[ids[i]];
        state.defects[ids[i]].turbineType = turbineTypes[turbinetype_lid];
      }
    },
  ),
};

// Computed values
const defectsComputed = {
  getDefectsAsList: computed((state) => () => Object.values(state.defects)),
  getDefectsById: computed((state) => (id) => state.defects[id]),
  getSiteNamesWithReportAsList: computed(
    (state) => () => Object.values(state.siteNamesWithReportCompleted),
  ),

  getDefectsByFilters: computed((state) => (filters) => {
    const defects = Object.values(state.defects);
    return defects.filter((defect) =>
      filters.every((filter) => defect[filter.col].indexOf(filter.val) !== -1),
    );
  }),
};

const defectsModel = {
  ...defaultDefectsState,
  ...defectsActions,
  ...defectsListeners,
  ...defectsComputed,
};

export default defectsModel;
