import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Radio } from '@ge/components/radio';
import { TableWidgetStateKeys } from '@ge/feat-reporting/components//widgets/table-widget/table-widget';
import {
  assetsOfConcernColumnDefs,
  assetsOfConcernDefaultCols,
  assetsOfConcernOmittedCols,
} from '@ge/feat-reporting/components/widgets/assets-of-concern-widget/assets-of-concern-cols';
import {
  closedCasesColumnDefs,
  closedCasesCustomColConfigs,
  closedCasesDefaultCols,
  closedCasesOmittedCols,
} from '@ge/feat-reporting/components/widgets/closed-cases-widget/closed-cases-cols';
import {
  completedWorkColumnDefs,
  completedWorkCustomColConfigs,
  completedWorkDefaultCols,
  completedWorkOmittedCols,
} from '@ge/feat-reporting/components/widgets/completed-work-widget/completed-work-cols';
import {
  contractualAvailabilityColumnDefs,
  contractualAvailabilityDefaultCols,
  contractualAvailabilityOmittedCols,
} from '@ge/feat-reporting/components/widgets/contractual-availability-widget/contractual-availability-cols';
import {
  manuallyAdjustedEventsColumnDefs,
  manuallyAdjustedEventsDefaultCols,
  manuallyAdjustedEventsOmittedCols,
} from '@ge/feat-reporting/components/widgets/manually-adjusted-events-widget/manually-adjusted-events-cols';
import {
  openCasesColumnDefs,
  openCasesCustomColConfigs,
  openCasesDefaultCols,
  openCasesOmittedCols,
} from '@ge/feat-reporting/components/widgets/open-cases-widget/open-cases-cols';
import {
  plannedWorkColumnDefs,
  plannedWorkCustomColConfigs,
  plannedWorkDefaultCols,
  plannedWorkOmittedCols,
} from '@ge/feat-reporting/components/widgets/planned-work-widget/planned-work-cols';
import {
  productionTableColumnDefs,
  productionTableDefaultCols,
  productionTableOmittedCols,
} from '@ge/feat-reporting/components/widgets/production-table-widget/production-table-cols';
import {
  siteRosterColumnDefs,
  siteRosterDefaultCols,
  siteRosterOmittedCols,
} from '@ge/feat-reporting/components/widgets/site-roster-widget/site-roster-cols';
import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';
import { useLogger } from '@ge/shared/hooks';

import { NotesCustomRenderer } from './notes-custom-renderer';

const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.createReport.sidebar.moduleBorderColor};
  padding-bottom: 5px;
  padding-top: 10px;
`;

const ColumnsHeader = styled.span`
  text-transform: uppercase;
  font-weight: 700;
`;

const ColumnsSubHeader = styled.span`
  display: inline-block;
  font-style: italic;
  color: gray;
  text-transform: lowercase;
`;

const CheckboxContainer = styled.ul`
  display: flex;
  flex-flow: row wrap;
  padding: 5px 0 5px 14px;
  margin: 0;
`;

const CheckboxWrapper = styled.li`
  flex: 1 0 calc(50% - 10px);
  list-style: none;
  margin: 5px 0;
  padding: 0;

  & .module-config-radio {
    margin-bottom: auto;
    margin-top: auto;
  }

  & input[disabled] + label {
    filter: brightness(0.5);
  }
`;

// Map widgets to column definitions
const getMaxColumns = (widgetName) => {
  const DefaultMaxColumns = 8;

  const widgetMaxColMap = {
    [WidgetNames.CONTRACTUAL_AVAILABILITY]: null,
    [WidgetNames.PRODUCTION_TABLE]: null,
  };

  if (Object.prototype.hasOwnProperty.call(widgetMaxColMap, widgetName)) {
    return widgetMaxColMap[widgetName];
  }

  return DefaultMaxColumns;
};

// Map widgets to column definitions
const getColumnDefs = (widgetName) => {
  const widgetColMap = {
    [WidgetNames.PLANNED_WORK]: [
      plannedWorkColumnDefs,
      plannedWorkOmittedCols,
      plannedWorkCustomColConfigs,
    ],
    [WidgetNames.COMPLETED_WORK]: [
      completedWorkColumnDefs,
      completedWorkOmittedCols,
      completedWorkCustomColConfigs,
    ],
    [WidgetNames.SITE_ROSTER]: [siteRosterColumnDefs, siteRosterOmittedCols],
    [WidgetNames.ASSETS_OF_CONCERN]: [assetsOfConcernColumnDefs, assetsOfConcernOmittedCols],
    [WidgetNames.MANUALLY_ADJUSTED_EVENTS]: [
      manuallyAdjustedEventsColumnDefs,
      manuallyAdjustedEventsOmittedCols,
    ],
    [WidgetNames.CONTRACTUAL_AVAILABILITY]: [
      contractualAvailabilityColumnDefs,
      contractualAvailabilityOmittedCols,
    ],
    [WidgetNames.PRODUCTION_TABLE]: [productionTableColumnDefs, productionTableOmittedCols],
    [WidgetNames.OPEN_CASES]: [
      openCasesColumnDefs,
      openCasesOmittedCols,
      openCasesCustomColConfigs,
    ],
    [WidgetNames.CLOSED_CASES]: [
      closedCasesColumnDefs,
      closedCasesOmittedCols,
      closedCasesCustomColConfigs,
    ],
  };

  return widgetColMap[widgetName] || [{}];
};

// Get default column configuration for the specified widget and apply overrides from the template.
const getColumnDefaults = (widgetName, configuration, logger) => {
  const [_columnDefaults, columnsToOmit] = {
    [WidgetNames.PLANNED_WORK]: [plannedWorkDefaultCols, plannedWorkOmittedCols],
    [WidgetNames.COMPLETED_WORK]: [completedWorkDefaultCols, completedWorkOmittedCols],
    [WidgetNames.SITE_ROSTER]: [siteRosterDefaultCols, siteRosterOmittedCols],
    [WidgetNames.ASSETS_OF_CONCERN]: [assetsOfConcernDefaultCols, assetsOfConcernOmittedCols],
    [WidgetNames.PRODUCTION_TABLE]: [productionTableDefaultCols, productionTableOmittedCols],
    [WidgetNames.MANUALLY_ADJUSTED_EVENTS]: [
      manuallyAdjustedEventsDefaultCols,
      manuallyAdjustedEventsOmittedCols,
    ],
    [WidgetNames.CONTRACTUAL_AVAILABILITY]: [
      contractualAvailabilityDefaultCols,
      contractualAvailabilityOmittedCols,
    ],
    [WidgetNames.PRODUCTION_TABLE]: [productionTableDefaultCols, productionTableOmittedCols],
    [WidgetNames.OPEN_CASES]: [openCasesDefaultCols, openCasesOmittedCols],
    [WidgetNames.CLOSED_CASES]: [closedCasesDefaultCols, closedCasesOmittedCols],
  }[widgetName];

  const columnDefaults = [..._columnDefaults];
  if (configuration) {
    let flatCols;
    for (const [configKey, configValue] of Object.entries(configuration)) {
      if (typeof configValue === 'object') {
        // Assume config is for a Field Group; find the matching group in the column defaults
        const fieldGroup = columnDefaults.find((cd) => cd.id === configKey);
        if (fieldGroup) {
          if (fieldGroup.ignoreTemplateConfig === true) {
            continue;
          }
          if (fieldGroup.cols?.length) {
            for (const [fieldConfigKey, fieldConfigValue] of Object.entries(configValue)) {
              if (typeof fieldConfigValue !== 'boolean') {
                logger.warn(
                  'Unsupported config value present for field config key: ',
                  fieldConfigKey,
                );
                continue;
              }
              const field = fieldGroup.cols.find((c) => c.id === fieldConfigKey);
              if (field && !field?.ignoreTemplateConfig) {
                field.visible = fieldConfigValue;
              } else if (!columnsToOmit?.includes(fieldConfigKey)) {
                logger.warn('Could not find field configuration with name: ', fieldConfigKey);
              }
            }
          } else {
            logger.warn('Column config missing for field group: ', configKey);
          }
        } else {
          logger.warn('Could not find field group configuration with name: ', configKey);
        }
      } else if (typeof configValue === 'boolean') {
        // Assume config is a single column that does not belong to a group.
        if (!flatCols) {
          flatCols = columnDefaults.flatMap((cd) => cd.cols);
        }
        const field = flatCols.find((c) => c.id === configKey);
        if (field) {
          field.visible = configValue;
        } else if (!columnsToOmit?.includes(configKey)) {
          logger.warn('Could not find field configuration with name: ', configKey);
        }
      } else {
        logger.warn('Unsupported config value present for config key: ', configKey);
        continue;
      }
    }
  }

  return columnDefaults;
};

/**
 * Merge column definitions and defaults into a single array that can be used to
 * render the checkbox lists generically.
 *
 * @param {array} colDefs All available columns and related table definitions
 * @param {array} defaultCols Default configuration for column visibility
 * @param {array} omittedCols Column keys that should not be displayed in the selection list
 *
 * @returns Array of column groups with selectable columns inside
 */
const getGroupColVisibilityArray = (colDefs, defaultCols, omittedCols) => {
  return Object.keys(colDefs).reduce((groupAcc, groupKey) => {
    const groupObj = colDefs[groupKey];

    // Extract default visible cols
    const groupDefaults = defaultCols.find((groupDefaultObj) => groupDefaultObj.id === groupKey);
    const colDefaults = groupDefaults?.cols.filter((colDefaultObj) => colDefaultObj.visible);
    const visibleCols = colDefaults?.map((colDefaultObj) => colDefaultObj.id);

    const configReducer = (colAcc, [colKey, colObj]) => {
      const isOmitted = !!omittedCols?.includes(colKey);

      // Ignore explicitly omitted columns, make "locked" columns disabled
      if (!isOmitted) {
        colAcc.push({
          key: colKey,
          a11yKey: colObj.labels?.[0].a11yKey,
          a11yDefault: colObj.labels?.[0].a11yDefault,
          visible: visibleCols?.includes(colKey),
          disabled: colObj.locked,
        });
      }

      return colAcc;
    };

    // Build objects that can be rendered easily as checkboxes using table configs
    const cols = Object.entries(groupObj.cols ?? {}).reduce(configReducer, []);
    const fields = Object.entries(groupObj.fields ?? {}).reduce(configReducer, []);

    // Only include field groups that have selectable fields inside
    if (fields.length) {
      groupAcc.push({
        key: groupKey,
        a11yKey: groupObj.labels?.[0].a11yKey,
        a11yDefault: groupObj.labels?.[0].a11yDefault,
        fields: fields,
        type: groupObj.type,
      });
    }

    // Only include column groups that have selectable columns inside
    if (cols.length) {
      groupAcc.push({
        key: groupKey,
        a11yKey: groupObj.labels?.[0].a11yKey,
        a11yDefault: groupObj.labels?.[0].a11yDefault,
        cols: cols,
      });
    }

    return groupAcc;
  }, []);
};

export const TableModule = ({ id, name, configuration }) => {
  const MAX_COLUMNS = useMemo(() => getMaxColumns(name), [name]);

  const { getWidgetState, setWidgetState } = useContext(ReportsContext);
  const columnState = getWidgetState(id, TableWidgetStateKeys.COLUMN_CONFIG_STATE);

  const [columnDefs, setColumnDefs] = useState();
  const [omittedCols, setOmittedCols] = useState();
  const [customCols, setCustomCols] = useState();

  const { t, ready } = useTranslation(['reporting.widgets', 'reporting.sidebar']);
  const logger = useLogger();

  const columnDefaults = useMemo(
    () => getColumnDefaults(name, configuration, logger),
    [name, configuration, logger],
  );

  // Bootstrap the column configurations based on the widget name.
  useEffect(() => {
    const [columnDefs, columnsToOmit, customCols] = getColumnDefs(name);
    setColumnDefs(columnDefs);
    setOmittedCols(columnsToOmit);
    setCustomCols(customCols);
  }, [name]);

  // Initialize default column configuration in widget state
  useEffect(() => {
    if (!columnState) {
      setWidgetState({
        widgetId: id,
        key: TableWidgetStateKeys.COLUMN_CONFIG_STATE,
        value: columnDefaults,
      });
    }
  }, [columnState, id, columnDefaults, setWidgetState]);

  // When the notes substate changes, update widget state. This function
  // returns a function so we can use it generically to set other sub state values.
  const subStateSetter = useCallback(
    (groupKey) => {
      if (columnState) {
        switch (groupKey) {
          case TableBaseCols.GROUP_NOTES: {
            return (newState = {}) => {
              const currentState = [...columnState];
              const groupObj = currentState.find((group) => group.id === groupKey);
              const externalCol = groupObj?.cols.find(
                (col) => col.id === TableBaseCols.NOTES_EXTERNAL,
              );
              const internalCol = groupObj?.cols.find(
                (col) => col.id === TableBaseCols.NOTES_INTERNAL,
              );
              if (
                externalCol?.visible !== newState.external ||
                internalCol?.visible !== newState.internal
              ) {
                externalCol && (externalCol.visible = newState.external);
                internalCol && (internalCol.visible = newState.internal);
                setWidgetState({
                  widgetId: id,
                  key: TableWidgetStateKeys.COLUMN_CONFIG_STATE,
                  value: currentState,
                });
              }
            };
          }
          default:
        }
      }
      return () => null;
    },
    [id, columnState, setWidgetState],
  );

  // Generate the structure to render selectable columns once per widget "name"
  const groupColArr = useMemo(() => {
    if (!columnDefs || !columnState) {
      return [];
    }

    return getGroupColVisibilityArray(columnDefs, columnState, omittedCols);
  }, [columnDefs, columnState, omittedCols]);

  // Track the number of user-selectable column items
  const selectableCount = useMemo(() => {
    return groupColArr?.reduce((acc, group) => {
      return (acc +=
        group.type === 'radio'
          ? 0
          : group.cols?.filter((col) => !col.excludeFromCount)?.length ?? 0);
    }, 0);
  }, [groupColArr]);

  // Track the number of selected items when the column state changes.
  const selectedCount = useMemo(() => {
    return columnState?.reduce((acc, group) => {
      return (acc +=
        group.cols?.filter((col) => col.visible && !col.excludeFromCount)?.length ?? 0);
    }, 0);
  }, [columnState]);

  // Update the visible state when a checkbox is selected.
  const handleCheckChange = useCallback(
    (groupKey, colKey, newState) => {
      // Check to make sure the max selection count is not exceeded.
      if (newState === true && selectedCount === MAX_COLUMNS) {
        return;
      }

      // Clone column state, modify the appropriate column, and re-set
      const currentState = [...columnState];

      // NOTE: This relies on defaults for EVERY COLUMN to be defined,
      // or this will fail to find the column by id/key.
      // TODO (astone): Perhaps introduce mutation here so this is not a requirement?
      const colObj = currentState
        .find((group) => group.id === groupKey)
        ?.cols.find((col) => col.id === colKey);
      colObj.visible = newState;

      // Update the report state with the updated column configuration when changed.
      setWidgetState({
        widgetId: id,
        key: TableWidgetStateKeys.COLUMN_CONFIG_STATE,
        value: currentState,
      });
    },
    [id, columnState, MAX_COLUMNS, selectedCount, setWidgetState],
  );

  // Render a generic checkbox unless it is included in the custom column configuration.
  const renderCheckbox = useCallback(
    (group, col) => {
      const maxSelected = selectedCount === MAX_COLUMNS;
      const colDisabled = col.disabled || (maxSelected && !col.visible);

      // Handle custom renderers
      // TODO: Get by widget name and column key. In this case, Planned Work and Completed
      // Work have the same key values, so we need a compound key to make sure we don't
      // grab the wrong renderer by mistake.
      if (customCols?.includes(col.key)) {
        if (col.key === TableBaseCols.NOTES_LATEST_NOTE) {
          const groupDefaults = columnDefaults?.find((cd) => cd.id === group.key)?.cols ?? {};
          const notesConfiguration = {
            internal: groupDefaults?.find((c) => c.id === TableBaseCols.NOTES_INTERNAL)?.visible,
            external: groupDefaults?.find((c) => c.id === TableBaseCols.NOTES_EXTERNAL)?.visible,
          };
          return (
            <NotesCustomRenderer
              name={`${id}-${group.key}-${col.key}`}
              label={t(col.a11yKey, col.a11yDefault)}
              checkState={col.visible ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              onChange={(newState) => handleCheckChange(group.key, col.key, newState)}
              disabled={colDisabled}
              onSubstateChange={subStateSetter(group.key)}
              configuration={notesConfiguration}
            />
          );
        }

        // Unknown custom renderer; return default.
      }

      // Default to normal checkboxes
      return (
        <Checkbox
          name={`${id}-${group.key}-${col.key}`}
          label={t(col.a11yKey, col.a11yDefault)}
          checkState={col.visible ? CheckedState.CHECKED : CheckedState.UNCHECKED}
          onChange={(newState) => handleCheckChange(group.key, col.key, newState)}
          disabled={colDisabled}
        />
      );
    },
    [
      id,
      columnDefaults,
      MAX_COLUMNS,
      selectedCount,
      subStateSetter,
      handleCheckChange,
      customCols,
      t,
    ],
  );

  // Render a generic radio button for a field per its configuration.
  const renderRadio = useCallback(
    (group, field) => {
      return (
        <Radio
          checked={field.visible === true}
          className="module-config-radio"
          disabled={field.disabled}
          id={field.key}
          label={t(field.a11yKey, field.a11yDefault)}
          name={`${id}-${group.key}`}
          onChange={(newState) => handleCheckChange(group.key, field.key, newState)}
          value={field.key}
        />
      );
    },
    [id, handleCheckChange, t],
  );

  const buildGroupCheckboxes = useCallback(
    (group, items) => {
      const renderItemFn = group.type === 'radio' ? renderRadio : renderCheckbox;
      return (
        <CheckboxContainer>
          {items?.map((item) => (
            <CheckboxWrapper key={item.key}>{renderItemFn(group, item)}</CheckboxWrapper>
          ))}
        </CheckboxContainer>
      );
    },
    [renderCheckbox, renderRadio],
  );

  // Memoized column panel configurations
  const colConfigPanels = useMemo(() => {
    const colGroups = groupColArr.filter((group) => !!group.cols);
    // Wrap checkboxes in panels by column group if more than one group defined.
    if (colGroups.length > 1) {
      return colGroups.map((group) => (
        <CollapsiblePanel
          key={group.key}
          headerContent={
            <h4>
              {t(group.a11yKey)} ({group.cols.filter((col) => col.visible).length})
            </h4>
          }
          expanded={true}
        >
          {buildGroupCheckboxes(group, group.cols)}
        </CollapsiblePanel>
      ));
    } else if (colGroups.length === 1) {
      return buildGroupCheckboxes(colGroups[0], colGroups[0].cols);
    }
  }, [groupColArr, t, buildGroupCheckboxes]);

  // Memoized option panel configurations
  const configOptionPanels = useMemo(() => {
    const optionGroups = groupColArr.filter((group) => !!group.fields);
    return optionGroups.map((group) => (
      <React.Fragment key={group.key}>
        <ColumnsWrapper>
          <ColumnsHeader>{t(group.a11yKey, group.a11yDefault)}</ColumnsHeader>
        </ColumnsWrapper>
        {buildGroupCheckboxes(group, group.fields)}
      </React.Fragment>
    ));
  }, [groupColArr, t, buildGroupCheckboxes]);

  if (!ready) {
    return;
  }

  return (
    <>
      {configOptionPanels}
      <ColumnsWrapper>
        <ColumnsHeader>
          {t('columns', { defaultValue: 'Columns', ns: 'reporting.sidebar' })}
        </ColumnsHeader>
        {!!MAX_COLUMNS && selectableCount > MAX_COLUMNS && (
          <ColumnsSubHeader>
            {t('select_col_max', { defaultValue: 'Select up to', ns: 'reporting.sidebar' })}{' '}
            {MAX_COLUMNS}
          </ColumnsSubHeader>
        )}
      </ColumnsWrapper>
      {colConfigPanels}
    </>
  );
};

TableModule.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  configuration: PropTypes.object,
};
