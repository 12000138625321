import { useState, useCallback } from 'react';

import { usePlanningContext } from '@ge/feat-manage/context/planning-context';

export const useCalendarCardMenu = () => {
  const [menuShown, setMenuShown] = useState(null);
  const [menuTaskId, setMenuTaskId] = useState(null);

  const {
    planningState: { dragItem, setIsBundleToggle },
  } = usePlanningContext();

  const destroyStackMenu = useCallback(() => {
    setMenuShown(false);
    setIsBundleToggle(false);
    setMenuTaskId(null);
  }, [setIsBundleToggle]);

  const showStackMenu = useCallback(
    (id) => {
      if (dragItem) return;
      setMenuShown(true);
      setMenuTaskId(id);
    },
    [dragItem],
  );

  return {
    menuTaskId,
    menuShown,
    showStackMenu,
    destroyStackMenu,
  };
};
