import { useStoreState, useStoreActions } from 'easy-peasy';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { EntityType, TabFlowModelVariables } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import { useEntitiesFiltered } from '../../../../entity-create/hooks/use-entities-filtered';
import { usePatchPerson } from '../../hooks/use-patch-person';

import AllUserRolesComponent from './all-users-roles/all-user-roles-component';
import ViewModeDropdownComponent from './all-users-roles/view-mode-dropdown-component';
import { PersonAssetsGroupServiceGroup } from './person-assets-service-group';
import { PersonAssetsSites } from './person-assets-sites';
import WorkerAssetsComponent from './worker-assets/worker-assets-component';

const LoadingWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 50vh;
`;

const PersonAssetsComponent = withTheme(({ theme }) => {
  const { iconColor } = theme.themeSelector;
  const {
    role,
    activeTab,
    editStatus,
    viewByServiceGroupsOrSites,
    person,
    filterByServiceGroup,
    filterBySite,
    tabIterator,
  } = useStoreState((state) => state.tabflow.personAssets);

  let { entitiesFiltered: sites } = useEntitiesFiltered(
    EntityType.SITE,
    filterBySite,
    tabIterator[activeTab],
  );
  let { entitiesFiltered: newServiceGroups } = useEntitiesFiltered(
    EntityType.SERVICE_GROUP,
    filterByServiceGroup,
    tabIterator[activeTab],
  );
  let isServiceGroupAndSitesLoading = useStoreState(
    (state) => state.sites.isServiceGroupAndSitesLoading,
  );

  const { changeState, addPersonData, refreshIds, cancelActions } = useStoreActions(
    (actions) => actions.tabflow,
  );

  const { t } = useTranslation(['admin.people']);
  const history = useHistory();
  const location = useLocation();

  const changeEditStatus = (editStatus) => {
    if (editStatus) {
      changeState({ name: TabFlowModelVariables.EDITSTATUS, value: false });
    } else {
      changeState({ name: TabFlowModelVariables.EDITSTATUS, value: true });
    }
  };

  const finishEditMode = (status) => {
    if (status) {
      changeState({ name: TabFlowModelVariables.EDITSTATUS, value: false });
      const returnSyncData = addPersonData('save');
      if (returnSyncData !== false) {
        refreshDataAndClose(returnSyncData);
      }
    } else {
      cancelActions('cancel');
      changeState({ name: TabFlowModelVariables.EDITSTATUS, value: false });
    }
  };

  const lastActions = useCallback(() => {
    history.push(location.pathname);
  }, [history, location.pathname]);

  const successPatch = () => {
    refreshIds();
  };

  const { patch } = usePatchPerson({
    onSuccess: successPatch,
  });

  const refreshDataAndClose = useCallback(
    (returnSyncData) => {
      const { username } = person;
      patch({ username, body: returnSyncData });
      lastActions();
    },
    [lastActions, patch, person],
  );

  let viewByItems = [
    {
      value: TabFlowModelVariables.VIEWBY.GROUPS,
      label: t('create.service_groups', 'Service Groups'),
    },
    {
      value: TabFlowModelVariables.VIEWBY.SITES,
      label: t('create.sites', 'Sites'),
    },
  ];
  return (
    <div className="person-assets-container">
      {editStatus === true && role === TabFlowModelVariables.ROLESTYPE.USER && (
        <AllUserRolesComponent dropdownItems={viewByItems} />
      )}

      {editStatus === true &&
        role === TabFlowModelVariables.ROLESTYPE.USER_AND_WORKER &&
        activeTab === 0 && <WorkerAssetsComponent />}

      {editStatus === true &&
        role === TabFlowModelVariables.ROLESTYPE.USER_AND_WORKER &&
        activeTab > 0 && <AllUserRolesComponent dropdownItems={viewByItems} />}

      {editStatus === true &&
        role === TabFlowModelVariables.ROLESTYPE.WORKER &&
        activeTab === 0 && <WorkerAssetsComponent />}

      {editStatus === true && role === TabFlowModelVariables.ROLESTYPE.WORKER && activeTab > 0 && (
        <AllUserRolesComponent dropdownItems={viewByItems} />
      )}

      <div className="person-assets-frame">
        {editStatus === false && (
          <>
            <div className="person-assets-header-container">
              <div className="person-assets-one">
                <div>{t('assets.ASSETS', 'ASSETS')}</div>
                <div className="person-assets-text-m">
                  {t('assets.assets_applied', 'Assets applied to')} {person.firstName} {role}{' '}
                  {t('assets.assets_role', 'Role(s)')}
                </div>
              </div>
              <div onClick={() => changeEditStatus(editStatus)} className="person-assets-two">
                <Icon icon={Icons.PENCIL} size={11} color={iconColor} />
              </div>
            </div>
            <ViewModeDropdownComponent dropdownItems={viewByItems} />
          </>
        )}

        {viewByServiceGroupsOrSites === TabFlowModelVariables.VIEWBY.GROUPS && (
          <div className="person-assets-assets" style={{ color: globalColors.grey4 }}>
            {t('assets.number_of_assets_edit_flow', '# of Sites')}
          </div>
        )}
        {viewByServiceGroupsOrSites === TabFlowModelVariables.VIEWBY.SITES && (
          <div className="person-assets-assets" style={{ color: globalColors.grey4 }}></div>
        )}
        {isServiceGroupAndSitesLoading ? (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        ) : viewByServiceGroupsOrSites === TabFlowModelVariables.VIEWBY.SITES ? (
          <PersonAssetsSites sites={sites} />
        ) : viewByServiceGroupsOrSites === TabFlowModelVariables.VIEWBY.GROUPS ? (
          <PersonAssetsGroupServiceGroup serviceGroups={newServiceGroups} />
        ) : null}
        {editStatus && (newServiceGroups.length || sites.length) && (
          <div className="person-assets-group-buttons">
            <Button
              onClick={() => finishEditMode(false)}
              type="button"
              className="person-assets-group-buttons-left-items"
            >
              {t('assets.Cancel', 'Cancel')}
            </Button>
            <Button
              primary
              onClick={() => finishEditMode(true)}
              type="button"
              className="person-assets-group-button-right-items"
            >
              {t('assets.Save', 'Save')} {role}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});

export default PersonAssetsComponent;
