import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_API;

export const fetchIssuesByCaseId = (caseId) =>
  request.get('/dav/issues', { baseURL: BASE_URL, params: { caseId } });

export const fetchEventHistory = (assetId) =>
  request.get('/dav/issues', { baseURL: BASE_URL, params: { assetId } });

/**
 * Dynamically load the anomalies mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/issue/issues.mocks').catch((e) => {
    // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
  });
