import { useState, useCallback, useEffect, useRef } from 'react';

export const useDraggable = (elements, { useDragButton, onDrop }) => {
  // Build isolated drag/drop state
  const [dragOver, setDragOver] = useState(null);
  const [dragIndex, setDragItem] = useState(null);
  const dragButtonRef = useRef(null);

  useEffect(() => {
    // Reset state.
    dragButtonRef.current = null;
    setDragOver(null);
    setDragItem(null);
  }, []);

  // Drag/drop event handlers
  const handleDragStart = useCallback(
    (_, index) => {
      if (!useDragButton) {
        setDragItem(index);
        return;
      }
      if (dragButtonRef.current) {
        setDragItem(index);
      }
    },
    [useDragButton],
  );

  const handleDragOver = useCallback(
    (e) => {
      if (!useDragButton) {
        e.preventDefault();
        return;
      }
      if (dragButtonRef.current) {
        e.preventDefault();
      }
    },
    [useDragButton],
  );

  const handleDragEnter = useCallback(
    (_, index) => {
      if (dragIndex === index) {
        setDragOver(null);
        return;
      }
      if (!useDragButton) {
        setDragOver(index);
        return;
      }
      if (dragButtonRef.current) {
        setDragOver(index);
      }
    },
    [dragIndex, useDragButton],
  );

  const handleDragLeave = useCallback(() => {
    setDragOver(null);
  }, []);

  const handleDragEnd = useCallback(() => {
    // Reset state.
    dragButtonRef.current = null;
    setDragOver(null);
    setDragItem(null);
  }, []);

  const handleDragButton = useCallback((e) => {
    dragButtonRef.current = e.currentTarget;
  }, []);

  const handleDrop = useCallback(() => {
    if (!dragButtonRef?.current) return;

    if (dragIndex >= 0 && dragOver >= 0 && dragIndex !== dragOver) {
      const newElementsState = [...elements];

      // Update the column order to reflect dropped position
      newElementsState.splice(dragOver, 0, newElementsState.splice(dragIndex, 1)[0]);

      // Invoke the provided onDrop method with the updated column state.
      onDrop.call(null, newElementsState);
    }
    // Reset state.
    dragButtonRef.current = null;
    setDragOver(null);
    setDragItem(null);
  }, [elements, dragIndex, dragOver, onDrop]);

  const handleReset = useCallback(() => {
    // Reset state.
    dragButtonRef.current = null;
    setDragOver(null);
    setDragItem(null);
  }, []);

  return {
    handlers: {
      handleDragStart,
      handleDragEnd,
      handleDragOver,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      handleReset,
      handleDragButton,
    },
    state: {
      elements,
      dragOver,
    },
  };
};

export default useDraggable;
