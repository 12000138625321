import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { DateTimeFormats, FormMode, EntityType, TaskNotes } from '@ge/models/constants';
import { getAssetIcon } from '@ge/shared/util';

const iconMap = {
  [EntityType.CASE]: Icons.CASE,
  [EntityType.TASK]: Icons.TASK,
};

const EntityInfo = styled.div`
  color: ${({ theme }) => theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
`;

const EntityInfoIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.entityDetails.notes.entityInfo,
}))`
  margin-right: 5px;
`;

const EntityDescription = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 4px;
  svg {
    margin-right: 5px;
  }
`;

const EntityDescriptionIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.entityDetails.notes.entityDescription,
}))`
  margin-right: 5px;
`;

const TitleContainer = styled.div`
  padding-bottom: 10px;
`;

const TitleRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.entityDetails.notes.separator};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const AuthorInfo = styled.label`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  margin: 0;
  width: 250px;
`;

const StyledLabel = styled.label`
  grid-row: 1;
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 13px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

const StyledValue = styled.label`
  font: 300 13px/15px;
  margin-left: 0;
`;

const NoteHeader = ({ mode, data, entity, entityType }) => {
  const { description, priority, site = {}, asset = {} } = entity ?? {};

  const { t } = useTranslation(['entity-details']);

  return (
    <TitleContainer>
      <TitleRow>
        <EntityInfo>
          <EntityInfoIcon icon={Icons.SITE} color={'transparent'} size={11} />
          <span>{`${site.name} / `}</span>
          <EntityInfoIcon icon={getAssetIcon(asset.type)} color={'transparent'} size={11} />
          <span>{asset.name}</span>
        </EntityInfo>
        <EntityDescription>
          {entity.taskIds?.length >= 1 && (
            <EntityDescriptionIcon icon={iconMap[entityType]} color={'transparent'} size={20} />
          )}
          <Severity level={priority} />
          {description}
        </EntityDescription>
      </TitleRow>
      {mode === FormMode.EDIT && data && (
        <TitleRow>
          <AuthorInfo>
            <StyledLabel>{t('notes.date_created', 'Date Created')}</StyledLabel>
            <StyledValue>
              {dayjs(data.creationDate).format(DateTimeFormats.DEFAULT_DATE)}
            </StyledValue>
            <StyledLabel>{t('notes.contributed_by', 'Contributed By')}</StyledLabel>
            <StyledValue>
              {data.createdBy === TaskNotes.DPOD_SSO ? 'DPOD' : data.createdBy}
            </StyledValue>
          </AuthorInfo>
        </TitleRow>
      )}
    </TitleContainer>
  );
};

NoteHeader.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  icon: PropTypes.node,
  mode: PropTypes.string,
  maxAttachments: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  entity: PropTypes.instanceOf(Object),
  entityType: PropTypes.string,
};

NoteHeader.defaultProps = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  icon: Icons.CASE,
  mode: FormMode.CREATE,
  maxAttachments: undefined,
  entity: null,
  entityType: null,
};

export default NoteHeader;
