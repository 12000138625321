import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextareaMetaField } from '../../../../meta-fields';
import { ResolutionNotesSelect } from '../../../task-fields/resolution-notes-select';
import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditResolutionNote = ({ metadata, task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.resolution_note', 'Resolution Note')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          {metadata.resolutionNotes.type === 'select' ? (
            <ResolutionNotesSelect
              metadata={metadata.resolutionNotes.edit}
              caseId={task.caseId}
              task={task}
            />
          ) : (
            <TextareaMetaField
              label={t('form.note', 'Note')}
              metadata={metadata.resolutionNotes.edit}
              maxLength={metadata.resolutionNotes.maxLength}
              name="resolutionNotes"
              placeholder={t('form.note_placeholder', 'Type note here')}
              defaultValue={task?.resolutionNotes}
            />
          )}
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

EditResolutionNote.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};
