import { TableFilterTypes } from '@ge/models/constants';

export const SitesColumns = {
  GROUP_REGION: 'group-region',
  REGION: 'regionName',

  GROUP_SITE: 'group-site',
  SITE: 'site',

  // Region view - Site columns
  GROUP_PLATFORM: 'group-platform',
  PLATFORM: 'platForm',

  GROUP_TBA: 'group-tba',
  TBA: 'tba',

  // PBA
  GROUP_PBA: 'group-pba',
  PBA: 'pba',

  GROUP_AVAILABILITY_CONTRACT: 'group-availability-contract',
  AVAILABILITY_CONTRACT: 'availabilityContract',

  // Unproduced Energy
  GROUP_UNPRODUCED_ENERGY_MWH: 'group-unproduced-energy-mwh',
  UNPRODUCED_ENERGY_MWH: 'unproducedEnergyContract',

  GROUP_ACTUAL_PRODUCTION: 'group-actual-production',
  ACTUAL_PRODUCTION: 'productionActual',

  GROUP_CAPACITY_FACTOR: 'group-capacity-factor',
  CAPACITY_FACTOR: 'capacityFactor',

  GROUP_EVENT_COVERAGE: 'group-event-coverage',
  EVENT_COVERAGE: 'eventCoverage',

  GROUP_ACTUAL_VS_EXPECTED_PRODUCTION: 'group-actual-vs-expected-production',
  ACTUAL_VS_EXPECTED_PRODUCTION: 'actualVsExpectedProduction',

  GROUP_REPORTING_VS_EXPECTED: 'group-actual-vs-expected-production',
  REPORTING_VS_EXPECTED: 'reportingAssetsRatio',

  GROUP_SITE_DETAIL: 'group-site-detail',
  SITE_DETAIL: 'siteDetail',
};

/*
  Region View - Site Cards -> Table Columns
   Region
   Site
   *Platform
   *TBA-Technical
   *Contractual Availability
   *PBA-Technical
   *Unproduced Energy
   *Actual Production
   *Capacity Factor
   *Event Coverage
   *Reporting vs. Expected (Maybe: Actual vs Expected Production?)
  */

// Define all available columns in the Region view - Sites List table.
export const SitesColumnDefs = {
  [SitesColumns.GROUP_REGION]: {
    cols: {
      [SitesColumns.REGION]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.region',
            a11yDefault: 'Region',
            sortValue: SitesColumns.REGION,
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_SITE]: {
    cols: {
      [SitesColumns.SITE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        minWidth: '200px',
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.site',
            a11yDefault: 'Site',
            sortValue: SitesColumns.SITE,
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_PLATFORM]: {
    cols: {
      [SitesColumns.PLATFORM]: {
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.platform',
            a11yDefault: 'Platform',
            sortValue: SitesColumns.PLATFORM,
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_TBA]: {
    cols: {
      [SitesColumns.TBA]: {
        filterType: TableFilterTypes.NUMBER,
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.tba',
            a11yDefault: 'TBA - Technical\n%',
            sortValue: 'tba.aggregateValue',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_AVAILABILITY_CONTRACT]: {
    cols: {
      [SitesColumns.AVAILABILITY_CONTRACT]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.contractual_availability',
            a11yDefault: 'Contractual Availability\n%',
            sortValue: 'availabilityContract.aggregateValue',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_PBA]: {
    cols: {
      [SitesColumns.PBA]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.pba',
            a11yDefault: 'PBA - Technical\n%',
            sortValue: 'pba.aggregateValue',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_UNPRODUCED_ENERGY_MWH]: {
    cols: {
      [SitesColumns.UNPRODUCED_ENERGY_MWH]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.unproduced_energy_mwh',
            a11yDefault: 'Unproduced Energy\nMWh',
            sortValue: 'unproducedEnergyContract.aggregateValue',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_ACTUAL_PRODUCTION]: {
    cols: {
      [SitesColumns.ACTUAL_PRODUCTION]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.actual_production_mwh',
            a11yDefault: 'Actual Production\nMWh',
            sortValue: 'productionActual.aggregateValue',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_CAPACITY_FACTOR]: {
    cols: {
      [SitesColumns.CAPACITY_FACTOR]: {
        filterType: TableFilterTypes.NUMBER,
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.capacity_factor',
            a11yDefault: 'Capacity Factor\n%',
            sortValue: 'capacityFactor.aggregateValue',
          },
        ],
      },
    },
  },
  [SitesColumns.GROUP_EVENT_COVERAGE]: {
    cols: {
      [SitesColumns.EVENT_COVERAGE]: {
        filterType: TableFilterTypes.NUMBER,
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.event_coverage',
            a11yDefault: 'Event Coverage\n%',
            sortValue: 'eventCoverage.aggregateValue',
          },
        ],
      },
    },
  },
  // [SitesColumns.GROUP_REPORTING_VS_EXPECTED]: {
  //   cols: {
  //     [SitesColumns.REPORTING_VS_EXPECTED]: {
  //       align: 'left',
  //       labels: [
  //         {
  //           a11yKey: 'region.table.reporting_vs_expected',
  //           a11yDefault: 'Reporting vs. Expected',
  //           sortValue: 'reportingAssetsRatio.aggregateValue',
  //         },
  //       ],
  //     },
  //   },
  // },

  [SitesColumns.GROUP_SITE_DETAIL]: {
    fixedRight: true,
    cols: {
      [SitesColumns.SITE_DETAIL]: {
        fixedRight: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.site_details',
            a11yDefault: 'Site\nDetails',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '43px',
        },
      },
    },
  },
};

// Define all available columns in the Region view - Sites List table.
export const AllSitesColumnDefs = {
  ...SitesColumnDefs,
  [SitesColumns.GROUP_REGION]: {
    cols: {
      [SitesColumns.REGION]: {
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        align: 'left',
        labels: [
          {
            a11yKey: 'region.table.region',
            a11yDefault: 'Region',
            sortValue: SitesColumns.REGION,
          },
        ],
      },
    },
  },
};

export const SolarSitesColumns = {
  GROUP_SITES: 'sites',
  DATA: 'data',
  LOCATION: 'location',
  SITE: 'site',
  GROUP_ASSETS: 'assets',
  TOTAL: 'total',
  AVAIL: 'avl',
  GROUP_PRODUCTION: 'production',
  PRODUCTION: 'e_mwh',
  GROUP_REVENUE: 'revenue',
  MTD: 'mtd',
  PERFORMANCE_RATIO: 'pr',
  YIELD: 'yld',
  CAPACITY_FACTOR: 'cf',
  GROUP_WEATHER: 'weather',
  WIND: 'wind',
  POA_IRRADIANCE: 'irrdn',
  AMBIENT_TEMPERATURE: 'ambt',
};

export const SolarSitesColumnsMapping = {
  avl: 'Avail',
  name: 'Site',
  country: 'Location',
  e_mwh: 'Production',
  mtdl: 'MTD(L)',
  pr: 'Performance Ratio',
  yld: 'Yield',
  cf: 'Capacity Factor',
  wind: 'Wind',
  irrdn: 'POA Irradiance',
  ambt: 'Ambient Temperature',
};

export const AllSolarSitesColumnDefs = {
  [SolarSitesColumns.GROUP_SITES]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'SITES',
      },
    ],
    cols: {
      [SolarSitesColumns.LOCATION]: {
        labels: [
          {
            a11yKey: 'table.country',
            a11yDefault: 'Location',
            sortValue: 'country',
          },
        ],
      },
      [SolarSitesColumns.SITE]: {
        labels: [
          {
            a11yKey: 'table.name',
            a11yDefault: 'Site',
            sortValue: 'name',
          },
        ],
      },
    },
  },
  [SolarSitesColumns.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [SolarSitesColumns.TOTAL]: {
        labels: [
          {
            a11yKey: '',
            a11yDefault: 'Total',
            sortValue: '',
          },
        ],
      },
      [SolarSitesColumns.AVAIL]: {
        labels: [
          {
            a11yKey: 'table.kpis.avl',
            a11yDefault: 'Avail',
            sortValue: 'kpis.avl',
          },
        ],
      },
    },
  },
  [SolarSitesColumns.GROUP_PRODUCTION]: {
    cols: {
      [SolarSitesColumns.PRODUCTION]: {
        labels: [
          {
            a11yKey: 'table.kpis.e_mwh',
            a11yDefault: 'Production',
            sortValue: 'kpis.e_mwh',
          },
        ],
      },
    },
  },
  [SolarSitesColumns.GROUP_REVENUE]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'REVENUE',
      },
    ],
    cols: {
      [SolarSitesColumns.MTD]: {
        labels: [
          {
            a11yKey: 'table.kpis.mtdl',
            a11yDefault: 'MTD(L)',
            sortValue: 'kpis.mtdl',
          },
        ],
      },
      [SolarSitesColumns.PERFORMANCE_RATIO]: {
        labels: [
          {
            a11yKey: 'table.kpis.pr',
            a11yDefault: 'Performance Ratio',
            sortValue: 'kpis.pr',
          },
        ],
      },
      [SolarSitesColumns.YIELD]: {
        labels: [
          {
            a11yKey: 'table.kpis.yld',
            a11yDefault: 'Yield',
            sortValue: 'kpis.yld',
          },
        ],
      },
      [SolarSitesColumns.CAPACITY_FACTOR]: {
        labels: [
          {
            a11yKey: 'table.kpis.cf',
            a11yDefault: 'Capacity Factor',
            sortValue: 'kpis.cf',
          },
        ],
      },
    },
  },
  [SolarSitesColumns.GROUP_WEATHER]: {
    labels: [
      {
        a11yKey: '',
        a11yDefault: 'WEATHER',
      },
    ],
    cols: {
      [SolarSitesColumns.WIND]: {
        labels: [
          {
            a11yKey: 'table.kpis.wind',
            a11yDefault: 'Wind',
            sortValue: 'kpis.wind',
          },
        ],
      },
      [SolarSitesColumns.POA_IRRADIANCE]: {
        labels: [
          {
            a11yKey: 'table.kpis.irrdn',
            a11yDefault: 'POA Irradiance',
            sortValue: 'kpis.irrdn',
          },
        ],
      },
      [SolarSitesColumns.AMBIENT_TEMPERATURE]: {
        labels: [
          {
            a11yKey: 'table.kpis.ambt',
            a11yDefault: 'Ambient Temperature',
            sortValue: 'kpis.ambt',
          },
        ],
      },
    },
  },
};
