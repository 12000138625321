import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, useRef, useMemo } from 'react';
import styled, { css, withTheme } from 'styled-components';

import { elevations } from '@ge/tokens/elevations';
import { getPercentLeftOffset } from '@ge/util';

export const timelineRanges = {
  MONTHS: 'months',
  MONTH: 'month',
  DAY: 'day',
};

const sliderThumbCss = css`
  pointer-events: all;
  width: 7px;
  height: 38px;
  border-radius: 0;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  &:hover {
    cursor: ew-resize;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 38px;
  padding: 0 3px;
  margin-top: 7px;
  &:hover {
    .left,
    .right {
      opacity: 1;
      box-shadow: 0 0 7px handleColor;
    }
  }
  input[type='range'] {
    position: absolute;
    -webkit-appearance: none;
    pointer-events: none;
    z-index: ${elevations.P2};
    height: 38px;
    width: 100%;
    opacity: 0.7;
    margin: 0;
    padding: 0;
    background: transparent;
    outline: none;
  }
  input[type='range']::-moz-range-thumb {
    ${sliderThumbCss}
    transform: translate(-3px,1px);
  }
  input[type='range']::-webkit-slider-thumb {
    ${sliderThumbCss}
    transform: translate(-3px,1px);
  }
  input[type='range']::-ms-thumb {
    ${sliderThumbCss}
    transform: translate(-3px,1px);
  }
`;

const RangeSlider = styled.div`
  position: relative;
  z-index: ${elevations.P1};
  height: 38px;
`;

const Track = styled.div`
  position: absolute;
  z-index: ${elevations.P1};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  height: 38px;
`;

const Range = styled.div`
  position: absolute;
  z-index: ${elevations.P2};
  left: ${(props) => `${props.left}%`};
  right: ${(props) => `${props.right}%`};
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.timeline.rangeBackground};
  height: 38px;
  border-bottom: solid 1px ${(props) => props.theme.timeline.handleColor};
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid ${(props) => props.theme.timeline.handleColor};
    transform: translate(50%, 0);
    left: 50%;
  }
`;

const Handle = styled.div`
  position: absolute;
  z-index: ${elevations.P3};
  width: 2px;
  height: 38px;
  background: ${(props) => props.theme.timeline.handleColor};
  opacity: 0.5;
  transition: opacity ease 0.3s, box-shadow ease 0.3s;
  &.left {
    left: ${(props) => `${props.left}%`};
    transform: translate(-1px, 1px);
  }
  &.right {
    right: ${(props) => `${props.right}%`};
    transform: translate(1px, 1px);
  }
`;

const RangeLabels = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${elevations.P2};
  li {
    position: relative;
    display: inline-block;
    height: 38px;
    user-select: none;
  }
`;

const WeekLabel = styled.label`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 20px;
  font-size: 12px;
`;

const MonthLabel = styled.label`
  position: absolute;
  left: 0;
  bottom: 20;
  width: 100%;
  text-align: center;
  height: 20px;
  font-size: 12px;
  z-index: ${elevations.P2};
`;

const tickCss = css`
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 1px;
  background: ${(props) => props.theme.timeline.tickMark};
`;

const Tick = styled.div`
  ${tickCss}
  height: 20px;
`;

const MonthTick = styled.div`
  ${tickCss}
  height: 38px;
`;

const getTicksArray = (num, range) => {
  let ticks = {
    options: [],
    months: [],
  };

  if (range === timelineRanges.MONTHS) {
    let date = dayjs().endOf('month');
    let currentMonth = dayjs();
    const start = currentMonth.clone().endOf('month');
    const end = currentMonth
      .clone()
      .subtract(num - 1, 'month')
      .startOf('month');
    const diffDays = start.diff(end, 'days');
    const diffWeeks = start.diff(end, 'weeks');

    // Set selectable ticks
    ticks.options = [...Array(diffWeeks)]
      .map(() => {
        const newDate = date.clone();
        const endDate = newDate.subtract(1, 'day').format('D');
        const startDate = newDate.subtract(1, 'week').format('D');
        const width = `${100 / diffWeeks}%`;
        date = newDate.subtract(1, 'week');
        return {
          label: `${startDate}-${endDate}`,
          date: newDate.unix(),
          styles: { width },
        };
      })
      .reverse();

    // Set month Ticks
    ticks.months = [...Array(num)]
      .map(() => {
        const month = currentMonth.clone();
        currentMonth = month.subtract(1, 'month');
        const width = `${Math.floor((100 / diffDays) * month.daysInMonth())}%`;
        return {
          label: month.format('MMMM'),
          styles: { width },
        };
      })
      .reverse();
  }

  return ticks;
};

export const Timeline = withTheme(({ ...props }) => {
  const { count, range } = props;

  const timelineArray = useMemo(() => getTicksArray(count, range), [count, range]);
  const minValue = useRef(0);
  const maxValue = useRef(timelineArray.options.length);
  const [handleLeft, setHandleLeft] = useState(0);
  const [handleRight, setHandleRight] = useState(0);
  const [leftVal, setLeftVal] = useState(minValue.current);
  const [rightVal, setRightVal] = useState(maxValue.current);

  const rangeLeftRef = useRef();
  const rangeRightRef = useRef();

  const setLeftValue = (e) => {
    const val = parseInt(e.target.value);
    if (val >= rightVal) {
      const newVal = parseInt(rightVal) - 1;
      setLeftVal(newVal);
      rangeLeftRef.current.value = newVal;
    } else {
      setLeftVal(val);
    }

    const { min, max, value } = e.target;
    setHandleLeft(getPercentLeftOffset(min, max, value));
  };

  const setRightValue = (e) => {
    const val = parseInt(e.target.value);
    if (val <= leftVal) {
      const newVal = parseInt(leftVal) + 1;
      setRightVal(newVal);
      rangeRightRef.current.value = newVal;
    } else {
      setRightVal(val);
    }
    const { min, max, value } = e.target;
    setHandleRight(100 - getPercentLeftOffset(min, max, value));
  };

  const handleDateRange = () => {
    const startDate = timelineArray.options[leftVal].date;
    const endDate = timelineArray.options[parseInt(rightVal) - 1].date;
    const query = { start: startDate, end: endDate };

    // Todo: handle date change
    console.log(query);
  };

  if (!timelineArray.options) return null;

  return (
    <SliderContainer>
      <input
        ref={rangeLeftRef}
        type="range"
        list="tickmarks"
        id="input-left"
        min={minValue.current}
        max={maxValue.current}
        defaultValue={leftVal}
        onChange={(e) => setLeftValue(e)}
        onMouseUp={() => handleDateRange()}
      />
      <input
        ref={rangeRightRef}
        type="range"
        list="tickmarks"
        id="input-right"
        min={minValue.current}
        max={maxValue.current}
        defaultValue={rightVal}
        onChange={(e) => setRightValue(e)}
        onMouseUp={() => handleDateRange()}
      />

      <RangeSlider>
        <Track />
        <Range left={handleLeft} right={handleRight} />
        <Handle left={handleLeft} className="left" />
        <Handle right={handleRight} className="right" />
        <RangeLabels>
          {timelineArray.months.map((month) => (
            <li style={month.styles} key={month.label}>
              <MonthTick />
              <MonthLabel>{month.label}</MonthLabel>
            </li>
          ))}
        </RangeLabels>
        <RangeLabels>
          {timelineArray.options.map((tick) => (
            <li style={tick.styles} key={tick.date}>
              <Tick />
              <WeekLabel>{tick.label}</WeekLabel>
            </li>
          ))}
        </RangeLabels>
      </RangeSlider>
    </SliderContainer>
  );
});

Timeline.propTypes = {
  theme: PropTypes.shape({
    donutChart: PropTypes.instanceOf(Object),
  }),
};

Timeline.defaultProps = {
  theme: null,
};
