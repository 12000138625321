import * as PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, ButtonGroup } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';
import { ContentType } from '@ge/models/constants';
import { RadioGroupMetaField, CheckBoxGroupMetaField } from '@ge/shared/components/meta-fields';

const FieldNames = { EXPORT_TYPE: 'exportType', GRAPHS: 'graphs' };

const EXPORT_TYPES = [
  { id: 'CSV', value: ContentType.CSV },
  { id: 'PNG', value: ContentType.PNG },
];

const DialogSegment = styled.div`
  margin-bottom: 15px;
`;

const StyledSubText = styled.i`
  color: ${(props) => props.theme.entityDetails.battery.textColor};
  font-size: 10px;
  line-height: 12px;
  font-style: italic;
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
`;

const StyledError = styled.div`
  color: ${({ theme }) => theme.fileUpload.errorTextColor};
`;

const Footer = ({ onCancel, onExport, disableSubmit }) => {
  return (
    <StyledButtonGroup>
      <Button onClick={onCancel}>Cancel</Button>
      <Button disabled={disableSubmit} onClick={onExport} primary>
        Export
      </Button>
    </StyledButtonGroup>
  );
};

Footer.propTypes = {
  onCancel: PropTypes.func,
  onExport: PropTypes.func,
  disableSubmit: PropTypes.bool,
};

export const ExportGraphModal = ({ isOpen, onClose, onSubmit, graphs, enablePng }) => {
  const { t, ready } = useTranslation(['analyze.dashboard']);

  const exportTypes = useMemo(
    () =>
      EXPORT_TYPES.map(({ id, value }) => ({ id, value, label: t(`file_types.${id}`, id) })).filter(
        (type) => {
          if (enablePng) return true;
          return type.id !== 'PNG';
        },
      ),
    [enablePng, t],
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      [FieldNames.EXPORT_TYPE]: exportTypes[0].value,
      [FieldNames.GRAPHS]: [],
    },
  });

  const { watch, handleSubmit, formState } = methods;

  const watchGraphFields = watch(FieldNames.GRAPHS);

  const graphsOptions = useMemo(
    () =>
      graphs.map(({ id, title, label, metadata = {} }) => {
        return {
          id,
          value: id,
          label: label ? `${title} > ${label}` : title,
          disabled: metadata.disabled,
        };
      }),
    [graphs],
  );

  if (!ready) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={t('export_modal.title', 'Export')}
      footer={
        <Footer
          onCancel={onClose}
          onExport={handleSubmit(onSubmit)}
          disableSubmit={!watchGraphFields?.length}
        />
      }
    >
      <FormProvider {...methods}>
        <DialogSegment>
          <h4>{t('export_modal.select_format', 'Select Format').toUpperCase()}</h4>
          <RadioGroupMetaField
            name={FieldNames.EXPORT_TYPE}
            defaultValue={exportTypes[0].value}
            choices={exportTypes}
            margin={`10px 40px 16px 0px`}
          />
        </DialogSegment>
        <DialogSegment>
          <TitleContainer>
            <h4>{t('export_modal.select_data', 'Select Charts to Include').toUpperCase()}</h4>
            <StyledSubText>
              {t(
                'export_modal.select_data_subtext',
                'Individual files for each chart will be created.',
              )}
            </StyledSubText>
          </TitleContainer>
          <CheckBoxGroupMetaField
            name={FieldNames.GRAPHS}
            choices={graphsOptions}
            required={true}
            margin={'0px 0px 10px 0px'}
          />
          {formState.errors[FieldNames.GRAPHS] && (
            <StyledError>
              {t('export_modal.checkbox_error', 'Please select at least one graph.')}
            </StyledError>
          )}
        </DialogSegment>
      </FormProvider>
    </Dialog>
  );
};

ExportGraphModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  enablePng: PropTypes.bool,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      metadata: PropTypes.object,
    }),
  ),
};

export default ExportGraphModal;
