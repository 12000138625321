import { useStoreState } from 'easy-peasy';
import { useMemo, useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import {
  QueryKey,
  MonitorCaseStatus,
  AssetStatus,
  MonitorCaseRibbonFilterTypes,
} from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { getActiveCaseCount } from '../services/cases';

export const useActiveCaseCount = ({ isEnable }) => {
  const [filterData, setFilterData] = useState([]);
  const { currentView } = useStoreState((state) => state.view);
  const { data, error, isLoading } = useQuery(
    [QueryKey.ACTIVE_CASE_COUNT, currentView],
    getActiveCaseCount,
    {
      // To avoid the call for anomalies
      refetchInterval: isEnable ? Config.REFRESH.realTime : false,
      enabled: isEnable,
    },
  );

  const updateRibbonFilterCounts = useCallback(
    (casesData) => {
      const newData = [];
      if (Array.isArray(data)) {
        newData.push(...data);
      }

      if (Array.isArray(casesData)) {
        // New and Returned ribbon filter
        let newAndReturned = casesData.filter(
          (item) =>
            item.caseState === MonitorCaseStatus.NEW ||
            item.caseState === MonitorCaseStatus.RETURNED,
        );
        newData.push({
          type: MonitorCaseRibbonFilterTypes.NEW_AND_RETURNED,
          caseCount: newAndReturned?.length,
        });

        // Station Check ribbon filter
        let stationCheck = casesData.filter(
          (item) =>
            item.caseState === MonitorCaseStatus.IN_PROGRESS &&
            item?.asset?.metrics?.state?.value !== AssetStatus.REPAIR &&
            item?.asset?.metrics?.state?.value !== AssetStatus.MAINTENANCE,
        );
        newData.push({
          type: MonitorCaseRibbonFilterTypes.STATION_CHECK,
          caseCount: stationCheck?.length,
        });

        // Second Look ribbon filter
        let secondLook = casesData.filter(
          (item) =>
            item.caseState === MonitorCaseStatus.IN_PROGRESS &&
            item?.asset?.metrics?.state?.value === AssetStatus.TRIPPED,
        );
        newData.push({
          type: MonitorCaseRibbonFilterTypes.SECOND_LOOK,
          caseCount: secondLook?.length,
        });
      }

      setFilterData(newData);
    },
    [data],
  );

  const response = useMemo(() => {
    if (!filterData?.length) return [];
    return filterData.map((element) => {
      return {
        isLoading: false,
        id: element.type,
        label: element.type?.replaceAll('_', ' '),
        i18nKey: `ribbon_menu.${element.type?.toLowerCase()}`,
        count: element.caseCount,
        type: element.type,
      };
    });
  }, [filterData]);

  return {
    isLoading,
    data: response,
    error,
    updateRibbonFilterCounts,
  };
};
