import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Timing } from '../../../task-fields/timing';
import { FormSection } from '../../../task-template-shared';

export const CreateTiming = ({ metadata }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <FormSection>
      <h4>{t('section_header.timing', 'Timing')}</h4>
      {!metadata?.timePeriods.hidden && <Timing metadata={metadata.timePeriods.create} />}
    </FormSection>
  );
};

CreateTiming.propTypes = {
  collapsed: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
};

CreateTiming.defaultProps = {
  collapsed: true,
};
