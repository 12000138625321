import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';

const ViewMoreIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.alertDialog.expandButtonColor,
}))`
  margin: 3px;
  font-weight: bold;
`;

const scrollbarHeight = `
    .simplebar-content-wrapper {
      height: 100% !important;
    }
  `;

const ScrollingBar = styled.div`
  display: flex;
  min-height: 5vh;
  overflow: hidden;
  [data-simplebar='init'] {
    padding-right: 10px;
  }
  ${scrollbarHeight}
`;

const StyledDiv = styled.div`
  .see-more {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    display: inline;
  }
  .toggle-icon {
    padding: 2px;
    cursor: pointer;
    color: ${(props) => props.theme.alertDialog.expandButtonColor};
    margin-left: 7px;
  }
`;

export const SeeMore = ({ children, charLimit, className }) => {
  const text = children;
  const { t } = useTranslation(['entity-details']);
  const [isSeeMore, setIsSeeMore] = useState(false);

  const toggleSeeMore = useCallback(() => {
    setIsSeeMore(!isSeeMore);
  }, [isSeeMore]);

  return (
    <StyledDiv className={className}>
      <ScrollingBar>
        <ScrollingContainer>
          <span>
            {isSeeMore ? (
              <span className="bulk-assets">{text}</span>
            ) : (
              <span>
                {text.slice(0, charLimit)}
                {text.length > charLimit && ' ...'}
              </span>
            )}
          </span>
          <span onClick={toggleSeeMore} className="toggle-icon">
            {text.length > charLimit &&
              (isSeeMore ? (
                <>
                  <ViewMoreIcon rotate={-180} />
                  <span>{t('escalate_case.show_less', 'show less')}</span>
                </>
              ) : (
                <>
                  <ViewMoreIcon /> <span>{t('escalate_case.show_more', 'show more')}</span>
                </>
              ))}
          </span>
        </ScrollingContainer>
      </ScrollingBar>
    </StyledDiv>
  );
};

SeeMore.propTypes = {
  children: PropTypes.string,
  charLimit: PropTypes.number,
  className: PropTypes.string,
};

SeeMore.defaultProps = {
  charLimit: 55,
  children: '',
  className: '',
};
