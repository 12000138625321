import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { Table, Tbody, Td, Thead, Th } from '@ge/components/table';

import FPO from './images/turbine_components.png';

const Container = styled.div``;

const CardContainer = styled.div`
  border-radius: 6px;
  background: ${(props) => props.theme.analyze.troubleshooting.card.background};
  overflow: hidden;
  padding-bottom: 16px;
  margin-bottom: 4px;
  tr:hover,
  tr.active {
    background: ${(props) => props.theme.table.hoverBackground};
  }
  tr td {
    border-bottom: solid 1px ${(props) => props.theme.analyze.troubleshooting.card.borderColor};
  }
  tr:first-child td {
    border-top: solid 1px ${(props) => props.theme.analyze.troubleshooting.card.borderColor};
  }
  &:first-of-type {
    .title button {
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 13px;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    button {
      color: currentcolor;
      padding: 0;
      text-transform: capitalize;
      &.bold {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
`;

const AssetGraphicContainer = styled.div`
  margin: 8px 8px 0;
  background-image: url(${FPO});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 16px);
  height: 450px;
`;

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.table.troubleshootingIconColor,
}))``;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))``;

export const TroubleshootingSidebar = withTheme(({ ...props }) => {
  const {
    anomaly: { components },
    issues,
    selectComponent,
    theme: {
      analyze: { troubleshooting },
    },
  } = props;

  const { t } = useTranslation(['analyze.troubleshooting'], {
    useSuspense: false,
  });
  const [selectedId, setSelectedId] = useState(components[0].id);

  return (
    <Container>
      {issues && (
        <CardContainer>
          <div className="title">
            <h4>{t('linked_issues', 'LINKED ISSUES')}</h4>
            <button type="button" className="bold">
              {`+ ${t('add_issue', 'add issue')}`}
            </button>
          </div>
          <Table>
            <Tbody transparent>
              {issues.map((issue) => (
                <tr key={issue.id}>
                  <Td>
                    <TypeIcon icon={issue.issueType === 'anomaly' ? Icons.ANOMALY : Icons.EVENT} />
                  </Td>
                  <Td>{issue.issueType === 'anomaly' ? issue.externalId : issue.code}</Td>
                  <Td align="left">{issue.description}</Td>
                  <Td>{dayjs(issue.start).format('DD/MM/YY')}</Td>
                </tr>
              ))}
            </Tbody>
          </Table>
        </CardContainer>
      )}

      <CardContainer>
        <div className="title">
          <h4>{t('affected_components', 'Affected Components')}</h4>
          <button type="button">{t('see_all', 'see all')}</button>
        </div>
        <Table backgroundColor={troubleshooting.card.background}>
          <Thead transparent>
            <tr>
              <Th align="left">{t('component', 'component')}</Th>
              <Th align="left">{t('rem_useful_life', 'Rem. /n Useful Life')}</Th>
              <Th align="left">{t('lower_upper_bound', 'Lower/Upper Bound')}</Th>
              <Th>{t('influence', 'Influence')}</Th>
              <Th>{t('cases', 'Cases')}</Th>
            </tr>
          </Thead>
          <Tbody transparent>
            {components.map((component) => (
              <tr
                key={component.id}
                onClick={() => {
                  selectComponent(component);
                  setSelectedId(component.id);
                }}
                className={selectedId === component.id ? 'active' : null}
              >
                <Td align="left">{component.shortName}</Td>
                <Td>{component.life}</Td>
                <Td>{`${component.boundLower} / ${component.boundUpper}`}</Td>
                <Td>{component.influence}</Td>
                <Td>
                  <StyledBadge label={`${component.cases.length}`} medium />
                </Td>
              </tr>
            ))}
          </Tbody>
        </Table>
        <AssetGraphicContainer />
      </CardContainer>
    </Container>
  );
});

TroubleshootingSidebar.propTypes = {
  theme: PropTypes.instanceOf(Object),
  anomaly: PropTypes.instanceOf(Object),
  issues: PropTypes.instanceOf(Object),
  selectComponent: PropTypes.func,
};

TroubleshootingSidebar.defaultProps = {
  theme: null,
  anomaly: null,
  issues: null,
  selectComponent: () => {},
};
