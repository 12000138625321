import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { QueryKey } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { AppScopes } from '@ge/shared/models/scopes';
import * as request from '@ge/shared/services/api';
import { dedupArray } from '@ge/shared/util/general';
import { sorter } from '@ge/util/metric-sorter';
import { findScopesByRoute } from '@ge/web-client/src/app/services/feature';

import { useTenants } from './use-tenants';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchAllPeople = (pageParam = 0, pageSize = 700) => {
  let params = { pageIdx: pageParam, pageSize };

  return request.get('/cmn/allpeople', {
    baseURL: USERS_BASE_URL,
    params,
  });
};

export const useFindPerson = (id) => {
  const { workers } = useStoreState((state) => state.workers);
  const path = useLocation().pathname;
  const appScope = findScopesByRoute(path);
  const scopePathOverview = AppScopes.MANAGE_OVERVIEW;
  const queryClient = useQueryClient();

  if (appScope === scopePathOverview) {
    let found = workers.find((worker) => worker.username === id);
    return found;
  } else {
    let loadedPeople = [];
    const data = queryClient.getQueryData([QueryKey.INFINITE_PEOPLE]);
    let allData = data.pages.map((page) => page.users.map((user) => user));
    for (let i = 0; i < allData.length; i++) {
      loadedPeople.push(...allData[i]);
    }
    let found = loadedPeople.find((person) => person.username === id);
    return found;
  }
};

export const useInfinitePeopleTable = ({
  filters,
  search,
  sortMetric,
  sortDirection,
  tenantId,
}) => {
  const { data: allTenants, applyTenantName } = useTenants();
  const {
    data: allPeople,
    fetchNextPage,
    error,
    isFetching: isfetchingAll,
    status,
    hasNextPage,
  } = useInfiniteQuery(
    [QueryKey.INFINITE_PEOPLE],
    ({ pageParam }) => {
      return fetchAllPeople(pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < lastPage.totalPages - 1) return lastPage.currentPage + 1;
        return false;
      },
      refetchOnWindowFocus: false,
    },
  );

  const mappedPeople = useMemo(() => {
    let collectedPeople = [];
    if (allTenants && allPeople) {
      const formattedPeople = allPeople.pages.map((page) =>
        page.users.map((user) => {
          return {
            tenant: {
              id: user.tenantId,
              name: applyTenantName(user.tenantId),
            },
            roles: user.attributes?.entity_group?.map((p) => p.roleName.replace(/[_-]/g, ' ')),
            name: `${user.firstName} ${user.lastName}`,
            userId: user.username,
            email: user.email,
            status: user.status === 0 ? 'Inactive' : user.status === 1 ? 'Active' : 'Suspended',
            lastLogin: '2021-10-05T02:52:12.470Z',
            allowAccess: true,
          };
        }),
      );
      for (let i = 0; i < formattedPeople.length; i++) {
        collectedPeople.push(...formattedPeople[i]);
      }
      return collectedPeople
        ?.sort(sorter(sortMetric, sortDirection))
        .filter((person) => (tenantId ? person.tenant.id === tenantId : true));
    }
  }, [allPeople, allTenants, applyTenantName, sortDirection, sortMetric, tenantId]);

  const { data, filterValues } = useTableFilter({ data: mappedPeople, filters, search });

  const mappedFilters = {
    ...filterValues,
    roles: dedupArray(filterValues?.roles?.flat()),
  };

  return {
    data: { data, filterValues: mappedFilters },
    allPeople,
    fetchNextPage,
    error,
    isFetching: isfetchingAll,
    status,
    hasNextPage,
  };
};
