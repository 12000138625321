/* eslint-disable react/jsx-key */
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';

import { StackCard } from '@ge/feat-manage/components/planning/month/cards/calendar-month-stack';
import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { getBundleInfo } from '@ge/feat-manage/util/bundle-util';
import { TasksPriority } from '@ge/models/constants';

import { DayCardSingle } from './cards/day-card-single';

export const CalendarDayCard = ({
  tasks,
  sidebarWidth,
  laneIndex,
  secondaryIndex,
  itemIndex,
  onDrop,
  onClick,
  draggable,
  top,
  date,
}) => {
  const {
    planningState: { handleDragStart },
  } = usePlanningContext();

  const dayTasks = useMemo(() => {
    if (!tasks?.length) return [];

    //categorize tasks with same startTime as stack
    return tasks
      .reduce((acc, task) => {
        let t = task.startTime.split(':');
        let key = Number(t[0]) + Number(t[1]);
        if (acc[key] === undefined) {
          acc[key] = {
            data: [],
          };
        }

        acc[key].data.push(task);

        return acc;
      }, [])
      .map(({ data }) => {
        //sorting tasks based on priority
        data.sort(function (a, b) {
          let x = TasksPriority.indexOf(a.priority);
          let y = TasksPriority.indexOf(b.priority);
          //-1 for asc and 1 for desc
          let order = 1;
          if (x < y) return -1 * order;
          if (x > y) return 1 * order;
          return 0;
        });

        return { data, bundleInfo: getBundleInfo(data, date) };
      });
  }, [tasks, date]);

  const calculateWidth = useCallback((hours, minutes, startTime) => {
    const maxWidth = 120 * 24;
    if (hours == 0 && minutes == 0) {
      return 240;
    }
    //if overflows : subtract overflowing width
    if (startTime !== undefined) {
      let t = startTime.split(':');
      let pos = Number(t[0]) * 120 + Number(t[1] * 2);
      let width = hours * 120 + minutes * 2;
      let x = pos + width;
      if (x > maxWidth) {
        let overflow = pos + width - maxWidth;
        return width - overflow;
      } else {
        return width;
      }
    }
  }, []);

  const calculatePosition = useCallback(
    (startTime) => {
      if (startTime == undefined) {
        return 120;
      }
      let t = startTime.split(':');
      return sidebarWidth + 120 * t[0] + t[1] * 2;
    },
    [sidebarWidth],
  );

  if (!tasks?.length) return null;

  return dayTasks.map(({ data, bundleInfo }) => {
    const sameAssetTasks = data.length == bundleInfo.sameAssetTaskCount ? true : false;
    const partialCompleted =
      bundleInfo.completedTasks != 0 && data.length !== bundleInfo.completedTasks ? true : false;

    const position = calculatePosition(data[0].startTime);
    const width = calculateWidth(
      data[0].estDurationHours,
      data[0].estDurationMinutes,
      data[0].startTime,
    );

    if (data.length > 1 && !sameAssetTasks) {
      return (
        <StackCard
          tasks={data}
          top={top}
          position={position}
          width={width}
          laneIndex={laneIndex}
          secondaryIndex={secondaryIndex}
          itemIndex={itemIndex}
          handleDragStart={handleDragStart}
          onDrop={onDrop}
          onClick={onClick}
          draggable={draggable}
          bundlePresent={bundleInfo.bundlePresent}
          date={date}
        />
      );
    } else {
      //take task based on precedence here
      const task = data[0];
      return (
        <DayCardSingle
          task={task}
          tasks={data}
          top={top}
          position={position}
          width={width}
          draggable={draggable}
          onDrop={onDrop}
          onClick={onClick}
          laneIndex={laneIndex}
          secondaryIndex={secondaryIndex}
          itemIndex={itemIndex}
          bundlePresent={bundleInfo.bundlePresent}
          bundleCount={bundleInfo.bundleCount}
          partialCompleted={partialCompleted}
          sameAssetTaskCount={bundleInfo.sameAssetTaskCount}
          date={date}
        ></DayCardSingle>
      );
    }
  });
};

CalendarDayCard.propTypes = {
  tasks: PropTypes.instanceOf(Array),
  date: PropTypes.instanceOf(Object), // dayjs
  sidebarWidth: PropTypes.number,
  top: PropTypes.number,
  laneIndex: PropTypes.number.isRequired,
  secondaryIndex: PropTypes.number,
  itemIndex: PropTypes.number.isRequired,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
};

CalendarDayCard.defaultProps = {
  count: null,
  sidebarWidth: null,
  top: null,
  onDrop: () => {},
  onClick: () => {},
  secondaryIndex: null,
  draggable: false,
};
