export const AnalyzeLocators = {
  // Date selection locators
  ANALYZE_DATE_LABELS: 'analyze-date-labels',
  ANALYZE_DATE_FILTER_CONTAINER: 'analyze-date-filter-container',

  // All view header locators
  ANALYZE_HEADER_BACK_BUTTON: 'analyze-header-back-button',
  ANALYZE_KPIBAR_HEADER: 'analyze-kpibar-header',
  ANALYZE_HEADER_HIGHER_VIEW_LINK: 'analyze-header-higher-view-link',

  // KPI header bar locators
  ANALYZE_KPIBAR_EMPTY_VALUE: 'analyze-kpibar-empty-value',
  ANALYZE_KPIBAR_VALID_VALUE: 'analyze-kpibar-valid-value',
  ANALYZE_KPIBAR_NAME: 'analyze-kpibar-name-',
  ANALYZE_KPIBAR_VALUE: 'analyze-kpibar-value-',
  ANALYZE_KPIBAR_KPI_LIST: 'analyze-kpibar-kpi-list',

  ANALYZE_TOGGLE_BUTTON_CONTAINER: 'analyze-toggle-button-container',
  ANALYZE_TOGGLE_BUTTON: 'analyze-toggle-button-',
  ANALYZE_CHART_DATA: 'analyze-chart-data-',

  ANALYZE_KPI_CHART_DATA_WRAP: 'analyze-kpi-chart-data-wrap',
  ANALYZE_MIRROR_CHART_DATA_WRAP: 'analyze-mirror-chart-data-wrap',
  ANALYZE_KPI_SELECTS_WRAPPER: 'analyze-kpi-selects-wrapper',

  ANALYZE_IEC_GRAPH_TOTAL_WRAP: 'analyze-iec-graph-total-wrap',
  ANALYZE_IEC_HOVER_TABLE: 'analyze-iec-hover-table',
  ANALYZE_IEC_HOVER: 'analyze-iec-hover',

  // graph title locators
  ANALYZE_IEC_GRAPH_TITLE: 'analyze-iec-graph-title',
  ANALYZE_KPI_CHART_TITLE: 'analyze-kpi-chart-title',
  ANALYZE_CONDITION_GRAPH_TITLE: 'analyze-condition-graph-title',
  ANALYZE_SCATTER_CHART_TITLE: 'analyze-scatter-chart-title',
  ANALYZE_KPI_SITE_OVERVIEW_CHART_TITLE: 'analyze-kpi-site-overview-chart-title',
  ANALYZE_KPI_ASSET_OVERVIEW_CHART_TITLE: 'analyze-kpi-asset-overview-chart-title',

  // Site view graph/table locators
  ANALYZE_SITE_TOP_GRAPHS: 'analyze-site-top-graphs',
  ANALYZE_SITE_MIRROR_GRAPH: 'analyze-site-mirror-graph',
  ANALYZE_SITE_SCATTER_GRAPH: 'analyze-site-scatter-graph',
  ANALYZE_SITE_BOTTOM_GRAPHS: 'analyze-site-bottom-graphs',
  ANALYZE_SITE_IEC_GRAPH: 'analyze-site-iec-graph',
  ANALYZE_SITE_CONDITION_GRAPH: 'analyze-site-condition-graph',
  ANALYZE_ASSET_ANOMALY_WRAPPER: 'analyze-asset-anomaly-wrapper',
  ANALYZE_ASSET_ANOMALY_TABLE: 'analyze-asset-anomaly-table',

  // Region view graph/table locators
  ANALYZE_REGION_GRAPHS_CONTAINER: 'analyze-region-graphs-container',
  ANALYZE_REGION_KPI_GRAPH: 'analyze-region-kpi-graph',
  ANALYZE_REGION_IEC_GRAPH: 'analyze-region-iec-graph',
  ANALYZE_SITE_TABLE_WRAPPER: 'analyze-site-table-wrapper',

  //Asset view header, ribbon locators
  ANALYZE_ASSET_RIBBON_CONTAINER: 'analyze-asset-ribbon-container',
  ANALYZE_ADD_ASSET_BUTTON: 'analyze-add-asset-button',
  ANALYZE_ASSET_LIST_WRAP: 'analyze-asset-list-wrap',
  ANALYZE_ASSET_SELECTION_BAR: 'analyze-asset-selection-bar',
  ANALYZE_ASSET_REMOVE_BUTTON: 'analyze-asset-remove-button',
  ANALYZE_ADDED_ASSET: 'analyze-added-asset-',
  ANALYZE_ASSET_RIBBON_ASSETNAME: 'analyze-asset-ribbon-assetname',

  //Asset view graph locators
  ANALYZE_ASSET_DATE_FILTER_WRAP: 'analyze-asset-date-filter-wrap',
  ANALYZE_ASSET_DONUT_CHART_WRAP: 'analyze-asset-donut-chart-wrap',
  ANALYZE_ASSET_POWER_CURVE_WRAP: 'analyze-asset-power-curve-wrap',
  ANALYZE_ASSET_KPI_CHART_WRAP: 'analyze-asset-kpi-chart-wrap',
  ANALYZE_ASSET_CONDITION_CHART_WRAP: 'analyze-asset-condition-chart-wrap',
};

export const CommonLocators = {
  // Donut chart locators
  COMMON_DONUT_CHART_HEADER: 'common-donut-chart-header',
  COMMON_DONUT_CHART_WRAPPER: 'common-donut-chart-wrapper',
  COMMON_DONUT_CHART_RING: 'common-donut-chart-ring',
  COMMON_DONUT_CHART_TABLE: 'common-donut-chart-table',

  // Legend locator
  COMMON_GRAPHS_LEGEND_WRAP: 'common-graphs-legend-wrap',

  // Pareto chart locators
  COMMON_PARETO_LABEL: 'common-pareto-label-',
  COMMON_PARETO_COLOUR_BAR: 'common-pareto-colour-bar',
  COMMON_PARETO_BAR_PERCENT: 'common-pareto-bar-percent',
  COMMON_PARETO_ROW: 'common-pareto-row-',
  COMMON_PARETO_DATA_CONTAINER: 'common-pareto-data-container',
  COMMON_PARETO_XAXIS: 'common-pareto-xaxis-',

  // Date picker locator
  COMMON_DATE_PICKER: 'common-date-picker',

  // Drop down locator
  COMMON_SELECT_WRAPPER: 'common-select-wrapper',

  //Dynamic table locators
  COMMON_DYNAMIC_TABLE_HEADER_ROW: 'common-dynamic-table-header-row',
  COMMON_DYNAMIC_TABLE_BODY: 'common-dynamic-table-body',

  //table locators
  COMMON_TABLE_HEADER_SPAN: 'common-table-header-span',
  COMMON_TABLE_HEADER_SUB_DIV: 'common-table-header-sub-div',
};

export const MonitorLocators = {};

export const ExecuteLocators = {};
