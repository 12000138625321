import { useStoreActions } from 'easy-peasy';
import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { createCase, createCases } from '@ge/feat-monitor/services';

import { updateCasesCache } from './use-update-cases';

/**
 * Create a case.
 *
 * @param opts useMutation options
 * @returns {{create: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useCreateCase = () => {
  // Manage lifecycle with react-query mutation
  const queryClient = useQueryClient();
  const { fetchCaseWithDetails } = useStoreActions((actions) => actions.cases);

  const { mutateAsync: create, isLoading, isError, error } = useMutation(
    (input_data) => createCase(input_data),
    {
      onSuccess: (respData) => {
        if (respData) {
          updateCasesCache(queryClient, [respData]);
        }
        return fetchCaseWithDetails(respData.id);
      },
    },
  );
  return useMemo(() => ({ create, isLoading, isError, error }), [
    create,
    error,
    isError,
    isLoading,
  ]);
};

/**
 * Create a cases.
 *
 * @returns {{create: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useCreateCases = () => {
  const queryClient = useQueryClient();
  const { updateCases } = useStoreActions((actions) => actions.cases);
  const { mutateAsync: create, isLoading, isError, error } = useMutation(createCases, {
    onSuccess: (res) => {
      if (res?.cases?.length) {
        updateCasesCache(queryClient, res.cases);
      }
      return updateCases(res?.cases ?? []);
    },
  });

  return { create, isLoading, isError, error };
};
