import { ChartType } from './chart-type';

export const columnGroupChartOptions = {
  series: [
    {
      borderRadiusTopLeft: '50%',
      borderRadiusTopRight: '50%',
      // setting to 100 per discussion with dave le
      maxPointWidth: 100,
      type: ChartType.COLUMN,
    },
  ],
  xAxis: {
    tickmarkPlacement: 'between',
  },
};
