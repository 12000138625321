import { useStoreActions, useStoreState } from 'easy-peasy';
import { useQueries, useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { Config } from './config';
import { useAuth } from './use-auth';

const STALE_THRESHOLD = 1000 * 60 * 60 * 4; // 4 hours
const withinThreshold = (updatedAt) => new Date().getTime() - updatedAt < STALE_THRESHOLD;

export const useStaticData = () => {
  // we use auth with static data since it is loaded early in the app lifecycle
  const { isAuthenticated } = useAuth();

  // store
  const appState = useStoreState((state) => state.app);

  // TODO: remove these fetches once we cut everything over to data hooks
  const { fetchRegions } = useStoreActions((actions) => actions.regions);
  const { lastUpdated: regionsLastUpdated } = useStoreState((state) => state.regions);
  const { fetchServiceGroups, fetchSites, getServiceGroupsWithFullSites } = useStoreActions(
    (actions) => actions.sites,
  );
  const {
    sitesLastUpdated,
    serviceGroupsLastUpdated,
    serviceGroupsWithFullSitesUpdated,
  } = useStoreState((state) => state.sites);
  const { fetchSiteControllers, fetchSubstations } = useStoreActions((actions) => actions.assets);
  const { siteControllerLastUpdated, subStationLastUpdated } = useStoreState(
    (state) => state.assets,
  );

  const fetches = {
    [QueryKey.REGIONS]: {
      queryFn: fetchRegions,
      enabled: !withinThreshold(regionsLastUpdated),
    },
    [QueryKey.SERVICE_GROUPS]: {
      queryFn: fetchServiceGroups,
      enabled: !withinThreshold(serviceGroupsLastUpdated),
    },
    [QueryKey.SITE_CONTROLLERS]: {
      queryFn: fetchSiteControllers,
      enabled: !withinThreshold(siteControllerLastUpdated),
    },
    [QueryKey.SITES]: {
      queryFn: fetchSites,
      enabled: !withinThreshold(sitesLastUpdated),
    },
    [QueryKey.SUBSTATIONS]: {
      queryFn: fetchSubstations,
      enabled: !withinThreshold(subStationLastUpdated),
    },
  };

  const queries = useQueries(
    Object.entries(fetches).map(([queryKey, { queryFn, enabled = true }]) => ({
      queryKey,
      queryFn,
      ...Config.EXECUTE_ONCE,
      // only fetch if authenticated, not loaded, and not in a popout
      enabled: enabled && isAuthenticated() && !appState.isPopout,
      retry: 1,
    })),
  );

  // currently sets isLoading to false if *all* fetches return
  const isLoading = queries.some((query) => query.isLoading);
  // bypass data loading logic if not authenticated since no data will be loaded
  const isLoaded = !isAuthenticated() || !isLoading;

  useQuery(QueryKey.INIT_FULL_SERVICE_GROUPS, () => getServiceGroupsWithFullSites(), {
    enabled:
      !isLoading &&
      !withinThreshold(serviceGroupsWithFullSitesUpdated) &&
      isAuthenticated() &&
      !appState.isPopout,
    ...Config.EXECUTE_ONCE,
  });

  return { isLoaded };
};
