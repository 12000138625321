/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow weak
import Paper from '@material-ui/core/Paper';
import LockIcon from '@material-ui/icons/Lock';
import React from 'react';
import styled from 'styled-components';

import { AnnotatorConstants } from '../../constants';
import DefaultRegionLabel from '../region-label/region-label';

const copyWithout = (obj, ...args) => {
  const newObj = { ...obj };
  for (const arg of args) {
    delete newObj[arg];
  }
  return newObj;
};

const RegionTagLockContainer = styled.div`
  position: absolute;
  left: ${(props) => props?.coords?.left || 0}px;
  top: ${(props) => props?.coords?.top || 0}px;
  z-index: ${(props) => (props.editingLabels ? 15 : 10)};
  width: ${(props) => (props.locked ? 200 : 0)}px;
`;

const TagContiner = styled.div`
  position: absolute;
  z-index: 20;
  left: 0;
  ${(props) => (props.displayOnTop ? 'bottom: 0;' : 'top: 0;')}
`;

const LockedTag = styled(Paper)`
  position: absolute;
  left: 0;
  ${(props) => (props.displayOnTop ? 'bottom: 0;' : 'top: 0;')}
  z-index: 10;
  background-color: #fff;
  border-radius: 4;
  padding: 2;
  padding-bottom: 0;
  opacity: 0.5;
  pointer-events: none;
`;

export const RegionTags = ({
  regions,
  projectRegionBox,
  mouseEvents,
  regionClsList,
  regionTagList,
  onBeginRegionEdit,
  onChangeRegion,
  onCloseRegionEdit,
  onDeleteRegion,
  imageSrc,
  RegionEditLabel,
  onRegionClassAdded,
  onRegionSelect,
  selectedAnnotationId,
  isLoading,
}) => {
  const RegionLabel = RegionEditLabel != null ? RegionEditLabel : DefaultRegionLabel;
  return (
    regions
      // .filter((r) => r.visible || r.visible === undefined)
      .filter((r) => !r.isAnnotationHidden)
      .map((region) => {
        const pbox = projectRegionBox(region);
        let margin = 8;
        if (region.highlighted && region.type === AnnotatorConstants.BOX) margin += 6;
        const labelBoxHeight = region.editingLabels && !region.locked ? 170 : region.tags ? 60 : 50;
        const displayOnTop = pbox.y > labelBoxHeight;

        const coords = displayOnTop
          ? {
              left: pbox.x,
              top: pbox.y - margin / 2,
            }
          : { left: pbox.x, top: pbox.y + pbox.h + margin / 2 };
        if (region.locked) {
          return (
            <RegionTagLockContainer
              key={region.id}
              coords={coords}
              editingLabels={region.editingLabels}
              locked={region.locked}
            >
              <LockedTag displayOnTop={displayOnTop}>
                <LockIcon style={{ width: 16, height: 16, color: '#333' }} />
              </LockedTag>
            </RegionTagLockContainer>
          );
        }
        return (
          region.type !== AnnotatorConstants.RULER && (
            <RegionTagLockContainer
              key={region.id}
              coords={coords}
              editingLabels={region.editingLabels}
              locked={region.locked}
              onMouseDown={(e) => e.preventDefault()}
              onMouseUp={(e) => e.preventDefault()}
              onMouseEnter={(e) => {
                if (region.editingLabels) {
                  mouseEvents.onMouseUp(e);
                  e.button = 1;
                  mouseEvents.onMouseUp(e);
                }
              }}
            >
              <TagContiner
                displayOnTop={displayOnTop}
                {...(!region.editingLabels
                  ? copyWithout(mouseEvents, 'onMouseDown', 'onMouseUp')
                  : {})}
              >
                <RegionLabel
                  allowedClasses={regionClsList}
                  allowedTags={regionTagList}
                  onOpen={onBeginRegionEdit}
                  onChange={onChangeRegion}
                  onClose={onCloseRegionEdit}
                  onDelete={onDeleteRegion}
                  editing={false}
                  region={region}
                  regions={regions}
                  imageSrc={imageSrc}
                  onRegionClassAdded={onRegionClassAdded}
                  onSelectRegion={onRegionSelect}
                  selectedAnnotationId={selectedAnnotationId}
                  isLoading={isLoading}
                />
              </TagContiner>
            </RegionTagLockContainer>
          )
        );
      })
  );
};

export default RegionTags;
