import { PropTypes } from 'prop-types';
import mergeDeepRight from 'ramda/src/mergeDeepRight';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Input } from '@ge/components/input';
import { Pattern } from '@ge/models/constants';
import { StatusColor } from '@ge/tokens/colors/colors';

import { RECIPIENT_DELIMITER } from '../alert-shared';

// storing internal error with _ prefix to avoid collisions with errors on main form
const RECIPIENT_EMAIL_ERROR_KEY = '_email';

const delimiterRegex = new RegExp(`(.*)${RECIPIENT_DELIMITER}`);

const resetEmailError = ({ errors, name }) => {
  // not clearing other errors we don't manage in here
  // shouldn't conflict with type property used on parent form
  const { [RECIPIENT_EMAIL_ERROR_KEY]: _, ...otherErrors } = errors[name] ?? {};

  return {
    ...errors,
    [name]: Object.keys(otherErrors).length ? otherErrors : undefined,
  };
};

// pull this in from a central place
const StyledInput = styled(Input)`
  ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
`;

// text field waits for user to enter semicolon before running validation on email address and passing up to change handler
export const MailRecipientTextField = ({
  errors: _errors,
  label,
  metadata,
  maxLength,
  name,
  onChange,
  placeholder,
  tabIndex,
}) => {
  const fieldName = 'mail_recipient_text_field';
  const { register, setValue } = useFormContext();
  useEffect(() => {
    register(
      { name: fieldName },
      {
        validate: (val) => {
          return !val;
        },
      },
    );
  }, [register]);

  // state
  const [errors, setErrors] = useState({});

  const ref = useRef();

  // listen for errors on main form
  useEffect(() => {
    setErrors((prev) => mergeDeepRight(prev, _errors));
  }, [_errors]);

  const { readOnly, required } = metadata;

  // if user leaves text in the field and blurs, set error state (per Amir)
  const handleBlur = ({ target }) => {
    const { value } = target;

    if (value.trim()) {
      const message = 'Field still has value, either clear or hit semicolon';

      setErrors((prev) =>
        mergeDeepRight(prev, { [name]: { [RECIPIENT_EMAIL_ERROR_KEY]: message } }),
      );
    }
  };

  const handleChange = ({ target }) => {
    const { value: updateValue } = target;
    setValue(fieldName, updateValue?.trim());

    // field is empty
    if (!updateValue?.trim()) {
      // clear email error if previously set
      setErrors((prev) => resetEmailError({ errors: prev, name }));

      return;
    }

    const match = updateValue.match(delimiterRegex);

    // user hasn't entered a semicolon
    if (!match) {
      return;
    }

    const [, _value] = match;
    const value = _value.trim();

    // user entered only a semicolon (nice try)
    if (!value) {
      ref.current.value = null;

      return;
    }

    const isValid = Boolean(value.match(Pattern.EMAIL));

    // user entered invalid email and hit semicolon
    if (!isValid) {
      // TODO: get translations
      const message = 'Invalid email address';

      const updateErrors = mergeDeepRight(errors, {
        [name]: { [RECIPIENT_EMAIL_ERROR_KEY]: message },
      });

      // would like to revisit this approach but the main form validates when user submits
      // we need this to fire when user hits semicolon so we maintain our own internal validation here
      setErrors(updateErrors);

      // strip out semi-colon so user can hit key again on retry
      ref.current.value = ref.current.value.replace(delimiterRegex, '$1');

      return;
    }

    // user entered valid email and hit semicolon
    setErrors((prev) => resetEmailError({ errors: prev, name }));

    onChange(value);
    setValue(fieldName, '');
    // clear input when everything checks out (email address goes into the list below input)
    ref.current.value = null;
  };

  return (
    <StyledInput
      disabled={readOnly}
      errors={errors}
      id={name}
      label={label}
      maxLength={maxLength}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder}
      ref={ref}
      required={required}
      tabIndex={tabIndex}
      type="text"
    />
  );
};

MailRecipientTextField.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  metadata: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
};

MailRecipientTextField.defaultProps = {
  errors: undefined,
  label: '',
  maxLength: null,
  onChange: () => {},
  placeholder: '',
  tabIndex: 0,
};
