import { fetchByStatus } from '@ge/feat-manage/services';
import { range } from '@ge/util/array-utils';

const PAGE_SIZE = 5000;
const MAX_TASK_COUNT = 100000;

const buildBatchForFetchByStatus = ({ pageIndex, batchSize, status, siteIds }) =>
  range(batchSize, pageIndex).map((pageIdx) => {
    return fetchByStatus({ status, pageIdx, pageSize: PAGE_SIZE, siteIds });
  });
const loadAllTasks = async ({ status, siteIds }) => {
  let tasks = [];
  let isMaxRecord = false;
  let totalRecords = 0;
  const loadFeature = async () => {
    let totalPages = 0;
    try {
      let firstPage;
      // Getting First page;
      firstPage = await fetchByStatus({
        status,
        pageIdx: 0,
        pageSize: PAGE_SIZE,
        siteIds,
      });
      totalPages = firstPage.totalPages;
      tasks = firstPage.tasks;
      if (firstPage.totalRecords > MAX_TASK_COUNT) {
        totalRecords = firstPage.totalRecords;
        isMaxRecord = true;
        return;
      }
      if (!totalPages || totalPages <= 1) return;
      // Getting remaining pages;
      const remainingPages = totalPages - 1;
      let batchResults;
      batchResults = await Promise.allSettled(
        buildBatchForFetchByStatus({
          pageIndex: 1,
          batchSize: remainingPages,
          status,
          siteIds,
        }),
      );

      // try to push forward eagerly by only evaluating successful calls to see if we hit the last page
      // TOOD: revisit this approach
      const nonEmptyResult = batchResults.filter(({ value }) => value && value.tasks.length > 0);
      let taskArr = nonEmptyResult.map(({ value }) => value.tasks);
      let flattenArr = [].concat.apply([], taskArr);
      tasks = tasks.concat(flattenArr);
    } catch (err) {
      console.error('Batch fetch error ', err);
    }
  };
  await loadFeature();
  if (isMaxRecord) {
    return {
      count: totalRecords,
      isMax: true,
    };
  } else {
    return tasks;
  }
};

export const taskPageStatus = async ({ status, siteIds }) => {
  return loadAllTasks({ status, siteIds });
};
