import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens/colors/colors';

const spinnerBlock = css`
  display: block;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => (props.spinner ? spinnerBlock : '')};
`;

export const StyledLabel = styled.label`
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 13px;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
  ${(props) => (props.spinner ? spinnerBlock : '')};
`;

const StyledInput = styled.input`
  height: 26px;
  padding: 0 0 0 5px;
  background-color: ${(props) => props.theme.input.background};
  border: 1px solid ${(props) => props.theme.input.border};
  border-radius: 0;
  color: ${(props) => props.theme.input.textColor};
  font-family: Museo Sans;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 26px ${(props) => props.theme.input.background} inset;
    -webkit-text-fill-color: ${(props) => props.theme.input.textColor};
  }
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  :-moz-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  ::-moz-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: ${(props) => props.theme.input.placeholderTextColor};
    font-style: italic;
  }
  &.invalid {
    border: 1px solid ${globalColors.red1};
  }
  &[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(99%) sepia(0%) saturate(6040%) hue-rotate(291deg) brightness(129%) contrast(90%);
  }
`;

const getContentSvg = (hex) => {
  if (typeof hex === 'string')
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="${hex.replace(
      '#',
      '%23',
    )}"><path d="${Icons.CHEVRON}" /></svg>`;
};

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
  &:before,
  &:after {
    content: url('${(props) => getContentSvg(props.theme.input.placeholderTextColor)}');
    position: absolute;
    right: 0;
    width: 15px;
    height: 12px;
    pointer-events: none;
  }
  &:before {
    top: 4px;
    transform: rotate(180deg) scale(0.4);
  }
  &:after {
    bottom: 4px;
    right: 2px;
    transform: scale(0.4);
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    background-color: ${(props) => props.theme.input.background};
    width: 1em;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
  input[type='number']::-webkit-inner-spin-button:hover,
  input[type='number']::-webkit-inner-spin-button:active {
    opacity: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Input = forwardRef(
  ({ label, required, error, name, id, spinner, value, ...props }, ref) => {
    const inputEl = (
      <>
        <StyledInput
          className={!!error && 'invalid'}
          ref={ref}
          aria-invalid={!!error}
          name={name}
          id={id}
          spinner={spinner}
          value={value}
          {...props}
        />
        {/* TODO: Should there be some messaging for error states? */}
        {/* {!!error && <span role="alert">{error}</span>} */}
      </>
    );

    if (label) {
      return (
        <InputWrapper spinner={spinner}>
          <StyledLabel htmlFor={id} spinner={spinner}>
            {label}
            {required && <>*</>}
          </StyledLabel>
          {spinner ? <StyledSpinner>{inputEl}</StyledSpinner> : inputEl}
        </InputWrapper>
      );
    }
    return spinner ? <StyledSpinner>{inputEl}</StyledSpinner> : inputEl;
  },
);

Input.displayName = 'Input';

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  required: PropTypes.bool,
  spinner: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  required: false,
  spinner: false,
};
