import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { Severity } from '@ge/components/severity';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AssetState } from '@ge/models/constants';
import { formatHoursAndMinutes, getDate, killEventPropagation } from '@ge/shared/util';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';

import { ReportWidgetTable } from '../../tables/report-widget-table';

import { plannedWorkColumnDefs, PlannedWorkColumns } from './planned-work-cols';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const PriorityLabel = styled.span`
  padding-left: 5px;
`;

const TechBadge = styled.span`
  background-color: ${({ theme }) => theme.createReport.widget.plannedWork.techBadge.background};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.createReport.widget.plannedWork.techBadge.border};
  text-transform: uppercase;
  letter-spacing: -0.5px;
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  padding: 3px 5px;
  color: ${({ theme }) => theme.createReport.widget.plannedWork.techBadge.textColor};
`;

const TechList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;

  ${TechBadge} {
    margin: 0 -5px 3px 0;
  }
`;

const PartsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const PartsDetails = styled.li`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const PartContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const PartDescription = styled.span``;

const PartNumber = styled.span`
  color: ${({ theme }) => theme.createReport.widget.plannedWork.expectedParts.partNumberTextColor};
`;

const PartQty = styled.span`
  min-width: 20px;
  text-align: right;
`;

const NoteContainer = styled.div`
  overflow-wrap: normal;
  white-space: normal;
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.plannedWork.includeTextColor};
`;

const ExpectedPartsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 125px;
`;

const NotesHeader = styled.div`
  min-width: 125px;
`;

const Legend = styled.ul`
  display: flex;
  justify-content: flex-end;
`;
const LegendItem = styled.li`
  list-style: none;
  margin-left: 10px;

  span {
    color: ${({ theme }) => theme.createReport.widget.headerTextColor};
    margin-left: 5px;
  }
`;

export const PlannedWorkWidget = ({
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
}) => {
  const { t, ready } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(data.map(({ task }) => task?.id));

  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom table headers for provided columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case PlannedWorkColumns.SELECTED:
          return <IncludeHeader>{t('planned_work.columns.include')}</IncludeHeader>;
        case PlannedWorkColumns.PARTS_EXPECTED:
          return (
            <ExpectedPartsHeader>
              <span>{t('planned_work.columns.expected')}</span>
              <span>{t('planned_work.columns.qty')}</span>
            </ExpectedPartsHeader>
          );
        case PlannedWorkColumns.PARTS_CONSUMED:
          return (
            <ExpectedPartsHeader>
              <span>{t('planned_work.columns.consumed')}</span>
              <span>{t('planned_work.columns.qty')}</span>
            </ExpectedPartsHeader>
          );
        case PlannedWorkColumns.NOTES_LATEST_NOTE:
          return <NotesHeader>{t('completed_work.columns.latest_note')}</NotesHeader>;
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case PlannedWorkColumns.SELECTED:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case PlannedWorkColumns.ASSET_STATE:
          return (
            <Badge
              color={getTurbineStateColor(cellValue)}
              label={getTurbineStateType(cellValue)}
              small
            />
          );
        case PlannedWorkColumns.TASK_PRIORITY:
          return (
            <>
              <Severity level={cellValue} />
              <PriorityLabel>{t(`planned_work.priorities.${cellValue}`)}</PriorityLabel>
            </>
          );
        case PlannedWorkColumns.TASK_ASSIGNED_TECHS:
          return (
            <TechList>
              {cellValue?.map((tech, idx) => (
                <TechBadge key={idx}>{tech}</TechBadge>
              ))}
            </TechList>
          );
        case PlannedWorkColumns.PARTS_EXPECTED:
        case PlannedWorkColumns.PARTS_CONSUMED:
          return (
            <PartsList>
              {cellValue?.map((part, idx) => (
                <PartsDetails key={idx}>
                  <PartContainer>
                    <PartDescription>{part.description}</PartDescription>
                    <PartNumber>{part.partNumber}</PartNumber>
                  </PartContainer>
                  <PartQty>{part.qty}</PartQty>
                </PartsDetails>
              ))}
            </PartsList>
          );
        case PlannedWorkColumns.NOTES_LATEST_NOTE:
          return <NoteContainer>{cellValue}</NoteContainer>;
        case PlannedWorkColumns.TASK_TITLE:
          return cellValue;
        default:
          return cellValue;
      }
    },
    [rowsSelected, t, toggleCheckedRow],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback(
    (plannedWork) => {
      return {
        [PlannedWorkColumns.SELECTED]: plannedWork.task.id,
        [PlannedWorkColumns.ASSET_NAME]: plannedWork.asset?.name,
        [PlannedWorkColumns.ASSET_SITE]: plannedWork.asset?.siteName,
        [PlannedWorkColumns.ASSET_STATE]: plannedWork.asset?.state,
        [PlannedWorkColumns.ASSET_SITE]: plannedWork.asset?.siteName,
        [PlannedWorkColumns.TASK_PRIORITY]: plannedWork.task.priority,
        [PlannedWorkColumns.TASK_STATUS]: t(
          `task_status.${plannedWork.task.status}`,
          plannedWork.task.status,
        ),
        [PlannedWorkColumns.TASK_TITLE]: plannedWork.task.title,
        [PlannedWorkColumns.TASK_DESCRIPTION]: plannedWork.task.description,
        [PlannedWorkColumns.TASK_TYPE]: t(
          `work_scopes.${plannedWork.task.type}`,
          plannedWork.task.type,
        ),
        [PlannedWorkColumns.TASK_LINKED]: 'N/A', // TODO: Where is this data?
        [PlannedWorkColumns.TASK_COMPONENT]: plannedWork.task.component,
        [PlannedWorkColumns.TASK_ASSIGNED_TECHS]: plannedWork.task.assignedTechs,
        [PlannedWorkColumns.TASK_SR_NUMBER]: plannedWork.task.srNumber,
        [PlannedWorkColumns.TASK_SR_STATUS]: plannedWork.task.srStatus,
        [PlannedWorkColumns.ESTIMATES_NUM_TECHS]: plannedWork.estimates?.estimatedNumberOfTechs,
        [PlannedWorkColumns.ESTIMATES_DURATION]: formatHoursAndMinutes(
          plannedWork.estimates?.estimatedDurationHours,
          plannedWork.estimates?.estimatedDurationMinutes,
        ),
        [PlannedWorkColumns.ESTIMATES_LABOR_HOURS]: formatHoursAndMinutes(
          plannedWork.estimates?.laborHours,
          plannedWork.estimates?.laborMinutes,
        ),
        [PlannedWorkColumns.DATES_CREATED]:
          plannedWork.dates?.createdDate && getDate(plannedWork.dates.createdDate),
        [PlannedWorkColumns.DATES_ELIGIBLE_START]:
          plannedWork.dates?.eligibleStartDate && getDate(plannedWork.dates.eligibleStartDate),
        [PlannedWorkColumns.DATES_SCHEDULED]:
          plannedWork.dates?.scheduleDate && getDate(plannedWork.dates.scheduleDate),
        [PlannedWorkColumns.DATES_DUE]:
          plannedWork.dates?.dueDate && getDate(plannedWork.dates.dueDate),
        [PlannedWorkColumns.DATES_COMPLETED]:
          plannedWork.dates?.completedDate && getDate(plannedWork.dates.completedDate),
        [PlannedWorkColumns.PARTS_EXPECTED]: plannedWork.parts?.expected,
        [PlannedWorkColumns.PARTS_CONSUMED]: plannedWork.parts?.consumed,
        [PlannedWorkColumns.NOTES_LATEST_NOTE]: plannedWork.notes?.latest,
        [PlannedWorkColumns.NOTES_RESOLUTION]: plannedWork.notes?.resolution,
      };
    },
    [t],
  );

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: plannedWorkColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  // Inspect column state to determine if the state column is visible. Used to show/hide legend.
  const stateVisible =
    columns
      ?.find((colGrp) => colGrp.id === PlannedWorkColumns.GROUP_ASSET_DETAILS)
      ?.cols.find((col) => col.id === PlannedWorkColumns.ASSET_STATE)?.visible === true;

  if (!ready) {
    return <Loader />;
  }

  return (
    <Container>
      {stateVisible && (
        <Legend>
          {Object.values(AssetState).map((state) => (
            <LegendItem key={state}>
              <Badge color={getTurbineStateColor(state)} label={getTurbineStateType(state)} small />
              <span>{t(`asset_state.${state}`)}</span>
            </LegendItem>
          ))}
        </Legend>
      )}

      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty={['task', 'id']}
        isEditing={isEditing}
        variableHeight={true}
      />
    </Container>
  );
};

PlannedWorkWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.string),
};
