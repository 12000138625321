import { useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  AdminStep,
  AttributeGroups,
  DefaultRoleType,
  Delimiters,
  EntityAll,
  EntityType,
  PersonCreateType,
  PersonType,
} from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors';

import { usePatchPerson } from '../../entity-details/person-details/hooks/use-patch-person';
import EntityCreateSteps from '../components/entity-create-steps';
import AdminCreateHeader from '../entity-create-header';
import {
  DetailContainer,
  SectionContainer,
  FormError,
  FormLoading,
  essentialAttributeBuilder,
  roleAppender,
  roleDeducter,
} from '../entity-create-shared';
import { useCreateNewPerson } from '../hooks/use-create-newperson';

import PersonAddDetails from './person-add-details';
import PersonApplyAssets from './person-apply-assets';
import PersonAssignAssets from './person-assign-assets';
import PersonAssignUserRoles from './person-assign-user-roles';

const AdminForm = styled.form`
  background: ${(props) => props.theme.admin.panel.backgroundColor};
  height: 100%;
  position: relative;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`;

const AdminCreateBody = styled.div`
  background: ${(props) => props.theme.admin.panel.body.backgroundColor};
  border-bottom: 2px solid ${(props) => props.theme.admin.panel.border};
  box-sizing: border-box;
  height: 100%;
  padding: 16px 25px;
  position: relative;

  > div {
    display: none;
    &.active {
      display: block;
    }
  }

  &:after {
    background: ${(props) => props.theme.admin.panel.backgroundColor};
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .form-row {
    display: flex;
    padding-top: 20px;
    &:first-of-type {
      border-bottom: 1px solid ${(props) => props.theme.admin.panel.body.border};
      padding: 0 0 15px 0;
    }
    &:last-of-type {
      padding-bottom: 20px;
    }
    &.half {
      width: 50%;
    }
    &.two-third {
      width: 66.666%;
    }
    &.three-quarter {
      width: 75%;
    }
  }
  .lookup-row {
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-end;
    border-bottom: 1px solid ${(props) => props.theme.admin.panel.body.border};
    margin-bottom: 5px;
    padding: 15px 0 15px 0;

    button {
      padding: 5px 14px;
    }

    & > div {
      width: 66.666%;
    }
  }
  .person-label {
    font-size: 14px;
    line-height: 17px;
    margin-right: 25px;
  }
  .worker-wrapper,
  .user-wrapper {
    margin-right: 40px;
    width: 208px;
    span {
      margin-left: 10px;
    }
    .detail {
      display: block;
      margin: 5px 0 0 25px;
    }
  }
  .validated-row {
    position: absolute;
    left: 0;
    top: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

const AdminFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 45px 0 25px;
  position: relative;
`;

const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  button {
    &:nth-child(2) {
      margin-left: auto;
    }
  }
  .add-role {
    color: ${(props) => props.theme.admin.panel.body.textButton};
    font-size: 12px;
    font-weight: ${typography.weight.bold};
    margin-right: 15px;
    text-transform: uppercase;
  }
`;
const ScrollingWrapper = styled(ScrollingContainer)`
  .simplebar-content {
    height: 100% !important;
  }
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.admin.panel.body.textButton,
}))`
  margin-right: 4px;
`;

const StyledErrorIcon = styled(Icon).attrs(() => ({
  size: 24,
  color: `${StatusColor.DANGER}`,
}))``;

const PersonCreate = () => {
  const { t } = useTranslation(['admin.people', 'general'], { useSuspense: false });
  const addRoleRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const viewServiceGroups = useStoreState((state) => state.sites.getViewServiceGroupsSites);
  const viewSites = useStoreState((state) => state.sites.sitesForView);
  const { workerLimitedEntities, workerLimitedSites, hasLimitedUserManagement } = useStoreState(
    (state) => state.userRole,
  );
  const { showPersonDetails } = useContext(EntityDetailsContext);

  // Types of people (eg. user, worker, user/worker) have different sets of steps for creation
  const person = useMemo(() => {
    return {
      [PersonType.CONTACT]: [AdminStep.PERSON_ADD_DETAILS],
      [PersonType.WORKER]: [AdminStep.PERSON_ADD_DETAILS, AdminStep.PERSON_ASSIGN_ASSETS],
      [PersonType.USER]: [
        AdminStep.PERSON_ADD_DETAILS,
        AdminStep.PERSON_ASSIGN_USER_ROLES,
        AdminStep.PERSON_APPLY_ASSETS,
      ],
      [PersonType.USER_AND_WORKER]: [
        AdminStep.PERSON_ADD_DETAILS,
        AdminStep.PERSON_ASSIGN_USER_ROLES,
        AdminStep.PERSON_APPLY_ASSETS,
      ],
    };
  }, []);

  const [queryParams, setQueryParams] = useQueryParams({
    step: withDefault(StringParam, AdminStep.PERSON_ADD_DETAILS.value),
    index: withDefault(NumberParam, 0),
  });

  const [personType, setPersonType] = useState(PersonType.CONTACT);
  const [steps, setSteps] = useState(person[personType]);
  const [isAddRoleDisabled, setIsAddRoleDisabled] = useState(true);
  const [personName, setPersonName] = useState(null);
  const [isRolesSelcted, setIsRolesSelected] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [foundPerson, setFoundPerson] = useState(null);
  const [personAttributes, setPersonAttributes] = useState(null);
  const [personIsFound, setPersonIsFound] = useState(false);
  const [redirectUserName, setRedirectUserName] = useState(null);
  const [viewSelected, setViewSelected] = useState({ serviceGroups: [], sites: [] });

  useEffect(() => {
    if (viewSites && viewServiceGroups) {
      setViewSelected({
        serviceGroups: viewServiceGroups.map((group) => group.id),
        sites: viewSites.map((site) => site.id),
      });
    }
  }, [viewServiceGroups, viewSites]);

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      selectedRoles: [
        {
          name: '',
          permissions: [],
          entities: {},
          isDynamicAll: false,
        },
      ],
    },
  });

  const watchUser = methods.watch('user');
  const watchWorker = methods.watch('worker');
  const watchSelectedRoles = methods.watch('selectedRoles');

  const closeModal = useCallback(() => {
    history.push(location.pathname);
  }, [history, location]);

  const detailsRedirect = useCallback(() => {
    showPersonDetails(redirectUserName);
  }, [redirectUserName, showPersonDetails]);

  const {
    create,
    isError,
    personCreating,
    error: createError,
  } = useCreateNewPerson({
    onSuccess: detailsRedirect,
  });

  const {
    patch,
    personPatching,
    isError: isPatchError,
    error: patchError,
  } = usePatchPerson({
    onSuccess: detailsRedirect,
  });

  const messageFormatter = (value) => {
    const splitValue = value.split('-');
    return splitValue[1];
  };

  // ASSIGN PERSON TYPE FOR PERSON CREATE FORM
  const assignPersonType = (values) => {
    if (!values.user && !values.worker) {
      return [PersonCreateType.CONTACT];
    }
    if (values.user && !values.worker) {
      return [PersonCreateType.USER];
    }
    if (!values.user && values.worker) {
      return [PersonCreateType.WORKER];
    }
    if (values.user && values.worker) {
      return [PersonCreateType.USER, PersonCreateType.WORKER];
    }
    return [PersonCreateType.CONTACT];
  };

  const updatePerson = useCallback(
    (values, personAttributes) => {
      const username = values.sso;
      const essentialAttributes = essentialAttributeBuilder(values, foundPerson);

      if (!values.user && values.worker) {
        if (
          Object.prototype.hasOwnProperty.call(
            personAttributes,
            AttributeGroups.WORKER_SERVICE_GROUP,
          )
        ) {
          const existingGroups = personAttributes.worker_service_group[0].groupCombination[0].ids;
          let filteredGroups = existingGroups.filter((group) =>
            viewSelected.serviceGroups.includes(group),
          );
          if (
            hasLimitedUserManagement &&
            workerLimitedEntities?.length &&
            workerLimitedSites?.indexOf(EntityAll.ALL) === -1
          ) {
            filteredGroups = filteredGroups.filter((group) =>
              workerLimitedEntities.includes(group),
            );
          }
          const entityList = values.entityList.serviceGroup;
          const addedGroups = entityList.filter((group) => !filteredGroups.includes(group));
          const removedGroups = filteredGroups.filter((group) => !entityList.includes(group));
          const uniqueRemovedGroups = [...new Set(removedGroups)];
          const bodyAdd = {
            path: '/attributes/worker_service_group/groupCombination/service_group/ids',
            value: addedGroups.toString(),
            op: 'ADD',
          };
          const bodyRemove = {
            path: '/attributes/worker_service_group/groupCombination/service_group/ids',
            value: uniqueRemovedGroups.toString(),
            op: 'REMOVE',
          };
          const bodyWorkerAssets = [bodyAdd, bodyRemove].filter(
            (group) => group.value !== '' && group.value !== undefined,
          );
          const body = [...essentialAttributes, ...bodyWorkerAssets];
          if (body.length) {
            patch({ username, body });
          }
        } else {
          const bodyWorkerAssets = [
            {
              path: '/attributes/worker_service_group/groupCombination/service_group/ids',
              value: values.entityList?.serviceGroup?.toString(),
              op: 'ADD',
            },
          ];
          const body = [...essentialAttributes, ...bodyWorkerAssets];
          if (body.length) {
            patch({ username, body });
          }
        }
      }

      if (values.user) {
        let userBody = [];
        let bodyWorkerAssets = [];
        if (Object.prototype.hasOwnProperty.call(personAttributes, AttributeGroups.ENTITY_GROUP)) {
          const existingRoles = personAttributes.entity_group
            .filter(
              (attribute) =>
                attribute.roleName !== DefaultRoleType.MONITOR_ADMIN &&
                attribute.roleName !== DefaultRoleType.ANALYTICS_VIEW &&
                attribute.roleName !== DefaultRoleType.MONITOR_VIEW,
            )
            .map((attribute) => attribute.roleName);

          const currentRoles = values.selectedRoles.filter((role) =>
            existingRoles.includes(role.name),
          );
          const addedRoles = values.selectedRoles.filter(
            (role) => !existingRoles.includes(role.name),
          );

          const submittedRoles = values.selectedRoles.map((role) => role.name);
          const removedRoles = existingRoles.filter((role) => !submittedRoles.includes(role));

          const bodyAddedRoles = addedRoles.map((role) => {
            return {
              path: '/attributes/entity_group',
              value: role.name,
              op: 'ADD',
            };
          });

          const bodyAddedRoleSites = addedRoles
            .map((role) => {
              if (role.isDynamicAll) {
                return {
                  path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
                  value: 'ALL',
                  op: 'ADD',
                };
              } else {
                return {
                  path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
                  value: role.entities?.site?.toString(),
                  op: 'ADD',
                };
              }
            })
            .filter((role) => role.value !== '' && role.value !== undefined);
          const bodyAddedRoleServiceGroups = addedRoles
            .map((role) => {
              if (!role.isDynamicAll) {
                return {
                  path: `/attributes/entity_group/${role.name}/groupCombination/service_group/ids`,
                  value: role.entities?.serviceGroup?.toString(),
                  op: 'ADD',
                };
              } else {
                return '';
              }
            })
            .filter((role) => role.value !== '' && role.value !== undefined);
          const currentRoleAddedSites = roleAppender(
            currentRoles,
            personAttributes,
            AttributeGroups.SITES,
          );
          const currentRoleAddedServiceGroups = roleAppender(
            currentRoles,
            personAttributes,
            AttributeGroups.SERVICE_GROUP,
          );

          const currentRoleSitesRemoved = roleDeducter(
            currentRoles,
            personAttributes,
            AttributeGroups.SITES,
          );
          const currentRoleServiceGroupsRemoved = roleDeducter(
            currentRoles,
            personAttributes,
            AttributeGroups.SERVICE_GROUP,
          );

          const bodyRemovedRoles = removedRoles.map((role) => {
            return {
              path: '/attributes/entity_group',
              value: role,
              op: 'REMOVE',
            };
          });

          userBody = [
            ...bodyAddedRoles,
            ...bodyAddedRoleSites,
            ...bodyAddedRoleServiceGroups,
            ...currentRoleAddedSites,
            ...currentRoleAddedServiceGroups,
            ...currentRoleSitesRemoved,
            ...currentRoleServiceGroupsRemoved,
            ...bodyRemovedRoles,
          ];
        } else {
          const bodyAddedRoles = values.selectedRoles.map((role) => {
            return {
              path: '/attributes/entity_group',
              value: role.name,
              op: 'ADD',
            };
          });
          const bodyAddedSites = values.selectedRoles
            .map((role) => {
              if (role.isDynamicAll) {
                return {
                  path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
                  value: 'ALL',
                  op: 'ADD',
                };
              } else {
                return {
                  path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
                  value: role.entities?.site?.toString(),
                  op: 'ADD',
                };
              }
            })
            .filter((role) => role.value !== '' && role.value !== undefined);
          const bodyAddedServiceGroups = values.selectedRoles
            .map((role) => {
              if (!role.isDynamicAll) {
                return {
                  path: `/attributes/entity_group/${role.name}/groupCombination/service_group/ids`,
                  value: role.entities?.serviceGroup?.toString(),
                  op: 'ADD',
                };
              } else {
                return '';
              }
            })
            .filter((role) => role.value !== '' && role.value !== undefined);
          userBody = [...bodyAddedRoles, ...bodyAddedSites, ...bodyAddedServiceGroups];
        }
        if (values.user && values.worker) {
          if (
            Object.prototype.hasOwnProperty.call(
              personAttributes,
              AttributeGroups.WORKER_SERVICE_GROUP,
            )
          ) {
            const existingGroups = personAttributes.worker_service_group[0].groupCombination[0].ids;
            let filteredGroups = existingGroups.filter((group) =>
              viewSelected.serviceGroups.includes(group),
            );
            if (
              hasLimitedUserManagement &&
              workerLimitedEntities?.length &&
              workerLimitedSites?.indexOf(EntityAll.ALL) === -1
            ) {
              filteredGroups = filteredGroups.filter((group) =>
                workerLimitedEntities.includes(group),
              );
            }
            const workerAssets = values.workerAssets.serviceGroup;
            const addedGroups = workerAssets.filter((group) => !filteredGroups.includes(group));
            const removedGroups = filteredGroups.filter((group) => !workerAssets.includes(group));
            const uniqueRemovedGroups = [...new Set(removedGroups)];

            const bodyAdd = {
              path: '/attributes/worker_service_group/groupCombination/service_group/ids',
              value: addedGroups.toString(),
              op: 'ADD',
            };
            const bodyRemove = {
              path: '/attributes/worker_service_group/groupCombination/service_group/ids',
              value: uniqueRemovedGroups.toString(),
              op: 'REMOVE',
            };
            bodyWorkerAssets = [bodyAdd, bodyRemove].filter(
              (group) => group.value !== '' && group.value !== undefined,
            );
          } else {
            bodyWorkerAssets = [
              {
                path: '/attributes/worker_service_group/groupCombination/service_group/ids',
                value: values.workerAssets.serviceGroup.toString(),
                op: 'ADD',
              },
            ];
          }
        }
        let body = [...essentialAttributes, ...userBody, ...bodyWorkerAssets];
        if (body.length) {
          patch({ username, body });
        }
      }
    },
    [
      foundPerson,
      patch,
      viewSelected.serviceGroups,
      workerLimitedEntities,
      hasLimitedUserManagement,
      workerLimitedSites,
    ],
  );

  const createPerson = useCallback(
    (values) => {
      let workerCombination =
        ((values.worker && !values.user) || (values.worker && values.user)) &&
        values.entityList.serviceGroup.length > 0
          ? [
              {
                groupName: 'service_group',
                ids: values.entityList.serviceGroup.map((group) => group),
              },
            ]
          : null;

      let userCombination = [];
      if ((!values.worker && values.user) || (values.worker && values.user)) {
        userCombination =
          values.entityList.serviceGroup.length > 0 && values.entityList.site.length > 0
            ? [
                {
                  groupName: 'sites',
                  ids: values.entityList.site.map((id) => id),
                },
                {
                  groupName: 'service_group',
                  ids: values.entityList.serviceGroup.map((group) => group),
                },
              ]
            : values.entityList.serviceGroup.length
            ? [
                {
                  groupName: 'service_group',
                  ids: values.entityList.serviceGroup.map((group) => group),
                },
              ]
            : values.entityList.site.length
            ? [
                {
                  groupName: 'sites',
                  ids: values.entityList.site.map((id) => id),
                },
              ]
            : [];
      }
      let roleCombination =
        values.user && !values.applyAllUserRoles
          ? values.selectedRoles.map((role) => {
              return {
                roleName: role.name,
                groupCombination: role.isDynamicAll
                  ? [{ groupName: 'sites', ids: ['ALL'] }]
                  : role.entities.serviceGroup.length > 0 && role.entities.site.length > 0
                  ? [
                      {
                        groupName: 'service_group',
                        ids: role.entities.serviceGroup.map((id) => id),
                      },
                      { groupName: 'sites', ids: role.entities.site.map((id) => id) },
                    ]
                  : role.entities.serviceGroup.length
                  ? [
                      {
                        groupName: 'service_group',
                        ids: role.entities.serviceGroup.map((id) => id),
                      },
                    ]
                  : role.entities.site.length
                  ? [{ groupName: 'sites', ids: role.entities.site.map((id) => id) }]
                  : [],
              };
            })
          : null;

      let allUserAssets = [];
      if (values.worker && values.user && values.applyAllUserRoles && !values.applyWorkerUser) {
        allUserAssets =
          values.entityList.serviceGroup.length > 0 && values.entityList.site.length > 0
            ? [
                {
                  groupName: 'sites',
                  ids: values.entityList.site.map((id) => id),
                },
                {
                  groupName: 'service_group',
                  ids: values.entityList.serviceGroup.map((group) => group),
                },
              ]
            : values.entityList.serviceGroup.length
            ? [
                {
                  groupName: 'service_group',
                  ids: values.entityList.serviceGroup.map((group) => group),
                },
              ]
            : values.entityList.site.length
            ? [
                {
                  groupName: 'sites',
                  ids: values.entityList.site.map((id) => id),
                },
              ]
            : [];
      }
      let allWorkerAssets =
        ((values.worker && values.user && values.applyAllUserRoles && !values.applyWorkerUser) ||
          (values.worker && values.user && !values.applyAllUserRoles && !values.applyWorkerUser)) &&
        values.workerAssets.serviceGroup.length > 0
          ? [
              {
                groupName: 'service_group',
                ids: values.workerAssets.serviceGroup.map((group) => group),
              },
            ]
          : null;

      let roleGroup =
        values.user && values.applyAllUserRoles
          ? values.selectedRoles.map((role) => {
              return { roleName: role.name, groupCombination: userCombination };
            })
          : null;

      let roleUserAssets =
        values.user && values.applyAllUserRoles && !values.applyWorkerUser
          ? values.selectedRoles.map((role) => {
              return { roleName: role.name, groupCombination: allUserAssets };
            })
          : null;

      // ALL USERS & WORKERS NEED DefaultGroup AS PART OF THEIR ATTRIBUTES
      const defaultGroup = [
        { roleName: DefaultRoleType.ANALYTICS_VIEW, groupCombination: [] },
        { roleName: DefaultRoleType.MONITOR_ADMIN, groupCombination: [] },
        { roleName: DefaultRoleType.MONITOR_VIEW, groupCombination: [] },
      ];
      // ATTRIBUTE FIELDS NEEDED FOR WORKER & USERS
      const assignAttributes = (values) => {
        if (!values.user) {
          if (values.worker) {
            return {
              worker_service_group: [{ groupCombination: workerCombination }],
            };
          }
          return {};
        }
        if (!values.worker) {
          if (values.applyAllUserRoles) {
            return {
              entity_group: [...roleGroup, ...defaultGroup],
            };
          }
          return {
            entity_group: [...roleCombination, ...defaultGroup],
          };
        }
        if (values.applyAllUserRoles) {
          if (values.applyWorkerUser) {
            return {
              entity_group: [...roleGroup, ...defaultGroup],
              worker_service_group: [{ groupCombination: workerCombination }],
            };
          }
          return {
            entity_group: [...roleUserAssets, ...defaultGroup],
            worker_service_group: [{ groupCombination: allWorkerAssets }],
          };
        }
        return {
          entity_group: [...roleCombination, ...defaultGroup],
          worker_service_group: [{ groupCombination: allWorkerAssets }],
        };
      };

      let newPerson = {
        username: values.sso ? values.sso : values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        type: assignPersonType(values),
        email: `${values.email}`,
        status: 1,
        nickName: null,
        title: values.jobTitle ? values.jobTitle : null,
        validated: values.validated ? values.validated : null,
        phoneNumber: values.phone ? values.phone : null,
        mobileNumber: null,
        initials: values.initials ? values.initials : null,
        functionalSSO: values.functionalSSO ? values.functionalSSO : null,
        onCall: [],
        attributes: assignAttributes(values),
      };
      // CREATE NEW PERSON
      console.log(newPerson);
      create(newPerson);
    },
    [create],
  );

  const onSubmit = useCallback(
    (values, step) => {
      if (step) {
        const stepIndex = queryParams.index;
        values.firstName &&
          values.lastName &&
          setPersonName(`${values.firstName} ${values.lastName}`);
        setQueryParams({
          index: stepIndex + 1,
          step: step,
        });
      } else {
        console.log('form submitted:', values);
        if (values.sso) {
          setRedirectUserName(values.sso);
        } else {
          setRedirectUserName(values.email);
        }
        if (personIsFound && personAttributes) {
          updatePerson(values, personAttributes);
        } else {
          createPerson(values);
        }
      }
    },
    [
      createPerson,
      personAttributes,
      personIsFound,
      queryParams.index,
      setQueryParams,
      updatePerson,
    ],
  );

  useEffect(() => {
    // If the browser has stray query params set back to step 1, ie. person name is set in first step
    if (!personName && queryParams.index > 0) {
      history.push(`${location.pathname}?entity=entityType${Delimiters.KEY_VALUE}person`);
    }
  }, [history, location, personName, queryParams]);

  useEffect(() => {
    if (watchUser !== undefined && watchWorker !== undefined) {
      if (!watchUser && !watchWorker) {
        setPersonType(PersonType.CONTACT);
      } else {
        if (watchUser && watchWorker) {
          setPersonType(PersonType.USER_AND_WORKER);
        } else {
          if (watchUser) {
            setPersonType(PersonType.USER);
          } else if (watchWorker) {
            setPersonType(PersonType.WORKER);
          }
        }
      }
      setSteps(person[personType]);
    }
  }, [person, personType, setSteps, watchUser, watchWorker]);

  useEffect(() => {
    setSteps(person[personType]);
  }, [person, personType]);

  useEffect(() => {
    let selectedRoles = watchSelectedRoles.map((role) => role.name);
    setIsRolesSelected(!selectedRoles.includes(''));
  }, [setIsRolesSelected, watchSelectedRoles]);

  const handleStepChange = useCallback(
    (index) => {
      setQueryParams({
        step: person[personType][index].value,
        index: index,
      });
    },
    [person, personType, setQueryParams],
  );

  const disableButtons = useCallback(() => {
    setIsNextDisabled(true);
  }, []);

  const handleNextButton = useCallback(() => {
    switch (personType) {
      case PersonType.CONTACT:
        return (
          <Button primary onClick={methods.handleSubmit((data) => onSubmit(data, null))}>
            {t('create.save_details_exit', 'Save Details & Exit')}
          </Button>
        );
      case PersonType.WORKER:
        if (queryParams.index === 0)
          return (
            <Button
              primary
              onClick={methods.handleSubmit((data) =>
                onSubmit(data, AdminStep.PERSON_ASSIGN_ASSETS.value),
              )}
            >
              {`${t('create.next', 'Next')}: ${t('steps.assign_assets', 'Assign Assets')}`}
            </Button>
          );
        else if (queryParams.index === 1)
          return (
            <Button primary onClick={methods.handleSubmit((data) => onSubmit(data, null))}>
              {t('create.save_exit', 'Save & Exit')}
            </Button>
          );
        break;
      case PersonType.USER:
      case PersonType.USER_AND_WORKER:
        if (queryParams.index === 0)
          return (
            <Button
              disabled={isNextDisabled}
              primary
              onClick={methods.handleSubmit((data) =>
                onSubmit(data, AdminStep.PERSON_ASSIGN_USER_ROLES.value),
              )}
            >
              {`${t('create.next', 'Next')}: ${t(
                'create.roles_permissions',
                'Role(s) & Permissions',
              )}`}
            </Button>
          );
        else if (queryParams.index === 1)
          return (
            <Button
              disabled={!isRolesSelcted}
              primary
              onClick={methods.handleSubmit((data) =>
                onSubmit(data, AdminStep.PERSON_APPLY_ASSETS.value),
              )}
            >
              {`${t('create.next', 'Next')}: ${t('steps.apply_assets', 'Apply Assets')}`}
            </Button>
          );
        else if (queryParams.index === 2)
          return (
            <Button primary onClick={methods.handleSubmit((data) => onSubmit(data, null))}>
              {t('create.save_exit', 'Save & Exit')}
            </Button>
          );
        break;
      default:
        return null;
    }
  }, [isNextDisabled, isRolesSelcted, methods, onSubmit, personType, queryParams.index, t]);

  const getFooter = useMemo(() => {
    return (
      <AdminFooterWrapper>
        <FooterButtons>
          <Button onClick={closeModal}>{t('general:cancel', 'Cancel')}</Button>
          {!isAddRoleDisabled && queryParams.step === AdminStep.PERSON_ASSIGN_USER_ROLES.value && (
            <button
              className="add-role"
              onClick={() => {
                addRoleRef.current.addRole();
              }}
              type="button"
            >
              <StyledIcon icon={Icons.ADD} />
              {t('create.add_another_role', 'Add Another Role')}
            </button>
          )}
          {handleNextButton()}
        </FooterButtons>
      </AdminFooterWrapper>
    );
  }, [closeModal, t, isAddRoleDisabled, queryParams.step, handleNextButton]);

  return (
    <FormProvider {...methods}>
      <DetailContainer>
        <SectionContainer>
          <AdminForm>
            <AdminCreateHeader
              header={t('create.new_person', 'New Person')}
              entityType={EntityType.PERSON}
              name={personName}
            />
            <EntityCreateSteps
              onStepChange={handleStepChange}
              steps={steps}
              stepIndex={queryParams.index}
            />
            <ScrollingWrapper>
              <AdminCreateBody>
                <PersonAddDetails
                  className={
                    queryParams.step === AdminStep.PERSON_ADD_DETAILS.value ? 'active' : ''
                  }
                  disableButtons={disableButtons}
                  personType={personType}
                  setPersonType={setPersonType}
                  person={person}
                  setSteps={setSteps}
                  setPersonAttributes={setPersonAttributes}
                  setPersonIsFound={setPersonIsFound}
                  foundPerson={foundPerson}
                  setFoundPerson={setFoundPerson}
                />
                <PersonAssignAssets
                  className={
                    queryParams.step === AdminStep.PERSON_ASSIGN_ASSETS.value ? 'active' : ''
                  }
                  personAttributes={personAttributes}
                  personType={personType}
                />
                <PersonAssignUserRoles
                  className={
                    queryParams.step === AdminStep.PERSON_ASSIGN_USER_ROLES.value ? 'active' : ''
                  }
                  personAttributes={personAttributes}
                  personType={personType}
                  onRoleChange={(disabled) => setIsAddRoleDisabled(disabled)}
                  ref={addRoleRef}
                />
                <PersonApplyAssets
                  className={
                    queryParams.step === AdminStep.PERSON_APPLY_ASSETS.value ? 'active' : ''
                  }
                  personAttributes={personAttributes}
                  personType={personType}
                  personIsFound={personIsFound}
                />
              </AdminCreateBody>
            </ScrollingWrapper>
          </AdminForm>

          {(personCreating || personPatching) && (
            <FormLoading>
              <Loader />
            </FormLoading>
          )}
          {isError && (
            <FormError>
              <StyledErrorIcon icon={Icons.ALERT_NEW} />{' '}
              {messageFormatter(createError.response.data.message.description[0])}
            </FormError>
          )}
          {isPatchError && (
            <FormError>
              <StyledErrorIcon icon={Icons.ALERT_NEW} />
              {messageFormatter(patchError.response.data.message.description[0])}
            </FormError>
          )}
        </SectionContainer>
        {getFooter}
      </DetailContainer>
    </FormProvider>
  );
};

export default PersonCreate;
