import React from 'react';
import styled from 'styled-components';

// import { Select } from '@ge/components/select';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useContractualReportingOverview } from '@ge/feat-analyze/data-hooks/use-contractual-reporting-overview';

import { SitePanel } from './site-panel';
import { SitesHeader } from './sites-header';

const MAContainer = styled.div`
  margin: 20px 50px;
`;

// const SiteSortSelect = styled(Select)`
//   margin-bottom: 15px;
// `;

const StyledScrollingContainer = styled(ScrollingContainer)`
  display: flex;
  position: relative;
  flex: 1;
  height: calc(100vh - 270px);
  width: 100%;
`;

export const Sites = () => {
  const { isLoading: isOverviewLoading, data: overviewData } = useContractualReportingOverview();

  // const handleSiteSort = useCallback(
  //   ({ value }) => {
  //     setSiteSortDirection(value);
  //   },
  //   [setSiteSortDirection],
  // );

  // const [siteSortDirection, setSiteSortDirection] = useState(
  //   SitePerformanceAggregateKey.LOWEST_PERFORMERS,
  // );

  // const selectOptionsData = [
  //   {
  //     value: SitePerformanceAggregateKey.LOWEST_PERFORMERS,
  //     label: t('sorts.lowest_performance', 'Lowest Performance'),
  //   },
  //   {
  //     value: SitePerformanceAggregateKey.HIGHEST_PERFORMERS,
  //     label: t('sorts.top_performance', 'Top Performance'),
  //   },
  //   {
  //     value: SortDirection.ASC,
  //     label: t('sorts.alpha_asc', 'Alphabetical A-Z'),
  //   },
  //   {
  //     value: SortDirection.DESC,
  //     label: t('sorts.alpha_desc', 'Alphabetical Z-A'),
  //   },
  // ];

  if (isOverviewLoading) {
    return <Loader />;
  }

  return (
    <MAContainer>
      <SitesHeader overviewData={overviewData} />
      {/*{overviewData.totalCount.sites > 1 && (*/}
      {/*  <SiteSortSelect*/}
      {/*    label="SORT SITES"*/}
      {/*    minWidth={180}*/}
      {/*    maxWidth={180}*/}
      {/*    value={selectOptionsData.find(({ value }) => value === siteSortDirection)}*/}
      {/*    options={selectOptionsData}*/}
      {/*    onChange={handleSiteSort}*/}
      {/*  />*/}
      {/*)}*/}
      <StyledScrollingContainer relative>
        {overviewData?.bySite.map((site) => (
          <SitePanel key={site.site?.id} siteData={site} isOverviewLoading={isOverviewLoading} />
        ))}
      </StyledScrollingContainer>
    </MAContainer>
  );
};
