import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Text } from '@ge/components/typography';
import { globalColors, typography } from '@ge/tokens';

const CustomFilterContainter = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.tasksTracker.buttonBackgroundColor};
  border-radius: 0;
  color: ${(props) => props.theme.tasksTracker.buttonTextColor};
  width: 155px;
  padding: 10px 10px 2px;
  .dropdown-container {
    margin-top: 2px;
    background-color: ${(props) => props.theme.tasksTracker.buttonBackgroundColor};
    position: absolute;
    width: 155px;
    padding: 3px 10px 10px;
    z-index: 1;
    left: 0;
    .button-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .filter-button {
      height: 22px;
    }
  }
`;

const ErrorsContainer = styled.div`
  text-align: center;
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
`;

const CustomFilter = ({ handleClick, tasksTrackerData, enableReorder }) => {
  const { t } = useTranslation(['tasks', 'general', 'manage.planning'], { useSuspense: false });
  const [showTextBox, setShowTextBox] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [customFilterName, setCustomfiterName] = useState('');
  const isError = useStoreState((state) => state.prefs.isError);
  const { setError } = useStoreActions((actions) => actions.prefs);
  const clearFilter = () => {
    setShowTextBox(false);
    setCustomfiterName('');
    setIsDuplicate(false);
    setError(false);
  };

  const openViewModal = () => {
    setError(false);
    setShowTextBox(true);
  };

  const checkDuplicateName = (name) =>
    tasksTrackerData.find((taskTracker) => taskTracker.name.toLowerCase() === name.toLowerCase());

  useEffect(() => {
    setShowTextBox(false);
    setError(false);
  }, []);

  useEffect(() => {
    if (isError === undefined || !isError) {
      setError(false);
      setShowTextBox(false);
    } else {
      setShowTextBox(true);
    }
  }, [isError, setError]);

  const handleApply = async () => {
    setError(false);
    if (customFilterName && checkDuplicateName(customFilterName)) {
      setIsDuplicate(true);
      return null;
    }
    clearFilter();
    handleClick(null, customFilterName);
  };

  const handleFilterName = (e) => {
    isDuplicate && setIsDuplicate(false);
    e.target.value?.length <= 16 && setCustomfiterName(e.target.value);
  };
  return (
    <>
      {showTextBox && (
        <CustomFilterContainter>
          <Input
            placeholder={t('general:new_view', 'Apply')}
            value={customFilterName}
            onChange={handleFilterName}
            required
            type="text"
            disabled={enableReorder}
          />
          <div className="dropdown-container">
            <ErrorsContainer>
              {isDuplicate && (
                <ErrorText>
                  <Icon icon={Icons.DATA_NETCOMM} color={globalColors.red2} size={11} />
                  <br />
                  {t('general.duplicate_error', 'Duplicate Error')}!
                  <br />
                  {t('general.select_different_name', 'Select different name.')}
                </ErrorText>
              )}
              {isError && <ErrorText>{t('general.api_error', 'Unable to create view')}</ErrorText>}
            </ErrorsContainer>
            <div className="button-wrapper">
              <Button className="filter-button" onClick={clearFilter}>
                {t('general.cancel', 'Cancel')}
              </Button>
              <Button
                className="filter-button"
                disabled={!customFilterName.trim().length || enableReorder}
                primary
                onClick={handleApply}
              >
                {t('general:apply', 'Apply')}
              </Button>
            </div>
          </div>
        </CustomFilterContainter>
      )}
      {!showTextBox && (
        <Button className={'addIconBtn'} onClick={() => !enableReorder && openViewModal()}>
          <Icon icon={Icons.ADD} color={globalColors.white} size={11} />
        </Button>
      )}
    </>
  );
};

CustomFilter.propTypes = {
  handleClick: PropTypes.func,
  tasksTrackerData: PropTypes.array,
  enableReorder: PropTypes.bool,
};

export default CustomFilter;
