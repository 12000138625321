import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const SearchContainer = styled.div`
  position: relative;
`;

const Input = styled.input`
  display: block;
  width: calc(100% - 32px);
  height: 28px;
  border: ${(props) => props.theme.manage.search.borderColor};
  border-radius: 2px;
  background-color: transparent;
  box-shadow: ${(props) => props.theme.manage.search.shadow};
  padding: 0 25px 0 7px;
  color: currentColor;
`;

const SearchIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SEARCH,
  color: props.theme.manage.search.iconColor,
}))`
  position: absolute;
  top: 7px;
  right: 3px;
`;

export const SearchInput = ({ placeholder, onChange, className }) => (
  <SearchContainer>
    <Input type="input" placeholder={placeholder} onChange={onChange} className={className} />
    <SearchIcon />
  </SearchContainer>
);

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

SearchInput.defaultProps = {
  placeholder: '',
  onChange: () => {},
};
