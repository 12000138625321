import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import path from 'ramda/src/path';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import { DetailContainer } from '../entity-details-shared';

import EditTicket from './edit-ticket';
import TicketDetailHeader from './ticket-details-header';

const StyledInstructions = styled.div`
  width: 100%;
  display: flex;
  div {
    flex: 1;
  }
  p {
    margin-top: 8px;
    font-size: 14px;
  }
`;

const InstructionsIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SI,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin: 0 10px;
`;

const TicketEntity = ({ ticket, entityType }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { getAssetById } = useStoreState((state) => state.assets);
  const { getSiteById } = useStoreState((state) => state.sites);
  const { getEventById } = useStoreState((store) => store.issues);
  const { fetchTicketById } = useStoreState((store) => store.tickets);

  const [asset, setAsset] = useState(null);
  const [site, setSite] = useState(null);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (ticket.id) fetchTicketById(ticket.id);
  }, [fetchTicketById, ticket.id]);

  useEffect(() => {
    if (path(['event', 'id'], ticket)) {
      setEvent(getEventById(ticket.event.id));
    }
  }, [getEventById, setEvent, ticket]);

  useEffect(() => {
    if (path(['asset', 'id'], event)) {
      setAsset(getAssetById(event.asset.id));
    }
  }, [event, setAsset, getAssetById]);

  // Get the related site for reference in production graphs
  useEffect(() => {
    if (path(['site', 'id'], event)) {
      setSite(getSiteById(event.site.id));
    }
  }, [event, setSite, getSiteById]);

  if (!site || !event || !asset) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  return (
    <DetailContainer>
      <TicketDetailHeader site={site} event={event} asset={asset} />
      <StyledInstructions>
        <InstructionsIcon />
        <div>
          <h4>{t('special_instructions', 'SPECIAL INSTRUCTIONS')}</h4>
          <p>{event.instructions}</p>
        </div>
      </StyledInstructions>
      <ScrollingContainer offsetX={10} autoHide={false}>
        <EditTicket asset={asset} entityType={entityType} ticket={ticket} />
      </ScrollingContainer>
    </DetailContainer>
  );
};

TicketEntity.propTypes = {
  entityType: PropTypes.string.isRequired,
  ticket: PropTypes.instanceOf(Object).isRequired,
};

export default TicketEntity;
