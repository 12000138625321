const is = require('ramda/src/is');
const mergeDeepRight = require('ramda/src/mergeDeepRight');
const path = require('ramda/src/path');

// can expand/simplify this as needed
// ramda also has an isEmpty func but not sure it's the exact behavior we want
const isEmpty = (val) => {
  if (val == null) {
    return true;
  }

  if (val.trim) {
    return val.trim().length === 0;
  }

  if (Array.isArray(val)) {
    return val.every(isEmpty);
  }

  if (is(Object, val)) {
    return Object.keys(val).length === 0;
  }

  return false;
};

/**
 * Map an object to a new object using the provided properties.
 * @param obj the reference object
 * @param properties the properties to map, either a string or
 * object of:
 *  <code>
 *      { to: 'toPath', from: 'fromPath'}
 *  </code>
 *  or
 *  <code>
 *      { to: 'toPath', from: ['from', 'nested', 'path']}
 *  </code>
 *   or
 *  <code>
 *      { to: ['to', 'nested', 'path'], from: ['from', 'nested', 'path']}
 *  </code>
 */
const mapObject = (obj, properties = []) =>
  properties.reduce((acc, p) => {
    if (typeof p === 'object') {
      const { to, from } = p;
      if (Array.isArray(to) && Array.isArray(from)) {
        return mergeDeepRight(acc, nestValue(to, path(from, obj)));
      } else if (Array.isArray(to)) {
        return mergeDeepRight(acc, nestValue(to, obj[from]));
      } else if (Array.isArray(from)) {
        acc[to] = path(from, obj);
        return acc;
      } else {
        acc[to] = path(obj[from]);
        return acc;
      }
    }
    if (obj[p] === undefined) {
      return acc;
    }
    acc[p] = obj[p];
    return acc;
  }, {});

const nestValue = (keys, val) =>
  keys
    .slice()
    .reverse()
    .reduce((res, key) => ({ [key]: res }), val);

/**
 * Map an array of objects to an array of new objects using the provided properties.
 * @param array the array of reference objects
 * @param properties the property map
 */
const mapObjects = (array, properties = []) => array.map((o) => mapObject(o, properties));

const mapObjectReverse = (obj, properties = []) => {
  const reversedProperties = properties.map(({ to: _to, from: _from }) => ({
    to: _from,
    from: _to,
  }));
  return mapObject(obj, reversedProperties);
};

/**
 * Require that all arguments (object values) are defined.
 * @param args variable argument list
 *
 * @throws Error if an argument is null or undefined
 */
const requireNonNull = (args = {}) => {
  const predicate = (a) => a === null || a === undefined;
  const arg = Object.keys(args).find((k) => predicate(args[k]));
  if (arg) {
    throw new Error(`Argument ${arg} must be defined.`);
  }
};

// can we consolidate this with requireNonNull?
/**
 * Require that all arguments (object values) have a non-empty value.
 * @param args variable argument list
 *
 * @throws Error if an argument is empty
 */
const requireNotEmpty = (args = {}) => {
  const emptyArgs = Object.keys(args).filter((key) => isEmpty(args[key]));

  if (emptyArgs.length) {
    throw new Error(`Argument(s) '${emptyArgs.join(', ')}' must not be empty.`);
  }
};

/**
 * Assign a value to the provided object and property key only if the value is defined.
 *
 * @param {object} obj Object to mutate
 * @param {string} key Property key
 * @param {any} val Value to apply to the provided key
 * @returns Modified input object
 */
const assignIfDefined = (obj, key, val) => {
  if (typeof val !== 'undefined' && typeof obj !== 'undefined') {
    obj[key] = val;
  }

  return obj;
};

module.exports = {
  isEmpty,
  mapObject,
  mapObjects,
  mapObjectReverse,
  requireNonNull,
  requireNotEmpty,
  assignIfDefined,
};
