import { PropTypes } from 'prop-types';
import React from 'react';

import { Icons } from '@ge/components/icon';
import { ComponentLoader } from '@ge/components/loader/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import {
  UserServiceGroupRoleStyled,
  DialogTitle,
  ServiceGroupIcon,
} from './user-service-group-role-styled';

/**
 * UserServiceGroupRole component
 */
const UserServiceGroupRole = (props) => {
  const { title, header, data, loading, noRecordText } = props;
  const getRow = (rowData, showIcons = false) => {
    const rows = [];
    for (let key in rowData) {
      rows.push(
        <div className="elem" key={key} title={rowData[key]?.toString()}>
          {showIcons && key === 'serviceGroup' && <ServiceGroupIcon icon={Icons.BLADE_DAMAGES} />}
          {showIcons && key === 'worker' && rowData[key] && <ServiceGroupIcon icon={Icons.CHECK} />}
          {rowData[key]}
        </div>,
      );
    }
    return rows;
  };
  return (
    <UserServiceGroupRoleStyled>
      {title && <DialogTitle>{title}:</DialogTitle>}

      {header && <div className="header">{getRow(header)}</div>}
      {header && data && (
        <ScrollingContainer relative>
          <div className="content">
            {loading && <ComponentLoader className={'noRecordLoader'} />}
            {!loading && data.length === 0 && <p className="noRecordText">{noRecordText}</p>}
            {!loading &&
              data?.map((elem, index) => {
                return (
                  <div key={index} className={'row ' + ((index + 1) % 2 ? 'odd' : '')}>
                    {getRow(elem, true)}
                  </div>
                );
              })}
          </div>
        </ScrollingContainer>
      )}
    </UserServiceGroupRoleStyled>
  );
};

UserServiceGroupRole.propTypes = {
  title: PropTypes.string,
  noRecordText: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.shape({
    serviceGroup: PropTypes.string,
    worker: PropTypes.string,
    user: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      serviceGroup: PropTypes.string,
      worker: PropTypes.bool,
      user: PropTypes.string,
    }),
  ),
};

export default UserServiceGroupRole;
