import equals from 'ramda/src/equals';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { PredicateMatch, QueryKey } from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';
import { isAuthorized as isRecognized } from '@ge/shared/services/iac';

import l2NavItems, { l3NavItems } from '../components/navigation/models/nav-items';
import { getDefaultRoute } from '../services/nav';

// TODO: add enums for well known routes?
const level1NavOrder = {
  '/monitor': 1,
  '/analyze': 2,
  '/execute': 3,
  '/inspect': 4,
  '/admin': 5,
};

export const useNavigation = () => {
  // data hooks
  const { audit, permissions } = useAuth();

  // TODO: handle errors
  const { data, isFetched } = useQuery(
    // only check for authorized navs when permissions change
    [QueryKey.NAVIGATION, permissions],
    () => {
      const level1Navs = new Set();
      const level2Navs = [];
      const level3Navs = [];
      const navMap = new Map();

      for (const navItem of l2NavItems) {
        const { capabilities, parent, scope: feature, authStrategy } = navItem;

        // using app features to enforce well defined routes in here
        if (!isRecognized(feature, PredicateMatch.SOME)) {
          continue;
        }

        // if nav item doesn't contain a claim (capability) we assume it's public
        // can revisit if we want to be more specific than this
        if (
          capabilities?.length &&
          !audit({
            capabilities,
            description: 'Level 2 nav items',
            source: `L2 Nav - ${navItem.label} (${navItem.route})`,
            type: 'L2 Nav item blocked',
            authStrategy,
          })
        ) {
          continue;
        }

        // Checking for L3 Nav Items
        const l3OfCurrentL2 = l3NavItems.filter((l3NavItem) =>
          equals(l3NavItem.parent.route, navItem.route),
        );

        let authorizedL3Count = 0;
        if (l3OfCurrentL2.length) {
          for (const l3NavItem of l3OfCurrentL2) {
            const { capabilities, scope: feature } = l3NavItem;

            // using app features to enforce well defined routes in here
            if (!isRecognized(feature, PredicateMatch.SOME)) {
              continue;
            }

            // if nav item doesn't contain a claim (capability) we assume it's public
            // can revisit if we want to be more specific than this
            if (
              capabilities?.length &&
              !audit({
                capabilities,
                description: 'Level 3 nav items',
                source: `L3 Nav - ${l3NavItem.label} (${l3NavItem.route})`,
                type: 'L3 Nav item blocked',
                authStrategy,
              })
            ) {
              continue;
            }
            authorizedL3Count++;
            level3Navs.push(l3NavItem);
          }
        }

        if (l3OfCurrentL2.length > 0 && authorizedL3Count === 0) {
          continue;
        }

        level1Navs.add(parent);
        level2Navs.push(navItem);

        const childItems = navMap.get(parent) ?? [];
        childItems.unshift(navItem);

        navMap.set(parent, childItems);
      }

      return {
        // de-dupe level 1 navs
        level1Navs: Array.from(level1Navs),
        level2Navs,
        level3Navs,
        navMap,
      };
    },
    {
      ...Config.EXECUTE_ONCE,
      // can revisit but assuming permissions will always be present once loaded
      enabled: Boolean(Object.keys(permissions ?? {}).length),
    },
  );

  // treating the entire flow up until query has data as isLoading state to make simpler for consumers
  const isLoading = !isFetched;

  const getDefaultLevel1Nav = useCallback(
    () =>
      data?.level1Navs.sort(
        ({ route: a }, { route: b }) => level1NavOrder[a] - level1NavOrder[b],
        // if can't resolve route then fallback on base route
        // would like to revisit this logic at some point and be more explicit in handling timing of loading permissions
      )?.[0]?.route ?? '/',
    [data],
  );

  const getDefaultLevel2Nav = useCallback(
    (level1Route) =>
      data?.level2Navs
        .filter((item) => item.parent.route === level1Route)
        .map(getDefaultRoute)
        .pop(),
    [data],
  );

  const getDefaultLevel3Nav = useCallback(
    (level2Route) =>
      data?.level3Navs
        .filter((item) => item.parent.route.includes(level2Route))
        .map(getDefaultRoute)
        .pop(),
    [data],
  );

  return {
    ...data,
    getDefaultLevel1Nav,
    getDefaultLevel2Nav,
    getDefaultLevel3Nav,
    isLoading,
  };
};
