import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { SiteDefs } from '@ge/feat-analyze/models/site-defaults';
import { DateRange, DateTimeFormats } from '@ge/models/constants';
import { timezoneFormat } from '@ge/util/dayjs-utils';
import { formatNumber, roundNumber } from '@ge/util/number-utils';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.extend(timezoneFormat);
dayjs.extend(isoWeek);

/**
 * @function getKpiChartDef
 *
 * Gets KPI chart def for the current filter bar state
 *
 * @param {*} chartDefs The chart definitions
 * @param {*} filterBarState The filter bar state
 *
 * @returns The KPI chart def
 */
export const getKpiChartDef = (chartDefs = {}, filterBarState = {}) => {
  const { primary, secondary, tertirary } = filterBarState;

  // revisit this if we can have charts for primary only selection
  let def = chartDefs[primary] || {};
  def = tertirary ? (def[secondary] || {})[tertirary] : def[secondary];

  // TODO: how do we want to handle a missing def?

  // TODO: do we still need to define a default here?  if so we should pull it from
  // a more central place (sadly the keys in the defs don't line up with KpiCategoryDefs enum)
  return def || chartDefs.availability.tba;
};

/**
 * @function getKpiCategories
 *
 * Gets distinct KPI categories from the chart defs
 *
 * @param {*} chartDef The KPI chart defs
 *
 * @returns The KPI categories
 */
export const getKpiCategories = (chartDef) => {
  if (!chartDef) {
    return [];
  }

  // make sure we are only processing graph defs
  const defs = Object.entries(chartDef).reduce((_defs, [key, value]) => {
    if (key.startsWith('graph')) {
      _defs.push(value);
    }

    return _defs;
  }, []);

  // we should just be able to return defs directly and not need this check
  if (!defs.length) {
    return [];
  }

  const kpiCategories = defs.reduce(
    (
      categories,
      {
        alternate: { categories: alternateCategories } = { categories: [] },
        default: { categories: defaultCategories },
      },
    ) => [
      ...categories,
      ...alternateCategories.map(({ key }) => key),
      ...defaultCategories.map(({ key }) => key),
    ],
    [],
  );

  // de-dupe
  return Array.from(new Set(kpiCategories));
};

// TODO: reconcile this with same func in analyze/util/kpi.js
/**
 * @function getFilterDateRange
 *
 * Gets start and end dates from filter
 *
 * @param {*} endDate End date for custom range
 * @param {*} range The range for start and end date
 * @param {*} startDate Start date for custom range
 * @param {*} entityTimezone
 *
 * @returns The start and end dates
 */
export const getFilterDateRange = ({
  endDate,
  range,
  startDate,
  entityTimezone = 'UTC',
  dateFormat = DateTimeFormats.ENDPOINT_PARAM,
}) => {
  if ((!range || range === DateRange.CUSTOM) && endDate && startDate) {
    const formattedEndDate = dayjs(endDate).format(dateFormat);
    const formattedStartDate = dayjs(startDate).format(dateFormat);
    return {
      startDate: {
        utc: formattedStartDate,
        entityTimezone: formattedStartDate,
      },
      endDate: {
        utc: formattedEndDate,
        entityTimezone: formattedEndDate,
      },
    };
  }

  const utcNow = dayjs().utc();
  const siteNow = entityTimezone === 'UTC' ? utcNow : dayjs().tz(entityTimezone);

  const getUtcAndSiteDateRange = (from, unit = 'd') => {
    if (typeof from === 'string') {
      return {
        startDate: {
          utc: utcNow.startOf(from).format(dateFormat),
          entityTimezone: siteNow.startOf(from).format(dateFormat),
        },
        endDate: {
          utc: utcNow.format(dateFormat),
          entityTimezone: siteNow.format(dateFormat),
        },
      };
    }
    return {
      startDate: {
        utc: utcNow.subtract(from, unit).format(dateFormat),
        entityTimezone: siteNow.subtract(from, unit).format(dateFormat),
      },
      endDate: {
        utc: utcNow.subtract(1, unit).format(dateFormat),
        entityTimezone: siteNow.subtract(1, unit).format(dateFormat),
      },
    };
  };

  switch (range) {
    // should this calculate from 00:00 current day?
    case DateRange.CURRENT_DAY:
      // the end date logic in getUtcAndSiteDateRange subtracts a day which doesn't work for this case
      // so adding this to avoid breaking existing behavior
      return {
        startDate: {
          utc: utcNow.format(dateFormat),
          entityTimezone: siteNow.format(dateFormat),
        },
        endDate: {
          utc: utcNow.format(dateFormat),
          entityTimezone: siteNow.format(dateFormat),
        },
      };

    case DateRange.LAST_DAY:
      return {
        startDate: {
          utc: utcNow.subtract(1, 'd').format(dateFormat),
          entityTimezone: siteNow.subtract(1, 'd').format(dateFormat),
        },
        endDate: {
          utc: utcNow.subtract(1, 'd').format(dateFormat),
          entityTimezone: siteNow.subtract(1, 'd').format(dateFormat),
        },
      };

    case DateRange.NEXT_DAY:
      return {
        startDate: {
          utc: utcNow.add(1, 'd').format(dateFormat),
          entityTimezone: siteNow.add(1, 'd').format(dateFormat),
        },
        endDate: {
          utc: utcNow.add(1, 'd').format(dateFormat),
          entityTimezone: siteNow.add(1, 'd').format(dateFormat),
        },
      };

    case DateRange.MONTH_TO_DATE:
      return getUtcAndSiteDateRange('M');

    case DateRange.WEEK_TO_DATE:
      return getUtcAndSiteDateRange('isoWeek');

    case DateRange.YEAR_TO_DATE:
      return getUtcAndSiteDateRange('y');

    case DateRange.LAST_30_DAYS:
      return getUtcAndSiteDateRange(30);

    case DateRange.LAST_90_DAYS:
      return getUtcAndSiteDateRange(90);

    case DateRange.LAST_12_MONTHS:
      return getUtcAndSiteDateRange(12, 'M');

    case DateRange.LAST_WEEK:
      return {
        startDate: {
          utc: utcNow.subtract(7, 'd').format(dateFormat),
          entityTimezone: siteNow.subtract(7, 'd').format(dateFormat),
        },
        endDate: {
          utc: utcNow.format(dateFormat),
          entityTimezone: siteNow.format(dateFormat),
        },
      };

    case DateRange.NEXT_WEEK:
      return {
        startDate: {
          utc: utcNow.add(0, 'd').format(dateFormat),
          entityTimezone: siteNow.add(0, 'd').format(dateFormat),
        },
        endDate: {
          utc: utcNow.add(7, 'd').format(dateFormat),
          entityTimezone: siteNow.add(7, 'd').format(dateFormat),
        },
      };

    case DateRange.LAST_MONTH:
      // the end date logic in getUtcAndSiteDateRange subtracts a month which doesn't work for this case
      // so adding this to avoid breaking existing behavior
      return {
        startDate: {
          utc: utcNow.subtract(1, 'M').format(dateFormat),
          entityTimezone: siteNow.subtract(1, 'M').format(dateFormat),
        },
        endDate: {
          utc: utcNow.format(dateFormat),
          entityTimezone: siteNow.format(dateFormat),
        },
      };

    default:
      return getUtcAndSiteDateRange(7);
  }
};

/**
 * @function getDefaultDateRange
 *
 * Gets detault start and end dates
 *
 * @param {*} range The range for start and end date
 *
 * @returns The start and end dates
 */
export const getDefaultDateRange = (range = 'w') => {
  const utcNow = dayjs(dayjs().utc());
  const entityTimezone = dayjs(dayjs().utc());
  return {
    endDate: {
      utc: utcNow.startOf().format(DateTimeFormats.ENDPOINT_PARAM),
      entityTimezone,
    },
    startDate: {
      utc: utcNow.startOf(range).format(DateTimeFormats.ENDPOINT_PARAM),
      entityTimezone,
    },
  };
};

/**
 * @function getFormatedDateTime
 *
 * Gets start and end dates from filter
 *
 * @param {*} date The datetime string
 * @param {*} entityTimezone The selected site timezone
 *
 * @returns The formated date
 */
export function getFormatedDateTime(date, entityTimezone = 'UTC') {
  return dayjs(date, 'UTC')
    .tz(entityTimezone)
    .format(DateTimeFormats.POWER_CURVE_DATE_TIME);
}

/**
 * @function parseTimeZone
 *
 * convert site date time to UTC
 *
 * @param {*} date The datetime string
 * @param {*} sourceTimezone The selected timezone string
 * @param {*} targetTimezone The returned timezone string
 * @param {*} format The returned date format string
 *
 * @returns The formated date
 */

export function parseTimeZone(
  date,
  sourceTimezone = 'UTC',
  targetTimezone = 'UTC',
  format = DateTimeFormats.ENDPOINT_DATE_TIME,
) {
  return dayjs
    .tz(date, sourceTimezone)
    .tz(targetTimezone)
    .format(format);
}

/**
 *  Rounds number and then formats it with commas/decimals based on language
 *
 *  @example
 *  // Returns 12,345.12
 *  roundAndFormatNumber(12345.123, 'en')
 *
 * @param number
 * @param {string | string[]} language
 * @param {number} digits
 *
 * @returns The given number rounded and formatted based on language.
 */
export function formatKPIValue(number, language, digits = 2) {
  if (!number || isNaN(number)) return number;

  const roundedNumber = roundNumber(number, digits);
  return formatNumber(roundedNumber, language);
}

/**
 *  get error object to display
 *
 * @param {boolean} isError
 * @param {string} title
 * @param {string} description
 *
 * @returns The noDataTitle and noDataDescription based on language.
 */
export function getKpiError(isError, title, description) {
  return {
    noData: isError,
    noDataTitle: title,
    noDataDescription: description,
  };
}

/**
 *  handle response error
 *
 * @param {*} error
 * @param {*} t
 *
 * @returns The noDataTitle and noDataDescription based on language.
 */
export function handleKpiError({ error, t }) {
  if (!error) {
    return getKpiError(false, '', '');
  }

  if (!error.response) {
    return getKpiError(true, t(`messages.technical_title`), t(`messages.technical`));
  }

  const statusCode = error.response.status;
  if ([401, 504].includes(statusCode)) {
    return getKpiError(true, t(`messages.${statusCode}_title`), t(`messages.${statusCode}`));
  }

  return getKpiError(true, '', error.message);
}

/**
 *  get default first 10 assets from KPI response
 *
 * @param {*} data KPI response
 *
 * @returns First 10 assets from the KPI response.
 */
export function getSelectedEntities(data, chartDef) {
  const category = chartDef?.default?.categories?.[0].key;

  if (category && data[category]) {
    const { entityData = [] } = data[category] || {};
    return entityData.length === 1
      ? entityData[0].entity
      : entityData.slice(0, SiteDefs.MAX_SELECTED_ASSETS).map(({ entity }) => entity);
  }
  return [];
}
