import { useStoreState } from 'easy-peasy';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@ge/components/containers';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { EntityType } from '@ge/models/constants';
import { DateRange } from '@ge/models/constants';

import { FilterBar } from '../components/dashboard/dashboard-filter-bar';
import { SitesHeader } from '../components/sites/sites-header';
import { SitesWind } from '../components/sites/sites-wind';
import { getFilterDateRange } from '../util';

import { AnalyzeFilterPanel } from './analyze-filter-panel';

// TODO: unhardcode this
const regionId = 'reg_nam';

export const AnalyzeSites = () => {
  const { ready } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });
  // In order to gain access to the sibling component instance,
  const windContentRef = useRef();

  const [dateRange, setDateRange] = useState({});
  const [enableExport, setEnableExport] = useState();

  const { dateRange: dates } = useStoreState(({ analyze }) => analyze);

  // Set initial date range to default
  useEffect(() => {
    // don't notify if custom date range is incomplete
    if (dates.range === DateRange.CUSTOM && !(dates.endDate && dates.startDate)) {
      return;
    }

    const filterDateRange = getFilterDateRange({
      startDate: dates.startDate,
      range: dates.range,
      endDate: dates.endDate,
    });

    setDateRange({
      startDate: filterDateRange.startDate.entityTimezone,
      endDate: filterDateRange.endDate.entityTimezone,
    });
  }, [dates]);

  const onExportEnabled = useCallback(
    (enabledExport) => {
      setEnableExport(enabledExport);
    },
    [setEnableExport],
  );

  return (
    <PageContainer i18nReady={ready}>
      <AnalyzeFilterPanel entityId={regionId} entityType={EntityType.REGION} />
      <ScrollingContainer>
        <SitesHeader dateRange={dateRange} />
        <FilterBar
          enabledExport={enableExport}
          onExportClick={() => windContentRef.current.openModal()}
        />
        <SitesWind ref={windContentRef} dateRange={dateRange} onExportEnabled={onExportEnabled} />
      </ScrollingContainer>
    </PageContainer>
  );
};
