import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

// can pull out flex-basis stuff into a util if needed
const ActionContainer = styled.div`
  box-sizing: border-box;
  flex: 0.4 1 25%;
  font-size: 12px;
`;

const ActionIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme?.alertDialog?.blockedActionIconColor,
  icon: Icons.CHECK,
  size: 12,
}))`
  margin-right: 10px;
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

// look into defining this in a more reusable way
const Label = styled.div`
  color: ${({ theme }) => theme?.input?.labelTextColor};
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 5px;
`;

export const BlockedActionsField = ({ values }) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });

  if (!(Boolean(values?.length) && ready)) {
    return null;
  }

  // snap actions to grid
  const span = Math.floor(12 / values.length);

  return (
    <>
      <Label>{t('form.block.label', 'Block')}</Label>
      <ActionsContainer>
        {values.map((action) => (
          <ActionContainer key={action} span={span}>
            <ActionIcon />
            {t(`form.block.options.${action}`, action)}
          </ActionContainer>
        ))}
      </ActionsContainer>
    </>
  );
};

BlockedActionsField.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
};

BlockedActionsField.defaultProps = {
  values: undefined,
};
