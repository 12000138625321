import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const ROLES_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchRoleSubsets = async () => request.get('/cmn/role-subsets', { baseURL: ROLES_BASE_URL });

export const useRoleSubsets = () => {
  const {
    error,
    isError,
    isLoading: isRoleSubsetsLoading,
    data: roleSubsets,
  } = useQuery([QueryKey.ROLE_SUBSETS], fetchRoleSubsets, {
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isRoleSubsetsLoading,
    isError,
    error,
    data: roleSubsets,
  };
};
