/* eslint-disable jsx-a11y/mouse-events-have-key-events */

// TODO: add back in if uncommenting stuff below
// import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { MiniBarChart } from '@ge/components/charts';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DraggableTable, TableArrow } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { Tooltip } from '@ge/components/tooltip';
import {
  AssetState,
  AlertStatusCodeMapping,
  MonitorDefs,
  Capability,
  PermissionScope,
  AlertsEntityType,
  FormMode,
} from '@ge/models/constants';
import { AlertDialog } from '@ge/shared/components/alerts/alert-dialog';
import { AlertTableIcons } from '@ge/shared/components/alerts/alert-table-icons';
import { useAuth } from '@ge/shared/data-hooks';
import { useStorageInverters } from '@ge/shared/data-hooks/use-storage-inverters';
import { useAlertDialog } from '@ge/shared/hooks';
import { isBeforeToday } from '@ge/shared/util/time-date';
import { globalColors } from '@ge/tokens/colors';
import { roundNumber } from '@ge/util';

import { StorageSitesColumnDefs, SitesColumns } from '../../models/sites-table-cols';
import { AssetStateCount } from '../asset-state-count';
import TaskMenu from '../task-menu';

const ROW_KEY_PROPERTY = ['site', 'id'];

const DataIcon = styled(Icon).attrs(({ icon, theme }) => ({
  size: 15,
  icon,
  color: icon === Icons.DATA_NETCOMM ? theme.table.noDataColor : theme.table.dataColor,
  viewbox: '0 0 24 20',
}))`
  display: inline-block;
  position: relative;
`;

const SiteDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

const StyledFlowWrapper = styled.div`
  display: flex;
`;

const SetWidth = styled.span`
  display: inline-block;
  padding-right: 8px;
  text-align: right;
  width: 34px;
`;

const NoWrap = styled.div`
  white-space: nowrap;
`;

const NewButton = styled.button`
  border: ${(props) => props.theme.tasks.newButton.border};
  border-radius: 2px;
  background: ${(props) => props.theme.tasks.newButton.background};
  padding: 0;
  height: 20px;
  width: 20px;
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ADD,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  color:
    props.theme.tasks.backgroundColor[props.label] || props.theme.tasks.backgroundColor.default,
}))``;

const TasksWrapper = styled.div`
  display: flex;

  div:not(:first-child) {
    margin-left: 3px;
  }
`;

const SetPointIndicator = styled.div`
  display: inline-block;
  margin-right: 4px;
  width: 2px;
  height: 0;
  border-style: solid;
  border-width: 9px 0.5px 0;
  border-color: ${(props) =>
    `${props.theme.miniBarChart.upperIndicatorColor} transparent transparent transparent`};
`;

const AvailabilityIndicator = styled.div`
  display: inline-block;
  margin-right: 4px;
  width: 2px;
  height: 0;
  border-style: solid;
  border-width: 0px 0.5px 6px 0.5px;
  border-color: ${(props) =>
    `transparent transparent ${props.theme.miniBarChart.indicatorColor} transparent`};
`;

const Threshold = styled.div`
  color: ${(props) => props.value < props.threshold && props.theme.dangerColor};
`;

// Commented for MVP 0
// const WindIcon = styled(Icon).attrs((props) => ({
//   size: 12,
//   icon: Icons.WIND_DIRECTION,
//   color: props.theme.table.arrowColor,
//   rotate: props.rotate || 0,
//   viewbox: '0 0 12 20',
// }))``;

const TextFaded = styled.span`
  opacity: 0.5;
`;

const IconContainer = styled.div`
  align-items: center;
  background: ${(props) =>
    props.invalid
      ? props.theme.entityDetails.notes.invalidSpecialInstruction
      : props.theme.entityDetails.notes.validSpecialInstruction};
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  top: 1px;
`;

const SocText = styled.span`
  align-items: left;
  min-width: 50px;
`;

const SpecialInstructionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.notes.entityDescription,
  size: 12,
  icon: props.icon,
  viewbox: '-2.5 -1 12 12',
}))`
  position: absolute;
  right: 0;
`;

const excludeWindColumns = ['wind', 'temp'];

const StorageSitesTable = ({
  columns,
  sites,
  sortAction,
  sortMetric,
  sortDirection,
  onRowSelect,
  onDrop,
  scrollable,
  className,
  theme,
  setFilterDefs,
  setCurrentSearch,
  filterValues,
  filterDefs,
  currentRow,
  type,
}) => {
  const { t, ready } = useTranslation(['monitor.sites', 'entity-details', 'tasks']);
  // TODO: add back in after reviewing if we determine that this is still needed
  // const getAssetsBySiteAndState = useStoreState((store) => store.assets.getAssetsBySiteAndState);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => setCurrentSearch({ key, value }),
    [setCurrentSearch],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => setFilterDefs(columnKey, value),
    [setFilterDefs],
  );

  const [tableColumns, setTableColumns] = useState([]);
  const [siteDetails, setSiteDetails] = useState({});

  const { isAuthorized } = useAuth();
  const siteId = siteDetails?.id;

  const authorizedAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });

  const {
    groupDetails,
    currentAlert,
    showAlertDialog,
    handleEditAlert,
    handleCancelAlertDialog,
    handleViewAlert,
  } = useAlertDialog({ entity: siteDetails, entityType: AlertsEntityType.SITE }, authorizedAlerts);

  useEffect(() => {
    const filteredColumns = [];
    columns.map((c) => {
      const newCol = { id: c.id, cols: [] };
      const cols = c.cols.filter(
        (f) =>
          (!excludeWindColumns.includes(f.id) && type !== MonitorDefs.WIND_SITES) ||
          type === MonitorDefs.WIND_SITES,
      );
      if (cols.length > 0) {
        newCol.cols = cols;
        filteredColumns.push(newCol);
      }
    });
    setTableColumns(filteredColumns);
  }, [columns, type, setTableColumns]);

  /**
   * Get all the translated weather icons
   */
  const getTranslatedValues = useCallback(
    (alerts) => {
      return alerts.map((alert) => ({
        ...alert,
        id: alert._id,
        type: alert.type,
        validTo: alert?.typeProperties?.validTo || alert.validTo,
        isExpired: alert.status === AlertStatusCodeMapping.EXPIRED,
        transText: t(`dynamic.warning.${alert}`, 'Warning'),
        auditProperties: alert.auditProperties,
        isGroup: !!alert?.groupDetails?.groupId,
        contactInfo: alert?.typeProperties?.contactInfo,
        description: alert?.typeProperties?.description,
        estimatedEndDate: alert?.typeProperties?.estimatedEndDate,
        requestedBy: alert?.typeProperties?.requestedBy,
        requestedTime: alert?.typeProperties?.requestedTime,
        enr: alert?.typeProperties?.enr,
        event: alert?.typeProperties?.event,
        gps: alert?.typeProperties?.gps,
        nearestAirport: alert?.typeProperties?.nearestAirport,
      }));
    },
    [t],
  );
  const { isLoading: inverterAssetsLoading } = useStorageInverters({
    enabled: type === MonitorDefs.STORAGE_SITES,
  });

  /**
   * Retrieve the appropriate icon for the given data key.
   */
  const getDataIcon = useCallback((data) => {
    switch (data) {
      case 0:
        return Icons.DATA_NETCOMM;
      case 1:
        return Icons.DATA_OK;
      case 2:
        return Icons.DATA_REFRESHING;
      default:
        // covers 'no-data' or unexpected value
        return Icons.DATA_NETCOMM;
    }
  }, []);

  /**
   * Checking the undefined value.
   */
  const getValue = (params, obj, uom) => {
    let keyValue = params[0];
    let keyObj = obj;

    for (let i = 0; i < params.length; i++) {
      keyValue = params[i];

      if (keyObj && typeof keyObj[keyValue] !== 'undefined') {
        keyObj = keyObj[keyValue];
      } else {
        return '-';
      }
    }

    return uom ? `${roundNumber(keyObj, 1)}${uom}` : roundNumber(keyObj, 1);
  };

  /**
   * Function used by useTableFactories to bootstrap the cell values map
   * before returning the factory to process cells for that row. This should
   * contain values for all columns that should be expected to show data in
   * the table.
   */
  const cellValueMapFn = useCallback(
    (row) => {
      const { site } = row;

      const {
        region,
        assets,
        production,
        maintenance,
        conditions,
        revenue,
        connStatus,
        noCommOrData,
      } = site;

      // Define and set the cell value mapping for each column. This will feed both
      // primitive cells and custom cells.
      return {
        [SitesColumns.DATA]: connStatus,
        [SitesColumns.GROUP]: site && site.group,
        [SitesColumns.REGION]: region && region.name,
        [SitesColumns.SITE]: site && site.name,
        [SitesColumns.CUSTOMER]: site && site.customer && site.customer.name,
        [SitesColumns.COUNTRY]: site && site.country,
        [SitesColumns.TOTAL]: assets && assets.total,
        [SitesColumns.POWER_ACTUAL]: getValue(['actualPower'], production),
        [SitesColumns.TEMP]: getValue(['tempC'], conditions, '°'),
        [SitesColumns.MVAR]: getValue(['reactivePower'], production),
        [SitesColumns.SET_POINT]: production && roundNumber(production.setPoint, 1),
        [SitesColumns.TASKS]:
          site && site.tasks
            ? site.tasks
                .filter((task) => task.state !== 'Closed')
                .sort((a, b) => a.type.localeCompare(b.type))
            : [],
        [SitesColumns.AVAILABILITY]:
          production && `${(production.realTimeAvailability * 100).toFixed(1)}%`, // TODO - get this from somewhere else?

        // i18n doesn't like spaces, dashes, or periods in the translation keys.
        // To avoid tedious work localizing turbine make and model, we might
        // consider doing the localization server-side.
        [SitesColumns.ASSET_MAKE]: site.make,
        [SitesColumns.ASSET_MODEL]: site.model,

        [SitesColumns.LOST_REVENUE]: revenue && revenue.lostRevenue.toLocaleString(),
        [SitesColumns.PRICE]: revenue && revenue.priceMW.toLocaleString(),
        [SitesColumns.TICKETS]: maintenance && (maintenance.tickets || '-'),
        [SitesColumns.ANOMALIES]: maintenance && (maintenance.anomalies || '-'),
        [SitesColumns.WORK_ORDERS]: maintenance && (maintenance.orders || '-'),
        [SitesColumns.ALERTS]: (conditions && conditions.alerts) || [],
        [SitesColumns.SI]: (conditions && conditions.specialInstructions) || [],
        [SitesColumns.MAINTENANCE_L_PADDING]: '',
        [SitesColumns.MAINTENANCE_R_PADDING]: '',
        [SitesColumns.PERCENT_AVAILABLE]: assets?.availablePercent ?? '-',
        [SitesColumns.ON]: assets?.online ?? 0,
        [SitesColumns.AVAILABLE]: assets?.available ?? 0,
        [SitesColumns.IMPACTED]: assets?.impacted ?? 0,
        [SitesColumns.TRIPPED]: assets?.tripped ?? 0,
        [SitesColumns.STOPPED]: assets?.stopped ?? 0,
        [SitesColumns.NO_COMM]: site && noCommOrData,
        [SitesColumns.NO_COMM_ASSETS]: site && noCommOrData,
        [SitesColumns.MAINTENANCE]: assets?.maintenance ?? 0,
        [SitesColumns.REPAIR]: assets?.repair ?? 0,
        [SitesColumns.POWER_RATED]:
          production &&
          ((production.capacityFactor / production.possibleParkPower) * 100).toFixed(1),
        [SitesColumns.SET_POINT_AVAILABILITY]: {
          lowerThreshold: production && (production.setPoint / production.possibleParkPower) * 100,
          upperThreshold: production && production.realTimeAvailability * 100,
          percent: production && (production.capacityFactor / production.possibleParkPower) * 100,
        },
        [SitesColumns.SOC]: {
          percent: getValue(['soc'], production),
        },
        [SitesColumns.WIND]: {
          // Commenting for MVP0
          // direction: conditions && conditions.direction,
          windMS: getValue(['windMS'], conditions),
        },
      };
    },
    [t],
  );

  /**
   * Factory function to generate custom column header components in
   * the case that the contents are not simply primitives.
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case SitesColumns.SET_POINT_AVAILABILITY:
          return (
            <div>
              <SetPointIndicator />
              {t('table.set_point', 'Set Point')}
              <br />
              <Tooltip
                title={t(
                  'table.available_power_based_on_wind_speed',
                  'Available Power based on wind speed.',
                )}
              >
                <span>
                  <AvailabilityIndicator />
                  {t('table.available', 'Available')}
                </span>
              </Tooltip>
            </div>
          );
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Factory function to generate custom table cell components in
   * the case that the contents are not simply primitives.
   */
  const customCellFn = useCallback(
    (columnKey, cellValue, row) => {
      switch (columnKey) {
        case SitesColumns.SITE:
        case SitesColumns.REGION:
        case SitesColumns.CUSTOMER:
          return <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell>;
        case SitesColumns.DATA:
          return <DataIcon icon={getDataIcon(cellValue)} />;
        case SitesColumns.TOTAL:
          return <b>{cellValue}</b>;
        case SitesColumns.PERCENT_AVAILABLE:
          return cellValue != '-' ? (
            <div>
              <Threshold threshold={0.85} value={cellValue} key={columnKey}>
                {(cellValue * 100).toFixed(1)}
              </Threshold>
            </div>
          ) : (
            <div>{cellValue}</div>
          );
        case SitesColumns.ON:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.online}
              assetState={AssetState.ONLINE}
              menuTitle={t('state.on', 'On')}
              className="cell-radius-start"
            />
          );
        case SitesColumns.AVAILABLE:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.available}
              assetState={AssetState.AVAILABLE}
              menuTitle={t('state.available', 'Available')}
            />
          );
        case SitesColumns.IMPACTED:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.impacted}
              assetState={AssetState.IMPACTED}
              menuTitle={t('state.impacted', 'Impacted')}
            />
          );
        case SitesColumns.TRIPPED:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.tripped}
              assetState={AssetState.TRIPPED}
              menuTitle={t('state.tripped', 'Tripped')}
            />
          );
        case SitesColumns.STOPPED:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.stopped}
              assetState={AssetState.STOPPED}
              menuTitle={t('state.stopped', 'Stopped')}
            />
          );
        case SitesColumns.NO_COMM:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.netComm}
              assetState={[AssetState.NO_DATA, AssetState.NET_COMM]}
              menuTitle={t('state.no_communication', 'No Communication')}
              className="cell-radius-end"
            />
          );
        case SitesColumns.MAINTENANCE:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.maintenance}
              assetState={AssetState.MAINTENANCE}
              menuTitle={t('state.maintenance', 'Maintenance')}
              className="cell-radius-start"
            />
          );
        case SitesColumns.REPAIR:
          return (
            <AssetStateCount
              row={row}
              count={cellValue}
              color={theme.siteStatusBar.repair}
              assetState={AssetState.REPAIR}
              menuTitle={t('state.repair', 'Repair')}
              className="cell-radius-end"
            />
          );
        case SitesColumns.TASKS:
          return (
            <TasksWrapper>
              {cellValue.map((task) => (
                <TaskMenu task={task} key={task.id}>
                  <StyledBadge key={task.id} label={task?.source?.charAt(0)} small />
                </TaskMenu>
              ))}
            </TasksWrapper>
          );
        case SitesColumns.NEW_TASK:
          return (
            <NewButton
              // eslint-disable-next-line no-console
              onClick={() => console.log('New Click')}
            >
              <AddIcon />
            </NewButton>
          );
        case SitesColumns.POWER_RATED:
          return <TextFaded>{cellValue}</TextFaded>;
        case SitesColumns.SET_POINT_AVAILABILITY:
          return (
            <MiniBarChart
              lowerThreshold={cellValue.lowerThreshold}
              upperThreshold={cellValue.upperThreshold}
              percent={cellValue.percent}
            />
          );
        case SitesColumns.SOC:
          if (cellValue.percent === '-') {
            return (
              <StyledFlowWrapper>
                <SocText>{cellValue.percent}</SocText>
                <MiniBarChart percent={0} />
              </StyledFlowWrapper>
            );
          } else {
            return (
              <StyledFlowWrapper>
                <SocText>{(cellValue.percent * 1).toFixed(1)}</SocText>
                <MiniBarChart percent={cellValue.percent} />
              </StyledFlowWrapper>
            );
          }
        case SitesColumns.WIND:
          return (
            <NoWrap>
              {/* Commenting for MVP0 */}
              {/* <WindIcon rotate={cellValue.direction} /> */}
              <SetWidth>{cellValue?.windMS || '-'}</SetWidth>
            </NoWrap>
          );
        case SitesColumns.ALERTS:
          return (
            <AlertTableIcons
              alerts={getTranslatedValues(cellValue)}
              onClick={(_, alert) => {
                setSiteDetails(row?.site);
                handleViewAlert(alert);
              }}
            />
          );
        case SitesColumns.SI:
          return (
            <>
              {cellValue?.length === 1 ? (
                <IconContainer invalid={isBeforeToday(cellValue[0]?.validDateBy)}>
                  <SpecialInstructionIcon icon={Icons.SPECIAL_INSTRUCTION} />
                </IconContainer>
              ) : cellValue?.length > 1 ? (
                <Badge color={globalColors.stone1} label={cellValue.length} small />
              ) : (
                ''
              )}
            </>
          );
        case SitesColumns.SITE_DETAIL:
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          return (
            <SiteDetailButton type="button">
              <TableArrow />
            </SiteDetailButton>
          );
        default:
          return null;
      }
    },
    [getTranslatedValues, getDataIcon, t, theme, handleViewAlert],
  );

  const filters = useMemo(
    () =>
      Object.keys(filterDefs).reduce(
        (_filter, key) => ({
          ..._filter,
          [key]: {
            ...filterDefs[key],
            value: filterDefs?.[key]?.value,
          },
        }),
        {},
      ),
    [filterDefs],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: StorageSitesColumnDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable: true,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters,
    filterValues,
  });

  if (!ready || inverterAssetsLoading) {
    return <Loader />;
  }

  if (!columns || columns.length === 0 || inverterAssetsLoading) {
    return null;
  }

  return (
    <PageContainer i18nReady={ready}>
      <DraggableTable
        columns={tableColumns}
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        sortAction={sortAction}
        values={sites}
        onValueSelect={onRowSelect}
        dropHandler={onDrop}
        rowKeyProperty={ROW_KEY_PROPERTY}
        scrollable={scrollable}
        className={className}
        rowsSelected={currentRow}
      />
      {showAlertDialog && (
        <AlertDialog
          alert={currentAlert}
          groupDetails={groupDetails}
          entity={siteDetails}
          entityType={AlertsEntityType.SITE}
          isOpen
          mode={FormMode.VIEW}
          onCancel={handleCancelAlertDialog}
          onConfirm={handleEditAlert}
        />
      )}
    </PageContainer>
  );
};

StorageSitesTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sites: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onRowSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.instanceOf(Object),
  filterDefs: PropTypes.object,
  setFilterDefs: PropTypes.func,
  setCurrentSearch: PropTypes.func,
  filterValues: PropTypes.object,
  currentRow: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

StorageSitesTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onRowSelect: () => null,
  onDrop: () => null,
  scrollable: true,
  filterDefs: {},
  setFilterDefs: () => null,
  setCurrentSearch: () => null,
  filterValues: {},
  type: 'storage',
};

export default withTheme(StorageSitesTable);
