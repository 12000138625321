import { PropTypes } from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SectionColumn,
  SectionContainer,
  SectionTopHeading,
  SectionHeading,
  SectionTroubleshooting,
  SectionRow,
} from './styles';

export const ViewLaborCharges = ({ section, erpData, setDivLabourHeight }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const rlvRef = useRef();
  const [refVisible, setRefVisible] = useState(false);
  useEffect(() => {
    if (!refVisible) return;
    setDivLabourHeight(rlvRef.current.clientHeight);
    // detected rendering
  }, [refVisible, setDivLabourHeight]);

  if (!section || section.hidden || !erpData) {
    return null;
  }

  return (
    <SectionContainer
      ref={(el) => {
        rlvRef.current = el;
        setRefVisible(!!el);
      }}
    >
      <SectionTopHeading>{section.title}</SectionTopHeading>
      <SectionHeading>{t('form.labor')}</SectionHeading>
      <SectionRow>
        <SectionColumn>
          <label>{t('form.labor_type')}</label>
        </SectionColumn>
        <SectionColumn>
          <label>{t('form.labor_hrs')}</label>
        </SectionColumn>
      </SectionRow>
      <SectionRow>
        <SectionColumn>
          <label>{t('form.repair')}</label>
        </SectionColumn>
        <SectionColumn>{erpData.repairHours}</SectionColumn>
      </SectionRow>
      <SectionRow>
        <SectionColumn>
          <SectionTroubleshooting>
            <label>{t('form.troubleshooting')}</label>
          </SectionTroubleshooting>
        </SectionColumn>
        <SectionColumn>{erpData.troubleshootingHours}</SectionColumn>
      </SectionRow>
    </SectionContainer>
  );
};

ViewLaborCharges.propTypes = {
  section: PropTypes.object.isRequired,
  erpData: PropTypes.object.isRequired,
  setDivLabourHeight: PropTypes.func,
};
