import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';
import { useAuth } from '@ge/shared/data-hooks';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';

import DistributionList from '../components/distribution-lists';
import HandlingProcedure from '../components/handling-procedures';
import { ConfigureNavItems } from '../models';

const Configure = () => {
  const { t, ready } = useTranslation(['admin.configure']);
  const { filter } = useParams();

  const { isAuthorized } = useAuth();

  const modifiedNavItems = useMemo(() => {
    return ConfigureNavItems.filter(({ capabilities }) =>
      capabilities ? isAuthorized({ capabilities }) : true,
    );
  }, [isAuthorized]);

  return (
    <PageContainer i18nReady={ready}>
      <h1 className="pageTitle">{t('configure', 'Configure')}</h1>
      <SubNavigation navItems={modifiedNavItems}></SubNavigation>
      {filter === 'distribution-list' && <DistributionList />}
      {filter === 'handling-procedures' && <HandlingProcedure />}
    </PageContainer>
  );
};

export default Configure;
