import { css } from 'styled-components';

import { typography } from '@ge/tokens';

export default css`
  body {
    font: ${typography.weight.regular} 11px/13px ${typography.family.default};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  button {
    background: transparent;
    border: 0;
    cursor: pointer;
    font-family: ${typography.family.default};
  }
`;
