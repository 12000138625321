import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import clone from 'ramda/src/clone';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ToggleButton } from '@ge/components/toggle-button';
import { placements, Tooltip } from '@ge/components/tooltip';
import { DateTimeFormats, Capability, PermissionScope } from '@ge/models/constants';
import { NotesCategory, SpecialInstructionStatus, NotesScope } from '@ge/models/constants';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useAuth } from '@ge/shared/data-hooks';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { ActionKeys, NotesMenuItems, SIMenuItems } from '@ge/shared/models/actions-menu-items';
import { getNotesDisabledActions, getNotesDisabledEyeActions } from '@ge/shared/util/general';
import { getRelativeTimeFromNow } from '@ge/shared/util/time-date';
import { isBeforeToday } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

import viewing_off from '../viewing_off.svg';
import viewing_on from '../viewing_on.svg';

const StyledNotesAttachmentsTable = styled.div`
  .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
    margin-left: 135px;
  }
  .note-attachment {
    &:last-of-type {
      margin-bottom: 50px;
      .row {
        border-bottom: 0;
      }
    }
  }
  .row {
    display: flex;
    align-items: flex-start;
    margin: 0 0 0 135px;
    &:first-of-type {
      margin: 0px;
    }
    &:last-of-type {
      border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
      div {
        margin: 8px 0 20px;
      }
    }
    &:nth-of-type(2) {
      div {
        margin-top: 0;
      }
    }
  }
  .contributed-by-header,
  .external-viewing-header,
  .actions-header {
    color: ${(props) => props.theme.entityDetails.notes.tableCopyHeader};
    font-weight: ${typography.weight.medium};
    padding: 8px 0;
  }
  .contributed-by-header,
  .contributed-by {
    width: 150px;
    text-align: center;
  }
  .external-viewing-header,
  .external-viewing {
    width: 100px;
    text-align: center;
  }
  .actions-header,
  .actions {
    width: 75px;
    text-align: center;
  }
  .contributed-by-header {
    margin-left: 330px;
  }
  .external-viewing-header {
    span {
      display: block;
      text-align: left;
    }
  }
  .actions-header {
    text-align: right;
  }
  .contributed-by,
  .external-viewing,
  .actions {
    margin-top: 22px;
  }
  .date-created {
    width: 135px;
    margin-top: 25px;
    span {
      display: block;
      &:first-of-type {
        font-weight: ${typography.weight.medium};
        font-size: 13px;
        line-height: 18px;
      }
      &:last-of-type {
        color: ${(props) => props.theme.entityDetails.notes.tableCopyHeader};
        font-weight: ${typography.weight.medium};
        line-height: 13px;
      }
    }
  }
  .title {
    overflow: hidden;
    margin-top: 22px;
    width: 330px;
    span {
      display: inline-block;
      &:first-of-type {
        color: ${(props) => props.theme.entityDetails.notes.entityDescription};
        line-height: 15px;
        margin-bottom: 8px;
        svg {
          margin-right: 5px;
        }
      }
      &:last-of-type {
        font-weight: ${typography.weight.regular};
        color: ${(props) => props.theme.entityDetails.notes.entityDescription};
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.33px;
      }
    }
    .status {
      display: inline-block;
      float: right;
    }
  }
  .contributed-by {
    font-weight: ${typography.weight.medium};
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  }
  .external-viewing {
    text-align: center;
    label {
      margin-left: 30px;
    }
  }
  .actions {
    text-align: center;
    width: 100px;
    div {
      display: contents;
    }
    img {
      vertical-align: middle;
    }
  }
  .cursorPointer {
    cursor: pointer;
  }
  .cursorNotAllowed {
    cursor: not-allowed;
  }
  .description {
    font-size: 13px;
    line-height: 18px;
    inline-size: 600px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
  .valid-until {
    color: ${(props) => props.theme.entityDetails.notes.entityDescription};
    font-weight: ${typography.weight.bold};
    line-height: 15px;
    font-size: 12px;
    text-transform: uppercase;
    &.past-due {
      color: ${(props) => props.theme.entityDetails.notes.invalidSpecialInstruction};
      font-weight: ${typography.weight.bolder};
      line-height: 14px;
      letter-spacing: 0.55px;
    }
  }
`;

const IconContainer = styled.div`
  align-items: center;
  background: ${(props) =>
    props.invalid
      ? props.theme.entityDetails.notes.invalidSpecialInstruction
      : props.theme.entityDetails.notes.validSpecialInstruction};
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const SpecialInstructionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.notes.entityDescription,
  size: 12,
  icon: props.icon,
  viewbox: '-2.5 -1 12 12',
}))`
  position: absolute;
`;

export const NotesAttachmentsTable = ({
  notesAttachments,
  editNoteAction,
  editNoteAndSI,
  toggleHandler,
  deleteNoteAction,
}) => {
  const { t } = useTranslation(['entity-details']);
  const containerRef = useRef(null);

  // data hooks
  const getAuthorizedNotesMenuItems = useActionsMenu({ menuItems: NotesMenuItems });
  const getAuthorizedSIMenuItems = useActionsMenu({ menuItems: SIMenuItems });

  const { isAuthorized } = useAuth();
  const isNotesAdmin = isAuthorized({
    capabilities: [{ capability: Capability.NOTES_ADMIN, scopes: [PermissionScope.ADMIN] }],
  });

  const actionMenuHandler = (action, note) => {
    if (action === ActionKeys.NOTE_EDIT || action === ActionKeys.SI_EDIT) {
      editNoteAction(note);
    } else {
      deleteNoteAction(note);
    }
  };

  const actionToggleHandler = (action, note) => {
    if (getNotesDisabledEyeActions(note, isNotesAdmin)) {
      toggleHandler(action, note);
    }
  };

  const toggleButtonHandler = (note) => {
    const originalData = clone(note);
    if (note.status === SpecialInstructionStatus.ACTIVE) {
      note.status = SpecialInstructionStatus.INACTIVE;
    } else {
      note.status = SpecialInstructionStatus.ACTIVE;
    }
    editNoteAndSI(note, originalData);
  };

  return (
    <StyledNotesAttachmentsTable>
      <div className="header">
        <div className="contributed-by-header">{t('notes.contributed_by', 'Contributed By')}</div>
        <AuthRender
          capability={Capability.NOTES_AND_ATTACHMENTS}
          edit
          delete
          description="Notes and attachments table  column header"
          siteLevel={false}
        >
          <div className="actions-header">{t('notes.actions', 'Actions')}</div>
        </AuthRender>
      </div>
      {/* #TODO: This has to be a virtual table */}
      {notesAttachments.map((note, i) => (
        <div key={i} className="note-attachment">
          <div className="row">
            <div className="date-created">
              <span>{dayjs(note.updateDate).format(DateTimeFormats.ISSUE_DETAIL_DATE)}</span>
              <span>{getRelativeTimeFromNow(dayjs(note.updateDate))}</span>
            </div>
            <div className="title">
              {note.category === NotesCategory.NOTE && (
                <div className="description">{note.note}</div>
              )}
              {/* #TODO: Will change the icons when we get inspect permission  */}
              {note.category === NotesCategory.SPECIAL_INSTRUCTION && (
                <span>
                  <IconContainer invalid={isBeforeToday(note.validDateBy)}>
                    <SpecialInstructionIcon icon={Icons.SPECIAL_INSTRUCTION} />
                  </IconContainer>
                  {note.title}
                </span>
              )}

              {note.category === NotesCategory.SPECIAL_INSTRUCTION && (
                <div className="status">
                  <ToggleButton
                    isToggleOn={note.status === SpecialInstructionStatus.ACTIVE}
                    handleOnClick={() => toggleButtonHandler(note)}
                  />
                </div>
              )}
            </div>
            <div className="contributed-by">{note.updatedBy ?? note.createdBy}</div>
            <AuthRender
              capability={Capability.NOTES_AND_ATTACHMENTS}
              edit
              delete
              description="Notes and attachments table action menu"
              siteLevel={false}
            >
              <div className="actions">
                <ActionsMenu
                  containerRef={containerRef}
                  entity={note}
                  menuItems={
                    (note.category === NotesCategory.SPECIAL_INSTRUCTION &&
                      getAuthorizedSIMenuItems([note])) ||
                    getAuthorizedNotesMenuItems([note])
                  }
                  menuIconSmall
                  onAction={({ type }) => actionMenuHandler(type, note)}
                  disabledActions={getNotesDisabledActions(note, isNotesAdmin)}
                />
                <AuthRender capability={Capability.INTERNAL_ACCESS} edit siteLevel={false}>
                  <Tooltip
                    title={
                      note.scope === NotesScope.EXTERNAL
                        ? t('notes.external_viewing_on', 'External viewing on')
                        : t('notes.external_viewing_off', 'External viewing off')
                    }
                    placement={placements.TOP}
                    zIndex={elevations.P20}
                  >
                    <span className="view-icon">
                      {note.scope === NotesScope.EXTERNAL ? (
                        <img
                          src={viewing_on}
                          alt="Viewing On"
                          className={
                            getNotesDisabledEyeActions(note, isNotesAdmin)
                              ? 'cursorPointer'
                              : 'cursorNotAllowed'
                          }
                          onClick={() => actionToggleHandler(NotesScope.INTERNAL, note)}
                        />
                      ) : (
                        <img
                          src={viewing_off}
                          alt="Viewing Off"
                          className={
                            getNotesDisabledEyeActions(note, isNotesAdmin)
                              ? 'cursorPointer'
                              : 'cursorNotAllowed'
                          }
                          onClick={() => actionToggleHandler(NotesScope.EXTERNAL, note)}
                        />
                      )}
                    </span>
                  </Tooltip>
                </AuthRender>
              </div>
            </AuthRender>
          </div>
          <div className="row">
            <div className="description">
              {note.category === NotesCategory.SPECIAL_INSTRUCTION && note.note}
            </div>
          </div>
          {note.category === NotesCategory.SPECIAL_INSTRUCTION && (
            <div className="row">
              <div
                className={isBeforeToday(note.validDateBy) ? 'valid-until past-due' : 'valid-until'}
              >
                {`${t('notes.valid_until', 'VALID UNTIL')} : ${dayjs(note.validDateBy).format(
                  DateTimeFormats.ISSUE_DETAIL_DATE,
                )}`}
                {isBeforeToday(note.validDateBy) && ` - ${t('notes.past_due', 'PAST DUE')}`}
              </div>
            </div>
          )}
        </div>
      ))}
    </StyledNotesAttachmentsTable>
  );
};

NotesAttachmentsTable.propTypes = {
  notesAttachments: PropTypes.instanceOf(Array).isRequired,
  editNoteAction: PropTypes.func.isRequired,
  editNoteAndSI: PropTypes.func,
  toggleHandler: PropTypes.func,
  deleteNoteAction: PropTypes.func,
};
