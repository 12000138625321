import { ChartType } from '@ge/components/charts/models/chart-type';
export const PORTAL_ROOT_ID = 'portal-root';

// TODO: use this anywhere we have hardcoded lang codes
export const Language = {
  DE: 'de',
  EN: 'en',
  PT_BR: 'pt-BR',
};

export const DEFAULT_LANGUAGE = Language.EN;

export const PredicateMatch = {
  ALL: 'all',
  SOME: 'some',
  NONE: 'none',
};

export const ContractType = {
  FSA: 'fsa',
  OSA: 'osa',
  POWER_UP: 'power-up',
  BOP: 'bop',
  CONVERSION_GUARANTEE: 'conversion-guarantee',
  OTHER: 'other',
};

export const commands = {
  MANUAL: 'MANUAL',
  VALUE: 'true',
  GROUP_DELAY_SEC: 0,
  MAX_GROUP_COMMANDS: 100,
  MAX_INPROGRESS_COMMANDS: 6,
};

export const AssetState = {
  TRIPPED: 'tripped',
  STOPPED: 'stopped',
  IMPACTED: 'impacted',
  REPAIR: 'repair',
  MAINTENANCE: 'maintenance',
  NET_COMM: 'no_communication',
  ONLINE: 'online',
  AVAILABLE: 'available',
  NO_DATA: 'no_data',
};

export const BOPSiteState = {
  YES: 'Yes',
  NO: 'No',
};

export const AbnormalAssetStates = [
  AssetState.TRIPPED,
  AssetState.IMPACTED,
  AssetState.STOPPED,
  AssetState.REPAIR,
  AssetState.MAINTENANCE,
  AssetState.NET_COMM,
];

export const CaseQueueAssetStateFilter = {
  ...AssetState,
  NO_COMM: 'No Comm',
};

export const SiteType = {
  WIND: 'Wind',
  SOLAR: 'Solar',
  STORAGE: 'Storage',
  HYBRID: 'Hybrid',
};

export const AssetType = {
  WIND_TURBINE: 'wind-turbine',
  SUBSTATION: 'substation',
  SITE_CONTROLLER: 'site-controller',
  STORAGE_INVERTER: 'storage-inverter',
  SOLAR_INVERTER: 'solar-inverter',
  HYBRID_INVERTER: 'hybrid-inverter',
  SITE: 'Site',
};

export const InverterType = {
  STORAGE: 'Storage',
  SOLAR: 'Solar',
  HYBRID: 'Hybrid',
};

export const PersonType = {
  CONTACT: 'contact',
  USER: 'user',
  WORKER: 'worker',
  USER_AND_WORKER: 'userAndWorker',
};

export const PersonCreateType = {
  CONTACT: 'Contact',
  USER: 'User',
  WORKER: 'Worker',
  SUCCESS: 'success',
};

export const DefaultRoleType = {
  MONITOR_ADMIN: 'monitoring-admin',
  ANALYTICS_VIEW: 'analytics-view',
  MONITOR_VIEW: 'monitoring-view',
};

export const AttributeGroups = {
  SERVICE_GROUP: 'service_group',
  SITES: 'sites',
  WORKER_SERVICE_GROUP: 'worker_service_group',
  ENTITY_GROUP: 'entity_group',
  ALL: 'ALL',
};

export const PersonAssetWorkerTab = {
  ASSETS_FOR_WORKER: 'Worker',
};

export const PersonCheckboxValue = {
  WORKER: 'worker',
  USER: 'user',
  APPLY_WORKER_USER: 'applyWorkerUser',
  APPLY_ALL_USER_ROLES: 'applyAllUserRoles',
  SELECTED_ROLES: 'selectedRoles',
};

export const Roles = {
  TECHNICIAN: 'technician',
  TECHNICIAN_VIEWER: 'technician_viewer',
};

export const AdminStep = {
  PERSON_ADD_DETAILS: {
    value: 'personAddDetails',
    defaultTranslation: 'Add Details',
  },
  PERSON_APPLY_ASSETS: {
    value: 'personApplyAssets',
    defaultTranslation: 'Apply Assets',
  },
  PERSON_ASSIGN_ASSETS: {
    value: 'personAssignAssets',
    defaultTranslation: 'Assign Assets',
  },
  PERSON_ASSIGN_USER_ROLES: {
    value: 'personAssignUserRoles',
    defaultTranslation: 'Assign User Roles & Permissions',
  },
};

export const AssetCommand = {
  BATTERY_TEST: 'batteryTest',
  HARD_RESET: 'hardReset',
  IDLE_RESET: 'idleReset',
  LOAD_SHUTDOWN: 'loadShutdown',
  RESET: 'reset',
  START: 'start',
  STOP: 'stop',
  TEST: 'testAlarm',
  EVENTS_REFRESH: 'eventsRefresh',
};

export const AssetCommandDetails = {
  Category: { CONTROLCOMMAND: 'CONTROLCOMMAND', ONDEMANDDATA: 'ONDEMANDDATA' },
  SubCategory: { EVENTDATA: 'EVENTDATA' },
};

export const CustomEventRefreshCommand = {
  canId: 'WTUR.Cmd.GE.EventsRefresh',
  desc: 'Events Refresh',
  commandId: 'eventsRefresh',
};

export const AssetCommandError = {
  COMMANDS: 'commands',
  EXECUTE: 'execute',
  STATUS: 'status',
  COMMNADS_BLOCK_STATUS: 'commandsBlockStatus',
};

export const AssetCommandStatus = {
  BLOCKED: 'BLOCKED',
  COMPLETE: 'COMPLETE',
  COMPLETED: 'COMPLETED',
  INITIATED: 'INITIATED',
  FAIL: 'FAILED',
  IN_PROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  TIME_OUT: 'TIMEOUT',
};

export const CardinalDirection = {
  NORTH: 'n',
  NORTH_NORTH_EAST: 'nne',
  NORTH_EAST: 'ne',
  EAST_NORTH_EAST: 'ene',
  EAST: 'e',
  EAST_SOUTH_EAST: 'ese',
  SOUTH_EAST: 'se',
  SOUTH_SOUTH_EAST: 'sse',
  SOUTH: 's',
  SOUTH_SOUTH_WEST: 'ssw',
  SOUTH_WEST: 'sw',
  WEST_SOUTH_WEST: 'wsw',
  WEST: 'w',
  WEST_NORTH_WEST: 'wnw',
  NORTH_WEST: 'nw',
  NORTH_NORTH_WEST: 'nnw',
};

export const EntityType = {
  CUSTOMER: 'customer',
  EVENT: 'event',
  FLEET: 'fleet',
  // TODO: Remove NEW_TICKET once we're creating draft tickets. Future will use TICKET only.
  NEW_TICKET: 'newticket',
  REGION: 'region',
  ASSET: 'asset',
  SITE: 'site',
  SITES: 'sites',
  WIND_SITE: 'wind',
  STORAGE_SITE: 'storage',
  SOLAR_SITE: 'solar',
  HYBRID_SITE: 'hybrid',
  TICKET: 'ticket',
  TURBINE: 'turbine',
  INVERTER: 'inverter',
  ANOMALY: 'anomaly',
  CASE: 'case',
  TASK: 'task',
  PERSON: 'person',
  BULK_TASK: 'bulkTask',
  SERVICE_GROUP: 'serviceGroup',
  ROLE: 'role',
  HANDLING_PROCEDURE: 'caseprocedure',
  INSPECTION_DAMAGE: 'inspectiondamage',
  FILES: 'files',
  HISTORY: 'history',
  CASE_TEMPLATE: 'caseTemplate',
  ANALYSIS_TEMPLATE: 'AnalysisTemplate',
  MANUAL_ADJUSTMENTS: 'manualadjustment',
};

export const SeverityType = {
  INFORMATION: 'Information',
  ALARM: 'Alarm',
  WARNING: 'Warning',
  CRITICAL: 'Critical',
};

export const AlertsEntityType = {
  SITE: 'Site',
  STORAGE_SITE: 'Storage',
  WIND_TURBINE: 'Wind_Turbine',
  WIND_SITE_CONTROLLER: 'Wind_Site_Controller',
  SUBSTATION: 'Substation',
  INVERTER: 'Inverter',
};

export const UserRoles = {
  SUPER_ADMIN: 'Super_Admin',
};

export const EntityAll = {
  ALL: 'ALL',
};

export const EntityTab = {
  PERFORMANCE: 'performance',
  PRODUCTION: 'production',
  OVERVIEW: 'overview',
  CASE_HISTORY: 'case-history',
  SENSOR_READINGS: 'sensor-readings',
  MESSAGING: 'messaging',
  RESOLUTION: 'resolution',
  REALTIME_INFO: 'realtime-info',
  NOTES: 'notes',
  HISTORY: 'history',
};

export const EntityMode = { CREATE: 'create', DELETE: 'delete', EDIT: 'edit', VIEW: 'view' };

export const DateTimeFormats = {
  // FutureDraft is going to try and reign in all the formats and the defaults below are their preference
  DEFAULT_DATE: 'DD MMM YY',
  DEFAULT_TIME: 'HH:mm',
  DEFAULT_TIME_W_SECS: 'HH:mm:ss',
  DEFAULT_DATE_TIME: 'DD MMM YY HH:mm',
  DEFAULT_DATE_TIME_SECS: 'DD MMM YY HH:mm:ss',
  DEFAULT_DATE_TIME_TIMEZONE: 'DD MMM YY HH:mm zz',
  DEFAULT_DATE_TIME_FORMAT_TIMEZONE: 'DD MMM YY \xa0\xa0\xa0HH:mm \xa0(zz)',
  ENDPOINT_PARAM: 'YYYY-MM-DD',
  ENDPOINT_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  // we were told this is the only format we need to support currently (besides the scheduled date)
  ERP_METADATA_DATE_TIME: 'DD-MMM-YYYY',
  INSPECTIONS_DASHBOARD_DATE_TIME: 'YYYY-MM',
  CASE_TABLE_DATE: 'MM/DD/YY',
  TASKS_DETAIL_PANEL: 'DD MMM YY',
  TASKS_TABLE_DATE: 'DD MMM YY',
  TASKS_TABLE_DATE_TIME: 'DD MMMM YY HH:mm',
  ISSUE_DETAIL_DATE: 'DD MMM YY',
  ISSUE_HEADER_DATE: 'DD MMM YY',
  ISSUE_DETAIL_TIME: 'HH:MM',
  ISSUE_DETAIL_DATE_TIME: 'DD MMM YY HH:mm',
  DATA_EXPLORER_DATE_TIME: 'MM/DD/YYYY',
  POWER_CURVE_DATE_TIME: 'MMM D, YYYY h:mm A zz',
  FILTER_CUSTOM_DATE: 'MM/DD/YYYY',
  FILTER_CUSTOM_MONTH: 'MM/YYYY',
  FILTER_CUSTOM_MONTH_CURRENT: 'DD MMM YY',
  EVENT_DATE_TIME: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  NEW_TASK: 'DD MMM YY',
  NOTES_DATE: 'MM/DD/YYYY',
  NOTES_DATETIME: 'MM/DD/YY HH:mm:ss',
  COMMAND_HISTORY: 'HH:mm, DD MMM YY',
  DATEPICKER_CUSTOM_DATE: 'DD MMM YYYY',
  ALERT_START_TIME: 'HH:mm DD MMM YY',
  NOTIFICATION_TIME: 'HH:mm DD MMM YY',
  MONTHLY_AGGR_DATE: 'MMM-YYYY',
  FILE_DOWNLOAD_DATE_TIME: 'YYYY-MM-DD HHmmss',
  CSV_DOWNLOAD_DATE_TIME: 'YYYY-MM-DD HH:mm:ss zz',
  CREW_TIMING: 'YYYY-MM-DD',
  SNOOZE_END_TIME: 'HH:mm DD MMM YY zz',
  SNOOZE_DATE_FORMAT: 'DD MMM, YYYY',
  SENSOR_READINGS_DATE_TIME: 'DD MMM YY HH:mm:ss.SSS zz',
  DESPATCH_TIME: 'HH:mm a',
  DAY_OF_MONTH: 'DD MMM',
  MAL_TABLE_DATE_TIME: 'DD MMM YY\xa0\xa0\xa0\xa0\xa0HH:mm',
  SR_NOTIFICATION_DATE_TIME: 'HH:mm [(UTC]Z[),] DD MMM YY',
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
};

export const Placeholders = {
  DASH: '-',
  DOUBLE_DASH: '--',
  TRIPLE_DASH: '---',
  TRIPLE_DOT: '...',
};

// issue for consolidating dupe defs:
// https://github.build.ge.com/REN-AWS/digital-windfarm/issues/350
export const KpiCategoryDefs = {
  ACTUAL_PRODUCTION_KPI: 'actualProductionKpi',
  ACTUAL_PRODUCTION: 'actualProduction',
  AVAILABILITY_TECH: 'availabilityTech',
  AVAILABILITY_CONTRACT: 'availabilityContract',
  AVERAGE_WINDSPEED: 'windSpeedAverage',
  CAPACITY_FACTOR: 'capacityFactor',
  CAPACITY_FACTOR_SHORT_KEY: 'cf',
  ENERGY: 'e_mwh',
  POWER: 'power',
  YIELD: 'yld',
  AVAILABILITY: 'avl',
  COST_ASSET: 'costAsset',
  COST_TURBINE: 'costTurbine',
  CUMULATIVE_PRODUCTION: 'cumulativeProduction',
  EVENT_COVERAGE: 'eventCoverage',
  EVENT_RATE: 'eventRate',
  EVENT_DURATION: 'eventDuration',
  EVENT_OCCURRENCE: 'eventOccurrence',
  EVENT_UNAVAILABLE_TIME: 'eventUnavailableTime',
  EVENT_RATES: 'eventRates',
  IU_COVERAGE: 'iuCoverage',
  LOST_PRODUCTION: 'lostProduction',
  NEAR_MISSES: 'nearMisses',
  PBA: 'pba',
  PPI_BANK_CONTRACT: 'ppiBankContract',
  PPI_BANK_LEARNED: 'ppiBankLearned',
  PPI_FULL_PRODUCTION_CONTRACT: 'ppiFullProductionContract',
  PPI_FULL_PRODUCTION_LEARNED: 'ppiFullProductionLearned',
  PPI_UNPRODUCED_ENERGY_CONTRACT: 'ppiUnproducedEnergyContract',
  PPI_UNPRODUCED_ENERGY_LEARNED: 'ppiUnproducedEnergyLearned',
  PRODUCTION_ACTUAL: 'productionActual',
  PRODUCTION_EXPECTED_CONTRACT: 'productionExpectedContract',
  PRODUCTION_EXPECTED_LEARNED: 'productionExpectedLearned',
  PRODUCTION_LOST_CONTRACT: 'productionLostContract',
  PRODUCTION_LOST_LEARNED: 'productionLostLearned',
  PRODUCTION_RATIO: 'productionRatio',
  RELIABILITY_MTBFO: 'reliabilityMTBFO',
  RELIABILITY_MTTR: 'reliabilityMTTR',
  REPORTING_SITES_RATIO: 'reportingSitesRatio',
  REPORTING_ASSETS_RATIO: 'reportingAssetsRatio',
  UNPRODUCED_ENERGY_CONTRACT: 'unproducedEnergyContract',
  UNPRODUCED_ENERGY_LEARNED: 'unproducedEnergyLearned',
  TBA: 'tba',
  SPEND_BUDGET: 'spendBudget',
  INSPECTIONS_UPLOADED: 'inspectionsUploaded',
  ANNOTATIONS_GENERATED: 'annotationsGenerated',
  REPORTS_GENERATED: 'reportsGenerated',
  AVERAGE_CYCLE_TIME_IN_SECONDS: 'averageCycleTimeInSeconds',
  AVERAGE_MANUAL_DAMAGES: 'averageManualDamages',
  EFFICIENCY_STORAGE: 'efficiencyStorage',
  EFFICIENCY: 'efficiency',
  REACTIVE_ENERGY_USAGE: 'reactiveEnergyUsage',
  ACTIVE_ENERGY_USAGE: 'activeEnergyUsage',
  SOC: 'soc',
  SOH: 'soh',
};

export const DefaultKpiCategoryDef = KpiCategoryDefs.TBA;

export const KpiCategoriesHeader = [
  KpiCategoryDefs.TBA,
  //KpiCategoryDefs.AVAILABILITY_CONTRACT,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
  KpiCategoryDefs.PRODUCTION_ACTUAL,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.REPORTING_ASSETS_RATIO,
  KpiCategoryDefs.REPORTING_SITES_RATIO,
];

export const SiteListKpiCategories = [
  KpiCategoryDefs.TBA,
  //KpiCategoryDefs.AVAILABILITY_CONTRACT,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
  KpiCategoryDefs.PRODUCTION_ACTUAL,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.REPORTING_ASSETS_RATIO,
  KpiCategoryDefs.REPORTING_SITES_RATIO,
];

export const SiteKpiHeaderCategories = [
  KpiCategoryDefs.TBA,
  KpiCategoryDefs.AVAILABILITY_CONTRACT,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
  KpiCategoryDefs.PRODUCTION_ACTUAL,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.REPORTING_ASSETS_RATIO,
  KpiCategoryDefs.IU_COVERAGE,
];

export const SolarSiteKpiHeaderCategories = [
  KpiCategoryDefs.ENERGY,
  KpiCategoryDefs.YIELD,
  KpiCategoryDefs.CAPACITY_FACTOR_SHORT_KEY,
  KpiCategoryDefs.AVAILABILITY,
];

export const SolarAssetKpiHeaderCategories = [
  KpiCategoryDefs.ENERGY,
  KpiCategoryDefs.YIELD,
  KpiCategoryDefs.EFFICIENCY,
  KpiCategoryDefs.AVAILABILITY,
];

export const StorageSiteKpiHeaderCategories = [
  KpiCategoryDefs.EFFICIENCY_STORAGE,
  KpiCategoryDefs.ACTIVE_ENERGY_USAGE,
  KpiCategoryDefs.REACTIVE_ENERGY_USAGE,
  KpiCategoryDefs.SOC,
  KpiCategoryDefs.SOH,
  KpiCategoryDefs.AVAILABILITY,
];

export const StorageAssetKpiHeaderCategories = [
  KpiCategoryDefs.EFFICIENCY_STORAGE,
  KpiCategoryDefs.ACTIVE_ENERGY_USAGE,
  KpiCategoryDefs.REACTIVE_ENERGY_USAGE,
  KpiCategoryDefs.SOC,
  KpiCategoryDefs.SOH,
  KpiCategoryDefs.AVAILABILITY,
];

export const StorageAssetKpiHeaderCategoriesApi = [
  KpiCategoryDefs.EFFICIENCY,
  KpiCategoryDefs.ACTIVE_ENERGY_USAGE,
  KpiCategoryDefs.REACTIVE_ENERGY_USAGE,
  KpiCategoryDefs.SOC,
  KpiCategoryDefs.SOH,
  KpiCategoryDefs.AVAILABILITY,
];

export const AssetKpiHeaderCategories = [
  KpiCategoryDefs.TBA,
  KpiCategoryDefs.AVAILABILITY_CONTRACT,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
  KpiCategoryDefs.PRODUCTION_ACTUAL,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.EVENT_RATES,
];

export const FleetKpiHeaderCategories = [
  KpiCategoryDefs.TBA,
  //KpiCategoryDefs.AVAILABILITY_CONTRACT,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.REPORTING_ASSETS_RATIO,
  KpiCategoryDefs.REPORTING_SITES_RATIO,
];

export const PerformanceKpiHeaderCategories = [
  KpiCategoryDefs.INSPECTIONS_UPLOADED,
  KpiCategoryDefs.ANNOTATIONS_GENERATED,
  KpiCategoryDefs.REPORTS_GENERATED,
  KpiCategoryDefs.DAYS_FROM_UPLOAD_TO_GEN,
];

export const ConditionsKpiCategoryDefs = {
  TEMPERATURE: 'temperature',
  WIND: 'wind',
  WIND_DIRECTION: 'windDirection',
};

export const DateRange = {
  CUSTOM: 'custom',
  MONTH_TO_DATE: 'month',
  WEEK_TO_DATE: 'week',
  YEAR_TO_DATE: 'year',
  CURRENT_DAY: 'day',
  LAST_30_DAYS: 'last_30_days',
  LAST_90_DAYS: 'last_90_days',
  LAST_7_DAYS: 'last_7_days',
  LAST_WEEK: 'last_week',
  LAST_12_MONTHS: 'last_12_months',
  LAST_MONTH: 'last_month',
  LAST_DAY: 'last_day',
  NEXT_DAY: 'next_day',
  NEXT_WEEK: 'next_week',
};

export const TimeAggr = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
};

export const Weather = {
  SUNNY: 'sunny',
  MOSTLY_SUNNY: 'mostly-sunny',
  CLOUDY: 'cloudy',
  RAIN: 'rain',
  SNOW: 'snow',
};

export const Alerts = {
  PRECIPITATION: 'precipitation',
  LIGHTNING: 'lightning',
  TEMP_COLD: 'temp-cold',
  TEMP_HEAT: 'temp-heat',
  WIND: 'wind',
  TEMPORARY_CONFIG_CHANGE: 'temporary_config_change',
  PLANNED_OUTAGE: 'planned_outage',
};

export const SidebarViews = {
  ALERT: 'ALERT',
  WATCHLIST: 'WATCHLIST',
  CUSTOMER: 'CUSTOMER',
  REGION: 'REGION',
  SITE: 'SITE',
  ASSET: 'ASSET',
  MARKET_PRICE: 'MARKET_PRICE',
};

export const PresentationTypes = {
  LIST: 'list',
  MAP: 'map',
  GRID: 'grid',
  DETAIL: 'detail',
};

export const StatusLabel = {
  DANGER: 'danger',
  AMBER: 'amber',
  WARNING: 'warning',
  DEFAULT: 'default',
};

// reconcile this with constant defined in TurbineDetails
export const EventMetric = {
  START: 'start',
};

export const KeyCode = {
  CANCEL: 3,
  HELP: 6,
  BACK_SPACE: 8,
  TAB: 9,
  CLEAR: 12,
  RETURN: 13,
  ENTER: 14,
  SHIFT: 16,
  CONTROL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  PRINTSCREEN: 44,
  INSERT: 45,
  DELETE: 46,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  SEMICOLON: 59,
  EQUALS: 61,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  CONTEXT_MENU: 93,
  NUMPAD0: 96,
  NUMPAD1: 97,
  NUMPAD2: 98,
  NUMPAD3: 99,
  NUMPAD4: 100,
  NUMPAD5: 101,
  NUMPAD6: 102,
  NUMPAD7: 103,
  NUMPAD8: 104,
  NUMPAD9: 105,
  MULTIPLY: 106,
  ADD: 107,
  SEPARATOR: 108,
  SUBTRACT: 109,
  DECIMAL: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  F13: 124,
  F14: 125,
  F15: 126,
  F16: 127,
  F17: 128,
  F18: 129,
  F19: 130,
  F20: 131,
  F21: 132,
  F22: 133,
  F23: 134,
  F24: 135,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  COMMA: 188,
  PERIOD: 190,
  SLASH: 191,
  BACK_QUOTE: 192,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRACKET: 221,
  QUOTE: 222,
  META: 224,
};

export const TaskCategory = {
  SERVICE: 'service',
  REMOTE: 'remote',
};

export const TaskPriority = {
  IMMEDIATE: 'immediate',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const TaskDueDateOffset = {
  IMMEDIATE: 2,
  HIGH: 14,
  MEDIUM: 30,
  LOW: 180,
};

export const TaskRecurrence = {
  NONE: 'none',
  ANNUAL: 'annual',
  SEMI_ANNUAL: 'semi-annual',
  MONTHLY: 'monthly',
  CUSTOM: 'custom',
};

export const TaskRecurrenceEnds = {
  END_BY_DATE: 'EndByDate',
  END_AFTER: 'EndAfter',
};

export const TasksTracker = {
  ALL_OPEN: 'all_open',
  NEW: 'new',
  OVERDUE: 'overdue',
  UPCOMING_DUE: 'upcoming_due',
  RECENTLY_CLOSED: 'recently_closed',
  ALL_COMPLETED: 'all_completed',
};

export const RepeatInterval = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
};

export const TaskLabors = {
  EST_DURATION_HRS: 'estDurationHours',
  EST_DURATION_MINUTES: 'estDurationMinutes',
  EST_TECHS: 'estTechs',
};
export const Recurrence = {
  RECURRENCE: 'recurrence',
  RECURRENCE_TYPE: 'recurrenceType',
  RECURRENCE_ENDBY_DATE: 'recurrenceEndByDate',
  RECURRENCE_ENDBY_COUNT: 'recurrenceEndByCount',
  RECURRENCE_CYCLE: 'recurrenceCycle',
  RECURRENCE_CYCLE_TYPE: 'recurrenceCycleType',
};

export const TaskSource = {
  APM: 'apm',
  E_SCADA: 'e-scada',
  SITE: 'site',
};

export const AdminEntities = {
  WORKER: 'worker',
  USER: 'user',
};

export const TaskSourceField = {
  ANOMALY: 'anomalyCase',
  APM_CASE: 'apm-case',
  MAINTENANCE: 'maintenance',
  EHS: 'ehs',
  INSPECTION_REQUEST: 'inspection-request',
  WARNING: 'warning',
  TEMP_CONFIG_CHANGE: 'temp-config-change',
  FAA_LIGHT_DEFECT: 'faa-light-defect',
  GRID_EVENT: 'grid-event',
  BLADE_REPAIR: 'blade-repair',
  EMERGING_ISSUE: 'emerging-issue',
  ETC: 'etc',
  EXTERNAL_CLEANING: 'external-cleaning',
  FAULTED: 'faulted',
  FLEET_PROGRAMS: 'fleet-programs',
  IMPACTED: 'impacted',
  LUBE_MANAGEMENT: 'lube-management',
  MCE: 'mce',
  NO_COMM: 'no-comm',
  ODOMETER_MAINTENANCE: 'odometer-maintenance',
  PROD_INFO_LETTER: 'prod-info-letter',
  PUNCHLIST: 'punchlist',
  RETROFIT: 'retrofit',
  STOPPED: 'stopped',
  TIL: 'til',
  UNPLANNED_MAINT: 'unplanned-maint',
  WATCHLIST: 'watchlist',
  OTHER: 'other',
  BOP: 'bop',
  COMM_LOSS: 'comm-loss',
  OTHER_SITE: 'other-site',
  MANUAL: 'manual',
  PIL: 'pil',
  CUSTOMER_REQUEST: 'customerRequest',
  ANOMALYCASEMANUAL: 'anomalyCaseManual',
  REALTIMECASES: 'realtimeCasesFaulted',
  MANUALSITE: 'manualSite',
};

export const Placements = {
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  TOP: 'top',
};

export const DataExplorerFilterTypes = {
  STATE: 'state',
  SIGNALS: 'signals',
};

export const AssetOverviewTabs = {
  DETAILS: 'details',
  DATA_EXPLORER: 'data-explorer',
  OVERVIEW: 'overview',
};

export const SiteTypeTabs = {
  ALL: 'all',
  WIND: 'wind',
  SOLAR: 'solar',
  STORAGE: 'storage',
};

export const DataExplorerLayouts = {
  DETAIL: 'detail',
  GRID: 'grid',
};

export const Feature = {
  ANALYZE: 'analyze',
  COMMON: 'common',
  INSPECTIONS: 'inspections',
  MANAGE: 'manage',
  MONITOR: 'monitor',
};

export const SegmentOffering = {
  ANALYZE: 'dav',
  ANALYZE_A: 'dav-a',
  ANALYZE_P: 'dav-p',
  COMMON: 'cmn',
  INSPECTIONS: 'rei',
  MANAGE: 'mwp',
  MANAGE_A: 'mwp-a',
  MANAGE_P: 'mwp-p',
  MONITOR: 'rtmc',
  MONITOR_A: 'rtmc-a',
  MONITOR_P: 'rtmc-p',
  MONITOR_ONPREM: 'rtmo',
};

export const FeatureSegmentOfferingDict = {
  [SegmentOffering.ANALYZE]: Feature.ANALYZE,
  [SegmentOffering.COMMON]: Feature.COMMON,
  [SegmentOffering.INSPECTIONS]: Feature.INSPECTIONS,
  [SegmentOffering.MANAGE]: Feature.MANAGE,
  [SegmentOffering.MONITOR]: Feature.MONITOR,
};

export const SegmentTranslated = {
  GLOBAL: 'Global',
  ANALYZE: 'Analyze',
  EXECUTE: 'Execute',
  MONITOR: 'Monitor',
  ANALYZE_ADVANCED: 'Analyze - Advanced',
  EXECUTE_ADVANCED: 'Execute - Advanced',
  MONITOR_ADVANCE: 'Monitor - Advanced',
};

export const PermissionScope = {
  ADMIN: 'admin',
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  VIEW: 'view',
};

export const Capability = {
  ASSET_MANAGEMENT: { value: 'asset-management', segmentOffering: SegmentOffering.COMMON },
  COMPONENT_MANAGEMENT: { value: 'component-management', segmentOffering: SegmentOffering.COMMON },
  NOTES_AND_ATTACHMENTS: {
    value: 'notes-and-attachments',
    segmentOffering: SegmentOffering.COMMON,
  },
  INTERNAL_ACCESS: {
    value: 'internal-access',
    segmentOffering: SegmentOffering.COMMON,
  },
  NOTES_ADMIN: {
    value: 'notes-admin',
    segmentOffering: SegmentOffering.COMMON,
  },
  FULL_ADMIN: { value: 'full-admin', segmentOffering: SegmentOffering.COMMON },
  GENERAL_REPORTING: { value: 'general-reporting', segmentOffering: SegmentOffering.COMMON },
  REPORTING_TEMPLATES: { value: 'reporting-templates', segmentOffering: SegmentOffering.COMMON },

  CASES_RECOMMENDATIONS: {
    value: 'cases-and-recommendations',
    segmentOffering: SegmentOffering.ANALYZE,
  },
  CORE_KPIS: { value: 'core-kpis', segmentOffering: SegmentOffering.ANALYZE },
  ADVANCED_KPIS: { value: 'adv-kpis', segmentOffering: SegmentOffering.ANALYZE },
  FRO_KPIS: { value: 'fro-kpis', segmentOffering: SegmentOffering.ANALYZE },
  DATA_EXPLORER: { value: 'data-explorer', segmentOffering: SegmentOffering.ANALYZE },
  ANALYTICS: { value: 'analytics', segmentOffering: SegmentOffering.ANALYZE },

  MOBILE_ASSIGNMENTS: { value: 'mobile-assignments', segmentOffering: SegmentOffering.MANAGE },
  PERSONNEL_MANAGEMENT: { value: 'personnel-management', segmentOffering: SegmentOffering.MANAGE },
  WORK_PLAN: { value: 'work-plan', segmentOffering: SegmentOffering.MANAGE },
  FIELD_TASKS: { value: 'field-tasks', segmentOffering: SegmentOffering.MANAGE },
  FIELD_TASK_APPROVER: { value: 'field-task-approver', segmentOffering: SegmentOffering.MANAGE },
  WORK_ORDERS: { value: 'work-orders', segmentOffering: SegmentOffering.MANAGE },
  LIMITED_USER_MANAGEMENT: {
    value: 'limited-user-management',
    segmentOffering: SegmentOffering.COMMON,
  },

  // monitor
  REAL_TIME_PAGES: { value: 'real-time-pages', segmentOffering: SegmentOffering.MONITOR },
  ROC_TASKS: { value: 'roc-tasks', segmentOffering: SegmentOffering.MONITOR },

  // monitor onprem
  REAL_TIME_PAGES_ONPREM: {
    value: 'real-time-pages',
    segmentOffering: SegmentOffering.MONITOR_ONPREM,
  },
  CASES: { value: 'cases', segmentOffering: SegmentOffering.MONITOR_ONPREM },
  NOTIFICATION_LIST: {
    value: 'notification-list',
    segmentOffering: SegmentOffering.MONITOR_ONPREM,
  },
  ALARM_MANAGEMENT: { value: 'alarm-management', segmentOffering: SegmentOffering.MONITOR_ONPREM },
  FAULT_HANDLING: {
    value: 'fault-handling-procedures',
    segmentOffering: SegmentOffering.MONITOR_ONPREM,
  },
  CAUSAL_ALARM: { value: 'causal-alarm', segmentOffering: SegmentOffering.MONITOR_ONPREM },
  EVENTS_ACTIONS: { value: 'rules-engine', segmentOffering: SegmentOffering.MONITOR_ONPREM },
  ROC_COMMANDS: { value: 'roc-commands', segmentOffering: SegmentOffering.MONITOR_ONPREM },
  ALERTS: { value: 'alerts', segmentOffering: SegmentOffering.MONITOR_ONPREM },
  SPECIAL_INSTRUCTION: {
    value: 'special-instruction',
    segmentOffering: SegmentOffering.MONITOR_ONPREM,
  },
  EMAIL_NOTIFICATION: {
    value: 'email-notification',
    segmentOffering: SegmentOffering.MONITOR_ONPREM,
  },
  CONTACTS: { value: 'contacts', segmentOffering: SegmentOffering.MONITOR_ONPREM },

  // inspections
  // do we still need this or can this roll under cmn asset-management permission?
  REI_ASSET_MANAGEMENT: { value: 'asset-management', segmentOffering: SegmentOffering.INSPECTIONS },
  REI_GENERATE_REPORT: { value: 'generate-report', segmentOffering: SegmentOffering.INSPECTIONS },
  REI_INSPECTION_DASHBOARD: {
    value: 'inspection-dashboards',
    segmentOffering: SegmentOffering.INSPECTIONS,
  },
  REI_INSPECTION_REPORTS: {
    value: 'inspection-reports',
    segmentOffering: SegmentOffering.INSPECTIONS,
  },
  REI_LISTS: { value: 'lists', segmentOffering: SegmentOffering.INSPECTIONS },
  REI_POST_PROCESS: { value: 'post-process', segmentOffering: SegmentOffering.INSPECTIONS },
  REI_RESULTS: { value: 'results', segmentOffering: SegmentOffering.INSPECTIONS },
  REI_SERVICE_NOW_PAC: { value: 'servicenow-pac', segmentOffering: SegmentOffering.COMMON },
  // reporting
  REPORTING_TEMPLATE: { value: 'reporting-templates', segmentOffering: SegmentOffering.COMMON },
  // Manual Adjustments
  CONTRACTUAL_AVAILABILITY_MAL: {
    value: 'contractual-availability',
    segmentOffering: SegmentOffering.ANALYZE,
  },
  CONTRACTUAL_AVAILABILITY_MAL_APPROVER: {
    value: 'contractual-availability-approver',
    segmentOffering: SegmentOffering.ANALYZE,
  },
};

export const FeatureCapability = {
  CONTRACTUAL_REPORTING_OVERVIEW: [
    {
      capability: Capability.CORE_KPIS,
      scopes: [PermissionScope.VIEW],
    },
  ],
};

export const PanelColumnWidths = {
  XXSMALL: '20px',
  XSMALL: '40px',
  SMALL: '60px',
  MEDIUM: '90px',
  LARGE: '120px',
  XLARGE: '250px',
  XXLARGE: '375px',
};

// used by query cache
// can look into providing key as function of params instead
export const QueryKey = {
  ALL_SITES_SITE_DATA: 'allSiteData',
  ALERT_MAIL_TEMPLATE: 'alertMailTemplate',
  ALERT_TEMPLATE: 'alertTemplate',
  ALERT_TYPES: 'alertTypes',
  ALERTS: 'alerts',
  ANOMALIES_TABLE: 'anomaliesTable',
  ANOMALIES_TABLE_REFRESH: 'anomaliesTableRefresh',
  ANOMALIES_TABLE_PAGE_PARAMS: 'anomaliesTablePageParams',
  ANOMALIES_FOR_SITES: 'fetchCasesForSites',
  ASSET_COMMAND_LIST: 'assetCommandList',
  ASSET_COMMAND_HISTORY: 'assetCommandHistory',
  ASSET_COMMAND_STATUS: 'assetCommandStatus',
  ASSET_COMMAND_BLOCK_STATUS: 'assetCommandBlockStatus',
  ASSET_IEC_DATA: 'assetIecData',
  ASSET_CONDITION_DATA: 'assetConditionData',
  ASSET_KPI_DATA: 'assetKpiData',
  ASSET_POWER_CURVE: 'powerCurve',
  ASSET_SIGNAL_MAPS: 'fetchAssetSignalMaps',
  TECHNICIAN_CREW: 'technicianCrew',
  CASE_ANALYSIS_TEMPLATE: 'caseAnalysisTemplate',
  ALL_CASE_ANALYSIS_TEMPLATE: 'allCaseAnalysisTemplate',
  ANALYSIS_TEMPLATE_DATA: 'analysisTemplateData',
  CASE_TEMPLATE: 'caseTemplate',
  CASE_DETAIL: 'caseDeatil',
  CASE_SENSOR_DATA: 'caseSensorData',
  CONDITIONS_KPI_DATA: 'conditionsKpiData',
  CONTRACTUAL_REPORTING_MAL_EVENTS: 'contractualReportingMalEvents',
  CONTRACTUAL_REPORTING_MAL_CATEGORY: 'contractualReportingMalCategory',
  CUSTOMERS: 'customers',
  DATA_EXPLORER_OVERVIEW: 'dataExplorerOverview',
  ERP_NOTIFICATIONS: 'erpNotifications',
  ERP_REQUEST: 'erpRequest',
  ERP_REQUEST_STATUS: 'erpRequestStatus',
  ERP_TEMPLATE: 'erpTemplate',
  ERP_TRANSLATIONS: 'erpTranslations',
  EVENTS: 'events',
  FLEET_KPI_DATA: 'fleetKpiData',
  FLEET_SITE_PERFORMANCE: 'fleetSitePerformance',
  FLEET_SITE_PERFORMANCE_TABLE: 'fleetSitePerformanceTable',
  INSPECTIONS_PERFORMANCE_DASHBOARD: 'inspectionsPerformanceDashboard',
  INSPECTIONS_PERFORMANCE_DASHBOARD_REPORT: 'inspectionsPerformanceDashboardReport',
  INSPECTIONS_SIGNED_URLS: 'inspectionsSignedUrls',
  KPI_DATA: 'kpiData',
  MAIL: 'mail',
  NAVIGATION: 'navigation',
  PEOPLE_TABLE: 'peopleTable',
  PERSON: 'person',
  PERMISSIONS: 'permissions',
  REGION_SITE_DATA: 'regionSiteData',
  REGIONS: 'regions',
  REPORTING_ASSET_EVENTS: 'reportingAssetEvents',
  REPORTING_ASSET_STATE: 'reportingAssetState',
  REPORTING_SIGNAL_DATA: 'reportingSignalData',
  ROC_LOGS_ACTIONS: 'rocLogsActions',
  ROC_LOGS_ACTION_TYPES: 'rocLogsActionType',
  ROC_LOGS_TEMPLATE: 'rocLogsTemplate',
  ROLES_TABLE: 'rolesTable',
  SERVICE_GROUPS: 'serviceGroups',
  SITE_CONTROLLERS: 'siteControllers',
  SITE_PERFORMANCE: 'sitePerformance',
  SITE_REAL_TIME_INFO: 'siteRealTimeInfo',
  SITE_KPI_DATA: 'siteKpiData',
  SITES: 'sites',
  SITES_KPI_DATA: 'sitesKpiData',
  ASSET_REAL_TIME_INFO: 'assetRealTimeInfo',
  ASSET_EVENTS_INFO: 'assetEventsInfo',
  ASSET_REALTIME_DATA: 'assetRTData',
  SITES_AGGREGATED_DATA: 'sitesAggRealTimeInfo',
  NOTES_DETAILS: 'notesDetails',
  CASE_NOTES: 'caseNotes',
  TASK_NOTES: 'taskNotes',
  CASE_TASKS: 'caseTasks',
  SUBSTATIONS: 'substations',
  INIT_ROC_STATIONS: 'rocstations',
  INIT_FULL_SERVICE_GROUPS: 'ServiceGroupsWithFullSites',
  STATIC_DATA: 'staticData',
  TASKS: 'tasks',
  TASK_SCHEDULE: 'taskSchedule',
  PREFS: 'prefs',
  VIEW_PREFS: 'viewPrefs',
  MONITOR_CASES: 'monitorCases',
  ASSETS_CASES: 'assetCases',
  FILE_DATA: 'fileData',
  GET_CONFIG: 'getConfig',
  FILE_DOWNLOAD_DATA: 'fileDownloadData',
  ADD_NOTE: 'addNote',
  EDIT_NOTE: 'editNote',
  ASSETS_CASE_METRICS: 'assetsCaseMetrics',
  MONITOR_ALERTS: 'monitorAlerts',
  ALL_TENANTS: 'allTenants',
  INFINITE_PEOPLE: 'allPeople',
  EVENT_NOTES: 'eventNotes',
  OEM_ASSET_DATA: 'oemAssetData',
  REGION_IEC_ASSETS: 'regionIecAssets',
  ALL_SITES_IEC_DATA: 'allSitesIecData',
  ALL_SITES_IEC_ASSETS: 'allSitesIecAssets',
  SITE_IEC_DATA: 'siteIecData',
  SITE_IEC_ASSETS: 'siteIecAssets',
  CASE_PROCEDURE: 'caseProcedure',
  ROLES_PERMISSIONS: 'RolesAndPermissions',
  ROLE_SUBSETS: 'roleSubsets',
  ACTIVE_CASE_COUNT: 'activeCaseCount',
  DISTRIBUTION_LIST: 'distributionList',
  ALL_DISTRIBUTION_LIST: 'allDistributionList',
  HANDLING_PROCEDURES: 'handlingProcedures',
  GET_HANDLING_PROCEDURE: 'getHandlingProcedure',
  EVENT_MAPS: 'eventMaps',
  EVENT_DESC: 'eventDesc',
  EVENT_MANAGEMENT_LIST: 'eventManagementList',
  HANDLING_PROCEDURE: 'handlingProcedure',
  LOOKUP_PERSON: 'lookupPerson',
  FETCH_PERSON: 'fetchPerson',
  FETCH_MULTIPLE_PERSON: 'fetchMultiplePerson',
  ASSET_TASK_HISTORY: 'assetTaskHistory',
  LIMITED_ROLES: 'limitedRoles',
  WORKER: 'worker',
  CREW: 'crew',
  CREWSG: 'crewSg',
  ALL_CREW: 'allCrew',
  DISPATCH: 'dispatch',
  FETCH_AUDIT_HISTORY: 'fetchAuditHistory',
  REPORT_TEMPLATE: 'reportTemplate',
  ASSET_INFO_DATA: 'assetInfoData',
  COMPONENT_INFO_DATA: 'componentInfoData',
  ASSET_TASKS: 'assetTasks',
  WIDGET_DATA: 'widgetData',
  FETCH_OIDC_INFO: 'fetchOidcInfo',
  SENT_REPORT: 'sentReports',
  ACTIVE_SPECIAL_INSTRUCTIONS: 'specialInstructions',
  ERP_WORKER: 'erp-worker',
  ALERT_NOTES: 'alertNotes',
  CASE_TEMPLATE_TABLE: 'caseTemplateTable',
  OPEN_CASES: 'openCases',
  CLOSED_CASES: 'closedCases',
  VIEW_COUNT: 'viewCount',
  FEATURE_FLAGS: 'featureFlags',
};

export const TableFilterTypes = {
  CHECKBOXES: 'checkboxes',
  TEXT: 'text',
  DATE: 'date',
  SEARCH: 'search',
  NUMBER: 'number',
  PROGRESSIVE_DROPDOWNS: 'progressiveDropdowns',
  PROGRESSIVE_MULTI_SELECT: 'progressiveDropdownsMultiSelect',
};

export const NotificationType = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  FAILURE: 'failure',
};

export const NotificationActionType = {
  SITE_DETAILS: 'siteDetails',
  TURBINE_DETAILS: 'turbineDetails',
  EVENT_DETAILS: 'eventDetails',
};

export const TaskTemplateModes = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};

export const PartsField = {
  EXPECTED_PARTS: 'expectedParts',
  PARTS_CONSUMED: 'consumedParts',
};

export const TaskTemplateSections = {
  TASK_DETAIL: 'task_detail',
  RECURRENCE: 'recurrence',
  LABOR_ESTIMATES: 'labor_estimates',
  SCHEDULE: 'schedule',
  EXPECTED_PARTS: 'expected_parts',
  NOTE_ATTACHMENT: 'note_attachment',
  SEND_NOTIFICATION: 'send_notification',
  TIMING: 'timing',
  RESOLUTION_NOTE: 'resolution_note',
  PARTS_CONSUMED: 'parts_consumed',
  ASSIGNEDTECH: 'assigned_tech',
};

export const SortType = {
  TURBINE_STATUS: 'turbine_status',
  FAULT_CODE: 'fault_code',
};

export const PromiseStatus = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export const EmailTypes = {
  ACTIONABLE_EMAIL: 'actionable',
  INFORMATIONAL_EMAIL: 'informational',
};

export const AlertType = {
  PLANNED_OUTAGE: 'PLANNED_OUTAGE',
  UNPLANNED_OUTAGE: 'UNPLANNED_OUTAGE',
  TEMPORARY_CONFIG_CHANGE: 'TEMPORARY_CONFIG_CHANGE',
  WORKER_PRESENT: 'WORKER_PRESENT',
  NOCOMM_OUTAGE: 'NOCOMM_OUTAGE',
  COMMISSIONING_OUTAGE: 'COMMISSIONING_OUTAGE',
  RELIABILITY_RUN: 'RELIABILITY_RUN',
  NOTAM: 'NOTAM',
  NON_OPERATING_ASSET: 'NON_OPERATING_ASSET',
};

export const AlertDLType = {
  PLANNED_OUTAGE: 'PLANNED_OUTAGE',
  UNPLANNED_OUTAGE: 'UNPLANNED_OUTAGE',
  NOCOMM_OUTAGE: 'NOCOMM_OUTAGE',
  COMMISSIONING_OUTAGE: 'COMMISSIONING_OUTAGE',
  WORKER_PRESENT: 'WORKER_PRESENT',
  RELIABILITY_RUN: 'RELIABILITY_RUN',
  NON_OPERATING_ASSET: 'NON_OPERATING_ASSET',
  TEMPORARY_CONFIG_ACTIONABLE: 'TEMPORARY_CONFIG_ACTIONABLE',
  TEMPORARY_CONFIG_INFORMATIONAL: 'TEMPORARY_CONFIG_INFORMATIONAL',
  NOTAM: 'NOTAM',
};

export const AlertTypeValue = {
  PLANNED_OUTAGE: 'Planned Outage',
  TEMPORARY_CONFIG_CHANGE: 'Temporary Config Change',
  WORKER_PRESENT: 'Worker Present',
  NOCOMM_OUTAGE: 'NoComm Outage',
  COMMISSIONING_OUTAGE: 'Commissioning',
  RELIABILITY_RUN: 'Reliability Run',
  UNPLANNED_OUTAGE: 'UnPlanned Outage',
  NOTAM: 'NOTAM',
  NON_OPERATING_ASSET: 'Non Operating Asset',
};

export const AlertEmailType = {
  PLANNED_OUTAGE: 'PLANNED_OUTAGE',
  UNPLANNED_OUTAGE: 'UNPLANNED_OUTAGE',
  TEMPORARY_CONFIG_CHANGE_ACTIONABLE: 'TEMPORARY_CONFIG_CHANGE_ACTIONABLE',
  TEMPORARY_CONFIG_CHANGE_INFORMATIONAL: 'TEMPORARY_CONFIG_CHANGE_INFORMATIONAL',
  TEMPORARY_CONFIG_ACTIONABLE: 'TEMPORARY_CONFIG_ACTIONABLE',
  TEMPORARY_CONFIG_INFORMATIONAL: 'TEMPORARY_CONFIG_INFORMATIONAL',
  WORKER_PRESENT: 'WORKER_PRESENT',
  NOCOMM_OUTAGE: 'NOCOMM_OUTAGE',
  COMMISSIONING_OUTAGE: 'COMMISSIONING_OUTAGE',
  RELIABILITY_RUN: 'RELIABILITY_RUN',
  NOTAM: 'NOTAM',
  NON_OPERATING_ASSET: 'NON_OPERATING_ASSET',

  BULK_PLANNED_OUTAGE: 'BULK_PLANNED_OUTAGE',
  BULK_UNPLANNED_OUTAGE: 'BULK_UNPLANNED_OUTAGE',
  BULK_TEMPORARY_CONFIG_CHANGE_ACTIONABLE: 'BULK_TEMPORARY_CONFIG_CHANGE_ACTIONABLE',
  BULK_TEMPORARY_CONFIG_CHANGE_INFORMATIONAL: 'BULK_TEMPORARY_CONFIG_CHANGE_INFORMATIONAL',
  BULK_WORKER_PRESENT: 'BULK_WORKER_PRESENT',
  BULK_NOCOMM_OUTAGE: 'BULK_NOCOMM_OUTAGE',
  BULK_COMMISSIONING_OUTAGE: 'BULK_COMMISSIONING_OUTAGE',
  BULK_RELIABILITY_RUN: 'BULK_RELIABILITY_RUN',
  BULK_NOTAM: 'BULK_NOTAM',
  BULK_NON_OPERATING_ASSET: 'BULK_NON_OPERATING_ASSET',
};

export const AlertSortOrder = {
  WORKER_PRESENT: 1,
  PLANNED_OUTAGE: 2,
  UNPLANNED_OUTAGE: 3,
  TEMPORARY_CONFIG_CHANGE: 3,
  NOTAM: 4,
  COMMISSIONING_OUTAGE: 5,
  NOCOMM_OUTAGE: 6,
  RELIABILITY_RUN: 7,
};

// what values will the backend use for these?
export const AlertStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
};

export const AlertStatusCodeMapping = {
  EXPIRED: -1,
  OPEN: 1,
  CLOSED: 2,
};

export const ControllerFileTypes = {
  INVALID_DATE: 'Invalid Date',
  DCAST: 'dcaST',
  TXT: 'txt',
  CSV: 'csv',
};

// TODO: look at consolidating this into a single enum
// also give it a more permissions specific name (not just forms)
export const FormMode = {
  ...TaskTemplateModes,
  ADMIN: 'admin',
  DELETE: 'delete',
};

export const TemplateFieldType = {
  DATE: 'date',
  DATE_TIME: 'dateTime',
  NUMBER: 'number',
  SELECT: 'select',
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

export const AlertTemplateFieldType = {
  ...TemplateFieldType,
  BLOCKED_ACTIONS: 'blockedActions',
};

export const CalendarRanges = {
  DAY: 'day',
  WEEK: 'isoweek', //correcting the calendar range as isoweek instead of week to get correct ranges
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};

export const CalendarFilters = {
  SOURCE: 'source',
  WORKSCOPE: 'workScope',
  PRIORITY: 'priority',
  STATUS: 'status',
  FLAG: 'flag',
};

export const AlertDataSource = {
  EVENTS: 'events',
};

// TODO include all the case status here
export const CaseStatus = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  UNKNOWN: 'UNKNOWN',
  NEW: 'NEW',
  IN_PROGRESS: 'IN PROGRESS',
  RETURNED: 'RETURNED',
  SNOOZED: 'SNOOZE',
  PENDING_REPAIR: 'PENDING REPAIR',
  PENDING_INSPECTION: 'PENDING INSPECTION',
};

export const WeekNames = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const DefaultWeekNames = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

export const AnomaliesTableCaseStatus = [
  CaseStatus.OPEN,
  CaseStatus.NEW,
  CaseStatus.RETURNED,
  CaseStatus.IN_PROGRESS,
  CaseStatus.SNOOZED,
  CaseStatus.PENDING_REPAIR,
];

export const MonitorCaseStatus = {
  OPEN: 'Open',
  CLOSED: 'closed',
  UNKNOWN: 'unknown',
  NEW: 'new',
  IN_PROGRESS: 'inProgress',
  RETURNED: 'returned',
  SNOOZED: 'snoozed',
};

export const CaseType = {
  // all cloud-based cases map to anomaly for now
  ANOMALY: 'anomaly',
  UNKNOWN: 'unknown',
  FLAGGED: 'flagged',
  FAULTED: 'faulted',
  SITE_NO_COMM: 'siteNoComm',
  FLEET_NO_COMM: 'fleetNoComm',
  STOPPED: 'stopped',
  EXTERNAL_API: 'externalApi',
  ASSET_NO_COMM: 'assetNoComm',
  BOP_BREAKER: 'bopBreaker',
  RNS: 'rns',
};

export const CaseTypeLabel = {
  [CaseType.FLAGGED]: 'flagged',
  [CaseType.FAULTED]: 'faulted',
  [CaseType.SITE_NO_COMM]: 'system',
  [CaseType.FLEET_NO_COMM]: 'system',
  [CaseType.STOPPED]: 'system',
  [CaseType.EXTERNAL_API]: 'external',
  [CaseType.ASSET_NO_COMM]: 'system',
  [CaseType.BOP_BREAKER]: 'system',
  [CaseType.RNS]: 'RNS',
};

export const IssueType = {
  OPEN_CASES: 'open-cases',
  CLOSED_CASES: 'closed-cases',
  OPEN_TASKS: 'open-tasks',
  CLOSED_TASKS: 'closed-tasks',
  ACTIVE_ALARMS: 'active-alarms',
  FIELD_TASK: 'field-task',
};

// statuses from /diagnostics/v1/cases/assets endpoint
export const AnomalyStatus = {
  PENDING_INSPECTION: 'PENDING INSPECTION',
  PENDING_REPAIR: 'PENDING REPAIR',
  NEW: 'NEW',
  RETURNED: 'RETURNED',
  IN_PROGRESS: 'IN PROGRESS',
  CLOSED: 'CLOSED',
  SNOOZED: 'SNOOZE',
};

export const AnomalyStatusMap = {
  [AnomalyStatus.PENDING_INSPECTION]: 'pendingInspection',
  [AnomalyStatus.PENDING_REPAIR]: 'pendingRepair',
  [AnomalyStatus.NEW]: 'new',
  [AnomalyStatus.RETURNED]: 'returned',
  [AnomalyStatus.CLOSED]: 'closed',
  [AnomalyStatus.IN_PROGRESS]: 'inProgress',
  [AnomalyStatus.SNOOZED]: 'snoozed',
};

// rename this but using for date values that come from task/alert template services
export const TemplateDateValue = {
  CURRENT_DATE: 'currentDate',
};

export const SnoozeType = {
  SNOOZE_30_MINUTES: '30-minutes',
  SNOOZE_1_HOUR: '1-hour',
  SNOOZE_2_HOUR: '2-hours',
  SNOOZE_3_HOUR: '3-hours',
};

export const ReasonType = {
  BATTERIES_CHARGING: 'batteries-charging',
  COMPONENT_COOLING: 'component-cooling',
  OWNED_BY_SITE: 'owned-by-site',
  COMPONENT_WARMING: 'component-warming',
};

// move this somewhere else?
export const Pattern = {
  // pretty basic, can revise if it doesn't cover all our cases
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  ANY_CHAR_BEFORE_AT_SYMBOL_EMAIL: /^.+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // this allows any character before the @ symbol
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
};

// Phone Patterns Supported
// (123) 456-7890
// (123)456-7890
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725

// standard template var names for interpolating values into templates from backend
export const TemplateVar = {
  ASSET_NAME: 'assetName',
  SITE_NAME: 'siteName',
};

export const RocLogsActionType = {
  PHONE_CALL: 'phoneCall',
  ACTION_TAKEN: 'actionTaken',
};

export const RocLogsActionList = {
  RESCUE_BYPASS: 'Rescue Bypass',
};

// TODO: can revisit but setting up as enum flags for aggregate values
const FileTypeFlag = {
  CSV: 1 << 0,
  JPEG: 1 << 1,
  MSG: 1 << 2,
  PDF: 1 << 3,
  PNG: 1 << 4,
  PPT: 1 << 5,
  PPTX: 1 << 6,
  XLS: 1 << 7,
  XLSX: 1 << 8,
  WAV: 1 << 9,
  GIF: 1 << 10,
  XLSM: 1 << 11,
  DOCX: 1 << 12,
  DOC: 1 << 13,
  JPG: 1 << 14,
  ZIP: 1 << 15,
  XML: 1 << 16,
  // TODO: add additional file types as needed
};

export const FileType = {
  ...FileTypeFlag,
  IMAGE: FileTypeFlag.JPEG | FileTypeFlag.PNG | FileTypeFlag.GIF | FileTypeFlag.JPG,
  // TODO: add additional aggregate files types as needed
};

export const NotesCategory = {
  NOTE: 'note',
  SPECIAL_INSTRUCTION: 'specialInstruction',
};

export const NotesScope = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
};

export const SpecialInstructionStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const NoteSelectOptions = {
  ALL: 'all',
  NOTES: 'notes',
  ACTIVE_SPECIAL_INSTRUCTION: 'active_special_instruction',
  INACTIVE_SPECIAL_INSTRUCTION: 'inactive_special_instruction',
};

export const NotesPageSize = 20;

export const DEFAULT_INSTRUCTION = 6;

export const MAX_INSTRUCTIONS = 1;

export const SEE_MORE_CHAR = 55;

export const MAX_INSTRUCTIONS_CHAR = 240;

export const ClipboardContentType = {
  FILE: 'file',
};

// TODO: revisit this approach if it gets unwieldy trying to keep up with all file types
export const ContentType = {
  CSV: 'text/csv',
  JPEG: 'image/jpeg',
  JPG: 'image/jpeg',
  GIF: 'image/gif',
  MSG: 'application/vnd.ms-outlook',
  PDF: 'application/pdf',
  PNG: 'image/png',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  XLS: 'application/vnd.ms-excel',
  XLSM: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  ZIP: 'application/zip',
  XML: 'text/xml',
  // TODO: expand this as needed
};

export const FileExtensionType = {
  [FileType.JPEG]: '.jpeg',
  [FileType.JPG]: '.jpg',
  [FileType.PNG]: '.png',
  [FileType.CSV]: '.csv',
  [FileType.XLS]: '.xls',
  [FileType.XLSM]: '.xlsm',
  [FileType.XLSX]: '.xlsx',
  [FileType.PPT]: '.ppt',
  [FileType.PPTX]: '.pptx',
  [FileType.PDF]: '.pdf',
  [FileType.MSG]: '.msg',
  [FileType.WAV]: '.wav',
  [FileType.GIF]: '.gif',
  [FileType.DOCX]: '.docx',
  [FileType.DOC]: '.doc',
  [FileType.ZIP]: '.zip',
  [FileType.XML]: '.xml',
};

export const DataLoaderType = {
  ...ChartType,
  SPINNER: 'spinner',
  TABLE: 'table',
  GRID: 'grid',
};

export const AdminDefs = {
  PEOPLE_FILTER_STATE_ID: 'admin-people',
  ROLES_FILTER_STATE_ID: 'admin-roles',
  PEOPLE: 'people',
  ROLES: 'roles',
  DISTRIBUTION_LIST_SORT_STATE_ID: 'distribution-list',
  HISTORY: 'history',
  HANDLING_PROCEDURE_LIST_SORT_STATE_ID: 'handling-procedure-list',
  EVENT_MANAGEMENT_SORT_STATE_ID: 'event-management',
};

export const AnaylzeDefs = {
  ANOMALIES: 'anomalies',
  ASSETS: 'assets',
  ASSETS_STORAGE: 'assets-storage',
  KPI_HEADER_MENU: 'kpi-header-menu',
  SITES: 'sites',
  MANUAL_ADJUSTMENTS: 'manual-adjustments',
  ANALYSIS_TEMPLATE: 'analysis-template',
  ANAYSIS_SORT_STATE_ID: 'analysis-template',
  CASE_TEMPLATE_FILTER_STATE_ID: 'admin-case-template-table-filter',
  CASE_TEMPLATE_SORT_STATE_ID: 'admin-case-template-table-sort',
  CASETEMPLATETABLE: 'admin-case-template-table',
};

export const MonitorDefs = {
  EVENTS_SORT_STATE_ID: 'monitor-events',
  ANOMALIES_SORT_STATE_ID: 'monitor-anomalies',
  ANOMALIES_FILTER_STATE_ID: 'monitor-anomalies',
  EVENTS: 'events',
  HISTORY: 'history',
  ANOMALIES: 'anomalies',
  CASES: 'cases',
  SITES: 'sites',
  WIND_SITES: 'wind',
  STORAGE_SITES: 'storage',
  SOLAR_SITES: 'solar',
  HYBRID_SITES: 'hybrid',
  ASSETS: 'assets',
  SUBSTATION: 'substation',
  SITE_CONTROLLER: 'site-controller',
  STORAGE_INVERTER: 'storage-inverter',
};

export const ManageDefs = {
  CASES_TASKS_FILTER_STATE_ID: 'manage-cases-tasks',
  CASES_TASKS: 'cases-tasks',
  OVERVIEW: 'overview',
  TASKS_TRACKER: 'tasks-tracker',
  PLANNING_CALENDAR_FILTER_STATE_ID: 'group-filter',
  BACKLOG_FILTER_STATE_ID: 'backlog-filter',
  ASSET_HISTORY_FILTER_STATE_ID: 'asset-history-filter',
  ALERT_HISTORY_FILTER_STATE_ID: 'alert-history-filter',
  HISTORY: 'history',
  CALENDAR: 'calendar',
  GROUP_BY: 'group-by',
  SECOND_GROUP_BY: 'second-group-by',
};

export const ReportingDefs = {
  REPORTS: 'reports',
  MY_REPORTS: 'my-reports',
  CREATE: 'create',
  REPORT_DASHBOARD: 'report-dashboard',
  DASHBOARD_FILTER_STATE_ID: 'dashboard-names',
};

export const ReportViews = {
  CREATE: ReportingDefs.CREATE,
  MY_REPORTS: ReportingDefs.MY_REPORTS,
};

// TODO: integrate with other service url enum currently wip
// expand as needed
// TODO: verify what these are actually supposed to be
export const ReportingServiceUrl = {
  ASSETS_OF_CONCERN: '/machinedata/realtime/v1/assetstate',
  ASSET_STATUS: '/machinedata/realtime/v1/assetstate',
  AVAILABILITY: '/availability',
  AVERAGE_WINDSPEED: '/average-windspeed',
  COMPLETED_WORK: '/completedWork',
  PERFORMANCE_SUMMARY: '/performance-summary',
  PLANNED_WORK: '/plannedWork',
  PRODUCTION: '/production',
  SITE_ROSTER: '/siteRoster',
  WIND_DIRECTION_SPEED: '/wind-direction-speed',
};

export const TableType = {
  EVENT: 'event',
  TASK_HISTORY: 'taskHistory',
  ALERT_HISTORY: 'alertHistory',
};

export const InspectDefs = {
  LIST: 'list',
};

export const SourceTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export const SortValueType = {
  ALPHANUMERIC: 'alphanumeric',
  NUMERIC: 'number',
};

export const EventType = {
  PERMISSION_METADATA: 'permission-metadata',
};

export const SitePerformanceAggregateKey = {
  HIGHEST_PERFORMERS: 'highest-performers',
  LOWEST_PERFORMERS: 'lowest-performers',
};

export const RoleTypes = {
  GLOBAL: 'global',
  TENANT: 'tenant',
};

export const NewPersonField = {
  ENTITY_LIST: 'entityList',
  WORKER_ASSETS: 'workerAssets',
  ALL_USER_ROLES: 'allUserRoles',
  USER_ROLES: 'userRoles',
};

export const AnalyzeGlobalFilterKeys = {
  ALL: 'all',
  CONTROL_CODES: 'controlCodes',
  CORE_FLEET: 'coreFleet',
  EXCLUDE_3_MONTHS_PAST_COD: 'exclude3MonthsPastCod',
  MODELS: 'models',
  NON_CORE_FLEET: 'nonCoreFleet',
  PLATFORMS: 'platforms',
  SERVICE_AGREEMENT: 'serviceAgreement',
  SITE_IDS: 'siteIds',
  OUT_OF_WARRANTY: 'outOfWarranty',
  IN_WARRANTY: 'inWarranty',
};

export const Delimiters = {
  KEY_VALUE: '_____',
  PARAM: '--',
};

export const DispositionOptionValues = {
  CORRECTED: 'corrected',
  RESOLVED: 'Issue resolved itself',
  INCORRECT: 'Algorithm incorrectly flagged',
  OTHER: 'other',
};

export const DispositionOptions = [
  {
    label: 'Issue found at turbine and corrected',
    value: DispositionOptionValues.CORRECTED,
  },
  {
    label: 'Issue resolved itself',
    value: DispositionOptionValues.RESOLVED,
  },
  {
    label: 'Algorithm incorrectly flagged',
    value: DispositionOptionValues.INCORRECT,
  },
  {
    label: 'Other',
    value: DispositionOptionValues.OTHER,
  },
];

export const RootCauseOptionValues = {
  SERVICE_PENDING: 'case-template-service-pending',
  OTHER: 'Other',
};

export const RootCauseOptions = [
  {
    label: 'Case Template Service Pending',
    value: RootCauseOptionValues.SERVICE_PENDING,
  },
  {
    label: 'Other',
    value: RootCauseOptionValues.OTHER,
  },
];

export const CasePriority = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  IMMEDIATE: 'immediate',
};

export const AssetConnectionStatus = {
  CONNECTED: 'connected',
  NOCOMM: 'no_comm',
  NO_DATA: 'no_data',
};

export const AssetStateHistory = {
  PREVIOUS_STATE: 1,
};

export const TaskDateKey = {
  COMPLETED_DATE_TIME: 'completedDateTime',
  COMPLETED_DATE_TIME_TZ: 'completedDateTimeTz',
  CREATE_DATE: 'createDate',
  CREATE_DATE_TZ: 'createDateTz',
  DUE_DATE: 'dueDate',
  DUE_DATE_TZ: 'dueDateTz',
  ELIGIBLE_START_DATE: 'eligibleStartDate',
  ELIGIBLE_START_DATE_TZ: 'eligibleStartDateTz',
  SCHEDULE_DATE: 'scheduleDate',
  SCHEDULE_DATE_TZ: 'scheduleDateTz',
  START_DATE_TIME: 'startedDateTime',
  START_DATE_TIME_TZ: 'startedDateTimeTz',
  COMMITTED_DATE: 'committedDate',
  COMMITTED_DATE_TZ: 'committedDateTz',
};

export const MAX_NOTE_CHAR = 175;

export const EscalateCaseActionsType = {
  NOTE_CREATION: 'note-creation',
  MOVE_CASE_TO_IN_PROGRESS: 'move-to-inprogress',
  ACTIONABLE_EMAIL: 'actionable',
  INFORMATIONAL_EMAIL: 'informational',
};
export const BulkNotesActionsType = {
  NOTE_CREATION: 'note-creation',
};
export const ControllerFileCategory = {
  TRIP: 'Trip',
  DDR: 'DDR',
  CMS: 'CMS',
  HIGHRESOLUTION: 'High Resolution',
};
export const ControllerFileCategoryValue = {
  TRIP: 1,
  DDR: 2,
  CMS: 3,
  HIGHRESOLUTION: 6,
};
export const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const CommandBlockingReasonKeys = {
  ALERT: 'alert',
  COM_STATE: 'comState',
  STATE: 'state',
  EVENTS: 'events',
};

export const AlarmType = {
  DIAG: '1',
  NON_DIAG: '0',
};

export const ActiveCaseRibbonFilterTypes = {
  ACTIONS_OVERDUE: 'ACTIONS_OVERDUE',
  NO_COMM_ASSETS: 'NO_COMM_ASSETS',
};

export const MonitorCaseRibbonFilterTypes = {
  ...ActiveCaseRibbonFilterTypes,
  NEW_AND_RETURNED: 'NEW_&_RETURNED',
  STATION_CHECK: 'STATION_CHECK',
  SECOND_LOOK: 'SECOND_LOOK',
};

export const MonitorCaseStateMapping = {
  UNKNOWN: 0,
  NEW: 1,
  IN_PROGRESS: 2,
  RETURNED: 3,
  SNOOZED: 4,
  CLOSED: 5,
};

export const CaseTypeMapping = {
  UNKNOWN: 0,
  FLAGGED: 1,
  FAULTED: 2,
  SITE_NO_COMM: 3,
  FLEET_NO_COMM: 4,
  STOPPED: 5,
  EXTERNAL_API: 6,
  ASSET_NO_COMM: 7,
  BOP_BREAKER: 8,
  RNS: 9,
};

export const BacklogFilters = {
  SOURCE: 'source',
  WORKSCOPE: 'workScope',
  STATUS: 'status',
  PRIORITY: 'priority',
  FLAG: 'flag',
};

export const CaseSource = {
  MANUAL: 'Manual',
};

// Internal - GE Blade Crawler will be changed to GE Blade Crawler. However,
// the label and the type are mixed in the code. We should separate these two.
// These are inspection types used internally. Not the labels shown on the UI.
export const InspectionTypes = {
  SABRE: 'Sabre IR',
  BURST: 'Burst Photography',
  PINWHEEL: 'Pinwheel',
  INTERNAL: 'Internal',
  INTERNALGEBLADE: 'Internal - GE Blade Crawler',
  DRONE: 'Drone',
  DEFAULT: '',
};

export const InspectionTypeKey = {
  ALL: 'all',
  BURST_PHOTOGRAPHY: 'burstPhotography',
  DRONE: 'drone',
  INTERNAL: 'internal',
  INTERNAL_GE_BLADE_CRAWLER: 'internalGeBladeCrawler',
  PINWHEEL: 'pinwheel',
  SABRE_IR: 'sabreIr',
};

export const KpiCategorySeriesType = {
  ENTITY: 'entity',
  TIME_SERIES: 'timeSeries',
};

export const AllKpiMetricSupportedCategories = [
  KpiCategoryDefs.PRODUCTION_ACTUAL,
  KpiCategoryDefs.ACTUAL_PRODUCTION,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
  KpiCategoryDefs.TBA,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
];

export const AuthStrategy = {
  ANY: 'any',
  ALL: 'all',
};

export const CaseEscalationType = {
  FAULTED_ACTIONABLE: 'FAULTED_ACTIONABLE',
  FAULTED_INFORMATIONAL: 'FAULTED_INFORMATIONAL',
  CASE_BULK_OPERATION: 'CASE_BULK_OPERATION',
  GENERAL_ACTIONABLE: 'GENERAL_ACTIONABLE',
  GENERAL_INFORMATIONAL: 'GENERAL_INFORMATIONAL',
  NOTFAULTED_ACTIONABLE: 'NOTFAULTED_ACTIONABLE',
  NOTFAULTED_INFORMATIONAL: 'NOTFAULTED_INFORMATIONAL',
};

export const AlertDLTemplate = {
  TEMPORARY_CONFIG_CHANGE_ACTIONABLE: 'temporary_config_change_actionable',
  TEMPORARY_CONFIG_CHANGE_INFORMATIONAL: 'temporary_config_change_informational',
  TEMPORARY_CONFIG_ACTIONABLE: 'temporary_config_actionable',
  TEMPORARY_CONFIG_INFORMATIONAL: 'temporary_config_informational',
};

export const CaseDLType = {
  NOTFAULTED_ACTIONABLE: 'notfaulted_actionable',
  NOTFAULTED_INFORMATIONAL: 'notfaulted_informational',
  GENERAL_ACTIONABLE: 'general_actionable',
  GENERAL_INFORMATIONAL: 'general_informational',
};

export const TypeToSourceIdNamespaceMapping = {
  [AlertEmailType.TEMPORARY_CONFIG_CHANGE_ACTIONABLE]: {
    sourceId: 'temporary_config_actionable',
    namespace: 'monitor',
  },
  [AlertEmailType.TEMPORARY_CONFIG_CHANGE_INFORMATIONAL]: {
    sourceId: 'temporary_config_informational',
    namespace: 'monitor',
  },
  [AlertEmailType.TEMPORARY_CONFIG_ACTIONABLE]: {
    sourceId: 'temporary_config_actionable',
    namespace: 'monitor',
  },
  [AlertEmailType.TEMPORARY_CONFIG_INFORMATIONAL]: {
    sourceId: 'temporary_config_informational',
    namespace: 'monitor',
  },
  [AlertEmailType.PLANNED_OUTAGE]: {
    sourceId: 'planned_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.UNPLANNED_OUTAGE]: {
    sourceId: 'unplanned_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.NOTAM]: {
    sourceId: 'notam',
    namespace: 'monitor',
  },
  [AlertEmailType.COMMISSIONING_OUTAGE]: {
    sourceId: 'commissioning_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.WORKER_PRESENT]: {
    sourceId: 'worker_present',
    namespace: 'monitor',
  },
  [AlertEmailType.RELIABILITY_RUN]: {
    sourceId: 'reliability_run',
    namespace: 'monitor',
  },
  [AlertEmailType.NOCOMM_OUTAGE]: {
    sourceId: 'nocomm_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.NON_OPERATING_ASSET]: {
    sourceId: 'non_operating_asset',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_TEMPORARY_CONFIG_CHANGE_ACTIONABLE]: {
    sourceId: 'bulk_temporary_config_actionable',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_TEMPORARY_CONFIG_CHANGE_INFORMATIONAL]: {
    sourceId: 'bulk_temporary_config_informational',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_PLANNED_OUTAGE]: {
    sourceId: 'bulk_planned_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_UNPLANNED_OUTAGE]: {
    sourceId: 'bulk_unplanned_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_NOTAM]: {
    sourceId: 'bulk_notam',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_COMMISSIONING_OUTAGE]: {
    sourceId: 'bulk_commissioning_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_WORKER_PRESENT]: {
    sourceId: 'bulk_worker_present',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_NOCOMM_OUTAGE]: {
    sourceId: 'bulk_nocomm_outage',
    namespace: 'monitor',
  },
  [AlertEmailType.BULK_NON_OPERATING_ASSET]: {
    sourceId: 'bulk_non_operating_asset',
    namespace: 'monitor',
  },
  [CaseEscalationType.FAULTED_ACTIONABLE]: {
    sourceId: 'faulted_actionable',
    namespace: 'monitor',
  },
  [CaseEscalationType.FAULTED_INFORMATIONAL]: {
    sourceId: 'faulted_informational',
    namespace: 'monitor',
  },
  [CaseEscalationType.NOTFAULTED_ACTIONABLE]: {
    sourceId: 'notfaulted_actionable',
    namespace: 'monitor',
  },
  [CaseEscalationType.NOTFAULTED_INFORMATIONAL]: {
    sourceId: 'notfaulted_informational',
    namespace: 'monitor',
  },
  [CaseEscalationType.CASE_BULK_OPERATION]: {
    sourceId: 'case_bulk_operation',
    namespace: 'monitor',
  },
  [CaseEscalationType.NOTFAULTED_ACTIONABLE]: {
    sourceId: 'notfaulted_actionable',
    namespace: 'monitor',
  },
  [CaseEscalationType.NOTFAULTED_INFORMATIONAL]: {
    sourceId: 'notfaulted_informational',
    namespace: 'monitor',
  },
};

export const TypeToSourceIdDLMapping = {
  ...TypeToSourceIdNamespaceMapping,
  [AlertEmailType.TEMPORARY_CONFIG_CHANGE_ACTIONABLE]: {
    sourceId: 'temporary_config_actionable',
    namespace: 'monitor',
  },
  [AlertEmailType.TEMPORARY_CONFIG_CHANGE_INFORMATIONAL]: {
    sourceId: 'temporary_config_informational',
    namespace: 'monitor',
  },
};

export const EntityTypeMapping = {
  [AssetType.WIND_TURBINE]: AlertsEntityType.WIND_TURBINE,
  [AssetType.SUBSTATION]: AlertsEntityType.SUBSTATION,
  [AssetType.SITE_CONTROLLER]: AlertsEntityType.WIND_SITE_CONTROLLER,
  [AssetType.STORAGE_INVERTER]: AlertsEntityType.INVERTER,
  [EntityType.SITE]: AlertsEntityType.SITE,
  [EntityType.WIND_SITE]: AlertsEntityType.SITE,
  [EntityType.STORAGE_SITE]: AlertsEntityType.STORAGE_SITE,
};

export const TimeUnits = {
  HOURS: 'hours',
  SECONDS: 'seconds',
};

export const InspectionsPerformanceMetricsKey = {
  ACCEPTED_DAMAGES: 'acceptedDamages',
  ANNOTATIONS_GENERATED: 'annotationsGenerated',
  AVERAGE_CYCLE_TIME: 'averageCycleTime',
  AVERAGE_MANUAL_DAMAGES: 'averageManualDamages',
  AVERAGE_UPLOAD_TIME: 'averageUploadTime',
  IDENTIFIED_VS_ACCEPTED: 'identifiedVsAccepted',
  INSPECTIONS_UPLOADED: 'inspectionsUploaded',
  INSPECTION_TYPE: 'inspectionType',
  REPORTS_GENERATED: 'reportsGenerated',
};

export const TaskNotes = {
  DPOD_SSO: 'service-account-predix-aws-task-sync',
};

export const InspectionsFilterKeys = {
  MODELS: 'models',
};

export const SystemResetStatus = {
  IN_PROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  NO_AUTOMATION: 'NO AUTOMATION',
};

export const SystemResetResult = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  NORESULT: 'NORESULT',
};

export const ErpDefaultValue = {
  ADDRESS: 'Default',
  SERVICE_ACTIVITY: 'Labor',
};

// can refine what we want to define here
export const ErpValuesFieldKey = {
  SERVICE_ACTIVITY_VALUES: 'serviceActivityValues',
  VALUES: 'values',
  VALUES_BY_VALUES: 'valuesByValues',
};

export const ErpLaborType = {
  REPAIR: 'Repair',
  TROUBLESHOOTING: 'Troubleshoot',
};

// expand as needed
export const ErpBindingPropertyName = {
  PAC_NUMBER: 'pacNumber',
  // currently this is
  PHONE_NUMBER: 'phoneNumber',
  // these are being inferred from the erpMetadata object in submit/request endpoint for custom binding property names
  // they aren't currently being provided by the template endpoint
  LABOR_DETAILS: 'laborDetails',
  PART_DETAILS: 'partDetails',
  PROBLEM_SUMMARY: 'srProblemSummary',
};

// this is for the custom field from backend that provides some add'l special logic
// wonder if it can be handled by the type field instead of introducing a whole new field...
export const ErpCustomProperty = {
  OWNER: 'owner',
  TAG: 'tag',
};

export const ErpCustomFieldType = {
  ADDRESS: 'address',
  LABOR_CHARGES: 'labor_charges',
  PARTS: 'parts',
  TECHNICIANS: 'technicians',
};

export const ErpCustomFieldName = {
  REPAIR_HOURS: 'repairHours',
  TROUBLESHOOTING_HOURS: 'troubleshootingHours',
};

export const ErpDynamicFieldType = {
  ADDRESS: 'address',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  LABEL: 'label',
  RADIO: 'radio',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
};

export const ErpDynamicFieldName = {
  SR_TYPE: 'srType',
  MAJOR_COMPONENT_SUPPLIER: 'majorCompSupplier',
  PROBLEM_CODE: 'srProblemCode',
};

// can look at mapping these backend types to consistent frontend types (with tasks and alerts) if needed
export const ErpFieldType = {
  ...ErpCustomFieldType,
  ...ErpDynamicFieldType,
};

export const ErpBindingPropertyNameMap = {
  [ErpCustomFieldType.LABOR_CHARGES]: ErpBindingPropertyName.LABOR_DETAILS,
  [ErpCustomFieldType.PARTS]: ErpBindingPropertyName.PART_DETAILS,
};

// for keywords that indicate a field that needs to be translated (the value currently points at a translation key)
export const ErpTranslationField = {
  DEFAULT_SELECTION: 'defaultSelection',
  EMPTY_LABEL: 'emptyLabel',
  INVALID_LABEL: 'invalidLabel',
  TITLE: 'title',
  // these are the fields from custom/non-standard field types
  ALTERNATE_ADDRESS_TITLE: 'alternateAddressTitle',
  EMPTY_SERVICE_ACTIVITY_LABEL: 'emptyServiceActivityLabel',
  HINT: 'hint',
  INVALID_ADDRESS_LABEL: 'invalidAddressLabel',
  INVALID_PART_NAME_LABEL: 'invalidPartNameLabel',
  INVALID_PART_QUANTITY_LABEL: 'invalidPartQuantityLabel',
  LABOR_TYPE_TITLE: 'laborTypeTitle',
  NOTE_TITLE: 'noteTitle',
  PART_NAME_TITLE: 'partNameTitle',
  PART_NUMBER_TITLE: 'partNumberTitle',
  PLACEHOLDER: 'placeholder',
  PLACE_ORDER_TITLE: 'placeOrderTitle',
  QUANTITY_ERROR_LABEL: 'quantityErrorLabel',
  QUANTITY_TITLE: 'quantityTitle',
  REPAIR_TITLE: 'repairTitle',
  SERVICE_ACTIVITY_TITLE: 'serviceActivityTitle',
  SHIPPING_ADDRESS_TITLE: 'shippingAddressTitle',
  TROUBLESHOOTING_TITLE: 'troubleshootingTitle',
};

// managing this on our end for now because backend doesn't define input masks
export const ErpFieldMask = {
  [ErpBindingPropertyName.PAC_NUMBER]: 'REN-########-####',
  [ErpBindingPropertyName.PHONE_NUMBER]: '###############',
};

export const ErpStatus = {
  SR_APPROVED_ESTIMATES: 'SR_APPROVED_ESTIMATES',
  SR_CLAIM_SUBMITTED: 'SR_CLAIM_SUBMITTED',
  SR_COMPLETED: 'SR_COMPLETED',
  SR_CREATION_FAILED: 'SR_CREATION_FAILED',
  SR_CREATION_INP: 'SR_CREATION_INP',
  SR_OPEN: 'SR_OPEN',
  SR_REJECTED_ESTIMATES: 'SR_REJECTED_ESTIMATES',
  SR_REQUEST_INFORMATION: 'SR_REQUEST_INFORMATION',
  WO_CREATED: 'WO_CREATED',
  WO_CREATION_FAILED: 'WO_CREATION_FAILED',
  WO_CREATION_INP: 'WO_CREATION_INP',
  // status used when submitting a new ERP request
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  // status from erp metadata object
  OPEN: 'Open',
  // status in parts creation
  SUCCESS: 'SUCCESS',
  SR_HEADER: 'SR_HEADER',
  SR_BODY_UPDATE: 'SR_BODY_UPDATE',
};

export const ErpSubStatus = {
  SR_CHARGES_FAILED: 'SR_CHARGES_FAILED',
  SR_CHARGES_INP: 'SR_CHARGES_INP',
  SR_ORDER_FAILED: 'SR_ORDER_FAILED',
  SR_ORDER_INP: 'SR_ORDER_INP',
  SR_ORDER_SUCCESS: 'SR_ORDER_SUCCESS',
  SR_CHARGES_SUCCESS: 'SR_CHARGES_SUCCESS',
  SR_PARTIAL_ORDER_FAILED: 'SR_PARTIAL_ORDER_FAILED',
  SR_TECH_ASSIGNMENT_FAILED: 'SR_TECH_ASSIGNMENT_FAILED',
  SR_TECH_ASSIGNMENT_INP: 'SR_TECH_ASSIGNMENT_INP',
  SR_TECH_ASSIGNMENT_SUCCESS: 'SR_TECH_ASSIGNMENT_SUCCESS',
  // consolidated sub-statuses
  SR_CHARGES_ORDER_FAILED: 'SR_CHARGES_ORDER_FAILED',
  SR_CHARGES_TECH_ASSIGNMENT_FAILED: 'SR_CHARGES_TECH_ASSIGNMENT_FAILED',
  SR_CHARGES_TECH_ASSIGNMENT_INP: 'SR_CHARGES_TECH_ASSIGNMENT_INP',
  SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED: 'SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED',
  SR_TECH_ASSIGNMENT_FAILED_ORDER_INP: 'SR_TECH_ASSIGNMENT_FAILED_ORDER_INP',
  SR_TECH_ASSIGNMENT_ORDER_FAILED: 'SR_TECH_ASSIGNMENT_ORDER_FAILED',
  SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED: 'SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED',
};

// using flags to support combining statuses without number collisions
// keys should match keys in status enums above (could use those as keys but gets unwieldy)
export const ErpStatusFlag = {
  // no status
  '': 0,
  // service requests
  // statuses
  SR_APPROVED_ESTIMATES: 1 << 0,
  SR_CLAIM_SUBMITTED: 1 << 1,
  SR_COMPLETED: 1 << 2,
  SR_CREATION_FAILED: 1 << 3,
  SR_CREATION_INP: 1 << 4,
  SR_OPEN: 1 << 5,
  SR_REJECTED_ESTIMATES: 1 << 6,
  SR_REQUEST_INFORMATION: 1 << 7,
  // sub-statuses
  SR_CHARGES_FAILED: 1 << 8,
  SR_CHARGES_INP: 1 << 9,
  SR_CHARGES_SUCCESS: 1 << 10,
  SR_ORDER_FAILED: 1 << 11,
  SR_ORDER_INP: 1 << 12,
  SR_ORDER_SUCCESS: 1 << 13,
  SR_PARTIAL_ORDER_FAILED: 1 << 14,
  SR_TECH_ASSIGNMENT_FAILED: 1 << 15,
  SR_TECH_ASSIGNMENT_INP: 1 << 16,
  SR_TECH_ASSIGNMENT_SUCCESS: 1 << 17,
  // consolidated sub-statuses
  SR_CHARGES_ORDER_FAILED: 1 << 18,
  SR_CHARGES_TECH_ASSIGNMENT_FAILED: 1 << 19,
  SR_CHARGES_TECH_ASSIGNMENT_INP: 1 << 20,
  SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED: 1 << 21,
  SR_TECH_ASSIGNMENT_FAILED_ORDER_INP: 1 << 22,
  SR_TECH_ASSIGNMENT_ORDER_FAILED: 1 << 23,
  SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED: 1 << 24,
  // work orders
  WO_CREATED: 1 << 25,
  WO_CREATION_FAILED: 1 << 26,
  WO_CREATION_INP: 1 << 27,
};

const _sFlag = ErpStatusFlag;

// mapping to erp status icon names
// order in here follows order in status matrix spreadsheet
// add together all the statuses for erp from backend using enum flags to get keys in mapping here
export const ErpStatusIconMap = {
  // service requests
  // SR creation
  [_sFlag.SR_CREATION_INP]: '3',
  [_sFlag.SR_CREATION_FAILED]: '2',
  // SR open
  [_sFlag.SR_OPEN]: '4',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_INP]: '16',
  [_sFlag.SR_OPEN + _sFlag.SR_ORDER_INP]: '16',
  [_sFlag.SR_OPEN +
  _sFlag.SR_CHARGES_SUCCESS +
  _sFlag.SR_TECH_ASSIGNMENT_SUCCESS +
  _sFlag.SR_ORDER_SUCCESS]: '4',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_SUCCESS + _sFlag.SR_TECH_ASSIGNMENT_SUCCESS]: '4',
  [_sFlag.SR_OPEN + _sFlag.SR_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_PARTIAL_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_TECH_ASSIGNMENT_FAILED_ORDER_INP]: '16',
  [_sFlag.SR_OPEN + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_TECH_ASSIGNMENT_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_ORDER_FAILED]: '10',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_INP + _sFlag.SR_TECH_ASSIGNMENT_INP]: '16',
  [_sFlag.SR_OPEN + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '10',
  // SR claim submitted
  [_sFlag.SR_CLAIM_SUBMITTED]: '5',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_ORDER_INP]: '17',
  [_sFlag.SR_CLAIM_SUBMITTED +
  _sFlag.SR_CHARGES_SUCCESS +
  _sFlag.SR_TECH_ASSIGNMENT_SUCCESS +
  _sFlag.SR_ORDER_SUCCESS]: '5',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_SUCCESS + _sFlag.SR_TECH_ASSIGNMENT_SUCCESS]: '5',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_ORDER_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_PARTIAL_ORDER_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_TECH_ASSIGNMENT_FAILED_ORDER_INP]: '17',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_ORDER_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED]: '11',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_INP + _sFlag.SR_TECH_ASSIGNMENT_INP]: '17',
  [_sFlag.SR_CLAIM_SUBMITTED + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '11',
  // SR approved estimates
  [_sFlag.SR_APPROVED_ESTIMATES]: '6',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_ORDER_INP]: '18',
  [_sFlag.SR_APPROVED_ESTIMATES +
  _sFlag.SR_CHARGES_SUCCESS +
  _sFlag.SR_TECH_ASSIGNMENT_SUCCESS +
  _sFlag.SR_ORDER_SUCCESS]: '6',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_SUCCESS + _sFlag.SR_TECH_ASSIGNMENT_SUCCESS]:
    '6',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_ORDER_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_PARTIAL_ORDER_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_TECH_ASSIGNMENT_FAILED_ORDER_INP]: '18',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_ORDER_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED]: '12',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_INP]: '18',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_INP + _sFlag.SR_TECH_ASSIGNMENT_INP]: '18',
  [_sFlag.SR_APPROVED_ESTIMATES + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]:
    '12',
  // SR rejected estimates
  [_sFlag.SR_REJECTED_ESTIMATES]: '7',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_ORDER_INP]: '19',
  [_sFlag.SR_REJECTED_ESTIMATES +
  _sFlag.SR_CHARGES_SUCCESS +
  _sFlag.SR_TECH_ASSIGNMENT_SUCCESS +
  _sFlag.SR_ORDER_SUCCESS]: '7',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_SUCCESS + _sFlag.SR_TECH_ASSIGNMENT_SUCCESS]:
    '7',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_ORDER_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_PARTIAL_ORDER_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_TECH_ASSIGNMENT_FAILED_ORDER_INP]: '19',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_ORDER_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED]: '13',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_INP + _sFlag.SR_TECH_ASSIGNMENT_INP]: '19',
  [_sFlag.SR_REJECTED_ESTIMATES + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]:
    '13',
  // SR request information
  [_sFlag.SR_REQUEST_INFORMATION]: '8',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_ORDER_INP]: '20',
  [_sFlag.SR_REQUEST_INFORMATION +
  _sFlag.SR_CHARGES_SUCCESS +
  _sFlag.SR_TECH_ASSIGNMENT_SUCCESS +
  _sFlag.SR_ORDER_SUCCESS]: '8',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_SUCCESS + _sFlag.SR_TECH_ASSIGNMENT_SUCCESS]:
    '8',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_ORDER_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_PARTIAL_ORDER_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_TECH_ASSIGNMENT_FAILED_ORDER_INP]: '20',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_ORDER_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED]: '14',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_INP + _sFlag.SR_TECH_ASSIGNMENT_INP]: '20',
  [_sFlag.SR_REQUEST_INFORMATION + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]:
    '14',
  // SR completed
  [_sFlag.SR_COMPLETED]: '9',
  [_sFlag.SR_COMPLETED + _sFlag.SR_ORDER_INP]: '21',
  [_sFlag.SR_COMPLETED +
  _sFlag.SR_CHARGES_SUCCESS +
  _sFlag.SR_TECH_ASSIGNMENT_SUCCESS +
  _sFlag.SR_ORDER_SUCCESS]: '9',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_SUCCESS + _sFlag.SR_TECH_ASSIGNMENT_SUCCESS]: '9',
  [_sFlag.SR_COMPLETED + _sFlag.SR_ORDER_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_PARTIAL_ORDER_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_TECH_ASSIGNMENT_FAILED_ORDER_INP]: '21',
  [_sFlag.SR_COMPLETED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_TECH_ASSIGNMENT_PARTIAL_ORDER_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_ORDER_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_TECH_ASSIGNMENT_ORDER_FAILED]: '15',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_INP + _sFlag.SR_TECH_ASSIGNMENT_INP]: '21',
  [_sFlag.SR_COMPLETED + _sFlag.SR_CHARGES_FAILED + _sFlag.SR_TECH_ASSIGNMENT_FAILED]: '15',
  // work orders
  [_sFlag.WO_CREATION_INP]: '3',
  [_sFlag.WO_CREATED]: '1',
  [_sFlag.WO_CREATION_FAILED]: '2',
};

// mapping to warranty icon file names
export const WarrantyStatusIconMap = {
  [ErpStatus.SR_APPROVED_ESTIMATES]: 'approved-estimates',
  [ErpStatus.SR_CLAIM_SUBMITTED]: 'claim-submitted',
  [ErpStatus.SR_COMPLETED]: 'completed',
  [ErpStatus.SR_OPEN]: 'open',
  [ErpStatus.SR_REJECTED_ESTIMATES]: 'rejected-estimates',
  [ErpStatus.SR_REQUEST_INFORMATION]: 'request-information',
};

export const Tenant = {
  DTE: 'dtesvc',
  CONST: 'const',
  GESERV: 'geserv',
};

export const ErpSource = {
  DTE_MAXIMO: 'DTE_MAXIMO',
  RACES: 'RACES',
};

export const ErpType = {
  SERVICE_REQUEST: 'serviceRequest',
  WORK_ORDER: 'workOrder',
};

export const ErpSourceTypeMap = {
  [ErpSource.DTE_MAXIMO]: ErpType.WORK_ORDER,
  [ErpSource.RACES]: ErpType.SERVICE_REQUEST,
};

export const ErpCustomSection = {
  CHARGES: 'charges',
};

export const WorkersFilters = {
  TITLE: 'title',
};

export const CasePatchCallStatus = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const CloseTask = {
  type: 'taskClose',
};

export const DayJs = {
  // string that returns from dayjs on an invalid date format
  INVALID_DATE: 'Invalid Date',
};

export const TabFlowModelVariables = {
  ROLE: 'role',
  ACTIVETAB: 'activeTab',
  EDITSTATUS: 'editStatus',
  LIST: 'list',
  SITELIST: 'siteList',
  TABITERATOR: 'tabIterator',
  VIEWBYSERVICEGROUPORSITES: 'viewByServiceGroupsOrSites',
  IDS: 'ids',
  ROLESTYPE: {
    USER: 'User',
    USER_AND_WORKER: 'User/Worker',
    WORKER: 'Worker',
  },
  VIEWBY: {
    SITES: 'sites',
    GROUPS: 'groups',
  },
};

export const graphQl = {
  GET_ALL_EVENTS:
    '{assetDataAssignment(assetId:"___", categoryId:"0", dataMode:"realtime", pageIdx:pageIDX, pageSize:1000, pageSortKey:"_id", pageSortOrder:"DESC"){ assetId tenantId eventMapId eventMap{ eventMapId  currentPage totalRecords totalPages pageSize name events{ sourceEventId event{ eventId name code canonicalStateId canonicalState{ id  canonicalStateId stateName }}}}}}',
};
export const CMSTypeCategory = {
  CMSAlarms: 'CMS Alarms',
  CMSSpectrum: 'CMS Spectrum',
  CMSWaveform: 'CMS Waveform',
};

export const CMSTypeCategoryValue = {
  CMSAlarms: 'alarm',
  CMSSpectrum: 'spectrum',
  CMSWaveform: 'waveform',
};

export const IncludeFields = {
  includeFields: 'metaData,event',
};

export const CaseSourceMapping = {
  CMS: 'anomalyCaseCms',
  FINGERPRINTS: 'anomalyCaseFingerprints',
  MANUAL: 'anomalyCaseManual',
  SCADA: 'anomalyCaseScada',
};

export const PageSource = {
  EXECUTE: 'execute',
  MONITOR: 'monitor',
  REALTIMECASE: 'realtimecase',
};

// Dispatch
export const DispStatus = {
  NO_CONTENT: 'NO_CONTENT',
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  CONFLICT: 'CONFLICT',
};

export const crewDisabledTypes = {
  CrewNotAvailable: 'not-available',
  CrewNotApplyOn: 'not-apply-on',
  CrewOther: 'other',
};

export const WorkScopes = {
  EXCHANGE: 'exchange',
  INSPECTION: 'inspection',
  MAINTENANCE: 'maintenance',
  REPAIR: 'repair',
  TROUBLESHOOT: 'troubleshoot',
  BOP: 'bop',
};

export const HistoryActionStatus = {
  INSERTED: 'INSERTED',
  DELETED: 'DELETED',
};

export const UserAuditHistory = {
  INSERTED: 'Added',
  DELETED: 'Deleted',
  User: 'User',
  HISTORY_DATE_FORMAT: 'HH:mm DD MMM YY',
  NULL: 'null',
  INVALID_DATE: 'Invalid Date',
};

export const HistorySelectOptions = [
  { label: 'Last 14 Days', value: 14 },
  { label: 'Last 45 Days', value: 45 },
  { label: 'Last 90 Days', value: 90 },
  { label: 'All History', value: 0 },
];

export const ReportTemplateTypes = {
  EOD: 'eod',
  POD: 'pod',
  CONT: 'cont',
};

export const RealTimeCases = [
  'realtimeCases',
  'realtimeCasesFaulted',
  'realtimeCasesSystem',
  'realtimeCasesWarning',
  'realtimeCasesTemporaryConfigChange',
];

export const WorkerDefaultParams = {
  types: 'Worker',
  pageIdx: 0,
  pageSize: 5000,
  sortKey: 'id',
  orderDirection: 'ASC',
};
export const chunkValues = {
  workerChunkSize: 80,
  servicegroupChunkSize: 50,
};

export const ResolutionNotes = { OTHER: 'Other' };

export const AnomalyCasesResolutionDropdown = [
  'anomalyCase',
  'anomalyCaseCMS',
  'anomalyCaseScada',
  'anomalyCaseFingerprints',
  'anomalyCaseManual',
];

export const TasksPriority = [
  TaskPriority.IMMEDIATE,
  TaskPriority.HIGH,
  TaskPriority.MEDIUM,
  TaskPriority.LOW,
];
export const WorkerQueryParams = {
  types: 'Worker',
  pageIdx: 0,
  pageSize: 5000,
  sortKey: 'id',
  orderDirection: 'ASC',
};

export const AssetStatus = {
  TOTAL: 'total',
  AVAILABLE_PERCENT: 'availablePercent',
  NO_DATA: 'noData',
  NET_COMM: 'netComm',
  ONLINE: 'online',
  AVAILABLE: 'available',
  IMPACTED: 'impacted',
  TRIPPED: 'tripped',
  STOPPED: 'stopped',
  MAINTENANCE: 'maintenance',
  REPAIR: 'repair',
};

export const TimeSignal = {
  id: 'TIME',
  name: 'Time',
  title: 'Time',
  unit: '',
  timeAggr: 'AVG_10M',
  signalSource: 'UI',
};

export const PowerCurve = {
  id: 'REFERENCE_POWER_CURVE',
  name: 'Reference Power Curve',
};

// TODO: update id's when we have signals list
export const AxisOptions = {
  TIME: TimeSignal.id,
  POWER_MWH: 'WTUR.W',
  WIND_SPEED: 'WNAC.WdSpd',
};

export const SignalCategoryId = {
  SCADA: 0,
  CMS: 2,
};

export const SignalIdTypes = {
  CANONICAL: 'canonicalSignalId',
  NON_CANONICAL: 'sourceSignalId',
};

export const EventIdType = {
  ALL: 'all',
  CANONICAL: 'canonical',
  SOURCE: 'source',
};

export const AssetModel = { WINDCONTROL: 'WindControl' };

export const DefaultTime = {
  TASK_DEFAULT_START_TIME: '07:00',
};

export const UserFullStory = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const TaskStatus = {
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  UNSCHEDULED: 'unscheduled',
};

export const ConditionType = {
  WIND: 'windSpeed',
  TEMPERATURE: 'temperature',
  WIND_DIRECTION: 'windBearing',
};

export const DefaultFilters = [
  { id: 'all_open', name: 'ALL OPEN', defaultView: true, visible: true, selected: false },
  { id: 'new', name: 'NEW', defaultView: true, visible: true, selected: false },
  { id: 'overdue', name: 'OVERDUE', defaultView: true, visible: true, selected: false },
  { id: 'upcoming_due', name: 'UPCOMING DUE', defaultView: true, visible: true, selected: false },
  {
    id: 'recently_closed',
    name: 'RECENTLY CLOSED',
    defaultView: true,
    visible: true,
    selected: false,
  },
  {
    id: 'all_completed',
    name: 'ALL COMPLETED',
    defaultView: true,
    visible: true,
    selected: false,
  },
];

export const TaskTrackers = {
  ERROR: 'error',
  ERROR_MSG: 'errorMsg',
  MAX_LENGTH: 'maxLength',
  SAME_NAME_ERROR: 'same_name_error',
  LENGTH_ERROR: 'length_error',
  DUPLICATE_ERROR: 'duplicate_error',
  CREATE_EDIT_VIEW: 'createEditView',
  MAX_FILTER_BUTTONS_INDEX: 6,
  POSITION: {
    TOP: 'top',
    SIDE: 'side',
  },
  DRAG_TASK_TRACKER: 'dragTaskTracker',
  FIRST_TRACKER_BTN: 'firstTrackerBtn',
};

export const DistributionLists = {
  TAB: 'tab',
  NEW: 'create',
  MODE: 'mode',
  VIEW: 'view',
  EDIT: 'edit',
};

export const Types = {
  EDIT: 'Edit',
  TO: 'to',
  CC: 'cc',
  BCC: 'bcc',
  VIEW: 'view',
  NEW: 'New',
};

export const EventManagement = {
  TAB: 'tab',
  NEW: 'create',
  MODE: 'mode',
  VIEW: 'view',
  EDIT: 'edit',
};

export const HandlingProcedures = {
  TAB: 'tab',
  NEW: 'create',
  MODE: 'mode',
  VIEW: 'view',
  EDIT: 'edit',
};

export const FLAGGED_STATES = {
  Online: { letter: 'O', color: '#207d4e' },
  Available: { letter: 'A', color: '#3287b9' },
  Impacted: { letter: 'I', color: '#7844b3' },
  Tripped: { letter: 'T', color: '#ef5353' },
  Stopped: { letter: 'S', color: '#ba903c' },
  Maintenance: { letter: 'M', color: '#5a1845' },
  Repair: { letter: 'R', color: '#b34490' },
};

export const TableNumberFilterType = {
  RANGE: 'range',
  VALUE: 'value',
  GREATER_THAN: 'greater_than',
  LESS_THAN: 'less_then',
};

export const TableNumberFilterTypes = {
  [TableNumberFilterType.VALUE]: {
    a11yKey: 'VALUE_INPUT',
    a11yDefault: 'Value',
  },
  [TableNumberFilterType.RANGE]: {
    a11yKey: 'RANGE',
    a11yDefault: 'Range',
  },
  [TableNumberFilterType.GREATER_THAN]: {
    a11yKey: 'GREATER',
    a11yDefault: 'Greater than >',
  },
  [TableNumberFilterType.LESS_THAN]: {
    a11yKey: 'LESS',
    a11yDefault: 'Less than <',
  },
};

export const OneSecondAdapter = {
  adapterName: 'One Second Configuration',
  adapterValue: 1,
};

export const TaskFlag = {
  APPROVED: 'approved',
  NOTAPPROVED: 'notApproved',
  BUNDLED: 'bundled',
};

export const TaskCategoryMonitor = {
  ROC: 'roc',
  FIELD: 'field',
};

export const DefaultDirtyEntityFields = {
  [EntityType.CASE]: false,
  [EntityType.TURBINE]: false,
  [EntityType.SITE]: false,
  [EntityType.TASK]: false,
};

export const MaxSelectionLimit = {
  TASKS: 100,
};
