import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropTypes } from 'prop-types';
import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';

import { modeChartOptions } from './mode-chart-consts';

const StyledModeChart = styled.div`
  margin: 0 45px 20px 0;

  &:nth-child(2n) {
    margin-right: 0;
  }
`;

const StyleChartTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => (props.large ? '0 0 25px' : '5px 0 3px')};
  align-items: center;
  position: relative;

  &.alt-chevron {
    justify-content: flex-start;

    button {
      padding: 0;
      position: absolute;
      left: -15px;
      top: -1px;
    }
  }
`;

const LargeTitleCss = css`
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
  text-transform: capitalize;
`;

const StyledChartTitle = styled.span`
  color: ${(props) => props.theme.analyze.frequencyLineChart.titleColor};
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  text-transform: uppercase;

  ${(props) => {
    if (props.large) return LargeTitleCss;
  }};
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.color || props.theme.analyze.frequencyLineChart.headerIconColor,
  rotate: -90,
}))`
  &.enlarge-chart-chevron {
    transform: rotate(90deg);
  }
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  color: props.theme.analyze.frequencyLineChart.badgeColor,
}))`
  margin: 0 6px 0 0;
`;

const Mode = ({ ...props }) => {
  const {
    alignChevronLeft,
    categories,
    large,
    onChevronClick,
    className,
    data,
    title,
    startOnTick,
    tickPositions,
    theme,
    type,
    xAxisMax,
    xInterval,
    yInterval,
  } = props;

  const chartOptions = useRef(modeChartOptions);

  const chartCreated = useCallback(
    (chartOptions) => {
      chartOptions.update({
        chart: {
          height: large ? 537 : 242,
        },
        colors: large
          ? [theme.analyze.frequencyLineChart.colorsLarge]
          : theme.analyze.frequencyLineChart.colors,
        xAxis: {
          categories: categories ? categories : null,
          startOnTick: startOnTick ? startOnTick : null,
          max: xAxisMax ? xAxisMax : null,
          tickInterval: xInterval,
          type: type ? type : null,
          tickPositions: tickPositions ? tickPositions : null,
        },
        yAxis: {
          tickInterval: yInterval,
          gridLineColor: theme.analyze.modeChart.gridLineColor,
        },
        title: {
          style: {
            color: theme.analyze.modeChart.titleColor,
            display: 'none',
          },
        },
      });

      chartOptions.addSeries({
        data: data.dataSetOne,
        stack: 1,
      });

      chartOptions.addSeries({
        data: data.dataSetTwo,
        stack: 1,
      });
    },
    [
      categories,
      data,
      large,
      startOnTick,
      theme,
      tickPositions,
      type,
      xAxisMax,
      xInterval,
      yInterval,
    ],
  );

  return (
    <StyledModeChart className={className}>
      <StyleChartTitleWrapper
        className={alignChevronLeft ? 'alt-chevron' : 'chevron'}
        large={large}
      >
        {onChevronClick && alignChevronLeft && (
          <button onClick={onChevronClick}>
            <ChevronIcon className="enlarge-chart-chevron" />
          </button>
        )}
        {large && <StyledBadge label={'1'} small />}
        <StyledChartTitle large={large}>{title}</StyledChartTitle>
        {onChevronClick && !alignChevronLeft && (
          <button onClick={onChevronClick}>
            <ChevronIcon />
          </button>
        )}
      </StyleChartTitleWrapper>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions.current}
        callback={chartCreated}
        immutable
      />
    </StyledModeChart>
  );
};

Mode.propTypes = {
  alignChevronLeft: PropTypes.bool,
  categories: PropTypes.array,
  className: PropTypes.string,
  data: PropTypes.instanceOf(Object).isRequired,
  large: PropTypes.boolean,
  onChevronClick: PropTypes.func,
  startOnTick: PropTypes.number,
  theme: PropTypes.instanceOf(Object).isRequired,
  tickPositions: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  xAxisMax: PropTypes.number,
  xInterval: PropTypes.number,
  yInterval: PropTypes.number,
};

Mode.defaultProps = {
  alignChevronLeft: false,
  data: [],
  onChevronClick: null,
  title: '',
  large: false,
};

export const ModeChart = withTheme(Mode);
