import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

/**
 * GET Work Standards
 */

export const getWorkStandards = (assetId, taskTitle) => {
  return request.get(`/mwp/tasks/work-standards/${assetId}/${taskTitle}`, { baseURL: BASE_URL });
};
