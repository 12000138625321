import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';

const SubCheckContainer = styled.ul`
  padding-left: 10px;
`;

const SubCheckWrapper = styled.li`
  list-style: none;
  padding-left: 10px;
  margin: 11px 0 0 0;
`;

export const NotesCustomRenderer = ({
  onChange,
  onSubstateChange,
  checkState,
  label,
  name,
  disabled,
  configuration,
}) => {
  const { t, ready } = useTranslation(['reporting.sidebar']);

  const [subCheckState, setSubCheckState] = useState({
    internal: checkState === CheckedState.CHECKED && configuration?.internal,
    external: checkState === CheckedState.CHECKED && configuration?.external,
  });

  // Report back when the substate changes.
  useEffect(() => {
    onSubstateChange(subCheckState);
  }, [onSubstateChange, subCheckState]);

  // Trigger the parent's onChange callback.
  const handleParentClick = useCallback(
    (newState) => {
      onChange(newState);
    },
    [onChange],
  );

  // Listen for a change to the underlying checkState
  useEffect(() => {
    if (checkState === CheckedState.UNCHECKED) {
      setSubCheckState({
        internal: false,
        external: false,
      });
    } else {
      if (!subCheckState.internal) {
        setSubCheckState({
          internal: false,
          external: true,
        });
      }
    }
  }, [checkState, subCheckState.internal, setSubCheckState]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Checkbox
        name={name}
        label={label}
        checkState={checkState}
        onChange={handleParentClick}
        disabled={disabled}
      />
      <SubCheckContainer>
        <SubCheckWrapper>
          <Checkbox
            name="external"
            label={t('notes_external', 'External')}
            checkState={subCheckState.external ? CheckedState.CHECKED : CheckedState.UNCHECKED}
            onChange={(newState) => {
              setSubCheckState((prevState) => ({ ...prevState, external: newState }));

              if (newState || subCheckState.internal) {
                handleParentClick(true);
              } else if (!newState && !subCheckState.internal) {
                handleParentClick(false);
              }
            }}
            disabled={disabled}
          />
        </SubCheckWrapper>
        <SubCheckWrapper>
          <Checkbox
            name="Internal"
            label={t('notes_internal', 'Internal')}
            checkState={subCheckState.internal ? CheckedState.CHECKED : CheckedState.UNCHECKED}
            onChange={(newState) => {
              setSubCheckState((prevState) => ({ ...prevState, internal: newState }));

              if (newState || subCheckState.external) {
                handleParentClick(true);
              } else if (!newState && !subCheckState.external) {
                handleParentClick(false);
              }
            }}
            disabled={disabled}
          />
        </SubCheckWrapper>
      </SubCheckContainer>
    </>
  );
};

NotesCustomRenderer.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkState: PropTypes.oneOf([
    CheckedState.CHECKED,
    CheckedState.UNCHECKED,
    CheckedState.INDETERMINATE,
  ]),
  onChange: PropTypes.func,
  onSubstateChange: PropTypes.func,
  disabled: PropTypes.bool,
  configuration: PropTypes.object,
};

NotesCustomRenderer.defaultProps = {
  name: null,
  label: null,
  checkState: CheckedState.UNCHECKED,
  onChange: () => null,
  disabled: false,
  configuration: null,
};
