import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchPersonByUsername = async (username) =>
  request.get(`/cmn/admin/users/${username}`, { baseURL: USERS_BASE_URL });

export const useFetchPerson = (username) => {
  const {
    error,
    isError,
    status,
    refetch,
    isLoading: isLoadingPerson,
    isFetching: isFetchingPerson,
    data: fetchedPerson,
  } = useQuery([QueryKey.FETCH_PERSON, { username }], () => fetchPersonByUsername(username), {
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isLoadingPerson,
    isFetching: isFetchingPerson,
    isError,
    status,
    error,
    refetch,
    data: fetchedPerson,
  };
};
