import { DateRange } from '@ge/models/constants';

import { getDefaultDateRange, getFilterDateRange } from '../util';

export const useFilterDateRange = ({ dateRange }) => {
  // TODO: define default range in one place and use everywhere so no discrepancies
  const range = dateRange?.range ?? DateRange.LAST_7_DAYS;
  const filterDateRange = getFilterDateRange({
    endDate: dateRange?.endDate,
    entityTimezone: dateRange?.entityTimezone,
    range,
    startDate: dateRange?.startDate,
  });
  const defaultDateRange = getDefaultDateRange(range);
  // going with utc for now because entityTimezone was giving end date in the future (tomorrow) causing backend service to throw error
  const endDate = filterDateRange?.endDate?.utc ?? defaultDateRange.endDate.utc;
  const startDate = filterDateRange?.startDate?.utc ?? defaultDateRange.startDate.utc;

  return { endDate, startDate, filterDateRange };
};
