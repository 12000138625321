import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { Text } from '@ge/components/typography';
import { DateTimeFormats, IssueType } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens';

import { AssetContext } from '../../../context/assetContext';

const StyledIssue = styled.div`
  display: flex;
  padding: 6px;
  border-bottom: solid 1px ${(props) => props.theme.assetOverview.issuesSidebar.itemBorderColor};
  .issue-content {
    margin: 0 10px 0 6px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .uppercase {
      text-transform: uppercase;
    }
    > div {
      justify-content: space-between;
      display: flex;
      &.top {
        margin-bottom: 4px;
      }
      > span:first-child {
        margin-right: 10px;
      }
    }
  }
`;

const StyledSeverity = styled(Severity)`
  margin-top: 6px;
`;

const IssueButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 4px;
  &:focus {
    outline: none;
  }
`;

const ChevronIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: globalColors.white,
  rotate: -90,
}))``;

const TroubleshootIcon = styled(Icon).attrs(() => ({
  size: 10,
  icon: Icons.TROUBLESHOOT,
  color: globalColors.white,
}))``;

export const IssueItem = ({ issue, issueType }) => {
  // hooks
  const {
    assetState: { setDataExplorerCase },
  } = useContext(AssetContext);
  const { title, priority, description, status, lastFlagged, id, closed, createDate } = issue;
  const { showCaseDetails, showTaskDetails } = useContext(EntityDetailsContext);

  const handleEntitySelect = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showCaseDetails(id);
    },
    [showCaseDetails],
  );

  const handleTaskSelect = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showTaskDetails(id);
    },
    [showTaskDetails],
  );
  const displayDate = issueType === IssueType.CLOSED_CASES ? closed : lastFlagged;

  const taskDate = issueType === IssueType.CLOSED_TASKS || IssueType.OPEN_TASKS ? createDate : null;

  const displayDateFormatted = displayDate
    ? dayjs(displayDate).format(DateTimeFormats.DEFAULT_DATE)
    : null;

  const displayTaskFormattedDate = taskDate
    ? dayjs(taskDate.replace('Z', '').replace('T', '')).format(DateTimeFormats.DEFAULT_DATE)
    : null;

  return (
    <StyledIssue>
      <StyledSeverity level={priority} />
      <div className="issue-content">
        <div className="top">
          <Text type={typography.textTypes.grid} level={3}>
            {issueType === IssueType.OPEN_TASKS || issueType === IssueType.CLOSED_TASKS
              ? title
              : description}
          </Text>
          {/* type is undefined once it comes will uncomment it and use */}
          {/* {(issueType === IssueType.OPEN_TASKS || issueType === IssueType.CLOSED_TASKS) && (
            <Text type={typography.textTypes.grid} level={4}>
              {type}
            </Text>
          )} */}
        </div>
        <div className="bottom">
          <Text type={typography.textTypes.grid} level={4}>
            {issueType === IssueType.OPEN_TASKS || issueType === IssueType.CLOSED_TASKS
              ? displayTaskFormattedDate
              : displayDateFormatted}
          </Text>
          {(issueType === IssueType.OPEN_TASKS || issueType === IssueType.CLOSED_TASKS) && (
            <Text className="uppercase" type={typography.textTypes.grid} level={4}>
              {status}
            </Text>
          )}
        </div>
      </div>
      {(issueType === IssueType.OPEN_CASES || issueType === IssueType.CLOSED_CASES) && (
        <IssueButton onClick={() => setDataExplorerCase(id)}>
          <TroubleshootIcon />
        </IssueButton>
      )}
      <IssueButton
        onClick={(e) =>
          issueType === IssueType.OPEN_TASKS || issueType === IssueType.CLOSED_TASKS
            ? handleTaskSelect(e, id)
            : handleEntitySelect(e, id)
        }
      >
        <ChevronIcon />
      </IssueButton>
    </StyledIssue>
  );
};

IssueItem.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  issueType: PropTypes.string.isRequired,
};
