import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';
import { useAssetContext } from '@ge/feat-analyze/context/assetContext';
import { useAssetDetail } from '@ge/feat-analyze/data-hooks/use-asset-detail';
import {
  AssetOverviewTabs,
  AuthStrategy,
  Capability,
  PermissionScope,
  DateRange,
} from '@ge/models/constants';
import { getFilterDateRange } from '@ge/shared/util';

import { DashboardDateFilter } from '../dashboard/dashboard-date-filter';

import { AssetOverviewHeader } from './asset-overview-header';
import { DataExplorer } from './data-explorer/data-explorer';
import { AssetOverviewDetail } from './details/asset-overview-detail';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
  width: 100%;
  > div:first-child {
    button {
      text-transform: capitalize;
    }
  }
`;

export const AssetOverview = () => {
  const { t, ready } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const {
    queryParam,
    assetState,
    assetState: { datePickerRef, setKpiDateRange },
  } = useAssetContext();
  const { fetchAssetEvents } = useStoreActions((actions) => actions.assets);
  const { fetchEventHistory } = useStoreActions((actions) => actions.issues);

  // Todo: setting to pull first id in array but need to update to get all the other assets.
  const assetId = queryParam.assets ? queryParam.assets[0] : null;
  // Fetch necessary information for the page to render.
  const getDateRange = useStoreState(({ analyze }) => analyze.getDateRange);
  const entity = useAssetDetail({ assetId });
  const { site } = entity;
  const dateRange = getDateRange(entity?.site?.timezone);

  useEffect(() => {
    if (!assetId && datePickerRef?.current) {
      const filterDateRange = getFilterDateRange({
        range: DateRange.LAST_7_DAYS,
      });
      const dates = {
        range: DateRange.LAST_7_DAYS,
        startDate: filterDateRange.startDate.entityTimezone,
        endDate: filterDateRange.endDate.entityTimezone,
      };
      datePickerRef?.current?.applyDateRange(dates);
    }
  }, [assetId, datePickerRef]);

  useEffect(() => {
    if (assetId) {
      fetchEventHistory(assetId);
      fetchAssetEvents(assetId);
    }
    setKpiDateRange(dateRange);
  }, [assetId, fetchAssetEvents, fetchEventHistory, dateRange, setKpiDateRange]);

  if (!ready) return null;

  // we show data explorer tab if any of sites in current view have correct permissions, can revisit
  return (
    <Container>
      <AssetOverviewHeader />
      <StyledTabs
        defaultTab={queryParam.tab}
        onChangeHandler={(val) => assetState.setTabsParam(val)}
        headerContent={<DashboardDateFilter ref={datePickerRef} timezone={site.timezone} />}
      >
        <Tab
          capabilities={[
            {
              capability: Capability.CORE_KPIS,
              scopes: [PermissionScope.VIEW],
            },
            {
              capability: Capability.CASES_RECOMMENDATIONS,
              scopes: [PermissionScope.VIEW],
            },
          ]}
          authStrategy={AuthStrategy.ANY}
          label={t(AssetOverviewTabs.DETAILS, AssetOverviewTabs.DETAILS)}
        >
          <AssetOverviewDetail />
        </Tab>
        <Tab
          capabilities={[
            {
              capability: Capability.DATA_EXPLORER,
              scopes: [PermissionScope.VIEW, PermissionScope.EDIT],
            },
          ]}
          enforceView
          label={t(AssetOverviewTabs.DATA_EXPLORER, AssetOverviewTabs.DATA_EXPLORER)}
          siteLevel={false}
        >
          <DataExplorer />
        </Tab>
      </StyledTabs>
    </Container>
  );
};
