import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { EntityType } from '@ge/models/constants';
import { ErpFormTemplate } from '@ge/shared/components/tasks/templates/erp-form-template';

import TabDetailHeader from '../components/tab-detail-header';
import { TabDetailContainer } from '../entity-details-shared';

const TaskDetailWrapper = styled.div`
  margin-bottom: 40px;
  .task-panel-row {
    align-items: flex-start;
    display: flex;
    &:last-of-type {
      .column-detail {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

const DisplayTaskContainer = styled.section``;

export const TaskErp = ({ task, isTaskSelected }) => {
  const [isEditErpPanel] = useState(false);

  return (
    <TabDetailContainer>
      <TabDetailHeader entity={task} entityType={EntityType.TASK} />
      <TaskDetailWrapper>
        {!isEditErpPanel && (
          <DisplayTaskContainer>
            <ErpFormTemplate task={task} isTaskSelected={isTaskSelected} />
          </DisplayTaskContainer>
        )}
      </TaskDetailWrapper>
    </TabDetailContainer>
  );
};

TaskErp.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  isTaskSelected: PropTypes.bool,
};
