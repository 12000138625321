import { PropTypes } from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { typography } from '@ge/tokens';

const buttonBaseCss = css`
  font-family: ${typography.family.default};
  font-size: 13px;
  text-align: center;
  padding: 2px 14px;
  border-radius: 2px;
  max-width: 100%;

  ${(props) => {
    if (props.primary) return primaryCss;
    return defaultCss;
  }};

  width: ${(props) => (props.full ? '100%' : 'initial')};

  svg {
    vertical-align: inherit;
  }
`;

const defaultCss = css`
  background: ${(props) => props.theme.button.backgroundColor};
  border: ${(props) => props.theme.button.border};
  color: ${(props) => props.theme.button.textColor};
  font-weight: ${(props) => props.theme.button.fontWeight};
  &:disabled {
    color: ${(props) => props.theme.button.disabled.textColor};
    font-weight: ${(props) => props.theme.button.disabled.fontWeight};
    cursor: not-allowed;
  }
`;

const primaryCss = css`
  background: ${(props) => props.theme.button.primary.backgroundColor};
  border: ${(props) => props.theme.button.primary.border};
  color: ${(props) => props.theme.button.primary.textColor};
  text-shadow: ${(props) => props.theme.button.primary.textShadow};
  font-weight: ${(props) => props.theme.button.primary.fontWeight};
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const StyledButton = styled.button`
  ${buttonBaseCss};
`;

export const Button = ({
  theme,
  primary,
  full,
  type,
  disabled,
  autofocus,
  className,
  children,
  onClick,
}) => (
  <StyledButton
    theme={theme}
    primary={primary}
    full={full}
    className={className}
    type={type}
    disabled={disabled}
    autoFocus={autofocus}
    onClick={onClick}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  theme: PropTypes.instanceOf(Object),
  primary: PropTypes.bool,
  full: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  theme: null,
  primary: false,
  full: false,
  className: '',
  disabled: false,
  autofocus: false,
  onClick: () => {},
  type: 'button',
};

export const ButtonLink = styled(NavLink)`
  ${buttonBaseCss};
`;

export const ButtonGroup = styled.div`
  button,
  a {
    margin-left: 4px;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;
