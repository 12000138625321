/**
 * Navigation item aware of scopes to allow role-based rendering
 * of application navigation.
 */
export default class NavItem {
  label = null;

  icon = null;

  i18nKey = null;

  rootRoute = null;

  route = null;

  scope = null;

  parent = null;

  children = null;

  dropdownComponent = null;

  // used to tell the nav to put the L1 nav item at the bottom of the menu and above any scrolling
  sticky = null;

  constructor(navItem) {
    Object.assign(this, navItem);
  }
}
