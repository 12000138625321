import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { globalColors } from '@ge/tokens/colors';

const ButtonContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0 12px;

  button {
    border-color: ${globalColors.slate12};
    ${({ compact }) => compact && 'padding-left: 8px; padding-right: 8px;'};
  }

  button + button {
    margin-left: 5px;
  }
`;

export const ActionButtons = ({
  primaryLabel,
  primaryEnabled,
  onPrimaryClick,
  onSecondaryClick,
  showAlternate,
  alternateLabel,
  alternateEnabled,
  onAlternateClick,
}) => {
  const { t } = useTranslation(['reporting.sidebar'], { useSuspense: false });

  const handlePrimaryClick = useCallback(() => {
    primaryEnabled && onPrimaryClick();
  }, [primaryEnabled, onPrimaryClick]);

  const handleSecondaryClick = useCallback(() => {
    onSecondaryClick();
  }, [onSecondaryClick]);

  const handleAlternateClick = useCallback(() => {
    alternateEnabled && typeof onAlternateClick === 'function' && onAlternateClick();
  }, [alternateEnabled, onAlternateClick]);

  return (
    <ButtonContainer compact={showAlternate}>
      <Button onClick={handleSecondaryClick}>{t('cancel', 'Cancel')}</Button>
      <div>
        {showAlternate && (
          <Button disabled={!alternateEnabled} onClick={handleAlternateClick} primary>
            {alternateLabel}
          </Button>
        )}
        <Button disabled={!primaryEnabled} onClick={handlePrimaryClick} primary>
          {primaryLabel}
        </Button>
      </div>
    </ButtonContainer>
  );
};

ActionButtons.propTypes = {
  primaryLabel: PropTypes.string.isRequired,
  primaryEnabled: PropTypes.bool,
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  showAlternate: PropTypes.bool,
  alternateLabel: PropTypes.string,
  alternateEnabled: PropTypes.bool,
  onAlternateClick: PropTypes.func,
};

ActionButtons.defaultProps = {
  primaryEnabled: true,
  onPrimaryClick: () => {},
  onSecondaryClick: () => {},
  showAlternate: false,
  alternateLabel: null,
  alternateEnabled: false,
  onAlternateClick: null,
};
