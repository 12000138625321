import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@ge/components/containers';
import { Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { TooltipCell } from '@ge/components/table/table';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import PageHeader from '@ge/feat-monitor/components/page-header';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { useLocalStorage } from '@ge/hooks/use-local-storage';
import { Capability } from '@ge/models/constants';
import { SortDirection, AdminDefs } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import {
  useEventMaps,
  useFetchAllHP,
  useHPTableFilter,
} from '@ge/shared/data-hooks/use-handling-procedure';
import { useFilterDefs } from '@ge/shared/hooks';
import { AppScopes } from '@ge/shared/models/scopes';
import { killEventPropagation } from '@ge/shared/util';

import {
  defaultHandlingProcedureColumns,
  HandlingProcedureColumnDefs,
  HandlingProcedureColumns,
} from '../../models';
import { HLP_ACTIONS } from '../handling-procedures/hp-common';
import {
  AddDLWrapper,
  StyledDraggableTable,
  StyledEventMapSelect,
  StyledHPFilterWrapper,
  StyledIcon,
  StyledSubNavigation,
} from '../handling-procedures/hp-styled-components';

export const DLMetric = {
  NAME: 'name',
};

//@functional component
const HandlingProcedureTable = ({ openDLSidePanel, selectedRow, setSelectedRow }) => {
  const {
    visibleCols: columns,
    sortDirection,
    sortMetric,
    setVisibleColumns,
    updateColumnVisibility,
    updateSortMetric: sortAction,
  } = useColumnState({
    columnDefs: HandlingProcedureColumnDefs,
    defaultCols: defaultHandlingProcedureColumns,
    defaultSortMetric: DLMetric.NAME,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: AdminDefs.HANDLING_PROCEDURE_LIST_SORT_STATE_ID,
  });

  const {
    filterDefs,
    onChange: setFilterDefs,
    onReset: resetFilter,
  } = useFilterDefs({
    columnDefs: HandlingProcedureColumnDefs,
    stateId: AdminDefs.HANDLING_PROCEDURE_LIST_SORT_STATE_ID,
  });

  const [selectedMapId, setSelectedMapId] = useLocalStorage(
    `${AdminDefs.HANDLING_PROCEDURE_LIST_SORT_STATE_ID}.${'mapId'}`,
    {
      label: 'GE Events 1 (MarkVIe)',
      value: 'ge-1',
    },
  );

  const { tableData, isSuccess, isLoading } = useFetchAllHP(selectedMapId?.value);

  const { filteredTableData, setSearchText, filterValues } = useHPTableFilter({
    tableData,
    sortDirection,
    sortMetric,
    filterDefs,
    isSuccess,
  });

  const { t, ready } = useTranslation(['admin.configure', 'monitor.sites']);
  const { getEventsMaps, eventMapsLoading } = useEventMaps();

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => setSearchText({ key, value }),
    [setSearchText],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => setFilterDefs(columnKey, value),
    [setFilterDefs],
  );

  const customCellFn = useCallback((columnKey, cellValue) => {
    switch (columnKey) {
      case HandlingProcedureColumns.DETAILS:
        return (
          <div>
            <StyledIcon icon={Icons.CHEVRON_RIGHT} size={12} />
          </div>
        );
      default:
        return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '_';
    }
  }, []);

  const cellValueMapFn = useCallback((row) => {
    const { code, name, procTitle, updatedBy, updatedDate } = row;
    return {
      [HandlingProcedureColumns.EVENT_CODE]: code,
      [HandlingProcedureColumns.EVENT_NAME]: name,
      [HandlingProcedureColumns.HANDLING_PROCEDURE_NAME]: procTitle,
      [HandlingProcedureColumns.HANDLING_PROCEDURE_EDIT_BY]: updatedBy,
      [HandlingProcedureColumns.HANDLING_PROCEDURE_LAST_EDIT]: updatedDate,
      [HandlingProcedureColumns.DETAILS]: row,
    };
  }, []);

  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: HandlingProcedureColumnDefs,
    sortAction,
    sortedDirection,
    cellValueMapFn,
    customCellFn,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
    filterValues,
    draggable: true,
  });

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ADMIN_CONFIGURE_HANDLING_PROCEDURES);

  const { onVisibilityChange: handleColumnChanges } = useManageColumnVisibility({
    subKey: AdminDefs.HANDLING_PROCEDURE_LIST_SORT_STATE_ID,
    featPrefs,
    savePrefs,
    setVisibleColumns,
    updateColumnVisibility,
  });

  const onEventMapChange = (e) => {
    setSelectedMapId(e);
    resetFilter();
  };

  const onRowSelect = (e, row) => {
    killEventPropagation(e);
    setSelectedRow([row.code]);
    openDLSidePanel(`view__${row.procId}`);
  };

  const tableProps = useMemo(() => {
    if (selectedMapId) {
      return { noDataTitle: t('Handling_procedures.No_data_found', 'No Data Found') };
    }
    return {
      noDataTitle: t('Handling_procedures.No_data_selected', 'No Data Selected'),
      noDataDescription: t('Select_event_map', 'Select an Event Map to see handling procedures'),
    };
  }, [selectedMapId, t]);

  if (!ready || eventMapsLoading) {
    return <Loader />;
  }

  return (
    <PageContainer i18nReady={ready}>
      <PageHeader title={t('Handling_procedures.view_HP_title', 'Handling Procedures')} />
      <StyledSubNavigation>
        <StyledEventMapSelect
          placeholder={t('Handling_procedures.select_event_map', 'Select Event Map')}
          options={getEventsMaps()}
          label={t('Handling_procedures.event_map', 'Event Map')}
          width={100}
          id="hp_select"
          value={selectedMapId}
          onChange={onEventMapChange}
        />
        <StyledHPFilterWrapper>
          <AuthRender
            capability={Capability.FAULT_HANDLING}
            admin
            description="create new Handling Procedure"
            siteLevel={false}
          >
            <AddDLWrapper onClick={() => openDLSidePanel(HLP_ACTIONS.NEW)}>
              &#10010; {t('Handling_procedures.Add_HP', 'ADD HANDLING PROCEDURE')}
            </AddDLWrapper>
          </AuthRender>

          <ColumnSelectMenu
            translateFn={t}
            columnDef={HandlingProcedureColumnDefs}
            columnState={columns}
            onMenuClose={handleColumnChanges}
          />
        </StyledHPFilterWrapper>
      </StyledSubNavigation>
      {isLoading ? (
        <Loader />
      ) : (
        <StyledDraggableTable
          scrollable={true}
          columns={columns}
          values={filteredTableData}
          sortAction={sortAction}
          columnFactory={columnFactory}
          columnGroupFactory={columnGroupFactory}
          cellFactory={cellFactory}
          onValueSelect={onRowSelect}
          rowKeyProperty={['code']}
          onRetry={null}
          dropHandler={(columnValue) =>
            savePrefs(columnValue, AdminDefs.HANDLING_PROCEDURE_LIST_SORT_STATE_ID)
          }
          rowsSelected={selectedRow}
          noData={!filteredTableData.length}
          {...tableProps}
        />
      )}
    </PageContainer>
  );
};

HandlingProcedureTable.propTypes = {
  selectedRow: PropTypes.array,
  setSelectedRow: PropTypes.func,
  openDLSidePanel: PropTypes.func,
};

HandlingProcedureTable.defaultProps = {
  filterDefs: {},
  selectedSiteId: '',
  selectedAssetId: '',
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  selectedRow: [],
};

export default HandlingProcedureTable;
