import { assignIfDefined } from '@ge/util/object-utils';

import { Entity } from '../entity';
import { Site } from '../site';

/**
 * An Asset is any renewable energy producing machine, which could be
 * wind, balance of plant, or site controller.
 */
export class Asset extends Entity {
  /**
   * Display name for the asset
   * @type {string}
   */
  name;

  /**
   * Short display name for the asset
   * @type {string}
   */
  shortName;

  /**
   * Asset manufacturer
   * @type {string}
   */
  make;

  /**
   * Asset model
   * @type {string}
   */
  model;

  /**
   * Asset rating (Mw)
   * @type {number}
   */
  ratedPower;

  /**
   * Asset contract type
   * @type {string}
   */
  contractType;

  /**
   * Asset type (wind, balance of plant, site controller)
   * @type {string}
   */
  type;

  /**
   * Asset lifecycle status
   * @type {string}
   */
  status;

  /**
   * Asset serial number
   * @type {string}
   */
  serialNumber;

  /**
   * Asset system number
   * @type {string}
   */
  systemNumber;

  /**
   * Reset mode
   * @type {string}
   */
  resetMode;

  /**
   * Si
   * @type {string}
   */
  si;

  /**
   * Site where the Asset exists
   * @type {Site}
   */
  site;

  /**
   * Geographic location of the Asset
   * @type {string}
   */
  location;

  /**
   * List of components in the Asset
   * @type {array}
   */
  components;

  /**
   * Cases associated with the Asset
   * // TODO (astone): Update class information when Case object exists
   * @type {array}
   */
  cases;

  /**
   * Asset metrics
   * // TODO (astone): Replace type with AssetMetrics object.
   * @type {object}
   */
  metrics;

  /**
   * Contract Power data
   * @type {object}
   */
  contractPower;

  /**
   * @param {Partial<Asset>} [initObj] object to use to initialize class
   */
  constructor(initObj) {
    super(initObj);

    // Apply all known object values
    Object.assign(this, initObj);

    // Cast non-primitive object types.
    const { site } = this;
    assignIfDefined(this, 'site', site && !(site instanceof Site) && new Site(site));
    // TODO (astone): Add casted props when types exist?
  }

  withCases(cases) {
    this.cases = cases;
    return this;
  }

  withSite(site) {
    this.site = site;
    return this;
  }

  withLocation(location) {
    this.location = location;
    return this;
  }

  withComponents(components) {
    this.components = components;
    return this;
  }

  withMetrics(metrics) {
    this.metrics = metrics;
    return this;
  }
}
