import { getIn } from 'seamless-immutable';

import { AnnotatorConstants } from '../../../constants';

export default (state) => {
  let pathToActiveImage;
  let activeImage;

  if (state.annotationType === AnnotatorConstants.IMAGE) {
    pathToActiveImage = [AnnotatorConstants.CURRENT_IMAGE];
  } else if (state.annotationType === AnnotatorConstants.VIDEO) {
    pathToActiveImage = [AnnotatorConstants.KEYFRAMES, state.currentVideoTime || 0];
  }

  activeImage = getIn(state, pathToActiveImage) || null;

  return {
    pathToActiveImage,
    activeImage,
  };
};
