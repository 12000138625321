const EXECUTE_ONCE = {
  staleTime: 14400000, // re-fetch if data is > 4 hours old
  cacheTime: Infinity, // cache indefinitely, regardless of consumers
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

const REFRESH = {
  realTime: 30000,
  commandStatus: 5000,
  // backend policy currently appears to be around 15-minutes
  // can bump this up if needed but trying to avoid any weird scenarios where urls expire before getting refetched
  signedUrl: 1000 * 60 * 14,
  reFetchErpNotification: 1000 * 60 * 5,
};

const TASKS_REFRESH = {
  cacheTime: Infinity, // cache indefinitely, regardless of consumers
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const Config = {
  EXECUTE_ONCE,
  REFRESH,
  TASKS_REFRESH,
};
