import { PropTypes } from 'prop-types';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { Menu, placements } from '@ge/components/menu';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useAuth } from '@ge/shared/data-hooks';

import { isFunction, killEventPropagation } from '../util/general';

const SCROLL_OFFSET_RATIO = 0.93;

const StyledColumnSelectMenu = styled.div.attrs({ className: 'ColumnSelectMenu' })`
  position: relative;
  @media (min-width: 1145px) {
    top: 2px;
  }
`;

const IconButton = styled.button`
  margin-right: 15px;
  padding: 0;
`;

const SettingsIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SETTINGS,
  color: props.theme.sidebar.settingsIconColor,
}))``;

const MenuContainer = styled.div`
  text-align: left;
  position: relative;

  h3 {
    color: ${(props) => props.theme.layout.textColor};
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 14px 0 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: ${(props) => props.theme.layout.textColor};
      display: flex;
      font-size: 15px;
      margin: 10px 0;
    }
  }
`;

const ColGroupHeader = styled.span`
  color: ${(props) => props.theme.layout.textColor};
  display: block;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
  text-transform: uppercase;
`;

const MenuContent = styled.div`
  height: ${({ heightOffset }) => `calc(100vh - ${heightOffset}px)`};
  overflow: hidden;
  min-height: 260px;

  .col-menu-scroll {
    height: ${({ heightOffset }) => `calc(100vh - ${heightOffset / SCROLL_OFFSET_RATIO}px)`};
    padding: 14px;
    min-height: 245px;
  }
`;

const StyledGroupContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 25px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const ColumnSelectMenu = ({
  onSetColumnFilters,
  columnDef,
  columnState,
  translateFn,
  onMenuClose,
  heightOffset = 195,
  onClumnSelection,
}) => {
  const { t } = useTranslation();

  const changeHistory = useRef(new Map());
  const onColumnChangeHistory = useRef(new Map());

  const [anchorEl, setAnchorEl] = useState(null);
  const [columns, setColumns] = useState(null);

  // data hooks
  const { isAuthorized } = useAuth();

  const visibleColumns = useMemo(
    () =>
      columnState.reduce((columnIdArray, group) => {
        const visibleCols = group.cols.filter((col) => col.visible).map((col) => col.id);

        return [...columnIdArray, ...visibleCols];
      }, []),
    [columnState],
  );

  useEffect(() => {
    setColumns(visibleColumns);
    if (typeof onSetColumnFilters === 'function') onSetColumnFilters(visibleColumns);
  }, [visibleColumns, onSetColumnFilters]);

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (isFunction(onClumnSelection)) {
      onMenuClose(onColumnChangeHistory.current);
      onColumnChangeHistory.current.clear();
    } else {
      onMenuClose(changeHistory.current);
      changeHistory.current.clear();
    }
  };

  const handleColumnChange = (groupKey, columnKey) => {
    const compoundKey = `${groupKey}.${columnKey}`;
    const hide = columns.includes(columnKey);
    const newColumnState = hide
      ? columns.filter((key) => key !== columnKey)
      : [...columns, columnKey];

    setColumns(newColumnState);

    if (changeHistory.current.has(compoundKey)) {
      changeHistory.current.delete(compoundKey);
      return;
    }

    changeHistory.current.set(compoundKey, !hide);
    if (isFunction(onClumnSelection)) {
      onColumnChangeHistory.current.set(compoundKey, !hide);
      onClumnSelection(changeHistory.current);
      changeHistory.current.clear();
    }
  };

  if (!columnDef || !columns) {
    return null;
  }

  // Render checkboxes for all defined columns within the provided column gr
  const getColumnCheckboxes = (groupKey, groupObj, translate, visibleCols) =>
    Object.keys(groupObj.cols)
      .map((columnKey) => {
        const col = groupObj.cols[columnKey];

        if (col.capabilities?.length && !isAuthorized(col)) {
          return null;
        }

        if (!col.labels) {
          return null;
        }

        if (groupObj.locked || col.locked) {
          return null;
        }

        const labels = col.labels.map((label) => translate(label.a11yKey || '', label.a11yDefault));
        const checkedState = visibleCols.includes(columnKey)
          ? CheckedState.CHECKED
          : CheckedState.UNCHECKED;

        return (
          <li key={columnKey} className="body-1">
            <Checkbox
              checkState={checkedState}
              onChange={() => handleColumnChange(groupKey, columnKey)}
              disabled={groupObj.locked}
            />
            {labels.join(' / ')}
          </li>
        );
      })
      .filter(Boolean);

  // Render the column group and associated columns
  const renderColumnGroup = (groupKey) => {
    const groupObj = columnDef[groupKey];
    const groupLabels = groupObj.labels && groupObj.labels[0];

    const columnCheckboxes = getColumnCheckboxes(groupKey, groupObj, translateFn, columns);

    if (!columnCheckboxes.length) {
      return null;
    }

    if (!groupLabels) {
      return (
        <section key={groupKey}>
          <ul>{columnCheckboxes}</ul>
        </section>
      );
    }

    return (
      <StyledGroupContainer key={groupKey}>
        {groupLabels && (
          <ColGroupHeader>
            {translateFn(groupLabels.a11yKey, groupLabels.a11yDefault)}
          </ColGroupHeader>
        )}

        <ul>{columnCheckboxes}</ul>
      </StyledGroupContainer>
    );
  };

  return (
    <StyledColumnSelectMenu onClick={(e) => killEventPropagation(e)}>
      <IconButton type="button" onClick={(e) => showMenu(e)}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        placement={placements.TOP_END}
      >
        <MenuContainer>
          <MenuContent heightOffset={heightOffset}>
            <ScrollingContainer className="col-menu-scroll" autoHide={false}>
              <h3>{t('table.show_columns', 'SHOW COLUMNS')}</h3>
              {Object.keys(columnDef).map((groupKey) => renderColumnGroup(groupKey))}
            </ScrollingContainer>
          </MenuContent>
        </MenuContainer>
      </Menu>
    </StyledColumnSelectMenu>
  );
};

ColumnSelectMenu.propTypes = {
  columnDef: PropTypes.instanceOf(Object).isRequired,
  columnState: PropTypes.instanceOf(Object).isRequired,
  translateFn: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func,
  onSetColumnFilters: PropTypes.func,
  heightOffset: PropTypes.number,
  onClumnSelection: PropTypes.func,
};

ColumnSelectMenu.defaultProps = {
  onMenuClose: () => null,
};

export { ColumnSelectMenu };
