import { PropTypes } from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@ge/components/badge';
import { placements } from '@ge/components/menu';
import { TaskStatusBadge } from '@ge/components/task-badge';
import { killEventPropagation } from '@ge/shared/util';
import { globalColors } from '@ge/tokens/colors/colors';

import { EntityDetailsContext } from '../context';

import LinkedTaskMenu from './linked-task-menu';

export const EscalatedBadge = ({
  caseId,
  taskId,
  status,
  workScope,
  totalTaskCount,
  containerRef,
  menuPlacement = placements.BOTTOM_START,
  computeStyle,
}) => {
  const { t, ready } = useTranslation(['monitor.issues'], { useSuspense: false });

  const { showTaskDetails } = useContext(EntityDetailsContext);

  const openTaskPanel = useCallback(
    (e) => {
      killEventPropagation(e);
      showTaskDetails(taskId);
    },
    [taskId, showTaskDetails],
  );

  if (!ready || !totalTaskCount) {
    return null;
  }

  const title = t(`table.escalated`, 'Escalated');
  const label = t(`dynamic.work_scopes_short_name.${workScope}`, workScope);

  return (
    <>
      {taskId ? (
        <LinkedTaskMenu
          title={title}
          taskId={taskId}
          containerRef={containerRef}
          menuPlacement={menuPlacement}
          computeStyle={computeStyle}
        >
          <TaskStatusBadge label={label} status={status} onClick={openTaskPanel} />
        </LinkedTaskMenu>
      ) : (
        <TaskStatusBadge label={label} status={status} menuPlacement={menuPlacement} />
      )}
      {totalTaskCount > 1 && (
        <LinkedTaskMenu
          title={title}
          caseId={caseId}
          containerRef={containerRef}
          menuPlacement={menuPlacement}
          computeStyle={computeStyle}
        >
          <Badge color={globalColors.slate3} label={`+${totalTaskCount - 1}`} small />
        </LinkedTaskMenu>
      )}
    </>
  );
};

EscalatedBadge.propTypes = {
  caseId: PropTypes.string,
  taskId: PropTypes.string,
  status: PropTypes.string,
  workScope: PropTypes.string,
  totalTaskCount: PropTypes.number,
  containerRef: PropTypes.instanceOf(Object),
  menuPlacement: PropTypes.string,
  computeStyle: PropTypes.instanceOf(Object),
};

EscalatedBadge.defaultProps = {
  containerRef: null,
};
