import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SiteTypeTabs } from '@ge/models/constants';

import { AnalyzeAssetsSolarHeader } from './solar/assets-solar-header';
import { AnalyzeAssetsSorageHeader } from './storage/assets-storage-header';
import { AnalyzeAssetsWindHeader } from './wind/assets-wind-header';

export const AnalyzeAssetsHeader = (props) => {
  const { t } = useTranslation(['general'], {
    useSuspense: false,
  });
  const { type } = props;

  return (
    <>
      {type === t(SiteTypeTabs.SOLAR).toLowerCase() ? (
        <AnalyzeAssetsSolarHeader />
      ) : type === t(SiteTypeTabs.STORAGE).toLowerCase() ? (
        <AnalyzeAssetsSorageHeader />
      ) : (
        <AnalyzeAssetsWindHeader />
      )}
    </>
  );
};

AnalyzeAssetsHeader.propTypes = {
  type: PropTypes.string,
};

AnalyzeAssetsHeader.defaultProps = {};
