import { SolarSeverity } from '../models/severity';

export const getSeverityColor = (id) => {
  const severityItem = SolarSeverity.find((sev) => sev.id === id);

  return severityItem.color || '';
};

export const getUnitsForSeverity = (filterSelection) => {
  switch (filterSelection) {
    case 'COUNT':
      return '';
    case 'DURATION':
    case 'MTTR':
    case 'MTBF':
      return { unit: 'hour', units: 'hours' };
  }
};
