// Column and group names common to multiple tables - eases the need to
// reference specific implementations when working within abstractions.
export const TableBaseCols = {
  GROUP_SELECTED: 'group-selected',
  SELECTED: 'selected',

  // Notes
  GROUP_NOTES: 'Notes',
  NOTES_LATEST_NOTE: 'Latest Note',
  NOTES_EXTERNAL: 'External',
  NOTES_INTERNAL: 'Internal',
};
