import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import {
  getEventManagementList,
  createEventManagement,
  updateEventManagement,
  putEventManagement,
  getProcProperties,
} from '@ge/shared/services/event-management';
import { sorter } from '@ge/util/metric-sorter';

import { Config } from './config';

export const useEM = ({ search, filters, sortDirection, sortMetric, mapId }) => {
  const { getSiteById } = useStoreState((state) => state.sites);

  let { data, error, isLoading, isFetching } = useQuery(
    [QueryKey.EVENT_MANAGEMENT_LIST, mapId],
    () => getEventManagementList({ mapId }),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      enabled: !!mapId,
    },
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tempFilterValues = { includedsites: [], excludedsites: [] };
  const recordsFn = useMemo(() => {
    const records = [];
    data?.length &&
      data[0]?.events &&
      data[0]?.events?.forEach((rec) => {
        const value = rec;
        if (value?.flaggedProperties) {
          ['include', 'exclude'].forEach((record) => {
            if (value.flaggedProperties[`${record}SitesFilter`]?.length) {
              value[`${record}dsites`] = {
                value: value.flaggedProperties[`${record}SitesFilter`]
                  ?.map((val) => getSiteById(val.siteId)?.name)
                  .filter((val) => !!val),
              };
              tempFilterValues[`${record}dsites`] = [
                ...(tempFilterValues[`${record}dsites`] || []),
                ...value[`${record}dsites`]?.value,
              ];
            }
          });
        }
        value.blockCommands = (value?.commandProperties?.blockCommands || false).toString();
        value.blockAutomation = (value?.automationProperties?.blockAutomation || false).toString();
        value.maxResetLimit = (value?.commandProperties?.maxResetLimit || 0).toString();
        value.flaggedStates = { value: value?.flaggedProperties?.eligableStates || [] };
        tempFilterValues.flaggedStates = [
          ...(tempFilterValues.flaggedStates || []),
          ...(value?.flaggedStates?.value || []),
        ];
        records.push(value);
      });
    return records;
  }, [data, getSiteById, tempFilterValues]);

  if (filters?.code?.value) {
    recordsFn?.forEach((data) => (data.code = String(data.code)));
  } else {
    recordsFn?.forEach((data) => (data.code = Number(data.code)));
  }
  const { data: filteredData, filterValues } = useTableFilter({
    data: recordsFn,
    filters,
    search,
  });

  if (filterValues) {
    filterValues.includedsites = [...new Set(tempFilterValues.includedsites)];
    filterValues.excludedsites = [...new Set(tempFilterValues.excludedsites)];
    filterValues.flaggedStates = [...new Set(tempFilterValues.flaggedStates)];
  }
  const code = filterValues?.code?.map(String);
  // cuts a new ref when sorting to update graph accordingly
  const sortedData = useMemo(
    () => (filteredData?.length ? [...filteredData.sort(sorter(sortMetric, sortDirection))] : []),
    [filteredData, sortDirection, sortMetric],
  );
  return {
    eventManagementLists: sortedData,
    filterValues: { ...filterValues, code: code },
    error,
    isLoading,
    isFetching,
  };
};

export const useEventValidationList = ({ id, mapId }) => {
  let { data, error, isLoading } = useQuery(
    [QueryKey.EVENT_MANAGEMENT_LIST, id, mapId],
    () => getEventManagementList({ id, mapId }),
    {
      ...Config.EXECUTE_ONCE,
      enabled: !!(id && mapId),
    },
  );

  let details = data && data[0]?.events[0];
  return { isExist: data?.length, error, isLoading, details };
};

export const useCreateEventManagement = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: createEM, data, isLoading, isError, error } = useMutation(
    createEventManagement,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(QueryKey.EVENT_MANAGEMENT_LIST, { exact: true }),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );
  return useMemo(() => ({ createEM, data, isLoading, isError, error }), [
    createEM,
    data,
    isLoading,
    isError,
    error,
  ]);
};

export const useUpdateEventManagement = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: updateEM, data, isLoading, isError, error } = useMutation(
    putEventManagement,
    {
      onSuccess: () => queryCache.invalidateQueries(QueryKey.EVENT_MANAGEMENT_LIST),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );

  return useMemo(() => ({ updateEM, data, isLoading, isError, error }), [
    updateEM,
    data,
    isLoading,
    isError,
    error,
  ]);
};

export const useProcProperties = (id) => {
  let { data } = useQuery([QueryKey.EVENT_MANAGEMENT_LIST, id], () => getProcProperties({ id }), {
    ...Config.EXECUTE_ONCE,
    enabled: !!id,
    select: (data) => data[0],
  });

  return { data };
};

export const useEditEventManagement = () => {
  const queryCache = useQueryClient();

  const { mutateAsync: editEM, data, isLoading, isError, error } = useMutation(
    updateEventManagement,
    {
      onSuccess: () => queryCache.invalidateQueries(QueryKey.EVENT_MANAGEMENT_LIST),
      enabled: true,
      ...Config.EXECUTE_ONCE,
    },
  );

  return useMemo(() => ({ editEM, data, isLoading, isError, error }), [
    editEM,
    data,
    isLoading,
    isError,
    error,
  ]);
};
