import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';

import { MonitorContext } from './monitor-context';

export const MonitorProvider = ({ children }) => {
  const [selectedNoteType, setSelectedNoteType] = useState(0);
  const providerValue = useMemo(
    () => ({ selectedNoteType, setSelectedNoteType }),
    [selectedNoteType, setSelectedNoteType],
  );

  return <MonitorContext.Provider value={providerValue}>{children}</MonitorContext.Provider>;
};

MonitorProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

MonitorProvider.defaultProps = {
  children: null,
};
