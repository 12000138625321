import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';

import { AssetType, QueryKey, RocLogsActionList } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import {
  getROCActionTakenOptions,
  createROCActionLog,
  createBulkROCActionLog,
} from '@ge/shared/services/roc-logs-action';

export const useGetROCActionTakenOptions = ({
  assetMake,
  assetModel,
  controllerType,
  assetType,
  isActive = true,
}) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.ROC_LOGS_ACTIONS, assetMake, assetModel, controllerType, assetType],
    () =>
      getROCActionTakenOptions({
        assetMake,
        assetModel,
        controllerType,
        assetType,
      }),
    {
      select: (data) => {
        if (assetType === AssetType.SITE_CONTROLLER || assetType === AssetType.SUBSTATION) {
          return data?.filter((type) => type !== RocLogsActionList.RESCUE_BYPASS);
        }
        return data;
      },
    },
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
    },
  );

  return { data, error, isLoading };
};

export const useCreateROCActionLog = (opt = {}) => {
  const {
    mutate: create,
    isLoading,
    isError,
    error,
  } = useMutation(createROCActionLog, {
    enabled: true,
    ...Config.EXECUTE_ONCE,
    ...opt,
  });

  return useMemo(
    () => ({ create, isLoading, isError, error }),
    [create, error, isError, isLoading],
  );
};

export const useCreateBulkROCActionLog = (opt = {}) => {
  const {
    mutate: create,
    isLoading,
    isError,
    error,
  } = useMutation(createBulkROCActionLog, {
    enabled: true,
    ...Config.EXECUTE_ONCE,
    ...opt,
  });

  return useMemo(
    () => ({ create, isLoading, isError, error }),
    [create, error, isError, isLoading],
  );
};
