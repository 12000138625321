import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { fetchCaseDetails } from '@ge/feat-monitor/services';
import { Config } from '@ge/shared/data-hooks';

import { CasesQueryKeys } from './query-keys';

export const useCaseDetailQuery = (caseId, enabled = true) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    CasesQueryKeys.detail(caseId),
    () => fetchCaseDetails(caseId),
    {
      enabled: Boolean(enabled && caseId),
      ...Config.EXECUTE_ONCE,
    },
  );

  useEffect(() => {
    if (caseId && enabled) {
      queryClient.invalidateQueries(CasesQueryKeys.detail(caseId));
    }
  }, [caseId, enabled, queryClient]);

  return {
    cases: data?.entities?.cases,
    alerts: data?.entities?.alerts,
    ...rest,
  };
};
