import { useStoreActions, useStoreState } from 'easy-peasy';
import { useCallback } from 'react';

export const useFeaturePrefs = (featureId) => {
  // store
  const { getFeaturePrefs } = useStoreState((store) => store.prefs);
  const { setFeaturePrefs } = useStoreActions((actions) => actions.prefs);

  const featPrefs = getFeaturePrefs(featureId);

  /**
   * Save the provided content block as the feature preference.
   */
  const savePrefs = useCallback(
    (value, subPrefKey) => {
      const newPrefState = subPrefKey ? { ...featPrefs, [subPrefKey]: value } : value;

      return setFeaturePrefs({
        feature: featureId,
        value: newPrefState,
      });
    },
    [setFeaturePrefs, featureId, featPrefs],
  );

  return {
    savePrefs,
    featPrefs,
  };
};
