import axios from 'axios';

import { SegmentOffering } from '@ge/models/constants';
import { getIacSegmentAccessToken } from '@ge/shared/services/iac';

// #RENINSPECT MIGRATION NOTES#
// remove this once inspections gets added to bff

const requestFactory = (instance) => async (config) => {
  // ensure that default transforms are still included.
  config.transformResponse = [
    ...axios.defaults.transformResponse,
    ...(config.transformResponse ?? []),
  ];

  const { data } = await instance(config);

  return data;
};

// inspections backend expects rei token so always grab and insert into header for inspections calls
// this will go away once bff endpoints are added
const inspectionsTokenInterceptor = async (config) => {
  config.headers.authorization = `Bearer ${await getIacSegmentAccessToken(
    SegmentOffering.INSPECTIONS,
  )}`;

  return config;
};

// for bff endpoint
const tokenInterceptor = async (config) => {
  config.headers.authorization = await getIacSegmentAccessToken(SegmentOffering.INSPECTIONS);

  return config;
};

// inspections instance
const inspectionsInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DIGITAL_WIND_FARM_INSPECT_API}/inspections`,
});

inspectionsInstance.interceptors.request.use(inspectionsTokenInterceptor);

const uploadInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DIGITAL_WIND_FARM_INSPECT_API}/inspections`,
});

const uploadRequest = requestFactory(uploadInstance);

const inspectionsRequest = requestFactory(inspectionsInstance);

export const get = (url, options) =>
  inspectionsRequest({
    method: 'get',
    url,
    ...options,
  });
export const post = (url, data, options) =>
  inspectionsRequest({
    method: 'post',
    url,
    data,
    ...options,
  });
export const put = (url, data, options) =>
  inspectionsRequest({
    method: 'put',
    url,
    data,
    ...options,
  });
export const patch = (url, data, options) =>
  inspectionsRequest({
    method: 'patch',
    url,
    data,
    ...options,
  });
export const delRequest = (url, options) =>
  inspectionsRequest({
    method: 'delete',
    url,
    ...options,
  });
export const putPresignedUpload = (url, data, options) =>
  uploadRequest({
    method: 'put',
    url,
    data,
    ...options,
  });
export const downloadFileRequest = (url, options) =>
  axios.get(url, {
    responseType: 'blob',
    timeout: 1000 * 60 * 3,
    ...options,
  });

export const client = inspectionsInstance;

// reports instance
const reportsInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DIGITAL_WIND_FARM_INSPECT_API}/report`,
});

reportsInstance.interceptors.request.use(inspectionsTokenInterceptor);

const reportsRequest = requestFactory(reportsInstance);

export const reportsApi = {
  get: (url, options) =>
    reportsRequest({
      method: 'get',
      url,
      ...options,
    }),
  post: (url, data, options) =>
    reportsRequest({
      method: 'post',
      url,
      data,
      ...options,
    }),
};

export const instance = axios.create({
  baseURL: process.env.REACT_APP_DIGITAL_WIND_FARM_REI_API,
});

instance.interceptors.request.use(tokenInterceptor);

const _request = requestFactory(instance);

export const request = {
  get: (url, options) =>
    _request({
      method: 'get',
      url,
      ...options,
    }),
  post: (url, data, options) =>
    _request({
      method: 'post',
      url,
      data,
      ...options,
    }),
  delete: (url, options) =>
    _request({
      method: 'delete',
      url,
      ...options,
    }),
  patch: (url, data, options) =>
    _request({
      method: 'patch',
      url,
      data,
      ...options,
    }),
  put: (url, data, options) =>
    _request({
      method: 'put',
      url,
      data,
      ...options,
    }),
  putPresignedUpload: (url, data, options) =>
    uploadRequest({
      method: 'put',
      url,
      data,
      ...options,
    }),
};
