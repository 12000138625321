import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import { AttributeGroups, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import * as reportsService from '../services';

export const useReportTemplateDashboard = ({ sortMetric, sortDirection, pageSize = 500 }) => {
  const { getViewServiceGroupsSites: viewServiceGroups = [], sitesForView = [] } = useStoreState(
    (state) => state.sites,
  );

  const {
    data: allTemplates,
    fetchNextPage,
    hasNextPage,
    ...queryRest
  } = useInfiniteQuery(
    [QueryKey.REPORT_TEMPLATE, sortMetric, sortDirection],
    ({ pageParam = 0 }) => {
      return reportsService.fetchReportTemplateDashboardData({
        sortMetric,
        sortDirection,
        pageIdx: pageParam,
        pageSize,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.currentPageIdx < lastPage.pagination.totalPages - 1)
          return lastPage.pagination.currentPageIdx + 1;
        return false;
      },
      ...Config.EXECUTE_ONCE,
      cacheTime: 60,
      enabled: true,
    },
  );

  const flattenedTemplateData = useMemo(
    () => allTemplates?.pages.flatMap((page) => page.templates.map((template) => template)),
    [allTemplates],
  );

  const filteredData = flattenedTemplateData?.filter(
    (template) =>
      template.scope === null ||
      (template.scopeType === AttributeGroups.SERVICE_GROUP &&
        viewServiceGroups.some((serviceGroup) => serviceGroup?.id === template.scope)) ||
      (template.scopeType === AttributeGroups.SITES &&
        sitesForView.some((serviceGroup) => serviceGroup?.id === template.scope)),
  );

  return {
    allTemplates,
    data: filteredData,
    fetchNextPage,
    hasNextPage,
    ...queryRest,
  };
};

export const useReportTemplate = ({ templateId, staleTime = 10 * 60 * 1000 }) => {
  const { data = null, ...queryRest } = useQuery(
    [QueryKey.REPORT_TEMPLATE, templateId],
    () => {
      const result = reportsService.fetchReportByTemplateId(templateId);

      return result;
    },
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(templateId),
      staleTime,
    },
  );

  return {
    data,
    ...queryRest,
  };
};
