import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { typography } from '@ge/tokens';

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TabDetailContainer = styled.div`
  background-color: ${({ theme }) => theme.entityDetails.tabContentBackground};
  display: flex;
  flex-direction: column;
  height: 100%;

  table {
    thead {
      vertical-align: bottom;
      th {
        background-color: ${({ theme }) => theme.entityDetails.tabContentBackground};
        border-bottom: 2px solid ${({ theme }) => theme.entityDetails.tableHeaderBorder};
        font-size: 10px;
        font-weight: ${typography.weight.regular};
        padding-bottom: 2px;
      }
    }
    tbody tr:first-of-type td {
      border-top: 1px solid ${({ theme }) => theme.entityDetails.tableRowBorder};
    }
  }
`;

export const TimeframeToggle = styled.div`
  display: inline-block;
  margin: 0 20px 0 auto;
  position: relative;
  top: 20px;
  button {
    font-size: 11px;
    font-weight: ${typography.weight.bold};
    padding: 2px 4px;
    width: 55px;
    &:first-of-type {
      margin-right: 1px;
    }
  }
`;

export const OverviewWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 10px;
  .overview-block {
    display: flex;
    background: ${({ theme }) => theme.entityDetails.overviewBlockBackground};
    padding: 14px 18px;
    justify-content: space-between;
  }
`;

export const OverviewRow = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
`;

export const EntityCollapsiblePanel = styled(CollapsiblePanel)`
  margin-bottom: 40px;
  padding-left: 3px;

  .title {
    border: 0;
    svg {
      fill: ${(props) => props.theme.entityDetails.cases.details.collapseArrow};
    }
    h2 {
      font-size: 18px;
      line-height: 21px;
      &.add-margin {
        margin-top: 10px;
      }
    }
  }

  table {
    border-top: 0 !important;

    thead th {
      border-right: 0 !important;
    }
  }
`;

export const SectionContainer = styled.section`
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionHeader = styled.h3`
  line-height: 13px;
  margin-bottom: 3px;
  &:last-of-type {
    margin-top: 10px;
  }
`;

// TODO: look into making this a full component that takes a title and wraps it in the h4
export const OverviewTitle = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  h2 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 20px;
  }
`;

export const DetailsCurrent = styled.div`
  align-items: center;
  display: flex;
  height: 14px;
`;

export const DetailsCurrentSeparator = styled.span`
  align-items: center;
  color: ${(props) => props.theme.layout.textColor};
  display: flex;
  font-size: 11px;
  line-height: 15px;
  padding: 0 6px 0 4px;
`;
