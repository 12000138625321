/**
 * Site metrics object consolidating sensor and analytics data
 * for display in real-time monitoring tables.
 */
export class SiteMetrics {
  /**
   * Signal (between 0.00 and 1.00)
   * @type {number}
   */
  signal;

  /**
   * Data state
   * @type {SiteDataState}
   */
  data;

  /**
   * Counts of assets in each state within the site
   * @type {SiteAssetState}
   */
  assets;

  /**
   * Site performance data
   * @type {SitePerformance}
   */
  performance;

  /**
   * Site production data
   * @type {SiteProduction}
   */
  production;

  /**
   * Site weather condition data
   * @type {SiteConditions}
   */
  conditions;

  /**
   * Site maintenance data
   * @type {SiteMaintenance}
   */
  maintenance;

  /**
   * Site reactive power data
   * @type {SiteReactivePower}
   */
  reactivePower;

  /**
   * Site revenue data
   * @type {SiteRevenue}
   */
  revenue;

  /**
   * @param {Partial<SiteMetrics>} [initObj] SiteMetrics object to use to initialized class
   */
  constructor(initObj) {
    Object.assign(this, initObj);
  }

  /**
   * Attach a SiteAssetState object to the metrics object.
   *
   * @param {SiteAssetState} assets Current state of assets
   */
  withAssets(assets) {
    this.assets = assets;
    return this;
  }

  /**
   * Attach a SitePerformance object to the metrics object.
   *
   * @param {SitePerformance} performance Site performance
   */
  withPerformance(performance) {
    this.performance = performance;
    return this;
  }

  /**
   * Attach a SiteProduction object to the metrics object.
   *
   * @param {SiteProduction} production Site production
   */
  withProduction(production) {
    this.production = production;
    return this;
  }

  /**
   * Attach a SiteConditions object to the metrics object.
   *
   * @param {SiteConditions} conditions Site conditions
   */
  withConditions(conditions) {
    this.conditions = conditions;
    return this;
  }

  /**
   * Attach a SiteMaintenance object to the metrics object.
   *
   * @param {SiteMaintenance} maintenance Site maintenance
   */
  withMaintenance(maintenance) {
    this.maintenance = maintenance;
    return this;
  }

  /**
   * Attach a SiteReactivePower object to the metrics object.
   *
   * @param {SiteReactivePower} reactivePower Site reactive power
   */
  withReactivePower(reactivePower) {
    this.reactivePower = reactivePower;
    return this;
  }

  /**
   * Attach a SiteRevenue object to the metrics object.
   *
   * @param {SiteRevenue} revenue Site revenue
   */
  withRevenue(revenue) {
    this.revenue = revenue;
    return this;
  }
}

// TODO

/**
 * Performance metrics for a site
 */
export class SitePerformance {}

/**
 * Production metrics for a site
 */
export class SiteProduction {}

/**
 * Conditions metrics for a site
 */
export class SiteConditions {}

/**
 * Maintenance metrics for a site
 */
export class SiteMaintenance {}

/**
 * Reactive Power metrics for a site
 */
export class SiteReactivePower {}

/**
 * Reveue metrics for a site
 */
export class SiteRevenue {}
