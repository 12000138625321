import * as request from './api';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;

/**
 * Get array of regions
 */
export const fetchRegions = () =>
  request.get('/cmn/regions', { baseURL: CMN_BASE_URL, options: {} });

export const fetchSiteMetricsByRegion = (regionId, measure, start, end, interval) =>
  request.get(`/regions/${regionId}/sites/metrics`, {
    params: {
      measure,
      start,
      end,
      interval,
    },
  });

/**
 * Dynamically load the region mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/region/regions.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });

/**
 * IMPORTANT TODO: These calls have no endpoints underneath them,
 * so we cannot remove the mock calls from the UI.
 */
export const forceMocks = () =>
  import('./__mocks__/region/regions.mocks')
    .then((mocks) => mocks.forceMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });
