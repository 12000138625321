import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { Icon, Icons } from '@ge/components/icon';
import { ConfirmationDialog } from '@ge/components/modal';
import { EntityType, FormMode, NotesCategory } from '@ge/models/constants';
import { NoteDialog } from '@ge/shared/components/notes/note-dialog';
import useCaseNotes from '@ge/shared/data-hooks/use-case-notes';
import { typography } from '@ge/tokens';

import TabDetailHeader from '../components/tab-detail-header';
import { TabDetailContainer } from '../entity-details-shared';

import { CaseNotesAttachmentsTable } from './case-notes-attachments-table';

const NotesContainer = styled.div`
  padding: 2px 40px 40px 20px;
  position: relative;
`;

const NotesEmpty = styled.div`
  margin-left: 150px;
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  p {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    padding-bottom: 25px;
  }
`;

const AddNoteButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  width: 115px;
  position: absolute;
  left: 5px;
  top: 5px;
`;

const AddNoteIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

export const CaseNotesAttachments = ({ issue }) => {
  const { t } = useTranslation(['entity-details']);

  const [dataToDelete, setDataToDelete] = useState(null);
  const [mode, setMode] = useState(null);
  const [notesAttachments, setNotesAttachments] = useState([]);
  const [noteData, setNoteData] = useState({});
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

  const {
    notes,
    isLoading,
    deleteNote: del,
    editNote: edit,
  } = useCaseNotes({
    caseId: issue.id,
  });

  useEffect(() => {
    if (isLoading || !notes) return;
    setNotesAttachments(notes);
  }, [notes, isLoading]);

  const closeModal = useCallback(() => {
    setShowAddNoteModal(false);
  }, [setShowAddNoteModal]);

  const handleNote = useCallback(
    (modeType, data) => {
      setMode(Object.values(FormMode).includes(modeType) ? modeType : null);
      if (modeType === FormMode.EDIT) {
        setNoteData(data);
      }
      setShowAddNoteModal(true);
    },
    [setShowAddNoteModal],
  );

  const handleToggleHandler = (action, response) => {
    const originalData = Object.assign({}, response);
    originalData.scope = action;
    originalData.attachments = [];
    editNoteAndSI(originalData, response.id);
  };

  const onSaveNote = (response, selectedMode) => {
    if (response?.id) {
      if (selectedMode === FormMode.EDIT) {
        setNotesAttachments((prev) =>
          prev.map((note) => (note.id === response.id ? response : note)),
        );
      } else {
        setNotesAttachments([response, ...notesAttachments]);
      }
    }
    setShowAddNoteModal(false);
  };

  const editNoteAndSI = (values, noteId) => {
    values = {
      ...values,
      noteId,
      issue,
      entityType: EntityType.CASE,
      category: NotesCategory.NOTE,
    };
    const originalData = notes.find((n) => n.id === noteId);
    edit({ inputData: values, originalData });
    setShowAddNoteModal(false);
  };

  const deleteNote = (values) => {
    setShowDeleteConfirmationDialog(true);
    setDataToDelete(values);
  };

  const handleDeleteConfirm = useCallback(() => {
    if (dataToDelete) {
      const values = { ...dataToDelete, caseId: issue.id };
      del(values);
      setShowDeleteConfirmationDialog(false);
      setDataToDelete(null);
    }
  }, [dataToDelete, del, issue.id]);

  const handleDeleteCancel = () => {
    setShowDeleteConfirmationDialog(false);
    setDataToDelete(null);
  };

  return (
    <TabDetailContainer>
      <TabDetailHeader entity={issue} entityType={EntityType.CASE} />
      <NotesContainer>
        <AddNoteButton onClick={() => handleNote(FormMode.CREATE)}>
          <AddNoteIcon />
          {t('notes.add_note_and_attachment_button', 'Add Note & Attachment')}
        </AddNoteButton>
        {!isLoading && !notesAttachments.length ? (
          <NotesEmpty>
            <p>{t('notes.no_notes', 'No Note & Attachment information added.')}</p>
          </NotesEmpty>
        ) : (
          <CaseNotesAttachmentsTable
            notesAttachments={notesAttachments}
            entity={issue}
            editNoteAction={(data) => {
              handleNote(FormMode.EDIT, data);
            }}
            toggleHandler={(action, data) => {
              handleToggleHandler(action, data);
            }}
            editNoteAndSI={(data) => editNoteAndSI(data, data.id)}
            deleteNoteAction={(data) => {
              deleteNote(data);
            }}
            isLoading={isLoading}
          />
        )}
      </NotesContainer>
      <ConditionalRender shouldRender={showAddNoteModal}>
        <NoteDialog
          onClose={closeModal}
          isOpen={showAddNoteModal}
          onSaveNote={onSaveNote}
          entity={issue}
          entityType={EntityType.CASE}
          data={noteData}
          mode={mode}
        />
      </ConditionalRender>
      <ConfirmationDialog
        contentWidth
        header={t('confirm_delete_header', 'Confirmation')}
        isOpen={showDeleteConfirmationDialog}
        padContent={true}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        confirmLabel={t('confirm_delete', 'Delete')}
        cancelLabel={t('confirm_delete_cancel', 'Cancel')}
      >
        <p>{t('confirm_delete_message', 'Are you sure?')}</p>
      </ConfirmationDialog>
    </TabDetailContainer>
  );
};

CaseNotesAttachments.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
};
