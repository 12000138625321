import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { fetchSitesRealTimeData } from '@ge/feat-monitor/services';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

export const useWidgetAssetState = ({ isActive = true }) => {
  const { siteIds } = useReportScope();

  const {
    data: _data,
    error,
    isLoading,
  } = useQuery([QueryKey.SITES_AGGREGATED_DATA, siteIds], () => fetchSitesRealTimeData(), {
    ...Config.EXECUTE_ONCE,
    enabled: isActive && Boolean(siteIds?.length),
  });

  const data = useMemo(() => {
    if (!(_data?.length && siteIds?.length)) {
      return []; // is this the right default?
    }

    return _data.filter(({ id }) => siteIds.includes(id));
  }, [_data, siteIds]);

  return { data, error, isLoading };
};
