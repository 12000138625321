export const timezoneFormat = (_, dayjsLib) => {
  const proto = dayjsLib.prototype;
  const oldFormat = proto.format;
  proto.format = function(formatStr) {
    if (!this.isValid()) {
      return oldFormat.bind(this)(formatStr);
    }
    const str = formatStr;
    const result =
      str &&
      str.replace(/(zz)/g, () => {
        const abbrName = `[${this.offsetName('long')
          .match(/(?:(\s|^))[a-z]/gi)
          .join('')
          .replace(/\s/g, '')
          .toUpperCase()}]`;
        //Converting Coordinated Universal Time(CUT) to official abbreviation UTC
        return abbrName === 'CUT' ? 'UTC' : abbrName;
      });
    return oldFormat.bind(this)(result);
  };
};
