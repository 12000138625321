import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateLaborEstimates } from './create-labor-estimates';
import { EditLaborEstimates } from './edit-labor-estimates';
import { ViewLaborEstimates } from './view-labor-estimates';

export const LaborEstimatesSection = ({ section, task, templateMode, workStandardsData }) => {
  const { collapsed, metadata } = section;
  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return <CreateLaborEstimates collapsed={collapsed} metadata={metadata} />;
      case TaskTemplateModes.EDIT:
        return (
          <EditLaborEstimates
            metadata={metadata}
            task={task}
            workStandardsData={workStandardsData}
          />
        );
      case TaskTemplateModes.VIEW:
        return <ViewLaborEstimates metadata={metadata} task={task} />;
      default:
        return null;
    }
  }
};

LaborEstimatesSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  workStandardsData: PropTypes.object,
};

LaborEstimatesSection.defaultProps = {
  task: {},
};
