/* eslint-disable camelcase */
import { saveSvgAsPng } from 'save-svg-as-png';

import { AnnotatorConstants } from '../constants';

export const saveAnnotationToServer = (
  currentImage,
  annotaion,
  points,
  svgMetadata,
  newAnnotation = true,
) => {
  const defaultAnnotation = {
    family: '',
    sub_family: '',
    family_desc: '',
    anomaly_mapping: '',
    annotation_type: AnnotatorConstants.POLYGON,
    implication: '',
    severity: '',
    source: '',
    root_cause: '',
    comments: '',
    action: '',
    side: '',
    ai_ref: AnnotatorConstants.HUMAN,
    z: 0,
    length: 0,
    width: 0,
    chord: 0,
    included_in_report: 'true',
    annotated_imgname: '',
    defect_type: '',
    inspector_lid: 1,
  };
  const { width: imgWidth, height: imgHeight } = currentImage.attributes;
  const waypoints = JSON.stringify(
    points.map((point) => [[point[0] * imgWidth, point[1] * imgHeight]]),
  );
  const user_details = JSON.parse(
    Object.values(sessionStorage).filter((key) => key.includes('profile'))[0],
  );
  const data = {
    ...defaultAnnotation,
    ...annotaion,
    object_id: currentImage.id,
    waypoints,
    inspector_sso: user_details ? user_details.profile.preferred_username : '',
  };
  const flagNew = newAnnotation ? 1 : 0;

  return {
    data,
    flagNew,
    svgMetadata,
    currentImage,
  };
};

export const exportAnnotationAsPng = (annotation) => {
  saveSvgAsPng(document.getElementById(`annotation_${annotation.id}`), 'annotation.png');
};
