export const DrivetrainComponents = {
  BRAKE_CALIPER: {
    componentTypeId: 'BRAKE_CALIPER',
    description: 'Brake Caliper',
  },
  BRAKE_CHOPPER: {
    componentTypeId: 'BRAKE_CHOPPER',
    description: 'Brake Chopper',
  },
  BRAKE_HPU: {
    componentTypeId: 'BRAKE_HPU',
    description: 'Brake Hpu',
  },
  BRAKE_ROTOR_LOCK_SYSTEM: {
    componentTypeId: 'BRAKE_ROTOR_LOCK_SYSTEM',
    description: 'Brake & Rotor Lock System',
  },
  DRIVETRAIN: {
    componentTypeId: 'DRIVETRAIN',
    description: 'Drivetrain',
  },
  FLANGE_COUPLING_GEARBOX: {
    componentTypeId: 'FLANGE_COUPLING_GEARBOX',
    description: 'Flange Coupling - Gearbox',
  },
  FLANGE_COUPLING_MAINSHAFT: {
    componentTypeId: 'FLANGE_COUPLING_MAINSHAFT',
    description: 'Flange Coupling - Mainshaft',
  },
  FLEX_COUPLING: {
    componentTypeId: 'FLEX_COUPLING',
    description: 'Flex Coupling',
  },
  GEARBOX: {
    componentTypeId: 'GEARBOX',
    description: 'Gearbox',
  },
  GEARBOX_COOLING_SYSTEM: {
    componentTypeId: 'GEARBOX_COOLING_SYSTEM',
    description: 'Gearbox Cooling System',
  },
  GEARBOX_ISOLATION: {
    componentTypeId: 'GEARBOX_ISOLATION',
    description: 'Gearbox Isolation',
  },
  GENERATOR: {
    componentTypeId: 'GENERATOR',
    description: 'Generator',
  },
  GENERATOR_BEARING: {
    componentTypeId: 'GENERATOR_BEARING',
    description: 'Generator Bearing',
  },
  GENERATOR_COOLING_PUMP: {
    componentTypeId: 'GENERATOR_COOLING_PUMP',
    description: 'Generator Cooling Pump',
  },
  GENERATOR_COOLING_PUMPING_STATION: {
    componentTypeId: 'GENERATOR_COOLING_PUMPING_STATION',
    description: 'Generator Cooling Pumping Station',
  },
  GEN_BEARING_DRIVE_END: {
    componentTypeId: 'GEN_BEARING_DRIVE_END',
    description: 'Gen Bearing Drive End',
  },
  GEN_BRG_NON_DRIVE_END: {
    componentTypeId: 'GEN_BRG_NON_DRIVE_END',
    description: 'Gen Brg Non-Drive End',
  },
  MACHINE_HEAD: {
    componentTypeId: 'MACHINE_HEAD',
    description: 'Machine Head',
  },
  MAIN_BEARING_DRIVE_END: {
    componentTypeId: 'MAIN_BEARING_DRIVE_END',
    description: 'Main Bearing (Drive End)',
  },
  MAIN_BEARING_GEN_END: {
    componentTypeId: 'MAIN_BEARING_GEN_END',
    description: 'Main Bearing (Gen End)',
  },
  MAIN_BRG_HSG_DRIVE_END: {
    componentTypeId: 'MAIN_BRG_HSG_DRIVE_END',
    description: 'Main Brg Hsg (Drive End)',
  },
  MAIN_BRG_HSG_GEN_END: {
    componentTypeId: 'MAIN_BRG_HSG_GEN_END',
    description: 'Main Brg Hsg (Gen End)',
  },
  MAIN_SHAFT: {
    componentTypeId: 'MAIN_SHAFT',
    description: 'Main Shaft',
  },
  PILLOW_BLOCK_BEARING: {
    componentTypeId: 'PILLOW_BLOCK_BEARING',
    description: 'Pillow Block Bearing',
  },
  PILLOW_BLOCK_HOUSING: {
    componentTypeId: 'PILLOW_BLOCK_HOUSING',
    description: 'Pillow Block Housing',
  },
  TAPER_ROLLER_BEARING: {
    componentTypeId: 'TAPER_ROLLER_BEARING',
    description: 'Taper Roller Bearing',
  },
};

export default DrivetrainComponents;
