import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { useAssetSignalDataQuery } from './use-asset-signal-data-query';

/**
 * Use asset signal data.
 *
 * @param assetIds an array of asset ids
 * @param signalIds an array of signal ids, i.e. [WNAC.WdSpd, WTUR.W]
 * @param startDate the start date
 * @param endDate the end date
 * @param opts React-Query options i.e. staleTime, enabled, initialData
 */
export const useAssetSignalData = ({
  assetIds,
  xAxisSignal,
  yAxisSignal,
  startDate,
  endDate,
  chartFilter,
}) => {
  const assetsById = useStoreState(({ assets }) => assets.assets);

  const { data: _queryData, ...queryRest } = useAssetSignalDataQuery({
    assetIds,
    xAxisSignal,
    yAxisSignal,
    startDate,
    endDate,
    chartFilter,
  });

  const dataMapped = useMemo(() => {
    return (
      assetIds?.reduce((result, id, i) => {
        const assetData =
          _queryData?.data
            .filter((v) => v.assetId === id)
            .flatMap((v) => ({ ...v, assetIndex: i, asset: assetsById[v.assetId] })) ?? [];

        return result.concat(assetData);
      }, []) ?? []
    );
  }, [assetsById, _queryData, assetIds]);

  return { ...queryRest, chartData: { data: dataMapped } };
};
