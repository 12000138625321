import memoize from 'memoizerific';
import { useMemo } from 'react';

import { AuthStrategy } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

const AUTH_CACHE_SIZE = 1000;

const getSiteIds = ({ entities }) =>
  // can an entity be a site here?
  entities?.reduce((siteIds, { asset, site }) => {
    const siteId = asset?.site?.id ?? site?.id;

    if (siteId) {
      siteIds.push(siteId);
    }

    return siteIds;
  }, []);

// might be overkill but trying to short-circuit auth for highly repeating checks
// such as lots of assets or tasks that roll up to the same site since auth is at site-level
const authorize = memoize(AUTH_CACHE_SIZE)(
  (menuItem, siteId, authStrategy = AuthStrategy.ANY, authFn) => {
    const { capabilities } = menuItem;
    return authFn({ capabilities, siteIds: [siteId], authStrategy });
  },
);

export const useActionsMenu = ({ menuItems }) => {
  const { isAuthorized } = useAuth();

  return useMemo(
    () =>
      // memoizing depth of 1 to handle bulk select scenario
      memoize(1)((entities) => {
        const siteIds = getSiteIds({ entities });

        return Object.entries(menuItems ?? {}).reduce((authMenuItems, [key, item]) => {
          const { capabilities, authStrategy } = item;

          if (
            !capabilities?.length ||
            // all rows must be authorized to allow actions
            siteIds.reduce(
              (authorized, siteId) =>
                authorized && authorize(item, siteId, authStrategy, isAuthorized),
              true,
            )
          ) {
            authMenuItems[key] = item;
          }

          return authMenuItems;
        }, {});
      }),
    [isAuthorized, menuItems],
  );
};
