import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PartsField } from '@ge/models/constants';
import { TaskTemplateModes } from '@ge/models/constants';

import { Parts } from '../../../task-fields/parts';
import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditExpectedParts = ({
  metadata,
  workStandardsData,
  setWorkStandardsData,
  templateMode,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.expected_parts', 'Expected Parts')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup className="expected-parts edit-parts">
          {!metadata?.expectedParts.hidden && (
            <Parts
              metadata={metadata.expectedParts.edit}
              fieldName={PartsField.EXPECTED_PARTS}
              workStandardsData={workStandardsData}
              setWorkStandardsData={setWorkStandardsData}
              templateMode={templateMode}
            />
          )}
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

EditExpectedParts.propTypes = {
  metadata: PropTypes.object.isRequired,
  workStandardsData: PropTypes.object,
  setWorkStandardsData: PropTypes.func,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};
