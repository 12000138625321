import { useStoreActions, useStoreState } from 'easy-peasy';
import { useQuery, useQueries } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { Config } from './config';
import { useAuth } from './use-auth';

export const useInitialStaticData = () => {
  // we use auth with static data since it is loaded early in the app lifecycle
  const { isAuthenticated } = useAuth();

  // store
  const appState = useStoreState((state) => state.app);

  const { fetchPrefs } = useStoreActions((actions) => actions.prefs);
  const { fetchFeatureFlags } = useStoreActions((actions) => actions.tenant);

  // note - pref and view pref api combined to one in BFF so no need of 2 apis calls here.
  const fetches = {
    //[QueryKey.VIEW_PREFS]: { queryFn: fetchViewPrefs },
    [QueryKey.PREFS]: { queryFn: fetchPrefs },
  };

  const { isLoading: isLoadingFeatureFlags } = useQuery(
    [QueryKey.FEATURE_FLAGS],
    () => fetchFeatureFlags(),
    {
      ...Config.EXECUTE_ONCE,
      enabled: true,
    },
  );

  const queries = useQueries(
    Object.entries(fetches).map(([queryKey, { queryFn, enabled = true }]) => ({
      queryKey,
      queryFn,
      ...Config.EXECUTE_ONCE,
      // only fetch if authenticated, not loaded, and not in a popout
      enabled: enabled && isAuthenticated() && !appState.isPopout,
      retry: 1,
    })),
  );

  // currently sets isLoading to false if *all* fetches return
  const isLoading = queries.some((query) => query.isLoading);
  // bypass data loading logic if not authenticated since no data will be loaded
  const isLoaded = !isAuthenticated() || !isLoading || !isLoadingFeatureFlags;

  return { isLoaded };
};
