import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_MWP_API;

/**
 * GET import task template
 */

export const getTaskTemplate = (siteIds) => {
  return request.get(`/mwp/task/task-template-import`, {
    baseURL: BASE_URL,
    params: { siteIds: siteIds },
  });
};

/**
 * Post task list in xlsx file
 */

export const postTaskList = (body) => {
  const headers = {};
  headers['Content-Type'] = 'multipart/form-data';
  return request.post(`/mwp/task/upload-tasks`, body, {
    baseURL: BASE_URL,
    headers,
  });
};
