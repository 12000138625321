import dayjs from 'dayjs';

import { EntityTypeMapping } from '@ge/models/constants';

import * as request from './api';

const RTMC_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_RTMC_API;
const URLS = {
  ROC_ACTION_TAKEN_OPTIONS: '/rtmc/roc-action-taken-options',
  CREATE_ROC_ACTION_LOG: '/rtmc/create-roc-action-log',
};

export const getROCActionTakenOptions = ({ assetMake, assetModel, controllerType, assetType }) => {
  const params = { assetMake, assetModel, controllerType, assetType };

  return request.get(URLS.ROC_ACTION_TAKEN_OPTIONS, {
    baseURL: RTMC_BASE_URL,
    params,
  });
};

export const createROCActionLog = (data) => {
  const payload = {
    caseId: data?.entityId,
    siteId: data?.entity?.site?.id,
    assetId: data?.entity?.asset?.id,
    assetType: EntityTypeMapping[data?.entity?.asset?.type],
    type: 'ROC_ACTION_TAKEN',
    eventDesc: data?.entity?.description,
    actionTakenValues: data?.actionsTaken,
    desc: data?.description,
    eventCode: data?.entity?.code,
  };
  if (data?.createdTime) {
    payload.actionTakenTime = dayjs(data?.createdTime).valueOf();
  }

  return request.post(URLS.CREATE_ROC_ACTION_LOG, payload, {
    baseURL: RTMC_BASE_URL,
  });
};

export const createBulkROCActionLog = (data) => {
  return request.post(URLS.CREATE_ROC_ACTION_LOG, data, {
    baseURL: RTMC_BASE_URL,
  });
};
