import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import flatten from 'ramda/src/flatten';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { ChartType, ParetoChart } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Select } from '@ge/components/select';
import { DataLoaderType, KpiCategoryDefs, SortDirection } from '@ge/models/constants';
import { useFilterDateRange } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';

import { useFleetSitePerformance } from '../../data-hooks';
import { FilterDefs } from '../../models/analyze-filter-defaults';
import { formatKPIValue } from '../../util';
import SitePerformanceTable from '../tables/site-performance-table';

import { SITE_PERFORMANCE_CHART_COLOR_MAP } from './chart-consts';
import ColumnChart from './column-chart';
import DashboardChartLegend from './dashboard-chart-legend';
import { DashboardChartToggle } from './dashboard-chart-toggle';

const StyledContainer = styled.div`
  padding: 26px 18px 26px 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  h3 {
    margin-bottom: 2px;
  }
  .options {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const ParetoContainer = styled.div`
  position: relative;
  margin-bottom: 28px;

  &&&& {
    width: 100%;
  }

  .arrow-wrapper {
    display: flex;
    position: absolute;
    bottom: -6px;
    left: 50px;
  }
`;

const StyledScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 0px;
`;

const StyledChartsWrapper = styled.div`
  display: flex;
  min-height: 321px;
  overflow: hidden;

  > div {
    width: 100%;

    &.parato-chart {
      margin-bottom: 13px;
      width: 100%;

      &:last-child {
        width: 100%;
      }
    }
  }
`;

const StyledDetailsWrapper = styled.div`
  h3 {
    margin-bottom: 20px;
    margin-left: 10px;
  }
  padding: 15px 15px 15px 0px;
  border-radius: 4px;
  background: ${(props) => props.theme.analyze.tableBackground};
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 400px;
  min-height: 400px;
`;

const Arrow = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CHEVRON,
  color: props.theme.analyze.pareto.arrowIconColor,
  viewbox: '0 0 20 18',
}))``;

const ArrowButon = styled.button`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${({ theme }) => theme.analyze.pareto.buttonColor};
  margin-right: 22px;

  &.disabled {
    background: ${({ theme }) => theme.analyze.pareto.disabledButtonColor}
    cursor: not-allowed;
  }
`;

const Title = styled.div`
  align-items: center;
  border-bottom: 2px solid ${(props) => props.theme.analyze.site.chartTitleBorder};
  display: flex;
  flex-flow: row nowrap;
  height: 24px;
  justify-content: space-between;
  padding-bottom: 6px;
  margin-bottom: 6px;
`;

const TotalContainer = styled.div`
  display: flex;
  padding-right: 30px;
`;

const Total = styled.p`
  color: ${(props) => props.theme.layout.textColor};
  font-size: 24px;
  align-self: left;
`;

const TotalText = styled.p`
  height: 24px;
  max-width: 30px;
  text-align: right;
  align-self: center;
  font-size: 10px;
  padding-right: 10px;
  text-transform: uppercase;
`;

const Units = styled.sup`
  color: ${(props) => props.theme.layout.textColor};
  font-size: 12px;
  align-self: right;
`;

const PARETO_CHART_HEIGHT = 292;
const COLUMN_CHART_HEIGHT = 321;

const SitePerformance = ({ theme, category, primaryGroup }) => {
  const { t, i18n } = useTranslation(['analyze.dashboard']);
  const sites = useStoreState((state) => state.sites.sites);

  const { push } = useHistory();

  const selectOptionsData = [
    { value: SortDirection.ASC, label: t('filters.bottom_contributors', 'Bottom Contributors') },
    { value: SortDirection.DESC, label: t('filters.top_contributors', 'Top Contributors') },
  ];

  const legend = useMemo(
    () => [
      {
        color: theme.analyze.columnChart.legendColor,
        name: t(`dynamic.kpi_chart.legends.${category}`),
      },
    ],
    [category, t, theme.analyze.columnChart.legendColor],
  );

  const chartTypes = useMemo(
    () => [
      {
        id: ChartType.COLUMN,
        type: ChartType.COLUMN,
      },
      {
        id: ChartType.PARETO,
        type: ChartType.PARETO,
        disabled: primaryGroup !== FilterDefs.AVAILABILITY ? true : false,
      },
    ],
    [primaryGroup],
  );

  // local state
  const [selectedRegion, setSelectedRegion] = useState();
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
  const [selectedChart, setSelectedChart] = useState(chartTypes[0]);
  const [paretoPageIndex, setParetoPageIndex] = useState(0);

  // store
  const { dateRange } = useStoreState(({ analyze }) => analyze);

  // hooks
  const { endDate, startDate } = useFilterDateRange({ dateRange });

  // data hooks
  const { data: regionSitesChartData, isLoading } = useFleetSitePerformance({
    category,
    endDate,
    sortDirection,
    startDate,
    pageIndex: paretoPageIndex,
  });

  // Grab top/bottom site performers ids.
  const sitePerformerIds = useMemo(
    () => regionSitesChartData[0]?.data.map((item) => item?.id),
    [regionSitesChartData],
  );

  // Map site performer ids to chart color map.
  const siteColorMap = useMemo(
    () =>
      sitePerformerIds?.reduce((acc, id, i) => {
        acc[id] = SITE_PERFORMANCE_CHART_COLOR_MAP[i];
        return acc;
      }, {}),
    [sitePerformerIds],
  );

  const formatedParetoData = useMemo(
    () =>
      selectedRegion?.data
        .map((item) => {
          const siteName = sites[item.id]?.name;

          return {
            id: item.id,
            type: siteName,
            title: siteName,
            region: item.region,
            value: item.unavailabilityValue * 100,
          };
        })
        .sort((a, b) => b.value - a.value),
    [selectedRegion, sites],
  );

  const paretoTotalValue = useMemo(
    () =>
      selectedRegion?.data.reduce((acc, item) => {
        acc = acc + item.unavailabilityValue * 100;
        return acc;
      }, 0),
    [selectedRegion],
  );

  const maxColumnValue = useMemo(() => {
    return Math.round(
      Math.max(
        ...flatten(regionSitesChartData?.map((region) => region?.data.map((site) => site.y))),
      ),
    );
  }, [regionSitesChartData]);

  useEffect(() => {
    if (!regionSitesChartData?.length) {
      setSelectedRegion();

      return;
    }

    setSelectedRegion(regionSitesChartData[0]);
  }, [regionSitesChartData]);

  // if current chart type is disabled, toggle to first enabled type
  useEffect(() => {
    // can review this but current chart in state won't reflect disabled state so cross-ref the main chart types def
    const chart = chartTypes.find(({ type }) => selectedChart?.type === type);

    if (chart?.disabled) {
      const defaultChart = chartTypes.find(({ disabled }) => !disabled);

      setSelectedChart(defaultChart);
    }
  }, [chartTypes, selectedChart]);

  const routeToSite = useCallback((siteId) => push(`/analyze/site/${siteId}`), [push]);

  const handleRegionSelect = useCallback(
    (region) => setSelectedRegion(region),
    [setSelectedRegion],
  );

  const handleSiteSelect = useCallback(
    (e, site) => {
      killEventPropagation(e);
      if (site?.id) {
        routeToSite(site.id);
      }
    },
    [routeToSite],
  );

  const handleSort = useCallback(
    ({ value }) => {
      setParetoPageIndex(0);
      setSortDirection(value);
    },
    [setSortDirection, setParetoPageIndex],
  );

  const handleChartToggle = useCallback(
    (toggle) => {
      setParetoPageIndex(0);
      setSelectedChart(toggle);
    },
    [setSelectedChart, setParetoPageIndex],
  );

  const handleNextParetoPage = useCallback(() => {
    setParetoPageIndex(paretoPageIndex + 1);
  }, [setParetoPageIndex, paretoPageIndex]);

  const handlePreviousParetoPage = useCallback(() => {
    if (paretoPageIndex > 0) {
      setParetoPageIndex(paretoPageIndex - 1);
    }
  }, [setParetoPageIndex, paretoPageIndex]);

  const ColumnCharts = () =>
    regionSitesChartData?.map((region, i) => (
      <ColumnChart
        onRegionSelect={handleRegionSelect}
        active={selectedRegion?.id === region?.id}
        colorMap={siteColorMap}
        key={region?.id}
        category={category}
        maxColumnValue={maxColumnValue}
        chartData={{
          ...region,
          name: region.name ?? t(`site_performance.${region.id}`, region.id),
        }}
        yaxis={regionSitesChartData.length === i + 1}
        namespace={'analyze.dashboard'}
      />
    ));

  const Pareto = () => {
    const endOfData = formatedParetoData?.length < 10;

    return (
      <ParetoContainer>
        <ParetoChart
          className="parato-chart"
          data={formatedParetoData ?? []}
          isLoading={isLoading}
          height={PARETO_CHART_HEIGHT}
          total={paretoTotalValue}
          dynamicScaling
        />
        <div className="arrow-wrapper">
          <ArrowButon
            onClick={handleNextParetoPage}
            disabled={endOfData}
            className={endOfData && 'disabled'}
          >
            <Arrow />
          </ArrowButon>
          <ArrowButon
            onClick={handlePreviousParetoPage}
            disabled={paretoPageIndex === 0}
            className={paretoPageIndex === 0 && 'disabled'}
          >
            <Arrow rotate={180} />
          </ArrowButon>
        </div>
      </ParetoContainer>
    );
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <h3>{t('sites_performance', 'Sites Performance')}</h3>
        <Title>
          <div className="options">
            <Select
              small
              value={selectOptionsData.find(({ value }) => value === sortDirection)}
              options={selectOptionsData}
              onChange={handleSort}
            />
          </div>
          {selectedChart.type === ChartType.PARETO &&
            (paretoTotalValue || paretoTotalValue === 0) && (
              <TotalContainer>
                <TotalText>{t('kpi.total_value', 'Total Value')}</TotalText>
                <Total>
                  {formatKPIValue(paretoTotalValue, i18n.language)}
                  <Units>{'%'}</Units>
                </Total>
              </TotalContainer>
            )}
          <DashboardChartToggle
            types={chartTypes}
            onToggle={handleChartToggle}
            currentType={selectedChart}
          />
        </Title>
      </StyledHeader>
      <StyledScrollWrapper>
        <ScrollingContainer offsetX={10} right>
          <DataLoader
            isLoading={isLoading || !category}
            renderCondition
            type={DataLoaderType.COLUMN}
            noData={!isLoading && !regionSitesChartData?.length > 0}
            height={`${COLUMN_CHART_HEIGHT}px`}
          >
            {selectedChart.type === ChartType.COLUMN && (
              <LegendWrapper>
                <DashboardChartLegend legend={legend} />
              </LegendWrapper>
            )}
            <StyledChartsWrapper>
              {selectedChart.type === ChartType.COLUMN && <ColumnCharts />}
              {selectedChart.type === ChartType.PARETO && <Pareto />}
            </StyledChartsWrapper>
          </DataLoader>
          <StyledDetailsWrapper>
            <DataLoader isLoading={isLoading} renderCondition type={DataLoaderType.TABLE}>
              <SitePerformanceTable
                title={selectedChart.type !== ChartType.PARETO ? selectedRegion?.name : null}
                colorMap={siteColorMap}
                sites={selectedRegion?.data ?? []}
                onSiteSelect={handleSiteSelect}
              />
            </DataLoader>
          </StyledDetailsWrapper>
        </ScrollingContainer>
      </StyledScrollWrapper>
    </StyledContainer>
  );
};

SitePerformance.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.oneOf(Object.values(KpiCategoryDefs)),
  primaryGroup: PropTypes.oneOf(Object.values(FilterDefs)),
};

SitePerformance.defaultProps = {
  category: undefined,
  primaryGroup: undefined,
};

export default withTheme(SitePerformance);
