import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { Title } from '@ge/components/typography';
import { BacklogFilters } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

import { PlanningContext, DefaultFilters } from '../../../context/planning-provider';

import TealChevron from './teal-chevron.svg';
import WhiteChevron from './white-chevron.svg';

const FilterMenu = styled.div``;

const FilterBtn = styled.div`
  border-radius: 2px;
  padding: 3px 8px 6px 6px;
  margin-top: 1px;
  background: ${(props) =>
    props.active ? props.theme.select.primaryBackground : props.theme.select.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15px;
  border: solid 1px ${({ theme }) => theme.select.borderColor};

  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: auto !important;
`;

const FilterIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.FILTER,
  color: props.theme.filterPanel.iconColor,
}))`
  margin-right: 3px;
  padding-right: 2px;
`;

const Text = styled.span`
  font-size: 12px;
  line-height: 10px;
  color: ${(props) => props.theme.manage.filterMenu.color};
  padding-right: 12px;
  margin-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75px !important;
`;

const StyledImg = styled.img`
  height: 15px;
  width: 10px;
  margin-left: 1px;
`;

const FilterCountText = styled(Title).attrs(() => ({
  level: 4,
}))`
  color: ${(props) => props.theme.manage.filterMenu.color};
  text-transform: uppercase;
  margin: 0 3px;
`;

const StyledContent = styled.div`
  > .title {
    text-transform: uppercase;
    padding: 8px;
  }
  .footer {
    padding: 6px;
    border-top: solid 1px ${(props) => props.theme.manage.filterMenu.borderColor};
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;
    background: ${(props) => props.theme.manage.filterMenu.background};
  }
  max-height: 450px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  .title {
    border: 0;
    padding: 8px 0;
    margin: 0 8px;
    border-top: solid 1px ${(props) => props.theme.manage.filterMenu.background};
    font-weight: 700;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.manage.filterMenu.chevronColor};
    }
  }
  label {
    margin-bottom: 8px;
    margin-left: 18px;
    text-transform: capitalize;
  }
`;

const StyledFilterList = styled.div`
  padding-bottom: 12px;
`;

export const BacklogFilter = ({ filters, state }) => {
  const { t } = useTranslation(['tasks', 'general', 'manage.planning'], { useSuspense: false });

  const {
    planningState: {
      backlogFilter,
      backlogTaskFilter,
      handleSetBacklogFilter,
      handleSetBacklogTaskFilter,
    },
  } = useContext(PlanningContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setActive] = useState(false);
  const [backLogFilterState, setBacklogFilterState] = useState(
    state === 'backlog' ? backlogFilter.filters : backlogTaskFilter.filters,
  );

  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    state === 'backlog'
      ? setBacklogFilterState(backlogFilter.filters)
      : setBacklogFilterState(backlogTaskFilter.filters);
  };

  const handleApply = () => {
    setActive(backLogFilterState != null);
    state === 'backlog'
      ? handleSetBacklogFilter(backLogFilterState, backlogFilter.assets)
      : handleSetBacklogTaskFilter(backLogFilterState, backlogTaskFilter.assets);
    handleMenuClose();
  };

  const handleClear = () => {
    setAnchorEl(null);
    setActive(null);
    state === 'backlog'
      ? handleSetBacklogFilter(DefaultFilters, backlogFilter.assets)
      : handleSetBacklogTaskFilter(DefaultFilters, backlogTaskFilter.assets);
  };

  const handleChange = (filterType, updateValue) => {
    const prevDef = backLogFilterState[filterType];
    const hide = prevDef.value.includes(updateValue);

    const updateValues = hide
      ? prevDef.value.filter((key) => key !== updateValue)
      : [...prevDef.value, updateValue];

    const newState = {
      ...backLogFilterState,
      [filterType]: {
        ...prevDef,
        value: updateValues,
      },
    };

    setBacklogFilterState(newState);
  };

  const buildCheckboxes = (filter, value, label) => {
    return (
      <Checkbox
        label={label}
        checkState={
          backLogFilterState[filter]?.value.includes(value)
            ? CheckedState.CHECKED
            : CheckedState.UNCHECKED
        }
        onChange={() => handleChange(filter, value)}
        key={value}
      />
    );
  };

  useEffect(() => {
    // track the filter context and update the local filter state to re-check the checkboxes on menu open.
    if (state === 'backlog') {
      setBacklogFilterState(backlogFilter.filters);
    } else {
      setBacklogFilterState(backlogTaskFilter.filters);
    }
  }, [backlogFilter.filters, backlogTaskFilter.filters, state]);

  const filterCounts = useMemo(() => {
    if (state === 'backlog') {
      return (
        backlogFilter.filters[BacklogFilters.SOURCE]?.value?.length +
        backlogFilter.filters[BacklogFilters.STATUS]?.value?.length +
        backlogFilter.filters[BacklogFilters.WORKSCOPE]?.value?.length +
        backlogFilter.filters[BacklogFilters.PRIORITY]?.value?.length +
        backlogFilter.filters[BacklogFilters.FLAG]?.value?.length
      );
    } else {
      return (
        backlogTaskFilter.filters[BacklogFilters.SOURCE]?.value?.length +
        backlogTaskFilter.filters[BacklogFilters.STATUS]?.value?.length +
        backlogTaskFilter.filters[BacklogFilters.WORKSCOPE]?.value?.length +
        backlogTaskFilter.filters[BacklogFilters.PRIORITY]?.value?.length +
        backlogTaskFilter.filters[BacklogFilters.FLAG]?.value?.length
      );
    }
  }, [backlogFilter.filters, backlogTaskFilter.filters, state]);

  // check and initliaze active state on load if persisted filter state exists.
  useEffect(() => {
    if (filterCounts > 0) setActive(backLogFilterState != null);
  }, [filterCounts, backLogFilterState, setActive]);

  const checkIsExpanded = useCallback(
    (filter) => backLogFilterState[filter].value.length !== 0,
    [backLogFilterState],
  );

  const sourceFilter = filters?.source?.map((value) => ({
    label: t(`dynamic.types.${value}`, value),
    value: value,
  }));
  sourceFilter?.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return (
    <FilterMenu onClick={(e) => killEventPropagation(e)}>
      <FilterBtn onClick={(e) => showMenu(e)} active={isActive}>
        <FilterIcon />
        <Text>{t('manage.planning:sort_filter', 'Sort & Filter')}</Text>
        {isActive && <FilterCountText>{filterCounts}</FilterCountText>}
        {!isActive && <StyledImg src={TealChevron} alt="TealChevron" />}
        {isActive && <StyledImg src={WhiteChevron} alt="WhiteChevron" />}
      </FilterBtn>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} zIndex={101}>
        <StyledContent>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:task_source', 'TASK SOURCE')}</Title>}
            expanded={checkIsExpanded(BacklogFilters.SOURCE)}
          >
            <StyledFilterList>
              {sourceFilter?.map((value) =>
                buildCheckboxes(BacklogFilters.SOURCE, value.value, value.label),
              )}
            </StyledFilterList>
          </StyledCollapsiblePanel>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:work_scope', 'TASK WORK SCOPE')}</Title>}
            expanded={checkIsExpanded(BacklogFilters.WORKSCOPE)}
          >
            <StyledFilterList>
              {filters?.workScope?.map((value) =>
                buildCheckboxes(
                  BacklogFilters.WORKSCOPE,
                  value,
                  t(`dynamic.work_scopes.${value}`, value),
                ),
              )}
            </StyledFilterList>
          </StyledCollapsiblePanel>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:task_status', 'TASK STATUS')}</Title>}
            expanded={checkIsExpanded(BacklogFilters.STATUS)}
          >
            <StyledFilterList>
              {filters?.status?.map((value) =>
                buildCheckboxes(BacklogFilters.STATUS, value, value),
              )}
            </StyledFilterList>
          </StyledCollapsiblePanel>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('general:task_priority', 'TASK PRIORITY')}</Title>}
            expanded={checkIsExpanded(BacklogFilters.PRIORITY)}
          >
            <StyledFilterList>
              {filters?.priority?.map((value) =>
                buildCheckboxes(BacklogFilters.PRIORITY, value, value),
              )}
            </StyledFilterList>
          </StyledCollapsiblePanel>
          {approvalFlag && (
            <StyledCollapsiblePanel
              headerContent={<Title level={4}>{t('general:task_flag', 'TASK APPROVED')}</Title>}
              expanded={checkIsExpanded(BacklogFilters.PRIORITY)}
            >
              <StyledFilterList>
                {filters?.flag?.map((value) =>
                  buildCheckboxes(
                    BacklogFilters.FLAG,
                    value,
                    t(`tasks:dynamic.task_flag.${value}`, value),
                  ),
                )}
              </StyledFilterList>
            </StyledCollapsiblePanel>
          )}
          <div className="footer">
            <Button onClick={handleClear}>{t('general:clear', 'Clear')}</Button>
            <Button primary onClick={handleApply}>
              {t('general:apply', 'Apply')}
            </Button>
          </div>
        </StyledContent>
      </Menu>
    </FilterMenu>
  );
};

BacklogFilter.propTypes = {
  filters: PropTypes.instanceOf(Object),
  state: PropTypes.string,
};

BacklogFilter.defaultProps = {
  filters: null,
  state: 'backlog',
};
