import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Select } from '@ge/components/select';
import { useEventMaps, useEventName } from '@ge/shared/data-hooks/use-handling-procedure';
import { globalColors } from '@ge/tokens/colors';

import { EMCheckboxes } from './em-checkboxes';
import { EMdropdowns } from './em-dropdowns';

//@static content
const SHOW_MORE_TEXT_LENGTH = 9;
const DEFAULT_DROPDOWN_VALUE = {
  label: '',
  value: '',
};

const FormContainer = styled.div`
  background: ${globalColors.grey9};
  .container1 {
    padding: 20px 20px 30px 30px;
  }
  .container2 {
    padding: 20px 10px 20px 30px;
  }
  .hidden {
    display: none;
  }
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const LabelWrap = styled.label`
  color: ${globalColors.grey4};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
`;

const InputWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  padding-top: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${globalColors.slate16};
  color: ${(props) => props.theme.distributionListSidePanel.defaultTextLabel};
  > div {
    flex: 1;
  }
  div {
    &:first-of-type {
      margin-right: 2px;
    }
    label {
      font: 500 11px/18px Museo Sans;
    }

    .label-cls {
      color: ${globalColors.grey4};
      font: 500 11px/18px Museo Sans;
    }
    .em-desc {
      max-width: 35vw;
    }
  }
`;

const EditInputWrapper = styled.div`
  .edit-header {
    display: grid;
    grid-template-columns: repeat(3, 230px);
    margin-top: 10px;
    column-gap: 10px;
    padding-bottom: 10px;
  }
`;

const ShowMoreText = styled.div`
  color: #6193a3;
  cursor: pointer;
  margin: 1em 0 0 0;
`;

export const StyledSelect = styled(Select)`
  .select__placeholder {
    font-style: italic;
    /* color: #999999 !important; */
  }
  .select__menu-list {
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 1px;
      background-color: rgba(12, 15, 18, 0.24);
    }
    &::-webkit-scrollbar-thumb {
      height: 100px;
      border: 2px solid transparent;
      background-clip: content-box;
      border-radius: 2.5px;
      background-color: ${(props) => props.theme.customSelect.scrollBar};
    }
  }
`;
export const StyledInput = styled(Input)`
  background-color: transparent;
`;

const ShowMoreIcon = styled(Icon).attrs(() => ({}))`
  transform: ${(props) =>
    props.more ? 'translate(-2px, -2px) rotate(180deg)' : 'translate(-2px, -2px)'};
`;

const SitesWrapper = styled.div`
  max-height: 12em;
  text-transform: capitalize;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;

const ErrorWrapper = styled.div`
  font-size: 1.2em;
  margin: 0.9em 0 1.2em 0;
  color: ${globalColors.red4};
`;

export const CreateEMForm = ({ type, isExist, details, values, setValues, setSelectedEventId }) => {
  const { control, setValue } = useFormContext();
  const { getEventsMaps, getEventCodes } = useEventMaps();
  const [eventMapOptions, setEventMapOptions] = useState([]);
  const { getEventName, eventName = [], isSuccess } = useEventName();
  const { t } = useTranslation(['admin.configure']);
  const event = useWatch({ name: `eventsProp.EventMap` });

  const [showMore, setShowMore] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);
  const { getSiteById } = useStoreState((state) => state.sites);

  useEffect(() => {
    setEventMapOptions(
      getEventsMaps()?.sort((a, b) =>
        a['label'].localeCompare(b['label'], undefined, { numeric: true }),
      ),
    );
  }, [getEventsMaps]);

  useEffect(() => {
    if (details) {
      getEventName(details?.eventId);
    }
  }, [details, getEventName]);

  useEffect(() => {
    if (isSuccess && eventName?.length) {
      const { name, code } = eventName[0];
      setValue(`eventsProp.name`, name);
      setValues({
        name,
        code,
        sourceEventId: code,
      });
    }
  }, [eventName, isSuccess, setValue, setValues]);

  const eventCodeOptions = useCallback(() => {
    const { events } = getEventCodes(event?.value);
    return events
      ?.map((record) => ({
        label: record.sourceEventId,
        value: record.eventId,
      }))
      ?.sort((a, b) => a['label'].localeCompare(b['label'], undefined, { numeric: true }));
  }, [event?.value, getEventCodes]);

  const onEventMapChange = (cb) => (e) => {
    setValue(`eventsProp.EventCode`, DEFAULT_DROPDOWN_VALUE);
    cb(e);
  };

  const onEventCodeChange = (cb) => (e) => {
    getEventName(e.value);
    setSelectedEventId(e.value);
    cb(e);
  };

  const showMoreText = () => {
    let text =
      selectedSites?.length > SHOW_MORE_TEXT_LENGTH ? (!showMore ? 'show more' : 'show less') : '';

    if (text) {
      return (
        <ShowMoreText onClick={() => setShowMore(!showMore)}>
          <ShowMoreIcon more={showMore} size={12} icon={Icons.CHEVRON} color={'#6193a3'} />
          {text}
        </ShowMoreText>
      );
    } else {
      return text;
    }
  };
  return (
    <FormContainer>
      <div className={'container1'}>
        {isExist ? (
          <ErrorWrapper>
            {t('configure_already_exist', 'This configuration already exists.')}
          </ErrorWrapper>
        ) : (
          ''
        )}
        <StyledLabel>{t('Event_managment.event_details', 'EVENT DETAILS')}</StyledLabel>
        {type === 'Edit' ? (
          <EditInputWrapper>
            <div className="edit-header">
              <LabelWrap>{t('Event_managment.event_map', 'Event Map')}</LabelWrap>
              <LabelWrap>{t('Event_managment.event_code', 'Event Code')}</LabelWrap>
              <LabelWrap>{t('Event_managment.event_name', 'Event Name')}</LabelWrap>
            </div>
            <div className="edit-header">
              <div>{details?.eventMapId}</div>
              <div>{values?.code}</div>
              <div>{values?.name}</div>
            </div>
          </EditInputWrapper>
        ) : (
          <InputWrapper>
            <Controller
              name={`eventsProp.EventMap`}
              defaultValue={DEFAULT_DROPDOWN_VALUE}
              render={({ onChange, value }) => (
                <StyledSelect
                  placeholder={t('Event_managment.select', 'Select')}
                  options={eventMapOptions}
                  onChange={onEventMapChange(onChange)}
                  value={value.label ? value : undefined}
                  label={t('Event_managment.event_map', 'Event Map')}
                  width={100}
                  id="em_select"
                />
              )}
            />
            <Controller
              name={`eventsProp.EventCode`}
              defaultValue={DEFAULT_DROPDOWN_VALUE}
              render={({ onChange, value }) => (
                <StyledSelect
                  placeholder={t('Event_managment.select', 'Select')}
                  disabled={!event?.value}
                  label={t('Event_managment.event_code', 'Event Code')}
                  onChange={onEventCodeChange(onChange)}
                  options={event?.value ? eventCodeOptions() : []}
                  value={value.label ? value : undefined}
                  width={100}
                  id="em_select"
                />
              )}
            />
            <Controller
              name={`eventsProp.name`}
              defaultValue={''}
              render={({ value }) => (
                <StyledInput
                  label={t('Event_managment.event_name', 'Event Name')}
                  disabled
                  type="text"
                  value={value}
                  placeholder={t('Event_managment.select', 'Select')}
                />
              )}
            />
          </InputWrapper>
        )}

        <InputWrapper>
          <Controller
            control={control}
            name="desc"
            defaultValue={''}
            render={({ onChange, onBlur, value }) => (
              <Input
                label={t('Event_managment.optional_description', 'Description (optional)')}
                onChange={onChange}
                placeholder={t('Event_managment.Short_description', 'Add a short description')}
                onBlur={onBlur}
                type="text"
                minLength="400"
                maxLength="500"
                value={value}
                className="em-desc"
              />
            )}
          />
        </InputWrapper>

        <InputWrapper>
          <div>
            <p className="label-cls">
              {t('Event_managment.Assigned_handling_procedure', 'Assigned Handling Procedure(s)')}
            </p>
            <p>
              {details?.procProperties?.procId} - {details?.procProperties?.procTitle}
            </p>
          </div>
        </InputWrapper>

        <StyledLabel>{t('Event_managment.Affected_sites', 'AFFECTED SITES')}:</StyledLabel>
        <EMdropdowns details={details} type={type} setSelectedSites={setSelectedSites} />
        <InputWrapper style={{ display: 'block' }}>
          <SitesWrapper style={{ width: '100%', font: '300 12px/15px Museo Sans' }}>
            {selectedSites.map((site, index) => {
              if (showMore || (!showMore && index < SHOW_MORE_TEXT_LENGTH)) {
                return (
                  <div
                    style={{
                      float: 'left',
                      width: '32%',
                      textAlign: 'left',
                      paddingBottom: '5px',
                      color: `${globalColors.grey13}`,
                    }}
                    key={index}
                  >
                    {getSiteById(site)?.name}
                  </div>
                );
              }
            })}
          </SitesWrapper>
          <div>{showMoreText()}</div>
        </InputWrapper>
        <StyledLabel>{t('Event_managment.settings', 'SETTINGS')}</StyledLabel>
        <EMCheckboxes />
      </div>
    </FormContainer>
  );
};

CreateEMForm.propTypes = {
  type: PropTypes.string,
  isExist: PropTypes.bool,
  values: PropTypes.object,
  details: PropTypes.object,
  setValues: PropTypes.func,
  procDetails: PropTypes.object,
  setSelectedEventId: PropTypes.func,
};
