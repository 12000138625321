import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  QueryKey,
  MonitorDefs,
  InverterType,
  Capability,
  PermissionScope,
} from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { getActiveSIsForAllSites } from '@ge/shared/services/notes';
import { sorter } from '@ge/util/metric-sorter';

import { SitesColumns } from '../models/sites-table-cols';
import { fetchSitesRealTimeData } from '../services';

// TODO: pull this from config, ideally something that can be updated without a redeploy
const REAL_TIME_DATA_REFRESH = 30000;

const makeModelTransformer = (makeModel) => {
  let uniqueValues = [...new Set(makeModel)];
  if (uniqueValues.length == 1) {
    return uniqueValues[0];
  } else if (uniqueValues.length > 1) {
    return 'table.multiple';
  } else {
    return '-';
  }
};

export const useSitesTable = ({
  isActive = true,
  sortDirection,
  sortMetric,
  search,
  filters,
  filterSequence,
  siteType,
}) => {
  const { t } = useTranslation(['monitor.sites']);
  const { isAuthorized } = useAuth();
  const isAuthorizedToViewSIs = isAuthorized({
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] }],
  });

  // store
  let sitesForView = useStoreState((state) => state.sites.sitesForView);
  const siteTypesToShow = useStoreState((state) => state.sites.siteTypesToShow);
  // can revist this but we're using this timestamp to verify that full site data load has happened
  const sitesLastUpdated = useStoreState((state) => state.sites.sitesLastUpdated);

  // Trigger the call when the sites are changed in view selector
  // right now when view selector updates, it fires and forgets a call to persist in db
  // this means that the real-time data won't update immediately but on next interval (presumably)
  // after in-memory and persisted views are in sync
  // this can be fixed by awaiting the save view prefs call
  // but that will cause some lag updating view in the ui while waiting on the save call to return
  // so might need to put some more thought into this
  // another option is to not access view from db in the bff but pass along in body of request from ui
  // we are already doing something similar for analyze global filters
  const {
    data: realTimeData,
    error,
    isLoading,
  } = useQuery(
    [QueryKey.SITES_AGGREGATED_DATA, sitesForView, siteType],
    async () => {
      return await fetchSitesRealTimeData(siteType);
    },
    {
      // can look into not waiting on sites to enable this
      // but it looked like it was making extra calls which could be bad
      enabled: isActive && Boolean(sitesLastUpdated),
      refetchInterval: REAL_TIME_DATA_REFRESH,
      // setting this to false to avoid unwanted refetch
      // https://github.com/tannerlinsley/react-query/discussions/1118#discussioncomment-90017
      refetchOnWindowFocus: false,
    },
  );

  // TODO this needs to redefined once scope selector changes are done
  switch (siteType) {
    case MonitorDefs.WIND_SITES:
      sitesForView = sitesForView?.filter((site) => site?.inverters?.instanceCount === 0);
      break;
    case MonitorDefs.STORAGE_SITES:
      sitesForView = sitesForView?.filter((site) =>
        site?.inverters?.types?.find((ent) => ent.type === InverterType.STORAGE) ? true : false,
      );
      break;
    case MonitorDefs.SOLAR_SITES:
      sitesForView = sitesForView?.filter((site) =>
        site?.inverters?.types?.find((ent) => ent.type === InverterType.SOLAR) ? true : false,
      );
      break;
    case MonitorDefs.HYBRID_SITES:
      sitesForView = sitesForView?.filter((site) =>
        site?.inverters?.types?.find((ent) => ent.type === InverterType.HYBRID) ? true : false,
      );
      break;
    default:
  }
  // Dependent Query for specialInstructions, using isLoading from previous query for enabled flag
  const {
    data: SIs,
    error: siError,
    isLoading: isSIsLoading,
  } = useQuery([QueryKey.ACTIVE_SPECIAL_INSTRUCTIONS, 'sites'], getActiveSIsForAllSites, {
    refetchOnWindowFocus: false,
    refetchInterval: Config.REFRESH.realTime,
    enabled: !isLoading && isAuthorizedToViewSIs,
  });

  const data = useMemo(() => {
    if (!sitesLastUpdated) {
      return undefined;
    }

    return sitesForView.map((_site) => {
      let site = { ..._site };
      const { id, turbineTypes, inverters } = site;

      const realTimeSiteData = realTimeData?.find(({ id: siteId }) => siteId === id);

      if (realTimeSiteData) {
        if (SIs && SIs[id]) {
          if (realTimeSiteData.conditions) {
            realTimeSiteData.conditions.specialInstructions = SIs[id] ?? [];
          } else {
            realTimeSiteData.conditions = { specialInstructions: SIs[id] ?? [] };
          }
        }
        site = { ...site, ...realTimeSiteData };
      }

      // TODO: this needs to be handled for different inverter types
      let makeModels;
      if (siteType === MonitorDefs.WIND_SITES) {
        makeModels = (turbineTypes ?? []).reduce(
          (ac, c) => {
            ac.count = ac.count + c.modelCount;
            ac.makes.push(c.make);
            ac.models.push(c.model);
            return ac;
          },
          {
            count: 0,
            makes: [],
            models: [],
          },
        );
      } else {
        makeModels = (
          inverters?.types?.filter((el) => el.type.toLowerCase() === siteType.toLowerCase()) ?? []
        ).reduce(
          (ac, c, currentIndex) => {
            ac.count = ac.count + c.typeCount;
            ac.makes.push(inverters?.makeModels?.[currentIndex]?.make);
            ac.models.push(inverters?.makeModels?.[currentIndex]?.model);
            return ac;
          },
          {
            count: 0,
            makes: [],
            models: [],
          },
        );
      }

      const make = makeModelTransformer(makeModels.makes),
        model = makeModelTransformer(makeModels.models);
      const _siteData = site ?? {};
      return {
        [SitesColumns.SITE]: _siteData.name,
        [SitesColumns.CUSTOMER]: _siteData.customer?.name,
        [SitesColumns.COUNTRY]: _siteData.country,
        [SitesColumns.ASSET_MAKE]: t(make, make),
        [SitesColumns.ASSET_MODEL]: t(model, model),
        site: {
          ...site,
          // Foundational apis will not return data for all sites. So counting the total assets here.
          assets: {
            ...site.assets,
            total: makeModels.count,
          },
          // This can be in the sites-table as well, but will be re-calculated when the user scrolls.
          make: t(make, make),
          model: t(model, model),
        },
        siteId: site?.id,
      };
    });
  }, [realTimeData, sitesForView, sitesLastUpdated, SIs, t, siteType]);

  const { data: filteredData, filterValues } = useTableFilter({
    data,
    filters,
    filterSequence,
    search,
    staticData: sitesForView,
  });

  // cuts a new ref when sorting to update graph accordingly
  const sortedData = useMemo(
    () => (filteredData?.length ? [...filteredData.sort(sorter(sortMetric, sortDirection))] : []),
    [filteredData, sortDirection, sortMetric],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    siError,
    isSIsLoading,
    filterValues,
    siteTypesToShow,
  };
};
