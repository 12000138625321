import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { BoldSearch } from '@ge/components/bold-search';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import useSiteSearch from '@ge/feat-analyze/data-hooks/use-site-search';
import { elevations, globalColors } from '@ge/tokens';

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.navigation.linkColor};
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
  &.active {
    font-weight: 300;
    text-decoration: none;
    background-color: ${(props) => props.theme.navigation.linkActiveBorderColor};
    color: ${(props) => props.theme.navigation.linkActiveColor};
    svg {
      &.magnifying-glass,
      &.chevron {
        fill: ${(props) => props.theme.navigation.linkActiveColor};
      }
    }
  }
`;

const SelectorLabel = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 13px;
  .chevron {
    transform: rotate(270deg);
  }
  svg {
    fill: ${(props) => props.theme.navigation.iconColor};
    margin-right: 8px;
  }
`;

const SelectorPanel = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  background: ${({ theme }) => theme.themeSelector.tabsBackgroundColor};
  color: ${globalColors.grey1};
  z-index: ${elevations.P2};
  transition: false;
  box-sizing: border-box;
  &.active {
    display: flex;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFilter = styled(Filter)`
  text-align: center;
  width: 80%;
  position: relative;
  input {
    color: ${globalColors.white};
    box-sizing: border-box;
    height: 28px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.input.borderNavbarInput};
    border-radius: 2px;
    background-color: ${({ theme }) => theme.themeSelector.tabsBackgroundColor};
    caret-color: ${globalColors.teal3};
    ::placeholder {
      font-size: 14px;
      font-style: italic;
    }
    :focus {
      outline: none;
    }
  }
  .search-icon {
    position: absolute;
    top: 7px;
    right: 5px;
  }
`;

const ResultsContainer = styled.div`
  display: none;
  position: absolute;
  top: 30px;
  width: 80%;
  background: ${({ theme }) => theme.themeSelector.tabsBackgroundColor};
  &.show-results,
  &:hover,
  &:focus,
  &:focus-within {
    display: block;
  }
`;

const ResultsHeader = styled.div`
  color: ${globalColors.grey4};
  font-weight: 700;
  margin: 8px;
  &:focus {
    outline: none;
  }
`;

const Results = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  max-height: 200px;
`;

const SiteIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.SITE,
  color: globalColors.slate4,
}))`
  margin-right: 12px;
`;

const SiteName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledLi = styled.li`
  border-bottom: 1px solid ${globalColors.slate12};
  max-height: 30px;
  color: ${globalColors.grey7};
  padding: 6px 8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  &:hover,
  &:focus {
    background-color: ${globalColors.teal4};
    border-bottom: 1px solid ${globalColors.teal7};
  }
  &:first-child {
    border-top: 1px solid ${globalColors.slate12};
  }
`;

const StyledUl = styled.ul`
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
`;

export const ViewSelectorSiteComponent = ({ className, nextLinkRef }) => {
  const [filterText, setFilterText] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const parentRef = useRef();

  const { resetDateRange } = useStoreActions(({ analyze }) => analyze);

  const { t } = useTranslation(['nav', 'general']);
  const { push } = useHistory();
  const routeToSite = (id) => {
    resetDateRange();
    setFilterText('');
    setShowResults(false);
    push(`/analyze/site/${id}`);
  };

  const { sites } = useSiteSearch(filterText);

  const onEscape = () => {
    // this will focus on the next L2 link
    // which will close the sites list
    // but will keep the main nav menu open
    nextLinkRef.firstElementChild.focus();
  };

  /*
    onMouseDown vs onClick fires before parent onBlur event
    Otherwise onBlur fires first and the onClick event
    is removed with the hidden element (the nav site list)
  */
  const siteItemFn = ({ id, name }) => (
    <StyledLi
      key={id}
      onClick={() => {
        routeToSite(id);
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          routeToSite(id);
        }
        if (e.key === 'Escape') {
          setShowResults(false);
          onEscape();
        }
      }}
      tabIndex="0"
    >
      <SiteIcon className="wind-icon" />
      <SiteName>
        <BoldSearch text={name} textBold={filterText} />
      </SiteName>
    </StyledLi>
  );

  return (
    <SelectorContainer
      className={isActive ? `${className} active` : className}
      tabIndex="0"
      onFocus={() => setIsActive(true)}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) isActive && setIsActive(false);
      }}
    >
      <SelectorLabel>
        <Icon
          size={14}
          icon={Icons.SEARCH}
          color={globalColors.white}
          className="magnifying-glass"
        />
        {t('view_selector_site.site_search', 'Site Search')}
        <Icon size={12} icon={Icons.CHEVRON} color={globalColors.white} className="chevron" />
      </SelectorLabel>
      <SelectorPanel className={isActive ? 'active' : ''}>
        <Header>
          <StyledFilter
            text={filterText}
            onChange={(event) => setFilterText(event.target.value)}
            placeholder={t('view_selector_site.site_search', 'Site Search')}
            // setting timeouts so tabbing can happen before the state changes
            showResults={() => {
              setTimeout(() => {
                setShowResults(true);
              }, 100);
            }}
            onInputBlur={() => {
              setTimeout(() => {
                setShowResults(false);
              }, 100);
            }}
            onInputEscaped={() => onEscape()}
          />
        </Header>
        <ResultsContainer className={showResults && 'show-results'}>
          <ResultsHeader
            tabIndex="0"
            onKeyUp={(e) => {
              if (e.key === 'Escape') {
                setShowResults(false);
                onEscape();
              }
            }}
          >
            {t('view_selector_site.select_site_to_view', 'SELECT SITE TO VIEW')}
          </ResultsHeader>

          <Results>
            {sites.length && (
              <ScrollingContainer ref={parentRef} relative>
                <StyledUl noPadding onClick={() => isActive && setIsActive(false)}>
                  {sites.map(({ id, name }) => siteItemFn({ id, name }))}
                </StyledUl>
              </ScrollingContainer>
            )}
          </Results>
        </ResultsContainer>
      </SelectorPanel>
    </SelectorContainer>
  );
};

ViewSelectorSiteComponent.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  nextLinkRef: PropTypes.instanceOf(Element),
};

ViewSelectorSiteComponent.defaultProps = {
  className: null,
};

const StyledViewSelectorSite = styled(ViewSelectorSiteComponent)`
  display: flex;
`;

export const ViewSelectorSite = withTheme(StyledViewSelectorSite);
