import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { Controller, useFormContext, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Input } from '@ge/components/input/input';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Select } from '@ge/components/select';
import {
  CaseStatus,
  DispositionOptionValues,
  DispositionOptions,
  RootCauseOptionValues,
} from '@ge/models/constants';
import { useUpdateCases } from '@ge/shared/data-hooks/use-update-cases';

const FormField = {
  DISPOSITION: 'disposition',
  ROOT_CAUSE: 'rootCause',
  ROOT_CAUSE_TEXT: 'rootCauseText',
};

const DialogWrapper = styled.div`
  position: relative;
  padding: 10px 25px 15px;
  * {
    box-sizing: border-box;
  }
`;

const StyledLoader = styled.div`
  position: relative;
  min-height: 80px;
  padding: 17px 25px;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.entityDetails.notes.separator};
  color: 1px solid ${({ theme }) => theme.caseCloseDialog.titleColor};
  font-size: 14px;
  padding: 17px 25px;
`;

const StyledBadge = styled(Badge)`
  margin-left: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  button:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const StyledSelect = styled.div`
  padding: 10px 0px;
  .error {
    color: ${({ theme }) => theme.fileUpload.errorTextColor};
  }
  label {
    color: ${({ theme }) => theme.input.labelTextColor};
    font-size: 11px;
    font-weight: 300;
  }
`;

const StyledInputRow = styled.div`
  padding-top: 10px;
  input {
    width: 100%;
  }
`;

const StyledError = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.errorColor};
  font-size: 10px;
  margin: 5px 0;
`;

const CustomSelect = ({ name, label, placeholder, defaultValue, options, metadata }) => {
  const { control, errors = {}, setValue, trigger } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
      trigger(name);
    }
  }, [name, defaultValue, setValue, trigger]);

  return (
    <Controller
      name={name}
      label={label}
      control={control}
      rules={{ required: metadata.required }}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <Select
          primary
          name={name}
          label={label}
          value={options.find((val) => val.value === value)}
          onChange={(val) => onChange(val.value)}
          options={options}
          placeholder={placeholder}
          error={errors[name]?.message ?? ''}
        />
      )}
    />
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
};

const CustomInput = ({ name, label, placeholder, defaultValue, metadata }) => {
  const { control, errors = {}, trigger } = useFormContext();

  useEffect(() => {
    if (defaultValue) trigger(name);
  }, [defaultValue, name, trigger]);

  return (
    <StyledInputRow>
      <Controller
        name={name}
        control={control}
        rules={{
          required: metadata.required,
        }}
        defaultValue={defaultValue ?? ''}
        render={({ onChange, value }) => (
          <Input
            type="text"
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            error={errors[name]?.message ?? ''}
          />
        )}
      />
    </StyledInputRow>
  );
};

CustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

export const CaseCloseDialog = React.memo(
  ({ isOpen, caseIds: selectedCaseIds, onClose, onConfirm }) => {
    const { t, ready } = useTranslation(['monitor.issues'], { useSuspense: false });

    const [error, setError] = useState(null);

    const theme = useContext(ThemeContext);

    const methods = useForm({
      mode: 'onChange',
      defaultValues: {
        [FormField.DISPOSITION]: '',
        [FormField.ROOT_CAUSE]: '',
      },
    });

    const { formState, watch, handleSubmit, reset } = methods;

    const watchDisposition = watch(FormField.DISPOSITION);
    const watchRootCause = watch(FormField.ROOT_CAUSE);

    const dispositions = useMemo(
      () => DispositionOptions.filter(({ value }) => value !== DispositionOptionValues.CORRECTED),
      [],
    );

    const { hasRootCause, rootCause, rootCauses } = useMemo(() => {
      if (!watchDisposition) return { hasRootCause: true, rootCauses: [] };

      if (
        watchDisposition === DispositionOptionValues.RESOLVED ||
        watchDisposition === DispositionOptionValues.INCORRECT
      ) {
        return { hasRootCause: false };
      }

      const rootCauses = [
        { label: RootCauseOptionValues.OTHER, value: RootCauseOptionValues.OTHER },
      ];
      const rootCause = rootCauses[0];

      return {
        rootCauses,
        rootCause,
        hasRootCause: true,
      };
    }, [watchDisposition]);

    const { mutate: close, isLoading } = useUpdateCases();

    const onCancelCloseCase = useCallback(() => {
      setError(null);
      onClose(false);
    }, [onClose]);

    const onConfirmCloseCase = useCallback(
      ({ disposition, rootCause, rootCauseText }) => {
        if (!selectedCaseIds?.length) return;
        const body = {
          caseIds: selectedCaseIds,
          disposition,
          rootCause: rootCause !== RootCauseOptionValues.OTHER ? rootCause : rootCauseText,
          status: CaseStatus.CLOSED,
        };

        setError(null);

        close(body, {
          onError: (e) => {
            setError(e.message);
          },
          onSuccess: (res) => {
            reset();
            onConfirm(res);
          },
        });
      },
      [selectedCaseIds, close, onConfirm, reset],
    );

    useEffect(() => {
      setError(null);
    }, [watchDisposition, watchRootCause]);

    if (!ready || !theme) {
      return null;
    }

    return (
      <Dialog
        isOpen={isOpen}
        contentWidth={false}
        padContent={false}
        onClose={onCancelCloseCase}
        header={
          <>
            {t('case_close_dialog.header', 'Close Cases')}
            {
              <StyledBadge
                label={selectedCaseIds.length}
                color={theme.caseCloseDialog.badgeColor}
              />
            }
          </>
        }
        footer={
          <ButtonGroup>
            <Button onClick={onCancelCloseCase}>{t('case_close_dialog.cancel', 'Cancel')}</Button>
            <Button
              primary
              onClick={handleSubmit(onConfirmCloseCase)}
              disabled={!formState.isDirty || !formState.isValid}
            >
              {t('case_close_dialog.confirm', 'Close')}
            </Button>
          </ButtonGroup>
        }
      >
        <StyledHeader>{t('case_close_dialog.title', 'Case Resolution')}</StyledHeader>
        <DialogWrapper>
          <FormProvider {...methods}>
            {isLoading ? (
              <StyledLoader>
                <Loader />
              </StyledLoader>
            ) : (
              <>
                <StyledSelect>
                  <CustomSelect
                    primary
                    name={FormField.DISPOSITION}
                    label={t('case_close_dialog.disposition_label', 'Disposition')}
                    placeholder={t(
                      'case_close_dialog.disposition_select',
                      'Please select a disposition',
                    )}
                    options={dispositions}
                    metadata={{
                      required: t('case_close_dialog.select_error', 'Please make a selection'),
                    }}
                  />
                </StyledSelect>
                <ConditionalRender shouldRender={hasRootCause}>
                  <StyledSelect>
                    <CustomSelect
                      primary
                      name={FormField.ROOT_CAUSE}
                      label={t('case_close_dialog.root_cause_label', 'Root Cause')}
                      placeholder={t(
                        'case_close_dialog.root_cause_select',
                        'Please select a root cause',
                      )}
                      defaultValue={rootCause?.value ?? ''}
                      options={rootCauses}
                      metadata={{
                        required: t('case_close_dialog.select_error', 'Please make a selection'),
                      }}
                    />
                    <ConditionalRender
                      shouldRender={watchRootCause === RootCauseOptionValues.OTHER}
                    >
                      <CustomInput
                        name={FormField.ROOT_CAUSE_TEXT}
                        placeholder={t(
                          'case_close_dialog.root_cause_text',
                          'Please provide the root cause',
                        )}
                        metadata={{
                          required: t(
                            'case_close_dialog.input_error',
                            'Please enter a valid root cause',
                          ),
                        }}
                      />
                    </ConditionalRender>
                  </StyledSelect>
                </ConditionalRender>
                <ConditionalRender shouldRender={Boolean(error)}>
                  <StyledError>{error}</StyledError>
                </ConditionalRender>
              </>
            )}
          </FormProvider>
        </DialogWrapper>
      </Dialog>
    );
  },
);

CaseCloseDialog.propTypes = {
  isOpen: PropTypes.bool,
  caseIds: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

CaseCloseDialog.defaultProps = {
  isOpen: false,
  caseIds: false,
  onClose: () => null,
  onConfirm: () => null,
};

CaseCloseDialog.displayName = 'CaseCloseDialog';
