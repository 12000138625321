import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';

import { typography } from '@ge/tokens/typography';

import { Base } from './base';

export const Text = (props) => {
  const { component, type, level, className, ...restProps } = props;
  const getClassName = classNames({ [`${type}-${level}`]: type, [className]: className });
  return <Base {...restProps} component={component} className={getClassName} />;
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(typography.textTypes)),
  level: PropTypes.number,
  component: PropTypes.oneOf(Object.keys(typography.elementTypes)),
  className: PropTypes.string,
};

Text.defaultProps = {
  component: typography.elementTypes.span,
  type: typography.textTypes.body,
  level: 1,
  className: null,
};
