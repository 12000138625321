import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';

import { TenantContext } from './tenant-context';

export const TenantProvider = ({ children }) => {
  const setSelectedTenant = (tenant) => {
    setTenantState((prevState) => ({
      ...prevState,
      selectedTenant: tenant,
    }));
  };

  const clearSelectedTenant = () => {
    setTenantState((prevState) => ({
      ...prevState,
      selectedTenant: null,
    }));
  };

  const defaultState = {
    selectedTenant: null,
    clearSelectedTenant,
    setSelectedTenant,
  };

  const [tenantState, setTenantState] = useState(defaultState);

  const providerValue = useMemo(
    () => ({ tenantState, setTenantState }),
    [tenantState, setTenantState],
  );

  return <TenantContext.Provider value={providerValue}>{children}</TenantContext.Provider>;
};

TenantProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

TenantProvider.defaultProps = {
  children: null,
};
