import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormMode } from '@ge/models/constants';

import {
  AlertCollapsiblePanel,
  FormCol,
  FormRow,
  FormSchema,
  parseViewModeValue,
} from '../alert-shared';

import { AttachmentsField } from './attachments-field';
import { MailRecipientField } from './mail-recipient-field';

const PanelHeader = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  width: 100%;

  > * {
    flex: 1 1;

    + * {
      margin-left: auto;
      text-transform: none;
      white-space: nowrap;
    }
  }
  .label {
    color: ${(props) => props.theme.escalationDialog.textColor};
  }
`;

const ToContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  input {
    width: -webkit-fill-available;
  }

  .to-label {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 5px;
    color: ${(props) => props.theme.input.labelTextColor};

    .actions {
      margin-left: auto;
      text-align: right;

      button {
        padding: 0px;
        color: ${(props) => props.theme.input.labelTextColor};
        + button {
          margin-left: 5px;
        }
      }
    }
  }
`;

const ViewContainer = styled.div`
  white-space: unset;
  margin-top: 5px;
  .view-mode-value {
    font-size: 12px;
  }
  .sent-by-style {
    padding-top: 5px;
  }
`;

export const SendNotificationField = ({
  lastSentDate,
  lastSentBy,
  mode,
  name,
  isHeaderHidden,
  multipleRecipientFields,
  showLastSent,
  actionableLabel,
  headerLabel,
  maxAttachments,
  isEscalateCase,
  maxAttachmentSizeMb,
  isAttachmentRequired,
  files,
  handleUpload,
  emailType,
  ...metadata
}) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });

  //when we have multiple recipient-fields fields
  const handleInputLabel = useCallback(
    (label) => {
      return multipleRecipientFields ? `${name}_${label}` : label;
    },
    [name, multipleRecipientFields],
  );

  const { control } = useFormContext();
  const bccValue = useWatch({ control, name: handleInputLabel(FormSchema.BCC) });
  const ccValue = useWatch({ control, name: handleInputLabel(FormSchema.CC) });

  // state
  const [isBccVisible, setIsBccVisible] = useState(false);
  const [isCcVisible, setIsCcVisible] = useState(false);
  const [resizeToggle, setResizeToggle] = useState();

  useEffect(() => {
    bccValue && setIsBccVisible(true);
  }, [bccValue]);

  useEffect(() => {
    ccValue && setIsCcVisible(true);
  }, [ccValue]);

  // don't like having these for manually forcing collapsize panel resize
  // should look into having this work implicitly
  useEffect(() => {
    setResizeToggle((prev) => !prev);
  }, [isBccVisible, isCcVisible]);

  const handleToggleAttachmentsExpand = useCallback(() => {
    setResizeToggle((prev) => !prev);
  }, []);

  if (!(ready && metadata) || metadata.hidden) {
    return null;
  }

  const { collapsed } = metadata;
  const translations = {
    bccLabel: t('form.send_notification.bcc.label', 'BCC'),
    bccPlaceholderLabel: t('form.send_notification.bcc.placeholder_label', 'Add recipients;'),
    ccLabel: t('form.send_notification.cc.label', 'CC'),
    ccPlaceholderLabel: t('form.send_notification.cc.placeholder_label', 'Add recipients;'),
    headerLabel: t('form.send_notification.label', 'Send Notification'),
    lastMessageSentOnLabel: t(
      'form.send_notification.last_message_sent_on_label',
      'Last Message Sent On:',
    ),
    actionableLastMessageSentOnLabel: t(
      'form.send_notification.actionable_last_message_sent_on_label',
      'Last ACTIONABLE message sent on:',
    ),
    lastMessageSentByLabel: t('form.send_notification.last_message_sent_by_label', 'Sent by:'),
    messagePreviewLabel: t('form.send_notification.message_preview_label', 'Message Preview'),
    noEmailSentLabel: t('form.send_notification.no_email_sent_label', 'No actionable email sent.'),
    subjectLabel: t('form.send_notification.subject.label', 'Subject'),
    subjectPlaceholderLabel: t('form.send_notification.subject.placeholder_label', 'Add a subject'),
    toLabel: t('form.send_notification.to.label', 'To'),
    toPlaceholderLabel: t('form.send_notification.to.placeholder_label', 'Add recipients;'),
  };
  const lastSentDateLabel = lastSentDate ? parseViewModeValue(lastSentDate) : undefined;

  const lastSentHeader = lastSentDateLabel ? (
    <ViewContainer>
      <span className="label">
        {actionableLabel
          ? translations.actionableLastMessageSentOnLabel
          : translations.lastMessageSentOnLabel}
      </span>{' '}
      <span className="view-mode-value">{lastSentDateLabel}</span>{' '}
      <div className="sent-by-style">
        <span className="label">{translations.lastMessageSentByLabel}</span>{' '}
        <span className="view-mode-value">{lastSentBy}</span>
      </div>
    </ViewContainer>
  ) : (
    <div className="label">{translations.noEmailSentLabel}</div>
  );

  if (mode === FormMode.VIEW) {
    return showLastSent ? lastSentHeader : '';
  }

  const panelHeader = lastSentDateLabel ? (
    <PanelHeader>
      <h4>{headerLabel}</h4>
      {lastSentHeader}
    </PanelHeader>
  ) : (
    headerLabel
  );

  const notificationForm = () => {
    return (
      <>
        {/* Hiding subject field to design changes */}
        {/* <FormRow>
          <FormCol span="12">
            <SubjectContainer>
              <div className="subject-label">
                <div className="label">{translations.subjectLabel}</div>
                <div className="actions">
                  {!isCcVisible && (
                    <button className="label" onClick={() => setIsCcVisible(true)} type="button">
                      {translations.ccLabel}
                    </button>
                  )}
                  {!isBccVisible && (
                    <button className="label" onClick={() => setIsBccVisible(true)} type="button">
                      {translations.bccLabel}
                    </button>
                  )}
                </div>
              </div>
              <TextMetaField
                maxLength={metadata.maxLength}
                metadata={metadata}
                name={handleInputLabel(FormSchema.SUBJECT)}
                placeholder={translations.subjectPlaceholderLabel}
                tabIndex={1}
              />
            </SubjectContainer>
          </FormCol>
        </FormRow> */}

        <FormRow>
          <FormCol span="12">
            <ToContainer>
              <div className="to-label">
                <div className="label">{translations.toLabel}</div>
                <div className="actions">
                  {!isCcVisible && (
                    <button className="label" onClick={() => setIsCcVisible(true)} type="button">
                      {translations.ccLabel}
                    </button>
                  )}
                  {!isBccVisible && (
                    <button className="label" onClick={() => setIsBccVisible(true)} type="button">
                      {translations.bccLabel}
                    </button>
                  )}
                </div>
              </div>
              <MailRecipientField
                name={handleInputLabel(FormSchema.TO)}
                placeholderLabel={translations.toPlaceholderLabel}
                tabIndex={1}
              />
            </ToContainer>
          </FormCol>
        </FormRow>
        <FormRow className={isCcVisible ? undefined : 'hidden'}>
          <FormCol span="12">
            <MailRecipientField
              label={translations.ccLabel}
              name={handleInputLabel(FormSchema.CC)}
              placeholderLabel={translations.ccPlaceholderLabel}
            />
          </FormCol>
        </FormRow>
        <FormRow className={isBccVisible ? undefined : 'hidden'}>
          <FormCol span="12">
            <MailRecipientField
              label={translations.bccLabel}
              name={handleInputLabel(FormSchema.BCC)}
              placeholderLabel={translations.bccPlaceholderLabel}
            />
          </FormCol>
        </FormRow>
        {isAttachmentRequired && (
          <AttachmentsField
            collapsed={collapsed}
            hidden={false}
            mode={mode}
            maxAttachments={maxAttachments}
            isEscalateCase={isEscalateCase}
            maxAttachmentSizeMb={maxAttachmentSizeMb}
            onToggleExpand={handleToggleAttachmentsExpand}
            files={files}
            handleUpload={handleUpload}
            emailType={emailType}
          />
        )}
      </>
    );
  };

  return (
    <AlertCollapsiblePanel
      expanded={!collapsed}
      header={panelHeader}
      mode={mode}
      renderToggle={resizeToggle}
      plusIcon={!isHeaderHidden}
    >
      {notificationForm()}
    </AlertCollapsiblePanel>
  );
};

SendNotificationField.propTypes = {
  collapsed: PropTypes.bool,
  hidden: PropTypes.bool,
  lastSentDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  lastSentBy: PropTypes.string,
  maxLength: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  name: PropTypes.string,
  headerLabel: PropTypes.node,
  isHeaderHidden: PropTypes.bool,
  multipleRecipientFields: PropTypes.bool,
  showLastSent: PropTypes.bool,
  actionableLabel: PropTypes.bool,
  maxAttachments: PropTypes.number,
  isEscalateCase: PropTypes.bool,
  maxAttachmentSizeMb: PropTypes.number,
  isAttachmentRequired: PropTypes.bool,
  files: PropTypes.array,
  handleUpload: PropTypes.func,
  emailType: PropTypes.string,
};

SendNotificationField.defaultProps = {
  collapsed: true,
  hidden: false,
  lastSentDate: undefined,
  lastSentBy: undefined,
  maxLength: 1000,
  mode: FormMode.VIEW,
  name: 'description',
  headerLabel: null,
  isHeaderHidden: false,
  multipleRecipientFields: false,
  showLastSent: true,
  actionableLabel: false,
  maxAttachments: 5,
  isEscalateCase: false,
  maxAttachmentSizeMb: 5,
  isAttachmentRequired: false,
  files: [],
  handleUpload: () => {},
  emailType: '',
};
