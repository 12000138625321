import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Loader } from '@ge/components/loader';
import { Menu } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { TableArrow } from '@ge/components/table';
import { DateTimeFormats, Placeholders } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';

import { EntityDetailsContext } from '../context/entity-details-context';

import { useCaseLinkedTasks } from './use-linked-task-menu';

const StyledBadge = styled(Badge)`
  color: ${(props) => props.theme.taskStatusBadge.textColor.default};
  background-color: ${(props) => props.theme.taskStatusBadge.backgroundColor.default};
  min-width: 18px;
  margin-left: 8px;
`;

const StyledTitle = styled.div`
  background: ${({ theme }) => theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${({ theme }) => theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  min-width: 367px;
  max-height: 240px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
  ::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }
`;

const TaskRow = styled.div`
  border-top: 1px solid ${globalColors.grey9};
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  &:first-of-type {
    border: none;
  }
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const TaskTitle = styled.div`
  flex-grow: 1;
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TaskSource = styled.div`
  display: flex;
  white-space: nowrap;
  margin-right: 10px;
  > div:not(:first-child) {
    &:before {
      content: '|';
      padding: 0px 3px;
    }
  }
`;

// TODO(#994): Revist and remove font styles when we update missing Typograhpy
const DisplayLabel = styled.div`
  color: ${globalColors.slate5};
  font-family: 'Museo Sans';
  font-size: 11px;
  font-weight: 300;
  text-transform: capitalize;
  white-space: pre-wrap;
  display: inherit;
  &.error {
    color: ${(props) => !props.theme.table.noDataColor};
  }
`;

const Status = styled.div`
  color: ${globalColors.slate5};
  font-size: 10px;
  font-weight: 500;
  margin-top: 6px;
  letter-spacing: 0.45px;
  text-transform: uppercase;
`;

const PriorityColumn = styled.div`
  margin-right: 9px;
`;

const DetailColumn = styled.div`
  flex: 1;
  max-width: 275px;
`;

const StatusColumn = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ArrowButtonColumn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
`;

const LinkedTaskMenu = ({ containerRef, children, title, caseId, taskId, menuPlacement }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const { showTaskDetails } = useContext(EntityDetailsContext);

  const { tasks, isLoading } = useCaseLinkedTasks({
    caseId,
    taskId,
    enabled: Boolean(anchorElement),
  });

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  const handleOpenTaskFn = (id) => () => {
    showTaskDetails(id);
    handleMenuClose();
  };

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={menuPlacement}
        onClick={handleMenuClick}
      >
        <TaskMenu
          tasks={tasks}
          isLoading={isLoading}
          title={title}
          taskId={taskId}
          handleOpenTaskFn={handleOpenTaskFn}
        />
      </Menu>
    </div>
  );
};
export default LinkedTaskMenu;

const TaskMenu = ({ title, taskId, handleOpenTaskFn, tasks, isLoading }) => {
  const { t } = useTranslation(['monitor.issues']);

  const sortedTasks = useMemo(
    () =>
      tasks?.sort(
        (a, b) => new Date(a?.createdDate).getTime() - new Date(b?.createdDate).getTime(),
      ),
    [tasks],
  );

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <>
      <StyledTitle>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <h4 className="menu-title-txt">
          {title ?? t(`table.linked_tasks`, 'Linked Tasks')}
          {!taskId && <StyledBadge color={'transparent'} label={tasks.length.toString()} small />}
        </h4>
      </StyledTitle>
      <Content>
        {sortedTasks?.map((task) => (
          <LinkedTaskItem key={task.id} task={task} handleOpenTaskFn={handleOpenTaskFn} />
        ))}
      </Content>
    </>
  );
};

const LinkedTaskItem = ({ task, handleOpenTaskFn }) => {
  const { t, ready } = useTranslation(['monitor.issues']);

  if (!ready || !task) return null;

  return (
    <TaskRow key={task?.id}>
      <PriorityColumn>
        <Severity level={task?.priority} />
      </PriorityColumn>
      <DetailColumn>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <TaskTitle>{task?.title}</TaskTitle>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <TaskSource>
          <DisplayLabel>{task?.source}</DisplayLabel>
          <DisplayLabel className={!task.workScope ? 'error' : ''}>
            {t(`dynamic.task_work_scope`, {
              scope: t(`dynamic.work_scopes.${task.workScope}`, task.workScope),
            })}
          </DisplayLabel>
        </TaskSource>
      </DetailColumn>
      <StatusColumn>
        {/* TODO(#994): Revist and replace with a Typography component */}
        <DisplayLabel>
          {task?.dueDate
            ? dayjs(task?.dueDate).format(DateTimeFormats.DEFAULT_DATE)
            : Placeholders.DASH}
        </DisplayLabel>
        <Status>{task?.status}</Status>
      </StatusColumn>
      <ArrowButtonColumn type="button" onClick={handleOpenTaskFn(task?.id)}>
        <TableArrow />
      </ArrowButtonColumn>
    </TaskRow>
  );
};

TaskMenu.propTypes = {
  title: PropTypes.string,
  caseId: PropTypes.string,
  taskId: PropTypes.string,
  handleOpenTaskFn: PropTypes.func,
  tasks: PropTypes.arrayOf(Object),
  isLoading: PropTypes.bool,
};

LinkedTaskItem.propTypes = {
  task: PropTypes.object,
  handleOpenTaskFn: PropTypes.func,
};

LinkedTaskMenu.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  caseId: PropTypes.string,
  taskId: PropTypes.string,
  containerRef: PropTypes.instanceOf(Object),
  taskIds: PropTypes.arrayOf(PropTypes.string),
  menuPlacement: PropTypes.string,
};

LinkedTaskMenu.defaultProps = {
  containerRef: null,
};
