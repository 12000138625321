import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ColumnTitle, ColumnDetail } from '@ge/shared/components/tasks/task-template-shared';

const ResolutionColumnTitle = styled(ColumnTitle)`
  color: ${({ theme }) => theme.entityDetails.cases.details.resolutionText};
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 150px;
`;

const StyledColumnTitle = styled(ResolutionColumnTitle)`
  margin-bottom: 0;
  padding-bottom: 0;
`;

const ResolutionColumnDetail = styled(ColumnDetail)`
  border-bottom: none;
`;

const StyledResolutionColumnDetail = styled(ResolutionColumnDetail)`
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const CaseDetailRow = ({ title, detail, detailClasses }) => {
  return (
    <div className="task-panel-row">
      <ResolutionColumnTitle>{title}</ResolutionColumnTitle>
      <ResolutionColumnDetail className={`column-detail ${detailClasses}`}>
        {detail}
      </ResolutionColumnDetail>
    </div>
  );
};

CaseDetailRow.propTypes = {
  title: PropTypes.node,
  detail: PropTypes.node,
  detailClasses: PropTypes.string,
};

export const StyledCaseDetailRow = ({ title, detail }) => {
  return (
    <div className="task-panel-row">
      <StyledColumnTitle>{title}</StyledColumnTitle>
      <StyledResolutionColumnDetail className="column-detail">
        {detail}
      </StyledResolutionColumnDetail>
    </div>
  );
};

StyledCaseDetailRow.propTypes = {
  title: PropTypes.node,
  detail: PropTypes.node,
};
