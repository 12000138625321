import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollbarWrapper } from '@ge/components/infinite-scrollbar-wrapper';
import { Loader, SpinLoader } from '@ge/components/loader';
import { EntityAll } from '@ge/models/constants';

import { PersonAssetsSiteItem } from './person-assets-site-item';

const ListWrapper = styled.ul`
  li:nth-child(odd) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  }
  li:nth-child(even) {
    background-color: ${(props) => props.theme.admin.panel.entityRow.dark};
  }
  list-style-type: none;
  padding: 2px 2px 2px 2px;
  background: ${(props) => props.theme.admin.panel.entityRow.dark};
`;

const ContentWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 50vh;
`;
const NoRecordsFound = styled.div`
  padding: 20px;
  text-align: center;
`;
export const PersonAssetsSites = withTheme(({ theme, sites }) => {
  let [siteItems, setSiteItems] = useState(null);
  let [hasMoreItems, setHasMoreItems] = useState(true);

  const { activeTab, existingSiteIds, editStatus } = useStoreState(
    (state) => state.tabflow.personAssets,
  );

  const { t } = useTranslation(['admin.people']);
  let allSites = useMemo(() => {
    let list = [...sites];
    if (!editStatus && !existingSiteIds.includes(EntityAll.ALL)) {
      list = list.filter((item) => existingSiteIds.includes(item.id));
    }
    return [...list];
  }, [editStatus, existingSiteIds, sites]);

  useEffect(() => {
    if (allSites) {
      setSiteItems([...allSites].splice(0, 20));
    }
  }, [activeTab, editStatus, existingSiteIds, allSites]);

  const updateInfiniteRecords = () => {
    if (siteItems.length >= allSites.length) {
      setHasMoreItems(false);
      return;
    }
    if (siteItems.length <= allSites.length) {
      let nextItemsToLoad = [...allSites].splice(siteItems.length, siteItems.length + 20);
      setSiteItems([...siteItems, ...nextItemsToLoad]);
    }
  };
  if (!siteItems) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }
  return (
    <div>
      {siteItems?.length ? (
        <ScrollbarWrapper>
          <InfiniteScroll
            dataLength={siteItems.length}
            next={updateInfiniteRecords}
            hasMore={hasMoreItems}
            height={340}
            loader={<SpinLoader className="person-assets-scroll-loader-manual-styles" />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b></b>
              </p>
            }
          >
            <ListWrapper>
              {editStatus
                ? siteItems.map((item) => <PersonAssetsSiteItem key={item.name} item={item} />)
                : siteItems.map((item) => (
                    <li key={item.name}>
                      <div className="person-assets-list-container-view">
                        <div>
                          <Icon
                            icon={Icons.SERVICE_GROUP}
                            size={11}
                            color={theme.admin.panel.entityRow.icon}
                          />
                        </div>
                        <div>{item.name}</div>
                        <div></div>
                      </div>
                    </li>
                  ))}
            </ListWrapper>
          </InfiniteScroll>
        </ScrollbarWrapper>
      ) : (
        <ContentWrapper>
          <NoRecordsFound>{t('assets.no_records', 'No records')}</NoRecordsFound>
        </ContentWrapper>
      )}
    </div>
  );
});
PersonAssetsSites.propTypes = {
  sites: PropTypes.array,
};
