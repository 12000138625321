import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
//  Removing for MVP
// import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  DataLoaderType,
  Capability,
  PermissionScope,
  TaskSourceField,
  TaskFlag,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { WarningMessage } from '@ge/shared/components/warning-message';
import { useAuth } from '@ge/shared/data-hooks';

import { PlanningContext, DragItemTypes, FilterValues } from '../../../context/planning-provider';
import useAssetSearch from '../../../data-hooks/use-asset-search';

import { BacklogFilter } from './backlog-filter';
import { AssetDialog } from './backlog-panel-asset-dialog';
import { SearchInput } from './search-input';
import { TaskItem } from './task-item';

const Container = styled.div`
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  .header {
    padding: 10px;
    border-bottom: solid 1px ${(props) => props.theme.manage.taskItem.borderColor};
    .bundle-checkbox {
      margin-top: 9px;
      align-items: center;
    }
    .filter {
      margin-top: 12px;
      position: relative;
      left: -5px;
      display: flex;
      justify-content: space-between;
    }
    label span {
      font-size: 12px;
      line-height: 14px;
      color: ${(props) => props.theme.manage.taskItem.textColor};
    }
  }
  .backlog {
    display: flex;
    flex: 1;
    position: relative;
  }
  .newtask {
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.21);
    margin-top: auto;
    padding: 16px 10px 10px;
    position: relative;
  }
`;

const FilterBtn = styled.div`
  font-size: 12px;
  color: inherit;
  padding: 0;
`;

const SeeAllBtn = styled(NavLink)`
  color: ${(props) => props.theme.manage.search.filterTextColor};
  font-size: 12px;
  font-weight: 700;
  padding-top: 9px;
  text-decoration: none;
`;

//  Removing for MVP
// const ChevronIcon = styled(Icon).attrs((props) => ({
//   icon: Icons.CHEVRON,
//   size: 10,
//   color: props.theme.manage.search.filterTextColor,
// }))`
//   margin-left: 6px;
// `;

const StyledTasksList = styled.div`
  position: relative;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }
  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const AddAssetButton = styled.button`
  border-radius: 2px;
  position: relative;
  &:focus {
    outline: none;
  }
  padding: 2.9px;
  background: ${(props) =>
    props.active ? props.theme.select.primaryBackground : props.theme.select.secondaryBackground};
`;

const AddAssetIcon = styled(Icon).attrs((props) => ({
  size: 18,
  icon: Icons.TURBINE,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
`;

const StyledDataLoader = styled(DataLoader).attrs(({ theme }) => ({
  noDataIconColor: theme.manage.timeline.noDataIconColor,
  type: DataLoaderType.GRID,
}))``;

export const BacklogPanel = ({ backlogData, isFetching }) => {
  const { t } = useTranslation(['tasks', 'manage.planning']);
  const {
    planningState,
    planningState: { groupFilter },
    serviceGroupIds,
  } = useContext(PlanningContext);
  const { data } = useAssetSearch();
  const [assetsFiltered] = data;
  const { isAuthorized } = useAuth();
  const pagination = useStoreState((store) => store.tasks.taskPageStatus);
  const { enabledApprovalFlag } = useStoreState((store) => store.tasks);
  const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const checkedState = planningState.bundleTasks ? CheckedState.CHECKED : CheckedState.UNCHECKED;
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [showAssetDialog, setShowAssetDialog] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isBacklogFilterVisible, setBacklogFilterVisible] = useState(true);
  const [backlog, setBacklog] = useState(backlogData);
  const [filterText, setFilterText] = useState('');
  const [assetsSelected, setfilteredAsset] = useState(planningState.backlogFilter?.assets);
  const parentRef = useRef();
  const authRef = useRef({});

  const editWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    enforceView: true,
    siteIds: [],
  });

  useEffect(() => {
    if (!filterText.length && !planningState.backlogFilter) return setBacklog(backlogData);
    const { status, source, workScope, priority, flag } = planningState.backlogFilter.filters;
    const assetIds = planningState.backlogFilter?.assets?.filter((a) =>
      assetsFiltered.some((b) => a === b.id),
    );
    setfilteredAsset(assetIds);
    const filteredData = backlogData.filter(
      (task) =>
        (task?.title?.toLowerCase().includes(filterText.toLowerCase()) ||
          task?.site?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          task?.asset?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          t(`dynamic.types.${task.source}`, task?.source)
            ?.toLowerCase()
            .includes(filterText.toLowerCase())) &&
        (assetIds?.length ? assetIds.includes(task?.asset?.id) : true) &&
        (status?.value?.length ? status.value.includes(task?.status) : true) &&
        (source?.value?.length ? source.value.includes(task?.source) : true) &&
        (workScope?.value?.length ? workScope.value.includes(task?.workScope) : true) &&
        (priority?.value?.length ? priority.value.includes(task?.priority) : true) &&
        (flag?.value?.length ? flag.value.includes(task?.flag) : true),
    );
    setBacklog(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backlogData, filterText, assetsFiltered, planningState.backlogFilter]);

  const handleModalOpen = () => {
    setBacklogFilterVisible(true);
  };

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const rowVirtualizer = useVirtual({
    size: backlog.length,
    parentRef,
    estimateSize: React.useCallback(() => 58, []),
    overscan: 5,
  });

  const buildTask = useCallback(
    ({ index, measureRef }) => {
      const task = backlog[index];
      if (!task) return;

      const siteId = task.site?.id;

      // check for cached auth
      let auth = authRef.current?.[siteId];

      if (!auth) {
        auth = {
          workPlanEdit: isAuthorized({
            capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
            enforceView: true,
            siteIds: [siteId],
          }),
          realTimePagesView: isAuthorized({
            capabilities: [
              { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
            ],
            enforceView: true,
            siteIds: [siteId],
          }),
        };

        authRef.current[siteId] = auth;
      }

      const getDraggblestate = () => {
        if (auth.workPlanEdit) {
          if (
            serviceGroupIds.length > 1 &&
            groupFilter.L1.value !== FilterValues.CREW &&
            groupFilter.L2.value !== FilterValues.CREW
          ) {
            return true;
          }
          if (serviceGroupIds.length === 1) {
            return true;
          }
        } else {
          return false;
        }
      };
      return (
        <TaskItem
          draggable={getDraggblestate()}
          hasAssetPanel={auth.realTimePagesView}
          hasSitePanel={auth.realTimePagesView}
          onDragStart={() => planningState.handleDragStart(task, DragItemTypes.BACKLOG, null, null)}
          onDragEnd={planningState.handleDragEnd}
          className={classNames({
            dragging: planningState.dragItem && planningState.dragItem.id === task.id,
            draggable: getDraggblestate(),
            disableDrag:
              approvalFlag &&
              getDraggblestate() &&
              task?.flag !== TaskFlag.APPROVED &&
              enabledApprovalFlag,
          })}
          task={task}
          key={task.id}
          ref={measureRef}
          isNotApprovedTask={task?.flag !== TaskFlag.APPROVED && enabledApprovalFlag}
        />
      );
    },
    [
      backlog,
      serviceGroupIds.length,
      groupFilter.L1.value,
      groupFilter.L2.value,
      planningState,
      isAuthorized,
      approvalFlag,
      enabledApprovalFlag,
    ],
  );

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  if (!items) return null;
  const filters = {
    status: [...new Set(backlogData?.map((e) => e.status))],
    source: [...new Set(backlogData?.map((e) => e.source))],
    workScope: [...new Set(backlogData?.map((e) => e.workScope))],
    priority: [...new Set(backlogData?.map((e) => e.priority))],
    flag: [...new Set(backlogData?.map((e) => e.flag).filter(Boolean))],
  };
  filters.workScope.sort();
  return (
    <>
      <Container>
        <div className="header">
          <SearchInput
            placeholder={t('search_tasks', 'Search tasks')}
            onChange={(e) => setFilterText(e.currentTarget.value)}
          />
          {editWorkPlan && (
            <Checkbox
              className="bundle-checkbox"
              checkState={checkedState}
              onChange={planningState.toggleBundleTasks}
              label={t('bundle_tasks', 'Bundle Tasks')}
            />
          )}
          <div className="filter">
            <FilterBtn>
              <span>
                <button onClick={handleModalOpen}>
                  {isBacklogFilterVisible && <BacklogFilter filters={filters} />}
                </button>
              </span>
              <AddAssetButton onClick={() => setShowAssetDialog(true)} active={isActive}>
                <AddAssetIcon />
              </AddAssetButton>
              {showAssetDialog && (
                <AssetDialog
                  backlogData={backlogData}
                  assetsSelected={assetsSelected}
                  setActive={setActive}
                  onClose={() => setShowAssetDialog(false)}
                  onConfirm={() => setShowAssetDialog(false)}
                />
              )}
            </FilterBtn>
            <SeeAllBtn to={'/execute/cases-tasks'}>{t('see_all', 'SEE ALL')}</SeeAllBtn>
          </div>
        </div>
        <div className="backlog">
          <StyledDataLoader isLoading={isFetching} type={DataLoaderType.GRID} margin="100px auto">
            <ScrollingContainer ref={parentRef}>
              {pagination?.isMax ? (
                <WarningMessage />
              ) : (
                <StyledTasksList paddingTop={paddingTop} paddingBottom={paddingBottom}>
                  {items?.map((item) => buildTask(item))}
                </StyledTasksList>
              )}
            </ScrollingContainer>
          </StyledDataLoader>
        </div>
        <div className="newtask">
          <AuthRender
            capability={Capability.FIELD_TASKS}
            create
            description="New Task button"
            siteLevel={false}
          >
            <Button type="button" full onClick={() => setShowNewTaskModal(true)}>
              {t('new_task', 'New Task')}
            </Button>
          </AuthRender>
        </div>
      </Container>
      {showNewTaskModal && (
        <NewTaskDialog
          onClose={closeModal}
          onConfirm={saveTask}
          taskSource={TaskSourceField.MANUAL}
        />
      )}
    </>
  );
};

BacklogPanel.propTypes = {
  backlogData: PropTypes.instanceOf(Object),
  isFetching: PropTypes.bool,
};

BacklogPanel.defaultProps = {
  backlogData: null,
};
