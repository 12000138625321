import { PropTypes } from 'prop-types';
import React from 'react';

import { RadioGroupMetaField } from '../../meta-fields';

export const ShippingAddress = ({ name, metadata }) => {
  const addressChoices = [
    { id: `${name}-0`, value: metadata.defaultSelection, label: metadata.shippingAddressTitle },
    { id: `${name}-1`, label: metadata.alternateAddressTitle, editable: true },
  ];

  return (
    <RadioGroupMetaField
      name={name}
      required={metadata.create.required}
      defaultValue={metadata.defaultSelection.toUpperCase()}
      choices={addressChoices}
      margin={`0`}
    />
  );
};

ShippingAddress.propTypes = {
  name: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
};

ShippingAddress.defaultProps = {
  name: '',
  metadata: {},
};
