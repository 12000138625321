import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';

import { PersonAssets } from './panels/person-assets';
import { PersonHistory } from './panels/person-history';
import { PersonOverview } from './panels/person-overview';
import { PersonRolesPermissions } from './panels/person-roles-permissions';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const TabContainer = styled(Tab)`
  background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  padding-bottom: 10px;
`;

export const PersonDetailsUser = ({ person, entityTab }) => {
  const { t } = useTranslation(['entity-details']);

  return (
    <StyledTabs default defaultTab={entityTab}>
      <TabContainer label={t('tabs.details', 'Details')}>
        <PersonOverview personDetail={person} />
      </TabContainer>
      <TabContainer label={t('tabs.roles_permissions', 'Roles & Permissions')}>
        <PersonRolesPermissions personDetail={person} />
      </TabContainer>
      <TabContainer label={t('tabs.person_assets', 'Assets')}>
        <PersonAssets personDetail={person} />
      </TabContainer>
      <TabContainer label={t('tabs.person_history', 'History')}>
        <PersonHistory personDetail={person} />
      </TabContainer>
    </StyledTabs>
  );
};

PersonDetailsUser.propTypes = {
  person: PropTypes.object,
  entityTab: PropTypes.string,
};

PersonDetailsUser.defaultProps = {
  entityTab: null,
};
