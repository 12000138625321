import { PropTypes } from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { NotificationProvider } from '@ge/components/notification';
import { Feature, SegmentOffering } from '@ge/models/constants';

import { useAssetCommandNotifications, useAuth } from '../data-hooks';

const FeatureNotificationContext = createContext();

const FeatureNotificationProvider = ({ children }) => {
  // TODO: look into registration pattern per discussion with Aaron
  const [featureApi, setFeatureApi] = useState({});

  // data hooks
  const { permissions = {} } = useAuth();
  const commands = useAssetCommandNotifications();

  useEffect(() => {
    setFeatureApi((prev) => {
      // only expose apis user has access to
      // can revisit this logic, ideally we'd not even import stuff user can't access
      if (permissions?.[SegmentOffering.MONITOR]) {
        prev[Feature.MONITOR] = { ...prev[Feature.MONITOR], commands };
      }

      return prev;
    });
  }, [commands, permissions]);

  return (
    <FeatureNotificationContext.Provider value={featureApi}>
      {children}
    </FeatureNotificationContext.Provider>
  );
};

FeatureNotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AppNotificationProvider = ({ children }) => {
  return (
    <NotificationProvider>
      <FeatureNotificationProvider>{children}</FeatureNotificationProvider>
    </NotificationProvider>
  );
};

AppNotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppNotificationContext = () => useContext(FeatureNotificationContext);
