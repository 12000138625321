import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FlagIcon } from '@ge/components/approval-flag-icon';
import { Checkbox } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { placements, Tooltip } from '@ge/components/tooltip';
import { DateTimeFormats } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const TaskContainer = styled.div`
  height: auto;
  position: relative;
  display: flex;
  padding: 8px;
  width: calc(100% - 16px);
  border-bottom: solid 1px ${({ theme }) => theme.manage.taskItem.borderBottomColor};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.manage.taskItem.textColor};
  margin-bottom: ${({ primary }) => (primary ? '10px' : '0')};
  box-shadow: ${({ primary }) => (primary ? '0 2px 2px 0 rgba(0,0,0,0.16)' : 'none')};
  cursor: 'pointer';
  &.draggable {
    cursor: grab;
  }
  &.dragging {
    background: ${({ theme }) => theme.manage.dayCard.scheduled.backgroundColor};
  }
  &:hover {
    border-bottom-color: ${({ theme }) => theme.manage.taskItem.hover.borderColor};
    // Commenting out for Bundle tasks
    /* .actions {
      display: flex;
    } */
  }
  // Commenting out for Bundle tasks
  /* > .actions {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    padding: 0 4px;
    display: none;
    background: ${({ theme }) => theme.manage.taskItem.hover.actionsBackground};
    z-index: ${elevations.P3};
    button {
      padding: 0 4px;
    }
  } */
  > .content {
    flex: 1;
    > .location {
      display: flex;
      align-items: baseline;
      button {
        color: ${({ theme }) => theme.manage.taskItem.locationColor};
        font-size: 11px;
        line-height: 15px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        padding-left: 0;
        padding: 0;
      }
      .site-width {
        max-width: 18vw;
      }
      .asset-width {
        max-width: 6vw;
      }
      .divider {
        color: ${({ theme }) => theme.manage.taskItem.iconColor};
      }
    }
    .title {
      letter-spacing: 0;
      font-weight: ${({ primary }) => (primary ? '700' : '300')};
    }
    .type {
      color: ${({ theme }) => theme.manage.taskItem.taskTypeColor};
      text-transform: capitalize;
    }
  }
  .time {
    padding: 0 4px;
    font-size: 10px;
  }
`;

const StyledIcon = styled(Icon).attrs(({ size, theme }) => ({
  size: size || 9,
  color: theme.manage.taskItem.iconColor,
}))`
  margin: 0 3px;
  vertical-align: initial;
`;

const ChevronIcon = styled(Icon).attrs(({ theme }) => ({
  icon: Icons.CHEVRON,
  size: 8,
  color: theme.manage.taskItem.iconColor,
  rotate: -90,
}))`
  margin: 0 3px;
`;

const StyledSeverity = styled(Severity)`
  padding-right: 6px;
`;

const SnoozeIcon = styled(Icon).attrs(() => ({
  size: 10,
  icon: Icons.SNOOZE,
  color: globalColors.straw5,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const TaskIcons = styled.div`
  align-self: baseline;
`;

const Flag = styled.div`
  display: block;
`;

export const AddTaskItem = forwardRef(
  (
    {
      task,
      assigned,
      primary,
      hasAssetPanel,
      hasSitePanel,
      className,
      getCheckState,
      toggleCheckbox,
    },
    ref,
  ) => {
    const { asset, site } = task;
    const { t } = useTranslation(['tasks']);
    const { approvalFlag } = useStoreState((state) => state.tenant.featureFlags);

    const renderAsset = () => {
      if (!asset?.id) {
        return null;
      }

      if (hasAssetPanel) {
        return (
          <>
            <button
              className="asset-width"
              type="button"
              // onClick={(e) => handleAssetClick(e, asset.id)}
            >
              <StyledIcon icon={Icons.TURBINE} />
              {asset.name}
            </button>
            <span className="divider">{'/'}</span>
          </>
        );
      }

      return (
        <>
          <span>
            <StyledIcon icon={Icons.TURBINE} />
            {asset.name}
          </span>
          <span className="divider">{'/'}</span>
        </>
      );
    };

    const renderSite = () => {
      if (hasSitePanel) {
        return (
          <>
            <button
              className="site-width"
              type="button"
              // onClick={(e) => handleSiteClick(e, site.id)}
            >
              <StyledIcon icon={Icons.SITE} /> {site.name}
            </button>
            <ChevronIcon />
          </>
        );
      }

      return (
        <span>
          <StyledIcon icon={Icons.SITE} /> {site.name}
        </span>
      );
    };

    return (
      <TaskContainer
        primary={primary}
        className={className}
        // onClick={(e) => handleTaskClick(e, task.id)}
        ref={ref}
      >
        <Checkbox
          checkState={getCheckState(task.id)}
          onChange={(e) => toggleCheckbox(e, task.id)}
          id={task.id}
        />
        <TaskIcons>
          <StyledSeverity level={task.priority} />
          {approvalFlag && task.flag && (
            <Tooltip
              title={t(`dynamic.task_flag.${task?.flag}`, task?.flag)}
              placement={placements.TOP}
              zIndex={elevations.P101}
            >
              <Flag>
                <FlagIcon value={task?.flag} size={9} />
              </Flag>
            </Tooltip>
          )}
        </TaskIcons>
        <div className="content">
          {!assigned && (
            <div className="location">
              {renderAsset()}
              {renderSite()}
            </div>
          )}
          <div className="title">{task.title}</div>
          {task.source && (
            <div className="type">{t(`dynamic.types.${task.source}`, task.source)}</div>
          )}
        </div>
        {task?.eligibleStartDate && (
          <div className="time">
            <SnoozeIcon /> {dayjs(task.eligibleStartDate).format(DateTimeFormats.DEFAULT_DATE)}
          </div>
        )}
        <ChevronIcon />
      </TaskContainer>
    );
  },
);

AddTaskItem.displayName = 'AddTaskItem';

AddTaskItem.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  assigned: PropTypes.bool,
  primary: PropTypes.bool,
  draggable: PropTypes.bool,
  hasAssetPanel: PropTypes.bool,
  hasSitePanel: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  className: PropTypes.string,
  getCheckState: PropTypes.func,
  toggleCheckbox: PropTypes.func,
};

AddTaskItem.defaultProps = {
  assigned: false,
  primary: false,
  draggable: false,
  hasAssetPanel: false,
  hasSitePanel: false,
  onDragStart: () => {},
  onDragEnd: () => {},
  className: null,
};
