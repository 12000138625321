import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { RealTimeCases } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { getWorkStandards } from '@ge/web-client/src/app/services/work-standards';

const FooterButtons = styled.div`
  display: table;
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const WorkStandardFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .spinner {
    left: calc(50% - 70px);
    margin-top: -15px;
  }
`;

// Work Standard  component
export const WorkStandardsDialog = ({ onClose, onConfirm, task, UpdatedTitle }) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const [isOverride, setIsOverride] = useState(false);
  const [taskTitle, setTaskTitle] = useState('');
  const isRealTimeCases = RealTimeCases.includes(task?.source);
  const assetId = task?.asset ? task?.asset?.id : '';
  const title = UpdatedTitle ? UpdatedTitle.trim() : task?.title.trim();

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (isRealTimeCases) {
      return setTaskTitle(title.substring(0, title.indexOf('—')));
    } else {
      return setTaskTitle(title);
    }
  }, [isRealTimeCases, title]);

  const {
    data: workStdData,
    error: workStdError,
    isLoading: workStdLoading,
  } = useQuery(
    ['TaskWorkStandardsData', assetId, taskTitle],

    async () => getWorkStandards(assetId, taskTitle),

    {
      enabled: isOverride,
      ...Config.EXECUTE_ONCE,
    },
  );

  if (isOverride && (workStdData || workStdError)) {
    onConfirm({
      workStandardsData: workStdData,
      workStandardsError: workStdError,
      workStandardsLoading: workStdLoading,
    });
  }

  const onSubmit = useCallback(() => {
    setIsOverride(true);
  }, [setIsOverride]);

  const getFooter = useMemo(() => {
    return (
      <WorkStandardFooterWrapper>
        <SpinLoader showLoader={workStdLoading} className="spinner" />
        <FooterButtons>
          <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleSubmit(onSubmit)}>
            {t('work_standard.work_override_Btn', 'Override')}
          </Button>
        </FooterButtons>
      </WorkStandardFooterWrapper>
    );
  }, [workStdLoading, onClose, t, handleSubmit, onSubmit]);

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onConfirm}
      header={t('work_standard.work_confirmation', 'CONFIRMATION')}
      footer={getFooter}
      className={'no-dismiss-alert'}
    >
      {t(
        'work_standard.work_confirmation_message',
        'Are you sure you want to apply work standards and override existing estimates & expected parts?',
      )}
    </Dialog>
  );
};

WorkStandardsDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  task: PropTypes.object.isRequired,
  UpdatedTitle: PropTypes.string,
};

WorkStandardsDialog.defaultValues = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  task: {},
  UpdatedTitle: '',
};
