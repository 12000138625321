/* eslint-disable prettier/prettier */
import { thunk, action } from 'easy-peasy';

import * as request from '@ge/shared/services/api';
// Define initial state
const currentState = {
  exampleVariable: null,
};

const auditHistoryActions = {
  //actions
  exampleTab: action((state, payload) => {
    state.exampleVariable = payload;
  }),
  
  getAuditData: thunk(async (actions, payload) => {
    console.log('get audit data');
    const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

    const result = request.get('/cmn/users/audit/103013600', {
      baseURL: USERS_BASE_URL,
    });

    console.log(result,actions,payload);
  }),
};

const auditHistoryModel = {
  ...currentState,
  ...auditHistoryActions,
};

export default auditHistoryModel;
