import dayjs from 'dayjs';
import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Menu, placements } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { TableArrow } from '@ge/components/table';
import { Capability, PermissionScope } from '@ge/models/constants';
import { TaskSourceField } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';

const StyledTitle = styled.div`
  height: 26px;
  border-radius: 5px 5px 0 0;
  background: ${({ theme }) => theme.menu.escalationTitleBackground};
  box-shadow: inset 0 -1px 0 0 #1c252d;
  color: ${({ theme }) => theme.menu.headerColor};
  .menu-title-txt {
    letter-spacing: 0.5px;
    line-height: 13px;
    text-transform: uppercase;
    padding: 7px 11px 6px;
  }
`;

const Content = styled.div`
  width: 345px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${globalColors.slate2};

  ::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const TaskRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.menu.borderColor};
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  &:first-of-type {
    border: none;
  }
`;

const TaskColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SeverityIcon = styled.div`
  align-self: start;
  margin-top: 2px;
  margin-right: 9px;
`;

const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

const TaskAdditionalDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableArrowIcon = styled.div`
  margin-left: 8px;
  cursor: pointer;
`;

const TaskTitle = styled.span`
  color: ${({ theme }) => theme.menu.tableBodyTextColor};
  font-family: 'Museo Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;

const TaskWorkScope = styled.span`
  color: ${({ theme }) => theme.caseLinkDialog.textColor};
  font-family: 'Museo Sans';
  font-size: 11px;
  letter-spacing: 0;
  line-height: 25px;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const TaskDueDate = styled.span`
  color: ${({ theme }) => theme.menu.textColor};
  font-family: 'Museo Sans';
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  text-align: right;
  margin-bottom: 4px;
`;

const TaskStatus = styled.span`
  color: ${({ theme }) => theme.themeSelector.iconColor};
  font-family: 'Museo Sans';
  font-size: 10px;
  letter-spacing: 0.45px;
  line-height: 11px;
  text-align: right;
`;

const CreateTaskSection = styled.div`
  text-align: right;
  margin: 15px 28px 10px 0;
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.themeSelector.badgeColor,
}))`
  margin: 0 7px;
`;

const CreateTaskButton = styled.button`
  color: ${({ theme }) => theme.menu.textColor};
  font-family: 'Museo Sans';
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  text-transform: uppercase;
`;

const TaskEscalationMenu = ({
  containerRef,
  children,
  tasksData,
  titleDefault,
  entityId,
  entity,
}) => {
  const { isAuthorized } = useAuth();
  const authorizedCreateTask = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  });
  const { t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const { showTaskDetails } = useContext(EntityDetailsContext);
  const { setSelectedAssetToEscalate } = useStoreActions((actions) => actions.tasks);
  const { setWorkStandards } = useStoreActions((actions) => actions.tasks);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  const handleTaskSelect = (e, id) => {
    killEventPropagation(e);
    showTaskDetails(id);
  };

  const TaskEscalationItem = (
    <>
      {tasksData?.map((task, id) => (
        <TaskRow key={id}>
          <TaskColumn>
            <SeverityIcon>
              <Severity level={task?.priority?.toLowerCase()} />
            </SeverityIcon>
            <TaskDetails>
              <TaskTitle>{task?.title}</TaskTitle>
              {task?.workScope && <TaskWorkScope>{task?.workScope}</TaskWorkScope>}
            </TaskDetails>
          </TaskColumn>
          <TaskColumn>
            <TaskAdditionalDetails>
              {task?.status === 'scheduled' && (
                <TaskDueDate>{dayjs(task?.scheduledDate).format('D MMM YYYY')}</TaskDueDate>
              )}
              <TaskStatus>{task?.status?.toUpperCase()}</TaskStatus>
            </TaskAdditionalDetails>
            <TableArrowIcon>
              <div onClick={(e) => handleTaskSelect(e, task?.id)}>
                <TableArrow />
              </div>
            </TableArrowIcon>
          </TaskColumn>
        </TaskRow>
      ))}
    </>
  );

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleCloseMenu}>
      {children}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        container={containerRef}
        placement={placements.LEFT}
        onClick={handleMenuClick}
        portalId={'.details-panel-transition-enter-done'}
      >
        <StyledTitle>
          <h4 className="menu-title-txt">{titleDefault}</h4>
        </StyledTitle>
        <Content>{TaskEscalationItem}</Content>
        <CreateTaskSection>
          <AuthRender
            capability={Capability.FIELD_TASKS}
            create
            description="Create Task"
            siteLevel={false}
          >
            <CreateTaskButton
              onClick={() => {
                handleCloseMenu();
                setShowCreateTaskModal(authorizedCreateTask);
                setSelectedAssetToEscalate(entity);
              }}
            >
              <AddIcon />
              {t('asset_panel.create_task', 'Create Task')}
            </CreateTaskButton>
          </AuthRender>
        </CreateTaskSection>
      </Menu>
      {showCreateTaskModal && (
        <NewTaskDialog
          onClose={() => {
            setShowCreateTaskModal(false);
            setSelectedAssetToEscalate({});
            setWorkStandards(null);
            setAnchorEl(null);
          }}
          onConfirm={() => {
            setShowCreateTaskModal(false);
            setSelectedAssetToEscalate({});
            setWorkStandards(null);
          }}
          taskSource={TaskSourceField.REALTIMECASES}
          entityId={entityId}
          entity={entity}
          isCalledfromTaskEscalationColumn={true}
          setAnchorEl={setAnchorEl}
        />
      )}
    </div>
  );
};

TaskEscalationMenu.propTypes = {
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
  tasksData: PropTypes.instanceOf(Object).isRequired,
  titleDefault: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
};

TaskEscalationMenu.defaultProps = {
  containerRef: null,
  tasksData: null,
};

export default TaskEscalationMenu;
