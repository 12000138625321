const roundNumber = (value, digits = 2) =>
  value || value === 0 ? Math.round(value * 10 ** digits) / 10 ** digits : null;

const getPercent = (value, total) => roundNumber((value / total) * 100).toFixed(1);

const getFixed = (value, digits = 2) => {
  let parsed = Number.parseFloat(value);

  return Number.isNaN(parsed) ? null : parsed.toFixed(digits);
};

// Get the left offset of item within a container
const getPercentLeftOffset = (left, right, val) => {
  const min = parseInt(left, 10);
  const max = parseInt(right, 10);
  const value = Math.min(parseInt(val, 10), parseInt(right, 10));
  const percent = ((value - min) / (max - min)) * 100;
  return percent;
};

/**
 * Get the min and max values from an array of numbers.
 *
 * @param {number[]} arr Array of numbers
 * @returns {number[]} Array with the min and max values
 */
const getMinMax = (arr) =>
  arr.reduce(
    ([prevMin, prevMax], current) => [Math.min(prevMin, current), Math.max(prevMax, current)],
    [Infinity, -Infinity],
  );

// verify browser support for our browsers
const formatNumber = (value, language) => new Intl.NumberFormat(language).format(value);

/**
 * Format a number as currency.
 *
 * @param {number} value Currency value to format
 * @param {string} locale Locale to use
 * @param {string} currency Currency decorator
 */
const formatToCurrency = (value, locale = 'en-US', currency = 'USD') =>
  value.toLocaleString(locale, { style: 'currency', currency });

// can improve this, just want a shorthand way to coerce non-numeric values to 0
const parseNaN = (val) => (isNaN(+val) ? 0 : +val);

module.exports = {
  roundNumber,
  getFixed,
  getMinMax,
  getPercent,
  getPercentLeftOffset,
  formatNumber,
  formatToCurrency,
  parseNaN,
};
