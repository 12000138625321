import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { EntityType } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';

import SensorReadingsList from '../components/sensor-readings-list';
import TabDetailHeader from '../components/tab-detail-header';
import {
  TabDetailContainer,
  EntityCollapsiblePanel,
  TimeframeToggle,
} from '../entity-details-shared';

import EventAlarmsTable from './event-alarms-table';
import { alarms } from './mock-alarms';

const EventMetric = {
  START: 'start',
};
const Timeframes = {
  CURRENT: 'current',
  INITIAL: 'initial',
};

const StyledTabDetailContainer = styled(TabDetailContainer)`
  .tab-detail-header {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  ${(props) =>
    !props.primary ? `color: ${props.theme.entityDetails.buttonInactiveTextColor}` : ''};
`;

const EventOverview = ({ event, asset, causalEvent, setCausalEvent, openFhp }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const [sensorReadingsTimeframe, setSensorReadingsTimeframe] = useState(Timeframes.CURRENT);
  const [activeAlarmsTimeframe, setActiveAlarmsTimeframe] = useState(Timeframes.CURRENT);

  const { showEventDetails } = useContext(EntityDetailsContext);

  // Table state management
  const {
    sortDirection: sortActiveDirection,
    sortMetric: sortActiveMetric,
    updateSortMetric: toggleActiveSort,
  } = useColumnState({
    defaultSortMetric: EventMetric.START,
    defaultSortDirection: SortDirection.DESC,
  });

  const handleAlarmSelect = useCallback(
    (e, event) => {
      killEventPropagation(e);
      showEventDetails(event.id);
    },
    [showEventDetails],
  );

  if (!alarms || !asset) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  return (
    <StyledTabDetailContainer>
      <TabDetailHeader entity={event} entityType={EntityType.EVENT} />
      <TimeframeToggle>
        <StyledButton
          type="button"
          primary={sensorReadingsTimeframe === Timeframes.CURRENT}
          onClick={() => setSensorReadingsTimeframe(Timeframes.CURRENT)}
        >
          {t('event_panel.current', 'Current')}
        </StyledButton>
        <StyledButton
          type="button"
          primary={sensorReadingsTimeframe === Timeframes.INITIAL}
          onClick={() => setSensorReadingsTimeframe(Timeframes.INITIAL)}
        >
          {t('event_panel.inital', 'Initial')}
        </StyledButton>
      </TimeframeToggle>
      <EntityCollapsiblePanel
        headerContent={<h2>{t('event_panel.sensor_readings', 'Sensor Readings')}</h2>}
        expanded={true}
      >
        <SensorReadingsList asset={asset} />
      </EntityCollapsiblePanel>
      <TimeframeToggle>
        <StyledButton
          type="button"
          primary={activeAlarmsTimeframe === Timeframes.CURRENT}
          onClick={() => setActiveAlarmsTimeframe(Timeframes.CURRENT)}
        >
          {t('event_panel.current', 'Current')}
        </StyledButton>
        <StyledButton
          type="button"
          primary={activeAlarmsTimeframe === Timeframes.INITIAL}
          onClick={() => setActiveAlarmsTimeframe(Timeframes.INITIAL)}
        >
          {t('event_panel.inital', 'Initial')}
        </StyledButton>
      </TimeframeToggle>
      <EntityCollapsiblePanel
        headerContent={<h2>{t('event_panel.active_alarms', 'Active Alarms')}</h2>}
        expanded={true}
      >
        <EventAlarmsTable
          alarms={alarms}
          sortAction={toggleActiveSort}
          sortMetric={sortActiveMetric}
          sortDirection={sortActiveDirection}
          causalEvent={causalEvent}
          onEventSelect={handleAlarmSelect}
          setCausalEvent={setCausalEvent}
          openFhp={openFhp}
        />
      </EntityCollapsiblePanel>
    </StyledTabDetailContainer>
  );
};

EventOverview.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  asset: PropTypes.shape({
    metrics: PropTypes.object,
  }).isRequired,
  causalEvent: PropTypes.string,
  setCausalEvent: PropTypes.func,
  openFhp: PropTypes.func,
};

EventOverview.defaultProps = {
  causalEvent: null,
  setCausalEvent: () => null,
  openFhp: () => null,
};

export default EventOverview;
