import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Donut } from '@ge/components/charts/donut-chart/donut';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Table, Td } from '@ge/components/table';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { DateTimeFormats } from '@ge/models/constants';
import { statusChartColors } from '@ge/tokens/charts';

const DonutContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  box-sizing: border-box;
  padding: 0 8px;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DonutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 50%;
`;

const TimestampContainer = styled.span`
  color: ${({ theme }) => theme.createReport.widget.timestamp};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const TableWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-top: 3px;
  width: 50%;
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  button:focus {
    outline: none;
  }
  table {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  tr {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.assetStatus.borderColor};
    justify-content: space-between;
    padding-left: 7px;
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  td.color-value-cell {
    display: flex;
    align-items: center;
  }
`;

const ColorBlock = styled.div.attrs(({ color }) => ({
  style: { backgroundColor: color },
}))`
  height: 11px;
  width: 11px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 8px;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.widget.headerTextColor};
`;

const TurbineIcon = styled(Icon).attrs(({ theme }) => ({
  size: 10,
  icon: Icons.TURBINE,
  color: theme.createReport.widget.headerIconColor,
}))``;

export const DonutWidgetStateKeys = {
  CHECKED_STATUSES: 'checkedStatuses',
};

export const DonutWidget = ({ id, data, name, isLoading, isPlaceholder, showTimestamp }) => {
  const { t } = useTranslation(['reporting.widgets', 'general'], { useSuspense: false });

  const {
    reportState: { createdTime },
    getWidgetState,
  } = useContext(ReportsContext);

  /**
   * Fuction to map status values into a format that highcharts expects.
   */
  const mapChartData = useCallback(
    (item) => ({
      y: item?.value,
    }),
    [],
  );

  /**
   * Fuction to format numeric cell value.
   */
  const formatValue = useCallback((value) => {
    return value ? value : <span>{'-'}</span>;
  }, []);

  /**
   * Checked statuses from left panel checkboxes.
   */
  const filteredStatuses = useMemo(
    () => ({
      ...data,
      statuses:
        data.statuses?.filter(
          (status) => getWidgetState(id, DonutWidgetStateKeys.CHECKED_STATUSES)?.[status.name],
        ) || [],
    }),
    [data, getWidgetState, id],
  );

  /**
   * Memoized chart data.
   */
  const chartData = useMemo(() => {
    if (isPlaceholder) return null;

    return filteredStatuses?.statuses?.map(mapChartData);
  }, [isPlaceholder, filteredStatuses, mapChartData]);

  const title = t('selected', 'SELECTED');
  const donutWidgetData = filteredStatuses?.statuses?.map((item) => item?.value);
  const summedStatusValues = donutWidgetData?.reduce((a, b) => a + b, 0);
  const donutLabel = summedStatusValues ? `${title}<br />${summedStatusValues}` : '';

  /**
   * Memoized table rows.
   */
  const tableContent = useMemo(() => {
    if (isPlaceholder) return null;

    return filteredStatuses?.statuses?.map((item, index) => {
      return (
        <tr key={index}>
          <Td className="color-value-cell" align="left" noPadding>
            <ColorBlock color={statusChartColors[item?.name]} />
            {t(`asset_state.${item?.name}`, item?.name)}
          </Td>
          <Td align="right">{formatValue(item?.value)}</Td>
        </tr>
      );
    });
  }, [isPlaceholder, filteredStatuses, formatValue, t]);

  const donutColors = useMemo(() => {
    if (isPlaceholder) return null;

    return filteredStatuses?.statuses?.map((item) => statusChartColors[item?.name]);
  }, [filteredStatuses, isPlaceholder]);

  const timestamp = useMemo(() => {
    return createdTime?.format(DateTimeFormats.DEFAULT_DATE_TIME_SECS);
  }, [createdTime]);

  if (isLoading) {
    return (
      <>
        <StyledHeader>
          <h4>{name}</h4>
          <TurbineIcon />
        </StyledHeader>
        <DonutContainer>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </DonutContainer>
      </>
    );
  }

  return (
    <>
      <StyledHeader>
        <h4>{name}</h4>
        <span>
          <TurbineIcon />
          {!isPlaceholder && ` ${data?.assetCount}`}
        </span>
      </StyledHeader>
      {!isPlaceholder && (
        <DonutContainer>
          <DonutWrapper>
            {showTimestamp && timestamp && (
              <TimestampContainer>{timestamp} | UTC</TimestampContainer>
            )}
            <FlexWrapper>
              <Donut
                data={chartData}
                colors={donutColors}
                width={111}
                height={111}
                interactive={false}
                innerSize="70%"
                title={donutLabel}
              />
            </FlexWrapper>
          </DonutWrapper>
          <TableWrapper>
            <TableContainer>
              <Table compressed>
                <tbody>{tableContent}</tbody>
              </Table>
            </TableContainer>
          </TableWrapper>
        </DonutContainer>
      )}
    </>
  );
};

DonutWidget.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  showTimestamp: PropTypes.bool,
};

DonutWidget.defaultProps = {
  data: {},
  showTimestamp: false,
};
