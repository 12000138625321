import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { EntityType } from '@ge/models/constants';

import AssetDetailHeader from '../asset-details/asset-details-header';
import EntityDetailHeader from '../entity-details-header';

const ContentWrapper = styled.section`
  position: relative;
  button {
    position: absolute;
    top: 11px;
    right: 30px;
  }
  h2 {
    font-size: 18px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

const EventDetails = styled.div`
  flex: 1;
  text-transform: capitalize;
  h5 {
    color: #9ba9b9;
  }
`;

const DateTime = styled.div`
  display: flex;
  flex-direction: column;
  color: #9ba9b9;
  h5 {
    flex: 1;
  }
  div {
    justify-self: flex-end;
  }
`;

const EventDetailHeader = ({ event, asset, site }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const { conditions } = asset.metrics;

  if (!event || !asset || !site || !conditions) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  return (
    <EntityDetailHeader type={EntityType.TICKET}>
      <ContentWrapper>
        <EventDetails>
          <h5>{t('ticket_entity.originating_event', 'ORIGINATING EVENT')}</h5>
          <h2>{event.description}</h2>
          <h4>{event.code}</h4>
        </EventDetails>
        <DateTime>
          <h5>4/12/19</h5>
          <div>
            <h5>{t('ticket_entity.site', 'Site')} 10:12</h5>
            <h5>{t('ticket_entity.local', 'Local')} 14:12</h5>
          </div>
        </DateTime>
      </ContentWrapper>
      <AssetDetailHeader site={site} asset={asset} />
      <LinkWrapper>
        <Button type="button">{t('ticket_entity.link_asset', 'LINK ASSET')}</Button>
      </LinkWrapper>
    </EntityDetailHeader>
  );
};

EventDetailHeader.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  asset: PropTypes.instanceOf(Object).isRequired,
  site: PropTypes.instanceOf(Object).isRequired,
};

export default EventDetailHeader;
