import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Menu, placements } from '@ge/components/menu';
import { Text } from '@ge/components/typography';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens/typography';

const StyledEscalateButton = styled.div`
  font-size: 11px;
  text-align: left;
  display: inline;
  position: relative;
  color: currentColor;
`;

const MenuTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 4,
}))`
  padding: 8px 10px 4px 8px;
  display: block;
  color: currentColor;
`;

const CommandsDisabledText = styled(Text).attrs(() => ({
  level: 2,
  type: typography.textTypes.body,
}))`
  color: ${(props) => props.theme.dangerColor};
  margin: 10px;
  display: block;
`;

const AddButton = styled.button`
  border: ${(props) => props.theme.tasks.newButton.border};
  border-radius: 2px;
  background: ${(props) => props.theme.tasks.newButton.background};
  padding: 0;
  height: 20px;
  width: 20px;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ADD,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

export const EscalateButton = ({
  containerRef,
  disabled,
  cellValue,
  entity,
  handleSendCase,
  isFleetCase,
  bulkErrorCase,
  onMenuClose,
  eventsLength,
}) => {
  const { t } = useTranslation(['entity-details']);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    onMenuClose();
  };

  return (
    <StyledEscalateButton onClick={(e) => killEventPropagation(e)}>
      <AddButton
        className={isFleetCase || disabled ? 'disabled' : ''}
        disabled={isFleetCase || disabled}
        onClick={(e) => {
          handleSendCase(e, cellValue, entity);
          if (eventsLength && !bulkErrorCase.trim().length) {
            if (anchorEl === null) {
              setAnchorEl(e.currentTarget);
            } else {
              handleMenuClose();
            }
          }
        }}
      >
        <AddIcon />
      </AddButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        width={150}
        placement={placements.BOTTOM_END}
        fixed
      >
        {bulkErrorCase.trim().length ? (
          <>
            <MenuTitle>
              <b>{t('escalate_error.group_error', 'Cannot send a group escalation')}</b>
            </MenuTitle>
            <CommandsDisabledText>{bulkErrorCase}</CommandsDisabledText>
          </>
        ) : (
          <></>
        )}
      </Menu>
    </StyledEscalateButton>
  );
};

EscalateButton.propTypes = {
  containerRef: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  handleSendCase: PropTypes.func,
  onMenuClose: PropTypes.func,
  isFleetCase: PropTypes.bool,
  entity: PropTypes.instanceOf(Object),
  cellValue: PropTypes.instanceOf(Object).isRequired,
  bulkErrorCase: PropTypes.string,
  eventsLength: PropTypes.number,
};

EscalateButton.defaultProps = {
  containerRef: null,
  disabled: false,
  onMenuClose: () => {},
};
