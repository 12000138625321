import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
import { Radio } from '@ge/components/radio';
import { CustomSelect } from '@ge/components/select';
import { SortDirection } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

const DropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 230px);
  margin-top: 10px;
  column-gap: 10px;
  //padding-bottom: 10px;
  //border-bottom: 1px solid ${globalColors.stone1};
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
`;
const SitesType = {
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
};
export const EMdropdowns = ({ details, setSelectedSites }) => {
  const maxWidth = 240;
  const minWidth = 200;

  const { t } = useTranslation(['admin.configure']);
  const { control } = useFormContext();
  const { getSortedSites, getSiteById } = useStoreState((state) => state.sites);
  const siteValueLabelMap = useMemo(
    () =>
      getSortedSites('name', SortDirection.ASC)?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [getSortedSites],
  );

  let selectedSites = [];
  const handleSiteChange = (onChange, value) => {
    selectedSites = value.map(({ value }) => value);
    onChange(value);
    setSelectedSites(selectedSites);
  };

  useEffect(() => {
    if (details) {
      let sites = [];
      control.getValues('sitesType') !== SitesType.INCLUDE
        ? details?.flaggedProperties?.excludeSitesFilter?.forEach((rec) => {
            if (rec?.siteId) {
              sites.push(rec.siteId);
            }
          })
        : details?.flaggedProperties?.includeSitesFilter?.forEach((rec) => {
            if (rec?.siteId) {
              sites.push(rec.siteId);
            }
          });
      sites = [...new Set(sites)];
      control.setValue(
        'sitesType',
        control.getValues('sitesType') === SitesType.INCLUDE
          ? SitesType.INCLUDE
          : SitesType.EXCLUDE,
      );
      control.setValue('sites', formatData(sites.map((item) => getSiteById(item))));
    }
  }, [details]);

  const formatData = (arg) => {
    if (!arg || !arg.length) return [];
    return arg.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  const onSitesChange = (onChange) => (e) => {
    control.setValue(
      'sitesType',
      e.target.id === 'Include' ? SitesType.INCLUDE : SitesType.EXCLUDE,
    );
    onChange(e.target.id);
  };

  return (
    <>
      <DropdownWrapper itemWidth={maxWidth}>
        <Controller
          control={control}
          name={'sites'}
          rules={{
            required: true,
            validate: (val) => Boolean(val.length),
          }}
          render={({ onChange, onBlur, value }, { invalid }) => (
            <CustomSelect
              placeholder={t('placeholders.select_sites', 'Select sites')}
              disabled={false}
              errors={invalid ? 'select minimum one site' : ''}
              id={'DL_Sites'}
              label={t('Event_managment.sites', 'Site(s)')}
              onSelect={(val) => handleSiteChange(onChange, val)}
              onBlur={onBlur}
              options={siteValueLabelMap}
              value={value}
              isMulti={true}
              searchable={true}
              maxMenuHeight={220}
              maxWidth={maxWidth}
              minWidth={minWidth}
              labelIcon={Icons.SITE}
            />
          )}
        />
        <Controller
          control={control}
          name="sitesType"
          render={({ onChange, value }) => (
            <RadioGroup>
              <Radio
                id="Include"
                name="Include"
                label={t('Event_managment.Include', 'Include')}
                value={value}
                checked={value === SitesType.INCLUDE}
                onChange={onSitesChange(onChange)}
              />
              <Radio
                id="Exclude"
                name="Exclude"
                label={t('Event_managment.Exclude', 'Exclude')}
                value={value}
                checked={value === SitesType.EXCLUDE}
                onChange={onSitesChange(onChange)}
              />
            </RadioGroup>
          )}
        />
      </DropdownWrapper>
    </>
  );
};

EMdropdowns.propTypes = {
  details: PropTypes.object,
  setSelectedSites: PropTypes.any,
};
