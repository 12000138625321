import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Severity } from '@ge/components/severity';
import { CasePriority } from '@ge/models/constants';
import { getAssetIcon } from '@ge/shared/util';

import { useCaseLinkContext } from './case-link-provider';

const TitleInputContainer = styled.div`
  display: flex;
  width: 100%;
  input {
    font-size: 14px;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 7px;
`;

const TitleRow = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.caseLinkDialog.headerSeparatorColor};
  padding-bottom: 10px;
  margin-bottom: 7px;
`;

const EntityInfo = styled.div`
  color: ${({ theme }) => theme.caseLinkDialog.entityInfo};
  font-size: 12px;
  padding-bottom: 6px;
`;

const EntityDescription = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;

const EntityInfoIcon = styled(Icon).attrs((props) => ({
  color: props.theme.caseLinkDialog.assetIconColor,
  size: 12,
}))`
  margin-right: 5px;
`;

const EntityDescriptionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.caseLinkDialog.entityIconColor,
  size: 20,
}))`
  margin-right: 5px;
`;

const ButtonIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.caseLinkDialog.entityIconColor,
}))`
  margin: 7px;
`;

const IconContainer = styled.span`
  &.hidden {
    visibility: hidden;
  }
`;

const getHighestPriority = (cases) => {
  const priorityMap = {
    [CasePriority.LOW]: 1,
    [CasePriority.MEDIUM]: 2,
    [CasePriority.HIGH]: 3,
    [CasePriority.IMMEDIATE]: 4,
  };
  return cases.reduce(
    (acc, _case) =>
      !_case.priority || priorityMap[_case.priority.toLowerCase()] > priorityMap[acc]
        ? _case.priority.toLowerCase()
        : acc,
    CasePriority.LOW,
  );
};

export const CaseLinkTitle = React.memo(() => {
  const { t, ready } = useTranslation(['entity-details'], { useSuspense: false });

  const { title, setTitle: onChange, entity, parentCase, allSelectedCases } = useCaseLinkContext();

  const priority = getHighestPriority(allSelectedCases);

  const handleTitleChange = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      onChange(value);
    },
    [onChange],
  );

  if (!ready) {
    return null;
  }

  return (
    <TitleContainer>
      <TitleRow>
        <EntityInfo>
          <EntityInfoIcon icon={Icons.SITE} color={'transparent'} />
          <span>{`${entity.site.name} / `}</span>
          <EntityInfoIcon icon={getAssetIcon(entity.asset.type)} color={'transparent'} />
          <span>{entity.asset.name}</span>
          <ButtonIcon icon={Icons.CHEVRON} rotate={-90} />
        </EntityInfo>
        <EntityDescription>
          <IconContainer className={!parentCase ? 'hidden' : ''}>
            <EntityDescriptionIcon
              icon={parentCase?.childCases?.length ? Icons.CASE : Icons.ANOMALY}
              color={'transparent'}
              className={!parentCase ? 'hidden' : ''}
            />
          </IconContainer>
          <IconContainer className={!parentCase ? 'hidden' : ''}>
            <Severity level={priority} size={12} />
          </IconContainer>
          <TitleInputContainer>
            <Input
              onChange={handleTitleChange}
              required
              type="text"
              value={title}
              placeholder={t('placeholder.title', 'Add Title')}
              name={'case-link-title'}
            />
          </TitleInputContainer>
        </EntityDescription>
      </TitleRow>
    </TitleContainer>
  );
});

CaseLinkTitle.displayName = 'CaseLinkTitle';
