import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { DEFAULT_INSTRUCTION, MAX_INSTRUCTIONS } from '@ge/models/constants';

import SpecialInstructionItems from './special-instruction-items';

const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: calc(100% - 2px);
  z-index: 1;
`;

const InstructionHeader = styled.div`
  background-color: ${(props) => props.theme.entityDetails.specialInstructions.evenListBackground};
  align-items: center;
  margin: 0px;
  padding: 9px 25px 6px 34px;
  h4 {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    color: ${(props) => props.theme.entityDetails.specialInstructions.headerColor};
    border-bottom: 1.5px solid
      ${({ theme }) => theme.entityDetails.specialInstructions.headerBorder};
    padding-bottom: 4px;
    .total-task-count {
      margin-left: 7px;
    }
    span {
      font-weight: 700;
      letter-spacing: 0.5px;
    }
  }
`;

const InsturctionList = styled.div`
  position: relative;
  overflow: hidden;
  color: ${(props) => props.theme.subNavigation.linkBorderColor};
  min-height: 30px;
  height: auto;
  background-color: ${(props) => props.theme.entityDetails.specialInstructions.oddListBackground};
  &:nth-child(even) {
    background-color: ${(props) =>
      props.theme.entityDetails.specialInstructions.evenListBackground};
  }
  .past-due {
    color: ${(props) => props.theme.entityDetails.specialInstructions.pastDue};
    font-weight: 700;
  }
`;

const ChevronUp = styled(Icon).attrs((props) => ({
  size: 15,
  icon: Icons.CHEVRON_UP,
  color: props.theme.select.primaryBorder,
}))`
  vertical-align: initial;
  cursor: pointer;
`;

const SeeAllButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => props.theme.entityDetails.specialInstructions.evenListBackground};
  color: ${(props) => props.theme.select.primaryBorder};
  padding: 4px 20px 6px 27px;
  margin: 0px;
  max-height: 20px;
  text-transform: uppercase;
  border-radius: 0px 0px 6px 6px;
  font-weight: bold;
  div:first-child {
    cursor: pointer;
  }
  .see-all {
    position: relative;
    top: 14px;
  }
`;

const StyledBadge = styled(Badge).attrs((props) => ({
  color: props.theme.entityDetails.specialInstructions.badgeCount,
}))`
  background: ${(props) => props.theme.entityDetails.specialInstructions.badgeBackground};
  font-size: 11px;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
`;

const CollapseButton = styled.div``;

const PStyle = styled.div`
  padding: 4px;
`;

const sortByDate = (a, b) => dayjs(b.updateDate) - dayjs(a.updateDate);

const SpecialInstructions = ({ asset, entityType, instructionData, totalCount, assetType }) => {
  const { t } = useTranslation(['monitor.sites']);

  const [seeAll, setSeeAll] = useState(false);

  const toggleSeeAll = useCallback(() => {
    setSeeAll((state) => !state);
  }, [setSeeAll]);

  const getInstructions = () => {
    if (seeAll) {
      return instructionData.sort(sortByDate).slice(0, MAX_INSTRUCTIONS);
    }

    return instructionData.sort(sortByDate).slice(0, DEFAULT_INSTRUCTION);
  };

  return (
    <>
      <InstructionContainer>
        <InstructionHeader>
          <h4>
            <span>{t('table.special_instructions', 'Special Instructions')}</span>
            {<StyledBadge className="total-task-count" label={totalCount} small />}
          </h4>
        </InstructionHeader>
        {getInstructions().map(
          ({ note, updatedBy, createdBy, validDateBy, updateDate, id, domainId }) => (
            <InsturctionList key={id}>
              <SpecialInstructionItems
                content={note}
                userName={updatedBy ?? createdBy}
                lastUpdatedDate={updateDate}
                validDateBy={validDateBy}
                entityType={entityType}
                isAssetSI={asset === String(domainId)}
                assetType={assetType}
              />
            </InsturctionList>
          ),
        )}
        <SeeAllButton>
          {instructionData.length > 1 ? (
            <CollapseButton onClick={toggleSeeAll}>
              {<ChevronUp rotate={seeAll ? 0 : 180} />}
            </CollapseButton>
          ) : (
            <PStyle />
          )}
        </SeeAllButton>
      </InstructionContainer>
    </>
  );
};

SpecialInstructions.propTypes = {
  site: PropTypes.instanceOf(Object),
  asset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  entityType: PropTypes.string,
  instructionData: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number,
  assetType: PropTypes.string,
};

SpecialInstructions.defaultProps = {
  site: {},
  asset: null,
  entityType: null,
  instructionData: [],
  totalCount: 0,
};

export default SpecialInstructions;
