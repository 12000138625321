/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import Sockette from 'sockette';

import { isOfflineMode } from '@ge/shared/services/auth';
//import { getSession, isOfflineMode } from '@ge/shared/services/auth';

let ws = null;

const SocketListener = () => {
  const [jwt] = useState(null);

  // TODO: Use the IAC identity token to initiate the websocket.
  // useEffect(() => {
  //   getSession().then(([, jwt]) => setJwt(jwt));
  // }, []);

  useEffect(() => {
    if (jwt && !isOfflineMode) {
      const wssUrl = process.env.REACT_APP_DIGITAL_WIND_FARM_WEBSOCKET;
      if (wssUrl === 'wss') {
        console.error('Websocket url not configured.');
        return () => {};
      }
      console.info('Initializing web socket');
      ws = new Sockette(`${process.env.REACT_APP_DIGITAL_WIND_FARM_WEBSOCKET}?auth=${jwt}`, {
        // protocols: [could send something here],
        timeout: 5e3,
        maxAttempts: 2,
        onopen: (event) => console.log('connected:', event),
        onmessage: (event) => console.log('Received message!', event),
        onreconnect: (event) => console.log('Reconnecting...', event),
        onmaximum: (event) => console.log('Stop Attempting!', event),
        onclose: (event) => console.log('Closed!', event),
        onerror: (event) => console.log('Error:', event),
      });
    }
    return () => {
      if (ws) ws.close();
      ws = null;
    };
  }, [jwt]);

  return null;
};

export default SocketListener;
