import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon } from '@ge/components/icon';
import { elevations, typography } from '@ge/tokens';

export const FormSection = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.newTaskDialog.sectionBorder};
  margin-bottom: 15px;
  h4 {
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  label,
  .label {
    color: ${(props) => props.theme.newTaskDialog.label};
  }
`;

export const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  flex: 1 0;
  margin-left: -6px;

  .collapsible-panel-content {
    &.expanded {
      transition: max-height 0s ease;
      overflow: visible;
    }
    > div {
      margin: 4px 0 15px 5px;
    }
  }

  .title {
    border-bottom: 0;
    text-transform: uppercase;
  }
  svg {
    fill: ${(props) => props.theme.newTaskDialog.collapsibleIcon};
    padding-left: 0;
  }
`;

export const FormRow = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TaskTypeRow = styled(FormRow)`
  > div:first-of-type {
    flex-grow: 1;
    margin-right: 20px;
  }
  .option-w-icon {
    margin-left: 8px;
    text-transform: capitalize;
  }
`;

export const StyledDateContainer = styled.div`
  margin-right: 30px;
  position: relative;
  &:last-of-type {
    margin-right: 0;
  }
  .label {
    color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
    margin-bottom: 5px;
  }
  .date-picker {
    display: flex;
    align-items: center;
    input {
      border-radius: 3px;
      color: ${(props) => props.theme.entityDetails.tasks.details.inputColor};
      font-size: 12px;
      width: 110px;
    }
    .value {
      margin-right: 40px;
    }
    .task-dialog-date {
      position: absolute;
      bottom: 7px;
      right: -2px;
    }
  }
`;

export const ColumnTitle = styled.div`
  margin-left: 18px;
  width: 200px;
  h4,
  button {
    font-weight: ${typography.bold};
    text-transform: uppercase;
  }
  button {
    align-items: center;
    background: none;
    border: 0;
    color: ${(props) => props.theme.entityDetails.tasks.details.columnButtonColor};
    display: flex;
    font-size: 11px;
    margin-bottom: 10px;
    padding: 0 0 0 15px;
    position: relative;
    svg {
    }
    &:last-of-type {
      svg {
        left: 2px;
      }
    }
  }
`;

export const ColumnDetail = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.tasks.details.columnBorder};
  flex: 1;
  padding-bottom: 18px;
  margin-bottom: 18px;
  &.buttons {
    display: flex;
    button {
      &:nth-child(2) {
        margin-left: auto;
      }
      &:last-of-type {
        margin-left: 15px;
      }
    }
  }
`;

export const ReopenButton = styled.div`
  flex: 1;
  &.buttons {
    display: flex;
    button {
      margin-left: auto;
    }
  }
`;

export const ActionsDetail = styled(ColumnDetail)`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.tasks.details.columnBorder};
  flex: 1;
  padding-bottom: 18px;
  margin-bottom: 18px;
  &.actions {
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .actions-left {
      text-align: left;

      > * {
        + * {
          margin-left: 15px;
        }
      }
    }

    .actions-right {
      text-align: right;

      > * {
        + * {
          margin-left: 15px;
        }
      }
    }
  }
  &.actions.below {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;

    border-top: 1px solid ${(props) => props.theme.entityDetails.tasks.details.columnBorder};
    padding-top: 18px;
    margin-top: 18px;
    padding-bottom: 20px;
  }
`;

export const DisplayLabel = styled.div`
  color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
  font-weight: ${typography.medium};
  margin-bottom: 5px;
`;

export const DisplayField = styled.div`
  font-size: 14px;
  line-height: 18px;
  &.small {
    font-size: 12px;
  }
  &.read-only {
    margin-top: 10px;
    span {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .time {
    margin-left: 4px;
  }
  &.wordbreak {
    word-break: break-word;
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  margin-bottom: 15px;
  .field-wrapper {
    margin-right: 40px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  label {
    color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
  }
  &.title {
    > div {
      flex-grow: 1;
    }
  }
  &.task-type {
    > div:first-of-type {
      flex-grow: 1;
      margin-right: 20px;
    }
  }
  .option-w-icon {
    margin-left: 8px;
    text-transform: capitalize;
  }
  &.dates-row {
    position: relative;
    z-index: ${elevations.P2};
  }
  &.expected-parts,
  &.parts-consumed {
    .parts > div {
      margin-right: 10px;
    }
  }
  &.timing {
    .timing > div {
      margin-right: 30px;
      &:last-of-type {
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-right: 10px;
      }
    }
  }
`;

export const MultiRow = styled.div`
  display: flex;
  .expected-parts &,
  .parts-consumed & {
    > div {
      margin-right: 30px;
      &:last-of-type {
        margin-right: 0;
      }
      &:nth-child(1) {
        width: 70px;
      }
      &:nth-child(2) {
        width: 250px;
      }
      &:nth-child(3) {
        text-align: center;
        width: 30px;
      }
      &:nth-child(4) {
        text-align: right;
        width: 55px;
      }
    }
  }
  .timing & {
    > div {
      margin-right: 30px;
      &:last-of-type {
        margin-right: 0;
      }
      &:nth-child(1) {
        width: 90px;
      }
      &:nth-child(2) {
        width: 75px;
      }
      &:nth-child(3) {
        width: 75px;
      }
      &:nth-child(4) {
        width: 20px;
      }
    }
  }
`;

export const TaskActionButton = styled.button.attrs({ type: 'button' })`
  padding: 3px 3px 3px 0;
  display: block;
  color: ${(props) => props.theme.newTaskDialog.actionButtonText};
  font-family: Museo Sans;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;

  &:not(:last-of-type) {
    margin-bottom: 9px;
    margin-bottom: 9px;
  }

  span {
    text-transform: uppercase;
    padding-left: 6px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const ActionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.newTaskDialog.actionButtonText,
  size: 12,
}))``;

// Build a Select option
export const BuildOption = (value, text) => ({ value, label: text });
