import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { CountCell } from '@ge/components/charts';
import { arrayify } from '@ge/shared/util/general';
import { camelize } from '@ge/util/string-utils';

import AssetStateMenu from './asset-state-menu';

const StyledCountCell = styled(CountCell)`
  cursor: ${(props) => (!props.count ? 'not-allowed' : 'inherit')};
  opacity: ${(props) => (!props.count ? 0.15 : 1)};
`;

const AssetStateCount = ({ color, row, assetState, count, menuTitle, className }) => {
  const menuRef = useRef(null);
  const anchorEl = useRef(null);

  // Asset state accessors
  const { fetchAssetsBySiteAndState } = useStoreActions((actions) => actions.assets);
  const { getAssetsBySiteAndState } = useStoreState((state) => state.assets);

  // Menu state
  const [menuShown, setMenuShown] = useState(null);

  // Store the relevant assets when menu is shown
  const menuAssets = useMemo(() => {
    if (!menuShown || !row.site.id || !assetState) {
      return [];
    }

    return getAssetsBySiteAndState(row.site.id, assetState);
  }, [getAssetsBySiteAndState, row.site.id, assetState, menuShown]);

  // Event handler for showing menu
  const showAssetsMenu = useCallback(() => {
    const counts = row.assets ?? {};
    const stateArray = arrayify(assetState);
    const stateCount = stateArray.reduce((theCount, aState) => {
      theCount += counts[camelize(aState)];
      return theCount;
    }, 0);

    if (!stateCount) {
      return;
    }

    fetchAssetsBySiteAndState({ siteId: row.site.id, state: stateArray });

    setMenuShown(true);
  }, [fetchAssetsBySiteAndState, row.site, row.assets, assetState]);

  // Event handler for hiding menu
  const destroyAssetMenu = () => {
    setMenuShown(false);
  };

  return (
    <StyledCountCell
      color={color}
      count={count}
      onMouseEnter={showAssetsMenu}
      onMouseLeave={destroyAssetMenu}
      ref={anchorEl}
      className={className}
    >
      {count || '—'}

      <AssetStateMenu
        open={menuShown}
        assets={menuAssets}
        onClose={destroyAssetMenu}
        site={row.site}
        ref={menuRef}
        anchorEl={anchorEl && anchorEl.current}
        title={menuTitle}
      />
    </StyledCountCell>
  );
};

AssetStateCount.propTypes = {
  color: PropTypes.string,
  row: PropTypes.instanceOf(Object),
  assetState: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  count: PropTypes.number,
  menuTitle: PropTypes.string,
  className: PropTypes.string,
};

AssetStateCount.defaultProps = {
  color: null,
  row: null,
  assetState: null,
  count: null,
  menuTitle: null,
  className: null,
};

export { AssetStateCount };
