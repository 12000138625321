import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { action, computed, thunk } from 'easy-peasy';
import memoize from 'memoizerific';
import path from 'ramda/src/path';

import { TaskStatus } from '@ge/models';
import { hydrateTask } from '@ge/shared/state/task-model';
import { groupById } from '@ge/util';

import { fetchBacklogTasks, fetchScheduledTasks, fetchTechniciansWithHours } from '../services';

dayjs.extend(isBetween);

// Define initial state
const defaultSiteMgrState = {
  taskGroups: {},
  technicianStatuses: {},
};

// Actions
const siteMgrActions = {
  /**
   * Reset state to defaults
   */

  resetSiteManager: action((state) => {
    /* eslint-disable-next-line no-unused-vars */
    state = Object.assign(state, defaultSiteMgrState);
  }),

  fetchScheduledTasks: thunk(async (actions, payload, { fail, getStoreActions }) => {
    try {
      const {
        data,
        entities: { tasks, technicians, crews },
      } = await fetchScheduledTasks(payload);

      getStoreActions().tasks.updateTasks(tasks);
      getStoreActions().technicians.updateCrews(crews);
      getStoreActions().technicians.updateTechnicians(technicians);
      actions.updateTaskGroups(data);
    } catch (err) {
      fail(err);
    }
  }),

  fetchBacklogTasks: thunk(async (_, payload, { fail, getStoreActions }) => {
    try {
      const { data } = await fetchBacklogTasks(payload);

      getStoreActions().tasks.updateTasks(
        data.reduce((acc, task) => {
          acc[task.id] = task;
          return acc;
        }, {}),
      );
    } catch (err) {
      fail(err);
    }
  }),

  fetchTechniciansWithStatus: thunk(async (actions, _, { fail, getStoreActions }) => {
    try {
      const {
        data,
        entities: { technicians, crews },
      } = await fetchTechniciansWithHours();
      getStoreActions().technicians.updateTechnicians(technicians);
      getStoreActions().technicians.updateCrews(crews);
      actions.updateTechnicianStatuses(data);
    } catch (err) {
      fail(err);
    }
  }),

  dismissToBacklog: thunk(async (actions, { groupId, taskId }, { getStoreActions, getState }) => {
    if (groupId) {
      // TODO - API call to dismiss task group
      const taskIds = getState().taskGroups[groupId].tasks;
      getStoreActions().tasks.markTasksUnscheduled(taskIds);
      actions.deleteTaskGroup(groupId);
    } else {
      // TODO - API call to dismiss task
      throw new Error(`Not implemented. Cannot dismiss task ${taskId}`);
    }
  }),

  moveTaskToGroup: thunk(async (actions, { taskId, groupId }, { getStoreActions }) => {
    // TODO - API call to add task to group
    getStoreActions().tasks.updateTaskGroup({ taskId, groupId });
    actions.moveTask({ taskId, groupId });
  }),

  scheduleBacklogTask: thunk(
    async (
      actions,
      { taskId, crewId, dropIndex, dateRange, bundle },
      { getStoreActions, getState, getStoreState },
    ) => {
      // TODO - API call to schedule task
      const task = getStoreState().tasks.tasks[taskId];
      if (!task) throw new Error('Task does not exist');
      // Make sure task group id is unique
      task.group.id = task.id;
      const mockTaskGroup = {
        id: task.group.id,
        travelTime: Math.floor(Math.random() * Math.floor(5) + 1) * 10,
        crew: { id: crewId },
        scheduled: true,
        specialist: false,
      };
      if (bundle && task.asset) {
        const bundledTasks = Object.values(getStoreState().tasks.tasks).filter(
          (t) => !t.scheduled && t.asset && t.asset.id === task.asset.id,
        );
        mockTaskGroup.tasks = [...bundledTasks.map((t) => t.id)];
        mockTaskGroup.estimate = bundledTasks.reduce((acc, item) => acc + item.estimate, 0);
      } else {
        mockTaskGroup.tasks = [task.id];
        mockTaskGroup.estimate = task.estimate;
      }

      const crewTaskDates = Object.values(getState().taskGroups)
        .filter(
          (taskGroup) =>
            taskGroup.scheduledStart &&
            taskGroup.crew.id === crewId &&
            dayjs(taskGroup.scheduledStart).isBetween(dateRange.start, dateRange.end, null, '[]'),
        )
        .map((tg) => tg.scheduledStart)
        .sort((a, b) => new Date(a) - new Date(b));

      mockTaskGroup.scheduledStart = mockScheduledStart(crewTaskDates, dropIndex, dateRange);

      getStoreActions().tasks.markTasksScheduled(mockTaskGroup.tasks);
      // temp to be sure task group (from above) is updated
      getStoreActions().tasks.updateTasks({ [task.id]: task });
      actions.addTaskGroup(mockTaskGroup);
    },
  ),

  rescheduleTaskGroup: thunk(
    async (actions, { id, crewId, dropIndex, dateRange }, { getState }) => {
      // TODO - API call to reschedule task
      const taskGroup = getState().taskGroups[id];
      if (!taskGroup) throw new Error('Task group does not exist');

      const crew = Object.values(getState().taskGroups).find(
        (taskGroup) => taskGroup.crew.id === crewId,
      ).crew;
      const crewTaskDates = Object.values(getState().taskGroups)
        .filter((taskGroup) => taskGroup.crew.id === crewId)
        .filter(
          (taskGroup) =>
            taskGroup.scheduledStart &&
            dayjs(taskGroup.scheduledStart).isBetween(dateRange.start, dateRange.end, null, '[]'),
        )
        .map((tg) => tg.scheduledStart)
        .sort((a, b) => new Date(a) - new Date(b));

      if (crewId === crew.id) {
        // Same lane
        const filteredDates = crewTaskDates.filter((d) => d !== taskGroup.scheduledStart);
        taskGroup.scheduledStart = mockScheduledStart(filteredDates, dropIndex, dateRange);
      } else {
        taskGroup.scheduledStart = mockScheduledStart(crewTaskDates, dropIndex, dateRange);
      }
      taskGroup.crew = { ...crew };

      actions.editTaskGroup(taskGroup);
    },
  ),

  removeCrewFromTasks: action((state, { crewId }) => {
    const affectedGroupIds = Object.values(state.taskGroups)
      .filter((tg) => tg.crew.id === crewId)
      .map((tg) => tg.id);
    // Temporarily delete the task groups to not blow up everything
    affectedGroupIds.forEach((groupId) => {
      delete state.taskGroups[groupId];
    });
  }),

  editTaskGroup: action((state, taskGroup) => {
    state.taskGroups = {
      ...state.taskGroups,
      [taskGroup.id]: taskGroup,
    };
  }),

  addTaskGroup: action((state, payload) => {
    state.taskGroups = { ...state.taskGroups, [payload.id]: payload };
  }),

  deleteTaskGroup: action((state, payload) => {
    delete state.taskGroups[payload];
  }),

  updateTaskGroups: action((state, payload) => {
    const byGroupId = payload.reduce((acc, group) => {
      acc[group.id] = group;
      return acc;
    }, {});
    state.taskGroups = { ...state.taskGroups, ...byGroupId };
  }),

  moveTask: action((state, { taskId, groupId }) => {
    const currentGroup = Object.values(state.taskGroups).find((group) =>
      group.tasks.includes(taskId),
    );
    if (currentGroup) {
      state.taskGroups[currentGroup.id].tasks = state.taskGroups[currentGroup.id].tasks.filter(
        (id) => id !== taskId,
      );
    }
    state.taskGroups[groupId].tasks = [...new Set([...state.taskGroups[groupId].tasks, taskId])];
  }),

  updateTechnicianStatuses: action((state, payload) => {
    const byId = payload.reduce((acc, item) => {
      acc[item.technician.id] = item;
      return acc;
    }, {});
    state.technicianStatuses = { ...state.technicianStatuses, ...byId };
  }),
};

// Listeners
const siteMgrListeners = {};

// Computed values
const siteMgrComputed = {
  hydratedTaskBacklog: computed(
    [
      (_, storeState) => storeState.tasks.tasks,
      (_, storeState) => storeState.view.currentView,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.assets.assets,
    ],
    (tasks, currentView, sites, assets) => {
      if (!Object.keys(tasks).length || !Object.keys(sites).length || !Object.keys(assets).length) {
        return [];
      }

      const viewFilter = (task) => {
        const viewSiteIds = currentView.sites.map((s) => s.id);
        if (viewSiteIds.length) {
          return viewSiteIds.includes(task.site.id);
        }
        return () => true;
      };

      return Object.values(tasks)
        .filter((task) =>
          [TaskStatus.SCHEDULED, TaskStatus.UNSCHEDULED].includes(task.status?.toLowerCase()),
        )
        .filter(viewFilter)
        .map((task) => hydrateTask(task, { assets, sites }))
        .sort((a, b) => {
          const siteCompare = a.site?.name?.localeCompare(b.site?.name);
          if (siteCompare === 0) {
            if (!a.asset) return 1;
            if (!b.asset) return -1;
            const assetCompare = a.asset?.name?.localeCompare(b.asset?.name);
            if (assetCompare === 0) {
              return mapPriority(a.priority) - mapPriority(b.priority);
            }
            return assetCompare;
          }
          return siteCompare;
        });
    },
  ),

  getAutomatedTaskGroups: computed(
    [
      (state) => state.taskGroups,
      (_, storeState) => storeState.tasks.tasks,
      (_, storeState) => storeState.view.currentView,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.assets.assets,
      (_, storeState) => storeState.technicians.crews,
      (_, storeState) => storeState.technicians.technicians,
    ],
    (taskGroups, tasks, currentView, sites, assets, crews, technicians) =>
      memoize(2)((range) => {
        if (!Object.keys(taskGroups).length) return [];

        const viewSiteIds = currentView.sites.map((s) => s.id);
        const { start, end } = range;

        const filteredTaskGroups = Object.values(taskGroups).filter(
          (taskGroup) =>
            taskGroup.scheduledStart &&
            !taskGroup.scheduled &&
            taskGroup.tasks.length > 1 &&
            dayjs(taskGroup.scheduledStart).isBetween(start, end, null, '[]'),
        );

        const hydratedTaskGroups = hydrateTaskGroups(filteredTaskGroups, {
          assets,
          sites,
          crews,
          technicians,
          tasks,
        });

        const groupedTechsByCrew = groupById(Object.values(technicians), ['crew', 'id']);

        return hydratedTaskGroups
          .map((group) => ({
            ...group,
            technicians: groupedTechsByCrew[group.crew.id],
          }))
          .filter(getViewFilter(viewSiteIds, ['tasks']))
          .sort((a, b) => new Date(a.scheduledStart) - new Date(b.scheduledStart));
      }),
  ),
  techniciansWithStatus: computed(
    [(state) => state.technicianStatuses, (_, storeState) => storeState.technicians.technicians],
    (technicianStatuses, technicians) => {
      if (!Object.keys(technicianStatuses).length || !Object.keys(technicians).length) return [];

      return Object.entries(technicianStatuses)
        .map(([id, statusItem]) => ({
          ...statusItem,
          technician: technicians[id],
        }))
        .sort((a, b) => a.technician?.last?.localeCompare(b.technician.last));
    },
  ),
  techniciansByCrew: computed(
    [
      (state) => state.technicianStatuses,
      (_, storeState) => storeState.technicians.technicians,
      (_, storeState) => storeState.technicians.crews,
    ],
    (technicianStatuses, technicians, crews) => {
      if (!Object.keys(technicianStatuses).length) return [];

      return Object.values(crews)
        .map((crew) => ({
          crew,
          technicians: Object.values(technicians)
            .filter((tech) => tech.crew && tech.crew.id === crew.id)
            .map((tech) => ({
              ...technicianStatuses[tech.id],
              technician: tech,
            }))
            .sort((a, b) => a.technician?.last?.localeCompare(b.technician.last)),
        }))
        .sort((a, b) => a.crew?.name?.localeCompare(b.crew.name));
    },
  ),

  getScheduledTasksByCrew: computed(
    [
      (state) => state.taskGroups,
      (_, storeState) => storeState.tasks.tasks,
      (_, storeState) => storeState.view.currentView,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.assets.assets,
      (_, storeState) => storeState.technicians.crews,
      (_, storeState) => storeState.technicians.technicians,
    ],
    (taskGroups, tasks, currentView, sites, assets, crews, technicians) =>
      memoize(1)((range) => {
        if (!Object.keys(taskGroups).length) return [];

        const viewSiteIds = currentView.sites.map((s) => s.id);

        const { start, end } = range;

        const filteredTaskGroups = Object.values(taskGroups).filter(
          (taskGroup) =>
            taskGroup.scheduledStart &&
            dayjs(taskGroup.scheduledStart).isBetween(start, end, null, '[]'),
        );

        const hydratedTaskGroups = hydrateTaskGroups(filteredTaskGroups, {
          assets,
          sites,
          crews,
          technicians,
          tasks,
        }).filter(getViewFilter(viewSiteIds, ['tasks']));

        const groupedTechsByCrew = groupById(Object.values(technicians), ['crew', 'id']);
        const initCrews = Object.keys(groupedTechsByCrew).reduce((acc, crewId) => {
          acc[crewId] = [];
          return acc;
        }, {});
        const groupedTasksByCrew = {
          ...initCrews,
          ...groupById(hydratedTaskGroups, ['crew', 'id']),
        };

        return Object.entries(groupedTasksByCrew)
          .map(([crewId, crewTaskGroups]) => {
            const crewTechnicians = groupedTechsByCrew[crewId].map((tech) => {
              const { crew, ...rest } = tech;
              return rest;
            });
            return {
              crew: crews[crewId],
              technicians: crewTechnicians,
              taskGroups: crewTaskGroups.sort(
                (a, b) => new Date(a.scheduledStart) - new Date(b.scheduledStart),
              ),
            };
          })
          .sort((a, b) => {
            if (a.taskGroups.length && !b.taskGroups.length) return -1;
            if (b.taskGroups.length && !a.taskGroups.length) return 1;
            return a.crew?.name?.localeCompare(b.crew.name);
          });
      }),
  ),
};

const getViewFilter = (viewSiteIds, dataPath) => (filterItems) => {
  if (viewSiteIds.length) {
    return path(dataPath, filterItems).some((item) => viewSiteIds.includes(item.site.id));
  }
  return true;
};

const hydrateTaskGroups = (taskGroups, { assets, sites, crews, technicians, tasks }) =>
  taskGroups.map((taskGroup) => {
    const specialist = taskGroup.specialist && technicians[taskGroup.specialist.id];
    const hydratedTasks = taskGroup.tasks
      .map((taskId) => tasks[taskId])
      .map((task) => hydrateTask(task, { assets, sites, technicians }));
    return {
      ...taskGroup,
      crew: crews[taskGroup.crew.id],
      tasks: hydratedTasks,
      specialist,
    };
  });

const mapPriority = (priority) => {
  switch (priority) {
    case 'immediate':
      return 10;
    case 'high':
      return 5;
    case 'medium':
      return 3;
    case 'low':
      return 1;
    default:
      return 0;
  }
};

const mockScheduledStart = (crewTaskDates, dropIndex, dateRange) => {
  if (crewTaskDates.length < dropIndex) {
    return dayjs(dateRange.end).subtract(1, 'minute').toISOString();
  } else {
    return dayjs(crewTaskDates[dropIndex]).subtract(1, 'minute').toISOString();
  }
};

// Compile the view store object for export
const siteManagerModel = {
  ...defaultSiteMgrState,
  ...siteMgrActions,
  ...siteMgrComputed,
  ...siteMgrListeners,
};

export default siteManagerModel;
