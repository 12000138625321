import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useCallback, useContext, useMemo } from 'react';

import { getFilterDateRange } from '@ge/feat-analyze/util';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { DateTimeFormats, DateRange } from '@ge/models/constants';
import { AttributeGroups, EntityType } from '@ge/models/constants';

// NOTE: currently scope can only be a single site or service group
// a service group can contain multiple sites, and a site can be in multiple service groups
// so we return both as arrays

// NOTE: might need to rethink what goes into report state and what is provided by this hook
// the general idea is to include template/report contract values only in the state to avoid increasing complexity of state mgmt
// this hook takes the scope parameter values persisted in state and enriches them with add'l info needed by hooks/components on the page

const TIME_ZONE = 'UTC';
const DATE_FORMAT = DateTimeFormats.FILTER_CUSTOM_MONTH_CURRENT;
const placeholderText = '--';

const renderDate = ({
  date,
  format = DATE_FORMAT,
  placeholder = placeholderText,
  timezone = TIME_ZONE,
}) => {
  let rendered = placeholder;

  if (date) {
    rendered = dayjs(date);

    if (timezone) {
      rendered = rendered.tz(timezone);
    }

    rendered = rendered.format(format);
  }

  return rendered;
};

export const useReportScope = (overrides) => {
  const { reportState = {} } = useContext(ReportsContext);

  const { createdTime, scope, scopeType } = reportState;
  let { customEndDate, customStartDate, timeRange } = reportState;

  // handle overrides if provided
  if (overrides?.endDate && overrides?.startDate) {
    customEndDate = overrides.endDate;
    customStartDate = overrides.startDate;
    timeRange = DateRange.CUSTOM;
  }

  // store
  // not destructuring these because have had issues with change detection doing so
  const serviceGroups = useStoreState((state) => state.sites.serviceGroups);
  const sites = useStoreState((state) => state.sites.sites);
  const getViewSiteIds = useStoreState((state) => state.view.getViewSiteIds);

  const viewSitesIds = getViewSiteIds();

  // TODO: look into getting back full date object as well as formatted date
  // this would be handy for the taks service which takes a full datetime for start/end dates
  const handleRenderDates = useCallback(
    ({ timezone }) => {
      const {
        startDate: { entityTimezone: startDate },
        endDate: { entityTimezone: endDate },
      } = getFilterDateRange({
        range: timeRange,
        entityTimezone: timezone,
        dateFormat: DATE_FORMAT,
      });

      return { endDate, startDate };
    },
    [timeRange],
  );

  const handleRenderCustomDates = useCallback(
    ({ timezone }) => {
      const startDate = renderDate({ date: customStartDate, timezone });
      const endDate = renderDate({ date: customEndDate, timezone });

      return { endDate, startDate };
    },
    [customEndDate, customStartDate],
  );

  const handleDateRender = useCallback(
    ({ timezone = TIME_ZONE }) => {
      if (timeRange && timeRange === DateRange.CUSTOM) {
        return handleRenderCustomDates({ timezone });
      }

      return handleRenderDates({ timezone });
    },
    [timeRange, handleRenderCustomDates, handleRenderDates],
  );

  const reportScope = useMemo(() => {
    const entityScope = {
      [AttributeGroups.SERVICE_GROUP]: {
        entityType: EntityType.SERVICE_GROUP,
        serviceGroupIds: [scope],
        siteIds: serviceGroups[scope]?.sites
          ?.filter(({ id }) => viewSitesIds.includes(id))
          .map(({ id }) => id),
      },
      [AttributeGroups.SITES]: {
        entityType: EntityType.SITE,
        serviceGroupIds: sites[scope]?.serviceGroups,
        siteIds: [scope],
      },
    }[scopeType];

    const { siteIds } = entityScope ?? {};
    // per discussion with Ram, current mvp policy is to just grab first site and use that timezone for date ranges
    // for practical purposes, scope is almost always for a single site
    // but if the need arises to revist tihs approach, can do so here
    // unless we need to handle multiple timezones in the report :grimacing:
    const timezoneSiteId = siteIds?.[0];
    let timezone = TIME_ZONE;

    if (timezoneSiteId) {
      const site = sites[timezoneSiteId];

      if (site?.timezone) {
        timezone = site.timezone;
      }
    }

    const { endDate, startDate } = handleDateRender({ timezone });

    return {
      ...entityScope,
      endDate,
      startDate,
      timezone,
    };
  }, [handleDateRender, scope, scopeType, serviceGroups, sites, viewSitesIds]);

  return {
    ...reportScope,
    createdTimestamp: createdTime?.unix(),
  };
};

export const useReportScopeWithAssets = () => {
  const reportScope = useReportScope();
  const { siteIds } = reportScope;

  // store
  const getAssetsBySiteId = useStoreState((state) => state.assets.getAssetsBySiteId);

  const assets = useMemo(() => {
    const assetLookup = siteIds?.reduce((lookup, siteId) => {
      const siteAssets = getAssetsBySiteId(siteId);

      siteAssets?.forEach((siteAsset) => {
        lookup[siteAsset?.id] = siteAsset;
      });

      return lookup;
    }, {});

    return Object.values(assetLookup ?? {});
  }, [getAssetsBySiteId, siteIds]);

  return {
    ...reportScope,
    assets,
  };
};
