import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileThumbnail } from '@ge/components/file-thumbnail';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { placements, Tooltip } from '@ge/components/tooltip';
import {
  DateTimeFormats,
  NotesScope,
  Capability,
  TaskNotes,
  PermissionScope,
} from '@ge/models/constants';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useAuth } from '@ge/shared/data-hooks';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { ActionKeys, NotesMenuItems } from '@ge/shared/models/actions-menu-items';
import { getNotesDisabledActions, getNotesDisabledEyeActions } from '@ge/shared/util/general';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

import viewing_off from '../viewing_off.svg';
import viewing_on from '../viewing_on.svg';

const StyledNotesAttachmentsTable = styled.div`
  .header {
    display: flex;
    align-items: flex-end;
    margin-left: 175px;
    justify-content: flex-end;
  }
  .note-attachment {
    &:last-of-type {
      margin-bottom: 50px;
      .row > div {
        &:nth-of-type(2),
        &:last-of-type {
          border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
        }
      }
    }
    .row {
      > div {
        padding: 25px 0;
        &:nth-of-type(2),
        &:last-of-type {
          border-top: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
        }
      }
    }
  }
  .row {
    display: flex;
    align-items: stretch;
    margin: 0 0 0 175px;
    &:first-of-type {
      margin: 0px;
    }
    &:nth-of-type(2) {
      div {
        margin-top: 0;
      }
    }
  }
  .actions-header {
    color: ${(props) => props.theme.entityDetails.notes.tableCopyHeader};
    font-weight: ${typography.weight.medium};
    padding: 8px 0;
  }
  .actions-header,
  .actions {
    width: 75px;
    text-align: center;
  }
  .actions {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    .view-icon {
      line-height: 22px;
      img {
        vertical-align: middle;
      }
    }
  }
  .cursorPointer {
    cursor: pointer;
  }
  .cursorNotAllowed {
    cursor: not-allowed;
  }
  .metadata-container {
    width: 150px;
  }
  .date-created,
  .date-updated,
  .contributed-by {
    span {
      display: block;
      &:first-of-type {
        color: ${(props) => props.theme.entityDetails.notes.tableCopyHeader};
        font-weight: ${typography.weight.medium};
        line-height: 13px;
        padding-top: 0;
      }
      &:last-of-type {
        font-weight: ${typography.weight.medium};
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
      }
    }
  }
  .date-updated span:last-of-type {
    margin-bottom: 0 !important;
  }
  .notes-body {
    overflow: hidden;
    width: 495px;
    span {
      display: block;
      &.notes-content {
        font-weight: ${typography.weight.regular};
        color: ${(props) => props.theme.entityDetails.notes.entityDescription};
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.33px;
      }
    }
    .status {
      float: right;
    }
    .note-scrolling-container-wrapper {
      margin-top: 15px;
      display: flex;
      height: 125px;
      margin-top: 10px;
      .notes-attachments-container {
        display: flex;
        height: 125px;
        .note-attachment {
          margin-right: 10px;
          .attachment-thumbnail img {
            width: 125px;
            height: 93px;
          }
          .attachment-name {
            color: ${(props) => props.theme.entityDetails.notes.tableCopyHeader};
            font-weight: ${typography.weight.medium};
            line-height: 13px;
          }
        }
      }
    }
  }
`;

const LoaderContainer = styled.div`
  min-height: 200px;
`;

export const TaskNotesAttachmentsTable = ({
  notesAttachments,
  editNoteAction,
  toggleHandler,
  deleteNoteAction,
  isLoading,
}) => {
  const { t } = useTranslation(['entity-details']);
  const containerRef = useRef(null);

  // data hooks
  const getAuthorizedMenuItems = useActionsMenu({ menuItems: NotesMenuItems });

  const { isAuthorized } = useAuth();
  const isNotesAdmin = isAuthorized({
    capabilities: [{ capability: Capability.NOTES_ADMIN, scopes: [PermissionScope.ADMIN] }],
  });

  const actionToggleHandler = (action, note) => {
    if (getNotesDisabledEyeActions(note, isNotesAdmin)) {
      toggleHandler(action, note);
    }
  };

  const actionMenuHandler = (action, note) => {
    if (action === ActionKeys.NOTE_EDIT) {
      editNoteAction(note);
    } else {
      deleteNoteAction(note);
    }
  };

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <StyledNotesAttachmentsTable>
      <div className="header">
        <div className="actions-header">
          <AuthRender
            capability={Capability.NOTES_AND_ATTACHMENTS}
            edit
            delete
            description="Notes and attachments table action menu"
            siteLevel={false}
          >
            {t('notes.actions', 'Actions')}
          </AuthRender>
        </div>
      </div>
      {/* #TODO: This has to be a virtual table */}
      {notesAttachments.map((note) => (
        <div key={note.id} className="note-attachment">
          <div className="row">
            <div className="metadata-container">
              <div className="contributed-by">
                <span>{t('notes.contributed_by', 'Contributed By')}</span>
                <span>{note.updatedBy === TaskNotes.DPOD_SSO ? 'DPOD' : note.updatedBy}</span>
              </div>
              <div className="date-created">
                <span>{t('notes.created_on', 'Created On')}</span>
                <span>{dayjs(note.creationDate).format(DateTimeFormats.ISSUE_DETAIL_DATE)}</span>
              </div>
              <div className="date-updated">
                <span>{t('notes.last_updated', 'Last Updated')}</span>
                <span>{dayjs(note.updateDate).format(DateTimeFormats.ISSUE_DETAIL_DATE)}</span>
              </div>
            </div>
            <div className="notes-body">
              <span className="notes-content">{note.note}</span>
              {note.attachments?.length > 0 && (
                <span className="note-scrolling-container-wrapper">
                  <ScrollingContainer>
                    <span className="notes-attachments-container">
                      {note.attachments.map((attachment) => (
                        <div key={attachment.file} className="note-attachment">
                          <div className="attachment-thumbnail">
                            <FileThumbnail
                              src={attachment.file}
                              ext={`.${attachment.contentType.split('/')[1]}`}
                              fileName={attachment.fileName}
                              download
                            />
                          </div>
                          <div className="attachment-name">{attachment.fileName}</div>
                        </div>
                      ))}
                    </span>
                  </ScrollingContainer>
                </span>
              )}
            </div>
            <AuthRender
              capability={Capability.NOTES_AND_ATTACHMENTS}
              edit
              delete
              description="Notes and attachments table action menu"
              siteLevel={false}
            >
              <div className="actions">
                <ActionsMenu
                  containerRef={containerRef}
                  entity={note}
                  menuItems={getAuthorizedMenuItems([note])}
                  menuIconSmall
                  onAction={({ type }) => actionMenuHandler(type, note)}
                  disabledActions={getNotesDisabledActions(note, isNotesAdmin)}
                />
                <AuthRender capability={Capability.INTERNAL_ACCESS} edit siteLevel={false}>
                  <Tooltip
                    title={
                      note.scope === NotesScope.EXTERNAL
                        ? t('notes.external_viewing_on', 'External viewing on')
                        : t('notes.external_viewing_off', 'External viewing off')
                    }
                    placement={placements.TOP}
                    zIndex={elevations.P20}
                  >
                    <span className="view-icon">
                      {note.scope === NotesScope.EXTERNAL ? (
                        <img
                          src={viewing_on}
                          alt="Viewing On"
                          className={
                            getNotesDisabledEyeActions(note, isNotesAdmin)
                              ? 'cursorPointer'
                              : 'cursorNotAllowed'
                          }
                          onClick={() => actionToggleHandler(NotesScope.INTERNAL, note)}
                        />
                      ) : (
                        <img
                          src={viewing_off}
                          alt="Viewing Off"
                          className={
                            getNotesDisabledEyeActions(note, isNotesAdmin)
                              ? 'cursorPointer'
                              : 'cursorNotAllowed'
                          }
                          onClick={() => actionToggleHandler(NotesScope.EXTERNAL, note)}
                        />
                      )}
                    </span>
                  </Tooltip>
                </AuthRender>
              </div>
            </AuthRender>
          </div>
        </div>
      ))}
    </StyledNotesAttachmentsTable>
  );
};

TaskNotesAttachmentsTable.propTypes = {
  notesAttachments: PropTypes.instanceOf(Array).isRequired,
  editNoteAction: PropTypes.func.isRequired,
  toggleHandler: PropTypes.func,
  editNoteAndSI: PropTypes.func,
  deleteNoteAction: PropTypes.func,
  isLoading: PropTypes.bool,
  entity: PropTypes.instanceOf(Object).isRequired,
};
