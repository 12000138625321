/* eslint-disable react/prop-types */ //Because forwareRef is not supported with our version of eslint. It messes up prop-types.

import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { handleKpiError } from '@ge/feat-analyze/util/kpi';
import { AnalyzeLocators } from '@ge/models/data-locators';

import useAssetPowerCurve from '../../data-hooks/use-asset-power-curve';
import { getFormatedDateTime } from '../../util';

import { DashboardChartTitle } from './dashboard-chart-title';
import { DashboardTitle } from './dashboard-shared';
// can make this more dynamic/configurable if needed
const TOGGLE_TYPES = [
  { id: ChartType.SCATTER, type: ChartType.SCATTER },
  // { id: ChartType.SPLINE, type: ChartType.SPLINE },
];

const PowerCurveDataType = {
  CONTRACT: 'contract',
  REGRESSION: 'regression',
};

const getTimeSeriesTooltipStyle = ({ theme }) => ({
  container: `
    display: grid;
    grid-template: auto / 1fr auto;
    grid-column-gap: 10px;
    font-size: 14px;
    line-height: 18px;
  `,
  heading: `
    grid-area: 1 / 1 / span 1 / span 2;
    border-bottom: 1px solid ${theme.newTaskDialog.sectionBorder};
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-size: 10px;
    color: ${theme.charts.tooltipColor};
  `,
  kpi: `
    font-size: 12px;
    color: ${theme.kpiChart.tooltipSecondaryColor};
  `,
  kpiUnits: `
    color: ${theme.kpiChart.tooltipSecondaryColor};
    font-size: 11px;
  `,
  sup: `line-height: 0;`,
});

const getTooltipText = ({ name, referenceAirDensity, t, type, date, timezone, x, y, classes }) => {
  const { container, heading, kpi, kpiUnits, sup } = classes;
  const xLabel = t('power_curve.wind_speed');
  const referenceAirDensityLabel = t('power_curve.air_density_title');
  const referenceAirDensityUnits = `kg/m<sup style="${sup}">3</sup>`;
  const xUnit = t('power_curve.wind_speed_units');
  const yUnit = t('power_curve.power_units');

  const isContract = type === PowerCurveDataType.CONTRACT;

  let title = '',
    yLabel = '';
  if (isContract) {
    title = t('power_curve.contractual_kpi_title');
    yLabel = t('power_curve.active_power');
  } else if (type === 'regression') {
    title = t('power_curve.learned_kpi_title');
    yLabel = t('power_curve.learned_power');
  } else {
    title = getFormatedDateTime(date, timezone);
    yLabel = t('power_curve.active_power');
  }
  return `
    <div style="${container}">
      <h4 style="${heading}">${title}</h4>
      <span style="${kpi}">Turbine: </span><span style="${kpiUnits}">${name || ''}</span>
      <span style="${kpi}">${xLabel}: </span><span style="${kpiUnits}">${x} ${xUnit}</span>
      <span style="${kpi}">${yLabel}: </span><span style="${kpiUnits}">${y} ${yUnit}</span>
      ${
        referenceAirDensity && isContract
          ? `<span style="${kpi}">${referenceAirDensityLabel}: </span><span style="${kpiUnits}">${referenceAirDensity} ${referenceAirDensityUnits}</span>`
          : ''
      }
    </div>
  `;
};

const getPowerCurveSeries = ({ data = [], timezone, t, theme, currentType }) => {
  const classes = getTimeSeriesTooltipStyle({ theme });
  let series = [];

  if (ChartType.SCATTER === currentType) {
    series = data.map(({ entity: { id, name }, data: powerCurve, contract }) => {
      const tooltipPointFormatter = ({ x, y, date }) =>
        getTooltipText({
          name,
          referenceAirDensity: contract?.referenceAirDensity,
          date,
          timezone,
          x,
          y,
          t,
          classes,
        });

      return {
        id: `data_${id}`,
        color: theme.analyze.powerCurveChart.powerCurveColor,
        data: powerCurve,
        tooltipPointFormatter,
        type: ChartType.SCATTER,
        boostThreshold: 3000, // Boost when there are more than 3000 points in the series.
      };
    });

    // add in contract lines if we have data
    const contractSeries = data.map(({ entity: { id, name }, contract }) => {
      const tooltipPointFormatter = ({ x, y, date }) =>
        getTooltipText({
          name,
          referenceAirDensity: contract?.referenceAirDensity,
          date,
          timezone,
          x,
          y,
          t,
          classes,
          type: 'contract',
        });

      return {
        id: `contract_${id}`,
        color: theme.analyze.powerCurveChart.referenceLineColor,
        data: contract?.points,
        tooltipPointFormatter,
        type: ChartType.SPLINE,
      };
    });

    series = [...series, ...contractSeries];
  } else {
    series = data.map(({ entity: { id, name }, regression }) => {
      return {
        id: `data_${id}`,
        color: theme.analyze.powerCurveChart.powerCurveColor,
        data: regression,
        tooltipHeader: `<h4>${name}</h4>`,
        type: ChartType.SPLINE,
      };
    });
  }
  return series;
};

export const DashboardPowerCurveChart = withTheme(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    ({ namespace, theme, hasTitle, height, noDataLabel, site, assetIds, dateRange }, ref) => {
      const { timezone } = site ?? {};
      // hooks
      const { ready, t } = useTranslation([namespace], {
        useSuspense: false,
      });
      const [currentType, setCurrentType] = useState(TOGGLE_TYPES[0].type);
      const { data, error, isLoading } = useAssetPowerCurve({
        assetIds,
        timezone,
        startDate: dateRange.startDate.entityTimezone,
        endDate: dateRange.endDate.entityTimezone,
      });
      // update the chart when data or type change
      const series = useMemo(
        () =>
          getPowerCurveSeries({
            t,
            theme,
            currentType,
            data,
            timezone,
          }),
        [data, timezone, currentType, t, theme],
      );

      const handleToggle = useCallback(({ type }) => setCurrentType(type), [setCurrentType]);

      // error handler
      const { noData, noDataTitle, noDataDescription } = useMemo(
        () => handleKpiError({ error, t }),
        [error, t],
      );

      if (!(data && ready)) {
        return null;
      }

      // translations
      const { title, xAxisTitle, yAxisTitle } = {
        title: t('power_curve.title'),
        xAxisTitle: t('power_curve.x_axis_title'),
        yAxisTitle: t('power_curve.y_axis_title'),
      };

      return (
        <div ref={ref}>
          {hasTitle && (
            <>
              <DashboardTitle data-testid={AnalyzeLocators.ANALYZE_SCATTER_CHART_TITLE}>
                {title}
              </DashboardTitle>
              <DashboardChartTitle chartTypes={TOGGLE_TYPES} onToggle={handleToggle} />
            </>
          )}
          <DataLoader
            isLoading={isLoading}
            type={currentType}
            noData={noData}
            noDataTitle={noDataTitle}
            noDataDescription={noDataDescription}
            renderCondition={!error}
            onRetry={null}
            height={`${height}px`}
          >
            <Chart
              colors={theme.analyze.powerCurveChart.colors}
              height={height}
              noDataLabel={noDataLabel}
              isLoading={isLoading}
              series={series}
              type={currentType}
              min={0}
              xMin={0}
              xAxisTitle={xAxisTitle}
              xAxisTickInterval={10}
              yAxisTitle={yAxisTitle}
              yAxisTickInterval={1}
              disableSideMargin
              spacingLeft={0}
              spacingRight={0}
              spacingBottom={0}
            />
          </DataLoader>
        </div>
      );
    },
  ),
);

DashboardPowerCurveChart.propTypes = {
  assetIds: PropTypes.arrayOf(PropTypes.string),
  site: PropTypes.object.isRequired,
  dateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Object),
    endDate: PropTypes.instanceOf(Object),
  }).isRequired,
  noDataLabel: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  hasTitle: PropTypes.bool,
  height: PropTypes.number,
};

DashboardPowerCurveChart.defaultProps = {
  assetIds: [],
  site: { timezone: 'UTC' },
  dateRange: {},
  noDataLabel: '',
  namespace: 'analyze.dashboard',
  isLoading: true,
  hasTitle: true,
  height: 286,
};

DashboardPowerCurveChart.displayName = 'DashboardPowerCurveChart';
