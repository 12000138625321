import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@ge/components/select';

import { CustomDropdownStyled } from './custom-dropdown-styled';
/**
 * CustomDropdown component
 */
export const CustomDropdown = (props) => {
  const { options, onChange, dropDownId, fileName } = props;
  const { t } = useTranslation([fileName]);
  const traslatedOptions = options?.map((currentObject) => {
    currentObject.label = t(currentObject.value, currentObject.label);
    return currentObject;
  });
  const [optionData, setOptionData] = useState(options[0]);
  const changeHandlar = useCallback(
    (value) => {
      setOptionData(value);
      onChange(dropDownId ? { id: dropDownId, option: value } : value);
    },
    [onChange, dropDownId],
  );
  useEffect(() => {
    setOptionData(traslatedOptions[0]);
  }, [options]);
  return (
    <CustomDropdownStyled {...props}>
      <Select
        primary
        value={[optionData]}
        onChange={(value) => changeHandlar(value)}
        options={traslatedOptions}
      />
    </CustomDropdownStyled>
  );
};

CustomDropdown.propTypes = {
  onChange: PropTypes.func,
  fileName: PropTypes.string,
  dropdownWidth: PropTypes.number,
  dropDownId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

CustomDropdown.defaultProps = {
  dropdownWidth: 15,
  fileName: '',
  dropDownId: null,
};
