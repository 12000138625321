import dayjs from 'dayjs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  QueryKey,
  Capability,
  PermissionScope,
  AlertStatusCodeMapping,
  MonitorDefs,
  AssetType,
} from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { getActiveSIsForAssets } from '@ge/shared/services/notes';
import { roundNumber } from '@ge/util';
import { sorter } from '@ge/util/metric-sorter';
import { useAssetNotCompletedTasks } from '@ge/web-client/src/app/hooks/use-asset-tasks';

import { fetchSiteMetrics } from '../services/site';

import { Config } from './config';
import { useSiteDetails } from './use-site-details';

// can move this out of here
const siteRealTimeInfoTransformer = ({
  assets = [],
  realTime = {},
  site = {},
  taskData = [],
  SIs = {},
}) => {
  return {
    siteData: realTime?.site,
    assetData: assets.map(({ id, name, make, model, controllerType, platform, aliases, type }) => {
      const { wind, alerts, power, state, prevState, connection, faultState, escalatedCases } =
        realTime?.assets?.[id] ?? {};
      const tasksData = taskData?.filter((task) => task?.asset.id === id);
      return {
        id,
        name,
        systemNumber: aliases?.find((item) => item.key == 'systemNumber')?.value,
        make,
        alerts: Array.isArray(alerts)
          ? alerts.map((alert) => ({
              ...alert,
              isExpired: alert.status === AlertStatusCodeMapping.EXPIRED,
              isGroup: !!alert?.groupDetails?.groupId,
            }))
          : [],
        escalatedCases,
        specialInstructions: SIs?.[id] ?? [],
        model,
        site,
        power: roundNumber(power?.value, 1),
        wind: roundNumber(wind?.value, 1),
        taskEscalation: tasksData?.length,
        tasksData: tasksData,
        state: state,
        fault_code: faultState?.value,
        prevState: prevState,
        connection: connection,
        duration: state?.timestamp
          ? dayjs()
              .subtract(state?.timestamp, 's')
              .valueOf()
          : 0,
        controllerType,
        platform,
        type,
      };
    }),
  };
};

const getFilteredAssetsBasedOnType = (assets, type) => {
  let filteredAssets = [];
  switch (type) {
    case MonitorDefs.WIND_SITES:
    case MonitorDefs.SITES:
      filteredAssets = assets?.filter(
        (asset) =>
          asset?.type === AssetType.WIND_TURBINE ||
          asset?.type === AssetType.SUBSTATION ||
          asset?.type === AssetType.SITE_CONTROLLER,
      );
      break;
    case MonitorDefs.STORAGE_SITES:
      filteredAssets = assets?.filter(
        (asset) =>
          asset?.type === AssetType.STORAGE_INVERTER ||
          asset?.type === AssetType.SUBSTATION ||
          asset?.type === AssetType.SITE_CONTROLLER,
      );
      break;
    case MonitorDefs.SOLAR_SITES:
      filteredAssets = assets?.filter(
        (asset) =>
          asset?.type === AssetType.SOLAR_INVERTER ||
          asset?.type === AssetType.SUBSTATION ||
          asset?.type === AssetType.SITE_CONTROLLER,
      );
      break;
    case MonitorDefs.HYBRID_SITES:
      filteredAssets = assets?.filter(
        (asset) =>
          asset?.type === AssetType.HYBRID_INVERTER ||
          asset?.type === AssetType.SUBSTATION ||
          asset?.type === AssetType.SITE_CONTROLLER,
      );
      break;
    default:
      filteredAssets = assets;
  }
  return filteredAssets;
};

export const useSiteRealTimeInfo = ({ siteId, sortDirection, sortMetric, sortType, type }) => {
  // TODO - fetch alerts for site once API exists

  const { isAuthorized } = useAuth();
  const isAuthorizedToViewSIs = isAuthorized({
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] }],
  });

  let {
    data: { assets, site },
    isLoading: isSiteDetailsLoading,
  } = useSiteDetails({ siteId });

  assets = getFilteredAssetsBasedOnType(assets, type);
  const assetIds = assets?.map(({ id }) => id).join(',');
  const { data: tasks } = useAssetNotCompletedTasks(assetIds);
  const { updateAssetsTasks } = useStoreActions((actions) => actions.tasks);
  useEffect(() => {
    if (Array.isArray(tasks?.tasks)) {
      const tasksArray = tasks.tasks;
      updateAssetsTasks(
        tasksArray.reduce((acc, task) => {
          acc[task.id] = task;
          return acc;
        }, {}),
      );
    }
  }, [tasks, updateAssetsTasks]);

  const getAssetTasks = useStoreState((store) => store.tasks.getAssetTasks);
  const taskData = getAssetTasks();
  // trying to memoize the full query response object doesn't work
  // so just picking the things we care about

  const { data: siteData, error, isLoading: isRealTimeLoading } = useQuery(
    [QueryKey.SITE_REAL_TIME_INFO, assetIds, type],
    async () => {
      // we can look into filtering by feature upstream
      // and only triggering update when change happens within our feature
      // but in practice, not sure how much we'll suppress that noise at the individual feature level
      const monitorAssets = assets ?? [];

      // right now we are only getting assets back by list of asset ids
      // the backend does provide a way to query by source id but we were
      // discouraged from using this approach since it's more for internal use
      // there was discussion about exposing real-time data by site id
      // but for now we are required to use asset ids
      const assetIds = monitorAssets.map(({ id }) => id);
      let realTime = {};

      // TODO: revisit this but not failing hard if real time info fails to come back
      // how do we want to surfact this issue to the user?
      try {
        realTime = assetIds?.length ? await fetchSiteMetrics(siteId, assetIds, type) : {};
      } catch (err) {
        console.error(err);
      }

      return {
        assets: monitorAssets,
        realTime,
        site,
        taskData,
      };
    },
    {
      enabled: Boolean(assets?.length) && Boolean(type),
      refetchInterval: Config.REFRESH.realTime,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  // Dependent Query for specialInstructions, using isLoading from previous query for enabled flag
  const { data: SIs, error: siError, isLoading: isSIsLoading } = useQuery(
    [QueryKey.ACTIVE_SPECIAL_INSTRUCTIONS, assetIds],
    () => {
      const assetIds = siteData?.assets?.map(({ id }) => id);
      return getActiveSIsForAssets(assetIds);
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Config.REFRESH.realTime,
      enabled: Boolean(!isRealTimeLoading && isAuthorizedToViewSIs && siteData?.assets?.length),
    },
  );

  const data = useMemo(() => {
    const siteTansformedData = siteRealTimeInfoTransformer({ ...siteData, taskData, SIs });
    return {
      site: siteTansformedData?.siteData,
      assets: siteTansformedData?.assetData?.length
        ? siteTansformedData.assetData.sort(sorter(sortMetric, sortDirection, sortType))
        : undefined,
    };
  }, [siteData, sortDirection, sortMetric, sortType, taskData, SIs]);

  const isLoading = isSiteDetailsLoading || isRealTimeLoading;

  return {
    data,
    error,
    isLoading,
    siError,
    isSIsLoading,
  };
};
