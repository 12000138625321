import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GaugeChart } from '@ge/components/charts';
import { DialGaugeDefs } from '@ge/feat-analyze/models';
import { Placeholders } from '@ge/models/constants';

import { DashboardKPI } from './dashboard-kpi';

const Container = styled.div`
  display: flex;
  padding: 14px 10px 0;
  justify-content: space-around;
  text-align: center;
`;

const GaugeChartOutput = styled(GaugeChart).attrs((props) => ({
  pageColor: props.theme.analyze.regionCard.backgroundColor,
}))``;

export const DashboardGaugeCard = ({ dialGauge1, dialGauge2, kpi1, kpi2 }) => {
  // hooks
  const { i18n, t } = useTranslation(['analyze.dashboard']);

  const kpi1Title = {
    i18nDefault: Placeholders.DOUBLE_DASH,
    i18nKey: `kpi.${kpi1?.type}`,
  };

  const kpi2Title = {
    i18nDefault: Placeholders.DOUBLE_DASH,
    i18nKey: `kpi.${kpi2?.type}`,
  };

  const dialGauge1Defs = DialGaugeDefs[dialGauge1?.type];
  const dialGauge2Defs = DialGaugeDefs[dialGauge2?.type];

  return (
    <Container>
      <div className="gauge">
        <GaugeChartOutput
          title={t(`kpi.${dialGauge1?.type}`, Placeholders.DOUBLE_DASH)}
          max={dialGauge1?.max ?? dialGauge1Defs.max}
          value={dialGauge1?.aggregateValue ?? dialGauge1Defs.defaultValue}
          label={dialGauge1?.aggregateValue?.toLocaleString(i18n.language, {
            maximumFractionDigits: 1,
          })}
          danger={dialGauge1?.danger ?? dialGauge1Defs.danger}
          warning={dialGauge1?.warning ?? dialGauge1Defs.warning}
          threshold={dialGauge1?.threshold ?? dialGauge1Defs.threshold}
          unit={dialGauge1?.units}
        />
        <div className="gauge-kpi">
          <DashboardKPI
            title={kpi1Title}
            data={kpi1?.aggregateValue && [kpi1]}
            compressed
            renderWhileLoading
          />
        </div>
      </div>
      <div className="gauge">
        <GaugeChartOutput
          title={t(`kpi.${dialGauge2?.type}`, Placeholders.DOUBLE_DASH)}
          max={dialGauge2?.max ?? dialGauge2Defs.max}
          value={dialGauge2?.aggregateValue ?? dialGauge2Defs.defaultValue}
          label={dialGauge2?.aggregateValue?.toLocaleString(i18n.language, {
            maximumFractionDigits: 1,
          })}
          danger={dialGauge2?.danger ?? dialGauge2Defs.danger}
          warning={dialGauge2?.warning ?? dialGauge2Defs.warning}
          threshold={dialGauge2?.threshold ?? dialGauge2Defs.threshold}
          unit={dialGauge2?.units}
        />
        <div className="gauge-kpi">
          <DashboardKPI
            title={kpi2Title}
            data={kpi2?.aggregateValue && [kpi2]}
            compressed
            renderWhileLoading
          />
        </div>
      </div>
    </Container>
  );
};

DashboardGaugeCard.propTypes = {
  dialGauge1: PropTypes.object,
  dialGauge2: PropTypes.object,
  kpi1: PropTypes.object,
  kpi2: PropTypes.object,
};

DashboardGaugeCard.defaultProps = {
  dialGauge1: undefined,
  dialGauge2: undefined,
  kpi1: undefined,
  kpi2: undefined,
};
