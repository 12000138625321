import { PropTypes } from 'prop-types';
import React from 'react';

const PersonContext = React.createContext(null);

const PersonProvider = ({ value, children }) => {
  return <PersonContext.Provider value={value}>{children}</PersonContext.Provider>;
};

PersonProvider.propTypes = {
  value: PropTypes.any,
  children: PropTypes.any,
};

export { PersonContext, PersonProvider };
