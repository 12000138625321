import { ChartType } from '@ge/components/charts';
import { KpiCategoryDefs } from '@ge/models/constants';

import { FilterDefs } from './analyze-filter-defaults';
import { KpiCategoryDataType, KpiCategorySeriesType } from './constants';

const AvailabilityDefs = {
  contractual_availability: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.AVAILABILITY_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.IU_COVERAGE,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.AVAILABILITY_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      // TODO: review if it's naive to assume a single sort across both default and alternate charts
      sortEntitiesBy: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    },
  },
  pba: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.PBA,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.IU_COVERAGE,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.PBA,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.PBA,
    },
  },
  tba: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.TBA,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.IU_COVERAGE,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.TBA,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.TBA,
    },
  },
};

const DataQualityDefs = {
  event_coverage: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.EVENT_COVERAGE,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.IU_COVERAGE,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.EVENT_COVERAGE,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.EVENT_COVERAGE,
    },
  },
  reporting_turbine_ratio: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.DATA_QUALITY,
            key: KpiCategoryDefs.REPORTING_ASSETS_RATIO,
            type: KpiCategoryDataType.NONE,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
    },
  },
};

export const GeInternalDefs = {
  ppi_bank: {
    // remmoving for MVP until contract/learned filter is added back in
    // contract_curve: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.GE_INTERNAL,
            key: KpiCategoryDefs.PPI_BANK_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.GE_INTERNAL,
            key: KpiCategoryDefs.PPI_BANK_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.LINE,
      },
      sortEntitiesBy: KpiCategoryDefs.PPI_BANK_CONTRACT,
    },
    // },
    // learned_curve: {
    //   graph1: {
    //     alternate: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.PPI_BANK_LEARNED,
    //           type: KpiCategoryDataType.PERCENT,
    //         },
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.UNPRODUCED_ENERGY_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.ENTITY,
    //       type: ChartType.MIRROR_COLUMN,
    //     },
    //     default: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.PPI_BANK_LEARNED,
    //           type: KpiCategoryDataType.PERCENT,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.TIME_SERIES,
    //       type: ChartType.LINE,
    //     },
    //     sortEntitiesBy: KpiCategoryDefs.PPI_BANK_LEARNED,
    //   },
    // },
  },
  ppi_full_production: {
    // remmoving for MVP until contract/learned filter is added back in
    // contract_curve: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.GE_INTERNAL,
            key: KpiCategoryDefs.PPI_FULL_PRODUCTION_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.GE_INTERNAL,
            key: KpiCategoryDefs.PPI_FULL_PRODUCTION_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.LINE,
      },
      sortEntitiesBy: KpiCategoryDefs.PPI_FULL_PRODUCTION_CONTRACT,
    },
    // },
    // learned_curve: {
    //   graph1: {
    //     alternate: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.PPI_FULL_PRODUCTION_LEARNED,
    //           type: KpiCategoryDataType.PERCENT,
    //         },
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.UNPRODUCED_ENERGY_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.ENTITY,
    //       type: ChartType.MIRROR_COLUMN,
    //     },
    //     default: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.PPI_FULL_PRODUCTION_LEARNED,
    //           type: KpiCategoryDataType.PERCENT,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.TIME_SERIES,
    //       type: ChartType.LINE,
    //     },
    //     sortEntitiesBy: KpiCategoryDefs.PPI_FULL_PRODUCTION_LEARNED,
    //   },
    // },
  },
  ppi_unproduced_energy: {
    // remmoving for MVP until contract/learned filter is added back in
    // contract_curve: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.GE_INTERNAL,
            key: KpiCategoryDefs.PPI_UNPRODUCED_ENERGY_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.GE_INTERNAL,
            key: KpiCategoryDefs.PPI_UNPRODUCED_ENERGY_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.LINE,
      },
      sortEntitiesBy: KpiCategoryDefs.PPI_UNPRODUCED_ENERGY_CONTRACT,
    },
    // },
    // learned_curve: {
    //   graph1: {
    //     alternate: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.PPI_UNPRODUCED_ENERGY_LEARNED,
    //           type: KpiCategoryDataType.PERCENT,
    //         },
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.UNPRODUCED_ENERGY_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.ENTITY,
    //       type: ChartType.MIRROR_COLUMN,
    //     },
    //     default: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.GE_INTERNAL,
    //           key: KpiCategoryDefs.PPI_UNPRODUCED_ENERGY_LEARNED,
    //           type: KpiCategoryDataType.PERCENT,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.TIME_SERIES,
    //       type: ChartType.LINE,
    //     },
    //     sortEntitiesBy: KpiCategoryDefs.PPI_UNPRODUCED_ENERGY_LEARNED,
    //   },
    // },
  },
};

const PerformanceDefs = {
  actual_production: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_ACTUAL,
            type: KpiCategoryDataType.ENERGY,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        selected: true,
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_ACTUAL,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.PRODUCTION_ACTUAL,
    },
  },
  actual_vs_expected_production: {
    // remmoving for MVP until contract/learned filter is added back in
    // contract_curve: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_ACTUAL,
            type: KpiCategoryDataType.ENERGY,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
    },
    // },
    // learned_curve: {
    //   graph1: {
    //     default: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_ACTUAL,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_EXPECTED_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.TIME_SERIES,
    //       type: ChartType.COLUMN,
    //     },
    //   },
    // },
  },
  capacity_factor: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.CAPACITY_FACTOR,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.CAPACITY_FACTOR,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.CAPACITY_FACTOR,
    },
  },
  expected_production: {
    // remmoving for MVP until contract/learned filter is added back in
    // contract_curve: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT,
    },
    // },
    // learned_curve: {
    //   graph1: {
    //     alternate: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_EXPECTED_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_LOST_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.ENTITY,
    //       type: ChartType.MIRROR_COLUMN,
    //     },
    //     default: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_EXPECTED_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.TIME_SERIES,
    //       type: ChartType.COLUMN,
    //     },
    //     sortEntitiesBy: KpiCategoryDefs.PRODUCTION_EXPECTED_LEARNED,
    //   },
    // },
  },
  lost_production: {
    // remmoving for MVP until contract/learned filter is added back in
    // contract_curve: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_ACTUAL,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
    },
    // },
    // learned_curve: {
    //   graph1: {
    //     alternate: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_LOST_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_ACTUAL,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.ENTITY,
    //       type: ChartType.MIRROR_COLUMN,
    //     },
    //     default: {
    //       categories: [
    //         {
    //           grouping: FilterDefs.PERFORMANCE,
    //           key: KpiCategoryDefs.PRODUCTION_LOST_LEARNED,
    //           type: KpiCategoryDataType.ENERGY,
    //         },
    //       ],
    //       seriesType: KpiCategorySeriesType.TIME_SERIES,
    //       type: ChartType.COLUMN,
    //     },
    //     sortEntitiesBy: KpiCategoryDefs.PRODUCTION_LOST_LEARNED,
    //   },
    // },
  },
  production_ratio: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_RATIO,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_RATIO,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.PRODUCTION_RATIO,
    },
  },
  actual_vs_irradiance_power: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.ACTUAL_POWER,
            type: KpiCategoryDataType.NONE,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.IRRADIANCE,
            type: KpiCategoryDataType.NONE,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.LINE,
      },
    },
  },
};

const ReliabilityDefs = {
  mean_time_forced_outage: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.RELIABILITY,
            key: KpiCategoryDefs.RELIABILITY_MTBFO,
            type: KpiCategoryDataType.DURATION,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.RELIABILITY,
            key: KpiCategoryDefs.RELIABILITY_MTBFO,
            type: KpiCategoryDataType.DURATION,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.RELIABILITY_MTBFO,
    },
  },
  mean_time_repair: {
    graph1: {
      alternate: {
        categories: [
          {
            grouping: FilterDefs.RELIABILITY,
            key: KpiCategoryDefs.RELIABILITY_MTTR,
            type: KpiCategoryDataType.DURATION,
          },
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.MIRROR_COLUMN,
      },
      default: {
        categories: [
          {
            grouping: FilterDefs.RELIABILITY,
            key: KpiCategoryDefs.RELIABILITY_MTTR,
            type: KpiCategoryDataType.DURATION,
          },
        ],
        seriesType: KpiCategorySeriesType.ENTITY,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.RELIABILITY_MTTR,
    },
  },
};

// model representation of the ge spreadsheet for what goes in what graph
// maps between filter bar and kpi data fetch defs
export const SiteKpiChartDefs = {
  [FilterDefs.AVAILABILITY]: AvailabilityDefs,
  [FilterDefs.DATA_QUALITY]: DataQualityDefs,
  [FilterDefs.GE_INTERNAL]: GeInternalDefs,
  [FilterDefs.PERFORMANCE]: PerformanceDefs,
  [FilterDefs.RELIABILITY]: ReliabilityDefs,
};
