import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { typography } from '@ge/tokens';

const Steps = styled.div`
  ol {
    display: flex;
    list-style: none;
    counter-reset: steps-counter;
    padding: 5px 25px;
    li {
      align-items: center;
      counter-increment: steps-counter;
      display: flex;
      font-size: 14px;
      font-weight: ${typography.weight.bold};
      margin-right: 30px;
      &:before {
        align-items: center;
        background: ${(props) => props.theme.admin.panel.steps.number};
        border-radius: 25px;
        content: counter(steps-counter);
        display: flex;
        height: 25px;
        justify-content: center;
        margin-right: 10px;
        width: 25px;
      }
      button {
        color: ${(props) => props.theme.admin.panel.steps.textColor};
        padding: 1px 0 0;
        cursor: default;
      }

      &.highlight {
        button {
          color: ${(props) => props.theme.admin.panel.steps.textColorActive};
          cursor: pointer;
        }
        &:before {
          background: ${(props) => props.theme.admin.panel.steps.numberActive};
        }
      }
    }
  }
`;

const StepIcon = styled(Icon).attrs((props) => ({
  color: props.theme.admin.panel.header.iconColor,
  rotate: -90,
  size: 12,
}))`
  margin: 0 0 4px 5px;
`;

const CheckIcon = styled(Icon).attrs((props) => ({
  color: props.theme.admin.panel.header.iconColor,
  size: 12,
}))`
  margin: 0 0 2px 5px;
`;

const EntityCreateSteps = ({ onStepChange, steps, stepIndex }) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });

  return (
    <Steps>
      <ol>
        {steps.map((step, index) => {
          return (
            <li className={index <= stepIndex ? 'highlight' : undefined} key={index}>
              <button onClick={index < stepIndex ? () => onStepChange(index) : null} type="button">
                {t(`dynamic.steps.${step.value}`, step.defaultTranslation)}
                {stepIndex === index && stepIndex !== steps.length - 1 && (
                  <StepIcon icon={Icons.ARROW_DOWN} rotate={-90} />
                )}
                {index < stepIndex && <CheckIcon icon={Icons.CHECK} />}
              </button>
            </li>
          );
        })}
      </ol>
    </Steps>
  );
};

EntityCreateSteps.propTypes = {
  onStepChange: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  stepIndex: PropTypes.number.isRequired,
};

EntityCreateSteps.defaultProps = {
  onStepChange: () => null,
};

export default EntityCreateSteps;
