// All available configuration fields and groups for Production graph
export const ProductionGraphFields = {
  // Show [affects field visibility]
  GROUP_SHOW: 'Show',
  TOTAL: 'Total',
};

// Production graph field definitions
export const productionGraphFieldDefs = {
  [ProductionGraphFields.GROUP_SHOW]: {
    labels: [
      {
        a11yKey: 'production_graph.show',
        a11yDefault: 'Show',
      },
    ],
    fields: {
      [ProductionGraphFields.TOTAL]: {
        labels: [
          {
            a11yKey: 'production_graph.total',
            a11yDefault: 'Total',
          },
        ],
      },
    },
  },
};

// Default order and visibility configuration for Production graph fields
// NOTE: For state selection, EVERY field must have a position and visibility default!
export const productionGraphDefaultConfig = [
  {
    id: ProductionGraphFields.GROUP_SHOW,
    fields: [
      {
        id: ProductionGraphFields.TOTAL,
        visible: true,
      },
    ],
  },
];
