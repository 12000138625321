import { request } from './api';

export const getAnnotations = (objId, options) =>
  request.get(`/rei/postProcess/annotations/${objId}`, options);
export const updateAnnotation = (flagNew, data, options) =>
  request.post(`/rei/postProcess/update-annotation/${flagNew}`, data, options);
export const getImage = (imageId, options) =>
  request.get(`/rei/postProcess/image/${imageId}`, options);
export const getImages = (jobId, options) =>
  request.get(`/rei/postProcess/images/${jobId}`, options);
export const deleteAnnotation = (annotationId, options) =>
  request.delete(`/rei/postProcess/delete-annotation/${annotationId}`, options);
export const updateImage = (flagNew, data, options) =>
  request.post(`/rei/postProcess/update-image/${flagNew}`, data, options);
export const getVideos = (jobId, options) =>
  request.get(`/rei/postProcess/videos/${jobId}`, options);
export const updateVideo = (flagNew, data, options) =>
  request.post(`/rei/postProcess/update-video/${flagNew}`, data, options);
export const bulkUpdateVideo = (data, options) =>
  request.post(`/rei/postProcess/bulk-update-video`, data, options);

export const createJobExecution = (jobId, data, options) =>
  request.post(`/rei/inspection/create-job-execution/${jobId}`, data, options);
// export const updateJobExecution = (jobId, data, options) =>
//   request.post(`/updateJobExecution/${jobId}`, data, options);
// export const getJobExecution = (jobId, options) =>
//   request.get(`/getJobExecution/${jobId}`, options);
export const getTopPendingJobExecution = (options) =>
  request.get('/getTopPendingJobExecution/', options);
export const getQueuedPendingJobExecution = (options) =>
  request.get('/getQueuedPendingJobExecution/', options);
export const getRunningJobExecution = (options) => request.get('/getRunningJobExecution/', options);

export const getInspectionFileAttributes = (jobId, options) =>
  request.get(`/getInspectionFileAttributes/${jobId}`, options);
export const pushToIW = (jobId, options) => request.get(`/pushToIW/${jobId}`, options);

export const getUploadStageInfo = (jobId, options) =>
  request.get(`/rei/inspection/upload-stage-info/${jobId}`, options);
export const getExecutionStageInfo = (jobId, options) =>
  request.get(`/rei/inspection/execution-stage-info/${jobId}`, options);
export const getPostProcessStageInfo = (jobId, options) =>
  request.get(`/rei/inspection/post-process-stage-info/${jobId}`, options);

export const changeJobWorkflow = (jobId, data, options) =>
  request.post(`/rei/inspection/change-job-workflow/${jobId}`, data, options);
export const postJob = (jobId, data, options) =>
  request.post(`/rei/upload/post-job/${jobId}?version=v2`, data, options);
export const patchJob = (jobId, data, options) =>
  request.patch(`/rei/upload/patch-job/${jobId}?version=v2`, data, options);
export const executeJob = (jobId, data, options) =>
  request.post(`/executeJob/${jobId}`, data, options);
export const getJob = (jobId, options) =>
  request.get(`/rei/inspection/job-by-id/${jobId}`, options);
export const getAllJobs = (number = 10000, filters, options) =>
  request.post(`/rei/inspection/all-jobs-list/${number}`, filters, options);
export const getAllJobsWSConn = () =>
  new WebSocket(`${process.env.REACT_APP_DIGITAL_WIND_FARM_INSPECT_WS}/getAllJobs/`);
export const deleteJob = (jobId, options) =>
  request.delete(`/rei/inspection/delete-flag/${jobId}`, options);

export const getDefectsDataForSite = (siteId, reportFlag, options) =>
  request.get(`/getAnnotationDataForSite/${siteId}/${reportFlag}`, options);
export const postMetadata = (jobId, data, options) =>
  request.post(`/rei/upload/${jobId}/metadata?version=v2`, data, options);
export const patchMetadata = (jobId, data, parameter = 'serialnos', options) =>
  request.patch(`/rei/upload/jobs/${jobId}/metadata/${parameter}`, data, options);
export const getReportsByInspectionIds = (jobIds, data, options) =>
  request.get(`/getReportsByInspectionIds/`, options);

export const getDamageTree = (inspectionType, options) =>
  request.get(`/rei/postProcess/damage-tree-details/${inspectionType}`, options);

/**
 * Dynamically load the mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/inspections.mocks')
    .then((mocks) => mocks.registerMocks())
    .catch((e) => {
      // eslint-disable-next-line
      console.error('Failed to register mocks.', e);
    });
