import { Capability, PermissionScope } from '@ge/models/constants';

export const ConfigureNavItems = [
  {
    label: 'Distribution List',
    route: '/admin/application configuration',
    i18nKey: 'nav:admin.l3.configure.distribution-list',
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
  },
];
