import styled from 'styled-components';

import { Icon } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
export const RemoveContainerStyled = styled.div.attrs({
  className: 'RemoveContainer',
})`
  padding: 0.9rem;
  margin-left: 0.3rem;
  background-color: ${({ theme }) => theme.admin.actionHeader.removeContainerBackground};
  button {
    color: ${({ theme }) => theme.admin.actionHeader.linkColor};
    text-transform: uppercase;
    font-size: inherit;
  }
`;

export const StyledDialog = styled(Dialog)`
  width: 551px;
`;

export const FooterButtons = styled.div.attrs({
  className: 'FooterButtonsContainer',
})`
  button:not(:last-of-type) {
    margin-right: 5px;
  }
  display: flex;
  justify-content: right;
  .cancelButton {
    background-color: ${({ theme }) => theme.admin.popup.backgroundColor};
    border: 1px solid ${({ theme }) => theme.admin.popup.borderColor};
  }
`;

export const RemoveIcon = styled(Icon).attrs((props) => ({
  size: 15,
  color: props.theme.admin.actionHeader.linkColor,
}))``;

export const ErrorMsg = styled.div.attrs({
  className: 'ErrorMsg',
})`
  color: ${({ theme }) => theme.layout.errorColor2};
  margin: auto;
`;
