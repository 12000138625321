import { useStoreState } from 'easy-peasy';
import { useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';

import { useTasksTable } from '@ge/feat-manage/data-hooks/use-tasks-table-asset';
import { CaseStatus, IssueType, QueryKey, SortDirection } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchCasesForAssetIdsAndStatus } from '../../monitor/services';

const CaseStatusMap = {
  [IssueType.OPEN_CASES]: CaseStatus.OPEN,
  [IssueType.CLOSED_CASES]: CaseStatus.CLOSED,
};

/**
 * Use asset signal data.
 *
 * @param assetId an asset id
 * @param issueType type of issue (case, alarm, task)
 */
const useAssetIssueData = ({ assetId = null, issueType = IssueType.OPEN_CASES }) => {
  const getEventsByAssetId = useStoreState((store) => store.issues.getEventsByAssetId);

  const {
    data: _queryData,
    isLoading,
    ...queryRest
  } = useQuery(
    [QueryKey.ASSETS_CASES, assetId, CaseStatusMap[issueType]],
    () => fetchCasesForAssetIdsAndStatus([assetId], CaseStatusMap[issueType], false),
    {
      enabled: Boolean(assetId && CaseStatusMap[issueType]),
      ...Config.EXECUTE_ONCE,
      refetchInterval: Config.REFRESH.realTime,
    },
  );

  const { data: { data: tasks } = {} } = useTasksTable({
    assetId,
    sortMetric: 'createDate',
    sortDirection: SortDirection.DESC,
  });

  const fetchAssetEventsforTasks = useCallback(
    (issueType) => {
      const closedTasks = tasks?.filter((task) => task.status.toLowerCase() === 'completed');
      const openTasks = tasks?.filter(
        (task) =>
          task.status.toLowerCase() === 'unscheduled' || task.status.toLowerCase() === 'scheduled',
      );
      if (issueType === 'open-tasks') {
        return openTasks;
      } else if (issueType === 'closed-tasks') {
        return closedTasks;
      } else {
        return [];
      }
    },
    [tasks],
  );
  // useEffect(() => {
  //   switch (issueType) {
  //     case IssueType.OPEN_TASKS:
  //       return fetchAssetEventsforTasks(IssueType.OPEN_TASKS);
  //     case IssueType.CLOSED_TASKS:
  //       return fetchAssetEventsforTasks(IssueType.CLOSED_TASKS);
  //     case IssueType.ACTIVE_ALARMS:
  //       // TODO fetch active alarms by asset id
  //       fetchAssetEvents(assetId);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [assetId, fetchAssetEvents, issueType, fetchAssetEventsforTasks]);

  const issues = useMemo(() => {
    switch (issueType) {
      case IssueType.OPEN_TASKS:
        return fetchAssetEventsforTasks(IssueType.OPEN_TASKS);
      case IssueType.CLOSED_TASKS:
        return fetchAssetEventsforTasks(IssueType.CLOSED_TASKS);
      case IssueType.ACTIVE_ALARMS:
        // TODO get active alarms by asset id
        return getEventsByAssetId(assetId);
      default:
        return [];
    }
  }, [assetId, getEventsByAssetId, issueType, fetchAssetEventsforTasks]);

  if (Object.keys(CaseStatusMap).includes(issueType)) {
    return {
      data: isLoading || !_queryData ? [] : _queryData.data,
      isLoading,
      ...queryRest,
    };
  }
  //TDOD: may need to format different entity types to an issue class or define these in the easy peasy get's

  return { data: issues };
};

export default useAssetIssueData;
