import { ChartType } from '@ge/components/charts';

export class ChartItem {
  id = null;

  xAxis = null;

  yAxis = null;

  type = ChartType.SCATTER;

  expended = false;

  constructor(chartItem) {
    Object.assign(this, chartItem);
  }
}
