import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useRef, useState, useLayoutEffect, useMemo } from 'react';
import Crosshairs from 'react-image-annotate/Crosshairs';
import useMouse from 'react-image-annotate/ImageCanvas/use-mouse';
import useProjectRegionBox from 'react-image-annotate/ImageCanvas/use-project-box';
import useWasdMode from 'react-image-annotate/ImageCanvas/use-wasd-mode';
import ImageMask from 'react-image-annotate/ImageMask';
import PointDistances from 'react-image-annotate/PointDistances';
import PreventScrollToParents from 'react-image-annotate/PreventScrollToParents';
import RegionSelectAndTransformBoxes from 'react-image-annotate/RegionSelectAndTransformBoxes';
// import VideoOrImageCanvasBackground from 'react-image-annotate/VideoOrImageCanvasBackground';
import useExcludePattern from 'react-image-annotate/hooks/use-exclude-pattern';
import useWindowSize from 'react-image-annotate/hooks/use-window-size';
import { useRafState } from 'react-use';
import styled from 'styled-components';
import { Matrix } from 'transformation-matrix-js';

import { useEventCallback } from '@ge/shared/hooks';

import { regionPropType, imagePropType } from '../../constants';
import imageResetWhite from '../icon-tools/icons/focus-target-white.svg';
import RegionLabel from '../region-label/region-label';
import RegionShapes from '../region-shapes/region-shapes';
import RegionTags from '../region-tags/region-tags';

import EnhancedImageCanvas from './enhanced-image-canvas';
import styles from './styles';

const useStyles = makeStyles(styles);

const getDefaultMat = function getDefaultMat() {
  return Matrix.from(1, 0, 0, 1, -10, -10);
};

const ZoomButton = styled.button`
  color: white;
  cursor: pointer;
`;
const ZoomIconsandIndication = styled.div``;
const ZoomIconsDiv = styled.div`
  margin-right: 10px;
  z-index: 1;
`;
const ToolIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: -6px;
  margin-left: 10px;
  margin-right: 0px;
  cursor: pointer;
`;
const ImageCanvas = ({
  regions,
  imageSrc,
  // videoSrc,
  // videoTime,
  realSize,
  showTags,
  onMouseMove = () => null,
  onMouseDown = () => null,
  onMouseUp = () => null,
  dragWithPrimary = false,
  zoomWithPrimary = false,
  createWithPrimary = false,
  pointDistancePrecision = 0,
  regionClsList,
  regionTagList,
  showCrosshairs,
  showHighlightBox = true,
  showPointDistances,
  allowedArea,
  RegionEditLabel = null,
  // videoPlaying = false,
  showMask = true,
  fullImageSegmentationMode,
  autoSegmentationOptions,
  onImageOrVideoLoaded,
  onChangeRegion,
  onBeginRegionEdit,
  onCloseRegionEdit,
  onBeginBoxTransform,
  onBeginMovePolygonPoint,
  onAddPolygonPoint,
  onSelectRegion,
  onBeginMovePoint,
  onDeleteRegion,
  // onChangeVideoTime,
  // onChangeVideoPlaying,
  onRegionClassAdded,
  currentImage,
  onRegionSelect,
  selectedAnnotationId,
  isLoading,
  SliderValueContext,
}) => {
  const classes = useStyles();

  const canvasEl = useRef(null);
  const layoutParams = useRef({});
  const [dragging, changeDragging] = useRafState(false);
  const [zoomStart, changeZoomStart] = useRafState(null);
  const [imageDimensions, changeImageDimensions] = useState();

  const [zoomEnd, changeZoomEnd] = useRafState(null);
  const [mat, changeMat] = useRafState(getDefaultMat());

  const windowSize = useWindowSize();
  const getLatestMat = useEventCallback(() => mat);
  useWasdMode({ getLatestMat, changeMat });

  const { mouseEvents, mousePosition } = useMouse({
    canvasEl,
    dragging,
    mat,
    layoutParams,
    changeMat,
    zoomStart,
    zoomEnd,
    changeZoomStart,
    changeZoomEnd,
    changeDragging,
    zoomWithPrimary,
    dragWithPrimary,
    onMouseMove,
    onMouseDown,
    onMouseUp,
  });

  useLayoutEffect(() => changeMat(mat.clone()), [changeMat, mat, windowSize]);

  // const innerMousePos = mat.applyToPoint(mousePosition.current.x, mousePosition.current.y);

  const projectRegionBox = useProjectRegionBox({ layoutParams, mat });

  const imageLoaded = Boolean(imageDimensions && imageDimensions.naturalWidth);
  const onVideoOrImageLoaded = useEventCallback(({ naturalWidth, naturalHeight, duration }) => {
    const dims = { naturalWidth, naturalHeight, duration };
    if (onImageOrVideoLoaded) onImageOrVideoLoaded(dims);
    changeImageDimensions(dims);
    // Redundant update to fix rerendering issues
    setTimeout(() => changeImageDimensions(dims), 10);
  });

  const excludePattern = useExcludePattern();

  // useEffect(() => {
  //   setCurrentUser(getUserBySSO(getSSO()));
  // }, [getUserBySSO, getSSO]);

  const canvas = canvasEl.current;
  if (canvas && imageLoaded) {
    const { clientWidth, clientHeight } = canvas;

    const fitScale = Math.max(
      imageDimensions.naturalWidth / (clientWidth - 20),
      imageDimensions.naturalHeight / (clientHeight - 20),
    );

    const [iw, ih] = [
      imageDimensions.naturalWidth / fitScale,
      imageDimensions.naturalHeight / fitScale,
    ];

    layoutParams.current = {
      iw,
      ih,
      fitScale,
      canvasWidth: clientWidth,
      canvasHeight: clientHeight,
    };
  }

  useLayoutEffect(() => {
    if (!imageDimensions) return;
    const { clientWidth, clientHeight } = canvas;
    canvas.width = clientWidth;
    canvas.height = clientHeight;
    const context = canvas.getContext('2d');

    context.save();
    context.transform(...mat.clone().inverse().toArray());

    const { iw, ih } = layoutParams.current;

    if (allowedArea) {
      // Pattern to indicate the NOT allowed areas
      const { x, y, w, h } = allowedArea;
      context.save();
      context.globalAlpha = 1;
      const outer = [
        [0, 0],
        [iw, 0],
        [iw, ih],
        [0, ih],
      ];
      const inner = [
        [x * iw, y * ih],
        [x * iw + w * iw, y * ih],
        [x * iw + w * iw, y * ih + h * ih],
        [x * iw, y * ih + h * ih],
      ];
      context.moveTo(...outer[0]);
      outer.forEach((p) => context.lineTo(...p));
      context.lineTo(...outer[0]);
      context.closePath();

      inner.reverse();
      context.moveTo(...inner[0]);
      inner.forEach((p) => context.lineTo(...p));
      context.lineTo(...inner[0]);

      context.fillStyle = excludePattern || '#f00';
      context.fill();

      context.restore();
    }

    context.restore();
  });

  // useEffect(() => {
  //   if (artboardEl && artboardEl.current) {
  //     const { clientHeight, clientWidth } = artboardEl.current;
  //     setArtboardParam({ clientHeight, clientWidth });
  //   }
  // }, [artboardEl]);

  const { iw, ih } = layoutParams.current;

  const zoomBox =
    !zoomStart || !zoomEnd
      ? null
      : {
          ...mat.clone().inverse().applyToPoint(zoomStart.x, zoomStart.y),
          w: (zoomEnd.x - zoomStart.x) / mat.a,
          h: (zoomEnd.y - zoomStart.y) / mat.d,
        };
  if (zoomBox) {
    if (zoomBox.w < 0) {
      zoomBox.x += zoomBox.w;
      zoomBox.w *= -1;
    }
    if (zoomBox.h < 0) {
      zoomBox.y += zoomBox.h;
      zoomBox.h *= -1;
    }
  }
  const resetZoom = () => {
    mat.setTransform(1, 0, 0, 1, -10, -10);
    changeMat(mat.clone());
  };
  const applyZoomOut = () => {
    if (((1 / mat.a) * 100).toFixed(0) <= 50) {
      return;
    }
    const imagePosition = {
      topLeft: mat.clone().inverse().applyToPoint(0, 0),
      bottomRight: mat.clone().inverse().applyToPoint(iw, ih),
    };
    console.log({ imagePosition });

    const mx = (imagePosition.topLeft.x + imagePosition.bottomRight.x) / 2;
    const my = (imagePosition.topLeft.y + imagePosition.bottomRight.y) / 2;
    mat.translate(mx, my).scaleU(2);
    mat.translate(-mx, -my);
    changeMat(mat.clone());
  };

  const applyZoomIn = () => {
    if (((1 / mat.a) * 100).toFixed(0) >= 2000) {
      return;
    }
    const imagePosition = {
      topLeft: mat.clone().inverse().applyToPoint(0, 0),
      bottomRight: mat.clone().inverse().applyToPoint(iw, ih),
    };

    const mx = (imagePosition.topLeft.x + imagePosition.bottomRight.x) / 2;
    const my = (imagePosition.topLeft.y + imagePosition.bottomRight.y) / 2;
    mat.translate(mx, my).scaleU(0.5);
    mat.translate(-mx, -my);
    changeMat(mat.clone());
  };
  const imagePosition = {
    topLeft: mat.clone().inverse().applyToPoint(0, 0),
    bottomRight: mat.clone().inverse().applyToPoint(iw, ih),
  };

  const highlightedRegion = useMemo(() => regions?.find((r) => r.highlighted), [regions]);
  const isAnnotationHidden = useMemo(
    () => regions?.find((r) => r.id === selectedAnnotationId)?.isAnnotationHidden,
    [regions, selectedAnnotationId],
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 68px)',
        position: 'relative',
        overflow: 'hidden',
        cursor: createWithPrimary
          ? 'crosshair'
          : dragging
          ? 'grabbing'
          : dragWithPrimary
          ? 'grab'
          : zoomWithPrimary
          ? mat.a < 1
            ? 'zoom-out'
            : 'zoom-in'
          : undefined,
      }}
    >
      {showCrosshairs && <Crosshairs key="crossHairs" mousePosition={mousePosition} />}
      {imageLoaded && !dragging && (
        <RegionSelectAndTransformBoxes
          key="regionSelectAndTransformBoxes"
          // imagePosition={imagePosition}
          regions={regions}
          mouseEvents={mouseEvents}
          projectRegionBox={projectRegionBox}
          dragWithPrimary={dragWithPrimary}
          createWithPrimary={createWithPrimary}
          zoomWithPrimary={zoomWithPrimary}
          onBeginMovePoint={onBeginMovePoint}
          onSelectRegion={onSelectRegion}
          layoutParams={layoutParams}
          mat={mat}
          onBeginBoxTransform={onBeginBoxTransform}
          onBeginMovePolygonPoint={onBeginMovePolygonPoint}
          onAddPolygonPoint={onAddPolygonPoint}
          showHighlightBox={showHighlightBox}
        />
      )}
      {imageLoaded && showTags && !dragging && (
        <PreventScrollToParents key="regionTags">
          <RegionTags
            regions={regions}
            projectRegionBox={projectRegionBox}
            mouseEvents={mouseEvents}
            regionClsList={regionClsList}
            regionTagList={regionTagList}
            onBeginRegionEdit={onBeginRegionEdit}
            onChangeRegion={onChangeRegion}
            onCloseRegionEdit={onCloseRegionEdit}
            onDeleteRegion={onDeleteRegion}
            layoutParams={layoutParams}
            imageSrc={imageSrc}
            RegionEditLabel={RegionEditLabel}
            onRegionClassAdded={onRegionClassAdded}
            onRegionSelect={onRegionSelect}
            selectedAnnotationId={selectedAnnotationId}
            isLoading={isLoading}
          />
        </PreventScrollToParents>
      )}
      {!showTags && highlightedRegion && !isAnnotationHidden && (
        <div key="topLeftTag" className={classes.fixedRegionLabel}>
          <RegionLabel
            disableClose
            allowedClasses={regionClsList}
            allowedTags={regionTagList}
            onChange={onChangeRegion}
            onDelete={onDeleteRegion}
            region={highlightedRegion}
            imageSrc={imageSrc}
            onRegionSelect={onRegionSelect}
            selectedAnnotationId={selectedAnnotationId}
            isLoading={isLoading}
          />
        </div>
      )}

      {zoomWithPrimary && zoomBox !== null && (
        <div
          key="zoomBox"
          style={{
            position: 'absolute',
            zIndex: 1,
            border: '1px solid #fff',
            pointerEvents: 'none',
            left: zoomBox.x,
            top: zoomBox.y,
            width: zoomBox.w,
            height: zoomBox.h,
          }}
        />
      )}
      {showPointDistances && (
        <PointDistances
          key="pointDistances"
          regions={regions}
          realSize={realSize}
          projectRegionBox={projectRegionBox}
          pointDistancePrecision={pointDistancePrecision}
        />
      )}
      <PreventScrollToParents
        style={{ width: '100%', height: '100%', backgroundColor: '#38434E' }}
        {...mouseEvents}
      >
        <div>
          {fullImageSegmentationMode && (
            <ImageMask
              hide={!showMask}
              autoSegmentationOptions={autoSegmentationOptions}
              imagePosition={imagePosition}
              regionClsList={regionClsList}
              imageSrc={imageSrc}
              regions={regions}
            />
          )}
          <canvas
            style={{ opacity: 0.25, height: '480px' }}
            className={classes.canvas}
            ref={canvasEl}
          />
          <RegionShapes
            mat={mat}
            imagePosition={imagePosition}
            regions={regions}
            fullSegmentationMode={fullImageSegmentationMode}
            currentImage={currentImage}
          />
          {/* <VideoOrImageCanvasBackground
            videoPlaying={videoPlaying}
            imagePosition={imagePosition}
            mouseEvents={mouseEvents}
            onLoad={onVideoOrImageLoaded}
            videoTime={videoTime}
            videoSrc={videoSrc}
            imageSrc={imageSrc}
            onChangeVideoTime={onChangeVideoTime}
            onChangeVideoPlaying={onChangeVideoPlaying}
          /> */}
          <EnhancedImageCanvas
            imageSrc={imageSrc}
            imagePosition={imagePosition}
            mouseEvents={mouseEvents}
            onLoad={onVideoOrImageLoaded}
            SliderValueContext={SliderValueContext}
          />
        </div>
      </PreventScrollToParents>
      <ZoomIconsandIndication>
        <ZoomIconsDiv className={classes.zoomIndicator}>
          {' '}
          <ZoomButton onClick={applyZoomOut}>-</ZoomButton>
          <ZoomButton>{((1 / mat.a) * 100).toFixed(0)}%</ZoomButton>
          <ZoomButton onClick={applyZoomIn}>+</ZoomButton>
          <ToolIcon src={imageResetWhite} onClick={resetZoom} />
        </ZoomIconsDiv>
      </ZoomIconsandIndication>
    </div>
  );
};
// ReportParameterCard.propTypes = {
//   parameter: PropTypes.instanceOf(Object).isRequired,
//   data: PropTypes.instanceOf(Object),
//   onClick: PropTypes.func,
//   value: PropTypes.func,
//   placeholder: PropTypes.string,
// };
ImageCanvas.propTypes = {
  regions: PropTypes.arrayOf(regionPropType),
  imageSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  videoTime: PropTypes.number,
  realSize: PropTypes.number,
  showTags: PropTypes.bool,
  onMouseMove: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  dragWithPrimary: PropTypes.bool,
  zoomWithPrimary: PropTypes.bool,
  createWithPrimary: PropTypes.bool,
  pointDistancePrecision: PropTypes.number,
  regionClsList: PropTypes.arrayOf(PropTypes.string),
  regionTagList: PropTypes.arrayOf(PropTypes.string),
  showCrosshairs: PropTypes.bool,
  showHighlightBox: PropTypes.bool,
  showPointDistances: PropTypes.bool,
  allowedArea: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }),
  RegionEditLabel: PropTypes.string,
  videoPlaying: PropTypes.bool,
  showMask: PropTypes.bool,
  fullImageSegmentationMode: PropTypes.bool,
  autoSegmentationOptions: PropTypes.shape({
    type: PropTypes.string,
  }),
  onImageOrVideoLoaded: PropTypes.func,
  onChangeRegion: PropTypes.func,
  onBeginRegionEdit: PropTypes.func,
  onCloseRegionEdit: PropTypes.func,
  onBeginBoxTransform: PropTypes.func,
  onBeginMovePolygonPoint: PropTypes.func,
  onAddPolygonPoint: PropTypes.func,
  onSelectRegion: PropTypes.func,
  onBeginMovePoint: PropTypes.func,
  onDeleteRegion: PropTypes.func,
  onChangeVideoTime: PropTypes.func,
  onChangeVideoPlaying: PropTypes.func,
  onRegionClassAdded: PropTypes.func,
  currentImage: imagePropType,
  onRegionSelect: PropTypes.func,
  selectedAnnotationId: PropTypes.number,
  isLoading: PropTypes.bool,
  SliderValueContext: PropTypes.any,
};

export default ImageCanvas;
