import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { MetricStatusCard } from '@ge/components/card';
import { Icons } from '@ge/components/icon';
import { EntityType } from '@ge/models/constants';

import SidebarCard from '../sidebar-card';

/**
 * Get the icon for the specified entity type.
 *
 * @param {String} entityType The type of entity to retrieve icon for
 */
const getEntityIcon = (entityType) => {
  switch (entityType) {
    case EntityType.TURBINE:
      return Icons.TURBINE;
    case EntityType.SITE:
      return Icons.SITE;
    default:
      return undefined;
  }
};

const getEntityType = (status) => {
  if ({}.hasOwnProperty.call(status, 'site')) {
    return EntityType.SITE;
  }

  if ({}.hasOwnProperty.call(status, 'asset')) {
    return EntityType.TURBINE;
  }

  return undefined;
};

const SidebarMetricStatusCard = ({ status }) => {
  const { fetchAssetMetricChart, fetchSiteMetricChart } = useStoreActions((store) => store.metrics);
  const { getMetricChart } = useStoreState((state) => state.metrics);
  const { getSiteById } = useStoreState((state) => state.sites);
  const { getAssetById } = useStoreState((state) => state.assets);

  const entity = useRef(null);
  const entityType = useRef(getEntityType(status));
  const chartData = useRef(null);
  const icon = useRef(getEntityIcon(entityType.current));

  // sets the current entity
  useEffect(() => {
    switch (entityType.current) {
      case EntityType.SITE:
        entity.current = getSiteById(status.site.id);
        break;
      case EntityType.TURBINE:
        entity.current = getAssetById(status.asset.id);
        break;
      default:
      // do nothing for now
    }
  }, [getAssetById, getSiteById, status]);

  // fetch the chart data
  useEffect(() => {
    switch (entityType.current) {
      case EntityType.SITE:
        fetchSiteMetricChart({ siteId: status.site.id, metric: status.metric });
        break;
      case EntityType.TURBINE:
        fetchAssetMetricChart({
          assetId: status.asset.id,
          metric: status.metric,
        });
        break;
      default:
      // do nothing for now
    }
  }, [fetchAssetMetricChart, fetchSiteMetricChart, status]);

  // update chart data
  useEffect(() => {
    const currentEntity = entity.current;

    if (!currentEntity) return;

    const metricChart = getMetricChart(currentEntity.id, getEntityType(status), status.metric);

    if (metricChart) {
      chartData.current = metricChart.chartData;
    }
  }, [getMetricChart, status]);

  /**
   * Determine if the status values should result in a "danger" decoration.
   */
  const isDanger = () => status.values[status.values.length - 1] > status.threshold;

  return (
    <SidebarCard
      icon={icon.current}
      title={entity.current && entity.current.name}
      danger={isDanger()}
    >
      <MetricStatusCard
        chartData={chartData.current}
        metric={status.metric}
        threshold={status.threshold}
        unit={status.unit}
        values={status.values}
      />
    </SidebarCard>
  );
};

SidebarMetricStatusCard.propTypes = {
  status: PropTypes.instanceOf(Object).isRequired,
};

export default SidebarMetricStatusCard;
