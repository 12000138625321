const fs = require('fs');
const os = require('os');
const path = require('path');

const chance = require('chance').Chance();
const dayjs = require('dayjs');

const average = (arr) => arr.reduce((a, b) => a + b) / arr.length;

const emoji = {
  check: String.fromCodePoint('0x2705'),
  construction: String.fromCodePoint('0x1F3D7'),
  disk: String.fromCodePoint('0x1F4BE'),
  folder: String.fromCodePoint('0x1F4C2'),
  rocket: String.fromCodePoint('0x1F680'),
  search: String.fromCodePoint('0x1F50D'),
  sparkles: String.fromCodePoint('0x2728'),
  warning: String.fromCodePoint('0x26A0'),
};

const colors = {
  cyan: '\x1b[36m',
};

const colorize = (str, color) => `${color}${str}\x1b[0m`;

/**
 * Generate a random integer within a given range.
 *
 * @param {number} min Min
 * @param {number} max Max
 */
const randomIntWithRange = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

/**
 * Pick a random entry in the given array.
 *
 * @param {array} arr Array of objects
 */
const random = (arr) => arr[randomIntWithRange(0, arr.length - 1)];

/**
 *  Picks random elements from the array
 *
 * @param {array} arr Array of objects
 */
const randomItemsInArray = (arr) => {
  const _set = new Set();
  const length = arr.length - 1;
  //Ensure that atleast 1 item exists
  const count = randomIntWithRange(1, length);
  for (let i = 0; i < count; i++) {
    const t = arr[randomIntWithRange(0, length)];
    if (!_set.has(t)) {
      _set.add(t);
    }
  }
  return [..._set];
};

/**
 * Generate a random value within given min and max
 *
 * @param {number} min Min
 * @param {number} max Max
 */
const randomNumber = (min, max) => Math.random() * (max - min) + min;

/**
 * Generate a normally distributed random number.
 * @param min The minimum value
 * @param max The maximum value
 * @param skew The skew
 * @return {number}
 */
const randomNumBoxMuller = (min, max, skew) => {
  let u = 0;
  let v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) num = randomNumBoxMuller(min, max, skew); // resample between 0 and 1 if out of range
  num = Math.pow(num, skew); // Skew
  num *= max - min; // Stretch to fill range
  num += min; // offset to min
  return num < 0 ? 0 : num;
};

/**
 * Repeat the provided function a random number of times between the
 * provided min and max.
 *
 * @param {number} min Min
 * @param {number | function} max Max in a repeat range OR function to execute if no range desired
 * @param {function} fn Function to execute
 */
const repeat = (min, max, fn) => {
  const arr = [];
  let count;

  if ({}.toString.call(max) === '[object Function]') {
    fn = max;
    count = min;
  } else {
    count = randomIntWithRange(min, max);
  }

  for (let i = 0; i < count; i += 1) {
    arr.push(fn());
  }

  return arr;
};

/**
 * Generate a random date from the current year.
 * @return {*}
 */
const dateFromThisYear = () => {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth();
  return chance.date({ year, month: chance.integer({ min: 0, max: month }) });
};

/**
 * Generate a random date from the current year.
 * @return {*}
 */
const dateWithinLastDays = (days = 30) => {
  const randomMinutes = chance.integer({ min: 1, max: days * 24 * 60 });
  return dayjs()
    .subtract(randomMinutes, 'minutes')
    .toDate();
};

/**
 * Recursively traverse the provided object looking for functions
 * to execute and replace the functions with their return value. Each
 * function is provided with the parent object as context.
 *
 * TODO: We may want to also provide the base object as an argument so
 * the functions can reference values outside the parent object.
 *
 * @param {object} obj Object to build
 */
const buildMockObject = (obj) => {
  const traverse = (obj, prevObj, key) => {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      traverseArray(obj);
    } else if (typeof obj === 'object' && obj !== null) {
      traverseObject(obj);
    } else if ({}.toString.call(obj) === '[object Function]') {
      // If we find a function, execute it with the parent element as "this" context.
      prevObj[key] = obj.apply(prevObj);
    }

    return obj;
  };

  const traverseArray = (arr) => {
    arr.forEach((x) => {
      traverse(x, arr);
    });
  };

  const traverseObject = (obj) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        traverse(obj[key], obj, key);
      }
    }
  };

  return traverse(obj, null);
};

/**
 * Build an array of mock objects from a mock template
 *
 * @param {object} objectTemplates Array of template mock objects to render
 */
const renderMockObjects = (objectTemplates) => {
  if (typeof objectTemplates === 'object' && objectTemplates !== null) {
    return buildMockObject(objectTemplates);
  }

  return objectTemplates.map((tmpl) => buildMockObject(tmpl));
};

// - TODO temporary to make view mocks smaller
// const stripSites = (sites) =>
//   sites.map((site) => ({
//     id: site.id,
//     name: site.name,
//     group: site.group,
//     country: site.country,
//     region: {
//       id: site.region.id,
//     },
//     customer: site.customer,
//     timezone: site.timezone,
//     location: site.location,
//   }));

// - TODO temporary to make view mocks smaller
const stripAssets = (assets) =>
  assets.map((asset) => ({
    id: asset.id,
    name: asset.name,
    make: asset.make,
    model: asset.model,
    serialNumber: asset.serialNumber,
    systemNumber: asset.systemNumber,
    contractPower: asset.contractPower,
    site: {
      id: asset.site.id,
    },
    location: asset.location,
    metrics: {
      state: asset.metrics.state,
    },
  }));

const buildEntities = (obj) => {
  const { sites, assets, ...rest } = obj;
  const toBuild = Object.entries({
    sites, //: sites && stripSites(sites),
    assets: assets && stripAssets(assets),
    ...rest,
  }).reduce((acc, [key, value]) => {
    if (obj[key] !== undefined) acc[key] = value;
    return acc;
  }, {});
  return Object.keys(toBuild)
    .map((key) => ({
      [key]: toBuild[key].reduce((acc, entity) => {
        acc[entity.id] = entity;
        return acc;
      }, {}),
    }))
    .reduce((acc, entity) => ({ ...acc, ...entity }), {});
};

/**
 * Write mock json to an view-specific json file.
 *
 * @param {object} viewMocks Mocks to write to a file
 * @param {String} viewPath The root write path (default to 'views')
 */
const writeViewJson = (viewMocks, viewPath = 'views') => {
  if (viewMocks === undefined || typeof viewMocks === 'string' || typeof viewMocks === 'number') {
    throw new Error(`Unable to find 'data' or 'dataById' key in generated mocks: ${viewPath}`);
  }
  if (!viewMocks.data && !viewMocks.dataById) {
    Object.entries(viewMocks).forEach(([key, value]) => writeViewJson(value, `${viewPath}/${key}`));
    return;
  }
  const i = viewPath.lastIndexOf('/');
  const type = viewPath.substring(i + 1, viewPath.length);
  const subPath = viewPath.substring(0, i);
  writeJson(type, viewMocks, subPath);
};

/**
 * Write mock json to an entity-specific json file.
 *
 * @param {string} entityType Entity name to write to file system
 * @param {object} json Mocks to write to a file
 * @param opts entity write options
 */
const writeEntityJson = (entityType, json, opts = { writeIndex: true, distFolder: true }) => {
  writeJson(entityType, json, 'entities', opts);
};

const writeJson = (objectType, json, pathName, opts = { writeIndex: true, distFolder: true }) => {
  const baseFolder = path.join(__dirname, '../dist', pathName);
  const distFolder = path.join(baseFolder, objectType);
  const jsonOutFile = path.join(opts.distFolder ? distFolder : baseFolder, `${objectType}.json`);
  const indexOutFile = path.join(opts.distFolder ? distFolder : baseFolder, 'index.js');

  // Create the folder for the entity type
  try {
    fs.mkdirSync(baseFolder, { recursive: true });
    console.log(`${emoji.folder} Created base ${baseFolder} folder.`);
  } catch (e) {
    // Do nothing
  }

  // Create the folder for the object type
  let distCreate = false;
  if (opts.distFolder) {
    try {
      fs.mkdirSync(distFolder);
      console.log(
        `|--${emoji.folder} Created folder for type "${colorize(objectType, colors.cyan)}".`,
      );
      distCreate = true;
    } catch (e) {
      // Do nothing
    }
  }

  // Write the json
  try {
    fs.writeFileSync(jsonOutFile, JSON.stringify(json, null, 2), () => null);
    const prepend = distCreate ? '   ' : '';
    console.log(
      `${prepend}|--${emoji.disk} Exported ${colorize(objectType, colors.cyan)} mocks to file.`,
    );
  } catch (e) {
    console.error(`Failed to write JSON for type "${objectType}"`, e);
  }

  // Write the index file to export the json
  if (opts.writeIndex) {
    const indexContent = `const ${objectType} = require('./${objectType}.json');${os.EOL}${os.EOL}module.exports = { ${objectType} };${os.EOL}`;
    try {
      fs.writeFileSync(indexOutFile, indexContent, () => null);
    } catch (e) {
      console.error(`Failed to write index file for type "${objectType}"`, e);
    }
  }
};

const writeFile = (content, filePath) => {
  try {
    fs.writeFileSync(filePath, content, () => null);
  } catch (e) {
    console.error(`Failed to write file to "${filePath}"`, e);
  }
};

/**
 * Generate a random compass direction (0-359 degrees)
 */
const compassDirection = () => chance.integer({ min: 0, max: 359 });

/**
 * Generate a random percent (0-100)
 */
const percent = () => chance.integer({ min: 0, max: 100 });

/**
 * Generate a UUID that has no dashes (dashes break query param parsing in the UI)
 *
 * @deprecated - need to support dashes in the UI
 */
const uuid = () => chance.guid().replace(/-/g, '');

/**
 * Generate an array of numbers summing to 100.
 *
 * @param segments the number of segments in the array
 * @return {this|number[]}
 */
const proportion = (segments) => {
  if (segments === 1) {
    return [100];
  }

  const max = 100;
  let segmentMax = (1.5 / segments) * 100;
  let remaining = max;
  const proportionArray = [];

  for (let i = 1; i <= segments; i++) {
    if (i === segments) {
      proportionArray.push(segmentMax);
    } else {
      const p = chance.floating({ min: 0, max: segmentMax, fixed: 2 });
      proportionArray.push(p);
      remaining = (remaining - p).toFixed(2);
      segmentMax = Math.min(remaining, (1.5 / (segments - i)) * 100);
    }
  }

  return proportionArray.sort(() => chance.bool());
};

const generateHistoricChartData = () => {
  const count = 34;
  const currentValue = 100;

  // assume that's 80%
  const starting = currentValue / 0.8 / 4;
  const values = [...Array(count)].map((v, i) =>
    i > 20
      ? randomNumber(starting * 2, starting * 4)
      : randomNumber(starting * 1.4, starting * 0.6),
  );

  const output = values.map((value, i) => ({
    value,
    average: i >= 5 ? average(values.slice(i - 5, i)) : undefined,
    time: new Date(),
  }));

  return output.splice(5);
};

module.exports = {
  buildMockObject,
  buildEntities,
  compassDirection,
  dateFromThisYear,
  dateWithinLastDays,
  emoji,
  generateHistoricChartData,
  percent,
  proportion,
  random,
  randomIntWithRange,
  randomItemsInArray,
  renderMockObjects,
  randomNumBoxMuller,
  repeat,
  uuid,
  writeEntityJson,
  writeFile,
  writeViewJson,
};
