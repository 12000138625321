import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { ErrorBanner } from '@ge/components/error-banner';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { PresentationTypes, EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { typography } from '@ge/tokens';
// TODO (astone): This is an unholy reference to the DWF from outside the app! Remove!
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';

import { FilterBar } from '../components/dashboard/dashboard-filter-bar';
import DashboardHeader from '../components/dashboard/dashboard-header';
import { DashboardIECLossCategories } from '../components/dashboard/dashboard-iec-loss-categories';
import { RegionSparkChart } from '../components/region/region-spark-chart';
import useRegionIecData from '../data-hooks/use-region-iec-data';

import { AnalyzeFilterPanel } from './analyze-filter-panel';

const StyledErrorMessage = styled.p`
  line-height: normal;
  font-weight: ${typography.weight.medium};
  font-size: 14px;
  & span {
    font-weight: ${typography.weight.bolder};
  }
`;

const ErrorMessage = () => (
  <StyledErrorMessage>
    The All Sites page is in development and is using mocked data currently. <br />
    Please click on <span>Sites</span> above and use <span>Site Search</span> capability to access
    the Analyze functionalities.
  </StyledErrorMessage>
);
// constants
const NAMESPACE = 'analyze.dashboard';

const DashboardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .header {
    border-bottom: solid 2px ${(props) => props.theme.analyze.header.borderBottomColor};
    margin-bottom: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    .tools {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      margin-left: auto;

      > * {
        + * {
          margin-left: 16px;
        }
      }
    }
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  > * {
    flex: 1 1;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

/**
 * Set list of view types for Presentation Toggle
 */
const PresentationViews = [PresentationTypes.LIST, PresentationTypes.GRID];

// TODO: unhardcode this
const regionId = 'reg_nam';

export const MockAnalyzeSites = () => {
  const { ready, t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const [isActive, setActiveState] = useState(PresentationTypes.LIST);
  const { search } = useLocation();

  /* Todo: need to add fetch/getters for all sites filtered by view selector
   and format to match the current region page. */
  // const { sites: viewSites } = useStoreState((state) => state.view.currentView);
  const getOverviewByRegion = useStoreState((state) => state.analyze.getOverviewByRegion);
  // const getSiteCards = useStoreState((state) => state.analyze.getSiteCards);
  const regionOverview = getOverviewByRegion(regionId);
  const { data: regionIecLoss } = useRegionIecData({
    regionId,
    category: KpiCategoryDefs.PRODUCTION_ACTUAL,
  });

  // Process URL params for setting initial view type.
  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get('view');
    if (view) {
      setActiveState(view);
    } else {
      setActiveState(PresentationTypes.GRID);
    }
  }, [isActive, search]);

  return (
    <PageContainer i18nReady={ready}>
      <AnalyzeFilterPanel entityId={regionId} entityType={EntityType.REGION} />
      <ScrollingContainer>
        <ErrorContainer>
          <ErrorBanner message={<ErrorMessage />} />
        </ErrorContainer>
        <DashboardHeader
          title={t('sites', 'Sites')}
          type={EntityType.SITE}
          showParent={false}
          data={regionOverview && regionOverview.kpi}
        />
        <FilterBar />
        <DashboardContainer>
          <ChartWrapper>
            <RegionSparkChart regionId={regionId} />
            <DashboardIECLossCategories
              title={t('tilc.title', 'Top IEC Loss Categories')}
              data={regionIecLoss}
              namespace={NAMESPACE}
            />
          </ChartWrapper>
          <div className="header">
            <h3>{t('sites', 'Sites')}</h3>
            <div className="tools">
              <PresentationToggle
                viewList={PresentationViews}
                defaultView={PresentationTypes.GRID}
              />
            </div>
          </div>
        </DashboardContainer>
      </ScrollingContainer>
    </PageContainer>
  );
};
