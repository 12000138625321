import dayjs from 'dayjs';
import { useStoreActions } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Icons } from '@ge/components/icon';
import { DotsLoader } from '@ge/components/loader';
import { useNotification } from '@ge/components/notification';
import { Severity } from '@ge/components/severity';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { TaskStatus } from '@ge/models';
import {
  Placeholders,
  AssetOverviewTabs,
  Capability,
  CaseStatus,
  DataExplorerLayouts,
  EntityType,
  TaskSourceField,
  FormMode,
  DateTimeFormats,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { CaseLinkDialog } from '@ge/shared/components/cases/case-link-dialog';
import { CaseLinkProvider } from '@ge/shared/components/cases/case-link-provider';
import { CaseSnoozeDialog } from '@ge/shared/components/cases/case-snooze-dialog';
import { NoteDialog } from '@ge/shared/components/notes/note-dialog';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAssetRealTimeData } from '@ge/shared/data-hooks';
import { useUpdateCase } from '@ge/shared/data-hooks/use-update-case';
import ActionsMenuItems, { ActionKeys } from '@ge/shared/models/actions-menu-items';
import { killEventPropagation } from '@ge/shared/util/general';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';
import { globalColors } from '@ge/tokens';

import { useInThisCase } from '../../../hooks/use-in-this-case';
import HeaderCurrentCustomer from '../components/header-current-customer';
import HeaderCurrentSite from '../components/header-current-site';
import EntityDetailHeader, {
  HeaderIcon,
  ButtonIcon,
  TypeIcon,
  MetricIcon,
  StyledDetailsDismissInert,
} from '../entity-details-header';
import { DetailsCurrent } from '../entity-details-shared';

const HeaderButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  .status-details {
    text-align: right;
  }
  .snooze-date {
    color: ${globalColors.slate4};
    font-style: italic;
    font-size: 11px;
    margin-top: 5px;
  }
  > div:last-child {
    align-self: flex-start;
  }
`;

const getOpenTasks = (tasks) => {
  if (!tasks || tasks.length === 0) {
    return tasks;
  }
  return tasks.filter(
    (task) => task.status !== TaskStatus.COMPLETED || task.status !== TaskStatus.CLOSED,
  );
};

export const CaseDetailsHeader = withRouter(
  ({ caseDetail, troubleshoot, entityType, handleCloseCase, assetId }) => {
    const { t, ready } = useTranslation(['analyze.troubleshooting'], {
      useSuspense: false,
    });
    const { updateCases, fetchCaseWithDetails } = useStoreActions((actions) => actions.cases);
    const { showCaseDetails, showDetails } = useContext(EntityDetailsContext);
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);
    const [showNewTaskDialog, setShowNewTaskDialog] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showCaseSnoozeModal, setShowCaseSnoozeModal] = useState(false);
    const { data: asset } = useAssetRealTimeData({
      assetId,
    });
    const { tasks, isLoading: isInThisCaseLoading } = useInThisCase({ caseDetail });

    const { conditions, isDisconnected } = asset.metrics || {};

    const { notify } = useNotification();

    const message = (
      <NotificationMessage message={t('link_created', 'Link Created Successfully').toUpperCase()} />
    );

    const { mutate: updateCase, isLoading: isCaseUpdating } = useUpdateCase();

    const handleEntitySelect = useCallback(
      (e, id, type) => {
        killEventPropagation(e);
        showDetails(id, type);
      },
      [showDetails],
    );

    const icon = useMemo(() => {
      if (!caseDetail) return null;
      if (EntityType.CASE === entityType) {
        return caseDetail.childCases?.length ? Icons.CASE : Icons.ANOMALY;
      }
      if (typeof caseDetail.caseId !== 'undefined') {
        return Icons.CASE;
      }
      return Icons[entityType.toUpperCase()];
    }, [caseDetail, entityType]);

    if (!ready || !caseDetail || !caseDetail.asset) {
      return null;
    }

    const updateCaseDetails = (type, snoozeDate) => {
      updateCase({
        id: caseDetail.id,
        asset: {
          id: caseDetail.asset.id,
        },
        status: ActionsMenuItems[type].title,
        snoozeDate: snoozeDate ? snoozeDate.getTime() / 1000 : null,
      });
    };

    const handleSnoozeSave = (snoozeDate) => {
      updateCaseDetails(ActionKeys.CASE_SNOOZE, snoozeDate);
      setShowCaseSnoozeModal(false);
    };

    // eslint-disable-next-line no-console
    const currentCustomerClickHandler = () => console.log('Customer Clicked');

    const handleCloseAddNoteDialog = () => setShowAddNoteModal(false);

    const handleNewNote = () => setShowAddNoteModal(true);

    const handleAddNote = () => {
      setShowAddNoteModal(false);
    };

    const handleNewTask = () => setShowNewTaskDialog(true);

    const handleCloseNewTaskDialog = () => setShowNewTaskDialog(false);

    const handleLinkModalClose = () => setShowLinkModal(false);

    const handleSaveNewTask = (_, __, variables) => {
      setShowNewTaskDialog(false);
      if (!variables) return;
      if (caseDetail.status.toUpperCase() === CaseStatus.NEW) {
        updateCase({
          id: caseDetail.id,
          asset: {
            id: caseDetail.asset.id,
          },
          status: CaseStatus.IN_PROGRESS,
        });
      } else {
        fetchCaseWithDetails(caseDetail.id);
      }
    };

    const handleSaveLink = (res) => {
      if (res?.id) {
        if (res.cases?.length > 0) {
          updateCases(res.cases);
        }
        setShowLinkModal(false);
        showCaseDetails(res.id);
        notify({ message });
      }
    };

    const troubleshootLink = {
      [EntityType.ANOMALY]: `/analyze/troubleshooting/${caseDetail.id}`,
      [EntityType.CASE]: `/analyze/asset-overview?assets=${caseDetail.asset?.id}&caseId=${caseDetail.id}&layout=${DataExplorerLayouts.DETAIL}&tab=${AssetOverviewTabs.DATA_EXPLORER}`,
    };

    return (
      <EntityDetailHeader type={EntityType.CASE}>
        <div className="left">
          <div className="related">
            <div className="asset-top">
              <StyledDetailsDismissInert className="left">
                <DetailsCurrent>
                  <HeaderCurrentCustomer
                    customerName={caseDetail.site?.customer?.name}
                    siteName={caseDetail.site?.name}
                    onClick={currentCustomerClickHandler}
                  />
                  <HeaderCurrentSite
                    siteName={caseDetail.site?.name}
                    onClick={(e) => handleEntitySelect(e, caseDetail.site?.id, EntityType.SITE)}
                  />
                </DetailsCurrent>
              </StyledDetailsDismissInert>
              <div className="right list-right">
                <div className="wind">
                  <MetricIcon icon={Icons.WIND_BARB_20} />
                  {`${
                    !conditions?.wind ?? isDisconnected ? Placeholders.DASH : conditions?.wind
                  } ${t('metrics.wind_unit', 'm/s')}`}
                </div>
                <div className="temp">
                  <MetricIcon icon={Icons.TEMPERATURE} />
                  {`${
                    !conditions?.temp ?? isDisconnected ? Placeholders.DASH : conditions?.temp
                  } ${t('metrics.temp_unit', '°C')}`}
                </div>
                {/* As per Product, commenting for now */}
                {/* <div className="phone">
                  <button type="button">
                    <MetricIcon icon={Icons.PHONE_CONTACT} />
                  </button>
                </div> */}
              </div>
            </div>
            <div className="asset-bottom">
              <div className="left">
                <button
                  type="button"
                  onClick={(e) => handleEntitySelect(e, caseDetail.asset?.id, EntityType.TURBINE)}
                >
                  <HeaderIcon icon={Icons.TURBINE} />
                  <Badge
                    color={getTurbineStateColor(caseDetail.asset?.state)}
                    label={getTurbineStateType(caseDetail.asset?.state)}
                    medium
                  />
                  &nbsp;
                  <div className="asset-name">{caseDetail.asset?.name}</div>
                  <ButtonIcon icon={Icons.CHEVRON} rotate={-90} />
                </button>
              </div>
              <div className="right list-right">
                <div>
                  <MetricIcon icon={Icons.EVENT} />
                  {caseDetail.asset?.events || '0'}
                </div>
                {/*<div>*/}
                {/*  <MetricIcon icon={Icons.ANOMALY} />*/}
                {/*  {assetMetrics.openCases ?? '-'}*/}
                {/*</div>*/}
                <div>
                  <MetricIcon icon={Icons.TASK} />
                  {isInThisCaseLoading ? Placeholders.DASH : getOpenTasks(tasks)?.length || '0'}
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <TypeIcon icon={icon} />
            <div className="right">
              <div className="code">
                <span>{t('case', 'Case')}</span>
                <span>
                  #{caseDetail.externalId || caseDetail.id}
                  {caseDetail.code}
                </span>
              </div>
              <div className="title">
                <Severity
                  size={12}
                  level={String(
                    entityType === EntityType.EVENT ? caseDetail.severity : caseDetail.priority,
                  ).toLowerCase()}
                />
                <span>
                  {caseDetail.description.length > 40
                    ? caseDetail.description.substring(0, 40) + '...'
                    : caseDetail.description}
                </span>
              </div>
              <div className="owner">
                {/* As per Product, commenting for now */}
                {/* {t('owner', 'Owner')}
                :
                <HeaderIcon icon={Icons.PERSON} /> DE */}
                <StatusContainer className="status">
                  {isCaseUpdating ? (
                    <DotsLoader />
                  ) : (
                    <>
                      <div className="status-details">
                        <div>{caseDetail.status?.toUpperCase()}</div>
                        {caseDetail.snoozeDate && (
                          <div className="snooze-date">
                            (Until{' '}
                            {dayjs(caseDetail.snoozeDate).format(
                              DateTimeFormats.SNOOZE_DATE_FORMAT,
                            )}
                            )
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </StatusContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="actions">
          <AuthRender
            capability={Capability.CASES_RECOMMENDATIONS}
            admin
            create
            edit
            delete
            description="Case details header - Link button"
            siteIds={[caseDetail.site?.id]}
          >
            <HeaderButton
              onClick={() => setShowLinkModal(true)}
              disabled={caseDetail.status === CaseStatus.CLOSED}
            >
              <HeaderIcon icon={Icons.CASE} /> {t('link', 'Link')}
            </HeaderButton>
          </AuthRender>
          <AuthRender
            capability={Capability.NOTES_AND_ATTACHMENTS}
            create
            description="Case details header - new note button"
            siteIds={[caseDetail.site?.id]}
          >
            <HeaderButton
              onClick={handleNewNote}
              disabled={caseDetail.status === CaseStatus.CLOSED}
            >
              <HeaderIcon icon={Icons.NOTE} /> {t('add_note', 'add note')}
            </HeaderButton>
          </AuthRender>
          <AuthRender
            capability={Capability.FIELD_TASKS}
            create
            description="Case details header - new task button"
            siteIds={[caseDetail.site?.id]}
          >
            <HeaderButton
              onClick={handleNewTask}
              disabled={caseDetail.status === CaseStatus.CLOSED}
            >
              <HeaderIcon icon={Icons.TASK} /> {t('new_task', 'new task')}
            </HeaderButton>
          </AuthRender>
          {/* <button type="button" onClick={(e) => handleCloseOpenTask(e, caseDetail)}> */}
          <AuthRender
            capability={Capability.CASES_RECOMMENDATIONS}
            edit
            description="Case details header - close case button"
            siteIds={[caseDetail.site?.id]}
          >
            <HeaderButton
              onClick={() => handleCloseCase(caseDetail.id)}
              disabled={caseDetail.status === CaseStatus.CLOSED}
            >
              <HeaderIcon icon={Icons.ARROW_UP} rotate={90} /> {t('close', 'close')}
            </HeaderButton>
          </AuthRender>
          {troubleshoot && (
            <div className="last-btn">
              <button
                type="button"
                onClick={(e) => handleEntitySelect(e, caseDetail.id, entityType)}
              >
                {t('case_details', 'Case Details')}
              </button>
            </div>
          )}
          {!troubleshoot && troubleshootLink[entityType] && (
            <div className="last-btn">
              <NavLink to={troubleshootLink[entityType]}>
                <HeaderIcon icon={Icons.TROUBLESHOOT} /> {t('troubleshoot', 'troubleshoot')}
              </NavLink>
            </div>
          )}
        </div>
        {showNewTaskDialog && (
          <NewTaskDialog
            entity={caseDetail}
            entityId={caseDetail.asset?.id}
            associationId={caseDetail.id}
            entityType={EntityType.CASE}
            onClose={handleCloseNewTaskDialog}
            onConfirm={handleSaveNewTask}
            taskSource={TaskSourceField.MANUALSITE}
            title={caseDetail.description}
          />
        )}
        <NoteDialog
          onClose={handleCloseAddNoteDialog}
          isOpen={showAddNoteModal}
          onSaveNote={handleAddNote}
          entity={caseDetail}
          entityType={EntityType.CASE}
          mode={FormMode.CREATE}
        />
        <CaseLinkProvider
          isOpen={showLinkModal}
          entity={caseDetail}
          onClose={handleLinkModalClose}
          onSave={handleSaveLink}
        >
          <CaseLinkDialog />
        </CaseLinkProvider>
        <ConditionalRender shouldRender={showCaseSnoozeModal}>
          <CaseSnoozeDialog
            isOpen={showCaseSnoozeModal}
            onClose={() => setShowCaseSnoozeModal(false)}
            onSave={handleSnoozeSave}
            caseDetails={caseDetail}
          />
        </ConditionalRender>
      </EntityDetailHeader>
    );
  },
);

CaseDetailsHeader.propTypes = {
  caseDetail: PropTypes.instanceOf(Object).isRequired,
  troubleshoot: PropTypes.bool,
  entityType: PropTypes.oneOf([EntityType.ANOMALY, EntityType.CASE]).isRequired,
  handleCloseCase: PropTypes.func,
};

CaseDetailsHeader.defaultProps = {
  troubleshoot: false,
  handleCloseCase: () => null,
};
