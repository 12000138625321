import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, Suspense, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ComponentLoader } from '@ge/components/loader/loader';
import { AssetContext } from '@ge/feat-analyze/context/assetContext';
import { Capability } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { AuthRender } from '@ge/shared/components/auth-render';

import { solarAssetKPISelectorData, assetSolarChartConfig } from '../../../../constants';
import { AssetEventsProvider } from '../../../../context';
import { useAssetDetail } from '../../../../data-hooks/use-asset-detail';
import { KpiChartConatinerTitle } from '../../../shared/dynamic-components/kpi-chart-container-title';
import ErrorBoundary from '../../../shared/error-boundary/error-boundary';
import { KpiChartSelector } from '../../../shared/kpi-chart-selector/kpi-chart-selector';
import {
  ChartGraphButtonStyled,
  ChartHeaderStyled,
} from '../../../shared/styled/site-content-styled';
import { IssuesSidebar } from '../issues-sidebar';

const AssetDetailsContainer = styled.div`
  display: flex;
  margin-right: 20px;
  min-height: 300px;
`;

const ChartsContainer = styled.div`
  flex: 1;
  .CustomDropdown {
    width: 20% !important;
  }
  .select__control {
    padding: 2px;
  }
  border: ${(props) => (props.hideBorder ? 'none' : '1px solid' + props.theme.charts.borderColor)};
  padding: 10px;
  margin-top: 10px;
`;

const NAMESPACE = 'analyze.dashboard';

const AssetDetails = () => {
  // store
  const getAssetById = useStoreState((store) => store.assets.getAssetById);
  const { assetState, queryParam } = useContext(AssetContext);

  const entity = useAssetDetail({ assetId: assetState.selectedAssetId });
  const { site } = entity;
  const getDateRange = useStoreState(({ analyze }) => analyze.getDateRange);
  const dateRange = getDateRange(site?.timezone);
  const [selectedCombinedState, setSelectedCombinedState] = useState(null);
  const [dynamicProps, setDynamicProps] = useState(null);
  const [LazyLoadedComponent, setLazyLoadedComponent] = useState(null);
  const assetConfig = assetSolarChartConfig;
  // setting AssetId to static value 27171218
  const invertersId = 27171218;
  const { name: assetName } = getAssetById(invertersId) ?? {};
  const childRef = useRef();
  const changeHandler = (combinedState) => {
    setSelectedCombinedState(combinedState);
  };

  const { t } = useTranslation([NAMESPACE], { useSuspense: false });

  useEffect(() => {
    let key;
    for (key in selectedCombinedState) {
      if (selectedCombinedState[key]?.component?.path) {
        break;
      }
    }
    if (key && selectedCombinedState[key]?.component?.path) {
      setDynamicProps({
        ...(selectedCombinedState[key]?.component?.props &&
          selectedCombinedState[key].component.props),
        dateRange: {
          startDate: dateRange.startDate.entityTimezone,
          endDate: dateRange.endDate.entityTimezone,
        },
        selectedCombinedState,
        assetConfig,
        assetType: 'solar',
        type: 'inverter',
        assetIds: invertersId,
        assetName: assetName,
      });
      setLazyLoadedComponent(
        React.lazy(() => import(`../../../shared/${selectedCombinedState[key].component.path}`)),
      );
    }
  }, [selectedCombinedState, dateRange, assetName]);

  return (
    <AssetDetailsContainer>
      <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteLevel={false} view>
        <IssuesSidebar />
      </AuthRender>
      {/* <AuthRender capability={Capability.CORE_KPIS} siteLevel={false} view> */}
      <ChartsContainer>
        <KpiChartConatinerTitle
          title={t('asset_trends', 'Asset Trends')}
          id={AnalyzeLocators.ANALYZE_KPI_ASSET_OVERVIEW_CHART_TITLE}
        />
        <ChartHeaderStyled>
          <KpiChartSelector
            kpiSelectorData={solarAssetKPISelectorData}
            siteType={queryParam.type}
            onChange={changeHandler}
          />
          <ChartGraphButtonStyled onClick={() => childRef.current.exportGraph()} />
        </ChartHeaderStyled>
        <ErrorBoundary>
          <Suspense fallback={<ComponentLoader />}>
            {LazyLoadedComponent && (
              <LazyLoadedComponent ref={childRef} {...(dynamicProps && dynamicProps)} />
            )}
          </Suspense>
        </ErrorBoundary>
      </ChartsContainer>
      {/* </AuthRender> */}
    </AssetDetailsContainer>
  );
};

// wrap in providers for export
export const AssetOverviewDetailSolar = () => (
  <AssetEventsProvider>
    <AssetDetails />
  </AssetEventsProvider>
);

AssetDetails.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};
AssetDetails.defaultProps = {
  dateRange: {
    startDate: '',
    endDate: '',
  },
};
