import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AccordionList } from '@ge/components/accordion-list';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  margin-bottom: 10px;
  .title {
    border-bottom: 0;
    color: ${({ theme }) => theme.analyze.filter.collapseHeader};
    margin-bottom: 8px;
    padding: 0;
    h2 {
      font: 700 11px/13px 'Museo Sans';
      letter-spacing: 0.5px;
      margin-left: ${({ enableToggle }) => (!enableToggle ? '0px' : '5px')};
      text-transform: uppercase;
    }
  }
  .carot {
    fill: ${({ theme }) => theme.analyze.filter.collapseArrow};
    padding: 0;
  }
  .collapsible-panel-content {
    button {
      color: ${({ theme }) => theme.analyze.filter.showMoreButton};
      font: 300 12px/15px;
      margin: -8px 0 8px 2px;
      padding: 0;
      text-decoration: underline;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  margin-left: 1px;
  padding: 6px 0;
  span {
    font: 300 12px/15px;
  }
`;

const getIsAllChecked = (group, checked) => group?.every((item) => checked.includes(item));

const CollapsibleFilterGroup = ({ group, onChange, selectAll, state, title, enableToggle }) => {
  const { t } = useTranslation(['general'], {
    useSuspense: false,
  });

  const allChecked = getIsAllChecked(group, state);

  const checked = state;
  const [triggerHeight, setTriggerHeight] = useState(false);

  useEffect(() => {
    setTriggerHeight((prev) => !prev);
  }, [group]);

  const toggleAllCheckboxes = useCallback(() => {
    if (allChecked) {
      const updated = [];

      onChange(updated);
    } else {
      onChange(group);
    }
  }, [allChecked, group, onChange]);

  const toggleCheckbox = useCallback(
    (filter) => {
      let updated;

      if (checked.includes(filter)) {
        updated = checked.filter((checkFilter) => checkFilter !== filter);
      } else {
        updated = [...checked, filter];
      }

      onChange(updated);
    },
    [checked, onChange],
  );

  const handleAccordionToggled = useCallback(() => {
    setTriggerHeight((prev) => !prev);
  }, []);

  const isChecked = (filter) =>
    checked.includes(filter) ? CheckedState.CHECKED : CheckedState.UNCHECKED;

  return (
    <StyledCollapsiblePanel
      expanded={true}
      headerContent={<h2>{title}</h2>}
      triggerHeight={triggerHeight}
      enableToggle={enableToggle}
    >
      <AccordionList showCount={4} onAccordionToggled={handleAccordionToggled}>
        {[
          selectAll ? (
            <StyledCheckbox
              key={0}
              label={t('all', 'All')}
              onChange={() => toggleAllCheckboxes()}
              checkState={allChecked ? CheckedState.CHECKED : CheckedState.UNCHECKED}
            />
          ) : null,
          ...group?.map((filter) => (
            <StyledCheckbox
              key={filter}
              label={filter}
              onChange={() => toggleCheckbox(filter)}
              checkState={isChecked(filter)}
            />
          )),
        ]}
      </AccordionList>
    </StyledCollapsiblePanel>
  );
};

CollapsibleFilterGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  selectAll: PropTypes.bool,
  state: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  enableToggle: PropTypes.bool,
};

CollapsibleFilterGroup.defaultProps = {
  onChange: () => {},
  selectAll: false,
  state: [],
  title: '',
  enableToggle: true,
};

export default CollapsibleFilterGroup;
