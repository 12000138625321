import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Dialog } from '@ge/components/modal';
import { Severity } from '@ge/components/severity';
import { DateTimeFormats } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';

import { getAssetIcon } from '../../util';

const DialogWrapper = styled.div`
  width: 300px;
  max-width: 100%;
  padding: 14px 25px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 15px;
  }
  button {
    padding: 5px 15px;
  }
  button:last-of-type {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const EntityInfo = styled.div`
  color: ${({ theme }) => theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
`;

const EntityInfoIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.entityDetails.notes.entityInfo,
}))`
  margin-right: 5px;
`;

const EntityDescription = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 4px;
  svg {
    margin-right: 5px;
  }
`;

const EntityDescriptionIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.entityDetails.notes.entityDescription,
}))`
  margin-right: 5px;
`;

const TitleContainer = styled.div`
  padding-bottom: 10px;
`;

const TitleRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.entityDetails.notes.separator};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const DialogBody = styled.div`
  padding-bottom: 10px;
  width: 125px;
`;

const StyledInput = styled(Input)`
  border-radius: 3px;
  color: ${globalColors.slate4};
  font-style: italic;
  font-size: 12px;
`;

const CalendarIconContainer = styled.div`
  position: absolute;
  bottom: 9px;
  right: 4px;
`;

const CalendarIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
  icon: Icons.CALENDAR,
  size: 10,
}))`
  margin-right: 4px;
`;

export const CaseSnoozeDialog = React.memo(({ isOpen, onClose, onSave, caseDetails }) => {
  const { t, ready } = useTranslation(['entity-details'], { useSuspense: false });
  const [snoozeDate, setSnoozeDate] = useState();
  const formattedSnoozeDate = useMemo(
    () => (snoozeDate ? dayjs(snoozeDate).format(DateTimeFormats.SNOOZE_DATE_FORMAT) : ''),
    [snoozeDate],
  );

  const minDate = useMemo(
    () =>
      dayjs()
        .add(1, 'day')
        .toDate(),
    [],
  );

  const onChange = (date) => {
    setSnoozeDate(date);
  };

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    onSave(snoozeDate);
  }, [onSave, snoozeDate]);

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      contentWidth={true}
      padContent={false}
      onClose={onCancel}
      header={t('case_panel.snooze', 'SNOOZE')}
      footer={
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={onCancel}>{t('general:cancel', 'Cancel')}</Button>
            <Button primary onClick={onSubmit} disabled={!snoozeDate}>
              {t('case_panel.snooze', 'Snooze')}
            </Button>
          </ButtonContainer>
        </FooterContainer>
      }
    >
      <DialogWrapper>
        <TitleContainer>
          <TitleRow>
            <EntityInfo>
              <EntityInfoIcon icon={Icons.SITE} color={'transparent'} size={11} />
              <span>{`${caseDetails.site.name} / `}</span>
              <EntityInfoIcon
                icon={getAssetIcon(caseDetails.asset.type)}
                color={'transparent'}
                size={11}
              />
              <span>{caseDetails.asset.name}</span>
            </EntityInfo>
            <EntityDescription>
              <EntityDescriptionIcon
                icon={caseDetails.isParent ? Icons.CASE : Icons.ANOMALY}
                color={'transparent'}
                size={20}
              />
              <Severity level={caseDetails.priority} />
              {caseDetails.description}
            </EntityDescription>
          </TitleRow>
        </TitleContainer>
        <DialogBody>
          <DatePicker
            selected={snoozeDate}
            startDate={snoozeDate}
            minDate={minDate}
            onChange={onChange}
            customInput={
              <div>
                <StyledInput
                  autoComplete="off"
                  label={t('case_panel.snooze_until', 'Snooze until')}
                  onChange={() => null}
                  required
                  value={formattedSnoozeDate}
                />
                <CalendarIconContainer>
                  <CalendarIcon />
                </CalendarIconContainer>
              </div>
            }
          />
        </DialogBody>
      </DialogWrapper>
    </Dialog>
  );
});

CaseSnoozeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  caseDetails: PropTypes.instanceOf(Object).isRequired,
};

CaseSnoozeDialog.displayName = 'CaseSnoozeDialog';
