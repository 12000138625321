import { useState, useMemo, useEffect } from 'react';

import {
  MonitorCaseStatus,
  EntityType,
  SystemResetStatus,
  MonitorDefs,
  CaseTypeLabel,
  CaseQueueAssetStateFilter,
  BOPSiteState,
} from '@ge/models/constants';
import { AssetType } from '@ge/models/entities/asset/constants';

import { EventsColumns } from '../models/events-table-cols';

export const useMonitorCaseTableFilter = ({ filterValues, filterDefs, tableType }) => {
  const staticFilterFields = useMemo(
    () => [
      EventsColumns.EVENT_STATUS,
      EventsColumns.TYPE,
      EventsColumns.SYSTEM_RESET,
      EventsColumns.EVENT_TYPE,
      EventsColumns.ASSET_STATUS,
      EventsColumns.BOP_SITE,
    ],
    [],
  );
  const staticFilterValues = useMemo(
    () => ({
      [EventsColumns.EVENT_STATUS]:
        tableType === MonitorDefs.HISTORY
          ? [MonitorCaseStatus.CLOSED]
          : [
              MonitorCaseStatus.NEW,
              MonitorCaseStatus.RETURNED,
              MonitorCaseStatus.SNOOZED,
              MonitorCaseStatus.IN_PROGRESS,
            ],
      [EventsColumns.TYPE]: [
        EntityType.SITE,
        EntityType.FLEET,
        AssetType.WIND_TURBINE,
        AssetType.SUBSTATION,
        AssetType.SITE_CONTROLLER,
        AssetType.STORAGE_INVERTER,
      ],
      [EventsColumns.SYSTEM_RESET]: [
        SystemResetStatus.IN_PROGRESS,
        SystemResetStatus.COMPLETED,
        SystemResetStatus.FAILED,
        SystemResetStatus.NO_AUTOMATION,
      ],
      [EventsColumns.EVENT_TYPE]: [...new Set(Object.values(CaseTypeLabel))],
      [EventsColumns.ASSET_STATUS]: [
        CaseQueueAssetStateFilter.ONLINE,
        CaseQueueAssetStateFilter.AVAILABLE,
        CaseQueueAssetStateFilter.IMPACTED,
        CaseQueueAssetStateFilter.TRIPPED,
        CaseQueueAssetStateFilter.STOPPED,
        CaseQueueAssetStateFilter.MAINTENANCE,
        CaseQueueAssetStateFilter.REPAIR,
        CaseQueueAssetStateFilter.NO_COMM,
      ],
      [EventsColumns.BOP_SITE]: [BOPSiteState.YES, BOPSiteState.NO],
    }),
    [tableType],
  );

  const filters = useMemo(
    () =>
      Object.keys(filterDefs).reduce(
        (_filter, key) => ({
          ..._filter,
          [key]: {
            ...filterDefs[key],
            value: staticFilterFields.includes(key)
              ? staticFilterValues[key]
              : filterDefs?.[key]?.value,
          },
        }),
        {},
      ),
    [filterDefs, staticFilterFields, staticFilterValues],
  );

  const [modifiedFilters, setModifiedFilters] = useState({});

  useEffect(() => {
    setModifiedFilters({
      ...filters,
      [EventsColumns.EVENT_STATUS]: {
        value:
          tableType === MonitorDefs.HISTORY
            ? [MonitorCaseStatus.CLOSED]
            : filterDefs?.[EventsColumns.EVENT_STATUS]?.value,
      },
      [EventsColumns.TYPE]: { value: filterDefs?.[EventsColumns.TYPE]?.value },
      [EventsColumns.SYSTEM_RESET]: { value: filterDefs?.[EventsColumns.SYSTEM_RESET]?.value },
      [EventsColumns.EVENT_TYPE]: { value: filterDefs?.[EventsColumns.EVENT_TYPE]?.value },
      [EventsColumns.ASSET_STATUS]: { value: filterDefs?.[EventsColumns.ASSET_STATUS]?.value },
      [EventsColumns.BOP_SITE]: { value: filterDefs?.[EventsColumns.BOP_SITE]?.value },
    });
  }, [filters, filterDefs, tableType]);

  let modifiedFiltersValues = useMemo(() => {
    let modifiedFiltersValues = {};
    Object.keys(filters ?? {}).forEach((key) => {
      modifiedFiltersValues[key] = staticFilterFields.includes(key)
        ? filters?.[key]?.value
        : filterValues?.[key];
    });
    return modifiedFiltersValues;
  }, [filters, filterValues, staticFilterFields]);

  return {
    modifiedFilters,
    setModifiedFilters,
    modifiedFiltersValues,
  };
};
