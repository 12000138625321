import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchPersonByMultipleUsername = async (username) =>
  request.get(`/cmn/admin/users/multiple/${username}`, { baseURL: USERS_BASE_URL });

export const useFetchMultiplePerson = (username) => {
  const {
    error,
    isError,
    status,
    refetch,
    isLoading: isLoadingMultiplePerson,
    isFetching: isFetchingMultiplePerson,
    data: fetchedMultiplePerson,
  } = useQuery(
    [QueryKey.FETCH_MULTIPLE_PERSON, { username }],
    () => {
      if (username.length > 0) return fetchPersonByMultipleUsername(username);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      keepPreviousData: false,
      cacheTime: 0,
    },
  );

  return {
    isLoading: isLoadingMultiplePerson,
    isFetching: isFetchingMultiplePerson,
    isError,
    status,
    error,
    refetch,
    data: fetchedMultiplePerson,
  };
};
