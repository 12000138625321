import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { AssetType } from '@ge/models';
import { KpiCategoryDefs, SortValueType, ConditionType } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { AssetsColumns } from '@ge/shared/models/table-col-defs';
import { roundNumber } from '@ge/util';
import { sorter } from '@ge/util/metric-sorter';

import useGetSitesKpiData from './use-get-sites-kpi-data';

/**
 * Use region IEC data.
 *
 * @param siteIds the site ids
 * @param def
 * @param categories the kpi categories
 * @param timeAggr the time aggregate
 * @param startDate the start date
 * @param endDate the end date
 * @param sortMetric the sort KPI metric
 * @param sortDirection the sort direction
 * @param entityAggr aggregate both entity and date
 * @param seriesEntityAggr aggregate entity (all metrics kpi only)
 * @param dateEntityAggr aggregate date (all metrics kpi only)
 */
const useSitesKpiData = (params) => {
  const { assets: assetsById } = useStoreState(({ assets }) => assets);
  return useGetSitesKpiData({ hydrateBy: assetsById, ...params });
};

export const useSiteAssetKpiData = (
  siteId,
  {
    kpiData,
    sortMetric,
    sortDirection = SortDirection.ASC,
    isLoading,
    search,
    filters,
    filterSequence,
  },
) => {
  // store
  const getFilteredAssetsBySiteId = useStoreState(
    (state) => state.analyze.getFilteredAssetsBySiteId,
  );

  let assets = getFilteredAssetsBySiteId(siteId, AssetType.WIND_TURBINE);

  const kpiAssets = useMemo(() => {
    const kpiLookup = Object.entries(kpiData ?? {}).reduce(
      (lookup, [category, { entityData }]) => {
        if (!entityData?.length) {
          return lookup;
        }

        const categoryData = {};

        for (const {
          entity: { id },
          value,
        } of entityData) {
          categoryData[id] = value;
          lookup.ids[id] = id;
        }

        lookup[category] = categoryData;
        lookup.categories[category] = category;

        return lookup;
      },
      {
        // using as a convenient flattened dictionary for all unique asset ids from kpi
        ids: {},
        categories: {},
      },
    );

    const kpis = Object.keys(kpiLookup.categories);
    if (kpis.length === 1 && kpis[0] === KpiCategoryDefs.AVAILABILITY_CONTRACT) {
      return [];
    }

    return assets?.reduce((_kpiAssets, asset) => {
      const { id } = asset;

      // only include assets that have kpi data
      if (!kpiLookup.ids[id]) {
        return _kpiAssets;
      }

      const performance = {
        [KpiCategoryDefs.CAPACITY_FACTOR]: roundNumber(
          kpiLookup[KpiCategoryDefs.CAPACITY_FACTOR]?.[id],
          2,
        ),
        [KpiCategoryDefs.EVENT_COVERAGE]: roundNumber(
          kpiLookup[KpiCategoryDefs.EVENT_COVERAGE]?.[id],
          2,
        ),
        [KpiCategoryDefs.PRODUCTION_ACTUAL]: roundNumber(
          kpiLookup[KpiCategoryDefs.PRODUCTION_ACTUAL]?.[id],
          2,
        ),
        [KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]: roundNumber(
          kpiLookup[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]?.[id],
          2,
        ),
        [KpiCategoryDefs.TBA]: roundNumber(kpiLookup[KpiCategoryDefs.TBA]?.[id], 2),
        [KpiCategoryDefs.PBA]: roundNumber(kpiLookup[KpiCategoryDefs.PBA]?.[id], 2),
        [KpiCategoryDefs.AVAILABILITY_CONTRACT]: roundNumber(
          kpiLookup[KpiCategoryDefs.AVAILABILITY_CONTRACT]?.[id],
          2,
        ),
      };

      const conditions = { windSpeedAverage: roundNumber(kpiLookup[ConditionType.WIND]?.[id], 1) };

      const kpiColumnValues = {
        [AssetsColumns.TBA]: { value: performance[KpiCategoryDefs.TBA] },
        [AssetsColumns.PBA]: { value: performance[KpiCategoryDefs.PBA] },
        [AssetsColumns.AVAILABILITY_CONTRACT]: {
          value: performance[KpiCategoryDefs.AVAILABILITY_CONTRACT],
        },
        [AssetsColumns.CAPACITY_FACTOR]: { value: performance[KpiCategoryDefs.CAPACITY_FACTOR] },
        [AssetsColumns.EVENT_COVERAGE]: { value: performance[KpiCategoryDefs.EVENT_COVERAGE] },
        [AssetsColumns.ACTUAL_PRODUCTION_MWH]: {
          value: performance[KpiCategoryDefs.PRODUCTION_ACTUAL],
        },
        [AssetsColumns.UNPRODUCED_ENERGY_MWH]: {
          value: performance[KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT],
        },
        [AssetsColumns.AVERAGE_WINDSPEED]: {
          value: conditions.windSpeedAverage,
        },
      };

      const kpiAsset = { ...asset, ...kpiColumnValues };
      kpiAsset.metrics = kpiAsset.metrics ?? {};
      kpiAsset.metrics.performance = performance;
      kpiAsset.metrics.conditions = conditions;
      kpiAsset[AssetsColumns.ASSET_MAKE] = asset.make;
      kpiAsset[AssetsColumns.ASSET_MODEL] = asset.model;
      kpiAsset[AssetsColumns.ASSET] = asset.name;
      _kpiAssets.push(kpiAsset);

      return _kpiAssets;
    }, []);
  }, [assets, kpiData]);

  const sortedKpiAssets = useMemo(() => {
    const sortType = sortMetric === 'name' ? SortValueType.ALPHANUMERIC : '';

    return [...kpiAssets].sort(sorter(sortMetric, sortDirection, sortType));
  }, [kpiAssets, sortDirection, sortMetric]);

  const { data: _data, filterValues } = useTableFilter({
    data: sortedKpiAssets ?? [],
    filterSequence,
    filters,
    search,
  });

  return { kpiData, data: _data, isLoading, filterValues };
};

export default useSitesKpiData;
