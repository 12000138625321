import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { useManualAdjustmentContext } from '@ge/feat-analyze/context/manual-adjustment-context';
import { useContractualReportingEvents } from '@ge/feat-analyze/data-hooks/use-contractual-reporting-events';
import { ContractualAvailabilitySortMetric } from '@ge/feat-analyze/models/constants';
import {
  Placeholders,
  SitePerformanceAggregateKey,
  SortDirection,
  DateTimeFormats,
  DataLoaderType,
  KpiCategoryDefs,
  TimeAggr,
  Capability,
  PermissionScope,
} from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { globalColors } from '@ge/tokens';

import useSitesKpiData from '../../data-hooks/use-sites-kpi-data';
import { AvailabilityKpi } from '../../models/manual-adjustment-defs';

import { EventBulkEditDialog } from './event-bulk-edit-dialog';
import { EventBulkEditSummary } from './event-bulk-edit-summary';
import { LegendMenu } from './legend-menu';
import { SitePanelChart } from './site-panel-chart';
import { PanelContainer, Timeline, EndingColumn } from './site-panel-chart-style';

const categories = [KpiCategoryDefs.AVAILABILITY_CONTRACT];

const PanelContentHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 0 7px;
`;

const PanelContentSection = styled.div`
  display: flex;
  > .panel-content {
    flex: 1;
  }
`;

const SelectContainer = styled.div`
  > div {
    flex-direction: row;
  }
  label {
    text-transform: uppercase;
    margin: 0 5px 0 0;
    align-self: center;
    color: #999999;
    letter-spacing: 0.5px;
    font-weight: 700;
  }
`;

const KpiCell = styled.div`
  width: 100px;
  max-width: 100px;
  padding: 5px 10px;
  box-sizing: border-box;
  justify-content: center;
  flex: 1;
`;

const KpiTitle = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KpiTitleText = styled.h3`
  text-align: center;
  color: ${(props) => props.theme.analyze.manualAdjustment.kpiTitleColor};
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  margin-bottom: 6px;
`;

const KpiMonthLabel = styled.span`
  display: block;
`;

const KpiValuesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const KpiContainer = styled.div`
  display: flex;
  text-align: center;
  color: ${(props) => props.theme.analyze.manualAdjustment.kpiColor};
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  &:nth-of-type(2) {
    margin-left: 4px;
    &:before {
      content: '/';
      font-weight: 100;
      margin-right: 4px;
    }
  }
`;

const KpiUnit = styled.sup`
  color: ${(props) => props.theme.analyze.manualAdjustment.kpiDescColor};
  font-size: 10px;
  letter-spacing: 0.45px;
  line-height: 12px;
  margin-left: 2px;
  align-self: flex-start;
`;

const LegendSeperator = styled.hr`
  border: 1px solid ${globalColors.slate2};
  margin: 0 0 10px;
`;

const SortDirectionMetricMap = {
  [SitePerformanceAggregateKey.LOWEST_PERFORMERS]: {
    direction: SortDirection.ASC,
    metric: ContractualAvailabilitySortMetric.PERFORMANCE,
  },
  [SitePerformanceAggregateKey.HIGHEST_PERFORMERS]: {
    direction: SortDirection.DESC,
    metric: ContractualAvailabilitySortMetric.PERFORMANCE,
  },
  [SortDirection.ASC]: {
    direction: SortDirection.ASC,
    metric: ContractualAvailabilitySortMetric.NAME,
  },
  [SortDirection.DESC]: {
    direction: SortDirection.DESC,
    metric: ContractualAvailabilitySortMetric.NAME,
  },
};

export const SitePanelAssets = ({ isOverviewLoading, siteData, triggerHeight }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const anchorEl = useRef(null);

  const [assetSortDirection, setAssetSortDirection] = useState(SortDirection.ASC);

  const { direction, metric } = SortDirectionMetricMap[assetSortDirection];

  const { calendarDate, selectedAssets, selectedEvent, setSelectedEvent } =
    useManualAdjustmentContext();

  const { isAuthorized } = useAuth();

  const isAuthorizedToAddEvent = isAuthorized({
    capabilities: [
      { capability: Capability.CONTRACTUAL_AVAILABILITY_MAL, scopes: [PermissionScope.CREATE] },
    ],
  });

  useEffect(() => {
    if (selectedEvent?.bulk && !selectedAssets?.length) {
      setSelectedEvent(null);
    }
  }, [selectedEvent, selectedAssets, setSelectedEvent]);

  const site = useMemo(() => siteData?.site ?? {}, [siteData]);

  const { startDate, endDate, startOfDay } = useMemo(() => {
    const timezone = site.timezone ?? 'UTC';
    const startDate = dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM);
    const startOfDay = dayjs.tz(startDate, timezone).valueOf();

    return {
      startDate,
      endDate: startDate,
      startOfDay,
    };
  }, [site, calendarDate]);

  const {
    isLoading: isEventsLoading,
    data: eventData,
    error: eventDataError,
    assetEventsKind,
  } = useContractualReportingEvents({
    siteId: site.id,
    sortDirection: direction,
    sortMetric: metric,
    startDate,
    endDate,
  });

  const { data: siteKpiData } = useSitesKpiData({
    categories,
    def: categories,
    endDate,
    siteIds: [site.id],
    startDate,
    timeAggr: TimeAggr.DAILY,
  });

  useEffect(() => {
    triggerHeight(!isEventsLoading);
  }, [isEventsLoading, triggerHeight]);

  const sortOptions = useMemo(
    () => [
      // {
      //   value: SitePerformanceAggregateKey.LOWEST_PERFORMERS,
      //   label: t('sorts.lowest_performance', 'Lowest Performance'),
      // },
      // {
      //   value: SitePerformanceAggregateKey.HIGHEST_PERFORMERS,
      //   label: t('sorts.top_performance', 'Top Performance'),
      // },
      {
        value: SortDirection.ASC,
        label: t('sorts.alpha_asc', 'Alphabetical A-Z'),
      },
      {
        value: SortDirection.DESC,
        label: t('sorts.alpha_desc', 'Alphabetical Z-A'),
      },
    ],
    [t],
  );

  const handleAssetSort = useCallback(
    ({ value }) => {
      setAssetSortDirection(value);
    },
    [setAssetSortDirection],
  );

  const kpiCells = useCallback(
    () =>
      Object.values(AvailabilityKpi).map(({ id: kpiKey, unit }) => {
        let kpiValue = siteKpiData?.availabilityContract?.aggregateValue;
        const monthSelected = dayjs(calendarDate).get('month');
        if (kpiValue === null || kpiValue === undefined || kpiValue === '') {
          kpiValue = Placeholders.DOUBLE_DASH;
        }
        return (
          <KpiCell key={kpiKey}>
            <KpiTitle>
              <KpiTitleText>
                {kpiKey === 'mtd' && (
                  <KpiMonthLabel>
                    {t(`kpi_labels.months.${monthSelected}`, `kpi_labels.months.${monthSelected}`)}
                  </KpiMonthLabel>
                )}
                {t(`kpi_labels.${kpiKey}`, `kpi_labels.${kpiKey}`)}
              </KpiTitleText>
            </KpiTitle>
            <KpiValuesContainer>
              <KpiContainer>
                {kpiValue || Placeholders.DOUBLE_DASH}
                <KpiUnit>{unit}</KpiUnit>
              </KpiContainer>
            </KpiValuesContainer>
          </KpiCell>
        );
      }),
    [calendarDate, t, siteKpiData],
  );

  if (!ready || isOverviewLoading) {
    return <Loader />;
  }

  return (
    <PanelContainer
      isLoading={isEventsLoading || !eventData?.length}
      isBulkEdit={selectedEvent?.bulk}
    >
      <DataLoader
        isLoading={isEventsLoading}
        noData={!eventData?.length}
        noDataDescription={eventDataError?.[0]?.message ?? ''}
        type={DataLoaderType.SPINNER}
      >
        {isAuthorizedToAddEvent && <EventBulkEditSummary site={site} />}
        <PanelContentSection>
          <div
            id={`eventBulkEditDialog-${site.id}`}
            className={`event-detail bulk ${!selectedEvent ? 'hide' : ''}`}
          ></div>
          <div className={'panel-content'}>
            <PanelContentHeader>
              <SelectContainer>
                <Select
                  label={t('sort', 'Sort')}
                  minWidth={180}
                  maxWidth={180}
                  value={sortOptions.find(({ value }) => value === assetSortDirection)}
                  options={sortOptions}
                  onChange={handleAssetSort}
                  primary
                />
              </SelectContainer>
              <Timeline />
              <EndingColumn autoWidth={true}>{kpiCells()}</EndingColumn>
            </PanelContentHeader>
            <LegendMenu assetEventsKind={assetEventsKind} />
            <LegendSeperator />
            <SitePanelChart
              data={eventData}
              site={site}
              sortMetric={metric}
              startOfDay={startOfDay}
              assetEventsKind={assetEventsKind}
            />
          </div>
        </PanelContentSection>
      </DataLoader>
      {isAuthorizedToAddEvent && selectedEvent?.bulk && (
        <EventBulkEditDialog
          onClose={() => setSelectedEvent(null)}
          site={site}
          data={eventData}
          anchorEl={anchorEl}
          portalId={`#eventBulkEditDialog-${site.id}`}
          assetEventsKind={assetEventsKind}
        />
      )}
    </PanelContainer>
  );
};

SitePanelAssets.propTypes = {
  isOverviewLoading: PropTypes.bool,
  siteData: PropTypes.object.isRequired,
  triggerHeight: PropTypes.func,
};
