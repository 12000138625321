import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { TaskStatus } from '@ge/models';
import { TaskSourceField } from '@ge/models/constants';

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

export const SendToBacklogDialog = ({
  showConfirmMessage,
  handleCancel,
  handleConfirm,
  showLoader,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  const getFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <SpinLoader showLoader={showLoader} />
          {showConfirmMessage.status === TaskStatus.UNSCHEDULED &&
          showConfirmMessage.type === TaskSourceField.MAINTENANCE ? (
            <Button primary onClick={handleCancel}>
              {t('general:ok', 'Ok')}
            </Button>
          ) : (
            <>
              <Button onClick={handleCancel}>{t('general:cancel', 'Cancel')}</Button>
              <Button disabled={showLoader} primary onClick={handleConfirm}>
                {t('general:yes', 'Yes')}
              </Button>
            </>
          )}
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [
    handleCancel,
    handleConfirm,
    showConfirmMessage.status,
    showConfirmMessage.type,
    showLoader,
    t,
  ]);

  return (
    <>
      <Dialog
        isOpen={true}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        header={t('general:confirmation', 'Confirmation')}
        footer={getFooter}
        contentWidth
        padContent={true}
      >
        {showConfirmMessage.status === TaskStatus.UNSCHEDULED ? (
          <p>
            {showConfirmMessage.type === TaskSourceField.MAINTENANCE ? (
              <p>
                {t(
                  'tasks:form.confirm_message_unscheduled_due_date',
                  'Due date cannot be modified for Maintenance task(s)',
                )}
              </p>
            ) : (
              <p>
                {t(
                  'tasks:form.confirm_message_unscheduled_anomaly',
                  'Are you sure you want to remove this task from the calendar?',
                )}
              </p>
            )}
          </p>
        ) : (
          <p>
            {t(
              'tasks:form.confirm_message_scheduled',
              'Are you sure you want to unschedule and send this task to the backlog?',
            )}
          </p>
        )}
      </Dialog>
    </>
  );
};

SendToBacklogDialog.propTypes = {
  showConfirmMessage: PropTypes.instanceOf(Object),
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  showLoader: PropTypes.bool,
};

SendToBacklogDialog.defaultProps = {
  showConfirmMessage: null,
  handleCancel: null,
  handleConfirm: null,
  showLoader: false,
};
