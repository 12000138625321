import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Severity } from '@ge/components/severity';
import { DynamicTable } from '@ge/components/table';
import { TableArrow, TooltipCell } from '@ge/components/table/table';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import {
  TaskColumns,
  DefaultTaskColumns,
  TaskColumnDefs,
} from '@ge/feat-analyze/models/configure-case-template';
import { SortDirection } from '@ge/models/constants';
import { elevations } from '@ge/tokens/elevations';

const Container = styled.div`
  .column-group-header {
    display: none;
  }
  .column-header {
    top: 0;
    border-color: transparent !important;
    height: auto;
    vertical-align: middle;
    padding-top: 5px;
    background: none;
  }
  tbody {
    tr:nth-child(even) {
      background: ${(props) => props.theme.table.evenRowColor};
    }
    tr:nth-child(odd) {
      background: ${(props) => props.theme.table.oddRowColor};
    }
  }
`;

const StyledSpan = styled.span`
  white-space: pre;
`;

const EditButton = styled.button``;
const DeleteButton = styled.button``;
const CaseDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

const ButtonIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.entityDetails.headerIconColor,
}))``;

const TasksTable = ({ values, onClickEdit, onClickDelete, isEditing }) => {
  const { t, ready } = useTranslation(['analyze.asset'], {
    useSuspense: false,
  });

  const customCellFn = useCallback(
    (columnKey, cellValue, value) => {
      switch (columnKey) {
        case TaskColumns.PRIORITY:
          return <Severity level={cellValue?.toLowerCase()} />;
        case TaskColumns.TITLE:
        case TaskColumns.DESCRIPTION:
          return (
            <TooltipCell tooltip={cellValue} zIndex={elevations.P20}>
              <StyledSpan>{cellValue}</StyledSpan>
            </TooltipCell>
          );
        case TaskColumns.EDIT:
          return (
            isEditing && (
              <EditButton onClick={() => onClickEdit(value.id)}>
                <ButtonIcon icon={Icons.PENCIL} />
              </EditButton>
            )
          );
        case TaskColumns.DELETE:
          return (
            isEditing && (
              <DeleteButton onClick={() => onClickDelete(value.id)}>
                <ButtonIcon icon={Icons.TRASH} />
              </DeleteButton>
            )
          );
        case TaskColumns.DUE_DATE_OFFSET:
          return cellValue;
        case TaskColumns.TASK_DETAIL:
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          return (
            !isEditing && (
              <CaseDetailButton type="button" onClick={() => onClickEdit(value.id)}>
                <TableArrow />
              </CaseDetailButton>
            )
          );
        default:
          return null;
      }
    },
    [onClickEdit, onClickDelete, isEditing],
  );

  const {
    visibleCols: columns,
    sortDirection,
    sortMetric,
  } = useColumnState({
    columnDefs: TaskColumnDefs,
    defaultCols: DefaultTaskColumns,
    defaultSortMetric: TaskColumns.TITLE,
    defaultSortDirection: SortDirection.DESC,
  });

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: TaskColumnDefs,
    cellValueMapFn: (val) => val,
    customCellFn,
    sortedDirection,
    defaultCellBorder: true,
  });

  if (!ready) return null;

  return (
    <Container>
      <DynamicTable
        columns={
          isEditing
            ? columns.filter((column) => column.id !== TaskColumns.GROUP_TASK_DETAIL)
            : columns.filter(
                (column) =>
                  column.id !== TaskColumns.GROUP_EDIT && column.id !== TaskColumns.GROUP_DELETE,
              )
        }
        values={values}
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        onValueSelect={() => {}}
        rowKeyProperty={TaskColumns.ID}
        scrollable={values.length > 5}
      />
    </Container>
  );
};

TasksTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  scrollable: PropTypes.bool,
  assetId: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  height: PropTypes.number,
  isEditing: PropTypes.bool,
};

TasksTable.defaultProps = {
  values: [],
  scrollable: false,
  onClickEdit: () => null,
  onClickDelete: () => null,
  height: 400,
  isEditing: true,
};

export default TasksTable;
