import { Capability, PermissionScope } from '@ge/models/constants';

export const NavItems = [
  {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
    label: 'Cases Queue',
    i18nKey: 'nav:monitor.l3.issues.case_queue',
    route: '/monitor/cases/events',
  },
  {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.VIEW] }],
    label: 'Cases History',
    i18nKey: 'nav:monitor.l3.issues.case_history',
    route: '/monitor/cases/history',
  },
  {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.VIEW] },
    ],
    label: 'Anomalies',
    i18nKey: 'nav:monitor.l3.issues.anomalies',
    route: '/monitor/cases/anomalies',
  },
];
