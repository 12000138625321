import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonGroup } from '@ge/components/button';
import { ConditionalRender } from '@ge/components/conditional-render';
import { MultiPathIcons, Icon, Icons } from '@ge/components/icon';
import { Capability, EntityType, PermissionScope } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ChevronIcon } from '@ge/shared/components/icons/chevron-icon';
import { NavIcon } from '@ge/shared/components/icons/nav-icon';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDateTimeBasedOnZone } from '@ge/shared/util/time-date';
import { globalColors } from '@ge/tokens';

import { getParentEntityUrl, getParentId } from '../../util';

import { DashboardHeaderMenu } from './dashboard-header-menu';
import { DashboardStatsHeader } from './dashboard-stats-header';

const ToggleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 26px 70px;
  ${ButtonGroup} {
    margin: 30px 15px 0 0;
  }
`;

const DateBelowSiteName = styled.div`
  padding-left: 30px;
`;

const StyledDashNav = styled.div`
  display: flex;
  ${(props) => props.shouldAlignCenter && 'align-items: center;'}
  a,
  button {
    display: inline-block;
    text-decoration: none;
    color: ${(props) => props.theme.layout.textColor};
    font-size: 12px;
    span {
      margin: 0 6px;
      font-size: 16px;
    }
  }
`;

const NavLinksContainer = styled.div`
  padding-left: 2px;
`;

const StyledSeparator = styled.span`
  margin: 0 6px;
  font-size: 16px;
`;

const StyledBackLink = styled(NavLink)`
  color: ${({ theme }) => theme.analyze.header.backButtonTextColor};
  margin-top: 1px;
  width: 20px;
`;

const StyledHeading = styled.h1`
  line-height: ${({ trimWhitespace }) => (trimWhitespace ? '0' : 'normal')};
  svg {
    margin: 0px 4px 4px 0px;
  }
`;

const DashStatsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.theme.analyze.header.kpiIconColor,
}))`
  vertical-align: initial;
  margin-right: 4px;
`;

const DashNav = ({ noTitle, shouldAlignCenter, children }) =>
  !noTitle && <StyledDashNav shouldAlignCenter={shouldAlignCenter}>{children}</StyledDashNav>;

const BackButton = ({ toURL }) => (
  // If user has FRO KPIs, redirect to parent view else to 'Sites' view from 'Site' page
  // Asset view redirects to Site page irrespective of permissions
  <StyledBackLink
    data-testid={AnalyzeLocators.ANALYZE_HEADER_BACK_BUTTON}
    to={toURL}
    shouldAlignCenter={true}
  >
    <ChevronIcon />
  </StyledBackLink>
);

const RegionLink = ({ fallback, id, regionId }) => (
  <AuthRender capability={Capability.FRO_KPIS} fallback={fallback} siteIds={[id]} view>
    <NavLinksContainer>
      <NavLink to={`/analyze/region/${regionId}`}>{fallback}</NavLink>
    </NavLinksContainer>
  </AuthRender>
);

const CustomerNameLink = ({ fallback, id }) => (
  <AuthRender capability={Capability.FRO_KPIS} fallback={fallback} siteIds={[id]} view>
    {fallback}
  </AuthRender>
);

const SiteLink = ({ name, onClick }) => (
  <NavLinksContainer>
    <button type="button" onClick={onClick}>
      <StyledSeparator>/</StyledSeparator>
      <NavIcon icon={Icons.SITE} />
      {name}
      <ChevronIcon rotate={-90} />
    </button>
  </NavLinksContainer>
);

const CurrentViewTitle = ({ iconType, defaultTitle, title, type }) => (
  <StyledHeading
    data-testid={AnalyzeLocators.ANALYZE_KPIBAR_HEADER}
    trimWhitespace={type === EntityType.REGION}
  >
    {title && type === EntityType.STORAGE_SITE && iconType && (
      <MultiPathIcons
        icon={[Icons.STROAGE_NEW1, Icons.STROAGE_NEW2]}
        size={25}
        color={globalColors.slate5}
      />
    )}
    {title && type !== EntityType.STORAGE_SITE && type !== 'undefined' && iconType && (
      <TypeIcon icon={iconType} />
    )}
    {!title ? defaultTitle : title}
  </StyledHeading>
);

const DashboardHeader = ({
  children,
  className,
  title,
  type,
  entity,
  data,
  showParent,
  kpiCategories,
  onMenuClose,
  visibleKpiPrefs,
  showStats,
  unitObject,
  noTitle,
}) => {
  /** STORE */
  const { getRegionById } = useStoreState((state) => state.regions);

  const { isAuthorized } = useAuth();
  const hasFROCapability = isAuthorized({
    capabilities: [{ capability: Capability.FRO_KPIS, scopes: [PermissionScope.VIEW] }],
    siteIds: [entity?.id],
  });

  /** UTIL HOOKS */
  const { ready, t } = useTranslation(['analyze.dashboard']);
  const { showSiteDetails } = useContext(EntityDetailsContext);

  /** LOCAL STATE */
  const [regionId, setRegionId] = useState(null);
  const [region, setRegion] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleKpis, setVisibleKpis] = useState([]);

  useEffect(() => {
    setVisibleKpis(visibleKpiPrefs);
  }, [visibleKpiPrefs, setVisibleKpis]);

  /** HANDLERS */
  const handleSiteSelect = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showSiteDetails(id);
    },
    [showSiteDetails],
  );

  const handleSetAnchorEl = (target) => {
    setAnchorEl(target);
  };

  const onVisibleKpis = () => {};

  useEffect(() => {
    if (entity?.region?.id) setRegionId(entity.region.id);
  }, [entity]);

  useEffect(() => {
    if (!regionId) {
      return;
    }
    setRegion(getRegionById(regionId));
  }, [regionId, setRegion, getRegionById]);

  /** GETTERS */
  const getIconType = (typeIcon) =>
    typeIcon === EntityType.REGION ? Icons.FLEET : Icons[typeIcon?.toUpperCase()];

  if (!ready || !visibleKpis) return null;

  const regionLabel = (
    <>
      <NavIcon icon={Icons.FLEET} />
      {region?.name}
    </>
  );
  const customerLabel = (
    <>
      <NavIcon icon={Icons.PERSON} />
      {entity?.customer?.name}
    </>
  );
  const { date, time, tz } = getDateTimeBasedOnZone(new Date(), entity?.timezone);

  const getToURL = () => {
    const parentId = getParentId(type, entity);
    let to = getParentEntityUrl(type, parentId);
    if (!hasFROCapability && type === EntityType.SITE) {
      to = getParentEntityUrl(EntityType.SITES, parentId);
    }
    return to;
  };

  return (
    <ToggleHeader className={className}>
      <DashNav shouldAlignCenter={type === EntityType.ASSET} noTitle={noTitle}>
        {title &&
          showParent &&
          ![EntityType.SOLAR_SITE, EntityType.STORAGE_SITE].includes(type) && (
            <BackButton toURL={getToURL()} />
          )}
        <div>
          {title && showParent && (
            <div data-testid={AnalyzeLocators.ANALYZE_HEADER_HIGHER_VIEW_LINK}>
              {
                // TODO: Region for solar sites are in scope for now, that's why displaying customer
                // name in place region name
              }
              {type === EntityType.SOLAR_SITE && (
                <CustomerNameLink fallback={customerLabel} id={entity?.id} />
              )}
              {type === EntityType.STORAGE_SITE && (
                <CustomerNameLink fallback={customerLabel} id={entity?.id} />
              )}
              {type === EntityType.SITE && regionId && (
                <RegionLink fallback={regionLabel} id={entity?.id} regionId={regionId} />
              )}
              {type === EntityType.TURBINE && (
                <SiteLink onClick={(e) => handleSiteSelect(e, entity.id)} name={entity.name} />
              )}
            </div>
          )}
          <CurrentViewTitle
            title={title}
            type={type}
            iconType={getIconType(type)}
            defaultTitle={type !== EntityType.SITE ? t('fleet_by_region', 'Fleet by Region') : ''}
          />
          {[EntityType.SOLAR_SITE, EntityType.STORAGE_SITE].includes(type) && (
            <DateBelowSiteName>
              {date} {time} ({tz})
            </DateBelowSiteName>
          )}
        </div>
      </DashNav>
      {children}
      <ConditionalRender shouldRender={showStats}>
        <DashStatsWrapper>
          <DashboardStatsHeader data={data} categories={visibleKpis} />
          <AuthRender capability={Capability.CORE_KPIS} siteIds={[entity?.id]} view>
            <DashboardHeaderMenu
              anchorEl={anchorEl}
              visibleKpis={visibleKpis}
              kpiCategories={kpiCategories}
              onSetAnchorEl={handleSetAnchorEl}
              onMenuClose={onMenuClose}
              onSetVisibleKpis={onVisibleKpis}
              unitObject={unitObject}
            />
          </AuthRender>
        </DashStatsWrapper>
      </ConditionalRender>
    </ToggleHeader>
  );
};

DashboardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  entity: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  showParent: PropTypes.bool,
  kpiCategories: PropTypes.instanceOf(Array),
  onMenuClose: PropTypes.func,
  visibleKpiPrefs: PropTypes.instanceOf(Array),
  showStats: PropTypes.bool,
  unitObject: PropTypes.object,
  noTitle: PropTypes.bool,
};

DashboardHeader.defaultProps = {
  title: null,
  type: null,
  entity: {},
  data: {},
  showParent: true,
  onMenuClose: () => {},
  visibleKpiPrefs: [],
  showStats: true,
  unitObject: {},
};

DashNav.propTypes = {
  children: PropTypes.node,
  shouldAlignCenter: PropTypes.bool,
  noTitle: PropTypes.bool,
};

BackButton.propTypes = {
  toURL: PropTypes.string,
};

RegionLink.propTypes = {
  fallback: PropTypes.any,
  id: PropTypes.any,
  regionId: PropTypes.any,
};

CustomerNameLink.propTypes = {
  fallback: PropTypes.any,
  id: PropTypes.any,
};

SiteLink.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.any,
};

CurrentViewTitle.propTypes = {
  title: PropTypes.any,
  type: PropTypes.any,
  iconType: PropTypes.string,
  defaultTitle: PropTypes.string,
};

export default withRouter(DashboardHeader);
