import { assignIfDefined } from '@ge/util/object-utils';

import { Customer } from '../customer';
import { Entity } from '../entity';
import { LatLng } from '../location';
import { Region } from '../region';
import { Task } from '../task';

import { SiteMetrics } from './site-metrics';

/**
 * A Site is a collection of Assets, and represents a grouping that is
 * either geographic or a logical.
 */
export class Site extends Entity {
  /**
   * The region in which the Site resides
   * @type {Region}
   */
  region;

  /**
   * Display name for the site
   * @type {string}
   */
  name;

  /**
   * The name of the group in which the site resides
   * @type {string}
   */
  group;

  /**
   * The country in which the site exists
   * @type {string}
   */
  country;

  /**
   * The customer to whom the site belongs
   * @type {Customer}
   */
  customer;

  /**
   * The timezone in which the site exists
   * @type {string}
   */
  timezone;

  /**
   * The LatLng coordinates of the geographic location of the site
   * @type {LatLng}
   */
  location;

  /**
   * Tasks to be associated with the site
   * @type {Task[]}
   */
  tasks;

  /**
   * A SiteMetrics object to be associated with the site
   * @type {SiteMetrics}
   */
  metrics;

  /**
   * Tickets associated with the site
   * TODO (astone): This needs to be cleaned up once we have tasks / cases, etc defined.
   * @type {Array<Object>}
   */
  tickets;

  /**
   * @param {Partial<Site>} [initObj] Site object to use to initialize class
   */
  constructor(initObj) {
    super(initObj);

    // Apply all known object values
    Object.assign(this, initObj);

    // Cast non-primitive object types.
    const { region, customer, location, tasks, metrics } = this;
    assignIfDefined(this, 'region', region && new Region(region));
    assignIfDefined(this, 'customer', customer && new Customer(customer));
    assignIfDefined(this, 'location', location && new LatLng(location));
    assignIfDefined(this, 'tasks', tasks && tasks.map((task) => new Task(task)));
    assignIfDefined(this, 'metrics', metrics && new SiteMetrics(metrics));
    // TODO (astone): Add tickets assignment when appropriate?
  }

  /**
   * Attach an array of Task objects to the Site.
   *
   * @param {Task[]} tasks Array of {@link Task} objects to include
   * @returns {Site} Updated Site
   */
  withTask(tasks) {
    this.tasks = tasks;
    return this;
  }

  /**
   * Attach a SiteMetrics object to the Site.
   *
   * @param {SiteMetrics} metrics Site metrics object to include
   * @returns {Site} Updated Site
   */
  withMetrics(metrics) {
    this.metrics = metrics;
    return this;
  }

  /**
   * Attach an array of Ticket objects to the Site.
   * TODO (astone): This needs to be cleaned up once we have tasks / cases, etc defined.
   *
   * @param {Object[]} tickets Array of Tickets to include
   * @returns {Site} Updated Site
   */
  withTickets(tickets) {
    this.tickets = tickets;
    return this;
  }
}
