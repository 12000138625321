import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { WarningDialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import {
  Capability,
  DateTimeFormats,
  NotificationType,
  TaskSourceField,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import Breadcrumbs from '@ge/shared/components/breadcrumbs';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';

import { Calendar } from '../components/planning/calendar';
import { NeedsAttention } from '../components/planning/needs-attention';
import { PlanningTabs } from '../components/planning/planning-tabs';
import {
  PlanningProvider,
  PlanningContext,
  FilterValues,
  DefaultFilters,
} from '../context/planning-provider';
import { useCrews } from '../data-hooks/use-crews';
import { useErpNotifications } from '../data-hooks/use-erp-notifications';
import { useTaskBacklog } from '../data-hooks/use-task-backlog';
import { useTaskSchedule } from '../data-hooks/use-task-schedule';
//import { useTasksTable } from '../data-hooks/use-tasks-table';
// import { useWorkers } from '../data-hooks/use-workers';

const NOTIFICATION_DELAY = 1000;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .overview-page-title {
    padding: 5px 18px 5px 18px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  button {
    align-self: center;
  }
`;

const StyledNewTaskButton = styled(Button)`
  position: relative;
  top: 6px;
`;

const DataIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.DATA_NETCOMM,
  color: props.theme.dangerColor,
}))`
  margin: 10px 0px;
  padding-left: 143px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 6px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
`;
const DataContent = styled.div`
  color: ${({ theme }) => theme.caseLinkDialog.errorTextColor};
  text-align: center;
  font-size: 14px;
  line-height: 16px;
`;
const CloseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.sidebar.settingsIconColor,
}))`
  display: block;
`;

const StyledButton = styled.button`
  position: absolute;
  right: 12px;
  top: 46px;
  display: inline-block;
  vertical-align: middle;
  padding: 3px;
  cursor: pointer;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.active ? props.theme.select.chevronPrimaryColor : props.theme.select.chevronColor,
}))``;

const ManageOverview = () => {
  const { ready, t } = useTranslation(['manage.planning', 'manage.erp-statuses'], {
    useSuspense: false,
  });
  const { notify } = useNotification();
  const {
    planningState: { range },
    serviceGroupIds,
  } = useContext(PlanningContext);
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [closeDialog, setCloseDialog] = useState(true);
  const [isActive, setIsActiveState] = useState(false);

  const isAssetsLoading = useStoreState(({ assets }) => assets.isLoading);

  useEffect(() => {
    if (serviceGroupIds.length > 1) {
      setCloseDialog(true);
    }
  }, [serviceGroupIds]);

  const getAutomatedTaskGroups = useStoreState((state) => state.siteManager.getAutomatedTaskGroups);

  const scheduleData = getAutomatedTaskGroups(range);
  const { data: backlogData, isFetching: isFetching } = useTaskBacklog();
  //WIP
  // const { data: workerData, isFetching: isFetchingWorkers } = useWorkers();
  const { data: crewData, isFetching: isFetchingCrewsWorkers } = useCrews();
  const newFieldTask = useCallback(() => {
    setShowNewTaskModal(true);
  }, [setShowNewTaskModal]);

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
  }, []);

  const DataFooter = styled.div`
    font-size: 12px;
    margin-left: 0px;
    color: ${({ theme }) => theme.caseLinkDialog.titleColor};
    line-height: 15px;
  `;
  const getFooter = useMemo(() => {
    return (
      isActive && (
        <DataFooter>
          {t(
            'service_group_message',
            'You have multiple service groups selected. Please use your scope selector to choose one service group to work on at a time',
          )}
        </DataFooter>
      )
    );
  }, [isActive, t]);
  const handleClose = () => {
    setCloseDialog(false);
  };

  const { data: allTasksDisplay } = useTaskSchedule({
    start: range.start,
    end: range.end,
    groupByL1: FilterValues.SITE_ASSET,
    groupByL2: null,
    groupByFilters: DefaultFilters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let allTasks = [];
  allTasksDisplay?.schedule.map((dateArray) => {
    dateArray?.data?.map((dateTasks) => {
      if (dateTasks?.tasks?.length) {
        dateTasks?.tasks.map((tasks) => {
          allTasks.push(tasks);
        });
      }
    });
  });

  // UPDATE: Call erpNotifications api if only backlogdata or schedular data available
  let loadErpNotify = false;
  if (backlogData?.length || allTasks?.length) {
    loadErpNotify = true;
  }
  const { data: erpNotifications, isFetching: isNotificationFetching } =
    useErpNotifications(loadErpNotify);

  const triggerNotification = useCallback(
    (notifications) => {
      if (!allTasks) {
        return;
      }
      notifications.forEach((notification, index) => {
        let notificationData = [];
        // Checking data available in scheduled data
        notificationData = allTasks.filter((el) => {
          return el?.id === notification?.taskId;
        });
        // Checking data available in backlog data
        if (notificationData?.length === 0) {
          notificationData = backlogData.filter((el) => {
            return el?.id === notification?.taskId;
          });
        }

        if (notificationData?.length) {
          const asset = {
            id: notificationData[0]?.asset?.id,
            name: notificationData[0]?.asset?.name,
          };
          const site = {
            id: notificationData[0]?.site?.id,
            name: notificationData[0]?.site?.name,
          };
          const taskName = {
            id: notificationData[0]?.id,
            name: notificationData[0]?.title,
          };

          // TODO: add method for the `onBreadCrumbClick` callback
          const status = (
            <Breadcrumbs
              onBreadCrumbClick={() => {
                console.log('breadcrumb clicked!');
              }}
              site={site}
              asset={asset}
              title={taskName}
              showChevron={true}
            />
          );

          // TODO: pass notification's message based on messageCode
          //t('case_created_success', 'Case Created Successfully').toUpperCase()(
          const message = (
            <NotificationMessage
              message={t(
                `manage.erp-statuses:status.${notification?.messageCode}`,
                notification?.messageCode,
              )}
            />
          );

          // Notification's timestamp
          const timestamp = dayjs(notification?.timestamp).format(
            DateTimeFormats.SR_NOTIFICATION_DATE_TIME,
          );

          // a failure will make the notification last longer on screen
          const type = notification.isFailure ? NotificationType.FAILURE : NotificationType.INFO;
          if (notificationData && notification) {
            setTimeout(() => {
              notify({ status, message, timestamp, type });
            }, NOTIFICATION_DELAY * index);
            delete notifications[index];
          }
        }
      });
    },
    [allTasks, backlogData, notify, t],
  );

  useEffect(() => {
    if (erpNotifications?.length && !isNotificationFetching) {
      triggerNotification(erpNotifications);
    }
  }, [erpNotifications, isNotificationFetching, triggerNotification]);

  if (!ready || !backlogData || !scheduleData) {
    return null;
  }

  return (
    <>
      <PageContainer>
        <Header>
          {serviceGroupIds.length > 1 && (
            <WarningDialog isOpen={closeDialog} footer={getFooter} contentWidth padContent={true}>
              <CloseButton onClick={handleClose}>
                <CloseIcon icon={Icons.CLOSE} size={10} />
              </CloseButton>
              <DataIcon />
              <DataContent>
                {t('service_warning_message', 'Some functionality is unavailable')}
              </DataContent>
              <StyledButton onClick={() => !isActive && setIsActiveState(true)} type="button">
                <ChevronIcon className={`chevron ${isActive && 'active'}`} />
              </StyledButton>
            </WarningDialog>
          )}
          <h1 className="overview-page-title">{t('planning', 'planning')}</h1>
          {!isAssetsLoading && (
            <AuthRender
              capability={Capability.FIELD_TASKS}
              create
              description="New Task button"
              siteLevel={false}
            >
              <StyledNewTaskButton primary onClick={newFieldTask}>
                {t('new_task', 'New Task')}
              </StyledNewTaskButton>
            </AuthRender>
          )}
        </Header>
        <NeedsAttention />
        <ContentContainer>
          <PlanningTabs
            scheduleData={scheduleData}
            backlogData={backlogData}
            isFetching={isFetching || isAssetsLoading}
            workerData={crewData.workers}
            crewData={crewData.crewsSg}
            isFetchingWorkers={isFetchingCrewsWorkers || isAssetsLoading}
          />
          <Calendar />
        </ContentContainer>
      </PageContainer>
      {showNewTaskModal && (
        <NewTaskDialog
          onClose={closeModal}
          onConfirm={saveTask}
          taskSource={TaskSourceField.MANUAL}
        />
      )}
    </>
  );
};

const ManageOverviewProvider = () => (
  <PlanningProvider>
    <ManageOverview />
  </PlanningProvider>
);

export default ManageOverviewProvider;
