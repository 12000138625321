import { PropTypes } from 'prop-types';
import clone from 'ramda/src/clone';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Text } from '@ge/components/typography';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { WidgetNames } from '@ge/feat-reporting/models/widgets';
import { TimeAggr } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';
import { typography } from '@ge/tokens/typography';

import { NoDataAvailable } from '../../no-data-available/no-data-available';
import {
  AssetsOfConcernColumns,
  assetsOfConcernDefaultCols,
} from '../assets-of-concern-widget/assets-of-concern-cols';
import { AssetsOfConcernWidget } from '../assets-of-concern-widget/assets-of-concern-widget';
import {
  ClosedCasesColumns,
  closedCasesDefaultCols,
} from '../closed-cases-widget/closed-cases-cols';
import { ClosedCasesWidget } from '../closed-cases-widget/closed-cases-widget';
import {
  CompletedWorkColumns,
  completedWorkDefaultCols,
} from '../completed-work-widget/completed-work-cols';
import { CompletedWorkWidget } from '../completed-work-widget/completed-work-widget';
import {
  ContractualAvailabilityColumns,
  contractualAvailabilityDefaultCols,
} from '../contractual-availability-widget/contractual-availability-cols';
import { ContractualAvailabilityWidget } from '../contractual-availability-widget/contractual-availability-widget';
import {
  ManuallyAdjustedEventsColumns,
  manuallyAdjustedEventsDefaultCols,
} from '../manually-adjusted-events-widget/manually-adjusted-events-cols';
import { ManuallyAdjustedEventsWidget } from '../manually-adjusted-events-widget/manually-adjusted-events-widget';
import { OpenCasesColumns, openCasesDefaultCols } from '../open-cases-widget/open-cases-cols';
import { OpenCasesWidget } from '../open-cases-widget/open-cases-widget';
import {
  PlannedWorkColumns,
  plannedWorkDefaultCols,
} from '../planned-work-widget/planned-work-cols';
import { PlannedWorkWidget } from '../planned-work-widget/planned-work-widget';
import {
  ProductionTableColumns,
  productionTableDefaultCols,
} from '../production-table-widget/production-table-cols';
import { ProductionTableWidget } from '../production-table-widget/production-table-widget';
import { siteRosterDefaultCols, SiteRosterColumns } from '../site-roster-widget/site-roster-cols';
import { SiteRosterWidget } from '../site-roster-widget/site-roster-widget';

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  box-sizing: border-box;
  padding: 0 8px;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  padding-bottom: 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.widget.headerTextColor};
  height: 24px;
  max-height: 20px;
`;

const UnitsLabel = styled.h4`
  color: ${({ theme }) => theme.createReport.widget.table.unitsLabelColor};
  margin-left: 5px;
  text-transform: none;
`;

const VariationLabel = styled.h4`
  color: ${({ theme }) => theme.createReport.widget.table.variationLabelColor};
  margin-right: 15px;
`;

const PencilIcon = styled(Icon).attrs(({ theme }) => ({
  size: 10,
  icon: Icons.PENCIL,
  color: theme.createReport.widget.headerIconColor,
}))``;

const IconContainer = styled.button`
  background: none;
  border: none;
  padding: 0 5px;

  .k-pdf-export & {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  button:first-of-type {
    margin-left: 15px;
  }
`;

const EntriesLabel = styled.span`
  color: ${({ theme }) => theme.createReport.widget.table.entriesLabelColor};
  font-size: 11px;
  margin-left: 10px;
  margin-right: auto;
  text-transform: none;
`;

const NoTableData = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const NoDataIcon = styled(Icon).attrs(() => ({
  icon: Icons.DATA_NETCOMM,
  size: 14,
  color: globalColors.grey5,
}))`
  margin: 0 5px 2px 0;
`;

const NoDataTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
}))`
  color: ${({ theme }) => theme.createReport.widget.headerLabelColor};
`;

const tableWidgets = {
  [WidgetNames.PLANNED_WORK]: {
    columns: PlannedWorkColumns,
    defaultCols: plannedWorkDefaultCols,
    widget: PlannedWorkWidget,
  },
  [WidgetNames.COMPLETED_WORK]: {
    columns: CompletedWorkColumns,
    defaultCols: completedWorkDefaultCols,
    widget: CompletedWorkWidget,
  },
  [WidgetNames.SITE_ROSTER]: {
    columns: SiteRosterColumns,
    defaultCols: siteRosterDefaultCols,
    widget: SiteRosterWidget,
  },
  [WidgetNames.ASSETS_OF_CONCERN]: {
    columns: AssetsOfConcernColumns,
    defaultCols: assetsOfConcernDefaultCols,
    widget: AssetsOfConcernWidget,
  },
  [WidgetNames.MANUALLY_ADJUSTED_EVENTS]: {
    columns: ManuallyAdjustedEventsColumns,
    defaultCols: manuallyAdjustedEventsDefaultCols,
    widget: ManuallyAdjustedEventsWidget,
  },
  [WidgetNames.CONTRACTUAL_AVAILABILITY]: {
    columns: ContractualAvailabilityColumns,
    defaultCols: contractualAvailabilityDefaultCols,
    widget: ContractualAvailabilityWidget,
  },
  [WidgetNames.PRODUCTION]: {
    columns: ProductionTableColumns,
    defaultCols: productionTableDefaultCols,
    widget: ProductionTableWidget,
  },
  [WidgetNames.OPEN_CASES]: {
    columns: OpenCasesColumns,
    defaultCols: openCasesDefaultCols,
    widget: OpenCasesWidget,
  },
  [WidgetNames.CLOSED_CASES]: {
    columns: ClosedCasesColumns,
    defaultCols: closedCasesDefaultCols,
    widget: ClosedCasesWidget,
  },
};

// These tables contain summary rows in their data sets, which needs to be excluded from the row count label
const SummaryRowTables = [
  WidgetNames.CONTRACTUAL_AVAILABILITY,
  WidgetNames.PRODUCTION,
  WidgetNames.PRODUCTION_TABLE,
];

// Return the correct table for the provided widget name.
const getTableByName = (
  widgetId,
  widgetName,
  columns,
  data,
  isEditing,
  isInteractive,
  isPlaceholder,
  setSelectedEntries,
  savedEntries,
  timeAggr,
) => {
  if (isPlaceholder) {
    return null;
  } else if (widgetName in tableWidgets) {
    // If the widget exists and we have an empty data set, show no data available.
    if (data && !data.length) {
      return <NoDataAvailable />;
    }

    const tableEl = React.createElement(tableWidgets[widgetName].widget, {
      id: widgetId,
      name: widgetName,
      columns,
      data,
      isEditing,
      isInteractive,
      onRowSelectionChange: (d) => setSelectedEntries(d),
      savedEntries,
      timeAggr,
    });
    return tableEl;
  } else {
    // TODO: Remove this when all widgets have been implemented?
    return (
      <NoTableData>
        <NoDataIcon />
        <NoDataTitle>Not yet implemented</NoDataTitle>
      </NoTableData>
    );
  }
};

export const TableWidgetStateKeys = {
  COLUMN_CONFIG_STATE: 'columnConfigState',
  ROW_CONFIG_STATE: 'rowConfigState',
};

export const TableWidget = ({
  id,
  name,
  data = [],
  selectedRows = null,
  isLoading,
  isInteractive,
  isPlaceholder,
  timeAggr,
  units,
  variationKey,
}) => {
  const { t, ready } = useTranslation(['reporting.widgets', 'general']);
  const [isEditing, setEditing] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState(data);
  // assuming data has standard 'id' field, can revisit
  const [savedEntries, setSavedEntries] = useState(selectedRows ?? data?.map(({ id }) => id));
  const [visibleColumns, setVisibleColumns] = useState(tableWidgets?.[name]?.defaultCols || []);

  // Retrieve column definitions from report context
  const { reportState, getWidgetState, setWidgetLocalState } = useContext(ReportsContext);

  // Update the visible columns in the table whenever the report state changes.
  useEffect(() => {
    setVisibleColumns(getWidgetState(id, TableWidgetStateKeys.COLUMN_CONFIG_STATE) || []);
  }, [reportState.widgetState, id, getWidgetState]);

  useEffect(() => {
    if (!isLoading && !isPlaceholder) {
      setSelectedEntries(data);
      setSavedEntries(selectedRows ?? data?.map(({ id }) => id));
    }
  }, [isLoading, isPlaceholder, data, selectedRows, setSelectedEntries, setSavedEntries]);

  // Toggle the visibility of the selection column based on edit mode.
  const columns = useMemo(() => {
    const cols = [...visibleColumns];
    const selectedCol =
      cols.find((col) => col.id === tableWidgets?.[name]?.columns.GROUP_SELECTED)?.cols?.[0] || {};

    selectedCol.visible = isEditing;
    return cols;
  }, [isEditing, name, visibleColumns]);

  const table = useMemo(() => {
    if (isLoading) {
      return null;
    }
    return getTableByName(
      id,
      name,
      columns,
      data,
      isEditing,
      isInteractive,
      isPlaceholder,
      setSelectedEntries,
      savedEntries,
      timeAggr,
      t,
    );
  }, [
    id,
    name,
    columns,
    data,
    isEditing,
    isLoading,
    isInteractive,
    isPlaceholder,
    savedEntries,
    timeAggr,
    t,
  ]);

  const entriesLabel = useMemo(() => {
    let entriesCount = selectedEntries.length;
    if (entriesCount > 0 && SummaryRowTables.includes(name)) {
      // Exclude summary entry in the data set which should not be included in the row count
      --entriesCount;
    }
    return (
      <EntriesLabel>
        {`${entriesCount} ${
          entriesCount !== 1 ? t('header.entries', 'entries') : t('header.entry', 'entry')
        }`}
      </EntriesLabel>
    );
  }, [name, selectedEntries.length, t]);

  if (!ready) {
    return null;
  }

  if (isLoading) {
    return (
      <>
        <StyledHeader>
          <h4>{name}</h4>
        </StyledHeader>
        <TableContainer>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <StyledHeader>
        <h4>{name}</h4>
        {!isPlaceholder && !!units && <UnitsLabel>({units})</UnitsLabel>}
        {!isPlaceholder && entriesLabel}
        <>
          {!isPlaceholder && !!variationKey && (
            <VariationLabel>{t(variationKey, '')}</VariationLabel>
          )}
          {!isPlaceholder &&
            (!isEditing ? (
              <IconContainer onClick={() => setEditing(true)}>
                <PencilIcon />
              </IconContainer>
            ) : (
              <ButtonContainer>
                <Button
                  primary
                  onClick={() => {
                    setEditing(false);
                    setSavedEntries(selectedEntries);

                    setWidgetLocalState({
                      widgetId: id,
                      key: TableWidgetStateKeys.ROW_CONFIG_STATE,
                      value: clone(selectedEntries),
                    });
                  }}
                >
                  {t('buttons.save_changes', 'Save Changes')}
                </Button>
                <Button
                  onClick={() => {
                    setEditing(false);
                    setSavedEntries(clone(savedEntries)); // copy is needed to fire off a re-render
                  }}
                >
                  {t('buttons.cancel', 'Cancel')}
                </Button>
              </ButtonContainer>
            ))}
        </>
      </StyledHeader>
      {!isPlaceholder && <TableContainer>{table}</TableContainer>}
    </>
  );
};

TableWidget.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  selectedRows: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool,
  isInteractive: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  timeAggr: PropTypes.oneOf(Object.values(TimeAggr)),
  units: PropTypes.string,
  variationKey: PropTypes.string,
};
