import { ChartType } from '@ge/components/charts';
import { KpiCategoryDefs } from '@ge/models/constants';

import { FilterDefs } from './analyze-filter-defaults';
import { KpiCategoryDataType, KpiCategorySeriesType } from './constants';

const AvailabilityDefs = {
  contractual_availability: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.AVAILABILITY_CONTRACT,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      // TODO: review if it's naive to assume a single sort across both default and alternate charts
      sortEntitiesBy: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    },
    kpi: KpiCategoryDefs.AVAILABILITY_CONTRACT,
  },
  pba: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.PBA,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.PBA,
    },
    kpi: KpiCategoryDefs.PBA,
  },
  tba: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.AVAILABILITY,
            key: KpiCategoryDefs.TBA,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.TBA,
    },
    kpi: KpiCategoryDefs.TBA,
  },
};

const PerformanceDefs = {
  actual_production: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_ACTUAL,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.PRODUCTION_ACTUAL,
    },
    kpi: KpiCategoryDefs.PRODUCTION_ACTUAL,
  },
  capacity_factor: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.CAPACITY_FACTOR,
            type: KpiCategoryDataType.PERCENT,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.SPLINE,
      },
      sortEntitiesBy: KpiCategoryDefs.CAPACITY_FACTOR,
    },
    kpi: KpiCategoryDefs.CAPACITY_FACTOR,
  },
  lost_production: {
    graph1: {
      default: {
        categories: [
          {
            grouping: FilterDefs.PERFORMANCE,
            key: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
            type: KpiCategoryDataType.ENERGY,
          },
        ],
        seriesType: KpiCategorySeriesType.TIME_SERIES,
        type: ChartType.COLUMN,
      },
      sortEntitiesBy: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
    },
    kpi: KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
  },
};

// model representation of the ge spreadsheet for what goes in what graph
// maps between filter bar and kpi data fetch defs
export const FleetKpiChartDefs = {
  [FilterDefs.AVAILABILITY]: AvailabilityDefs,
  [FilterDefs.PERFORMANCE]: PerformanceDefs,
};
