import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { SourceTypes } from '@ge/models/constants';

const SelectWrapper = styled.div`
  margin-bottom: 10px;
  max-width: 188px;

  .select__menu {
    position: relative;
    width: 186px;
  }
`;

export const FilterProgressiveDropdowns = ({ options, values, onChange }) => {
  const { t } = useTranslation(['general'], { useSuspense: false });

  const DEFAULT_OPTION = { label: t('all', 'All'), value: 'default' };

  const { primaryOptions, secondaryOptions, tertiaryOptions } = options;

  const dropdownDefs = [
    {
      value: values?.primary,
      label: t('component_filter.level_one', 'Level 1'),
      disabledCondition: !primaryOptions.length > 0,
      sourceType: SourceTypes.PRIMARY,
      options: primaryOptions,
    },
    {
      value: values?.secondary,
      label: t('component_filter.level_two', 'Level 2'),
      disabledCondition: !values?.primary,
      sourceType: SourceTypes.SECONDARY,
      options: secondaryOptions,
    },
    {
      value: values?.tertiary,
      label: t('component_filter.level_three', 'Level 3'),
      disabledCondition: !values?.secondary,
      sourceType: SourceTypes.TERTIARY,
      options: tertiaryOptions,
    },
  ];

  const handleChange = useCallback(
    (val, field) => {
      if (field === SourceTypes.PRIMARY) {
        onChange({
          [field]: val,
        });
      } else {
        onChange({ ...values, [field]: val });
      }
    },
    [onChange, values],
  );

  return (
    <div>
      {dropdownDefs.map((def) => (
        <SelectWrapper key={def?.sourceType}>
          <Select
            secondary
            minWidth={def?.minWidth ?? 160}
            value={def?.value ?? DEFAULT_OPTION}
            label={def?.label}
            disabled={def?.disabledCondition}
            onChange={(option) => handleChange(option, def?.sourceType)}
            options={def?.options}
          />
        </SelectWrapper>
      ))}
    </div>
  );
};

FilterProgressiveDropdowns.propTypes = {
  primaryOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  secondaryOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  tertiaryOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  values: PropTypes.instanceOf(Object),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Object),
};

FilterProgressiveDropdowns.defaultProps = {
  primaryOptions: [],
  values: [],
};
