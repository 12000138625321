import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateTiming } from './create-timing';
import { EditTiming } from './edit-timing';
import { ViewTiming } from './view-timing';

export const TimingSection = ({ section, task, taskPanelControls, templateMode }) => {
  const { metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return <CreateTiming metadata={metadata} />;
      case TaskTemplateModes.EDIT:
        return <EditTiming metadata={metadata} task={task} />;
      case TaskTemplateModes.VIEW:
        return <ViewTiming task={task} taskPanelControls={taskPanelControls} />;
      default:
        return null;
    }
  }
};

TimingSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  taskPanelControls: PropTypes.node,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

TimingSection.defaultProps = {
  task: {},
  taskPanelControls: null,
};
