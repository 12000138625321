import { PropTypes } from 'prop-types';

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
};

ConditionalWrapper.defaultProps = {
  condition: true,
  wrapper: () => null,
};
