import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import boost from 'highcharts/modules/boost';
import highchartsMarkerClusters from 'highcharts/modules/marker-clusters';
import highchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import highchartsPatternFill from 'highcharts/modules/pattern-fill';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled, { withTheme } from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Icons, Icon } from '@ge/components/icon';

import { chartApi } from './../chart-api';
import highchartsCustom from './../highcharts-custom';
import { AxisTickPlacement, ChartType } from './../models';
import chartOptionsFactory from './chart-options-factory';
import ChartSelectionDialog from './chart-selection-dialog';

/* Modules */
highchartsMore(Highcharts);
highchartsNoDataToDisplay(Highcharts);
highchartsCustom(Highcharts);
highchartsMarkerClusters(Highcharts);
boost(Highcharts);
highchartsPatternFill(Highcharts);

const ChartContainer = styled.div`
  position: relative;
  .highcharts-reset-zoom {
    display: none;
  }
`;

const ChartButtons = styled.div`
  position: absolute;
  top: 10px;
  right: ${(props) => props.position.right + 10}px;
`;

const ChartButton = styled.button.attrs(() => ({ type: 'button' }))`
  color: ${(props) => props.theme.dataExplorer.charts.buttonColor};
  background: ${(props) => props.theme.dataExplorer.charts.buttonBackground};
  border: none;
  margin-left: 6px;
  padding: 3px 10px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const ChartButtonIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.dataExplorer.charts.buttonIconColor,
}))`
  margin-right: 6px;
`;

const defaultSelection = { multi: false, selection: [], series: [] };

const ThemedChart = forwardRef(
  (
    {
      theme,
      isLoading,
      noData,
      noDataTitle,
      noDataDescription,
      height,
      yAxisTickPosition,
      xAxisTitle,
      xAxisType,
      xAxisLabelFormatter,
      type,
      xAxis,
      yAxis,
      series,
      enableSyncZoom,
      renderCondition,
      shouldPropsUpdate,
      recreateOnUpdate,
      boostThreshold,
      turboThreshold,
      useClustering,
      onCreated,
      onSelect,
      onSelected,
      onRetry,
      onZoom,
      onResetZoom,
    },
    ref,
  ) => {
    const [options, setOptions] = useState();
    const [showSelectionDialog, setShowSelectionDialog] = useState(false);
    const [selection, setSelection] = useState(defaultSelection);

    const chartRef = useRef();
    const themeRef = useRef();
    const zoomRef = useRef({});

    const handleSelection = useCallback(
      (e) => {
        if (!e.series || e.series?.length <= 1) {
          onSelect({ ...e, selected: e.series?.[0]?.name ?? null });
          return;
        }
        setShowSelectionDialog(true);
        setSelection(e);
      },
      [onSelect],
    );

    const clearSelection = useCallback(() => {
      setSelection(defaultSelection);
      onSelect(defaultSelection);
    }, [onSelect]);

    const applySelection = useCallback(
      (selected) => {
        onSelect({
          ...selection,
          selection: selection?.series?.find((v) => v.name === selected)?.points ?? [],
          selected,
        });
        setShowSelectionDialog(false);
      },
      [selection, onSelect],
    );

    const handleZoom = useCallback(
      (e) => {
        zoomRef.current = e;
        onZoom(e);
      },
      [onZoom],
    );

    const handleResetZoom = useCallback(() => {
      if (chartRef.current?.chart) {
        chartRef.current.chart.zoomOut();
        const ext = chartRef.current.chart.xAxis[0].getExtremes();
        onResetZoom(ext);
      }
    }, [onResetZoom]);

    // pass theme by ref into api so it doesn't grow stale
    useEffect(() => {
      themeRef.current = theme;
    }, [theme]);

    useEffect(() => {
      setOptions(
        chartOptionsFactory({
          type,
          xAxis,
          yAxis,
          series,
          height,
          xAxisTitle,
          xAxisType,
          yAxisTickPosition,
          xAxisLabelFormatter,
          zoom: true,
          theme,
          boostThreshold,
          turboThreshold,
          useClustering,
          onMouseOver: () => {},
          onCreated,
          onSelect: handleSelection,
          onSelected,
          onRetry,
          onZoom: handleZoom,
        }),
      );
    }, [
      xAxisTitle,
      xAxisType,
      yAxisTickPosition,
      xAxisLabelFormatter,
      type,
      xAxis,
      yAxis,
      series,
      height,
      theme,
      boostThreshold,
      turboThreshold,
      useClustering,
      onCreated,
      onSelect,
      onSelected,
      onRetry,
      handleSelection,
      handleZoom,
    ]);

    // api wrapper we define and expose based on high charts api
    useImperativeHandle(ref, () => chartApi(chartRef, { themeRef }));

    const handleCreated = useCallback(
      (chart) => {
        chartRef.current = chart;

        const api = chartApi(chartRef, { themeRef });

        onCreated(api);
      },
      [onCreated, themeRef],
    );

    if (!options) {
      return null;
    }

    const getPosition = () => {
      if (!chartRef.current?.chart) return { right: 0 };
      return {
        right:
          chartRef.current.chart.chartWidth -
          chartRef.current.chart.plotWidth -
          chartRef.current.chart.plotLeft,
      };
    };

    const isLassoActive = () => series?.find((v) => v.isSelectedPointsSeries);

    const isZomeActive = () => {
      if (!chartRef.current?.chart) return false;
      if (zoomRef.current?.active) return true;
      if (enableSyncZoom) return false;
      const ext = chartRef.current.chart.xAxis[0].getExtremes();
      return Math.round((ext.dataMax - ext.dataMin) / (ext.max - ext.min)) !== 1;
    };

    return (
      <DataLoader
        isLoading={isLoading}
        noData={noData}
        noDataTitle={noDataTitle}
        noDataDescription={noDataDescription}
        onRetry={onRetry}
        type={type}
        renderCondition={renderCondition}
      >
        <ChartContainer>
          <HighchartsReact
            allowChartUpdate={shouldPropsUpdate}
            callback={handleCreated}
            highcharts={Highcharts}
            immutable={recreateOnUpdate}
            options={options}
            ref={chartRef}
          />
          <ChartButtons position={getPosition()}>
            <ChartButton onClick={clearSelection} hidden={!isLassoActive()}>
              <ChartButtonIcon icon={Icons.LASSO} viewbox="0 0 11 13" />
              {'Clear Lasso'}
            </ChartButton>
            <ChartButton onClick={handleResetZoom} hidden={!isZomeActive()}>
              <ChartButtonIcon icon={Icons.SEARCH} />
              {'Reset Zoom'}
            </ChartButton>
          </ChartButtons>
          {showSelectionDialog && (
            <ChartSelectionDialog
              onClose={applySelection}
              onConfirm={applySelection}
              options={selection.series ?? []}
              type={type}
            />
          )}
        </ChartContainer>
      </DataLoader>
    );
  },
);

ThemedChart.displayName = 'ThemedChart';

ThemedChart.propTypes = {
  theme: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  noData: PropTypes.bool,
  noDataTitle: PropTypes.string,
  noDataDescription: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(Object.values(ChartType)).isRequired,
  xAxisTitle: PropTypes.string,
  xAxisType: PropTypes.string,
  xAxisLabelFormatter: PropTypes.func,
  xAxis: PropTypes.instanceOf(Object).isRequired,
  yAxis: PropTypes.arrayOf(PropTypes.instanceOf(Object).isRequired),
  series: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.shape({
            name: PropTypes.string,
            x: PropTypes.number,
            y: PropTypes.number,
          }),
        ]),
      ).isRequired,
      name: PropTypes.string,
      pointInterval: PropTypes.number,
      pointStart: PropTypes.number,
      selected: PropTypes.func,
      tooltipHeader: PropTypes.string,
      tooltipPoint: PropTypes.string,
      tooltipPointFormatter: PropTypes.func,
    }),
  ),
  enableSyncZoom: PropTypes.bool,
  renderCondition: PropTypes.bool,
  yAxisTickPosition: PropTypes.string,
  shouldPropsUpdate: PropTypes.bool,
  recreateOnUpdate: PropTypes.bool,
  boostThreshold: PropTypes.number,
  turboThreshold: PropTypes.number,
  useClustering: PropTypes.bool,
  onCreated: PropTypes.func,
  onSelect: PropTypes.func,
  onSelected: PropTypes.func,
  onRetry: PropTypes.func,
  onZoom: PropTypes.func,
  onResetZoom: PropTypes.func,
};

ThemedChart.defaultProps = {
  type: ChartType.SCATTER,
  yAxisTickPosition: AxisTickPlacement.OUTSIDE,
  xAxis: {},
  yAxis: [],
  series: [],
  shouldPropsUpdate: true,
  recreateOnUpdate: false,
  xAxisLabelFormatter: () => {},
  onClick: () => {},
  onCreated: () => {},
  onSelect: () => {},
  onSelected: () => {},
  onRetry: () => {},
  onZoom: () => {},
  onResetZoom: () => {},
};

export const Chart = withTheme(ThemedChart);
export const DataExplorerChart = Chart;
