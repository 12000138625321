import dayjs from 'dayjs';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import {
  DateTimeFormats,
  AllKpiMetricSupportedCategories,
  KpiCategoryDefs,
  TimeAggr,
  ConditionType,
} from '@ge/models/constants';

const defaultKpiDateRange = {
  endDate: dayjs().format(DateTimeFormats.ENDPOINT_PARAM),
  startDate: dayjs().startOf('M').format(DateTimeFormats.ENDPOINT_PARAM),
};

const formatDateBasedOnAggr = (date, timeAggr) => {
  if (timeAggr === TimeAggr.MONTHLY) {
    return dayjs(date, DateTimeFormats.MONTHLY_AGGR_DATE).format(DateTimeFormats.ENDPOINT_PARAM);
  }
  return date;
};

const hydrateKpi = (regionData, entityById, timeAggr) => {
  return Object.entries(regionData)
    .map(([kpi, d]) => {
      return {
        [kpi]: {
          ...d,
          timeSeriesData: d.timeSeriesData?.map((td) => ({
            ...td,
            date: formatDateBasedOnAggr(td.date, timeAggr),
          })),
          entityData: d.entityData?.map((ed) => {
            return {
              ...ed,
              entity: { ...ed.entity, name: entityById[ed.entity.id]?.name },
            };
          }),
        },
      };
    })
    .reduce((acc, o) => ({ ...acc, ...o }), {});
};

const getSortedKpiData = (kpiData, sortByKpi, _sortDirection) => {
  const { entityData, ..._data } = kpiData[sortByKpi] ?? {};

  if (!entityData?.length) {
    return kpiData;
  }

  const compare = (a, b) => (_sortDirection === SortDirection.DESC ? b - a : a - b);
  const sorted = entityData.sort(({ value: a = 0 }, { value: b = 0 }) => compare(a, b));
  let data = { [sortByKpi]: { entityData: sorted, ..._data } };

  // sort other kpis based on sorted
  const adjacentData = Object.entries(kpiData).reduce(
    (adjacent, [kpi, { entityData: adjacentKpi = [], ...adjacentRest }]) =>
      kpi === sortByKpi
        ? adjacent
        : {
            ...adjacent,
            [kpi]: {
              ...adjacentRest,
              // compact sort by another array from https://stackoverflow.com/a/54913401
              entityData: sorted.map(
                ({ entity: { id: sortedId, ...sortedEntity } }) =>
                  adjacentKpi.find(({ entity: { id } }) => sortedId === id) ?? {
                    // if for some reason we don't have an adjacent entity,
                    // we just create a placeholder with null value
                    // can revisit this, because a) it should't happen and
                    // b) we might want to reflect this differently
                    entity: { id: sortedId, ...sortedEntity },
                    value: null,
                  },
              ),
            },
          },
    {},
  );

  return {
    ...data,
    ...adjacentData,
  };
};

/**
 * Splits an array of categories into two arrays based on if they are supported by new "all-kpi-metrics" endpoint or not.
 * @param categories
 * @return {{allKpiMetricsEndpointCategories: string[], otherKpiEndpointsCategories: string[][]}}
 */
export const splitKpisCategoryByApi = (categories) => {
  const allKpiMetricsEndpointCategories = [];
  const otherKpiEndpointsCategories = [];
  const windMetricsCategories = [];
  const conditionTypes = Object.values(ConditionType);

  [...new Set(categories.flat())].forEach((category) => {
    if (AllKpiMetricSupportedCategories.includes(category)) {
      allKpiMetricsEndpointCategories.push(category);
    } else if (conditionTypes.includes(category)) {
      windMetricsCategories.push(category);
    } else {
      if (category !== KpiCategoryDefs.EVENT_RATES) otherKpiEndpointsCategories.push(category);
    }
  });

  return { allKpiMetricsEndpointCategories, otherKpiEndpointsCategories, windMetricsCategories };
};

const getSortedTimeSeriesData = (kpiData) => {
  return Object.entries(kpiData).reduce((acc, [key, value]) => {
    acc[key] = {
      ...value,
      timeSeriesData: value?.timeSeriesData?.sort(({ date: a }, { date: b }) =>
        dayjs(a).diff(dayjs(b)),
      ),
    };
    return acc;
  }, {});
};

export const KPI = {
  defaultDateRange: defaultKpiDateRange,
  getSorted: getSortedKpiData,
  hydrate: hydrateKpi,
  getSortedTimeSeriesData,
};
