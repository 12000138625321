import dayjs from 'dayjs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import {
  EntityType,
  TaskPriority,
  TaskRecurrence,
  TaskTemplateModes,
  PageSource,
  TaskSourceField,
} from '@ge/models/constants';
import { TaskProvider } from '@ge/shared/components/tasks/task-provider';
import { CloseTaskTemplate } from '@ge/shared/components/tasks/templates/close-task-template';
import { AffectedAssetsSection } from '@ge/shared/components/tasks/templates/sections/affected-assets-section/affected-assets-section';
import { EntityHeaderSection } from '@ge/shared/components/tasks/templates/sections/entity-header-section';
import { TaskTemplate } from '@ge/shared/components/tasks/templates/task-template';
import { useCreateTask, useTaskSource, useTaskWorkScope } from '@ge/shared/data-hooks';
import useCaseDetailDataByQuery from '@ge/shared/data-hooks/cases/use-case-detail-data-by-query';
import { toSiteISOString } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';

import { EntityDetailsContext } from '../../context/entity-details-context';

const StyledNewTaskDialog = styled(Dialog)`
  &.hide-dialog {
    opacity: 0;
    visibility: hidden;
  }
`;

const DialogScrollWrapper = styled.div`
  box-sizing: border-box;
  min-height: 600px;
  padding: 20px 25px;
`;

const NewTaskForm = styled.form`
  display: flex;
  flex: 1;
  position: relative;
  width: 567px;
  height: 600px;
  max-height: calc(75vh - 101px);
`;

const CloseTaskForm = styled.form`
  display: flex;
  flex: 1;
  position: relative;
  width: 567px;
  height: 400px;
  max-height: calc(75vh - 101px);
`;

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  span {
    font-weight: ${typography.weight.bold};
    margin-top: 1px;
    text-transform: uppercase;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const NewTaskDialogComponent = ({
  // currently pre-populating from a single entity
  // if there is a need to support pre-populating multiple entities
  // can make this an array
  associationId,
  entity,
  entityType: _entityType,
  entityId,
  onClose,
  onConfirm,
  title,
  taskSource: _taskSource, //taskType modified to taskSource
  siteTimezone,
  isCalledfromTaskEscalationColumn,
  setAnchorEl,
}) => {
  const { t } = useTranslation(['tasks', 'general'], { useSuspense: false });
  const { disableNewTaskSaveBtn } = useContext(EntityDetailsContext);
  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      priority: '',
      component1: '',
      component2: '',
      component3: '',
      entityType: _entityType ?? EntityType.ASSET,
      entityList: entityId ? [entityId] : null,
      consumedParts: [
        {
          number: '',
          name: '',
          quantity: '',
        },
      ],
      expectedParts: [
        {
          number: '',
          name: '',
          quantity: '',
        },
      ],
      laborHours: 0,
      laborMinutes: 0,
      taskDetailTitle: '',
      taskDetailDescription: '',
      taskTemplate: {},
      timing: [
        {
          date: '',
          durationHrs: 0,
          durationMins: 0,
          startTime: '',
          endTime: '',
        },
      ],
    },
  });

  const [closeTask, setCloseTask] = useState(CheckedState.UNCHECKED);
  const [selectedSites, setSelectedSites] = useState([]);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showCloseTaskDialog, setShowCloseTaskDialog] = useState(false);
  const { selectedAlarmOrEvent } = useStoreState((store) => store.tasks);
  const [workerState, setWorkerState] = useState([]);
  const [deleteWrkrIds, setDeleteWrkrIds] = useState([]);
  const watchEntityType = useMemo(() => methods.watch('entityType'), [methods]);

  const { setWorkStandards } = useStoreActions((store) => store.tasks);
  const { notify, dismiss } = useNotification();

  const { create } = useCreateTask({
    onSettled: onConfirm,
    onSuccess: () => {
      dismiss();
      const message = (
        <NotificationMessage
          message={t('task_notification.task_creation', 'Task Created Successfully')}
        />
      );
      notify({ message });
      onConfirm();
      setWorkStandards(null);
    },
  });

  const path = useLocation().pathname;
  let page = path.includes(PageSource.MONITOR) ? PageSource.MONITOR : PageSource.EXECUTE;
  if (watchEntityType === EntityType.ASSET) page = PageSource.EXECUTE;
  if (selectedAlarmOrEvent?.description || _taskSource == TaskSourceField.REALTIMECASES)
    page = PageSource.REALTIMECASE;
  if (_entityType === EntityType.CASE) page = PageSource.MONITOR;

  const { isLoading: isTaskSourcesLoading, data: taskSources } = useTaskSource({
    entityType: _entityType === EntityType.CASE ? EntityType.ASSET : watchEntityType,
    page: page,
  });

  const { isLoading: isTaskWorkScopeLoading, data: taskWorkScopes } = useTaskWorkScope({
    entityType: _entityType === EntityType.CASE ? EntityType.ASSET : watchEntityType,
  });

  const { caseWithDetails, isLoading: isCaseDetailsLoading } = useCaseDetailDataByQuery({
    caseId: watchEntityType === EntityType.CASE && entity ? entity.id : null,
  });

  const isLoading = useMemo(
    () => isCaseDetailsLoading || isTaskSourcesLoading || isTaskWorkScopeLoading,
    [isCaseDetailsLoading, isTaskSourcesLoading, isTaskWorkScopeLoading],
  );

  const toggleCloseTask = useCallback(() => {
    setCloseTask(
      closeTask === CheckedState.CHECKED ? CheckedState.UNCHECKED : CheckedState.CHECKED,
    );
  }, [closeTask, setCloseTask]);

  const handleVerifyCancel = useCallback(() => {
    if (Object.keys(methods.formState.dirtyFields ?? {}).length === 0) onClose();
    else setShowCancelConfirmation(true);
  }, [methods.formState.dirtyFields, onClose]);

  const cleanParts = useCallback((formValues) => {
    const parts = formValues?.filter((part) => part.number && part.name && part.quantity);
    return parts ? parts : null;
  }, []);

  const fnHandleTimezone = (arr) => {
    setSelectedSites(arr);
  };
  const removeWorker = useCallback(
    (username) => {
      if (deleteWrkrIds.indexOf(username) === -1) {
        setDeleteWrkrIds([...deleteWrkrIds, username]);
      } else {
        const removedWorker = deleteWrkrIds.filter((item) => item !== username);
        setDeleteWrkrIds(removedWorker);
      }
    },
    [deleteWrkrIds],
  );
  const completedTech = [];
  workerState.map((filtered) => completedTech.push(filtered.username));
  const completedByTechWorkers = completedTech.filter((x) => !deleteWrkrIds.includes(x));

  const createTask = useCallback(
    (values) => {
      const timezone = siteTimezone ? siteTimezone : selectedSites?.[0]?.timezone ?? '';
      const component1 =
        watchEntityType === EntityType.CASE ? caseWithDetails?.componentIds[0] : values.component1;
      const component2 =
        watchEntityType === EntityType.CASE ? caseWithDetails?.componentIds[1] : values.component2;
      const component3 =
        watchEntityType === EntityType.CASE ? caseWithDetails?.componentIds[2] : values.component3;

      const task = {
        source: values.taskSource,
        workScope: values.taskWorkScope,
        priority: values.priority,
        category: 'field',
        title: values.title,
        description: values.description,
        component1: component1,
        component2: component2,
        component3: component3,
        dueDate: values.dueDate ? toSiteISOString(values.dueDate, timezone) : null,
        eligibleStartDate: values.eligibleStartDate
          ? toSiteISOString(values.eligibleStartDate, timezone)
          : null,
        committedDate: values.committedDate
          ? toSiteISOString(values.committedDate, timezone)
          : null,
        estDurationHours: values.estDurationHours || 0,
        estDurationMinutes: values.estDurationMinutes || 0,
        estTechs: Number(values.estTechs) || 0,
        ...(values?.approvalFlag && { flag: 'approved' }),
        laborHours: values.laborHours,
        laborMinutes: values.laborMinutes,
        scheduleDate: values.scheduleDate ? toSiteISOString(values.scheduleDate, timezone) : null,
        startTime: values.startTime,
        recurrence: {
          ...(values.recurrence !== TaskRecurrence.NONE && {
            recurrence: values.recurrence,
            recurrenceType: values.recurrenceType,
            recurrenceEndByDate: dayjs(values.recurrenceEndByDate).isValid()
              ? dayjs(values.recurrenceEndByDate).toISOString()
              : null,
            recurrenceEndByCount: values.recurrenceEndByCount,
            recurrenceCycle: values.recurrenceCycle,
            recurrenceCycleType: values.recurrenceCycleType,
          }),
        },
        expectedParts: cleanParts(values?.expectedParts),
        consumedParts: cleanParts(values?.consumedParts),
        resolutionNotes: values.resolutionNotes,
        timing: values.timing?.map((time) => {
          return {
            ...time,
            date: time.date ? toSiteISOString(time.date, timezone) : null,
            startTime: time?.startTime ? toSiteISOString(time.startTime, timezone) : null,
            endTime: time?.endTime ? toSiteISOString(time.endTime, timezone) : null,
          };
        }),
        status: closeTask === CheckedState.CHECKED ? 'completed' : null,
        ...(associationId && { caseId: associationId }),
        crewIds: [values?.assignee],

        completedByTech: completedByTechWorkers,
      };
      let entType = values.entityType;
      if (!entType && watchEntityType === EntityType.CASE) {
        entType = EntityType.ASSET;
      }
      if (values?.assignee === null) {
        delete task.crewIds;
      }
      create({
        task,
        entityIds: values.entityList,
        entityType: entType,
        associationId,
      });
    },
    [
      siteTimezone,
      selectedSites,
      cleanParts,
      closeTask,
      associationId,
      completedByTechWorkers,
      watchEntityType,
      create,
    ],
  );

  const onSubmit = useCallback(
    (values) => {
      if (closeTask === CheckedState.CHECKED && !showCloseTaskDialog) {
        setShowCloseTaskDialog(true);
      } else {
        createTask(values);

        const message = (
          <NotificationMessage
            message={t('task_notification.creating_task', 'Creating Task')}
            isProgressLoader={true}
          />
        );
        notify({ message });
      }
      if (setAnchorEl) {
        setAnchorEl(null);
      }
    },
    [closeTask, createTask, showCloseTaskDialog, setAnchorEl, notify, t],
  );

  const handleCloseTaskBack = useCallback(() => {
    setShowCloseTaskDialog(false);
  }, []);

  const handleCancelClose = useCallback(() => {
    setShowCancelConfirmation(false);
  }, [setShowCancelConfirmation]);

  const handleCancelConfirm = useCallback(() => {
    onClose();
  }, [onClose]);

  const getTaskTemplate = useMemo(() => {
    // currently only supporting view only header for cases
    // handled miniloader in tasktemplate as form getting reloaded loosing entityType value
    return (
      <ScrollingContainer>
        <DialogScrollWrapper>
          {watchEntityType === EntityType.CASE ? (
            <EntityHeaderSection entity={caseWithDetails} entityType={watchEntityType} />
          ) : (
            <AffectedAssetsSection
              entity={entity}
              entityType={watchEntityType}
              templateMode={TaskTemplateModes.CREATE}
              fnHandleTimezone={fnHandleTimezone}
            />
          )}
          <TaskTemplate
            control={methods.control}
            templateMode={TaskTemplateModes.CREATE}
            task={{
              title: title,
              source: _taskSource,
              description: caseWithDetails?.body ?? '',
              priority: caseWithDetails?.priority ?? entity?.priority ?? TaskPriority.IMMEDIATE,
              entType: watchEntityType,
            }}
            taskSources={taskSources}
            taskWorkScopes={taskWorkScopes}
            entity={entity}
            entType={watchEntityType}
            isLoading={isLoading}
            isCalledfromTaskEscalationColumn={isCalledfromTaskEscalationColumn}
          />
        </DialogScrollWrapper>
      </ScrollingContainer>
    );
  }, [
    entity,
    isLoading,
    methods,
    _taskSource,
    taskSources,
    title,
    watchEntityType,
    caseWithDetails,
    taskWorkScopes,
    isCalledfromTaskEscalationColumn,
  ]);

  const getCloseTaskTemplate = useMemo(() => {
    return (
      <ScrollingContainer>
        <DialogScrollWrapper>
          <CloseTaskTemplate
            templateMode={TaskTemplateModes.CREATE}
            type={'taskClose'}
            entity={entity}
            workerState={workerState}
            setWorkerState={setWorkerState}
            removeWorker={removeWorker}
            deleteWrkrIds={deleteWrkrIds}
          />
        </DialogScrollWrapper>
      </ScrollingContainer>
    );
  }, [deleteWrkrIds, entity, removeWorker, workerState]);

  // Build the footer to pass into the Dialog component.
  const getFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <StyledCheckbox
          label={t('form.close_task', 'Close Task')}
          checkState={closeTask}
          onChange={toggleCloseTask}
          ref={methods.register({ name: 'closeTask' })}
          disabled={isCalledfromTaskEscalationColumn && disableNewTaskSaveBtn}
        />
        <FooterButtons>
          <Button onClick={handleVerifyCancel}>{t('general:cancel', 'Cancel')}</Button>
          {closeTask === CheckedState.CHECKED ? (
            <Button primary onClick={methods.handleSubmit(onSubmit)}>
              {/* {...methods.register('entityList')} */}
              {t('form.next_close_task', 'Next: Close Task')}
            </Button>
          ) : (
            <Button
              primary
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isCalledfromTaskEscalationColumn && disableNewTaskSaveBtn}
              {...methods.register('entityList')}
            >
              {t('general:save', 'Save')}
            </Button>
          )}
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [
    t,
    closeTask,
    toggleCloseTask,
    methods,
    handleVerifyCancel,
    onSubmit,
    isCalledfromTaskEscalationColumn,
    disableNewTaskSaveBtn,
  ]);

  const getCloseTaskFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <Button primary onClick={handleCloseTaskBack}>
          {t('general:back', 'Back')}
        </Button>
        <FooterButtons>
          <Button onClick={handleVerifyCancel}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={methods.handleSubmit(onSubmit)}>
            {t('form.save_close_task', 'Save & Close Task')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [handleCloseTaskBack, methods, handleVerifyCancel, onSubmit, t]);

  const getCancelFooter = useMemo(() => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={handleCancelClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button
            primary
            onClick={() => {
              setWorkStandards(null);
              onClose();
            }}
          >
            {t('form.delete_task', 'Delete Task')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  }, [handleCancelClose, onClose, setWorkStandards, t]);

  return (
    <TaskProvider>
      <FormProvider {...methods}>
        <StyledNewTaskDialog
          className={showCloseTaskDialog ? 'hide-dialog' : ''}
          isOpen={true}
          onClose={onClose}
          onConfirm={onConfirm}
          header={t('form.create_field_task', 'Create Field Task')}
          footer={getFooter}
          contentWidth
          padContent={false}
        >
          <NewTaskForm>{getTaskTemplate}</NewTaskForm>
        </StyledNewTaskDialog>
        {showCloseTaskDialog && (
          <Dialog
            isOpen={true}
            onClose={handleCloseTaskBack}
            onConfirm={onConfirm}
            header={t('form.close_task', 'Close Task')}
            footer={getCloseTaskFooter}
            contentWidth
            padContent={false}
          >
            <CloseTaskForm>{getCloseTaskTemplate}</CloseTaskForm>
          </Dialog>
        )}
        {showCancelConfirmation && (
          <Dialog
            isOpen={true}
            onClose={handleCancelClose}
            onConfirm={handleCancelConfirm}
            header={t('form.delete_task', 'Confirmation')}
            footer={getCancelFooter}
            contentWidth
            padContent={true}
          >
            <p>{t('form.are_you_sure', 'Are you sure?')}</p>
          </Dialog>
        )}
      </FormProvider>
    </TaskProvider>
  );
};

NewTaskDialogComponent.propTypes = {
  associationId: PropTypes.string,
  entity: PropTypes.object,
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  entityId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setAnchorEl: PropTypes.func,
  onConfirm: PropTypes.func,
  priority: PropTypes.oneOf(Object.values(TaskPriority)),
  taskSource: PropTypes.string,
  title: PropTypes.string,
  siteTimezone: PropTypes.string,
  isCalledfromTaskEscalationColumn: PropTypes.bool,
};

NewTaskDialogComponent.defaultProps = {
  associationId: undefined,
  entity: undefined,
  entityType: undefined,
  entityId: undefined,
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  priority: undefined,
  taskSource: undefined,
  title: undefined,
  siteTimezone: '',
  isCalledfromTaskEscalationColumn: null,
};

export const NewTaskDialog = withTheme(NewTaskDialogComponent);
