import { PropTypes } from 'prop-types';
import path from 'ramda/src/path';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { StatusColor } from '@ge/tokens/colors/colors';

const StyledSelect = styled(Select)`
  .select__control,
  .select__dropdown-control {
    ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER}` : '')};
  }
`;

const getErrors = (name, errors) => {
  const idPath = String(name).split('.');
  return { [name]: path(idPath, errors) };
};

export const SelectMetaField = ({
  placeholder,
  defaultValue,
  label,
  maxWidth,
  metadata,
  minWidth,
  primary,
  name,
  options,
  searchable,
  disabled,
  className,
  handleChange,
}) => {
  const { readOnly, required } = metadata;
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? null}
      name={name}
      rules={{ required: required }}
      render={({ onChange, value }) => (
        <StyledSelect
          className={className}
          placeholder={placeholder}
          disabled={disabled ? disabled : readOnly}
          errors={getErrors(name, errors)}
          id={name}
          label={label}
          maxWidth={maxWidth}
          minWidth={minWidth}
          name={name}
          onChange={(val) => {
            onChange(val.value);
            handleChange(val.value);
          }}
          options={options}
          searchable={searchable}
          primary={primary}
          required={required}
          value={options?.find((val) => val.value === value)}
        />
      )}
    />
  );
};

SelectMetaField.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  maxWidth: PropTypes.number,
  metadata: PropTypes.instanceOf(Object).isRequired,
  minWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  searchable: PropTypes.bool,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  handleChange: PropTypes.func,
};

SelectMetaField.defaultProps = {
  placeholder: null,
  defaultValue: undefined,
  label: '',
  maxWidth: null,
  minWidth: null,
  options: [],
  searchable: true,
  primary: false,
  disabled: false,
  handleChange: () => null,
};
