import { PropTypes } from 'prop-types';
import React, { useState, useRef, useCallback } from 'react';

import { ChartItem } from '@ge/feat-analyze/models/chart-item';
import { getSelectedMinMax } from '@ge/feat-analyze/util/data-explorer-chart';
import useStateRef from '@ge/hooks/state-ref';
import useDraggable from '@ge/hooks/use-draggable';
import { DataExplorerLayouts as Layouts } from '@ge/models/constants';

import { useAssetContext } from './assetContext';
import { DataExplorerContext } from './data-explorer-context';

const applySetZoom = (zoomState, chartApiRefs) => {
  const apis = chartApiRefs.current ?? {};
  for (let chartId in apis) {
    if (zoomState.id !== chartId && apis[chartId]?.setZoom) {
      apis[chartId].setZoom(zoomState);
    }
  }
};

const applyResetZoom = (zoomState, chartApiRefs) => {
  const apis = chartApiRefs.current ?? {};
  for (let chartId in apis) {
    if (zoomState.id !== chartId && apis[chartId]?.resetZoom) {
      apis[chartId].resetZoom();
    }
  }
};

const applyResetZoomToAll = (chartApiRefs) => {
  const apis = chartApiRefs.current ?? {};
  for (let chartId in apis) {
    if (apis[chartId]?.resetZoom) {
      apis[chartId].resetZoom();
    }
  }
};

export const DataExplorerProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [zoomState, setZoomState] = useState({});
  const [syncZoom, setSyncZoom] = useState(false);
  const [selection, setSelection, selectionRef] = useStateRef({ points: [] });

  const chartApiRefs = useRef({});

  const {
    queryParam,
    assetState: { chartsArray, setChartsArray },
  } = useAssetContext();

  const { enableMultiAxis } = queryParam;

  const setZoom = useCallback(
    (e, id) => {
      if (e.active) {
        setZoomState({ ...e, id });
        if (syncZoom) {
          applySetZoom({ ...e, id }, chartApiRefs);
        }
      }
    },
    [syncZoom, chartApiRefs],
  );

  const resetZoom = useCallback(
    (e, id) => {
      setZoomState((prev) => (prev.id === id ? { ...e, id } : prev));
      if (syncZoom) {
        applyResetZoom({ ...e, id }, chartApiRefs);
      }
    },
    [syncZoom, chartApiRefs],
  );

  const _setSyncZoom = useCallback(
    (syncState) => {
      setSyncZoom(syncState);
      if (syncState) {
        if (zoomState.active) {
          applySetZoom(zoomState, chartApiRefs);
        } else {
          setZoomState({});
          applyResetZoomToAll(chartApiRefs);
        }
      }
    },
    [zoomState, chartApiRefs],
  );

  const _setSelection = useCallback(
    (e, id) => {
      const { multi, selection: points = [] } = e;
      if (selectionRef.current.id === id && selectionRef.current.selected === e.selected) {
        setSelection((prev) => {
          const _points = multi ? [...prev.points, ...points] : points;
          const [tsMin, tsMax] = getSelectedMinMax(_points, 'ts');
          return {
            id,
            points: _points,
            selected: e.selected,
            multi,
            tsMin,
            tsMax,
          };
        });
      } else {
        const [tsMin, tsMax] = getSelectedMinMax(points, 'ts');
        setSelection({ id, points, selected: e.selected, multi, tsMin, tsMax });
      }
    },
    [selectionRef, setSelection],
  );

  const onRemoveChart = useCallback(
    (id) => {
      if (!id) return;
      if (syncZoom && zoomState?.id === id) {
        resetZoom({}, id);
      }
      if (selectionRef.current?.id === id) {
        _setSelection({ points: [] }, id);
      }
      delete chartApiRefs.current[id];
    },
    [syncZoom, zoomState, resetZoom, selectionRef, _setSelection],
  );

  const dropHandler = useCallback(
    (values) => {
      setChartsArray(values.flatMap((prop) => new ChartItem(prop)));
    },
    [setChartsArray],
  );

  const draggableContext = useDraggable(chartsArray, { useDragButton: true, onDrop: dropHandler });

  const providerValue = {
    enableMultiAxis,
    chartApiRefs,
    isLoading,
    setIsLoading,
    zoomState,
    setZoom,
    resetZoom,
    syncZoom,
    setSyncZoom: _setSyncZoom,
    selection,
    setSelection: _setSelection,
    onRemoveChart,
    draggable: enableMultiAxis && queryParam.layout === Layouts.DETAIL && chartsArray.length > 1,
    draggableContext,
  };

  return (
    <DataExplorerContext.Provider value={providerValue}>{children}</DataExplorerContext.Provider>
  );
};

DataExplorerProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

DataExplorerProvider.defaultProps = {
  children: null,
};
