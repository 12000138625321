import { useCallback, useEffect, useState } from 'react';

import { useLocalStorage } from '@ge/hooks/use-local-storage';
import { TableFilterTypes } from '@ge/models/constants';

const LOCAL_STATE_PREFIX = 'table-col-filter-state';

const getByColumnDefs = (columnDefs) =>
  Object.values(columnDefs ?? {}).reduce(
    (_filter, { cols }) => ({
      ..._filter,
      ...Object.entries(cols ?? {})
        .filter(([, { filterType }]) => filterType)
        .reduce(
          (_cols, [key, { filterType: type, filters: value }]) => ({
            ..._cols,
            [key]: { type, value },
          }),
          {},
        ),
    }),
    {},
  );

const toDate = (date) => (date && typeof date === 'string' ? new Date(date) : date);

/**
 * This is used to parse string dates in filter defs to Date object
 *
 * @param defs filter definitions
 * @returns filter definitions with parsed dates
 */
const parseFilterDates = (defs) =>
  Object.entries(defs).reduce(
    (acc, [col, filter]) => ({
      ...acc,
      [col]:
        filter.type === TableFilterTypes.DATE
          ? {
              ...filter,
              max: toDate(filter.max),
              min: toDate(filter.min),
            }
          : filter,
    }),
    {},
  );

/**
 * useFilterDefs
 *
 * Gets filter defs in a standard format for the various column filters
 *
 * @param {object} columnDefs The filter definitions for the table used to set default state
 * @returns The filter definitions with active filters applied and an onChange handler to call when filters change
 */

export const useFilterDefs = ({ columnDefs, stateId }) => {
  // Keep the filters in local storage only when the state id is passed
  // Otherwise use the hook state
  const isPersistenceEnabled = !!stateId;
  // Store filter defs in local storage
  const [filterState, setFilterState] = useLocalStorage(`${LOCAL_STATE_PREFIX}.${stateId}`);
  // Store filter column sequence in local storage
  const [filterSequence = [], setFilterSequence] = useLocalStorage(
    `${LOCAL_STATE_PREFIX}-column-sequence.${stateId}`,
  );
  const [filterDefs, setFilterDefs] = useState();
  useEffect(() => {
    if (filterDefs) return;
    const filters =
      isPersistenceEnabled && filterState
        ? parseFilterDates(filterState)
        : getByColumnDefs(columnDefs);
    setFilterDefs(filters);
  }, [columnDefs, filterDefs, filterState, isPersistenceEnabled]);

  const onChange = useCallback(
    (columnKey, value) => {
      setFilterSequence((prevState = []) => {
        // remove column key from sqeuence array on clear.
        if (!value) {
          return prevState.filter((val) => val !== columnKey);
        }
        // push column key of which filter is applied.
        if (!prevState.includes(columnKey)) {
          return [...prevState, columnKey];
        }
        return prevState;
      });

      // massage filter value into standard format from various filter types
      setFilterDefs((prev = {}) => {
        const prevDef = prev[columnKey];
        const { type } = prevDef;
        let updateDef;

        // for single values or cleared filters
        if (!Array.isArray(value)) {
          updateDef = { max: null, min: null, value };
          // parse values into max/min
        } else if (
          [TableFilterTypes.DATE, TableFilterTypes.NUMBER].includes(type) &&
          value.length === 2
        ) {
          // strip out null dates from filter when just one value is specified
          const _value = value.filter(Boolean);

          updateDef =
            _value.length === 2 ? { max: _value[1], min: _value[0] } : { value: _value[0] };
        } else {
          updateDef = { value };
        }

        let newDefs = {
          ...prev,
          [columnKey]: {
            ...prevDef,
            ...updateDef,
          },
        };

        if (isPersistenceEnabled) setFilterState(newDefs);
        return newDefs;
      });
    },
    [isPersistenceEnabled, setFilterState, setFilterSequence],
  );

  const onReset = useCallback(() => {
    setFilterDefs((prev = {}) => {
      let newDefs = {};
      Object.keys(prev).forEach((columnKey) => {
        const prevDef = prev[columnKey];
        let updateDef;

        if ([TableFilterTypes.DATE, TableFilterTypes.NUMBER].includes(prevDef?.type)) {
          updateDef = { max: null, min: null, value: null };
        } else {
          updateDef = { value: null };
        }

        newDefs[columnKey] = {
          ...prevDef,
          ...updateDef,
        };
      });
      setFilterSequence([]);
      setFilterState(newDefs);
    });
  }, [setFilterState, setFilterSequence]);

  return {
    filterSequence,
    filterDefs,
    onChange,
    onReset,
  };
};
