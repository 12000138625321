import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { Text, Title } from '@ge/components/typography';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { getTurbineStateType, getTurbineStateColor } from '@ge/shared/util/turbine-state';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

import { usePlanningContext } from '../../context/planning-context';
import { FilterValues } from '../../context/planning-provider';
import { isWorkerActive, isWorkerSplit } from '../../util/worker-util';
import { CrewBadge } from '../crew-badge';

const Container = styled.div`
  background: inherit;
  width: calc(${({ sidebarWidth }) => sidebarWidth}px);
  box-shadow: 2px 1px 3px ${({ theme }) => theme.manage.crew.shadowColor};
  position: sticky;
  left: 0;
  z-index: ${elevations.P3};

  button,
  span {
    display: flex;
    padding: 0;
    color: currentColor;
    text-align: left;
    align-items: center;
  }
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    height: 100%;
    width: 2px;
    background: ${({ theme }) => theme.manage.calendar.sideBarBorderColor};
    box-shadow: 1px 0 6px rgba(0, 0, 0, 0.6);
    display: block;
    content: '';
  }
  .carotBtn {
    padding: 0 6px;
  }
  .carot {
    transition: transform 0.6s ease;
    padding: 6px;
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(0deg);
    }
  }
`;

const StyledDetailsDismissInert = styled(DetailsDismissInert)`
  display: flex;
  align-items: flex-start;
`;

// disabling until we handle expand/collapse of lanes
// const CaretIcon = styled(Icon).attrs(({ theme }) => ({
//   size: 8,
//   color: theme.manage.calendar.laneExpandCaret,
//   icon: Icons.CARET,
// }))``;

const TitleText = styled(Title)`
  text-transform: uppercase;
  display: inline;
  margin-top: 4px;
`;

const EntityText = styled(Text).attrs(() => ({
  type: typography.textTypes.kpi,
  component: typography.elementTypes.div,
}))``;

const SiteAssetIcon = styled(Icon).attrs(({ theme, icon }) => ({
  size: 24,
  color: theme.manage.calendar.groupBySiteAssetIcon,
  icon,
}))`
  margin-right: 6px;
`;

const SiteAssetContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 8px;
`;

const ChevronIcon = styled(Icon).attrs(({ theme }) => ({
  size: 8,
  color: theme.manage.calendar.laneExpandCaret,
  icon: Icons.CHEVRON,
  rotate: -90,
}))``;

const StatusBadge = styled(Badge)`
  position: absolute;
  top: -7px;
  left: -8px;
  z-index: ${elevations.P10};
`;

const GroupByItem = styled.div`
  flex: 1;
  padding: 40px 10px;
`;

const GroupByContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  ${GroupByItem}:nth-child(2) {
    border-left: solid 1px #2a323b;
  }
`;
const CrewPills = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const TaskTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  component: typography.elementTypes.div,
  level: 3,
}))`
  color: ${(props) => props.theme.button.textColor};
`;

const SiteAssetGroupBy = ({ asset, site }) => {
  const { showAssetDetails, showSiteDetails } = useContext(EntityDetailsContext);

  const onClickHandler = useCallback(() => {
    asset?.id ? showAssetDetails(asset.id) : showSiteDetails(site.id);
  }, [showSiteDetails, showAssetDetails, asset, site]);

  const getAssetContent = useCallback(
    (auth) => (
      <>
        <StatusBadge
          small
          color={getTurbineStateColor(asset.state)}
          label={getTurbineStateType(asset.state)}
        />
        <SiteAssetIcon icon={Icons.TURBINE} />
        <div>
          {site.name && <EntityText level={4}>{site.name}</EntityText>}
          {asset.name && (
            <EntityText level={2}>
              {asset.name} {auth && <ChevronIcon />}
            </EntityText>
          )}
        </div>
      </>
    ),
    [asset, site],
  );

  return (
    <SiteAssetContainer>
      {asset?.id ? (
        <AuthRender
          capability={Capability.REAL_TIME_PAGES}
          view
          description="Asset details button"
          siteLevel={false}
          fallback={getAssetContent(false)}
        >
          <button type="button" onClick={onClickHandler}>
            {getAssetContent(true)}
          </button>
        </AuthRender>
      ) : (
        <AuthRender
          capability={Capability.REAL_TIME_PAGES}
          view
          description="Asset details button"
          siteLevel={false}
          fallback={
            <span>
              <SiteAssetIcon icon={Icons.SITE} />
              <div>
                <EntityText level={3}>{site.name}</EntityText>
              </div>
            </span>
          }
        >
          <button type="button" onClick={onClickHandler}>
            <SiteAssetIcon icon={Icons.SITE} />
            <div>
              <EntityText level={3}>
                {site.name} <ChevronIcon />
              </EntityText>
            </div>
          </button>
        </AuthRender>
      )}
    </SiteAssetContainer>
  );
};

const TaskGroupBy = ({ groupBy }) => {
  //Todo: add priotity
  return <TaskTitle>{groupBy}</TaskTitle>;
};

const CrewGroupBy = ({ crewData, crewId, crewEndDate, groupByCrew }) => {
  const {
    planningState: { range },
  } = usePlanningContext();

  const { workers } = useStoreState((state) => state.workers);

  const isMemberFromSelectedSg = useCallback(
    (member) => {
      const crew = crewData?.find((crew) => crew?._id === crewId);
      const worker = workers.find((worker) => worker?.username === member?.username);
      const workerServiceGroups =
        worker?.attributes?.worker_service_group[0]?.groupCombination[0]?.ids;
      if (!workerServiceGroups?.includes(crew?.service_group)) {
        return false;
      }
      return true;
    },
    [crewId, crewData, workers],
  );

  //Todo: add priotity
  return (
    <>
      <TaskTitle>{groupByCrew?.crewName}</TaskTitle>
      <CrewPills>
        {groupByCrew?.crewMem?.map(
          (mem, index) =>
            !!mem?.username &&
            isMemberFromSelectedSg(mem) && (
              <CrewBadge
                key={mem?.member_id + '__' + index}
                technician={mem}
                active={!!isWorkerActive(mem, crewData, crewId, crewEndDate, range)}
                split={isWorkerSplit(mem, crewData, crewId, crewEndDate, range)}
              />
            ),
        )}
      </CrewPills>
    </>
  );
};

export const LaneGroupBy = ({
  unassigned,
  sidebarWidth,
  // disabling until we handle expand/collapse of lanes
  // expanded,
  groupByL1,
  groupByL2,
  showGroupByL1,
}) => {
  // disabling until we handle expand/collapse of lanes
  // const [isExpanded, setIsExpanded] = useState(expanded ? 'expanded' : '');
  // const toggleExpanded = () => setIsExpanded(isExpanded === '' ? 'expanded' : '');
  const getGroupBy = useCallback((data) => {
    switch (data.groupByType) {
      // TODO: add task layout
      case FilterValues.TASK:
        return <TaskGroupBy {...data} />;

      case FilterValues.SITE_ASSET:
        return <SiteAssetGroupBy {...data} />;

      // TODO: add crew layout
      case FilterValues.CREW:
        return <CrewGroupBy {...data} />;

      default:
        return null;
    }
  }, []);

  return (
    <Container sidebarWidth={sidebarWidth}>
      <StyledDetailsDismissInert>
        {/* disabling until we handle expand/collapse of lanes */}
        {/*<button className="carotBtn" type="button" onClick={() => toggleExpanded()}>
          <CaretIcon className={isExpanded === 'expanded' ? 'carot rotate' : 'carot'} />
  </button>*/}
        <GroupByContainer>
          {unassigned && (
            <GroupByItem>
              <TitleText level={5}>unassigned tasks</TitleText>
            </GroupByItem>
          )}
          {!unassigned && (
            <GroupByItem>{showGroupByL1 && groupByL1 && getGroupBy(groupByL1)}</GroupByItem>
          )}
          {groupByL2 && <GroupByItem>{getGroupBy(groupByL2)}</GroupByItem>}
        </GroupByContainer>
      </StyledDetailsDismissInert>
    </Container>
  );
};

SiteAssetGroupBy.propTypes = {
  site: PropTypes.object,
  asset: PropTypes.object,
};

SiteAssetGroupBy.defaultProps = {
  site: {},
  asset: {},
};

TaskGroupBy.propTypes = {
  groupBy: PropTypes.string,
};

TaskGroupBy.defaultProps = {
  groupBy: '',
};

CrewGroupBy.propTypes = {
  crewData: PropTypes.array,
  crewId: PropTypes.string,
  crewEndDate: PropTypes.string,
  groupByCrew: PropTypes.object,
};

LaneGroupBy.propTypes = {
  groupByL1: PropTypes.instanceOf(Object),
  groupByL2: PropTypes.instanceOf(Object),
  unassigned: PropTypes.bool,
  sidebarWidth: PropTypes.number,
  expanded: PropTypes.bool,
  showGroupByL1: PropTypes.bool,
};

LaneGroupBy.defaultProps = {
  groupByL1: null,
  groupByL2: null,
  unassigned: false,
  sidebarWidth: null,
  expanded: false,
  showGroupByL1: false,
};
