import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  DataLoaderType,
  Capability,
  DateTimeFormats,
  DefaultWeekNames,
  PermissionScope,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EntityCreateContext } from '@ge/shared/context/entity-create-context';
import { useAuth } from '@ge/shared/data-hooks';

import { PlanningContext } from '../../../context/planning-provider';
import { getServiceGroupIds, getSortCrewMember } from '../../../util/worker-util';

import { SearchInput } from './search-input';
import { TechsPanelCrew } from './tech-panel-crew';
import { TechsPanelTechnician } from './techs-panel-technician';
import { WorkersFilter } from './workers-filter';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .crews-header {
    display: flex;
    padding: 10px;
    border-bottom: solid 1px ${(props) => props.theme.manage.tabs.techs.borderColor};
    justify-content: space-between;
    align-items: center;
    button:first-child {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      color: ${(props) => props.theme.manage.tabs.techs.crew.descriptionTitle};
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .crew-container {
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    box-shadow: ${(props) => props.theme.manage.tabs.techs.boxShadow};
    .title {
      cursor: pointer;
      padding: 5px;
      outline: none;
      border-bottom-color: ${(props) => props.theme.manage.tabs.techs.borderColor};
      color: ${(props) => props.theme.manage.tabs.techs.crew.headerText};
      font-size: 12px;
      letter-spacing: 0.5px;
      font-weight: 700;
      > svg {
        fill: ${(props) => props.theme.manage.tabs.techs.primaryColor};
      }
      &.expanded {
        box-shadow: ${(props) => props.theme.manage.tabs.techs.boxShadow};
      }
    }
  }
  .search-container {
    padding: 10px;
    box-shadow: ${(props) => props.theme.manage.tabs.techs.boxShadow};
    border-bottom: 1px solid ${(props) => props.theme.manage.tabs.techs.borderColor};
    text-align: right;
    .search-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.3em;
    }
    .section-title {
      font-size: 16px;
      text-align: left;
    }
  }
  .workers {
    display: flex;
    flex: 1;
    position: relative;
  }
  .new-worker {
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.21);
    margin-top: auto;
    padding: 3pxpx 10px 10px;
    position: relative;
  }
`;

const FilterBtn = styled.button`
  font-size: 12px;
  color: inherit;
  padding: 0;
  /* margin-top: 4px; */
`;

const WorkersSearchInput = styled(SearchInput)`
  margin-bottom: 5px;
  width: calc(100% - 38px);
`;

const DeleteIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.TRASH,
  color: props.theme.manage.tabs.techs.crew.descriptionTitle,
  viewbox: '0 0 13 19',
}))`
  vertical-align: initial;
  margin-right: 7px;
`;

const StyledDataLoader = styled(DataLoader).attrs(({ theme }) => ({
  noDataIconColor: theme.manage.timeline.noDataIconColor,
  type: DataLoaderType.GRID,
}))``;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const DialogWrapper = styled(Dialog)`
  width: 380px;
`;
const DeleteCrew = styled.div`
  font-family: Museo Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
`;
const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

export const TechsPanel = ({ workerData, crewData, isFetchingWorkers }) => {
  const { t } = useTranslation(['manage.planning', 'general']);
  const [showModal, setShowModal] = useState(false);
  const { isAuthorized } = useAuth();

  const deleteWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.DELETE] }],
    siteIds: [],
  });

  const createWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.CREATE] }],
    siteIds: [],
  });

  const viewWorkPlan = isAuthorized({
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.VIEW] }],
    siteIds: [],
  });

  // const viewlimitedUserMGMT = isAuthorized({
  //   capabilities: [
  //     { capability: Capability.LIMITED_USER_MANAGEMENT, scopes: [PermissionScope.VIEW] },
  //   ],
  //   siteIds: [],
  // });

  const [filterText, setFilterText] = useState('');
  const [workers, setWorkers] = useState([]);
  const { showCreatePerson } = useContext(EntityCreateContext);

  const [isWorkerFilterVisible, setIsWorkerFilterVisible] = useState(true);

  const { planningState, serviceGroupIds } = useContext(PlanningContext);

  const { createCrew, deleteAllCrews } = useStoreActions((actions) => actions.workers);

  //Gives list of service groups
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  //Gives list of sites currently selected
  const { currentView } = useStoreState((state) => state.view);

  // Todo: remove techId once we can create new empty crews
  const handleCreateCrew = () =>
    createCrew({
      name: 'New Crew',
      service_group: getServiceGroupIds(sortedServiceGroups, currentView).toString(),
      crewShiftStartTime: '07:00',
      crewShiftEndTime: '16:00',
      apply_on: DefaultWeekNames,
      crewStartDate: dayjs(new Date()).format(DateTimeFormats.CREW_TIMING),
      crewEndDate: null,
      members: [],
    });

  const handleModalOpen = () => {
    setIsWorkerFilterVisible(true);
  };

  const applyDelete = useCallback(() => {
    // Todo: need to add action for deleting all crews
    let serviceGroupIds = getServiceGroupIds(sortedServiceGroups, currentView).toString();
    deleteAllCrews({
      serviceGroup: serviceGroupIds,
    });
    setShowModal(false);
  }, [deleteAllCrews, sortedServiceGroups, currentView]);

  const getCancelFooter = useMemo(() => {
    return (
      <FooterWrapper>
        <FooterButtons>
          <Button onClick={() => setShowModal(false)}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={() => applyDelete()}>
            {t('manage.planning:delete', 'Delete')}
          </Button>
        </FooterButtons>
      </FooterWrapper>
    );
  }, [applyDelete, t]);

  const filters = {
    title: [
      ...new Set(workerData?.map((worker) => worker.title).filter((x) => x !== undefined || null)),
    ],
  };

  const searchMatch = (worker) => {
    let completeName = worker?.firstName.toLowerCase() + ' ' + worker?.lastName.toLowerCase();
    if (!filterText) {
      return true;
    }
    if (
      worker?.firstName.toLowerCase().includes(filterText) ||
      worker?.lastName.toLowerCase().includes(filterText) ||
      completeName.includes(filterText)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const { title } = planningState.workersFilter.filters;
    if (!title.value.length) {
      setWorkers(workerData);
    } else {
      //filter based on selection
      const filteredWorkers = workerData.filter((worker) => {
        if (title.value.includes(worker?.title)) {
          return worker;
        }
      });
      setWorkers(filteredWorkers);
    }
  }, [filterText, planningState.workersFilter.filters, workerData]);

  const handleCreatePerson = useCallback(() => {
    showCreatePerson();
  }, [showCreatePerson]);

  return (
    <>
      <Container>
        {viewWorkPlan && (
          <div className="crew-container">
            <StyledDataLoader
              noData={crewData?.length === 0}
              isLoading={isFetchingWorkers}
              type={DataLoaderType.GRID}
              margin="auto"
              noDataTitle={t('general:no_data_title', 'No Data Available')}
            >
              <ScrollingContainer>
                {crewData.map((crew) => (
                  <TechsPanelCrew
                    crew={getSortCrewMember(crew)}
                    key={crew._id}
                    crewData={crewData}
                    workerData={workerData}
                  />
                ))}
              </ScrollingContainer>
            </StyledDataLoader>
          </div>
        )}
        <div className="crews-header">
          {deleteWorkPlan && (
            <button
              type="button"
              onClick={() => setShowModal(true)}
              disabled={serviceGroupIds.length !== 1}
            >
              <DeleteIcon />
              {t('all_crews', 'all crews')}
            </button>
          )}
          {createWorkPlan && (
            <Button disabled={serviceGroupIds.length !== 1} onClick={handleCreateCrew}>
              {t('create_crew', 'Create Crew')}
            </Button>
          )}
        </div>
        <div className="search-container">
          <div className="search-header">
            <h3 className="section-title">{t('workers', 'Workers')}</h3>
            <FilterBtn>
              {t('sort_filter', 'Sort & Filter')}
              <span>
                <button onClick={handleModalOpen}>
                  {isWorkerFilterVisible && <WorkersFilter filters={filters} />}
                </button>
              </span>
            </FilterBtn>
          </div>
          <WorkersSearchInput
            className="workers-search"
            placeholder={t('search_techs', 'Search techs')}
            onChange={(e) => setFilterText(e.currentTarget.value)}
          />
        </div>
        <div className="workers">
          <StyledDataLoader
            noData={!workerData?.length}
            isLoading={isFetchingWorkers}
            type={DataLoaderType.TABLE}
            margin="auto"
            noDataTitle={t('general:no_data_title', 'No Data Available')}
          >
            <ScrollingContainer offsetX={5} right>
              {workers.map((worker) => {
                if (searchMatch(worker)) {
                  return <TechsPanelTechnician worker={worker} key={worker.username} />;
                }
              })}
            </ScrollingContainer>
          </StyledDataLoader>
        </div>
        <div className="new-worker">
          <AuthRender
            capability={Capability.LIMITED_USER_MANAGEMENT}
            create
            description="New Worker button"
            siteLevel={false}
          >
            <Button type="button" full onClick={handleCreatePerson}>
              {t('new_worker', 'Add New Worker')}
            </Button>
          </AuthRender>
        </div>

        <DialogWrapper
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          header={t('general:confirmation', 'Confirmation')}
          footer={getCancelFooter}
          contentWidth
          padContent={true}
        >
          <p>
            <DeleteCrew>
              {`${t(
                'manage.planning:confirm_delete_all_crew',
                'Are you sure you want to delete all crews',
              )} `}
            </DeleteCrew>
          </p>
        </DialogWrapper>
      </Container>
    </>
  );
};

TechsPanel.propTypes = {
  workerData: PropTypes.instanceOf(Object),
  crewData: PropTypes.instanceOf(Object),
  isFetchingWorkers: PropTypes.instanceOf(Boolean),
};

TechsPanel.defaultProps = {
  workerData: null,
  crewData: null,
};
