import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledThreshold = styled.svg`
  circle {
    fill: transparent;
    stroke: ${(props) => props.theme.gaugeChart.thresholdBackground};
    stroke-width: 200px;
    stroke-dasharray: ${(props) => props.deg} 628;
    opacity: ${(props) => props.theme.gaugeChart.thresholdBgOpacity};
  }
`;

const ThresholdLine = styled.path`
  animation: rotate 5s linear infinite;
  transform-box: fill-box;
  transform-origin: 0px 110px;
  transform: rotate(${(props) => props.degree}deg);
  stroke: ${(props) => props.theme.gaugeChart.thresholdLine};
`;

const calcDeg = ({ max, maxPieDeg, pieDegPerPecent, threshold }) => {
  // Calculates the differnce of max pie degree and the pie degrees for the threshold.
  return maxPieDeg - (max - threshold) * pieDegPerPecent;
};

const ThresholdRadial = ({ threshold, reverse, max }) => {
  // May need to eventually expand this to be a bit more generic.
  const RADIAL_MAX = 620;
  const OFFSET = 12;
  const PIE_DEG_PER_PERCENT = 28;
  const LINE_PIE_DEG_PER_PERCENT = 16;

  const maxPieDeg = RADIAL_MAX - OFFSET;
  const lineMaxPieDeg = 80;

  const pieDeg = calcDeg({
    max,
    maxPieDeg,
    pieDegPerPecent: PIE_DEG_PER_PERCENT,
    threshold,
  });

  const lineDeg = calcDeg({
    max,
    maxPieDeg: lineMaxPieDeg,
    pieDegPerPecent: LINE_PIE_DEG_PER_PERCENT,
    threshold,
  });

  return (
    <g mask="url(#mask-3)">
      <StyledThreshold viewBox="0 0 120 120" deg={pieDeg} reverse={reverse}>
        <circle
          r="100"
          cx="60"
          cy="118"
          transform={reverse ? 'scale(-1, 1) translate(-120, 0)' : ''}
        />
      </StyledThreshold>
      <ThresholdLine
        strokeWidth="1"
        fillRule="evenodd"
        strokeDasharray="4,2"
        degree={lineDeg}
        d="M49,-59 L49,160"
        id="Line"
        style={{ mixBlendMode: 'multiply' }}
      />
    </g>
  );
};

ThresholdRadial.propTypes = {
  threshold: PropTypes.number,
  max: PropTypes.number,
  reverse: PropTypes.bool,
};

ThresholdRadial.defaultProps = {
  threshold: 0,
  max: 0,
  reverse: false,
};

export default ThresholdRadial;
