import { PropTypes } from 'prop-types';
import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Updated state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    {
      this.setState({ hasError: true });
    }
  }

  render() {
    if (this.state.hasError) {
      // Any custom fallback UI can be render here
      return <h1>Something went wrong. Please refresh this page.</h1>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
