import { PropTypes } from 'prop-types';
import React from 'react';

import { typography } from '@ge/tokens/typography';

import { Base } from './base';

export const Title = (props) => {
  const { level = 1, ...restProps } = props;
  let component = `h${level}`;
  return <Base {...restProps} component={component} />;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf(typography.titleLevels).isRequired,
};
