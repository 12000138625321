import Highcharts from 'highcharts';

import {
  getOriginalPoints,
  chartOptionsFactory,
} from '@ge/components/charts/chart-options-factory';
import { Icons } from '@ge/components/icon';
import { SortDirection, PowerCurve } from '@ge/models/constants';
import { mergeOptionsRight } from '@ge/util';
import { sorter } from '@ge/util/metric-sorter';

export const PlotLinesIds = {
  TRIP: 'trip',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

const setOpacity = (color, opacity) =>
  Highcharts.color(color)
    .setOpacity(opacity)
    .get();

const mapPlotLineProps = (theme) => (val) => {
  const color = theme.dataExplorer.charts.plotLineColors[val.id] ?? val.color;

  const label =
    {
      [PlotLinesIds.TRIP]: {
        useHTML: true,
        text: `<svg width="10px" height="5px" viewBox="0 0 10 5" style="transform: rotate(270deg)"><path d="${Icons.TRIP_LINE_ICON}" fill="${color}" transform="translate(-37, -289)"></path></svg>`,
        x: -1,
        y: -8,
      },
      [PlotLinesIds.HIGH]: {
        text: '+',
        x: -5,
        y: -110,
        style: {
          color,
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
      [PlotLinesIds.MEDIUM]: {
        text: '+',
        x: -5,
        y: -110,
        style: {
          color,
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
      [PlotLinesIds.LOW]: {
        text: '+',
        x: -5,
        y: -110,
        style: {
          color,
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
    }[val.id] ?? {};

  return {
    ...val,
    color: val.id === PlotLinesIds.TRIP ? color : setOpacity(color, 0.45),
    label: {
      ...label,
      verticalAlign: 'bottom',
    },
    width: 2,
    iconColor: color,
  };
};

const getSelectedPoints = ({ maxSelect, selectedPoints, xAxis, yAxis, multi, series }) => {
  const selectedSeries = {};
  if (!maxSelect || selectedPoints.length < maxSelect) {
    loop1: for (const { options } of series) {
      const { index, name, type, allowPointSelect, data, custom, yAxis: yAxisIndex } = options;
      const color = options.legendColor ?? options.color;
      if (!allowPointSelect || custom.isSelectedPointsSeries || name === PowerCurve.name) {
        continue loop1;
      }
      const points = [];
      for (const point of data) {
        const { x, y, ts } = point;
        const { value: xValue, min: xMin, max: xMax } = xAxis[0] ?? {};
        const { value: yValue, min: yMin, max: yMax } = yAxis[yAxisIndex] ?? {};
        if ((y === yValue && x === xValue) || (y >= yMin && y < yMax && x >= xMin && x < xMax)) {
          const point = { x, y, ts };
          selectedPoints.push(point);
          points.push(point);
          selectedSeries[name] = { index, name, type, color, points, custom };
        }
        if (selectedPoints.length === maxSelect) {
          break loop1;
        }
      }
    }
  }

  // The set of selected points may include clustered points.
  // Translate to the set of all selected individual points in the underlying data.
  const selection = selectedPoints.flatMap((point) => getOriginalPoints(point));

  return { multi, selection, series: Object.values(selectedSeries) };
};

const dataExplorerChartOptionsFactory = ({
  type,
  xAxis: _xAxis,
  yAxis: _yAxis,
  series,
  theme,
  height,
  xAxisTitle,
  xAxisType,
  yAxisTickPosition,
  xAxisLabelFormatter,
  zoom,
  maxSelect,
  boostThreshold,
  turboThreshold,
  useClustering,
  onCreated,
  onSelect,
  onSelected,
  onZoom,
}) => {
  const yAxis = _yAxis
    .sort(sorter('yAxis', SortDirection.ASC))
    .reduce((acc, signal) => {
      const index = signal.yAxis ?? 0;
      if (!acc[index]) {
        acc[index] = { labels: [], unit: '' };
      }
      acc[index].labels.push(signal.title);
      acc[index].unit = signal.unit;
      acc[index].opposite = index % 2 == 1;
      return acc;
    }, [])
    .map(({ opposite, labels, unit }) => ({
      endOnTick: true,
      ceiling: null,
      tickPosition: 'outside',
      gridLineColor: theme.dataExplorer.charts.gridLineColor,
      gridLineWidth: 0.5,
      maxPadding: 0.02,
      minPadding: 0.02,
      opposite,
      title: {
        useHTML: true,
        text: labels.join('<br />'),
        style: {
          color: theme.dataExplorer.charts.yAxisTitleColor,
          fontSize: '11px',
          textAlign: 'center',
        },
      },
      labels: {
        useHTML: true,
        x: opposite ? 6 : -6,
        y: -3,
        style: {
          color: theme.dataExplorer.charts.yAxisLabelColor,
          fontSize: '11px',
        },
        formatter: function() {
          const styles = {
            unit: `
              margin-top: 4px;
              position: absolute;
              top: 100%;
              ${opposite ? 'left: 0;' : 'right: 0;'}
            `,
          };
          const unitLabel = this.isFirst ? `<span style="${styles.unit}">${unit}</span>` : '';
          const axisLabel = `<span>${this.value}</span>`;
          return `<span>${unitLabel}${axisLabel}</span>`;
        },
      },
    }));

  const plotLines = [];
  const plotBands = [];

  _xAxis?.plotBands?.forEach(({ from, to }) => {
    plotBands.push({
      from,
      to,
      color: setOpacity(theme.dataExplorer.charts.plotBandColor, 0.15),
    });
    plotLines.push({
      value: from,
      color: theme.dataExplorer.charts.plotBandColor,
      width: 0.75,
    });
    plotLines.push({
      value: to,
      color: theme.dataExplorer.charts.plotBandColor,
      width: 0.75,
    });
  });

  _xAxis?.plotLines?.forEach((line) => {
    plotLines.push(mapPlotLineProps(theme)(line));
  });

  const xAxis = {
    gridLineWidth: 0,
    title: {
      style: {
        color: theme.dataExplorer.charts.xAxisTitleColor,
        fontSize: '11px',
      },
    },
    labels: {
      style: {
        color: theme.dataExplorer.charts.xAxisLabelColor,
        fontSize: '11px',
      },
    },
    tickLength: 10,
    plotBands,
    plotLines,
    events: {},
    id: _xAxis?.title ?? _xAxis?.id,
  };

  if (zoom && onZoom) {
    xAxis.events.afterSetExtremes = (e) => {
      const { max, min, dataMax, dataMin, displayBtn, options } = e.target ?? {};
      const level = (dataMax - dataMin) / (max - min);
      onZoom({
        active: !!displayBtn,
        level,
        max,
        min,
        dataMax,
        dataMin,
        trigger: e.trigger,
        options: { id: options.id },
      });
    };
  }

  const events = {
    // draggable selection
    selection: function(e) {
      const { ctrlKey, metaKey } = e?.originalEvent ?? {};
      const multi = ctrlKey || metaKey;

      // check for multi keys or shift or if zoom is added to chart props
      if (multi || !zoom) {
        e.preventDefault();
      }

      // select points in envelope (until we hit max selection if defined)
      if (e.xAxis !== undefined && e.yAxis !== undefined && multi) {
        let selectedPoints = multi ? this.getSelectedPoints() || [] : [];
        onSelect(
          getSelectedPoints({
            multi,
            maxSelect,
            selectedPoints,
            xAxis: e.xAxis,
            yAxis: e.yAxis,
            series: this.series,
          }),
        );
      }
    },
    click: function(e) {
      const selectedPoints = this.getSelectedPoints() || [];
      if (selectedPoints.length) {
        onSelect(
          getSelectedPoints({
            maxSelect,
            selectedPoints,
            xAxis: e.xAxis,
            yAxis: e.yAxis,
            series: this.series,
          }),
        );
      }
    },
  };

  const options = chartOptionsFactory({
    type,
    useClustering,
    boostThreshold,
    turboThreshold,
    series,
    theme,
    selectable: true,
    zoom,
    noDataLabel: '',
    xAxisTitle,
    yAxisTickPosition,
    xAxisType,
    xAxisLabelFormatter,
    xAxisTickLength: 10,
    onCreated,
    onSelected,
  });

  const _options = mergeOptionsRight(options, {
    xAxis,
    yAxis,
  });

  _options.chart.events = events;
  _options.chart.height = height;
  _options.chart.marginLeft = undefined;
  _options.chart.marginRight = undefined;
  _options.time.useUTC = true;

  return _options;
};

export default dataExplorerChartOptionsFactory;
