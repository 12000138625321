import { useStoreState } from 'easy-peasy';

import { KpiCategoryDefs, TimeAggr } from '@ge/models/constants';
import { roundNumber } from '@ge/util';

import { getDefaultDateRange, getFilterDateRange } from '../util';

import useSitesKpiData from './use-sites-kpi-data';

// unhardcode if needed
// TODO: add productioRatio in here when it's ready for future mvp
const categories = [
  KpiCategoryDefs.CAPACITY_FACTOR,
  KpiCategoryDefs.ACTUAL_PRODUCTION,
  KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
  KpiCategoryDefs.EVENT_COVERAGE,
  KpiCategoryDefs.PBA,
  KpiCategoryDefs.TBA,
];

export const useFleetSitePerformanceTable = ({ siteIds }) => {
  // store
  const { dateRange } = useStoreState(({ analyze }) => analyze);
  const regions = useStoreState((state) => state.regions.regions);
  const sitesState = useStoreState((state) => state.sites.sites);

  // TODO: cut this over to taylor's useFilterDateRange data hook when it's merged
  const filterDateRange = getFilterDateRange(dateRange);
  const defaultDateRange = getDefaultDateRange(dateRange.range);
  // going with utc for now because entityTimezone was giving end date in the future (tomorrow) causing backend service to throw error
  const endDate = filterDateRange?.endDate?.utc ?? defaultDateRange.endDate.utc;
  const startDate = filterDateRange?.startDate?.utc ?? defaultDateRange.startDate.utc;

  const {
    data: sitesKpiData,
    isLoading,
    error,
    refetch,
    isFetched,
  } = useSitesKpiData({
    siteIds,
    categories,
    def: categories,
    startDate,
    endDate,
    dateEntityAggr: 'Site',
    seriesEntityAggr: 'Site',
    timeAggr: TimeAggr.DAILY,
    entityType: 'Fleet',
    queryKey: siteIds,
    hydrateBy: siteIds,
    enabled: siteIds.length > 0,
  });

  const sitesDataProcessed = Object.entries(sitesKpiData).reduce(
    (sites, [category, { entityData = [] }]) => {
      entityData.forEach(({ entity: { id: siteId }, value }) => {
        const siteInfoFromState = sitesState[siteId];
        const region = regions[siteInfoFromState?.region?.id];
        sites[siteId] = {
          ...sites[siteId],
          id: siteId,
          name: sitesState[siteId]?.name,
          region: region ? { id: region.id, name: region.name } : undefined,
          performance: {
            ...sites[siteId]?.performance,
            [category]: roundNumber(value, 2),
          },
        };
      });
      return sites;
    },
    {},
  );

  const data = Object.values(sitesDataProcessed);

  const noData = !data?.length;

  return { data, error, isLoading, noData, refetch, isFetched };
};
