import { useQuery } from 'react-query';

import { Capability, PermissionScope } from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';

import { fetchTaskWorkScope } from '../services/task-template';

/**
 * Get task work scopes.
 *
 * @param entityType entity type
 * @param language language
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useTaskWorkScope = ({ entityType, language } = {}) => {
  const { isAuthorized } = useAuth();
  const isAuthorizedToGetTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  });
  return useQuery(
    ['task-workscopes', { entityType, language }],
    () => fetchTaskWorkScope({ assetType: entityType, language }),
    { enabled: isAuthorizedToGetTasks, ...Config.EXECUTE_ONCE },
  );
};
