import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PartsField, CloseTask } from '@ge/models/constants';

import { Parts } from '../../../task-fields/parts';
import { FormRow, FormSection } from '../../../task-template-shared';

export const CreatePartsConsumed = ({ metadata, type }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    // Pre-populate parts consumed with expected parts in create mode
    if (type === CloseTask.type) {
      const consumedParts = getValues().consumedParts;
      consumedParts?.length && setValue(PartsField.PARTS_CONSUMED, consumedParts);
    } else {
      const expectedParts = getValues().expectedParts;
      expectedParts?.length && setValue(PartsField.PARTS_CONSUMED, expectedParts);
    }
  }, [getValues, setValue, type]);

  return (
    <FormSection>
      <h4>{t('section_header.parts_consumed', 'Parts Consumed')}</h4>
      {!metadata?.partsConsumed.hidden && (
        <Parts metadata={metadata.partsConsumed.create} fieldName={PartsField.PARTS_CONSUMED} />
      )}
      <FormRow></FormRow>
    </FormSection>
  );
};

CreatePartsConsumed.propTypes = {
  collapsed: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
  type: PropTypes.String,
};

CreatePartsConsumed.defaultProps = {
  collapsed: true,
};
