import React, { useMemo, memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import {
  useCreateHP,
  useDeleteHP,
  useEventMaps,
  useFetchHPRecord,
  useUpdateHP,
} from '@ge/shared/data-hooks/use-handling-procedure';
import { SidePanel } from '@ge/web-client/src/app/components/side-panel/side-panel';

import { DEFAULT_EVENT_PROPS, EditButton, HLP_ACTIONS, useMode } from './hp-common';
import { HLPForm } from './hp-form';
import HandlingProcedureFooter from './hp-popup-footer';
import { HLPHeader } from './hp-popup-header';
import {
  ErrorWrapper,
  FormContainer,
  HeaderWrapper,
  ScrollableDiv,
  StyledConfirmationFooterWrapper,
  StyledOverView,
} from './hp-styled-components';
import { HandlingProcedureTable } from './hp-view-table';

const defaultValue = {
  title: '',
  desc: '',
  eventsProp: [DEFAULT_EVENT_PROPS],
};

const defaultConfirmPopUp = {
  isVisible: false,
  actionConfirm: null,
  message: '',
};

const HPSidepanel = () => {
  const { t } = useTranslation(['admin.configure']);
  const { action, id, pathname } = useMode();
  const history = useHistory();
  const { data, isLoading: loadingGetRecord } = useFetchHPRecord({
    id: id,
    params: {
      query: `procId==${id}`,
      eventsData: true,
    },
    isActive: id,
  });
  const {
    deleteHP,
    isLoading: loadingDeleteRecord,
    isSuccess: isDeleteSuccess,
    deleteErrorMsg,
  } = useDeleteHP();
  const {
    isSuccess: isCreateSuccess,
    isLoading: loadingCreateRecord,
    createHP,
    createErrorMsg,
  } = useCreateHP();
  const {
    isSuccess: isUpdateSuccess,
    isLoading: loadingUpdateRecord,
    updateHP,
    updateErrorMsg,
  } = useUpdateHP();
  const [confirmPopUp, setConfirmPopUp] = useState(defaultConfirmPopUp);
  const { eventMapsLoading } = useEventMaps();
  const [hasDuplicateRecords, setHasDuplicateRecords] = useState(null);
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValue,
  });
  const { isDirty } = methods.formState;

  useEffect(() => {
    data && methods.reset({ ...data.procProp, eventsProp: data.eventsProp });
  }, [data]);

  useEffect(() => {
    if (isDeleteSuccess || isCreateSuccess || isUpdateSuccess) {
      history.goBack();
    }
  }, [isCreateSuccess, isDeleteSuccess, isUpdateSuccess]);

  const isLoading = useMemo(
    () =>
      loadingCreateRecord ||
      loadingDeleteRecord ||
      loadingGetRecord ||
      eventMapsLoading ||
      loadingUpdateRecord,
    [
      loadingCreateRecord,
      loadingGetRecord,
      loadingDeleteRecord,
      eventMapsLoading,
      loadingUpdateRecord,
    ],
  );

  const errorMsg = useMemo(
    () => createErrorMsg || updateErrorMsg || deleteErrorMsg,
    [createErrorMsg, deleteErrorMsg, updateErrorMsg],
  );

  const resetConfirmPopUp = () => {
    setConfirmPopUp(defaultConfirmPopUp);
  };

  const onDelete = () => {
    resetConfirmPopUp();
    deleteHP(id);
  };

  const onCreateHP = (res) => {
    const { result, isError, commonEventCodes } = res;
    if (isError) return setHasDuplicateRecords(commonEventCodes.join(','));
    if (HLP_ACTIONS.NEW === action) {
      return createHP(result);
    }
    return updateHP({
      procId: id,
      ...result,
    });
  };

  const onEditClick = () => {
    history.push({
      pathname: pathname,
      search: `?mode=${HLP_ACTIONS.EDIT}__${id}`,
    });
  };

  const handleCancel = () => {
    if (!isDirty) {
      return history.goBack();
    }
    setConfirmPopUp({
      isVisible: true,
      actionConfirm: () => {
        resetConfirmPopUp();
        action === HLP_ACTIONS.EDIT &&
          methods.reset({ ...data.procProp, eventsProp: data.eventsProp });
        history.push(pathname);
      },
      message: t(
        'Handling_procedures.cancel_confirmation_message',
        'Are you sure you want to cancel this Handling Procedure?',
      ),
    });
  };

  const onClose = () => {
    if (action === HLP_ACTIONS.VIEW || !isDirty) {
      return history.push(pathname);
    }

    handleCancel();
  };

  return (
    <CSSTransition
      in={true}
      classNames="details-panel-transition"
      onExited={(e) => close(e)}
      timeout={300}
      unmountOnExit
    >
      <SidePanel close={confirmPopUp.isVisible || isLoading ? () => {} : onClose}>
        {isLoading ? (
          <Loader />
        ) : (
          <HLPHeader
            action={action}
            name={data?.procProp.title}
            onDelete={() =>
              setConfirmPopUp({
                isVisible: true,
                actionConfirm: onDelete,
                message: t(
                  'Handling_procedures.delete_confirmation_message',
                  'Are you sure you want to delete this Handling Procedure?',
                ),
              })
            }
          />
        )}

        {errorMsg && <ErrorWrapper>{errorMsg}</ErrorWrapper>}
        <ScrollableDiv className={isLoading ? 'loading-cls' : ''}>
          <FormContainer>
            <HeaderWrapper>
              <StyledOverView>OVERVIEW</StyledOverView>
              {action === HLP_ACTIONS.VIEW ? <EditButton onClick={onEditClick} /> : null}
            </HeaderWrapper>
            <FormProvider {...methods}>
              {' '}
              <HLPForm action={action} auditProps={data?.auditProperties || {}}>
                <HandlingProcedureTable
                  action={action}
                  setHasDuplicateRecords={setHasDuplicateRecords}
                  hasDuplicateRecords={hasDuplicateRecords}
                />
              </HLPForm>{' '}
            </FormProvider>
          </FormContainer>
          {action === HLP_ACTIONS.VIEW ? null : (
            <HandlingProcedureFooter
              onCancel={handleCancel}
              onSubmit={methods.handleSubmit}
              onCreateHP={onCreateHP}
            />
          )}
          <Dialog
            isOpen={confirmPopUp.isVisible}
            onClose={resetConfirmPopUp}
            onConfirm={confirmPopUp.actionConfirm}
            header={t('general:confirmation', 'Confirmation')}
            footer={
              <StyledConfirmationFooterWrapper>
                <Button onClick={resetConfirmPopUp}>{t('general:cancel', 'Cancel')}</Button>
                <Button onClick={confirmPopUp.actionConfirm} primary>
                  {t('general:ok', 'OK')}
                </Button>
              </StyledConfirmationFooterWrapper>
            }
            contentWidth
            padContent={true}
          >
            <p>{confirmPopUp.message}</p>
          </Dialog>
        </ScrollableDiv>
      </SidePanel>
    </CSSTransition>
  );
};

export default memo(HPSidepanel);
