import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { MiniLoader } from '@ge/components/loader';
import { Tab, Tabs } from '@ge/components/tabs';
import {
  AttributeGroups,
  EntityType,
  NewPersonField,
  PersonCheckboxValue,
  PersonCreateType,
  PersonAssetWorkerTab,
} from '@ge/models/constants';
import { getPreferredUserName } from '@ge/shared/services/auth';

import { useFetchPerson } from '../../entity-details/person-details/hooks/use-fetch-person';
import AssetUtilityBar from '../components/asset-utility-bar';
import EntityPicker from '../components/entity-picker';
import { Description, Header } from '../entity-create-shared';
import { useEntitiesFiltered } from '../hooks/use-entities-filtered';
import { useRoleSubsets } from '../hooks/use-role-subsets';
import useRoles from '../hooks/use-roles';

const PickerLabel = styled.div`
  margin: 0 14px 10px auto;
  text-align: right;
`;

const HeaderWrapper = styled.div`
  position: relative;
  label {
    position: absolute;
    right: 0;
    top: 10px;
  }
`;

const StyledTabs = styled(Tabs)`
  &.force-render .active {
    height: auto;
    z-index: 10;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  margin-bottom: 15px;

  &.read-only {
    > div {
      opacity: 0.6;
      /* TEMPORARY FIX UNTIL REQUIREMENTS CAN BE SORTED OUT */
      visibility: hidden;
    }
    > span {
      opacity: 0.6;
      /* TEMPORARY FIX UNTIL REQUIREMENTS CAN BE SORTED OUT */
      visibility: hidden;
    }
  }
`;

const DynamicAll = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: auto;
  padding: 16px 0 0;
  &.hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

const PersonApplyAssets = ({ className, personAttributes }) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });
  const personId = getPreferredUserName();

  const { data: fetchedPerson, isFetching: isFetchingPerson } = useFetchPerson(personId);
  const { data: roleSubsets, isLoading: isRoleSubsetsLoading } = useRoleSubsets();
  const { data: roles, isLoading: isRolesLoading } = useRoles();

  const { control, setValue, watch } = useFormContext();

  const watchWorker = watch(PersonCheckboxValue.WORKER);
  const watchApplyWorkerUser = watch(PersonCheckboxValue.APPLY_WORKER_USER);
  const watchApplyAllUserRoles = watch(PersonCheckboxValue.APPLY_ALL_USER_ROLES);
  const watchSelectedRoles = watch(PersonCheckboxValue.SELECTED_ROLES);

  const [viewBy, setViewBy] = useState(EntityType.SERVICE_GROUP);
  const [filterText, setFilterText] = useState('');
  const [activeTab, setActiveTab] = useState(null);
  let defaultTab = watchWorker
    ? PersonAssetWorkerTab.ASSETS_FOR_WORKER
    : watchSelectedRoles[0]?.name;
  let selectedTab = activeTab ? activeTab : defaultTab;
  const { entitiesFiltered, isLoading } = useEntitiesFiltered(viewBy, filterText, selectedTab);

  const [parentEntityType, setParentEntityType] = useState(EntityType.SERVICE_GROUP);
  const [childEntityType, setChildEntityType] = useState(EntityType.SITE);
  const [roleCheck, setRoleCheck] = useState([]);

  useEffect(() => {
    if (!isFetchingPerson && !isRoleSubsetsLoading) {
      // FILTER OPERATORS ATTRIBUTES AND RETURN ONLY ROLES THAT HAVE SITES === 'ALL'
      if (fetchedPerson.type.includes(PersonCreateType.USER)) {
        const operatorRolesWithSites = fetchedPerson.attributes.entity_group
          .filter((attribute) => attribute.groupCombination.length > 0)
          .map((attribute) => attribute)
          .filter((group) =>
            group.groupCombination
              .map((combination) => combination.groupName)
              .includes(AttributeGroups.SITES),
          );

        const RolesWithAll = operatorRolesWithSites.flatMap((role) =>
          role.groupCombination
            .filter((group) => group.groupName === AttributeGroups.SITES)
            .flatMap((combination) => combination.ids)
            .includes(AttributeGroups.ALL)
            ? role.roleName
            : [],
        );
        // SET IN STATE ONLY ROLE(S) SUBSETS WITH 'ALL'
        const roleSubsetArray = [];
        // eslint-disable-next-line
        roleSubsets.map((role) => {
          for (const key in role) {
            if (RolesWithAll.includes(key)) {
              roleSubsetArray.push(role[key]);
            }
          }
        });
        const flattenedArray = roleSubsetArray.flatMap((role) => role.map((item) => item.name));
        setRoleCheck([...new Set(flattenedArray)]);
      }
    }
  }, [fetchedPerson, isFetchingPerson, isRoleSubsetsLoading, roleSubsets]);

  useEffect(() => {
    if (viewBy === EntityType.SERVICE_GROUP) {
      setParentEntityType(EntityType.SERVICE_GROUP);
      setChildEntityType(EntityType.SITE);
    } else {
      setParentEntityType(EntityType.SITE);
      setChildEntityType(null);
    }
  }, [viewBy]);

  const handleViewByChange = useCallback((option) => {
    setViewBy(option);
  }, []);

  const handleFilterTextChange = useCallback((filterText) => {
    setFilterText(filterText);
  }, []);

  const onTabChange = (val) => {
    setActiveTab(val);
  };

  const isActiveTabContent = (activeTab, roleName) => {
    let selectedTab = activeTab ? activeTab : watchSelectedRoles[0]?.name;
    return (
      roleName?.replace(/[_-]/g, ' ').toLowerCase() ===
      selectedTab?.replace(/[_-]/g, ' ').toLowerCase()
    );
  };
  const getEntityPicker = useCallback(
    (isDisableChildren, fieldName, appliedEntities, dynamicAllKey, index) => (
      <div>
        <AssetUtilityBar
          viewBy={viewBy}
          filterText={filterText}
          isDisableViewBy={isDisableChildren}
          onViewByChange={handleViewByChange}
          onFilterTextChange={handleFilterTextChange}
        />
        {isLoading ? (
          <MiniLoader />
        ) : (
          <>
            <PickerLabel>{t('assets.number_of_assets', '# of Assets')}</PickerLabel>
            <EntityPicker
              childEntityType={childEntityType}
              isDisableChildren={viewBy === EntityType.SERVICE_GROUP || isDisableChildren}
              entityCollection={entitiesFiltered}
              filterText={filterText}
              isAssetCount={true}
              fieldName={fieldName}
              appliedEntities={appliedEntities}
              personAttributes={personAttributes}
              parentEntityType={parentEntityType}
              dynamicAllKey={dynamicAllKey}
              entityIndex={index}
            />
          </>
        )}
      </div>
    ),
    [
      childEntityType,
      entitiesFiltered,
      filterText,
      handleFilterTextChange,
      handleViewByChange,
      isLoading,
      parentEntityType,
      personAttributes,
      t,
      viewBy,
    ],
  );

  if (isRolesLoading) return null;

  return (
    <div className={className}>
      <HeaderWrapper>
        <Header>{t('apply_assets.header', 'Apply Assets')}</Header>
        <Description>
          {t('apply_assets.desc', 'This user is assigned to the assets selected below.')}
        </Description>
        {watchWorker && (
          <Controller
            control={control}
            defaultValue={false}
            name="applyWorkerUser"
            render={({ value }) => (
              <StyledCheckbox
                label={t(
                  'apply_assets.apply_assets_worker',
                  'Apply these assets to both Worker and User Roles',
                )}
                className={'read-only'}
                checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                onChange={(e) => {
                  setValue('applyWorkerUser', e);
                }}
                value={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                readOnly={true}
              />
            )}
          />
        )}
      </HeaderWrapper>
      {!watchApplyWorkerUser && (
        <Controller
          control={control}
          defaultValue={true}
          name="applyAllUserRoles"
          render={({ value }) => (
            <StyledCheckbox
              label={t('apply_assets.apply_user_roles', 'Apply same assets to all User Roles')}
              className={'read-only'}
              checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              onChange={(e) => {
                setValue('applyAllUserRoles', e);
              }}
              value={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              readOnly={true}
            />
          )}
        />
      )}
      {!watchWorker && watchApplyAllUserRoles && getEntityPicker(false)}
      {!watchWorker && !watchApplyAllUserRoles && (
        <StyledTabs
          onChangeHandler={(val) => onTabChange(val)}
          defaultTab={watchSelectedRoles[0]?.name?.replace(/_/g, '-').toLowerCase()}
          isForceRenderTabs={true}
        >
          {watchSelectedRoles.map((role, index) => (
            <Tab
              key={`${role.name}${watchSelectedRoles.length}`}
              label={t(
                role.name.replace(/[_-]/g, ' '),
                roles.find((obj) => obj.a11yKey === role.name)?.defaultLabel,
              )}
            >
              <DynamicAll className={!roleCheck.includes(role.name) ? 'hidden' : null}>
                <Controller
                  control={control}
                  defaultValue={false}
                  name={`userDynamicAll-${index}`}
                  render={({ value }) => (
                    <StyledCheckbox
                      label={t('apply_assets.dynamic_all', 'Apply ALL SITES in the tenant')}
                      checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                      onChange={(e) => {
                        setValue(`userDynamicAll-${index}`, e);
                      }}
                      value={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                    />
                  )}
                />
              </DynamicAll>
              {isActiveTabContent(activeTab, role.name) &&
                getEntityPicker(
                  false,
                  `selectedRoles[${index}].entities`,
                  watchSelectedRoles[index].entities,
                  `selectedRoles[${index}].isDynamicAll`,
                  index,
                )}
            </Tab>
          ))}
        </StyledTabs>
      )}
      {watchWorker && watchApplyWorkerUser && getEntityPicker(true)}
      {watchWorker && !watchApplyWorkerUser && watchApplyAllUserRoles && (
        <StyledTabs defaultTab={'worker'} isForceRenderTabs={true}>
          <Tab label={PersonAssetWorkerTab.ASSETS_FOR_WORKER}>
            {getEntityPicker(true, NewPersonField.WORKER_ASSETS)}
          </Tab>
          <Tab label={t('apply_assets.all_user_roles', 'All User Roles')}>
            {getEntityPicker(false, NewPersonField.ALL_USER_ROLES)}
          </Tab>
        </StyledTabs>
      )}
      {watchWorker && !watchApplyWorkerUser && !watchApplyAllUserRoles && (
        <StyledTabs
          onChangeHandler={(val) => onTabChange(val)}
          defaultTab={'worker'}
          isForceRenderTabs={true}
        >
          {[{ role: { name: PersonAssetWorkerTab.ASSETS_FOR_WORKER } }, ...watchSelectedRoles].map(
            (role, index) => (
              <Tab
                key={`${role.name}`}
                label={
                  index === 0
                    ? PersonAssetWorkerTab.ASSETS_FOR_WORKER
                    : t(
                        role.name.replace(/[_-]/g, ' '),
                        roles.find((obj) => obj.a11yKey === role.name)?.defaultLabel,
                      )
                }
              >
                {index !== 0 ? (
                  <DynamicAll className={!roleCheck.includes(role.name) ? 'hidden' : null}>
                    <Controller
                      control={control}
                      defaultValue={false}
                      name={`userDynamicAll-${index - 1}`}
                      render={({ value }) => (
                        <StyledCheckbox
                          key={`${role.name}`}
                          label={t('apply_assets.dynamic_all', 'Apply ALL SITES in the tenant')}
                          checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                          onChange={(e) => {
                            setValue(`userDynamicAll-${index - 1}`, e);
                          }}
                          value={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                        />
                      )}
                    />
                  </DynamicAll>
                ) : null}
                {index === 0 && getEntityPicker(true, NewPersonField.WORKER_ASSETS)}
                {activeTab &&
                  isActiveTabContent(activeTab, role.name) &&
                  getEntityPicker(
                    false,
                    `selectedRoles[${index - 1}].entities`,
                    watchSelectedRoles[index - 1].entities,
                    `selectedRoles[${index - 1}].isDynamicAll`,
                    `${index - 1}`,
                  )}
              </Tab>
            ),
          )}
        </StyledTabs>
      )}
    </div>
  );
};

PersonApplyAssets.propTypes = {
  className: PropTypes.string,
  personAttributes: PropTypes.object,
  personIsFound: PropTypes.bool,
};

PersonApplyAssets.defaultProps = {
  className: null,
};

export default PersonApplyAssets;
