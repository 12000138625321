import { KpiCategoryDefs, TableFilterTypes } from '@ge/models/constants';
import { AssetsColumns as _AssetsColumns } from '@ge/shared/models/table-col-defs/assets-table-cols';

const MAX_FILTERED_VALUES = 1000;
export const AssetsColumns = {
  // Assets
  GROUP_ASSETS: _AssetsColumns.GROUP_ASSETS,
  ASSET: _AssetsColumns.ASSET,

  // Asset Make
  GROUP_ASSET_MAKE: _AssetsColumns.GROUP_ASSET_MAKE,
  ASSET_MAKE: _AssetsColumns.ASSET_MAKE,

  // Asset Model
  GROUP_ASSET_MODEL: _AssetsColumns.GROUP_ASSET_MODEL,
  ASSET_MODEL: _AssetsColumns.ASSET_MODEL,

  // TBA
  GROUP_TBA: _AssetsColumns.GROUP_TBA,
  TBA: _AssetsColumns.TBA,

  // Contractual Availability
  GROUP_AVAILABILITY_CONTRACT: _AssetsColumns.GROUP_AVAILABILITY_CONTRACT,
  AVAILABILITY_CONTRACT: _AssetsColumns.AVAILABILITY_CONTRACT,

  // PBA
  GROUP_PBA: _AssetsColumns.GROUP_PBA,
  PBA: _AssetsColumns.PBA,

  // Unproduced Energy
  GROUP_UNPRODUCED_ENERGY_MWH: _AssetsColumns.GROUP_UNPRODUCED_ENERGY_MWH,
  UNPRODUCED_ENERGY_MWH: _AssetsColumns.UNPRODUCED_ENERGY_MWH,

  // Actual Production
  GROUP_ACTUAL_PRODUCTION_MWH: _AssetsColumns.GROUP_ACTUAL_PRODUCTION_MWH,
  ACTUAL_PRODUCTION_MWH: _AssetsColumns.ACTUAL_PRODUCTION_MWH,

  // Cumulative Production
  GROUP_CUMULATIVE_PROD: _AssetsColumns.GROUP_CUMULATIVE_PROD,
  CUMULATIVE_PROD: _AssetsColumns.CUMULATIVE_PROD,

  // Capacity Factor
  GROUP_CAPACITY_FACTOR: _AssetsColumns.GROUP_CAPACITY_FACTOR,
  CAPACITY_FACTOR: _AssetsColumns.CAPACITY_FACTOR,

  // Event Rate
  GROUP_EVENT_RATE: _AssetsColumns.GROUP_EVENT_RATE,
  EVENT_RATE: _AssetsColumns.EVENT_RATE,

  // Event Coverage
  GROUP_EVENT_COVERAGE: _AssetsColumns.GROUP_EVENT_COVERAGE,
  EVENT_COVERAGE: _AssetsColumns.EVENT_COVERAGE,

  // Asset Detail
  GROUP_ASSET_DETAIL: _AssetsColumns.GROUP_ASSET_DETAIL,
  ASSET_DETAIL: _AssetsColumns.ASSET_DETAIL,

  //Average Wind Speed
  GROUP_AVERAGE_WINDSPEED: _AssetsColumns.GROUP_AVERAGE_WINDSPEED,
  AVERAGE_WINDSPEED: _AssetsColumns.AVERAGE_WINDSPEED,
};

// Define all available columns in the assets table.
export const AssetsColumnDefs = {
  [AssetsColumns.GROUP_ASSETS]: {
    labels: [
      {
        a11yKey: 'table.assets',
        a11yDefault: 'ASSETS',
      },
    ],
    cols: {
      [AssetsColumns.ASSET]: {
        locked: true,
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        align: 'left',
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: AssetsColumns.ASSET,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ASSET_MAKE]: {
    cols: {
      [AssetsColumns.ASSET_MAKE]: {
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.asset_make',
            a11yDefault: 'Asset Make',
            sortValue: AssetsColumns.ASSET_MAKE,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ASSET_MODEL]: {
    cols: {
      [AssetsColumns.ASSET_MODEL]: {
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'table.asset_model',
            a11yDefault: 'Asset Model',
            sortValue: AssetsColumns.ASSET_MODEL,
          },
        ],
      },
    },
  },
  // removing for now because not in current mvp
  // [AssetsColumns.GROUP_EVENT_RATE]: {
  //   cols: {
  //     [AssetsColumns.EVENT_RATE]: {
  //       whiteSpace: 'wrap',
  //       align: 'left',
  //       labels: [
  //         {
  //           a11yKey: 'table.event_rate',
  //           a11yDefault: 'Event Rate',
  //           sortValue: 'metrics.production.faultRate',
  //         },
  //       ],
  //     },
  //   },
  // },
  [AssetsColumns.GROUP_TBA]: {
    cols: {
      [AssetsColumns.TBA]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.tba',
            a11yDefault: 'TBA - Technical\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.TBA}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_AVAILABILITY_CONTRACT]: {
    cols: {
      [AssetsColumns.AVAILABILITY_CONTRACT]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.contractual_availability',
            a11yDefault: 'Contractual Availability\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.AVAILABILITY_CONTRACT}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_PBA]: {
    cols: {
      [AssetsColumns.PBA]: {
        filterType: TableFilterTypes.NUMBER,
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.pba',
            a11yDefault: 'PBA - Technical\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.PBA}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_UNPRODUCED_ENERGY_MWH]: {
    cols: {
      [AssetsColumns.UNPRODUCED_ENERGY_MWH]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.unproduced_energy_mwh',
            a11yDefault: 'Unproduced Energy\nMWh',
            sortValue: `metrics.performance.${KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ACTUAL_PRODUCTION_MWH]: {
    cols: {
      [AssetsColumns.ACTUAL_PRODUCTION_MWH]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.actual_production_mwh',
            a11yDefault: 'Actual Production\nMWh',
            sortValue: `metrics.performance.${KpiCategoryDefs.PRODUCTION_ACTUAL}`,
          },
        ],
      },
    },
  },
  // removing for now because not in current mvp
  // [AssetsColumns.GROUP_CUMULATIVE_PROD]: {
  //   cols: {
  //     [AssetsColumns.CUMULATIVE_PROD]: {
  //       whiteSpace: 'wrap',
  //       minWidth: '50px',
  //       align: 'left',
  //       labels: [
  //         {
  //           a11yKey: 'table.cumulativeProduction',
  //           a11yDefault: 'Cumulative Production\n%',
  //           sortValue: `metrics.performance.${KpiCategoryDefs.CUMULATIVE_PRODUCTION}`,
  //         },
  //       ],
  //     },
  //   },
  // },
  [AssetsColumns.GROUP_CAPACITY_FACTOR]: {
    cols: {
      [AssetsColumns.CAPACITY_FACTOR]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.capacity_factor',
            a11yDefault: 'Capacity Factor\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.CAPACITY_FACTOR}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_EVENT_COVERAGE]: {
    cols: {
      [AssetsColumns.EVENT_COVERAGE]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.event_coverage',
            a11yDefault: 'Event Coverage\n%',
            sortValue: `metrics.performance.${KpiCategoryDefs.EVENT_COVERAGE}`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_AVERAGE_WINDSPEED]: {
    cols: {
      [AssetsColumns.AVERAGE_WINDSPEED]: {
        filterType: TableFilterTypes.NUMBER,
        whiteSpace: 'wrap',
        minWidth: '85px',
        align: 'left',
        labels: [
          {
            a11yKey: 'table.average_wind_speed',
            a11yDefault: 'Average Wind Speed (m/sec)',
            sortValue: `metrics.conditions.windSpeedAverage`,
          },
        ],
      },
    },
  },
  [AssetsColumns.GROUP_ASSET_DETAIL]: {
    fixedRight: true,
    cols: {
      [AssetsColumns.ASSET_DETAIL]: {
        fixedRight: true,
        labels: [
          {
            a11yKey: 'table.asset_detail',
            a11yDefault: 'Asset Details',
          },
        ],
        cell: {
          zeroPadding: true,
        },
      },
    },
  },
};
