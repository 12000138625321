import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import MajorLossChart from '@ge/feat-analyze/components/asset/major-loss-chart';
import { DashboardDateFilter } from '@ge/feat-analyze/components/dashboard/dashboard-date-filter';
import { DashboardKpiChart } from '@ge/feat-analyze/components/dashboard/dashboard-kpi-chart';
import { DashboardPowerCurveChart } from '@ge/feat-analyze/components/dashboard/dashboard-power-curve-chart';
import { DashboardStatsHeader } from '@ge/feat-analyze/components/dashboard/dashboard-stats-header';
import { AssetProvider } from '@ge/feat-analyze/context';
import { useAssetDetail } from '@ge/feat-analyze/data-hooks/use-asset-detail';
import useAssetIecData from '@ge/feat-analyze/data-hooks/use-asset-iec-data';
import useAssetKpiData from '@ge/feat-analyze/data-hooks/use-asset-kpi-data';
import { FilterDefs, AssetKpiChartDefs } from '@ge/feat-analyze/models';
import { getKpiCategories } from '@ge/feat-analyze/util';
import { DateRange, EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { getFilterDateRange } from '@ge/shared/util';

import { TabDetailContainer } from '../../entity-details-shared';

const CHART_HEIGHT = 280;

const ChartType = {
  POWER_CURVE: 'powerCurve',
  TBA: 'tba',
  UNPRODUCED_ENERGY: 'unproducedEnergy',
};

const chartDefMap = {
  [ChartType.TBA]: AssetKpiChartDefs[FilterDefs.AVAILABILITY].tba,
};

const getKpiCategoriesByDef = (def = []) =>
  Array.from(
    new Set([
      // these are always included for the kpi header and table
      KpiCategoryDefs.CAPACITY_FACTOR,
      KpiCategoryDefs.EVENT_COVERAGE,
      // also seeing EVENT_RATE, are these dupe, which to use?
      KpiCategoryDefs.EVENT_RATES,
      KpiCategoryDefs.PRODUCTION_ACTUAL,
      // mvp0 - no learned data available yet so only contract
      KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
      KpiCategoryDefs.TBA,
      ...getKpiCategories(def),
    ]),
  );

const StyledTabDetailContainer = styled(TabDetailContainer)`
  position: relative;
`;

// reusing theming from site panel
// can we make theming more generic so not specific to site panel?
const DashboardContainer = styled.div`
  margin: 0px 15px 30px 10px;
  background-color: ${(props) => props.theme.entityDetails.sitePanelBlockBackground};
`;

const DashboardHeaderContainer = styled(DashboardContainer)`
  > div {
    align-items: center;
    justify-content: center;
    margin: 10px 0 14px;

    > div {
      border-left-color: ${(props) => props.theme.entityDetails.sitePanelBlockBorder};
    }
  }
  .kpi-title {
    h3 {
      margin-bottom: 0;
    }
  }
`;

const DashboardChartContainer = styled(DashboardContainer)`
  padding: 16px 5px 30px;

  .chart-header {
    h2 {
      margin-left: 20px;
    }

    .chart-select {
      margin: 10px auto 4px 8px;
    }
  }

  .chart {
    padding: 0;
  }
`;

const StyledHeader = styled.h2`
  font-size: 18px;
  margin-left: 35px;
  margin-bottom: 0 !important;
`;

const DateFilterContainer = styled.div`
  display: flex;
  flex-flow: row-reverse;
  margin: 4px 0;
`;

export const AssetPerformance = ({ assetId, isDatePersist, popsout }) => {
  // state
  const [{ categories, def, type }, setChartState] = useState(() => {
    const type = ChartType.TBA;
    const def = chartDefMap[type];

    return {
      categories: getKpiCategoriesByDef(def),
      def,
      type,
    };
  });

  // store
  const getDateRange = useStoreState((state) => state.analyze.getDateRange);

  // store
  let { dateRange: dates } = useStoreState(({ analyze }) => analyze);
  const { panelDateRange } = useStoreState(({ analyze }) => analyze);
  const { updatePanelDateRange } = useStoreActions(({ analyze }) => analyze);

  const [dateRange, setDateRange] = useState({});
  if (popsout) {
    dates = panelDateRange;
  }
  useEffect(() => {
    if (!popsout) {
      updatePanelDateRange(dates);
    }
  }, [popsout, updatePanelDateRange, dates]);

  useEffect(() => {
    // don't notify if custom date range is incomplete
    if (dates.range === DateRange.CUSTOM && !(dates.endDate && dates.startDate)) {
      return;
    }

    const filterDateRange = getFilterDateRange({
      startDate: dates.startDate,
      range: dates.range,
      endDate: dates.endDate,
    });

    setDateRange({
      startDate: filterDateRange.startDate.entityTimezone,
      endDate: filterDateRange.endDate.entityTimezone,
    });
  }, [dates]);

  const onDateFilterChange = ({ endDate, range, startDate }) => {
    const filterDateRange = getFilterDateRange({ endDate, range, startDate });
    setDateRange({
      startDate: filterDateRange.startDate.entityTimezone,
      endDate: filterDateRange.endDate.entityTimezone,
    });
  };

  // hooks
  const { t } = useTranslation(['analyze.entity-panel'], { useSuspense: false });
  const { endDate, startDate } = dateRange;

  // data hooks
  const {
    data: kpiData,
    error: kpiError,
    isLoading: isKpiLoading,
  } = useAssetKpiData({
    assetId,
    categories,
    endDate,
    startDate,
  });

  const {
    data: iecCategoryData,
    error: iecCategoryError,
    isLoading: isIecLoading,
  } = useAssetIecData({
    assetId,
    category: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    endDate,
    isActive: type === ChartType.UNPRODUCED_ENERGY,
    startDate,
  });

  // handlers
  const handleSelectChange = useCallback(({ value: type }) => {
    const def = chartDefMap[type];
    const categories = getKpiCategoriesByDef(def);

    setChartState({
      categories,
      def,
      type,
    });
  }, []);

  // Get site details
  const { site } = useAssetDetail({ assetId });

  const translations = {
    performanceTitle: t('performance_title', 'Performance'),
    powerCurveLabel: t('power_curve_label', 'Power curve'),
    tbaTechnicalLabel: t('tba_technical_label', 'TBA-Technical'),
    unproducedEnergyLabel: t('unproduced_energy_label', 'Unproduced Energy'),
  };

  const selectOptions = [
    { label: translations.tbaTechnicalLabel, value: ChartType.TBA },
    { label: translations.powerCurveLabel, value: ChartType.POWER_CURVE },
    { label: translations.unproducedEnergyLabel, value: ChartType.UNPRODUCED_ENERGY },
  ];

  const renderChart = () => {
    switch (type) {
      case ChartType.POWER_CURVE:
        return (
          <DashboardPowerCurveChart
            assetIds={[assetId]}
            dateRange={getDateRange(site?.timezone)}
            hasTitle={false}
            site={site}
          />
        );

      case ChartType.TBA:
        return (
          <DashboardKpiChart
            chartDef={def.graph4}
            className="chart"
            data={kpiData}
            entityType={EntityType.TURBINE}
            error={kpiError}
            height={CHART_HEIGHT}
            hideBorder
            isLoading={isKpiLoading}
            namespace="analyze.dashboard"
            parentEntity={site}
            showTitle={false}
            showToggle={false}
            xAxisTitle={translations.assetsTitle}
          />
        );

      case ChartType.UNPRODUCED_ENERGY:
        return (
          <AssetProvider>
            <MajorLossChart
              data={iecCategoryData}
              error={iecCategoryError}
              hasTitle={false}
              height={CHART_HEIGHT}
              hideBorder
              isLoading={isIecLoading}
            />
          </AssetProvider>
        );

      default:
        return null;
    }
  };

  return (
    <StyledTabDetailContainer>
      <DateFilterContainer>
        <DashboardDateFilter
          noCustom
          noPreview
          timezone={site?.timezone}
          isDatePersist={isDatePersist}
          onChange={!isDatePersist ? onDateFilterChange : undefined}
          popsout={popsout}
          isPanelDateSelector
        />
      </DateFilterContainer>
      <DashboardHeaderContainer>
        <DashboardStatsHeader data={kpiData} />
      </DashboardHeaderContainer>
      <DashboardChartContainer>
        <div className="chart-header">
          <StyledHeader>{translations.performanceTitle}</StyledHeader>
          <Select
            className="chart-select"
            onChange={handleSelectChange}
            options={selectOptions}
            value={selectOptions.find(({ value }) => value === type)}
          />
        </div>
        {renderChart()}
      </DashboardChartContainer>
    </StyledTabDetailContainer>
  );
};

AssetPerformance.propTypes = {
  assetId: PropTypes.string.isRequired,
  isDatePersist: PropTypes.bool,
  popsout: PropTypes.bool,
};
