import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DotsLoader } from '@ge/components/loader';
//Commented for AHR US496455-Remove Linked count in PI-2
//import { Icon, Icons } from '@ge/components/icon';
import { DateTimeFormats, EntityType, Placeholders } from '@ge/models/constants';
import { typography } from '@ge/tokens';

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.entityDetails.cases.details.issueHeaderBackground};
  padding: 15px;
  display: flex;
  margin-top: 2px;
  border-bottom: solid 1px ${({ theme }) => theme.entityDetails.cases.details.rowBorder};
  margin-bottom: 18px;
  section {
    display: flex;
    &:last-child {
      margin-right: 0;
      flex: 1;
      text-align: right;
      justify-content: flex-end;
      > div {
        text-align: right;
        margin: 0;
        padding: 0;
      }
    }
  }
  > section > div {
    border-right-color: ${({ theme }) => theme.entityDetails.cases.details.rowBorder};

    &:last-child {
      border: 0;
      margin-right: 90px;
      text-align: left;
    }
  }
`;

const OverviewBlock = styled.div`
  color: ${({ theme }) => theme.entityDetails.cases.details.dataTitle};
  line-height: 15px;
  padding-right: 20px;
  margin-right: 20px;
  border-right: solid 1px ${({ theme }) => theme.entityDetails.cases.details.columnSeparator};
  div {
    color: ${({ theme }) => theme.entityDetails.cases.details.dataDefinition};
    font-weight: ${typography.weight.medium};
    font-size: 14px;
  }
`;

const StyledCreateDate = styled.span`
  span {
    color: ${({ theme }) => theme.entityDetails.cases.details.dataTitle};
    &:before {
      content: ',';
      margin-right: 10px;
    }
  }
`;

const ComponentDiv = styled.div`
  text-transform: capitalize;
`;

// Commented for AHR US496455-Remove Linked count in PI-2
/* const RelatedBlock = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    align-items: center;
  }
`;

const MetricIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.entityDetails.headerIconColor,
}))`
  height: 15px;
  margin-right: 4px;
`;

const MetricInteger = styled.span`
  line-height: 15px;
  margin-right: 10px;
`; */

const TabDetailHeader = ({ entity, entityType, isLoading }) => {
  const { t } = useTranslation(['tasks', 'entity-details'], { useSuspense: false });

  const { createDate, component } = useMemo(() => {
    if (entityType === EntityType.CASE) {
      return {
        createDate: entity.createDate ? (
          <StyledCreateDate>
            {dayjs(entity.createDate).format(DateTimeFormats.DEFAULT_TIME)}
            <span>{dayjs(entity.createDate).format(DateTimeFormats.ISSUE_HEADER_DATE)}</span>
          </StyledCreateDate>
        ) : (
          Placeholders.DOUBLE_DASH
        ),
        component: entity.componentIds[0]
          ? [entity.componentIds[0], entity.componentIds[1], entity.componentIds[2]]
              .filter((c) => !!c)
              .join(' / ')
          : '',
      };
    } else {
      return {
        createDate: entity.createDate ? (
          <StyledCreateDate>
            {dayjs(entity.createDate)
              .tz(entity.site?.timezone)
              .format(DateTimeFormats.DEFAULT_TIME)}
            <span>
              {dayjs(entity.createDate)
                .tz(entity.site?.timezone)
                .format(DateTimeFormats.ISSUE_HEADER_DATE)}
            </span>
          </StyledCreateDate>
        ) : (
          Placeholders.DOUBLE_DASH
        ),
        component: entity.component1
          ? [entity.component1, entity.component2, entity.component3].filter((c) => !!c).join(' / ')
          : '',
      };
    }
  }, [
    entity.site,
    entity.createDate,
    entity.component1,
    entity.component2,
    entity.component3,
    entity.componentIds,
    entityType,
  ]);

  return (
    <HeaderContainer className="tab-detail-header">
      <section>
        <OverviewBlock>
          {t('entity-details:created', 'Created')}
          <div>{isLoading ? <DotsLoader /> : createDate}</div>
        </OverviewBlock>
        {(entityType === EntityType.CASE || entityType === EntityType.TASK) && (
          <OverviewBlock>
            {t('entity-details:source', 'Source')}
            <div>{t(`dynamic.types.${entity.source}`, entity.source)}</div>
          </OverviewBlock>
        )}
        <OverviewBlock>
          {t('entity-details:component', 'Component')}
          <ComponentDiv>{component}</ComponentDiv>
        </OverviewBlock>
      </section>
      <section>
        {
          // Commented for AHR US496455-Remove Linked count in PI-2
          /* {(entityType === EntityType.CASE || entityType === EntityType.TASK) && (
          <OverviewBlock>
            {t('linked', 'Linked')}
            <RelatedBlock>
              {entityType === EntityType.CASE && (
                <>
                  <MetricIcon icon={Icons.ANOMALY} />
                  <MetricInteger>{entity.childCases ? entity.childCases.length : 0}</MetricInteger>
                </>
              )}
              {entity.tasks?.filter((task) => task.state === 'Open').length ? (
                <>
                  <MetricIcon icon={Icons.TASK} />
                  <MetricInteger>
                    {entity.tasks?.filter((task) => task.state === 'Open').length}
                  </MetricInteger>
                </>
              ) : null}
            </RelatedBlock>
          </OverviewBlock>
        )} */
        }
      </section>
    </HeaderContainer>
  );
};

export default TabDetailHeader;

TabDetailHeader.propTypes = {
  isLoading: PropTypes.bool,
  entity: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string.isRequired,
};

TabDetailHeader.defaultValues = {
  isLoading: false,
};
