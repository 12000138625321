import { useMemo } from 'react';

import useAssetIecData from './use-asset-iec-data';
import useAssetKpiData from './use-asset-kpi-data';

/**
 * Use asset KPI Bar data.
 *
 * @param assetId the asset id
 * @param categories the kpi categories
 * @param iecCategories the iec category
 * @param timeAggr the time aggregate
 * @param startDate the start date
 * @param endDate the end date
 */
const useAssetKpiBarData = ({ assetId, categories, iecCategory, startDate, endDate }) => {
  const { data: assetKpiData, isLoading: isKpiDataLoading } = useAssetKpiData({
    categories,
    assetId,
    startDate,
    endDate,
  });

  const { data: iecCategoryData, isLoading: isIecDataLoading } = useAssetIecData({
    assetId,
    category: iecCategory,
    startDate,
    endDate,
  });

  const isLoading = isKpiDataLoading || isIecDataLoading;

  const data = useMemo(
    () =>
      isLoading
        ? undefined
        : {
            ...assetKpiData,
            [iecCategory]: {
              aggregateValue: iecCategoryData?.total,
              units: iecCategoryData?.unit,
            },
          },
    [assetKpiData, iecCategoryData, iecCategory, isLoading],
  );

  return { data, isLoading };
};

export default useAssetKpiBarData;
