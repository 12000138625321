import { number, select } from '@storybook/addon-knobs';
import React from 'react';
import styled from 'styled-components';

import { globalColors } from '@ge/tokens/colors';

import { Icons, Icon } from '../index';

const Box = styled.div`
  padding: 10px;
  margin: 10px;
  display: inline-block;
  height: 100px;
  width: 200px;
  text-align: center;
  border: solid 1px ${globalColors.grey6};
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.menu.textColor};
`;

const InnerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-flow: wrap;
`;

const StyledIcon = styled(Icon).attrs(({ color, theme }) => ({
  color: color ? color : theme.menu.textColor,
}))`
  flex: 1 100%;
`;

const IconName = styled.p`
  font-family: 'Museo Sans';
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  flex: 1 100%;
`;

export const AllIcons = ({ size, color }) => {
  const orderedIcons = Object.keys(Icons)
    .sort()
    .reduce((obj, key) => {
      obj[key] = Icons[key];
      return obj;
    }, {});

  const items = Object.entries(orderedIcons).map((icon, i) => {
    return (
      <Box key={i}>
        <InnerBox>
          <StyledIcon size={size} icon={icon[1]} color={color} />
          <IconName>{icon[0]}</IconName>
        </InnerBox>
      </Box>
    );
  });

  return <div>{items}</div>;
};
