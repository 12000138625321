import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { TooltipCell } from '@ge/components/table/table';
import { RoleTypes, Placeholders } from '@ge/models/constants';
import { RoleColor } from '@ge/tokens/colors';

const roleSeparator = ', ';

const StyledRoles = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTableRoleCell = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
`;

const PeopleTableRoleCell = ({ roles }) => {
  const [overflowActive, setOverflowActive] = useState(false);

  useEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, []);

  const ref = useRef();

  if (roles?.length === 0) return Placeholders.DASH;

  let firstRole = roles[0];

  if (roles[0].toLowerCase() === RoleTypes.GLOBAL)
    firstRole = <Badge key={roles[0]} color={RoleColor.GLOBAL} label={roles[0]} large />;
  else if (roles[0].toLowerCase() === RoleTypes.TENANT)
    firstRole = <Badge color={RoleColor.TENANT} label={roles[0]} large />;

  const roleDisplay = (
    <StyledRoles ref={ref}>
      {firstRole}
      {typeof firstRole === 'string' && roles.length > 1 ? roleSeparator : ''}{' '}
      {roles.slice(1).join(roleSeparator)}
    </StyledRoles>
  );

  const qtyBadge = <Badge color={RoleColor.QTY} label={roles.length} small />;

  if (overflowActive) {
    return (
      <TooltipCell tooltip={roles.join(roleSeparator)}>
        <StyledTableRoleCell>
          {roleDisplay}
          {qtyBadge}
        </StyledTableRoleCell>
      </TooltipCell>
    );
  }

  return <StyledTableRoleCell>{roleDisplay}</StyledTableRoleCell>;
};

PeopleTableRoleCell.propTypes = {
  roles: PropTypes.array,
};

export default PeopleTableRoleCell;
