import { AssetState, Placeholders } from '@ge/models/constants';
import { globalColors, StateColor } from '@ge/tokens/colors';

export const getTurbineStateType = (state) => {
  switch (state) {
    case AssetState.ONLINE:
      return 'O';
    case AssetState.AVAILABLE:
      return 'A';
    case AssetState.IMPACTED:
      return 'I';
    case AssetState.TRIPPED:
      return 'T';
    case AssetState.STOPPED:
      return 'S';
    case AssetState.NO_DATA:
      return 'ND';
    case AssetState.MAINTENANCE:
      return 'M';
    case AssetState.REPAIR:
      return 'R';
    case AssetState.NET_COMM:
      return 'NC';
    default:
      return Placeholders.DASH;
  }
};

export const getTurbineStateColor = (state) => {
  switch (state) {
    case AssetState.ONLINE:
      return StateColor.ONLINE;
    case AssetState.AVAILABLE:
      return StateColor.AVAILABLE;
    case AssetState.IMPACTED:
      return StateColor.IMPACTED;
    case AssetState.TRIPPED:
      return StateColor.TRIPPED;
    case AssetState.STOPPED:
      return StateColor.STOPPED;
    case AssetState.NO_DATA:
    case AssetState.NET_COMM:
      return StateColor.NO_DATA;
    case AssetState.MAINTENANCE:
      return StateColor.MAINTENANCE;
    case AssetState.REPAIR:
      return StateColor.REPAIR;
    default:
      return globalColors.stone1;
  }
};

export const getSeverityColor = (state) => {
  switch (state) {
    case 5:
      return globalColors.red2;
    case 4:
      return globalColors.red1;
    case 3:
      return globalColors.orange1;
    case 2:
      return globalColors.straw5;
    case 1:
      return globalColors.green2;
    case 0:
      return globalColors.green1;
    default:
      return globalColors.stone1;
  }
};
