import { useStoreState } from 'easy-peasy';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { ScrollingContainer } from '@ge/components/scrolling-container';
import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { CalendarRanges } from '@ge/models/constants';
import { WarningMessage } from '@ge/shared/components/warning-message';
import { elevations } from '@ge/tokens/elevations';

import { useTaskSchedule } from '../../data-hooks/use-task-schedule';

import { CalendarGroupFilter } from './calendar-group-filter';
import { CalendarHeader } from './calendar-header';
import { CalendarSummary } from './calendar-summary';
import { CalendarTimeline } from './calendar-timeline';
import { CalendarDayView } from './day/calendar-day-view';
import { DispatchContainer } from './day/calendar-dispatch-view';
import { CalendarMonthView } from './month/calendar-month-view';
import { CalendarQuarterView } from './quarter/calendar-quarter-view';
import { CalendarWeekView } from './week/calendar-week-view';
// Hiding weather due to design changes to be revisited in the future
// import { CalendarDayWeather } from './calendar-day-weather';

const Container = styled.div`
  height: 100%;
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  background: ${({ theme }) => theme.manage.calendar.backgroundColor};
  flex: 1;
  display: flex;
`;

const LanesContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: ${elevations.P3};
  display: flex;
`;

const ScrollingHeader = styled.div`
  width: calc(100% - 200px);
  z-index: ${elevations.P2};
`;

const CaseTaskContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Calendar = () => {
  const {
    planningState: { scrollRef, range, hour, groupFilter },
  } = usePlanningContext();

  const pagination = useStoreState((store) => store.tasks.taskPage);

  useEffect(() => {
    if (scrollRef?.current && range.type === CalendarRanges.DAY) {
      const scrollEl = scrollRef.current;
      scrollEl.scrollLeft = hour * 6;
    }
  }, [scrollRef, hour, range]);

  const { data, refetch, isError, isFetching, isLoading } = useTaskSchedule({
    start: range.start,
    end: range.end,
    groupByL1: groupFilter.L1.value,
    groupByL2: groupFilter.L2.value,
    groupByFilters: groupFilter.filters,
  });

  const CalendarView = useMemo(() => {
    switch (range.type) {
      case CalendarRanges.DAY:
        return CalendarDayView;
      case CalendarRanges.MONTH:
        return CalendarMonthView;
      case CalendarRanges.WEEK:
        return CalendarWeekView;
      case CalendarRanges.QUARTER:
        return CalendarQuarterView;
      default:
        return React.Fragment;
    }
  }, [range]);

  return (
    <Container>
      <CalendarHeader />
      <CalendarSummary
        scheduled={data.counts?.scheduled}
        dueUnScheduled={data.counts?.unscheduled}
        completed={data.counts?.completed}
      />
      <CalendarContainer>
        <LanesContainer>
          <ScrollingContainer ref={scrollRef}>
            <StickyHeader>
              <ScrollingHeader>
                {/* Hiding weather due to design changes to be revisited in the future */}
                {/* {range.type === CalendarRanges.DAY && <CalendarDayWeather hour={hour} />} */}

                {/* TODO: add group by form */}
                <CalendarTimeline
                  filter={<CalendarGroupFilter filters={data.filters} />}
                  monthCounts={data.counts?.byWeek}
                  weekCounts={data.counts?.byDays}
                  /* in future can be updated as per requirement for quarterly view
                  quarterCounts={data?.counts?.byWeek} */
                />
              </ScrollingHeader>
            </StickyHeader>
            <CaseTaskContainer>
              {pagination?.isMax && !isFetching ? (
                <WarningMessage />
              ) : (
                <CalendarView
                  unassignedData={data.unassigned}
                  scheduleData={data.schedule}
                  refetch={refetch}
                  isLoading={isLoading}
                  isError={isError}
                  parentRef={scrollRef}
                />
              )}
            </CaseTaskContainer>
          </ScrollingContainer>
        </LanesContainer>
      </CalendarContainer>
      <DispatchContainer />
    </Container>
  );
};
