import { AssetStatus } from '@ge/models/constants';

import { globalColors } from '../colors';

export const chartColors = {
  level1: [
    globalColors.mint3,
    globalColors.magenta3,
    globalColors.terracotta3,
    globalColors.pine3,
    globalColors.blue3,
    globalColors.violet3,
    globalColors.straw3,
    globalColors.lavender3,
    globalColors.stone3,
    globalColors.mint1,
    globalColors.magenta1,
    globalColors.terracotta1,
    globalColors.pine1,
    globalColors.blue1,
    globalColors.violet1,
    globalColors.straw1,
    globalColors.lavender1,
    globalColors.stone1,
  ],
  level2: [
    [
      globalColors.mint3,
      globalColors.mint1,
      globalColors.mint4,
      globalColors.mint2,
      globalColors.mint5,
    ],
    [
      globalColors.magenta3,
      globalColors.magenta1,
      globalColors.magenta4,
      globalColors.magenta2,
      globalColors.magenta5,
    ],
    [
      globalColors.terracotta3,
      globalColors.terracotta1,
      globalColors.terracotta4,
      globalColors.terracotta2,
      globalColors.terracotta5,
    ],
    [
      globalColors.pine3,
      globalColors.pine1,
      globalColors.pine4,
      globalColors.pine2,
      globalColors.pine5,
    ],
    [
      globalColors.blue3,
      globalColors.blue6,
      globalColors.blue4,
      globalColors.blue2,
      globalColors.blue5,
      globalColors.blue1,
    ],
    [
      globalColors.violet3,
      globalColors.violet1,
      globalColors.violet4,
      globalColors.violet2,
      globalColors.violet5,
    ],
    [
      globalColors.straw3,
      globalColors.straw1,
      globalColors.straw4,
      globalColors.straw2,
      globalColors.straw5,
    ],
    [
      globalColors.lavender3,
      globalColors.lavender1,
      globalColors.lavender4,
      globalColors.lavender2,
      globalColors.lavender5,
    ],
    [
      globalColors.stone3,
      globalColors.stone1,
      globalColors.stone4,
      globalColors.stone2,
      globalColors.stone5,
    ],
  ],
};

export const statusChartColors = {
  [AssetStatus.TRIPPED]: globalColors.red2,
  [AssetStatus.STOPPED]: globalColors.straw3,
  [AssetStatus.IMPACTED]: globalColors.violet4,
  [AssetStatus.REPAIR]: globalColors.magenta2,
  [AssetStatus.MAINTENANCE]: globalColors.magenta1,
  [AssetStatus.NET_COMM]: globalColors.stone2,
  [AssetStatus.ONLINE]: globalColors.pine1,
  [AssetStatus.AVAILABLE]: globalColors.blue3,
};

export const getL2ChartColors = (index) => {
  let colors = [...chartColors.level2];

  if (index > 0) {
    const first = colors.slice(0, index - 1);
    const second = colors.slice(index);
    colors = [...second, ...first];
  }

  return colors.flat(2);
};
