import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import flatten from 'ramda/src/flatten';
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Icon, Icons } from '@ge/components/icon';
import { ConfirmationDialog } from '@ge/components/modal';
import { Select } from '@ge/components/select';
import { MonitorContext } from '@ge/feat-monitor/context/monitor-context';
import {
  FormMode,
  EntityType,
  NotesCategory,
  NotesPageSize,
  NoteSelectOptions,
  Capability,
  PermissionScope,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useAuth } from '@ge/shared/data-hooks';
import { isBeforeToday } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';

import {
  useAddNote,
  useEditNote,
  useNotesPaginationDetails,
  useDeleteNote,
} from '../../../hooks/use-notes-details';
import { TabDetailContainer } from '../entity-details-shared';

import { AddNoteDialog } from './notes-add-note-dialog';
import { NotesAttachmentsTable } from './notes-attachments-table';
import { SpecialInstructionDialog } from './special-instructions-dialog';

const NotesContainer = styled.div`
  margin-top: 20px;
  padding: 2px 40px 40px 20px;
  position: relative;
  &.is-loading {
    margin-bottom: 50px;
  }
  .show-more {
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }
`;

const NotesEmpty = styled.div`
  margin-left: 150px;
  margin-top: 50px;
  margin-bottom: 100px;
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  p {
    font: 300 12px/15px;
    margin: 0;
    padding-bottom: 25px;
  }
`;

const AddNoteButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  line-height: 13px;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  width: 115px;
  position: absolute;
  left: 5px;
  top: 5px;
`;

const AddSIButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  line-height: 13px;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  width: 115px;
  position: absolute;
  left: 5px;
  top: 30px;
`;

const AddNoteIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const FilterNotes = styled(Select)`
  position: absolute;
  left: 135px;
  top: 5px;
  margin-right: 6px;
  min-width: 140px;
  .select__indicators {
    padding-right: 0px !important;
  }
`;

const sortByDate = (a, b) => dayjs(b.updateDate) - dayjs(a.updateDate);

export const NotesAttachments = ({ entity, entityType, siteType }) => {
  const { t, ready } = useTranslation(['entity-details']);

  const { selectedNoteType, setSelectedNoteType } = useContext(MonitorContext);

  const notesSelectOptions = useMemo(() => {
    return [
      // { label: t('notes.all'), value: NoteSelectOptions.ALL },
      {
        label:
          entityType === EntityType.SITE
            ? t('notes.site_notes', 'Site Notes')
            : t('notes.asset_notes', 'Asset Notes'),
        value: NoteSelectOptions.NOTES,
      },
      {
        label: t('notes.active_special_instruction', 'Active Special Instruction'),
        value: NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION,
      },
      {
        label: t('notes.inactive_special_instruction', 'Inactive Special Instruction'),
        value: NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION,
      },
    ];
  }, [t, entityType]);

  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [notesAttachments, setNotesAttachments] = useState([]);
  const [selectedNotesType, setSelectedNotesType] = useState(notesSelectOptions[selectedNoteType]);
  const [pageIndex, setPageIndex] = useState(0);
  const [showAddSpecialInstruction, setShowAddSpecialInstruction] = useState(false);
  const [noteAndSIData, setNoteAndSIData] = useState({});
  const [mode, setMode] = useState('');
  const { getAssetById } = useStoreState((store) => store.assets);
  const [entityDetails, setEntityDetails] = useState(entity);
  const [showMore, setShowMore] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);

  const { add, isLoading: isAddNoteLoading } = useAddNote({
    assetType: entityType,
    id: entity?.id,
  });

  const { edit, isLoading: isEditNoteLoading } = useEditNote({
    assetType: entityType,
    id: entity?.id,
  });

  const { del, isLoading: isDeleteNoteLoading } = useDeleteNote({
    assetType: entityType,
    id: entity?.id,
  });

  //Fetch notes and SI based on user capability
  const { isAuthorized } = useAuth();
  const authorizedSI = isAuthorized({
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] }],
    siteIds: [entityType === EntityType.SITE && entity?.id],
  });
  const {
    data: notes,
    isFetching: isGetNotesLoading,
    fetchNextPage,
  } = useNotesPaginationDetails({
    assetType: entityType,
    id: entity?.id,
    notesType: (!authorizedSI && NoteSelectOptions.NOTES) || selectedNotesType.value,
    pageSize: NotesPageSize,
  });

  useEffect(() => {
    if (entityType === EntityType.ASSET && entity?.id) {
      setEntityDetails(getAssetById(entity.id));
    }
  }, [setEntityDetails, getAssetById, entity, entityType]);

  useEffect(() => {
    return () => setSelectedNoteType(0);
  }, [setSelectedNoteType]);

  useEffect(() => {
    if (isGetNotesLoading || !notes) return;
    setNotesAttachments(flatten(notes?.pages.map((page) => page.notes)).sort(sortByDate));
    const lastPage = notes?.pages?.length ? notes.pages[notes.pages.length - 1] : null;
    lastPage &&
      setShowMore(
        lastPage?.pagination?.totalRecords >
          NotesPageSize * (lastPage?.pagination?.currentPage + 1),
      );
  }, [notes, isGetNotesLoading]);

  const handleNote = useCallback(
    (modeType, data) => {
      setMode(modeType);
      if (modeType === FormMode.EDIT) {
        setNoteAndSIData(data);
      }
      setShowAddNoteModal(true);
    },
    [setShowAddNoteModal],
  );

  const handleSpecialInstruction = useCallback(
    (modeType, data) => {
      setMode(modeType);
      if (modeType === FormMode.EDIT) {
        setNoteAndSIData(data);
      }
      setShowAddSpecialInstruction(true);
    },
    [setShowAddSpecialInstruction],
  );

  const addNoteAndSI = (values, category) => {
    values = { ...values, entity, category, entityType };
    add(values);
    setShowAddNoteModal(false);
    setShowAddSpecialInstruction(false);
  };

  const handleToggleHandler = (action, category, response) => {
    const originalData = Object.assign({}, response);
    originalData.scope = action;
    originalData.attachments = [];
    editNoteAndSI(originalData, category, response.id, response);
  };

  const editNoteAndSI = (values, category, noteId, originalData) => {
    values = { ...values, entity, category, noteId, entityType };
    if (category === NotesCategory.SPECIAL_INSTRUCTION && values.validDateBy) {
      if (isBeforeToday(values.validDateBy)) {
        delete values.validDateBy;
      }
    }
    edit({ inputData: values, originalData: originalData ?? noteAndSIData });
    setShowAddNoteModal(false);
    setShowAddSpecialInstruction(false);
  };

  const deleteNoteOrSI = (values) => {
    setShowDeleteConfirmationDialog(true);
    setDataToDelete(values);
  };

  const handleDeleteConfirm = useCallback(() => {
    if (dataToDelete) {
      const values = { ...dataToDelete, entityType };
      del(values);
      setShowDeleteConfirmationDialog(false);
      setDataToDelete(null);
    }
  }, [dataToDelete, entityType, del]);

  const handleDeleteCancel = () => {
    setShowDeleteConfirmationDialog(false);
    setDataToDelete(null);
  };

  const changeNotesType = (option) => {
    if (option.value && option.value !== selectedNotesType.value) {
      setPageIndex(0);
      setNotesAttachments([]);
      setSelectedNotesType(option);
    }
  };

  const fetchNextData = () => {
    setPageIndex((cur) => cur + 1);
    fetchNextPage({
      pageParam: pageIndex + 1,
    });
  };

  const isLoading =
    isAddNoteLoading || isEditNoteLoading || isGetNotesLoading || isDeleteNoteLoading;

  // #TODO: handle errors from backend

  if (!ready) {
    return null;
  }

  return (
    <TabDetailContainer>
      <NotesContainer className={isLoading ? 'is-loading' : ''}>
        <AuthRender
          capability={Capability.NOTES_AND_ATTACHMENTS}
          create
          description="Notes attachments add note/SI button"
          siteLevel={false}
        >
          <AddNoteButton onClick={() => handleNote(FormMode.CREATE)}>
            <AddNoteIcon />
            {t('notes.add_note_button', 'Add Note')}
          </AddNoteButton>
        </AuthRender>
        <AuthRender
          capability={Capability.SPECIAL_INSTRUCTION}
          create
          description="Notes attachments add note/SI button"
          siteLevel={false}
        >
          <AddSIButton onClick={() => handleSpecialInstruction(FormMode.CREATE)}>
            <AddNoteIcon />
            {t('notes.add_special_instruction', 'Add Special Instructions')}
          </AddSIButton>
        </AuthRender>
        <AuthRender
          capability={Capability.SPECIAL_INSTRUCTION}
          view
          description="Notes attachments add note/SI button"
          siteLevel={false}
        >
          <FilterNotes
            small
            maxWidth={180}
            options={notesSelectOptions}
            value={selectedNotesType}
            onChange={(value) => {
              changeNotesType(value);
            }}
          />
        </AuthRender>
        {!isGetNotesLoading && !notesAttachments.length ? (
          <NotesEmpty>
            <p>{t('notes.no_notes', 'No information added.')}</p>
          </NotesEmpty>
        ) : (
          <DataLoader isLoading={isLoading} type="table">
            <NotesAttachmentsTable
              notesAttachments={
                (entityType === EntityType.SITE &&
                  notesAttachments.map((element) => {
                    return {
                      ...element,
                      site: { id: element?.domainId },
                    };
                  })) ||
                notesAttachments
              }
              editNoteAction={(data) =>
                data.category === NotesCategory.NOTE
                  ? handleNote(FormMode.EDIT, data)
                  : handleSpecialInstruction(FormMode.EDIT, data)
              }
              toggleHandler={(action, data) => {
                handleToggleHandler(
                  action,
                  data.category === NotesCategory.NOTE
                    ? NotesCategory.NOTE
                    : NotesCategory.SPECIAL_INSTRUCTION,
                  data,
                );
              }}
              editNoteAndSI={(data, originalData) => {
                editNoteAndSI(
                  data,
                  data.category === NotesCategory.NOTE
                    ? NotesCategory.NOTE
                    : NotesCategory.SPECIAL_INSTRUCTION,
                  data.id,
                  originalData,
                );
              }}
              deleteNoteAction={(data) => {
                deleteNoteOrSI(data);
              }}
            />
            {showMore && (
              <span className="show-more" onClick={fetchNextData}>
                {t('notes.show_more', 'Show More')}
              </span>
            )}
          </DataLoader>
        )}
      </NotesContainer>
      <AddNoteDialog
        isOpen={showAddNoteModal}
        onClose={() => setShowAddNoteModal(false)}
        onConfirm={(values) =>
          mode === FormMode.EDIT
            ? editNoteAndSI(values, NotesCategory.NOTE, noteAndSIData.id)
            : addNoteAndSI(values, NotesCategory.NOTE)
        }
        entity={entityDetails}
        entityType={entityType}
        data={noteAndSIData}
        mode={mode}
        siteType={siteType}
      />

      <SpecialInstructionDialog
        isOpen={showAddSpecialInstruction}
        onConfirm={(values) =>
          mode === FormMode.EDIT
            ? editNoteAndSI(values, NotesCategory.SPECIAL_INSTRUCTION, noteAndSIData.id)
            : addNoteAndSI(values, NotesCategory.SPECIAL_INSTRUCTION)
        }
        onClose={() => setShowAddSpecialInstruction(false)}
        entity={entityDetails}
        entityType={entityType}
        data={noteAndSIData}
        mode={mode}
        siteType={siteType}
      />
      <ConfirmationDialog
        contentWidth
        header={t('confirm_delete_header', 'Confirmation')}
        isOpen={showDeleteConfirmationDialog}
        padContent={true}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        confirmLabel={t('confirm_delete', 'Delete')}
        cancelLabel={t('confirm_delete_cancel', 'Cancel')}
      >
        <p>{t('confirm_delete_message', 'Are you sure?')}</p>
      </ConfirmationDialog>
    </TabDetailContainer>
  );
};

NotesAttachments.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
  siteType: PropTypes.string,
};

export default NotesAttachments;
