import { useMemo } from 'react';

import { getBundleInfo } from '@ge/feat-manage/util/bundle-util';

export const useCalendarCardDetails = ({ tasks, date }) => {
  return useMemo(() => {
    if (!tasks?.length) return { bundleInfo: {} };

    const bundleInfo = getBundleInfo(tasks, date);
    const sameAssetTasks = tasks.length == bundleInfo.sameAssetTaskCount ? true : false;
    const partialCompleted =
      bundleInfo.completedTasks != 0 && tasks.length !== bundleInfo.completedTasks ? true : false;

    return { bundleInfo, sameAssetTasks, partialCompleted };
  }, [tasks, date]);
};
