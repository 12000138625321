import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

export const inertClass = 'dismiss-inert';

const StyledDetailsDismissInert = styled.div`
  height: 100%;
  width: 100%;
`;

// Wrapper component that marks elements as inert so clicks off of the
// details panel will not trigger a dismissal.
export const DetailsDismissInert = forwardRef(({ children, className }, ref) => (
  <StyledDetailsDismissInert className={`${inertClass} ${className}`} ref={ref}>
    {children}
  </StyledDetailsDismissInert>
));

DetailsDismissInert.displayName = 'DetailsDismissInert';

DetailsDismissInert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
