import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PartsField } from '@ge/models/constants';

import { Parts } from '../../../task-fields/parts';
import { ColumnTitle, ColumnDetail, FieldGroup } from '../../../task-template-shared';

export const EditPartsConsumed = ({ metadata }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.parts_consumed', 'Parts Consumed')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup className="parts-consumed">
          {!metadata?.partsConsumed.hidden && (
            <Parts metadata={metadata.partsConsumed.edit} fieldName={PartsField.PARTS_CONSUMED} />
          )}
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

EditPartsConsumed.propTypes = {
  metadata: PropTypes.object.isRequired,
};
