import { EntityType } from '@ge/models/constants';

export const getParentEntityUrl = (type, id) => {
  switch (type) {
    case EntityType.SITE:
      return `/analyze/region/${id}`;
    case EntityType.SITES:
      return '/analyze/sites';
    case EntityType.TURBINE:
    case EntityType.ASSET:
      return `/analyze/site/${id}`;
    default:
      return '/analyze/fleet-overview/region';
  }
};

export const getParentId = (type, entity) => {
  switch (type) {
    case EntityType.SITE:
      return entity?.region?.id;
    case EntityType.TURBINE:
    case EntityType.ASSET:
      return entity?.site?.id;
    case EntityType.REGION:
      return EntityType.FLEET;
    default:
      return null;
  }
};

export const siteTypes = {
  solar: 'solar',
  storage: 'storage',
  wind: 'wind',
};
