import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CommandsController } from '@ge/components/controllers/commands-controller';
import { Icon, Icons } from '@ge/components/icon';
import { Menu, placements } from '@ge/components/menu';
import { Text } from '@ge/components/typography';
import { AssetCommandStatus, EntityTab, Feature, commands } from '@ge/models/constants';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { EntityDetailsContext, useAppNotificationContext } from '@ge/shared/context';
import { useAssetCommands } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens/typography';

const StyledCommandsMenu = styled.div`
  font-size: 11px;
  text-align: left;
  display: inline;
  position: relative;
  color: currentColor;
`;

const MenuIcon = styled(Icon).attrs((props) => ({
  size: 16,
  icon: Icons.CONTROLS,
  color: props.theme.table.arrowColor,
}))`
  path {
    transform: translate(4px, 1px);
  }
`;

const CommandControlsWrapper = styled.div`
  .asset-controls {
    padding: 3px;
    max-width: 150px;

    div:first-of-type button {
      &:hover {
        border-radius: 2px 2px 0 0;
      }
    }

    div:last-of-type button {
      border: 0;

      &:hover {
        border-radius: 0 0 2px 2px;
      }
    }
  }
`;

const MenuTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 4,
}))`
  padding: 8px 10px 4px 8px;
  display: block;
  color: currentColor;
`;

const MenuButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const CommandsDisabledText = styled(Text).attrs(() => ({
  level: 2,
  type: typography.textTypes.body,
}))`
  color: ${(props) => props.theme.dangerColor};
  margin: 10px;
  display: block;
`;

export const CommandsMenu = ({ assets, containerRef, disabled, onExecute }) => {
  const { t } = useTranslation(['entity-details']);
  const [anchorEl, setAnchorEl] = useState(null);
  const { showAssetDetails, showSiteDetails } = useContext(EntityDetailsContext);
  const notifications = useAppNotificationContext();

  // TODO: show error banner when execute fails (check for error.execute)
  const { data, error, isLoading } = useAssetCommands({
    assets: assets,
    isActive: Boolean(anchorEl),
  });

  const { executionContext, isExecuting, reset, inProgressLimitReached } = data;
  const { notify } = notifications[Feature.MONITOR]?.commands ?? {};

  const isMultiCommand = executionContext?.assets?.length > 1;

  // handles notifications for multi commands, waiting for dialog to be closed before notifying
  useEffect(() => {
    if (
      anchorEl ||
      !(
        executionContext?.command &&
        isExecuting &&
        isMultiCommand &&
        notify &&
        executionContext?.statusId
      )
    ) {
      return;
    }

    notify({
      ...executionContext,
      onAssetDetails: (id) => showAssetDetails(id, EntityTab.REALTIME_INFO),
      onSiteDetails: (id) => showSiteDetails(id, EntityTab.REALTIME_INFO),
    });
  }, [
    anchorEl,
    executionContext,
    isExecuting,
    isMultiCommand,
    notify,
    showAssetDetails,
    showSiteDetails,
  ]);

  const showMenu = (e) => {
    e.stopPropagation();
    if (anchorEl === null) {
      reset();

      setAnchorEl(e.currentTarget);
    } else {
      handleMenuClose();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (executionContext?.statusId) onExecute();
  };

  // handles notifications for a single command
  const handleExecute = (context) => {
    const isSingleCommand = context?.assets?.length === 1;

    if (!isSingleCommand) {
      return;
    }

    notify({
      ...context,
      onAssetDetails: (id) => showAssetDetails(id, EntityTab.REALTIME_INFO),
      onSiteDetails: (id) => showSiteDetails(id, EntityTab.REALTIME_INFO),
    });
  };

  if (!assets) return null;

  return (
    <StyledCommandsMenu onClick={(e) => killEventPropagation(e)}>
      <MenuButton
        onClick={(e) => showMenu(e, 'commands')}
        disabled={disabled || error?.commands?.message.trim().length}
      >
        <MenuIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        width={150}
        placement={placements.BOTTOM_END}
        fixed
      >
        <DetailsDismissInert>
          {error?.commands?.message.trim().length ? (
            <>
              <MenuTitle>
                <b>{t('command_menu.commands_error', 'Cannot process commands')}</b>
              </MenuTitle>
              <CommandsDisabledText>{error?.commands?.message}</CommandsDisabledText>
            </>
          ) : (
            <MenuTitle>{t('command_menu.asset_count', { count: assets.length })}</MenuTitle>
          )}
          <CommandControlsWrapper>
            <CommandsController
              assets={assets}
              commandsData={data}
              disabled={Boolean(
                error?.commands ||
                  assets?.length > commands.MAX_GROUP_COMMANDS ||
                  isLoading ||
                  inProgressLimitReached ||
                  (data?.status?.length === 1 &&
                    data.status[0].status === AssetCommandStatus.BLOCKED),
              )}
              onClose={handleMenuClose}
              onExecute={handleExecute}
            />
          </CommandControlsWrapper>
          {(assets.length > commands.MAX_GROUP_COMMANDS && (
            <CommandsDisabledText>
              {t('general:disabled', 'Disabled')}: {t('command_menu.limit_exceed')}
            </CommandsDisabledText>
          )) ||
            (data.status.length === 1 && data?.status[0].reason && (
              <CommandsDisabledText>
                {t('general:disabled', 'Disabled')}: {data?.status[0].reason}
              </CommandsDisabledText>
            )) ||
            (inProgressLimitReached && (
              <CommandsDisabledText>
                {t('general:disabled', 'Disabled')}: {t('command_menu.max_inprogress_limit')}
              </CommandsDisabledText>
            ))}
        </DetailsDismissInert>
      </Menu>
    </StyledCommandsMenu>
  );
};

CommandsMenu.propTypes = {
  assets: PropTypes.instanceOf(Array).isRequired,
  containerRef: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  onExecute: PropTypes.func,
};

CommandsMenu.defaultProps = {
  containerRef: null,
  disabled: false,
  onExecute: () => {},
};
