import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@ge/components/loader';
import { useColumnState } from '@ge/components/table/use-column-state';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import GlobalAdminActionsHeader from '../components/global-admin-actions-header';
import RolesTable from '../components/tables/roles-table';
import { useRolesTable } from '../data-hooks/use-roles-table';
import { globalRolesCols, RolesColumnDefs } from '../models/roles-table-cols';

const SORT_STATE_ID = 'admin-roles';

/**
 * Available sort metrics for Roles.
 */
export const RolesMetric = {
  ROLE: 'role',
  TENANT: 'tenant.id',
};

const AdminRoles = () => {
  const { t } = useTranslation(['admin.roles', 'general'], { useSuspense: false });

  const [currentSearch, setCurrentSearch] = useState({});
  const [filterDefs, setFilterDefs] = useState({});

  const { showRoleDetails } = useContext(EntityDetailsContext);
  const { getUserRoleBasedEntityAttributes } = useStoreActions((actions) => actions.userRole);
  const { limitedUserRoles } = useStoreState((state) => state.userRole);

  const { sortDirection, sortMetric, updateSortMetric } = useColumnState({
    columnDefs: RolesColumnDefs,
    defaultCols: globalRolesCols,
    sortStateId: SORT_STATE_ID,
  });

  useEffect(() => {
    if (!limitedUserRoles) {
      getUserRoleBasedEntityAttributes();
    }
  }, [getUserRoleBasedEntityAttributes, limitedUserRoles]);

  const handleRoleSelect = useCallback(
    (_, role) => {
      showRoleDetails(role.id);
    },
    [showRoleDetails],
  );

  const { data: { data: roles = [], filterValues } = {}, isRolesLoading } = useRolesTable({
    filters: filterDefs,
    search: currentSearch,
    sortMetric,
    sortDirection,
  });

  const handleFilter = useCallback(
    (updateDefs) => {
      setFilterDefs(updateDefs);
    },
    [setFilterDefs],
  );

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  return (
    <>
      <GlobalAdminActionsHeader />
      <h1 className="pageTitle">{t('roles', 'Roles')}</h1>
      {isRolesLoading ? (
        <Loader />
      ) : (
        <RolesTable
          columns={globalRolesCols}
          roles={roles}
          sortAction={updateSortMetric}
          sortMetric={sortMetric}
          sortDirection={sortDirection}
          onFilter={handleFilter}
          onFilterChange={handleFilterChange}
          filterValues={filterValues}
          onRoleSelect={handleRoleSelect}
        />
      )}
    </>
  );
};

export default AdminRoles;
