import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { FormMode, AlertsEntityType, Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { SeeMore } from '@ge/shared/components/see-more';
import { getFriendlyTimestamp } from '@ge/shared/util';

// TODO: update task dialog theme to be generic to other templates?

const Header = styled.div`
  align-items: start;
  border-bottom: 1px solid ${({ theme }) => theme?.newTaskDialog?.sectionBorder};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 20px 25px 16px;

  .entity-details {
    flex: 1;
    .name {
      margin-left: 4px;
    }

    .entity-name {
      &:first-of-type {
        margin-right: 4px;
      }

      &:last-of-type {
        margin-left: 4px;
      }
    }
  }

  .subtitle {
    color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
    font-size: 12px;
    font-weight: 300;
  }

  .timestamp {
    color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
    margin-left: auto;
    text-align: right;
  }

  .timestamp-edit {
    align-items: flex-end;
    color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin-left: auto;

    button {
      letter-spacing: 0;
      line-height: 0;
      margin-bottom: 5px;
      padding: 0;
    }
  }
`;

const EditIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme?.newTaskDialog?.headerSubtitleColor,
  icon: Icons.PENCIL,
  size: 12,
}))``;

const EntityIcon = styled(Icon).attrs(({ icon, theme }) => ({
  color: theme?.newTaskDialog?.headerSubtitleColor,
  icon,
  size: 12,
}))``;

const EntityInfo = styled.div`
  color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
  margin-top: 5px;
  height: 15px;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
`;

const AssociatedAssetsInfo = styled.div`
  color: ${({ theme }) => theme?.alertDialog?.viewValueTextColor};
  margin-top: 5px;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  > span {
    color: ${({ theme }) => theme?.newTaskDialog?.headerSubtitleColor};
  }
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))`
  margin-left: 7px;
`;

const TimestampContainer = ({ mode, onEdit, startTime, siteTimezone, isClosedAlert }) => {
  // if we need to allow providing a specific time can get in props and pass in here
  const timestamp = getFriendlyTimestamp(startTime, siteTimezone);
  const [time, date] = timestamp.split(', ');

  const defaultView = (
    <div className="timestamp">
      <div>
        {date} {time}
      </div>
    </div>
  );

  if (mode !== FormMode.VIEW) {
    return defaultView;
  }

  return (
    <div className="timestamp-edit">
      <AuthRender
        capability={Capability.ALERTS}
        edit
        description="Alert header pencil edit button"
        siteLevel={false}
      >
        {!isClosedAlert && (
          <button onClick={onEdit}>
            <EditIcon />
          </button>
        )}
      </AuthRender>
      <div>
        {date} {time}
      </div>
    </div>
  );
};

TimestampContainer.propTypes = {
  mode: PropTypes.oneOf(Object.values(FormMode)),
  onEdit: PropTypes.func,
  startTime: PropTypes.string,
  siteTimezone: PropTypes.string,
  isClosedAlert: PropTypes.bool,
  // siteId: PropTypes.string.isRequired,
};

TimestampContainer.defaultProps = {
  mode: FormMode.VIEW,
  onEdit: () => {},
  isClosedAlert: false,
};

const IconsMapping = {
  [AlertsEntityType.SITE]: Icons.SITE,
  [AlertsEntityType.WIND_TURBINE]: Icons.TURBINE,
  [AlertsEntityType.STORAGE_SITE]: Icons.STORAGE,
  [AlertsEntityType.INVERTER]: Icons.STORAGE_INVERTER,
};

const EntityContainer = ({ name, type }) => {
  // TODO: add support for more types in here as needed
  const icon = IconsMapping[type];
  return (
    <span className="entity-name">
      <EntityIcon icon={icon} />
      <span className="name subtitle">{name}</span>
    </span>
  );
};

EntityContainer.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const getAssetIconFromEntityType = (type = AlertsEntityType.WIND_TURBINE) => {
  return IconsMapping[type];
};

const getSiteTypeFromEntityType = (type) => {
  switch (type) {
    case AlertsEntityType.WIND_TURBINE:
    case AlertsEntityType.SITE:
      return AlertsEntityType.SITE;
    case AlertsEntityType.INVERTER:
    case AlertsEntityType.STORAGE_SITE:
      return AlertsEntityType.STORAGE_SITE;
    default:
      return AlertsEntityType.SITE;
  }
};

// TODO: look at refactoring task dialog header and this into more reusable component
export const AlertHeader = ({
  entity,
  entityType,
  mode,
  onEdit,
  startTime,
  selectedAssets,
  isBulk,
  associatedAssetsName,
  isClosedAlert,
}) => {
  const { t } = useTranslation(['entity-details']);
  // store
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  const isSite =
    entityType === AlertsEntityType.SITE || entityType === AlertsEntityType.STORAGE_SITE;
  const site = isSite ? entity : getSiteById(entity?.site?.id);
  const assetsNames = useMemo(() => selectedAssets?.map((asset) => asset.name).join(', '), [
    selectedAssets,
  ]);

  // do we need to factor in other entity types like site controller here?
  const assetContainer = isSite ? null : (
    <>
      <span className="subtitle">/</span>
      {isBulk ? (
        <span className="entity-name">
          <EntityIcon icon={getAssetIconFromEntityType(entityType)} />
          <span className="name subtitle">
            {t('alert_dialog.assetsSelected', 'Asset(s) selected')}
            <StyledBadge label={selectedAssets?.length} small />
          </span>
        </span>
      ) : (
        <EntityContainer name={entity?.name} type={entityType} />
      )}
    </>
  );

  return (
    <Header>
      <div className="entity-details">
        <EntityContainer name={site?.name} type={getSiteTypeFromEntityType(entityType)} />
        {assetContainer}
        {isBulk ? (
          <EntityInfo>
            <SeeMore charLimit={35}>{assetsNames}</SeeMore>
          </EntityInfo>
        ) : associatedAssetsName && mode === FormMode.VIEW ? (
          <AssociatedAssetsInfo>
            <span>{t('alert_dialog.associatedAssets', 'Asset(s) associated with same alert')}</span>
            <SeeMore charLimit={35}>{associatedAssetsName}</SeeMore>
          </AssociatedAssetsInfo>
        ) : (
          ''
        )}
      </div>
      <TimestampContainer
        mode={mode}
        onEdit={onEdit}
        siteId={site?.id}
        startTime={startTime}
        siteTimezone={site?.timezone}
        isClosedAlert={isClosedAlert}
      />
    </Header>
  );
};

AlertHeader.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.oneOf(Object.values(AlertsEntityType)).isRequired,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  onEdit: PropTypes.func,
  selectedAssets: PropTypes.array,
  isBulk: PropTypes.bool,
  associatedAssetsName: PropTypes.string,
  startTime: PropTypes.string,
  isClosedAlert: PropTypes.bool,
};

AlertHeader.defaultProps = {
  mode: FormMode.VIEW,
  onEdit: () => {},
  isClosedAlert: false,
};
