import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '../../../task-template-shared';

export const ViewLaborEstimates = ({ task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const parsedDurationHrs = parseInt(task?.estDurationHours) || 0;
  const parsedDurationMins = parseInt(task?.estDurationMinutes) || 0;
  const parsedTechs = parseInt(task?.estTechs) || 0;

  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.labor_estimates', 'Labor Estimates')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.est_duration', 'Est. Duration')}</DisplayLabel>
            <DisplayField>
              {parsedDurationHrs} {t('form.hrs', 'hrs')} {parsedDurationMins} {t('form.min', 'min')}
            </DisplayField>
          </div>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.est_techs', 'Est. # Techs')}</DisplayLabel>
            <DisplayField>{parsedTechs}</DisplayField>
          </div>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.labor_hours', 'Labor Hours')}</DisplayLabel>
            <DisplayField>
              {task?.laborHours} {t('form.hrs', 'hrs')} {task?.laborMinutes} {t('form.min', 'min')}
            </DisplayField>
          </div>
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

ViewLaborEstimates.propTypes = {
  task: PropTypes.object.isRequired,
};
