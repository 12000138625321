import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import ReactSelect, { components } from 'react-select';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { CommonLocators } from '@ge/models/data-locators';
import { elevations } from '@ge/tokens';

import { MenuPlacements } from './models/constants';

const PrimarySelectCss = css`
  color: ${(props) => props.theme.select.primaryTextColor};
  border: 1px solid ${(props) => props.theme.select.primaryBorder};
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  position: relative;
  border-radius: 3px;
  background: ${(props) => props.theme.select.primaryBackground};
`;

const SecondaryCss = css`
  font-size: 13px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  background: ${(props) => props.theme.select.secondaryBackground};
`;

const SmallCss = css`
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 20px;
`;

const MediumCss = css`
  box-sizing: border-box;
  min-height: 25px;
  border-radius: 4px;
`;

const TransparentCss = css`
  background: transparent;
`;

const StyleSelect = styled(ReactSelect)`
  .select__control {
    ${(props) => props.isDisabled && `opacity: 0.3`};
    ${(props) => (props.primary ? PrimarySelectCss : SecondaryCss)};
    ${(props) => (props.small ? SmallCss : MediumCss)};
    ${(props) => props.transparent && TransparentCss};
    .select__value-container {
      .select__single-value {
        height: 19px;
        line-height: 19px;
        color: inherit;
        font-family: 'Museo Sans';
        font-size: 13px;
        letter-spacing: 0;
        position: static;
        top: 0px;
        transform: none;
        max-width: calc(100% - 5px);
      }
      .select__multi-value {
        background: ${(props) => props.theme.input.background};
        .select__multi-value__label {
          color: inherit;
        }
        .select__multi-value__remove {
          &:hover {
            background: ${(props) => props.theme.input.background};
            color: inherit;
            cursor: pointer;
          }
        }
      }
      .select__placeholder {
        color: ${(props) => props.theme.select.optionColor};
      }
    }
    .select__indicators {
      padding-right: 6px;
      &:hover {
        background-color: transparent;
      }
      span {
        display: none;
      }
      .select__dropdown-indicator {
        svg {
          fill: ${(props) =>
            props.primary
              ? props.theme.select.chevronPrimaryColor
              : props.theme.select.chevronColor};
        }
        padding: 0px;
      }
    }
    .select__clear-indicator {
      display: none;
    }
  }
  .select__menu {
    margin: 0;
    font-family: 'Museo Sans';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 0;
    background: ${(props) => props.theme.select.optionBackground};
    z-index: ${elevations.P100};
    .select__menu-list {
      .select__option {
        padding: 7px 12px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.select.optionColor};
        &.select__option--is-focused {
          background-color: ${(props) => props.theme.select.hoverColor};
        }
        &.select__option--is-selected {
          font-weight: 500;
        }
        background-color: ${(props) => props.theme.select.optionBackground};
        &:hover {
          cursor: default;
          color: ${(props) => props.theme.select.optionColor};
          width: 100%;
          background: ${(props) => props.theme.select.hoverColor};
        }
      }
    }
  }

  &.invalid {
    border: ${(props) => props.theme.select.errorBorder};
    border-radius: 4px;
  }
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.select.labelTextColor};
  font-size: 11px;
  font-family: 'Museo Sans';
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 5px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'initial')};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'initial')};
`;

const Check = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHECK,
  color: props.theme.select.checkmarkColor,
}))`
  margin-left: auto;
  display: flex;
`;

const InlineIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: props.icon,
  color: props.theme.select.checkmarkColor,
}))`
  margin-right: 4px;
  vertical-align: initial;
`;

const SingleValue = ({ children, selectProps, ...rest }) => {
  return (
    <components.SingleValue {...rest}>
      {selectProps.icon && <InlineIcon icon={selectProps.icon} />}
      {children}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.instanceOf(Object),
};

const Option = ({ isSelected, children, ...rest }) => {
  return (
    <components.Option {...rest} className={isSelected ? 'select__option--is-selected' : null}>
      {children}
      {isSelected && <Check />}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  icon: PropTypes.string,
};

export const SelectDefault = forwardRef(
  (
    {
      primary,
      small,
      transparent,
      className,
      name,
      options,
      defaultValue,
      autoFocus,
      isSearchable,
      maxWidth,
      minWidth,
      onChange,
      value,
      disabled,
      label,
      id,
      icon,
      placeholder,
      menuPlacement,
      isMulti,
      error,
      required,
      ...rest
    },
    ref,
  ) => (
    <SelectWrapper
      data-testid={CommonLocators.COMMON_SELECT_WRAPPER}
      maxWidth={maxWidth}
      minWidth={minWidth}
    >
      {label && (
        <StyledLabel htmlFor={id}>
          {label}
          {required && <>*</>}
        </StyledLabel>
      )}
      <StyleSelect
        primary={primary}
        small={small}
        className={`${className} ${!!error && 'invalid'}`}
        classNamePrefix={'select'}
        id={id}
        name={name}
        value={value}
        isDisabled={disabled}
        defaultValue={defaultValue}
        options={options}
        components={{ Option, SingleValue }}
        onChange={onChange}
        ref={ref}
        autoFocus={autoFocus}
        isSearchable={isSearchable}
        transparent={transparent}
        icon={icon}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        isMulti={isMulti}
        aria-invalid={!!error}
        {...rest}
      />
    </SelectWrapper>
  ),
);

SelectDefault.displayName = 'SelectDefault';

SelectDefault.propTypes = {
  label: PropTypes.string,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  isSearchable: PropTypes.bool,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  transparent: PropTypes.bool,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string,
  isMulti: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
};

SelectDefault.defaultProps = {
  label: null,
  onChange: () => {},
  disabled: false,
  options: [],
  value: null,
  defaultValue: null,
  name: null,
  autoFocus: false,
  isSearchable: false,
  primary: false,
  small: false,
  maxWidth: null,
  minWidth: null,
  transparent: false,
  icon: null,
  placeholder: null,
  menuPlacement: MenuPlacements.AUTO,
  isMulti: false,
};
