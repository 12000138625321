import { useMutation } from 'react-query';

import { createCaseTemplate, deleteCaseTemplate, updateCaseTemplate } from '../services';

export const useCreateCaseTemplate = (opt) => {
  return useMutation(createCaseTemplate, opt);
};

export const useUpdateCaseTemplate = (opt) => {
  return useMutation(updateCaseTemplate, opt);
};

export const useDeleteCaseTemplate = (opt) => {
  return useMutation(deleteCaseTemplate, opt);
};
