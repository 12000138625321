import dayjs from 'dayjs';
import React, { useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { useAssetContext, useDataExplorerContext } from '@ge/feat-analyze/context';
import useCaseAnalysisTemplate from '@ge/feat-analyze/data-hooks/use-case-analysis-template';
import useDataExplorerOverview from '@ge/feat-analyze/data-hooks/use-data-explorer-overview';
import { ChartItem } from '@ge/feat-analyze/models/chart-item';
import { getSignalTitle } from '@ge/feat-analyze/util/data-explorer-chart';
import { DataExplorerLayouts as Layouts, DataLoaderType } from '@ge/models/constants';
import { TimeSignal, DateRange } from '@ge/models/constants';
import useCaseDetailDataByQuery from '@ge/shared/data-hooks/cases/use-case-detail-data-by-query';
import { getFilterDateRange } from '@ge/shared/util';

import { DataExplorerChartContainer } from './data-explorer-chart-container';

const CHART_DETAIL_HEIGHT = 436;
const CHART_LIST_HEIGHT = 234;
const CHART_GRID_HEIGHT = 330;

const ChartsDetailContainer = styled.div`
  margin: 0px 16px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .main-chart {
    min-width: calc(68% - 16px);
    margin-bottom: ${({ enableMultiAxis }) => (!enableMultiAxis ? `24px;` : `8px`)};
  }
`;

const ChartsGridContainer = styled.div`
  margin: 0px 16px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ enableMultiAxis }) => (!enableMultiAxis ? `24px;` : `8px`)};
`;

const ChartListContainer = styled.div`
  display: grid;
  ${({ enableMultiAxis }) => {
    if (!enableMultiAxis) {
      return css`
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        > div:first-child {
          grid-column: span 2;
        }
      `;
    }
    return `
      grid-gap: 8px;
    `;
  }}
`;

// Small helper util to create the object shape the ChartItem constructor expects.
// Could move this out to utils if there is any where else this would be useful?
const buildChartAxisObj = (signal, signalData) => {
  const id = signal.signalId;
  const timeAggr = signal.signalAggregation ? String(signal.signalAggregation).toUpperCase() : '';
  const _signal =
    id === TimeSignal.id ? TimeSignal : signalData?.find((v) => v.id === id) ?? { ...signal, id };

  return {
    ..._signal,
    title: getSignalTitle({ ..._signal, timeAggr }),
    timeAggr,
  };
};

const buildChartObj = (plotId, signal_x, signals_y, plotType, signalData) => {
  const { yAxis, units } = signals_y
    .map((signal_y) => buildChartAxisObj(signal_y, signalData))
    .reduce(
      (result, signal) => {
        const units = [...new Set([...result.units, signal.unit])];
        if (signal.yAxis === undefined) {
          const unitIndex = units.indexOf(signal.unit);
          signal.yAxis = unitIndex !== -1 ? unitIndex : 0;
        }
        result.yAxis.push(signal);
        result.units = units;
        return result;
      },
      { yAxis: [], units: [] },
    );

  return {
    id: `plot-${plotId}`,
    xAxis: buildChartAxisObj(signal_x, signalData),
    yAxis,
    type:
      plotType === ChartType.LINE || plotType === ChartType.SPLINE ? ChartType.SPLINE : plotType,
    units,
  };
};

export const ChartLayoutFactory = () => {
  const {
    queryParam,
    assetState: { datePickerRef, signalData, chartsArray, setChartsArray },
  } = useAssetContext();

  const { enableMultiAxis } = useDataExplorerContext();

  const { assets: assetIds, caseId } = queryParam ?? {};
  // Hooks
  const { caseWithDetails, isLoading } = useCaseDetailDataByQuery({ caseId });
  const { data: analysisTemplateData, isLoading: isAnalysisTemplateLoading } =
    useCaseAnalysisTemplate({ caseWithDetails, isLoading });
  const { isLoading: isDataExplorerOverviewLoading } = useDataExplorerOverview(assetIds);

  // Logic to apply the template to DataExplore.
  // Could look at moving this as features are added if necessary.
  const applyTemplate = useCallback(
    (template) => {
      const { plots = [] } = template;
      // Builds a configured charts object that contains correctly ordered CartItems.
      const configuredCharts = plots
        .sort(
          (a, b) =>
            parseInt(String(a.plotId).replace('_', '')) -
            parseInt(String(b.plotId).replace('_', '')),
        )
        .reduce((ac, { plotId, signal_x, signals_y, plotType }, index) => {
          const prop = buildChartObj(plotId, signal_x, signals_y, plotType, signalData);
          prop.expended = index === 0;
          const chartObj = new ChartItem(prop);
          // Since the plots are sorted by plotId(i.e 1-1, 2-1, 2-2).
          // The first item will always be the selected chart.
          ac.push(chartObj);
          return ac;
        }, []);

      // set context
      setChartsArray(configuredCharts);
    },
    [setChartsArray, signalData],
  );

  useEffect(() => {
    if (!isLoading && caseWithDetails?.start && dayjs(caseWithDetails?.start).isValid()) {
      const currentDate = dayjs().endOf('date').toDate();
      const filterDateRange = getFilterDateRange({
        endDate: currentDate,
        range: DateRange.CUSTOM,
        startDate: caseWithDetails.start,
      });
      const dates = {
        range: DateRange.CUSTOM,
        startDate: filterDateRange.startDate.entityTimezone,
        endDate: filterDateRange.endDate.entityTimezone,
      };
      datePickerRef?.current?.applyDateRange(dates);
    }
  }, [caseId, isLoading, caseWithDetails, datePickerRef]);

  useEffect(() => {
    if (!queryParam.caseId || !analysisTemplateData || isAnalysisTemplateLoading) return;
    applyTemplate(analysisTemplateData);
  }, [analysisTemplateData, applyTemplate, isAnalysisTemplateLoading, queryParam]);

  const handlePromoteChart = useCallback(
    (index) => {
      const charts = [...chartsArray];
      const chartToPromote = charts[index];
      if (enableMultiAxis) {
        chartToPromote.expended = !chartToPromote.expended;
        setChartsArray(charts);
      } else {
        charts.splice(index, 1);
        charts.splice(0, 0, chartToPromote);
      }
      setChartsArray(charts);
    },
    [enableMultiAxis, chartsArray, setChartsArray],
  );

  return (
    <DataLoader
      isLoading={isAnalysisTemplateLoading || isDataExplorerOverviewLoading}
      type={DataLoaderType.GRID}
    >
      {queryParam.layout === Layouts.DETAIL ? (
        <ChartsDetailContainer enableMultiAxis={enableMultiAxis}>
          <ChartListContainer enableMultiAxis={enableMultiAxis}>
            {chartsArray.map((chart, i) => (
              <DataExplorerChartContainer
                key={chart.id}
                id={chart.id}
                height={chart.expended ? CHART_DETAIL_HEIGHT : CHART_LIST_HEIGHT}
                chartDef={chart}
                onPromote={() => handlePromoteChart(i)}
                index={i}
              />
            ))}
          </ChartListContainer>
        </ChartsDetailContainer>
      ) : (
        <ChartsGridContainer enableMultiAxis={enableMultiAxis}>
          {chartsArray.map((chart, i) => (
            <DataExplorerChartContainer
              key={chart.id}
              id={chart.id}
              height={CHART_GRID_HEIGHT}
              chartDef={chart}
              index={i}
            />
          ))}
        </ChartsGridContainer>
      )}
    </DataLoader>
  );
};
