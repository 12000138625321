import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateLaborCharges } from './create-labor-charges';
import { ViewLaborCharges } from './view-labor-charges';

export const SectionHeading = styled.h4`
  margin-bottom: 10px;
`;

export const LaborChargesSection = ({
  task,
  section,
  templateMode,
  erpData,
  setDivLabourHeight,
  isTaskSelected,
}) => {
  const getLaborChargesSection = () => {
    if (!section.hidden) {
      switch (templateMode) {
        case TaskTemplateModes.CREATE:
        case TaskTemplateModes.EDIT:
          return (
            <CreateLaborCharges
              section={section}
              erpData={erpData}
              task={task}
              isTaskSelected={isTaskSelected}
            />
          );
        case TaskTemplateModes.VIEW:
          return (
            <ViewLaborCharges
              section={section}
              erpData={erpData}
              setDivLabourHeight={setDivLabourHeight}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  return <section>{getLaborChargesSection()}</section>;
};

LaborChargesSection.propTypes = {
  section: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  task: PropTypes.object.isRequired,
  erpData: PropTypes.object,
  setDivLabourHeight: PropTypes.func,
  isTaskSelected: PropTypes.bool,
};
