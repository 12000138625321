import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { DateTimeFormats } from '@ge/models/constants';
import { timezoneFormat } from '@ge/util/dayjs-utils';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(timezoneFormat);

const CHART_HEIGHT = 260;

export const CaseSensorReadingsChart = withTheme(({ theme, alert, sensorData, asset, site }) => {
  const { ready, t } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const { sensorReadingsChart } = theme;

  if (!(sensorData && ready)) {
    return null;
  }
  const { alerts = [], context = [] } = sensorData;
  const { timezone: siteTimeZone = 'UTC' } = site;

  const xAxisTitle = t('sensor_readings.x_axis_title', 'Time');
  const yAxisTitle = alert?.signalId ?? '';

  const tooltipHeader = `<h4>${site.name} ${asset.name}</h4>`;
  const tooltipPointFormatter = ({ y, date }) => `
    <div class="body-4">
      ${date} <b>${xAxisTitle}</b><br/>
      ${y} <b>${yAxisTitle}</b>
    </div>
  `;

  const mapSensorData = (lineColor) => (sensorData) =>
    sensorData.map((series) => {
      return {
        data: series.map((coord) => {
          const localDate = dayjs.unix(coord.ts).tz(siteTimeZone);
          return {
            x: localDate.$d,
            y: parseFloat(coord[alert.signalId]),
            date: `${localDate.format(DateTimeFormats.DEFAULT_DATE_TIME_TIMEZONE)}`,
          };
        }),
        color: lineColor,
        tooltipHeader,
        tooltipPointFormatter,
      };
    });

  const series = [
    mapSensorData(sensorReadingsChart.alertLine)(alerts),
    mapSensorData(sensorReadingsChart.contextLine)(context),
  ].flat();

  const tooltip = { outside: false };

  return (
    <Chart
      height={CHART_HEIGHT}
      noDataLabel={t('sensor_readings.no_data_label', 'No Data')}
      recreateOnUpdate
      series={series}
      type={ChartType.SPLINE}
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      yAxisOpposite={false}
      xAxisType={'datetime'}
      disableSideMargin
      useUTC={false}
      tooltip={tooltip}
    />
  );
});
CaseSensorReadingsChart.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.instanceOf(Object),
  sensorData: PropTypes.instanceOf(Object),
  asset: PropTypes.instanceOf(Object),
  site: PropTypes.instanceOf(Object),
};

CaseSensorReadingsChart.defaultProps = {
  alert: null,
  sensorData: null,
  asset: {},
  site: {},
};

CaseSensorReadingsChart.displayName = 'CaseSensorReadingsChart';
