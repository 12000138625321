import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

const InputWrapper = styled.input`
  border: none;
  margin: 0px;
  padding: 7px 8px;
  font-size: 12px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  ::placeholder {
    color: ${globalColors.grey4};
  }
  background: ${(props) => props.theme.input.background};
`;

export const StyledFilter = withTheme(({ theme, searchByName }) => {
  const { filterBySearchValue } = useStoreActions((actions) => actions.tabflow);

  return (
    <form>
      <InputWrapper
        type="search"
        placeholder="Search"
        onChange={(event) => {
          filterBySearchValue({ name: searchByName, searchValue: event.target.value });
        }}
      />
      <button onClick={(e) => e.preventDefault()} className="person-assets-sites-filter-button">
        <Icon icon={Icons.SEARCH} size={11} color={theme.admin.panel.entityRow.icon} />
      </button>
    </form>
  );
});
StyledFilter.propTypes = {
  sites: PropTypes.array,
};
