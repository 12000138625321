import { getContrast } from 'polished';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';

const arrowCSS = css`
  top: 100%;
  left: 0;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: ${(props) => props.color};
  border-width: 4px;
  transform: translateX(-50%);
  margin-left: 50%;
`;

const DismissIcon = styled(Icon).attrs(({ theme }) => ({
  size: 10,
  icon: Icons.CLOSE,
  color: theme?.badge?.dismissIconColor,
}))`
  background: ${({ theme }) => theme?.badge?.dismissIconBackgroundColor};
  border-radius: 6px;
  margin: 0;
  stroke: ${({ theme }) => theme?.badge?.dismissIconBackgroundColor};
  stroke-width: 1px;

  &.dismiss-icon {
    padding: 1px;
  }
`;

const StyledBadge = styled.div`
  position: relative;
  border-radius: ${(props) => (props.arrow ? '2px' : '5px')};

  border-radius: ${(props) => {
    const { arrow } = props;
    if (arrow) {
      return '2px';
    }
    return '5px';
  }};

  height: 14px;
  line-height: 15px;
  background: ${(props) => props.color};
  border: ${(props) => {
    if (props.isDisconnected) {
      return `1px solid ${props.theme.colors.grey1}`;
    }
  }};

  display: inline-block;
  font-family: 'Museo Sans';
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
  text-align: center;
  min-width: ${(props) => {
    const { fullWidth, large, medium, small } = props;

    if (fullWidth) {
      return '100%';
    }
    if (large) {
      return '30px';
    }
    if (medium) {
      return '22px';
    }
    if (small) {
      return '10px';
    }
    return null;
  }};

  padding: ${(props) => {
    const { small } = props;
    if (small) {
      return '0 4px';
    }
    return '1px 6px 1px;';
  }};

  color: ${(props) => {
    const contrastRatio = getContrast(props.color, globalColors.white);
    if (contrastRatio > 2) {
      return 'white';
    }
    return 'black';
  }};

  outline: ${(props) => {
    const { isDisconnected } = props;
    if (isDisconnected) {
      return `2.5px dashed ${props.theme.colors.grey4}`;
    }
  }};

  &:after {
    content: '';

    ${(props) => {
      const { arrow } = props;
      if (arrow) return arrowCSS;
      return '';
    }}
  }

  &.dismissable {
    border-radius: 8px;
    box-sizing: border-box;
    height: 18px;
    line-height: 12px;
    min-width: unset;
    padding: 3px 10px;

    .badge-label-wrapper {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;

      .badge-label {
        font-size: 10px;
        font-weight: 300;
        white-space: pre;
      }
    }

    button {
      padding: 0;
      position: absolute;
      right: 2px;
      top: -11px;
    }
  }
`;

export const Badge = ({
  arrow,
  className,
  color,
  fullWidth,
  onDismiss,
  label,
  large,
  medium,
  small,
  isDisconnected,
}) => {
  let classes = className;
  let content = label;

  if (onDismiss) {
    const handleDismiss = (e) => {
      killEventPropagation(e);

      onDismiss();
    };

    classes = `${className} dismissable`;
    content = (
      <>
        <button type="button" onClick={handleDismiss}>
          <DismissIcon className="dismiss-icon" />
        </button>
        <div className="badge-label-wrapper">
          <span className="badge-label">{label}</span>
        </div>
      </>
    );
  }

  return (
    <StyledBadge
      arrow={arrow}
      className={classes}
      color={color}
      fullWidth={fullWidth}
      large={large}
      medium={medium}
      small={small}
      isDisconnected={isDisconnected}
    >
      {content}
    </StyledBadge>
  );
};

Badge.propTypes = {
  arrow: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  onDismiss: PropTypes.func,
  small: PropTypes.bool,
  isDisconnected: PropTypes.bool,
};

Badge.defaultProps = {
  arrow: false,
  className: '',
  color: null,
  fullWidth: false,
  large: false,
  medium: false,
  onDismiss: undefined,
  small: false,
  isDisconnected: false,
};
