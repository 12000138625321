import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Kpi } from '../data-visualization/kpi';

const CardOutputComponent = ({ className, value, label, footer, children }) => (
  <div className={className}>
    {children}
    <div className="data">
      <Kpi value={value} label={label} footer={footer} title="Availability" />
      <div className="spacer" />
      <Kpi value={value} label={label} footer={footer} title="24hr Total" />
    </div>
  </div>
);

CardOutputComponent.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CardOutputComponent.defaultProps = {
  children: [],
};

export const CardOutput = styled(CardOutputComponent)`
  padding: 12px 12px 0px;
  overflow: hidden;
  .data {
    border-top: solid 1px ${(props) => props.theme.assetCard.borderColor};
    border-bottom: solid 1px ${(props) => props.theme.assetCard.borderColor};
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    > div {
      padding: 8px;
    }
  }
`;
