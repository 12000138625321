import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Manager, Popper } from 'react-popper';
import styled from 'styled-components';

import { elevations } from '@ge/tokens/elevations';

import { placements } from './models/placements';

const StyledMenu = styled.div`
  height: auto;
  position: absolute;
  z-index: ${elevations.P25};
  color: black;
  transition: opacity 0.3s ease;
  min-width: ${(props) => (props.width ? `${props.width}px` : '200px')};
`;

const MenuContent = styled.div`
  background: ${(props) => props.theme.menu.backgroundColor};
  color: ${(props) => props.theme.menu.textColor};
  border-radius: 5px;
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.02);
  overflow: hidden;
`;

export const Menu = ({
  open,
  children,
  anchorEl,
  portalId,
  onClose,
  width,
  placement,
  padding,
  fixed,
  onClick,
  zIndex,
}) => {
  const modifiers = [
    {
      name: 'hide',
      options: { enabled: fixed },
    },
    { name: 'preventOverflow', options: { enabled: fixed } },
  ];

  useEffect(() => {
    // Handler for a document event to determine if we should close the menu.
    const handleEvent = (e) => {
      if (!open) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      onClose();
    };

    document.addEventListener('click', handleEvent, false);
    document.addEventListener('keydown', handleEvent, false);

    // Clean up event listener on teardown.
    return () => {
      document.removeEventListener('click', handleEvent, false);
      document.removeEventListener('keydown', handleEvent, false);
    };
  }, [onClose, open]);

  return (
    <Manager>
      {open &&
        children &&
        ReactDOM.createPortal(
          <Popper placement={placement} referenceElement={anchorEl} modifiers={modifiers}>
            {({ ref, placement, style }) => (
              <StyledMenu
                ref={ref}
                style={{ ...style, padding: padding, zIndex: zIndex }}
                data-placement={placement}
                width={width}
                onClick={(e) => {
                  onClick(e);
                }}
              >
                <MenuContent>{children}</MenuContent>
              </StyledMenu>
            )}
          </Popper>,
          document.querySelector(portalId),
        )}
    </Manager>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  offset: PropTypes.number,
  skidding: PropTypes.number,
  width: PropTypes.number,
  padding: PropTypes.string,
  portalId: PropTypes.string,
  placement: PropTypes.oneOf(Object.values(placements)),
  fixed: PropTypes.bool,
  zIndex: PropTypes.number,
};

Menu.defaultProps = {
  open: false,
  anchorEl: null,
  children: null,
  onClose: () => {},
  onClick: () => {},
  offset: 0,
  skidding: 0,
  width: null,
  padding: '10px 0',
  portalId: '#portal-root',
  placement: placements.BOTTOM_START,
  fixed: false,
  zIndex: 25,
};
