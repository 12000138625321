import { PropTypes } from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import styled, { withTheme } from 'styled-components';

import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { elevations } from '@ge/tokens/elevations';

const ModalWrapper = styled.div`
  background-color: ${(props) => props.theme.modal.overlay};
  bottom: 0;
  left: 0;
  padding: 12px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${elevations.P100};
`;

const ModalComponent = ({ isOpen, children, portalId }) => {
  if (!isOpen) {
    return null;
  }

  return createPortal(
    <DetailsDismissInert>
      <ModalWrapper>{children}</ModalWrapper>
    </DetailsDismissInert>,
    document.querySelector(portalId),
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  portalId: PropTypes.string,
};

ModalComponent.defaultProps = {
  cancelText: 'cancel',
  confirmText: 'OK',
  isOpen: false,
  children: null,
  onConfirm: () => null,
  portalId: '#portal-root',
};

export const Modal = withTheme(ModalComponent);
