import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BoldSearch } from '@ge/components/bold-search';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';

const FilterSelectLabel = styled.div`
  color: ${(props) => props.theme.filterMenu.titleColor};
  line-height: 13px;
  font-size: 11px;
  &.disabled {
    color: #656566;
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
  }
`;

const FilterSelect = styled.div`
  background: ${(props) => props.theme.select.optionBackground};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.menu.borderColor};
  border-radius: 2px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  cursor: default;
  display: flex;
  justify-content: space-between;
  line-height: 15px;
  font-size: 13px;
  margin-top: 5px;
  padding: 2px 8px;
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &.disabled {
    color: #999999;
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
  }
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.select.chevronColor,
}))`
  margin-top: 3px;
`;

const SelectMenu = styled.div``;

const SearchWrapper = styled.div`
  padding: 8px 7px 0px;
  .filter {
    position: relative;
    input {
      background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
      box-sizing: border-box;
      border: 1px solid ${(props) => props.theme.input.borderNavbarInput};
      border-radius: 2px;
      caret-color: ${(props) => props.theme.newTaskDialog.searchCaret};
      color: ${(props) => props.theme.newTaskDialog.searchText};
      height: 28px;
      padding: 6px 8px;
      width: 100%;
      ::placeholder {
        font-size: 14px;
        font-style: italic;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }
`;

const SelectResultsContainer = styled.ul`
  list-style: none;
  padding: 6px 0px 6px;
  margin: 0;
  > li {
    padding: 0px;
    margin: 0px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 6px 7px;
  line-height: 15px;
  font-size: 12px;
  color: ${(props) => props.theme.select.optionColor};
  &.show_all {
    border-bottom: solid 1px ${(props) => props.theme.filterMenu.borderColor};
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
`;

const PORTAL_ID = 'filter-menu-root__';

const FilterMultiSelectCheckbox = ({ className, label, value, filterText, checked, onChange }) => {
  const inputRef = useRef();

  return (
    <li>
      <StyledCheckbox
        className={className}
        label={filterText ? <BoldSearch text={label} textBold={filterText} /> : label}
        checkState={checked ? CheckedState.CHECKED : CheckedState.UNCHECKED}
        onChange={() => onChange({ checked: inputRef?.current?.checked, value })}
        ref={inputRef}
      />
    </li>
  );
};

export const FilterMultiSelect = ({ name, label, disabled, selected, options, onChange }) => {
  const { t, ready } = useTranslation(['general'], { useSuspense: false });

  const [filterText, setFilterText] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const anchorEl = useRef();

  useEffect(() => {
    const node = document.createElement('div');
    node.id = PORTAL_ID + name;
    document.body.appendChild(node);
    return () => {
      document.body.removeChild(node);
    };
  }, [name]);

  const filteredOptions = useMemo(
    () =>
      options.filter(({ label }) =>
        String(label)
          .toLowerCase()
          .includes(filterText.toLowerCase()),
      ),
    [options, filterText],
  );

  const toggleMenu = useCallback((e) => {
    killEventPropagation(e);
    setShowMenu((prev) => !prev);
  }, []);

  const handleCloseMenu = useCallback((e) => {
    killEventPropagation(e);
    setShowMenu(false);
  }, []);

  const handleSelectAll = useCallback(
    ({ checked }) => {
      if (checked) {
        onChange(filteredOptions.map(({ value }) => value));
        return;
      }
      onChange([]);
    },
    [filteredOptions, onChange],
  );

  const handleChange = useCallback(
    ({ checked, value }) => {
      if (checked) {
        onChange(Array.from(new Set([...selected, value])));
      } else {
        onChange(selected.filter((val) => val !== value));
      }
    },
    [selected, onChange],
  );

  if (!ready) return null;

  return (
    <div onMouseLeave={handleCloseMenu}>
      {label && (
        <FilterSelectLabel className={disabled ? 'disabled' : ''}>{label}</FilterSelectLabel>
      )}
      <FilterSelect onClick={toggleMenu} ref={anchorEl} className={disabled ? 'disabled' : ''}>
        {selected?.length > 0
          ? t('filter_menu.selected', { count: selected.length })
          : t('filter_menu.select', 'Select')}
        <ChevronIcon />
      </FilterSelect>
      <Menu
        anchorEl={anchorEl.current}
        open={showMenu}
        onClose={handleCloseMenu}
        portalId={'#' + PORTAL_ID + name}
        style={{ boxShadow: '0 1px 5px 3px rgba(0, 0, 0, 0.15)' }}
        padding={'0px'}
        width={anchorEl.current?.offsetWidth}
      >
        <SelectMenu>
          <SearchWrapper>
            <Filter
              text={filterText}
              onKeyDown={(e) => {
                killEventPropagation(e);
              }}
              onChange={(e) => {
                killEventPropagation(e);
                setFilterText(e.target.value);
              }}
              placeholder={t('filter_menu.search', 'Search')}
              hideResultsOnBlur={false}
            />
          </SearchWrapper>
          <SelectResultsContainer>
            <FilterMultiSelectCheckbox
              className={'show_all'}
              label={t('filter_menu.show_all', 'Show All')}
              checked={selected.length > 0 && selected.length === filteredOptions.length}
              onChange={handleSelectAll}
            />
            {filteredOptions?.map((item, i) => (
              <FilterMultiSelectCheckbox
                key={item.value + '-' + i}
                label={item.label}
                value={item.value}
                filterText={filterText}
                checked={selected?.includes(item.value)}
                onChange={handleChange}
              />
            ))}
          </SelectResultsContainer>
        </SelectMenu>
      </Menu>
    </div>
  );
};

FilterMultiSelectCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  filterText: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

FilterMultiSelectCheckbox.defaultProps = {
  onChange: () => null,
};

FilterMultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.array,
  options: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

FilterMultiSelect.defaultProps = {
  name: '',
  label: '',
  disabled: false,
  selected: [],
  options: [],
  onChange: () => {},
};
