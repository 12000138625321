import { Capability, PermissionScope } from '@ge/models/constants';

export const TemplateColumns = {
  GROUP_NAME: 'group-name',
  NAME: 'name',

  GROUP_SCOPE: 'group-scope',
  SCOPE: 'scope',

  GROUP_SPAN: 'group-span',
  SPAN: 'span',

  GROUP_CREATE_REPORT: 'group-create-report',
  CREATE_REPORT: 'createReport',
};

// Define all available columns in the roles table.
export const TemplateColumnDefs = {
  [TemplateColumns.GROUP_NAME]: {
    cols: {
      [TemplateColumns.NAME]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.name',
            a11yDefault: 'Template Name',
            sortValue: 'name',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [TemplateColumns.GROUP_SCOPE]: {
    cols: {
      [TemplateColumns.SCOPE]: {
        align: 'left',
        labels: [
          {
            a11yKey: 'table.scope',
            a11yDefault: 'Scope',
            sortValue: 'scope',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [TemplateColumns.GROUP_SPAN]: {
    cols: {
      [TemplateColumns.SPAN]: {
        labels: [
          {
            a11yKey: 'table.span',
            a11yDefault: '',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [TemplateColumns.GROUP_CREATE_REPORT]: {
    cols: {
      [TemplateColumns.CREATE_REPORT]: {
        capabilities: [
          { capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.CREATE] },
          { capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.EDIT] },
        ],
        labels: [
          {
            a11yKey: 'table.createReport',
            a11yDefault: 'New Report',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
};

export const globalTemplateCols = [
  {
    id: TemplateColumns.GROUP_NAME,
    cols: [
      {
        id: TemplateColumns.NAME,
        visible: true,
      },
    ],
  },
  {
    id: TemplateColumns.GROUP_SCOPE,
    cols: [
      {
        id: TemplateColumns.SCOPE,
        visible: true,
      },
    ],
  },
  {
    id: TemplateColumns.GROUP_SPAN,
    cols: [
      {
        id: TemplateColumns.SPAN,
        visible: false,
      },
    ],
  },
  {
    id: TemplateColumns.GROUP_CREATE_REPORT,
    cols: [
      {
        id: TemplateColumns.CREATE_REPORT,
        visible: true,
      },
    ],
  },
];
