import { useState, useEffect } from 'react';

const useInterval = (ms) => {
  const [intervalEnd, setIntervalEnd] = useState(new Date());

  useEffect(() => {
    const handleIntervalEnd = () => {
      setIntervalEnd(new Date());
    };

    const timeout = setInterval(handleIntervalEnd, ms);

    return () => {
      clearInterval(timeout);
    };
  });

  return intervalEnd;
};

export default useInterval;
