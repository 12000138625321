import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@ge/components/icon';

const HeaderContainer = styled.div`
  .header {
    display: flex;
    padding: 0px 16px 8px;
    width: calc(100% - 32px);
    align-items: center;
    border-bottom: solid 1px ${(props) => props.theme.sidebar.borderColor};
  }
`;

const SettingsIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: props.icon,
  color: props.theme.sidebar.iconColor,
}))`
  margin-right: 6px;
`;

const SettingsHeader = ({ icon, title }) => (
  <HeaderContainer>
    <div className="header">
      <SettingsIcon icon={icon} />
      <h2>{title}</h2>
    </div>
  </HeaderContainer>
);

SettingsHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SettingsHeader;
