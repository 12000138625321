import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AlertTemplateFieldType, FormMode, DateTimeFormats } from '@ge/models/constants';
import {
  DateMetaField,
  DateTimeMetaField,
  SelectMetaField,
  TextMetaField,
} from '@ge/shared/components/meta-fields';
import { StyledDateContainer } from '@ge/shared/components/tasks/task-template-shared';
import { getFriendlyTimestamp } from '@ge/shared/util';

import { AlertViewField } from '../alert-shared';

import { BlockedActionsField } from './blocked-actions-field';

// will this defaultValue (esp if empty) override the default value on an existing alert bound to the form higher up the chain?
export const AlertField = ({ defaultValue, mode, name, ...metadata }) => {
  const { ready, t } = useTranslation(['alerts'], { useSuspense: false });
  const { control } = useFormContext();
  let value = useWatch({ control, defaultValue, name });

  if (!ready) {
    return null;
  }

  if (metadata?.type === AlertTemplateFieldType.BLOCKED_ACTIONS) {
    return <BlockedActionsField values={metadata.values} />;
  }

  const translations = {
    label: t(`form.${name}.label`, name),
    placeholderLabel: t(`form.${name}.placeholder_label`, ''),
  };

  if (mode === FormMode.VIEW) {
    // if we have options then get label to display in view mode
    if (metadata?.values?.length) {
      value = metadata.values.find(({ value: _value }) => value === _value)?.label ?? value;
    }
    if (metadata?.type === AlertTemplateFieldType.DATE_TIME && value) {
      const timeZone = getFriendlyTimestamp(new Date()).replace(/.*(\([^)]+\)).*/, '$1');
      value = `${dayjs(value).format(DateTimeFormats.DEFAULT_DATE_TIME)} ${timeZone}`;
    }
    return <AlertViewField label={translations.label} value={value} />;
  }

  switch (metadata?.type) {
    case AlertTemplateFieldType.DATE:
      return (
        <StyledDateContainer>
          <div className="label">{translations.label}</div>
          <DateMetaField
            defaultSelection={metadata.defaultSelection}
            defaultValue={defaultValue}
            metadata={metadata}
            name={name}
            validators={metadata.validators}
          />
        </StyledDateContainer>
      );

    case AlertTemplateFieldType.DATE_TIME:
      return (
        <DateTimeMetaField
          defaultSelection={metadata.defaultSelection}
          defaultValue={defaultValue}
          label={translations.label}
          metadata={metadata}
          name={name}
          validators={metadata.validators}
        />
      );

    case AlertTemplateFieldType.SELECT: {
      return (
        <SelectMetaField
          defaultValue={defaultValue ?? metadata.defaultSelection ?? metadata.values?.[0]?.value}
          label={translations.label}
          metadata={metadata}
          name={name}
          options={metadata.values}
          primary={metadata.emphasis === 1}
          searchable={false}
        />
      );
    }

    case AlertTemplateFieldType.TEXT:
      return (
        <TextMetaField
          defaultValue={defaultValue}
          label={translations.label}
          maxLength={metadata.maxLength}
          metadata={metadata}
          name={name}
          placeholder={translations.placeholderLabel}
          tabIndex={1}
        />
      );

    default:
      return null;
  }
};

AlertField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hidden: PropTypes.bool,
  maxLength: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  name: PropTypes.string.isRequired,
};

AlertField.defaultProps = {
  defaultValue: undefined,
  hidden: false,
  maxLength: undefined,
  mode: FormMode.VIEW,
};
