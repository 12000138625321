import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DynamicTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { AdminDefs } from '@ge/models/constants';
import { useFilterDefs } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { withDefault } from '@ge/shared/util/table-utils';

import { TenantContext } from '../../context/tenant-context';
import logo from '../../images/logo.svg';
import { RolesColumnDefs, RolesColumns, globalRolesCols } from '../../models/roles-table-cols';

const StyledDisplay = styled.span`
  color: ${(props) => props.theme.table.columnTextColor};
`;

const StyledTenant = styled.span`
  align-items: center;
  display: flex;
  font-size: 14px;
`;

const CustomerIcon = styled(Icon).attrs((props) => ({
  size: 18,
  icon: Icons.FLEET,
  color: props.theme.table.personIconColor,
}))`
  margin-right: 10px;
`;

const GeIcon = styled.img.attrs(() => ({
  src: logo,
}))`
  height: 18px;
  margin-right: 10px;
  width: 18px;
`;

const ThGroup = styled.span`
  height: 1px;
`;

const RolesTable = ({
  roles,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onRoleSelect,
  scrollable,
  className,
  onFilter,
  onFilterChange,
  filterValues,
}) => {
  const { t, ready } = useTranslation(['admin.roles']);

  const {
    tenantState: { setSelectedTenant },
  } = useContext(TenantContext);

  const cellValues = {
    GE: 'geservgEuc',
  };

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const { filterDefs, onChange: onFilterApply } = useFilterDefs({
    columnDefs: RolesColumnDefs,
    stateId: AdminDefs.ROLES_FILTER_STATE_ID,
  });

  useEffect(() => {
    onFilter(filterDefs);
  }, [filterDefs, onFilter]);

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilterApply(columnKey, value);
    },
    [onFilterApply],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const cellValueMapFn = useCallback((cell) => {
    if (!cell) {
      return {};
    }

    const { role, description, tenant } = cell;

    return {
      [RolesColumns.DESCRIPTION]: description,
      [RolesColumns.ROLE]: role,
      [RolesColumns.TENANT]: { name: tenant?.name, id: tenant?.id } || {},
    };
  }, []);

  // TODO: figure out why this isnt rendering in the column header
  const customHeaderFn = useCallback((columnKey) => {
    switch (columnKey) {
      case RolesColumns.GROUP_ROLE:
      case RolesColumns.GROUP_DESCRIPTION:
      case RolesColumns.GROUP_TENANT:
        return <ThGroup />;
      default:
        return null;
    }
  }, []);

  const customCellFn = (columnKey, cellValue) => {
    switch (columnKey) {
      case RolesColumns.ROLE:
        return withDefault(cellValue);
      case RolesColumns.DESCRIPTION:
        return withDefault(cellValue, <StyledDisplay>{cellValue}</StyledDisplay>);
      case RolesColumns.TENANT:
        return withDefault(
          cellValue,
          <StyledTenant
            onClick={(e) => {
              killEventPropagation(e);
              e.preventDefault();
              setSelectedTenant(cellValue);
            }}
          >
            {cellValue?.id === cellValues.GE ? <GeIcon /> : <CustomerIcon />}
            {cellValue?.name}
          </StyledTenant>,
        );
      default:
        return null;
    }
  };

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: RolesColumnDefs,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    filterValues,
    filters: filterDefs,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
  });

  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: roles,
    onValueSelect: onRoleSelect,
    rowKeyProperty: 'id',
  };

  if (!ready) {
    return <Loader />;
  }

  const tableEl = React.createElement(DynamicTable, dynamicProps);

  return <PageContainer i18nReady={ready}>{tableEl}</PageContainer>;
};

RolesTable.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onRoleSelect: PropTypes.func,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
};

RolesTable.defaultProps = {
  columns: globalRolesCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onRoleSelect: () => null,
  scrollable: true,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
};

export default RolesTable;
