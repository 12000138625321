import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { DraggableTable, DynamicTable } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { globalColors } from '@ge/tokens/colors';

import {
  CaseAlertsColumnDefs,
  CaseAlertsColumns,
  defaultCaseAlertsCols,
} from '../../models/table-col-defs/case-alerts-table-cols';
import { getDuration } from '../../util/time-date';

const StyledTypeIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.theme.table.iconLightColor,
}))``;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
      font-size: 11px;
    }
  }
`;

const Chevron = styled(Icon).attrs(() => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: globalColors.slate3,
  rotate: -90,
}))``;

const CaseAlertsTable = ({
  alerts,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onEventSelect,
  draggable,
  scrollable,
  onDrop,
  handleDetailClick,
}) => {
  const { t, ready } = useTranslation(['monitor.issues'], {
    useSuspense: false,
  });

  const sortedDirection = (metric) => (metric === sortMetric ? sortDirection : '');

  const cellValueMapFn = useCallback((alert) => {
    if (!alert) {
      return {};
    }

    const { id, caseId, type, info: description, startDate, endDate } = alert;

    return {
      [CaseAlertsColumns.TYPE]: type,
      [CaseAlertsColumns.CODE]: id,
      [CaseAlertsColumns.DESCRIPTION]: description,
      [CaseAlertsColumns.DURATION]: startDate && getDuration(startDate, endDate).formatted,
      [CaseAlertsColumns.START]: startDate,
      [CaseAlertsColumns.END]: endDate,
      [CaseAlertsColumns.SENSOR_READINGS]: { id, caseId },
    };
  }, []);

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case CaseAlertsColumns.START:
        case CaseAlertsColumns.END:
          return (
            <StyledTimeDate>
              <span>{dayjs(cellValue).format('HH:mm')}</span>
              <span>{dayjs(cellValue).format('DD MMM YYYY')}</span>
            </StyledTimeDate>
          );
        case CaseAlertsColumns.TYPE:
          return <StyledTypeIcon icon={Icons.ANOMALY} />;
        case CaseAlertsColumns.SENSOR_READINGS:
          return (
            <>
              <button type="button" onClick={() => handleDetailClick(cellValue)}>
                <Chevron />
              </button>
            </>
          );
        default:
          return null;
      }
    },
    [handleDetailClick],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: CaseAlertsColumnDefs,
    cellValueMapFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
  });

  const dynamicProps = {
    scrollable,
    compressed: true,
    transparent: true,
    noTitles: true,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: alerts,
    onValueSelect: onEventSelect,
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
  };

  if (!ready) {
    return <Loader />;
  }

  return React.createElement(draggable ? DraggableTable : DynamicTable, dynamicProps);
};

CaseAlertsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  alerts: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onEventSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  handleDetailClick: PropTypes.func,
};

CaseAlertsTable.defaultProps = {
  columns: defaultCaseAlertsCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onEventSelect: () => null,
  onDrop: () => null,
  scrollable: false,
  draggable: false,
  handleDetailClick: () => {},
};

export default CaseAlertsTable;
