import { PropTypes } from 'prop-types';
import * as type from 'ramda/src/type';
import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import styled, { css, keyframes, withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { CommonLocators } from '@ge/models/data-locators';
import { elevations } from '@ge/tokens/elevations';
import { getGridIncrement, roundNumber } from '@ge/util';

import { DataLoader } from '../../data-loader';
import { ChartType } from '../models';

import { ParetoChartProvider, ParetoChartContext } from './context';

const animations = {
  collapse: (top) => () => keyframes`
    from {
      top: 0;
    }
    to {
      top: ${top};
    }
  `,
  expand: (top) => () => keyframes`
    from {
      top: ${top};
    }
    to {
      top: 0;
    }
  `,
  fadeIn: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
};

const footerHeight = 22;
const gridIncrements = [1, 2, 2.5, 5];
const gridLineAmount = 5;

const getTotal = (_total, data) => {
  if (data[0]?.value) {
    const totalValue = data.reduce((acc, { value }) => {
      if (value > 0) {
        return acc + value;
      } else {
        return acc;
      }
    }, 0);
    return roundNumber(totalValue);
  } else if (_total) {
    return _total;
  } else {
    return null;
  }
};

const getBars = ({ data, parentTotal, dynamicScaling, total, displayPercValue }) => {
  if (!(data && data.length)) {
    return [];
  }

  return data.map((d, i) => {
    let id = d.id;

    if (!id) {
      id = isNaN(d.type)
        ? d.type
            .trim()
            .toLocaleLowerCase()
            .replace(/\s+/g, '-')
        : d.type;
    }

    const getChildPct = (value) => (value / parentTotal) * 100;
    const getBarPercentage = (value, kpiValue) =>
      dynamicScaling
        ? (value / total) * 100
        : parentTotal
        ? getChildPct(kpiValue)
        : (value / total) * 100;

    return {
      title: d.title,
      kpiValue: d.kpiValue,
      width: getBarPercentage(d.value, d.kpiValue),
      offset: data
        .slice(0, i)
        .reduce(
          (acc, item) =>
            (dynamicScaling
              ? (item.value / total) * 100
              : getBarPercentage(item.value, item.kpiValue)) + acc,
          0,
        ),
      label: parentTotal
        ? `${roundNumber(getChildPct(d.kpiValue))}%`
        : displayPercValue
        ? `${roundNumber((d.value / total) * 100)}%`
        : `${roundNumber(d.value)}%`,

      children: d.details,
      id,
      expandable: !parentTotal && d?.details && d?.details.length ? true : false,
      pColor: d?.color || null,
    };
  });
};

const getGrid = (increment = 100, total = 1000) => {
  const grid = [];
  const width = roundNumber((increment / total) * 100);

  for (let i = 0; i <= total; i += increment) {
    grid.push({
      width,
      label: i,
    });
  }

  return grid;
};

const handleClick = ({ nativeEvent }, handler) => {
  nativeEvent && nativeEvent.stopImmediatePropagation();

  handler();
};

// are we already handling aria stuff somewhere else?
// if we ever want to add keyboard interactions, we can use tabIndex: 0 here
const ariaExpandable = ({ expanded }) => ({
  'aria-expanded': expanded,
  role: 'button',
});

const Expandable = styled.div.attrs(ariaExpandable)`
  cursor: pointer;
`;
Expandable.propTypes = { expanded: PropTypes.bool };
Expandable.defaultProps = { expanded: false };

const ChartLabelToggleIcon = styled(Icon).attrs(({ theme, expanded }) => ({
  color: theme.charts.pareto.barToggleIconColor,
  icon: Icons.TRAY_EXPANDABLE,
  rotate: expanded ? 0 : 270, // TODO: clean up svg so this is intuitive
  size: 8,
}))`
  margin-right: 4px;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Grid = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  padding-right: ${({ scale }) => 100 - scale}%;
  position: absolute;
  width: 100%;
`;

const GridLineContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 0 75%;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

const GridLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.charts.gridLineColor};
  box-sizing: border-box;
  display: flex;
  flex: 1 0 ${({ width }) => width}%;
  flex-flow: column nowrap;
  justify-content: flex-end;
  opacity: 0.6;
`;

const GridOffset = styled.div`
  flex: 1 25%;
`;

const GridLabel = styled.span`
  color: ${({ theme }) => theme.charts.axisTitleColor};
  font-size: 11px;
  padding-left: 4px;
`;

const ChartBodyContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: ${({ scrollHeight }) => scrollHeight};
  padding-bottom: 4px;
  padding-right: ${100 - (({ scale }) => scale)}%;
  position: relative;
`;

const ScrollingChartBody = styled.div`
  display: flex;
  height: ${({ height }) => height};
`;

const ChartBody = ({ scale, scrollHeight, height, children }) => (
  <ScrollingChartBody height={height}>
    <ScrollingContainer>
      <ChartBodyContainer scrollHeight={scrollHeight} scale={scale}>
        {children}
      </ChartBodyContainer>
    </ScrollingContainer>
  </ScrollingChartBody>
);

ChartBody.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  scrollHeight: PropTypes.string.isRequired,
};

const parentLabelCss = css`
  cursor: auto;
  > span {
    font-weight: normal;
    margin-left: 24px;
  }
`;

const ChartLabelContainer = styled(Expandable)`
  box-sizing: border-box;
  flex: 1 0 25%;
  overflow-x: hidden;
  padding-right: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #c0c0c0;
  > span {
    font-weight: 100;
  }
  ${(props) => (props.parent ? parentLabelCss : null)}
`;

const ChartLabel = ({ id, label, expanded, expandable, parent, onExpand = () => {} }) => {
  const _expandable = expandable && !parent;
  const _onExpand = _expandable ? onExpand : () => {};

  return (
    <ChartLabelContainer
      parent={parent}
      title={_expandable ? null : label}
      onClick={(event) => handleClick(event, () => _onExpand(id))}
    >
      <span id={id} data-testid={CommonLocators.COMMON_PARETO_LABEL.concat(id)}>
        {_expandable && <ChartLabelToggleIcon expanded={expanded} />}
        {label}
      </span>
    </ChartLabelContainer>
  );
};

ChartLabel.propTypes = {
  expandable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  expanded: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
  parent: PropTypes.bool,
};

ChartLabel.defaultProps = {
  expanded: false,
  parent: false,
};

const ChartBarContainer = styled.div`
  flex: 1 0 75%;
  height: 16px;
`;

const StyledChartBar = styled(Expandable)`
  left: ${({ width, offset }) => (width < 0 ? offset + width : offset)}%;
  width: ${({ width }) => (width < 0 ? Math.abs(width) : width)}%;
  height: 100%;
  position: relative;
  > .bar {
    background: ${({ color }) => color};
    border-radius: 2px;
    height: 100%;
  }
  > .label {
    left: 100%;
    padding-left: 8px;
    top: -18px;
  }
  ${({ parent }) =>
    parent
      ? css`
          cursor: auto;
          > .bar {
            background-color: ${({ selected, width, theme }) => {
              if (width < 0) return theme.charts.pareto.dangerColor;
              if (selected) return theme.charts.pareto.selectedColor;
              return theme.charts.pareto.nestedColor;
            }};
            border-radius: 4px;
            height: 6px;
            margin: 5px 0;
          }
          > .label {
            top: -19px !important;
          }
        `
      : null}
`;

const getBarColor = ({ index, width, selected, theme }) => {
  const { selectedColor, dangerColor, colors } = theme.charts.pareto;
  if (width < 0) return dangerColor;
  if (selected) return selectedColor;
  return colors[index % colors.length];
};

const ChartBar = withTheme(
  ({
    // using children as a place to drop in a tooltip/hover
    children,
    index,
    id,
    label,
    width,
    offset,
    selected,
    expanded,
    expandable,
    parent,
    theme,
    onExpand,
    onMouseEnter,
    onMouseLeave,
    pColor,
  }) => {
    const _expandable = expandable && !parent;
    const _onExpand = _expandable ? onExpand : () => {};
    const _width = expanded ? 100 : width;
    const _offset = expanded ? 0 : offset;

    // repeat if we run out of colors
    const color = pColor ? pColor : getBarColor({ index, width, selected, theme });

    const { setBarIndex } = useContext(ParetoChartContext);

    const handleMouseEnter = useCallback(
      (event, index) => {
        setBarIndex(index);
        onMouseEnter(event);
      },
      [setBarIndex, onMouseEnter],
    );

    return (
      <ChartBarContainer>
        <StyledChartBar
          width={_width}
          offset={_offset}
          expanded={expanded}
          onClick={(event) => handleClick(event, () => _onExpand(id))}
          onMouseEnter={(event) => handleMouseEnter(event, index)}
          onMouseLeave={onMouseLeave}
          parent={parent}
          color={color}
          selected={selected}
        >
          <div data-testid={CommonLocators.COMMON_PARETO_COLOUR_BAR} className="bar" />
          <span
            data-testid={CommonLocators.COMMON_PARETO_BAR_PERCENT}
            className="label"
            aria-labelledby={id}
          >
            {label}
          </span>
          {children}
        </StyledChartBar>
      </ChartBarContainer>
    );
  },
);

ChartBar.propTypes = {
  children: PropTypes.node,
  colors: PropTypes.arrayOf(PropTypes.string),
  expandable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  expanded: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
  onExpand: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  parent: PropTypes.bool,
  width: PropTypes.number.isRequired,
};

ChartBar.defaultProps = {
  children: null,
  expanded: undefined,
  onExpand: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  parent: undefined,
};

const StyledChartRow = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  right: ${({ right }) => right}%;
  align-items: center;
  flex-flow: row nowrap;
  left: 0;
  position: absolute;
  z-index: ${elevations.P1};
  ${(props) =>
    props.expanded || props.collapsed
      ? css`
          animation: ${props.expanded
              ? animations.expand(props.top)
              : animations.collapse(props.top)}
            0.5s forwards;
        `
      : `
      top: ${props.collapsed ? 0 : props.top};
    `}
  &.childRow {
    animation: ${animations.fadeIn} 0.6s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    .bar {
      cursor: ${({ lv5Hover }) => (lv5Hover ? 'pointer' : 'default')};
    }
  }
  span {
    font-size: 11px;
    position: relative;
    top: -2px;
  }
`;

const ChartRow = ({
  barChildren,
  height,
  scale,
  index,
  id,
  title,
  width,
  offset,
  label,
  expandable,
  expanded,
  collapsed,
  visible,
  parent,
  onBarEnter,
  onBarLeave,
  onExpand,
  isChild,
  lv5Hover,
  selected,
  pColor,
}) => {
  const [active, setActive] = useState(false);

  const topOffset = parent ? height : 0;
  const top = `${topOffset + roundNumber(index * height)}px`;

  const handleMouseEnter = (event) => {
    setActive(true);

    onBarEnter(event, id, isChild);
  };

  const handleMouseLeave = (event) => {
    setActive(false);

    onBarLeave(event, id, isChild);
  };

  return (
    <StyledChartRow
      key={id}
      visible={visible}
      right={100 - scale}
      expanded={expanded}
      collapsed={collapsed}
      top={top}
      lv5Hover={lv5Hover}
      selected={selected}
      className={!parent ? 'parentRow' : 'childRow'}
      data-testid={CommonLocators.COMMON_PARETO_ROW.concat(!parent ? 'iec-level' : 'oem-level')}
    >
      <ChartLabel
        id={id}
        label={title}
        expanded={expanded}
        expandable={expandable}
        parent={parent}
        onExpand={onExpand}
      />
      <ChartBar
        index={index}
        id={id}
        label={label}
        width={width}
        offset={offset}
        selected={selected}
        expanded={expanded}
        expandable={expandable}
        parent={parent}
        onExpand={onExpand}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        pColor={pColor}
      >
        {active && barChildren}
      </ChartBar>
    </StyledChartRow>
  );
};

ChartRow.displayName = 'ChartRow';

ChartRow.propTypes = {
  barChildren: PropTypes.node,
  collapsed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  expandable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  expanded: PropTypes.bool,
  height: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
  onBarEnter: PropTypes.func,
  onBarLeave: PropTypes.func,
  onExpand: PropTypes.func,
  parent: PropTypes.bool,
  scale: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  isChild: PropTypes.bool,
  lv5Hover: PropTypes.bool,
  selected: PropTypes.bool,
  pColor: PropTypes.string,
};

ChartRow.defaultProps = {
  barChildren: null,
  collapsed: undefined,
  expanded: undefined,
  onBarEnter: () => {},
  onBarLeave: () => {},
  onExpand: () => {},
  parent: undefined,
  isChild: false,
  lv5Hover: false,
  selected: false,
};

const ChartFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.charts.gridLineColor};
  height: ${footerHeight}px;
`;

export const ParetoChart = ({
  barChildren,
  data,
  isLoading,
  total: _total,
  height,
  rowHeight,
  chartScalePercent,
  onExpand,
  onHover,
  onTotal,
  className,
  dynamicScaling,
  displayPercValue,
  lv5Hover,
  selected,
  isOem,
}) => {
  const total = getTotal(_total, data);
  const gridIncrement = getGridIncrement({
    gridLineAmount,
    increments: gridIncrements,
    total,
    dynamicScaling,
  });
  const grid = useMemo(() => getGrid(gridIncrement, total), [gridIncrement, total]);
  const bars = useMemo(() => getBars({ data, dynamicScaling, total, displayPercValue }), [
    data,
    dynamicScaling,
    total,
    displayPercValue,
  ]);
  const [scrollHeight, setScrollHeight] = useState('0px');
  const [childBars, setChildBars] = useState([]);
  const [expanded, setExpanded] = useState(null);

  const isExpanded = (id) => expanded && expanded.id === id;

  const handleExpand = (id, children, kpiValue) => {
    const _isExpanded = isExpanded(id);
    setExpanded(
      isExpanded(id)
        ? { previous: expanded && expanded.id }
        : { id, children, parentKpiValue: kpiValue },
    );

    // notify parent of expanded state
    onExpand(_isExpanded ? null : id);
  };

  const handleBarEnter = ({ currentTarget: element }, id, isChild) =>
    onHover({ active: true, element, id, isChild });
  const handleBarLeave = ({ currentTarget: element }, id, isChild) =>
    onHover({ active: false, element, id, isChild });

  const chartBodyHeight = `${height - footerHeight}px`;

  useEffect(() => {
    if (!(expanded && expanded.children && expanded.children.length)) {
      setChildBars([]);
      setScrollHeight(`${roundNumber(data.length * rowHeight)}px`);

      return;
    }

    const childBars = expanded.children;

    // can cache child bars if performance becomes a concern
    setChildBars(getBars({ data: childBars, parentTotal: expanded?.parentKpiValue }));
    // sets height in scrolling container when nested data expanded and includes height of parent row at the top
    setScrollHeight(`${roundNumber(rowHeight + childBars.length * rowHeight)}px`);
  }, [data.length, expanded, rowHeight, total]);

  useEffect(() => {
    if (type(onTotal) === 'Function') {
      onTotal(total);
    }
  }, [onTotal, total]);

  useEffect(() => {
    // Collapse rows if data changes.
    setExpanded(null);
  }, [data]);

  return (
    <ParetoChartProvider>
      <ChartContainer
        className={className}
        data-testid={CommonLocators.COMMON_PARETO_DATA_CONTAINER}
      >
        <Grid scale={chartScalePercent}>
          <GridOffset />
          <GridLineContainer>
            {grid.map(({ label, width }) => (
              <GridLine key={label} width={width}>
                <GridLabel data-testid={CommonLocators.COMMON_PARETO_XAXIS.concat(label)}>
                  {label}
                </GridLabel>
              </GridLine>
            ))}
          </GridLineContainer>
        </Grid>
        <DataLoader
          isLoading={isLoading}
          height={chartBodyHeight}
          type={ChartType.PARETO}
          renderCondition={true}
        >
          <ChartBody scale={chartScalePercent} height={chartBodyHeight} scrollHeight={scrollHeight}>
            {bars.map(
              ({ id, title, width, offset, label, expandable, children, kpiValue, pColor }, i) => (
                <ChartRow
                  barChildren={barChildren}
                  key={id}
                  index={i}
                  scale={chartScalePercent}
                  height={rowHeight}
                  id={id}
                  title={title}
                  width={width}
                  offset={offset}
                  label={label}
                  selected={selected}
                  expanded={isExpanded(id)}
                  collapsed={expanded && expanded.previous && expanded.previous === id}
                  expandable={expandable}
                  visible={expanded == null || expanded.id == null || isExpanded(id)}
                  onExpand={() => handleExpand(id, children, kpiValue)}
                  onBarEnter={handleBarEnter}
                  onBarLeave={handleBarLeave}
                  pColor={pColor}
                  isChild={isOem}
                />
              ),
            )}
            {childBars.map(({ expandable, id, label, offset, title, width }, i) => (
              <ChartRow
                barChildren={barChildren}
                expandable={expandable}
                height={rowHeight}
                id={id}
                index={i}
                key={title}
                label={label}
                offset={offset}
                onBarEnter={handleBarEnter}
                onBarLeave={handleBarLeave}
                parent={true}
                scale={chartScalePercent}
                title={title}
                visible={true}
                width={width}
                isChild
                lv5Hover={lv5Hover}
                selected={selected}
              />
            ))}
          </ChartBody>
        </DataLoader>
        <ChartFooter />
      </ChartContainer>
    </ParetoChartProvider>
  );
};

const dataShape = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  value: PropTypes.number,
};

export const paretoChartPropTypes = {
  barChildren: PropTypes.node,
  total: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ...dataShape,
      details: PropTypes.arrayOf(PropTypes.shape(dataShape)),
    }).isRequired,
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  height: PropTypes.number,
  onHover: PropTypes.func,
  onExpand: PropTypes.func,
  rowHeight: PropTypes.number,
  /** scale sets how much space is left on the right for displaying labels */
  chartScalePercent: PropTypes.number,
  onTotal: PropTypes.func,
  className: PropTypes.string,
  dynamicScaling: PropTypes.bool,
  displayPercValue: PropTypes.bool,
  ParetoChart: PropTypes.bool,
  lv5Hover: PropTypes.bool,
  selected: PropTypes.bool,
  isOem: PropTypes.bool,
};

ParetoChart.propTypes = paretoChartPropTypes;

ParetoChart.defaultProps = {
  barChildren: null,
  chartScalePercent: 90,
  height: 240,
  onExpand: () => {},
  onHover: () => {},
  rowHeight: 26,
  total: 1000,
  dynamicScaling: false,
  displayPercValue: false,
  lv5Hover: false,
  selected: false,
};
