import { ChartType } from './chart-type';

export const scatterChartOptions = {
  boost: {
    debug: {
      showSkipSummary: false,
      timeBufferCopy: false,
      timeKDTree: false,
      timeRendering: false,
      timeSeriesProcessing: false,
      timeSetup: false,
    },
  },
  series: [
    {
      marker: {
        enabled: true,
        radius: 2,
        symbol: 'circle',
      },
      type: ChartType.SCATTER,
    },
  ],
};
