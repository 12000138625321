import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { KpiCategoryDefs } from '@ge/models/constants';
import { mediaQueries } from '@ge/tokens';

import { RegionSiteCard } from '../region/region-site-card';

const AssetCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  ${mediaQueries('lg')`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${mediaQueries('xlg')`
    grid-template-columns: repeat(5, 1fr);
  `}
`;

const SitesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// TODO: unhardcode kpi categories in here
export const AssetsGrid = ({ assetData, onSelectAsset, onSelectAssetTitle }) => (
  <SitesContainer>
    <AssetCard>
      {Boolean(assetData.length) &&
        assetData.map((d) => (
          // TODO: Genericize this or duplicate it?
          <RegionSiteCard
            key={d.site.id}
            id={d.site.id}
            title={d.site.name}
            kpi={d.kpi}
            gauge={d.gauge}
            assetCount={d.assetCount}
            offline={d.offline}
            danger={
              d.gauge?.[KpiCategoryDefs.CUMULATIVE_PRODUCTION]?.value <
                d.gauge?.[KpiCategoryDefs.CUMULATIVE_PRODUCTION]?.threshold ||
              d.gauge?.[KpiCategoryDefs.TBA]?.value < d.gauge?.[KpiCategoryDefs.TBA]?.threshold
            }
            onSelect={() => onSelectAsset(d.site)}
            onTitleClick={(e) => onSelectAssetTitle(e, d.site.id)}
          />
        ))}
    </AssetCard>
  </SitesContainer>
);

AssetsGrid.propTypes = {
  assetData: PropTypes.instanceOf(Object),
  onSelectAsset: PropTypes.func,
  onSelectAssetTitle: PropTypes.func,
};

AssetsGrid.defaultProps = {
  assetData: [],
  onSelectAsset: () => {},
  onSelectAssetTitle: () => {},
};
