import de from 'date-fns/locale/de';
import pt from 'date-fns/locale/pt';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import TimePicker from 'react-time-picker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { Placements } from '@ge/models/constants';
import { CommonLocators } from '@ge/models/data-locators';
import { elevations } from '@ge/tokens/elevations';

const CalendarContainer = styled.div`
  /* container styles */
  .react-datepicker-popper {
    z-index: ${elevations.P2};
  }
  .react-datepicker-popper .react-datepicker__triangle,
  .react-datepicker-popper .react-datepicker__triangle::before {
    display: none;
  }
  .react-datepicker {
    border: 0;
    background: ${(props) => props.theme.datePicker.backgroundColor};
    box-shadow: ${(props) => (props.inline ? 'none' : '3px 3px 10px 2px rgba(0, 0, 0, 0.16)')};
  }

  /* Header Styles */
  .react-datepicker__header {
    background: ${(props) => props.theme.datePicker.backgroundColor};
    border-bottom: 0;
    .react-datepicker__current-month {
      color: ${(props) => props.theme.datePicker.monthTitleColor};
      font-family: 'Museo Sans';
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
    }
    .react-datepicker__day-names {
      margin-top: 6px;
    }
    .react-datepicker__day-name {
      color: ${(props) => props.theme.datePicker.dayHeaderColor};
      font-family: 'Museo Sans';
      font-weight: 500;
      font-size: 11px;
      width: 20px;
      line-height: 15px;
      text-transform: capitalize;
    }
  }
  /* Chevron Styles */
  .react-datepicker__navigation {
    display: inline-block;
    border: 0;
    width: 8px;
    height: 8px;
    top: 15px;
    border-color: ${(props) => props.theme.datePicker.chevronColor};
    border-style: solid;
    &:focus {
      outline: none;
    }
    &.react-datepicker__navigation--previous {
      border-right-width: 1px;
      border-bottom-width: 1px;
      transform: rotate(-225deg);
      left: 15px;
    }
    &.react-datepicker__navigation--next {
      border-right-width: 1px;
      border-bottom-width: 1px;
      transform: rotate(-45deg);
      right: 15px;
    }
  }

  /* Content Styles */
  .react-datepicker__day--today {
    font-weight: 500;
  }
  .react-datepicker__month {
    margin: 0 2px 2px;
  }
  .react-datepicker__day {
    color: ${(props) => props.theme.datePicker.day.color};
    font-size: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    line-height: 20px;
    margin: 0 0 2px;
    padding: 2.6px;
    &:focus {
      outline: none;
    }
    &:hover {
      border-radius: 20px;
      color: ${(props) => props.theme.datePicker.day.hoverTextColor};
    }
    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
      background: ${(props) => props.theme.datePicker.day.selectedBackground};
      color: ${(props) => props.theme.datePicker.day.selectedColor};
    }
    &.react-datepicker__day--outside-month {
      color: ${(props) => props.theme.datePicker.day.outsideMonthColor};
    }
    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range {
      background: ${(props) => props.theme.datePicker.day.highlightColor};
      border-radius: 0;
      margin: 0 0 2px;
      padding: 2.6px;
      color: ${(props) => props.theme.datePicker.day.highlightTextColor};
      font-weight: 500;
    }

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end {
      background: ${(props) => props.theme.datePicker.day.selectedBackground};
      margin-right: 0;
      position: relative;
      color: ${(props) => props.theme.datePicker.day.selectedColor};
      font-weight: 600;
    }

    &.react-datepicker__day--range-start {
      border-radius: 20px 0 0 20px;
      margin-right: 0;
      &.react-datepicker__day--range-end {
        border-radius: 20px;
      }
    }

    &.react-datepicker__day--range-end {
      border-radius: 0 20px 20px 0;
      margin-left: 0;
    }
  }

  /* time */
  .react-datepicker__input-time-container {
    margin: 5px 0 10px 8px;
  }
  .react-datepicker-time__caption {
    color: ${(props) => props.theme.datePicker.dayHeaderColor};
    font-size: 11px;
  }
  .react-datepicker-time__input > input {
    width: 94px !important;
  }

  .react-datepicker__day--disabled {
    &:hover {
      color: ${(props) => props.theme.datePicker.day.color};
    }
    opacity: 0.5;
  }

  /* month year picker */
  .react-datepicker__month-container {
    .react-datepicker-year-header {
      border-bottom: solid 1px ${(props) => props.theme.datePicker.day.outsideMonthColor};
      font-weight: 400;
      color: ${(props) => props.theme.datePicker.day.color};
      padding: 12px 0;
    }
  }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    color: ${(props) => props.theme.datePicker.day.color};
    border-radius: 20px;
    width: 3rem;
    margin: 4px 7px;
    font-weight: 400;
    text-transform: capitalize;
    &.react-datepicker__month--selected,
    &:hover {
      background: ${(props) => props.theme.datePicker.day.selectedBackground};
      color: ${(props) => props.theme.datePicker.day.selectedColor};
    }
  }
  .react-datepicker__month-read-view,
  .react-datepicker__month-option {
    text-transform: capitalize;
  }
`;

const ApplyButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: ${(props) => props.theme.button.primary.textColor};
  background: ${(props) => props.theme.button.primary.backgroundColor};
  border: ${(props) => props.theme.button.primary.border};
  border-radius: 2px;
  padding: 2px 14px;
  font-family: 'Museo Sans';
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  text-transform: uppercase;
  text-shadow: ${(props) => props.theme.button.primary.textShadow};
  font-weight: ${(props) => props.theme.button.primary.fontWeight};
`;

const StyledFooter = styled.div`
  border-top: 1px solid ${(props) => props.theme.dialog.footerSeparatorColor};
  padding: 0.5em 1em;
  display: block;
  clear: both;
  text-align: center;
`;

const TimeContainer = styled.div`
  background: ${(props) => props.theme.datePicker.time.backgroundColor};
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.5);
  font-style: italic;
  .react-time-picker__wrapper {
    background: transparent;
    border: 0;
    padding: 4px;
    color: ${(props) => props.theme.datePicker.time.inputColor};
    width: 50px;
    input {
      color: ${(props) => props.theme.datePicker.time.inputColor};
    }
  }
  .locale {
    color: ${(props) => props.theme.datePicker.time.inputColor};
    padding: 4px 5px 4px 0px;
  }
`;

const CustomTimeInput = ({ value, onChange, date }) => {
  return (
    <TimeContainer>
      <TimePicker
        onChange={(value) => onChange(value)}
        value={value}
        format="HH:mm"
        disableClock
        clearIcon={null}
        locale="hu-HU"
      />
      {/* Adding current user timezone for now until we know how to handle this */}
      <span className="locale">
        {
          new Date(date)
            .toLocaleTimeString(window.navigator.language, { timeZoneName: 'short' })
            .split(' ')[2]
        }
      </span>
    </TimeContainer>
  );
};

CustomTimeInput.propTypes = {
  value: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.func,
  date: PropTypes.func,
};

CustomTimeInput.propTypes = {
  value: () => {},
  onChange: () => {},
  error: () => {},
  date: () => {},
};

export const DatePicker = forwardRef(
  (
    {
      startDate,
      endDate,
      onChange,
      onCalendarClose,
      customInput,
      selectsRange,
      shouldCloseOnSelect,
      showMonthYearPicker,
      calendarStartDay,
      todayButton,
      showQuarterYearPicker,
      showTimeInput,
      popperPlacement,
      filterDate,
      inline,
      name,
      minDate,
      maxDate,
      onApplyClick,
      className,
      ...props
    },
    ref,
  ) => {
    const { t, ready } = useTranslation([], { useSuspense: false });
    const prefLang = useStoreState((state) => state.prefs.language);
    let defaultLanguage = 'en';

    if (!ready) {
      return null;
    }
    if (prefLang === 'pt-BR') {
      registerLocale('pt', pt);
      defaultLanguage = 'pt';
    } else if (prefLang === 'de') {
      registerLocale('de', de);
      defaultLanguage = 'de';
    } else {
      defaultLanguage = 'en';
    }

    return (
      <CalendarContainer
        className={className}
        data-testid={CommonLocators.COMMON_DATE_PICKER}
        inline={inline}
      >
        <ReactDatePicker
          locale={defaultLanguage}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          onCalendarClose={onCalendarClose}
          customInput={customInput}
          shouldCloseOnSelect={shouldCloseOnSelect}
          showMonthYearPicker={showMonthYearPicker}
          calendarStartDay={calendarStartDay}
          showQuarterYearPicker={showQuarterYearPicker}
          timeInputLabel={`${t('time', 'Time')}:`}
          showTimeInput={showTimeInput}
          customTimeInput={<CustomTimeInput date={startDate} />}
          todayButton={todayButton && t('today', 'TODAY')}
          wrapperClassName="ge-datepicker"
          selectsRange={selectsRange}
          popperPlacement={popperPlacement}
          ref={ref}
          filterDate={filterDate}
          inline={inline}
          name={name}
          maxDate={maxDate}
          minDate={minDate}
          {...props}
        >
          {onApplyClick && (
            <StyledFooter>
              <ApplyButton onClick={onApplyClick}>{t('filters.apply', 'Apply')}</ApplyButton>
            </StyledFooter>
          )}
        </ReactDatePicker>
      </CalendarContainer>
    );
  },
);

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  customInput: PropTypes.element,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onCalendarClose: PropTypes.func,
  selectsRange: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  showQuarterYearPicker: PropTypes.bool,
  todayButton: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  popperPlacement: PropTypes.string,
  filterDate: PropTypes.func,
  inline: PropTypes.bool,
  name: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  calendarStartDay: PropTypes.number,
  onApplyClick: PropTypes.func,
  className: PropTypes.string,
};

DatePicker.defaultProps = {
  customInput: null,
  startDate: null,
  endDate: null,
  selectsRange: false,
  shouldCloseOnSelect: true,
  showMonthYearPicker: false,
  showQuarterYearPicker: false,
  todayButton: false,
  showTimeInput: false,
  popperPlacement: Placements.BOTTOM_START,
  onCalendarClose: () => {},
  filterDate: null,
  inline: false,
  name: null,
  minDate: null,
  maxDate: null,
  calendarStartDay: null,
  onApplyClick: null,
};
