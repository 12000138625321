import axios from 'axios';
import { action, thunkOn, computed } from 'easy-peasy';
import flatten from 'ramda/src/flatten';

// Default state to apply on initialization or reset.
const defaultErrorsState = {
  errors: [],
};

// Actions
const errorsActions = {
  // eslint-disable-next-line no-unused-vars
  resetErrors: action((state) => {
    state = Object.assign(state, defaultErrorsState);
  }),

  addErrors: action((state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((error) =>
        state.errors.push({
          created: new Date(),
          message: error,
        }),
      );
      return;
    }
    state.errors.push({
      created: new Date(),
      message: payload,
    });
  }),
};

// Listeners
const errorsListeners = {
  /**
   * This listener watches for API errors in every thunk state function.
   */
  onCriticalAction: thunkOn(
    (actions, storeActions) =>
      // Loop through all the state models and register the thunks.
      // We're only looking for failures (failType).
      flatten(
        Object.values(storeActions).map((model) =>
          Object.values(model).reduce((memo, thunk) => {
            if (thunk.failType) {
              memo.push(thunk.failType);
            }

            return memo;
          }, []),
        ),
      ),

    // TODO: Commented error dispatch to redux - now only logging to console to suppress alerts (ref PR #744 / #915)
    (actions, payload, { dispatch }) => {
      const { error, type } = payload;
      const { status } = error?.response ?? {};
      const message = error instanceof Error ? error?.response?.statusText ?? error.message : error;
      /**
       * Dispatching logging error to redux or console
       * @param {string} message Error message to be displayed
       * @param {boolean} doDispatch Defaults to `false` - pass `true` to dispatch to redux
       **/
      const reportError = (message, doDispatch = false) => {
        const errorMessage = `${type}: ${message}`;
        if (!doDispatch) {
          console.error(errorMessage);
          return;
        }
        dispatch({ type, payload: error });
        actions.addErrors(errorMessage);
      };

      // TODO (astone): Send this through a log wrapper so we can turn it off for prod?
      console.error(error);

      if (error instanceof axios.Cancel) return reportError(error.message);
      if (status === 403) return reportError(message);
    },
  ),
};

// Computed values
const errorsComputed = {
  getErrors: computed((state) => () => state.errors),
};

const errorsModel = {
  ...defaultErrorsState,
  ...errorsActions,
  ...errorsComputed,
  ...errorsListeners,
};

export default errorsModel;
