import { useMemo } from 'react';

import { getDataFilter, getDataFilterUp } from '@ge/shared/util/data-filter';
import { dedupArray, searchStr } from '@ge/shared/util/general';

/**
 * Get filtered table data.
 *
 * @param data
 * @param filters
 * @param search
 * @returns {data: Array, filterValues: Object}
 */

export const useDlTableFilter = ({ data, filters, search, isTaskTable }) => {
  const { key, value } = search ?? {};
  const { data: filteredData } = useMemo(() => getDataFilter({ data, filters, isTaskTable }), [
    JSON.stringify(data),
    filters,
    isTaskTable,
  ]);
  const { filterValues } = useMemo(() => getDataFilterUp({ data, filters, isTaskTable }), [
    JSON.stringify(data),
    filters,
    isTaskTable,
  ]);
  const searchValues = useMemo(
    () =>
      dedupArray(
        value
          ? data
              ?.map((item) => {
                const itemValue = item[key];

                // TODO: review but right now expecting if cell value is complex that it has a description prop
                // otherwise try to match cell value directly assuming it's text
                return itemValue?.description ?? itemValue?.name ?? itemValue;
              })
              .filter((itemValue) => searchStr(itemValue, value))
          : data,
      ),
    [data, key, value],
  );

  return useMemo(
    () => ({
      data: filteredData,
      filterValues: !(key && value)
        ? filterValues
        : {
            ...filterValues,
            [key]: searchValues,
          },
    }),
    [filterValues, filteredData, key, searchValues, value],
  );
};
