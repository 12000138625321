import { useStoreState } from 'easy-peasy';
import { useEffect, useState } from 'react';

import { useWindTurbines } from './use-wind-turbines';

export const useSiteDetails = ({ siteId }) => {
  const [assets, setAssets] = useState();

  // data hooks
  const { data: assetData, isLoading } = useWindTurbines();

  const isLoaded = Boolean(assetData?.length);

  // store
  const getAssetsBySiteId = useStoreState((state) => state.assets.getAssetsBySiteId);
  const getSiteById = useStoreState((state) => state.sites.getSiteById);

  const site = getSiteById(siteId);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const siteAssets = getAssetsBySiteId(siteId);

    setAssets(siteAssets);
  }, [getAssetsBySiteId, isLoaded, siteId]);

  return {
    data: {
      assets,
      site,
    },
    // we just propagate loading state of assets here but can
    // make this a combination of multiple inputs if needed
    isLoading,
  };
};
