import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import {
  AlertDLType,
  CaseEscalationType,
  TypeToSourceIdDLMapping,
  CaseDLType,
  AlertDLTemplate,
} from '@ge/models/constants';

const CheckboxWrapper = styled.div`
  margin-top: 30px;
  padding-top: 1em;
  font: 300 12px/15px Museo Sans;
  letter-spacing: 0;
  color: ${(props) => props.theme.distributionListSidePanel.checkbox};
  border-top: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};
  border-bottom: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};

  .label-cls {
    color: ${(props) => props.theme.distributionListSidePanel.checkboxContainer};
  }
`;

const CheckboxContainer = styled.div`
  display: grid;
  padding-bottom: 1em;
  grid-template-columns: repeat(3, 1fr);
  color: ${(props) => props.theme.distributionListSidePanel.checkboxContainer};
  .subContainer {
    margin-top: 20px;
    width: max-content;
    .title {
      font: 500 11px/13px Museo Sans;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 10px;
  font: 300 12px/20px Museo Sans;
  color: ${(props) => props.theme.distributionListSidePanel.checkbox};
  width: fit-content;
`;

export const DLCheckboxes = ({ data }) => {
  const { t } = useTranslation(['admin.configure']);
  const [selectAll, setSelectAll] = useState(false);
  const { control, setValue, getValues, trigger } = useFormContext();
  const { fields: caseFields } = useFieldArray({ name: 'cases' });
  const { fields: alertFields } = useFieldArray({ name: 'alerts' });

  useEffect(() => {
    trigger([
      ...caseFields.map(({ type }, index) => `cases[${index}].${type}`),
      ...alertFields.map(({ type }, index) => `alerts[${index}].${type}`),
    ]);

    if (data && data.length) {
      const isBool = { value: true };
      const getIsSelected = (type) => {
        let returnBool = data.find((rec) => rec?.sourceId?.toUpperCase() === type);

        if (!returnBool) {
          isBool.value = false;
        }

        return returnBool;
      };

      setValue('cases', [
        {
          type: CaseEscalationType.FAULTED_ACTIONABLE,
          value: getIsSelected(CaseEscalationType.FAULTED_ACTIONABLE),
        },
        {
          type: CaseEscalationType.FAULTED_INFORMATIONAL,
          value: getIsSelected(CaseEscalationType.FAULTED_INFORMATIONAL),
        },
        {
          type: CaseEscalationType.NOTFAULTED_ACTIONABLE,
          value: getIsSelected(CaseEscalationType.NOTFAULTED_ACTIONABLE),
        },
        {
          type: CaseEscalationType.NOTFAULTED_INFORMATIONAL,
          value: getIsSelected(CaseEscalationType.NOTFAULTED_INFORMATIONAL),
        },
      ]);
      setValue(
        'alerts',
        Object.keys(AlertDLType).map((key) => ({ type: key, value: getIsSelected(key) })),
      );

      setSelectAll(isBool.value);
    }
  }, [data]);

  const selectAllFn = () => {
    let count = 0;

    caseFields.forEach((rec, index) => {
      if (control.getValues(`cases[${index}].${rec.type}`)) {
        count += 1;
      }
    });

    Object.keys(AlertDLType).forEach((key, index) => {
      if (control.getValues(`alerts[${index}].${key}`)) {
        count += 1;
      }
    });

    setSelectAll(count === caseFields.length + alertFields.length);
  };

  const handleSelectAll = (isSelected) => {
    setSelectAll(isSelected);
    setValue('cases', [
      { type: CaseEscalationType.FAULTED_ACTIONABLE, value: isSelected },
      { type: CaseEscalationType.FAULTED_INFORMATIONAL, value: isSelected },
      { type: CaseEscalationType.NOTFAULTED_ACTIONABLE, value: isSelected },
      { type: CaseEscalationType.NOTFAULTED_INFORMATIONAL, value: isSelected },
    ]);
    setValue(
      'alerts',
      Object.keys(AlertDLType).map((key) => ({ type: key, value: isSelected })),
    );
  };

  const ValidateCasesOrAlerts = () => {
    const { cases = [], alerts = [] } = getValues();
    const isValid = [...cases, ...alerts].some((item) => {
      const [, value] = Object.entries(item)[0];
      return value;
    });
    return isValid;
  };

  const handleChange = (onChange, val) => {
    onChange(val);
    trigger([
      ...caseFields.map(({ type }, index) => `cases[${index}].${type}`),
      ...alertFields.map(({ type }, index) => `alerts[${index}].${type}`),
    ]);

    selectAllFn();
  };

  return (
    <CheckboxWrapper>
      <label className="label-cls">{t('templates', 'Templates')}:</label>

      <StyledCheckbox
        onChange={handleSelectAll}
        checkState={selectAll ? CheckedState.CHECKED : CheckedState.UNCHECKED}
        label={t('apply_to_all', 'Apply to All')}
      />
      <CheckboxContainer>
        <div className="subContainer">
          <label className="title">{t('cases', 'Cases')}</label>
          {caseFields.map((field, index) => {
            let label;
            if (
              TypeToSourceIdDLMapping[field.type]?.sourceId === CaseDLType.NOTFAULTED_ACTIONABLE
            ) {
              label = CaseDLType.GENERAL_ACTIONABLE;
            } else if (
              TypeToSourceIdDLMapping[field.type]?.sourceId === CaseDLType.NOTFAULTED_INFORMATIONAL
            ) {
              label = CaseDLType.GENERAL_INFORMATIONAL;
            } else {
              label = TypeToSourceIdDLMapping[field.type]?.sourceId;
            }
            return (
              <Controller
                control={control}
                name={`cases[${index}].${field.type}`}
                key={field.id}
                defaultValue={field.value}
                rules={{
                  validate: ValidateCasesOrAlerts,
                }}
                render={({ onChange, value }) => (
                  <StyledCheckbox
                    onChange={(val) => handleChange(onChange, val)}
                    checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                    label={t(`DL_form_fields.${label}`, label)}
                  />
                )}
              />
            );
          })}
        </div>
        <div className="subContainer">
          <label className="title">{t('alerts', 'Alerts')}</label>
          {alertFields.map((field, index) => {
            let label;
            if (
              TypeToSourceIdDLMapping[field.type]?.sourceId ===
              AlertDLTemplate.TEMPORARY_CONFIG_ACTIONABLE
            ) {
              label = AlertDLTemplate.TEMPORARY_CONFIG_ACTIONABLE;
            } else if (
              TypeToSourceIdDLMapping[field.type]?.sourceId ===
              AlertDLTemplate.TEMPORARY_CONFIG_INFORMATIONAL
            ) {
              label = AlertDLTemplate.TEMPORARY_CONFIG_INFORMATIONAL;
            } else {
              label = TypeToSourceIdDLMapping[field.type]?.sourceId;
            }
            return (
              <Controller
                control={control}
                name={`alerts[${index}].${field.type}`}
                key={field.id}
                defaultValue={field.value}
                rules={{
                  validate: ValidateCasesOrAlerts,
                }}
                render={({ onChange, value }) => (
                  <StyledCheckbox
                    onChange={(val) => handleChange(onChange, val)}
                    checkState={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                    label={t(`DL_form_fields.${label}`, label)}
                  />
                )}
              />
            );
          })}
        </div>
      </CheckboxContainer>
    </CheckboxWrapper>
  );
};

DLCheckboxes.propTypes = {
  data: PropTypes.any,
};
