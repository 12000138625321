import clone from 'ramda/src/clone';
import { useQuery } from 'react-query';

import {
  AlertStatus,
  AlertsEntityType,
  AlertType,
  AssetType,
  InverterType,
} from '@ge/models/constants';
import { QueryKey, AlertStatusCodeMapping, FormMode } from '@ge/models/constants';

import {
  getMonitorSiteAlerts,
  getMonitorAssetAlerts,
  postMonitorAlert,
  editMonitorAlert,
} from '../services/monitor-alerts';

const REAL_TIME_DATA_REFRESH = 30000;
const getAlertsList = async (entityId, assetType) => {
  let data = [];
  try {
    if (assetType === AlertsEntityType.SITE || assetType === AlertsEntityType.STORAGE_SITE) {
      data = await getMonitorSiteAlerts(entityId, AlertsEntityType.SITE);
    } else {
      data = await getMonitorAssetAlerts(entityId, assetType);
    }
  } catch (err) {
    console.error(err);
  }
  return data;
};

const getAssetSubTypeFromAsset = (asset) => {
  switch (asset?.type) {
    case AssetType.STORAGE_INVERTER:
      return InverterType.STORAGE;
    case AssetType.SOLAR_INVERTER:
      return InverterType.SOLAR;
    default:
      return '';
  }
};

const getAssetTypeFromAlertsEntityType = (entityType, asset) => {
  if (entityType === AlertsEntityType.STORAGE_SITE) {
    return AlertsEntityType.SITE;
  }
  if (asset?.type === AssetType.SITE_CONTROLLER) {
    return AlertsEntityType.WIND_SITE_CONTROLLER;
  }
  if (asset?.type === AssetType.SUBSTATION) {
    return AlertsEntityType.SUBSTATION;
  }
  return entityType;
};

const getMappedAlert = ({ alertClone, asset, site, assetId, mode }) => {
  // Incase of Site, this assetId will be ignored by api
  alertClone.assetId = assetId ? assetId : alertClone.entityId;
  alertClone.assetType = getAssetTypeFromAlertsEntityType(alertClone.entityType, asset);
  alertClone.assetSubType = getAssetSubTypeFromAsset(asset);
  alertClone.siteId = asset?.site?.id;
  alertClone.siteName = site?.name;
  alertClone.timeZone = site?.timezone;
  alertClone.assetName = asset?.name;
  // Incase of site, asset param will be null so this if condition
  if (
    alertClone.entityType === AlertsEntityType.SITE ||
    alertClone.entityType === AlertsEntityType.STORAGE_SITE
  ) {
    alertClone.siteId = alertClone.entityId;
    alertClone.assetName = 'All';
  }
  if (mode === FormMode.EDIT) {
    if (alertClone.status === AlertStatus.CLOSED) {
      alertClone.status = AlertStatusCodeMapping.CLOSED;
    } else {
      delete alertClone.status;
    }
  } else {
    alertClone.status = AlertStatusCodeMapping.OPEN;
  }
  // removing unwanted props
  delete alertClone.entityId;
  delete alertClone.entityType;
  delete alertClone.selectedAssets;
  delete alertClone.mail_recipient_text_field;
  delete alertClone.attachments;
  delete alertClone.to;
  delete alertClone.cc;
  delete alertClone.bcc;
  delete alertClone.replyTo;
  delete alertClone.groupDetails;
  delete alertClone.applyToAll;
  delete alertClone.actionable_to;
  delete alertClone.actionable_cc;
  delete alertClone.actionable_bcc;
  delete alertClone.attachments_actionable;
  delete alertClone.informational_to;
  delete alertClone.informational_cc;
  delete alertClone.informational_bcc;
  delete alertClone.attachments_informational;
  delete alertClone.is_actionable_checked;
  delete alertClone.is_informational_checked;
  delete alertClone.actionable_reply_to;
  delete alertClone.informational_reply_to;
  delete alertClone.is_send_notification_checked;

  return alertClone;
};

const saveAlert = async (alert, site, asset, selectedAssetDetails = {}) => {
  let alertClone = clone(alert);
  const alerts = [];
  try {
    const selectedAssets = Object.keys(selectedAssetDetails);
    if (selectedAssets.length) {
      selectedAssets.forEach((assetId) => {
        alertClone = clone(alert);
        const mappedAlert = getMappedAlert({
          alertClone,
          asset: selectedAssetDetails[assetId],
          site,
          assetId,
        });
        alerts.push(mappedAlert);
      });
    } else {
      const mappedAlert = getMappedAlert({ alertClone, asset, site });
      alerts.push(mappedAlert);
    }
    const alertReqData =
      alert.type === AlertType.TEMPORARY_CONFIG_CHANGE
        ? {
            actionable: {
              to: alert?.is_actionable_checked ? alert?.actionable_to : '',
              bcc: alert?.is_actionable_checked ? alert?.actionable_bcc : '',
              cc: alert?.is_actionable_checked ? alert?.actionable_cc : '',
              attachments: alert?.attachments_actionable,
              replyTo: alert?.actionable_reply_to,
            },
            informational: {
              to: alert?.is_informational_checked ? alert?.informational_to : '',
              bcc: alert?.is_informational_checked ? alert?.informational_bcc : '',
              cc: alert?.is_informational_checked ? alert?.informational_cc : '',
              attachments: alert?.attachments_informational,
              replyTo: alert?.informational_reply_to,
            },
            alerts,
            isBulk: selectedAssets?.length > 1,
          }
        : {
            attachments: alert?.attachments,
            mails: {
              to: alert?.is_send_notification_checked ? alert?.to : '',
              bcc: alert?.is_send_notification_checked ? alert?.bcc : '',
              cc: alert?.is_send_notification_checked ? alert?.cc : '',
              replyTo: alert?.replyTo,
            },
            alerts,
            isBulk: selectedAssets?.length > 1,
          };
    return await postMonitorAlert(alertReqData);
  } catch (err) {
    throw new Error(err);
  }
};

const editAlert = async (alert, site, asset, selectedAssetDetails) => {
  let alertClone = clone(alert);
  try {
    const alerts = [];
    const mappedAlert = getMappedAlert({ alertClone, asset, site, mode: FormMode.EDIT });
    alerts.push(mappedAlert);

    // Apply bulk patch only if status changed to close and applyToAll checkbox selected
    if (alert?.applyToAll && alert.status === AlertStatus.CLOSED && selectedAssetDetails) {
      const selectedAssets = Object.keys(selectedAssetDetails);
      selectedAssets.forEach((assetId) => {
        const mappedAlert = {
          id: selectedAssetDetails[assetId]?.alertId,
          type: alert?.type,
          status: AlertStatusCodeMapping.CLOSED,
        };
        alerts.push(mappedAlert);
      });
    }
    const alertReqData =
      alert.type === AlertType.TEMPORARY_CONFIG_CHANGE
        ? {
            actionable: {
              to: alert?.is_actionable_checked ? alert?.actionable_to : '',
              bcc: alert?.is_actionable_checked ? alert?.actionable_bcc : '',
              cc: alert?.is_actionable_checked ? alert?.actionable_cc : '',
              attachments: alert?.attachments_actionable,
              replyTo: alert?.actionable_reply_to,
            },
            informational: {
              to: alert?.is_informational_checked ? alert?.informational_to : '',
              bcc: alert?.is_informational_checked ? alert?.informational_bcc : '',
              cc: alert?.is_informational_checked ? alert?.informational_cc : '',
              attachments: alert?.attachments_informational,
              replyTo: alert?.informational_reply_to,
            },
            alerts,
            isBulk: alerts?.length > 1,
          }
        : {
            attachments: alert?.attachments,
            mails: {
              to: alert?.is_send_notification_checked ? alert?.to : '',
              bcc: alert?.is_send_notification_checked ? alert?.bcc : '',
              cc: alert?.is_send_notification_checked ? alert?.cc : '',
              replyTo: alert?.replyTo,
            },
            alerts,
            isBulk: alerts?.length > 1,
          };

    return await editMonitorAlert(alertReqData);
  } catch (err) {
    throw new Error(err);
  }
};

// REVIST: Adding a temporary enabled flag for this hook
// so that uncessary calls are avoided from action-menu component
export const useAlertsHeader = ({ entityId, entityType }, enabled = true) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.MONITOR_ALERTS, entityType, entityId],
    () => getAlertsList(entityId, entityType),
    {
      refetchOnWindowFocus: false,
      refetchInterval: REAL_TIME_DATA_REFRESH,
      enabled,
      select: (data) => {
        const alerts = Array.isArray(data?.alerts)
          ? data?.alerts?.map((elem) => ({
              ...elem,
              ...elem.typeProperties,
              id: elem._id,
              status:
                elem.status !== AlertStatusCodeMapping.CLOSED
                  ? AlertStatus.OPEN
                  : AlertStatus.CLOSED,
              isExpired: elem.status === AlertStatusCodeMapping.EXPIRED,
              isGroup: !!elem?.groupDetails?.groupId,
            }))
          : [];

        return { ...data, alerts };
      },
    },
  );

  // Incase Storage Site/Inverter receives invalid alerts
  let filteredAlerts = [];
  if (entityType === AlertsEntityType.STORAGE_SITE || entityType === AlertsEntityType.INVERTER) {
    filteredAlerts = data?.alerts?.filter(
      (elem) =>
        elem?.type === AlertType.PLANNED_OUTAGE || elem?.type === AlertType.UNPLANNED_OUTAGE,
    );
  } else {
    filteredAlerts = data?.alerts;
  }
  return {
    data: filteredAlerts,
    groupDetails: data?.groupDetails,
    error,
    isLoading,
    save: {},
    saveAsync: saveAlert,
    editAsync: editAlert,
  };
};
