import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Loader } from '@ge/components/loader';
import { Menu } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { placements } from '@ge/components/tooltip';
import { DateTimeFormats } from '@ge/models/constants';
import { getDate, killEventPropagation } from '@ge/shared/util';

import { ReportWidgetTable } from '../../tables/report-widget-table';

import { openCasesColumnDefs, OpenCasesColumns } from './open-cases-cols';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const NoteContainer = styled.div`
  overflow-wrap: normal;
  white-space: normal;
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.completedWork.includeTextColor};
`;

const NotesHeader = styled.div`
  min-width: 125px;
`;

const HoverText = styled.div`
  padding: 8px 15px;
  max-width: 200px;
  word-break: break-all;
  hyphens: auto;
`;

const Seperator = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 253px;
  border: 1px solid #252c33;
  opacity: 0.5;
`;

const StatusDetailTitle = styled.div`
  padding-left: 10px;
  height: 20px;
  padding-top: 10px;
  width: 244px;
  border-radius: 5px 5px 0 0;
  background-color: #5a6978;
`;

const StatusDetailFooter = styled.div`
  padding-left: 10px;
  height: 20px;
  padding-top: 10px;
  width: 244px;
  border-radius: 5px 5px 0 0;
  color: #b3b3b3;
`;

const PriorityLabel = styled.span`
  padding-left: 5px;
`;

const ColumnHover = ({ children, value, header, footer }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  if (!value) return null;

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        placement={placements.TOP_START}
      >
        {header}
        <HoverText>{value}</HoverText>
        {footer}
      </Menu>
    </div>
  );
};

ColumnHover.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export const OpenCasesWidget = ({
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
}) => {
  const { t, ready } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(data.map((row) => row?.case?.id));
  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom table headers for provided columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case OpenCasesColumns.SELECTED:
          return <IncludeHeader>{t('completed_work.columns.include')}</IncludeHeader>;
        case OpenCasesColumns.NOTES_LATEST_NOTE:
          return <NotesHeader>{t('completed_work.columns.latest_note')}</NotesHeader>;
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case OpenCasesColumns.SELECTED:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case OpenCasesColumns.CASE_PRIORITY:
          return cellValue && cellValue !== null ? (
            <NoteContainer>
              <Severity level={cellValue} />
              <PriorityLabel>{t(`cases.priorities.${cellValue.toLowerCase()}`)}</PriorityLabel>
            </NoteContainer>
          ) : (
            ''
          );
        case OpenCasesColumns.CASE_DESCRIPTION:
          return <NoteContainer>{cellValue.description}</NoteContainer>;
        case OpenCasesColumns.STATUS_DETAIL:
          return (
            <ColumnHover
              value={cellValue?.description}
              header={<StatusDetailTitle>{t('status_detail', 'STATUS DETAIL')}</StatusDetailTitle>}
              footer={
                <>
                  <Seperator />
                  <StatusDetailFooter>
                    {t('lastUpdatedOn', 'Last Updated On')}:{' '}
                    {dayjs(cellValue?.value * 1000).format(DateTimeFormats.TASKS_TABLE_DATE_TIME)}
                  </StatusDetailFooter>
                </>
              }
            >
              <NoteContainer>{cellValue?.description}</NoteContainer>
            </ColumnHover>
          );
        case OpenCasesColumns.NOTES_LATEST_NOTE:
          return <NoteContainer>{cellValue}</NoteContainer>;
        default:
          return cellValue;
      }
    },
    [rowsSelected, t, toggleCheckedRow],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback(
    (caseData) => {
      return {
        [OpenCasesColumns.SELECTED]: caseData?.case?.id,
        [OpenCasesColumns.ASSET_NAME]: caseData?.asset?.name,
        [OpenCasesColumns.CASE_PRIORITY]: caseData?.case?.priority,
        [OpenCasesColumns.CASE_STATUS]: caseData?.case?.status,
        [OpenCasesColumns.CASE_TITLE]: caseData?.case?.title,
        [OpenCasesColumns.SOURCE]: caseData.case?.source,
        [OpenCasesColumns.CASE_DESCRIPTION]: caseData?.case?.description,
        [OpenCasesColumns.STATUS_DETAIL]: {
          value: caseData.case?.statusDetails?.lastUpdatedDate,
          description: caseData.case?.statusDetails?.statusDetail,
        },
        [OpenCasesColumns.DATES_CREATED]:
          caseData.dates?.createdDate && getDate(caseData.dates?.createdDate),
        [OpenCasesColumns.DATES_LAST_FLAGGED]:
          caseData.dates?.lastFlagged && getDate(caseData.dates?.lastFlagged),
        [OpenCasesColumns.DATES_CLOSED]:
          caseData.dates?.closedDate && getDate(caseData.dates?.closedDate),
        [OpenCasesColumns.DATES_FIXED]:
          caseData.dates?.fixedDate && getDate(caseData.dates?.fixedDate),
        [OpenCasesColumns.NOTES_LATEST_NOTE]: caseData?.notes?.latest,
      };
    },
    [t],
  );

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: openCasesColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  if (!ready) {
    return <Loader />;
  }

  return (
    <Container>
      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty={['case', 'id']}
        isEditing={isEditing}
        variableHeight={true}
      />
    </Container>
  );
};

OpenCasesWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.string),
};
