import styled from 'styled-components';

export const FormRow = styled.div`
  display: flex;
  padding-bottom: 10px;
  > div {
    margin-top: 10px;
    margin-left: 10px;
    width: 100%;
    &:first-of-type {
      margin-left: 0px;
    }
  }
`;
