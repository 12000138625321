import { TableFilterTypes } from '@ge/models/constants';

const MAX_FILTERED_VALUES = 1000;

export const AnalysisColumns = {
  GROUP_SELECTED: 'group-selected',
  SELECTED: 'selected',

  GROUP_ANALYSIS_TEMPLATE_ID: 'group_analysis_template_id',
  ANALYSIS_TEMPLATE_ID: 'id',

  GROUP_SIGNAL_SOURCE: 'group_signal_source',
  SIGNAL_SOURCE: 'signalSource',

  GROUP_PLOTS: 'group_plots',
  PLOTS: 'plotCount',

  //plot 1
  GROUP_PLOT_1: 'group_plot_1',
  PLOT1_X1: 'plot1_x',
  PLOT1_Y1: 'plot1_y',

  //plot 2
  GROUP_PLOT_2: 'group_plot_2',
  PLOT2_X1: 'plot2_x',
  PLOT2_Y1: 'plot2_y',

  //plot 3
  GROUP_PLOT_3: 'group_plot_3',
  PLOT3_X1: 'plot3_x',
  PLOT3_Y1: 'plot3_y',

  //plot 4
  GROUP_PLOT_4: 'group_plot_4',
  PLOT4_X1: 'plot4_x',
  PLOT4_Y1: 'plot4_y',

  GROUP_TEMPLATE_DETAILS: 'group_template_details',
  TEMPLATE_DETAIL: 'template_detail',
};

export const AnalysisColumnsDefs = {
  [AnalysisColumns.GROUP_SELECTED]: {
    locked: true,
    minWidth: '50px',
    cols: {
      [AnalysisColumns.SELECTED]: {
        labels: [
          {
            a11yKey: 'analysis_template_table.check_box',
            a11yDefault: '',
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_ANALYSIS_TEMPLATE_ID]: {
    align: 'left',
    cols: {
      [AnalysisColumns.ANALYSIS_TEMPLATE_ID]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.analysis_template_id',
            a11yDefault: 'Analysis Template ID',
            sortValue: AnalysisColumns.ANALYSIS_TEMPLATE_ID,
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_PLOTS]: {
    cols: {
      [AnalysisColumns.PLOTS]: {
        minWidth: '50px',
        filterType: TableFilterTypes.NUMBER,
        enableShowAll: true,
        labels: [
          {
            a11yKey: 'analysis_template_table.plots',
            a11yDefault: 'PLOTS',
            sortValue: AnalysisColumns.PLOTS,
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_PLOT_1]: {
    labels: [
      {
        a11yKey: 'analysis_template_table.plot_1',
        a11yDefault: 'PLOT 1',
      },
    ],
    cols: {
      [AnalysisColumns.PLOT1_X1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.x_1',
            a11yDefault: 'X',
            sortValue: `${AnalysisColumns.PLOT1_X1}.name`,
          },
        ],
      },
      [AnalysisColumns.PLOT1_Y1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.y1_1',
            a11yDefault: 'Y',
            sortValue: `${AnalysisColumns.PLOT1_Y1}.name`,
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_PLOT_2]: {
    labels: [
      {
        a11yKey: 'analysis_template_table.plot_2',
        a11yDefault: 'PLOT 2',
      },
    ],
    cols: {
      [AnalysisColumns.PLOT2_X1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.x_1',
            a11yDefault: 'X',
            sortValue: `${AnalysisColumns.PLOT2_X1}.name`,
          },
        ],
      },
      [AnalysisColumns.PLOT2_Y1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.y1_1',
            a11yDefault: 'Y',
            sortValue: `${AnalysisColumns.PLOT2_Y1}.name`,
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_PLOT_3]: {
    labels: [
      {
        a11yKey: 'analysis_template_table.plot_3',
        a11yDefault: 'PLOT 3',
      },
    ],
    cols: {
      [AnalysisColumns.PLOT3_X1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.x_1',
            a11yDefault: 'X',
            sortValue: `${AnalysisColumns.PLOT3_X1}.name`,
          },
        ],
      },
      [AnalysisColumns.PLOT3_Y1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.y1_1',
            a11yDefault: 'Y',
            sortValue: `${AnalysisColumns.PLOT3_Y1}.name`,
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_PLOT_4]: {
    labels: [
      {
        a11yKey: 'analysis_template_table.plot_4',
        a11yDefault: 'PLOT 4',
      },
    ],
    cols: {
      [AnalysisColumns.PLOT4_X1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.x_1',
            a11yDefault: 'X',
            sortValue: `${AnalysisColumns.PLOT4_X1}.name`,
          },
        ],
      },
      [AnalysisColumns.PLOT4_Y1]: {
        minWidth: '194px',
        align: 'left',
        filterType: TableFilterTypes.CHECKBOXES,
        enableShowAll: true,
        maxFilteredValues: MAX_FILTERED_VALUES,
        labels: [
          {
            a11yKey: 'analysis_template_table.y1_1',
            a11yDefault: 'Y',
            sortValue: `${AnalysisColumns.PLOT4_Y1}.name`,
          },
        ],
      },
    },
  },
  [AnalysisColumns.GROUP_TEMPLATE_DETAILS]: {
    fixedRight: true,
    cols: {
      [AnalysisColumns.TEMPLATE_DETAIL]: {
        fixedRight: true,
        minWidth: '50px',
        labels: [
          {
            a11yKey: 'analysis_template_table.template_detail',
            a11yDefault: 'Template \n Detail',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '40px',
        },
      },
    },
  },
};

export const GlobalAnalysisTemplates = [
  {
    id: AnalysisColumns.GROUP_SELECTED,
    cols: [
      {
        id: AnalysisColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_ANALYSIS_TEMPLATE_ID,
    cols: [
      {
        id: AnalysisColumns.ANALYSIS_TEMPLATE_ID,
        visible: true,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_PLOTS,
    cols: [
      {
        id: AnalysisColumns.PLOTS,
        visible: true,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_PLOT_1,
    cols: [
      {
        id: AnalysisColumns.PLOT1_X1,
        visible: true,
      },
      {
        id: AnalysisColumns.PLOT1_Y1,
        visible: true,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_PLOT_2,
    cols: [
      {
        id: AnalysisColumns.PLOT2_X1,
        visible: true,
      },
      {
        id: AnalysisColumns.PLOT2_Y1,
        visible: true,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_PLOT_3,
    cols: [
      {
        id: AnalysisColumns.PLOT3_X1,
        visible: false,
      },
      {
        id: AnalysisColumns.PLOT3_Y1,
        visible: false,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_PLOT_4,
    cols: [
      {
        id: AnalysisColumns.PLOT4_X1,
        visible: false,
      },
      {
        id: AnalysisColumns.PLOT4_Y1,
        visible: false,
      },
    ],
  },
  {
    id: AnalysisColumns.GROUP_TEMPLATE_DETAILS,
    cols: [
      {
        id: AnalysisColumns.TEMPLATE_DETAIL,
        visible: true,
      },
    ],
  },
];
