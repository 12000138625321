import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { KpiCategoryDefs } from '@ge/models/constants';
import { mediaQueries } from '@ge/tokens';

import { RegionSiteCard } from './region-site-card';

const SiteCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  ${mediaQueries('lg')`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${mediaQueries('xlg')`
    grid-template-columns: repeat(6, 1fr);
  `}
`;

const SitesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SitesGrid = ({ onSelectSite, onSelectSiteTitle, sites }) => (
  <SitesContainer>
    <SiteCards>
      {sites.length
        ? sites.map((d) => {
            const kpi = {
              [KpiCategoryDefs.PRODUCTION_ACTUAL]: [
                { value: d?.productionActual?.aggregateValue, unit: d?.productionActual?.units },
              ],
              [KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]: [
                {
                  value: d?.unproducedEnergyContract?.aggregateValue,
                  unit: d?.unproducedEnergyContract?.units,
                },
              ],
            };
            const gauge = {
              [KpiCategoryDefs.PBA]: {
                capacity: 100,
                danger: 96,
                max: 100,
                threshold: 98,
                value: d?.pba?.aggregateValue,
                warning: 98,
              },
              [KpiCategoryDefs.TBA]: {
                capacity: 100,
                change: 'decrease',
                danger: 96,
                max: 100,
                threshold: 98,
                value: d?.tba?.aggregateValue,
                warning: 98,
              },
            };
            return (
              <RegionSiteCard
                key={d.siteId}
                id={d.siteId}
                title={d.site}
                kpi={kpi}
                gauge={gauge}
                danger={
                  gauge?.[KpiCategoryDefs.PBA]?.value < gauge?.[KpiCategoryDefs.PBA]?.threshold ||
                  gauge?.[KpiCategoryDefs.TBA]?.value < d.gauge?.[KpiCategoryDefs.TBA]?.threshold
                }
                onSelect={(e) => onSelectSite(e, d)}
                onTitleClick={(e) => onSelectSiteTitle(e, d.siteId)}
              />
            );
          })
        : null}
    </SiteCards>
  </SitesContainer>
);

SitesGrid.propTypes = {
  siteData: PropTypes.instanceOf(Object),
  onSelectSite: PropTypes.func,
  onSelectSiteTitle: PropTypes.func,
  sites: PropTypes.instanceOf(Object),
};

SitesGrid.defaultProps = {
  siteData: [],
  onSelectSite: () => {},
  onSelectSiteTitle: () => {},
  sites: {},
};
