import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DotsLoader } from '@ge/components/loader';
import { RibbonFilterType, ribbonFilterPropType } from '@ge/feat-monitor/models';

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.ribbonFilter.textColor};
  background: ${(props) => props.theme.ribbonFilter.backgroundColor};
  border: solid 1px ${(props) => props.theme.ribbonFilter.borderColor};
  font-size: 10px;
  text-transform: uppercase;
  padding: 3px 14px;
  &:first-child {
    border-radius: 2px 0px 0px 2px;
  }
  &:last-child {
    border-radius: 0px 2px 2px 0px;
  }
  &:not(:first-child) {
    border-left: 0px;
  }
  &.active {
    color: ${(props) => props.theme.ribbonFilter.activeTextColor};
    background: ${(props) => props.theme.ribbonFilter.activeBackgroundColor};
  }
`;

const StyledName = styled.span``;

const StyledCount = styled.span`
  font-size: 11px;
  padding-top: 2px;
`;

const NavItem = ({ isLoading, id, label, i18nKey, count, active, onSelect }) => {
  const { t } = useTranslation(['entity-details']);

  const title = useMemo(
    () => (isLoading ? t('ribbon_menu.loading_status', { count }) : null),
    [isLoading, count, t],
  );

  return (
    <Button
      className={active ? 'active' : ''}
      onClick={() => onSelect(!active ? id : RibbonFilterType.NONE)}
      title={title}
    >
      <StyledName>{t(i18nKey, label)}</StyledName>
      <StyledCount>{isLoading ? <DotsLoader /> : count}</StyledCount>
    </Button>
  );
};

NavItem.propTypes = ribbonFilterPropType;

NavItem.defaultProps = {
  isLoading: true,
  id: '',
  label: '',
  i18nKey: '',
  count: 0,
  active: false,
};

NavItem.displayName = 'RibbonFilterNavItem';

export default NavItem;
