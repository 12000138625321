import { action, thunk } from 'easy-peasy';

import { fetchWatchlist } from '../../app/services/watchlist';

// Default state to apply on initialization or reset.
const defaultWatchlistState = {
  watchlist: null,
};

const watchlistActions = {
  resetWatchlist: action((state) => {
    state = Object.assign(state, defaultWatchlistState);

    state.watchlist = defaultWatchlistState.watchlist; // HACK: For lint only.
  }),

  setWatchlist: action((state, payload) => {
    state.watchlist = payload;
  }),

  fetchWatchlist: thunk(async (actions, _, { fail }) => {
    try {
      const { watchlist } = await fetchWatchlist();
      actions.setWatchlist(watchlist);
    } catch (err) {
      fail(err);
    }
  }),
};

const watchlistComputed = {};

const watchlistModel = {
  ...defaultWatchlistState,
  ...watchlistActions,
  ...watchlistComputed,
};

export default watchlistModel;
