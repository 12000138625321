import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  ErpBindingPropertyName,
  ErpFieldType,
  ErpTranslationField,
  ErpValuesFieldKey,
  QueryKey,
} from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchErpTemplate } from '../services';

import { useCrew } from './use-crew';
import { useErpTranslations } from './use-erp-translations';

const ErpTranslationFieldLookup = Object.values(ErpTranslationField).reduce(
  (lookup, value) => ({ ...lookup, [value]: true }),
  {},
);

// this is for transforming data in a way that doesn't involve redundant calls to the bff/backend
// if some of this logic can be pushed down into bff then go for it
// could look into passing the whole task object instead of individual fields if that makes more sense
const transformTemplate = ({ crew, taskTitle, template, translations }) => {
  if (!(template && translations)) {
    return null;
  }

  const { create = {}, edit = {}, sections, ..._template } = template;

  return {
    ..._template,
    create: {
      ...create,
      title: translations[create.title], // can provide fallback values from our translations here
    },
    edit: {
      ...edit,
      title: translations[edit.title], // can provide fallback values from our translations here
    },
    sections: sections
      ?.sort((a, b) => {
        return a.order - b.order;
      })
      .reduce((transformedSections, { title, ...section }) => {
        const translatedSection = {
          ...section,
          metadata: Object.entries(section?.metadata ?? {})
            .sort(([, fieldA], [, fieldB]) => {
              return fieldA.order - fieldB.order;
            })
            .reduce((transformedMetadata, [fieldKey, field]) => {
              // translate fields
              transformedMetadata[fieldKey] = Object.entries(field ?? {}).reduce(
                (transformedField, [key, value]) => {
                  // if this feels too brittle, we can brute force try to translate every field and fall back to original value
                  if (ErpTranslationFieldLookup[key]) {
                    // attempt to translate, fall back if fail
                    transformedField[key] = translations[value] ?? value;
                  } else {
                    const valuesTransformer = (values) =>
                      Object.entries(values ?? {}).reduce((transformedValues, [key, value]) => {
                        // attempt to translate options
                        transformedValues[key] = translations[value] ?? value;

                        return transformedValues;
                      }, {});

                    switch (key) {
                      case ErpValuesFieldKey.SERVICE_ACTIVITY_VALUES:
                      case ErpValuesFieldKey.VALUES:
                        transformedField[key] = valuesTransformer(value);

                        break;

                      case ErpValuesFieldKey.VALUES_BY_VALUES:
                        transformedField[key] = value?.map(({ values, ..._valuesByValues }) => ({
                          ..._valuesByValues,
                          [ErpValuesFieldKey.VALUES]: valuesTransformer(values),
                        }));

                        break;

                      default:
                        transformedField[key] = value;
                    }
                  }

                  return transformedField;
                },
                {},
              );

              const _field = transformedMetadata[fieldKey];

              // bind assigned techs to field
              if (_field?.type === ErpFieldType.TECHNICIANS) {
                _field.values = crew?.technicians;
              }

              // US549543 use task title as default value for problem summary
              if (fieldKey === ErpBindingPropertyName.PROBLEM_SUMMARY) {
                _field.defaultSelection = taskTitle;
              }

              return transformedMetadata;
            }, {}),
          title: translations[title] ?? title,
        };

        transformedSections.push(translatedSection);

        return transformedSections;
      }, []),
  };
};

export const useErpTemplate = ({ crewId, isActive = true, taskTitle }) => {
  const { data: crew, isLoading: isCrewLoading } = useCrew({ crewId });
  const { data: translations, isLoading: isTranslationsLoading } = useErpTranslations();
  const { srNewFieldsFlag } = useStoreState((state) => state.tenant.featureFlags);

  const {
    data: template,
    error,
    isLoading: isTemplateLoading,
  } = useQuery([QueryKey.ERP_TEMPLATE], fetchErpTemplate, {
    ...Config.EXECUTE_ONCE,
    enabled: isActive,
  });

  const isLoading = isCrewLoading || isTemplateLoading || isTranslationsLoading;

  const data = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (!srNewFieldsFlag) {
      const filteredSections = [
        'customerReferenceNumber',
        'highRiskActivity',
        'isolation',
        'rdspp',
      ];
      filteredSections.forEach((el) => {
        if (Object.keys(template.sections[0].metadata).includes(el)) {
          delete template.sections[0].metadata[el];
        }
      });
    }

    return transformTemplate({ crew, taskTitle, template, translations });
  }, [crew, isLoading, srNewFieldsFlag, taskTitle, template, translations]);

  return { data, error, isLoading };
};
