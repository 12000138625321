import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { AlertDLType, CaseEscalationType, DistributionLists, Types } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAllDistributionLists } from '@ge/shared/data-hooks';
import { SidePanel } from '@ge/web-client/src/app/components/side-panel/side-panel';

import { CreateDL } from './create-dl';
import { ViewDistrubtionList } from './view-distribution-list';

const duration = 300;

const SharedPanelStyles = css`
  &.details-panel-transition-enter {
    transform: translateX(802px);
  }
  &.details-panel-transition-enter-active {
    transition: transform ${duration}ms;
    transform: translateX(0);
  }
  &.details-panel-transition-exit {
    transform: translateX(0);
  }
  &.details-panel-transition-exit-active {
    transition: transform ${duration}ms;
    transform: translateX(802px);
  }
`;

// Apply portal animation styles to entity details panel
const StyledEntityDetailsPanel = styled(SidePanel)`
  ${SharedPanelStyles};
`;

export const DLSidePanel = ({ data, filterDefs }) => {
  const [ispopup, setIsPopup] = useState(false);
  const [isCancelPopup, setIsCancelPopup] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const { params, paramValues, openDLSidePanel, hideDLSidePanel } =
    useContext(EntityDetailsContext);

  const { distributionLists } = useAllDistributionLists({
    filters: filterDefs,
  });

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      desc: '',
      sites: [],
      assets: [],
      cases: [
        { type: CaseEscalationType.FAULTED_ACTIONABLE, value: false },
        { type: CaseEscalationType.FAULTED_INFORMATIONAL, value: false },
        { type: CaseEscalationType.NOTFAULTED_ACTIONABLE, value: false },
        { type: CaseEscalationType.NOTFAULTED_INFORMATIONAL, value: false },
      ],
      alerts: Object.keys(AlertDLType).map((key) => ({ type: key, value: false })),
      mails: [{ firstName: '', lastName: '', type: 'to', email: '' }],
    },
  });

  const onExit = useCallback(
    (e) => {
      hideDLSidePanel(e);
    },
    [hideDLSidePanel],
  );

  const toggleConfirmPopup = useCallback(() => {
    setIsPopup((state) => !state);
  }, [setIsPopup]);

  const toggleCancelConfirmPopup = useCallback(() => {
    setIsCancelPopup((state) => !state);
  }, [setIsCancelPopup]);

  const renderDL = useCallback(() => {
    if (!paramValues) {
      return;
    }

    switch (paramValues[0]) {
      case DistributionLists.NEW:
        return (
          <StyledEntityDetailsPanel
            close={ispopup || isCancelPopup ? () => {} : hideDLSidePanel}
            navBack={params.get(DistributionLists.TAB) === '2'}
          >
            <CreateDL
              type={Types.NEW}
              close={onExit}
              ispopup={ispopup}
              setIsPopup={setIsPopup}
              setIsCancelPopup={setIsCancelPopup}
              filterDefs={filterDefs}
              toggleConfirmPopup={toggleConfirmPopup}
              isCancelPopup={isCancelPopup}
              toggleCancelConfirmPopup={toggleCancelConfirmPopup}
              isCancel={isCancel}
              setIsCancel={setIsCancel}
              distributionLists={distributionLists}
            />
          </StyledEntityDetailsPanel>
        );
      case DistributionLists.VIEW:
        return (
          <StyledEntityDetailsPanel close={ispopup ? () => {} : hideDLSidePanel}>
            <ViewDistrubtionList
              details={data}
              close={onExit}
              ispopup={ispopup}
              toggleConfirmPopup={toggleConfirmPopup}
              openDLSidePanel={openDLSidePanel}
            />
          </StyledEntityDetailsPanel>
        );
      case DistributionLists.EDIT:
        return (
          <StyledEntityDetailsPanel
            navBack={true}
            close={ispopup || isCancelPopup ? () => {} : hideDLSidePanel}
          >
            <CreateDL
              type={Types.EDIT}
              close={onExit}
              details={data}
              filterDefs={filterDefs}
              ispopup={ispopup}
              setIsPopup={setIsPopup}
              setIsCancelPopup={setIsCancelPopup}
              toggleConfirmPopup={toggleConfirmPopup}
              isCancelPopup={isCancelPopup}
              toggleCancelConfirmPopup={toggleCancelConfirmPopup}
              isCancel={isCancel}
              setIsCancel={setIsCancel}
              distributionLists={distributionLists}
            />
          </StyledEntityDetailsPanel>
        );
      default:
        return null;
    }
  }, [
    data,
    filterDefs,
    isCancel,
    isCancelPopup,
    ispopup,
    onExit,
    paramValues,
    params,
    toggleCancelConfirmPopup,
    toggleConfirmPopup,
    openDLSidePanel,
    hideDLSidePanel,
  ]);
  return (
    <CSSTransition
      in={!!params.get(DistributionLists.MODE)}
      classNames="details-panel-transition"
      onExited={onExit}
      timeout={300}
      unmountOnExit
    >
      <FormProvider {...methods}>{renderDL()}</FormProvider>
    </CSSTransition>
  );
};

DLSidePanel.propTypes = {
  close: PropTypes.any,
  isNew: PropTypes.bool,
  showSidebar: PropTypes.bool,
  data: PropTypes.object,
  filterDefs: PropTypes.object,
};
