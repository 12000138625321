import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import clone from 'ramda/src/clone';
import uniq from 'ramda/src/uniq';
import React, { useCallback, useRef, useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ConfirmationDialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { Severity } from '@ge/components/severity';
import { DraggableTable, TableTitle, TableArrow, EntityCell } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { EscalateButton } from '@ge/feat-monitor/components';
import { CloseCaseAction } from '@ge/feat-monitor/components/close-case-action';
import { RocLogsAction } from '@ge/feat-monitor/components/roc-logs-action';
import { useMonitorCaseTableFilter } from '@ge/feat-monitor/data-hooks/use-monitor-case-table-filter';
import { useCrossTabState } from '@ge/hooks/use-cross-tab-state';
import { randomIntWithRange } from '@ge/mocks/util/mock-utils';
import {
  AssetState,
  AssetType,
  EntityType,
  FormMode,
  NotificationType,
  NotificationActionType,
  MonitorDefs,
  AssetConnectionStatus,
  Capability,
  PermissionScope,
  AlertsEntityType,
  AlertType,
  EntityTypeMapping,
  SystemResetStatus,
  SystemResetResult,
  MonitorCaseStatus,
  CasePatchCallStatus,
  CaseTypeLabel,
  BulkNotesActionsType,
  CaseType,
} from '@ge/models/constants';
// import { EntityTypeMap } from '@ge/serverless-models/src/rendigital/enums';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { AlertDialog } from '@ge/shared/components/alerts/alert-dialog';
import { AlertTableIcons } from '@ge/shared/components/alerts/alert-table-icons';
import { AssetPreviousState } from '@ge/shared/components/asset-previous-state';
import { AutomationStatus } from '@ge/shared/components/automation-status';
import { EscalatedInfo } from '@ge/shared/components/escalated-info';
import { SnoozeDetails } from '@ge/shared/components/snooze-details';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAuth } from '@ge/shared/data-hooks';
import { useActionsMenu, useMonitorCases, useChangeBulkCasesStatus } from '@ge/shared/data-hooks';
import {
  useAlertDialog,
  useCreateNotification,
  useSnoozeDialog,
  useAddNoteDialog,
  useROCActionDialog,
  useCloseCaseActionDialog,
} from '@ge/shared/hooks';
import {
  EventTableMenuItems,
  EventHistoryTableMenuItems,
  ActionKeys,
} from '@ge/shared/models/actions-menu-items';
import { getCaseLevelIcon, getBOPSiteIcon } from '@ge/shared/util/asset-icon';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDuration, getDateTimeBasedOnZone, isBeforeToday } from '@ge/shared/util/time-date';
import { getTurbineStateType, getTurbineStateColor } from '@ge/shared/util/turbine-state';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';
import { formatToCurrency, roundNumber } from '@ge/util/number-utils';
import { CaseNotesDialog } from '@ge/web-client/src/app/components/entity-details/components/case-notes-dialog';
import { AddNoteDialog } from '@ge/web-client/src/app/components/entity-details/components/notes-add-note-dialog';
// TODO (astone): These are an unholy reference to DWF from a feature! Must remove!
import { SnoozeDialog } from '@ge/web-client/src/app/components/entity-details/components/snooze-dialog';
import i18n from '@ge/web-client/src/i18n';

import { getMappedEventStatus, getMappedEventType } from '../../models/event-type-status';
import { EventsColumnDefs, EventsColumns } from '../../models/events-table-cols';

import automationCompletedFailed from './automation-completed-failed.svg';
import rnsImg from './rns.svg';
// import EventCodeMenu from '../event-code-menu';
// import StateMenu from '../state-menu';

const StyledLoadingCell = styled(({ className }) => (
  <div className={className}>
    <span />
    <span />
    <span />
  </div>
))`
span {
    margin: 2px;
    display: inline-block;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: ${(props) => props.theme.table.dataTextColor}
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
    &:nth-child(2n) {
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
    }
  }
  @keyframes dotFlashing {
    0% {
      background-color: ${(props) => props.theme.table.dataTextColor}
    }
    50%,
    100% {
      background-color: ${(props) => props.theme.table.dataTextColorLight}
    }
  }
`;

// Will remove text-align once Timezone format
// is decided (alignment issue for site/local time)
const StyledTimeDate = styled.div`
  text-align: left;
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      margin-left: 11px;
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

// Number of hours to show alert for, will probably become a user setting
const DURATION_ALERT = 4;

const MailIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.EMAIL,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
  svg {
    margin-left: 0;
  }
`;

const MailButton = styled.button`
  border: ${(props) => props.theme.tasks.newButton.border};
  border-radius: 2px;
  background: ${(props) => props.theme.tasks.backgroundColor.W};
  padding: 0;
  height: 20px;
  width: 20px;
`;

const StyledEventType = styled.div`
  display: flex;
  h3 {
    flex: 1;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3 span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
  svg {
    min-width: 16px;
  }
`;

const StyledNotes = styled.div`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const getSystemResetColors = ({ table }) => ({
  [SystemResetStatus.IN_PROGRESS]: table.iconLightColor,
  [SystemResetStatus.COMPLETED]: table.iconCompletedColor,
  [SystemResetStatus.FAILED]: table.iconErrorColor,
  [SystemResetStatus.NO_AUTOMATION]: table.iconNotEligibleColor,
});

const SystemResetIcon = styled(Icon).attrs(({ theme, resetInfo }) => ({
  animate: resetInfo?.resetStatus === SystemResetStatus.IN_PROGRESS ? 'rotate' : undefined,
  color: getSystemResetColors(theme)[resetInfo?.resetStatus ?? SystemResetStatus.NO_AUTOMATION],
  icon: Icons.SYSTEM_RESET,
  size: 14,
}))``;

const tableTypeActionMapping = {
  [MonitorDefs.CASES]: EventTableMenuItems,
  [MonitorDefs.HISTORY]: EventHistoryTableMenuItems,
};

const getEntityTypeFromSiteObj = (site) => {
  if (Array.isArray(site?.type) && site?.type?.length > 0) {
    return site.type[0]?.toLowerCase();
  }
  return EntityType.WIND_SITE;
};

const TypeIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.theme.table.iconLightColor,
}))`
  margin-top: 2px;
  margin-right: 4px;
`;

const NoWrap = styled.div`
  white-space: nowrap;
`;

const SetWidth = styled.span.attrs((props) => ({
  width: props.width,
}))`
  display: inline-block;
  padding-right: 8px;
  text-align: right;
  width: ${(props) => props.width};
`;

const NotificationMessage = styled.div`
  font: ${typography.weight.regular} 10px/15px ${typography.family.default};
`;

const StyledSpan = styled.span`
  opacity: ${(props) => (props.isDisconnected ? 0.4 : 1)};
`;

// Will uncomment when we display site/local time
// const StyledTimeDate = styled.div`
//   span {
//     display: inline-block;

//     &:nth-child(n + 2) {
//       margin-left: 11px;
//       color: ${(props) => props.theme.menu.tableHeaderTextColor};
//     }
//   }
// `;

const NoteIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.NOTE,
  color: props.theme.table.iconLightColor,
}))`
  padding-bottom: 2px;
`;

const StyledStatus = styled.span`
  font-size: 12px;
  .status-message {
    margin-left: 10px;
    .changed-state {
      font-weight: 700;
    }
  }
`;

const IconContainer = styled.div`
  align-items: center;
  background: ${(props) =>
    props.invalid
      ? props.theme.entityDetails.notes.invalidSpecialInstruction
      : props.theme.entityDetails.notes.validSpecialInstruction};
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  top: 1px;
`;

const SpecialInstructionIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.notes.entityDescription,
  size: 12,
  icon: props.icon,
  viewbox: '-2.5 -1 12 12',
}))`
  position: absolute;
  right: 0;
`;

const getBulkNotesErrorMessage = (errors, selectedAssets, t) => {
  const selectedAssetsCount = selectedAssets.length;
  const noteCreationErrors = errors.filter(
    (error) => error.type === BulkNotesActionsType.NOTE_CREATION,
  );
  return (
    <NotificationMessage>
      <StyledStatus>
        {`${t('status.success', 'Success')}: ${
          selectedAssetsCount - noteCreationErrors.length
        } / ${t('status.failure', 'Failure')}: ${noteCreationErrors.length}`}
        <span className="status-message">
          {`${t('status.case_notes_status', 'Notes creation for cases completed')}`}
        </span>
      </StyledStatus>
    </NotificationMessage>
  );
};

const getBulkNotesFailedMessage = (t) => {
  return (
    <NotificationMessage>
      <StyledStatus>
        <span className="status-message">
          {t('status.failed_to_create_note', 'Failed to create note')}
        </span>
      </StyledStatus>
    </NotificationMessage>
  );
};

const getCount = (func, arr = []) => (Array.isArray(arr) ? arr.filter(func).length : 0);

const EventsTable = ({
  columns,
  tableType,
  sortAction,
  sortMetric,
  sortDirection,
  onEventSelect,
  onDrop,
  selectedRibbonFilter,
  filterDefs,
  setFilterDefs,
  setCaseHistoryRefreshing,
  updateRibbonFilterCounts,
}) => {
  const { t, ready } = useTranslation(['monitor.issues', 'tasks'], {
    useSuspense: false,
  });

  const {
    showSiteDetails,
    showStorageSiteDetails,
    showSolarSiteDetails,
    showHybridSiteDetails,
    showAssetDetails,
    showStorageAssetDetails,
    showSolarAssetDetails,
    showHybridAssetDetails,
    panelShown,
  } = useContext(EntityDetailsContext);

  // store
  const { getSiteById } = useStoreState((state) => state.sites);

  // state
  const [currentEvent, setCurrentEvent] = useState();
  const [eventsChecked, setEventsChecked] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [currentSearch, setCurrentSearch] = useState({});
  const [isBulkCaseStateChanged, setIsBulkCaseStateChanged] = useState(false);
  const [caseNotesModal, setCaseNotesModal] = useState(false);
  const [caseNotes, setCaseNotes] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [bulkErrorCase, setBulkErrorCase] = useState('');
  const [limitCase, setLimitCase] = useState('');
  const [actionType, setActionType] = useState('');
  const [groupSelectionState, setGroupSelectionState] = useState(CheckedState.UNCHECKED);
  const [currentRow, setCurrentRow] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [escalatePopoutUrl, setEscalatePopoutUrl] = useState('');
  const [caseEscalateError, setCaseEscalateError] = useCrossTabState('case-escalate-notify', '');

  const differencesOfSelectedAssets = useMemo(() => {
    const differentSite = uniq(selectedAssets.map(({ siteId }) => siteId))?.length > 1;
    const differentType = uniq(selectedAssets.map(({ assetType }) => assetType))?.length > 1;
    const differentCaseType =
      uniq(selectedAssets.map(({ caseType }) => CaseTypeLabel[caseType] ?? caseType))?.length > 1;
    const isSameAsset =
      uniq(selectedAssets.map(({ assetId }) => assetId))?.length !== selectedAssets.length;
    const isSiteLevelCase = selectedAssets.find((item) => item?.caseLevel === EntityType.SITE);
    const isFleetLevelCase = selectedAssets.find((item) => item?.caseLevel === EntityType.FLEET);
    return {
      differentSite,
      differentType,
      differentCaseType,
      isSameAsset,
      isSiteLevelCase,
      isFleetLevelCase,
    };
  }, [selectedAssets]);

  const disabledBulkActions = useCallback(
    () =>
      selectedAssets?.length
        ? {
            [ActionKeys.EVENT_SNOOZE]: true,
            [ActionKeys.EVENT_CHANGE_CAUSAL_ALARM]: true,
            [ActionKeys.EVENT_LOG_ACTION]: true,
            [ActionKeys.NOTE_EDIT]: true,
            [ActionKeys.NOTE_DELETE]: true,
            [ActionKeys.EVENT_SEND_NOTIFICATION]: true,
            [ActionKeys.CREATE_ALERT]: true,
          }
        : {},
    [selectedAssets],
  );

  const containerRef = useRef(null);

  const { isAuthorized } = useAuth();

  const authorizedAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.CREATE] }],
    siteIds: [currentEvent?.site?.id],
  });

  // data hooks
  const {
    groupDetails,
    currentAlert,
    showAlertDialog,
    handleSaveAlert,
    handleEditAlert,
    handleCancelAlertDialog,
    handleShowAlertDialog,
    handleViewAlert,
  } = useAlertDialog(
    {
      entity:
        currentEvent?.caseLevel === EntityType.SITE ? currentEvent?.site : currentEvent?.asset,
      entityType:
        currentEvent?.caseLevel === EntityType.SITE
          ? AlertsEntityType.SITE
          : EntityTypeMapping[currentEvent?.asset?.type],
    },
    authorizedAlerts,
  );

  // data hooks
  const getAuthorizedMenuItems = useActionsMenu({ menuItems: tableTypeActionMapping[tableType] });

  const {
    data: events,
    filterValues,
    isAssetsLoading,
    inverterAssetsLoading,
    isLoading: isCasesLoading,
    isAlertsLoading: isAlertsLoading,
    isSIsLoading,
    isRefetching,
    refetchCases,
  } = useMonitorCases({
    tableType,
    sortDirection,
    sortMetric,
    search: currentSearch,
    filters: filterDefs,
    selectedRibbonFilter: selectedRibbonFilter?.type,
    updateRibbonFilterCounts,
  });

  const { modifiedFilters, setModifiedFilters, modifiedFiltersValues } = useMonitorCaseTableFilter({
    filterValues,
    filterDefs,
    tableType,
  });
  const {
    changeBulkCaseStatus,
    data: updatedBulkCaseData,
    isError: isChangeBulkCaseError,
    error: changeBulkCaseError,
  } = useChangeBulkCasesStatus();

  const { notify } = useNotification();

  const {
    changeCaseStatus,
    handleSubmitSnoozeValue,
    snoozeDialog,
    setSnoozeDialog,
    isCaseStateChanged,
    setIsCaseStateChanged,
    updatedData,
    isError,
    error,
  } = useSnoozeDialog();

  const {
    handleCloseCaseStatus,
    closeCaseDialog,
    setCloseCaseDialog,
    isCloseCaseStateChanged,
    isBulkCloseCaseStateChanged,
    setIsCloseCaseStateChanged,
    setIsBulkCloseCaseStateChanged,
    updatedCloseData,
    updatedBulkCloseData,
    isCloseError,
    isBulkCloseError,
    closeError,
    bulkCloseError,
  } = useCloseCaseActionDialog();

  const {
    addNoteDialog,
    setAddNoteDialog,
    isAddNoteClicked,
    setIsAddNoteClicked,
    createNoteError,
    isCreateNoteError,
    handleAddNote,
    bulkEventNotesData,
    createBulkNotesError,
    isCreateBulkNotesError,
  } = useAddNoteDialog(currentEvent, selectedAssets, setSelectedAssets, setEventsChecked);

  const { rocLogsDialog, setRocLogsDialog, handleAddRocLogs } = useROCActionDialog();

  const { createNotification, addNoteNotification } = useCreateNotification(
    currentEvent,
    updatedData,
    isError,
    error,
  );

  const { createNotification: closeCreateNotification } = useCreateNotification(
    currentEvent,
    updatedCloseData,
    isCloseError,
    closeError,
  );

  useEffect(() => {
    if (setCaseHistoryRefreshing) {
      setCaseHistoryRefreshing(isRefetching);
    }
  }, [setCaseHistoryRefreshing, isRefetching]);

  useEffect(() => {
    const filteredColumns = [];
    columns.map((c) => {
      const newCol = { id: c.id, cols: [] };
      const cols = c.cols.filter((f) => f.filter === undefined || f.filter === tableType);
      if (cols.length > 0) {
        newCol.cols = cols;
        filteredColumns.push(newCol);
      }
    });
    setTableColumns(filteredColumns);
  }, [columns, tableType, setTableColumns]);

  useEffect(() => {
    const linkItem = Object.values(tableTypeActionMapping[tableType]).find(
      (x) => x.translationKey === 'link',
    );
    if (linkItem) {
      linkItem.disabled = eventsChecked.length > 1;
    }
  }, [eventsChecked, tableType]);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const onRowSelect = useCallback(
    (e, row) => {
      const {
        site: { id: siteId, type: siteType },
        asset: { id: assetId, type: assetType },
      } = row;
      if (e.target.classList.contains('site-name') || e.target.classList.contains('asset-name')) {
        return;
      }
      if (assetId) {
        switch (assetType) {
          case AssetType.WIND_TURBINE:
            showAssetDetails(assetId);
            break;
          case AssetType.STORAGE_INVERTER:
            showStorageAssetDetails(assetId);
            break;
          case AssetType.SOLAR_INVERTER:
            showSolarAssetDetails(assetId);
            break;
          case AssetType.HYBRID_INVERTER:
            showHybridAssetDetails(assetId);
            break;
          default:
            showAssetDetails(assetId);
        }
      } else if (siteId) {
        switch (siteType) {
          case EntityType.WIND_SITE:
          case EntityType.SITE:
            showSiteDetails(siteId);
            break;
          case EntityType.STORAGE_SITE:
            showStorageSiteDetails(siteId);
            break;
          case EntityType.SOLAR_SITE:
            showSolarSiteDetails(siteId);
            break;
          case EntityType.HYBRID_SITE:
            showHybridSiteDetails(siteId);
            break;
          default:
            showSiteDetails(siteId);
        }
      }
    },
    [
      showAssetDetails,
      showStorageAssetDetails,
      showSolarAssetDetails,
      showHybridAssetDetails,
      showSiteDetails,
      showStorageSiteDetails,
      showSolarSiteDetails,
      showHybridSiteDetails,
    ],
  );

  const handleSiteClick = useCallback(
    (e, site, event = null) => {
      const siteType = getEntityTypeFromSiteObj(site);
      switch (siteType) {
        case EntityType.WIND_SITE:
        case EntityType.SITE:
          showSiteDetails(site.id);
          break;
        case EntityType.STORAGE_SITE:
          showStorageSiteDetails(site.id);
          break;
        case EntityType.SOLAR_SITE:
          showSolarSiteDetails(site.id);
          break;
        case EntityType.HYBRID_SITE:
          showHybridSiteDetails(site.id);
          break;
        default:
          showSiteDetails(site.id);
      }
      event && setCurrentRow([event?.id]);
    },
    [showSiteDetails, showStorageSiteDetails, showSolarSiteDetails, showHybridSiteDetails],
  );

  const handleAssetClick = useCallback(
    (e, asset, event = null) => {
      const assetType = asset?.type;
      switch (assetType) {
        case AssetType.WIND_TURBINE:
          showAssetDetails(asset.id);
          break;
        case AssetType.STORAGE_INVERTER:
          showStorageAssetDetails(asset.id);
          break;
        case AssetType.SOLAR_INVERTER:
          showSolarAssetDetails(asset.id);
          break;
        case AssetType.HYBRID_INVERTER:
          showHybridAssetDetails(asset.id);
          break;
        default:
          showAssetDetails(asset.id);
      }
      event && setCurrentRow([event?.id]);
    },
    [showAssetDetails, showStorageAssetDetails, showSolarAssetDetails, showHybridAssetDetails],
  );

  useEffect(() => {
    !panelShown && setCurrentRow([]);
  }, [panelShown]);

  const selectAllEvents = useCallback(() => {
    const assets = events?.map((event) => {
      const {
        id: caseId,
        type: caseType,
        caseLevel,
        asset: { id: assetId, name: assetName, type: assetType },
        site: { id: siteId, name: siteName },
        description: description,
        code: code,
      } = event;
      return {
        caseId,
        caseType,
        caseLevel,
        assetId,
        assetName,
        assetType,
        siteId,
        siteName,
        description,
        code,
      };
    });
    setSelectedAssets(assets);
    setEventsChecked(events?.map(({ id }) => id));
  }, [events]);

  // to update (remove) selection for hidden items when table updates
  useEffect(() => {
    if (groupSelectionState !== CheckedState.UNCHECKED) {
      setEventsChecked((prevCheckedEvents) =>
        prevCheckedEvents.filter((eventId) => events?.some(({ id }) => eventId === id)),
      );
      setSelectedAssets((prevSelectedAssets) => {
        const selectedAssets = prevSelectedAssets.filter(({ caseId }) =>
          events?.some(({ id }) => caseId === id),
        );
        if (selectedAssets?.length === events?.length) {
          setGroupSelectionState(CheckedState.CHECKED);
        } else if (selectedAssets?.length === 0) {
          setGroupSelectionState(CheckedState.UNCHECKED);
        } else {
          setGroupSelectionState(CheckedState.INDETERMINATE);
        }
        return selectedAssets;
      });
    }
  }, [events, groupSelectionState]);

  useEffect(() => {
    if (isCaseStateChanged && events?.length && (updatedData?.length || isError)) {
      createNotification();
      setIsCaseStateChanged(false);
    }
  }, [
    isCaseStateChanged,
    updatedData,
    events,
    isError,
    error,
    createNotification,
    setIsCaseStateChanged,
  ]);

  useEffect(() => {
    if (isCloseCaseStateChanged && events?.length && (updatedCloseData?.length || isCloseError)) {
      closeCreateNotification();
      setIsCloseCaseStateChanged(false);
    }
  }, [
    isCloseCaseStateChanged,
    updatedCloseData,
    events,
    isCloseError,
    closeError,
    closeCreateNotification,
    setIsCloseCaseStateChanged,
  ]);

  const createBulkNotification = useCallback(
    (input_data) => {
      if (!Array.isArray(input_data) || !input_data?.length) return;
      const option = { actions: [] };
      const site = currentEvent?.site;
      if (site) {
        option.actions.push({
          label: site.name,
          type: NotificationActionType.SITE_DETAILS,
          onClick: (e) => handleSiteClick(e, site),
        });
      }
      option.actions.push({
        label: `${input_data.length} ${t('table.cases', 'Cases')}`,
        type: NotificationActionType.EVENT_DETAILS,
      });
      const mappedEventStatus = getMappedEventStatus(input_data?.[0]?.state);
      option.message = (
        <NotificationMessage>
          {!isChangeBulkCaseError || !bulkCloseError ? (
            <StyledStatus>
              {`${t('status.success', 'Success')}: ${getCount(
                (item) => item?.status === CasePatchCallStatus.SUCCESS,
                input_data,
              )} / ${t('status.failure', 'Failure')}: ${getCount(
                (item) => item?.status === CasePatchCallStatus.FAILURE,
                input_data,
              )}`}
              <span className="status-message">
                {`${t('status.change_case_status', {
                  state: t(mappedEventStatus?.translationKey)?.toUpperCase(),
                })}`}
              </span>
            </StyledStatus>
          ) : (
            `${t('status.failed_to_update', 'Failed to change the state to')} "${t(
              mappedEventStatus?.translationKey,
            )?.toUpperCase()}"`
          )}
        </NotificationMessage>
      );
      option.type = isChangeBulkCaseError ? NotificationType.ERROR : NotificationType.SUCCESS;

      notify(option);
    },
    [isChangeBulkCaseError, bulkCloseError, notify, t, handleSiteClick, currentEvent],
  );

  const bulkActionsErrorNotification = useCallback(
    (message, count, site) => {
      const option = { actions: [] };
      if (site) {
        option.actions.push({
          label: site.name,
          type: NotificationActionType.SITE_DETAILS,
          onClick: (e) => handleSiteClick(e, site),
        });
      }
      option.actions.push({
        label: `${count} ${t('table.cases', 'Cases')}`,
        type: NotificationActionType.EVENT_DETAILS,
      });
      option.message = (
        <NotificationMessage>
          <StyledStatus>
            <span className="status-message">{message}</span>
          </StyledStatus>
        </NotificationMessage>
      );
      option.type = NotificationType.ERROR;
      notify(option);
    },
    [notify, t, handleSiteClick],
  );

  useEffect(() => {
    if (
      isBulkCaseStateChanged &&
      events?.length &&
      (updatedBulkCaseData?.length || isChangeBulkCaseError)
    ) {
      const input_data = isChangeBulkCaseError
        ? changeBulkCaseError?.response?.data
        : updatedBulkCaseData;
      createBulkNotification(input_data);
      setIsBulkCaseStateChanged(false);
    }
  }, [
    isBulkCaseStateChanged,
    events,
    updatedBulkCaseData,
    isChangeBulkCaseError,
    createBulkNotification,
    changeBulkCaseError,
  ]);

  useEffect(() => {
    if (
      isBulkCloseCaseStateChanged &&
      events?.length &&
      (updatedBulkCloseData?.length || isBulkCloseError)
    ) {
      const input_data = isBulkCloseError ? isBulkCloseError?.response?.data : updatedBulkCloseData;
      createBulkNotification(input_data);
      setIsBulkCloseCaseStateChanged(false);
    }
  }, [
    events,
    createBulkNotification,
    isBulkCloseCaseStateChanged,
    updatedBulkCloseData,
    isBulkCloseError,
    setIsBulkCloseCaseStateChanged,
  ]);

  useEffect(() => {
    if (isAddNoteClicked && events?.length && isCreateNoteError) {
      setIsAddNoteClicked(false);
      addNoteNotification();
    }
  }, [
    isAddNoteClicked,
    isCreateNoteError,
    events,
    createNoteError,
    createNotification,
    t,
    currentEvent,
    notify,
    handleAssetClick,
    handleSiteClick,
    addNoteNotification,
    setIsAddNoteClicked,
  ]);

  useEffect(() => {
    if (
      isAddNoteClicked &&
      selectedAssets?.length > 1 &&
      (bulkEventNotesData?.errors?.length || isCreateBulkNotesError)
    ) {
      setIsAddNoteClicked(false);
      const option = { actions: [] };
      if (currentEvent?.site) {
        option.actions.push({
          label: currentEvent?.site?.name,
          type: NotificationActionType.SITE_DETAILS,
          onClick: (e) => handleSiteClick(e, currentEvent.site),
        });
      }
      option.actions.push({
        label: `${selectedAssets?.length} ${t('table.cases', 'Cases')}`,
        type: NotificationActionType.EVENT_DETAILS,
      });
      let message = '';
      if (isCreateBulkNotesError) {
        let errors = createBulkNotesError?.response?.data?.message;
        if (errors) {
          try {
            errors = JSON.parse(createBulkNotesError.response.data.message);
          } catch (e) {
            errors = createBulkNotesError.response.data.message;
          }
        }
        message = Array.isArray(errors)
          ? getBulkNotesErrorMessage(errors, selectedAssets, t)
          : getBulkNotesFailedMessage(t);
      } else {
        message = Array.isArray(bulkEventNotesData.errors)
          ? getBulkNotesErrorMessage(bulkEventNotesData.errors, selectedAssets, t)
          : '';
      }
      option.message = message;
      option.type = NotificationType.ERROR;
      notify(option);

      setEventsChecked([]);
      setSelectedAssets([]);
    }
  }, [
    bulkEventNotesData,
    isCreateBulkNotesError,
    createBulkNotesError,
    isAddNoteClicked,
    setIsAddNoteClicked,
    currentEvent,
    notify,
    selectedAssets,
    t,
    handleSiteClick,
    events,
    bulkActionsErrorNotification,
  ]);

  /**
   * Get all the translated weather icons
   */
  const getTranslatedValues = useCallback(({ alerts = [] }) => {
    return alerts?.map((alert) => ({
      ...alert,
      id: alert.id,
      type: alert.type,
      validTo: alert.validTo,
      isExpired: alert.isExpired,
      auditProperties: alert.auditProperties,
      isGroup: !!alert?.groupDetails?.groupId,
      contactInfo: alert?.typeProperties?.contactInfo,
      description: alert?.typeProperties?.description,
      estimatedEndDate: alert?.typeProperties?.estimatedEndDate,
      requestedBy: alert?.typeProperties?.requestedBy,
      requestedTime: alert?.typeProperties?.requestedTime,
    }));
  }, []);

  // handlers
  const handleFilterChange = useCallback(
    (_, key, value) => setCurrentSearch({ key, value }),
    [setCurrentSearch],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      const filtersClone = { ...modifiedFilters };
      if (filtersClone?.[columnKey]) {
        let valueClone = clone(value);
        if (columnKey === EventsColumns.EVENT_STATUS) {
          if (value?.length && !value.includes(MonitorCaseStatus.CLOSED)) {
            value.push(MonitorCaseStatus.CLOSED);
          } else if (value?.length === 1) {
            value = null;
            valueClone = null;
          }
        }
        filtersClone[columnKey].value = valueClone;
        setModifiedFilters({ ...filtersClone });
      }
      setFilterDefs(columnKey, value);
    },
    [setFilterDefs, modifiedFilters, setModifiedFilters],
  );

  const handleOpenConfirmDialog = useCallback(() => {
    const height = 520;
    const width = 465;
    const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
    const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
    if (actionType === ActionKeys.EVENT_IN_PROGRESS) {
      changeBulkCaseStatus({ state: actionType, caseIds: eventsChecked });
      setActionType('');
      setIsBulkCaseStateChanged(true);
      setShowConfirmationDialog(false);
    } else if (actionType === ActionKeys.EVENT_CLOSE) {
      setCloseCaseDialog(true);
      setShowConfirmationDialog(false);
    } else {
      if (escalatePopoutUrl) {
        const escalateWindow = window.open(
          escalatePopoutUrl,
          '_blank',
          `toolbar=no,addressbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,left=${x},top=${y},width=${width},height=${height}`,
        );
        escalateWindow.onbeforeunload = () => {
          refetchCases();
        };
        setSelectedAssets([]);
        setEventsChecked([]);
      }
      setEscalatePopoutUrl('');
      setShowConfirmationDialog(false);
    }
  }, [
    actionType,
    changeBulkCaseStatus,
    eventsChecked,
    setCloseCaseDialog,
    escalatePopoutUrl,
    refetchCases,
  ]);

  const handleCancelConfirmDialog = () => {
    setShowConfirmationDialog(false);
    setLimitCase('');
  };

  const toggleEventSelection = useCallback(
    (rowId, event) => {
      if (eventsChecked.includes(rowId)) {
        if (eventsChecked.length > 1) {
          groupSelectionState !== CheckedState.INDETERMINATE &&
            setGroupSelectionState(CheckedState.INDETERMINATE);
        } else {
          setGroupSelectionState(CheckedState.UNCHECKED);
        }
        setEventsChecked(eventsChecked.filter((row) => row !== rowId));
        setSelectedAssets(selectedAssets.filter((item) => item.caseId !== rowId));
      } else {
        if (events.length === eventsChecked.length + 1) {
          setGroupSelectionState(CheckedState.CHECKED);
        } else {
          groupSelectionState !== CheckedState.INDETERMINATE &&
            setGroupSelectionState(CheckedState.INDETERMINATE);
        }
        setEventsChecked([...eventsChecked, rowId]);
        let arr = [...selectedAssets];
        arr.push({
          caseId: rowId,
          assetName: event?.asset?.name,
          siteName: event?.site?.name,
          siteId: event?.site?.id,
          assetType: event?.asset?.type,
          caseLevel: event?.caseLevel,
          assetId: event?.asset?.id,
          caseType: event?.type,
          description: event?.description,
          code: event?.code,
        });
        setSelectedAssets(arr);
      }
    },
    [events, eventsChecked, setEventsChecked, selectedAssets, groupSelectionState],
  );

  const handleSendCase = useCallback(
    (e, cellValue, entity) => {
      e.stopPropagation();
      let url = `/escalate-case?isSiteLevelCase=${cellValue?.isSiteLevelCase ? true : false}`;
      if (entity?.id) {
        url += `&id=${entity.id}`;
      }
      if (cellValue?.site) {
        url += `&site=${cellValue.site}`;
      }
      if (cellValue?.asset) {
        url += `&asset=${cellValue.asset}`;
      }
      if (cellValue?.description) {
        url += `&description=${encodeURIComponent(cellValue.description)}`;
      }
      if (cellValue?.siteId) {
        url += `&siteId=${cellValue.siteId}`;
      }
      if (cellValue?.assetId) {
        url += `&assetId=${cellValue.assetId}`;
      }
      if (cellValue?.type) {
        url += `&type=${cellValue.type}`;
      }
      if (entity?.code) {
        url += `&code=${entity.code}`;
      }
      if (entity?.start) {
        url += `&start=${entity.start}`;
      }
      if (entity?.end) {
        url += `&end=${entity.end}`;
      }
      if (entity?.assetStateValue) {
        url += `&assetState=${entity.assetStateValue}`;
      }
      const height = 520;
      const width = 465;
      const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
      if (selectedAssets?.length <= 1) {
        const escalateWindow = window.open(
          url,
          '_blank',
          `toolbar=no,addressbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,left=${x},top=${y},width=${width},height=${height}`,
        );
        escalateWindow.onbeforeunload = () => {
          refetchCases();
        };
      } else {
        const {
          differentSite,
          differentType,
          differentCaseType,
          isSameAsset,
          isSiteLevelCase,
          isFleetLevelCase,
        } = differencesOfSelectedAssets;

        const assetIds = [],
          caseIds = [],
          assetNames = [];
        Array.isArray(selectedAssets) &&
          selectedAssets.forEach((item) => {
            assetIds.push(item.assetId);
            caseIds.push(item.caseId);
            assetNames.push(item.assetName);
          });

        url += `&assetIds=${assetIds.join(',')}`;
        url += `&caseIds=${caseIds.join(',')}`;
        url += `&assetNames=${assetNames.join(',')}`;

        if (isSiteLevelCase || isFleetLevelCase) {
          setBulkErrorCase(
            t('escalate_error.site_level', 'Cannot escalate for site/fleet level cases'),
          );
        } else if (differentSite) {
          setBulkErrorCase(
            t('escalate_error.different_site', 'Cannot escalate for assets in different sites'),
          );
        } else if (isSameAsset) {
          setBulkErrorCase(
            t('escalate_error.uniq_asset', 'Cannot escalate multiple cases for the same asset'),
          );
        } else if (differentType) {
          setLimitCase(
            t(
              'escalate_error.limit_asset_type',
              'You are performing an action on assets from different asset types. \nDo you wish to continue?',
            ),
          );
          setEscalatePopoutUrl(url);
          setShowConfirmationDialog(true);
        } else if (differentCaseType) {
          setBulkErrorCase(
            t('escalate_error.different_case_type', 'Cannot escalate for cases of different types'),
          );
        } else {
          setBulkErrorCase('');
          const escalateWindow = window.open(
            url,
            '_blank',
            `toolbar=no,addressbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,left=${x},top=${y},width=${width},height=${height}`,
          );
          escalateWindow.onbeforeunload = () => {
            refetchCases();
          };
          setSelectedAssets([]);
          setEventsChecked([]);
        }
      }
    },
    [selectedAssets, t, differencesOfSelectedAssets, refetchCases],
  );

  useEffect(() => {
    if (caseEscalateError) {
      let option = JSON.parse(JSON.stringify(caseEscalateError));
      const msg = option.message;
      option.message = <NotificationMessage>{msg}</NotificationMessage>;
      notify(option);
      setCaseEscalateError('');
    }
  }, [caseEscalateError, setCaseEscalateError, notify]);

  const handleCaseNotes = useCallback(
    (e, cellValue) => {
      killEventPropagation(e);
      setCaseNotes(cellValue);
      setCaseNotesModal(true);
    },
    [setCaseNotesModal],
  );

  const closeNoteModal = useCallback(
    (e) => {
      killEventPropagation(e);
      setCaseNotes({});
      setCaseNotesModal(false);
    },
    [setCaseNotesModal],
  );

  const getEventSiteTimeAndZone = useCallback(
    (event, loc = 'start') => {
      const site = getSiteById(event.site?.id);
      return getDateTimeBasedOnZone(event?.[loc], site?.timezone);
    },
    [getSiteById],
  );

  const bulkActionsEventHandler = useCallback(
    (entity, type) => {
      const { differentSite, differentType, differentCaseType } = differencesOfSelectedAssets;
      if (
        eventsChecked.length > 0 &&
        (type === ActionKeys.EVENT_IN_PROGRESS || type === ActionKeys.EVENT_CLOSE)
      ) {
        if (differentSite) {
          setLimitCase(
            t(
              'action_error.limit_site',
              'You are performing an action on assets from different sites. \nDo you wish to continue?',
            ),
          );
          setShowConfirmationDialog(true);
        } else if (differentType) {
          setLimitCase(
            t(
              'action_error.limit_asset_type',
              'You are performing an action on assets from different asset types. \nDo you wish to continue?',
            ),
          );
          setShowConfirmationDialog(true);
        } else if (differentCaseType) {
          setLimitCase(
            t(
              'action_error.limit_case_type',
              'You are performing an action on assets from different case types. \nDo you wish to continue?',
            ),
          );
          setShowConfirmationDialog(true);
        } else {
          setLimitCase('');
          setShowConfirmationDialog(false);
        }
      }

      if (eventsChecked.length > 0 && type === ActionKeys.EVENT_ADD_NOTE) {
        if (differentSite)
          bulkActionsErrorNotification(
            t('action_error.different_site_asset', 'Assets are from different sites'),
            selectedAssets?.length,
          );
        else if (differentType)
          bulkActionsErrorNotification(
            t('action_error.different_type', 'Assets are of different types'),
            selectedAssets?.length,
            entity?.site,
          );
      }

      if (selectedAssets?.length === 1 && !eventsChecked?.includes(entity?.id)) {
        bulkActionsErrorNotification(
          t('action_error.different_asset', 'Selected different asset'),
          selectedAssets?.length,
          entity?.site,
        );
      } else if (
        selectedAssets?.length > 1 &&
        (!eventsChecked.includes(entity?.id) ||
          differentSite ||
          differentType ||
          (type !== ActionKeys.EVENT_ADD_NOTE && differentCaseType))
      ) {
        if (!eventsChecked.includes(entity?.id))
          bulkActionsErrorNotification(
            t('action_error.different_asset', 'Selected different asset'),
            selectedAssets?.length,
          );
      } else {
        setCurrentEvent(entity);
        if (type == ActionKeys.EVENT_ADD_NOTE) {
          setAddNoteDialog(true);
        } else if (type == ActionKeys.EVENT_CLOSE) {
          setCloseCaseDialog(true);
        } else {
          if (eventsChecked?.length > 0) {
            changeBulkCaseStatus({ state: type, caseIds: eventsChecked });
            setIsBulkCaseStateChanged(true);
          } else {
            changeCaseStatus({ state: type, id: entity.id });
            setIsCaseStateChanged(true);
          }
        }
      }
    },
    [
      differencesOfSelectedAssets,
      eventsChecked,
      selectedAssets,
      t,
      bulkActionsErrorNotification,
      setAddNoteDialog,
      changeBulkCaseStatus,
      changeCaseStatus,
      setIsCaseStateChanged,
      setCloseCaseDialog,
    ],
  );

  /**
   * Case action hanlder
   */
  const eventsActionHandler = useCallback(
    ({ entity, type }) => {
      switch (type) {
        case ActionKeys.EVENT_ADD_NOTE:
          bulkActionsEventHandler(entity, type);
          break;
        case ActionKeys.EVENT_LOG_ACTION:
          setCurrentEvent(entity);
          setRocLogsDialog(true);
          break;
        case ActionKeys.EVENT_IN_PROGRESS:
        case ActionKeys.EVENT_CLOSE:
          bulkActionsEventHandler(entity, type);
          setActionType(type);
          break;
        case ActionKeys.EVENT_SNOOZE:
          setCurrentEvent(entity);
          setSnoozeDialog(true);
          break;
        case ActionKeys.CREATE_ALERT:
          setCurrentEvent(entity);
          handleShowAlertDialog();
          break;
        default:
          break;
      }
    },
    [handleShowAlertDialog, setSnoozeDialog, setRocLogsDialog, bulkActionsEventHandler],
  );

  /**
   * Function used by useTableFactories to bootstrap the cell values map
   * before returning the factory to process cells for that row. This should
   * contain values for all columns that should be expected to show data in
   * the table.
   */
  const cellValueMapFn = useCallback(
    (event) => {
      // Required details
      const { state } = event.asset.metrics || {};
      const { windSpeed, windDirection } = event.readings.general || {};
      const { metrics } = event.site || {};

      // Temporary inline mocks
      const stateHistory = [
        {
          id: '3407834gjkhgsf',
          time: '11:14',
          start: '2019-11-19T11:55:14.867Z',
          end: '2019-11-19T11:59:14.867Z',
          state: AssetState.TRIPPED,
        },
        {
          id: '340dhd34gjkhgsf',
          time: '08:23',
          start: '2019-11-19T17:55:14.867Z',
          end: '2019-11-19T21:51:14.867Z',
          state: AssetState.STOPPED,
        },
      ];

      // Temporary inline mocks
      const assetAlarms = [
        {
          code: '6',
          description: 'System OK',
          severity: 'low',
          // Current alarms don't have end time
        },
        {
          code: '414',
          description: 'Power reduced operation',
          severity: 'high',
          // Current alarms don't have end time
        },
        {
          code: '1002',
          description: 'Pitch Bearing Rotate Function paused with additional description',
          severity: 'medium',
        },
        {
          code: '608',
          description: 'De-icing system active',
          severity: 'low',
        },
        {
          code: 'DI_In_Gen_cooling_low',
          description: 'Generator cooling air over temperature',
          severity: 'high',
        },
      ];

      const alarmsWithDuration = [...assetAlarms].map((alarm) => {
        alarm.duration = randomIntWithRange(5, 60) + ' m';
        return alarm;
      });

      const indexToReplace = randomIntWithRange(0, assetAlarms.length - 1);
      alarmsWithDuration[indexToReplace].code = event.code;
      alarmsWithDuration[indexToReplace].description = event.description;

      // Define and set the cell value mapping for each column. This will feed both
      // primitive cells and custom cells.
      return {
        [EventsColumns.SELECT]: { id: event.id, eventsChecked: eventsChecked },
        //  [EventsColumns.GROUP]: event.site && event.site.group,
        [EventsColumns.SITE]: event.site && {
          name: event.site?.name,
          id: event.site?.id,
          type: event.site?.type,
        },
        [EventsColumns.ASSET]: {
          isLoading: isAssetsLoading || inverterAssetsLoading,
          name: event.asset?.name,
          id: event.asset?.id,
          type: event.asset?.type,
        },
        [EventsColumns.SI]: event && event.specialInstructions,
        [EventsColumns.ASSET_STATUS]: {
          state,
          stateHistory,
          assetPrevState: event?.assetPrevState,
          assetConnection: event?.assetConnection,
        },
        [EventsColumns.SYSTEM_RESET]: event?.resetStatus && {
          sequenceName: event.sequenceName,
          resetStatus: event.resetStatus,
          result: event.result,
          resultReason: event.resultReason,
        },
        [EventsColumns.CODE]: { event, assetAlarms: [...alarmsWithDuration] },
        [EventsColumns.SEVERITY]: event && event.severity,
        [EventsColumns.EVENT_TYPE]: event && getMappedEventType(event.type),
        [EventsColumns.EVENT_STATUS]: {
          caseStatus: event && getMappedEventStatus(event.caseState),
          snoozedEndTime:
            event &&
            getMappedEventStatus(event.caseState) &&
            event.caseState.includes(MonitorCaseStatus.SNOOZED)
              ? event.snoozedEndDate
              : '',
          snoozedReason:
            event &&
            getMappedEventStatus(event.caseState) &&
            event.caseState.includes(MonitorCaseStatus.SNOOZED)
              ? event.snoozedReason
              : '',
          caseState: event && event.caseState.includes(MonitorCaseStatus.SNOOZED),
        },
        [EventsColumns.DESCRIPTION]: {
          event,
          status: getMappedEventStatus(event.status),
          assetAlarms: [...alarmsWithDuration],
        },

        [EventsColumns.DURATION]: event && getDuration(event.start, event.end),
        [EventsColumns.SITE_TIME]: event && getEventSiteTimeAndZone(event),
        [EventsColumns.CLOSE_TIME]: event && getEventSiteTimeAndZone(event, 'end'),
        [EventsColumns.LOCAL_TIME]: event && getDateTimeBasedOnZone(event.start),
        [EventsColumns.NOTES]: event && event,
        [EventsColumns.ALERTS]: event && event,
        [EventsColumns.TASKS]: event && [
          ...new Set(
            event.tasks
              .filter((task) => task.state !== 'Closed')
              .map((task) => task?.source?.charAt(0)),
          ),
        ],
        [EventsColumns.ESCALATIONS]: event && Array.isArray(event.escalated) && event.escalated[0],
        [EventsColumns.ESCALATE]: event && {
          site: event.site?.name,
          asset: event.asset?.name,
          start: event?.start,
          description: event.description,
          severity: event.severity,
          isSiteLevelCase: event?.caseLevel === EntityType.SITE,
          siteId: event?.site?.id,
          assetId: event?.asset?.id,
          type: event?.type,
        },
        [EventsColumns.WIND]: {
          direction: windDirection,
          windMS: windSpeed,
        },
        [EventsColumns.ASSET_MAKE]: event?.asset?.make,
        [EventsColumns.ASSET_MODEL]: event?.asset?.model,
        [EventsColumns.CUSTOMER]: event.site && event.site.customer && event.site.customer.name,
        [EventsColumns.COUNTRY]: event?.site?.country,
        [EventsColumns.PRIORITY]: event && event.priority,
        [EventsColumns.PRICE]: metrics && formatToCurrency(metrics.revenue.priceMW, i18n.language),
        [EventsColumns.LOST_REVENUE]:
          metrics && formatToCurrency(metrics.revenue.lostRevenue, i18n.language),
        [EventsColumns.EXPECTED_PRODUCTION]: '$1.00', // TODO: ADD TO MOCKS!!
        [EventsColumns.SERIAL_NUMBER]: event?.asset?.serialNumber,
        [EventsColumns.SYSTEM_NUMBER]: event?.asset?.systemNumber,
        [EventsColumns.TYPE]: getCaseLevelIcon(
          event?.caseLevel,
          event?.asset?.type,
          getEntityTypeFromSiteObj(event?.site),
        ),
        [EventsColumns.CASE_DETAIL]: event && event,
        [EventsColumns.ACTIONS_MENU]: getAuthorizedMenuItems([event]),
        [EventsColumns.CASE_ID]: event?.id,
        [EventsColumns.BOP_SITE]: getBOPSiteIcon(event),
      };
    },
    [
      eventsChecked,
      getAuthorizedMenuItems,
      getEventSiteTimeAndZone,
      isAssetsLoading,
      inverterAssetsLoading,
    ],
  );

  /**
   * Factory function to generate custom table cell components in
   * the case that the contents are not simply primitives.
   */
  const customCellFn = useCallback(
    (columnKey, cellValue, event) => {
      const isDisconnected =
        event?.assetConnection?.value === AssetConnectionStatus.NOCOMM ||
        event?.assetConnection?.value === AssetConnectionStatus.NO_DATA;
      const isFleetCase = event?.caseLevel === EntityType.FLEET;

      switch (columnKey) {
        case EventsColumns.SELECT:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                setBulkErrorCase('');
                toggleEventSelection(cellValue.id, event);
              }}
            >
              <Checkbox
                checkState={
                  eventsChecked.includes(cellValue.id)
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case EventsColumns.GROUP:
          return <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell>;
        case EventsColumns.SITE:
          return cellValue?.name ? (
            <EntityCell
              tooltip={cellValue?.name}
              onClick={(e) => handleSiteClick(e, event?.site)}
              className="site-name"
            >
              {cellValue?.name}
            </EntityCell>
          ) : (
            <></>
          );
        case EventsColumns.ASSET:
          return !cellValue.isLoading ? (
            cellValue.name ? (
              <EntityCell
                tooltip={cellValue.name}
                onClick={(e) => handleAssetClick(e, cellValue, event)}
                className="asset-name"
              >
                {cellValue.name}
              </EntityCell>
            ) : (
              <></>
            )
          ) : (
            <StyledLoadingCell />
          );
        case EventsColumns.DESCRIPTION:
          return (
            // Commenting Hover over component for now
            // <EventCodeMenu
            //   event={cellValue.event}
            //   assetAlarms={cellValue.assetAlarms}
            //   containerRef={containerRef}
            // >
            <TooltipCell tooltip={cellValue?.event?.description}>
              <StyledEventType>
                <TableTitle>
                  <StyledSpan isDisconnected={isDisconnected}>
                    {cellValue?.event?.description}
                  </StyledSpan>
                </TableTitle>
              </StyledEventType>
            </TooltipCell>
            // </EventCodeMenu>
          );
        case EventsColumns.EVENT_TYPE:
          return (
            <TooltipCell tooltip={t(cellValue.translationKey)}>
              {cellValue.key === CaseType.RNS ? (
                <img src={rnsImg} alt={t(cellValue.translationKey)} />
              ) : (
                <Icon icon={cellValue.icon} size={16} color={globalColors.slate5}></Icon>
              )}
            </TooltipCell>
          );
        case EventsColumns.EVENT_STATUS:
          return cellValue?.caseState ? (
            <SnoozeDetails endTime={cellValue?.snoozedEndTime} reason={cellValue?.snoozedReason}>
              <Icon icon={cellValue?.caseStatus?.icon} size={16} color={globalColors.slate5}></Icon>
            </SnoozeDetails>
          ) : (
            <TooltipCell tooltip={t(cellValue?.caseStatus?.translationKey)}>
              <Icon icon={cellValue?.caseStatus?.icon} size={16} color={globalColors.slate5}></Icon>
            </TooltipCell>
          );
        case EventsColumns.TYPE:
          return <TypeIcon icon={cellValue} />;
        case EventsColumns.SI:
          return isSIsLoading ? (
            <StyledLoadingCell />
          ) : (
            <>
              {cellValue?.length === 1 ? (
                <IconContainer invalid={isBeforeToday(cellValue[0]?.validDateBy)}>
                  <SpecialInstructionIcon icon={Icons.SPECIAL_INSTRUCTION} />
                </IconContainer>
              ) : cellValue?.length > 1 ? (
                <Badge color={globalColors.stone1} label={cellValue.length} small />
              ) : (
                ''
              )}
            </>
          );
        case EventsColumns.ASSET_STATUS:
          return (
            // Commenting Hover over component for now
            // <StateMenu
            //   state={cellValue.state}
            //   stateHistory={cellValue.stateHistory}
            //   containerRef={containerRef}
            // />
            <AssetPreviousState
              currentState={cellValue?.state}
              prevState={cellValue?.assetPrevState}
              connectionStatus={cellValue?.assetConnection}
            >
              <Badge
                color={getTurbineStateColor(cellValue?.state?.value)}
                label={getTurbineStateType(cellValue?.state?.value)}
                medium
                isDisconnected={isDisconnected}
              />
            </AssetPreviousState>
          );
        case EventsColumns.CODE:
          return (
            // Commenting Hover over component for now
            // <EventCodeMenu
            //   event={cellValue.event}
            //   assetAlarms={cellValue.assetAlarms}
            //   containerRef={containerRef}
            // >
            <StyledSpan isDisconnected={isDisconnected}>{cellValue.event.code}</StyledSpan>
            // </EventCodeMenu>
          );
        case EventsColumns.SYSTEM_RESET:
          return (
            <AutomationStatus
              status={cellValue?.resetStatus}
              name={cellValue?.sequenceName}
              result={cellValue?.result}
              reason={cellValue?.resultReason}
            >
              {cellValue?.resetStatus === SystemResetStatus.COMPLETED &&
              cellValue?.result === SystemResetResult.FAILED ? (
                <img src={automationCompletedFailed} />
              ) : (
                <SystemResetIcon resetInfo={cellValue} />
              )}
            </AutomationStatus>
          );
        case EventsColumns.SEVERITY:
          return <Severity level={cellValue} />;
        case EventsColumns.DURATION:
          return (
            <span className={cellValue.totHours >= DURATION_ALERT ? 'danger-txt' : ''}>
              {cellValue.formatted}
            </span>
          );
        case EventsColumns.SITE_TIME:
        case EventsColumns.CLOSE_TIME:
        case EventsColumns.LOCAL_TIME:
          return (
            <>
              <StyledTimeDate>
                <span>{cellValue.time}</span>
                <span>{cellValue.date}</span>
                <span>{cellValue.tz}</span>
              </StyledTimeDate>
            </>
          );
        case EventsColumns.NOTES:
          return (
            <StyledNotes
              className={isFleetCase ? 'disabled' : ''}
              onClick={(e) =>
                isFleetCase ? killEventPropagation(e) : handleCaseNotes(e, cellValue)
              }
            >
              <NoteIcon />
            </StyledNotes>
          );
        case EventsColumns.ALERTS:
          return isAlertsLoading ? (
            <StyledLoadingCell />
          ) : (
            <AlertTableIcons
              alerts={getTranslatedValues(cellValue)}
              onClick={(_, alert) => {
                setCurrentEvent(event);
                handleViewAlert(alert);
              }}
            />
          );
        case EventsColumns.ESCALATIONS:
          return (
            <>
              {cellValue && (
                <EscalatedInfo escalated={cellValue}>
                  <MailButton>
                    <MailIcon />
                  </MailButton>
                </EscalatedInfo>
              )}
            </>
          );
        case EventsColumns.ESCALATE:
          return (
            <EscalateButton
              cellValue={cellValue}
              entity={event}
              handleSendCase={handleSendCase}
              isFleetCase={isFleetCase}
              bulkErrorCase={bulkErrorCase}
              eventsLength={eventsChecked.length}
              disabled={eventsChecked.length > 0 && !eventsChecked.includes(event?.id)}
              onMenuClose={() => {
                setBulkErrorCase('');
              }}
            />
          );
        case EventsColumns.CUSTOMER:
          return (
            <TooltipCell tooltip={event?.site?.customer?.name}>
              {event?.site?.customer?.name}
            </TooltipCell>
          );
        case EventsColumns.WIND:
          return (
            <NoWrap>
              <SetWidth width="32px">
                <StyledSpan isDisconnected={isDisconnected}>
                  {roundNumber(cellValue.windMS, 1) || '-'}
                </StyledSpan>
              </SetWidth>
            </NoWrap>
          );
        case EventsColumns.ACTIONS_MENU:
          return (
            <ActionsMenu
              containerRef={containerRef}
              entity={event}
              menuIconSmall
              menuItems={cellValue}
              onAction={eventsActionHandler}
              disabledActions={disabledBulkActions()}
              disabled={eventsChecked.length > 0 && !eventsChecked.includes(event?.id)}
            />
          );
        case EventsColumns.CASE_DETAIL:
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          return (
            <button
              className="row-hover-icon"
              type="button"
              onClick={(e) => onEventSelect(e, cellValue)}
            >
              <TableArrow />
            </button>
          );
        case EventsColumns.BOP_SITE:
          return <TypeIcon icon={cellValue} />;
        default:
          return null;
      }
    },
    [
      getTranslatedValues,
      handleAssetClick,
      handleSiteClick,
      onEventSelect,
      eventsChecked,
      toggleEventSelection,
      eventsActionHandler,
      handleSendCase,
      t,
      handleCaseNotes,
      isAlertsLoading,
      bulkErrorCase,
      disabledBulkActions,
      isSIsLoading,
      handleViewAlert,
    ],
  );

  const handleSelectAll = useCallback(
    (e) => {
      killEventPropagation(e);
      e.preventDefault();
      setBulkErrorCase('');
      if (groupSelectionState === CheckedState.CHECKED) {
        setGroupSelectionState(CheckedState.UNCHECKED);
        setEventsChecked([]);
        setSelectedAssets([]);
      } else {
        setGroupSelectionState(CheckedState.CHECKED);
        selectAllEvents();
      }
    },
    [groupSelectionState, selectAllEvents],
  );

  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case EventsColumns.SELECT:
          return (
            <StyledCheckbox onClick={handleSelectAll}>
              <Checkbox checkState={groupSelectionState} label={''} />
            </StyledCheckbox>
          );
        default:
          return null;
      }
    },
    [groupSelectionState, handleSelectAll],
  );

  const handleValueSelect = (e, row) => {
    setCurrentRow([row?.id]);
    onRowSelect(e, row);
  };

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: EventsColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
    sortAction,
    sortedDirection,
    draggable: true,
    filterValues: modifiedFiltersValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: modifiedFilters,
    rowsSelected: eventsChecked.length !== 0,
  });

  if (!ready || isCasesLoading) {
    return <Loader />;
  }

  if (!columns || columns.length === 0) {
    return null;
  }

  return (
    <PageContainer i18nReady={ready}>
      <DraggableTable
        scrollable
        columns={tableColumns}
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        sortAction={sortAction}
        values={events ?? []}
        onValueSelect={handleValueSelect}
        dropHandler={onDrop}
        rowKeyProperty="id"
        rowsSelected={currentRow}
      />
      {snoozeDialog && (
        <SnoozeDialog
          entities={[currentEvent]}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          onConfirm={handleSubmitSnoozeValue}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setSnoozeDialog(false);
          }}
        />
      )}
      {addNoteDialog && (
        <AddNoteDialog
          isOpen={addNoteDialog}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          selectedAssets={selectedAssets}
          entity={currentEvent} // single add note dialog. Will change when bulk notes are added
          entityType={EntityType.EVENT}
          onConfirm={handleAddNote}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setAddNoteDialog(false);
          }}
        />
      )}
      {rocLogsDialog && (
        <RocLogsAction
          entity={currentEvent}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          entityType={EntityType.CASE}
          onConfirm={handleAddRocLogs}
          mode={FormMode.CREATE}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setRocLogsDialog(false);
          }}
        />
      )}
      {closeCaseDialog && (
        <CloseCaseAction
          entity={currentEvent}
          siteType={getEntityTypeFromSiteObj(currentEvent?.site)}
          selectedAssets={selectedAssets}
          entityType={EntityType.CASE}
          onConfirm={handleCloseCaseStatus}
          mode={FormMode.CREATE}
          eventsChecked={eventsChecked}
          onClose={(e) => {
            killEventPropagation(e);
            setCurrentEvent();
            setCloseCaseDialog(false);
          }}
        />
      )}
      {caseNotesModal && (
        <CaseNotesDialog
          entity={caseNotes}
          isOpen={caseNotesModal}
          onClose={closeNoteModal}
          siteType={getEntityTypeFromSiteObj(caseNotes?.site)}
        />
      )}
      {showAlertDialog && (
        <AlertDialog
          alert={currentAlert}
          groupDetails={groupDetails}
          entity={
            currentEvent?.caseLevel === EntityType.SITE ? currentEvent?.site : currentEvent?.asset
          }
          entityType={
            currentEvent?.caseLevel === EntityType.SITE
              ? EntityTypeMapping[getEntityTypeFromSiteObj(currentEvent?.site)]
              : EntityTypeMapping[currentEvent?.asset?.type]
          }
          isOpen
          mode={currentAlert ? FormMode.VIEW : FormMode.CREATE}
          onCancel={handleCancelAlertDialog}
          onConfirm={currentAlert ? handleEditAlert : handleSaveAlert}
          alertType={AlertType.PLANNED_OUTAGE}
        />
      )}
      {showConfirmationDialog && (
        <ConfirmationDialog
          contentWidth={false}
          header={t('confirm_delete_header', 'Confirmation')}
          isOpen={true}
          padContent={true}
          onConfirm={handleOpenConfirmDialog}
          onCancel={handleCancelConfirmDialog}
          confirmLabel={t('confirm_yes_button', 'Yes')}
          cancelLabel={t('confirm_no_button', 'No')}
        >
          <p>{limitCase}</p>
        </ConfirmationDialog>
      )}
    </PageContainer>
  );
};

EventsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  tableType: PropTypes.string.isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onEventSelect: PropTypes.func,
  onDrop: PropTypes.func,
  selectedRibbonFilter: PropTypes.object,
  filterDefs: PropTypes.object,
  setFilterDefs: PropTypes.func,
  setCaseHistoryRefreshing: PropTypes.func,
  updateRibbonFilterCounts: PropTypes.func,
};

EventsTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onEventSelect: () => null,
  onDrop: () => null,
  selectedRibbonFilter: null,
  setFilterDefs: () => null,
  filterDefs: {},
  updateRibbonFilterCounts: () => null,
};

export default EventsTable;
