import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

/**
 * Use assets detail.
 *
 * @param assetId the asset id
 */
export const useAssetDetail = ({ assetId }) => {
  const {
    assets: { getAssetById },
    sites: { getSiteById },
  } = useStoreState(({ assets, sites }) => ({ assets, sites }));
  return useMemo(() => {
    const currentAsset = getAssetById(assetId);
    if (currentAsset) {
      const site = getSiteById(currentAsset.site.id) || {};
      return {
        ...currentAsset,
        site,
      };
    }
    return {
      id: assetId,
      site: { timezone: 'UTC' },
    };
  }, [getSiteById, getAssetById, assetId]);
};
