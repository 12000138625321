import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

const ExportIcon = styled(Icon).attrs(() => ({
  size: 12,
  color: globalColors.slate4,
}))``;

const StyledButton = styled.button`
  color: ${globalColors.slate4};
  background: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  svg {
    margin-right: 6px;
  }
`;

export const ExportGraphButton = (props) => {
  const { t } = useTranslation(['inspections.performance']);

  return (
    <StyledButton {...props} type="button">
      <ExportIcon viewbox={'0 0 12 12'} icon={Icons.EXPORT} />
      {t('export', 'Export')}
    </StyledButton>
  );
};

export default ExportGraphButton;
