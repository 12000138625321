import axios from 'axios';

import { Feature, SegmentOffering } from '@ge/models/constants';

import { getIacSegmentAccessToken } from './iac';

const FEATURES = Object.values(Feature);

const { CancelToken } = axios;

// Set config defaults when creating the instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DIGITAL_WIND_FARM_API,
});

/**
 * Attempt to extract the segment offering from the URL path
 * @param url
 * @returns {null|String}
 */
const getSegmentOffering = (url) => {
  const paths = url.split('/');
  if (paths.length > 1) {
    return paths[1];
  }
  return null;
};

// Add an interceptor to inject the auth token from session eagerly.
axiosInstance.interceptors.request.use(
  async (config) => {
    // Creating a cancelToken allows us to cancel the request in case there's
    // something wrong, like no access token.
    let cancel;
    config.cancelToken = new CancelToken((c) => {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });

    let iacAccessToken;
    try {
      iacAccessToken = await getIacSegmentAccessToken(getSegmentOffering(config.url));
    } catch (e) {
      cancel(`IAC: ${e.message}`);
    }

    if (iacAccessToken) {
      config.headers.authorization = iacAccessToken;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

const request = (config, withHeaders = false) => {
  const onSuccess = (response) => (withHeaders ? response : response.data);
  const onError = (error) => Promise.reject(error);

  // Ensure that default transforms are still included.
  config.transformResponse = [
    ...axios.defaults.transformResponse,
    ...(config.transformResponse || []),
  ];
  return axiosInstance(config)
    .then(onSuccess)
    .catch(onError);
};

export const get = (url, options) =>
  request({
    method: 'get',
    url,
    ...options,
  });
export const post = (url, data, options, withHeaders = false) =>
  request(
    {
      method: 'post',
      url,
      data,
      ...options,
    },
    withHeaders,
  );
export const put = (url, data, options) =>
  request({
    method: 'put',
    url,
    data,
    ...options,
  });
export const patch = (url, data, options) =>
  request({
    method: 'patch',
    url,
    data,
    ...options,
  });
export const deleteItem = (url, options) =>
  request({
    method: 'delete',
    url,
    ...options,
  });
export const deleteItemWithBody = (url, data, options) =>
  request({
    method: 'delete',
    url,
    data,
    ...options,
  });
export const getSegmentTokens = async (features = FEATURES) => {
  try {
    const segmentTokens = await features.reduce(async (tokens, feature) => {
      // map feature to segment offering
      const segmentOffering = {
        [Feature.ANALYZE]: SegmentOffering.ANALYZE,
        [Feature.COMMON]: SegmentOffering.COMMON,
        [Feature.INSPECTIONS]: SegmentOffering.INSPECTIONS,
        [Feature.MANAGE]: SegmentOffering.MANAGE,
        [Feature.MONITOR]: SegmentOffering.MONITOR,
      }[feature];

      if (!segmentOffering) {
        console.warn(`Feature '${feature}' not recognized!`);

        return tokens;
      }

      // TODO: clean this up but right now if a user doesn't have a specific token it will throw an error
      // it should just look at tokens the user actually has in their permissions, and only throw an error if there's a problem getting those
      // didn't want to tackle this deeper fix now to avoid introducing add'l problems
      try {
        const segmentToken = await getIacSegmentAccessToken(segmentOffering);

        const _tokens = await tokens;

        if (segmentToken) {
          _tokens[segmentOffering] = segmentToken;
        }

        return _tokens;
      } catch (err) {
        return tokens;
      }
    }, Promise.resolve({}));

    return { segmentTokens };
  } catch (err) {
    console.error(err);

    return null;
  }
};

export const client = axiosInstance;
