import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Menu, MenuTable, placements, MenuLoader } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDuration } from '@ge/shared/util/time-date';
import { getTurbineStateType, getTurbineStateColor } from '@ge/shared/util/turbine-state';

const MOCK_AVAILABILITY_DATA = [
  { id: 1, status: 'good', duration: 288 },
  { id: 2, status: 'error', duration: 346 },
  { id: 3, status: 'good', duration: 432 },
  { id: 4, status: 'warn', duration: 230 },
  { id: 5, status: 'good', duration: 1440 },
  { id: 6, status: 'error', duration: 144 },
];

const StyledTicketsMenu = styled.div`
  font-size: 11px;
  text-align: left;
  display: inline;
  position: relative;
  width: 100%;
`;

const BadgeButton = styled.button`
  padding: 0;
  width: 100%;
`;

const GrayedTd = styled.div`
  color: ${(props) => props.theme.menu.tableHeaderTextColor};
  text-align: left;
`;

// TODO: swap this out with the status bar from #648 when it's ready
const StatusBar = styled.div`
  align-items: stretch;
  border-radius: 2px;
  display: flex;
  flex-flow: row nowrap;
  height: 8px;
  justify-content: stretch;
  overflow: hidden;
  margin: 5px;
  > * {
    padding: 0;
    + * {
      margin-left: 2px;
    }
  }
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }
  }
`;

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
`;

const last48InMinutes = 48 * 60;

const getDurationPercentage = (duration) =>
  Math.round(((duration || last48InMinutes) / last48InMinutes) * 100);

const StatusBarSegment = styled.div`
  background-color: ${({ theme, status }) =>
    ({
      good: theme.entityDetails.statusBar.goodColor,
      warn: theme.entityDetails.statusBar.warnColor,
      error: theme.entityDetails.statusBar.errorColor,
    }[status])};
  flex: 1 1 ${({ duration }) => getDurationPercentage(duration)}%;
`;

StatusBarSegment.propTypes = {
  status: PropTypes.oneOf(['good', 'warn', 'error']).isRequired,
  duration: PropTypes.number.isRequired,
};

StatusBarSegment.defaultProps = {
  status: 'good',
  duration: last48InMinutes,
};

const StateMenu = ({ state, stateHistory, onClick, containerRef }) => {
  const { t } = useTranslation(['monitor.issues']);
  const [anchorEl, setAnchorEl] = useState(null);

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const availability = MOCK_AVAILABILITY_DATA;
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTicketsMenu onMouseEnter={(e) => showMenu(e)} onMouseLeave={() => handleMenuClose()}>
      <BadgeButton type="button" onClick={() => onClick()}>
        <Badge color={getTurbineStateColor(state)} label={getTurbineStateType(state)} fullWidth />
      </BadgeButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.BOTTOM_START}
        onClick={(e) => {
          e.preventDefault();
          killEventPropagation(e);
        }}
      >
        {/* #TODO : Have to change the duration based on the stateHistory */}
        <StyledTitle>
          <h4 className="menu-title-txt">{t(`state.state`, '')}</h4>
          <div className="menu-title-duration">{'Last 24h'}</div>
        </StyledTitle>
        <StatusBar>
          {availability.map(({ id, status, duration }) => (
            <StatusBarSegment key={id} status={status} duration={duration} />
          ))}
        </StatusBar>
        <ConditionalRender shouldRender={!stateHistory}>
          <MenuLoader />
        </ConditionalRender>
        <ConditionalRender shouldRender={!!stateHistory}>
          <MenuTable disableHover={true}>
            <table className="state-table">
              <thead>
                <tr>
                  <th>{t('state.state', 'State')}</th>
                  <th>{t('state.duration', 'Duration')}</th>
                  <th>{t('state.time_tz', 'Time')}</th>
                </tr>
              </thead>
              <tbody>
                {stateHistory
                  .sort((a, b) => b.start.localeCompare(a.start))
                  .map((item) => (
                    <tr key={item.id}>
                      <td>{item.state}</td>
                      <td>
                        <GrayedTd>{getDuration(item.start, item.end).formatted}</GrayedTd>
                      </td>
                      <td>
                        <GrayedTd>
                          <StyledTimeDate>
                            <span>{dayjs(item.start).format('HH:mm')}</span>
                            <span>{dayjs(item.start).format('MM/DD/YY')}</span>
                          </StyledTimeDate>
                        </GrayedTd>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </MenuTable>
        </ConditionalRender>
      </Menu>
    </StyledTicketsMenu>
  );
};

StateMenu.propTypes = {
  state: PropTypes.string.isRequired,
  stateHistory: PropTypes.arrayOf(Object),
  onClick: PropTypes.func,
  onSelectHandler: PropTypes.func,
  containerRef: PropTypes.instanceOf(Object),
};

StateMenu.defaultProps = {
  stateHistory: null,
  onClick: () => null,
  onSelectHandler: () => null,
  containerRef: null,
};

export default StateMenu;
