import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DateTimeFormats, Tenant } from '@ge/models/constants';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  FieldGroup,
} from '../../../task-template-shared';

const FieldWrapper = styled.div`
  width: 30%;
`;

export const ViewTaskDetail = ({ task, taskPanelControls }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const tenantId = sessionStorage.getItem('tenantId');

  return (
    <div className="task-panel-row">
      <ColumnTitle>{taskPanelControls}</ColumnTitle>
      <ColumnDetail className="column-detail">
        <FieldGroup>
          <FieldWrapper className="field-wrapper">
            <DisplayLabel>{t('form.source', 'Source')}</DisplayLabel>
            <DisplayField>{t(`dynamic.types.${task.source}`, task.source)}</DisplayField>
          </FieldWrapper>
          <FieldWrapper className="field-wrapper">
            <DisplayLabel>{t('form.work_scope', 'Work Scope')}</DisplayLabel>
            <DisplayField>
              {t(`dynamic.work_scopes.${task.workScope}`, task.workScope)}
            </DisplayField>
          </FieldWrapper>
          <FieldWrapper className="field-wrapper">
            <DisplayLabel>{t('form.priority', 'Priority')}</DisplayLabel>
            <DisplayField>{t(`dynamic.priorities.${task.priority}`, task.priority)}</DisplayField>
          </FieldWrapper>
        </FieldGroup>
        <FieldGroup>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.description', 'Description')}</DisplayLabel>
            <DisplayField className="field small wordbreak">{task.description}</DisplayField>
          </div>
        </FieldGroup>
        <FieldGroup>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.eligible_start_date', 'Eligible Start Date')}</DisplayLabel>
            <DisplayField>
              {task.eligibleStartDateTz
                ? task.eligibleStartDateTz.format(DateTimeFormats.DEFAULT_DATE)
                : ''}
            </DisplayField>
          </div>
          <div className="field-wrapper">
            <DisplayLabel>{t('form.due_date', 'Due Date')}</DisplayLabel>
            <DisplayField>
              {task.dueDateTz ? task.dueDateTz.format(DateTimeFormats.DEFAULT_DATE) : ''}
            </DisplayField>
          </div>
          {tenantId.startsWith(Tenant.GESERV) && (
            <div className="field-wrapper">
              <DisplayLabel>{t('form.committed_date', 'Committed Date')}</DisplayLabel>
              <DisplayField>
                {task.committedDateTz
                  ? task.committedDateTz.format(DateTimeFormats.DEFAULT_DATE)
                  : ''}
              </DisplayField>
            </div>
          )}
        </FieldGroup>
      </ColumnDetail>
    </div>
  );
};

ViewTaskDetail.propTypes = {
  taskPanelControls: PropTypes.node,
  task: PropTypes.object.isRequired,
};

ViewTaskDetail.defaultProps = {
  taskPanelControls: null,
};
