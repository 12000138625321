import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateSendNotification } from './create-send-notification';
import { EditSendNotification } from './edit-send-notification';

export const SendNotificationSection = ({ section, templateMode }) => {
  const { collapsed, metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return <CreateSendNotification collapsed={collapsed} metadata={metadata} />;
      case TaskTemplateModes.EDIT:
        return <EditSendNotification metadata={metadata} />;
      default:
        return null;
    }
  }
};

SendNotificationSection.propTypes = {
  section: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};
