import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { Title } from '@ge/components/typography';
import { WorkersFilters } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

import { PlanningContext, DefaultFiltersWorkers } from '../../../context/planning-provider';

const FilterMenu = styled.div``;

const FilterBtn = styled.div`
  border-radius: 3px;
  padding: 4px 6px 5px;
  margin-top: 1px;
  background: ${(props) =>
    props.active ? props.theme.select.primaryBackground : props.theme.select.secondaryBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15px;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.active ? props.theme.select.chevronPrimaryColor : props.theme.select.chevronColor,
}))`
  margin-left: 2px;
`;

const FilterCountText = styled(Title).attrs(() => ({
  level: 4,
}))`
  color:${(props) => props.theme.menu.actionsMenu.textColor}
  text-transform: uppercase;
  margin: 0 4px;
`;

const StyledContent = styled.div`
  > .title {
    text-transform: uppercase;
    padding: 8px;
  }
  .footer {
    padding: 6px;
    border-top: solid 1px ${(props) => props.theme.manage.filterMenu.borderColor};
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;
    background: ${(props) => props.theme.manage.filterMenu.background};
  }
`;

const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  .title {
    border: 0;
    padding: 8px 0;
    margin: 0 8px;
    border-top: solid 1px ${(props) => props.theme.manage.filterMenu.background};
    font-weight: 700;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.manage.filterMenu.chevronColor};
    }
  }
  label {
    margin-bottom: 8px;
    margin-left: 18px;
    text-transform: capitalize;
  }
`;

export const WorkersFilter = ({ filters }) => {
  const { t } = useTranslation(['general', 'manage.planning'], { useSuspense: false });

  const {
    planningState: { workersFilter, handleSetWorkersFilter },
  } = useContext(PlanningContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setActive] = useState(false);
  const [workersFilterState, setWorkersFilterState] = useState(workersFilter.filters);
  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setWorkersFilterState(workersFilter.filters);
  };

  const handleApply = () => {
    setActive(workersFilterState != null);
    handleSetWorkersFilter(workersFilterState);
    handleMenuClose();
  };

  const handleClear = () => {
    setAnchorEl(null);
    setActive(null);
    handleSetWorkersFilter(DefaultFiltersWorkers);
  };

  const handleChange = (filterType, updateValue) => {
    const prevDef = workersFilterState[filterType];
    const hide = prevDef.value.includes(updateValue);

    const updateValues = hide
      ? prevDef.value.filter((key) => key !== updateValue)
      : [...prevDef.value, updateValue];

    const newState = {
      ...workersFilterState,
      [filterType]: {
        ...prevDef,
        value: updateValues,
      },
    };
    setWorkersFilterState(newState);
  };

  const buildCheckboxes = (filter, value) => {
    return (
      <Checkbox
        label={value.replace('_', ' ')}
        checkState={
          workersFilterState[filter]?.value.includes(value)
            ? CheckedState.CHECKED
            : CheckedState.UNCHECKED
        }
        onChange={() => handleChange(filter, value)}
        key={value}
      />
    );
  };

  useEffect(() => {
    // track the filter context and update the local filter state to re-check the checkboxes on menu open.
    setWorkersFilterState(workersFilter.filters);
  }, [workersFilter.filters]);

  const filterCounts = useMemo(
    () => workersFilter.filters[WorkersFilters.TITLE]?.value.length,
    [workersFilter.filters],
  );

  // check and initliaze active state on load if persisted filter state exists.
  useEffect(() => {
    if (filterCounts > 0) setActive(workersFilterState != null);
  }, [filterCounts, workersFilterState, setActive]);

  const checkIsExpanded = useCallback(
    (filter) => workersFilterState[filter]?.value.length !== 0,
    [workersFilterState],
  );

  return (
    <FilterMenu onClick={(e) => killEventPropagation(e)}>
      <FilterBtn onClick={(e) => showMenu(e)} active={isActive}>
        {isActive && <FilterCountText>{filterCounts}</FilterCountText>}
        <ChevronIcon active={isActive} />
      </FilterBtn>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <StyledContent>
          <StyledCollapsiblePanel
            headerContent={<Title level={4}>{t('manage.planning:job_title', 'JOB TITLE')}</Title>}
            expanded={checkIsExpanded(WorkersFilters.TITLE)}
          >
            {filters?.title.map((value) => buildCheckboxes(WorkersFilters.TITLE, value))}
          </StyledCollapsiblePanel>
          <div className="footer">
            <Button onClick={handleClear}>{t('clear', 'Clear')}</Button>
            <Button primary onClick={handleApply}>
              {t('apply', 'Apply')}
            </Button>
          </div>
        </StyledContent>
      </Menu>
    </FilterMenu>
  );
};

WorkersFilter.propTypes = {
  filters: PropTypes.instanceOf(Object),
};

WorkersFilter.defaultProps = {
  filters: null,
};
