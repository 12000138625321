import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { MonitorCaseStatus, CaseType, EntityType } from '@ge/models/constants';
import { ActionKeys } from '@ge/shared/models/actions-menu-items';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';

import { ConditionalRender } from '../conditional-render';

const StyledActionItemContainer = styled.div.attrs((props) => ({
  style: {
    opacity: props.item.disabled ? (props.item.disabled == true ? 0.5 : 1) : 1,
  },
}))`
  display: flex;
  border-top: solid 1px ${(props) => props.theme.menu.actionsMenu.borderColor};
  display: ${(props) => (props.item.disabled ? 'none' : '')};
  &:first-of-type {
    border-top: none;
  }
`;

const StyledActionItem = styled.div`
  display: flex;
  color: ${(props) => props.theme.menu.actionsMenu.textColor};
  cursor: ${(props) => (props.item.disabled ? 'not-allowed' : 'pointer')};
  box-sizing: content-box;
  align-items: center;
  padding: 6px 8px;
  text-align: left;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.5px;
  width: 100%;
  text-transform: uppercase;

  &:hover {
    background-image: linear-gradient(180deg, #0a9ccd 0%, #10bfd9 100%);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  }
`;

const StyledActionButton = styled(StyledActionItem).attrs(() => ({
  as: 'button',
  type: 'button',
}))``;

const StyledActionAnchor = styled(StyledActionItem).attrs(({ item }) => ({
  as: 'a',
  href: item.href,
  target: item.target,
  download: item.download,
}))`
  text-decoration: none;
`;

const ButtonIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.color ? props.color : props.theme.eventController.iconColor,
}))`
  margin-right: 8px;

  path {
    transform: ${(props) => (props.largeIcon ? 'translate(0, 0)' : 'translate(2px, 0)')};
  }
`;

const CheckIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.eventController.iconColor,
  icon: Icons.CHECK,
}))`
  padding-left: 10px;
`;

const CheckIconContainer = styled.div`
  margin-left: auto;
`;

export const ActionController = ({
  menuItems,
  onActionHandler,
  iconColor,
  entity,
  selected,
  disabledActions,
}) => {
  const { t, ready } = useTranslation(['entity-details'], { useSuspense: false });
  const { massSRFlag, approvalFlag } = useStoreState((state) => state.tenant.featureFlags);
  const { bulkTask } = useContext(EntityDetailsContext);

  if (!ready) {
    return null;
  }

  const isSiteLevel = bulkTask?.some((task) => task?.taskLevel === EntityType.SITE);

  return (
    <div className="entity-controls">
      {Object.keys(menuItems).map((key) => {
        let isDisabled = disabledActions[key] ?? false;
        if (!isDisabled) {
          switch (key) {
            case ActionKeys.EVENT_SNOOZE:
              isDisabled = entity.caseState === MonitorCaseStatus.SNOOZED;
              break;
            case ActionKeys.EVENT_CHANGE_CAUSAL_ALARM:
              isDisabled = entity?.caseType !== CaseType.FAULTED;
              break;
            case ActionKeys.EVENT_LOG_ACTION:
              isDisabled =
                entity?.caseLevel === EntityType.SITE || entity?.caseLevel === EntityType.FLEET;
              break;
            case ActionKeys.EVENT_IN_PROGRESS:
              isDisabled = entity.caseState === MonitorCaseStatus.IN_PROGRESS;
              break;
            // case ActionKeys.NOTE_EDIT:
            // case ActionKeys.NOTE_DELETE:
            //   isDisabled = disabledActions[key];
            //   break;
            case ActionKeys.EVENT_ADD_NOTE:
            case ActionKeys.EVENT_SEND_NOTIFICATION:
            case ActionKeys.CREATE_ALERT:
              isDisabled = entity?.caseLevel === EntityType.FLEET;
              break;
            case ActionKeys.TASK_CREATE_SERVICE_REQUEST:
              isDisabled = !massSRFlag || isSiteLevel;
              break;
            case ActionKeys.TASK_FLAG_BULK_EDIT:
              isDisabled = !approvalFlag;
              break;
            default:
              isDisabled = false;
              break;
          }
        }
        const item = menuItems[key];
        item.disabled = isDisabled;

        const ActionItemType = item.href ? StyledActionAnchor : StyledActionButton;

        return (
          <StyledActionItemContainer key={key} item={item}>
            <ActionItemType
              item={item}
              onClick={(e) => onActionHandler(e, key, null)}
              disabled={isDisabled}
            >
              <ConditionalRender shouldRender={!!item.icon}>
                <ButtonIcon
                  icon={item.icon}
                  viewbox={item.viewbox}
                  color={iconColor}
                  rotate={item.rotate ? item.rotate : null}
                  largeIcon={item.largeIcon}
                  className={item.icon}
                />
              </ConditionalRender>
              {t(`actions.${item.translationKey}`, item.title)}
              <ConditionalRender shouldRender={key === selected}>
                <CheckIconContainer>
                  <CheckIcon icon={Icons.CHECK} viewbox={item.viewbox} largeIcon={item.largeIcon} />
                </CheckIconContainer>
              </ConditionalRender>
            </ActionItemType>
          </StyledActionItemContainer>
        );
      })}
    </div>
  );
};

ActionController.propTypes = {
  menuItems: PropTypes.instanceOf(Object).isRequired,
  onActionHandler: PropTypes.func,
  iconColor: PropTypes.string,
  entity: PropTypes.instanceOf(Object),
  disabledActions: PropTypes.instanceOf(Object),
  selected: PropTypes.string,
};

ActionController.defaultProps = {
  onActionHandler: () => null,
  iconColor: null,
  disabledActions: {},
};
