import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// removed for MVP 0 - #612
// import { getAlertIcon } from '@ge/components/alert-icon';
import { Icon, Icons } from '@ge/components/icon';
import { Placeholders } from '@ge/models/constants';
import { roundNumber } from '@ge/util';

const WeatherWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  .forecast {
    font-weight: 500;
    text-align: center;
    margin-right: 20px;
    span {
      margin-top: 6px;
      display: block;
    }
  }
  .weather-data {
    display: flex;
    align-items: center;
    margin-left: 15px;
    svg {
      margin-right: 5px;
    }
  }
`;

// removed for MVP 0 - #612
// const PrecipitationIcon = styled(Icon).attrs((props) => ({
//   size: 12,
//   icon: Icons.PRECIPITATION,
//   color: props.theme.entityDetails.headerIconColor,
// }))``;

const TemperatureIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.TEMPERATURE,
  color: props.theme.entityDetails.headerIconColor,
}))``;

const WindIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.WIND_BARB_20,
  color: props.theme.entityDetails.headerIconColor,
}))``;

// removed for MVP 0 - #612
// const StyledAlertIcon = styled(Icon).attrs(({ theme, icon }) => ({
//   icon,
//   color: theme.entityDetails.alertIconColor,
//   size: 12,
// }))`
//   background-color: ${({ theme }) => theme.entityDetails.alertBorderColor};
//   border-radius: 50%;
//   padding: 2px;
// `;

const EntityDetailsWeather = ({ className, weather }) => {
  const {
    // removed for MVP 0 - #612
    // alert = Placeholders.DOUBLE_DASH,
    // precip = Placeholders.DOUBLE_DASH,
    wind = Placeholders.DOUBLE_DASH,
    temp = Placeholders.DOUBLE_DASH,
  } = weather ?? {};

  return (
    <WeatherWrapper className={className}>
      {/* removed for MVP 0 - #612 */}
      {/* {alert && (
        <div className="weather-data">
          <StyledAlertIcon icon={getAlertIcon(alert)} />
        </div>
      )}
      <div className="weather-data">
        <PrecipitationIcon />
        <h3 className="text-4">{`${precip}%`}</h3>
      </div> */}
      <div className="weather-data">
        <TemperatureIcon />
        <h3 className="text-4">{`${
          temp != null ? roundNumber(temp, 1) : Placeholders.DOUBLE_DASH
        }° C`}</h3>
      </div>
      <div className="weather-data">
        <WindIcon />
        <h3 className="text-4">{`${
          wind != null ? roundNumber(wind, 1) : Placeholders.DOUBLE_DASH
        } m/s`}</h3>
      </div>
    </WeatherWrapper>
  );
};

EntityDetailsWeather.propTypes = {
  className: PropTypes.string,
  weather: PropTypes.instanceOf(Object),
};

EntityDetailsWeather.defaultProps = {
  className: null,
};

export default EntityDetailsWeather;
