import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Menu, placements } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { DraggableTable, DynamicTable, TableArrow } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { EntityType } from '@ge/models/constants';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { ActionKeys, childCaseTaskMenuItems } from '@ge/shared/models/actions-menu-items';
import { killEventPropagation } from '@ge/shared/util';

import {
  CaseOverviewColumnDefs,
  CaseOverviewColumns,
  defaultCaseOverviewCols,
} from '../../models/table-col-defs/case-overview-table-cols';

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const StyledTypeIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: props.icon,
  color: props.theme.table.iconLightColor,
}))``;

const StyledDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDesc = styled.span`
  margin-left: 6px;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
      font-size: 11px;
    }
  }
`;

const StyledSource = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColumnHover = ({ children, value }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorElement(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorElement(null);

  if (!value) return null;

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        placement={placements.TOP_START}
      >
        <HoverText>{value}</HoverText>
      </Menu>
    </div>
  );
};
ColumnHover.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
};

const HoverText = styled.div`
  padding: 8px 15px;
  max-width: 200px;
  word-break: break-all;
  hyphens: auto;
`;

const CaseOverviewTable = ({
  childCases,
  checked,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onTaskSelect,
  onUnlinkSelect,
  draggable,
  scrollable,
  onDrop,
  toggleIncludedRow,
}) => {
  const { t, ready } = useTranslation(['monitor.issues'], {
    useSuspense: false,
  });
  const containerRef = useRef(null);

  const getActionsMenuItems = useActionsMenu({ menuItems: childCaseTaskMenuItems });

  const sortedDirection = (metric) => (metric === sortMetric ? sortDirection : '');

  const handleActionMenu = useCallback(
    ({ entity, type }) => {
      if (type === ActionKeys.CASE_TASK_UNLINK) {
        onUnlinkSelect(entity);
      }
    },
    [onUnlinkSelect],
  );

  const cellValueMapFn = useCallback(
    (childCase) => {
      if (!childCase) {
        return {};
      }

      const {
        id,
        taskId,
        type = EntityType.CASE,
        priority,
        description,
        status,
        end,
        source,
        createDate,
      } = childCase;

      return {
        [CaseOverviewColumns.INCLUDE]: { id: id, checked: checked, type },
        [CaseOverviewColumns.TYPE]: type,
        [CaseOverviewColumns.CODE]: { id: id, type, taskId },
        [CaseOverviewColumns.DESCRIPTION]: { priority, description },
        [CaseOverviewColumns.START]: createDate,
        [CaseOverviewColumns.END]: { status, end },
        [CaseOverviewColumns.ACTIONS]: { id, type },
        [CaseOverviewColumns.SOURCE]: { id, type, source },
      };
    },
    [checked],
  );

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      if (!cellValue) return null;

      switch (columnKey) {
        case CaseOverviewColumns.INCLUDE:
          return (
            <StyledCheckbox>
              <Checkbox
                checkState={
                  checked.includes(cellValue.id) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
                onChange={() => toggleIncludedRow(cellValue.id)}
              />
            </StyledCheckbox>
          );
        case CaseOverviewColumns.TYPE:
          return (
            <StyledTypeIcon icon={cellValue === EntityType.TASK ? Icons.TASK : Icons.ANOMALY} />
          );
        case CaseOverviewColumns.CODE:
          return (
            <span>{cellValue.type === EntityType.TASK ? cellValue.taskId : cellValue.id}</span>
          );
        case CaseOverviewColumns.DESCRIPTION:
          return (
            <ColumnHover value={cellValue.description}>
              <StyledDescription>
                {cellValue.priority && (
                  <Severity level={String(cellValue.priority).toLowerCase()} />
                )}
                <StyledDesc>{cellValue.description}</StyledDesc>
              </StyledDescription>
            </ColumnHover>
          );
        case CaseOverviewColumns.START:
          return (
            <StyledTimeDate>
              <span>{cellValue && dayjs(cellValue).format('HH:mm')}</span>
              <span>{cellValue && dayjs(cellValue).format('DD MMM YYYY')}</span>
            </StyledTimeDate>
          );
        case CaseOverviewColumns.END:
          return (
            <>
              {cellValue && cellValue.end ? (
                <StyledTimeDate>
                  <span>{dayjs(cellValue.end).format('HH:mm')}</span>
                  <span>{dayjs(cellValue.end).format('DD MMM YYYY')}</span>
                </StyledTimeDate>
              ) : (
                <span>{cellValue.status}</span>
              )}
            </>
          );
        case CaseOverviewColumns.ACTIONS:
          return (
            <div className="actions">
              {cellValue.type === EntityType.CASE ? (
                <ActionsMenu
                  containerRef={containerRef}
                  entity={cellValue}
                  menuItems={getActionsMenuItems([cellValue])}
                  menuIconSmall
                  onAction={handleActionMenu}
                />
              ) : null}
            </div>
          );

        case CaseOverviewColumns.SOURCE:
          return (
            <StyledSource>
              {cellValue.source && <span>{cellValue.source}</span>}
              {cellValue.type === EntityType.TASK && (
                <button
                  className="row-hover-icon"
                  type="button"
                  onClick={(e) => onTaskSelect(e, cellValue.id, cellValue.type)}
                >
                  <TableArrow />
                </button>
              )}
            </StyledSource>
          );
        default:
          return null;
      }
    },
    [checked, toggleIncludedRow, onTaskSelect, handleActionMenu, getActionsMenuItems],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: CaseOverviewColumnDefs,
    cellValueMapFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
  });

  const dynamicProps = {
    scrollable,
    compressed: true,
    transparent: true,
    noTitles: true,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: childCases,
    onValueSelect: () => {},
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
  };

  if (!ready) {
    return <Loader />;
  }

  return React.createElement(draggable ? DraggableTable : DynamicTable, dynamicProps);
};

CaseOverviewTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  childCases: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onTaskSelect: PropTypes.func,
  onUnlinkSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string),
  toggleIncludedRow: PropTypes.func,
};

CaseOverviewTable.defaultProps = {
  columns: defaultCaseOverviewCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onTaskSelect: () => null,
  onUnlinkSelect: () => null,
  onDrop: () => null,
  scrollable: false,
  draggable: false,
  checked: [],
  toggleIncludedRow: () => null,
};

CaseOverviewTable.displayName = 'CaseOverviewTable';

export default CaseOverviewTable;
