import { Capability, PermissionScope } from '@ge/models/constants';

export const SitesNavItems = [
  {
    capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
    label: 'Wind',
    i18nKey: 'nav:monitor.l3.sites.wind',
    route: '/monitor/sites/wind',
  },
  {
    capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
    label: 'Storage',
    i18nKey: 'nav:monitor.l3.sites.storage',
    route: '/monitor/sites/storage',
  },
  // {
  //   capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
  //   label: 'Solar',
  //   i18nKey: 'nav:monitor.l3.sites.storage',
  //   route: '/monitor/sites/solar',
  // },
  // {
  //   capabilities: [{ capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] }],
  //   label: 'Hybrid',
  //   i18nKey: 'nav:monitor.l3.sites.storage',
  //   route: '/monitor/sites/hybrid',
  // },
];
