import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icons } from '@ge/components/icon';
import { Select } from '@ge/components/select';
import { DateTimeFormats, EntityType } from '@ge/models/constants';

import TabDetailHeader from '../components/tab-detail-header';
import { TabDetailContainer, SectionContainer, SectionHeader } from '../entity-details-shared';

import { CaseSensorReadingsChart } from './case-sensor-readings-chart';

const SensorReadingsContainer = styled.div`
  margin: 0 25px 40px 15px;
`;

const SelectWrapper = styled.div`
  display: inline-block;
  &:last-of-type {
    margin-bottom: 20px;
  }
`;

export const CaseSensorReadings = ({ issue, alert }) => {
  const { t } = useTranslation(['entity-details']);

  const [selectCases, setSelectCases] = useState(
    [issue, ...issue.childCases].filter((i) => i.alertIds?.length),
  );
  const [selectAlerts, setSelectAlerts] = useState(issue.alerts);
  const [currentCaseId, setCurrentCaseId] = useState(alert?.caseId);
  const [currentAlert, setCurrentAlert] = useState(alert);

  const { fetchAlertSignalData } = useStoreActions((actions) => actions.analyze);
  const getAlertSignalData = useStoreState(({ analyze }) => analyze.getAlertSignalData);

  useEffect(() => {
    setCurrentCaseId(alert?.caseId);
    setCurrentAlert(alert);
  }, [alert]);

  useEffect(() => {
    if (currentAlert) {
      fetchAlertSignalData(currentAlert?.id);
    }
  }, [currentAlert, fetchAlertSignalData]);

  const signalData = currentAlert && getAlertSignalData(currentAlert?.id);

  const handleSetAlerts = useCallback(
    (value) => {
      const currentAlerts = issue.alerts.filter((a) => a.caseId === value);
      setSelectAlerts(currentAlerts);
      setCurrentAlert(currentAlerts.find((a) => a.id === currentAlert?.id));
    },
    [issue.alerts, currentAlert],
  );

  useEffect(() => {
    const selectCases = [issue, ...issue?.childCases].filter((i) => i.alertIds?.length);
    setSelectCases(selectCases);
    if (!issue.childCaseIds?.length) handleSetAlerts(issue.id);
    else handleSetAlerts(issue.childCases.filter((a) => a.id === currentCaseId?.id)[0]);
  }, [issue, currentCaseId, handleSetAlerts]);

  const caseSelectOptions = useMemo(
    () =>
      selectCases.map((anomaly) => ({
        value: anomaly.id,
        label: anomaly.description,
      })),
    [selectCases],
  );

  const handleCaseSelect = (value) => {
    handleSetAlerts(value);
    setCurrentCaseId(value);
  };

  const handleAlertSelect = (value) => {
    setCurrentAlert(issue.alerts.filter((a) => a.id === value)[0]);
  };

  const alertSelectOptions = useMemo(
    () =>
      selectAlerts.map((alert) => ({
        value: alert.id,
        label: `${alert.info} • ${dayjs(alert.start).format(
          DateTimeFormats.ISSUE_DETAIL_DATE_TIME,
        )}`,
      })),
    [selectAlerts],
  );

  const caseSelectValue = useMemo(
    () => caseSelectOptions.filter((option) => option.value === currentCaseId),
    [currentCaseId, caseSelectOptions],
  );

  const alertSelectValue = useMemo(
    () => alertSelectOptions.filter((option) => option.value === currentAlert?.id),
    [currentAlert, alertSelectOptions],
  );

  return (
    <TabDetailContainer>
      <TabDetailHeader entity={issue} entityType={EntityType.CASE} />
      <SensorReadingsContainer>
        <SectionContainer>
          <SectionHeader>{t('case_panel.sensor_readings.case', 'Case')}</SectionHeader>
          <SelectWrapper>
            <Select
              primary
              onChange={(option) => handleCaseSelect(option.value)}
              icon={Icons.ANOMALY}
              options={caseSelectOptions}
              value={caseSelectValue.length > 0 ? caseSelectValue : caseSelectOptions[0]}
            />
          </SelectWrapper>
          <SectionHeader>
            {t('case_panel.sensor_readings.select_evidence', 'Select Evidence')}
          </SectionHeader>
          <SelectWrapper>
            <Select
              onChange={(option) => handleAlertSelect(option.value)}
              value={alertSelectValue.length > 0 ? alertSelectValue : alertSelectOptions[0]}
              options={alertSelectOptions}
            />
          </SelectWrapper>
        </SectionContainer>
        {/* Not adding a translation as this will be dynamic soon */}
        <CaseSensorReadingsChart
          alert={currentAlert}
          sensorData={signalData}
          asset={issue.asset}
          site={issue.site}
        />
      </SensorReadingsContainer>
    </TabDetailContainer>
  );
};

CaseSensorReadings.propTypes = {
  issue: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.instanceOf(Object),
};

CaseSensorReadings.defaultProps = {
  alert: null,
};
