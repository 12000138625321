import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FormMode, Placeholders } from '@ge/models/constants';
import { getFriendlyTimestamp } from '@ge/shared/util';
import { roundNumber } from '@ge/util';

import { FormField } from './form-fields/form-field';

export const FORM_COL_GUTTER_PX = 25;

// parses value into string for view mode
const parseViewModeValue = (value = '') =>
  value?.toISOString
    ? getFriendlyTimestamp(value).split(', ').reverse().join(' ')
    : value?.toString();

export const FormViewContainer = styled.div`
  p {
    color: ${({ theme }) => theme?.formDialog?.viewValueTextColor};
    font-size: 12px;
    font-weight: 300;
    margin: 4px 0;
  }
`;

export const Form = styled.div`
  margin-top: 16px;
  .description {
    margin-top: 0px;
  }
  form {
    input {
      font-size: 12px;
      font-weight: 300;
    }

    label,
    .label {
      color: ${({ theme }) => theme?.input?.labelTextColor};
      font-size: 11px;
      font-weight: 500;
      white-space: pre;
    }

    > * {
      + * {
        margin-top: 16px;
      }
    }
  }
`;

export const FormRow = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;

  #formTypeSelect {
    min-width: 225px;
  }
`;

// have to set width explicitly (instead of via flex-basis)
// because it's not being enforced everywhere otherwise
export const StyledFormCol = styled.div`
  box-sizing: border-box;
  flex: 0 1 ${({ width }) => width};
  width: ${({ width }) => width};

  + div {
    margin-left: ${FORM_COL_GUTTER_PX}px;
  }
`;

export const FormCol = ({ children, count, span }) => {
  let width = `${roundNumber((Number(span) * 100) / 12, 3)}%`;

  // if multiple cols then calc width with gutters
  if (count > 1) {
    width = `calc(${width} - ${roundNumber((FORM_COL_GUTTER_PX * (count - 1)) / count, 3)}px)`;
  }

  return <StyledFormCol width={width}>{children}</StyledFormCol>;
};

FormCol.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  span: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FormCol.defaultProps = {
  count: 1,
  span: 12,
};

export const FormViewField = ({ className, id, label, value: _value }) => {
  const value = parseViewModeValue(_value);

  return (
    <FormViewContainer className={className} id={id}>
      {label && <label>{label}</label>}
      <p>{value?.trim() ? value : Placeholders.DOUBLE_DASH}</p>
    </FormViewContainer>
  );
};

FormViewField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
};

FormViewField.defaultProps = {
  className: undefined,
  id: undefined,
  label: undefined,
  value: Placeholders.DOUBLE_DASH,
};

export const FormTemplateCol = ({ col, namespace }) => {
  if (!col) {
    return null;
  }

  return (
    <FormCol count={col.count} span={col.span}>
      <FormField {...col} namespace={namespace} />
    </FormCol>
  );
};

FormTemplateCol.propTypes = {
  col: PropTypes.object,
  namespace: PropTypes.object,
};

FormTemplateCol.defaultProps = {
  col: undefined,
};

export const FormTemplateRow = ({ mode, row, namespace }) => {
  if (!row?.length) {
    return null;
  }

  return (
    <FormRow>
      {row?.map((col) => (
        <FormTemplateCol
          key={col?.name}
          col={{ count: row.length, mode, ...col }}
          namespace={namespace}
        />
      ))}
    </FormRow>
  );
};

FormTemplateRow.propTypes = {
  mode: PropTypes.oneOf(Object.values(FormMode)),
  namespace: PropTypes.object,
  row: PropTypes.arrayOf(PropTypes.object),
};

FormTemplateRow.defaultProps = {
  mode: FormMode.VIEW,
  row: undefined,
};
