import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Input } from '@ge/components/input/input';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Select } from '@ge/components/select';
import { useRolesTable } from '@ge/feat-admin/data-hooks/use-roles-table';
import { useTenants } from '@ge/feat-admin/data-hooks/use-tenants';
import { NewPersonField } from '@ge/models/constants';

import RolesGrid from '../../entity-create/components/roles-grid';
import { AdminCollapsiblePanel, Description } from '../../entity-create/entity-create-shared';
import usePermissions from '../../entity-create/hooks/use-permissions';

import RoleDetailsHeader from './role-details-header';

const RolesDetailContainer = styled.div`
  padding: 10px 8px;
`;

const RoleGridContainer = styled.div`
  > div {
    height: calc(100vh - 550px);
  }
`;

const TenantSelectContainer = styled.div`
  margin-bottom: 20px;
  width: 50%;
`;

const TitleInputContainer = styled.div`
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 15px;
  width: 30%;
`;

const DescriptionInputContainer = styled.div`
  display: inline-block;
  width: 65%;
`;

const formFields = {
  tenantId: 'tenantId',
  title: 'title',
  description: 'description',
};

const AdminFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding: 0 45px 0 25px;
  position: relative;
`;

const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  button {
    &:last-of-type {
      margin-left: auto;
    }
  }
`;

const CollapsePanels = styled.div`
  border-bottom: ${(props) => props.theme.admin.panel.body.border} 1px solid;
`;

const RoleDetails = ({ roleId }) => {
  const { t } = useTranslation(['admin.roles', 'general'], { useSuspense: false });
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { data: roleData } = useRolesTable({});
  const { data: tenants } = useTenants();
  const methods = useForm();

  // NOTE(greg): roles and permissions are not currently tied together in data hooks.  I'm not positive if this
  // will be separate or in the same BFF call as of yet.  This will need to be revisited.
  // for now, usePermissions is hardcoded in the UI and we are just using the 'new_role' permissions for all roles
  const { data: permissions } = usePermissions('new_role');

  useEffect(() => {
    setSelectedPermissions(permissions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const roleDetails = useMemo(
    () => roleData?.data?.find((role) => role.id.toString() === roleId) || {},
    [roleData, roleId],
  );

  const getTenantOptions = useCallback(() => {
    if (!tenants) return {};

    return tenants.map((tenant) => {
      return {
        label: tenant.companyName,
        value: tenant.tenantId,
      };
    });
  }, [tenants]);

  const onSubmit = useCallback(
    (values) => {
      // this section is solely to update the permissions in memory - this needs to be integrated with the BFF layer
      // when this functionality is available
      console.log('onSubmit', values);
      permissions.forEach((segment) => {
        segment.capabilityGroups.forEach((group) => {
          group.capabilities.forEach((capability) => {
            Object.entries(capability.permissions).forEach(([key, permission]) => {
              if (permission.readOnly) return;
              const capabilityKey = `${capability.name}_${key}`;
              permission.checked = values[NewPersonField.USER_ROLES]?.includes(capabilityKey)
                ? true
                : false;
            });
          });
        });
      });
      setSelectedPermissions(permissions);
      setIsEditMode(false);
    },
    [permissions, setIsEditMode],
  );

  const closeModal = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const getEditDetailsControls = useCallback(() => {
    return (
      <>
        <TenantSelectContainer>
          <Controller
            name={formFields.tenantId}
            rules={{
              required: true,
            }}
            defaultValue={
              roleDetails?.tenant
                ? getTenantOptions().find((opt) => opt.label === roleDetails.tenant.name)
                : null
            }
            render={({ onChange, value }) => (
              <Select
                primary
                onChange={onChange}
                label={t('role_panel.tenant_select', 'Tenant')}
                options={getTenantOptions()}
                value={value}
                placeholder={t('role_panel.tenant_placeholder', 'Select a tenant')}
                error={methods.errors?.tenantId}
              />
            )}
          />
        </TenantSelectContainer>

        <TitleInputContainer>
          <Controller
            name={formFields.title}
            defaultValue={roleDetails?.role}
            rules={{
              required: true,
            }}
            render={({ onChange, value }) => (
              <Input
                onChange={onChange}
                label={t('role_panel.title_label', 'Role name')}
                type="text"
                placeholder={t('role_panel.title_placeholder', 'Enter role title')}
                value={value}
                error={methods.errors?.title}
              />
            )}
          />
        </TitleInputContainer>

        <DescriptionInputContainer>
          <Controller
            name={formFields.description}
            defaultValue={roleDetails.description}
            render={({ onChange, value }) => (
              <Input
                onChange={onChange}
                label={t('role_panel.description_label', 'Description (optional)')}
                type="text"
                placeholder={t('role_panel.description_placeholder', 'Enter role description')}
                value={value}
                error={methods.errors?.description}
              />
            )}
          />
        </DescriptionInputContainer>
      </>
    );
  }, [getTenantOptions, methods, roleDetails, t]);

  return (
    <>
      <RoleDetailsHeader roleDetails={roleDetails} handleEditClick={() => setIsEditMode(true)} />

      <RolesDetailContainer>
        <FormProvider {...methods}>
          {isEditMode && getEditDetailsControls()}

          <h2>{t('role_panel.permissions_title', 'Product Permissions')}</h2>
          <Description className="roles">
            {t(
              'role_panel.permissions_desc',
              'Select what features and capabilities will be available to this role.',
            )}
          </Description>

          <RoleGridContainer>
            <ScrollingContainer>
              <CollapsePanels>
                {selectedPermissions?.map((segment, id) => (
                  <AdminCollapsiblePanel
                    headerContent={
                      <div>
                        <h4>
                          {t(
                            `segment_offerings.${segment.segmentOffering}`,
                            segment.defaultTranslation,
                          )}
                        </h4>
                        <p>{segment.description}</p>
                      </div>
                    }
                    key={id}
                    expanded={true}
                  >
                    <RolesGrid
                      capabilityGroups={segment.capabilityGroups}
                      segmentOffering={segment.segmentOffering}
                      isEditMode={isEditMode}
                    />
                  </AdminCollapsiblePanel>
                ))}
              </CollapsePanels>
            </ScrollingContainer>
          </RoleGridContainer>

          {isEditMode && (
            <AdminFooterWrapper>
              <FooterButtons>
                <Button onClick={closeModal}>{t('general:cancel', 'Cancel')}</Button>
                <Button primary onClick={methods.handleSubmit(onSubmit)}>
                  {t('general:update', 'Update')}
                </Button>
              </FooterButtons>
            </AdminFooterWrapper>
          )}
        </FormProvider>
      </RolesDetailContainer>
    </>
  );
};

RoleDetails.propTypes = {
  roleId: PropTypes.string,
};

export default RoleDetails;
