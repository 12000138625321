import React from 'react';

// Create a React Context to share within the DynamicTable so all
// components rendering inside the table have access to required
// state and handler functions.
export const TableDragDropContext = React.createContext();

// Custom hook to inject the TableDragDropContext into components that will
// leverage it. This will ensure that draggable versions of table components
// cannot be used outside the context of the DraggableTable.
export const useDragDropContext = () => {
  const context = React.useContext(TableDragDropContext);
  if (!context) {
    throw new Error(
      'Draggable Table Components cannot be used outside of the DraggableTable component.',
    );
  }

  return context;
};
