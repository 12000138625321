import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

const SUNDAY = 0;
const SATURDAY = 6;

export const getWeeksAndDaysInMonth = ({ start, daysInMonth }) => {
  const firstDate = start;

  // build array of days in week by month
  const weekDaysInMonthObj = [];
  for (let i = 0; i < daysInMonth; i++) {
    const date = firstDate.add(i, 'day');
    const week = date.isoWeek();
    const day = date.date();
    const dayNum = date.day();
    const isWeekend = dayNum === SATURDAY || dayNum === SUNDAY;
    const isToday = dayjs().startOf('d').valueOf() === date.valueOf();

    if (!(`week${week}` in weekDaysInMonthObj)) {
      weekDaysInMonthObj[`week${week}`] = {
        week: week,
        days: [{ day: day, isWeekend, date, isToday }],
      };
    } else {
      weekDaysInMonthObj[`week${week}`].days.push({ day: date.date(), isWeekend, date, isToday });
    }
  }
  return weekDaysInMonthObj;
};

export const getWeeksInQuarter = ({ start, daysInQuarter }) => {
  const firstDate = start;

  // build array of days in week by month
  const weekDaysInQuarterObj = [];
  for (let i = 0; i < daysInQuarter; i++) {
    const date = firstDate.add(i, 'day');
    const week = date.isoWeek();
    const day = i + 1;
    const dayNum = date.day();
    const isWeekend = dayNum === SATURDAY || dayNum === SUNDAY;
    const isToday = dayjs().startOf('d').valueOf() === date.valueOf();

    if (!(`week${week}` in weekDaysInQuarterObj)) {
      weekDaysInQuarterObj[`week${week}`] = {
        week: week,
        days: [{ day: day, isWeekend, date, isToday }],
      };
    } else {
      weekDaysInQuarterObj[`week${week}`].days.push({ day: day, isWeekend, date, isToday });
    }
  }
  return weekDaysInQuarterObj;
};

export const getDaysInWeek = ({ start }) => {
  const firstDate = start;
  // build array of days in week with date and day
  const daysInWeekObj = [];
  for (let i = 0; i < 7; i++) {
    const date = firstDate.add(i, 'day');
    const dayOfMonth = date.format('D');
    const dayname = date.format('dddd');
    const dayOfYear = dayjs(date).dayOfYear();
    const dayNum = date.day();
    const isWeekend = dayNum === SATURDAY || dayNum === SUNDAY;
    const isToday = dayjs().startOf('d').valueOf() === date.valueOf();
    daysInWeekObj[`day${i + 1}`] = {
      dayOfMonth: dayOfMonth,
      date: date,
      dayName: dayname,
      dayOfYear: dayOfYear,
      isWeekend,
      isToday,
    };
  }
  return daysInWeekObj;
};

export const getHoursInDay = ({ start }) => {
  const hoursInDayObj = [];
  const minutesDiff = [0, 15, 30, 45];
  const timeArray = [...Array(24).keys()];
  timeArray.map((time, index) => {
    for (let i = 0; i < minutesDiff.length; i++) {
      hoursInDayObj[`h${index + 1}m${minutesDiff[i]}`] = {
        time: dayjs.duration({ minutes: minutesDiff[i], hours: time }).format('HH:mm'),
        date: start,
      };
    }
  });
  return hoursInDayObj;
};
