import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icons } from '@ge/components/icon';
import { useCustomFilterCount } from '@ge/feat-manage/data-hooks/use-custom-filter-count';
import { TaskTrackers } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';

import FavoriteIcon from './favourite.svg';
import { ListIcon } from './tasks-tracker';
const TaskTrackerName = styled.span`
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  text-align: center;
  margin: 0px;
`;

const TaskTrackerCount = styled.p`
  font-size: 16px;
  letter-spacing: -0.33px;
  line-height: 19px;
  text-align: center;
  margin: 0px;
`;

const FilterButton = ({
  taskTracker,
  handleClick,
  tasksTrackerCount,
  identifier,
  enableReorder,
  handleDrag,
  handleDrop,
  handleDragOver,
  isTasksLoading,
  loadCompletedTasks,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { getTaskCount, filterTask } = useCustomFilterCount();

  useEffect(() => {
    if (!taskTracker.defaultView) {
      getTaskCount(taskTracker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTracker]);

  return (
    <div
      draggable={enableReorder}
      onDrag={(event) => handleDrag(event, taskTracker, identifier)}
      onDrop={(event) => handleDrop(event, taskTracker)}
      onDragOver={(event) => handleDragOver(event, identifier)}
    >
      <Button
        key={taskTracker?.id}
        onClick={() => !enableReorder && handleClick(taskTracker?.id)}
        className={`taskTrackerBtn ${taskTracker?.selected ? 'selected' : ''} 
        ${identifier === 0 ? TaskTrackers.FIRST_TRACKER_BTN : ''} ${
          enableReorder ? TaskTrackers.DRAG_TASK_TRACKER : ''
        }`}
      >
        {!taskTracker?.defaultView && !enableReorder ? (
          <img
            src={FavoriteIcon}
            alt={t('tasks_tracker.fav_icon', 'favorite-icon')}
            className="fav-icon"
          />
        ) : (
          enableReorder && (
            <ListIcon
              icon={Icons.LIST}
              color={taskTracker?.selected ? globalColors.white : globalColors.slate3}
              size={11}
            />
          )
        )}
        {}
        <TaskTrackerName>
          {t(`tasks_tracker.${taskTracker?.id}`, taskTracker?.name)}
        </TaskTrackerName>
        {!isTasksLoading ? (
          !loadCompletedTasks &&
          (taskTracker?.name === 'RECENTLY CLOSED' || taskTracker?.name === 'ALL COMPLETED') ? (
            <TaskTrackerCount>-</TaskTrackerCount>
          ) : (
            <TaskTrackerCount>
              {!taskTracker.defaultView && filterTask
                ? filterTask?.length
                : tasksTrackerCount[taskTracker?.name]}
            </TaskTrackerCount>
          )
        ) : (
          <TaskTrackerCount>-</TaskTrackerCount>
        )}
      </Button>
    </div>
  );
};

FilterButton.propTypes = {
  taskTracker: PropTypes.object,
  handleClick: PropTypes.func,
  tasksTrackerCount: PropTypes.object,
  identifier: PropTypes.number,
  enableReorder: PropTypes.bool,
  handleDrag: PropTypes.func,
  handleDrop: PropTypes.func,
  handleDragOver: PropTypes.func,
  isTasksLoading: PropTypes.bool,
  loadCompletedTasks: PropTypes.bool,
};

export default FilterButton;
