export const elevations = {
  N1: -1,
  P0: 0,
  P1: 1,
  P2: 2,
  P3: 3,
  P4: 4,
  P10: 10,
  P15: 15,
  P20: 20,
  P25: 25,
  P50: 50,
  P100: 100,
  P101: 101,
};
