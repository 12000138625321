import { PropTypes } from 'prop-types';
import React, { useState, Suspense, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ComponentLoader } from '@ge/components/loader/loader';
import { AssetContext } from '@ge/feat-analyze/context/assetContext';
import { Capability } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { AuthRender } from '@ge/shared/components/auth-render';

import { storageAssetKPISelectorData } from '../../../../constants';
import { AssetEventsProvider } from '../../../../context';
import { KpiChartConatinerTitle } from '../../../shared/dynamic-components/kpi-chart-container-title';
import ErrorBoundary from '../../../shared/error-boundary/error-boundary';
import { KpiChartSelector } from '../../../shared/kpi-chart-selector/kpi-chart-selector';
import { IssuesSidebar } from '../issues-sidebar';

const AssetDetailsContainer = styled.div`
  display: flex;
  margin-right: 20px;
  min-height: 300px;
`;

const ChartsContainer = styled.div`
  flex: 1;
  .CustomDropdown {
    width: 20% !important;
  }
  .select__control {
    padding: 2px;
  }
  border: ${(props) => (props.hideBorder ? 'none' : '1px solid' + props.theme.charts.borderColor)};
  padding: 10px;
  margin-top: 10px;
`;

const NAMESPACE = 'analyze.dashboard';

const AssetDetails = (props) => {
  const { dateRange } = props;
  const [selectedCombinedState, setSelectedCombinedState] = useState(null);
  const [dynamicProps, setDynamicProps] = useState(null);
  const [LazyLoadedComponent, setLazyLoadedComponent] = useState(null);
  const { queryParam } = useContext(AssetContext);
  const childRef = useRef();
  const changeHandler = (combinedState) => {
    setSelectedCombinedState(combinedState);
  };

  const { t } = useTranslation([NAMESPACE], { useSuspense: false });

  useEffect(() => {
    let key;
    for (key in selectedCombinedState) {
      if (selectedCombinedState[key]?.component?.name) {
        break;
      }
    }
    if (key && selectedCombinedState[key]?.component?.name) {
      setDynamicProps({
        ...(selectedCombinedState[key]?.component?.props &&
          selectedCombinedState[key].component.props),
        dateRange,
        selectedCombinedState,
      });
      setLazyLoadedComponent(
        React.lazy(() => import(`../../../shared/${selectedCombinedState[key].component.path}`)),
      );
    }
  }, [selectedCombinedState, dateRange]);

  return (
    <AssetDetailsContainer>
      <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteLevel={false} view>
        <IssuesSidebar />
      </AuthRender>
      <AuthRender capability={Capability.CORE_KPIS} siteLevel={false} view>
        <ChartsContainer>
          <KpiChartConatinerTitle
            title={t('asset_trends', 'Asset Trends')}
            id={AnalyzeLocators.ANALYZE_KPI_ASSET_OVERVIEW_CHART_TITLE}
          />
          <KpiChartSelector
            kpiSelectorData={storageAssetKPISelectorData}
            siteType={queryParam.type}
            onChange={changeHandler}
          />
          <ErrorBoundary>
            <Suspense fallback={<ComponentLoader />}>
              {LazyLoadedComponent && (
                <LazyLoadedComponent ref={childRef} {...(dynamicProps && dynamicProps)} />
              )}
            </Suspense>
          </ErrorBoundary>
        </ChartsContainer>
      </AuthRender>
    </AssetDetailsContainer>
  );
};

// wrap in providers for export
export const AssetOverviewDetailStorage = () => (
  <AssetEventsProvider>
    <AssetDetails />
  </AssetEventsProvider>
);

AssetDetails.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};
AssetDetails.defaultProps = {
  dateRange: {
    startDate: '',
    endDate: '',
  },
};
