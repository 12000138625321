import { Icons, FileIcons } from '@ge/components/icon';
import { Capability, PermissionScope, AuthStrategy } from '@ge/models/constants';

export const ActionKeys = {
  CREATE_ALERT: 'create_alert',
  EVENT_LINK: 'event_link',
  EVENT_ACKNOWLEDGE: 'event_achknowledge',
  EVENT_IN_PROGRESS: 'event_in_progress',
  EVENT_LOG_ACTION: 'event_log_action',
  EVENT_SNOOZE: 'event_snooze',
  EVENT_CHECKOUT: 'event_checkout',
  EVENT_ADD_NOTE: 'event_add_note',
  EVENT_SEND_NOTIFICATION: 'event_send_notification',
  EVENT_CLOSE: 'event_close',
  EVENT_MARK_PENDING: 'event_mark_pending',
  EVENT_ESCALATE: 'event_escalate',
  EVENT_CHANGE_CAUSAL_ALARM: 'event_change_causal_alarm',
  TASK_SCHEDULE_RESCHEDULE: 'task_schedule_reschedule',
  TASK_DELETE: 'task_delete',
  TASK_CREATE_SERVICE_REQUEST: 'task_create_service_request',
  TASK_FLAG_BULK_EDIT: 'task_flag_bulk_edit',
  TASK_LINK: 'task_link',
  TASK_ADD_NOTE: 'task_add_note',
  TASK_CLOSE: 'task_close',
  NOTE_EDIT: 'note_edit',
  NOTE_DELETE: 'note_delete',
  SI_EDIT: 'si_edit',
  SI_DELETE: 'si_delete',
  CASE_NEW: 'case_new',
  CASE_IN_PROGRESS: 'case_in_progress',
  CASE_RETURNED: 'case_returned',
  CASE_SNOOZE: 'case_snooze',
  CASE_PENDING_REPAIR: 'case_pending_repair',
  CASE_PENDING_INSPECTION: 'case_pending_inspection',
  CASE_TASK_UNLINK: 'case_task_unlink',
  CASE_TASK_LINK: 'case_task_link',
  CASE_CLOSE: 'case_close',
  FORWARD_REPORT: 'forward_report',
  DOWNLOAD_REPORT: 'download_report',
  DOWNLOAD_REPORT_AND_ATTACHMENTS: 'download_report_and_attachments',
};

export const ActionsMenuItems = {
  [ActionKeys.CASE_NEW]: {
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.CREATE] }],
    title: 'New Task',
    translationKey: 'new_case_task',
    icon: Icons.PENCIL,
  },
  [ActionKeys.CREATE_ALERT]: {
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.CREATE] }],
    title: 'Create Alert',
    translationKey: 'create_alert',
    icon: Icons.ALERT_NEW,
    viewbox: '1 0 13 13',
  },
  [ActionKeys.EVENT_LINK]: {
    title: 'Link',
    translationKey: 'link',
    icon: Icons.LINK,
  },
  [ActionKeys.EVENT_ACKNOWLEDGE]: {
    title: 'Acknowledge',
    translationKey: 'acknowledge',
    icon: Icons.CHECK,
  },
  [ActionKeys.EVENT_IN_PROGRESS]: {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.EDIT] }],
    title: 'In Progress',
    translationKey: 'in_progress',
    icon: Icons.CHECK,
  },
  [ActionKeys.EVENT_LOG_ACTION]: {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.EDIT] }],
    title: 'Log ROC Action',
    translationKey: 'log_action',
    icon: Icons.PENCIL,
    largeIcon: true,
  },
  [ActionKeys.EVENT_SNOOZE]: {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.EDIT] }],
    title: 'Snooze',
    translationKey: 'snooze',
    icon: Icons.EVENT_SNOOZED,
    viewbox: '1 0 18 18',
  },
  [ActionKeys.EVENT_CHECKOUT]: {
    title: 'Check Out',
    translationKey: 'check_out',
    icon: Icons.ARROW_DOWN,
  },
  [ActionKeys.EVENT_ADD_NOTE]: {
    capabilities: [
      { capability: Capability.CASES, scopes: [PermissionScope.EDIT] },
      { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.CREATE] },
    ],
    authStrategy: AuthStrategy.ALL,
    title: 'Add Note',
    translationKey: 'add_note',
    icon: Icons.NOTE,
  },
  [ActionKeys.EVENT_SEND_NOTIFICATION]: {
    capabilities: [{ capability: Capability.EMAIL_NOTIFICATION, scopes: [PermissionScope.CREATE] }],
    title: 'Send Notification',
    translationKey: 'send_notification',
    icon: Icons.EMAIL,
    largeIcon: true,
  },
  [ActionKeys.EVENT_CLOSE]: {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.EDIT] }],
    title: 'Close',
    translationKey: 'close',
    icon: Icons.ARROW_UP,
    rotate: 90,
  },
  [ActionKeys.EVENT_MARK_PENDING]: {
    title: 'Mark Pending',
    translationKey: 'mark_pending',
    icon: Icons.BELL_ALT,
  },
  [ActionKeys.EVENT_ESCALATE]: {
    title: 'Escalate',
    translationKey: 'escalate',
    icon: Icons.ARROW_UP,
  },
  [ActionKeys.EVENT_CHANGE_CAUSAL_ALARM]: {
    capabilities: [{ capability: Capability.CASES, scopes: [PermissionScope.EDIT] }],
    title: 'Change Causal Alarm',
    translationKey: 'change_causal_alarm',
    icon: Icons.CHANGE_CAUSAL_ALARM,
    viewbox: '2 -3 15 15',
  },
  [ActionKeys.TASK_SCHEDULE_RESCHEDULE]: {
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    // TODO: should this include create scope too?
    // right now it doesn't appear that scope exists for work plan
    title: 'Schedule/Reschedule',
    translationKey: 'schedule_reschedule',
    icon: Icons.CALENDAR,
    largeIcon: true,
  },
  [ActionKeys.TASK_DELETE]: {
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    title: 'Delete',
    translationKey: 'delete',
    icon: Icons.TRASH,
    largeIcon: true,
  },
  [ActionKeys.TASK_CREATE_SERVICE_REQUEST]: {
    capabilities: [{ capability: Capability.WORK_PLAN, scopes: [PermissionScope.EDIT] }],
    title: 'Create Service Request',
    translationKey: 'task_create_service_request',
    icon: Icons.SR_CREATE,
    viewbox: '0 0 14 14',
  },
  [ActionKeys.TASK_FLAG_BULK_EDIT]: {
    capabilities: [{ capability: Capability.FIELD_TASK_APPROVER, scopes: [PermissionScope.EDIT] }],
    title: 'APPROVE/NOT APPROVE',
    translationKey: 'approve_not_approve',
    icon: Icons.THUMB_UP,
    largeIcon: true,
    viewbox: '0 0 10 10',
  },
  [ActionKeys.TASK_LINK]: {
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.EDIT] }],
    title: 'Link',
    translationKey: 'link',
    icon: Icons.ARROW_DOWN,
  },
  [ActionKeys.TASK_ADD_NOTE]: {
    title: 'Add Note',
    translationKey: 'add_note',
    icon: Icons.PENCIL,
    largeIcon: true,
  },
  [ActionKeys.TASK_CLOSE]: {
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.EDIT] }],
    title: 'Close',
    translationKey: 'close',
    icon: Icons.ARROW_UP,
    rotate: 90,
  },
  [ActionKeys.NOTE_EDIT]: {
    capabilities: [
      { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'Edit',
    translationKey: 'edit',
    icon: Icons.PENCIL,
  },
  [ActionKeys.NOTE_DELETE]: {
    capabilities: [
      { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.DELETE] },
    ],
    title: 'Delete',
    translationKey: 'delete',
    icon: Icons.TRASH,
  },
  [ActionKeys.SI_EDIT]: {
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.EDIT] }],
    title: 'Edit',
    translationKey: 'edit',
    icon: Icons.PENCIL,
  },
  [ActionKeys.SI_DELETE]: {
    capabilities: [
      { capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.DELETE] },
    ],
    title: 'Delete',
    translationKey: 'delete',
    icon: Icons.TRASH,
  },
  [ActionKeys.CASE_IN_PROGRESS]: {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'IN PROGRESS',
    translationKey: 'case_in_progress',
  },
  [ActionKeys.CASE_PENDING_INSPECTION]: {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'PENDING INSPECTION',
    translationKey: 'case_pending_inspection',
  },
  [ActionKeys.CASE_PENDING_REPAIR]: {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'PENDING REPAIR',
    translationKey: 'case_pending_repair',
  },
  [ActionKeys.CASE_SNOOZE]: {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'SNOOZE',
    translationKey: 'case_snooze',
  },
  [ActionKeys.CASE_TASK_UNLINK]: {
    title: 'UnLink',
    translationKey: 'unlink',
    icon: Icons.CASE,
  },
  [ActionKeys.CASE_TASK_LINK]: {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'Link',
    translationKey: 'link',
    icon: Icons.CASE,
  },
  [ActionKeys.CASE_CLOSE]: {
    capabilities: [
      { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.EDIT] },
    ],
    title: 'Close',
    translationKey: 'close',
    icon: Icons.ARROW_UP,
    rotate: 90,
  },
  [ActionKeys.FORWARD_REPORT]: {
    capabilities: [{ capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.CREATE] }],
    title: 'Forward Report',
    translationKey: '', // TODO
    icon: Icons.EXPORT,
    viewbox: '0 0 14 14',
    rotate: 270,
  },
  [ActionKeys.DOWNLOAD_REPORT]: {
    capabilities: [{ capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] }],
    title: 'Download Report',
    translationKey: '', // TODO
    icon: FileIcons.PDF,
    viewbox: '0 0 75 85',
  },
  [ActionKeys.DOWNLOAD_REPORT_AND_ATTACHMENTS]: {
    capabilities: [{ capability: Capability.GENERAL_REPORTING, scopes: [PermissionScope.VIEW] }],
    title: 'Download Report and Attachments',
    translationKey: '', // TODO
    icon: Icons.ATTACHMENT_ICON,
    viewbox: '0 0 14 14',
  },
};

export default ActionsMenuItems;

export const TaskMenuItems = {
  [ActionKeys.TASK_SCHEDULE_RESCHEDULE]: ActionsMenuItems[ActionKeys.TASK_SCHEDULE_RESCHEDULE],
  [ActionKeys.TASK_DELETE]: ActionsMenuItems[ActionKeys.TASK_DELETE],
  [ActionKeys.TASK_CREATE_SERVICE_REQUEST]:
    ActionsMenuItems[ActionKeys.TASK_CREATE_SERVICE_REQUEST],
  [ActionKeys.TASK_FLAG_BULK_EDIT]: ActionsMenuItems[ActionKeys.TASK_FLAG_BULK_EDIT],
  // Commenting out unused menu items for now. Will need to add these back as these other items become available.
  // [ActionKeys.TASK_LINK]: ActionsMenuItems[ActionKeys.TASK_LINK],
  // [ActionKeys.TASK_ADD_NOTE]: ActionsMenuItems[ActionKeys.TASK_ADD_NOTE],
  // [ActionKeys.TASK_CLOSE]: ActionsMenuItems[ActionKeys.TASK_CLOSE],
};

export const PanelTableActionsMenuItems = {
  [ActionKeys.CREATE_ALERT]: ActionsMenuItems[ActionKeys.CREATE_ALERT],
};

export const EventMenuItems = {
  [ActionKeys.EVENT_ACKNOWLEDGE]: ActionsMenuItems[ActionKeys.EVENT_ACKNOWLEDGE],
  [ActionKeys.EVENT_CHECKOUT]: ActionsMenuItems[ActionKeys.EVENT_CHECKOUT],
  [ActionKeys.EVENT_MARK_PENDING]: ActionsMenuItems[ActionKeys.EVENT_MARK_PENDING],
  [ActionKeys.EVENT_ESCALATE]: ActionsMenuItems[ActionKeys.EVENT_ESCALATE],
};

export const EventTableMenuItems = {
  [ActionKeys.EVENT_IN_PROGRESS]: ActionsMenuItems[ActionKeys.EVENT_IN_PROGRESS],
  [ActionKeys.EVENT_SNOOZE]: ActionsMenuItems[ActionKeys.EVENT_SNOOZE],
  [ActionKeys.EVENT_CLOSE]: ActionsMenuItems[ActionKeys.EVENT_CLOSE],
  [ActionKeys.EVENT_ADD_NOTE]: ActionsMenuItems[ActionKeys.EVENT_ADD_NOTE],
  [ActionKeys.EVENT_LOG_ACTION]: ActionsMenuItems[ActionKeys.EVENT_LOG_ACTION],
  [ActionKeys.EVENT_SEND_NOTIFICATION]: ActionsMenuItems[ActionKeys.EVENT_SEND_NOTIFICATION],
  [ActionKeys.CREATE_ALERT]: ActionsMenuItems[ActionKeys.CREATE_ALERT],
};

export const EventHistoryTableMenuItems = {
  [ActionKeys.EVENT_ADD_NOTE]: ActionsMenuItems[ActionKeys.EVENT_ADD_NOTE],
  [ActionKeys.EVENT_LOG_ACTION]: ActionsMenuItems[ActionKeys.EVENT_LOG_ACTION],
};

export const NotesMenuItems = {
  [ActionKeys.NOTE_EDIT]: ActionsMenuItems[ActionKeys.NOTE_EDIT],
  [ActionKeys.NOTE_DELETE]: ActionsMenuItems[ActionKeys.NOTE_DELETE],
};

export const SIMenuItems = {
  [ActionKeys.SI_EDIT]: ActionsMenuItems[ActionKeys.SI_EDIT],
  [ActionKeys.SI_DELETE]: ActionsMenuItems[ActionKeys.SI_DELETE],
};

export const AssetMonitorCaseMenuItems = {
  [ActionKeys.EVENT_CHANGE_CAUSAL_ALARM]: ActionsMenuItems[ActionKeys.EVENT_CHANGE_CAUSAL_ALARM],
  [ActionKeys.EVENT_IN_PROGRESS]: ActionsMenuItems[ActionKeys.EVENT_IN_PROGRESS],
  [ActionKeys.EVENT_CLOSE]: ActionsMenuItems[ActionKeys.EVENT_CLOSE],
  [ActionKeys.EVENT_SNOOZE]: ActionsMenuItems[ActionKeys.EVENT_SNOOZE],
  [ActionKeys.EVENT_LOG_ACTION]: ActionsMenuItems[ActionKeys.EVENT_LOG_ACTION],
  [ActionKeys.EVENT_ADD_NOTE]: ActionsMenuItems[ActionKeys.EVENT_ADD_NOTE],
};

export const AssetAnalyzeCaseMenuItems = {
  [ActionKeys.CASE_CLOSE]: ActionsMenuItems[ActionKeys.CASE_CLOSE],
  [ActionKeys.CASE_NEW]: ActionsMenuItems[ActionKeys.CASE_NEW],
  [ActionKeys.CASE_TASK_LINK]: ActionsMenuItems[ActionKeys.CASE_TASK_LINK],
};

export const AnomalyTableMenuItems = {
  [ActionKeys.CASE_CLOSE]: ActionsMenuItems[ActionKeys.CASE_CLOSE],
};

export const childCaseTaskMenuItems = {
  [ActionKeys.CASE_TASK_UNLINK]: ActionsMenuItems[ActionKeys.CASE_TASK_UNLINK],
};

export const CaseStatusMenuItems = {
  [ActionKeys.CASE_SNOOZE]: ActionsMenuItems[ActionKeys.CASE_SNOOZE],
  [ActionKeys.CASE_IN_PROGRESS]: ActionsMenuItems[ActionKeys.CASE_IN_PROGRESS],
  // Disabling this option for now
  // [ActionKeys.CASE_PENDING_INSPECTION]: ActionsMenuItems[ActionKeys.CASE_PENDING_INSPECTION],
  [ActionKeys.CASE_PENDING_REPAIR]: ActionsMenuItems[ActionKeys.CASE_PENDING_REPAIR],
};

export const SentReportsMenuItems = {
  [ActionKeys.FORWARD_REPORT]: ActionsMenuItems[ActionKeys.FORWARD_REPORT],
};
