export const modeChartOptions = {
  chart: {
    animation: false,
    backgroundColor: 'transparent',
    spacingRight: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    marginTop: 15,
    marginLeft: 0,
    type: 'column',
  },
  title: {
    align: 'left',
    style: {
      fontFamily: 'Museo Sans',
      fontSize: '11px',
      letterSpacing: '0.5px',
      lineHeight: '13px',
      textTransform: 'uppercase',
    },
    margin: 20,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    minPadding: 0,
    maxPadding: 0,
    visible: true,
    left: 5,
    labels: {
      enabled: true,
      align: 'center',
      style: {
        fontSize: '10px',
      },
    },
    tickLength: 0,
    gridLineWidth: 0,
    lineWidth: 0,
    lineColor: 'transparent',
    tickWidth: 0,
  },
  yAxis: {
    visible: true,
    gridLineWidth: 1,
    title: '',
    labels: {
      align: 'left',
      style: {
        fontSize: '10px',
      },
      x: 0,
      y: -3,
    },
    tickPosition: 'inside',
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      animation: false,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      pointWidth: 8,
    },
  },
  series: [
    {
      name: '',
      lineWidth: 1,
    },
  ],
};
