import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey, SegmentOffering, SegmentTranslated } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const ROLES_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const fetchRolesAndPermissions = () => {
  return request.get('/cmn/role-permissions', {
    baseURL: ROLES_BASE_URL,
  });
};

const applyProductName = (product) => {
  if (product === SegmentOffering.COMMON) {
    return SegmentTranslated.GLOBAL;
  } else if (product === SegmentOffering.ANALYZE_A) {
    return SegmentTranslated.ANALYZE_ADVANCED;
  } else if (product === SegmentOffering.ANALYZE_P) {
    return SegmentTranslated.ANALYZE;
  } else if (product === SegmentOffering.MANAGE_A) {
    return SegmentTranslated.EXECUTE_ADVANCED;
  } else if (product === SegmentOffering.MANAGE_P) {
    return SegmentTranslated.EXECUTE;
  } else if (product === SegmentOffering.MONITOR_A) {
    return SegmentTranslated.MONITOR_ADVANCE;
  } else if (product === SegmentOffering.MONITOR_P) {
    return SegmentTranslated.MONITOR;
  } else {
    return product;
  }
};

export const useAllRoles = () => {
  const {
    error,
    isError,
    isLoading: isAllRolesLoading,
    data: allroles,
  } = useQuery([QueryKey.ROLES_PERMISSIONS], fetchRolesAndPermissions, {
    refetchOnWindowFocus: false,
  });

  const mappedRoles = useMemo(() => {
    const formattedRoles = allroles?.map((role) => {
      return {
        a11yKey: role.name,
        tenantId: role.tenantId,
        defaultDesc: role.description,
        defaultLabel: role.name.replace(/[_-]/g, ' '),
      };
    });
    return formattedRoles;
  }, [allroles]);

  const mappedPermissions = useMemo(() => {
    const formattedPermissions = allroles?.map((role) => {
      return {
        roleName: role.name,
        tenantId: role.tenantId,
        segmentOfferings: role.products
          ?.filter(
            (product) =>
              product.name === 'cmn' ||
              product.name === 'dav-p' ||
              product.name === 'mwp-p' ||
              product.name === 'rtmc-p',
          )
          .map((r) => {
            const allCapabilites = r.capabilities?.map((y) => {
              // prettier-ignore
              let nm = y.name.split('-').slice(0, -1).join(' ');
              let perm = y.name.split('-').pop();
              return {
                name: nm,
                permission: perm,
              };
            });
            const grouped = Object.values(
              allCapabilites?.reduce((acc, { name, permission }) => {
                acc[name] = acc[name] || { name, permissions: [] };
                acc[name].permissions.push(permission);
                return acc;
              }, {}),
            );

            return {
              name: applyProductName(r.name),
              defaultTranslation: r.name,
              description: r.description,
              capabilities: grouped,
            };
          }),
      };
    });
    return formattedPermissions;
  }, [allroles]);

  const filteredPermission = (role) => {
    if (role) {
      const rolePermission = mappedPermissions?.filter(
        (permission) => permission.roleName === role,
      );
      return rolePermission;
    }
  };

  return {
    isLoading: isAllRolesLoading,
    isError,
    error,
    data: allroles,
    mappedRoles,
    mappedPermissions,
    filteredPermission,
  };
};
