import { fetchAllTasks } from '@ge/feat-manage/services';
import { range } from '@ge/util/array-utils';

const PAGE_SIZE = 3000;
const MAX_TASK_COUNT = 100000;

const buildBatchForFetchAllTasks = ({
  startDate,
  endDate,
  pageIndex,
  batchSize,
  siteIds,
  assetIds,
  serviceGroupIds,
  status,
  getBundledTasks,
  taskStatus,
}) =>
  range(batchSize, pageIndex).map((pageIdx) => {
    return fetchAllTasks({
      startDate,
      endDate,
      pageIdx,
      pageSize: PAGE_SIZE,
      siteIds,
      assetIds,
      serviceGroupIds,
      status,
      getBundledTasks,
      taskStatus,
    });
  });
const loadAllTasks = async ({
  startDate,
  endDate,
  siteIds,
  assetIds,
  serviceGroupIds,
  status,
  getBundledTasks,
  taskStatus,
}) => {
  let tasks = [];
  let workers = [];
  let isMaxRecord = false;
  let totalRecords = 0;
  const loadFeature = async () => {
    let totalPages = 0;
    try {
      let firstPage;
      firstPage = await fetchAllTasks({
        startDate,
        endDate,
        pageIdx: 0,
        pageSize: PAGE_SIZE,
        siteIds,
        assetIds,
        serviceGroupIds,
        status,
        getBundledTasks,
        taskStatus,
      });
      totalPages = firstPage.totalPages;
      tasks = firstPage.tasks;
      workers = firstPage.workers;
      if (firstPage.totalRecords > MAX_TASK_COUNT) {
        totalRecords = firstPage.totalRecords;
        isMaxRecord = true;
        return;
      }
      if (!totalPages || totalPages <= 1) return;
      // Getting remaining pages;
      const remainingPages = totalPages - 1;
      let batchResults;
      batchResults = await Promise.allSettled(
        buildBatchForFetchAllTasks({
          startDate,
          endDate,
          pageIndex: 1,
          batchSize: remainingPages,
          siteIds,
          assetIds,
          serviceGroupIds,
          status,
          getBundledTasks,
          taskStatus,
        }),
      );
      // try to push forward eagerly by only evaluating successful calls to see if we hit the last page
      // TOOD: revisit this approach
      const nonEmptyResult = batchResults.filter(({ value }) => value && value.tasks.length > 0);
      let taskArr = nonEmptyResult.map(({ value }) => value.tasks);
      let flattenArr = [].concat.apply([], taskArr);
      tasks = tasks.concat(flattenArr);
    } catch (err) {
      console.error('Batch fetch error ', err);
    }
  };
  await loadFeature();
  if (isMaxRecord) {
    return {
      count: totalRecords,
      isMax: true,
    };
  } else {
    return { tasks: tasks, workers: workers };
  }
};

// Remove this once all calls are migrated to fetchTasks
export const taskPage = async ({
  startDate,
  endDate,
  siteIds,
  assetIds,
  serviceGroupIds,
  status,
  getBundledTasks,
  taskStatus,
}) => {
  return loadAllTasks({
    startDate,
    endDate,
    siteIds,
    assetIds,
    serviceGroupIds,
    status,
    getBundledTasks,
    taskStatus,
  });
};

export const fetchTasks = async ({
  startDate,
  endDate,
  siteIds,
  assetIds,
  serviceGroupIds,
  status,
  getBundledTasks,
  taskStatus,
}) => {
  return loadAllTasks({
    startDate,
    endDate,
    siteIds,
    assetIds,
    serviceGroupIds,
    status,
    getBundledTasks,
    taskStatus,
  });
};
