import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  ErpType,
  ErpDynamicFieldType,
  ErpValuesFieldKey,
  DateTimeFormats,
} from '@ge/models/constants';

// gets the value from the data by field name
// also checks if value is in a collection (like dropdowns)
// and attempts to get the label from the collection if the value is defined
const getDataValue = ({ erpData, fieldName, metadataByRows }) => {
  let fieldValue = erpData[fieldName] ?? '';

  const { VALUES_BY_VALUES: valuesByValuesKey, ...valuesKeys } = ErpValuesFieldKey;
  const erpValuesKeys = Object.values(valuesKeys);

  let fieldMetadataValues;

  for (const cols of metadataByRows ?? []) {
    for (const [key, value] of cols ?? []) {
      let _values;

      if (fieldName === key) {
        _values = value;
      }

      if (_values) {
        const { [valuesByValuesKey]: valuesByValues, ...values } = _values;

        // because we have some custom values fields we try to exhaustively search through our known list of values fields
        for (const valuesKey of erpValuesKeys) {
          const valuesByKey = values[valuesKey];

          if (valuesByKey) {
            fieldMetadataValues = valuesByKey;

            break;
          }
        }

        if (valuesByValues) {
          fieldMetadataValues = valuesByValues.reduce(
            (_values, { values }) => ({ ..._values, ...values }),
            {},
          );

          break;
        }
      }
    }
  }

  if (fieldMetadataValues) {
    return fieldMetadataValues[fieldValue] ?? fieldValue; // fall back to field value in case, so we show something
  }

  return fieldValue;
};

const SectionContainer = styled.div`
  width: 100%;

  // making sure the select menus don't go out of their container
  // when they're displayed above the select menu button
  .select__menu-list {
    max-height: 200px;
  }
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
  width: 100%;
`;

const SectionRowSmall = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
  width: 40%;
`;

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-left: 40px;

  &:first-child {
    margin-left: 0;
  }

  & label {
    // TODO: add theme values for ERP tab or refactor to unify theme values used commonly across other tabs?
    color: ${(props) => props.theme.entityDetails.tasks.details.labelColor};
  }

  & input[type='text']:disabled {
    background: transparent;
    padding-left: 0;
  }
`;

const FieldLabel = styled.p`
  color: ${(props) => props.theme.input.labelTextColor};
  font-size: 11px;
  line-height: 13px;
`;

const FieldValue = styled.span`
  color: ${(props) => props.theme.table.dataTextColor};
  font-size: 13px;
  line-height: 18px;
`;

export const ViewDynamicSection = ({
  metadataByRows,
  erpType = ErpType.SERVICE_REQUEST,
  erpData,
  task,
}) => {
  if (!metadataByRows || !erpData) {
    return null;
  }

  const getDynamicField = (metadataKey, metadataField) => {
    if (!metadataField) return null;

    let _metadataKey = metadataKey;
    if (metadataKey === 'assetSerialNumber') {
      // `assetSerialNumber` in the submitted form is returned from the server in the `tag` field
      _metadataKey = 'tag';
    }

    let fieldValue = getDataValue({ erpData, fieldName: _metadataKey, metadataByRows });

    if (metadataField.type === ErpDynamicFieldType.ADDRESS && metadataField.shippingAddressTitle) {
      metadataField.title = metadataField.shippingAddressTitle;
    }

    // Server may send date data back in a different format; redisplay in the original format shown in the input.
    if (metadataField.type === ErpDynamicFieldType.DATE && fieldValue) {
      const dateValue = dayjs(fieldValue);
      fieldValue = dateValue.isValid() ? dateValue.format(DateTimeFormats.NEW_TASK) : null;
    }

    // Checkbox values shown comma sperated
    if (metadataField.type === ErpDynamicFieldType.CHECKBOX && fieldValue) {
      fieldValue = Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
    }

    if (metadataKey === 'assetSerialNumber' && task?.asset?.name) {
      fieldValue = `${task?.site?.name} | ${task?.asset?.name}`;
    } else if (metadataKey === 'assetSerialNumber' && !task?.asset?.name) {
      fieldValue = `${task?.site?.name}`;
    }

    return (
      <>
        <FieldLabel>{metadataField.title}</FieldLabel>
        <FieldValue>{fieldValue}</FieldValue>
      </>
    );
  };

  return (
    <SectionContainer>
      {metadataByRows.map((metadataRow, rowIndex) => {
        let Row = SectionRow;
        if (erpType === ErpType.WORK_ORDER) {
          if (rowIndex === 0) {
            Row = SectionRowSmall;
          }
        }

        return (
          <Row key={rowIndex}>
            {metadataRow.map((metadataColumn, columnIndex) => {
              return (
                <SectionColumn key={columnIndex}>
                  {getDynamicField(...metadataColumn)}
                </SectionColumn>
              );
            })}
          </Row>
        );
      })}
    </SectionContainer>
  );
};

ViewDynamicSection.propTypes = {
  metadataByRows: PropTypes.array.isRequired,
  erpType: PropTypes.string,
  erpData: PropTypes.object.isRequired,
  task: PropTypes.object,
};
