/**
 * Converts a kebab-case-string to a camelCaseString.
 *
 * @param {string} s A kebab-case string to convert to camelCase
 */
const camelize = (s) => s.replace(/-./g, (x) => x.toUpperCase()[1]);

/**
 * Converts a string to kebab-case
 * @param s
 * @return {string}
 */
const toKebab = (s = '') =>
  s
    .split(' ')
    .map((x) => x.toLowerCase())
    .join('-');

/**
 * Converts a string to i18n locale key
 * @param s
 * @return {string}
 */
const toLocaleKey = (s = '') => (s ? s.replace(/[ /]/g, '_').toLowerCase() : '');

module.exports = {
  camelize,
  toKebab,
  toLocaleKey,
};
