import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { TaskStatus } from '@ge/models';
import { QueryKey } from '@ge/models/constants';

export const useTaskBacklog = () => {
  const { fetchByStatus } = useStoreActions((store) => store.tasks);
  const backlogData = useStoreState((state) => state.siteManager.hydratedTaskBacklog);
  const { sitesForView } = useStoreState((state) => state.sites);
  const siteIds = useMemo(() => sitesForView.map((s) => s.id), [sitesForView]);

  const { isFetching, isError, error } = useQuery([QueryKey.TASK_SCHEDULE, { siteIds }], () =>
    fetchByStatus({ status: [TaskStatus.SCHEDULED, TaskStatus.UNSCHEDULED], siteIds }),
  );

  return useMemo(
    () => ({ data: backlogData, isFetching, isError, error }),
    [backlogData, error, isError, isFetching],
  );
};
