import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@ge/components/loader';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';

import { TenantContext } from '../context/tenant-context';
import { usePeopleTable } from '../data-hooks/use-people-table';
import { PeopleColumnDefs, tenantPeopleCols } from '../models/people-table-cols';

import BackToGlobalTablesButton from './back-to-global-tables-button';
import PeopleTable from './tables/people-table';
import TenantAdminActionsHeader from './tenant-admin-actions-header';

const SORT_STATE_ID = 'admin-people';

/**
 * Available sort metrics for People.
 */
export const PeopleMetric = {
  NAME: 'name',
};

const TenantPeople = ({ handlePersonSelect }) => {
  const { t } = useTranslation(['admin.people', 'general'], { useSuspense: false });

  const {
    tenantState: { selectedTenant },
  } = useContext(TenantContext);

  const [currentSearch, setCurrentSearch] = useState({});
  const [filterDefs, setFilterDefs] = useState({});

  const { sortDirection, sortMetric, updateSortMetric } = useColumnState({
    columnDefs: PeopleColumnDefs,
    defaultCols: tenantPeopleCols,
    defaultSortMetric: PeopleMetric.NAME,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: SORT_STATE_ID,
  });

  const { data: { data: people = [], filterValues } = {}, isPeopleLoading } = usePeopleTable({
    filters: filterDefs,
    search: currentSearch,
    tenantId: selectedTenant.id,
    sortMetric,
    sortDirection,
  });

  const handleFilter = useCallback(
    (updateDefs) => {
      setFilterDefs(updateDefs);
    },
    [setFilterDefs],
  );

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  return (
    <>
      <BackToGlobalTablesButton />
      <TenantAdminActionsHeader />
      <h1 className="pageTitle">{t('people', 'People')}</h1>
      {isPeopleLoading ? (
        <Loader />
      ) : (
        <PeopleTable
          columns={tenantPeopleCols}
          people={people}
          sortAction={updateSortMetric}
          sortMetric={sortMetric}
          sortDirection={sortDirection}
          onFilter={handleFilter}
          onFilterChange={handleFilterChange}
          filterValues={filterValues}
          onPersonSelect={handlePersonSelect}
          isDataLoading={isPeopleLoading}
        />
      )}
    </>
  );
};

TenantPeople.propTypes = {
  handlePersonSelect: PropTypes.func,
};

TenantPeople.defaultProps = {
  handlePersonSelect: () => null,
};

export default TenantPeople;
