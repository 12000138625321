import { KpiCategoryDefs } from '@ge/models/constants';

// wondering if we can determine this further upstream
// will we really get both time series or entities for a given category?
// if not, would be nice to have the api dictate this without having
// to specify in config
export const KpiCategorySeriesType = {
  ENTITY: 'entity',
  TIME_SERIES: 'timeSeries',
};

// TODO: using this to define things like max/min/interval on charts (need to review)
export const KpiCategoryDataType = {
  DURATION: 'duration', // using for mean time, etc
  ENERGY: 'energy', // using for mwh, etc
  NONE: 'none',
  PERCENT: 'percent',
  SPEED: 'speed', // using for m/s, etc
  TEMPERATURE: 'temperature',
};

// TODO: add more mappings as needed
export const KpiCategoryDataTypeMap = {
  // energy
  [KpiCategoryDefs.CUMULATIVE_PRODUCTION]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.PRODUCTION_ACTUAL]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.PRODUCTION_EXPECTED_LEARNED]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.PRODUCTION_LOST_CONTRACT]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.PRODUCTION_LOST_LEARNED]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]: KpiCategoryDataType.ENERGY,
  [KpiCategoryDefs.UNPRODUCED_ENERGY_LEARNED]: KpiCategoryDataType.ENERGY,
  // percent
  [KpiCategoryDefs.AVAILABILITY_CONTRACT]: KpiCategoryDataType.PERCENT,
  [KpiCategoryDefs.CAPACITY_FACTOR]: KpiCategoryDataType.PERCENT,
  [KpiCategoryDefs.PBA]: KpiCategoryDataType.PERCENT,
  [KpiCategoryDefs.TBA]: KpiCategoryDataType.PERCENT,
};

export const KpiGraphType = {
  ALTERNATE: 'alternate',
  DEFAULT: 'default',
};

export const SitePerformanceChartType = {
  SNAPSHOT: 'snapshot',
  UNAVAILABILITY: 'unavailability',
};

export const GaugeCategoryType = {
  DIAL_GAUGE: 'dialGauge',
  KPI: 'kpi',
};

export const ContractualAvailabilitySortMetric = {
  NAME: 'name',
  PERFORMANCE: 'performamce',
};

export const DashboardConditionType = {
  TEMPERATURE: 'temperature',
  WIND_DIRECTION_AND_SPEED: 'windBearing',
  WIND_SPEED: 'windSpeed',
};

export const ChartNames = {
  PARETO: 'pareto',
  TRENDS: 'trends',
  POWER_CURVE: 'power-curve',
  CONDITION: 'condition',
};

export const SiteOverviewGraphs = [ChartNames.PowerCurve, ChartNames.ParetoAnalysis];

export const EventLevelDataTypes = {
  OEM: 'oem',
  IEC: 'iec',
};

export const OmittedPlatforms = ['Scanwind', 'Tacke', 'Repower', 'Senvion', 'Nordex', 'ECO'];

export const reliabilitySeriesType = {
  EVENTS: 'events',
  ASSETS: 'assets',
};

export const reliabilityEventType = {
  CRITICAL: 1,
  WARNING: 2,
  INFO: 3,
  ALL: 4,
};

export const reliabilityGroupBy = {
  EVENTS: 'events',
  ASSETS: 'assets',
};

export const FilterIds = {
  count: 'count',
};

export const KPIList = {
  ACTUAL_POWER: 'actualPower',
  IRRADIANCE: 'irradiance',
  ENERGY_TREND: 'e_mwh',
  AVAILABILITY_TREND: 'avl',
  YIELD_TREND: 'yld',
  PR_TREND: 'pr',
  CAPACITY_FACTOR_TREND: 'cf',
  WEATHER_TREND: 'weather',
  BESS_CHARGE_ENERGY: 'bessChargeEnergy',
  BESS_DISCHARGE_ENERGY: 'bessDischargeEnergy',
  AMBIENT_TEMPERATURE: 'ambTemp',
  MODULE_TEMPERATURE: 'moduleTemp',
  REVENUE: 'revenue',
  RAW_AVAILABILITY_INVERTER: 'rawAvlInv',
  RAW_AVAILABILITY_DATE: 'rawAvlDate',
};
