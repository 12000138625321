import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { fetchSitesAssetsCount } from '@ge/shared/services/common';

export const useSitesAssetsCount = (selectedSites) => {
  const siteIds = selectedSites?.map(({ id }) => id);

  const {
    data: _data,
    error,
    isLoading,
  } = useQuery([QueryKey.VIEW_COUNT, siteIds], () => fetchSitesAssetsCount(siteIds), {
    ...Config.EXECUTE_ONCE,
    enabled: true,
  });

  const data = useMemo(() => {
    return _data ?? undefined;
  }, [_data]);

  return { data, error, isLoading };
};
