import { PropTypes } from 'prop-types';
import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { DonutChart } from '@ge/components/charts';
import { toolTipFormatter } from '@ge/feat-analyze/util/iec-loss-donut-util';
import { roundNumber } from '@ge/util';

export const IecLossDonutChart = ({
  data,
  value,
  unit,
  noDataError,
  showZero,
  selected,
  onCreated,
}) => {
  const { t } = useTranslation(['analyze.site'], { useSuspense: false });
  const theme = useContext(ThemeContext);
  const mappedData = useMemo(
    () =>
      data?.map((parent) => ({
        title: parent.title,
        value: parent.value,
        kpiValue: roundNumber(parent.kpiValue),
        id: parent.type,
        data: parent?.details?.map((child) => ({ ...child, id: child.type })),
      })),
    [data],
  );

  return (
    <DonutChart
      donutTitle={t('total_value', 'Total Value')}
      donutValue={value}
      donutUnit={unit}
      legendColumns={['', t(`unit.${unit}`, 'Value'), t('unit.%', '%')]}
      data={mappedData}
      noDataError={noDataError}
      showZero={showZero}
      selected={selected}
      onCreated={onCreated}
      tooltipPointFormatter={toolTipFormatter(theme)}
    />
  );
};

IecLossDonutChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  value: PropTypes.number,
  unit: PropTypes.string.isRequired,
  noDataError: PropTypes.string,
  showZero: PropTypes.bool,
  selected: PropTypes.bool,
  onCreated: PropTypes.func,
};
