import { PropTypes } from 'prop-types';
import React, { Children, cloneElement } from 'react';
import styled from 'styled-components';

import { CommonLocators } from '@ge/models/data-locators';
import { chartColors } from '@ge/tokens/charts';

const { level1: colors } = chartColors;

const LEGEND_KEY_DISPLAY_NAME = 'LegendKey';
const SERIES_KEY_DISPLAY_NAME = 'SeriesKey';

const legendChild = (child, index) => {
  // only render LegendKeys inside Legend
  if (!(child && child.type && child.type.displayName === LEGEND_KEY_DISPLAY_NAME)) {
    return null;
  }

  // if props doesn't specify symbol color, then use standard chart colors to generate
  const symbolColor = (child.props && child.props.symbolColor) || colors[index];

  return cloneElement(child, { symbolColor });
};

const StyledLegend = styled.div``;

const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-bottom: 10px;
  min-height: 12px;
`;

export const LegendKey = ({ children, name, symbolColor }) => {
  const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex: 0 1;
    flex-flow: row nowrap;
    justify-content: stretch;
    + * {
      margin-left: 16px;
    }
  `;

  const Symbol = styled.div`
    background-color: ${symbolColor};
    border-radius: 1px;
    box-sizing: border-box;
    flex: 0 0 11px;
    margin: 0px 4px 0px 0;
    max-height: 14px;
  `;

  const Key = styled.div`
    align-items: stretch;
    display: flex;
    flex: 1 1;
    flex-flow: column nowrap;
    justify-content: flex-end;
  `;

  const Name = styled.h4`
    flex: 1 1;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    white-space: pre;
  `;

  const KeyChildren = styled.div`
    flex: 1 1;
    font-weight: 500;
    white-space: pre;
  `;

  return (
    <Container>
      <Symbol />
      <Key>
        <Name>{name}</Name>
        {children && <KeyChildren className="body-4">{children}</KeyChildren>}
      </Key>
    </Container>
  );
};

LegendKey.displayName = LEGEND_KEY_DISPLAY_NAME;

const legendKeyPropTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  symbolColor: PropTypes.string,
};

LegendKey.propTypes = legendKeyPropTypes;

export const SeriesKey = ({ name, type, color }) => {
  const Container = styled.div`
    align-items: center;
    display: flex;
    flex: 0 1;
    flex-flow: row nowrap;
    justify-content: center;
    + * {
      margin-left: 16px;
    }
    margin-top: 1px;
    margin-left: 30px;
  `;

  const Symbol = styled.div`
    border-bottom: 2px ${({ type, color }) => `${type} ${color}`};
    height: 0px;
    position: relative;
    width: 52px;

    &::before,
    &::after {
      content: '';
      height: 0px;
      width: 0px;
      border: 3px solid ${({ color }) => color};
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: -2px;
    }

    &::before {
      left: -5px;
    }

    &::after {
      right: -5px;
    }
  `;

  const Key = styled.div`
    align-items: stretch;
    display: flex;
    flex: 1 1;
    flex-flow: column nowrap;
    justify-content: flex-end;
    margin-left: 15px;
  `;

  const Name = styled.h4`
    flex: 1 1;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    white-space: pre;
  `;

  return (
    <Container>
      <Symbol type={type} color={color} />
      <Key>
        <Name>{name}</Name>
      </Key>
    </Container>
  );
};

SeriesKey.displayName = SERIES_KEY_DISPLAY_NAME;

const seriesKeyPropTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
};

SeriesKey.propTypes = seriesKeyPropTypes;

export const Legend = ({ children, keys, seriesLegend }) => {
  if (!((keys && keys.length) || children)) {
    return null;
  }

  return (
    <StyledLegend>
      <Container data-testid={CommonLocators.COMMON_GRAPHS_LEGEND_WRAP}>
        {keys
          ? keys.map(({ children, name, symbolColor }, i) => (
              <LegendKey key={name} name={name} symbolColor={symbolColor || colors[i]}>
                {children}
              </LegendKey>
            ))
          : Children.map(children, legendChild)}
      </Container>
      <Container>
        {seriesLegend &&
          seriesLegend.map(({ type, name, color }) => (
            <SeriesKey key={name} type={type} name={name} color={color} />
          ))}
      </Container>
    </StyledLegend>
  );
};

Legend.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.shape(legendKeyPropTypes)),
  children: PropTypes.node,
  seriesLegend: PropTypes.arrayOf(PropTypes.shape(seriesKeyPropTypes)),
};
