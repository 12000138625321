import * as request from '@ge/shared/services/api';

const BASE_URL_DAV_ASSET = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_ASSET_API;

/**
 * Fetch the signal data for the specified asset
 */
export const fetchAssetsSignalData = (assetIds = [], { start, end, signals }) => {
  const body = { assetIds, start, end, signals };
  return request.post('/dav/assets/signal-data', body, {
    baseURL: BASE_URL_DAV_ASSET,
  });
};
