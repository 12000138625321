import { PropTypes } from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import {
  ClipboardContentType,
  ContentType,
  FileType,
  FileExtensionType,
} from '@ge/models/constants';
import { killEventPropagation, reverseDictionary } from '@ge/shared/util/general';

import { FilePreview } from './file-preview';

const extMap = {
  [FileExtensionType[FileType.JPEG]]: FileExtensionType[FileType.JPEG],
  [FileExtensionType[FileType.JPG]]: FileExtensionType[FileType.JPG],
  [FileExtensionType[FileType.PNG]]: FileExtensionType[FileType.PNG],
  [FileExtensionType[FileType.GIF]]: FileExtensionType[FileType.GIF],
  [FileExtensionType[FileType.CSV]]: FileExtensionType[FileType.CSV],
  [FileExtensionType[FileType.MSG]]: FileExtensionType[FileType.MSG],
  [FileExtensionType[FileType.PDF]]: FileExtensionType[FileType.PDF],
  [FileExtensionType[FileType.PPT]]: FileExtensionType[FileType.PPT],
  [FileExtensionType[FileType.PPT]]: FileExtensionType[FileType.PPTX],
  [FileExtensionType[FileType.PPTX]]: FileExtensionType[FileType.PPTX],
  [FileExtensionType[FileType.XLS]]: FileExtensionType[FileType.XLSX],
  [FileExtensionType[FileType.XLSM]]: FileExtensionType[FileType.XLSM],
  [FileExtensionType[FileType.XLSX]]: FileExtensionType[FileType.XLSX],
  [FileExtensionType[FileType.DOC]]: FileExtensionType[FileType.DOCX],
  [FileExtensionType[FileType.DOCX]]: FileExtensionType[FileType.DOCX],
  [FileExtensionType[FileType.ZIP]]: FileExtensionType[FileType.ZIP],
  [FileExtensionType[FileType.XML]]: FileExtensionType[FileType.XML],
};

const contentTypeMap = {
  [FileType.CSV]: ContentType.CSV,
  [FileType.JPEG]: ContentType.JPEG,
  [FileType.JPG]: ContentType.JPG,
  [FileType.GIF]: ContentType.GIF,
  [FileType.MSG]: ContentType.MSG,
  [FileType.PDF]: ContentType.PDF,
  [FileType.PNG]: ContentType.PNG,
  [FileType.PPT]: ContentType.PPT,
  [FileType.PPTX]: ContentType.PPTX,
  [FileType.XLS]: ContentType.XLS,
  [FileType.XLSX]: ContentType.XLSX,
  [FileType.XLSM]: ContentType.XLSM,
  [FileType.DOC]: ContentType.DOC,
  [FileType.DOCX]: ContentType.DOCX,
  [FileType.ZIP]: ContentType.ZIP,
  [FileType.XML]: ContentType.XML,
  // aggregates
  [FileType.IMAGE]: `${ContentType.JPEG}, ${ContentType.PNG}, ${ContentType.JPG}, ${ContentType.GIF},`,
  // TODO: expand this as needed
};

const fileTypeMap = reverseDictionary(contentTypeMap);

const getFile = ({ file, id, emailType }) => ({
  file,
  id: id ?? file.name,
  path: emailType ? id : file.name,
  preview: URL.createObjectURL(file),
  type: file.type,
  ext: String(file.name).match(/\.[0-9a-z]+$/i)[0],
  fileType: fileTypeMap[file.type],
});

const UploadContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.fileUpload.backgroundColor};
  border: 1px dashed ${({ theme }) => theme.fileUpload.borderColor};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 16px;
  &.file-upload-error {
    border: 1px dashed ${({ theme }) => theme.fileUpload.errorBorderColor};
  }
  &.file-upload.container {
    margin-top: 5px;
    padding: 30px;
  }

  > div {
    color: ${({ theme }) => theme.fileUpload.textColor};
    font-size: 14px;
    font-weight: 300;

    + div {
      margin-top: 8px;
    }

    .upload-button {
      background: ${({ theme }) => theme.fileUpload.uploadButtonBackgroundColor};
      border: 1px solid ${({ theme }) => theme.fileUpload.uploadButtonBorderColor};
      color: ${({ theme }) => theme.fileUpload.textColor};
      font-size: 13px;
      font-weight: 500;
      padding: 5px 16px;
      &:disabled {
        cursor: not-allowed;
        background: ${({ theme }) => theme.analyze.pareto.disabledButtonColor};
      }
    }
  }
`;

const FileUploadLabel = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.fileUpload.filenameTextColor};
  display: ${(props) => props.hideCollapsiblePanel && 'none'};
`;

const UploadInfo = styled.div`
  padding-top: 6px;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.fileUpload.filenameTextColor};
  &.file-upload-error {
    color: ${({ theme }) => theme.fileUpload.errorTextColor};
  }
`;

const UploadIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.fileUpload.uploadIconColor,
  icon: Icons.EXPORT,
  rotate: 270,
  size: 20,
  viewbox: '0 0 13 13',
}))`
  transform: rotateX(180deg);
`;

// TODO: really handle required (right now just changes label)
export const FileUpload = forwardRef(
  (
    {
      allowedFileTypes,
      maxFiles,
      isEscalateCase,
      maxFileSizeMb,
      onUpload,
      required,
      files: initialFiles,
      emailType,
      hideCollapsiblePanel,
      uploadTemplateMode,
    },
    ref,
  ) => {
    const { ready, t } = useTranslation(['general'], { useSuspense: false });
    const [maxAttachFile, setMaxAttachFile] = useState(false);

    // state
    const [files, setFiles] = useState(Array.isArray(initialFiles) ? [...initialFiles] : []);
    const [errors, setErrors] = useState([]);

    // this is used by any parent component that wants to reset the uploaded files programmatically
    useImperativeHandle(ref, () => ({
      resetFiles: () => setFiles([]),
    }));

    useEffect(() => {
      onUpload(files, errors);
    }, [files, errors, onUpload]);

    // revoke data uris to avoid memory leaks
    useEffect(
      () => () => {
        files.forEach(({ preview }) => URL.revokeObjectURL(preview));
      },
      [files],
    );

    useEffect(() => {
      if (maxFiles === 0) setErrors(['Max limit reached']);
    }, [maxFiles]);

    // handlers
    const handleDelete = useCallback((id) => {
      setFiles((prev) => prev.filter((prev) => id !== prev.id));
    }, []);

    const getUniqueImageId = useCallback(
      (file, length) => {
        const arr = file.name?.split('.');
        const ext = arr.pop();
        return `${arr.join('.')}_${emailType}_${length + 1}.${ext}`;
      },
      [emailType],
    );

    useEffect(() => {
      if (files.length >= 5) {
        setMaxAttachFile(true);
      }
      const timer = setTimeout(() => setMaxAttachFile(false), 5000);
      return () => {
        clearTimeout(timer);
      };
    }, [files]);

    const handleDrop = useCallback(
      (acceptedFiles, rejectedFiles) => {
        // emulating file dialog behavior of not adding any if exceeds specified max files
        // if we want to eagerly add files up to the cut off can try to add custom behavior
        let remainingFiles =
          maxFiles == null ? undefined : maxFiles - files.length - acceptedFiles.length;

        // hit max number of files
        // have to check this here because we allow pasting files that dropzone doesn't track
        if (remainingFiles < 0) {
          return;
        }

        const fileIds = files.map(({ id }) => id);
        // ignore dupes
        const distinctFiles = acceptedFiles.filter(({ path: id }) => !fileIds.includes(id));

        const updateFiles = [
          ...files,
          ...distinctFiles.map((file) =>
            getFile({
              file,
              id: emailType ? getUniqueImageId(file, fileIds.length) : file.path,
              emailType,
            }),
          ),
        ];

        setFiles(updateFiles);

        const rejections = rejectedFiles.reduce(
          (result, file) => [...result, ...file.errors.map((err) => err.message)],
          [],
        );

        setErrors(rejections);
      },
      [files, maxFiles, emailType, getUniqueImageId],
    );

    const handlePaste = useCallback(
      (event) => {
        const clipboard = event.clipboardData ?? event.originalEvent?.clipboardData;

        if (!clipboard?.items?.length) {
          return;
        }

        // emulating dropzone behavior of not adding any if exceeds specified max files
        // if we want to eagerly add files up to the cut off can define custom behavior for that
        let remainingFiles =
          maxFiles == null ? undefined : maxFiles - files.length - clipboard.items.length;

        // hit max number of files
        if (remainingFiles < 0) {
          return;
        }

        const fileIds = files.map(({ id, path }) => id ?? path);
        // using a map because any changes to original file causes error when creating object url
        const distinctFiles = new Map();

        // need to iterate this way because items is a DataTransferItemList
        for (const item of clipboard.items) {
          // only looking for files in clipboard
          if (item.kind !== ClipboardContentType.FILE) {
            continue;
          }

          const file = item.getAsFile();
          // kind of hacky but using filename and size to track unique images pasted from clipboard that have same filename by default
          // could hash the file for id, but seems overkill here
          let id = '';
          if (emailType) {
            id = getUniqueImageId(file, fileIds.length);
          } else {
            id = `${file.name}_${file.size}`;
          }

          // ignore dupes
          if (fileIds.includes(id)) {
            continue;
          }

          distinctFiles.set(id, file);
        }

        const updateFiles = [
          ...files,
          ...Array.from(distinctFiles.entries()).map(([id, file]) =>
            getFile({ file, id, emailType }),
          ),
        ];

        setFiles(updateFiles);

        onUpload(updateFiles);
      },
      [files, maxFiles, onUpload, emailType, getUniqueImageId],
    );

    // related to a known issue with dropzone opening file dialog twice due to interaction with parent container
    // https://stackoverflow.com/a/62695076
    const handleSuppressParentClick = useCallback((event) => {
      killEventPropagation(event);
    }, []);

    const { accept, fileTypes } = useMemo(() => {
      const accept = Array.isArray(allowedFileTypes)
        ? allowedFileTypes.map((type) => {
            if (FileExtensionType[type]) {
              return FileExtensionType[type];
            } else if (ContentType[type]) {
              const fileType = fileTypeMap[type];
              return FileExtensionType[fileType];
            }
            return type;
          })
        : [];
      return {
        accept,
        fileTypes: [
          ...new Set(
            accept?.map((ext) => String(extMap[ext]).replace(/^\.(.*)([x]+)$/gi, '.$1(x)')),
          ),
        ].join(', '),
      };
    }, [allowedFileTypes]);

    const handleUploadTemplateModeError = useCallback(() => {
      if (uploadTemplateMode && errors.length) {
        if (errors.includes('File type must be .xlsx')) return 'File type must be .xlsx';
      }
      if (errors.includes('Too many files'))
        return 'Maximum 1 file; 7MB Limit. Allowed file types:.xls(x)';
    }, [errors, uploadTemplateMode]);

    const { getInputProps, getRootProps, open } = useDropzone({
      accept,
      maxFiles,
      maxSize: maxFileSizeMb ? maxFileSizeMb * 1024 ** 2 : undefined,
      multiple: true,
      noClick: true,
      onDrop: handleDrop,
    });

    if (!ready) {
      return null;
    }

    const translations = {
      infoLabel1: t('file_upload.info_label_1', 'Drop your files here'),
      infoLabel2: t('file_upload.info_label_2', 'or'),
      label: t(required ? 'file_upload.label_required' : 'file_upload.label', 'Attach files'),
      uploadButton: t('file_upload.upload_button', 'Browse files'),
      info_with_max_file_and_limit: t('file_upload.info_with_max_file_and_limit', {
        max_files_info:
          maxFiles === 1
            ? t('file_upload.max_files_info', {
                max_files: maxFiles,
              }).slice(0, -1)
            : isEscalateCase
            ? t('file_upload.max_files_info', {
                max_files: 5,
              })
            : maxFiles > 1
            ? t('file_upload.max_files_info', {
                max_files: maxFiles,
              })
            : maxFiles === 0
            ? t('file_upload.max_files_limit_reached')
            : '',
        size_limit_info:
          maxFileSizeMb > 0
            ? t('file_upload.size_limit_info', {
                size: maxFileSizeMb,
              })
            : '',
        file_type_info: t('file_upload.file_type_info', {
          type: fileTypes,
        }),
      }),
      infoLabel: t('file_upload.info', {
        file_size_info:
          maxFileSizeMb > 0
            ? t('file_upload.file_size_info', {
                size: maxFileSizeMb,
              })
            : '',
        file_type_info: t('file_upload.file_type_info', {
          type: fileTypes,
        }),
      }),
    };

    return (
      <>
        {!hideCollapsiblePanel && <FileUploadLabel>{translations.label}</FileUploadLabel>}
        <UploadContainer
          className={`file-upload container ${errors.length > 0 ? 'file-upload-error' : ''}`}
          onPaste={handlePaste}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div>
            <UploadIcon />
          </div>
          <div>{translations.infoLabel1}</div>
          {translations.infoLabel2 && <div>{translations.infoLabel2}</div>}
          <div onClick={handleSuppressParentClick}>
            <button
              className="upload-button"
              onClick={open}
              type="button"
              disabled={maxFiles === 0}
            >
              {translations.uploadButton}
            </button>
          </div>
        </UploadContainer>
        {!hideCollapsiblePanel && (
          <UploadInfo
            className={`${errors.length > 0 || maxAttachFile ? 'file-upload-error' : ''}`}
          >
            {translations.info_with_max_file_and_limit}
          </UploadInfo>
        )}
        {uploadTemplateMode && errors.length > 0 && (
          <UploadInfo className="file-upload-error">{handleUploadTemplateModeError()}</UploadInfo>
        )}
        <FilePreview files={files} handleDelete={handleDelete} />
      </>
    );
  },
);

FileUpload.propTypes = {
  files: PropTypes.array,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  maxFiles: PropTypes.number,
  maxFileSizeMb: PropTypes.number,
  onUpload: PropTypes.func,
  hideCollapsiblePanel: PropTypes.bool,
  required: PropTypes.bool,
  emailType: PropTypes.string,
  uploadTemplateMode: PropTypes.bool,
  isEscalateCase: PropTypes.bool,
};

FileUpload.defaultProps = {
  files: null,
  allowedFileTypes: null,
  maxFiles: null,
  maxFileSizeMb: null,
  onUpload: () => {},
  hideCollapsiblePanel: false,
  required: false,
  emailType: '',
};
