export const AppScopes = {
  /** *
   * Feature Scopes (Navigation)
   */
  PROFILE: 'feat-profile',

  // Analyze Nav Items
  ANALYZE_MANUAL_ADJUSTMENT: 'feat-analyze-manual-adjustment',
  ANALYZE_REPORTS: 'feat-analyze-reports',
  ANALYZE_SITES: 'feat-analyze-sites',
  ANALYZE_CONTRACTS: 'feat-analyze-contracts',
  ANALYZE_SITE: 'feat-analyze-site',
  ANALYZE_ASSETS: 'feat-analyze-assets',
  ANALYZE_IVERTERS: 'feat-analyze-inverters',
  ANALYZE_REGION: 'feat-analyze-region',
  ANALYZE_FLEET_OVERVIEW: 'feat-analyze-fleet-overview',
  ANALYZE_TROUBLESHOOTING: 'feat-analyze-troubleshooting',
  ANALYZE_CASES_TASKS: 'feat-analyze-cases-tasks',
  ANALYZE_ASSET_OVERVIEW: 'feat-analyze-asset-overview',
  ANALYSIS_TEMPLATE: 'feat-analysis-template',
  ANALYZE_APPLICATION_CONFIGURATION: 'feat-analyze-application-configuration',
  ANALYZE_CASE_TEMPLATE: 'feat-analyze-case-template',
  ANALYZE_ANALYSIS_TEMPLATE: 'feat-analyze-analysis-template',

  // Manage Nav Items
  MANAGE_OVERVIEW: 'feat-manage-overview',
  MANAGE_SERVICE: 'feat-manage-service',
  MANAGE_LABOR: 'feat-manage-labor',
  MANAGE_CASES_TASKS: 'feat-manage-cases-tasks',
  MANAGE_REPORTS: 'feat-manage-reports',

  // Monitor Nav Items
  MONITOR_ISSUES: 'feat-monitor-issues',
  MONITOR_STATUS: 'feat-monitor-status',
  MONITOR_SITES: 'feat-monitor-sites',
  MONITOR_SITES_WIND: 'feat-monitor-sites-wind',
  MONITOR_SITES_STORAGE: 'feat-monitor-sites-storage',
  MONITOR_ASSETS: 'feat-monitor-assets',
  MONITOR_REPORTS: 'feat-monitor-reports',
  MONITOR_ISSUES_EVENTS: 'feat-monitor-issues-events',
  MONITOR_ISSUES_HISTORY: 'feat-monitor-issues-history',
  MONITOR_ISSUES_ANOMALIES: 'feat-monitor-issues-anomalies',
  HANDLING_PROCEDURE_CONFIGURE: 'feat-admin-configure-handlingProcedures',

  // Admin Nav Items
  ADMIN_PEOPLE: 'feat-admin-people',
  ADMIN_ROLES: 'feat-admin-roles',
  ADMIN_CONFIGURE: 'feat-admin-configure',
  ADMIN_CONFIGURE_DISTRIBUTION_LISTS: 'feat-admin-configure-distributionLists',
  MONITOR_APPLICATION_CONFIGURATION: 'feat-monitor-application-configuration',
  ADMIN_CONFIGURE_HANDLING_PROCEDURES: 'feat-admin-configure-handlingProcedures',
  ADMIN_CONFIGURE_EVENT_MANAGEMENT: 'feat-admin_configure_event_management',
  ADMIN_CONFIGURE_DISTRIBUTION_LIST: 'feat-admin_configure_distribution_list',

  // Reporting
  REPORTING_REPORTS: 'feat-reporting-reports',
  REPORTING_REPORTS_MY_REPORTS: 'feat-reporting-reports-my-reports',
  REPORTING_REPORTS_CREATE: 'feat-reporting-reports-create',

  // Inspections Items
  INSPECTIONS_REPORTS: 'feat-inspections-reports',
  INSPECTIONS_LIST: 'feat-inspections-list',
  INSPECTIONS_UPLOAD: 'feat-inspections-upload',
  INSPECTIONS_PREVIEW: 'feat-inspections-preview',
  INSPECTIONS_EXECUTION: 'feat-inspections-execution',
  INSPECTIONS_POST_PROCESS: 'feat-inspections-post-process',
  INSPECTIONS_REPORT_GENERATION: 'feat-inspections-report-generation',
  INSPECTIONS_RESULTS: 'feat-inspections-results',
  INSPECTIONS_IMAGE_VIEWER: 'feat-inspections-image-viewer',
  INSPECTIONS_PERFORMANCE: 'feat-inspections-performance',
  INSPECTIONS_ANALYTICS: 'feat-inspections-analytics',

  /** *
   * Tickets Scopes
   */
  TICKETS_RO: 'tickets-ro',
  TICKETS_RW: 'tickets-rw',

  /** *
   * Turbine Scopes
   */
  TURBINE_ACTIONS_RW: 'turbine-actions-rw',
};
