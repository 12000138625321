import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useMemo, useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { DateMetaField, NumberMetaField, TimeMetaField } from '@ge/shared/components/meta-fields';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { addUTCOffset } from '@ge/shared/util/time-date';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors';

import { StyledDateContainer } from '../task-template-shared';
import { TimingErrors } from '../templates/sections/timing-section/timing-errors';

dayjs.extend(duration);

const TimingRow = styled.div`
  margin: 20px 0 10px;
`;

const TimingFields = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TimingDateContainer = styled(StyledDateContainer)`
  margin-right: 0;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-bottom: 8px;
`;

export const StyledNumberMetaField = styled(NumberMetaField)`
  color: ${(props) => props.theme.entityDetails.tasks.details.inputColor};
  width: 70px;
  height: 25px;
`;

const AddTimeButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  margin: 0 4px 15px auto;
  text-transform: uppercase;
`;

const AddTimeIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;
const StyledTimingErrors = styled(TimingErrors)``;

export const StyledErrorMessage = styled.span`
  color: ${StatusColor.DANGER};
  margin-top: 5px;

  &:before {
    content: '\\2296'; // &ominus;
    font-size: 13px;
    padding-right: 2px;
  }
`;

export const NumberFieldAlignment = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DurationMinutesField = styled.div`
  margin-top: 18px;
`;

export const Timing = ({ metadata }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const {
    taskState: { timezone },
  } = useContext(TaskContext);
  const { control, setValue, watch, errors } = useFormContext();
  const watchTiming = watch('timing');

  const { fields, prepend, remove } = useFieldArray({
    name: 'timing',
    control,
  });

  const defaultTiming = useMemo(
    () => ({
      date: '',
      durationHrs: 0,
      durationMins: 0,
      startTime: '',
      endTime: '',
    }),
    [],
  );

  const handleRemove = useCallback(
    (index) => {
      // Only clear field array values if there is one row, otherwise delete row
      if (watchTiming?.length === 1) {
        setValue(`timing[${index}]`, { ...defaultTiming });
      } else remove(index);
    },
    [defaultTiming, remove, setValue, watchTiming],
  );

  useEffect(() => {
    if (!watchTiming?.length) {
      prepend(defaultTiming);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimingRow>
      <div>
        {fields.map((timePeriod, index) => {
          return (
            <div key={timePeriod.id}>
              <TimingFields className="timing">
                <TimingDateContainer>
                  <div className="label">{t('form.completed_date', 'Completed Date')}*</div>
                  <DateMetaField
                    defaultSelection="currentDate"
                    defaultValue={
                      timePeriod.date
                        ? addUTCOffset(timePeriod.date, timezone)
                        : dayjs().startOf('date')
                    }
                    fieldArray={{ fieldName: 'timing', errorIndex: index, errorName: 'date' }}
                    name={`timing[${index}].date`}
                    metadata={{ ...metadata, required: true }}
                    validators={[{ latestDate: 'currentDate' }]}
                  />
                </TimingDateContainer>
                <TimeMetaField
                  date={watchTiming[index]?.date || ''}
                  defaultValue={
                    timePeriod.startTime ? addUTCOffset(timePeriod.startTime, timezone) : null
                  }
                  label={t('form.start_time', 'Start Time')}
                  name={`timing[${index}].startTime`}
                  metadata={metadata}
                  setDuration={(startTime) => {
                    const duration =
                      startTime && watchTiming[index].endTime
                        ? dayjs.duration(dayjs(watchTiming[index].endTime).diff(dayjs(startTime)))
                        : '';
                    if (duration) {
                      setValue(`timing[${index}].durationMins`, duration.$d.minutes);
                      setValue(`timing[${index}].durationHrs`, duration.$d.hours);
                    }
                  }}
                />
                <TimeMetaField
                  date={watchTiming[index]?.date || ''}
                  defaultValue={
                    timePeriod.endTime ? addUTCOffset(timePeriod.endTime, timezone) : null
                  }
                  label={t('form.end_time', 'End Time')}
                  name={`timing[${index}].endTime`}
                  metadata={metadata}
                  fieldArray={{ fieldName: 'timing', errorIndex: index, errorName: 'endTime' }}
                  rules={{
                    validate: {
                      endTimeValid: () =>
                        watchTiming[index].startTime && watchTiming[index].endTime
                          ? dayjs(watchTiming[index].endTime)
                              .startOf('second')
                              .isAfter(watchTiming[index].startTime)
                          : true,
                    },
                  }}
                  setDuration={(endTime) => {
                    const duration =
                      watchTiming[index].startTime && endTime
                        ? dayjs.duration(dayjs(endTime).diff(dayjs(watchTiming[index].startTime)))
                        : '';
                    if (duration) {
                      setValue(`timing[${index}].durationMins`, duration.$d.minutes);
                      setValue(`timing[${index}].durationHrs`, duration.$d.hours);
                    }
                  }}
                />
                <NumberFieldAlignment>
                  <StyledNumberMetaField
                    defaultValue={parseInt(timePeriod.durationHrs)}
                    fieldArray={{
                      fieldName: 'timing',
                      errorIndex: index,
                      errorName: 'durationHrs',
                    }}
                    isInteger
                    label={t('form.duration', 'Duration')}
                    max={99}
                    metadata={{ ...metadata, required: true }}
                    min={0}
                    name={`timing[${index}].durationHrs`}
                    placeholder="0"
                    rules={{
                      validate: {
                        positive: (v) =>
                          parseInt(v) >= 0 ||
                          t('form.min_hour_limit', 'Hours field cannot be less than 0'),
                        lessThanTen: (v) =>
                          parseInt(v) < 100 ||
                          t('form.max_hour_limit', 'Hours field cannot be greater than 99'),
                      },
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`timing[${index}].durationHrs`}
                    render={({ message }) => (
                      <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                    )}
                  />
                </NumberFieldAlignment>
                <DurationMinutesField>
                  <NumberFieldAlignment>
                    <StyledNumberMetaField
                      defaultValue={parseInt(timePeriod.durationMins)}
                      fieldArray={{
                        fieldName: 'timing',
                        errorIndex: index,
                        errorName: 'durationMins',
                      }}
                      isInteger
                      max={59}
                      metadata={{ ...metadata, required: true }}
                      min={0}
                      name={`timing[${index}].durationMins`}
                      placeholder="0"
                      rules={{
                        validate: {
                          positive: (v) =>
                            watchTiming[index].durationHrs > 0
                              ? parseInt(v) >= 0 ||
                                t('form.min_minute_limit', 'Minutes field cannot be less than 0')
                              : (watchTiming[index].durationHrs === 0 && parseInt(v) > 0) ||
                                t('form.min_duration_limit', 'Duration cannot be 0 hrs and 0 mins'),
                          lessThanTen: (v) =>
                            parseInt(v) < 60 ||
                            t('form.max_minute_limit', 'Minutes field cannot be greater than 59'),
                        },
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`timing[${index}].durationMins`}
                      render={({ message }) => (
                        <StyledErrorMessage role="alert">{message}</StyledErrorMessage>
                      )}
                    />
                  </NumberFieldAlignment>
                </DurationMinutesField>
                <button type="button" onClick={() => handleRemove(index)}>
                  <StyledIcon icon={Icons.TRASH} size={13} />
                </button>
              </TimingFields>

              <StyledTimingErrors index={index} />
            </div>
          );
        })}
      </div>
      <AddTimeButton type="button" onClick={() => prepend(defaultTiming)}>
        <AddTimeIcon />
        {t('form.time_period', 'Time Period')}
      </AddTimeButton>
    </TimingRow>
  );
};

Timing.propTypes = {
  metadata: PropTypes.object.isRequired,
};
