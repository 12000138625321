import React from 'react';
import styled from 'styled-components';

import { MiniLoader } from '@ge/components/loader';

const LoaderContainer = styled.div`
  min-height: 100px;
  position: relative;
  display: block;
`;

export const MenuLoader = () => (
  <LoaderContainer>
    <MiniLoader />
  </LoaderContainer>
);
