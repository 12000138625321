import dayjs from 'dayjs';

import { DateTimeFormats, SignalIdTypes, TimeSignal } from '@ge/models/constants';
import { getMinMax } from '@ge/util';

export const sortBySelected = (selected) => (a, b) => {
  const isSelectedA = selected.find(
    (v) => v.id === a.id && String(v.timeAggr).toUpperCase() === String(a.timeAggr).toUpperCase(),
  );
  const isSelectedB = selected.find(
    (v) => v.id === b.id && String(v.timeAggr).toUpperCase() === String(b.timeAggr).toUpperCase(),
  );
  if (isSelectedA && isSelectedB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
  return isSelectedA ? -1 : 1;
};

export const getSignalTitle = (signal) => {
  const name = signal.name ?? signal.id;
  const timeAggr = signal.timeAggr
    ? ` [${String(signal.timeAggr).toUpperCase().split('_')[0]}]`
    : '';
  return name + timeAggr;
};

export const getSignalSourceBasedOnType = (signal) => {
  if (signal.id === TimeSignal.id) {
    return TimeSignal.signalSource;
  }

  return {
    [SignalIdTypes.CANONICAL]: 'HistoricalMachineData-Canonical',
    [SignalIdTypes.NON_CANONICAL]: 'HistoricalMachineData-Source',
  }[signal.type];
};

export const getSignal = (signalData, signal) => {
  if (!signal) return {};
  if (signal.id === TimeSignal.id) return { ...TimeSignal };

  const _signal = signalData?.find((v) => v.id === signal.id) ?? signal;
  return { ..._signal, title: signal.title, timeAggr: signal.timeAggr, yAxis: signal?.yAxis ?? 0 };
};

export const getDateLabel = ({ value }) =>
  value && dayjs.unix(Number(value)).format(DateTimeFormats.DEFAULT_DATE_TIME);

export const getTooltipStyle = ({ theme }) => ({
  container: `
    display: block;
    min-width: 180px;
  `,
  section: `
    display: block;
    padding: 0px;
    margin: 0px;
  `,
  heading: `
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.dataExplorer.charts.tooltipBorderColor};
    padding: 0px 0px 5px;
    margin: 0px 0px 5px;
    font-size: 10px;
    line-height: 1.5;
    color: ${theme.dataExplorer.charts.tooltipHeadingColor};
  `,
  subheading: `
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.dataExplorer.charts.tooltipBorderColor};
    padding-bottom: 2px;
    margin: 5px 0 4px;
    font-size: 10px;
    line-height: 1.5;
    color: ${theme.dataExplorer.charts.tooltipHeadingColor};
  `,
  kpiWrapper: `display: block;`,
  kpi: `
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    line-height: 1.5;
    color: ${theme.dataExplorer.charts.tooltipColor};
  `,
  assetName: `
    flex: 1
  `,
  yKpiName: `
    color: ${theme.dataExplorer.charts.tooltipKpiNameColor};
  `,
  yKpiValue: `
    margin-left: 10px;
    color: ${theme.dataExplorer.charts.tooltipKpiValueColor};
  `,
  xKpiName: `
    margin-right: 5px;
  `,
  xKpiValue: `
    margin-left: 10px;
  `,
});

export const tooltipFormatter =
  ({ theme, chartDef, assets }) =>
  ({ x, clusteredData, point, points }) => {
    const {
      container,
      section,
      heading,
      subheading,
      kpi,
      kpiWrapper,
      yKpiName,
      yKpiValue,
      xKpiName,
      xKpiValue,
    } = getTooltipStyle({
      theme,
    });
    const _points = points ?? [point];

    let [xMin, xMax] = clusteredData ? getMinMax(clusteredData.map((data) => data.options.x)) : [x];
    if (chartDef.xAxis.id === TimeSignal.id) {
      xMin = getDateLabel({ value: xMin });
      xMax = getDateLabel({ value: xMax });
    }

    const assetIds = [...new Set(_points.map((v) => v.series.options.custom.assetId))].sort();

    return `<span style="${container}"><span style="${heading}"><span>${
      assetIds[0] ? assets[assetIds[0]]?.name : ''
    }</span><span><span style="${xKpiName}">${
      chartDef.xAxis.id !== TimeSignal.id ? chartDef.xAxis.name : ''
    }</span><span style="${xKpiValue}">${xMin}${xMax && xMax !== xMin ? ` - ${xMax}` : ''}${
      chartDef.xAxis.unit ? ' ' + chartDef.xAxis.unit : ''
    }</span></span></span>
    ${assetIds
      .reduce((acc, assetId, i) => {
        const title =
          i > 0 ? `<span style="${subheading}">${assets[assetId]?.name ?? assetId}</span>` : '';
        const content = `<span style="${kpiWrapper}">${_points
          .filter((v) => v.series.options.custom.assetId === assetId)
          .map((v) => {
            const [yMin, yMax] = v.clusteredData
              ? getMinMax(v.clusteredData.map((data) => data.options.y))
              : [v.y];
            // return getFormattedTooltip([yMin, yMax], v.series.name, v.series.unit);
            return `<span style="${kpi}"><span style="${yKpiName}">${
              v.series.name
            }</span><span style="${yKpiValue}">${yMin}${yMax && yMax !== yMin ? ` - ${yMax}` : ''}${
              v.series.userOptions.unit ? ' ' + v.series.userOptions.unit : ''
            }</span></span>`;
          })
          .join('')}</span>`;
        return [...acc, `<span style="${section}">${title}${content}</span>`];
      }, [])
      .join('')}</span>`;
  };

export const xAxisLabelFormatter =
  (xAxis) =>
  ({ value }) =>
    xAxis.id === TimeSignal.id ? dayjs(value).format(DateTimeFormats.DEFAULT_TIME_W_SECS) : value;

export const getSelectedMinMax = (selectedChartPoints, prop) => {
  if (!selectedChartPoints) return [];
  const values = selectedChartPoints.map((v) => v[prop]);
  return [Math.min(...values), Math.max(...values)];
};

export function hex2rgba(haxColor, opacity = 1) {
  if (opacity === 1 || haxColor?.length !== 7) return haxColor;

  const hex = haxColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
}
