import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

//@custom components
import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

//@custom styles
const Header = styled.div`
	display: flex;
	padding: 1.5em;
	justify-content: space-between;
	align-items:center
	height: 50px;
	width: auto;
	border-radius: 6px;
	background-color: ${(props) => props.theme.distributionListSidePanel.header};
	box-shadow: inset 0px 6px ${(props) => props.theme.distributionListSidePanel.headerShadow};
	margin: 10px;
`;

const Container = styled.div`
  button {
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const HeaderTitle = styled.h1`
  flex: 0.9;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 10px;
  display: inline-block;
  font-family: 'GE Inspira';
  color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
`;

const ViewTitle = styled.h4`
  color: #80c5d3;
  padding: 0.5em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 0 0 0 0.4em;
`;

const ErrorWrapper = styled.div`
  font-size: 1.5em;
  margin: 0.9em 2em;
  color: ${globalColors.red4};
`;

const ActionLabel = styled.span`
  font-weight: 600;
  color: ${globalColors.grey4};
`;

const ActionContainer = styled.div`
  flex: 0.1;
  padding: 2em;
  margin-top: 7px;
  min-width: 57px;
  border-left: 1px solid ${globalColors.grey4};
`;

const ItemContainer = styled.div`
  cursor: pointer;
  color: ${globalColors.grey4};
`;

//@functional component
const Create = ({ data, type, error, theme, handleDelete }) => {
  const { t } = useTranslation(['admin.configure']);

  return (
    <>
      <Header>
        {data ? (
          <Container>
            <ViewTitle>{t('DL_header.DL_title', 'DISTRIBUTION LIST')}</ViewTitle>
            <HeaderTitle>
              {type === 'Edit' ? 'Edit ' : ''}
              {t(data?.name)}
            </HeaderTitle>
          </Container>
        ) : (
          <Container>
            <Icon
              icon={Icons.PERSON}
              size={30}
              color={theme.distributionListSidePanel.headerIcon}
            />
            <HeaderTitle>
              {t('DL_header.create_DL_title', 'Create New Distribution List')}
            </HeaderTitle>
          </Container>
        )}
        {type === 'View' ? (
          <ActionContainer>
            <ItemContainer onClick={handleDelete}>
              <Icon
                icon={Icons.TRASH}
                size={12}
                color={theme.distributionListSidePanel.headerIcon}
              />
              <ActionLabel>DELETE</ActionLabel>
            </ItemContainer>
          </ActionContainer>
        ) : (
          ''
        )}
      </Header>
      {error ? <ErrorWrapper>{error}</ErrorWrapper> : ''}
    </>
  );
};

Create.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  error: PropTypes.any,
  theme: PropTypes.object,
  handleDelete: PropTypes.any,
};

export const CreateHeader = withTheme(Create);
