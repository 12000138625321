import { useQuery } from 'react-query';

import { Capability, PermissionScope } from '@ge/models/constants';
import { Config, useAuth } from '@ge/shared/data-hooks';

import { fetchCaseIdRootCause } from '../services/case-template';
/**
 * Get task sources.
 *
 * @param caseId caseId
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useResolutionNotes = (caseId) => {
  const { isAuthorized } = useAuth();
  const isAuthorizedToGetTasks = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  });
  return useQuery(['resolution-notes', { caseId }], () => fetchCaseIdRootCause(caseId), {
    enabled: isAuthorizedToGetTasks,
    ...Config.EXECUTE_ONCE,
  });
};
