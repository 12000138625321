import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Dialog } from '@ge/components/modal';
import { EventManagement } from '@ge/models/constants';
import { SidePanel } from '@ge/web-client/src/app/components/side-panel/side-panel';

import { CreateEM } from './create-em';
import { ViewEventManagementList } from './view-em-list';

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;
export const EMSidePanel = ({ data, close }) => {
  const [ispopup, setIsPopup] = useState(false);
  const [isFromChanged, setFormChanged] = useState(false);
  const { handleSubmit, ...methods } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // Todo : for edit the values has to be dynamic
    defaultValues: {
      name: '',
      desc: '',
      sites: [],
      sitesType: 'Include',
    },
  });
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const onExit = (e) => {
    close(e);
  };
  const onOkClick = (e) => {
    toggleCancelPopup();
    close(e);
  };

  const getCancelChanges = () => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={toggleCancelPopup}>{'Cancel'}</Button>
          <Button primary onClick={onOkClick}>
            {'OK'}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  };
  const toggleConfirmPopup = () => {
    if (!isFromChanged) {
      setIsPopup((state) => !state);
    }
  };
  const toggleCancelPopup = () => {
    // if (!isFromChanged) {
    setFormChanged((state) => !state);
    // }
  };

  const handleOnClose = () => {
    if (Object.keys(methods.formState?.dirtyFields).length) {
      toggleConfirmPopup();
      return () => {};
    }
    return onExit();
  };

  const renderDL = () => {
    const paramValues = params.get(EventManagement.MODE)?.split('__');
    if (!paramValues) {
      return;
    }
    switch (paramValues[0]) {
      case EventManagement.NEW:
        return (
          <SidePanel close={ispopup ? () => {} : close}>
            <CreateEM
              type={'Add'}
              handleSave={handleSubmit}
              close={onExit}
              ispopup={ispopup}
              toggleConfirmPopup={toggleConfirmPopup}
            />
          </SidePanel>
        );
      case EventManagement.VIEW:
        return (
          <SidePanel close={close}>
            <ViewEventManagementList
              details={data}
              close={onExit}
              ispopup={ispopup}
              toggleConfirmPopup={toggleConfirmPopup}
            />
          </SidePanel>
        );
      case EventManagement.EDIT:
        return (
          <SidePanel close={!ispopup ? handleOnClose : () => {}}>
            <Dialog
              isOpen={isFromChanged}
              onClose={() => setFormChanged(false)}
              onConfirm={onExit}
              header={'Confirmation'}
              footer={getCancelChanges()}
              contentWidth
              padContent={true}
            >
              <p>{'Are you sure, you want to discard the changes?'}</p>
            </Dialog>
            <CreateEM
              details={data}
              close={onExit}
              type={'Edit'}
              handleSave={handleSubmit}
              ispopup={ispopup}
              toggleConfirmPopup={toggleConfirmPopup}
              setFormChanged={setFormChanged}
            />
          </SidePanel>
        );
      default:
        return null;
    }
  };
  return (
    <CSSTransition
      in={!!params.get(EventManagement.MODE)}
      classNames="details-panel-transition"
      onExited={onExit}
      timeout={300}
      unmountOnExit
    >
      <FormProvider {...methods}>{renderDL()}</FormProvider>
    </CSSTransition>
  );
};

EMSidePanel.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};
