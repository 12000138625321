import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { TaskFlag } from '@ge/models/constants';

const TaskFlagIcon = {
  [TaskFlag.APPROVED]: { icon: Icons.THUMB_UP, viewbox: '0 0 10 10' },
  [TaskFlag.NOTAPPROVED]: { icon: Icons.OUTAGE, viewbox: '0 0 12 12' },
  [TaskFlag.BUNDLED]: { icon: Icons.BUNDLE, viewbox: '0 0 12 12' },
};

const StyledIcon = styled(Icon).attrs((props) => ({
  size: props.size ?? 10,
  icon: TaskFlagIcon[props.value]?.icon,
  viewbox: TaskFlagIcon[props.value]?.viewbox,
  color: props.theme.taskApprovalFlag[props.value],
}))``;

export const FlagIcon = ({ value, size, className }) => {
  if (!value || !TaskFlagIcon[value]) return null;
  return <StyledIcon value={value} size={size} className={className} />;
};

FlagIcon.propTypes = {
  value: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};
