import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { useEventMaps, useEventName } from '@ge/shared/data-hooks/use-handling-procedure';

import { DEFAULT_DROPDOWN_VALUE, DeleteButton } from './hp-common';
import { MailFieldsRow, StyledInput, StyledSelect } from './hp-styled-components';

const EventEditTable = (props) => {
  const {
    index,
    remove,
    theme,
    eventMapOptions,
    totalFields,
    hasDuplicateRecords,
    setHasDuplicateRecords,
    updatedEventProps,
  } = props;

  const { getEventCodes } = useEventMaps();
  const { getEventName, eventName = [], isLoading } = useEventName();
  const { t } = useTranslation(['admin.configure']);
  const [selectedEventMap, setSelectedEventMap] = useState(
    updatedEventProps.EventMap || DEFAULT_DROPDOWN_VALUE,
  );
  const eventCodeChange = useRef();
  const { headerIcon } = theme.distributionListSidePanel;

  const eventCodeOptions = useCallback(() => {
    const { events } = getEventCodes(selectedEventMap?.value);
    if (!events || !events.length) return [];
    return events
      .map((record) => ({
        label: Number(record.sourceEventId),
        value: record.eventId,
      }))
      .sort(function (a, b) {
        return a.label - b.label;
      });
  }, [getEventCodes, selectedEventMap?.value]);

  const onEventMapChange = (cb) => (e) => {
    setSelectedEventMap(e);
    cb(e);
    eventCodeChange.current(DEFAULT_DROPDOWN_VALUE);
  };

  const onEventCodeChange = (e) => {
    getEventName(e.value);
    if (hasDuplicateRecords) {
      setHasDuplicateRecords(null);
    }
    eventCodeChange.current(e);
  };

  return (
    <MailFieldsRow>
      <Controller
        name={`eventsProp[${index}].EventMap`}
        defaultValue={updatedEventProps?.EventMap}
        rules={{ required: true, validate: (val) => Boolean(val.value) }}
        render={({ onChange, value }, { invalid }) => (
          <StyledSelect
            error={invalid ? 'invalid' : ''}
            placeholder={t('Handling_procedures.select', 'Select')}
            options={eventMapOptions}
            onChange={onEventMapChange(onChange)}
            value={value.label ? value : undefined}
            label={index === 0 ? t('Handling_procedures.event_map', 'Event Map') : null}
            width={100}
            id="hp_select"
            title={value.label}
          />
        )}
      />
      <Controller
        name={`eventsProp[${index}].EventCode`}
        defaultValue={updatedEventProps?.EventCode}
        rules={{ required: true, validate: (val) => Boolean(val.value) }}
        render={({ onChange, value }, { invalid }) => {
          eventCodeChange.current = onChange;
          return (
            <StyledSelect
              error={invalid ? 'invalid' : ''}
              placeholder={t('Handling_procedures.select', 'Select')}
              disabled={!selectedEventMap?.value}
              label={index === 0 ? t('Handling_procedures.event_code', 'Event Code') : null}
              onChange={onEventCodeChange}
              options={selectedEventMap?.value ? eventCodeOptions() : []}
              value={value.label ? value : undefined}
              width={100}
              id="hp_select"
            />
          );
        }}
      />
      <Controller
        name={`eventsProp[${index}].name`}
        defaultValue={updatedEventProps.name}
        render={({ value }) => (
          <StyledInput
            label={index === 0 ? t('Handling_procedures.event_name', 'Event Name') : null}
            disabled
            type="text"
            value={eventName?.[0]?.name || value}
            placeholder={t('Handling_procedures.event_name', 'Event Name')}
            title={eventName?.[0]?.name || value}
          />
        )}
      />
      {totalFields > 1 ? (
        <DeleteButton disabled={isLoading} theme={headerIcon} onClick={remove} />
      ) : null}
    </MailFieldsRow>
  );
};

EventEditTable.propTypes = {
  remove: PropTypes.func,
  theme: PropTypes.object,
  field: PropTypes.object,
  index: PropTypes.number,
  eventMapOptions: PropTypes.array,
  control: PropTypes.object,
  totalFields: PropTypes.number,
  hasDuplicateRecords: PropTypes.any,
  setHasDuplicateRecords: PropTypes.func,
  updatedEventProps: PropTypes.any,
};
export default withTheme(EventEditTable);
