import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';

import SettingsHeader from './settings-header';

const EditContainer = styled.div`
  background: ${(props) => props.theme.sidebar.editBackground};
  margin-top: 10px;
  border-top: ${(props) => props.theme.sidebar.borderColor};
  box-shadow: ${(props) => props.theme.sidebar.boxShadow};
  .content {
    padding: 20px 25px;
  }
  .header {
    display: flex;
    padding: 4px 0;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
  }
  .back-btn {
    margin: 0 16px 12px;
  }
  .footer {
    height: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    background: ${(props) => props.theme.sidebar.editFooterBackground};
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
  }
`;

const AlertContainer = ({ onCancel, onSave, title, icon }) => {
  const { t } = useTranslation(['general']);

  return (
    <EditContainer>
      <div className="content">
        <SettingsHeader title={title} icon={icon} />
      </div>
      <div className="footer">
        <Button onClick={onCancel}>{t('cancel', 'Cancel')}</Button>
        <Button primary onClick={onSave}>
          {t('save', 'Save')}
        </Button>
      </div>
    </EditContainer>
  );
};

AlertContainer.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AlertContainer;
