import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityType, KpiCategoriesHeader, TimeAggr } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';
import { maxDayDiff } from '@ge/util';

import useAllSitesKpi from '../../data-hooks/use-all-sites-kpi';
import useKpiHeaderMenu from '../../data-hooks/use-kpi-header-menu';
import { DefaultRegionKpiVisibility } from '../../models/kpi-header-visibility-defaults';
import DashboardHeader from '../dashboard/dashboard-header';

export const SitesHeader = (props) => {
  const { dateRange } = props;
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const timeAggr = useMemo(() => {
    if (!dateRange.startDate || !dateRange.endDate) return null;
    return maxDayDiff(dateRange.startDate, dateRange.endDate, 92)
      ? TimeAggr.DAILY
      : TimeAggr.WEEKLY;
  }, [dateRange.startDate, dateRange.endDate]);

  const { data: kpiBarData, categories } = useAllSitesKpi({
    dateRange,
    timeAggr,
  });

  const { handleKpiChanges, visibleKpis } = useKpiHeaderMenu({
    scope: AppScopes.ANALYZE_REGION,
    defaultVisibility: DefaultRegionKpiVisibility,
    kpiCategories: categories,
  });

  return (
    <DashboardHeader
      title={t('sites', 'Sites')}
      type={EntityType.SITE}
      showParent={false}
      data={kpiBarData}
      onMenuClose={handleKpiChanges}
      visibleKpiPrefs={visibleKpis}
      kpiCategories={KpiCategoriesHeader}
    />
  );
};

SitesHeader.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

SitesHeader.defaultProps = {
  dateRange: {
    startDate: '',
    endDate: '',
  },
};
