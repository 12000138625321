import { action, computed, persist, thunk } from 'easy-peasy';
import memoize from 'memoizerific';

import { SortDirection, SortValueType, InverterType, SiteType } from '@ge/models/constants';
import { byId } from '@ge/util/array-utils';
import merge from '@ge/util/deep-merge';
import { sorter } from '@ge/util/metric-sorter';

import {
  fetchServiceGroups,
  fetchSiteAssetsWithMetrics,
  fetchSiteById,
  fetchSites,
  fetchSitesWithMetrics,
  getServiceGroupsByRocStations,
} from '../../shared/services/site';
import { filterByView, getViewEntity } from '../../shared/util/view-utils';

import indexedDb from './storage/indexedDb';

// Define initial state

const defaultSiteState = persist(
  {
    serviceGroups: {},
    serviceGroupsWithFullSites: [],
    serviceGroupsWithFullSitesUpdated: 0,
    serviceGroupsLastUpdated: 0,
    sites: {},
    stationList: [],
    sitesLastUpdated: 0,
    sortedSites: [],
  },
  {
    version: 1,
    storage: indexedDb,
    mergeStrategy: 'overwrite',
  },
);

// Actions
const siteActions = {
  /**
   * Reset state to defaults
   */
  resetSites: action((state) => {
    state = Object.assign(state, defaultSiteState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.sites = defaultSiteState.sites;
  }),

  /**
   * Set the sites state array
   */
  setSites: action((state, payload) => {
    state.sites = payload;
  }),

  setStationList: action((state, payload) => {
    state.stationList = payload;
  }),

  /**
   * Retrieve sites from API and update state.
   */
  fetchSites: thunk(async (actions, payload, { fail }) => {
    try {
      const { sites } = await fetchSites(payload);
      actions.updateSites(sites);
      actions.setSitesLastUpdated();
    } catch (err) {
      fail(err);
    }
  }),

  fetchSitesWithMetrics: thunk(async (actions, payload, { fail }) => {
    try {
      const { sortMetric, sortDirection } = payload;
      const { sites } = await fetchSitesWithMetrics(sortMetric, sortDirection);

      actions.updateSites(sites);
    } catch (err) {
      fail(err);
    }
  }),

  // TODO are we really ripping all of this out and just using the common token
  fetchSiteById: thunk(async (actions, { id }, { fail }) => {
    try {
      const { site } = await fetchSiteById({ id });
      actions.updateSites([site]);
    } catch (err) {
      fail(err);
    }
  }),

  // TODO (astone): Does this do anything? Doesn't update state.
  fetchSiteAssetsWithMetrics: thunk(async (_, payload, { fail }) => {
    try {
      return await fetchSiteAssetsWithMetrics(payload);
    } catch (err) {
      fail(err);
    }
  }),

  fetchServiceGroups: thunk(async (actions, payload, { fail }) => {
    try {
      const serviceGroups = await fetchServiceGroups(payload);
      actions.updateServiceGroups(serviceGroups);
      actions.setServiceGroupsLastUpdated();
    } catch (err) {
      fail(err);
    }
  }),

  getServiceGroupsByRocStations: thunk(async (actions, payload, { fail }) => {
    try {
      const serviceGroups = await getServiceGroupsByRocStations();
      actions.setStationList(serviceGroups);
    } catch (err) {
      fail(err);
    }
  }),

  setSortedSites: action((state, payload) => {
    state.sortedSites = payload;
  }),
  setFullServiceGroupsWithSites: action((state, payload) => {
    state.serviceGroupsWithFullSites = payload;
  }),

  setServiceGroupsWithFullSitesUpdated: action((state) => {
    state.serviceGroupsWithFullSitesUpdated = new Date().getTime();
  }),

  setIsServiceGroupAndSitesLoading: action((state, payload) => {
    state.isServiceGroupAndSitesLoading = payload;
  }),

  getServiceGroupsWithFullSites: thunk(async (actions, payload, { getStoreState }) => {
    const serviceGroups = Object.values(getStoreState().sites.serviceGroups);
    const sites = Object.values(getStoreState().sites.sites);

    actions.setIsServiceGroupAndSitesLoading(true);
    //sites sort
    sites.sort((a, b) => a.name.localeCompare(b.name));
    const newServiceGroups = combineSitesWithServiceGroups(serviceGroups, sites);
    actions.setSortedSites(sites);
    actions.setFullServiceGroupsWithSites(newServiceGroups);
    actions.setIsServiceGroupAndSitesLoading(false);
  }),

  updateSites: action((state, payload) => {
    payload.forEach((site) => {
      // get the type of site ex. Solar/Storage/Wind
      // and attach it to the site object
      site.type = [];
      if (site?.turbineTypes?.length) {
        site.type.push(SiteType.WIND);
      }

      site?.inverters?.types?.forEach((item) => {
        site.type.push(item.type);
      });

      const existingSite = state.sites[site.id];
      state.sites[site.id] = !existingSite ? site : merge(existingSite, site);
    });
  }),

  setSitesLastUpdated: action((state) => {
    state.sitesLastUpdated = new Date().getTime();
  }),

  updateServiceGroups: action((state, payload) => {
    payload?.forEach((serviceGroup) => {
      const existing = state.serviceGroups[serviceGroup.id];

      state.serviceGroups[serviceGroup.id] = existing
        ? merge(existing, serviceGroup)
        : serviceGroup;
    });
  }),

  setServiceGroupsLastUpdated: action((state) => {
    state.serviceGroupsLastUpdated = new Date().getTime();
  }),
};

const getViewSites = (sites, view) => {
  const { currentView } = view;
  const siteArray = Object.values(sites);

  // Apply view filter if one is provided.
  if (currentView) {
    return filterByView(siteArray, currentView);
  }
  return siteArray;
};

const getSiteTypesToShow = (sites) => {
  const siteArray = Object.values(sites);
  let siteTypeMap = {
    wind: false,
    storage: false,
    solar: false,
    hybrid: false,
  };
  siteArray.forEach((site) => {
    site?.type?.forEach((type) => (siteTypeMap[type.toLowerCase()] = true));
  });
  return siteTypeMap;
};

// Computed values
const siteComputed = {
  /**
   * Return the site object by its ID.
   */
  getSiteById: computed((state) => (siteId) => state.sites[siteId]),

  getFeatureSites: computed(
    [(state) => state.sites, (_, storeState) => storeState.view],
    (sitesById, view = {}) =>
      memoize(10)(() => {
        return getViewEntity(sitesById, view).sort(
          sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC),
        );
      }),
  ),

  getCustomerMap: computed([(state) => state.sites], (sites) =>
    memoize(2)(() =>
      Object.values(sites).reduce((_customerMap, site) => {
        const { customer } = site;

        // site doesn't have a customer so skip
        if (!customer?.id) {
          return _customerMap;
        }

        const _customer = _customerMap.get(customer.id);

        if (_customer) {
          _customer.sites.push(site);
        } else {
          _customerMap.set(customer.id, {
            ...customer,
            sites: [site],
          });
        }

        return _customerMap;
      }, new Map()),
    ),
  ),

  /**
   * Return a map of customer to associated sites. If no id is
   * provided, return all sites grouped by customer. If an id
   * is provided, return only the sites mapped to that customer.
   */
  getSitesByCustomer: computed((state) => (customerId) => {
    let filteredSites = Object.values(state.sites);
    if (typeof customerId !== 'undefined') {
      filteredSites = filteredSites.filter((site) => customerId === site.customer?.id);
    }

    const customerMap = new Map();

    // Return an empty map if any of the sites don't have a customer.
    const siteWithNoCustomer = filteredSites.find((site) => !site.customer);
    if (siteWithNoCustomer) {
      return customerMap;
    }

    // If all known sites have associated customers, map by customer ID.
    return filteredSites.reduce((map, site) => {
      map.set(site.customer.id, [site, ...(map.get(site.customer?.id) || [])]);
      return map;
    }, customerMap);
  }),

  /**
   * Return a map of region to associated sites.
   */
  sitesByRegion: computed([(state) => state.sites], (sites) => {
    return Object.values(sites).reduce((map, site) => {
      if (site?.region?.id) {
        map.set(site.region?.id, [site, ...(map.get(site.region?.id) || [])]);
      }
      return map;
    }, new Map());
  }),

  sortedServiceGroups: computed([(state) => state.serviceGroups], (serviceGroups) =>
    Object.values(serviceGroups).sort((a, b) => a.name?.localeCompare(b.name)),
  ),

  getServiceGroups: computed([(state) => state.serviceGroups], (serviceGroups) =>
    Object.values(serviceGroups ?? {}),
  ),

  getServiceGroupSites: computed(
    (state) => (serviceGroup) =>
      serviceGroup.sites.reduce((serviceGroupSites, serviceGroupSite) => {
        if (state.sites[serviceGroupSite.id]) {
          serviceGroupSites.push(state.sites[serviceGroupSite.id]);
        }
        return serviceGroupSites;
      }, []),
  ),

  getViewServiceGroupsSites: computed(
    [
      (state) => state.serviceGroups,
      (state) => state.sites,
      (_, storeState) => storeState.assets.assetsBySiteId,
      (_, storeState) => storeState.view,
    ],

    (serviceGroups, sites, assetsBySiteId, view = {}) => {
      const siteArray = getViewSites(sites, view);
      const filteredSitesById = byId(siteArray);
      return Object.values(serviceGroups)
        .map((serviceGroup) => {
          return {
            ...serviceGroup,
            sites: serviceGroup?.sites
              .map((site) => filteredSitesById[site.id])
              .filter(Boolean)
              .map((site) => ({ ...site, assetCount: assetsBySiteId[site.id]?.length || 0 }))
              .sort(sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC)),
            assetCount: serviceGroup.sites.reduce(
              (acc, site) => acc + (assetsBySiteId[site.id]?.length || 0),
              0,
            ),
          };
        })
        .filter((serviceGroup) => serviceGroup.sites.length)
        .sort(sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC));
    },
  ),

  getFilteredSites: computed(
    [(state) => state, (_, storeState) => storeState.view],
    (state, view = {}) =>
      memoize(10)((filterText) => {
        let siteArray = getViewEntity(state.sites, view);
        if (!filterText || !filterText.length) {
          return siteArray.sort(sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC));
        }
        return siteArray
          .filter((site) => site.name?.toLowerCase().includes(filterText.toLowerCase()))
          .sort(sorter('name', SortDirection.ASC, SortValueType.ALPHANUMERIC));
      }),
  ),

  getSortedSites: computed(
    [(state) => state, (_, storeState) => storeState.view],
    (state, view = {}) =>
      memoize(10)((sortMetric, sortDirection) => {
        const siteArray = getViewSites(state.sites, view);
        let sortedSites = siteArray.sort(sorter(sortMetric, sortDirection));
        return sortedSites;
      }),
  ),

  sitesPlatforms: computed(
    [(state) => state.sites, (_, storeState) => storeState.assets.assetsBySiteId],
    (sites, assetsBySiteId) => {
      return Object.keys(sites).reduce((acc, siteId) => {
        acc[siteId] = [...new Set(assetsBySiteId[siteId]?.map((asset) => asset.platform))].filter(
          Boolean,
        );
        return acc;
      }, {});
    },
  ),

  // Will not sort and gives only the sites selected in the view.
  sitesForView: computed(
    [(state) => state, (_, storeState) => storeState.view],
    (state, view = {}) => {
      return getViewSites(state.sites, view);
    },
  ),

  // Wil return what all site table types are available for display
  siteTypesToShow: computed([(state) => state, (_, storeState) => storeState.view], (state) => {
    return getSiteTypesToShow(state.sites);
  }),

  storageSites: computed([(state) => state, (_, storeState) => storeState.view], (state) => {
    const sitesArray = Object.values(state.sites);
    let storageSites = {};
    sitesArray?.forEach((site) => {
      if (site?.inverters?.types?.find((ent) => ent.type === InverterType.STORAGE)) {
        storageSites[site?.id] = site;
      }
    });
    return storageSites;
  }),
};

const combineSitesWithServiceGroups = (serviceGroups, sites) => {
  for (let i = 0; i < serviceGroups.length; i++) {
    let serviceGroupSites = serviceGroups[i].sites;
    for (let j = 0; j < serviceGroupSites.length; j++) {
      let serviceGroupSitesItemId = serviceGroupSites[j].id;
      for (let l = 0; l < sites.length; l++) {
        if (serviceGroupSitesItemId == sites[l].id) {
          serviceGroupSites[j] = sites[l];
          break;
        }
      }
    }
  }
  serviceGroups.sort((a, b) => a.id.localeCompare(b.id));
  return serviceGroups;
};

// Compile the view store object for export
const siteModel = {
  ...defaultSiteState,
  ...siteActions,
  ...siteComputed,
};

export default siteModel;
