import { useStoreState } from 'easy-peasy';
import { useMemo, useCallback } from 'react';
import { useQueries } from 'react-query';

import { SortDirection } from '@ge/components/table/models/sort-direction';
import { QueryKey, KpiCategoryDefs } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { useGlobalFilters } from '../hooks';
import { fetchSiteKpiData } from '../services';

import { KPI, splitKpisCategoryByApi } from './util';

/**
 * Use region IEC data.
 *
 * @param siteIds the site ids
 * @param def
 * @param categories the kpi categories
 * @param timeAggr the time aggregate
 * @param startDate the start date
 * @param endDate the end date
 * @param sortMetric the sort KPI metric
 * @param sortDirection the sort direction
 * @param entityAggr aggregate both entity and date
 * @param seriesEntityAggr aggregate entity (all metrics kpi only)
 * @param dateEntityAggr aggregate date (all metrics kpi only)
 */
const useGetSitesKpiData = ({
  siteIds: _siteIds,
  def,
  categories,
  startDate,
  endDate,
  timeAggr,
  sortMetric,
  sortDirection = SortDirection.ASC,
  entityType,
  entityAggr,
  seriesEntityAggr,
  dateEntityAggr,
  hydrateBy,
  enabled = true,
  queryKey = QueryKey.SITES_KPI_DATA,
}) => {
  const { visibleKpis } = useStoreState((state) => state.analyze);

  const endpointCategories = useMemo(() => {
    const { allKpiMetricsEndpointCategories, otherKpiEndpointsCategories, windMetricsCategories } =
      splitKpisCategoryByApi(categories);
    if (entityType === 'Fleet') {
      return [
        allKpiMetricsEndpointCategories,
        windMetricsCategories,
        ...otherKpiEndpointsCategories.map((category) => [category]),
      ].filter((categories) => categories?.length > 0);
    } else {
      return [
        allKpiMetricsEndpointCategories,
        otherKpiEndpointsCategories,
        windMetricsCategories,
      ].filter((categories) => categories?.length > 0);
    }
  }, [categories, entityType]);

  const _filters = useGlobalFilters();

  const getViewSiteIds = useStoreState((state) => state.view.getViewSiteIds);

  const viewSiteIds = getViewSiteIds();

  const filters = useMemo(() => {
    const { siteIds: currentViewSiteIds, ...restFilters } = _filters;
    const selectedSiteIds = _siteIds?.length ? _siteIds : currentViewSiteIds;
    const siteIds = selectedSiteIds?.filter((id) => id && viewSiteIds.includes(id)) ?? [];
    return { ...restFilters, siteIds };
  }, [_siteIds, _filters, viewSiteIds]);

  const isSelected = useCallback(
    (categories) => {
      if (
        entityType === 'Fleet' &&
        (categories.includes(KpiCategoryDefs.REPORTING_ASSETS_RATIO) ||
          categories.includes(KpiCategoryDefs.REPORTING_SITES_RATIO))
      ) {
        return categories.every((category) => visibleKpis.includes(category));
      }
      return true;
    },
    [visibleKpis, entityType],
  );

  const { siteIds } = filters;

  const queries = useQueries(
    endpointCategories.map((categories) => ({
      queryKey: [
        queryKey,
        {
          categories,
          filters,
          startDate,
          endDate,
          timeAggr,
          entityType,
          entityAggr,
          seriesEntityAggr,
          dateEntityAggr,
        },
      ],
      queryFn: () =>
        fetchSiteKpiData({
          categories,
          filters,
          startDate,
          endDate,
          timeAggr,
          entityType,
          entityAggr,
          seriesEntityAggr,
          dateEntityAggr,
        }),
      enabled: Boolean(
        siteIds?.length &&
          startDate != undefined &&
          endDate != undefined &&
          enabled &&
          timeAggr &&
          isSelected(categories),
      ),
      ...Config.EXECUTE_ONCE,
      retry: false,
    })),
  );

  const isLoading = queries.some((result) => result.isLoading);

  const { data, error } = useMemo(
    () => {
      const initialData = isLoading
        ? endpointCategories.flat().reduce((acc, val) => ({ ...acc, [val]: { isLoading } }), {})
        : {};
      const errors = queries
        .filter((o) => o.isError && def.indexOf(o.error?.config?.params?.categories) !== -1)
        .map((o) => o.error);
      const data = queries
        .filter((o) => !o.isLoading && !o.isFetching)
        .reduce((data, result) => ({ ...data, ...result?.data?.data }), initialData);

      return {
        data,
        error: errors[0],
      };
    },
    [queryKey, def, endDate, _filters, isLoading, startDate, timeAggr], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const dataMapped = useMemo(() => {
    const siteData = data || {};
    const hydrated = KPI.hydrate(siteData, hydrateBy, timeAggr);
    const sortedData = KPI.getSorted(hydrated, sortMetric, sortDirection);
    return KPI.getSortedTimeSeriesData(sortedData);
  }, [data, hydrateBy, timeAggr, sortMetric, sortDirection]);

  const kpiQueryState = useMemo(
    () =>
      endpointCategories.reduce(
        (acc, categories, i) =>
          categories.reduce(
            (result, category) => ({
              ...result,
              [category]: queries[i] ?? {},
            }),
            acc,
          ),
        {},
      ),
    [isLoading, endpointCategories], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    data: dataMapped,
    error,
    isLoading,
    kpiQueryState,
  };
};
export default useGetSitesKpiData;
