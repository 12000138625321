import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery, useQueries } from 'react-query';

import { intersectBy } from '@ge/util/array-utils';

import { fetchAssetComponentHierarchy, fetchAssetsComponentHierarchies } from '../services/asset';

import { Config } from './config';

/**
 * Get asset component hierarchy.
 *
 * @param assetIds
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useComponentHierarchy = (assetIds) => {
  // TODO - once added to base asset
  // const hierarchyId = assets[assetId]?.components?.hierarchyId;

  const getAssetById = useStoreState((state) => state.assets.getAssetById);

  const queries = useQueries(
    assetIds.map((assetId) => {
      const { aliases } = getAssetById(assetId) ?? {};
      const isEBopTurbine = aliases?.some((alias) => alias?.key === 'racesBopId');
      return {
        queryKey: ['component-hierarchy', assetId],
        queryFn: () => fetchAssetComponentHierarchy(assetId),
        enabled: Boolean(assetId) && !isEBopTurbine,
        ...Config.EXECUTE_ONCE,
      };
    }),
  );

  return useMemo(() => {
    const isLoading = queries.some((query) => query.isLoading);
    if (isLoading || queries.length === 0) {
      return { isLoading };
    }

    const errorRes = queries.find(({ isError }) => isError);

    if (errorRes) {
      return errorRes;
    }

    const data = queries.filter((res) => res.data).map((res) => res.data);
    return { data: intersectBy(['name'], data) };
  }, [queries]);
};

/**
 * Get asset component hierarchies.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useComponentHierarchies = () => {
  return useQuery(['component-hierarchies'], fetchAssetsComponentHierarchies, {
    ...Config.EXECUTE_ONCE,
  });
};
