import { PropTypes } from 'prop-types';
import React, { useMemo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select } from '@ge/components/select';

import { maLegendValues } from '../../models/manual-adjustment-legends';

import {
  StyledSelect,
  EventCategory,
  EventCategoryName,
  EventCategoryColor,
} from './event-dialog-style';

export const MalEventCategorySelect = ({ id, name, data, assetEventsKind }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const [selectEventCategory, setSelectEventCategory] = useState();

  const { control, errors, watch } = useFormContext();
  const currentValue = watch(name);

  useEffect(() => {
    const { eventCategory } = data?.find((data) => data.id === currentValue) ?? {};
    setSelectEventCategory(eventCategory);
  }, [currentValue, data]);

  const categoryOptions = useMemo(
    () =>
      maLegendValues[assetEventsKind]?.map((e) => ({
        label: t(`legend.${e.a11yKey}.dialog_title`),
        value: e.category,
      })),
    [assetEventsKind, t],
  );

  const eventOptions = useMemo(
    () =>
      typeof data === 'object'
        ? data?.map((e) => ({
            label: e.description,
            value: e.id,
          }))
        : [],
    [data],
  );

  if (!ready) return null;

  return (
    <>
      <EventCategory>
        <EventCategoryColor
          backgroundColor={
            maLegendValues[assetEventsKind]?.find(
              ({ category }) => category === selectEventCategory,
            )?.color
          }
          size={'10px'}
        />
        <EventCategoryName>
          {categoryOptions?.find(({ value }) => value === selectEventCategory)?.label ??
            t('event_details_dialog.eventcategory_placeholder', 'Event Category')}
        </EventCategoryName>
      </EventCategory>
      <StyledSelect>
        <Controller
          control={control}
          name={name}
          id={id}
          rules={{
            required: true,
          }}
          defaultValue={0}
          render={({ value, onChange }) => (
            <Select
              onChange={(e) => onChange(e.value)}
              placeholder={t(
                'event_details_dialog.dropdown_placeholder',
                'Please select an option',
              )}
              name={name}
              value={eventOptions.find((option) => option.value === value)}
              options={eventOptions}
              error={errors[name]?.type}
            />
          )}
        />
      </StyledSelect>
    </>
  );
};

MalEventCategorySelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  assetEventsKind: PropTypes.string,
};

MalEventCategorySelect.defaultProps = {
  data: [],
};
