import { useStoreState } from 'easy-peasy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { StyledFilter } from '@ge/components/assets-filter';
import { globalColors } from '@ge/tokens';

const WorkerAssetsComponent = withTheme(({ theme }) => {
  const { textColor } = theme.themeSelector.panel;
  const { t } = useTranslation(['admin.people']);
  const { viewByServiceGroupsOrSites } = useStoreState((state) => state.tabflow.personAssets);
  return (
    <div>
      <div className="worker-assets" style={{ color: textColor }}>
        <div>{t('assign_assets.header', 'ASSIGN ASSETS TO THIS WORKER').toUpperCase()}</div>
        <div className="worker-assets-subtitle" style={{ color: globalColors.grey4 }}>
          {t(
            'assign_assets.text_a',
            'This worker can be scheduled for work on the service groups selected below',
          )}
        </div>
      </div>

      <div className="person-assets-sites-filter-content" style={{ color: textColor }}>
        <div className="person-assets-align-self">
          {t('assign_assets.text_b', 'AVAILABLE SERVICE GROUPS')}
        </div>
        <div className="person-assets-sites-filter-right-item">
          <StyledFilter searchByName={viewByServiceGroupsOrSites} />
        </div>
      </div>
    </div>
  );
});

export default WorkerAssetsComponent;
