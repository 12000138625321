import classNames from 'classnames';
// import { getContrast } from 'polished';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icons, Icon } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Dialog } from '@ge/components/modal';
import { Radio } from '@ge/components/radio/radio';
import { Tab, Tabs } from '@ge/components/tabs';
import { Text } from '@ge/components/typography';
import { AssetContext } from '@ge/feat-analyze/context/assetContext';
import { AssetState, DataExplorerFilterTypes } from '@ge/models/constants';
import { typography } from '@ge/tokens/typography';

import SignalButton from './shared/data-explorer-signal-btn';
import DataExplorerSignalListItems from './shared/data-explorer-signal-list-items';

const SignalsContainer = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  flex: 1;
  height: 400px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.dataExplorer.dialog.backgroundColor};
  overflow: hidden;
`;

const StyledTabs = styled(Tabs)`
  background-color: ${(props) => props.theme.dataExplorer.dialog.backgroundColor};
  width: 100%;
`;

const formFields = {
  MIN: 'min',
  MAX: 'max',
  RANGE: 'range',
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};

// const SignalFilterForm = styled.form``;

const FooterButtons = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  button {
    text-transform: capitalize;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  width: 940px;
  height: 400px;
  background: ${(props) => props.theme.dataExplorer.dialog.backgroundColor};
  > div {
    border-right: solid 1px ${(props) => props.theme.dataExplorer.dialog.sectionBorderColor};
  }
  hr {
    border: solid 1px #1c252d;
    margin: 14px 0;
  }
`;

const FilterTypeContainer = styled.div`
  width: 280px;
  button {
    padding: 0 10px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  > div:first-child {
    margin-right: 10px;
  }
`;

const ArrowIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ARROW_UP,
  rotate: 90,
  color: props.theme.dataExplorer.dialog.arrowColor,
  viewbox: '0 0 11 18',
}))``;

const FilterWrapper = styled.div`
  flex: 1;
  background: ${(props) => props.theme.dataExplorer.dialog.activeSectionBackgroundColor};
  padding: 20px;
  height: calc(100% - 40px);
`;

export const DataExplorerFilterDialog = ({ onClose, onConfirm }) => {
  const { t } = useTranslation(['analyze.data-explorer', 'general'], { useSuspense: false });

  const {
    assetState: { chartFilter, setChartFilter, signalData },
  } = useContext(AssetContext);

  const [filterText, setFilterText] = useState('');

  // Configure form hook and define logic for submission.
  const { handleSubmit, register, errors, getValues, trigger } = useForm({
    mode: 'onBlur',
    defaultValues: {
      min: chartFilter?.filter.min,
      max: chartFilter?.filter.max,
    },
  });

  // radio state handler
  const [range, setRange] = useState(
    chartFilter?.filter.include === false ? formFields.EXCLUDE : formFields.INCLUDE,
  );

  // Set the Panel to the correct filter type: SIGNALS / STATE
  const [filterType, setFilterType] = useState(
    chartFilter?.type === DataExplorerFilterTypes.STATE
      ? DataExplorerFilterTypes.STATE
      : DataExplorerFilterTypes.SIGNALS,
  );

  const [stateFilters /*, setStateFilters */] = useState(
    chartFilter?.type === DataExplorerFilterTypes.STATE
      ? chartFilter.filter
      : Object.values(AssetState),
  );

  const [selectedSignal, setSelectedSignal] = useState(
    chartFilter ? chartFilter.filter : signalData && signalData[0],
  );

  const handleSelectSignal = useCallback(
    ({ signal, timeAggr }) => {
      // Todo: update this to some getter for a single signal - will need to include data for range types etc for filtering
      const item = signalData.find((v) => v.id === signal.id);
      if (item) {
        setSelectedSignal({ ...item, timeAggr });
      } else {
        setSelectedSignal({});
      }
    },
    [signalData],
  );

  const onSubmit = useCallback(
    (values) => {
      let signalValues = selectedSignal;
      signalValues.min = values.min || null;
      signalValues.max = values.max || null;
      signalValues.include = values.range === formFields.INCLUDE;
      const filter = filterType === DataExplorerFilterTypes.STATE ? stateFilters : signalValues;
      setChartFilter({ type: filterType, filter: filter });
      onConfirm();
    },
    [onConfirm, stateFilters, filterType, setChartFilter, selectedSignal],
  );

  // Build the footer to pass into the Dialog component.
  const getFooter = useMemo(() => {
    return (
      <FooterButtons>
        <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
        <Button primary onClick={handleSubmit(onSubmit)}>
          {t('dialogs.apply_filter', 'Apply Filter')}
        </Button>
      </FooterButtons>
    );
  }, [onClose, t, onSubmit, handleSubmit]);

  const filteredSignals = useMemo(() => {
    if (filterText) {
      const val = String(filterText).toLowerCase();
      return signalData.filter((signal) => String(signal.name).toLowerCase().includes(val));
    }
    return signalData;
  }, [filterText, signalData]);

  const tabs = useMemo(
    () => [
      {
        label: t('analyze.data-explorer.scada', 'Scada'),
        signals:
          filteredSignals?.filter((signal) => signal.id === 'TIME' || signal.categoryId === 0) ??
          [],
      },
      {
        label: t('analyze.data-explorer.cms', 'CMS'),
        signals:
          filteredSignals?.filter((signal) => signal.id === 'TIME' || signal.categoryId === 2) ??
          [],
      },
    ],
    [filteredSignals, t],
  );

  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      const activeElPos = scrollRef.current.el?.querySelector('.active');
      if (activeElPos) {
        scrollEl.scrollTop = activeElPos.getBoundingClientRect().top - 300;
      }
    }
  }, [scrollRef]);

  const width = 400;

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onConfirm}
      header={t('dialogs.filter_by', 'Filter By')}
      icon={Icons.TASK}
      footer={getFooter}
      contentWidth
      padContent={false}
    >
      <FiltersContainer>
        <FilterTypeContainer>
          <SignalButton
            className={classNames({ active: true }, 'grid-1')}
            onClick={() => setFilterType(DataExplorerFilterTypes.SIGNALS)}
          >
            {t('dialogs.all_signals', 'All Signals')} <ArrowIcon />
          </SignalButton>
        </FilterTypeContainer>
        <SignalsContainer width={width}>
          <StyledTabs>
            {tabs.map(({ label, signals }) => (
              <Tab key={label} label={label}>
                <DataExplorerSignalListItems
                  signals={signals}
                  selected={[selectedSignal]}
                  onSelectSignal={handleSelectSignal}
                  width={width}
                  filterText={filterText}
                  onChangeFilter={setFilterText}
                />
              </Tab>
            ))}
          </StyledTabs>
        </SignalsContainer>
        <FilterWrapper>
          <Text type={typography.textTypes.kpi} level={2}>
            {selectedSignal?.name}
            <hr />
          </Text>
          <InputWrapper>
            <Input
              id={formFields.MIN}
              label={t(formFields.MIN, 'Min')}
              type="number"
              name={formFields.MIN}
              ref={register({
                required: true,
                validate: (value) => {
                  const max = getValues(formFields.MAX);
                  if (!parseInt(value) && !parseInt(max)) return false;
                },
              })}
              onKeyUp={() => {
                trigger([formFields.MAX, formFields.MIN]);
              }}
              error={errors.max && 'min and max required'}
            />
            <Input
              id={formFields.MAX}
              label={t(formFields.MAX, 'Max')}
              type="number"
              name={formFields.MAX}
              ref={register({
                required: true,
                validate: (value) => {
                  const min = getValues(formFields.MIN);
                  if (!parseInt(value) && !parseInt(min)) return false;
                  if (parseInt(value) <= parseInt(min)) return false;
                },
              })}
              onKeyUp={() => {
                trigger([formFields.MAX, formFields.MIN]);
              }}
              error={errors.max && 'min and max required'}
            />
          </InputWrapper>
          <Radio
            id={formFields.INCLUDE}
            name={formFields.RANGE}
            label="Include selected Range"
            value={formFields.INCLUDE}
            ref={register()}
            checked={range === formFields.INCLUDE}
            onChange={(e) => setRange(e.target.value)}
          />
          <Radio
            id={formFields.EXCLUDE}
            name={formFields.RANGE}
            label="Exclude selected Range"
            value={formFields.EXCLUDE}
            checked={range === formFields.EXCLUDE}
            ref={register({
              required: true,
            })}
            onChange={(e) => setRange(e.target.value)}
          />
          <span className="danger-txt">
            {errors.max && !errors.min && 'Max value must be greater than min'}
            {errors.max && errors.min && 'Max and min value required'}
          </span>
        </FilterWrapper>
      </FiltersContainer>
    </Dialog>
  );
};

DataExplorerFilterDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  filter: PropTypes.instanceOf(Object),
};

DataExplorerFilterDialog.defaultProps = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  filter: null,
};
