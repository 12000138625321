import { useMutation } from 'react-query';

import * as reportsService from '../services';

export const useSaveTemplate = ({ onSuccess, onError }) => {
  const { mutate } = useMutation(
    ({ templateData = {} }) => {
      const { id, baseTemplate } = templateData;

      if (baseTemplate) {
        return reportsService.createTemplate({ ...templateData, baseTemplateId: id });
      }

      return reportsService.updateTemplate({ ...templateData, templateId: id });
    },
    {
      onSuccess: (data) => {
        if (data && typeof data === 'string') {
          const templateIdPattern = /\b([a-fA-F0-9]{24,})\b/;
          const match = data.match(templateIdPattern);
          match && onSuccess(match[1]);
        }
      },
      onError: (error) => onError(error),
    },
  );

  return { mutate };
};

export const useSaveTemplateConfig = ({ onSuccess, onError }) => {
  const { mutate } = useMutation(
    ({ templateId, hiddenWidgetIds, widgetConfigurations }) => {
      const patchOps = [];
      if (hiddenWidgetIds) {
        patchOps.push({
          op: 'add',
          path: '/hiddenWidgetIds',
          value: hiddenWidgetIds,
        });
      }

      if (widgetConfigurations) {
        patchOps.push({
          op: 'add',
          path: '/widgets',
          value: widgetConfigurations,
        });
      }

      if (patchOps.length > 0) {
        return reportsService.updateTemplateConfig({ templateId, patchOps });
      }
    },
    {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    },
  );

  return { mutate };
};
