import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import {
  AssetCommandError,
  AssetCommandStatus,
  CommandBlockingReasonKeys,
  AssetState,
  CustomEventRefreshCommand,
  commands,
} from '@ge/models/constants';
import { toLocaleKey } from '@ge/util/string-utils';

import { sendCommand, abortCommands } from '../services/commands';

import { useAssetCommandBlockStatus } from './use-asset-command-block-status';
import { useAssetCommandList } from './use-asset-command-list';
import { useAssetCommandStatus } from './use-asset-command-status';

const INPROGRESS_LIMIT = commands.MAX_INPROGRESS_COMMANDS;
const sendCommandTransformer = ({ assets, command }) => {
  const trimmedAssets = assets.map((element, index) => {
    return {
      cmdNo: index + 1,
      siteId: element?.site?.id,
      turbineId: element.id,
    };
  });

  return { assets: trimmedAssets, command: command?.canId };
};

const getReasonString = (reason, t) => {
  const NO_COMM = 'noComm';
  let reasonString = '';
  if (!Array.isArray(reason)) return reasonString;
  for (const [index, elem] of reason.entries()) {
    const reasonKey = Object.keys(elem)[0];
    const val = elem[reasonKey];
    switch (reasonKey) {
      case CommandBlockingReasonKeys.ALERT:
        if (val) {
          reasonString += t('command_blocking_reason.alerts');
        }
        break;
      case CommandBlockingReasonKeys.COM_STATE:
        if (val === NO_COMM) {
          reasonString += t('command_blocking_reason.no_comm');
        }
        break;
      case CommandBlockingReasonKeys.STATE:
        if (val === AssetState.REPAIR) {
          reasonString += t('command_blocking_reason.repair');
        } else if (val === AssetState.MAINTENANCE) {
          reasonString += t('command_blocking_reason.maintenance');
        }
        break;
      case CommandBlockingReasonKeys.EVENTS:
        if (Array.isArray(val)) {
          // Showing 1st event due to less space, may revisit later
          reasonString += t('command_blocking_reason.event') + `${val[0]} \n`;
        }
        break;
      default:
    }
    // Breaking after first iteration for now, in future may need to show all the reasons
    if (index === 1) break;
  }
  return reasonString;
};

const validateAssets = ({ blockedAssets = [], assets = [] }, t) => {
  const blockedStatus = [];
  const validAssets = [];

  if (Array.isArray(blockedAssets)) {
    for (const asset of assets) {
      const { id, blockCmdCheck, reason } =
        blockedAssets?.find((elem) => elem.assetId === asset.id) || asset;

      if (blockCmdCheck === false) {
        blockedStatus.push({
          id,
          name: asset?.name,
          reason: getReasonString(reason, t),
          status: AssetCommandStatus.BLOCKED,
        });
      } else {
        validAssets.push(asset);
      }
    }
  }

  return {
    blockedStatus: blockedStatus.sort(({ name: a }, { name: b }) => a?.localeCompare(b)),
    validAssets,
  };
};

const saveCommand = async ({ assets, command = '' }) => {
  let resp = {};
  try {
    resp =
      assets?.length && assets?.length <= commands.MAX_GROUP_COMMANDS
        ? await sendCommand(sendCommandTransformer({ assets, command }))
        : {};
  } catch (err) {
    console.error(err);
  }
  return resp;
};

export const useAssetCommands = ({ assets, isActive = true, enableSafetyCheck = true }) => {
  const {
    data: blockAssetsStatus,
    error: blockStatusError,
    isLoading: blockStatusLoading,
  } = useAssetCommandBlockStatus(assets, isActive && assets.length < 2, enableSafetyCheck);

  const { commands: commandsInProgress } = useStoreState((state) => state.assets);

  const { t } = useTranslation(['entity-details']);
  const { blockedStatus, validAssets } = useMemo(
    () =>
      validateAssets(
        {
          blockedAssets: blockStatusError || blockStatusLoading ? [] : blockAssetsStatus,
          assets,
        },
        t,
      ),
    [blockAssetsStatus, assets, blockStatusError, blockStatusLoading, t],
  );

  const {
    data: commands,
    error: commandsError,
    isLoading: isCommandsLoading,
  } = useAssetCommandList({ assets, isActive });

  const {
    data: executionContext,
    error: executeError,
    isLoading: isExecuteLoading,
    mutateAsync,
    reset: resetExecute,
  } = useMutation(async (command) => {
    // can elaborate on how we want to handle these scenarios
    if (isCommandsLoading) {
      throw new Error('Available commands not loaded');
    }

    if (
      Array.isArray(commands) &&
      commands.length &&
      !commands.find((item) => item.commandId === command?.commandId) &&
      CustomEventRefreshCommand.commandId !== command?.commandId
    ) {
      const message = commands?.length
        ? `Command '${command.desc || ''}' is invalid`
        : 'No commands available';

      throw new Error(message);
    }

    // TODO: if we need to fetch execution history and failure count
    // can look at adding an intermediate step in here to get that

    const response = await saveCommand({ assets: validAssets, command });

    // we preserve the current execution context which includes the command and provided assets
    return {
      assets: validAssets,
      command: toLocaleKey(command?.desc),
      statusId: response?.statusId,
    };
  });

  const {
    data: {
      isComplete,
      isExecuting,
      reset: resetStatus,
      // default to non-blocked assets without status
      status: executeStatus = validAssets
        .map(({ id, name }) => ({ id, name }))
        .sort(({ name: a }, { name: b }) => a?.localeCompare(b)),
    },
    error: statusError,
    isLoading: isStatusLoading,
    // this should only need to take a status id (not entire context) once backend is wired up
  } = useAssetCommandStatus({ ...executionContext, isActive });

  const execute = (command) => {
    // can return sync mutate command if that makes more sense
    // swallow error because it will bubble up to the main error object anyway
    return mutateAsync(command).catch(() => {});
  };

  const reset = () => {
    resetExecute();
    resetStatus();
  };

  const abortGroupCommand = async () => {
    return await abortCommands(executionContext?.statusId);
  };

  const error = {
    [AssetCommandError.COMMANDS]: commandsError,
    [AssetCommandError.EXECUTE]: executeError,
    [AssetCommandError.STATUS]: statusError,
    [AssetCommandError.COMMNADS_BLOCK_STATUS]: blockStatusError,
  };

  const isLoading = isCommandsLoading || isExecuteLoading || isStatusLoading || blockStatusLoading;

  const status = useMemo(
    () => [...blockedStatus, ...executeStatus],
    [blockedStatus, executeStatus],
  );

  const counts = useMemo(
    () =>
      status.reduce((acc, item) => {
        if (item.status === undefined) return;
        return { ...acc, [item.status]: (acc[item.status] || 0) + 1 };
      }, {}),
    [status],
  );

  const isGroupCommandComplete = useMemo(
    () => isComplete && status?.length > 1,
    [isComplete, status],
  );

  return {
    data: {
      commands,
      counts,
      execute,
      executionContext,
      abortGroupCommand,
      isComplete,
      isExecuting,
      reset,
      status,
      inProgressLimitReached: Object.keys(commandsInProgress)?.length >= INPROGRESS_LIMIT,
      isGroupCommandComplete,
    },
    error,
    isLoading,
  };
};
