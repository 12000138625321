import { useMutation, useQueries } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { delay } from '@ge/shared/util';

export const useMailNotification = ({ addresses = [], isActive = true }) => {
  const queries = useQueries(
    addresses?.map((address) => ({
      queryKey: [QueryKey.MAIL, address],
      queryFn: () => delay(() => ({ [address]: address }), 500),
      options: {
        enabled: isActive,
      },
    })) ?? [],
  );

  const { error: saveError, mutate: save, mutateAsync: saveAsync } = useMutation(
    // TODO: wire this up to backend
    (notification) => delay(() => console.info('Sending notification:', notification), 500),
  );

  const { data, error: addressError, isLoading } = queries.reduce(
    ({ data: _data, error: _error, isLoading: _isLoading }, query) => ({
      data: {
        ..._data,
        ...query.data,
      },
      // TODO: how do we want to pass back errors here?
      error: query.error ?? _error,
      isLoading: query.isLoading || _isLoading,
    }),
    {
      data: null,
      error: null,
      isLoading: false,
    },
  );

  // how do we want to return these?
  const error = saveError ?? addressError;

  return {
    data,
    error,
    isLoading,
    save,
    saveAsync,
  };
};
