import * as request from '@ge/shared/services/api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_HYBRIDS_API;

/**
 * Fetch Storage inverters list
 */
export const fetchStorageInvertersList = ({ startDate, endDate }) => {
  return request.post(
    `/dav/storage/inverterList`,
    { startDate, endDate },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch Storage asset list
 */
export const fetchStorageAssetList = ({ startDate, endDate }) => {
  return request.post(
    `/dav/storage/assetsList`,
    { startDate, endDate },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch Storage anomaly list
 */
export const fetchStorageAnomalyList = ({ startDate, endDate }) => {
  return request.post(
    `/dav/storage/anomalysList`,
    { startDate, endDate },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch mal events
 */
export const fetchMalEventsStorage = () => {
  return {
    malEvents: [
      {
        assetId: 'MO01',
        day: '2023-06-07',
        events: [
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1686581620,
            approvedAt: null,
            endTime: 1686103200,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGFP',
            iecCategoryName: 'Full Performance',
            malCategory: 28,
            malCategoryName: 'Normal operation (IAOGFP)',
            startTime: 1686091175,
            status: '',
            user_id: 20,
          },
        ],
      },
      {
        assetId: 'MO01',
        day: '2023-06-12',
        events: [
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1686640478,
            approvedAt: null,
            endTime: 1686522614,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGFP',
            iecCategoryName: 'Full Performance',
            malCategory: 28,
            malCategoryName: 'Normal operation (IAOGFP)',
            startTime: 1686522600,
            status: '',
            user_id: 20,
          },
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1686640478,
            approvedAt: null,
            endTime: 1686535308,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGPPI',
            iecCategoryName: 'Partial Performance Internal',
            malCategory: 9,
            malCategoryName: 'Derated by contractor (IAOGPPI)',
            startTime: 1686522614,
            status: '',
            user_id: 20,
          },
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1686640478,
            approvedAt: null,
            endTime: 1686537718,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGFP',
            iecCategoryName: 'Full Performance',
            malCategory: 28,
            malCategoryName: 'Normal operation (IAOGFP)',
            startTime: 1686535308,
            status: '',
            user_id: 20,
          },
        ],
      },
      {
        assetId: 'MO07',
        day: '2023-06-12',
        events: [
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1686647886,
            approvedAt: 1688708405,
            endTime: 1686571619,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGFP',
            iecCategoryName: 'Full Performance',
            malCategory: 28,
            malCategoryName: 'Normal operation (IAOGFP)',
            startTime: 1686522656,
            status: 'Approved',
            user_id: 20,
          },
        ],
      },
      {
        assetId: 'MO01',
        day: '2023-06-13',
        events: [
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1686754950,
            approvedAt: null,
            endTime: 1686625373,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGPPE',
            iecCategoryName: 'Partial Performance External',
            malCategory: 10,
            malCategoryName: 'Derated by employer or TSO or BSH requirements (IAOGPPE)',
            startTime: 1686609600,
            status: '',
            user_id: 20,
          },
        ],
      },
      {
        assetId: 'MO01',
        day: '2023-06-18',
        events: [
          {
            adjustedBy: 'Priyanka Ramanolla',
            adjustedTime: 1687182224,
            approvedAt: null,
            endTime: 1687053576,
            eventImpact: {
              availabilityLoss: null,
              energyLoss: null,
              productionLoss: null,
              revenueLoss: null,
            },
            iecCategory: 'IAOGFP',
            iecCategoryName: 'Full Performance',
            malCategory: 28,
            malCategoryName: 'Normal operation (IAOGFP)',
            startTime: 1687041034,
            status: '',
            user_id: 20,
          },
        ],
      },
    ],
  };
  //return request.post('/dav/storage/anomalysList', params, { baseURL: BASE_URL });
};

// To approve multiple events
export const approveMalEventStorage = () => {
  return true;
  // return request.post(`/dav/mal-events/approve`, _events, {
  //   baseURL: BASE_URL,
  // });
};

/**
 * Fetch details of specific Site ID
 * @param {String} siteId Site ID
 */
export const fetchSiteDetailsStorage = ({ siteId }) => {
  return request.post(
    '/dav/storage/siteDetails',
    {
      id: siteId,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch aggregated details of specific Site
 */
export const fetchAggregatedKpisStorage = ({ assetId, startDate, endDate, kpiList, assetType }) => {
  return request.post(
    '/dav/storage/agreegated/kpis',
    { assetId, startDate, endDate, kpiList, assetType },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Fetch pareto analysis data by site
 * @param {Array<string>} assetIds Site id
 * @param {Date} startDate selected from global date range
 * @param {Date} endDate selected from global date range
 * @param {String} severity selected from event type
 * @param {string} reportBy selected from report by
 * @param {String} groupBy grouped by events or assets
 */
export const getSiteHistoricalDataStorage = ({ assetIds, startDate, endDate, kpiList }) => {
  return request.post(
    `/dav/storage/kpi/site/historical`,
    {
      assetIds,
      startDate,
      endDate,
      kpiList,
    },
    {
      baseURL: BASE_URL,
    },
  );
};

/**
 * Dynamically load the region mocks if using mocks. This allows code splitting to
 * exclude the mock json from the production package.
 */
export const registerMocks = () =>
  import('./__mocks__/storage.mocks').catch((e) => {
    // eslint-disable-next-line
    console.error('Failed to register mocks.', e);
  });
