import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';

const FormWrapper = styled.form`
  flex: 1;
  color: ${(props) => props.theme.entityDetails.tickets.textColor};
`;

const FormLabel = styled.label`
  margin-bottom: 14px;
  display: block;
`;

const TextArea = styled.textarea`
  background: ${(props) => props.theme.entityDetails.tickets.background};
  box-shadow: inset 0 1px 3px 2px rgba(0, 0, 0, 0.02);
  color: ${(props) => props.theme.entityDetails.tickets.textColor};
  margin: 6px 0 0;
  border: 0px;
  border-radius: 5px;
  padding: 14px;
  font-size: 12px;
  width: calc(100% - 28px);
`;

const TicketDetailsForm = ({ ticket, selectValue, selectOnChange }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const statusOptions = [
    { value: 'asset_conditions', label: t('ticket_entity.asset_conditions', 'Asset Conditions') },
    { value: 'stopped', label: t('ticket_entity.stopped', 'Stopped') },
    { value: 'started', label: t('ticket_entity.started', 'Started') },
  ];

  if (!ready) {
    return <Loader />;
  }

  return (
    <FormWrapper>
      <FormLabel>
        <Select
          onChange={(option) => selectOnChange(option)}
          options={statusOptions}
          value={statusOptions.filter((option) => option.value === selectValue)}
        />
      </FormLabel>

      <FormLabel>
        {t('ticket_entity.notes', 'Notes')}
        <TextArea rows="5" defaultValue={ticket.notes} />
      </FormLabel>

      <FormLabel>
        {t('ticket_entity.secondary_status', 'Secondary Status')}
        <TextArea rows="5" defaultValue={ticket['secondary_status']} />
      </FormLabel>

      <FormLabel>
        {t('ticket_entity.root_causes', 'Root Causes')}
        <TextArea rows="5" defaultValue={ticket['root_causes']} />
      </FormLabel>

      <FormLabel>
        {t('ticket_entity.actions-performed', 'Actions Performed')}
        <TextArea rows="5" defaultValue={ticket['actions-performed']} />
      </FormLabel>

      <FormLabel>
        {t('ticket_entity.additional_systems_data', 'Additional Systems Data')}
        <TextArea rows="5" defaultValue={ticket['additional_systems_data']} />
      </FormLabel>
    </FormWrapper>
  );
};

TicketDetailsForm.propTypes = {
  ticket: PropTypes.instanceOf(Object).isRequired,
  selectOnChange: PropTypes.func.isRequired,
  selectValue: PropTypes.string.isRequired,
};

export default TicketDetailsForm;
