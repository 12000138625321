import PropTypes from 'prop-types';
import insert from 'ramda/src/insert';
import without from 'ramda/src/without';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import { CheckedState } from '@ge/components/checkbox';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { EntityDetailsContext } from '@ge/shared/context';
import { killEventPropagation } from '@ge/shared/util';

import AssetBlock from './asset-block';

const AssetBar = styled.div`
  display: flex;
  flex: 1;
  border-radius: 6px;
  background: ${(props) => props.theme.dataExplorer.header.assetBar.backgroundColor};
  box-shadow: ${(props) => props.theme.dataExplorer.header.assetBar.boxShadow};
`;

const AssetRibbon = ({ assetState, assets }) => {
  const { showDetails } = useContext(EntityDetailsContext);

  const handleEntitySelect = useCallback(
    (e, id, type) => {
      killEventPropagation(e);
      showDetails(id, type);
    },
    [showDetails],
  );

  const handleAssetRemove = (e, id) => {
    killEventPropagation(e);
    assetState.setAssetsParam(without([id], assets));
    assetState.setComparisonAssets(without([id], assetState.comparisonAssets));

    if (assetState.assets.length === 1) assetState.setDataExplorerCase('');
  };

  // Hide/show case link via checkbox.
  const toggleComparisonAsset = (id, i) => {
    if (assetState.comparisonAssets.includes(id)) {
      assetState.setComparisonAssets(without([id], assetState.comparisonAssets));
      return CheckedState.UNCHECKED;
    } else {
      assetState.setComparisonAssets(insert(i, id, assetState.comparisonAssets));
      return CheckedState.CHECKED;
    }
  };

  const assetsToDisplay = assetState.assets;

  return (
    <ScrollingContainer>
      <AssetBar data-testid={AnalyzeLocators.ANALYZE_ASSET_LIST_WRAP}>
        {assetsToDisplay?.map((asset, index) => (
          <AssetBlock
            key={`header-${asset.asset.id}`}
            index={index}
            assetState={assetState}
            asset={asset}
            handleEntitySelect={handleEntitySelect}
            handleAssetRemove={handleAssetRemove}
            toggleComparisonAsset={toggleComparisonAsset}
          />
        ))}
      </AssetBar>
    </ScrollingContainer>
  );
};

AssetRibbon.propTypes = {
  assetState: PropTypes.object,
  assets: PropTypes.arrayOf(PropTypes.string),
};

export default AssetRibbon;
