import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

export const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.analyze.header.kpiIconColor,
  icon: Icons.CHEVRON,
  rotate: props.rotate || 90,
  ...props,
}))`
  margin-top: -1px;
  margin-right: 4px;
`;
