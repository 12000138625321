import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@ge/components/containers';
import { useColumnState } from '@ge/components/table/use-column-state';
import { SortDirection, AdminDefs } from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useFilterDefs } from '@ge/shared/hooks';

import { defaultDistributionColumns, DistributionColumnDefs } from '../../models';
import DistributionListTable from '../tables/distribution-list-table';

import { DLSidePanel } from './dl-sidepanel';

/**
 * Available sort metrics for Distribution Lists.
 */
export const DLMetric = {
  NAME: 'name',
};

const DistributionList = () => {
  const { ready } = useTranslation(['admin.configure']);
  const [listData, setListData] = useState({});
  const { hideDLSidePanel } = useContext(EntityDetailsContext);

  const {
    visibleCols: visibleDistributionCols,
    sortDirection,
    sortMetric,
    updateColumnVisibility,
    setVisibleColumns,
    updateSortMetric,
  } = useColumnState({
    columnDefs: DistributionColumnDefs,
    defaultCols: defaultDistributionColumns,
    defaultSortMetric: DLMetric.NAME,
    defaultSortDirection: SortDirection.ASC,
    sortStateId: AdminDefs.DISTRIBUTION_LIST_SORT_STATE_ID,
  });

  const { filterDefs, onChange: setDistributionColumnDefs } = useFilterDefs({
    columnDefs: DistributionColumnDefs,
    stateId: AdminDefs.DISTRIBUTION_LIST_SORT_STATE_ID,
  });

  /**
   * TODO: should open DL details on row select
   * (e,row)
   */

  return (
    <PageContainer i18nReady={ready}>
      <DLSidePanel data={listData} close={hideDLSidePanel} filterDefs={filterDefs} />
      <DistributionListTable
        viewDetail={setListData}
        columns={visibleDistributionCols}
        filterDefs={filterDefs}
        sortAction={updateSortMetric}
        sortMetric={sortMetric}
        updateColumnVisibility={updateColumnVisibility}
        setVisibleColumns={setVisibleColumns}
        sortDirection={sortDirection}
        setFilterDefs={setDistributionColumnDefs}
      />
    </PageContainer>
  );
};

export default DistributionList;
