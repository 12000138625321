import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';

import { EntityType, QueryKey, SortDirection } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import {
  addNoteAndSI,
  deleteNoteAndSI,
  editNoteAndSI,
  fetchEntitiesNotes,
} from '../services/notes';

import { Config } from './';

/**
 * Get notes for case and tasks
 * @param {Object} params
 * @param {Array} params.queryKey -Query key for react query
 * @param {('case'|'task')} params.entityType - Type of entity(case/task) to get notes for
 * @param {Array} params.entityIds - Case and task ids to get notes for
 * @returns {data: Object, isLoading: Boolean}
 */
export const useEntityNotes = ({
  config = Config.EXECUTE_ONCE,
  queryKey,
  entityType,
  entityIds,
}) => {
  const { data, isLoading, ...queryRest } = useQuery(
    queryKey,
    () => fetchEntitiesNotes(entityIds, entityType),
    {
      ...config,
      enabled: (config.enabled ?? true) && Boolean(entityIds.length),
    },
  );

  const notes = useMemo(() => (isLoading || !data?.notes ? [] : data.notes), [
    data?.notes,
    isLoading,
  ]);

  return {
    data: notes,
    isLoading,
    ...queryRest,
  };
};

/**
 * This is used to delete case and task notes
 * @returns { { deleteNote: Function, isLoading: Boolean, isError: Boolean, error: Object } }
 */
const useDeleteEntityNote = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteNote, isLoading, isError, error } = useMutation(deleteNoteAndSI, {
    onSettled: (_, error, variables) => {
      if (!error) {
        const caseId = variables.caseId;
        const queryKey = QueryKey.TASK_NOTES;
        queryClient.setQueryData([queryKey, caseId], (data) => {
          return { ...data, notes: data.notes.filter((n) => n.id !== variables.id) };
        });
      }
    },
  });

  return { deleteNote, isLoading, isError, error };
};

/**
 * This is used to edit case and task notes
 * @returns {{ editNote: Function, isLoading: Boolean, isError: Boolean, error: Object }}
 */
const useEditEntityNote = () => {
  const queryClient = useQueryClient();
  const { mutate: editNote, isLoading, isError, error } = useMutation(editNoteAndSI, {
    onSettled: (response, error, variables) => {
      if (!error) {
        const queryKey = QueryKey.TASK_NOTES;
        queryClient.setQueryData([queryKey, variables.originalData.domainId], (data) => {
          return {
            ...data,
            notes: data.notes.map((note) =>
              note.id !== variables.originalData.id ? note : { ...note, ...response.updated },
            ),
          };
        });
      }
    },
  });

  return { editNote, isLoading, isError, error };
};

/**
 * This is used to add case and task notes
 * @returns {{ addNote: Function, isLoading: Boolean, isError: Boolean, error: Object }}
 */
const useAddEntityNote = () => {
  const queryClient = useQueryClient();
  const { mutate: addNote, isLoading, isError, error } = useMutation(addNoteAndSI, {
    onSuccess: (response) => {
      const queryKey = QueryKey.TASK_NOTES;
      queryClient.setQueryData([queryKey, response.domainId], (data) => {
        return {
          ...data,
          notes: [...data.notes, response],
        };
      });
    },
  });

  return { addNote, isLoading, isError, error };
};

/**
 * This hook can be used to get case, child cases and task notes. Also this gives the ability to add/edit/delete notes
 * @param {{ caseId: String }} caseId - The identity of the case you wish to operate on it's notes
 * @returns {Object} ret
 * @returns {Array} ret.notes - List of case and task notes including
 * @returns {Function} ret.addNote - Can be used to add a new note
 * @returns {Function} ret.editNote - Can be used to edit and existing note
 * @returns {Function} ret.deleteNote - Can be used to delete an existing note
 * @returns {Boolean} ret.isLoading - Shows whether notes are being fetched, added, edited or updated
 * @returns {{ isCaseNotesLoading: Boolean, isTaskNotesLoading: Boolean, isAdding: Boolean, isEditing: Boolean, isDeleting: Boolean }} ret.loadingStates
 *    Object with individual loading state for each of the operations
 */
const useTaskNotes = ({ caseId }) => {
  const id = caseId;

  const taskIds = useMemo(() => {
    return !id ? [] : [id];
  }, [id]);

  // Getting notes for parent and child cases tasks
  const { data: taskNotes, isLoading: isTaskNotesLoading } = useEntityNotes({
    queryKey: [QueryKey.TASK_NOTES, caseId],
    entityType: EntityType.TASK,
    entityIds: taskIds,
  });

  const { addNote, isLoading: isAdding } = useAddEntityNote();
  const { editNote, isLoading: isEditing } = useEditEntityNote();
  const { deleteNote, isLoading: isDeleting } = useDeleteEntityNote();

  const notes = useMemo(() => {
    const _taskNotes = taskNotes.map((note) => ({ ...note, entityType: EntityType.TASK }));
    return [..._taskNotes].sort(sorter('updateDate', SortDirection.DESC));
  }, [taskNotes]);

  return {
    notes,
    addNote,
    editNote,
    deleteNote,
    isLoading: isTaskNotesLoading || isAdding || isEditing || isDeleting,
    loadingStates: {
      isTaskNotesLoading,
      isAdding,
      isEditing,
      isDeleting,
    },
  };
};

export default useTaskNotes;
