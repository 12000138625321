import { useState, useEffect } from 'react';

/* 
    Returns a boolean that repersents if an element is in view.
    The amount of the element that must be in view to trigger
    can be finetuned by adjusting the threshold. 
    This value is a decimal percentage.
*/
export const useIsVisible = ({ elementRef, options }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(elementRef.current);
          }
        });
      }, options);
      observer.observe(elementRef.current);
    }
  }, [elementRef, options]);

  return isVisible;
};
