import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Title } from '@ge/components/typography';
import { DataExplorerFilterTypes } from '@ge/models/constants';
import { getTurbineStateColor } from '@ge/shared/util/turbine-state';

import { AssetContext } from '../../../context/assetContext';

const StateList = styled.div`
  display: flex;
  margin: 0 20px 10px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      display: flex;
      align-items: center;
      margin-left: 20px;
      text-transform: uppercase;
    }
  }
`;

const StateBadge = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 2px;
  margin-right: 6px;
  background: ${(props) => getTurbineStateColor(props.state)};
  display: block;
`;

const SignalFilter = styled.div`
  margin: 0 20px 10px;
`;

export const DataExplorerCurrentFilter = () => {
  const { t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const {
    assetState: {
      chartFilter: { filter, type },
    },
  } = useContext(AssetContext);

  const getChartFilterText = () => {
    const { min, max, unit } = filter;
    if (min && !max) {
      return `> ${min} ${unit}`;
    }

    if (!min && max) {
      return `< ${max} ${unit}`;
    }

    return `${min} - ${max} ${unit}`;
  };

  if (!filter) return null;
  return (
    <>
      {type === DataExplorerFilterTypes.STATE ? (
        <StateList>
          <Title level={5}>{t('dialogs.asset_state', 'Asset State')}:</Title>
          <ul>
            {filter.map((state) => (
              <li key={state}>
                <StateBadge state={state} /> <Title level={4}>{state}</Title>
              </li>
            ))}
          </ul>
        </StateList>
      ) : (
        <SignalFilter>
          <Title level={5}>{filter.name}:</Title>
          {/* placeholder metric values until we build out the signal filter form/components */}
          <Title level={2}>{getChartFilterText()}</Title>
        </SignalFilter>
      )}
    </>
  );
};
