import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Pattern, DistributionLists } from '@ge/models/constants';
import {
  AlertDLType,
  CaseEscalationType,
  TypeToSourceIdDLMapping,
  Types,
} from '@ge/models/constants';
import { useCreateDistributionList, useUpdateDistributionList } from '@ge/shared/data-hooks';
import { killEventPropagation } from '@ge/shared/util';
import { globalColors } from '@ge/tokens';

import { useSSO } from '../../../inspections/data-hooks/use-session';

import { CreateDLForm } from './dl-form';
import { CreateHeader } from './headers';

const Tabs = styled.div`
  display: flex;
  margin-left: 30px;

  &.loading-cls {
    display: none;
  }
`;

const TabInfo = styled.div`
  display: flex;
  color: ${(props) =>
    props.theme.distributionListSidePanel[props.$isCurrent ? 'tabTextSelected' : 'tabText']};
  font: 14px/17px 'Museo Sans';
  letter-spacing: 0;
  > .oval {
    display: flex;
    justify-content: center;
    align-items: center
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background: ${(props) =>
      props.theme.distributionListSidePanel[props.$isCurrent ? 'tabNumberSelected' : 'tabNumber']};
  }
  label {
    margin: 6px 10px;
  }
  svg {
    margin: 10px;
  }
`;

const StyledButton = styled(Button)`
  float: right;
  margin: 1em 0.5em;
`;

const NewTaskFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  button {
    text-transform: capitalize;
  }
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const ScrollableDiv = styled.div`
  max-height: calc(100vh - 170px);
  text-transform: capitalize;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
  &.loading-cls {
    display: none;
  }
`;

export const CreateDL = ({
  details,
  close,
  type,
  ispopup,
  isCancelPopup,
  setIsPopup,
  setIsCancelPopup,
  toggleConfirmPopup,
  toggleCancelConfirmPopup,
  isCancel,
  setIsCancel,
  distributionLists,
}) => {
  const { t } = useTranslation(['admin.configure']);

  const cases = [
    { type: CaseEscalationType.FAULTED_ACTIONABLE, value: false },
    { type: CaseEscalationType.FAULTED_INFORMATIONAL, value: false },
    { type: CaseEscalationType.NOTFAULTED_ACTIONABLE, value: false },
    { type: CaseEscalationType.NOTFAULTED_INFORMATIONAL, value: false },
  ];
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [mails, setMails] = useState([]);
  const [isExistedName, setExistedName] = useState(false);
  const [loading, setLoading] = useState({ load: false, error: '' });

  const { reset, control } = useFormContext();
  const { data: sso } = useSSO();
  const { createDL } = useCreateDistributionList();
  const { updateDL, isLoading } = useUpdateDistributionList();

  const { getAssetsBySiteId } = useStoreState((state) => state.assets);
  let isChanged = false;

  const isNameExistedFn = () => {
    let isNameExisted = false;

    distributionLists?.some((rec) => {
      let typeBool = (type === Types.EDIT && rec.distId !== details?.distId) || type !== Types.EDIT;
      let uniqueNameBool = rec?.name?.toLowerCase() === control.getValues('name').toLowerCase();

      if (typeBool && uniqueNameBool) {
        isNameExisted = true;
      }
    });

    setExistedName(isNameExisted);

    return isNameExisted;
  };

  const isTemplatesSelected = () => {
    let bool = false;

    cases.forEach((rec, index) => {
      if (!bool && control.getValues(`cases[${index}].${rec.type}`)) {
        bool = true;
      }
    });

    !bool &&
      Object.keys(AlertDLType).forEach((key, index) => {
        if (!bool && control.getValues(`alerts[${index}].${key}`)) {
          bool = true;
        }
      });

    return bool;
  };

  const onNextTab = (e) => {
    if (e) {
      toggleConfirmPopup();
    }

    history.push({ pathname: location.pathname, search: `?mode=${DistributionLists.NEW}&tab=2` });
  };

  const onPrevTab = () => {
    history.push({ pathname: location.pathname, search: `?mode=${DistributionLists.NEW}&tab=1` });
  };

  const handleClick = (e) => {
    const isSelected = isTemplatesSelected();

    if (!isSelected) {
      toggleConfirmPopup();
    }

    if (!isNameExistedFn() && (ispopup || isSelected)) {
      onNextTab();

      killEventPropagation(e);
    }
  };

  const resetFormData = () => {
    reset({
      name: '',
      desc: '',
      sites: [],
      assets: [],
      cases,
      alerts: Object.keys(AlertDLType).map((key) => ({ type: key, value: false })),
      mails: [{ firstName: '', lastName: '', type: 'to', email: '' }],
    });
  };

  const getValues = () => {
    const obj = {
      name: control.getValues('name'),
      desc: control.getValues('desc'),
      sites: control.getValues('sites'),
      assets: control.getValues('assets'),
      mails: mails.map((rec, index) => {
        return { ...rec, type: control.getValues(`mails[${index}].type`) || 'to' };
      }),
      cases: cases.map((rec, index) => {
        return { ...rec, value: control.getValues(`cases[${index}].${rec.type}`) || false };
      }),
      alerts: Object.keys(AlertDLType).map((key, index) => ({
        type: key,
        value: control.getValues(`alerts[${index}].${key}`) || false,
      })),
    };

    return obj;
  };

  // A function to compare if two arrays have the same elements regardless of their order
  const equalsCheck = (a, b) => {
    // If they point to the same instance of the array
    if (a === b) return true;

    // If they point to the same instance of date
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();

    // If both of them are not null and their type is not an object
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;

    // This means the elements are objects
    // If they are not the same type of objects
    if (a.prototype !== b.prototype) return false;

    // Check if both of the objects have the same number of keys
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;

    // Check recursively for every key in both
    return keys.every((k) => equalsCheck(a[k], b[k]));
  };

  const isInputChanged = () => {
    const { name, desc, cases, alerts, sites, assets, mails } = getValues();
    const entityArrayFn2 = (siteId, assetsBySite) => {
      if (assets === 'All') {
        return { siteId };
      }

      return {
        siteId,
        assetId: assets?.reduce((arr, { value: assetId }) => {
          if (assetsBySite?.some(({ id }) => assetId === id)) {
            arr.push(assetId);
          }
          return arr;
        }, []),
      };
    };

    const entity = sites?.reduce((siteAssetArr, { value: siteId }) => {
      const assetsBySite = getAssetsBySiteId(siteId);
      siteAssetArr.push(entityArrayFn2(siteId, assetsBySite));
      return siteAssetArr;
    }, []);

    const distributionType = [...cases, ...alerts].reduce((arr, item) => {
      if (item.value) {
        arr.push(TypeToSourceIdDLMapping[item.type]);
      }
      return arr;
    }, []);

    const newToList = mails
      ?.filter(
        ({ type, email, firstName, lastName }) =>
          type === Types.TO && (email != '' || firstName != '' || lastName != ''),
      )
      .map(({ email, firstName, lastName }) => ({ email, firstName, lastName }));
    const newCCList = mails
      ?.filter(
        ({ type, email, firstName, lastName }) =>
          type === Types.CC && (email != '' || firstName != '' || lastName != ''),
      )
      .map(({ email, firstName, lastName }) => ({ email, firstName, lastName }));
    const newBccList = mails
      ?.filter(
        ({ type, email, firstName, lastName }) =>
          type === Types.BCC && (email != '' || firstName != '' || lastName != ''),
      )
      .map(({ email, firstName, lastName }) => ({ email, firstName, lastName }));

    if (type === Types.NEW) {
      if (name != '') {
        isChanged = true;
      } else if (desc != '') {
        isChanged = true;
      } else if (entity?.length > 0) {
        isChanged = true;
      } else if (newToList?.length > 0) {
        isChanged = true;
      } else if (newCCList?.length > 0) {
        isChanged = true;
      } else if (newBccList?.length > 0) {
        isChanged = true;
      } else if (distributionType?.length > 0) {
        isChanged = true;
      } else {
        isChanged = false;
      }
    }
    if (type === Types.EDIT) {
      const { toList, ccList, bccList } = details.distProperties;
      if (name !== details.name) {
        isChanged = true;
      } else if (desc !== details.desc) {
        isChanged = true;
      } else if (!equalsCheck(details.entity, entity)) {
        isChanged = true;
      } else if (!equalsCheck(toList, newToList)) {
        isChanged = true;
      } else if (!equalsCheck(ccList, newCCList)) {
        isChanged = true;
      } else if (!equalsCheck(bccList, newBccList)) {
        isChanged = true;
      } else if (!equalsCheck(details.distributionType, distributionType)) {
        isChanged = true;
      } else {
        isChanged = false;
      }
    }
  };

  const onSave = () => {
    const { name, desc, cases, alerts, sites, assets, mails } = getValues();

    setLoading({ load: true, error: '' });

    const entityArrayFn = (siteId, assetsBySite) => {
      if (assets === 'All' || assets?.length === assetsBySite?.length || sites?.length > 1) {
        return { siteId };
      }

      if (assets?.length === 0) {
        return { siteId, assetId: [] };
      }

      if (assets?.length !== assetsBySite?.length) {
        return {
          assetId: assets.reduce((arr, { value: assetId }) => {
            if (assetsBySite?.some(({ id }) => assetId === id)) {
              arr.push(assetId);
            }
            return arr;
          }, []),
        };
      }
    };

    const data = {
      name,
      desc,
      deleted: 0,
      entity: sites.reduce((siteAssetArr, { value: siteId }) => {
        const assetsBySite = getAssetsBySiteId(siteId);
        siteAssetArr.push(entityArrayFn(siteId, assetsBySite));
        return siteAssetArr;
      }, []),

      auditProperties: {
        createdBy: sso,
        updatedBy: sso,
        createdTime: Date.now(),
        updatedTime: Date.now(),
      },
      distributionType: [...cases, ...alerts].reduce((arr, item) => {
        if (item.value) {
          arr.push(TypeToSourceIdDLMapping[item.type]);
        }
        return arr;
      }, []),

      distProperties: {
        name,
        toList: mails
          .filter(({ type }) => type === Types.TO)
          .map(({ email, firstName, lastName }) => ({ email, firstName, lastName })),
        ccList: mails
          .filter(({ type }) => type === Types.CC)
          .map(({ email, firstName, lastName }) => ({ email, firstName, lastName })),
        bccList: mails
          .filter(({ type }) => type === Types.BCC)
          .map(({ email, firstName, lastName }) => ({ email, firstName, lastName })),

        replyToList: details?.distProperties?.replyToList.map(
          ({ email, firstName = 'No-reply', lastName }) => ({
            email,
            firstName,
            lastName,
          }),
        ),
      },
    };

    if (data?.distProperties?.toList?.length === 0) {
      setLoading({
        load: false,
        error: t(
          'DL_form_fields.email_to_type_error',
          `Atleast one Recipient should be selected as 'To'`,
        ),
      });

      return;
    }

    const triggerDLRequest = () => {
      if (type === Types.EDIT) {
        data.distId = details.distId;
        data.distEntityId = details.distEntityId;

        return updateDL({ id: details.distId, body: data });
      } else {
        return createDL(data);
      }
    };

    triggerDLRequest()
      .then(() => {
        setLoading({ load: false, error: '' });
        resetFormData();
        if (type === Types.EDIT) {
          history.goBack();
        } else {
          close();
        }
      })
      .catch((err) => {
        setLoading({
          load: false,
          error: err?.response?.data?.errorDescription || err?.response?.statusText || err?.message,
        });
      });
  };

  function onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9 ]*$/.test(str);
  }

  const isValid = () => {
    let isValidName = /^[^!-/:-@[-`{-~]+$/.test(control.getValues('name'));
    let bool = isValidName && control.getValues('sites')?.length && mails?.length;

    if (bool) {
      mails.forEach((rec) => {
        if (!rec?.email?.match(Pattern.EMAIL)) {
          bool = false;
        }
      });
    }

    return bool;
  };

  const getCancelFooter = () => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={toggleConfirmPopup}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={onNextTab}>
            {t('general:ok', 'OK')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  };

  const getCancelFooterEdit = () => {
    return (
      <NewTaskFooterWrapper>
        <FooterButtons>
          <Button onClick={toggleCancelConfirmPopup}>{t('cancel', 'Cancel')}</Button>
          <Button primary onClick={onOkClick}>
            {t('ok', 'Ok')}
          </Button>
        </FooterButtons>
      </NewTaskFooterWrapper>
    );
  };

  const onCancelClick = () => {
    isInputChanged();
    if (isChanged) {
      setIsCancel(true);
      toggleCancelConfirmPopup();
    } else {
      close();
    }
  };

  const onOkClick = () => {
    setIsCancel(false);
    setIsPopup(false);
    setIsCancelPopup(false);
    close();
  };

  return (
    <>
      {isCancel ? (
        <Dialog
          isOpen={isCancelPopup && isCancel}
          onClose={toggleCancelConfirmPopup}
          onConfirm={close}
          header={t('general:confirmation', 'Confirmation')}
          footer={getCancelFooterEdit()}
          contentWidth
          padContent={true}
        >
          <p>
            {t(
              'dist_list.cancel_confirmation_message',
              'Are you sure to cancel this distribution list?',
            )}
          </p>
        </Dialog>
      ) : null}

      <Dialog
        isOpen={ispopup}
        onClose={toggleConfirmPopup}
        onConfirm={onNextTab}
        header={t('general:confirmation', 'Confirmation')}
        footer={getCancelFooter()}
        contentWidth
        padContent={true}
      >
        <p>
          {t(
            'post_process.not_right',
            'None of the templates are selected, do you want to proceed?',
          )}
        </p>
      </Dialog>

      {loading.load || isLoading ? (
        <Loader />
      ) : (
        <CreateHeader data={details} type={type} error={loading.error} />
      )}
      {type && type === Types.NEW ? (
        <Tabs className={loading.load ? 'loading-cls' : ''}>
          <TabInfo $isCurrent={params.get(DistributionLists.TAB) !== '2'} onClick={onPrevTab}>
            <div className="oval">
              <span>1</span>
            </div>
            <label>{t('select_list_type', 'Select List Type')}</label>
            <Icon size={20} icon={Icons.ARROW_RIGHT} color={globalColors.slate5} />
          </TabInfo>
          <TabInfo $isCurrent={params.get(DistributionLists.TAB) === '2'} onClick={handleClick}>
            <div className="oval">
              <span>2</span>
            </div>
            <label>{t('add_recipients', 'Add Recipients')}</label>
          </TabInfo>
        </Tabs>
      ) : (
        ''
      )}
      <ScrollableDiv className={loading.load ? 'loading-cls' : ''}>
        <CreateDLForm
          details={details}
          type={type}
          isTabbed={true}
          mails={mails}
          setMails={setMails}
          isExistedName={isExistedName}
          currentTab={params.get(DistributionLists.TAB)}
        />
        {type &&
          type === Types.NEW &&
          params.get(DistributionLists.TAB) !== '2' &&
          (control.getValues('name') &&
          onlyLettersAndNumbers(control.getValues('name')) &&
          control.getValues('sites').length ? (
            <StyledButton onClick={handleClick} primary>
              {t('next', 'Next')}
            </StyledButton>
          ) : (
            <StyledButton disabled primary>
              {t('next', 'Next')}
            </StyledButton>
          ))}
        {((type && type !== Types.VIEW && type !== Types.NEW) ||
          params.get(DistributionLists.TAB) == '2') &&
          (isValid() || (type === Types.EDIT && isValid()) ? (
            <StyledButton onClick={() => onSave()} primary>
              {t('save', 'Save')}
            </StyledButton>
          ) : (
            <StyledButton disabled primary>
              {t('save', 'Save')}
            </StyledButton>
          ))}{' '}
        {type !== Types.VIEW ? (
          <StyledButton onClick={onCancelClick}>{t('cancel', 'Cancel')}</StyledButton>
        ) : (
          ''
        )}
      </ScrollableDiv>
    </>
  );
};

CreateDL.propTypes = {
  details: PropTypes.object,
  close: PropTypes.any,
  type: PropTypes.string,
  filterDefs: PropTypes.object,
  toggleConfirmPopup: PropTypes.func,
  ispopup: PropTypes.bool,
  isCancelPopup: PropTypes.bool,
  toggleCancelConfirmPopup: PropTypes.func,
  isCancel: PropTypes.bool,
  setIsCancel: PropTypes.any,
  setIsPopup: PropTypes.any,
  setIsCancelPopup: PropTypes.any,
  distributionLists: PropTypes.arrayOf(PropTypes.object),
};
