import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { DateTimeFormats } from '@ge/models/constants';
import { roundNumber } from '@ge/util';

import { availabilityFieldDefs, AvailabilityFields } from './availability-config';

const XAxisDateLabel = styled.div`
  font-size: 11px;
`;

const XAxisValueLabel = styled.div`
  font-size: 12px;
`;

const ChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const AverageLineLabel = styled.div`
  color: ${({ theme }) => theme.createReport.widget.availability.lineLabelColor};
  font-size: 11px;
`;

const AverageLineValue = styled.span`
  color: ${({ theme }) => theme.createReport.widget.availability.lineUnitsColor};
  padding-left: 5px;
`;

const FilterTypeLabel = styled.div`
  color: ${({ theme }) => theme.createReport.widget.availability.filterLabelColor};
  font-size: 11px;
  font-weight: 700;
  margin-left: auto;
  order: 2;
  padding-right: 24px;
  text-transform: uppercase;
`;

const UnitsLabel = styled.span`
  color: ${({ theme }) => theme.createReport.widget.table.unitsLabelColor};
  font-size: 11px;
  font-weight: 500;
  padding-left: 5px;
`;

const MAX_Y_VALUE = 100;

export const AvailabilityStateKeys = {
  CONFIG_STATE: 'configState',
};

export const AvailabilityWidget = withTheme(({ theme, id, data, units }) => {
  const { t } = useTranslation(['reporting.widgets', 'general']);

  const { getWidgetState } = useContext(ReportsContext);
  const configState = getWidgetState(id, AvailabilityStateKeys.CONFIG_STATE);

  const showAverage = useMemo(() => {
    const showGroup = configState?.find((group) => group.id === AvailabilityFields.GROUP_SHOW);
    const averageField = showGroup?.fields?.find(
      (field) => field.id === AvailabilityFields.AVERAGE,
    );
    return averageField?.visible;
  }, [configState]);

  const filterLabel = useMemo(() => {
    const filterGroup = configState?.find((group) => group.id === AvailabilityFields.GROUP_FILTERS);
    const enabledFilter = filterGroup?.fields?.find((field) => field.visible === true);

    const filterConfigItem =
      enabledFilter &&
      availabilityFieldDefs[AvailabilityFields.GROUP_FILTERS].fields[enabledFilter.id];
    if (filterConfigItem?.labels?.length > 0) {
      return t(filterConfigItem.labels[0].a11yKey, filterConfigItem.labels[0].a11yDefault);
    }

    return '';
  }, [configState, t]);

  const seriesData = useMemo(
    () =>
      (data ?? []).map((seriesValue) => {
        const { kpiValue, requestedDate: _requestedDate } = seriesValue;
        const requestedDate = dayjs(_requestedDate).valueOf();
        return {
          x: requestedDate,
          y: kpiValue,
        };
      }),
    [data],
  );

  const series = [
    {
      animation: false,
      data: seriesData,
      tooltipPointFormatter: ({ x, y }) => `
        <div>
          ${dayjs(x).format(DateTimeFormats.DEFAULT_DATE)}: ${y}${units}
        </div>
      `,
    },
  ];

  const minYValue = useMemo(() => {
    if (seriesData.length === 0) {
      return 0;
    }
    const { y } = seriesData.reduce((prev, curr) => (prev.y < curr.y ? prev : curr));
    return y;
  }, [seriesData]);

  const xAxisLabelFormatter = (chartObj) => {
    const xDate = dayjs(chartObj.value).format('MM/DD');
    const series = chartObj.chart.series[0];

    // We need to display the corresponding Y value with the X axis labels
    // The X Axis labels aren't guaranteed to be on a valid X value - find the closest valid x value
    // Since the lowest granularity for this widget's data is `DAILY`, we can safely use the first x
    // value that formats to the same date string as the current `chartObj`.
    const xIndex = series.xData.findIndex((x) => dayjs(x).format('MM/DD') === xDate);
    if (xIndex < 0) {
      return null;
    }
    // Use the index of the closes valid X value to get the corresponding Y value
    const yValue = roundNumber(series.yData[xIndex], 2);

    // Highcharts label formatter doesn't entirely respect markup - add in the line break to render properly
    return renderToString(
      <>
        <XAxisDateLabel style={{ color: theme.createReport.widget.productionGraph.labelColor }}>
          {xDate}
        </XAxisDateLabel>
        <br />
        <XAxisValueLabel
          style={{ color: theme.createReport.widget.productionGraph.valueLabelColor }}
        >
          {yValue}
        </XAxisValueLabel>
      </>,
    );
  };

  const yAxisLabelFormatter = (chartObj) => {
    const yValue = Math.min(chartObj.value, MAX_Y_VALUE);
    return chartObj.isLast ? `${yValue}${units}` : `${yValue}`;
  };

  if (!data?.length > 0) {
    return null;
  }

  return (
    <>
      <ChartHeader>
        <FilterTypeLabel>
          {filterLabel}
          <UnitsLabel>{`(${units})`}</UnitsLabel>
        </FilterTypeLabel>
        {showAverage && (
          <AverageLineLabel>
            {t('availability.average', 'Average')}
            <AverageLineValue>
              {data[0]?.totalValue}
              {units}
            </AverageLineValue>
          </AverageLineLabel>
        )}
      </ChartHeader>
      <Chart
        ceiling={MAX_Y_VALUE}
        height={140}
        min={minYValue}
        series={series}
        type={ChartType.AREA_SPLINE}
        xAxisType={'datetime'}
        xAxisLabelFormatter={xAxisLabelFormatter}
        yAxisLabelFormatter={yAxisLabelFormatter}
        yAxisTickInterval={5}
        yAxisOffset={30}
        yAxisOpposite
        disableSideMargin
      />
    </>
  );
});

AvailabilityWidget.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  units: PropTypes.string,
};
