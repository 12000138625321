import { useStoreState } from 'easy-peasy';
import * as Exceljs from 'exceljs';
import * as saveAs from 'file-saver';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { TasksColumns } from '@ge/feat-manage/models/tasks-table-cols';
import { ErpSource, Tenant } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';

const ExportIcon = styled(Icon).attrs(() => ({
  size: 14,
  color: globalColors.slate4,
  viewbox: '0 0 12 12',
}))``;

const StyledExportButton = styled.button`
  svg {
    margin-right: 6px;
  }
`;

export default function ExportTaskTable({ xlsxData, columnFilters }) {
  const { t } = useTranslation(['tasks'], {
    useSuspense: false,
  });

  const { approvalFlag, srNewFieldsFlag } = useStoreState((state) => state.tenant.featureFlags);

  const [year, setYear] = useState(new Date().toJSON().slice(0, 4));
  const [month, setMonth] = useState(new Date().toString().slice(4, 7));
  const [day, setDay] = useState(new Date().toJSON().slice(8, 10));
  const [militaryTime, setMilitaryTime] = useState(new Date().toString().slice(16, 24));
  const tenantId = sessionStorage.getItem('tenantId');
  const erpSource = tenantId.startsWith(Tenant.DTE) ? ErpSource.DTE_MAXIMO : ErpSource.RACES;

  const xlsxFilename = useCallback(() => {
    return `Tasks_Export_${day}_${month.toUpperCase()}_${year}-${militaryTime
      .split(':')
      .join('_')}`;
  }, [day, militaryTime, month, year]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMilitaryTime(new Date().toString().slice(16, 24));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (day !== new Date().toJSON().slice(8, 10)) setDay(new Date().toJSON().slice(8, 10));
    if (month !== new Date().toString().slice(4, 7)) setMonth(new Date().toString().slice(4, 7));
    if (year !== new Date().toJSON().slice(0, 4)) setYear(new Date().toJSON().slice(0, 4));
  }, [day, month, year]);

  const xlsxDataLabels = useCallback(
    (label) => {
      switch (label) {
        case 'site':
          return t('table.site', 'Site');
        case 'asset':
          return t('table.asset', 'Asset');
        case 'state':
          return t('table.state', 'State');
        case 'priority':
          return t('table.priority', 'Priority');
        case 'status':
          return t('table.status', 'Status');
        case 'title':
          return t('table.title', 'Title');
        case 'description':
          return t('table.description', 'Description');
        case 'source':
          return t('form.source', 'Source');
        case 'workScope':
          return t('table.workScope', 'Work Scope');
        case 'linked':
          return t('table.linked', 'Linked');
        case 'component':
          return t('table.component', 'Component');
        case 'estTechs':
          return t('table.est_techs', 'Est. # Techs');
        case TasksColumns.EST_DURATION:
          return t('form.est_duration', 'Est. Duration');
        case 'duration':
          return t('form.act_duration', 'Actual Duration');
        case 'est-labor-hours':
          return t('table.est_labor_hours', 'Est. Labor Hrs');
        case TasksColumns.CREATED_DATE:
          return t('table.created_date', 'Created Date');
        case TasksColumns.ELIGIBLE_START_DATE:
          return t('table.eligible_start_date', 'Eligible Start Date');
        case TasksColumns.SCHEDULED_DATE:
          return t('table.scheduled_date', 'Scheduled Date');
        case TasksColumns.DUE_DATE:
          return t('form.due_date', 'Due Date');
        case TasksColumns.COMMITTED_DATE:
          return t('form.committed_date', 'Committed Date');
        case TasksColumns.STARTED_DATE_TIME:
          return t('table.start_date', 'Start Date');
        case TasksColumns.COMPLETED_DATE_TIME:
          return t('form.completed_date', 'Completed Date');
        case 'techs':
          return t('table.assigned_techs', 'Assigned Techs');
        case 'parts-expected':
          return t('table.parts_expected', 'Parts Expected');
        case 'parts-consumed':
          return t('table.parts_consumed', 'Parts Consumed');
        case 'attachment':
          return t('table.attachment', 'Attachment');
        case 'resolution':
          return t('table.resolution', 'Resolution');
        case TasksColumns.SR_NUMBER:
          return erpSource === ErpSource.DTE_MAXIMO
            ? t('table.wo_number', 'WO Number')
            : t('table.sr_number', 'SR Number');
        case TasksColumns.SR_STATUS:
          return erpSource === ErpSource.DTE_MAXIMO ? '' : t('table.sr_status', 'SR Status');
        case TasksColumns.FLAG:
          return approvalFlag ? t('table.approvalFlag', 'Task Approved') : '';
        case TasksColumns.RDSPP:
          return srNewFieldsFlag ? t('table.rdspp', 'rdspp') : '';
        default:
          return label;
      }
    },
    [erpSource, t, approvalFlag, srNewFieldsFlag],
  );
  const buildFilterColumn = useCallback(
    () =>
      (columnFilters ?? [])
        .filter((x) => x !== 'selected' && x !== 'actions' && x !== 'notes' && x !== 'detail')
        .map((keys) => ({ header: xlsxDataLabels(keys), key: keys, width: 15 })),
    [columnFilters, xlsxDataLabels],
  );

  const handleClick = () => {
    const workbook = new Exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Tasks Data');
    worksheet.columns = buildFilterColumn();
    xlsxData.forEach((data) => {
      worksheet.addRow(data);
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
      saveAs(blob, `${xlsxFilename()}.xlsx`);
    });
  };
  return (
    <div>
      <StyledExportButton type="button" onClick={handleClick}>
        <ExportIcon icon={Icons.EXPORT} />
      </StyledExportButton>
    </div>
  );
}
ExportTaskTable.propTypes = {
  xlsxData: PropTypes.array.isRequired,
  columnFilters: PropTypes.array,
};
