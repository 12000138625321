import dayjs from 'dayjs';
import html2canvas from 'html2canvas';

import { DateTimeFormats, EntityType } from '@ge/models/constants';

const downloadImage = (blob, fileName) => {
  // eslint-disable-next-line no-undef
  const fakeLink = window.document.createElement('a');
  fakeLink.style = 'display:none;';
  fakeLink.download = fileName;

  fakeLink.href = blob;

  // eslint-disable-next-line no-undef
  document.body.appendChild(fakeLink);
  fakeLink.click();
  // eslint-disable-next-line no-undef
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export const exportAsImage = (
  element,
  imageFileName,
  options,
  textColor,
  graphDetails,
  siteDetails,
) => {
  const elementWidth = element.offsetWidth + 'px';
  const clonedElement = element.cloneNode(true);
  // eslint-disable-next-line no-undef
  const document = window.document;
  const graphContainer = document.createElement('div');
  if (
    siteDetails.entityType === EntityType.SITE ||
    siteDetails.entityType === EntityType.REGION ||
    siteDetails.entityType === EntityType.SITES ||
    siteDetails.entityType === EntityType.ASSET
  ) {
    const content = document.createElement('div');
    const { title, metadata } = graphDetails;
    const name =
      siteDetails.entityType === EntityType.ASSET && siteDetails.entity.site.name
        ? siteDetails.entity.site.name + '-'
        : '';
    const startDate = dayjs(metadata.startDate).format(DateTimeFormats.FILTER_CUSTOM_DATE);
    const endDate = dayjs(metadata.endDate).format(DateTimeFormats.FILTER_CUSTOM_DATE);
    content.innerHTML += `<p>${title} : ${name} ${siteDetails.entity.name} ${startDate} - ${endDate}</p>`;
    graphContainer.append(content);
  }
  graphContainer.append(clonedElement);
  const styleGraphContainer = {
    position: 'absolute',
    left: '-99999px',
    top: '0px',
    zIndex: '-1',
    width: elementWidth,
    color: textColor,
  };
  Object.assign(graphContainer.style, styleGraphContainer);
  document.body.appendChild(graphContainer);
  html2canvas(graphContainer, options).then(function(canvas) {
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
    document.body.removeChild(graphContainer);
  });
};

export default exportAsImage;
