/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tab, Tabs } from '@ge/components/tabs';
import { Tooltip } from '@ge/components/tooltip';
import { useGlobalFilters } from '@ge/feat-analyze/hooks';
import { PermissionScope, AuthStrategy } from '@ge/models/constants';
import { DayJs, InspectionTypes } from '@ge/models/constants';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { elevations, globalColors } from '@ge/tokens';
import { isEmpty } from '@ge/util/object-utils';

import { DetailContainer, SectionContainer } from '../entity-details-shared';
import { EntityCollapsiblePanel } from '../entity-details-shared';

import DamageDetailsHeader from './damage-details-header';
import PacIntegrationTable from './pac-integration-table';

const StyledDetailContainer = styled(DetailContainer)`
  padding: 0 10px;
`;

const BladeDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledEntityCollapsiblePanel = styled(EntityCollapsiblePanel)`
  background-color: ${globalColors.slate5};
  color: ${globalColors.slate7};
  border-radius: 3px;
  margin-bottom: 18px;
  padding: 12px;
  position: relative;
  > div {
    margin-bottom: 0px;
  }
  .content {
    > section {
      margin-bottom: 30px;
    }
  }
`;
const CollapsiblePanelHeading = styled.h2`
  font-family: 'Museo Sans';
  font-size: 12px !important;
  letter-spacing: 0.5px;
  line-height: 12px !important;
  font-weight: 700;
  text-transform: uppercase;
`;
const StyledSummary = styled.div`
  padding: 5px 5px 5px 21px;
  font-size: 13px;
  line-height: 18px;
`;
const BladeDetail = styled.div`
  display: flex;
  align-items: center;
`;
const BladeDetailLabel = styled.label`
  color: ${globalColors.grey4};
  margin-right: 4px;
`;
const BladeDetailValue = styled.p`
  margin: 0;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ImageViewerButton = styled.button`
  border: none;
  background: none;
`;
const ImageViewerLinkIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.theme.layout.textColor,
}))``;
const RawImage = styled.image`
  position: absolute;
  top: 0;
  width: 100%;
`;
const AnnotationImage = styled.image`
  position: absolute;
  cursor: pointer;
  top: 0;
  width: 100%;
`;

const LoaderContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
`;
const ScrollingContainerContent = styled.div``;

const DamageDetailsContainer = styled.div`
  display: flex;
  font-size: 12px;
`;
const DamageDetailBoxLabelsValues = styled.div`
  flex: 1;
`;
const DamageFieldsLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.themeSelector.customView.inputBorderColor};
  margin-bottom: 4px;
`;
const DamageFieldsValue = styled.p`
  margin: 0 0 18px 0;
`;
const DamageDetailDescription = styled.div`
  flex: 2;
`;
const ReportDamage = styled.div`
  display: grid;
  grid-template-columns: 100px 350px;
  column-gap: 10px;
  margin-bottom: 10px;
`;
const ReportDamageFieldsLabel = styled.label`
  color: ${(props) => props.theme.themeSelector.customView.inputBorderColor};
  text-align: right;
`;
const ReportDamageFieldsValue = styled.p`
  margin: 0;
`;
const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const DamageDetails = (damageParams) => {
  const { t, ready } = useTranslation(['inspections.damage-details'], {
    useSuspense: false,
  });
  const { hideDetails } = useContext(EntityDetailsContext);
  const { damages } = useStoreState((state) => state.damages);

  const [damageDetails, setDamageDetails] = useState(null);
  const [inspectionId, setInspectionId] = useState(null);
  const [rawImageUrl, setRawImageUrl] = useState(null);
  const [annotationImageUrl, setAnnotationImageUrl] = useState(null);
  // const [annotationCSS, setAnnotationCSS] = useState({});
  const [summary, setSummary] = useState('');
  const getDamageDetailsById = useStoreActions((actions) => actions.damages.getDamageDetailsById);
  const fetchImageDetails = useStoreActions((actions) => actions.inspections.fetchImageDetails);
  const { fetchDamagesResultTable } = useStoreActions((actions) => actions.damages);

  const globalFilters = useGlobalFilters();
  const sitesData = useStoreState((state) => state.sites.sites);
  const [bladeSerialNumber, setBladeSerialNUmber] = useState('');
  const [axisNumber, setAxisNumber] = useState('');
  const [imageName, setImageName] = useState('T1234');
  const [location, setLocation] = useState('');
  const [damageID, setDamageID] = useState();
  const [status, setStatus] = useState();
  const [component, setComponent] = useState();
  const [requiredDate, setRequiredDate] = useState();
  const [dateIdentified, setDateIdentified] = useState();
  const [length, setLength] = useState();

  const [family, setFamily] = useState();
  const [subFamily, setSubFamily] = useState();
  const [damage, setDamage] = useState();
  const [category, setCategory] = useState();
  const [followUp, setFollowUp] = useState();
  const [action, setAction] = useState();
  const [probableCause, setProbableCause] = useState();
  const [spanZ, setSpanZ] = useState();
  const [chord, setChord] = useState();
  const [width, setWidth] = useState();
  const [imageComponentHeight, setImageComponenetHeight] = useState(0);
  const [imageId, setImageId] = useState(null);
  const [annotationComment, setAnnotationComment] = useState();
  const [enhancementMetada, setEnhancementMetadata] = useState({
    weight: 1,
    gamma: 1,
    brightness: 0,
    contrast: 1,
    invert: 0,
    grayscale: 0,
    blur: 13,
  });
  const [timerId, setTimerId] = useState();
  const [originalImageHeight, setOriginalImageHeight] = useState(null);
  const [originalImageWidth, setOriginalImageWidth] = useState(null);
  const [assetSiteId, setAssetSiteId] = useState(null);
  const [showBladeDetails, setShowBladeDetails] = useState(true);
  const getDate = (inputDate) => {
    if (!inputDate) {
      return null;
    }
    const date = dayjs(inputDate * 1000).format('DD MMM YY');
    return date !== DayJs.INVALID_DATE && date;
  };

  const getImageName = (imageUrl) => {
    const fileName = imageUrl.split('/').pop();
    const rawName = fileName.replace('compressed_', '');
    return rawName;
  };

  useEffect(() => {
    if (isEmpty(damageDetails) || isEmpty(damages)) {
      return;
    }

    const currentDamageData = Object.values(damages).find(
      // 'Damage ID' is an int and 'damageId' is a string
      // making them both strings no matter what for comparison's sake
      (damage) => damage['Damage ID'].toString() === damageParams.damageId.toString(),
    );

    setRawImageUrl(damageDetails.signedUrls[0].url);
    setAnnotationImageUrl(damageDetails.signedUrls[1].url);
    if (damageDetails.reportJsonMetadata['REPORT DETAILS']) {
      setSummary(damageDetails.reportJsonMetadata['REPORT DETAILS']['Summary/Comments']);
    }
    setBladeSerialNUmber(currentDamageData['Blade Serial #']);
    setAxisNumber(
      currentDamageData['Axis #'] <= 0 ? t('unknown', 'UNKNOWN') : currentDamageData['Axis #'],
    );
    setLocation(currentDamageData['Location']);
    setDamageID(currentDamageData['Damage ID']);
    setStatus(currentDamageData['Status']);
    setComponent(currentDamageData['Component']);
    setDateIdentified(getDate(currentDamageData['Date Identified']));
    setRequiredDate(getDate(currentDamageData['Required Date']));
    setInspectionId(currentDamageData['Inspection Id']);
    setAssetSiteId(currentDamageData['Asset Site Id']);
    setFamily(damageDetails.annotationResult.data.attributes.family);
    setSubFamily(damageDetails.annotationResult.data.attributes.sub_family);
    setDamage(damageDetails.annotationResult.data.attributes.family_desc);
    setCategory(damageDetails.annotationResult.data.attributes.severity);
    setFollowUp(damageDetails.annotationResult.data.attributes.source);
    setAction(damageDetails.annotationResult.data.attributes.action);
    setProbableCause(damageDetails.annotationResult.data.attributes.root_cause);
    setSpanZ(damageDetails.annotationResult.data.attributes.z);
    setChord(damageDetails.annotationResult.data.attributes.chord);
    setWidth(damageDetails.annotationResult.data.attributes.width);
    setLength(damageDetails.annotationResult.data.attributes.length);
    setImageId(damageDetails.annotationResult.data.attributes.object_id);
    setAnnotationComment(damageDetails.annotationResult.data.attributes.comments);
    if (damageDetails.annotationResult.data.attributes.image_enhancement_metadata) {
      setEnhancementMetadata(
        damageDetails.annotationResult.data.attributes.image_enhancement_metadata,
      );
    }
    setImageName(getImageName(damageDetails.signedUrls[0].file_name));
  }, [damageDetails, damageParams.damageId, damages]);

  const {
    weight = 1,
    gamma = 1,
    brightness = 0,
    contrast = 1,
    invert = 0,
    grayscale = 0,
  } = enhancementMetada;

  useEffect(() => {
    if (damageParams) {
      if (isEmpty(damages)) {
        fetchDamagesResultTable({ globalFilters, sitesData });
      }
      getDamageDetailsById(damageParams['damageId']).then((result) => {
        setDamageDetails(result);
      });
    }
  }, [getDamageDetailsById, damages]);

  useEffect(() => {
    if (timerId) {
      return () => clearTimeout(timerId);
    }
  }, [timerId]);

  const damageData = useMemo(() => {
    const damageValues1 = [
      {
        access: 'damage_data.family',
        label: 'Part',
        value: family,
      },
      {
        access: 'damage_data.subFamily',
        label: 'Sub Part',
        value: subFamily,
      },
      {
        access: 'damage_data.damage',
        label: 'Damage Type',
        value: damage,
      },
      {
        access: 'damage_data.category',
        label: 'Category',
        value: category,
      },
      {
        access: 'damage_data.probableCause',
        label: 'Probable Cause',
        value: probableCause,
      },
      {
        access: 'damage_data.action',
        label: 'Action',
        value: action,
      },
      {
        access: 'damage_data.followUp',
        label: 'Follow-up',
        value: followUp,
      },
      {
        access: 'damage_data.spanZ',
        label: 'Span Z (m)',
        value: spanZ,
      },
    ];
    const damageValues2 = [
      {
        access: 'damage_data.annotationComment',
        label: 'Comment',
        value: annotationComment,
      },
    ];
    return [
      ...damageValues1,
      {
        access: 'damage_data.width',
        label: 'Size SW (mm)',
        value: width,
      },
      {
        access: 'damage_data.length',
        label: 'Size CW (mm)',
        value: length,
      },
      {
        access: 'damage_data.chord',
        label: 'Chord Y(%)',
        value: chord,
      },
      ...damageValues2,
    ];
  }, [
    action,
    annotationComment,
    category,
    damage,
    family,
    followUp,
    length,
    probableCause,
    spanZ,
    subFamily,
    width,
    InspectionTypes,
  ]);

  const clearEventListener = () => {
    window.removeEventListener('resize', handleResize);
  };

  const handleResize = () => {
    if (damageParams.isPopOut) {
      setImageComponenetHeight((originalImageHeight / originalImageWidth) * window.innerWidth);
    }
    clearEventListener();
  };

  useEffect(() => {
    if (originalImageHeight && originalImageWidth) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    if (imageId !== null) {
      const getImageDetails = async () => {
        const data = {
          imageId,
        };
        const { attributes } = await fetchImageDetails(data, {});
        setOriginalImageHeight(attributes.height);
        setOriginalImageWidth(attributes.width);
        const { width: w, height: h } = attributes;
        setImageComponenetHeight((h / w) * 780);
      };
      getImageDetails();
    }
  }, [imageId, fetchImageDetails, damage]);

  if (isEmpty(damages)) {
    if (!damageParams.isPopOut) {
      const timeOutId = setTimeout(function () {
        hideDetails();
        setTimerId(timeOutId);
      }, 1000);
    }
    return <Loader />;
  }
  const handleImageClick = () => {
    if (imageId && damageParams.damageId) {
      window.open(`/inspect/image-viewer/${imageId}/${damageParams.damageId}`);
    }
  };
  if (!(ready && damageDetails)) {
    return <Loader />;
  }

  const damageLabels = [
    {
      label: 'Damage ID',
      access: 'damageId',
    },
    {
      label: 'Status',
      access: 'status',
    },
    { label: 'Component', access: 'component', key: 'component' },
    {
      label: 'Date Identified',
      access: 'dateIdentified',
    },
    { label: 'Required Date', access: 'requiredDate' },
  ];
  const damageLabelValues = {
    damageId: damageID,
    status,
    component,
    dateIdentified,
    requiredDate,
  };
  const handleTabOnClick = (tabName) => {
    if (tabName === 'integrations') {
      setShowBladeDetails(false);
    } else {
      setShowBladeDetails(true);
    }
  };

  return (
    <StyledDetailContainer>
      <DamageDetailsHeader damageDetails={damageDetails} assetSiteId={assetSiteId} />
      <StyledEntityCollapsiblePanel
        headerContent={
          <CollapsiblePanelHeading>
            {t('summary_or_comments', 'Report Summary/Comments')}
          </CollapsiblePanelHeading>
        }
      >
        <StyledSummary>{summary}</StyledSummary>
      </StyledEntityCollapsiblePanel>
      {showBladeDetails ? (
        <BladeDetailContainer>
          <BladeDetail>
            <BladeDetailLabel>{t('blade', 'Blade')}</BladeDetailLabel>
            <BladeDetailValue>{bladeSerialNumber}</BladeDetailValue>
          </BladeDetail>
          <BladeDetail>
            <BladeDetailLabel>{t('axis', 'Axis')}</BladeDetailLabel>
            <BladeDetailValue>{axisNumber}</BladeDetailValue>
          </BladeDetail>
          <BladeDetail>
            <BladeDetailLabel>{t('imageName', 'Image Name')}</BladeDetailLabel>
            <Tooltip title={imageName} zIndex={elevations.P20} placement="top">
              <BladeDetailValue>{imageName}</BladeDetailValue>
            </Tooltip>
          </BladeDetail>
          <BladeDetail>
            <BladeDetailLabel>{t('location', 'Location')}</BladeDetailLabel>
            <BladeDetailValue>{location}</BladeDetailValue>
          </BladeDetail>
          <AuthRender
            capability={Capability.REI_RESULTS}
            edit
            componentssss="results page"
            enforceView
            description="export damages"
            siteLevel={false}
          >
            <ImageViewerButton
              onClick={() => {
                window.open(
                  `/inspect/post-process/${inspectionId}?imgId=${imageId}&annoId=${damageParams.damageId}`,
                  '_blank',
                );
              }}
            >
              <ImageViewerLinkIcon icon={Icons.NOTE} />
            </ImageViewerButton>
          </AuthRender>
          <ImageViewerButton onClick={() => handleImageClick()}>
            <ImageViewerLinkIcon icon={Icons.FULL_SCREEN} />
          </ImageViewerButton>
        </BladeDetailContainer>
      ) : null}
      <ScrollingContainer autoHide={false}>
        <SectionContainer>
          <StyledTabs small onChangeHandler={(e) => handleTabOnClick(e)}>
            <Tab
              capabilities={[
                { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
              ]}
              label={t('tabs.overview', 'Overview')}
            >
              <ScrollingContainerContent>
                {imageComponentHeight ? (
                  <div style={{ height: imageComponentHeight }}>
                    <svg width="100%" height="100%">
                      <defs>
                        <filter
                          id={`filterStack_${damageID}`}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                        >
                          <feColorMatrix
                            in="SourceGraphic"
                            result="grayscaleResult"
                            type="matrix"
                            values={`
            ${0.2126 + 0.7874 * (1 - grayscale)} ${0.7152 - 0.7152 * (1 - grayscale)} ${
                              0.0722 - 0.0722 * (1 - grayscale)
                            } 0 0
            ${0.2126 - 0.2126 * (1 - grayscale)} ${0.7152 + 0.2848 * (1 - grayscale)} ${
                              0.0722 - 0.0722 * (1 - grayscale)
                            } 0 0
            ${0.2126 - 0.2126 * (1 - grayscale)} ${0.7152 - 0.7152 * (1 - grayscale)} ${
                              0.0722 + 0.9278 * (1 - grayscale)
                            } 0 0
            0 0 0 1 0`}
                          />
                          <feColorMatrix
                            type="matrix"
                            colorInterpolationFilters="sRGB"
                            in="grayscaleResult"
                            result="invertResult"
                            values={`${1 - 2 * invert} 0 0 0 ${invert}
                      0 ${1 - 2 * invert} 0 0 ${invert}
                      0 0 ${1 - 2 * invert} 0 ${invert}
                      0 0 0 1 0`}
                          />
                          <feComponentTransfer in="invertResult" result="brightnessResult">
                            <feFuncR type="linear" slope={1} intercept={brightness} />
                            <feFuncG type="linear" slope={1} intercept={brightness} />
                            <feFuncB type="linear" slope={1} intercept={brightness} />
                          </feComponentTransfer>
                          <feComponentTransfer in="brightnessResult" result="contrastResult">
                            <feFuncR
                              type="linear"
                              slope={contrast}
                              intercept={-(0.5 * contrast) + 0.5}
                            />
                            <feFuncG
                              type="linear"
                              slope={contrast}
                              intercept={-(0.5 * contrast) + 0.5}
                            />
                            <feFuncB
                              type="linear"
                              slope={contrast}
                              intercept={-(0.5 * contrast) + 0.5}
                            />
                          </feComponentTransfer>
                          <feComponentTransfer in="contrastResult" result="gammaResult">
                            <feFuncR type="gamma" amplitude={1} exponent={gamma} offset="0" />
                            <feFuncG type="gamma" amplitude={1} exponent={gamma} offset="0" />
                            <feFuncB type="gamma" amplitude={1} exponent={gamma} offset="0" />
                          </feComponentTransfer>
                          <feGaussianBlur
                            id="f_gaussianBlur"
                            in="gammaResult"
                            result="blurResult"
                            stdDeviation={weight === 1 || weight === 0 ? 1 : 13}
                          />
                          <feComposite
                            id="f_composite"
                            operator="arithmetic"
                            k1="0"
                            k2={weight}
                            k3={1 - weight}
                            k4="0"
                            in="gammaResult"
                            in2="blurResult"
                          />
                        </filter>
                        <filter
                          id={`annotation_filter_${damageID}`}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                        >
                          <feColorMatrix
                            in="SourceGraphic"
                            result="grayscaleResult"
                            type="matrix"
                            values="
                1 0 0 0 0
                0 1 0 0 0
                0 0 1 0 0
                0 0 0 1 0"
                          ></feColorMatrix>
                          <feComponentTransfer in="grayscaleResult" result="invertResult">
                            <feFuncR type="table" tableValues="0 1"></feFuncR>
                            <feFuncG type="table" tableValues="0 1"></feFuncG>
                            <feFuncB type="table" tableValues="0 1"></feFuncB>
                          </feComponentTransfer>
                          <feComponentTransfer in="invertResult" result="brightnessResult">
                            <feFuncR type="linear" slope="3"></feFuncR>
                            <feFuncG type="linear" slope="3"></feFuncG>
                            <feFuncB type="linear" slope="3"></feFuncB>
                          </feComponentTransfer>
                          <feComponentTransfer in="brightnessResult" result="contrastResult">
                            <feFuncR
                              type="linear"
                              slope="3"
                              intercept="-0.15000000000000002"
                            ></feFuncR>
                            <feFuncG
                              type="linear"
                              slope="1.3"
                              intercept="-0.15000000000000002"
                            ></feFuncG>
                            <feFuncB
                              type="linear"
                              slope="1.3"
                              intercept="-0.15000000000000002"
                            ></feFuncB>
                          </feComponentTransfer>
                          <feComponentTransfer in="contrastResult" result="gammaResult">
                            <feFuncR type="gamma" amplitude="1" exponent="3" offset="0"></feFuncR>
                            <feFuncG type="gamma" amplitude="1" exponent="3" offset="0"></feFuncG>
                            <feFuncB type="gamma" amplitude="1" exponent="3" offset="0"></feFuncB>
                          </feComponentTransfer>
                          <feGaussianBlur
                            id="f_gaussianBlur"
                            in="gammaResult"
                            result="blurResult"
                            stdDeviation="7"
                          ></feGaussianBlur>
                          <feComposite
                            id="f_composite"
                            operator="arithmetic"
                            k1="0"
                            k2="5"
                            k3="-4"
                            k4="0"
                            in="gammaResult"
                            in2="blurResult"
                          ></feComposite>
                        </filter>
                      </defs>
                      <RawImage
                        xlinkHref={rawImageUrl}
                        width="100%"
                        alt="original image"
                        filter={`url(#filterStack_${damageID})`}
                      />
                      <AnnotationImage
                        xlinkHref={annotationImageUrl}
                        width="100%"
                        alt="annotation image"
                        filter={`url(#annotation_filter_${damageID})`}
                        onClick={() => handleImageClick()}
                      />
                    </svg>
                  </div>
                ) : (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                )}

                <DamageDetailsContainer>
                  <DamageDetailBoxLabelsValues>
                    {damageLabels.map((label) => (
                      <div key={label.access}>
                        <DamageFieldsLabel>
                          {t(`damage_labels.${label.access}`, label.label)}
                        </DamageFieldsLabel>
                        <DamageFieldsValue>{damageLabelValues[label.access]}</DamageFieldsValue>
                      </div>
                    ))}
                  </DamageDetailBoxLabelsValues>

                  <DamageDetailDescription>
                    {damageData.map((dData) => {
                      return (
                        <ReportDamage key={dData.access}>
                          <ReportDamageFieldsLabel>
                            {t(dData.access, dData.label)}
                          </ReportDamageFieldsLabel>
                          <ReportDamageFieldsValue>{dData.value}</ReportDamageFieldsValue>
                        </ReportDamage>
                      );
                    })}
                  </DamageDetailDescription>
                </DamageDetailsContainer>
              </ScrollingContainerContent>
            </Tab>
            <Tab
              capabilities={[
                { capability: Capability.REI_RESULTS, scopes: [PermissionScope.VIEW] },
              ]}
              authStrategy={AuthStrategy.ANY}
              label={t('tabs.integrations', 'Integrations')}
            >
              <PacIntegrationTable damageDetails={damageDetails} />
            </Tab>
          </StyledTabs>
        </SectionContainer>
      </ScrollingContainer>
    </StyledDetailContainer>
  );
};

DamageDetails.propTypes = {
  damageParams: PropTypes.object,
};

export default DamageDetails;
