import { ChartType } from './chart-type';

export const columnChartOptions = {
  series: [
    {
      // setting to 100 per discussion with dave le
      maxPointWidth: 100,
      type: ChartType.COLUMN,
    },
  ],
  xAxis: {
    tickmarkPlacement: 'on',
  },
};
