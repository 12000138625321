import { PropTypes } from 'prop-types';
import React, { forwardRef, Fragment } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { NotificationActionType, NotificationType } from '@ge/models/constants';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { killEventPropagation } from '@ge/shared/util';

const ACTION_TYPES = Object.values(NotificationActionType);

const ActionContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme?.notification?.textColor};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 3px;
  &:not(:first-child):not(:last-child) > * {
    + * {
      margin-left: 5px;
    }
  }
  .action-label {
    margin-left: 4px;
    margin-right: 5px;
  }
`;

// might need to account for overflow in here
const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

const Container = styled(DetailsDismissInert)`
  align-items: stretch;
  background: ${({ theme }) => theme?.notification?.background};
  border: 3px solid ${({ theme }) => theme?.notification?.background};
  box-sizing: border-box;
  color: ${({ theme }) => theme?.notification?.textColor};
  display: flex;
  flex-flow: row nowrap;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: space-between;
  padding: 9px 23px;
  white-space: pre;
  &.${NotificationType.ERROR} {
    border: 3px solid ${({ theme }) => theme?.notification?.borderError};
  }
  &.${NotificationType.SUCCESS} {
    border: 3px solid ${({ theme }) => theme?.notification?.borderSuccess};
  }
  &.${NotificationType.FAILURE} {
    background: ${({ theme }) => theme?.notification?.failureBackground};
    border: 3px solid ${({ theme }) => theme?.notification?.failureBackground};
  }
  .center {
    align-items: center;
    display: flex;
    flex: 0;
    flex-flow: row nowrap;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 2px;
  }
  .close-button {
    margin-left: 18px;
  }
  .left {
    align-items: center;
    display: flex;
    flex: 1 0;
    flex-flow: row nowrap;
    justify-content: flex-start;
    > * {
      + * {
        margin-left: 3px;
      }
    }
  }
  .message {
    padding-top: 1px;
    font-size: 13px;
  }
  .right {
    align-items: center;
    display: flex;
    flex: 1 0;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
  .status {
    font-size: 11px;
    padding-top: 1px;
  }
  .timestamp {
    padding-top: 1px;
    font-size: 12px;
  }
`;

const NotificationButton = styled.button.attrs(() => ({
  type: 'button',
}))``;

const NotificationIcon = styled(Icon).attrs(({ icon, theme }) => ({
  color: theme?.notification?.textColor,
  icon,
  size: 10,
}))`
  display: block;
`;

const ActionIcon = ({ type }) => {
  switch (type) {
    case NotificationActionType.SITE_DETAILS:
      return <NotificationIcon icon={Icons.SITE} />;
    case NotificationActionType.TURBINE_DETAILS:
      return <NotificationIcon icon={Icons.TURBINE} />;
    case NotificationActionType.EVENT_DETAILS:
      return <NotificationIcon icon={Icons.EVENT} />;
    default:
      return null;
  }
};

ActionIcon.propTypes = {
  type: PropTypes.oneOf(ACTION_TYPES).isRequired,
};

// TODO: refactor actions so they take icon and just include an click handler
// so they don't need to know so much about how they'll be used
const Action = ({ label, onClick, type }) => {
  const handleClick = (event) => {
    killEventPropagation(event);

    onClick(event);
  };

  return (
    <NotificationButton onClick={handleClick}>
      <ActionContainer>
        <ActionIcon type={type} />
        <div className="action-label">{label}</div>
        <NotificationIcon icon={Icons.CHEVRON} rotate={-90} />
      </ActionContainer>
    </NotificationButton>
  );
};

Action.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(ACTION_TYPES).isRequired,
};

Action.defaultProps = {
  onClick: () => {},
};

// wanted to make this generic enough for multiple uses
// can revisit how successful that was and refactor as needed
export const Notification = forwardRef(
  ({ actions, id, message, onDismiss, status, type, timestamp }, ref) => {
    // const timestamp = getFriendlyTimestamp();

    const handleClose = (event) => {
      killEventPropagation(event);

      onDismiss(event);
    };

    return (
      <Container className={type} id={id} ref={ref}>
        <div className="left">
          {actions?.length > 0 && (
            <ActionsContainer>
              {actions?.map(({ label, onClick, type }, i) => (
                <Fragment key={`${type}_${label}`}>
                  <Action label={label} onClick={onClick} type={type} />
                  {actions.length > 1 && i < actions.length - 1 ? '/' : null}
                </Fragment>
              ))}
            </ActionsContainer>
          )}
          {status && <div className="status">{status}</div>}
        </div>
        <div className="center">
          <div className="message">{message}</div>
        </div>
        <div className="right">
          {/* // Removing time stamp for this release */}
          {timestamp && <div className="timestamp">{timestamp}</div>}
          <NotificationButton className="close-button" onClick={handleClose}>
            <NotificationIcon icon={Icons.CLOSE} />
          </NotificationButton>
        </div>
      </Container>
    );
  },
);

Notification.displayName = 'Notification';

Notification.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(ACTION_TYPES).isRequired,
    }),
  ),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onDismiss: PropTypes.func,
  status: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.string,
  timestamp: PropTypes.string,
};

Notification.defaultProps = {
  actions: undefined,
  children: undefined,
  message: undefined,
  onDismiss: undefined,
  status: undefined,
  type: NotificationType.INFO,
};
