import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';

const ContactWrapper = styled.section`
  align-self: flex-start;
  border-radius: 3px;
  background: ${(props) => props.theme.entityDetails.tickets.background};
  width: 200px;
  margin-right: 14px;
  .contact-title {
    border-bottom: solid 1px ${(props) => props.theme.entityDetails.tickets.borderColor};
    padding: 14px 18px;
    font-size: 13px;
  }
  .contact {
    padding: 14px 18px;
  }
  .button-group {
    margin-top: 16px;
    button {
      margin-right: 10px;
    }
  }
`;

const PhoneIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tickets.iconColor,
  icon: Icons.PHONE,
  size: 14,
}))``;

const EmailIcon = styled(Icon).attrs((props) => ({
  color: props.theme.entityDetails.tickets.iconColor,
  icon: Icons.EMAIL,
  size: 14,
}))``;

const SiteContact = ({ contact }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  if (!contact) {
    return null;
  }

  if (!ready) {
    return <Loader />;
  }

  return (
    <ContactWrapper>
      <div className="contact-title">{t('ticket_entity.site_contact', 'SITE CONTACT')}</div>
      <div className="contact">
        <h4>{contact.name}</h4>
        <h5>{contact.title}</h5>
        <div className="button-group">
          <Button type="text">
            <PhoneIcon />
          </Button>
          <Button type="text">
            <EmailIcon />
          </Button>
        </div>
      </div>
    </ContactWrapper>
  );
};

SiteContact.propTypes = {
  contact: PropTypes.instanceOf(Object).isRequired,
};

export default SiteContact;
