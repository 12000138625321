import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0px 20px 15px;
  color: ${({ theme, isError }) => (isError ? theme.layout.errorColor2 : '')};
`;

export const FormErrorMessage = ({ className, isError, message }) => {
  return message ? (
    <Container isError={isError} className={className}>
      {message}
    </Container>
  ) : null;
};

FormErrorMessage.propTypes = {
  className: PropTypes.string,
  isError: PropTypes.bool,
  message: PropTypes.string,
};

FormErrorMessage.defaultValues = {
  className: '',
  isError: false,
};

export default FormErrorMessage;
