import { TableFilterTypes } from '@ge/models/constants';

export const PersonHistoryColumns = {
  GROUP_ACTION: 'group-action',
  ACTION: 'action',

  GROUP_TYPE: 'group-type',
  TYPE: 'type',

  GROUP_SERVICE_GROUP: 'group-service-group',
  SERVICE_GROUP: 'serviceGroup',

  GROUP_CHANGED_BY: 'group-changed-by',
  CHANGED_BY: 'changedBy',

  GROUP_ACTION_TIME: 'group-action-time',
  ACTION_TIME: 'actionTime',
};

// Define all available columns in the people table.
export const PersonHistoryColumnDefs = {
  [PersonHistoryColumns.GROUP_ACTION]: {
    cols: {
      [PersonHistoryColumns.ACTION]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.action',
            a11yDefault: 'Action',
            sortValue: 'action',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PersonHistoryColumns.GROUP_TYPE]: {
    cols: {
      [PersonHistoryColumns.TYPE]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.type',
            a11yDefault: 'Role',
            sortValue: 'type',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PersonHistoryColumns.GROUP_SERVICE_GROUP]: {
    cols: {
      [PersonHistoryColumns.SERVICE_GROUP]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.service-group',
            a11yDefault: 'Site / Service Group ID',
            sortValue: 'serviceGroup',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PersonHistoryColumns.GROUP_CHANGED_BY]: {
    cols: {
      [PersonHistoryColumns.CHANGED_BY]: {
        filterType: TableFilterTypes.SEARCH,
        labels: [
          {
            a11yKey: 'table.changed-by',
            a11yDefault: 'Changed By',
            sortValue: 'changedBy',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },

  [PersonHistoryColumns.GROUP_ACTION_TIME]: {
    cols: {
      [PersonHistoryColumns.ACTION_TIME]: {
        width: '100px',
        labels: [
          {
            a11yKey: 'table.action-ime',
            a11yDefault: 'Date & Time (EST)',
            sortValue: 'actionTime',
          },
        ],
        cell: {
          align: 'left',
        },
      },
    },
  },
};

export const globalPersonHistoryCols = [
  {
    id: PersonHistoryColumns.GROUP_ACTION,
    cols: [
      {
        id: PersonHistoryColumns.ACTION,
        visible: true,
      },
    ],
  },
  {
    id: PersonHistoryColumns.GROUP_TYPE,
    cols: [
      {
        id: PersonHistoryColumns.TYPE,
        visible: true,
      },
    ],
  },
  {
    id: PersonHistoryColumns.GROUP_SERVICE_GROUP,
    cols: [
      {
        id: PersonHistoryColumns.SERVICE_GROUP,
        visible: true,
      },
    ],
  },
  {
    id: PersonHistoryColumns.GROUP_CHANGED_BY,
    cols: [
      {
        id: PersonHistoryColumns.CHANGED_BY,
        visible: true,
      },
    ],
  },
  {
    id: PersonHistoryColumns.GROUP_ACTION_TIME,
    cols: [
      {
        id: PersonHistoryColumns.ACTION_TIME,
        visible: true,
      },
    ],
  },
];
