import * as request from '@ge/shared/services/api';

const FormData = require('form-data');

const CMN_URLS = {
  BASE: process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API,
  REPORTS: '/cmn/reports/reports',
  SEND_REPORT: '/cmn/reports/send',
  TEMPLATE: '/cmn/reports/template/',
  WIDGET_DATA: '/cmn/reports/widget-data',
};

/**
 * Fetch data for sent report list display
 */
export const fetchSentReportListData = ({
  pageIdx,
  pageSize,
  sortMetric,
  sortDirection,
  name,
  createdBy,
  reportType,
  scopeType,
  scope,
}) => {
  const params = {
    pageIdx,
    pageSize,
    orderDirection: sortDirection,
    sortKey: sortMetric,
    ...(name && { name }),
    ...(createdBy && { createdBy }),
    ...(reportType && { reportType }),
    ...(scope && {
      scopeType,
      scope,
    }),
  };
  return request.get(CMN_URLS.REPORTS, { baseURL: CMN_URLS.BASE, params });
};

/**
 * Fetch data for report template dashboard display
 */
export const fetchReportTemplateDashboardData = ({
  pageIdx,
  pageSize,
  sortMetric,
  sortDirection,
}) => {
  const params = {
    pageIdx,
    pageSize,
    ...(sortDirection && { orderDirection: sortDirection }),
    ...(sortMetric && { sortKey: sortMetric }),
  };
  return request.get(CMN_URLS.TEMPLATE, { baseURL: CMN_URLS.BASE, params });
};

/**
 * Fetch report template by template type
 */
export const fetchReportByTemplateId = (templateId) =>
  request.get(CMN_URLS.TEMPLATE + templateId, { baseURL: CMN_URLS.BASE });

/**
 * Create a new template from base template
 */
export const createTemplate = ({
  name,
  description,
  scope,
  scopeType,
  templateType,
  timeRange,
  baseTemplateId,
  distributionList,
}) => {
  const data = {
    name,
    description,
    scope,
    scopeType,
    templateType,
    timeRange,
    baseTemplateId,
    distributionList,
  };

  return request.post(CMN_URLS.TEMPLATE, data, { baseURL: CMN_URLS.BASE });
};

/**
 * Update user template
 */
export const updateTemplate = ({
  templateId,
  name,
  description,
  scope,
  scopeType,
  templateType,
  timeRange,
  baseTemplateId,
  distributionList,
}) => {
  const data = {
    templateId,
    name,
    description,
    scope,
    scopeType,
    templateType,
    timeRange,
    baseTemplateId,
    distributionList,
  };

  return request.put(CMN_URLS.TEMPLATE + templateId, data, { baseURL: CMN_URLS.BASE });
};

/**
 * Update user template configuration
 */
export const updateTemplateConfig = ({ templateId, patchOps }) => {
  const body = { templateId, patchOps };
  return request.patch(CMN_URLS.TEMPLATE + templateId, body, { baseURL: CMN_URLS.BASE });
};

/**
 * Fetch widget report data
 */
export const fetchWidgetData = async ({ startDate, endDate, serviceUrls, siteIds } = {}) => {
  const { segmentTokens } = await request.getSegmentTokens();
  return request.post(
    CMN_URLS.WIDGET_DATA,
    {
      segmentTokens,
      siteIds,
    },
    {
      baseURL: CMN_URLS.BASE,
      params: {
        startDate,
        endDate,
        serviceUrls: serviceUrls.map((url) => encodeURIComponent(url)).join(','),
      },
    },
  );
};

const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

const getFilenameForReportPdf = (json) => {
  // NOTE: Filename should be in this format: scope-pod-date.pdf or scope-eod-date.pdf
  const { scope, reportType, dateSent } = json;
  return `${scope?.toLowerCase()}-${reportType?.toLowerCase()}-${dateSent}.pdf`;
};

const getFormDataAndHeader = ({ json, attachments, reportFile }) => {
  const formData = new FormData();
  formData.append('json', JSON.stringify(json));

  const reportFileName = getFilenameForReportPdf(json);

  // NOTE: The BFF expects the reportFile to be parsed out of the request before the attachments.
  formData.append('reportFile', reportFile, reportFileName);

  if (attachments) {
    for (const attachment of attachments) {
      formData.append('attachments', attachment.file, attachment.id);
    }
  }

  return { body: formData };
};

export const postNewReport = async (json, attachments, reportFile) => {
  const fileData = dataURItoBlob(reportFile);

  const { body } = getFormDataAndHeader({ json, attachments, reportFile: fileData });

  return request.post(CMN_URLS.REPORTS, body, { baseURL: CMN_URLS.BASE });
};

export const sendReport = (reportId, distributionList) => {
  const data = { distributionList, reportId };

  return request.post(CMN_URLS.SEND_REPORT, data, { baseURL: CMN_URLS.BASE });
};
