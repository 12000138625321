import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Select } from '@ge/components/select';
import { Pattern, PersonCreateType, PersonType } from '@ge/models/constants';
import { AppScopes } from '@ge/shared/models/scopes';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors/colors';
import { globalColors } from '@ge/tokens/colors/colors';
import { findScopesByRoute } from '@ge/web-client/src/app/services/feature';

import { useLookupPerson } from '../hooks/use-lookup-person';

const InputWrapper = styled.div`
  flex: 1;
  margin-right: 15px;
  input {
    ${({ errors, id }) => (errors?.[id] ? `border-color: ${StatusColor.DANGER}` : '')};
  }

  input:read-only {
    background-color: ${(props) => props.theme.input.background} !important;
  }

  span.select-label {
    display: block;
    margin: 0 0 5px 0;
    font-size: 11px;
    line-height: 13px;
    color: ${(props) => props.theme.input.labelTextColor};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  span {
    font-weight: ${typography.weight.bold};
    margin-top: 1px;
    text-transform: uppercase;
  }

  &.read-only {
    > div {
      opacity: 0.6;
    }
  }
`;

const FormHeader = styled.div`
  position: relative;
  margin: 15px 0 0;
  width: 100%;
  height: auto;
  font-size: 14px;
  line-height: 13px;
  color: ${(props) => props.theme.input.labelTextColor};
`;

const AddDetailButton = styled.button`
  align-items: center;
  color: ${(props) => props.theme.admin.panel.body.detailButton};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.bold};
  text-transform: uppercase;
`;

const ButtonIcon = styled(Icon).attrs((props) => ({
  icon: Icons.ADD,
  size: 8,
  color: props.theme.admin.panel.body.detailButton,
}))`
  margin: 0 5px 2px 0;
`;

const FieldError = styled.div`
  color: ${StatusColor.DANGER};
  font-weight: ${typography.weight.bold};
  margin-top: 5px;
`;

const LookupError = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  color: ${StatusColor.DANGER};
  font-weight: ${typography.weight.bold};
  margin-top: 10px;
`;

const StyledSelect = styled(Select)`
  label {
    color: ${globalColors.grey4};
  }
  .select__control {
    padding: 1.5px 0;
  }
`;

// const formatPhone = (number) => {
//   const phoneNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
//   return phoneNumber;
// };

const jobTitleOptions = ['Site_Manager', 'Site_Lead', 'Technician', 'Contractor', 'Other'];

const PersonAddDetails = ({
  className,
  disableButtons,
  personType,
  setPersonType,
  person,
  setSteps,
  setPersonAttributes,
  setPersonIsFound,
  foundPerson,
  setFoundPerson,
}) => {
  const { t } = useTranslation(['admin.people', 'general'], { useSuspense: false });
  const { control, errors, register, setValue, watch } = useFormContext();
  const [lookupValue, setLookupValue] = useState('');
  const [jobSelect, setJobSelect] = useState({
    value: '',
    label: t('create.person.select_title', 'Select a Job Title'),
  });
  const [inputs, setInputs] = useState({
    email: '',
    firstName: '',
    lastName: '',
    functionalSSO: '',
    phone: '',
    initials: '',
    title: '',
  });

  const watchUser = watch('user');
  const watchWorker = watch('worker');

  const validatedNumber = setValue('validated', 0);
  const personFound = foundPerson !== null && foundPerson.length !== 0;
  const personNotFound = foundPerson !== null && foundPerson.length === 0;

  const path = useLocation().pathname;
  const appScope = findScopesByRoute(path);
  const scopePathOverview = AppScopes.MANAGE_OVERVIEW;

  const { data: lookedUpPerson, refetch, status } = useLookupPerson(lookupValue);
  const { limitedUserRoles: mappedLimitedRoles } = useStoreState((state) => state.userRole);

  const handleJobSelect = useCallback(
    (e) => {
      setJobSelect({
        value: e.value,
        label: t(e.value, e.value.replace(/[_-]/g, ' ')),
      });
      setValue('jobTitle', e.value);
    },
    [setValue, t],
  );

  const handleLookupClick = useCallback(() => {
    refetch();
  }, [refetch]);

  if (status === PersonCreateType.SUCCESS && lookedUpPerson) {
    setFoundPerson(lookedUpPerson);
    if (lookedUpPerson.attributes?.entity_group) {
      setPersonAttributes({
        ...lookedUpPerson.attributes,
        entity_group: lookedUpPerson.attributes.entity_group.filter((role) =>
          mappedLimitedRoles?.some((item) => item.a11yKey === role.roleName),
        ),
      });
    } else {
      setPersonAttributes(lookedUpPerson.attributes);
    }
    setInputs({
      email: lookedUpPerson.email,
      firstName: lookedUpPerson.firstName,
      lastName: lookedUpPerson.lastName,
      functionalSSO: lookedUpPerson.functionalSSO,
      phone: lookedUpPerson.phoneNumber,
      initials: lookedUpPerson.initials,
    });
    if (lookedUpPerson.title) {
      setJobSelect({
        value: lookedUpPerson.title,
        label: t(lookedUpPerson.title, lookedUpPerson.title.replace(/[_-]/g, ' ')),
      });
      setValue('jobTitle', lookedUpPerson.title);
    }
    if (lookedUpPerson.functionalSSO) {
      setValue('functionalSSO', lookedUpPerson.functionalSSO);
    }
    setLookupValue('');
    setPersonIsFound(true);
  }

  useEffect(() => {
    if (personType === PersonType.WORKER && foundPerson?.type?.includes(PersonCreateType.USER)) {
      setPersonType(PersonType.USER_AND_WORKER);
      setValue(PersonType.USER, true);
    }

    if (personType === PersonType.USER && foundPerson?.type?.includes(PersonCreateType.WORKER)) {
      setPersonType(PersonType.USER_AND_WORKER);
      setValue(PersonType.WORKER, true);
    }
  }, [foundPerson, person, personType, setPersonType, setSteps, setValue]);

  const handleInputChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const checkIfEmail = (str) => {
    // Regular expression to check if string is email
    const regexExp = Pattern.EMAIL;
    return regexExp.test(str);
  };

  return (
    <div className={className}>
      <div className="form-row">
        <div className="person-label">{t('create.person_type', 'Check if this person is a')}:</div>
        <div className="worker-wrapper">
          <Controller
            control={control}
            defaultValue={appScope === scopePathOverview ? true : false}
            name="worker"
            render={({ value }) => (
              <StyledCheckbox
                label={t('create.worker', 'Worker')}
                className={foundPerson?.type?.includes('Worker') ? 'read-only' : null}
                checkState={
                  foundPerson?.type?.includes('Worker')
                    ? CheckedState.CHECKED
                    : value
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                onChange={(e) => {
                  setValue('worker', e);
                }}
                value={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                disabled={appScope === scopePathOverview ? true : false}
                readOnly={foundPerson?.type?.includes('Worker') ? true : false}
              />
            )}
          />
          <span className="detail">
            {t('create.worker_detail', 'Can be scheduled for work on a site (i.e. Technician)')}
          </span>
        </div>
        <div className="user-wrapper">
          <Controller
            control={control}
            defaultValue={false}
            name="user"
            render={({ value }) => (
              <StyledCheckbox
                label={t('create.user', 'User')}
                className={foundPerson?.type?.includes('User') ? 'read-only' : null}
                checkState={
                  foundPerson?.type?.includes('User')
                    ? CheckedState.CHECKED
                    : value
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                onChange={(e) => {
                  setValue('user', e);
                }}
                value={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                readOnly={foundPerson?.type?.includes('User') ? true : false}
              />
            )}
          />
          <span className="detail">
            {t('create.user_detail', 'Needs access to software systems')}
          </span>
        </div>
      </div>

      {!watchUser && !watchWorker && (
        <>
          <div className="form-row">
            <InputWrapper errors={errors} id="email">
              <Controller
                control={control}
                defaultValue=""
                name="email"
                rules={{
                  required: true,
                  pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                }}
                render={({ onChange, value }) => (
                  <Input
                    label={t('create.email', 'Email')}
                    onChange={onChange}
                    required
                    type="email"
                    value={value}
                  />
                )}
              />
              {errors?.email?.type === 'pattern' && <FieldError>{errors.email.message}</FieldError>}
            </InputWrapper>
            <InputWrapper errors={errors} id="firstName">
              <Controller
                control={control}
                defaultValue=""
                name="firstName"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    label={t('create.first_name', 'First Name')}
                    onChange={onChange}
                    required
                    type="text"
                    value={value}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper errors={errors} id="lastName">
              <Controller
                control={control}
                defaultValue=""
                name="lastName"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    label={t('create.last_name', 'Last Name')}
                    onChange={onChange}
                    required
                    type="text"
                    value={value}
                  />
                )}
              />
            </InputWrapper>
          </div>
          <div className="form-row">
            <InputWrapper>
              <Input
                type="tel"
                name="phone"
                label={t('create.phone', 'Phone')}
                ref={register({
                  minLength: {
                    value: 7,
                    message: 'Invalid phone number',
                  },
                })}
              />
              {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
            </InputWrapper>
            <InputWrapper>
              <Input
                type="text"
                name="initials"
                label={t('create.initials', 'Initials (up to 5 characters)')}
                ref={register({
                  maxLength: 5,
                })}
                maxLength={5}
              />
            </InputWrapper>
            <InputWrapper>
              <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
              <StyledSelect
                maxWidth={228}
                minWidth={228}
                name="jobTitle"
                options={jobTitleOptions.map((option) => ({
                  value: option,
                  label: t(option, option.replace(/[_-]/g, ' ')),
                }))}
                value={jobSelect}
                onChange={handleJobSelect}
                ref={register}
              />
            </InputWrapper>
          </div>
          <div className="validated-row">
            <InputWrapper>
              <Input
                type="number"
                name="validated"
                label={t('create.validated', 'validated')}
                ref={register}
                value={validatedNumber}
              />
            </InputWrapper>
          </div>
        </>
      )}

      {(watchUser || watchWorker) && foundPerson === null && (
        <div className="lookup-row">
          <InputWrapper>
            <Input
              type="text"
              name="lookup"
              placeholder="Enter email or SSO ID"
              value={lookupValue}
              ref={register({
                minLength: {
                  value: 3,
                  message: 'A valid email or SSO is required to continue',
                },
              })}
              onChange={(e) => setLookupValue(e.target.value)}
              label={t('create.lookup_field', 'Email/SSO')}
            />
            {errors?.lookup?.message && <FieldError>{errors.lookup.message}</FieldError>}
          </InputWrapper>
          <InputWrapper>
            <Button type="button" onClick={handleLookupClick}>
              {t('create.lookup_button', 'Lookup or Create')}
            </Button>
          </InputWrapper>
        </div>
      )}

      {!watchUser &&
        watchWorker &&
        personFound &&
        foundPerson.validated === 0 &&
        !foundPerson?.type?.includes(PersonCreateType.USER) && (
          <>
            <div className="lookup-row">
              <InputWrapper>
                <Input
                  type="text"
                  name="lookup"
                  placeholder="Enter email or SSO ID"
                  value={lookupValue}
                  onChange={(e) => setLookupValue(e.target.value)}
                  label={t('create.lookup_field', 'Email/SSO')}
                />
              </InputWrapper>
              <InputWrapper>
                <Button type="button" onClick={handleLookupClick}>
                  {t('create.lookup_button', 'Lookup or Create')}
                </Button>
              </InputWrapper>
            </div>
            <FormHeader>{t('create.person_found', 'Person found')}:</FormHeader>
            <div className="form-row">
              <InputWrapper id="firstName">
                <Input
                  label={t('create.first_name', 'First Name')}
                  name="firstName"
                  required
                  type="text"
                  onChange={handleInputChange}
                  value={inputs.firstName}
                  ref={register({ required: true })}
                  autoFocus
                />
              </InputWrapper>
              <InputWrapper id="lastName">
                <Input
                  label={t('create.last_name', 'Last Name')}
                  name="lastName"
                  required
                  type="text"
                  onChange={handleInputChange}
                  value={inputs.lastName}
                  ref={register({ required: true })}
                  autoFocus
                />
              </InputWrapper>
              <InputWrapper id="email">
                {inputs.email ? (
                  <Input
                    label={t('create.email', 'Email')}
                    name="email"
                    required
                    onChange={handleInputChange}
                    type="email"
                    value={inputs.email}
                    ref={register({ required: true })}
                    autoFocus
                  />
                ) : (
                  <Controller
                    control={control}
                    defaultValue=""
                    name="email"
                    rules={{
                      required: true,
                      pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                    }}
                    render={({ onChange, value }) => (
                      <Input
                        label={t('create.email', 'Email')}
                        onChange={onChange}
                        required
                        type="email"
                        value={value}
                      />
                    )}
                  />
                )}
                {errors?.email?.type === 'pattern' && (
                  <FieldError>{errors.email.message}</FieldError>
                )}
              </InputWrapper>
              <InputWrapper>
                {checkIfEmail(foundPerson.username) === false ? (
                  <Input
                    type="text"
                    name="sso"
                    value={foundPerson.username}
                    label={t('create.user_id_sso', 'User ID/SSO')}
                    ref={register({ required: true })}
                    readOnly
                  />
                ) : inputs.functionalSSO ? (
                  <Input
                    type="text"
                    name="functionalSSO"
                    value={inputs.functionalSSO}
                    onChange={handleInputChange}
                    label={t('create.functional_sso', 'Shared SSO')}
                    ref={register}
                  />
                ) : (
                  <Input
                    type="text"
                    name="functionalSSO"
                    label={t('create.functional_sso', 'Shared SSO')}
                    ref={register}
                  />
                )}
              </InputWrapper>
              {checkIfEmail(foundPerson.username) === true ? (
                <div className="validated-row">
                  <InputWrapper>
                    <Input type="text" name="sso" ref={register} value={foundPerson.username} />
                  </InputWrapper>
                </div>
              ) : null}
            </div>
            <div className="form-row three-quarter">
              <InputWrapper>
                {inputs.phone ? (
                  <Input
                    type="text"
                    name="phone"
                    onChange={handleInputChange}
                    value={inputs.phone}
                    label={t('create.phone', 'Phone')}
                    ref={register}
                    autoFocus
                  />
                ) : (
                  <InputWrapper>
                    <Input
                      type="tel"
                      name="phone"
                      label={t('create.phone', 'Phone')}
                      ref={register({
                        minLength: {
                          value: 7,
                          message: 'Invalid phone number',
                        },
                      })}
                    />
                    {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
                  </InputWrapper>
                )}
              </InputWrapper>
              <InputWrapper>
                {inputs.initials ? (
                  <Input
                    type="text"
                    name="initials"
                    onChange={handleInputChange}
                    value={inputs.initials}
                    label={t('create.initials', 'Initials (up to 5 characters)')}
                    ref={register}
                    maxLength={5}
                    autoFocus
                  />
                ) : (
                  <Input
                    type="text"
                    name="initials"
                    label={t('create.initials', 'Initials (up to 5 characters)')}
                    ref={register}
                    maxLength={5}
                  />
                )}
              </InputWrapper>
              <InputWrapper>
                <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
                <StyledSelect
                  maxWidth={167}
                  minWidth={167}
                  name="jobTitle"
                  options={jobTitleOptions.map((option) => ({
                    value: option,
                    label: t(option, option.replace(/[_-]/g, ' ')),
                  }))}
                  value={jobSelect}
                  onChange={handleJobSelect}
                  ref={register}
                />
              </InputWrapper>
            </div>
            <div className="validated-row">
              <InputWrapper>
                <Input
                  type="number"
                  name="validated"
                  label={t('create.validated', 'validated')}
                  ref={register}
                  value={foundPerson?.validated ? foundPerson.validated : validatedNumber}
                />
              </InputWrapper>
            </div>
          </>
        )}

      {!watchUser &&
        watchWorker &&
        personFound &&
        foundPerson.validated !== 0 &&
        !foundPerson?.type?.includes(PersonCreateType.USER) && (
          <>
            <div className="lookup-row">
              <InputWrapper>
                <Input
                  type="text"
                  name="lookup"
                  placeholder="Enter email or SSO ID"
                  value={lookupValue}
                  onChange={(e) => setLookupValue(e.target.value)}
                  label={t('create.lookup_field', 'Email/SSO')}
                />
              </InputWrapper>
              <InputWrapper>
                <Button type="button" onClick={handleLookupClick}>
                  {t('create.lookup_button', 'Lookup or Create')}
                </Button>
              </InputWrapper>
            </div>
            <FormHeader>{t('create.person_found', 'Person found')}:</FormHeader>
            <div className="form-row">
              <InputWrapper id="firstName">
                <Input
                  label={t('create.first_name', 'First Name')}
                  name="firstName"
                  required
                  type="text"
                  value={inputs.firstName}
                  ref={register({ required: true })}
                  autoFocus
                  readOnly
                />
              </InputWrapper>
              <InputWrapper id="lastName">
                <Input
                  label={t('create.last_name', 'Last Name')}
                  name="lastName"
                  required
                  type="text"
                  value={inputs.lastName}
                  ref={register({ required: true })}
                  autoFocus
                  readOnly
                />
              </InputWrapper>
              <InputWrapper id="email">
                {inputs.email ? (
                  <Input
                    label={t('create.email', 'Email')}
                    name="email"
                    required
                    type="email"
                    value={inputs.email}
                    ref={register({ required: true })}
                    autoFocus
                    readOnly
                  />
                ) : (
                  <Controller
                    control={control}
                    defaultValue=""
                    name="email"
                    rules={{
                      required: true,
                      pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                    }}
                    render={({ onChange, value }) => (
                      <Input
                        label={t('create.email', 'Email')}
                        onChange={onChange}
                        required
                        type="email"
                        value={value}
                      />
                    )}
                  />
                )}
                {errors?.email?.type === 'pattern' && (
                  <FieldError>{errors.email.message}</FieldError>
                )}
              </InputWrapper>
              <InputWrapper>
                {checkIfEmail(foundPerson.username) === false ? (
                  <Input
                    type="text"
                    name="sso"
                    value={foundPerson.username}
                    label={t('create.user_id_sso', 'User ID/SSO')}
                    ref={register({ required: true })}
                    autoFocus
                    readOnly
                  />
                ) : inputs.functionalSSO ? (
                  <Input
                    type="text"
                    name="functionalSSO"
                    value={inputs.functionalSSO}
                    onChange={handleInputChange}
                    label={t('create.functional_sso', 'Shared SSO')}
                    ref={register}
                  />
                ) : (
                  <Input
                    type="text"
                    name="functionalSSO"
                    label={t('create.functional_sso', 'Shared SSO')}
                    ref={register}
                  />
                )}
              </InputWrapper>
            </div>
            <div className="form-row three-quarter">
              <InputWrapper>
                {inputs.phone ? (
                  <Input
                    type="text"
                    name="phone"
                    value={inputs.phone}
                    label={t('create.phone', 'Phone')}
                    ref={register}
                    autoFocus
                    readOnly
                  />
                ) : (
                  <InputWrapper>
                    <Input
                      type="tel"
                      name="phone"
                      label={t('create.phone', 'Phone')}
                      ref={register({
                        minLength: {
                          value: 7,
                          message: 'Invalid phone number',
                        },
                      })}
                    />
                    {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
                  </InputWrapper>
                )}
              </InputWrapper>
              <InputWrapper>
                {inputs.initials ? (
                  <Input
                    type="text"
                    name="initials"
                    onChange={handleInputChange}
                    value={inputs.initials}
                    label={t('create.initials', 'Initials (up to 5 characters)')}
                    ref={register}
                    maxLength={5}
                    autoFocus
                  />
                ) : (
                  <Input
                    type="text"
                    name="initials"
                    label={t('create.initials', 'Initials (up to 5 characters)')}
                    ref={register}
                    maxLength={5}
                  />
                )}
              </InputWrapper>
              <InputWrapper>
                <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
                <StyledSelect
                  maxWidth={167}
                  minWidth={167}
                  name="jobTitle"
                  options={jobTitleOptions.map((option) => ({
                    value: option,
                    label: t(option, option.replace(/[_-]/g, ' ')),
                  }))}
                  value={jobSelect}
                  onChange={handleJobSelect}
                  ref={register}
                />
              </InputWrapper>
            </div>
            <div className="validated-row">
              <InputWrapper>
                <Input
                  type="number"
                  name="validated"
                  label={t('create.validated', 'validated')}
                  ref={register}
                  value={foundPerson?.validated ? foundPerson.validated : validatedNumber}
                />
              </InputWrapper>
            </div>
          </>
        )}

      {!watchUser &&
        watchWorker &&
        personFound &&
        foundPerson?.type?.includes(PersonCreateType.USER) && (
          <>
            <div className="lookup-row">
              <InputWrapper>
                <Input
                  type="text"
                  name="lookup"
                  placeholder="Enter email or SSO ID"
                  value={lookupValue}
                  onChange={(e) => setLookupValue(e.target.value)}
                  label={t('create.lookup_field', 'Email/SSO')}
                />
              </InputWrapper>
              <InputWrapper>
                <Button type="button" onClick={handleLookupClick}>
                  {t('create.lookup_button', 'Lookup or Create')}
                </Button>
              </InputWrapper>
            </div>
            <FormHeader>{t('create.person_found', 'Person found')}:</FormHeader>
            <div className="form-row">
              <InputWrapper>
                <Input
                  type="text"
                  name="sso"
                  value={foundPerson.username}
                  label={t('create.user_id_sso', 'User ID/SSO')}
                  ref={register({ required: true })}
                  readOnly
                />
              </InputWrapper>
              <InputWrapper id="email">
                {inputs.email ? (
                  <Input
                    label={t('create.email', 'Email')}
                    name="email"
                    required
                    type="email"
                    value={inputs.email}
                    ref={register({ required: true })}
                    autoFocus
                    readOnly
                  />
                ) : (
                  <Controller
                    control={control}
                    defaultValue=""
                    name="email"
                    rules={{
                      required: true,
                      pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                    }}
                    render={({ onChange, value }) => (
                      <Input
                        label={t('create.email', 'Email')}
                        onChange={onChange}
                        required
                        type="email"
                        value={value}
                      />
                    )}
                  />
                )}
                {errors?.email?.type === 'pattern' && (
                  <FieldError>{errors.email.message}</FieldError>
                )}
              </InputWrapper>
              <InputWrapper id="firstName">
                <Input
                  label={t('create.first_name', 'First Name')}
                  name="firstName"
                  required
                  type="text"
                  value={inputs.firstName}
                  ref={register({ required: true })}
                  autoFocus
                  readOnly
                />
              </InputWrapper>
              <InputWrapper id="lastName">
                <Input
                  label={t('create.last_name', 'Last Name')}
                  name="lastName"
                  required
                  type="text"
                  value={inputs.lastName}
                  ref={register({ required: true })}
                  autoFocus
                  readOnly
                />
              </InputWrapper>
            </div>
            <div className="form-row three-quarter">
              <InputWrapper>
                {inputs.phone ? (
                  <Input
                    type="text"
                    name="phone"
                    value={inputs.phone}
                    label={t('create.phone', 'Phone')}
                    ref={register}
                    autoFocus
                    readOnly
                  />
                ) : (
                  <InputWrapper>
                    <Input
                      type="tel"
                      name="phone"
                      label={t('create.phone', 'Phone')}
                      ref={register({
                        minLength: {
                          value: 7,
                          message: 'Invalid phone number',
                        },
                      })}
                    />
                    {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
                  </InputWrapper>
                )}
              </InputWrapper>
              <InputWrapper>
                {inputs.initials ? (
                  <Input
                    type="text"
                    name="initials"
                    onChange={handleInputChange}
                    value={inputs.initials}
                    label={t('create.initials', 'Initials (up to 5 characters)')}
                    ref={register}
                    maxLength={5}
                    autoFocus
                  />
                ) : (
                  <Input
                    type="text"
                    name="initials"
                    label={t('create.initials', 'Initials (up to 5 characters)')}
                    ref={register}
                    maxLength={5}
                  />
                )}
              </InputWrapper>
              <InputWrapper>
                <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
                <StyledSelect
                  maxWidth={167}
                  minWidth={167}
                  name="jobTitle"
                  options={jobTitleOptions.map((option) => ({
                    value: option,
                    label: t(option, option.replace(/[_-]/g, ' ')),
                  }))}
                  value={jobSelect}
                  onChange={handleJobSelect}
                  ref={register}
                />
              </InputWrapper>
            </div>
            <div className="validated-row">
              <InputWrapper>
                <Input
                  type="number"
                  name="validated"
                  label={t('create.validated', 'validated')}
                  ref={register}
                  value={foundPerson?.validated ? foundPerson.validated : validatedNumber}
                />
              </InputWrapper>
            </div>
          </>
        )}

      {!watchUser && watchWorker && personNotFound && (
        <>
          <div className="lookup-row">
            <InputWrapper>
              <Input
                type="text"
                name="lookup"
                placeholder="Enter email or SSO ID"
                value={lookupValue}
                onChange={(e) => setLookupValue(e.target.value)}
                label={t('create.lookup_field', 'Email/SSO')}
              />
            </InputWrapper>
            <InputWrapper>
              <Button type="button" onClick={handleLookupClick}>
                {t('create.lookup_button', 'Lookup or Create')}
              </Button>
            </InputWrapper>
          </div>
          <FormHeader>
            {t('create.person_not_found', 'Email/SSO ID not found, fill out Worker information')}:
          </FormHeader>
          <div className="form-row">
            <InputWrapper errors={errors} id="firstName">
              <Controller
                control={control}
                defaultValue=""
                name="firstName"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    label={t('create.first_name', 'First Name')}
                    onChange={onChange}
                    required
                    type="text"
                    value={value}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper errors={errors} id="lastName">
              <Controller
                control={control}
                defaultValue=""
                name="lastName"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    label={t('create.last_name', 'Last Name')}
                    onChange={onChange}
                    required
                    type="text"
                    value={value}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper errors={errors} id="email">
              <Controller
                control={control}
                defaultValue=""
                name="email"
                rules={{
                  required: true,
                  pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                }}
                render={({ onChange, value }) => (
                  <Input
                    label={t('create.email', 'Email')}
                    onChange={onChange}
                    required
                    type="email"
                    value={value}
                  />
                )}
              />
              {errors?.email?.type === 'pattern' && <FieldError>{errors.email.message}</FieldError>}
            </InputWrapper>
            <InputWrapper>
              <Input
                type="text"
                name="functionalSSO"
                label={t('create.functional_sso', 'Shared SSO')}
                ref={register}
              />
            </InputWrapper>
          </div>
          <div className="form-row three-quarter">
            <InputWrapper>
              <Input
                type="tel"
                name="phone"
                label={t('create.phone', 'Phone')}
                ref={register({
                  minLength: {
                    value: 7,
                    message: 'Invalid phone number',
                  },
                })}
              />
              {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
            </InputWrapper>
            <InputWrapper>
              <Input
                type="text"
                name="initials"
                label={t('create.initials', 'Initials (up to 5 characters)')}
                ref={register}
                maxLength={5}
              />
            </InputWrapper>
            <InputWrapper>
              <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
              <StyledSelect
                maxWidth={167}
                minWidth={167}
                name="jobTitle"
                // label={t('create.person.select_title', 'Select a Job Title')}
                options={jobTitleOptions.map((option) => ({
                  value: option,
                  label: t(option, option.replace(/[_-]/g, ' ')),
                }))}
                value={jobSelect}
                onChange={handleJobSelect}
                ref={register}
              />
            </InputWrapper>
          </div>
          <div className="validated-row">
            <InputWrapper>
              <Input
                type="number"
                name="validated"
                label={t('create.validated', 'validated')}
                ref={register}
                value={validatedNumber}
              />
            </InputWrapper>
          </div>
        </>
      )}

      {watchUser && !watchWorker && personFound && foundPerson.validated !== 0 && (
        <>
          <div className="lookup-row">
            <InputWrapper>
              <Input
                type="text"
                name="lookup"
                placeholder="Enter email or SSO ID"
                value={lookupValue}
                onChange={(e) => setLookupValue(e.target.value)}
                label={t('create.lookup_field', 'Email/SSO')}
              />
            </InputWrapper>
            <InputWrapper>
              <Button type="button" onClick={handleLookupClick}>
                {t('create.lookup_button', 'Lookup or Create')}
              </Button>
            </InputWrapper>
          </div>
          <FormHeader>{t('create.person_found', 'Person found')}:</FormHeader>
          <div className="form-row">
            <InputWrapper>
              <Input
                type="text"
                name="sso"
                value={foundPerson ? foundPerson.username : null}
                label={t('create.user_id_sso', 'User ID/SSO')}
                ref={register({ required: true })}
                readOnly
              />
            </InputWrapper>
            <InputWrapper id="email">
              {foundPerson.email ? (
                <Input
                  label={t('create.email', 'Email')}
                  name="email"
                  required
                  type="email"
                  value={foundPerson.email ? foundPerson.email : ''}
                  ref={register({ required: true })}
                  autoFocus
                  readOnly
                />
              ) : (
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  rules={{
                    required: true,
                    pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      label={t('create.email', 'Email')}
                      onChange={onChange}
                      required
                      type="email"
                      value={value}
                    />
                  )}
                />
              )}
              {errors?.email?.type === 'pattern' && <FieldError>{errors.email.message}</FieldError>}
            </InputWrapper>
            <InputWrapper id="firstName">
              <Input
                label={t('create.first_name', 'First Name')}
                name="firstName"
                required
                type="text"
                value={foundPerson ? foundPerson.firstName : ''}
                ref={register({ required: true })}
                autoFocus
                readOnly
              />
            </InputWrapper>
            <InputWrapper id="lastName">
              <Input
                label={t('create.last_name', 'Last Name')}
                name="lastName"
                required
                type="text"
                value={foundPerson ? foundPerson.lastName : ''}
                ref={register({ required: true })}
                autoFocus
                readOnly
              />
            </InputWrapper>
          </div>
          <div className="form-row three-quarter">
            <InputWrapper>
              {foundPerson.phoneNumber ? (
                <Input
                  type="text"
                  name="phone"
                  value={foundPerson.phoneNumber}
                  label={t('create.phone', 'Phone')}
                  ref={register}
                  autoFocus
                  readOnly
                />
              ) : (
                <InputWrapper>
                  <Input
                    type="tel"
                    name="phone"
                    label={t('create.phone', 'Phone')}
                    ref={register({
                      minLength: {
                        value: 7,
                        message: 'Invalid phone number',
                      },
                    })}
                  />
                  {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
                </InputWrapper>
              )}
            </InputWrapper>
            <InputWrapper>
              {inputs.initials ? (
                <Input
                  type="text"
                  name="initials"
                  onChange={handleInputChange}
                  value={inputs.initials}
                  label={t('create.initials', 'Initials (up to 5 characters)')}
                  ref={register}
                  maxLength={5}
                  autoFocus
                />
              ) : (
                <Input
                  type="text"
                  name="initials"
                  label={t('create.initials', 'Initials (up to 5 characters)')}
                  ref={register}
                  maxLength={5}
                />
              )}
            </InputWrapper>
            <InputWrapper>
              <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
              <StyledSelect
                maxWidth={167}
                minWidth={167}
                name="jobTitle"
                options={jobTitleOptions.map((option) => ({
                  value: option,
                  label: t(option, option.replace(/[_-]/g, ' ')),
                }))}
                value={jobSelect}
                onChange={handleJobSelect}
                ref={register}
              />
            </InputWrapper>
          </div>
          <div className="validated-row">
            <InputWrapper>
              <Input
                type="number"
                name="validated"
                label={t('create.validated', 'validated')}
                ref={register}
                value={foundPerson?.validated ? foundPerson.validated : validatedNumber}
              />
            </InputWrapper>
          </div>
        </>
      )}

      {watchUser && !watchWorker && personFound && foundPerson.validated === 0 && (
        <div className="lookup-row">
          <InputWrapper>
            <Input
              type="text"
              name="lookup"
              placeholder="Enter email or SSO ID"
              value={lookupValue}
              onChange={(e) => setLookupValue(e.target.value)}
              label={t('create.lookup_field', 'Email/SSO')}
            />
          </InputWrapper>
          <InputWrapper>
            <Button type="button" onClick={handleLookupClick}>
              {t('create.lookup_button', 'Lookup or Create')}
            </Button>
          </InputWrapper>
          <LookupError>
            A validated Email or SSO is required to access the system. Recheck the Email/SSO you
            entered. To proceed with Worker setup uncheck the User checkbox.
          </LookupError>
          {disableButtons()}
        </div>
      )}

      {watchUser && !watchWorker && personNotFound && (
        <div className="lookup-row">
          <InputWrapper>
            <Input
              type="text"
              name="lookup"
              placeholder="Enter email or SSO ID"
              value={lookupValue}
              onChange={(e) => setLookupValue(e.target.value)}
              label={t('create.lookup_field', 'Email/SSO')}
            />
          </InputWrapper>
          <InputWrapper>
            <Button type="button" onClick={handleLookupClick}>
              {t('create.lookup_button', 'Lookup or Create')}
            </Button>
          </InputWrapper>
          <LookupError>
            Valid Email or SSO is required to access the system. Please recheck the Email/SSO you
            entered.
          </LookupError>
          {disableButtons()}
        </div>
      )}

      {watchUser && watchWorker && personFound && foundPerson.validated !== 0 && (
        <>
          <div className="lookup-row">
            <InputWrapper>
              <Input
                type="text"
                name="lookup"
                placeholder="Enter email or SSO ID"
                value={lookupValue}
                onChange={(e) => setLookupValue(e.target.value)}
                label={t('create.lookup_field', 'Email/SSO')}
              />
            </InputWrapper>
            <InputWrapper>
              <Button type="button" onClick={handleLookupClick}>
                {t('create.lookup_button', 'Lookup or Create')}
              </Button>
            </InputWrapper>
          </div>
          <FormHeader>{t('create.person_found', 'Person found')}:</FormHeader>
          <div className="form-row">
            <InputWrapper>
              <Input
                type="text"
                name="sso"
                value={foundPerson ? foundPerson.username : null}
                label={t('create.user_id_sso', 'User ID/SSO')}
                ref={register({ required: true })}
                readOnly
              />
            </InputWrapper>
            <InputWrapper id="email">
              {foundPerson.email ? (
                <Input
                  label={t('create.email', 'Email')}
                  name="email"
                  required
                  type="email"
                  value={foundPerson.email ? foundPerson.email : ''}
                  ref={register({ required: true })}
                  autoFocus
                  readOnly
                />
              ) : (
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  rules={{
                    required: true,
                    pattern: { value: Pattern.EMAIL, message: 'Invalid email address' },
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      label={t('create.email', 'Email')}
                      onChange={onChange}
                      required
                      type="email"
                      value={value}
                    />
                  )}
                />
              )}
              {errors?.email?.type === 'pattern' && <FieldError>{errors.email.message}</FieldError>}
            </InputWrapper>
            <InputWrapper id="firstName">
              <Input
                label={t('create.first_name', 'First Name')}
                name="firstName"
                required
                type="text"
                value={foundPerson ? foundPerson.firstName : ''}
                ref={register({ required: true })}
                autoFocus
                readOnly
              />
            </InputWrapper>
            <InputWrapper id="lastName">
              <Input
                label={t('create.last_name', 'Last Name')}
                name="lastName"
                required
                type="text"
                value={foundPerson ? foundPerson.lastName : ''}
                ref={register({ required: true })}
                autoFocus
                readOnly
              />
            </InputWrapper>
          </div>
          <div className="form-row three-quarter">
            <InputWrapper>
              {foundPerson.phoneNumber ? (
                <Input
                  type="text"
                  name="phone"
                  value={foundPerson.phoneNumber}
                  label={t('create.phone', 'Phone')}
                  ref={register}
                  autoFocus
                  readOnly
                />
              ) : (
                <InputWrapper>
                  <Input
                    type="tel"
                    name="phone"
                    label={t('create.phone', 'Phone')}
                    ref={register({
                      minLength: {
                        value: 7,
                        message: 'Invalid phone number',
                      },
                    })}
                  />
                  {errors?.phone?.message && <FieldError>{errors.phone.message}</FieldError>}
                </InputWrapper>
              )}
            </InputWrapper>
            <InputWrapper>
              {inputs.initials ? (
                <Input
                  type="text"
                  name="initials"
                  onChange={handleInputChange}
                  value={inputs.initials}
                  label={t('create.initials', 'Initials (up to 5 characters)')}
                  ref={register}
                  maxLength={5}
                  autoFocus
                />
              ) : (
                <Input
                  type="text"
                  name="initials"
                  label={t('create.initials', 'Initials (up to 5 characters)')}
                  ref={register}
                  maxLength={5}
                />
              )}
            </InputWrapper>
            <InputWrapper>
              <span className="select-label">{t('create.person.select_title', 'Job Title')}</span>
              <StyledSelect
                maxWidth={167}
                minWidth={167}
                name="jobTitle"
                options={jobTitleOptions.map((option) => ({
                  value: option,
                  label: t(option, option.replace(/[_-]/g, ' ')),
                }))}
                value={jobSelect}
                onChange={handleJobSelect}
                ref={register}
              />
            </InputWrapper>
          </div>
          <div className="validated-row">
            <InputWrapper>
              <Input
                type="number"
                name="validated"
                label={t('create.validated', 'validated')}
                ref={register}
                value={foundPerson?.validated ? foundPerson.validated : validatedNumber}
              />
            </InputWrapper>
          </div>
        </>
      )}

      {watchUser && watchWorker && personFound && foundPerson.validated === 0 && (
        <div className="lookup-row">
          <InputWrapper>
            <Input
              type="text"
              name="lookup"
              placeholder="Enter email or SSO ID"
              value={lookupValue}
              onChange={(e) => setLookupValue(e.target.value)}
              label={t('create.lookup_field', 'Email/SSO')}
            />
          </InputWrapper>
          <InputWrapper>
            <Button type="button" onClick={handleLookupClick}>
              {t('create.lookup_button', 'Lookup or Create')}
            </Button>
          </InputWrapper>
          <LookupError>
            A validated Email or SSO is required to access the system. Recheck the Email/SSO you
            entered. To proceed with Worker setup uncheck the User checkbox.
          </LookupError>
          {disableButtons()}
        </div>
      )}

      {watchUser && watchWorker && personNotFound && (
        <div className="lookup-row">
          <InputWrapper>
            <Input
              type="text"
              name="lookup"
              placeholder="Enter email or SSO ID"
              value={lookupValue}
              onChange={(e) => setLookupValue(e.target.value)}
              label={t('create.lookup_field', 'Email/SSO')}
            />
          </InputWrapper>
          <InputWrapper>
            <Button type="button" onClick={handleLookupClick}>
              {t('create.lookup_button', 'Lookup or Create')}
            </Button>
          </InputWrapper>
          <LookupError>
            A validated Email or SSO is required to access the system. Recheck the Email/SSO you
            entered. To proceed with Worker setup uncheck the User checkbox.
          </LookupError>
          {disableButtons()}
        </div>
      )}

      <ConditionalRender shouldRender={false}>
        {/* redlined for MVP0 */}
        <AddDetailButton type="button">
          <ButtonIcon />
          {t('create.add_more_details', 'Add More Details')}
        </AddDetailButton>
      </ConditionalRender>
    </div>
  );
};

PersonAddDetails.propTypes = {
  className: PropTypes.string,
  disableButtons: PropTypes.func,
  personType: PropTypes.string,
  setPersonType: PropTypes.func,
  person: PropTypes.object,
  setSteps: PropTypes.func,
  setPersonAttributes: PropTypes.func,
  setPersonIsFound: PropTypes.func,
  foundPerson: PropTypes.object,
  setFoundPerson: PropTypes.func,
};

PersonAddDetails.defaultProps = {
  className: null,
};

export default PersonAddDetails;
