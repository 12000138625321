import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Card } from '@ge/components/card';

const CardWrapper = styled.div`
  margin-bottom: 10px;
  div.danger {
    border-left: 3px solid ${(props) => props.theme.assetCard.dangerColor};
  }
`;

const SidebarCard = ({ icon, title, danger, children }) => (
  <CardWrapper>
    <Card icon={icon} title={title} danger={danger}>
      {children}
    </Card>
  </CardWrapper>
);

SidebarCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  danger: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SidebarCard.defaultProps = {
  title: '',
  icon: '',
  danger: false,
  children: [],
};

export default SidebarCard;
