import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { globalColors } from '@ge/tokens';

import { ViewSelectorStatus } from '../view-selector/view-selector-status';

import Navigation from './navigation';
import Profile from './profile';
import SitesAssetsCount from './sites-assets-count';

const Container = styled.div`
  height: 50px;
  background: ${(props) => props.theme.navigation.backgroundColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LogoLink = styled.a`
  padding: 0 12px;
  border-right: 1px solid ${(props) => props.theme.navigation.borderColor};
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
`;

const MainNavContent = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const MainNav = () => {
  let pathname = useLocation().pathname;
  const logoRef = useRef();
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    if (previousPath === pathname) {
      return;
    }

    setPreviousPath(pathname);

    // Take focus off of the link element the user just chose.
    // This way the menu will close since we have styles based
    // on focus within the <Navigation> element.
    return logoRef.current?.focus();
  }, [pathname, previousPath]);

  return (
    <Container>
      <LogoLink href="/" tabIndex="0" ref={logoRef}>
        <Icon icon={Icons.GE_ICON} size={35} color={globalColors.white} />
      </LogoLink>
      <Navigation previousPath={previousPath} />
      <MainNavContent>
        <ViewSelectorStatus />
        <SitesAssetsCount />
        <Profile />
      </MainNavContent>
    </Container>
  );
};

export default MainNav;
