import { PropTypes } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

export const TRANSITION_TIME = 0.6;

const StyledPanel = styled.div`
  .title {
    cursor: pointer;
    display: flex;
    flex: 1 100%;
    align-items: center;
    border-bottom: solid 1px ${(props) => props.theme.collapsiblePanel.borderBottomColor};
    overflow: hidden;
  }
  .carot,
  .chevron {
    transition: transform ${TRANSITION_TIME}s ease;
    padding: 6px;
  }
  .carot {
    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(0deg);
    }
  }
  .chevron {
    transform: rotate(0deg);
    &.rotate {
      transform: rotate(-180deg);
    }
  }
  .collapsible-panel-content {
    height: 0;
    transition: height ${TRANSITION_TIME}s ease;
    &.panel-loading,
    &.no-overflow {
      overflow: hidden;
    }
  }
`;

const CaretIcon = styled(Icon).attrs((props) => ({
  size: 8,
  color: props.theme.collapsiblePanel.carotIconColor,
  icon: Icons.CARET,
}))``;

const PlusIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.collapsiblePanel.carotIconColor,
  icon: Icons.ADD,
}))`
  padding: 6px;
`;

const MinusIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.collapsiblePanel.carotIconColor,
  icon: Icons.REMOVE,
}))`
  padding: 6px;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.collapsiblePanel.carotIconColor,
  icon: Icons.CHEVRON,
}))`
  margin-left: auto;
`;

const StyledDiv = styled.div`
  &.hidden {
    display: none;
  }
`;

export const CollapsiblePanel = ({
  children,
  headerContent,
  isActiveTitle,
  onToggleExpand,
  expanded,
  className,
  triggerHeight,
  plusIcon,
  secondary,
  allowOverflow,
  enableToggle,
  disableKeyClick,
}) => {
  const iconClass = secondary ? 'chevron' : 'carot';
  const [isExpand, setIsExpandState] = useState(false);
  const [setHeight, setHeightState] = useState('');
  const [setRotate, setRotateState] = useState(expanded ? `${iconClass} rotate` : iconClass);
  const [isContentVisible, setIsContentVisibleState] = useState(false);
  const [isPanelLoading, setPanelLoading] = useState(false);
  const content = useRef(null);

  useEffect(() => {
    setIsExpandState(expanded);
    setRotateState(!expanded ? iconClass : `${iconClass} rotate`);
  }, [expanded, iconClass]);

  const toggleExpanded = () => {
    // preventing fast clicking/button pressing where content wouldn't be visible in an expanded panel
    if (isPanelLoading) {
      return;
    }

    setIsExpandState(!isExpand);
    setRotateState(isExpand ? iconClass : `${iconClass} rotate`);
    onToggleExpand(!isExpand);
  };

  const handleKeyDown = (e) => {
    if (disableKeyClick && e.key === 'Enter') {
      e.preventDefault();
      toggleExpanded();
    }
  };

  // setting content container to content height
  // setting content container inert attribute for accessibility
  // setting content visibility
  const handleContentVisibility = (visible) => {
    setPanelLoading(true);

    if (visible) {
      content.current?.removeAttribute('inert');
      setIsContentVisibleState(true);

      setTimeout(() => {
        setPanelLoading(false);
      }, TRANSITION_TIME * 1000);

      // waiting for content to be injected into the DOM before calculating container height
      return setTimeout(() => {
        setHeightState(`${content.current?.clientHeight}px`);
      });
    } else {
      setHeightState('0px');
      content.current?.setAttribute('inert', '');

      // waiting for the css transition to complete before removing content from DOM
      return setTimeout(() => {
        setIsContentVisibleState(false);
        setPanelLoading(false);
      }, TRANSITION_TIME * 1000);
    }
  };

  useEffect(() => {
    const cancelToken = handleContentVisibility(isExpand);
    return () => clearTimeout(cancelToken);
  }, [isExpand, setHeight, triggerHeight]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((el) => {
      setHeightState(el[0].contentRect.height);
    });
    resizeObserver.observe(content.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [triggerHeight]);

  return (
    <StyledPanel className={`${className} ${isExpand ? 'expanded' : ''}`}>
      <div
        className={`title ${isActiveTitle ? 'active' : ''} ${isExpand ? 'expanded' : ''}`}
        onClick={enableToggle ? toggleExpanded : () => {}}
        onKeyDown={(e) => handleKeyDown(e)}
        role="button"
        tabIndex="0"
      >
        {!secondary && enableToggle && (
          <>
            {plusIcon ? (
              isExpand ? (
                <MinusIcon />
              ) : (
                <PlusIcon />
              )
            ) : (
              <CaretIcon className={`${setRotate}`} />
            )}
          </>
        )}
        {headerContent}
        {secondary && enableToggle && <ChevronIcon className={`${setRotate}`} />}
      </div>
      <div
        className={`collapsible-panel-content ${isExpand ? 'expanded' : ''} ${
          isPanelLoading && allowOverflow ? 'panel-loading' : ''
        } ${!allowOverflow ? 'no-overflow' : ''}`}
        style={{ height: `${setHeight}` }}
      >
        <StyledDiv ref={content} className={!isContentVisible ? 'hidden' : ''}>
          {children}
        </StyledDiv>
      </div>
    </StyledPanel>
  );
};

CollapsiblePanel.propTypes = {
  headerContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disableKeyClick: PropTypes.bool,
  isActiveTitle: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  triggerHeight: PropTypes.any,
  plusIcon: PropTypes.bool,
  secondary: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  enableToggle: PropTypes.bool,
};

CollapsiblePanel.defaultProps = {
  children: [],
  isActiveTitle: false,
  onToggleExpand: () => null,
  expanded: false,
  className: null,
  triggerHeight: null,
  plusIcon: false,
  secondary: false,
  allowOverflow: false,
  enableToggle: true,
  disableKeyClick: true,
};
