import dayjs from 'dayjs';

export const lastThreeMonths = () => {
  const currentDate = new Date();
  const today = new Date();
  const someDayAgo = currentDate.setDate(today.getDate() - 90);
  return {
    endDate: dayjs(today).format('YYYY-MM-DD'),
    startDate: someDayAgo
      ? dayjs(someDayAgo).format('YYYY-MM-DD')
      : dayjs(today).format('YYYY-MM-DD'),
  };
};

export const lastSixMonths = () => {
  const currentDate = new Date();
  const today = new Date();
  const someDayAgo = currentDate.setDate(today.getDate() - 180);
  return {
    endDate: dayjs(today).format('YYYY-MM-DD'),
    startDate: someDayAgo
      ? dayjs(someDayAgo).format('YYYY-MM-DD')
      : dayjs(today).format('YYYY-MM-DD'),
  };
};
