import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Placements } from '@ge/models/constants';

const baseTabCSS = css`
  border-color: ${(props) => props.theme.tabs.borderColor};
  color: ${(props) => props.theme.tabs.textColor};
`;

const activeTabCSS = css`
  font-weight: 700;
  border-color: ${(props) => props.theme.tabs.activeBorderColor};
  color: ${(props) => props.theme.tabs.activeTextColor};
`;

const largeCss = css`
  min-width: 180px;
`;

const StyledTab = styled.button`
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  min-width: ${(props) => (props.small ? '110px' : '140px')};
  text-align: center;
  padding: ${(props) => (props.small ? '5px 0' : '7px 0')};
  margin-right: 2px;
  border-bottom: solid 3px transparent;
  &:focus {
    outline: none;
  }
  ${(props) => {
    if (props.activeTab) return activeTabCSS;
    return baseTabCSS;
  }};

  ${(props) => {
    if (props.large) return largeCss;
  }};
`;

const StyledBadgeLeft = styled(Badge)`
  margin: 0 6px 5px 0;
`;

const StyledBadgeRight = styled(Badge)`
  margin: 0 0px 5px 6px;
`;

export const TabHeader = ({
  activeTab,
  badgeValue,
  badgeColor,
  badgePlacement,
  label,
  onClick,
  small,
  large,
}) => (
  <StyledTab
    className={'auth-tab'}
    onClick={onClick}
    activeTab={activeTab}
    small={small}
    large={large}
    badgeValue={badgeValue}
    badgeColor={badgeColor}
    type="button"
  >
    {badgeValue && badgePlacement === Placements.LEFT && (
      <StyledBadgeLeft
        color={badgeColor}
        label={badgeValue}
        badgePlacement={badgePlacement}
        small
      />
    )}
    {activeTab}
    {label}
    {badgeValue && badgePlacement === Placements.RIGHT && (
      <StyledBadgeRight
        color={badgeColor}
        label={badgeValue}
        badgePlacement={badgePlacement}
        small
      />
    )}
  </StyledTab>
);

TabHeader.propTypes = {
  activeTab: PropTypes.bool,
  badgeValue: PropTypes.string,
  badgeColor: PropTypes.string,
  badgePlacement: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
  large: PropTypes.bool,
};

TabHeader.defaultProps = {
  activeTab: false,
  small: false,
  badgePlacement: Placements.LEFT,
};
