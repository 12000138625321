import { useStoreActions } from 'easy-peasy';
import { useMemo, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { QueryKey, CaseStatus } from '@ge/models/constants';

import { EntityDetailsContext } from '../context/entity-details-context';

import { CasesQueryKeys } from './cases/query-keys';
import { useUpdateCase } from './use-update-case';

/* Sample Task Format
https://devcloud.swcoe.ge.com/devspace/display/NBJXM/Low+Level+Design+for+Field+Task

{
  "assetSourceKeys": [
    "A01"
  ],
  "priority": "LOW",
  "properties": {
    "title": "test-2",
    "description": "test description",
    "scheduleDate": "2021-04-30T11:33:43",
    "eligibleStartDate": "2021-04-30T11:33:43.00",
    "estimatedDuration": "10:10",
    "dueDate": "2021-04-30T11:33:43.00",
    "taskAddedOn": "2021-04-30T11:33:43.00",
    "recurrence": {
      "recurrence": "5",
      "recurrenceType": "EndByDate",
      "recurrenceCycle": "1",
      "recurrenceCycleType": "Days",
      "recurrenceEndByCount": "",
      "recurrenceEndByDate": "2021-04-26T11:33:43.00"
    },
    "group": "Hub"
  },
  "siteGroupSourceKey": "Site1",
  "taskStatus": "planned",
  "taskType": "Maintenance",
  "tenantId": "dfsa",
  "taskCategory": "turbine"
}
 */

// create function to update the task description for a case in the cache
const invalidateCaseListCacheBasedOnTask = (queryClient, task) => {
  const queriesData = queryClient.getQueriesData(CasesQueryKeys.lists());

  queriesData?.forEach((queryData) => {
    let cacheKey = queryData[0][2];
    if (cacheKey.siteIds.includes(task.site.id) && !cacheKey.lastUpdatedStart) {
      queryClient.invalidateQueries(CasesQueryKeys.list(cacheKey));
    }
  });
};

function updateQueriesBasedOnTasks(data, queryClient) {
  // update case detail
  // update case table
  //check to see if data is array or object
  if (Array.isArray(data)) {
    const caseIds = [...new Set(data.map((task) => task.caseId))];
    caseIds?.forEach((caseId) => {
      queryClient.invalidateQueries([QueryKey.CASE_TASKS, caseId]);
      queryClient.refetchQueries(CasesQueryKeys.detail(caseId));
      data.forEach((task) => {
        invalidateCaseListCacheBasedOnTask(queryClient, task);
      });
    });
  } else {
    queryClient.refetchQueries([QueryKey.CASE_TASKS, data.caseId]);
    queryClient.refetchQueries(CasesQueryKeys.detail(data.caseId));
    invalidateCaseListCacheBasedOnTask(queryClient, data);
  }
}

/**
 * Create a task.
 *
 * @param opts useMutation options
 * @returns {{create: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useCreateTask = ({ onSettled, onSuccess }) => {
  const { createTask } = useStoreActions((store) => store.tasks);
  const { createTasks } = useContext(EntityDetailsContext);
  const queryClient = useQueryClient();
  const { mutate: updateCase } = useUpdateCase();

  // Manage lifecycle with react-query mutation
  const {
    mutate: create,
    isLoading,
    isError,
    error,
  } = useMutation(
    ({ task, entityIds, entityType, associationId }) =>
      createTask({ task, entityIds, entityType, associationId, createTasks }),
    {
      onSuccess: (data, variables) => {
        updateQueriesBasedOnTasks(data, queryClient);
        if (variables?.task?.caseId) {
          const caseDetail = queryClient.getQueryData(CasesQueryKeys.detail(variables.task.caseId))
            ?.entities?.cases[variables.task.caseId];
          if (String(caseDetail?.status).toUpperCase() === CaseStatus.NEW) {
            updateCase({
              id: caseDetail.id,
              asset: {
                id: caseDetail.asset.id,
              },
              status: CaseStatus.IN_PROGRESS,
            });
          }
        }
        onSuccess && onSuccess();
      },
      onSettled: (data, error, variables) => {
        onSettled && onSettled(data, variables, error);
      },
    },
  );
  return useMemo(
    () => ({ create, isLoading, isError, error }),
    [create, error, isError, isLoading],
  );
};

/**
 * Edit a task.
 *
 * @param opts useMutation options
 * @returns {{edit: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useEditTask = (opts = {}) => {
  const { editTask } = useStoreActions((store) => store.tasks);
  const { updateTasks } = useContext(EntityDetailsContext);
  const queryClient = useQueryClient();

  // Manage lifecycle with react-query mutation
  const {
    mutate: edit,
    isLoading,
    isError,
    error,
  } = useMutation(
    ({ id, task, associationId, bundledTaskIds }) =>
      editTask({ id, task, associationId, bundledTaskIds, updateTasks }),
    {
      ...opts,
      onSuccess: (data, ...rest) => {
        updateQueriesBasedOnTasks(data, queryClient);
        opts?.onSuccess(data, ...rest);
      },
    },
  );
  return useMemo(() => ({ edit, isLoading, isError, error }), [edit, error, isError, isLoading]);
};

/**
 * Remove a task.
 *
 * @param opts useMutation options
 * @returns {{remove: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useRemoveTask = (opts = {}) => {
  const { deleteTask } = useStoreActions((store) => store.tasks);
  const { removeTask } = useContext(EntityDetailsContext);
  const queryClient = useQueryClient();

  // Manage lifecycle with react-query mutation
  const {
    mutate: remove,
    isLoading,
    isError,
    error,
  } = useMutation(({ id }) => deleteTask({ id, removeTask }), {
    ...opts,
    onSuccess: (data, ...rest) => {
      opts?.onSuccess(data, ...rest);
      updateQueriesBasedOnTasks(data, queryClient);
    },
  });
  return useMemo(
    () => ({ remove, isLoading, isError, error }),
    [remove, error, isError, isLoading],
  );
};

/**
 * Close a task.
 *
 *
 * @param opts useMutation options
 * @returns {{close: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useCloseTask = (opts = {}) => {
  const { closeTask } = useStoreActions((store) => store.tasks);
  const { updateTasks } = useContext(EntityDetailsContext);
  const queryClient = useQueryClient();

  // Manage lifecycle with react-query mutation
  const {
    mutateAsync: close,
    isLoading,
    isError,
    error,
  } = useMutation(
    ({ id, task, associationId }) => closeTask({ id, task, associationId, updateTasks }),
    {
      ...opts,
      onSuccess: (data, ...rest) => {
        updateQueriesBasedOnTasks(data, queryClient);
        opts?.onSuccess(data, ...rest);
      },
    },
  );
  return useMemo(() => ({ close, isLoading, isError, error }), [close, error, isError, isLoading]);
};

/**
 * Reopen a task.
 *
 *
 * @param opts useMutation options
 * @returns {{reopen: Function, isLoading: boolean, isError: boolean, error: String}}
 */
export const useReopenTask = (opts = {}) => {
  const { reopenTask } = useStoreActions((store) => store.tasks);
  const { updateTasks } = useContext(EntityDetailsContext);
  const queryClient = useQueryClient();

  // Manage lifecycle with react-query mutation
  const {
    mutateAsync: reopen,
    isLoading,
    isError,
    error,
  } = useMutation(
    ({ id, task, associationId }) => reopenTask({ id, task, associationId, updateTasks }),
    {
      ...opts,
      onSuccess: (data, ...rest) => {
        updateQueriesBasedOnTasks(data, queryClient);
        opts?.onSuccess(data, ...rest);
      },
    },
  );
  return useMemo(
    () => ({ reopen, isLoading, isError, error }),
    [error, isError, isLoading, reopen],
  );
};
