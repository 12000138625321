import { PropTypes } from 'prop-types';
import React, { forwardRef, useState, useCallback } from 'react';
import ReactSelect, { components } from 'react-select';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { CommonLocators } from '@ge/models/data-locators';
import { elevations } from '@ge/tokens';

import { Button } from '../button';

const PrimarySelectCss = css`
  color: ${(props) => props.theme.select.primaryTextColor};
  border: 1px solid ${(props) => props.theme.select.primaryBorder};
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  position: relative;
  border-radius: 3px;
  background: ${(props) => props.theme.select.primaryBackground};
`;

const SecondaryCss = css`
  font-size: 13px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  background: ${(props) => props.theme.select.secondaryBackground};
`;

const SmallCss = css`
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 20px;
`;

const MediumCss = css`
  box-sizing: border-box;
  min-height: 25px;
  border-radius: 4px;
`;

const TransparentCss = css`
  background: transparent;
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.select.labelTextColor};
  font-size: 11px;
  font-family: 'Museo Sans';
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 5px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'initial')};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'initial')};
`;

const StyledDropdown = styled.div`
  position: relative;
`;

const ValueContainer = styled.div`
  ${(props) => props.isDisabled && `opacity: 0.3`};
  ${(props) => (props.primary ? PrimarySelectCss : SecondaryCss)};
  ${(props) => (props.small ? SmallCss : MediumCss)};
  ${(props) => props.transparent && TransparentCss};
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 2px 10px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;

  span {
    min-width: 50%;
    padding-top: 1px;
  }

  &.invalid {
    border: ${(props) => props.theme.select.errorBorder};
    border-radius: 4px;
  }
`;

const StyledMenu = styled.div`
  margin: 0 0 0 1px;
  font-family: 'Museo Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 0;
  position: absolute;
  background: ${(props) => props.theme.select.optionBackground};
  z-index: ${elevations.P100};
  width: calc(100% - 2px);
`;

const StyleSelect = styled(ReactSelect)`
  padding: 8px 0 15px;

  .select__control {
    color: ${(props) => props.theme.select.primaryTextColor};
    font-size: 13px;
    line-height: 12px;
    font-weight: 400;
    position: relative;
    background: ${(props) => props.theme.select.searchBackground};
    border: 1px solid ${(props) => props.theme.select.searchBorderColor};
    min-height: 25px;
    margin: 0 14px;
    border-radius: 0;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__input {
    color: ${(props) => props.theme.select.optionColor};
  }

  .select__menu {
    margin: 0;
    font-family: 'Museo Sans';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 0;
    border-radius: 0 0 4px 4px;
    background: ${(props) => props.theme.select.optionBackground};
    z-index: ${elevations.P100};

    .select__menu-list {
      .select__option {
        padding: 7px 12px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.select.optionColor};
        &.select__option--is-focused {
          background-color: ${(props) => props.theme.select.hoverColor};
        }
        &.select__option--is-selected {
          font-weight: 500;
        }
        background-color: ${(props) => props.theme.select.optionBackground};
        &:hover {
          cursor: default;
          color: ${(props) => props.theme.select.optionColor};
          width: 100%;
          background: ${(props) => props.theme.select.hoverColor};
        }
      }
    }
  }

  &.invalid {
    border: ${(props) => props.theme.select.errorBorder};
    border-radius: 4px;
  }
`;

const StyledBlanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`;

const Check = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHECK,
  color: props.theme.select.checkmarkColor,
}))`
  margin-left: auto;
  display: flex;
`;

const InlineIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: props.icon,
  color: props.theme.select.checkmarkColor,
}))`
  margin-right: 4px;
  vertical-align: initial;
`;

const DropdownIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.primary ? props.theme.select.chevronPrimaryColor : props.theme.select.chevronColor,
}))`
  vertical-align: initial;
`;

const SearchIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.SEARCH,
  color: props.theme.select.chevronColor,
}))`
  vertical-align: initial;
  margin-right: 8px;
`;

const StyledFooter = styled.div`
  display: flex;
  height: 40px;
  font: 300 12px/15px Museo Sans;
  margin-top: ${(props) => props.marginTop}px;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  box-shadow: inset 0px 1px 0 0 ${(props) => props.theme.select.footerBoxShadow};
  button {
    height: 20px;
    font: font: 700 13px/15px Museo Sans;
    &:first-of-type {
      background: transparent;
      font: font: 700 11px/13px Museo Sans;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
`;

const SingleValue = ({ children, selectProps, ...rest }) => {
  return (
    <components.SingleValue {...rest}>
      {selectProps.icon && <InlineIcon icon={selectProps.icon} />}
      {children}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.instanceOf(Object),
};

const Option = ({ isSelected, children, ...rest }) => {
  return (
    <components.Option {...rest} className={isSelected ? 'select__option--is-selected' : null}>
      {children}
      {isSelected && <Check />}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  icon: PropTypes.string,
};

const Menu = (props) => {
  return <StyledMenu {...props} />;
};

const Blanket = (props) => <StyledBlanket {...props} />;

const Dropdown = ({
  className,
  children,
  isOpen,
  label,
  onClose,
  onOpen,
  placeholder,
  autoClose,
}) => (
  <StyledDropdown className={className}>
    <ValueContainer className="select__dropdown-control" onClick={onOpen}>
      <span>{label ?? placeholder}</span>
      <DropdownIcon />
    </ValueContainer>
    {isOpen ? (
      <>
        <Menu>{children}</Menu>
        {autoClose && <Blanket onClick={onClose} />}
      </>
    ) : null}
  </StyledDropdown>
);

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  placeholder: PropTypes.string,
  autoClose: PropTypes.bool,
};

const DropdownIndicator = () => {
  return <SearchIcon />;
};

export const SelectSearch = forwardRef(
  (
    {
      primary,
      small,
      transparent,
      className,
      name,
      options,
      defaultValue,
      autoFocus,
      isSearchable,
      maxWidth,
      minWidth,
      onChange,
      onSelect,
      onCancel,
      value,
      disabled,
      label,
      id,
      icon,
      placeholder,
      searchPlaceholder,
      isMulti,
      error,
      required,
      autoClose = true,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => {
      setIsOpen(true);
    }, [setIsOpen]);

    const handleClose = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    const handleChange = useCallback(
      (val) => {
        onChange(val);
        autoClose && handleClose();
      },
      [onChange, handleClose, autoClose],
    );

    const getLabel = () => {
      if (Array.isArray(value)) {
        return value.length ? `${value.length} ${label} selected` : null;
      }
      return value?.label;
    };

    return (
      <SelectWrapper
        data-testid={CommonLocators.COMMON_SELECT_WRAPPER}
        maxWidth={maxWidth}
        minWidth={minWidth}
      >
        {label && (
          <StyledLabel htmlFor={id}>
            {label}
            {required && <>*</>}
          </StyledLabel>
        )}
        <Dropdown
          isOpen={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          primary={primary}
          small={small}
          isDisabled={disabled}
          label={getLabel()}
          className={`${className} ${!!error && 'invalid'}`}
          transparent={transparent}
          placeholder={placeholder}
          autoClose={autoClose}
        >
          <StyleSelect
            autoFocus
            backspaceRemovesValue={false}
            components={{ Option, DropdownIndicator, ...rest.additionalComponents }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            ref={ref}
            onChange={handleChange}
            isSearchable={true}
            options={options}
            placeholder={searchPlaceholder}
            classNamePrefix={'select'}
            tabSelectsValue={false}
            id={id}
            name={name}
            value={value}
            isDisabled={disabled}
            defaultValue={defaultValue}
            isMulti={isMulti}
            {...rest}
          />
          {!autoClose && (
            <StyledFooter marginTop={rest?.maxMenuHeight}>
              <Button onClick={() => onCancel(handleClose)}>cancel</Button>
              <label>{`${value?.length} selected`}</label>
              <Button primary onClick={() => onSelect(handleClose)}>
                Select
              </Button>
            </StyledFooter>
          )}
        </Dropdown>
      </SelectWrapper>
    );
  },
);

SelectSearch.displayName = 'SearchSelect';

SelectSearch.propTypes = {
  label: PropTypes.string,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  isSearchable: PropTypes.bool,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  transparent: PropTypes.bool,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string,
  isMulti: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  autoClose: PropTypes.bool,
};

SelectSearch.defaultProps = {
  label: null,
  onChange: () => {},
  onSelect: () => {},
  onCancel: () => {},
  disabled: false,
  options: [],
  value: null,
  defaultValue: null,
  name: null,
  autoFocus: false,
  isSearchable: false,
  primary: false,
  small: false,
  maxWidth: null,
  minWidth: null,
  transparent: false,
  icon: null,
  placeholder: null,
  isMulti: false,
  searchPlaceholder: null,
};
