import { useStoreState } from 'easy-peasy';
import { useMemo, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  Capability,
  PermissionScope,
  QueryKey,
  EntityType,
  PageSource,
  AnomalyCasesResolutionDropdown,
} from '@ge/models/constants';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { Config, useAuth, useTaskSource, useTaskWorkScope } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { fetchTasks } from '@ge/shared/services/task-page';
import { filterByView } from '@ge/shared/util';
import { withDefault } from '@ge/shared/util/table-utils';
import { formatHoursAndMinutes } from '@ge/shared/util/time-date';

import { TasksColumns } from '../models/tasks-table-cols';
import { hydrateTask, getSortedTasks, updateTimezoneTaskFields } from '../util/tasks-util';
import { getServiceGroupIds } from '../util/worker-util';

const TasksTableCapability = {
  FIELD_TASKS_VIEW: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
  REAL_TIME_PAGES_VIEW: [
    { capability: Capability.REAL_TIME_PAGES, scopes: [PermissionScope.VIEW] },
  ],
};

const formatComponentCell = (components) =>
  components.reduce(
    (acc, value, index) => (value ? `${acc} ${index !== 0 ? '|' : ''} ${value}` : acc),
    '',
  );

/**
 * Get all tasks.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object, error: String}}
 */
export const useTasksTable = ({
  filters,
  filterSequence,
  search,
  sortMetric,
  sortDirection,
  siteId,
  status = [],
}) => {
  const [isMax, setIsMax] = useState(false);
  const [tasks, setTasks] = useState([]);
  // store
  const isAssetsLoading = useStoreState(({ assets }) => assets.isLoading);

  // data hooks
  const { isAuthorized } = useAuth();
  const { data: taskSources, isLoading: isTaskSourcesLoading } = useTaskSource({
    entityType: EntityType.ASSET,
    page: PageSource.EXECUTE,
  });

  const { isLoadingWorkScopes, data: taskWorkScopes } = useTaskWorkScope({
    entityType: EntityType.ASSET,
  });

  // hooks
  const { t } = useTranslation(['tasks'], {
    useSuspense: false,
  });

  const { sitesForView, sites } = useStoreState((state) => state.sites);
  const { assets } = useStoreState((state) => state.assets);
  const { technicians } = useStoreState((state) => state.technicians);
  const { tasksTrackerData, setTaskPagesData, taskWorkers, setTaskWorkers, taskPagesData } =
    useContext(EntityDetailsContext);

  const siteIds = useMemo(
    () => (siteId ? [siteId] : sitesForView.map((s) => s.id)),
    [sitesForView, siteId],
  );
  const { sortedServiceGroups } = useStoreState((state) => state.sites);
  //Gives list of sites currently selected
  const { currentView } = useStoreState((state) => state.view);

  const serviceGroupIds = getServiceGroupIds(sortedServiceGroups, currentView).toString();
  const {
    data: taskPages = {},
    error: siteError,
    isError: isSiteError,
    isFetching: isTasksLoading,
  } = useQuery(
    [QueryKey.TASKS, siteIds, status],
    () =>
      fetchTasks({
        siteIds,
        serviceGroupIds,
        status: status,
      }),
    // how often do we want to refetch?  currently just fetching one time
    { ...Config.TASKS_REFRESH },
  );

  useEffect(() => {
    if (Object.keys(taskPages)?.length) {
      if (taskPages?.isMax) {
        setIsMax(true);
        setTaskPagesData([]);
        setTaskWorkers([]);
      } else {
        setIsMax(false);
        const hydratedTasks = !taskPages.tasks
          ? []
          : taskPages.tasks.map((task) => hydrateTask(task, { assets, sites, technicians }));
        updateTimezoneTaskFields(hydratedTasks, sites);
        setTaskPagesData(currentView ? filterByView(hydratedTasks, currentView) : hydratedTasks);
        setTaskWorkers(taskPages?.workers);
      }
    }
  }, [assets, currentView, setTaskPagesData, sites, taskPages, technicians, setTaskWorkers]);

  useEffect(() => {
    const sortedTasks = getSortedTasks(
      taskPagesData,
      currentView,
      sortMetric,
      sortDirection,
      tasksTrackerData,
    );
    sortedTasks.length && setTasks(sortedTasks);
  }, [currentView, sortDirection, sortMetric, taskPagesData, tasksTrackerData]);

  const rows = useMemo(() => {
    if (isTaskSourcesLoading || isLoadingWorkScopes) return [];

    return tasks?.map((task) => {
      const {
        asset,
        consumedParts,
        expectedParts,
        estDurationHours,
        estDurationMinutes,
        laborHours,
        laborMinutes,
        component1,
        component2,
        component3,
        site,
        priority,
        status,
        title,
        description,
        linked,
        estTechs,
        completedByTech,
        attachment,
        note,
        resolutionNotes,
        srNumber,
        srStatus,
        timing,
        flag,
        rdspp,
      } = task;
      const allTechs =
        completedByTech?.reduce((acc, v) => {
          const tech = taskWorkers?.find((worker) => worker?.username === v);
          const techName = tech?.initials || tech?.firstName?.substring(0, 5);
          return techName ? [...acc, techName] : acc;
        }, []) ?? [];

      const siteId = asset?.site?.id ?? site?.id;
      let resolution = resolutionNotes;
      if (resolutionNotes && AnomalyCasesResolutionDropdown.includes(task?.source))
        resolution = resolutionNotes.replace(/;/g, ', ');

      const authorization = {
        capabilities: TasksTableCapability.FIELD_TASKS_VIEW,
        siteIds: [siteId],
      };

      // task needs to be scoped to a site and have view permissions to be visible
      // TODO: verify if the task service will already filter this according to field-tasks view metadata
      // would be nice to know if some of this is already handled for us
      if (!(siteId && isAuthorized(authorization))) {
        return tasks;
      }
      // add custom auth flags for table
      const canOpenEntityPanel = isAuthorized({
        capabilities: TasksTableCapability.REAL_TIME_PAGES_VIEW,
        siteIds: [siteId],
      });

      const totalDurationMinutes = (estDurationHours ?? 0) * 60 + (estDurationMinutes ?? 0);

      let parsedHrsActualDuration = 0;
      let parsedMinsActualDuration = 0;
      let i = 0;
      while (undefined != timing && i < timing.length) {
        parsedMinsActualDuration += timing[i].durationMins;
        parsedHrsActualDuration += timing[i].durationHrs;
        i++;
      }
      const totalDurationMinutesActualDuration =
        (parsedHrsActualDuration ?? 0) * 60 + (parsedMinsActualDuration ?? 0);

      return {
        ...task,
        authorization: {
          canOpenEntityPanel,
        },
        [TasksColumns.ASSET]: asset && { id: asset?.id, name: asset?.name, siteId: site?.id },
        [TasksColumns.SITE]: site && { id: site.id, name: site.name, timezone: site.timezone },
        [TasksColumns.STATE]: asset?.state,
        [TasksColumns.DETAIL]: '',
        [TasksColumns.EST_LABOR_HOURS]: withDefault(
          laborHours ?? laborMinutes,
          formatHoursAndMinutes(laborHours, laborMinutes),
        ),
        [TasksColumns.ACTUAL_DURATION]: {
          totalDurationMinutesActualDuration,
          value: withDefault(
            (Math.floor(totalDurationMinutesActualDuration / 60) == 0
              ? null
              : Math.floor(totalDurationMinutesActualDuration / 60)) ??
              totalDurationMinutesActualDuration % 60,
            formatHoursAndMinutes(
              Math.floor(totalDurationMinutesActualDuration / 60),
              totalDurationMinutesActualDuration % 60,
            ),
          ),
        },
        [TasksColumns.PARTS_EXPECTED]:
          expectedParts && expectedParts.length
            ? { parts: expectedParts, count: expectedParts.length }
            : undefined,
        [TasksColumns.PARTS_CONSUMED]:
          consumedParts && consumedParts.length
            ? { parts: consumedParts, count: consumedParts.length }
            : undefined,
        [TasksColumns.EST_DURATION]: {
          totalDurationMinutes,
          value: withDefault(
            (Math.floor(totalDurationMinutes / 60) == 0
              ? null
              : Math.floor(totalDurationMinutes / 60)) ?? totalDurationMinutes % 60,
            formatHoursAndMinutes(Math.floor(totalDurationMinutes / 60), totalDurationMinutes % 60),
          ),
        },
        [TasksColumns.COMPONENT]: formatComponentCell([component1, component2, component3]),
        [TasksColumns.PRIORITY]: withDefault(priority),
        [TasksColumns.STATUS]: status,

        [TasksColumns.TITLE]: withDefault(title),
        [TasksColumns.DESCRIPTION]: withDefault(description),
        [TasksColumns.LINKED]: linked,
        [TasksColumns.EST_TECHS]: withDefault(estTechs),
        [TasksColumns.ASSIGNED_TECHS]: { value: allTechs },
        // Not part of MVP0
        [TasksColumns.ATTACHMENT]: withDefault(attachment), // Not part of MVP0
        [TasksColumns.NOTES]: withDefault(note), // Not part of MVP0
        [TasksColumns.RESOLUTION]: resolution,
        [TasksColumns.SR_NUMBER]: withDefault(srNumber), // Not part of MVP0
        [TasksColumns.SR_STATUS]: withDefault(srStatus), // Not part of MVP0
        ...(taskSources && {
          source: t(`dynamic.types.${task.source}`, task.source),
        }),
        ...(taskWorkScopes && {
          workScope: t(`dynamic.work_scopes.${task.workScope}`, task.workScope),
        }),
        [TasksColumns.FLAG]: flag,
        [TasksColumns.RDSPP]: withDefault(rdspp),
      };
    });
  }, [
    isTaskSourcesLoading,
    isLoadingWorkScopes,
    tasks,
    isAuthorized,
    taskSources,
    t,
    taskWorkScopes,
    taskWorkers,
  ]);

  const data = useTableFilter({ data: rows, filters, filterSequence, search, isTaskTable: true });

  return {
    data,
    error: siteError,
    isError: isSiteError,
    isLoading: isTasksLoading || isTaskSourcesLoading || isLoadingWorkScopes || isAssetsLoading,
    isMax,
  };
};
