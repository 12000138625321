import { KpiCategoryDefs } from '@ge/models/constants';

export const DefaultFleetKpiVisibility = [
  {
    id: KpiCategoryDefs.TBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    visible: false,
  },
  {
    id: KpiCategoryDefs.PBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.CAPACITY_FACTOR,
    visible: true,
  },
  {
    id: KpiCategoryDefs.EVENT_COVERAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.REPORTING_ASSETS_RATIO,
    visible: false,
  },
  {
    id: KpiCategoryDefs.REPORTING_SITES_RATIO,
    visible: true,
  },
];

export const DefaultSiteKpiVisibility = [
  {
    id: KpiCategoryDefs.TBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    visible: true,
  },
  {
    id: KpiCategoryDefs.PBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    visible: true,
  },
  {
    id: KpiCategoryDefs.PRODUCTION_ACTUAL,
    visible: true,
  },
  {
    id: KpiCategoryDefs.CAPACITY_FACTOR,
    visible: true,
  },
  {
    id: KpiCategoryDefs.EVENT_COVERAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.REPORTING_ASSETS_RATIO,
    visible: true,
  },
  {
    id: KpiCategoryDefs.IU_COVERAGE,
    visible: false,
  },
];

export const DefaultRegionKpiVisibility = [
  {
    id: KpiCategoryDefs.TBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    visible: false,
  },
  {
    id: KpiCategoryDefs.PBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    visible: true,
  },
  {
    id: KpiCategoryDefs.PRODUCTION_ACTUAL,
    visible: true,
  },
  {
    id: KpiCategoryDefs.CAPACITY_FACTOR,
    visible: true,
  },
  {
    id: KpiCategoryDefs.EVENT_COVERAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.REPORTING_ASSETS_RATIO,
    visible: false,
  },
  {
    id: KpiCategoryDefs.REPORTING_SITES_RATIO,
    visible: true,
  },
];

export const DefaultAssetKpiVisibility = [
  {
    id: KpiCategoryDefs.TBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY_CONTRACT,
    visible: true,
  },
  {
    id: KpiCategoryDefs.PBA,
    visible: true,
  },
  {
    id: KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
    visible: true,
  },
  {
    id: KpiCategoryDefs.PRODUCTION_ACTUAL,
    visible: true,
  },
  {
    id: KpiCategoryDefs.CAPACITY_FACTOR,
    visible: true,
  },
  {
    id: KpiCategoryDefs.EVENT_COVERAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.EVENT_RATES,
    visible: true,
  },
];

export const DefaultSolarSiteKpiVisibility = [
  {
    id: KpiCategoryDefs.POWER,
    visible: true,
  },
  {
    id: KpiCategoryDefs.ENERGY,
    visible: true,
  },
  {
    id: KpiCategoryDefs.YIELD,
    visible: true,
  },
  {
    id: KpiCategoryDefs.CAPACITY_FACTOR,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY,
    visible: true,
  },
];

export const DefaultSolarAssetKpiVisibility = [
  {
    id: KpiCategoryDefs.ENERGY,
    visible: true,
  },
  {
    id: KpiCategoryDefs.YIELD,
    visible: true,
  },
  {
    id: KpiCategoryDefs.EFFICIENCY,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY,
    visible: true,
  },
];

export const DefaultStorageSiteKpiVisibility = [
  {
    id: KpiCategoryDefs.EFFICIENCY_STORAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.ACTIVE_ENERGY_USAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.REACTIVE_ENERGY_USAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.SOC,
    visible: true,
  },
  {
    id: KpiCategoryDefs.SOH,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY,
    visible: true,
  },
];

export const DefaultStorageAssetKpiVisibility = [
  {
    id: KpiCategoryDefs.EFFICIENCY_STORAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.ACTIVE_ENERGY_USAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.REACTIVE_ENERGY_USAGE,
    visible: true,
  },
  {
    id: KpiCategoryDefs.SOC,
    visible: true,
  },
  {
    id: KpiCategoryDefs.SOH,
    visible: true,
  },
  {
    id: KpiCategoryDefs.AVAILABILITY,
    visible: true,
  },
];
