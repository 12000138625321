import PropTypes from 'prop-types';
import path from 'ramda/src/path';
import React from 'react';
import { withTheme } from 'styled-components';

const GaugeMask = ({ className, pageColor }) => (
  <svg
    width="57px"
    height="24px"
    viewBox="0 0 57 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="Components:-Data-Vis" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="GE-VD---Gauges" transform="translate(-418.000000, -388.000000)" fill={pageColor}>
        <path
          d="M432.337789,388 L460.662211,388 C465.139807,388 469.07164,390.976442 470.28687,395.285976 L475,412 L475,412 L418,412 L422.71313,395.285976 C423.92836,390.976442 427.860193,388 432.337789,388 Z"
          id="Rectangle"
        />
      </g>
    </g>
  </svg>
);

GaugeMask.propTypes = {
  className: PropTypes.string,
  pageColor: PropTypes.string.isRequired,
};

GaugeMask.defaultProps = {
  className: '',
};

export default withTheme(GaugeMask);
