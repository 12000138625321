import dayjs from 'dayjs';
import Highcharts from 'highcharts';

import { ChartType } from '@ge/components/charts';
import { chartOptionsFactory } from '@ge/components/charts/chart-options-factory';
import { Icons } from '@ge/components/icon';
import { DateTimeFormats } from '@ge/models/constants';
import { mergeOptionsRight } from '@ge/util';

export const PlotLinesIds = {
  TRIP: 'trip',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

const setOpacity = (color, opacity) =>
  Highcharts.color(color)
    .setOpacity(opacity)
    .get();

const mapPlotLineProps = (theme) => (val) => {
  const color = theme.sensorReadingsChart.plotLineColors[val.id] ?? val.color;

  const label =
    {
      [PlotLinesIds.TRIP]: {
        useHTML: true,
        text: `<svg width="10px" height="5px" viewBox="0 0 10 5" style="transform: rotate(270deg)"><path d="${Icons.TRIP_LINE_ICON}" fill="${color}" transform="translate(-37, -289)"></path></svg>`,
        x: -1,
        y: -8,
      },
      [PlotLinesIds.HIGH]: {
        text: '+',
        x: -5,
        y: -110,
        style: {
          color,
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
      [PlotLinesIds.MEDIUM]: {
        text: '+',
        x: -5,
        y: -110,
        style: {
          color,
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
      [PlotLinesIds.LOW]: {
        text: '+',
        x: -5,
        y: -110,
        style: {
          color,
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
    }[val.id] ?? {};

  return {
    ...val,
    color: val.id === PlotLinesIds.TRIP ? color : setOpacity(color, 0.45),
    label: {
      ...label,
      verticalAlign: 'bottom',
    },
    width: 2,
    iconColor: color,
  };
};

const sensorReadingChartOptionsFactory = ({
  chartOptions,
  theme,
  height,
  xAxisTitle,
  tooltipEnabled,
  onMouseOver,
}) => {
  const { colors } = theme.sensorReadingsChart;

  const yAxis = chartOptions.yAxis.map((v, i) => {
    const group = chartOptions.series
      .filter((item) => item.yAxis === i)
      .reduce(
        (acc, { min, max, uom }) => ({
          min: Math.min(acc.min, min),
          max: Math.max(acc.max, max),
          uom,
        }),
        {
          min: 0,
          max: 0,
          uom: '',
        },
      );

    const opposite = i % 2 == 1;

    return {
      min: group.min,
      max: group.max,
      endOnTick: true,
      startOnTick: true,
      tickAmount: 6,
      gridLineWidth: 0,
      margin: 10,
      opposite,
      title: {
        text: group.uom,
        align: 'low',
        textAlign: 'middle',
        rotation: 0,
        offset: opposite ? 12 : 20,
        y: 18,
        style: {
          color: colors[i][0] ?? v.title.style.color,
          fontSize: '11px',
          letterSpacing: 0,
        },
      },
      labels: {
        x: opposite ? 6 : -6,
        style: {
          color: colors[i][0] ?? v.labels.style.color,
          fontSize: '11px',
          letterSpacing: 0,
        },
      },
    };
  });

  const seriesGroups = Object.values(
    chartOptions.series.reduce(
      (acc, v) => ({
        ...acc,
        [v.yAxis]: acc[v.yAxis] ? [...acc[v.yAxis], v] : [v],
      }),
      [],
    ),
  ).map((v, i) => {
    return v.map((v, j) => {
      const color = colors[i]?.[j] || colors[i][colors[i].length - 1];
      return {
        ...v,
        axis: `Y${v.yAxis + 1}`,
        color,
        pointInterval: 60 * 60 * 1000,
        threshold: 1,
        tooltipEnabled,
        tooltipHeader: ``,
        tooltipPointFormatter: ({ x }) =>
          `<div class="body-4">${dayjs(x).format(DateTimeFormats.SENSOR_READINGS_DATE_TIME)}</div>`,
        marker: { enabled: false, opacity: 1 },
        states: {
          hover: {
            enabled: false,
          },
          inactive: { opacity: 1 },
        },
        point: {
          events: {
            mouseOver: function() {
              const { index, x } = this;
              onMouseOver({
                index,
                x,
                values: this.series.chart.series.reduce(
                  (acc, v) => ({ ...acc, [v.name]: v.data[index]?.y }),
                  {},
                ),
              });
            },
          },
        },
        events: {
          mouseOut: function() {
            onMouseOver(null);
          },
        },
      };
    });
  });

  const series = seriesGroups.reduce((acc, v) => [...acc, ...v], []);
  const plotLines = [];
  const plotBands = [];

  chartOptions.xAxis.plotBands.forEach(({ from, to }) => {
    plotBands.push({
      from,
      to,
      color: setOpacity(theme.sensorReadingsChart.plotBandColor, 0.15),
    });
    plotLines.push({
      value: from,
      color: theme.sensorReadingsChart.plotBandColor,
      width: 0.75,
    });
    plotLines.push({
      value: to,
      color: theme.sensorReadingsChart.plotBandColor,
      width: 0.75,
    });
  });

  chartOptions.xAxis.plotLines.forEach((line) => {
    plotLines.push(mapPlotLineProps(theme)(line));
  });

  const xAxis = {
    gridLineWidth: 1,
    title: {
      style: {
        color: theme.sensorReadingsChart.xAxisTitleColor,
        fontSize: '10px',
      },
    },
    labels: {
      style: {
        color: theme.sensorReadingsChart.xAxisLabelColor,
        fontSize: '10px',
      },
    },
    min: chartOptions.xAxis.min,
    max: chartOptions.xAxis.max,
    plotBands,
    plotLines,
  };

  if (tooltipEnabled) {
    xAxis.crosshair = {
      width: 1,
      color: theme.sensorReadingsChart.xAxisLabelColor,
      dashStyle: 'Dash',
      snap: false,
      zIndex: series.length + 1,
    };
  }

  const defaultOptions = chartOptionsFactory({
    series,
    theme,
    type: ChartType.SPLINE,
    onSelect: () => {},
    selectable: false,
    zoom: true,
    noDataLabel: '',
    xAxisTitle: xAxisTitle,
    xAxisType: 'datetime',
    xAxisLabelFormatter: ({ value }) => dayjs(value).format(DateTimeFormats.DEFAULT_TIME_W_SECS),
  });

  const { chart, tooltip, ...rest } = mergeOptionsRight(defaultOptions, { xAxis, yAxis });

  chart.height = height;
  chart.marginLeft = undefined;
  chart.marginRight = undefined;
  tooltip.shared = false;

  return { seriesGroups, options: { chart, tooltip, ...rest }, plotLines };
};

export default sensorReadingChartOptionsFactory;
