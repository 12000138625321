import { PropTypes } from 'prop-types';
import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { Radio } from '@ge/components/radio';

const DialogContainer = styled.div`
  box-sizing: border-box;
  min-width: 230px;
  padding: 15px 24px;
  width: 100%;
`;

const StyledDialog = styled(Dialog)`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
`;

const Title = styled.h3`
  margin: 0px;
  padding: 0px 0px 5px;
  text-transform: uppercase;
`;

const RadioWrapper = styled.div`
  display: flex;
  padding: 5px 0px;
`;

const StyledRadio = styled(Radio)`
  margin: 0px;
  padding: 0px;
  flex: 1;
`;

const SeriesIcon = styled(Icon).attrs(() => ({
  size: 12,
  icon: Icons.SQUARE_LEGEND_ICON,
}))`
  margin-left: 4px;
`;

export const ChartSelectionDialog = ({ options, onClose, onConfirm }) => {
  const { t, ready } = useTranslation(['analyze.data-explorer'], { useSuspense: false });

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
  }, []);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (selected) {
        onConfirm(selected);
      }
    });
    return () => clearTimeout(timeoutID);
  }, [selected, onConfirm]);

  const choices = useMemo(
    () =>
      options?.length > 0
        ? options.map(({ name, color }) => ({ value: name, label: name, color }))
        : [],
    [options],
  );

  if (!ready) return null;

  return (
    <StyledDialog
      isOpen={true}
      onClose={() => onClose({ multi: false, selection: [] })}
      header={t('lasso', 'LASSO')}
      contentWidth
      padContent={false}
    >
      <DialogContainer>
        <Title>{t('select_signal', 'Select Signal')}</Title>
        {choices.map((choice) => (
          <RadioWrapper key={choice.id}>
            <StyledRadio
              name={'select_signal_name'}
              id={choice.value}
              label={choice.label}
              value={choice.value}
              onClick={() => setSelected(choice.value)}
              checked={selected === choice.value}
            />
            <SeriesIcon color={choice.color}></SeriesIcon>
          </RadioWrapper>
        ))}
      </DialogContainer>
    </StyledDialog>
  );
};

ChartSelectionDialog.propTypes = {
  isOpen: PropTypes.bool,
  options: PropTypes.array,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ChartSelectionDialog.defaultProps = {
  isOpen: false,
  options: [],
  onClose: () => null,
  onConfirm: () => null,
};

export default ChartSelectionDialog;
