import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens/typography';

const StyledRadio = styled.div`
  margin: 16px 0;

  input[type='radio'] {
    display: none;

    &:checked + label:before {
      border-color: ${(props) => props.theme.radio.borderColor};
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: 14px;
    position: relative;
    padding: 0 0 0 20px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: 12px;
      height: 12px;
      border: 1px solid ${(props) => props.theme.radio.borderColor};
    }

    &:after {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      transform: scale(0);
      background: ${(props) => props.theme.radio.activeColor};
    }
  }
`;

export const Radio = forwardRef(
  ({ label, required, name, id, error, onChange, value, checked, className, ...props }, ref) => {
    return (
      <StyledRadio className={className}>
        <input
          ref={ref}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          type="radio"
          checked={checked}
          aria-invalid={!!error}
          {...props}
        />
        <Text
          htmlFor={id}
          component={typography.elementTypes.label}
          type={typography.grid}
          level={2}
        >
          {label}
        </Text>
      </StyledRadio>
    );
  },
);

Radio.displayName = 'Radio';

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Radio.defaultProps = {
  required: false,
  checked: false,
  onChange: null,
};
