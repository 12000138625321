export const alarms = [
  {
    id: '6c3ce9c6d539586cb42528f8cf8243ab',
    type: 'state',
    status: 'in-progress',
    priority: 'low',
    severity: 'medium',
    code: 154,
    source: 'eSCADA',
    description: 'Kozzokam gewov dube huzwoum ba.',
    instructions:
      'Dad teurcas dozpowko niihtot zivrav metdu vu uvajop fi vo ze podcin em lezugron. Cad pemuosi jomni jiip gez neh cujjekha agoho ezge vogdo machi edsohzes to bata se gajlor sejuftip. Giho cawez egifi foso wadgob jelevjub rut voodiedo gepworne gacetnih fazdezezu idhol nulus.',
    fhpLink: 'https://www.ge.com',
    fhpText:
      'Pifwofbed faud noklote webjusgeh asuv gim ipijalok can kurog zez ebeosi ikeenene rep icolo. Ed taz tictag tu rietoki heojama gis otbeoh jeabivut nuker cutpis goero po ge egat waladaro. Menen bebdokpoj libi raiti edwuac zikivu ere jugkuku fuc luhe gutped hem hu puk wubiliwid ja puwire. Dikmica hi lav zaaleofu uddidfi civog cedpessek picha bep va jum la leme zujhe lukgeig. Miig hokumek eketebpa uve wugejmib juwogoewa efwirif cebatbiv jusunok cabsuv edu zaha agka tilpico jaur nacobo kifut.',
    start: '2021-02-22T14:12:37.717Z',
    tasks: [
      {
        id: 'TA-2216',
        type: 'TEA',
        title: 'Umape gicew at ugahe.',
        source: 'ROC | en',
        createdDate: '2021-01-02T14:09:59.483Z',
        due: 'immediate',
        status: 'complete',
      },
    ],
    asset: {
      id: '16120895',
      name: 'Sigel SI-04',
      type: 'substation',
      make: 'GE',
      model: '1.6-100',
      serialNumber: '16120895',
      resetMode: 'in-progress',
      metrics: {
        state: 'online',
      },
      wind: 21.5,
      temp: 31,
      events: 8,
      anomalies: 2,
      tasks: 4,
      state: 'online',
    },
    readings: {
      general: {
        ambientTemp: 19.8,
        brakeOilPressure: 67.1,
        gearboxHsBearingTemp: 59.4,
        generatorSpeed: 28.6,
        generatorWinding1Temp: 34.3,
        generatorDriveBearingTemp: 36.5,
        msBearingTemp: 34,
        reactivePower: 19.8,
        towerAccel: 16.9,
        windSpeed: 39.5,
        windDirection: 73,
      },
      pitch: {
        bladePosition: [0.0794522, 0.8692692, 0.1846984],
        mode: 'manual',
        chargers: {
          c1: [10.1736, 11.8143, 12.4421],
          c2: [11.0197, 12.0566, 13.0217],
          c3: [13.4264, 10.2154, 11.3014],
          c4: [12.1473, 13.5156, 11.7638],
          c5: [0, 0, 0],
          c6: [0, 0, 0],
        },
      },
    },
    site: {
      id: '5000615',
      group: 'TEST',
      name: 'SIGEL',
      customer: {
        id: 'DTE',
        name: 'DTE',
      },
      country: 'US',
      metrics: {
        data: 'ok',
        conditions: {
          wind: 5.6,
          temp: -16,
        },
        revenue: {
          lostRevenue: 4899,
          priceMW: 2495,
        },
      },
      tickets: {
        thread: 1,
        si: [],
      },
    },
  },
  {
    id: 'b4019ac9c72f54d29d4d3ed653e60313',
    type: 'logic',
    status: 'open',
    priority: 'high',
    severity: 'low',
    code: 166,
    source: 'eSCADA',
    description: 'Ez damuta cawugeowo cu hifilzib eterez.',
    instructions:
      'Pourobos joopita jevut pawiji dabnafvog ki sub pokel vothawi howkeg iveem orinak hapfosu voreh gah huf faiti uscon. Apsivzo havu visummu cem ca vugro bet fewvaej udapa pedlapap vokowlab iksahu pomeke zukol li aggiznu. Gumjiw jahpezaj oc tihfado nujloced izaac ve meufuvej opapape saza godij ubi luwihu ogrin ifjaepi dagjor zuvovi.',
    fhpLink: 'https://www.ge.com',
    fhpText:
      'Didzowgi in fi nar acijul to fuul wavwekbep kidazziv molfik johojkup pidvotez lujhina ne. Wek ro feuwamu vuh li tuvikeb buabi faru herruh cuvejon ti tidef callif iribu. Fitjigsud fihuk si eh izigka si acdapo ri ucuvala doze ora jasakegud fo kiruhen ivno laop dupen. Puseludi rawlaupo heka atcebeh tul liunfos laf wormevo owkag se bokevut mugen. Guvorub forteb jam muf gu camomme iw daczuw bum juz cazag ike.',
    start: '2021-02-22T05:55:13.717Z',
    causal: true,
    tasks: [],
    asset: {
      id: '16120895',
      name: 'Sigel SI-04',
      type: 'substation',
      make: 'GE',
      model: '1.6-100',
      serialNumber: '16120895',
      resetMode: 'in-progress',
      metrics: {
        state: 'online',
      },
      wind: 21.5,
      temp: 31,
      events: 17,
      anomalies: 7,
      tasks: 1,
      state: 'online',
    },
    readings: {
      general: {
        ambientTemp: -27.7,
        brakeOilPressure: 57.5,
        gearboxHsBearingTemp: 41,
        generatorSpeed: 1.7,
        generatorWinding1Temp: 54.7,
        generatorDriveBearingTemp: 51.7,
        msBearingTemp: 32,
        reactivePower: 5.3,
        towerAccel: 6.1,
        windSpeed: 38,
        windDirection: 38,
      },
      pitch: {
        bladePosition: [0.8385071, 0.4805768, 0.0411579],
        mode: 'manual',
        chargers: {
          c1: [12.7401, 10.7709, 11.7506],
          c2: [12.0422, 12.5771, 12.5354],
          c3: [11.592, 10.4794, 11.643],
          c4: [12.8657, 13.8737, 11.0694],
          c5: [0, 0, 0],
          c6: [0, 0, 0],
        },
      },
    },
    site: {
      id: '5000615',
      group: 'TEST',
      name: 'SIGEL',
      customer: {
        id: 'DTE',
        name: 'DTE',
      },
      country: 'US',
      metrics: {
        data: 'ok',
        conditions: {
          wind: 5.6,
          temp: -16,
        },
        revenue: {
          lostRevenue: 4899,
          priceMW: 2495,
        },
      },
      tickets: {
        thread: 0,
        si: [],
      },
    },
  },
  {
    id: 'f666b0fbf37f5c8781f585ba4fb7efff',
    type: 'state',
    status: 'open',
    priority: 'low',
    severity: 'high',
    code: 188,
    source: 'eSCADA',
    description: 'Fafcitvu fuduclug eh.',
    instructions:
      'Hutfotes palonfes loat samih mavmimka cos faku wuse rumelena ujo tof kisin kerjol cecucjuv pefi. Sojewmaw poc bo pi teve jojah otsejzob tajo nomi nardofag orahu segooc vizabfu. Zoel fosmed peedudil tunro bas ajrap modmegnoj se ila go jadlukumo zahijik gabibi ba ra.',
    fhpLink: 'https://www.ge.com',
    fhpText:
      'Biuk ocituli onied irohe vaif udahoza hofe utpa buuhvin wor ninon desuf. Banu liz sejguj hifveiji vav sev edobego jumwedita sar subime ik nufuna tonmofre fecekubi jalinu bo gori wod. Aguhoton lacocko voh nikkinut ulu agif tojce haze ticlar cempek ciz ha pu bazevu. Ives ni zizokkor dilanas ku bahigfi owso hijwuvmiz jokco edo sur bukuk ef givusure tuzbe ejatujcu. Tunu ignutek atbug vonejopa niagko lak os dave besduc ki as ebojo uneco ro no.',
    start: '2021-02-21T23:33:42.718Z',
    end: '2021-02-22T21:30:22.718Z',
    acknowledged: '2021-02-22T05:00:22.718Z',
    tasks: [
      {
        id: 'TA-1177',
        type: 'Outage',
        title: 'Palo rarjeha leh cab.',
        source: 'ROC | tokougu',
        createdDate: '2021-02-04T05:55:53.116Z',
        due: 'immediate',
        status: 'unscheduled',
      },
    ],
    asset: {
      id: '16120895',
      name: 'Sigel SI-04',
      type: 'substation',
      make: 'GE',
      model: '1.6-100',
      serialNumber: '16120895',
      resetMode: 'in-progress',
      metrics: {
        state: 'online',
      },
      wind: 21.5,
      temp: 31,
      events: 4,
      anomalies: 2,
      tasks: 5,
      state: 'online',
    },
    readings: {
      general: {
        ambientTemp: -21,
        brakeOilPressure: 72.5,
        gearboxHsBearingTemp: 35.2,
        generatorSpeed: 1.4,
        generatorWinding1Temp: 56.1,
        generatorDriveBearingTemp: 27.7,
        msBearingTemp: 44.1,
        reactivePower: 0.2,
        towerAccel: 20,
        windSpeed: 29.4,
        windDirection: 135,
      },
      pitch: {
        bladePosition: [0.6784489, 0.9473132, 0.0973473],
        mode: 'manual',
        chargers: {
          c1: [10.6694, 13.408, 11.77],
          c2: [10.6846, 11.4083, 10.8288],
          c3: [12.0129, 10.6795, 11.0543],
          c4: [11.4936, 13.6717, 12.473],
          c5: [0, 0, 0],
          c6: [0, 0, 0],
        },
      },
    },
    site: {
      id: '5000615',
      group: 'TEST',
      name: 'SIGEL',
      customer: {
        id: 'DTE',
        name: 'DTE',
      },
      country: 'US',
      metrics: {
        data: 'ok',
        conditions: {
          wind: 5.6,
          temp: -16,
        },
        revenue: {
          lostRevenue: 4899,
          priceMW: 2495,
        },
      },
      tickets: {
        thread: 3,
        si: [],
      },
    },
  },
  {
    id: '0bd1fbe491fb523da483fc4ac08a2fa9',
    type: 'state',
    status: 'open',
    priority: 'high',
    severity: 'high',
    code: 116,
    source: 'eSCADA',
    description: 'Dum ti fe wouji.',
    instructions:
      'Mecaalo ifoitotul casjuep haken ga usnu efu nujmirus vuznom mi seba biloj gaf vatso iktojmod efze. Raneru look he saf tef idobib dizzo uhi vadel rojlozez we si seganun det liz. Perri hubvi muknenzig rirut ben ikcuonu ji ezi tokoggo urile cozaveozu wazepca fovformag sis ilew.',
    fhpLink: 'https://www.ge.com',
    fhpText:
      'Dedwu tun tim zidacnid sakog losoffes kopit ces nomijhu sul emdip abgilhig onzez memek to wazmarsej fabuvbun koac. Sairku omamufwuf lowespaw uwpaewo nezozu iwusun de ibu ufadukti ofu es meec wu hekru maj coolviv lik sukirbus. Opzunna ucunaw rawoim ajnaoco comcem lim si huiv pamoze buvojum esluw ah oner ro telvahibi sadrubo. Ladag fusta am hek niun fah ricet ziliz ijueb limorpem ha cesu uzhimgiz dewojober permeur kucfifu damamo. Zepijip fipela rinakud giaro taazi ovuwaf zev lagel vathedifo boh edipufo oc ubame cufrofim vufri.',
    start: '2021-02-20T02:04:29.717Z',
    end: '2021-02-22T21:30:22.717Z',
    tasks: [],
    asset: {
      id: '16120895',
      name: 'Sigel SI-04',
      type: 'substation',
      make: 'GE',
      model: '1.6-100',
      serialNumber: '16120895',
      resetMode: 'in-progress',
      metrics: {
        state: 'online',
      },
      wind: 21.5,
      temp: 31,
      events: 8,
      anomalies: 1,
      tasks: 6,
      state: 'online',
    },
    readings: {
      general: {
        ambientTemp: 19.9,
        brakeOilPressure: 88.9,
        gearboxHsBearingTemp: 43.3,
        generatorSpeed: 24.8,
        generatorWinding1Temp: 56,
        generatorDriveBearingTemp: 36.2,
        msBearingTemp: 23.9,
        reactivePower: 1.1,
        towerAccel: 8.4,
        windSpeed: 24.7,
        windDirection: 192,
      },
      pitch: {
        bladePosition: [0.8051957, 0.2435051, 0.9141794],
        mode: 'manual',
        chargers: {
          c1: [12.7872, 13.5325, 13.1623],
          c2: [13.3339, 13.7458, 12.5968],
          c3: [13.2525, 11.1781, 10.7978],
          c4: [10.08, 12.5237, 13.0664],
          c5: [0, 0, 0],
          c6: [0, 0, 0],
        },
      },
    },
    site: {
      id: '5000615',
      group: 'TEST',
      name: 'SIGEL',
      customer: {
        id: 'DTE',
        name: 'DTE',
      },
      country: 'US',
      metrics: {
        data: 'ok',
        conditions: {
          wind: 5.6,
          temp: -16,
        },
        revenue: {
          lostRevenue: 4899,
          priceMW: 2495,
        },
      },
      tickets: {
        thread: 1,
        si: [],
      },
    },
  },
];
