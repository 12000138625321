import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_API;

/**
 * Fetch case template data
 * @param id - template id
 * @return {Promise<unknown>}
 */
export const fetchCaseTemplate = (id) => {
  return request.get(`/dav/cases/case-template`, {
    baseURL: BASE_URL,
    params: { id },
  });
};

/**
 * Fetch case template rootcause data
 * @param id - template id
 * @return {Promise<unknown>}
 */
export const fetchCaseTemplateRootCause = (ids) => {
  return request.get(`/dav/cases/case-template/rootcause`, {
    baseURL: BASE_URL,
    params: { ids },
  });
};

/**
 * Fetch case rootcause data
 * @param id - case id
 * @return {Promise<unknown>}
 */
export const fetchCaseIdRootCause = (ids) => {
  return request.get(`/dav/cases/case-id/rootcause`, {
    baseURL: BASE_URL,
    params: { ids },
  });
};
