import { useStoreState } from 'easy-peasy';
import difference from 'ramda/src/difference';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useVirtual } from 'react-virtual';
import styled, { withTheme } from 'styled-components';

import { Badge } from '@ge/components/badge';
import { BoldSearch } from '@ge/components/bold-search';
import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { Filter } from '@ge/components/filter';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Textarea } from '@ge/components/textarea';
import { FilterBar } from '@ge/feat-analyze/components/dashboard/dashboard-filter-bar';
import { RangeDefs } from '@ge/feat-analyze/models/analyze-filter-defaults';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { CreateModes } from '@ge/feat-reporting/models/modes';
import { AttributeGroups, Capability, DateRange, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';
import { elevations } from '@ge/tokens';

import { EmailRecipients } from '../../email-recipients/email-recipients';
import { SaveTemplateModal } from '../../save-template-modal/save-template-modal';
import { ActionButtons } from '../action-buttons/actions-buttons';
import { InputStateKeys } from '../sidebar';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');

const NAMESPACE = 'reporting.sidebar';

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-bottom: 2px solid ${({ theme }) => theme.createReport.headerBorderColor};
`;

const SidebarHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SidebarContent = styled.div`
  height: 100%;

  & > div {
    min-height: calc(100% - 85px);
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.createReport.sidebar.separationColor};
`;

const TemplateTitle = styled.p`
  font-size: 11px;
  color: ${({ theme }) => theme.createReport.sidebar.titleColor};
  margin: 0 0 15px;
`;

const TitleLabel = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  margin-right: 2px;
`;

const CharLimit = styled.span`
  font-size: 9px;
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  font-family: 'Museo Sans';
  font-size: 9px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 10px;
  text-transform: none;
  margin-left: 3px;
`;

const Label = styled.h4`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.sidebar.labelColor};
  font-family: 'Museo Sans';
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
`;

const LabelInfo = styled.p`
  margin: -4px 0 0 0;
  font-style: italic;
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  font-size: 11px;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;

  input {
    box-sizing: border-box;
    height: 28px;
    width: 100%;
  }
`;

const TextareaWrapper = styled.div`
  margin-bottom: 0;
  width: 100%;
`;

const StyledTextarea = styled(Textarea)`
  box-sizing: border-box;
  resize: none;
  height: 56px;
  width: 100%;
`;

const Scope = styled.p`
  font-family: 'Museo Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const ScopeSelectorContainer = styled.div`
  width: 275px;
`;

const EntitySelect = styled.div`
  background: ${(props) => props.theme.select.secondaryBackground};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  cursor: default;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
  min-height: 25px;
  padding: 2px 8px;
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.CHEVRON,
  color: props.theme.select.chevronColor,
}))`
  margin-top: 3px;
`;

const EntitySelectMenu = styled.div`
  position: relative;
`;

const SelectMenu = styled.div`
  background: ${(props) => props.theme.menu.backgroundColor};
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.15);
  left: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: opacity 0s;
  visibility: hidden;
  width: 273px;
  z-index: ${elevations.N1};
  &.show {
    opacity: 1;
    transition: opacity 0.1s 0.1s;
    visibility: visible;
    z-index: ${elevations.P10};
  }
`;

const SelectResultsContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  height: 250px;
`;

const StyledVirtualResults = styled.div`
  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const SearchWrapper = styled.div`
  padding: 8px 10px;
  .filter {
    position: relative;
    input {
      background-color: ${(props) => props.theme.themeSelector.tabsBackgroundColor};
      box-sizing: border-box;
      border: 1px solid ${(props) => props.theme.input.borderNavbarInput};
      border-radius: 2px;
      caret-color: ${(props) => props.theme.newTaskDialog.searchCaret};
      color: ${(props) => props.theme.newTaskDialog.searchText};
      height: 28px;
      padding: 6px 8px;
      width: 100%;
      ::placeholder {
        font-size: 14px;
        font-style: italic;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }
`;

const UngroupedSiteContainer = styled.div`
  margin-left: 14px;

  button {
    flex: 1;
    text-align: left;
    font-size: 14px;
    line-height: 14px;
    color: ${(props) => props.theme.themeSelector.listButtonColor};
    padding-top: 2px;
    padding-bottom: 1px;
  }
`;

const SiteIcon = styled(Icon).attrs(({ theme }) => ({
  size: 14,
  icon: Icons.SITE,
  color: theme.createReport.sidebar.siteIconColor,
}))`
  margin-right: 5px;
`;

const GroupIcon = styled(Icon).attrs(({ theme }) => ({
  size: 14,
  icon: Icons.SERVICE_GROUP,
  color: theme.createReport.sidebar.siteIconColor,
}))`
  margin-right: 5px;
`;

const SectionHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 8px;

  span {
    margin-left: 8px;
    font-size: 14px;
    flex: 1;
  }

  label {
    margin-top: 2px;
  }
`;

const StyledUl = styled.ul`
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  li {
    padding: 5px 20px;
    font-weight: 500;
    font-size: 14px;
    display: block;
    padding-left: ${(props) => (props.noPadding ? '0px' : '40px')};
    display: flex;
    background: ${(props) => props.theme.themeSelector.listBackgroundColor};
    border-bottom: 1px solid ${(props) => props.theme.themeSelector.listBorderColor};
    &.odd {
      background: ${(props) => props.theme.themeSelector.listBackgroundColorOdd};
    }
    button {
      flex: 1;
      text-align: left;
      font-size: 14px;
      line-height: 14px;
      color: ${(props) => props.theme.themeSelector.listButtonColor};
      padding-top: 2px;
      padding-bottom: 1px;
    }
    label {
      margin-left: 15px;
      span {
        margin: 0;
      }
    }
    &.active {
      background: ${(props) => props.theme.themeSelector.listBackgroundActiveColor};
    }
  }
`;

const ClearAllButton = styled.button`
  color: ${(props) => props.theme.createReport.sidebar.clearAllColor};
  font-size: 11px;
  text-transform: uppercase;
`;

const ClearAllIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CLOSE,
  color: props.theme.createReport.sidebar.clearAllColor,
}))`
  margin-right: 3px;
`;

const TimeRangeWrapper = styled.div`
  margin-bottom: 33px;
`;

const StyledFilterBar = styled(FilterBar)`
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
`;

const RecipientListHeading = styled.h5`
  color: ${({ theme }) => theme.createReport.sidebar.altTextColor};
  font-size: 11px;
  margin: 10px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.createReport.sidebar.altSeparationColor};
`;

const TextInputError = styled.label`
  color: ${(props) => props.theme.dangerColor};
  margin-left: 5px;
`;

const INPUT_CHAR_LIMIT = 50;
const TEXT_AREA_CHAR_LIMIT = 150;

export const DefineParameters = withTheme(({ theme, onClearAll }) => {
  const { themeSelector } = theme;
  const { t } = useTranslation([NAMESPACE], {
    useSuspense: false,
  });

  // HOOKS //
  const { push } = useHistory();
  const { timezone } = useReportScope();
  const { setParameters, setCreateMode, resetReportState, initalReportState, reportState } =
    useContext(ReportsContext);

  const { name: templateName } = initalReportState ?? {};
  const {
    baseTemplate,
    name: reportName,
    description,
    scope: currentScope,
    scopeType: currentScopeType,
    distributionList,
  } = reportState ?? {};

  const reportNameRef = useRef();
  const [reportNameError, setReportNameError] = useState(false);
  const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState(false);
  const [templateConflictError, setTemplateConflictError] = useState(false);

  const reportNameErrorMessage = useMemo(() => {
    if (reportNameError) {
      return t('report_name_error', 'Please enter a report name');
    }
    if (templateConflictError) {
      return t('template_name_conflict', 'This name is already in use, enter a new name');
    }
    return null;
  }, [reportNameError, templateConflictError, t]);

  useEffect(() => {
    if (reportNameErrorMessage && reportNameRef.current) {
      reportNameRef.current.focus();
    }
  }, [reportNameErrorMessage]);

  // HANDLERS //
  const handleNameChange = useCallback(
    (event) => {
      setParameters(InputStateKeys.NAME, event.target.value);
      setTemplateConflictError(false);
      setReportNameError(false);
    },
    [setParameters],
  );

  const handleDescriptionChange = useCallback(
    (event) => {
      setParameters(InputStateKeys.DESCRIPTION, event.target.value);
    },
    [setParameters],
  );

  const handleUpdateDistributionList = useCallback(
    (updatedList) => {
      setParameters(InputStateKeys.DISTRIBUTION_LIST, updatedList);
    },
    [setParameters],
  );

  const handleCancel = useCallback(() => {
    resetReportState();
    push(`/reporting/reports/my-reports/`);
  }, [resetReportState, push]);

  const handleTemplateConflict = useCallback(() => {
    setTemplateConflictError(true);
    setIsSaveTemplateModalOpen(false);
  }, []);

  const { isAuthorized } = useAuth();
  const canEditReportTemplates = isAuthorized({
    capabilities: [{ capability: Capability.REPORTING_TEMPLATES, scopes: [PermissionScope.EDIT] }],
  });

  const saveTemplateButtonLabel = useMemo(() => {
    if (!canEditReportTemplates) {
      return null;
    }

    return baseTemplate
      ? t('save_new_template', 'Save New Template')
      : t('save_to_template', 'Save To Template');
  }, [canEditReportTemplates, baseTemplate, t]);

  const saveTemplateEnabled = useMemo(() => {
    if (!(reportState && initalReportState && canEditReportTemplates)) {
      return false;
    }

    // Require template name change when Report Name field is in an error state
    if (reportNameErrorMessage) {
      return false;
    }

    // Backend needs a scope for a template to be saved
    if (!reportState.scope) {
      return false;
    }

    // Simple comparisons: scope, name, description, etc.
    if (
      reportState.scope !== initalReportState.scope ||
      reportState.name !== initalReportState.name ||
      reportState.description !== initalReportState.description ||
      reportState.distributionList?.length !== initalReportState.distributionList?.length
    ) {
      return true;
    }

    // Distribution list comparisons
    if (
      reportState.distributionList?.some(
        (emailRecipient) => !initalReportState.distributionList?.includes(emailRecipient),
      )
    ) {
      return true;
    }

    // Time range comparisons
    // Custom time ranges are not currently supported for saving to template
    if (
      reportState.timeRange !== initalReportState.timeRange &&
      reportState.timeRange !== DateRange.CUSTOM
    ) {
      return true;
    }

    return false;
  }, [reportState, initalReportState, canEditReportTemplates, reportNameErrorMessage]);

  const handleSaveTemplateClick = useCallback(() => {
    if (!reportName) {
      setReportNameError(true);
      return;
    }
    if (baseTemplate && reportName === templateName) {
      setTemplateConflictError(true);
      return;
    }
    setIsSaveTemplateModalOpen(true);
  }, [baseTemplate, reportName, templateName]);

  const handlePrepareReport = useCallback(() => {
    if (!reportName) {
      setReportNameError(true);
      return;
    }
    setReportNameError(false);
    setCreateMode(CreateModes.VIEW);

    // NOTE: Dayjs does not handle formatting UTC in formatters well, so hard-coding "UTC" below.
    dayjs.extend(utc);
    setParameters(InputStateKeys.CREATED_TIME, dayjs.utc());
  }, [reportName, setCreateMode, setParameters]);

  const handleFilterChange = useCallback(
    (dateRange) => {
      const timeRange = dateRange?.range ?? dateRange;
      setParameters(InputStateKeys.TIME_RANGE, timeRange);

      if (timeRange === DateRange.CUSTOM) {
        setParameters(InputStateKeys.CUSTOM_START_DATE, dateRange.startDate);
        setParameters(InputStateKeys.CUSTOM_END_DATE, dateRange.endDate);
      }
    },
    [setParameters],
  );

  // vvv SCOPE SELECTOR vvv //

  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const [filterText, setFilterText] = useState('');
  const filterTextLower = filterText.toLowerCase();

  const {
    getServiceGroupSites,
    getViewServiceGroupsSites: viewServiceGroups = [],
    sitesForView = [],
  } = useStoreState((state) => state.sites);

  const combinedSitesAndServiceGroups = useMemo(() => {
    const viewServiceGroupsSiteIds = viewServiceGroups.flatMap((vsg) => vsg.sites.map((s) => s.id));
    const sitesForViewSiteIds = sitesForView.map((s) => s.id);
    const ungroupedSiteIds = difference(sitesForViewSiteIds, viewServiceGroupsSiteIds);
    const ungroupedSites = ungroupedSiteIds.map((id) => sitesForView.find((s) => s.id === id));

    return [...viewServiceGroups, ...ungroupedSites].sort((a, b) => a.name.localeCompare(b.name));
  }, [sitesForView, viewServiceGroups]);

  const filteredSitesAndServiceGroups = useMemo(() => {
    if (filterTextLower.length > 0) {
      return combinedSitesAndServiceGroups.filter((siteOrServiceGroup) => {
        let filterMatches = siteOrServiceGroup.name?.toLowerCase().includes(filterTextLower);
        if (!filterMatches && siteOrServiceGroup.sites) {
          const serviceGroupSites = [...getServiceGroupSites(siteOrServiceGroup).values()].flat();
          filterMatches = serviceGroupSites.some((s) =>
            s.name?.toLowerCase().includes(filterTextLower),
          );
        }
        return filterMatches;
      });
    }
    return combinedSitesAndServiceGroups;
  }, [combinedSitesAndServiceGroups, filterTextLower, getServiceGroupSites]);

  const selectedScope = useMemo(() => {
    switch (currentScopeType) {
      case AttributeGroups.SERVICE_GROUP: {
        const serviceGroup = filteredSitesAndServiceGroups?.find(
          (sg) => sg.id === currentScope && sg.sites,
        );
        if (serviceGroup) {
          setParameters(InputStateKeys.SCOPE_NAME, serviceGroup.name);
          return serviceGroup;
        }
        break;
      }
      case AttributeGroups.SITES: {
        const site = sitesForView?.find((s) => s.id === currentScope);
        if (site) {
          setParameters(InputStateKeys.SCOPE_NAME, site.name);
          return site;
        }
        break;
      }
      default:
        return null;
    }
  }, [currentScopeType, currentScope, filteredSitesAndServiceGroups, setParameters, sitesForView]);

  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: filteredSitesAndServiceGroups.length,
    parentRef,
    estimateSize: React.useCallback(() => 29, []),
    overscan: 4,
  });

  const handleClearAll = useCallback(() => {
    onClearAll();
  }, [onClearAll]);

  const entitySelect = useMemo(() => {
    if (selectedScope) {
      return (
        <div>
          {!selectedScope.sites && <SiteIcon />}
          {selectedScope.sites && <GroupIcon />}
          {selectedScope.name}
        </div>
      );
    } else {
      return <>{t('select_scope', 'Select Site / Service Group')}</>;
    }
  }, [selectedScope, t]);

  const updateScope = (entity, entityType) => {
    setShowSelectMenu(false);
    setParameters(InputStateKeys.SCOPE, entity.id);
    setParameters(InputStateKeys.SCOPE_TYPE, entityType);
  };

  const renderSite = (site) => {
    return (
      <li key={`${site.id}`}>
        <button type="button" onClick={() => updateScope(site, AttributeGroups.SITES)}>
          <SiteIcon />
          <BoldSearch text={site.name} textBold={filterTextLower} />
        </button>
      </li>
    );
  };

  const renderAllSites = (sitesArray) =>
    sitesArray
      .sort((a, b) => a.name?.localeCompare(b.name))
      .reduce((arr, site) => {
        arr.push(renderSite(site));
        return arr;
      }, []);

  const renderGroupWithSites = (parentEntity, sitesArray, ref) => {
    if (!sitesArray?.length) {
      return null;
    }

    const filterApplied = filterTextLower.length > 0;
    const filterMatchesParent =
      filterApplied && parentEntity.name?.toLowerCase().includes(filterTextLower);
    const filterMatchesChild =
      filterApplied && sitesArray.some((s) => s.name?.toLowerCase().includes(filterTextLower));

    // Skip group when filter is applied and does not match parent or any children
    if (filterApplied) {
      if (!filterMatchesParent && !filterMatchesChild) {
        return null;
      }
    }

    return (
      <div key={`${parentEntity.id}`} ref={ref}>
        <CollapsiblePanel
          expanded={filterMatchesChild}
          headerContent={
            <SectionHeader onClick={() => updateScope(parentEntity, AttributeGroups.SERVICE_GROUP)}>
              <GroupIcon />
              <span>
                <BoldSearch text={parentEntity.name} textBold={filterText} />
              </span>
              <Badge
                medium
                label={sitesArray?.length.toString()}
                color={themeSelector.badgeColor}
                className="badge"
              />
            </SectionHeader>
          }
        >
          <StyledUl>{renderAllSites(sitesArray)}</StyledUl>
        </CollapsiblePanel>
      </div>
    );
  };

  const renderUngroupedSite = (site, ref) => {
    // Skip site when filter is applied and does not match site name
    if (filterTextLower.length > 0) {
      if (!site.name?.toLowerCase().includes(filterTextLower)) {
        return null;
      }
    }

    return (
      <div key={`${site.id}`} ref={ref}>
        <CollapsiblePanel
          expanded={false}
          enableToggle={false}
          headerContent={
            <SectionHeader>
              <UngroupedSiteContainer>
                <button type="button" onClick={() => updateScope(site, AttributeGroups.SITES)}>
                  <SiteIcon />
                  <span>
                    <BoldSearch text={site.name} textBold={filterTextLower} />
                  </span>
                </button>
              </UngroupedSiteContainer>
            </SectionHeader>
          }
        ></CollapsiblePanel>
      </div>
    );
  };

  const virtualItems = rowVirtualizer.virtualItems;
  const paddingTop = virtualItems.length > 0 ? virtualItems[0].start : 0;
  const paddingBottom =
    virtualItems.length > 0
      ? rowVirtualizer.totalSize - virtualItems[virtualItems.length - 1].end
      : 0;

  const renderSitesAndServiceGroups = (virtualItems) => {
    return virtualItems.reduce((arr, { index, measureRef }) => {
      const siteOrServiceGroup = filteredSitesAndServiceGroups[index];
      if (siteOrServiceGroup.sites) {
        const serviceGroupSites = [...getServiceGroupSites(siteOrServiceGroup).values()].flat();
        return [...arr, renderGroupWithSites(siteOrServiceGroup, serviceGroupSites, measureRef)];
      }
      return [...arr, renderUngroupedSite(siteOrServiceGroup, measureRef)];
    }, new Array());
  };

  // ^^^ SCOPE SELECTOR ^^^ //

  if (!reportState) {
    return null;
  }

  const getCustomDateRange = () => {
    if (reportState.timeRange !== DateRange.CUSTOM) {
      return null;
    }

    return {
      endDate: reportState[InputStateKeys.CUSTOM_END_DATE],
      startDate: reportState[InputStateKeys.CUSTOM_START_DATE],
    };
  };

  return (
    <>
      <SidebarHeader>
        <SidebarHeaderRow>
          <TemplateTitle>
            <TitleLabel>{t('template', 'Template')}:</TitleLabel>
            {templateName}
          </TemplateTitle>
        </SidebarHeaderRow>
        <SidebarHeaderRow>
          <h2>{t('define_parameters', 'Define Parameters')}</h2>
          <ClearAllButton onClick={handleClearAll}>
            <ClearAllIcon />
            {t('clear_all', 'Clear All')}
          </ClearAllButton>
        </SidebarHeaderRow>
      </SidebarHeader>

      <SidebarContent>
        <ScrollingContainer>
          <ContentArea>
            <InputWrapper>
              <Label>{t('name', 'Name')}</Label>
              <Input
                ref={reportNameRef}
                type="text"
                value={reportName}
                onChange={handleNameChange}
                maxLength={INPUT_CHAR_LIMIT}
                error={reportNameErrorMessage}
              />
              {reportNameErrorMessage && <TextInputError>{reportNameErrorMessage}</TextInputError>}
            </InputWrapper>
            <TextareaWrapper>
              <Label>
                {t('description', 'Description')}{' '}
                <CharLimit>{`${TEXT_AREA_CHAR_LIMIT} ${t('char_limit', 'char limit')}`}</CharLimit>
              </Label>
              <StyledTextarea
                value={description}
                onChange={handleDescriptionChange}
                maxLength={TEXT_AREA_CHAR_LIMIT}
              />
            </TextareaWrapper>
          </ContentArea>
          {/* TimeRange/Scope Content */}
          <ContentArea>
            <TimeRangeWrapper>
              <Label>{t('time_range', 'Time Range')}</Label>
              <StyledFilterBar
                defaultDateRange={reportState.timeRange}
                onChange={handleFilterChange}
                persist={reportState.timeRange === DateRange.CUSTOM}
                rangeDef={RangeDefs.RANGE_BY_MONTH_CURRENT}
                timezone={timezone}
                externalDateRange={getCustomDateRange()}
              />
            </TimeRangeWrapper>
            <Label>{t('scope', 'Scope')}</Label>
            {!baseTemplate && selectedScope && (
              <Scope>
                {!selectedScope.sites && <SiteIcon />}
                {selectedScope.sites && <GroupIcon />}
                {selectedScope.name}
              </Scope>
            )}
            {baseTemplate && (
              <ScopeSelectorContainer>
                <EntitySelectMenu>
                  <EntitySelect
                    id="entityList"
                    onClick={() => setShowSelectMenu((currentValue) => !currentValue)}
                  >
                    {entitySelect}
                    <ChevronIcon />
                  </EntitySelect>
                  <SelectMenu className={showSelectMenu ? 'show' : ''}>
                    <SearchWrapper>
                      <Filter
                        text={filterText}
                        onChange={(event) => setFilterText(event.target.value)}
                        onInputEscaped={() => {}}
                        placeholder={t('form.search', 'Search')}
                        hideResultsOnBlur={false}
                      />
                    </SearchWrapper>
                    <SelectResultsContainer>
                      <ScrollingContainer ref={parentRef}>
                        <StyledVirtualResults paddingTop={paddingTop} paddingBottom={paddingBottom}>
                          {renderSitesAndServiceGroups(virtualItems)}
                        </StyledVirtualResults>
                      </ScrollingContainer>
                    </SelectResultsContainer>
                  </SelectMenu>
                </EntitySelectMenu>
              </ScopeSelectorContainer>
            )}
          </ContentArea>
          <ContentArea>
            <Label>{t('add_recipients', 'Add Recipients')}</Label>
            <LabelInfo>
              {t('recipients_info', 'More can be added before sending (limit 50)')}
            </LabelInfo>
            <RecipientListHeading>{t('send_to', 'Send To')}</RecipientListHeading>
            <EmailRecipients
              distributionList={distributionList}
              onDistributionListChanged={handleUpdateDistributionList}
              inputStateKeys={InputStateKeys}
            />
          </ContentArea>
          <ActionButtons
            primaryLabel={t('prepare_report', 'Prepare Report')}
            primaryEnabled={Boolean(selectedScope)}
            onPrimaryClick={handlePrepareReport}
            onSecondaryClick={handleCancel}
            showAlternate={canEditReportTemplates}
            alternateLabel={saveTemplateButtonLabel}
            alternateEnabled={saveTemplateEnabled}
            onAlternateClick={handleSaveTemplateClick}
          />
        </ScrollingContainer>
      </SidebarContent>
      <SaveTemplateModal
        isOpen={isSaveTemplateModalOpen}
        onModalClose={() => setIsSaveTemplateModalOpen(false)}
        onTemplateConflict={handleTemplateConflict}
        distributionList={distributionList}
      />
    </>
  );
});
