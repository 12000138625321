import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu, MenuTable, placements } from '@ge/components/menu';
import { DayJs, Placeholders, ReasonType, DateTimeFormats } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
`;

const StyledTr = styled.tr`
  td {
    font-size: 11px;
    letter-spacing: 0;
    color: ${(props) => props.theme.table.dataTextColor};
    font-weight: ${(props) => (props.isBold ? 700 : 300)};
    div {
      text-transform: none;
      text-align: left;
    }
    &.heading {
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    &:nth-of-type(2) {
      min-width: 130px;
      max-width: 200px;
      opacity: 0.6;
    }
    &:last-of-type {
      div {
        padding-right: 11px;
      }
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export const SnoozeDetails = ({ endTime, reason, children, containerRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['monitor.issues', 'entity-details'], { useSuspense: false });
  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const getReasonLabel = (reason) => {
    switch (reason) {
      case ReasonType.BATTERIES_CHARGING:
        return t('entity-details:case_panel.batteries_charging', 'Batteries Charging');
      case ReasonType.COMPONENT_COOLING:
        return t('entity-details:case_panel.component_cooling', 'Component Cooling');
      case ReasonType.COMPONENT_WARMING:
        return t('entity-details:case_panel.component_warming', 'Component Warming');
      case ReasonType.OWNED_BY_SITE:
        return t('entity-details:case_panel.owned_by_site', 'Owned by Site');
      default:
        reason;
    }
  };

  const getFormattedTime = (time) => {
    const snoozeEndTime = dayjs(time).format(DateTimeFormats.SNOOZE_END_TIME);
    if (snoozeEndTime === DayJs.INVALID_DATE) return Placeholders.DASH;
    return snoozeEndTime;
  };

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      <div>{children}</div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.RIGHT}
        onClick={(e) => {
          e.preventDefault();
          killEventPropagation(e);
        }}
      >
        <StyledTitle>
          <h4 className="menu-title-txt">{t('snoozeDetails.snoozeHeader', 'SNOOZED')}</h4>
        </StyledTitle>
        <MenuTable disableHover={true}>
          <table>
            <tbody>
              <>
                <StyledTr>
                  <td className="heading">{t('snoozeDetails.end_time', 'End Time:')}</td>
                  <td>
                    <div>{getFormattedTime(endTime)}</div>
                  </td>
                </StyledTr>
                <StyledTr>
                  <td className="heading">{t('snoozeDetails.reason', 'Reason:')}</td>
                  <td>
                    <div>{getReasonLabel(reason) ?? Placeholders.DASH}</div>
                  </td>
                </StyledTr>
              </>
            </tbody>
          </table>
        </MenuTable>
      </Menu>
    </div>
  );
};

SnoozeDetails.propTypes = {
  endTime: PropTypes.string,
  reason: PropTypes.string,
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
};
