import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { Tooltip } from '@ge/components/tooltip';
import {
  Capability,
  AlertsEntityType,
  EntityType,
  TaskSourceField,
  FormMode,
  NoteSelectOptions,
  NotesPageSize,
  AlertType,
  PermissionScope,
  Placeholders,
  EntityTypeMapping,
} from '@ge/models/constants';
import { AssetConnectionStatus } from '@ge/models/constants';
import { AssetType } from '@ge/models/entities/asset/constants';
import { AlertDialog } from '@ge/shared/components/alerts/alert-dialog';
import { AlertHeaderIcons } from '@ge/shared/components/alerts/alert-header-icons';
import { AssetPreviousState } from '@ge/shared/components/asset-previous-state';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ContactDialog } from '@ge/shared/components/contacts/contact-dialog';
import { NewTaskDialog } from '@ge/shared/components/tasks/new-task-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAssetRealTimeData, useAuth } from '@ge/shared/data-hooks';
import { useAlertsHeader } from '@ge/shared/data-hooks';
import { useAlertDialog } from '@ge/shared/hooks';
import { getAssetIcon } from '@ge/shared/util/asset-icon';
import { getConnectionStatusDuration } from '@ge/shared/util/time-date';
import { getTurbineStateType, getTurbineStateColor } from '@ge/shared/util/turbine-state';
import { typography } from '@ge/tokens';
import { elevations } from '@ge/tokens/elevations';

import { useNotesPaginationDetails } from '../../../hooks/use-notes-details';
import HeaderCurrentCustomer from '../components/header-current-customer';
import HeaderCurrentSite from '../components/header-current-site';
import SpecialInstructions from '../components/special-instructions';
// import OverviewActiveHeader from '../components/overview-active-header';
// import OverviewContactHeader from '../components/overview-contact-header';
import EntityDetailHeader, {
  HeaderIcon,
  StyledDetailHeader,
  StyledDetailMetrics,
} from '../entity-details-header';
import { DetailsCurrent } from '../entity-details-shared';
import EntityDetailsWeather from '../entity-details-weather';

// import AssetModel from './asset-model';

const StatusBadge = styled(Badge)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: ${elevations.P10};
`;

const AssetIcon = styled(Icon).attrs((props) => ({
  size: 36,
  color: props.theme.entityDetails.headerIconColor,
}))`
  padding: 12px 10px;
  z-index: ${elevations.P1};
`;

const AssetName = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-top: 2px;
  max-width: 350px;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
  > * {
    + * {
      margin-left: 5px;
    }
  }
  > button {
    margin-top: 1px;
  }
`;

const NoCommText = styled.div`
  flex: 1;
  display: flex;
  font-size: 10px;
  font-family: Museo Sans;
  font-weight: ${typography.weight.bold};
  align-items: end;
  color: ${(props) => props.theme.table.noDataColor};
  padding: ${(props) => props.isDisconnectedStyle && '3px 0 10px 0'};
`;

const StyledEntityDetailHeader = styled(EntityDetailHeader)`
  &.instruction-header {
    margin-bottom: ${(props) => (props.isShowMore ? '114px' : '102px')};
  }
`;

const AssetDetailsHeader = ({ assetId, entityType }) => {
  const { t } = useTranslation(['entity-details'], { useSuspense: false });
  const { t: trans } = useTranslation(['monitor.sites'], { useSuspense: false });
  const { showSiteDetails, showStorageSiteDetails } = useContext(EntityDetailsContext);
  const [showNewTaskDialog, setShowNewTaskDialog] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);

  // data hooks
  const { data: asset, isLoading } = useAssetRealTimeData({ assetId });

  const { isAuthorized } = useAuth();
  const siteId = asset?.site?.id;
  const authorizedSI = isAuthorized({
    capabilities: [{ capability: Capability.SPECIAL_INSTRUCTION, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });
  const authorizedAlerts = isAuthorized({
    capabilities: [{ capability: Capability.ALERTS, scopes: [PermissionScope.VIEW] }],
    siteIds: [siteId],
  });

  const { data: instructionData } = useNotesPaginationDetails(
    {
      assetType: EntityType.ASSET,
      id: assetId,
      notesType: NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION,
      pageSize: NotesPageSize,
      siteId,
    },
    authorizedSI,
  );

  const instructionItems = instructionData?.pages?.[0]?.notes?.length;

  // util hooks
  const {
    alerts: assetAlerts,
    groupDetails,
    currentAlert,
    showAlertDialog,
    handleSaveAlert,
    handleEditAlert,
    handleCancelAlertDialog,
    handleShowAlertDialog,
    handleViewAlert,
  } = useAlertDialog(
    { entity: asset, entityType: EntityTypeMapping[asset.type] },
    authorizedAlerts,
  );

  const { data: siteAlerts } = useAlertsHeader(
    {
      entityId: siteId,
      entityType:
        entityType === EntityType.INVERTER ? AlertsEntityType.STORAGE_SITE : AlertsEntityType.SITE,
    },
    authorizedAlerts,
  );

  const { state, conditions, connection, prevState } = asset.metrics ?? {};

  const currentSiteClickHandler = useCallback(
    (siteId, type) => {
      switch (type) {
        case AssetType.WIND_TURBINE:
          showSiteDetails(siteId);
          break;
        case AssetType.STORAGE_INVERTER:
          showStorageSiteDetails(siteId);
          break;
        case AssetType.SITE_CONTROLLER:
        case AssetType.SUBSTATION:
          if (entityType === EntityType.INVERTER) {
            showStorageSiteDetails(siteId);
          } else {
            showSiteDetails(siteId);
          }
          break;
        default:
          showSiteDetails(siteId);
      }
    },
    [showSiteDetails, showStorageSiteDetails, entityType],
  );

  const handleNewTask = () => setShowNewTaskDialog(true);

  const handleCloseNewTaskDialog = () => setShowNewTaskDialog(false);

  const handleSaveNewTask = () => setShowNewTaskDialog(false);

  const handleContact = () => setShowContactDialog(!showContactDialog);

  // Don't render until we have all the info
  //#TODO: Commenting for MVP0
  // if (!state || !conditions || !maintenance) {
  //   return null;
  // }

  return (
    <StyledEntityDetailHeader
      type={EntityType.ASSET}
      className={instructionItems && authorizedSI ? 'instruction-header' : ''}
      isShowMore={instructionItems > 1}
    >
      <AssetPreviousState prevState={prevState} connectionStatus={connection} currentState={state}>
        <StatusBadge
          small
          color={getTurbineStateColor(state?.value)}
          label={getTurbineStateType(state?.value)}
          isDisconnected={
            connection?.value === AssetConnectionStatus.NOCOMM ||
            connection?.value === AssetConnectionStatus.NO_DATA
          }
        />
      </AssetPreviousState>
      <AssetIcon icon={getAssetIcon(asset.type)} />
      <StyledDetailHeader>
        <DetailsCurrent>
          <HeaderCurrentCustomer
            customerName={asset.site?.customer?.name}
            siteName={asset.site?.name}
          />
          <HeaderCurrentSite
            siteName={asset.site?.name}
            assetType={asset?.type}
            entityType={entityType}
            onClick={() => currentSiteClickHandler(asset?.site?.id, asset?.type)}
            alerts={<AlertHeaderIcons alerts={siteAlerts} isSiteAlerts />}
          />
        </DetailsCurrent>
        <AssetName>
          <Tooltip title={asset?.name} zIndex={elevations.P20} placement="top-start">
            <h2>{asset?.name}</h2>
          </Tooltip>

          <AuthRender
            capability={Capability.ALERTS}
            view
            description="Asset details header alert icons"
            siteIds={[siteId]}
          >
            <AlertHeaderIcons
              alerts={assetAlerts}
              onClick={(_, alert) => handleViewAlert(alert)}
              isAssetDetail
              headerAlerts
              large
            />
          </AuthRender>
        </AssetName>
        {(connection?.value === AssetConnectionStatus.NOCOMM ||
          connection?.value === AssetConnectionStatus.NO_DATA) && (
          <NoCommText isDisconnectedStyle={connection?.value === AssetConnectionStatus.NO_DATA}>
            {t('asset_panel.no_comm_text', {
              netCommDuration: getConnectionStatusDuration(connection),
              prevState:
                prevState && prevState.value
                  ? trans(`state.${prevState?.value}`)
                  : Placeholders.DASH,
            })}
          </NoCommText>
        )}
      </StyledDetailHeader>
      {/* <OverviewContactHeader /> */}
      <StyledDetailMetrics>
        <div className="make-model">
          {asset.make} {asset.model}
        </div>
        {/* don't show any sensors in the headers for Site controllers */}
        {!isLoading && asset.type === AssetType.WIND_TURBINE && (
          <EntityDetailsWeather weather={conditions} />
        )}
      </StyledDetailMetrics>
      <div className="actions">
        <AuthRender
          capability={Capability.CONTACTS}
          view
          description="Asset details header - contact"
          siteIds={[siteId]}
        >
          <button type="button" onClick={handleContact}>
            <HeaderIcon icon={Icons.PHONE_CONTACT} /> {t('contact', 'Contact')}
          </button>
        </AuthRender>
        {asset?.type === AssetType.WIND_TURBINE && (
          <AuthRender
            capability={Capability.CORE_KPIS}
            view
            description="Asset details header - Analyze Asset"
            siteIds={[siteId]}
          >
            <NavLink type="button" to={`/analyze/asset-overview?assets=${asset.id}`}>
              <HeaderIcon icon={Icons.OVERVIEW} /> {t('asset_panel.analyze_asset', 'Analyze Asset')}
            </NavLink>
          </AuthRender>
        )}
        <AuthRender
          capability={Capability.FIELD_TASKS}
          create
          description="Asset details header - new task button"
          siteIds={[siteId]}
        >
          <button type="button" onClick={handleNewTask}>
            <HeaderIcon icon={Icons.TASK} /> {t('new_task', 'New Task')}
          </button>
        </AuthRender>
        <AuthRender
          capability={Capability.ALERTS}
          create
          description="Asset details header - new alert button"
          siteIds={[siteId]}
        >
          <button type="button" onClick={handleShowAlertDialog}>
            <HeaderIcon icon={Icons.ALERT_NEW} viewbox="0 0 12 12" /> {t('new_alert', 'New Alert')}
          </button>
        </AuthRender>
      </div>
      {/* <OverviewActiveHeader maintenance={maintenance} /> */}
      {showNewTaskDialog && (
        <NewTaskDialog
          entity={asset}
          entityType={EntityType.ASSET}
          onClose={handleCloseNewTaskDialog}
          onConfirm={handleSaveNewTask}
          taskSource={TaskSourceField.MANUAL}
        />
      )}
      {(entityType === EntityType.TURBINE || entityType === EntityType.INVERTER) &&
        !!instructionItems && (
          <AuthRender
            capability={Capability.SPECIAL_INSTRUCTION}
            view
            description="Asset details header specialInstructions"
            siteIds={[siteId]}
          >
            <SpecialInstructions
              asset={assetId}
              entityType={entityType}
              instructionData={instructionData.pages[0].notes}
              totalCount={instructionData?.pages?.[0]?.pagination?.totalRecords}
              assetType={asset}
            />
          </AuthRender>
        )}
      {showContactDialog && (
        <ContactDialog entity={asset} siteId={asset.site.id} onClose={handleContact} />
      )}
      {showAlertDialog && (
        <AlertDialog
          alert={currentAlert}
          groupDetails={groupDetails}
          entity={asset}
          entityType={EntityTypeMapping[asset.type]}
          isOpen
          mode={currentAlert ? FormMode.VIEW : FormMode.CREATE}
          onCancel={handleCancelAlertDialog}
          onConfirm={currentAlert ? handleEditAlert : handleSaveAlert}
          alertType={AlertType.PLANNED_OUTAGE}
        />
      )}
    </StyledEntityDetailHeader>
  );
};

AssetDetailsHeader.propTypes = {
  assetId: PropTypes.string,
  entityType: PropTypes.string,
};

export default AssetDetailsHeader;
