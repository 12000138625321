import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { AuthTab } from '@ge/components/tabs';
import { PermissionScope } from '@ge/models/constants';
import { arrayify } from '@ge/shared/util';

import { serializeLabel } from './tabs-shared';

const StyledTabsWrapper = styled.div`
  &.force-render {
    height: inherit;
    width: inherit;
  }
`;
const StyledPanels = styled.div`
  .force-render & {
    height: 100%;
    position: relative;
    width: 100%;
    > div {
      height: inherit;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      width: inherit;
    }
    .active {
      opacity: 1;
      visibility: visible;
      > div {
        height: inherit;
        width: inherit;
      }
    }
  }
`;

const StyledTabs = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    justify-content: flex-end;
    display: flex;
    padding-right: 15px;
    > div:not(:first-child) {
      align-self: start;
      padding-top: 5px;
    }
  }
`;

const StyledBlock = styled.div`
  border-bottom: solid 3px ${(props) => props.theme.tabs.borderColor};
  flex: 1;
  display: block;
`;

export const Tabs = ({
  className,
  children: _children,
  small,
  large,
  onChangeHandler,
  dropdown,
  defaultTab,
  isForceRenderTabs,
  setActiveTabFromParent,
  headerContent,
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : '');

  const children = useMemo(() => (_children ? arrayify(_children).filter(Boolean) : []), [
    _children,
  ]);

  useEffect(() => {
    // TODO: Make activeTab a prop of Tabs
    setActiveTab(serializeLabel(defaultTab));
  }, [defaultTab]);

  useEffect(() => {
    // try to set an active tab if one isn't defined yet
    if (!activeTab) {
      const label = children[0]?.props?.label;

      setActiveTab(serializeLabel(label));
    }
  }, [children, activeTab, setActiveTab, dropdown]);

  const tabOnClick = (label) => {
    if (!setActiveTabFromParent) {
      setActiveTab(label);
    }
    onChangeHandler(label);
  };

  const buildOptions = useCallback((label) => ({ value: serializeLabel(label), label: label }), []);

  const selectOptions = useMemo(
    () =>
      children.map((child) => {
        const { label } = child?.props ?? {};
        return buildOptions(label);
      }),
    [children, buildOptions],
  );

  return (
    <StyledTabsWrapper className={`${className} ${isForceRenderTabs ? 'force-render' : ''}`}>
      {dropdown ? (
        <>
          {children && (
            <Select
              minWidth={120}
              primary
              onChange={(option) => tabOnClick(option.value)}
              value={selectOptions.filter((option) => option.value === activeTab)}
              options={selectOptions}
            />
          )}
          {headerContent}
        </>
      ) : (
        <StyledTabs className={'auth-tabs'}>
          {children.map((child) => (
            <AuthTab
              activeTab={activeTab}
              capabilities={child.props.capabilities}
              key={child.props.label}
              large={large}
              onTabClick={tabOnClick}
              siteIds={[child.props.siteId]}
              small={small}
              {...child.props}
            />
          ))}
          <StyledBlock>{headerContent}</StyledBlock>
        </StyledTabs>
      )}
      <StyledPanels>
        {children.map((child, index) => {
          if (isForceRenderTabs) {
            return (
              <div
                className={serializeLabel(child?.props.label) === activeTab ? 'active' : ''}
                key={index}
              >
                {child}
              </div>
            );
          } else {
            if (serializeLabel(child?.props.label) !== activeTab) return undefined;
            return child;
          }
        })}
      </StyledPanels>
    </StyledTabsWrapper>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerContent: PropTypes.node,
  small: PropTypes.bool,
  large: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  dropdown: PropTypes.bool,
  defaultTab: PropTypes.string,
  isForceRenderTabs: PropTypes.bool,
  setActiveTabFromParent: PropTypes.bool,
};

Tabs.defaultProps = {
  headerContent: <></>,
  small: false,
  onChangeHandler: () => null,
  dropdown: false,
  defaultTab: '',
  isForceRenderTabs: false,
  setActiveTabFromParent: false,
};

export const Tab = styled.div.attrs({ className: 'Tab' })`
  overflow: hidden;
`;

Tab.propTypes = {
  badgeValue: PropTypes.string,
  badgeColor: PropTypes.string,
  capabilities: PropTypes.arrayOf(
    PropTypes.shape({
      capability: PropTypes.object,
      scopes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PermissionScope))),
    }),
  ),
  label: PropTypes.string.isRequired,
};
