import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Input } from '@ge/components/input/input';
import { Dialog } from '@ge/components/modal';
import { Select } from '@ge/components/select';
import { Severity } from '@ge/components/severity';
import { CasePriority, EntityType } from '@ge/models/constants';
import { CaseAffectedAssets } from '@ge/shared/components/cases/case-affected-assets';
import { TaskProvider } from '@ge/shared/components/tasks/task-provider';
import { useCreateCases } from '@ge/shared/data-hooks/use-create-case';

const CaseDialogSegment = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  &:first-of-type {
    margin-top: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.caseInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const CaseDialogSegmentTitle = styled.div`
  color: white;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const AddCaseFormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 15px;
  div:first-of-type {
    flex-grow: 1;
    margin-right: 10px;
  }
  input[type='text'] {
    width: 75%;
  }
`;

const PrioritySelectLabel = styled.span`
  margin-left: 5px;
  text-transform: capitalize;
`;

const AddCaseFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;

  button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

const CaseFooter = ({ onClick, onSubmit, isLoading }) => {
  const { t } = useTranslation(['entity-details']);

  return (
    <AddCaseFooterWrapper>
      <FooterButtons>
        <Button onClick={onClick} disabled={isLoading}>
          {t('general:cancel', 'Cancel')}
        </Button>
        <Button primary onClick={onSubmit} disabled={isLoading}>
          {t('general:save', 'Save')}
        </Button>
      </FooterButtons>
    </AddCaseFooterWrapper>
  );
};

export const NewCaseDialog = ({ isOpen, onClose, selectedEntityId }) => {
  const { t } = useTranslation(['entity-details']);

  const { create, isLoading } = useCreateCases();

  const getAssetById = useStoreState((store) => store.assets.getAssetById);

  const methods = useForm({ mode: 'onBlur' });

  const onSubmit = async (values) => {
    const {
      entityList = [],
      caseTitle: title,
      priority: casePriority,
      component1,
      component2,
      component3,
    } = values;

    const componentIds = [];
    if (component1) {
      componentIds.push(component1);
      if (component2) {
        componentIds.push(component2);
        if (component3) {
          componentIds.push(component3);
        }
      }
    }

    const priority = casePriority ? String(casePriority.value).toLowerCase() : '';
    const casesToBeLoaded = entityList.map((assetId) => ({
      assetId,
      siteId: getAssetById(assetId)?.site?.id,
      title,
      priority,
      componentIds,
    }));

    const result = await create({ casesToBeLoaded });

    if (result) {
      onClose(result);
    } else {
      methods.trigger();
    }
  };

  const onConfirm = () => null;

  const prioritySelectOptions = Object.entries(CasePriority).map(([key, value]) => {
    return {
      value: key,
      label: (
        <>
          <Severity level={value} />
          <PrioritySelectLabel>{value}</PrioritySelectLabel>
        </>
      ),
    };
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      header={t('case.new_case', 'Create New Case')}
      footer={
        <CaseFooter
          isLoading={isLoading}
          onClick={onClose}
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      }
      contentWidth
    >
      <FormProvider {...methods}>
        <CaseDialogSegment>
          <TaskProvider>
            <CaseAffectedAssets entity={selectedEntityId} entityType={EntityType.ASSET} />
          </TaskProvider>
        </CaseDialogSegment>
        <CaseDialogSegment>
          <CaseDialogSegmentTitle>
            {t('add_case.case_detail', 'Case Detail')}
          </CaseDialogSegmentTitle>

          <AddCaseFormRow>
            <Controller
              name="caseTitle"
              rules={{
                required: true,
              }}
              defaultValue=""
              render={({ onChange, value }) => (
                <Input
                  id="case-title-input"
                  label={`${t('new_case.title', 'Title')}*`}
                  type="text"
                  placeholder={`${t('add_case.title-placeholder', 'Type case name')}...`}
                  name="title"
                  onChange={onChange}
                  value={value}
                  error={methods.errors?.caseTitle}
                />
              )}
            />

            <Controller
              name="priority"
              rules={{
                required: true,
              }}
              defaultValue={prioritySelectOptions[0]}
              render={({ onChange, value }) => (
                <Select
                  id="case-priority-select"
                  label={`${t('new_case.priority', 'Priority')}*`}
                  onChange={onChange}
                  value={value}
                  options={prioritySelectOptions}
                  name="priority"
                />
              )}
            />
          </AddCaseFormRow>
        </CaseDialogSegment>
      </FormProvider>
    </Dialog>
  );
};

CaseFooter.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

CaseFooter.defaultValues = {
  isLoading: false,
  onClick: () => null,
  onSubmit: () => null,
};

NewCaseDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedEntityId: PropTypes.object,
};

NewCaseDialog.defaultValues = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
  selectedEntityId: undefined,
};
