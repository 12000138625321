import styled from 'styled-components';

import { Icon } from '@ge/components/icon';
import { DAY_PADDING } from '@ge/feat-manage/context/planning-provider';
import { TaskStatus } from '@ge/models';

export const TaskTypeIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color:
    (props.status === TaskStatus.SCHEDULED && props.theme.manage.cards.scheduledIconColor) ||
    (props.status === TaskStatus.UNSCHEDULED && props.theme.manage.cards.unscheduledIconColor) ||
    (props.status === TaskStatus.COMPLETE && props.theme.manage.cards.completedIconColor),
}))`
  margin: 6px 1px 4px;
`;

export const BundleIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.theme.manage.cards.bundleBadge,
}))`
  position: relative;
  top: 1px;
  left: 4px;
`;

export const BundleBadge = styled.div`
  color: ${(props) => props.theme.manage.cards.bundleBadge};
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  height: 15px;
  line-height: 11px;
  min-width: 20px;
  background-color: ${(props) => props.theme.manage.tabs.badge.backgroundColor};
  border-radius: 4px;
  display: flex;
  position: absolute;
  top: -12px;
  right: -7px;
  &.month {
    left: 8px;
    right: auto;
  }
  &.quarter {
    left: 2px;
    right: auto;
  }
  > .bundle-count {
    margin-left: -6px;
    position: relative;
    top: 2px;
    left: 2px;
    margin-right: 4px;
  }
`;

export const TaskCard = styled.div`
  cursor: pointer;
  position: absolute;
  left: ${({ position }) => position}px;
  top: 20px;
  height: 68px;
  width: ${({ width }) => width}px;
  border-radius: 3px;
  ${({ bundlePresent, theme }) =>
    bundlePresent
      ? `border:${theme.manage.cards.bundleTaskBorder}`
      : `border:${theme.manage.cards.border}`};
  &.day {
    top: ${({ top }) => top}px;
  }
  &.isoweek,
  &.month {
    margin-left: ${DAY_PADDING - 2}px;
  }
  &.quarter {
    margin: 0 1px;
  }
  > .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    user-select: none;
    > .task-count {
      text-align: center;
      margin-top: 18px;
      font-weight: bold;
    }
  }
  > .asset-name {
    overflow: hidden;
    display: flex;
    align-items: center;
    user-select: none;
    margin: 2px 5px;
    border-bottom: ${({ theme }) => theme.manage.cards.border};
  }
  > .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 5px;
  }
  > .type-container {
    display: flex;
    justify-content: space-between;
    margin: 2px 5px;
    align-items: center;
    .type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.unscheduled {
    background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    color: ${({ theme }) => theme.manage.cards.unscheduledTextColor};
    > .type-container .type {
      color: ${({ theme }) => theme.manage.cards.unscheduledTypeTextColor};
    }
  }
  &.scheduled {
    background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
    > .type-container .type {
      color: ${({ theme }) => theme.manage.cards.scheduledTypeTextColor};
    }
  }
  &.completed {
    background-color: ${({ theme }) => theme.manage.cards.completedColor};
    color: ${({ theme }) => theme.manage.cards.completedTextColor};
    > .type-container .type {
      color: ${({ theme }) => theme.manage.cards.completedTypeTextColor};
    }
  }
  &.partial {
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.manage.cards.scheduledColor},
      ${({ theme }) => theme.manage.cards.completedColor}
    );
    > .type-container .type {
      color: ${({ theme }) => theme.manage.cards.completedTypeTextColor};
    }
  }
  &.selected {
    border-color: ${({ theme }) => theme.manage.cards.selectedBorder};
    &:after {
      content: '';
      background: ${({ theme }) => theme.manage.cards.selectedHalo};
      position: absolute;
      left: -5px;
      display: block;
      top: -5px;
      right: -5px;
      bottom: -5px;
      z-index: -1;
      border-radius: 5px;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.7);
    }
  }
  &.draggable {
    cursor: ${(props) => (props.draggable ? 'grab' : 'not-allowed')};
  }
`;
