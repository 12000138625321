import { PropTypes } from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageContainer } from '@ge/components/containers';
import { DraggableTable, DynamicTable, TableArrow } from '@ge/components/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { formatKPIValue } from '@ge/feat-analyze/util';
import { EntityTab } from '@ge/models/constants';
import {
  AllSitesColumnDefs,
  SitesColumns,
} from '@ge/shared/models/table-col-defs/sites-list-table-cols';

import { EntityDetailsContext } from '../../context';
import { killEventPropagation } from '../../util';

import SiteListTablePlatform from './site-list-table-platform';

const SiteDetailButton = styled.button`
  padding: 8px 0;
  width: 100%;
`;

// Component Table
export const SiteListTable = ({
  columns,
  isLoading,
  sites,
  region,
  sortAction,
  sortMetric,
  sortDirection,
  onSiteSelect,
  onDrop,
  scrollable,
  draggable,
  onFilter,
  onFilterChange,
  filterDefs,
  filterValues,
}) => {
  // Translation keys
  const { t, ready, i18n } = useTranslation(['analyze.dashboard'], { useSuspense: false });

  const { showSiteDetails } = useContext(EntityDetailsContext);

  // Table State / handlers
  const sortedDirection = useCallback((metric) => (metric === sortMetric ? sortDirection : ''), [
    sortMetric,
    sortDirection,
  ]);

  const handleSiteClick = useCallback(
    (e, site) => {
      killEventPropagation(e);
      showSiteDetails(site.id, EntityTab.PERFORMANCE);
    },
    [showSiteDetails],
  );
  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilter(columnKey, value);
    },
    [onFilter],
  );

  const handleFilterChange = useCallback((_, key, value) => onFilterChange({ key, value }), [
    onFilterChange,
  ]);
  /**
   * Function used by useTableFactories to bootstrap the cell values map
   * before returning the factory to process cells for that row. This should
   * contain values for all columns that should be expected to show data in
   * the table.
   */
  const cellValueMapFn = (regionSite) => {
    if (!regionSite) return {};

    const kpiObjToStr = (kpi) => {
      if (!kpi) return '-';
      const { aggregateValue } = kpi;
      return `${formatKPIValue(aggregateValue, i18n.language)}`;
    };

    const {
      site,
      siteId,
      platform,
      tba,
      unproducedEnergyContract,
      productionActual,
      capacityFactor,
      eventCoverage,
      reportingAssetsRatio,
      availabilityContract,
      pba,
      regionName,
    } = regionSite;

    return {
      [SitesColumns.REGION]: regionName ?? region,
      [SitesColumns.SITE]: site,
      [SitesColumns.PLATFORM]: platform,
      [SitesColumns.TBA]: kpiObjToStr(tba),
      [SitesColumns.AVAILABILITY_CONTRACT]: kpiObjToStr(availabilityContract),
      [SitesColumns.PBA]: kpiObjToStr(pba),
      [SitesColumns.UNPRODUCED_ENERGY_MWH]: kpiObjToStr(unproducedEnergyContract),
      [SitesColumns.ACTUAL_PRODUCTION]: kpiObjToStr(productionActual),
      [SitesColumns.CAPACITY_FACTOR]: kpiObjToStr(capacityFactor),
      [SitesColumns.EVENT_COVERAGE]: kpiObjToStr(eventCoverage),
      [SitesColumns.REPORTING_VS_EXPECTED]: kpiObjToStr(reportingAssetsRatio),
      [SitesColumns.SITE_DETAIL]: {
        id: siteId,
      },
    };
  };

  // const data = cellValueMapFn();
  // const { filterValues } = useTableFilter({
  //   data: data.sort(sorter(sortMetric, sortDirection)),
  //   filters: filterDefs,
  //   // search,
  // });

  /**
   * Factory function to generate custom table cell components in
   * the case that the contents are not simply primitives.
   */
  const customCellFn = useCallback((columnKey, cellValue) => {
    // const { aggregateValue, value, unit } = cellValue;
    if (!cellValue) {
      cellValue = '-';
    }
    if (cellValue === 'null' || cellValue === 'undefined') cellValue = '-';
    switch (columnKey) {
      case SitesColumns.PLATFORM:
        return <SiteListTablePlatform platforms={cellValue} />;
      case SitesColumns.SITE:
      case SitesColumns.REGION:
      case SitesColumns.TBA:
      case SitesColumns.AVAILABILITY_CONTRACT:
      case SitesColumns.PBA:
      case SitesColumns.UNPRODUCED_ENERGY_MWH:
      case SitesColumns.ACTUAL_PRODUCTION:
      case SitesColumns.CAPACITY_FACTOR:
      case SitesColumns.EVENT_COVERAGE:
      case SitesColumns.REPORTING_VS_EXPECTED:
        return <span>{cellValue}</span>;
      case SitesColumns.SITE_DETAIL:
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        return (
          <SiteDetailButton type="button" onClick={(e) => handleSiteClick(e, cellValue)}>
            <TableArrow />
          </SiteDetailButton>
        );
      default:
        return null;
    }
  }, []);

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: AllSitesColumnDefs,
    cellValueMapFn,
    customCellFn,
    // customHeaderFn,
    sortAction,
    sortedDirection,
    draggable,
    filterValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
  });

  if (!columns || columns.length === 0) return null;

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    sortAction,
    values: sites,
    onValueSelect: onSiteSelect,
    rowKeyProperty: 'siteId',
    dropHandler: draggable ? onDrop : () => null,
    isLoading,
  };

  const TABLE = draggable ? <DraggableTable /> : <DynamicTable {...dynamicProps} />;

  return <PageContainer i18nReady={ready}>{TABLE}</PageContainer>;
};

SiteListTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sites: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  region: PropTypes.string,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onSiteSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
  filterDefs: PropTypes.object,
};

SiteListTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onSiteSelect: () => null,
  onDrop: () => null,
  scrollable: false,
  draggable: false,
  isLoading: false,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
  filterDefs: null,
};
