import dayjs from 'dayjs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Select } from '@ge/components/select';
import {
  DefaultRange,
  DefaultRangeByMonth,
  DefaultRangeByMonthCurrent,
  FilterOptions,
  FilterDefs,
  RangeDefs,
} from '@ge/feat-analyze/models/analyze-filter-defaults';
import { DateRange, DateTimeFormats } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { Breakpoints } from '@ge/web-client/src/assets/styles/mixins';

import { getFilterDateRange } from '../../util/kpi';

const getDefaultDateRange = (rangeDef = RangeDefs.RANGE) =>
  ({
    [RangeDefs.RANGE]: DefaultRange,
    [RangeDefs.RANGE_BY_MONTH]: DefaultRangeByMonth,
    [RangeDefs.RANGE_BY_MONTH_CURRENT]: DefaultRangeByMonthCurrent,
  }[rangeDef]);

const getDateFormat = (rangeDef = RangeDefs.RANGE) =>
  ({
    [RangeDefs.RANGE]: DateTimeFormats.FILTER_CUSTOM_DATE,
    [RangeDefs.RANGE_BY_MONTH]: DateTimeFormats.FILTER_CUSTOM_MONTH,
    [RangeDefs.RANGE_BY_MONTH_CURRENT]: DateTimeFormats.FILTER_CUSTOM_MONTH_CURRENT,
  }[rangeDef]);

const StyledSelect = styled(Select)`
  .select__control {
    border: none;
  }
`;

const CustomDateRange = styled.div`
  margin-left: 10px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
    font-size: 14px;
  }
  ${Breakpoints.tabletDown`
      span {
        font-size: 11px;
        max-width: 74px;
      }
      > div {
        flex: 1;
      }
  `}
`;

const CalendarIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor,
  icon: Icons.CALENDAR,
}))`
  margin-left: 6px;
  vertical-align: initial;
`;

// TODO: look into a potential refactor to remove store persistence logic from here and delegate to parent to handle
// eslint-disable-next-line react/display-name
export const DashboardDateFilter = forwardRef(
  (
    {
      defaultDateRange,
      onChange,
      namespace,
      noCustom,
      noPreview,
      persist,
      rangeDef,
      timezone,
      isDatePersist,
      popsout,
      externalDateRange,
      isPanelDateSelector,
    },
    ref,
  ) => {
    const { t } = useTranslation([namespace], {
      useSuspense: false,
    });
    const datePickerRef = useRef();

    // store
    let { dateRange } = useStoreState(({ analyze }) => analyze);
    let { panelDateRange } = useStoreState(({ analyze }) => analyze);
    if (popsout) dateRange = panelDateRange;
    const { updateDateRange, updatePanelDateRange } = useStoreActions(({ analyze }) => analyze);

    // state
    const [dates, setDates] = useState(() => {
      if (persist) {
        // might need to look into persisted filter supporting month range
        return {
          endDate: dayjs(dateRange.endDate).toDate(),
          range: dateRange.range,
          startDate: dayjs(dateRange.startDate).toDate(),
        };
      }
      return {};
    });

    // tracks date changes in picker before apply is clicked
    const [workingDateRange, setWorkingDateRange] = useState(() => {
      const rehydrate = persist && dateRange.range === DateRange.CUSTOM;
      const currentDate = new Date();
      const endDate = rehydrate ? dayjs(dateRange.endDate).toDate() : currentDate;
      const startDate = rehydrate ? dayjs(dateRange.startDate).toDate() : currentDate;

      return {
        endDate,
        range: DateRange.CUSTOM,
        startDate,
      };
    });

    useEffect(() => {
      if (externalDateRange) {
        setDates({
          endDate: externalDateRange.endDate,
          range: DateRange.CUSTOM,
          startDate: externalDateRange.startDate,
        });
      } else if (externalDateRange === null) {
        // added else if for defaults to today's date for reporting ftr as externalDateRange sent only by reporting ftr, can revisit
        // for other ftr dateRange is persist
        const currentDate = new Date();

        setDates({
          endDate: currentDate,
          range: defaultDateRange ?? getDefaultDateRange(rangeDef),
          startDate: currentDate,
        });
      }
    }, [setDates, externalDateRange, defaultDateRange, rangeDef]);

    // handlers
    const handleDateRange = useCallback(
      ({ value: range }) => {
        if (range === DateRange.CUSTOM) {
          setDates((prev) => ({ ...prev, range: DateRange.CUSTOM }));
          datePickerRef.current.setOpen(true);
        } else {
          if (persist && isDatePersist) {
            updateDateRange({ range });
          }
          if (!isDatePersist) {
            updatePanelDateRange({ range });
          }
          setDates(({ endDate, startDate }) => {
            onChange({ endDate, range, startDate });
            return { endDate, range, startDate };
          });
        }
      },
      [onChange, persist, updateDateRange],
    );

    const handleCustomDateRange = useCallback(() => {
      const { startDate, endDate } = workingDateRange;

      if (startDate && endDate) {
        const updated = {
          endDate,
          range: DateRange.CUSTOM,
          startDate,
        };

        // can button be disabled if valid dates not picked otherwise it just refuses to close which can be confusing
        datePickerRef.current.setOpen(false);

        if (persist && isDatePersist) {
          updateDateRange(updated);
        }
        if (!isDatePersist) {
          updatePanelDateRange(updated);
        }
        setDates(updated);
        // working date range will start with applied dates next time date picker is opened
        setWorkingDateRange(updated);

        onChange(updated);
      }
    }, [onChange, persist, updateDateRange, workingDateRange]);

    const handleDatePickerChange = useCallback(
      ([startDate, endDate]) => setWorkingDateRange((prev) => ({ ...prev, startDate, endDate })),
      [],
    );

    // set starting dates next time date picker is opened
    const handleDatePickerClose = useCallback(() => {
      if (dates.startDate && dates.endDate) {
        setWorkingDateRange(dates);

        return;
      }

      // nothing applied so reset working dates to default
      setWorkingDateRange({
        endDate: new Date(),
        range: DateRange.CUSTOM,
        startDate: new Date(),
      });
    }, [dates]);

    const renderDates = useCallback(() => {
      const {
        startDate: { entityTimezone: startDate },
        endDate: { entityTimezone: endDate },
      } = getFilterDateRange({
        range: dates.range,
        entityTimezone: timezone,
        dateFormat: getDateFormat(rangeDef),
      });

      return `${startDate} - ${endDate}`;
    }, [dates.range, rangeDef, timezone]);

    // do we need to support timezones in here?
    const renderCustomDates = useCallback(() => {
      const placeholderText = t('filters.select_date', 'Select Date');
      const format = getDateFormat(rangeDef);
      const startDate = workingDateRange.startDate
        ? dayjs(workingDateRange.startDate).format(format)
        : placeholderText;
      const endDate = workingDateRange.endDate
        ? dayjs(workingDateRange.endDate).format(format)
        : placeholderText;

      return `${startDate} - ${endDate}`;
    }, [rangeDef, t, workingDateRange]);

    const dateSelectOptions = useMemo(() => {
      return FilterOptions[rangeDef]
        .filter(({ key }) => !(noCustom && key === DateRange.CUSTOM))
        .map((filter) => ({
          value: filter.key,
          label: t(filter.a11yKey, filter.label),
        }));
    }, [noCustom, rangeDef, t]);

    // Set date range to last_7_days on store update
    useEffect(() => {
      if (dateRange.range === DateRange.LAST_7_DAYS && !defaultDateRange && !isPanelDateSelector) {
        const filterDateRange = getFilterDateRange({ range: dateRange.range });
        const updated = {
          range: dateRange.range,
          endDate: dayjs(filterDateRange.endDate.entityTimezone).toDate(),
          startDate: dayjs(filterDateRange.startDate.entityTimezone).toDate(),
        };
        setDates(updated);
        setWorkingDateRange(updated);
      }
    }, [dateRange.range, defaultDateRange, isPanelDateSelector]);

    useImperativeHandle(ref, () => ({
      applyDateRange: (updated) => {
        if (updated?.startDate && updated?.endDate) {
          const updatedDateRange = {
            endDate: dayjs(updated.endDate).toDate(),
            range: updated?.range ?? DateRange.CUSTOM,
            startDate: dayjs(updated.startDate).toDate(),
          };
          updateDateRange(updated);
          updatePanelDateRange(updated);
          setDates(updatedDateRange);
          setWorkingDateRange(updatedDateRange);
        }
      },
    }));

    return (
      <>
        <StyledSelect
          value={dateSelectOptions.find((option) => option.value === dates.range)}
          onChange={handleDateRange}
          options={dateSelectOptions}
          transparent
          minWidth={115}
          maxWidth={130}
        />
        {!(noPreview || dates.range === FilterDefs.CUSTOM) && (
          <CustomDateRange data-testid={AnalyzeLocators.ANALYZE_DATE_LABELS} clickable={false}>
            <span>{renderDates()}</span>
          </CustomDateRange>
        )}
        <DatePicker
          startDate={workingDateRange.startDate}
          endDate={workingDateRange.endDate}
          selectsRange
          showMonthYearPicker={rangeDef === RangeDefs.RANGE_BY_MONTH}
          showWeekNumbers={rangeDef !== RangeDefs.RANGE_BY_MONTH}
          calendarStartDay={1}
          ref={datePickerRef}
          customInput={
            dates.range === FilterDefs.CUSTOM ? (
              <CustomDateRange clickable>
                <span>{renderCustomDates()}</span>
                <div>
                  <CalendarIcon />
                </div>
              </CustomDateRange>
            ) : (
              <div />
            )
          }
          shouldCloseOnSelect={false}
          popperPlacement="bottom-start"
          onApplyClick={handleCustomDateRange}
          onCalendarClose={handleDatePickerClose}
          onChange={handleDatePickerChange}
        />
      </>
    );
  },
);

DashboardDateFilter.propTypes = {
  defaultDateRange: PropTypes.oneOf(Object.values(DateRange)),
  onChange: PropTypes.func,
  namespace: PropTypes.string,
  noCustom: PropTypes.bool,
  noPreview: PropTypes.bool,
  persist: PropTypes.bool,
  rangeDef: PropTypes.oneOf(Object.values(RangeDefs)),
  timezone: PropTypes.string,
  isDatePersist: PropTypes.bool,
  popsout: PropTypes.bool,
  externalDateRange: PropTypes.object,
  isPanelDateSelector: PropTypes.bool,
};

DashboardDateFilter.defaultProps = {
  onChange: () => {},
  namespace: 'analyze.dashboard',
  noCustom: false,
  noPreview: false,
  persist: true,
  rangeDef: RangeDefs.RANGE,
  timezone: 'UTC',
  isDatePersist: true,
  popsout: false,
  isPanelDateSelector: false,
};
