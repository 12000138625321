export const InspectionStage = {
  UPLOAD: 'upload',
  EXECUTION: 'execution',
  POSTPROCESS: 'post process',
  REPORT: 'report',
};

export const InspectionStages = {
  UPLOAD: 'upload',
  EXECUTION: 'execution',
  POST_PROCESS: 'post_process',
  REPORT_GENERATION: 'report_generation',
};

export const AuthDataPermission = {
  LIST: 'lists',
  POST_PROCESS: 'post-process',
  REPORT_GENERATION: 'generate-report',
};

export const PermissionTypes = {
  VIEW: 'view',
  DELETE: 'delete',
  EDIT: 'edit',
  ADMIN: 'admin',
};

export const StageStatus = {
  NA: 'n/a',
  READY: 'ready',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  FAILED: 'failed',
  FINISHED: 'finish',
  SUCCESS_SUBMITTING: 'Success submitting this job.',
};

export const PostProcessMediaMode = {
  VIDEOS: 'Videos',
  IMAGES: 'Images',
  DEFAULT: 'Default',
};

export const SourceVideoNamePlaceholder = {
  PINWHEEL: 'pinwheel_placeholder_video',
  BURST: 'burst_placeholder_video',
  INTERNAL: 'internal_placeholder_video',
  DRONE: 'drone_placeholder_video',
};

export const TurbineBladeLocations = {
  PRESSURE_SIDE: 'Pressure Side',
  SUCTION_SIDE: 'Suction Side',
  LEADING_EDGE: 'Leading Edge',
  TRAILING_EDGE: 'Trailing Edge',
};

export const PostProcessThumbnailImageSize = {
  TINY: 'tiny',
  REGULAR: 'regular',
};

export const Blades = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  ONE_UNKNOWN: -1,
  TWO_UNKNOWN: -2,
  THREE_UNKNOWN: -3,
};

export const InspectionModalities = {
  ALL: 'all',
  SABRE_IR: 'sabreIr',
  BURST: 'burstPhotography',
  PINWHEEL: 'pinwheel',
  INTERNAL: 'internal',
  DRONE: 'drone',
  INTERNAL_GE_BLADE_CRAWLER: 'internalGeBladeCrawler',
};

export const ModalitySeriesType = {
  CATEGORY: 'category',
  TIME_SERIES: 'timeSeries',
};

export const TableType = {
  id: 'default',
  type: 'table',
};

export const ParameterCategory = {
  PARAMETERS: 'Parameters',
  STATIC: 'Static',
  NOTES: 'Notes',
};

export const FileConstants = {
  COMMA: '_COMMA_',
};

export const DEFAULT_IMAGE_ENHANCEMENT_VALUES = {
  weight: 1,
  gamma: 1,
  brightness: 0,
  contrast: 1,
  invert: 0,
  grayscale: 0,
  blur: 13,
  enableImageEnhance: false,
  applyToAllImages: true,
};

export const UnknownTurbine = {
  UNKNOWN: 'UNKNOWN',
};
