import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { placements, Tooltip } from '@ge/components/tooltip';
import { AlertIcon } from '@ge/shared/components/alerts/alert-icon';
import { isInvalid } from '@ge/shared/util/alert-utils';
import { elevations } from '@ge/tokens/elevations';

const AlertIconButton = styled.button`
  padding: 0;
  display: flex;
`;

export const AlertIconTooltip = ({ alert, onClick, large, small }) => {
  const { t } = useTranslation(['alerts'], { useSuspense: false });

  return (
    <Tooltip
      title={t(`form.type.options.${alert.type}`, '')}
      placement={placements.BOTTOM_START}
      key={alert.id}
      zIndex={elevations.P25}
    >
      <AlertIconButton key={alert.id} onClick={(e) => onClick && onClick(e, alert)}>
        <AlertIcon
          type={alert.type}
          invalid={isInvalid(alert)}
          large={large}
          small={small}
          isGroup={alert?.isGroup}
        />
      </AlertIconButton>
    </Tooltip>
  );
};

AlertIconTooltip.propTypes = {
  alert: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  small: PropTypes.bool,
};
