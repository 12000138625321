import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Chart, ChartType, getBaseChartType, MirrorColumnChart } from '@ge/components/charts';
import { useKpiChartSelection } from '@ge/feat-analyze/hooks/use-kpi-chart';
import { EntityType, KpiCategoryDefs } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import {
  entityPropType,
  kpiChartApi,
  kpiChartOptionsFactory,
  KpiCategorySeriesType,
} from '../../../models';

const TOOLTIP_HEADER_PLACEHOLDER = 'KPI';
const INNER_KPI_CHART_HEIGHT_PERCENT = 0.68;

export const KpiChart = withTheme(
  ({
    categories,
    colors,
    data,
    entityType,
    isLoading,
    height,
    maxSelectedEntities,
    namespace,
    onSelected,
    onUpdated,
    parentEntity,
    selectable: _selectable,
    seriesType,
    theme,
    type,
    xAxisTitle,
    staticChartHeight,
    timeAggr,
    yAxisLabelFormatter,
    firstTimeRender,
    yAxisAllowDecimals,
    yAxislabelsYPosition,
    chartAddProps,
  }) => {
    // hooks
    const { ready, t } = useTranslation(namespace ? [namespace] : null);

    // if only 1 category, it is assumed to be positive
    // unless it is PRODUCTION_LOST_CONTRACT or PRODUCTION_LOST_LEARNED
    const category = categories[0];

    const negative = [
      KpiCategoryDefs.PRODUCTION_LOST_CONTRACT,
      KpiCategoryDefs.PRODUCTION_LOST_LEARNED,
    ].includes(category?.key);

    if (!ready) {
      return null;
    }

    const noDataLabel = t('kpi_chart.no_data_label', 'There is no data to display');
    const { series, ...options } = kpiChartOptionsFactory({
      categories,
      colors,
      data,
      entityType,
      getKpiCategory: (category) => t(`dynamic.kpi_chart.${category}`, TOOLTIP_HEADER_PLACEHOLDER),
      getUnits: (units) => (units && t(`dynamic.kpi_chart.${units}`, units)) || '',
      height,
      innerHeightPercent: INNER_KPI_CHART_HEIGHT_PERCENT,
      noDataLabel,
      parentEntity,
      seriesType,
      theme,
      type,
      timeAggr,
      chartAddProps,
    });

    // handlers
    const handleUpdated = (ref) => onUpdated(kpiChartApi({ ref, series, seriesType, type }));

    // just return one chart selection since it's the same entities in both charts (can revisit)
    // just return one chart selection since it's the same entities in both charts (can revisit)
    const handleMirrorSelected = ({ lower, upper }) => {
      const negativeSide = lower.map(({ negative }) => negative);
      // check if lower chart data is available if not, send the upper selection
      onSelected(negativeSide[0] ? lower : upper);
    };

    const handleSelected = ({ selection }) => onSelected(selection);

    const { onSelect } = useKpiChartSelection({
      selectable: _selectable && options?.selectable,
      seriesType,
      timeAggr,
      categories,
      onChange: handleSelected,
    });

    switch (type) {
      case ChartType.MIRROR_COLUMN: {
        const { height: _height, lowerChart, selectable, upperChart } = options;

        // remove entity data on first chart render
        // we use max entities to determine how many columns to show once we get the chart api
        if (seriesType === KpiCategorySeriesType.ENTITY) {
          lowerChart.series = lowerChart.series.map(({ data: _, ...seriesOptions }) => ({
            data: [],
            ...seriesOptions,
          }));
          upperChart.series = upperChart.series.map(({ data: _, ...seriesOptions }) => ({
            data: [],
            ...seriesOptions,
          }));
        }

        return (
          <MirrorColumnChart
            height={_height}
            lowerChart={lowerChart}
            maxSelect={maxSelectedEntities}
            onCreated={handleUpdated}
            onSelected={handleMirrorSelected}
            selectable={_selectable && selectable}
            upperChart={upperChart}
            isLoading={isLoading}
            xAxisVisible={true}
            xAxisTitle={xAxisTitle}
          />
        );
      }

      default: {
        const {
          height: _height,
          max,
          min,
          selectable,
          xAxisLabelFormatter,
          xAxisType,
          xAxisVisible,
          xAxisLabelVisible,
          xAxisTickLength,
          xAxisMaxPadding,
          yAxisTickInterval,
          ceiling,
          endOnTick,
        } = options;

        const stacked = type === ChartType.STACKED_COLUMN;
        const _type = getBaseChartType(type);
        let _series = series;

        // remove entity data on first chart render
        // we use max entities to determine how many columns to show once we get the chart api
        if (seriesType === KpiCategorySeriesType.ENTITY && firstTimeRender) {
          _series = series.map(({ data: _, ...seriesOptions }) => ({
            data: [],
            ...seriesOptions,
          }));
        }

        // TODO: Added custom color themes extensibility per chart
        // Custom Theme only for Actual vs. Expected Production chart
        const colors =
          type === ChartType.COLUMN &&
          categories.length === 2 &&
          categories.some(({ key }) => key === KpiCategoryDefs.PRODUCTION_ACTUAL) &&
          categories.some(({ key }) => key === KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT)
            ? [globalColors.magenta3, globalColors.mint2]
            : undefined;

        // Removing 'asset/site' x-axis title for series with dates
        if (seriesType === KpiCategorySeriesType.TIME_SERIES) {
          xAxisTitle = undefined;
        }

        return (
          <Chart
            height={staticChartHeight ?? _height}
            max={max}
            maxSelect={maxSelectedEntities}
            min={min}
            negative={negative}
            noDataLabel={noDataLabel}
            onCreated={handleUpdated}
            onSelect={onSelect}
            ref={handleUpdated}
            selectable={_selectable && selectable}
            series={_series}
            stacked={stacked}
            type={_type}
            xAxisLabelFormatter={xAxisLabelFormatter}
            xAxisScrollable={true}
            xAxisType={xAxisType}
            xAxisVisible={xAxisVisible}
            xAxisMaxPadding={xAxisMaxPadding}
            yAxisTickInterval={yAxisTickInterval}
            colors={colors}
            isLoading={isLoading}
            ceiling={ceiling}
            endOnTick={endOnTick}
            xAxisTitle={xAxisTitle}
            xAxisLabelVisible={xAxisLabelVisible}
            xAxisTickLength={xAxisTickLength}
            disableSideMargin
            yAxisLabelFormatter={yAxisLabelFormatter}
            yAxisAllowDecimals={yAxisAllowDecimals}
            yAxislabelsYPosition={yAxislabelsYPosition}
          />
        );
      }
    }
  },
);

KpiChart.displayName = 'KpiChart';

KpiChart.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ),
  colors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.instanceOf(Object),
  entityType: PropTypes.oneOf(Object.values(EntityType)).isRequired,
  maxSelectedEntities: PropTypes.number,
  namespace: PropTypes.string, // probably should make this an array
  onSelected: PropTypes.func,
  onUpdated: PropTypes.func,
  parentEntity: entityPropType,
  selectable: PropTypes.bool,
  seriesType: PropTypes.oneOf(Object.values(KpiCategorySeriesType)),
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  xAxisTitle: PropTypes.string,
  xAxisLabelVisible: PropTypes.bool,
  yAxisAllowDecimals: PropTypes.bool,
  yAxislabelsYPosition: PropTypes.number,
  yAxisLabelFormatter: PropTypes.func,
  firstTimeRender: PropTypes.bool,
  chartAddProps: PropTypes.Object,
};

KpiChart.defaultProps = {
  categories: [],
  colors: [],
  data: {},
  maxSelectedEntities: undefined,
  namespace: undefined,
  onSelected: () => {},
  onUpdated: () => {},
  parentEntity: undefined,
  selectable: true,
  // is this what we want to default to?
  seriesType: KpiCategorySeriesType.TIME_SERIES,
  isLoading: false,
  xAxisTitle: undefined,
  xAxisLabelVisible: undefined,
  yAxisAllowDecimals: undefined,
  yAxislabelsYPosition: undefined,
  yAxisLabelFormatter: undefined,
  firstTimeRender: true,
  chartAddProps: {},
};
