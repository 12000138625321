import { useMutation, useQueryClient } from 'react-query';

import { unlinkCases } from '@ge/feat-monitor/services';

import { CasesQueryKeys } from './cases/query-keys';
import { updateCasesCache } from './use-update-cases';

/**
 * To unlink a case from master.
 *
 * @param options options for react query useMutation
 */
export const useCaseUnlink = (options) => {
  const queryClient = useQueryClient();
  return useMutation(unlinkCases, {
    ...options,
    onSuccess: (data, ...rest) => {
      updateCasesCache(queryClient, data.cases);
      queryClient.invalidateQueries(CasesQueryKeys.detail(data.id));
      options?.onSuccess?.(data, ...rest);
    },
  });
};
