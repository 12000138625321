import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import styled, { css } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { ResolutionNotes } from '@ge/models/constants';
import { elevations } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';
import { StatusColor } from '@ge/tokens/colors/colors';

import { useResolutionNotes } from '../../../data-hooks';

/** This component is for single select. use resolution-notes-multiselect.jsx for multi selection in future*/
const PrimarySelectCss = css`
  color: ${(props) => props.theme.select.primaryTextColor};
  border: 1px solid ${(props) => props.theme.select.primaryBorder};
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  position: relative;
  border-radius: 3px;
  background: ${(props) => props.theme.select.primaryBackground};
`;

const SecondaryCss = css`
  font-size: 13px;
  color: ${(props) => props.theme.select.secondaryTextColor};
  border: 1px solid ${(props) => props.theme.select.secondaryBorder};
  border-radius: 3px;
  background: ${(props) => props.theme.select.secondaryBackground};
`;

const SmallCss = css`
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 20px;
`;

const MediumCss = css`
  box-sizing: border-box;
  min-height: 25px;
  border-radius: 4px;
`;

const TransparentCss = css`
  background: transparent;
`;

const StyledSelect = styled(Select)`
  .select__control {
    ${({ errors, id }) => (errors[id] ? `border-color: ${StatusColor.DANGER} !important` : '')};
    width: 300px;
    ${(props) => props.isDisabled && `opacity: 0.3`};
    ${(props) => (props.primary ? PrimarySelectCss : SecondaryCss)};
    ${(props) => (props.small ? SmallCss : MediumCss)};
    ${(props) => props.transparent && TransparentCss};
    .select__value-container {
      .select__single-value {
        height: 19px;
        line-height: 19px;
        color: inherit;
        font-family: 'Museo Sans';
        font-size: 13px;
        letter-spacing: 0;
        position: static;
        top: 0px;
        transform: none;
        max-width: calc(100% - 5px);
      }
      .select__multi-value {
        background: ${(props) => props.theme.input.background};
        .select__multi-value__label {
          color: inherit;
        }
        .select__multi-value__remove {
          &:hover {
            background: ${(props) => props.theme.input.background};
            color: inherit;
            cursor: pointer;
          }
        }
      }
      .select__placeholder {
        color: ${(props) => props.theme.select.optionColor};
      }
    }
    .select__indicators {
      padding-right: 6px;
      &:hover {
        background-color: transparent;
      }
      span {
        display: none;
      }
      .select__dropdown-indicator {
        svg {
          fill: ${(props) =>
            props.primary
              ? props.theme.select.chevronPrimaryColor
              : props.theme.select.chevronColor};
        }
        padding: 0px;
      }
    }
    .select__clear-indicator {
      display: none;
    }
  }
  .select__menu {
    margin: 0;
    font-family: 'Museo Sans';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 0;
    background: ${(props) => props.theme.select.optionBackground};
    z-index: ${elevations.P100};
    .select__menu-list {
      .select__option {
        padding: 7px 12px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.select.optionColor};
        &.select__option--is-focused {
          background-color: ${(props) => props.theme.select.hoverColor};
        }
        &.select__option--is-selected {
          font-weight: 500;
        }
        background-color: ${(props) => props.theme.select.optionBackground};
        &:hover {
          cursor: default;
          color: ${(props) => props.theme.select.optionColor};
          width: 100%;
          background: ${(props) => props.theme.select.hoverColor};
        }
      }
    }
  }

  &.invalid {
    border: ${(props) => props.theme.select.errorBorder};
    border-radius: 4px;
  }
`;
const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
`;
const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-bottom: 6px;
  &:hover {
    fill: ${globalColors.red1};
  }
`;
const Value = styled.div`
  padding: 3px;
  margin: 4px;
  user-select: none;
  background-color: rgb(28, 37, 45);
`;

const Text = styled.input`
  height: 22px;
  padding-right: 25px;
  margin-right: 5px;
  width: calc(100% - 31px);
  color: ${(props) => props.theme.filterMenu.textColor};
  font-size: 12px;
  line-height: 15px;
  border: 1px solid ${(props) => props.theme.filterMenu.inputBorderColor};
  border-radius: 2px;
  background-color: ${(props) => props.theme.filterMenu.inputBackgroundColor};
  width: 200px;
`;
const StyledLabel = styled.label`
  color: ${(props) => props.theme.select.labelTextColor};
  font-size: 11px;
  font-family: 'Museo Sans';
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 5px;
`;
const OtherControls = styled.span`
  .space {
    margin-right: 5px;
  }
`;
export const ResolutionNotesSelect = ({ metadata, caseId, task }) => {
  const { control, setValue, errors } = useFormContext();
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const { data: resolutionNotesOptions } = useResolutionNotes(caseId);
  metadata.required = metadata.completeRequired;

  const [isShowTextField, setIsShowTextField] = useState(false);
  const [selection, setSelection] = useState({});
  const [otherNotes, setOtherNotes] = useState();
  const [shouldValidate, setShouldValidate] = useState(false);
  //build select dropdown options
  const resolutionSelectOptions = useMemo(
    () => resolutionNotesOptions?.rootCauses.map((val) => ({ value: val, label: val })) ?? [],
    [resolutionNotesOptions],
  );

  //set default values
  let taskResolutionNotes = useMemo(() => {
    if (task?.resolutionNotes && resolutionSelectOptions.length > 0) {
      let notes = [];
      if (
        task?.resolutionNotes === ResolutionNotes.OTHER ||
        !resolutionSelectOptions?.find((val) => val.value === task?.resolutionNotes)
      ) {
        notes.push({ value: ResolutionNotes.OTHER, label: ResolutionNotes.OTHER });
        setOtherNotes(task?.resolutionNotes);
      } else notes.push({ value: task?.resolutionNotes, label: task?.resolutionNotes });
      return notes;
    }
  }, [task, resolutionSelectOptions]);

  useEffect(() => {
    if (taskResolutionNotes && taskResolutionNotes?.length > 0)
      setSelection(taskResolutionNotes[0]);
  }, [taskResolutionNotes]);

  useEffect(() => {
    setTimeout(() => {
      let value = '';
      if (selection.label === ResolutionNotes.OTHER) value = otherNotes;
      else value = selection.value;
      if (value) setShouldValidate(true);
      setValue('resolutionNotes', value, {
        shouldDirty: true,
        shouldValidate: shouldValidate,
      });
    }, 0);
  }, [selection, setValue, shouldValidate, otherNotes]);

  //handle selection; if Other show input
  const handleNoteSelect = useCallback((option) => {
    setSelection(option);
    if (option.value === ResolutionNotes.OTHER) setIsShowTextField(true);
    else {
      setIsShowTextField(false);
      setOtherNotes('');
    }
  }, []);

  const handleNoteRemove = useCallback(() => {
    setOtherNotes('');
    setSelection({});
  }, []);

  // Add other note from additional input field in selected options
  const handleNoteAdd = useCallback(() => {
    if (!otherNotes || !otherNotes.trim()) return;
    setIsShowTextField(false);
  }, [otherNotes]);

  const handleChange = useCallback((note) => {
    setOtherNotes(note);
  }, []);

  const handleNoteCancel = useCallback(() => {
    setIsShowTextField(false);
  }, []);
  // Display otherNote
  const container = () => {
    return (
      <ValuesContainer>
        {otherNotes && !isShowTextField && (
          <Value key={otherNotes}>
            {otherNotes}
            <button name={otherNotes} onClick={handleNoteRemove}>
              <StyledIcon icon={Icons.CLOSE} size={10} />
            </button>
          </Value>
        )}
      </ValuesContainer>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name="resolutionNotes"
          rules={{ required: metadata.required }}
          render={() => (
            <>
              <StyledLabel htmlFor="resolutionNotes">
                {t('form.note', 'Note')}
                {metadata.required && <>*</>}
              </StyledLabel>
              <StyledSelect
                name="resolutionNotes"
                id="resolutionNotes"
                label={t('section_header.resolution_note', 'Resolution Note')}
                metadata={metadata}
                options={resolutionSelectOptions}
                minWidth={250}
                onChange={handleNoteSelect}
                value={selection}
                classNamePrefix={'select'}
                errors={errors}
              />
            </>
          )}
        />
        {container()}
      </div>
      {isShowTextField && (
        <div style={{ display: 'flex' }}>
          <Text
            name="otherNotes"
            onChange={({ target }) => handleChange(target.value)}
            required={isShowTextField}
          />
          <OtherControls>
            <Button type="button" onClick={() => handleNoteAdd(otherNotes)} className="space">
              {t('form.add', 'Add')}
            </Button>
            <Button type="button" onClick={() => handleNoteCancel()}>
              {t('form.cancel', 'Cancel')}
            </Button>
          </OtherControls>
        </div>
      )}
    </div>
  );
};

ResolutionNotesSelect.propTypes = {
  defaultValue: PropTypes.string,
  metadata: PropTypes.object.isRequired,
  caseId: PropTypes.string,
  task: PropTypes.instanceOf(Object),
};

ResolutionNotesSelect.defaultProps = {
  defaultValue: '',
  caseId: '',
  task: {},
};
