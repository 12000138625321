import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey, NotesCategory } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import {
  addEventNote,
  escalateMonitorCase,
  getEventNotes,
  escalateBulkCases,
  editEventNote,
  deleteEventNote,
  addBulkEventNotes,
} from '@ge/shared/services/notes';

export const useCreateEventNote = () => {
  const queryClient = useQueryClient();
  const { mutate: create, data, isLoading, isError, error } = useMutation(addEventNote, {
    enabled: true,
    ...Config.EXECUTE_ONCE,
    onSuccess: (data) => {
      const queryKey = [QueryKey.EVENT_NOTES, parseInt(data.domainId)];
      // Using removeQueries as invalidateQueries not working
      queryClient.removeQueries(queryKey);
    },
  });

  return { create, data, isLoading, isError, error };
};

export const useBulkEventNotes = (setSelectedAssets, setEventsChecked) => {
  const queryClient = useQueryClient();
  const { mutate: create, data, isLoading, isError, error } = useMutation(addBulkEventNotes, {
    enabled: true,
    ...Config.EXECUTE_ONCE,
    onSuccess: (data) => {
      // Using removeQueries as invalidateQueries not working
      data.domainIds.forEach((domainId) => {
        queryClient.removeQueries([QueryKey.EVENT_NOTES, parseInt(domainId)]);
      });

      if (!data?.errors) {
        setEventsChecked([]);
        setSelectedAssets([]);
      }
    },
  });

  return { create, data, isLoading, isError, error };
};

const checkForQueryKey = (queryClient, key) => {
  return queryClient.getQueryData(key) !== undefined;
};

export const useEditEventNote = () => {
  const queryClient = useQueryClient();
  const { mutate: edit, data, isLoading, isError, error } = useMutation(
    ({ inputData, originalData }) => editEventNote({ inputData, originalData }),
    {
      onSettled: (respData, error) => {
        let pages;
        if (!error) {
          if (respData?.original?.category === NotesCategory.NOTE) {
            const queryKey = [QueryKey.EVENT_NOTES, parseInt(respData?.original?.domainId)];
            if (checkForQueryKey(queryClient, queryKey))
              queryClient.setQueryData(queryKey, (_data) => {
                pages = _data.pages?.map((page) => {
                  return {
                    ...page,
                    notes: page.notes.map((note) =>
                      note.id === respData.original.id
                        ? { ...respData.original, ...respData.updated }
                        : note,
                    ),
                  };
                });
                return { pages, pageParams: _data.pageParams };
              });
            queryClient.removeQueries(queryKey);
          }
        }
      },
    },
  );

  return useMemo(() => ({ edit, data, isLoading, isError, error }), [
    edit,
    data,
    isLoading,
    isError,
    error,
  ]);
};

export const useDeleteEventNote = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteNote, isLoading, isError, error } = useMutation(
    (input_data) => {
      return deleteEventNote(input_data);
    },
    {
      onSettled: (respData, error, variables) => {
        if (!error && respData === variables?.id) {
          const queryKey = [QueryKey.EVENT_NOTES, parseInt(variables?.domainId)];
          // Invalidating the query whenever any record gets deleted
          queryClient.invalidateQueries(queryKey);
        }
      },
    },
  );
  return useMemo(() => ({ deleteNote, isLoading, isError, error }), [
    deleteNote,
    error,
    isError,
    isLoading,
  ]);
};

export const useGetEventNotes = ({ id }) => {
  const { data, error, isLoading } = useQuery([QueryKey.EVENT_NOTES, id], () => getEventNotes(id), {
    ...Config.REFRESH,
    enabled: Boolean(id),
  });

  return { data, error, isLoading };
};

export const useEscalateCase = () => {
  const queryClient = useQueryClient();
  let id;
  const { mutate: escalateCase, data, isLoading, isError, error } = useMutation(
    (input_data) => {
      id = input_data?.entity?.id;
      return escalateMonitorCase(input_data);
    },
    {
      onSuccess: () => {
        const queryKey = [QueryKey.EVENT_NOTES, parseInt(id)];
        // Using removeQueries as invalidateQueries not working
        queryClient.removeQueries(queryKey);
        queryClient.invalidateQueries(QueryKey.MONITOR_CASES);
      },
    },
  );

  return { escalateCase, data, isLoading, isError, error };
};

export const useBulkEscalateCases = () => {
  const queryClient = useQueryClient();

  const { mutate: bulkEscalateCases, data, isLoading, isError, error } = useMutation(
    (input_data) => escalateBulkCases(input_data),
    {
      onSuccess: () => {
        const queryKey = [QueryKey.EVENT_NOTES];
        queryClient.removeQueries(queryKey);
        queryClient.removeQueries(QueryKey.MONITOR_CASES);
      },
    },
  );

  return { bulkEscalateCases, data, isLoading, isError, error };
};
