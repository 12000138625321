import { useMemo } from 'react';

import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { useErpRequestStatus } from '@ge/feat-manage/data-hooks/use-erp-request-status';
import { getUpdatedStackTasks } from '@ge/feat-manage/util/bundle-util';
import { Capability, PermissionScope } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

export const useStackMenu = ({ open, tasks, date }) => {
  const { isAuthorized } = useAuth();

  const {
    planningState: { groupFilter, range },
  } = usePlanningContext();

  const updatedTasks = getUpdatedStackTasks(tasks, date, groupFilter?.L1?.value, range?.type);

  const { taskIds = [], siteId } = useMemo(() => {
    if (!open || !tasks?.length) return {};

    const firstTask = tasks[0] ?? {};
    const siteId = firstTask.asset?.site?.id ?? firstTask.site?.id;
    const taskIds = updatedTasks.reduce(
      // eslint-disable-next-line no-unsafe-optional-chaining
      (acc, { allTasks }) => [...acc, ...allTasks?.map((v) => v.id)],
      [],
    );

    return { siteId, updatedTasks, taskIds };
  }, [open, tasks, updatedTasks]);

  const erpStatusAccess = isAuthorized({
    capabilities: [{ capability: Capability.WORK_ORDERS, scopes: [PermissionScope.EDIT] }],
    siteIds: [siteId],
  });

  const { data: erpStatusData, isLoading: erpStatusLoading } = useErpRequestStatus({
    isActive: erpStatusAccess && taskIds.length > 0,
    taskIds,
  });

  const erpStatusByTaskId = useMemo(() => {
    if (erpStatusLoading || !erpStatusData) return {};
    return erpStatusData.reduce((acc, v) => ({ ...acc, [v.taskId]: v }), {});
  }, [erpStatusLoading, erpStatusData]);

  return { siteId, updatedTasks, erpStatusByTaskId, erpStatusLoading };
};
