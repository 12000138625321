import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileThumbnail } from '@ge/components/file-thumbnail';
import { Icon, Icons } from '@ge/components/icon';

const PreviewsContainer = styled.div`
  margin-top: 16px;
`;

const ThumbnailList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`;

const FileUploadLabel = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.fileUpload.filenameTextColor};
`;

const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  width: 100%;
  > * {
    flex: 1 0;
  }
`;

const ThumbnailIcon = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  img {
    width: 100%;
    height: 100px;
  }
  svg {
    color: ${({ theme }) => theme.fileUpload.uploadIconColor};
    display: block;
    margin: auto;
  }
`;

const ThumbnailName = styled.div`
  color: ${({ theme }) => theme.fileUpload.filenameTextColor};
  flex-basis: 14px;
  font-size: 11px;
  margin-top: 2px;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TrashButton = styled.button`
  width: 24px;
  margin: 0 auto;
  padding: 5px;
`;

const TrashIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.fileUpload.trashIconColor,
}))``;

export const FilePreview = ({ files, handleDelete }) => {
  const { ready, t } = useTranslation(['general'], { useSuspense: false });

  if (!ready) {
    return null;
  }

  return files?.length > 0 ? (
    <PreviewsContainer>
      <FileUploadLabel>{t('file_upload.attached_label', 'Attached files')}</FileUploadLabel>
      <ThumbnailList>
        {files.map(({ id, ext, path, preview }) => (
          <Thumbnail key={id}>
            <ThumbnailIcon>
              <FileThumbnail src={preview} ext={ext} fileName={path} />
            </ThumbnailIcon>
            <ThumbnailName>{path}</ThumbnailName>
            <TrashButton onClick={() => handleDelete(id)} type="button">
              <TrashIcon icon={Icons.TRASH} size={12} color="transparent" />
            </TrashButton>
          </Thumbnail>
        ))}
      </ThumbnailList>
    </PreviewsContainer>
  ) : null;
};

FilePreview.propTypes = {
  files: PropTypes.array,
  handleDelete: PropTypes.func,
};

FilePreview.defaultProps = {
  files: null,
  handleDelete: () => {},
};
