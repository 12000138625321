import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import useDismissListeners from '@ge/hooks/use-dismiss-listeners';
import { elevations } from '@ge/tokens';

import { SidePanelNav } from './side-panel-nav';

const StyledSidePanel = styled.aside`
  border-left: solid 1px ${(props) => props.theme.entityDetails.borderColor};
  background: ${(props) => props.theme.entityDetails.backgroundColor};
  bottom: 0;
  box-shadow: ${(props) => props.theme.layout.sidebarBoxShadow};
  color: ${(props) => props.theme.layout.textColor};
  overflow: hidden;
  padding: 30px 0 0 4px;
  position: fixed;
  right: 0;
  top: 0;
  width: 780px;
  z-index: ${elevations.P20};

  &:after {
    background-color: ${(props) => props.theme.entityDetails.detailsPanelAfter};
    content: ' ';
    height: 100%;
    width: 4px;
    position: absolute;
    left: 0;
    top: 0;
  }

  table {
    background: ${(props) => props.theme.entityDetails.overviewBlockBackground};
    border-top: solid 1px ${(props) => props.theme.entityDetails.overviewBorderColor};
  }
`;

const SidePanelComponent = ({
  children,
  className,
  close,
  navBack,
  spawnWindow,
  entityType,
  entityTab,
}) => {
  const containerRef = useRef(null);

  // Apply the close function provided by the parent.
  useDismissListeners(containerRef, close, entityType, entityTab);

  // Don't render if there is no content (children) to render.
  if (!children) {
    return (
      <StyledSidePanel>
        <Loader />
      </StyledSidePanel>
    );
  }

  return createPortal(
    <StyledSidePanel ref={containerRef} className={className}>
      <SidePanelNav closePanel={close} navBack={navBack} spawnWindow={spawnWindow} />
      {children}
    </StyledSidePanel>,
    document.querySelector('#portal-root'),
  );
};

export const SidePanel = withRouter(SidePanelComponent);
