import { getLoadable } from '@ge/shared/util';

// Define the available pages for lazy loading.
export const loadables = {
  configure: getLoadable(() => import('./configure'), 'configure'),
  contracts: getLoadable(() => import('./contracts')),
  fleetOverview: getLoadable(() => import('./fleet-overview')),
  financial: getLoadable(() => import('./financial')),
  manualAdjustment: getLoadable(() => import('./manual-adjustment')),
  reports: getLoadable(() => import('./reports')),
  region: getLoadable(() => import('./region'), 'AnalyzeRegion'),
  sites: getLoadable(() => import('./sites'), 'AnalyzeSites'),
  underConstruction: getLoadable(() => import('./under-construction'), 'UnderConstruction'),
  site: getLoadable(() => import('./site'), 'AnalyzeSite'),
  dataDownload: getLoadable(() => import('./data-download'), 'AnalyzeSite'),
  assets: getLoadable(() => import('./assets'), 'AnalyzeAssets'),
  troubleshooting: getLoadable(() => import('./troubleshooting')),
  anomalyCases: getLoadable(() => import('./anomaly-cases'), 'AnalyzeAnomalyCases'),
  eventCases: getLoadable(() => import('./event-cases'), 'AnalyzeEventCases'),
  tasks: getLoadable(() => import('./tasks'), 'AnalyzeTasks'),
  assetOverview: getLoadable(() => import('./asset-overview'), 'AnalyzeAssetOverview'),
  assetOverviewWind: getLoadable(() => import('./asset-ov'), 'AnalyzeAssetOv'), // should be removed once overview page is finalized
  sitesAll: getLoadable(() => import('./sites-all'), 'AnalyzeSitesAll'), // should be removed once sites page is finalized
};

// @index('./**/*.jsx', f => `export * from '${f.path}';`)
export * from './anomaly-cases';
export * from './assets';
export * from './configure';
export * from './contracts';
export * from './manual-adjustment';
export * from './asset-overview';
export * from './event-cases';
export * from './filters';
export * from './financial';
export * from './fleet-overview';
export * from './region';
export * from './reports';
export * from './site';
export * from './sites';
export * from './mock-sites';
export * from './tasks';
export * from './troubleshooting';
export * from './asset-ov';
// @endindex
