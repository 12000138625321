const { KpiCategoryDefs } = require('@ge/models/constants');

export const iecCategorySelect = [
  {
    grouping: 'performance',
    categories: [
      KpiCategoryDefs.PRODUCTION_ACTUAL,
      KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT,
      // unproduced energy system isn't included in this api doc:
      // https://devcloud.swcoe.ge.com/devspace/pages/viewpage.action?pageId=1742705026
      // KpiCategoryDefs.UNPRODUCED_ENERGY_SYSTEM_CONTRACT,
      // #327 - removing for MVP0
      // KpiCategoryDefs.UNPRODUCED_ENERGY_IEC_LEARNED,
      // KpiCategoryDefs.UNPRODUCED_ENERGY_SYSTEM_LEARNED,
    ],
  },
  {
    grouping: 'reliability',
    categories: [
      KpiCategoryDefs.EVENT_DURATION,
      KpiCategoryDefs.EVENT_OCCURRENCE,
      KpiCategoryDefs.EVENT_RATES,
      KpiCategoryDefs.EVENT_UNAVAILABLE_TIME,
    ],
  },
];
