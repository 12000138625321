import { useMemo } from 'react';

import { Capability, PermissionScope } from '@ge/models/constants';

import { useFeatureSites } from './use-feature-sites';

// defining statically to avoid re-renders
const CAPABILITIES = [
  { capability: Capability.CASES_RECOMMENDATIONS, scopes: [PermissionScope.CREATE] },
];

export const useCaseEntityFilter = (filterText) => {
  const { isLoading, sites } = useFeatureSites({
    capabilities: CAPABILITIES,
  });

  const sitesFiltered = useMemo(() => {
    if (filterText.length < 2) {
      return sites;
    }

    const sitesFiltered = sites.filter((site) => site.name?.toLowerCase().includes(filterText));

    if (sitesFiltered.length > 0) {
      return sitesFiltered;
    }

    return sites
      .map((site) => {
        return {
          ...site,
          assets: site.assets.filter((asset) => asset.name?.toLowerCase().includes(filterText)),
        };
      })
      .filter((site) => site.assets.length !== 0);
  }, [filterText, sites]);

  return { isLoading, sitesFiltered };
};
