export const generateMetadata = (isSabreInspection = false, videos, images, sn) => {
  if (isSabreInspection) return [];

  // consider situations such as fragment images aor non blade images
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { blade_index } = images?.[0].attributes;
  if (blade_index >= 0) {
    // blade_index === 0 Is there any case like this?
    const video0 = videos.find((vdo) =>
      images?.[0].attributes.imgname.includes(vdo.attributes.videoname),
    );
    return videos
      .filter((video) => video.attributes.blade_serial_no === video0?.attributes?.blade_serial_no)
      .map((v) => ({
        videoid: v.id,
        blade_serial_no: sn,
      }));
  } else if (blade_index < 0) {
    return videos
      .filter((video) => video.attributes[`blade${Math.abs(blade_index)}_axis`] === blade_index)
      .map((v) => ({
        videoid: v.id,
        blade_serial_no: sn,
      }));
  }
};
