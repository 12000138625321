import styled from 'styled-components';

import { typography } from '@ge/tokens/typography';

export const SignalButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  line-height: 1.5;
  align-items: center;
  width: 100%;
  color: inherit;
  padding: 6px 20px;
  border-bottom: solid 1px ${(props) => props.theme.dataExplorer.dialog.listBorderColor};
  text-align: left;
  display: flex;
  justify-content: space-between;
  &:focus {
    outline: none;
  }
  &.active {
    background: ${(props) => props.theme.dataExplorer.dialog.listActiveColor};
    font-weight: ${typography.weight.bold};
    svg {
      fill: ${(props) => props.theme.dataExplorer.dialog.activeArrowColor};
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export default SignalButton;
