import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Menu, MenuTitle } from '@ge/components/menu';
import { Severity } from '@ge/components/severity';
import { globalColors } from '@ge/tokens/colors/colors';

const StyledTasksMenu = styled.div`
  font-size: 11px;
  text-align: left;
  position: relative;
  flex: 1;
  height: 18px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  width: 285px;
  color: ${(props) => props.theme.menu.tableBodyTextColor};
  border-bottom: solid 1px ${(props) => props.theme.menu.borderColor};
`;

const TaskIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.TASK,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin: 0 10px;
`;

const DetailsContainer = styled.div`
  margin: 7px 8px 7px 30px;
  color: ${globalColors.grey7};
`;

const DetailsRow = styled.div`
  display: flex;
  padding: 1px 0;
  font-size: 10px;
  text-transform: capitalize;
`;

const AlignRight = styled.div`
  margin-left: auto;
  font-size: ${(props) => (props.emphasize ? '11px' : 'inherit')};
  text-transform: ${(props) => (props.emphasize ? 'uppercase' : 'inherit')};
`;

const TaskMenu = ({ task, containerRef, children }) => {
  const { t } = useTranslation(['general']);
  const [anchorEl, setAnchorEl] = useState(null);

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTasksMenu onMouseEnter={(e) => showMenu(e)} onMouseLeave={() => handleMenuClose()}>
      {children}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        offset={10}
      >
        <MenuTitle title={`${task.source} ${t('task', 'task')}`} onDetailClick={() => null} />
        <HeadingContainer>
          <Severity level={task.priority} />
          <TaskIcon />
          <div>{task.title}</div>
        </HeadingContainer>
        <DetailsContainer>
          <DetailsRow>
            <div>
              <span>Source:&nbsp;</span>
              {task.source}
            </div>
            <AlignRight>{dayjs(task.createdDate).format('D MMM YYYY')}</AlignRight>
          </DetailsRow>
          <DetailsRow>
            <div>
              <span>Due:&nbsp;</span>
              {task.due}
            </div>
            <AlignRight emphasize>{task.status}</AlignRight>
          </DetailsRow>
        </DetailsContainer>
      </Menu>
    </StyledTasksMenu>
  );
};

TaskMenu.propTypes = {
  task: PropTypes.instanceOf(Object),
  containerRef: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Object),
};

TaskMenu.defaultProps = {
  task: null,
  containerRef: null,
  children: {},
};

export default TaskMenu;
