// MVP0 - temporarily converting event field to text without data source
// import { AlertDataSource, AlertType } from '@ge/models/constants';
import { AlertType } from '@ge/models/constants';

export const TemporaryConfigChangeTemplate = {
  _id: 'cc6a7101-3093-4a4c-9dcf-207e45f45dd2',
  alertType: AlertType.TEMPORARY_CONFIG_CHANGE,
  entrypoint: 'string',
  sections: [
    {
      collapsed: false,
      hidden: false,
      metadata: {
        event: {
          create: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          // data: AlertDataSource.EVENTS,
          edit: {
            hidden: false,
            readOnly: false,
            required: false,
          },
          // emphasis: 1,
          position: {
            col: 1,
            row: 1,
            span: 12,
          },
          // type: 'select',
          type: 'text',
        },
        newValue: {
          create: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          position: {
            col: 2,
            row: 3,
            span: 2,
          },
          type: 'text',
        },
        oldValue: {
          create: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          position: {
            col: 1,
            row: 3,
            span: 2,
          },
          type: 'text',
        },
        parameterName: {
          create: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: true,
          },
          position: {
            col: 1,
            row: 2,
            span: 12,
          },
          type: 'text',
        },
        validTo: {
          create: {
            hidden: false,
            readOnly: false,
            required: true,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          edit: {
            hidden: false,
            readOnly: false,
            required: true,
            validators: [
              {
                earliestDate: 'currentDate',
              },
            ],
          },
          position: {
            col: 3,
            row: 3,
            span: 8,
          },
          type: 'dateTime',
        },
      },
      type: 'body',
    },
    {
      collapsed: true,
      hidden: false,
      metadata: {
        description: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'description',
    },
    {
      collapsed: true,
      showActionableInformational: true,
      metadata: {
        sendActionableInformational: {
          create: {
            readOnly: false,
            required: false,
          },
          edit: {
            readOnly: false,
            required: false,
          },
        },
      },
      type: 'send_actionable_informational',
    },
  ],
  operations: ['create', 'edit'],
  tenantId: 'dfsa',
};

export const TemporaryConfigChangeMailTemplate = {
  bcc: '',
  cc: '',
  body: '',
  header: 'The configuration of the asset has been changed temporarily to allow it to operate.',
  maxAttachments: 5,
  maxAttachmentSizeMb: 10,
  subject: 'Temporary Configuration Change for {{siteName}}',
  to: 'TemporaryConfigChange@mock.com',
};
