import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const Header = styled.div`
  align-items: center;
  background: ${(props) => props.theme.admin.panel.header.backgroundColor};
  border-radius: 6px;
  display: flex;
  margin: 0 10px 10px 10px;
  padding: 21px 12px 17px 12px;
  position: relative;
  &:before {
    background: ${(props) => props.theme.admin.panel.header.gradient};
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 0 0 #38434e;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  h2 {
    margin-left: 10px;
    padding-right: 20px;
  }
  h3 {
    font-size: 16px;
    padding-left: 20px;
    position: relative;
    &:before {
      background: ${(props) => props.theme.admin.panel.header.borderColor};
      content: '';
      height: 46px;
      left: 0;
      position: absolute;
      top: -12px;
      width: 1px;
    }
  }
`;

const HeaderIcon = styled(Icon).attrs((props) => ({
  size: 30,
  color: props.theme.admin.panel.header.iconColor,
}))``;

const EntityCreateHeader = ({ header, entityType, name }) => {
  return (
    <Header>
      <HeaderIcon icon={Icons[entityType.toUpperCase()]} />
      <h2>{header}</h2>
      {name && <h3>{name}</h3>}
    </Header>
  );
};

EntityCreateHeader.propTypes = {
  header: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  name: PropTypes.string,
};

EntityCreateHeader.defaultProps = {
  name: undefined,
};

export default EntityCreateHeader;
