import React from 'react';

import { ManualAdjustmentProvider } from '@ge/feat-analyze/context/manual-adjustment-provider';

import { Sites } from '../components/manual-adjustment/sites';

const ManualAdjustment = () => {
  return (
    <ManualAdjustmentProvider>
      <Sites />
    </ManualAdjustmentProvider>
  );
};

export default ManualAdjustment;
