import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ScrollingContainer } from '@ge/components/scrolling-container';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';

const StyledNotesContainer = styled.div`
  ${({ wide, isInteractive }) => (!wide || isInteractive) && `max-height: 200px;`};
`;

const StyledNotes = styled.div`
  ${({ wide }) => wide && `padding-right: 20px;`};
  padding-top: 11px;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.createReport.widget.headerBorderColor};
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.createReport.widget.headerTextColor};
`;

const StyledTitle = styled.h3`
  color: ${({ theme }) => theme.createReport.widget.notes.titleTextColor};
  font-weight: bold;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  white-space: break-spaces;
`;

const StyledBody = styled.p`
  color: ${({ theme }) => theme.createReport.widget.notes.bodyTextColor};
  margin: 0;
  overflow-wrap: break-word;
  white-space: break-spaces;
`;

export const NotesWidgetStateKeys = {
  VALUES: 'values',
  OVERFLOW: 'overflow',
};

export const NotesWidget = ({ id, name, isPlaceholder, isInteractive }) => {
  const notesRef = useRef();

  const {
    getWidgetState,
    getInitialWidgetState,
    getWidgetLocalState,
    setWidgetState,
    setWidgetLocalState,
  } = useContext(ReportsContext);

  const { title, body } = getWidgetState(id, NotesWidgetStateKeys.VALUES) ?? {};
  const isOverflowed = getWidgetLocalState(id, NotesWidgetStateKeys.OVERFLOW);

  // Reset Notes on mode change/cancel
  useEffect(() => {
    if (isPlaceholder) {
      const initialState = getInitialWidgetState(id, NotesWidgetStateKeys.VALUES);
      if (initialState && !equals(initialState, { title, body })) {
        setWidgetState({
          widgetId: id,
          key: NotesWidgetStateKeys.VALUES,
          value: { ...initialState },
        });
        if (isOverflowed) {
          setWidgetLocalState({
            widgetId: id,
            key: NotesWidgetStateKeys.OVERFLOW,
            value: false,
          });
        }
      }
    }
  }, [
    id,
    title,
    body,
    isOverflowed,
    isPlaceholder,
    getInitialWidgetState,
    setWidgetState,
    setWidgetLocalState,
  ]);

  // Force a re-render to widen this widget if its contents are overflowed.
  // This effect runs on every render, but is skipped if the widget is already widened.
  // Shrinking the widened widget is handled in a separate effect.
  useEffect(() => {
    if (isOverflowed || !notesRef.current) {
      return;
    }

    if (notesRef.current.clientHeight > notesRef.current.parentElement.clientHeight) {
      setWidgetLocalState({
        widgetId: id,
        key: NotesWidgetStateKeys.OVERFLOW,
        value: true,
      });
    }
  });

  const notesContents = (
    <StyledNotesContainer wide={isOverflowed} isInteractive={isInteractive}>
      <StyledNotes ref={notesRef} wide={isOverflowed}>
        {title && <StyledTitle>{title}</StyledTitle>}
        {body && <StyledBody>{body}</StyledBody>}
      </StyledNotes>
    </StyledNotesContainer>
  );

  return (
    <>
      <StyledHeader>
        <h4>{name}</h4>
      </StyledHeader>
      {!isPlaceholder && isInteractive && <ScrollingContainer>{notesContents}</ScrollingContainer>}
      {!isPlaceholder && !isInteractive && <>{notesContents}</>}
    </>
  );
};

NotesWidget.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isPlaceholder: PropTypes.bool,
  isInteractive: PropTypes.bool,
};

NotesWidget.defaultProps = {
  isPlaceholder: false,
  isInteractive: false,
};
