import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnTitle,
  ColumnDetail,
  DisplayField,
  DisplayLabel,
  MultiRow,
} from '../../../task-template-shared';

export const ViewExpectedParts = ({ task }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  return (
    <div className="task-panel-row">
      <ColumnTitle>
        <h4>{t('section_header.expected_parts', 'Expected Parts')}</h4>
      </ColumnTitle>
      <ColumnDetail className="column-detail expected-parts">
        <MultiRow>
          <DisplayLabel>{t('form.part_number', 'Part #')}</DisplayLabel>
          <DisplayLabel>{t('form.part_name', 'Part Name')}</DisplayLabel>
          <DisplayLabel>{t('form.quantity', 'Qty')}</DisplayLabel>
          <DisplayLabel>{t('form.probability', 'Probability')}</DisplayLabel>
        </MultiRow>
        {task.expectedParts?.map((part, idx) => (
          <MultiRow key={idx}>
            <DisplayField className="wordbreak">{part.number}</DisplayField>
            <DisplayField className="wordbreak">{part.name}</DisplayField>
            <DisplayField>{part.quantity}</DisplayField>
            <DisplayField></DisplayField>
          </MultiRow>
        ))}
      </ColumnDetail>
    </div>
  );
};

ViewExpectedParts.propTypes = {
  task: PropTypes.object.isRequired,
};
