import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { PropTypes } from 'prop-types';
import React, { createContext, useContext, memo } from 'react';
import styled from 'styled-components';

export const SelectedTool = createContext();

const ButtonContainer = styled.div`
  margin: 1px;
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) =>
    props.selected
      ? props.theme.postProcess.selectedIconButton
      : props.theme.postProcess.iconButton};
  height: 41px;
  margin: 1px;
  width: 41px;
`;

export const SmallToolButton = ({ id, name, icon, selected, togglable, alwaysShowing = false }) => {
  const { updateSelectedAnnotationId, enabledTools, selectedTool, onClickTool } = useContext(
    SelectedTool,
  );
  if (!enabledTools.includes(id) && !alwaysShowing) return null;
  selected = selected || (selectedTool === id && selectedTool !== 'image_enhancer');
  return (
    <Tooltip placement="right" title={name}>
      <ButtonContainer>
        <StyledIconButton
          disabled={!togglable ? selected : undefined}
          aria-label={name}
          onClick={() => {
            onClickTool(id);
            if (selectedTool !== 'image_enhancer') {
              updateSelectedAnnotationId({ id: null });
            }
          }}
          size="small"
        >
          {icon}
        </StyledIconButton>
      </ButtonContainer>
    </Tooltip>
  );
};
SmallToolButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.any,
  selected: PropTypes.any,
  togglable: PropTypes.any,
  alwaysShowing: PropTypes.bool,
  updateSelectedAnnotationId: PropTypes.func,
};
export default memo(
  SmallToolButton,
  (prevProps, nextProps) =>
    prevProps.togglable === nextProps.togglable &&
    prevProps.selected === nextProps.selected &&
    prevProps.name === nextProps.name &&
    prevProps.id === nextProps.id,
);
