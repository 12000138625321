import { PropTypes } from 'prop-types';

export const ribbonFilterPropType = {
  isLoading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  i18nKey: PropTypes.string,
  count: PropTypes.number,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
};

export const ribbonStatePropType = {
  selected: PropTypes.shape(ribbonFilterPropType),
  filters: PropTypes.arrayOf(PropTypes.shape(ribbonFilterPropType)),
  updateFilter: PropTypes.func,
};
