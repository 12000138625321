import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { MiniLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { DateRange } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors/colors';

import { useSaveTemplate } from '../../data-hooks/use-save-template';
import { EmailRecipients } from '../email-recipients/email-recipients';

const StyledDialog = styled(Dialog)`
  width: 550px;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 12px;
`;

const CancelButton = styled(Button)`
  border-color: ${globalColors.slate12};
  margin-right: 20px;
`;

const Title = styled.h1`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${({ theme }) => theme.createReport.sendReportModal.titleColor};
  margin-bottom: 10px;
  font-weight: 500;
`;

const Label = styled.label`
  display: block;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  color: ${({ theme }) => theme.createReport.sendReportModal.labelColor};
  margin-bottom: 2px;
`;

const ErrorLabel = styled(Label)`
  color: ${({ theme }) => theme.createReport.sendReportModal.errorLabelColor};
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
`;

const ParameterValue = styled.p`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  word-wrap: break-word;
  margin: 0 0 10px;
  color: ${({ theme }) => theme.createReport.sendReportModal.parameterColor};
`;

const Separator = styled.hr`
  border: none;
  border-top: 2px solid ${({ theme }) => theme.createReport.sendReportModal.separationColor};
  padding-top: 10px;
  margin-bottom: 5px;
`;

const REPORTING_NAMESPACE = 'reporting.sidebar';
const ANALYZE_NAMESPACE = 'analyze.dashboard';

const TimeRangeDefaultLabels = {
  [DateRange.CURRENT_DAY]: 'Current Day',
  [DateRange.LAST_DAY]: 'Last Day',
  [DateRange.NEXT_DAY]: 'Next Day',
  [DateRange.LAST_WEEK]: 'Last Week',
  [DateRange.NEXT_WEEK]: 'Next Week',
  [DateRange.LAST_MONTH]: 'Last Month',
};

export const SaveTemplateModal = ({ isOpen, onModalClose, onTemplateConflict }) => {
  const { t } = useTranslation([REPORTING_NAMESPACE, ANALYZE_NAMESPACE], {
    useSuspense: false,
  });

  const { push } = useHistory();

  const { reportState, initalReportState } = useContext(ReportsContext);

  const {
    baseTemplate,
    name: templateName,
    description,
    scopeName,
    distributionList,
    timeRange: selectedTimeRange,
  } = reportState ?? {};
  const { timeRange: initialTimeRange } = initalReportState ?? {};

  let timeRange = null;
  if (selectedTimeRange === DateRange.CUSTOM) {
    if (initialTimeRange !== DateRange.CUSTOM) {
      timeRange = initialTimeRange;
    }
  } else {
    timeRange = selectedTimeRange;
  }

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const { mutate: saveTemplate } = useSaveTemplate({
    onSuccess: (templateId) => {
      setSaveError(null);
      setIsSaving(false);

      if (templateId !== reportState.id) {
        push(`/reporting/reports/create/${templateId}`);
      } else {
        window.location.reload();
      }

      onModalClose();
    },
    onError: (error) => {
      setIsSaving(false);

      if (error?.response?.status === 409) {
        onTemplateConflict();
      } else {
        setSaveError(error ?? 'Unspecified error');
      }
    },
  });

  const handleCancel = useCallback(() => {
    setSaveError(null);
    onModalClose();
  }, [onModalClose]);

  const handleSaveTemplate = () => {
    setIsSaving(true);
    saveTemplate({ templateData: reportState });
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      onClose={handleCancel}
      header={t('save_template', 'Save Template')}
      footer={
        <>
          <ButtonContainer>
            {isSaving && (
              <LoaderContainer>
                <MiniLoader />
              </LoaderContainer>
            )}
            <CancelButton onClick={handleCancel}>{t('cancel', 'Cancel')}</CancelButton>
            <Button onClick={() => handleSaveTemplate()} disabled={isSaving} primary>
              {t('save_template', 'Save Template')}
            </Button>
          </ButtonContainer>
          {saveError && (
            <ErrorLabel>
              {t(
                'save_template_error',
                'An error occurred and your changes were not saved. Please try again or contact support.',
              )}
            </ErrorLabel>
          )}
        </>
      }
      padContent={true}
    >
      <Label>{t('template_name', 'Template Name')}</Label>
      <Title>{templateName}</Title>
      {baseTemplate && (
        <>
          <Label>{t('scope', 'Scope')}</Label>
          <ParameterValue>{scopeName || t('na', 'N/A')}</ParameterValue>
        </>
      )}
      <Label>{t('description', 'Description')}</Label>
      <ParameterValue>{description}</ParameterValue>
      <Label>{t('time_range', 'Time Range')}</Label>
      {timeRange && (
        <ParameterValue>
          {t(`filters.${timeRange}`, TimeRangeDefaultLabels[timeRange] ?? timeRange)}
        </ParameterValue>
      )}
      {!timeRange && (
        <ParameterValue>
          {t(`filters.${DateRange.CURRENT_DAY}`, TimeRangeDefaultLabels[DateRange.CURRENT_DAY])}
        </ParameterValue>
      )}
      {selectedTimeRange === DateRange.CUSTOM && (
        <ParameterValue>
          {t('custom_dates_not_saved', 'Custom dates are not saved to templates.')}
        </ParameterValue>
      )}
      <Separator />
      <Label>{t('recipients', 'Recipients')}</Label>
      <EmailRecipients distributionList={distributionList} readOnly={true} />
    </StyledDialog>
  );
};

SaveTemplateModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
  onTemplateConflict: PropTypes.func,
};
