import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@ge/components/input';
import { DateTimeFormats } from '@ge/models/constants';

import { HLP_ACTIONS, SeeMoreDescription } from './hp-common';
import {
  FormWrapper,
  HandlingProcedureFormSection,
  StyledTextarea,
  UpdatedBy,
  UpdatedLabel,
  UpdatedValue,
} from './hp-styled-components';

const HP_NAME_REGEX = /^[^!-/:-@[-`{-~]+$/;

export const HLPForm = (props) => {
  const { action, auditProps = {}, children } = props;
  const { control } = useFormContext();
  const { t } = useTranslation(['admin.configure']);

  const renderUpdatedBy = useMemo(() => {
    return action === HLP_ACTIONS.VIEW ? (
      <HandlingProcedureFormSection>
        <UpdatedBy>
          <UpdatedLabel>{t('Handling_procedures.last_updated', 'Last Updated')}</UpdatedLabel>
          <UpdatedValue>
            {auditProps.updatedDate
              ? dayjs(auditProps.updatedDate).format(DateTimeFormats.DEFAULT_DATE_TIME_TIMEZONE)
              : '-'}
          </UpdatedValue>
        </UpdatedBy>
        <UpdatedBy>
          <UpdatedLabel>{t('Handling_procedures.last_updated_by', 'Last Updated By')}</UpdatedLabel>
          <UpdatedValue>{auditProps.updatedBy || '-'}</UpdatedValue>
        </UpdatedBy>
      </HandlingProcedureFormSection>
    ) : null;
  }, [action, auditProps.updatedBy, auditProps.updatedDate, t]);

  return (
    <FormWrapper>
      <Controller
        control={control}
        name="title"
        rules={{
          required: true,
          validate: (val) => Boolean(val?.trim()) && HP_NAME_REGEX.test(val),
        }}
        render={({ onChange, onBlur, value }, { invalid }) =>
          action === HLP_ACTIONS.VIEW ? (
            <div>
              <p className="label-cls">
                {t('Handling_procedures.HLP_Name', 'Handling Procedure Name')}
              </p>
              <p>{value}</p>
            </div>
          ) : (
            <Input
              style={{ width: '250px' }}
              required
              error={invalid ? 'HLP name invalid' : ''}
              label={t('Handling_procedures.HLP_Name', 'Handling Procedure Name')}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              value={value}
            />
          )
        }
      />
      <Controller
        control={control}
        name="desc"
        rules={{
          required: true,
          validate: (val) => Boolean(val?.trim()),
        }}
        render={({ onChange, onBlur, value }, { invalid }) =>
          action === HLP_ACTIONS.VIEW ? (
            <div>
              <p className="label-cls">{t('Handling_procedures.description', 'Description')}</p>
              <SeeMoreDescription charLimit={2000} className="handling-description">
                {value}
              </SeeMoreDescription>
            </div>
          ) : (
            <StyledTextarea
              error={invalid ? 'HLP name invalid' : ''}
              label={t('Handling_procedures.description', 'Description')}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              value={value}
              maxLength="3999"
              rows="10"
              required
            />
          )
        }
      />
      {children}
      {renderUpdatedBy}
    </FormWrapper>
  );
};

HLPForm.propTypes = {
  action: PropTypes.string,
  auditProps: PropTypes.any,
  children: PropTypes.any,
};
