import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageContainer } from '@ge/components/containers';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { useAuth } from '@ge/shared/data-hooks';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';

import { AnalysisTemplates } from '../components/configure/cases/analysis-template';
import { NewAnalysisTemplateButton } from '../components/configure/cases/analysis-template';
import { CaseTemplates } from '../components/configure/cases/case-templates';
import { ConfigureNavItem, ConfigureNavItems } from '../models/configure-nav-items';

const Configure = () => {
  const { t, ready } = useTranslation(['admin.configure']);
  const { filter } = useParams();

  const { isAuthorized } = useAuth();

  const modifiedNavItems = useMemo(() => {
    return ConfigureNavItems.filter(({ capabilities }) =>
      capabilities ? isAuthorized({ capabilities }) : true,
    );
  }, [isAuthorized]);

  return (
    <DetailsDismissInert>
      <PageContainer i18nReady={ready}>
        <h1 className="pageTitle">{t('application-configuration', 'Application Configuration')}</h1>
        <SubNavigation navItems={modifiedNavItems}>
          <AuthRender capability={Capability.CASES_RECOMMENDATIONS} create siteLevel={false}>
            {filter === ConfigureNavItem.ANALYSIS_TEMPLATES && <NewAnalysisTemplateButton />}
          </AuthRender>
        </SubNavigation>
        <AuthRender capability={Capability.CASES_RECOMMENDATIONS} create siteLevel={false}>
          {filter === ConfigureNavItem.CASE_TEMPLATES && <CaseTemplates />}
        </AuthRender>
        <AuthRender capability={Capability.CASES_RECOMMENDATIONS} create siteLevel={false}>
          {filter === ConfigureNavItem.ANALYSIS_TEMPLATES && <AnalysisTemplates />}
        </AuthRender>
      </PageContainer>
    </DetailsDismissInert>
  );
};

export default Configure;
