import { action, computed, persist, thunk } from 'easy-peasy';

import merge from '@ge/util/deep-merge';

import { fetchRegions } from '../services/region';

import indexedDb from './storage/indexedDb';

// Define initial state
const defaultRegionState = persist(
  {
    regions: {},
    lastUpdated: 0,
  },
  {
    version: 1,
    storage: indexedDb,
    mergeStrategy: 'overwrite',
  },
);

// Actions
const regionActions = {
  /**
   * Reset state to defaults
   */
  resetRegions: action((state) => {
    state = Object.assign(state, defaultRegionState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.regions = defaultRegionState.regions;
  }),

  /**
   * Set the regions state array
   */
  updateRegions: action((state, payload) => {
    payload.forEach((region) => {
      const existingRegion = state.regions[region.id];
      state.regions[region.id] = !existingRegion ? region : merge(existingRegion, region);
      state.lastUpdated = new Date().getTime();
    });
  }),
  //rocStations

  /**
   * Retrieve regions from API and update state.
   */
  fetchRegions: thunk(async (actions, payload, { fail, getStoreActions }) => {
    try {
      const regionsWithSites = await fetchRegions(payload);

      // Get the list of sites to update with their embedded regions
      const sites = regionsWithSites.reduce((acc, r) => [...acc, ...r.sites], []);

      // Update sites with the regions
      getStoreActions().sites.updateSites(sites);

      // Remove the sites from the regions since the association is on the sites
      const regions = Object.values(regionsWithSites).map(({ sites, ...rest }) => rest);

      // Update the regions without the sites
      actions.updateRegions(Object.values(regions));
    } catch (err) {
      fail(err);
    }
  }),
};

// Computed values
const regionComputed = {
  /**
   * Return the region object by its ID.
   */
  getRegionById: computed((state) => (regionId) => state.regions[regionId]),

  sortedRegions: computed((state) =>
    Object.values(state.regions).sort((a, b) => a.name?.localeCompare(b.name)),
  ),
};

// Compile the view store object for export
const regionModel = {
  ...defaultRegionState,
  ...regionActions,
  ...regionComputed,
};

export default regionModel;
