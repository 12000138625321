import { ChartType } from '@ge/components/charts';

import { KpiCategorySeriesType } from './constants';

// api that kpi chart exposes for actions like selecting and toggling number of visible columns
export const kpiChartApi = ({ ref = {}, series = [], seriesType, type }) => {
  if (!ref) {
    return {};
  }

  const { selectBy } = ref;

  return {
    // select points on chart that represent entities (sites, turbines, etc)
    selectEntities: (entities = []) => {
      const hasEntities =
        entities && entities.length && seriesType === KpiCategorySeriesType.ENTITY;

      if (!hasEntities) {
        return;
      }

      // select entities by id in chart
      const predicate = ({ id: pointId }) => entities.some(({ id }) => pointId === id);

      selectBy && selectBy({ predicate });
    },
    // set number of visible points on chart
    // NOTE: had a lot of issues trying to do this without purging existing data first
    setMaxEntities: (maxEntities) => {
      if (seriesType !== KpiCategorySeriesType.ENTITY) {
        return;
      }

      // for mirror charts we update both upper and lower charts
      if (type === ChartType.MIRROR_COLUMN) {
        const { setLowerData, setUpperData } = ref;
        const lowerSeries = series.slice(-1);
        const { data: lowerData } = lowerSeries[0] || {};

        setLowerData && setLowerData({ data: lowerData.slice(0, maxEntities) });

        const upperSeries = series.slice(0, series.length - 1);

        setUpperData &&
          upperSeries.forEach(({ data }, i) =>
            setUpperData({ data: data.slice(0, maxEntities), seriesIndex: i }),
          );
      } else {
        const { setData } = ref;

        setData &&
          series.forEach(({ data }, i) =>
            setData({ data: data.slice(0, maxEntities), seriesIndex: i }),
          );
      }
    },
  };
};
