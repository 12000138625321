import { action, persist } from 'easy-peasy';

// Define initial state
const defaultAuthState = {
  metadata: persist(
    {},
    {
      storage: 'sessionStorage',
      mergeStrategy: 'overwrite',
    },
  ),
};

const authActions = {
  /* eslint-disable-next-line no-unused-vars */
  resetAuth: action((state) => {
    state = Object.assign(state, defaultAuthState);
  }),

  setMetadata: action((state, payload) => {
    if (payload) {
      state.metadata = payload;
      // WalkMe
      window.walkme = payload;
    }
  }),
};

// TODO - handle metadata change
// const authListeners = {
//   metadataListener: actionOn(
//     (actions) => actions.setMetadata,
//     () => {},
//   ),
// };

const authModel = {
  ...defaultAuthState,
  ...authActions,
  // ...authListeners,
};

export default authModel;
