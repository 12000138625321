import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text, Title } from '@ge/components/typography';
import { typography } from '@ge/tokens/typography';

import { TabDetailContainer } from '../../entity-details-shared';
import { EntityCollapsiblePanel } from '../../entity-details-shared';

const BreakersContainer = styled.div`
  display: flex;
  padding: 20px 20px 0;
  .totals {
    max-width: 120px;
    margin-right: 15px;
    > .note {
      color: ${(props) => props.theme.entityDetails.battery.textColor};
      font-size: 10px;
      line-height: 12px;
      font-family: ${typography.family.default};
      font-weight: ${typography.weight.regular};
      font-style: italic;
    }
  }
  .breakers {
    flex: 1;
  }
`;

const BreakerTitles = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  > div {
    padding: 6px 20px 0px 6px;
    margin-bottom: 5px;
    border-left: solid 4px transparent;
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.theme.entityDetails.battery.textColor};
    font-size: 10px;
    line-height: 12px;
    font-family: ${typography.family.default};
    font-weight: ${typography.weight.medium};
    border-right: solid transparent 1px;
    &:nth-child(3n) {
      border: 0;
    }
  }
`;

const BreakerColumns = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
`;

const BreakerItem = styled.div`
  padding: 6px;
  padding-right: 20px;
  border-left: solid 4px transparent;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid ${(props) => props.theme.entityDetails.battery.borderColor};
  &.non-nominal {
    border-left-color: ${(props) => props.theme.dangerColor};
  }
`;

const BreakerName = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  text-transform: uppercase;
  margin-right: 10px;
`;

const BreakerCurrent = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.entityDetails.battery.openColor};
  text-transform: capitalize;
  &.close {
    color: ${(props) => props.theme.dangerColor};
  }
`;

const NominalTitle = styled(Title).attrs(() => ({
  level: 5,
}))`
  color: ${(props) => props.theme.entityDetails.battery.textColor};
`;

const NominalCount = styled(Text).attrs(() => ({
  type: typography.textTypes.kpi,
  level: 2,
  component: typography.elementTypes.div,
}))`
  margin: 6px 0 10px;
  > span {
    &:first-child {
      color: ${(props) => props.theme.dangerColor};
    }
    margin-right: 3px;
  }
`;

export const AssetBreakers = ({ asset }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  if (!ready || !asset.metrics?.breakers) {
    return null;
  }

  const { breakers } = asset.metrics || {};
  const nonNominalCount = breakers?.filter((b) => b.defaultState !== b.value).length;

  return (
    <EntityCollapsiblePanel
      headerContent={<h2>{t('asset_panel.breakers', 'Breakers')}</h2>}
      expanded={true}
    >
      <TabDetailContainer>
        <BreakersContainer>
          <div className="totals">
            <NominalTitle level={5}>{t('battery_panel.non_nominal', 'Non-nominal')}</NominalTitle>
            <NominalCount>
              <span>{nonNominalCount}</span>
              <span>/</span>
              <span>{breakers?.length}</span>
            </NominalCount>
            <div className="note">
              {t('battery_panel.breaker_state', 'These breakers are not in their specified state.')}
            </div>
          </div>
          <div className="breakers">
            <BreakerTitles>
              {[...Array(3)].map((_, idx) => (
                <div key={`${idx}-title`}>
                  <span>{t('battery_panel.name', 'Name')}</span>
                  <span>{t('battery_panel.current', 'Current')}</span>
                </div>
              ))}
            </BreakerTitles>
            <BreakerColumns>
              {breakers?.map((breaker) => (
                <BreakerItem key={breaker.id} className={!breaker.value && 'non-nominal'}>
                  <BreakerName>{breaker.desc}</BreakerName>
                  <BreakerCurrent className={breaker.value && 'close'}>
                    {!breaker.value
                      ? t('battery_panel.open', 'Open')
                      : t('battery_panel.close', 'Close')}
                  </BreakerCurrent>
                </BreakerItem>
              ))}
            </BreakerColumns>
          </div>
        </BreakersContainer>
      </TabDetailContainer>
    </EntityCollapsiblePanel>
  );
};

AssetBreakers.propTypes = {
  asset: PropTypes.instanceOf(Object).isRequired,
};
