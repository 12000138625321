export const CaseAlertsColumns = {
  // Type
  GROUP_TYPE: 'group-type',
  TYPE: 'type',

  // Code
  GROUP_CODE: 'group-code',
  CODE: 'code',

  // Description
  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'description',

  // Duration
  GROUP_DURATION: 'group-duration',
  DURATION: 'duration',

  // Start
  GROUP_START: 'group-start',
  START: 'start',

  // End
  GROUP_END: 'group-end',
  END: 'end',

  // Open Tasks
  // TODO: Remove this?
  GROUP_OPEN_TASKS: 'group-open-tasks',
  OPEN_TASKS: 'open-tasks',

  // Sensor Readings
  GROUP_SENSOR_READINGS: 'group-sensor-readings',
  SENSOR_READINGS: 'sensor-readings',
};

// Define all available columns in the case alerts table.
export const CaseAlertsColumnDefs = {
  [CaseAlertsColumns.GROUP_TYPE]: {
    cols: {
      [CaseAlertsColumns.TYPE]: {
        labels: [
          {
            a11yKey: 'table.type',
            a11yDefault: 'Type',
            sortValue: 'type',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.GROUP_CODE]: {
    align: 'left',
    minWidth: '50px',
    cols: {
      [CaseAlertsColumns.CODE]: {
        labels: [
          {
            a11yKey: '',
            a11yDefault: '#',
            sortValue: 'code',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.GROUP_DESCRIPTION]: {
    align: 'left',
    cols: {
      [CaseAlertsColumns.DESCRIPTION]: {
        minWidth: '130px',
        labels: [
          {
            a11yKey: 'table.title',
            a11yDefault: 'Title',
            sortValue: 'description',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.GROUP_DURATION]: {
    align: 'left',
    cols: {
      [CaseAlertsColumns.DURATION]: {
        minWidth: '75px',
        labels: [
          {
            a11yKey: 'table.duration',
            a11yDefault: 'Duration',
            sortValue: 'duration',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.GROUP_START]: {
    align: 'left',
    minWidth: '118px',
    cols: {
      [CaseAlertsColumns.START]: {
        labels: [
          {
            a11yKey: 'table.site_tz',
            a11yDefault: 'Site (EST)',
          },
          {
            a11yKey: 'table.start',
            a11yDefault: 'Start',
            sortValue: 'start',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.GROUP_END]: {
    align: 'left',
    minWidth: '118px',
    cols: {
      [CaseAlertsColumns.END]: {
        labels: [
          {
            a11yKey: 'table.end',
            a11yDefault: 'End',
            sortValue: 'end',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.GROUP_OPEN_TASKS]: {
    align: 'left',

    cols: {
      [CaseAlertsColumns.OPEN_TASKS]: {
        labels: [
          {
            a11yKey: 'table.openTasks',
            a11yDefault: 'Open Tasks',
            sortValue: 'openTasks',
          },
        ],
      },
    },
  },
  [CaseAlertsColumns.SENSOR_READINGS]: {
    align: 'left',
    cols: {
      [CaseAlertsColumns.SENSOR_READINGS]: {
        width: '70px',
        whiteSpace: 'normal',
        labels: [
          {
            a11yKey: 'table.sensor_readings',
            a11yDefault: 'Sensor Readings',
            sortValue: 'sensor_readings',
          },
        ],
        cell: {
          align: 'center',
          maxWidth: '70px',
        },
      },
    },
  },
};

// Define the default visible columns and their order.
// NOTE: Defining this in one place because currently all
// implementations of the case alerts table have the same
// order. When presentations diverge, move this definition
// closer to the implementation and modify accordingly.
export const defaultCaseAlertsCols = [
  {
    id: CaseAlertsColumns.GROUP_TYPE,
    cols: [
      {
        id: CaseAlertsColumns.TYPE,
        visible: true,
      },
    ],
  },
  {
    id: CaseAlertsColumns.GROUP_CODE,
    cols: [
      {
        id: CaseAlertsColumns.CODE,
        visible: true,
      },
    ],
  },
  {
    id: CaseAlertsColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: CaseAlertsColumns.DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: CaseAlertsColumns.GROUP_DURATION,
    cols: [
      {
        id: CaseAlertsColumns.DURATION,
        visible: true,
      },
    ],
  },
  {
    id: CaseAlertsColumns.GROUP_START,
    cols: [
      {
        id: CaseAlertsColumns.START,
        visible: true,
      },
    ],
  },
  {
    id: CaseAlertsColumns.GROUP_END,
    cols: [
      {
        id: CaseAlertsColumns.END,
        visible: true,
      },
    ],
  },
  {
    id: CaseAlertsColumns.SENSOR_READINGS,
    cols: [
      {
        id: CaseAlertsColumns.SENSOR_READINGS,
        visible: true,
      },
    ],
  },
];
