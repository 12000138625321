import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { EntityCreateContext } from '@ge/shared/context/entity-create-context';
import { typography } from '@ge/tokens';

import logo from '../images/logo.svg';

const Header = styled.div`
  align-items: stretch;
  border: solid 1px ${(props) => props.theme.admin.actionHeader.borderColor};
  border-radius: 6px;
  display: flex;
  margin: 30px 10px 10px 10px;
  place-content: space-between;
`;

const HeaderTitle = styled.div`
  align-items: center;
  display: flex;
  padding: 24px 20px 23px 28px;
  h2 {
    font-size: 30px;
    margin-left: 10px;
  }
`;

const HeaderActionsContainer = styled.div`
  border-left: solid 1px ${(props) => props.theme.admin.actionHeader.borderColor};
  color: white;
  padding: 10px 79px 8px 20px;
  button,
  a {
    align-items: center;
    color: ${(props) => props.theme.admin.actionHeader.linkColor};
    display: flex;
    font-weight: ${typography.weight.bold};
    font-size: 12px;
    width: 100%;
    display: block;
    text-align: left;
    margin-bottom: 6px;
    padding: 0 6px 0 0;
    text-transform: uppercase;
    text-decoration: none;
    svg {
      margin-bottom: 3px;
    }
  }
`;

export const HeaderIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.admin.actionHeader.iconColor,
  viewbox: props.viewbox,
}))`
  margin-right: 7px;
`;

const GlobalAdminActionsHeader = () => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });
  const { showCreatePerson, showCreateRole } = useContext(EntityCreateContext);

  const handleCreatePerson = useCallback(() => {
    showCreatePerson();
  }, [showCreatePerson]);

  const handleCreateRole = useCallback(() => {
    showCreateRole();
  }, [showCreateRole]);

  return (
    <Header>
      <HeaderTitle>
        <img src={logo} alt="Logo" />
        <h2>{t('title', 'GE Admin')}</h2>
      </HeaderTitle>
      <HeaderActionsContainer>
        <button type="button" onClick={handleCreatePerson}>
          <HeaderIcon icon={Icons.ADD} /> {t('add_new_person', 'Add New Person')}
        </button>
        <button type="button" onClick={handleCreateRole}>
          <HeaderIcon icon={Icons.ADD} /> {t('add_new_role', 'Add New Role')}
        </button>
      </HeaderActionsContainer>
    </Header>
  );
};

export default GlobalAdminActionsHeader;
