import { PropTypes } from 'prop-types';
import concat from 'ramda/src/concat';
import React from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

import { Tbody, Td } from './table';

const StyledTbody = styled(Tbody)`
  tr:not(:first-child) {
    display: none;
  }
  &.expanded tr {
    display: table-row;
  }
`;

const CarrotIcon = styled(Icon).attrs((props) => ({
  size: 12,
  color: props.theme.analyze.majorLoss.table.text,
  icon: Icons.TRAY_EXPANDABLE,
  rotate: props.rotate,
}))`
  margin-top: -3px;
  margin-right: 6px;
  transform: rotate(-90deg);
  &.expanded {
    transform: rotate(0deg);
  }
`;

const isExpanded = (Arr, id) => (Arr.indexOf(id) > -1 ? 'expanded' : '');

export const TableGroup = ({ id, expandedArr, children }) => {
  return (
    <StyledTbody key={id} transparent className={isExpanded(expandedArr, id)}>
      {children}
    </StyledTbody>
  );
};

TableGroup.propTypes = {
  id: PropTypes.string,
  expandedArr: PropTypes.instanceOf(Array),
  children: PropTypes.instanceOf(Object),
};

TableGroup.defaultProps = {
  id: null,
  expandedArr: null,
  children: null,
};

export const TdToggle = ({ id, expandedArr, setExpandedArr, limit, onClick }) => {
  const expandRow = (rows, rowId, e) => {
    e.stopPropagation();

    const newExpandedRows = isExpanded(rows, rowId)
      ? rows.filter((id) => id !== rowId)
      : limit
      ? [rowId]
      : concat(rows, [rowId]);

    setExpandedArr(newExpandedRows);
    onClick();
  };

  return (
    <Td align="left" onClick={(e) => expandRow(expandedArr, id, e)} style={{ padding: '8px' }}>
      <CarrotIcon className={isExpanded(expandedArr, id)} />
    </Td>
  );
};

TdToggle.propTypes = {
  id: PropTypes.string,
  expandedArr: PropTypes.instanceOf(Array),
  limit: PropTypes.bool,
  onClick: PropTypes.func,
  setExpandedArr: PropTypes.func,
};

TdToggle.defaultProps = {
  id: null,
  expandedArr: null,
  limit: false,
  onClick: () => null,
  setExpandedArr: () => null,
};
