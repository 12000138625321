import { TableFilterTypes } from '@ge/models/constants';
import { Capability, PermissionScope } from '@ge/models/constants';

export const CaseHistoryColumns = {
  // Source
  GROUP_SOURCE: 'group-source',
  SOURCE: 'source',

  //Work Scope
  GROUP_WORKSCOPE: 'group-workscope',
  WORKSCOPE: 'work-scope',

  // Code
  GROUP_CODE: 'group-code',
  CODE: 'code',

  // Description
  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'description',

  // Duration
  GROUP_DURATION: 'group-duration',
  DURATION: 'duration',

  // Start
  GROUP_START: 'group-start',
  START: 'start',

  // End
  GROUP_END: 'group-end',
  END: 'end',

  // Open Tasks
  // TODO: Remove this?
  GROUP_OPEN_TASKS: 'group-open-tasks',
  OPEN_TASKS: 'open-tasks',

  // View
  GROUP_VIEW: 'group-view',
  VIEW: 'view',

  // Notes
  GROUP_NOTES: 'group-notes',
  NOTES: 'notes',

  // SubType
  GROUP_SUBTYPE: 'group-subtype',
  SUBTYPE: 'subtype',

  // Stop
  GROUP_STOP: 'group-stop',
  STOP: 'stop',

  // Note
  GROUP_LASTNOTE: 'group-lastnote',
  LASTNOTE: 'lastnote',

  // Title
  GROUP_TITLE: 'group-title',
  TITLE: 'title',

  GROUP_COMPLETED_DATE_TIME: 'group-completed-date-time',
  COMPLETED_DATE_TIME: 'completed-date-time',

  GROUP_ASSIGNED_TECHS: 'group-techs',
  ASSIGNED_TECHS: 'techs',

  GROUP_PERFORMED_BY: 'group-performed-by',
  PERFORMED_BY: 'performed-by',

  GROUP_PARTS_CONSUMED: 'group-parts-consumed',
  PARTS_CONSUMED: 'parts-consumed',

  GROUP_WO_SR: 'group-wo-sr',
  WO_SR: 'wo-sr',

  GROUP_RESOLUTION_NOTES: 'group-resolution-notes',
  RESOLUTION_NOTES: 'resolutionNotes',

  GROUP_TASK_DETAIL: 'group-detail',
  TASK_DETAIL: 'detail',

  GROUP_CASE_ID: 'group-case-id',
  CASE_ID: 'id',
};

// Define all available columns in the case history table.
export const CaseHistoryColumnDefs = {
  // [CaseHistoryColumns.GROUP_SUBTYPE]: {
  //   align: 'left',
  //   cols: {
  //     [CaseHistoryColumns.SUBTYPE]: {
  //       labels: [
  //         {
  //           a11yKey: 'table.subtype',
  //           a11yDefault: 'SubType',
  //           sortValue: 'subtype',
  //         },
  //       ],
  //     },
  //   },
  // },
  [CaseHistoryColumns.GROUP_SOURCE]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.SOURCE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '85px',
        labels: [
          {
            a11yKey: 'table.source',
            a11yDefault: 'Source',
            sortValue: 'source',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_WORKSCOPE]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.WORKSCOPE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '85px',
        labels: [
          {
            a11yKey: 'table.work_scope',
            a11yDefault: 'Work Scope',
            sortValue: 'workScope',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_TITLE]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.TITLE]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '135px',
        labels: [
          {
            a11yKey: 'table.asset_history_title',
            a11yDefault: 'Title',
            sortValue: 'description',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_COMPLETED_DATE_TIME]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.COMPLETED_DATE_TIME]: {
        filterType: TableFilterTypes.DATE,
        minWidth: '60px',
        labels: [
          {
            a11yKey: 'table.completed_date',
            a11yDefault: 'Completed Date',
            sortValue: 'completedDateTime',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_ASSIGNED_TECHS]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.ASSIGNED_TECHS]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '60px',
        labels: [
          {
            a11yKey: 'table.assigned_techs',
            a11yDefault: 'Assigned Techs',
            sortValue: 'technicians',
          },
        ],
        filterTypeDefs: {
          nested: true,
        },
      },
    },
  },
  [CaseHistoryColumns.GROUP_PERFORMED_BY]: {
    align: 'left',
    minWidth: '80px',
    cols: {
      [CaseHistoryColumns.PERFORMED_BY]: {
        labels: [
          {
            a11yKey: 'table.performed_by',
            a11yDefault: 'Performed By',
            sortValue: 'performedby',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_RESOLUTION_NOTES]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.RESOLUTION_NOTES]: {
        filterType: TableFilterTypes.SEARCH,
        minWidth: '50px',
        labels: [
          {
            a11yKey: 'table.resolution_notes',
            a11yDefault: 'Resolution Notes',
            sortValue: 'resolutionNotes',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_WO_SR]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.WO_SR]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '40px',
        labels: [
          {
            a11yKey: 'table.wo_sr',
            a11yDefault: 'WO / SR #',
            sortValue: 'wo',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_PARTS_CONSUMED]: {
    align: 'left',
    minWidth: '30px',
    cols: {
      [CaseHistoryColumns.PARTS_CONSUMED]: {
        filterType: TableFilterTypes.CHECKBOXES,
        minWidth: '60px',
        labels: [
          {
            a11yKey: 'table.parts_consumed',
            a11yDefault: 'Parts Consumed',
            sortValue: 'consumedParts.count',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_TASK_DETAIL]: {
    align: 'left',
    minWidth: '30px',
    cols: {
      [CaseHistoryColumns.TASK_DETAIL]: {
        labels: [
          {
            a11yKey: 'table.task_detail',
            a11yDefault: 'Task Detail',
            sortValue: 'detail',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_START]: {
    align: 'left',
    minWidth: '45px',
    cols: {
      [CaseHistoryColumns.START]: {
        labels: [
          {
            a11yKey: 'table.start',
            a11yDefault: 'Start',
            sortValue: 'start',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_STOP]: {
    align: 'left',
    minWidth: '45px',
    cols: {
      [CaseHistoryColumns.STOP]: {
        labels: [
          {
            a11yKey: 'table.stop',
            a11yDefault: 'Stop',
            sortValue: 'end',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_LASTNOTE]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.LASTNOTE]: {
        minWidth: '135px',
        labels: [
          {
            a11yKey: 'table.last_note',
            a11yDefault: 'Last Note',
            sortValue: 'eventNote',
          },
        ],
      },
    },
  },
  [CaseHistoryColumns.GROUP_NOTES]: {
    align: 'center',
    cols: {
      [CaseHistoryColumns.NOTES]: {
        capabilities: [
          { capability: Capability.NOTES_AND_ATTACHMENTS, scopes: [PermissionScope.VIEW] },
        ],
        minWidth: '50px',
        labels: [
          {
            a11yKey: 'table.notes',
            a11yDefault: 'Note',
            sortValue: 'eventNote',
          },
        ],
        cell: {
          align: 'center',
        },
      },
    },
  },
  [CaseHistoryColumns.GROUP_CASE_ID]: {
    align: 'left',
    cols: {
      [CaseHistoryColumns.CASE_ID]: {
        minWidth: '135px',
        labels: [
          {
            a11yKey: 'table.id',
            a11yDefault: 'Case Id',
            sortValue: 'id',
          },
        ],
      },
    },
  },
};

// Define the default visible columns and their order.
// NOTE: Defining this in one place because currently all
// implementations of the case history table have the same
// order. When presentations diverge, move this definition
// closer to the implementation and modify accordingly.
export const defaultCaseHistoryCols = [
  {
    id: CaseHistoryColumns.GROUP_SOURCE,
    cols: [
      {
        id: CaseHistoryColumns.SOURCE,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_WORKSCOPE,
    cols: [
      {
        id: CaseHistoryColumns.WORKSCOPE,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_TITLE,
    cols: [
      {
        id: CaseHistoryColumns.TITLE,
        visible: true,
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_COMPLETED_DATE_TIME,
    cols: [
      {
        id: CaseHistoryColumns.COMPLETED_DATE_TIME,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_ASSIGNED_TECHS,
    cols: [
      {
        id: CaseHistoryColumns.ASSIGNED_TECHS,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_PERFORMED_BY,
    cols: [
      {
        id: CaseHistoryColumns.PERFORMED_BY,
        visible: false,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_RESOLUTION_NOTES,
    cols: [
      {
        id: CaseHistoryColumns.RESOLUTION_NOTES,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_WO_SR,
    cols: [
      {
        id: CaseHistoryColumns.WO_SR,
        visible: false,
        filter: 'taskHistory',
      },
    ],
  },
  // {
  //   id: CaseHistoryColumns.GROUP_SUBTYPE,
  //   cols: [
  //     {
  //       id: CaseHistoryColumns.SUBTYPE,
  //       visible: true,
  //       filter: 'event',
  //     },
  //   ],
  // },
  {
    id: CaseHistoryColumns.GROUP_PARTS_CONSUMED,
    cols: [
      {
        id: CaseHistoryColumns.PARTS_CONSUMED,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_TASK_DETAIL,
    cols: [
      {
        id: CaseHistoryColumns.TASK_DETAIL,
        visible: true,
        filter: 'taskHistory',
      },
    ],
  },

  {
    id: CaseHistoryColumns.GROUP_LASTNOTE,
    cols: [
      {
        id: CaseHistoryColumns.LASTNOTE,
        visible: true,
        filter: 'event',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_NOTES,
    cols: [
      {
        id: CaseHistoryColumns.NOTES,
        visible: true,
        filter: 'event',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_START,
    cols: [
      {
        id: CaseHistoryColumns.START,
        visible: true,
        filter: 'event',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_STOP,
    cols: [
      {
        id: CaseHistoryColumns.STOP,
        visible: true,
        filter: 'event',
      },
    ],
  },
  {
    id: CaseHistoryColumns.GROUP_CASE_ID,
    cols: [
      {
        id: CaseHistoryColumns.CASE_ID,
        visible: false,
        filter: 'event',
      },
    ],
  },
];
