import clone from 'ramda/src/clone';
import difference from 'ramda/src/difference';
import intersection from 'ramda/src/intersection';
import { useCallback, useMemo, useRef, useEffect } from 'react';

import useStateRef from '@ge/hooks/state-ref';
import { useLocalStorage } from '@ge/hooks/use-local-storage';
import { SortDirection } from '@ge/models/constants';
import { useAuth } from '@ge/shared/data-hooks';

const DEFAULT_DIRECTION = SortDirection.DESC;
const LOCAL_STATE_PREFIX = 'table-col-state';
const DEFAULT_SORT_TYPE = 'number';

/**
 * Applies the provided authorization function to the input columns.
 * Column definitions are provided for capability and scopes.
 * @param {function} authFn
 * @param {function} authLogFn
 * @param {array} columnDefs
 * @param {array} columns
 * @returns An array of authorized columns
 */
const getAuthorizedCols = ({ authFn, columnDefs, columns = [] }) => {
  // Maintain column order
  const colGroupSort = columns?.map(({ id }) => id);
  return Object.entries(columnDefs ?? {})
    .reduce((groups, [id, { cols }]) => {
      const columnGroup = columns.find(({ id: groupId }) => id === groupId);

      if (!columnGroup) {
        return groups;
      }

      const authCols = Object.entries(cols ?? {}).reduce((_authCols, [id, col]) => {
        const column = columnGroup.cols.find(({ id: columnId }) => id === columnId);
        const label = col.labels?.[0]?.a11yKey;

        if (!column) {
          return _authCols;
        }

        const authorization = {
          ...col,
          source: `Column - ${id}${label ? ` (${label})` : ''}`,
          type: 'Table column blocked',
        };

        if (col.capabilities?.length && !authFn(authorization)) {
          column.visible = false;
        }

        return [..._authCols, column];
      }, []);

      if (!authCols.length) {
        return groups;
      }

      return [
        ...groups,
        {
          ...columnGroup,
          cols: authCols,
        },
      ];
    }, [])
    .sort(({ id: aId }, { id: bId }) => colGroupSort.indexOf(aId) - colGroupSort.indexOf(bId));
};

/**
 * Remove invalid group IDs from column state. This is usually because a previously
 * defined column has been since removed from the application and saved table
 * preferences may include that column and need to be updated.
 *
 * @param {array} invalidGroupIds Array of group IDs to remove
 */
const sanitizeColGroups = (groupIds, invalidGroupIds, columnState) => {
  invalidGroupIds.forEach((groupId) => {
    columnState.splice(groupIds.indexOf(groupId), 1);
  });
};

/**
 * Remove invalid column IDs from column state.
 *
 * @param {array} invalidColIds Array of column IDs to remove
 */
const sanitizeCols = (invalidColIds, columnState) => {
  invalidColIds.map((colId) =>
    columnState.map((group, index) => {
      const removalIndex = group.cols.findIndex((col) => col.id === colId);
      if (removalIndex > -1) {
        columnState[index].cols.splice(removalIndex, 1);
      }
      return columnState;
    }),
  );
};

/**
 * Add the supplied column group IDs to the column state definition. This
 * is usually because the defaults for a feature have been updated and a
 * new column has been added that should be shown to all users.
 *
 * @param {array} newGroups Array of column group IDs to add
 */
const addNewDefaultColGroups = (newGroups, defaultGroups, columnState) => {
  newGroups.forEach((groupId) => {
    const insertIndex = defaultGroups.findIndex((group) => group.id === groupId);
    columnState.splice(insertIndex, 0, defaultGroups[insertIndex]);
  });

  return columnState;
};

/**
 * Add the supplied column IDs to the column state definition.
 *
 * @param {array} newCols Array of column IDs to add
 */
const addNewDefaultCols = (newCols, defaultCols, columnState) => {
  newCols.map((colId) =>
    defaultCols.map((group) => {
      // the new group should already be added in the previous step where addNewDefaultColGroups gets called
      // but can add an extra check here if needed
      const groupIndex = columnState.findIndex(({ id }) => group.id === id);
      const insertIndex = group.cols.findIndex((col) => col.id === colId);
      if (insertIndex > -1) {
        columnState[groupIndex].cols.splice(insertIndex, 0, group.cols[insertIndex]);
      }
      return columnState;
    }),
  );
};

const useColumnState = ({
  columnDefs,
  defaultCols,
  defaultSortMetric,
  defaultSortDirection,
  defaultSortType,
  sortStateId,
}) => {
  const columnDefsRef = useRef(columnDefs);

  // data hooks
  const { audit } = useAuth();

  const defaultVisibleCols = useMemo(
    () =>
      getAuthorizedCols({
        authFn: audit,
        columnDefs,
        columns: defaultCols,
      }),
    [audit, columnDefs, defaultCols],
  );

  const defaultColsRef = useRef(defaultVisibleCols);
  const [visibleCols, setVisibleCols, visibleColsRef] = useStateRef(defaultVisibleCols);
  const [sortMetric, setSortMetric, sortMetricRef] = useStateRef(defaultSortMetric);
  const [sortDirection, setSortDirection, sortDirectionRef] = useStateRef(
    defaultSortDirection || DEFAULT_DIRECTION,
  );
  const [sortType, setSortType] = useStateRef(defaultSortType || DEFAULT_SORT_TYPE);

  // Store sort order in local storage
  const [sortState, setSortState] = useLocalStorage(`${LOCAL_STATE_PREFIX}.${sortStateId}`);

  /**
   * Update the visibility of the columns in state. Input is a map of
   * diffs in the format of `columnKey: isVisibleBoolean`.
   * e.g. -
   * {
   *  'foo.bar': true,
   *  'bar.baz': false
   * }
   */
  const updateColumnVisibility = useCallback(
    (columnChanges) => {
      const dirty = !!columnChanges.size;
      let cols = visibleColsRef.current;

      if (dirty) {
        // We need to do a deep clone here to ensure our objects are unique
        // TODO: Need to determine if React is smart enough to diff this if we
        // deep clone. In this case, if we don't deep clone we end up modifying
        // the same object in visibleCols which makes it impossible to set state
        // at the end to cause a re-render.
        const newColumnState = clone(visibleColsRef.current);
        columnChanges.forEach((visible, compoundKey) => {
          const [groupKey, colKey] = compoundKey.split('.');
          const groupObj = newColumnState.find((group) => group.id === groupKey);

          // If the group object doesn't exist in the default columns, force-add it.
          if (!groupObj) {
            newColumnState.push({
              id: groupKey,
              cols: [
                {
                  id: colKey,
                  visible,
                },
              ],
            });
          } else {
            const colObj = groupObj.cols.find((col) => col.id === colKey);
            colObj.visible = visible;
          }
        });

        setVisibleCols(newColumnState);
        cols = newColumnState;
      }

      return { cols, dirty };
    },
    [setVisibleCols, visibleColsRef],
  );

  /**
   * Flatten available column IDs.
   *
   * @param {array} colGroups Column group object to flatten
   * @param {boolean} filterVisible Filter by visible state
   */
  const getFlatColIds = (colGroups, filterVisible) =>
    colGroups
      .reduce((agg, colGroup) => {
        colGroup.cols.forEach((col) => {
          const doFilter = typeof filterVisible !== 'undefined';
          if (doFilter && filterVisible === col.visible) {
            agg.push(col.id);
          } else if (!doFilter) {
            agg.push(col.id);
          }
        });

        return agg;
      }, [])
      .flat();

  /**
   * Check to determine if the default column definitions have changed from the
   * provided columns previously stored in preferences. If there are differences,
   * make sure to remove anything that is inconsistent to avoid uncontrolled failures.
   */
  const sanitizeColumns = useCallback((columns) => {
    if (!columns) {
      return [];
    }

    const newColsState = clone(columns);

    // IDs //
    const colGroupIds = columns.map((visibleColGroup) => visibleColGroup.id);
    const colIds = getFlatColIds(columns);
    const defaultGroupIds = defaultColsRef.current.map((colGroup) => colGroup.id);
    const defaultColIds = defaultColsRef.current
      .map((colGroup) => colGroup.cols.map((col) => col.id))
      .flat();
    const allGroupIds = Object.keys(columnDefsRef.current);
    const allColIds = allGroupIds
      .map((groupId) => Object.keys(columnDefsRef.current[groupId].cols))
      .flat();

    // Check to see if any existing visible groups/cols have been removed from the table def
    const invalidGroups = difference(colGroupIds, allGroupIds);
    const invalidCols = difference(colIds, allColIds);

    // Check to see if a new (valid) default has been added since prefs were stored
    const newDefaultGroups = intersection(difference(defaultGroupIds, colGroupIds), allGroupIds);
    const newDefaultCols = intersection(difference(defaultColIds, colIds), allColIds);

    // Remove invalid groups
    if (invalidGroups.length) {
      sanitizeColGroups(colGroupIds, invalidGroups, newColsState);
    }

    // Remove invalid columns
    if (invalidCols.length) {
      sanitizeCols(invalidCols, newColsState);
    }

    // Add new default groups or columns. Groups include columns, so handle separately.
    if (newDefaultGroups.length) {
      addNewDefaultColGroups(newDefaultGroups, defaultColsRef.current, newColsState);
    } else if (newDefaultCols.length) {
      addNewDefaultCols(newDefaultCols, defaultColsRef.current, newColsState);
    }

    // Ensure that we are only returning the modified state if there was a change
    const dirty =
      invalidGroups.length ||
      invalidCols.length ||
      newDefaultGroups.length ||
      newDefaultCols.length;
    const cols = dirty ? newColsState : columns;

    return { cols, dirty };
  }, []);

  /**
   * Programmatically set the state of the visible columns. This will
   * automatically sanitize the columns prior to setting state to ensure
   * that column definitions match the available columns that were provided
   * at instantiation. Returns sanitized columns and a dirty flag.
   */
  const setVisibleColumns = useCallback(
    (columns) => {
      const { current: defs } = columnDefsRef;
      const authorizedColumns = getAuthorizedCols({
        authFn: audit,
        columnDefs: defs,
        columns,
      });
      const { cols, dirty } = authorizedColumns ? sanitizeColumns(authorizedColumns) : {};

      setVisibleCols(cols);

      return { cols, dirty };
    },
    [audit, sanitizeColumns, setVisibleCols],
  );

  useEffect(() => {
    const { metric } = sortState || {};

    if (!metric) {
      return;
    }

    const [sortedCol] = sortState.metric.split('.');

    // If sort state references a not visible column, reset.
    if (!getFlatColIds(visibleCols, true).includes(sortedCol)) {
      setSortDirection(defaultSortDirection || DEFAULT_DIRECTION);
      setSortMetric(defaultSortMetric);
    }
  }, [
    visibleCols,
    setSortMetric,
    setSortDirection,
    defaultSortDirection,
    defaultSortMetric,
    sortState,
  ]);

  /**
   * For the provided sort metric, return the sort direction if there is one
   * defined for it.
   *
   * @param {string} metric Sort metric to retrieve sort direction for
   */
  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirectionRef.current : ''),
    [sortDirectionRef, sortMetric],
  );

  useEffect(() => {
    if (!sortState) {
      return;
    }

    setSortMetric(sortState.metric);
    setSortDirection(sortState.direction);
  }, [setSortDirection, setSortMetric, sortState]);

  /**
   * Update the selected sort metric. Either update the direction if the
   * metric is already selected or set the sort metric to the provided
   * metric.
   *
   * @param {string} metric Selected sort metric to toggle/set
   */
  const updateSortMetric = useCallback(
    (metric, type) => {
      if (!metric) return;
      let newSortDirection = defaultSortDirection;
      if (metric === sortMetricRef.current) {
        newSortDirection =
          sortDirectionRef.current === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      }

      setSortDirection(newSortDirection);
      setSortMetric(metric);
      setSortType(type);

      // If a sort state ID is provided, store the sort info to localStorage.
      if (sortStateId) {
        setSortState({
          direction: newSortDirection,
          metric,
        });
      }
    },
    [
      sortMetricRef,
      setSortMetric,
      sortDirectionRef,
      setSortDirection,
      setSortType,
      defaultSortDirection,
      sortStateId,
      setSortState,
    ],
  );

  return {
    updateColumnVisibility,
    setVisibleColumns,
    updateSortMetric,
    sortedDirection,

    visibleCols,
    sortMetric,
    sortDirection,
    sortType,
  };
};

export { useColumnState };
