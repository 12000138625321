import { action, computed, persist, thunk, thunkOn } from 'easy-peasy';
import memoize from 'memoizerific';

import { AssetType } from '@ge/models/constants';
import { groupById } from '@ge/util';
import merge from '@ge/util/deep-merge';
import { sorter } from '@ge/util/metric-sorter';

import {
  fetchAssetById,
  fetchAssetEventsWithMetrics,
  fetchWindTurbinesBySiteId,
  fetchWindTurbines,
  fetchAssetsWithMetrics,
  fetchSiteControllers,
  fetchSubstations,
} from '../services/asset';
import { fetchStorageInvertersBySiteId, fetchStorageInverters } from '../services/inverter';
import { fetchAssetsBySiteAndState } from '../services/site';
import { arrayify } from '../util/general';
import { filterByView, getViewEntity } from '../util/view-utils';

import indexedDb from './storage/indexedDb';

const ASSET_TYPE_COUNT = Object.values(AssetType).length;

// Define initial state
const defaultAssetsState = persist(
  {
    assets: {},
    lastUpdated: 0,
    subStationLastUpdated: 0,
    siteControllerLastUpdated: 0,
    inverterLastUpdated: 0,
    commands: {},
    error: undefined,
    isLoaded: false,
    isLoading: false,
  },
  {
    version: 1,
    storage: indexedDb,
    mergeStrategy: 'mergeShallow',
    allow: [
      'assets',
      'lastUpdated',
      'inverterLastUpdated',
      'siteControllerLastUpdated',
      'subStationLastUpdated',
    ],
  },
);

// Actions
const assetsActions = {
  // we can look into maintaining a history of errors
  setError: action((state, payload) => {
    state.error = payload;
  }),

  setIsLoaded: action((state, payload) => {
    state.isLoaded = payload;
  }),

  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),

  setLastUpdated: action((state) => {
    state.lastUpdated = new Date().getTime();
  }),

  setSubstationLastUpdated: action((state) => {
    state.subStationLastUpdated = new Date().getTime();
  }),

  setSiteControllerLastUpdated: action((state) => {
    state.siteControllerLastUpdated = new Date().getTime();
  }),

  setInverterLastUpdated: action((state) => {
    state.inverterLastUpdated = new Date().getTime();
  }),

  resetAssets: action((state) => {
    state = Object.assign(state, defaultAssetsState);

    // HACK: This is just here to avoid `no-unused-vars` lint :eyeroll:
    state.assets = defaultAssetsState.assets;
  }),

  resetAssetMetrics: action((state) => {
    Object.values(state.assets).forEach((asset) => {
      delete asset.metrics;
    });
  }),

  fetchWindTurbines: thunk(async (actions, payload, { fail }) => {
    try {
      const { assets } = await fetchWindTurbines(payload);

      actions.updateAssets(assets);
    } catch (err) {
      fail(err);
    }
  }),

  fetchSiteControllers: thunk(async (actions, payload, { fail }) => {
    try {
      const { assets: siteControllers } = await fetchSiteControllers(payload);

      actions.updateAssets(siteControllers);
      actions.setSiteControllerLastUpdated();
    } catch (err) {
      fail(err);
    }
  }),

  fetchSubstations: thunk(async (actions, payload, { fail }) => {
    try {
      const { assets: substations } = await fetchSubstations(payload);

      actions.updateAssets(substations);
      actions.setSubstationLastUpdated();
    } catch (err) {
      fail(err);
    }
  }),

  fetchInverters: thunk(async (actions, payload, { fail }) => {
    try {
      const { assets: inverters } = await fetchStorageInverters(payload);
      actions.updateAssets(inverters);
      actions.setInverterLastUpdated();
    } catch (err) {
      fail(err);
    }
  }),

  fetchInvertersBySiteId: thunk(async (actions, payload, { fail }) => {
    try {
      const { assets } = await fetchStorageInvertersBySiteId(payload);

      actions.updateAssets(assets);
    } catch (err) {
      fail(err);
    }
  }),

  fetchAssetsWithMetrics: thunk(async (actions, payload, { fail }) => {
    try {
      const { sortMetric, sortDirection } = payload || {};
      const { assets } = await fetchAssetsWithMetrics(sortMetric, sortDirection);

      actions.updateAssets(assets);
    } catch (err) {
      fail(err);
    }
  }),

  fetchAssetsBySiteAndState: thunk(async (actions, payload, { fail }) => {
    try {
      const { siteId, state } = payload || {};
      const { assets } = await fetchAssetsBySiteAndState(siteId, state);
      actions.updateAssets(assets);
    } catch (err) {
      fail(err);
    }
  }),

  fetchAssetById: thunk(async (actions, payload, { fail }) => {
    try {
      const { asset } = await fetchAssetById(payload);
      if (asset?.id) {
        actions.updateAssets([asset]);
      }
    } catch (err) {
      fail(err);
    }
  }),

  fetchWindTurbinesBySiteId: thunk(async (actions, payload, { fail }) => {
    try {
      const { assets } = await fetchWindTurbinesBySiteId(payload);

      actions.updateAssets(assets);
    } catch (err) {
      fail(err);
    }
  }),

  // TODO (astone): Does this need to return anything or update state?
  fetchAssetEvents: thunk(async (_, payload, { fail }) => {
    try {
      return await fetchAssetEventsWithMetrics(payload);
    } catch (err) {
      fail(err);
    }
  }),

  setAssets: action((state, payload) => {
    state.assets = payload;
  }),

  updateAssets: action((state, payload) => {
    payload.forEach((asset) => {
      const existingAsset = state.assets[asset.id];
      state.assets[asset.id] = !existingAsset ? asset : merge(existingAsset, asset);
    });
  }),

  // can move these into a separate commands model if needed, but these
  // are specific to assets (currently) so putting here for now
  addCommand: action((state, payload) => {
    const { statusId } = payload ?? {};

    if (statusId) {
      state.commands[statusId] = payload;
    }
  }),

  removeCommand: action((state, payload) => {
    delete state.commands[payload];
  }),
};

// Listeners
const assetsListeners = {
  onEventsAdded: thunkOn(
    (_, storeActions) => storeActions.issues.updateEvents,
    (actions, target) => {
      const assets = target.payload
        .filter((event) => !!event.asset)
        .map((event) => ({ ...event.asset, site: { id: event.site.id } }));
      actions.updateAssets(assets);
    },
  ),

  onAlertsAdded: thunkOn(
    (_, storeActions) => storeActions.alerts.setAlerts,
    (actions, target) => {
      const assets = target.payload.filter((alert) => !!alert.asset).map((alert) => alert.asset);
      actions.updateAssets(assets);
    },
  ),

  onWatchlistAdded: thunkOn(
    (_, storeActions) => storeActions.watchlist.setWatchlist,
    (actions, target) => {
      const assets = target.payload.statuses
        .filter((status) => !!status.asset)
        .map((status) => status.asset);
      actions.updateAssets(assets);
    },
  ),

  onFetchSiteAssetsWithMetrics: thunkOn(
    (_, storeActions) => storeActions.sites.fetchSiteAssetsWithMetrics,
    (actions, target) => {
      const { assets } = target.result;
      actions.updateAssets(assets);
    },
  ),
};

// Computed values
const assetsComputed = {
  getSortedAssets: computed(
    [(state) => state, (state, storeState) => storeState.view.currentView],
    (state, view) =>
      memoize(10)((sortMetric, sortDirection, siteId) => {
        let assetArray = siteId ? state.getAssetsBySiteId(siteId) : Object.values(state.assets);

        // Apply view filter if one is provided.
        if (view) {
          assetArray = filterByView(assetArray, view);
        }

        return assetArray.sort(sorter(sortMetric, sortDirection));
      }),
  ),

  allAssets: computed(
    [(state) => state, (_, storeState) => storeState.view.currentView],
    (state, view) => {
      const assets = Object.values(state.assets);

      return view ? filterByView(assets, view) : assets;
    },
  ),

  getFeatureAssets: computed(
    [(state) => state.assets, (_, storeState) => storeState.view],
    (assetsById, view = {}) =>
      memoize(10)(() => {
        return getViewEntity(assetsById, view).sort((a, b) => String(a.name).localeCompare(b.name));
      }),
  ),

  getAssetById: computed((state) => (assetId) => state.assets[assetId]),

  assetsBySiteId: computed([(state) => state.assets], (assets) =>
    groupById(Object.values(assets), ['site', 'id']),
  ),

  getAssetsBySiteId: computed((state) =>
    memoize(5)((siteId) => {
      return state.assetsBySiteId[siteId];
    }),
  ),

  getAssetsBySiteIds: computed(
    [(state) => state, (_, storeState) => storeState.view],
    (state, view = {}) =>
      memoize(10)((siteIds) => {
        return getViewEntity(state.assets, view)
          .filter(
            ({ type, site }) => type === AssetType.WIND_TURBINE && siteIds?.includes(site?.id),
          )
          .sort((a, b) => a.name?.localeCompare(b.name));
      }),
  ),

  getAllAssetsBySiteIds: computed(
    [(state) => state, (_, storeState) => storeState.view],
    (state, view = {}) =>
      memoize(10)((siteIds) => {
        return getViewEntity(state.assets, view)
          .filter(({ site }) => siteIds?.includes(site?.id))
          .sort((a, b) => a.name?.localeCompare(b.name));
      }),
  ),

  getAssetsBySiteIdAndType: computed((state) => (siteId, assetType) => {
    const assets = Object.values(state.assets);

    return assets.filter(({ site, type }) => {
      if (assetType) {
        return site?.id === siteId && type === assetType;
      }
      return site?.id === siteId;
    });
  }),

  getAssetsBySiteAndState: computed([(state) => state.assets], (_assets) =>
    memoize(2)((siteId, aState) => {
      const assets = Object.values(_assets);
      const stateArr = arrayify(aState);

      return assets.filter((asset) => {
        if (!asset.metrics) {
          return false;
        }
        const isInSite = asset.site && asset.site.id === siteId;
        const isInState = stateArr.includes(asset.metrics.state);

        return isInSite && isInState;
      });
    }),
  ),

  getFilteredAssetsAndSites: computed(
    [
      (state) => state,
      (_, storeState) => storeState.sites.sites,
      (_, storeState) => storeState.view,
    ],
    (state, sitesById, view = {}) =>
      memoize(10)((filterText, assetType) => {
        const assetArray = getViewEntity(state.assets, view).filter((asset) =>
          assetType ? asset.type === assetType : true,
        );
        if (!filterText || filterText.length < 2) {
          const sortedAssets = assetArray.sort((a, b) => a.name?.localeCompare(b.name));
          const sites = getViewEntity(sitesById, view).sort((a, b) =>
            a.name?.localeCompare(b.name),
          );

          return [sortedAssets, sites];
        }
        const sortedAssets = assetArray
          .filter((asset) => asset.name?.toLowerCase().includes(filterText.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name));
        const sites = [...new Set(sortedAssets.map((a) => a.site.id))]
          .map((id) => sitesById[id])
          .sort((a, b) => a.name?.localeCompare(b.name));
        return [sortedAssets, sites];
      }),
  ),

  getAssetDefs: computed(
    [(state) => state.assets, (_, storeState) => storeState.view],
    (assetsById, view) =>
      // can revisit but memoizing for each type
      memoize(ASSET_TYPE_COUNT)((assetType = AssetType.WIND_TURBINE) => {
        const defs = getViewEntity(assetsById, view).reduce(
          (defs, { controlCode, model, platform, type }) => {
            if (type !== assetType) {
              return defs;
            }

            controlCode && defs.controlCodes.add(controlCode);
            model && defs.models.add(model);
            platform && defs.platforms.add(platform);

            return defs;
          },
          {
            // TODO: add add'l defs as needed
            controlCodes: new Set(),
            models: new Set(),
            platforms: new Set(),
          },
        );

        return Object.entries(defs).reduce(
          (_defs, [key, value]) => ({
            ..._defs,
            [key]: Array.from(value).sort((a, b) =>
              a.localeCompare(b, undefined, { numeric: true }),
            ),
          }),
          {},
        );
      }),
  ),
};

const assetModel = {
  ...defaultAssetsState,
  ...assetsActions,
  ...assetsComputed,
  ...assetsListeners,
};

export default assetModel;
