import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icons } from '@ge/components/icon';

import { BundleBadge, BundleIcon, TaskTypeIcon } from './calendar-card-style';

export const BundledCountBadge = ({ className, bundlePresent, bundleCount }) => {
  return (
    bundlePresent && (
      <BundleBadge className={className}>
        <BundleIcon icon={Icons.BUNDLE} />
        <div className="bundle-count">{bundleCount}</div>
      </BundleBadge>
    )
  );
};

BundledCountBadge.propTypes = {
  className: PropTypes.string,
  bundleCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bundlePresent: PropTypes.bool,
};

export const TaskCardEntity = ({ className, task, sameAssetTaskCount }) => {
  if (!task) return null;

  return (
    <div className={className}>
      <TaskTypeIcon icon={task?.asset?.id ? Icons.TURBINE : Icons.SITE} status={task?.status} />
      {task?.asset?.id ? task?.asset?.name : task?.site?.name}
      {sameAssetTaskCount > 0 && <div className="task-count">{sameAssetTaskCount}</div>}
    </div>
  );
};

TaskCardEntity.propTypes = {
  className: PropTypes.string,
  task: PropTypes.object,
  sameAssetTaskCount: PropTypes.string,
};

TaskCardEntity.defaultProps = {
  className: 'content',
};

export const TaskCardInfo = ({ task, sameAssetTaskCount }) => {
  const { t, ready } = useTranslation(['tasks', 'general']);

  if (!ready || !task) return null;

  return (
    <>
      <div className="title">{task.title}</div>
      <div className="type-container">
        <div className="type">{t(`tasks:dynamic.types.${task.source}`, task.source)}</div>
        {sameAssetTaskCount !== 0 && (
          <div className="task-count">
            <b>{sameAssetTaskCount}</b>&nbsp;
            {t('general:task_plural', 'Tasks')}
          </div>
        )}
      </div>
    </>
  );
};

TaskCardInfo.propTypes = {
  task: PropTypes.object,
  sameAssetTaskCount: PropTypes.string,
};
