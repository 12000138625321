import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectMetaField } from '@ge/shared/components/meta-fields/select-meta-field';

import { useComponentHierarchy } from '../../../data-hooks';

const FormSchema = {
  component1: 'component1',
  component2: 'component2',
  component3: 'component3',
};

export const AssetComponents = ({ assetIds, className, width, task, requiredL1 }) => {
  const { t, ready } = useTranslation(['tasks'], { useSuspense: false });

  const { watch } = useFormContext();
  const component1Value = watch(FormSchema.component1, false);
  const component2Value = watch(FormSchema.component2, false);

  const { data: componentHierarchy } = useComponentHierarchy(assetIds);

  const component1Options = useMemo(
    () => componentHierarchy?.map((c) => ({ value: c.name, label: c.name })) ?? [],
    [componentHierarchy],
  );

  const component2Options = useMemo(
    () =>
      componentHierarchy
        ?.find((c) => c.name === component1Value)
        ?.children?.map((child) => ({
          value: child.name,
          label: child.name,
        })) || [],
    [componentHierarchy, component1Value],
  );

  const component3Options = useMemo(
    () =>
      componentHierarchy
        ?.find((c) => c.name === component1Value)
        ?.children?.find((c) => c.name === component2Value)
        ?.children?.map((child) => ({
          value: child.name,
          label: child.name,
        })) ?? [],
    [componentHierarchy, component1Value, component2Value],
  );

  const meta = useMemo(
    () => ({
      [FormSchema.component1]: { required: !!requiredL1 },
      [FormSchema.component2]: {
        readOnly: !component1Value,
      },
      [FormSchema.component3]: {
        readOnly: !component1Value || !component2Value,
      },
    }),
    [requiredL1, component1Value, component2Value],
  );

  const { component1 = '', component2 = '', component3 = '' } = useMemo(() => task ?? {}, [task]);

  if (!ready) {
    return null;
  }

  return (
    <div className={className}>
      <SelectMetaField
        label={`${t('form.component', 'Component')} L1 ${requiredL1 ? '*' : ''}`}
        defaultValue={component1}
        metadata={meta[FormSchema.component1]}
        maxWidth={width}
        minWidth={width}
        name={FormSchema.component1}
        placeholder={`${t('component', 'Component')} 1`}
        options={component1Options}
      />
      <SelectMetaField
        label={`${t('form.component', 'Component')} L2`}
        defaultValue={component2}
        metadata={meta[FormSchema.component2]}
        maxWidth={width}
        minWidth={width}
        name={FormSchema.component2}
        placeholder={`${t('component', 'Component')} 2`}
        options={component2Options}
      />
      <SelectMetaField
        label={`${t('form.component', 'Component')} L3`}
        defaultValue={component3}
        metadata={meta[FormSchema.component3]}
        maxWidth={width}
        minWidth={width}
        name={FormSchema.component3}
        placeholder={`${t('component', 'Component')} 3`}
        options={component3Options}
      />
    </div>
  );
};

AssetComponents.propTypes = {
  assetIds: PropTypes.array,
  className: PropTypes.string,
  width: PropTypes.number,
  reset: PropTypes.bool,
  task: PropTypes.instanceOf(Object),
  requiredL1: PropTypes.bool,
};

AssetComponents.defaultProps = {
  assetIds: [],
  className: null,
  width: null,
  reset: false,
  task: null,
  requiredL1: false,
};
