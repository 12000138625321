import { TableFilterTypes } from '@ge/models/constants';
export const AlertHistoryColumns = {
  // Alert Type
  GROUP_ALERT_TYPE: 'group-alert-type',
  ALERT_TYPE: 'type',

  // Description
  GROUP_DESCRIPTION: 'group-description',
  DESCRIPTION: 'typeProperties',

  // Open Date
  GROUP_OPEN_DATE: 'group-open-date',
  OPEN_DATE: 'createdDate',
  // Closed Date
  GROUP_CLOSED_DATE: 'group-closed-date',
  CLOSED_DATE: 'closedDate',

  // Aletr Icon
  GROUP_ALERT_ICON: 'group-alert-icon',
  ALERT_ICON: 'alert-icon',
};

// Define all available columns in the alert history table.
export const AlertHistoryColumnDefs = {
  [AlertHistoryColumns.GROUP_ALERT_TYPE]: {
    align: 'left',
    cols: {
      [AlertHistoryColumns.ALERT_TYPE]: {
        minWidth: '140px',
        labels: [
          {
            a11yKey: 'alertHistory.alert_type',
            a11yDefault: 'Alert Type',
            sortValue: 'type',
          },
        ],
        filterType: TableFilterTypes.CHECKBOXES,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [AlertHistoryColumns.GROUP_DESCRIPTION]: {
    align: 'left',
    cols: {
      [AlertHistoryColumns.DESCRIPTION]: {
        minWidth: '140px',
        labels: [
          {
            a11yKey: 'alertHistory.alert_description',
            a11yDefault: 'Description',
            sortValue: 'typeProperties.description',
          },
        ],
        filterType: TableFilterTypes.SEARCH,
        cell: {
          align: 'left',
        },
      },
    },
  },
  [AlertHistoryColumns.GROUP_OPEN_DATE]: {
    align: 'left',
    cols: {
      [AlertHistoryColumns.OPEN_DATE]: {
        minWidth: '50px',
        labels: [
          {
            a11yKey: 'alertHistory.alert_openDate',
            a11yDefault: 'Open Date',
            sortValue: 'auditProperties.createdDate',
          },
        ],
      },
    },
  },
  [AlertHistoryColumns.GROUP_CLOSED_DATE]: {
    align: 'left',
    cols: {
      [AlertHistoryColumns.CLOSED_DATE]: {
        minWidth: '50px',
        labels: [
          {
            a11yKey: 'alertHistory.alert_closedDate',
            a11yDefault: 'Closed Date',
            sortValue: 'auditProperties.closedDate',
          },
        ],
      },
    },
  },
  [AlertHistoryColumns.GROUP_ALERT_ICON]: {
    align: 'left',
    cols: {
      [AlertHistoryColumns.ALERT_ICON]: {
        minWidth: '30px',
        labels: [
          {
            a11yKey: 'alertHistory.alert_icon',
            a11yDefault: 'Alert',
          },
        ],
      },
    },
  },
};

// Define the default visible columns and their order.
// NOTE: Defining this in one place because currently all
// implementations of the case history table have the same
// order. When presentations diverge, move this definition
// closer to the implementation and modify accordingly.
export const defaultAlertHistoryCols = [
  {
    id: AlertHistoryColumns.GROUP_ALERT_TYPE,
    cols: [
      {
        id: AlertHistoryColumns.ALERT_TYPE,
        visible: true,
        filter: 'alertHistory',
      },
    ],
  },
  {
    id: AlertHistoryColumns.GROUP_DESCRIPTION,
    cols: [
      {
        id: AlertHistoryColumns.DESCRIPTION,
        visible: true,
        filter: 'alertHistory',
      },
    ],
  },
  {
    id: AlertHistoryColumns.GROUP_OPEN_DATE,
    cols: [
      {
        id: AlertHistoryColumns.OPEN_DATE,
        visible: true,
        filter: 'alertHistory',
      },
    ],
  },
  {
    id: AlertHistoryColumns.GROUP_CLOSED_DATE,
    cols: [
      {
        id: AlertHistoryColumns.CLOSED_DATE,
        visible: true,
        filter: 'alertHistory',
      },
    ],
  },
  {
    id: AlertHistoryColumns.GROUP_ALERT_ICON,
    cols: [
      {
        id: AlertHistoryColumns.ALERT_ICON,
        visible: true,
        filter: 'alertHistory',
      },
    ],
  },
];
