import { PropTypes } from 'prop-types';
import React, { useContext, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { Module } from '@ge/feat-reporting/components/sidebar/modules/module';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  color: ${({ theme }) => theme.createReport.headerTextColor};
`;

const ResetButtonContainer = styled.div`
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 26px;
`;

const ResetButtonLabel = styled.span`
  color: ${({ theme }) => theme.createReport.sidebar.moduleHeaderIconColor};
  font-weight: 700;
  margin-left: 5px;
  text-transform: uppercase;
`;

const ResetIcon = styled(Icon).attrs(({ theme }) => ({
  size: 10,
  icon: Icons.CLOSE,
  color: theme.createReport.sidebar.moduleHeaderIconColor,
  viewbox: '0 0 20 20',
}))`
  display: inline-block;
  position: relative;
`;

export const ReportModules = ({ data }) => {
  const { t, ready } = useTranslation(['reporting.sidebar']);

  const [expandedModule, setExpandedModule] = useState('');
  const { hiddenWidgets, setWidgetVisibility, resetWidgetState } = useContext(ReportsContext);

  // Update reportState with a widget's hidden state.
  const handleToggleHidden = useCallback(
    ({ id }, hidden) => {
      setWidgetVisibility(id, hidden);
    },
    [setWidgetVisibility],
  );

  const handleToggleExpand = useCallback(
    ({ id }) => {
      setExpandedModule(id);
    },
    [setExpandedModule],
  );

  const modules = useMemo(() => {
    const modulesData = data && Object.entries(data);

    return modulesData?.map(([rowKey, modules]) => {
      return modules.map((module) => {
        const { id, name, configuration, visualizationType } = module ?? {};

        return (
          <Module
            id={id}
            key={id}
            name={name}
            configuration={configuration}
            visualizationType={visualizationType}
            expanded={expandedModule === id}
            rowKey={rowKey}
            onToggleExpand={handleToggleExpand}
            hidden={hiddenWidgets.has(id)}
            onToggleHidden={() => handleToggleHidden(module, !hiddenWidgets.has(id))}
          />
        );
      });
    });
  }, [data, expandedModule, hiddenWidgets, handleToggleExpand, handleToggleHidden]);

  if (!ready) {
    return;
  }

  return (
    <>
      <TitleContainer>
        <h2>Report Modules</h2>
        <ResetButtonContainer onClick={resetWidgetState}>
          <ResetIcon />
          <ResetButtonLabel>{t('restore_defaults', 'Restore Defaults')}</ResetButtonLabel>
        </ResetButtonContainer>
      </TitleContainer>
      {modules}
    </>
  );
};

ReportModules.propTypes = {
  data: PropTypes.instanceOf(Object),
};
