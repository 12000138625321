import { useStoreState } from 'easy-peasy';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';

import { fetchAssetHistory } from '../services/commands';

export const useAssetCommandsHistory = (asset, isActive = true) => {
  const getAssetById = useStoreState((store) => store.assets.getAssetById);
  const { data, error, isLoading } = useQuery(
    [QueryKey.ASSET_COMMAND_HISTORY, asset?.site?.id, asset?.id],
    // fetching empty history response for now from BFF, will revisit BFF later
    () => {
      const assetData = getAssetById(asset?.id);
      return fetchAssetHistory(
        asset?.site?.id,
        asset?.id,
        assetData?.make,
        assetData?.controllerType,
        assetData?.platform,
      );
    },
    {
      enabled: isActive,
    },
  );
  if (isLoading || error) return { data: {}, isLoading };
  return { data, error, isLoading };
};
