import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { getCaseProcedureData } from '@ge/shared/services/asset';
import { sorter } from '@ge/util/metric-sorter';

export const useGetCaseProcedure = ({ assetId, eventCode, sortDirection, sortMetric }) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.CASE_PROCEDURE, assetId, eventCode],
    () => getCaseProcedureData(assetId, eventCode),
    {
      ...Config.EXECUTE_ONCE,
    },
  );

  return useMemo(() => {
    return {
      data: {
        ...data,
        associatedEvents: data?.associatedEvents?.length
          ? data?.associatedEvents.sort(sorter(sortMetric, sortDirection))
          : [],
      },
      error,
      isLoading,
    };
  }, [data, error, isLoading, sortDirection, sortMetric]);
};
