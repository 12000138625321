import { mergeOptionsRight } from '@ge/util';

import { KpiCategorySeriesType } from '.';

const CHART_HEIGHT = 350;

// get options for entity series charts
const getEntityChartOptions = () => {
  const tooltipPointFormatter = ({ color, x, y, value, series }) => {
    let tooltip = `
  <table  class"historical-chart-tooltip">
    <tr> <td colspan="5" class="historical-chart-nowrap">${series.name}</td></tr>
    <tr><td colspan="5" class="historical-chart-nowrap"><hr size="1" class="historical-chart-tooltip-hr"></td></tr>`;

    tooltip += `<tr><td colspan="5" class="historical-chart-nowrap">${y}</td></tr>`;

    tooltip +=
      '<tr><td colspan="5" class="historical-chart-nowrap"><hr size="1" class="historical-chart-tooltip-hr"></td></tr>';
    tooltip += `<tr>
      <td class="historical-chart-nowrap">
      <div style="background-color: ${color}; height: 3px; width: 15px;"></div></td>
      <td class="historical-chart-nowrap">${x} </td>
      <td class="historical-chart-nowrap"> : </td>
      <td class="historical-chart-nowrap tooltip-number"> ${value === null ? '-' : value} </td>
      <td class="historical-chart-nowrap"> % </td>
    </tr>`;
    tooltip += `</table>`;
    return tooltip;
  };
  const series = [
    {
      borderWidth: 1,
      data: [
        [0, 0, 10],
        [0, 1, 19],
        [0, 2, 8],
        [0, 3, 24],
        [0, 4, 67],
        [1, 0, 92],
        [1, 1, 58],
        [1, 2, 78],
        [1, 3, 100],
        [1, 4, 48],
        [2, 0, 35],
        [2, 1, 15],
        [2, 2, 100],
        [2, 3, 64],
        [2, 4, 52],
        [3, 0, 72],
        [3, 1, 100],
        [3, 2, 100],
        [3, 3, 19],
        [3, 4, 16],
        [4, 0, 38],
        [4, 1, 5],
        [4, 2, 8],
        [4, 3, 100],
        [4, 4, 100],
        [5, 0, 88],
        [5, 1, 32],
        [5, 2, 12],
        [5, 3, 6],
        [5, 4, 100],
        [6, 0, 13],
        [6, 1, 44],
        [6, 2, 88],
        [6, 3, 98],
        [6, 4, 96],
        [7, 0, 31],
        [7, 1, 1],
        [7, 2, 82],
        [7, 3, 32],
        [7, 4, 30],
        [8, 0, 85],
        [8, 1, 97],
        [8, 2, 100],
        [8, 3, 64],
        [8, 4, 84],
        [9, 0, 47],
        [9, 1, 100],
        [9, 2, 31],
        [9, 3, 48],
        [9, 4, 91],
      ],
      tooltipPointFormatter,
    },
  ];

  return {
    series,
  };
};

const getInnerChartHeight = (height, innerHeightPercent) => {
  return height * innerHeightPercent;
};

// define plot options by data type
const getPlotOptions = ({ height, innerHeightPercent }) => {
  const plotOptions = {
    height: height ? getInnerChartHeight(height, innerHeightPercent) : CHART_HEIGHT,
  };

  return plotOptions;
};

export const mapChartOptionsFactory = ({
  height,
  innerHeightPercent = 0.63,
  seriesType,
  theme,
}) => {
  const getOptions = {
    [KpiCategorySeriesType.ENTITY]: getEntityChartOptions,
  }[seriesType];

  // get base options then massage based on chart type
  const chartOptions = getOptions({
    theme,
  });

  return mergeOptionsRight(getPlotOptions({ height, innerHeightPercent }), chartOptions);
};
