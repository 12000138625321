import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ToggleButton } from '@ge/components/toggle-button';

export const ToggleButtonMetaField = ({ defaultValue, label, metadata, name, className }) => {
  const { control } = useFormContext();

  const { readOnly, required } = metadata;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required,
      }}
      render={({ value, onChange }) => (
        <ToggleButton
          label={label}
          isToggleOn={value}
          handleOnClick={() => onChange(!value)}
          disabled={readOnly}
          className={className}
        />
      )}
    />
  );
};

ToggleButtonMetaField.propTypes = {
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  metadata: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ToggleButtonMetaField.defaultProps = {
  defaultValue: false,
  label: '',
};
