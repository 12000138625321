import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Tab, Tabs } from '@ge/components/tabs';
import useAssetCaseMetrics from '@ge/feat-analyze/data-hooks/use-asset-case-metrics';
import { EntityType, EntityTab, EntityMode } from '@ge/models/constants';
import CaseFPSensorReadings from '@ge/shared/components/cases/case-sensor-readings';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import useCaseDetailDataByQuery from '@ge/shared/data-hooks/cases/use-case-detail-data-by-query';
import { useCloseCase } from '@ge/shared/data-hooks/use-update-case';

import { DetailContainer, SectionContainer } from '../entity-details-shared';

import { CaseDetailsHeader } from './case-details-header';
// import { CaseHistory } from './case-history';
import { CaseNotesAttachments } from './case-notes-attachments';
import { CaseOverview } from './case-overview';
import { CaseResolution } from './case-resolution';
import { CaseSensorReadings } from './case-sensor-readings';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

const CaseDetails = ({ caseId, showNewTaskDialog, setShowNewTaskDialog, handleNewTask }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const [alert, setAlert] = useState(null);

  const { entityTab, entityMode, showCaseDetails } = useContext(EntityDetailsContext);

  let { caseWithDetails, isLoading: isCaseDetailsLoading } = useCaseDetailDataByQuery({
    caseId,
  });
  const assetId = caseWithDetails?.asset?.id;
  const { data: assetMetricsData, isLoading: isAssetMetricsLoading } = useAssetCaseMetrics(assetId);

  const { mutate: closeCase } = useCloseCase();

  const { search } = useLocation();
  const enabledFpData = new URLSearchParams(search).get('fp');

  useEffect(() => {
    setAlert(caseWithDetails?.alerts[0]);
  }, [caseWithDetails]);

  const handleEntityTab = useCallback(
    (e) => {
      showCaseDetails(caseWithDetails.id, e);
    },
    [caseWithDetails, showCaseDetails],
  );

  const handleDetailClick = useCallback(
    (e) => {
      setAlert(e);
      showCaseDetails(caseWithDetails.id, EntityTab.SENSOR_READINGS);
    },
    [caseWithDetails, showCaseDetails],
  );

  const handleCloseCase = useCallback(() => {
    if (caseWithDetails?.disposition && caseWithDetails?.rootCause) {
      closeCase(caseWithDetails);
    } else {
      showCaseDetails(caseWithDetails.id, EntityTab.RESOLUTION, EntityMode.EDIT);
    }
  }, [caseWithDetails, closeCase, showCaseDetails]);

  if (!ready || isAssetMetricsLoading) {
    return <Loader />;
  }

  return (
    <DetailContainer>
      {caseWithDetails && (
        <CaseDetailsHeader
          caseDetail={caseWithDetails}
          entityType={EntityType.CASE}
          assetMetrics={assetMetricsData}
          handleCloseCase={handleCloseCase}
          assetId={assetId}
          showNewTaskDialog={showNewTaskDialog}
          setShowNewTaskDialog={setShowNewTaskDialog}
          handleNewTask={handleNewTask}
        />
      )}
      <ScrollingContainer autoHide={false}>
        {caseWithDetails && (
          <SectionContainer>
            <StyledTabs small defaultTab={entityTab} onChangeHandler={handleEntityTab}>
              <Tab label={t('tabs.overview', 'Overview')}>
                <CaseOverview
                  caseDetail={caseWithDetails}
                  handleDetailClick={handleDetailClick}
                  isCaseDetailsLoading={isCaseDetailsLoading}
                />
              </Tab>
              {/* <Tab label={t('tabs.case_history', 'Case History')}>
                <CaseHistory issue={caseWithDetails} />
              </Tab> */}
              <Tab label={t('tabs.sensor_readings', 'Sensor Readings')}>
                {enabledFpData ? (
                  <CaseFPSensorReadings issue={caseWithDetails} alert={alert} />
                ) : (
                  <CaseSensorReadings issue={caseWithDetails} alert={alert} />
                )}
              </Tab>
              <Tab label={t('tabs.notes', 'Notes')}>
                <CaseNotesAttachments issue={caseWithDetails} />
              </Tab>
              <Tab label={t('tabs.resolution', 'Resolution')}>
                <CaseResolution
                  issue={caseWithDetails}
                  openEditPanel={entityMode === EntityMode.EDIT}
                />
              </Tab>
            </StyledTabs>
          </SectionContainer>
        )}
      </ScrollingContainer>
    </DetailContainer>
  );
};

CaseDetails.propTypes = {
  caseId: PropTypes.string,
  showNewTaskDialog: PropTypes.bool,
  setShowNewTaskDialog: PropTypes.any,
  handleNewTask: PropTypes.any,
};

CaseDetails.defaultProps = {
  caseId: '',
  showNewTaskDialog: false,
  setShowNewTaskDialog: () => {},
  handleNewTask: () => {},
};

export default CaseDetails;
