import { StoreProvider } from 'easy-peasy';
import React from 'react';
import ReactDOM from 'react-dom';

import 'wicg-inert';
import { registerMocks as registerAnalyzeMocks } from '@ge/feat-analyze/services';
import {
  registerMocks as registerManageMocks,
  forceMocks as forceManageMocks,
} from '@ge/feat-manage/services';
import {
  registerMocks as registerMonitorMocks,
  forceMocks as forceMonitorMocks,
} from '@ge/feat-monitor/services';
import { registerMocks as registerSharedMocks } from '@ge/shared/services/__mocks__';
import {
  getOidcInfo,
  initOidc,
  initOidcForSelectedIdp,
  isOfflineMode,
} from '@ge/shared/services/auth';

import App from './app/app';
import './i18n';
import registerAppMocks from './app/services/__mocks__';
import * as serviceWorker from './serviceWorker';
import store from './store';

const init = async () => {
  let oidcConfig = await getOidcInfo();
  const { baseUrl, clientId, clientChallenge: clientSecret, idpName, multiTenant } = oidcConfig;
  //Same oidc in network, prep below
  if (!multiTenant?.length) {
    initOidc({ baseUrl, clientId, clientSecret, idpName, isMutiTenant: false });
  } else {
    //multitenancy set oidc config for selected idp if already exists
    initOidcForSelectedIdp();
  }
};

const renderApp = async () => {
  ReactDOM.render(
    <StoreProvider store={store}>
      <App />
    </StoreProvider>,
    document.getElementById('app-root'),
  );
};
if (isOfflineMode) {
  Promise.all([
    registerAppMocks(),
    registerAnalyzeMocks(),
    registerMonitorMocks(),
    registerManageMocks(),
    registerSharedMocks(),
  ])
    .then(renderApp)
    // eslint-disable-next-line no-console
    .catch((error) => console.error('Error bootstrapping app', error));
} else {
  // TODO - forceSharedMocks takes almost 3 seconds before rendering anything.
  // I think might cause the auth issue on redirect from Amplify
  Promise.all([init(), forceMonitorMocks(), forceManageMocks()])
    .then(() => renderApp())
    // eslint-disable-next-line no-console
    .catch((error) => console.error('Error bootstrapping app', error));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
