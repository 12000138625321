import * as request from './api';

export const fetchAllHandlingProcedure = (id) => {
  return request.get(`/cmn/handling-procedure/${id}`);
};

export const getHandlingProcedure = ({ params }) => {
  return request.get(`/cmn/handling-procedure`, {
    params,
  });
};

export const deleteHandlingProcedure = (id) => {
  return request.deleteItem(`/cmn/handling-procedure/${id}`);
};

export const getEventMaps = () => {
  return request.get(`/cmn/event-maps`);
};

export const getEventDesc = (id) => {
  return request.get(`/cmn/event/search`, {
    params: { query: `eventId=in=(${id})` },
  });
};

export const createHandlingProcedure = (data) => {
  return request.post('/cmn/handling-procedure', data);
};

export const updateHandlingProcedure = (data) => {
  return request.put('/cmn/handling-procedure', data);
};
