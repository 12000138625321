import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import equals from 'ramda/src/equals';
import path from 'ramda/src/path';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { Table, Tbody, Td, Thead, Tr } from '@ge/components/table';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import useStateRef from '@ge/hooks/state-ref';
import { CommonLocators } from '@ge/models/data-locators';

import { CreateModes } from '../../models/modes';

/**
 * The table body needs to have cell truncation turned off
 * in order to enable variable height cells.
 */
const StyledTbody = styled(Tbody)`
  ${(props) => {
    if (props.variableHeight) {
      const valign = props.vAlign || 'top';
      return `
      ${Td} {
          overflow-wrap: break-word;
          vertical-align: ${valign};
          white-space: normal;
        }
      `;
    }
  }}

  &:before {
    display: block;
    padding-top: ${(props) => props.paddingTop}px;
    content: '';
  }

  &:after {
    display: block;
    padding-bottom: ${(props) => props.paddingBottom}px;
    content: '';
  }
`;

const StyledTr = styled(Tr)`
  display: ${(props) => props.hideRow && 'none'};

  ${(props) => {
    // Only override the row styling if the table is virualized ("scrollable")
    if (props.scrollable) {
      return `background: ${
        props.odd ? props.theme.table.evenRowColor : props.theme.table.oddRowColor
      } !important;
      `;
    }
  }}
`;

/**
 * Custom table implementation to support config-driven, non-virtualized,
 * variable height row tables with non-standard theme styling. This is a
 * unique scenario to reporting widgets, so the functionality is not being
 * added to the globally consumed table component.
 */
export const ReportWidgetTable = ({
  scrollable,
  compressed,
  noTitles,
  columnGroupFactory,
  columnFactory,
  cellFactory,
  columns,
  values = [],
  onValueSelect,
  rowKeyProperty,
  className,
  isEditing,
  // data loader props (TODO: Are all of these valuable?)
  isLoading,
  noData,
  noDataDescription,
  noDataTitle,
  onRetry,
  renderCondition,
  rowsSelected,
  variableHeight,
  vAlign,
}) => {
  const parentRef = useRef();

  const { getThemeJs } = useStoreState((state) => state.prefs);
  const { createMode } = useContext(ReportsContext);

  // Internal state used to manage JSX components built from configuration.
  const [colGroups, setColGroups, colGroupsRef] = useStateRef(null);
  const [cols, setCols, colsRef] = useStateRef(null);

  // storing editing state for virtualization measurement purposes
  const [editingChange, setEditingChange] = useState(isEditing);

  /**
   * Filter an array of columns based on visibility.
   */
  const getVisibleColumns = useCallback(
    (someColumns, excludeMarkupOnly) =>
      someColumns.filter((col) =>
        excludeMarkupOnly ? col.visible && !col.markupOnly : col.visible,
      ),
    [],
  );

  /**
   * Memoize the currently visible columns.
   */
  const visibleCols = useMemo(() => {
    if (!columns.length) {
      return [];
    }

    return columns.reduce((idArray, columnGroup) => {
      const visibleColIds = getVisibleColumns(columnGroup.cols).map((col) => col.id);
      idArray.push({ cols: [...visibleColIds], groupKey: columnGroup.id });
      return idArray;
    }, []);
  }, [columns, getVisibleColumns]);

  // Build renderable table header components (column groups and columns) and reference
  // the computed values until something changes that affects the computation.
  useEffect(() => {
    const { builtGroups, builtCols } = columns.reduce(
      (colMap, columnGroup) => {
        const colKeys = getVisibleColumns(columnGroup.cols, true).map((col) => col.id);

        colMap.builtGroups = [...colMap.builtGroups, columnGroupFactory(columnGroup.id, colKeys)];
        colMap.builtCols = [
          ...colMap.builtCols,
          ...colKeys.map((columnKey, idx, arr) =>
            columnFactory(columnGroup.id, columnKey, idx, arr.length),
          ),
        ];

        return colMap;
      },
      { builtGroups: [], builtCols: [] },
    );

    // Update state if the computed values have changed.
    if (!equals(colGroupsRef, builtGroups)) {
      setColGroups(builtGroups);
    }
    if (!equals(colsRef, builtCols)) {
      setCols(builtCols);
    }
  }, [
    columns,
    columnGroupFactory,
    columnFactory,
    colGroupsRef,
    colsRef,
    setColGroups,
    setCols,
    getVisibleColumns,
  ]);

  const rowVirtualizer = useVirtual({
    size: values?.length,
    parentRef,
    estimateSize: useCallback(() => 100, []),
    overscan: 20,
  });

  const getRowDefs = useCallback(
    (value) => {
      const factoryInstance = cellFactory(value);
      return {
        children: (
          <>
            {visibleCols?.map(({ cols, groupKey }) =>
              cols.map((colKey, idx, arr) => factoryInstance(groupKey, colKey, idx, arr.length)),
            )}
          </>
        ),
        key: Array.isArray(rowKeyProperty) ? path(rowKeyProperty, value) : value[rowKeyProperty],
        value,
        onClick: (e) => onValueSelect(e, value),
      };
    },
    [cellFactory, rowKeyProperty, visibleCols, onValueSelect],
  );

  // Override tbody theme styles locally
  const overrideTheme = createMode === CreateModes.PREVIEW && 'light';
  const localTheme = getThemeJs(overrideTheme);
  const { evenRowColor, oddRowColor, backgroundColor } = localTheme.createReport.widget.table;
  const localTableTheme = {
    evenRowColor,
    oddRowColor,
  };
  localTheme.table = { ...localTheme.table, ...localTableTheme };

  const buildRow = useCallback(
    ({ index, measureRef }, idx) => {
      // Handle both virtualized and non-virtualized scenarios. If the table is not
      // scrollable, we cannot virtualize the rows because they are all shown.
      const value = scrollable ? values[index] : values[idx];

      if (!value) {
        return null;
      }

      const { children, key, onClick } = getRowDefs(value);

      // check if the provided list of selected row Id's matches the current row.
      const isSelected = rowsSelected?.includes(key);

      return (
        <StyledTr
          ref={measureRef}
          key={key}
          onClick={onClick}
          // selected={isSelected} // don't think the design for this table needs this styling
          hideRow={!isSelected && !isEditing}
          odd={!!(index % 2)}
          theme={localTheme}
          scrollable={scrollable}
        >
          {children}
        </StyledTr>
      );
    },
    [scrollable, values, getRowDefs, rowsSelected, isEditing, localTheme],
  );

  // need to re-measure the virtualization after an edit has happened
  useEffect(() => {
    // without this check, this will loop infinitely due to the rowVirtualizer dependency
    if (editingChange !== isEditing) {
      rowVirtualizer.measure();
      setEditingChange(isEditing);
    }
  }, [editingChange, isEditing, rowVirtualizer]);

  if (!visibleCols.length) {
    return null;
  }

  const virtualizerItems = scrollable ? rowVirtualizer.virtualItems : values;
  const paddingTop = virtualizerItems?.length > 0 && scrollable ? virtualizerItems[0].start : 0;
  const paddingBottom =
    virtualizerItems?.length > 0 && scrollable
      ? rowVirtualizer.totalSize - virtualizerItems[virtualizerItems.length - 1].end
      : 0;

  return (
    <Table
      scrollable={scrollable}
      ref={parentRef}
      compressed={compressed}
      className={className}
      isLoading={isLoading}
      noData={noData}
      noDataDescription={noDataDescription}
      noDataTitle={noDataTitle}
      onRetry={onRetry}
      type="table"
      renderCondition={renderCondition}
      backgroundColor={backgroundColor}
    >
      <Thead noTitles={noTitles}>
        {!noTitles && <Tr>{colGroups}</Tr>}
        <Tr data-testid={CommonLocators.COMMON_DYNAMIC_TABLE_HEADER_ROW}>{cols}</Tr>
      </Thead>
      <StyledTbody
        theme={localTheme}
        data-testid={CommonLocators.COMMON_DYNAMIC_TABLE_BODY}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        variableHeight={variableHeight}
        vAlign={vAlign}
      >
        {virtualizerItems?.map((value, idx) => buildRow(value, idx))}
      </StyledTbody>
    </Table>
  );
};

ReportWidgetTable.propTypes = {
  ...Table.propTypes,
  values: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  variableHeight: PropTypes.bool,
  vAlign: PropTypes.string,
};
