import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { Icon, Icons } from '@ge/components/icon';
import { SpinLoader } from '@ge/components/loader';
import { placements } from '@ge/components/menu';
import { TooltipCell } from '@ge/components/table/table';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { typography } from '@ge/tokens';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

import { WorkerDialog } from '../assigned-techs-section/worker-panel-dialog';

const TaskDetailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  border-top: ${(props) =>
    props.techType == 'completed'
      ? ''
      : `1px solid ${globalColors.slate1}` && props.techType == 'view'
      ? ''
      : `1px solid ${globalColors.slate1}`};
  border-bottom: ${(props) =>
    props.techType == 'completed'
      ? ''
      : `1px solid ${globalColors.slate1}` && props.techType == 'view'
      ? ''
      : `1px solid ${globalColors.slate1}`};
  width: 100%;
  .spinner {
    left: calc(30% - 30px);
    margin-top: 7px;
  }
  .details {
    background-color: ${(props) => (props.techType == 'view' ? '' : '#1C252D')};
    border: 1px solid #2a323b;
    margin: 2px;
    border-radius: 3px;
    width: 160px;
    color: ${(props) => props.theme.manage.tabs.techs.technician.textColor};
    .tech {
      display: flex;
      padding: 5px;
      width: 155px;
    }
    .nametitle {
      display: inline-block;
      margin-left: 10px;
      width: 100px;
    }
    .name {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
    .title {
      font-size: 10px;
      color: ${globalColors.slate5};
    }
    .nickname-pill {
      height: 15px;
      width: 30px;
      border-radius: 12px;
      background-color: #b6e1e9;
    }
    .nickname {
      height: 10px;
      color: #1c252d;
      font-size: 8px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: -0.5px;
      line-height: 16px;
      text-align: center;
    }
    &.striped {
      background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
      color: ${({ theme }) => theme.manage.cards.unscheduledTextColor};
      background: repeating-linear-gradient(
        145deg,
        #8694a1,
        #8694a1 0.3px,
        #5e6d7b 1px,
        #5e6d7b 6px
      );
    }
  }
`;

const AddTechButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-size: 11px;
  font-weight: ${typography.weight.medium};
  line-height: 13px;
  margin: 5px 4px 15px auto;
  text-transform: uppercase;
`;

const AddTechIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;
const StyledIcon = styled(Icon).attrs(({ size, theme }) => ({
  size: size || 9,
  color: theme.manage.taskItem.iconColor,
}))`
  margin: 0 3px;
  vertical-align: initial;
  &:hover {
    fill: ${globalColors.red1};
  }
`;
export const CompletedTechsSection = ({
  section,
  techType,
  modalType,
  workerState,
  setWorkerState,
  removeWorker,
  deleteWrkrIds,
  task,
  isLoadingMultiplePerson,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const [showWorkerDialog, setShowWorkerDialog] = useState(false);
  const { dirtyFields, setDirtyFields } = useContext(EntityDetailsContext);
  const [savedDirtyFields, setSavedDirtyFields] = useState(false);
  const closeWorkerModal = useCallback(() => {
    setShowWorkerDialog(false);
    setDirtyFields(savedDirtyFields);
  }, [savedDirtyFields, setDirtyFields, setShowWorkerDialog]);
  if (!section || section.hidden) {
    return null;
  }

  const formatWorkerTitle = (title) => {
    if (title?.includes('_')) {
      return title.split('_').join(' ');
    } else {
      return title;
    }
  };

  const AssignedTechs = ({ worker, removeWorker, deleteWrkrIds }) => {
    const filteredWorkers = workerState?.filter((w) => deleteWrkrIds?.includes(w?.username));
    const showFade =
      techType === 'completed' && filteredWorkers?.some((k) => k?.username === worker?.username);
    return (
      <div className={`details ${showFade ? 'striped' : ''}`}>
        <TooltipCell
          placement={placements.TOP}
          tooltip={`${worker?.firstName?.substring(0, 5)} ${worker?.lastName?.substring(0, 5)}`}
          zIndex={elevations.P101}
          customClass="tooltip-close-style"
        >
          <div className="tech">
            <div className="nickname-pill">
              <div className="nickname">
                {worker?.initials || worker?.firstName?.substring(0, 5)}
              </div>
            </div>
            <div className="nametitle">
              <div className="name">{`${worker?.firstName?.substring(
                0,
                5,
              )} ${worker?.lastName?.substring(0, 5)}`}</div>
              <div className="title">{formatWorkerTitle(worker?.title) || 'Worker'}</div>
            </div>
            {techType === 'completed' && (
              <div>
                <button onClick={() => removeWorker(worker?.username)} type="Button">
                  <input
                    type="hidden"
                    id={`${worker?.username}`}
                    name="userName"
                    value={`${worker?.username}`}
                  />
                  <TooltipCell
                    placement={placements.TOP}
                    tooltip={'Click to Unremove'}
                    zIndex={elevations.P101}
                    customClass="tooltip-close-style"
                  >
                    <StyledIcon icon={Icons.CLOSE} size={10} />
                  </TooltipCell>
                </button>
              </div>
            )}
          </div>
        </TooltipCell>
      </div>
    );
  };

  return (
    <>
      <TaskDetailWrapper techType={techType}>
        {isLoadingMultiplePerson ? (
          <SpinLoader className="spinner" />
        ) : workerState?.length > 0 ? (
          <>
            {workerState?.map((worker) => {
              return (
                <AssignedTechs
                  worker={worker}
                  setWorkerState={setWorkerState}
                  key={worker?.username}
                  task={task}
                  removeWorker={removeWorker}
                  deleteWrkrIds={deleteWrkrIds}
                />
              );
            })}
          </>
        ) : (
          <div>{t('form.no_techs_message', 'No Tech Assigned for this Task')}</div>
        )}
        {techType !== 'view' && (
          <ConditionalRender>
            <AddTechButton
              type="button"
              onClick={() => {
                setShowWorkerDialog(true);
                setSavedDirtyFields(dirtyFields);
                setDirtyFields(false);
              }}
            >
              <AddTechIcon />
              {t('form.add_worker', 'Add Worker')}
            </AddTechButton>
            <WorkerDialog
              siteID={task?.site?.id}
              workerState={workerState}
              modalType={modalType}
              setWorkerState={setWorkerState}
              isOpen={showWorkerDialog}
              onClose={closeWorkerModal}
            />
          </ConditionalRender>
        )}
      </TaskDetailWrapper>
    </>
  );
};

CompletedTechsSection.propTypes = {
  techType: PropTypes.string,
  modalType: PropTypes.string,
  section: PropTypes.object,
  task: PropTypes.instanceOf(Object),
  workerState: PropTypes.instanceOf(Object),
  isLoadingMultiplePerson: PropTypes.instanceOf(Boolean).isRequired,
  worker: PropTypes.object,
  setWorkerState: PropTypes.func,
  removeWorker: PropTypes.func,
  deleteWrkrIds: PropTypes.instanceOf(Object),
};
