import React from 'react';

// Create a React Context to share within the DynamicTable so all
// components rendering inside the table have access to required
// state and handler functions.
export const TableResizableContext = React.createContext();

// Custom hook to inject the TableResizableContext into components that will
// leverage it. This will ensure that resizable  versions of table components
// cannot be used outside the context of the ResizableTable.
export const useResizableContext = () => {
  const context = React.useContext(TableResizableContext);
  return context ? context : {};
};
