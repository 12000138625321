import { useStoreState } from 'easy-peasy';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AnalyzeGlobalFilterKeys, EntityType } from '@ge/models/constants';

import { OmittedPlatforms } from '../models';

import { useGlobalFilters } from './use-global-filters';

// counts on filter button are based on these groupings
const FILTER_GROUP_DEFS = {
  contractStatus: [
    AnalyzeGlobalFilterKeys.SERVICE_AGREEMENT,
    AnalyzeGlobalFilterKeys.OUT_OF_WARRANTY,
    AnalyzeGlobalFilterKeys.IN_WARRANTY,
    AnalyzeGlobalFilterKeys.CORE_FLEET,
    AnalyzeGlobalFilterKeys.EXCLUDE_3_MONTHS_PAST_COD,
    AnalyzeGlobalFilterKeys.NON_CORE_FLEET,
  ],
  controlCodes: [AnalyzeGlobalFilterKeys.CONTROL_CODES],
  modesl: [AnalyzeGlobalFilterKeys.MODELS],
  platforms: [AnalyzeGlobalFilterKeys.PLATFORMS],
  // scope: [
  //   AnalyzeGlobalFilterKeys.CORE_FLEET,
  //   AnalyzeGlobalFilterKeys.EXCLUDE_3_MONTHS_PAST_COD,
  //   AnalyzeGlobalFilterKeys.NON_CORE_FLEET,
  // ],
};

// filters are either bool flags or arrays, so we test explicitly for those values
// if we end up with flags that are true by default can revise this and just make sure not undefined
const isFilterActive = (value) => value === true || Boolean(value?.length);

const getFilterCount = (filters) =>
  Object.values(FILTER_GROUP_DEFS).reduce(
    (count, keys) => count + Number(keys.some((key) => isFilterActive(filters?.[key]))),
    0,
  );

function filterModelsAndPlatformsBasedOnSelection(assetsFromSitesInCurrentView, filters) {
  const filterHasPlatforms = filters?.platforms && filters?.platforms.length > 0;

  return assetsFromSitesInCurrentView.reduce(
    (accumulator, { model, platform }) => {
      if (OmittedPlatforms.includes(platform)) {
        return accumulator;
      }
      platform && accumulator.platforms.add(platform);

      if (filterHasPlatforms) {
        if (filters?.platforms?.includes(platform)) {
          model && accumulator.models.add(model);
        }
      } else {
        model && accumulator.models.add(model);
      }

      return accumulator;
    },
    {
      models: new Set(),
      platforms: new Set(),
    },
  );
}

/**
 *
 * @param entityType - Entity type of the page you are filtering by. e.g. Region page will have entity type REGION
 * @param additionalSiteIdsToFilterBy - Additional site ids to filter by.
 * @returns {{models: unknown[], setFilters: (value: unknown) => void, setFilterCount: (value: *) => void, state: *, filters: unknown, platforms: unknown[], filterCount: *, getFilterCount: (function(*): number)}}
 */
const useFilterPanel = ({ entityType, additionalSiteIdsToFilterBy }) => {
  const { region: regionId } = useParams();

  const state = useGlobalFilters();

  // state
  const [filters, setFilters] = useState(state);
  const [filterCount, setFilterCount] = useState(() => getFilterCount(state));

  // store
  const getAssetsBySiteIds = useStoreState((state) => state.assets.getAssetsBySiteIds);
  const sitesByRegion = useStoreState((state) => state.sites.sitesByRegion);

  const filterHasNoSites = !state?.siteIds || state?.siteIds.length === 0;

  // Get all sites based on entity
  const getSitesByEntityType = useMemo(() => {
    switch (entityType) {
      case EntityType.REGION:
        if (!regionId) return state.siteIds;
        return sitesByRegion.get(regionId)?.map((site) => site.id);
      default:
        return state.siteIds;
    }
  }, [entityType, regionId, sitesByRegion, state.siteIds]);

  // Filter sites based on selection in View Selector
  const sitesFilteredByCurrentViewFilter = useMemo(() => {
    if (filterHasNoSites) return getSitesByEntityType;

    return getSitesByEntityType?.filter((id) => {
      return state.siteIds?.includes(id);
    });
  }, [filterHasNoSites, getSitesByEntityType, state.siteIds]);

  // Filter sites based on the prop "additionalSiteIdsToFilterBy"
  const filteredSitesByParentSiteFilter = useMemo(() => {
    return sitesFilteredByCurrentViewFilter?.filter((id) => {
      if (Array.isArray(additionalSiteIdsToFilterBy)) {
        return additionalSiteIdsToFilterBy?.includes(id);
      }
      return true;
    });
  }, [additionalSiteIdsToFilterBy, sitesFilteredByCurrentViewFilter]);

  // After all filtering has been done, grab the assets from the siteIds
  const assetsFromSitesInCurrentView = useMemo(() => {
    return getAssetsBySiteIds(filteredSitesByParentSiteFilter);
  }, [filteredSitesByParentSiteFilter, getAssetsBySiteIds]);

  const { models, platforms } = filterModelsAndPlatformsBasedOnSelection(
    assetsFromSitesInCurrentView,
    filters,
  );

  return {
    models: Array.from(models).sort(),
    platforms: Array.from(platforms).sort(),
    state,
    filters,
    setFilters,
    filterCount,
    setFilterCount,
    getFilterCount,
  };
};

export default useFilterPanel;
