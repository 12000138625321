import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';

import { useReportState } from '@ge/feat-reporting/hooks/use-report-state';

import { ReportsContext } from './reports-context';

export const ReportsProvider = ({ children }) => {
  const reportStateValue = useReportState();

  const providerValue = {
    ...reportStateValue,
  };

  return <ReportsContext.Provider value={providerValue}>{children}</ReportsContext.Provider>;
};

ReportsProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
};

ReportsProvider.defaultProps = {
  children: null,
};

export const useReportsContext = () => useContext(ReportsContext);
