import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { HighSpeedPinionContent } from './content/high-speed-pinion';

const StyledMetricsPanel = styled.section`
  width: calc(100% - 30%);
  margin: 0 25px 25px 60px;
`;

export const MetricsPanel = ({ onChevronClick, component }) => (
  <StyledMetricsPanel>
    <HighSpeedPinionContent component={component} onChevronClick={onChevronClick} />
  </StyledMetricsPanel>
);

MetricsPanel.propTypes = {
  onChevronClick: PropTypes.func,
  component: PropTypes.instanceOf(Object),
};

MetricsPanel.defaultProps = {
  onChevronClick: () => null,
  component: null,
};
