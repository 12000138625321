import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { fetchCasesForAssetIdsAndStatus } from '@ge/feat-monitor/services';
import {
  Capability,
  PermissionScope,
  QueryKey,
  AnomaliesTableCaseStatus,
} from '@ge/models/constants';
import { useCaseDetailQuery } from '@ge/shared/data-hooks';
import { Config } from '@ge/shared/data-hooks';
import { removeFalsyVals } from '@ge/util/array-utils';

import { useAuth } from '../../data-hooks';
import { useCaseFusion } from '../../data-hooks/use-case-fusion';
import { useCasesTasks } from '../use-linked-task-menu';

const CaseLinkContext = createContext();

// eslint-disable-next-line react/display-name
export const CaseLinkProvider = React.memo(({ isOpen, entity, onClose, onSave, children }) => {
  const [selectedChildCaseIds, setSelectedCases] = useState([]);
  const [selectedParentCaseId, setSelectedParent] = useState(null);
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState('cases');
  const { cases: allParentCases } = useCaseDetailQuery(selectedParentCaseId, true);
  const status = AnomaliesTableCaseStatus.join(',');
  const includeChildCases = true;
  const { data: allChildCases } = useQuery(
    [QueryKey.ASSETS_CASES, entity?.asset?.id, includeChildCases],
    () => fetchCasesForAssetIdsAndStatus([entity?.asset?.id], status, includeChildCases),
    {
      enabled: Boolean(selectedChildCaseIds.length),
      ...Config.EXECUTE_ONCE,
    },
  );

  useEffect(() => {
    setSelectedParent(null);
    setSelectedCases([]);
  }, [isOpen]);

  const {
    isLoading: isCasesLoading,
    cases,
    fuse,
  } = useCaseFusion({
    assetId: entity?.asset?.id,
    caseId: entity?.id,
    isActive: isOpen,
  });

  const { parentCase, allSelectedCases } = useMemo(() => {
    const selectedParentCase = cases.find((_case) => _case.id === selectedParentCaseId);
    const parentChildCases = removeFalsyVals(
      selectedParentCase?.childCaseIds?.map((caseId) => allParentCases && allParentCases[caseId]) ||
        [],
    );
    if (parentChildCases.length) {
      selectedParentCase.childCases = parentChildCases;
    }
    const selectedChildCases = cases.filter((_case) => selectedChildCaseIds.includes(_case.id));
    const childData = [];
    // eslint-disable-next-line no-unused-vars
    const childCases = removeFalsyVals(
      selectedChildCases?.map((childCase) => {
        childCase?.childCaseIds?.map((id) => {
          childData.push(allChildCases?.data?.find((_case) => _case.id === id));
        });
        return childData;
      }),
    );
    if (childData.length) {
      selectedChildCases.forEach((selectedChildCase) => {
        childData.forEach((childCase) => {
          if (selectedChildCase?.id === childCase?.parentId) {
            selectedChildCase.childCases = childData;
          }
        });
      });
    }
    return {
      parentCase: selectedParentCase,
      allSelectedCases: removeFalsyVals([selectedParentCase, ...selectedChildCases]),
    };
  }, [allParentCases, cases, selectedChildCaseIds, selectedParentCaseId, allChildCases]);

  const { isAuthorized } = useAuth();
  const hasTaskViewAccess = isAuthorized({
    capabilities: [{ capability: Capability.FIELD_TASKS, scopes: [PermissionScope.VIEW] }],
    siteIds: [entity?.site?.id],
  });

  const {
    tasks,
    taskIds,
    isLoading: isTasksLoading,
  } = useCasesTasks({
    cases: allSelectedCases,
  });

  const isFormValid = Boolean(
    selectedChildCaseIds.length &&
      selectedParentCaseId &&
      title &&
      (!hasTaskViewAccess || activeTab === 'tasks' || !taskIds.length),
  );

  const showReviewMessage = Boolean(
    hasTaskViewAccess &&
      activeTab === 'cases' &&
      selectedChildCaseIds.length &&
      selectedParentCaseId &&
      taskIds.length,
  );

  const handleSelection = useCallback((id) => {
    setSelectedCases((prev) => (prev.includes(id) ? prev.filter((v) => v !== id) : [...prev, id]));
  }, []);

  const handleParentCaseChange = useCallback(
    (selectedId) => {
      const selectedParentCase = cases.find((_case) => _case.id === selectedId);
      setTitle(selectedParentCase.description);
      setSelectedParent(selectedId);
      setSelectedCases((prev) =>
        prev.includes(selectedId) ? prev.filter((v) => v !== selectedId) : prev,
      );
    },
    [cases],
  );

  const onCancel = useCallback(() => {
    setErrorMessage('');
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    const body = {
      title,
      parentCaseId: selectedParentCaseId,
      caseToBeLinked: selectedChildCaseIds,
    };
    fuse(body, {
      onSettled: (res, error, variables) => {
        if (error) {
          setErrorMessage(error?.response?.data?.error ?? '');
          return;
        }
        onSave(res, error, variables);
      },
    });
  }, [title, selectedParentCaseId, selectedChildCaseIds, fuse, onSave]);

  const provider = {
    isCasesLoading,
    selectedChildCaseIds,
    setSelectedCases,
    selectedParentCaseId,
    setSelectedParent,
    parentCase,
    title,
    setTitle,
    errorMessage,
    setErrorMessage,
    activeTab,
    setActiveTab,
    isFormValid,
    showReviewMessage,
    handleSelection,
    handleParentCaseChange,
    onCancel,
    onSubmit,
    tasks,
    taskIds,
    isTasksLoading,
    entity,
    allSelectedCases,
    cases,
    isOpen,
  };

  return <CaseLinkContext.Provider value={provider}>{children}</CaseLinkContext.Provider>;
});

CaseLinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  entity: PropTypes.instanceOf(Object),
};

CaseLinkProvider.defaultProps = {
  isOpen: false,
  onClose: () => null,
  onSave: () => null,
  entity: null,
};

export const useCaseLinkContext = () => useContext(CaseLinkContext);
