import { PropTypes } from 'prop-types';
import React from 'react';

import { TaskTemplateModes } from '@ge/models/constants';

import { CreateAssignedTechs } from './create-assigned-techs';
import { EditAssignedTechs } from './edit-assigned-techs';
import { ViewAssignedTechs } from './view-assigned-techs';

export const AssignedCompletedTechsSection = ({
  section,
  task,
  templateMode,
  workerState,
  setWorkerState,
  removeWorker,
  deleteWrkrIds,
  isLoadingMultiplePerson,
}) => {
  const { metadata } = section;

  if (!section.hidden) {
    switch (templateMode) {
      case TaskTemplateModes.CREATE:
        return (
          <CreateAssignedTechs
            metadata={metadata}
            task={task}
            workerState={workerState}
            setWorkerState={setWorkerState}
            removeWorker={removeWorker}
            deleteWrkrIds={deleteWrkrIds}
            isLoadingMultiplePerson={isLoadingMultiplePerson}
          />
        );
      case TaskTemplateModes.EDIT:
        return (
          <EditAssignedTechs
            metadata={metadata}
            task={task}
            workerState={workerState}
            setWorkerState={setWorkerState}
            removeWorker={removeWorker}
            deleteWrkrIds={deleteWrkrIds}
            isLoadingMultiplePerson={isLoadingMultiplePerson}
          />
        );
      case TaskTemplateModes.VIEW:
        return (
          <ViewAssignedTechs
            section={section}
            task={task}
            workerState={workerState}
            setWorkerState={setWorkerState}
            removeWorker={removeWorker}
            deleteWrkrIds={deleteWrkrIds}
            isLoadingMultiplePerson={isLoadingMultiplePerson}
          />
        );
      default:
        return null;
    }
  }
};

AssignedCompletedTechsSection.propTypes = {
  section: PropTypes.object.isRequired,
  task: PropTypes.object,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  workerState: PropTypes.instanceOf(Object),
  setWorkerState: PropTypes.func,
  removeWorker: PropTypes.func,
  deleteWrkrIds: PropTypes.instanceOf(Object),
  isLoadingMultiplePerson: PropTypes.instanceOf(Boolean).isRequired,
};

AssignedCompletedTechsSection.defaultProps = {
  task: {},
};
