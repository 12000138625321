import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu, MenuTable, placements } from '@ge/components/menu';
import { DateTimeFormats, Placeholders } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledDiv = styled.div`
  display: inline-block;
`;

const StyledTable = styled.table`
  &.escalate-info {
    text-align: left;
    th {
      opacity: 0.5;
      color: ${(props) => props.theme.table.sectionTextColor};
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }
    td {
      color: ${(props) => props.theme.table.dataTextColor};
      font-size: 12px;
    }
    thead {
      tr {
        border-bottom: none;
        th {
          padding-bottom: 0;
        }
      }
    }
    tr {
      td:last-child {
        padding: 6px 10px;
      }
    }
  }
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

export const EscalatedInfo = ({ escalated, children, containerRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['monitor.issues'], { useSuspense: false });
  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <StyledDiv onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      <Menu anchorEl={anchorEl} open={open} container={containerRef} placement={placements.RIGHT}>
        <MenuTable disableHover={true}>
          <StyledTable className={'escalate-info'}>
            <thead>
              <tr>
                <th>{t('table.escalation_by', 'Escalation by')}</th>
                <th>{t('table.escalation_date', 'Date')}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{escalated?.escalatedBy ?? Placeholders.DOUBLE_DASH}</td>
                <td>
                  {escalated?.escalatedTime ? (
                    <StyledTimeDate>
                      <span>
                        {dayjs(escalated.escalatedTime).format(DateTimeFormats.DEFAULT_TIME)}
                      </span>
                      <span>
                        {dayjs(escalated.escalatedTime).format(DateTimeFormats.DEFAULT_DATE)}
                      </span>
                    </StyledTimeDate>
                  ) : (
                    Placeholders.DOUBLE_DASH
                  )}
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </MenuTable>
      </Menu>
    </StyledDiv>
  );
};

EscalatedInfo.propTypes = {
  escalated: PropTypes.object.isRequired,
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
};

EscalatedInfo.defaultProps = {
  escalated: null,
};
