import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Menu, MenuTable, placements } from '@ge/components/menu';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens';

//@custom styles
const Wrapper = styled.div`
  float: right;
  display: inline-block;
`;

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-alarms-count {
    margin-left: 10px;
  }
`;

const StyledThead = styled.tr`
  && > th {
    font-size: 10px;
    font-weight: 500;
    padding: 6px;
  }
  .alert {
    padding-right: 0;
  }
  .start-time {
    padding-left: 5px;
  }
`;

const StyledTr = styled.tr`
  td {
    font-size: 11px;
    font-weight: ${(props) => (props.isBold ? 700 : 300)};
    div {
      text-overflow: ellipsis;
      text-transform: none;
      overflow: hidden;
      white-space: nowrap;
    }
    &:first-of-type {
      display: flex;
      div {
        flex: 1;
        max-width: 100px; // Should change based on the FD designs
        margin-right: 11px;
      }
    }
    &:nth-of-type(2) {
      div {
        max-width: 200px;
      }
    }
    &:last-of-type {
      div {
        padding-right: 11px;
      }
    }
    &.alert {
      padding-right: 0;
      & > div {
        margin: 0 0 0 4px;
      }
    }
  }
`;

const MenuTableWarpper = styled(MenuTable)`
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;

//@functional component
export const PopupMenu = ({ title, headers, data, children, containerRef }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = (e) => {
    killEventPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Wrapper onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      {children}
      {data?.length > 1 ? (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          container={containerRef}
          placement={placements.BOTTOM_END}
          onClick={(e) => {
            e.preventDefault();
            killEventPropagation(e);
          }}
        >
          <StyledTitle>
            <h4 className="menu-title-txt">
              {title} <Badge color={globalColors.stone1} label={data.length} small />
            </h4>
          </StyledTitle>
          <MenuTableWarpper disableHover={true} style={{ maxHeight: '21em', overflow: 'auto' }}>
            <table>
              <thead>
                <StyledThead>
                  {headers?.map((value, id) => {
                    return (
                      <th key={id} className="alert">
                        {value}
                      </th>
                    );
                  })}
                </StyledThead>
              </thead>
              <tbody>
                {data?.map((record, id) => (
                  <StyledTr key={id}>
                    {headers ? (
                      headers.map((value, index) => {
                        return (
                          <td key={index} className="alert">
                            {record[value] || record}
                          </td>
                        );
                      })
                    ) : (
                      <td className="alert">{record}</td>
                    )}
                  </StyledTr>
                ))}
              </tbody>
            </table>
          </MenuTableWarpper>
        </Menu>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

PopupMenu.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  headers: PropTypes.array,
  containerRef: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object).isRequired,
};
