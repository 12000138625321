import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import {
  NAMESPACE,
  Fields,
  TaskFields,
  TaskColumns,
} from '@ge/feat-analyze/models/configure-case-template';
import {
  TaskPriority,
  EntityType,
  CaseSourceMapping,
  TaskTemplateModes,
} from '@ge/models/constants';
import { TaskProvider } from '@ge/shared/components/tasks/task-provider';
import { globalColors, typography } from '@ge/tokens';

import { FormRow, FormCollapsiblePanel } from '../../shared';

import NewTaskDialog from './case-template-task-dialog';
import TasksTable from './case-template-tasks-table';

const CheckboxContainer = styled.div`
  flex: 1;
`;

const StyledCheckBox = styled(Checkbox)`
  margin: ${({ margin }) => margin};
  span {
    color: ${(props) => props.theme.select.primaryTextColor};
  }
`;

const AddTaskButton = styled.button`
  color: ${(props) => props.theme.entityDetails.notes.addNote};
  display: flex;
  font-weight: ${typography.weight.bold};
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
`;

const PlusIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: props.theme.entityDetails.notes.addNote,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const CheckedIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHECK,
  color: props.color,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CLOSE,
  color: props.color,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0;
`;
const toTasksParams = (tasks) => {
  return (
    tasks?.filter(Boolean).map((task) => ({
      [TaskFields.TITLE]: task[TaskColumns.TITLE],
      [TaskFields.DESCRIPTION]: task[TaskColumns.DESCRIPTION],
      [TaskFields.WORK_SCOPE]: task[TaskColumns.WORK_SCOPE]
        ? task[TaskColumns.WORK_SCOPE]
        : task[TaskColumns.WORK_SCOPE_VIEW],
      [TaskFields.PRIORITY]: task[TaskColumns.PRIORITY],
      [TaskFields.DUE_DATE_OFFSET]: task[TaskColumns.DUE_DATE_OFFSET],
    })) ?? []
  );
};
const toTasksReverseParams = (tasks, caseTemplateData) => {
  return (
    tasks?.filter(Boolean).map((task) => ({
      id: uuidv4().slice(0, 8),
      [TaskColumns.TITLE]: task[TaskFields.TITLE],
      [TaskColumns.DESCRIPTION]: task[TaskFields.DESCRIPTION],
      [TaskColumns.WORK_SCOPE]: task[TaskFields.WORK_SCOPE],
      [TaskColumns.WORK_SCOPE_VIEW]: task[TaskFields.WORK_SCOPE],
      [TaskColumns.PRIORITY]: task[TaskFields.PRIORITY],
      [TaskColumns.DUE_DATE_OFFSET]: task[TaskFields.DUE_DATE_OFFSET],
      source: CaseSourceMapping[caseTemplateData?.source.toUpperCase()],
    })) ?? []
  );
};

const CaseTemplateTasks = ({ caseTemplateData, isEditing = true, isOpen }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });
  const [selected, setSelected] = useState(null);
  const [tasks, setTasks] = useState([]);

  const { register, setValue, watch } = useFormContext();

  register(Fields.TASK_AUTO_CREATION);
  register(Fields.TASK_SOURCE);

  const watchTaskSource = watch(Fields.TASK_SOURCE);
  const watchPriority = watch(Fields.CASE_PRIORITY);
  const watchSource = watch(Fields.CASE_SOURCE);

  const saveTask = useCallback((task) => {
    setSelected(null);
    setTasks((prev) => {
      const index = prev.findIndex((v) => v.id === task.id);
      let computedTasks = [...prev];
      if (index !== -1) {
        computedTasks[index] = task;
      } else {
        computedTasks = [...computedTasks, task];
      }
      return computedTasks;
    });
  }, []);

  const handleNewTaskClick = useCallback(() => {
    setSelected({
      title: '',
      description: '',
      source: watchTaskSource,
      priority: TaskPriority.MEDIUM,
      entType: EntityType.CASE,
    });
  }, [watchTaskSource]);

  const handleEditClick = useCallback(
    (taskId) => {
      setSelected(tasks?.find((v) => v.id === taskId));
    },
    [tasks],
  );

  const handleDeleteClick = useCallback((taskId) => {
    setTasks((prev) => prev.filter((v) => v.id !== taskId));
  }, []);

  useEffect(() => {
    if (watchSource) {
      setValue(Fields.TASK_SOURCE, CaseSourceMapping[watchSource.toUpperCase()]);
    } else {
      setValue(Fields.TASK_SOURCE, '');
    }
  }, [watchSource, setValue]);

  useEffect(() => {
    if (tasks.length === 0 && caseTemplateData && caseTemplateData?.taskAutoCreation?.length > 0) {
      setTasks(toTasksReverseParams(caseTemplateData.taskAutoCreation, caseTemplateData));
      setValue(Fields.TASK_SOURCE, CaseSourceMapping[caseTemplateData?.source.toUpperCase()]);
    }
  }, [caseTemplateData, setValue]);

  useEffect(() => {
    setValue(Fields.TASK_AUTO_CREATION, toTasksParams(tasks));
  }, [tasks, setValue]);

  if (!ready) return null;

  return (
    <TaskProvider>
      <FormCollapsiblePanel isOpen={isOpen} title={t('case_template.tasks', 'Tasks')}>
        <FormRow>
          <CheckboxContainer>
            {isEditing && (
              <StyledCheckBox
                label={t('case_template.auto_task_creation', 'Auto Task Creation')}
                defaultValue={false}
                checkState={tasks.length ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                onChange={handleNewTaskClick}
                margin={'0px 0px 10px 0px'}
              />
            )}

            {caseTemplateData?.taskAutoCreation?.length > 0
              ? !isEditing && <CheckedIcon color={globalColors.blue3} />
              : !isEditing && <CloseIcon color={globalColors.red2} />}
            {!isEditing && t('case_template.auto_task_creation', 'Auto Task Creation')}
          </CheckboxContainer>
          {tasks.length && isEditing ? (
            <AddTaskButton onClick={handleNewTaskClick}>
              <PlusIcon />
              {t('case_template.add_task_button', 'Add Task')}
            </AddTaskButton>
          ) : null}
        </FormRow>
        {tasks.length ? (
          <TasksTable
            values={tasks}
            onClickEdit={handleEditClick}
            onClickDelete={handleDeleteClick}
            isEditing={isEditing}
          />
        ) : null}
      </FormCollapsiblePanel>
      {selected && (
        <NewTaskDialog
          isOpen={true}
          task={selected}
          entity={{
            workScopes: 'TROUBLESHOOT',
            priority: watchPriority,
            source: isEditing ? watchSource : caseTemplateData?.source,
          }}
          entityType={EntityType.CASE}
          onClose={() => setSelected(null)}
          onConfirm={saveTask}
          templateMode={isEditing ? TaskTemplateModes.CREATE : TaskTemplateModes.VIEW}
        />
      )}
    </TaskProvider>
  );
};

CaseTemplateTasks.propTypes = {
  isOpen: PropTypes.bool,
  caseTemplateData: PropTypes.instanceOf(Object),
  isEditing: PropTypes.bool,
};

export default CaseTemplateTasks;
