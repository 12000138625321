import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useMutation, useInfiniteQuery } from 'react-query';

import { useReportScope } from '@ge/feat-reporting/hooks/use-report-scope';
import { AttributeGroups, DateTimeFormats, QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';

import * as reportsService from '../services';

const dayjs = require('dayjs');

export const useSentReportListTemplate = ({
  filters,
  search,
  sortMetric,
  sortDirection,
  pageSize = 200,
}) => {
  // use below code for site specific reports
  //const sitesForView = useStoreState((state) => state.sites.sitesForView);
  //const siteIds = sitesForView.map((site) => site.id);
  const serviceGroupsSites = useStoreState((state) => state.sites.getViewServiceGroupsSites);
  const serviceGroups = serviceGroupsSites.map((group) => group.id);

  // Might need to revisit this to figure out how to handle this for reports for individual sites
  // Currently, the backend only supports queries by service group OR site IDs, not a composite
  const scope = serviceGroups?.length <= 10 ? serviceGroups.join(',') : null;
  const scopeType = AttributeGroups.SERVICE_GROUP;

  const {
    data: allReports,
    fetchNextPage,
    hasNextPage,
    ...queryRest
  } = useInfiniteQuery(
    [QueryKey.SENT_REPORT, sortMetric, sortDirection, scope],
    ({ pageParam = 0 }) => {
      return reportsService.fetchSentReportListData({
        sortMetric,
        sortDirection,
        pageIdx: pageParam,
        pageSize,
        scopeType,
        scope,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.pagination?.currentPageIdx < lastPage?.pagination?.totalPages - 1)
          return lastPage.pagination.currentPageIdx + 1;
        return false;
      },
      ...Config.EXECUTE_ONCE,
      cacheTime: 60,
      enabled: true,
    },
  );

  const mappedReports = useMemo(
    () => allReports?.pages?.flatMap((page) => page.reports?.map((report) => report)),
    [allReports],
  );

  const {
    data: filteredData,
    filterValues,
    search: filterSearch,
  } = useTableFilter({
    data: mappedReports,
    filters,
    search,
  });

  return {
    data: filteredData,
    allReports,
    filters: filterValues,
    fetchNextPage,
    hasNextPage,
    search: filterSearch,
    ...queryRest,
  };
};

export const useCreateReport = ({ onSuccess, onError }) => {
  const { endDate, startDate } = useReportScope();

  const { mutate } = useMutation(
    ({ reportState, distributionList, attachments, reportFile, sendMail = false }) => {
      const reportParameters = reportState ?? {};

      const json = {
        name: reportParameters.name,
        templateId: reportParameters.id,
        reportType: reportParameters.templateType,
        scopeType: reportParameters.scopeType,
        scope: reportParameters.scope,
        distributionList,
        dateSent: dayjs().format(DateTimeFormats.ENDPOINT_PARAM),
        startDate: dayjs(startDate).format(DateTimeFormats.ENDPOINT_PARAM),
        endDate: dayjs(endDate).format(DateTimeFormats.ENDPOINT_PARAM),
        sendMail,
      };

      return reportsService.postNewReport(json, attachments, reportFile);
    },
    {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    },
  );

  return { mutate };
};

export const useSendReport = ({ onSuccess, onError }) => {
  const { mutate } = useMutation(
    ({ reportId, distributionList }) =>
      !reportId || !distributionList || distributionList.length < 1
        ? () => {}
        : reportsService.sendReport(reportId, distributionList),
    {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    },
  );
  return { mutate };
};
