/* eslint-disable */
import { PropTypes } from 'prop-types';
import React, { useEffect, useContext, useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Select } from '@ge/components/select';
import { PersonContext } from '../person-context';
import { useFetchAuditHistory } from '../hooks/use-fetch-audit-history';
import { FormLoading } from '../../../entity-create/entity-create-shared';
import { Loader } from '@ge/components/loader';
import { HistorySelectOptions } from '@ge/models/constants';
import { globalPersonHistoryCols, PersonHistoryColumnDefs } from '../../../../models/person-history-table-cols';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import PersonHistoryTable from './person-history-table';

const PersonHistoryContainer = styled.div`
  padding-top: 30px;
  background-color: ${(props) => props.theme.admin.panel.entityRow.light};
`;

const PersonHistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  span {
    display: block;
    margin: 0 25px 0 25px;
    font-size: 18px;
  }

  select {
    position: relative;
    padding: 6px 12px;
    width: 135px;
    color: #f2f2f2;
    background: #38434e;
    border: none;
    outline: none;
    border-radius: 6px;
    appearance: none;
    :focus {
      outline: none;
      border: none;
    }

    option {
      position: relative;
      background: #4b555f;
    }
  }
`;

const PersonHistoryWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  height:calc(100vh - 235px) !important;
  .simplebar-content {
    height: 100% !important;
  }
  .simplebar-content-wrapper {
    height: 100% !important;
  }

  table tr:first-child th {
    top: 0px;
    border-bottom: none !important;
  }
  table {
    border-top: 0 !important;
  }
  thead > tr > th {
    background-color: ${({ theme }) => theme.entityDetails.tabContentBackground};
  }
  tbody > tr {
    &:nth-child(odd) {
      background:  ${(props) => props.theme.admin.panel.entityRow.light} !important;
    }
    &:nth-child(even) {
      background: ${(props) => props.theme.admin.panel.entityRow.dark} !important;
    }
`;

const LoadingWrapper = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 75vh;
`;
export const PersonHistory = ({ personDetail }) => {
  const { providedAttributes, setProvidedAttributes } = useContext(PersonContext);
  const [selectValue, setSelectValue] = useState();
  const { t } = useTranslation(['admin.people']);

  useEffect(() => {
    let filteredAttributes = providedAttributes?.filter(
      (attribute) => attribute.roleName !== 'New_User_Role',
    );
    setProvidedAttributes(() => filteredAttributes);
  }, []);

  useEffect(() => {
    setSelectValue(HistorySelectOptions[3].value);
  }, []);


  const handleSelectChange = (option) => {
    setSelectValue(option.value);
    // when time value is selected, we filter History range accordingly
  };

  const [currentSearch, setCurrentSearch] = useState({});
  const [filterDefs, setFilterDefs] = useState({});

  const { sortDirection, sortMetric, updateSortMetric } = useColumnState({
    columnDefs: PersonHistoryColumnDefs,
    defaultCols: globalPersonHistoryCols,
    defaultSortMetric: 'actionTime',
    defaultSortDirection: SortDirection.ASC,
    sortStateId: 'history',
  });
  const { data: { data: history = [], filterValues } = {}, isLoading: isLoadingAuditHistory } = useFetchAuditHistory({
    filters: filterDefs,
    search: currentSearch,
    sortMetric,
    sortDirection,
    username: personDetail.username,
    dateRangeValue:selectValue,
  });

  const handleFilter = useCallback(
    (updateDefs) => {
      setFilterDefs(updateDefs);
    },
    [setFilterDefs],
  );

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  if (isLoadingAuditHistory)
  return (
    <LoadingWrapper>
      <FormLoading>
        <Loader />
      </FormLoading>
    </LoadingWrapper>
  ); 

  return (
    <PersonHistoryContainer>
      <PersonHistoryHeader>
        <span>{t('history', 'Audit History')}</span>
        <Select
          maxWidth={175}
          minWidth={175}
          onChange={(option) => handleSelectChange(option)}
          options={HistorySelectOptions}
          value={HistorySelectOptions.filter((option) => option.value === selectValue)}
        />
      </PersonHistoryHeader>
      <PersonHistoryWrapper>
        <PersonHistoryTable
          className="person-history-table"
          columns={globalPersonHistoryCols}
          history={history}
          sortAction={updateSortMetric}
          sortMetric={sortMetric}
          sortDirection={sortDirection}
          onFilter={handleFilter}
          onFilterChange={handleFilterChange}
          filterValues={filterValues}
          onRowSelect={()=>null}
        />
      </PersonHistoryWrapper>
    </PersonHistoryContainer>
  );
};

PersonHistory.propTypes = {
  personDetail: PropTypes.object,
  historyDetail: PropTypes.array,
};
