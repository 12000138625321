import { useEffect, useState } from 'react';
import { useQueries } from 'react-query';

import { Config } from '@ge/shared/data-hooks/config';

const getPageNumbers = ({ queries, batchSize }) => {
  const pageNumbers = [...new Array(batchSize)].map((_, i) => i);
  const res = queries.filter(({ data }) => !!data).map(({ data }) => data);
  const hasNextPage = res.every((row) => row.nextPage > 0);
  if (res.length > 0 && hasNextPage) {
    const lastPage = Math.max(...res.map(({ nextPage = 1 }) => nextPage));
    const pages = pageNumbers.map((i) => lastPage + i);

    if (res[0]?.totalPages > 0) {
      return pages.filter((page) => page < res[0].totalPages);
    }
    return pages;
  }

  return pageNumbers;
};

export const useTablePagination = ({
  queryKey = [],
  queryFn = () => {},
  enabled = false,
  retry = false,
  batchSize = 5,
  onSettled = () => {},
  onError = () => {},
  onSuccess = () => {},
}) => {
  const [pages, setPages] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const queries = useQueries(
    pageNumbers.map((page) => ({
      queryKey: [...queryKey, page],
      queryFn: () => queryFn({ page }),
      ...Config.EXECUTE_ONCE,
      keepPreviousData: true,
      enabled,
      retry,
      onSettled,
      onError,
      onSuccess: (data) => {
        setPages((prev) => [...prev, data]);
        onSuccess(data);
      },
    })),
  );

  const isLoading = queries.some((o) => o.isLoading);
  const isFetching = queries.some((o) => o.isFetching);

  useEffect(() => {
    if (!isFetching) {
      const hasNextPage = queries.every(({ data }) => data?.nextPage > 0);
      setNextPageLoading(hasNextPage);
      setPageNumbers(getPageNumbers({ queries, batchSize }));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isFetching, batchSize]);

  return {
    pages,
    isLoading,
    isLoadingNextPage: nextPageLoading,
  };
};
