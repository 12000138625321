import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { globalColors } from '@ge/tokens';

import { CaseLinkChildSection } from './case-link-child-section';
import { CaseLinkParentSection } from './case-link-parent-section';
import { useCaseLinkContext } from './case-link-provider';
import { CaseLinkTitle } from './case-link-title';

const DialogWrapper = styled.div`
  width: 480px;
  max-width: 100%;
`;

const DialogBody = styled(ScrollingContainer)`
  padding: 14px 25px;
  max-height: 70vh;
`;

const DescriptionText = styled.div`
  padding-bottom: 15px;
  font-size: 11px;
  line-height: 18px;
  font-weight: 300;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const CaseListLoaderContainer = styled.div`
  height: 165px;
  position: relative;
`;

const ErrorMessageContainer = styled.div`
  color: ${({ theme }) => theme.fileUpload.errorTextColor};
`;

const WarningIcon = styled(Icon).attrs(() => ({
  size: 10,
  icon: Icons.DATA_NETCOMM,
  color: globalColors.red2,
}))`
  margin: 10px 0px;
`;

export const CaseLinkDialog = React.memo(() => {
  const { t, ready } = useTranslation(['entity-details'], { useSuspense: false });
  const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);
  const { notify } = useNotification();

  const {
    isCasesLoading,
    errorMessage,
    isFormValid,
    showReviewMessage,
    onCancel,
    onSubmit,
    isOpen,
  } = useCaseLinkContext();

  useEffect(() => {
    if (showConfirmPrompt) {
      const message = (
        <NotificationMessage message={t('creating_link', 'Creating Link').toUpperCase()} />
      );
      notify({ message });
      setShowConfirmPrompt(false);
    }
  }, [notify, showConfirmPrompt, t, onSubmit]);

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      contentWidth={true}
      padContent={false}
      onClose={onCancel}
      header={t('link', 'Link')}
      footer={
        <FooterContainer>
          <ButtonContainer>
            <Button onClick={onCancel} disabled={isCasesLoading}>
              {t('general:cancel', 'Cancel')}
            </Button>
            <Button
              primary
              onClick={() => [setShowConfirmPrompt(true), onSubmit()]}
              disabled={isCasesLoading || !isFormValid}
            >
              {t('general:save', 'Save')}
            </Button>
          </ButtonContainer>
        </FooterContainer>
      }
    >
      <DialogWrapper>
        <DialogBody relative={true}>
          <CaseLinkTitle />
          <DescriptionText>
            {t(
              'link_dialog_description',
              'Linking relevant Cases and Tasks enables information to be shared between, and for them to be evaluated and addressed together.',
            )}
          </DescriptionText>
          {isCasesLoading ? (
            <CaseListLoaderContainer>
              <Loader />
            </CaseListLoaderContainer>
          ) : (
            <>
              <CaseLinkParentSection />
              <CaseLinkChildSection />
              {showReviewMessage && (
                <ErrorMessageContainer>
                  <WarningIcon />{' '}
                  {t(
                    'case_link_dialog.task_review_message',
                    'Please check the task tab to review associated tasks to the cases.',
                  )}
                </ErrorMessageContainer>
              )}
              {errorMessage ? <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer> : null}
            </>
          )}
        </DialogBody>
      </DialogWrapper>
    </Dialog>
  );
});

CaseLinkDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  entity: PropTypes.instanceOf(Object),
};

CaseLinkDialog.defaultProps = {
  isOpen: false,
  onClose: () => null,
  onSave: () => null,
  entity: null,
};

CaseLinkDialog.displayName = 'CaseLinkDialog';
