import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { forwardRef, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { DateTimeFormats, TemplateDateValue } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { addUTCOffset, getDateObject } from '@ge/shared/util/time-date';
import { StatusColor } from '@ge/tokens/colors/colors';

const StyledInput = styled(Input)`
  ${({ errors, fieldArray, id }) => {
    const { fieldName, errorIndex, errorName } = fieldArray ?? {};
    return errors[id] ||
      errors.scheduleDate?.type === id ||
      errors[fieldName]?.[errorIndex]?.[errorName]
      ? `border-color: ${StatusColor.DANGER}`
      : '';
  }}
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: props.theme.manage.taskItem.iconColor,
}))`
  margin-right: 4px;
`;

// eslint-disable-next-line react/display-name
const DatePickerIcon = forwardRef(({ onClick }, refDate) => (
  <button type="button" tabIndex="-1" className="task-dialog-date" onClick={onClick} ref={refDate}>
    <StyledIcon icon={Icons.CALENDAR} size={10} />
  </button>
));

DatePickerIcon.propTypes = {
  onClick: PropTypes.func,
};

DatePickerIcon.propTypes = {
  onClick: () => {},
};

export const DateMetaField = ({
  defaultSelection,
  defaultValue,
  fieldArray,
  metadata,
  name,
  rules,
  validators,
}) => {
  const { readOnly, required } = metadata;
  const { control, errors } = useFormContext();

  const {
    taskState: { timezone },
  } = useContext(TaskContext);

  const formatDate = (date) => (date ? dayjs(date).format(DateTimeFormats.NEW_TASK) : null);
  const earliestDate = validators?.map((validator) => validator.earliestDate)[0] || '';
  const latestDate = validators?.map((validator) => validator.latestDate)[0] || '';
  const minDate =
    earliestDate === TemplateDateValue.CURRENT_DATE
      ? getDateObject(addUTCOffset(dayjs(), timezone))
      : null;
  const maxDate =
    latestDate === TemplateDateValue.CURRENT_DATE
      ? getDateObject(addUTCOffset(dayjs(), timezone))
      : null;
  const startDate = dayjs(defaultValue).isValid()
    ? getDateObject(dayjs(defaultValue))
    : defaultSelection === TemplateDateValue.CURRENT_DATE
    ? getDateObject(addUTCOffset(dayjs(), timezone))
    : null;
  if (required) rules = { ...rules, required: true };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={startDate}
      rules={rules}
      render={({ onChange, value }) => {
        const _value =
          value === TemplateDateValue.CURRENT_DATE
            ? getDateObject(addUTCOffset(dayjs(), timezone))
            : value;
        return (
          <DatePicker
            customInput={
              <div className="date-picker">
                <StyledInput
                  autoComplete="off"
                  errors={errors}
                  fieldArray={fieldArray}
                  id={name}
                  onChange={() => null}
                  value={_value ? formatDate(_value) : ''}
                  disabled={readOnly}
                />
                <DatePickerIcon />
              </div>
            }
            disabled={readOnly}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            selected={_value || null}
            startDate={startDate}
          />
        );
      }}
    />
  );
};

DateMetaField.propTypes = {
  defaultSelection: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, null]),
  fieldArray: PropTypes.instanceOf(Object),
  metadata: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.instanceOf(Object),
  validators: PropTypes.instanceOf(Array),
};

DateMetaField.defaultProps = {
  defaultSelection: '',
  defaultValue: null,
  fieldArray: null,
  rules: null,
  validators: [],
};
