import { useStoreActions } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { DataLoaderType, EntityType } from '@ge/models/constants';
import { CaseUnlinkDialog } from '@ge/shared/components/cases/case-unlink-dialog';
import CaseAlertsTable from '@ge/shared/components/tables/case-alerts-table';
import CaseOverviewTable from '@ge/shared/components/tables/case-overview-table';
// import CaseRecentActivityTable from '@ge/shared/components/tables/case-recent-activity-table';
import { useCaseLinkedTaskIds } from '@ge/shared/components/use-linked-task-menu';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';

import { useInThisCase } from '../../../hooks/use-in-this-case';
import TabDetailHeader from '../components/tab-detail-header';
import { TabDetailContainer, EntityCollapsiblePanel } from '../entity-details-shared';

import { CaseDescription } from './case-description';

const SectionContainer = styled.div`
  position: relative;
  display: flex;
`;

const AlertsEmpty = styled.div`
  margin-left: 21px;
  margin-top: 12px;
  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }
`;

export const CaseOverview = ({ caseDetail, handleDetailClick, isCaseDetailsLoading }) => {
  const { t } = useTranslation(['entity-details']);

  const [selectedCases, setSelectedCases] = useState([]);

  const [triggerHeight, setTriggerHeight] = useState(false);
  const [dataToUnlink, setDataToUnlink] = useState({});

  const { showTaskDetails } = useContext(EntityDetailsContext);

  const { updateCases } = useStoreActions((actions) => actions.cases);

  const { childCasesAndTasks, isLoading: isInThisCaseLoading } = useInThisCase({ caseDetail });

  const { taskIds: caseTaskIds } = useCaseLinkedTaskIds({ caseId: caseDetail.id });

  useEffect(() => {
    if (!isCaseDetailsLoading) {
      setSelectedCases([...caseDetail.childCaseIds, ...caseTaskIds]);
    }
  }, [caseDetail.childCaseIds, caseTaskIds, isCaseDetailsLoading]);

  useEffect(() => {
    setTriggerHeight((_triggerHeight) => !_triggerHeight);
  }, [isCaseDetailsLoading, caseDetail, selectedCases, isInThisCaseLoading]);

  const sortByMostRecent = (a, b) => new Date(b.startDate) - new Date(a.startDate);

  const selectedAlerts = useMemo(() => {
    const alerts = !caseDetail.childCaseIds?.length
      ? caseDetail.alerts
      : caseDetail.alerts.filter((a) => selectedCases.includes(a.caseId));
    // renaming to startDate and endDate to fix paddingTop in CaseAlertsTable calculated in DynamicTable
    return alerts
      ?.map(({ start, end, ...rest }) => ({ startDate: start, endDate: end, ...rest }))
      .sort(sortByMostRecent);
  }, [caseDetail, selectedCases]);

  const toggleIncludedCase = useCallback((selectedCaseId) => {
    setSelectedCases((_selectedCases) =>
      _selectedCases.includes(selectedCaseId)
        ? _selectedCases.filter((caseId) => caseId !== selectedCaseId)
        : [..._selectedCases, selectedCaseId],
    );
  }, []);

  const handleTaskSelect = useCallback(
    (e, id, type) => {
      killEventPropagation(e);
      if (type === EntityType.TASK) showTaskDetails(id);
    },
    [showTaskDetails],
  );

  const handleUnlinkSelect = (entity) => {
    console.log('handleUnlinkSelect', entity);
    setDataToUnlink(entity);
  };

  const handleUnlinkClose = () => {
    setDataToUnlink({});
  };

  const handleUnlinkSave = (res) => {
    if (res.cases?.length > 0) {
      updateCases(res.cases);
    }
    setDataToUnlink({});
  };

  if (!caseDetail) {
    return null;
  }

  return (
    <TabDetailContainer>
      <TabDetailHeader
        entity={caseDetail}
        entityType={EntityType.CASE}
        isLoading={isCaseDetailsLoading}
      />
      <CaseDescription caseDetails={caseDetail} isLoading={isCaseDetailsLoading} />
      <EntityCollapsiblePanel
        headerContent={<h2>{t('case_panel.in_this_case', 'In This Case')}:</h2>}
        expanded={true}
        triggerHeight={triggerHeight}
      >
        <SectionContainer>
          <DataLoader
            type={DataLoaderType.TABLE}
            isLoading={isCaseDetailsLoading || isInThisCaseLoading}
          >
            <CaseOverviewTable
              childCases={childCasesAndTasks}
              checked={selectedCases}
              toggleIncludedRow={toggleIncludedCase}
              onTaskSelect={handleTaskSelect}
              onUnlinkSelect={handleUnlinkSelect}
            />
          </DataLoader>
        </SectionContainer>
      </EntityCollapsiblePanel>
      <EntityCollapsiblePanel
        headerContent={<h2>{t('case_panel."evidence":', 'Evidence')}</h2>}
        expanded={true}
        triggerHeight={triggerHeight}
      >
        <DataLoader type={DataLoaderType.TABLE} isLoading={isCaseDetailsLoading}>
          <SectionContainer>
            {!isCaseDetailsLoading && !selectedAlerts?.length ? (
              <AlertsEmpty>
                <p>{t('case_panel.no_evidence', 'No Evidence Attached To This Case')}</p>
              </AlertsEmpty>
            ) : (
              <CaseAlertsTable alerts={selectedAlerts} handleDetailClick={handleDetailClick} />
            )}
          </SectionContainer>
        </DataLoader>
      </EntityCollapsiblePanel>
      {/* As per Product, commenting for now */}
      {/* <EntityCollapsiblePanel
        headerContent={<h2>{t('case_panel.recent_activity', 'Recent Activity')}</h2>}
        expanded={true}
        triggerHeight={triggerHeight}
      >
        <DataLoader type={DataLoaderType.TABLE} isLoading={isCaseDetailsLoading}>
          <SectionContainer>
            <CaseRecentActivityTable activity={[]} />
          </SectionContainer>
        </DataLoader>
      </EntityCollapsiblePanel> */}
      <CaseUnlinkDialog
        caseDetail={caseDetail}
        isOpen={Boolean(dataToUnlink.id)}
        entityId={dataToUnlink.id}
        entityType={dataToUnlink.type}
        onClose={handleUnlinkClose}
        onSave={handleUnlinkSave}
      />
    </TabDetailContainer>
  );
};

CaseOverview.propTypes = {
  caseDetail: PropTypes.instanceOf(Object).isRequired,
  handleDetailClick: PropTypes.func,
  isCaseDetailsLoading: PropTypes.bool,
};

CaseOverview.defaultProps = {
  handleDetailClick: () => {},
  isCaseDetailsLoading: false,
};
