import { PropTypes } from 'prop-types';
import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Chart, ChartBorder, ChartType } from '@ge/components/charts';
import { chartOptions } from '@ge/components/charts/models';
import { DataLoader } from '@ge/components/data-loader';
import { Select } from '@ge/components/select';
import { handleKpiError } from '@ge/feat-analyze/util/kpi';
import { CardinalDirection } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { roundNumber } from '@ge/util';

import { DashboardChartTitle } from './dashboard-chart-title';
import { DashboardTitle } from './dashboard-shared';

export const DashboardConditionType = {
  TEMPERATURE: 'temperature',
  WIND_DIRECTION_AND_SPEED: 'windBearing',
  WIND_SPEED: 'windSpeed',
};

const SeriesCategoryLevel = {
  '<0.5': 'conditions.rose.min',
  '0.5 to 2.0': 'conditions.rose.2',
  '2.0 to 4.0': 'conditions.rose.4',
  '4.0 to 6.0': 'conditions.rose.6',
  '6.0 to 8.0': 'conditions.rose.8',
  '8.0 to 10.0': 'conditions.rose.10',
  '>10.0': 'conditions.rose.max',
};

const conditionChartTypeMap = {
  [DashboardConditionType.TEMPERATURE]: ChartType.AREA_SPLINE,
  [DashboardConditionType.WIND_DIRECTION_AND_SPEED]: ChartType.ROSE,
  [DashboardConditionType.WIND_SPEED]: ChartType.AREA,
};

const getChartOptions = ({ data: _data, t, type: conditionType }) => {
  const data = _data[conditionType] || [];
  const type = conditionChartTypeMap[conditionType];

  switch (conditionType) {
    case DashboardConditionType.WIND_SPEED: {
      //translations
      const { percent, title, units } = {
        percent: t('conditions.percent', '%'),
        title: t('conditions.wind_speed', 'Wind Speed'),
        units: t('conditions.meters_per_second', 'm/sec'),
      };
      if (!data.length) {
        return { series: data };
      }

      const tooltipHeader = `<h4>${title}</h4>`;
      const tooltipPointFormatter = ({ x, y }) => `
        <div class='body-4'>
          ${x}${units}, ${y}${percent}
        </div>
      `;

      return {
        series: [
          {
            data: data.map(({ bucket, distribution }) => ({
              x: Number(bucket),
              y: roundNumber(distribution * 100, 1),
            })),
            tooltipHeader,
            tooltipPointFormatter,
          },
        ],
        type,
        xAxisTitle: t('conditions.wind_speed_x_axis_title', 'Wind Speed (m/sec)'),
      };
    }

    case DashboardConditionType.TEMPERATURE: {
      //translations
      const { percent, title, units } = {
        percent: t('conditions.percent', '%'),
        title: t('conditions.temperature', 'Temperature'),
        units: t('conditions.celsius', '°C'),
      };

      const tooltipHeader = `<h4>${title}</h4>`;
      const tooltipPointFormatter = ({ x, y }) => `
        <div class='body-4'>
          ${x}${units}, ${y}${percent}
        </div>
      `;

      return {
        series: [
          {
            data: data.map(({ bucket, distribution }) => ({
              x: Number(bucket),
              y: roundNumber(distribution * 100, 1),
            })),
            tooltipHeader,
            tooltipPointFormatter,
          },
        ],
        type,
        xAxisTitle: t('conditions.temperature_x_axis_title', 'Temperature (°C)'),
      };
    }

    case DashboardConditionType.WIND_DIRECTION_AND_SPEED: {
      // translations
      const { cardinal, categoryMap, percent, title, units } = {
        cardinal: Object.values(CardinalDirection).reduce(
          (acc, v) => ({ ...acc, [v]: t(`conditions.cardinal.${v}`) }),
          {},
        ),
        categoryMap: Object.keys(SeriesCategoryLevel).reduce(
          (acc, key) => ({ ...acc, [key]: t(`${SeriesCategoryLevel[key]}`) }),
          {},
        ),
        percent: t('conditions.percent', '%'),
        title: t('conditions.wind_direction_and_speed', 'Wind Direction & Speed'),
        units: t('conditions.meters_per_second', 'm/s'),
      };

      const tooltipHeader = `
        <h4>
          ${title}
        </h4>
      `;
      const tooltipPointFormatter = ({ name, y }) => `
        <div class='body-4'>
          ${cardinal[name]}, ${y}${percent}
        </div>
      `;

      const buildSeries = (category, bearingData) => {
        const categoryData = Object.keys(bearingData).map((key) => ({
          name: key,
          y: roundNumber(bearingData[key][category] * 100, 1),
        }));
        return {
          data: categoryData,
          name: categoryMap[category],
          tooltipHeader,
          tooltipPointFormatter,
        };
      };

      const series = Object.keys(SeriesCategoryLevel).map((category) =>
        buildSeries(category, data),
      );

      return {
        categories: Object.keys(data).map((key) => cardinal[key]),
        legendTitle: units,
        series: series.slice(),
        stacked: true,
        type,
        xAxisTickInterval: 4,
      };
    }

    default:
      return [];
  }
};

const StyledSelect = styled(Select)`
  margin-left: 2px;
`;

// styled DashboardChartTitle to override the default margin
const StyledDashboardChartTitle = styled(DashboardChartTitle)`
  & div:nth-child(2) {
    padding-right: 27px;
  }
`;

export const DashboardConditionsChart = React.forwardRef(
  (
    {
      height,
      data,
      error,
      defaultType,
      namespace,
      onConditionTypeChange,
      getCurrentCondition,
      isLoading,
      isSiteCondition,
      noDataLabel: noDataLabelProp,
      windSpeedAvg,
    },
    ref,
  ) => {
    const { ready, t } = useTranslation([namespace], { useSuspense: false });
    const [currentType, setCurrentType] = useState(defaultType);
    const handleConditionTypeChange = useCallback(
      (option) => {
        setCurrentType(option.value);
        onConditionTypeChange(option.value);
      },
      [setCurrentType, onConditionTypeChange],
    );

    // translations
    const translations = useMemo(
      () => ({
        noDataLabel: noDataLabelProp || t('no_data_label', 'No data to display'),
        temperature: t('conditions.temperature', 'Temperature'),
        title: isSiteCondition
          ? t('conditions.site_title', 'Conditions')
          : t('conditions.asset_title', 'Conditions'),
        windDirectionAndSpeed: t('conditions.wind_direction_and_speed', 'Wind Direction & Speed'),
        windSpeed: t('conditions.wind_speed', 'Wind Speed'),
        // currently this looks consistent across charts but can be made dynamic
        yAxisTitle: t('conditions.y_axis_title', 'Distribution (%)'),
        units: t('conditions.meters_per_second', 'm/sec'),
        average: t('conditions.average', 'Average'),
      }),
      [noDataLabelProp, t, isSiteCondition],
    );

    // error handler
    const { noData, noDataTitle, noDataDescription } = useMemo(
      () => handleKpiError({ error, t }),
      [error, t],
    );

    const selectOptions = useMemo(() => {
      return (
        ready && [
          { value: DashboardConditionType.WIND_SPEED, label: translations.windSpeed },
          { value: DashboardConditionType.TEMPERATURE, label: translations.temperature },
          {
            value: DashboardConditionType.WIND_DIRECTION_AND_SPEED,
            label: translations.windDirectionAndSpeed,
          },
        ]
      );
    }, [
      ready,
      translations.temperature,
      translations.windDirectionAndSpeed,
      translations.windSpeed,
    ]);

    useEffect(() => {
      getCurrentCondition &&
        getCurrentCondition(selectOptions.find((option) => option.value === currentType));
    }, [currentType, getCurrentCondition, selectOptions]);

    if (!ready) {
      return null;
    }

    // chart options
    const {
      categories,
      height: _height,
      legendTitle,
      noDataLabel,
      series,
      stacked,
      xAxisTickInterval,
      xAxisTitle,
    } = {
      ...getChartOptions({
        data,
        t,
        type: currentType,
      }),
      height: height,
      noDataLabel: translations.noDataLabel,
    };

    const avgLine = [
      // to add an average plotline
      // please refer to the plotline object on Highcharts
      // https://api.highcharts.com/highcharts/xAxis.plotLines
    ];

    const chartType =
      currentType === DashboardConditionType.WIND_DIRECTION_AND_SPEED
        ? ChartType.ROSE
        : ChartType.SPLINE;

    return (
      <ChartBorder ref={ref}>
        <DashboardTitle data-testid={AnalyzeLocators.ANALYZE_CONDITION_GRAPH_TITLE}>
          {translations.title}
        </DashboardTitle>
        <StyledDashboardChartTitle
          displayLegend={false}
          metadata={
            currentType != DashboardConditionType.TEMPERATURE && {
              total: windSpeedAvg
                ? windSpeedAvg * 100
                : data.windSpeedAverage && data.windSpeedAverage * 100,
              unit: translations.units,
              isAverage: true,
            }
          }
        >
          <StyledSelect
            small
            value={selectOptions.filter((option) => option.value === currentType)}
            onChange={handleConditionTypeChange}
            options={selectOptions}
          />
        </StyledDashboardChartTitle>
        <DataLoader
          isLoading={isLoading}
          type={chartType}
          noData={noData}
          noDataTitle={noDataTitle}
          noDataDescription={noDataDescription}
          renderCondition={!error}
          onRetry={null}
          height={`${height + chartOptions.chart.marginBottom}px`}
        >
          {(currentType === DashboardConditionType.WIND_SPEED ||
            currentType === DashboardConditionType.TEMPERATURE) && (
            <Chart
              height={_height}
              noDataLabel={noDataLabel}
              series={series}
              type={ChartType.AREA_SPLINE}
              xAxisTitle={xAxisTitle}
              yAxisTitle={translations.yAxisTitle}
              xAxisPlotLines={avgLine}
              disableSideMargin
            />
          )}
          {/* Using same chart for wind and temperature distribution */}
          {currentType === DashboardConditionType.WIND_DIRECTION_AND_SPEED && (
            <Chart
              categories={categories}
              height={_height}
              legendTitle={legendTitle}
              noDataLabel={noDataLabel}
              recreateOnUpdate={true}
              series={series}
              stacked={stacked}
              type={ChartType.ROSE}
              xAxisTickInterval={xAxisTickInterval}
              disableSideMargin
            />
          )}
        </DataLoader>
      </ChartBorder>
    );
  },
);

DashboardConditionsChart.propTypes = {
  height: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  defaultType: PropTypes.string,
  namespace: PropTypes.string,
  onConditionTypeChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isSiteCondition: PropTypes.bool,
  noDataLabel: PropTypes.string,
  getCurrentCondition: PropTypes.func,
  windSpeedAvg: PropTypes.number,
};

DashboardConditionsChart.defaultProps = {
  height: 250,
  data: {},
  defaultType: DashboardConditionType.WIND_SPEED,
  onConditionTypeChange: () => {},
  isLoading: false,
};

DashboardConditionsChart.displayName = 'DashboardConditionsChart';
export const MemoizedDashboardConditionsChart = memo(DashboardConditionsChart);
