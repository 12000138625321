import { PropTypes } from 'prop-types';
import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { useNotification } from '@ge/components/notification';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { FormErrorMessage } from '@ge/feat-analyze/components/configure/shared';
import useCaseAnalysisTemplate from '@ge/feat-analyze/data-hooks/use-case-analysis-template';
import {
  useUpdateAnalysisTemplate,
  useCaseAnalysisTemplateQuery,
} from '@ge/feat-analyze/data-hooks/use-case-analysis-template-details';
import { NAMESPACE, Fields, PlotFields } from '@ge/feat-analyze/models/configure-analysis-template';
import {
  getSignalSourceBasedOnType,
  getSignalTitle,
} from '@ge/feat-analyze/util/data-explorer-chart';
import { TimeSignal, EntityMode, SortDirection, QueryKey } from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import AnalysisTemplateCharts from './analysis-template-charts';
import OverviewDetails from './analysis-template-overview-details';

const LoaderContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  min-height: 200px;
  max-height: calc(100vh - 148px);
  overflow: auto;
  ${({ isLoading }) => (isLoading ? 'position: relative' : '')}
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.trackBackground};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumbBackground};
    border-radius: 2.5px;
  }
`;

const FooterButtons = styled.div`
  display: flex;
  padding: 12px 15px;
  justify-content: space-between;
`;

const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  min-height: 80vh;
  overflow: hidden;
  [data-simplebar='init'] {
    padding-right: 10px;
  }
  ${scrollbarWrapperOverride}
`;

const signalSourceType = {
  HISTORICAL: 'historical',
};

const SignalSourceMap = {
  [signalSourceType.HISTORICAL]: 'HistoricalMachineData',
};

const buildChartAxisObj = (signal, signalsById) => {
  const id = signal.signalId;
  const timeAggr = signal.signalAggregation ?? '';
  const signalSource = signal.signalSource ?? '';
  const _signal = id === TimeSignal.id ? TimeSignal : signalsById?.[signal.signalId] ?? {};
  return {
    ...signal,
    ..._signal,
    id: signal.signalId,
    title: getSignalTitle({ ..._signal, timeAggr }),
    timeAggr,
    signalSource: getSignalSourceBasedOnType({ ..._signal, signalSource }),
  };
};

const getDefaultValues = (res, signalsById) => {
  const values = {
    ...res,
    [Fields.ANALYSIS_TEMPLATE_ID]: res.analysisTemplateIds[0],
    [Fields.ASSET_TYPE_ID]: null,
    [Fields.SIGNAL_MAPS]: [],
    [Fields.IS_DEFAULT_TEMPLATE]: false,
    [Fields.PLOTS]: [],
  };

  for (let plot of res.plots) {
    const { plotId, plotType, signal_x, signals_y } = plot;
    values[Fields.PLOTS].push({
      [PlotFields.PLOT_ID]: plotId,
      [PlotFields.PLOT_TYPE]: plotType,
      [PlotFields.SIGNAL_X]: buildChartAxisObj(signal_x, signalsById),
      [PlotFields.SIGNALS_Y]: signals_y.map((signal_y) => buildChartAxisObj(signal_y, signalsById)),
    });
  }
  return values;
};

export const AnalysisTemplateOverview = ({ entityId, onClose, onConfirm }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });
  const ref = useRef();

  const queryClient = useQueryClient();

  const { notify } = useNotification();

  const { isLoading: isLoadingDetails, data } = useCaseAnalysisTemplate({
    caseWithDetails: { analysisTemplateId: entityId, asset: {} },
    isCaseDetailsLoading: false,
  });

  const {
    mutate,
    isLoading: isUpdating,
    isError,
    error,
  } = useUpdateAnalysisTemplate({
    enabled: true,
    onSuccess: (res) => {
      queryClient.invalidateQueries([QueryKey.ANALYSIS_TEMPLATE_DATA]);
      queryClient.invalidateQueries([QueryKey.CASE_ANALYSIS_TEMPLATE, [entityId]]);
      onConfirm(res);
      notify({
        message: t('analysis_template_updated_success', 'Analysis Template Updated Successfully'),
      });
    },
  });

  const methods = useForm({ mode: 'onBlur' });
  const { watch, reset } = methods;

  const selectedAssetTypeId = watch(Fields.ASSET_TYPE_ID);
  const selectedSignalMapIds = watch(Fields.SIGNAL_MAPS);

  const {
    isLoading: isLoadingSignals,
    assetSignalMap,
    signalMappings,
  } = useCaseAnalysisTemplateQuery({
    isActive: !!entityId,
  });

  const { signalMapById, signalsById } = useMemo(() => {
    const signalsById = {};
    const signalMapById = {};
    if (!signalMappings) {
      return { signalMapById, signalsById };
    }
    const mappings = Object.values(signalMappings);
    for (let mapping of mappings) {
      if (mapping?.signals) {
        const {
          categoryId = 0,
          dataMode = 'historical',
          assetTypeId,
        } = assetSignalMap?.[mapping.id] ?? {};
        signalMapById[mapping.id] = { id: mapping.id, name: mapping.name, assetTypeId };
        for (let signal of mapping.signals) {
          if (
            !(
              signalsById[signal.id] &&
              signalsById[signal.id].aggregations?.length > signal.aggregations?.length
            )
          ) {
            signalsById[signal.id] = {
              ...signal,
              categoryId,
              signalSource: SignalSourceMap[dataMode],
              signalMapId: mapping.id,
            };
          }
        }
      }
    }

    return { signalMapById, signalsById };
  }, [assetSignalMap, signalMappings]);

  const signalMaps = useMemo(() => {
    if (selectedAssetTypeId) {
      return Object.values(signalMapById)
        .filter((v) => selectedAssetTypeId === v.assetTypeId)
        .sort(sorter('name', SortDirection.ASC));
    }
    return Object.values(signalMapById).sort(sorter('name', SortDirection.ASC));
  }, [selectedAssetTypeId, signalMapById]);

  const signalData = useMemo(() => {
    if (selectedSignalMapIds?.length) {
      return Object.values(signalsById)
        .filter((signal) => selectedSignalMapIds.includes(signal.signalMapId))
        .sort(sorter('name', SortDirection.ASC));
    }
    return Object.values(signalsById).sort(sorter('name', SortDirection.ASC));
  }, [selectedSignalMapIds, signalsById]);

  const isLoading = isLoadingDetails || isLoadingSignals;

  const onSubmit = useCallback(
    (values) => {
      const payload = {
        [Fields.ANALYSIS_TEMPLATE_ID]: values[Fields.ANALYSIS_TEMPLATE_ID],
        // This is for future implementation
        // [Fields.IS_DEFAULT_TEMPLATE]: values[Fields.IS_DEFAULT_TEMPLATE],
        [Fields.PLOTS]: values[Fields.PLOTS]
          .filter((value) => value[PlotFields.PLOT_TYPE])
          .map((value) => ({
            [PlotFields.PLOT_ID]: value[PlotFields.PLOT_ID],
            [PlotFields.PLOT_TYPE]: value[PlotFields.PLOT_TYPE],
            [PlotFields.SIGNALS_Y]: value[PlotFields.SIGNALS_Y],
            [PlotFields.SIGNAL_X]: value[PlotFields.SIGNAL_X],
          })),
      };
      mutate(payload);
    },
    [mutate],
  );

  useEffect(() => {
    if (!isLoading && data) {
      const values = getDefaultValues(data, signalsById);
      if (ref.current && values[Fields.PLOTS]) {
        ref.current.setCharts(
          values[Fields.PLOTS].map((chart) => ({
            ...chart,
            signals: { x: chart[PlotFields.SIGNAL_X], y: chart[PlotFields.SIGNALS_Y] },
          })),
        );
      }
      reset(values);
    }
  }, [isLoading, data, signalsById, reset]);

  if (!ready) return null;

  if (isLoading) {
    return (
      <Container isLoading={isLoading}>
        <Loader />
      </Container>
    );
  }

  return (
    <FormProvider {...methods}>
      <ScrollingWrapper>
        <ScrollingContainer>
          <OverviewDetails
            entityMode={EntityMode.EDIT}
            isLoading={isLoadingSignals}
            signalMaps={signalMaps}
          />
          <AnalysisTemplateCharts ref={ref} isLoading={isLoadingSignals} signalData={signalData} />
          <FormErrorMessage isError={isError} message={error?.message} />
        </ScrollingContainer>
      </ScrollingWrapper>
      <FooterButtons>
        <Button onClick={onClose} disabled={isLoading || isUpdating}>
          {t('analysis_template.cancel', 'Cancel')}
        </Button>
        {isUpdating && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <Button primary onClick={methods.handleSubmit(onSubmit)} disabled={isLoading || isUpdating}>
          {t('analysis_template.save', 'Save & Exit')}
        </Button>
      </FooterButtons>
    </FormProvider>
  );
};

AnalysisTemplateOverview.propTypes = {
  entityId: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

AnalysisTemplateOverview.defaultValues = {
  entityId: null,
  onClose: () => {},
  onConfirm: () => {},
  selectedEntityId: undefined,
};

export default AnalysisTemplateOverview;
