import { useStoreActions, useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';
import { AttributeGroups, DefaultRoleType, PersonCheckboxValue } from '@ge/models/constants';

import RolesTab from '../components/roles-tab';

const StyledTabs = styled(Tabs)`
  height: 100% !important;
  margin-bottom: 10px;
  > div:last-child > div {
    overflow: visible;
  }
`;

const PersonAssignUserRoles = forwardRef(({ className, onRoleChange, personAttributes }, ref) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'selectedRoles',
    control,
  });
  const watchSelectedRoles = watch(PersonCheckboxValue.SELECTED_ROLES);
  const watchUser = watch(PersonCheckboxValue.USER);

  const [activeTab, setActiveTab] = useState('');
  const [initialLoad, setInitialLoad] = useState(false);

  const defaultRole = useMemo(
    () => ({
      name: '',
      permissions: [],
      entities: {},
      isDynamicAll: false,
    }),
    [],
  );

  useImperativeHandle(ref, () => ({
    addRole() {
      append(defaultRole);
    },
  }));
  const { getUserRoleBasedEntityAttributes } = useStoreActions((actions) => actions.userRole);
  const { limitedUserRoles: mappedLimitedRoles, isUserAttributesLoading: isLimitedRolesLoading } =
    useStoreState((state) => state.userRole);

  useEffect(() => {
    if (!mappedLimitedRoles) {
      getUserRoleBasedEntityAttributes();
    }
  }, [getUserRoleBasedEntityAttributes, mappedLimitedRoles]);

  useEffect(() => {
    if (personAttributes && watchUser) {
      let filteredRoles = personAttributes?.entity_group?.filter(
        (attribute) =>
          attribute.roleName !== DefaultRoleType.MONITOR_ADMIN &&
          attribute.roleName !== DefaultRoleType.ANALYTICS_VIEW &&
          attribute.roleName !== DefaultRoleType.MONITOR_VIEW,
      );
      let formattedRoles = filteredRoles?.map((role) => {
        return {
          name: role.roleName,
          permissions: [],
          entities: {
            serviceGroup: role.groupCombination.length
              ? role.groupCombination
                  .filter((group) => group.groupName === AttributeGroups.SERVICE_GROUP)[0]
                  ?.ids?.map((id) => id)
              : [],
            site: role.groupCombination.length
              ? role.groupCombination
                  .filter((group) => group.groupName === AttributeGroups.SITES)[0]
                  ?.ids?.map((id) => id)
              : [],
          },
          isDynamicAll: role.groupCombination.length
            ? role.groupCombination.filter((group) => group.groupName === AttributeGroups.SITES)[0]
                ?.userAccess?.length > 0
            : false,
        };
      });
      if (formattedRoles?.length) {
        formattedRoles.map((role) => append(role));
        remove(0);
        setValue(PersonCheckboxValue.APPLY_ALL_USER_ROLES, false);
      }
    }
  }, [personAttributes, append, watchUser, remove, setValue, defaultRole]);

  useEffect(() => {
    if (!initialLoad && watchSelectedRoles?.length) {
      setActiveTab(watchSelectedRoles[0].name.replace(/_/g, '-').toLowerCase());
    }
  }, [initialLoad, watchSelectedRoles]);

  useEffect(() => {
    onRoleChange(
      Boolean(
        watchSelectedRoles.filter((role) => role.name === '').length ||
          mappedLimitedRoles?.length === watchSelectedRoles.length,
      ),
    );
  }, [mappedLimitedRoles, onRoleChange, setActiveTab, watchSelectedRoles]);

  const handleRoleChange = useCallback(
    (value) => {
      // TODO: Tabs component label serialization doesn't handle the underscore and causes mismatch,
      // Using this `replace` to handle until we get real data and see how role names will be managed
      setInitialLoad(true);
      setActiveTab(value.replace(/_/g, '-').toLowerCase());
      setValue(PersonCheckboxValue.APPLY_ALL_USER_ROLES, false);
    },
    [setValue],
  );

  const handleRemoveRole = useCallback(
    (index) => {
      remove(index);
      const selectedRoles = watchSelectedRoles.filter((role, idx) => idx !== index);
      setActiveTab(selectedRoles[0].name.replace(/_/g, '-').toLowerCase());
      setValue(PersonCheckboxValue.APPLY_ALL_USER_ROLES, false);
    },
    [remove, setValue, watchSelectedRoles],
  );

  if (isLimitedRolesLoading) return null;

  return (
    <StyledTabs className={className} defaultTab={activeTab} isForceRenderTabs={true}>
      {fields.map((role, index) => (
        <Tab
          key={role.id}
          label={
            watchSelectedRoles[index].name
              ? t(
                  `roles.${watchSelectedRoles[index].name}`,
                  watchSelectedRoles[index]?.name?.replace(/[_-]/g, ' '),
                )
              : t('assign_roles.new_user_role', 'New User Role')
          }
        >
          <RolesTab
            index={index}
            onRoleChange={handleRoleChange}
            onRemoveRole={handleRemoveRole}
            roleName={watchSelectedRoles[index].name}
            roles={mappedLimitedRoles}
          />
        </Tab>
      ))}
    </StyledTabs>
  );
});

PersonAssignUserRoles.propTypes = {
  className: PropTypes.string,
  onRoleChange: PropTypes.func.isRequired,
  personAttributes: PropTypes.object,
};

PersonAssignUserRoles.defaultProps = {
  className: null,
};

PersonAssignUserRoles.displayName = 'PersonAssignUserRoles';

export default PersonAssignUserRoles;
