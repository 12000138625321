import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConditionalRender } from '@ge/components/conditional-render';
import { Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Capability, DateTimeFormats, PermissionScope } from '@ge/models/constants';
import { ErrorNotification } from '@ge/shared/components/tasks/error-notification';
import { useAuth } from '@ge/shared/data-hooks';
import { getDayEndTime } from '@ge/shared/util';

import { useManualAdjustmentContext } from '../../context/manual-adjustment-context';
import { useDeleteMalEvent } from '../../data-hooks/use-contractual-reporting-events';

import { EditEventDetailDialog } from './edit-event-detail-dialog';
import {
  CTAButtons,
  CTAContainer,
  DialogGrid2,
  DialogLabel,
  DialogValue,
  IconButton,
  IconButtonContent,
  IconButtonIcon,
  EventCategory,
  EventCategoryName,
  EventCategoryColor,
} from './event-dialog-style';
import { MalEventDetailDialog } from './mal-event-detail-dialog';

const StyledDialog = styled(Dialog)`
  display: block;
  position: relative;
  margin: 0;
  width: 96%;
  height: 100%;
  transform: inherit;
  background-color: ${(props) => props.theme.analyze.manualAdjustment.eventDialog.backgroundColor};
  header {
    border-radius: 5px 5px 0px 0px;
    height: 25px;
    background-color: #5a6978;
    background-color: ${(props) =>
      props.theme.analyze.manualAdjustment.eventDialog.headerBackgroundColor};
  }
`;
const EditIconButton = styled(IconButton)`
  justify-content: flex-end;
`;

const RevertIconButton = styled(IconButton)`
  justify-content: flex-start;
  .revertIcon {
    margin: -14px 7px 0px -14px;
  }
`;

const AddIconButtonContent = styled(IconButtonContent)`
  width: max-content;
`;

const DialogContent = styled.div`
  padding: 12px;
  max-height: 310px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.scrollbar.trackLightBackground};
  }
  &::-webkit-scrollbar-thumb {
    width: 7px;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.scrollbar.thumbBackground};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.scrollbar.thumbActiveBackground};
  }
`;

const DialogGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-bottom: 12px;
`;

const StyledError = styled(ErrorNotification)`
  margin: 0px 0 14px 0px;
`;

const StyledDiv = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledAddDiv = styled.div`
  margin-left: 5px;
`;

export const EventDetailDialog = ({ event, site, isOpen, onClose, portalId, assetEventsKind }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { isAuthorized } = useAuth();

  const isAuthorizedToAddEvent = isAuthorized({
    capabilities: [
      { capability: Capability.CONTRACTUAL_AVAILABILITY_MAL, scopes: [PermissionScope.CREATE] },
    ],
  });

  const isAuthorizedToEditMALEvent = isAuthorized({
    capabilities: [
      { capability: Capability.CONTRACTUAL_AVAILABILITY_MAL, scopes: [PermissionScope.EDIT] },
    ],
  });

  const isAuthorizedToDeleteMALEvent = isAuthorized({
    capabilities: [
      { capability: Capability.CONTRACTUAL_AVAILABILITY_MAL, scopes: [PermissionScope.DELETE] },
    ],
  });

  const { calendarDate, selectedEvent } = useManualAdjustmentContext();
  const [eventsData, setEventsData] = useState([]);
  const [malCategoryData, setMalCategoryData] = useState([]);
  const onSuccess = () => {
    if (eventsData.length > 0) {
      if (event.type === 'IEC') {
        setEventsData([]);
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const {
    deleteEvent,
    isError,
    isLoading: revertLoader,
  } = useDeleteMalEvent({
    siteId: site.id,
    onSuccess,
  });

  const [editMode, setEditMode] = useState(false);
  const [endTime, setEndTime] = useState(false);

  const timezone = useMemo(() => site?.timezone ?? 'UTC', [site]);

  useEffect(() => {
    setEditMode(false);
    setEventsData([]);
    setEndTime(getDayEndTime(event.endTime, timezone, calendarDate));
  }, [event, timezone, calendarDate]);

  const calculateDuration = (startTime, endTime) => {
    const st = dayjs(
      `${dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)} ${dayjs(startTime).format(
        'HH:mm:ss',
      )}`,
    );
    const et = dayjs(
      `${dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM)} ${dayjs(endTime).format(
        'HH:mm:ss',
      )}`,
    );
    const secs = dayjs(et).diff(st, 'seconds', true);
    const mins = dayjs(et).diff(st, 'minutes', true);
    const hours = parseInt(mins / 60);
    let minutes = dayjs().minute(mins).$m;
    let seconds = dayjs().second(secs).$s;
    if (mins < 0) {
      minutes = mins > -60 ? mins : mins % 60;
    }
    seconds = secs < 0 ? -seconds : seconds;
    return `${hours} hr ${minutes} m ${seconds} s`;
  };

  const showMalEvents = useCallback(
    (events, malCategoryData) => {
      setEventsData(events);
      setMalCategoryData(malCategoryData);
    },
    [eventsData],
  );

  const deleteMalEvent = useCallback(() => {
    const malEvents = [];
    if (event.type === 'MAL') {
      malEvents.push({
        assetId: selectedEvent.id,
        day: dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM),
        startTime: dayjs(event.startTime).unix(),
        endTime: dayjs(event.endTime).unix(),
      });
    }
    if (eventsData.length > 0) {
      eventsData.map((event) => {
        malEvents.push({
          assetId: selectedEvent.id,
          day: dayjs(calendarDate).format(DateTimeFormats.ENDPOINT_PARAM),
          startTime: event.startTime,
          endTime: event.endTime,
        });
      });
    }
    deleteEvent({ malEvents });
  }, [eventsData]);

  if (!ready) {
    return <Loader />;
  }

  return (
    <StyledDialog
      isOpen={isOpen}
      onClose={onClose}
      header={t('event_details_dialog.header', 'Event Detail')}
      portalId={portalId}
      padContent={false}
      className={'event-detail-dialog'}
    >
      <DialogContent>
        <ConditionalRender shouldRender={revertLoader}>
          <Loader color="rgba(255, 255, 255, 0.2)" />
        </ConditionalRender>
        <ConditionalRender shouldRender={isError}>
          <StyledError />
        </ConditionalRender>
        <ConditionalRender shouldRender={!editMode}>
          <EventCategory>
            <EventCategoryColor backgroundColor={event.color} />
            <EventCategoryName>
              {t(`legend.${event.a11yKey}.dialog_title`, event.eventCategory)}
            </EventCategoryName>
          </EventCategory>
          <DialogGrid>
            <div>
              <DialogLabel>{t('event_details_dialog.event_code', 'Code / #')}</DialogLabel>
              <DialogValue>
                {event.type === 'IEC' ? event.oemCategory : event.malCategory}
              </DialogValue>
            </div>
            <div>
              <DialogLabel>{t('event_details_dialog.event_title', 'Title')}</DialogLabel>
              <DialogValue>
                {event.type === 'IEC' ? event.oemDescription : event.malCategoryName}
              </DialogValue>
            </div>
          </DialogGrid>
          <DialogGrid2>
            <div>
              <DialogLabel>{t('event_details_dialog.event_duration', 'Duration')}</DialogLabel>
              <DialogValue>
                {calculateDuration(
                  dayjs(event.startTime).tz(timezone),
                  dayjs(endTime).tz(timezone),
                )}
              </DialogValue>
            </div>
            <div>
              <DialogLabel>{t('event_details_dialog.event_start', 'Start')}</DialogLabel>
              <DialogValue>{dayjs(event.startTime).tz(timezone).format('HH:mm:ss')}</DialogValue>
            </div>
            <div>
              <DialogLabel>{t('event_details_dialog.event_end', 'End')}</DialogLabel>
              <DialogValue>{dayjs(endTime).tz(timezone).format('HH:mm:ss')}</DialogValue>
            </div>
          </DialogGrid2>
          <ConditionalRender shouldRender={eventsData.length > 0}>
            <MalEventDetailDialog
              eventsData={eventsData}
              calculateDuration={calculateDuration}
              malCategoryData={malCategoryData}
              timezone={timezone}
              assetEventsKind={assetEventsKind}
            />
          </ConditionalRender>
          <CTAContainer>
            <CTAButtons>
              {isAuthorizedToDeleteMALEvent && (event.type === 'MAL' || eventsData.length > 0) && (
                <div>
                  <RevertIconButton type="button" onClick={deleteMalEvent}>
                    <IconButtonIcon
                      className="revertIcon"
                      icon={Icons.ARROW_RIGHT}
                      size={20}
                      rotate={180}
                    />
                    <IconButtonContent>
                      {t('event_details_dialog.revert_event_button', 'Revert')}
                    </IconButtonContent>
                  </RevertIconButton>
                </div>
              )}
              <StyledDiv>
                {isAuthorizedToEditMALEvent && (
                  <div>
                    <EditIconButton type="button" onClick={() => setEditMode(true)}>
                      <IconButtonIcon icon={Icons.PENCIL} />
                      <IconButtonContent>
                        {t('event_details_dialog.edit_button', 'Edit')}
                      </IconButtonContent>
                    </EditIconButton>
                  </div>
                )}
                {isAuthorizedToAddEvent && (
                  <ConditionalRender shouldRender={eventsData.length > 0}>
                    <StyledAddDiv>
                      <IconButton type="button" onClick={() => setEditMode(true)}>
                        <IconButtonIcon icon={Icons.ADD} />
                        <AddIconButtonContent>
                          {t('event_details_dialog.add_event_button', 'Add Event')}
                        </AddIconButtonContent>
                      </IconButton>
                    </StyledAddDiv>
                  </ConditionalRender>
                )}
              </StyledDiv>
            </CTAButtons>
          </CTAContainer>
        </ConditionalRender>
        <ConditionalRender shouldRender={editMode}>
          <EditEventDetailDialog
            calculateDuration={calculateDuration}
            setEditMode={setEditMode}
            timezone={timezone}
            showMalEvents={showMalEvents}
            assetEventsKind={assetEventsKind}
          />
        </ConditionalRender>
      </DialogContent>
    </StyledDialog>
  );
};

EventDetailDialog.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  site: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  portalId: PropTypes.string,
  assetEventsKind: PropTypes.string,
};
