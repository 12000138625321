import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useState, useMemo, useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { Radio } from '@ge/components/radio/radio';
import { Select } from '@ge/components/select';
import { Textarea } from '@ge/components/textarea/textarea';
import useStateRef from '@ge/hooks/state-ref';
import { SnoozeType, ReasonType } from '@ge/models/constants';
import { DateTimeMetaField } from '@ge/shared/components/meta-fields';
import { toSiteISOString } from '@ge/shared/util';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

const Snoozedialogsegment = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  margin-top: 20px;
  padding-bottom: 10px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const SnoozeFormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 15px;
  &:first-of-type {
    margin-bottom: 6px;
  }
  &.local-time-row {
    justify-content: flex-end;
  }
  &.reason-row {
    > div {
      width: 166px;
    }
  }
  div:first-of-type {
    &.label {
      display: none;
    }
  }
  div > label {
    font-family: Museo Sans;
    font-size: 11px;
    line-height: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.input.labelTextColor};
    margin-bottom: 0;
  }
  &:nth-of-type(1) > div:first-child,
  div:nth-child(3) > div {
    margin: 6px 0px;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: 6px;
  min-width: 140px;
  .select__indicators {
    padding-right: 0px !important;
  }
`;

const SnoozeFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const LocalTime = styled.div`
  display: flex;
  justify-content: flex-end;
  span {
    font: 300 12px/15px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  font: 12px;
  line-height: 14px;
  font-weight: 300;
`;

const StyledLabel = styled.label`
  font-family: Museo Sans;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.input.labelTextColor};
  margin-bottom: 5px;
`;

const formFields = {
  snoozeUntil: 'snooze-until',
  snoozeFor: 'snooze-for',
};

const metadata = {
  defaultSelection: 'currentDate',
  required: true,
  readOnly: false,
  validators: [
    {
      earliestDate: 'currentDate',
    },
  ],
};

export const SnoozeDialog = ({ onClose, onConfirm, siteType, entities }) => {
  const { t } = useTranslation(['entity-details']);

  const entityDetails = {
    assetType: entities[0]?.asset?.type,
    siteType: siteType,
    siteName: entities[0]?.site?.name,
    assetName: entities[0]?.asset?.name,
    entityDescriptionIcon: Icons.EVENT,
    entityDescription: entities[0]?.description,
  };

  const snoozeoptions = useMemo(() => {
    return [
      {
        value: SnoozeType.SNOOZE_30_MINUTES,
        label: t('case_panel.snooze_30_minutes', '30 minutes'),
      },
      { value: SnoozeType.SNOOZE_1_HOUR, label: t('case_panel.snooze_1_hour', '1 hour') },
      { value: SnoozeType.SNOOZE_2_HOUR, label: t('case_panel.snooze_2_hour', '2 hours') },
      { value: SnoozeType.SNOOZE_3_HOUR, label: t('case_panel.snooze_3_hour', '3 hours') },
    ];
  }, [t]);

  const reasonoptions = useMemo(() => {
    return [
      { value: '', label: t('case_panel.select_reason', 'Select reason') },
      {
        value: ReasonType.BATTERIES_CHARGING,
        label: t('case_panel.batteries_charging', 'Batteries Charging'),
      },
      {
        value: ReasonType.COMPONENT_COOLING,
        label: t('case_panel.component_cooling', 'Component Cooling'),
      },
      {
        value: ReasonType.COMPONENT_WARMING,
        label: t('case_panel.component_warming', 'Component Warming'),
      },
      { value: ReasonType.OWNED_BY_SITE, label: t('case_panel.owned_by_site', 'Owned by Site') },
    ];
  }, [t]);

  const [localTime, setLocalTime, localTimeRef] = useStateRef(CheckedState.CHECKED);

  const [radioSelect, setRadioSelect] = useState(formFields.snoozeFor);

  const [snoozeFor, setSnoozeFor] = useState(snoozeoptions[0]);
  const [snoozeReason, setSnoozeReason] = useState(reasonoptions[0]);

  const handleSnoozeFor = useCallback(
    (option) => {
      setSnoozeFor(option);

      if (option) {
        setRadioSelect(formFields.snoozeFor);
      }
    },
    [setSnoozeFor],
  );

  const handleReason = useCallback(
    (option) => {
      setSnoozeReason(option);
    },
    [setSnoozeReason],
  );

  // Configure form hook and define logic for submission.
  const methods = useForm({
    mode: 'onBlur',
  });
  const { control, handleSubmit, register } = methods;

  useWatch({
    control,
    name: 'snoozeUntil',
  });

  const onSubmit = useCallback(
    (values) => {
      let snoozeDateTime;
      if (values?.snoozeTimeType === formFields.snoozeUntil && values?.snoozeUntil) {
        if (localTime === CheckedState.UNCHECKED && entities?.[0]?.site?.timezone) {
          snoozeDateTime = dayjs(
            toSiteISOString(values.snoozeUntil, entities[0].site.timezone),
          ).valueOf();
        } else {
          snoozeDateTime = dayjs(values.snoozeUntil).valueOf();
        }
      } else {
        const snoozeForOption = snoozeFor?.value?.split('-');
        if (snoozeForOption.length) {
          snoozeDateTime = dayjs().add(snoozeForOption[0], snoozeForOption[1]).valueOf();
        }
      }
      onConfirm({
        id: entities?.[0]?.id,
        snoozeTime: snoozeDateTime,
        snoozeReason: snoozeReason?.value,
        snoozeDescription: values?.note,
      });
    },
    [onConfirm, entities, snoozeFor, snoozeReason, localTime],
  );

  const toggleLocalTime = useCallback(() => {
    setLocalTime(
      localTimeRef.current === CheckedState.CHECKED ? CheckedState.UNCHECKED : CheckedState.CHECKED,
    );
  }, [setLocalTime, localTimeRef]);

  const getSnoozeValue = useCallback(
    (value) => {
      if (value) {
        setRadioSelect(formFields.snoozeUntil);
      }
    },
    [setRadioSelect],
  );

  const getFooter = useMemo(() => {
    return (
      <SnoozeFooterWrapper>
        <FooterButtons>
          <Button onClick={onClose}>{t('general:cancel', 'Cancel')}</Button>
          <Button primary onClick={handleSubmit(onSubmit)}>
            {t('general:snooze', 'Snooze')}
          </Button>
        </FooterButtons>
      </SnoozeFooterWrapper>
    );
  }, [onClose, t, handleSubmit, onSubmit]);

  return (
    <>
      <Dialog
        isOpen={true}
        onClose={onClose}
        onConfirm={onConfirm}
        icon={Icons.SNOOZE_BELL}
        header={t('case_panel.snooze', 'Snooze')}
        footer={getFooter}
      >
        {entities?.[0] && (
          <Snoozedialogsegment>
            <EntityDialogHeader entityDetails={entityDetails} />
          </Snoozedialogsegment>
        )}

        <Snoozedialogsegment>
          <FormProvider {...methods}>
            <SnoozeFormRow>
              <Radio
                id={formFields.snoozeFor}
                label=""
                name="snoozeTimeType"
                value={formFields.snoozeFor}
                ref={register}
                checked={radioSelect === formFields.snoozeFor}
                onChange={({ target }) => {
                  setRadioSelect(target?.value);
                }}
              />

              <div>
                <StyledLabel>{t('notes.snooze_for', 'Snooze for')}</StyledLabel>
                <StyledSelect
                  small
                  maxWidth={200}
                  options={snoozeoptions}
                  value={snoozeFor}
                  onChange={handleSnoozeFor}
                  name="snoozefor"
                />
              </div>
              <div>
                <Radio
                  id={formFields.snoozeUntil}
                  label=""
                  name="snoozeTimeType"
                  value={formFields.snoozeUntil}
                  ref={register}
                  checked={radioSelect === formFields.snoozeUntil}
                  onChange={({ target }) => {
                    setRadioSelect(target.value);
                  }}
                />
              </div>
              <div onFocus={getSnoozeValue}>
                <StyledLabel>{t('notes.snooze_until', 'Snooze until')}</StyledLabel>
                <DateTimeMetaField
                  defaultSelection={metadata.defaultSelection}
                  name="snoozeUntil"
                  metadata={metadata}
                  validators={metadata.validators}
                  timezone={
                    localTime === CheckedState.UNCHECKED && entities?.[0]?.site?.timezone
                      ? entities?.[0]?.site?.timezone
                      : ''
                  }
                />
              </div>
            </SnoozeFormRow>
            <SnoozeFormRow className={'local-time-row'}>
              <LocalTime>
                <StyledCheckbox
                  label={t('notes.local_time', 'Local Time')}
                  checkState={localTime}
                  onChange={toggleLocalTime}
                />
              </LocalTime>
            </SnoozeFormRow>
            {/* {entities?.[0]?.asset?.type === AssetType.WIND_TURBINE && ( */}
            <SnoozeFormRow className={'reason-row'}>
              <div>
                <StyledLabel>{t('notes.reason', 'Reason')}</StyledLabel>
                <StyledSelect
                  small
                  maxWidth={200}
                  options={reasonoptions}
                  value={snoozeReason}
                  onChange={handleReason}
                />
              </div>
            </SnoozeFormRow>
            {/* )} */}
            <SnoozeFormRow>
              <Textarea
                id="task-description-textarea"
                label={t('notes.description', 'Description')}
                placeholder={t('notes.note_placeholder', 'Enter note..')}
                name="note"
                ref={register}
              />
            </SnoozeFormRow>
          </FormProvider>
        </Snoozedialogsegment>
      </Dialog>
    </>
  );
};

SnoozeDialog.propTypes = {
  entities: PropTypes.instanceOf(Array).isRequired,
  siteType: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

SnoozeDialog.defaultValues = {
  onClose: () => null,
  onConfirm: () => null,
};
