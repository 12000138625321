import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components';

import { Icon, Icons } from '../icon';

export const ToggleCard = ({ className, title, danger, children, ...props }) => {
  const [setActive, setActiveState] = useState('active');
  const [setHeight, setHeightState] = useState('');
  const [setRotate, setRotateState] = useState('chevron');
  const content = useRef(null);
  const {
    theme: { assetCard },
  } = props;

  function toggleOpen() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'active' ? 'chevron rotate' : 'chevron');
  }

  useEffect(() => {
    setHeightState(setActive === 'active' ? `${content.current.scrollHeight}px` : '0px');
  }, [setActive]);

  return (
    <div className={`${className} ${danger ? 'danger' : ''}`}>
      <div className="title" onClick={toggleOpen} onKeyDown={toggleOpen} role="button" tabIndex="0">
        <Icon
          size={12}
          icon={Icons.SITE}
          className="icon"
          alt="site icon"
          color={danger ? assetCard.dangerColor : assetCard.iconColor}
          danger={danger}
        />
        <h4 className={danger ? 'danger' : ''}>{title}</h4>
        <Icon
          size={12}
          icon={Icons.CHEVRON}
          className={`${setRotate}`}
          color={assetCard.chevronColor}
        />
      </div>
      <div className="content" ref={content} style={{ maxHeight: `${setHeight}` }}>
        {children}
      </div>
    </div>
  );
};

ToggleCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  danger: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  theme: PropTypes.instanceOf(Object).isRequired,
};

ToggleCard.defaultProps = {
  title: '',
  danger: false,
  className: '',
  children: [],
};

const styledCard = styled(ToggleCard)`
  display: block;
  border-radius: 2px 0 0 2px;
  box-shadow: 0 0 0 1px ${(props) => props.theme.assetCard.borderColor},
    0 1px 1px 0 ${(props) => props.theme.assetCard.borderColor};
  border-radius: 3px;
  background: ${(props) => props.theme.assetCard.titleBackgroundColor};

  .title {
    padding: 12px;
    border-bottom: solid 2px ${(props) => props.theme.assetCard.borderColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      vertical-align: text-top;
      margin-top: -2px;
    }
    h4 {
      margin: 0;
      display: inline;
      font-family: 'Museo Sans';
      font-weight: 500;
      line-height: 18px;
      vertical-align: text-top;
      color: ${(props) => props.theme.assetCard.textColor};
      &.danger {
        color: ${(props) => props.theme.assetCard.dangerColor};
      }
    }
    .chevron {
      margin-left: auto;
      transition: transform 0.6s ease;
      &.rotate {
        transform: rotate(-180deg);
      }
    }
  }

  .content {
    background: ${(props) => props.theme.assetCard.contentBackgroundColor};
    color: ${(props) => props.theme.assetCard.textColor};
    transition: max-height 0.6s ease;
    overflow: hidden;
  }
`;

export default withTheme(styledCard);
