import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useRef, useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FlagIcon } from '@ge/components/approval-flag-icon';
import { Badge } from '@ge/components/badge';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Menu, placements } from '@ge/components/menu';
import { WarningDialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { Severity } from '@ge/components/severity';
import {
  ResizableTable,
  DraggableTable,
  DynamicTable,
  EntityCell,
  TableArrow,
  TableArrowButton,
} from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { Tooltip } from '@ge/components/tooltip';
import { useBulkDeleteTask } from '@ge/feat-manage/data-hooks/use-bulk-delete-task';
import { useBulkScheduleTask } from '@ge/feat-manage/data-hooks/use-bulk-schedule-task';
import NotificationMessage from '@ge/feat-monitor/components/notification-message';
import { DateTimeFormats, MaxSelectionLimit, NotificationType } from '@ge/models/constants';
import { DeleteTasksDialog } from '@ge/shared/components/actions-delete-tasks-dialog';
import { BulkEditTaskFlagDialog } from '@ge/shared/components/actions-edit-task-flag-dialog';
import ActionsMenu from '@ge/shared/components/actions-menu';
import { RescheduleTasksDialog } from '@ge/shared/components/actions-reschedule-tasks-dialog';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useActionsMenu } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { ActionKeys, TaskMenuItems } from '@ge/shared/models/actions-menu-items';
import { killEventPropagation } from '@ge/shared/util/general';
import { withDefault } from '@ge/shared/util/table-utils';
import { getTurbineStateColor, getTurbineStateType } from '@ge/shared/util/turbine-state';
import { globalColors } from '@ge/tokens';

import { useColumnDefs } from '../../data-hooks/use-column-defs';
import { TasksColumnDefs, TasksColumns, defaultTasksCols } from '../../models/tasks-table-cols';
import { CrewBadge } from '../crew-badge';
import { NotesMenu } from '../notes-menu';
import { PartsMenu } from '../parts-menu';
import { ResolutionNotesMenu } from '../resolution-notes-menu';

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const StyledDesc = styled.span`
  margin-left: 6px;
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
      font-size: 11px;
    }
  }
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))``;

const ThCheckbox = styled(Checkbox)`
  justify-content: center;

  span {
    display: none;
  }
`;

const StyledTooltip = styled(Tooltip)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.limitSelectAll.backgroundColor};
  color: ${(props) => props.theme.limitSelectAll.tooltipTextColor};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
`;

const StyledWarningDialog = styled(WarningDialog)`
  padding-top: 10px;
  text-align: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.limitSelectAll.backgroundColor};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  position: absolute;
  right: 6px;
  display: inline-flex;
`;

const DataContent = styled.p`
  color: ${(props) => props.theme.limitSelectAll.modalTextColor};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin: 6px 0 0 0;
  font-weight: 600;
`;

const StyledStatus = styled.span`
  text-transform: capitalize;
`;

const LinkedWrapper = styled.div`
  &:hover .arrow-icon {
    transform: rotate(-90deg);
    scale: (1.2);
    fill: ${(props) => props.theme.table.iconHighlightColor};
  }
`;

const DataIcon = styled(Icon)`
  position: absolute;
  bottom: 28px;
  left: 10px;
`;

const CloseIcon = styled(Icon)`
  vertical-align: baseline;
`;

const StyledResizableTable = styled(ResizableTable)`
  tbody td:nth-child(3) {
    padding: 0px;
    div span {
      padding: 12px;
    }
  }
`;

const StyledDraggableTable = styled(DraggableTable)`
  tbody td:nth-child(3) {
    padding: 0px;
    div span {
      padding: 12px;
    }
  }
`;

const NoteIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.NOTE,
  color: props.theme.entityDetails.notes.addNote,
}))``;

const AnomalyIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.ANOMALY,
  color: props.theme.entityDetails.notes.addNote,
}))`
  margin-right: 19px;
`;
const StyledCrewBadges = styled.span`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 150px !important;
  margin-left: 4px;

  & > div {
    margin-left: -4px;
  }
`;
const CrewPills = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

// extract filter defs from column defs to pass up to parent for managing data filtering
export const getDefaultFilterDefs = (columnDefs = TasksColumnDefs) =>
  Object.values(columnDefs).reduce(
    (_filter, { cols }) => ({
      ..._filter,
      ...Object.entries(cols ?? {})
        .filter(([, { filterType }]) => filterType)
        .reduce(
          (_cols, [key, { filterType: type, filters: value }]) => ({
            ..._cols,
            [key]: { type, value },
          }),
          {},
        ),
    }),
    {},
  );

const TasksTable = ({
  tasks,
  columns,
  sortAction,
  sortMetric,
  sortDirection,
  onTaskSelect,
  draggable,
  resizable,
  scrollable,
  onDrop,
  className,
  onFilter,
  onFilterChange,
  filterValues,
  isLoading,
  onSetSelectedTasksToExport,
  filters,
}) => {
  const { t, ready } = useTranslation(['tasks']);
  const columnDefinition = useColumnDefs();
  const {
    showAssetDetails,
    showCaseDetails,
    showTaskDetails,
    showSiteDetails,
    setBulkTask,
    showBulkTaskDetails,
    taskWorkers,
  } = useContext(EntityDetailsContext);

  const containerRef = useRef(null);
  // state
  // arr of task id's for managing selected state on table
  const [rowsSelected, setRowsSelected] = useState([]);

  // arr of task objects to be passed to actions
  const [selectedTasks, setSelectedTasks] = useState([]);
  // Manage the state of the header checkbox and updates to selected rows and tasks
  const [isChecked, setIsChecked] = useState(CheckedState.UNCHECKED);
  const [bulkScheduleTasks, setBulkScheduleTasks] = useState();
  const [bulkDeleteTasks, setBulkDeleteTasks] = useState();
  const [bulkEditTaskFlag, setBulkEditTaskFlag] = useState();
  const [isBulkScheduleError, setIsBulkScheduleError] = useState(false);
  const [noteHover, setNotesHover] = useState({ flag: false, id: null });
  const [anchorEll, setAnchorEll] = useState(null);
  const [selectAllLimitReached, setSelectAllLimitReached] = useState(false);
  const [closeWarningDialog, setCloseWarningDialog] = useState(true);
  const { notify } = useNotification();

  useEffect(() => {
    setRowsSelected(
      tasks.map((task) => rowsSelected.includes(task?.id) && task?.id).filter(Boolean),
    );
    setSelectedTasks(tasks.filter((task) => rowsSelected.includes(task?.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, setRowsSelected, setSelectedTasks]);

  // hooks
  const logger = useLogger();

  // data hooks
  const getAuthorizedMenuItems = useActionsMenu({ menuItems: TaskMenuItems });

  const cleanUpSelectedTasks = useCallback(() => {
    setRowsSelected([]);
    setSelectedTasks([]);
    onSetSelectedTasksToExport([]);
  }, [onSetSelectedTasksToExport]);

  const { bulkSchedule, isLoading: isBulkScheduleLoading } = useBulkScheduleTask({
    onSuccess: () => {
      const message = (
        <NotificationMessage
          message={t('task_notification.tasks_updation', 'Tasks Updated Successfully')}
        />
      );
      const timestamp = <p>DETAILS | {dayjs().format('HH:mm (UTCZ), DD MMM YY')}</p>;
      notify({ message, timestamp });
      setIsBulkScheduleError(false);
      setBulkScheduleTasks();
      setBulkEditTaskFlag();
      cleanUpSelectedTasks();
    },
    onError: (e) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.tasks_update_failed', 'Tasks Update Failed')}
        />
      );
      const timestamp = <p>DETAILS | {dayjs().format('HH:mm (UTCZ), DD MMM YY')}</p>;
      const type = NotificationType.FAILURE;
      notify({ message, timestamp, type });
      setIsBulkScheduleError(true);
      setBulkEditTaskFlag();
      logger.error('Error scheduling', e);
    },
  });

  const { bulkDelete } = useBulkDeleteTask({
    onSuccess: () => {
      setIsBulkScheduleError(false);
      setBulkDeleteTasks();
      cleanUpSelectedTasks();
      const message = (
        <NotificationMessage
          message={t('task_notification.task_deletion', 'Task Deleted Successfully')}
        />
      );
      notify({ message });
    },
    onError: (e) => {
      setIsBulkScheduleError(true);
      logger.error('Error scheduling', e);
    },
  });

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );
  // Mnage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
        setSelectedTasks(selectedTasks?.filter((task) => task.id !== rowId));
        onSetSelectedTasksToExport(selectedTasks?.filter((task) => task.id !== rowId));
      } else {
        if (!selectAllLimitReached) {
          setRowsSelected([...rowsSelected, rowId]);
          const task = tasks?.filter((task) => task.id === rowId);
          const updatedSelectedTasks = [...selectedTasks, ...task];
          const sortedIds = tasks.map((task) => task.id);
          setSelectedTasks(
            updatedSelectedTasks.sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id)),
          );
          onSetSelectedTasksToExport(
            updatedSelectedTasks.sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id)),
          );
        }
      }
    },
    [rowsSelected, selectedTasks, onSetSelectedTasksToExport, tasks, selectAllLimitReached],
  );

  const getCheckState = useCallback(() => {
    if (isChecked === CheckedState.UNCHECKED || isChecked === CheckedState.INDETERMINATE) {
      let computedTasks = [];
      computedTasks = tasks
        .map((task, index) => index < MaxSelectionLimit.TASKS && task)
        .filter(Boolean);
      if (rowsSelected.length > 0) {
        computedTasks = [
          ...selectedTasks,
          ...computedTasks.filter((task) => !rowsSelected.includes(task?.id)),
        ];
        if (computedTasks.length > MaxSelectionLimit.TASKS) {
          computedTasks.splice(MaxSelectionLimit.TASKS);
        }
      }
      setRowsSelected(computedTasks.map((task) => task?.id));
      setSelectedTasks(computedTasks);
      onSetSelectedTasksToExport(computedTasks);
      return setIsChecked(CheckedState.CHECKED);
    }
    cleanUpSelectedTasks();
    return setIsChecked(CheckedState.UNCHECKED);
  }, [
    isChecked,
    onSetSelectedTasksToExport,
    tasks,
    rowsSelected,
    selectedTasks,
    cleanUpSelectedTasks,
  ]);

  // handlers
  const handleAssetClick = useCallback(
    (e, asset) => {
      killEventPropagation(e);
      showAssetDetails(asset.id);
    },
    [showAssetDetails],
  );

  const handleSiteClick = useCallback(
    (e, site) => {
      killEventPropagation(e);
      showSiteDetails(site.id);
    },
    [showSiteDetails],
  );

  const handleMenuAction = useCallback(
    ({ entity, type }) => {
      let label;
      if (entity.asset.id) {
        label = 'ERP';
      } else {
        label = 'task-details';
      }

      if (type === ActionKeys.TASK_SCHEDULE_RESCHEDULE) {
        // only reschedule tasks that are selected and in current view
        const matchedTasks = tasks.filter((x) => selectedTasks.includes(x));
        const tasksToSchedule = matchedTasks?.length ? matchedTasks : [entity];
        setBulkScheduleTasks(tasksToSchedule);
      }
      if (type === ActionKeys.TASK_DELETE) {
        const matchedTasks = tasks.filter((x) => selectedTasks.includes(x));
        const tasksToSchedule = matchedTasks?.length ? matchedTasks : [entity];
        setBulkDeleteTasks(tasksToSchedule);
      }
      if (type === ActionKeys.TASK_CREATE_SERVICE_REQUEST) {
        if (selectedTasks?.length >= 2) {
          showBulkTaskDetails(entity?.id);
        } else if (selectedTasks?.length === 1) {
          selectedTasks.map((task) => {
            showTaskDetails(task?.id, label);
          });
        } else if (isChecked === CheckedState.UNCHECKED) {
          showTaskDetails(entity?.id, label);
        } else {
          return null;
        }
      }
      if (type === ActionKeys.TASK_FLAG_BULK_EDIT) {
        const matchedTasks = tasks.filter((task) => selectedTasks.includes(task));
        const taskFlagToBulkEdit = matchedTasks?.length ? matchedTasks : [entity];
        setBulkEditTaskFlag(taskFlagToBulkEdit);
      }
    },
    [selectedTasks, showTaskDetails, showBulkTaskDetails, isChecked, tasks],
  );
  const CaseTable = useMemo(() => {
    if (resizable) return StyledResizableTable;
    if (draggable) return StyledDraggableTable;
    return DynamicTable;
  }, [resizable, draggable]);

  const handleBulkSchedule = useCallback((data) => bulkSchedule(data), [bulkSchedule]);

  const handleBulkDelete = useCallback(
    (data) => {
      const message = (
        <NotificationMessage
          message={t('task_notification.deleting_task', 'Deleting Task')}
          isProgressLoader={true}
        />
      );
      notify({ message });
      bulkDelete(data), [bulkDelete];
    },
    [bulkDelete, notify, t],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => {
      onFilter(columnKey, value);
    },
    [onFilter],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => onFilterChange({ key, value }),
    [onFilterChange],
  );

  const handleLinkedClick = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showCaseDetails(id);
    },
    [showCaseDetails],
  );

  // Handle change in the table checkbox selections and update on header checkbox
  useEffect(() => {
    if (rowsSelected.length === 0) {
      setIsChecked(CheckedState.UNCHECKED);
      setSelectAllLimitReached(false);
      setCloseWarningDialog(false);
    } else {
      if (rowsSelected.length > 0 && rowsSelected.length < MaxSelectionLimit.TASKS) {
        setIsChecked(CheckedState.INDETERMINATE);
        setSelectAllLimitReached(false);
        setCloseWarningDialog(false);
      } else if (rowsSelected.length === MaxSelectionLimit.TASKS) {
        setIsChecked(CheckedState.CHECKED);
        setSelectAllLimitReached(true);
        setCloseWarningDialog(true);
      }
    }
    setBulkTask(selectedTasks);
  }, [rowsSelected, selectedTasks, setIsChecked, setBulkTask, tasks]);

  const cellValueMapFn = useCallback(
    (taskRow) => {
      if (!taskRow) {
        return {};
      }

      // TODO: Handle open tasks?
      const {
        [TasksColumns.EST_DURATION]: estDuration,
        [TasksColumns.ACTUAL_DURATION]: actDuration,
        id,
      } = taskRow;
      const entities = selectedTasks?.length ? selectedTasks : [taskRow];

      return {
        ...taskRow,
        [TasksColumns.SELECTED]: { id, rowsSelected },
        [TasksColumns.ACTIONS]: getAuthorizedMenuItems(entities),
        [TasksColumns.EST_DURATION]: estDuration?.value,
        [TasksColumns.ACTUAL_DURATION]: actDuration?.value,
      };
    },
    [getAuthorizedMenuItems, rowsSelected, selectedTasks],
  );

  const handleNotes = (e, id) => {
    killEventPropagation(e);
    setNotesHover({ flag: true, id: id });
    setAnchorEll(e.currentTarget);
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    killEventPropagation(e);
  };

  const handleMenuClose = (e) => {
    killEventPropagation(e);
    setAnchorEll(null);
    setNotesHover({ flag: false, id: null });
  };

  /**
   * Factory function to generate custom column header components in
   * the case that the contents are not simply primitives.
   */

  // TODO: figure out why this isnt rendering in the column header
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case TasksColumns.SELECTED:
          return !selectAllLimitReached ? (
            <ThCheckbox checkState={isChecked} onChange={() => getCheckState()} label={''} />
          ) : (
            <>
              <DataIcon size={12} icon={Icons.DATA_NETCOMM} color={globalColors.red2} />
              <ThCheckbox checkState={isChecked} onChange={() => getCheckState()} label={''} />
            </>
          );
        default:
          return null;
      }
    },
    [getCheckState, isChecked, selectAllLimitReached],
  );

  const customCellFn = useCallback(
    (columnKey, cellValue, task) => {
      const { authorization } = task;
      const { canOpenEntityPanel } = authorization;
      const assignedTechs = task?.completedByTech?.map(
        (techs) => taskWorkers && taskWorkers?.find((worker) => worker?.username === techs),
      );
      switch (columnKey) {
        case TasksColumns.SELECTED:
          return !selectAllLimitReached || rowsSelected.includes(task?.id) ? (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue.id);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue.id)
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          ) : (
            <StyledTooltip
              title={t(
                'table.limit_select_all.max_limit_reached',
                'Maximum 100 selection limit reached',
              )}
              placement={placements.RIGHT}
            >
              <StyledCheckbox
                onClick={(e) => {
                  killEventPropagation(e);
                  e.preventDefault();
                  toggleCheckedRow(e, cellValue.id);
                }}
              >
                <Checkbox
                  checkState={
                    rowsSelected.includes(cellValue.id)
                      ? CheckedState.CHECKED
                      : CheckedState.UNCHECKED
                  }
                  label={''}
                />
              </StyledCheckbox>
            </StyledTooltip>
          );
        case TasksColumns.SITE:
          return withDefault(
            cellValue,
            <EntityCell
              authorized={canOpenEntityPanel}
              tooltip={cellValue?.name}
              onClick={(e) => handleSiteClick(e, cellValue)}
            >
              {cellValue?.name}
            </EntityCell>,
          );
        case TasksColumns.ASSET:
          return withDefault(
            cellValue?.name,
            <EntityCell
              authorized={canOpenEntityPanel}
              onClick={(e) => handleAssetClick(e, cellValue)}
            >
              {cellValue?.name}
            </EntityCell>,
          );
        case TasksColumns.STATE:
          return withDefault(
            cellValue,
            <Badge
              color={getTurbineStateColor(cellValue)}
              label={getTurbineStateType(cellValue)}
              fullWidth
            />,
          );
        case TasksColumns.PRIORITY:
          return <Severity level={cellValue.toLowerCase()} />;
        case TasksColumns.FLAG:
          return withDefault(cellValue, <FlagIcon value={cellValue} size={11} />);
        case TasksColumns.DESCRIPTION:
          return (
            <TooltipCell tooltip={cellValue}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case TasksColumns.SOURCE:
          return (
            <TooltipCell tooltip={cellValue}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case TasksColumns.WORK_SCOPE:
          return (
            <TooltipCell tooltip={cellValue}>
              <StyledDesc>{cellValue}</StyledDesc>
            </TooltipCell>
          );
        case TasksColumns.LINKED:
          return withDefault(
            cellValue,
            <LinkedWrapper onClick={(e) => handleLinkedClick(e, cellValue.id)}>
              <AnomalyIcon />
              <TableArrow className="arrow-icon" />
            </LinkedWrapper>,
          );
        case TasksColumns.STATUS:
          return <StyledStatus>{cellValue}</StyledStatus>;
        case TasksColumns.ASSIGNED_TECHS:
          return withDefault(
            assignedTechs,
            <StyledCrewBadges>
              <CrewPills>
                {assignedTechs?.length > 0 && !assignedTechs?.includes(undefined) ? (
                  assignedTechs?.map((technician) => (
                    <TooltipCell
                      key={technician?.id}
                      tooltip={technician?.firstName + ' ' + technician?.lastName}
                    >
                      <CrewBadge
                        key={technician?.id}
                        technician={technician}
                        overlap
                        taskTable={true}
                      />
                    </TooltipCell>
                  ))
                ) : (
                  <span>-</span>
                )}
              </CrewPills>
            </StyledCrewBadges>,
          );
        case TasksColumns.PARTS_CONSUMED:
          return withDefault(
            cellValue,
            <PartsMenu
              titleKey="parts_consumed"
              titleDefault="Parts Consumed"
              parts={cellValue?.parts}
            >
              <StyledBadge small label={cellValue?.count.toString()} className="badge" />
            </PartsMenu>,
          );
        case TasksColumns.PARTS_EXPECTED:
          return withDefault(
            cellValue,
            <PartsMenu
              titleKey="parts_expected"
              titleDefault="Parts Expected"
              parts={cellValue?.parts}
            >
              <StyledBadge small label={cellValue?.count.toString()} className="badge" />
            </PartsMenu>,
          );
        case TasksColumns.CREATED_DATE:
        case TasksColumns.ELIGIBLE_START_DATE:
        case TasksColumns.SCHEDULED_DATE:
        case TasksColumns.DUE_DATE:
        case TasksColumns.STARTED_DATE_TIME:
        case TasksColumns.COMPLETED_DATE_TIME:
        case TasksColumns.COMMITTED_DATE:
          return withDefault(
            cellValue,
            <StyledTimeDate>
              <span>{dayjs(cellValue).format(DateTimeFormats.DEFAULT_DATE)}</span>
            </StyledTimeDate>,
          );
        case TasksColumns.ACTIONS:
          return (
            <ActionsMenu
              containerRef={containerRef}
              disabled={rowsSelected.length > 0 && !rowsSelected.includes(task.id)}
              entity={task}
              menuItems={cellValue}
              onAction={handleMenuAction}
            />
          );
        case TasksColumns.NOTES:
          return (
            <>
              <div onMouseEnter={(e) => handleNotes(e, task.id)} onMouseLeave={handleMenuClose}>
                <NoteIcon />
              </div>
            </>
          );
        case TasksColumns.RESOLUTION:
          return withDefault(
            cellValue,
            <ResolutionNotesMenu
              titleKey="resolution_notes"
              titleDefault="Resolution Notes"
              notes={cellValue}
            >
              <NoteIcon />
            </ResolutionNotesMenu>,
          );
        case TasksColumns.DETAIL:
          return <TableArrowButton className="row-hover-icon" />;
        default:
          return null;
      }
    },
    [
      taskWorkers,
      rowsSelected,
      handleMenuAction,
      toggleCheckedRow,
      handleSiteClick,
      handleAssetClick,
      handleLinkedClick,
      selectAllLimitReached,
      t,
    ],
  );

  /**
   * Bootstrap table factories
   */
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: columnDefinition,
    cellValueMapFn,
    customHeaderFn,
    customCellFn,
    sortAction,
    sortedDirection,
    draggable,
    toggleCheckedRow,
    handleAssetClick,
    filterValues,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters,
  });
  if (!columns || columns.length === 0) {
    return null;
  }

  const dynamicProps = {
    scrollable,
    columns,
    columnGroupFactory,
    columnFactory,
    cellFactory,
    className,
    sortAction,
    values: tasks,
    onValueSelect: onTaskSelect,
    rowKeyProperty: 'id',
    dropHandler: draggable ? onDrop : () => null,
    rowsSelected,
    isLoading,
    draggable,
  };

  if (!ready) {
    return <Loader />;
  }

  return (
    <PageContainer i18nReady={ready}>
      <CaseTable {...dynamicProps} />
      {Boolean(bulkScheduleTasks?.length) && (
        <RescheduleTasksDialog
          tasks={bulkScheduleTasks}
          onClose={(e) => {
            killEventPropagation(e);
            setBulkScheduleTasks();
            setIsBulkScheduleError(false);
          }}
          onSaveAll={handleBulkSchedule}
          onRemoveTasks={(ids) => {
            setBulkScheduleTasks((prev) =>
              prev ? prev.filter((task) => !ids.includes(task.id)) : prev,
            );
            setRowsSelected((prev) => prev.filter((_id) => !ids.includes(_id)));
            setSelectedTasks((prev) => prev.filter((task) => !ids.includes(task.id)));
            onSetSelectedTasksToExport((prev) => prev.filter((task) => !ids.includes(task.id)));
          }}
          shiftCount={0}
          isError={isBulkScheduleError}
        />
      )}
      {Boolean(bulkDeleteTasks?.length) && (
        <DeleteTasksDialog
          tasks={bulkDeleteTasks}
          onClose={(e) => {
            killEventPropagation(e);
            setBulkDeleteTasks();
          }}
          onSaveAlls={handleBulkDelete}
        />
      )}
      {Boolean(bulkEditTaskFlag?.length) && (
        <BulkEditTaskFlagDialog
          tasks={bulkEditTaskFlag}
          onClose={(e) => {
            killEventPropagation(e);
            setBulkEditTaskFlag();
          }}
          onSaveAll={handleBulkSchedule}
          isBulkScheduleLoading={isBulkScheduleLoading}
          setBulkEditTaskFlag={setBulkEditTaskFlag}
        />
      )}
      {selectAllLimitReached && (
        <StyledWarningDialog
          isOpen={closeWarningDialog}
          contentWidth
          padContent={true}
          portalId=".cases-tasks-table"
        >
          <CloseButton onClick={() => setCloseWarningDialog(false)}>
            <Icon icon={Icons.CLOSE} size={12} color={globalColors.slate4} />
          </CloseButton>
          <CloseIcon size={14} icon={Icons.DATA_NETCOMM} color={globalColors.red2} />
          <DataContent>
            {t(
              'table.limit_select_all.max_tasks_selected',
              'Only 100 Tasks can be selected at a time',
            )}
          </DataContent>
        </StyledWarningDialog>
      )}
      <Menu
        anchorEl={anchorEll}
        open={Boolean(anchorEll)}
        container={containerRef}
        onClose={handleMenuClose}
        onClick={handleMenuClick}
        placement={placements.LEFT_END}
        offset={2}
        width={300}
      >
        <NotesMenu titleKey="notes_expected" titleDefault="Notes" taskId={noteHover.id} />
      </Menu>
    </PageContainer>
  );
};

TasksTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  onTaskSelect: PropTypes.func,
  onDrop: PropTypes.func,
  scrollable: PropTypes.bool,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValues: PropTypes.object,
  isLoading: PropTypes.bool,
  onSetSelectedTasksToExport: PropTypes.func,
  filters: PropTypes.object,
};

TasksTable.defaultProps = {
  columns: defaultTasksCols,
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  onTaskSelect: () => null,
  onDrop: () => null,
  scrollable: true,
  draggable: true,
  resizable: false,
  onFilter: () => null,
  onFilterChange: () => null,
  filterValues: {},
  tasks: [],
  filters: null,
};

export default TasksTable;
