import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';

// All available columns and groups for completed work
export const OpenCasesColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  GROUP_ASSET_DETAILS: 'Asset Details',
  ASSET_NAME: 'Asset Name',

  // Case Details
  GROUP_CASE_DETAILS: 'Case Details',
  CASE_PRIORITY: 'Priority',
  CASE_STATUS: 'Status',
  CASE_TITLE: 'Case Title',
  CASE_DESCRIPTION: 'Description',
  STATUS_DETAIL: 'Status Details',
  SOURCE: 'Source',

  // Dates
  GROUP_DATES: 'Dates',
  DATES_CREATED: 'Created',
  DATES_LAST_FLAGGED: 'Last Flagged',
  DATES_FIXED: 'Fixed',
  DATES_CLOSED: 'Closed',

  // Notes
  GROUP_NOTES: TableBaseCols.GROUP_NOTES,
  NOTES_LATEST_NOTE: TableBaseCols.NOTES_LATEST_NOTE,
  NOTES_EXTERNAL: TableBaseCols.NOTES_EXTERNAL,
  NOTES_INTERNAL: TableBaseCols.NOTES_INTERNAL,
};

// Completed work table configuration
export const openCasesColumnDefs = {
  [OpenCasesColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [OpenCasesColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'cases.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  [OpenCasesColumns.GROUP_ASSET_DETAILS]: {
    fixedLeft: true,
    width: '50px',
    labels: [
      {
        a11yKey: 'cases.columns.asset_details',
        a11yDefault: 'Asset Details',
      },
    ],
    cols: {
      [OpenCasesColumns.ASSET_NAME]: {
        locked: true,
        fixedLeft: true,
        cell: {
          width: '100px',
        },
        labels: [
          {
            a11yKey: 'cases.columns.asset_name',
            a11yDefault: 'Asset',
          },
        ],
      },
    },
  },
  [OpenCasesColumns.GROUP_CASE_DETAILS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'cases.columns.case_details',
        a11yDefault: 'Case Details',
      },
    ],
    cols: {
      [OpenCasesColumns.CASE_PRIORITY]: {
        labels: [
          {
            a11yKey: 'cases.columns.priority',
            a11yDefault: 'Priority',
          },
        ],
      },
      [OpenCasesColumns.CASE_TITLE]: {
        labels: [
          {
            a11yKey: 'cases.columns.case_title',
            a11yDefault: 'Case Title',
          },
        ],
      },
      [OpenCasesColumns.SOURCE]: {
        labels: [
          {
            a11yKey: 'cases.columns.source',
            a11yDefault: 'Source',
          },
        ],
      },
      [OpenCasesColumns.CASE_STATUS]: {
        labels: [
          {
            a11yKey: 'cases.columns.status',
            a11yDefault: 'Status',
          },
        ],
      },
      [OpenCasesColumns.STATUS_DETAIL]: {
        labels: [
          {
            a11yKey: 'cases.columns.status_details',
            a11yDefault: 'Status Details',
          },
        ],
      },
      [OpenCasesColumns.CASE_DESCRIPTION]: {
        cell: {
          width: '200px',
          maxWidth: '200px',
        },
        labels: [
          {
            a11yKey: 'cases.columns.description',
            a11yDefault: 'Description',
          },
        ],
      },
    },
  },
  [OpenCasesColumns.GROUP_DATES]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'cases.columns.dates',
        a11yDefault: 'Dates',
      },
    ],
    cols: {
      [OpenCasesColumns.DATES_CREATED]: {
        labels: [
          {
            a11yKey: 'cases.columns.created',
            a11yDefault: 'Created',
          },
        ],
      },
      [OpenCasesColumns.DATES_LAST_FLAGGED]: {
        labels: [
          {
            a11yKey: 'cases.columns.last_flagged',
            a11yDefault: 'Last Flagged',
          },
        ],
      },
      [OpenCasesColumns.DATES_FIXED]: {
        labels: [
          {
            a11yKey: 'cases.columns.fixed',
            a11yDefault: 'Fixed',
          },
        ],
      },
      [OpenCasesColumns.DATES_CLOSED]: {
        labels: [
          {
            a11yKey: 'cases.columns.closed',
            a11yDefault: 'Closed',
          },
        ],
      },
    },
  },

  [OpenCasesColumns.GROUP_NOTES]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'cases.columns.notes',
        a11yDefault: 'Notes',
      },
    ],
    cols: {
      [OpenCasesColumns.NOTES_LATEST_NOTE]: {
        labels: [
          {
            a11yKey: 'cases.columns.latest_note',
            a11yDefault: 'Latest Note',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const openCasesOmittedCols = [
  OpenCasesColumns.GROUP_SELECTED,
  OpenCasesColumns.SELECTED,
  // OpenCasesColumns.CASE_STATUS,
];

export const openCasesCustomColConfigs = [OpenCasesColumns.NOTES_LATEST_NOTE];

// Default order and visibility configuration for completed work columns
// NOTE: For state selection, EVERY column must have a position and visibility default!
export const openCasesDefaultCols = [
  {
    id: OpenCasesColumns.GROUP_SELECTED,
    cols: [
      {
        id: OpenCasesColumns.SELECTED,
        visible: true,
        excludeFromCount: true,
      },
    ],
  },
  {
    id: OpenCasesColumns.GROUP_ASSET_DETAILS,
    cols: [
      {
        id: OpenCasesColumns.ASSET_NAME,
        visible: true,
        excludeFromCount: true,
      },
    ],
  },
  {
    id: OpenCasesColumns.GROUP_CASE_DETAILS,
    cols: [
      {
        id: OpenCasesColumns.CASE_TITLE,
        visible: false,
      },
      {
        id: OpenCasesColumns.ASSET_NAME,
        visible: true,
      },
      {
        id: OpenCasesColumns.CASE_PRIORITY,
        visible: true,
      },
      {
        id: OpenCasesColumns.SOURCE,
        visible: true,
      },
      {
        id: OpenCasesColumns.CASE_STATUS,
        visible: true,
      },
      {
        id: OpenCasesColumns.STATUS_DETAIL,
        visible: false,
      },
      {
        id: OpenCasesColumns.CASE_DESCRIPTION,
        visible: true,
      },
    ],
  },
  {
    id: OpenCasesColumns.GROUP_DATES,
    ignoreTemplateConfig: true,
    cols: [
      {
        id: OpenCasesColumns.DATES_CREATED,
        visible: true,
      },
      {
        id: OpenCasesColumns.DATES_LAST_FLAGGED,
        visible: false,
        ignoreTemplateConfig: true,
      },
      {
        id: OpenCasesColumns.DATES_FIXED,
        visible: false,
        ignoreTemplateConfig: true,
      },
      {
        id: OpenCasesColumns.DATES_CLOSED,
        visible: false,
        ignoreTemplateConfig: true,
      },
    ],
  },
  {
    id: OpenCasesColumns.GROUP_NOTES,
    ignoreTemplateConfig: true,
    cols: [
      {
        id: OpenCasesColumns.NOTES_LATEST_NOTE,
        visible: false,
        ignoreTemplateConfig: true,
      },
      {
        id: OpenCasesColumns.NOTES_INTERNAL,
        visible: false,
        excludeFromCount: true,
        ignoreTemplateConfig: true,
      },
      {
        id: OpenCasesColumns.NOTES_EXTERNAL,
        visible: false,
        excludeFromCount: true,
        ignoreTemplateConfig: true,
      },
    ],
  },
];
