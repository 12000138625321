import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PresentationTypes } from '@ge/models/constants';

export const usePresentationType = (active = true) => {
  const { search } = useLocation();
  return useMemo(() => {
    if (!active) return PresentationTypes.LIST;

    const params = new URLSearchParams(search);
    return params.get('view') ?? PresentationTypes.LIST;
  }, [active, search]);
};
