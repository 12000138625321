import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { DashboardConditionType } from '@ge/feat-analyze/models/constants';
import { downloadFile } from '@ge/feat-analyze/util';
import { EntityType, QueryKey, DateTimeFormats, ContentType, FileType } from '@ge/models/constants';

import { useGlobalFilters } from '../hooks';

import { getFilename } from './use-export-graph';

/**
 * To fetch condition data for download
 */
export const useConditionDataDownload = () => {
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });

  const queryClient = useQueryClient();
  const _filters = useGlobalFilters();

  const getAssetsBySiteId = useStoreState((state) => state.assets.getAssetsBySiteId);

  const downloadPNG = () => {};

  const downloadCSV = useCallback(
    ({ entity, entityType, graph }) => {
      if (!entity || !entityType || !graph) return null;
      const { endDate, startDate } = graph.metadata ?? {};

      const value = graph.value;
      const entityId = entity.id;
      const assetIds = getAssetsBySiteId(entityId)?.map(({ id }) => id);
      const filters =
        entityType === EntityType.ASSET
          ? { entityIds: entityId ? [entityId] : assetIds }
          : _filters;
      const fields = [
        entityType === EntityType.ASSET
          ? t('asset_name', 'Asset Name')
          : t('site_name', 'Site Name'),
        t('date_time', 'Date Range'),
        value === DashboardConditionType.TEMPERATURE
          ? t('conditions.temperature_x_axis_title', 'Temperature (°C)')
          : t('conditions.wind_speed_x_axis_title', 'Wind Speed (m/s)'),
        t('conditions.y_axis_title', 'Distribution (%)'),
        ...(value !== DashboardConditionType.TEMPERATURE
          ? [t('conditions.average_wind_speed', 'Wind Speed (m/sec)')]
          : []),
      ];

      let data = [];

      const datarange = t('from_date_to_date', {
        from: dayjs(startDate).format(DateTimeFormats.DEFAULT_DATE),
        to: dayjs(endDate).format(DateTimeFormats.DEFAULT_DATE),
      });
      const queryKey = [
        QueryKey.ASSET_CONDITION_DATA,
        {
          endDate,
          entityId,
          entityType,
          filters,
          startDate,
        },
      ];
      const queries = queryClient.getQueryCache().findAll(queryKey, { active: true });
      const queryData = queries.reduce((result, { state }) => {
        return !state.error && state.data
          ? {
              ...result,
              ...state.data.data?.find((data) => {
                return data.site?.id === entityId || data.turbine?.id === entityId;
              }),
            }
          : result;
      }, {});
      const res = queryData?.[value];

      if (value === DashboardConditionType.WIND_DIRECTION_AND_SPEED) {
        fields.splice(3, 0, t('conditions.nacelle_direction', 'Nacelle Direction'));
        data = res
          ? Object.keys(res).reduce((result, direction) => {
              const values = Object.keys(res[direction]).map((bucket) => [
                entity.name,
                datarange,
                bucket,
                t(`conditions.cardinal.${direction}`, direction),
                res[direction][bucket] * 100,
                queryData.windSpeedAverage,
              ]);
              return result.concat(values);
            }, [])
          : [];
      } else {
        data =
          res?.map(({ bucket, distribution }) => [
            entity.name,
            datarange,
            bucket,
            distribution * 100,
            ...(value !== DashboardConditionType.TEMPERATURE ? [queryData.windSpeedAverage] : []),
          ]) ?? [];
      }

      const filename = getFilename(entity, graph, FileType.CSV);

      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [_filters, queryClient, t],
  );

  return {
    downloadPNG,
    downloadCSV,
  };
};
