import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker } from '@ge/components/datepicker';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input/input';
import { Select } from '@ge/components/select';
import { Text } from '@ge/components/typography';
import { DateTimeFormats } from '@ge/models/constants';
import { addUTCOffset } from '@ge/shared/util/time-date';
import { globalColors, typography } from '@ge/tokens';

import { getDatesDistribution } from '../util/distribution';

dayjs.extend(isBetween);

const DistributeFormContainer = styled.div`
  overflow-y: auto;
  height: ${(props) => (props.isCalendarOpen ? '390px' : '290px')};
`;

const ErrorText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dangerColor};
  display: block;
  margin-top: 6px;
  text-transform: capitalize;
`;

const RangeToggle = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 15px;

  span {
    display: block;
    height: 13px;
    width: 70px;
    color: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.grey4}`};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
    text-transform: none;
    margin-bottom: 5px;
  }

  button {
    padding: 3px 0px;
    margin-right: 8px;
    font-size: 13px;
    width: 20px;
    text-align: center;
    color: ${(props) => props.theme.manage.rangeToggle.textColor};
    background: ${(props) => props.theme.manage.rangeToggle.backgroundColor};
    border: solid 1px ${(props) => props.theme.manage.rangeToggle.borderColor};
    font-weight: ${typography.weight.medium};
    &:focus {
      outline: none;
    }
    &.active {
      background: ${(props) => props.theme.manage.rangeToggle.activeBackgroundColor};
      border-color: ${(props) => props.theme.manage.rangeToggle.activeBorderColor};
      font-weight: ${typography.weight.bold};
    }
  }
`;

const PreviewButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  color: ${globalColors.white};
  box-sizing: border-box;
  height: 25px;
  width: 80px;
  border: 1px solid ${(props) => props.theme.select.primaryBorder};
  border-radius: 4px;
  background: ${(props) => props.theme.select.primaryBackground2};

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const StyledDateLabel = styled.span`
  height: 13px;
  width: 51px;
  color: ${(props) =>
    props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.slate4}`};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
`;

const StyledInput = styled(Input)`
  width: 120px;
`;

const StyledSelect = styled(Select)`
  width: 120px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  label {
    height: 13px;
    width: 87px;
    color: ${globalColors.grey4};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
  }
`;

const ContentWrapper = styled.div`
  font-family: ${typography.family.default};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  label {
    display: block;
    color: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.grey2}` : `${globalColors.grey4}`};
    margin-bottom: ${(props) => props.userLang !== 'en' && '14px'};
    text-transform: capitalize;
  }
  .date-picker input {
    margin-top: 3px;
    padding: 0;
    height: 22px;
    width: 120px;
    border: 1px solid ${globalColors.grey9};
    background-color: ${(props) =>
      props.theme.name === 'Light' ? `${globalColors.white}` : `${globalColors.slate1}`};
  }

  button {
    align-self: flex-end;
  }

  .preview-btn-container {
    display: flex;
    justify-content: end;
    margin-top: 12.5px;
  }
`;

const DatePickerIcon = styled(Icon).attrs(({ theme }) => ({
  size: 12,
  color: theme.analyze.header.calendarIconColor,
  icon: Icons.CALENDAR,
}))`
  margin-left: -20px;
  vertical-align: text-top;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.dialog.taskReschedule.spacerBorderColor};
  margin-top: 18.5px;
`;

const ParamValues = {
  START: 'start',
  END: 'end',
  PER_DAY: 'perDay',
};

export const DistributeTasksForm = ({ triggerValidation, tasks, setTaskValues }) => {
  const { t } = useTranslation(['manage.cases-tasks']);
  const getProfileLang = useStoreState((state) => state.prefs.language);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [days, setDays] = useState([0, 1, 2, 3, 4, 5, 6]);

  const DAYS = [
    t('dayInitials.sunday', 'S'),
    t('dayInitials.monday', 'M'),
    t('dayInitials.tuesday', 'T'),
    t('dayInitials.wednesday', 'W'),
    t('dayInitials.thursday', 'T'),
    t('dayInitials.friday', 'F'),
    t('dayInitials.saturday', 'S'),
  ];

  const [FormOptions] = useState({
    P1: [
      { value: ParamValues.START, label: t('start_date', 'Start Date') },
      { value: ParamValues.END, label: t('end_date', 'End Date') },
    ],
    [ParamValues.START]: [
      { value: ParamValues.END, label: t('end_date', 'End Date') },
      { value: ParamValues.PER_DAY, label: t('tasks_per_day', 'Tasks per Day') },
    ],
    [ParamValues.END]: [{ value: ParamValues.PER_DAY, label: t('tasks_per_day', 'Tasks per Day') }],
  });

  const {
    handleSubmit: handleSubmitDistribute,
    control: distributeControl,
    watch,
    setValue,
    getValues,
    errors,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      param1: FormOptions.P1[0],
      param2: FormOptions[ParamValues.START][0],
      start: '',
      end: '',
      perDay: '',
    },
  });

  const watchParam1 = watch('param1');
  const watchParam2 = watch('param2');

  const formatDate = (date) => (date ? dayjs(date).format(DateTimeFormats.NEW_TASK) : null);

  const incrementDate = (date, param1Val = ParamValues.START) =>
    param1Val === ParamValues.START ? dayjs(date).add(1, 'day') : dayjs(date).subtract(1, 'day');

  const handlePerDayDist = useCallback(
    (data, param1Val) => {
      const { start, end, perDay } = data;
      let count = 0;
      let date = dayjs(param1Val === ParamValues.START ? start : end);

      // get day of the week
      let dayOfWeek = date.day();

      // loop through the tasks
      for (const i in tasks) {
        //get task
        const task = tasks[i];

        //if tasks per day limit reached add day
        if (count >= perDay) {
          date = incrementDate(date, param1Val);
          dayOfWeek = date.day();
          count = 0;
        }

        //find next available date
        while (!days.includes(dayOfWeek)) {
          date = incrementDate(date, param1Val);
          dayOfWeek = date.day();
        }

        // set task schedule date and update count
        setTaskValues(`${task.id}.scheduleDate`, addUTCOffset(date, task.site?.timezone).toDate());
        count++;
      }

      triggerValidation();
    },
    [tasks, days, triggerValidation, setTaskValues],
  );

  const getDatesArr = useCallback(
    (start, end) => {
      let Arr = [];
      let dayOfWeek = dayjs(start).day();
      let date = dayjs(start).toDate();

      // days between start and end date + 1 to include the last day
      const daysBetween = dayjs(end).diff(dayjs(start), 'd') + 1;

      for (let i = 0; i < daysBetween; i++) {
        if (!days.includes(dayOfWeek)) {
          date = incrementDate(date);
          dayOfWeek = date.day();
          continue;
        }

        Arr.push(date);
        date = incrementDate(date);
        dayOfWeek = date.day();
      }
      return Arr;
    },
    [days],
  );

  const matchTasksWithDates = useCallback(
    (distArrIndex, numTasksAllowed, datesArr, tasks) => {
      for (let i = 0; i < numTasksAllowed; i++) {
        const task = tasks[i];
        const distDate = datesArr[distArrIndex];
        const distDateUTCOffset = addUTCOffset(distDate, task.site?.timezone).toDate();

        // set task schedule date with timezone and update count
        setTaskValues(`${task.id}.scheduleDate`, distDateUTCOffset);
      }
    },
    [setTaskValues],
  );

  const assignTasksToDates = useCallback(
    (distributionArr, datesArr) => {
      // example: distributionArr = [3, 2, 3] (8 tasks over 3 days: day 1 = 3 tasks, day 2 = 2 tasks, day 3 = 3 tasks)
      // example: datesArr = [dates available to assign tasks to]

      let lastTaskIndexUsed = 0;
      // loop through distribution array then match tasks with dates
      for (let x = 0; x < distributionArr.length; x++) {
        const numTasksAllowed = distributionArr[x];
        const newLastIndex = lastTaskIndexUsed + numTasksAllowed;
        const slicedTasks = tasks.slice(lastTaskIndexUsed, newLastIndex);

        matchTasksWithDates(x, numTasksAllowed, datesArr, slicedTasks);
        lastTaskIndexUsed = newLastIndex;
      }
    },
    [matchTasksWithDates, tasks],
  );

  const handleStartEndDist = useCallback(
    (data) => {
      const { start, end } = data;
      const datesArr = getDatesArr(start, end); // build out array of available dates
      const distributionArr = getDatesDistribution(datesArr, tasks); // build out date distribution array

      assignTasksToDates(distributionArr, datesArr);
      triggerValidation();
    },
    [getDatesArr, tasks, assignTasksToDates, triggerValidation],
  );

  const handleDistribute = useCallback(
    (data) => {
      const param1Val = data.param1.value;
      const param2Val = data.param2.value;

      if (param2Val === ParamValues.PER_DAY) {
        handlePerDayDist(data, param1Val);
      }

      if (param2Val === ParamValues.END) {
        handleStartEndDist(data);
      }
    },
    [handlePerDayDist, handleStartEndDist],
  );

  useEffect(() => {
    setValue('param2', FormOptions[watchParam1.value][0]);
  }, [watchParam1, setValue, FormOptions]);

  const toggleDay = (e, arr, day) => {
    e.preventDefault();
    var idx = arr.indexOf(day);
    setDays(idx > -1 ? arr.filter((d) => d !== day) : [...arr, day]);
  };

  return (
    <DistributeFormContainer isCalendarOpen={isCalendarOpen}>
      <StyledForm userLang={getProfileLang}>
        <ContentWrapper>
          <InputContainer>
            <Controller
              control={distributeControl}
              name="param1"
              render={({ onChange, value }) => (
                <StyledSelect
                  minWidth={120}
                  onChange={onChange}
                  options={FormOptions.P1}
                  value={value}
                  label={t('select_parameter', 'Select Parameter')}
                />
              )}
            />

            <Controller
              control={distributeControl}
              name="param2"
              render={({ onChange, value }) => (
                <StyledSelect
                  minWidth={120}
                  onChange={onChange}
                  options={FormOptions[watchParam1.value]}
                  value={value}
                  label={t('select_parameter', 'Select Parameter')}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <Controller
              control={distributeControl}
              name={watchParam1.value}
              rules={{
                required: true,
              }}
              render={({ onChange, value }, { invalid }) => (
                <DatePicker
                  onCalendarOpen={() => {
                    setIsCalendarOpen(true);
                  }}
                  onCalendarClose={() => {
                    setIsCalendarOpen(false);
                  }}
                  customInput={
                    <div className="date-picker">
                      <StyledDateLabel>
                        {t(`${watchParam1.value}_date`, `${watchParam1.value} Date'`)}
                      </StyledDateLabel>
                      <StyledInput
                        onChange={() => null}
                        value={value ? formatDate(value) : ''}
                        error={invalid ? 'error' : null}
                      />
                      <DatePickerIcon />

                      {errors[watchParam1.value]?.type === 'required' && (
                        <ErrorText>
                          {t(
                            `${watchParam1.value}_date_reqired`,
                            `${watchParam1.value} date reqired`,
                          )}
                          {}
                        </ErrorText>
                      )}
                    </div>
                  }
                  onChange={onChange}
                  selected={value || null}
                />
              )}
            />
            {watchParam2.value === ParamValues.END && (
              <Controller
                control={distributeControl}
                name={ParamValues.END}
                rules={{
                  required: true,
                  validate: {
                    isBeforeStart: (val) =>
                      dayjs(getValues('start'))
                        .startOf('d')
                        .isBefore(dayjs(val).endOf('d')),
                  },
                }}
                render={({ onChange, value }) => (
                  <DatePicker
                    onCalendarOpen={() => {
                      setIsCalendarOpen(true);
                    }}
                    onCalendarClose={() => {
                      setIsCalendarOpen(false);
                    }}
                    customInput={
                      <div className="date-picker">
                        <StyledDateLabel>{t('end_date', 'End Date')}</StyledDateLabel>
                        <StyledInput onChange={() => null} value={value ? formatDate(value) : ''} />
                        <DatePickerIcon />
                        {errors[ParamValues.END]?.type === 'required' && (
                          <ErrorText>{t('end_date_required', 'End date reqired')}</ErrorText>
                        )}
                        {errors[ParamValues.END]?.type === 'isBeforeStart' && (
                          <ErrorText>
                            {t('error_end_before_start', 'End Date is before Start Date')}
                          </ErrorText>
                        )}
                      </div>
                    }
                    onChange={onChange}
                    selected={value || null}
                  />
                )}
              />
            )}
            {watchParam2.value === ParamValues.PER_DAY && (
              <div>
                <Controller
                  control={distributeControl}
                  name={ParamValues.PER_DAY}
                  rules={{
                    required: true,
                  }}
                  render={({ onChange, value, ref }, { invalid }) => (
                    <>
                      <StyledInput
                        value={value}
                        type="number"
                        onChange={onChange}
                        min={1}
                        name="shiftCount"
                        error={invalid ? 'error' : null}
                        ref={ref}
                        label={t('tasks_per_day', 'Tasks per Day')}
                      />
                      {errors[ParamValues.PER_DAY]?.type === 'required' && (
                        <ErrorText>{t('tasks_required', 'Task count required')}</ErrorText>
                      )}
                    </>
                  )}
                />
              </div>
            )}
          </InputContainer>
          <RangeToggle>
            <span>{t('apply_on', 'Apply on')}*</span>
            {DAYS.map((day, idx) => (
              <button
                key={`${idx}-day`}
                onClick={(e) => toggleDay(e, days, idx)}
                className={days?.includes(idx) ? 'active' : null}
              >
                {day}
              </button>
            ))}
          </RangeToggle>
        </ContentWrapper>
        <Divider />
        <div className="preview-btn-container">
          <PreviewButton primary onClick={handleSubmitDistribute(handleDistribute)}>
            {t('preview', 'Preview')}
          </PreviewButton>
        </div>
      </StyledForm>
    </DistributeFormContainer>
  );
};

DistributeTasksForm.propTypes = {
  triggerValidation: PropTypes.func,
  tasks: PropTypes.instanceOf(Array).isRequired,
  setTaskValues: PropTypes.func,
};

DistributeTasksForm.defaultValues = {
  onShift: () => null,
  setTaskValues: () => null,
};
