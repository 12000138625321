import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Loader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { useNotification } from '@ge/components/notification';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import {
  useCreateCaseAnalysisTemplate,
  useCaseAnalysisTemplateQuery,
  useCaseAnalysisTemplateDetails,
} from '@ge/feat-analyze/data-hooks/use-case-analysis-template-details';
import { NAMESPACE, Fields, PlotFields } from '@ge/feat-analyze/models/configure-analysis-template';
import { EntityMode } from '@ge/models/constants';

import { FormErrorMessage } from './../../shared';
import AnalysisTemplateCharts from './analysis-template-charts';
import OverviewDetails from './analysis-template-overview-details';

const Container = styled.div`
  ${({ isLoading }) => (isLoading ? 'position: relative' : '')}
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterButtons = styled.div`
  margin-left: auto;
  button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  overflow: hidden;
  min-width: 72vh;
  min-height: 490px;
  max-height: calc(100% - 250px);
  [data-simplebar='init'] {
    padding-right: 10px;
  }
  ${scrollbarWrapperOverride}
`;

const DialogFooter = ({ onCancel, onSave, isLoading }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  if (!ready) return null;

  return (
    <FooterWrapper>
      <FooterButtons>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('analysis_template.cancel', 'Cancel')}
        </Button>
        <Button primary onClick={onSave} disabled={isLoading}>
          {t('analysis_template.save', 'Save & Exit')}
        </Button>
      </FooterButtons>
    </FooterWrapper>
  );
};

export const NewAnalysisTemplateDialog = ({ isOpen, onClose, onConfirm }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { isLoading: isLoadingSites } = useStoreState((state) => state.sites);
  const { isLoading: isLoadingAssets } = useStoreState((state) => state.assets);

  const { notify } = useNotification();

  const {
    mutate: create,
    isLoading: isCreating,
    isError,
    error,
  } = useCreateCaseAnalysisTemplate({
    enabled: true,
    onSuccess: (res) => {
      onConfirm(res);
      notify({
        message: t('analysis_template_created_success', 'Analysis Template Created Successfully'),
      });
    },
  });

  const methods = useForm({ mode: 'onBlur' });
  const { watch } = methods;

  const selectedAssetTypeId = watch(Fields.ASSET_TYPE_ID);
  const selectedSignalMapIds = watch(Fields.SIGNAL_MAPS);

  const {
    isLoading: isLoadingSignals,
    assetSignalMap,
    signalMappings,
  } = useCaseAnalysisTemplateQuery({
    isActive: isOpen,
  });

  const { signalMaps, signalData } = useCaseAnalysisTemplateDetails({
    assetSignalMap,
    signalMappings,
    selectedAssetTypeId,
    selectedSignalMapIds,
  });

  const isLoading = isCreating || isLoadingSites || isLoadingAssets || isLoadingSignals;

  const onSubmit = useCallback(
    (values) => {
      const payload = {
        [Fields.ANALYSIS_TEMPLATE_ID]: values[Fields.ANALYSIS_TEMPLATE_ID],
        // This is for future implementation
        // [Fields.IS_DEFAULT_TEMPLATE]: values[Fields.IS_DEFAULT_TEMPLATE],
        [Fields.PLOTS]: values[Fields.PLOTS].map((value) => ({
          [PlotFields.PLOT_ID]: value[PlotFields.PLOT_ID],
          [PlotFields.PLOT_TYPE]: value[PlotFields.PLOT_TYPE],
          [PlotFields.SIGNALS_Y]: value[PlotFields.SIGNALS_Y],
          [PlotFields.SIGNAL_X]: value[PlotFields.SIGNAL_X],
        })),
      };
      create(payload);
    },
    [create],
  );

  if (!ready) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header={t('analysis_template.title', 'New Analysis Template')}
      footer={
        <DialogFooter
          isLoading={isLoading}
          onCancel={onClose}
          onSave={methods.handleSubmit(onSubmit)}
        />
      }
      contentWidth={true}
      padContent={false}
    >
      <FormProvider {...methods}>
        <ScrollingWrapper>
          <ScrollingContainer>
            <Container isLoading={isLoading}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <OverviewDetails
                    isLoading={isLoadingSignals}
                    signalMaps={signalMaps}
                    entityMode={EntityMode.CREATE}
                  />
                  <AnalysisTemplateCharts isLoading={isLoadingSignals} signalData={signalData} />
                  <FormErrorMessage isError={isError} message={error?.message} />
                </>
              )}
            </Container>
          </ScrollingContainer>
        </ScrollingWrapper>
      </FormProvider>
    </Dialog>
  );
};

DialogFooter.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

DialogFooter.defaultValues = {
  isLoading: false,
  onCancel: () => {},
  onSave: () => {},
};

NewAnalysisTemplateDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

NewAnalysisTemplateDialog.defaultValues = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
  selectedEntityId: undefined,
};
