import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DateTimeFormats, TaskTemplateModes } from '@ge/models/constants';

import { useServiceGroups } from '../../../../../features/manage/util/worker-util';
import { SelectMetaField } from '../../meta-fields';

const StyledSelect = styled(SelectMetaField)`
  .select__menu {
    .select__menu-list {
      max-height: 62px;
      &::-webkit-scrollbar {
        width: 4px;
        height: 0px;
      }
      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.scrollbar.trackBackground};
      }
      &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollbar.thumbBackground};
        border-radius: 2.5px;
      }
    }
  }
`;

export const AssigneeSelect = ({ defaultValue, metadata, task, templateMode, disabled }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const [selectedCrew, setDefaultCrew] = useState(defaultValue);

  const { watch } = useFormContext();
  const watchScheduleDate = watch('scheduleDate');

  const { crewsSg } = useStoreState((state) => state.workers);

  const serviceGroups = useServiceGroups();

  const assigneeSelectOptions = useMemo(() => {
    if (watchScheduleDate) {
      const scheduleDate = dayjs(watchScheduleDate).format(DateTimeFormats.ENDPOINT_PARAM);

      const taskScheduledDate = task?.scheduleDateTz
        ? dayjs(task?.scheduleDateTz).format(DateTimeFormats.CREW_TIMING)
        : null;
      if (scheduleDate !== taskScheduledDate) {
        setDefaultCrew(null);
      }
      return crewsSg
        ?.map(
          (crew) =>
            watchScheduleDate &&
            scheduleDate &&
            dayjs(scheduleDate).isBetween(
              dayjs(crew.crewStartDate),
              dayjs(crew.crewEndDate),
              null,
              '[]',
            ) && {
              value: crew?._id,
              label: crew?.name,
            },
        )
        .filter((crew) => crew);
    } else {
      return [];
    }
  }, [crewsSg, task, watchScheduleDate]);

  const assignedCrew = assigneeSelectOptions.find((val) => val.value === selectedCrew);

  return (
    <div>
      <StyledSelect
        defaultValue={selectedCrew}
        name="assignee"
        label={t('form.assignee', 'Assignee')}
        metadata={metadata}
        minWidth={150}
        options={
          templateMode === TaskTemplateModes.CREATE || TaskTemplateModes.EDIT
            ? serviceGroups.length === 1
              ? assigneeSelectOptions
              : []
            : assigneeSelectOptions
        }
        placeholder={
          serviceGroups.length > 1 && assigneeSelectOptions.length > 1
            ? assignedCrew?.label
            : t('form.unassigned', 'Unassigned')
        }
        disabled={serviceGroups.length > 1 || disabled}
      />
    </div>
  );
};

AssigneeSelect.propTypes = {
  metadata: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  task: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
  disabled: PropTypes.bool,
};
