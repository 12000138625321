const { groupById, sortedArrByElement } = require('./array-utils');
const {
  getGridIncrement,
  mergeOptionsRight,
  resolveMergedOptions,
  selectBy,
} = require('./chart-utils');
const { maxDayDiff } = require('./date-utils');
const { exportAsImage } = require('./download-image-of-element');
const { hexRgb } = require('./hex-rgb');
const {
  roundNumber,
  formatNumber,
  getFixed,
  getMinMax,
  getPercent,
  getPercentLeftOffset,
} = require('./number-utils');

module.exports = {
  roundNumber,
  getFixed,
  formatNumber,
  getGridIncrement,
  getMinMax,
  getPercent,
  getPercentLeftOffset,
  groupById,
  mergeOptionsRight,
  resolveMergedOptions,
  selectBy,
  maxDayDiff,
  sortedArrByElement,
  hexRgb,
  exportAsImage,
};
