import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Chart, ChartType } from '@ge/components/charts';
import { DashStyleType } from '@ge/components/charts/models';
import { roundNumber } from '@ge/util';
import { isEmpty } from '@ge/util/object-utils';

import { NoDataAvailable } from '../../no-data-available/no-data-available';

const AverageLineLabel = styled.div`
  color: ${({ theme }) => theme.createReport.widget.averageWindSpeed.lineLabelColor};
  font-size: 11px;
`;

const AverageLineDisplayLine = styled.span`
  border: 1px solid ${({ theme }) => theme.createReport.widget.averageWindSpeed.lineColor};
  box-sizing: border-box;
  display: inline-block;
  height: 2px;
  margin: 0 3px 0 10px;
  vertical-align: middle;
  width: 13px;
`;

const AverageLineDisplay = styled.span`
  color: ${({ theme }) => theme.createReport.widget.averageWindSpeed.lineColor};
  text-transform: uppercase;
`;

const AverageLineValue = styled.span`
  color: ${({ theme }) => theme.createReport.widget.averageWindSpeed.lineUnitsColor};
  padding-left: 5px;
`;

export const AverageWindSpeedWidget = ({ data }) => {
  const { t } = useTranslation(['reporting.widgets', 'general']);

  const { percent, title, units, xAxisTitle, yAxisTitle, averages, windSpd } = {
    percent: t('average_wind_speed.percent', '%'),
    title: t('average_wind_speed.wind_speed', 'Wind Speed'),
    units: t('average_wind_speed.meters_per_second', 'm/sec'),
    xAxisTitle: t('average_wind_speed.x_axis_title', 'Wind Speed (m/sec)'),
    yAxisTitle: t('average_wind_speed.y_axis_title', 'Distribution (%)'),
    averages: t('average_wind_speed.averages', 'Averages'),
    windSpd: t('average_wind_speed.wind_spd', 'Wind Spd'),
  };

  const tooltipHeader = `<h4>${title}</h4>`;
  const tooltipPointFormatter = ({ x, y }) => `
        <div class='body-4'>
          ${x}${units}, ${y}${percent}
        </div>
      `;

  const seriesData = [];

  if (!data || isEmpty(data) || isEmpty(data.entityData)) {
    return <NoDataAvailable />;
  }

  for (const [, value] of Object.entries(data?.entityData ?? {})) {
    value.forEach(({ bucket, distribution }) => {
      seriesData.push({
        x: Number(bucket),
        y: roundNumber(distribution * 100, 1),
      });
    });
  }

  const averageValue = data?.aggregateValue && roundNumber(data.aggregateValue, 2);

  const series = [
    {
      data: seriesData,
      tooltipHeader,
      tooltipPointFormatter,
    },
  ];

  const avgLine = [
    {
      dashStyle: DashStyleType.SHORT_DASH,
      value: averageValue,
    },
  ];

  return (
    <>
      <AverageLineLabel>
        {averages}
        <AverageLineDisplayLine />
        <AverageLineDisplay>{windSpd}</AverageLineDisplay>
        <AverageLineValue>
          {averageValue} {units}
        </AverageLineValue>
      </AverageLineLabel>
      <Chart
        height={140}
        series={series}
        type={ChartType.AREA_SPLINE}
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        xAxisPlotLines={avgLine}
        disableSideMargin
        spacingRight={0}
      />
    </>
  );
};

AverageWindSpeedWidget.propTypes = {
  data: PropTypes.instanceOf(Object),
};
