import styled from 'styled-components';

export const MainWrapper = styled.div`
  background: ${(props) => props.theme.admin.panel.entityRow.light};
  .person-assets-loader-manual-styles {
    height: 100px;
    top: 50%;
    width: 200px;
    margin-left: -90px;
    z-index: 1000;
  }

  .person-assets-scroll-loader-manual-styles {
    height: 50px;
    width: 50px;
    z-index: 1000;
  }

  .person-assets-content {
    width: 100%;
  }
  .person-assets-title {
    margin-top: 20px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
  }
  .person-assets-view-mode {
    padding-left: 10px;
    z-index: 2;
  }
  .person-assets-container {
    width: 100%;
    padding: 20px 10px 20px 10px;
    overflow: hidden;
  }
  .person-assets-frame {
    width: 94%;
    height: 100%;
    position: relative;
  }
  .person-assets-header-container {
    display: grid;
    grid-template-columns: 95% 5%;
    margin-top: 10px;
  }
  .person-assets-text-m {
    margin-top: 5px;
  }
  .person-assets-one {
    justify-self: start;
    align-self: center;
  }
  .person-assets-two {
    justify-self: end;
    align-self: center;
    cursor: pointer;
  }

  .person-assets-assets {
    width: 30%;
    margin-top: 10px;
    margin-left: 70%;
    text-align: center;
  }

  .person-assets-group-buttons {
    display: grid;
    width: 100%;
    height: 24px;
    grid-template-columns: 50% 50%;
    margin-top: 20px;
  }

  .person-assets-group-buttons-left-items {
    justify-self: start;
    align-self: center;
    width: 70px;
    height: 100%;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
  .person-assets-group-button-right-items {
    justify-self: end;
    align-self: center;
    width: 140px;
    height: 100%;
    font-size: 12px;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
  }

  .person-assets-list-container-view {
    display: grid;
    grid-template-columns: 2.5% 76.7% 10%;
    padding: 12px 0px 12px 8px;
  }

  .person-assets-sites-list-container {
    display: grid;
    grid-template-columns: 4.5% 2.5% 70%;
    padding: 12px 0px 12px 8px;
  }

  .person-assets-table-frame {
    overflow-y: auto;
    height: 340px;
  }

  .person-assets-no-records {
    margin-top: 40px;
    text-align: center;
  }

  .person-assets-assets-caret-curson-pointer {
    cursor: pointer;
  }

  .person-assets-assets-text-margin {
    margin-top: 2px;
  }
  .person-assets-assets-center-text {
    text-align: center;
  }

  .person-assets-list-container {
    display: grid;
    grid-template-columns: 3.5% 4.5% 2.5% 70% 10%;
    padding: 12px 0px 12px 8px;
  }

  .person-assets-delete-dots {
    list-style-type: none;
    padding: 0px 0px 0px 2px;
  }
  .person-assets-align-self {
    align-self: center;
  }

  .person-assets-sites-sublist {
    padding: 14px 0px 12px 49px;
    width: 100%;
    display: grid;
    grid-template-columns: 3.7% 71.7% 10%;
  }

  .all-user-roles-grid {
    display: grid;
    grid-template-columns: 4% 70%;
  }

  .all-user-roles-grid-leftItem {
    justify-self: start;
    align-self: center;
  }
  .all-user-roles-grid-right-item {
    justify-self: start;
    align-self: center;
  }

  .person-assets-sites-filter-content {
    margin-top: 30px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .person-assets-sites-filter-right-item {
    justify-self: end;
  }

  .person-assets-sites-filter-right-item-second {
    justify-self: end;
    align-self: end;
  }

  .person-assets-sites-filter-button {
    text-indent: -70px;
    width: 40px;
  }

  .worker-assets {
    font-size: 12px;
    margin-top: 20px;
  }

  .worker-assets-subtitle {
    margin-top: 4px;
  }
`;
