import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SpinLoader } from '@ge/components/loader';
import { typography } from '@ge/tokens';

const StyledMessage = styled.div`
  font-weight: ${typography.weight.bold};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  ${({ isProgressLoader }) =>
    isProgressLoader &&
    `margin: -9px 0;
  `}
`;

const StyledSpinLoader = styled(SpinLoader)`
  display: unset;
  position: unset;
  margin-top: 0;
`;

const NotificationMessage = ({ message, isProgressLoader }) => {
  return (
    <StyledMessage isProgressLoader={isProgressLoader}>
      <span>{message}</span>
      <StyledSpinLoader showLoader={isProgressLoader} />
    </StyledMessage>
  );
};

NotificationMessage.propTypes = {
  message: PropTypes.string,
  isProgressLoader: PropTypes.bool,
};

NotificationMessage.defaultProps = {
  isProgressLoader: false,
};

export default NotificationMessage;
