import { useStoreActions, useStoreState } from 'easy-peasy';
import pathOr from 'ramda/src/pathOr';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Alert } from '@ge/components/modal';
import { SidebarViews } from '@ge/models/constants';

import Alerts from './alerts';
import AlertSettings from './settings/alert-settings';
import WatchlistSettings from './settings/watchlist-settings';
import Watchlist from './watchlist';

const PanelPositioningCss = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: visibility 0.5s ease, opacity 0.5s ease;
`;

// Style the sidebar container
const StyledSidebar = styled.aside`
  position: relative;
  transition: flex ease 0.5s;
  flex: ${(props) => (props.settings ? '0 1 480px' : '0 1 180px')};
  order: 2;
  color: ${(props) => props.theme.layout.textColor};
  background: ${(props) => props.theme.layout.sidebarBackgroundColor};
  box-shadow: ${(props) => props.theme.layout.sidebarBoxShadow};
  .container-title {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    h3 {
      font-size: 11px;
      font-weight: 700;
    }
    .settings-btn {
      margin-left: auto;
      margin-right: 8px;
      padding: 0;
    }
    .settings-title {
      font-size: 22px;
    }
  }
  .lists {
    ${PanelPositioningCss}
    opacity: ${(props) => (props.settings ? '0' : '1')};
    visibility: ${(props) => (props.settings ? 'hidden' : 'visible')};
  }
  .settings {
    ${PanelPositioningCss}
    opacity: ${(props) => (props.settings ? '1' : '0')};
    visibility: ${(props) => (props.settings ? 'visible' : 'hidden')};
  }
`;

const Sidebar = () => {
  const { t } = useTranslation();
  const { alerts } = useStoreState((state) => state.alerts);
  const { watchlist } = useStoreState((state) => state.watchlist);

  const { fetchAlerts } = useStoreActions((actions) => actions.alerts);
  const { fetchWatchlist } = useStoreActions((actions) => actions.watchlist);
  const [alertsToDisplay, setAlertsToDisplay] = useState(null);
  const [watchlistToDisplay, setWatchlistToDisplay] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [activeSettings, setActiveSettings] = useState('');
  const [showModal, setShowModal] = useState(false);

  const componentRef = useRef(null);

  // Reference to handler for a document event to determine if we should close the theme selector.
  const handleEvent = useRef(null);

  // Update the alerts
  useEffect(() => {
    setAlertsToDisplay(Object.values(alerts));
  }, [alerts]);

  // Update the watchlist
  useEffect(() => {
    setWatchlistToDisplay(pathOr([], ['statuses'], watchlist));
  }, [watchlist]);

  // Bootstrap required state.
  useEffect(() => {
    fetchAlerts();
    fetchWatchlist();
  }, [fetchAlerts, fetchWatchlist]);

  const handleSettingsOpen = (type) => {
    setActiveSettings(type);
    setShowSettings(true);

    // Event handler for this open panel
    handleEvent.current = (e) => {
      if ((e.keyCode && e.keyCode === 27) || !componentRef.current.contains(e.target)) {
        setShowModal(true);
      }
    };

    // Add event handler to document events
    document.addEventListener('mousedown', handleEvent.current, false);
  };

  const handleSettingsClose = () => {
    document.removeEventListener('mousedown', handleEvent.current, false);
    setShowSettings(false);
    setShowModal(false);
    setActiveSettings('');
  };

  return (
    <StyledSidebar settings={showSettings} ref={componentRef}>
      <div className="lists">
        <Alerts
          alerts={alertsToDisplay}
          onShowSettings={() => {
            handleSettingsOpen(SidebarViews.ALERT);
          }}
        />
        <Watchlist
          statuses={watchlistToDisplay}
          onShowSettings={() => {
            handleSettingsOpen(SidebarViews.WATCHLIST);
          }}
        />
      </div>
      <div className="settings">
        {activeSettings === SidebarViews.ALERT && (
          <AlertSettings onHideSettings={handleSettingsClose} />
        )}
        {activeSettings === SidebarViews.WATCHLIST && (
          <WatchlistSettings onHideSettings={handleSettingsClose} />
        )}
      </div>

      {/* Alert for abandoning changes */}
      <Alert isOpen={showModal} onClose={handleSettingsClose} onConfirm={handleSettingsClose}>
        {t('sidebar.modal_warning', 'Do you want to save your updates?')}
      </Alert>
    </StyledSidebar>
  );
};

export default Sidebar;
