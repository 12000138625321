// TODO: look into whether we want initial blocking load or use skeleton loaders in ui

import { PropTypes } from 'prop-types';
import React, { Suspense } from 'react';

import { FullLoader } from '@ge/components/loader';
import { useInitialStaticData } from '@ge/shared/data-hooks';

export const StaticDataLoader = ({ block, children }) => {
  // data hooks
  const { isLoaded } = useInitialStaticData();

  return !block || isLoaded ? (
    <Suspense fallback={<FullLoader />}>{children}</Suspense>
  ) : (
    <FullLoader />
  );
};

StaticDataLoader.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

StaticDataLoader.defaultProps = {
  block: false,
};
