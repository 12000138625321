import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import useAssetInfoData from '@ge/feat-analyze/data-hooks/use-asset-info-data';
import { DataLoaderType } from '@ge/models/constants';
import { useComponentHierarchy } from '@ge/shared/data-hooks';
import { globalColors, typography } from '@ge/tokens';

import { TabDetailContainer } from '../../entity-details-shared';
import { AssetComponentHierarchy } from '../asset-component-hierarchy';
import { AssetInfoDetails } from '../asset-info-details';

const AssetContainer = styled.div`
  display: flex;
`;

const StyledAssetInfo = styled.div`
  background: ${(props) => props.theme.entityDetails.sitePanelBlockBackground};
  border-radius: 3px;
  margin: 10px 10px 18px 3px;
  padding: 5px 5px 5px 0px;
  position: relative;
  flex: 1;
  > div {
    margin-bottom: 0px;
    padding-left: 0;
  }
`;

const AssetInfoContainer = styled.div`
  height: 270px;
  width: 700px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.themeSelector.panelBackgroundColor};
  padding: 10px;
`;

const AssetInfoHeader = styled.div`
  height: 21px;
  width: 75px;
  color: ${({ theme }) => theme.themeSelector.tabsTextColor};
  font-family: ${typography.family.secondary};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 16px;
  padding-left: 19px;
  padding-bottom: 20px;
`;

const AssetHeaderBottom = styled.div`
  height: 7px;
  width: 745px;
  background-color: ${globalColors.green1};
  margin-left: 18px;
`;

const HierarchyHeader = styled.div`
  height: 21px;
  width: 97px;
  color: ${({ theme }) => theme.themeSelector.tabsTextColor};
  font-family: ${typography.family.secondary};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  padding: 35px 0 10px 29px;
`;

const getChildComponent = (componentData) => {
  let level2Children = [];
  let level3Children = [];

  componentData.map((component) => {
    component.children.map((data) => {
      level2Children.push(data);
      return data['children'].map((child) => level3Children.push(child));
    });
    return level2Children;
  });
  return { level2Children, level3Children };
};

export const AssetInfo = ({ entity }) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const [level2Component, setlevel2Component] = useState();
  const [level3Component, setlevel3Component] = useState();

  const { data, isLoading: assetInfoLoading } = useAssetInfoData(entity.id);
  const { data: componentHierarchy, isLoading: componentHierarchyLoading } = useComponentHierarchy([
    entity.id,
  ]);

  const { level2Children, level3Children } = useMemo(
    () => (componentHierarchy ? getChildComponent(componentHierarchy) : []),
    [componentHierarchy],
  );

  useEffect(() => {
    if (level2Children) setlevel2Component(level2Children);
    if (level3Children) setlevel3Component(level3Children);
  }, [level2Children, level3Children]);

  const handleComponentClick = useCallback((data, level) => {
    if (level === 'level1') {
      setlevel2Component(data.children);
      setlevel3Component();
    }
    if (level === 'level2') {
      setlevel3Component(data.children);
    }
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <TabDetailContainer>
      <AssetContainer>
        <StyledAssetInfo>
          <AssetInfoContainer>
            <AssetInfoHeader>{t('asset_panel.asset_info', 'Asset Info')}</AssetInfoHeader>
            <AssetHeaderBottom />
            <DataLoader
              isLoading={assetInfoLoading}
              type={DataLoaderType.TABLE}
              renderCondition={true}
              margin="10px"
            >
              <AssetInfoDetails entity={entity} entityData={data} />
            </DataLoader>
          </AssetInfoContainer>
        </StyledAssetInfo>
      </AssetContainer>
      <HierarchyHeader>{t('asset_panel.components', 'Components')}</HierarchyHeader>
      <DataLoader
        isLoading={componentHierarchyLoading}
        type={DataLoaderType.TABLE}
        renderCondition={true}
        margin="10px"
      >
        <AssetComponentHierarchy
          onComponentSelect={handleComponentClick}
          componentList={data?.componentList}
          componentHierarchy={componentHierarchy}
          level2Component={level2Component}
          level3Component={level3Component}
        />
      </DataLoader>
    </TabDetailContainer>
  );
};

AssetInfo.propTypes = {
  entity: PropTypes.instanceOf(Object),
};
