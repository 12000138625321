import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { AttributeGroups, EntityAll } from '@ge/models/constants';
import { typography } from '@ge/tokens';
import { StatusColor } from '@ge/tokens/colors';
import { globalColors } from '@ge/tokens/colors/colors';

export const Header = styled.h3`
  font-weight: ${typography.weight.bold};
  padding-top: 10px;
  text-transform: uppercase;
`;

export const Description = styled.p`
  margin-top: 5px;
  &.roles {
    margin-bottom: 20px;
  }
`;

export const DetailContainer = styled.div`
  height: 100%;
`;

export const SectionContainer = styled.section`
  height: calc(100vh - 95px);
  position: relative;
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormLoading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  height: 200px;
  background-color: ${(props) => props.theme.admin.panel.entityRow.light};
  transform: translate(-50%, -50%);
  z-index: 50;
`;

export const FormError = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 100px;
  width: 275px;
  font-weight: ${typography.weight.bold};
  color: ${StatusColor.DANGER};
  padding: 20px 30px;
  background-color: ${globalColors.slate1};
  transform: translateX(-50%);
  z-index: 50;
`;

export const SectionHeader = styled.h3`
  line-height: 13px;
  margin-bottom: 3px;
  &:last-of-type {
    margin-top: 10px;
  }
`;

export const AdminCollapsiblePanel = styled(CollapsiblePanel)`
  border-top: ${(props) => props.theme.admin.panel.body.border} 1px solid;
  margin: 0;
  padding: 15px 0 18px 0;
  &.expanded {
    background-color: ${(props) => props.theme.admin.panel.body.panelHighlight};
  }

  .title {
    align-items: flex-start;
    border: 0;
    margin-left: 5px;
    svg {
      fill: ${(props) => props.theme.admin.panel.body.collapseArrow};
    }
    h4 {
      flex-grow: 1;
      margin: 3px;
      text-transform: uppercase;
    }
    p {
      color: ${(props) => props.theme.admin.panel.body.subHeader};
      margin: 0 0 0 3px;
    }
  }
`;

export const essentialAttributeBuilder = (values, foundPerson) => {
  let essentialAttributes = [];

  if (values.firstName !== foundPerson.firstName) {
    essentialAttributes.push({
      path: '/firstName',
      value: values.firstName,
      op: 'REPLACE',
    });
  }

  if (values.lastName !== foundPerson.lastName) {
    essentialAttributes.push({
      path: '/lastName',
      value: values.lastName,
      op: 'REPLACE',
    });
  }

  if (values.email !== foundPerson.email) {
    essentialAttributes.push({
      path: '/email',
      value: values.email,
      op: 'REPLACE',
    });
  }

  if (values.functionalSSO && values.functionalSSO !== foundPerson.functionalSSO) {
    essentialAttributes.push({
      path: '/functionalSSO',
      value: values.functionalSSO,
      op: 'REPLACE',
    });
  }

  if (values.phone !== '' && values.phone !== foundPerson.phoneNumber) {
    essentialAttributes.push({
      path: '/phoneNumber',
      value: values.phone.split('-').join(''),
      op: 'REPLACE',
    });
  }

  if (values.initials !== '' && values.initials !== foundPerson.initials) {
    essentialAttributes.push({
      path: '/initials',
      value: values.initials,
      op: 'REPLACE',
    });
  }

  if (values.jobTitle && values.jobTitle !== foundPerson.title) {
    essentialAttributes.push({
      path: '/title',
      value: values.jobTitle,
      op: 'REPLACE',
    });
  }

  return essentialAttributes;
};

export const roleAppender = (currentRoles, personAttributes, attributeGroups) => {
  if (attributeGroups === AttributeGroups.SITES) {
    const addedSites = currentRoles
      .flatMap((role) => {
        let existingRole = personAttributes.entity_group.find(
          (attribute) => attribute.roleName === role.name,
        );
        let siteGroup = existingRole?.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SITES,
        );
        //check for any update happened for ALL if role has access to ALL
        if (siteGroup?.userAccess && siteGroup.userAccess === EntityAll.ALL && role.isDynamicAll) {
          return '';
        } else if (role.isDynamicAll) {
          return {
            path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
            value: 'ALL',
            op: 'ADD',
          };
        } else {
          const existingSites = personAttributes.entity_group
            .filter((attribute) => attribute.roleName === role.name)[0]
            ?.groupCombination.filter((group) => group.groupName === AttributeGroups.SITES);
          const existingRoleSites = existingSites?.[0]?.ids ?? [];
          return {
            path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
            value: role.entities?.site?.filter((id) => !existingRoleSites.includes(id)).toString(),
            op: 'ADD',
          };
        }
      })
      .filter((role) => role.value !== '' && role.value !== undefined);
    return addedSites;
  }

  if (attributeGroups === AttributeGroups.SERVICE_GROUP) {
    const addedServiceGroups = currentRoles
      .flatMap((role) => {
        const existingGroups = personAttributes.entity_group
          .filter((attribute) => attribute.roleName === role.name)[0]
          ?.groupCombination.filter((group) => group.groupName === AttributeGroups.SERVICE_GROUP);

        const existingRoleGroups = existingGroups?.[0]?.ids ?? [];
        return {
          path: `/attributes/entity_group/${role.name}/groupCombination/service_group/ids`,
          value: role.entities?.serviceGroup
            ?.filter((id) => !existingRoleGroups.includes(id))
            .toString(),
          op: 'ADD',
        };
      })
      .filter((role) => role.value !== '' && role.value !== undefined);
    return addedServiceGroups;
  }
};

export const roleDeducter = (currentRoles, personAttributes, attributeGroups) => {
  if (attributeGroups === AttributeGroups.SITES) {
    const sitesRemoved = currentRoles
      .flatMap((role) => {
        let existingRole = personAttributes.entity_group.find(
          (attribute) => attribute.roleName === role.name,
        );
        let siteGroup = existingRole?.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SITES,
        );
        const existingSites = personAttributes.entity_group
          .filter((attribute) => attribute.roleName === role.name)[0]
          ?.groupCombination.filter((group) => group.groupName === AttributeGroups.SITES);
        const existingRoleSites = existingSites?.[0]?.ids ?? [];

        if (siteGroup?.userAccess && siteGroup.userAccess === EntityAll.ALL && !role.isDynamicAll) {
          return {
            path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
            value: 'ALL',
            op: 'REMOVE',
          };
        } else if (!siteGroup?.userAccess && role.isDynamicAll) {
          return {
            path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
            value: existingRoleSites.map((site) => site).toString(),
            op: 'REMOVE',
          };
        } else if (!siteGroup?.userAccess && !role.isDynamicAll) {
          return {
            path: `/attributes/entity_group/${role.name}/groupCombination/sites/ids`,
            value: existingRoleSites.filter((id) => !role.entities?.site?.includes(id)).toString(),
            op: 'REMOVE',
          };
        } else {
          return '';
        }
      })
      .filter((role) => role.value !== '' && role.value !== undefined);
    return sitesRemoved;
  }

  if (attributeGroups === AttributeGroups.SERVICE_GROUP) {
    const serviceGroupsRemoved = currentRoles
      .flatMap((role) => {
        const existingGroups = personAttributes.entity_group
          .filter((attribute) => attribute.roleName === role.name)[0]
          ?.groupCombination.filter((group) => group.groupName === AttributeGroups.SERVICE_GROUP);
        const existingRoleGroups = existingGroups?.[0]?.ids ?? [];

        let existingRole = personAttributes.entity_group.find(
          (attribute) => attribute.roleName === role.name,
        );
        let siteGroup = existingRole?.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SITES,
        );

        if (!role.isDynamicAll && !siteGroup?.userAccess) {
          return {
            path: `/attributes/entity_group/${role.name}/groupCombination/service_group/ids`,
            value: existingRoleGroups
              .filter((id) => !role.entities?.serviceGroup?.includes(id))
              .toString(),
            op: 'REMOVE',
          };
        } else {
          return '';
        }
      })
      .filter((role) => role.value !== '' && role.value !== undefined);
    return serviceGroupsRemoved;
  }
};

export const getCurrentRole = ({
  attributeGroups = AttributeGroups.SITES,
  currentRoles,
  op,
  personAttributes,
}) =>
  currentRoles
    ?.flatMap((role) => {
      const { entity, groupCombination } = {
        [AttributeGroups.SERVICE_GROUP]: {
          entity: 'serviceGroup',
          groupCombination: 'service_group',
        },
        [AttributeGroups.SITES]: {
          entity: 'site',
          groupCombination: 'sites',
        },
      }[attributeGroups];
      const existingRoles = personAttributes?.entity_group
        .filter((attribute) => attribute.roleName === role.name)[0]
        ?.groupCombination.filter((group) => group.groupName === attributeGroups)[0].ids;
      return {
        path: `/attributes/entity_group/${role.name}/groupCombination/${groupCombination}/ids`,
        value: role.entities?.[entity].filter((id) => !existingRoles.includes(id)).toString(),
        op,
      };
    })
    .filter((role) => role.value !== '');
