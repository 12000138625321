import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';

import { StackMenu } from '@ge/feat-manage/components/planning/month/cards/stack-card-menu';
import {
  BundledCountBadge,
  TaskCard,
  TaskCardEntity,
  TaskCardInfo,
} from '@ge/feat-manage/components/planning/shared';
import { DragItemTypes } from '@ge/feat-manage/context';
import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { useCalendarCardMenu } from '@ge/feat-manage/data-hooks/use-calendar-card-menu';

export const DayCardSingle = ({
  task,
  top,
  position,
  width,
  draggable,
  onDrop,
  laneIndex,
  secondaryIndex,
  itemIndex,
  onClick,
  bundlePresent,
  partialCompleted,
  sameAssetTaskCount,
  tasks,
  bundleCount,
  date,
}) => {
  const {
    planningState: { handleDragStart, handleDragEnd, range },
  } = usePlanningContext();

  const menuRef = useRef(null);
  const anchorEl = useRef(null);

  const { menuTaskId, menuShown, destroyStackMenu, showStackMenu } = useCalendarCardMenu();

  return (
    <TaskCard
      top={top}
      position={position}
      width={width}
      className={classNames(
        `${partialCompleted ? 'partial' : task.status}`,
        'lasso-card',
        {
          draggable,
        },
        range.type,
      )}
      draggable={draggable}
      onMouseEnter={() => showStackMenu(task.id)}
      onMouseLeave={destroyStackMenu}
      ref={anchorEl}
      onDragStart={() => {
        destroyStackMenu();
        handleDragStart(
          task,
          DragItemTypes.CARD,
          null,
          secondaryIndex !== null ? `${laneIndex}-${secondaryIndex}` : laneIndex,
        );
      }}
      onDragEnd={handleDragEnd}
      onDrop={onDrop}
      data-laneindex={laneIndex}
      data-id={task.id}
      data-id3={task.id}
      data-secondaryindex={secondaryIndex}
      data-dayindex={itemIndex}
      onClick={(e) => onClick(e)}
    >
      <StackMenu
        open={menuShown && menuTaskId == task.id}
        tasks={tasks}
        onClose={destroyStackMenu}
        ref={menuRef}
        anchorEl={anchorEl && anchorEl.current}
        bundlePresent={bundlePresent}
        date={date}
      />
      <TaskCardEntity className="asset-name" task={task} />
      <TaskCardInfo task={task} sameAssetTaskCount={sameAssetTaskCount} />
      <BundledCountBadge
        className={range.type}
        bundlePresent={bundlePresent}
        bundleCount={bundleCount}
      />
    </TaskCard>
  );
};

DayCardSingle.propTypes = {
  task: PropTypes.instanceOf(Array),
  tasks: PropTypes.instanceOf(Array),
  top: PropTypes.number,
  position: PropTypes.number,
  width: PropTypes.number,
  laneIndex: PropTypes.number.isRequired,
  secondaryIndex: PropTypes.number,
  itemIndex: PropTypes.number.isRequired,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
  bundlePresent: PropTypes.bool,
  partialCompleted: PropTypes.bool,
  sameAssetTaskCount: PropTypes.number,
  bundleCount: PropTypes.number,
  date: PropTypes.instanceOf(Object), // dayjs
};

DayCardSingle.defaultProps = {
  top: null,
  position: null,
  width: null,
  onDrop: () => {},
  onClick: () => {},
  secondaryIndex: null,
  draggable: false,
  bundlePresent: false,
  partialCompleted: false,
  sameAssetTaskCount: 0,
  bundleCount: 0,
};
