import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { KpiCategoryDefs, KpiCategoriesHeader } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';

import { DashboardKPI } from './dashboard-kpi';

const StatsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  & > div {
    border-left: 2px solid ${(props) => props.theme.analyze.header.statusBorder};
    &:first-child {
      border-left: 0px;
    }
  }
`;

export const DashboardStatsHeader = ({ data, categories }) => {
  const { ready } = useTranslation(['analyze.dashboard'], { useSuspense: false });

  const i18nKeys = useMemo(
    () => ({
      // Site and Region KPIs Header
      [KpiCategoryDefs.TBA]: {
        i18nDefault: 'TBA - Technical',
        i18nKey: 'kpi.tba',
      },
      [KpiCategoryDefs.PBA]: {
        i18nDefault: 'PBA - Technical',
        i18nKey: 'kpi.pba',
      },
      [KpiCategoryDefs.AVAILABILITY_CONTRACT]: {
        i18nDefault: 'Contractual Availability',
        i18nKey: 'kpi.contractual_availability',
      },
      [KpiCategoryDefs.UNPRODUCED_ENERGY_CONTRACT]: {
        i18nDefault: 'Unproduced Energy',
        i18nKey: 'kpi.unproduced_energy',
      },
      [KpiCategoryDefs.PRODUCTION_ACTUAL]: {
        i18nDefault: 'Actual Production',
        i18nKey: 'kpi.actual_production',
      },
      [KpiCategoryDefs.PRODUCTION_RATIO]: {
        i18nDefault: 'Production Ratio',
        i18nKey: 'kpi.production_ratio',
      },
      [KpiCategoryDefs.CAPACITY_FACTOR]: {
        i18nDefault: 'Capacity Factor',
        i18nKey: 'kpi.capacity_factor',
      },
      // Solar KPI Headers API Response updated with capacityFactor to cf since short key required here
      [KpiCategoryDefs.CAPACITY_FACTOR_SHORT_KEY]: {
        i18nDefault: 'Capacity Factor',
        i18nKey: 'kpi.capacity_factor',
      },
      [KpiCategoryDefs.EVENT_COVERAGE]: {
        i18nDefault: 'Event Coverage',
        i18nKey: 'kpi.event_coverage',
      },
      [KpiCategoryDefs.REPORTING_ASSETS_RATIO]: {
        i18nDefault: 'Reporting vs. Expected Assets',
        i18nKey: 'kpi.reporting_assets',
      },
      [KpiCategoryDefs.REPORTING_SITES_RATIO]: {
        i18nDefault: 'Reporting vs. Expected Sites',
        i18nKey: 'kpi.reporting_sites',
      },
      [KpiCategoryDefs.EVENT_RATES]: {
        i18nDefault: 'Event Rate',
        i18nKey: 'kpi.event_rate',
      },
      [KpiCategoryDefs.IU_COVERAGE]: {
        i18nDefault: 'IU Coverage',
        i18nKey: 'kpi.iu_coverage',
      },
      [KpiCategoryDefs.ENERGY]: {
        i18nDefault: 'Energy',
        i18nKey: 'kpi.energy',
      },
      [KpiCategoryDefs.POWER]: {
        i18nDefault: 'Power',
        i18nKey: 'kpi.power',
      },
      [KpiCategoryDefs.YIELD]: {
        i18nDefault: 'Yield',
        i18nKey: 'kpi.yield',
      },
      [KpiCategoryDefs.AVAILABILITY]: {
        i18nDefault: 'Availability',
        i18nKey: 'kpi.availability',
      },
      [KpiCategoryDefs.EFFICIENCY_STORAGE]: {
        i18nDefault: 'Eff (Chg/DisChg)',
        i18nKey: 'kpi.efficiency',
      },
      [KpiCategoryDefs.EFFICIENCY]: {
        i18nDefault: 'Efficiency',
        i18nKey: 'kpi.efficiency',
      },
      [KpiCategoryDefs.REACTIVE_ENERGY_USAGE]: {
        i18nDefault: 'Reactive Energy (Chg/DisChg)',
        i18nKey: 'kpi.reactiveEnergyUsage',
      },
      [KpiCategoryDefs.ACTIVE_ENERGY_USAGE]: {
        i18nDefault: 'Active Energy (Chg/DisChg)',
        i18nKey: 'kpi.activeEnergyUsage',
      },
      [KpiCategoryDefs.SOC]: {
        i18nDefault: 'SOC',
        i18nKey: 'kpi.soc',
      },
      [KpiCategoryDefs.SOH]: {
        i18nDefault: 'SOH',
        i18nKey: 'kpi.soh',
      },
    }),
    [],
  );

  const categoriesData = useMemo(() => {
    const kpis = Object.entries(data)
      .filter(([, value]) => value.entityData?.length > 0)
      .map(([kpi]) => kpi);
    if (kpis.length === 1 && kpis[0] === KpiCategoryDefs.AVAILABILITY_CONTRACT) {
      return categories.reduce(
        (acc, category) => ({
          ...acc,
          [category]: { entityData: [], timeSeriesData: [], aggregateValue: null, units: '' },
        }),
        {},
      );
    }
    return data;
  }, [categories, data]);

  const renderKPI = useCallback(
    (key) => {
      if (categoriesData && !categoriesData[key]) return null;
      return <DashboardKPI title={i18nKeys[key]} data={categoriesData[key]} key={key} />;
    },
    [categoriesData, i18nKeys],
  );

  if (!ready) return null;

  return (
    <StatsHeader data-testid={AnalyzeLocators.ANALYZE_KPIBAR_KPI_LIST}>
      {/* KPIs Header */}
      {categories.map(renderKPI)}
    </StatsHeader>
  );
};

DashboardStatsHeader.propTypes = {
  data: PropTypes.instanceOf(Object),
  categories: PropTypes.arrayOf(PropTypes.string),
};

DashboardStatsHeader.defaultProps = {
  data: {},
  categories: KpiCategoriesHeader,
};
