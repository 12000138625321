import { RibbonFilterTypes, RibbonFilterDefs, RibbonFilterType } from '@ge/feat-monitor/models';
import { CasePriority } from '@ge/models/constants';
import { AssetType } from '@ge/models/entities/asset/constants';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';
import { getDataFilter } from '@ge/shared/util/data-filter';

const casePriorities = [null].concat(Object.values(CasePriority));

const getSelectedRibbonFilter = (selected, type) =>
  RibbonFilterDefs[type]?.find(({ id }) => id === selected);

const getRibbonFilters = (isLoading, data, type) =>
  RibbonFilterDefs[type]?.map((item) => ({
    ...item,
    isLoading,
    count: applyRibbonFilter(data ?? [], item).length,
  })) ?? [];

export const getRibbonState = ({ isLoading, type, selected, data, isAuthorizedToViewTasks }) => {
  if (type === RibbonFilterTypes.ANOMALIES) {
    const id = selected?.id;
    const selectedFilter = getSelectedRibbonFilter(id, type);
    return {
      type,
      selected: selectedFilter,
      filters: !isAuthorizedToViewTasks
        ? getRibbonFilters(isLoading, data, type).filter(
            (ribbonFilter) => ribbonFilter.id !== RibbonFilterType.CLOSED_TASKS,
          )
        : getRibbonFilters(isLoading, data, type),
      data: applyRibbonFilter(data, selectedFilter),
    };
  }
  return {};
};

export const applyRibbonFilter = (data, selected) => {
  if (selected && data && data.length > 0) {
    const { name, filter, filterFn } = selected;
    if (filterFn) {
      return data.filter(filterFn);
    }
    const dataFilter = getDataFilter({
      data,
      filters: {
        [name]: filter,
      },
    });
    return dataFilter.data;
  }
  return data;
};

export const caseTableRowTransformer = (caseDetail) => {
  const {
    id: caseId,
    description,
    status,
    priority,
    createDate,
    start,
    fixed,
    lastFlagged,
    asset,
    site,
    components = [],
    componentIds = [],
    notes = [],
    totalTaskCount = 0,
    task,
    taskIds = [],
    tasks = [],
    taskDescription,
    analyticSource,
    source,
    isParent,
    statusDetails,
  } = caseDetail ?? {};

  const latestTask = task ?? {};

  const casePriority = priority ? String(priority).toLowerCase() : null;

  return {
    [AnomaliesColumns.SELECTED]: { id: caseId },
    [AnomaliesColumns.TYPE]: asset.type ?? AssetType.WIND_TURBINE,
    [AnomaliesColumns.SITE]: { description: site.name, id: site.id, isLoading: site.isLoading },
    [AnomaliesColumns.ASSET]: { description: asset.name, id: asset.id, isLoading: asset.isLoading },
    [AnomaliesColumns.COMPONENT]: components?.length
      ? components[0].name ?? components[0]
      : componentIds[0],
    [AnomaliesColumns.STATUS]: status,
    [AnomaliesColumns.ID]: caseId,
    [AnomaliesColumns.DESCRIPTION]: { description, caseId, isParent },
    [AnomaliesColumns.PRIORITY]: {
      description: casePriority,
      value: casePriorities.indexOf(casePriority),
    },
    [AnomaliesColumns.NOTES]: { id: caseId, description: notes?.totalCount || 0 },
    [AnomaliesColumns.TROUBLESHOOTING]: { asset, caseId },
    [AnomaliesColumns.LINKED]: {
      totalTaskCount,
      taskIds,
      caseId,
      tasks,
      closed: fixed,
    },
    [AnomaliesColumns.ESCALATED]: {
      taskId: latestTask.taskId,
      status: latestTask.status,
      workScope: latestTask.workScope,
      totalTaskCount,
      taskIds,
      caseId,
      tasks,
      closed: fixed,
      value: [latestTask.status ?? '', latestTask.workScope ?? ''],
    },
    [AnomaliesColumns.STATUS_DETAIL]: {
      value: statusDetails?.lastUpdatedDate,
      description: statusDetails?.statusDetail,
    },
    [AnomaliesColumns.LAST_FLAGGED]: lastFlagged,
    [AnomaliesColumns.CREATE_DATE]: createDate,
    [AnomaliesColumns.START_DATE]: start,
    [AnomaliesColumns.FIXED_DATE]: fixed,
    [AnomaliesColumns.SERIAL_NUMBER]: asset.serialNumber,
    [AnomaliesColumns.ASSET_MAKE]: asset.make,
    [AnomaliesColumns.ASSET_MODEL]: asset.model,
    [AnomaliesColumns.CUSTOMER]: site.customer?.name,
    [AnomaliesColumns.COUNTRY]: site.country,
    [AnomaliesColumns.CASE_DETAIL]: { id: caseId },
    [AnomaliesColumns.NEW_TASK]: { id: caseId },
    [AnomaliesColumns.TASK_DESCRIPTION]: latestTask.description ?? taskDescription,
    [AnomaliesColumns.ANALYTIC_SOURCE]: analyticSource,
    [AnomaliesColumns.SOURCE]: source,
  };
};
