import { TableBaseCols } from '@ge/feat-reporting/components/widgets/table-widget/table-base-cols';
import { Placeholders } from '@ge/models/constants';

// All available columns and groups for Production table
export const ProductionTableColumns = {
  GROUP_SELECTED: TableBaseCols.GROUP_SELECTED,
  SELECTED: TableBaseCols.SELECTED,

  // Filters [affects data fetching]
  GROUP_SHOW_BY: 'Show By',
  SHOW_BY_ASSET: 'Asset',
  SHOW_BY_SITE: 'Site',
  SHOW_BY_REPORTSCOPE: 'Report Scope',

  // Asset Details
  GROUP_ASSET_DETAILS: 'group-asset-details',
  ASSET: 'padNumber',

  // Static dimensions* for aggregated KPI values
  // *NB:
  //  For most table-type widgets, data is one-dimensional and is shown in a columnar format.
  //  Users are then able to choose which columns to display or hide for their report.
  //  This widget displays tabular data, and supports configuration options for controlling
  //  visibility of certain data shown in both columns and rows.
  //  Whether data is shown in columns, rows, or another format is just an implementation detail.
  //  I.e., the values for these config options are as specified in the server template data;
  //  don't get thrown off by the "columns" vs "rows" distinction - it doesn't matter here.
  GROUP_ENTITY_AGGR_KPI: 'Columns',
  ENTITY_AGGR_KPI: 'Total per Asset/Site',
  GROUP_PRODUCTION_AGGR_KPI: 'Rows',
  TOTAL_PRODUCTION_KPI: 'Total Production',

  // Dynamic columns for asset KPI values:
  // Widget supports displaying up to 11 dynamic columns of data.
  // Column labels and visibility are determined at runtime based on API response.
  GROUP_DYNAMIC_COLUMNS: 'group-dynamic-cols',
  ONE: 'dynamic-col-0',
  TWO: 'dynamic-col-1',
  THREE: 'dynamic-col-2',
  FOUR: 'dynamic-col-3',
  FIVE: 'dynamic-col-4',
  SIX: 'dynamic-col-5',
  SEVEN: 'dynamic-col-6',
  EIGHT: 'dynamic-col-7',
  NINE: 'dynamic-col-8',
  TEN: 'dynamic-col-9',
  ELEVEN: 'dynamic-col-10',
};

// Production table configuration
export const productionTableColumnDefs = {
  [ProductionTableColumns.GROUP_SELECTED]: {
    fixedLeft: true,
    width: '50px',
    cols: {
      [ProductionTableColumns.SELECTED]: {
        fixedLeft: true,
        locked: true,
        labels: [
          {
            a11yKey: 'production_table.columns.include',
            a11yDefault: 'Include',
          },
        ],
        cell: {
          align: 'center',
          width: '50px',
        },
      },
    },
  },
  // This only a configuration option group; these fields do not actually appear in the rendered table.
  [ProductionTableColumns.GROUP_SHOW_BY]: {
    type: 'radio',
    labels: [
      {
        a11yKey: 'production_table.options.show_by',
        a11yDefault: 'Show By',
      },
    ],
    cols: {}, // For compatibility with table factory, but we don't want any columns created for this group.
    fields: {
      [ProductionTableColumns.SHOW_BY_ASSET]: {
        labels: [
          {
            a11yKey: 'production_table.options.show_by_asset',
            a11yDefault: 'Asset',
          },
        ],
      },
      [ProductionTableColumns.SHOW_BY_SITE]: {
        locked: true, // not supported in MVP
        labels: [
          {
            a11yKey: 'production_table.options.show_by_site',
            a11yDefault: 'Site',
          },
        ],
      },
      [ProductionTableColumns.SHOW_BY_REPORTSCOPE]: {
        locked: true, // not supported in MVP
        labels: [
          {
            a11yKey: 'production_table.options.show_by_reportscope',
            a11yDefault: 'Report Scope',
          },
        ],
      },
    },
  },
  [ProductionTableColumns.GROUP_ASSET_DETAILS]: {
    align: 'left',
    labels: [
      {
        a11yKey: 'production_table.columns.asset_details',
        a11yDefault: 'Asset Details',
      },
    ],
    cols: {
      [ProductionTableColumns.ASSET]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.asset_name',
            a11yDefault: 'Asset',
          },
        ],
      },
    },
  },
  [ProductionTableColumns.GROUP_DYNAMIC_COLUMNS]: {
    cols: {
      [ProductionTableColumns.ONE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.TWO]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.THREE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.FOUR]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.FIVE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.SIX]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.SEVEN]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.EIGHT]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.NINE]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.TEN]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
      [ProductionTableColumns.ELEVEN]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.dynamic_col',
            a11yDefault: Placeholders.DOUBLE_DASH,
          },
        ],
      },
    },
  },
  [ProductionTableColumns.GROUP_ENTITY_AGGR_KPI]: {
    labels: [
      {
        a11yKey: 'production_table.columns.group_entity_aggr',
        a11yDefault: 'Columns',
      },
    ],
    cols: {
      [ProductionTableColumns.ENTITY_AGGR_KPI]: {
        cell: {
          width: '50px',
        },
        labels: [
          {
            a11yKey: 'production_table.columns.entity_aggregate_kpi',
            a11yDefault: 'Average',
          },
        ],
      },
    },
  },
  [ProductionTableColumns.GROUP_PRODUCTION_AGGR_KPI]: {
    labels: [
      {
        a11yKey: 'production_table.columns.group_production_aggr',
        a11yDefault: 'Rows',
      },
    ],
    cols: {}, // For compatibility with table factory, but we don't want any columns created for this group.
    fields: {
      [ProductionTableColumns.TOTAL_PRODUCTION_KPI]: {
        labels: [
          {
            a11yKey: 'production_table.columns.production_aggregate_kpi',
            a11yDefault: 'Average',
          },
        ],
      },
    },
  },
};

// Explicitly define columns that should not be shown in any user-selection
// scenarios (i.e. - report widget configuration).
export const productionTableOmittedCols = [
  ProductionTableColumns.GROUP_SELECTED,
  ProductionTableColumns.SELECTED,
  ProductionTableColumns.GROUP_ASSET_DETAILS,
  ProductionTableColumns.ASSET,
  ProductionTableColumns.GROUP_DYNAMIC_COLUMNS,
  ProductionTableColumns.ONE,
  ProductionTableColumns.TWO,
  ProductionTableColumns.THREE,
  ProductionTableColumns.FOUR,
  ProductionTableColumns.FIVE,
  ProductionTableColumns.SIX,
  ProductionTableColumns.SEVEN,
  ProductionTableColumns.EIGHT,
  ProductionTableColumns.NINE,
  ProductionTableColumns.TEN,
  ProductionTableColumns.ELEVEN,
];

// Default order and visibility configuration for production table columns
// NOTE: For state selection, EVERY column must have a position and visibility default!
export const productionTableDefaultCols = [
  {
    id: ProductionTableColumns.GROUP_SELECTED,
    cols: [
      {
        id: ProductionTableColumns.SELECTED,
        visible: true,
      },
    ],
  },
  {
    id: ProductionTableColumns.GROUP_SHOW_BY,
    // Only one of these options is currently supported.
    // This flag tells the config loader to use the values specified here
    // and not overwrite with configuration values sent from the server.
    ignoreTemplateConfig: true,
    // NB: the name `cols` is misleading here as these are only configuration
    // options for the module; they are not displayed in the table itself.
    cols: [
      {
        id: ProductionTableColumns.SHOW_BY_ASSET,
        visible: true,
      },
      {
        id: ProductionTableColumns.SHOW_BY_SITE,
        visible: false, // Not supported yet
      },
      {
        id: ProductionTableColumns.SHOW_BY_REPORTSCOPE,
        visible: false, // Not supported yet
      },
    ],
  },
  {
    id: ProductionTableColumns.GROUP_ASSET_DETAILS,
    cols: [
      {
        id: ProductionTableColumns.ASSET,
        visible: true,
      },
    ],
  },
  {
    id: ProductionTableColumns.GROUP_DYNAMIC_COLUMNS,
    cols: [
      {
        id: ProductionTableColumns.ONE,
        visible: false,
      },
      {
        id: ProductionTableColumns.TWO,
        visible: false,
      },
      {
        id: ProductionTableColumns.THREE,
        visible: false,
      },
      {
        id: ProductionTableColumns.FOUR,
        visible: false,
      },
      {
        id: ProductionTableColumns.FIVE,
        visible: false,
      },
      {
        id: ProductionTableColumns.SIX,
        visible: false,
      },
      {
        id: ProductionTableColumns.SEVEN,
        visible: false,
      },
      {
        id: ProductionTableColumns.EIGHT,
        visible: false,
      },
      {
        id: ProductionTableColumns.NINE,
        visible: false,
      },
      {
        id: ProductionTableColumns.TEN,
        visible: false,
      },
      {
        id: ProductionTableColumns.ELEVEN,
        visible: false,
      },
    ],
  },
  {
    id: ProductionTableColumns.GROUP_ENTITY_AGGR_KPI,
    cols: [
      {
        id: ProductionTableColumns.ENTITY_AGGR_KPI,
        visible: true,
      },
    ],
  },
  {
    id: ProductionTableColumns.GROUP_PRODUCTION_AGGR_KPI,
    // NB: the name `cols` is misleading here as this data is displayed as a row, not
    // a column, but the distinction is not terribly important. The configuration is
    // necessary for the purpose of hiding/showing the row from the sidebar module panel.
    cols: [
      {
        id: ProductionTableColumns.TOTAL_PRODUCTION_KPI,
        visible: true,
      },
    ],
  },
];
