import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Input } from '@ge/components/input';
import { Pattern } from '@ge/models/constants';
import { RadioGroupMetaField } from '@ge/shared/components/meta-fields';

const MailFieldsRow = styled.div`
  display: grid;
  grid-template-columns: 26% 20% 20% 150px auto;
  column-gap: 12px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  background: transparent;
  border: none;
`;

const AddRow = styled(Button)`
  background: transparent;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: left;
  text-transform: uppercase;
  label {
    margin-left: 10px;
  }
`;

const AddRecipientLabel = styled.span`
  color: ${(props) => props.theme.distributionListSidePanel.defaultTextLabel};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-left: 4px;
`;

/* eslint-disable no-unused-vars */
const MailFields = ({ theme, mails, setMails }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'mails' });
  const { t } = useTranslation(['admin.configure']);

  const defaultMail = useMemo(
    () => [
      {
        firstName: '',
        lastName: '',
        type: 'to',
        email: '',
      },
    ],
    [],
  );

  useEffect(() => {
    if (fields.find((key) => key?.email !== '')) {
      setMails(fields);
    } else setMails(defaultMail);
  }, [fields.find((key) => key?.email !== '')]);

  const onValueChange = (onChange, val, index, key) => {
    onChange(val);
    const tempMails = fields;
    tempMails[index][key] = val.target.value;
    setMails(tempMails);
  };

  return (
    <>
      {fields.map((field, index) => {
        return (
          <MailFieldsRow key={field.id}>
            <Controller
              control={control}
              name={`mails[${index}].email`}
              defaultValue={field.email}
              rules={{
                required: true,
                validate: (val) => Boolean(val?.match(Pattern.EMAIL)),
              }}
              render={({ onChange, onBlur, value }, { invalid }) => (
                <Input
                  error={invalid ? 'enter valid email' : ''}
                  required
                  label={index === 0 ? t('DL_form_fields.email', 'Email') : null}
                  onChange={(val) => onValueChange(onChange, val, index, 'email')}
                  onBlur={onBlur}
                  type="email"
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name={`mails[${index}].firstName`}
              defaultValue={field.firstName}
              render={({ onChange, onBlur, value }) => (
                <Input
                  label={index === 0 ? t('DL_form_fields.firstName', 'First Name') : null}
                  onChange={(val) => onValueChange(onChange, val, index, 'firstName')}
                  onBlur={onBlur}
                  type="text"
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name={`mails[${index}].lastName`}
              defaultValue={field.lastName}
              render={({ onChange, onBlur, value }) => (
                <Input
                  label={index === 0 ? t('DL_form_fields.lastName', 'Last Name') : null}
                  onChange={(val) => onValueChange(onChange, val, index, 'lastName')}
                  onBlur={onBlur}
                  type="text"
                  value={value}
                />
              )}
            />
            <RadioGroupMetaField
              control={control}
              name={`mails[${index}].type`}
              required={false}
              defaultValue={field.type || 'to'}
              choices={[
                { id: `to-${field.id}`, label: 'to', value: 'to' },
                { id: `cc-${field.id}`, label: 'cc', value: 'cc' },
                { id: `bcc-${field.id}`, label: 'bcc', value: 'bcc' },
              ]}
              margin={'0px 10px 0px 0px'}
            />
            <DeleteButton onClick={() => remove(index)}>
              <Icon icon={Icons.TRASH} size={12} color={'#8694A1'} />
            </DeleteButton>
          </MailFieldsRow>
        );
      })}
      <AddRow onClick={() => append({ email: '', firstName: '', lastName: '', type: 'to' })}>
        <Icon icon={Icons.ADD} size={10} color={theme.button.primary.textColor} />
        <AddRecipientLabel>{t('add_recipients', 'Add Recipients')}</AddRecipientLabel>
      </AddRow>
    </>
  );
};

MailFields.propTypes = {
  theme: PropTypes.object,
  mails: PropTypes.array,
  setMails: PropTypes.func,
};

export const DLMailFields = withTheme(MailFields);
