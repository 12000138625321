import React, { useContext } from 'react';

import { SiteTypeTabs } from '@ge/models/constants';

import { AssetContext } from '../../context/assetContext';

import { AssetOverviewSolarHeader } from './asset-overview-solar-header';
import { AssetOverviewStorageHeader } from './asset-overview-storage-header';
import { AssetOverviewWindHeader } from './asset-overview-wind-header';

export const AssetOverviewHeader = () => {
  const { queryParam } = useContext(AssetContext);

  return (
    <>
      {queryParam.type === SiteTypeTabs.SOLAR ? (
        <AssetOverviewSolarHeader />
      ) : queryParam.type === SiteTypeTabs.STORAGE ? (
        <AssetOverviewStorageHeader />
      ) : (
        <AssetOverviewWindHeader />
      )}
    </>
  );
};
