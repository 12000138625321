import dayjs from 'dayjs';
import { action, thunk, thunkOn } from 'easy-peasy';

import { DateTimeFormats } from '@ge/models/constants';

import {
  createCrew,
  deleteCrew,
  editCrew,
  fetchAllCrews,
  fetchCrews,
  fetchAllWorkers,
  deleteAllCrews,
} from '../services/worker';

// Define initial state
const defaultWorkersState = {
  workers: [],
  crews: [],
  // Dispatch
  dispatchCrew: null,
  previousCrew: null,
  dispDelCrew: null,
  crewsSg: [],
  refreshTask: false,
};

// Actions
const workersActions = {
  /**
   * Reset state to defaults
   */
  resetWorker: action((state) => {
    /* eslint-disable-next-line no-unused-vars */
    state = Object.assign(state, defaultWorkersState);
  }),

  //fetch workers
  fetchAllWorkers: thunk(
    async (
      actions,
      { serviceGroupIds, types, sortKey, orderDirection, pageSize, pageIdx, end, start },
      { fail },
    ) => {
      try {
        const payload = {
          serviceGroupIds,
          types,
          sortKey,
          orderDirection,
          pageIdx,
          pageSize,
          end,
          start,
        };
        const workers = await fetchAllWorkers(payload);
        actions.updateWorkers(workers.users);
      } catch (err) {
        fail(err);
      }
    },
  ),

  //fetch crews
  fetchAllCrews: thunk(async (actions, { serviceGroupIds, start, end }, { fail }) => {
    try {
      const payload = { serviceGroupIds, start, end };
      const crews = await fetchAllCrews(payload);
      actions.setCrews(crews);
    } catch (err) {
      fail(err);
    }
  }),

  fetchCrewsOnServiceGroup: thunk(
    async (actions, { serviceGroupIds, startDate, endDate }, { fail }) => {
      try {
        const payload = { serviceGroupIds, ...(startDate && startDate), ...(endDate && endDate) };
        const crews = await fetchCrews(payload);
        actions.setCrewsSg(crews);
      } catch (err) {
        fail(err);
      }
    },
  ),

  //update the worker list
  updateWorkers: action((state, payload) => {
    state.workers = payload;
  }),

  setCrews: action((state, payload) => {
    state.crews = payload;
  }),

  setCrewsSg: action((state, payload) => {
    state.crewsSg = payload;
  }),

  //create new crew
  createCrew: thunk(async (actions, newCrew) => {
    try {
      const createdCrew = await createCrew(newCrew);
      actions.onCreateCrew(createdCrew);
    } catch (err) {
      console.log('Create Crew Failed!');
    }
  }),

  //update crew
  updateCrew: thunk(async (actions, payload) => {
    try {
      const updatedCrew = await editCrew(payload._id, payload);
      actions.updatedDispatchCrew(updatedCrew); // Dispatch
      actions.updateCrews(updatedCrew);
    } catch (err) {
      console.log('Update Crew Failed!');
    }
  }),

  //delete crew
  deleteCrew: thunk(async (actions, { crewId }) => {
    try {
      // Dispatch
      actions.updateDeletedCrew(crewId, true);
      await deleteCrew(crewId);
      actions.onDeleteCrew(crewId);
    } catch (err) {
      console.log('Delete Crew Failed!');
    }
  }),
  //delete all crews
  deleteAllCrews: thunk(async (actions, { serviceGroup }) => {
    try {
      console.log(serviceGroup, 'serviceGroup');
      // Dispatch
      const response = await deleteAllCrews({ serviceGroup });
      if (response?.success) {
        actions.onDeleteAllCrews();
      }
    } catch (err) {
      console.log('Delete All Crew Failed!');
    }
  }),

  //removes worker from a crew on delete icon click and auto saves it
  removeWorkerFromCrew: thunk(async (actions, { memId, crew }) => {
    actions.updatePreviousCrew(crew._id); // Dispatch
    let memIndex = crew.members.findIndex((mem) => mem.username == memId);
    crew.members.splice(memIndex, 1);
    actions.updateCrew(crew);
  }),

  onCreateCrew: action((state, payload) => {
    state.crews = [...state.crews, payload];
    state.crewsSg = [...state.crewsSg, payload];
  }),

  updateCrews: action((state, payload) => {
    let crewIndexSg = state.crewsSg.findIndex((crew) => crew._id == payload._id);
    let crewIndex = state.crews.findIndex((crew) => crew._id == payload._id);

    if (crewIndexSg !== -1) {
      state.crewsSg.splice(crewIndexSg, 1, payload);
    }

    if (crewIndex !== -1) {
      state.crews.splice(crewIndex, 1, payload);
    }
  }),

  updatedDispatchCrew: action((state, payload) => {
    state.dispatchCrew = payload;
  }),

  // Dispatch
  updatePreviousCrew: action((state, crewId) => {
    const crews = { ...state.crews.filter((crew) => crew._id === crewId)[0] };
    state.previousCrew = crews._id ? JSON.parse(JSON.stringify(crews)) : null;
  }),

  updateDeletedCrew: action((state, crewId) => {
    const crews = { ...state.crews.filter((crew) => crew._id === crewId)[0] };
    state.dispDelCrew = crews._id ? crews : null;
  }),

  onDeleteCrew: action((state, crewId) => {
    state.crews = state.crews.filter((crew) => {
      return crew._id !== crewId;
    });
    state.crewsSg = state.crewsSg.filter((crew) => {
      return crew._id !== crewId;
    });
  }),

  onDeleteAllCrews: action((state) => {
    state.crews = [];
    state.crewsSg = [];
    state.refreshTask = !state.refreshTask;
  }),

  //adds new timing set to a member
  addTimingToMember: action((state, { crewId, memId }) => {
    const crews = state.crews;
    let crewIndex = crews.findIndex((crew) => crew._id == crewId);
    let memIndex = crews[crewIndex].members.findIndex((mem) => mem.username == memId);
    crews[crewIndex].members[memIndex].time_periods.push({
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
    });
    state.crews = [...crews];
    state.crewsSg = [...crews];
  }),

  //deletes member timing
  deleteTimingFromMember: action((state, { crewId, memIndex, timeIndex }) => {
    const crews = state.crews;
    let crewIndex = crews.findIndex((crew) => crew._id == crewId);
    crews[crewIndex].members[memIndex].time_periods.splice(timeIndex, 1);
    state.crews = [...crews];
    state.crewsSg = [...crews];
  }),

  //clears member time values
  removeTimeValuesFromMember: action((state, { crewId, memIndex, timeIndex }) => {
    const crews = state.crews;
    let crewIndex = crews.findIndex((crew) => crew._id == crewId);
    crews[crewIndex].members[memIndex].time_periods.splice(timeIndex, 1);
    crews[crewIndex].members[memIndex].time_periods.push({
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
    });
    state.crews = [...crews];
    state.crewsSg = [...crews];
  }),

  //updates crew name
  updateCrewName: action((state, { selectedCrew, crewName }) => {
    const crews = state.crews;
    const crewsSg = state.crewsSg;
    let crewIndex = crews.findIndex((crew) => crew._id == selectedCrew._id);
    let crewIndexSg = crewsSg.findIndex((crew) => crew._id == selectedCrew._id);

    if (crewIndex !== -1) {
      crews[crewIndex].name = crewName;
      state.crews = [...crews];
    }

    if (crewIndexSg !== -1) {
      crewsSg[crewIndexSg].name = crewName;
      state.crewsSg = [...crewsSg];
    }
  }),

  //adds member to crew and makes api call to save them
  addMemberToCrew: thunk(async (actions, { member, memId, crew }) => {
    try {
      let startDate = '';
      //check if member already exists
      actions.updatePreviousCrew(crew._id); // Dispatch
      let memIndex = crew.members.findIndex((mem) => mem.username == memId);
      if (memIndex === -1) {
        //past
        if (
          dayjs().isAfter(dayjs(crew.crewStartDate), 'date') ||
          dayjs().isSame(dayjs(crew.crewStartDate), 'date')
        ) {
          startDate = dayjs(new Date()).format(DateTimeFormats.CREW_TIMING);
        }
        //future
        if (dayjs().isBefore(dayjs(crew.crewStartDate), 'date')) {
          startDate = crew.crewStartDate;
        }

        const newMember = {
          time_periods: [
            {
              startDate: startDate,
              endDate: '',
              startTime: crew.crewShiftStartTime,
              endTime: '',
            },
          ],
          member_id: memId,
          firstName: member.firstName,
          lastName: member.lastName,
          title: member.title,
          username: member.username,
        };
        crew.members.push(newMember);
        actions.updateCrew(crew);
      }
    } catch (err) {
      console.log('Adding member failed!');
    }
  }),
};

// Listeners
const workersListeners = {
  //makes api call on crew name change
  onUpdateCrewName: thunkOn(
    // targetResolver:
    (actions) => actions.updateCrewName,
    // handler:
    async (actions, target) => {
      let crew = target.payload.selectedCrew;
      actions.updatePreviousCrew(crew._id); // Dispatch
      crew.name = target.payload.crewName;
      actions.updateCrew(crew);
    },
  ),
};

// Computed values
const workersComputed = {};

// Compile the view store object for export
const workersModel = {
  ...defaultWorkersState,
  ...workersActions,
  ...workersComputed,
  ...workersListeners,
};

export default workersModel;
