import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu, MenuTable, placements } from '@ge/components/menu';
import { AssetConnectionStatus } from '@ge/models/constants';
import { Placeholders } from '@ge/models/constants';
import { DetailsDismissInert } from '@ge/shared/components/entity-details/dismiss-inert';
import { killEventPropagation } from '@ge/shared/util/general';
import { getConnectionStatusDuration } from '@ge/shared/util/time-date';

const StyledTitle = styled.div`
  background: ${(props) => props.theme.menu.titleBackground};
  border-radius: 5px 5px 0 0;
  padding: 6px 2px 6px 9px;
  display: flex;
  align-items: center;
  height: 13px;
  color: ${(props) => props.theme.menu.titleTextColor};
  letter-spacing: 0.5px;
  .menu-title-txt {
    text-transform: uppercase;
    margin-right: 6px;
  }
  .menu-title-duration {
    margin-left: auto;
    margin-right: 6px;
  }
  .total-alarms-count {
    margin-left: 10px;
  }
`;

const StyledTr = styled.tr`
  td {
    font-size: 11px;
    font-weight: ${(props) => (props.isBold ? 700 : 300)};
    div {
      text-overflow: ellipsis;
      text-transform: none;
      overflow: hidden;
      white-space: nowrap;
    }
    &:first-of-type {
      display: flex;
      align-items: center;
      div {
        flex: 1;
        max-width: 100px; // Should change based on the FD designs
        margin-right: 11px;
      }
    }
    &:nth-of-type(2) {
      div {
        max-width: 200px;
      }
    }
    &:last-of-type {
      div {
        text-align: right;
        padding-right: 11px;
      }
    }
    &.alert {
      justify-content: center;
      padding-right: 0;
      & > div {
        margin: 0 0 0 4px;
      }
    }
    .duration {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const StyledContainer = styled.div`
  opacity: ${(props) => (props.isDisconnected ? 0.4 : 1)};
`;

export const AssetPreviousState = ({
  currentState,
  prevState,
  connectionStatus,
  children,
  containerRef,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['monitor.sites'], { useSuspense: false });
  const handleShowMenu = (e) => {
    killEventPropagation(e);
    // setAnchorEl(e.currentTarget?.children[0]);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);
  const isDisconnected = useMemo(
    () =>
      connectionStatus?.value === AssetConnectionStatus.NOCOMM ||
      connectionStatus?.value === AssetConnectionStatus.NO_DATA,
    [connectionStatus],
  );

  return (
    <div onMouseEnter={handleShowMenu} onMouseLeave={handleMenuClose}>
      <StyledContainer isDisconnected={isDisconnected}>{children}</StyledContainer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        container={containerRef}
        placement={placements.TOP}
        onClick={(e) => {
          e.preventDefault();
          killEventPropagation(e);
        }}
      >
        <DetailsDismissInert>
          <StyledTitle>
            <h4 className="menu-title-txt">{t(`table.status`, 'Status')}</h4>
          </StyledTitle>
          <MenuTable disableHover={true}>
            <table>
              <tbody>
                <StyledTr>
                  <td>{t('table.previous_state', 'Previous State')}</td>
                  <td>
                    {prevState && prevState.value ? (
                      <div>{t(`state.${prevState?.value}`)}</div>
                    ) : (
                      <div>-</div>
                    )}
                  </td>
                </StyledTr>
                {currentState && !isDisconnected && (
                  <StyledTr>
                    <td>{t('table.current_state_duration', 'Current State Duration')}</td>
                    <td>
                      <div>{getConnectionStatusDuration(currentState) ?? Placeholders.DASH}</div>
                    </td>
                  </StyledTr>
                )}
                {isDisconnected && (
                  <StyledTr>
                    <td>{t('table.no_comm_duration', 'No Comm Duration')}</td>
                    <td>
                      <div>
                        {getConnectionStatusDuration(connectionStatus) ?? Placeholders.DASH}
                      </div>
                    </td>
                  </StyledTr>
                )}
              </tbody>
            </table>
          </MenuTable>
        </DetailsDismissInert>
      </Menu>
    </div>
  );
};

AssetPreviousState.propTypes = {
  currentState: PropTypes.instanceOf(Object),
  prevState: PropTypes.instanceOf(Object),
  connectionStatus: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  containerRef: PropTypes.instanceOf(Object),
};
