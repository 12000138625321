import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { roundNumber } from '@ge/util';

import { fetchAssetIecCategory } from '../services';

/**
 * Use asset IEC data.
 *
 * @param assetId the asset id
 * @param category the iec category
 * @param startDate the start date
 * @param endDate the end date
 */
const useAssetIecData = ({ assetId, category, endDate, isActive = true, startDate }) => {
  // store
  const getAssetById = useStoreState((store) => store.assets.getAssetById);

  const asset = getAssetById(assetId);
  const siteId = asset?.site?.id;

  const queryKey = {
    assetId,
    category,
    startDate,
    endDate,
    siteId,
  };

  const {
    data: _queryData,
    isLoading,
    error,
  } = useQuery(
    [QueryKey.ASSET_IEC_DATA, queryKey],
    () =>
      fetchAssetIecCategory({
        assetId,
        categories: [category],
        startDate,
        endDate,
      }),
    {
      enabled: isActive && Boolean(assetId && category && startDate && endDate),
      ...Config.EXECUTE_ONCE,
    },
  );

  const dataMapped = useMemo(() => {
    const categoryData = _queryData?.data[category];

    const data = categoryData?.data.reduce(
      (acc, data) => {
        return {
          iec: [data, ...acc.iec],
          // eslint-disable-next-line no-unsafe-optional-chaining
          oem: [...data?.details, ...acc.oem],
        };
      },
      {
        iec: [],
        oem: [],
      },
    );

    // Should move this logic to the BFF at somepoint.
    const updatedOemData = data?.oem?.map((detail) => {
      if (categoryData.total > 0) {
        detail.value = roundNumber((detail.kpiValue / categoryData.total) * 100);
      }
      return detail;
    });

    return {
      ...categoryData,
      data: !data
        ? null
        : {
            ...data,
            oem: updatedOemData,
          },
    };
  }, [_queryData?.data, category]);

  return { data: dataMapped, isLoading, error };
};

export default useAssetIecData;
