import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';

import { SitesTableCategories } from '@ge/feat-analyze/models';
import { KpiCategoryDefs } from '@ge/models/constants';
import { useTableFilter } from '@ge/shared/data-hooks/use-table-filter';
import { sorter } from '@ge/util/metric-sorter';

const useSitesFromRegion = ({
  regionData,
  regionId,
  tableSortMetric,
  tableSortDirection,
  regionName,
  search,
  filters,
  filterSequence,
}) => {
  const sitesPlatforms = useStoreState((state) => state.sites.sitesPlatforms);
  const { isLoading, sites } = useMemo(() => {
    if (Object.keys(regionData).length === 0) return { sites: [] };

    const isLoading = SitesTableCategories.some((category) => regionData?.[category]?.isLoading);

    const kpiLookup = Object.entries(regionData).reduce(
      (lookup, [category, { entityData = [], ...categoryData }]) => {
        entityData.forEach(({ entity: { id: siteId, name }, value }) => {
          lookup.sites[siteId] = {
            ...lookup.sites[siteId],
            siteId,
            site: name,
            region: regionId,
            regionName: regionName,
            platform: sitesPlatforms[siteId],
            platForm: sitesPlatforms[siteId]?.join(', ') ?? '',
            [category]: { value, aggregateValue: value, units: categoryData.units },
          };
          lookup.categories[category] = category;
        });
        return lookup;
      },
      { sites: {}, categories: {} },
    );

    const kpis = Object.keys(kpiLookup.categories);
    if (kpis.length === 1 && kpis[0] === KpiCategoryDefs.AVAILABILITY_CONTRACT) {
      return { isLoading, sites: [] };
    }
    return {
      isLoading,
      sites: Object.values(kpiLookup.sites)?.sort(sorter(tableSortMetric, tableSortDirection)),
    };
  }, [regionId, regionData, sitesPlatforms, tableSortMetric, tableSortDirection, regionName]);

  const { data, filterValues } = useTableFilter({ data: sites, filterSequence, filters, search });

  return { isLoading, data, filterValues };
};

export default useSitesFromRegion;
