import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

const StyledCrewBadge = styled.div`
  height: 15px;
  width: 35px;
  border-radius: 12px;
  background: ${(props) =>
    props.active
      ? props.theme.manage.dayCard.crewBackgroundColorActive
      : props.theme.manage.dayCard.crewBackgroundColor};
  border: solid 1px ${(props) => props.theme.manage.dayCard.crewBorderColor};
  border-radius: 12px;
  color: ${(props) =>
    props.active
      ? props.theme.manage.dayCard.crewTextColor
      : props.theme.manage.dayCard.crewTextColorInActive};
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  margin: ${(props) =>
    props.overlap && props.split ? '0 6px 0 -5px' : props.overlap ? '0 0 0 -5px' : '0 6px 0 -5px'};
  box-shadow: ${(props) =>
    props.split ? '4px 0px 0 -1px ' + props.theme.manage.dayCard.crewBackgroundColor : 'none'};
  ${(props) => {
    if (props.specialist) return specialistCss;
    if (props.scheduled) return scheduledCss;
    return defaultCss;
  }};
  .nickname {
    height: 10px;
    color: ${(props) =>
      props.taskTable
        ? props.theme.entityDetails.tickets.badgeColor
        : props.theme.manage.dayCard.borderColor};
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    line-height: 16px;
    text-align: center;
`;
const defaultCss = css`
  background: ${(props) =>
    props.active
      ? props.theme.manage.dayCard.crewBackgroundColorActive
      : props.theme.manage.dayCard.crewBackgroundColor || props.taskTable
      ? props.theme.entityDetails.headerBadgeColor
      : ''};
  color: ${(props) =>
    props.active
      ? props.theme.manage.dayCard.crewTextColor
      : props.theme.manage.dayCard.crewTextColorInActive};
`;

const specialistCss = css`
  color: ${(props) => props.theme.manage.dayCard.specialistTextColor};
  background: ${(props) => props.theme.manage.dayCard.specialistBackgroundColor};
`;

const scheduledCss = css`
  color: ${(props) => props.theme.manage.dayCard.scheduled.crewTextColor};
  background: ${(props) => props.theme.manage.dayCard.scheduled.crewBackgroundColor};
`;
const MenuLinkIcon = styled(Icon)`
  margin: 0 1px 0 1px;
  color: ${(props) => props.theme.manage.dayCard.crewTextColor};
`;
export const CrewBadge = ({
  technician,
  scheduled,
  specialist,
  overlap,
  split,
  lead,
  active,
  taskTable,
}) => {
  return (
    <StyledCrewBadge
      scheduled={scheduled}
      specialist={specialist}
      overlap={overlap}
      split={split}
      lead={lead}
      active={active}
      taskTable={taskTable}
    >
      {lead && <MenuLinkIcon size={8} icon={Icons.PERSON} />}
      <div className="nickname">
        {technician?.initials || technician?.firstName?.substring(0, 5)}
      </div>
    </StyledCrewBadge>
  );
};

CrewBadge.propTypes = {
  technician: PropTypes.instanceOf(Object).isRequired,
  scheduled: PropTypes.bool,
  specialist: PropTypes.bool,
  overlap: PropTypes.bool,
  taskTable: PropTypes.bool,
  split: PropTypes.bool,
  lead: PropTypes.bool,
  active: PropTypes.bool,
};

CrewBadge.defaultProps = {
  scheduled: false,
  specialist: false,
  overlap: false,
  taskTable: false,
  split: false,
  lead: false,
  active: false,
};
