import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useIsFetching } from 'react-query';
import styled from 'styled-components';

import { IconRefresh, IconRefreshing } from '@ge/components/svg-icons';
import { Tooltip, placements } from '@ge/components/tooltip';
import { PresentationTypes } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';
import { elevations } from '@ge/tokens';

const StyledIcon = styled.button`
  padding: 0px 4px;
`;

export const TableRefreshButton = ({
  queryKey,
  title,
  manualRefresh,
  presentationType,
  onRefresh,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });

  const isFetching = useIsFetching(queryKey, { active: true, fetching: true });
  const queryClient = useQueryClient();

  const handleRefresh = useCallback(
    (e) => {
      killEventPropagation(e);
      queryClient.invalidateQueries(queryKey);
      onRefresh(queryKey);
    },
    [queryKey, onRefresh, queryClient],
  );

  if (!ready || presentationType !== PresentationTypes.LIST) return null;

  return isFetching ? (
    <Tooltip
      title={t('table_refresh_button.in_progress', 'Refresh in Progress')}
      zIndex={elevations.P20}
      placement={placements.TOP}
    >
      <StyledIcon>
        <IconRefreshing />
      </StyledIcon>
    </Tooltip>
  ) : (
    manualRefresh && (
      <Tooltip
        title={t('table_refresh_button.manual_refresh', { title })}
        zIndex={elevations.P20}
        placement={placements.TOP}
      >
        <StyledIcon onClick={handleRefresh}>
          <IconRefresh />
        </StyledIcon>
      </Tooltip>
    )
  );
};

TableRefreshButton.propTypes = {
  queryKey: PropTypes.array,
  title: PropTypes.string,
  manualRefresh: PropTypes.bool,
  presentationType: PropTypes.string,
  onRefresh: PropTypes.func,
};

TableRefreshButton.defaultProps = {
  queryKey: [],
  title: '',
  manualRefresh: true,
  presentationType: PresentationTypes.LIST,
  onRefresh: () => {},
};

export default TableRefreshButton;
