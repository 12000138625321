import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';
import { Dialog } from '@ge/components/modal';
import {
  FormMode,
  EntityType,
  NotesCategory,
  NotesScope,
  DataLoaderType,
} from '@ge/models/constants';
import { useNoteDialog } from '@ge/shared/hooks';

import { useCaseLinkedTasks } from '../use-linked-task-menu';

import NoteFooter from './note-footer';
import NoteForm from './note-form';
import NoteHeader from './note-header';
import NoteMessage from './note-message';
const NAMESPACE = 'entity-details';

const DialogWrapper = styled.div`
  width: 480px;
`;

export const NoteDialog = ({
  isOpen,
  mode,
  data,
  entity,
  entityType,
  onClose,
  onSaveNote,
  enableFileUpload,
  maxFiles,
  maxFileSizeMb,
}) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const [attachments, setAttachments] = useState([]);

  const { isLoading, error, fetch } = useNoteDialog({
    mode,
    entityType,
    onComplete: (response, error) => {
      if (!error) {
        onSaveNote(response, mode);
      }
    },
  });

  const {
    isLoading: isTaskNoteLoading,
    error: taskNoteError,
    fetch: fetchTaskNote,
  } = useNoteDialog({
    mode,
    entityType: EntityType.TASK,
    onComplete: (response, error) => {
      if (!error) {
        onSaveNote(response, mode);
      }
    },
  });

  const { tasks, isLoading: isTasksLoading } = useCaseLinkedTasks({ caseId: entity.id });

  // Configure form hook and define logic for submission.
  const { handleSubmit, register, reset, control: noteControl, errors } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (!isTasksLoading && tasks?.length > 0) {
      reset({
        task: {
          label: tasks[0].title,
          value: tasks[0].id,
        },
      });
    }
  }, [isTasksLoading, reset, tasks]);

  const handleUpload = useCallback(
    (selectedFiles) => {
      setAttachments(selectedFiles);
    },
    [setAttachments],
  );

  const onCancel = useCallback(() => {
    setAttachments([]);
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    (fields) => {
      const { title, note, externalViewing, isAddToCase, isAddToTask, task: taskOption } = fields;
      const values = {
        title,
        note,
        attachments,
        category: mode === FormMode.EDIT ? data.category : NotesCategory.NOTE,
        scope: externalViewing ? NotesScope.EXTERNAL : NotesScope.INTERNAL,
      };
      if (mode === FormMode.CREATE) {
        if (isAddToCase) {
          fetch({ ...values, entity }, data);
        }
        if (isAddToTask) {
          const task = tasks.find((t) => t.id === taskOption.value);
          fetchTaskNote({ ...values, entity: task }, data);
        }
      } else {
        if (data.entityType === EntityType.CASE) {
          fetch(values, data);
        } else if (data.entityType === EntityType.TASK) {
          const task = tasks.find((t) => t.id === data.domainId);
          fetchTaskNote(values, data, task);
        }
      }
      setAttachments([]);
      reset();
    },
    [attachments, mode, data, reset, fetch, entity, tasks, fetchTaskNote],
  );

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      contentWidth={true}
      onClose={onCancel}
      header={
        mode === FormMode.EDIT ? t('notes.edit_note', 'Edit Note') : t('notes.new_note', 'New Note')
      }
      footer={
        <NoteFooter
          isLoading={isLoading || isTaskNoteLoading}
          namespace={NAMESPACE}
          mode={mode}
          data={data}
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
          ref={register}
        />
      }
    >
      <DialogWrapper>
        <NoteHeader
          namespace={NAMESPACE}
          mode={mode}
          data={data}
          entity={entity}
          entityType={entityType}
        />
        <DataLoader type={DataLoaderType.GRID} isLoading={isTasksLoading}>
          <NoteForm
            namespace={NAMESPACE}
            mode={mode}
            isLoading={isLoading || isTaskNoteLoading}
            data={data}
            files={attachments}
            enableFileUpload={enableFileUpload}
            maxFiles={maxFiles}
            maxFileSizeMb={maxFileSizeMb}
            handleUpload={handleUpload}
            ref={register}
            entity={entity}
            entityType={entityType}
            noteControl={noteControl}
            errors={errors}
          />
          <NoteMessage namespace={NAMESPACE} message={error || taskNoteError} />
        </DataLoader>
      </DialogWrapper>
    </Dialog>
  );
};

NoteDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveNote: PropTypes.func,
  mode: PropTypes.oneOf(Object.values(FormMode)),
  data: PropTypes.instanceOf(Object),
  entity: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    createdBy: PropTypes.string,
    creationDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    asset: PropTypes.shape({ name: PropTypes.string }),
    site: PropTypes.shape({ name: PropTypes.string }),
    taskIds: PropTypes.arrayOf(PropTypes.string),
  }),
  entityType: PropTypes.oneOf(Object.values(EntityType)),
  enableFileUpload: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxFileSizeMb: PropTypes.number,
};

NoteDialog.defaultProps = {
  isOpen: false,
  onClose: () => null,
  onSaveNote: () => null,
  mode: null,
  entity: null,
  entityType: null,
  enableFileUpload: true,
  maxFiles: 5,
  maxFileSizeMb: 4,
};
