import { PropTypes } from 'prop-types';
import React from 'react';
//import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { EntityType, Capability, Placeholders } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { AuthRender } from '@ge/shared/components/auth-render';
import { chartColors } from '@ge/tokens';

import useAssetCaseMetrics from '../../data-hooks/use-asset-case-metrics';

const AssetColor = styled.div`
  display: block;
  height: 37px;
  width: 6px;
  border-radius: 2px;
  background: ${(props) => chartColors.level1[props.index]};
  margin-right: 10px;
  cursor: pointer;
`;

const SelectedBlock = css`
  border: solid 1px ${(props) => props.theme.dataExplorer.header.assetBar.selectedBorderColor};
  box-shadow: 0 0 5px ${(props) => props.theme.dataExplorer.header.assetBar.assetBoxShadow};
  font-weight: 600;
  ${AssetColor} {
    background: ${(props) => props.theme.dataExplorer.charts.selectedAssetColor};
  }
`;

const SingleAssetBlock = css`
  border: 0;
  width: 100%;
  border-radius: 6px;
  .top button:last-child {
    margin-left: initial;
  }
`;

const SmallAssetBlock = css`
  .top button {
    max-width: 100px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .bottom button {
    font-size: 14px;
    line-height: 14px;
  }
`;

const StyledBlock = styled.div`
  padding: 8px 12px;
  font-size: 11px;
  display: flex;
  border-right: solid 1px ${(props) => props.theme.dataExplorer.header.assetBar.borderColor};
  background: ${(props) => props.theme.dataExplorer.header.assetBar.backgroundColor};

  &:last-child {
    border-radius: 0;
  }
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
  ${({ isSelected }) => isSelected && SelectedBlock}
  ${({ isSmall }) => isSmall && SmallAssetBlock}
  ${({ isSingleAsset }) => isSingleAsset && SingleAssetBlock}
  .top {
    display: flex;
    margin-bottom: 6px;
  }
  .bottom {
    display: flex;
    align-items: center;
    button {
      font-size: 20px;
      line-height: 20px;
      font-family: 'GE Inspira';
    }
  }
`;

const AssetDetails = styled.div`
  margin-left: auto;
  display: flex;

  .details {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.dataExplorer.header.assetBar.textColor};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px;

    > div {
      &:first-child {
        margin-bottom: 6px;
      }

      text-align: right;

      > div {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .actions {
    padding: 2px 8px;
    border-left: 1px solid ${(props) => props.theme.dataExplorer.header.assetBar.actionsBorderColor};
    flex-direction: column;
  }
`;

const HeaderIcon = styled(Icon).attrs((props) => ({
  size: props.size || 11,
  color: props.theme.dataExplorer.header.iconColor,
}))`
  margin-right: 5px;
`;

const MetricIcon = styled(Icon).attrs((props) => ({
  size: props.size || 11,
  color: props.theme.dataExplorer.header.iconColor,
}))`
  margin-right: 4px;
`;

const RemoveIcon = styled(Icon).attrs((props) => ({
  size: props.size || 11,
  color: props.theme.dataExplorer.header.removeIconColor,
  icon: Icons.CLOSE,
}))``;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: currentColor;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
  span {
    padding: 0 2px 0 0;
    white-space: nowrap;
  }
`;

const RemoveButton = styled(StyledButton)`
  align-self: flex-start;
  margin-left: auto;
`;

const RemoveButtonWithMargin = styled(RemoveButton)`
  margin: 4px 10px 0 0;
`;

// Commenting for Merkur release
/* const StyledDetailButton = styled(StyledButton)`
  color: currentcolor;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: 6px;
  text-transform: uppercase;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
`; */

const StyledCheckbox = styled(Checkbox)``;

const AssetRemoveButton = (props) => {
  if (props.withMargins) {
    return (
      <RemoveButtonWithMargin {...props}>
        <RemoveIcon />
      </RemoveButtonWithMargin>
    );
  }
  return (
    <RemoveButton {...props}>
      <RemoveIcon />
    </RemoveButton>
  );
};

const AssetBlock = ({
  index,
  assetState,
  asset,
  handleEntitySelect,
  handleAssetRemove,
  toggleComparisonAsset,
}) => {
  //const { t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });

  const { data: assetMetricsData, isLoading: isAssetMetricsLoading } = useAssetCaseMetrics(
    assetState.selectedAssetId,
  );

  return (
    <StyledBlock
      isSelected={assetState.selectedAssetId === asset.asset.id}
      isSmall={assetState.assets?.length >= 6}
      isSingleAsset={assetState.assets?.length === 1}
      data-testid={AnalyzeLocators.ANALYZE_ADDED_ASSET.concat(asset.asset.id)}
    >
      <AssetColor
        data-testid={AnalyzeLocators.ANALYZE_ASSET_SELECTION_BAR}
        index={index}
        onClick={() => assetState.setSelectedAsset(asset.asset.id)}
      />
      <div>
        <div className="top">
          <StyledButton
            type="button"
            onClick={(e) => handleEntitySelect(e, asset.site.id, EntityType.SITE)}
          >
            <HeaderIcon icon={Icons.SITE} />
            <span>{asset.site.name}</span>
            <HeaderIcon icon={Icons.CHEVRON} rotate={-90} size={8} />
          </StyledButton>
          {assetState.assets?.length > 1 && (
            <AssetRemoveButton
              data-testid={AnalyzeLocators.ANALYZE_ASSET_REMOVE_BUTTON}
              type="button"
              onClick={(e) => handleAssetRemove(e, asset.asset.id)}
            />
          )}
        </div>
        <div className="bottom">
          {assetState.assets?.length > 1 && (
            <StyledCheckbox
              checkState={
                assetState.comparisonAssets.includes(asset.asset.id)
                  ? CheckedState.CHECKED
                  : CheckedState.UNCHECKED
              }
              onChange={() => toggleComparisonAsset(asset.asset.id, index)}
            />
          )}
          <StyledButton
            type="button"
            onClick={(e) => handleEntitySelect(e, asset.asset.id, EntityType.TURBINE)}
          >
            <HeaderIcon icon={Icons.TURBINE} size={16} />
            <span data-testid={AnalyzeLocators.ANALYZE_ASSET_RIBBON_ASSETNAME}>
              {asset.asset.name}
            </span>
            <HeaderIcon icon={Icons.CHEVRON} rotate={-90} />
          </StyledButton>
        </div>
      </div>
      {!isAssetMetricsLoading && assetState.assets?.length === 1 && (
        <AssetDetails>
          <div className="details">
            <div>
              <div>
                <MetricIcon icon={Icons.WIND_BARB_20} />
                {`${asset.asset.wind ?? Placeholders.DOUBLE_DASH}`}
              </div>
              {/* Commented for MVP0 */}
              {/* <div>
                          <MetricIcon icon={Icons.TEMPERATURE} />
                          {`${asset.asset.temp}`}
                        </div> */}
              <div>{`${asset.asset.make} ${asset.asset.model}`}</div>
            </div>
            <div>
              <div>
                <MetricIcon icon={Icons.EVENT} />
                {`${asset.asset.events ?? Placeholders.DOUBLE_DASH}`}
              </div>
              <AuthRender capability={Capability.CASES_RECOMMENDATIONS} siteLevel={false} view>
                <div>
                  <MetricIcon icon={Icons.ANOMALY} />
                  {`${assetMetricsData?.openCases ?? '-'}`}
                </div>
              </AuthRender>
              <div>
                <MetricIcon icon={Icons.TASK} />
                {`${asset.asset.tasks ?? Placeholders.DOUBLE_DASH}`}
              </div>
            </div>
          </div>

          <AssetRemoveButton
            withMargins
            type="button"
            onClick={(e) => handleAssetRemove(e, asset.asset.id)}
          />
          {/* Commenting for Merkur release until further change in the requirement */}
          {/* <div className="actions">
            <StyledDetailButton type="button">
              <HeaderIcon icon={Icons.PHONE_CONTACT} size={12} />
              {t('contact', 'contact')}
            </StyledDetailButton>
            
            { <StyledButton type="button">
                        <HeaderIcon icon={Icons.PENCIL} />
                        {t('add_note', 'add note')}
                      </StyledButton> }
          </div> */}
        </AssetDetails>
      )}
    </StyledBlock>
  );
};

AssetRemoveButton.propTypes = {
  withMargins: PropTypes.bool,
};

AssetBlock.propTypes = {
  index: PropTypes.number,
  assetState: PropTypes.object,
  asset: PropTypes.object,
  handleEntitySelect: PropTypes.func,
  handleAssetRemove: PropTypes.func,
  toggleComparisonAsset: PropTypes.func,
};

export default AssetBlock;
