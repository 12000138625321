import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import {
  NAMESPACE,
  PlotFields,
  ChartTypeOption,
  ChartTypeOptionDefs,
} from '@ge/feat-analyze/models/configure-analysis-template';
import { SelectMetaField } from '@ge/shared/components/meta-fields';

const Select = styled(SelectMetaField)`
  white-space: nowrap;
  text-transform: capitalize;
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const Container = styled.div`
  display: flex;
`;

const Count = styled.div`
  color: ${(props) => props.theme.select.labelTextColor};
  padding: 10px 5px 0px 0px;
`;

const Content = styled.div`
  flex: 1;
  min-height: 240px;
  border: 1px solid #2a323b;
  border-radius: 4px;
  background-color: #1c252d;
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #2a323b;
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

const ContentTitle = styled.div`
  flex: 1;
`;

const ChartRow = styled.div`
  margin-bottom: 15px;
`;

const AxisContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  div:fisrt-child {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: span ${({ rowCount }) => rowCount};
  }
`;

const AxisName = styled.div`
  margin-bottom: 8px;
`;

const SignalName = styled.div`
  margin-bottom: 8px;
  line-height: 1.5;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.button.textColor};
  display: flex;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 0px;
`;

const StyledButtonIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.button.iconColor,
}))`
  flex-shrink: 0;
  margin: 1px 7px 0 0px;
`;

const StyledIconButton = styled.button`
  padding: 1px 4px 0;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: 10,
  color: props.theme.button.iconColor,
}))`
  margin: 0;
  flex-shrink: 0;
`;

const ChartDetails = ({
  index,
  name,
  type,
  signals,
  onDeleteClick,
  onAddSignals,
  onEditSignals,
  onPlotTypeChange,
}) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const { register, setValue } = useFormContext();

  register(`${name}.signals`, { required: true });

  useEffect(() => {
    setValue(`${name}.signals`, signals ?? null);
  }, [name, signals, setValue]);

  const typeOptions = useMemo(
    () =>
      Object.values(ChartTypeOption).map((value) => ({
        value,
        label: t(
          `analysis_template.${ChartTypeOptionDefs[value].a11yKey}`,
          ChartTypeOptionDefs[value].a11yDefault,
        ),
      })),
    [t],
  );

  const yAxisMap = useMemo(() => {
    if (signals?.y?.length) {
      const _map = signals.y.reduce((acc, v) => {
        const yAxis = v.yAxis ?? 0;
        if (!acc[yAxis]) {
          acc[yAxis] = [{ ...v, yAxis }];
        } else {
          acc[yAxis].push({ ...v, yAxis });
        }
        return acc;
      }, {});
      return Object.values(_map);
    }
    return [];
  }, [signals]);

  const handleDrag = useCallback(() => {}, []);

  if (!ready) return null;

  return (
    <Container name={name}>
      <Count>{index < 9 ? `0${index + 1}` : index + 1}</Count>
      <Content>
        <ContentHeader>
          <ContentTitle>
            <Select
              name={`${name}.${PlotFields.PLOT_TYPE}`}
              label={t('analysis_template.chart.type', 'Type')}
              placeholder={t(`analysis_template.chart.type_placeholder`, 'Select Type')}
              defaultValue={type}
              options={typeOptions}
              metadata={{ required: true }}
              maxWidth={144}
              searchable={false}
              handleChange={(plotType) => onPlotTypeChange(index, plotType)}
            />
          </ContentTitle>
          <StyledIconButton
            title={t('analysis_template.delete', 'Delete')}
            onClick={() => onDeleteClick(index)}
          >
            <StyledIcon icon={Icons.TRASH} />
          </StyledIconButton>
          <StyledIconButton
            title={t('analysis_template.drag_to_reorder', 'Drag to Reorder')}
            onClick={handleDrag}
          >
            <StyledIcon icon={Icons.GRID} />
          </StyledIconButton>
        </ContentHeader>
        {yAxisMap.length > 0 ? (
          <>
            <ChartRow>
              <StyledButton onClick={() => onEditSignals(index)}>
                <StyledButtonIcon icon={Icons.PENCIL} />
                {t('analysis_template.add_signals', 'Edit Signals')}
              </StyledButton>
            </ChartRow>
            <AxisContent rowCount={Math.floor((yAxisMap.length - 1) / 2)}>
              <ChartRow key={signals.x.id}>
                <AxisName>{'X'}</AxisName>
                <SignalName>{signals.x.title}</SignalName>
              </ChartRow>
              {yAxisMap.map((yAxisSignals, i) => (
                <ChartRow key={i}>
                  <AxisName>{`Y${i + 1}`}</AxisName>
                  {yAxisSignals.map((signal) => (
                    <SignalName key={signal.id}>{signal.title}</SignalName>
                  ))}
                </ChartRow>
              ))}
            </AxisContent>
          </>
        ) : (
          <StyledButton onClick={() => onAddSignals(index)}>
            <StyledButtonIcon icon={Icons.ADD} />
            {t('analysis_template.add_signals', 'Add Signals')}
          </StyledButton>
        )}
      </Content>
    </Container>
  );
};

ChartDetails.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  signals: PropTypes.shape({ x: PropTypes.object, y: PropTypes.object }),
  handleEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAddSignals: PropTypes.func,
  onEditSignals: PropTypes.func,
  onPlotTypeChange: PropTypes.func,
};

ChartDetails.defaultValues = {
  className: '',
  index: 0,
  name: '',
  type: '',
  signals: null,
  handleEditClick: () => null,
  onDeleteClick: () => null,
  onAddSignals: () => null,
  onEditSignals: () => null,
  onPlotTypeChange: () => null,
};

export default ChartDetails;
