import dayjs from 'dayjs';
import is from 'ramda/src/is';
import isNil from 'ramda/src/isNil';
import path from 'ramda/src/path';

import { EventManagementColumnsFiltersArrayType } from '@ge/feat-admin/models/event-management-table-cols';
import { TasksColumns } from '@ge/feat-manage/models/tasks-table-cols';
import { TableFilterTypes, DateTimeFormats, TaskDateKey } from '@ge/models/constants';
import { searchStr } from '@ge/shared/util/general';

const { camelize } = require('@ge/util/string-utils');

// passing in filter values by ref to build the unique values for data set
// can refactor this, but since this func is used internally not sure it matters
const filterItem = ({ item, filterDict, isTaskTable }) => {
  let isFiltered = false;

  for (const [key, { max, min, type, value }] of filterDict) {
    let prop = path([camelize(key?.split('.').toString())], item) || path(key?.split('-'), item);

    // if prop is complex object, we expect there to be a description field
    // can revisit this, does it make more sense to call it 'label'?
    if (is(Object, prop)) {
      prop = prop.description ?? prop.name ?? prop.value;
    }
    if (type === TableFilterTypes.DATE && isTaskTable && item[key] !== '-') {
      let temp = `${camelize(key?.split('.').toString())}Tz`;
      if (temp === 'scheduledDateTz') {
        temp = TaskDateKey.SCHEDULE_DATE_TZ;
      }
      prop = path([temp], item);
      // can revisit this logic based on the requirement clarification
      if (prop == null) {
        isFiltered = true;
        continue;
      }
    }
    if (key === 'parts-expected' || key === 'parts-consumed') {
      prop = item[key]?.count;
    }

    // Checks if an empty cell is an empty string instead of null/undefined.
    if (value && !prop?.toString()?.length) {
      isFiltered = true;
      break;
    }

    // can revisit this logic
    if (prop == null) {
      continue;
    }
    // if prop is empty filter it
    if (prop === '') {
      isFiltered = true;
      continue;
    }
    switch (type) {
      case TableFilterTypes.CHECKBOXES: {
        if (value?.length) {
          if (
            key === TasksColumns.ASSIGNED_TECHS ||
            EventManagementColumnsFiltersArrayType.includes(key)
          ) {
            isFiltered = Array.isArray(prop)
              ? !value.some((elem) => prop.includes(elem))
              : !value.includes(prop);
          } else {
            isFiltered = Array.isArray(prop)
              ? !value.every((elem) => prop.includes(elem))
              : !value.includes(prop);
          }
        }
        break;
      }
      case TableFilterTypes.DATE: {
        const _prop = dayjs(
          dayjs(prop).format(DateTimeFormats.DEFAULT_DATE),
          DateTimeFormats.DEFAULT_DATE,
        );

        if (!_prop.isValid()) {
          continue;
        }

        if (value) {
          const _value = dayjs(
            dayjs(value).format(DateTimeFormats.DEFAULT_DATE),
            DateTimeFormats.DEFAULT_DATE,
          );

          if (_value.isValid()) {
            isFiltered = !_prop.isSame(_value);
          }
        }

        if (max && min) {
          const _max = dayjs(
            dayjs(max).format(DateTimeFormats.DEFAULT_DATE),
            DateTimeFormats.DEFAULT_DATE,
          );
          const _min = dayjs(
            dayjs(min).format(DateTimeFormats.DEFAULT_DATE),
            DateTimeFormats.DEFAULT_DATE,
          );

          if (_max.isValid() && _min.isValid()) {
            isFiltered = !_prop.isBetween(_min, _max) && !_prop.isSame(_min) && !_prop.isSame(_max);
          }
        }

        break;
      }
      case TableFilterTypes.NUMBER: {
        const checkMinMaxAbsent = max && min ? false : true;
        if (isTaskTable) {
          prop === '-' ? (prop = 0) : prop;
        }
        const _prop = Number.parseFloat(prop);

        if (Number.isNaN(_prop)) {
          continue;
        }

        if (value && checkMinMaxAbsent) {
          const _value = Number.parseFloat(value);

          if (!Number.isNaN(_value)) {
            isFiltered = _prop !== _value;
          }
        } else if (max && min) {
          const _max = Number.parseFloat(max);
          const _min = Number.parseFloat(min);
          if (
            !Number.isNaN(_min) &&
            !Number.isNaN(_max) &&
            Number.POSITIVE_INFINITY !== _min &&
            Number.POSITIVE_INFINITY !== _max &&
            Number.NEGATIVE_INFINITY !== _min &&
            Number.NEGATIVE_INFINITY !== _max
          ) {
            // Handle value, range
            isFiltered = _min > _prop || _prop > _max;
          } else if (!(Number.isNaN(_max) || Number.isNaN(_min))) {
            // Handle min, max filter for greater & less than
            isFiltered = _min >= _prop || _prop >= _max;
          }
        }
        break;
      }
      case TableFilterTypes.SEARCH: {
        if (isNil(value)) {
          isFiltered = false;
        } else if (Array.isArray(value)) {
          isFiltered = !value.some((_value) => searchStr(prop, _value));
        } else {
          isFiltered = !searchStr(prop, value);
        }

        break;
      }
      case TableFilterTypes.PROGRESSIVE_DROPDOWNS: {
        if (value?.length) {
          isFiltered = !value.every((sv) => Object.values(prop).includes(sv));
        }

        break;
      }
      case TableFilterTypes.PROGRESSIVE_MULTI_SELECT: {
        if (value?.length) {
          isFiltered = !value.every((sv, i) => sv.includes(prop[i]));
        }

        break;
      }
    }

    if (isFiltered) {
      break;
    }
  }

  return isFiltered;
};

const hasPrevFilter = ({ item, filterDict = [], filterSequence = [] }) => {
  const isMatched = filterSequence.every((prevFilterKey) => {
    let prevProp = path([prevFilterKey], item);
    if (is(Object, prevProp)) {
      prevProp = prevProp.description ?? prevProp.name ?? prevProp.value;
    }
    const filterValue = filterDict.find(([key]) => key === prevFilterKey)?.[1];
    if (!filterValue) return true;

    if (filterValue.value?.length) {
      if (
        filterValue.type === TableFilterTypes.PROGRESSIVE_MULTI_SELECT &&
        Array.isArray(prevProp)
      ) {
        return prevProp.every((val, i) => filterValue.value[i].includes(val));
      }

      if (filterValue.type === TableFilterTypes.NUMBER) {
        return filterValue.value.includes(String(prevProp));
      }

      return filterValue.value.includes(prevProp);
    }

    if (filterValue.type === TableFilterTypes.DATE) {
      const { min, max } = filterValue;
      if (max && min && prevProp) {
        const _prop = dayjs(
          dayjs(prevProp).format(DateTimeFormats.DEFAULT_DATE),
          DateTimeFormats.DEFAULT_DATE,
        );
        const _max = dayjs(
          dayjs(max).format(DateTimeFormats.DEFAULT_DATE),
          DateTimeFormats.DEFAULT_DATE,
        );
        const _min = dayjs(
          dayjs(min).format(DateTimeFormats.DEFAULT_DATE),
          DateTimeFormats.DEFAULT_DATE,
        );
        if (_max.isValid() && _min.isValid()) {
          return _prop.isBetween(_min, _max) || _prop.isSame(_min) || _prop.isSame(_max);
        }
      }
      return false;
    }

    if (filterValue.min && filterValue.max) {
      return filterValue.min < prevProp && filterValue.max > prevProp;
    }

    return true;
  });

  return isMatched;
};
// filter item
const filterItemUp = ({ item, filterDict, filterValues, isTaskTable, filterSequence }) => {
  //let isFiltered = false;

  for (const [key, { type, value }] of filterDict) {
    let prop = path([camelize(key?.split('.').toString())], item) || path(key?.split('-'), item);

    // if prop is complex object, we expect there to be a description field
    // can revisit this, does it make more sense to call it 'label'?
    if (is(Object, prop)) {
      prop = prop.description ?? prop.name ?? prop.value;
    }
    if (type === TableFilterTypes.DATE && isTaskTable && item[key] !== '-') {
      let temp = `${camelize(key?.split('.').toString())}Tz`;
      if (temp === 'scheduledDateTz') {
        temp = TaskDateKey.SCHEDULE_DATE_TZ;
      }
      prop = path([temp], item);
    }
    if (key === 'parts-expected' || key === 'parts-consumed') {
      prop = item[key]?.count;
    }

    // Checks if an empty cell is an empty string instead of null/undefined.
    if (value && !prop?.toString()?.length) {
      //isFiltered = true;
      break;
    }

    // can revisit this logic
    if (prop == null || prop == '-') {
      continue;
    }
    // Checks filter sequence.
    if (type === TableFilterTypes.CHECKBOXES && filterSequence?.length) {
      const prevFiltersSequence =
        filterSequence.indexOf(key) === -1
          ? filterSequence
          : filterSequence.slice(0, filterSequence.indexOf(key));

      if (prevFiltersSequence?.length) {
        const isMatchedPrevFilters = hasPrevFilter({
          item,
          filterDict,
          filterSequence: prevFiltersSequence,
        });

        if (!isMatchedPrevFilters) continue;
      }
    }
    // add value to static filter options if exists
    filterValues[key]?.add(prop);
  }
};

// for in-memory filtering
export const getDataFilter = ({ data, filters, isTaskTable }) => {
  if (!data?.length) {
    // is this the best default?
    return {};
  }

  const filterDict = Object.entries(filters ?? {});

  //.filter(([, { value }]) => value !== undefined);

  // right now building filter values dynamically from currently visible dataset
  // if that requirement changes, we can look at doing this only when the underlying dataset
  // changes, so hopefully once per session or after a refresh

  const filteredData = filterDict.length
    ? data.filter((item) => !filterItem({ item, filterDict, isTaskTable }))
    : data;

  return {
    data: filteredData,
  };
};

export const getDataFilterUp = ({ data, filters, isTaskTable, filterSequence }) => {
  if (!data?.length) {
    // is this the best default?
    return {};
  }

  const filterDict = Object.entries(filters ?? {});

  const getFilterValues = (filterType) =>
    filterDict
      .filter(([, { type }]) => type === filterType)
      .reduce((values, [key]) => {
        values[key] = new Set();
        return values;
      }, {});

  // right now building filter values dynamically from currently visible dataset
  // if that requirement changes, we can look at doing this only when the underlying dataset
  // changes, so hopefully once per session or after a refresh
  const filterValues = {
    ...getFilterValues(TableFilterTypes.PROGRESSIVE_MULTI_SELECT),
    ...getFilterValues(TableFilterTypes.PROGRESSIVE_DROPDOWNS),
    ...getFilterValues(TableFilterTypes.CHECKBOXES),
  };

  data.filter(
    (item) => !filterItemUp({ item, filterDict, filterValues, isTaskTable, filterSequence }),
  );

  return {
    filterValues: Object.entries(filterValues).reduce((_filterValues, [key, value]) => {
      _filterValues[key] = Array.from(value).sort((a, b) => {
        return String(a).localeCompare(b, undefined, { numeric: true, caseFirst: 'upper' });
      });
      return _filterValues;
    }, {}),
  };
};
