import { ChartType } from '@ge/components/charts';
import { AxisOptions, TimeSignal } from '@ge/models/constants';

import { ChartItem } from './chart-item';

export const DataExplorerAxisType = { X: 'X', Y: 'Y' };

export const DataExplorerOptions = {
  AD_HOC: 'ad-hoc',
  POWER_CURVE: 'power-curve',
  GENERATOR_TEMP: 'generator-temp',
};

export const DataExplorerTemplates = {
  [DataExplorerOptions.AD_HOC]: {
    charts: [
      new ChartItem({
        id: `chart-adhoc`,
        xAxis: TimeSignal,
        yAxis: [],
        type: ChartType.SCATTER,
        expended: true,
      }),
    ],
  },
  [DataExplorerOptions.POWER_CURVE]: {
    charts: [
      new ChartItem({
        id: `chart-power-curve`,
        xAxis: { id: AxisOptions.POWER_MWH, name: AxisOptions.POWER_MWH },
        yAxis: [{ id: AxisOptions.WIND_SPEED, name: AxisOptions.WIND_SPEED }],
        type: ChartType.SCATTER,
        expended: true,
      }),
    ],
  },
  [DataExplorerOptions.GENERATOR_TEMP]: {
    charts: [
      new ChartItem({
        id: `chart-generator-temp-main`,
        xAxis: { id: AxisOptions.POWER_MWH, name: AxisOptions.POWER_MWH },
        yAxis: [{ id: AxisOptions.WIND_SPEED, name: AxisOptions.WIND_SPEED }],
        type: ChartType.SCATTER,
        expended: true,
      }),
      new ChartItem({
        id: `chart-generator-temp-1`,
        xAxis: { id: AxisOptions.POWER_MWH, name: AxisOptions.POWER_MWH },
        yAxis: [{ id: AxisOptions.WIND_SPEED, name: AxisOptions.WIND_SPEED }],
        type: ChartType.SCATTER,
      }),
      new ChartItem({
        id: `chart-generator-temp-1`,
        xAxis: { id: AxisOptions.POWER_MWH, name: AxisOptions.POWER_MWH },
        yAxis: [{ id: AxisOptions.WIND_SPEED, name: AxisOptions.WIND_SPEED }],
        type: ChartType.SCATTER,
      }),
    ],
  },
};
