import dayjs from 'dayjs';

import { SortValueType, TaskDateKey } from '@ge/models/constants';
import { filterByView } from '@ge/shared/util';
import { sorter } from '@ge/util/metric-sorter';

import { getFilteredTasksTrackerData, getSelectedTaskTrackerData } from './tasks-tracker-util';

export const updateTimezoneTaskFields = (hydratedTasks, sites) => {
  const dateCache = (date, timezone) => dayjs(date).tz(timezone);
  const taskDates = Object.entries(hydratedTasks).map(([id, task]) => {
    const { site } = task;
    const { timezone } = sites[site?.id] ?? {};
    const taskDateInfo = { id };
    if (timezone) {
      taskDateInfo[TaskDateKey.COMPLETED_DATE_TIME_TZ] =
        task[TaskDateKey.COMPLETED_DATE_TIME] &&
        dateCache(task[TaskDateKey.COMPLETED_DATE_TIME], timezone);
      taskDateInfo[TaskDateKey.CREATE_DATE_TZ] =
        task[TaskDateKey.CREATE_DATE] && dateCache(task[TaskDateKey.CREATE_DATE], timezone);
      taskDateInfo[TaskDateKey.DUE_DATE_TZ] =
        task[TaskDateKey.DUE_DATE] && dateCache(task[TaskDateKey.DUE_DATE], timezone);
      taskDateInfo[TaskDateKey.ELIGIBLE_START_DATE_TZ] =
        task[TaskDateKey.ELIGIBLE_START_DATE] &&
        dateCache(task[TaskDateKey.ELIGIBLE_START_DATE], timezone);
      taskDateInfo[TaskDateKey.SCHEDULE_DATE_TZ] =
        task[TaskDateKey.SCHEDULE_DATE] && dateCache(task[TaskDateKey.SCHEDULE_DATE], timezone);
      taskDateInfo[TaskDateKey.START_DATE_TIME_TZ] =
        task[TaskDateKey.START_DATE_TIME] && dateCache(task[TaskDateKey.START_DATE_TIME], timezone);
      taskDateInfo[TaskDateKey.COMMITTED_DATE_TZ] =
        task[TaskDateKey.COMMITTED_DATE] && dateCache(task[TaskDateKey.COMMITTED_DATE], timezone);
    }
    return taskDateInfo;
  });
  taskDates.forEach((taskDateInfo) => {
    const task = hydratedTasks[taskDateInfo.id];
    task[TaskDateKey.COMPLETED_DATE_TIME_TZ] = taskDateInfo[TaskDateKey.COMPLETED_DATE_TIME_TZ];
    task[TaskDateKey.CREATE_DATE_TZ] = taskDateInfo[TaskDateKey.CREATE_DATE_TZ];
    task[TaskDateKey.DUE_DATE_TZ] = taskDateInfo[TaskDateKey.DUE_DATE_TZ];
    task[TaskDateKey.ELIGIBLE_START_DATE_TZ] = taskDateInfo[TaskDateKey.ELIGIBLE_START_DATE_TZ];
    task[TaskDateKey.SCHEDULE_DATE_TZ] = taskDateInfo[TaskDateKey.SCHEDULE_DATE_TZ];
    task[TaskDateKey.START_DATE_TIME_TZ] = taskDateInfo[TaskDateKey.START_DATE_TIME_TZ];
    task[TaskDateKey.COMMITTED_DATE_TZ] = taskDateInfo[TaskDateKey.COMMITTED_DATE_TZ];
  });
};

export const hydrateTask = (task, { assets, sites, technicians }) => {
  const hydratedTask = { ...task };
  if (task.asset?.id && assets[task.asset.id]) {
    const taskAsset = assets[task.asset.id];
    hydratedTask.asset = {
      id: taskAsset.id,
      name: taskAsset.name,
      state: taskAsset.metrics?.state,
      // TODO - we need to get this from some association
      events: 4,
      anomalies: 2,
      tasks: 4,
      openEvents: 1,
      openAnomalies: 2,
    };
  }
  if (task.site && sites[task.site.id]) {
    const taskSite = sites[task.site.id];
    hydratedTask.site = {
      id: taskSite.id,
      name: taskSite.name,
      timezone: taskSite.timezone,
    };
  }
  if (task.crew) {
    hydratedTask.crew = Object.values(technicians)
      .filter((tech) => tech.crew && tech.crew.id === task.crew.id)
      .map((tech) => {
        const { crew, ...rest } = tech;
        return rest;
      });
  }
  if (task.specialist && technicians[task.specialist.id]) {
    hydratedTask.specialist = technicians[task.specialist.id];
  }

  return hydratedTask;
};

export const getSortedTasks = (
  hydratedTasks,
  currentView,
  sortMetric,
  sortDirection,
  tasksTrackerData,
) => {
  let taskArray = hydratedTasks;

  if (currentView) {
    taskArray = filterByView(taskArray, currentView);
  }
  const sortedTasks = taskArray.sort(sorter(sortMetric, sortDirection, SortValueType.ALPHANUMERIC));
  const taskTrackerData = getSelectedTaskTrackerData(tasksTrackerData);
  return taskTrackerData && sortedTasks?.length
    ? getFilteredTasksTrackerData(sortedTasks, taskTrackerData)
    : sortedTasks;
};

export const getTaskById = (tasks, taskId) => tasks?.find((task) => taskId === task?.id);
