import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { Loader } from '@ge/components/loader';
import { Severity } from '@ge/components/severity';
import { Table, Tbody, Td, Th, Thead } from '@ge/components/table';
import { DateTimeFormats, PanelColumnWidths } from '@ge/models/constants';
import { getDuration } from '@ge/shared/util/time-date';

const StyledTr = styled.tr`
  line-height: 16px;
`;

const StyledTd = styled(Td)`
  padding-left: 0;
`;

const StyledCode = styled.span`
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
`;

const StyledDesc = styled.span`
  margin-left: 6px;
`;

const StyledCheckbox = styled(Checkbox)`
  &.causal-checkbox {
    justify-content: center;
    span {
      margin: 0;
    }
  }
`;

const StyledTimeDate = styled.div`
  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 11px;
    }

    &:last-of-type {
      color: ${(props) => props.theme.table.textLightColor};
    }
  }
`;

const EventAlarmsTable = ({
  alarms,
  sortAction,
  sortMetric,
  sortDirection,
  causalEvent,
  setCausalEvent,
  openFhp,
}) => {
  const { t, ready } = useTranslation(['entity-details'], {
    useSuspense: false,
  });
  const { getSiteById } = useStoreState((store) => store.sites);
  const { getAssetById } = useStoreState((store) => store.assets);
  const [isHovering, setIsHovering] = useState(false);

  const sortedDirection = (metric) => (metric === sortMetric ? sortDirection : '');

  if (!ready) {
    return <Loader />;
  }

  return (
    <Table height={400} compressed>
      <Thead transparent noTitles>
        <StyledTr>
          {/* Hiding for MVP0 - Story #535 - remove conditional render component */}
          <ConditionalRender shouldRender={false}>
            <Th
              labels={{
                [t('alarms_table.causal', 'Causal')]: 'Causal',
              }}
              sortedDirection={sortedDirection}
              onClick={sortAction}
              width={PanelColumnWidths.XSMALL}
            />
          </ConditionalRender>
          <Th
            labels={{
              [t('alarms_table.code', 'Code / #')]: 'code',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XSMALL}
          />
          <Th
            labels={{
              [t('alarms_table.sev', 'Sev.')]: 'severity',
            }}
            align="right"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XXSMALL}
          />
          <Th
            labels={{
              [t('alarms_table.title', 'Title')]: 'description',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.XXLARGE}
          />
          <Th
            labels={{
              [t('alarms_table.duration', 'Duration')]: 'duration',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.MEDIUM}
          />
          <Th
            labels={{
              [t('alarms_table.start_site_est', 'Start Site (Est)')]: 'start',
            }}
            align="left"
            sortedDirection={sortedDirection}
            onClick={sortAction}
            width={PanelColumnWidths.LARGE}
          />
        </StyledTr>
      </Thead>
      <Tbody transparent>
        {alarms.map((alarm) => {
          const site = getSiteById(alarm.site?.id);
          const asset = getAssetById(alarm.asset?.id);

          const renderCheckBox = () => {
            if (isHovering === alarm.id || causalEvent === alarm.id) {
              return (
                <StyledCheckbox
                  className="causal-checkbox"
                  checkState={calcCheckState()}
                  onChange={() => setCausalEvent(alarm.id)}
                />
              );
            }
            return null;
          };

          const calcCheckState = () => {
            if (causalEvent === alarm.id) {
              return CheckedState.CHECKED;
            }
            return CheckedState.UNCHECKED;
          };

          if (!site || !asset) {
            return null;
          }

          // Required details
          const { state } = asset.metrics || {};
          const { conditions } = site.metrics || {};
          const { tickets } = site;

          // Don't render until we have all the required information.
          if (!state || !conditions || !tickets) {
            return null;
          }

          return (
            <StyledTr
              key={alarm.id}
              onClick={() => openFhp(alarm)}
              onMouseEnter={() => setIsHovering(alarm.id)}
              onMouseLeave={() => setIsHovering(false)}
            >
              {/* Hiding for MVP0 - Story #535 - remove conditional render component */}
              <ConditionalRender shouldRender={false}>
                <Td border onClick={(e) => e.stopPropagation()} width={PanelColumnWidths.XSMALL}>
                  {renderCheckBox()}
                </Td>
              </ConditionalRender>
              <Td border align="left" width={PanelColumnWidths.XSMALL}>
                <StyledCode>{alarm.code}</StyledCode>
              </Td>
              <StyledTd align="right" width={PanelColumnWidths.XXSMALL}>
                <Severity level={alarm.severity} />
              </StyledTd>
              <StyledTd border align="left" width={PanelColumnWidths.XXLARGE}>
                <StyledDesc>{alarm.description}</StyledDesc>
              </StyledTd>
              <Td border align="left" width={PanelColumnWidths.MEDIUM}>
                {getDuration(alarm.start, alarm.end).formatted}
              </Td>
              <Td border align="left" width={PanelColumnWidths.LARGE}>
                <StyledTimeDate>
                  <span>{dayjs(alarm.start).format(DateTimeFormats.DEFAULT_TIME)}</span>
                  <span>{dayjs(alarm.start).format(DateTimeFormats.DEFAULT_DATE)}</span>
                </StyledTimeDate>
              </Td>
            </StyledTr>
          );
        })}
      </Tbody>
    </Table>
  );
};

EventAlarmsTable.propTypes = {
  alarms: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  sortAction: PropTypes.func,
  sortMetric: PropTypes.string,
  sortDirection: PropTypes.string,
  causalEvent: PropTypes.string,
  onEventSelect: PropTypes.func,
  setCausalEvent: PropTypes.func,
  openFhp: PropTypes.func,
};

EventAlarmsTable.defaultProps = {
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
  causalEvent: null,
  onEventSelect: () => null,
  setCausalEvent: () => null,
  openFhp: () => null,
};

export default EventAlarmsTable;
