import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Select } from '@ge/components/select';
import { TaskSourceField } from '@ge/models/constants';
import { TaskContext } from '@ge/shared/components/tasks/task-context';
import { AppScopes } from '@ge/shared/models/scopes';
import { findScopesByRoute } from '@ge/web-client/src/app/services/feature';

import { BuildOption } from '../task-template-shared';

const StyledSelect = styled(Select)`
  text-transform: capitalize;
  margin-right: 20px;
`;

export const TaskWorkScopeSelect = () => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  const {
    taskState: { taskWorkScope, taskWorkScopes, setTaskWorkScope },
  } = useContext(TaskContext);

  const { control, setValue } = useFormContext();

  const [typeSelectOptions, setTypeSelectOptions] = useState([{}]);
  const path = useLocation().pathname;
  const appScope = findScopesByRoute(path);
  const scopePath = AppScopes.MANAGE_CASES_TASKS;
  const scopePathOverview = AppScopes.MANAGE_OVERVIEW;
  // service is managed via the TaskProvider context at a higher level in the task-template.jsx
  useEffect(() => {
    setValue('taskWorkScope', taskWorkScope);
  }, [setValue, taskWorkScope]);

  useEffect(() => {
    const allTypes = Object.entries(taskWorkScopes).map(([key, val]) => BuildOption(key, val));
    let selectOptions;
    if (appScope === scopePath || appScope === scopePathOverview) {
      selectOptions = allTypes.filter((option) => option.value !== TaskSourceField.ANOMALY);
    } else {
      selectOptions = allTypes;
    }
    setTypeSelectOptions(selectOptions);
  }, [taskWorkScopes, setTypeSelectOptions, appScope, scopePath, scopePathOverview]);

  const handleTypeSelect = useCallback(
    (option) => {
      setTaskWorkScope(option.value);
      setTimeout(() => {
        setValue('taskWorkScope', option.value, { shouldDirty: true });
      }, 0);
    },
    [setTaskWorkScope, setValue],
  );

  return (
    <Controller
      control={control}
      defaultValue={taskWorkScope}
      name="taskWorkScope"
      render={(value) => (
        <StyledSelect
          id="taskWorkScopeSelect"
          label={t('form.work_scope', 'Work Scope')}
          onChange={(option) => handleTypeSelect(option)}
          options={typeSelectOptions}
          value={typeSelectOptions.filter((option) => option.value === value.value)}
          minWidth={150}
          marginRight={20}
        />
      )}
    />
  );
};
TaskWorkScopeSelect.propTypes = {
  workScope: PropTypes.object,
};
TaskWorkScopeSelect.defaultProps = {
  workScope: {},
};
