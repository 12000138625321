import { useQuery } from 'react-query';

import { AlertType, AlertsEntityType, QueryKey } from '@ge/models/constants';
import { delay } from '@ge/shared/util';

import { Config } from './config';

export const useAlertTypes = ({ entityType, isActive = true }) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.ALERT_TYPES, entityType],
    // TODO: get these template types from backend
    () =>
      delay(() => {
        switch (entityType) {
          case AlertsEntityType.SITE:
            return [
              AlertType.NOTAM,
              AlertType.PLANNED_OUTAGE,
              AlertType.UNPLANNED_OUTAGE,
              AlertType.COMMISSIONING_OUTAGE,
              AlertType.RELIABILITY_RUN,
            ];
          case AlertsEntityType.WIND_TURBINE:
            return Object.values(AlertType);
          case AlertsEntityType.SUBSTATION:
          case AlertsEntityType.WIND_SITE_CONTROLLER:
            return [AlertType.PLANNED_OUTAGE, AlertType.UNPLANNED_OUTAGE, AlertType.NOCOMM_OUTAGE];
          case AlertsEntityType.INVERTER:
          case AlertsEntityType.STORAGE_SITE:
            return [AlertType.PLANNED_OUTAGE, AlertType.UNPLANNED_OUTAGE];
          default:
            return [];
        }
      }, 500),
    {
      ...Config.EXECUTE_ONCE,
      enabled: isActive,
    },
  );

  return { data, error, isLoading };
};
