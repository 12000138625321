// TODO: can move collapsible panel out of here if it makes sense
import { PropTypes } from 'prop-types';
// TODO: move some of the task template stuff we're using in alerts up a level?
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CollapsiblePanel } from '@ge/components/collapsible-panel';
import { FileUpload } from '@ge/components/file-upload';
import { FormMode, FileType } from '@ge/models/constants';

const AllowedFileTypes = [
  FileType.JPEG,
  FileType.PNG,
  FileType.XLS,
  FileType.XLSX,
  FileType.PPT,
  FileType.PPTX,
  FileType.PDF,
];

const StyledCollapsiblePanel = styled(CollapsiblePanel).attrs(() => ({
  className: 'collapsible',
}))`
  flex: 1 0;
  margin-left: 0;
  &.expanded {
    transition: max-height 0s ease;
  }
  .collapsible-panel-content {
    margin-top: 10px;
  }
  .title {
    border-bottom: 0;
    text-transform: uppercase;
    display: ${(props) => props.hideCollapsiblePanel && 'none'};
    svg {
      fill: ${(props) => props.theme.newTaskDialog.collapsibleIcon};
      width: 12px;
      height: 12px;
      padding: 0;
      margin-right: 6px;
    }
  }
  textarea {
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const AttachmentField = ({
  collapsed,
  hideCollapsiblePanel,
  hidden,
  files,
  maxFiles,
  maxFileSizeMb,
  allowedFileTypes,
  mode,
  onToggleExpand,
  handleUpload,
  uploadTemplateMode,
}) => {
  const { t, ready } = useTranslation(['general'], { useSuspense: false });

  // state
  const [resizeToggle, setResizeToggle] = useState(false);

  // TODO: look into collapsible panel resizing automatically when contents change size
  useEffect(() => {
    // wrapping in setTimeout so it will resize correctly once child is fully rendered
    const cancelId = setTimeout(() => setResizeToggle((prev) => !prev));
    return () => clearTimeout(cancelId);
  }, [files]);

  if (!ready || hidden || mode === FormMode.VIEW) {
    return null;
  }

  const translations = {
    headerLabel: t('file_upload.attachments_label', 'Attachments'),
  };

  return [FormMode.CREATE, FormMode.EDIT].includes(mode) ? (
    <StyledCollapsiblePanel
      divider={false}
      expanded={!collapsed}
      hideCollapsiblePanel={hideCollapsiblePanel}
      headerContent={<h4>{translations.headerLabel}</h4>}
      onToggleExpand={onToggleExpand}
      triggerHeight={resizeToggle}
      plusIcon={true}
    >
      <FileUpload
        files={files}
        maxFileSizeMb={maxFileSizeMb}
        maxFiles={maxFiles}
        allowedFileTypes={allowedFileTypes}
        onUpload={handleUpload}
        hideCollapsiblePanel={hideCollapsiblePanel}
        uploadTemplateMode={uploadTemplateMode}
      />
    </StyledCollapsiblePanel>
  ) : null;
};

AttachmentField.propTypes = {
  collapsed: PropTypes.bool,
  hideCollapsiblePanel: PropTypes.bool,
  hidden: PropTypes.bool,
  files: PropTypes.array,
  maxFiles: PropTypes.number,
  maxFileSizeMb: PropTypes.number,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  mode: PropTypes.oneOf(Object.values(FormMode)),
  onToggleExpand: PropTypes.func,
  handleUpload: PropTypes.func,
  uploadTemplateMode: PropTypes.bool,
};

AttachmentField.defaultProps = {
  collapsed: true,
  hideCollapsiblePanel: false,
  hidden: false,
  files: [],
  maxFiles: null,
  maxFileSizeMb: null,
  allowedFileTypes: AllowedFileTypes,
  mode: null,
  onToggleExpand: () => {},
  handleUpload: () => {},
};

AttachmentField.displayName = 'AttachmentField';
