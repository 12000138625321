import { useStoreState } from 'easy-peasy';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { downloadFile } from '@ge/feat-analyze/util';
import {
  EntityType,
  DateTimeFormats,
  QueryKey,
  ContentType,
  FileType,
  SortDirection,
} from '@ge/models/constants';
import { sorter } from '@ge/util/metric-sorter';

import { parseTimeZone } from '../util';

import { getFilename } from './use-export-graph';

const parseDate = (date, timezone) => {
  return date && timezone
    ? parseTimeZone(date, 'UTC', timezone, DateTimeFormats.CSV_DOWNLOAD_DATE_TIME)
    : date;
};

/**
 * Use assets power curve data download.
 */
export const useAssetPowerCurveDownload = () => {
  const { t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });
  const getAssetById = useStoreState((store) => store.assets.getAssetById);
  const queryClient = useQueryClient();

  const downloadPNG = () => {};

  const downloadCSV = useCallback(
    ({ entity, entityType, graph }) => {
      if (!entity || !entityType || !graph) return null;

      const { endDate, startDate, assetIds } = graph.metadata ?? {};

      const timezone = entityType === EntityType.ASSET ? entity.site?.timezone : entity.timezone;

      const fields = [
        t('asset_name', 'Asset Name'),
        t('date_time', 'Date/Time'),
        t('power_curve.x_axis_title', 'Wind Speed (m/sec)'),
        t('power_curve.y_axis_title', 'Power (MW)'),
      ];

      const data = assetIds.reduce((result, assetId) => {
        const { name: assetName } = getAssetById(assetId) ?? {};
        const queryKey = [QueryKey.ASSET_POWER_CURVE, assetId, { startDate, endDate }];
        const queryData = queryClient.getQueryData(queryKey);
        const data =
          queryData?.data?.data
            ?.find((v) => v.asset.id === assetId)
            ?.data?.sort(sorter('date', SortDirection.ASC))
            .map(({ x, y, date }) => [assetName, parseDate(date, timezone), x, y > 0 ? y : 0]) ??
          [];

        return result.concat(data);
      }, []);

      const filename = getFilename(entity, graph, FileType.CSV);

      downloadFile({ fields, data, filename, type: ContentType.CSV });
    },
    [queryClient, getAssetById, t],
  );

  return { downloadPNG, downloadCSV };
};
