import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { globalColors } from '@ge/tokens/colors';

import Logo from './images/logo.svg';

const StyledLoginContainer = styled.div`
  align-items: center;
  background: ${globalColors.grey1};
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const StyledLoginWrapper = styled.div`
  align-items: center;
  background-color: ${globalColors.slate2};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 318px;
  width: 474px;

  .login-button {
    background-image: linear-gradient(-180deg, #237b8c 0%, #1e6978 100%);
    border-radius: 4px;
    border: 1px solid #237383;
    padding: 4px 35px;
  }

  .title {
    color: ${globalColors.grey7};
    margin-bottom: 29px;
  }
`;

const StyledText = styled.p`
  color: ${globalColors.white};
  margin: 0 0 21px 0;
`;

const StyledImg = styled.img`
  margin-bottom: 21px;
  height: 60px;
  width: 60px;
`;

export const Logout = () => {
  const { t } = useTranslation(['general']);

  const history = useHistory();

  return (
    <Route
      component={() => (
        <StyledLoginContainer>
          <StyledLoginWrapper>
            <StyledImg src={Logo} alt="Logo" />
            <h1 className="title">{t('ge_renewables', 'GE Renewable Energy')}</h1>
            <StyledText className="body-1">
              {t('login_message', 'Sign in with your company SSO for access.')}
            </StyledText>
            <Button className="login-button" type="button" onClick={() => history.push('/')}>
              {t('go_to_sign_in', 'Go to Sign In')}
            </Button>
          </StyledLoginWrapper>
        </StyledLoginContainer>
      )}
    />
  );
};
