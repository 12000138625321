import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { TableDragDropContext, useDragDropContext } from './draggable-table-context';
import { DynamicTable } from './dynamic-table';
import { Td, Th } from './table';
import { useDragState } from './use-drag-state';

/**
 * Helper method to build the appropriate class string to decorate columns
 * adding separators between groups and highlighting columns while dragging.
 *
 * NOTE: This is a very expensive process. This requires rebuilding the entire
 * column structure to apply the appropriate styles. There may be a CSS-only
 * solution to explore to allow us to either apply this once for resting-state
 * column decorators and rely on CSS for drag highlighting or potentially a
 * CSS solution for both (ideally).
 *
 * @param {*} columns Columns used in the table
 * @param {*} groupKey Current column group key (id)
 * @param {*} columnKey Current column key (id)
 * @param {*} dragOver
 * @param {*} hoverColumn
 */
const buildColumnClasses = (columns, groupKey, columnKey, dragOver, hoverColumn) => {
  // Find the first and last column in group that the user
  // can view and add the on drag border and hover styles.
  const groupIndex = columns.findIndex((col) => col.id === groupKey);

  const firstVisibleColumn = columns[groupIndex]?.cols?.find((col) => !!col.visible);
  const firstVisibleColumnId = firstVisibleColumn && firstVisibleColumn.id;

  const isFirstDrop = firstVisibleColumnId === columnKey && dragOver === groupKey;
  const isGroupHover = hoverColumn === groupKey;

  // add drag/drop border styles to first item when drag over is true
  // add group hover styles
  // add hover border to first child
  // add hover border to last child
  const classes = [isFirstDrop ? 'group-first-drop' : '', isGroupHover ? 'group-hover' : ''].join(
    ' ',
  );

  return classes;
};

/**
 * Drag/Drop enabled DynamicTable that provides context and state management
 * for drag and drop of user-defined column structures.
 */
export const DraggableTable = ({ columns, dropHandler, className, ...props }) => {
  const dragState = useDragState(columns, dropHandler);

  return (
    <TableDragDropContext.Provider value={dragState}>
      <DynamicTable draggable={true} columns={columns} className={className} {...props} />
    </TableDragDropContext.Provider>
  );
};

DraggableTable.displayName = 'DraggableTable';

DraggableTable.propTypes = {
  ...DynamicTable.propTypes,
  dropHandler: PropTypes.func,
};

/**
 * Column Group for use inside a DraggableTable. Provides drag/drop
 * functionality and styling.
 */
export const DraggableColumnGroup = ({ groupKey, children, fixedLeft, fixedRight, ...props }) => {
  const {
    handlers: { handleDragStart, handleDragOver, handleDrop, handleDragEnter, handleHover },
    state: { dragOver },
  } = useDragDropContext();

  if (fixedLeft || fixedRight) {
    return (
      <Th
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e)}
        onDragEnter={() => handleDragEnter(groupKey)}
        dragOver={dragOver === groupKey}
        onFocus={() => handleHover(groupKey)}
        fixedLeft={fixedLeft}
        fixedRight={fixedRight}
        groupKey={groupKey}
        {...props}
      >
        {children}
      </Th>
    );
  }

  return (
    <Th
      draggable
      onDragStart={() => handleDragStart(groupKey)}
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e)}
      onDragEnter={() => handleDragEnter(groupKey)}
      dragOver={dragOver === groupKey}
      onFocus={() => handleHover(groupKey)}
      onMouseEnter={() => handleHover(groupKey)}
      onMouseLeave={() => handleHover('')}
      groupKey={groupKey}
      {...props}
    >
      {children}
    </Th>
  );
};

DraggableColumnGroup.propTypes = {
  ...Th.propTypes,
  groupKey: PropTypes.string.isRequired,
};

/**
 * Column component for use inside a DraggableTable. Provides styling for drag/drop
 * interactions.
 */
export const DraggableColumn = ({ className, columnKey, groupKey, children, ...props }) => {
  const {
    state: { columns, dragOver, hoverColumn },
  } = useDragDropContext();

  // Factory-computed classes.
  const hoverClasses = buildColumnClasses(columns, groupKey, columnKey, dragOver, hoverColumn);

  return (
    <Th
      {...props}
      columnKey={columnKey}
      groupKey={groupKey}
      className={[className, hoverClasses].join(' ')}
    >
      {children}
    </Th>
  );
};

DraggableColumn.propTypes = {
  ...Th.propTypes,
  groupKey: PropTypes.string.isRequired,
  columnKey: PropTypes.string.isRequired,
};

/**
 * Table Cell component for use inside a DraggableTable. Provides styling for drag/drop
 * interactions.
 */
const StyledTd = styled(Td)`
  margin: 0;
  padding: 0;
  .cell-radius-start {
    border-radius: 6px 0 0 6px !important;
  }
  .cell-radius-end {
    border-radius: 0 6px 6px 0 !important;
    margin-right: 10px;
  }
`;

export const DraggableCell = ({ className, columnKey, groupKey, children, ...props }) => {
  const {
    state: { columns, dragOver, hoverColumn },
  } = useDragDropContext();

  // Factory-computed classes.
  const hoverClasses = buildColumnClasses(columns, groupKey, columnKey, dragOver, hoverColumn);

  if (groupKey === 'group-assets') {
    return (
      <StyledTd
        {...props}
        columnKey={columnKey}
        groupKey={groupKey}
        className={[className, hoverClasses].join(' ')}
      >
        {children}
      </StyledTd>
    );
  }

  return (
    <Td
      {...props}
      columnKey={columnKey}
      groupKey={groupKey}
      className={[className, hoverClasses].join(' ')}
    >
      {children}
    </Td>
  );
};

DraggableCell.propTypes = {
  ...Td.propTypes,
  groupKey: PropTypes.string.isRequired,
  columnKey: PropTypes.string.isRequired,
};
