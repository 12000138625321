import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import { DateTimeFormats } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util';

import { ReportWidgetTable } from '../../tables/report-widget-table';

import {
  manuallyAdjustedEventsColumnDefs,
  ManuallyAdjustedEventsColumns,
} from './manually-adjusted-events-cols';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCheckbox = styled.div`
  label {
    display: inline-block;
    margin-top: 2px;
  }
  span {
    margin-left: 0;
  }
`;

const IncludeHeader = styled.span`
  color: ${({ theme }) => theme.createReport.widget.manuallyAdjustedEvents.includeTextColor};
`;

const BreakingDash = styled.span`
  display: block;
`;

export const ManuallyAdjustedEventsWidget = ({
  name,
  columns,
  data = [],
  isEditing,
  isInteractive,
  onRowSelectionChange,
  savedEntries,
}) => {
  const { t } = useTranslation(['reporting.widgets']);
  // initialize all rows being selected
  const [rowsSelected, setRowsSelected] = useState(data.map(({ id }) => id));

  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return '';
    }

    const st = dayjs.unix(startTime);
    const et = dayjs.unix(endTime);
    const mins = dayjs(et).diff(st, 'minutes', true);
    let hours = parseInt(mins / 60).toString();
    if (hours.length === 1) {
      hours = hours.padStart(2, '0');
    }
    let minutes = dayjs().minute(mins).$m;
    if (mins < 0) {
      minutes = mins > -60 ? mins : mins % 60;
    }
    return `${hours} hr ${minutes} m`;
  };

  // manage the state of the checkbox on a row
  const toggleCheckedRow = useCallback(
    (e, rowId) => {
      if (rowsSelected.includes(rowId)) {
        setRowsSelected(rowsSelected.filter((row) => row !== rowId));
      } else {
        setRowsSelected([...rowsSelected, rowId]);
      }
    },
    [rowsSelected],
  );

  useEffect(() => {
    onRowSelectionChange(rowsSelected);
  }, [onRowSelectionChange, rowsSelected]);

  useEffect(() => {
    setRowsSelected(savedEntries);
  }, [savedEntries]);

  /**
   * Render custom cells for specific columns
   */
  const customHeaderFn = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case ManuallyAdjustedEventsColumns.SELECTED:
          return (
            <IncludeHeader>
              {t('manually_adjusted_events.columns.include', 'Include')}
            </IncludeHeader>
          );
        default:
          return null;
      }
    },
    [t],
  );

  /**
   * Render custom cells for specific columns
   */
  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      switch (columnKey) {
        case ManuallyAdjustedEventsColumns.SELECTED:
          return (
            <StyledCheckbox
              onClick={(e) => {
                killEventPropagation(e);
                e.preventDefault();
                toggleCheckedRow(e, cellValue);
              }}
            >
              <Checkbox
                checkState={
                  rowsSelected.includes(cellValue) ? CheckedState.CHECKED : CheckedState.UNCHECKED
                }
                label={''}
              />
            </StyledCheckbox>
          );
        case ManuallyAdjustedEventsColumns.START_END_TIME:
          return (
            <span>
              {dayjs.unix(cellValue.startTime).format(DateTimeFormats.DEFAULT_DATE_TIME)}
              <BreakingDash>-</BreakingDash>
              {dayjs.unix(cellValue.endTime).format(DateTimeFormats.DEFAULT_DATE_TIME)}
            </span>
          );
        default:
          return cellValue;
      }
    },
    [rowsSelected, toggleCheckedRow],
  );

  /**
   * Map a row object to its column values
   */
  const cellValueMapFn = useCallback((cellValue) => {
    return {
      [ManuallyAdjustedEventsColumns.SELECTED]: cellValue.id,
      [ManuallyAdjustedEventsColumns.ASSET_NAME]: cellValue.assetId,
      [ManuallyAdjustedEventsColumns.CODE]: cellValue.malCategory,
      [ManuallyAdjustedEventsColumns.EVENT_CATEGORY]: cellValue.iecCategoryName,
      [ManuallyAdjustedEventsColumns.TITLE]: cellValue.malCategoryName,
      [ManuallyAdjustedEventsColumns.START_END_TIME]: cellValue,
      [ManuallyAdjustedEventsColumns.DURATION]: calculateDuration(
        cellValue.startTime,
        cellValue.endTime,
      ),
      // TODO: figure out what's shown when data is available (availabilityLoss, energyLoss, productionLoss, revenueLoss)
      // [ManuallyAdjustedEventsColumns.EVENT_IMPACT]: cellValue.eventImpact.productionLoss,
      [ManuallyAdjustedEventsColumns.ADJUSTED_DATE_TIME]: `${dayjs
        .unix(cellValue.adjustedTime)
        .format(DateTimeFormats.DEFAULT_DATE_TIME)}`,
      [ManuallyAdjustedEventsColumns.ADJUSTED_BY]: cellValue.adjustedBy,
      [ManuallyAdjustedEventsColumns.APPROVAL_DATE]: dayjs
        .unix(cellValue.approvedAt)
        .format(DateTimeFormats.DEFAULT_DATE_TIME),
    };
  }, []);

  // Table factories
  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: manuallyAdjustedEventsColumnDefs,
    cellValueMapFn,
    customCellFn,
    customHeaderFn,
  });

  return (
    <Container>
      <ReportWidgetTable
        key={name}
        compressed
        noTitles
        scrollable={isInteractive}
        transparent
        columnGroupFactory={columnGroupFactory}
        columnFactory={columnFactory}
        cellFactory={cellFactory}
        columns={columns}
        values={data}
        onValueSelect={() => null}
        rowsSelected={rowsSelected}
        rowKeyProperty="id"
        isEditing={isEditing}
        variableHeight={true}
      />
    </Container>
  );
};

ManuallyAdjustedEventsWidget.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  onRowSelectionChange: PropTypes.func.isRequired,
  savedEntries: PropTypes.arrayOf(PropTypes.string),
};
