import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { placements } from '@ge/components/menu';
import { TooltipCell } from '@ge/components/table/table';
import { Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

const Tr = styled.tr`
  cursor: pointer;
  border-bottom-color: ${({ theme }) => theme.manage.cards.trBorderBottom};
  th {
    border-bottom: 2px solid ${({ theme }) => theme.manage.cards.thBorderBottom};
  }
`;
const ActionsButton = styled.button`
  border-radius: 2px;
  position: relative;
  &:focus {
    outline: none;
  }
  padding: 1px;
  background: transparent;
  margin-right: 10px;
`;
const SnoozeIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.SNOOZE,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CLOSE,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;
const CalendarIcon = styled(Icon).attrs((props) => ({
  size: 9,
  icon: Icons.CALENDAR,
  color: props.theme.entityDetails.headerIconColor,
  hover: props.theme.entityDetails.hoverIconColor,
}))`
  margin-right: 5px;
  padding-right: 2px;
`;

export const CommonActions = ({ tasks, siteId, onCommonActionClick, setShowConfirmMessage }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  return (
    <>
      <Tr style={{ backgroundColor: globalColors.slate2 }}>
        <td colSpan={8} align="right">
          <ActionsButton
            onClick={() =>
              setShowConfirmMessage({
                isOpen: true,
                key: tasks[0]?.id,
                status: tasks[0]?.status,
                type: tasks[0]?.source,
                isMultiSelect: true,
              })
            }
          >
            <TooltipCell
              customClass="tooltip-backlog"
              placement={placements.TOP}
              tooltip={t('tasks:form.send_to_backlog', 'Send to Backlog')}
              zIndex={elevations.P101}
            >
              <CloseIcon />
            </TooltipCell>
          </ActionsButton>
          <AuthRender
            capability={Capability.WORK_PLAN}
            description="Snooze"
            edit
            siteIds={[siteId]}
          >
            <ActionsButton onClick={(event) => onCommonActionClick(event, tasks[0], 'snooze')}>
              <TooltipCell
                customClass={'tooltip-snooze'}
                placement={placements.TOP}
                tooltip={t('tasks:form.snooze', 'Snooze')}
                zIndex={elevations.P101}
              >
                <SnoozeIcon />
              </TooltipCell>
            </ActionsButton>
          </AuthRender>
          <AuthRender
            capability={Capability.WORK_PLAN}
            description="Schedule/Reschedule"
            edit
            siteIds={[siteId]}
          >
            <ActionsButton onClick={(event) => onCommonActionClick(event, tasks[0], 'schedule')}>
              <TooltipCell
                customClass={'tooltip-schedule'}
                placement={placements.TOP}
                tooltip={t('tasks:form.schedule', 'Schedule')}
                zIndex={elevations.P101}
              >
                <CalendarIcon />
              </TooltipCell>
            </ActionsButton>
          </AuthRender>
        </td>
      </Tr>
    </>
  );
};

CommonActions.propTypes = {
  tasks: PropTypes.instanceOf(Object),
  siteId: PropTypes.string,
  onCommonActionClick: PropTypes.func,
  setShowConfirmMessage: PropTypes.func,
};

CommonActions.defaultProps = {
  tasks: null,
  siteId: null,
  onCommonActionClick: () => null,
  setShowConfirmMessage: () => null,
};
