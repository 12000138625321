import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { SpinLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { Text } from '@ge/components/typography';
import { typography } from '@ge/tokens';

const DialogContainer = styled.div`
  width: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
`;

const FooterButtons = styled.div`
  margin-left: auto;
  text-align: right;
  button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const TaskText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 2,
}))`
  color: ${(props) => props.theme.dialog.taskReschedule.labelColor};
  &.value {
    color: currentColor;
  }
`;

const DescriptionText = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 3,
}))`
  color: ${(props) => props.theme.dialog.headerTextColor};
  width: 100%;
  display: block;
  margin: 20px;
`;

export const DeleteTasksDialog = ({ tasks, onClose, onSaveAlls }) => {
  const { t } = useTranslation(['manage.cases-tasks', 'tasks'], { useSuspense: false });
  const { handleSubmit } = useForm({
    mode: 'onChange',
  });
  const handleChange = useCallback(() => {
    onClose();
  }, [onClose]);
  const [spinLoader, setSpinLoader] = useState(false);
  const handleSaveAll = useCallback(
    async (data) => {
      data = tasks;
      data = tasks.map((eachtask) => {
        return { taskId: eachtask.taskId, id: eachtask.id };
      });
      setSpinLoader(true);
      onSaveAlls(data);
    },
    [onSaveAlls, tasks],
  );

  const getFooter = useMemo(() => {
    return (
      <>
        <FooterButtons onClick={(e) => e.stopPropagation()}>
          <SpinLoader showLoader={spinLoader} />
          <Button onClick={handleChange}>{t('general:cancel', 'Cancel')}</Button>
          <Button disabled={spinLoader} primary onClick={handleSubmit(handleSaveAll)}>
            {t('general:delete_task', 'Delete Task')}
          </Button>
        </FooterButtons>
      </>
    );
  }, [handleChange, t, handleSaveAll, handleSubmit, spinLoader]);

  const MultiTaskDeleteMessage = () => {
    return (
      <>
        {t('multi_delete_task_message', 'Are you sure you want to permanently delete these task?')}
        {tasks.length} {t('delete_task_message', 'tasks?')}
      </>
    );
  };

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      onConfirm={onSaveAlls}
      header={t('delete_task', 'Delete Task Confirmation')}
      footer={getFooter}
      contentWidth
      padContent={false}
    >
      <DialogContainer>
        <TaskText>
          <DescriptionText>
            {tasks.length === 1 ? (
              t(
                'single_delete_task_message',
                'Are you sure you want to permanently delete this task?',
              )
            ) : (
              <MultiTaskDeleteMessage />
            )}
          </DescriptionText>
        </TaskText>
      </DialogContainer>
    </Dialog>
  );
};

DeleteTasksDialog.propTypes = {
  tasks: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func,
  onSaveAlls: PropTypes.func,
  dropDate: PropTypes.instanceOf(Object),
  dropTime: PropTypes.string,
  crewLaneInfo: PropTypes.instanceOf(Object),
  tasktobeBundled: PropTypes.instanceOf(Object),
  bundleTasks: PropTypes.instanceOf(Object),
  dragItemBacklogType: PropTypes.instanceOf(Object),
};

DeleteTasksDialog.defaultValues = {
  onClose: () => null,
  onSaveAlls: () => null,
  dropDate: null,
  dropTime: null,
  crewLaneInfo: null,
  tasktobeBundled: null,
  bundleTasks: null,
  dragItemBacklogType: null,
};

Controller.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.func,
};
