import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MiniLoader } from '@ge/components/loader';
import { EntityType, PersonAssetWorkerTab } from '@ge/models/constants';

import AssetUtilityBar from '../components/asset-utility-bar';
import EntityPicker from '../components/entity-picker';
import { Description, Header } from '../entity-create-shared';
import { useEntitiesFiltered } from '../hooks/use-entities-filtered';

const PickerLabel = styled.div`
  margin: 0 14px 10px auto;
  text-align: right;
`;

const PersonAssignAssets = ({ className, personType, personAttributes }) => {
  const { t } = useTranslation(['admin.people'], { useSuspense: false });

  const [filterText, setFilterText] = useState('');

  const { entitiesFiltered, isLoading } = useEntitiesFiltered(
    EntityType.SERVICE_GROUP,
    filterText,
    PersonAssetWorkerTab.ASSETS_FOR_WORKER,
  );

  const handleFilterTextChange = useCallback((filterText) => {
    setFilterText(filterText);
  }, []);

  return (
    <div className={className}>
      <Header>{t('assign_assets.header', 'Assign Assets to this Worker')}</Header>
      <Description>
        {t(
          'assign_assets.desc',
          'This worker can be scheduled for work on the assets selected below.',
        )}
      </Description>
      <AssetUtilityBar
        filterText={filterText}
        isDisableViewBy={true}
        onFilterTextChange={handleFilterTextChange}
        viewBy={EntityType.SERVICE_GROUP}
      />
      {isLoading ? (
        <MiniLoader />
      ) : (
        <>
          <PickerLabel>{t('assets.number_of_assets', '# of Assets')}</PickerLabel>
          <EntityPicker
            childEntityType={EntityType.SITE}
            entityCollection={entitiesFiltered}
            filterText={filterText}
            isDisableChildren={true}
            isAssetCount={true}
            parentEntityType={EntityType.SERVICE_GROUP}
            personType={personType}
            personAttributes={personAttributes}
          />
        </>
      )}
    </div>
  );
};

PersonAssignAssets.propTypes = {
  className: PropTypes.string,
  personType: PropTypes.string,
  personAttributes: PropTypes.object,
};

PersonAssignAssets.defaultProps = {
  className: null,
};

export default PersonAssignAssets;
