import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledTemplateRow = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
`;

export const TemplateRow = ({ children }) => {
  return <StyledTemplateRow>{children}</StyledTemplateRow>;
};

TemplateRow.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledTemplateRows = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1024px;
  min-width: 1024px;
`;

export const TemplateRows = ({ children }) => {
  return <StyledTemplateRows>{children}</StyledTemplateRows>;
};

TemplateRows.propTypes = {
  children: PropTypes.node.isRequired,
};
