import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import * as request from '@ge/shared/services/api';

const USERS_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_USER_API;

const patchPerson = async ({ username, body }) =>
  request.patch(`/cmn/admin/users/${username}`, body, { baseURL: USERS_BASE_URL });

export const usePatchPerson = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const {
    mutate: patch,
    isLoading: personPatching,
    status,
    isError,
    error,
  } = useMutation(({ username, body }) => patchPerson({ username, body }), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.INFINITE_PEOPLE]);
      queryClient.invalidateQueries([QueryKey.FETCH_PERSON]);
      onSuccess();
    },
  });

  return { patch, personPatching, status, isError, error };
};
