import { PropTypes } from 'prop-types';
import React from 'react';

import { Badge } from '@ge/components/badge';
import { globalColors } from '@ge/tokens';

const NotesBadge = ({ onClick, label }) => {
  return (
    <span onClick={onClick}>
      <Badge label={label} color={globalColors.slate3} />
    </span>
  );
};

NotesBadge.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.number,
};

export default NotesBadge;
