import React, { useCallback } from 'react';
import { FormProvider, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Input } from '@ge/components/input/input';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { Select } from '@ge/components/select';
import { useTenants } from '@ge/feat-admin/data-hooks/use-tenants';
import { EntityType } from '@ge/models/constants';

import RolesGrid from '../components/roles-grid';
import AdminCreateHeader from '../entity-create-header';
import {
  AdminCollapsiblePanel,
  Description,
  DetailContainer,
  SectionContainer,
} from '../entity-create-shared';
import usePermissions from '../hooks/use-permissions';

const AdminForm = styled.form`
  background: ${(props) => props.theme.admin.panel.backgroundColor};
  position: relative;
  padding-top: 10px;
`;

const AdminCreateBody = styled.div`
  background: ${(props) => props.theme.admin.panel.body.backgroundColor};
  box-sizing: border-box;
  padding: 20px 25px;
  .form-row {
    display: flex;
    padding-top: 20px;
    &:first-of-type {
      border-bottom: 1px solid ${(props) => props.theme.admin.panel.body.border};
      margin-bottom: 5px;
      padding: 0 0 20px 0;
    }
    &:last-of-type {
      padding-bottom: 20px;
    }
    &.half {
      width: 50%;
    }
  }
  .person-label {
    font-size: 14px;
    line-height: 17px;
    margin-right: 25px;
  }
  .worker-wrapper,
  .user-wrapper {
    margin-right: 40px;
    width: 208px;
    span {
      margin-left: 10px;
    }
    .detail {
      display: block;
      margin: 5px 0 0 25px;
    }
  }
`;

const AdminFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 45px 0 25px;
  position: relative;
`;

const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  button {
    &:last-of-type {
      margin-left: auto;
    }
  }
`;

const CollapsePanels = styled.div`
  border-bottom: ${(props) => props.theme.admin.panel.body.border} 1px solid;
`;

const RoleGridContainer = styled.div`
  > div {
    height: calc(100vh - 350px);
  }
`;

const TenantSelectContainer = styled.div`
  margin-bottom: 20px;
  width: 50%;
`;

const TitleInputContainer = styled.div`
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 15px;
  width: 30%;
`;

const DescriptionInputContainer = styled.div`
  display: inline-block;
  width: 65%;
`;

const formFields = {
  tenantId: 'tenantId',
  title: 'title',
  description: 'description',
};

const RoleCreate = () => {
  const { t } = useTranslation(['admin.roles', 'general'], { useSuspense: false });

  const history = useHistory();

  const { data: tenants } = useTenants();

  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const { data: permissions } = usePermissions('new_role');

  const closeModal = useCallback(() => {
    history.push('/admin/roles');
  }, [history]);

  const onSubmit = useCallback(
    (values) => {
      // Intentionally leaving this as a console log until the backend call is available
      // and the BFF is hooked up to it
      // eslint-disable-line no-console
      console.log('onSubmit', values);

      closeModal();
    },
    [closeModal],
  );

  const getTenantOptions = useCallback(() => {
    if (!tenants) return {};

    return tenants.map((tenant) => {
      return {
        label: tenant.companyName,
        value: tenant.tenantId,
      };
    });
  }, [tenants]);

  return (
    <FormProvider {...methods}>
      <DetailContainer>
        <SectionContainer>
          <AdminForm>
            <AdminCreateHeader
              header={t('role_panel.new_role', 'Add New Role')}
              entityType={EntityType.ROLE}
            />
            <AdminCreateBody>
              <TenantSelectContainer>
                <Controller
                  name={formFields.tenantId}
                  rules={{
                    required: true,
                  }}
                  defaultValue={null}
                  render={({ onChange, value }) => (
                    <Select
                      primary
                      onChange={onChange}
                      label={t('role_panel.tenant_select', 'Tenant')}
                      options={getTenantOptions()}
                      value={value}
                      placeholder={t('role_panel.tenant_placeholder', 'Select a tenant')}
                      error={methods.errors?.tenantId}
                    />
                  )}
                />
              </TenantSelectContainer>

              <TitleInputContainer>
                <Controller
                  name={formFields.title}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      onChange={onChange}
                      label={t('role_panel.title_label', 'Role name')}
                      type="text"
                      placeholder={t('role_panel.title_placeholder', 'Enter role title')}
                      value={value}
                      error={methods.errors?.title}
                    />
                  )}
                />
              </TitleInputContainer>

              <DescriptionInputContainer>
                <Controller
                  name={formFields.description}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <Input
                      onChange={onChange}
                      label={t('role_panel.description_label', 'Description (optional)')}
                      type="text"
                      placeholder={t(
                        'role_panel.description_placeholder',
                        'Enter role description',
                      )}
                      value={value}
                      error={methods.errors?.description}
                    />
                  )}
                />
              </DescriptionInputContainer>

              <h2>{t('role_panel.permissions_title', 'Product Permissions')}</h2>
              <Description className="roles">
                {t(
                  'role_panel.permissions_desc',
                  'Select what features and capabilities will be available to this role.',
                )}
              </Description>

              <RoleGridContainer>
                <ScrollingContainer>
                  <CollapsePanels>
                    {permissions.map((segment, id) => (
                      <AdminCollapsiblePanel
                        headerContent={
                          <div>
                            <h4>
                              {t(
                                `segment_offerings.${segment.segmentOffereing}`,
                                segment.defaultTranslation,
                              )}
                            </h4>
                            <p>{segment.description}</p>
                          </div>
                        }
                        key={id}
                      >
                        <RolesGrid capabilityGroups={segment.capabilityGroups} isEditMode={true} />
                      </AdminCollapsiblePanel>
                    ))}
                  </CollapsePanels>
                </ScrollingContainer>
              </RoleGridContainer>
            </AdminCreateBody>
          </AdminForm>
        </SectionContainer>

        <AdminFooterWrapper>
          <FooterButtons>
            <Button onClick={closeModal}>{t('general:cancel', 'Cancel')}</Button>
            <Button onClick={methods.handleSubmit(onSubmit)}>
              {t('general:create', 'Create')}
            </Button>
          </FooterButtons>
        </AdminFooterWrapper>
      </DetailContainer>
    </FormProvider>
  );
};

export default RoleCreate;
