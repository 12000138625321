import { useStoreState } from 'easy-peasy';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ButtonGroup, Button } from '@ge/components/button';
import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
// #1511: Commenting out for lint.
// import { Select } from '@ge/components/select';
import { useAssetContext, useDataExplorerContext } from '@ge/feat-analyze/context';
import { ChartItem } from '@ge/feat-analyze/models/chart-item';
import { PresentationTypes } from '@ge/models/constants';

/// #1511: Commenting out for lint.
// import { DataExplorerTemplates } from '../../../models/data-explorer-defs';
import { AssetDialog, SelectionType } from './../asset-overview-asset-dialog';
import { DataExplorerCurrentFilter } from './data-explorer-current-filter';
import { DataExplorerFilterDialog } from './data-explorer-filter-dialog';
import { DataExplorerSignalDialog } from './data-explorer-signal-dialog';

const buttonTextStyle = css`
  color: ${(props) => props.theme.dataExplorer.filters.headerButton};
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: 700;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const HeaderFilters = styled.div`
  display: flex;
  padding: 16px;
  .filter-left {
    flex: 1;
  }
  .filter-right {
    margin-left: auto;
    display: flex;
    vertical-align: middle;
  }
`;

const HeaderButton = styled.button`
  ${buttonTextStyle}
`;

const HeaderCheckbox = styled(Checkbox)`
  align-items: center;
  ${buttonTextStyle}
`;

const HeaderIcon = styled(Icon).attrs((props) => ({
  size: 11,
  color: props.theme.dataExplorer.filters.headerIconColor,
}))`
  margin-right: 4px;
`;

const SearchButton = styled(Button)`
  margin: auto 20px auto 30px;
  height: 20px;
  width: 36px;
  padding: initial;
`;

const SearchIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.SEARCH,
  color: props.theme.dataExplorer.filters.searchIconColor,
}))``;

const StyledButtonGroup = styled(ButtonGroup)`
  margin: auto;
  > button {
    height: 25px;
    width: 42px;
    padding: 0;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`;

const ToggleIcon = styled(Icon).attrs((props) => ({
  size: 20,
  color: props.active
    ? props.theme.dataExplorer.filters.layoutIconSelected
    : props.theme.dataExplorer.filters.layoutIcon,
  viewbox: '0 0 20 13',
}))`
  margin-top: 2px;
`;

const CompareAssetButton = styled.button.attrs(() => ({ type: 'button' }))`
  position: relative;
  color: ${(props) => props.theme.dataExplorer.filters.headerButton};
  outline: none;
`;

const CompareAssetIcon = styled(Icon).attrs((props) => ({
  size: 20,
  icon: Icons.COMPARE,
  color: props.theme.entityDetails.headerIconColor,
}))`
  margin-top: 6px;
`;

export const DataExplorerFilters = () => {
  const { t } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSignalDialog, setShowSignalDialog] = useState(false);
  const [showAssetDialog, setShowAssetDialog] = useState(false);

  const { assets } = useStoreState((state) => state.assets);

  const { isLoading, enableMultiAxis, syncZoom, setSyncZoom } = useDataExplorerContext();

  const {
    queryParam,
    assetState: {
      // #1511: Commenting out for lint
      // setTemplateParam,
      setLayoutParam,
      chartsArray,
      chartFilter,
      signalData,
      addChartToArray,
      setChartFilter,
    },
  } = useAssetContext();

  // #1511: Commenting out for lint
  // const selectOptions = useMemo(
  //   () =>
  //     Object.keys(DataExplorerTemplates).map((key) => ({
  //       value: key,
  //       label: t(`dynamic.options.${key}`),
  //     })),
  //   [t],
  // );

  // #1511: Commenting out for lint
  // const selectedValue = useMemo(
  //   () => selectOptions.filter((option) => option.value === queryParam.template),
  //   [selectOptions, queryParam.template],
  // );

  const showFilterModal = useCallback(() => {
    setShowFilterDialog(true);
  }, [setShowFilterDialog]);

  const closeFilterModal = useCallback(() => {
    setShowFilterDialog(false);
  }, [setShowFilterDialog]);

  const getSelectedSiteId = useCallback(() => {
    if (!queryParam?.assets?.length) return null;
    if (queryParam.assets.length === 1) return assets[queryParam.assets[0]]?.site?.id;
    return assets[queryParam.assets[1]]?.site?.id;
  }, [queryParam.assets, assets]);

  const handleAddChart = (axisIds) => {
    addChartToArray(
      new ChartItem({
        id: `plot-${new Date().valueOf()}`,
        xAxis: axisIds.x,
        yAxis: axisIds.y,
      }),
    );
    setShowSignalDialog(false);
  };

  const assetIds = queryParam?.assets ?? [];

  return (
    <>
      <HeaderFilters>
        {/* #1511: Hide until this can be explored futher */}
        {/* <Select
          primary
          minWidth={180}
          value={selectedValue}
          onChange={(option) => setTemplateParam(option.value)}
          options={selectOptions}
          disabled
        /> */}
        <div className="filter-left">
          {enableMultiAxis && assetIds.length >= 1 && (
            <CompareAssetButton onClick={() => setShowAssetDialog(true)}>
              <CompareAssetIcon />
              {t('compare', 'COMPARE')}
              {assetIds?.length === 2 ? ': ' + assets[assetIds[1]]?.name : ''}
            </CompareAssetButton>
          )}
        </div>
        <div className="filter-right">
          {enableMultiAxis && (
            <HeaderCheckbox
              name={'sync_zoom'}
              label={t('sync_zoom', 'Sync Zoom')}
              onChange={(checked) => setSyncZoom(checked)}
              checkState={syncZoom ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              disabled={isLoading || !signalData}
            />
          )}
          <HeaderButton onClick={showFilterModal} disabled={!signalData}>
            <HeaderIcon icon={Icons.FILTER} />
            {chartFilter ? t('edit_filter', 'Edit Filter') : t('add_filter', 'Add Filter')}
          </HeaderButton>
          {chartFilter && (
            <HeaderButton onClick={() => setChartFilter(null)}>
              <HeaderIcon icon={Icons.CLOSE} />
              {t('clear_filter', 'Clear Filter')}
            </HeaderButton>
          )}
          <HeaderButton onClick={() => setShowSignalDialog(true)}>
            <HeaderIcon icon={Icons.ADD} />
            {t('add_chart', 'Add Chart')}
          </HeaderButton>
          <SearchButton>
            <SearchIcon />
          </SearchButton>
          <StyledButtonGroup>
            <Button
              onClick={() => setLayoutParam(PresentationTypes.DETAIL)}
              primary={queryParam.layout === PresentationTypes.DETAIL}
            >
              <ToggleIcon
                active={queryParam.layout === PresentationTypes.DETAIL}
                icon={Icons.DETAIL_EXPLORER}
              />
            </Button>

            <Button
              onClick={() => setLayoutParam(PresentationTypes.GRID)}
              disabled={!(chartsArray?.length > 1)}
            >
              <ToggleIcon
                active={queryParam.layout === PresentationTypes.GRID}
                icon={Icons.GRID_EXPLORER}
              />
            </Button>
          </StyledButtonGroup>
        </div>
      </HeaderFilters>
      {showFilterDialog && (
        <DataExplorerFilterDialog
          filter={chartFilter}
          onClose={closeFilterModal}
          onConfirm={closeFilterModal}
        />
      )}
      {showSignalDialog && (
        <DataExplorerSignalDialog
          onClose={() => setShowSignalDialog(false)}
          onConfirm={handleAddChart}
          signalData={signalData}
          enableMultiAxis={enableMultiAxis}
        />
      )}
      {chartFilter && <DataExplorerCurrentFilter />}
      {showAssetDialog && (
        <AssetDialog
          assetsDisabled={assetIds.length >= 1 ? [assetIds[0]] : []}
          assetsSelected={assetIds.length > 1 ? [assetIds[1]] : []}
          selectedSiteId={getSelectedSiteId()}
          maxSelectCount={1}
          selectionType={SelectionType.COMPARISON}
          onClose={() => setShowAssetDialog(false)}
          onConfirm={() => setShowAssetDialog(false)}
        />
      )}
    </>
  );
};
