import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';

import { WidgetNames } from '@ge/feat-reporting/models/widgets';

import { AssetStatusModule } from '../asset-status-module';

// going with how this logic is being handled for tables
const getListByName = (id, name, configuration) => {
  switch (name) {
    case WidgetNames.ASSET_STATUS:
      return <AssetStatusModule id={id} configuration={configuration} />;
  }
};

export const DonutModule = ({ id, name, configuration }) => {
  const module = useMemo(() => getListByName(id, name, configuration), [id, name, configuration]);

  return <>{module}</>;
};

DonutModule.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  configuration: PropTypes.object,
};
