import { useMemo, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { TimeSignal, EventIdType } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { fetchAssetsSignalData } from '@ge/shared/services/signal-data';
import { mergeSignalData, splitDates } from '@ge/shared/util';

/**
 * Use asset signal query.
 *
 * @param assetIds an array of asset ids
 * @param xAxisSignal an array of signal ids, i.e. [WNAC.WdSpd, WTUR.W]
 * @param yAxisSignal an array of signal ids, i.e. [WNAC.WdSpd, WTUR.W]
 * @param startDate the start date
 * @param endDate the end date
 * @param chartFilter an object of applied filters
 * @param opts React-Query options i.e. staleTime, enabled, initialData
 */
export const useAssetSignalDataQuery = ({
  assetIds,
  xAxisSignal,
  yAxisSignal,
  startDate,
  endDate,
  chartFilter,
}) => {
  const queryClient = useQueryClient();

  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const { dates, queryKey, queryParams } = useMemo(() => {
    const yAxisSignals = Array.isArray(yAxisSignal) ? yAxisSignal : [];
    const signals = [xAxisSignal, ...yAxisSignals, chartFilter?.filter].filter(
      (signal) => !!signal?.id && signal.id !== TimeSignal.id,
    );

    const dates = splitDates(startDate, endDate);
    const { start, end } = dates[pageNumber] ?? {};

    return {
      dates,
      queryKey: {
        assetIds,
        xAxisSignal,
        yAxisSignal,
        chartFilter,
        start,
        end,
      },
      queryParams: {
        start,
        end,
        signals: signals.map((signal) => ({
          id: signal.id,
          type:
            String(signal.type)
              .toLowerCase()
              .includes(EventIdType.CANONICAL) || signal.id === TimeSignal.id
              ? EventIdType.CANONICAL
              : EventIdType.SOURCE,
          timeAggr: signal.timeAggr,
          categoryId: signal.categoryId,
        })),
      },
    };
  }, [assetIds, xAxisSignal, yAxisSignal, chartFilter, startDate, endDate, pageNumber]);

  const { isLoading, isError, error } = useQuery(
    ['fetchAssetsSignalData', queryKey],
    () => fetchAssetsSignalData(assetIds, queryParams),
    {
      ...Config.EXECUTE_ONCE,
      enabled: Boolean(
        assetIds?.length > 0 && queryParams.signals?.length > 0,
        queryParams.start && queryParams.end,
      ),
      retry: false,
      onSuccess: (res) => {
        setData((prev) => mergeSignalData(prev, res));
        if (pageNumber < dates.length - 1) {
          setPageNumber(pageNumber + 1);
        }
      },
    },
  );

  const refetch = () => {
    queryClient.invalidateQueries('fetchAssetsSignalData');
    setData(null);
    setPageNumber(0);
  };

  useEffect(() => {
    queryClient.invalidateQueries('fetchAssetsSignalData');
    setData(null);
    setPageNumber(0);
  }, [assetIds, xAxisSignal, yAxisSignal, chartFilter, startDate, endDate, queryClient]);

  return {
    isLoading,
    isError,
    error,
    data,
    refetch,
  };
};
