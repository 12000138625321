import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ClearColumnFilter from '@ge/components/table/filters/clear-column-filter';
import { EntityType, PresentationTypes } from '@ge/models/constants';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { SitesColumnDefs } from '@ge/shared/models/table-col-defs/sites-list-table-cols';
import PresentationToggle from '@ge/web-client/src/app/components/presentation-toggle/presentation-toggle';

import { usePresentationType } from '../../data-hooks/use-presentation-type';
import { AssetsColumnDefs } from '../../models/assets-table-cols';

import { DownloadTableButton } from './download-table-button';
/**
 * Set list of view types for Presentation Toggle
 */
// const PresentationViews = [PresentationTypes.LIST, PresentationTypes.GRID];
const PresentationViews = [PresentationTypes.GRID, PresentationTypes.LIST];

const StyledTools = styled.div`
  display: flex;
`;

const StyledDownloadTableButton = styled(DownloadTableButton)`
  margin-right: 16px;
  padding: 0;
`;

const ClearButton = styled(ClearColumnFilter)`
  margin-right: 16px;
`;

export const EntityTableTools = ({
  children,
  isLoading,
  data,
  columnState,
  entity,
  entityType,
  dateRange,
  disabledDownload,
  disabledViewToggle,
  className,
  onMenuClose,
  onClumnSelection,
  filters,
  onReset,
}) => {
  const { ready, t } = useTranslation(['analyze.dashboard'], {
    useSuspense: false,
  });
  const presentationType = usePresentationType(!disabledViewToggle);

  const columnDef = useMemo(() => {
    switch (entityType) {
      case EntityType.REGION:
      case EntityType.SITES:
        return SitesColumnDefs;
      case EntityType.SITE:
        return AssetsColumnDefs;
      default:
        return {};
    }
  }, [entityType]);

  if (!ready) return null;
  return (
    <StyledTools className={className}>
      {children}
      <ClearButton filters={filters} onReset={onReset} />
      {presentationType === PresentationTypes.LIST && (
        <ColumnSelectMenu
          translateFn={t}
          columnDef={columnDef}
          columnState={columnState}
          onMenuClose={onMenuClose}
          heightOffset={690}
          onClumnSelection={onClumnSelection}
        />
      )}
      {!disabledDownload && (
        <StyledDownloadTableButton
          isLoading={isLoading}
          data={data}
          columns={columnState}
          entity={entity}
          entityType={entityType}
          dateRange={dateRange}
        />
      )}
      {!disabledViewToggle && (
        <PresentationToggle viewList={PresentationViews} defaultView={PresentationTypes.LIST} />
      )}
    </StyledTools>
  );
};

EntityTableTools.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  columnState: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  entity: PropTypes.instanceOf(Object),
  entityType: PropTypes.string,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  disabledDownload: PropTypes.bool,
  disabledViewToggle: PropTypes.bool,
  className: PropTypes.string,
  onMenuClose: PropTypes.func,
  onClumnSelection: PropTypes.func,
  onReset: PropTypes.func,
  filters: PropTypes.object,
};

EntityTableTools.defaultProps = {
  children: null,
  isLoading: false,
  columnState: [],
  data: [],
  entity: null,
  entityType: null,
  dateRange: {},
  disabledDownload: false,
  disabledViewToggle: false,
  className: null,
  onMenuClose: () => {},
  filters: {},
  onReset: () => {},
};

export default EntityTableTools;
