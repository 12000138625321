import styled from 'styled-components';

import ExportGraphButton from '../../modals/export-graph-modal/export-graph-button';
export const SiteContentStyled = styled.div.attrs({
  className: 'SiteContent',
})`
  clear: both;
  min-height: 300px;
  width: 96%;
  margin-left: 1%;
  border: ${(props) => (props.hideBorder ? 'none' : '1px solid' + props.theme.charts.borderColor)};
  border-radius: 4px;
  padding: 8px;
`;

export const ChartHeaderStyled = styled.div.attrs({
  className: 'ChartHeader',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 20%;
  border-bottom: 1px solid#5E6D7B;
  margin-bottom: 15px;
`;

export const ChartGraphButtonStyled = styled(ExportGraphButton)`
  margin-left: auto;
  font-size: 0;
`;
