import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme, css } from 'styled-components';

import { ChartType } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';

import SensorReadingChartLegend from './sensor-readings-chart-legends';
import chartOptionsFactory from './sensor-readings-chart-options-factory';

const ChartLayout = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const Container = styled.div`
  margin-bottom: 20px;
  ${({ layout }) =>
    layout === ChartLayout.SECONDARY
      ? css`
          padding: 5px 7px;
          border-radius: 4px;
          border: 1px solid
            ${({ theme }) => theme.sensorReadingsChart.borderColor[ChartLayout.SECONDARY]};
        `
      : css`
          background-color: ${({ theme, layout }) =>
            theme.sensorReadingsChart.backgroundColor[layout]};
        `}
  .highcharts-plot-background {
    fill: ${({ theme, layout }) => theme.sensorReadingsChart.plotBackground[layout]};
  }
  .highcharts-yaxis {
    .highcharts-axis-line {
      stroke-width: 0.5;
      stroke: ${({ theme }) => theme.charts.gridLineColor};
    }
  }
`;

export const SensorReadingChart = withTheme(
  ({ chartOptions, height, tooltipEnabled, layout, theme, className }) => {
    const { ready, t } = useTranslation(['entity-details'], {
      useSuspense: false,
    });

    const [point, setPoint] = useState({});

    const chartRef = useRef();

    const { seriesGroups = [], options = {}, plotLines = [] } = useMemo(() => {
      if (!chartOptions?.series?.length) return {};

      return chartOptionsFactory({
        chartOptions,
        height,
        xAxisTitle: t('sensor_readings.x_axis_title', 'Time(Sec)'),
        tooltipEnabled,
        theme,
        onMouseOver: setPoint,
      });
    }, [chartOptions, height, tooltipEnabled, theme, t]);

    if (!ready) return null;

    return (
      <Container className={className} layout={layout}>
        <DataLoader noData={!options} type={ChartType.SPLINE} height={`${height}px`}>
          <SensorReadingChartLegend
            seriesGroups={seriesGroups}
            plotLines={plotLines}
            chartRef={chartRef}
            layout={layout}
            point={point}
          />
          <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </DataLoader>
      </Container>
    );
  },
);

SensorReadingChart.propTypes = {
  theme: PropTypes.instanceOf(Object),
  chartOptions: PropTypes.instanceOf(Object),
  height: PropTypes.number,
  tooltipEnabled: PropTypes.bool,
  layout: PropTypes.string,
  className: PropTypes.string,
};

SensorReadingChart.defaultProps = {
  chartOptions: null,
  height: null,
  tooltipEnabled: false,
  layout: ChartLayout.PRIMARY,
};
