/* eslint-disable camelcase */
import { action, thunk } from 'easy-peasy';

import {
  submitFile,
  submitMultiPartsFile,
  getPresignedUrl as getPresignedUrlCall,
  putFilesByPresignedUrl as putFilesByPresignedUrlCall,
  getBulkReportDownloadUrl,
} from '../services/files';
import { getCommaSeparatedFilenames } from '../util';

// Define initial state
const defaultFileState = {
  file: {},
  isUploading: false,
};

// helper functions

// Actions
const fileActions = {
  resetFile: action((state) => {
    // eslint-disable-next-line no-unused-vars
    state = Object.assign(state, defaultFileState);
  }),
  updateFileUploading: action((state, payload) => {
    const { isUploading } = payload;
    state.isUploading = isUploading;
  }),

  submitFile: thunk(async (actions, payload) => {
    const { data } = payload;
    const options = {};
    // eslint-disable-next-line no-unused-vars
    const { results } = await submitFile(data, options);
  }),
  submitMultiPartsFile: thunk(async (actions, payload) => {
    const { data } = payload;
    const options = {};
    const { status } = await submitMultiPartsFile(data, options);

    return new Promise((resolve) => {
      resolve(status);
    });
  }),
  // add new record in video table
  // Andy: unused code?
  // createNewVideoRecord: thunk(async (actions, payload, { getStoreActions }) => {
  //   const { newJobstable_lid, fileName, axis } = payload;
  //   const options = {};
  //   const flagNew = 1; // 1 indicates new record entry into video table
  //   const data = {
  //     jobstable_lid: newJobstable_lid,
  //     videoname: fileName,
  //     blade1_axis: ((axis) % 3) + 1, // setting default to 1, 2 and 3 respectively
  //     blade2_axis: ((axis + 1) % 3) + 1,
  //     blade3_axis: ((axis + 2) % 3) + 1,
  //     side: 'Pressure Side',
  //   };
  //   await getStoreActions.inspections.updateVideo(flagNew, data, options);
  // }),

  // add new record in image table
  // createNewImageRecord: thunk(async (actions, payload) => {
  //   const { newJobstable_lid, fileName } = payload;
  //   const options = {};
  //   const flagNew = 1; // 1 indicates new record entry into image table
  //   const data = {
  //     jobstable_lid: newJobstable_lid,
  //     imgname: fileName,
  //     defect_flag: 'false',
  //   };
  //   const { id } = await updateImage(flagNew, data, options);
  // }),

  getPresignedUrl: thunk(async (actions, payload) => {
    const { files, type = 'get' } = payload;
    const expires = 60 * 60 * 12;
    const options = {};
    const data = {
      file: getCommaSeparatedFilenames(files),
      type,
      expires,
    };
    actions.updateFileUploading({ isUploading: true });
    const { signedUrls } = await getPresignedUrlCall(data, options);
    actions.updateFileUploading({ isUploading: false });
    return new Promise((resolve, reject) => {
      if (Array.isArray(signedUrls)) resolve(signedUrls);
      else reject(signedUrls);
    });
  }),
  putFilesByPresignedUrl: thunk(async (actions, payload) => {
    const { presignedUrl, fileData, uploadProgress } = payload;
    const options = {};
    actions.updateFileUploading({ isUploading: true });
    let result;
    try {
      result = await putFilesByPresignedUrlCall(presignedUrl, fileData, options, uploadProgress);
    } catch (err) {
      console.error(err);
    }
    actions.updateFileUploading({ isUploading: false });
    return new Promise((resolve) => {
      resolve(result);
    });
  }),

  getReportsPresignedUrl: thunk(async (actions, payload) => {
    const options = {};
    const data = {
      jobIds: payload.selectedJobIds,
      user_id: payload.user_id ? payload.user_id : 1,
    };
    const results = await getBulkReportDownloadUrl(data, options);
    return new Promise((resolve) => {
      resolve(results);
    });
  }),
};

// Listeners
const fileListeners = {};

// Computed values
const fileComputed = {};

const fileModel = {
  ...defaultFileState,
  ...fileActions,
  ...fileListeners,
  ...fileComputed,
};

export default fileModel;
