import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropTypes } from 'prop-types';
import React, { useCallback, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';

import { frequencyLineChartOptions } from './frequency-line-chart-conts';

const StyledFrequencyChart = styled.div`
  width: 100%;

  /* Do not delete important: Responsive Highcharts styles https://github.com/highcharts/highcharts/issues/1157 */
  .highcharts-container,
  .highcharts-container svg {
    width: 100% !important;
  }
`;

const StyleChartTitleWrapper = styled.div`
  display: flex;
  position: relative;
  &.alt-chevron {
    justify-content: flex-start;

    button {
      padding: 0;
      position: absolute;
      left: -15px;
      top: -1px;
    }
  }
`;

const LargeTitleCss = css`
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
`;

const StyledChartTitle = styled.span`
  color: ${(props) => props.theme.analyze.frequencyLineChart.titleColor};
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 13px;
  ${(props) => {
    if (props.large) return LargeTitleCss;
  }};
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.color || props.theme.analyze.frequencyLineChart.headerIconColor,
  rotate: -90,
}))`
  vertical-align: initial;
  &.enlarge-chart-chevron {
    transform: rotate(90deg);
  }
`;

const FrequencyLine = ({ ...props }) => {
  const {
    alignChevronLeft,
    className,
    data,
    large,
    onChevronClick,
    title,
    startOnTick,
    theme,
    type,
    xAxisMax,
    xInterval,
    yInterval,
  } = props;

  const chart = useRef();
  const chartOptions = useRef(frequencyLineChartOptions);

  const chartCreated = useCallback(
    (chartRef) => {
      chartRef.update({
        chart: {
          height: large ? 537 : 242,
        },
        colors: large
          ? [theme.analyze.frequencyLineChart.colorsLarge]
          : theme.analyze.frequencyLineChart.colors,
        xAxis: {
          max: xAxisMax ? xAxisMax : null,
          startOnTick: startOnTick ? startOnTick : null,
          tickInterval: xInterval,
          type: type ? type : null,
        },
        yAxis: {
          tickInterval: yInterval,
          gridLineColor: theme.analyze.frequencyLineChart.gridLineColor,
          opposite: true,
        },
        title: {
          text: '',
        },
      });

      chartRef.addSeries({
        data: data,
        lineWidth: large ? 2 : 1,
      });
    },
    [data, large, startOnTick, theme, type, xAxisMax, xInterval, yInterval],
  );

  useEffect(() => {
    if (chart.current) {
      chart.current.chart.reflow();
    }
  }, [chart]);

  return (
    <StyledFrequencyChart className={className} large={large}>
      <StyleChartTitleWrapper
        className={alignChevronLeft ? 'alt-chevron' : 'chevron'}
        large={large}
      >
        {onChevronClick && alignChevronLeft && (
          <button onClick={onChevronClick}>
            <ChevronIcon className="enlarge-chart-chevron" />
          </button>
        )}
        <StyledChartTitle large={large}>{title}</StyledChartTitle>
        {onChevronClick && !alignChevronLeft && (
          <button onClick={onChevronClick}>
            <ChevronIcon />
          </button>
        )}
      </StyleChartTitleWrapper>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions.current}
        callback={chartCreated}
        ref={chart}
        immutable
      />
    </StyledFrequencyChart>
  );
};

FrequencyLine.propTypes = {
  alignChevronLeft: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  large: PropTypes.boolean,
  onChevronClick: PropTypes.func,
  startOnTick: PropTypes.number,
  theme: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  xAxisMax: PropTypes.number,
  xInterval: PropTypes.number,
  yInterval: PropTypes.number,
};

FrequencyLine.defaultProps = {
  alignChevronLeft: false,
  className: '',
  data: [],
  onChevronClick: null,
  title: '',
  large: false,
};

export const FrequencyLineChart = withTheme(FrequencyLine);
