import l2NavItems from '../components/navigation/models/nav-items';

export const getDefaultRoute = (navItem) =>
  Array.isArray(navItem.route) ? navItem.route[0] : navItem.route;

export const getFirstL2NavPath = (rootPath) =>
  l2NavItems
    .filter((item) => item.parent.route === rootPath)
    .map((item) => getDefaultRoute(item))
    .pop();

export default getFirstL2NavPath;
