import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Tabs, Tab } from '@ge/components/tabs';
import { AssetOverviewTabs, SiteTypeTabs } from '@ge/models/constants';

import { AssetContext } from '../../context/assetContext';
import { DashboardDateFilter } from '../dashboard/dashboard-date-filter';

import { AssetOverviewHeader } from './asset-ov-header';
import { AssetOverviewSolar } from './asset-overview-solar';
import { AssetOverviewStorage } from './asset-overview-storage';
import { AssetOverviewWind } from './asset-overview-wind';

export const FilterBarStyled = styled.div.attrs({ className: 'FilterBar' })`
  margin-bottom: -30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AssetOverview = () => {
  const { t, ready } = useTranslation(['analyze.data-explorer'], { useSuspense: false });
  const { queryParam, assetState } = useContext(AssetContext);

  if (!ready) return null;

  return (
    <>
      <AssetOverviewHeader />
      {queryParam.tab !== AssetOverviewTabs.DATA_EXPLORER && (
        <FilterBarStyled>
          <DashboardDateFilter />
        </FilterBarStyled>
      )}
      <Tabs defaultTab={queryParam.type} onChangeHandler={(val) => assetState.setType(val)}>
        <Tab label={t(SiteTypeTabs.WIND, SiteTypeTabs.WIND)}>
          <AssetOverviewWind />
        </Tab>
        <Tab label={t(SiteTypeTabs.SOLAR, SiteTypeTabs.SOLAR)}>
          <AssetOverviewSolar />
        </Tab>
        <Tab label={t(SiteTypeTabs.STORAGE, SiteTypeTabs.STORAGE)}>
          <AssetOverviewStorage />
        </Tab>
      </Tabs>
    </>
  );
};
