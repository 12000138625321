import * as request from './api';

const BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_DAV_ASSET_API;
/**
 * Get array of FP sensor data
 */

export const fetchSensorData = (params) =>
  request.post(`/dav/fp/sensor-data`, params, {
    baseURL: BASE_URL,
  });
