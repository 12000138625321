import SimplebarStyles from 'simplebar/dist/simplebar.min.css';
import { createGlobalStyle } from 'styled-components';

import { typographyClasses } from '@ge/tokens';

import Fonts from '../fonts/fonts.css';

import Base from './base';
import Overrides from './overrides';

export default createGlobalStyle`
  ${Fonts}
  ${Base};
  ${Overrides};
  ${typographyClasses};
  ${SimplebarStyles}
`;
