import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import StatusCardFactory from './status-cards/status-card-factory';

const AlertsContainer = styled.div`
  max-height: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 4px 0 10px;
  .container-title h3 {
    text-transform: uppercase;
  }
`;

const BellIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.BELL,
  color: props.theme.sidebar.iconColor,
}))`
  margin-right: 6px;
`;

const AlertsBadge = styled(Badge).attrs((props) => ({
  color: props.theme.sidebar.badgeColor,
  small: true,
}))`
  margin-left: 7px;
`;

const SettingsIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SETTINGS,
  color: props.theme.sidebar.settingsIconColor,
}))``;

const Alerts = ({ alerts, onShowSettings }) => {
  const { t } = useTranslation();

  return (
    <AlertsContainer>
      <div className="container-title">
        <BellIcon />
        <h3>{t('sidebar.alerts', 'ALERTS')}</h3>
        <AlertsBadge label="5" />
        <button type="button" className="settings-btn" onClick={onShowSettings}>
          <SettingsIcon />
        </button>
      </div>
      <ScrollingContainer style={{ maxHeight: '100%' }} autoHide={false} offsetX={10} absolute>
        {alerts && alerts.map((alert, idx) => StatusCardFactory.build(alert, idx))}
      </ScrollingContainer>
    </AlertsContainer>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onShowSettings: PropTypes.func,
};

Alerts.defaultProps = {
  alerts: [],
  onShowSettings: () => null,
};

export default Alerts;
