import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { SortDirection } from '@ge/components/table/models/sort-direction';
import { useColumnState } from '@ge/components/table/use-column-state';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { Text } from '@ge/components/typography';
import { EventRefreshButtonWithToolTip } from '@ge/feat-monitor/components/event-refresh';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import { MonitorDefs, ManageDefs, TableType, Capability } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import AlertHistoryTable from '@ge/shared/components/tables/alert-history-table';
import CaseHistoryTable from '@ge/shared/components/tables/case-history-table';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { useAssetCaseHistory, useAssetTaskHistory } from '@ge/shared/data-hooks';
import { useAssetAlertHistory } from '@ge/shared/data-hooks/use-asset-alert-history';
import { AppScopes } from '@ge/shared/models/scopes';
import {
  AlertHistoryColumnDefs,
  defaultAlertHistoryCols,
} from '@ge/shared/models/table-col-defs/alert-history-table-cols';
import {
  CaseHistoryColumnDefs,
  defaultCaseHistoryCols,
} from '@ge/shared/models/table-col-defs/case-history-table-cols';
import { killEventPropagation } from '@ge/shared/util/general';
import { typography } from '@ge/tokens/typography';

import { TabDetailContainer } from '../../entity-details-shared';

const EventMetric = {
  START: 'start',
};

const AlertMetric = {
  CREATED_DATE: 'createdDate',
};

export const TaskMetric = {
  COMPLETED_DATE_TIME: 'completedDateTime',
};
export const OverviewTitle = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  .history {
    line-height: 21px;
    margin: 30px 20px 35px 45px;
  }
`;
const Banner = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 110px 0;
  align-item: center;
  justify-content: center;
  text-align: center;
  span {
    padding-top: 10px;
  }
`;
const NoDataTitle = styled(Text).attrs(() => ({
  type: typography.textTypes.body,
  level: 1,
}))`
  margin-bottom: 6px;
`;
const Load = styled.div`
  padding-top: 400px;
`;
const TableActionsContainer = styled.div`
  display:flex
  flex-direction:row;
  justify-content:flex-end;
  align-items:center;
`;
export const AssetHistory = ({
  assetId,
  isAuthorizedToViewTasks,
  isAuthorizedToMonitorCases,
  isAuthorizedAlertsHistory,
}) => {
  const { t, ready } = useTranslation(['manage.cases-tasks', 'monitor.issues'], {
    useSuspense: false,
  });

  const HistorySelectOptions = [
    { label: t('selectMenu.last7_days', 'Last 7 Days'), value: 7 },
    { label: t('selectMenu.last30_days', 'Last 30 Days'), value: 30 },
  ];

  const AlertHistorySelectOptions = [
    { label: t('selectMenu.last30_days', 'Last 30 Days'), value: 30 },
    { label: t('selectMenu.last90_days', 'Last 90 Days'), value: 90 },
  ];

  //if any other capibilities is added in future then respective dropdown values can be added inside options
  const SelectOptions = useMemo(() => {
    const options = [
      {
        value: TableType.TASK_HISTORY,
        label: t('selectMenu.task_completion', 'Asset History Task Completion History'),
        enabled: isAuthorizedToViewTasks,
      },
      {
        value: TableType.EVENT,
        label: t('selectMenu.case_history', 'Asset History - Case'),
        enabled: isAuthorizedToMonitorCases,
      },
      {
        value: TableType.ALERT_HISTORY,
        label: t('selectMenu.alert_history', 'Asset History - Alert'),
        enabled: isAuthorizedAlertsHistory,
      },
    ];
    return options.filter((op) => op.enabled);
  }, [isAuthorizedToMonitorCases, isAuthorizedToViewTasks, isAuthorizedAlertsHistory, t]);
  const [timeRange, setTimeRange] = useState(HistorySelectOptions[0]);
  const [alertTimeRange, setAlertTimeRange] = useState(AlertHistorySelectOptions[0]);
  const [tableType, setTableType] = useState(SelectOptions[0]?.value);
  const [filterDefs, setFilterDefs] = useState({});
  const [currentSearch, setCurrentSearch] = useState({});

  // Table state management
  const {
    sortDirection,
    sortMetric,
    updateSortMetric: toggleHistoryTaskSort,
    visibleCols: visibleAssetHistoryCols,
    updateColumnVisibility: updateAssetHistoryColumnVisibility,
    setVisibleColumns: setVisibleAssetHistoryCols,
  } = useColumnState({
    columnDefs: CaseHistoryColumnDefs,
    defaultCols: defaultCaseHistoryCols,
    defaultSortMetric: TaskMetric.COMPLETED_DATE_TIME,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: ManageDefs.HISTORY,
  });
  const { showTaskDetails } = useContext(EntityDetailsContext);

  const handleSelect = useCallback((option) => setTableType(option.value), []);

  const selectedHistoryType = useMemo(
    () => SelectOptions.filter((option) => option.value === tableType),
    [SelectOptions, tableType],
  );
  const {
    sortDirection: sortHistoryDirection,
    sortMetric: sortHistoryMetric,
    updateSortMetric: toggleHistorySort,
  } = useColumnState({
    columnDefs: CaseHistoryColumnDefs,
    defaultCols: defaultCaseHistoryCols,
    defaultSortMetric: EventMetric.START,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: MonitorDefs.HISTORY,
  });

  const {
    sortDirection: sortAlertHistoryDirection,
    sortMetric: sortAlertHistoryMetric,
    updateSortMetric: toggleAlertHistorySort,
    visibleCols: visibleAlertHistoryCols,
  } = useColumnState({
    columnDefs: AlertHistoryColumnDefs,
    defaultCols: defaultAlertHistoryCols,
    defaultSortMetric: AlertMetric.CREATED_DATE,
    defaultSortDirection: SortDirection.DESC,
    sortStateId: MonitorDefs.HISTORY,
  });

  const filteredColumns = useMemo(() => {
    const filteredColDefs = defaultCaseHistoryCols?.reduce((prevDefs, c) => {
      const cols = c?.cols?.reduce((prevCols, col) => {
        if (col?.filter === undefined || col?.filter === tableType) {
          prevCols[col.id] = CaseHistoryColumnDefs[c.id]?.cols?.[col.id];
        }
        return prevCols;
      }, {});
      if (Object.keys(cols).length) {
        prevDefs[c.id] = { cols };
      }
      return prevDefs;
    }, {});
    return filteredColDefs;
  }, [tableType]);

  const {
    data: caseHistoryData,
    isLoading: isCaseHistoryDataLoading,
    refetch: refetchCaseHistoryData,
    isRefetching: isCaseHistoryDataRefetching,
  } = useAssetCaseHistory({
    assetId,
    days: timeRange.value,
    sortDirection: sortHistoryDirection,
    sortMetric: sortHistoryMetric,
    enabled: tableType === TableType.EVENT && isAuthorizedToMonitorCases,
  });
  const { taskUpdateForAsset } = useStoreState((state) => state.tasks);
  const {
    data: taskHistoryData,
    isLoading: isTaskHisotryDataLoading,
    filterValues,
  } = useAssetTaskHistory({
    filters: filterDefs,
    search: currentSearch,
    assetId,
    sortDirection,
    sortMetric,
    enabled: tableType === TableType.TASK_HISTORY,
    taskUpdateForAsset: taskUpdateForAsset[assetId] ?? assetId,
  });
  const {
    data: alertHistoryData,
    isLoading: isAlertHistoryDataLoading,
    refetch: refetchAlertHistoryData,
    isRefetching: isAlertHistoryDataRefetching,
    filterValues: alertFilterValues,
  } = useAssetAlertHistory({
    filters: filterDefs,
    search: currentSearch,
    assetId,
    days: alertTimeRange.value,
    sortDirection: sortAlertHistoryDirection,
    sortMetric: sortAlertHistoryMetric,
    enabled: tableType === TableType.ALERT_HISTORY,
  });
  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.MONITOR_ASSETS);

  const { onVisibilityChange: handleAssetHistoryColumnChanges } = useManageColumnVisibility({
    subKey: MonitorDefs.EVENTS,
    featPrefs,
    savePrefs,
    setVisibleColumns: setVisibleAssetHistoryCols,
    updateColumnVisibility: updateAssetHistoryColumnVisibility,
  });

  const historyTableProps = useMemo(() => {
    const dynamicProps = [
      {
        isLoading: isTaskHisotryDataLoading,
        history: taskHistoryData,
        sortAction: toggleHistoryTaskSort,
        sortMetric,
        sortDirection,
        tableType: TableType.TASK_HISTORY,
      },
      {
        isLoading: isCaseHistoryDataLoading,
        history: caseHistoryData,
        sortAction: toggleHistorySort,
        sortMetric: sortHistoryMetric,
        sortDirection: sortHistoryDirection,
        tableType: TableType.EVENT,
      },
      {
        isLoading: isAlertHistoryDataLoading,
        history: alertHistoryData,
        sortAction: toggleAlertHistorySort,
        sortMetric: sortAlertHistoryMetric,
        sortDirection: sortAlertHistoryDirection,
        tableType: TableType.ALERT_HISTORY,
      },
    ];
    return dynamicProps.find((prop) => prop.tableType === tableType);
  }, [
    isTaskHisotryDataLoading,
    taskHistoryData,
    toggleHistoryTaskSort,
    sortMetric,
    sortDirection,
    isCaseHistoryDataLoading,
    caseHistoryData,
    toggleHistorySort,
    sortHistoryMetric,
    sortHistoryDirection,
    isAlertHistoryDataLoading,
    alertHistoryData,
    toggleAlertHistorySort,
    sortAlertHistoryMetric,
    sortAlertHistoryDirection,
    tableType,
  ]);

  const { isLoading, history: data } = historyTableProps;
  const handleFilter = useCallback((updateDefs) => setFilterDefs(updateDefs), [setFilterDefs]);

  const handleFilterChange = useCallback((search) => setCurrentSearch(search), [setCurrentSearch]);

  const handleEventSelect = useCallback((e) => {
    killEventPropagation(e);
  }, []);

  const handleAssetCaseHistoryRefresh = (e) => {
    killEventPropagation(e);
    refetchCaseHistoryData({ throwOnError: true });
  };

  const handleAssetAlertHistoryRefresh = (e) => {
    killEventPropagation(e);
    refetchAlertHistoryData({ throwOnError: true });
  };

  const handleTaskSelect = useCallback(
    (_, task) => {
      showTaskDetails(task.id);
    },
    [showTaskDetails],
  );

  if (!ready || isLoading) {
    return (
      <Load>
        <Loader />
      </Load>
    );
  }
  return (
    <TabDetailContainer>
      {!isLoading && (
        <OverviewTitle>
          {SelectOptions?.length && (
            <Select
              primary
              className="history"
              minWidth={375}
              maxWidth={375}
              options={SelectOptions}
              value={selectedHistoryType}
              onChange={handleSelect}
            />
          )}
          {tableType === TableType.EVENT || tableType === TableType.TASK_HISTORY ? (
            <Select
              small
              maxWidth={180}
              options={HistorySelectOptions}
              value={timeRange}
              onChange={(option) => setTimeRange(option)}
            />
          ) : null}
          {tableType === TableType.ALERT_HISTORY ? (
            <Select
              small
              maxWidth={180}
              options={AlertHistorySelectOptions}
              value={alertTimeRange}
              onChange={(option) => setAlertTimeRange(option)}
            />
          ) : null}
        </OverviewTitle>
      )}
      {tableType === TableType.TASK_HISTORY ||
        (tableType === TableType.EVENT && (
          <AuthRender
            capability={Capability.CASES}
            view
            description="Column selector and event refresh"
            siteLevel={false}
          >
            <TableActionsContainer>
              <EventRefreshButtonWithToolTip
                onClick={handleAssetCaseHistoryRefresh}
                inProgress={isCaseHistoryDataRefetching}
                toolTip={
                  isCaseHistoryDataRefetching
                    ? t('event_refresh.tooltip.in_progress', 'Refresh in Progress')
                    : t('event_refresh.tooltip.case_refresh', 'Case Refresh')
                }
              />
              <ColumnSelectMenu
                heightOffset={380}
                translateFn={t}
                columnDef={filteredColumns}
                columnState={visibleAssetHistoryCols}
                onMenuClose={handleAssetHistoryColumnChanges}
              />
            </TableActionsContainer>
          </AuthRender>
        ))}
      {tableType === TableType.ALERT_HISTORY && (
        <AuthRender
          capability={Capability.ALERTS}
          view
          description="Column selector and event refresh"
          siteLevel={false}
        >
          <TableActionsContainer>
            <EventRefreshButtonWithToolTip
              onClick={handleAssetAlertHistoryRefresh}
              inProgress={isAlertHistoryDataRefetching}
              toolTip={
                isAlertHistoryDataRefetching
                  ? t('event_refresh.tooltip.in_progress', 'Refresh in Progress')
                  : t('event_refresh.tooltip.alert_refresh', 'Alert Refresh')
              }
            />
          </TableActionsContainer>
        </AuthRender>
      )}
      {(tableType === TableType.EVENT || tableType === TableType.TASK_HISTORY) && (
        <CaseHistoryTable
          columns={visibleAssetHistoryCols}
          tableType={tableType}
          onEventSelect={handleEventSelect}
          onTaskSelect={handleTaskSelect}
          onFilter={handleFilter}
          onFilterChange={handleFilterChange}
          filterValues={filterValues}
          {...historyTableProps}
        />
      )}
      {tableType === TableType.ALERT_HISTORY && (
        <AlertHistoryTable
          columns={visibleAlertHistoryCols}
          tableType={tableType}
          onEventSelect={handleEventSelect}
          onFilter={handleFilter}
          onFilterChange={handleFilterChange}
          filterValues={alertFilterValues}
          {...historyTableProps}
        />
      )}
      {data.length === 0 && !isLoading && (
        <Banner>
          <span>
            {tableType === TableType.TASK_HISTORY && (
              <NoDataTitle>{t('no_tasks', 'No Tasks')}</NoDataTitle>
            )}
            {tableType === TableType.EVENT && (
              <NoDataTitle>{t('no_cases', 'No Cases')}</NoDataTitle>
            )}
            {tableType === TableType.ALERT_HISTORY && (
              <NoDataTitle>{t('no_alerts', 'No Alerts')}</NoDataTitle>
            )}
          </span>
        </Banner>
      )}
    </TabDetailContainer>
  );
};

AssetHistory.propTypes = {
  assetId: PropTypes.instanceOf(Object).isRequired,
  isAuthorizedToViewTasks: PropTypes.bool,
  isAuthorizedToMonitorCases: PropTypes.bool,
  isAuthorizedToAnalyzeCases: PropTypes.bool,
  isAuthorizedAlertsHistory: PropTypes.bool,
};
AssetHistory.defaultProps = {
  isAuthorizedToViewTasks: false,
  isAuthorizedToMonitorCases: false,
  isAuthorizedToAnalyzeCases: false,
  isAuthorizedAlertsHistory: false,
};
