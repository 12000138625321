import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { ScrollingContainer } from '@ge/components/scrolling-container';

import StatusCardFactory from './status-cards/status-card-factory';

const WatchlistContainer = styled.div`
  border-top: solid 1px ${(props) => props.theme.layout.sidebarBorderTopColor};
  max-height: 50%;
  flex: 1;
  display: flex;
  padding: 18px 4px 10px 10px;
  flex-direction: column;
  .simplebar-content > div:last-child {
    margin-bottom: 4px;
  }
  .container-title h3 {
    text-transform: uppercase;
  }
`;

const WatchilistIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.WATCHLIST,
  color: props.theme.sidebar.iconColor,
}))`
  margin-right: 6px;
`;

const SettingsIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: Icons.SETTINGS,
  color: props.theme.sidebar.settingsIconColor,
}))``;

const Watchlist = ({ statuses, onShowSettings }) => {
  const { t } = useTranslation();

  return (
    <WatchlistContainer>
      <div className="container-title">
        <WatchilistIcon />
        <h3>{t('sidebar.watchlist', 'WATCHLIST')}</h3>
        <button type="button" className="settings-btn" onClick={() => onShowSettings('watchlist')}>
          <SettingsIcon />
        </button>
      </div>
      <ScrollingContainer style={{ maxHeight: '100%' }} autoHide={false} offsetX={10} absolute>
        {statuses && statuses.map((monitor, idx) => StatusCardFactory.build(monitor, idx))}
      </ScrollingContainer>
    </WatchlistContainer>
  );
};

Watchlist.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  onShowSettings: PropTypes.func,
};

Watchlist.defaultProps = {
  statuses: [],
  onShowSettings: () => null,
};

export default Watchlist;
