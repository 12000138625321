import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { useMemo } from 'react';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';

import {
  QueryKey,
  NoteSelectOptions,
  NotesCategory,
  SpecialInstructionStatus,
  EntityType,
} from '@ge/models/constants';
import {
  addNoteAndSI,
  editNoteAndSI,
  fetchNotesData,
  deleteNoteAndSI,
} from '@ge/shared/services/notes';

export const useNotesPaginationDetails = (
  { assetType, notesType, id, pageSize, creationDate, siteId },
  enabled = true,
) => {
  const queryKey = [QueryKey.NOTES_DETAILS, assetType, notesType, id];
  if (assetType === EntityType.ASSET && siteId) {
    queryKey.push(siteId);
  }
  const { data, isFetching, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => {
      if (!enabled) return {};
      return fetchNotesData(assetType, notesType, id, pageParam, pageSize, creationDate, siteId);
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnWindowFocus: false,
    },
  );
  return { data, isFetching, fetchNextPage };
};

// Have to check if the other query keys exists or not before updating
const checkForQueryKey = (queryClient, key) => {
  return queryClient.getQueryData(key) !== undefined;
};

export const useAddNote = ({ assetType, id }) => {
  const { getAssetById } = useStoreState((store) => store.assets);
  const queryClient = useQueryClient();
  const {
    mutate: add,
    isLoading,
    isError,
    error,
  } = useMutation((input_data) => addNoteAndSI(input_data), {
    onSuccess: (respData) => {
      // If a new note/SI is added, don't fetch all the details again. Add it to the query cache
      // If a note is added, add it to "SITE/ASSET NOTES"
      // If a special instruction is added, if it is active, add to "ACTIVE_SPECIAL_INSTRUCTION" or add to "INACTIVE_SPECIAL_INSTRUCTION"
      // Bu default, add everything to "ALL"

      const category =
        respData.category === NotesCategory.NOTE
          ? NoteSelectOptions.NOTES
          : respData.status === SpecialInstructionStatus.ACTIVE
          ? NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION
          : NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION;

      const queryKey = [QueryKey.NOTES_DETAILS, assetType, category, id];
      if (checkForQueryKey(queryClient, queryKey))
        queryClient.setQueryData(queryKey, (_data) => {
          let lastPage = _data.pages[_data.pages.length - 1];
          const totalCount = lastPage?.pagination?.totalRecords + 1;
          lastPage = {
            ...lastPage,
            pagination: {
              totalRecords: totalCount,
              currentPage: lastPage?.pagination?.currentPage,
            },
            notes: [...lastPage.notes, respData],
          };
          _data.pages[_data.pages.length - 1] = lastPage;
          return { ..._data, updated: dayjs() }; //#TODO: dirty hack to force the useeffect with array props to trigger. Will change later
        });

      // queryClient.setQueryData(
      //   [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.ALL, id],
      //   (_data) => {
      //     if (!_data) return;
      //     let lastPage = _data.pages[_data.pages.length - 1];
      //     const totalCount = lastPage?.pagination?.totalRecords + 1;
      //     lastPage = {
      //       ...lastPage,
      //       pagination: {
      //         totalRecords: totalCount,
      //         currentPage: lastPage?.pagination?.currentPage,
      //       },
      //       notes: [...lastPage.notes, respData],
      //     };
      //     _data.pages[_data.pages.length - 1] = lastPage;
      //     return { ..._data, updated: dayjs() }; //#TODO: dirty hack to force the useeffect with array props to trigger. Will change later
      //   },
      // );

      if (
        assetType === EntityType.ASSET &&
        category === NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION
      ) {
        const asset = getAssetById(id);
        const queryKey = [QueryKey.NOTES_DETAILS, assetType, category, id];
        if (asset?.site?.id) {
          queryKey.push(asset.site.id);
        }
        if (checkForQueryKey(queryClient, queryKey))
          queryClient.setQueryData(queryKey, (_data) => {
            let lastPage = _data.pages[_data.pages.length - 1];
            const totalCount = lastPage?.pagination?.totalRecords + 1;
            lastPage = {
              ...lastPage,
              pagination: {
                totalRecords: totalCount,
                currentPage: lastPage?.pagination?.currentPage,
              },
              notes: [...lastPage.notes, respData],
            };
            _data.pages[_data.pages.length - 1] = lastPage;
            return { ..._data, updated: dayjs() }; //#TODO: dirty hack to force the useeffect with array props to trigger. Will change later
          });
      }
    },
  });
  return useMemo(() => ({ add, isLoading, isError, error }), [add, error, isError, isLoading]);
};

export const useEditNote = ({ assetType, id }) => {
  const { getAssetById } = useStoreState((store) => store.assets);
  const queryClient = useQueryClient();
  const {
    mutate: edit,
    data,
    isLoading,
    isError,
    error,
  } = useMutation(({ inputData, originalData }) => editNoteAndSI({ inputData, originalData }), {
    onSettled: (respData, error, variables) => {
      let pages;
      if (!error) {
        // Edit has more scenarios to handle compared to "Add"
        // Either "prefetch" all the querykeys ( ALL, SITE/ASSET NOTES, IN_/ACTIVE_SPECIAL_INSTRUCTION) or check if the query key exists before modifying
        // If we update the key even if if doesn't exist, api will not be triggered when we first visit the option that the key is associated to

        // Data for all queryKeys will have pages.
        // If we are updating a note, update NOTE and ALL query keys..
        // If we are updating a SI :
        //    Check if the status is updated so that we have to move from active/inactive to inactive/active
        //    If any other property is updated, update based on the current status

        // Be default, update "ALL"

        if (respData.original.category === NotesCategory.NOTE) {
          const queryKey = [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.NOTES, id];
          if (checkForQueryKey(queryClient, queryKey))
            queryClient.setQueryData(queryKey, (_data) => {
              // "Updated" will not always have all the props..
              pages = _data.pages?.map((page) => {
                return {
                  ...page,
                  notes: page.notes.map((note) =>
                    note.id === respData.original.id
                      ? { ...respData.original, ...respData.updated }
                      : note,
                  ),
                };
              });
              return { pages, pageParams: _data.pageParams };
            });
        } else {
          const category =
            respData.original.status === SpecialInstructionStatus.ACTIVE
              ? NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION
              : NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION;

          if (respData.original.status === respData.updated.status) {
            const queryKey = [QueryKey.NOTES_DETAILS, assetType, category, id];
            if (checkForQueryKey(queryClient, queryKey))
              queryClient.setQueryData(queryKey, (_data) => {
                // "Updated" will not always have all the props..
                pages = _data.pages?.map((page) => {
                  return {
                    ...page,
                    notes: page.notes.map((note) =>
                      note.id === respData.original.id ? { ...note, ...respData.updated } : note,
                    ),
                  };
                });
                return { pages, pageParams: _data.pageParams };
              });
          } else {
            const removeFromCategory = category;
            const addToCategory =
              respData.updated.status === SpecialInstructionStatus.ACTIVE
                ? NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION
                : NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION;

            let queryKey = [QueryKey.NOTES_DETAILS, assetType, addToCategory, id];
            if (checkForQueryKey(queryClient, queryKey))
              queryClient.setQueryData(queryKey, (_data) => {
                let lastPage = _data.pages[_data.pages.length - 1];
                const totalCount = lastPage?.pagination?.totalRecords + 1;
                lastPage = {
                  ...lastPage,
                  pagination: {
                    totalRecords: totalCount,
                    currentPage: lastPage?.pagination?.currentPage,
                  },
                  notes: [...lastPage.notes, { ...respData.original, ...respData.updated }],
                };
                _data.pages[_data.pages.length - 1] = lastPage;
                return _data;
              });

            const asset = getAssetById(id);
            if (
              assetType === EntityType.ASSET &&
              addToCategory === NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION
            ) {
              queryKey = [QueryKey.NOTES_DETAILS, assetType, addToCategory, id];
              if (asset?.site?.id) {
                queryKey.push(asset.site.id);
              }
              if (checkForQueryKey(queryClient, queryKey))
                queryClient.setQueryData(queryKey, (_data) => {
                  let lastPage = _data.pages[_data.pages.length - 1];
                  const totalCount = lastPage?.pagination?.totalRecords + 1;
                  lastPage = {
                    ...lastPage,
                    pagination: {
                      totalRecords: totalCount,
                      currentPage: lastPage?.pagination?.currentPage,
                    },
                    notes: [...lastPage.notes, { ...respData.original, ...respData.updated }],
                  };
                  _data.pages[_data.pages.length - 1] = lastPage;
                  return _data;
                });
            }

            queryKey = [QueryKey.NOTES_DETAILS, assetType, removeFromCategory, id];

            if (checkForQueryKey(queryClient, queryKey))
              queryClient.setQueryData(queryKey, (_data) => {
                pages = _data.pages?.map((page) => {
                  const totalCount =
                    page?.pagination?.totalRecords > 0 ? page?.pagination?.totalRecords - 1 : 0;
                  return {
                    ...page,
                    pagination: {
                      totalRecords: totalCount,
                      currentPage: page?.pagination?.currentPage,
                    },
                    notes: page.notes.filter((note) => note.id !== respData.original.id),
                  };
                });
                return { pages, pageParams: _data.pageParams };
              });

            if (
              assetType === EntityType.ASSET &&
              removeFromCategory === NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION
            ) {
              queryKey = [QueryKey.NOTES_DETAILS, assetType, removeFromCategory, id];
              if (asset?.site?.id) {
                queryKey.push(asset.site.id);
              }
              if (checkForQueryKey(queryClient, queryKey))
                queryClient.setQueryData(queryKey, (_data) => {
                  pages = _data.pages?.map((page) => {
                    const totalCount =
                      page?.pagination?.totalRecords > 0 ? page?.pagination?.totalRecords - 1 : 0;
                    return {
                      ...page,
                      pagination: {
                        totalRecords: totalCount,
                        currentPage: page?.pagination?.currentPage,
                      },
                      notes: page.notes.filter((note) => note.id !== respData.original.id),
                    };
                  });
                  return { pages, pageParams: _data.pageParams };
                });
            }
          }
        }
        // queryClient.setQueryData(
        //   [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.ALL, id],
        //   (_data) => {
        //     if (!_data) return;
        //     pages = _data.pages?.map((page) => {
        //       return {
        //         ...page,
        //         notes: page.notes.map((note) =>
        //           note.id === respData.original.id ? { ...note, ...respData.updated } : note,
        //         ),
        //       };
        //     });
        //     return { pages, pageParams: _data.pageParams };
        //   },
        // );
      } else {
        // Force update the existing cache so that the component can refresh with old data.
        if (variables.category === NotesCategory.NOTE) {
          const queryKey = [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.NOTES, id];
          if (checkForQueryKey(queryClient, queryKey))
            queryClient.setQueryData(queryKey, (_data) => {
              return { ..._data };
            });
        } else {
          let queryKey = [
            QueryKey.NOTES_DETAILS,
            assetType,
            NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION,
            id,
          ];

          if (checkForQueryKey(queryClient, queryKey))
            queryClient.setQueryData(queryKey, (_data) => {
              return { ..._data };
            });

          queryKey = [
            QueryKey.NOTES_DETAILS,
            assetType,
            NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION,
            id,
          ];
          if (checkForQueryKey(queryClient, queryKey))
            queryClient.setQueryData(queryKey, (_data) => {
              return { ..._data };
            });
        }

        // queryClient.setQueryData(
        //   [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.ALL, id],
        //   (_data) => {
        //     if (!_data) return;
        //     return { ..._data };
        //   },
        // );
      }
    },
  });
  return useMemo(
    () => ({ edit, isLoading, isError, error, data }),
    [edit, error, isError, isLoading, data],
  );
};

export const useDeleteNote = ({ assetType, id }) => {
  const queryClient = useQueryClient();
  const { getAssetById } = useStoreState((store) => store.assets);
  const {
    mutate: del,
    isLoading,
    isError,
    error,
  } = useMutation(
    (input_data) => {
      return deleteNoteAndSI(input_data);
    },
    {
      onSettled: (respData, error, variables) => {
        const asset = getAssetById(id);
        if (!error && respData === variables.id) {
          let queryKey = [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.NOTES, id];
          // Invalidating the query whenever any record gets deleted
          queryClient.invalidateQueries(queryKey);

          queryKey = [
            QueryKey.NOTES_DETAILS,
            assetType,
            NoteSelectOptions.ACTIVE_SPECIAL_INSTRUCTION,
            id,
          ];

          // Invalidating the query whenever any record gets deleted
          queryClient.invalidateQueries(queryKey);

          if (assetType === EntityType.ASSET && asset?.site?.id) {
            queryKey.push(asset.site.id);
            queryClient.invalidateQueries(queryKey);
          }

          queryKey = [
            QueryKey.NOTES_DETAILS,
            assetType,
            NoteSelectOptions.INACTIVE_SPECIAL_INSTRUCTION,
            id,
          ];

          // Invalidating the query whenever any record gets deleted
          queryClient.invalidateQueries(queryKey);

          // queryKey = [QueryKey.NOTES_DETAILS, assetType, NoteSelectOptions.ALL, id];

          // // Invalidating the query whenever any record gets deleted
          // queryClient.invalidateQueries(queryKey);
        }
      },
    },
  );
  return useMemo(() => ({ del, isLoading, isError, error }), [del, error, isError, isLoading]);
};
