import { PropTypes } from 'prop-types';
import React, { Suspense } from 'react';

import { ComponentLoader } from '@ge/components/loader/loader';
import ErrorBoundary from '@ge/shared/components/error-boundary/error-boundary';
export const LazyLoad = (props) => {
  const { children } = props;
  return (
    <ErrorBoundary>
      <Suspense fallback={<ComponentLoader />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

LazyLoad.propTypes = {
  children: PropTypes.node.isRequired,
};

LazyLoad.displayName = 'LazyLoad';
export default LazyLoad;
