import { ScrollOptions } from '@ge/feat-manage/models/constants';

function getPosition(e, el) {
  var rect = el.getBoundingClientRect();
  var x = e.clientX - rect.left;
  var y = e.clientY - rect.top;
  return {
    x,
    y,
  };
}

function handleCalendarViewScroll(e) {
  const delta = ScrollOptions.DELTA;
  var el = e.currentTarget;
  var position = getPosition(e, el);
  if (position.x < 300 && el.scrollLeft > 0) {
    el.scrollLeft = el.scrollLeft - delta;
  } else if (el.offsetWidth - position.x < 30) {
    el.scrollLeft = el.scrollLeft + delta;
  }
  if (position.y < 119 && el.scrollTop > 0) {
    el.scrollTop = el.scrollTop - delta;
  } else if (el.offsetHeight - position.y < 30) {
    el.scrollTop = el.scrollTop + delta;
  }
}

export function calendarViewScroll({ active, ref }) {
  if (ref?.current) {
    if (active) {
      ref?.current.addEventListener('dragover', handleCalendarViewScroll, true);
    } else {
      ref?.current.removeEventListener('dragover', handleCalendarViewScroll, true);
    }
  }
}
