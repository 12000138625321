import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

// keyframes are currently defined in the web-client, could move into packages so can be shared here too
export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const IconAnimation = {
  rotate: css`
    animation: ${rotate} 2s linear infinite;
  `, // can add support for different speeds
};

export const StyledIcon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.color};
  transform: rotate(${(props) => props.rotate}deg);
  ${({ animate }) => IconAnimation[animate]}
  &:hover {
    fill: ${(props) => props.hover};
  }
`;

export const HighlightIcon = ({
  className,
  color,
  height,
  icon,
  rotate,
  viewbox,
  animate,
  hover,
}) => (
  <StyledIcon
    animate={animate}
    color={color}
    viewBox={viewbox}
    width={`100%`}
    height={height}
    className={className}
    rotate={rotate}
    hover={hover}
  >
    <defs>
      <linearGradient x1="0%" y1="-3.49018592e-09%" x2="100%" y2="0%" id="linearGradient-1">
        <stop stopColor="#DB8585" stopOpacity="0.45" offset="0%"></stop>
        <stop stopColor="#C15A47" stopOpacity="0.33" offset="35.8530405%"></stop>
        <stop stopColor="#782D1E" stopOpacity="0.194438374" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Contractual-Availability---SG-&gt;-Edited"
        transform="translate(-889.000000, -562.000000)"
      >
        <g
          id="Grids/New-Row"
          transform="translate(969.000000, 573.500000) rotate(-90.000000) translate(-969.000000, -573.500000) translate(958.000000, 494.000000)"
        >
          <path d={icon} fill="url(#linearGradient-1)" />
          <rect id="Rectangle" fill="#EF5353" x="0" y="0" width="3" height="159"></rect>
        </g>
      </g>
    </g>
  </StyledIcon>
);

HighlightIcon.propTypes = {
  animate: PropTypes.oneOf(Object.keys(IconAnimation)),
  className: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.string,
  icon: PropTypes.string.isRequired,
  rotate: PropTypes.number,
  height: PropTypes.string,
  viewbox: PropTypes.string,
};

HighlightIcon.defaultProps = {
  className: null,
  rotate: 0,
  viewbox: '0 0 20 20',
  hover: '',
};
