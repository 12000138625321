import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TaskTemplateModes } from '@ge/models/constants';

import { LaborEstimates } from '../../../task-fields/labor-estimates';
import { FormRow, FormSection, StyledCollapsiblePanel } from '../../../task-template-shared';

const EstimateRow = styled(FormRow)`
  justify-content: flex-start;
  .field-group {
    display: flex;
    align-items: flex-end;
    margin-right: 40px;
  }
`;

export const CreateLaborEstimates = ({ collapsed, metadata }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <StyledCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{t('section_header.labor_estimates', 'Labor Estimates')}</h4>}
      plusIcon
    >
      <FormSection>
        <EstimateRow>
          <LaborEstimates metadata={metadata} mode={TaskTemplateModes.CREATE} />
        </EstimateRow>
      </FormSection>
    </StyledCollapsiblePanel>
  );
};

CreateLaborEstimates.propTypes = {
  collapsed: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
};

CreateLaborEstimates.defaultProps = {
  collapsed: true,
};
