import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  NAMESPACE,
  Fields,
  AssetType,
  AssetTypeDefs,
} from '@ge/feat-analyze/models/configure-analysis-template';
import { EntityMode } from '@ge/models/constants';
import {
  TextMetaField,
  CheckBoxMetaField,
  SelectMetaField,
  MultiSelectMetaField,
} from '@ge/shared/components/meta-fields';

import { FormRow, FormCollapsiblePanel } from '../../shared';

const Input = styled(TextMetaField)`
  max-width: 50%;
`;

const Select = styled(SelectMetaField)`
  white-space: nowrap;
  .select__placeholder {
    overflow: hidden;
  }
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const MultiSelect = styled(MultiSelectMetaField)`
  .select__dropdown-control {
    display: flex;
    span {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
    }
  }
`;

const StyledCheckBox = styled(CheckBoxMetaField)`
  margin: ${({ margin }) => margin};
  span {
    color: ${(props) => props.theme.select.primaryTextColor};
  }
`;

const OverviewDetails = ({ isOpen, signalMaps, entityMode }) => {
  const { t, ready } = useTranslation([NAMESPACE], { useSuspense: false });

  const assetClassOptions = useMemo(
    () =>
      Object.values(AssetType).map((value) => ({
        label: t(
          `analysis_template.${AssetTypeDefs[value].a11yKey}`,
          AssetTypeDefs[value].a11yDefault,
        ),
        value,
      })) ?? [],
    [t],
  );

  const signalMapOptions = useMemo(() => {
    return signalMaps?.map(({ id, name }) => ({ label: name, value: id })) ?? [];
  }, [signalMaps]);

  if (!ready) return null;

  return (
    <FormCollapsiblePanel
      isOpen={isOpen}
      title={t('analysis_template.details', 'Details')}
      allowOverflow={true}
    >
      <FormRow>
        <Input
          name={Fields.ANALYSIS_TEMPLATE_ID}
          label={t('analysis_template.analysis_template_id', 'Analysis Template Id')}
          placeholder={t(
            `analysis_template.analysis_template_id_placeholder`,
            'Type analysis template id...',
          )}
          defaultValue=""
          metadata={{ required: true, readOnly: entityMode !== EntityMode.CREATE }}
        />
      </FormRow>
      <FormRow>
        <Select
          name={Fields.ASSET_TYPE_ID}
          label={t('analysis_template.asset_class', 'Asset Class')}
          placeholder={t(`analysis_template.asset_class_placeholder`, 'Select Asset Class')}
          defaultValue={null}
          options={assetClassOptions}
          metadata={{ required: entityMode === EntityMode.CREATE }}
          maxWidth={144}
          searchable={false}
        />
        <MultiSelect
          name={Fields.SIGNAL_MAPS}
          label={t('analysis_template.signal_maps', 'Signal Maps')}
          placeholder={t(`analysis_template.signal_maps_placeholder`, 'Select Signal Maps')}
          defaultValue={[]}
          options={signalMapOptions}
          metadata={{ required: entityMode === EntityMode.CREATE }}
          maxWidth={144}
          showBadge={false}
          isSelectAll={true}
        />
      </FormRow>
      <FormRow>
        <div>
          <StyledCheckBox
            name={Fields.IS_DEFAULT_TEMPLATE}
            label={t('analysis_template.default_template', 'Default Template')}
            defaultValue={false}
            metadata={{ required: false }}
          />
        </div>
      </FormRow>
    </FormCollapsiblePanel>
  );
};

OverviewDetails.propTypes = {
  isOpen: PropTypes.bool,
  signalMaps: PropTypes.array,
  entityMode: PropTypes.string,
};

OverviewDetails.defaultValues = {
  signalMaps: [],
};

export default OverviewDetails;
