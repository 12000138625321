import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useRef, useMemo } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { usePlanningContext } from '@ge/feat-manage/context/planning-context';
import { useCalendarCardMenu } from '@ge/feat-manage/data-hooks/use-calendar-card-menu';
import { TaskStatus } from '@ge/models';
import { typography } from '@ge/tokens';

import { DAY_PADDING } from '../../../../context/planning-provider';
import { DragItemTypes, FilterValues } from '../../../../context/planning-provider';

import { StackMenu } from './stack-card-menu';

const StyledStack = styled.div`
  cursor: ${(props) => (props.draggable ? 'pointer' : 'not-allowed')};
  position: absolute;
  left: ${({ position }) => position}px;
  top: ${({ top }) => top}px;
  margin-left: ${DAY_PADDING - 2}px; // subtracting 2 for the border on the cards
  &.selected {
    div.card:first-child {
      border-color: ${({ theme }) => theme.manage.cards.selectedBorder};
      &:after {
        content: '';
        background: ${({ theme }) => theme.manage.cards.selectedHalo};
        position: absolute;
        left: -5px;
        display: block;
        top: -5px;
        right: -5px;
        bottom: -5px;
        z-index: -1;
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.7);
      }
    }
    div.card:last-child {
      display: none;
    }
  }
  div.card {
    cursor: ${(props) => (props.draggable ? 'pointer' : 'not-allowed')};
    line-height: 68px;
    height: 68px;
    width: ${({ width }) => width}px;
    border: ${({ theme }) => theme.manage.cards.border};
    background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    box-shadow: ${({ theme }) => theme.manage.cards.stack.boxShadow};
    border-radius: 3px;
    text-align: center;
    font-weight: ${typography.weight.bolder};
    &:first-child {
      position: absolute;
      top: -5px;
      left: -5px;
    }
  }
  &.scheduled div.card {
    background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
  }
  &.completed div.card {
    background-color: ${({ theme }) => theme.manage.cards.completedColor};
    color: ${({ theme }) => theme.manage.cards.completedTextColor};
  }
  &.mixed-scheduled-completed div.card {
    background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
    &:last-child {
      background-color: ${({ theme }) => theme.manage.cards.completedColor};
    }
  }
  &.mixed-scheduled div.card {
    background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
    &:last-child {
      background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    }
  }
  &.mixed-completed div.card {
    background-color: ${({ theme }) => theme.manage.cards.completedColor};
    color: ${({ theme }) => theme.manage.cards.completedTextColor};
    &:last-child {
      background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    }
  }
  &.draggable > .card {
    cursor: ${(props) => (props.draggable ? 'grab' : 'not-allowed')};
  }
`;

// eslint-disable-next-line no-unused-vars
const BundleBadge = styled.div`
  color: ${(props) => props.theme.manage.cards.bundleBadge};
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  height: 15px;
  line-height: 11px;
  min-width: 20px;
  background-color: ${(props) => props.theme.manage.tabs.badge.backgroundColor};
  border-radius: 4px;
  display: flex;
  position: absolute;
  top: -12px;
  left: ${(props) => props.width - 15 + 'px'};
`;

// eslint-disable-next-line no-unused-vars
const BundleIcon = styled(Icon).attrs({
  size: 20,
  color: (props) => props.theme.manage.cards.bundleBadge,
})`
  position: relative;
  top: 1px;
  left: 4px;
`;

export const StackCard = ({
  tasks,
  position,
  width,
  laneIndex,
  secondaryIndex,
  itemIndex,
  onDrop,
  onClick,
  draggable,
  top,
  bundlePresent,
  date,
}) => {
  const menuRef = useRef(null);
  const anchorEl = useRef(null);
  const ids = tasks.map((task) => task.id);
  const {
    planningState: { handleDragStart, handleDragEnd, groupFilter, handleDragOver },
    serviceGroupIds,
  } = usePlanningContext();

  const { menuShown, destroyStackMenu, showStackMenu } = useCalendarCardMenu({
    tasks,
    date,
  });

  const stackColors = useMemo(() => {
    if (tasks.length > 1) {
      const scheduledCount = tasks.reduce((acc, t) => {
        if (t.status === TaskStatus.SCHEDULED) acc++;
        return acc;
      }, 0);

      if (scheduledCount === tasks.length) return TaskStatus.SCHEDULED;

      const completedCount = tasks.reduce((acc, t) => {
        if (t.status === TaskStatus.COMPLETE) acc++;
        return acc;
      }, 0);

      if (completedCount === tasks.length) return TaskStatus.COMPLETE;

      if (scheduledCount > 0 && completedCount > 0) {
        return 'mixed-scheduled-completed';
      }
      if (scheduledCount > 0) return 'mixed-scheduled';
      if (completedCount > 0) return 'mixed-completed';
      return TaskStatus.UNSCHEDULED;
    }
  }, [tasks]);

  return (
    <StyledStack
      position={position}
      width={width}
      top={top ? top : 25}
      className={classNames('lasso-card', stackColors, { draggable })}
      onMouseEnter={showStackMenu}
      onMouseLeave={destroyStackMenu}
      ref={anchorEl}
      onDrop={onDrop}
      draggable={
        serviceGroupIds.length === 1
          ? draggable
          : groupFilter.L1.value === FilterValues.CREW || groupFilter.L2.value === FilterValues.CREW
          ? false
          : draggable
      }
      onDragStart={() => {
        destroyStackMenu();
        handleDragStart(
          tasks,
          DragItemTypes.CARD,
          null,
          secondaryIndex !== null ? `${laneIndex}-${secondaryIndex}` : laneIndex,
        );
      }}
      onDragEnd={handleDragEnd}
      data-laneindex={laneIndex}
      data-dayindex={itemIndex}
      data-id={ids}
      data-secondaryindex={secondaryIndex}
      onClick={(e) => onClick(e)}
      onDragOver={(e) => handleDragOver(e)}
    >
      <div className="card">{tasks.length}</div>
      <div className="card" />

      <StackMenu
        open={menuShown}
        tasks={menuShown ? tasks : []}
        onClose={destroyStackMenu}
        ref={menuRef}
        anchorEl={anchorEl && anchorEl.current}
        date={date}
        bundlePresent={bundlePresent}
      />

      {bundlePresent && (
        <BundleBadge width={width}>
          <BundleIcon icon={Icons.BUNDLE} />
        </BundleBadge>
      )}
    </StyledStack>
  );
};

StackCard.propTypes = {
  tasks: PropTypes.instanceOf(Array),
  position: PropTypes.number,
  width: PropTypes.number,
  laneIndex: PropTypes.number.isRequired,
  secondaryIndex: PropTypes.number,
  itemIndex: PropTypes.number.isRequired,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
  top: PropTypes.number,
  bundlePresent: PropTypes.bool,
  date: PropTypes.instanceOf(Object),
};

StackCard.defaultProps = {
  tasks: null,
  position: null,
  width: null,
  onDrop: () => {},
  onClick: () => {},
  secondaryIndex: null,
  draggable: false,
  top: null,
  bundlePresent: false,
};
