import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextareaMetaField } from '../../../../meta-fields';
import { ResolutionNotesSelect } from '../../../task-fields/resolution-notes-select';
import { FormRow, FormSection } from '../../../task-template-shared';

export const CreateResolutionNote = ({ metadata, entity }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <FormSection>
      <h4>{t('section_header.resolution_note', 'Resolution Note')}</h4>
      <FormRow>
        {metadata.resolutionNotes.type === 'select' ? (
          <ResolutionNotesSelect metadata={metadata.resolutionNotes.edit} caseId={entity.id} />
        ) : (
          <TextareaMetaField
            label={t('form.note', 'Note')}
            metadata={metadata.resolutionNotes.create}
            maxLength={metadata.resolutionNotes.maxLength}
            name="resolutionNotes"
            placeholder={t('form.note_placeholder', 'Type note here')}
          />
        )}
      </FormRow>
    </FormSection>
  );
};

CreateResolutionNote.propTypes = {
  metadata: PropTypes.object.isRequired,
  entity: PropTypes.instanceOf(Object),
};
