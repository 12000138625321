import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { FLAGGED_STATES } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import tick from '../event-management/assets/tick.svg';

import { CreateHeader } from './em-headers';

//@custom styles
const Container = styled.div`
  padding: 2em 2em 3em 3.3em;
  background: ${(props) => props.theme.distributionListSidePanel.formBackground};
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.distributionListSidePanel.defaultTextLabel};
  font: 700 11px/13px Museo Sans;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
const DetailsWrapper = styled.div`
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.distributionListSidePanel.InputWrapper};

  .label-cls {
    color: ${globalColors.grey4};
    font: 500 11px/18px Museo Sans;
  }
  p {
    color: ${(props) => props.theme.distributionListSidePanel.headerTitle};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  padding-top: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${globalColors.slate16};
  > div {
    flex: 1;
  }
  div {
    &:first-of-type {
      margin-right: 2px;
    }
    label {
      font: 500 11px/18px Museo Sans;
    }

    .label-cls {
      color: ${globalColors.grey4};
      font: 500 11px/18px Museo Sans;
    }
  }
`;

const OverviewWrap = styled.div`
  margin: 1em 0;

  .edit-icon-cls {
    float: right;
    cursor: pointer;
  }
`;

const ScrollableDiv = styled.div`
  max-height: calc(100vh - 200px);
  text-transform: capitalize;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;

const FlaggedStateIcon = styled.div`
  width: 16px;
  height: 14px;
  padding: 2px 3px;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
`;

const SitesWrapper = styled.div`
  max-height: 12em;
  text-transform: capitalize;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 1px;
    background-color: rgba(12, 15, 18, 0.24);
  }
  &::-webkit-scrollbar-thumb {
    height: 100px;
    border: 2px solid transparent;
    background-clip: content-box;
    border-radius: 2.5px;
    background-color: ${(props) => props.theme.table.fixedBorderColor};
  }
`;

const ShowMoreText = styled.div`
  color: #6193a3;
  cursor: pointer;
  margin: 1em 0 0 0;
`;

const ShowMoreIcon = styled(Icon).attrs(() => ({}))`
  transform: ${(props) =>
    props.more ? 'translate(-2px, -2px) rotate(180deg)' : 'translate(-2px, -2px)'};
`;

const SHOW_MORE_TEXT_LENGTH = 9;

//@functional component
export const ViewEventManagementList = ({ details }) => {
  const { t } = useTranslation(['admin.configure']);
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const getSiteType = () =>
    details?.flaggedProperties?.excludeSitesFilter?.length ? 'exclude' : 'include';

  const showMoreText = () => {
    let text =
      details?.[`${getSiteType()}dsites`]?.value?.length > SHOW_MORE_TEXT_LENGTH
        ? !showMore
          ? 'show more'
          : 'show less'
        : '';

    if (text) {
      return (
        <ShowMoreText onClick={() => setShowMore(!showMore)}>
          <ShowMoreIcon more={showMore} size={12} icon={Icons.CHEVRON} color={'#6193a3'} />
          {text}
        </ShowMoreText>
      );
    } else {
      return text;
    }
  };

  return (
    <>
      <CreateHeader data={details} type={'View'} />
      <ScrollableDiv>
        <Container>
          <div>
            <OverviewWrap>
              <div
                className="edit-icon-cls"
                onClick={() => {
                  history.push({
                    pathname: location.pathname,
                    search: `?mode=edit__${details?.eventMapId}__${details?.eventId}`,
                  });
                }}
              >
                <Icon size={12} icon={Icons.PENCIL} color={'White'} />
              </div>
              <StyledLabel>{t('Event_managment.event_details', 'EVENT DETAILS')}</StyledLabel>
            </OverviewWrap>

            <DetailsWrapper>
              <div>
                <div className="label-cls">
                  {t('Event_managment.event_description', 'Description')}
                  <p className="label-cls"></p>
                  <p>{details?.eventRemarks}</p>
                </div>
              </div>
            </DetailsWrapper>

            <DetailsWrapper>
              <div>
                <div>
                  <p className="label-cls">
                    {t(
                      'Event_managment.Assigned_handling_procedure',
                      'Assigned Handling Procedure(s)',
                    )}
                  </p>
                  <p>
                    {details?.procProperties?.procId} - {details?.procProperties?.procTitle}
                  </p>
                </div>
              </div>
            </DetailsWrapper>

            <StyledLabel>
              {getSiteType()
                ? t('affected_sites', `AFFECTED SITES - ${getSiteType().toUpperCase()}D`)
                : ''}
            </StyledLabel>
            <InputWrapper style={{ display: 'block' }}>
              <SitesWrapper style={{ width: '100%', font: '300 12px/15px Museo Sans' }}>
                {details &&
                  details?.[`${getSiteType()}dsites`]?.value?.map((site, index) => {
                    if (showMore || (!showMore && index < SHOW_MORE_TEXT_LENGTH)) {
                      return (
                        <div
                          style={{
                            float: 'left',
                            width: '32%',
                            textAlign: 'left',
                            paddingBottom: '5px',
                            color: `${globalColors.white}`,
                          }}
                          key={index}
                        >
                          {site}
                        </div>
                      );
                    }
                  })}
              </SitesWrapper>
              <div>{showMoreText()}</div>
            </InputWrapper>

            <StyledLabel>{t('settings', 'SETTINGS')}</StyledLabel>
            <InputWrapper>
              <div>
                <p className="label-cls">
                  {t('Event_managment.max_reset_limit', 'Max Reset Limit')}
                </p>
                <p>{details?.commandProperties?.maxResetLimit}</p>
              </div>
              <div>
                <p className="label-cls"> {t('Event_managment.block', 'Block')}</p>
                {details?.commandProperties?.blockCommands && (
                  <p>
                    <img src={tick} alt="Tick Logo" />{' '}
                    {t('Event_managment.Block_commands', 'Block Commands')}
                  </p>
                )}
                {details?.automationProperties?.blockAutomation && (
                  <p>
                    <img src={tick} alt="Tick Logo" />{' '}
                    {t('Event_managment.Block_automation', 'Block Automation')}
                  </p>
                )}
              </div>
              <div>
                <p className="label-cls">
                  {t('Event_managment.Selected_states', 'Selected States')}
                </p>
                {details?.flaggedProperties?.eligableStates?.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: 'flex', marginBottom: '4px', alignItems: 'center' }}
                  >
                    <FlaggedStateIcon style={{ backgroundColor: FLAGGED_STATES[item].color }}>
                      {FLAGGED_STATES[item].letter}
                    </FlaggedStateIcon>{' '}
                    {item}
                  </div>
                )) || ''}
              </div>
            </InputWrapper>
          </div>
        </Container>
      </ScrollableDiv>
    </>
  );
};

ViewEventManagementList.propTypes = {
  details: PropTypes.object,
};
