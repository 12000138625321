/* eslint-disable react/prop-types */ //Because forwareRef is not supported with our version of eslint. It messes up prop-types.
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

import { ChartType, getBaseChartType, ChartBorder } from '@ge/components/charts';
import { DataLoader } from '@ge/components/data-loader';
import { handleKpiError } from '@ge/feat-analyze/util/kpi';
import { EntityType, KpiCategoryDefs, TimeAggr } from '@ge/models/constants';
import { AnalyzeLocators } from '@ge/models/data-locators';
import { globalColors } from '@ge/tokens/colors';

import {
  chartDefPropTypes,
  entityPropType,
  KpiGraphType,
  KpiCategorySeriesType,
  FilterDefs,
} from '../../models';
import { KpiChart } from '../shared/kpi-chart/kpi-chart';

import DashboardChartLegend from './dashboard-chart-legend';
import DashboardChartStats from './dashboard-chart-stats';
import { DashboardChartTitle } from './dashboard-chart-title';
import { DashboardKpiFilter } from './dashboard-kpi-filter';
import { DashboardTitle } from './dashboard-shared';

// temporarily removed intersect observer to dynamically
// calculate chart height because it was causing issues
// with scrolling
const ENTITY_CHART_MIN_WIDTH = 500;
const MAX_SELECTED_ENTITIES = FilterDefs.MAX_SELECTED_ENTITIES;

const MemoizedKpiChart = memo(KpiChart);

const getTitle = ({ categories, t }) =>
  categories?.reduce((title, { grouping }) => {
    const groupingTitle = t(`dynamic.kpi_chart.${grouping}`);

    // don't put redundant groupings in title
    if (title.includes(groupingTitle)) {
      return title;
    }

    return title ? `${title} ${t('kpi_chart.versus')} ${groupingTitle}` : groupingTitle;
  }, '');

const getDataCount = ({ categories = [], data = {} }) => {
  // data should be same length for all categories so grab first
  const key = categories[0]?.key;

  return data[key]?.entityData?.length ?? 0;
};

// gets all colors represented on graph (instead of all potential colors from theme, etc)
// we repeat colors if we run out and there are still categories, but probably want to make sure that doesn't happen
const getLegendColors = ({ categories = [], colors = [], negativeColor, type }) =>
  [
    ...Array.from(
      {
        length: type === ChartType.MIRROR_COLUMN ? categories.length - 1 : categories.length,
      },
      (_, i) => colors[i % colors.length],
    ),
    negativeColor,
  ].filter(Boolean);

const StyledChartBorder = styled(ChartBorder)`
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SeriesChart = styled.div`
  min-width: ${({ type }) =>
    type === KpiCategorySeriesType.ENTITY && `${ENTITY_CHART_MIN_WIDTH}px`};
`;

const NoDataError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1
  color: ${({ theme }) => theme.analyze.majorLoss.donutChart.errorMessageColor}
  font-weight: bold;
`;
// TODO: add support for more than two chart types, just basing this off of graph 1 requirements doc for now
export const DashboardKpiChart = withTheme(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    (
      {
        chartDef,
        data,
        error,
        entityType,
        filterOptions,
        inlineTitle,
        maxSelectedEntities,
        namespace,
        onSelected,
        parentEntity,
        selectable,
        selectedEntities,
        theme,
        onChange,
        isLoading,
        xAxisTitle,
        xAxisLabelVisible,
        showToggle,
        staticChartTitle,
        height,
        showTitle,
        className,
        handleFilterBarChange,
        hideBorder,
        noDataError,
        showTimeAggr,
        timeAggregation,
        timeAggrOptions,
        onTimeAggrChange,
        onCurrentTypeChange,
        yAxisLabelFormatter,
        onRetry,
      },
      ref,
    ) => {
      // hooks
      const { ready, t } = useTranslation([namespace], {
        useSuspense: false,
      });
      const [chartApi, setChartApi] = useState({});
      const [chartState, setChartState] = useState();
      const [currentType, setCurrentType] = useState();
      const [maxEntities, setMaxEntities] = useState(FilterDefs.MAX_ENTITIES);

      const { charts } = theme;

      // handlers
      const handleUpdated = useCallback((api) => setChartApi(api), [setChartApi]);

      const handleToggle = useCallback(
        (toggleType) => setCurrentType(toggleType),
        [setCurrentType],
      );

      const handleSelected = useCallback(
        (selection = []) => onSelected(selection.map(({ id, name }) => ({ id, name }))),
        [onSelected],
      );

      // effects
      // reset state when chart def changes
      useEffect(() => {
        setCurrentType(null);
      }, [chartDef]);

      useEffect(() => {
        onCurrentTypeChange(currentType ?? chartState?.defaultType);
      }, [chartState, currentType, onCurrentTypeChange]);

      // set number of entities and selection to display on the chart
      useEffect(() => {
        const { selectEntities, setMaxEntities } = chartApi || {};

        setMaxEntities && setMaxEntities(maxEntities);

        selectEntities && selectEntities(selectedEntities);
      }, [chartApi, currentType, maxEntities, selectedEntities]);

      // get chart state from props
      useEffect(() => {
        const { alternate, default: _default } = chartDef || {};
        const { categories, seriesType, type } = _default;

        // TODO: Added custom color themes extensibility per chart
        // Custom Theme only for Actual vs. Expected Production chart
        const colors =
          type === ChartType.COLUMN &&
          categories.length === 2 &&
          categories.some(({ key }) => key === KpiCategoryDefs.PRODUCTION_ACTUAL) &&
          categories.some(({ key }) => key === KpiCategoryDefs.PRODUCTION_EXPECTED_CONTRACT)
            ? [globalColors.magenta3, globalColors.mint2]
            : charts.colors;
        const baseType = getBaseChartType(type);
        const defaultTheme = charts[baseType] || {};
        const { color, negativeColor } = defaultTheme;
        const legendColors = getLegendColors({
          categories,
          negativeColor,
          type,
          colors: color ? [color] : colors,
        });

        let state = {
          [KpiGraphType.DEFAULT]: {
            categories,
            legendColors,
            seriesType,
            title: t(getTitle({ categories, t })),
          },
          defaultType: { id: KpiGraphType.DEFAULT, type },
        };

        if (alternate) {
          const {
            categories: alternateCategories,
            selected,
            seriesType: alternateSeriesType,
            type: alternateType,
          } = alternate || {};
          const toggleTypes = alternateType && [
            state.defaultType,
            { id: KpiGraphType.ALTERNATE, type: alternateType },
          ];

          const alternateBaseType = getBaseChartType(alternateType);
          const alternateTheme = charts[alternateBaseType] || {};
          const { color: alternateColor, negativeColor: alternateNegativeColor } = alternateTheme;
          const alternateLegendColors = getLegendColors({
            categories: alternateCategories,
            colors: alternateColor ? [alternateColor] : colors,
            negativeColor: alternateNegativeColor,
            type: alternateType,
          });

          state = {
            ...state,
            [KpiGraphType.ALTERNATE]: {
              categories: alternateCategories,
              legendColors: alternateLegendColors,
              seriesType: alternateSeriesType,
              title: t(getTitle({ categories: alternateCategories, t })),
            },
            defaultType: toggleTypes[selected ? 1 : 0],
            toggleTypes,
          };
        }

        setChartState(state);
      }, [chartDef, charts, t]);

      // error handler
      const { noData, noDataTitle, noDataDescription } = useMemo(
        () => handleKpiError({ error, t }),
        [error, t],
      );

      if (!(chartState && data && ready)) {
        return null;
      }

      const { defaultType, toggleTypes } = chartState;
      const { id, type } = currentType || defaultType;
      const { categories, legendColors, seriesType, title } = chartState[id];
      const legend = categories.map(({ key: name }, i) => ({
        color: legendColors[i],
        name: `dynamic.kpi_chart.legends.${name}`,
      }));

      const dataCount = getDataCount({ categories, data });

      const chartStats = {
        chartDef,
        dataCount,
        handleSortChange: onChange,
        handleMaxEntities: setMaxEntities,
        maxEntitiesSelect: seriesType === KpiCategorySeriesType.ENTITY,
        showSort: seriesType === KpiCategorySeriesType.ENTITY,
        showDateAggregation: showTimeAggr && seriesType === KpiCategorySeriesType.TIME_SERIES,
        timeAggregation,
        timeAggrOptions,
        onTimeAggrChange,
      };

      return (
        <StyledChartBorder ref={ref} height={height} className={className} hideBorder={hideBorder}>
          {(!inlineTitle || handleFilterBarChange) && (
            <DashboardTitle data-testid={AnalyzeLocators.ANALYZE_KPI_CHART_TITLE}>
              {staticChartTitle ? staticChartTitle : title}
            </DashboardTitle>
          )}
          {showTitle && (
            <DashboardChartTitle
              chartTypes={toggleTypes}
              currentType={defaultType}
              onToggle={handleToggle}
              title={staticChartTitle ? staticChartTitle : inlineTitle ? title : null}
              onChange={onChange}
              seriesType={seriesType}
              showToggle={showToggle}
            >
              {handleFilterBarChange && (
                <DashboardKpiFilter
                  onChange={handleFilterBarChange}
                  filterOptions={filterOptions}
                />
              )}
            </DashboardChartTitle>
          )}
          <DataLoader
            isLoading={isLoading}
            type={type}
            noData={noData}
            noDataTitle={noDataTitle}
            noDataDescription={noDataDescription}
            renderCondition={!error}
            onRetry={onRetry}
          >
            <LegendContainer>
              <DashboardChartLegend legend={legend} />
              <DashboardChartStats {...chartStats} />
            </LegendContainer>
            {noDataError ? (
              <NoDataError className="body-4">{noDataError}</NoDataError>
            ) : (
              <SeriesChart
                data-testid={AnalyzeLocators.ANALYZE_KPI_CHART_DATA_WRAP}
                type={seriesType}
              >
                <MemoizedKpiChart
                  categories={categories}
                  colors={legendColors}
                  data={data}
                  entityType={entityType}
                  height={height}
                  isLoading={isLoading}
                  maxEntities={maxEntities}
                  maxSelectedEntities={maxSelectedEntities}
                  namespace={namespace}
                  onSelected={handleSelected}
                  onUpdated={handleUpdated}
                  parentEntity={parentEntity}
                  selectable={selectable}
                  seriesType={seriesType}
                  type={type}
                  xAxisTitle={xAxisTitle}
                  xAxisLabelVisible={xAxisLabelVisible}
                  timeAggr={timeAggregation}
                  yAxisLabelFormatter={yAxisLabelFormatter}
                />
              </SeriesChart>
            )}
          </DataLoader>
        </StyledChartBorder>
      );
    },
  ),
);

DashboardKpiChart.propTypes = {
  entity: entityPropType,
  height: PropTypes.number,
  kpiCategory: PropTypes.oneOf(Object.values(KpiCategoryDefs)),
  kpiGraphType: PropTypes.oneOf(Object.values(KpiGraphType)),
  kpiSeriesType: PropTypes.oneOf(Object.values(KpiCategorySeriesType)),
  onEntityClick: PropTypes.func,
  onEntityHover: PropTypes.func,
  onKpiCategoryChange: PropTypes.func,
  onKpiGraphTypeChange: PropTypes.func,
  onKpiSeriesTypeChange: PropTypes.func,
  timeAggr: PropTypes.oneOf(Object.values(TimeAggr)),
  theme: PropTypes.object,
  chartDef: chartDefPropTypes.isRequired,
  data: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  entityType: PropTypes.oneOf(Object.values(EntityType)).isRequired,
  // convenience for placing the title on same or separate line so it is flush with neighboring charts
  inlineTitle: PropTypes.bool,
  maxSelectedEntities: PropTypes.number,
  namespace: PropTypes.string.isRequired,
  onSelected: PropTypes.func,
  // might need to make this specific to default/alternate graphs
  selectable: PropTypes.bool,
  parentEntity: entityPropType,
  selectedEntities: PropTypes.arrayOf(entityPropType),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  handleFilterBarChange: PropTypes.func,
  xAxisTitle: PropTypes.string,
  xAxisLabelVisible: PropTypes.bool,
  showTitle: PropTypes.bool,
  noDataError: PropTypes.string,
  showTimeAggr: PropTypes.bool,
  timeAggregation: PropTypes.string,
  timeAggrOptions: PropTypes.arrayOf(PropTypes.object),
  onTimeAggrChange: PropTypes.func,
  onCurrentTypeChange: PropTypes.func,
  onRetry: PropTypes.func,
  yAxisLabelFormatter: PropTypes.func,
};

DashboardKpiChart.defaultProps = {
  data: {},
  inlineTitle: true,
  maxSelectedEntities: MAX_SELECTED_ENTITIES,
  onSelected: () => {},
  selectable: true,
  parentEntity: undefined,
  selectedEntities: [],
  onChange: () => {},
  isLoading: false,
  showCategoryFilter: false,
  onCategoryChange: () => {},
  xAxisTitle: undefined,
  xAxisLabelVisible: undefined,
  showTitle: true,
  noDataError: null,
  showTimeAggr: false,
  timeAggregation: PropTypes.oneOf([TimeAggr.DAILY, TimeAggr.WEEKLY, TimeAggr.MONTHLY]),
  timeAggrOptions: [],
  onTimeAggrChange: () => {},
  onCurrentTypeChange: () => {},
  onRetry: () => {},
  yAxisLabelFormatter: undefined,
};

DashboardKpiChart.displayName = 'DashboardKpiChart';
