import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';

import { ChartType } from '@ge/components/charts';
import { Icons } from '@ge/components/icon';
import { DateTimeFormats, EntityType, Placeholders, TimeAggr } from '@ge/models/constants';
import { formatNumber, mergeOptionsRight, roundNumber } from '@ge/util';

import { KpiCategoryDataType, KpiCategorySeriesType } from '.';

dayjs.extend(utc);
dayjs.extend(isoWeek);

const CHART_HEIGHT = 260;
const DATE_LABEL_FORMAT = 'MMM DD';

const getEntityTooltipStyle = ({ theme }) => ({
  entityIcon: `
    display: inline-block;
    fill: ${theme.charts.tooltipColor};
    vertical-align: middle;
  `,
  entityName: `
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  `,
  container: `
    align-items: start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  `,
  kpiColor: `
    border-radius: 4px;
    height: 12px;
    width: 12px;
  `,
  kpiName: `
    color: ${theme.kpiChart.tooltipSecondaryColor};
    font-size: 10px;
    font-weight: 500;
    margin-top: 10px;
  `,
  kpiUnits: `
    color: ${theme.kpiChart.tooltipSecondaryColor};
    font-size: 11px;
  `,
  kpiValue: `
    font-size: 16px;
  `,
  parentEntityName: `
    font-size: 11px;
  `,
});

const getTimeSeriesTooltipStyle = ({ theme }) => ({
  container: `
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  `,
  date: `
    font-size: 10px;
    font-weight: 500;
    color: ${theme.kpiChart.tooltipSecondaryColor};
  `,
  kpi: `
    font-size: 12px;
  `,
  kpiUnits: `
    color: ${theme.kpiChart.tooltipSecondaryColor};
    font-size: 11px;
  `,
});

const getColorSymbolStyle = (color) => `
  background-color: ${color};
  border-radius: 2px;
  display: inline-block;
  height: 12px;
  margin-right: 3px;
  width: 12px;
`;

const getEntityIcon = (entityType) =>
  ({
    [EntityType.REGION]: Icons.SITE,
    [EntityType.SITES]: Icons.SITE,
    [EntityType.SITE]: Icons.SITE,
    [EntityType.TURBINE]: Icons.TURBINE,
  }[entityType]);

const getDateLabelFn =
  (timeAggr) =>
  ({ value }) => {
    switch (timeAggr) {
      case TimeAggr.MONTHLY:
        return dayjs.utc(value).format('MMM');
      case TimeAggr.WEEKLY:
        return `WK ${String(value).slice(-2)}`;
      default:
        return dayjs(value).utc().format(DATE_LABEL_FORMAT);
    }
  };

const getPointOptions =
  ({ theme, type, seriesIndex }) =>
  (y) => {
    if (type !== ChartType.MIRROR_COLUMN || y >= 0) return {};

    const colors = theme.charts[type].negativeColors[seriesIndex % 2];
    const { color, patternColor, selectedColor, selectedPatternColor } = colors ?? {};

    const d =
      seriesIndex % 2 === 0
        ? 'M 0 10 L 10 0 M 9 11 L 11 9 M -1 1 L 1 -1'
        : 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11';
    const strokeWidth = 3;

    return {
      color: {
        pattern: {
          backgroundColor: color,
          path: {
            d,
            stroke: patternColor,
            strokeWidth,
          },
          width: 10,
          height: 10,
        },
      },
      states: {
        select: {
          color: {
            pattern: {
              backgroundColor: selectedColor,
              path: {
                d,
                stroke: selectedPatternColor,
                strokeWidth,
              },
              width: 10,
              height: 10,
            },
          },
        },
      },
    };
  };

// get options for entity charts (turbines, sites, etc)
const getEntityChartOptions = ({
  categories,
  colors,
  data: _data,
  entityType,
  getKpiCategory,
  getUnits,
  parentEntity,
  theme,
  type,
}) => {
  const selectable = true;
  const xAxisVisible = true;
  const xAxisLabelVisible = false;
  const xAxisTickLength = 0;

  const { name: parentEntityName } = parentEntity || {};
  const {
    entityIcon: entityIconStyle,
    entityName,
    container,
    kpiName: kpiNameStyle,
    kpiUnits,
    kpiValue,
    parentEntityName: parentEntityNameStyle,
  } = getEntityTooltipStyle({ theme });
  const entityIcon = getEntityIcon(entityType);

  // intermediate step for building data that will be used for final processing of series
  const workingSeries = categories.reduce((_series, { key }, seriesIndex) => {
    const { entityData: categoryData = [], units: _units } = _data[key] || {};

    const pointOptions = getPointOptions({ theme, type, seriesIndex });

    const data = categoryData.map(({ entity: { id, name }, value: y }) => ({
      id,
      name,
      y,
      ...pointOptions(y),
    }));

    const kpiName = getKpiCategory(key);
    const units = getUnits(_units);

    return [..._series, { data, kpiName, units }];
  }, []);

  // we need to get value for same entity across charts/series in tooltip so create a lookup here for convenience
  const valueLookup = workingSeries.reduce(
    (lookup, { data, kpiName, units }) => ({
      ...data.reduce(
        (dataLookup, { id, y: value }) => ({
          ...lookup,
          ...dataLookup,
          [id]: {
            ...lookup[id],
            [kpiName]: {
              units,
              value,
            },
          },
        }),
        {},
      ),
    }),
    {},
  );

  const series = workingSeries.map(({ data }) => {
    const tooltipPointFormatter = ({ id, name }) => {
      return `
        <div style='${container}'>
          <span style='${entityName}'>
            <svg height='12px' rotate='0' style='${entityIconStyle}' viewBox='0 0 20 20' width='12px'>
              <path d='${entityIcon}' />
            </svg>
            ${name}
          </span>
          ${
            (parentEntityName &&
              `<span style='${parentEntityNameStyle}'>${parentEntityName}</span>`) ||
            ''
          }
          ${workingSeries.reduce((kpis, { kpiName }, i) => {
            const { units, value } = valueLookup[id][kpiName];
            const multi = type !== ChartType.MIRROR_COLUMN && workingSeries.length > 1;
            const valueLabel =
              value || value === 0
                ? // do we need to round differently based on the kpi?
                  `${formatNumber(roundNumber(value, 1))}${
                    units === '%' ? '%' : `<span style='${kpiUnits}'>${units}</span>`
                  }`
                : Placeholders.DOUBLE_DASH;

            return (
              kpis +
              `
                <span style='${kpiNameStyle}'>${kpiName}</span>
                <span style='${kpiValue}'>
                  ${(multi && `<span style='${getColorSymbolStyle(colors[i])}'></span>`) || ''}
                  ${valueLabel}
                </span>
              `
            );
          }, '')}
        </div>
      `;
    };

    return {
      data,
      tooltipPointFormatter,
    };
  });

  return {
    selectable,
    series,
    xAxisVisible,
    xAxisLabelVisible,
    xAxisTickLength,
  };
};

/**
 *  Getting time series data points from data and adding points for missing dates
 *
 * @param {*} data
 *
 * @returns Time series data points(eg: [[x1, y1], [x2, y2], [x3, y3], ...])
 */
const getTimeSeriesData = (data, pointInterval) => {
  if (data && data.length > 2) {
    const pointStart = new Date(data[0].date).getTime();
    const pointEnd = new Date(data[data.length - 1].date).getTime();
    const days = (pointEnd - pointStart) / pointInterval + 1;
    if (data.length < days) {
      for (let i = 0; i < days; i++) {
        const key = new Date(pointStart + i * pointInterval).toJSON().split('T')[0];
        let point = data.find((o) => o.date === key);
        if (!point) {
          data.splice(i, 0, { date: key, value: null });
        }
      }
    }
  }
  return data?.map(({ date, value }) => [new Date(date).getTime(), value]) ?? [];
};

const getTimeSeriesDataForWeeklyAggr = (data) => {
  return (
    data
      ?.map(({ date, value }) => ({ name: date, y: value }))
      .sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      }) ?? []
  );
};

const getTimeSeriesDataForMonthlyAggr = (data) => {
  let hydratedData = [];
  const getDateAndYear = (date) => {
    const _date = dayjs(date);
    return [_date.year(), _date.month()];
  };

  if (data && data.length > 2) {
    const mappedData = data.reduce((acc, { date, value }) => {
      const _date = dayjs(date);
      acc[`${_date.year()} - ${_date.month()}`] = { date, value };
      return acc;
    }, {});
    const [firstYear, firstMonth] = getDateAndYear(data[0].date);
    const [lastYear, lastMonth] = getDateAndYear(data[data.length - 1].date);
    for (let y = firstYear; y <= lastYear; y++) {
      for (let m = y === firstYear ? firstMonth : 0; m <= (y === lastYear ? lastMonth : 11); m++) {
        const _data = mappedData[`${y} - ${m}`];
        hydratedData.push(_data || { date: `${y}-${m + 1}-01`, value: null });
      }
    }
  }
  return (
    (hydratedData.length ? hydratedData : data)?.map(({ date, value }) => [
      new Date(date).getTime(),
      value,
    ]) ?? []
  );
};

// get options for time series charts
const getTimeSeriesChartOptions = ({
  categories,
  data: _data,
  getUnits,
  theme,
  timeAggr,
  entityType,
}) => {
  const selectable =
    categories?.length === 1 &&
    (entityType === EntityType.REGION || entityType === EntityType.SITES);
  const xAxisLabelFormatter = getDateLabelFn(timeAggr);
  const xAxisType = timeAggr === TimeAggr.WEEKLY ? 'category' : 'datetime';
  const xAxisLabelVisible = true;
  const oneDay = 24 * 36e5;
  const pointInterval = timeAggr === TimeAggr.WEEKLY ? oneDay * 7 : oneDay;
  const xAxisMaxPadding = 0.07;

  const series = categories.reduce((_series, { key: category }) => {
    const { timeSeriesData: categoryData, units: _units } = _data[category] || {};
    let data = [];
    if (timeAggr === TimeAggr.MONTHLY) {
      data = getTimeSeriesDataForMonthlyAggr(categoryData);
    } else if (timeAggr === TimeAggr.WEEKLY) {
      data = getTimeSeriesDataForWeeklyAggr(categoryData);
    } else {
      data = getTimeSeriesData(categoryData || [], pointInterval);
    }
    const { container, date, kpi, kpiUnits } = getTimeSeriesTooltipStyle({ theme });
    const units = getUnits(_units);
    const unitsLabel = units === '%' ? '%' : `<span style='${kpiUnits}'>${units}</span>`;

    if (timeAggr === TimeAggr.WEEKLY) {
      return [
        ..._series,
        {
          data,
          tooltipPointFormatter: ({ name, y }) => `
          <div style='${container}'>
            <span style='${date}'>${xAxisLabelFormatter({ value: name })}</span>
            <span style='${kpi}'>${formatNumber(roundNumber(y, 1))}${unitsLabel}</span>
          </div>
        `,
        },
      ];
    } else {
      const startDate = dayjs((categoryData || [{}])[0]?.date)
        .utc()
        .format(DateTimeFormats.YEAR_MONTH_DAY);
      const match = startDate ? startDate.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) : false;
      let pointStart;

      if (match) {
        const [, year, month, day] = match;

        // if local tz should use this:
        // Math.round(new Date(Number(year), Number(month) - 1, Number(day)).getTime());
        // also need to make sure x-axis and point formatters are using same tz
        pointStart = Date.UTC(Number(year), Number(month) - 1, Number(day));
      }

      return [
        ..._series,
        {
          data,
          pointStart,
          ...(timeAggr === TimeAggr.MONTHLY ? { pointIntervalUnit: 'month' } : { pointInterval }),
          tooltipPointFormatter: ({ x, y }) => `
          <div style='${container}'>
            <span style='${date}'>${xAxisLabelFormatter({ value: x })}</span>
            <span style='${kpi}'>${formatNumber(roundNumber(y, 1))}${unitsLabel}</span>
          </div>
        `,
        },
      ];
    }
  }, []);

  return {
    selectable,
    series,
    xAxisLabelFormatter,
    xAxisType,
    xAxisLabelVisible,
    xAxisMaxPadding,
  };
};

const getInnerChartHeight = (height, innerHeightPercent) => {
  return height * innerHeightPercent;
};

// define plot options by data type
const getPlotOptions = ({ type, height, innerHeightPercent, chartAddProps }) => {
  const plotOptions = {
    height: height ? getInnerChartHeight(height, innerHeightPercent) : CHART_HEIGHT,
  };

  if (chartAddProps?.min && chartAddProps?.max) {
    return {
      ...plotOptions,
      min: chartAddProps.min,
      max: chartAddProps.max,
    };
  }

  switch (type) {
    case KpiCategoryDataType.PERCENT:
      return {
        ...plotOptions,
      };

    default:
      return plotOptions;
  }
};

export const kpiChartOptionsFactory = ({
  categories = [],
  colors,
  data = {},
  entityType,
  getKpiCategory,
  getUnits,
  height,
  innerHeightPercent = 0.63,
  maxEntities,
  noDataLabel,
  seriesType,
  parentEntity,
  theme,
  type,
  timeAggr,
  chartAddProps,
}) => {
  const getOptions = {
    [KpiCategorySeriesType.ENTITY]: getEntityChartOptions,
    [KpiCategorySeriesType.TIME_SERIES]: getTimeSeriesChartOptions,
  }[seriesType];

  // get base options then massage based on chart type
  const chartOptions = getOptions({
    categories,
    colors,
    data,
    entityType,
    getKpiCategory,
    getUnits,
    maxEntities,
    parentEntity,
    theme,
    type,
    timeAggr,
  });

  switch (type) {
    case ChartType.MIRROR_COLUMN: {
      const { series, ...options } = chartOptions;

      // lower chart
      // peel off props for whole mirror chart
      const {
        height: _height,
        selectable,
        ...lowerChart
      } = mergeOptionsRight(getPlotOptions(categories.slice(-1)[0]), {
        ...options,
        noDataLabel,
        series: series.slice(-1),
        yAxisLabelFormatter: ({ value }) => Math.abs(value),
      });

      // upper chart
      const upperChart = mergeOptionsRight(getPlotOptions(categories[0]), {
        // height is for the whole mirror chart, so don't need to grab it here
        height: null,
        noDataLabel,
        series: series.slice(0, series.length - 1),
      });

      return {
        height: _height,
        lowerChart,
        selectable,
        series,
        upperChart,
      };
    }

    default: {
      return mergeOptionsRight(
        getPlotOptions({ ...categories[0], height, innerHeightPercent, chartAddProps }),
        chartOptions,
      );
    }
  }
};
