import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { useManualAdjustmentContext } from '@ge/feat-analyze/context/manual-adjustment-context';
import { AvailabilityKpi } from '@ge/feat-analyze/models/manual-adjustment-defs';

import {
  Cell,
  ChartHeader,
  SelectionColumn,
  EntityCell,
  Timeline,
  EndingColumn,
  AssetKPI,
  EntityTitle,
  TimelineSlot,
  TimelineLabel,
  TimelineGrid,
} from './site-panel-chart-style';

const SitePanelChartHeader = ({ data }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { autoWidth, selectedAssets, setSelectedAssets } = useManualAdjustmentContext();

  const labels = useMemo(() => {
    const timeline = [];

    for (let i = 0; i <= 23; i++) {
      timeline.push(
        i.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) + ':00',
      );
    }

    return timeline;
  }, []);

  const handleSelectAll = useCallback(
    (checked) => {
      if (!checked || !data?.length) {
        setSelectedAssets([]);
      } else {
        setSelectedAssets(data.map((item) => item.asset?.id));
      }
    },
    [data, setSelectedAssets],
  );

  const getCheckedState = useCallback(() => {
    if (!selectedAssets?.length || !data?.length) {
      return CheckedState.UNCHECKED;
    }
    if (selectedAssets.length > 0 && selectedAssets.length < data.length) {
      return CheckedState.INDETERMINATE;
    }
    return CheckedState.CHECKED;
  }, [selectedAssets, data]);

  if (!ready) return null;

  return (
    <ChartHeader>
      <Cell>
        <SelectionColumn>
          <Checkbox checkState={getCheckedState()} label={''} onChange={handleSelectAll} />
        </SelectionColumn>
        <EntityCell>
          <EntityTitle>{t('assets', 'Assets')}</EntityTitle>
        </EntityCell>
        <Timeline>
          {labels.map((label) => (
            <TimelineSlot key={label}>
              <TimelineLabel>{label}</TimelineLabel>
              <TimelineGrid />
            </TimelineSlot>
          ))}
        </Timeline>
      </Cell>
      <EndingColumn autoWidth={autoWidth}>
        {Object.values(AvailabilityKpi).map(({ id }) => (
          <AssetKPI key={id}></AssetKPI>
        ))}
      </EndingColumn>
    </ChartHeader>
  );
};

SitePanelChartHeader.propTypes = {
  data: PropTypes.array,
};

SitePanelChartHeader.defaultProps = {
  data: [],
};

export default SitePanelChartHeader;
