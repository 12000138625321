import { getLoadable } from '@ge/shared/util';

// Define the available pages for lazy loading.
export const loadables = {
  labor: getLoadable(() => import('./labor')),
  casesTasks: getLoadable(() => import('./cases-tasks')),
  overview: getLoadable(() => import('./overview')),
  reports: getLoadable(() => import('./reports')),
  service: getLoadable(() => import('./service')),
};

// @index('./**/*.jsx', f => `export * from '${f.path}';`)
export * from './cases-tasks';
export * from './labor';
export * from './overview';
export * from './reports';
export * from './service';
// @endindex
