import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icons, Icon } from '@ge/components/icon';

import { useManualAdjustmentContext } from '../../context/manual-adjustment-context';

const SelectedAssets = styled.div`
  padding-left: 6px;
  font-size: 11px;
  font-family: 'Museo Sans';
  line-height: 13px;
  color: ${(props) => props.theme.analyze.manualAdjustment.kpiColor};
  letter-spacing: 0.5px;
  font-weight: 700;
  text-transform: uppercase;
`;

const BulkEditSummary = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 7px;
  background-color: ${(props) => props.theme.analyze.manualAdjustment.chart.rowBackgroundColor};
  .left {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const ColumnSeparator = styled.span`
  border-right: 1px solid ${(props) => props.theme.analyze.manualAdjustment.kpiColor};
  margin-left: 10px;
  height: 20px;
`;

const AddEventButton = styled(Button)`
  margin-left: 18px;
`;

const CloseButton = styled.button.attrs(() => ({ type: 'button' }))`
  margin: 0px 6px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  color: props.theme.dialog.iconColor,
}))`
  display: block;
`;

export const EventBulkEditSummary = ({ site }) => {
  const { t, ready } = useTranslation(['analyze.manual-adjustment'], {
    useSuspense: false,
  });

  const { selectedAssets, setSelectedAssets, selectedEvent, setSelectedEvent } =
    useManualAdjustmentContext();

  if (!ready) return null;

  return (
    selectedAssets.length > 0 && (
      <BulkEditSummary>
        <div className="left">
          <SelectedAssets>
            {t('event_details_dialog.assets_selected', { count: selectedAssets.length })}
          </SelectedAssets>
          <ColumnSeparator />
          <AddEventButton
            primary
            onClick={() => setSelectedEvent({ site, event: {}, bulk: true })}
            disabled={selectedEvent?.bulk}
          >
            {t('event_details_dialog.add_event_button', 'Add Event')}
          </AddEventButton>
        </div>
        <CloseButton onClick={() => setSelectedAssets([])}>
          <CloseIcon icon={Icons.CLOSE} size={10} />
        </CloseButton>
      </BulkEditSummary>
    )
  );
};

EventBulkEditSummary.propTypes = {
  site: PropTypes.instanceOf(Object).isRequired,
};
