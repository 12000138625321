import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { ConditionalRender } from '@ge/components/conditional-render';
import { ConditionalWrapper } from '@ge/components/conditional-wrapper';
import { Icon, Icons } from '@ge/components/icon';
import { Menu, MenuTable, placements, MenuLoader } from '@ge/components/menu';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useBulkScheduleTask } from '@ge/feat-manage/data-hooks/use-bulk-schedule-task';
import { useStackMenu } from '@ge/feat-manage/data-hooks/use-stack-menu';
import { TaskStatus } from '@ge/models';
import { Capability, ErpSource, Tenant } from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { useEditTask } from '@ge/shared/data-hooks';
import { useLogger } from '@ge/shared/hooks';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

import { CommonActions } from '../../actions/actions-common-actions';
import { SendToBacklogDialog } from '../../actions/actions-send-to-backlog-dialog';
import { SnoozeRescheduleDialog } from '../../actions/actions-snooze-reschedule-dialog';
import { AddTaskPanel } from '../../panels/add-task-panel';

import { BundleTasksLists } from './bundle-tasks-lists';

dayjs.extend(isSameOrAfter);

const Tr = styled.tr`
  cursor: pointer;
  border-bottom-color: ${({ theme }) => theme.manage.cards.trBorderBottom};
  .bundleText {
    color: ${globalColors.slate5};
  }
  &.scheduled {
    background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    color: ${({ theme }) => theme.manage.cards.scheduledTextColor};
    &.striped {
      background: repeating-linear-gradient(
        145deg,
        #8694a1,
        #8694a1 0.1px,
        #85cddb 2px,
        #85cddb 6px
      );
      &:hover {
        background: repeating-linear-gradient(
          145deg,
          #8694a1,
          #8694a1 0.1px,
          #85cddb 2px,
          #85cddb 6px
        );
      }
      > td {
        border-left: 0px;
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.manage.cards.scheduledColor};
    }
    > td .type {
      color: ${({ theme }) => theme.manage.cards.scheduledTypeTextColor};
    }
    > td {
      border-left: 2px solid ${({ theme }) => theme.manage.cards.scheduledColor};
    }
    &:last-child {
      border-bottom: 1px;
    }
  }
  &.unscheduled {
    background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    color: ${({ theme }) => theme.manage.cards.unscheduledTextColor};
    &.striped {
      background: repeating-linear-gradient(
        145deg,
        #8694a1,
        #8694a1 0.3px,
        #5e6d7b 1px,
        #5e6d7b 6px
      );
      &:hover {
        background: repeating-linear-gradient(
          145deg,
          #8694a1,
          #8694a1 0.3px,
          #5e6d7b 1px,
          #5e6d7b 6px
        );
      }
      > td {
        border-left: 0px !important;
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.manage.cards.unscheduledColor};
    }
    > td .type {
      color: ${({ theme }) => theme.manage.cards.unscheduledTypeTextColor};
    }
    > td {
      border-left: 2px solid ${({ theme }) => theme.manage.cards.unscheduledColor};
    }
    &:last-child {
      border-bottom: 1px;
    }
  }
  &.completed {
    background-color: ${({ theme }) => theme.manage.cards.completedColor};
    color: ${({ theme }) => theme.manage.cards.completedTextColor};
    &:hover {
      background-color: ${({ theme }) => theme.manage.cards.completedColor};
    }
    > td .type {
      color: ${({ theme }) => theme.manage.cards.completedTypeTextColor};
    }
    > td {
      border-left: 2px solid ${({ theme }) => theme.manage.cards.completedColor};
    }
    &:last-child {
      border-bottom: 1px;
    }
  }
`;

const Th = styled.th`
  font-size: 12px !important;
  border-bottom: 2px solid ${({ theme }) => theme.manage.cards.thBorderBottom};
  color: ${({ theme }) => theme.tabs.textColor};
`;

const ScrollingFlex = styled.div`
  max-height: 350px;
  display: flex;
  .tableBdrCollapse {
    border-collapse: collapse;
  }
  .bundleDuration,
  .bundleDuration:hover {
    background: ${globalColors.slate5};
    color: ${globalColors.grey1};
    border-left: 5px solid ${globalColors.slate5};
  }
  .bundleCount td {
    border-top: 3px solid ${globalColors.slate5};
  }
  .bundleTaskTr {
    color: ${globalColors.grey1};
    font-size: 12px;
    .secondaryTask {
      border-left: 5px solid ${globalColors.slate5};
    }
    &.unscheduled {
      color: ${({ theme }) => theme.manage.cards.unscheduledTextColor};
    }
    &.scheduled {
      .bundleText {
        color: ${globalColors.slate3};
      }
    }
    &.completed {
      .bundleText {
        color: ${globalColors.slate3};
      }
    }
  }
  tr.bundleCount,
  tr.bundleCount:hover {
    background: ${globalColors.slate1};
    padding: 0px;
  }
  th {
    position: sticky;
    top: 0;
    background: ${globalColors.grey};
    z-index: ${elevations.P1};
  }
  td.bundleSection {
    padding: 0px;
  }
  tfoot,
  tfoot tr {
    position: sticky;
    left: 0;
    height: 41px;
    bottom: 0;
    background: ${globalColors.slate1};
    z-index: ${elevations.P1};
  }
`;

const AddTaskButton = styled.div`
  width: 100px;
  color: ${globalColors.white};
  font-size: 11px;
  letter-spacing: 0.5px;
  postion: relative;
  float: right;
  .addTask {
    position: relative;
    top: 2px;
    text-transform: uppercase;
  }
`;

const AddIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.ADD,
  color: globalColors.slate4,
  hover: props.theme.entityDetails.iconColor,
}))`
  margin-right: 0px;
  padding-right: 2px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const getCrewName = (task, crewsSg, t) => {
  let crewName = t('tasks:form.unassigned', 'Unassigned');
  if (task?.crewIds && task?.crewIds[0] !== null) {
    crewsSg?.filter((crew) => {
      if (task?.crewIds.indexOf(crew?._id) > -1) {
        crewName = crew?.name;
        return crewName;
      }
    });
  }
  return crewName;
};

export const StackMenu = forwardRef(({ anchorEl, tasks, open, onClose, date }, ref) => {
  const { t } = useTranslation(['manage.planning', 'general', 'tasks']);
  const [isBulkScheduleError, setIsBulkScheduleError] = useState(false);
  const logger = useLogger();
  const [hoverActions, setHoverActions] = useState({ hover: false, key: null });
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showRescheduleDialog, setShowRescheduleDialog] = useState(false);
  const [showSnoozeDialog, setShowSnoozeDialog] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const tenantId = sessionStorage.getItem('tenantId');
  const erpSource = tenantId.startsWith(Tenant.DTE) ? ErpSource.DTE_MAXIMO : ErpSource.RACES;
  const erpTitle =
    erpSource === ErpSource.DTE_MAXIMO
      ? t('manage.planning:wo', 'WO')
      : t('manage.planning:sr', 'SR');

  const addTasks = useMemo(
    () => tasks?.filter((aTask) => aTask?.status !== TaskStatus.UNSCHEDULED),
    [tasks],
  );

  const isCompletedTask =
    addTasks?.length && addTasks?.filter((task) => task?.status !== TaskStatus.COMPLETE);

  const [showNewTaskModal, setShowNewTaskModal] = useState(false);

  const newFieldTask = useCallback(() => {
    setShowNewTaskModal(true);
  }, [setShowNewTaskModal]);

  const closeModal = useCallback(() => {
    setShowNewTaskModal(false);
  }, [setShowNewTaskModal]);

  const saveTask = useCallback(() => {
    setShowNewTaskModal(false);
  }, []);

  const { crewsSg } = useStoreState((state) => state.workers);

  const { updatedTasks, erpStatusByTaskId, erpStatusLoading } = useStackMenu({ open, tasks, date });

  let getUpdatedTasks = useMemo(() => {
    return updatedTasks?.map((allTasks) => {
      return {
        ...allTasks,
        crewName: getCrewName(allTasks?.mainTask, crewsSg, t),
        primaryTaskDetails: allTasks?.mainTask,
        hasSecondaryTasks: allTasks?.secondaryTasks?.length ? true : false,
      };
    });
  }, [updatedTasks, crewsSg, t]);

  const [showConfirmMessage, setShowConfirmMessage] = useState({
    isOpen: false,
    key: null,
    status: null,
    type: null,
  });

  const [spinLoader, setSpinLoader] = useState(false);

  const { bulkSchedule, isLoading } = useBulkScheduleTask({
    onSuccess: () => {
      setIsBulkScheduleError(false);
      handleOnClose();
    },
    onError: (e) => {
      setIsBulkScheduleError(true);
      logger.error('Error scheduling', e);
    },
  });

  const siteId = tasks[0]?.asset?.site?.id ?? tasks[0]?.site?.id;

  const handleSaveAll = useCallback(
    (data) => {
      bulkSchedule(data);
    },
    [bulkSchedule],
  );

  const handleCancel = useCallback(() => {
    setShowConfirmMessage({ isOpen: false, key: null, status: null, type: null });
    onClose();
  }, [onClose]);

  const { edit } = useEditTask({
    onSuccess: () => setDiscardTask(),
  });

  const setDiscardTask = () => {
    setSelectedTasks([]);
    setShowConfirmMessage({ isOpen: false, key: null, status: null, type: null });
    setSpinLoader(false);
    handleOnClose();
  };

  const handleBundledList = useCallback(
    (selectedIds, taskIds, status, isSchedule, isBacklog, bundledTaskIds) => {
      const payload =
        selectedIds && selectedIds.length && isSchedule
          ? {
              bundledTasks: selectedIds,
              isBundled: true,
            }
          : !selectedIds || (!selectedIds.length && !isSchedule)
          ? {
              scheduleDate: null,
              status: 'unscheduled',
              bundledTasks: selectedIds,
              isBundled: selectedIds && selectedIds.length ? true : false,
            }
          : isSchedule
          ? {
              bundledTasks: null,
              isBundled: false,
            }
          : {
              scheduleDate: null,
              status: 'unscheduled',
              bundledTasks: null,
              isBundled: false,
            };
      const payloadUnschedule =
        selectedIds && selectedIds.length
          ? {
              dueDate: null,
              bundledTasks: selectedIds,
              isBundled: true,
            }
          : {
              bundledTasks: null,
              isBundled: false,
              dueDate: null,
            };
      const id = taskIds;
      if (isBacklog) {
        status && status != 'unscheduled'
          ? (payload.crewIds = null)
          : (payloadUnschedule.crewIds = null);
      }
      status && status != 'unscheduled'
        ? edit({ id, task: payload, bundledTaskIds })
        : edit({ id, task: payloadUnschedule, bundledTaskIds });
    },
    [edit],
  );

  const handleYes = useCallback(() => {
    const { key } = showConfirmMessage;
    const taskIds = key.split();
    const filterTaskList = getUpdatedTasks?.filter((val) =>
      val?.primaryTasks.some((val2) => key === val2.id),
    );
    if (filterTaskList && filterTaskList.length) {
      taskIds.forEach((taskId) => {
        let status = tasks.find((task) => task.id === taskId);
        handleBundledList(
          [],
          taskId,
          status?.status,
          false,
          true,
          status?.bundledTasks && status.bundledTasks,
        );
      });
    } else {
      getUpdatedTasks?.map((task) => {
        if (task?.hasSecondaryTasks) {
          task?.primaryTasks?.map((taskPrimary) => {
            const allSelectedTask = taskPrimary?.bundledTasks?.filter((val) => {
              return !taskIds.find((val2) => {
                if (val instanceof Object) {
                  return val.id === val2;
                } else {
                  return val === val2;
                }
              });
            });
            const allTaskIds = allSelectedTask?.map((res) =>
              res instanceof Object ? res.id : res,
            );
            handleBundledList(
              allTaskIds,
              taskPrimary?.id,
              taskPrimary?.status,
              true,
              false,
              taskIds,
            );
          });
        }
      });
    }
    setSpinLoader(true);
  }, [showConfirmMessage, getUpdatedTasks, tasks, handleBundledList]);

  const handleYesMulti = useCallback(() => {
    const uniqIds = [...new Set(selectedTasks)];
    const getPrimary = tasks.filter((task) => {
      if (uniqIds.includes(task?.id)) {
        let index = uniqIds.findIndex((val) => val === task?.id);
        uniqIds.splice(index, 1);
        return task;
      }
    });

    if (uniqIds && uniqIds.length > 0) {
      getUpdatedTasks?.map((task) => {
        if (task?.hasSecondaryTasks) {
          task?.primaryTasks?.map((taskPrimary) => {
            const allSelectedTask = taskPrimary?.bundledTasks?.filter((val) => {
              return !uniqIds.find((val2) => {
                if (val instanceof Object) {
                  return val.id === val2;
                } else {
                  return val === val2;
                }
              });
            });
            const allTaskIds = allSelectedTask?.map((res) =>
              res instanceof Object ? res.id : res,
            );
            handleBundledList(
              allTaskIds,
              taskPrimary?.id,
              taskPrimary?.status,
              true,
              false,
              uniqIds,
            );
          });
        }
      });
    }
    if (getPrimary && getPrimary.length) {
      getPrimary.forEach((taskId) => {
        handleBundledList(
          [],
          taskId.id,
          taskId?.status,
          false,
          true,
          taskId?.bundledTasks && taskId.bundledTasks,
        );
      });
    }

    setSpinLoader(true);
  }, [getUpdatedTasks, handleBundledList, selectedTasks, tasks]);

  const handleOnClose = () => {
    setShowRescheduleDialog(false);
    setShowSnoozeDialog(false);
    setHoverActions({ hover: false, key: null });
    setSelectedTask([]);
    setSelectedTasks([]);
    setIsBulkScheduleError(false);
    onClose();
  };

  const destroyStack = () => {
    setHoverActions({ hover: false, key: null });
  };

  const toggleCheckbox = useCallback(
    (e, id) => {
      killEventPropagation(e);
      return selectedTasks.includes(id)
        ? setSelectedTasks((prevselectedTask) => prevselectedTask.filter((el) => el !== id))
        : setSelectedTasks((prevselectedTask) => [...prevselectedTask, id]);
    },
    [selectedTasks],
  );

  const getAllTaskSelected = (selectedTask) => {
    let filteredTasks = [];
    getUpdatedTasks?.map((allTaskList) => {
      allTaskList?.allTasks?.filter(function (obj) {
        if (selectedTask && selectedTask.indexOf(obj.id) > -1) {
          filteredTasks.push(obj);
        }
      });
    });
    return filteredTasks;
  };

  // on checkbox click
  const onCheckBoxClick = (e) => {
    killEventPropagation(e);
    setIsCheckAll(!isCheckAll);
    let combinedAllTask = [];
    getUpdatedTasks?.map((allTaskList) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      var allTasks = [...allTaskList?.primaryTasks, ...allTaskList?.secondaryTasks];
      allTasks?.map((task) => {
        combinedAllTask.push(task);
      });
      const taskList = combinedAllTask?.map(
        (task) => task?.status !== TaskStatus.COMPLETE && task.id,
      );
      setSelectedTasks(taskList.filter(Boolean));
    });
    if (isCheckAll) {
      setSelectedTasks([]);
    }
  };

  const onActionClick = (event, task, taskType) => {
    event.stopPropagation();
    setHoverActions({ hover: false, key: null });
    setSelectedTask(task);
    if (taskType == 'schedule') {
      setShowRescheduleDialog(true);
    }
    if (taskType == 'snooze') {
      setShowSnoozeDialog(true);
    }
  };

  const onCommonActionClick = (event, task, taskType) => {
    event.stopPropagation();
    setSelectedTask(task);
    if (taskType == 'schedule') {
      setShowRescheduleDialog(true);
    }
    if (taskType == 'snooze') {
      setShowSnoozeDialog(true);
    }
  };

  const handleConfirm = useCallback(() => {
    showConfirmMessage.isMultiSelect ? handleYesMulti() : handleYes();
  }, [handleYes, handleYesMulti, showConfirmMessage.isMultiSelect]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        container={ref}
        offset={2}
        placement={placements.BOTTOM_END}
        width={600}
      >
        <ConditionalRender shouldRender={Boolean(anchorEl) && !tasks}>
          <MenuLoader />
        </ConditionalRender>
        <ConditionalRender shouldRender={Boolean(anchorEl) && !!tasks}>
          <MenuTable className="table">
            <ConditionalWrapper
              condition={true}
              wrapper={(children) => (
                <ScrollingFlex>
                  <ScrollingContainer relative>{children}</ScrollingContainer>
                </ScrollingFlex>
              )}
            >
              <table className="tableBdrCollapse">
                <thead>
                  <Tr>
                    <Th width="25">
                      <CheckboxWrapper onMouseDown={(e) => onCheckBoxClick(e)}>
                        <Checkbox
                          checkState={isCheckAll ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                          onChange={(e) => onCheckBoxClick(e)}
                        />
                      </CheckboxWrapper>
                    </Th>
                    <Th>{t('manage.planning:site_asset', 'Site / Asset')}</Th>
                    <Th>{t('general:task', 'Task')}</Th>
                    <Th>{t('manage.planning:due', 'Due')}</Th>
                    <Th>{t('manage.planning:scheduled', 'Scheduled')}</Th>
                    <Th>{t('manage.planning:duration', 'Duration')}</Th>
                    <AuthRender
                      capability={Capability.WORK_ORDERS}
                      description="ERP Status"
                      edit
                      siteIds={[siteId]}
                    >
                      <Th>{erpTitle}</Th>
                    </AuthRender>
                    <Th saria-label="actions" />
                  </Tr>
                </thead>
                <tbody>
                  <>
                    {/* Common actions */}
                    {selectedTasks.length > 1 ? (
                      <AuthRender
                        capability={Capability.WORK_PLAN}
                        description="Snooze"
                        edit
                        siteIds={[siteId]}
                      >
                        <CommonActions
                          tasks={selectedTasks.length ? getAllTaskSelected(selectedTasks) : tasks}
                          siteId={siteId}
                          setShowConfirmMessage={setShowConfirmMessage}
                          onCommonActionClick={onCommonActionClick}
                        ></CommonActions>
                      </AuthRender>
                    ) : null}

                    {getUpdatedTasks.map((task, index) => (
                      <React.Fragment key={task.id}>
                        <BundleTasksLists
                          key={index}
                          bTaskList={task?.primaryTasks}
                          allTasksDuration={task?.allTasksDuration}
                          siteId={siteId}
                          isBundled={false}
                          erpStatusByTaskId={erpStatusByTaskId}
                          erpStatusLoading={erpStatusLoading}
                          setShowConfirmMessage={setShowConfirmMessage}
                          destroyStack={destroyStack}
                          hoverActions={hoverActions}
                          setHoverActions={setHoverActions}
                          selectedTasks={selectedTasks}
                          toggleCheckbox={toggleCheckbox}
                          primaryTaskDetails={task?.primaryTaskDetails}
                          crewName={task?.crewName}
                          crewIds={task?.primaryTaskDetails?.crewIds}
                          hasSecondaryTasks={task?.hasSecondaryTasks}
                          onActionClick={onActionClick}
                          onClose={onClose}
                        />
                        <BundleTasksLists
                          key={`${task.id}_${index}`}
                          bTaskList={task?.secondaryTasks}
                          allTasksDuration={task?.allTasksDuration}
                          siteId={siteId}
                          isBundled={true}
                          erpStatusByTaskId={erpStatusByTaskId}
                          erpStatusLoading={erpStatusLoading}
                          setShowConfirmMessage={setShowConfirmMessage}
                          destroyStack={destroyStack}
                          hoverActions={hoverActions}
                          setHoverActions={setHoverActions}
                          selectedTasks={selectedTasks}
                          toggleCheckbox={toggleCheckbox}
                          crewIds={task?.primaryTaskDetails?.crewIds}
                          primaryTaskDetails={task?.primaryTaskDetails}
                          crewName={task?.crewName}
                          hasSecondaryTasks={task?.hasSecondaryTasks}
                          isLastBundledTask={getUpdatedTasks?.length - 1 === index}
                          onActionClick={onActionClick}
                          onClose={onClose}
                        />
                      </React.Fragment>
                    ))}
                  </>
                </tbody>
                {/* +ADD TASK BUTTON */}
                <AuthRender capability={Capability.WORK_PLAN} edit siteIds={[siteId]}>
                  {addTasks?.length > 0 && dayjs(date).isSameOrAfter(new Date(), 'date') && (
                    <tfoot>
                      <Tr>
                        <td colSpan="8" valign="center">
                          <AuthRender
                            capability={Capability.FIELD_TASKS}
                            create
                            description="Add task button"
                            siteLevel={false}
                          >
                            {isCompletedTask?.length ? (
                              <AddTaskButton onClick={newFieldTask}>
                                <AddIcon />{' '}
                                <span className="addTask">{t('add_task_bundle', 'ADD TASK')}</span>
                              </AddTaskButton>
                            ) : null}
                          </AuthRender>
                        </td>
                      </Tr>
                    </tfoot>
                  )}
                </AuthRender>
              </table>
            </ConditionalWrapper>
          </MenuTable>
        </ConditionalRender>
      </Menu>

      {/* SEND TO BACKLOG DIALOG */}
      {showConfirmMessage.isOpen && (
        <SendToBacklogDialog
          showConfirmMessage={showConfirmMessage}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          showLoader={spinLoader}
        ></SendToBacklogDialog>
      )}

      {/* SNOOZE / SCHEDULE-UNSCHEDULE */}
      {(showRescheduleDialog || showSnoozeDialog) && (
        <SnoozeRescheduleDialog
          tasks={[selectedTask]}
          allTasks={getAllTaskSelected(selectedTasks)}
          scheduleType={showRescheduleDialog ? 'Reschedule' : 'eligible'}
          onSaveAll={handleSaveAll}
          isError={isBulkScheduleError}
          onClose={handleOnClose}
          isLoading={isLoading}
        ></SnoozeRescheduleDialog>
      )}

      {showNewTaskModal && (
        <AddTaskPanel
          onClose={closeModal}
          onConfirm={saveTask}
          tasks={addTasks}
          destoryStack={onClose}
        />
      )}
    </>
  );
});

StackMenu.displayName = 'StackMenu';

StackMenu.propTypes = {
  open: PropTypes.bool,
  tasks: PropTypes.instanceOf(Object),
  anchorEl: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  onClose: PropTypes.func,
  date: PropTypes.instanceOf(Object),
};

StackMenu.defaultProps = {
  open: false,
  title: null,
  tasks: null,
  anchorEl: null,
  onClose: () => null,
  date: null,
};
