import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

import { fetchCaseMetricsForAssetIds } from '../../monitor/services';

/**
 * Get case Metrics data for the asset
 *
 * @param assetId assetId to get the case metrics data
 * @returns
 */
const useAssetCaseMetrics = (assetId) => {
  const { data: _queryData, ...queryRest } = useQuery(
    [QueryKey.ASSETS_CASE_METRICS, assetId],
    () => fetchCaseMetricsForAssetIds([assetId]),
    {
      enabled: Boolean(assetId),
      ...Config.EXECUTE_ONCE,
      refetchInterval: Config.REFRESH.realTime,
    },
  );
  return { data: _queryData?.data?.metricsData, queryRest };
};

export default useAssetCaseMetrics;
