import * as request from './api';

const CMN_BASE_URL = process.env.REACT_APP_DIGITAL_WIND_FARM_CMN_API;

export const getMonitorSiteAlerts = async (siteId, assetType) =>
  request.get('/cmn/monitor/alerts', {
    baseURL: CMN_BASE_URL,
    params: {
      siteId,
      assetType,
    },
  });

export const getMonitorAssetAlerts = async (assetId, assetType) =>
  request.get('/cmn/monitor/alerts', {
    baseURL: CMN_BASE_URL,
    params: {
      assetId,
      assetType,
    },
  });

export const getMonitorAlertsForAssets = async (data) => {
  return request.post('/cmn/monitor/asset-alerts', data, {
    baseURL: CMN_BASE_URL,
  });
};

const getFormDataAndHeaderForAlert = (data) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const { attachments, actionable, informational } = data ?? {};
  const formData = new FormData();
  formData.append('payload', JSON.stringify(data));
  if (actionable?.attachments?.length > 0) {
    for (const attachment of actionable.attachments) {
      formData.append('attachment', attachment.file, attachment.id);
    }
  }
  if (informational?.attachments?.length > 0) {
    for (const attachment of informational.attachments) {
      formData.append('attachment', attachment.file, attachment.id);
    }
  }
  if (attachments?.length > 0) {
    for (const attachment of attachments) {
      formData.append('attachment', attachment.file, attachment.id);
    }
  }
  return { headers, body: formData };
};

export const postMonitorAlert = async (data) => {
  const { headers, body } = getFormDataAndHeaderForAlert(data);
  return request.post('/cmn/monitor/alerts', body, {
    baseURL: CMN_BASE_URL,
    headers,
  });
};

export const editMonitorAlert = async (data) => {
  const { headers, body } = getFormDataAndHeaderForAlert(data);
  return request.patch('/cmn/monitor/alerts', body, {
    baseURL: CMN_BASE_URL,
    headers,
  });
};

export const getMonitorAlertsHistory = async (assetId, days = 7) =>
  request.get('/cmn/monitor/alert-history', {
    baseURL: CMN_BASE_URL,
    params: {
      assetId,
      days,
      status: 2,
    },
  });
