import { useStoreState } from 'easy-peasy';
import intersection from 'ramda/src/intersection';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { PermissionScope, QueryKey, AuthStrategy } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useAuthLogger } from '@ge/shared/hooks';
import { getUserAccessToken, isAuthenticated } from '@ge/shared/services/auth';
import { getIacToken } from '@ge/shared/services/iac';

const SCOPE_ALL = 'ALL';

const IMPLIED_MAP = {
  [PermissionScope.ADMIN]: [
    PermissionScope.ADMIN,
    PermissionScope.CREATE,
    PermissionScope.DELETE,
    PermissionScope.EDIT,
    PermissionScope.VIEW,
  ],
  [PermissionScope.CREATE]: [PermissionScope.VIEW, PermissionScope.CREATE],
  [PermissionScope.DELETE]: [PermissionScope.DELETE, PermissionScope.VIEW],
  [PermissionScope.EDIT]: [PermissionScope.VIEW, PermissionScope.EDIT],
  [PermissionScope.VIEW]: [PermissionScope.VIEW],
};

const hasScope = (userScopes, requiredScopes) =>
  Boolean(
    userScopes?.some((userScope) =>
      Boolean(intersection(IMPLIED_MAP[userScope], requiredScopes ?? []).length),
    ),
  );

export const useAuth = () => {
  const { metadata } = useStoreState((state) => state.auth);
  const sitesForView = useStoreState((state) => state.sites.sitesForView);

  const log = useAuthLogger();
  const accessToken = getUserAccessToken();

  const { data, isLoading } = useQuery(
    [QueryKey.PERMISSIONS],
    () => Promise.resolve(getIacToken(accessToken)),
    {
      ...Config.EXECUTE_ONCE,
      ...(Object.keys(metadata).length && { initialData: { metadata } }),
      enabled: !!accessToken,
      select: ({ metadata: permissions }) => {
        const viewSiteIds = sitesForView?.map(({ id }) => id) ?? [];
        let viewPermissions = permissions;

        // get permissions for view selector if filter applied
        if (viewSiteIds.length) {
          // TODO: clean this up to avoid nested reducers
          viewPermissions = Object.entries(permissions ?? {}).reduce(
            (viewMetadata, [segmentOffering, permissionSet]) => {
              const viewPermissionSet = Object.entries(permissionSet).reduce(
                (_viewPermissionSet, [key, sitesById]) => {
                  const viewSites = Object.entries(sitesById).reduce(
                    (viewSitesById, [siteId, scopes]) => {
                      if (siteId === SCOPE_ALL || viewSiteIds.includes(siteId)) {
                        viewSitesById[siteId] = scopes;
                      }

                      return viewSitesById;
                    },
                    {},
                  );

                  if (Object.keys(viewSites).length) {
                    _viewPermissionSet[key] = viewSites;
                  }

                  return _viewPermissionSet;
                },
                {},
              );

              if (Object.keys(viewPermissionSet).length) {
                viewMetadata[segmentOffering] = viewPermissionSet;
              }

              return viewMetadata;
            },
            {},
          );
        }

        return {
          permissions,
          viewPermissions,
        };
      },
    },
  );

  const isAuthorized = useCallback(
    ({ capabilities, enforceView, siteIds = [], authStrategy }) => {
      if (!capabilities?.length) {
        return false;
      }

      const permissions = enforceView ? data?.viewPermissions : data?.permissions;
      const isLoaded = Boolean(Object.keys(permissions ?? {}).length);

      if (!isLoaded) {
        return false;
      }
      const isAllCapabilitiesRequired = authStrategy === AuthStrategy.ALL;
      return capabilities.reduce(
        (_isAuthorized, { capability, scopes }) => {
          if (!(capability && scopes.length)) {
            return _isAuthorized;
          }

          const { segmentOffering, value } = capability;
          const bySite = permissions[segmentOffering]?.[value] ?? {};

          let userScopes = siteIds.filter(Boolean).length
            ? Object.entries(bySite).reduce(
                (_userScopes, [siteId, scopes]) =>
                  siteId === SCOPE_ALL || siteIds.includes(siteId)
                    ? [..._userScopes, ...scopes]
                    : _userScopes,
                [],
              )
            : Object.values(bySite ?? []).flat();

          // might be overkill, but de-dupe scopes before authorizing
          userScopes = Array.from(new Set(userScopes));

          return isAllCapabilitiesRequired
            ? _isAuthorized && hasScope(userScopes, scopes)
            : _isAuthorized || hasScope(userScopes, scopes);
        },
        isAllCapabilitiesRequired ? true : false,
      );
    },
    [data],
  );

  // same as isAuthorized, but logs auth failures with some extra context provided by caller
  const audit = useCallback(
    ({ capabilities, description, enforceView, siteIds, source, type, authStrategy }) => {
      if (isAuthorized({ capabilities, enforceView, siteIds, authStrategy })) {
        return true;
      }

      const permissions = enforceView ? data?.viewPermissions : data?.permissions;
      const isLoaded = Boolean(Object.keys(permissions ?? {}).length);

      // suppress failed auth logging due to permissions still loading
      if (isLoaded) {
        log({
          capabilities,
          description,
          entity: siteIds?.join(', '),
          permissions,
          source,
          type,
        });
      }

      return false;
    },
    [data, isAuthorized, log],
  );

  const permissions = data?.permissions ?? {};

  return {
    audit,
    isAuthenticated,
    isAuthorized,
    permissions,
    isLoading,
  };
};
