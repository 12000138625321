import PropTypes from 'prop-types';
import React from 'react';

import { Loader } from '@ge/components/loader';

import Annotator from './components/annotator/annotator';

export const ImageAnnotator = ({
  currentImage,
  annotationTools,
  selectedAnnotationId,
  isLoading,
  handleModalOpen = () => {},
  onRegionSelect = () => {},
  onAnnotationAdd = () => {},
  onRulerCreate = () => {},
  updateSelectedAnnotationId = () => {},
  SliderValueContext,
  selectedVideo,
}) => {
  if (!currentImage) return <Loader />;

  return (
    <Annotator
      labelImages
      selectedImageSrc={currentImage?.src}
      currentImage={currentImage}
      selectedTools={annotationTools}
      onModalOpen={handleModalOpen}
      onRegionSelect={onRegionSelect}
      onAnnotationAdd={onAnnotationAdd}
      onRulerCreate={onRulerCreate}
      selectedAnnotationId={selectedAnnotationId}
      updateSelectedAnnotationId={updateSelectedAnnotationId}
      isLoading={isLoading}
      SliderValueContext={SliderValueContext}
      selectedVideo={selectedVideo}
    />
  );
};

ImageAnnotator.propTypes = {
  currentImage: PropTypes.any,
  annotationTools: PropTypes.any,
  handleModalOpen: PropTypes.func,
  onRegionSelect: PropTypes.func,
  onAnnotationAdd: PropTypes.func,
  onRulerCreate: PropTypes.func,
  selectedAnnotationId: PropTypes.number,
  updateSelectedAnnotationId: PropTypes.func,
  isLoading: PropTypes.bool,
  SliderValueContext: PropTypes.any,
  selectedVideo: PropTypes.instanceOf(Object),
};
