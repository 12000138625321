import { PropTypes } from 'prop-types';
import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { Icon, Icons } from '@ge/components/icon';
import { Menu } from '@ge/components/menu';
import { Tooltip } from '@ge/components/tooltip';
import { TasksColumns } from '@ge/feat-manage/models/tasks-table-cols';
import { CasePriority, TableFilterTypes, TableNumberFilterType } from '@ge/models/constants';
import { AnomaliesColumns } from '@ge/shared/models/table-col-defs';
import { killEventPropagation } from '@ge/shared/util/general';
import { globalColors } from '@ge/tokens/colors';
import { elevations } from '@ge/tokens/elevations';

import { placements } from '../../menu';

import { FilterCheckboxes } from './filter-checkboxes';
import { FilterDatepicker } from './filter-datepicker';
import { FilterNumber } from './filter-number';
import { FilterProgressiveDropdowns } from './filter-progressive-dropdowns';
import { FilterProgressiveMultiSelect } from './filter-progressive-multi-select';
import { FilterSearch } from './filter-search';

const casePriorities = [null].concat(Object.values(CasePriority));

const StyledSVG = styled.svg`
  #filter-stroke {
    stroke-width: 0;
  }
  #filter-fill {
    fill: ${(props) =>
      props.active
        ? props.isMenuOpen
          ? props.theme.filterMenu.icon.hover
          : props.theme.filterMenu.icon.active
        : props.theme.filterMenu.icon.default};
  }
  #filter-active-fill {
    fill: ${(props) =>
      props.active ? props.theme.filterMenu.icon.active : props.theme.filterMenu.icon.default};
  }

  &:hover {
    #filter-stroke {
      stroke-width: 1px;
    }
    #filter-fill {
      fill: ${(props) => props.theme.filterMenu.icon.hover};
      stroke: ${(props) => props.theme.filterMenu.icon.hoverStroke};
    }
    #filter-active-fill {
      fill: ${(props) => props.theme.filterMenu.icon.hover};
      stroke: ${(props) => props.theme.filterMenu.icon.hoverStroke};
    }
  }
`;

const defaultIcon = (
  <g id="filter-stroke" stroke="none" fill="none" fillRule="evenodd">
    <g id="filter-fill" transform="translate(-52.000000, -50.000000)">
      <g
        id="UI/Glyphs/Grid-Controls/Column-Control/Hover---D"
        transform="translate(58.000000, 55.000000) scale(-1, 1) rotate(-270.000000) translate(-58.000000, -55.000000) translate(52.000000, 49.000000)"
      >
        <path
          d="M10.5,1 L10.5,6.58578644 C10.5,7.41421356 9.82842712,8.08578644 9,8.08578644 C8.60217527,8.08578644 8.2206444,7.92775118 7.93933983,7.64644661 L2.35355339,2.06066017 C1.76776695,1.47487373 1.76776695,0.525126266 2.35355339,-0.0606601718 C2.63485796,-0.341964739 3.01638883,-0.5 3.41421356,-0.5 L9,-0.5 C9.82842712,-0.5 10.5,0.171572875 10.5,1 Z"
          id="Path-14"
          transform="translate(5.500000, 4.500000) rotate(-270.000000) translate(-5.500000, -4.500000) "
        />
      </g>
    </g>
  </g>
);

const isActiveIcon = (
  <g id="filter-stroke" fill="none" fillRule="evenodd">
    <g id="filter-active-fill" transform="translate(-691.000000, -333.000000)">
      <g id="Group-8" transform="translate(691.014706, 333.000000)">
        <g id="UI/Glyphs/Search" transform="translate(0.000000, 3.000000)">
          <path
            d="M9.06617647,0.933823529 L9.06617647,6.62682045 C9.06617647,7.1791052 8.61846122,7.62682045 8.06617647,7.62682045 C7.79842975,7.62682045 7.54186588,7.51944955 7.3539273,7.32874717 L1.74343758,1.63575025 C1.35577416,1.24238589 1.36039568,0.609237781 1.75376004,0.221574359 C1.94085941,0.0371865684 2.19299866,-0.0661764706 2.45568676,-0.0661764706 L8.06617647,-0.0661764706 C8.61846122,-0.0661764706 9.06617647,0.38153878 9.06617647,0.933823529 Z"
            id="Path-14"
            transform="translate(4.566176, 4.500000) rotate(-270.000000) translate(-4.566176, -4.500000) "
          ></path>
        </g>
        <path
          d="M8.0916731,0.905625 C9.10718174,2.3625 10.9823536,5.0625 10.9823536,5.0625 L10.9823536,8.4375 C10.9823536,8.746875 11.2085808,9 11.4850807,9 L12.4905348,9 C12.7670347,9 12.9932619,8.746875 12.9932619,8.4375 L12.9932619,5.0625 C12.9932619,5.0625 14.8634065,2.3625 15.8789151,0.905625 C16.1353059,0.534375 15.8990242,0 15.4817608,0 L8.48882747,0 C8.07156401,0 7.8352823,0.534375 8.0916731,0.905625 Z"
          id="Path"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </g>
);

const FilterIcon = ({ active, onClick, isMenuOpen }) => (
  <StyledSVG
    active={active}
    isMenuOpen={isMenuOpen}
    onClick={onClick}
    width="16px"
    height="12px"
    viewBox="0 0 16 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    {active ? isActiveIcon : defaultIcon}
  </StyledSVG>
);

FilterIcon.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
};

FilterIcon.defaultProps = {
  onClick: () => null,
  active: false,
  isMenuOpen: false,
};

const StyledMenu = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
`;

const StyledFilterMenu = styled.div`
  padding: 10px 6px;
  max-width: 188px;
  > .title {
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.theme.filterMenu.titleColor};
    font-weight: 700;
    margin-bottom: 7px;

    > button {
      line-height: 8px;
      padding: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    max-height: 200px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.scrollbar.trackBackground};
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scrollbar.thumbBackground};
      border-radius: 2.5px;
    }

    li {
      margin-bottom: 8px;

      span {
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props.theme.filterMenu.textColor};
      }
    }
  }

  .filter-input {
    position: relative;

    svg {
      position: absolute;
      top: 6px;
      right: 10px;
    }
  }

  .footer {
    padding-top: 6px;
    border-top: solid 1px ${(props) => props.theme.filterMenu.borderColor};
    display: flex;
    justify-content: space-between;
  }
`;

/* to show tooltip as mouseEvents will not be triggered for disabled buttons
  or button with curser style not-enabled */
const TooltipButton = styled.span`
  font-size: 13px;
  text-align: center;
  padding: 2px 14px;
  border-radius: 2px;
  max-width: 100%;
  svg {
    vertical-align: inherit;
  }
  background: ${(props) => props.theme.button.primary.backgroundColor};
  border: ${(props) => props.theme.button.primary.border};
  color: ${(props) => props.theme.button.primary.textColor};
  text-shadow: ${(props) => props.theme.button.primary.textShadow};
  font-weight: ${(props) => props.theme.button.primary.fontWeight};
  opacity: 0.7;
  cursor: not-allowed;
`;

const FilterMenu = ({
  columnDef,
  filterValues,
  onApply,
  onChange,
  onMenuClose,
  translateFn,
  value,
  rowsSelected = false,
  columnKey,
}) => {
  const { t } = useTranslation(['general'], { useSuspense: false });

  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setActive] = useState(false);
  // leave default state undefined so filters can define their own default value in props
  const [filterState, setFilterState] = useState();
  const textInput = useRef(null);
  const [showClear, setShowClear] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [checkboxfilterValues, setCheckboxfilterValues] = useState([]);
  const { filterType, className, enableShowAll, maxFilteredValues, filterTypeDefs } = columnDef;
  const [isFutureDateBlockRequired, setisFutureDateBlockRequired] = useState(false);
  const [numberFilterType, setNumberFilterType] = useState();
  useEffect(() => {
    if (value) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [value, setActive]);

  useEffect(() => {
    if (filterValues) {
      setCheckboxfilterValues(filterValues);
    }
  }, [filterValues, setCheckboxfilterValues]);

  useEffect(() => {
    if (filterType === TableFilterTypes.SEARCH) {
      filterValues?.length ? setShowApply(false) : setShowApply(true);
    } else if (filterType === TableFilterTypes.PROGRESSIVE_MULTI_SELECT) {
      filterState?.length && filterTypeDefs?.fields?.every((_, i) => !!filterState[i])
        ? setShowApply(false)
        : setShowApply(true);
    } else {
      filterState?.length ? setShowApply(false) : setShowApply(true);
    }
  }, [filterState, filterType, filterValues, filterTypeDefs, setShowApply]);

  const setFilterDefaultState = () => {
    if (filterType === TableFilterTypes.PROGRESSIVE_MULTI_SELECT) {
      setCheckboxfilterValues(filterValues);
      setFilterState(value);
    } else if (filterType === TableFilterTypes.CHECKBOXES) {
      if (value) {
        const checked = filterValues?.sort(function(x, y) {
          if (value?.includes(x) === value?.includes(y)) {
            return x
              .toString()
              .toLowerCase()
              .localeCompare(y.toString().toLowerCase());
          }
          return value?.includes(x) ? -1 : 1;
        });
        setCheckboxfilterValues(checked);
        setFilterState(value);
      } else {
        if (columnKey === AnomaliesColumns.PRIORITY) {
          filterValues?.sort(function(x, y) {
            const _x = casePriorities.indexOf(x);
            const _y = casePriorities.indexOf(y);
            return _x < _y ? 1 : _x > _y ? -1 : 0;
          });
        }
        setCheckboxfilterValues(filterValues);
        setFilterState(null);
      }
    } else if (filterType === TableFilterTypes.DATE) {
      setFilterState(value);
      if (
        columnKey === TasksColumns.COMPLETED_DATE_TIME ||
        columnKey === TasksColumns.CREATED_DATE
      ) {
        setisFutureDateBlockRequired(true);
      }
    } else if (filterType === TableFilterTypes.NUMBER) {
      if (Array.isArray(value)) {
        if (value[1] === 'Infinity') {
          setNumberFilterType(TableNumberFilterType.GREATER_THAN);
        } else if (value[0] === '-Infinity') {
          setNumberFilterType(TableNumberFilterType.LESS_THAN);
        } else if (!isNaN(value[0]) && !isNaN(value[1])) {
          setNumberFilterType(TableNumberFilterType.RANGE);
        } else {
          setNumberFilterType(TableNumberFilterType.VALUE);
        }
        setFilterState(value);
      } else {
        setNumberFilterType(TableNumberFilterType.VALUE);
        setFilterState([value]);
      }
    } else if (filterType === TableFilterTypes.PROGRESSIVE_MULTI_OPTION) {
      setFilterState(value);
    }
  };

  const showMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setActive(true);
    setShowClear(false);
    setShowApply(true);
    setFilterDefaultState();
    if (value?.length) {
      setShowClear(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setFilterState(value);
    setActive(value != null);
    onMenuClose();
    onChange(null);
  };

  const applyValueFn = () => {
    switch (filterType) {
      case TableFilterTypes.SEARCH:
        return filterValues;
      case TableFilterTypes.CHECKBOXES:
        if (
          filterState?.length !== checkboxfilterValues.length ||
          columnKey === TasksColumns.FLAG
        ) {
          return filterState;
        }
        return null;
      case TableFilterTypes.PROGRESSIVE_DROPDOWNS:
        return [
          filterState?.primary?.value,
          filterState?.secondary?.value,
          filterState?.tertiary?.value,
        ].filter(Boolean);
      case TableFilterTypes.NUMBER:
        return filterState?.length
          ? filterState.sort((a, b) => (a + '').localeCompare(b, undefined, { numeric: true }))
          : [];
      default:
        return filterState;
    }
  };

  const handleApply = () => {
    setActive(filterState != null);
    handleMenuClose();
    onApply(applyValueFn());
    onChange(null);
  };

  const handleChange = useCallback(
    (_value) => {
      setFilterState(_value);
      // for some filters we want to bubble up current value
      // before user hits apply button so that filter options can update
      // such as search results
      if (filterType === TableFilterTypes.SEARCH) {
        onChange(_value);
      }
      setShowClear(false);
    },
    // can look into this but because of depth of callback passing
    // it's difficult to enforce that onChange has been wrapped in
    // callback by all parents so leaving it out of deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterType, setFilterState, onChange],
  );

  const handleClear = () => {
    setActive(false);
    setFilterState();
    if (textInput?.current?.value) {
      textInput.current.value = '';
    }
    if (textInput?.current?.reset) {
      textInput.current.reset();
    }
    onApply(null);
    setShowClear(true);
    setShowApply(true);
  };

  const MenuTitle = useCallback(
    (col) => {
      if (col.filterTypeDefs?.titles) {
        const titles = col.filterTypeDefs.titles.map((label) =>
          translateFn(label.a11yKey || '', label.a11yDefault),
        );
        return titles.join(' / ');
      }
      const titles = col.labels.map((label) => translateFn(label.a11yKey || '', label.a11yDefault));
      return titles.join(' / ');
    },
    [translateFn],
  );

  // Removes duplicate / undefined entries and builds drop down options from visible table component data.
  const options = useMemo(
    () =>
      Array.from(
        filterValues
          .reduce((a, { component1: c1, component2: c2, component3: c3 }) => {
            if (c1) a.set(c1, { key: 'primaryOptions', option: { value: c1, label: c1 } });
            if (c2) a.set(c2, { key: 'secondaryOptions', option: { value: c2, label: c2 } });
            if (c3) a.set(c3, { key: 'tertiaryOptions', option: { value: c3, label: c3 } });
            return a;
          }, new Map())
          .values(),
      )?.reduce(
        (acc, { key, option }) => {
          acc[key].push(option);
          return acc;
        },
        { primaryOptions: [], secondaryOptions: [], tertiaryOptions: [] },
      ),
    [filterValues],
  );

  const renderFilterContent = () => {
    switch (filterType) {
      case TableFilterTypes.CHECKBOXES:
        return (
          <FilterCheckboxes
            onChange={handleChange}
            filterValues={checkboxfilterValues}
            value={filterState}
            className={className}
            ref={textInput}
            enableShowAll={enableShowAll}
            maxFilteredValues={maxFilteredValues}
            columnKey={columnKey}
            filterTypeDefs={filterTypeDefs}
          />
        );
      case TableFilterTypes.DATE:
        return (
          <FilterDatepicker
            onChange={handleChange}
            value={filterState}
            isMaxDateRequired={isFutureDateBlockRequired}
          />
        );
      case TableFilterTypes.NUMBER:
        return (
          <FilterNumber
            onChange={handleChange}
            value={filterState}
            inputRef={textInput}
            type={numberFilterType}
          />
        );
      case TableFilterTypes.SEARCH:
        return (
          <FilterSearch
            active={isActive}
            onChange={handleChange}
            filterValues={filterValues}
            value={filterState || ''}
          />
        );
      case TableFilterTypes.PROGRESSIVE_MULTI_SELECT:
        return (
          <FilterProgressiveMultiSelect
            onChange={handleChange}
            values={filterState}
            filterValues={filterValues}
            filterTypeDefs={filterTypeDefs}
          />
        );
      case TableFilterTypes.PROGRESSIVE_DROPDOWNS:
        return (
          <FilterProgressiveDropdowns
            onChange={handleChange}
            filterValues={filterValues}
            values={filterState}
            options={options}
          />
        );
      default:
        return null;
    }
  };

  const renderApplyBtn = () => {
    if (rowsSelected) {
      return (
        <Tooltip
          title={t(
            'filter_menu.apply_error',
            'In order to change and apply filters, first remove any current case selections',
          )}
          zIndex={elevations.P25}
          placement={placements.RIGHT_START}
        >
          <TooltipButton onClick={(e) => killEventPropagation(e)}>
            {t('apply', 'Apply')}
          </TooltipButton>
        </Tooltip>
      );
    } else if (showApply) {
      return (
        <Button primary onClick={handleApply} disabled>
          {t('apply', 'Apply')}
        </Button>
      );
    } else {
      return (
        <Button primary onClick={handleApply}>
          {t('apply', 'Apply')}
        </Button>
      );
    }
  };

  return (
    <StyledMenu onClick={(e) => killEventPropagation(e)}>
      <FilterIcon onClick={(e) => showMenu(e)} active={isActive} isMenuOpen={Boolean(anchorEl)} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        placement={placements.AUTO_START}
      >
        <StyledFilterMenu>
          <div className="title">
            <div>{MenuTitle(columnDef)}</div>
            <button type="button" onClick={handleMenuClose}>
              <Icon icon={Icons.CLOSE} color={globalColors.slate3} size={10} />
            </button>
          </div>
          {renderFilterContent()}
          <div className="footer">
            {showClear ? <div></div> : <Button onClick={handleClear}>{t('clear', 'Clear')}</Button>}
            {renderApplyBtn()}
          </div>
        </StyledFilterMenu>
      </Menu>
    </StyledMenu>
  );
};

FilterMenu.propTypes = {
  columnDef: PropTypes.instanceOf(Object).isRequired,
  filterValues: PropTypes.instanceOf(Object),
  onApply: PropTypes.func,
  onChange: PropTypes.func,
  onMenuClose: PropTypes.func,
  translateFn: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string]),
  rowsSelected: PropTypes.bool,
  columnKey: PropTypes.string,
};

FilterMenu.defaultProps = {
  filterValues: [],
  onApply: () => null,
  onChange: () => null,
  onMenuClose: () => null,
  value: null,
  rowsSelected: false,
};

export default FilterMenu;
