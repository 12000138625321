import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import boost from 'highcharts/modules/boost';
import highchartsMarkerClusters from 'highcharts/modules/marker-clusters';
import highchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import highchartsPatternFill from 'highcharts/modules/pattern-fill';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { withTheme } from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';

import { chartApi } from './chart-api';
import { chartOptionsFactory } from './chart-options-factory';
import highchartsCustom from './highcharts-custom';
import { AxisTickPlacement, chartOptions, ChartType } from './models';

/* Modules */
highchartsMore(Highcharts);
highchartsNoDataToDisplay(Highcharts);
highchartsCustom(Highcharts);
highchartsMarkerClusters(Highcharts);
boost(Highcharts);
highchartsPatternFill(Highcharts);

const ThemedChart = forwardRef(
  (
    {
      categories,
      colors,
      height,
      legendTitle,
      xMin,
      xMax,
      max,
      maxSelect,
      min,
      negative,
      noDataLabel,
      onClick,
      onCreated,
      onSelect,
      onSelected,
      // useful when getting update anomalies in highcharts
      recreateOnUpdate,
      selectable,
      series,
      shouldPropsUpdate,
      stacked,
      theme,
      turboThreshold,
      boostThreshold,
      type,
      width,
      xAxisLabelFormatter,
      xAxisLabelHeight,
      xAxisTickInterval,
      xAxisTitle,
      xAxisType,
      xAxisVisible,
      xAxisLabelsRotation,
      xAxisLabelsStep,
      xAxisLabelVisible,
      xAxisTickLength,
      xAxisTickWidth,
      xAxisPlotLines,
      xAxisTickmarkPlacement,
      yAxisAllowDecimals,
      yAxislabelsYPosition,
      yAxisLabelFormatter,
      xAxisScrollable,
      xAxisMaxPadding,
      yAxisTickInterval,
      yAxisTitle,
      yAxisVisible,
      yAxisOpposite,
      yAxisPlotLines,
      yAxisOffset,
      yAxisTickWidth,
      yAxisTickLength,
      yAxisTickPosition,
      isLoading,
      noData,
      noDataTitle,
      noDataDescription,
      onRetry,
      zoom,
      renderCondition,
      marginLeft,
      marginRight,
      disableSideMargin,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      useUTC,
      ceiling,
      endOnTick,
      tooltip,
      grouped,
      useClustering,
    },
    ref,
  ) => {
    const [options, setOptions] = useState();
    const chartRef = useRef();
    const themeRef = useRef();

    // pass theme by ref into api so it doesn't grow stale
    useEffect(() => {
      themeRef.current = theme;
    }, [theme]);

    useEffect(
      () =>
        setOptions(
          chartOptionsFactory({
            categories,
            colors,
            height,
            legendTitle,
            xMin,
            xMax,
            max,
            maxSelect,
            min,
            negative,
            noDataLabel,
            onClick,
            onSelect,
            onSelected,
            selectable,
            series,
            stacked,
            theme,
            turboThreshold,
            boostThreshold,
            type,
            width,
            xAxisLabelFormatter,
            xAxisLabelHeight,
            xAxisScrollable,
            xAxisTickInterval,
            xAxisTitle,
            xAxisType,
            xAxisVisible,
            xAxisLabelVisible,
            xAxisLabelsRotation,
            xAxisLabelsStep,
            xAxisTickLength,
            xAxisTickWidth,
            xAxisPlotLines,
            xAxisMaxPadding,
            xAxisTickmarkPlacement,
            yAxisAllowDecimals,
            yAxislabelsYPosition,
            yAxisLabelFormatter,
            yAxisTickInterval,
            yAxisTitle,
            yAxisVisible,
            yAxisOpposite,
            yAxisPlotLines,
            yAxisOffset,
            yAxisTickWidth,
            yAxisTickLength,
            yAxisTickPosition,
            zoom,
            marginLeft,
            marginRight,
            disableSideMargin,
            spacingLeft,
            spacingRight,
            spacingTop,
            spacingBottom,
            useUTC,
            ceiling,
            endOnTick,
            tooltip,
            grouped,
            useClustering,
          }),
        ),
      [
        categories,
        colors,
        height,
        legendTitle,
        xMin,
        xMax,
        max,
        maxSelect,
        min,
        negative,
        noDataLabel,
        onClick,
        onSelect,
        onSelected,
        selectable,
        series,
        stacked,
        theme,
        turboThreshold,
        boostThreshold,
        type,
        width,
        xAxisLabelFormatter,
        xAxisLabelHeight,
        xAxisScrollable,
        xAxisTickInterval,
        xAxisTitle,
        xAxisType,
        xAxisVisible,
        xAxisLabelVisible,
        xAxisLabelsRotation,
        xAxisLabelsStep,
        xAxisTickLength,
        xAxisTickWidth,
        xAxisPlotLines,
        xAxisMaxPadding,
        xAxisTickmarkPlacement,
        yAxisAllowDecimals,
        yAxislabelsYPosition,
        yAxisLabelFormatter,
        yAxisTickInterval,
        yAxisTitle,
        yAxisVisible,
        yAxisOpposite,
        yAxisPlotLines,
        yAxisOffset,
        yAxisTickWidth,
        yAxisTickLength,
        yAxisTickPosition,
        zoom,
        marginLeft,
        marginRight,
        spacingLeft,
        spacingRight,
        spacingTop,
        spacingBottom,
        disableSideMargin,
        useUTC,
        ceiling,
        endOnTick,
        tooltip,
        grouped,
        useClustering,
      ],
    );

    // api wrapper we define and expose based on high charts api
    useImperativeHandle(ref, () => chartApi(chartRef, { themeRef }));

    const handleCreated = useCallback(
      (chart) => {
        chartRef.current = chart;

        const api = chartApi(chartRef, { themeRef });

        onCreated(api);
      },
      [onCreated, themeRef],
    );

    if (!options) {
      return null;
    }

    return (
      <DataLoader
        isLoading={isLoading}
        noData={noData}
        noDataTitle={noDataTitle}
        noDataDescription={noDataDescription}
        onRetry={onRetry}
        type={type}
        renderCondition={renderCondition}
      >
        <HighchartsReact
          allowChartUpdate={shouldPropsUpdate}
          callback={handleCreated}
          highcharts={Highcharts}
          immutable={recreateOnUpdate}
          options={options}
          ref={chartRef}
        />
      </DataLoader>
    );
  },
);

export const seriesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          name: PropTypes.string,
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ]),
    ).isRequired,
    name: PropTypes.string,
    pointInterval: PropTypes.number,
    pointStart: PropTypes.number,
    selected: PropTypes.func,
    tooltipHeader: PropTypes.string,
    tooltipPoint: PropTypes.string,
    tooltipPointFormatter: PropTypes.func,
  }),
);

export const tooltipPropType = PropTypes.shape({
  borderRadius: PropTypes.number,
  outside: PropTypes.bool,
  useHTML: PropTypes.bool,
  style: PropTypes.object,
});

export const chartPropTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  grouped: PropTypes.bool,
  height: PropTypes.number,
  legendTitle: PropTypes.string,
  xMin: PropTypes.number,
  xMax: PropTypes.number,
  max: PropTypes.number,
  maxSelect: PropTypes.number,
  min: PropTypes.number,
  negative: PropTypes.bool,
  noDataLabel: PropTypes.string,
  onClick: PropTypes.func,
  onCreated: PropTypes.func,
  onSelect: PropTypes.func,
  onSelected: PropTypes.func,
  pointInterval: PropTypes.number,
  pointStart: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.number]),
  recreateOnUpdate: PropTypes.bool,
  selectable: PropTypes.bool,
  series: seriesPropType,
  shouldPropsUpdate: PropTypes.bool,
  stacked: PropTypes.bool,
  theme: PropTypes.instanceOf(Object),
  turboThreshold: PropTypes.number,
  boostThreshold: PropTypes.number,
  type: PropTypes.oneOf(Object.values(ChartType)).isRequired,
  width: PropTypes.number,
  xAxisLabelFormatter: PropTypes.func,
  xAxisLabelHeight: PropTypes.number,
  xAxisScrollable: PropTypes.bool,
  xAxisTickInterval: PropTypes.number,
  xAxisTitle: PropTypes.string,
  xAxisType: PropTypes.string,
  xAxisVisible: PropTypes.bool,
  xAxisLabelVisible: PropTypes.bool,
  xAxisLabelsRotation: PropTypes.number,
  xAxisLabelsStep: PropTypes.number,
  xAxisTickLength: PropTypes.number,
  xAxisTickWidth: PropTypes.number,
  xAxisTickmarkPlacement: PropTypes.string,
  xAxisMaxPadding: PropTypes.number,
  xAxisPlotLines: PropTypes.array,
  yAxisAllowDecimals: PropTypes.bool,
  yAxislabelsYPosition: PropTypes.number,
  yAxisLabelFormatter: PropTypes.func,
  yAxisTickInterval: PropTypes.number,
  yAxisTitle: PropTypes.string,
  yAxisVisible: PropTypes.bool,
  yAxisOpposite: PropTypes.bool,
  yAxisPlotLines: PropTypes.array,
  yAxisOffset: PropTypes.number,
  yAxisTickWidth: PropTypes.number,
  yAxisTickLength: PropTypes.number,
  yAxisTickPosition: PropTypes.string,
  isLoading: PropTypes.bool,
  noData: PropTypes.bool,
  noDataTitle: PropTypes.string,
  noDataDescription: PropTypes.string,
  onRetry: PropTypes.func,
  zoom: PropTypes.bool,
  renderCondition: PropTypes.bool,
  border: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  disableSideMargin: PropTypes.bool,
  spacingLeft: PropTypes.number,
  spacingRight: PropTypes.number,
  spacingTop: PropTypes.number,
  spacingBottom: PropTypes.number,
  useUTC: PropTypes.bool,
  ceiling: PropTypes.number,
  endOnTick: PropTypes.bool,
  tooltip: tooltipPropType,
  useClustering: PropTypes.bool,
};

ThemedChart.displayName = 'ThemedChart';

ThemedChart.propTypes = chartPropTypes;

ThemedChart.defaultProps = {
  categories: undefined,
  colors: undefined,
  height: undefined,
  legendTitle: undefined,
  xMin: null,
  xMax: null,
  max: null,
  maxSelect: undefined,
  min: null,
  negative: false,
  noDataLabel: undefined,
  onClick: () => {},
  onCreated: () => {},
  onSelect: () => {},
  onSelected: () => {},
  pointInterval: undefined,
  pointStart: undefined,
  recreateOnUpdate: false,
  selectable: false,
  series: [],
  shouldPropsUpdate: true,
  stacked: false,
  theme: undefined,
  turboThreshold: 0,
  boostThreshold: 5000,
  width: undefined,
  xAxisLabelFormatter: undefined,
  xAxisLabelHeight: chartOptions.chart && chartOptions.chart.marginBottom,
  xAxisScrollable: undefined,
  xAxisTickInterval: null,
  xAxisTitle: undefined,
  xAxisType: undefined,
  xAxisVisible: undefined,
  xAxisLabelVisible: undefined,
  xAxisLabelsRotation: 0,
  xAxisLabelsStep: 0,
  xAxisTickLength: 10,
  xAxisPlotLines: undefined,
  xAxisMaxPadding: undefined,
  yAxisAllowDecimals: undefined,
  yAxislabelsYPosition: undefined,
  yAxisLabelFormatter: undefined,
  yAxisTickInterval: null,
  yAxisTitle: undefined,
  yAxisVisible: undefined,
  yAxisOpposite: undefined,
  yAxisPlotLines: undefined,
  yAxisOffset: undefined,
  yAxisTickWidth: undefined,
  yAxisTickLength: undefined,
  yAxisTickPosition: AxisTickPlacement.OUTSIDE,
  isLoading: false,
  noData: null,
  onRetry: () => {},
  zoom: false,
  renderCondition: true,
  border: true,
  marginLeft: null,
  marginRight: null,
  disableSideMargin: false,
  spacingLeft: null,
  spacingRight: null,
  spacingTop: null,
  spacingBottom: null,
  useUTC: true,
  ceiling: null,
  endOnTick: true,
  tooltip: {},
  useClustering: false,
};

export const Chart = withTheme(ThemedChart);
