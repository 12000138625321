import { thunk, action } from 'easy-peasy';

import { AttributeGroups, UserRoles } from '@ge/models/constants';

import { getPreferredUserName } from '../services/auth';
import { fetchPersonById, fetchUserRoleSubsets } from '../services/person';

// Define initial state
let currentState = {
  userEntityAttributes: null,
  limitedUserRoles: null,
  limitedUserEntityAttributes: null,
  hasLimitedUserManagement: false,
  isUserAttributesLoading: false,
  workerLimitedEntities: [],
  workerLimitedSites: [],
};

const userRoleActions = {
  setUserEntityAttributes: action((state, payload) => {
    if (payload.attributes && payload.attributes.entity_group) {
      state.userEntityAttributes = payload.attributes.entity_group;
    }
  }),

  setUserRoleHierarchy: action((state, payload) => {
    let userRoleSubsetsPayload = payload.userRoleSubsets;
    const rolesWithSubsetKeys = payload.rolesWithSubsetKeys;
    let rolesWithSubset = [];
    let isAdminFound = userRoleSubsetsPayload.find(
      (item) => item.primaryRole === UserRoles.SUPER_ADMIN,
    );
    if (isAdminFound) {
      rolesWithSubset = isAdminFound.subRoles;
    } else if (userRoleSubsetsPayload.length === 1) {
      rolesWithSubset = userRoleSubsetsPayload[0].subRoles;
    } else if (userRoleSubsetsPayload.length > 1) {
      let values = userRoleSubsetsPayload
        .map((item) => item.subRoles)
        .reduce((result, item) => {
          return [...result, ...item];
        }, []);
      rolesWithSubset = [
        ...values
          .reduce((map, item) => {
            return map.set(`${item.name}`, item);
          }, new Map())
          .values(),
      ];
    }
    let workerEntities = getLimitedWorkerEntitiesBasedonRole(payload, rolesWithSubsetKeys);
    state.workerLimitedEntities = workerEntities.serviceGroups;
    state.workerLimitedSites = workerEntities.sites;
    const formattedRoles = rolesWithSubset?.map((role) => {
      let limitedValues = getLimitedEntitiesBasedonRole(payload, rolesWithSubsetKeys, role.name);
      return {
        a11yKey: role.name,
        defaultDesc: role.description,
        defaultLabel: role.name.replace(/[_-]/g, ' '),
        roleIsIn: limitedValues.roleIsIn,
        limitedServiceGroups: limitedValues.limitedServiceGroups,
        limitedSites: limitedValues.limitedSites,
      };
    });
    state.limitedUserRoles = formattedRoles;
  }),

  setIsUserAttributesLoading: action((state, payload) => {
    state.isUserAttributesLoading = payload;
  }),

  setHasLimitedUserManagement: action((state, payload) => {
    state.hasLimitedUserManagement = payload;
  }),

  getUserRoleBasedEntityAttributes: thunk(async (actions, payload) => {
    const getUserInfo = async () => {
      let userName = getPreferredUserName();
      return await fetchPersonById(userName);
    };
    const getUserRoleSubsets = async () => {
      return await fetchUserRoleSubsets(payload);
    };
    actions.setIsUserAttributesLoading(true);
    getUserRoleSubsets().then((userRoleSubsets) => {
      if (userRoleSubsets) {
        const mappedRoleSubsetResult = userRoleSubsets.map((subset) => {
          let key = Object.keys(subset)[0];
          return { primaryRole: key, subRoles: subset[key] };
        });
        const rolesWithSubsetKeys = mappedRoleSubsetResult.map((subset) => subset.primaryRole);
        if (rolesWithSubsetKeys.length && !rolesWithSubsetKeys.includes(UserRoles.SUPER_ADMIN)) {
          getUserInfo().then((userInfo) => {
            actions.setHasLimitedUserManagement(true);
            actions.setUserEntityAttributes(userInfo);
            actions.setUserRoleHierarchy({
              userRoleSubsets: mappedRoleSubsetResult,
              userInfo,
              rolesWithSubsetKeys,
            });
            actions.setIsUserAttributesLoading(false);
          });
        } else {
          actions.setUserRoleHierarchy({ userRoleSubsets: mappedRoleSubsetResult, userInfo: null });
          actions.setIsUserAttributesLoading(false);
          actions.setHasLimitedUserManagement(false);
        }
      }
    });
  }),
};

const getLimitedWorkerEntitiesBasedonRole = (payload, primaryLimitedRoles) => {
  let userEntityAttributes = payload.userInfo?.attributes?.entity_group;
  let serviceGroups = [];
  let sites = [];
  if (
    userEntityAttributes?.length &&
    primaryLimitedRoles.length &&
    !primaryLimitedRoles.includes(UserRoles.SUPER_ADMIN)
  ) {
    userEntityAttributes
      .filter((item) => primaryLimitedRoles.includes(item.roleName))
      .forEach((role) => {
        let serviceGroup = role.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SERVICE_GROUP,
        );
        if (serviceGroup?.ids?.length) {
          serviceGroups = [...serviceGroups, ...serviceGroup.ids];
        }
        let site = role.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SITES,
        );
        if (site?.ids?.length) {
          sites = [...sites, ...site.ids];
        }
      });
  }
  return { serviceGroups, sites };
};
const getLimitedEntitiesBasedonRole = (payload, primaryLimitedRoles, roleName) => {
  let userEntityAttributes = payload.userInfo?.attributes?.entity_group;
  let userRoleSubsets = payload.userRoleSubsets;
  let serviceGroups = [];
  let sites = [];
  let roleExistsIn = [];
  if (
    userEntityAttributes?.length &&
    primaryLimitedRoles.length &&
    !primaryLimitedRoles.includes(UserRoles.SUPER_ADMIN)
  ) {
    if (primaryLimitedRoles.length > 1) {
      roleExistsIn = userRoleSubsets
        .filter((item) => item.subRoles.some((subItem) => subItem.name === roleName))
        .map((role) => role.primaryRole);
    } else {
      roleExistsIn = primaryLimitedRoles;
    }
    userEntityAttributes
      .filter((item) => roleExistsIn.includes(item.roleName))
      .forEach((role) => {
        let serviceGroup = role.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SERVICE_GROUP,
        );
        if (serviceGroup?.ids?.length) {
          serviceGroups = [...serviceGroups, ...serviceGroup.ids];
        }
        let site = role.groupCombination?.find(
          (group) => group.groupName === AttributeGroups.SITES,
        );
        if (site?.ids?.length) {
          sites = [...sites, ...site.ids];
        }
      });
  }
  return {
    roleIsIn: roleExistsIn,
    limitedServiceGroups: serviceGroups,
    limitedSites: sites,
  };
};
const userRoleModel = {
  ...currentState,
  ...userRoleActions,
};

export default userRoleModel;
