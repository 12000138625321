import { useStoreState, useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';

export const useDataExplorerOverview = (assetIds) => {
  const getDataExplorerOverview = useStoreState(
    (actions) => actions.analyze.getDataExplorerOverview,
  );
  const { fetchDataExplorerOverview } = useStoreActions((actions) => actions.analyze);
  const { updateAssets } = useStoreActions((actions) => actions.assets);

  const { data, isLoading } = useQuery(
    [QueryKey.DATA_EXPLORER_OVERVIEW, assetIds],
    () => fetchDataExplorerOverview(assetIds),
    {
      enabled: Boolean(assetIds?.length),
      ...Config.EXECUTE_ONCE,
      retry: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data?.entities?.assets) {
      const _assets = Object.values(data.entities.assets);
      updateAssets(_assets);
    }
  }, [isLoading, data, updateAssets]);

  const { assets, signals } = getDataExplorerOverview(assetIds) || [];

  return {
    isLoading,
    data: {
      ...data,
      assets,
      signals,
    },
  };
};

export default useDataExplorerOverview;
