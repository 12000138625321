import { useStoreState, useStoreActions } from 'easy-peasy';
import { useMemo, useCallback } from 'react';

import { getRibbonState } from '@ge/feat-monitor/util/data-filter';

export const useRibbonFilter = ({ isLoading, type, data = [], isAuthorizedToViewTasks }) => {
  const setRibbonFilter = useStoreActions(({ monitor }) => monitor.setRibbonFilter);
  const ribbonFilter = useStoreState(({ monitor }) => monitor.ribbonFilter);

  const { selected, filters, ...rest } = useMemo(
    () =>
      getRibbonState({
        isLoading,
        type,
        selected: ribbonFilter[type],
        data,
        isAuthorizedToViewTasks,
      }),
    [isLoading, type, data, ribbonFilter],
  );

  const updateFilter = useCallback(
    (id) => {
      setRibbonFilter({ [type]: { id } });
    },
    [setRibbonFilter, type],
  );

  return {
    selected,
    filters,
    updateFilter,
    ...rest,
  };
};
