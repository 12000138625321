import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import { SidebarViews } from '@ge/models/constants';
import { elevations } from '@ge/tokens/elevations';

import {
  fadeOutLeft,
  fadeInRight,
  fadeInLeft,
  fadeOutRight,
} from '../../../../assets/styles/keyframes';

import AlertContainer from './shared/alert-container';
import SettingsHeader from './shared/settings-header';

const PanelPositioningCss = css`
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  transition: visibility 0.4s ease;
  animation-fill-mode: forwards;
`;

const AlertsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  overflow: hidden;
  position: relative;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: ${(props) => props.theme.sidebar.boxShadow};
    li {
      background: ${(props) => props.theme.sidebar.evenRowBackground};
      &:nth-child(odd) {
        background: ${(props) => props.theme.sidebar.oddRowBackground};
      }
    }
    button {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0;
      h3 {
        color: ${(props) => props.theme.sidebar.textColor};
        line-height: 30px;
      }
    }
  }
  .alert-options {
    ${PanelPositioningCss}
    left: 0;
    animation: ${(props) => (props.edit ? fadeOutLeft : fadeInRight)} 0.4s ease-in-out;
    visibility: ${(props) => (props.edit === '' ? 'visible' : 'hidden')};
  }
  .alert-edit {
    ${PanelPositioningCss}
    animation: ${(props) => (props.edit ? fadeInLeft : fadeOutRight)} 0.4s ease-in-out;
    visibility: ${(props) => (props.edit !== '' ? 'visible' : 'hidden')};
    h3 {
      background: ${(props) => props.theme.sidebar.editHeaderBackground};
      color: ${(props) => props.theme.sidebar.textColor};
      padding: 10px;
      margin-top: 8px;
    }
  }
  .back-btn {
    color: ${(props) => props.theme.sidebar.textColor};
    text-transform: uppercase;
    svg {
      margin-right: 4px;
    }
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: ${elevations.P10};
  }
`;

const ListTypeIcon = styled(Icon).attrs((props) => ({
  size: 14,
  icon: props.icon,
  color: props.theme.sidebar.listIconColor,
}))`
  margin-left: 20px;
  margin-right: 10px;
`;

const ChevronIcon = styled(Icon).attrs((props) => ({
  size: 12,
  icon: Icons.CHEVRON,
  color: props.theme.sidebar.listActionIconColor,
  rotate: -90,
}))`
  margin-left: auto;
  margin-right: 10px;
`;

const CloseIcon = styled(Icon).attrs((props) => ({
  size: 10,
  icon: Icons.CLOSE,
  color: props.theme.sidebar.settingsIconColor,
}))``;

const BackIcon = styled(Icon).attrs((props) => ({
  color: props.theme.sidebar.settingsIconColor,
}))``;

const ListItem = ({ label, icon, onClick }) => (
  <li>
    <button type="button" onClick={onClick}>
      <ListTypeIcon icon={icon} />
      <h3>{label}</h3>
      <ChevronIcon />
    </button>
  </li>
);

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const AlertSettings = ({ onHideSettings }) => {
  const { t } = useTranslation();

  const [alertEdit, setAlertEdit] = useState('');

  const clearEdit = () => {
    setAlertEdit('');
  };

  return (
    <AlertsContainer edit={alertEdit}>
      <button
        type="button"
        className="close-btn"
        onClick={() => {
          onHideSettings();
          clearEdit();
        }}
      >
        <CloseIcon />
      </button>

      <div className="alert-options">
        <SettingsHeader
          title={`${t('sidebar.alerts', 'Alerts')} ${t('settings', 'settings')}`}
          icon={Icons.BELL}
        />
        <ul>
          <ListItem
            icon={Icons.PERSON}
            label={t('customer', 'Customer')}
            onClick={() => setAlertEdit(SidebarViews.CUSTOMER)}
          />
          <ListItem
            icon={Icons.FLEET}
            label={t('region', 'Region')}
            onClick={() => setAlertEdit(SidebarViews.REGION)}
          />
          <ListItem
            icon={Icons.SITE}
            label={t('site', 'Site')}
            onClick={() => setAlertEdit(SidebarViews.SITE)}
          />
          <ListItem
            icon={Icons.TURBINE}
            label={t('asset', 'Asset')}
            onClick={() => setAlertEdit(SidebarViews.ASSET)}
          />
          <ListItem
            icon={Icons.DOLLAR}
            label={t('market_price', 'Market Price')}
            onClick={() => setAlertEdit(SidebarViews.MARKET_PRICE)}
          />
        </ul>
      </div>
      <div className="alert-edit">
        <button type="button" className="back-btn" onClick={clearEdit}>
          <BackIcon icon={Icons.CHEVRON} rotate={90} size={12} />
          {t('back', 'back')}
        </button>
        {alertEdit === SidebarViews.CUSTOMER && (
          <AlertContainer
            title={`${t('customer', 'Customer')} ${t('sidebar.alerts', 'Alerts')}`}
            icon={Icons.PERSON}
            onCancel={clearEdit}
            onSave={clearEdit}
          />
        )}
        {alertEdit === SidebarViews.REGION && (
          <AlertContainer
            title={`${t('region', 'Region')} ${t('sidebar.alerts', 'Alerts')}`}
            icon={Icons.FLEET}
            onCancel={clearEdit}
            onSave={clearEdit}
          />
        )}
        {alertEdit === SidebarViews.SITE && (
          <AlertContainer
            title={`${t('site', 'Site')} ${t('sidebar.alerts', 'Alerts')}`}
            icon={Icons.SITE}
            onCancel={clearEdit}
            onSave={clearEdit}
          />
        )}
        {alertEdit === SidebarViews.ASSET && (
          <AlertContainer
            title={`${t('asset', 'Asset')} ${t('sidebar.alerts', 'Alerts')}`}
            icon={Icons.TURBINE}
            onCancel={clearEdit}
            onSave={clearEdit}
          />
        )}
        {alertEdit === SidebarViews.MARKET_PRICE && (
          <AlertContainer
            title={`${t('market_price', 'Market Price')} ${t('sidebar.alerts', 'Alerts')}`}
            icon={Icons.DOLLAR}
            onCancel={clearEdit}
            onSave={clearEdit}
          />
        )}
      </div>
    </AlertsContainer>
  );
};

AlertSettings.propTypes = {
  onHideSettings: PropTypes.func,
};

AlertSettings.defaultProps = {
  onHideSettings: () => null,
};

export default AlertSettings;
