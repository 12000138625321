import { useState, useCallback, useEffect } from 'react';

import { useResizableContext } from './resizable-table-context';

export const useDragState = (columns, onDrop) => {
  // Build isolated drag/drop state
  const [dragOver, setDragOver] = useState(null);
  const [dragItem, setDragItem] = useState(null);
  const [hoverColumn, setHoverColumn] = useState(null);

  const { isResizing } = useResizableContext();

  useEffect(() => {
    if (isResizing) {
      // Reset state.
      setDragOver(null);
      setDragItem(null);
      setHoverColumn(null);
    }
  }, [isResizing]);

  // Drag/drop event handlers
  const handleDragStart = useCallback(
    (key) => {
      if (isResizing) return;
      setDragItem(key);
      setHoverColumn(null);
    },
    [isResizing, setDragItem, setHoverColumn],
  );

  const handleDragOver = useCallback(
    (e) => {
      if (isResizing) return;
      e.preventDefault();
    },
    [isResizing],
  );

  const handleDragEnter = useCallback(
    (key) => {
      if (isResizing) return;
      setDragOver(key);
    },
    [isResizing, setDragOver],
  );

  const handleHover = useCallback(
    (groupId) => {
      if (isResizing) return;
      setHoverColumn(groupId);
    },
    [isResizing, setHoverColumn],
  );

  const handleDrop = useCallback(
    (e) => {
      if (isResizing) return;

      const newColumnState = [...columns];

      // Calculate start and end indexes.
      let dropIndex = columns.findIndex((col) => col.id === e.target.getAttribute('data-key'));
      const dragIndex = columns.findIndex((col) => col.id === dragItem);

      // alter drop index for LTR column move.
      if (dragIndex < dropIndex) {
        dropIndex -= 1;
      }

      // Update the column order to reflect dropped position
      newColumnState.splice(dropIndex, 0, newColumnState.splice(dragIndex, 1)[0]);

      // Invoke the provided onDrop method with the updated column state.
      onDrop.call(null, newColumnState);

      // Reset state.
      setDragOver(null);
      setDragItem(null);
      setHoverColumn(null);
    },
    [isResizing, columns, dragItem, onDrop],
  );

  return {
    handlers: {
      handleDragStart,
      handleDragOver,
      handleDragEnter,
      handleHover,
      handleDrop,
    },
    state: {
      columns,
      dragOver,
      hoverColumn,
      isResizing,
    },
  };
};
