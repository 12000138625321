import { useStoreState } from 'easy-peasy';
import { useQuery } from 'react-query';

import { QueryKey } from '@ge/models/constants';
import { Config } from '@ge/shared/data-hooks';
import { useFilterDateRange } from '@ge/shared/hooks';

import { useGlobalFilters } from '../hooks';
import { fetchOemAssetData } from '../services';

export const useOemAssetData = ({
  category,
  entityId,
  entityType,
  enabled,
  oemStates,
  pageIndex,
  pageSize,
}) => {
  // store
  const { dateRange } = useStoreState(({ analyze }) => analyze);
  const filters = useGlobalFilters();

  // hooks
  // TODO: can move start and end date up to params but for now trying to streamline through date range set in store
  const { endDate, startDate } = useFilterDateRange({ dateRange });

  const { data, error, isLoading } = useQuery(
    [
      QueryKey.OEM_ASSET_DATA,
      endDate,
      entityId,
      entityType,
      oemStates,
      pageIndex,
      pageSize,
      startDate,
      category,
      filters,
    ],
    async () => {
      const params = {
        category,
        endDate,
        entityId,
        entityType,
        oemStates,
        pageIndex,
        pageSize,
        startDate,
        filters,
      };

      const { data } = await fetchOemAssetData(params);

      return data;
    },
    {
      ...Config.EXECUTE_ONCE,
      enabled,
    },
  );

  return { data, error, isLoading };
};
