import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useMemo, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Input } from '@ge/components/input/input';
import { Radio } from '@ge/components/radio';
import { TableNumberFilterType, TableNumberFilterTypes } from '@ge/models/constants';
import { killEventPropagation } from '@ge/shared/util/general';

const StyledRadioInput = styled.div`
  margin: 16px 0 16px;
`;

const Container = styled.div`
  padding: 0px 6px 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
    overflow: hidden;
    padding: 1px;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  input {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const FilterNumber = ({ onChange, value, inputRef, type }) => {
  const { t } = useTranslation(['general'], { useSuspense: false });

  const startRef = useRef(null);
  const endRef = useRef(null);

  const [selectedType, setSelectedType] = useState(type);

  const options = useMemo(() => Object.entries(TableNumberFilterTypes), []);

  const [start, end] = useMemo(() => {
    const [min, max] = value ?? [];
    if (selectedType === TableNumberFilterType.RANGE) {
      return [
        { a11yKey: 'start', a11yDefault: 'Start', value: min },
        { a11yKey: 'end', a11yDefault: 'End', value: max },
      ];
    }
    if (selectedType === TableNumberFilterType.GREATER_THAN) {
      return [
        {
          ...TableNumberFilterTypes[TableNumberFilterType.GREATER_THAN],
          value: min,
        },
      ];
    }
    if (selectedType === TableNumberFilterType.LESS_THAN) {
      return [
        {
          ...TableNumberFilterTypes[TableNumberFilterType.LESS_THAN],
          value: max,
        },
      ];
    }
    return [{ label: null, value: min }];
  }, [value, selectedType]);

  const handleFilterType = useCallback(
    (name) => {
      if (startRef?.current?.value) {
        startRef.current.value = '';
      }
      if (endRef?.current?.value) {
        endRef.current.value = '';
      }
      setSelectedType(name);
      onChange([]);
    },
    [startRef, endRef, onChange],
  );

  const handleChange = useCallback(
    ({ target }) => {
      switch (selectedType) {
        case TableNumberFilterType.RANGE:
          return target.name === 'end'
            ? onChange([value?.[0], target.value])
            : onChange([target.value, null]);
        case TableNumberFilterType.GREATER_THAN:
          return onChange([target.value, 'Infinity']);
        case TableNumberFilterType.LESS_THAN:
          return onChange(['-Infinity', target.value]);
        default:
          return onChange([target.value]);
      }
    },
    [value, selectedType, onChange],
  );

  useImperativeHandle(inputRef, () => ({
    reset: () => {
      if (startRef?.current?.value) {
        startRef.current.value = '';
      }
      if (endRef?.current?.value) {
        endRef.current.value = '';
      }
    },
  }));

  return (
    <Container>
      <FlexWrapper>
        <Input
          type="number"
          name={'start'}
          onKeyDown={(e) => killEventPropagation(e)}
          onChange={handleChange}
          defaultValue={start.value}
          label={start.a11yKey ? t(start.a11yKey, start.a11yDefault) : null}
          ref={startRef}
        />
        {selectedType === TableNumberFilterType.RANGE && (
          <Input
            type="number"
            name={'end'}
            onKeyDown={(e) => killEventPropagation(e)}
            onChange={handleChange}
            defaultValue={end.value}
            label={end.a11yKey ? t(end.a11yKey, end.a11yDefault) : null}
            ref={endRef}
          />
        )}
      </FlexWrapper>
      <StyledRadioInput>
        {options.map(([name, { a11yKey, a11yDefault }]) => (
          <Radio
            key={name}
            name={'selectedType'}
            id={name}
            checked={selectedType == name}
            onChange={() => handleFilterType(name)}
            label={t(a11yKey, a11yDefault)}
            ref={inputRef}
          />
        ))}
      </StyledRadioInput>
    </Container>
  );
};

FilterNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  inputRef: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

FilterNumber.defaultValues = {
  value: [],
  type: 'value',
};
