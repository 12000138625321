import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@ge/components/button';
import { globalColors } from '@ge/tokens/colors';

import { Menu, MenuTitle } from '../index';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  padding: 30px;
`;

const StyledContent = styled.p`
  color: ${(props) => (props.theme.name === 'Light' ? globalColors.black : globalColors.white)};
`;

const MenuContent = styled.div`
  padding: 10px;
  max-width: 300px;
`;

export const SBDMenu = ({ title, count, duration, content }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledWrapper>
      <Button type="button" key="menu-button" onClick={(e) => showMenu(e)}>
        Toggle Menu
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} offset={10}>
        <MenuContent>
          <MenuTitle title={title} count={count} duration={duration} />
          <StyledContent>{content}</StyledContent>
        </MenuContent>
      </Menu>
    </StyledWrapper>
  );
};

SBDMenu.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  duration: PropTypes.string,
  content: PropTypes.string,
};
