import { TableFilterTypes } from '@ge/models/constants';

export const AssetsColumnsStorage = {
  // Assets
  GROUP_ASSETS: 'group-assets',
  ASSET: 'asset',

  // Contractual Availability
  GROUP_CONTRACTUAL_AVAILABILITY: 'group-contractual-availability',
  CONTRACTUAL_AVAILABILITY: 'contractual_availability',

  // Raw Availability
  GROUP_RAW_AVAILABILITY: 'group-raw-availability',
  RAW_AVAILABILITY: 'raw_availability',

  // State of Health
  GROUP_STATE_OF_HEALTH_SOH: 'group-state-of-health-soh',
  STATE_OF_HEALTH_SOH: 'state_of_health_soh',

  // Avarage cycles per day
  GROUP_AVERAGE_CYCLES_PER_DAY: 'group-average-cycles-per-day',
  AVERAGE_CYCLES_PER_DAY: 'average_cycles_per_day',

  // Daily Avg. SOC
  GROUP_DAILY_AVG_SOC: 'group-daily-avg-soc',
  DAILY_AVG_SOC: 'daily_avg_soc',

  // Total Energy Throughput
  GROUP_TOTAL_ENERGY_THROUGHPUT: 'group-total-energy-thoughput',
  TOTAL_ENERGY_THROUGHPUT: 'total_energy_throughput',

  GROUP_ASSET_DETAIL: 'group-asset-detail',
  ASSET_DETAIL: 'asset-detail',
};

// Define all available columns in the assets table.
export const AssetsColumnStorageDefs = {
  [AssetsColumnsStorage.GROUP_ASSETS]: {
    cols: {
      [AssetsColumnsStorage.ASSET]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        locked: true,
        labels: [
          {
            a11yKey: 'table.asset',
            a11yDefault: 'Asset',
            sortValue: 'asset',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_CONTRACTUAL_AVAILABILITY]: {
    cols: {
      [AssetsColumnsStorage.CONTRACTUAL_AVAILABILITY]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.contractual_availability_storage',
            a11yDefault: 'Contractual Availability (%)',
            sortValue: 'contractual_availability',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_RAW_AVAILABILITY]: {
    cols: {
      [AssetsColumnsStorage.RAW_AVAILABILITY]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.raw_availability_storage',
            a11yDefault: 'Raw Availability (%)',
            sortValue: 'raw_availability',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_STATE_OF_HEALTH_SOH]: {
    cols: {
      [AssetsColumnsStorage.STATE_OF_HEALTH_SOH]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.state_of_health_storage',
            a11yDefault: 'State of Health SoH (%)',
            sortValue: 'state_of_health_soh',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_AVERAGE_CYCLES_PER_DAY]: {
    cols: {
      [AssetsColumnsStorage.AVERAGE_CYCLES_PER_DAY]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.avg_cycles_per_day_storage',
            a11yDefault: 'Average Cycles per Day',
            sortValue: 'average_cycles_per_day',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_DAILY_AVG_SOC]: {
    cols: {
      [AssetsColumnsStorage.DAILY_AVG_SOC]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.daily_avg_soc_storage',
            a11yDefault: 'Daily Avg. SoC (%)',
            sortValue: 'daily_avg_soc',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_TOTAL_ENERGY_THROUGHPUT]: {
    cols: {
      [AssetsColumnsStorage.TOTAL_ENERGY_THROUGHPUT]: {
        align: 'left',
        minWidth: '140px',
        filterType: TableFilterTypes.CHECKBOXES,
        labels: [
          {
            a11yKey: 'table.total_energy_throughput_storage',
            a11yDefault: 'Total Energy Throughput (MWh)',
            sortValue: 'total_energy_throughput',
          },
        ],
      },
    },
  },
  [AssetsColumnsStorage.GROUP_ASSET_DETAIL]: {
    fixedRight: true,
    align: 'left',
    cols: {
      [AssetsColumnsStorage.ASSET_DETAIL]: {
        whiteSpace: 'wrap',
        minWidth: '50px',
        align: 'left',
        locked: true,
        labels: [
          {
            a11yKey: 'table.asset_detail',
            a11yDefault: 'Asset\nDetails',
          },
        ],
        cell: {
          zeroPadding: true,
          width: '43px',
        },
      },
    },
  },
};
