import HighchartsReact from 'highcharts-react-official';
import highchartsRoundedCorners from 'highcharts-rounded-corners';
import highchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highmaps';
import boost from 'highcharts/modules/boost';
import highchartsMarkerClusters from 'highcharts/modules/marker-clusters';
import highchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { withTheme } from 'styled-components';

import { DataLoader } from '@ge/components/data-loader';

import { chartApi } from './chart-api';
import { mapsChartOptionsFactory } from './maps-chart-options-factory';
import { AxisTickPlacement, chartOptions, ChartType } from './models';

/* Modules */
highchartsMore(Highcharts);
highchartsNoDataToDisplay(Highcharts);
highchartsRoundedCorners(Highcharts);
highchartsMarkerClusters(Highcharts);
boost(Highcharts);

(function(H) {
  // Workaround for the bug described here: https://github.com/highcharts/highcharts/issues/13981
  // Remove once the Highcharts package has been upgraded to version 9.0.0 or later.
  H.wrap(H.seriesTypes.scatter.prototype, 'getScaledGridSize', function(proceed) {
    if (this.data.length) {
      proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    } else {
      const clusterOptions = this.options.cluster?.layoutAlgorithm;
      return clusterOptions?.processedGridSize || clusterOptions.gridSize || 50;
    }
  });

  // Workaround Highcharts bug when boost rendering is enabled:
  // The `buildKDTree` function is sometimes called after the selection event
  // with `series.options` being undefined, which the code does not check for.
  H.wrap(H.Series.prototype, 'buildKDTree', function(proceed) {
    if (this.options) {
      proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    }
  });
})(Highcharts);

const ThemedChart = forwardRef(
  (
    {
      categories,
      colors,
      height,
      legendTitle,
      xMin,
      xMax,
      max,
      min,
      negative,
      noDataLabel,
      onCreated,
      // useful when getting update anomalies in highcharts
      recreateOnUpdate,
      inverted,
      title,
      subTitle,
      series,
      shouldPropsUpdate,
      theme,
      boostThreshold,
      type,
      width,
      xAxisLabelFormatter,
      xAxisLabelHeight,
      xAxisTickInterval,
      xAxisTitle,
      xAxisLabelVisible,
      xAxisTickLength,
      xAxisTickWidth,
      xAxisLabelsRotation,
      xAxisStaggerLines,
      xAxisAllowOverflow,
      xAxisMaxPadding,
      xShowEmpty,
      xAxisReversed,
      yAxis,
      yAxisLabelFormatter,
      yShowEmpty,
      yAxisTickInterval,
      yAxisTitle,
      yAxisOffset,
      yAxisTickLength,
      yAxisTickPosition,
      isLoading,
      noData,
      noDataTitle,
      noDataDescription,
      onRetry,
      zoom,
      renderCondition,
      marginLeft,
      marginRight,
      marginTop,
      disableSideMargin,
      spacingLeft,
      spacingRight,
      spacingTop,
      spacingBottom,
      useUTC,
      endOnTick,
      tooltip,
      grouped,
      tooltipCrosshairs,
    },
    ref,
  ) => {
    const [options, setOptions] = useState();
    const chartRef = useRef();
    const themeRef = useRef();

    // pass theme by ref into api so it doesn't grow stale
    useEffect(() => {
      themeRef.current = theme;
    }, [theme]);

    useEffect(
      () =>
        setOptions(
          mapsChartOptionsFactory({
            categories,
            colors,
            height,
            legendTitle,
            xMin,
            xMax,
            max,
            min,
            negative,
            noDataLabel,
            inverted,
            title,
            subTitle,
            series,
            theme,
            boostThreshold,
            type,
            width,
            xAxisLabelFormatter,
            xAxisLabelHeight,
            xAxisTickInterval,
            xAxisTitle,
            xAxisLabelVisible,
            xAxisTickLength,
            xAxisTickWidth,
            xAxisMaxPadding,
            xShowEmpty,
            xAxisReversed,
            xAxisLabelsRotation,
            xAxisStaggerLines,
            xAxisAllowOverflow,
            yAxis,
            yShowEmpty,
            yAxisLabelFormatter,
            yAxisTickInterval,
            yAxisTitle,
            yAxisOffset,
            yAxisTickLength,
            yAxisTickPosition,
            zoom,
            marginLeft,
            marginRight,
            marginTop,
            disableSideMargin,
            spacingLeft,
            spacingRight,
            spacingTop,
            spacingBottom,
            useUTC,
            endOnTick,
            tooltip,
            grouped,
            tooltipCrosshairs,
          }),
        ),
      [
        categories,
        colors,
        height,
        legendTitle,
        xMin,
        xMax,
        max,
        min,
        negative,
        noDataLabel,
        inverted,
        title,
        subTitle,
        series,
        theme,
        boostThreshold,
        type,
        width,
        xAxisLabelFormatter,
        xAxisLabelHeight,
        xAxisTickInterval,
        xAxisTitle,
        xAxisLabelVisible,
        xAxisTickLength,
        xAxisTickWidth,
        xAxisMaxPadding,
        xShowEmpty,
        xAxisReversed,
        xAxisLabelsRotation,
        xAxisStaggerLines,
        xAxisAllowOverflow,
        yAxis,
        yShowEmpty,
        yAxisLabelFormatter,
        yAxisTickInterval,
        yAxisTitle,
        yAxisOffset,
        yAxisTickLength,
        yAxisTickPosition,
        zoom,
        marginLeft,
        marginRight,
        marginTop,
        spacingLeft,
        spacingRight,
        spacingTop,
        spacingBottom,
        disableSideMargin,
        useUTC,
        endOnTick,
        tooltip,
        grouped,
        tooltipCrosshairs,
      ],
    );

    // api wrapper we define and expose based on high charts api
    useImperativeHandle(ref, () => chartApi(chartRef, { themeRef }));

    const handleCreated = useCallback(
      (chart) => {
        chartRef.current = chart;

        const api = chartApi(chartRef, { themeRef });

        onCreated(api);
      },
      [onCreated, themeRef],
    );

    if (!options) {
      return null;
    }

    return (
      <DataLoader
        isLoading={isLoading}
        noData={noData}
        noDataTitle={noDataTitle}
        noDataDescription={noDataDescription}
        onRetry={onRetry}
        type={type}
        renderCondition={renderCondition}
      >
        <HighchartsReact
          allowChartUpdate={shouldPropsUpdate}
          callback={handleCreated}
          highcharts={Highcharts}
          constructorType={'mapChart'}
          immutable={recreateOnUpdate}
          options={options}
          ref={chartRef}
        />
      </DataLoader>
    );
  },
);

export const seriesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          name: PropTypes.string,
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ]),
    ).isRequired,
    name: PropTypes.string,
    pointInterval: PropTypes.number,
    pointStart: PropTypes.number,
    selected: PropTypes.func,
    tooltipHeader: PropTypes.string,
    tooltipPoint: PropTypes.string,
    tooltipPointFormatter: PropTypes.func,
  }),
);

export const tooltipPropType = PropTypes.shape({
  borderRadius: PropTypes.number,
  outside: PropTypes.bool,
  useHTML: PropTypes.bool,
  style: PropTypes.object,
});

export const chartPropTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  grouped: PropTypes.bool,
  height: PropTypes.number,
  legendTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  xMin: PropTypes.number,
  xMax: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  negative: PropTypes.bool,
  noDataLabel: PropTypes.string,
  onCreated: PropTypes.func,
  pointInterval: PropTypes.number,
  pointStart: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.number]),
  recreateOnUpdate: PropTypes.bool,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  opacity: PropTypes.number,
  scrollPositionX: PropTypes.number,
  scrollPositionY: PropTypes.number,
  inverted: PropTypes.bool,
  series: seriesPropType,
  shouldPropsUpdate: PropTypes.bool,
  theme: PropTypes.instanceOf(Object),
  boostThreshold: PropTypes.number,
  type: PropTypes.oneOf(Object.values(ChartType)).isRequired,
  width: PropTypes.number,
  xAxisLabelFormatter: PropTypes.func,
  xAxisLabelHeight: PropTypes.number,
  xAxisTickInterval: PropTypes.number,
  xAxisTitle: PropTypes.string,
  xAxisLabelVisible: PropTypes.bool,
  xAxisTickLength: PropTypes.number,
  xAxisTickWidth: PropTypes.number,
  xAxisMaxPadding: PropTypes.number,
  xShowEmpty: PropTypes.bool,
  xAxisReversed: PropTypes.bool,
  xAxisLabelsRotation: PropTypes.number,
  xAxisStaggerLines: PropTypes.number,
  xAxisAllowOverflow: PropTypes.string,
  yAxis: PropTypes.instanceOf(Object),
  yShowEmpty: PropTypes.bool,
  yAxisLabelFormatter: PropTypes.func,
  yAxisTickInterval: PropTypes.number,
  yAxisTitle: PropTypes.string,
  yAxisOffset: PropTypes.number,
  yAxisTickLength: PropTypes.number,
  yAxisTickPosition: PropTypes.string,
  isLoading: PropTypes.bool,
  noData: PropTypes.bool,
  noDataTitle: PropTypes.string,
  noDataDescription: PropTypes.string,
  onRetry: PropTypes.func,
  zoom: PropTypes.bool,
  renderCondition: PropTypes.bool,
  border: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  disableSideMargin: PropTypes.bool,
  spacingLeft: PropTypes.number,
  spacingRight: PropTypes.number,
  spacingTop: PropTypes.number,
  spacingBottom: PropTypes.number,
  useUTC: PropTypes.bool,
  endOnTick: PropTypes.bool,
  tooltip: tooltipPropType,
  tooltipCrosshairs: PropTypes.bool,
};

ThemedChart.displayName = 'ThemedChart';

ThemedChart.propTypes = chartPropTypes;

ThemedChart.defaultProps = {
  categories: undefined,
  colors: undefined,
  height: undefined,
  legendTitle: undefined,
  title: '',
  subTitle: '',
  xMin: undefined,
  xMax: undefined,
  max: undefined,
  min: undefined,
  negative: false,
  noDataLabel: undefined,
  onCreated: () => {},
  pointInterval: undefined,
  pointStart: undefined,
  recreateOnUpdate: false,
  minHeight: undefined,
  minWidth: undefined,
  opacity: 0.85,
  scrollPositionX: undefined,
  scrollPositionY: undefined,
  inverted: false,
  series: [],
  shouldPropsUpdate: true,
  theme: undefined,
  boostThreshold: 5000,
  width: undefined,
  xAxisLabelFormatter: undefined,
  xAxisLabelHeight: chartOptions.chart && chartOptions.chart.marginBottom,
  xAxisTickInterval: undefined,
  xAxisTitle: undefined,
  xAxisLabelVisible: true,
  xAxisTickLength: 10,
  xAxisMaxPadding: undefined,
  xShowEmpty: true,
  xAxisReversed: undefined,
  xAxisLabelsRotation: undefined,
  xAxisStaggerLines: 0,
  xAxisAllowOverflow: 'justify',
  yAxis: undefined,
  yShowEmpty: true,
  yAxisLabelFormatter: undefined,
  yAxisTickInterval: null,
  yAxisTitle: undefined,
  yAxisOffset: undefined,
  yAxisTickLength: undefined,
  yAxisTickPosition: AxisTickPlacement.OUTSIDE,
  isLoading: false,
  noData: null,
  onRetry: () => {},
  zoom: false,
  renderCondition: true,
  border: true,
  marginLeft: null,
  marginRight: null,
  marginTop: null,
  disableSideMargin: false,
  spacingLeft: null,
  spacingRight: null,
  spacingTop: null,
  spacingBottom: null,
  useUTC: true,
  endOnTick: true,
  tooltip: {},
  tooltipCrosshairs: true,
};

export const MapsChart = withTheme(ThemedChart);
