import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Icons } from '@ge/components/icon';
import { Dialog } from '@ge/components/modal';
import { Textarea } from '@ge/components/textarea/textarea';
import { EntityType, SEE_MORE_CHAR } from '@ge/models/constants';
import {
  DateTimeFormats,
  FormMode,
  Capability,
  NotesScope,
  PermissionScope,
} from '@ge/models/constants';
import { SeeMore } from '@ge/shared/components/see-more';
import { useAuth } from '@ge/shared/data-hooks';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

import NoteFooter from './note-footer';
const NAMESPACE = 'entity-details';

const NotesDialogSegment = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  margin-top: 20px;
  padding-bottom: 10px;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  dl {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    width: 250px;
  }
  dt {
    color: ${(props) => props.theme.entityDetails.notes.entityInfo};
    font: 300 11px/15px;
    grid-row: 1;
  }
  dd {
    font: 300 13px/15px;
    margin-left: 0;
  }
`;

const AddNoteFormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  div:first-of-type {
    flex-grow: 1;
    margin-right: 10px;
  }
  input[type='text'] {
    width: 80%;
  }
  textarea {
    width: 100%;
  }
  &.invalid {
    textarea {
      border: 1px solid ${(props) => props.theme.input.invalidBorder};
    }
  }
`;

const EntityInfo = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
`;

const BulkAssets = styled.div`
  color: ${(props) => props.theme.entityDetails.notes.entityInfo};
  font: 300 12px/15px;
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  & > div:first-child {
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 12px;
    color: ${(props) => props.theme.entityDetails.tickets.textColor};
    text-align: left;
    margin-bottom: 12px;
    font-weight: 400;
  }
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  color: theme.entityDetails.badgeColor,
}))`
  margin-left: 7px;
`;

export const AddNoteDialog = ({
  onClose,
  onConfirm,
  entity,
  siteType,
  entityType,
  mode,
  data,
  isOpen,
  selectedAssets,
}) => {
  const { t } = useTranslation(['entity-details']);
  const { getSiteById } = useStoreState((state) => state.sites);
  const [site, setSite] = useState(null);
  const [isInvalidNote, setIsInvalidNote] = useState(false);

  const isBulkNotes = useMemo(() => selectedAssets?.length > 1, [selectedAssets]);

  useEffect(() => {
    if (
      (entityType === EntityType.ASSET || entityType === EntityType.INVERTER) &&
      entity?.site?.id
    ) {
      setSite(getSiteById(entity?.site?.id));
    }
  }, [setSite, getSiteById, entity, entityType]);

  // Configure form hook and define logic for submission.
  const { handleSubmit, register } = useForm({
    mode: 'onBlur',
  });

  const onNoteChange = useCallback(({ currentTarget }) => {
    currentTarget?.value.trim() ? setIsInvalidNote(false) : setIsInvalidNote(true);
  }, []);

  const onCloseDialog = useCallback(() => {
    onClose();
    setIsInvalidNote(false);
  }, [onClose]);

  const { isAuthorized } = useAuth();
  const isInternalUser = isAuthorized({
    capabilities: [{ capability: Capability.INTERNAL_ACCESS, scopes: [PermissionScope.EDIT] }],
  });

  const onSubmit = useCallback(
    (values) => {
      const { externalViewing } = values;
      let isValidationFailed = false;
      values.scope = isInternalUser
        ? externalViewing
          ? NotesScope.EXTERNAL
          : NotesScope.INTERNAL
        : NotesScope.EXTERNAL;
      if (!values.note?.trim()) {
        setIsInvalidNote(true);
        isValidationFailed = true;
      }

      if (!isValidationFailed) {
        onConfirm({
          ...values,
        });
      }
    },
    [onConfirm, isInternalUser],
  );

  const getSiteName = useMemo(() => {
    if (site) {
      return site?.name;
    } else if (entity?.name) {
      return entity?.name;
    } else {
      return entity?.site?.name;
    }
  }, [site, entity]);

  const getAssetName = useMemo(() => {
    if (siteType === EntityType.TURBINE || siteType === EntityType.INVERTER) {
      return entity?.name;
    } else if (entity?.asset?.id) {
      return entity?.asset?.name;
    }
  }, [entity, siteType]);

  const getHeader = useCallback(() => {
    if (isBulkNotes) {
      return (
        <>
          <BulkAssets>
            <EntityInfo>
              <span>
                {t('escalate_case.assets_selected', 'Asset(s) Selected: All Asset(s)')}
                <StyledBadge label={selectedAssets?.length} small />
              </span>
            </EntityInfo>
            <EntityInfo>
              <SeeMore charLimit={SEE_MORE_CHAR}>
                {selectedAssets.map((item) => item.assetName).join(', ')}
              </SeeMore>
            </EntityInfo>
          </BulkAssets>
          <NotesDialogSegment>
            <AddNoteFormRow className={isInvalidNote ? 'invalid' : ''}>
              <Textarea
                id="task-description-textarea"
                label={t('notes.note', 'Note')}
                placeholder={t('notes.bulk_note_placeholder', 'Type note here...')}
                name="note"
                defaultValue={''}
                ref={register}
                required
                onChange={onNoteChange}
              />
            </AddNoteFormRow>
          </NotesDialogSegment>
        </>
      );
    } else {
      const entitySiteDetails = {
        assetType: (entity?.asset?.id && entity?.asset?.type) || entity?.type,
        siteType: siteType,
        siteName: getSiteName,
        assetName: getAssetName,
        entityDescriptionIcon: Icons.EVENT,
        entityDescription: entity?.description,
      };

      return (
        <>
          <NotesDialogSegment>
            <EntityDialogHeader entityDetails={entitySiteDetails} />
          </NotesDialogSegment>

          {mode === FormMode.EDIT && (
            <NotesDialogSegment>
              <dl>
                <dt>{t('notes.date_created', 'Date Created')}</dt>
                <dd>{dayjs(data.creationDate).format(DateTimeFormats.ISSUE_DETAIL_DATE)}</dd>
                <dt>{t('notes.contributed_by', 'Contributed By')}</dt>
                <dd>{data.createdBy}</dd>
              </dl>
            </NotesDialogSegment>
          )}
          <NotesDialogSegment>
            <AddNoteFormRow className={isInvalidNote ? 'invalid' : ''}>
              <Textarea
                id="task-description-textarea"
                label={t('notes.note', 'Note')}
                placeholder={t('notes.add_note_placeholder', 'Add a note')}
                name="note"
                defaultValue={mode === FormMode.EDIT ? data.note : ''}
                ref={register}
                required
                onChange={onNoteChange}
              />
            </AddNoteFormRow>
            {/* {entityType !== EntityType.EVENT && (
          <AddNoteFormRow>
            <Button primary>{t('notes.attach_file', 'Attach File')}</Button>
          </AddNoteFormRow>
        )} */}
          </NotesDialogSegment>
        </>
      );
    }
  }, [
    selectedAssets,
    t,
    data,
    siteType,
    entity,
    isBulkNotes,
    isInvalidNote,
    mode,
    onNoteChange,
    register,
    getSiteName,
    getAssetName,
  ]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      header={
        mode === FormMode.EDIT
          ? t('notes.edit_note', 'Edit Note')
          : entityType === EntityType.EVENT
          ? (selectedAssets?.length > 1 && t('notes.bulk_case_note', 'Bulk Add Note')) ||
            t('notes.new_case_note', 'New Case Note')
          : t('notes.new_note', 'New Note')
      }
      footer={
        <NoteFooter
          namespace={NAMESPACE}
          mode={mode}
          data={data}
          onCancel={onCloseDialog}
          onSubmit={handleSubmit(onSubmit)}
          ref={register}
          assetsLength={selectedAssets?.length}
        />
      }
    >
      {getHeader()}
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string,
  siteType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  mode: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  selectedAssets: PropTypes.instanceOf(Array),
  iconType: PropTypes.string,
};

AddNoteDialog.defaultValues = {
  isOpen: false,
  onClose: () => null,
  onConfirm: () => null,
};
