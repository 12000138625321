import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { PageContainer } from '@ge/components/containers';
import { Icon, Icons } from '@ge/components/icon';
import { Loader } from '@ge/components/loader';
import { Select } from '@ge/components/select';
import { DraggableTable } from '@ge/components/table';
import { TooltipCell } from '@ge/components/table/table';
import { useManageColumnVisibility } from '@ge/components/table/use-manage-column-visibility';
import { useTableFactories } from '@ge/components/table/use-table-factories';
import PageHeader from '@ge/feat-monitor/components/page-header';
import { useFeaturePrefs } from '@ge/hooks/feature-prefs';
import {
  Capability,
  AdminDefs,
  FLAGGED_STATES,
  EventManagement,
  DistributionLists,
} from '@ge/models/constants';
import { AuthRender } from '@ge/shared/components/auth-render';
import { ColumnSelectMenu } from '@ge/shared/components/column-select-menu';
import { useEM } from '@ge/shared/data-hooks/use-event-management';
import { useEventMaps } from '@ge/shared/data-hooks/use-handling-procedure';
import { AppScopes } from '@ge/shared/models/scopes';
import { globalColors } from '@ge/tokens';
import SubNavigation from '@ge/web-client/src/app/components/navigation/sub-navigation';

import {
  EventManagementColumnsDefs,
  EventManagementColumns,
} from '../../models/event-management-table-cols';
import { ReactComponent as CheckMark } from '../event-management/assets/tick.svg';
import { PopupMenu } from '../menu-pop-up';

//@custom styles
const AddEMWrapper = styled.div`
  cursor: pointer;
  margin: 0.5em 1em 0 0;
`;

const FlaggedStateIcon = styled.div`
  min-width: 16px;
  min-height: 14px;
  padding: 2px 3px;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
`;

const StyledDraggableTable = styled(DraggableTable)`
  th:not(:last-child),
  td:not(:last-child) {
    border-right: solid 1px ${(props) => props.theme.subNavigation.linkBorderColor} !important;
  }
  td{
    &.group-last {
      top: 0px !important;
      z-index: 8 !important;
   }
   span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
`;

const SelectWrapper = styled.div`
  text-align: left;
  left: 2em;
  z-index: 1;
  position: absolute;
  transform: translateY(-21px);
  label {
    color: ${({ theme }) => theme.sensorReadingsChart.labelColor};
  }
`;

const HeaderWrap = styled.div`
  margin: 0 0 2.3em 0;
`;

const EllipsisDivWrapper = styled.div`
  max-width: 60%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;

const CellWrapBadge = styled(Badge)`
  float: right;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  size: props.size,
  icon: props.icon,
  color: props.theme.postProcess.iconButton,
}))``;

export const DLMetric = {
  NAME: 'name',
};

//@functional component
const EventManagementTable = ({
  columns,
  onRowSelect,
  setFilterDefs,
  filterDefs,
  openEMSidePanel,
  sortAction,
  sortMetric,
  viewDetail,
  sortDirection,
  setVisibleColumns,
  updateColumnVisibility,
  resetFilter,
  selectedRow,
}) => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [options, setOptions] = useState([]);
  const [selectedMapId, setSelectedMapId] = useState();
  const [currentSearch, setCurrentSearch] = useState({});
  const { t, ready } = useTranslation(['monitor.sites']);
  const { eventManagementLists, filterValues, isFetching, isLoading } = useEM({
    search: currentSearch,
    filters: filterDefs,
    sortDirection,
    sortMetric,
    mapId: selectedMapId?.value,
  });

  const { getEventsMaps, eventMapsLoading } = useEventMaps();
  useEffect(() => {
    setOptions(
      getEventsMaps()?.sort((a, b) =>
        a['label'].localeCompare(b['label'], undefined, { numeric: true }),
      ),
    );
  }, [getEventsMaps]);

  useEffect(() => {
    const record = options.find((rec) => rec.label.match('Energy Storage Site'));

    record && setSelectedMapId(record);
  }, [options]);

  useEffect(() => {
    const paramValues = params.get(DistributionLists.MODE)?.split('__');
    if (
      paramValues &&
      paramValues[0] !== DistributionLists.NEW &&
      paramValues[1] &&
      paramValues[2] &&
      eventManagementLists.length
    ) {
      const mapRecord = options?.find((value) => value.value == paramValues[1]);
      mapRecord && setSelectedMapId(mapRecord);
      const eventRecord = eventManagementLists?.find((value) => value.eventId == paramValues[2]);
      eventRecord && viewDetail({ ...eventRecord, eventMapId: mapRecord?.value });
    }
    // Dont add eventManagementLists due to call stack issue.
  }, [params, viewDetail, options, isFetching]);

  const sortedDirection = useCallback(
    (metric) => (metric === sortMetric ? sortDirection : ''),
    [sortMetric, sortDirection],
  );

  const handleFilterChange = useCallback(
    (_, key, value) => setCurrentSearch({ key, value }),
    [setCurrentSearch],
  );

  const handleFilterApply = useCallback(
    (_, columnKey, value) => setFilterDefs(columnKey, value),
    [setFilterDefs],
  );

  const onEventMapChange = (e) => {
    setSelectedMapId(e);
    resetFilter();
  };

  const customCellFn = useCallback(
    (columnKey, cellValue) => {
      const menuPopupCellFn = (values, emptyText) => {
        let cellElement = `${emptyText || ''}`;
        if (cellValue?.length > 0) {
          let badgeText = '';
          let text = cellValue;

          cellValue = cellValue.split(', ');
          const ellipsisTextLength = 15;

          if (cellValue.length > 1 && text && text.length > ellipsisTextLength + 1) {
            const remainingCount =
              cellValue.length - text.slice(0, ellipsisTextLength).split(', ').length;

            if (remainingCount > 0) {
              badgeText = `+${
                cellValue.length - text.slice(0, ellipsisTextLength).split(', ').length
              }`;
            }
          }

          cellElement =
            !badgeText || cellValue.length === 1 ? (
              <TooltipCell tooltip={text}>{text}</TooltipCell>
            ) : (
              <div>
                <EllipsisDivWrapper>{text}</EllipsisDivWrapper>
                <PopupMenu {...values} data={cellValue}>
                  {cellValue.length > 1 ? (
                    <CellWrapBadge color={globalColors.stone1} label={badgeText} small />
                  ) : (
                    ''
                  )}
                </PopupMenu>
              </div>
            );
        }

        return cellElement;
      };

      switch (columnKey) {
        case EventManagementColumns.EVENT_MAP:
          return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
        case EventManagementColumns.EVENT_CODE:
          return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
        case EventManagementColumns.EVENT_NAME:
          return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
        case EventManagementColumns.INCLUDED_SITES:
          return menuPopupCellFn({ title: 'INCLUDED_SITES', headers: ['Name'] });
        case EventManagementColumns.EXCLUDED_SITES:
          return menuPopupCellFn({ title: 'EXCLUDED_SITES', headers: ['Name'] });
        case EventManagementColumns.MAX_REST_LIMIT:
          return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
        case EventManagementColumns.FLAGGED_STATES:
          return cellValue ? (
            <div style={{ display: 'flex' }}>
              {cellValue.map((item, index) => (
                <FlaggedStateIcon
                  key={index}
                  style={{ backgroundColor: FLAGGED_STATES[item].color }}
                >
                  <TooltipCell tooltip={item}>{FLAGGED_STATES[item].letter}</TooltipCell>
                </FlaggedStateIcon>
              ))}
            </div>
          ) : (
            ''
          );
        case EventManagementColumns.BLOCK_AUTOMATION:
          return cellValue ? <CheckMark /> : '';
        case EventManagementColumns.BLOCK_ALL_COMMANDS:
          return cellValue ? <CheckMark /> : '';
        case EventManagementColumns.DESCRIPTION:
          return cellValue ? <TooltipCell tooltip={cellValue}>{cellValue}</TooltipCell> : '';
        case EventManagementColumns.EVENT_DETAIL:
          return (
            <div>
              <StyledIcon icon={Icons.CHEVRON_RIGHT} size={12} />
            </div>
          );
        default:
          return null;
      }
    },
    [openEMSidePanel, selectedMapId?.value, viewDetail],
  );

  const cellValueMapFn = useCallback((row) => {
    const {
      code,
      name,
      flaggedStates,
      maxResetLimit,
      eventRemarks,
      commandProperties,
      automationProperties,
      excludedsites,
      includedsites,
    } = row;

    return {
      [EventManagementColumns.EVENT_CODE]: +code,
      [EventManagementColumns.EVENT_NAME]: name,
      [EventManagementColumns.INCLUDED_SITES]: includedsites?.value?.join(', '),
      [EventManagementColumns.EXCLUDED_SITES]: excludedsites?.value?.join(', '),
      [EventManagementColumns.MAX_REST_LIMIT]: maxResetLimit || '0',
      [EventManagementColumns.FLAGGED_STATES]: flaggedStates?.value,
      [EventManagementColumns.BLOCK_AUTOMATION]: automationProperties?.blockAutomation,
      [EventManagementColumns.BLOCK_ALL_COMMANDS]: commandProperties?.blockCommands,
      [EventManagementColumns.DESCRIPTION]: eventRemarks,
      [EventManagementColumns.EVENT_DETAIL]: row,
    };
  }, []);

  const [columnGroupFactory, columnFactory, cellFactory] = useTableFactories({
    t,
    columnDefs: EventManagementColumnsDefs,
    sortAction,
    sortedDirection,
    cellValueMapFn,
    customCellFn,
    onFilterApply: handleFilterApply,
    onFilterChange: handleFilterChange,
    filters: filterDefs,
    filterValues,
    draggable: true,
  });

  const { savePrefs, featPrefs } = useFeaturePrefs(AppScopes.ADMIN_CONFIGURE_EVENT_MANAGEMENT);

  const { onVisibilityChange: handleColumnChanges } = useManageColumnVisibility({
    subKey: AdminDefs.EVENT_MANAGEMENT_SORT_STATE_ID,
    featPrefs,
    savePrefs,
    setVisibleColumns,
    updateColumnVisibility,
  });

  const emptyText = useCallback(
    (type) => {
      let text = '';

      if (type === 'title') {
        text = !selectedMapId
          ? 'No Data Selected'
          : eventManagementLists.length === 0
          ? 'No Data Found'
          : '';
      } else {
        text = !selectedMapId
          ? 'Select an Event Map to see existing configurations'
          : eventManagementLists.length === 0
          ? 'Selected Event Map has no existing configurations'
          : '';
      }

      return text;
    },
    [selectedMapId, eventManagementLists],
  );

  if (!ready || eventMapsLoading) {
    return <Loader />;
  }

  return (
    <PageContainer i18nReady={ready}>
      <HeaderWrap>
        <PageHeader title={t('event_management', 'Event Management')} />
      </HeaderWrap>
      <SubNavigation>
        <SelectWrapper>
          <Select
            maxWidth={270}
            minWidth={270}
            primary
            name="eventMap"
            label={t('events.code', 'EVENT MAP')}
            options={options}
            placeholder={t('events.code', 'Select Event Map')}
            value={selectedMapId}
            onChange={onEventMapChange}
          />
        </SelectWrapper>
        <AuthRender
          capability={Capability.ALARM_MANAGEMENT}
          admin
          description="create new Event"
          siteLevel={false}
        >
          <AddEMWrapper onClick={(e) => openEMSidePanel(e, EventManagement.NEW)}>
            {t('new_event', '+ ADD EVENT')}
          </AddEMWrapper>
        </AuthRender>
        <ColumnSelectMenu
          translateFn={t}
          columnDef={EventManagementColumnsDefs}
          columnState={columns}
          onMenuClose={handleColumnChanges}
        />
      </SubNavigation>
      {!ready || isLoading ? (
        <Loader />
      ) : (
        <StyledDraggableTable
          scrollable={true}
          columns={columns}
          noData={!eventManagementLists.length}
          noDataDescription={emptyText()}
          noDataTitle={emptyText('title')}
          onRetry={null}
          values={eventManagementLists}
          columnGroupFactory={columnGroupFactory}
          columnFactory={columnFactory}
          sortAction={sortAction}
          cellFactory={cellFactory}
          onValueSelect={(...arg) => onRowSelect(...arg, selectedMapId)}
          rowKeyProperty={['sourceEventId']}
          rowsSelected={selectedRow}
          dropHandler={(columnValue) =>
            savePrefs(columnValue, AdminDefs.EVENT_MANAGEMENT_SORT_STATE_ID)
          }
        />
      )}
    </PageContainer>
  );
};

EventManagementTable.propTypes = {
  onDrop: PropTypes.func,
  resetFilter: PropTypes.any,
  sortAction: PropTypes.func,
  scrollable: PropTypes.bool,
  viewDetail: PropTypes.func,
  onRowSelect: PropTypes.func,
  className: PropTypes.string,
  sortMetric: PropTypes.string,
  filterDefs: PropTypes.object,
  setFilterDefs: PropTypes.func,
  filterValues: PropTypes.object,
  openEMSidePanel: PropTypes.func,
  sortDirection: PropTypes.string,
  selectedSiteId: PropTypes.string,
  selectedAssetId: PropTypes.string,
  setVisibleColumns: PropTypes.func,
  theme: PropTypes.instanceOf(Object),
  updateColumnVisibility: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  selectedRow: PropTypes.array,
};

EventManagementTable.defaultProps = {
  filterDefs: {},
  selectedSiteId: '',
  selectedAssetId: '',
  sortAction: () => null,
  sortMetric: '',
  sortDirection: '',
};

export default EventManagementTable;
