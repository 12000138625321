import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PartsField } from '@ge/models/constants';
import { TaskTemplateModes } from '@ge/models/constants';

import { Parts } from '../../../task-fields/parts';
import { FormSection, StyledCollapsiblePanel } from '../../../task-template-shared';

export const CreateExpectedParts = ({ collapsed, metadata, templateMode }) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });

  return (
    <StyledCollapsiblePanel
      expanded={!collapsed}
      headerContent={<h4>{t('section_header.expected_parts', 'Expected Parts')}</h4>}
      plusIcon
    >
      <FormSection>
        {!metadata?.expectedParts.hidden && (
          <Parts
            metadata={metadata.expectedParts.create}
            fieldName={PartsField.EXPECTED_PARTS}
            templateMode={templateMode}
          />
        )}
      </FormSection>
    </StyledCollapsiblePanel>
  );
};

CreateExpectedParts.propTypes = {
  collapsed: PropTypes.bool,
  metadata: PropTypes.object.isRequired,
  templateMode: PropTypes.oneOf(Object.values(TaskTemplateModes)).isRequired,
};

CreateExpectedParts.defaultProps = {
  collapsed: true,
};
