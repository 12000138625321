import { PropTypes } from 'prop-types';
import sort from 'ramda/src/sort';
import React, { forwardRef, useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionalRender } from '@ge/components/conditional-render';
import {
  Menu,
  MenuAllButton,
  MenuChevron,
  MenuLoader,
  MenuTable,
  MenuTitle,
} from '@ge/components/menu';
import { EntityDetailsContext } from '@ge/shared/context/entity-details-context';
import { killEventPropagation } from '@ge/shared/util/general';
import { getDuration } from '@ge/shared/util/time-date';

const DISPLAY_ASSET_COUNT = 5;
{
  /* #366 / #367 - removing for MVP0 */
}
// const TasksWrapper = styled.div`
//   display: flex;
//   div:not(:first-child) {
//     margin-left: 3px;
//   }
// `;

// const StyledBadge = styled(Badge).attrs((props) => ({
//   color:
//     props.theme.tasks.backgroundColor[props.label] || props.theme.tasks.backgroundColor.default,
// }))``;

// eslint-disable-next-line react/display-name
const AssetStateMenu = forwardRef(({ anchorEl, open, site, assets, title, onClose }, ref) => {
  const { t } = useTranslation(['monitor.sites']);
  const { showSiteDetails, showAssetDetails } = useContext(EntityDetailsContext);

  const count = useMemo(() => assets.length, [assets]);

  const getDurationFormatted = (start) => `${getDuration(start).formatted}`;

  /**
   * Memoize the last 5 assets sorted by duration.
   */
  const trimmedAssets = useMemo(
    () =>
      sort((a, b) => {
        const {
          metrics: { stateUpdated: stateUpdatedA },
        } = a;
        const {
          metrics: { stateUpdated: stateUpdatedB },
        } = b;
        const durationA = getDuration(stateUpdatedA).ms;
        const durationB = getDuration(stateUpdatedB).ms;

        return durationB - durationA;
      }, assets).slice(0, DISPLAY_ASSET_COUNT),
    [assets],
  );

  const handleAssetSelectedClick = useCallback(
    (e, id) => {
      killEventPropagation(e);
      showAssetDetails(id);
      onClose();
    },
    [showAssetDetails, onClose],
  );

  const handleAssetSeeAllClick = useCallback(
    (e) => {
      killEventPropagation(e);
      showSiteDetails(site.id);
      onClose();
    },
    [showSiteDetails, site.id, onClose],
  );

  return (
    <Menu anchorEl={anchorEl} open={open} container={ref} offset={10}>
      <MenuTitle title={title} count={count} />
      <ConditionalRender shouldRender={Boolean(anchorEl) && trimmedAssets.length === 0}>
        <MenuLoader />
      </ConditionalRender>
      <ConditionalRender shouldRender={Boolean(anchorEl) && trimmedAssets.length > 0}>
        <MenuTable>
          <table>
            <thead>
              <tr>
                <th>{t('table.asset', 'Asset')}</th>
                <th>{t('table.duration', 'Duration')}</th>
                {/* #366 / #367 - removing for MVP0 */}
                {/* <th>{t('table.tasks', 'Tasks')}</th> */}
                <th aria-label="actions" />
              </tr>
            </thead>
            <tbody>
              {trimmedAssets &&
                trimmedAssets.length &&
                trimmedAssets.map((asset) => {
                  const { stateUpdated } = asset.metrics || {};

                  return (
                    <tr
                      key={asset.id}
                      onClick={(e) => {
                        handleAssetSelectedClick(e, asset.id);
                      }}
                    >
                      <td>{asset.name}</td>
                      <td>{stateUpdated && getDurationFormatted(stateUpdated)}</td>
                      {/* #366 / #367 - removing for MVP0 */}
                      {/* <td style={{ textAlign: 'center' }}>
                        <TasksWrapper>
                          {tasks?.length > 0 &&
                            tasks.map((task) => (
                              <StyledBadge key={task.id} label={task.type.charAt(0)} small />
                            ))}
                        </TasksWrapper>
                      </td> */}
                      <td>
                        <MenuChevron />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </MenuTable>
        {trimmedAssets && trimmedAssets.length && (
          <MenuAllButton onClick={(e) => handleAssetSeeAllClick(e)}>
            {t('table.see_all', 'See All')}
          </MenuAllButton>
        )}
      </ConditionalRender>
    </Menu>
  );
});

AssetStateMenu.propTypes = {
  open: PropTypes.bool,
  assets: PropTypes.instanceOf(Object),
  site: PropTypes.instanceOf(Object),
  anchorEl: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  onClose: PropTypes.func,
};

AssetStateMenu.defaultProps = {
  open: false,
  title: null,
  assets: null,
  site: null,
  anchorEl: null,
  onClose: () => null,
};

export default AssetStateMenu;
