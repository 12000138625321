import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSection } from '../../../task-template-shared';

import { CompletedTechsSection } from './completedtechs-section';

export const CreateAssignedTechs = ({
  metadata,
  task,
  workerState,
  setWorkerState,
  removeWorker,
  deleteWrkrIds,
  isLoadingMultiplePerson,
  selectedWorkers,
}) => {
  const { t } = useTranslation(['tasks'], { useSuspense: false });
  return (
    <>
      <div className="task-panel-row">
        <FormSection>
          <h4>{t('section_header.assigned_crews', 'Assigned crews')}</h4>
          {!metadata?.completedByTech.hidden && (
            <CompletedTechsSection
              modalType="newTask"
              techType="completed"
              section={metadata.completedByTech.create}
              task={task}
              workerState={workerState}
              selectedWorkers={selectedWorkers}
              setWorkerState={setWorkerState}
              removeWorker={removeWorker}
              deleteWrkrIds={deleteWrkrIds}
              isLoadingMultiplePerson={isLoadingMultiplePerson}
            />
          )}
        </FormSection>
      </div>
    </>
  );
};

CreateAssignedTechs.propTypes = {
  metadata: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  selectedWorkers: PropTypes.instanceOf(Object),
  workerState: PropTypes.instanceOf(Object),
  setWorkerState: PropTypes.func,
  removeWorker: PropTypes.func,
  deleteWrkrIds: PropTypes.instanceOf(Object),
  isLoadingMultiplePerson: PropTypes.instanceOf(Boolean).isRequired,
};

CreateAssignedTechs.defaultProps = {
  handleTaskType: () => null,
};
