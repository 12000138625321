import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { FileUpload } from '@ge/components/file-upload';
import { ReportsContext } from '@ge/feat-reporting/context/reports-context';
import { FileType } from '@ge/models/constants';

// can these props come from the template (config section for widget)?
// if not provided by backend, could define in bff for now...
// going with what is currently defined for the notes section uploader as this was agreed upon behavior for mvp
const DefaultProps = {
  allowedFileTypes: [
    FileType.JPEG,
    FileType.PNG,
    FileType.XLS,
    FileType.XLSX,
    FileType.PPT,
    FileType.PPTX,
    FileType.PDF,
  ],
  maxFiles: 5,
  maxFileSizeMb: 8,
};

export const AttachmentsModule = ({
  allowedFileTypes = DefaultProps.allowedFileTypes,
  maxFiles = DefaultProps.maxFiles,
  maxFileSizeMb = DefaultProps.maxFileSizeMb,
} = DefaultProps) => {
  const { attachments, setAttachments } = useContext(ReportsContext);
  const fileUploadRef = useRef();
  const [localAttachments, setLocalAttachments] = useState([]);

  // Attachments returns a bunch of times, so limiting the logic.
  // Syncing the attachments in the context with the local state.
  // If they differ, proceed with syncing AND if the context ever has an empty state
  // reset the files within the FileUpload component.
  // The reset occurs when a user presses the "Restore Defaults" button.
  useEffect(() => {
    if (localAttachments.length === attachments.length) {
      return;
    }

    if (attachments.length === 0) {
      fileUploadRef.current.resetFiles();
    }

    setLocalAttachments(attachments);
  }, [attachments, localAttachments]);

  return (
    <>
      <FileUpload
        ref={fileUploadRef}
        allowedFileTypes={allowedFileTypes}
        maxFiles={maxFiles}
        maxFileSizeMb={maxFileSizeMb}
        onUpload={setAttachments}
      />
    </>
  );
};

AttachmentsModule.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  maxFileSizeMb: PropTypes.number,
};
