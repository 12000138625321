import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Icon, Icons } from '@ge/components/icon';
import {
  useSitesTableDownload,
  useAssetsTableDownload,
  useAssetsTableDownloadStorage,
} from '@ge/feat-analyze/data-hooks/use-table-download';
import { EntityType } from '@ge/models/constants';
import { globalColors } from '@ge/tokens';

import ExportFileModal from '../modals/export-graph-modal/export-graph-modal-basic-view';

const DownloadIcon = styled(Icon).attrs(() => ({
  size: 12,
  color: globalColors.slate4,
}))``;

const StyledButton = styled.button`
  color: ${globalColors.slate4};
  background: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  padding: 0;
  svg {
    margin: 0;
    display: flex;
  }
`;

export const DownloadTableButton = ({
  isLoading,
  data,
  columns,
  entity,
  entityType,
  dateRange,
  className,
}) => {
  const { downloadCSV: downloadSiteTable } = useSitesTableDownload();
  const { downloadCSV: downloadAssetTable } = useAssetsTableDownload();
  const { downloadCSV: downloadAssetTableStorage } = useAssetsTableDownloadStorage();

  const [isModalOpen, setModal] = useState(false);
  const [radioSelected, setradioSelected] = useState('csv');

  const handleShow = () => setModal(true);
  const handleClose = () => setModal(false);
  const putRadioSelected = (param) => {
    setradioSelected(param);
  };
  const onClick = useCallback(() => {
    const params = { data, columns, entity, entityType, dateRange, radioSelected };
    setModal(false);
    switch (entityType) {
      case EntityType.REGION:
      case EntityType.SITES:
        return downloadSiteTable(params);
      case EntityType.SITE:
        return downloadAssetTable(params);
      case EntityType.STORAGE_SITE:
      case EntityType.ANOMALY:
      case EntityType.MANUAL_ADJUSTMENTS:
        return downloadAssetTableStorage(params);
      default:
        return null;
    }
  }, [
    data,
    columns,
    entity,
    entityType,
    dateRange,
    radioSelected,
    downloadSiteTable,
    downloadAssetTable,
    downloadAssetTableStorage,
  ]);

  if (isLoading || !entityType) return null;

  return (
    <>
      <ExportFileModal
        onSubmit={onClick}
        getRadioSelected={putRadioSelected}
        isOpen={isModalOpen}
        onClose={handleClose}
      ></ExportFileModal>
      <StyledButton className={className} onClick={handleShow} type="button">
        <DownloadIcon viewbox={'0 0 12 12'} icon={Icons.EXPORT} />
      </StyledButton>
    </>
  );
};

DownloadTableButton.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  className: PropTypes.string,
};

DownloadTableButton.defaultProps = {
  isLoading: true,
  data: [],
  columns: [],
  entity: null,
  entityType: null,
  dateRange: {},
  className: null,
};
