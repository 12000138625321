import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Legend, LegendKey } from '@ge/components/charts';

const NAMESPACE = 'analyze.dashboard';

export const DashboardChartLegend = ({ namespace, legend }) => {
  const { ready, t } = useTranslation([namespace], {
    useSuspense: false,
  });

  const translations = useMemo(
    () => ({
      ...legend.reduce(
        (names, { name }) => ({
          ...names,
          [name]: t(name),
        }),
        {},
      ),
    }),
    [legend, t],
  );

  if (!ready) {
    return null;
  }

  return (
    <Legend>
      {legend.map(({ color, name }) => (
        <LegendKey key={name} name={translations[name]} symbolColor={color} />
      ))}
    </Legend>
  );
};

DashboardChartLegend.propTypes = {
  namespace: PropTypes.string,
  legend: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
};

DashboardChartLegend.defaultProps = {
  namespace: NAMESPACE,
  legend: [],
};

DashboardChartLegend.displayName = 'DashboardChartLegend';

export default DashboardChartLegend;
