import PropTypes from 'prop-types';
import React from 'react';

import { KpiChartConatinerTitleStyled } from './kpi-chart-container-title-styled';

export const KpiChartConatinerTitle = (props) => {
  const { id, title } = props;
  return <KpiChartConatinerTitleStyled data-testid={id}>{title}</KpiChartConatinerTitleStyled>;
};

KpiChartConatinerTitle.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
};
