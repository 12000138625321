import { PropTypes } from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Badge } from '@ge/components/badge';
import { Button } from '@ge/components/button';
import { Icons } from '@ge/components/icon';
import { MiniLoader } from '@ge/components/loader';
import { Dialog } from '@ge/components/modal';
import { ScrollingContainer } from '@ge/components/scrolling-container';
import { useGetEventNotes } from '@ge/shared/data-hooks/';
import { EntityDialogHeader } from '@ge/web-client/src/app/components/entity-details/entity-dialog-header';

import { CaseNoteItem } from './case-note-item';

const StyledBadge = styled(Badge).attrs((props) => ({
  color: props.theme.entityDetails.specialInstructions.badgeCount,
}))`
  background: ${(props) => props.theme.entityDetails.specialInstructions.badgeBackground};
  font-size: 11px;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
`;

const NotesTitle = styled.span`
  margin-right: 8px;
`;

const StyledDialog = styled(Dialog)`
  section {
    padding: 20px 0px 20px 0px;
  }
`;

const CaseNotesDialogsegment = styled.div`
  margin: 0px;
  padding: 6px 15px 0px 25px;
  &:first-of-type:not(.note-item:first-of-type) {
    padding: 0px 20px 16px 25px;
    border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
  }
  &:last-of-type {
    border-bottom: 0;
    p {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
  &.note-item:first-of-type {
    margin-top: 15px;
  }
`;

const CaseNotesFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: flex-end;
`;

const EmptyNotes = styled.div`
  padding: 16px;
  margin: 0px 30px;
  border-bottom: 1px solid ${(props) => props.theme.entityDetails.notes.separator};
`;

const FooterButton = styled.div`
  margin: 0px 5px 0px 0px;
  span {
    padding-top: 3px;
  }
`;

const scrollbarWrapperOverride = `
  .simplebar-content-wrapper {
    height: 100% !important;
  }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  min-height: 46vh;
  overflow: hidden;
  [data-simplebar='init'] {
    padding-right: 10px;
  }
  ${scrollbarWrapperOverride}
`;

export const CaseNotesDialog = ({ isOpen, onClose, entity, siteType }) => {
  const { t } = useTranslation(['entity-details']);
  const { data, isLoading } = useGetEventNotes({ id: entity?.id });
  const [clicked, setClicked] = useState('');

  const entityDetails = {
    assetType: entity?.asset?.type,
    siteName: entity?.site?.name,
    siteType: siteType,
    assetName: entity?.asset?.name,
    entityDescriptionIcon: Icons.EVENT,
    entityDescription: entity?.description,
  };

  const getHeader = useMemo(() => {
    return (
      <>
        <NotesTitle>{t('notes.case_notes', 'Case Notes')}</NotesTitle>
        {data?.notes?.length && (
          <StyledBadge className="total-task-count" label={data?.notes?.length} small />
        )}
      </>
    );
  }, [t, data]);

  const getFooter = useMemo(() => {
    return (
      <CaseNotesFooterWrapper>
        <FooterButton>
          <Button onClick={onClose}>{t('general:close', 'Close')}</Button>
        </FooterButton>
      </CaseNotesFooterWrapper>
    );
  }, [t, onClose]);

  return (
    <>
      <StyledDialog isOpen={isOpen} onClose={onClose} header={getHeader} footer={getFooter}>
        <CaseNotesDialogsegment>
          <EntityDialogHeader entityDetails={entityDetails} />
        </CaseNotesDialogsegment>
        <ScrollingWrapper>
          <ScrollingContainer>
            {isLoading ? (
              <MiniLoader />
            ) : Array.isArray(data?.notes) && data?.notes.length ? (
              data.notes.map((noteItem, id) => (
                <CaseNotesDialogsegment key={id} className="note-item">
                  <CaseNoteItem
                    noteItem={noteItem}
                    onToggle={() => setClicked(id)}
                    active={clicked === id}
                    setInactive={setClicked}
                    entity={entity}
                  />
                </CaseNotesDialogsegment>
              ))
            ) : (
              <EmptyNotes>{t('notes.no_notes', 'No information added.')}</EmptyNotes>
            )}
          </ScrollingContainer>
        </ScrollingWrapper>
      </StyledDialog>
    </>
  );
};

CaseNotesDialog.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  siteType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

CaseNotesDialog.defaultValues = {
  entity: null,
  isOpen: false,
  onClose: () => null,
};
