import { useStoreState, useStoreActions } from 'easy-peasy';
import React from 'react';
import styled from 'styled-components';

import { Tab, Tabs } from '@ge/components/tabs';

import PersonAssetsComponent from '../person-assets-components/person-assets-component';

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
  padding-left: 10px;
  z-index: 1;
`;

const cleanString = (item) => item.replace(/_/g, ' ');

const convertString = (item) => {
  if (item) {
    let newString = '';
    for (let i = 0; i < item.length; i++) {
      if (item[i] === ' ') {
        newString += '-';
      } else if (item[i] === '_') {
        newString += '-';
      } else {
        newString += item[i];
      }
    }
    return newString.toLowerCase();
  } else {
    return '';
  }
};

const TabsGenerator = () => {
  const { tabIterator, activeTab } = useStoreState((state) => state.tabflow.personAssets);
  const { onChangeTabHandler } = useStoreActions((actions) => actions.tabflow);

  const RenderList = tabIterator.map((item, index) => (
    <Tab label={cleanString(item)} key={index}>
      <PersonAssetsComponent />
    </Tab>
  ));

  return (
    <StyledTabs
      small
      onChangeHandler={(e) => onChangeTabHandler(e)}
      defaultTab={convertString(tabIterator[activeTab])}
    >
      {RenderList}
    </StyledTabs>
  );
};

export default TabsGenerator;
