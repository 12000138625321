import { useStoreState, useStoreActions } from 'easy-peasy';
import React from 'react';
import { useState } from 'react';
import { withTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import { Checkbox, CheckedState } from '@ge/components/checkbox';
import { Icon, Icons } from '@ge/components/icon';
import { TabFlowModelVariables } from '@ge/models/constants';

import PersonAssetsSitesComponent from '../person-assets-sites/person-assets-sites-component';

const parentRowDisable = css`
  opacity: 0.7;
`;

const ListItemWrapper = styled.div`
  ${({ isDisabled }) => {
    if (isDisabled) return parentRowDisable;
  }}
`;

const PersonAssetsContentComponent = withTheme(({ theme, item }) => {
  const {
    editStatus,
    viewByServiceGroupsOrSites,
    activeTab,
    selectedServiceGroupIds,
    selectedSiteIds,
    role,
  } = useStoreState((state) => state.tabflow.personAssets);
  const { setCheckBox } = useStoreActions((actions) => actions.tabflow);
  const [expandedItems, setExpandedItems] = useState([]);
  const expandItem = (item) => {
    if (expandedItems.includes(item.id)) {
      setExpandedItems((prevItems) => prevItems.filter((expandedId) => expandedId !== item.id));
    } else {
      setExpandedItems((prevItems) => [...prevItems, item.id]);
    }
  };
  const CheckExpanded = (item) => {
    return expandedItems.includes(item.id) ? 0 : -90;
  };

  const ShowExpanded = (item) => {
    return expandedItems.includes(item.id) ? true : false;
  };

  const ChangeCheckBoxState = (entity, checkBoxState) => {
    const childArray = entity.sites.map((child) => child.id);
    if (
      activeTab === 0 &&
      (role === TabFlowModelVariables.ROLESTYPE.WORKER ||
        role === TabFlowModelVariables.ROLESTYPE.USER_AND_WORKER)
    ) {
      setCheckBox({
        id: entity.id,
        permission: { idsIndex: activeTab, viewBy: viewByServiceGroupsOrSites },
        currentState: checkBoxState,
      });
    } else {
      setCheckBox({
        id: entity.id,
        permission: { idsIndex: activeTab, viewBy: viewByServiceGroupsOrSites },
        currentState: checkBoxState,
        siteIds: childArray,
      });
    }
  };
  const getCheckedState = () => {
    return selectedServiceGroupIds?.includes(item.id)
      ? CheckedState.CHECKED
      : CheckedState.UNCHECKED;
  };

  const getDisabledState = (entity) => {
    if (selectedServiceGroupIds?.includes(item.id)) {
      return false;
    }
    const childArray = entity.sites?.map((child) => child.id);
    if (childArray?.some((childId) => selectedSiteIds.includes(childId))) {
      return true;
    }
    return false;
  };

  const content = (
    <>
      {
        // groups not edit mode
        editStatus === false && (
          <div className="person-assets-list-container-view">
            <div>
              <Icon icon={Icons.SERVICE_GROUP} size={11} color={theme.admin.panel.entityRow.icon} />
            </div>
            <div>{item.name}</div>
            <div className="person-assets-assets-center-text">{item.sites.length}</div>
          </div>
        )
      }

      {editStatus && (
        <ListItemWrapper
          isDisabled={getDisabledState(item)}
          className="person-assets-list-container"
          key={item.name}
        >
          <div
            onClick={() => expandItem(item)}
            className="person-assets-assets-caret-curson-pointer"
          >
            <Icon
              icon={Icons.CARET}
              size={8}
              rotate={CheckExpanded(item)}
              color={theme.admin.panel.entityRow.icon}
            />
          </div>

          <Checkbox
            checkState={getCheckedState(item)}
            onChange={(e) => ChangeCheckBoxState(item, e)}
            disabled={getDisabledState(item)}
          />

          <div>
            <Icon icon={Icons.SERVICE_GROUP} size={11} color={theme.admin.panel.entityRow.icon} />
          </div>
          <div className="person-assets-assets-text-margin">{item.name}</div>
          <div className="person-assets-assets-text-margin person-assets-assets-center-text">
            {item.sites?.length ? item.sites?.length : 1}
          </div>
        </ListItemWrapper>
      )}

      {editStatus === false && ShowExpanded(item) && (
        <div className="person-assets-sites-sublist">
          <div>
            <Icon icon={Icons.TURBINE} size={11} color={theme.admin.panel.entityRow.icon} />
          </div>
          <div>{item.name}</div>
          <div>{item.sites.length}</div>
        </div>
      )}

      {editStatus && ShowExpanded(item) && (
        <PersonAssetsSitesComponent list={item.sites} theme={theme} />
      )}
    </>
  );

  return content;
});

export default PersonAssetsContentComponent;
