import { ChartType } from '@ge/components/charts';
import { TimeAggr } from '@ge/models/constants';
import { globalColors } from '@ge/tokens/colors';

import { KpiCategorySeriesType, KPIList } from '../models';

export const solarSeverityOptions = [
  {
    id: 'severity',
    default: 1,
    options: [
      {
        value: 1,
        label: 'Critical',
      },
      {
        value: 2,
        label: 'Warning',
      },
      {
        value: 3,
        label: 'Info',
      },
      {
        value: 4,
        label: 'All Events',
      },
    ],
  },
];

/**
 * RULES
 *
 *
 *
 *
 */
export const solarKPISelectorData = [
  {
    id: 'performance',
    value: 'Performance',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'power-trend',
        value: 'Power Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'energy-trend',
        value: 'Energy Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'capacity-factor-trend',
        value: 'Capacity Factor Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'yield-trend',
        value: 'Yield Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'pr-trend',
        value: 'PR Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
  {
    id: 'availability',
    value: 'Availability',
    component: {
      name: null,
      props: null,
      path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
    },
    childList: [
      {
        id: 'availability-trend',
        value: 'Availability Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
  {
    id: 'revenue',
    value: 'Revenue',
    component: {
      name: null,
      props: null,
      path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
    },
    childList: [
      {
        id: 'revenue',
        value: 'Revenue',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
  {
    id: 'reliability',
    value: 'Reliability',
    component: {
      props: null,
      path: 'dynamic-components/site/reliability/reliability',
    },
    filterList: [
      {
        id: 'reportBy',
        options: [
          { value: 'duration', label: 'Event Duration' },
          { value: 'count', label: 'Event Occurrence' },
          { value: 'mttr', label: 'MTTR' },
          //commenting as per suggestion for some point of time
          // { value: 'mtbf', label: 'MTBF' },
        ],
      },
      ...solarSeverityOptions,
    ],
  },
  {
    id: 'weather',
    value: 'Weather',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'weather-trend',
        value: 'Weather Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
];

export const solarAssetKPISelectorData = [
  {
    id: 'performance',
    value: 'Performance',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'power-trend',
        value: 'Power Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'energy-trend',
        value: 'Energy Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'capacity-factor-trend',
        value: 'Capacity Factor Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
      {
        id: 'yield-trend',
        value: 'Yield Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },
  {
    id: 'availability',
    value: 'Availability',
    component: {
      name: null,
      props: null,
    },
    childList: [
      {
        id: 'availability-trend',
        value: 'Availability Trend',
        component: {
          props: null,
          path: 'dynamic-components/hybrids-kpi-chart/hybrids-kpi-chart',
        },
        filterList: [],
      },
    ],
    filterList: [],
  },

  {
    id: 'reliability',
    value: 'Reliability',
    component: {
      props: null,
      path: 'dynamic-components/site/reliability/reliability',
    },
    filterList: [
      {
        id: 'reportBy',
        options: [
          { value: 'duration', label: 'Event Duration' },
          { value: 'count', label: 'Event Occurrence' },
          { value: 'mttr', label: 'MTTR' },
        ],
      },
      ...solarSeverityOptions,
    ],
  },
];

const assetSolarPerformanceOptions = {
  'energy-trend': {
    kpiKeys: [
      {
        key: KPIList.ENERGY_TREND,
        type: ChartType.COLUMN,
        color: globalColors.pine5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 1,
    timeAggr: TimeAggr.DAILY,
  },
  'power-trend': {
    kpiKeys: [
      {
        key: KPIList.ACTUAL_POWER,
        type: ChartType.LINE,
        color: globalColors.pine5,
        yAxis: 0,
      },
      {
        key: KPIList.IRRADIANCE,
        type: ChartType.LINE,
        color: globalColors.straw4,
        yAxis: 1,
      },
    ],
    type: ChartType.LINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
  },
  'capacity-factor-trend': {
    kpiKeys: [
      {
        key: KPIList.CAPACITY_FACTOR_TREND,
        type: ChartType.COLUMN,
        color: globalColors.terracotta4,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
  'yield-trend': {
    kpiKeys: [
      {
        key: KPIList.YIELD_TREND,
        type: ChartType.COLUMN,
        color: globalColors.terracotta5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
  'pr-trend': {
    kpiKeys: [
      {
        key: KPIList.PR_TREND,
        type: ChartType.COLUMN,
        color: globalColors.magenta3,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
};

const assetSolarAvailabilityOptions = {
  'availability-trend': {
    kpiKeys: [
      {
        key: KPIList.AVAILABILITY_TREND,
        type: ChartType.COLUMN,
        color: globalColors.straw5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
};

const assetSolarWeatherOptions = {
  'weather-trend': {
    kpiKeys: [
      {
        key: KPIList.IRRADIANCE,
        type: ChartType.SPLINE,
        color: globalColors.straw4,
        yAxis: 0,
      },
      {
        key: KPIList.AMBIENT_TEMPERATURE,
        type: ChartType.SPLINE,
        color: globalColors.terracotta3,
        yAxis: 1,
      },
      {
        key: KPIList.MODULE_TEMPERATURE,
        type: ChartType.SPLINE,
        color: globalColors.terracotta5,
        yAxis: 1,
      },
    ],
    type: ChartType.SPLINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 2,
    timeAggr: TimeAggr.DAILY,
  },
};

export const assetSolarChartConfig = {
  ['performance']: assetSolarPerformanceOptions,
  ['availability']: assetSolarAvailabilityOptions,
  ['weather']: assetSolarWeatherOptions,
};

const siteSolarPerformanceOptions = {
  'energy-trend': {
    kpiKeys: [
      {
        key: KPIList.ENERGY_TREND,
        type: ChartType.COLUMN,
        color: globalColors.pine5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 1,
    timeAggr: TimeAggr.DAILY,
  },
  'power-trend': {
    kpiKeys: [
      {
        key: KPIList.ACTUAL_POWER,
        type: ChartType.SPLINE,
        color: globalColors.pine5,
        yAxis: 0,
      },
      {
        key: KPIList.IRRADIANCE,
        type: ChartType.SPLINE,
        color: globalColors.straw4,
        yAxis: 1,
      },
    ],
    type: ChartType.SPLINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
  },
  'capacity-factor-trend': {
    kpiKeys: [
      {
        key: KPIList.CAPACITY_FACTOR_TREND,
        type: ChartType.COLUMN,
        color: globalColors.terracotta4,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
  'yield-trend': {
    kpiKeys: [
      {
        key: KPIList.YIELD_TREND,
        type: ChartType.COLUMN,
        color: globalColors.terracotta5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
  'pr-trend': {
    kpiKeys: [
      {
        key: KPIList.PR_TREND,
        type: ChartType.COLUMN,
        color: globalColors.magenta3,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
};

const siteSolarAvailabilityOptions = {
  'availability-trend': {
    kpiKeys: [
      {
        key: KPIList.AVAILABILITY_TREND,
        type: ChartType.COLUMN,
        color: globalColors.straw5,
        yAxis: 0,
      },
    ],
    type: ChartType.COLUMN,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
    yAxisDisplay: 1,
  },
};
const siteSolarRevenueOptions = {
  revenue: {
    kpiKeys: [
      {
        key: KPIList.REVENUE,
        type: ChartType.SPLINE,
        color: globalColors.mint2,
        yAxis: 0,
      },
      {
        key: KPIList.ENERGY_TREND,
        type: ChartType.SPLINE,
        color: globalColors.pine5,
        yAxis: 1,
      },
    ],
    type: ChartType.SPLINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    timeAggr: TimeAggr.DAILY,
  },
};

const siteSolarWeatherOptions = {
  'weather-trend': {
    kpiKeys: [
      {
        key: KPIList.IRRADIANCE,
        type: ChartType.SPLINE,
        color: globalColors.straw4,
        yAxis: 0,
      },
      {
        key: KPIList.AMBIENT_TEMPERATURE,
        type: ChartType.SPLINE,
        color: globalColors.terracotta3,
        yAxis: 1,
      },
      {
        key: KPIList.MODULE_TEMPERATURE,
        type: ChartType.SPLINE,
        color: globalColors.terracotta5,
        yAxis: 1,
      },
    ],
    type: ChartType.SPLINE,
    seriesType: KpiCategorySeriesType.TIME_SERIES,
    yAxisDisplay: 2,
    timeAggr: TimeAggr.DAILY,
  },
};

export const siteSolarChartConfig = {
  ['performance']: siteSolarPerformanceOptions,
  ['availability']: siteSolarAvailabilityOptions,
  ['revenue']: siteSolarRevenueOptions,
  ['weather']: siteSolarWeatherOptions,
};
