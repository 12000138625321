import { styled } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useRef, useMemo, useContext, useState } from 'react';

import { useEventCallback } from '@ge/shared/hooks';

import { pointPropType } from '../../constants';

var StyledImage = styled('img')({
  zIndex: 0,
  position: 'absolute',
  opacity: 0,
});

var StyledSvg = styled('svg')({
  position: 'absolute',
});

const EnhancedImageCanvas = ({ SliderValueContext, imageSrc, imagePosition, onLoad }) => {
  const [filterId] = useState('url(#filterStack)');
  const imageRef = useRef();
  const {
    sliderValue: { weight, gamma, brightness, contrast, invert, grayscale },
  } = useContext(SliderValueContext);

  const stylePosition = useMemo(() => {
    const width = imagePosition.bottomRight.x - imagePosition.topLeft.x;
    const height = imagePosition.bottomRight.y - imagePosition.topLeft.y;
    return {
      imageRendering: 'pixelated',
      left: imagePosition.topLeft.x,
      top: imagePosition.topLeft.y,
      width: isNaN(width) ? 0 : width,
      height: isNaN(height) ? 0 : height,
    };
  }, [
    imagePosition.topLeft.x,
    imagePosition.topLeft.y,
    imagePosition.bottomRight.x,
    imagePosition.bottomRight.y,
  ]);

  const onImageLoaded = useEventCallback((event) => {
    // const ctx = canvasRef.current.getContext('2d');
    // ctx.drawImage(imageRef.current, 0, 0);
    const imageElm = event.currentTarget;
    if (onLoad)
      onLoad({
        naturalWidth: imageElm.naturalWidth,
        naturalHeight: imageElm.naturalHeight,
        imageElm: imageElm,
      });
  });

  return (
    <div>
      <StyledImage ref={imageRef} src={imageSrc} style={stylePosition} onLoad={onImageLoaded} />
      <StyledSvg
        width={stylePosition.width}
        height={stylePosition.height}
        style={stylePosition}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <filter id="filterStack" x="0" y="0" width="100%" height="100%">
            <feColorMatrix
              in="SourceGraphic"
              result="grayscaleResult"
              type="matrix"
              values={`
            ${0.2126 + 0.7874 * (1 - grayscale)} ${0.7152 - 0.7152 * (1 - grayscale)} ${
                0.0722 - 0.0722 * (1 - grayscale)
              } 0 0
            ${0.2126 - 0.2126 * (1 - grayscale)} ${0.7152 + 0.2848 * (1 - grayscale)} ${
                0.0722 - 0.0722 * (1 - grayscale)
              } 0 0
            ${0.2126 - 0.2126 * (1 - grayscale)} ${0.7152 - 0.7152 * (1 - grayscale)} ${
                0.0722 + 0.9278 * (1 - grayscale)
              } 0 0
            0 0 0 1 0`}
            />
            <feColorMatrix
              type="matrix"
              colorInterpolationFilters="sRGB"
              in="grayscaleResult"
              result="invertResult"
              values={`${1 - 2 * invert} 0 0 0 ${invert}
                      0 ${1 - 2 * invert} 0 0 ${invert}
                      0 0 ${1 - 2 * invert} 0 ${invert}
                      0 0 0 1 0`}
            />
            <feComponentTransfer in="invertResult" result="brightnessResult">
              <feFuncR type="linear" slope={1} intercept={brightness} />
              <feFuncG type="linear" slope={1} intercept={brightness} />
              <feFuncB type="linear" slope={1} intercept={brightness} />
            </feComponentTransfer>
            <feComponentTransfer in="brightnessResult" result="contrastResult">
              <feFuncR type="linear" slope={contrast} intercept={-(0.5 * contrast) + 0.5} />
              <feFuncG type="linear" slope={contrast} intercept={-(0.5 * contrast) + 0.5} />
              <feFuncB type="linear" slope={contrast} intercept={-(0.5 * contrast) + 0.5} />
            </feComponentTransfer>
            <feComponentTransfer in="contrastResult" result="gammaResult">
              <feFuncR type="gamma" amplitude={1} exponent={gamma} offset="0" />
              <feFuncG type="gamma" amplitude={1} exponent={gamma} offset="0" />
              <feFuncB type="gamma" amplitude={1} exponent={gamma} offset="0" />
            </feComponentTransfer>
            <feGaussianBlur
              id="f_gaussianBlur"
              in="gammaResult"
              result="blurResult"
              stdDeviation={weight === 1 || weight === 0 ? 1 : 13}
            />
            <feComposite
              id="f_composite"
              operator="arithmetic"
              k1="0"
              k2={weight}
              k3={1 - weight}
              k4="0"
              in="gammaResult"
              in2="blurResult"
            />
          </filter>
        </defs>
        <image
          x="0"
          y="0"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid meet"
          // filter="url(#filterStack)"
          filter={filterId}
          xlinkHref={imageSrc}
        />
      </StyledSvg>
    </div>
  );
};

EnhancedImageCanvas.propTypes = {
  imageSrc: PropTypes.string,
  imagePosition: PropTypes.shape({
    bottomRight: pointPropType,
    topLeft: pointPropType,
  }),
  onLoad: PropTypes.func,
  SliderValueContext: PropTypes.any,
};

EnhancedImageCanvas.defaultProps = {
  imageSrc: null,
  onLoad: () => {},
  mouseEvents: () => {},
};

export default EnhancedImageCanvas;
